import { css } from 'styled-components';
import {
  alignItems,
  justifyContent,
  flexWrap,
  flexDirection,
  flex,
  alignContent,
  justifyItems,
  justifySelf,
  alignSelf,
  order,
  flexBasis,
  style
} from 'styled-system';

export const gap = style({
  prop: 'gap',
  cssProperty: 'gap',
  key: 'space'
});

const flexHelper = css`
  ${alignItems};
  ${justifyContent};
  ${flexWrap};
  ${flexDirection};
  ${flex};
  ${alignContent};
  ${justifyItems};
  ${justifySelf};
  ${alignSelf};
  ${order};
  ${flexBasis};
  ${gap}
`;

export default flexHelper;
