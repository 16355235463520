import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, FormDialog } from 'core/form';
import { fields, options } from 'app/forms/config/dashboardDetails';
import { persistHash } from 'app/stores/query/urlHash';
import { merge } from 'lodash';
import DashboardDetailsForm from './DashboardDetailsForm';

@Form({ fields, options })
@inject('$dashboards')
@observer
class DashboardDetailsDialog extends Component {
  state = {
    editingDevices: false
  };

  handleToggleEditDevices = () => {
    const { editingDevices } = this.state;
    this.setState({ editingDevices: !editingDevices });
  };

  handleSubmit = (form) => {
    const { $dashboards, model: dashboard, onSubmit, dashboardItem } = this.props;
    const { isNew, isClone, origId } = dashboard;
    const { panel_title, parametric_mode, parametric_overrides, ...formValues } = form.getValues();
    const isParametricTypeChanged =
      dashboard.isParametric && dashboard.get('parametric_fields')[0].type !== formValues.parametric_fields[0].type;

    let promise;
    if (isClone) {
      const origModel = $dashboards.collection.get(origId);
      delete dashboard.isClone;
      delete dashboard.origId;
      promise = origModel
        .getSerializedDataForClone()
        .then((json) => $dashboards.cloneDashboardByJSON(merge(json, formValues)));
    } else {
      promise = dashboard.save(formValues, { clearSelection: false }).then(() => dashboard);
    }

    return promise.then((model) => {
      if (!isNew && model.hasItems && isParametricTypeChanged) {
        // we only call this if the parametric field's type has changed.
        model.updateItemsParametricFilterField();
      }

      // Reset category to be a full category, not just an ID
      const category_id = model.get('category_id');
      model.set('category', category_id ? $dashboards.categories.get(category_id).toJS() : {});

      if (dashboardItem) {
        dashboardItem.set({
          dashboard_id: model.get('id'),
          panel_title,
          parametric_mode,
          parametric_overrides
        });

        dashboardItem
          .save({}, { toast: false })
          .then(() => (dashboardItem.has('saved_query_id') ? persistHash(dashboardItem.get('saved_query_id')) : null))
          .then(() => {
            if (onSubmit) {
              onSubmit(model, isNew);
            }
          });
      } else if (onSubmit) {
        onSubmit(model, isNew);
      }
    });
  };

  render() {
    const { editingDevices } = this.state;
    const { model } = this.props;
    const isClone = model && model.isNew && model.name.includes('[Copy');

    return (
      <FormDialog
        {...this.props}
        style={{
          width: 850,
          maxHeight: 'calc(100vh - 100px)',
          top: 40,
          position: 'absolute'
        }}
        title={(isClone && 'Clone Dashboard') || (model && model.isNew && 'Add Dashboard') || 'Edit Dashboard'}
        entityName="Dashboard"
        formActionsProps={{
          onSubmit: this.handleSubmit
        }}
      >
        <DashboardDetailsForm
          {...this.props}
          editingDevices={editingDevices}
          onEditDevices={this.handleToggleEditDevices}
        />
      </FormDialog>
    );
  }
}

export default DashboardDetailsDialog;
