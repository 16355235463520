import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Flex, Suspense, Icon, EmptyState } from 'core/components';
import { formConsumer } from 'core/form';

import InterfaceGroupSelector from 'app/components/interfaceGroup/InterfaceGroupSelector';
import { FaEthernet } from 'react-icons/fa';

@formConsumer
@observer
export default class CapacityPlanInterfaces extends Component {
  state = {
    interfaceSelectorOpen: false
  };

  handleOpenInterfaceSelector = () => {
    this.setState({ interfaceSelectorOpen: true });
  };

  handleInterfaceGroupDialogClose = () => {
    this.setState({ interfaceSelectorOpen: false });
  };

  onInterfaceGroupSave = () => {
    const { form } = this.props;
    form.setValue('_interfaceGroup', 'dirty');
    form.validate();
  };

  render() {
    const { model, showErrors } = this.props;
    const { interfaceSelectorOpen } = this.state;

    const totalInterfaces = model.interfaceGroup.get('interfaces').length;

    return (
      <Suspense loading={!model}>
        {model && totalInterfaces > 0 && (
          <Flex alignItems="center" justifyContent="flex-end" mb={1}>
            <Button intent="primary" onClick={this.handleOpenInterfaceSelector} text="Add/Edit Interfaces" />
          </Flex>
        )}

        {model && totalInterfaces === 0 && (
          <EmptyState
            icon={<Icon icon={FaEthernet} color="specialMuted" iconSize={96} />}
            title="No interfaces configured"
            description="Add interfaces to the capacity group to start tracking them."
            mb={5}
            mt={5}
          >
            <Button intent="primary" onClick={this.handleOpenInterfaceSelector} text="Add/Edit Interfaces" />
          </EmptyState>
        )}

        {model && (
          <InterfaceGroupSelector
            isOpen={interfaceSelectorOpen}
            interfaceGroup={model.interfaceGroup}
            moduleName={model.moduleName}
            onInterfaceGroupSave={this.onInterfaceGroupSave}
            onInterfaceGroupDialogClose={this.handleInterfaceGroupDialogClose}
            showErrors={showErrors}
            staticInterfacesTitle="Static Group"
            fetch={false}
          />
        )}
      </Suspense>
    );
  }
}
