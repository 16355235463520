const { ENTITY_TYPES } = require('../hybrid/constants');

const OCI_ENTITY_TYPES = ENTITY_TYPES.get('oci');
const GCP_ENTITY_TYPES = ENTITY_TYPES.get('gcp');

// OCI
const CUSTOM_DIMENSION_SHORT_NAME_OCI_VCN = 'vcn';
const CUSTOM_DIMENSION_SHORT_NAME_OCI_CPE = 'cpe';
const CUSTOM_DIMENSION_SHORT_NAME_OCI_VNIC = 'vnic';
const CUSTOM_DIMENSION_SHORT_NAME_OCI_SUBNET = 'osub';
const CUSTOM_DIMENSION_SHORT_NAME_OCI_NAT_GATEWAY = 'onut';

// AWS
const CUSTOM_DIMENSION_SHORT_NAME_ENI = 'eni';
const CUSTOM_DIMENSION_SHORT_NAME_VPC = 'vpc';
const CUSTOM_DIMENSION_SHORT_NAME_SUBNET = 'sub';

const CUSTOM_DIMENSION_SHORT_NAME_NAT_GATEWAY = 'nat';
const CUSTOM_DIMENSION_SHORT_NAME_TRANSIT_GATEWAY = 'tgw';
const CUSTOM_DIMENSION_SHORT_NAME_CUSTOMER_GATEWAY = 'cg';
const CUSTOM_DIMENSION_SHORT_NAME_VIRTUAL_INTERFACE = 'vi';
const CUSTOM_DIMENSION_SHORT_NAME_TRANSIT_GATEWAY_ATTACHMENT = 'tgwa';
const CUSTOM_DIMENSION_SHORT_NAME_TRANSIT_GATEWAY_VPC_ATTACHMENT = 'tgwva';

// AZURE
const CUSTOM_DIMENSION_SHORT_NAME_FIREWALLS = 'fw';
const CUSTOM_DIMENSION_SHORT_NAME_EXPRESS_ROUTE_CIRCUITS = 'xrc';
const CUSTOM_DIMENSION_SHORT_NAME_PUBLIC_IP_ADDRESSESS = 'zip';
const CUSTOM_DIMENSION_SHORT_NAME_VIRTUAL_HUBS = 'vh';
const CUSTOM_DIMENSION_SHORT_NAME_ROUTE_TABLES = 'rt';
const CUSTOM_DIMENSION_SHORT_NAME_LOCAL_NETWORK_GATEWAYS = 'lngw';
const CUSTOM_DIMENSION_SHORT_NAME_AZURE_NETWORK_INTERFACES = 'znic';
const CUSTOM_DIMENSION_SHORT_NAME_P2S_VPN_GATEWAYS = 'p2sgw';
const CUSTOM_DIMENSION_SHORT_NAME_VNETS = 'vnt';
const CUSTOM_DIMENSION_SHORT_NAME_VNET_GATEWAYS = 'vntgw';
const CUSTOM_DIMENSION_SHORT_NAME_VPN_GATEWAYS = 'vpngw';
const CUSTOM_DIMENSION_SHORT_NAME_VPN_SITES = 'vpns';

// GCP  prefix with 'g' to disabiguate w/ other entities
const CUSTOM_DIMENSION_SHORT_NAME_GCP_FORWARDING_RULE = 'gfr';
const CUSTOM_DIMENSION_SHORT_NAME_GCP_INSTANCE = 'gi';
const CUSTOM_DIMENSION_SHORT_NAME_GCP_INTERCONNECT_ATTACHMENT = 'gic';
const CUSTOM_DIMENSION_SHORT_NAME_GCP_EXTERNAL_VPN_GATEWAY = 'gevgw';
const CUSTOM_DIMENSION_SHORT_NAME_GCP_VPN_GATEWAY = 'gvgw';
const CUSTOM_DIMENSION_SHORT_NAME_GCP_VPN_TUNNEL = 'gvt';

// while there's no hard limit on col name in pg, names > 60 chars have negative consequences. See Mike for full details.
const DIMENSION_COL_NAME_MAX_LENGTH = 60;
const AWS_AUTO_GENERATED_PREFIX = 'c_aws_';
const OCI_AUTO_GENERATED_PREFIX = 'c_oci_';
const AZURE_AUTO_GENERATED_PREFIX = 'c_azure_';
const GCP_AUTO_GENERATED_PREFIX = 'c_gcp_';

/*
these have tags but don't really have usable ipaddress fields
keeping around in case we decide to use UDR instead of ipaddress:

const CUSTOM_DIMENSION_SHORT_NAME_APPLICATION_GATEWAYS = 'agw';
const CUSTOM_DIMENSION_SHORT_NAME_EXPRESS_ROUTE_GATEWAYS = 'xrgw';
const CUSTOM_DIMENSION_SHORT_NAME_AZURE_NAT_GATEWAY = 'znat';
const CUSTOM_DIMENSION_SHORT_NAME_NETWORK_SECURITY_GROUP = 'nsg';
const CUSTOM_DIMENSION_SHORT_NAME_VNET_GATEWAY_CONNECTIONS = 'vntgwc';

TBD...
const CUSTOM_DIMENSION_SHORT_NAME_EXPRESS_ROUTE_CONNECTIONS = 'xrcn';
const CUSTOM_DIMENSION_SHORT_NAME_LOAD_BALANCERS = 'lb';
const CUSTOM_DIMENSION_SHORT_NAME_ROUTERS = 'r';
const CUSTOM_DIMENSION_SHORT_NAME_VIRTUAL_WANS = 'vw';

*/

// this should match entity type in topology Entities
const entityNames = {
  // oci
  [CUSTOM_DIMENSION_SHORT_NAME_OCI_CPE]: OCI_ENTITY_TYPES.CPE,
  [CUSTOM_DIMENSION_SHORT_NAME_OCI_VNIC]: OCI_ENTITY_TYPES.VNIC,
  [CUSTOM_DIMENSION_SHORT_NAME_OCI_SUBNET]: OCI_ENTITY_TYPES.SUBNET,
  [CUSTOM_DIMENSION_SHORT_NAME_OCI_NAT_GATEWAY]: OCI_ENTITY_TYPES.NAT_GATEWAY,
  [CUSTOM_DIMENSION_SHORT_NAME_OCI_VCN]: OCI_ENTITY_TYPES.VIRTUAL_CLOUD_NETWORK,
  // gcp
  [CUSTOM_DIMENSION_SHORT_NAME_GCP_FORWARDING_RULE]: GCP_ENTITY_TYPES.FORWARDING_RULE,
  [CUSTOM_DIMENSION_SHORT_NAME_GCP_INSTANCE]: GCP_ENTITY_TYPES.INSTANCE,
  [CUSTOM_DIMENSION_SHORT_NAME_GCP_INTERCONNECT_ATTACHMENT]: GCP_ENTITY_TYPES.INTERCONNECT_ATTACHMENT,
  [CUSTOM_DIMENSION_SHORT_NAME_GCP_EXTERNAL_VPN_GATEWAY]: GCP_ENTITY_TYPES.EXTERNAL_VPN_GATEWAY,
  [CUSTOM_DIMENSION_SHORT_NAME_GCP_VPN_GATEWAY]: GCP_ENTITY_TYPES.VPN_GATEWAY,
  [CUSTOM_DIMENSION_SHORT_NAME_GCP_VPN_TUNNEL]: GCP_ENTITY_TYPES.VPN_TUNNEL,
  // aws
  eni: 'NetworkInterfaces',
  vpc: 'Vpcs',
  sub: 'Subnets',
  nat: 'NatGateways',
  tgw: 'TransitGateways',
  cg: 'CustomerGateways',
  vi: 'VirtualInterfaces',
  tgwa: 'TransitGatewayAttachments',
  tgwva: 'TransitGatewayVpcAttachments',
  // azure
  agw: 'applicationGateways',
  fw: 'azureFirewalls',
  xrc: 'expressRouteCircuits',
  xrcn: 'expressRouteConnections',
  xrgw: 'expressRouteGateways',
  lb: 'loadBalancers',
  lngw: 'localNetworkGateways',
  znat: 'natGateways',
  znic: 'networkInterfaces',
  nsg: 'networkSecurityGroups',
  zip: 'publicIPAddresses',
  p2sgw: 'p2SVpnGateways',
  rt: 'routeTables',
  r: 'routers',
  vh: 'virtualHubs',
  vw: 'virtualWans',
  vntgwc: 'vnetGatewayConnections',
  vntgw: 'vnetGateways',
  vnt: 'vnets',
  vpngw: 'vpnGateways',
  vpns: 'vpnSites'
};

// this represents text in labels
const formFieldLabels = {
  // oci
  [CUSTOM_DIMENSION_SHORT_NAME_OCI_CPE]: 'Cpe',
  [CUSTOM_DIMENSION_SHORT_NAME_OCI_VNIC]: 'Vnic',
  [CUSTOM_DIMENSION_SHORT_NAME_OCI_SUBNET]: 'Subnets',
  [CUSTOM_DIMENSION_SHORT_NAME_OCI_NAT_GATEWAY]: 'NAT Gateway',
  [CUSTOM_DIMENSION_SHORT_NAME_OCI_VCN]: 'Virtual Cloud Network',
  // gcp
  [CUSTOM_DIMENSION_SHORT_NAME_GCP_FORWARDING_RULE]: 'Forwarding Rules',
  [CUSTOM_DIMENSION_SHORT_NAME_GCP_INSTANCE]: 'VM Instances',
  [CUSTOM_DIMENSION_SHORT_NAME_GCP_INTERCONNECT_ATTACHMENT]: 'Interconnect Attachments',
  [CUSTOM_DIMENSION_SHORT_NAME_GCP_EXTERNAL_VPN_GATEWAY]: 'External VPN Gateways',
  [CUSTOM_DIMENSION_SHORT_NAME_GCP_VPN_GATEWAY]: 'VPN Gateways',
  [CUSTOM_DIMENSION_SHORT_NAME_GCP_VPN_TUNNEL]: 'VPN Tunnels',
  // aws
  eni: 'Network Interfaces',
  vpc: 'VPCs',
  sub: 'Subnets',
  nat: 'NAT Gateways',
  tgw: 'Transit Gateways',
  cg: 'Customer Gateways',
  vi: 'Virtual Interfaces',
  tgwa: 'Transit Gateway Attachments',
  tgwva: 'Transit Gateway VPC Attachment',
  // azure
  agw: 'Application Gateways',
  fw: 'Firewalls',
  xrc: 'Express Route Circuits',
  xrcn: 'Express Route Connections',
  xrgw: 'Express Route Gateways',
  lb: 'Load Balancers',
  lngw: 'Local Network Gateways',
  znat: 'Nat Gateways',
  znic: 'Network Interfaces',
  nsg: 'Network Security Groups',
  zip: 'Public IP Addresses',
  p2sgw: 'p2S Vpn Gateways',
  rt: 'Route Tables',
  r: 'Routers',
  vh: 'Virtual Hubs',
  vw: 'Virtual Wans',
  vntgwc: 'VNET Gateway Connections',
  vntgw: 'VNET Gateways',
  vnt: 'VNETS',
  vpngw: 'VPN Gateways',
  vpns: 'VPN Sites'
  // oci
  // sub: OCI_ENTITY_TYPES.SUBNET
};

const shortNameToEntityType = (shortName) => {
  const entityName = entityNames[shortName];
  if (!entityName) {
    console.error('unknown short name type');
  }
  return entityName ?? '';
};

const formFieldToLabel = (fieldName) => formFieldLabels[fieldName] ?? '';

const tagToDimensionShortName = (tag, cloudProvider) => {
  const dimension = tag.toLowerCase().replaceAll(/[^\w]/gi, '_').replace(cloudProvider, '');

  return dimension;
};

const isOci = (cloudProvider) => cloudProvider === 'oci';
const isAzure = (cloudProvider) => cloudProvider === 'azure';
const isAws = (cloudProvider) => cloudProvider === 'aws';

module.exports = {
  shortNameToEntityType,
  formFieldToLabel,
  tagToDimensionShortName,
  isOci,
  isAzure,
  isAws,
  DIMENSION_COL_NAME_MAX_LENGTH,
  AWS_AUTO_GENERATED_PREFIX,
  OCI_AUTO_GENERATED_PREFIX,
  GCP_AUTO_GENERATED_PREFIX,
  AZURE_AUTO_GENERATED_PREFIX,
  awsShortNames: {
    CUSTOM_DIMENSION_SHORT_NAME_ENI,
    CUSTOM_DIMENSION_SHORT_NAME_VPC,
    CUSTOM_DIMENSION_SHORT_NAME_SUBNET,
    CUSTOM_DIMENSION_SHORT_NAME_NAT_GATEWAY,
    CUSTOM_DIMENSION_SHORT_NAME_TRANSIT_GATEWAY,
    CUSTOM_DIMENSION_SHORT_NAME_CUSTOMER_GATEWAY,
    CUSTOM_DIMENSION_SHORT_NAME_VIRTUAL_INTERFACE,
    CUSTOM_DIMENSION_SHORT_NAME_TRANSIT_GATEWAY_ATTACHMENT,
    CUSTOM_DIMENSION_SHORT_NAME_TRANSIT_GATEWAY_VPC_ATTACHMENT
  },
  ociShortNames: {
    CUSTOM_DIMENSION_SHORT_NAME_OCI_VCN,
    CUSTOM_DIMENSION_SHORT_NAME_OCI_CPE,
    CUSTOM_DIMENSION_SHORT_NAME_OCI_VNIC,
    CUSTOM_DIMENSION_SHORT_NAME_OCI_SUBNET,
    CUSTOM_DIMENSION_SHORT_NAME_OCI_NAT_GATEWAY
  },
  azureShortNames: {
    CUSTOM_DIMENSION_SHORT_NAME_FIREWALLS,
    CUSTOM_DIMENSION_SHORT_NAME_LOCAL_NETWORK_GATEWAYS,
    CUSTOM_DIMENSION_SHORT_NAME_AZURE_NETWORK_INTERFACES,
    CUSTOM_DIMENSION_SHORT_NAME_P2S_VPN_GATEWAYS,
    CUSTOM_DIMENSION_SHORT_NAME_VNET_GATEWAYS,
    CUSTOM_DIMENSION_SHORT_NAME_VPN_GATEWAYS,
    CUSTOM_DIMENSION_SHORT_NAME_EXPRESS_ROUTE_CIRCUITS,
    CUSTOM_DIMENSION_SHORT_NAME_PUBLIC_IP_ADDRESSESS,
    CUSTOM_DIMENSION_SHORT_NAME_VIRTUAL_HUBS,
    CUSTOM_DIMENSION_SHORT_NAME_ROUTE_TABLES,
    CUSTOM_DIMENSION_SHORT_NAME_VNETS,
    CUSTOM_DIMENSION_SHORT_NAME_VPN_SITES
    /*
    Some like subnet and vnetPeerings have ipaddress but no tag options...
    these have tags but don't really have usable ipaddress fields:

    CUSTOM_DIMENSION_SHORT_NAME_VNET_GATEWAY_CONNECTIONS,
    CUSTOM_DIMENSION_SHORT_NAME_NETWORK_SECURITY_GROUP,
    CUSTOM_DIMENSION_SHORT_NAME_AZURE_NAT_GATEWAY,
    CUSTOM_DIMENSION_SHORT_NAME_EXPRESS_ROUTE_GATEWAYS,
    CUSTOM_DIMENSION_SHORT_NAME_APPLICATION_GATEWAYS,

    TBD Not sure about these...
    CUSTOM_DIMENSION_SHORT_NAME_EXPRESS_ROUTE_CONNECTIONS,
    CUSTOM_DIMENSION_SHORT_NAME_LOAD_BALANCERS,
    CUSTOM_DIMENSION_SHORT_NAME_ROUTERS,
    CUSTOM_DIMENSION_SHORT_NAME_VIRTUAL_WANS,
    */
  },
  gcpShortNames: {
    CUSTOM_DIMENSION_SHORT_NAME_GCP_FORWARDING_RULE,
    CUSTOM_DIMENSION_SHORT_NAME_GCP_INSTANCE,
    CUSTOM_DIMENSION_SHORT_NAME_GCP_INTERCONNECT_ATTACHMENT,
    CUSTOM_DIMENSION_SHORT_NAME_GCP_EXTERNAL_VPN_GATEWAY,
    CUSTOM_DIMENSION_SHORT_NAME_GCP_VPN_GATEWAY,
    CUSTOM_DIMENSION_SHORT_NAME_GCP_VPN_TUNNEL
  }
};
