const { mapFilters } = require('../util/filters');

const allowedFilters = [
  'i_device_id',
  'i_device_label',
  'i_device_name',
  'i_device_site_id',
  'i_device_site_name',
  'i_device_subtype',
  'i_device_type',
  'i_input|output_interface_description',
  'i_input|output_interface_group',
  'i_input|output_snmp_alias',
  'inet_src|dst_addr'
];

const filterMappings = {
  i_device_name: 'device_name',
  'i_input|output_interface_description': 'name',
  'i_input|output_snmp_alias': 'description',
  'inet_src|dst_addr': 'device_ip'
};

const filterMeasurementConstraints = {
  'i_input|output_interface_description': (model) => model.measurement.startsWith('/interfaces/'),
  'i_input|output_snmp_alias': (model) => model.measurement.startsWith('/interfaces/')
};

function mapDataExplorerFilters(filters, measurementModel) {
  const { Dimensions = {} } = measurementModel.storage || {};

  return mapFilters(filters, (filter) => {
    if (allowedFilters.includes(filter.filterField)) {
      const mapping = filterMappings[filter.filterField];
      const constraint = filterMeasurementConstraints[filter.filterField];

      if (constraint && !constraint(measurementModel)) {
        // filter can't be used here
        return null;
      }

      if (!mapping) {
        // no transformation needed
        return filter;
      }

      if (Dimensions[mapping]) {
        return { ...filter, filterField: mapping };
      }
    }

    // filter can't be mapped
    return null;
  });
}

module.exports = { mapDataExplorerFilters };
