import React from 'react';
import sizeMe from 'react-sizeme';

const sizeMeHOC = sizeMe({
  monitorWidth: true,
  monitorHeight: false,
  refreshRate: 16
});

export default function dashboardWidthProvider(GridLayoutComponent) {
  const WrappedGridLayout = (props) => {
    const { size } = props;
    return <GridLayoutComponent {...props} width={size.width} />;
  };
  return sizeMeHOC(WrappedGridLayout);
}
