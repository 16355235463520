import React from 'react';
import { Tag } from 'core/components';

export default ({ text, isDarkMode, isUpdated, isBeta, isNew, isPreview, ...rest }) => {
  if (isUpdated) {
    return (
      <>
        {' '}
        <Tag
          className="product-meta updated"
          intent="warning"
          fontSize={12}
          fontWeight="medium"
          minimal={!isDarkMode}
          {...rest}
        >
          Updated
        </Tag>
      </>
    );
  }

  if (isBeta) {
    return (
      <>
        {' '}
        <Tag
          className="product-meta beta"
          intent="success"
          fontSize={12}
          fontWeight="medium"
          minimal={!isDarkMode}
          {...rest}
        >
          Beta
        </Tag>
      </>
    );
  }
  if (isNew) {
    return (
      <>
        {' '}
        <Tag
          className="product-meta new"
          intent="warning"
          fontSize={12}
          fontWeight="medium"
          minimal={!isDarkMode}
          {...rest}
        >
          New
        </Tag>
      </>
    );
  }

  if (isPreview) {
    return (
      <>
        {' '}
        <Tag
          className="product-meta preview"
          intent="success"
          fontSize={12}
          fontWeight="medium"
          minimal={!isDarkMode}
          {...rest}
        >
          Preview
        </Tag>
      </>
    );
  }

  return null;
};
