import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { debounce } from 'lodash';
import { greekPrefix, adjustByGreekPrefix } from 'core/util';
import { getMetadata, getBracketingDataClasses } from 'app/util/bracketing';
import BaseHighchartsDataview from './BaseHighchartsDataview';
import { getPeriodOverPeriodTooltip, getSharedTooltip, sharedTooltipPositioner } from './tooltipRenderers';

@inject('$app', '$dictionary')
@observer
export default class LineView extends BaseHighchartsDataview {
  sync = false;

  useQualitativeColors = true;

  chartOptions = {
    chart: {
      zoomType: 'x',
      events: {
        selection: this.handleTimeZoom
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      minRange: 60000,
      dateTimeLabelFormats: {
        day: '%m/%e',
        minute: '%H:%M'
      },
      title: {
        text: 'UTC'
      }
    },
    yAxis: [
      {
        min: 0.001,
        title: {
          text: 'units go here'
        }
      }
    ],
    plotOptions: {
      series: {
        marker: {
          enabled: false
        }
      }
    },
    legend: { enabled: false },
    tooltip: {
      shared: true,
      crosshairs: true,
      outside: true,
      useHTML: true,
      followPointer: true,
      hideDelay: 0,
      positioner: sharedTooltipPositioner.bind(this)
    },
    series: []
  };

  updatePlotBands = debounce(() => {
    const { viewProps } = this.props;
    this.chart.xAxis[0].update({ plotBands: viewProps.xPlotBands });
  }, 0);

  redraw({ setColors = false } = {}) {
    const { $app, viewProps } = this.props;

    if (this.chart) {
      $app.renderSync(() => {
        if (this.chart && this.chart.series && this.chart.series.length > 0) {
          const { dateRangeDisplay } = this.props.dataview.queryBuckets.activeBuckets[0].firstQuery;
          const { data } = this.chart.series[0].userOptions;
          const minuteIntervals = (data[1][0] - data[0][0]) / 60000;
          const dateRangeIntervalsDisplay = `${
            minuteIntervals >= 1 ? `${minuteIntervals} minute intervals` : `${minuteIntervals * 60} second intervals`
          }`;
          this.chart.xAxis[0].setTitle({
            text: `${dateRangeDisplay} (${dateRangeIntervalsDisplay})`
          });
        }

        if (!this.hasOverriddenExtremes) {
          if (this.chart.yAxis.length > 1) {
            if (this.sync) {
              const { dataMin, dataMax } = this.chart.yAxis[0].getExtremes();
              const { dataMin: secondaryMin, dataMax: secondaryMax } = this.chart.yAxis[1].getExtremes();
              const extreme = Math.max(
                Math.abs(dataMin),
                Math.abs(secondaryMin),
                Math.abs(secondaryMax),
                Math.abs(dataMax)
              );
              if (extreme) {
                this.chart.yAxis.forEach((axis) =>
                  axis.setExtremes(dataMin < 0 || secondaryMin < 0 ? extreme * -1 : 0.001, extreme, false)
                );
              }
            } else {
              const { dataMin: primaryMin } = this.chart.yAxis[0].getExtremes();
              const { dataMin: secondaryMin } = this.chart.yAxis[1].getExtremes();
              const extremeMin = Math.min(primaryMin, secondaryMin);
              this.chart.yAxis.forEach((axis) => {
                const { dataMin, dataMax } = axis.getExtremes();
                const extreme = Math.max(Math.abs(dataMin), Math.abs(dataMax));
                if (extreme) {
                  axis.setExtremes(extremeMin < 0 ? extreme * -1 : 0.001, extreme, false);
                }
              });
            }
          } else {
            this.chart.yAxis.forEach((axis) => {
              const { dataMin, dataMax } = axis.getExtremes();
              const extreme = Math.max(Math.abs(dataMin), Math.abs(dataMax));
              if (extreme) {
                axis.setExtremes(dataMin < 0 ? extreme * -1 : 0.001, extreme, false);
              }
            });
          }
        }

        if (this.chart && this.chart.yAxis && viewProps.plotLines) {
          this.chart.yAxis[0].update({ plotLines: viewProps.plotLines });
        }

        if (this.chart && this.chart.xAxis && viewProps.xPlotBands) {
          this.updatePlotBands();
        }

        if (this.chart && viewProps.seriesConfig) {
          const { series, max } = viewProps.seriesConfig;

          if (series) {
            const { max: currentMax } = this.getExtremes();
            const newSeriesNames = series.map((s) => s.name);
            const seriesToRemove = this.chart.series.reduce((acc, item) => {
              if (item.options.isArbitrary && !newSeriesNames.includes(item.name)) {
                acc.push(item);
              }

              return acc;
            }, []);

            if (seriesToRemove.length > 0) {
              seriesToRemove.forEach((s) => s.remove(false));
            }

            series.forEach(({ bucket, name, data, ...overrides }) => {
              const foundSeries = this.findSeries(bucket, name);

              if (foundSeries) {
                foundSeries.setData(data, false, false, true);
              } else {
                this.renderSeries(
                  bucket,
                  name,
                  data,
                  this.props.dataview.queryBuckets.activeBuckets[0].firstQuery,
                  overrides
                );
              }
            });

            this.setExtremes(0, Math.max(currentMax, max));
          }
        }

        if (setColors) {
          this.updateColors();
        } else {
          this.chart.redraw();
        }
      });
    }
    $app.renderSync(() => {
      this.dismissSpinner();
    });
  }

  renderSeries(bucket, name, data, query, overrides) {
    const { viewProps } = this.props;

    if (!this.chart || !this.chart.series) {
      return null;
    }

    const type = 'line';
    const yAxis = this.getYAxis(bucket);
    const isPreviousPeriod = bucket.get('isPreviousPeriod');
    const className = yAxis > 0 || isPreviousPeriod ? 'highcharts-series-dot' : '';
    const visible = !isPreviousPeriod || name === 'Total';
    const seriesData = { type, name, data, yAxis, className, visible, isPreviousPeriod, ...overrides };
    const matchingSeries = this.chart.series.find((series) => series.name === name);
    if (matchingSeries) {
      seriesData.linkedTo = matchingSeries.name;
      seriesData.color = matchingSeries.color;
      seriesData.colorIndex = viewProps.getColorIndex
        ? viewProps.getColorIndex(name, seriesData)
        : matchingSeries.colorIndex;

      this.addNativeLegendHoverEvents(matchingSeries);
    } else {
      seriesData.id = name;

      if (query.get('filterDimensionsEnabled') && query.get('filterDimensionSort')) {
        const { filterGroups } = query.get('filterDimensions');
        seriesData.colorIndex = filterGroups.findIndex((group) => group.name === name);
      } else if (viewProps.getColorIndex) {
        seriesData.colorIndex = viewProps.getColorIndex(name, seriesData);
      }
    }

    return this.chart.addSeries(seriesData, false);
  }

  findSeries(bucket, name) {
    const yAxisIdx = this.getYAxis(bucket);
    return this.chart.series.find((series) => series.name === name && series.yAxis === this.chart.yAxis[yAxisIdx]);
  }

  removeSeries(bucketName, name) {
    const yAxisIndex = this.getYAxis(bucketName);
    const isNegative = bucketName.includes('-Y Axis');
    const existingSeries =
      this.chart &&
      this.chart.series.find(
        (series) =>
          series.name === name &&
          series.yAxis === this.chart.yAxis[yAxisIndex] &&
          ((isNegative && series.dataMax < 0) || series.dataMax > 0)
      );
    if (existingSeries) {
      existingSeries.remove(false);
    } else {
      console.warn('Attempted to remove series', name, 'from bucket', bucketName, ', but no series was found.');
    }
  }

  renderOverlay(bucket, name, data) {
    if (!this.chart) {
      return null;
    }

    const yAxis = this.getYAxis(bucket);
    const type = 'line';
    const colorIndex = name.startsWith('Historical Total') ? 500 : 50;
    const isPreviousPeriod = bucket.get('isPreviousPeriod');
    const className = yAxis > 0 || isPreviousPeriod ? 'highcharts-series-dashdot' : 'highcharts-series-dash';
    const seriesData = { type, name, data, yAxis, colorIndex, className, isPreviousPeriod };
    const matchingSeries = this.chart.series.find((series) => series.name === name);
    if (matchingSeries) {
      seriesData.linkedTo = name;
      this.addNativeLegendHoverEvents(matchingSeries);
    } else {
      seriesData.id = name;
    }
    return this.chart.addSeries(seriesData, false);
  }

  getComponent() {
    const { chartOptions } = this;
    const { dataview, showNativeLegend, viewProps } = this.props;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const query = activeBuckets[0].firstQuery;

    if (query.get('outsort').includes('agg_total')) {
      throw new Error('This Time-Series chart does not support Display & Sort By Total.');
    }

    const { $dictionary } = this.props;

    chartOptions.xAxis.momentFn = query.get('time_format') === 'UTC' ? moment.utc : moment;
    chartOptions.xAxis.title.text = query.dateRangeDisplay;
    chartOptions.yAxis[0].title.text = $dictionary.get('units')[query.outsortUnit];
    chartOptions.yAxis[0].unit = query.outsortUnit;
    chartOptions.yAxis[0].type = query.get('use_log_axis') ? 'logarithmic' : 'linear';
    chartOptions.yAxis[0].custom = query.get('use_log_axis') ? { allowNegativeLog: true } : {};

    chartOptions.yAxis[0].min = query.get('use_log_axis') ? 0 : 0.001;

    if (viewProps.plotLines) {
      chartOptions.yAxis[0].endOnTick = false;
      chartOptions.yAxis[0].softMax = viewProps.plotLines.reduce((max, { value }) => Math.max(max, value), 0) * 1.1;
      chartOptions.yAxis[0].plotLines = viewProps.plotLines;
    }

    if (showNativeLegend) {
      chartOptions.legend = {
        labelFormatter() {
          return this.name.substr(0, 16);
        },
        verticalAlign: 'bottom',
        align: 'center'
      };
    }

    if (
      (activeBucketCount > 1 && !dataview.queryBuckets.hasPeriodOverPeriod) ||
      (query.get('secondaryOutsort') && query.get('secondaryTopxSeparate') === false)
    ) {
      chartOptions.yAxis[0].title.text = `${$dictionary.get('units')[query.outsortUnit]} \u2014`;
      const minusYbucket = activeBuckets.find((bucket) => bucket.get('name').includes('-Y'));
      if (minusYbucket) {
        chartOptions.yAxis.forEach((axis) => (axis.min = null));
      }

      const rightBucket = activeBuckets.find((bucket) => bucket.get('name').includes('Right'));
      if (rightBucket || (query.get('secondaryOutsort') && query.get('secondaryTopxSeparate') === false)) {
        const outsortUnit = rightBucket ? rightBucket.firstQuery.outsortUnit : query.secondaryOutsortUnit;
        if (chartOptions.yAxis.length === 1) {
          chartOptions.yAxis.push({
            title: {
              text: `${$dictionary.get('units')[outsortUnit]} \u2011\u2011\u2011`
            },
            opposite: true,
            gridLineColor: '#DEDEDE',
            gridLineWidth: 1,
            unit: outsortUnit,
            type: query.get('use_secondary_log_axis') ? 'logarithmic' : 'linear',
            custom: query.get('use_secondary_log_axis') ? { allowNegativeLog: true } : {}
          });
        } else {
          chartOptions.yAxis[1].title.text = `${$dictionary.get('units')[outsortUnit]} \u2011\u2011\u2011`;
          chartOptions.yAxis[1].unit = outsortUnit;
        }
      } else if (chartOptions.yAxis.length > 1) {
        chartOptions.yAxis = chartOptions.yAxis.slice(0, 1);
      }
    } else if (chartOptions.yAxis.length > 1) {
      chartOptions.yAxis = chartOptions.yAxis.slice(0, 1);
    }

    const bracketOptions = query.get('bracketOptions');
    if (bracketOptions && bracketOptions.type === 'staticRanges') {
      const metadata = getMetadata({ queryResults: activeBuckets[0].queryResults, bracketOptions });
      const bracketDataClasses = getBracketingDataClasses({ metadata, bracketOptions });
      chartOptions.yAxis.forEach((axis, index) => {
        axis.plotLines = [];
        bracketDataClasses.forEach((dataClass, bracketIdx) => {
          const value = dataClass.to * (index ? -1 : 1);
          const prefix = greekPrefix([value], 1.5);
          axis.plotLines.push({
            className: `plot-line-${bracketIdx}`,
            dashStyle: 'Dash',
            label: {
              text: `${adjustByGreekPrefix(value, prefix)} ${prefix}${$dictionary.get('units')[query.outsortUnit]}`
            },
            value,
            width: 2,
            zIndex: 5
          });
        });
      });
    } else {
      chartOptions.yAxis.forEach((axis) => delete axis.plotLines);
    }

    const { getColorFromIndex } = this;
    if (dataview.queryBuckets.hasPeriodOverPeriod) {
      chartOptions.tooltip.formatter = function formatter() {
        return getPeriodOverPeriodTooltip({
          points: this.points,
          offset: moment
            .duration(query.get('period_over_period_lookback'), query.get('period_over_period_lookback_unit'))
            .asMilliseconds(),
          getColorFromIndex,
          showSeconds: query.get('reAggInterval') ? query.get('reAggInterval') < 60 : false
        });
      };
    } else {
      chartOptions.tooltip.formatter = function formatter() {
        return getSharedTooltip({
          points: this.points,
          getColorFromIndex,
          showSeconds: query.get('reAggInterval') ? query.get('reAggInterval') < 60 : false
        });
      };
    }

    return super.getComponent();
  }
}

const config = {
  showTotalTrafficOverlay: true,
  showHistoricalOverlay: false,
  showLegend: true,
  timeBased: true,
  isSVG: true,
  enableToggle: true,
  mirrorable: true,
  allowsSecondaryOverlay: true,
  allowsSyncAxes: true,
  supportsLogAxis: true,
  buckets: [
    {
      name: 'Left +Y Axis',
      mirrorBucket: 2,
      secondaryMirrorBucket: 3,
      secondaryOverlayBucket: 1
    },
    {
      name: 'Right +Y Axis',
      mirrorBucket: 3
    },
    {
      name: 'Left -Y Axis',
      sampleRateFactor: -1,
      secondaryOverlayBucket: 3
    },
    {
      name: 'Right -Y Axis',
      sampleRateFactor: -1
    }
  ]
};

export { config };
