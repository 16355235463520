import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FaEllipsisH } from 'react-icons/fa';
import { IoIosGitNetwork } from 'react-icons/io';
import classNames from 'classnames';
import { Classes } from '@blueprintjs/core';
import storeLoader from 'app/stores/storeLoader';
import { Menu, MenuItem, Popover, Suspense, Text, Tag, Box } from 'core/components';
import { getHealthStateFromHealthData } from 'app/views/hybrid/utils/health';
import { capitalize } from 'lodash';
import { makeConfig } from './configHelper';

const defaultPopoverConfig = {
  positionOffset: {
    top: 0,
    left: -20
  }
};

export default function withPopover(WrappedComponent, passedPopoverConfig = {}) {
  const popoverConfig = { ...defaultPopoverConfig, ...passedPopoverConfig };
  @storeLoader('$devices', '$sites')
  @inject('$hybridMap')
  @withRouter
  @observer
  class WrapperComponent extends React.Component {
    state = {
      position: null,
      config: null,
      panel: null
    };

    boxRef = React.createRef();

    clickX = 0;

    clickY = 0;

    componentDidMount() {
      window.addEventListener('click', this.handleMouseClick, { capture: true });
    }

    componentWillUnmount() {
      window.removeEventListener('click', this.handleMouseClick);
    }

    componentDidUpdate(prevProps) {
      const { location } = this.props;

      if (prevProps.location.pathname !== location.pathname) {
        // close the popover on location change
        this.handlePopoverClose();
      }
    }

    setPopoverPosition = ({ left, top }) => {
      this.clickX = left;
      this.clickY = top;
    };

    handleMouseClick = (e) => {
      const clickRect = e.target.getBoundingClientRect();

      this.clickX = clickRect.x;
      this.clickY = clickRect.y;
    };

    handlePopoverOpen = ({ position, offset, panel, ...initialConfig }) => {
      const { setSidebarDetails } = this.props;
      const offsetRect = this.boxRef.current.getBoundingClientRect();
      let left = this.clickX - offsetRect.x;
      let top = this.clickY - offsetRect.y;
      let width = 0;
      let height = 0;
      let config = null;

      if (offset) {
        // support an additional offset against the original click position of the target
        left += offset.left || 0;
        top += offset.top || 0;
      }

      if (position) {
        // escape hatch to define our own position to put the popover
        left = position.left;
        top = position.top;
        width = position.width || 0;
        height = position.height || 0;

        // there's a 20px offset between the svg overlay and the popover container
        left += popoverConfig?.positionOffset?.left ?? 0;
        top += popoverConfig?.positionOffset?.top ?? 0;
      }

      if (!panel) {
        // at this point we have a raw config and will probably want to decorate it
        // with additional details that haven't been submitted via the openPopover call
        config = makeConfig(initialConfig);
      }

      this.setState({ position: { left, top, width, height }, config, panel });
      // sidebar opens automatically if show details button is disabled
      if (config?.isShowDetailsButtonDisabled) {
        const { shortcutMenu } = config;
        setSidebarDetails({
          ...shortcutMenu.selectedNode,
          ...config
        });
      }
    };

    handlePopoverClose = () => {
      this.setState({ position: null, config: null, panel: null });
    };

    get getTitleAndHealth() {
      const { config } = this.state;
      if (config.showTitle || config.showHealth) {
        const { id, name, typeLabel, health } = config.nodeData;
        const { intent, text } = getHealthStateFromHealthData(health);
        return (
          <Box m={1}>
            {config.showTitle && (
              <>
                <Text small as="div">
                  {typeLabel || capitalize((config.subType || config.type)?.replace(/s$/, '') || 'item')}
                </Text>
                <Text fontWeight="bold" as="div" mb={1}>
                  {name || id}
                </Text>
              </>
            )}
            {config.showHealth && (
              <Tag intent={intent} minimal round>
                {text}
              </Tag>
            )}
          </Box>
        );
      }
      return null;
    }

    get topologyLink() {
      const { history, $hybridMap } = this.props;
      const { config } = this.state;
      const validNodeTypes = ['cloud', 'device', 'region', 'site', 'vpc'];

      if (!config.isEmbedded && validNodeTypes.includes(config.type)) {
        let mapType = config.type;
        let id = config.value;

        if (config.type === 'site') {
          id = config.id;
        }

        if (config.type === 'region') {
          mapType = `cloud/${config.cloudProvider}/region`;
        }

        if (config.type === 'vpc') {
          mapType = `cloud/${config.cloudProvider}/region/${config.cloudRegion}/vpc`;
        }

        return (
          <MenuItem
            text="View Topology"
            onClick={() => history.push(`${$hybridMap.baseRoute}/${mapType}/${id}`)}
            icon={IoIosGitNetwork}
          />
        );
      }

      return null;
    }

    get embeddedMapLink() {
      const { history, $hybridMap, device } = this.props;
      const { config } = this.state;
      const validNodeTypes = ['cloud', 'device', 'site', 'interface'];

      if (config.isEmbedded && validNodeTypes.includes(config.type)) {
        let mapType = config.type;
        let id = config.value;

        if (config.type === 'site') {
          id = config.id;
        }

        if (config.type === 'interface') {
          mapType = 'device';
          id = device ? device.id : config.value.device_id;
        }

        return (
          <MenuItem
            text="View Full Map"
            onClick={() => history.push(`${$hybridMap.baseRoute}/${mapType}/${id}`)}
            icon={IoIosGitNetwork}
          />
        );
      }

      return null;
    }

    get popoverContent() {
      const { setSidebarDetails } = this.props;
      const { config, panel } = this.state;

      if (panel) {
        return panel;
      }

      const { shortcutMenu, showConnectionsMenuItemProps } = config;

      // if we have an actual selectedNode properties, display the shortcut menu
      return (
        <Menu>
          {this.getTitleAndHealth}
          {this.topologyLink}
          {this.embeddedMapLink}
          {!config.isEmbedded && !config.isShowDetailsButtonDisabled && (
            <MenuItem
              text="Show Details"
              icon={FaEllipsisH}
              onClick={() => {
                setSidebarDetails({
                  ...shortcutMenu.selectedNode,
                  ...config
                });

                this.handlePopoverClose();
              }}
            />
          )}
          {!shortcutMenu.hideShowConnectionsMenuItem && (
            <MenuItem
              text={`${shortcutMenu.isShowingConnections ? 'Hide' : 'Show'} Connections`}
              icon="flow-review"
              onClick={() => {
                const node = shortcutMenu.isShowingConnections ? null : shortcutMenu.selectedNode;

                shortcutMenu.showConnectionsCallback(node);
                this.handlePopoverClose();
              }}
              {...showConnectionsMenuItemProps}
            />
          )}

          {shortcutMenu.customItems &&
            shortcutMenu.customItems.map((item) => {
              if (React.isValidElement(item)) {
                return item;
              }

              const { text, icon, action } = item;
              return <MenuItem key={text} text={text} icon={icon} onClick={action} />;
            })}
        </Menu>
      );
    }

    get popover() {
      const { position, config } = this.state;
      const { dark, ...popoverProps } = popoverConfig;

      if (position) {
        return (
          <Popover
            isOpen={position !== null}
            position={(config && config.placement) || 'left'}
            content={this.popoverContent}
            popoverClassName={classNames('no-transform', { [Classes.DARK]: dark })}
            minimal={false}
            autoFocus={false}
            onClose={this.handlePopoverClose}
            targetProps={{
              style: {
                position: 'absolute',
                left: position.left,
                top: position.top,
                width: position.width,
                height: position.height
              }
            }}
            {...popoverProps}
          >
            <div />
          </Popover>
        );
      }

      return null;
    }

    render() {
      const { $hybridMap, loading, ...restProps } = this.props;
      const { config, position } = this.state;

      return (
        <Suspense loading={loading}>
          <div ref={this.boxRef} style={{ height: '100%', position: 'relative' }}>
            {this.popover}
            <WrappedComponent
              {...restProps}
              setPopoverPosition={this.setPopoverPosition}
              openPopover={this.handlePopoverOpen}
              onPopoverClose={this.handlePopoverClose}
              isPopoverOpen={!!(config && position)}
            />
          </div>
        </Suspense>
      );
    }
  }

  return WrapperComponent;
}
