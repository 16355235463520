import React from 'react';
import Icon from 'core/components/Icon';
import Tag from 'core/components/Tag';

const ParametricTag = () => (
  <Tag mr="4px" minimal>
    <Icon icon="chat" iconSize={11} style={{ marginLeft: 'auto', marginRight: 4 }} />
    Guided
  </Tag>
);

export default ParametricTag;
