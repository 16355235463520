import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';
import Button from 'core/components/Button';

@inject('$app', '$explorer')
@observer
export default class ViewInExplorerButton extends Component {
  static defaultProps = {
    dataview: {},
    small: true,
    minimal: true,
    openInNewWindow: false
  };

  handleOnClick = () => {
    const { $explorer, dataview, query, openInNewWindow } = this.props;
    const { hash } = dataview;
    const selectedQuery = query || get(dataview, 'queryBuckets.selectedQuery');

    if (hash) {
      $explorer.loadHash(hash);
    }

    if (selectedQuery) {
      $explorer.navigateToExplorer(selectedQuery, openInNewWindow);
    }
  };

  render() {
    const { $app, $explorer, dataview, query, small, minimal, openInNewWindow, ...buttonProps } = this.props;

    if ($app.isExport || $app.isSubtenant) {
      return null;
    }

    return (
      <Button
        small={small}
        minimal={minimal}
        icon="series-search"
        text="View in Data Explorer"
        onClick={this.handleOnClick}
        {...buttonProps}
      />
    );
  }
}
