import { Component } from 'react';

class CollectionLookup extends Component {
  state = {
    model: undefined
  };

  componentDidMount() {
    this.doLookup();
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.doLookup();
    }
  }

  doLookup() {
    const { collection, predicate } = this.props;

    this.setState({ model: undefined });

    collection.fetch().then(() => {
      const model = collection.get().find(predicate);
      this.setState({ model });
    });
  }

  render() {
    const { children, fallback } = this.props;
    const { model } = this.state;

    if (!model) {
      return fallback || null;
    }

    return children({ model });
  }
}

export default CollectionLookup;
