import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Callout, Tag, Text } from 'core/components';
import FilterItem from 'app/components/filters/FilterItem';
import SavedFilterDisplay from '../SavedFilterDisplay';

@inject('$dictionary')
@observer
class FilterGroup extends Component {
  render() {
    const { $dictionary, connector: groupConnector, group, isLastGroup, useNmsDimensions } = this.props;
    const shouldExcludeResults = group.not;
    const filterConnector = group.connector;

    return (
      <>
        <Callout mb={isLastGroup ? 0 : 0.5}>
          <Box mb="4px">
            <Text small>
              <Text fontWeight="bold">{`${shouldExcludeResults ? 'Exclude' : 'Include'} `}</Text>
              results matching <Tag>{filterConnector.toUpperCase()}</Tag>
            </Text>
          </Box>
          <Box ml={1}>
            {group.filters &&
              group.filters.map((filter, index) => {
                const key = `${filter.filterField}.${filter.operator}.${filter.filterValue}.${filter.rightFilterField}.${index}`;
                const showValueField = !$dictionary
                  .get('queryFilters.suppressValueOperators')
                  .includes(filter.operator);

                return (
                  <FilterItem
                    key={key}
                    isLast={index === group.filters.length - 1}
                    {...filter}
                    showValueField={showValueField}
                    useNmsDimensions={useNmsDimensions}
                  />
                );
              })}
            {group.saved_filters &&
              group.saved_filters.map((filter) => (
                <SavedFilterDisplay key={filter.filter_id} filter_id={filter.filter_id} is_not={filter.is_not} />
              ))}
          </Box>
          {group.filterGroups && group.filterGroups.length > 0 && (
            <Box p={1} mt={1} pb={0}>
              {group.filterGroups.map((nestedGroup, index) => (
                /* eslint-disable react/no-array-index-key */
                <FilterGroup
                  key={index}
                  group={nestedGroup}
                  index={index}
                  connector={group.connector}
                  isLastGroup={index === group.filterGroups.length - 1}
                  useNmsDimensions={useNmsDimensions}
                  $dictionary={$dictionary} /* crazy but needed */
                />
              ))}
            </Box>
          )}
        </Callout>
        {!isLastGroup && (
          <Box mb="4px" ml={1}>
            <Text fontWeight="bold" muted small>
              {groupConnector === 'All' ? 'AND' : 'OR'}
            </Text>
          </Box>
        )}
      </>
    );
  }
}

export default FilterGroup;
