import React from 'react';
import { Button, Flex, Box, Card, Text, Tag } from 'core/components';

const ArchitectureDisplay = ({ value, onEditArchitectureClick }) => (
  <Box>
    {!value && (
      <Text as="div" mb="4px" muted>
        Defining the architecture for your Site allows Kentik to better render the Network Map.
      </Text>
    )}
    {value && (
      <Flex>
        {value.length > 1 && (
          <Box mt="16px" mb="24px" width="1px" border="thin">
            &nbsp;
          </Box>
        )}
        <Box>
          {value.map((layer) => (
            <Flex key={layer.subLayers.map((subLayer) => subLayer.name).toString()} mb={1}>
              {value.length > 1 && (
                <Flex flexDirection="column" alignItems="center" justifyContent="center">
                  <Box border="thin" height={1} width={15}>
                    &nbsp;
                  </Box>
                </Flex>
              )}
              {layer.subLayers.map((subLayer, subIdx) => (
                <Flex key={subLayer.name + (subLayer.devices || []).length}>
                  <Card width={200} p={1}>
                    <Flex justifyContent="space-between">
                      <Text as="div" fontWeight="normal" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {subLayer.name}
                      </Text>
                      {!subLayer.devices && (
                        <Tag small minimal flex="0 0 fit-content">
                          No Devices
                        </Tag>
                      )}
                      {subLayer.devices && (
                        <Tag small minimal flex="0 0 fit-content">
                          {subLayer.devices.length === 0 && 'No Devices'}
                          {subLayer.devices.length > 0 && (
                            <>
                              {subLayer.devices.length} Device{subLayer.devices.length > 1 ? 's' : ''}
                            </>
                          )}
                        </Tag>
                      )}
                    </Flex>
                  </Card>
                  {subIdx === 0 && layer.subLayers.length > 1 && (
                    <Flex flexDirection="column" alignItems="center" justifyContent="center">
                      <Box border="thin" height={1} width={15}>
                        &nbsp;
                      </Box>
                    </Flex>
                  )}
                </Flex>
              ))}
            </Flex>
          ))}
        </Box>
      </Flex>
    )}
    {onEditArchitectureClick && <Button text="Edit Architecture" icon="edit" onClick={onEditArchitectureClick} />}
  </Box>
);

export default ArchitectureDisplay;
