import React, { Component } from 'react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import { getOption } from 'core/form/components/modalSelect/selectHelpers';
import DeviceValuesContainer from './DeviceValuesContainer';
import DeviceValueRenderer from './DeviceValueRenderer';

class DeviceValuesRenderer extends Component {
  static defaultProps = {
    readOnly: false
  };

  state = {
    expanded: false
  };

  handleHideShowAllDevices = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  render() {
    const { maxVisible = 8, options, values, onUnselect, readOnly } = this.props;
    const { expanded } = this.state;

    const valueTags = [];

    if (values.length === 0) {
      valueTags.push(
        <div key="no-devices-tag" className="empty-text pt-text-muted">
          No devices selected
        </div>
      );
    }

    values.forEach((value, idx) => {
      if (expanded || idx < maxVisible) {
        const option = getOption(options, value);

        if (option) {
          valueTags.push(
            <DeviceValueRenderer
              key={value}
              {...this.props}
              value={value}
              option={option}
              onUnselect={onUnselect}
              asTag
            />
          );
        }
      }
    });

    if (values.length > maxVisible) {
      valueTags.push(
        <div key="show-all-btn">
          <Button intent="primary" onClick={this.handleHideShowAllDevices} minimal small>
            {expanded && `Show ${maxVisible} selected devices only`}
            {!expanded && `Show ${values.length - maxVisible} other selected devices...`}
          </Button>
        </div>
      );
    }

    return <DeviceValuesContainer className={classNames({ 'read-only': readOnly })}>{valueTags}</DeviceValuesContainer>;
  }
}

export default DeviceValuesRenderer;
