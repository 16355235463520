import React from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Heading, Icon, Tooltip } from 'core/components';
import AlarmTimeline from 'app/views/synthetics/components/alarmTimeline/AlarmTimeline';
import IncidentLogMessage from 'app/views/synthetics/components/incidentLogs/IncidentLogMessage';
import LoadingPanel from './LoadingPanel';

@observer
export default class AlertTimelinePanel extends React.Component {
  get isLoading() {
    const { testResults, loading } = this.props;
    const { loadingTimestamps, loadingAlarmResults } = testResults;

    return loading || loadingTimestamps || loadingAlarmResults;
  }

  get incidentThresholdMessage() {
    const { testResults } = this.props;
    const { alarmResults } = testResults;

    if (alarmResults) {
      const totalCount = +(alarmResults.pagination?.total_count || 0);
      const alarmCount = +alarmResults.count;

      if (alarmCount < totalCount) {
        return (
          <Tooltip
            content={
              <Box width={200}>
                <IncidentLogMessage alarms={alarmResults?.alarms || []} totalAlarms={totalCount} />
              </Box>
            }
          >
            <Icon ml={1} icon="info-sign" color="primary" />
          </Tooltip>
        );
      }
    }

    return null;
  }

  get content() {
    const { testResults, onSelect, onHover, onShowHealthClick } = this.props;
    const { healthTimeline, timelineBounds } = testResults;

    return (
      <LoadingPanel loading={this.isLoading} height={82}>
        <AlarmTimeline
          {...timelineBounds}
          data={healthTimeline}
          testResults={testResults}
          onSelect={onSelect}
          onHover={onHover}
          onShowHealthClick={onShowHealthClick}
        />
      </LoadingPanel>
    );
  }

  render() {
    const { testResults } = this.props;
    const { displayOptions } = testResults;
    const { allowHealthTimeline } = displayOptions;

    if (allowHealthTimeline) {
      return (
        <>
          <Flex>
            <Heading level={6}>Alert Timeline</Heading>
            {this.incidentThresholdMessage}
          </Flex>
          {this.content}
        </>
      );
    }

    return null;
  }
}
