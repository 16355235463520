let lookups = {};

const getPathCount = (group) =>
  Object.keys(group).reduce((prefixCount, prefixKey) => (prefixCount += Object.keys(group[prefixKey]).length), 0);

const truncateString = (str, maxLength) => {
  const { length } = str;
  const splitLength = (maxLength - 5) / 2;

  return length > maxLength
    ? `${str.substring(0, splitLength)} ... ${str.substring(length - splitLength, length)}`
    : str;
};

const prefixInfo = (ip) => ({
  name: ip
});

const asnInfo = (id) => {
  const asn = lookups.asns[id];

  return {
    name: `AS${truncateString(id, 22)}`,
    title: asn.name ? truncateString(asn.name, 25) : '',
    tooltipName: `AS${id}${asn.name ? ` - ${asn.name}` : ''}`,
    asn
  };
};

export const buildNodeData = (data) => {
  let info = {};

  if (data.type === 'asn') {
    info = asnInfo(data.id);
  }

  if (data.type === 'prefix') {
    info = prefixInfo(data.id);
  }

  return {
    ...info,
    pathCount: getPathCount(data.group)
  };
};

const buildLinkedNode = (node, hops) => ({ ...node, hops });

const buildLinkGroup = (data) => {
  const group = {};

  data.toHops.forEach(({ prefix, routeIndex }) => {
    if (!group[prefix]) {
      group[prefix] = {};
    }

    if (!group[prefix][routeIndex]) {
      group[prefix][routeIndex] = true;
    }
  });

  return group;
};

export const buildLinkData = (data, nodes) => {
  const fromNode = buildLinkedNode(
    nodes.find(({ id }) => data.from === id),
    data.fromHops
  );
  const toNode = buildLinkedNode(
    nodes.find(({ id }) => data.to === id),
    data.toHops
  );
  const to = buildNodeData(toNode);
  const from = buildNodeData(fromNode);
  const group = buildLinkGroup(data);
  const pathCount = getPathCount(group);

  return {
    to: {
      ...to,
      style: toNode.style
    },
    from: {
      ...from,
      style: fromNode.style
    },
    group,
    pathCount
  };
};

export const setOptions = (options) => {
  lookups = options.lookups;
};
