import $dictionary from 'app/stores/$dictionary';
import { Collection } from 'core/model';
import { greekIt } from 'app/util/utils';
import * as d3 from 'd3';

const PARSERS = {
  i_protocol_name: /^([A-Za-z0-9-]+) \(\d+\)$/,
  i_input_snmp_alias: '\\(([0-9]+)\\)$',
  i_output_snmp_alias: '\\(([0-9]+)\\)$',
  inet_dst_addr: '(\\S+)(?:\\s+\\()?',
  inet_src_addr: '(\\S+)(?:\\s+\\()?',

  // "Apple (CDN),US (6185)" -> "Apple (CDN),US"
  i_src_as_name: /^(.*) \(\d+\)$/,
  i_dst_as_name: /^(.*) \(\d+\)$/
};

const ILIKE_FIELDS = ['inet_dst_addr', 'inet_src_addr', 'i_output_snmp_alias', 'i_input_snmp_alias'];
const IGNORE_FILTER_FIELDS = ['i_input_snmp_alias', 'i_output_snmp_alias', 'i_src_bgp_prefix', 'i_dst_bgp_prefix'];

const OVERRIDE_DIMENSIONS = {
  inet_src_addr: 'Source IP',
  inet_dst_addr: 'Destination IP',
  i_src_bgp_prefix: 'Source Prefix',
  i_dst_bgp_prefix: 'Destination Prefix'
};

/**
 * Formats the metric value with the specified number of decimal places and suffix.
 * @param {number} value - The metric value.
 * @param {number} [fix=2] - The number of decimal places to display.
 * @param {string} [suffix='bits/s'] - The suffix to append to the value.
 * @returns {string} The formatted metric value.
 */
function formatMetricValue(value, units) {
  const suffix = `${units === 'bytes' ? 'bits' : units}/s`;
  const { displayFull } = greekIt(value, { fix: 2, suffix });
  return displayFull;
}

/**
 * Takes a raw KDE column name and returns the corresponding dimension label.
 * @param {string} type - The raw KDE column name.
 * @returns {string} The dimension label.
 */
function processTypeToDimension(type, dimensions, returnField = 'label') {
  let dim = type;

  if (type.startsWith('str')) {
    const regex = new RegExp(`.*${type.toUpperCase()}$`, 'i');
    const match = dimensions.find((d) => regex.test(d));
    if (match) {
      dim = match;
    }
  }

  const dimension = $dictionary.flatFilterLabels.find((l) => l.value === dim || l.value === type.replace('i_', ''));

  if (returnField === 'label') {
    return OVERRIDE_DIMENSIONS[dim] || (dimension ? dimension.label : undefined) || `Unknown Dimension (${type})`;
  }

  // Return the field value directly when used by processIsetToFilters
  return dimension ? dimension[returnField] : undefined;
}

/**
 * Takes an item set and returns an array of values.
 * @param {object} suffix - The item set.
 * @returns {string[]} The array of values.
 */
function processSuffixToValues(suffix) {
  const { items } = suffix;
  return items.map(({ type, value }) => {
    const valueOptions = $dictionary.filterFieldValueOptions[type];
    if (!valueOptions) {
      return value;
    }
    const option = valueOptions.find((opt) => opt.value === value);
    return option ? option.label : value;
  });
}

/**
 * Takes an item set and produces a name string that is used to identify the node.
 * @param {object} suffix - The item set.
 * @returns {string} The name string.
 */
function processSuffixToName(suffix, requestedDimensions) {
  if (!suffix || !suffix.items || !Array.isArray(suffix.items)) {
    return 'Invalid suffix';
  }

  const { items } = suffix;

  if (items.length === 0) {
    return 'No items';
  }

  return items
    .map(({ type, value }) => {
      const dimension = processTypeToDimension(type, requestedDimensions);

      const processedValue = processSuffixToValues({ items: [{ type, value }] })[0];
      return `${dimension}: ${processedValue}`;
    })
    .join(', ');
}

/**
 * Takes an item set and returns an array of dimension types.
 * @param {object} suffix - The item set.
 * @returns {string[]} The array of dimension types.
 */
function processSuffixToDimensions(suffix, dimensions) {
  const { items } = suffix;
  return items.map(({ type }) => processTypeToDimension(type, dimensions));
}

// same as above but returns raw type
function processSuffixToDimensionsRaw(suffix) {
  const { items } = suffix;
  return items.map(({ type }) => type);
}

/**
 * Takes an item set and returns an array of filters.
 * @param {object} iset - The item set.
 * @returns {object[]} The array of filters.
 */
function processIsetToFilters(iset, dimensions) {
  const { items } = iset;

  return items
    .map(({ type, value }) => {
      const filterValue = PARSERS[type] ? value.match(new RegExp(PARSERS[type]))?.[1] || value : value;

      return {
        filterField: processTypeToDimension(type, dimensions, 'value'),
        operator: ILIKE_FIELDS.includes(type) ? 'ILIKE' : '=',
        filterValue
      };
    })
    .filter(({ filterField }) => !IGNORE_FILTER_FIELDS.includes(filterField));
}

/**
 * Processes a child node into a collection.
 * @param {object} node - The child node.
 * @returns {object} The processed node data.
 *
 */
function processChildNodeIntoCollection(node, units, requestedDimensions) {
  const { suffix, children, metric, percentage, level, color, iset } = node;
  const name = processSuffixToName(suffix, requestedDimensions);
  const filters = processIsetToFilters(iset, requestedDimensions);
  const values = processSuffixToValues(suffix);
  const dimensions = processSuffixToDimensions(suffix, requestedDimensions);
  const dimensionsRaw = processSuffixToDimensionsRaw(suffix);
  const nodeData = {
    name,
    filters,
    values,
    dimensions,
    dimensionsRaw,
    color,
    iset,
    suffix,
    value: metric,
    dimensionValueHash: Object.fromEntries(dimensions.map((dimension, index) => [dimension, values[index]])),
    formattedValue: formatMetricValue(metric, units),
    children: new Collection(),
    percentage: +(percentage ?? 0).toFixed(0),
    level
  };
  nodeData.children.add(children.map((child) => processChildNodeIntoCollection(child, units, requestedDimensions)));
  nodeData.children.sort('percentage', 'desc');
  if (nodeData.children.length === 0) {
    delete nodeData.children;
  }
  return nodeData;
}
/**
 * Processes a child node.
 * @param {object} node - The child node.
 * @returns {object} The processed node data.
 */
function processChildNode(node, units, requestedDimensions) {
  const { suffix, children = [], metric, percentage, level, color } = node;
  const name = processSuffixToName(suffix, requestedDimensions);
  const filters = processIsetToFilters(suffix, requestedDimensions);
  const values = processSuffixToValues(suffix);
  const dimensions = processSuffixToDimensions(suffix, requestedDimensions);
  const dimensionsRaw = processSuffixToDimensionsRaw(suffix);
  const nodeData = {
    name,
    filters,
    values,
    dimensions,
    dimensionsRaw,
    color,
    value: metric,
    formattedValue: formatMetricValue(metric, units),
    children: children.map((child) => processChildNode(child, units, requestedDimensions)),
    percentage: +(percentage ?? 0).toFixed(0),
    level
  };
  if (nodeData.children.length === 0) {
    delete nodeData.children;
  }
  return nodeData;
}
// Function to recursively assign color to all child nodes
function propagateColorToChildren(node, color) {
  // is a model?
  if (node.set) {
    node.set('color', color);
  } else {
    node.color = color;
  }
  if (node.get ? node.get('children') : node.children) {
    if (node.get) {
      node.get('children').each((child) => {
        propagateColorToChildren(child, color);
      });
    } else {
      node.children.each((child) => {
        propagateColorToChildren(child, color);
      });
    }
  }
}
/**
 * Transforms nested tree data into Collections for use in Table
 * @param {object} data - The nested tree data.
 * @returns {object} The transformed data.
 */
export function transformNestedTreeDataIntoCollections(data) {
  if (!data) {
    return {
      name: 'Factors',
      children: new Collection()
    };
  }

  const { units, dimensions } = data;
  const children = data.children.map((child) => processChildNodeIntoCollection(child, units, dimensions));
  // use the same colorScale as the Treemap
  const colorScale = d3.scaleOrdinal(
    children.map((d) => d.name),
    d3.schemeTableau10
  );
  // assign a color to each of the level 0 parent nodes and propagate it to all children
  children.forEach((node) => {
    node.color = colorScale(node.name);
    propagateColorToChildren(node, node.color);
  });
  return {
    name: 'Factors',
    children: new Collection(children)
  };
}
/**
 * Transforms nested tree data.
 * @param {object} data - The nested tree data.
 * @returns {object} The transformed data.
 */
export function transformNestedTreeData(data) {
  if (!data) {
    return {
      name: 'Factors',
      children: []
    };
  }
  const { units, dimensions } = data;
  const children = data.children.map((child) => processChildNode(child, units, dimensions));
  const colorScale = d3.scaleOrdinal(
    children.map((d) => d.name),
    d3.schemeTableau10
  );
  // assign a color to each of the level 0 parent nodes
  children.forEach((node) => {
    node.color = colorScale(node.name);
  });

  return {
    name: 'Factors',
    children
  };
}
