import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Dialog, Card } from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import Collection from 'core/model/Collection';
import { Model } from 'core/model';
import PolicyLink from 'app/components/links/PolicyLink';
import Link from 'core/components/Link';
import { CELL_TYPES } from 'core/components/table';
import { RemoveButton } from 'core/form';
import NotificationUsageListTable from './NotificationUsageListTable';

@inject('$auth', '$insights')
@storeLoader('$alerting.policyCollection', '$mitigations.methodsCollection', '$syn.tests', '$mkp.tenants')
@observer
export default class NotificationUsageListContainer extends Component {
  get columns() {
    const { $auth, addDeleteColumn, notificationModel } = this.props;

    const finalColumns = [
      {
        label: 'What',
        name: 'where',
        width: 120
      },
      {
        label: 'ID',
        name: 'id',
        width: 180
      },
      {
        label: 'Name',
        name: 'name'
      },
      {
        label: 'Link',
        name: 'link',
        width: 160,
        sortable: false,
        renderer: ({ model, value }) => {
          const where = model.get('where');
          const linkProps = {
            target: '_blank',
            to: value,
            rel: 'noopener noreferrer'
          };

          if (where.includes('Tenant')) {
            return <Link {...linkProps}>Edit Tenant</Link>;
          }

          // TODO: redirect to levels tab for NMS policies https://github.com/kentik/ui-app/issues/24842
          if (where.includes('Policy')) {
            return <PolicyLink {...linkProps} id={model.get('id')} />;
          }

          if (where.includes('Mitigation')) {
            return <Link {...linkProps}>Edit Mitigation Method</Link>;
          }

          if (where.includes('Family')) {
            return <Link {...linkProps}>Go to Insights</Link>;
          }

          if (where.includes('Insight')) {
            return <Link {...linkProps}>Go to Insight</Link>;
          }

          if (where.includes('Synth')) {
            return <Link {...linkProps}>Go to Test</Link>;
          }

          return value;
        }
      }
    ];

    // this auth permission check is a little iffy - technically users are editing other entities
    // that the notifications are tied to (ie. policies, tests etc.) and not the notifications themselves
    // it's too difficult to check what they're editing and disable based on permissions for those, so we just go with this
    if (addDeleteColumn && $auth.hasPermission('alerts.notifications.edit')) {
      finalColumns.push({
        type: CELL_TYPES.ACTION,
        actions: [
          (associationModel) => (
            <RemoveButton
              popoverProps={{
                confirmBodyContent: 'Are you sure you want this channel to stop notifications from this source?'
              }}
              key="delete"
              showIcon
              hideText
              onRemove={() => notificationModel.removeAssociation(associationModel)}
              small
            />
          )
        ]
      });
    }

    return finalColumns;
  }

  get usageCollection() {
    const { usages, $alerting, $mitigations, $syn, $insights, $mkp } = this.props;

    const collection = new Collection([]);

    Object.keys(usages).forEach((usageKey) => {
      switch (usageKey) {
        case 'mitigationMethods': {
          const methodModels = usages[usageKey]
            .map((methodId) => {
              const mitigationMethod = $mitigations.methodsCollection.modelById[methodId];

              if (mitigationMethod) {
                return new Model({
                  where: 'Mitigation Method',
                  lhs: 'mitigationMethod',
                  id: methodId,
                  name: mitigationMethod.get('name'),
                  // TODO maybe open edit modal when navigating directly as ux improvement
                  link: `/v4/settings/mitigations?editMethodId=${methodId}`
                });
              }

              return null;
            })
            .filter(Boolean);

          collection.add(methodModels);

          break;
        }
        case 'insights': {
          const insightModels = usages[usageKey].map((insightName) => {
            const insightModel = $insights.getInsightNames().find((option) => option.value === insightName);
            return new Model({
              where: 'Insight',
              lhs: 'insightName',
              id: insightName,
              name: insightModel?.label || insightName,
              link: `/v4/core/insights?insightNames=${insightName}`
            });
          });

          collection.add(insightModels);

          break;
        }
        case 'insightFamilies': {
          const insightFamilyModels = usages[usageKey].map(
            (insightFamilyName) =>
              new Model({
                where: 'Insight Family',
                lhs: 'insightFamily',
                id: '---',
                name: insightFamilyName,
                link: `/v4/core/insights?families=${insightFamilyName}`
              })
          );

          collection.add(insightFamilyModels);

          break;
        }

        case 'synthTest': {
          const testModels = Object.keys(usages[usageKey])
            .map((testId) => {
              const { tests } = $syn;
              const containingTest = tests.modelById[testId];

              if (containingTest) {
                return new Model({
                  where: 'Synthetic Test',
                  lhs: 'alertmanRule',
                  id: testId,
                  associationId: containingTest.get('config.alerting.ktrac_rule_id'),
                  name: containingTest.attributes.display_name,
                  link: `/v4/synthetics/tests/${testId}`
                });
              }

              return null;
            })
            .filter(Boolean);

          collection.add(testModels);

          break;
        }

        case 'policies': {
          const policyModels = Object.keys(usages[usageKey])
            .map((policyId) => {
              const { policyCollection } = $alerting;
              const containingPolicy = policyCollection.modelById[policyId];

              if (containingPolicy) {
                return new Model({
                  where: 'Alert Policy',
                  lhs: 'alertPolicy',
                  id: containingPolicy.id,
                  associationId: containingPolicy.ruleId,
                  name: containingPolicy.get('name'),
                  link: containingPolicy.manageUrl
                });
              }

              return null;
            })
            .filter(Boolean);

          collection.add(policyModels);

          break;
        }

        case 'subpolicies': {
          const subpolicyModels = Object.keys(usages[usageKey])
            .map((tenantId) => {
              const subpolicyIds = usages[usageKey][tenantId].join(', ');
              const containingTenant = $mkp.tenants.get(tenantId);
              if (containingTenant) {
                return new Model({
                  where: 'Tenant Policy',
                  lhs: 'alertPolicy',
                  id: subpolicyIds,
                  name: containingTenant.get('name'),
                  link: `/v4/mkp/tenants/${containingTenant.id}`
                });
              }

              return null;
            })
            .filter(Boolean);

          collection.add(subpolicyModels);

          break;
        }

        // Do nothing, unexpected case
        default:
      }
    });

    return collection;
  }

  render() {
    const { usages, $alerting, $mitigations, asTable, ...rest } = this.props;
    const { policyCollection } = $alerting;
    const { methodsCollection } = $mitigations;
    const isLoading = !policyCollection.hasFetched || !methodsCollection.hasFetched;

    const table = (
      <Card width="100%">
        <NotificationUsageListTable loading={isLoading} usageCollection={this.usageCollection} columns={this.columns} />
      </Card>
    );

    if (asTable) {
      return table;
    }

    return (
      <Dialog
        {...rest}
        title="Notification Channel Usage Detail"
        canEscapeKeyClose
        canClickOutside
        isCloseButtonShown
        width={800}
        height={400}
      >
        <Dialog.Body>{table}</Dialog.Body>
      </Dialog>
    );
  }
}
