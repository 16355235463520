import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import { Classes } from '@blueprintjs/core';

import { Box, Button } from 'core/components';
import { formConsumer, ValidationErrorsOrHelpText } from 'core/form';
import { getDeviceValues } from 'app/util/devices';
import DeviceSelectorDialog from 'app/components/device/DeviceSelectorDialog';

import DeviceSelectorDisplay from './DeviceSelectorDisplay';

@formConsumer
@observer
class DeviceSelector extends Component {
  static defaultProps = {
    allowAllDevices: true,
    autoSubmit: false,
    allDevicesLabel: 'All data sources',

    // If set to false, we allow users to select attributes that are not currently applied to any active devices.
    // Eg, if false, a user can choose a label that has been created but is not applied to any active devices.
    // If true, the user would not be able to see or select that label.
    allowOnlyActiveDeviceAttributes: true,

    // shown in the selector left sidebar "Devices"
    devicesLabel: 'Devices',
    editButtonText: 'Edit Data Sources',
    showEditButton: true,
    showLabels: true,
    showSites: true,
    showTypes: true,
    deviceFilter: () => true,
    /** there's 2 ways to drive this component (mutually exclusive):
     * 1: using string field names for the device related data
     *    this is meant to be used when form fields are on the top level on the form
     * 2: using actual form fields for the device related data
     *    this is meant to be used when form fields are nested inside another field within the form
     */
    useFieldsInsteadOfFieldNames: false,
    deviceTypesFieldName: 'device_types',
    deviceTypesField: null,
    deviceLabelsFieldName: 'device_labels',
    deviceLabelsField: null,
    deviceSitesFieldName: 'device_sites',
    deviceSitesField: null,
    deviceNameFieldName: 'device_name',
    deviceNameField: null,
    allDevicesFieldName: 'all_devices',
    allDevicesField: null,
    readOnly: false,
    required: true
  };

  state = {
    isOpen: false,
    all_devices: false,
    device_types: [],
    device_labels: [],
    device_sites: [],
    device_names: []
  };

  disposers = [];

  componentDidMount() {
    const {
      allDevicesField,
      allDevicesFieldName,
      deviceTypesField,
      deviceTypesFieldName,
      deviceLabelsField,
      deviceLabelsFieldName,
      deviceSitesField,
      deviceSitesFieldName,
      deviceNameField,
      deviceNameFieldName
    } = this.props;

    this.addReaction(allDevicesField || allDevicesFieldName, 'all_devices');
    this.addReaction(deviceTypesField || deviceTypesFieldName, 'device_types');
    this.addReaction(deviceLabelsField || deviceLabelsFieldName, 'device_labels');
    this.addReaction(deviceSitesField || deviceSitesFieldName, 'device_sites');
    this.addReaction(deviceNameField || deviceNameFieldName, 'device_names');
  }

  componentWillUnmount() {
    this.disposers.forEach((disposer) => disposer());
  }

  addReaction(fieldOrFieldName, stateName) {
    const { form, useFieldsInsteadOfFieldNames, showTypes, showSites, showLabels } = this.props;

    if (
      (!showTypes && fieldOrFieldName === 'device_types') ||
      (!showSites && fieldOrFieldName === 'device_sites') ||
      (!showLabels && fieldOrFieldName === 'device_labels')
    ) {
      // skip adding reaction for device_types if it's not shown
    } else {
      this.disposers.push(
        reaction(
          () => (useFieldsInsteadOfFieldNames ? fieldOrFieldName.getValue() : form.getValue(fieldOrFieldName)),
          () =>
            this.setState({
              [stateName]: useFieldsInsteadOfFieldNames ? fieldOrFieldName.getValue() : form.getValue(fieldOrFieldName)
            })
        )
      );
    }
  }

  handleSave = () => {
    const {
      autoSubmit,
      form,
      useFieldsInsteadOfFieldNames,
      allDevicesField,
      allDevicesFieldName,
      deviceTypesField,
      deviceTypesFieldName,
      deviceLabelsField,
      deviceLabelsFieldName,
      deviceSitesField,
      deviceSitesFieldName,
      deviceNameField,
      deviceNameFieldName,
      onSubmit,
      showTypes,
      showLabels,
      showSites
    } = this.props;
    const { all_devices, device_types, device_labels, device_sites, device_names } = this.state;

    this.setState({ isOpen: false });

    if (useFieldsInsteadOfFieldNames) {
      allDevicesField.setValue(all_devices, { validate: false, pristine: false });
      if (showTypes) {
        deviceTypesField.setValue(device_types, { validate: false, pristine: false });
      }
      if (showLabels) {
        deviceLabelsField.setValue(device_labels, { validate: false, pristine: false });
      }
      if (showSites) {
        deviceSitesField.setValue(device_sites, { validate: false, pristine: false });
      }
      deviceNameField.setValue(device_names, { validate: false, pristine: false });
    } else {
      form.setValue(allDevicesFieldName, all_devices, { validate: false, pristine: false });
      if (showTypes) {
        form.setValue(deviceTypesFieldName, device_types, { validate: false, pristine: false });
      }
      if (showLabels) {
        form.setValue(deviceLabelsFieldName, device_labels, { validate: false, pristine: false });
      }
      if (showSites) {
        form.setValue(deviceSitesFieldName, device_sites, { validate: false, pristine: false });
      }
      form.setValue(deviceNameFieldName, device_names, { validate: false, pristine: false });
    }

    form.validate();

    if (autoSubmit && form.dirty && form.validate() && onSubmit) {
      onSubmit();
    }
  };

  handleClose = () => {
    const { onClose } = this.props;

    this.setInternalStateFromFieldState(false);

    if (onClose) {
      onClose();
    }
  };

  setInternalStateFromFieldState = (isOpen = true) => {
    const {
      form,
      useFieldsInsteadOfFieldNames,
      allDevicesField,
      allDevicesFieldName,
      deviceTypesField,
      deviceTypesFieldName,
      deviceLabelsField,
      deviceLabelsFieldName,
      deviceSitesField,
      deviceSitesFieldName,
      deviceNameField,
      deviceNameFieldName,
      showTypes,
      showLabels,
      showSites
    } = this.props;

    if (useFieldsInsteadOfFieldNames) {
      this.setState((prevState) => ({
        isOpen,
        all_devices: allDevicesField.getValue(),
        device_types: showTypes ? deviceTypesField.getValue() : prevState.device_types,
        device_labels: showLabels ? deviceLabelsField.getValue() : prevState.device_labels,
        device_sites: showSites ? deviceSitesField.getValue() : prevState.device_sites,
        device_names: deviceNameField.getValue()
      }));
    } else {
      this.setState((prevState) => ({
        isOpen,
        all_devices: form.getValue(allDevicesFieldName),
        device_types: showTypes ? form.getValue(deviceTypesFieldName) : prevState.device_types,
        device_labels: showLabels ? form.getValue(deviceLabelsFieldName) : prevState.device_labels,
        device_sites: showSites ? form.getValue(deviceSitesFieldName) : prevState.device_sites,
        device_names: form.getValue(deviceNameFieldName)
      }));
    }
  };

  handleEdit = () => {
    this.setInternalStateFromFieldState(true);
  };

  handleAddAll = (options, fieldName) => {
    const { [fieldName]: values } = this.state;

    // filter out duplicates found in current value list
    const optionValues = options.filter((option) => !values.includes(option.value)).map((option) => option.value);

    this.setState({ [fieldName]: values.concat(optionValues), all_devices: false });
  };

  handleAdd = (option, fieldName) => {
    const { [fieldName]: values } = this.state;
    if (!values.includes(option.value)) {
      this.setState({ [fieldName]: values.concat(option.value) });
    }
  };

  handleChange = (value, fieldName) => {
    const internalFieldName = this.getStateNameFromFieldName(fieldName) || fieldName;
    this.setState({ [internalFieldName]: value });
  };

  getValuesWithOptionRemoved(option, fieldName) {
    const { [fieldName]: values } = this.state;
    const index = values.findIndex((id) => id === option.value);

    if (index !== -1) {
      return [...values.slice(0, index), ...values.slice(index + 1)];
    }

    return values;
  }

  getField(fieldStateName) {
    const {
      form,
      useFieldsInsteadOfFieldNames,
      allDevicesField,
      allDevicesFieldName,
      deviceTypesField,
      deviceTypesFieldName,
      deviceLabelsField,
      deviceLabelsFieldName,
      deviceSitesField,
      deviceSitesFieldName,
      deviceNameField,
      deviceNameFieldName
    } = this.props;
    let fieldName = fieldStateName;

    if (fieldStateName === 'all_devices') {
      fieldName = allDevicesFieldName;

      if (useFieldsInsteadOfFieldNames) {
        return allDevicesField;
      }
    } else if (fieldStateName === 'device_types') {
      fieldName = deviceTypesFieldName;

      if (useFieldsInsteadOfFieldNames) {
        return deviceTypesField;
      }
    } else if (fieldStateName === 'device_labels') {
      fieldName = deviceLabelsFieldName;

      if (useFieldsInsteadOfFieldNames) {
        return deviceLabelsField;
      }
    } else if (fieldStateName === 'device_sites') {
      fieldName = deviceSitesFieldName;

      if (useFieldsInsteadOfFieldNames) {
        return deviceSitesField;
      }
    } else if (fieldStateName === 'device_names') {
      fieldName = deviceNameFieldName;

      if (useFieldsInsteadOfFieldNames) {
        return deviceNameField;
      }
    }

    return form.getField(fieldName);
  }

  getStateNameFromFieldName(fieldName) {
    const {
      allDevicesFieldName,
      deviceTypesFieldName,
      deviceLabelsFieldName,
      deviceSitesFieldName,
      deviceNameFieldName
    } = this.props;

    if (fieldName === allDevicesFieldName) {
      return 'all_devices';
    }
    if (fieldName === deviceTypesFieldName) {
      return 'device_types';
    }
    if (fieldName === deviceLabelsFieldName) {
      return 'device_labels';
    }
    if (fieldName === deviceSitesFieldName) {
      return 'device_sites';
    }
    if (fieldName === deviceNameFieldName) {
      return 'device_name';
    }

    return undefined;
  }

  handleRemoveAndSubmit = (option, fieldName) => {
    const { autoSubmit, form, onSubmit } = this.props;
    const values = this.getValuesWithOptionRemoved(option, fieldName);
    const field = this.getField(fieldName);

    field.setValue(values, { pristine: false });

    if (autoSubmit && form.dirty && form.validate() && onSubmit) {
      onSubmit();
    }
  };

  handleRemove = (option, fieldName) => {
    this.setState({ [fieldName]: this.getValuesWithOptionRemoved(option, fieldName) });
  };

  /*
    Handles reverse lookups where applicable, since we're using IDs for values
   */
  get selectedValues() {
    const { all_devices, device_types, device_labels, device_sites, device_names } = this.state;
    const { allowOnlyActiveDeviceAttributes } = this.props;
    return getDeviceValues({
      all_devices,
      device_types,
      device_labels,
      device_sites,
      device_name: device_names,
      allowOnlyActiveDeviceAttributes
    });
  }

  render() {
    const {
      form,
      useFieldsInsteadOfFieldNames,
      editButtonText,
      showEditButton,
      allDevicesLabel,
      allDevicesField: allDevicesFieldProp,
      allDevicesFieldName,
      deviceTypesField,
      deviceTypesFieldName,
      deviceLabelsField,
      deviceLabelsFieldName,
      deviceSitesField,
      deviceSitesFieldName,
      deviceNameField,
      deviceNameFieldName,
      allowAllDevices,
      disabled,
      readOnly,
      noRemove,
      showTypes,
      showLabels,
      showSites,
      small = true,
      allowOnlyActiveDeviceAttributes
    } = this.props;
    const { isOpen } = this.state;

    const allDevicesField = allDevicesFieldProp || form.getField(allDevicesFieldName);

    return (
      <div>
        <DeviceSelectorDisplay
          useFieldsInsteadOfFieldNames={useFieldsInsteadOfFieldNames}
          allDevicesLabel={allDevicesLabel}
          allDevicesField={allDevicesField}
          allDevicesFieldName={allDevicesFieldName}
          deviceTypesField={deviceTypesField}
          deviceTypesFieldName={deviceTypesFieldName}
          deviceLabelsField={deviceLabelsField}
          deviceLabelsFieldName={deviceLabelsFieldName}
          deviceSitesField={deviceSitesField}
          deviceSitesFieldName={deviceSitesFieldName}
          deviceNameField={deviceNameField}
          deviceNameFieldName={deviceNameFieldName}
          showTypes={showTypes}
          showLabels={showLabels}
          showSites={showSites}
          onRemove={this.handleRemoveAndSubmit}
          disabled={disabled}
          readOnly={readOnly || noRemove}
          allowOnlyActiveDeviceAttributes={allowOnlyActiveDeviceAttributes}
        />
        {allowAllDevices && allDevicesField.hasError && (
          <Box className={Classes.FORM_GROUP} mb="4px" mt={0}>
            <ValidationErrorsOrHelpText field={allDevicesField} small />
          </Box>
        )}
        {!readOnly && showEditButton && (
          <Button
            icon="edit"
            small={small}
            disabled={disabled}
            text={editButtonText}
            onClick={this.handleEdit}
            mt={1}
          />
        )}
        {!readOnly && (
          <DeviceSelectorDialog
            {...this.props}
            isOpen={isOpen}
            onAdd={this.handleAdd}
            onAddAll={this.handleAddAll}
            onCancel={this.handleClose}
            onChange={this.handleChange}
            onRemove={this.handleRemove}
            onSave={this.handleSave}
            selectedValues={this.selectedValues}
          />
        )}
      </div>
    );
  }
}

export default DeviceSelector;
