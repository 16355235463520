const { AZURE_ENTITY_TYPES } = require('../constants');

const {
  VNET,
  NAT_GATEWAY,
  VNET_GATEWAY,
  LOCAL_NETWORK_GATEWAY,
  LOAD_BALANCER,
  NETWORK_INTERFACE,
  VIRTUAL_MACHINE,
  EXPRESS_ROUTE_CIRCUIT,
  APPLICATION_GATEWAY,
  PUBLIC_IP_ADDRESS,
  FIREWALL,
  VIRTUAL_HUB,
  SUBNET
} = AZURE_ENTITY_TYPES;

const RESOURCE_GRAPH_VNET = 'microsoft.network/virtualnetworks';
const RESOURCE_GRAPH_VIRTUAL_NAT_GATEWAY = 'microsoft.network/natgateways';
const RESOURCE_CONTAINER_SUBSCRIPTIONS = 'microsoft.resources/subscriptions';
const RESOURCE_GRAPH_LOCAL_NETWORK_GATEWAY = 'microsoft.network/localnetworkgateways';
const RESOURCE_GRAPH_VIRTUAL_NETWORK_GATEWAY = 'microsoft.network/virtualnetworkgateways';
const RESOURCE_GRAPH_LOAD_BALANCER = 'microsoft.network/loadbalancers';
const RESOURCE_GRAPH_NETWORK_INTERFACE = 'microsoft.network/networkinterfaces';
const RESOURCE_GRAPH_VIRTUAL_MACHINE = 'microsoft.compute/virtualmachines';
const RESOURCE_GRAPH_EXPRESS_ROUTE_CIRCUIT = 'microsoft.network/expressroutecircuits';
const RESOURCE_GRAPH_APPLICATION_GATEWAY = 'microsoft.network/applicationgateways';
const RESOURCE_GRAPH_PUBLIC_IP_ADDRESS = 'microsoft.network/publicipaddresses';
const RESOURCE_GRAPH_FIREWALL = 'microsoft.network/azurefirewalls';
const RESOURCE_GRAPH_VIRTUAL_HUB = 'microsoft.network/virtualhubs';
const RESOURCE_GRAPH_SUBNET = 'microsoft.network/virtualnetworks/subnets';

const convertResourceGraphTypeToTopologyType = (resourceGraphType) => {
  switch (resourceGraphType.toLowerCase()) {
    case RESOURCE_GRAPH_VNET:
      return VNET;
    case RESOURCE_GRAPH_VIRTUAL_NAT_GATEWAY:
      return NAT_GATEWAY;
    case RESOURCE_GRAPH_LOCAL_NETWORK_GATEWAY:
      return LOCAL_NETWORK_GATEWAY;
    case RESOURCE_GRAPH_VIRTUAL_NETWORK_GATEWAY:
      return VNET_GATEWAY;
    case RESOURCE_GRAPH_LOAD_BALANCER:
      return LOAD_BALANCER;
    case RESOURCE_GRAPH_NETWORK_INTERFACE:
      return NETWORK_INTERFACE;
    case RESOURCE_GRAPH_VIRTUAL_MACHINE:
      return VIRTUAL_MACHINE;
    case RESOURCE_GRAPH_EXPRESS_ROUTE_CIRCUIT:
      return EXPRESS_ROUTE_CIRCUIT;
    case RESOURCE_GRAPH_APPLICATION_GATEWAY:
      return APPLICATION_GATEWAY;
    case RESOURCE_GRAPH_PUBLIC_IP_ADDRESS:
      return PUBLIC_IP_ADDRESS;
    case RESOURCE_GRAPH_FIREWALL:
      return FIREWALL;
    case RESOURCE_GRAPH_VIRTUAL_HUB:
      return VIRTUAL_HUB;
    case RESOURCE_GRAPH_SUBNET:
      return SUBNET;
    default:
      throw new Error(`Unknown resource graph type ${resourceGraphType}`);
  }
};

module.exports = {
  RESOURCE_GRAPH_VNET,
  RESOURCE_CONTAINER_SUBSCRIPTIONS,
  RESOURCE_GRAPH_VIRTUAL_NAT_GATEWAY,
  RESOURCE_GRAPH_LOCAL_NETWORK_GATEWAY,
  RESOURCE_GRAPH_VIRTUAL_NETWORK_GATEWAY,
  RESOURCE_GRAPH_LOAD_BALANCER,
  RESOURCE_GRAPH_NETWORK_INTERFACE,
  RESOURCE_GRAPH_VIRTUAL_MACHINE,
  RESOURCE_GRAPH_EXPRESS_ROUTE_CIRCUIT,
  RESOURCE_GRAPH_APPLICATION_GATEWAY,
  RESOURCE_GRAPH_PUBLIC_IP_ADDRESS,
  RESOURCE_GRAPH_FIREWALL,
  RESOURCE_GRAPH_VIRTUAL_HUB,
  RESOURCE_GRAPH_SUBNET,
  convertResourceGraphTypeToTopologyType
};
