import React, { useEffect, useState } from 'react';

import { Link } from 'core/components';
import { getHashForObject } from 'app/stores/query/urlHash';

const SyntheticsAgentsViewLink = ({ tabId, children, ...rest }) => {
  const [hash, setHash] = useState(null);

  useEffect(() => {
    if (tabId) {
      getHashForObject({
        hashedSelectedTabId: tabId
      }).then((_hash) => {
        setHash(_hash);
      });
    }
  }, [tabId]);

  return (
    <Link to={`/v4/synthetics/agents${hash ? `/?q=${hash}` : ''}`} {...rest}>
      {children}
    </Link>
  );
};

export default SyntheticsAgentsViewLink;
