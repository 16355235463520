// Vendor Imports
const { get, round } = require('lodash');

// Local Imports
const {
  AGENT_TYPES,
  TEST_TYPES,
  DNS_TEST_TYPES,
  AGENT_MATRIX_TEST_TYPES,
  USER_DEFINED_TEST_TYPES,
  FLOW_BASED_TEST_TYPES,
  URL_DEFINED_TEST_TYPES,
  PRIVATE_AGENT_COST,
  GLOBAL_AGENT_COST,
  PRIVATE_AGENT_HTTP_COST,
  GLOBAL_AGENT_HTTP_COST
} = require('./constants');

/**
 * @private Returns agent type from agent model or request params
 * @param {object} agent
 * @returns {string} agent_type
 */
const getAgentType = (agent) => (agent?.get ? agent.get('agent_type') : agent?.agent_type);

/**
 * @private Retuns cost multiplier for tests based on agent types, test type, and test frequency (period)
 * @param {object} options ({ agent_type, test_type, period })
 * @param {string} options.agent_type
 * @param {string} options.test_type
 * @param {(number|string)} options.period
 * @returns {number}
 */
const getAgentCostMultiplier = ({ agent_type, test_type, period }) => {
  if (agent_type === AGENT_TYPES.GLOBAL && !DNS_TEST_TYPES.includes(test_type) && Number(period) <= 30) {
    return Number(period) <= 5 ? 4.0 : 2.0;
  }
  if (Number(period) <= 60) {
    return 1.0;
  }
  return 60.0 / Number(period);
};

/**
 * @public Returns cost of agent per minute
 * @param {object} options ({ agent_type, test_type, period })
 * @param {string} options.agent_type
 * @param {string} options.test_type
 * @param {(number|string)} options.period
 * @returns {number}
 */
const getAgentCostPerMin = ({ agent_type, test_type, period }) => {
  const costMultiplier = getAgentCostMultiplier({ agent_type, test_type, period });
  return (agent_type === AGENT_TYPES.PRIVATE ? PRIVATE_AGENT_COST : GLOBAL_AGENT_COST) * costMultiplier;
};

/**
 * @public Returns Http test cost per minute based on agent data and config
 * @param {object} options
 * @param {array} options.agentData
 * @param {object} options.config
 * @returns {number} credits
 */
const getHttpCostPerMin = ({ agentData = [], config }) =>
  agentData?.reduce((acc, agent) => {
    const agent_type = getAgentType(agent);
    const expiry = get(config, 'expiry');
    const period = get(config, 'period');
    const costMultiplier = (60.0 / Number(period)) * (Number(expiry) / 1000.0);
    const agentCost = agent_type === AGENT_TYPES.PRIVATE ? PRIVATE_AGENT_HTTP_COST : GLOBAL_AGENT_HTTP_COST;
    return acc + agentCost * costMultiplier;
  }, 0);

/**
 * @private Returns credit cost for User Defined or DNS Tests based on agents and config
 * @param {*} options ({agentData, test_type, config, period})
 * @param {array} options.agentData ([AgentCollection])
 * @param {string} options.test_type
 * @param {object} options.config
 * @param {(number|string)} options.period
 * @param {object} options.reciprocalAgent
 * @returns {number} credits
 */
const getUserDefinedOrDnsTestCreditsPerMin = ({ agentData = [], test_type, config, period, reciprocalAgent }) => {
  const numOfTargets = DNS_TEST_TYPES.includes(test_type)
    ? `${get(config, 'servers', '')}`.split(',').length
    : `${get(config, 'target.value', '')}`.split(',').length;

  const multiplier = numOfTargets > 0 ? numOfTargets : 1;
  const total_credits = agentData?.reduce(
    (acc, agent) => {
      const agent_type = getAgentType(agent);
      acc.total += multiplier * getAgentCostPerMin({ agent_type, test_type, period });
      return acc;
    },
    { total: 0 }
  );
  // we can't just double credits for reciprocal a2a tests; every agent:target has to be evaluated separately
  if (reciprocalAgent) {
    const additional_credits =
      agentData.length * getAgentCostPerMin({ agent_type: getAgentType(reciprocalAgent), test_type, period });
    total_credits.total += additional_credits;
  }
  return total_credits;
};

/**
 * @private Returns credit cost per min for Flow Based Tests based on agents and config
 * @param {*} options ({agentData, test_type, config, period})
 * @param {array} options.agentData ([AgentCollection])
 * @param {string} options.test_type
 * @param {object} options.config
 * @param {(number|string)} options.period
 * @returns {number} credits
 */
const getFlowBasedTestCreditsPerMin = ({ agentData = [], test_type, config, period }) => {
  const maxProviders = config.max_tasks || 10; // Max number of providers to track autonomously
  const maxIpTarget = config.max_targets || 20; // Max number of IP targets per Provider
  return agentData?.reduce(
    (acc, agent) => {
      const agent_type = getAgentType(agent);
      acc.total += getAgentCostPerMin({ agent_type, test_type, period }) * (maxProviders * maxIpTarget);
      return acc;
    },
    { total: 0 }
  );
};

/**
 * @private Returns credit costs per min for Agent Matrix Tests base on agents and config
 * @param {*} options ({agentData, test_type, period})
 * @param {array} options.agentData ([AgentCollection])
 * @param {string} options.test_type
 * @param {(number|string)} options.period
 * @returns {number} credits
 */
const getAgentMatrixTestCreditsPerMin = ({ agentData = [], test_type, period }) => {
  const numAgents = agentData?.length;
  let numTargets = 0;
  if (numAgents) {
    numTargets = numAgents === 1 ? 1 : numAgents - 1; // allow for one agent testing itself?
  }
  return agentData?.reduce(
    (acc, agent) => {
      const agent_type = getAgentType(agent);
      acc.total += getAgentCostPerMin({ agent_type, test_type, period }) * numTargets;
      return acc;
    },
    { total: 0 }
  );
};

/**
 * @private Retuns credit cost per min for Ping and Knock based on agent data tasks
 * @param {*} options ({agentData, test_type, config, period})
 * @param {array} options.agentData ([AgentCollection])
 * @param {string} options.test_type
 * @param {object} options.config
 * @param {(number|string)} options.period
 * @returns {number} credits
 */
const getPingCreditsPerMin = ({ agentData = [], test_type, config, period }) => {
  const { tasks } = config;
  if (tasks.includes('ping') || tasks.includes('knock')) {
    return agentData?.reduce((acc, agent) => {
      const agent_type = getAgentType(agent);
      return acc + getAgentCostPerMin({ agent_type, test_type, period });
    }, 0);
  }
  return 0.0;
};

/**
 * @private Retuns credit cost per min for URL Defined Tests based on agents and config
 * @param {*} options ({agentData, test_type, config, period})
 * @param {array} options.agentData ([AgentCollection])
 * @param {string} options.test_type
 * @param {object} options.config
 * @param {(number|string)} options.period
 * @returns {number} credits
 */
const getUrlDefinedTestCreditsPerMin = ({ agentData = [], test_type, config, period }) => {
  const httpTestCreditsPerMin = getHttpCostPerMin({ agentData, config });
  const pingCreditsPerMin = getPingCreditsPerMin({ agentData, test_type, config, period });

  return {
    items: [
      { label: 'HTTP Testing', value: round(httpTestCreditsPerMin, 2) },
      { label: 'Network Testing', value: round(pingCreditsPerMin, 2) }
    ].filter((item) => item.value > 0), // an itemized breakdown will display if there are 2 or more items
    total: pingCreditsPerMin + httpTestCreditsPerMin
  };
};

/**
 * @private Retuns credit cost per min for URL Defined Tests based on agents and config
 * @param {*} options ({agentData, test_type, config, period})
 * @param {array} options.agentData ([AgentCollection])
 * @param {string} options.test_type
 * @param {object} options.config
 * @param {(number|string)} options.period
 * @returns {number} credits
 */
const getDnsTestCreditsPerMin = ({ agentData = [], test_type, config, period }) => {
  const dnsTestCreditsPerMin = getUserDefinedOrDnsTestCreditsPerMin({ agentData, test_type, config, period }).total;
  const pingCreditsPerMin = getPingCreditsPerMin({ agentData, test_type, config, period });

  return {
    items: [
      { label: 'DNS Testing', value: round(dnsTestCreditsPerMin, 2) },
      { label: 'Network Testing', value: round(pingCreditsPerMin, 2) }
    ].filter((item) => item.value > 0), // an itemized breakdown will display if there are 2 or more items
    total: pingCreditsPerMin + dnsTestCreditsPerMin
  };
};

/**
 * @private Retuns credit cost per min for Bgp Monitor Tests based on config
 * @param {*} options ({config})
 * @param {object} options.config
 * @returns {number} credits
 */
const getBgpMonitorTestCreditsPerMin = ({ config }) => {
  // .53 credits per minutes (roughly 8/15) * num of prefixes
  const multiplier = config.bgp && typeof config.bgp.prefix === 'string' ? config.bgp.prefix.split(',').length : 1;
  return { total: 0.53 * multiplier };
};

/**
 * @public Returns credit cost per minute for test type based on config
 * @param {object} options ({agentData, testType, config})
 * @param {array} options.agentData ([AgentCollection])
 * @param {string} options.test_type
 * @param {object} options.config
 * @param {object} options.reciprocalAgent
 * @retuns {Number}
 */
const getCreditCostPerMin = ({ agentData, test_type, config, reciprocalAgent }) => {
  const period = DNS_TEST_TYPES.concat(URL_DEFINED_TEST_TYPES).includes(test_type)
    ? config.period
    : get(config, 'ping.period');

  let testCreditsPerMin = 0;
  if (USER_DEFINED_TEST_TYPES.includes(test_type)) {
    testCreditsPerMin = getUserDefinedOrDnsTestCreditsPerMin({ agentData, test_type, config, period, reciprocalAgent });
  } else if (DNS_TEST_TYPES.includes(test_type)) {
    testCreditsPerMin = getDnsTestCreditsPerMin({ agentData, test_type, config, period });
  } else if (FLOW_BASED_TEST_TYPES.includes(test_type)) {
    testCreditsPerMin = getFlowBasedTestCreditsPerMin({ agentData, test_type, config, period });
  } else if (AGENT_MATRIX_TEST_TYPES.includes(test_type)) {
    testCreditsPerMin = getAgentMatrixTestCreditsPerMin({ agentData, test_type, config, period });
  } else if (URL_DEFINED_TEST_TYPES.includes(test_type)) {
    testCreditsPerMin = getUrlDefinedTestCreditsPerMin({ agentData, test_type, config, period });
  } else if (test_type === TEST_TYPES.BGP_MONITOR) {
    testCreditsPerMin = getBgpMonitorTestCreditsPerMin({ config });
  } else {
    console.error('UNKNOWN TEST TYPE IN TEST CREDIT CALCULATION', test_type);
  }

  return testCreditsPerMin;
};

module.exports = { getAgentCostPerMin, getHttpCostPerMin, getCreditCostPerMin };
