import React from 'react';
import { observer } from 'mobx-react';

import * as Sentry from '@sentry/react';
import AbstractErrorBoundary from 'core/components/AbstractErrorBoundary';
import ErrorMessage from 'core/components/ErrorMessage';

export default function getErrorBoundry(componentName) {
  const boundaryClass = class ErrorBoundary extends AbstractErrorBoundary(componentName) {
    static defaultProps = {
      fullScreen: false
    };

    render() {
      const { error, hasOutdatedBundle, errorReportSuccess } = this.state;
      const { children, fullScreen } = this.props;

      if (error) {
        Sentry.captureException(error);
        return (
          <ErrorMessage
            fullScreen={fullScreen}
            showDescription={errorReportSuccess}
            hasOutdatedBundle={hasOutdatedBundle}
          />
        );
      }

      // when there's not an error, render children untouched
      return children;
    }
  };

  return observer(boundaryClass);
}
