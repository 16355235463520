import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('BgpCommunityAggregate'))
class BgpCommunityAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="BGP Community"
        resultsKey="BGP Community"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'BGP Community',
          aggregateFilters: [
            { metric: ['src_bgp_community', 'dst_bgp_community'] },
            { metric: ['src_bgp_community'] },
            { metric: ['dst_bgp_community'] },
            { metric: ['src_bgp_community', 'dst_bgp_community'] },
            { metric: ['src_bgp_community', 'dst_bgp_community'] }
          ]
        }}
        chartProps={{
          metrics: {
            Internal: ['dst_bgp_community', 'src_bgp_community'],
            Inbound: 'src_bgp_community',
            Outbound: 'dst_bgp_community'
          },
          topxMetricField: 'src|dst_bgp_community'
        }}
      />
    );
  }
}

export default BgpCommunityAggregate;
