import * as api from '@opentelemetry/api';
import { CompositePropagator, W3CBaggagePropagator, W3CTraceContextPropagator } from '@opentelemetry/core';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { browserDetector, detectResourcesSync, Resource } from '@opentelemetry/resources';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { B3InjectEncoding, B3Propagator } from '@opentelemetry/propagator-b3';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { generateSpoofUrl } from 'app/util/generateSpoofUrl';

let initialized = false;
let initializing;
let myTracer;
let $auth;

function getAuthSpanAttributes() {
  return initialized
    ? {
        page_url: window.location.href,
        spoof_url: generateSpoofUrl($auth),
        spoofing: $auth.isSpoofed || false,
        spoof_user_id: $auth.getActiveUserProperty('chfAdmin.id') || 0
      }
    : {};
}

const _initialize = async (authStore) => {
  const brResources = detectResourcesSync({ detectors: [browserDetector] });
  $auth = authStore;
  const collectorOptions = {
    url: '/api/ui/otel/v1/traces',
    headers: {
      Accept: '*/*',
      'X-CSRF-Token': $auth.csrfToken,
      'Content-Type': 'application/octet-stream'
    }
  };

  const serviceName = 'ui-app-browser';

  const provider = new WebTracerProvider({
    resource: new Resource({
      'service.name': serviceName
    }).merge(brResources)
  });

  provider.addSpanProcessor(new BatchSpanProcessor(new OTLPTraceExporter(collectorOptions)));
  provider.register({
    contextManager: new ZoneContextManager(),
    propagator: new CompositePropagator({
      propagators: [
        new B3Propagator({ injectEncoding: B3InjectEncoding.MULTI_HEADER }),
        new W3CTraceContextPropagator(),
        new W3CBaggagePropagator()
      ]
    })
  });

  const InstrConfig = {
    ignoreUrls: ['/hc', '/api/ui/hc-full', '/api/ui//hc-full', '/api/ui/metrics/browser'],
    applyCustomAttributesOnSpan: (span) => {
      span.setAttributes(getAuthSpanAttributes());
    }
  };

  registerInstrumentations({
    instrumentations: [new XMLHttpRequestInstrumentation(InstrConfig), new FetchInstrumentation(InstrConfig)]
  });

  initialized = true;
  myTracer = api.trace.getTracer(serviceName);
};

export function initializeOpenTelemetry(authStore) {
  if (!initializing) {
    initializing = _initialize(authStore);
  }
  return initializing;
}

export const noopSpan = {
  setAttributes: () => {},
  setAttribute: () => {},
  setStatus: () => {},
  end: () => {},
  addEvent: () => {},
  isRecording: () => false,
  _spanContext: {}
};
export function getActiveSpan() {
  return initialized ? api.trace.getActiveSpan() : noopSpan;
}

export function getSpanMeta(currentSpan = {}) {
  const ctext = currentSpan._spanContext || {};
  return {
    name: currentSpan.name,
    traceId: ctext.traceId,
    spanId: ctext.spanId,
    status: currentSpan.status,
    isRecording: currentSpan.isRecording()
  };
}

export function injectB3(b3Object = {}) {
  api.propagation.inject(api.context.active(), b3Object);
  return b3Object;
}

export function activateSpan(fn, name, attributes = {}, b3 = {}) {
  if (!initialized) {
    return fn;
  }
  return (...args) =>
    myTracer.startActiveSpan(
      name || fn.name,
      {
        kind: api.SpanKind.CLIENT,
        attributes: {
          ...attributes,
          ...getAuthSpanAttributes()
        },
        startTime: Date.now()
      },
      api.propagation.extract(api.context.active(), b3),
      (currentSpan) =>
        Promise.resolve(fn(...args)).finally(() => {
          if (currentSpan.isRecording()) {
            currentSpan.end();
          }
        })
    );
}
