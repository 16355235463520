function filterGroupHasFilters(filterGroup) {
  const { filters, saved_filters, filterGroups } = filterGroup;
  return (
    (filters && filters.length > 0) ||
    (saved_filters && saved_filters.length > 0) ||
    (filterGroups && filterGroups.length > 0 && filterGroups.some((group) => filterGroupHasFilters(group)))
  );
}

export default function hasFilters(filters) {
  const { filterGroups } = filters;
  return filterGroups && filterGroups.length > 0 && filterGroups.some((group) => filterGroupHasFilters(group));
}
