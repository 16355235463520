const FILTER_CONNECTORS = Object.freeze({
  ANY: 'any',
  ALL: 'all'
});

const FILTER_OPERATORS = Object.freeze({
  EQUALS: 'equals', // =
  NOT_EQUALS: 'notEquals', // <>
  BITWISE_AND: 'bitwiseAnd', // &
  BITWISE_OR: 'bitwiseOr', // |
  LESS_THAN: 'lessThan', // <
  GREATER_THAN: 'greaterThan', // >
  LESS_THAN_OR_EQUALS: 'lessThanOrEquals', // <=
  GREATER_THAN_OR_EQUALS: 'greaterThanOrEquals', // >=
  NOT_CONTAINS: 'notContains', // NOT ILIKE
  CONTAINS: 'contains', // ILIKE
  NOT_CONTAINS_RE: 'notContainsRe', // !~
  CONTAINS_RE: 'containsRe', // ~
  NOT_CONTAINS_RE_STAR: 'notContainsReStar', // !~*
  CONTAINS_RE_STAR: 'containsReStar', // ~*
  BITWISE_ANY: 'bitwiseAny', // BIT_ANY
  BITWISE_NONE: 'bitwiseNone', // BIT_NONE
  BITWISE_ALL: 'bitwiseAll', // BIT_ALL
  BITWISE_NOT_ALL: 'bitwiseNotALl' // BIT_NOT_ALL
});

const CONDITION_CONNECTORS = Object.freeze({
  ANY: 'CONDITION_CONNECTOR_ANY',
  ALL: 'CONDITION_CONNECTOR_ALL'
});

const CONDITION_OPERATORS = Object.freeze({
  EQUALS: 'CONDITION_OPERATOR_EQUALS',
  LESS_THAN: 'CONDITION_OPERATOR_LESS_THAN',
  GREATER_THAN: 'CONDITION_OPERATOR_GREATER_THAN',
  LESS_THAN_OR_EQUALS: 'CONDITION_OPERATOR_LESS_THAN_OR_EQUALS',
  GREATER_THAN_OR_EQUALS: 'CONDITION_OPERATOR_GREATER_THAN_OR_EQUALS'
});

/**
 * @typedef {T[keyof T]} Enum<T>
 * @template T
 *
 * @typedef {Enum<FILTER_OPERATORS>} FilterOperator
 */

module.exports = {
  FILTER_CONNECTORS,
  FILTER_OPERATORS,
  CONDITION_CONNECTORS,
  CONDITION_OPERATORS
};
