import { baseFields } from './baseFields';
import { pingFields } from './pingFields';
import { flowBaseFields } from './flowBaseFields';

const countryOptions = {
  name: 'Country Test'
};

const countryFields = {
  ...baseFields,
  ...pingFields,
  ...flowBaseFields
};

export { countryOptions, countryFields };
