import { Classes, Position } from '@blueprintjs/core';
import Map from 'app/components/map';
import { greekIt } from 'app/util/utils';
import { Box, Button, Card, Flex, FlexColumn, Icon, Tab, Tabs, Tag, Text, Tooltip } from 'core/components';
import { CELL_TYPES, VirtualizedTable } from 'core/components/table';
import { Field, formConsumer, InputGroup, MultiSelect, Select } from 'core/form';
import { debounce, throttle } from 'lodash';
import { computed } from 'mobx';
import { inject } from 'mobx-react';
import { rgba } from 'polished';
import React, { Component } from 'react';
import { AutoSizer } from 'react-virtualized';
import { withTheme } from 'styled-components';
import PeeringExchangeCollection from './PeeringExchangeCollection';
import PeeringFacilityCollection from './PeeringFacilityCollection';
import { getExchangeIcon, getFacilityIcon } from './PeeringMapIcons';

@formConsumer
@withTheme
@inject('$lookups', '$query')
export default class PeeringMap extends Component {
  state = {
    mapMarkers: [],
    facilities: new PeeringFacilityCollection(),
    exchanges: new PeeringExchangeCollection(),
    expandedExchange: null
  };

  constructor(props) {
    super(props);
    const { facilities, exchanges } = props;
    this.state.facilities = new PeeringFacilityCollection(facilities || []);
    this.state.exchanges = new PeeringExchangeCollection(exchanges || []);
  }

  componentDidMount() {
    this.computeMapMarkers();
    // this.runPeeringQuery();
  }

  get facilitiesColumns() {
    return [
      {
        name: 'common',
        label: 'Common',
        width: 83,
        align: 'center',
        renderer: ({ value }) => (value ? <Icon icon="tick" color="primary" /> : null),
        title: ({ value }) => (value ? 'Facility in common' : null)
      },
      {
        name: 'name',
        label: 'Facility'
      },
      {
        name: 'org_name',
        label: 'Organization',
        flexBasis: 75
      },
      {
        name: 'address',
        computed: true,
        label: 'Address',
        flexBasis: 80
      },
      {
        name: 'city',
        label: 'City',
        flexBasis: 30,
        minWidth: 110
      },
      {
        name: 'state',
        label: 'State / Region',
        flexBasis: 30,
        minWidth: 110
      },
      {
        name: 'country',
        label: 'Country',
        width: 75
      },
      {
        name: 'net_count',
        label: 'Networks',
        width: 85,
        renderer: ({ value }) => <Tag minimal>{value}</Tag>
      }
    ];
  }

  get exchangesColumns() {
    const { theme } = this.props;
    const { expandedExchange } = this.state;

    return [
      {
        key: 'expand',
        type: CELL_TYPES.ACTION,
        actions: [
          (model) => (
            <Button
              icon={expandedExchange ? 'chevron-down' : 'chevron-right'}
              title={expandedExchange ? 'Collapse' : 'Expand'}
              small
              minimal
              onClick={
                expandedExchange ? () => this.handleCollapseExchange(model) : () => this.handleExpandExchange(model)
              }
            />
          )
        ],
        padding: '0 0 0 6px',
        width: 30
      },
      {
        name: 'common',
        label: 'Common',
        width: 83,
        align: 'center',
        renderer: ({ model, value: common }) => {
          const numFacilities = model.facilities.common.length;

          if (common || numFacilities > 0) {
            return (
              <Tooltip
                content={
                  common
                    ? 'Exchange in common with network'
                    : `${numFacilities} location${numFacilities === 1 ? '' : 's'} in common with exchange`
                }
                position={Position.TOP}
              >
                <Icon icon="tick" color={common ? 'primary' : rgba(theme.colors.primary, 0.35)} />
              </Tooltip>
            );
          }

          return null;
        },
        title: () => null
      },
      {
        name: 'name',
        label: 'Exchange Point'
      },
      {
        name: 'speed',
        computed: true,
        label: 'Speed',
        width: 80,
        renderer: ({ value }) =>
          value.map((speed) => <div key={speed}>{greekIt(speed * 10 ** 6, { fix: 0 }).displayFull}</div>),
        title: ({ value }) => value.map((speed) => greekIt(speed * 10 ** 6, { fix: 0 }).displayFull).join(', ')
      },
      {
        name: 'ipaddr',
        label: 'IP Address',
        minWidth: 250,
        renderer: ({ model }) => (
          <>
            <Text as="div" ellipsis>
              {model.lans
                .map((lan) => lan.ipaddr4)
                .filter(Boolean)
                .join(', ')}
            </Text>
            <Text as="div" ellipsis>
              {model.lans
                .map((lan) => lan.ipaddr6)
                .filter(Boolean)
                .join(', ')}
            </Text>
          </>
        ),
        title: ({ model }) =>
          model.lans.map((lan) => [lan.ipaddr4, lan.ipaddr6].filter(Boolean).join('\n')).join('\n\n')
      },
      {
        name: 'city',
        label: 'City',
        flexBasis: 30,
        minWidth: 110
      },
      {
        name: 'country',
        label: 'Country',
        width: 75
      },
      {
        name: 'fac_count',
        label: 'Facilities',
        width: 81,
        renderer: ({ value }) => <Tag minimal>{value}</Tag>
      },
      {
        name: 'net_count',
        label: 'Networks',
        width: 85,
        renderer: ({ value }) => <Tag minimal>{value}</Tag>
      }
    ];
  }

  @computed
  get countries() {
    const { facilities, exchanges } = this.state;
    const countries = new Set();

    [facilities, exchanges].forEach((collection) => {
      collection.unfiltered.forEach((model) => {
        const country = model.get('country');

        if (country) {
          countries.add(country);
        }
      });
    });

    return countries;
  }

  get hasFilter() {
    const { facilities, exchanges } = this.state;
    return facilities.hasFilter || exchanges.hasFilter;
  }

  getFacilityIcon(facility = null) {
    const { theme } = this.props;
    return getFacilityIcon(theme, facility);
  }

  getExchangeIcon(exchange = null) {
    const { theme } = this.props;
    return getExchangeIcon(theme, exchange);
  }

  getPeeringQuery(direction) {
    return {
      aggregateTypes: ['avg_bits_per_sec'],
      all_devices: false,
      customAsGroups: false,
      device_types: ['router'],
      filters: {
        connector: 'All',
        filterGroups: [
          {
            connector: 'All',
            filters: [
              { filterField: `${direction}_as`, operator: '<>', filterValue: '0' },
              { filterField: `${direction}_as`, operator: '=', rightFilterField: `${direction}_nexthop_as` },
              { filterField: `i_${direction}_network_bndry_name`, operator: '=', filterValue: 'external' }
            ]
          }
        ]
      },
      lookback_seconds: 604800,
      metric: [`AS_${direction}`],
      show_overlay: false,
      show_total_overlay: false,
      topx: 0,
      viz_type: 'table'
    };
  }

  computeMapMarkers() {
    const { form } = this.props;
    const { facilities, exchanges } = this.state;
    const mapMarkerTypes = form.getValue('mapMarkerTypes');
    const mapMarkers = [];

    if (mapMarkerTypes.includes('ix')) {
      mapMarkers.push(
        ...exchanges.models
          .filter((ix) => ix.isMappable)
          .map((ix) => {
            const [lon, lat] = ix.coordinates;
            return {
              id: `ix-${ix.id}`,
              lat,
              lon,
              model: ix,
              title: ix.get('name'),
              icon: this.getExchangeIcon(ix),
              iconSize: 17,
              popupOptions: { maxWidth: 'none' }
            };
          })
      );
    }

    if (mapMarkerTypes.includes('fac')) {
      mapMarkers.push(
        ...facilities.models
          .filter((fac) => fac.isMappable)
          .map((fac) => ({
            id: `fac-${fac.id}`,
            lat: fac.get('latitude'),
            lon: fac.get('longitude'),
            model: fac,
            title: fac.get('name'),
            icon: this.getFacilityIcon(fac),
            iconSize: 23,
            options: { anchor: 'bottom' },
            popupOptions: { maxWidth: 'none' }
          }))
      );
    }

    this.setState({ mapMarkers });
  }

  runPeeringQuery() {
    const { $query } = this.props;

    Promise.all(
      ['src', 'dst'].map((direction) =>
        $query
          .runQuery(this.getPeeringQuery(direction), true)
          .then(({ results }) => results)
          .catch(() => null)
      )
    ).then((allResults) => {
      const peeredAsns = new Set();

      allResults.forEach((results) => {
        if (results) {
          results.each((result) => {
            const asn = Number((result.get('key') || '').match(/\((\d+)\)$/)?.[1]);

            if (asn > 0) {
              peeredAsns.add(asn);
            }
          });
        }
      });

      this.handlePeeredAsnsUpdate(peeredAsns);
    });
  }

  handlePeeredAsnsUpdate = (peeredAsns) => {
    const { facilities } = this.state;
    facilities.peeredAsns = peeredAsns;
    this.computeMapMarkers();
  };

  handleCountryQuery = (filter, lookupOptions) => {
    const { $lookups } = this.props;
    const { countries } = this;

    return $lookups
      .countries(filter, lookupOptions)
      .then((countryOptions) => countryOptions.filter((option) => countries.has(option.value)));
  };

  handleFilterChange = () => {
    const { form } = this.props;
    const { facilities, exchanges } = this.state;
    const filters = form.getFieldGroupValues('filters');
    const discreteFilters = [];

    if (filters.countries?.length > 0) {
      discreteFilters.push({ type: 'countries', fn: (model) => filters.countries.includes(model.get('country')) });
    }

    if (filters.common) {
      discreteFilters.push({
        type: 'common',
        fn: (model) => Boolean(model.get('common')).toString() === filters.common
      });
    }

    facilities.setDiscreteFilters(discreteFilters);
    facilities.filter();

    exchanges.setDiscreteFilters(discreteFilters);
    exchanges.filter();

    this.computeMapMarkers();
  };

  handleSearchChange = debounce((field, value) => {
    const { facilities, exchanges } = this.state;
    facilities.filter(value);
    exchanges.filter(value);
    this.computeMapMarkers();
  }, 100);

  handleVisualizationChange = () => {
    this.computeMapMarkers();
  };

  handleClearFilters = () => {
    const { form } = this.props;
    const { facilities, exchanges } = this.state;
    facilities.clearFilters();
    exchanges.clearFilters();
    form.reset();
    this.computeMapMarkers();
  };

  handleExpandExchange = (exchange) => {
    const { exchanges } = this.state;
    this.setState({
      expandedExchange: exchange,
      expandedExchangeCollection: new PeeringExchangeCollection([], {
        models: [exchange],
        sortState: exchanges.sortState
      })
    });
  };

  handleCollapseExchange = (exchange) => {
    this.setState({ expandedExchange: null, expandedExchangeCollection: null, previousExpandedExchange: exchange });
  };

  handleMapContainerResize = throttle(() => {
    if (this.map) {
      this.map.resize();
    }
  }, 500);

  handleMapLoad = (map, mapCmp) => {
    this.map = map;
    this.mapCmp = mapCmp;
  };

  handleUpdateMapStyle = () => {
    this.computeMapMarkers();
  };

  handleMarkerClick = ({ model }, evt) => {
    const { onModelSelect } = this.props;

    if (model) {
      evt.stopPropagation();
      onModelSelect(model);
    }
  };

  isRowSelected = (model) => {
    const { selectedModel } = this.props;
    return model.type === selectedModel?.type && model.get('id') === selectedModel?.get('id');
  };

  /** render option without the icon */
  renderSelectValue = (option) => option?.label || 'All';

  renderCountryValue = (option, placeholder, values, options) => {
    const selectedOptions = (options || []).filter((o) => values.includes(o.value));
    let text = `${values.length} ${values.length === 1 ? 'country' : 'countries'} selected`;

    if (values.length === 0) {
      text = 'All';
    } else if (selectedOptions.length === 1) {
      text = selectedOptions[0].label;
    }

    return (
      <Flex title={selectedOptions.map((o) => o.label).join('\n')}>
        <Text ellipsis>{text}</Text>
      </Flex>
    );
  };

  renderMarkerPopup = ({ model }) => `
      <div style="line-height: 1.3;">
        <div style="text-transform: capitalize; opacity: 0.65;">${model.type}</div>
        <div style="font-weight: bolder; font-size: larger;">${model.get('name')}</div>
        <div style="font-weight: bolder; font-size: smaller; text-transform: uppercase; opacity: 0.65;">
          ${model.get('org_name') || ''}
        </div>
        <div style="display: flex; gap: 16px; align-items: center; margin-top: 8px;">
          ${
            model.location
              ? `
                <div style="flex: 1 1 200px">
                  <div style="white-space: pre-line; margin-top: 4px;">${model.multiLineLocation}</div>
                </div>
              `
              : ''
          }
          <div style="flex: 0 1 105px; display: grid; grid-template-columns: auto auto; align-items: baseline; gap: 4px 8px;">
            <div>
              <div class="${Classes.TAG} ${Classes.ROUND}">${model.get('net_count')}</div>
            </div>
            <div>Network${model.get('net_count') !== 1 ? 's' : ''}</div>
            ${
              model.type === 'facility'
                ? `
                  <div>
                    <div class="${Classes.TAG} ${Classes.ROUND}">${model.get('ix_count') || 0}</div>
                  </div>
                  <div>Exchange${model.get('ix_count') !== 1 ? 's' : ''}</div>
                `
                : `
                  <div>
                    <div class="${Classes.TAG} ${Classes.ROUND}">${model.get('fac_count') || 0}</div>
                  </div>
                  <div>${model.get('fac_count') !== 1 ? 'Facilities' : 'Facility'}</div>
                `
            }
          </div>
        </div>
      </div>
    `;

  renderMap() {
    const { mapMarkers } = this.state;

    return (
      <AutoSizer onResize={this.handleMapContainerResize}>
        {({ width, height }) => {
          if (width === 0 || height === 0) {
            return null;
          }
          return (
            <Map
              containerWrapperProps={{ width, height }}
              onLoad={this.handleMapLoad}
              onUpdateMapStyle={this.handleUpdateMapStyle}
              markers={mapMarkers}
              autoBounds
              renderWorldCopies
              markerPopupOnHover
              getMarkerContentHTML={this.renderMarkerPopup}
              onMarkerClick={this.handleMarkerClick}
            />
          );
        }}
      </AutoSizer>
    );
  }

  renderFacilitiesTable() {
    const { onModelSelect } = this.props;
    const { facilities } = this.state;

    return (
      <VirtualizedTable
        collection={facilities}
        columns={this.facilitiesColumns}
        onRowClick={onModelSelect}
        isRowSelected={this.isRowSelected}
        flexed
      />
    );
  }

  renderExchangesTable() {
    const { onModelSelect } = this.props;
    const { exchanges, expandedExchange, expandedExchangeCollection, previousExpandedExchange } = this.state;

    if (expandedExchange) {
      return (
        <FlexColumn flex={1}>
          <Box height={82} borderBottom="thin">
            <VirtualizedTable
              collection={expandedExchangeCollection}
              columns={this.exchangesColumns.map((column) => ({ ...column, sortable: false }))}
              onRowClick={onModelSelect}
              isRowSelected={this.isRowSelected}
              style={{ height: '100%' }}
            />
          </Box>

          <VirtualizedTable
            collection={expandedExchange.facilities}
            columns={this.facilitiesColumns}
            onRowClick={onModelSelect}
            isRowSelected={this.isRowSelected}
            flexed
          />
        </FlexColumn>
      );
    }

    return (
      <VirtualizedTable
        collection={exchanges}
        columns={this.exchangesColumns}
        onRowClick={onModelSelect}
        isRowSelected={this.isRowSelected}
        scrollToIndex={exchanges.models.indexOf(previousExpandedExchange)}
        scrollToAlignment="start"
        flexed
      />
    );
  }

  renderTables() {
    const { facilities, exchanges } = this.state;

    const tables = [
      { collection: facilities, title: 'Peering Sites (Facilities)', panel: this.renderFacilitiesTable() },
      { collection: exchanges, title: 'IX (Exchanges)', panel: this.renderExchangesTable() }
    ];

    return (
      <Tabs id="peeringTabs" flexed>
        {tables.map(({ collection, title, panel }) => (
          <Tab
            key={title}
            className="flex-column"
            id={title.replace(/\W+/g, '')}
            title={
              <Flex gap={1} alignItems="center">
                <Text>{title}</Text>
                <Tag minimal>
                  {collection.size}
                  {collection.unfilteredSize > collection.size && ` of ${collection.unfilteredSize}`}
                </Tag>
              </Flex>
            }
            panel={
              <Card height="100%" display="flex" flex={1} flexDirection="column" overflow="hidden" minHeight={400}>
                {panel}
              </Card>
            }
          />
        ))}
      </Tabs>
    );
  }

  render() {
    const { form } = this.props;
    const vizTypes = form.getValue('vizTypes');

    return (
      <FlexColumn gap={2} minHeight="100%">
        <Flex gap={1} alignItems="center" justifyContent="space-between">
          <Flex flex="1 auto" gap={1} alignItems="center">
            <Field
              name="mapMarkerTypes"
              flex="0 1 160px"
              minWidth={80}
              mb={0}
              showLabel={false}
              onChange={this.handleVisualizationChange}
            >
              <Select inlineLabel menuWidth={160} fill valueRenderer={this.renderSelectValue} />
            </Field>
            <Field
              name="countries"
              onQuery={this.handleCountryQuery}
              flex="0 1 165px"
              minWidth={85}
              mb={0}
              showLabel={false}
              onChange={this.handleFilterChange}
            >
              <MultiSelect
                inlineLabel
                showFilter
                renderAsButton
                toggle
                keepOpen
                clearable
                clearableLabel="All"
                menuWidth={180}
                fill
                valueRenderer={this.renderCountryValue}
              />
            </Field>
            <Field
              name="common"
              flex="0 1 110px"
              minWidth={85}
              mb={0}
              showLabel={false}
              onChange={this.handleFilterChange}
            >
              <Select inlineLabel menuWidth={110} fill valueRenderer={this.renderSelectValue} />
            </Field>
            <Field
              name="search"
              flex="0 1 300px"
              minWidth={150}
              mb={0}
              showLabel={false}
              large
              onChange={this.handleSearchChange}
            >
              <InputGroup className="jumbo" leftIcon="search" clearable fill />
            </Field>
            {this.hasFilter && (
              <Button flex="0 0 auto" text="Clear" intent="primary" minimal onClick={this.handleClearFilters} />
            )}
          </Flex>
          <Flex gap={1} alignItems="center">
            <Field name="vizTypes" mb={0} showLabel={false} onChange={this.handleVisualizationChange}>
              <Select inlineLabel menuWidth={110} />
            </Field>
          </Flex>
        </Flex>
        {vizTypes.includes('map') && (
          <Box flex="1 calc(50vh - 160px)" minHeight="400px" border="thin" borderRadius={8} overflow="hidden">
            {this.renderMap()}
          </Box>
        )}
        {vizTypes.includes('table') && <>{this.renderTables()}</>}
      </FlexColumn>
    );
  }
}
