import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';
import { Card, FlexColumn, Flex, Box, Tag } from 'core/components';
import { VirtualizedTable, Table } from 'core/components/table';
import BgpResultCollection from 'app/stores/synthetics/BgpResultCollection';
import { ALLOWED_COLOR, WITHDRAWAL_COLOR, INVALID_ORIGIN_COLOR } from 'app/views/synthetics/utils/syntheticsUtils';

@withTheme
@inject('$app', '$exports')
@observer
class BgpEventsTable extends Component {
  state = {
    collection: new BgpResultCollection([])
  };

  static getDerivedStateFromProps(props) {
    if (props.results) {
      return {
        collection: new BgpResultCollection(props.results)
      };
    }

    return null;
  }

  getColumns = () => {
    const { routeviewer } = this.props;
    const columns = [
      {
        label: 'Type',
        name: 'type_display',
        width: 135
      },
      {
        label: 'Prefix',
        name: 'prefix',
        width: 200
      },
      {
        label: 'Origin AS',
        name: 'origin_as_name'
      },
      {
        label: 'Upstream AS',
        name: 'upstream_asname'
      },
      {
        label: 'AS Path',
        name: 'as_path'
      },
      {
        label: 'AS Path Length',
        name: 'path_length',
        width: 80
      },
      {
        label: 'Total',
        name: 'total',
        width: 75
      },
      {
        label: 'RPKI Status',
        name: 'rpki',
        width: 100
      },
      {
        label: 'Dataset',
        name: 'dataset',
        width: 100
      }
    ];

    if (routeviewer) {
      return columns.filter((column) => column.name !== 'rpki');
    }

    return columns;
  };

  groupSummary = (params) => {
    const { group, groupKey } = params;
    const countEl = (count, text, color) => {
      if (count) {
        return (
          <Tag minimal ml={1} color={color}>
            {`${text}: ${count}`}
          </Tag>
        );
      }

      return '';
    };

    const { announcement, withdrawal, invalidRpki, unexpectedOrigin, unexpectedUpstream } = group.reduce(
      (acc, curr) => {
        const { type, total } = curr.get();

        acc[type] += total;

        return acc;
      },
      {
        announcement: 0,
        withdrawal: 0,
        invalidRpki: 0,
        unexpectedOrigin: 0,
        unexpectedUpstream: 0
      }
    );

    return {
      groupType: 'summary',
      label: (
        <Flex alignItems="center" overflow="hidden">
          <Box mr={1}>{groupKey}</Box>
          {countEl(announcement, 'Announcements', ALLOWED_COLOR)}
          {countEl(invalidRpki, 'Invalid RPKI', INVALID_ORIGIN_COLOR)}
          {countEl(unexpectedOrigin, 'Unexpected Origins', INVALID_ORIGIN_COLOR)}
          {countEl(unexpectedUpstream, 'Unexpected Upstream', INVALID_ORIGIN_COLOR)}
          {countEl(withdrawal, 'Withdrawals', WITHDRAWAL_COLOR)}
        </Flex>
      )
    };
  };

  render() {
    const { $app, collapse } = this.props;
    const { collection } = this.state;

    if (!collection || collection.size === 0) {
      return null;
    }

    if (collapse && !collection.groupBy) {
      collection.toggleGroup(true);
    } else if (!collapse && collection.groupBy) {
      collection.toggleGroup(false);
    }

    const TableComponent = $app.isExport ? Table : VirtualizedTable;
    const oneGroup = collection.groupedData && Object.keys(collection.groupedData).length === 1;
    const isCollapsed = oneGroup ? false : collapse;

    return (
      <FlexColumn flex={1}>
        <Card flex="1 0 auto" display="flex" flexDirection="column" minHeight={300}>
          <TableComponent
            columns={this.getColumns()}
            collection={collection}
            scrollToAlignment="start"
            flexed
            groupSummaryLookup={this.groupSummary}
            isCollapsed={isCollapsed}
          />
        </Card>
      </FlexColumn>
    );
  }
}

export default BgpEventsTable;
