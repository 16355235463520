import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Button, Flex, Spinner, Tag, Text } from 'core/components';

@inject('$cloudExportWizard')
@observer
class AccessCheckButton extends Component {
  render() {
    const {
      $cloudExportWizard: { form },
      formField,
      loading,
      validationFn,
      validState,
      message,
      btnText = 'Validate Access'
    } = this.props;
    const field = form.getField(formField);
    const formErrors = field.errors;
    let isDisabled = field.pristine;
    let validationMessage;

    if (formErrors.length && !field.pristine) {
      isDisabled = true;
      [validationMessage] = formErrors; // e.g. formErrors[0] thanks eslint :/
    } else {
      validationMessage = message;
      if (!validationMessage && validState) {
        validationMessage = 'Valid';
      }
    }

    return (
      <Flex flexDirection="column">
        <Button
          small
          disabled={isDisabled}
          text={btnText}
          my="auto"
          style={{ height: '30px', width: '200px' }}
          loading={loading}
          onClick={validationFn}
        />
        <Box mb={1} mt={1}>
          {loading && <Spinner size={30} ml={1} />}
          {validState === true && (
            <Tag intent="success" ml={1} minimal>
              {validationMessage}
            </Tag>
          )}

          {!validState && (
            <Text mt="4px" color="danger">
              {validationMessage}
            </Text>
          )}
        </Box>
      </Flex>
    );
  }
}

export default AccessCheckButton;
