import React, { Component } from 'react';

import { Grid } from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import PresetCard from 'app/components/library/PresetCard';
import Page from 'app/components/page/Page';
import PageHeading from 'app/components/page/PageHeading';
import parentLinks from './NetworkExplorerParentLink';

@storeLoader('$dashboards')
class TCPTraffic extends Component {
  render() {
    const { $dashboards } = this.props;

    const applicationDashboards = $dashboards.collection
      .get()
      .filter(
        (dash) =>
          dash.get('category.name') === 'VM, Host, Applications and Performance' &&
          dash.get('dash_title') !== 'DNS Analytics'
      );

    return (
      <Page title="TCP Traffic" parentLinks={parentLinks} scrolls>
        <PageHeading title="TCP Traffic" />
        <Grid gridGap={32} gridRowGap={32}>
          {applicationDashboards.map((dashboard) => (
            <PresetCard key={dashboard.id} dashboard={dashboard} navPath="core/quick-views/tcp-traffic" />
          ))}
        </Grid>
      </Page>
    );
  }
}

export default TCPTraffic;
