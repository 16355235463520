import React from 'react';
import Page from 'app/components/page/Page';
import { NonIdealState } from '@blueprintjs/core';
import { inject } from 'mobx-react';
import { Box, Icon, Text } from 'core/components';
import { HiUserGroup } from 'react-icons/hi';

const RbacDeniedPermission = ({ $rbac, permissions }) => {
  const permissionNames = permissions.map((permission) => $rbac.getPermissionNameFromKey(permission));
  return (
    <Page title="Access Denied">
      <NonIdealState
        title="Access Denied"
        description={
          <Box>
            <Text as="div" mb={1}>
              You are not allowed to access this page without the following permission(s):{' '}
              <Text fontWeight="heavy">{permissionNames.join(',')}</Text>
            </Text>
            <Text>
              Contact your RBAC Administrator for more details - <Text monospace>Error: {permissions.join(',')}</Text>
            </Text>
          </Box>
        }
        icon={<Icon iconSize={96} icon={HiUserGroup} />}
      />
    </Page>
  );
};

export default inject('$rbac')(RbacDeniedPermission);
