import React from 'react';
import DimensionToLink from 'app/components/links/DimensionToLink';
import NmsDeviceLink from 'app/components/links/NmsDeviceLink';
import NmsInterfaceLink from 'app/components/links/NmsInterfaceLink';
import { getEntityData } from './nmsAlertEntity';

const DIMENSIONS = {
  deviceId: 'device_id',
  ifIndex: 'ifindex'
};

const getMetricDimension = ({ dimension, alertModel, $metrics }) => {
  const targetMeasurements = Object.keys(alertModel.nmsAlarmContext?.alarmTargets || {});
  // It doesn't matter which measurement we choose here, as any dimension listed on an alert must be common to all alert measurements
  const measurement = targetMeasurements[0];
  const measurementModel = $metrics.measurementModel(measurement);
  if (!measurement || !measurementModel) {
    return null;
  }

  const dimensionKey = dimension.replace($metrics.getAppProtocolDimension(measurement), '');

  const mergedMetricsDimensions = measurementModel.mergedMetricsDimensions[dimensionKey];

  if (!mergedMetricsDimensions) {
    return null;
  }

  const { Label, name } = mergedMetricsDimensions;
  return { label: Label || name, name };
};

export const getNmsAlertDimensionDisplay = ({ $alerting, $devices, $metrics, dimension, value, alertModel }) => {
  if (!dimension || !alertModel) {
    return null;
  }

  let displayValue = value;
  let dimensionKey = dimension;
  let dimensionLabel = $alerting.getDimensionLabel(dimension);

  const fallbackValue = '<empty>';
  const dimensionValue = value || fallbackValue;
  let dimensionToValue = dimensionValue;

  displayValue = $alerting.lookupValue(alertModel.get(), dimension, dimensionValue);

  let overrideContent;

  const metricDimension = getMetricDimension({ dimension, alertModel, $metrics });
  const entityData = getEntityData(alertModel);
  const { interface: entityInterface, device: entityDevice } = entityData || {};

  if (metricDimension) {
    const { label, name } = metricDimension;
    dimensionKey = name;
    dimensionLabel = label;
    dimensionToValue = { [name]: displayValue };
  } else if (dimension === DIMENSIONS.deviceId) {
    /**
     This is a special case where we are getting this dimension information in a different format than expected by DimensionToLink.
     Here, we receive device ID under the dimension device_id.
     DimensionToLink will try to render a regular (non-NMS) device link for that dimension. It expects a device name in order to render an NMS device link.
     */
    const deviceName = entityDevice?.name || $devices.deviceSummariesById?.[dimensionValue]?.device_name;
    overrideContent = <NmsDeviceLink id={dimensionValue}>{deviceName || dimensionValue}</NmsDeviceLink>;
  } else if (dimension === DIMENSIONS.ifIndex && entityInterface && entityDevice) {
    dimensionLabel = 'Interface';
    overrideContent = (
      <NmsInterfaceLink deviceId={entityDevice.id} snmpId={value}>
        {entityInterface.name || value}
      </NmsInterfaceLink>
    );
  }

  return {
    label: dimensionLabel,
    value: overrideContent || (
      <DimensionToLink dimension={dimensionKey} dimensionToValue={dimensionToValue}>
        {displayValue}
      </DimensionToLink>
    )
  };
};
