import React from 'react';

import { Box, LinkButton, Text } from 'core/components';
import $sites from 'app/stores/site/$sites';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: ['i_ult_exit_site', 'i_device_site_name', 'i_device_site_country', 'i_ult_exit_site_country'],
  filterFields: ['i_ult_exit_site', 'i_device_site_name', 'i_device_site_country', 'i_ult_exit_site_country']
};

class SiteDependency extends AbstractDependency {
  get key() {
    return 'site';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return $sites.collection.unfilteredSize > 0;
  }

  get message() {
    return (
      <>
        <Box mb="4px">
          <Text small>
            <strong>Sites</strong> are required by dimensions you&apos;re using, but your organization currently has
            none defined.
          </Text>
        </Box>
        <LinkButton icon="cog" small text="Configure" to="/v4/settings/sites" />
      </>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || $sites.collection.unfilteredSize > 0;
  }
}

export default SiteDependency;
