import { buildFilterGroup } from 'core/util/filters';
import transformConfigToFilters from 'app/util/mkp/transformConfigToFilters';

function getTenantPreviewFilters(tenant, currentFilters) {
  const tenantFilterGroup = transformConfigToFilters(tenant.get('config'), {
    name: tenant.get('name')
  });

  let { ...previewFilters } = currentFilters;
  if (previewFilters && previewFilters.filterGroups && previewFilters.filterGroups.length) {
    // Remove any existing MKP tenant groups - assumes they're at the top-level!
    previewFilters.filterGroups = previewFilters.filterGroups.filter(
      (filterGroup) => !filterGroup.name.startsWith('mkp_preview')
    );

    if (previewFilters.connector === 'All') {
      previewFilters.filterGroups.push(tenantFilterGroup);
    } else if (previewFilters.filterGroups.length > 0) {
      // If top-level connector is any, we need to nest
      previewFilters = {
        connector: 'All',
        filterGroups: [buildFilterGroup(previewFilters), tenantFilterGroup]
      };
    } else {
      previewFilters = {
        connector: 'All',
        filterGroups: [tenantFilterGroup]
      };
    }
  } else {
    previewFilters = {
      connector: 'All',
      filterGroups: [tenantFilterGroup]
    };
  }

  return previewFilters;
}

export default getTenantPreviewFilters;
