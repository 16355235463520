import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

const asnTopxMatchFn = (result) => {
  const [, match] = (result['AS Number'] || '').match(/\(([0-9]+)\)/) || [];
  return match;
};

@Form(aggregateConfig('AsnAggregate'))
class AsnAggregate extends Component {
  render() {
    const topx = 300;

    return (
      <QuickViewAggregate
        title="ASNs"
        resultsKey="AS Number"
        topx={topx}
        insightsQuery="fetchAsnInsights"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'AS Number',
          aggregateFilters: [
            { metric: ['AS_src', 'AS_dst'] },
            { metric: ['AS_src'] },
            { metric: ['AS_dst'] },
            { metric: ['AS_src', 'AS_dst'] },
            { metric: ['AS_src', 'AS_dst'] }
          ],
          depth: topx
        }}
        chartProps={{
          metrics: {
            Internal: ['AS_dst', 'AS_src'],
            Inbound: 'AS_src',
            Outbound: 'AS_dst'
          },
          topxMetricField: 'src|dst_as'
        }}
        getTopxProps={{ mapFn: asnTopxMatchFn }}
      />
    );
  }
}

export default AsnAggregate;
