import { action, computed, observable, toJS } from 'mobx';
import { get, isEqual, isString, omit, uniqueId } from 'lodash';

import { showSuccessToast } from 'core/components/toast';
import api from 'core/util/api';

// we never serialize these fields to the API.
const EDITED_CREATED_FIELDS = ['edate', 'cdate'];

export default class Model {
  @observable.shallow error;

  optimisticId = uniqueId('i_');

  attributes;

  collection = null;

  @observable
  requestStatus = null;

  @observable
  requestProgress = 0;

  @observable
  lastUpdated;

  constructor(attributes = {}) {
    /*
     * Very important to note that any fields used here, must be defined with getter syntax
     * in subclasses or they will be undefined here!
     */

    let data = Object.assign({}, this.defaults, attributes);
    if (this.omitDuringDeserialize && this.omitDuringDeserialize.length) {
      data = omit(data, this.omitDuringDeserialize);
    }
    this.attributes = observable.object(data, {}, { deep: false, name: 'Model.attributes' });
  }

  get defaults() {
    return {};
  }

  /**
   * Return the base url used in the `url` method
   *
   * @abstract
   */
  get urlRoot() {
    return undefined;
  }

  /**
   * Keys that are ignored in the response when deserializing
   */
  get omitDuringDeserialize() {
    return [];
  }

  get omitDuringSerialize() {
    return [];
  }

  get messages() {
    return {
      create: 'Added successfully',
      update: 'Updated successfully',
      destroy: 'Removed successfully',
      duplicate: 'Duplicated successfully'
    };
  }

  get showToastTitles() {
    return false;
  }

  @computed
  get index() {
    if (!this.collection) {
      throw new Error('index: Model is not a part of a collection');
    }

    return this.collection.models.indexOf(this);
  }

  /**
   * Return the url for this given REST resource
   */
  get url() {
    if (!this.urlRoot && (!this.collection || !this.collection.url)) {
      throw new Error('No `urlRoot` provided');
    }

    const urlRoot = this.urlRoot || this.collection.url;

    if (this.isNew) {
      return urlRoot;
    }

    return `${urlRoot}/${this.get('id')}`;
  }

  @computed
  get lastEdited() {
    return this.toDate('etime') || this.toDate('edate');
  }

  @computed
  get created() {
    return this.toDate('ctime') || this.toDate('cdate');
  }

  /**
   * Whether the resource is new or not
   *
   * We determine this asking if it contains the `id` attribute (set by the server).
   */
  @computed
  get isNew() {
    return !this.has('id') || (!this.get('id') && this.get('id') !== 0);
  }

  /**
   * Determines whether or not this Model is selectable in it's collection.
   */
  @computed
  get isSelectable() {
    return true;
  }

  /**
   * Determines whether or not this Model is selected in it's collection.
   */
  @computed
  get isSelected() {
    if (!this.collection) {
      console.warn(`BaseModel (${this.constructor.name}) is not a part of a Collection. Cannot call isSelected()`);

      return false;
    }

    if (Array.isArray(this.collection.selected)) {
      return this.collection.selected.includes(this);
    }

    return this.collection.selected === this;
  }

  /**
   * Get an id from the model. It will use either
   * the backend assigned one or the client.
   */
  get id() {
    return this.has('id') ? this.get('id') : this.optimisticId;
  }

  /**
   * Questions whether the request exists and matches a certain label.
   * If no label are passed it will check if any requests are active.
   */
  isRequestActive = (label) => {
    if (!label) {
      return this.requestStatus !== null;
    }

    return (
      (!!this.requestStatus && this.requestStatus === label) ||
      (this.collection && this.collection.isRequestActive(label))
    );
  };

  baseGet(attribute) {
    if (!attribute) {
      return toJS(this.attributes);
    }

    if (attribute.includes('.')) {
      const [root, ...rest] = attribute.split('.');
      // ignore dot in keys attributes like `f_hll(inet_src_addr,0.0001)__k_last`
      if (!this.attributes[root] && this.has(attribute)) {
        return this.attributes[attribute];
      }
      if (rest.length === 1) {
        return this.attributes[root] && this.attributes[root][rest[0]];
      }
      return this.attributes[root] && get(this.attributes[root], rest.join('.'));
    }

    if (Array.isArray(attribute)) {
      return attribute.reduce((result, attr) => {
        if (attr.includes('.')) {
          [attr] = attr.split('.');
        }
        result[attr] = this.attributes[attr];
        return result;
      }, {});
    }

    return this.attributes[attribute];
  }

  /**
   * Get the attribute from the model.
   *
   * For optional fields use `has()` to check whether the field is populated.
   * If no attribute is passed, returns all of the model attributes as a plain object.
   */
  get(attribute, defaultValue) {
    const result = this.baseGet(attribute);

    return result === undefined ? defaultValue : result;
  }

  /**
   * Returns whether the given field exists for the model.
   */
  has(attribute) {
    return this.attributes[attribute] !== undefined && this.attributes[attribute] !== null;
  }

  get sortValues() {
    return {};
  }

  getSortValue(field) {
    let value;

    if (this.sortValues[field]) {
      value = this.sortValues[field](this);
    } else {
      value = this.get(field);
      if (value === undefined) {
        value = this[field];
      }
    }

    // Replacing undefined with empty string makes _.orderBy happy when ordering numbers and strings
    return value !== undefined ? value : '';
  }

  /**
   * Returns a raw JS instance of our Model, used for debugging purposes mostly.
   */
  toJS(supportCycles = true) {
    return toJS(this.attributes, supportCycles);
  }

  isEqual(otherModel) {
    return otherModel && otherModel.toJS && isEqual(this.toJS(), otherModel.toJS());
  }

  @action
  setRequestStatus = (status) => (this.requestStatus = status);

  /**
   * Set the current selected model in a collection to this model. Typically this
   * method is called from a UI with a direct reference to `this`, so we keep the
   * binding with an arrow function. `onClick={model.select}`
   */
  @action
  select = (options = {}) => {
    const { multi = false, shiftKey = false, fetch = false } = options;

    if (!this.collection) {
      console.warn(`BaseModel (${this.constructor.name}) is not a part of a Collection. Cannot call select()`);
    }

    if (!this.isSelectable) {
      return Promise.resolve();
    }

    if (fetch) {
      return this.fetch().then(() => {
        this.collection.select(this, { multi });
      });
    }
    return Promise.resolve().then(() => this.collection.select(this, { multi, shiftKey }));
  };

  @action
  deselect = () => {
    if (!this.collection) {
      console.warn(`Model (${this.constructor.name}: ${this.id}) is not a part of a Collection. Cannot call select()`);
    }

    if (!this.isSelected) {
      console.warn(`Model (${this.constructor.name}: ${this.id}) is not selected.`);
    }

    this.collection.clearSelection();
  };

  @action
  setLastUpdated = () => {
    this.lastUpdated = Date.now();
  };

  /**
   * Merge the given data with the current attributes
   */
  @action
  set(dataOrFieldName = {}, fieldData) {
    if (isString(dataOrFieldName) && fieldData !== undefined) {
      this.attributes[dataOrFieldName] = fieldData;
    } else {
      Object.assign(this.attributes, dataOrFieldName);
    }

    this.setLastUpdated();

    return this;
  }

  /**
   * Replaces all of the attributes with new ones.
   */
  @action
  replace = (data = {}) => {
    this.attributes = observable.object(data, {}, { deep: false, name: 'Model.attributes' });

    this.setLastUpdated();
  };

  @action
  duplicate = (options = {}) => {
    const { removeId = true, save = true, deserialize = true } = options;
    const attributes = removeId ? omit(this.get(), ['id']) : this.get();

    if (!this.collection) {
      return new this.constructor(attributes);
    }

    const dupe = this.collection.build(attributes, { deserialize });

    if (save) {
      dupe.save(attributes);
    }

    return dupe;
  };

  /**
   * Saves the resource on the backend.
   *
   * If the item has an `id` it updates it, otherwise it creates the new resource.
   *
   * It supports optimistic and patch updates.
   *
   * optimistic - updates the model before we get a response back from the server.
   * patch - uses `PATCH` versus `PUT`.
   */
  @action
  async save(attributes = {}, options = {}) {
    const {
      optimistic = false,
      patch = false,
      preserialize = true,
      setOnPatchSuccess = true,
      clearSelection = true,
      toast = true,
      toastMessage,
      sendProvidedValuesOnly = false,
      url
    } = options;

    this.requestStatus = 'updating';
    this.requestProgress = 0;
    if (this.isNew) {
      this.set(Object.assign({}, attributes));
      return this.create(attributes, {
        optimistic,
        toast,
        clearSelection,
        url
      });
    }
    let data = attributes;
    const originalAttributes = this.get();

    // if PATCH, we only want to send the values that changed.
    if (patch) {
      data = Object.keys(attributes).reduce((result, attribute) => {
        const newValue = attributes[attribute];
        const oldValue = originalAttributes[attribute];
        if (newValue !== oldValue) {
          result[attribute] = newValue;
        }
        return result;
      }, {});
    } else if (!sendProvidedValuesOnly) {
      data = Object.assign({}, originalAttributes, attributes);
    }

    if (optimistic) {
      this.set(attributes);
    }

    const saveUrl = url || (this.urlPaths ? this.urlPaths.update : this.url);

    const apiMethod = patch ? 'patch' : 'put';
    const promise = api[apiMethod](saveUrl, {
      data: preserialize ? this.serialize(data) : data
    });

    return promise.then(
      (success) => {
        if (this.collection && clearSelection) {
          this.collection.clearSelection();
        }

        if (!patch || setOnPatchSuccess) {
          this.set(this.deserialize(success));
        }

        if (toast) {
          showSuccessToast(toastMessage || this.messages.update, {
            title: this.showToastTitles ? 'Updated' : undefined
          });
        }

        if (this.collection) {
          this.collection.setLastUpdated();
        }

        this.setLastUpdated();

        this.requestStatus = null;

        return success;
      },
      (error) => {
        if (optimistic) {
          this.set(originalAttributes);
        }
        this.error = { label: 'updating' };
        this.requestStatus = null;
        throw error;
      }
    );
  }

  /**
   * Saves this new model on the backend (via POST operation)
   */
  @action
  async create(attributes = {}, options = {}) {
    const label = 'creating';
    const data = Object.assign({}, this.get(), attributes);
    const { clearSelection, toast = true } = options;

    const url = options.url || (this.collection && this.collection.url ? this.collection.url : this.urlRoot);
    const payload = this.serialize(data);
    const promise = api.post(url, { data: payload });

    this.requestStatus = label;

    return promise.then(
      (success) => {
        this.set(this.deserialize(success));

        if (this.collection) {
          this.collection.add(this);

          if (clearSelection) {
            this.collection.clearSelection();
            this.collection.sort(); // Note: it seemed to make sense not to re-apply existing filter rules
          }
        }

        if (toast) {
          const createMessage = this.messages ? this.messages.create : 'Created Successfully';
          showSuccessToast(createMessage, {
            title: this.showToastTitles ? 'Created' : undefined
          });
        }

        this.requestStatus = null;

        return success;
      },
      (error) => {
        this.error = { label, body: error };
        this.requestStatus = null;
        throw error;
      }
    );
  }

  @action
  async fetch(options = {}) {
    const label = 'fetching';
    this.requestStatus = label;
    this.requestProgress = 0;

    const url = this.urlPaths ? this.urlPaths.fetch : this.url;
    const apiFetchMethod = this.fetchMethod === 'post' ? api.post : api.get;
    const promise = apiFetchMethod(url, options);

    return promise.then(
      (success) => {
        this.set(this.deserialize(success));
        this.setRequestStatus(null);
        return success;
      },
      (error) => {
        this.error = { label, body: error };
        this.requestStatus = null;
        throw error;
      }
    );
  }

  @action
  destroyModel = async (options = {}) => {
    const { toast = true, remove = true, url, data } = options;
    const destroyUrl =
      url ||
      (this.collection && this.collection.urlPaths && this.collection.urlPaths.destroy
        ? `${this.collection.urlPaths.destroy}/${this.get('id')}`
        : this.url);

    this.requestStatus = 'destroying';

    return api.del(destroyUrl, data ? { data } : undefined).then(
      action((success) => {
        if (toast) {
          showSuccessToast(this.messages.destroy, {
            title: this.showToastTitles ? 'Removed' : undefined
          });
        }

        if (this.collection) {
          if (remove) {
            this.collection.remove([this.id]);
          }

          this.collection.clearSelection();
        }

        this.requestStatus = null;

        return success;
      }),
      action((error) => {
        console.error('destroyModel error', error);
        if (options.optimistic && this.collection) {
          this.collection.add([this.get()]);
        }
        this.error = { label: 'destroying', body: error };
        this.requestStatus = null;

        return error;
      })
    );
  };

  /**
   * Destroys the resource on the client and requests the backend to delete it there too
   */
  /* eslint-disable consistent-return */
  async destroy(options = {}) {
    if (this.collection) {
      if (this.isNew || !this.urlRoot) {
        return Promise.resolve(this.collection.remove([this.id]));
      }
    }
    return this.destroyModel(options);
  }

  serialize(data) {
    return omit(data || this.get(), [...this.omitDuringSerialize, ...EDITED_CREATED_FIELDS]);
  }

  deserialize(data) {
    let prunedData = data;
    if (this.omitDuringDeserialize && this.omitDuringDeserialize.length) {
      prunedData = omit(data || this.get(), this.omitDuringDeserialize);
    }

    return prunedData && this.jsonRoot ? prunedData[this.jsonRoot] : prunedData;
  }

  getUniqueCopyName(nameField) {
    let name = this.get(nameField);

    if (this.collection) {
      // Remove the [Copy] or [Copy x] from the name
      const nameMatch = name.match(/\[Copy.*\] (.+)/);
      if (nameMatch) {
        name = nameMatch[nameMatch.length - 1];
      }

      if (!this.collection.find({ [nameField]: `[Copy] ${name}` })) {
        return `[Copy] ${name}`;
      }
      for (let i = 1; ; i += 1) {
        if (
          !this.collection.find({
            [nameField]: `[Copy ${i}] ${name}`
          })
        ) {
          return `[Copy ${i}] ${name}`;
        }
      }
    }

    return name;
  }
}
