import $dictionary from 'app/stores/$dictionary';
import { greekIt, formatNumber } from 'app/util/utils';
import { PERCENT_METRICS } from './constants';

const mappedLabels = { Bytes: 'B', dest_ips: 'Dest IPs' };
const metricToFix = {
  botnets: 0,
  n_botnet_hosts: 0,
  dest_ips: 0,
  unique_src_ip: 0,
  unique_dst_ip: 0,
  last_agent_count: 0,
  last_attempt_count: 0,
  last_error_count: 0,
  last_success_count: 0,
  last_timeout_count: 0,
  new_agent_count: 0,
  new_attempt_count: 0,
  new_error_count: 0,
  new_success_count: 0,
  new_timeout_count: 0,
  iface_limit: 0,
  num_ifaces_in_plan: 0
};

export default function formatMetricValue(value, metric) {
  let unitsLabel = $dictionary.get(`units.${metric}`) || metric;
  let display = Number(value).toFixed(2);

  if (unitsLabel in mappedLabels) {
    unitsLabel = mappedLabels[unitsLabel];
  }

  if (unitsLabel.includes('Bit Rate')) {
    const { display: bitRateDisplay, unitsLabel: bitRateUnitsLabel } = greekIt(value, { fix: 2, suffix: 'bits/s' });
    display = bitRateDisplay;
    unitsLabel = unitsLabel.replace('Bit Rate', bitRateUnitsLabel);
  } else if ($dictionary.get('unitsToPrefix').includes(metric)) {
    ({ display, unitsLabel } = greekIt(value, { fix: 2, suffix: unitsLabel }));
  } else if (PERCENT_METRICS.includes(metric)) {
    display = Math.round(value * (metric.includes('perc_') ? 1 : 100));
  } else {
    ({ display, unitsLabel } = formatNumber(value, {
      fix: metric in metricToFix ? metricToFix[metric] : 2,
      suffix: unitsLabel
    }));
  }

  return {
    value: display,
    metric: unitsLabel,
    formatted: `${display} ${unitsLabel}`
  };
}
