export default function groupByDay(models, key = 'startTime') {
  // Sort the array based on the key provided
  models.sort((a, b) => new Date(a.get(key)) - new Date(b.get(key)));

  const groupedObjects = {};

  // Initialize groupedObjects with the last 7 days, including today
  const currentDate = new Date();
  for (let i = 0; i < 7; i++) {
    const yyyy = currentDate.getFullYear();
    const mm = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const dd = currentDate.getDate().toString().padStart(2, '0');

    const dateKey = `${yyyy}-${mm}-${dd}`;
    groupedObjects[dateKey] = [];

    currentDate.setDate(currentDate.getDate() - 1); // Move to the previous day
  }

  // Group the models by day
  for (const model of models) {
    const dateKey = model.get(key).split('T')[0];

    if (Object.prototype.hasOwnProperty.call(groupedObjects, dateKey)) {
      groupedObjects[dateKey].push(model);
    }
  }

  return groupedObjects;
}
