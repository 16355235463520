/**
 * Agent Types Consts
 */
// TODO Deprecate browser and pending
const AGENT_TYPES = {
  GLOBAL: 'global',
  PRIVATE: 'private',
  BROWSER: 'browser',
  PENDING: 'pending'
};

// These are used primarly in AgentSelectorFormBody until we deprecate Rust Agents
const AGENT_GROUPS = {
  PENDING: 'pending',
  PENDING_NETWORK: 'pending_network',
  PENDING_APP: 'pending_app',
  GLOBAL: 'global',
  GLOBAL_APP: 'global_app',
  GLOBAL_NETWORK: 'global_network',
  PRIVATE: 'private',
  PRIVATE_APP: 'private_app',
  PRIVATE_NETWORK: 'private_network'
};

const AGENT_STATUSES = {
  OK: 'OK',
  WAIT: 'WAIT',
  D: 'D'
};

/**
 * Test Types Consts
 */
const TEST_TYPES = {
  AGENT: 'agent',
  APPLICATION_MESH: 'application-mesh',
  ASN: 'asn',
  BGP_MONITOR: 'bgp-monitor',
  CDN: 'cdn',
  CITY: 'city',
  COUNTRY: 'country',
  DNS_GRID: 'dns-grid',
  DNS: 'dns',
  HOSTNAME: 'hostname',
  IP_ADDRESS: 'ip-address',
  NETWORK_GRID: 'network-grid',
  PAGE_LOAD: 'page-load',
  REGION: 'region',
  TRANSACTION: 'transaction',
  URL: 'url',
  THROUGHPUT: 'throughput'
};

const TEST_TYPE_NAMES = {
  agent: 'Agent-to-Agent',
  'application-mesh': 'Network Mesh',
  asn: 'ASN',
  'bgp-monitor': 'BGP Monitor',
  cdn: 'CDN',
  city: 'City',
  country: 'Country',
  'dns-grid': 'DNS Server Grid',
  dns: 'DNS Server Monitor',
  hostname: 'Hostname',
  'ip-address': 'IP Addresses',
  'network-grid': 'Network Grid',
  'page-load': 'Page Load',
  region: 'Region',
  transaction: 'Transaction',
  url: 'HTTP(S) or API'
};

const ALERTING_TYPES = {
  AGENT: 'agent',
  SUBTEST_ROLLUP: 'subtest'
};

const AGENT_MATRIX_TEST_TYPES = [TEST_TYPES.APPLICATION_MESH];
const USER_DEFINED_TEST_TYPES = [TEST_TYPES.IP_ADDRESS, TEST_TYPES.NETWORK_GRID, TEST_TYPES.HOSTNAME, TEST_TYPES.AGENT];
const FLOW_BASED_TEST_TYPES = [TEST_TYPES.ASN, TEST_TYPES.CDN, TEST_TYPES.COUNTRY, TEST_TYPES.REGION, TEST_TYPES.CITY];
const URL_DEFINED_TEST_TYPES = [TEST_TYPES.URL, TEST_TYPES.PAGE_LOAD, TEST_TYPES.TRANSACTION];
const DNS_TEST_TYPES = [TEST_TYPES.DNS, TEST_TYPES.DNS_GRID];
const HOSTNAME_TEST_TYPES = [TEST_TYPES.HOSTNAME];

/**
 * Test Task Types Consts
 */
const TASK_TYPES = {
  HTTP: 'http',
  PAGE_LOAD: 'page-load',
  PING: 'ping',
  TRACE_ROUTE: 'traceroute',
  TRANSACTION: 'transaction',
  DNS: 'dns',
  DNS_SEC: 'dns-sec',
  BGP_MONITOR: 'bgp-monitor',

  KNOCK: 'knock',
  ERROR: 'error',
  TIMEOUT: 'timeout',
  THROUGHPUT: 'throughput'
};

/**
 * Test Credit Cost Calculation Consts
 */
const PRIVATE_AGENT_COST = 2.5;
const GLOBAL_AGENT_COST = 5;

// Agent HTTP Costs
const PRIVATE_AGENT_HTTP_COST = 0.5;
const GLOBAL_AGENT_HTTP_COST = 1.0;

const TEST_INTERVAL_MIN = 60 * 24 * 30; // 43,200 - ~1 month in minutes.

const getDSCPOptions = () => [
  { value: 0, label: 'Best Effort (DSCP 0)' },
  { value: 8, label: 'CS1 (DSCP 8)' },
  { value: 10, label: 'AF11 (DSCP 10)' },
  { value: 12, label: 'AF12 (DSCP 12)' },
  { value: 14, label: 'AF13 (DSCP 14)' },
  { value: 16, label: 'CS2 (DSCP 16)' },
  { value: 18, label: 'AF21 (DSCP 18)' },
  { value: 20, label: 'AF22 (DSCP 20)' },
  { value: 22, label: 'AF23 (DSCP 22)' },
  { value: 24, label: 'CS3 (DSCP 24)' },
  { value: 26, label: 'AF31 (DSCP 26)' },
  { value: 28, label: 'AF32 (DSCP 28)' },
  { value: 30, label: 'AF33 (DSCP 30)' },
  { value: 32, label: 'CS4 (DSCP 32)' },
  { value: 34, label: 'AF41 (DSCP 34)' },
  { value: 36, label: 'AF42 (DSCP 36)' },
  { value: 38, label: 'AF43 (DSCP 38)' },
  { value: 40, label: 'CS5 (DSCP 40)' },
  { value: 44, label: 'Voice Admit (DSCP 44)' },
  { value: 46, label: 'EF (DSCP 46)' },
  { value: 48, label: 'CS6 (DSCP 48)' },
  { value: 56, label: 'CS7 (DSCP 56)' }
];

const DNS_RECORD_TYPES = {
  A: 'A',
  AAAA: 'AAAA',
  CAA: 'CAA',
  CNAME: 'CNAME',
  MX: 'MX',
  NS: 'NS',
  PTR: 'PTR',
  SOA: 'SOA',
  TXT: 'TXT'
};

const ALLOWED_DNS_RESULTS_TYPES = [
  DNS_RECORD_TYPES.A,
  DNS_RECORD_TYPES.AAAA,
  DNS_RECORD_TYPES.CNAME,
  DNS_RECORD_TYPES.NS
];

const SYN_CONSTANTS = {
  httpExpiryMinMs: 1e3, // one second
  httpExpiryMaxMs: 30e3, // thirty seconds
  httpExpiryDefaultMs: 5e3, // five seconds
  trxScriptMaxLength: 100e3, // one hundred thousand characters
  trxGotoLimit: 5,
  trxScreenshotLimit: 10,
  certExpiryDefaultWarningDays: 30, // 30 days
  certExpiryDefaultCriticalDays: 10, // 10 days
  trxAttachmentMaxSize: 20e6 // 20 MB
};

const AGENT_IMPLEMENTATIONS = {
  RUST: 'RUST',
  NODE: 'NODE',
  NETWORK: 'NETWORK'
};

const AGENT_CAPABILITIES = {
  DNS: 'dns',
  WEB: 'web',
  PREVIEW: 'preview',
  SYNGEST: 'syngest',
  CREDENTIAL_DECRYPTION: 'credential_decryption',
  THROUGHPUT: 'throughput'
};

const TEST_STATUS = {
  ACTIVE: 'A',
  DELETED: 'D',
  INSTANT: 'I', // Preview
  PAUSED: 'P'
};

const AGENT_ALERT_STATUS = {
  ACTIVE: 'A',
  DELETED: 'D'
};

const RECENTLY_ADDED_DAYS = 7;

// defines the default shape of a community test aggregated summary
const COMMUNITY_TEST_SUMMARY = {
  avg_ttlb: 0,
  size: 0,
  status: 0,
  domain_lookup_time: 0,
  connect_time: 0,
  response_time: 0,
  ttlb_health: 'healthy',
  status_health: 'healthy',
  latency: 0,
  jitter: 0,
  packet_loss: 0,
  latency_health: 'healthy',
  packet_loss_health: 'healthy',
  jitter_health: 'healthy'
};
// Format of a vault key in the config must match.. $vault('group_name.vault_key')
// The groupname and key are alphanumeric and can contain spaces, underscores, and dashes
const VAULT_MATCH_REGEX = /\$vault\('[a-zA-Z0-9-_\s]*\.[a-zA-Z0-9-_\s]*'\)/g;
const VAULT_CAPTURE_REGEX = /\$vault\('([a-zA-Z0-9-_\s]*\.[a-zA-Z0-9-_\s]*)'\)/g;
const SYNTH_CONSUMER_TYPE = 'synth';
const SYNTH_AGENT_CREDENTIAL_VERSION = '0.6.10';
const SYNTH_AGENT_THROUGHPUT_VERSION = '1.0.11-rc.2';

const HEALTH = {
  HEALTHY: 'healthy',
  WARNING: 'warning',
  CRITICAL: 'critical',
  FAILING: 'failing'
};

const HEALTH_SORT = [HEALTH.CRITICAL, HEALTH.WARNING, HEALTH.HEALTHY, HEALTH.FAILING];

const DEFAULT_LOOKBACK_OPTIONS = [
  { label: '5 Minutes', value: 300 },
  { label: '15 Minutes', value: 900 },
  { label: '1 Hour', value: 3600 },
  { label: '3 Hours', value: 10800 },
  { label: '6 Hours', value: 21600 },
  { label: '1 Day', value: 86400 },
  { label: '1 Week', value: 604800 },
  { label: '2 Weeks', value: 1209600 },
  { label: '30 Days', value: 2592000 }
];
const ALERT_MANAGER_APPLICATIONS = {
  synthetics: 'synthetics'
};

const TREND_DETAIL_CHART_HEIGHT = 200;
const TREND_CHART_HEIGHT = 75;

const DEFAULT_THRESHOLD_MS_VALUES = { warning: 25000, critical: 75000 };
const DEFAULT_MBPS_VALUES = { warning: 50, critical: 25 };

// Export
module.exports = {
  AGENT_TYPES,
  AGENT_GROUPS,
  AGENT_STATUSES,

  AGENT_ALERT_STATUS,
  ALERTING_TYPES,

  TEST_TYPES,
  TEST_TYPE_NAMES,
  AGENT_MATRIX_TEST_TYPES,
  USER_DEFINED_TEST_TYPES,
  FLOW_BASED_TEST_TYPES,
  URL_DEFINED_TEST_TYPES,
  DNS_TEST_TYPES,
  HOSTNAME_TEST_TYPES,

  TASK_TYPES,

  PRIVATE_AGENT_COST,
  GLOBAL_AGENT_COST,
  PRIVATE_AGENT_HTTP_COST,
  GLOBAL_AGENT_HTTP_COST,
  TEST_INTERVAL_MIN,
  getDSCPOptions,

  DNS_RECORD_TYPES,
  ALLOWED_DNS_RESULTS_TYPES,
  SYN_CONSTANTS,
  AGENT_IMPLEMENTATIONS,
  AGENT_CAPABILITIES,
  TEST_STATUS,
  RECENTLY_ADDED_DAYS,

  COMMUNITY_TEST_SUMMARY,
  VAULT_MATCH_REGEX,
  VAULT_CAPTURE_REGEX,
  SYNTH_CONSUMER_TYPE,
  SYNTH_AGENT_CREDENTIAL_VERSION,
  SYNTH_AGENT_THROUGHPUT_VERSION,

  HEALTH,
  HEALTH_SORT,

  DEFAULT_LOOKBACK_OPTIONS,
  ALERT_MANAGER_APPLICATIONS,

  TREND_DETAIL_CHART_HEIGHT,
  TREND_CHART_HEIGHT,

  DEFAULT_THRESHOLD_MS_VALUES,
  DEFAULT_MBPS_VALUES
};
