import { Classes } from '@blueprintjs/core';
import { HEALTH_SORT } from 'shared/synthetics/constants';
import { formatDateTime } from 'core/util/dateUtils';

const FOCUS_TIME_RANGE_BUTTON_ID = 'synth-focus-time-range-button';

// renders a list item containing either a severity or metric count
function getAlarmItems(items) {
  return items
    .map(
      ([key, count]) => `
        <li class="${key}">
          <div class="label">${key.replaceAll('_', ' ')}</div>
          <div class="value">${count}</div>
        </li>
      `
    )
    .join('');
}

/*
  renders list of severities and/or metrics
  severities are sorted by 'health' status
  metrics are sorted by count
*/
function renderAlarmSummary(alarmSummary = {}) {
  const { severities = {}, metrics = {} } = alarmSummary;
  const severityList = Object.entries(severities);
  const metricList = Object.entries(metrics);
  let summary = '';

  if (severityList.length > 0) {
    const sortedSeverities = severityList.sort(
      ([health1], [health2]) => HEALTH_SORT.indexOf(health1) - HEALTH_SORT.indexOf(health2)
    );

    summary += `
      <ul class="severity">
        ${getAlarmItems(sortedSeverities)}
      </ul>
    `;
  }

  if (metricList.length > 0) {
    const sortedMetrics = metricList.sort(([, count1], [, count2]) => {
      if (count1 > count2) {
        return -1;
      }

      if (count1 < count2) {
        return 1;
      }

      return 0;
    });

    summary += `
      <ul class="metrics">
        ${getAlarmItems(sortedMetrics)}
      </ul>
    `;
  }

  return summary;
}

function renderRawHealthTimeRange({ testResults, time, alarms = [], health } = {}) {
  // test results are null-checked because bgp results are always raw so we don't bother sending in test results for that test type
  // we also don't use the 'Show Health' button in dashboards
  if (testResults?.canShowRawHealth) {
    // the default behavior is to jump to the currently hovered slice on the timeline
    let rawHealthTime = time;

    if (alarms.length > 0) {
      // start by getting the first alarm in the group by the worst health (found in the 'health' property)
      // if for some reason that doesn't work out, fall back to the first alarm
      const selectedAlarm = alarms.find((alarm) => alarm.severity === health) || alarms[0];

      if (typeof selectedAlarm?.start === 'number') {
        // we have a good unix timestamp, convert to ms
        rawHealthTime = selectedAlarm.start * 1000;
      }
    }

    return `<button type="button" id="${FOCUS_TIME_RANGE_BUTTON_ID}" class="${Classes.BUTTON} ${Classes.SMALL}" data-time="${rawHealthTime}">Show Health Data</button>`;
  }

  return '';
}

function tooltipPointFormatter(theme, userTimezone) {
  const { alarmSummary, options, testResults, alarms, health } = this;
  const { time } = options;

  // relies on css classes in _synth.scss
  return `
    <div class="${theme.name} synth-alarm-timeline">
      <div class="timestamp">${formatDateTime(time, 'dddd, MMM D, HH:mm:ss')} ${userTimezone}</div>
      ${renderAlarmSummary(alarmSummary)}
      <div>${renderRawHealthTimeRange({ testResults, time, alarms, health })}</div>
    </div>
  `;
}

export { FOCUS_TIME_RANGE_BUTTON_ID, tooltipPointFormatter };
