import * as React from 'react';
import { Box, Button, Collapse, Dialog, Flex, Heading, Icon, Tag, Text } from 'core/components';

export default class SidebarItem extends React.Component {
  static defaultProps = {
    isLast: false,
    openOnMount: false,
    keepChildrenUnMounted: false,
    showMaximizeButton: true,
    navigationButtons: null,
    excludeFormProps: false,
    panelTitle: null,
    collapsedPanelTitle: null,
    expandedPanelTitle: null,
    // determines whether we wrap the icon with the tag
    useIconTag: true,
    // when true, the tags that wrap sidebar item icons will use intent styles to denote (in)active states
    // when false, the intent styles are skipped altogether which is useful for custom icons that don't play well with darker backgrounds
    filledIconTag: true
  };

  state = {
    isOpen: false,
    isPoppedOut: false,
    formValues: null
  };

  constructor(props) {
    super(props);
    const { openOnMount } = props;

    if (openOnMount) {
      this.state.isOpen = true;
    }
  }

  handlePopOut = (e) => {
    e.stopPropagation();
    this.setState({ isPoppedOut: true }, this.handlePopOutChange);
  };

  handleFormChange = (formValues) => {
    this.setState({ formValues });
  };

  get title() {
    const { icon, title, collapsedPanelTitle, expandedPanelTitle, useIconTag, filledIconTag } = this.props;
    const { isOpen } = this.state;
    let iconTag = null;
    let titleCmp = title;

    if (icon) {
      iconTag = icon;

      if (useIconTag) {
        const tagStyle = filledIconTag ? {} : { background: 'none' };

        iconTag = (
          <Tag intent={isOpen ? 'primary' : 'none'} style={tagStyle} mx="4px" small>
            <Icon icon={icon} iconSize={14} my="2px" />
          </Tag>
        );
      }
    }

    if (typeof title === 'string') {
      titleCmp = (
        <Heading level={5} mb={0}>
          {title}
        </Heading>
      );
    }

    if (collapsedPanelTitle || expandedPanelTitle) {
      if (isOpen && expandedPanelTitle) {
        titleCmp = expandedPanelTitle;
      } else if (!isOpen && collapsedPanelTitle) {
        titleCmp = collapsedPanelTitle;
      }
    }

    return (
      <Flex alignItems="center" width="100%">
        {iconTag}
        {titleCmp}
      </Flex>
    );
  }

  handleTitleClick = () => {
    const { onExpandChange } = this.props;

    this.setState((prevState) => {
      const nextOpenState = !prevState.isOpen;

      if (onExpandChange) {
        onExpandChange(nextOpenState);
      }

      return { isOpen: nextOpenState };
    });
  };

  handlePopOutChange = () => {
    const { onPopOutChange } = this.props;
    const { isPoppedOut } = this.state;

    if (onPopOutChange) {
      onPopOutChange(isPoppedOut);
    }
  };

  render() {
    const {
      title,
      isLast,
      children,
      popoutTitle,
      dialogProps,
      fullscreenProps,
      navigationButtons,
      showMaximizeButton,
      excludeFormProps,
      keepChildrenUnMounted,
      query,
      onExpandChange,
      onPopOutChange,
      ...boxProps
    } = this.props;
    const { isOpen, isPoppedOut, formValues } = this.state;
    if (isPoppedOut) {
      return (
        <Dialog
          title={
            <Flex alignItems="center" width="100%" gap={1}>
              {popoutTitle}
              <Text>{title}</Text>
            </Flex>
          }
          minHeight={300}
          maxHeight="calc(100vh - 150px)"
          width="calc(100vw - 150px)"
          isOpen={isPoppedOut}
          onClose={() => this.setState({ isPoppedOut: false }, this.handlePopOutChange)}
          canEscapeKeyClose
          {...dialogProps}
        >
          <Dialog.Body>
            {React.cloneElement(children, {
              ...fullscreenProps,
              ...(!excludeFormProps ? { onFormChange: this.handleFormChange } : {}),
              formValues,
              isPoppedOut
            })}
          </Dialog.Body>
        </Dialog>
      );
    }

    return (
      <Box borderBottom={isOpen && !isLast ? 'thin' : ''} {...boxProps}>
        <Flex
          cursor="pointer"
          p={1}
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          borderBottom={isOpen || isLast ? '' : 'thin'}
          onClick={this.handleTitleClick}
        >
          <Flex width="100%" alignItems="center" gap="4px">
            <Icon icon={`caret-${isOpen ? 'down' : 'right'}`} color={isOpen ? 'primary' : 'muted'} />
            {this.title}
          </Flex>
          <Flex alignItems="center" gap="4px">
            {navigationButtons}
            {showMaximizeButton && <Button icon="maximize" onClick={this.handlePopOut} minimal small />}
          </Flex>
        </Flex>
        <Collapse isOpen={isOpen} keepChildrenMounted={!keepChildrenUnMounted}>
          <Box p={1}>
            {React.cloneElement(children, {
              formValues,
              isPoppedOut,
              isOpen,
              ...(!excludeFormProps ? { onFormChange: this.handleFormChange } : {})
            })}
          </Box>
        </Collapse>
      </Box>
    );
  }
}
