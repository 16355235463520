import React from 'react';
import { observer } from 'mobx-react';
import { Box, Text } from 'core/components';
import KeyValueTable from 'core/components/KeyValueTable';
import { hexToMac } from 'core/util/macAddress';

const InterfaceStatus = ({ interfaceKvs }) => {
  function statusRenderer(status) {
    switch (status) {
      case '1':
        return 'Up (1)';
      case '2':
        return 'Down (2)';
      case '3':
        return 'Testing (3)';
      default:
        return `UNKNOWN (${status})`;
    }
  }

  const statusKeyValuePairs = [
    {
      key: 'Admin Status',
      value: (interfaceKvs && statusRenderer(interfaceKvs.ifAdminStatus)) || <Text muted>N/A</Text>
    },
    {
      key: 'Operating Status',
      value: (interfaceKvs && statusRenderer(interfaceKvs.ifOperStatus)) || <Text muted>N/A</Text>
    }
  ];

  return (
    <>
      <Text as="div" fontWeight="bold" fontSize={16} mb={1}>
        Status
      </Text>

      <KeyValueTable keyValuePairs={statusKeyValuePairs} />
    </>
  );
};

const InterfaceLLDP = ({ interfaceKvs }) => {
  function subTypeRenderer(subType) {
    switch (subType) {
      case '1':
        return 'interfaceAlias (1)';
      case '2':
        return 'portComponent (2)';
      case '3':
        return 'macAddress (3)';
      case '4':
        return 'networkAddress (4)';
      case '5':
        return 'interfaceName (5)';
      case '6':
        return 'agentCircuitId (6)';
      case '7':
        return 'local (7)';
      default:
        return `UNKNOWN (${subType})`;
    }
  }

  function portIdRenderer(subType, portId) {
    switch (subType) {
      case '3':
        return hexToMac(portId);
      default:
        return portId;
    }
  }

  const lldpKeyValuePairs = [
    {
      key: 'Remote SysName',
      value: interfaceKvs?.lldpRemSysName
    },
    {
      key: 'Remote Port ID',
      value: interfaceKvs && portIdRenderer(interfaceKvs.lldpRemPortIdSubtype, interfaceKvs.lldpRemPortId)
    },
    {
      key: 'Remote Port ID Subtype',
      value: interfaceKvs && subTypeRenderer(interfaceKvs.lldpRemPortIdSubtype)
    },
    {
      key: 'Remote Port Description',
      value: interfaceKvs?.lldpRemPortDesc
    }
  ];

  return (
    <>
      <Text as="div" fontWeight="bold" fontSize={16} mt={2} mb={1}>
        LLDP Neighbors
      </Text>

      <KeyValueTable keyValuePairs={lldpKeyValuePairs} />
    </>
  );
};

const InterfaceKvsMetadata = ({ selectedInterface }) => {
  const { interfaceKvs } = selectedInterface.attributes;
  const hasLLDP = interfaceKvs?.lldpRemPortId !== undefined;

  return (
    <Box flex={1} pl={3}>
      <InterfaceStatus interfaceKvs={interfaceKvs} />
      {hasLLDP && <InterfaceLLDP interfaceKvs={interfaceKvs} />}
    </Box>
  );
};

export default observer(InterfaceKvsMetadata);
