import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';

import { getTrend } from 'app/util/queryResults';
import { Box, Icon, Text } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';

import TabView from './TabView';

function getTabQuery(filters) {
  return {
    all_devices: true,
    metric: ['Traffic'],
    aggregateTypes: ['p95th_bits_per_sec'],
    show_overlay: true,
    filters: {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          ...filters // filters get inserted here
        }
      ]
    }
  };
}

function calculateTrendData(data, overlayDay) {
  const { value, percentage, direction } = getTrend(data, 'p95th_bits_per_sec');

  return {
    value,
    percentage,
    direction,
    text: `${percentage}% (${overlayDay}d)`
  };
}

const StyledTab = styled(Box)`
  border-bottom: 2px solid transparent;
  background-color: ${themeGet('colors.white')};
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid ${themeGet('borderColors.thin')};
  }

  ${({ active }) =>
    active &&
    css`
      border-bottom: 2px solid ${themeGet('colors.primary')} !important;
      cursor: default;
    `};
`;

@inject('$colors')
@observer
class Tab extends Component {
  @computed
  get color() {
    const { $colors, name, viewProps, index } = this.props;

    if (name) {
      let color = $colors.getTabColor(name);

      if (viewProps && viewProps.useQualitativeColors && typeof index !== 'undefined') {
        color = $colors.getQualitativeTabColor(name);
      }

      if (color) {
        return color;
      }
    }

    return null;
  }

  render() {
    const { value, title, unit, filters, onTabChange, active, noTrend } = this.props;

    if (value === null) {
      return null;
    }

    return noTrend ? (
      <StyledTab px={2} py={1} onClick={() => onTabChange(title)} tabIndex={0} active={active}>
        <TabView
          title={
            <>
              <Icon icon="full-circle" color={this.color} iconSize={10} mr="4px" />
              <Text muted small>
                {title}
              </Text>
            </>
          }
          value={value}
          unit={unit}
        />
      </StyledTab>
    ) : (
      <LightweightDataViewWrapper title="TabbedChart" query={getTabQuery(filters)}>
        {({ results, loading: loadingTrendData, queryModel }) => {
          const raw = results.getRawData(true);
          const overlayDay = Math.abs(queryModel.get('overlay_day'));
          const { direction, text } = calculateTrendData(raw, overlayDay);

          return (
            <StyledTab p={2} onClick={() => onTabChange(title)} tabIndex={0} active={active}>
              <TabView
                title={
                  <>
                    <Icon icon="full-circle" color={this.color} iconSize={10} mr="4px" />
                    <Text muted small>
                      {title}
                    </Text>
                  </>
                }
                value={value}
                unit={unit}
                text={text}
                direction={direction}
                loadingTrendData={loadingTrendData}
              />
            </StyledTab>
          );
        }}
      </LightweightDataViewWrapper>
    );
  }
}

export default Tab;
