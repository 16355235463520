// a higher order component that uses a chart as the primary (detail) chart while using it as a template to construct a trend chart with an expanded query window
import React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { transparentize } from 'polished';
import { TREND_DETAIL_CHART_HEIGHT, TREND_CHART_HEIGHT } from 'shared/synthetics/constants';
import { Box } from 'core/components';
import { LoadingPanel } from 'app/views/synthetics/components/testResults/panels';
import TrendChartContainer from './TrendChartContainer';

export default function withTrendChart(WrappedComponent) {
  @withTheme
  @inject('$app')
  @observer
  class WrapperComponent extends React.Component {
    detailChartRef = React.createRef();

    trendChartRef = React.createRef();

    componentDidMount() {
      this.plotBandDisposer = reaction(
        () => {
          const { $app, test } = this.props;

          return {
            selectedIndex: test?.agentResults?.selectedTrendDetailIndex,
            darkTheme: $app.darkThemeEnabled
          };
        },
        () => this.updatePlotBands()
      );

      this.hideTrendChartLegend();

      this.trendChart?.update({
        chart: {
          height: TREND_CHART_HEIGHT,
          spacingLeft: 35,
          spacingRight: 15
        },
        tooltip: { enabled: false },
        xAxis: {
          crosshair: false,
          plotBands: this.plotBands
        },
        yAxis: {
          visible: false
        },
        plotOptions: {
          column: {
            pointWidth: 10
          },
          series: {
            states: {
              select: {
                enabled: false
              }
            }
          }
        }
      });
    }

    componentDidUpdate() {
      this.hideTrendChartLegend();
    }

    componentWillUnmount() {
      if (this.plotBandDisposer) {
        this.plotBandDisposer();
      }
    }

    hideTrendChartLegend = () => {
      this.trendChart?.update({
        legend: { enabled: false }
      });
    };

    updatePlotBands = () => {
      this.trendChart?.update({
        xAxis: {
          plotBands: this.plotBands
        }
      });
    };

    get trendChart() {
      return this.trendChartRef?.current?.chart;
    }

    get plotBands() {
      const { theme, test } = this.props;
      const highlightColor = theme.colors.primary;

      return test.agentResults.trendIntervals.map((interval, id) => {
        const isSelected = test.agentResults.isTrendDetailSelected({ id });

        return {
          id,
          from: interval.fromMs,
          to: interval.toMs,
          borderWidth: 1,
          // a selected interval will have a transparent body and blue border
          borderColor: isSelected ? highlightColor : 'transparent',
          color: isSelected ? 'transparent' : transparentize(0.25, theme.colors.appBackground),
          zIndex: 20, // the max recommended by highcharts for plotbands
          events: {
            mouseover: function mouseOverHandler() {
              this.svgElem.attr({ stroke: highlightColor, cursor: 'pointer' });
            },
            mouseout: function mouseOutHandler() {
              this.svgElem.attr({ stroke: 'transparent' });
            },
            click: function handleClick(e) {
              e.stopPropagation();

              test.agentResults.fetchTrendDetailResults({ id });
            }
          }
        };
      });
    }

    get loading() {
      const { test } = this.props;
      return !!test?.agentResults?.loadingTrendDetailResults;
    }

    render() {
      const { trendResults, dataKey, test } = this.props;
      const isAggregated = test?.agentResults?.isAggregated;
      const detailChart = <WrappedComponent ref={this.detailChartRef} isAggregated={isAggregated} {...this.props} />;

      return (
        <TrendChartContainer>
          <Box className="detail">
            <LoadingPanel loading={this.loading} height={TREND_DETAIL_CHART_HEIGHT}>
              {detailChart}
            </LoadingPanel>
          </Box>
          {trendResults && (
            <Box className="trend">
              {React.cloneElement(detailChart, {
                ref: this.trendChartRef,
                data: trendResults[dataKey],
                onHover: null,
                ...test.agentResults.trendTimelineBounds
              })}
            </Box>
          )}
        </TrendChartContainer>
      );
    }
  }

  return WrapperComponent;
}
