import React from 'react';

import { Card, Flex, Heading, Icon, Link, Spinner, Text } from 'core/components';

import ModuleLinkButton from './ModuleLinkButton';

function SidebarCard({ icon, title, loading, notConfiguredText, isConfigured, children, to, headingLink, ...rest }) {
  const showHeadingLink = to && headingLink;
  return (
    <Card bg="subnavBackground" position="relative" p={2} {...rest}>
      <Flex alignItems="center" mb={1}>
        {icon && <Icon icon={icon} color="warning" iconSize={18} mr={1} />}
        {showHeadingLink && (
          <Heading level={4} mb={0}>
            <Link to={to}>{title}</Link>
          </Heading>
        )}
        {!showHeadingLink && (
          <Heading level={4} mb={0}>
            {title}
          </Heading>
        )}
      </Flex>
      {loading && (
        <Flex alignItems="center">
          <Spinner size={12} mr={1} />
          <Text color="muted">Loading...</Text>
        </Flex>
      )}
      {!loading && !isConfigured && (
        <>
          <Text color="muted" as="div" my={2}>
            {notConfiguredText}
          </Text>
          <ModuleLinkButton text={`Setup ${title}`} to={to} />
        </>
      )}
      {!loading && isConfigured && children}
    </Card>
  );
}

SidebarCard.defaultProps = {
  minHeight: 130
};

export default SidebarCard;
