import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Classes } from '@blueprintjs/core';

import { Box, Card, Collapse, Flex, Text } from 'core/components';
import { Field, formConsumer, FormGroup, InputGroup, Select, Slider, Switch } from 'core/form';
import { getQueryTimeInterval } from 'core/util/dateUtils';
import DimensionSelector from 'app/components/dimensions/DimensionSelector';
import { getVisualizationDepthLabel } from 'app/stores/query/ExplorerQueryModel';
import { getAppProtocol } from 'app/util/dimensions';

import { decoupleFromFlow, getFlowAggIntervalOptions } from '@kentik/ui-shared/query/queryUtils';
import CutFnSection from './CutFnSection';

@inject('$auth', '$dataviews', '$dictionary')
@formConsumer
@observer
class AdvancedOptions extends Component {
  static defaultProps = {
    fieldName: 'filters',
    smallText: true,
    emptyText: 'No filtering criteria have been applied',
    readOnly: false,
    showNames: false
  };

  componentDidMount() {
    const { form, $dictionary } = this.props;

    if (!$dictionary.get('asGroups.populated')) {
      const customAsGroups = form.getField('customAsGroups');
      customAsGroups.disable();
      customAsGroups.setValue(false);
    }
  }

  getFastDataOptions() {
    const { form, allowFastDataOverride } = this.props;
    const appProtocol = getAppProtocol(form.getValue('metric'), form.getValue('filters.filterGroups'));
    const lookback_seconds = form.getValue('lookback_seconds');
    const starting_time = form.getValue('starting_time');
    const ending_time = form.getValue('ending_time');
    const fastDataField = form.getField('fastData');
    const interval = getQueryTimeInterval({ lookback_seconds, starting_time, ending_time });

    const options = [{ value: 'Auto', label: 'Auto' }];
    if (appProtocol && appProtocol.metadata.fullDataOnly) {
      fastDataField.setValue('Full'); // If it's the only option, we better set it.
      return [{ value: 'Full', label: 'Full' }];
    }

    if (allowFastDataOverride || (interval >= 3 * 3600 && interval <= 75 * 24 * 3600)) {
      return options.concat({ value: 'Fast', label: 'Fast' }, { value: 'Full', label: 'Full' });
    }

    // If field is set to Full/Fast and these aren't valid, fix it!
    if (fastDataField.getValue() !== 'Auto') {
      fastDataField.setValue('Auto');
    }

    return options;
  }

  getFlowAggDisabled() {
    const { form } = this.props;
    const appProtocolId = getAppProtocol(form.getValue('metric'), form.getValue('filters.filterGroups'), true);

    if (appProtocolId) {
      const lookback_seconds = form.getValue('lookback_seconds');
      const starting_time = form.getValue('starting_time');
      return decoupleFromFlow({ lookback_seconds, starting_time }, [appProtocolId]);
    }

    return false;
  }

  getFlowAggWindowOptions() {
    const { form } = this.props;
    const dataseriesAgg = form.getValue('fastData');

    const lookback_seconds = form.getValue('lookback_seconds');
    const starting_time = form.getValue('starting_time');
    const ending_time = form.getValue('ending_time');
    const interval = getQueryTimeInterval({ lookback_seconds, starting_time, ending_time });

    const defaultOption = {
      value: 0,
      label: 'Auto'
    };

    if (dataseriesAgg === 'Fast' || (dataseriesAgg === 'Auto' && (interval < 60 || interval > 86400))) {
      return [
        defaultOption,
        {
          value: 1,
          label: '1 Hour'
        },
        {
          value: 6,
          label: '6 Hours'
        },
        {
          value: 12,
          label: '12 Hours'
        }
      ];
    }

    return [
      defaultOption,
      {
        value: 1,
        label: '1 Min'
      },
      {
        value: 5,
        label: '5 Min'
      },
      {
        value: 10,
        label: '10 Min'
      },
      {
        value: 20,
        label: '20 Min'
      }
    ];
  }

  getFlowAggIntervalSelectOptions() {
    const { $auth, form } = this.props;

    const fastData = form.getValue('fastData');

    const lookback_seconds = form.getValue('lookback_seconds');
    const starting_time = form.getValue('starting_time');
    const ending_time = form.getValue('ending_time');

    const minsPolling = form.getValue('minsPolling');

    const depth = form.getValue('depth');

    const filterGroups = form.getValue('filters.filterGroups');
    const reAggFn = form.getValue('reAggFn');

    const allow30sPolicyWindow = $auth.hasPermission('alerts.allow30sPolicyWindow');

    return getFlowAggIntervalOptions({
      allow30sPolicyWindow,
      fastData,
      lookback_seconds,
      starting_time,
      ending_time,
      minsPolling,
      depth,
      filterGroups,
      reAggFn
    });
  }

  visualizationDepthLabel = (value) => (
    <div>
      {value}
      <div className="description">{getVisualizationDepthLabel(value)}</div>
    </div>
  );

  generatorMinHeightLabelRenderer = (value) => {
    const heightMap = {
      250: 'Short',
      375: 'Medium',
      500: 'Tall'
    };
    return <div>{heightMap[value] || value}</div>;
  };

  render() {
    const { $auth, $dataviews, disabledDimensions, form } = this.props;

    const viz_type = form.getValue('viz_type');
    const showOverlayOn = form.getValue('show_overlay');
    const generatorMode = form.getValue('generatorMode');
    const generatorDimensions = form.getValue('generatorDimensions');
    const isAggTotal = form.getValue('outsort').includes('agg_total');
    const period_over_period = form.getValue('period_over_period');

    const { suppressGeneratorMode, showTotalTrafficOverlay, mirrorable } = $dataviews.getConfig(viz_type);

    const hasGeneratorDimensions = !!generatorMode && !!generatorDimensions && generatorDimensions.length > 0;
    const disableFlowAgg = this.getFlowAggDisabled();

    let topxMax = 40;
    if (viz_type === 'table') {
      if (isAggTotal) {
        topxMax = 50000;
      } else {
        topxMax = 350;
      }
    }

    const StandardQueryControls = (
      <>
        {showTotalTrafficOverlay && (
          <Field showLabel={false} name="show_total_overlay" small mb={1}>
            <Switch switchLabel="Show Total Overlay" />
          </Field>
        )}
        <Field showLabel={false} name="show_overlay" small disabled={period_over_period} mb={1}>
          <Switch switchLabel="Show Historical Overlay" />
        </Field>
        {showOverlayOn && (
          <Field inline name="overlay_day" small mb={1} ml={4}>
            <InputGroup />
          </Field>
        )}
        <Field showLabel={false} name="hostname_lookup" small mb={1}>
          <Switch switchLabel="Enable Reverse DNS Lookups" />
        </Field>
        <Field showLabel={false} name="customAsGroups" small mb={1}>
          <Switch switchLabel="Use AS Groups" />
        </Field>
        {mirrorable && (
          <Flex>
            <Field showLabel={false} flex={1} name="mirror" disabled={period_over_period} small mb={1}>
              <Switch switchLabel="Bi-Directional Mode" />
            </Field>
            {/* do not delete this, we're going to re-enable it later */}
            {/* {mirror && */}
            {/* <Field flex={1} name="mirrorUnits"> */}
            {/* <Switch /> */}
            {/* </Field>} */}
          </Flex>
        )}
      </>
    );

    const GeneratorMode = (
      <>
        {!suppressGeneratorMode && (
          <Box className={generatorMode ? Classes.CALLOUT : ''}>
            <Field
              showLabel={false}
              name="generatorMode"
              mb={generatorMode ? undefined : 0}
              disabled={period_over_period}
              small
            >
              <Switch switchLabel="Generate One Chart Per Series" />
            </Field>
            <Collapse isOpen={generatorMode}>
              <>
                <Field name="generatorQueryTitle" small>
                  <InputGroup />
                </Field>
                <Field name="generatorColumns" className="viz-depth-input" mb={0}>
                  {({ field, name, ...rest }) => (
                    <FormGroup labelFor={`field-${name}`} {...rest} label={<Text small>{field.label}</Text>}>
                      <Box pl="12px" pr="12px">
                        <Slider {...rest} min={1} max={4} stepSize={1} labelStepSize={1} />
                      </Box>
                    </FormGroup>
                  )}
                </Field>
                <Field name="generatorPanelMinHeight" mb={0}>
                  {({ field, name, ...rest }) => (
                    <FormGroup labelFor={`field-${name}`} {...rest} label={<Text small>{field.label}</Text>}>
                      <Box pl="12px" pr="12px">
                        <Slider
                          {...rest}
                          min={250}
                          max={500}
                          stepSize={125}
                          labelStepSize={125}
                          labelRenderer={this.generatorMinHeightLabelRenderer}
                        />
                      </Box>
                    </FormGroup>
                  )}
                </Field>
                <Box flex={1} mr={1}>
                  <DimensionSelector
                    title="Group By Dimensions for Generated Panels"
                    showClearButton
                    fieldName="generatorDimensions"
                    disabledValues={disabledDimensions}
                    showEditButton
                    editButtonText="Edit Chart-level Dimensions"
                    small
                  />
                </Box>
                {hasGeneratorDimensions && (
                  <Field name="generatorTopx" className={`render-topx-max-change-${topxMax}`}>
                    {({ field, name, ...rest }) => (
                      <FormGroup
                        labelFor={`field-${name}`}
                        {...rest}
                        label={<Text small>{field.label}</Text>}
                        mt={2}
                        mb={0}
                      >
                        <Box pl="12px" pr="12px" pb="10px">
                          <Slider
                            {...rest}
                            min={1}
                            max={topxMax}
                            stepSize={1}
                            labelStepSize={topxMax - 1}
                            labelRenderer={this.visualizationDepthLabel}
                          />
                        </Box>
                      </FormGroup>
                    )}
                  </Field>
                )}
              </>
            </Collapse>
          </Box>
        )}
      </>
    );

    const BaselineControls = (
      <>
        <Box mt={1}>
          <Flex as={Text} mb="4px" small lineHeight={1} fontWeight="600">
            Baselining
          </Flex>
          <Card p={1}>
            <Field name="tsp.baseline.enabled" small mb={1}>
              <Switch />
            </Field>
            <Field name="tsp.baseline.seasonalities" small mb={0}>
              <InputGroup />
            </Field>
          </Card>
        </Box>
      </>
    );

    const ForecastControls = (
      <>
        <Box mt={1}>
          <Flex as={Text} mb="4px" small lineHeight={1} fontWeight="600">
            Forecasting
          </Flex>
          <Card p={1}>
            <Field name="tsp.forecasting.enabled" small mb={1}>
              <Switch />
            </Field>
            <Field name="tsp.forecasting.samples" small mb={1}>
              <InputGroup />
            </Field>
            <Field name="tsp.forecasting.seasonalities" small mb={0}>
              <InputGroup />
            </Field>
          </Card>
        </Box>
      </>
    );

    const TimeSeriesControls = (
      <>
        <Box mt={1}>
          <Flex as={Text} mb="4px" small lineHeight={1} fontWeight="600">
            Time Series
          </Flex>
          <Card p={1}>
            <Field name="minsPolling" options={this.getFlowAggWindowOptions()} disabled={disableFlowAgg} small mb={1}>
              <Select />
            </Field>

            <Flex mb={0}>
              <Field name="reAggFn" disabled={disableFlowAgg} small mb={0} mr={1}>
                <Select menuWidth={100} />
              </Field>
              {form.getValue('reAggFn') !== 'none' && (
                <Field
                  name="reAggInterval"
                  options={this.getFlowAggIntervalSelectOptions()}
                  disabled={disableFlowAgg}
                  small
                  mb={0}
                >
                  <Select menuWidth={100} />
                </Field>
              )}
            </Flex>
          </Card>
        </Box>
      </>
    );

    return (
      <>
        <Field name="fastData" options={this.getFastDataOptions()} small>
          <Select />
        </Field>

        {StandardQueryControls}
        {GeneratorMode}
        <CutFnSection />
        {$auth.hasPermission('tsp') && (
          <>
            {BaselineControls}
            {ForecastControls}
          </>
        )}
        {TimeSeriesControls}
      </>
    );
  }
}

export default AdvancedOptions;
