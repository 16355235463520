// Traverse filterGroups to get all the filter fields as an array
// Optionally set `includeFilterValue` to true for an array of `{filterField:filterValue}` objects
function getFilterFields(groups, includeFilterValue) {
  const filterFields = new Set();

  if (Array.isArray(groups)) {
    groups
      .filter((group) => group)
      .forEach((group) => {
        if (Array.isArray(group.filters)) {
          group.filters
            .filter((filter) => filter)
            .forEach((filter) => {
              if (includeFilterValue) {
                const { filterField, filterValue } = filter;
                filterFields.add({ filterField, filterValue });
              } else {
                filterFields.add(filter.filterField);
              }
            });

          getFilterFields(group.filterGroups || [], includeFilterValue)
            .filter((filter) => filter)
            .forEach((field) => filterFields.add(field));
        }
      });
  }

  return Array.from(filterFields);
}

module.exports = getFilterFields;
