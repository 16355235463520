import React from 'react';
import { observer } from 'mobx-react';
import { Flex, FlexColumn, Icon, Text, Tooltip } from 'core/components';
import { greekIt } from 'app/util/utils';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';

const InterfaceFlowStatus = observer(({ status, model, hideThresholdText }) => {
  const { fetchedFlow } = model.collection;

  const hasFlow = status.flowMbps;
  const hasSNMP = status.snmpMbps;
  const isAboveThresholds = status.error && status.error.isAboveThresholds;
  const hasErrorPercentage = status.error && status.error.percentage;

  let snmpTooltip = null;
  if (hasSNMP && hideThresholdText && hasErrorPercentage && isAboveThresholds) {
    snmpTooltip = `Traffic ${status.error.percentage}% different than flow!`;
  }

  if (!fetchedFlow) {
    return '-';
  }

  return (
    <FlexColumn alignItems="flex-end">
      <Flex gap="4px" overflow="hidden">
        <Text fontWeight="normal" muted={!hasFlow} small whiteSpace="nowrap">
          {hasFlow
            ? `${greekIt(status.flowMbps * 1_000_000, { fix: 1, forcePrefix: status.prefix, suffix: 'bps' }).displayFull}`
            : '-'}
        </Text>
      </Flex>
      <Flex gap="4px" overflow="hidden">
        {isAboveThresholds && (
          <Tooltip content={snmpTooltip}>
            <Icon iconSize={12} icon={BsFillExclamationTriangleFill} color="danger" />
          </Tooltip>
        )}
        <Text fontWeight="normal" muted={!hasSNMP} small whiteSpace="nowrap">
          {hasSNMP
            ? `${greekIt(status.snmpMbps * 1_000_000, { fix: 1, forcePrefix: status.prefix, suffix: 'bps' }).displayFull}`
            : '-'}
          {!hideThresholdText && hasErrorPercentage && (
            <Text as="div" small>
              ({status.error.percentage}%{isAboveThresholds && ' Outside of Threshold'})
            </Text>
          )}
        </Text>
      </Flex>
    </FlexColumn>
  );
});

export default InterfaceFlowStatus;
