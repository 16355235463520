import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { orderBy } from 'lodash';
import { Field, Select } from 'core/form';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$dashboards')
@observer
class DashboardSelector extends Component {
  static defaultProps = {
    onlyEditable: false,
    showPresets: true,
    menuWidth: 300,
    fill: false,
    large: false,
    placeholder: 'Select a dashboard...',
    tetherOptions: {
      offset: '-3px 0',
      constraints: [{ attachment: 'together', pin: true, to: 'scrollParent' }]
    }
  };

  render() {
    const { $dashboards, fill, large, onlyEditable, showPresets, hiddenDashboards, menuWidth } = this.props;

    let dashboardOptions = $dashboards.collection.get();

    if (onlyEditable) {
      dashboardOptions = dashboardOptions.filter((dash) => dash.canEdit);
    }

    if (!showPresets) {
      dashboardOptions = dashboardOptions.filter((dash) => !dash.isPreset);
    }

    if (hiddenDashboards) {
      dashboardOptions = dashboardOptions.filter((dash) => hiddenDashboards.indexOf(dash.id) === -1);
    }

    const dashboards = orderBy(
      dashboardOptions,
      [(model) => model.getSortValue('sortableShareLevel'), (model) => model.getSortValue('dash_title')],
      ['asc']
    );

    const options = $dashboards.getDashboardOptions(dashboards);

    return (
      <Field {...this.props} options={options} showFilter large={large}>
        <Select showFilter menuWidth={fill ? undefined : menuWidth} fill={fill} />
      </Field>
    );
  }
}

export default DashboardSelector;
