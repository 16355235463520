import React from 'react';
import { observer } from 'mobx-react';
import { Menu } from 'core/components';
import JourneyListItem from './JourneyListItem';

const JourneyList = ({
  journeys,
  hideUserName = false,
  hideEditedDate = false,
  hideShareLevel = false,
  onSelectJourney
}) => (
  <Menu p={0}>
    {journeys.map((journey) => (
      <JourneyListItem
        journey={journey}
        hideUserName={hideUserName}
        hideEditedDate={hideEditedDate}
        hideShareLevel={hideShareLevel}
        key={journey.id}
        onSelectJourney={onSelectJourney}
      />
    ))}
  </Menu>
);

export default observer(JourneyList);
