export const region = [
  'kt_aws_src_region',
  'kt_aws_dst_region',
  'ktsubtype__gcp_subnet__STR04',
  'ktsubtype__gcp_subnet__STR05',
  'kt_az_src_region',
  'kt_az_dst_region'
];
export const zone = [
  'ktsubtype__gcp_subnet__STR06',
  'ktsubtype__gcp_subnet__STR07',
  'kt_az_src_zone',
  'kt_az_src_zone',
  'kt_aws_src_zone',
  'kt_aws_dst_zone'
];
export const zone_id = ['kt_aws_src_zone_id', 'kt_aws_dst_zone_id'];
export const instance = ['kt_aws_src_vm_name', 'kt_aws_dst_vm_name', 'kt_az_src_inst_name', 'kt_az_dst_inst_name'];
export const security_group = [
  'kt_aws_src_sg',
  'kt_aws_dst_sg',
  'ktsubtype__azure_subnet__STR05',
  'ktsubtype__azure_subnet__STR04'
];
export const public_dns = ['kt_aws_src_pub_dns', 'kt_aws_dst_pub_dns', 'kt_az_src_fqdn', 'kt_az_dst_fqdn'];
export const private_dns = ['kt_aws_src_priv_dns', 'kt_aws_dst_priv_dns'];
export const vpc_id = ['kt_aws_src_vpc_id', 'kt_aws_dst_vpc_id'];
export const subnet_id = ['kt_aws_src_subnet_id', 'kt_aws_dst_subnet_id'];
export const interface_id = ['ktsubtype__aws_subnet__STR03'];
export const firewall_action = ['kt_aws_action', 'ktsubtype__azure_subnet__STR03', 'ktsubtype__azure_subnet__STR02'];
export const logging_status = ['kt_aws_status'];
export const project_id = ['ktsubtype__gcp_subnet__STR00', 'ktsubtype__gcp_subnet__STR01'];
export const vm_name = ['ktsubtype__gcp_subnet__STR02', 'ktsubtype__gcp_subnet__STR03'];
export const subnet_name = [
  'ktsubtype__gcp_subnet__STR08',
  'ktsubtype__gcp_subnet__STR09',
  'kt_az_src_subnet',
  'kt_az_dst_subnet'
];
export const reporter = ['ktsubtype__gcp_subnet__STR10'];
export const vnet_id = ['kt_az_src_vnet', 'kt_az_dst_vnet'];
export const public_ip = ['kt_az_src_public_ip', 'kt_az_dst_public_ip'];
export const subscription = ['kt_az_src_sub_id', 'kt_az_dst_sub_id'];
export const security_rule = ['ktsubtype__azure_subnet__STR01', 'ktsubtype__azure_subnet__STR00'];
export const all = [
  ...region,
  ...zone,
  ...zone_id,
  ...instance,
  ...security_group,
  ...public_dns,
  ...private_dns,
  ...vpc_id,
  ...subnet_id,
  ...interface_id,
  ...firewall_action,
  ...logging_status,
  ...project_id,
  ...vm_name,
  ...subnet_name,
  ...reporter,
  ...vnet_id,
  ...public_ip,
  ...subscription,
  ...security_rule
];
