import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';

import BollingerBandChart from 'app/views/synthetics/components/BollingerBandChart';
import { checkSeriesDataForError } from 'app/views/synthetics/utils/syntheticsUtils';

import withTrendChart from './withTrendChart';

@withTrendChart
@withTheme
@inject('$app', '$dataviews')
@observer
class PacketLossTimeSeries extends BollingerBandChart {
  static defaultProps = {
    isAggregated: false // we do not show health colors in aggregated results
  };

  get valueSuffix() {
    return '%';
  }

  renderData() {
    if (this.chart) {
      const { healthSettings, xAxisMin, xAxisMax, isAggregated } = this.props;
      const { packetLossCritical = 0, packetLossWarning = 0 } = healthSettings || {};
      const warningValue = packetLossWarning !== 0 ? packetLossWarning : 0.001;
      const criticalValue = packetLossCritical !== 0 ? packetLossCritical : 50;
      const {
        data,
        theme: { colors }
      } = this.props;
      const averages = [];

      data.forEach((point, i) => {
        const { time, packet_loss } = point;

        if (checkSeriesDataForError(point)) {
          averages.push({
            time,
            x: time,
            y: null
          });
        } else {
          const y = Math.trunc(packet_loss * 10000) / 100; // fix(2) here so tooltips don't show x.66666666666666666666666 %;

          let color = this.getColor('critical');

          if (y < warningValue) {
            color = colors.success;
          } else if (y < criticalValue) {
            color = this.getColor('warning');
          }

          averages.push({
            x: time,
            y,
            time,
            marker: {
              enabled: checkSeriesDataForError(data[i - 1]) || checkSeriesDataForError(data[i + 1]),
              states: {
                select: {
                  fillColor: isAggregated ? colors.muted : color,
                  lineColor: 'rgba(255, 255, 255, 0.5)',
                  lineWidth: 5,
                  radius: 8
                }
              }
            }
          });
        }
      });

      const zones = [
        {
          value: warningValue,
          color: isAggregated ? colors.muted : colors.success
        },
        {
          value: criticalValue,
          color: this.getColor('warning')
        },
        {
          color: this.getColor('critical')
        }
      ];
      this.setState({
        chartOptions: {
          series: [{ name: 'Packet Loss', zoneAxis: 'y', data: averages, zones }],
          yAxis: {
            max: 100,
            offset: 20,
            plotLines: [
              {
                value: criticalValue,
                color: this.getColor('critical'),
                dashStyle: 'shortdash',
                width: 2,
                label: {
                  style: {
                    color: this.getColor('critical')
                  },
                  x: 20,
                  text: 'Critical'
                }
              }
            ]
          },
          xAxis: {
            min: xAxisMin,
            max: xAxisMax
          }
        }
      });
    }
  }
}

export default PacketLossTimeSeries;
