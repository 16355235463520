import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Box, Button, Flex, Icon, Link, Text } from 'core/components';

import FavoriteButton from 'app/views/core/FavoriteButton';
import KentikLogo from 'app/components/KentikLogo';
import LabelList from 'app/components/labels/LabelList';

@observer
class ViewBox extends Component {
  render() {
    const { expanded, fieldMap, item, onExpand, onFavorite, style = {}, highlighted, toLink } = this.props;
    const { title, description } = fieldMap;

    return (
      <Flex border="thin" borderRadius="4px 4px 0 0" flexDirection="column" key={item.id} mt="-1px" p={2} style={style}>
        <Flex alignItems="center">
          <FavoriteButton model={item} minHeight={0} minWidth={0} mr={1} onFavorite={onFavorite} />
          <Flex gap={1} alignItems="center" width="90%">
            <Link
              alignItems="flex-start"
              fontWeight="bold"
              ellipsis
              whiteSpace="nowrap"
              overflow="hidden"
              to={toLink(item.id)}
              style={{ textDecoration: highlighted ? 'underline' : 'none' }}
            >
              {item.get(title)}
            </Link>
            {highlighted && (
              <Box ml="auto">
                <kbd className="bp4-key bp4-modifier-key">
                  <Icon icon="key-enter" />
                  enter
                </kbd>
              </Box>
            )}
          </Flex>
          <Button icon={expanded ? 'chevron-up' : 'chevron-down'} minimal ml="auto" onClick={() => onExpand()} />
        </Flex>

        <Flex ml="26px" flexDirection="column" gap="4px">
          <LabelList labels={item.labels} shareLevel={item.shareLevel} wrapLabels />

          {expanded && (
            <>
              {item.get(description)}

              {(item.author || item.shareLevel === 'Preset') && (
                <Text alignItems="center" ellipsis muted overflow="hidden" whiteSpace="nowrap" width="100%">
                  {item.shareLevel === 'Preset' ? (
                    <Flex alignItems="center">
                      <KentikLogo onlyMark alt="Kentik" fillColor="grey" style={{ maxWidth: 20 }} />
                      Kentik Preset
                    </Flex>
                  ) : (
                    item.author
                  )}
                </Text>
              )}
            </>
          )}
        </Flex>
      </Flex>
    );
  }
}

export default ViewBox;
