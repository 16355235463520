import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import moment from 'moment';
import { isNumber } from 'lodash';

import { Flex, Box, EmptyState, Spinner, Suspense } from 'core/components';
import { getStartAndEndFromLookback, timezone } from 'core/util/dateUtils';
import AlarmTimeline from 'app/views/synthetics/components/alarmTimeline/AlarmTimeline';
import AgentMesh from 'app/views/synthetics/components/mesh/AgentMesh';
import TestResultsTable from 'app/views/synthetics/components/testResults/TestResultsTable';
import AggregationTag from 'app/views/synthetics/components/AggregationTag';

@inject('$app', '$syn')
@observer
class SynthTestDashboardItemMobx extends Component {
  constructor(props) {
    super(props);

    this.fetch();

    const { dataview } = this.props;

    // Set up the reaction for updateFrequency
    this.updateFrequencyReaction = reaction(
      // Function that returns the value to track
      () => dataview.updateFrequency,
      // Function to run when the tracked value changes
      (updateFrequency) => {
        // Clear the old interval
        if (this.refreshInterval) {
          clearInterval(this.refreshInterval);
        }

        // Set a new interval if updateFrequency is set
        if (updateFrequency) {
          this.refreshInterval = setInterval(() => {
            this.fetch();
          }, updateFrequency * 1000); // Assuming updateFrequency is in seconds
        }
      }
    );

    // Set up the reaction for refresh
    this.refreshReaction = reaction(
      // Function that returns the value to track
      () => dataview.lastRefresh,
      () => {
        this.fetch();
      }
    );

    // Function to run when the tracked value changes
    this.queryUpdateReaction = reaction(
      () => dataview.query,
      () => {
        this.fetch();
      }
    );
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }

    // Dispose of the reaction when the component is unmounted
    if (this.updateFrequencyReaction) {
      this.updateFrequencyReaction();
    }

    // Dispose of the reaction when the component is unmounted
    if (this.refreshReaction) {
      this.refreshReaction();
    }
    // Dispose of the reaction when the component is unmounted
    if (this.queryUpdateReaction) {
      this.queryUpdateReaction();
    }
  }

  getDateOpts() {
    const { dataview } = this.props;
    const { lookback_seconds, starting_time, ending_time } = dataview.query;

    // NOTE: syngest is expecting a start_time/end_time in seconds, so we need to convert from lookback_seconds
    // These are special cases where we want to use the current month or last month's data
    let start = starting_time;
    let end = ending_time;
    if (lookback_seconds === 1 && !start && !end) {
      // Get the beginning of this month, to the current date
      start = timezone.momentFn().date(1).hour(0).minute(0).second(0).millisecond(0);
      end = timezone.momentFn();
    } else if (lookback_seconds === 2 && !start && !end) {
      // Get the beginning of this month, to the current date
      start = timezone.momentFn().date(1).hour(0).minute(0).second(0).millisecond(0).subtract(1, 'month');
      end = timezone.momentFn(start).date(31);
    } else if (lookback_seconds > 2 && !start && !end) {
      const { start: start_time, end: end_time } = getStartAndEndFromLookback(lookback_seconds, 60);
      start = start_time;
      end = end_time;
    }

    return {
      start_time: isNumber(start) ? start : moment.utc(start).unix(),
      end_time: isNumber(end) ? end : moment.utc(end).unix()
    };
  }

  fetch() {
    const { dataview, test } = this.props;
    const request = test.dataviews.get(dataview.hash);
    const { start_time, end_time, ...opts } = this.getDateOpts();

    if (request) {
      // TestResultsStateByTimestamp fetch takes in 'startDate' and 'endDate' params
      request.fetch({ startDate: start_time, endDate: end_time, ...opts });
    }
  }

  onChangeResultTimeMs = (resultTimeMs) => {
    const { dataview, test } = this.props;
    const view = test.dataviews.get(dataview.hash);
    view.onChangeResultTimeMs(resultTimeMs);
  };

  render() {
    const { $app, dataview, test } = this.props;
    const { synth_test_display, lookback_seconds } = dataview.query;
    const { start_time, end_time } = this.getDateOpts();
    const view = test.dataviews.get(dataview.hash);
    const {
      resultTimeMs,
      results,
      hasResults,
      timelineBounds,
      agentTaskConfig,
      healthTimeline,
      loadingTimestamps,
      loadingAlarmResults,
      loadingResults,
      mesh,
      isAggregated
    } = view; // view an instance of TestResultsStateByTimestamp
    const loadingTimeline = loadingTimestamps || loadingAlarmResults;
    const isHighDensityGridDisplay = synth_test_display === 'network-grid'; // legacy "network-grid" value represents all grid test types
    const isTableDisplay = synth_test_display === 'grid'; // legacy "grid" value represents table test types
    const isMeshDisplay = synth_test_display === 'mesh';

    return (
      <Flex flex={1} flexDirection="column">
        <Box position="absolute" top={18} right={16}>
          <AggregationTag isAggregated={isAggregated} />
        </Box>
        {loadingTimeline ? (
          <Suspense
            loading={loadingTimeline}
            fallback={
              <Flex justifyContent="center" alignItems="center">
                <Spinner size={32} />
              </Flex>
            }
          />
        ) : (
          <Flex px={2} mb={1} width="100%">
            <AlarmTimeline {...timelineBounds} data={healthTimeline} onSelect={this.onChangeResultTimeMs} />
          </Flex>
        )}
        {!loadingTimeline &&
          // eslint-disable-next-line no-nested-ternary
          (loadingResults ? (
            <Suspense
              loading={loadingResults}
              fallback={
                <Flex justifyContent="center" alignItems="center">
                  <Spinner size={32} />
                </Flex>
              }
            />
          ) : !!healthTimeline.length && hasResults ? (
            <Box mx={2}>
              {isMeshDisplay && (
                <AgentMesh
                  test={test}
                  data={mesh}
                  resultTimeMs={resultTimeMs}
                  lookbackSeconds={lookback_seconds}
                  startDate={start_time}
                  endDate={end_time}
                  test_id={test.id}
                  mx="auto"
                  isAggregated={isAggregated}
                />
              )}
              {(isTableDisplay || isHighDensityGridDisplay) && (
                <Box className="break-before">
                  <TestResultsTable
                    gridMargins={isHighDensityGridDisplay ? 1 : 0}
                    allowAgentDetails={!$app.isSharedLink}
                    showAllResultsTable={isTableDisplay}
                    showAllResultsTablePrefix={false}
                    showHighDensityGrid={isHighDensityGridDisplay}
                    showUnhealthyResults={false}
                    test={test}
                    results={results}
                    agentTaskConfig={agentTaskConfig}
                    resultTimeMs={resultTimeMs}
                    lookbackSeconds={lookback_seconds}
                    startDate={start_time}
                    endDate={end_time}
                    className="break-before"
                    mb={0}
                  />
                </Box>
              )}
            </Box>
          ) : (
            <Box px={4}>
              <EmptyState
                icon="disable"
                title="No results"
                description="Results are not currently available for this time range or this test no longer exists."
              />
            </Box>
          ))}
      </Flex>
    );
  }
}

export default SynthTestDashboardItemMobx;
