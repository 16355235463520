import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import { MdOpenInNew } from 'react-icons/md';
import { Box, Button, ButtonGroup } from 'core/components';
import Topology from './Topology';

@inject('$topo')
@observer
class EmbeddedTopo extends Component {
  static propTypes = {
    onOpen: PropTypes.func
  };

  static defaultProps = {
    onOpen: () => {}
  };

  state = {
    expanded: false
  };

  handleExpand = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded
    }));
  };

  render() {
    const { onOpen, expandable } = this.props;
    const { expanded } = this.state;

    let height = 600;
    if (expandable) {
      height = expanded ? 400 : 200;
    }

    return (
      <Box height={height} position="relative">
        <Topology enableMouseWheelZoom={false} embedded />

        <Box position="absolute" top="16px" left="16px">
          {expandable && (
            <ButtonGroup vertical>
              <Button icon={MdOpenInNew} onClick={onOpen} />
              <Button icon={expanded ? 'collapse-all' : 'expand-all'} onClick={this.handleExpand} />
            </ButtonGroup>
          )}
          {!expandable && <Button icon={MdOpenInNew} onClick={onOpen} />}
        </Box>
      </Box>
    );
  }
}

export default EmbeddedTopo;
