import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { KeyCombo as BlueprintKeyCombo } from '@blueprintjs/core';

import position from './utils/position';

const StyledKeyCombo = styled(BlueprintKeyCombo)`
  ${({ showIcon = true }) => `
      ${space};
      ${position};

      &.inline {
        display: inline-flex;
      }

      ${
        !showIcon &&
        `
        .bp4-icon {
          display: none;
        }
      `
      }
    `};
`;

const KeyCombo = (props) => {
  const { inline, ...rest } = props;
  return <StyledKeyCombo {...rest} className={inline ? 'inline' : undefined} />;
};

export default KeyCombo;
