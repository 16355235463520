import React from 'react';
import { inject } from 'mobx-react';

import Link from 'core/components/Link';

const AsPathLink = ({ $app, asPath, ...rest }) => {
  if ($app.isSubtenant) {
    return asPath;
  }

  return (
    <Link to={`/v4/core/quick-views/aspath/${asPath}`} {...rest}>
      {asPath}
    </Link>
  );
};

export default inject('$app')(AsPathLink);
