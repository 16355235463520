import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import ViewInExplorerMenuItem from 'app/components/dataviews/tools/ViewInExplorerMenuItem';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import WidgetFrame from '../WidgetFrame';

const tabbedChartExclChartHeight = 141;
@inject('$query')
@observer
class TrafficOverviewWidget extends Component {
  render() {
    const { $query, canCustomize, onRemove, height, approxWidth } = this.props;

    const baseQuery = $query.get('inboundOutboundInternalQuery');
    baseQuery.filterDimensionsEnabled = false;
    baseQuery.filterDimensionSort = false;
    baseQuery.metric = ['simple_trf_prof'];

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        menuOptions={<ViewInExplorerMenuItem query={baseQuery} />}
        onRemove={onRemove}
        title="Traffic Overview"
      >
        <TabbedChart
          height={height - 41 - tabbedChartExclChartHeight}
          availableWidth={approxWidth}
          viewInExplorer={false}
        />
      </WidgetFrame>
    );
  }
}

export default TrafficOverviewWidget;
