import styled from 'styled-components';
import { TREND_DETAIL_CHART_HEIGHT, TREND_CHART_HEIGHT } from 'shared/synthetics/constants';
import { Box } from 'core/components';

// wraps a chart marked for including a companion trend chart
// the chart heights here help maintain consistent sizing as we do things like switching tabs
const TrendChartContainer = styled(Box)`
  .detail {
    .highcharts-container {
      height: ${TREND_DETAIL_CHART_HEIGHT}px !important;

      .highcharts-legend {
        // disable legend interactions because they will only affect the detail chart
        pointer-events: none;
      }
    }
  }

  .trend {
    .highcharts-container {
      height: ${TREND_CHART_HEIGHT}px !important;
    }
  }
`;

export default TrendChartContainer;
