import React, { Component } from 'react';
import { inject } from 'mobx-react';

import { Card } from 'core/components';
import { Form } from 'core/form';
import ResultsTableDataView from 'app/components/resultsTable/ResultsTableDataView';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import aggregateConfig from 'app/forms/config/aggregate';
import { getTopx } from 'app/util/queryResults';
import { applyFiltersToAggFilterQuery } from 'app/components/filters/simple/simpleFilterUtils';
import Page from 'app/components/page/Page';

import InsightsMenu from 'app/components/navbar/InsightsMenu';
import AggregateViewHeader from './AggregateViewHeader';
import parentLinks from '../NetworkExplorerParentLink';

@Form(aggregateConfig('CityAggregate'))
@inject('$query')
class CityAggregate extends Component {
  static defaultProps = {
    title: '',
    resultsKey: '',
    insightsQuery: undefined,
    topx: 8,
    tableQueryProps: {},
    chartProps: {},
    getTopxProps: {}
  };

  state = {
    topxMetrics: null,
    filters: [],
    lookback_seconds: 86400,
    aggregateType: 'p95th_bits_per_sec'
  };

  get tableQuery() {
    const { $query, tableQueryProps } = this.props;
    const { filters, lookback_seconds, aggregateType } = this.state;
    const query = $query.get('inboundOutboundInternalAggregateQuery', {
      lookback_seconds,
      aggregateTypes: [aggregateType],
      ...tableQueryProps
    });

    applyFiltersToAggFilterQuery(query, filters);
    return query;
  }

  handleFiltersApply = (filters) => {
    this.setState({ topxMetrics: null, filters });
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ topxMetrics: null, lookback_seconds });
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ topxMetrics: null, aggregateType });
  };

  handleResultsTableComplete = ({ results }) => {
    const { resultsKey, topx, getTopxProps } = this.props;
    const simpleResults = results.map((result) => result.get([resultsKey, 'Internal', 'Inbound', 'Outbound']));

    const Internal = getTopx({
      queryResults: simpleResults,
      key: resultsKey,
      aggregate: 'Internal',
      topx,
      ...getTopxProps
    });

    const Inbound = getTopx({
      queryResults: simpleResults,
      key: resultsKey,
      aggregate: 'Inbound',
      topx,
      ...getTopxProps
    });

    const Outbound = getTopx({
      queryResults: simpleResults,
      key: resultsKey,
      aggregate: 'Outbound',
      topx,
      ...getTopxProps
    });

    const topxMetrics = {
      Internal,
      Inbound,
      Outbound
    };

    this.setState({ topxMetrics });
  };

  render() {
    const { chartProps, insightsQuery, title, topx } = this.props;
    const { lookback_seconds, aggregateType, filters, topxMetrics } = this.state;

    return (
      <Page
        title={title}
        parentLinks={parentLinks}
        insightsMenu={insightsQuery ? <InsightsMenu query={insightsQuery} /> : undefined}
        scrolls
        showExport
      >
        <AggregateViewHeader
          title={title}
          aggregateType={aggregateType}
          filters={filters}
          lookbackSeconds={lookback_seconds}
          onFiltersChange={this.handleFiltersApply}
          onAggregateChange={this.handleAggregationChange}
          onMetricsChange={this.handleAggregationChange}
          onTimeRangeChange={this.handleLookbackChange}
        />

        <Card mb={2} p={0}>
          <TabbedChart
            overrides={{ lookback_seconds, aggregateTypes: [aggregateType], topx, depth: topx }}
            simpleFilters={filters}
            topxMetrics={topxMetrics}
            showNativeLegend
            {...chartProps}
          />
        </Card>

        <Card>
          <ResultsTableDataView
            query={this.tableQuery}
            onQueryComplete={this.handleResultsTableComplete}
            showCombinedTotalColumn
            showActionsMenu
            hashEnabled
          />
        </Card>
      </Page>
    );
  }
}

export default CityAggregate;
