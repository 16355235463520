import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import { FormDialog } from 'core/form';
import { fields, options } from 'app/forms/config/dashboardItemDetails';
import { persistHash } from 'app/stores/query/urlHash';
import DashboardItemForm from './DashboardItemForm';

@inject('$dashboards')
@observer
class DashboardPanelFormDialog extends Component {
  componentDidMount() {
    const { model } = this.props;

    if (model && (!model.fetchedQuery || !model.editQuery)) {
      model.fetchSavedQuery();
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen: wasOpen } = prevProps;
    const { isOpen: isCurrentlyOpen, model } = this.props;

    if (model && !wasOpen && isCurrentlyOpen) {
      model.fetchSavedQuery();
    }
  }

  get panelTypeDisplay() {
    const { model } = this.props;
    const panel_type = model.get('panel_type');

    if (panel_type === 'metrics') {
      return 'Metrics Explorer';
    }

    return 'Data Explorer';
  }

  handleSubmit = (form, values) => {
    const { model, $dashboards, dataview, navigateOnSave = true } = this.props;

    return Promise.resolve()
      .then(() => {
        if (model.get('saved_query_id')) {
          return persistHash(model.get('saved_query_id')).then(() => model.save(values, { toast: false }));
        }

        return dataview.queryBuckets
          .save({ persist: true })
          .then((hash) => model.save(Object.assign(values, { saved_query_id: hash }), { toast: false }));
      })
      .then(({ dashboard_id }) => {
        const dashboard = $dashboards.collection.get(dashboard_id);
        if (navigateOnSave) {
          $dashboards.navigateToDashboard(dashboard);
        }
      });
  };

  render() {
    const { model, $dashboards, onCancel } = this.props;

    if (!model) {
      return null;
    }

    const dashboard = $dashboards.collection.get(model.get('dashboard_id'));

    return (
      <FormDialog
        {...this.props}
        fields={fields}
        options={options}
        onClose={onCancel}
        title={`${model.isNew ? 'Add' : 'Edit'} ${this.panelTypeDisplay} View Panel`}
        entityName="Panel"
        style={{ width: 680 }}
        formActionsProps={{ showRemove: false, onSubmit: this.handleSubmit, onCancel }}
      >
        <DashboardItemForm
          {...this.props}
          dashboard={dashboard}
          showItemProperties={false}
          showDataviewConfiguration={false}
          p={0}
        />
      </FormDialog>
    );
  }
}

export default withRouter(DashboardPanelFormDialog);
