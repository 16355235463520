import React from 'react';
import { Tag } from 'core/components';

export default ({ value }) => {
  let intent;
  if (value === 'Customer') {
    intent = 'success';
  } else if (value === 'Free') {
    intent = 'warning';
  }

  return (
    <Tag as="div" textAlign="center" width={75} intent={intent} minimal>
      {value}
    </Tag>
  );
};
