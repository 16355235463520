import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Box } from 'core/components';
import Spinner from './Spinner';

const FALLBACK_THRESHOLD = 500;
const MIN_FALLBACK = 1000;

/*
 * This is here as a placeholder that'll give us a "good enough" Suspense
 * implementation until the React team gets around to doing something useful.
 */
@observer
class Suspense extends Component {
  static propTypes = {
    fallbackThreshold: PropTypes.number,
    fallback: PropTypes.node
  };

  static defaultProps = {
    fallback: <Spinner />,
    fallbackThreshold: FALLBACK_THRESHOLD
  };

  timerId = null;

  constructor(props) {
    super(props);
    const { loading, fallbackThreshold } = props;
    this.state = {
      showFallback: false,
      showChildren: !loading
    };

    if (loading) {
      this.waitStart = Date.now();
      this.timerId = setTimeout(this.onFallbackThreshold, fallbackThreshold);
    }
  }

  waitStart;

  fallbackStart;

  componentDidUpdate(prevProps) {
    const { loading, fallbackThreshold } = this.props;

    if (!prevProps.loading && loading) {
      this.waitStart = Date.now();
      clearTimeout(this.timerId);
      this.timerId = setTimeout(this.onFallbackThreshold, fallbackThreshold);
    } else if (prevProps.loading && !loading) {
      const fellBack = Date.now() - this.fallbackStart;
      clearTimeout(this.timerId);
      this.timerId = setTimeout(this.onMinFallbackThreshold, Math.min(MIN_FALLBACK - fellBack, 0));
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  onFallbackThreshold = () => {
    const { loading } = this.props;

    this.waitStart = null;

    if (loading) {
      this.fallbackStart = Date.now();
      this.setState({ showFallback: true, showChildren: false });
    }
  };

  onMinFallbackThreshold = () => {
    this.fallbackStart = null;
    this.setState({ showFallback: false, showChildren: true });
  };

  render() {
    const { fallback, children } = this.props;
    const { showFallback, showChildren } = this.state;

    if (showFallback) {
      return fallback;
    }

    if (showChildren) {
      return (
        <>
          {children}
          <Box className="suspense-loaded" display="none" />
        </>
      );
    }

    return null;
  }
}

export default Suspense;
