import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { Suspense, Flex, Spinner, Box } from 'core/components';
import { VirtualizedList } from 'core/components/list';
import Collection from 'core/model/Collection';
import Model from 'core/model/Model';

import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import InsightRowRenderer from 'app/views/serviceProvider/marketIntel/Insights/InsightRowRenderer';

import InsightsConfigForm, { getDefaultConfig } from 'app/views/serviceProvider/marketIntel/Insights/InsightConfigForm';

@inject('$marketIntel')
@withTheme
@observer
class KMIInsightWidget extends Component {
  static defaultProps = {
    contentRef: React.createRef()
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isConfigurePanelOpen: false,
      config: null
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { config } = props;

    // If there's no config in state, let's get setup
    if (!state.config) {
      // If there's no config, initialize one
      if (!config) {
        return { config: getDefaultConfig(), isConfigurePanelOpen: true, model: new Model(getDefaultConfig()) };
      }
      return { config, model: new Model(config), loading: true };
    }

    return null;
  }

  componentDidMount() {
    const { config } = this.props;
    if (config) {
      this.loadInsights();
    }
  }

  loadInsights() {
    const { $marketIntel } = this.props;
    const { config } = this.state;
    const { asn, market, types = {}, magnitude = [], lookback, includeGeo, ip } = config;
    const asnList = (asn && asn.split(',')) || undefined;
    const options = {
      limit: 100,
      lookback,
      types: Object.keys(types).reduce((acc, ty) => (types[ty] ? acc.concat([parseInt(ty.slice(1))]) : acc), []),
      magnitude: magnitude[0],
      market,
      asnArr: asnList ? asnList.map((asnStr) => parseInt(asnStr.trim())) : undefined,
      includeGeo,
      ip
    };

    $marketIntel.getInsights(options).then((insights) => {
      const insightCollection = new Collection(insights.map((ins) => ({ key: ins.insightID, ...ins })));
      this.setState({ loading: false, insightCollection });
    });
  }

  handleShowConfigurePanel = () => {
    this.setState({ isConfigurePanelOpen: true });
  };

  handleConfigClose(values) {
    const { onConfigChange } = this.props;
    const { config } = values;
    this.setState(
      () => {
        const newState = { isConfigurePanelOpen: false };
        if (config) {
          newState.config = config;
          newState.loading = true;
        }
        return newState;
      },
      () => (config ? this.loadInsights() : null)
    );
    if (onConfigChange && config) {
      onConfigChange(config);
    }
  }

  insightRowRenderer = ({ model }) => <InsightRowRenderer insightData={model.get()} />;

  renderWidgetContent() {
    const { height } = this.props;
    const { insightCollection, loading, isConfigurePanelOpen } = this.state;
    const rowHeight = 105;

    return (
      <Suspense
        loading={loading && !isConfigurePanelOpen}
        fallback={
          <Box pt={3}>
            <Spinner size={24} />
          </Box>
        }
      >
        <Flex flexDirection="column" height={height - 40}>
          <VirtualizedList
            collection={insightCollection}
            rowHeight={rowHeight}
            rowRenderer={this.insightRowRenderer}
            minimal
            flexed
          />
        </Flex>
      </Suspense>
    );
  }

  render() {
    const { canCustomize, onRemove, contentRef } = this.props;
    const { isConfigurePanelOpen, config } = this.state;
    const tooltip = 'Kentik Market Intelligence Insights highlight changes in BGP Routing';
    const { widgetTitle } = config;
    return (
      <WidgetFrame
        canCustomize={canCustomize}
        configAction={this.handleShowConfigurePanel}
        onRemove={onRemove}
        title={widgetTitle || (isConfigurePanelOpen ? 'Configure KMI Insights' : 'KMI Insights')}
        tooltip={tooltip}
        ref={contentRef}
        hideScrollbar
      >
        {isConfigurePanelOpen && (
          <InsightsConfigForm
            onClose={(cf) => this.handleConfigClose(cf)}
            config={config}
            widgetize
            showTitle={false}
          />
        )}
        {!isConfigurePanelOpen && this.renderWidgetContent()}
      </WidgetFrame>
    );
  }
}

export default KMIInsightWidget;
