import React from 'react';

import { Icon, Tag } from 'core/components';

const DefaultValueRenderer = ({ option, showGroupLabel, style }) => {
  if (showGroupLabel) {
    return (
      <span className={option && option.className} style={style}>
        <Tag minimal className={option.className}>
          {option.group}
        </Tag>
        {option && option.iconCls && <Icon icon={option.iconCls} mr="6px" />}
        <strong>{option && option.label}</strong>
      </span>
    );
  }

  return (
    <span className={option && option.className} style={style}>
      {option && option.iconCls && <Icon icon={option.iconCls} mr="6px" />}
      {option && option.label}
    </span>
  );
};

export default DefaultValueRenderer;
