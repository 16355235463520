import React from 'react';

import { generateSnmpQuery, generateStreamingQuery } from './InterfaceMetricQueries';
import { MetricVisualization } from './MetricVisualization';

export const MetricVisualizationPair = ({
  title,
  snmpQuery,
  snmpResponse,
  streamingQuery,
  streamingResponse,
  selectedInterface,
  unit,
  metricSuffix
}) => {
  const snmpQueryObj = generateSnmpQuery(selectedInterface, snmpQuery);
  const streamingQueryObj = generateStreamingQuery(selectedInterface, streamingQuery);

  return (
    <>
      <MetricVisualization
        title={title}
        subTitle="SNMP"
        query={snmpQueryObj}
        unit={unit}
        metricSuffix={metricSuffix}
        responseKeys={snmpResponse}
      />

      {selectedInterface.get('device').stEnabled && (
        <MetricVisualization
          title={title}
          subTitle="Streaming Telemetry"
          query={streamingQueryObj}
          unit={unit}
          responseKeys={streamingResponse}
        />
      )}
    </>
  );
};
