import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';
import { adjustByGreekPrefix } from 'core/util';
import { escapeHtml, getToFixed, zeroToText } from 'app/util/utils';
import { getBaseSorts } from 'app/stores/query/ExplorerQueryModel';
import BaseHighchartsDataview from './BaseHighchartsDataview';

const MAXIMUM_LABEL_LENGTH = 30;

@inject('$app', '$dictionary')
@observer
export default class PieView extends BaseHighchartsDataview {
  useQualitativeColors = true;

  chartOptions = {
    chart: {
      className: 'pie',
      zoomType: 'x'
    },
    title: {
      text: ''
    },
    plotOptions: {
      pie: {
        allowPointSelect: true
      },
      series: {
        cursor: 'pointer',
        dataLabels: {
          color: this.chartLabelColor,
          enabled: true,
          formatter() {
            const str = this.key.replace(/----/g, '\u2192');
            if (str.length > MAXIMUM_LABEL_LENGTH) {
              return `${str.substring(0, MAXIMUM_LABEL_LENGTH - 4)}... (${this.percentage.toFixed(1)}%)`;
            }
            return `${str} (${this.percentage.toFixed(1)}%)`;
          }
        }
      }
    },
    legend: { enabled: false },
    series: [
      {
        type: 'pie',
        name: '',
        colorByPoint: true,
        innerSize: '75%',
        data: []
      }
    ],
    tooltip: {
      formatter() {
        const { prefix, suffix, unit } = this.point.options;
        const val = escapeHtml(zeroToText(adjustByGreekPrefix(this.y, prefix), { fix: getToFixed(unit) }));
        return `${this.key.replace(/----/g, '\u2192')}:<br/><b>${val} ${
          prefix || ''
        }${suffix}</b> (${this.percentage.toFixed(1)}%)`;
      }
    }
  };

  buildSeriesInternal(bucket, models) {
    this.clear();

    if (!(this.chart && this.chart.series)) {
      return;
    }

    const { $app, $dictionary } = this.props;
    const { firstQuery } = bucket;
    const outsort = firstQuery.get('outsort');
    const units = firstQuery.get('units');
    const { aggregates, outsortUnit, outsortDataKey } = firstQuery;
    const prefix = bucket.queryResults.prefix[outsortUnit];
    let suffix = $dictionary.get('units')[outsortUnit];
    if (units.includes('sample_rate')) {
      suffix = ` : 1 ${suffix}`;
    }
    let total = 0;
    let sum = 0;

    const [series] = this.chart.series;

    const outsortAgg = aggregates.find((agg) => agg.value === outsort);
    let outsortFn = '';
    if (outsortAgg) {
      outsortFn = outsortAgg.fn;
    }

    let colorCounter = 0;
    models.forEach((model) => {
      const lookup = model.get('lookup');
      const key = model.get('key');
      const isOverlay = model.get('isOverlay');
      let data = model.get(outsortDataKey);
      if (units.includes('sample_rate') && outsortFn !== 'percentile') {
        data /= 100;
      }

      if (isOverlay) {
        if (key === 'Total' && outsortFn === 'average') {
          series.addPoint({
            name: 'Other',
            y: 0,
            prefix,
            suffix
          });
          total = data;
        } else {
          return; // we do this because we want to ignore other overlays
        }
      } else {
        series.addPoint(
          {
            name: lookup || key || `Unnamed ${colorCounter}`,
            y: data,
            prefix,
            suffix,
            unit: outsortUnit
          },
          false
        );
        sum += data;
      }

      const point = series.data[series.data.length - 1];
      point.options.model = model;
      model.set({
        colorIndex: point.color || isOverlay ? undefined : colorCounter,
        color: point.color || isOverlay ? point.color : this.getColorFromIndex(colorCounter),
        toggled: false
      });
      colorCounter += 1;
      this.togglerDisposers.push(
        autorun(() => {
          const toggled = model.get('toggled');
          const mouseover = model.get('mouseover');
          if (toggled === point.sliced) {
            this.toggleSeries(point, toggled);
          }
          if (mouseover) {
            this.highlightSeries(point);
          } else {
            this.unhighlightSeries(point);
          }
        })
      );
    });

    if (total) {
      series.data.find((point) => point.options.name === 'Other').update({ y: Math.max(total - sum, 0) }, false);
    }

    $app.renderSync(() => {
      if (this.chart) {
        this.chart.redraw();
      }
    });
    $app.renderSync(() => {
      this.dismissSpinner();
    });
  }

  redraw({ setColors = false } = {}) {
    const { $app } = this.props;

    if (this.chart) {
      let pointMap = {};
      if (setColors) {
        const { legend } = this.chart;
        pointMap = this.chart.series[0].data.reduce((map, point) => {
          map[point.name] = point.options.model;
          return map;
        }, {});
        if (legend.options.enabled) {
          legend.update(
            {
              itemStyle: { color: `${this.chartLabelColor} !important` },
              itemHoverStyle: { color: `${this.chartLabelColor} !important` }
            },
            false
          );
        }
        this.chart.update(
          { colors: this.qualitativeColors, plotOptions: { series: { dataLabels: { color: this.chartLabelColor } } } },
          false
        );
      }
      $app.renderSync(() => {
        if (this.chart) {
          this.chart.redraw();
          this.chart.series[0].data.forEach((point) => {
            if (pointMap[point.name]) {
              point.options.model = pointMap[point.name];
              point.options.model.set({ color: point.color });
            }
          });
        }
      });
      $app.renderSync(() => {
        this.dismissSpinner();
      });
    }
  }

  highlightSeries(series) {
    if (series && series.setState) {
      series.onMouseOver();
      series.setState('hover');
    }
  }

  unhighlightSeries(series) {
    if (series && series.setState) {
      series.setState();
      series.onMouseOut();
    }
  }

  clear() {
    if (this.chart?.series?.length > 0) {
      this.chart.series[0].setData([]);
    }
  }

  toggleSeries(point, toggled) {
    point.slice(!toggled, true);
    this.redraw();
  }

  setVisibleModels(models) {
    if (this.chart?.series?.length) {
      this.chart.series[0].data.forEach((point) => {
        if (point.options && point.options.model) {
          const { model } = point.options;
          const pointKey = model.get('key');

          const selected = models.some((m) => m.get('key') === pointKey);
          point.slice(selected, true);
        }
      });

      this.chart.redraw();
    }
  }

  getComponent() {
    const { chartOptions } = this;
    const { dataview, showNativeLegend } = this.props;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const query = activeBuckets[0].firstQuery;
    const outsort = query.get('outsort');

    if (!(getBaseSorts([outsort], 'pie') || []).length) {
      throw new Error('Invalid Primary Display/Sort Metric for this visualization');
    }

    chartOptions.series[0].name = outsort;

    if (showNativeLegend) {
      chartOptions.legend = {
        itemStyle: {
          color: `${this.chartLabelColor} !important`,
          fontWeight: 'normal'
        },
        itemHoverStyle: {
          color: `${this.chartLabelColor} !important`,
          fontWeight: 'bold !important'
        },
        verticalAlign: 'bottom',
        align: 'center'
      };
    }

    if (chartOptions.plotOptions && chartOptions.plotOptions.pie) {
      const { pie } = chartOptions.plotOptions;

      if (this.props.onModelSelect) {
        if (!pie.point) {
          pie.point = { events: {} };
        }
        pie.point.events.click = this.getLegendItemClick();
      }
    }

    return super.getComponent();
  }
}

const config = {
  showTotalTrafficOverlay: true,
  showLegend: true,
  timeBased: false,
  isSVG: true,
  enableToggle: false,
  enableNonToggleableColor: true,
  buckets: [
    {
      name: 'Pie'
    }
  ]
};

export { config };
