import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('ConnectivityTypeAggregate'))
class ConnectivityTypeAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="Connectivity Types"
        resultsKey="Connectivity Type"
        insightsQuery="fetchConnectivityTypeInsights"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'Connectivity Type',
          aggregateFilters: [
            { metric: ['i_src_connect_type_name', 'i_dst_connect_type_name'] },
            { metric: ['i_src_connect_type_name'] },
            { metric: ['i_dst_connect_type_name'] },
            { metric: ['i_src_connect_type_name', 'i_dst_connect_type_name'] },
            { metric: ['i_src_connect_type_name', 'i_dst_connect_type_name'] }
          ]
        }}
        chartProps={{
          metrics: {
            Internal: ['i_dst_connect_type_name', 'i_src_connect_type_name'],
            Inbound: 'i_src_connect_type_name',
            Outbound: 'i_dst_connect_type_name'
          },
          topxMetricField: 'i_src|dst_connect_type_name'
        }}
      />
    );
  }
}

export default ConnectivityTypeAggregate;
