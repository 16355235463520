import React from 'react';
import styled from 'styled-components';
import { space, width, maxWidth, height, maxHeight } from 'styled-system';
import { Classes, NonIdealState } from '@blueprintjs/core';
import flex from './utils/flex';

import Text from './Text';

const WrappedEmptyState = styled(NonIdealState)`
  ${width};
  ${space};
  ${maxWidth};
  ${height};
  ${maxHeight};
  ${flex};

  /* remove max-width restraint */
  &.${Classes.NON_IDEAL_STATE} > * {
    max-width: none;
  }
`;

function EmptyState({ description, ...rest }) {
  return (
    <WrappedEmptyState
      {...rest}
      description={
        <Text muted textAlign="left" data-testid="emptyStateIndicator">
          {description}
        </Text>
      }
    />
  );
}

export default EmptyState;
