import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FiActivity } from 'react-icons/fi';
import { inject } from 'mobx-react';

import { Box, Button, Icon, Link, Popover, Text } from 'core/components';
import CapacityCard from 'app/components/landing/CapacityCard';
import CdnCard from 'app/components/landing/CdnCard';
import OttCard from 'app/components/landing/OttCard';
import PeeringCard from 'app/components/landing/PeeringCard';
import DdosCard from 'app/components/landing/DdosCard';
import CostsCard from 'app/components/landing/CostsCard';
import SidebarCard from 'app/components/landing/SidebarCard';
import SyntheticsCard from 'app/components/landing/SyntheticsCard';
import CoreJumpMenu from 'app/components/navbar/jumpMenus/CoreJumpMenu';

@inject('$app')
@withRouter
class LandingSidebar extends Component {
  state = {
    quickViewsMenuOpen: false
  };

  handleQuickViewMenuInteraction = (nextState) => {
    this.setState({ quickViewsMenuOpen: nextState });
  };

  render() {
    const { $app } = this.props;
    const { quickViewsMenuOpen } = this.state;

    return (
      <Box>
        {!$app.isExport && (
          <SidebarCard icon={FiActivity} title="Core" isConfigured minHeight={140} mb={2}>
            <Box>
              <Link to="/v4/kentik-map">
                <Text as="div" mb="4px">
                  Kentik Map
                </Text>
              </Link>
              <Link to="/v4/library">
                <Text as="div" mb="4px">
                  Library
                </Text>
              </Link>
              <Link to="/v4/core/explorer">
                <Text as="div" mb="4px">
                  Data Explorer
                </Text>
              </Link>
              <Link to="/v4/alerting">
                <Text as="div" mb="4px">
                  Alerting
                </Text>
              </Link>
              <Link to="/v4/core/insights">
                <Text as="div" mb="8px">
                  Insights
                </Text>
              </Link>

              {/* This specific wrapper needed for correct positioning */}
              <Box display="inline-block">
                <Popover
                  position="bottom-right"
                  isOpen={quickViewsMenuOpen}
                  onInteraction={this.handleQuickViewMenuInteraction}
                  content={<CoreJumpMenu />}
                >
                  <Button
                    text="Explore Top Talkers..."
                    rightIcon={<Icon icon="caret-down" iconSize={16} mx="-2px" />}
                    active={quickViewsMenuOpen}
                  />
                </Popover>
              </Box>
            </Box>
          </SidebarCard>
        )}

        <Box>
          <Box>
            <SyntheticsCard />
          </Box>

          <Box>
            <CapacityCard />
          </Box>

          <Box>
            <CostsCard />
            <PeeringCard />
          </Box>

          <Box>
            <DdosCard />
          </Box>

          <Box>
            <CdnCard />
            <OttCard />
          </Box>
        </Box>
      </Box>
    );
  }
}

export default LandingSidebar;
