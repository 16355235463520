import React from 'react';
import { observer } from 'mobx-react';
import { Classes, Popover } from '@blueprintjs/core';

import { Flex, Box } from 'core/components';

import AbstractSelect from './AbstractSelect';
import ModalOptions from './ModalOptions';
import ModalSelectFooter from './ModalSelectFooter';
import DefaultValueRenderer from './DefaultValueRenderer';
import ModalSelectValuesPanel from './ModalSelectValuesPanel';
import ModalValuesPanel from './ModalValuesPanel';
import SelectFilter from './SelectFilter';

@observer
class PopoverSelect extends AbstractSelect {
  static defaultProps = {
    closeOnSelect: false,
    filterable: true,
    filterPlaceholder: 'Search options...',
    multi: false,
    position: 'bottom-left',
    reorderable: false,
    saveButtonText: 'Save',
    valueRenderer: DefaultValueRenderer,
    valuesPanelWidth: 260
  };

  handlePopoverOpen = () => {
    if (this.filterRef) {
      setTimeout(() => this.filterRef.focus(), 10);
    }
  };

  renderPopoverContent() {
    const { multi, tools } = this.props;

    return (
      <Box className={Classes.MINIMAL} maxWidth={800} maxHeight="80vh" overflow="auto">
        <Flex p={1} alignItems="center" justifyContent="space-between" flex="none">
          {tools && tools(this.selectState.filterValue)}
          <SelectFilter
            selectState={this.selectState}
            filterRef={(ref) => {
              this.filterRef = ref;
            }}
          />
        </Flex>
        <Flex className={Classes.DIALOG_BODY} m={0}>
          {multi && <ModalSelectValuesPanel {...this.props} selectState={this.selectState} />}
          <ModalOptions {...this.props} selectState={this.selectState} padding={1} />
        </Flex>
        <ModalSelectFooter {...this.props} selectState={this.selectState} />
      </Box>
    );
  }

  render() {
    const { position } = this.props;

    return (
      <Popover
        popoverClassName={Classes.MINIMAL}
        content={this.renderPopoverContent()}
        position={position}
        autoFocus={false}
        boundary="window"
        popoverDidOpen={this.handlePopoverOpen}
      >
        <ModalValuesPanel {...this.props} selectState={this.selectState} />
      </Popover>
    );
  }
}

export default PopoverSelect;
