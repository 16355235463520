import request from 'superagent';
import { action } from 'mobx';
import { showClientErrorToast, showNetworkErrorToast } from 'core/components/toast';
import { isPathSharedLink } from 'app/util/sharedLink';
import safelyParseJson from './safelyParseJson';
import $forms from '../form/util/$forms';

function getHostByRegion(region) {
  if (region === 'US') {
    return 'iad1';
  }
  if (region === 'EU') {
    return 'fra1';
  }
  return region;
}

let $auth;
export function registerAuthStore(authStore) {
  $auth = authStore;
}

/*
 * Takes provided promise and overrides its then() with an implementation that will wrap callbacks given to it as
 * mobx actions. This should reduce boilerplate/gotchas around mobx strict mode.
 */
function thenInActionify(promise) {
  const originalThen = promise.then;

  promise.then = (success, error) => {
    const maybePromise = originalThen.call(promise, success && action(success), error && action(error));

    if (Object.prototype.hasOwnProperty.call(maybePromise, 'then')) {
      return thenInActionify(maybePromise);
    }

    return maybePromise;
  };

  return promise;
}

function assertAuthRegistered() {
  if (!$auth) {
    throw new Error('$auth store not registered with api');
  }
}

function makeRequest(path, options, wrapActions = true) {
  assertAuthRegistered();

  let method = (options.method || 'GET').toLowerCase();
  const rawResponse = !!options.rawResponse;

  if (!request[method]) {
    method = 'get';
  }

  let promise = request[method](path);
  promise.withCredentials().set('X-CSRF-Token', $auth.csrfToken);

  if (options.body) {
    promise = promise.send(options.body);
  } else {
    promise.set('Content-Type', 'application/json');
  }

  if (options.data) {
    promise = promise.send(JSON.stringify(options.data));
  }

  if (options.query) {
    promise = promise.query(options.query);
  }

  promise = promise.then(
    (response) => {
      const { headers } = response;

      if (headers && Object.prototype.hasOwnProperty.call(headers, 'x-2fa-required')) {
        $auth.twoFactorVerifyRequired = true;
      }

      // if selected, return raw response before body processing.
      if (rawResponse) {
        return response;
      }

      if (response.body || response.text.length > 0) {
        const json = safelyParseJson(response.text);
        return response.body || json;
      }

      return response;
    },
    (error) => {
      const { response = {} } = error;
      const { status, headers } = response;

      if (headers && Object.prototype.hasOwnProperty.call(headers, 'x-2fa-required')) {
        $auth.twoFactorVerifyRequired = true;
      }

      if (headers && Object.prototype.hasOwnProperty.call(headers, 'x-2fa-retry-after')) {
        $auth.setTwoFactorDelay(parseInt(headers['x-2fa-retry-after']));
        setTimeout(() => {
          $auth.setTwoFactorDelay(0);
        }, $auth.twoFactorDelay * 1000);
      } else {
        $auth.setTwoFactorDelay(0);
      }

      if ($auth.isSpoofed || $auth.hasSudo) {
        const endTime = new Date().toISOString();
        const startTime = new Date(Date.now() - 60000).toISOString();
        const kibanaURL = `https://kibana.${getHostByRegion(
          $auth.openConfig.region
          // eslint-disable-next-line max-len
        )}.kentik.com/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:'${startTime}',mode:absolute,to:'${endTime}'))&_a=(columns:!(host,proc.name,MESSAGE),index:'logstash-*',interval:auto,query:(language:lucene,query:'proc.name:%22node-portal%22%20%20AND%20%22${encodeURIComponent(
          response.req.url
        )}%20${response.statusCode}%22'),sort:!('@timestamp',desc%29%29`;
        console.error('API ERROR', response.statusCode, response.body, response.req.url);
        console.info('Check Kibana:', kibanaURL);
      }

      if (status === 401) {
        if (!options.noRedirect) {
          console.warn(`Received 401 on ${path}. Logging out.`);
          $auth.store.logout(401);
        } else {
          console.info(`Received a 401 on a noRedirect call to ${path}, but not logging out.`);
        }
      } else if (status === 404) {
        if (!options.suppressNotFoundErrors) {
          showClientErrorToast(response);
        } else {
          // eslint-disable-next-line no-use-before-define
          submitErrorReport(response);
        }
      } else if (options.showErrorToast !== false) {
        if (status >= 500) {
          showNetworkErrorToast(response || 'Unknown error', error);
        } else {
          showClientErrorToast(response);
        }
      }

      if (rawResponse) {
        throw response;
      }

      throw response.statusText;
    }
  );

  if (wrapActions) {
    return thenInActionify(promise);
  }

  return promise;
}

const methodMap = {
  get: 'GET',
  put: 'PUT',
  post: 'POST',
  patch: 'PATCH',
  del: 'DELETE'
};

const methods = Object.keys(methodMap).reduce((result, method) => {
  result[method] = (path, options = {}, wrapActions) => {
    if ($auth.isSubtenantUser && !path.includes('sudo')) {
      return makeRequest(
        path.replace('/ui', '/mkp'),
        { ...options, suppressNotFoundErrors: true, method: methodMap[method] },
        wrapActions
      );
    }

    if (isPathSharedLink() && !path.includes('/api/ui/logout')) {
      return makeRequest(path.replace('/ui', '/ui/shared'), { ...options, method: methodMap[method] }, wrapActions);
    }
    return makeRequest(path, { ...options, method: methodMap[method] }, wrapActions);
  };

  return result;
}, {});

// functions below were based off of existing functions
// to allow for error reporting within api.js
let loadedVersion;

function getPortalVersion() {
  return methods
    .get('/api/ui/hc-full', { showErrorToast: false })
    .then((response) => ({
      uiapp: {
        rev: response.rev.uiapp,
        commit: response.versionControl.uiapp,
        release: response.release
      }
    }))
    .then((latestVersion) => {
      // Only set loadedVersion the first time. Otherwise getPortalVersion() is not idempotent.
      if (loadedVersion === undefined) {
        loadedVersion = latestVersion;
      }

      return { latestVersion, loadedVersion, hasOutdatedBundle: loadedVersion.uiapp.rev !== latestVersion.uiapp.rev };
    });
}

function submitErrorReport(response) {
  const { platform, userAgent } = window.navigator;
  const { pathname } = window.location;
  const { clientWidth, clientHeight } = document.querySelector('body');
  const { error } = response;

  return getPortalVersion().then(({ hasOutdatedBundle }) => {
    methods.post('/api/ui/support/error', {
      data: {
        clientHeight,
        clientWidth,
        info: {
          history: window.$store.$app.routeHistory,
          productArea: 'Core > My Kentik Portal',
          hasOutdatedBundle,
          allForms: $forms.getAllUnsuppressedFormValues()
        },
        pathname,
        platform,
        userAgent,
        error: error ? error.message : 'Error reported as falsey',
        errorInfo: { componentStack: '' }
      }
    });
  });
}

export default methods;
