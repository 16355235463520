import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { formConsumer, Field } from 'core/form';
import { ModalSelect } from 'core/form/components/modalSelect';
import { MetricRendererSimple } from './metricRenderers';

const defaultDialogStyle = {
  width: '800px',
  maxHeight: '800px'
};

@inject('$dictionary', '$devices', '$auth')
@formConsumer
@observer
class MetricSelector extends Component {
  state = {
    isEditing: false
  };

  static defaultProps = {
    multi: true,
    readOnly: false,
    field: undefined,
    fieldName: 'aggregateTypes',
    aggregateThresholdsFieldName: 'aggregateThresholds',
    rootPrefix: undefined,
    noSelectedValuesText: 'No metrics selected',
    selectedValuesTitle: 'Selected Metrics',
    reorderable: true,
    showClearButton: false,
    isPopover: false,
    itemFlexed: true,
    rules: undefined,
    allowSelectAll: true,
    showTools: true,
    showPrimaryDisplaySortMetric: true,
    allowMinimumThresholds: true,
    valueRenderer: MetricRendererSimple,
    dialogStyle: defaultDialogStyle
  };

  getField(name) {
    const { form, field, fieldName, rootPrefix } = this.props;

    if (field) {
      return field;
    }

    return rootPrefix ? form.getField(`${rootPrefix}.${name || fieldName}`) : form.getField(name || fieldName);
  }

  getFieldValue(name) {
    return this.getField(name).getValue();
  }

  handleClearValues = () => {
    const { onChange } = this.props;
    const field = this.getField();
    field.onChange([]);
    if (onChange) {
      onChange(field, []);
    }
  };

  handleToggleEditing = () => {
    this.getField().setPristine(false);
    this.setState((prevState) => ({ isEditing: !prevState.isEditing }));
  };

  render() {
    const {
      $dictionary,
      $devices,
      className,
      multi,
      options,
      onChange,
      readOnly,
      showLabel,
      onEditComplete,
      inputStyle,
      dialogOnly,
      isEditing,
      rules,
      helpContents,
      dialogStyle,
      $auth
    } = this.props;

    const { isEditing: isEditingState } = this.state;

    const formField = this.getField();
    const selectOptions = options || $dictionary.get('aggregates');

    // hide bgp for now
    if (!$auth.hasSudo && !$auth.isSpoofed) {
      delete selectOptions['Route Updates'];
    }

    if (!$devices.hasSubtype('istio')) {
      delete selectOptions['Istio (Beta)'];
    }

    const selectField = (
      <Field
        className={className}
        field={formField}
        isEditing={isEditing || isEditingState}
        onChange={(field, value) => {
          if (onChange) {
            onChange(field, value);
          }
        }}
        options={selectOptions}
        showLabel={showLabel}
        toggleEditing={onEditComplete || this.handleToggleEditing}
        readOnly={readOnly}
        inputStyle={inputStyle}
        rules={rules}
        mb={0}
      >
        <ModalSelect
          title="Metrics"
          helpContents={helpContents}
          dialogStyle={dialogStyle}
          maxColumns={1}
          valuesPanelWidth={300}
          {...this.props}
          filterPlaceholder="Search Available Metrics..."
          useLegacyOptionsFormat
        />
      </Field>
    );

    if (!dialogOnly && !readOnly && multi) {
      return (
        <div onClick={onEditComplete || this.handleToggleEditing} style={{ cursor: 'pointer' }}>
          {selectField}
        </div>
      );
    }

    return selectField;
  }
}

export default MetricSelector;
