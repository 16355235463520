import styled from 'styled-components';
import { Box } from 'core/components';

const DrawerMenu = styled(Box)`
  position: absolute;
  width: 100%;
  z-index: 40;
  display: flex;
  flex-direction: column;

  transition: opacity, visibility, transform;
  transition-delay: 0ms, 250ms, 0ms;
  transition-duration: 350ms, 350ms, 250ms;

  &.hidden {
    transform: translateY(-200px);
    opacity: 0;
    visibility: hidden;

    &.bottom-aligned {
      transform: translateY(200px);
    }
  }

  > .drawer-body {
    margin: 0 auto;
    max-width: 1250px;
  }

  .bp4-card {
    background: ${({ theme }) => theme.colors.decks.customizeDrawerCardBackground};
  }
`;

export default DrawerMenu;
