import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Box, Flex, Text } from 'core/components';
import Trend from './Trend';

@observer
class TabView extends Component {
  render() {
    const { value, title, unit, text, direction, loadingTrendData, ...rest } = this.props;

    if (value === null) {
      return null;
    }

    return (
      <Box {...rest}>
        <Flex alignItems="center">{title}</Flex>

        <Flex alignItems="flex-end" flexWrap="wrap">
          <Text fontSize={24} fontWeight="bold" mr="4px">
            {value}
          </Text>
          <Box mb="6px">
            <Text as="div" small>
              {unit}
            </Text>
          </Box>
        </Flex>
        {text && (
          <Trend direction={direction} loading={loadingTrendData}>
            {text}
          </Trend>
        )}
      </Box>
    );
  }
}

export default TabView;
