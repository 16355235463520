import { uniq } from 'lodash';
import { FiCheck } from 'react-icons/fi';
import React, { Component } from 'react';
import { Intent } from '@blueprintjs/core';
import Collection from 'core/model/Collection';
import { inject, observer } from 'mobx-react';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';

import Checkbox from 'core/components/Checkbox';
import ExpandableRow from 'app/components/admin/ExpandableRow';
import { CELL_TYPES, Table, VirtualizedTable } from 'core/components/table';
import { Field, formConsumer, InputGroup, Select, TagInput } from 'core/form';
import ExporterCompartmentCollection from 'app/stores/clouds/oci/ExporterCompartmentCollection';
import AccountStatusIcon from 'app/views/settings/clouds/AccountStatusIcon';
import {
  Heading,
  Link,
  Text,
  Flex,
  Button,
  Dialog,
  Card,
  CopyText,
  Box,
  Tag,
  EmptyState,
  Icon,
  showErrorToast
} from 'core/components';

// @formConsumer
@inject('$cloudExportWizard', '$clouds')
@formConsumer
@observer
class OciExportConfigFields extends Component {
  state = {
    isLoading: false,
    showModal: false,
    validationResults: []
  };

  collection = new ExporterCompartmentCollection();

  constructor(props) {
    const { form } = props;

    const compartmentField = form.getField('properties.oci_compartment_id');
    const compartmentFieldValue = compartmentField.getValue();

    // convert string to array to make tagInput work for legacy exporters
    if (compartmentFieldValue && !Array.isArray(compartmentFieldValue)) {
      compartmentField.setValue([compartmentFieldValue]);
    }

    super(props);
  }

  fetchChildrenCompartmentsPromise = (collection, childCompartmentId = null) => {
    const { form, $clouds } = this.props;

    const compartmentIds = form.getValue('properties.oci_compartment_id');

    const requestData = {
      ...form.getValues()
    };
    // childCompartmentId passed when modal row is expanded
    if (childCompartmentId) {
      // when childCompartmentId passed, we need to ignore form compartment_id to get its children
      requestData.properties.request_oci_compartment_id = childCompartmentId;
    }
    // mark as loading
    collection.startFetchRequest();
    return $clouds
      .listOciChildrenCompartments(requestData)
      .then((response) => {
        const { data = [], validationResults = [] } = response;

        this.setState({ validationResults });

        const modelsData = data.map((compartmentData) => {
          const { id, name, description, lifecycleState } = compartmentData;
          return {
            id,
            name,
            description,
            compartmentId: id,
            lifecycleState,
            isEnabled: compartmentIds.includes(id),
            childrenCollection: new ExporterCompartmentCollection()
          };
        });

        return collection.set(modelsData);
      })
      .finally(() => {
        // remove loading
        collection.completeFetchRequest();
      });
  };

  get columns() {
    const { form } = this.props;
    return [
      {
        key: 'select',
        value: 'select',
        id: 'select',
        type: CELL_TYPES.ACTION,
        actions: [
          (model) => (
            <Checkbox
              key="select"
              checked={model.get('isEnabled')}
              onClick={(e) => {
                const field = form.getField('properties.oci_compartment_id');
                const currentFormCompartmentsValues = field.getValue() ?? [];
                e.stopPropagation();
                const newIsChecked = !model.get('isEnabled', false);
                model.set('isEnabled', newIsChecked);

                if (newIsChecked) {
                  const newFieldValues = uniq([...currentFormCompartmentsValues, model.get('id')]);
                  field.setValue(newFieldValues);
                } else {
                  const newFieldValues = uniq([
                    ...currentFormCompartmentsValues.filter((compartmentId) => compartmentId !== model.get('id'))
                  ]);
                  field.setValue(newFieldValues);
                }
              }}
              mb={0}
            />
          )
        ],
        justifyContent: 'center',
        verticalAlign: 'center',
        dynamicClassName: (model) => (model.get('isSelected') ? 'pt-intent-primary-cell' : '')
      },
      {
        name: 'lifecycleState',
        label: 'Status',
        width: 50,
        wrapText: true,
        renderer: ({ value }) => {
          const isActive = value === 'ACTIVE';

          return (
            <Flex alignItems="center" justifyContent="center" width="100%">
              <Icon
                icon={isActive ? FiCheck : BsFillExclamationTriangleFill}
                color={isActive ? 'success' : 'warning'}
                title={value}
              />
            </Flex>
          );
        }
      },
      { name: 'name', label: 'Name', width: 200 },
      { name: 'description', label: 'Description', width: 350 },
      { name: 'compartmentId', label: 'Compartment Id' }
    ];
  }

  expandedRowRenderer = (model) => {
    const childrenCollection = model.get('childrenCollection');
    const hasChildren = (childrenCollection?.size ?? 0) > 0;

    if (!childrenCollection.hasFetched && !childrenCollection.loading && !hasChildren) {
      // start loading children compartments, will mark collection as loading
      this.fetchChildrenCompartmentsPromise(childrenCollection, model.get('compartmentId'));
    }

    return (
      <ExpandableRow
        p="0px 0px 0px 28px"
        // default to 3 to show loading spinner
        rowExpansionHeight={Math.min((childrenCollection.totalModels.length || 3) * 36, 500)}
        className={`row-${model.get('id')}`}
      >
        <Table
          hideHeader
          multiSelect
          emptyState={<EmptyState title="No Children Compartments found" />}
          collection={childrenCollection}
          columns={this.columns}
          expandedRowRenderer={hasChildren ? this.expandedRowRenderer : false}
          rowAlign="center"
          className="sub-table-row"
        />
      </ExpandableRow>
    );
  };

  handleShowModal = () => {
    this.setState({ showModal: true }, () => {
      this.fetchChildrenCompartmentsPromise(this.collection);
    });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleCheckAccess = () => {
    const { form, $clouds } = this.props;
    this.setState({ isLoading: true });
    $clouds
      .validateOCIAccess(form.getValues())
      .then((response) => {
        const validationResults = response
          .map((result) => {
            const { validationResultsByRegion = {}, ...rest } = result;
            return Object.keys(validationResultsByRegion).map((region) => ({
              ...rest,
              region,
              results: result.validationResultsByRegion[region]
            }));
          })
          .flat();
        this.setState({ validationResults });
      })
      .catch(() => {
        showErrorToast('Unable to validate OCI Permissions');
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  get apiColumns() {
    const columns = [
      {
        label: 'Endpoint',
        name: 'fn',
        flexBasis: 70,
        renderer: ({ value }) => <CopyText text={value} />
      },
      {
        label: 'Can Access?',
        name: 'success',
        flexBasis: 30,
        align: 'center',
        renderer: ({ value, model }) => (
          <AccountStatusIcon
            value={value ? 'success' : 'error'}
            message={model.get('message')}
            messageProps={{ position: 'top' }}
          />
        )
      }
    ];

    return columns;
  }

  groupSummary = ({ groupKey, group }) => {
    // gather a count of failed api checks
    const errorCount = group.filter((model) => model.get('success') === 'Error').length;

    // start by assuming great success
    let statusCmp = <AccountStatusIcon value="success" />;

    if (errorCount > 0) {
      // provide a breakdown of how many failed of the total
      statusCmp = <Tag intent="danger" round>{`${errorCount} of ${group.length}`}</Tag>;
    }

    return (
      <Flex alignItems="center">
        <Text mr={1}>{groupKey}</Text>
        {statusCmp}
      </Flex>
    );
  };

  regionOptionRenderer = (option) => {
    const { className, label, value, selectItem, selected, disabled } = option;
    const onClick = !selected && !disabled ? () => selectItem(value) : undefined;

    return (
      <Flex key={value} justifyContent="space-between" className={className} onClick={onClick}>
        <Text ml="2px">{value}</Text>
        <Text muted>{label}</Text>
      </Flex>
    );
  };

  regionValueRenderer = (option, placeholder) => {
    if (!option) {
      return <Text muted>{placeholder || 'Select a value...'}</Text>;
    }

    const { label, value } = option;
    return (
      <Flex justifyContent="space-between">
        <Text>{value}</Text> <Text muted>{label}</Text>
      </Flex>
    );
  };

  render() {
    const { showModal, validationResults, isLoading } = this.state;
    const { $cloudExportWizard, form } = this.props;
    const { isFlowLogCollectionEnabled } = $cloudExportWizard;

    const isLoadCompartmentsButtonEnabled =
      form.getValue('properties.oci_tenancy_id') &&
      form.getValue('properties.oci_user_id') &&
      form.getValue('properties.oci_default_region');

    return (
      <Flex gap={1} data-testid="OciExportConfigFields">
        {' '}
        {/* test id for CloudExportConfigWizard jest tests */}
        <Flex flexDirection="column" gap="1" flex={1} mb={2}>
          <Flex flexDirection="column" gap="1">
            <Heading level={5} py={1} mb={2}>
              Kentik Export Configuration
            </Heading>
            <Field
              name="properties.oci_tenancy_id"
              width="100%"
              large
              helperText={
                <Text>
                  Tenancy id is located in{' '}
                  <Link to="https://cloud.oracle.com/org-mgmt/tenancy" blank>
                    OCI Portal Tenancy Page
                  </Link>
                </Text>
              }
            >
              <InputGroup />
            </Field>

            <Field
              name="properties.oci_user_id"
              width="100%"
              large
              helperText={
                <Text>
                  <Link to="https://docs.oracle.com/en-us/iaas/Content/GSG/Tasks/addingusers.htm" blank>
                    OCI Documentation
                  </Link>
                </Text>
              }
            >
              <InputGroup />
            </Field>

            <Flex justifyContent="space-between">
              <Field name="properties.oci_default_region" large>
                <Select
                  optionRenderer={this.regionOptionRenderer}
                  valueRenderer={this.regionValueRenderer}
                  showFilter
                />
              </Field>

              <Button small my="auto" style={{ height: '30px' }} loading={isLoading} onClick={this.handleCheckAccess}>
                Validate Permissions
              </Button>
            </Flex>
          </Flex>

          {isFlowLogCollectionEnabled && (
            <Flex flexDirection="column">
              <Heading level={5} py={1} mb={2}>
                Flowlogs bucket configuration
              </Heading>

              <Flex gap={1} justifyContent="space-between">
                <Field name="properties.oci_bucket_name" large flex={1}>
                  <InputGroup width="100%" />
                </Field>

                <Field name="properties.oci_bucket_namespace_name" large flex={1}>
                  <InputGroup width="100%" />
                </Field>
              </Flex>
              <Flex gap={1} justifyContent="space-between">
                <Field name="properties.oci_service_connector_ocid" large flex={1}>
                  <InputGroup width="100%" />
                </Field>

                <Field name="properties.oci_flow_object_name_prefix" large flex={1}>
                  <InputGroup width="100%" />
                </Field>
              </Flex>
            </Flex>
          )}

          <Field
            name="properties.oci_compartment_id"
            width="100%"
            large
            helperText={
              <Text>
                Compartment id is located in{' '}
                <Link to="https://cloud.oracle.com/identity/compartments" blank>
                  OCI Portal Compartment Page
                </Link>
              </Text>
            }
          >
            <TagInput fill />
          </Field>

          <Button
            width="250px"
            onClick={this.handleShowModal}
            disabled={!isLoadCompartmentsButtonEnabled}
            // style={{ marginLeft: 'auto' }}
          >
            Load Compartments
          </Button>

          <Dialog
            isOpen={showModal}
            onClose={this.handleCloseModal}
            style={{ width: 'calc(100vw - 200px)', height: 'calc(100vh - 100px)' }}
            title="Select Additional Compartments"
            canOutsideClickClose
            canEscapeKeyClose
            isCloseButtonShown
          >
            <Dialog.Body>
              <Card>
                <Box border="thin">
                  <Table
                    rowAlign="center"
                    searchBar={false}
                    isExpanded
                    multiSelect
                    emptyState={
                      <EmptyState
                        title={
                          validationResults.length
                            ? validationResults
                                .map(
                                  (result) =>
                                    `Unable to execute ${result.fn} for service ${result.service} with error message - ${result.message}`
                                )
                                .join(', ')
                            : 'No Children Compartments found'
                        }
                      />
                    }
                    collection={this.collection}
                    expandedRowRenderer={this.expandedRowRenderer}
                    tableContainerProps={{ bg: 'cardBackground' }}
                    columns={this.columns}
                  />
                </Box>
              </Card>
            </Dialog.Body>
            <Dialog.Footer>
              <Button intent={Intent.PRIMARY} text="close" onClick={this.handleCloseModal} width={110} ml={1} />
            </Dialog.Footer>
          </Dialog>
        </Flex>
        <Flex flex={1} flexDirection="column">
          <Flex height={750} flexWrap="wrap" justifyContent="flex-end">
            {validationResults.map((apiData) => {
              const { results } = apiData;
              const apiCollection = new Collection(results, { groupBy: 'service' });
              apiCollection.sort('success');

              return (
                <Flex
                  flexDirection="column"
                  border="thin"
                  width={500}
                  key={`${apiData.compartmentId}-${apiData.region}`}
                >
                  <Flex flexDirection="column" flex="1" style={{ marginLeft: '24px', marginRight: '24px' }}>
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      py="4px"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      <Text as="div" muted pr={1}>
                        Compartment ID:
                      </Text>
                      <CopyText text={apiData.compartmentId} textProps={{ textAlign: 'right' }} iconLeft />
                    </Flex>
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      py="4px"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      <Text as="div" muted pr={1}>
                        Region:
                      </Text>
                      <CopyText text={apiData.region} textProps={{ textAlign: 'right' }} iconLeft />
                    </Flex>
                    <Flex flexDirection="column" border="thin" flex="1" mt={2}>
                      <VirtualizedTable
                        selectOnRowClick={false}
                        collection={apiCollection}
                        rowHeight={30}
                        columns={this.apiColumns}
                        groupSummaryLookup={this.groupSummary}
                        stickyHeader
                        flexed
                      />
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

export default OciExportConfigFields;
