import validatorjs from 'validatorjs';

function registerValidation({ name, fn, message, implicit = false, async = false }) {
  let { register } = validatorjs;
  if (implicit && !async) {
    register = validatorjs.registerImplicit;
  } else if (implicit && async) {
    register = validatorjs.registerAsyncImplicit;
  } else if (async) {
    register = validatorjs.registerAsync;
  }

  register(name, fn, message);
}

export default registerValidation;
