import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { Spinner, Flex, Icon, Heading, Text, Box } from 'core/components';

class GraphWrapper extends Component {
  progress() {
    const { progress = {} } = this.props;
    const hasProgress = progress.loaded > 0;
    const percentageComplete = (hasProgress ? progress.loaded / progress.loadingCount : 0) * 100;
    const loadingComplete = percentageComplete === 100;
    const parsing = loadingComplete && !progress.parsed;
    const loading = !loadingComplete && !parsing;

    return { loading, percentageComplete, parsing };
  }

  renderHeading() {
    const { heading, subHeading, text, loading, menu } = this.props;
    const { percentageComplete, parsing } = this.progress();

    return (
      <>
        <Flex justifyContent="space-between">
          <Heading level={4} mb="4px">
            {heading}
            {subHeading}
          </Heading>
          {menu}
        </Flex>
        {(loading || text) && (
          <>
            <Flex flexWrap="wrap">
              <Text muted mr={1}>
                {loading && (parsing ? 'processing...' : 'loading...')}
                {!loading && text}
              </Text>
              {loading && <Spinner intent="primary" size="14" />}
              {loading && !!percentageComplete && (
                <Text muted mx={1}>
                  {`${percentageComplete.toFixed(0)}%`}
                </Text>
              )}
            </Flex>
          </>
        )}
      </>
    );
  }

  emptyState = () => {
    const {
      theme,
      noResultsTitle = 'No Results',
      noResultsDescription = 'Results are not currently available for this time range.'
    } = this.props;
    const { colors } = theme;

    return (
      <Flex alignItems="center" pb={1}>
        <Icon icon="disable" iconSize={25} mr={1} color={colors.muted} />
        <Flex flexDirection="column">
          <Heading level={6} mb={0.5}>
            {noResultsTitle}
          </Heading>
          <Box>
            <Text small muted>
              {noResultsDescription}
            </Text>
          </Box>
        </Flex>
      </Flex>
    );
  };

  render() {
    const { renderContent, children, expand, title, loading } = this.props;
    const heading = this.renderHeading();
    const flexStyles = { flexDirection: 'column' };

    if (expand) {
      flexStyles.flex = '1 0 auto';
    }

    return (
      <Flex my={3} {...flexStyles}>
        {heading}
        <Flex mt={2} {...flexStyles}>
          {!!renderContent && !loading && children}
          {!renderContent && !loading && this.emptyState()}
        </Flex>
        {title && (
          <Flex flexDirection="column" alignItems="center" mt={2}>
            <Text small muted>
              {title}
            </Text>
          </Flex>
        )}
      </Flex>
    );
  }
}

export default withTheme(GraphWrapper);
