export default function addFilterGroup(filtersObj, connector = 'All', not = false, filters = [], name = '', atIndex) {
  const newGroup = {
    connector,
    not,
    filters,
    name,
    named: !!name,
    filterGroups: [],
    autoAdded: '',
    saved_filters: []
  };

  if (atIndex || atIndex === 0) {
    filtersObj.filterGroups = [
      ...filtersObj.filterGroups.slice(0, atIndex),
      newGroup,
      ...filtersObj.filterGroups.slice(atIndex)
    ];
  } else if (filtersObj && filtersObj.filterGroups) {
    filtersObj.filterGroups.push(newGroup);
  }

  return filtersObj;
}
