import React from 'react';
import { inject, observer } from 'mobx-react';
import { select } from 'd3-selection';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { rgb } from 'd3-color';
import { debounce } from 'lodash';
import styled from 'styled-components';

import sankey from 'app/components/dataviews/d3/sankey';
import { getShortName } from 'app/util/queryResults';
import { getMetadata, getBracketingDataClasses } from 'app/util/bracketing';
import { adjustByGreekPrefix } from 'core/util';
import { getToFixed, zeroToText } from 'app/util/utils';
import { Box } from 'core/components';
import BaseDataview from './BaseDataview';

const SankeyContainer = styled.div`
  .node rect {
    cursor: move;
    fill-opacity: 0.9;
  }

  .node text {
    pointer-events: none;
    text-shadow: 0 1px 0 ${({ theme }) => theme.colors.white};
    color: red;
    fill: red;
  }

  .node .deep-label {
    background-color: ${({ theme }) => (theme.name === 'dark' ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.5)')};
  }

  .link {
    fill: none;
    stroke: ${({ theme }) => (theme.name === 'dark' ? theme.colors.white : theme.colors.black)};
    stroke-opacity: 0.1;
    transition: stroke-opacity 0.2s ease;
  }

  .link.fillLink {
    fill-opacity: 0.2;
    transition: fill-opacity 0.2s ease;
  }

  .pt-dark .link {
    stroke: ${({ theme }) => theme.colors.lightGray1};
  }

  .link:hover {
    stroke-opacity: 0.5;
  }

  .cycleLink {
    fill: ${({ theme }) => theme.colors.red1};
    opacity: 0.2;
    stroke: none;
    stroke-linejoin: round;
  }

  .cycleLink:hover {
    opacity: 0.5;
  }
`;

@inject('$app', '$dictionary')
@observer
export default class SankeyView extends BaseDataview {
  constructor(props) {
    super(props);
    window.addEventListener(
      'resize',
      debounce(() => this.reflow(), 100)
    );
  }

  componentDidMount() {
    // is this an anti-pattern? sure but you and mobx can kiss my a$$.
    this.mounted = true;
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.mounted) {
      super.componentDidUpdate(prevProps, prevState);
    }
  }

  afterComponentWillUnmount() {
    this.mounted = false;
  }

  chartRef = (ref) => {
    if (ref) {
      this.chart = ref;
    }
  };

  graph = {
    nodes: [],
    links: []
  };

  drawSankey() {
    if (!this.chart || !this.mounted) {
      return;
    }

    const { graph, props } = this;
    const { $dictionary, dataview, padHeight } = props;
    const chartTypesValidations = $dictionary.get('chartTypesValidations');
    const { firstQuery } = dataview.queryBuckets.activeBuckets[0];
    const { outsortUnit, prefixUnit } = firstQuery;
    const units = $dictionary.get('units')[outsortUnit];
    const { prefix } = dataview.queryBuckets.activeBuckets[0].queryResults;
    const labels = [];

    const margin = {
      top: 4,
      right: 0,
      bottom: 4,
      left: 0
    };

    this.chart.innerHTML = '';
    const chartContainer = select(this.chart);
    const width = parseInt(chartContainer.style('width')) - margin.left - margin.right;
    const height = chartContainer.node().parentElement.scrollHeight - margin.top - margin.bottom;

    const format = (d) =>
      `${zeroToText(adjustByGreekPrefix(d, prefix[prefixUnit]), { fix: getToFixed(outsortUnit) })} ${
        prefix[prefixUnit] || ''
      }${units}`;
    const color = scaleOrdinal(schemeCategory10);

    const getTitle = (d) => {
      let title = '';
      if (d.isRatioBased) {
        title = `${d.source.name.substring(d.source.name.indexOf('__k_') + 4)} (${format(
          d.source.value
        )}) → ${d.target.name.substring(d.target.name.indexOf('__k_') + 4)} (${format(d.target.value)})`;
      } else {
        title = `${d.source.name.substring(d.source.name.indexOf('__k_') + 4)} → ${d.target.name.substring(
          d.target.name.indexOf('__k_') + 4
        )}
      ${format(d.value)}`;
      }
      return title;
    };

    const getCssClass = (d) => {
      let cssClass = d.causesCycle ? 'cycleLink' : 'link';
      if (d.isRatioBased) {
        cssClass += ' fillLink';
      }
      return cssClass;
    };

    const getColor = (value) => {
      const { $app } = this.props;

      if (this.bracketDataClasses) {
        return this.getBracketColor(value);
      }

      return $app.isDarkTheme ? '#ffffff' : '#000000';
    };

    function getTextWidth(text) {
      // re-use canvas object for better performance
      const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
      const context = canvas.getContext('2d');
      context.font = '14px "Roboto Flex", -apple-system, system-ui, sans-serif';
      const metrics = context.measureText(text);
      return metrics.width;
    }

    // Set the sankey diagram properties
    const sankeyChart = sankey().nodeWidth(36).nodePadding(10).nodeMinHeight(5).size([width, height]);

    const path = sankeyChart.link();

    sankeyChart.nodes(graph.nodes).links(graph.links).layout(32, true);

    // prepare the chart canvas
    const svg = chartContainer
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', Math.ceil(sankeyChart.totalDepth) + margin.top + (padHeight || 0))
      .attr('overflow', 'visible')
      .append('g')
      .attr('transform', `translate(${margin.left},0)`);

    // add in the links
    const link = svg
      .append('g')
      .selectAll('.link')
      .data(graph.links)
      .enter()
      .append('path')
      .attr('class', (d) => getCssClass(d))
      .attr('d', path)
      // .sort((a, b) => b.dy - a.dy) // in pathLinks and mouseover handler we assume graph.links and links appended to svg are in the same order
      .style('stroke', (d) => (d.isRatioBased ? 'none' : getColor(d.value)))
      .style('stroke-width', (d) => (d.isRatioBased ? 0 : Math.max(1, d.dy)))
      .style('fill', (d) => (d.isRatioBased ? getColor(d.value) : 'none'));

    // add the link titles
    link.append('title').text((d) => getTitle(d));

    link.on('mouseover', (d) => {
      for (let x = 0, xlen = d.pathLinks.length; x < xlen; x += 1) {
        if (d.isRatioBased) {
          select(link._groups[0][d.pathLinks[x]]).style('fill-opacity', 0.5);
        } else {
          select(link._groups[0][d.pathLinks[x]]).style('stroke-opacity', 0.5);
        }
      }
    });

    link.on('mouseout', (d) => {
      for (let x = 0, xlen = d.pathLinks.length; x < xlen; x += 1) {
        if (d.isRatioBased) {
          select(link._groups[0][d.pathLinks[x]]).style('fill-opacity', 0.2);
        } else {
          select(link._groups[0][d.pathLinks[x]]).style('stroke-opacity', 0.1);
        }
      }
    });

    // add in the nodes

    const node = svg
      .append('g')
      .selectAll('.node')
      .data(
        graph.nodes.sort((a, b) => {
          const ay = a.dy / 2 - 10 + a.y;
          const by = b.dy / 2 - 10 + b.y;
          return ay - by;
        })
      ) // loop through nodes in label y pos order
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', (d) => `translate(${d.x},${d.y})`);

    // add the rectangles for the nodes
    node
      .append('rect')
      .attr('height', (d) => d.dy)
      .attr('width', sankeyChart.nodeWidth())
      .style(
        'fill',
        (d) => (d.color = this.bracketDataClasses ? this.getBracketColor(d.value) : color(d.name.replace(/ .*/, '')))
      )
      .style('stroke', (d) => rgb(d.color).darker(2))
      .attr('pointer-events', 'all')
      .on('mouseover', (d) => {
        let x;
        let xlen;
        let y;
        let ylen;
        const linksToHighlight = [];
        for (x = 0, xlen = d.sourceLinks.length; x < xlen; x += 1) {
          for (y = 0, ylen = d.sourceLinks[x].pathLinks.length; y < ylen; y += 1) {
            if (linksToHighlight.indexOf(d.sourceLinks[x].pathLinks[y]) === -1) {
              linksToHighlight.push(d.sourceLinks[x].pathLinks[y]);
            }
          }
        }
        for (x = 0, xlen = d.targetLinks.length; x < xlen; x += 1) {
          for (y = 0, ylen = d.targetLinks[x].pathLinks.length; y < ylen; y += 1) {
            if (linksToHighlight.indexOf(d.targetLinks[x].pathLinks[y]) === -1) {
              linksToHighlight.push(d.targetLinks[x].pathLinks[y]);
            }
          }
        }
        for (x = 0, xlen = linksToHighlight.length; x < xlen; x += 1) {
          select(link._groups[0][linksToHighlight[x]]).style('stroke-opacity', 0.5);
        }
      })
      .on('mouseout', (d) => {
        let x;
        let xlen;
        let y;
        let ylen;
        const linksToHighlight = [];
        for (x = 0, xlen = d.sourceLinks.length; x < xlen; x += 1) {
          for (y = 0, ylen = d.sourceLinks[x].pathLinks.length; y < ylen; y += 1) {
            if (linksToHighlight.indexOf(d.sourceLinks[x].pathLinks[y]) === -1) {
              linksToHighlight.push(d.sourceLinks[x].pathLinks[y]);
            }
          }
        }
        for (x = 0, xlen = d.targetLinks.length; x < xlen; x += 1) {
          for (y = 0, ylen = d.targetLinks[x].pathLinks.length; y < ylen; y += 1) {
            if (linksToHighlight.indexOf(d.targetLinks[x].pathLinks[y]) === -1) {
              linksToHighlight.push(d.targetLinks[x].pathLinks[y]);
            }
          }
        }
        for (x = 0, xlen = linksToHighlight.length; x < xlen; x += 1) {
          select(link._groups[0][linksToHighlight[x]]).style('stroke-opacity', 0.1);
        }
      })
      .append('title')
      .text((d) => {
        let aspathInfo;
        const arr = d.name.split('__k_');
        if (arr[0].indexOf('bgp_aspath') !== -1) {
          aspathInfo = /^(.*bgp_aspath)_(\d)/.exec(arr[0]);
          if (aspathInfo) {
            arr[0] = `${chartTypesValidations[aspathInfo[1]]} Step ${parseInt(aspathInfo[2]) + 1}`;
          } else {
            arr[0] = `${chartTypesValidations[arr[0]]} Step 1`;
          }
        } else {
          arr[0] = chartTypesValidations[arr[0]];
        }
        arr.push(format(d.value));
        return arr.join('\n');
      });

    // add clickable label to nodes
    node
      .append('foreignObject')
      .attr('x', (d) => {
        const label_width = Math.min(d.shortName ? getTextWidth(d.shortName.toString()) : 10, 250);
        const label_padding = 6;
        const label_x = d.x < width / 2 ? sankeyChart.nodeWidth() + label_padding : -1 * (label_width + label_padding);
        return label_x;
      })
      .attr('y', (d) => {
        // restating some variables from x attribute calculation for simplicity
        let label_align_v = d.dy / 2 - 10;
        const label_width = Math.min(d.shortName ? getTextWidth(d.shortName.toString()) : 10, 250);

        const label_padding = 6;
        const label_x = d.x < width / 2 ? sankeyChart.nodeWidth() + label_padding : -1 * (label_width + label_padding);
        const label_height = 12; // through trial and error

        // absolute pos of g in svg + offset of label inside g
        const currXPos = d.x + label_x;
        let currYPos = d.y + label_align_v;

        for (let n = 0; n < labels.length; n++) {
          // checks if right/top edge of one label is overlapping left/bottom edge of other label
          const hasWidthOverlap =
            currXPos + label_width >= labels[n].xPos && labels[n].xPos + labels[n].width >= currXPos;
          const hasHeightOverlap =
            currYPos + label_height >= labels[n].yPos && currYPos <= labels[n].yPos + label_height;

          if (hasWidthOverlap && hasHeightOverlap) {
            const minTopYPos = Math.min(currYPos + label_height, labels[n].yPos + label_height);
            const maxBottomYPos = Math.max(currYPos, labels[n].yPos);
            // increase y so it's just under the other label
            label_align_v += minTopYPos - maxBottomYPos; // should always be positive if there is overlap
            currYPos = d.y + label_align_v; // update y pos
          }
        }
        labels.push({ xPos: currXPos, yPos: currYPos, width: label_width });
        return label_align_v;
      })
      .attr('width', (d) => {
        const label_width = Math.min(d.shortName ? getTextWidth(d.shortName.toString()) : 10, 250);
        return label_width;
      })
      .attr('height', 20)
      .append('xhtml:div')
      .style('margin', 0)
      .style('padding', 0)
      .style('white-space', 'nowrap')
      .style('background-color', 'transparent')
      .style('text-align', (d) => {
        const label_align = d.x < width / 2 ? 'left' : 'right';
        return label_align;
      })
      .append('span')
      .attr('class', (d) => {
        if (
          (d.x < width / 2 && d.sourceLinksUnderHalfwayPoint > 1) ||
          (d.x >= width / 2 && d.targetLinksUnderHalfwayPoint > 1)
        ) {
          return 'deep-label';
        }
        return '';
      })
      .text((d) => d.shortName);
  }

  getRatioFields = (outsortAggregate) => {
    const { $dictionary } = this.props;
    const compositeAggs = $dictionary.get('aggregateComposites');
    const { leftOperand, rightOperand, fn, compositeFn } = outsortAggregate;
    let ratioFields = null;
    if (fn === 'composite') {
      // Currently only percent and latency apply
      const isPercent = compositeFn === 'multiply' && parseInt(rightOperand, 10) === 100;
      const cfg = isPercent ? compositeAggs.find((agg) => agg.value === leftOperand) : outsortAggregate;
      if (cfg && cfg.compositeFn === 'divide') {
        ratioFields = {
          numeratorField: cfg.leftOperand,
          denominatorField: cfg.rightOperand
        };
      }
    }
    return ratioFields;
  };

  getBracketColor = (value) => {
    const linkDataClass =
      Array.isArray(this.bracketDataClasses) &&
      this.bracketDataClasses.find((d) => value >= d.from && (value <= d.to || d.to === undefined));
    return linkDataClass && linkDataClass.color;
  };

  buildSeriesInternal(bucket, models) {
    this.clear();
    if (!this.chart || !models.length) {
      return;
    }

    const { $dictionary } = this.props;
    const metric = bucket.firstQuery.get('metric');
    const { outsortDataKey, outsortAggregate } = bucket.firstQuery;
    const metricColumns = $dictionary.get('metricColumns');
    const ratioFields = this.getRatioFields(outsortAggregate);
    const isRatioBased = !!ratioFields;

    const bracketOptions = bucket.firstQuery.get('bracketOptions');

    if (bracketOptions) {
      const metadata = getMetadata({ queryResults: bucket.queryResults, bracketOptions });
      this.bracketDataClasses = getBracketingDataClasses({ metadata, bracketOptions });
    } else {
      this.bracketDataClasses = null;
    }

    models.forEach((model) => {
      const isOverlay = model.get('isOverlay');
      if (isOverlay) {
        return;
      }

      const value = model.get(outsortDataKey);
      let numerator;
      let denominator;
      if (isRatioBased) {
        const { numeratorField, denominatorField } = ratioFields;
        numerator = model.get(numeratorField);
        denominator = model.get(denominatorField);
        if (outsortDataKey.includes('perc_')) {
          numerator *= 100;
        }
      }

      const metrics = [].concat(metric);
      const nodes = metrics.map((m) => model.get(metricColumns[m] || m));
      let x = 0;
      let xlen = nodes.length;
      let path;
      let y;
      let ylen;
      let found;
      let foundNext;
      const initialLinkLength = this.graph.links.length;
      const linkedLinks = [];

      for (; x < xlen; x += 1) {
        if (metrics[x].indexOf('bgp_aspath') !== -1) {
          path = nodes[x].split(' ');
          // eslint-disable-next-line prefer-destructuring
          nodes[x] = path[0];
          for (y = 1, ylen = path.length; y < ylen; y += 1) {
            metrics.splice(x + y, 0, `${metrics[x]}_${y}`);
            nodes.splice(x + y, 0, path[y]);
          }
          x += path.length - 1;
          xlen += path.length - 1;
        }
      }

      for (x = 0, xlen = nodes.length; x < xlen - 1; x += 1) {
        linkedLinks.push(initialLinkLength + x);
      }

      for (x = 0, xlen = nodes.length; x < xlen - 1; x += 1) {
        found = null;
        foundNext = null;
        for (y = 0, ylen = this.graph.nodes.length; y < ylen; y += 1) {
          if (this.graph.nodes[y].name === `${metrics[x]}__k_${nodes[x]}`) {
            found = y;
          }
          if (this.graph.nodes[y].name === `${metrics[x + 1]}__k_${nodes[x + 1]}`) {
            foundNext = y;
          }
        }
        if (found === null) {
          found = this.graph.nodes.length;
          this.graph.nodes.push({
            node: found,
            name: `${metrics[x]}__k_${nodes[x]}`,
            shortName: getShortName(metrics[x], nodes[x])
          });
        }
        if (foundNext === null) {
          foundNext = this.graph.nodes.length;
          this.graph.nodes.push({
            node: foundNext,
            name: `${metrics[x + 1]}__k_${nodes[x + 1]}`,
            shortName: getShortName(metrics[x + 1], nodes[x + 1])
          });
        }
        this.graph.links.push({
          source: found,
          target: foundNext,
          pathLinks: [].concat(linkedLinks),
          isRatioBased,
          value,
          numerator,
          denominator
        });
      }
    });

    this.redraw();
  }

  redraw() {
    const { $app } = this.props;

    // only do drawing if there's something to draw
    if (this.mounted && this.graph.nodes.length && this.graph.links.length) {
      $app.renderSync(() => {
        this.drawSankey(this.graph);
      });
      $app.renderSync(() => {
        this.dismissSpinner(50);
      });
    }
  }

  reflow() {
    this.redraw();
  }

  clear() {
    this.graph.nodes = [];
    this.graph.links = [];
  }

  getComponent() {
    const { dataview, fitToHeight, padChart } = this.props;
    if (!dataview.queryBuckets.activeBucketCount) {
      return null;
    }

    const metric = dataview.queryBuckets.activeBuckets[0].firstQuery.get('metric');
    if (metric.length < 2 && !metric.find((m) => m.includes('bgp_aspath'))) {
      throw new Error(
        'You must select at least one AS Path or two (2) group by dimensions to visualize data in a Sankey Diagram.'
      );
    }

    const height = fitToHeight ? '100%' : 'auto';

    if (padChart) {
      return (
        <Box px={2} overflow="auto" height={height}>
          <SankeyContainer style={{ height, minHeight: 100 }} ref={this.chartRef} />
        </Box>
      );
    }

    return <SankeyContainer style={{ height, minHeight: 100, overflow: 'auto' }} ref={this.chartRef} />;
  }
}

const config = {
  showTotalTrafficOverlay: true,
  showLegend: true,
  singleDataseries: true,
  timeBased: false,
  isSVG: true,
  enableToggle: false,
  buckets: [
    {
      name: 'Flow'
    }
  ]
};

export { config };
