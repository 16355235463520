import React from 'react';
import { observer } from 'mobx-react';
import { FlexColumn, Flex } from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import AlertingTable from 'app/views/alerting/components/AlertingTable';
import AlertCollection from 'app/stores/alerting/AlertCollection';
import PromptFeedback from './PromptFeedback';

@storeLoader('$alerting.policyCollection')
@observer
class JourneyAlertResponse extends React.Component {
  state = {
    collection: new AlertCollection()
  };

  componentDidMount() {
    const { collection } = this.state;
    const { query } = this.props;
    // Override because we don't need server filters and it breaks inline filters.
    collection.serverSortableFields = [];
    // Override because we don't want to clearFilters on result collapse/expand.
    collection.clearFilters = () => {};
    // flatten these because the collection re-nests under filter before fetching.
    const { filter, pagination } = query.get('query');
    collection.setServerFilter({ ...filter, pagination });
  }

  render() {
    const { collection } = this.state;
    const { promptHeight, onDelete, query } = this.props;
    return (
      <FlexColumn flex={1} position="relative">
        <Flex mb={1} height={promptHeight}>
          <AlertingTable collection={collection} miniKebab useVirtualizedTable />
        </Flex>
        <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between">
          <PromptFeedback model={query} onDelete={onDelete} />
        </Flex>
      </FlexColumn>
    );
  }
}

export default JourneyAlertResponse;
