/*
  Used to indicate if and to what degree test results are being aggregated
  Rendered as a tag with the aggregation level and explanation info in a tooltipped icon 
*/
import React from 'react';
import { observer } from 'mobx-react';
import { IoStatsChart } from 'react-icons/io5';
import { Box, Icon, Tag, Text, Tooltip } from 'core/components';

@observer
export default class AggregationTag extends React.Component {
  render() {
    const { testResults, agentResults, isAggregated: isAggregatedOverride, ...tagProps } = this.props;
    const aggLabel = testResults?.aggregationLabel || agentResults?.aggregationLabel; // ex: 10 minute
    // in the event that parent or subtest results return an incomplete aggregation object (no aggregation but isAggregated is true),
    // we can still show an indication of aggregated state. We can also handle an explicit override to mark as aggregated (useful for dashboards)
    const isAggregated = testResults?.isAggregated || agentResults?.isAggregated || isAggregatedOverride;

    if (aggLabel || isAggregated) {
      // parent and subtests show the aggregation value
      // anything else can leverage an escape hatch prop 'isAggregated' that will just show an indicator
      const label = aggLabel ? `${aggLabel} aggregates` : 'Aggregated';

      return (
        <Tag
          icon={IoStatsChart}
          intent="success"
          minimal
          rightIcon={
            <Tooltip
              content={
                <Box width={180}>
                  <Text as="div" mb={1}>
                    Larger time ranges will show averaged metrics from multiple test runs.
                  </Text>
                  <Text as="div">Changing test frequency may result in missing historical aggregate data</Text>
                </Box>
              }
              minimal
            >
              <Icon icon="info-sign" iconSize={14} />
            </Tooltip>
          }
          {...tagProps}
        >
          {label}
        </Tag>
      );
    }

    return null;
  }
}
