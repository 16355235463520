import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { MenuItem } from 'core/components';

@inject('$metrics')
@withRouter
@observer
export default class MetricsExplorerMenuItem extends Component {
  static defaultProps = {
    text: 'View in Metrics Explorer',
    icon: 'series-search'
  };

  state = {
    hash: undefined,
    loading: true
  };

  componentDidMount() {
    const { $metrics, query } = this.props;

    $metrics.queryToHash(query).then((hash) => this.setState({ hash, loading: false }));
  }

  get link() {
    const { hash } = this.state;
    return `/v4/nms/explorer/${hash}`;
  }

  get disabled() {
    const { loading } = this.state;
    return loading;
  }

  handleOnClick = () => {
    const { history, savedViewId } = this.props;

    if (savedViewId) {
      history.push(`/v4/nms/explorer/saved-view/${savedViewId}`);
    } else {
      history.push(this.link);
    }
  };

  render() {
    const { icon, text } = this.props;

    return <MenuItem icon={icon} text={text} onClick={this.handleOnClick} disabled={this.disabled} />;
  }
}
