import React from 'react';
import { Card } from 'core/components';

const SelectWidgetCard = (props) => {
  const { children, onClick } = props;

  return (
    <Card
      interactive
      display="flex"
      elevation={1}
      flexDirection="column"
      alignItems="flex-start"
      bg="appBackground"
      textAlign="left"
      p={2}
      onClick={onClick}
      minHeight={125}
    >
      {children}
    </Card>
  );
};

export default SelectWidgetCard;
