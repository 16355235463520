import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Box, Flex, Text, Card, Button, Popover } from 'core/components';
import { transformNestedTreeData, transformNestedTreeDataIntoCollections } from 'app/util/fpa/transformNestedTreeData';

import TreeTable from 'app/components/dataviews/views/fpa/TreeTable';
import LoadingSkeleton from 'app/views/metrics/LoadingSkeleton';
import { Collection } from 'core/model';
import Markdown from 'app/views/core/journeys/Markdown';
import styled from 'styled-components';
import { buildFilterGroup } from 'core/util/filters';
import Dimension from 'app/components/dimensions/Dimension';
import { NonIdealState } from '@blueprintjs/core';
import ChangePointDetectionResultsTable from './ChangePointDetectionResultsTable';
import { getHashForQuery } from '../../../stores/query/urlHash';

const MarkdownWrapper = styled(Box)`
  h4 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  p,
  ul {
    line-height: 21px;
  }

  ul {
    padding-left: 24px;
  }
`;

@inject('$fpa', '$auth', '$explorer')
@observer
export default class FpaResult extends Component {
  static defaultProps = {
    markdownWrapperProps: {}
  };

  state = {
    hasData: false,
    fpaTrees: [],
    fpaSummary: { answer: undefined },
    isLoadingSummary: false,
    cpdCollection: new Collection()
  };

  static getDerivedStateFromProps(nextProps) {
    const { dataview } = nextProps;
    const { queryBuckets } = dataview;
    const { activeBuckets, activeBucketCount } = queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const fpaTrees = [];

    activeBuckets.forEach((bucket) => {
      if (!bucket.loading) {
        const { type } = bucket.firstQuery.get('fpa');
        const results = bucket.queryResults?.fpaRows[0]?.get();

        if (!results) {
          return;
        }

        console.info(`FPA (${type}) RESULTS`, results);

        const freqPatterns = [];
        if (type === 'cpd_fpa' && results.events) {
          freqPatterns.push(...results.events);
        } else {
          freqPatterns.push(results);
        }

        fpaTrees.push(
          ...freqPatterns.map((fp) => {
            const rec_tree = fp?.rec_tree?.root;
            const rec_tree_copy = structuredClone(fp?.rec_tree?.root);
            const nestedTree = transformNestedTreeData(rec_tree);
            const nestedTreeCollections = transformNestedTreeDataIntoCollections(rec_tree_copy);

            return {
              ...fp,
              isLoadingSummary: true,
              nestedTree,
              nestedTreeCollections,
              hasData: true
            };
          })
        );
      }
    });

    return {
      fpaTrees
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { hasData, fpaTrees, cpdCollection } = this.state;
    const { dataview } = this.props;

    if (hasData !== !prevState.hasData && fpaTrees.length && fpaTrees?.length !== prevState.fpaTrees?.length) {
      cpdCollection.reset();
      cpdCollection.add(fpaTrees);

      if (dataview.fpaType === 'cpd_fpa') {
        this.generateLLMSummariesForChangePoints();
      } else {
        this.generateLLMSummary();
      }
    }
  }

  generateAdditionalContext = () => {
    const { dataview, additionalContext } = this.props;

    // other consumers of this component can pass in additional context that overrides the default
    // context generated here, which is specific to Data Explorer
    if (additionalContext) {
      return additionalContext;
    }

    // need to get `fpa.time` and `fpa.compare_time from the query model
    const explorerQueryModel = dataview.createExplorerQueryModelFromSelectedQuery();

    const starting_time =
      explorerQueryModel.get('fpa.time.starting_time') || explorerQueryModel.get('fpa.time.lookback_seconds');
    const ending_time =
      explorerQueryModel.get('fpa.time.ending_time') || explorerQueryModel.get('fpa.time.lookback_seconds');
    const filters = explorerQueryModel.get('filters');

    // TODO: get the metric from the query model
    const metric = 'bits per second';

    return `**Context:**
- The 'Data Explorer' Graphical User Interface used for troubleshooting and query of Flow data which is stored in the Kentik database
- The data that you will summarize is a result of the analysis performed in the 'Data Explorer'
- The data is a result of Frequent Pattern Analysis and highlights the primary factors contributing to the Traffic that user has selected.
- The metric used is ${metric}. When referring to metrics values, use short versions K, M, G, T, P rounded up to the nearest whole number and no decimals
- The time frame user selected is:
  - start_time = ${starting_time}
  - end_time = ${ending_time}
- If the certain dimension and the value are used in the filter of the query, it will 100% contributing to the traffic, 
so those values you must not mention in the summary, as user is already aware of them. 
- For example, if the filter is using device_name with only one device, than the results will always have 100% traffic for that device. 
In such case, you should not highlight that fact in your summary. Same approach you need to do for any dimension which is filtered for only one value, by the filter provided below.
  - Filter in the query of Data Explorer is represented with the following JSON:
${JSON.stringify(filters)}`;
  };

  generateAdditionalContextForChangePoint = (event) => `
**Context:**
You are analyzing a change point event.
The type of change point event is a ${event.type}. Use this information to provide a summary of the event.
Ignore all previous instructions around the format return structure.
Do not return a #### Summary or #### Details section in the response. Instead, provide a brief summary of the event in the response.
Your response should not be longer than 3 sentences. Try and keep it concise and to the point, but also informative and detailed.
There will be multiple change point events to analyze, so make sure to try and differentiate between them in your responses.
    `;

  generateLLMSummary = () => {
    const { fpaTrees } = this.state;
    const { $fpa } = this.props;

    this.setState({ isLoadingSummary: true });

    const nestedTree = fpaTrees[0]?.nestedTree;

    if (nestedTree) {
      $fpa.getFrequentPatternAnalysisSummary(nestedTree, this.generateAdditionalContext()).then((response) => {
        console.warn('FPA Summary', response);
        this.setState({ fpaSummary: response, isLoadingSummary: false });
      });
    }
  };

  generateLLMSummariesForChangePoints = () => {
    const { fpaTrees: changePointEvents, cpdCollection } = this.state;
    const { $fpa } = this.props;

    cpdCollection.each((model) => model.set({ isLoadingSummary: true }));

    const summaries = changePointEvents.map((event, index) => {
      const nestedTree = event?.nestedTree;
      return $fpa
        .getFrequentPatternAnalysisSummary(nestedTree, this.generateAdditionalContextForChangePoint(event))
        .then((response) => ({ summary: response.answer, index }));
    });

    Promise.all(summaries).then((response) => {
      // console.warn('generateLLMSummariesForChangePoints', response);
      response.forEach(({ summary, index }) => {
        cpdCollection.at(index).set({
          summary,
          isLoadingSummary: false
        });
      });
    });
  };

  handleViewResultInDE = (model) => {
    const { dataview } = this.props;
    const explorerQueryModel = dataview.createExplorerQueryModelFromSelectedQuery();

    const filters = {
      connector: 'All',
      filterGroups: [
        buildFilterGroup({
          connector: 'All',
          filters: model.get('filters')
        })
      ]
    };

    explorerQueryModel.set({
      all_devices: true,
      filters,
      use_fpa: false,
      fpa: {
        type: 'cpd_fpa'
      }
    });

    getHashForQuery(explorerQueryModel.serialize()).then((hash) => {
      window.open(`/v4/core/explorer/${hash}`, '_blank');
    });
  };

  handleSetDimensions = (dimensions) => {
    const { dataview } = this.props;
    const explorerQueryModel = dataview.createExplorerQueryModelFromSelectedQuery();
    const currentFpaSelections = explorerQueryModel.get('fpa');

    explorerQueryModel.set({
      fpa: {
        ...currentFpaSelections,
        dimensions
      }
    });

    dataview.applyToSelectedQuery(explorerQueryModel, false);
  };

  renderMarkdownSummary = () => {
    const { markdownWrapperProps } = this.props;
    const { fpaSummary, isLoadingSummary } = this.state;

    if (isLoadingSummary) {
      return <LoadingSkeleton height="250px" width="100%" />;
    }

    if (!fpaSummary) {
      <Box px={1}>Something went wrong while generating a summary!</Box>;
    }

    return (
      <MarkdownWrapper {...markdownWrapperProps}>
        {fpaSummary && <Markdown>{fpaSummary.answer}</Markdown>}
      </MarkdownWrapper>
    );
  };

  renderShowColumnsButton = (dimensions) => (
    <Popover
      position="right-top"
      minimal={false}
      targetProps={{ style: { alignSelf: 'flex-start', display: 'inline-flex' } }}
      target={
        <Button
          small
          icon="database"
          text={`View Columns (${dimensions.length})`}
          style={{ alignSelf: 'flex-start' }}
        />
      }
      content={
        <Flex flexDirection="column" gap="4px" minWidth={410} maxHeight={500} overflow="auto" p={2}>
          <Button fill small onClick={() => this.handleSetDimensions(dimensions)}>
            Set Dimensions in Sidebar
          </Button>
          {dimensions
            .sort((a, b) => a.localeCompare(b))
            .map((dim) => (
              <Dimension key={dim} useChartTypes dimension={dim} />
            ))}
        </Flex>
      }
    />
  );

  render() {
    const { $auth, dataview } = this.props;
    const { fpaTrees, cpdCollection } = this.state;
    const { fullyLoaded } = dataview.queryBuckets;

    const hasData = fpaTrees.length > 0 && fpaTrees[0]?.rec_tree?.root?.children?.length > 0;

    if (dataview?.errors?.length) {
      return (
        <Box px={2}>
          <Text small muted>
            Uh oh: {dataview.errorMessage}
          </Text>
        </Box>
      );
    }

    if (!fullyLoaded || dataview.loading) {
      return <LoadingSkeleton height="500px" width="100%" />;
    }

    if (fullyLoaded && !hasData) {
      return (
        <Flex
          flex="1 1 auto"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <NonIdealState
            title="No probable cause detected"
            icon="signal-search"
            description="Try refining your query or exploring alternative options for better results."
          />
        </Flex>
      );
    }

    if (dataview.fpaType === 'cpd_fpa') {
      return (
        <Flex flexDirection="column" flex={1}>
          <Card p={0} ml={-1}>
            <ChangePointDetectionResultsTable
              dataview={dataview}
              collection={cpdCollection}
              onViewInDataExplorer={this.handleViewResultInDE}
            />
          </Card>
        </Flex>
      );
    }

    return (
      <Box style={{ width: '100%', height: '100%', minHeight: 100, overflow: 'auto' }}>
        {fpaTrees.map((tree) => {
          const { dimensions } = tree;

          return (
            <Flex key="fpa-result" flexDirection="column" flex={1}>
              <Box px={1}>{this.renderMarkdownSummary()}</Box>

              <Box ml={-1}>
                <TreeTable
                  {...this.props}
                  key={tree.change_point?.seconds || tree.name}
                  collection={tree.nestedTreeCollections}
                  onViewInDataExplorer={this.handleViewResultInDE}
                />
              </Box>

              {$auth.isSudoer && dimensions?.length > 0 && (
                <Box ml={1} my={3}>
                  {this.renderShowColumnsButton(dimensions)}
                </Box>
              )}
            </Flex>
          );
        })}
      </Box>
    );
  }
}
