import React, { Component } from 'react';
import { uniq } from 'lodash';
import { TagInput as TagInputCore } from '@blueprintjs/core';
import { observer } from 'mobx-react';

@observer
class TagInput extends Component {
  static defaultProps = {
    unique: true,
    addOnBlur: true,
    separator: /[,\n\r\s]/
  };

  handleOnChange = (values) => {
    const { unique, onChange } = this.props;

    if (unique) {
      values = uniq(values);
    }

    onChange(values);
  };

  render() {
    const { field, separator, dataTestId, ...rest } = this.props;
    const values = field.getValue() || [];
    const inputProps = {
      'data-testid': dataTestId // this testid is used in the CloudExportConfigWizard jest tests.
    };

    return (
      <TagInputCore
        {...rest}
        separator={separator}
        label={field.label}
        values={values}
        onChange={this.handleOnChange}
        inputProps={inputProps}
      />
    );
  }
}

export default TagInput;
