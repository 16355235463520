import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Box, Flex, Text } from 'core/components';
import { getPacketLossValue, getMsValue } from 'app/views/synthetics/utils/syntheticsUtils';

const test_types = {
  agent: 'Agent-to-Agent',
  isDns: 'DNS Server Monitor',
  isHostname: 'Hostname',
  url: 'HTTPS/API',
  isBulkIpTest: 'IP Address',
  isMesh: 'Mesh',
  'page-load': 'Page Load'
};

const metric_types = {
  avg_latency: 'Latency',
  dns_resolution: 'DNS Resolution Time',
  avg_jitter: 'Jitter',
  packet_loss: 'Packet Loss'
};

@inject('$syn')
@observer
class SynthDashboardGauge extends Component {
  render() {
    const { measure, synth_test_metric, synth_test_type, testResults } = this.props;
    const { group_by, health, test_ids } = testResults;
    const [agg] = health;
    const { all_tests, groups } = agg;
    const value = all_tests[synth_test_metric] ? all_tests[synth_test_metric][measure] : 0;
    const friendly_value = synth_test_metric === 'packet_loss' ? getPacketLossValue(value) : getMsValue(value);
    const num_tests = test_ids.length;
    const group_type = `${group_by}s`;
    return (
      <Flex bg="#3A80EB" color="#FFFFFF" width="100%">
        <Box p={2}>
          <Box fontWeight="bold" fontSize={16}>
            {metric_types[synth_test_metric]} (<Text style={{ textTransform: 'capitalize' }}>{measure}</Text>)
          </Box>
          <Box fontSize={60}>{friendly_value}</Box>
          <Box fontSize={16}>
            {test_types[synth_test_type]} - {num_tests} Tests - {groups.length} {group_type}
          </Box>
        </Box>
      </Flex>
    );
  }
}

export default SynthDashboardGauge;
