import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Position } from '@blueprintjs/core';

import { Button, Menu, MenuItem, MenuDivider, Popover } from 'core/components';
import { isDev } from 'core/util';

const tetherOptions = {
  constraints: [
    {
      to: 'scrollParent',
      attachment: 'together',
      pin: true
    }
  ]
};

const popoverProps = {
  tetherOptions,
  minimal: true,
  position: Position.BOTTOM_RIGHT
};

@inject('$auth')
@observer
export default class LiveUpdateButton extends Component {
  renderLiveUpdateMenu(dataview) {
    const { $auth } = this.props;
    return (
      <Menu>
        <MenuDivider title="Update every..." />
        {($auth.hasSudo || $auth.isSpoofed || isDev) && (
          <MenuItem text="5 Seconds" onClick={() => dataview.setUpdateFrequency(5)} />
        )}
        {($auth.hasSudo || $auth.isSpoofed || isDev) && (
          <MenuItem text="10 Seconds" onClick={() => dataview.setUpdateFrequency(10)} />
        )}
        <MenuItem text="60 Seconds" onClick={() => dataview.setUpdateFrequency(60)} />
        <MenuItem text="90 Seconds" onClick={() => dataview.setUpdateFrequency(90)} />
        <MenuItem text="120 Seconds" onClick={() => dataview.setUpdateFrequency(120)} />
      </Menu>
    );
  }

  render() {
    const { dataview, buttonProps, loading } = this.props;

    if (dataview.hasUpdateFrequency) {
      return (
        <Button icon="stop" intent="danger" onClick={() => dataview.setUpdateFrequency()} ml={1} minimal small>
          Stop Updating
        </Button>
      );
    }

    return (
      <Popover content={this.renderLiveUpdateMenu(dataview)} {...popoverProps}>
        <Button
          className="translucent"
          disabled={loading}
          icon="automatic-updates"
          rightIcon="caret-down"
          text="Live Update"
          ml={1}
          // minimal
          small
          {...buttonProps}
        />
      </Popover>
    );
  }
}
