// simple function for adding a text element to an svg
// useful for knowing how large text will be before rendering
export const makeText = (selection, size = 12) =>
  selection.append('text').attr('font-family', 'sans-serif').attr('font-size', size).attr('pointer-events', 'none');

// builds d value for a path
// horizontal line between two points
export const traceCurve = (d) => {
  const { points } = d;
  return points
    .map(([x1, y1], index) => {
      if (index === 0) {
        return `M ${x1} ${y1}`;
      }
      const [x0, y0] = points[index - 1];
      const dx = 0.5 * (x1 - x0);

      return `C ${x0 + dx} ${y0}, ${x1 - dx} ${y1}, ${x1} ${y1}`;
    })
    .join(' ');
};

// builds d value for a path
// vertical line between two points
export const verticalTraceCurve = (d) => {
  const { points } = d;
  const [[x0, y0], [x1, y1]] = points;
  const yDiff = Math.abs(y0 - y1);
  const offset = ((y0 > y1 ? -1 : 1) * yDiff) / 3;

  return `M ${x0} ${y0} C ${x0 - offset} ${y0 + offset}, ${x1 - offset} ${y1 - offset}, ${x1} ${y1}`;
};

// gets the angle of a path, must provide two points along the path
// preferably those points are next to each other
export const getAngleOfPath = ([x0, y0], [x1, y1]) => (Math.atan2(y1 - y0, x1 - x0) * 180) / Math.PI;

// gets the angle of a path at a specific length along the path
export const getPointAndAngleOfPathAtLength = (pathSvg, length) => {
  const { x: x0, y: y0 } = pathSvg.getPointAtLength(length - 1);
  const { x: x1, y: y1 } = pathSvg.getPointAtLength(length);
  const point = [x1, y1];
  const angle = getAngleOfPath([x0, y0], [x1, y1]);

  return { point, angle };
};
