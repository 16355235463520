import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { memoize } from 'lodash';
import Validator from 'validatorjs';
import { showErrorToast, Flex, Grid } from 'core/components';
import { formConsumer } from 'core/form';
import SubscriptionIdDropZone from './SubscriptionIdDropZone';
import SubscriptionCard from './SubscriptionCard';

@inject('$clouds', '$cloudExportWizard')
@formConsumer
@observer
export default class EnrichmentScopeEditor extends React.Component {
  state = {
    loading: false
  };

  // returns a unique list of subscriptions taking a list dropped in and the current listing in the form
  mergeSubscriptionIds(ids = []) {
    const { form } = this.props;

    return form.getValue('properties.azure_enrichment_scope').reduce((subscriptionIds, scope) => {
      if (!ids.includes(scope.subscriptionId)) {
        subscriptionIds.push(scope.subscriptionId);
      }

      return subscriptionIds;
    }, ids);
  }

  /*
    Expects an array of subscription ids that come in from the drop zone via paste or file drag/drop.
    The ids that come in are immediately validated using the rules from the subscription id field of the form and classified as valid or invalid.
    Ids classified as invalid will be immediately added to the enrichment scope, there's nothing left to do with them but report on them being bad.
    Ids classified as valid are sent for further validation. If we can successfully access them via the Azure REST API, they are confirmed to be valid.
  */
  handleSubscriptionIdsChange = (ids) => {
    const { $clouds, $cloudExportWizard } = this.props;
    const { form, azureSubscriptionId } = $cloudExportWizard;
    const scopeField = form.getField('properties.azure_enrichment_scope');
    // remove the subscription id already entered in properties.azure_subscription_id field
    if (azureSubscriptionId && ids.includes(azureSubscriptionId)) {
      const subIdIndex = ids.indexOf(azureSubscriptionId);
      ids.splice(subIdIndex, 1);
    }
    const mergedIds = this.mergeSubscriptionIds(ids);
    const subscriptionIdRules = [
      'required',
      'max:36',
      'regex:^({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}$'
    ];
    // classify the ids valid or invalid using the rules from the subscription id field
    const subscriptionIds = mergedIds.reduce(
      (acc, id) => {
        const data = { id };
        const rules = { id: subscriptionIdRules };
        const validation = new Validator(data, rules);

        acc[validation.passes() ? 'valid' : 'invalid'].push(id);

        return acc;
      },
      { valid: [], invalid: [] }
    );

    // clear out the previously invalid items
    scopeField.setValue(scopeField.getValue().filter((f) => f.isValid));

    // just add all the invalid subscriptions directly to scope so we can report on it, there's no need to send them off to ask the API
    subscriptionIds.invalid.map((id) =>
      scopeField.add({
        isValid: false,
        message: 'Subscription ID is invalid',
        subscriptionId: id
      })
    );

    if (subscriptionIds.valid.length > 0) {
      this.setState({ loading: true }, () => {
        $clouds
          .checkAzureSubscriptionIds(subscriptionIds.valid)
          .then((response) => {
            const scopeFieldValues = [];

            response.forEach((item) => {
              // preserve previously selected resource groups
              // if we have to re-paste or re-drag/drop our file, we want to keep the resource groups already selected
              const existingField = scopeField.find((f) => f.subscriptionId.getValue() === item.subscriptionId);

              scopeFieldValues.push({
                isValid: item.success,
                message: item.message,
                subscriptionId: item.subscriptionId,
                resourceGroups: existingField?.resourceGroups.getValue() || []
              });
            });

            // update the enrichment scope with all of our subscription ids and their statuses, grouping the invalid ones at the top
            scopeField.setValue(
              scopeField
                .getValue()
                .filter((f) => !f.isValid)
                .concat(scopeFieldValues)
            );
          })
          .catch(() => showErrorToast('An error occurred attempting to validate the subscription ids'))
          .finally(() => this.setState({ loading: false }));
      });
    }
  };

  /*
    Queries the API for an explicit list of resource groups for a given subscription id, memoized to limit the chatter
  */
  getResourceGroupOptions = memoize(
    (scopeField) => {
      const { $clouds } = this.props;
      const subscriptionId = scopeField.subscriptionId.getValue();

      return $clouds.getAzureResourceGroups(subscriptionId).catch(() => {
        // remove the cache entry so when we fetch again, we're not going to get the busted promise from cache
        this.getResourceGroupOptions.cache.delete(subscriptionId);

        showErrorToast(`An error occurred attempting to get the resource groups for ${subscriptionId}`);

        return [];
      });
    },
    (scopeField) => scopeField.subscriptionId.getValue()
  );

  /*
    Loads the resource group select control options and filters by search criteria
  */
  handleQuery = (scopeField, search) =>
    this.getResourceGroupOptions(scopeField)
      .then((resourceGroups) => resourceGroups.map((resourceGroup) => ({ label: resourceGroup, value: resourceGroup })))
      .then((options) => {
        if (typeof search === 'string') {
          return options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
        }

        return options;
      });

  /*
    Classifies the current enrichment scope values based on their 'isValid' internal value.
    This is used to report the status of the items in the drop zone.
  */
  get subscriptionBreakdown() {
    const { form } = this.props;
    const scopeField = form.getField('properties.azure_enrichment_scope');

    return scopeField.fieldStates.reduce(
      (acc, field) => {
        if (field.isValid.getValue()) {
          acc.valid += 1;
        } else {
          acc.invalid += 1;
        }

        return acc;
      },
      { valid: 0, invalid: 0 }
    );
  }

  render() {
    const { form } = this.props;
    const { loading } = this.state;
    const scopeField = form.getField('properties.azure_enrichment_scope');

    return (
      <Flex flexDirection="column">
        <SubscriptionIdDropZone
          mb={2}
          breakdown={this.subscriptionBreakdown}
          loading={loading}
          onSubscriptionIdListChange={this.handleSubscriptionIdsChange}
        />

        {!loading && (
          <Grid gridTemplateColumns="repeat(2, 1fr)">
            {scopeField.map((scope, idx) => (
              <SubscriptionCard
                key={scope.subscriptionId.getValue()}
                field={scope}
                scopeField={scopeField}
                scopeFieldIndex={idx}
                onQuery={this.handleQuery}
              />
            ))}
          </Grid>
        )}
      </Flex>
    );
  }
}
