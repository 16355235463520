import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { Box, Button, Collapse, EmptyState, Flex, Text, Spinner, Suspense } from 'core/components';
import { VirtualizedTable } from 'core/components/table';
import Collection from 'core/model/Collection';
import { InputGroup } from 'core/form';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import withHybridTopoSettings from 'app/views/hybrid/maps/components/settingsToolbar/withHybridTopoSettings';
import summaryQuery from 'shared/hybrid/resourceGraphQueries/azureEntityExplorerWidgetSummary';
import { convertResourceGraphTypeToTopologyType } from 'shared/hybrid/resourceGraphQueries/constants';
import { generateEntityMap } from './AzureEntityExplorerMap';
import { EntityRow } from './EntityExplorerRenderers';

const {
  LOCATION,
  VNET,
  LOCAL_NETWORK_GATEWAY,
  NAT_GATEWAY,
  VNET_GATEWAY,
  EXPRESS_ROUTE_CIRCUIT,
  FIREWALL,
  LOAD_BALANCER,
  APPLICATION_GATEWAY,
  VIRTUAL_HUB,
  SUBNET
} = ENTITY_TYPES.get('azure');

@withHybridTopoSettings
@inject('$clouds', '$dictionary')
@withRouter
@observer
class AzureEntityExplorerWidget extends Component {
  static defaultProps = {
    entityRowHeight: 37,
    contentRef: React.createRef()
  };

  state = {
    loading: true,
    selected: null,
    selectedId: null,
    searchValue: '',
    contentOverflow: 'auto'
  };

  async fetchCounts() {
    const { $clouds, $dictionary } = this.props;

    const locations = $dictionary.get('cloudMetadata')?.azure?.regions ?? {};

    return $clouds
      .fetchAzureResourceGraphQueryData({
        query: summaryQuery
      })
      .then((queryResponse) => {
        if (!queryResponse.length) {
          return null;
        }
        // group response by type
        const groupedByType = queryResponse.reduce((carry, data) => {
          const { id, type, location } = data;
          const topologyType = convertResourceGraphTypeToTopologyType(type);
          carry[topologyType] = carry[topologyType] ?? {};

          const { coordinates, name } = locations[location] ?? {};

          carry[topologyType][id] = { ...data, type: topologyType };

          // exctract locations into its own field
          carry.locations = carry?.locations ?? {};
          const locationEntityTypeClount = carry.locations?.[location]?.[topologyType] ?? 0;

          carry.locations[location] = {
            ...(carry.locations?.[location] ?? {}),
            id: location,
            name,
            coordinates,
            [topologyType]: locationEntityTypeClount + 1
          };

          return carry;
        }, {});

        const parsedTopology = this.parseTopology(groupedByType);
        this.ENTITY_MAP = generateEntityMap({ jumpToFn: this.jumpTo, topology: groupedByType });

        return parsedTopology;
      });
  }

  async fetchTopology() {
    this.setState({ loading: true });
    this.fetchCounts().then((topology) => {
      this.setState({ loading: false, topology });
    });
  }

  componentDidMount() {
    this.fetchTopology();
  }

  componentDidUpdate(prevProps, prevState) {
    const { topology, selected, selectedId, searchValue } = this.state;

    if (prevState.selected !== selected) {
      if (selected !== null) {
        // Wait for Collapse animation
        setTimeout(() => {
          prevProps.contentRef.current.scrollTo({
            top: selected * prevProps.entityRowHeight,
            left: 0,
            behavior: 'smooth'
          });
          if (selectedId) {
            topology[selected].list.get(selectedId).select();
            this.setState({ selectedId: null });
          }

          if (selected === 0) {
            this.hideScrollbars();
          }

          // hide the scrollbars when scrolling has ended
          prevProps.contentRef.current.addEventListener('scrollend', () => this.hideScrollbars(), { once: true });
        }, 200);
      } else {
        // Wait for Collapse animation, otherwise you get can scrollbars popping in/out
        setTimeout(() => {
          this.setState({ contentOverflow: 'auto' });
        }, 200);
      }
      if (prevState.selected !== null) {
        topology[prevState.selected].list.clearSelection();
        topology[prevState.selected].list.clearSort();
      }
    }

    if (prevState.searchValue !== searchValue) {
      topology?.forEach((entity) => (entity.name !== 'instance' ? entity.list.filter(searchValue) : null));
    }
  }

  hideScrollbars = () => {
    this.setState({ contentOverflow: 'hidden' });
  };

  parseTopology(data) {
    const topology = [
      LOCATION,
      VNET,
      NAT_GATEWAY,
      VNET_GATEWAY,
      FIREWALL,
      VIRTUAL_HUB,
      LOAD_BALANCER,
      APPLICATION_GATEWAY,
      EXPRESS_ROUTE_CIRCUIT,
      LOCAL_NETWORK_GATEWAY
    ].flatMap((entityType) => {
      if (entityType === VNET) {
        const vnetEntities = data[entityType] ?? {};
        const subnetEntities = Object.values(vnetEntities).reduce((acc, vnet) => {
          const subnets = (vnet.subnets || []).map((subnet) => ({
            ...subnet,
            type: convertResourceGraphTypeToTopologyType(subnet.type),
            key: subnet.id,
            tenantId: vnet.tenantId,
            subscriptionId: vnet.subscriptionId,
            resourceGroup: vnet.resourceGroup,
            provisioningState: subnet.properties.provisioningState,
            addressPrefix: subnet.properties.addressPrefix,
            vnetName: vnet.name
          }));

          return acc.concat(subnets);
        }, []);

        return [
          { name: VNET, list: this.objToCollection(vnetEntities) },
          { name: SUBNET, list: this.objToCollection(subnetEntities) }
        ];
      }

      return {
        name: entityType,
        list: this.objToCollection(data[entityType] ?? {})
      };
    });

    return topology.filter((entity) => entity.list.size !== 0);
  }

  objToCollection(obj) {
    return new Collection(Object.keys(obj).map((key) => ({ key, ...obj[key] })));
  }

  // Toggles row selection to expand/collapse entity table
  toggleRow({ index }) {
    const { selected } = this.state;
    if (index === selected) {
      this.setState({ selected: null, selectedId: null });
    } else {
      this.setState({ selected: index, selectedId: null });
    }
  }

  // Jumps to the Entity id in the given section
  jumpTo = ({ sectionType, id }) => {
    const { topology } = this.state;
    const sectionIdx = topology.findIndex((entity) => entity.name === sectionType);
    this.setState({ selected: sectionIdx, selectedId: id });
  };

  renderData() {
    const { topology } = this.state;
    if (!topology) {
      return <EmptyState title="No Topology Found" />;
    }
    return (
      <Flex flexDirection="column" position="relative" top="38px" zIndex={1}>
        {topology.map((entity, index) => this.invItemRenderer({ entity, index }))}
      </Flex>
    );
  }

  invItemRenderer({ entity, index }) {
    const { height, entityRowHeight } = this.props;
    const { selected, selectedId } = this.state;
    const { label, icon, renderer, tableProps, rowHeight = 48, expandHeight = 0 } = this.ENTITY_MAP[entity.name];

    return (
      <Box key={`${entity.name}-${entity.list.size}`}>
        <EntityRow onClick={() => this.toggleRow({ index })} height={entityRowHeight} selected={index === selected}>
          {icon}
          <Text px={1}>{label}</Text>
          <Box flex={1} />
          {renderer && renderer(entity)}
          {!renderer && (
            <Flex justifyContent="flex-end">
              <Text as="div" fontWeight="bold">
                {entity.list.size}
              </Text>
              {entity.list.hasFilter && entity.list.unfilteredSize !== entity.list.size && (
                <Text as="div" muted>
                  &nbsp;{`of ${entity.list.unfilteredSize}`}
                </Text>
              )}
            </Flex>
          )}
        </EntityRow>
        <Collapse isOpen={index === selected}>
          <Flex flexDirection="column" height={height - 115} borderBottom="thin">
            <VirtualizedTable
              collection={entity.list}
              rowHeight={({ model }) => (model && model.isSelected ? rowHeight + expandHeight : rowHeight)}
              scrollToIndex={selectedId ? entity.list.get(selectedId)?.index : undefined}
              scrollToAlignment="start"
              flexed
              {...tableProps}
            />
          </Flex>
        </Collapse>
      </Box>
    );
  }

  handleSearch = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  clearSearch() {
    this.setState({ searchValue: '' });
  }

  renderWidgetTitle() {
    const { title } = this.props;

    return (
      <Flex justifyContent="space-between" width="100%" alignItems="center">
        <div>{title}</div>
      </Flex>
    );
  }

  render() {
    const { canCustomize, onRemove, contentRef } = this.props;
    const { loading, selected, contentOverflow, searchValue } = this.state;

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        onRemove={onRemove}
        title={this.renderWidgetTitle()}
        ref={contentRef}
        overflow={contentOverflow}
      >
        <Suspense
          loading={loading}
          fallback={
            <Box pt={2}>
              <Spinner size={24} />
            </Box>
          }
        >
          <Flex width="100%" position="absolute" zIndex={5} bg="appBackground" maxHeight={39} borderBottom="thin">
            <Box width={selected === null ? 0 : 100} overflow="hidden" style={{ transition: 'width 0.2s ease-out' }}>
              <Button
                m="4px 0 4px 4px"
                icon="chevron-left"
                text="Go Back"
                onClick={() => this.toggleRow({ index: selected })}
                width={96}
                outlined
              />
            </Box>
            <Box flex={1} p="4px">
              <InputGroup
                leftIcon="search"
                value={searchValue}
                onChange={this.handleSearch}
                placeholder="Search by name, id, etc..."
                fill
                rightElement={
                  searchValue !== '' ? (
                    <Button small minimal icon="cross" iconSize={16} onClick={() => this.clearSearch()} />
                  ) : null
                }
              />
            </Box>
          </Flex>
          {this.renderData()}
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default AzureEntityExplorerWidget;
