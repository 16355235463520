import { METRICS_ENTITY_TYPE_LABEL_MAP } from 'app/stores/metrics/metricsConstants';
import { NMS_ENTITY_TYPES } from '@kentik/ui-shared/nms/policies';

export const ENTITY_DIMENSIONS = {
  [NMS_ENTITY_TYPES.DEVICE]: {
    ID: 'device_id',
    NAME: 'device_name'
  },
  [NMS_ENTITY_TYPES.INTERFACE]: {
    ID: 'ifindex',
    NAME: 'name',
    DESCRIPTION: 'ifDescr'
  },
  [NMS_ENTITY_TYPES.BGP]: {
    INDEX: 'index',
    PEER_AS: 'peer-as',
    REMOTE_IP: 'transport/remote-address'
  },
  [NMS_ENTITY_TYPES.COMPONENT]: {
    INDEX: 'index',
    NAME: 'name'
  },
  [NMS_ENTITY_TYPES.AGENT]: {
    AGENT_ID: 'agent_id',
    AGENT_NAME: 'agent_name',
    KAGENT_HOST: 'kagent_host'
  }
};

class EntityData {
  #device;

  #interface;

  #bgp;

  #component;

  #agent;

  #type;

  #typeLabel;

  constructor(type, typeLabel) {
    this.#type = type;
    this.#typeLabel = typeLabel;
  }

  set device(partialDevice) {
    const prevDevice = this.#device || {};
    this.#device = {
      ...prevDevice,
      ...partialDevice
    };
  }

  get device() {
    return this.#device;
  }

  set interface(partialInterface) {
    const prevInterface = this.#interface || {};
    this.#interface = {
      ...prevInterface,
      ...partialInterface
    };
  }

  get interface() {
    return this.#interface;
  }

  set component(partialComponent) {
    const prevComponent = this.#component || {};
    this.#component = {
      ...prevComponent,
      ...partialComponent
    };
  }

  get component() {
    return this.#component;
  }

  set bgp(partialBgp) {
    const prevbgp = this.#bgp || {};
    this.#bgp = {
      ...prevbgp,
      ...partialBgp
    };
  }

  get bgp() {
    return this.#bgp;
  }

  get hasBgpData() {
    const { index } = this.bgp || {};
    return !!index;
  }

  get hasBgpDisplayData() {
    const { peerAs, remoteIp } = this.bgp || {};
    return peerAs || remoteIp;
  }

  get agent() {
    return this.#agent;
  }

  set agent(partialAgent) {
    const prevAgent = this.#agent || {};
    this.#agent = {
      ...prevAgent,
      ...partialAgent
    };
  }

  get hasAgentData() {
    const { id } = this.agent || {};
    return !!id;
  }

  get hasAgentDisplayData() {
    const { name } = this.agent || {};
    return !!name;
  }

  get type() {
    return this.#type;
  }

  get typeLabel() {
    return this.#typeLabel;
  }
}

export const getEntityData = (alertModel) => {
  if (!alertModel?.nmsAlarmContext) {
    return null;
  }
  const { nmsAlarmContext } = alertModel;

  // If this alarm is not associated with a policy that uses an entity type to define its target, exit early
  if (!nmsAlarmContext?.policy?.target?.entity_type) {
    return null;
  }

  const { alarmTargets } = nmsAlarmContext;
  if (!alarmTargets) {
    return null;
  }

  let allAlarmContextTags = {};
  Object.values(alarmTargets).forEach((measurementInfo) => {
    const { Tags } = measurementInfo;
    if (Tags) {
      allAlarmContextTags = {
        ...allAlarmContextTags,
        ...Tags
      };
    }
  });

  const type = nmsAlarmContext?.policy?.target?.entity_type;
  const typeLabel = METRICS_ENTITY_TYPE_LABEL_MAP[nmsAlarmContext?.policy?.target?.entity_type];

  const entityData = new EntityData(type, typeLabel);

  if (type === NMS_ENTITY_TYPES.AGENT) {
    entityData.agent = {
      id: allAlarmContextTags[ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.AGENT].AGENT_ID],
      name:
        allAlarmContextTags[ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.AGENT].AGENT_NAME] ||
        allAlarmContextTags[ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.AGENT].KAGENT_HOST]
    };
    return entityData;
  }

  const deviceId = allAlarmContextTags[ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.DEVICE].ID];
  if (!deviceId) {
    // If we don't have minimally necessary data for any of our entities (interface ID or device ID), return early.
    // Device ID is required for both device and interface entity details.
    return null;
  }

  const interfaceId = allAlarmContextTags[ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.INTERFACE].ID];

  entityData.device = {
    id: deviceId,
    name: allAlarmContextTags[ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.DEVICE].NAME]
  };

  // Interface
  if (interfaceId) {
    entityData.interface = {
      id: interfaceId,
      deviceId,
      name: allAlarmContextTags[ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.INTERFACE].NAME],
      description: allAlarmContextTags[ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.INTERFACE].DESCRIPTION]
    };
  }

  if (entityData.type === NMS_ENTITY_TYPES.BGP) {
    entityData.bgp = {
      index: allAlarmContextTags[ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.BGP].INDEX],
      peerAs: allAlarmContextTags[ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.BGP].PEER_AS],
      remoteIp: allAlarmContextTags[ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.BGP].REMOTE_IP]
    };
  }

  if (entityData.type === NMS_ENTITY_TYPES.COMPONENT) {
    entityData.component = {
      index: allAlarmContextTags[ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.COMPONENT].INDEX],
      name: allAlarmContextTags[ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.COMPONENT].NAME]
    };
  }

  return entityData;
};
