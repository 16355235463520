import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Button, Dialog, Flex, Box } from 'core/components';
import { Form, Field, TextArea } from 'core/form';

const fields = {
  info: {
    label: 'Debug Info',
    placeholder: 'Enter the debug info to append to each query',
    rules: 'required'
  }
};

const options = {
  name: 'Debug Mode'
};

@Form({ fields, options })
@inject('$app')
@observer
class KDEDebugModeDialog extends Component {
  handleKeyboardSubmit = (e) => {
    const { form } = this.props;
    if (e && e.key === 'Enter') {
      e.stopPropagation();
      if (form.valid) {
        this.handleSubmit();
      }
    }
  };

  handleSubmit = async () => {
    const { form, $app } = this.props;
    $app.enableDebugMode(form.getValues());
  };

  handleCancel = () => {
    const { $app } = this.props;
    $app.setDebugModeOpen(false);
  };

  render() {
    const { form } = this.props;

    if (!form) {
      return null;
    }

    return (
      <Dialog
        title="Enable Debug Mode"
        {...this.props}
        onClose={this.handleCancel}
        style={{ top: 100, width: 475, minHeight: 100 }}
      >
        <Dialog.Body>
          <Field name="info" autoFocus>
            <TextArea fill onKeyPress={this.handleKeyboardSubmit} />
          </Field>
        </Dialog.Body>
        <Dialog.Footer>
          <Flex>
            <Box flexAuto />
            <Button text="Cancel" onClick={this.handleCancel} style={{ marginRight: 8 }} />
            <Button
              disabled={!form.valid || !form.dirty}
              intent="primary"
              text="Enable Debug Mode"
              onClick={this.handleSubmit}
            />
          </Flex>
        </Dialog.Footer>
      </Dialog>
    );
  }
}

export default KDEDebugModeDialog;
