import React from 'react';
import styled, { css } from 'styled-components';
import {
  space,
  width,
  flex,
  fontWeight,
  fontSize,
  display,
  lineHeight,
  opacity,
  fontStyle,
  color,
  textAlign,
  letterSpacing,
  minHeight,
  maxHeight,
  maxWidth,
  themeGet
} from 'styled-system';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import { textTransform, textDecoration, whiteSpace, textOverflow } from './utils/typography';

const StyledText = styled.span`
  ${space};
  ${width};
  ${flex};
  ${fontSize};
  ${display};
  ${fontWeight};
  ${color};
  ${textTransform};
  ${textDecoration};
  ${textAlign};
  ${fontStyle};
  ${letterSpacing};
  ${lineHeight};
  ${opacity};
  ${whiteSpace};
  ${textOverflow};
  ${minHeight};
  ${maxHeight};
  ${maxWidth};

  ${({ hoverColor }) =>
    hoverColor &&
    css`
      &:hover {
        color: ${themeGet(`colors.${hoverColor}`, hoverColor)};
      }
    `}
`;

const Text = ({
  className,
  monospace,
  runningText,
  large,
  small,
  showSkeleton,
  muted,
  disabled,
  ellipsis,
  ...rest
}) => {
  let { fontSize: fontSizeProp } = rest;
  if (monospace && small) {
    fontSizeProp = 10;
  }

  return (
    <StyledText
      {...rest}
      fontSize={fontSizeProp}
      className={classNames(
        {
          [Classes.MONOSPACE_TEXT]: monospace,
          [Classes.RUNNING_TEXT]: runningText,
          [Classes.TEXT_LARGE]: large,
          [Classes.TEXT_SMALL]: small,
          [Classes.TEXT_MUTED]: muted,
          [Classes.TEXT_DISABLED]: disabled,
          [Classes.TEXT_OVERFLOW_ELLIPSIS]: ellipsis,
          [Classes.SKELETON]: showSkeleton
        },
        className
      )}
    />
  );
};

Text.defaultProps = {
  as: 'span'
};

export default Text;
