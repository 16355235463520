import React from 'react';
import styled from 'styled-components';

import { Box, Flex, FlexColumn, Heading, Icon, Text } from 'core/components';
import SelectedFilterGroup from 'app/components/interfaceGroup/SelectedFilterGroup';
import InterfaceGroupListItem from 'app/components/interfaceGroup/InterfaceGroupListItem';

const SectionHeader = styled(Flex)`
  background-color: ${(props) => (props.theme.name === 'dark' ? 'rgba(138,155,168,0.15)' : 'specialMuted')};
  padding: 7px 8px;
  align-items: center;
  justify-content: space-between;
`;

const SelectedFiltersSummary = ({
  filterGroups,
  staticInterfaces,
  onRemoveGroup,
  onRemoveInterface,
  staticInterfacesTitle,
  staticOnly
}) => (
  <Flex flexDirection="column" flex={1} overflow="auto">
    {!staticOnly && (
      <>
        <SectionHeader>
          <Box>
            <Icon icon="antenna" color={filterGroups.length === 0 ? 'muted' : 'primary'} iconSize={18} />
            <Heading level={5} mb={0} ml={1} display="inline-block">
              Dynamic Groups
            </Heading>
          </Box>
        </SectionHeader>
        <Box>
          {filterGroups.length === 0 && (
            <FlexColumn alignItems="center" justifyContent="center" minHeight={100} height={150} borderTop="thin">
              <Icon icon="folder-close" color="muted" iconSize={36} />
              <Text as="div" muted textAlign="center" fontSize={14} my={1}>
                No dynamic groups created
              </Text>
            </FlexColumn>
          )}
          {filterGroups.length > 0 &&
            filterGroups.map((filterGroup) => (
              <SelectedFilterGroup
                key={JSON.stringify(filterGroup.filters)}
                filterGroup={filterGroup}
                onRemove={onRemoveGroup}
              />
            ))}
        </Box>
      </>
    )}
    <SectionHeader borderTop="thin">
      <Box>
        <Icon icon="confirm" color={staticInterfaces.length === 0 ? 'muted' : 'primary'} iconSize={18} />
        <Heading level={5} mb={0} ml={1} display="inline-block">
          {staticInterfacesTitle || 'Selected Interfaces'}
        </Heading>
      </Box>
    </SectionHeader>
    {staticInterfaces.length === 0 && (
      <FlexColumn alignItems="center" justifyContent="center" minHeight={100} height={150} borderTop="thin">
        <Icon icon="folder-close" color="muted" iconSize={36} />
        <Text as="div" muted textAlign="center" fontSize={14} my={1}>
          No interfaces selected
        </Text>
      </FlexColumn>
    )}
    {staticInterfaces.map((intf, idx) => (
      <InterfaceGroupListItem
        key={`${intf.snmp_id}|${intf.device_id}`}
        intf={intf}
        onRemoveInterface={onRemoveInterface}
        isLast={idx === staticInterfaces.length - 1}
      />
    ))}
  </Flex>
);

export default SelectedFiltersSummary;
