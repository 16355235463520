import React from 'react';
import { inject } from 'mobx-react';
import { Box, Flex, Text } from 'core/components';
import InterfaceLink from 'app/components/links/InterfaceLink';
import { formatBytesGreek } from 'core/util';

function getKeyLabel(key) {
  if (key === 'isAbove') {
    return 'Lower Sublayer Interface';
  }
  if (key === 'isBelow') {
    return 'Parent Interface';
  }
  return key.replace('if', '');
}

function getValue($dictionary, $devices, interfaceKvs, model, key) {
  const { snmpMetadata } = $dictionary.dictionary;
  const mapping = snmpMetadata[key];
  const value = interfaceKvs[key];
  if (mapping) {
    return `${mapping[value]} (${value})`;
  }
  if (key === 'isAbove' || key === 'isBelow') {
    const device = $devices.deviceSummariesById[model.get('device_id')];

    if (!device) {
      return value.split(',').join(', ');
    }

    return (
      <Text as={Flex} flexWrap="wrap" small>
        {value.split(',').map((snmpId) => (
          <InterfaceLink key={snmpId} snmp_id={snmpId} device_name={device.device_name} mr="4px">
            {snmpId}
          </InterfaceLink>
        ))}
      </Text>
    );
  }
  return value;
}

const InterfaceKvsMetadata = ({ $dictionary, $devices, model }) => {
  const interfaceKvs = model.get('interfaceKvs') || {};
  const capacity = model.get('snmp_speed');
  const formattedCapacity = capacity !== '0' ? formatBytesGreek(capacity * 1000000, '', 0.9991) : 'N/A';
  const type = model.get('snmp_type');
  return (
    <>
      {type && (
        <Flex>
          <Box flex={1}>
            <Text small color="muted" fontWeight="normal" mr="4px">
              Type
            </Text>
          </Box>
          <Box flex={1}>
            <Text small>{$dictionary.get('snmpMetadata.snmpType')[model.get('snmp_type')]}</Text>
            <Text small ml="4px" muted>
              ({model.get('snmp_type')})
            </Text>
          </Box>
        </Flex>
      )}
      <Flex>
        <Box flex={1}>
          <Text small color="muted" fontWeight="normal" mr="4px">
            Capacity
          </Text>
        </Box>
        <Text flex={1} small>
          {formattedCapacity}
          {model.overriddenFields.includes('snmp_speed') && (
            <Text muted small>
              (Overridden)
            </Text>
          )}
        </Text>
      </Flex>
      {Object.keys(interfaceKvs)
        .filter((key) => key !== 'updated')
        .map((key) => (
          <Flex key={key}>
            <Box flex={1}>
              <Text small color="muted" fontWeight="normal" mr="4px">
                {getKeyLabel(key)}
              </Text>
            </Box>
            <Text flex={1} small>
              {getValue($dictionary, $devices, interfaceKvs, model, key)}
            </Text>
          </Flex>
        ))}
    </>
  );
};

export default inject('$dictionary', '$devices')(InterfaceKvsMetadata);
