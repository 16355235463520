import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { MdGroupAdd } from 'react-icons/md';
import { Box, Button, Flex, Heading, Icon, Text } from 'core/components';
import { FormDialog } from 'core/form';
import { fields, options } from 'app/forms/config/inviteUsers';
import InviteUsersForm from './InviteUsersForm';

@inject('$auth', '$users')
@observer
class InviteUsers extends Component {
  handleSendInvites = (form) => {
    const { $users, onDialogClose } = this.props;
    const formValues = form.getValues();

    $users.inviteDemoUsers(formValues);

    onDialogClose();
    form.reset();
    form.getField('invitees').setValue([]);
  };

  renderForm = ({ form, model }) => (
    <>
      <InviteUsersForm />

      <Heading level="6" mt={3}>
        What happens next?
      </Heading>

      <Flex>
        <Box flex={1} mr={2}>
          <Text muted small>
            All invited users will have access to your company’s main environment which is shared among all users at
            your company. They will be able to test drive the pre-configured network setup provided by Kentik for trial
            purposes, which is shared among all users of Kentik.
          </Text>
        </Box>

        <Button
          text="Send Invites"
          intent="primary"
          width={110}
          height={30}
          disabled={!form.valid || !form.dirty}
          onClick={() => this.handleSendInvites(form, model)}
        />
      </Flex>
    </>
  );

  render() {
    const { $auth, isOpen, onDialogClose } = this.props;

    if (!$auth.isAbleToInviteFromDemo) {
      return null;
    }

    return (
      <FormDialog
        title={
          <Flex>
            <Icon icon={MdGroupAdd} iconSize={22} />
            Invite Others to Demo Kentik
          </Flex>
        }
        isOpen={isOpen}
        fields={fields}
        options={options}
        onClose={onDialogClose}
        width={600}
        showFormActions={false}
      >
        {this.renderForm}
      </FormDialog>
    );
  }
}

export default InviteUsers;
