import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';

import BollingerBandChart from 'app/views/synthetics/components/BollingerBandChart';
import { checkSeriesDataForFailure } from 'app/views/synthetics/utils/syntheticsUtils';

import withTrendChart from './withTrendChart';

@withTrendChart
@withTheme
@inject('$app', '$dataviews')
@observer
class JitterTimeSeries extends BollingerBandChart {
  get valueSuffix() {
    return 'ms';
  }

  renderData() {
    if (this.chart) {
      const { data, xAxisMin, xAxisMax, delta } = this.props;

      const averages = [];
      const stddevs = [];
      const zones = [];
      let currHealth;

      const offset = delta || 60000;

      data.forEach((point, i) => {
        const { time, jitter_health, avg_jitter, rolling_avg_jitter, rolling_std_jitter } = point;
        if (checkSeriesDataForFailure(point)) {
          averages.push({ time, x: time, y: null });
        } else {
          averages.push({
            time,
            x: time,
            y: avg_jitter / 1000,
            marker: {
              enabled: checkSeriesDataForFailure(data[i - 1]) || checkSeriesDataForFailure(data[i + 1])
            }
          });
          stddevs.push([
            time,
            Math.max(0, (rolling_avg_jitter - rolling_std_jitter) / 1000),
            (rolling_avg_jitter + rolling_std_jitter) / 1000
          ]);
        }

        if (currHealth !== jitter_health) {
          zones.push({
            value: time - offset,
            color: this.getColor(currHealth),
            fillColor: this.getFillColor(currHealth)
          });
          currHealth = jitter_health;
        }
      });

      zones.push({ color: this.getColor(currHealth), fillColor: this.getFillColor(currHealth) });

      const marker = {
        states: {
          select: {
            fillColor: this.getColor(currHealth),
            lineColor: 'rgba(255, 255, 255, 0.5)',
            lineWidth: 5,
            radius: 8
          }
        }
      };

      this.setState({
        chartOptions: {
          series: [
            { name: 'Avg Jitter', data: averages, zones, marker },
            { data: stddevs, zones, marker }
          ],
          xAxis: {
            min: xAxisMin,
            max: xAxisMax
          },
          yAxis: {
            offset: 20
          }
        }
      });
    }
  }
}

export default JitterTimeSeries;
