import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import QueryModel from 'app/stores/query/QueryModel';
import { getQueriesForHash } from 'app/stores/query/urlHash';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';

import { Box, Flex, Heading, Spinner, Suspense, Link, Text } from 'core/components';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';

@withTheme
@inject('$setup')
@observer
class RecentQueriesWidget extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    const { $setup } = this.props;

    Promise.all(
      $setup
        .getSettings('recentQueries', [])
        .map((hash) => getQueriesForHash(hash).then((q) => ({ model: q[0].query, hash })))
    ).then((queryList) => this.setState({ queryList }));
    this.setState({ loading: false });
  }

  renderLastQueryChart() {
    const { queryList } = this.state;

    if (!queryList || queryList.length === 0) {
      return (
        <Flex flexDirection="column" alignItems="center">
          <Text>No recent queries...</Text>
          <Link to="/v4/core/explorer">Try some queries now!</Link>
        </Flex>
      );
    }

    const { hash, model } = queryList[0];
    return (
      <Box>
        <Heading level={6} fontWeight="heavy">
          Last Query
        </Heading>
        <Link to={`/v4/core/explorer/${hash}`} pb={1}>
          {QueryModel.create(model).autoTitle}
        </Link>

        {model && <DataViewWrapper query={model} viewProps={{ showTooltips: false, height: 200 }} allowCache />}
      </Box>
    );
  }

  renderLastQueryList() {
    const { queryList } = this.state;

    if (!queryList || queryList.length <= 1) {
      return null;
    }

    return (
      <Flex flexDirection="column" flex={1}>
        <Heading level={6} fontWeight="heavy">
          Previous Queries
        </Heading>
        {queryList.slice(1).map(({ model, hash }) => {
          const queryModel = QueryModel.create(model);
          return (
            <Flex key={hash} justifyContent="space-between" alignItems="center" py="4px" mb="4px" borderBottom="thin">
              <Link to={`/v4/core/explorer/${hash}`}>{queryModel.autoTitle}</Link>
              <Text muted small>
                {queryModel.queryDuration}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    );
  }

  render() {
    const { onRemove, canCustomize } = this.props;
    const { loading } = this.state;
    const title = 'Recent Data Explorer Queries';

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        onRemove={onRemove}
        title={title}
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Suspense
          loading={loading}
          fallback={
            <Box pt={2}>
              <Spinner size={24} />
            </Box>
          }
        >
          {this.renderLastQueryChart()}
          {this.renderLastQueryList()}
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default RecentQueriesWidget;
