/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Button } from '@blueprintjs/core';
import { Box, Card, Flex, FlexColumn, LinkButton, Tab, Tabs, Text, ThemedImage, Typewriter } from 'core/components';
import styled from 'styled-components';
import { isEmpty, orderBy } from 'lodash';
import Legend from 'app/components/dataviews/views/legend/Legend';
import { getHashForObject } from 'app/stores/query/urlHash';
import MetricsExplorerResult from 'app/views/metrics/MetricsExplorerResult';
import MetricsExplorerLink from 'app/views/metrics/MetricsExplorerLink';
import { inject, observer } from 'mobx-react';
import ConnectivityReport from 'app/views/cloudConnectivity/ConnectivityReport';
import { formatDateTime } from 'core/util/dateUtils';
import kentikAiUser from 'app/assets/kentik-ai-user.svg';
import kentikAiUserDark from 'app/assets/kentik-ai-user-dark.svg';
import Gravatar from 'react-gravatar';
import DataViewWrapper from '../../../components/dataviews/DataViewWrapper';
import SynResponse from './SynResponse';
import PromptFeedback from './PromptFeedback';
import Markdown from './Markdown';
import JourneyAlertResponse from './JourneyAlertResponse';
import PingResponse from './PingResponse';

const PromptHeader = styled(Flex)`
  align-items: center;
  border-radius: 8px;
  padding: 0;

  .bp4-button {
    opacity: 0.35;
  }

  :hover {
    .bp4-button {
      opacity: 1;
    }
  }
`;

export const ShimmerThinkingIndicator = styled.div`
  font-size: 15px;
  background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.colors.body} 30%, #888 45%, #fafafa 50%, #888 55%, ${theme.colors.body} 70%)`};

  background-size: 200% 100%;
  animation: gradientAnimation 2s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @keyframes gradientAnimation {
    0% {
      background-position: 200% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const User = ({ isSystemUser, userEmail }) =>
  isSystemUser ? (
    <ThemedImage src={kentikAiUser} darkSrc={kentikAiUserDark} width={40} height={40} />
  ) : (
    <Gravatar email={userEmail} size={40} default="initials" style={{ borderRadius: '8px' }} />
  );

const PromptWrapper = styled(Box)`
  scroll-snap-align: start;
  /* min-height: ${(props) => (props.isPlaceholder || props.isOpen ? '630px' : 'auto')}; */
`;

const KbAnswer = styled.div`
  line-height: 24px;
  pre {
    background: #4e4a67;
    padding: 1em;
    margin: 8px 0;
    border-radius: 6px;

    code {
      color: #ffffff;
      padding: 4px;
      border-radius: 4px;
    }
  }

  code {
    font-weight: 500;
    padding: 4px;
    border-radius: 4px;
  }

  th {
    background: ${(props) => props.theme.backgrounds.tableHeader};
  }
`;

function getRandomItem(array) {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

const BAD_PROMPT_RESPONSES = [
  "Oops! Looks like we're having trouble coming up with a response for that prompt!",
  "Uh-oh! It seems we're having a bit of difficulty generating a response for your prompt.",
  'Having a little trouble generating a response for that one!',
  "Oh no! It appears we're encountering a hiccup in generating a response for your prompt.",
  "Whoops! We're facing a challenge in creating a response for that particular prompt."
];

@inject('$metrics', '$explorer')
@observer
class PromptResponse extends React.Component {
  promptRef = React.createRef();

  state = {
    updating: false
  };

  componentDidMount() {
    this.scrollIntoView();
  }

  componentDidUpdate(prevProps) {
    // when kentik ai is done thinking
    const { loading, isScrolledToBottom } = this.props;
    const { loading: prevLoading } = prevProps;

    if (loading !== prevLoading && isScrolledToBottom) {
      this.scrollIntoView();
    }
  }

  handleViewInDataExplorer = (query) => {
    const { $explorer } = this.props;
    $explorer.navigateToExplorer(query, true);
  };

  handleViewInCloudConnectivityChecker = (query) => {
    getHashForObject(query).then((hash) => {
      const path = `/v4/cloud/pathfinder/aws/${hash}`;
      window.open(path, '_blank');
    });
  };

  scrollIntoView = (force) => {
    const { isOpen, isScrolledToBottom } = this.props;

    if (this.promptRef.current && (force || (isOpen && isScrolledToBottom))) {
      setTimeout(() => {
        const targetElement = this.promptRef.current;

        if (!targetElement) {
          return;
        }

        const journeysScrollContainer = targetElement.parentElement.parentElement;

        journeysScrollContainer.scrollTo({
          top: this.promptRef.current.offsetTop - 20,
          behavior: 'smooth'
        });
      }, 200);
    }
  };

  onQueryError = (errors) => {
    const { updating } = this.state;
    const { query } = this.props;

    // Prevent a state/render infinite loop, and only needs to write to the DB on first render.
    if (!updating && query.get('is_query_valid')) {
      this.setState({ updating: true }, () => {
        query.save(
          { is_query_valid: false, admin_comment: JSON.stringify(errors) },
          { sendProvidedValuesOnly: true, toast: false }
        );
      });
    }
  };

  getResults = () => {
    const { model, query } = this.props;
    const models = model.get('models');
    if (models.length > 1) {
      return (
        <FlexColumn flex={1} position="relative" overflow="hidden" width="100%">
          <Tabs fill>
            {orderBy(models, (m) => m.get('model_id')).map((m) => (
              <Tab key={m.id} id={m.id} title={m.get('model_id')} panel={this.getResult(m)} />
            ))}
          </Tabs>
        </FlexColumn>
      );
    }
    return this.getResult(query);
  };

  // If a user selects FPA analysis options from the chart:
  // - Insert a new prompt with the FPA analysis options selected
  handleRunFpaFromChartSelection = (queryObject) => {
    const { journey, query } = this.props;
    const promptText = query.get('prompt');

    const {
      starting_time,
      ending_time,
      fpa: { time }
    } = queryObject;

    const prompt = `${promptText} from ${starting_time} to ${ending_time}. Run probable cause analysis from ${formatDateTime(
      time.starting_time
    )} to ${formatDateTime(time.ending_time)}`;

    journey.addPrompt(prompt);
  };

  onDataViewCreate = (dataview) => {
    console.warn('onDataViewCreate', dataview);
  };

  onNmsComplete = ({ results, query }) => {
    const { query: model } = this.props;
    model.setNmsCsv(results, query);
  };

  checkForReplay(prompt) {
    const query = prompt.get('query');
    if (query.answer?.includes('replay_last')) {
      return true;
    }
    return false;
  }

  getResult = (query) => {
    const { onDelete, journey, loading } = this.props;

    const replayQuery = this.checkForReplay(query);

    // const isRemoving = query.isRequestActive('destroying');
    const isMetricsQuery = query.get('app_protocol') === 16;
    const isFlowQuery = query.get('app_protocol') === 0;
    const isAskKb = query.get('app_protocol') === 99;
    const isAlerting = query.get('app_protocol') === 98;
    const isCloudConnectivityChecker = query.get('app_protocol') === 97;
    const isSynthetics = query.get('app_protocol') === 10;
    const isLiveSynth = query.get('app_protocol') === 82 || !!replayQuery;
    const isInvestigation = query.get('app_protocol') === 96;

    // TODO: Please move this to the backend!
    if (replayQuery) {
      const replayAppProtocol = parseInt(JSON.parse(query.get('query').answer).replay_last);
      query = query.collection.models.findLast((model) => model.get('app_protocol') === replayAppProtocol) || query;
    }
    const queryToRun = query.get('query');
    const description = query.get('query._description');

    const { use_fpa } = queryToRun;

    // With FPA results, we want to give more space to the legend and overall prompt height.
    const chartHeight = 250;
    const promptHeight = 500;
    const legendFlex = use_fpa ? 2 : 1;

    if (loading) {
      return null;
    }

    if (isEmpty(query.get('query'))) {
      return (
        <FlexColumn flex={1} position="relative" overflow="hidden" p={2}>
          <Text as="div" muted>
            <Typewriter text={getRandomItem(BAD_PROMPT_RESPONSES)} />
          </Text>
        </FlexColumn>
      );
    }

    if (isFlowQuery) {
      return (
        <FlexColumn flex={1} position="relative" overflow="hidden">
          <Flex flexDirection="column" mb={2} height={query.get('query.viz_type') === 'table' ? promptHeight : null}>
            <DataViewWrapper
              query={query.get('query')}
              isExport
              // allowCache
              onDataViewCreate={this.onDataViewCreate}
              headerProps={{
                showTitle: false,
                showTitleLink: false,
                showViewTypeTag: true,
                shouldArrangeVertically: false,
                showLastUpdated: false,
                showDateRange: true,
                showAppliedFilters: true,
                showDataSources: true,
                isDashboardView: true // makes the title size smaller
              }}
              onSelectFpaAnalysisOption={this.handleRunFpaFromChartSelection}
              viewProps={{
                showNativeLegend: false,
                height: chartHeight
              }}
            >
              {({ component, header, className, dataview, dataViewConfig, loading: isDataViewLoading }) => {
                if (!isDataViewLoading && dataview?.queryBuckets?.activeBucketCount > 0) {
                  query.setDataviewCsv(dataview);
                }
                if (dataview?.errors.length > 0) {
                  this.onQueryError(dataview.errors);
                }
                const { showLegend } = dataViewConfig;
                return (
                  <>
                    <Flex className={className} flexDirection="column" flex={1} overflow="hidden">
                      <Flex flexDirection="column" p={1} px="12px">
                        {header}
                      </Flex>
                      <Flex flex={1} overflow="auto">
                        {component}
                      </Flex>
                    </Flex>
                    {showLegend && (
                      <Box display="flex" flex={legendFlex} m="1px" style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                        <Legend dataview={dataview} isVirtualized={false} includeTotalRow={false} />
                      </Box>
                    )}
                  </>
                );
              }}
            </DataViewWrapper>
          </Flex>

          {description && (
            <Text as="div" mb={2}>
              <Markdown>{description}</Markdown>
            </Text>
          )}

          <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between" p="12px" pt={0}>
            <PromptFeedback journey={journey} model={query} onDelete={onDelete} />

            <Button
              text="View in Data Explorer"
              style={{ minWidth: 185 }}
              onClick={() => this.handleViewInDataExplorer(query.get('query'))}
            />
          </Flex>
        </FlexColumn>
      );
    }

    if (isMetricsQuery) {
      return (
        <FlexColumn flex={1} position="relative" overflow="hidden" height={promptHeight}>
          <MetricsExplorerResult
            query={queryToRun}
            chartHeight={chartHeight}
            showLegend
            showTools
            isWidget
            // isExport // this was added investigation PR but has some side effects we don't want, i.e. hiding selected filters.
            onQueryComplete={this.onNmsComplete}
          />

          {description && (
            <Text as="div" mb={2}>
              <Markdown>{description}</Markdown>
            </Text>
          )}

          <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between" p="12px" pt={0}>
            <PromptFeedback journey={journey} model={query} onDelete={onDelete} />

            <MetricsExplorerLink as={LinkButton} query={queryToRun} style={{ minWidth: 195 }} blank>
              View in Metrics Explorer
            </MetricsExplorerLink>
          </Flex>
        </FlexColumn>
      );
    }

    if (isCloudConnectivityChecker) {
      return (
        <FlexColumn flex={1} position="relative" overflow="hidden">
          <Flex flexDirection="column" mb={2} overflow="auto" minHeight={promptHeight} p={2}>
            {queryToRun?.error_message ? (
              <Text as="div">{queryToRun.error_message}</Text>
            ) : (
              <ConnectivityReport reportObject={queryToRun} />
            )}
          </Flex>
          <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between" p="12px" pt={0}>
            <PromptFeedback journey={journey} model={query} onDelete={onDelete} />

            <Button
              text="View Report"
              style={{ minWidth: 185 }}
              onClick={() => this.handleViewInCloudConnectivityChecker(query.get('query'))}
            />
          </Flex>
        </FlexColumn>
      );
    }

    if (isLiveSynth) {
      return (
        <FlexColumn flex={1} position="relative" overflow="hidden">
          <Flex flexDirection="column" mb={2}>
            <PingResponse model={query} results={queryToRun} />
          </Flex>

          {description && (
            <Text as="div" mb={2}>
              <Markdown>{description}</Markdown>
            </Text>
          )}

          <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between" p="12px" pt={0}>
            <PromptFeedback journey={journey} model={query} onDelete={onDelete} />
          </Flex>
        </FlexColumn>
      );
    }

    if (isInvestigation) {
      return (
        <FlexColumn flex={1} position="relative" overflow="hidden">
          <Flex flexDirection="column" mb={2} p={2}>
            <Text as="div">
              <Markdown>{query.get('query.answer')}</Markdown>
            </Text>
          </Flex>
          {query.get('query.nms') && (
            <Flex flexDirection="column" height={promptHeight} minHeight={promptHeight} mb={2}>
              <MetricsExplorerResult
                query={query.get('query.nms')}
                showLegend
                showTools
                isWidget
                onQueryComplete={this.onNmsComplete}
              />
            </Flex>
          )}
          <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between" p="12px" pt={0}>
            <PromptFeedback journey={journey} model={query} onDelete={onDelete} />
          </Flex>
        </FlexColumn>
      );
    }

    if (isAskKb) {
      if (!query.get('query.answer')) {
        return null;
      }

      return (
        <FlexColumn flex={1} position="relative" overflow="hidden">
          <Flex flexDirection="column" p={2}>
            <KbAnswer>
              <Text as="div">
                <Markdown>{query.get('query.answer')}</Markdown>
              </Text>
            </KbAnswer>

            {query.get('query.sources')?.length > 0 ? (
              <Box>
                <Text as="h4">References</Text>
                {query.get('query.sources')?.map((source) => (
                  <Text as="div" key={source.url}>
                    <Text muted>[{Math.round(source.similarity * 100)}%] </Text>
                    <a href={source.url} target="_blank" rel="noreferrer">
                      {source.article ? `${source.article} - ${source.heading}` : source.url}
                    </a>
                  </Text>
                ))}
                <Text as="div" mt={3} muted>
                  AI can make mistakes. Validate important information using references or{' '}
                  <a href="https://www.kentik.com/customer-care/">contact Kentik support.</a>
                </Text>
              </Box>
            ) : null}
          </Flex>
          <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between" p="12px" pt={0}>
            <PromptFeedback journey={journey} model={query} onDelete={onDelete} />
          </Flex>
        </FlexColumn>
      );
    }

    if (isAlerting) {
      return <JourneyAlertResponse query={query} journey={journey} onDelete={onDelete} promptHeight={promptHeight} />;
    }

    if (isSynthetics) {
      return <SynResponse query={query} onDelete={onDelete} promptHeight={promptHeight} />;
    }

    return (
      <FlexColumn flex={1} position="relative" overflow="hidden">
        <Flex flexDirection="column" mb={2}>
          <Box>{query.get('completion.error') || query.get('completion.message')}</Box>
        </Flex>
        <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between">
          <PromptFeedback journey={journey} model={query} onDelete={onDelete} />
        </Flex>
      </FlexColumn>
    );
  };

  renderResponse() {
    const { query } = this.props;
    const isAskKb = query.get('app_protocol') === 99;
    const hasQuery = query.get('query') && !isEmpty(query.get('query'));
    const isEmptyAnswer = isAskKb && query.get('query.answer') === '';
    const isSystemUser = query.get('user.user_full_name') === 'Kentik AI';

    if ((!isSystemUser || isEmptyAnswer) && !hasQuery) {
      return null;
    }

    const responseComponent = this.getResults();

    return (
      <>
        {responseComponent && (
          <Card p={0} mt={3} maxHeight={560} overflow="auto">
            {responseComponent}
          </Card>
        )}
      </>
    );
  }

  render() {
    const { query, loading } = this.props;
    const isPlaceholder = query.get('placeholder');
    const isSystemUser = query.get('user.user_full_name') === 'Kentik AI';
    const userEmail = query.get('user.user_email');
    const promptText = query.get('prompt');

    return (
      <PromptWrapper key={query.id} ref={this.promptRef} isOpen isPlaceholder={isPlaceholder}>
        <PromptHeader p="12px" mb={0} gap={1}>
          <Flex alignItems="flex-start" gap={2}>
            <User isSystemUser={isSystemUser} userEmail={userEmail} />
            <Flex flexDirection="column" pt="1px" gap="2px" flex={1}>
              <Flex alignItems="center" gap={1}>
                <Text fontSize={13} fontWeight="bold">
                  {query.get('user.user_full_name')}
                </Text>
                <Text muted fontSize={13} fontWeight="normal">
                  {formatDateTime(query.get('cdate', ''), 'MMM DD, YYYY, hh:mm a')}
                </Text>
              </Flex>

              {loading && <ShimmerThinkingIndicator>{loading}</ShimmerThinkingIndicator>}
              {!loading && promptText && (
                <KbAnswer fontSize={15}>
                  <Markdown>{promptText}</Markdown>
                </KbAnswer>
              )}
            </Flex>
          </Flex>
        </PromptHeader>
        {!loading && this.renderResponse()}
      </PromptWrapper>
    );
  }
}

export default PromptResponse;
