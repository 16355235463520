import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Card, Flex, Icon, Text, Tag } from 'core/components';

@inject('$savedFilters')
@observer
class SavedFilterDisplay extends Component {
  render() {
    const { $savedFilters, filter_id, is_not } = this.props;
    const savedFilter = $savedFilters.getSavedFilterById(filter_id);
    const isExcluded = is_not;

    if (!savedFilter) {
      return (
        <Card p={1}>
          <Flex justifyContent="space-between">
            <Flex alignItems="center" flex={1}>
              <Box className="pt-intent-danger-text">Invalid saved filter ({filter_id})</Box>
            </Flex>
          </Flex>
        </Card>
      );
    }

    return (
      <Flex alignItems="center" fontSize="small" mt="4px" pl="4px">
        <Box position="relative" top={-3}>
          <Icon icon="nest" color="muted" iconSize={11} />
        </Box>
        <Flex alignItems="center" className="saved-filter-readonly">
          <Icon icon="star-empty" color="warning" iconSize={12} />
          <Box ml="2px">
            <Text color="muted">{isExcluded ? 'Exclude' : 'Include'}</Text>{' '}
            <Tag outline round fontWeight="normal" fontSize={11}>
              {savedFilter.get('filter_name')}
            </Tag>
          </Box>
        </Flex>
      </Flex>
    );
  }
}

export default SavedFilterDisplay;
