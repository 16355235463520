import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MetricsExplorerResult from 'app/views/metrics/MetricsExplorerResult';

@observer
class MetricsExplorerPanel extends Component {
  render() {
    const { dataview, height } = this.props;

    if (!dataview.loading) {
      return <MetricsExplorerResult query={dataview.query} isWidget height={height} containerHeight={height} />;
    }

    return null;
  }
}

export default MetricsExplorerPanel;
