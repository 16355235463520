import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { PopoverPosition } from '@blueprintjs/core';

import { Flex, Button, Menu, MenuItem, Popover, Text } from 'core/components';
import { getTimeRangeLookbackOptions } from 'app/views/synthetics/utils/syntheticsUtils';

@inject('$app', '$exports')
class TimeRangeSelector extends Component {
  static defaultProps = {
    inline: false,
    options: getTimeRangeLookbackOptions(),
    popoverPosition: PopoverPosition.BOTTOM_RIGHT
  };

  state = {
    isOpen: false
  };

  handleClick = (value) => {
    const { $exports, onChange, hashEnabled } = this.props;

    onChange(value);

    if (hashEnabled) {
      $exports.setHash({
        hashedLookbackSeconds: value
      });
    }
  };

  handleInteraction = (nextOpenState) => {
    this.setState({ isOpen: nextOpenState });
  };

  render() {
    const { $app, inline, lookback_seconds, options, popoverPosition } = this.props;
    const { isOpen } = this.state;

    const selectedOption = options.find((option) => option.value === lookback_seconds);
    const buttonText = selectedOption ? selectedOption.label : `Last ${lookback_seconds}s`;

    const width = 132;
    if ($app.isExport) {
      return (
        <Flex pl="12px" alignItems="center">
          <Text as="div" fontSize="small" color="muted" pb="1px">
            {buttonText}
          </Text>
        </Flex>
      );
    }

    return (
      <Popover
        isOpen={isOpen}
        onInteraction={this.handleInteraction}
        position={popoverPosition}
        content={
          <Menu minWidth={width}>
            {options.map((option) => (
              <MenuItem
                key={option.value}
                active={lookback_seconds === option.value}
                text={option.label}
                onClick={() => {
                  this.handleClick(option.value);
                }}
                minWidth={width - 8}
              />
            ))}
          </Menu>
        }
      >
        {inline && (
          <Button textAlign="left" rightIcon="caret-down" active={isOpen}>
            <Text color="muted">Time Range: </Text>
            <Text fontWeight="bold">{buttonText}</Text>
          </Button>
        )}
        {!inline && (
          <Button textAlign="left" rightIcon="caret-down" active={isOpen} width={width} height={35}>
            <Text as="div" fontSize="small" color="muted" pb="1px">
              Time Range
            </Text>
            <Text as="div" fontWeight="bold" fontSize="small">
              {buttonText}
            </Text>
          </Button>
        )}
      </Popover>
    );
  }
}

export default TimeRangeSelector;
