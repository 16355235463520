import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex } from 'core/components';
import { hideTooltips } from 'app/views/synthetics/utils/syntheticsUtils';
import BgpReachabilityTimeline from '../ReachabilityTimeline';
import BgpPathChangeTimeline from '../path/ChangeTimeline';

@inject('$dataviews')
@observer
class BgpTestResultsTimelines extends Component {
  handleMouseOut = () => {
    const { $dataviews } = this.props;

    hideTooltips($dataviews);
  };

  render() {
    const { timeline, ...rest } = this.props;
    const showAll = timeline === 'all';

    return (
      <Flex flexDirection="column" onMouseOut={this.handleMouseOut}>
        {(showAll || timeline === 'showReachability') && <BgpReachabilityTimeline {...rest} />}
        {(showAll || timeline === 'showPathChange') && <BgpPathChangeTimeline {...rest} />}
      </Flex>
    );
  }
}

export default BgpTestResultsTimelines;
