import styled from 'styled-components';
import { space, width, height, opacity } from 'styled-system';
import position from './utils/position';

const Image = styled.img`
  ${space};
  ${width};
  ${height};
  ${position};
  ${opacity}
`;

/* images should respect their aspect-ratios */
Image.defaultProps = {
  width: '100%',
  height: 'auto'
};

export default Image;
