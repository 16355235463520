import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { Box, Highcharts } from 'core/components';
import storeLoader from 'app/stores/storeLoader';

function generateConfig({ percentComplete, showLabel }, theme) {
  return {
    chart: {
      type: 'solidgauge',
      height: '100%',
      styledMode: false,
      style: { fontFamily: theme.fonts.body },
      spacingBottom: 10,
      backgroundColor: null
    },
    title: null,
    tooltip: { enabled: false },
    credits: {
      enabled: false
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          outerRadius: '60%',
          innerRadius: '100%',
          backgroundColor: theme.colors.lightGray1,
          borderWidth: 0
        }
      ]
    },

    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: []
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: -13,
          enabled: showLabel,
          borderWidth: 0,
          useHTML: true
        },
        linecap: 'square'
      }
    },

    series: [
      {
        name: 'Move',
        data: [
          {
            y: percentComplete,
            color: theme.colors.primary,
            dataLabels: {
              format: `<div style="text-align:center">
                <div style="font-size:12px; font-weight:600; color:${theme.colors.body}">{y}%</div>
              </div>`
            }
          }
        ]
      }
    ]
  };
}

@withTheme
@storeLoader('$setup.setupTasks')
@observer
class SetupGauge extends Component {
  render() {
    const { $setup, theme, width, showLabel } = this.props;
    const { percentComplete, size } = $setup.setupTasks;

    if (size === 0 || percentComplete === 100) {
      return null;
    }

    return (
      <Box width={width} my={-3}>
        <Highcharts styledMode={false} options={generateConfig({ percentComplete, showLabel }, theme)} />
      </Box>
    );
  }
}

export default SetupGauge;
