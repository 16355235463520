import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';

const CdnLink = ({ $app, cdn, children, ...rest }) => {
  if ($app.isSubtenant) {
    return children || cdn;
  }

  return (
    <Link to={`/v4/service-provider/cdn/${cdn}`} {...rest}>
      {children || cdn}
    </Link>
  );
};

export default inject('$app')(CdnLink);
