import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Flex } from 'core/components';
import { VirtualizedTable } from 'core/components/table';
import RawFlowDashboardOptions from './RawFlowDashboardOptions';

@inject('$rawFlow', '$dashboard')
@observer
class ConfigureRawFlowForm extends Component {
  handleFormSubmit = () => {};

  componentDidMount() {
    const { $dashboard, $rawFlow } = this.props;
    const { dashboard } = $dashboard;
    const dashboardItem = dashboard.selectedPanel;
    const selectedQuery = dashboardItem.get('rawFlowQuery');
    $rawFlow.setDashboardQueryModel(selectedQuery);
  }

  componentWillUnmount() {
    const { $rawFlow } = this.props;
    const { resultsCollection, queryModel } = $rawFlow;
    if (resultsCollection) {
      resultsCollection.reset();
    }
    if (queryModel) {
      $rawFlow.resetDashboardQueryModel();
    }
  }

  render() {
    const { $dashboard, $rawFlow } = this.props;
    const { dashboard } = $dashboard;
    const dashboardItem = dashboard.selectedPanel;
    const { queryModel, resultsCollection, isFetching, columns } = $rawFlow;

    const showTable = !isFetching && !resultsCollection.isEmpty();

    return (
      <Flex>
        <RawFlowDashboardOptions
          onSubmit={this.handleFormSubmit}
          model={queryModel}
          dashboardItem={dashboardItem}
          dashboard={dashboard}
        />
        {showTable && (
          <VirtualizedTable
            collection={resultsCollection}
            columns={columns}
            onSortClick={$rawFlow.sortResults}
            selectOnRowClick={false}
            flexed
          />
        )}
      </Flex>
    );
  }
}

export default ConfigureRawFlowForm;
