import { get, uniq } from 'lodash';
import { toJS } from 'mobx';
import CELL_TYPES from 'core/components/table/CELL_TYPES';
import $setup from 'app/stores/$setup';

export function getTabPref(tabPrefId, columns, defaultColumns) {
  const tabPrefs = get(toJS($setup.settings), `tabPrefs.${tabPrefId}`) || [];
  let visibleColumns;
  let derivedColumns = [];

  // If we have prefs, let's use them
  if (tabPrefs.length > 0) {
    const columnsMap = {};
    let preprend = null;
    let append = null;

    // Get a map of just the columns that users can toggle, save the actions off to read later
    columns.forEach((column, idx) => {
      if (column?.id) {
        columnsMap[column.id] = column;
      } else if (column.type === CELL_TYPES.ACTION) {
        if (idx === 0) {
          preprend = column;
        } else {
          append = column;
        }
      }
    });

    // Remove dupes and get an ordered list of the visible columns
    visibleColumns = uniq(tabPrefs.filter((tab) => tab.visible).map((tab) => tab.id));

    // We want to preserve the correct order of the columns (visible or not) based on the prefs
    // Let's make sure to exclude any tabs that aren't valid, like if a column was removed!
    tabPrefs.forEach((column) => {
      if (column?.id && columnsMap[column.id] && !derivedColumns.find((c) => c.id === column.id)) {
        derivedColumns.push(columnsMap[column.id]);
      }
    });

    // Note: at this point, the user could have preferences but be missing some columns if their prefs were saved
    // before new columns became available, so let's attempt to reconcile that here
    const totalColumns = columns.length - (preprend ? 1 : 0) - (append ? 1 : 0);
    for (let i = 0; i < columns.length && derivedColumns.length < totalColumns; i++) {
      const column = columns[i];
      if (column.id && !derivedColumns.find((c) => c.id === column.id)) {
        derivedColumns.push(column);
      }
    }

    if (preprend) {
      derivedColumns.unshift(preprend);
    }
    if (append) {
      derivedColumns.push(append);
    }
  } else {
    visibleColumns = defaultColumns;
    derivedColumns = columns;
  }

  return { columns: derivedColumns, visibleColumns };
}
