import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { defaultsDeep, isEqual, sum } from 'lodash';
import { Box, Highcharts } from 'core/components';
import MetricsExplorerVisualizationTools from 'app/views/metrics/components/MetricsExplorerVisualizationTools';
import { CardSkeleton, ProductSkeleton } from 'core/components/Skeletons';
import { formatMetricsValueForDisplay } from './utils/formatMetricValues';

const tooltipPointFormatter = ({
  y,
  series,
  metric,
  kmetricsQuery,
  measurementModel,
  prefixes,
  valueSuffix = ''
} = {}) => {
  const value = formatMetricsValueForDisplay(y, {
    metric,
    kmetricsQuery,
    measurementModel,
    prefixes,
    sampleAdjusted: true
  });
  return `<span class="highcharts-color-${series.colorIndex}">\u25CF</span> ${series.name}: <b>${value} ${valueSuffix}</b><br/>`;
};

const omitTooltipUnit = (unit) =>
  // These are formatted in a particular way for display that make the unit redundant
  unit === 'percent' || unit?.startsWith('timestamp') || unit?.startsWith('duration');
@inject('$auth', '$colors', '$metrics')
@observer
class ReconExplorerChart extends Component {
  constructor(props) {
    super(props);
    const vizType = props.query.kmetrics?.viz?.type;
    const vizRollup = props.query.kmetrics?.viz?.rollup;
    const vizMetric = props.query.kmetrics?.viz?.metric;
    const vizDefault = props.query.kmetrics?.includeTimeseries > 0 ? 'line' : 'column';
    const visualization = vizType || vizDefault;
    const rollups = vizRollup ? [vizRollup] : props.rollupNames.slice(0, 1);
    const selectedMetric = vizMetric || props.query.kmetrics.metrics[0].name;

    Object.assign(this.state, {
      chartType: visualization,
      rollups,
      selectedMetric
    });
  }

  el;

  chart;

  resizeWatcher;

  static defaultProps = {
    showAxisLabels: true
  };

  state = {
    chartOptions: false,
    chartType: 'line',
    rollups: [],
    selectedMetric: '',
    queryResults: []
  };

  componentDidUpdate() {
    const { query, showVisualizationSelector } = this.props;
    const { chartType, rollups, selectedMetric } = this.state;

    if (!showVisualizationSelector) {
      const metric = query.kmetrics?.viz?.metric;
      const vizType = query.kmetrics?.viz?.type;
      const rollupViz = query.kmetrics?.viz?.rollup;
      if (vizType && chartType !== vizType) {
        this.handleToggleChartType(vizType);
      }
      if (rollupViz && !isEqual(rollups, [rollupViz])) {
        this.handleChangeRollup(rollupViz);
      }
      if (metric && selectedMetric !== metric) {
        this.handleChangeMetric(metric);
      }
    }
  }

  get measurement() {
    const { query } = this.props;
    return query?.kmetrics?.measurement;
  }

  @computed
  get metricMeta() {
    const { selectedMetric } = this.state;
    return this.measurementModel?.storage?.Metrics?.[selectedMetric];
  }

  @computed
  get measurementModel() {
    const { $metrics } = this.props;
    return $metrics.measurementModelByName(this.measurement);
  }

  @computed
  get generateChartConfig() {
    const {
      $auth,
      showAxisLabels,
      showSharedTooltip,
      showLegend,
      visualization,
      highChartOptions,
      useUTC,
      metricPrefixes
    } = this.props;
    const { selectedMetric } = this.state;
    const { query } = this.props;
    const { measurementModel } = this;
    let tooltipSuffix = this.metricMeta.Unit || '';
    if (omitTooltipUnit(this.metricMeta.Unit)) {
      tooltipSuffix = '';
    }

    const getTooltipPointFormatter = (y, series) =>
      tooltipPointFormatter({
        y,
        series,
        metric: selectedMetric,
        kmetricsQuery: query?.kmetrics,
        measurementModel,
        prefixes: metricPrefixes,
        valueSuffix: tooltipSuffix
      });

    const baseOptions = {
      time: {
        useUTC: useUTC ?? $auth.userTimezone === 'UTC'
      },
      chart: {
        reflow: true,
        type: visualization,
        spacingTop: 16,
        spacingBottom: 16,
        spacingLeft: 16,
        spacingRight: 16
      },
      plotOptions: {
        series: {
          connectNulls: true,
          marker: {
            enabled: false
          },
          states: {
            inactive: {
              enabled: true
            }
          },
          tooltip: {
            pointFormatter() {
              return getTooltipPointFormatter(this.y, this.series);
            }
          }
        },
        pie: {
          allowPointSelect: true,
          size: '100%',
          innerSize: '60%',
          cursor: 'pointer',
          dataLabels: {
            enabled: true
          },
          showInLegend: true
        },
        area: {
          stacking: 'normal'
        }
      },
      yAxis: {
        title: {
          enabled: showAxisLabels,
          text: `${this.metricMeta.Label || selectedMetric} ${tooltipSuffix}`
        }
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%m/%e',
          minute: '%H:%M'
        }
      },
      legend: { enabled: showLegend },
      tooltip: {
        followPointer: false,
        shared: showSharedTooltip,
        crosshairs: true
      },
      series: []
    };

    return defaultsDeep({}, highChartOptions, baseOptions);
  }

  @computed
  get lineChartSeries() {
    const { groupedQueryResults } = this.props;
    const { selectedMetric } = this.state;
    return groupedQueryResults.flat().filter((timeseries) => timeseries.metric === selectedMetric);
  }

  @computed
  get pieSeries() {
    const { rollupRows } = this.props;
    const { rollups } = this.state;
    let centerX = 100;
    const size = 130;
    const isMulti = rollups.length > 1;
    return rollups.map((name) => {
      const rollupSum = sum(rollupRows.map((row) => parseFloat(row[name])));
      const config = {
        name,
        size,
        data: rollupRows.map((row) => ({
          name: row.seriesName,
          y: (row[name] / rollupSum) * 100
        }))
      };
      if (isMulti) {
        config.dataLabels = false;
        config.center = [centerX, 110];
        centerX += 175;
      }
      return config;
    });
  }

  @computed
  get columnChartSeries() {
    const { rollupRows } = this.props;
    const { rollups } = this.state;
    return rollupRows.map((row) => ({
      name: row.seriesName,
      data: rollups.map((name) => parseFloat(row[name])),
      dataLabels: [
        {
          enabled: false
        },
        {
          enabled: true,
          format: '{point.series.name}'
        }
      ]
    }));
  }

  @computed
  get columnChartOptions() {
    const { rollups } = this.state;
    return {
      chart: { type: 'column' },
      xAxis: {
        categories: rollups,
        crosshair: false,
        labels: {
          enabled: true
        }
      },
      plotOptions: {
        column: {
          stacking: undefined
        }
      }
    };
  }

  chartOptionsByType = (chartType) => {
    const chartMap = {
      area: {
        series: this.lineChartSeries,
        options: { chart: { type: 'area' } }
      },
      column: {
        series: this.columnChartSeries,
        options: this.columnChartOptions
      },
      line: {
        series: this.lineChartSeries,
        options: { chart: { type: 'line' } }
      },
      pie: {
        series: this.pieSeries,
        options: { chart: { type: 'pie' } }
      }
    };

    const { series, options } = chartMap[chartType];
    return defaultsDeep({}, this.generateChartConfig, { chart: { chartType }, series }, options);
  };

  handleToggleChartType = (chartType) => {
    this.setState({ chartOptions: this.chartOptionsByType(chartType), chartType });
  };

  handleChangeMetric = (metric) => {
    const { groupedQueryResults } = this.props;
    const { chartType } = this.state;
    this.setState({ selectedMetric: metric }, () => {
      const { selectedMetric } = this.state;
      const chartOptions = this.chartOptionsByType(chartType);
      chartOptions.series = groupedQueryResults.flat().filter((timeseries) => timeseries.metric === selectedMetric);
      this.setState({ chartOptions });
    });
  };

  handleChangeRollup = (rollup) => {
    const { rollupNames } = this.props;
    const { chartType } = this.state;
    const selectedRollup = rollup === 'all' ? rollupNames : [rollup];
    this.setState({ rollups: selectedRollup }, () => {
      this.setState({ chartOptions: this.chartOptionsByType(chartType) });
    });
  };

  componentWillUnmount() {
    this.disconnectResizer();
  }

  chartCallback = (chart) => {
    this.disconnectResizer();

    this.chart = chart.container ? chart : null;
    this.el = chart.container;

    this.resizeWatcher = new ResizeObserver(() => {
      try {
        if (this.chart && this.chart.hasRendered && this.el) {
          this.chart.reflow();
          this.setState({ chartHeight: this.el.offsetHeight }, () => {
            this.chart.setSize(this.el.offsetWidth, this.el.offsetHeight);
          });
        }
      } catch (e) {
        // OK to ignore
      }
    });

    this.resizeWatcher.observe(this.el);
  };

  disconnectResizer() {
    if (this.resizeWatcher) {
      if (this.el) {
        this.resizeWatcher.unobserve(this.el);
      } else {
        this.resizeWatcher.disconnect();
      }
    }
  }

  render() {
    const { $colors, height, showVisualizationSelector, query, useHorizontalLayout } = this.props;
    const { chartOptions, chartType, isLoading, selectedMetric, chartHeight } = this.state;
    const { kmetrics } = query;
    const { metrics } = kmetrics;

    return (
      <>
        {showVisualizationSelector && (
          <MetricsExplorerVisualizationTools
            metrics={metrics}
            measurementModel={this.measurementModel}
            onChangeMetric={this.handleChangeMetric}
            selectedMetric={selectedMetric}
            useHorizontalLayout={useHorizontalLayout}
          />
        )}

        {!isLoading && (
          <Highcharts
            key={chartType}
            allowChartUpdate
            options={chartOptions || this.chartOptionsByType(chartType)}
            colors={$colors.chartColors}
            callback={this.chartCallback}
            width="100%"
            height={chartHeight || '100%'}
            containerHeight="100%"
            flex={1}
          />
        )}

        {isLoading && (
          <Box height={height} width="100%" flex={1}>
            <CardSkeleton>
              <ProductSkeleton width={`${Math.floor(Math.random() * 100) + 1}%`} />
              <ProductSkeleton width={`${Math.floor(Math.random() * 100) + 1}%`} />
              <ProductSkeleton width={`${Math.floor(Math.random() * 100) + 1}%`} />
              <ProductSkeleton width={`${Math.floor(Math.random() * 100) + 1}%`} />
            </CardSkeleton>
          </Box>
        )}
      </>
    );
  }
}

export default ReconExplorerChart;
