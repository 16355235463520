import React, { Component } from 'react';

import ErrorMessage from 'core/components/ErrorMessage';
import $forms from 'core/form/util/$forms';
import submitErrorReport from 'core/util/submitErrorReport';
import getPortalVersion from 'core/util/getPortalVersion';

export function componentDidCatch({ componentName, error, errorInfo, component }) {
  const { platform, userAgent } = window.navigator;
  const { pathname } = window.location;
  const { clientWidth, clientHeight } = document.querySelector('body');

  console.error(`Error Boundary ${componentName}`, error, errorInfo);

  getPortalVersion().then(({ hasOutdatedBundle }) => {
    submitErrorReport({
      clientHeight,
      clientWidth,
      info: {
        history: window.$store.$app.routeHistory,
        productArea: window.$store.$app.productArea,
        hasOutdatedBundle,
        componentName,
        allForms: $forms.getAllUnsuppressedFormValues()
      },
      pathname,
      platform,
      userAgent,
      error: error ? error.toString() : 'Error reported as falsey',
      errorInfo
    }).then(() => component.setState({ errorReportSuccess: true }));

    component.setState({ hasOutdatedBundle });
  });
}

export default function (componentName) {
  return class AbstractErrorBoundary extends Component {
    state = {
      hasOutdatedBundle: false
    };

    // Set error state in getDerivedStateFromError. ComponentDidCatch is called AFTER the render method, which renders
    // fallback UI and that might lead to more issues while getDerivedStateFromError updates state BEFORE the render
    // phase so that the correct fallback UI is rendered and no more errors are caused to the rendered components
    static getDerivedStateFromError(error) {
      console.error('error', error);
      return { error };
    }

    componentDidCatch(error, errorInfo) {
      componentDidCatch({ componentName, error, errorInfo, component: this });
    }

    renderBody() {
      console.warn('Required renderBody() not implemented!');
    }

    render() {
      const { error, hasOutdatedBundle, errorReportSuccess } = this.state;
      const { fullScreen } = this.props;

      if (error) {
        return (
          <ErrorMessage
            fullScreen={fullScreen}
            showDescription={errorReportSuccess}
            hasOutdatedBundle={hasOutdatedBundle}
          />
        );
      }

      return this.renderBody();
    }
  };
}
