import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';

const SiteLink = ({ $app, siteId, children, ...rest }) => {
  if ($app.isSubtenant) {
    return children;
  }

  return (
    <Link to={`/v4/core/quick-views/sites/${siteId}`} {...rest}>
      {children}
    </Link>
  );
};

export default inject('$app')(SiteLink);
