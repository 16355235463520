import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Box, Popover, Button, Text } from 'core/components';
import { getFilterCount } from 'core/util/filters';
import Dimension from 'app/components/dimensions/Dimension';
import FiltersDisplay from 'app/components/filters/display/FiltersDisplay';

const filterSources = {
  dashboard: 'Panel Filters Ignored',
  additive: 'View Filters added to Panel Filters',
  locked: 'Locked to Panel Filters'
};

const parametricSetting = {
  override_specific: 'Overriding specific filters',
  override_all: 'Overriding all existing filters',
  add_group: 'Adding a new filter group with',
  ignore: 'Ignoring Guided Mode settings'
};

@inject('$dictionary')
@observer
export default class AppliedFiltersButton extends Component {
  render() {
    const { item, loading, buttonStyle } = this.props;
    let { dataview } = this.props;
    if (item && !dataview) {
      dataview = item.dataview;
    }

    // if there are no FilterGroups or Saved Filters, don't render the button
    if (
      !dataview.appliedFilters ||
      !dataview.appliedFilters.filterGroups ||
      dataview.appliedFilters.filterGroups.length === 0
    ) {
      return null;
    }

    const filterCount = getFilterCount(dataview.appliedFilters.filterGroups);

    return (
      <Popover
        modifiers={{
          arrow: { enabled: true },
          flip: { enabled: true },
          keepTogether: { enabled: true },
          preventOverflow: { enabled: true, boundariesElement: 'window' }
        }}
        minimal={false}
      >
        <Button
          icon="filter"
          text={`${filterCount} Filter${filterCount > 1 ? 's' : ''}`}
          disabled={loading}
          style={buttonStyle}
          color="muted"
          minimal
          small
        />
        <Box p={1} maxHeight={450} maxWidth={600} overflow="auto">
          {item && (
            <Box mb={dataview.appliedFilters ? 1 : 0}>
              <Box>
                <small>
                  <Text muted>Filter Source:</Text> {filterSources[item.get('filter_source')]}
                </small>
              </Box>
              {item.get('device_locked') && (
                <Box>
                  <small>
                    <strong>Device Locked</strong>
                  </small>
                </Box>
              )}
              {item.get('dashboard')?.get('parametric') &&
                item.get('parametric_mode') &&
                item.get('parametric_overrides') && (
                  <Box mt={1}>
                    <small>
                      <strong>Guided Mode Behavior: </strong>
                      <br />
                      {parametricSetting[item.get('parametric_mode')]}{' '}
                      {item.get('parametric_mode') !== 'ignore' && (
                        <Dimension dimension={item.get('parametric_overrides').filterField} />
                      )}
                    </small>
                  </Box>
                )}
            </Box>
          )}

          <Text fontWeight="bold" small>
            Applied Filters:
          </Text>
          <br />
          <FiltersDisplay filters={dataview.appliedFilters} />
        </Box>
      </Popover>
    );
  }
}
