import React from 'react';

import { Box, LinkButton, Text } from 'core/components';
import $sites from 'app/stores/site/$sites';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: ['i_device_site_country', 'i_ult_exit_site_country'],
  filterFields: ['i_device_site_country', 'i_ult_exit_site_country']
};

export function getInvalidSitesCount() {
  return $sites.collection.unfiltered.reduce((acc, model) => {
    const country = model.get('country');

    return !country || country.length !== 2 ? acc + 1 : acc;
  }, 0);
}

class SiteCountryDependency extends AbstractDependency {
  get key() {
    return 'siteCountry';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return getInvalidSitesCount() === 0;
  }

  get message() {
    return (
      <>
        <Box mb="4px">
          <Text small>
            Your organization currently has <strong>{getInvalidSitesCount()} sites with invalid countries</strong>{' '}
            defined. These will not will map correctly in the GeoHeatmap and may appear inconsistently in other
            visualizations.
          </Text>
        </Box>
        <LinkButton icon="cog" small text="Configure" to="/v4/settings/sites" />
      </>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || getInvalidSitesCount() === 0;
  }
}

export default SiteCountryDependency;
