import styled, { css } from 'styled-components';

const DeviceValuesContainer = styled.div`
  ${({ theme }) => {
    let bgColor = theme.colors.lightGray5;
    let hoverColor = theme.colors.lightGray3;

    if (theme.name === 'dark') {
      bgColor = theme.colors.darkGray2;
      hoverColor = theme.colors.darkGray1;
    }

    return css`
      &:not(.read-only) {
        background: ${bgColor};
        border-radius: 3px;
        box-shadow: ${theme.shadows.thin};
        max-width: 324px;
        padding: 4px;

        &:hover {
          background: ${hoverColor};
        }

        &:active {
          background: ${hoverColor};
        }
      }

      width: 100%;
    `;
  }}
`;

export default DeviceValuesContainer;
