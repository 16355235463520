import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Heading, Text } from 'core/components';
import TransactionScreenshots from 'app/views/synthetics/components/TransactionScreenshots';
import LatencyTimeSeries from '../LatencyTimeSeries';
import TransactionCompletionSeries from '../TransactionCompletionSeries';
import AggregateView from './AggregateView';

@observer
class ResultsView extends Component {
  render() {
    const {
      httpHealthTs,
      httpHealthAggregates,
      screenshotsTsResult,
      resultTimeMs,
      timelineBounds,
      test,
      trendResults,
      onHover
    } = this.props;

    return (
      <Flex flexDirection="column" gap={4}>
        <Box>
          <Heading level={5} fontWeight="heavy" mb={1}>
            Total Transaction Completion Time{' '}
            <Text fontWeight="normal" muted>
              (ms)
            </Text>
          </Heading>
          <AggregateView aggregate={httpHealthAggregates.avg_latency} />
          <LatencyTimeSeries
            trendResults={trendResults}
            dataKey="httpHealthTs"
            test={test}
            data={httpHealthTs}
            selectedTime={resultTimeMs}
            seriesName="Total Transaction Completion Time"
            onHover={onHover}
            {...timelineBounds}
          />
        </Box>

        <Box>
          <Heading level={5} fontWeight="heavy">
            Transaction Completion{' '}
          </Heading>
          <TransactionCompletionSeries
            trendResults={trendResults}
            dataKey="httpHealthTs"
            test={test}
            data={httpHealthTs}
            selectedTime={resultTimeMs}
            seriesName="Transaction Completion"
            onHover={onHover}
            {...timelineBounds}
          />
        </Box>

        <TransactionScreenshots data={screenshotsTsResult} />
      </Flex>
    );
  }
}

export default ResultsView;
