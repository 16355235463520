import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Position } from '@blueprintjs/core';
import { Box, Button, Flex, Icon, Popover, Spinner, Suspense, Tag, Text, Tooltip } from 'core/components';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import getErrorBoundary from 'core/util/getErrorBoundary';
import InsightsCollection from 'app/stores/insight/InsightsCollection';
import InsightList from 'app/components/insights/InsightList';

const ErrorBoundaryCmp = getErrorBoundary('InsightsSidebar');

@inject('$insights')
@observer
class InsightsMenu extends Component {
  static defaultProps = {
    query: 'fetchInsights',
    params: undefined,
    paramsArray: undefined,
    interval: 30000,
    limit: 100
  };

  state = {
    isMenuOpen: false,
    loading: true,
    hasLoaded: false,
    insights: [],
    showTip: false
  };

  fetchTimer;

  showTipIntent = 'warning';

  componentDidMount() {
    this.refreshInsights();
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
    this.insightsRequest.cancel();
  }

  onMenuInteraction = (nextState) => this.setState({ isMenuOpen: nextState, showTip: false });

  refreshInsights() {
    const { $insights, query, params, paramsArray, interval } = this.props;
    const collection = new InsightsCollection({ $insights, includeDisabled: true });

    let queryParams;
    if (!paramsArray && params) {
      queryParams = [params];
    } else {
      queryParams = paramsArray || [];
    }

    this.insightsRequest = makeCancelable($insights[query](...queryParams));
    clearTimeout(this.fetchTimer);

    this.insightsRequest.promise
      .then((response) => {
        collection.processData(response);
        this.setState({ loading: false, hasLoaded: true, insights: collection });
        this.fetchTimer = setTimeout(() => this.refreshInsights(), interval);
        this.notify(collection);
      })
      .catch((err) => {
        if (!(err instanceof CanceledError)) {
          this.fetchTimer = setTimeout(() => this.refreshInsights(), interval);
          this.setState({ loading: false, insights: collection });
          console.error('Error handling insights request', err);
        }
      });
  }

  // eslint-disable-next-line react/destructuring-assignment
  notify(insightsCollection = this.state.insights) {
    const { notifyOnInsightName } = this.props;

    if (notifyOnInsightName) {
      let showTip = false;
      const insights = insightsCollection.get();
      let previousNotifies = localStorage.getItem('insightNotifications');
      previousNotifies = previousNotifies ? JSON.parse(previousNotifies) : {};

      notifyOnInsightName.forEach((notifyRequest) => {
        const notifyInsight = insights.find((insight) => {
          const name = insight.get('insightName');
          return notifyRequest === name || notifyRequest.name === name;
        });

        if (notifyInsight) {
          const today = new Date();
          const insightID = notifyInsight.get('insightID');
          const previousNotify = previousNotifies[insightID];
          let insightShouldTriggerNotify = false;

          if (!previousNotify) {
            insightShouldTriggerNotify = true;
          }

          if (previousNotify) {
            const creationTime = new Date(notifyInsight.get('creationTime'));
            const notifyTime = new Date(previousNotify);
            const reNotifyTime = new Date(previousNotify);

            reNotifyTime.setDate(notifyTime.getDate() + (notifyRequest.frequency === 'weekly' ? 7 : 1));
            if (creationTime > notifyTime && today > reNotifyTime) {
              insightShouldTriggerNotify = true;
            }
          }

          if (insightShouldTriggerNotify) {
            previousNotifies[insightID] = today.toISOString();
            showTip = true;
          }
        }
      });

      if (showTip) {
        localStorage.setItem('insightNotifications', JSON.stringify(previousNotifies));
      }

      this.setState({ showTip });
    }
  }

  renderInsightTag() {
    const { limit } = this.props;
    const { hasLoaded, insights, showTip } = this.state;

    if (!hasLoaded) {
      return <Spinner size={16} />;
    }

    return (
      <Tag round small intent={showTip ? this.showTipIntent : 'none'}>
        {insights.size || 0}
        {insights.size === limit && '+'}
      </Tag>
    );
  }

  render() {
    const { loading, hasLoaded, insights, isMenuOpen, showTip } = this.state;

    return (
      <Popover
        position="bottom-right"
        isOpen={isMenuOpen}
        onInteraction={this.onMenuInteraction}
        content={
          <Box minWidth={400} maxWidth={400} maxHeight="calc(100vh - 125px)" overflow="auto" p="12px">
            <Suspense loading={loading && !hasLoaded} fallback={<Spinner size={24} />}>
              <ErrorBoundaryCmp>
                <InsightList insights={insights} limit={25} />
              </ErrorBoundaryCmp>
            </Suspense>
          </Box>
        }
      >
        <Tooltip
          content={<Text>There are important capacity planning related insights for your review.</Text>}
          isOpen
          intent={this.showTipIntent}
          disabled={!showTip}
          position={Position.BOTTOM_RIGHT}
        >
          <Button
            text={
              <Flex alignItems="center">
                <Box mr="4px">Insights</Box>
                {this.renderInsightTag()}
              </Flex>
            }
            rightIcon={<Icon icon="caret-down" iconSize={16} mx="-2px" />}
            minimal
            active={isMenuOpen}
          />
        </Tooltip>
      </Popover>
    );
  }
}

export default InsightsMenu;
