import { Collection } from 'core/model';
import { computed, observable } from 'mobx';
import PeeringFacilityModel from './PeeringFacilityModel';

export default class PeeringFacilityCollection extends Collection {
  @observable.shallow
  peeredAsns = new Set();

  get defaultSortState() {
    return { field: 'common', direction: 'asc' };
  }

  get secondarySort() {
    return { field: 'name', direction: 'asc' };
  }

  get model() {
    return PeeringFacilityModel;
  }

  @computed
  get common() {
    return this.unfiltered.filter((model) => model.get('common'));
  }
}
