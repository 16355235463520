/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { withTheme } from 'styled-components';
import { scaleLinear } from 'd3-scale';
import { clampNumber } from 'app/util/graphing';

@inject('$topo')
@observer
class Link extends Component {
  static propTypes = {
    linkId: PropTypes.string.isRequired,
    groupLink: PropTypes.bool,
    highlightKey: PropTypes.string,
    highlight: PropTypes.bool,
    markerId: PropTypes.string,
    onShowDetails: PropTypes.func,
    onHighlight: PropTypes.func
  };

  static defaultProps = {
    groupLink: false,
    highlightKey: undefined,
    highlight: false,
    markerId: undefined,
    onShowDetails: () => {},
    onHighlight: () => {}
  };

  linkEl;

  smallLineSize = 120;

  @computed
  get link() {
    const { $topo, linkId } = this.props;
    const { getLinkOrGroupLink } = $topo;
    return getLinkOrGroupLink(linkId);
  }

  @computed
  get lineThickness() {
    const { $topo, linkId } = this.props;
    const { getLinkSpeed } = $topo;
    const minThickness = 1.5;
    const maxThickness = 5;

    const lineThickness = scaleLinear().domain([100, 10000]).range([minThickness, maxThickness]).clamp(true);

    return lineThickness(getLinkSpeed(linkId).value || minThickness);
  }

  @computed
  get flowIn() {
    const { $topo } = this.props;
    const { getFlowData } = $topo;
    return getFlowData(this.link, 'in');
  }

  @computed
  get flowOut() {
    const { $topo } = this.props;
    const { getFlowData } = $topo;
    return getFlowData(this.link, 'out');
  }

  @computed
  get hasFlowIn() {
    return this.flowIn.length > 0;
  }

  @computed
  get hasFlowOut() {
    return this.flowOut.length > 0;
  }

  @computed
  get width() {
    const { $topo, linkId } = this.props;
    const { getLinkPoint } = $topo;
    const point = getLinkPoint(linkId);
    return point.x1 - point.x2;
  }

  @computed
  get height() {
    const { $topo, linkId } = this.props;
    const { getLinkPoint } = $topo;
    const point = getLinkPoint(linkId);
    return point.y1 - point.y2;
  }

  @computed
  get color() {
    const { theme, highlight } = this.props;

    if (highlight) {
      return theme.name === 'dark' ? theme.colors.lightGray5 : theme.colors.darkGray5;
    }

    return theme.colors.gray4;
  }

  @computed
  get flowScale() {
    const inAmount = this.getFlowAmount(this.flowIn);
    const outAmount = this.getFlowAmount(this.flowOut);
    const total = inAmount + outAmount;

    return {
      in: total ? inAmount / total : 1,
      out: total ? outAmount / total : 1
    };
  }

  @computed
  get lineScale() {
    const xScale = this.width / (Math.abs(this.width) + Math.abs(this.height)) || 0;
    const yScale = this.height / (Math.abs(this.width) + Math.abs(this.height)) || 0;

    return [xScale, yScale];
  }

  @computed
  get xyPoints() {
    const { $topo, linkId } = this.props;
    const { getLinkPoint } = $topo;
    const { x1, x2, y1, y2 } = getLinkPoint(linkId);
    let offset = 35;

    // decrease the offset for small lines
    if (Math.abs(this.width) + Math.abs(this.height) < this.smallLineSize) {
      offset = 5;
    }

    return {
      x1: x1 - (offset + 10) * this.lineScale[0],
      y1: y1 - (offset + 10) * this.lineScale[1],
      x2: x2 + offset * this.lineScale[0],
      y2: y2 + offset * this.lineScale[1]
    };
  }

  @computed
  get isVisible() {
    const { $topo, groupLink } = this.props;
    const { groups, getGroupFromNode, getNodesFromGroup } = $topo;
    const sourceGroup = this.link.isGroup ? groups[this.link.source] : getGroupFromNode(this.link.source);
    const targetGroup = this.link.isGroup ? groups[this.link.target] : getGroupFromNode(this.link.target);
    let areGroupsCollapsed = false;

    if (sourceGroup && targetGroup) {
      areGroupsCollapsed =
        (sourceGroup.collapsed || getNodesFromGroup(sourceGroup.id).length <= 1) &&
        (targetGroup.collapsed || getNodesFromGroup(targetGroup.id).length <= 1);
    }

    return groupLink && this.link.isGroup ? areGroupsCollapsed : !areGroupsCollapsed;
  }

  getLinkRef = (linkEl) => {
    this.linkEl = linkEl;
  };

  getFlowAmount = (flowData) => flowData.reduce((total, data) => total + data.value, 0);

  showDetails = (e) => {
    const { onShowDetails } = this.props;

    onShowDetails(
      'link',
      this.link,
      this.linkEl,
      {
        color: this.color,
        flowIn: this.flowIn,
        flowOut: this.flowOut,
        flowScale: this.flowScale
      },
      e
    );
  };

  handleClick = (e) => {
    const { onHighlight, highlight } = this.props;
    e.stopPropagation();
    this.showDetails(e);
    onHighlight(this.link, !highlight);
  };

  renderLine = (dir = 'out') => {
    const { $topo, highlightKey, highlight, markerId, theme } = this.props;
    const { hasFlowDataKey, highlightColor } = $topo;

    const opacity = 0.4;
    const flow = dir === 'out' ? this.flowOut : this.flowIn;
    const hasFlow = dir === 'out' ? this.hasFlowOut : this.hasFlowIn;
    const highlightedFlow = hasFlowDataKey(flow, highlightKey);
    let marker = `url(#${markerId}-${highlightedFlow ? 'highlightflow' : theme.name}${
      highlight && !highlightedFlow ? '-selected' : ''
    })`;

    // set line segment size based on the proportion of flow (in vs. out) minimum size is 1
    let flowScale = this.flowScale.out;

    if (this.hasFlowOut && this.hasFlowIn) {
      flowScale = clampNumber(flowScale, 0.1, 0.9);
    }

    // determine the center point where the in and out lines meet
    const centerPoint = [
      (this.xyPoints.x2 - this.xyPoints.x1) * flowScale,
      (this.xyPoints.y2 - this.xyPoints.y1) * flowScale
    ];
    let centerGap = this.hasFlowOut && this.hasFlowIn ? 4 * this.lineThickness * (dir === 'out' ? 1 : -1) : -4;

    // hide the markers and remove the center gap for very small lines
    if (Math.abs(this.width) + Math.abs(this.height) < this.smallLineSize) {
      marker = '';
      centerGap = 0;
    }

    const startX = dir === 'out' ? this.xyPoints.x1 : this.xyPoints.x2;
    const startY = dir === 'out' ? this.xyPoints.y1 : this.xyPoints.y2;

    // end positions must be within bounds and have some positive movement for the marker to point correctly
    let endX = clampNumber(
      this.xyPoints.x1 + (hasFlow ? centerGap * this.lineScale[0] : 0) + centerPoint[0],
      this.xyPoints.x1,
      this.xyPoints.x2,
      2 * this.lineScale[0]
    );
    let endY = clampNumber(
      this.xyPoints.y1 + (hasFlow ? centerGap * this.lineScale[1] : 0) + centerPoint[1],
      this.xyPoints.y1,
      this.xyPoints.y2,
      2 * this.lineScale[1]
    );

    // seems hacky but small lines act weird, this tries to keep the end point after the start point
    if (dir === 'out') {
      if (centerPoint[0] < 0 && endX >= startX) {
        endX = startX - 1 * this.lineScale[0];
      }
      if (centerPoint[1] < 0 && endY >= startY) {
        endY = startY - 1 * this.lineScale[1];
      }
      if (centerPoint[0] > 0 && endX <= startX) {
        endX = startX + 1 * this.lineScale[0];
      }
      if (centerPoint[1] > 0 && endY <= startY) {
        endY = startY + 1 * this.lineScale[1];
      }
    }

    // finally
    return (
      <line
        className={`flow-${dir}`}
        x1={startX}
        y1={startY}
        x2={endX}
        y2={endY}
        stroke={highlightedFlow ? highlightColor : this.color}
        strokeOpacity={highlight ? 1 : opacity}
        style={{
          cursor: 'pointer',
          strokeDasharray: `${hasFlow && !highlight ? 0 : 6}`,
          animation: highlight ? 'topologyAnimateLine 30s linear 0s infinite reverse running' : ''
        }}
        markerEnd={hasFlow && markerId ? marker : ''}
        strokeWidth={this.lineThickness}
      />
    );
  };

  render() {
    return (
      <g ref={this.getLinkRef} style={{ visibility: `${this.isVisible ? 'visible' : 'hidden'}` }}>
        {this.flowOut && this.renderLine('out')}
        {this.flowIn && this.renderLine('in')}

        <line
          x1={this.xyPoints.x1}
          y1={this.xyPoints.y1}
          x2={this.xyPoints.x2}
          y2={this.xyPoints.y2}
          stroke="transparent"
          strokeWidth={20}
          onClick={this.handleClick}
        />
      </g>
    );
  }
}

export default withTheme(Link);
