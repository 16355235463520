import React from 'react';

import { Box, Text, LinkButton } from 'core/components';
import $devices from 'app/stores/device/$devices';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: [
    'kt_aws_action',
    'kt_aws_status',
    'kt_aws_src_acc_id',
    'kt_aws_src_acc_alias',
    'kt_aws_src_acc_name',
    'kt_aws_src_vm_name',
    'kt_aws_src_vm_id',
    'kt_aws_src_region',
    'kt_aws_src_zone',
    'kt_aws_src_zone_id',
    'kt_aws_src_vm_type',
    'kt_aws_src_image_id',
    'kt_aws_src_sg',
    'kt_aws_src_asg',
    'kt_aws_src_pub_dns',
    'kt_aws_src_priv_dns',
    'kt_aws_src_vpc_id',
    'kt_aws_src_subnet_id',
    'kt_aws_dst_acc_id',
    'kt_aws_dst_acc_alias',
    'kt_aws_dst_acc_name',
    'kt_aws_dst_vm_name',
    'kt_aws_dst_vm_id',
    'kt_aws_dst_region',
    'kt_aws_dst_zone',
    'kt_aws_dst_zone_id',
    'kt_aws_dst_vm_type',
    'kt_aws_dst_image_id',
    'kt_aws_dst_sg',
    'kt_aws_dst_asg',
    'kt_aws_dst_pub_dns',
    'kt_aws_dst_priv_dns',
    'kt_aws_dst_vpc_id',
    'kt_aws_dst_subnet_id',
    'ktsubtype__aws_subnet__STR03'
  ],
  filterFields: [
    'kt_aws_action',
    'kt_aws_status',
    'kt_aws_src_acc_id',
    'kt_aws_src_acc_alias',
    'kt_aws_src_acc_name',
    'kt_aws_src_vm_name',
    'kt_aws_src_vm_id',
    'kt_aws_src_region',
    'kt_aws_src_zone',
    'kt_aws_src_zone_id',
    'kt_aws_src_vm_type',
    'kt_aws_src_image_id',
    'kt_aws_src_sg',
    'kt_aws_src_asg',
    'kt_aws_src_pub_dns',
    'kt_aws_src_priv_dns',
    'kt_aws_src_vpc_id',
    'kt_aws_src_subnet_id',
    'kt_aws_dst_acc_id',
    'kt_aws_dst_acc_alias',
    'kt_aws_dst_acc_name',
    'kt_aws_dst_vm_name',
    'kt_aws_dst_vm_id',
    'kt_aws_dst_region',
    'kt_aws_dst_zone',
    'kt_aws_dst_zone_id',
    'kt_aws_dst_vm_type',
    'kt_aws_dst_image_id',
    'kt_aws_dst_sg',
    'kt_aws_dst_asg',
    'kt_aws_dst_pub_dns',
    'kt_aws_dst_priv_dns',
    'kt_aws_dst_vpc_id',
    'kt_aws_dst_subnet_id',
    'ktsubtype__aws_subnet__STR03'
  ]
};

class AWSDependency extends AbstractDependency {
  get key() {
    return 'aws';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get preventQuery() {
    return true;
  }

  get meets() {
    return $devices.hasAWSDevice;
  }

  get message() {
    return (
      <>
        <Box mb="4px">
          <Text small>
            <strong>AWS</strong>
            devices are required by dimensions you&apos;re using, but have not been detected for any devices in your
            organization. Results shown herein may be highly inaccurate.
          </Text>
        </Box>
        <LinkButton text="Configure" icon="cog" small to="/v4/settings/clouds" />
      </>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || $devices.hasAWSDevice;
  }
}

export default AWSDependency;
