import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Position } from '@blueprintjs/core';

import { Box, Button, Flex, Tag, Text, Tooltip } from 'core/components';

const DashboardTooltipContent = ({ dashboard, selectedValue }) => {
  if (dashboard) {
    return (
      <Box maxWidth={200}>
        <Text fontWeight="bold">Navigate To:</Text>
        <Text as="div" ellipsis whiteSpace="nowrap" overflow="hidden">
          {dashboard.get('dash_title')}
        </Text>

        {selectedValue && (
          <>
            <Text fontWeight="bold" as="div" mt="4px">
              Filter Selection:
            </Text>
            <Text as="div" ellipsis whiteSpace="nowrap" overflow="hidden">
              {selectedValue}
            </Text>
          </>
        )}
      </Box>
    );
  }
  return <div>No Dashboard Destination Set</div>;
};

@inject('$dashboards')
export default class DashboardItemFooter extends Component {
  prevSize = 0;

  componentDidMount() {
    const { selectedModels } = this.props;
    this.prevSize = selectedModels.length;
  }

  componentDidUpdate() {
    const { item, selectedModels } = this.props;

    if (
      item.dataview.component &&
      item.dataview.component.reflow &&
      this.prevSize !== selectedModels.length &&
      (selectedModels.length === 0 || selectedModels.length === 1) &&
      !(item.get('dashboard_navigation') && item.get('destination_dashboard'))
    ) {
      item.dataview.component.reflow();
    }

    this.prevSize = selectedModels.length;
  }

  renderDrillDownButton = () => {
    const { dashboard, item, selectedModels, onClick, $dashboards } = this.props;
    const firstModel = selectedModels.length > 0 ? selectedModels[0] : undefined;
    const parametric_fields = (dashboard && dashboard.get('parametric_fields')) || [];
    let selectedValue;

    if (
      !item.get('dashboard_navigation') ||
      !item.get('destination_dashboard') ||
      !$dashboards.collection.get(item.get('destination_dashboard'))
    ) {
      return null;
    }

    if (firstModel && parametric_fields.length > 0) {
      selectedValue = item.getParametricKeyFromModel(firstModel, parametric_fields[0]);
    }

    return (
      <Tooltip
        content={<DashboardTooltipContent dashboard={dashboard} selectedValue={selectedValue} />}
        position={Position.TOP_RIGHT}
      >
        <Button onClick={onClick} intent="primary" small minimal>
          {item.get('dashboard_navigation_options.nesting.drillDownButtonText')}
          {selectedModels.length > 0 && (
            <Box display="inline-block" ml={1}>
              <Tag intent="primary" small>
                {selectedModels.length}
              </Tag>
            </Box>
          )}
        </Button>
      </Tooltip>
    );
  };

  render() {
    const { selectedModels, onClear } = this.props;

    return (
      <Flex p={1} justifyContent="space-between">
        <Box flex={1}>
          {selectedModels.length > 0 && (
            <Button intent="danger" onClick={onClear} minimal small>
              Clear Selections
            </Button>
          )}
        </Box>

        {this.renderDrillDownButton()}
      </Flex>
    );
  }
}
