import { get } from 'lodash';
import { isIpV4Valid, isIpV6Valid } from 'core/util/ip';

let lookups = {};

const getTraceCount = (group) =>
  Array.from(group.keys()).reduce(
    (traceCount, aID) =>
      (traceCount += Array.from(group.get(aID).keys()).reduce(
        (probeCount, tID) => (probeCount += group.get(aID).get(tID).size),
        0
      )),
    0
  );

// Todo: https://github.com/kentik/ui-app/issues/8161
// move and util
const getGeo = (geo = { city: {}, country: {}, region: {} }) => {
  const { latitude, longitude } = geo.city || {};
  const { code, name: country } = geo.country || {};
  const { name: region } = geo.region || {};

  if (!latitude || !longitude || !code || !country || !region) {
    return null;
  }

  return geo;
};

// move and util
const getSiteGeoByID = (siteID) => {
  const { geo } = lookups.sites[siteID] || {};

  return getGeo(geo);
};

// move and util
const getAgentGeoByID = (agentID) => {
  const { geo, site_id } = lookups.agents[agentID] || {};
  const siteGeo = getSiteGeoByID(site_id);
  const agentGeo = getGeo(geo);

  return siteGeo || agentGeo;
};

// move and util
const getIPGeo = (ip) => {
  const { geo, site_id } = lookups.ips[ip] || {};
  const siteGeo = getSiteGeoByID(site_id);
  const ipGeo = getGeo(geo);

  return siteGeo || ipGeo;
};

// move and util
const aggregateNumbers = (numbers) => {
  const count = numbers.length;

  const { total, max, min } = numbers.reduce(
    (acc, curr) => {
      if (curr > 0) {
        acc.total += curr;
        acc.max = curr > acc.max ? curr : acc.max;
        acc.min = curr < acc.min ? curr : acc.min;
      }

      return acc;
    },
    {
      total: 0,
      max: numbers[0],
      min: numbers[0]
    }
  );

  return {
    count,
    min,
    max,
    average: total / count
  };
};

const getLocationName = (geo) => {
  const { country, region, city } = geo || {};
  const parts = [];

  [city, region, country].forEach((area) => {
    const name = (area && area.name) || '';
    if (name && name !== '-' && parts[parts.length - 1] !== name) {
      parts.push(name);
    }
  });

  return parts.length ? parts.join(', ') : null;
};

const calculateNodeMetrics = (hops = [], { withLoss } = {}) => {
  const { losses, latencies, minExpectedLatencies, successes } = hops.reduce(
    (acc, curr) => {
      const { loss, latency, minExpectedLatency } = curr;

      if (loss) {
        acc.losses += 1;
      } else {
        acc.successes += 1;
      }

      if (latency) {
        acc.latencies.push(latency);
      }

      if (minExpectedLatency) {
        acc.minExpectedLatencies.push(minExpectedLatency);
      }

      return acc;
    },
    {
      losses: 0,
      latencies: [],
      minExpectedLatencies: [],
      successes: 0
    }
  );

  let loss;
  if (withLoss) {
    loss = (losses / hops.length) * 100;
  }

  let success;
  if (withLoss) {
    success = (successes / hops.length) * 100;
  }

  return {
    loss,
    success,
    successes,
    total: hops.length,
    latency: latencies.length
      ? {
          ...aggregateNumbers(latencies),
          minExpectedLatency: minExpectedLatencies.length ? aggregateNumbers(minExpectedLatencies).average : null
        }
      : null
  };
};

const getIPInfo = (ip) => {
  const ipInfo = lookups.ips[ip] || {};
  const ingress = (lookups.interfaces && lookups.interfaces[ip]) || null;
  const egress = (ipInfo.egress && lookups.interfaces && lookups.interfaces[ipInfo.egress]) || null;
  const device = (ipInfo.device_id && lookups.devices && lookups.devices[ipInfo.device_id]) || null;
  const { asn } = ipInfo;
  const { dns } = ipInfo;

  return {
    ingress,
    egress,
    device,
    asn,
    dns
  };
};

const getSiteInfo = (id, ip) => {
  if (lookups.sites) {
    const siteID = id || lookups.site_id_by_ip[ip];

    if (siteID) {
      return lookups.sites[siteID];
    }
  }

  return null;
};

const getIPData = (data) => {
  const ip = data.id;
  const { ingress, egress, device, asn, dns } = getIPInfo(ip);
  const site = getSiteInfo(data.site_id, ip);
  const geo = getIPGeo(ip);
  const location = getLocationName(geo);
  const nodeMetrics = calculateNodeMetrics(data.hops, { withLoss: false });

  return {
    name: (ingress && ingress.interface_ip_cidr) || ip,
    nodeMetrics,
    ingress,
    egress,
    device,
    asn,
    dns,
    location,
    site,
    geo
  };
};

const getTimeoutData = (data) => {
  const hopsCount = data.hops.length;
  const plural = hopsCount > 1 ? 's' : '';

  return {
    name: `${hopsCount} Unidentified Node${plural}`
  };
};

const inferAgentIPBasedOnTarget = (data, agent) => {
  const { target_ip = '' } = data.traces.find((trace) => trace.target_ip !== '::') || {};
  const isTargetIPv4 = isIpV4Valid(target_ip);
  const isTargetIPv6 = isIpV6Valid(target_ip);
  const ipv4 = get(agent, 'metadata.public_ipv4_addresses[0].value');
  const ipv6 = get(agent, 'metadata.public_ipv6_addresses[0].value');

  if (isTargetIPv4 && ipv4) {
    return ipv4;
  }

  if (isTargetIPv6 && ipv6) {
    return ipv6;
  }

  return agent.ip;
};

const getAgentData = (data) => {
  const agent = lookups?.agents[data.id] || {};
  const { agent_ip } = data.traces.find((trace) => trace.agent_ip !== '::') || {};
  const ip = agent_ip || inferAgentIPBasedOnTarget(data, agent);
  const geo = getAgentGeoByID(data.id);
  const location = getLocationName(geo);
  const site = getSiteInfo(data.site_id || agent.site_id, ip);
  const { ingress, egress, device, asn } = getIPInfo(ip);

  return {
    name: agent.agent_alias || `Agent ${agent.id}`,
    asn: agent.asn || asn || {},
    agent: { ...agent, ip },
    ingress,
    egress,
    device,
    location,
    site,
    geo
  };
};

const getTargetData = (data) => {
  const agentID = lookups.agent_id_by_ip[data.id];
  const agent = lookups.agents[agentID] || {};
  const ip = data.id;
  const geo = getAgentGeoByID(agentID) || getIPGeo(ip);
  const location = getLocationName(geo);
  const site = getSiteInfo(agent.site_id || data.site_id, ip);
  const { ingress, egress, device, asn } = getIPInfo(ip);
  const nodeMetrics = calculateNodeMetrics(data.hops, { withLoss: true });

  return {
    name: agent.agent_alias || ip,
    title: 'Target',
    asn: agent.asn || asn || {},
    agent: { ...agent, ip },
    nodeMetrics,
    ingress,
    egress,
    device,
    location,
    site,
    geo
  };
};

const getClosedPathData = (data) => {
  const { group } = data;
  const agentGroup = group.entries().next().value;
  const agentID = agentGroup[0];
  const targetGroup = agentGroup[1].entries().next().value;
  const target_ip = targetGroup[0];
  const maxPathCount = Array.from(targetGroup.keys()).reduce((acc, probeIndex) => {
    const { count = 0 } = group.get(agentID).get(target_ip).get(probeIndex) || {};

    acc = acc > count ? acc : count;

    return acc;
  }, 0);

  return {
    name: 'Collapsed Path',
    title: 'Collapsed Path',
    textCount: maxPathCount
  };
};

const getASNData = (data) => {
  const { id, hops } = data;
  const multiple = hops.length > 1;

  return {
    name: lookups.asns[id],
    supText: `${hops.length} hop${multiple ? 's' : ''}`,
    asn: {
      id,
      name: lookups.asns[id]
    }
  };
};

const getSiteData = (data) => {
  const site = getSiteInfo(data.id);

  return {
    name: site && site.title,
    title: 'Site',
    site
  };
};

const getRegionData = (data) => ({
  name: data.id
});

export const buildNodeData = (data) => {
  let info = {};

  if (data.type === 'ip') {
    info = getIPData(data);
  }

  if (data.type === 'timeout') {
    info = getTimeoutData(data);
  }

  if (data.type === 'closed_path') {
    info = getClosedPathData(data);
  }

  if (data.type === 'agent') {
    info = getAgentData(data);
  }

  if (data.type === 'target') {
    info = getTargetData(data);
  }

  if (data.type === 'asn') {
    info = getASNData(data);
  }

  if (data.type === 'site') {
    info = getSiteData(data);
  }

  if (data.type === 'region') {
    info = getRegionData(data);
  }

  return {
    ...info,
    traceCount: getTraceCount(data.group)
  };
};

const buildLinkedNode = (node, hops) => ({
  ...node,
  hops: [...hops]
});

const buildLinkGroup = (data) => {
  const group = new Map();

  data.toHops.forEach(({ agentID, target_ip, probeIndex }) => {
    // document agents
    if (!group.has(agentID)) {
      group.set(agentID, new Map());
    }

    // document targets
    if (!group.get(agentID).has(target_ip)) {
      group.get(agentID).set(target_ip, new Map());
    }

    // document probes
    if (!group.get(agentID).get(target_ip).has(probeIndex)) {
      group.get(agentID).get(target_ip).set(probeIndex, { count: 0 });
    }

    group.get(agentID).get(target_ip).get(probeIndex).count += 1;
  });

  return group;
};

export const buildLinkData = (data, nodes) => {
  const fromNode = buildLinkedNode(
    nodes.find(({ id }) => data.from === id),
    data.fromHops
  );
  const toNode = buildLinkedNode(
    nodes.find(({ id }) => data.to === id),
    data.toHops
  );
  const to = buildNodeData(toNode);
  const from = buildNodeData(fromNode);
  const toMetrics = get(to, 'nodeMetrics.latency');
  const fromMetrics = fromNode.type === 'agent' ? { min: 0, max: 0, average: 0 } : get(from, 'nodeMetrics.latency');

  let linkMetrics;

  if (fromMetrics && toMetrics) {
    // avoid negative values by using the absolute delta between source and destination metrics
    linkMetrics = {
      min: Math.abs(toMetrics.min - fromMetrics.min),
      max: Math.abs(toMetrics.max - fromMetrics.max),
      average: Math.abs(toMetrics.average - fromMetrics.average),
      minExpectedLatency: toMetrics.minExpectedLatency
    };
  }

  const group = buildLinkGroup(data);
  const traceCount = getTraceCount(group);

  return {
    to: {
      ...to,
      isCircle: toNode.isCircle,
      style: toNode.style
    },
    from: {
      ...from,
      isCircle: fromNode.isCircle,
      style: fromNode.style
    },
    group,
    traceCount,
    linkMetrics
  };
};

export const setOptions = (options) => {
  lookups = options.lookups;
};
