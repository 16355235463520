import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Dialog, DialogFooter } from 'core/components';

const dialogStyles = {
  top: 150,
  width: 400
};

@observer
export default class ConfirmDialog extends Component {
  static defaultProps = {
    text: 'You sure you wanna do this?',
    showCancel: true,
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Remove',
    confirmButtonIntent: 'primary',
    bodyProps: {
      pt: 4,
      px: 4,
      pb: 1,
      muted: true
    },
    footerButtonProps: {
      minWidth: 100
    }
  };

  render() {
    const {
      title,
      text,
      bodyProps,
      cancelButtonText,
      confirmButtonText,
      confirmButtonIntent,
      footerProps,
      footerButtonProps,
      onConfirm,
      onCancel,
      isOpen,
      themeName,
      children,
      model,
      dialogStyle,
      showCancel
    } = this.props;
    const isSaving = model && model.isRequestActive('updating');
    const isDestroying = model && model.isRequestActive('destroying');
    return (
      <Dialog
        title={title}
        className={themeName}
        isOpen={isOpen}
        style={Object.assign(dialogStyles, dialogStyle)}
        onClose={onCancel}
      >
        {children && React.cloneElement(children)}
        {!children && <Box {...bodyProps}>{text}</Box>}

        <DialogFooter {...footerProps}>
          {showCancel && <Button {...footerButtonProps} text={cancelButtonText} onClick={onCancel} mr={1} />}
          <Button
            {...footerButtonProps}
            text={confirmButtonText}
            intent={confirmButtonIntent}
            onClick={onConfirm}
            loading={isSaving || isDestroying}
          />
        </DialogFooter>
      </Dialog>
    );
  }
}
