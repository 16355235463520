import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import TestResultsPageWrapper from './components/testResults/TestResultsPageWrapper';
import TestResultsPageContent from './components/testResults/TestResultsPageContent';

@inject('$syn')
@observer
class TestResults extends Component {
  render() {
    const { test } = this.props;
    const { usePageComponent } = test.results.displayOptions;

    if (usePageComponent) {
      return (
        <TestResultsPageWrapper test={test}>
          <TestResultsPageContent test={test} />
        </TestResultsPageWrapper>
      );
    }

    return <TestResultsPageContent test={test} />;
  }
}

export default TestResults;
