import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { showErrorToast, showSuccessToast } from 'core/components/toast';
import Box from 'core/components/Box';
import Button from 'core/components/Button';
import Card from 'core/components/Card';
import Flex from 'core/components/Flex';
import Text from 'core/components/Text';
import Icon from 'core/components/Icon';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';

@inject('$notifications')
export default class NotificationFormDialog extends Component {
  state = {
    loading: false
  };

  handleTestClick = () => {
    const { $notifications, model } = this.props;

    this.setState({ loading: true });
    $notifications
      .testChannel(model.id)
      .then(
        () => {
          showSuccessToast('Test Notification has been sent.');
        },
        () => {
          showErrorToast('Your test could not be processed at this time. Please try again later.');
        }
      )
      .then(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { previewContent, previewError } = this.props;
    const { loading } = this.state;

    return (
      <Box mx={1}>
        <Card p={1}>
          <Flex flexDirection="column" height={580} overflow="hidden">
            <Box flex={1} overflow="auto">
              <pre style={{ whiteSpace: 'pre-wrap' }}>{previewContent}</pre>
            </Box>
            {previewError && (
              <div>
                <Icon icon={BsFillExclamationTriangleFill} color="warning" mr={1} />
                <Text color="warning" fontSize={12}>
                  {previewError}
                </Text>
              </div>
            )}
          </Flex>
        </Card>

        <Button text="Test" mt={3} loading={loading} onClick={this.handleTestClick} />
      </Box>
    );
  }
}
