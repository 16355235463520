import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import { MdInsertChart } from 'react-icons/md';
import { GiRadarSweep } from 'react-icons/gi';
import classNames from 'classnames';

import { Button, Flex, FlexColumn, Icon, Text } from 'core/components';

@inject('$app', '$auth', '$dashboard', '$dashboards', '$mkp')
@observer
class DashboardAddPanel extends Component {
  render() {
    const { $app, $auth, onAddItem } = this.props;

    const addBtnCls = classNames({
      [Classes.MINIMAL]: $app.darkThemeEnabled,
      [Classes.INTENT_SUCCESS]: $app.darkThemeEnabled
    });

    return (
      <Flex py={2} justifyContent="flex-start" gap={2}>
        <FlexColumn bg="subnavBackground" height={80} flex={1} maxWidth={250} minWidth={150} p={1} borderRadius={4}>
          <Flex alignItems="center" gap={1}>
            <Icon icon={MdInsertChart} iconSize={30} />
            <Text>Data Explorer View</Text>
          </Flex>
          <Button className={addBtnCls} onClick={() => onAddItem()} text="Add" alignSelf="flex-end" />
        </FlexColumn>
        {$auth.hasPermission('recon.enabled', { overrideForSudo: false }) && (
          <FlexColumn bg="subnavBackground" height={80} flex={1} maxWidth={250} minWidth={150} p={1} borderRadius={4}>
            <Flex alignItems="center" gap={1}>
              <Icon icon={MdInsertChart} iconSize={30} />
              <Text>Metrics Explorer View</Text>
            </Flex>
            <Button className={addBtnCls} onClick={() => onAddItem('metrics')} text="Add" alignSelf="flex-end" />
          </FlexColumn>
        )}
        <FlexColumn bg="subnavBackground" height={80} flex={1} maxWidth={250} minWidth={150} p={1} borderRadius={4}>
          <Flex alignItems="center" gap={1}>
            <Icon icon="search-template" iconSize={28} />
            <Text>Raw Flow View</Text>
          </Flex>
          <Button className={addBtnCls} onClick={() => onAddItem('raw_flow')} text="Add" alignSelf="flex-end" />
        </FlexColumn>
        <FlexColumn bg="subnavBackground" height={80} flex={1} maxWidth={250} minWidth={150} p={1} borderRadius={4}>
          <Flex alignItems="center" gap={1}>
            <Icon icon={GiRadarSweep} iconSize={30} />
            <Text>Synthetic Test View</Text>
          </Flex>
          <Button className={addBtnCls} onClick={() => onAddItem('synth_test')} text="Add" alignSelf="flex-end" />
        </FlexColumn>
      </Flex>
    );
  }
}

export default DashboardAddPanel;
