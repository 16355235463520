import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { POLICY_APPLICATIONS, POLICY_APPLICATION_LABELS } from 'shared/alerting/constants';

import AdminFilterSidebar from 'app/components/admin/AdminFilterSidebar';

export const ALLOWED_POLICY_TYPES = [
  POLICY_APPLICATIONS.DDOS,
  POLICY_APPLICATIONS.CORE,
  POLICY_APPLICATIONS.CLOUD,
  POLICY_APPLICATIONS.METRIC
];

@withRouter
@observer
class AlertingOverviewFilters extends Component {
  static defaultProps = {
    filterValues: undefined
  };

  get resetValues() {
    return {
      applications: Object.values(POLICY_APPLICATIONS).filter((application) =>
        ALLOWED_POLICY_TYPES.includes(application)
      ),
      lookbackSeconds: 24 * 3600 * 180
    };
  }

  get filterFields() {
    const { filterValues, readyOnly } = this.props;

    const filterOverrides = {};
    const passedFilters = Object.assign({}, filterValues, filterOverrides);

    const filterFields = {
      lookbackSeconds: {
        disabled: readyOnly,
        name: 'lookbackSeconds',
        label: 'Time Range',
        defaultValue: this.resetValues.lookbackSeconds,
        options: [
          {
            label: 'Past 3 months',
            value: 24 * 3600 * 90
          },
          {
            label: 'Past 6 months',
            value: 24 * 3600 * 180
          },
          {
            label: 'Past 9 months',
            value: 24 * 3600 * 270
          },
          {
            label: 'Past 12 months',
            value: 24 * 3600 * 365
          }
        ]
      },

      applications: {
        disabled: readyOnly,
        name: 'applications',
        label: 'Type',
        defaultValue: this.resetValues.applications,
        allowMultiSelect: true,
        showCheckboxes: true,
        options: ALLOWED_POLICY_TYPES.map((application) => ({
          value: application,
          label: POLICY_APPLICATION_LABELS[application]
        }))
      }
    };

    if (passedFilters) {
      Object.keys(passedFilters).forEach((key) => {
        let defaultValue = passedFilters[key];
        const filterField = filterFields[key];

        // set the default form values for all of the incoming query values and prop values
        if (filterField) {
          if (filterField.allowMultiSelect && !Array.isArray(defaultValue)) {
            defaultValue = [defaultValue];
          }

          if (key === 'lookbackSeconds') {
            defaultValue = parseInt(defaultValue) || 0;
            // if we're only showing lookback (and not start/end dates) make sure we have a valid value for the lookback (at least 24hrs)
            if (defaultValue < 24 * 3600 * 90) {
              defaultValue = 24 * 3600 * 90;
            }
          }

          filterField.defaultValue = defaultValue;
        }
      });
    }

    return Object.values(filterFields);
  }

  get adjustedFilterValues() {
    const { filterValues } = this.props;

    // Prefer lookback for subscriptions and make sure it's at least 3 months
    if (filterValues?.lookbackSeconds < 24 * 90 * 3600) {
      filterValues.lookbackSeconds = 24 * 90 * 3600;
    }

    return filterValues;
  }

  handleFilterChange = (filters) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(filters);
    }
  };

  render() {
    const { filterValues, ...otherProps } = this.props;

    return (
      <AdminFilterSidebar
        {...otherProps}
        filterValues={this.adjustedFilterValues}
        filterFields={this.filterFields}
        resetValues={this.resetValues}
        onFilterChange={this.handleFilterChange}
      />
    );
  }
}

export default AlertingOverviewFilters;
