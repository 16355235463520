import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Collection from 'core/model/Collection';
import Box from 'core/components/Box';
import Button from 'core/components/Button';
import Flex from 'core/components/Flex';
import InputGroup from 'core/form/components/InputGroup';

@observer
class Search extends Component {
  static propTypes = {
    // array of strings, filters to exclude from showing
    excludeFilters: PropTypes.array,
    autoFocus: PropTypes.bool,
    clearSelectionsOnChange: PropTypes.bool,
    collection: PropTypes.instanceOf(Collection).isRequired,
    inputProps: PropTypes.object,
    placeholder: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    excludeFilters: [],
    autoFocus: true,
    clearSelectionsOnChange: false,
    placeholder: 'Search',
    inputProps: {},
    onChange: null
  };

  get filterValue() {
    const { collection, searchTerm } = this.props;
    return searchTerm || collection.searchState?.value || collection.filterState;
  }

  handleSearchChange = (e) => {
    const { collection, onChange } = this.props;

    if (onChange) {
      onChange(e);
    } else {
      collection.filter(e.target.value);
    }
  };

  handleSearchClear = () => {
    const { collection, clearSelectionsOnChange, onChange } = this.props;

    if (onChange) {
      onChange({ target: { value: '' } });
    } else {
      collection.filter('');
    }

    if (clearSelectionsOnChange) {
      collection.clearSelection();
    }
  };

  render() {
    const {
      autoFocus,
      clearSelectionsOnChange,
      collection,
      excludeFilters,
      inputProps,
      placeholder,
      onChange,
      ...rest
    } = this.props;

    const rightElement = this.filterValue ? (
      <Flex height="100%" mr="5px">
        <Button icon="cross" onClick={this.handleSearchClear} minimal />
      </Flex>
    ) : undefined;

    return (
      <Box {...rest}>
        <InputGroup
          value={this.filterValue}
          autoFocus={autoFocus}
          leftIcon={inputProps.leftElement ? undefined : 'search'}
          onChange={this.handleSearchChange}
          placeholder={placeholder}
          rightElement={rightElement}
          {...inputProps}
        />
      </Box>
    );
  }
}

export default Search;
