import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { debounce } from 'lodash';
import { withRouter } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';

import { Button, AnchorButton, CalloutOutline, Flex, Text } from 'core/components';
import Page from 'app/components/page/Page';
import TestPauseResumeButton from 'app/views/synthetics/tests/TestPauseResumeButton';
import { hideTooltips, handleCopyTest } from 'app/views/synthetics/utils/syntheticsUtils';
import TestResultsPageHeader from './TestResultsPageHeader';

@inject('$auth', '$dataviews', '$sharedLinks')
@observer
@withRouter
class TestResultsPageWrapper extends Component {
  handleOnScroll = debounce(() => {
    const { $dataviews } = this.props;

    hideTooltips($dataviews);
  }, 50);

  get title() {
    const { test } = this.props;

    if (test.isPreview) {
      return 'Test Preview';
    }

    return test.get('display_name') || 'Test Results';
  }

  get showExport() {
    const { test } = this.props;
    const { bigDataSet, displayOptions } = test.results;
    const { allowExport } = displayOptions;

    if (allowExport) {
      return bigDataSet ? { sleepDuration: 30000 } : true;
    }

    return false;
  }

  get shareButtonProps() {
    const { $sharedLinks, test } = this.props;
    const { lookbackSeconds, startDate, endDate, displayOptions } = test.results;
    const { allowShare } = displayOptions;

    if (allowShare) {
      // time selections for SharePublicViewDialog
      let shareStart = startDate;
      let shareEnd = endDate;
      if (lookbackSeconds) {
        if (!endDate) {
          shareEnd = Math.floor(Date.now() / 1000);
        }

        if (!startDate) {
          shareStart = shareEnd - lookbackSeconds;
        }
      }

      let privateShareUrl;
      if ($sharedLinks && $sharedLinks.history && $sharedLinks.history.location) {
        privateShareUrl = `${$sharedLinks.history?.location?.pathname}${$sharedLinks.history?.location?.search}`;
      }

      return {
        link_type: $sharedLinks.types.synthetics,
        test_id: test.id,
        start_time: shareStart,
        end_time: shareEnd,
        legacyLinkShare: false,
        privateShareUrl
      };
    }

    return {};
  }

  render() {
    const { $auth, history, test, children, match } = this.props;
    const parentLinks = [{ link: '/v4/synthetics/tests', label: 'Test Control Center' }];
    const canEditTests = $auth.hasRbacPermissions(['synthetics.test::update']);
    const showTestActions = canEditTests && !test.isPreview;
    const subnavTools = [
      {
        id: 'edit-test',
        text: 'Edit Test',
        icon: FiEdit,
        onClick: () => history.push(`/v4/synthetics/tests/${test.id}`),
        show: showTestActions && !test.isPreset
      },
      {
        id: 'pause-resume-test',
        model: test,
        component: TestPauseResumeButton,
        show: showTestActions && !test.isPreset
      },
      {
        id: 'customize-test',
        text: 'Customize this test',
        icon: FiEdit,
        onClick: (e) => {
          e.stopPropagation();
          handleCopyTest(history, test);
        },
        show: showTestActions && test.isPreset
      }
    ]
      .filter(({ show }) => show)
      .map(({ id, component, show, ...rest }) => {
        const Btn = component || Button;
        return <Btn id={id} key={id} {...rest} minimal />;
      });

    return (
      <Page
        title={this.title}
        parentLinks={parentLinks}
        subnavTools={subnavTools}
        scrolls
        showExport={this.showExport}
        forceShowExport={!!this.showExport}
        shareButtonProps={this.shareButtonProps}
        onScroll={this.handleOnScroll}
      >
        {$auth.isSynOnboarding && (
          <CalloutOutline intent="primary" mb={4} px={2}>
            <Flex alignItems="center" justifyContent="space-between">
              <Text color="muted">
                Modify test to gain deeper results based on your specific configuration and resolve identified issues.
              </Text>
              <Flex gap={2}>
                <AnchorButton
                  minimal
                  rel="noopener noreferrer"
                  intent="warning"
                  fontSize="small"
                  fontWeight="bold"
                  target="_blank"
                  href="https://kb.kentik.com/v4/Ma00.htm#Ma00-Synthetics_Overview"
                  text="Learn More"
                  style={{ textTransform: 'uppercase' }}
                />
                <Button
                  minimal
                  intent="warning"
                  fontSize="small"
                  fontWeight="bold"
                  style={{ textTransform: 'uppercase' }}
                  onClick={() => history.push(`/v4/synthetics/tests/${test.id}`)}
                >
                  Modify Test
                </Button>
              </Flex>
            </Flex>
          </CalloutOutline>
        )}
        <TestResultsPageHeader test={test} match={match} />
        {children}
      </Page>
    );
  }
}

export default TestResultsPageWrapper;
