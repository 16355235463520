import React from 'react';
import { inject, observer } from 'mobx-react';
import BGPTestResults from 'app/views/synthetics/components/bgp/TestResults';

@inject('$syn')
@observer
export default class BGPPanel extends React.Component {
  render() {
    const { test } = this.props;
    const { startDate, endDate, lookbackSeconds } = test.results;

    return (
      <BGPTestResults model={test} startDate={startDate} endDate={endDate} lookbackSeconds={lookbackSeconds} embedded />
    );
  }
}
