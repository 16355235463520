module.exports = {
  get getReasonOptions() {
    return [
      { label: 'Troubleshooting Customer Issue', value: 'troubleshooting issue' },
      { label: 'Verifying Customer Fix', value: 'verifying fix' },
      { label: 'Security Investigation', value: 'security investigation' },
      { label: 'Product: Feature Validation', value: 'feature validation' },
      { label: 'Customer Setup/Config Support', value: 'support' },
      { label: 'Other', value: 'other' }
    ];
  }
};
