import React from 'react';
import { Box, Text } from 'core/components';

const AlarmValue = ({ value, unit, ellipsis, bold, large, small, className, ...props }) => {
  let fontSize = 14;

  if (large) {
    fontSize = 24;
  }
  if (small) {
    fontSize = 14;
  }

  return (
    <Box
      maxWidth="100%"
      className={className}
      title={typeof value === 'string' ? `${value}${unit ? ` ${unit}` : ''}` : undefined}
      {...props}
    >
      <Text style={{ wordBreak: 'break-word' }} fontSize={fontSize} fontWeight={bold ? 700 : undefined}>
        {value}
      </Text>

      {unit && (
        <Text muted fontSize={16}>
          &nbsp;{unit}
        </Text>
      )}
    </Box>
  );
};

AlarmValue.defaultProps = {
  ellipsis: true,
  bold: false,
  large: false,
  small: false
};

export default AlarmValue;
