import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';

@inject('$lookups')
@observer
export default class ProtocolRenderer extends Component {
  state = {
    protocol: null
  };

  componentDidMount() {
    const { $lookups, protocol } = this.props;

    this.lookupRequest = makeCancelable($lookups.protocols(protocol.toString()));

    this.lookupRequest.promise
      .then(([match]) => this.setState({ protocol: match }))
      .catch((err) => {
        if (!(err instanceof CanceledError)) {
          console.error(`Error looking up protocol ${protocol}`, err);
        }
      });
  }

  componentWillUnmount() {
    this.lookupRequest.cancel();
  }

  render() {
    const { children } = this.props;
    const { protocol } = this.state;

    if (children && typeof children === 'function') {
      return children(protocol);
    }

    if (protocol) {
      return `${protocol.matcher} (${protocol.value})`;
    }

    return null;
  }
}
