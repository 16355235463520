import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';

import { Button, Flex, Text, Icon } from 'core/components';
import { Field, InputGroup, Select } from 'core/form';

import { getFilterValueSelectProps } from './simpleFilterUtils';

const selectValueRenderer = (option, placeholder) => {
  if (!option) {
    return <span className={Classes.TEXT_MUTED}>{placeholder || 'Select a value...'}</span>;
  }

  const iconName = option ? option.iconCls || option.iconName : false;
  return (
    <Text as="div" style={{ width: 125 }} ellipsis className={option.className}>
      {iconName && <Icon icon={iconName} mr="6px" />}
      {option && option.label}
    </Text>
  );
};

@observer
class SimpleFilterItem extends Component {
  render() {
    const { filter, index, numFilters, onChange, onRemove, options } = this.props;

    const filterFieldValue = filter.filterField.getValue();
    const { onQuery, valueOptions } = getFilterValueSelectProps(filterFieldValue);

    return (
      <Flex alignItems="center">
        {numFilters > 1 && (
          <Text as="div" fontSize={10} fontWeight="bold" color="muted" mr={1} width={22}>
            {index > 0 ? 'AND' : ''}
          </Text>
        )}

        <Field showLabel={false} field={filter.filterField} options={options} mb={0} mr={1} small>
          <Select showFilter menuWidth={200} boundary="window" buttonStyle={{ width: 200 }} />
        </Field>
        {filterFieldValue && (
          <>
            {(valueOptions || onQuery) && (
              <Field
                mb={0}
                showLabel={false}
                field={filter.filterValue}
                placeholder="Select value..."
                options={valueOptions}
                onChange={onChange}
                validateOptions
                autoFocus
                small
                onQuery={onQuery}
              >
                <Select
                  shouldDismissPopover={false}
                  exactMatch
                  showFilter
                  menuWidth={200}
                  boundary="window"
                  valueRenderer={selectValueRenderer}
                  buttonStyle={{ width: 200 }}
                  inputStyle={{ height: 24, width: 200 }}
                />
              </Field>
            )}
            {!valueOptions && !onQuery && (
              <Field
                mb={0}
                showLabel={false}
                field={filter.filterValue}
                onChange={onChange}
                options={null} /* need this to handle toggling from options to no options */
                autoFocus
                small
              >
                <InputGroup width={150} />
              </Field>
            )}
          </>
        )}
        <Button icon="cross" onClick={() => onRemove(index)} minimal small ml={1} />
      </Flex>
    );
  }
}

export default SimpleFilterItem;
