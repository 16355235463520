import React, { Component } from 'react';
import { Button, ConfirmPopover } from 'core/components';
import { ReactComponent as ResetIcon } from 'app/assets/icons/reset.svg';

class ResetDeckButton extends Component {
  handleReset = () => {
    const { deck, onReset } = this.props;

    if (deck && !deck.isNew) {
      deck.destroy().then(() => {
        onReset();
      });
    } else {
      onReset();
    }
  };

  render() {
    return (
      <ConfirmPopover
        onConfirm={this.handleReset}
        confirmText="Reset"
        confirmBodyContent="Are you sure want to discard your customizations and use the system default?"
      >
        <Button minimal icon={ResetIcon} muted text="Reset" ml={1} />
      </ConfirmPopover>
    );
  }
}

export default ResetDeckButton;
