import React from 'react';

import { Heading, Text } from 'core/components';
import Page from 'app/components/page/Page';

const NotFound = ({
  hideSubnav = true,
  parentLinks,
  title = 'Not Found',
  heading = '404',
  text = 'This page does not exist.'
}) => (
  <Page title={title} hideSubnav={hideSubnav} parentLinks={parentLinks}>
    <Heading level={2}>{heading}</Heading>
    <Text muted monospace>
      {text}
    </Text>
  </Page>
);

export default NotFound;
