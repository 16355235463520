import React, { Component } from 'react';
import GraphContainer from '../../GraphContainer';
import Table from './table';

const parseData = ({ data }) => {
  const { results, prefixes } = data;

  return {
    data: results,
    graphProps: { collapse: prefixes.length > 1 }
  };
};

const filterData = (results, { eventsTableFilter }) =>
  eventsTableFilter
    ? results.filter((result) => [eventsTableFilter].some((filterName) => result.type === filterName))
    : results;

class BgpEventsTableWrapper extends Component {
  render() {
    return (
      <GraphContainer
        {...this.props}
        key="bgp-events-table"
        type="eventsTable"
        tableComponent={Table}
        heading="Events Table"
        text={() => 'Raw BGP events for the prefixes being monitored, grouped by AS Path per prefix'}
        noResultsDescription="No BGP events were found for this time range"
        parseData={parseData}
        filterData={filterData}
        filters={['eventsTableFilter']}
      />
    );
  }
}

export default BgpEventsTableWrapper;
