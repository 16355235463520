import React from 'react';

const Forbidden = () => (
  <div>
    <h2>403 Forbidden</h2>
    <p className="pt-text-muted pt-monospace-text">
      You are not authorized to access this page. Please contact your administrative user for further details.
    </p>
  </div>
);

export default Forbidden;
