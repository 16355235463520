const { isEqual } = require('lodash');
const {
  SEVERITY_MAP,
  ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY
} = require('@kentik/ui-shared/alerting/constants');

const STANDARD_ALERT_SEVERITY_TO_NOTIFY_SEVERITY = {
  [SEVERITY_MAP.MINOR.STANDARD_ALERT]: SEVERITY_MAP.MINOR.NOTIFY,
  [SEVERITY_MAP.WARNING.STANDARD_ALERT]: SEVERITY_MAP.WARNING.NOTIFY,
  [SEVERITY_MAP.MAJOR.STANDARD_ALERT]: SEVERITY_MAP.MAJOR.NOTIFY,
  [SEVERITY_MAP.SEVERE.STANDARD_ALERT]: SEVERITY_MAP.SEVERE.NOTIFY,
  [SEVERITY_MAP.CRITICAL.STANDARD_ALERT]: SEVERITY_MAP.CRITICAL.NOTIFY
};

const ALERT_MANAGER_SEVERITY_TO_NOTIFY_SEVERITY = {
  [SEVERITY_MAP.MINOR.ALERT_MANAGER]: SEVERITY_MAP.MINOR.NOTIFY,
  [SEVERITY_MAP.WARNING.ALERT_MANAGER]: SEVERITY_MAP.WARNING.NOTIFY,
  [SEVERITY_MAP.MAJOR.ALERT_MANAGER]: SEVERITY_MAP.MAJOR.NOTIFY,
  [SEVERITY_MAP.SEVERE.ALERT_MANAGER]: SEVERITY_MAP.SEVERE.NOTIFY,
  [SEVERITY_MAP.CRITICAL.ALERT_MANAGER]: SEVERITY_MAP.CRITICAL.NOTIFY
};

const NOTIFICATION_OAUTH_BLANK_SECRET_VALUE = '********';

// find a conjunction in a channel
function findChannelConjunction(channel, newConjunction) {
  const attributes = channel.get ? channel.get() : channel;
  const sortSelectors = (a, b) => a.lhs.localeCompare(b.lhs);
  const sortedNewSelectors = newConjunction.selectors.sort(sortSelectors);

  if (!attributes.config.selectorConfig?.conjunctions) {
    return -1;
  }

  return attributes.config.selectorConfig.conjunctions.findIndex((conjunction) => {
    const sortedSelectors = conjunction.selectors.sort(sortSelectors);
    return isEqual(sortedSelectors, sortedNewSelectors);
  });
}

// check if the conjunction is found in this channel's conjunctions
function checkChannelForConjunction(channel, newConjunction) {
  return findChannelConjunction(channel, newConjunction) >= 0;
}

// check if the rule (and optional policy) is found in this channel's conjunction junctions
function checkChannelForRuleAndSeverityUsage({ channel, ruleId, severity }) {
  // accommodate UI model/collection and bookshelfjs model
  const config = channel.config || (channel.get && channel.get('config'));
  return config?.selectorConfig?.conjunctions?.some((conjunction) => {
    let ruleSelector;
    let severitySelector;

    conjunction.selectors.forEach((selector) => {
      if (selector.lhs === 'alertmanRule') {
        ruleSelector = selector;
      }
      if (selector.lhs === 'alertmanSeverity') {
        severitySelector = selector;
      }
    });

    if (ruleId && severity && ruleSelector && severitySelector) {
      // Try to translate the severity we've received to the severity values that the notify service uses.
      // We might receive values that correspond to alert manager severities or standard alert severities,
      // and need to match these with the corresponding notify severity value.
      return (
        ruleSelector?.rhs === ruleId &&
        (severitySelector?.rhs === ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY[severity] ||
          severitySelector?.rhs === STANDARD_ALERT_SEVERITY_TO_NOTIFY_SEVERITY[severity] ||
          severitySelector?.rhs === ALERT_MANAGER_SEVERITY_TO_NOTIFY_SEVERITY[severity])
      );
    }

    return false;
  });
}

// find notifications that use this specific policy descriptor (level or threshold)
function findNotificationsByPolicyDescriptor({ descriptor, ruleId, channels }) {
  return channels.filter((channel) =>
    checkChannelForRuleAndSeverityUsage({
      channel,
      ruleId,
      severity: descriptor.severity
    })
  );
}

// find all notifications that use the rule id
function findNotificationsByRule({ ruleIds, channels, asList = false }) {
  const ruleIdMap = ruleIds.reduce((acc, ruleId) => {
    acc[ruleId] = [];
    return acc;
  }, {});

  const channelsByRuleId = channels.reduce((acc, channel) => {
    const config = channel.config || (channel.get && channel.get('config'));
    const conjunctions = config?.selectorConfig?.conjunctions || [];

    conjunctions.forEach((conjunction) => {
      const ruleSelector = conjunction.selectors.find((selector) => selector.lhs === 'alertmanRule');
      if (ruleSelector?.rhs && acc[ruleSelector.rhs]) {
        acc[ruleSelector.rhs].push(channel);
      }
    });
    return acc;
  }, ruleIdMap);

  if (asList) {
    return Object.values(channelsByRuleId).flat();
  }

  return channelsByRuleId;
}

// find all notifications that use any level (nms) or threshold on this policy
function findNotificationsByPolicy({ policy, channels }) {
  const { ruleId } = policy;

  const policyDescriptors = policy.levels || policy.thresholds;

  return policyDescriptors.reduce((acc, descriptor) => {
    findNotificationsByPolicyDescriptor({ descriptor, ruleId, channels }).forEach((notificationChannel) => {
      // accommodate UI model/collection and bookshelfjs model
      acc[notificationChannel.id || notificationChannel.channelID || notificationChannel.get('channelID')] =
        notificationChannel;
    });

    return acc;
  }, {});
}

function setModelNotifications({ models, notifications }) {
  models.forEach((m) => {
    const alertingConfig = m.get('config')?.alerting;

    if (alertingConfig) {
      // gather a list of all possible alert rule ids
      const ruleIds = [alertingConfig.rule_id, alertingConfig.ktrac_rule_id];

      // get the notification channels attached to any of the rule ids, returning as a list of models
      const channels = findNotificationsByRule({ ruleIds, channels: notifications.channels, asList: true });

      // just keep the channel ids to tag on to the test config
      const channelIds = channels.map((channel) => channel.channelID);

      m.get('config').notifications = { channels: channelIds };
    }
  });
}

/*
  checks a conjunction for the presence of a rule-based selector matching by the provided rule id
*/
function isConjunctionByRuleId({ conjunction, ruleId } = {}) {
  return !!conjunction?.selectors?.some(
    (selector) => selector.lhs === 'alertmanRule' && selector.op === '=' && selector.rhs === ruleId
  );
}

/* 
  checks a notification channel for the presence of a rule-based conjunction matching by the provided rule id
  flexibly accepts a plain channel or a channel model
*/
function hasConjunctionByRuleId({ channel, ruleId } = {}) {
  const config = channel?.config || (channel?.get && channel.get('config'));

  if (config) {
    return !!config?.selectorConfig?.conjunctions?.some((conjunction) =>
      isConjunctionByRuleId({ conjunction, ruleId })
    );
  }

  return false;
}

/**
 * @param {Collection} policies
 * @param {Collection} methods
 * @param {Collection} synthTests
 * @param {Collection} tenants
 * @param {Collection} channel
 * @returns {Object} usedIn
 */
function getChannelUsageMap(policies, methods, synthTests, tenants, channel) {
  const usedIn = {
    policies: {},
    mitigationMethods: [],
    insights: [],
    insightFamilies: [],
    subpolicies: {},
    synthTest: {}
  };

  const conjunctions = channel.get('config.selectorConfig.conjunctions') || [];
  const userGroupId = channel.get('config.userGroupID');
  const tenantModel = tenants.get(userGroupId);

  conjunctions.forEach((conjunction) => {
    const selectors = conjunction.selectors || [];

    /**
     * Conjunctions with a single selector - Synth V2 (with no severity selector), Mitigation methods, Insights
     */
    if (selectors.length === 1) {
      const selector = selectors[0];

      // Synthetic Test - [{"op": "=", "lhs": "alertmanRule", "rhs": "018e34a0-9119-7759-a21d-7c22eaed0142"}]
      // (an alert policy would be 2 selectors, 1 for rule and 1 for severity)
      if (selector.lhs === 'alertmanRule') {
        const testId = synthTests.modelIdByRuleId[selector.rhs];

        if (testId) {
          usedIn.synthTest[testId] = usedIn.synthTest[testId] || [];
          usedIn.synthTest[testId].push(selector.rhs);
        }

        // Mitigation Methods - [{"op": "=", "lhs": "mitigationMethod", "rhs": "2059"}]
      } else if (selector.lhs === 'mitigationMethod') {
        const mitigationMethod = methods.modelById[selector.rhs];

        if (mitigationMethod) {
          usedIn.mitigationMethods.push(selector.rhs);
        }

        // Single Insight - [{"op": "=", "lhs": "insightName", "rhs": "core.networkHealth.deviceNoOrLowFlow"}]
      } else if (selector.lhs === 'insightName' && !selector.rhs.startsWith('custom.insight.')) {
        usedIn.insights.push(selector.rhs);

        // Family of Insights - [{"op": "=", "lhs": "insightFamily", "rhs": "Traffic Comparison"}]
      } else if (selector.lhs === 'insightFamily') {
        usedIn.insightFamilies.push(selector.rhs);
      }

      /**
       * Alerting Policies
       * Alert Manager Rule - [{"op": "=", "lhs": "alertmanRule", "rhs": "018ed103-ac74-7e03-a8b8-7393feb52134"}, {"op": "=", "lhs": "alertmanSeverity", "rhs": "severe"}]
       */
    } else if (selectors[0].lhs === 'alertmanRule') {
      const ruleId = selectors[0].rhs;
      const severityId = selectors[1].rhs;

      const containingPolicyId = policies.getPolicyByRuleId(ruleId)?.id;
      const testId = synthTests.modelIdByRuleId[ruleId];

      let matchingSubpolicy;
      let matchingTenant;

      (tenantModel ? [tenantModel] : tenants.get() || []).forEach((tenant) => {
        (tenant.get('config.alerts') || []).forEach((policy) => {
          if (`${policy.activationSettings?.alertManagerConfig?.ruleId}` === `${ruleId}`) {
            matchingSubpolicy = policy;
            matchingTenant = tenant;
          }
        });
      });

      if (containingPolicyId) {
        usedIn.policies[containingPolicyId] = usedIn.policies[containingPolicyId] || [];
        usedIn.policies[containingPolicyId].push(severityId);
      }

      if ((matchingTenant || tenantModel) && matchingSubpolicy) {
        const tenant = matchingTenant || tenantModel;
        usedIn.subpolicies[tenant.id] = usedIn.subpolicies[tenant.id] || [];
        usedIn.subpolicies[tenant.id].push(matchingSubpolicy.id);
      }

      if (testId) {
        // count usage of synth tests where we also define selectors by severity
        usedIn.synthTest[testId] = usedIn.synthTest[testId] || [];
        usedIn.synthTest[testId].push(ruleId);
      }

      /**
       * Conjunctions with three selectors - Ktrac
       */
    } else if (selectors.length === 3) {
      // Ktrac Onprem Insight [{"op": "!=", "lhs": "eventType", "rhs": "insightEvent"}, {"op": "=", "lhs": "alertPolicy", "rhs": "*"}, {"op": "=", "lhs": "region", "rhs": "JP1"}]
      // Ktrac Alert Policy - [{"op": "=", "lhs": "alertPolicy", "rhs": "18358"}, {"op": "=", "lhs": "alertThreshold", "rhs": "112355"}, {"op": "=", "lhs": "company", "rhs": "87629"}]
      // ignored for now
    }
  });

  return usedIn;
}

module.exports = {
  getChannelUsageMap,
  checkChannelForConjunction,
  findChannelConjunction,
  findNotificationsByPolicy,
  findNotificationsByRule,
  findNotificationsByPolicyDescriptor,
  checkChannelForRuleAndSeverityUsage,
  setModelNotifications,
  isConjunctionByRuleId,
  hasConjunctionByRuleId,
  STANDARD_ALERT_SEVERITY_TO_NOTIFY_SEVERITY,
  ALERT_MANAGER_SEVERITY_TO_NOTIFY_SEVERITY,
  NOTIFICATION_OAUTH_BLANK_SECRET_VALUE
};
