import React from 'react';
import { observer } from 'mobx-react';
import { NonIdealState } from '@blueprintjs/core';
import classNames from 'classnames';

import Flex from 'core/components/Flex';

export default observer((props) => {
  const { fullScreen, hasOutdatedBundle, showDescription } = props;
  const className = classNames({ page: fullScreen });

  const message = (
    <span>
      {hasOutdatedBundle && (
        <span>
          Kentik has been notified of this error, but it appears you are running with outdated resources.
          <br />
          We recommend refreshing your browser and feel free to report an issue if the problem persists.
        </span>
      )}
      {!hasOutdatedBundle && (
        <span>Kentik has been notified of this error, but feel free to report an issue if the problem persists</span>
      )}
    </span>
  );

  console.info(showDescription, message);

  return (
    <Flex
      className={className}
      flex="1 1 auto"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: '100%' }}
    >
      <NonIdealState title="Error Occurred" visual="issue" description={showDescription && message} />
    </Flex>
  );
});
