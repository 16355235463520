import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Box } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import SidebarCard from 'app/components/landing/SidebarCard';
import ModuleLinkButton from 'app/components/landing/ModuleLinkButton';
import TopFiveTraffic from 'app/views/serviceProvider/cdn/TopFiveTraffic';

@inject('$auth', '$cdns')
class CdnCard extends Component {
  state = {
    loadingModuleConfig: true,
    loadingNonCdnTraffic: true,
    nonCdnTraffic: null
  };

  aggregateType = 'max_bits_per_sec';

  componentDidMount() {
    const { $auth, $cdns } = this.props;

    if ($auth.hasPermission('cdn.enabled', { overrideForSudo: false })) {
      $cdns.getModuleConfig().then(() => {
        this.setState({ loadingModuleConfig: false });
      });
    } else {
      this.setState({ loadingModuleConfig: false, loadingNonCdnTraffic: false });
    }
  }

  getTotalTraffic(results) {
    const model = results.models.find((item) => item.get('name') === 'Total');
    return model ? model.get(this.aggregateType) : 0;
  }

  getNonCdnTraffic(results) {
    const model = results.models && results.models[0];
    return model ? model.get(this.aggregateType) : 0;
  }

  handleNonCdnQueryComplete = ({ results }) => {
    this.setState({ nonCdnTraffic: this.getNonCdnTraffic(results), loadingNonCdnTraffic: false });
  };

  renderCard({ loading = false, total = 0, enabled = true } = {}) {
    const { loadingNonCdnTraffic, nonCdnTraffic } = this.state;
    const title = 'CDN Analytics';
    const route = '/v4/service-provider/cdn';

    return (
      <SidebarCard
        icon="database"
        title={title}
        loading={loading || loadingNonCdnTraffic}
        isConfigured={enabled && total > 0}
        notConfiguredText={enabled ? 'You do not have any CDN traffic' : 'This module is not enabled'}
        to={route}
        mb={2}
      >
        <Box my={2}>
          <TopFiveTraffic altLayout aggregateType={this.aggregateType} nonCdnTraffic={nonCdnTraffic} total={total} />
        </Box>

        <ModuleLinkButton mt={1} text={title} to={route} />
      </SidebarCard>
    );
  }

  render() {
    const { $auth, $cdns } = this.props;
    const { loadingModuleConfig, nonCdnTraffic } = this.state;

    if (!$auth.hasPermission('cdn.enabled', { overrideForSudo: false })) {
      return this.renderCard({ loading: false, total: 0, enabled: false });
    }

    return (
      <>
        {!nonCdnTraffic && (
          <LightweightDataViewWrapper
            onQueryComplete={this.handleNonCdnQueryComplete}
            query={$cdns.queries.getNonCdnQuery({})}
          />
        )}

        {loadingModuleConfig && this.renderCard({ loading: true })}

        {!loadingModuleConfig && (
          <LightweightDataViewWrapper query={$cdns.queries.getTrafficQuery({})}>
            {({ loading, results }) => {
              const total = this.getTotalTraffic(results);
              return this.renderCard({ loading, total });
            }}
          </LightweightDataViewWrapper>
        )}
      </>
    );
  }
}

export default CdnCard;
