import React, { useState } from 'react';
import classNames from 'classnames';

import { Button, ButtonGroup } from 'core/components';

const SwitchOnOff = (props) => {
  const { onChange, className, small, disabled, value, invert } = props;
  const [isOn, setOn] = useState(invert ? !value : value);

  const toggleOn = (on) => {
    setOn(on);
    onChange(invert ? !on : on);
  };

  return (
    <ButtonGroup className={classNames(className)}>
      <Button
        small={small}
        text="On"
        intent={isOn ? 'primary' : 'none'}
        disabled={disabled}
        onClick={() => toggleOn(true)}
      />
      <Button
        small={small}
        text="Off"
        intent={!isOn ? 'primary' : 'none'}
        disabled={disabled}
        onClick={() => toggleOn(false)}
      />
    </ButtonGroup>
  );
};

export default SwitchOnOff;
