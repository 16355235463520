import { buildNodeData } from './info';
import { buildNodeStyle } from './style';

export default function ({ nodes, links, columns, numberOfColumns, columnKeys }, { width: minWidth }) {
  const structure = {
    columns: new Map(),
    prefixes: [],
    height: 0,
    width: 0,
    gutterWidth: 30,
    gutterHeight: 15,
    prefixColumnWidth: 0,
    prefixColumnHeight: -15
  };

  // 1. build up node data and style, "no positioning yet"
  // use loop to add prefix nodes to structure, define bounds of prefix column
  Object.values(nodes).forEach((node) => {
    node.info = buildNodeData(node);
    buildNodeStyle(node);

    if (node.type === 'prefix') {
      structure.prefixes.push(node);

      if (node.width > structure.prefixColumnWidth) {
        structure.prefixColumnWidth = node.width;
      }

      structure.prefixColumnHeight += node.height + structure.gutterHeight;
    }
  });

  // 2. add all other nodes to structure, define bounds of columns
  for (let i = 0; numberOfColumns > i; i++) {
    const ids = columns[i];

    for (let j = 0; ids.length > j; j++) {
      const node = nodes[ids[j]];

      if (!structure.columns.has(node.column)) {
        structure.columns.set(node.column, { width: 0, height: 0, nodes: [] });
      }

      const column = structure.columns.get(node.column);

      if (column.width < node.width) {
        column.width = node.width;
      }

      column.height += node.height + (column.height === 0 ? 0 : structure.gutterHeight);

      if (structure.height < column.height) {
        structure.height = column.height;
      }

      column.nodes.push(node.id);
    }
  }

  // 3. Final structure calculations

  // update structure height if prefix column is taller than the existing structure height
  if (structure.prefixColumnHeight > structure.height) {
    structure.height = structure.prefixColumnHeight;
  }

  // determine the total width of columns
  const totalWidthOfColumns = columnKeys.reduce((acc, curr) => {
    const column = structure.columns.get(curr);
    return acc + column.width;
  }, 0);

  // minWidth is passed in from the view
  const availableLinkWidth = minWidth - (totalWidthOfColumns + structure.prefixColumnWidth);
  // prefixes don't get put into a column, so the number of gutters we need will be the same as the number of columns
  const numberOfGutters = numberOfColumns;
  const minGutterWidth = numberOfGutters * structure.gutterWidth;

  // allow the gutters to expand to fill the view, if there is any room for it
  if (availableLinkWidth > minGutterWidth) {
    structure.gutterWidth = availableLinkWidth / numberOfGutters;
  }

  const totalGutterWidth = numberOfGutters * structure.gutterWidth;

  // finally we can determine the width of the structure
  structure.width = totalWidthOfColumns + totalGutterWidth + structure.prefixColumnWidth;

  return {
    ...structure,
    nodes,
    links,
    numberOfColumns,
    columnKeys
  };
}
