import React from 'react';
import { observer } from 'mobx-react';

import Switch from 'core/components/Switch';
import omitFieldProps from './util/omitFieldProps';

const getSwitchLabel = (
  field,
  switchLabel,
  showSwitchLabel,
  showOptionLabel,
  showFieldLabel,
  onLabel,
  offLabel,
  checked
) => {
  if (showSwitchLabel) {
    if (showOptionLabel) {
      const selectedOption = field.options.find((option) => option.value === !!checked);
      return selectedOption && selectedOption.label;
    }
    if (showFieldLabel) {
      return field.label;
    }
    return switchLabel || (checked ? onLabel : offLabel);
  }
  return undefined;
};

/*
  the 'inverse' prop is useful for fields labeled as enabling a condition that ultimately is stored as a 'disabled' state
  for example you have a field named 'disableEdit'
  when marked in the 'on' position (enabled), the resulting value will be disableEdit=false
  an alternate solution would be to choose better field names
*/
const Component = observer((props) => {
  const {
    field,
    onChange,
    className,
    switchLabel,
    showSwitchLabel = true,
    showOptionLabel = false,
    showFieldLabel = false,
    onLabel = 'On',
    offLabel = 'Off',
    inverse = false,
    ...restProps
  } = props;
  const checked = inverse ? !field.value : field.value;

  return (
    <Switch
      {...field.getProps()}
      {...omitFieldProps(restProps)}
      onChange={(value) => onChange(inverse ? !value : value)}
      className={className}
      checked={checked}
      label={getSwitchLabel(
        field,
        switchLabel,
        showSwitchLabel,
        showOptionLabel,
        showFieldLabel,
        onLabel,
        offLabel,
        checked
      )}
    />
  );
});

Component.displayName = 'Switch';

export default Component;
