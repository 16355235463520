import React from 'react';
import { observer } from 'mobx-react';

import { Flex, Box } from 'core/components';

import SelectedValuesPanel from './SelectedValuesPanel';

const ModalSelectValuesPanel = (props) => {
  const {
    field,
    reorderable,
    valuesPanelWidth,
    selectState,
    bottomTools: propsBottomTools,
    conditionalControls: propsConditionalControls
  } = props;

  // We need to wrap the tools, but only if they actually yield tools
  let bottomTools;
  if (propsBottomTools) {
    bottomTools = propsBottomTools(selectState.filterValue);
  }

  let conditionalControls;
  if (propsConditionalControls) {
    conditionalControls = propsConditionalControls();
  }

  return (
    <Flex
      flexDirection="column"
      flex={1}
      p="2px"
      width={valuesPanelWidth}
      minWidth={valuesPanelWidth}
      overflow="hidden"
    >
      <SelectedValuesPanel
        {...props}
        values={selectState.values}
        options={selectState.options}
        reorderable={field.reorderable || reorderable}
      />
      {bottomTools && <Box mt={2}>{bottomTools}</Box>}
      {conditionalControls && <Box mt={2}>{conditionalControls}</Box>}
    </Flex>
  );
};

export default observer(ModalSelectValuesPanel);
