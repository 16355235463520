import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Button, Card, Collapse, Icon, Box, Flex, ColorBlock, Tag } from 'core/components';

@observer
class SelectedValueButton extends Component {
  state = {
    isOpen: false
  };

  handleToggleOpen = (e) => {
    e.stopPropagation();

    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleRemove = () => {
    const { onRemove, option, field } = this.props;
    onRemove(option, field);
  };

  render() {
    const { option, style, onRemove, className, disabled, label: propLabel, icon: propIcon } = this.props;
    const { isOpen } = this.state;
    const { label: optionLabel, activeDevices = [], color, iconName: optionIcon } = option || {};
    const iconName = propIcon || optionIcon;
    const label = propLabel || optionLabel;

    const hasDevices = activeDevices && activeDevices.length > 0;
    const removeButton = onRemove && (
      <Button className={className} onClick={this.handleRemove} icon="cross" disabled={disabled} small minimal />
    );

    return (
      <Card mb={1} style={style}>
        <Flex p="4px" justifyContent="space-between" fontSize="small">
          <Flex alignItems="center" flex={1} pl="4px">
            <Box mr="6px">
              {color && <ColorBlock color={color} style={{ width: 14, height: 14, minHeight: 14 }} />}
              {!color && <Icon icon={iconName} color="muted" iconSize={14} />}
            </Box>
            {label}
          </Flex>

          {hasDevices && (
            <Button
              icon={isOpen ? 'caret-down' : 'caret-right'}
              onClick={this.handleToggleOpen}
              className={className}
              text={`${activeDevices.length} devices`}
              disabled={disabled}
              small
              minimal
            />
          )}

          {removeButton}
        </Flex>

        {hasDevices && (
          <Collapse isOpen={isOpen}>
            <Box style={{ padding: '0 8px 4px' }}>
              {activeDevices.map((device) => (
                <Tag minimal key={device.id} mr="4px" mb="4px">
                  {device.device_name}
                </Tag>
              ))}
            </Box>
          </Collapse>
        )}
      </Card>
    );
  }
}

export default SelectedValueButton;
