import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  AnchorButton,
  Box,
  Button,
  Callout,
  Card,
  ConfirmPopover,
  Dialog,
  EmptyState,
  Flex,
  FlexColumn,
  Icon,
  Link,
  LinkButton,
  Lookup,
  MultiSelect,
  Popover,
  Select,
  Suspense,
  Tag,
  Text
} from 'core/components';
import OttIcon from 'app/views/serviceProvider/ott/OttIcon';
import { ReactComponent as KentikIcon } from 'app/assets/logos/kentik-icon.svg';
import { ReactComponent as PeeringdbIcon } from 'app/assets/logos/peeringdb-icon.svg';

import { VirtualizedTable } from 'core/components/table';
import InterfaceGroupModel from 'app/stores/interfaceGroup/InterfaceGroupModel';
import InterfaceGroupSelector from 'app/components/interfaceGroup/InterfaceGroupSelector';
import { PopoverInteractionKind } from '@blueprintjs/core';
import { InterfaceMetadataTags, InterfaceNameDeviceNameAndDescription } from 'app/components/Interface';
import storeLoader from 'app/stores/storeLoader';
import { siteOptionsWithAddress } from 'app/stores/site/SiteOptions';

const groupSummaryLookup = ({ groupKey, group }) => ({
  renderer: () => (
    <Flex width="100%" justifyContent="space-between">
      <Flex alignItems="center" flex={8}>
        <PeeringdbIcon style={{ width: 15, height: 15 }} />
        <Text muted mx={1}>
          {groupKey}
        </Text>
        <Tag minimal round>
          {group.length}
        </Tag>
      </Flex>
      <Flex alignItems="center" flex={5}>
        <KentikIcon style={{ width: 15, height: 15 }} />
        <Text muted mx={1}>
          {groupKey.includes('Facilities') ? 'Sites' : 'Interfaces'}
        </Text>
      </Flex>
    </Flex>
  )
});

const InterfacesList = ({ interfaces }) =>
  interfaces.map((iface, idx) => (
    <Flex
      key={`${iface.snmp_id}|${iface.device_id}`}
      borderTop="thin"
      borderBottom={idx === interfaces.length - 1 ? 'thin' : undefined}
      alignItems="center"
      justifyContent="space-between"
      overflow="hidden"
      px={2}
      py={1}
      minHeight={48}
    >
      <Box fontSize="small">
        <InterfaceNameDeviceNameAndDescription {...iface} />
        <InterfaceMetadataTags {...iface} />
      </Box>
    </Flex>
  ));

@storeLoader('$sites')
@inject('$networkClass', '$peering')
@observer
export default class PeeringDbMapDialog extends Component {
  state = {
    loading: true,
    asnOptions: [],
    asnIncludes: [],
    options: { site: [], iface: [] },
    interfaceGroupSelectorOpen: undefined,
    interfaceGroup: undefined,
    selectedModel: undefined
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  options = {
    name: 'PeeringDB mapping'
  };

  componentDidMount() {
    const { $networkClass } = this.props;
    $networkClass.model.fetch().then(() => {
      const validAsns = [];
      $networkClass.model.internalASNs.forEach((asnStr) =>
        parseInt(asnStr) ? validAsns.push(parseInt(asnStr)) : undefined
      );
      const asnArr = Array.from(new Set(validAsns));
      const asnOptions = asnArr.map((asn) => ({ value: asn, label: <Lookup lookup="asNumbers" value={asn} /> }));
      this.fetchPeeringDbInfo(asnArr).then(({ collection, asnIncludes, options }) => {
        this.setState({
          loading: false,
          asnOptions,
          collection,
          asnIncludes,
          options
        });
      });
    });
  }

  fetchPeeringDbInfo(asnArr) {
    const { $peering } = this.props;
    this.setState({ loading: true });
    const options = { site: siteOptionsWithAddress.get() };
    return $peering
      .getPeeringdbMappingCollection(asnArr)
      .then((collection) => ({ collection, asnIncludes: asnArr, options }));
  }

  getToBeMapped() {
    const { collection } = this.state;
    return `To be mapped (${collection.toMapCount})`;
  }

  getAssigned() {
    const { collection } = this.state;
    return `Assigned (${collection.assignedCount} out of ${collection.unfilteredSize})`;
  }

  onAssignSite({ model, site_arr }) {
    model.set('kentik_mapped_id', site_arr);
  }

  onChooseInterfacesClick = (model) => {
    const interfaces = model.get('interfaces');
    const interfaceGroup = new InterfaceGroupModel({ interfaces, staticInterfaces: interfaces });

    this.setState({
      interfaceGroupSelectorOpen: true,
      selectedModel: model,
      interfaceGroup
    });
  };

  handleInterfaceGroupDialogClose = () => {
    this.setState({
      interfaceGroupSelectorOpen: false,
      selectedModel: undefined,
      interfaceGroup: undefined
    });
  };

  handleInterfaceGroupSave = (interfaceGroupModel) => {
    const { selectedModel } = this.state;
    const interfaces = interfaceGroupModel.get('staticInterfaces');
    selectedModel.set({
      kentik_mapped_id: interfaces.map((iface) => `${iface.device_id}$${iface.snmp_id}`),
      interfaces
    });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  get fields() {
    const { asnOptions } = this.state;

    return {
      asnIncludes: { options: asnOptions }
    };
  }

  siteValueRenderer = (option) => option.name;

  get columns() {
    const { options } = this.state;
    const hasPeeringDb = [
      {
        label: this.getToBeMapped(),
        name: 'name',
        sortable: false,
        flexBasis: 40
      },
      {
        label: '',
        name: 'location',
        flexBasis: 40,
        sortable: false,
        ellipsis: false,
        renderer: ({ model }) => {
          const { address1, city, country } = model.get();
          return (
            <Flex flex={1} alignItems="center" justifyContent="space-between">
              <Box>
                <Text as={Box}>{`${city}, ${country}`}</Text>
                <Text small muted>
                  {address1}
                </Text>
              </Box>
              <Icon icon="arrow-right" />
            </Flex>
          );
        }
      },
      {
        label: this.getAssigned(),
        name: 'kentik_mapped_id',
        flexBasis: 50,
        sortable: false,
        ellipsis: false,
        renderer: ({ model }) => {
          const {
            category,
            kentik_mapped_id = [],
            interfaces,
            sites,
            key
          } = model.get(['category', 'kentik_mapped_id', 'interfaces', 'sites', 'key']);
          const hasInterfacesMapped = kentik_mapped_id && Array.isArray(kentik_mapped_id) && kentik_mapped_id.length;

          if (category === 'Facilities') {
            return (
              <Select
                key={key}
                options={options.site}
                values={kentik_mapped_id}
                valueRenderer={this.siteValueRenderer}
                placeholder="Choose Site(s)"
                onChange={(site_arr) => this.onAssignSite({ model, site_arr })}
                showFilter
                width={320}
                height={30}
                menuWidth={320}
                multi
              />
            );
          }

          if (category === 'Exchanges') {
            const interfaceDialogButton = (
              <Button
                key={key}
                width={135}
                text={
                  hasInterfacesMapped
                    ? `${kentik_mapped_id.length} Interface${kentik_mapped_id.length > 1 ? 's' : ''}`
                    : 'Select Interfaces'
                }
                onClick={() => this.onChooseInterfacesClick(model)}
              />
            );
            if (hasInterfacesMapped) {
              return (
                <Popover
                  usePortal
                  modifiers={{
                    offset: { enabled: false },
                    flip: { enabled: false },
                    preventOverflow: { enabled: true, boundariesElement: 'window' }
                  }}
                  interactionKind={PopoverInteractionKind.HOVER}
                  position="right"
                  minimal={false}
                  content={<InterfacesList interfaces={interfaces} />}
                >
                  {interfaceDialogButton}
                </Popover>
              );
            }
            return interfaceDialogButton;
          }

          // Other Mappings
          if (category.includes('Facilities')) {
            return sites.map((site) => (
              <Tag minimal round key={`${key}-${site.id}`}>
                {site.title}
              </Tag>
            ));
          }

          return (
            <Popover
              usePortal
              modifiers={{
                offset: { enabled: false },
                flip: { enabled: false },
                preventOverflow: { enabled: true, boundariesElement: 'window' }
              }}
              interactionKind={PopoverInteractionKind.HOVER}
              position="right"
              minimal={false}
              content={<InterfacesList interfaces={interfaces} />}
            >
              <Tag minimal round key={key}>
                {interfaces.length} Interface{interfaces.length > 1 ? 's' : ''}
              </Tag>
            </Popover>
          );
        }
      }
    ];
    return hasPeeringDb;
  }

  onAsnChange(arr) {
    this.fetchPeeringDbInfo(arr).then(({ collection, asnIncludes }) => {
      this.setState({
        loading: false,
        collection,
        asnIncludes
      });
    });
  }

  handleSave = () => {
    const { $peering, onClose } = this.props;
    const { collection } = this.state;
    $peering.setPeeringdbMappings(collection);
    onClose();
  };

  handleClearAll = () => {
    const { $peering, onClose } = this.props;
    $peering.clearAllMappings();
    onClose();
  };

  render() {
    const { onClose } = this.props;
    const { loading, collection, asnOptions, asnIncludes, interfaceGroup, interfaceGroupSelectorOpen } = this.state;

    const hasASNs = asnOptions.length > 0;
    const hasOptions = collection?.hasMappingOptions;

    return (
      <Dialog
        isOpen
        title="Integrate PeeringDB"
        canOutsideClickClose={false}
        onClose={onClose}
        top={40}
        width={950}
        height={866}
        position="fixed"
      >
        <Dialog.Body display="flex" flexDirection="column">
          <FlexColumn flex={1}>
            <FlexColumn justifyContent="space-between" mb={1} flex={1} maxHeight={156}>
              <Flex alignItems="center">
                <Box mx={2}>
                  <OttIcon type="integration" value="peeringdb" width="auto" height={40} useAlternateDarkLogo />
                </Box>

                <FlexColumn>
                  <Text muted pb={1}>
                    Based on the existing Internal ASNs configured by your Network Classification, below is the list of
                    Facilities and Internet Exchanges on PeeringDB that can be mapped to Kentik Sites and Interfaces.
                  </Text>
                  <Callout intent="primary" alignItems="center" justifyItems="center">
                    <Box>
                      <Text>
                        PeeringDB Mappings can alternatively be created from the Settings pages for
                        <Link to="/v4/settings/sites"> Sites </Link>and<Link to="/v4/settings/sites"> Interfaces </Link>
                        respectively. Mappings created this way will show as read-only entries in the table below.
                      </Text>
                    </Box>
                  </Callout>
                </FlexColumn>
              </Flex>
              <Flex alignItems="center">
                <Text px={1}>PeeringDB ASN lookup:</Text>
                <Box flex={1}>
                  <MultiSelect
                    fill
                    placeholder={hasASNs ? 'Select ASNs...' : 'No ASNs Found'}
                    options={asnOptions}
                    values={asnIncludes}
                    onChange={(arr) => this.onAsnChange(arr)}
                    showFilter
                    keepOpen
                  />
                </Box>
              </Flex>
            </FlexColumn>

            <Suspense loading={loading}>
              {hasASNs && (
                <Card height={588} borderRadius="4px" pt={1} display="flex" flexDirection="column">
                  <VirtualizedTable
                    collection={collection}
                    rowHeight={40}
                    rowKeyField="key"
                    columns={this.columns}
                    selectOnRowClick={false}
                    groupSummaryLookup={groupSummaryLookup}
                    collapsedGroups={hasOptions ? ['Custom - Facilities', 'Custom - Exchanges'] : undefined}
                    flexed
                    stickyHeader
                    emptyState={
                      <EmptyState
                        title="No Results found in PeeringDB"
                        icon="inbox"
                        description="Mapping can still be added manually from the Sites and Interfaces settings"
                      />
                    }
                  />
                </Card>
              )}

              {!hasASNs && (
                <Callout intent="primary" p={2}>
                  <FlexColumn>
                    <Text muted pb={2}>
                      We haven’t found any Internal ASN(s) listed in your Network Classification Settings. Once you set
                      up your Network Classification, Kentik will query PeeringDB for Facilities and Internet Exchanges
                      that are associated with your Internal ASN(s).
                    </Text>
                    <Box>
                      <LinkButton
                        mt={2}
                        intent="primary"
                        large
                        to="/v4/settings/network-classification"
                        text="Set Up Network Classification"
                      />
                    </Box>
                  </FlexColumn>
                </Callout>
              )}
            </Suspense>
          </FlexColumn>
        </Dialog.Body>

        <Dialog.Footer>
          <Flex justifyContent="space-between" flex={1}>
            <ConfirmPopover
              onConfirm={() => this.handleClearAll()}
              confirmBodyContent={
                <Box maxWidth={200}>
                  <Text>Are you sure you want to remove all PeeringDB mappings for this Company?</Text>
                </Box>
              }
            >
              <Button text="Remove All Mappings" intent="danger" minimal minWidth={80} />
            </ConfirmPopover>
            <Flex>
              <Button onClick={onClose} text="Cancel" mr={1} minWidth={110} />
              <AnchorButton text="Save" disabled={!hasASNs} intent="primary" onClick={this.handleSave} minWidth={110} />
            </Flex>
          </Flex>
        </Dialog.Footer>
        {interfaceGroup && (
          <InterfaceGroupSelector
            title="Select Exchange Interfaces"
            isOpen={interfaceGroupSelectorOpen}
            interfaceGroup={interfaceGroup}
            moduleName="Exchange"
            onInterfaceGroupSave={this.handleInterfaceGroupSave}
            onInterfaceGroupDialogClose={this.handleInterfaceGroupDialogClose}
            staticOnly
          />
        )}
      </Dialog>
    );
  }
}
