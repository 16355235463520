import React from 'react';
import styled from 'styled-components';
import { space, width, display } from 'styled-system';
import { FileInput as BlueprintFileInput } from '@blueprintjs/core';
import omitFieldProps from './util/omitFieldProps';

const FileInput = styled(BlueprintFileInput)`
  ${display};
  ${space};
  ${width};
`;

const Component = ({ small, ...rest }) => <FileInput {...omitFieldProps(rest)} />;

Component.displayName = 'FileInput';

export default Component;
