import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('PacketSizeAggregate'))
class PacketSizeAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="Packet Sizes"
        resultsKey="Packet Size"
        insightsQuery="fetchPacketSizeInsights"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'Packet Size',
          metric: ['sampledpktsize']
        }}
        chartProps={{
          metrics: {
            Internal: 'sampledpktsize',
            Inbound: 'sampledpktsize',
            Outbound: 'sampledpktsize'
          }
        }}
      />
    );
  }
}

export default PacketSizeAggregate;
