import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Box, Link, Text } from 'core/components';
import { RemoveMenuItem } from 'core/form';

@inject('$dashboard', '$dashboards', '$mkp')
@observer
class DashboardAddPanel extends Component {
  state = {
    confirmBodyContent: null
  };

  componentDidMount() {
    const { $dashboard, $dashboards, $mkp } = this.props;
    const tenantUserCount = $mkp.countDashboardUsers($dashboard.dashboard.id);
    $dashboard.dashboard.fetchDependentsAndDependencies().then(() => {
      const dependents = $dashboard.dashboard.get('dependents');
      let confirmBodyContent = null;

      if (dependents.length || tenantUserCount) {
        confirmBodyContent = (
          <>
            {dependents.length > 0 && (
              <Box>
                The following views link to the view you are trying to delete.
                <Box py={1}>
                  {dependents.map((dependent) => (
                    <>
                      <Link to={$dashboards.collection.get(dependent.id).navigatePath}>{dependent.dash_title}</Link>
                      <br />
                    </>
                  ))}
                </Box>
                <Text>Removing the view will prevent those views from navigating to it.</Text>
              </Box>
            )}
            {tenantUserCount > 0 && (
              <Box>
                <Text>This view is used by {tenantUserCount} Tenant(s)</Text>
              </Box>
            )}
            <Box mt={1}>Are you sure you want to continue?</Box>
          </>
        );
      }

      this.setState({ confirmBodyContent });
    });
  }

  render() {
    const { $dashboard, onDashboardRemove } = this.props;
    const { confirmBodyContent } = this.state;

    if (!$dashboard.dashboard.canEdit) {
      return null;
    }

    return (
      <RemoveMenuItem
        model={$dashboard.dashboard}
        entityName="view"
        confirmText={confirmBodyContent}
        onRemove={onDashboardRemove}
      />
    );
  }
}

export default DashboardAddPanel;
