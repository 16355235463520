export const colorNames = [
  'black',
  'blue1',
  'blue2',
  'blue3',
  'blue4',
  'blue5',
  'darkGray1',
  'darkGray2',
  'darkGray3',
  'darkGray4',
  'darkGray5',
  'forest1',
  'forest2',
  'forest3',
  'forest4',
  'forest5',
  'gold1',
  'gold2',
  'gold3',
  'gold4',
  'gold5',
  'gray1',
  'gray2',
  'gray3',
  'gray4',
  'gray5',
  'green1',
  'green2',
  'green3',
  'green4',
  'green5',
  'indigo1',
  'indigo2',
  'indigo3',
  'indigo4',
  'indigo5',
  'lightGray1',
  'lightGray2',
  'lightGray3',
  'lightGray4',
  'lightGray5',
  'lime1',
  'lime2',
  'lime3',
  'lime4',
  'lime5',
  'orange1',
  'orange2',
  'orange3',
  'orange4',
  'orange5',
  'red1',
  'red2',
  'red3',
  'red4',
  'red5',
  'rose1',
  'rose2',
  'rose3',
  'rose4',
  'rose5',
  'sepia1',
  'sepia2',
  'sepia3',
  'sepia4',
  'sepia5',
  'turquoise1',
  'turquoise2',
  'turquoise3',
  'turquoise4',
  'turquoise5',
  'vermilion1',
  'vermilion2',
  'vermilion3',
  'vermilion4',
  'vermilion5',
  'violet1',
  'violet2',
  'violet3',
  'violet4',
  'violet5',
  'white',
  'yellow'
];

// spacing grid
export const space = [0, 8, 16, 24, 32, 64, 128, 256, 512];

export const bracketingColors = ['#3a80eb', '#0f9960', '#ff850e', '#d65b4a', '#a82a2b'];
