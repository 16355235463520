import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { capitalize, debounce } from 'lodash';

import { Box, Button, Flex, Link, Text } from 'core/components';
import $app from 'app/stores/$app';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import {
  applyFilterGroupsToFbdQuery,
  applyFiltersToFbdQuery,
  getInternalFilters
} from 'app/components/filters/simple/simpleFilterUtils';

import { addFilters } from 'app/stores/query/FilterUtils';
import $rbac from 'app/stores/rbac/$rbac';
import Tabs from './Tabs';

function applyTotalFilters(query, filters, filterGroups) {
  if (!query.filters) {
    query.filters = {
      connector: 'All',
      filterGroups: [
        ...filterGroups,
        {
          connector: 'All',
          not: false,
          named: false,
          name: '',
          filterGroups: [],
          autoAdded: true,
          filters: getInternalFilters(filters)
        }
      ]
    };
  } else {
    query.filters.filterGroups.push({
      connector: 'All',
      filters: getInternalFilters(filters),
      not: false,
      named: false,
      name: '',
      filterGroups,
      autoAdded: true
    });
  }
}

function getTabQuery({
  $query,
  queryName,
  overrides,
  filters,
  simpleFilters = [],
  simpleFilterGroups = [],
  immutableSimpleFilters = [],
  metrics,
  tabName,
  topxMetrics,
  topxMetricField
}) {
  const tabQuery = $query.get(queryName, overrides);
  if (filters && filters.length > 0) {
    addFilters(tabQuery, filters);
  }

  if (simpleFilters) {
    applyFiltersToFbdQuery(tabQuery, simpleFilters);
  }
  if (immutableSimpleFilters) {
    applyFiltersToFbdQuery(tabQuery, immutableSimpleFilters);
  }
  if (simpleFilterGroups) {
    applyFilterGroupsToFbdQuery(tabQuery, simpleFilterGroups);
  }

  tabQuery.metric = metrics[tabName] || 'Traffic';
  if (tabName === 'Total') {
    if (!$app.isSubtenant && queryName !== 'interfaceIngressEgressQuery') {
      tabQuery.filterDimensionsEnabled = false;
      tabQuery.filterDimensionSort = false;
      tabQuery.metric = ['simple_trf_prof'];
    }
  } else {
    tabQuery.filterDimensionsEnabled = false;
    tabQuery.filterDimensionSort = false;
  }

  if (tabName === 'Ingress' || tabName === 'Egress') {
    tabQuery.filterDimensionsEnabled = true;
    tabQuery.filterDimensionSort = true;
    tabQuery.show_total_overlay = false;
  }

  if (tabName === 'Inbound' || tabName === 'Ingress') {
    $query.promoteFbdFilterGroup(tabQuery, 'Inbound', 'Ingress');
  } else if (tabName === 'Outbound' || tabName === 'Egress') {
    $query.promoteFbdFilterGroup(tabQuery, 'Outbound', 'Egress');
  } else if (tabName === 'Internal') {
    if (topxMetricField) {
      // If there's a topxMetricField but no topxMetrics (yet), we don't query (yet)
      if (!topxMetrics) {
        return null;
      }

      tabQuery.aggregateFiltersEnabled = true;
      tabQuery.aggregateFilters = [
        {
          name: 'Internal',
          named: true,
          connector: 'All',
          not: false,
          autoAdded: '',
          metric: metrics[tabName],
          filters: $query.getFilterDimensionGroupFilters(tabQuery, 'Internal')
        }
      ];

      addFilters(
        tabQuery,
        topxMetrics.Internal.map((filterValue) => ({
          filterField: topxMetricField,
          operator: '=',
          filterValue
        })),
        'Any'
      );
    } else {
      $query.promoteFbdFilterGroup(tabQuery, 'Internal');
    }
  } else if (tabName === 'Through') {
    $query.promoteFbdFilterGroup(tabQuery, 'Through');
  } else if (tabName === 'Other') {
    $query.promoteFbdFilterGroup(tabQuery, 'Other');
  } else if (simpleFilters.length > 0 || immutableSimpleFilters.length > 0 || simpleFilterGroups.length > 0) {
    applyTotalFilters(tabQuery, [...simpleFilters, ...immutableSimpleFilters], simpleFilterGroups);
  }

  return tabQuery;
}

@inject('$colors', '$explorer', '$query')
@observer
class TabbedChart extends Component {
  static defaultProps = {
    metrics: {},
    queryName: 'inboundOutboundInternalQuery',
    height: 250,
    viewInExplorer: true,
    simpleFilters: [],
    simpleFilterGroups: [],
    immutableSimpleFilters: [],
    zoomEnabled: false
  };

  static getDerivedStateFromProps(props, state) {
    const {
      $query,
      queryName,
      overrides,
      filters,
      simpleFilters,
      simpleFilterGroups,
      immutableSimpleFilters,
      metrics,
      topxMetrics,
      topxMetricField
    } = props;
    let { tabName } = state;

    if (
      !tabName ||
      queryName !== state.queryName ||
      overrides !== state.overrides ||
      filters !== state.filters ||
      topxMetrics !== state.topxMetrics ||
      immutableSimpleFilters !== state.immutableSimpleFilters
    ) {
      const query = $query.get(queryName, overrides);
      if (filters && filters.length > 0) {
        addFilters(query, filters);
      }
      if (simpleFilters && simpleFilters.length > 0) {
        applyFiltersToFbdQuery(query, simpleFilters);
      }
      if (immutableSimpleFilters && immutableSimpleFilters.length > 0) {
        applyFiltersToFbdQuery(query, immutableSimpleFilters);
      }
      if (simpleFilterGroups && simpleFilterGroups.length > 0) {
        applyFilterGroupsToFbdQuery(query, simpleFilterGroups);
      }
      if (simpleFilters.length > 0 || immutableSimpleFilters.length > 0 || simpleFilterGroups.length > 0) {
        applyTotalFilters(query, [...simpleFilters, ...immutableSimpleFilters], simpleFilterGroups);
      }

      tabName = tabName || 'Total';

      const tabQuery = getTabQuery({
        $query,
        queryName,
        overrides,
        filters,
        simpleFilters,
        simpleFilterGroups,
        immutableSimpleFilters,
        metrics,
        tabName,
        topxMetrics,
        topxMetricField
      });

      return {
        tabName,
        tabQuery,
        query,
        queryName,
        overrides,
        filters,
        topxMetrics,
        immutableSimpleFilters,
        dataview: null
      };
    }

    return null;
  }

  state = {};

  componentDidUpdate(prevProps) {
    const { height, availableWidth } = this.props;

    if (prevProps.height !== height || prevProps.availableWidth !== availableWidth) {
      this.reflow();
    }
  }

  reflow = debounce(() => {
    const { dataview } = this.state;

    if (dataview) {
      dataview.reflow();
    }
  }, 250);

  handleTabChange = (tabName) => {
    const {
      $query,
      queryName,
      overrides,
      filters,
      metrics,
      topxMetrics,
      topxMetricField,
      simpleFilters,
      simpleFilterGroups,
      immutableSimpleFilters
    } = this.props;

    const tabQuery = getTabQuery({
      $query,
      queryName,
      overrides,
      filters,
      metrics,
      tabName,
      topxMetrics,
      topxMetricField,
      simpleFilters,
      simpleFilterGroups,
      immutableSimpleFilters
    });

    this.setState({ tabName, tabQuery });
  };

  handleOpenInExplorer = () => {
    const { $explorer } = this.props;
    const { tabQuery } = this.state;
    $explorer.navigateToExplorer(tabQuery);
  };

  handleDataViewCreate = (dataview) => {
    this.setState({ dataview });
  };

  render() {
    const {
      $colors,
      children,
      height,
      metrics,
      queryName,
      showNativeLegend,
      viewInExplorer,
      viewProps /* , noTrend */,
      zoomEnabled
    } = this.props;
    const { query, tabName, tabQuery } = this.state;

    const tabNames = query.filterDimensions.filterGroups.map((group) => group.name);

    if (!$app.isSubtenant && queryName !== 'interfaceIngressEgressQuery') {
      // force top level query for tab values (query) to not use FBD ('total' tabQuery already taken care of)
      query.filterDimensionsEnabled = false;
      query.filterDimensionSort = false;
      query.metric = ['simple_trf_prof'];
    }

    return (
      <Box>
        <Flex>
          <LightweightDataViewWrapper query={query}>
            {({ results, queryModel }) => (
              <Tabs
                data={results.getRawData(true)}
                aggregatePrefix={results.prefix}
                queryModel={queryModel}
                queryResults={results}
                viewProps={{ ...viewProps }}
                onTabChange={this.handleTabChange}
                noTrend //= {noTrend} override prop and just turn off for now
              />
            )}
          </LightweightDataViewWrapper>
        </Flex>
        <Box position="relative" width="100%" p={2} pb={0} minHeight={height}>
          {tabQuery && (
            <DataViewWrapper
              query={tabQuery}
              onDataViewCreate={this.handleDataViewCreate}
              viewProps={{
                height,
                ...viewProps,
                colors: $colors.getAllTabbedChartColors(tabName, tabNames, !!metrics[tabName]),
                getColorIndex: $colors.getTabColorIndex,
                showNativeLegend: showNativeLegend && tabName !== 'Total',
                showAxisLabels: false,
                chartConfig: {
                  chart: zoomEnabled ? {} : { zoomType: '' },
                  tooltip: {
                    seriesNameFormatter: (value) => capitalize(value)
                  }
                }
              }}
              allowCache
            />
          )}
        </Box>
        <Flex alignItems="center" justifyContent="flex-end" p={1} pt={0}>
          {tabName === 'Other' && (
            <Text flex={1} muted small>
              Other designates traffic that is neither outbound, inbound, through or internal. This is usually a result
              of a low{' '}
              <Link
                to={
                  $rbac.hasUnrestrictedPermissions(['device::read'])
                    ? 'v4/settings/interface-classification'
                    : 'v4/settings/interfaces'
                }
              >
                interface classification
              </Link>{' '}
              rate.
            </Text>
          )}
          {viewInExplorer && !$app.isExport && !$app.isSubtenant && (
            <Button
              icon="series-search"
              text="View in Data Explorer"
              onClick={this.handleOpenInExplorer}
              minimal
              small
            />
          )}
          {children}
        </Flex>
      </Box>
    );
  }
}

export default TabbedChart;
