import React from 'react';
import { PopoverInteractionKind } from '@blueprintjs/core';
import { HEALTH } from 'shared/synthetics/constants';
import { Box, Button, Flex, Heading, Icon, Popover, Tag, Text } from 'core/components';
import { FiInfo } from 'react-icons/fi';
import PopoverDot from 'app/views/synthetics/components/mesh/PopoverDot';

const tagProps = {
  minimal: true,
  fontSize: 'small',
  fontWeight: 'bold',
  mr: 2,
  p: '0 8px',
  minWidth: 50,
  textAlign: 'center'
};

export default ({ theme }) => (
  <Popover
    position="top-right"
    minimal={false}
    interactionKind={PopoverInteractionKind.HOVER}
    content={
      <Box p={2} maxWidth={400}>
        <Heading level={6}>No Data</Heading>
        <Flex alignItems="start" mb={2}>
          <Tag {...tagProps}>---</Tag>
          <Text as="div">The host was not tested during the selected time frame</Text>
        </Flex>
        <Heading level={6}>No Health (Aggregate, HTTP Metrics)</Heading>
        <Flex alignItems="start" mb={2}>
          <Tag {...tagProps}>100%</Tag>
          <div>
            We do not show health threshold colors for:
            <ul style={{ paddingLeft: 24, margin: 0 }}>
              <li>Aggregated metrics</li>
              <li>Blocked agent pings</li>
              <li>Metrics excluded from health thresholds</li>
            </ul>
          </div>
        </Flex>
        <Heading level={6}>Healthy, Warning, Critical (Raw Data)</Heading>
        <Flex alignItems="start">
          <Flex mr={2}>
            <PopoverDot health={HEALTH.HEALTHY} theme={theme} />
            <PopoverDot health={HEALTH.WARNING} theme={theme} />
            <PopoverDot health={HEALTH.CRITICAL} theme={theme} />
          </Flex>
          <Text as="div">Metrics are colored green, yellow, or red based on health thresholds for this test</Text>
        </Flex>
      </Box>
    }
  >
    <Button minimal small>
      <Flex alignItems="center">
        <span>Legend</span>
        <Icon iconSize={14} icon={FiInfo} color="primary" ml="4px" />
      </Flex>
    </Button>
  </Popover>
);
