import { Collection } from 'core/model';
import { uniqBy } from 'lodash';
import { computed } from 'mobx';
import PeeringNetworkModel from './PeeringNetworkModel';

export default class PeeringNetworkCollection extends Collection {
  get defaultSortState() {
    return { field: 'name', direction: 'asc' };
  }

  get model() {
    return PeeringNetworkModel;
  }

  @computed
  get peers() {
    return uniqBy(this.unfiltered, (model) => model.get('asn'));
  }

  @computed
  get openPeers() {
    return this.peers.filter((model) => model.get('policy_general') === 'Open');
  }

  @computed
  get withIPv6() {
    return this.unfiltered.filter((model) => !!model.get('ipaddr6'));
  }

  @computed
  get totalSpeed() {
    return this.unfiltered.reduce((sum, model) => sum + (model.get('speed') || 0), 0);
  }
}
