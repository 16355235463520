import { InternalFilters, InboundFilters, OutboundFilters } from './cloudInboundOutboundQuery';

export default {
  aggregateTypes: ['p95th_bits_per_sec'],
  topx: 0,
  lookback_seconds: 86400,
  all_devices: false, // default - restricted to cloud providers via device_types.
  device_types: [],
  show_overlay: false,
  show_total_overlay: false,
  aggregateFiltersEnabled: true,
  aggregateFilters: [
    {
      name: 'Internal',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      saved_filters: [],
      ...InternalFilters,
      filterGroups: []
    },
    {
      name: 'Inbound',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      saved_filters: [],
      ...InboundFilters,
      filterGroups: []
    },
    {
      name: 'Outbound',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      saved_filters: [],
      ...OutboundFilters,
      filterGroups: []
    }
  ]
};
