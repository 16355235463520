import React, { Component } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import Forbidden from 'app/views/Forbidden';
import RbacDeniedPermission from 'app/views/RbacDeniedPermission';
import { LOGIN } from '@kentik/ui-shared/paths/login';

/* eslint-disable react/no-multi-comp */
const loginRedirect = ({ location }) => (
  <Redirect
    to={{
      pathname: '/login',
      state: { from: location }
    }}
  />
);

const getReasonState = (location) => {
  const isSpoofing = location.pathname.includes('/spoof/');
  let reason;
  if (isSpoofing) {
    const params = new URLSearchParams(location.search);
    reason = params.get('reason');
  }
  return {
    requireReason: isSpoofing && !reason,
    reason
  };
};

const totpRedirect = ({ location }) => {
  const { requireReason, reason } = getReasonState(location);
  const target_cid = location?.pathname?.match('/v4/spoof/(?<cid>[0-9]+)/')?.groups?.cid;
  return (
    <Redirect
      to={{
        pathname: LOGIN.TWO_FACTOR,
        state: { from: location, requireReason, reason, target_cid }
      }}
    />
  );
};

const expiredRedirect = () => <Redirect to="/expired-trial" />;
const inactiveRedirect = () => <Redirect to="/inactive-company" />;
const suspendedRedirect = () => <Redirect to="/suspended-company" />;
const passwordExpiredRedirect = () => <Redirect to={LOGIN.PASSWORD_RECOVERY} />;
const defaultRedirect = () => <Redirect to="/" />;

@inject('$auth', '$app')
@withRouter
@observer
class ProtectedRoute extends Component {
  static defaultProps = {
    overrideForSudo: true,
    overrideForSpoof: false
  };

  render() {
    const {
      $app,
      $auth,
      skip2fa,
      force2fa,
      location,
      permission,
      rbacPermissions,
      isNegativePermission,
      forbiddenComponent,
      overrideForSudo,
      overrideForSpoof,
      fallback,
      fallbackComponent,
      ...rest
    } = this.props;
    const verified2fa = location && location.state && location.state.verified2fa;

    let redirect;

    if (!$auth.authenticated && !$app.isSharedLink) {
      redirect = loginRedirect;
    } else if ($auth.isExpiredPassword) {
      redirect = passwordExpiredRedirect;
    } else if (!$auth.isSharedUser && (($auth.twoFactorVerifyRequired && !skip2fa) || (force2fa && !verified2fa))) {
      redirect = totpRedirect;
    } else if (
      $auth.isSuspendedCompany &&
      location.pathname !== '/suspended-company' &&
      location.pathname !== LOGIN.TWO_FACTOR
    ) {
      redirect = suspendedRedirect;
    } else if (
      $auth.isExpiredTrial &&
      location.pathname !== '/expired-trial' &&
      location.pathname !== LOGIN.TWO_FACTOR
    ) {
      redirect = expiredRedirect;
    } else if (
      $auth.isInactiveCompany &&
      location.pathname !== '/inactive-company' &&
      location.pathname !== LOGIN.TWO_FACTOR
    ) {
      redirect = inactiveRedirect;
    } else if (
      (!$auth.isExpiredTrial && location.pathname === '/expired-trial') ||
      (!$auth.isInactiveCompany && location.pathname === '/inactive-company') ||
      (!$auth.isSuspendedCompany && location.pathname === '/suspended-company')
    ) {
      redirect = defaultRedirect;
    }
    if (redirect) {
      return <Route location={location} render={redirect} />;
    }

    // for now, same outcome as the following `else/if` statement
    // keeping separate so it's more clear this is RBAC-related
    if (rbacPermissions?.length > 0 && !$auth.hasRbacPermissions(rbacPermissions)) {
      return <Route location={location} render={() => <RbacDeniedPermission permissions={rbacPermissions} />} />;
    }
    // !isInPermission && !checkFalsy) || (isInPermission && checkFalsy)
    if (
      (permission &&
        !$auth.hasPermission(permission, { overrideForSudo, overrideForSpoof }) &&
        !isNegativePermission) ||
      ($auth.hasPermission(permission, { overrideForSudo, overrideForSpoof }) && isNegativePermission)
    ) {
      return <Route location={forbiddenComponent ? location : '*'} component={forbiddenComponent || Forbidden} />;
    }

    if (fallback && fallbackComponent) {
      // for cases where we have a condition outside of a permission that we want to fallback to a different component, likely some sort of marketing view
      return <Route location={fallbackComponent ? location : '*'} component={fallbackComponent} />;
    }

    return <Route location={location} {...rest} />;
  }
}

export default ProtectedRoute;
