import React from 'react';
import { Classes, Collapse } from '@blueprintjs/core';
import classNames from 'classnames';

import ModalOptionGroup from './ModalOptionGroup';
import { getClassNameFromGroup } from './selectHelpers';
import ModalOptionGroupTitle from './ModalOptionGroupTitle';

const ModalOptionGroups = (props) => {
  const { options, group, values, disabled, multi, disabledValues, collapsedState, handleSelectGroup } = props;
  const collapsed = collapsedState[group];
  const onTitleClick = multi ? () => handleSelectGroup(options) : undefined;

  if (Array.isArray(options)) {
    return (
      <ModalOptionGroup
        {...props}
        className={getClassNameFromGroup(group)}
        group={group}
        key={group}
        onTitleClick={onTitleClick}
        options={options}
        values={values}
        disabled={disabled}
        collapsed={collapsed}
      />
    );
  }

  const optionsMarkup = (
    <div className="modal-options">
      {Object.keys(options).map((groupName) => (
        <ModalOptionGroups
          key={groupName}
          {...props}
          options={options[groupName]}
          group={groupName}
          values={values}
          disabled={disabled || disabledValues.includes(groupName)}
        />
      ))}
    </div>
  );

  if (group) {
    return (
      <div key={group} className={classNames(Classes.CARD, 'modal-options-parent-group')}>
        <ModalOptionGroupTitle
          {...props}
          group={group}
          onTitleClick={onTitleClick}
          collapsed={collapsed}
          disabled={disabled}
        />
        <Collapse isOpen={!collapsed}>{optionsMarkup}</Collapse>
      </div>
    );
  }

  return optionsMarkup;
};

ModalOptionGroups.defaultProps = {
  options: [],
  group: '',
  disabledValues: []
};

export default ModalOptionGroups;
