import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, Heading, Icon, Tab, Tabs, Text } from 'core/components';
import styled from 'styled-components';
import { Classes } from '@blueprintjs/core';
import { rgba } from 'polished';
import { sortBy } from 'lodash';
import TabResultsTable from './TabResultsTable';
import { defaultTabWhitelist } from './tabLists';

const TabsWrapper = styled.div`
  .${Classes.TAB_LIST} {
    border: ${({ theme }) => theme.borders.thinLighter};
    border-radius: 5px;

    > .${Classes.TAB} {
      color: ${({ theme }) => theme.colors.body};
      padding: 3px 16px;

      .${Classes.ICON} {
        color: transparent;
      }

      &:hover {
        .${Classes.ICON} {
          color ${({ theme }) => theme.colors.muted};
        }
      }

      &[aria-selected='true'] {
        font-weight: 600;

        .${Classes.ICON} {
          color: ${({ theme }) => theme.colors.body};
        }
      }
    }

    > .${Classes.TAB_INDICATOR_WRAPPER} {
      > .${Classes.TAB_INDICATOR} {
        background-color: ${({ theme }) => rgba(theme.colors.gray3, 0.15)};
      }
    }
  }
`;

function filterTabs(tabs, whitelist, blacklist, { hasGCEDevice, hasAzureDevice, hasAWSDevice }) {
  const filteredTabs = tabs.filter(
    (tab) =>
      tab &&
      tab.id &&
      whitelist.includes(tab.id) &&
      !blacklist.includes(tab.id) &&
      !(
        (!hasAzureDevice && tab.id.startsWith('az_')) ||
        (!hasGCEDevice && tab.id.startsWith('gce_')) ||
        (!hasAWSDevice && tab.id.startsWith('aws_'))
      )
  );

  return sortBy(filteredTabs, (tab) => (tab.title === 'ASNs' ? 'AAA' : tab.title.toUpperCase()));
}

@inject('$app', '$devices', '$exports', '$tabs')
@observer
class VerticalDetailTabs extends Component {
  state = {
    activeTab: undefined,
    tabs: []
  };

  constructor(props) {
    super(props);
    const { $devices, $tabs, whitelist, blacklist, defaultTab, cloud } = props;

    const allTabs = Object.values($tabs.getTabs(cloud));
    const allowableTabs = filterTabs(allTabs, whitelist || defaultTabWhitelist, blacklist || [], $devices);

    this.state.tabs = allowableTabs.map((tab) => ({ ...tab, value: tab.id, label: tab.title }));
    this.state.activeTab = defaultTab || allowableTabs[0].id;
  }

  componentDidMount() {
    const { $exports } = this.props;

    $exports.getSettings().then(({ activeTab }) => {
      if (activeTab) {
        this.setState({
          activeTab
        });
      }
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleTabChange = (tab) => {
    const { $exports } = this.props;

    this.setState({ activeTab: tab });
    $exports.setHash({ activeTab: tab, sortResultsBy: undefined, sortResultsDir: undefined });
  };

  render() {
    const { queryOverrides, simpleFilters, simpleFilterGroups, $app, ...tableProps } = this.props;
    const { activeTab, tabs } = this.state;

    return (
      <TabsWrapper>
        {!$app.isExport && (
          <Heading level={2} fontSize={20}>
            Top Talkers
          </Heading>
        )}
        <Tabs onChange={this.handleTabChange} selectedTabId={activeTab} vertical renderActiveTabPanelOnly>
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              {...tab}
              title={
                <Flex alignItems="center" justifyContent="space-between" width="100%">
                  <Text>{tab.title}</Text>
                  <Icon icon="chevron-right" />
                </Flex>
              }
              visible={undefined}
              panel={
                <TabResultsTable
                  {...tableProps}
                  {...tab}
                  simpleFilters={simpleFilters}
                  simpleFilterGroups={simpleFilterGroups}
                  queryOverrides={queryOverrides}
                />
              }
            />
          ))}
        </Tabs>
      </TabsWrapper>
    );
  }
}

export default VerticalDetailTabs;
