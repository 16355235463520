import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { capitalize } from 'lodash';
import { FiGitBranch } from 'react-icons/fi';

import buildFilterGroup from 'core/util/filters/buildFilterGroup';
import ResultsTableDataView from 'app/components/resultsTable/ResultsTableDataView';
import SidebarCard from 'app/components/landing/SidebarCard';
import ModuleLinkButton from 'app/components/landing/ModuleLinkButton';

@inject('$dictionary', '$tabs')
@observer
class PeeringCard extends Component {
  getAggregateFilter = (dir) =>
    buildFilterGroup({
      metric: [`i_${dir === 'inbound' ? 'src' : 'dst'}_connect_type_name`],
      name: capitalize(dir),
      named: true,
      filterGroups: [
        buildFilterGroup({
          filters: [{ filterField: 'simple_trf_prof', operator: '=', filterValue: dir }]
        }),
        buildFilterGroup({
          connector: 'Any',
          filters: [
            {
              filterField: `i_${dir === 'inbound' ? 'src' : 'dst'}_connect_type_name`,
              operator: '=',
              filterValue: 'ix'
            },
            {
              filterField: `i_${dir === 'inbound' ? 'src' : 'dst'}_connect_type_name`,
              operator: '=',
              filterValue: 'free_pni'
            },
            {
              filterField: `i_${dir === 'inbound' ? 'src' : 'dst'}_connect_type_name`,
              operator: '=',
              filterValue: 'paid_pni'
            }
          ]
        })
      ]
    });

  get peeringQuery() {
    const query = {
      aggregateFiltersDimensionLabel: 'Connectivity Type',
      aggregateFilters: [this.getAggregateFilter('inbound'), this.getAggregateFilter('outbound')],
      aggregateTypes: ['p95th_bits_per_sec'],
      lookback_seconds: 86400,
      all_devices: true,
      show_overlay: false,
      show_total_overlay: false,
      aggregateFiltersEnabled: true,
      fastData: 'Fast',
      viz_type: 'table'
    };

    return query;
  }

  getValueColumnOverrides = () => [
    {
      key: 'Inbound',
      width: 72
    },
    {
      key: 'Outbound',
      width: 72
    }
  ];

  render() {
    return (
      <SidebarCard icon={FiGitBranch} title="Peering" isConfigured mb={2}>
        <ResultsTableDataView
          showSparklines={false}
          showActionsMenu={false}
          query={this.peeringQuery}
          valueOverrides={this.getValueColumnOverrides()}
        />

        <ModuleLinkButton mt={2} to="/v4/edge/peering" text="Discover New Peers" />
      </SidebarCard>
    );
  }
}

export default PeeringCard;
