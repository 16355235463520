import React from 'react';
import { observer } from 'mobx-react';

import { Box, Callout, Heading } from 'core/components';
import { FiInfo } from 'react-icons/fi';

export default observer(({ boxProps, title, description, intent = 'primary', icon = FiInfo }) => (
  <Box {...boxProps}>
    <Callout intent={intent} icon={icon} pl="29px">
      <Heading level={5}>{title}</Heading>
      {description}
    </Callout>
  </Box>
));
