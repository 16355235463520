import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Box, Flex } from 'core/components';

const TransitionBox = styled(Box)`
  transition: all 0.5s ease-in-out 0s;
`;

const ConsumptionBar = ({ bars, innerWidth = '100%', theme, ...props }) => {
  const bg = theme.name === 'dark' ? '#252525' : '#5c708033';
  return (
    <Flex width="auto" overflow="hidden" height="10px" borderRadius={8} bg={bg} {...props}>
      <Flex width={innerWidth}>
        {bars.map((bar) => (
          <TransitionBox key={bar.key} {...bar} />
        ))}
      </Flex>
    </Flex>
  );
};

export default withTheme(ConsumptionBar);
