import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { capitalize } from 'lodash';

import { Box } from 'core/components';
import buildFilterGroup from 'core/util/filters/buildFilterGroup';
import ResultsTableDataView from 'app/components/resultsTable/ResultsTableDataView';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import ModuleLinkButton from '../../../landing/ModuleLinkButton';

@inject('$dictionary', '$tabs')
@observer
class PeeringWidget extends Component {
  getAggregateFilter = (dir) =>
    buildFilterGroup({
      metric: [`i_${dir === 'inbound' ? 'src' : 'dst'}_connect_type_name`],
      name: capitalize(dir),
      named: true,
      filterGroups: [
        buildFilterGroup({
          filters: [{ filterField: 'simple_trf_prof', operator: '=', filterValue: dir }]
        }),
        buildFilterGroup({
          connector: 'Any',
          filters: [
            {
              filterField: `i_${dir === 'inbound' ? 'src' : 'dst'}_connect_type_name`,
              operator: '=',
              filterValue: 'ix'
            },
            {
              filterField: `i_${dir === 'inbound' ? 'src' : 'dst'}_connect_type_name`,
              operator: '=',
              filterValue: 'free_pni'
            },
            {
              filterField: `i_${dir === 'inbound' ? 'src' : 'dst'}_connect_type_name`,
              operator: '=',
              filterValue: 'paid_pni'
            }
          ]
        })
      ]
    });

  get peeringQuery() {
    return {
      aggregateFiltersDimensionLabel: 'Connectivity Type',
      aggregateFilters: [this.getAggregateFilter('inbound'), this.getAggregateFilter('outbound')],
      aggregateTypes: ['p95th_bits_per_sec'],
      lookback_seconds: 86400,
      all_devices: true,
      show_overlay: false,
      show_total_overlay: false,
      aggregateFiltersEnabled: true,
      fastData: 'Fast',
      viz_type: 'table'
    };
  }

  getValueColumnOverrides = () => [
    {
      key: 'Inbound',
      width: 72
    },
    {
      key: 'Outbound',
      width: 72
    }
  ];

  render() {
    const { canCustomize, onRemove } = this.props;
    const route = '/v4/edge/peering';
    const title = 'Peering';

    return (
      <WidgetFrame canCustomize={canCustomize} onRemove={onRemove} title={title} display="flex" flexDirection="column">
        <Box flex={1}>
          <ResultsTableDataView
            showSparklines={false}
            showActionsMenu={false}
            query={this.peeringQuery}
            valueOverrides={this.getValueColumnOverrides()}
          />
        </Box>
        <ModuleLinkButton m={2} to={route} text="Discover New Peers" />
      </WidgetFrame>
    );
  }
}

export default PeeringWidget;
