import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FiPlus } from 'react-icons/fi';
import { Button, Flex, Heading, Icon } from 'core/components';
import ResetDeckButton from 'app/components/decks/ResetDeckButton';
import SaveDeckButton from 'app/components/decks/SaveDeckButton';

@observer
class DeckHeading extends Component {
  static defaultProps = {
    icon: undefined,
    title: '',
    description: undefined,
    tools: undefined,
    justifyContent: 'space-between',
    iconSize: 40
  };

  render() {
    const {
      canCustomize,
      canSaveOnly,
      canReset,
      children,
      deck,
      decks,
      type,
      icon,
      title,
      tools,
      iconSize,
      isCustomized,
      isDirty,
      onAddWidgets,
      onReset,
      onSave,
      ...rest
    } = this.props;

    return (
      <Flex {...rest} borderBottom="thin">
        {title && (
          <Flex width="100%" alignItems="center">
            {icon && <Icon icon={icon} iconSize={iconSize} color="warning" mr={1} />}
            <Heading level={5} m={0} mr={2}>
              {title}
            </Heading>
            {canCustomize && (
              <Flex justifyContent="flex-end" flex="auto">
                {canCustomize && <Button icon={FiPlus} text="Add Visualizations" onClick={onAddWidgets} />}
                {isCustomized && canReset && <ResetDeckButton deck={deck} onReset={onReset} />}
                {isDirty && (
                  <SaveDeckButton
                    deck={deck}
                    decks={decks}
                    type={type}
                    onSave={onSave}
                    selectedShareLevel="self"
                    ml={1}
                  />
                )}
              </Flex>
            )}
          </Flex>
        )}
        {tools}
        {children}
      </Flex>
    );
  }
}

export default DeckHeading;
