import React from 'react';
import { observer } from 'mobx-react';
import TestResultsTable from 'app/views/synthetics/components/testResults/TestResultsTable';
import LoadingPanel from './LoadingPanel';

@observer
export default class GridPanel extends React.Component {
  render() {
    const { test } = this.props;
    const {
      loading,
      results,
      agentTaskConfig,
      resultTimeMs,
      lookbackSeconds,
      startDate,
      endDate,
      displayOptions,
      healthTimeline,
      hasResults
    } = test.results;
    const { allowAgentDetails } = displayOptions;

    return (
      <LoadingPanel
        test={test}
        testResults={test.results}
        loading={loading && !hasResults}
        empty={healthTimeline.length === 0 || !hasResults}
      >
        <TestResultsTable
          test={test}
          results={results}
          agentTaskConfig={agentTaskConfig}
          resultTimeMs={resultTimeMs}
          lookbackSeconds={lookbackSeconds}
          startDate={startDate}
          endDate={endDate}
          allowAgentDetails={allowAgentDetails}
          showUnhealthyResults={false}
          showAllResultsTablePrefix={false}
        >
          {(resultsTable) => resultsTable.grid}
        </TestResultsTable>
      </LoadingPanel>
    );
  }
}
