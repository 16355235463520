import React from 'react';
import DictionaryLookupLink from 'app/components/DictionaryLookupLink';

const NetworkBoundaryLink = ({ ...rest }) => (
  <DictionaryLookupLink
    baseUrl="/v4/core/quick-views/network-boundary"
    lookup="interfaceClassification.networkBoundaryTypes"
    {...rest}
  />
);

export default NetworkBoundaryLink;
