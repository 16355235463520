import React, { Component } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';
import { withTheme, ThemeProvider } from 'styled-components';
import moment from 'moment';

import Map from 'app/components/map';
import Page from 'app/components/page/Page';
import PageHeading from 'app/components/page/PageHeading';
import { Box, Button, Card, EmptyState, Flex, HTMLTable, Spinner, Suspense, Text } from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import { get } from 'lodash';
import { FiRefreshCcw } from 'react-icons/fi';
import TimeseriesLineChart from 'app/views/core/networkHealth/TimeseriesLineChart';

import parentLinks from './NetworkHealthParentLink';

@storeLoader('$networkHealth')
@inject('$networkBi')
@withTheme
@withRouter
@observer
class NetworkHealthTestDetail extends Component {
  state = {
    loading: false,
    selectedAgent: null
  };

  get testId() {
    const { match } = this.props;
    return match.params.testId;
  }

  componentDidMount() {
    this.refreshDetails();
  }

  refreshDetails = () => {
    this.setState({ loading: true });
    const {
      $networkHealth: { testDetailCollection }
    } = this.props;
    testDetailCollection.reset();
    testDetailCollection.testId = this.testId;
    testDetailCollection.fetch().then(() => this.setState({ loading: false }));
  };

  getLocation(model) {
    const geo = model.get('geo');

    if (geo) {
      const city = get(geo, 'city.name');
      const country = get(geo, 'country.name');
      if (city && city !== '-' && country && country !== '-') {
        return `${city}, ${country}`;
      }
    }

    return 'Unknown Location';
  }

  getSubmarkers() {
    const {
      $networkHealth: { testDetailCollection }
    } = this.props;

    const submarkers = {};
    testDetailCollection.each((model) => {
      const targets = model.get('targets');
      if (targets && targets.dest_ips) {
        submarkers[model.get('agent_id')] = targets.dest_ips.map(({ geo = {} }) => ({
          lat: get(geo, 'city.latitude'),
          lon: get(geo, 'city.longitude'),
          title: `${get(geo, 'city.name')}, ${get(geo, 'country.name')}`
        }));
      }
    });

    return submarkers;
  }

  getMarkers() {
    const {
      $networkHealth: { testDetailCollection }
    } = this.props;

    const markers = [];
    testDetailCollection.each((model) => {
      const geo = model.get('geo') || {};

      if (geo) {
        const lat = get(geo, 'city.latitude');
        const lon = get(geo, 'city.longitude');
        const city = get(geo, 'city.name');
        const country = get(geo, 'country.name');

        if (lat && lon) {
          const latency = model.get('weightedLatency');
          const loss = 100 - model.get('availability');
          const measure = Math.max(latency, loss);
          let color = 'green';
          if (measure > 80) {
            color = 'red';
          } else if (measure > 50) {
            color = 'yellow';
          }
          markers.push({
            id: `agent-${model.get('agent_ip')}`,
            lat,
            lon,
            title: `${city}, ${country}`,
            modelId: model.get('agent_id'),
            color,
            element: 'dot'
          });
        }
      }
    });

    return markers;
  }

  getMeter({ score, rawValue }, suffix) {
    return (
      <Flex
        height={12}
        width={89}
        borderRadius={3}
        mt={1}
        overflow="hidden"
        title={`${(rawValue || score).toFixed(2)}${suffix}`}
      >
        <Box bg={score > 0 || rawValue > 0 ? 'success' : 'specialMuted'} flex={1} mr="1px" />
        <Box bg={score > 10 ? 'success' : 'specialMuted'} flex={1} mr="1px" />
        <Box bg={score > 20 ? 'success' : 'specialMuted'} flex={1} mr="1px" />
        <Box bg={score > 30 ? 'success' : 'specialMuted'} flex={1} mr="1px" />
        <Box bg={score > 40 ? 'success' : 'specialMuted'} flex={1} mr="1px" />
        <Box bg={score > 50 ? 'warning' : 'specialMuted'} flex={1} mr="1px" />
        <Box bg={score > 60 ? 'warning' : 'specialMuted'} flex={1} mr="1px" />
        <Box bg={score > 70 ? 'warning' : 'specialMuted'} flex={1} mr="1px" />
        <Box bg={score > 80 ? 'danger' : 'specialMuted'} flex={1} mr="1px" />
        <Box bg={score > 90 ? 'danger' : 'specialMuted'} flex={1} />
      </Flex>
    );
  }

  getMarkerContentHTML = (el, { modelId: id, title }) => {
    unmountComponentAtNode(el);

    const { $networkHealth, theme } = this.props;
    const model = $networkHealth.testDetailCollection.models.find((m) => m.get('agent_id') === id);

    const content = (
      <ThemeProvider theme={theme}>
        <Box bg="appBackground" p={2} borderRadius={5}>
          <Text as="div" fontSize={18} fontWeight="heavy">
            {title}
          </Text>
          <Flex my={2} justifyContent="space-between">
            <Box mr={2}>
              <Text small muted>
                Avg Latency
              </Text>
              <Text as="div" fontSize={20} fontWeight="heavy" lineHeight={1}>
                {model.get('latency').toFixed(2)} ms
              </Text>
              {this.getMeter({ score: model.get('weightedLatency'), rawValue: model.get('latency') }, ' ms')}
            </Box>
            <Box>
              <Text small muted>
                Avg Packet Loss
              </Text>
              <Text as="div" fontSize={20} fontWeight="heavy" lineHeight={1}>
                {(100 - model.get('availability')).toFixed(2)}%
              </Text>
              {this.getMeter({ score: 100 - model.get('availability') }, '%')}
            </Box>
          </Flex>
          <Text small muted>
            Click to see more details
          </Text>
        </Box>
      </ThemeProvider>
    );

    render(content, el);
  };

  onMapLoad = (map) => {
    this.map = map;

    setTimeout(() => {
      this.map.resize();
    }, 100);
  };

  getDests = () => {
    const {
      $networkHealth: { testDetailCollection }
    } = this.props;
    const dests = new Set();
    testDetailCollection.each((model) => {
      const targets = model.get('targets');
      if (targets && targets.dest_ips) {
        targets.dest_ips.forEach((ip) => dests.add(ip));
      }
    });
    return Array.from(dests);
  };

  handleDisableMonitoring = () => {
    const { $networkHealth } = this.props;
    const test = $networkHealth.collection.get(this.testId);
    test.save({ status: 'D' }, { patch: true });
  };

  handleEnableMonitoring = () => {
    const { $networkHealth } = this.props;
    const test = $networkHealth.collection.get(this.testId);
    test.save({ status: 'A' }, { patch: true });
  };

  selectAgent = ({ modelId: agentId }) => {
    const { $networkHealth } = this.props;
    const selectedAgent = $networkHealth.testDetailCollection.models.find((model) => model.get('agent_id') === agentId);
    $networkHealth.getAgentData(selectedAgent, this.testId).then(() => {
      this.setState({ selectedAgent });
    });
  };

  render() {
    const { $networkHealth } = this.props;
    const { selectedAgent, loading } = this.state;

    const test = $networkHealth.collection.get(this.testId);
    const hasDetails = $networkHealth.testDetailCollection.size > 0;
    const selectedAgentAvailability =
      selectedAgent && selectedAgent.get('timeseries.availability').map((val) => 100 - val);
    const selectedAgentTimestamps = selectedAgent && selectedAgent.get('timeseries.timestamps');
    const selectedAgentLatency = selectedAgent && selectedAgent.get('timeseries.latency');

    return (
      <Page title="Test Results" parentLinks={parentLinks} scrolls>
        <Box>
          <Flex alignItems="center" justifyContent="space-between">
            <Box flex="1 1 auto" mr={2}>
              {!!test && (
                <>
                  <PageHeading title={test.get('target')} mb={0} />
                  <Flex alignItems="center">
                    <Text muted fontSize={12}>
                      Last Updated: {moment(test.get('timeseries.timestamps').pop()).fromNow()}
                    </Text>
                    <Button small minimal icon={FiRefreshCcw} onClick={this.refreshDetails} />
                  </Flex>
                </>
              )}
            </Box>
          </Flex>

          <Suspense loading={loading} fallback={<Spinner intent="primary" />}>
            {(!test || !hasDetails) && (
              <EmptyState
                icon="folder-open"
                title="Please wait..."
                description="Kentik's Agent Network is still provisioning this test."
                maxWidth={650}
                m="0px auto"
              />
            )}

            {!!test && !!hasDetails && (
              <>
                <Flex mt={3} mb={2}>
                  <Card width={230} p={1} mr={2}>
                    <Flex justifyContent="space-between">
                      <div>
                        <Text small muted>
                          Status
                        </Text>
                        <Text
                          as="div"
                          fontSize={test.get('status') === 'A' ? 24 : 22}
                          fontWeight="heavy"
                          lineHeight={1}
                          color={test.get('status') === 'A' ? 'success' : undefined}
                        >
                          {test.get('status') === 'A' ? 'Monitoring' : 'Not Monitoring'}
                        </Text>
                      </div>
                      <Button
                        icon={test.get('status') === 'A' ? 'pause' : 'play'}
                        minimal
                        large
                        iconSize={28}
                        p={0}
                        onClick={
                          test.get('status') === 'A' ? this.handleDisableMonitoring : this.handleEnableMonitoring
                        }
                      />
                    </Flex>
                  </Card>
                  <Card width={176} p={1} mr={2}>
                    <Text small muted>
                      Total Destination IPs
                    </Text>
                    <Text as="div" fontSize={24} fontWeight="heavy" lineHeight={1}>
                      {this.getDests().length}
                    </Text>
                  </Card>
                  <Card width={176} p={1} mr={2}>
                    <Text small muted>
                      Average Packet Loss
                    </Text>
                    <Text as="div" fontSize={24} fontWeight="heavy" lineHeight={1}>
                      {(100 - test.get('availability')).toFixed(1)}%
                    </Text>
                  </Card>
                  <Card width={176} p={1}>
                    <Text small muted>
                      Average Latency
                    </Text>
                    <Text as="div" fontSize={24} fontWeight="heavy" lineHeight={1}>
                      {test.get('latency').toFixed(0)}ms
                    </Text>
                  </Card>
                </Flex>
                <Card width="100%" minHeight={400} p={0} mb={2} overflow="hidden">
                  <AutoSizer disableHeight>
                    {({ width }) => {
                      if (width === 0) {
                        return null;
                      }

                      const height = Math.max(400, (400 / 892) * width);

                      return (
                        <Box width="100%" height={height}>
                          <Map
                            containerWrapperProps={{ width, height }}
                            markerPopupOnHover
                            onLoad={this.onMapLoad}
                            markers={this.getMarkers()}
                            submarkers={this.getSubmarkers()}
                            onMarkerClick={this.selectAgent}
                            autoBounds
                            getMarkerContentHTML={this.getMarkerContentHTML}
                          />
                        </Box>
                      );
                    }}
                  </AutoSizer>
                </Card>
                {!!selectedAgent && (
                  <>
                    <h2>Details from {this.getLocation(selectedAgent)}</h2>
                    <Flex mb={2}>
                      <Card flex={1} p={2} pt="4px" pb={0} mr={2}>
                        <Flex>
                          <h2>Packet Loss</h2>
                          <Flex flex={1} ml={2} my="auto">
                            <Text muted mr={1}>
                              Min: {Math.min(...selectedAgentAvailability).toFixed(1)}%{' '}
                            </Text>
                            <Text muted mr={1}>
                              Avg:{' '}
                              {(
                                selectedAgentAvailability.reduce((a, b) => a + b, 0) / selectedAgentAvailability.length
                              ).toFixed(1)}
                              %
                            </Text>
                            <Text muted>Max: {Math.max(...selectedAgentAvailability).toFixed(1)}% </Text>
                          </Flex>
                        </Flex>
                        <Flex flexDirection="column" justifyContent="flex-end" mx={-2} flex={1}>
                          <TimeseriesLineChart
                            height={100}
                            dataPoints={selectedAgentAvailability}
                            timestamps={selectedAgentTimestamps}
                            yAxis={{ title: { text: '% Loss' }, min: 0.001, max: 100 }}
                            name="Packet Loss %"
                          />
                        </Flex>
                      </Card>
                      <Card flex={1} p={2} pt="4px" pb={0}>
                        <Flex>
                          <h2>Average Latency</h2>
                          <Flex flex={1} ml={2} my="auto">
                            <Text muted mr={1}>
                              Min: {Math.min(...selectedAgentLatency).toFixed(1)} ms{' '}
                            </Text>
                            <Text muted mr={1}>
                              Avg:{' '}
                              {(
                                selectedAgentLatency.reduce((a, b) => a + b, 0) / selectedAgentAvailability.length
                              ).toFixed(1)}{' '}
                              ms
                            </Text>
                            <Text muted>Max: {Math.max(...selectedAgentLatency).toFixed(1)} ms </Text>
                          </Flex>
                        </Flex>
                        <Flex flexDirection="column" justifyContent="flex-end" mx={-2} flex={1}>
                          <TimeseriesLineChart
                            height={100}
                            dataPoints={selectedAgentLatency}
                            timestamps={selectedAgentTimestamps}
                            yAxis={{ title: { text: 'ms' }, min: 0.001 }}
                            name="Latency (ms)"
                          />
                        </Flex>
                      </Card>
                    </Flex>
                    <Card p={2} pt="4px" mb={2}>
                      <h2>Recent Tests</h2>
                      <HTMLTable>
                        <thead>
                          <tr>
                            <th>Time</th>
                            <th>Tests</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedAgent.get('tests').map((model) => (
                            <tr key={model.ctime}>
                              <td>{model.ctime}</td>
                              <td>{model.tests.join(', ')}</td>
                            </tr>
                          ))}
                        </tbody>
                      </HTMLTable>
                    </Card>
                    <Card p={2} mb={2}>
                      <h2>Coming soon: Path & Path History!</h2>
                    </Card>
                  </>
                )}
              </>
            )}
          </Suspense>
        </Box>
      </Page>
    );
  }
}

export default NetworkHealthTestDetail;
