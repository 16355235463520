import React, { Component } from 'react';
import { interpolateSpectral, interpolateRdYlBu } from 'd3-scale-chromatic';
import { Text } from 'core/components';
import { Form } from 'core/form/components';
import GraphContainer from '../GraphContainer';
import Timeline from './timeline';

const colorSeries = (expectedSeries, unexpectedSeries) => {
  const series = [];

  for (let i = 0; i < unexpectedSeries.length; i++) {
    const pilot = unexpectedSeries[i];
    pilot.color = interpolateRdYlBu(pilot.colorIndex * 0.25);
    series.push(pilot);
  }

  for (let i = 0; i < expectedSeries.length; i++) {
    const pilot = expectedSeries[i];
    pilot.color = interpolateSpectral(pilot.colorIndex * 0.25 + 0.75);
    series.push(pilot);
  }

  return series;
};

const parseData = ({ asn, data }) => {
  if (data) {
    const { expectedSeries = [], unexpectedSeries = [], prefixes } = data;
    let type;
    let stacking;

    if (asn || prefixes.length === 1) {
      type = 'area';
      stacking = 'normal';
    } else {
      type = 'line';
      stacking = undefined;
    }

    return {
      data: colorSeries(expectedSeries, unexpectedSeries),
      graphProps: {
        type,
        stacking
      }
    };
  }

  return { data: [] };
};
const checkForResults = (data) => !!data.length;

const fields = {
  hop: {
    label: 'Show reachability by',
    options: [
      {
        label: 'Origin',
        value: 'origin'
      },
      {
        label: 'Upstream',
        value: 'upstream'
      }
    ],
    defaultValue: 'origin',
    type: 'select'
  }
};

const GraphSubHeading = ({ form }) => (
  <Text small ml="8px">
    (by {form.getOptionValue('hop')})
  </Text>
);
@Form({ fields, options: { name: 'Bgp Test Results Reachability Graph Options' } })
class ReachabilityTimelineWrapper extends Component {
  render() {
    return (
      <GraphContainer
        {...this.props}
        key="bgp-reachability-timeline"
        type="visibilityTS"
        timelineComponent={Timeline}
        heading="Reachability / Visibility"
        subHeading={GraphSubHeading}
        text={({ numberOfVPs }) =>
          `Percentage of Kentik's BGP vantage points (VPs) with routes to the monitored prefixes (${numberOfVPs} total VPs)`
        }
        noResultsDescription="No monitored prefixes were reachable from any Vantage Points during this time range"
        parseData={parseData}
        checkForResults={checkForResults}
        fields={fields}
      />
    );
  }
}

export default ReachabilityTimelineWrapper;
