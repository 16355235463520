import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { startCase } from 'lodash';
import { MdCompareArrows } from 'react-icons/md';
import {
  Box,
  Button,
  Callout,
  Card,
  EmptyState,
  Flex,
  Heading,
  Icon,
  Spinner,
  Suspense,
  Tag,
  Text
} from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import ViewInExplorerButton from 'app/components/dataviews/tools/ViewInExplorerButton';
import { convertDnsHealthDataToArray, healthToIntent } from 'app/views/synthetics/utils/syntheticsUtils';
import { getTrafficChartData } from 'app/stores/synthetics/utils';
import { withRouter } from 'react-router-dom';
import LatencyTimeSeries from '../LatencyTimeSeries';
import HttpStatusTimeSeries from '../HttpStatusTimeSeries';
import StackedColumnTimeSeries from '../StackedColumnTimeSeries';
import PacketLossTimeSeries from '../PacketLossTimeSeries';
import JitterTimeSeries from '../JitterTimeSeries';
import ThroughputTimeSeries from '../ThroughputTimeSeries';
import TrafficTimeSeries from '../TrafficTimeSeries';
import CSSSelectorSeries from '../CSSSelectorSeries';
import AggregateView from './AggregateView';

@inject('$syn', '$auth', '$devices', '$dataviews')
@withRouter
@observer
class MetricsView extends Component {
  // fake a dns result health from backend for now.
  dataTagIntent = healthToIntent({ health: 'healthy' });

  get dataAsArray() {
    const { dnsHealthTsResult } = this.props;
    return convertDnsHealthDataToArray(dnsHealthTsResult);
  }

  get latencySeriesName() {
    return 'Avg Latency';
  }

  get hasCSSSelectors() {
    const { test } = this.props;
    return test.isPageLoad && !!test.get('config.http.css_selectors')?.length;
  }

  render() {
    const {
      $auth,
      $syn,
      test,
      resultTimeMs,
      timelineBounds,
      pingHealthTs,
      dnsHealthTs,
      httpHealthTs,
      throughputHealthTs,
      dnsHealthAggregates,
      httpHealthAggregates,
      pingHealthAggregates,
      throughputHealthAggregates,
      allowFlowQuery,
      displayFlowQuery,
      trafficFlowQuery,
      onHover,
      agent_id,
      target,
      trendResults
    } = this.props;
    const agent = $syn.agents.get(agent_id);
    const targetAgent = $syn.agents.get(target);
    const { isAdministrator } = $auth;
    const { config } = test.get();

    return (
      <Flex flexDirection="column" pb={4} gap={4}>
        {test.isDns && (
          <Box className="avoid-break">
            <Heading level={5} fontWeight="heavy" mb={2}>
              DNS Results for {test.get('config.target.value')}
            </Heading>
            <Card p={1}>
              {this.dataAsArray.map((ip) => (
                <Tag mx="2px" my="2px" fontWeight="bold" key={ip} minimal intent={this.dataTagIntent}>
                  {ip}
                </Tag>
              ))}
            </Card>
          </Box>
        )}

        {throughputHealthTs && (
          <Box>
            <Heading level={5} fontWeight="heavy" mb={1}>
              Avg Bandwidth{' '}
              <Text fontWeight="normal" muted>
                (mbps)
              </Text>
            </Heading>
            <AggregateView
              unit="mbps"
              toFixed={2}
              isThroughput
              aggregate={throughputHealthAggregates?.avg_throughput_bandwidth}
            />
            <ThroughputTimeSeries
              test={test}
              trendResults={trendResults}
              dataKey="throughputHealthTs"
              data={throughputHealthTs}
              selectedTime={resultTimeMs}
              seriesName="Avg Bandwidth"
              onHover={onHover}
              {...timelineBounds}
            />
          </Box>
        )}

        {httpHealthTs && (
          <>
            <Box>
              <Heading level={5} fontWeight="heavy" mb={2}>
                HTTP Stages Breakdown{' '}
                <Text fontWeight="normal" muted>
                  (ms)
                </Text>
              </Heading>
              <StackedColumnTimeSeries
                test={test}
                trendResults={trendResults}
                dataKey="httpHealthTs"
                data={httpHealthTs}
                isPageLoad={test.isPageLoad}
                selectedTime={resultTimeMs}
                onHover={onHover}
                {...timelineBounds}
              />
            </Box>

            <Box>
              <Heading level={5} fontWeight="heavy" mb={1}>
                Avg HTTP Latency{' '}
                <Text fontWeight="normal" muted>
                  (ms)
                </Text>
              </Heading>
              <AggregateView aggregate={httpHealthAggregates.avg_latency} />
              <LatencyTimeSeries
                test={test}
                trendResults={trendResults}
                dataKey="httpHealthTs"
                data={httpHealthTs}
                selectedTime={resultTimeMs}
                seriesName="Avg HTTP Latency"
                onHover={onHover}
                {...timelineBounds}
              />
            </Box>

            <Box>
              <Heading level={5} fontWeight="heavy" mb={2}>
                HTTP Status Code{' '}
              </Heading>
              <HttpStatusTimeSeries
                test={test}
                trendResults={trendResults}
                dataKey="httpHealthTs"
                data={httpHealthTs}
                selectedTime={resultTimeMs}
                seriesName="HTTP Status Code"
                onHover={onHover}
                {...timelineBounds}
              />
            </Box>

            {this.hasCSSSelectors && (
              <Box className="avoid-break" position="relative" zIndex="4">
                <Heading level={5} fontWeight="heavy" mb={2}>
                  CSS Selectors
                </Heading>
                <CSSSelectorSeries
                  test={test}
                  trendResults={trendResults}
                  dataKey="httpHealthTs"
                  baseline={config.http.css_selectors.length}
                  data={httpHealthTs}
                  time={resultTimeMs}
                  onHover={onHover}
                  {...timelineBounds}
                />
              </Box>
            )}
          </>
        )}

        {(test.isDns || !!dnsHealthTs) && (
          <Box>
            <Heading level={5} fontWeight="heavy" mb={1}>
              DNS Resolution{' '}
              <Text fontWeight="normal" muted>
                (ms)
              </Text>
            </Heading>
            <AggregateView aggregate={dnsHealthAggregates.avg_latency} />
            <LatencyTimeSeries
              test={test}
              trendResults={trendResults}
              dataKey="dnsHealthTs"
              data={dnsHealthTs}
              selectedTime={resultTimeMs}
              seriesName="DNS Resolution"
              onHover={onHover}
              {...timelineBounds}
            />
          </Box>
        )}

        {!!pingHealthTs && (
          <>
            <Box>
              <Heading level={5} fontWeight="heavy" mb={1}>
                {this.latencySeriesName}{' '}
                <Text fontWeight="normal" muted>
                  (ms)
                </Text>
              </Heading>
              <AggregateView aggregate={pingHealthAggregates.avg_latency} />
              <LatencyTimeSeries
                trendResults={trendResults}
                dataKey="pingHealthTs"
                test={test}
                data={pingHealthTs}
                selectedTime={resultTimeMs}
                seriesName={this.latencySeriesName}
                onHover={onHover}
                onTrendDetailClick={this.handleTrendDetailClick}
                {...timelineBounds}
              />
            </Box>

            <Box className="avoid-break">
              <Heading level={5} fontWeight="heavy" mb={1}>
                Packet Loss{' '}
                <Text fontWeight="normal" muted>
                  (%)
                </Text>
              </Heading>
              <AggregateView aggregate={pingHealthAggregates.packet_loss} isPercentage />
              <PacketLossTimeSeries
                trendResults={trendResults}
                dataKey="pingHealthTs"
                test={test}
                healthSettings={config.healthSettings}
                data={pingHealthTs}
                selectedTime={resultTimeMs}
                onHover={onHover}
                {...timelineBounds}
              />
            </Box>

            <Box className="avoid-break">
              <Heading level={5} fontWeight="heavy" mb={1}>
                Avg Jitter{' '}
                <Text fontWeight="normal" muted>
                  (ms)
                </Text>
              </Heading>
              <AggregateView aggregate={pingHealthAggregates.avg_jitter} />
              <JitterTimeSeries
                trendResults={trendResults}
                dataKey="pingHealthTs"
                test={test}
                data={pingHealthTs}
                selectedTime={resultTimeMs}
                onHover={onHover}
                {...timelineBounds}
              />
            </Box>
          </>
        )}

        {displayFlowQuery && allowFlowQuery && !!trafficFlowQuery && Object.keys(trafficFlowQuery).length && (
          <LightweightDataViewWrapper allowCache={false} query={trafficFlowQuery}>
            {({ dataview, queryModel }) => {
              const trafficChart = getTrafficChartData(dataview.queryBuckets.activeBuckets);
              return (
                <Box mt={2}>
                  <Heading level={5} fontWeight="heavy">
                    {queryModel && queryModel.get('query_title')}
                    <ViewInExplorerButton dataview={dataview} ml={1} minimal={false} openInNewWindow />
                  </Heading>
                  <Suspense loading={trafficChart === null} fallback={<Spinner intent="primary" mt={4} />}>
                    {trafficChart && trafficChart.visible && (
                      <>
                        <Heading level={6} mb={1}>
                          {trafficChart.heading}
                        </Heading>
                        <TrafficTimeSeries
                          data={trafficChart.flowData}
                          selectedTime={resultTimeMs}
                          onHover={onHover}
                          {...timelineBounds}
                        />
                      </>
                    )}
                    {trafficChart && !trafficChart.visible && <EmptyState title="No results" />}
                  </Suspense>
                </Box>
              );
            }}
          </LightweightDataViewWrapper>
        )}

        {displayFlowQuery && !allowFlowQuery && (
          <Callout mt={2}>
            <Flex alignItems="center">
              <Icon iconSize={50} icon={MdCompareArrows} color="warning" />
              <Box ml={3} flex="1 1 auto">
                <Heading level={5} fontWeight="black">
                  {startCase(agent.get('site.title') || 'Add your first traffic source')}
                </Heading>
                <Text muted>
                  You don&apos;t have any traffic data {agent.get('site.title') ? 'for this site ' : ''}
                  yet!{' '}
                  {isAdministrator
                    ? 'Adding your first traffic source takes just a few clicks.'
                    : 'Contact your administrator to add a traffic source.'}
                </Text>
              </Box>

              {isAdministrator && (
                <Button
                  large
                  px={3}
                  onClick={() => {
                    const { history, $devices } = this.props;
                    history.push(
                      $devices.deviceSummaries.length ? '/v4/settings/devices' : '/v4/onboarding/select-flow-type'
                    );
                  }}
                >
                  Get started
                </Button>
              )}
            </Flex>
          </Callout>
        )}

        {displayFlowQuery &&
          allowFlowQuery &&
          agent?.get('site.title') &&
          targetAgent?.get('site.title') &&
          (!agent.hasSiteIps || !targetAgent.hasSiteIps) && (
            <Callout mt={2}>
              <Flex alignItems="center">
                <Icon iconSize={50} icon={MdCompareArrows} color="warning" />
                <Box ml={3} flex="1 1 auto">
                  <Heading level={5} fontWeight="black">
                    Want to see traffic between {agent.get('site.title')} and {targetAgent.get('site.title')}?
                  </Heading>
                  <Text muted>
                    You don&apos;t have Site IPs defined yet!{' '}
                    {isAdministrator
                      ? 'Adding Site IPs takes just a few seconds.'
                      : 'Contact your administrator to configure your sites to see traffic.'}
                  </Text>
                </Box>

                {isAdministrator && (
                  <Button
                    large
                    px={3}
                    onClick={() => {
                      const { history } = this.props;
                      history.push('/v4/settings/sites');
                    }}
                  >
                    Get started
                  </Button>
                )}
              </Flex>
            </Callout>
          )}
      </Flex>
    );
  }
}

export default MetricsView;
