import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

const iconSize = 16;

function svgToDataUrl(svg) {
  return `data:image/svg+xml,${encodeURIComponent(svg.trim().replace(/>\s+</g, '><').replace(/\s+/g, ' '))}`;
}

function getIconColors(theme, common) {
  if (common) {
    return {
      fill: theme.colors.primary,
      stroke: theme.name === 'light' ? theme.colors.lightGray1 : theme.colors.darkGray5
    };
  }

  return {
    fill: theme.name === 'light' ? theme.colors.gray1 : theme.colors.gray5,
    stroke: theme.name === 'light' ? theme.colors.gray5 : theme.colors.gray1
  };
}

export function getFacilityIcon(theme, facility = null) {
  const common = facility?.get('common');
  const { fill, stroke } = getIconColors(theme, common);

  const markup = `
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="23" viewBox="0 -1 17 23" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.50008 0C4.32008 0 0.923828 3.28625 0.923828 7.32875C0.923828 11.385 8.50008 22 8.50008 22C8.50008 22 16.0763 11.385 16.0763 7.32875C16.0626 3.28625 12.6801 0 8.50008 0Z"
        fill="${fill}"
        stroke="${stroke}"
        stroke-width="1"
      />
    </svg>
  `;

  return svgToDataUrl(markup);
}

export function getExchangeIcon(theme, exchange = null) {
  const common = exchange?.get('common');
  const { fill, stroke } = getIconColors(theme, common);

  const markup = `
    <svg width="17" height="17" viewBox="-1 -1 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="63" height="63" fill="${fill}" stroke="${stroke}" stroke-width="1" />
      <path
        d="M10.5 53.5001L10.4999 35.9776L15.23 35.9776L15.23 45.5451L25.3887 35.3864L28.6137 38.6114L18.455 48.7701
          L28.0225 48.7701L28.0225 53.5002L10.5 53.5001ZM10.4999 27.4851L10.5 10.5L27.5925 10.5L27.5925 15.2301
          L18.455 15.2301L28.6137 25.3888L25.3887 28.6138L15.23 18.4551L15.23 27.4851H10.4999ZM36.515 53.5002
          L36.515 48.7701L45.545 48.7701L35.44 38.6651L38.665 35.4401L48.77 45.5451L48.77 36.5151L53.5001 36.5151
          L53.5 53.5001L36.515 53.5002ZM35.44 25.3351L45.545 15.2301L36.515 15.2301V10.5L53.5 10.5L53.5001 27.4851
          H48.77L48.77 18.4551L38.665 28.5601L35.44 25.3351Z"
        fill="${theme.name === 'light' ? 'white' : stroke}"
      />
    </svg>
  `;

  return svgToDataUrl(markup);
}

export function BlankIcon() {
  return <div css={{ width: iconSize, height: iconSize }} />;
}

export function CommonIcon({ common = false }) {
  const theme = useTheme();
  const { fill } = getIconColors(theme, common);
  return <div css={{ width: iconSize, height: iconSize, borderRadius: '50%', backgroundColor: fill }} />;
}

export function FacilityIcon({ facility = null }) {
  const theme = useTheme();
  const icon = useMemo(() => getFacilityIcon(theme, facility), [theme.name, facility?.get('common') || false]);
  return (
    <div
      css={{
        backgroundImage: `url("${icon}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        width: iconSize,
        height: iconSize
      }}
    />
  );
}

export function ExchangeIcon({ exchange = null }) {
  const theme = useTheme();
  const icon = useMemo(() => getExchangeIcon(theme, exchange), [theme.name, exchange?.get('common') || false]);
  return (
    <div
      css={{
        backgroundImage: `url("${icon}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        width: iconSize,
        height: iconSize
      }}
    />
  );
}
