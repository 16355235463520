import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { greekIt } from 'app/util/utils';
import safelyParseJSON from 'core/util/safelyParseJson';
import { Callout, Flex, Tag, Text } from 'core/components';
import { getMeasurementModelDataFromPolicy, getMetricOption } from 'app/util/policies';
import { POLICY_APPLICATIONS } from 'shared/alerting/constants';

export const OPERATOR_LABELS = {
  equals: '=',
  notEquals: '≠',
  lessThan: '<',
  greaterThan: '>',
  lessThanOrEquals: '≤',
  greaterThanOrEquals: '≥'
};

const TYPE_LABELS = {
  baseline: 'Baseline',
  baselinePercent: 'Baseline',
  keyNotInTop: 'Top Keys',
  interfaceCapacity: 'Interface Capacity',
  interfaceCapacityPercent: 'Interface Capacity',
  ratio: 'Ratio-Based'
};

@inject('$dictionary')
@observer
export default class ConditionsTags extends Component {
  static propTypes = {
    showLabels: PropTypes.bool,
    policy: PropTypes.object.isRequired,
    threshold: PropTypes.object.isRequired
  };

  static defaultProps = {
    showLabels: true
  };

  get metrics() {
    const { policy } = this.props;
    return policy.metrics || [];
  }

  get threshold() {
    const { policy, threshold } = this.props;
    let topKeys = policy.nTopKeysEvaluated;

    // If this is form data, topKeys is on threshold
    if (threshold.conditions.form) {
      return {
        enabled: threshold.enabled.getValue(),
        direction: threshold.direction.getValue(),
        conditions: threshold.conditions.getValue(),
        topKeys: threshold['keySettings.nTopKeysEvaluated'].getValue() || topKeys
      };
    }

    // If this is model data, topKeys is on condition
    threshold.conditions.every((condition) => {
      if (condition.keySettings) {
        topKeys = condition.keySettings.nTopKeysEvaluated;
        return false;
      }
      return true;
    });

    return {
      enabled: threshold.enabled,
      direction: threshold.direction,
      conditions: threshold.conditions,
      topKeys
    };
  }

  get conditions() {
    return this.threshold.conditions;
  }

  isPrimary = (metric) => metric === this.metrics[0];

  renderRatioConditionText(condition) {
    const { policy, showLabels } = this.props;

    const lhsMetricIndex = condition.ratioSettings.lhsMetricPosition;
    const rhsMetricIndex = condition.ratioSettings.rhsMetricPosition;

    const lhsProportion = parseInt(condition.ratioSettings.lhsMetricProportion) || 0;
    const rhsProportion = parseInt(condition.ratioSettings.rhsMetricProportion) || 0;
    const margin = parseFloat(condition.ratioSettings.margin) || 0;
    const isBidirectional = condition.ratioSettings.direction === 'DirectionBoth';

    const lhsLabel = getMetricOption(this.metrics[lhsMetricIndex], policy.selectedMeasurement).label;
    const rhsLabel = getMetricOption(this.metrics[rhsMetricIndex], policy.selectedMeasurement).label;

    const tagProps = { minimal: true, round: true, large: showLabels, maxWidth: '100%' };

    return (
      <Flex maxWidth="100%" gap="4px" alignItems="center" flexWrap="wrap">
        <Tag {...tagProps}>
          &ge; {lhsProportion + margin} {lhsLabel} : {rhsProportion} {rhsLabel}
        </Tag>

        {isBidirectional && (
          <>
            <Text small muted>
              OR
            </Text>
            <Tag {...tagProps}>
              &ge; {rhsProportion + margin} {rhsLabel} : {lhsProportion} {lhsLabel}
            </Tag>
          </>
        )}
      </Flex>
    );
  }

  renderCondition(condition) {
    const { policy, showLabels } = this.props;
    const { type, value_select, metric, comparisonValue, comparisonValueFactor = 1 } = condition;

    const conditionMetric = value_select ? this.metrics[value_select.split('_')[1]] : metric;
    const isMetricPolicy = policy?.application === POLICY_APPLICATIONS.METRIC;
    const measurement =
      policy.selectedMeasurement ||
      policy?.applicationMetadata?.measurement ||
      getMeasurementModelDataFromPolicy({ ...policy, applicationMetadata: safelyParseJSON(policy.applicationMetadata) })
        ?.measurement;
    const metricOption = getMetricOption(conditionMetric, isMetricPolicy ? measurement : undefined);
    const operatorLabel = OPERATOR_LABELS[condition.operator];
    const direction = condition.direction || this.threshold.direction;

    const { value, displayFull } = greekIt(comparisonValue * (metricOption.canGreek ? comparisonValueFactor : 1), {
      trailingZeros: false,
      suffix: metricOption.unitLabel
    });

    const conditionLabel = (
      <Text as="div" mb="4px">
        {TYPE_LABELS[type] || metricOption.label}
      </Text>
    );

    const wrapperProps = {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      maxWidth: '100%',
      width: showLabels ? '100%' : 'auto'
    };

    const tagProps = {
      minimal: true,
      large: showLabels,
      round: true
    };

    if (type === 'static') {
      return (
        <Flex {...wrapperProps}>
          {showLabels && conditionLabel}
          <Tag {...tagProps} intent={this.isPrimary(conditionMetric) ? 'primary' : undefined}>
            {operatorLabel}{' '}
            {metricOption.canGreek ? displayFull : `${Number(value).toLocaleString()} ${metricOption.unitLabel}`}
          </Tag>
        </Flex>
      );
    }

    if (type.startsWith('baseline')) {
      const directionLabel = direction === 'currentToHistory' ? 'above' : 'below';
      return (
        <Flex {...wrapperProps}>
          {showLabels && conditionLabel}
          <Tag {...tagProps} intent="primary">
            &ge;{' '}
            {type === 'baselinePercent'
              ? `${value}% ${directionLabel} baseline`
              : `${displayFull} ${directionLabel} baseline`}
          </Tag>
        </Flex>
      );
    }

    if (type === 'keyNotInTop') {
      return (
        <Flex {...wrapperProps}>
          {showLabels && conditionLabel}
          <Tag {...tagProps}>
            {direction === 'currentToHistory' ? 'Joins' : 'Leaves'} top {this.threshold.topKeys} keys
          </Tag>
        </Flex>
      );
    }

    if (type.startsWith('interfaceCapacity')) {
      return (
        <Flex {...wrapperProps}>
          {showLabels && conditionLabel}
          <Tag {...tagProps}>
            &ge;{' '}
            {type === 'interfaceCapacityPercent'
              ? `${comparisonValue}% of capacity`
              : `${Number(comparisonValue).toLocaleString()} Mbits/s over capacity`}
          </Tag>
        </Flex>
      );
    }

    if (type === 'ratio' && this.metrics.length > 1) {
      return (
        <Flex {...wrapperProps}>
          {showLabels && conditionLabel}
          {this.renderRatioConditionText(condition)}
        </Flex>
      );
    }

    return null;
  }

  render() {
    const { showLabels } = this.props;
    if (this.conditions.length === 0) {
      return (
        <Callout muted intent={this.threshold.enabled ? 'danger' : 'muted'} mt={1}>
          <Text>A threshold must have at least one condition.</Text>
        </Callout>
      );
    }

    return (
      <Flex flexWrap="wrap" gap={showLabels ? 2 : 1} maxWidth="100%">
        {this.conditions.map((condition) => (
          <Fragment key={`${condition.type}-${condition.value_select}`}>{this.renderCondition(condition)}</Fragment>
        ))}
      </Flex>
    );
  }
}
