import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Form, SubmitButton } from 'core/form';
import { numberWithCommas } from 'app/util/utils';

import { Text, Flex, FlexColumn, Spinner, ConfirmPopover, Button, Tag } from 'core/components';
import Page from 'app/components/page/Page';
import PageTabs, { PageTab } from 'app/components/page/PageTabs';
import { fields, options } from 'app/forms/config/capacityPlan';
import PageHeading from 'app/components/page/PageHeading';
import CapacityPlanModel from 'app/stores/capacityPlan/CapacityPlanModel';
import CapacityPlanInterfaces from './CapacityPlanInterfaces';
import CapacityPlanConfigure from './CapacityPlanConfigure';

@inject('$capacity', '$app', '$auth')
@Form({ fields, options })
@withRouter
@observer
class EditCapacityPlan extends Component {
  state = {
    model: null,
    loading: true,
    saving: false,
    showErrors: false
  };

  componentDidMount() {
    const { match, form } = this.props;
    const { id } = match.params;

    if (id === undefined) {
      this.loadNewModel();
    } else {
      const model = new CapacityPlanModel({ id });
      model
        .fetch()
        .then(() => {
          form.setModel(model);
          this.setState({ model, loading: false });
        })
        .catch(() => {
          this.loadNewModel();
        });
    }
  }

  loadNewModel = () => {
    const { $capacity, history, form } = this.props;
    history.push('/v4/core/capacity/new');
    const newModel = $capacity.collection.build();
    form.setModel(newModel);
    this.setState({ model: newModel, loading: false });
  };

  handleCancelPlan = () => {
    const { history, match } = this.props;
    const { id } = match.params;

    if (id === undefined) {
      history.push('/v4/core/capacity');
    } else {
      history.push(`/v4/core/capacity/${id}`);
    }
  };

  handleSavePlan = () => {
    const { $capacity, form, history } = this.props;
    const { model } = this.state;
    const { interfaceGroup, isNew } = model;
    const { _interfaceGroup, ...formValues } = form.getValues();
    this.setState({ saving: true });
    const filters = interfaceGroup.get('filters').map((filter) => filter.filters);
    const operation = isNew ? 'create' : 'save';
    return model[operation]({ ...interfaceGroup.get(), ...formValues, id: model.get('id'), filters })
      .then((plan) => {
        this.setState({ saving: false });
        if (!isNew) {
          $capacity.collection.remove(model.get('id'));
          $capacity.collection.add(plan);
        }
        $capacity.executeInsight().catch((err) => {
          console.warn('Capacity insight execute failed:', err);
        });

        history.push(`/v4/core/capacity/${plan.id}`);
      })
      .catch((err) => {
        console.error(err);
        this.setState({ showErrors: true, saving: false });
      });
  };

  onRemovePlan = () => {
    const { $capacity, history } = this.props;
    const { model } = this.state;
    return model.destroy().then(() => {
      $capacity.collection.remove(model.id);
      history.push('/v4/core/capacity');
    });
  };

  render() {
    const { $app, $auth, form } = this.props;
    const { loading, model, saving, showErrors } = this.state;
    const planName = model ? model.get('name') : undefined;

    const parentLinks = [{ link: '/v4/core/capacity', label: 'Capacity Planning' }];
    const planInterfaceCount = (model && model.interfaceGroup.get('interfaces').length) || 0;

    if (model && !model.isNew) {
      parentLinks.push({ link: `/v4/core/capacity/${model.id}`, label: planName });
    }

    if (loading) {
      return (
        <Page title={planName} parentLinks={parentLinks} scrolls>
          <Flex flex={1} justifyContent="center" alignItems="center" height="calc(100vh - 80px)">
            <Spinner />
          </Flex>
        </Page>
      );
    }

    return (
      <Page title="Configure Plan" parentLinks={parentLinks} scrolls forceShowExport>
        <Flex flexDirection="column" height="100%">
          <Flex alignItems="flex-start" justifyContent="space-between" mb={2}>
            <PageHeading
              title={planName || 'Capacity Plan'}
              mb={0}
              hideFavorite
              favoriteMetadata={{ resultType: 'capacityPlan' }}
            />
            {$auth.isAdministrator && model && (
              <Flex>
                <Button onClick={this.handleCancelPlan} text="Cancel" mr={1} />
                {!model.isNew && (
                  <ConfirmPopover onConfirm={this.onRemovePlan} entityName="Capacity Plan">
                    <Button intent="danger" text="Remove Capacity Plan" icon="trash" mr={1} />
                  </ConfirmPopover>
                )}
                <SubmitButton
                  onSubmit={this.handleSavePlan}
                  text="Save Capacity Plan"
                  disabled={!form.dirty || !form.valid}
                  loading={
                    saving || (model && (model.isRequestActive('creating') || model.isRequestActive('updating')))
                  }
                />
              </Flex>
            )}
          </Flex>
          <FlexColumn width="100%" height={$app.isExport ? 'auto' : 'calc(100vh - 234px)'} minHeight={500}>
            <PageTabs>
              <PageTab
                id="configure"
                title="Capacity Computation"
                panel={model && <CapacityPlanConfigure hasInterfaces={planInterfaceCount > 0} />}
              />
              <PageTab
                id="interfaces"
                title={
                  <Text color={planInterfaceCount > 0 ? undefined : 'danger'}>
                    <Tag
                      round
                      textAlign="center"
                      mr={1}
                      minimal={planInterfaceCount > 0}
                      intent={planInterfaceCount > 0 ? 'none' : 'danger'}
                    >
                      {numberWithCommas(planInterfaceCount)}
                    </Tag>
                    Interfaces
                  </Text>
                }
                panel={<CapacityPlanInterfaces showErrors={showErrors} />}
              />
            </PageTabs>
          </FlexColumn>
        </Flex>
      </Page>
    );
  }
}

export default EditCapacityPlan;
