function equalsEquals(obj1, obj2) {
  if (typeof obj1 === 'object') {
    if (typeof obj2 === 'object') {
      const keys = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys.length !== keys2.length) {
        return false;
      }

      return keys.every((key) => equalsEquals(obj1[key], obj2[key]));
    }

    return false;
  }

  /* eslint-disable eqeqeq */
  return obj1 == obj2;
}

function validOptionsValidation(val) {
  let list;

  if (val) {
    list = this.getParameters();

    if (Array.isArray(val)) {
      for (let i = 0; i < val.length; i += 1) {
        if (list.find((currVal) => equalsEquals(currVal, val[i])) === undefined) {
          return false;
        }
      }
    } else {
      return list.find((currVal) => equalsEquals(currVal, val)) !== undefined;
    }
  }

  return true;
}

export default {
  name: 'validOptions',
  message: ':attribute has an invalid selection',
  fn: validOptionsValidation
};
