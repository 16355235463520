import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import storeLoader from 'app/stores/storeLoader';
import TestStatusSummary from 'app/views/synthetics/components/dashboard/TestStatusSummary';
import WidgetFrame from '../WidgetFrame';

@storeLoader('$syn.tests', '$syn.agents')
@inject('$syn')
@observer
class TestStatusSummaryWidget extends Component {
  constructor(props) {
    super(props);

    const { $syn } = props;

    $syn.tests.loadTestHealth();
  }

  render() {
    const { $syn, canCustomize, onRemove } = this.props;

    return (
      <WidgetFrame canCustomize={canCustomize} onRemove={onRemove} title="Test Status Summary">
        <TestStatusSummary loading={$syn.tests.healthLoading} isWidgetView />
      </WidgetFrame>
    );
  }
}

export default TestStatusSummaryWidget;
