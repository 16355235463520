import { observer } from 'mobx-react';
import { ReactComponent as PeeringdbIcon } from 'app/assets/logos/peeringdb-icon.svg';
import { ReactComponent as RouterIcon } from 'app/assets/icons/router_icon.svg';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import ViewInExplorerButton from 'app/components/dataviews/tools/ViewInExplorerButton';
import ExpandableBox from 'app/components/ExpandableBox';
import $colors from 'app/stores/colors/$colors';
import { getShortName } from 'app/util/queryResults';
import { greekIt, reduceFraction } from 'app/util/utils';
import {
  Box,
  Callout,
  Divider,
  EmptyState,
  Flex,
  Grid,
  Heading,
  Highcharts,
  HTMLTable,
  Icon,
  Link,
  Spinner,
  Suspense,
  Text,
  Tooltip
} from 'core/components';
import { toDecimal } from 'core/util/greekPrefixing';
import React, { Component } from 'react';
import { Remarkable } from 'remarkable';
import remarkableExtLink from 'remarkable-extlink';
import { useTheme, withTheme } from 'styled-components';
import { Form } from 'core/form';
import TrafficCostsBox from 'app/views/edge/trafficcost/TrafficCostsBox';
import { BlankIcon, CommonIcon, ExchangeIcon, FacilityIcon } from './PeeringMapIcons';
import PeeringMap from './PeeringMap';
import PolicyType from './PolicyType';

const md = new Remarkable();
md.use(remarkableExtLink, { host: window.location.host });

function getResultValue(results, key, agg) {
  return results.find({ key })?.get(agg) || 0;
}

function getRatio(inbound, outbound) {
  const inboundMax = getResultValue(inbound, 'Total', 'max_bits_per_sec');
  const outboundMax = getResultValue(outbound, 'Total', 'max_bits_per_sec');

  if (inboundMax === 0 && outboundMax === 0) {
    return '0 : 0';
  }

  if (inboundMax === 0) {
    return '0 : 1';
  }

  if (outboundMax === 0) {
    return '1 : 0';
  }

  return reduceFraction(inboundMax, outboundMax, true).join(' : ');
}

function getColors(allResults) {
  const { qualitativeColors: colors } = $colors;
  const totals = {};

  allResults.forEach((results) => {
    if (results) {
      results.nonOverlayRows.forEach((result) => {
        const key = result.get('key');

        totals[key] ??= 0;
        totals[key] += result.get('p95th_bits_per_sec') || 0;
      });
    }
  });

  const colorEntries = Object.entries(totals)
    .sort((a, b) => b[1] - a[1])
    .map(([key], idx) => [key, colors[idx % colors.length]]);

  return Object.fromEntries(colorEntries);
}

function DataGrid({ children, ...rest }) {
  return (
    <Grid gridTemplateColumns="minmax(33%, auto) 1fr" gridRowGap="4px" {...rest}>
      {children}
    </Grid>
  );
}

function DataRow({ label, value, display, fallback = 'None', title = value }) {
  return (
    <>
      <Text small muted>
        {label}
      </Text>
      <Text small ellipsis muted={!value} title={title}>
        {value ? display || value : fallback}
      </Text>
    </>
  );
}

function ExternalLink({ to }) {
  if (!to) {
    return null;
  }

  return (
    <a href={to} target="_blank" rel="noreferrer">
      {to}
    </a>
  );
}

function TrafficChart({ results, colors }) {
  const theme = useTheme();

  const sum = results.nonOverlayRows.reduce((s, result) => s + (result.get('p95th_bits_per_sec') || 0), 0);
  const total = getResultValue(results, 'Total', 'p95th_bits_per_sec');

  const backgroundColor = theme.colors.appBackground;
  const data = results.nonOverlayRows.map((row) => ({
    name: getShortName('i_src_connect_type_name', row.get('key')),
    value: row.get('p95th_bits_per_sec'),
    color: colors[row.get('key')]
  }));

  const options = {
    chart: { type: 'bar', height: 14, spacing: [0, 0, 0, 0], backgroundColor },
    credits: { enabled: false },
    title: { text: null },
    xAxis: { categories: [''] },
    yAxis: { labels: { enabled: false }, title: { enabled: false }, min: 0, gridLineWidth: 0 },
    legend: { enabled: false },
    tooltip: { enabled: false },
    plotOptions: {
      series: {
        stacking: 'percent',
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 2,
        borderColor: backgroundColor,
        enableMouseTracking: false
      }
    },
    series: data.map(({ name, value, color }) => ({ name, data: [value], color })).reverse()
  };

  return (
    <>
      <Text fontWeight="bold">
        {greekIt(total, { fix: 0, suffix: 'bps', forcePrefix: results.prefix.bytes }).displayFull}
      </Text>
      <Box borderRadius={8} overflow="hidden" mt="4px">
        <Highcharts options={options} styledMode={false} />
      </Box>
      <Grid gridTemplateColumns="auto 1fr auto auto" gridGap="12px" gridRowGap="4px" mt={1}>
        {data
          .filter(({ value }) => value > 0)
          .map(({ name, value, color }) => (
            <React.Fragment key={name}>
              <Box width={12} height={12} bg={color} borderRadius="50%" />
              <Text small fontWeight="medium" title={name} ellipsis>
                {name}
              </Text>
              <Text small textAlign="right">
                {greekIt(value, { fix: 0, suffix: 'bps', forcePrefix: results.prefix.bytes }).displayFull}
              </Text>
              <Text small muted textAlign="right">
                {toDecimal((value / sum) * 100, 1)}%
              </Text>
            </React.Fragment>
          ))}
      </Grid>
    </>
  );
}

function TrafficProfile({ asn, details, inbound, outbound, countries }) {
  if (inbound.results.size === 0 && outbound.results.size === 0) {
    return (
      <>
        {countries && countries.length > 0 && (
          <Text as="div" mb={1} small muted>
            Filtered for {countries.join(', ')}
          </Text>
        )}
        <Callout
          title="No flow telemetry data to display"
          icon={<Icon icon={RouterIcon} css={{ 'svg path': { fill: 'currentColor' } }} />}
          intent="warning"
          pl={4}
        >
          <Text runningText>
            <ul>
              <li>In : out ratio</li>
              <li>Inbound connectivity mix</li>
              <li>Outbound connectivity mix</li>
            </ul>
          </Text>
        </Callout>
      </>
    );
  }

  const ratio = getRatio(inbound.results, outbound.results);
  const colors = getColors([inbound.results, outbound.results]);

  return (
    <>
      {countries && countries.length > 0 && (
        <Text as="div" mb={1} small muted>
          Filtered for {countries.join(', ')}
        </Text>
      )}
      <DataGrid>
        <DataRow
          label="In : Out Ratio"
          value={ratio}
          display={
            <Flex gap="6px">
              {details?.data.policy_ratio && (
                <Tooltip content="Note: Peer has a ratio requirement">
                  <Icon icon="error" color="danger" iconSize={12} style={{ display: 'block' }} />
                </Tooltip>
              )}
              <Text fontWeight="bold">{ratio}</Text>
            </Flex>
          }
        />
      </DataGrid>

      <DataGrid my={inbound.results.size > 0 ? 2 : '4px'}>
        <DataRow
          label="Inbound"
          value={inbound.results.size > 0}
          display={<TrafficChart {...inbound} colors={colors} />}
          fallback={`No inbound traffic to AS${asn}`}
          title={null}
        />
      </DataGrid>

      <DataGrid mt={outbound.results.size > 0 ? 2 : '4px'}>
        <DataRow
          label="Outbound"
          value={outbound.results.size > 0}
          display={<TrafficChart {...outbound} colors={colors} />}
          fallback={`No outbound traffic from AS${asn}`}
          title={null}
        />
      </DataGrid>

      <Text as="div" mt={2} small>
        <Link to={`/v4/service-provider/market-intel/network/${asn}`}>
          See Kentik Market Intelligence rankings
          <Icon icon="chevron-right" iconSize={12} ml="2px" />
        </Link>
      </Text>
    </>
  );
}

function PolicyLocations({ value }) {
  const iconMap = {
    Preferred: <Icon color="success" icon="success" iconSize={12} />,
    'Required - US': <Icon color="warning" icon="warning-sign" iconSize={12} />,
    'Required - EU': <Icon color="warning" icon="warning-sign" iconSize={12} />,
    'Required - International': <Icon color="danger" icon="error" iconSize={12} />
  };

  return (
    <Flex gap="6px">
      {iconMap[value] || null}
      <div>{value}</div>
    </Flex>
  );
}

const fields = {
  mapMarkerTypes: {
    label: 'Type',
    defaultValue: 'fac,ix',
    options: [
      { label: 'All', value: 'fac,ix', icon: <BlankIcon /> },
      { label: 'Peering Site (Facility)', value: 'fac', icon: <FacilityIcon /> },
      { label: 'IX (Exchange)', value: 'ix', icon: <ExchangeIcon /> }
    ]
  },
  countries: {
    label: 'Country',
    defaultValue: []
  },
  common: {
    label: 'Common',
    defaultValue: '',
    options: [
      { label: 'All', value: '', icon: <BlankIcon /> },
      { label: 'Common', value: 'true', icon: <CommonIcon common /> },
      { label: 'Not Common', value: 'false', icon: <CommonIcon /> }
    ]
  },
  search: {
    label: 'Search',
    defaultValue: ''
  },
  vizTypes: {
    label: 'Visualization',
    defaultValue: 'map,table',
    options: [
      { label: 'Map + Table', value: 'map,table' },
      { label: 'Map', value: 'map' },
      { label: 'Table', value: 'table' }
    ]
  }
};

const formOptions = {
  name: 'PeeringMapFilters',
  groups: {
    filters: ['countries', 'common']
  }
};

@Form({ fields, options: formOptions })
@withTheme
@observer
export default class PeeringInfo extends Component {
  get trafficProfileQuery() {
    const { asn, form } = this.props;
    const filters = form.getFieldGroupValues('filters');

    return {
      aggregateTypes: ['p95th_bits_per_sec', 'max_bits_per_sec'],
      filters: {
        connector: 'All',
        filterGroups: [
          {
            connector: 'All',
            filters: [
              { filterField: 'src_as', operator: '=', filterValue: `${asn}` },
              { filterField: 'i_src_network_bndry_name', operator: '=', filterValue: 'external' },
              ...(filters.countries.length > 0
                ? [
                    {
                      filterField: 'src_geo',
                      operator: '=',
                      filterValue: filters.countries.join(',')
                    },
                    {
                      filterField: 'dst_geo',
                      operator: '=',
                      filterValue: filters.countries.join(',')
                    }
                  ]
                : [])
            ]
          }
        ]
      },
      lookback_seconds: 604800,
      metric: ['i_src_connect_type_name'],
      mirror: true,
      show_overlay: false,
      topx: 0,
      viz_type: 'table'
    };
  }

  get trafficProfileExplorerQuery() {
    return {
      ...this.trafficProfileQuery,
      topx: 8,
      viz_type: 'stackedArea'
    };
  }

  renderTrafficProfileSection() {
    const { asn, details, form } = this.props;
    const { countries } = form.getFieldGroupValues('filters');
    return (
      <>
        <Flex gap="6px" alignItems="center" mb={1}>
          <Heading level={5} mb={0}>
            Traffic Profile
          </Heading>
          <ViewInExplorerButton
            query={this.trafficProfileExplorerQuery}
            text={null}
            title="View in Data Explorer"
            openInNewWindow
          />
        </Flex>
        <LightweightDataViewWrapper
          query={this.trafficProfileQuery}
          checkDependencies={false}
          key={countries.join(',')}
        >
          {(inbound) => (
            <LightweightDataViewWrapper query={inbound.queryModel.invert()} checkDependencies={false}>
              {(outbound) => (
                <Suspense loading={inbound.loading || outbound.loading}>
                  <TrafficProfile
                    asn={asn}
                    details={details}
                    inbound={inbound}
                    outbound={outbound}
                    countries={countries}
                  />
                </Suspense>
              )}
            </LightweightDataViewWrapper>
          )}
        </LightweightDataViewWrapper>
      </>
    );
  }

  render() {
    const { details, loading, onModelSelect, selectedModel, theme } = this.props;
    const backgroundColor = theme.colors.appBackground;

    if (loading) {
      return <Spinner />;
    }

    if (!details) {
      return (
        <Flex flex="1 auto" gap={3}>
          <Box flex="0 0 350px">{this.renderTrafficProfileSection()}</Box>
          <Box flex="1 auto">
            <EmptyState icon={<PeeringdbIcon />} description="No PeeringDB record available for this ASN" />
          </Box>
        </Flex>
      );
    }

    return (
      <Flex flex="1 auto" gap={3}>
        <Box flex="0 0 350px">
          {this.renderTrafficProfileSection()}

          <Divider my={3} />

          <Heading level={5}>Info</Heading>
          <DataGrid>
            <DataRow label="AKA" value={details.data.aka} />
            <DataRow
              label="Website"
              value={details.data.website}
              display={<ExternalLink to={details.data.website} />}
            />
          </DataGrid>

          <Heading level={5} mt={3}>
            Network Attributes
          </Heading>
          <DataGrid>
            <DataRow
              label="AS-Set / Route Set"
              value={details.data.irr_as_set}
              display={<Text monospace>{details.data.irr_as_set}</Text>}
            />
            <DataRow
              label="Looking Glass"
              value={details.data.looking_glass}
              display={<ExternalLink to={details.data.looking_glass} />}
            />
            <DataRow label="Network Type" value={details.data.info_type} />
            <DataRow label="IPv4 Prefixes" value={details.data.info_prefixes4} />
            <DataRow label="IPv6 Prefixes" value={details.data.info_prefixes6} />
            <DataRow label="Traffic Levels" value={details.data.info_traffic} fallback="Not Disclosed" />
            <DataRow label="Traffic Ratios" value={details.data.info_ratio} />
            <DataRow label="Geo Scope" value={details.data.info_scope} />
          </DataGrid>

          <Heading level={5} mt={3}>
            Notes
          </Heading>
          {details.data.notes ? (
            <ExpandableBox maxHeight={80} fadeTo={backgroundColor} pb="40px">
              <Text small runningText>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: md.render(details.data.notes || '') }} />
              </Text>
            </ExpandableBox>
          ) : (
            <Text muted>None</Text>
          )}

          <Heading level={5} mt={3}>
            Peering Policy
          </Heading>
          <DataGrid>
            <DataRow
              label="Policy URL"
              value={details.data.policy_url}
              display={<ExternalLink to={details.data.policy_url} />}
            />
            <DataRow
              label="Policy Type"
              value={details.data.policy_general}
              fallback=""
              display={<PolicyType value={details.data.policy_general} />}
            />
            <DataRow
              label="Multiple Locations"
              value={details.data.policy_locations}
              fallback=""
              display={<PolicyLocations value={details.data.policy_locations} />}
            />
            <DataRow label="Ratio Requirement" value={details.data.policy_ratio ? 'Yes' : 'No'} />
            <DataRow label="Contract Requirement" value={details.data.policy_contracts} />
          </DataGrid>

          {details.contacts?.length > 0 && (
            <>
              <Heading level={5} mt={3}>
                Contacts
              </Heading>
              <HTMLTable condensed striped>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Phone / Email</th>
                  </tr>
                </thead>
                <tbody>
                  {details.contacts.map((contact) => (
                    <tr key={contact.id}>
                      <td>{contact.name}</td>
                      <td>{contact.role}</td>
                      <td>
                        {contact.phone && (
                          <div>
                            <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                          </div>
                        )}
                        {contact.email && (
                          <div>
                            <a href={`mailto:${contact.email}`}>{contact.email}</a>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </HTMLTable>
            </>
          )}

          <TrafficCostsBox
            entityLabel={details.data.name}
            state={{
              filterField: 'src_as',
              filterValue: details.asn
            }}
            mt={3}
          />
        </Box>

        <Box flex="1 auto">
          <PeeringMap
            key={details.asn}
            facilities={details.facilities}
            exchanges={details.exchanges}
            onModelSelect={onModelSelect}
            selectedModel={selectedModel}
          />
        </Box>
      </Flex>
    );
  }
}
