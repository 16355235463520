import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { dateFormat } from 'highcharts';
import { Suspense, Flex, Highcharts, Box, Text, Button, Tag, Icon } from 'core/components';
import { OPERATOR_LABELS } from 'app/views/alerting/policies/PolicyDetails/conditions/ConditionsTags';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import { VirtualizedTable } from 'core/components/table';
import { timezone } from 'core/util/dateUtils';
import TargetRenderer from 'app/views/protect/ddos/analyze/components/TargetRenderer';
import MetricRenderer from 'app/views/protect/ddos/analyze/components/MetricRenderer';
import NmsNativeAlertMetricListRenderer from 'app/views/alerting/components/nms/NmsNativeAlertMetricListRenderer';
import NmsNativeAlertDimensionRenderer from 'app/views/alerting/components/nms/NmsNativeAlertDimensionRenderer';
import AlertCollection from 'app/stores/alerting/AlertCollection';
import { Field, FormComponent, Select } from 'core/form';

import { DEFAULT_QUALITATIVE_PALETTES } from 'app/stores/colors/Colors';
import LoadingSkeleton from 'app/views/metrics/LoadingSkeleton';
import LargeLabeledValue from 'app/components/LargeLabeledValue';
import { fetchAlertsParameters } from './shared';
import withConfigOptions from '../withConfigOptions';

// TODO: Set default sort another way
const DEFAULT_ALERT_FETCH_OPTIONS = {
  ...fetchAlertsParameters
};

@inject('$insights', '$alerting', '$colors')
@withConfigOptions
@withRouter
@observer
class AlertingHistoryWidget extends Component {
  static defaultProps = {
    contentRef: React.createRef()
  };

  state = {
    loading: true,
    hasLoaded: false,
    alertFilters: DEFAULT_ALERT_FETCH_OPTIONS,
    alertCollection: new AlertCollection()
  };

  componentDidMount() {
    this.fetchAlerts();
  }

  componentWillUnmount() {
    if (this.resizeWatcher && this.el) {
      this.resizeWatcher.unobserve(this.el);
    } else if (this.resizeWatcher) {
      this.resizeWatcher.disconnect();
    }
  }

  get config() {
    const { config } = this.props;
    const { groupBy = 'policyName' } = config;
    return { groupBy };
  }

  fetchAlerts() {
    const { alertFilters, alertCollection } = this.state;

    alertCollection.setServerFilter(alertFilters).then(() => {
      this.setState({ loading: false, hasLoaded: true });
    });
  }

  handleSave(form) {
    const { handleSaveConfiguration } = this.props;
    handleSaveConfiguration(form);
  }

  generateChartConfig = () => {
    const { alertCollection } = this.state;
    const { groupBy } = this.config;
    const series = alertCollection.getAlertingOverviewData(groupBy);
    const tickMargin = 12 * 3600 * 1000; // 12 hours in milliseconds

    let minX;
    let maxX;

    series.forEach(({ data }) => {
      const nextMax = data[0];
      const nextMin = data[data.length - 1];
      maxX = !maxX || nextMax.x > maxX ? nextMax.x : maxX;
      minX = !minX || nextMin.x < minX ? nextMin.x : minX;
    });

    return {
      series,
      chart: {
        type: 'column',
        events: {
          load() {
            const chart = this;
            const max = chart?.series?.[0]?.dataMax;
            // Allow room for stack labels
            if (max) {
              chart.yAxis[0].update({ max: max * 1.25 });
            }
          }
        }
      },

      title: {
        text: ''
      },

      xAxis: {
        type: 'datetime',
        min: minX - tickMargin,
        max: maxX + tickMargin,
        tickInterval: tickMargin * 2, // 1 day in milliseconds
        labels: {
          formatter() {
            return dateFormat('%m-%d', this.value);
          }
        },
        title: {
          text: timezone.value
        }
      },
      legend: {
        enabled: false
      },
      yAxis: {
        title: {
          text: 'Alert Count'
        },
        stackLabels: {
          align: 'center',
          textAlign: 'center',
          enabled: true,
          formatter() {
            return `<b>${this.total}</b>`;
          }
        }
      },

      tooltip: {
        formatter() {
          return `${this.key}: ${this.y}`;
        }
      },

      plotOptions: {
        column: {
          stacking: 'normal'
        },
        series: {
          allowPointSelect: true,
          groupPadding: 0.1,
          point: {
            events: {
              select: this.handleSelectSeries,
              unselect: this.handleUnselectSeries
            }
          }
        }
      }
    };
  };

  handleSetAlertFilters = (filters) => {
    const { alertFilters } = this.state;
    const { alertIds, ...restFilters } = filters;
    const newAlertFilters = { ...alertFilters, ...restFilters };

    if (alertIds.length > 0) {
      newAlertFilters.filter = { alarmIds: alertIds };
    }

    this.setState({ alertFilters: newAlertFilters }, () => {
      this.fetchAlerts();
    });
  };

  handleResetAlertFilters = () => {
    this.setState({ alertFilters: DEFAULT_ALERT_FETCH_OPTIONS }, () => {
      this.fetchAlerts();
    });
  };

  chartCallback = (chart) => {
    this.chart = chart.container ? chart : null;
    this.el = chart.container;
    this.resizeWatcher = new ResizeObserver(() => {
      try {
        this.chart.reflow();
      } catch (e) {
        // OK to ignore
      }
    });
    this.resizeWatcher.observe(this.el);
  };

  // when a Series is selected, filter the Table below to only include those Alerts
  handleSelectSeries = (e) => {
    const { alertIds } = e.target;
    this.handleSetAlertFilters({ alertIds });
  };

  handleUnselectSeries = (e) => {
    // if there are new selected points, we don't need to reset
    if (e.target.series?.chart?.getSelectedPoints().length) {
      return false;
    }

    return this.handleResetAlertFilters();
  };

  renderConfigurePanel() {
    const { handleCancelConfiguration, model, config } = this.props;
    const { groupBy } = this.config;

    const fields = {
      groupBy: {
        label: 'Visualization Grouping',
        helpText: 'Choose the grouping for the visualization.',
        defaultValue: groupBy,
        options: [
          {
            label: 'Policy Name',
            value: 'policyName'
          },
          {
            label: 'Severity',
            value: 'severity'
          }
        ]
      }
    };

    return (
      <FormComponent fields={fields} model={model} options={{ name: 'Configure Alert History', large: true }}>
        {({ form }) => (
          <Flex p={2} gap={3}>
            <Box>
              <Field name="groupBy" large>
                <Select />
              </Field>

              <Flex gap={1} mt={3}>
                {config.lookback !== undefined && config.interval !== undefined && (
                  <Button text="Cancel" flex={1} minWidth={100} onClick={() => handleCancelConfiguration(form)} />
                )}
                <Button
                  text="Save"
                  flex={1}
                  intent="primary"
                  disabled={!form.valid}
                  onClick={() => this.handleSave(form)}
                />
              </Flex>
            </Box>
          </Flex>
        )}
      </FormComponent>
    );
  }

  renderWidgetContent() {
    const { history, height } = this.props;
    const { hasLoaded, alertCollection } = this.state;

    const columns = [
      {
        label: 'Status',
        name: 'status',
        id: 'status',
        value: 'status',
        computed: true,
        width: 105,
        ellipsis: false,
        renderer: ({ model, value }) => (
          <Flex alignItems="center" gap="4px">
            {model.statusIcon && <Icon icon={model.statusIcon} color={model.statusColor} iconSize={12} />}
            {value}
          </Flex>
        )
      },
      {
        label: 'Policy',
        name: 'policyName',
        id: 'policyName',
        value: 'policyName',
        computed: true,
        flexBasis: 150,
        minWidth: 150
      },
      {
        label: 'Dimensions',
        name: 'primaryDimension',
        id: 'primaryDimension',
        value: 'primaryDimension',
        flexBasis: 200,
        minWidth: 200,
        computed: true,
        ellipsis: false,
        renderer: ({ model }) =>
          model.isNmsPolicy ? (
            <NmsNativeAlertDimensionRenderer maxDimensionsToShow={3} alertModel={model} />
          ) : (
            <TargetRenderer model={model} />
          )
      },
      {
        label: 'Threshold Metric',
        name: 'metric',
        id: 'metric',
        value: 'metric',
        sortable: false,
        ellipsis: false,
        minWidth: 100,
        renderer: ({ model }) => {
          let operator = '';
          if (model.threshold?.conditions?.length > 0) {
            operator = model.threshold.conditions[0].operator;
          }
          const operatorLabel = OPERATOR_LABELS[operator];
          return (
            <Flex gap="4px" alignItems="center">
              {!model.isNmsPolicy && model.isStateChangeAlert && operatorLabel !== '=' && <Text>{operatorLabel}</Text>}
              {!model.isNmsPolicy && <MetricRenderer model={model} showLabel={false} />}
              {model.isNmsPolicy && <NmsNativeAlertMetricListRenderer alertModel={model} numMetricsToShow={2} />}
            </Flex>
          );
        }
      },
      {
        label: 'Duration',
        name: 'displayDuration',
        id: 'displayDuration',
        value: 'displayDuration',
        computed: true,
        sortable: true,
        width: 170,
        minWidth: 120
      }
    ];

    function handleRowClick(model) {
      history.push(model.alertDetailLink);
    }

    if (!hasLoaded) {
      return <Text muted>Loading...</Text>;
    }

    return (
      <Flex flexDirection="column" flex={1} height="100%">
        <Flex flexDirection="row" justifyContent="flex-end" alignItems="center" m={1} gap={2}>
          <LargeLabeledValue flexBasis={80} label="Ack Required" value={alertCollection.countAckReq} />
          <LargeLabeledValue flexBasis={80} label="Cleared" value={alertCollection.countCleared || '0'} />
          <LargeLabeledValue flexBasis={80} label="Active" value={alertCollection.countAlarm || '0'} />
        </Flex>

        <Flex flexDirection="column" flex={1} height="100%">
          <Box height={height / 3}>
            <Highcharts
              height={height / 3}
              width="100%"
              callback={this.chartCallback}
              colors={DEFAULT_QUALITATIVE_PALETTES.qualitative4}
              options={this.generateChartConfig()}
            />
          </Box>
          <Flex flexDirection="column" flex={1} height="100%">
            <VirtualizedTable
              flexed
              collection={alertCollection}
              columns={columns}
              onRowClick={(model) => handleRowClick(model)}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  }

  render() {
    const { canCustomize, onRemove, handleShowConfigurePanel, isConfigurePanelOpen } = this.props;
    const { loading, hasLoaded } = this.state;

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        configAction={handleShowConfigurePanel}
        onRemove={onRemove}
        title={
          isConfigurePanelOpen ? (
            'Configure'
          ) : (
            <Flex alignItems="center" gap={1}>
              Alert History
              <Tag minimal round small fontWeight="normal">
                Last 7 days
              </Tag>
            </Flex>
          )
        }
      >
        <Suspense loading={loading && !hasLoaded} fallback={<LoadingSkeleton />}>
          {isConfigurePanelOpen && this.renderConfigurePanel()}
          {!isConfigurePanelOpen && this.renderWidgetContent()}
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default AlertingHistoryWidget;
