import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Flex, Box, Button, Popover, Text } from 'core/components';
import { Form, Field, InputGroup, Select } from 'core/form';
// import CategorySelector from 'views/Admin/Templates/CategorySelector';

const fields = {
  name: {
    label: 'Name',
    rules: 'required',
    messages: {
      required: 'A name is required'
    }
  },

  description: {
    label: 'Description'
  },

  category_id: {
    label: 'Category',
    rules: 'required'
  },

  company_id: {
    label: 'Company',
    rules: 'required'
  },

  share_level: {
    label: 'Share Level',
    defaultValue: 'org'
  }
};

@Form({ fields })
@inject('$sudo', '$basicExplorer', '$queryTemplates')
@observer
export default class CreateTemplateButton extends Component {
  state = {
    isOpen: false,
    saving: false,
    template: undefined
  };

  componentDidUpdate() {
    const { $sudo } = this.props;
    const { isOpen } = this.state;
    if (isOpen && !$sudo.companiesCollection.hasFetched) {
      $sudo.companiesCollection.fetch();
    }
  }

  handleOpenPopover = () => {
    const { $queryTemplates, form } = this.props;
    const template = $queryTemplates.collection.forge();
    form.validate();
    this.setState({ isOpen: true, template });
  };

  handleCancel = () => {
    const { form } = this.props;
    this.setState({ isOpen: false, template: undefined });
    form.reset();
  };

  handleSubmit = () => {
    const { form, $queryTemplates, dataview } = this.props;
    const { template } = this.state;
    this.setState({ saving: true });

    const attributes = form.getValues();

    const { queryBuckets } = dataview;
    $queryTemplates.createTemplateFromQueries(template, attributes, queryBuckets).then(() => {
      this.setState({ saving: false, template: undefined });
    });
  };

  render() {
    const { $sudo, form } = this.props;
    const { isOpen, saving } = this.state;

    return (
      <Popover
        isOpen={isOpen}
        content={
          <Box p={2} style={{ width: 400 }}>
            <Text muted>
              <small>
                Create a new Explorer Lite template. The templates base query will use the currently active query
                options.
              </small>
            </Text>
            <Field name="name" autoFocus>
              <InputGroup />
            </Field>
            <Flex>
              <Box mr={1} flex="1 1 auto">
                <Field name="company_id" options={$sudo.companiesCollection.selectOptions}>
                  <Select autoComplete exactMatch showFilter optionLimit={50} menuWidth={200} />
                </Field>
              </Box>
              <Box ml={1} flex="1 1 auto">
                {/* <CategorySelector template={template} field={form.getField('category_id')} /> */}
              </Box>
            </Flex>
            <Flex justifyContent="flex-end" pt={2}>
              <Button text="Cancel" disabled={saving} onClick={this.handleCancel} width={110} mr={1} />
              <Button
                className="pt-medium pt-intent-primary"
                text="Save"
                loading={saving}
                onClick={this.handleSubmit}
                disabled={!form.valid}
                width={110}
              />
            </Flex>
          </Box>
        }
      >
        <Button text="Create Template" onClick={this.handleOpenPopover} icon="plus" mr="4px" small />
      </Popover>
    );
  }
}
