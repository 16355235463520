import { addFilter, addFilterGroup, buildFilterGroup } from 'core/util/filters';
import $sites from 'app/stores/site/$sites';
import { BASE_TYPES } from '@kentik/ui-shared/query/queryUtils';

const filterMapping = {
  device_id: 'i_device_id',
  device_site: 'i_device_site_name',
  device_type: 'i_device_subtype',
  device_name: 'i_device_name',
  device_label: 'i_device_label',
  interface_name: 'i_input|output_interface_description',
  snmp_alias: 'i_input|output_snmp_alias',
  cidr: 'inet_src|dst_addr',
  asn: 'src|dst_as'
};

export default function transformConfigToFilters(config, options) {
  const { name } = options || {};
  const { devices, interface_name, snmp_alias, cidr, asn, custom_dimensions } = config;
  const { device_sites, device_name, device_labels, device_types } = devices;

  const filterGroup = buildFilterGroup({
    named: true,
    name: `mkp_preview:${name}`
  });

  if (device_sites.length || device_name.length) {
    addFilterGroup(filterGroup, 'Any');

    device_sites.forEach((deviceSiteId) => {
      const site = $sites.collection.get(deviceSiteId);
      if (site) {
        addFilter(filterGroup, filterMapping.device_site, '=', site.get('title'));
      }
    });

    device_name.forEach((deviceName) => {
      addFilter(filterGroup, filterMapping.device_name, '=', deviceName);
    });
  }

  if (device_labels.length || device_types.length) {
    if (!filterGroup.filterGroups.length) {
      addFilterGroup(filterGroup, 'Any');
    }

    device_labels.forEach((deviceLabel) => {
      addFilter(filterGroup, filterMapping.device_label, '=', deviceLabel);
    });
    device_types.forEach((deviceType) => {
      const hasBaseType = device_types.some((type) => BASE_TYPES.includes(type));
      addFilter(filterGroup, filterMapping.device_type, '=', deviceType);
      if (hasBaseType) {
        addFilter(filterGroup, 'i_device_type', '=', deviceType);
      }
    });
  }

  if (interface_name || snmp_alias) {
    addFilterGroup(filterGroup, 'Any');

    if (interface_name) {
      addFilter(filterGroup, filterMapping.interface_name, '~*', interface_name, filterGroup.filterGroups.length - 1);
    }

    if (snmp_alias) {
      addFilter(filterGroup, filterMapping.snmp_alias, '~*', snmp_alias, filterGroup.filterGroups.length - 1);
    }
  }

  if (cidr) {
    addFilterGroup(filterGroup, 'Any');
    const cidrs = cidr.split(',');
    cidrs.forEach((currCidr) => {
      if (currCidr) {
        addFilter(filterGroup, filterMapping.cidr, 'ILIKE', currCidr.trim(), filterGroup.filterGroups.length - 1);
      }
    });
  }

  if (asn) {
    addFilterGroup(filterGroup, 'Any');
    const asns = asn.split(',');
    asns.forEach((currAsn) => {
      if (currAsn) {
        addFilter(filterGroup, filterMapping.asn, '=', currAsn.trim(), filterGroup.filterGroups.length - 1);
      }
    });
  }

  if (custom_dimensions && custom_dimensions.length) {
    addFilterGroup(filterGroup, 'Any');
    custom_dimensions.forEach((customDimension) => {
      addFilter(
        filterGroup,
        `${customDimension.dimension}`,
        '=',
        customDimension.populator,
        filterGroup.filterGroups.length - 1
      );
    });
  }

  return filterGroup;
}
