import React, { Component } from 'react';
import { Table } from 'core/components/table';

export default class NotificationUsageListTable extends Component {
  render() {
    const { isLoading, usageCollection, columns } = this.props;

    return <Table loading={isLoading} collection={usageCollection} columns={columns} selectOnRowClick={false} />;
  }
}
