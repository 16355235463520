import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Box } from 'core/components';
import { Field, FormComponent, Select } from 'core/form';
import { SUPPORTED_MODELS } from 'shared/journeys/constants';

const options = {
  showPristineErrors: false,
  name: 'Sudo Options'
};

@observer
export default class SudoJourney extends Component {
  onChange = (args) => {
    const { journeyModel } = this.props;
    const { fieldValue } = args;
    const metadata = { ...journeyModel.get('metadata'), ...{ compare: { models: fieldValue } } };
    journeyModel.set('metadata', metadata);
  };

  render() {
    const { journeyModel } = this.props;
    const fields = {
      'metadata.compare.models': {
        options: [],
        label: 'Models',
        rules: ['required']
      }
    };
    fields['metadata.compare.models'].options = Object.keys(SUPPORTED_MODELS).map((id) => ({ value: id, label: id }));
    return (
      <Box>
        <FormComponent fields={fields} options={options} model={journeyModel} onChange={this.onChange}>
          <Field name="metadata.compare.models">
            <Select multi />
          </Field>
        </FormComponent>
      </Box>
    );
  }
}
