import React, { Component, Children } from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import { Box, Flex } from 'core/components';

const Tab = styled.div`
  align-items: center;
  background: none;
  border-radius: 0;
  display: flex;
  height: 50px;
  justify-content: center;
  margin: 0;
  min-width: 120px;
  overflow: visible;
  padding: 0 16px;
  position: relative;
  text-align: center;

  &[aria-selected='true'] {
    background-color: ${themeGet('colors.sidebarBackground')};
    border: none;
  }

  // scoot the tab over a bit so that the border isn't visible
  &:first-child {
    left: -1px;
    position: relative;

    &[aria-selected='true'] {
      border-bottom-left-radius: 0;
      border-left-color: transparent;
    }
  }
`;

class TabPanel extends Component {
  static defaultProps = {
    vertical: false
  };

  state = {
    activeTabId: null
  };

  static getDerivedStateFromProps(props, state) {
    const { activeTabId } = props;
    const { prevPropsActiveTabId } = state;

    return {
      prevPropsActiveTabId: activeTabId,
      activeTabId: prevPropsActiveTabId !== activeTabId ? activeTabId : state.activeTabId
    };
  }

  handleTabClick = (tabId) => {
    const { onTabChange } = this.props;

    this.setState({ activeTabId: tabId });

    if (onTabChange) {
      onTabChange(tabId);
    }
  };

  renderTabBar() {
    const { vertical, tools } = this.props;

    const tabList = (
      <Flex flexDirection={vertical ? 'column' : 'row'} className={classNames({ [Classes.TAB_LIST]: !tools })}>
        {this.renderTabs()}
      </Flex>
    );

    if (tools) {
      return (
        <Flex justifyContent="space-between" alignItems="center" className={Classes.TAB_LIST}>
          {tabList}
          <Box flex={1} mr={1}>
            {tools}
          </Box>
        </Flex>
      );
    }

    return tabList;
  }

  renderTabs() {
    const { children } = this.props;
    const { activeTabId } = this.state;

    return Children.map(children, (child) => {
      if (child) {
        const { tabId, tab } = child.props;

        return (
          <Tab
            key={tabId}
            className={Classes.TAB}
            onClick={() => this.handleTabClick(tabId)}
            tabIndex="0"
            aria-selected={activeTabId === tabId}
          >
            {tab}
          </Tab>
        );
      }
      return null;
    });
  }

  renderChildren() {
    const { children } = this.props;
    const { activeTabId } = this.state;

    return Children.map(children, (child) => {
      if (child && activeTabId === child.props.tabId) {
        return child;
      }
      return null;
    });
  }

  render() {
    const { vertical, flex, className: classNameProp } = this.props;
    const className = classNames(Classes.TABS, { [Classes.VERTICAL]: vertical }, classNameProp);
    return (
      <Flex flexDirection={vertical ? 'row' : 'column'} flex={flex} className={className}>
        {this.renderTabBar()}
        {this.renderChildren()}
      </Flex>
    );
  }
}

export default observer(TabPanel);
