// This method computes the i_sub_limit portion of the WHERE clause
const moment = require('moment');
const safelyParseJSON = require('../util/safelyParseJson');

const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';

const SOURCE_NAMES = {
  journeys: 'ui-app-journeys',
  data_explorer: 'ui-app-data-explorer',
  data_explorer_nlq: 'ui-app-data-explorer-nlq',
  nms_explorer: 'ui-app-nms-explorer',
  nms_nlq: 'ui-app-nms-nlq',
  saved_view: 'ui-app-savedviews',
  dashboard: 'ui-app-dashboard'
};

const SOURCE_URL = {
  '/v4/home': 'ui-app-observationdeck',
  '/v4/library/saved-views': 'ui-app-savedviews',
  '/v4/library/dashboards': 'ui-app-dashboard',
  '/v4/core/explorer': 'ui-app-dataexplorer',
  '/v4/explorer': 'ui-app-dataexplorer',
  '/v4/core/insights': 'ui-app-insights',
  '/v4/core/journeys': 'ui-app-journeys',
  '/v4/core': 'ui-app-networkexplorer',
  '/v4/nms/nlq': 'ui-app-nms-nlq',
  '/v4/nms/explorer': 'ui-app-nms-explorer',
  '/v4/nms/devices': 'ui-app-nms-device',
  '/v4/nms/interfaces': 'ui-app-nms-interface',
  '/v4/savedView': 'ui-app-savedviews',
  '/v4/dashboards': 'ui-app-dashboard',
  '/v4/kentik-map': 'ui-app-kentik-map',
  '/v4/settings/universal-agents': 'ui-app-universal-agent',
  '/v4/service-provider/cdn': 'ui-app-cdn',
  '/v4/service-provider/ott': 'ui-app-ott',
  '/v4/alerting/policies': 'ui-app-policies',
  '/v4/cloud/kube/': 'ui-app-kube',
  '/v4/synthetics/tests': 'ui-app-synthetics-tests',
  '/v4/alerting': 'ui-app-alerting'
};

const sourceUrlPrefix = Object.keys(SOURCE_URL);

function getApplicationName(path) {
  const urlPrefix = sourceUrlPrefix.find((url) => path.indexOf(url) === 0);
  const source = SOURCE_URL[urlPrefix];
  if (source) {
    return source;
  }
  const applicationName = path.replace('/v4/', 'ui-app-');
  const pathTokens = applicationName.split('/');

  return pathTokens.slice(0, Math.min(pathTokens.length, 2)).join('-');
}

/**
 * "{1,2,3}" => [1, 2, 3]
 * @param {string} s
 * @returns {number[]}
 */
function parseNumberArray(s) {
  if (!s) {
    return [];
  }

  return safelyParseJSON(`[${s.substring(1, s.length - 1).replaceAll('NULL', 'null')}]`) || [];
}

const BASE_TYPES = ['router', 'host-nprobe-basic', 'host-nprobe-dns-www'];

function getSubLimit(depth) {
  let sublimit = 10;
  if (depth < 100) {
    sublimit = depth * 4;
  } else if (depth < 150) {
    sublimit = depth * 3;
  } else if (depth < 250) {
    sublimit = depth * 2;
  } else if (depth < 500) {
    sublimit = 500;
  } else {
    sublimit = depth;
  }
  return Math.max(sublimit, 10);
}

// from queryBuilder
function getDuration({ lookback_seconds, starting_time, ending_time }) {
  // "this month"
  if (lookback_seconds === 1) {
    return moment.utc().diff(moment.utc().startOf('month'));
  }

  // "last month"
  if (lookback_seconds === 2) {
    return moment.utc().diff(moment.utc().subtract(1, 'month').startOf('month'));
  }

  return lookback_seconds || moment.utc(ending_time).diff(moment.utc(starting_time), 'second');
}

function getDefaultAggInterval(queryWidthSeconds, fastData) {
  if (fastData === 'Full' || (fastData === 'Auto' && queryWidthSeconds <= 24 * 60 * 60)) {
    if (queryWidthSeconds > 24 * 60 * 60) {
      return 600;
    }
    if (queryWidthSeconds > 3 * 60 * 60) {
      return 300;
    }
    return 60;
  }
  // fast data is 1 hour
  return 3600;
}

function getFlowAggIntervalOptions(
  {
    allow30sPolicyWindow,
    fastData,
    lookback_seconds,
    starting_time,
    ending_time,
    minsPolling,
    depth,
    filterGroups,
    reAggFn
  },
  options = {}
) {
  const { ignoreDefault } = options;
  const interval = getDuration({ lookback_seconds, starting_time, ending_time });
  const minsPollingSeconds = minsPolling * 60;

  const sublimit = getSubLimit(depth);
  const maxPolls = 500000 / sublimit;
  const maxChunkWidthHours = maxPolls / (60 / minsPolling);

  const defaultOption = {
    value: 'auto',
    label: 'Auto'
  };

  let intervalOptions = [
    {
      label: '5 Min',
      value: 300
    },
    {
      label: '10 Min',
      value: 600 // seconds
    },
    {
      label: '20 Min',
      value: 1200
    },
    {
      label: '30 Min',
      value: 1800
    },
    {
      label: '1 Hr',
      value: 3600
    },
    {
      label: '6 Hr',
      value: 3600 * 6
    },
    {
      label: '12 Hr',
      value: 3600 * 12
    },
    {
      label: '1 Day',
      value: 3600 * 24
    },
    {
      label: '1 Week',
      value: 3600 * 24 * 7
    }
  ];

  if (allow30sPolicyWindow) {
    intervalOptions = [
      {
        label: '15 Sec',
        value: 15
      },
      {
        label: '20 Sec',
        value: 20
      },
      {
        label: '30 Sec',
        value: 30
      },
      {
        label: '1 Min',
        value: 60
      }
    ].concat(intervalOptions);
  }

  const baseOptions = ignoreDefault ? [] : [defaultOption];
  return baseOptions.concat(
    intervalOptions.filter((opt) => {
      if (interval <= 900 && reAggFn === 'sum' && filterGroups.length) {
        return opt.value <= interval;
      }

      if (fastData === 'Fast' || (fastData === 'Auto' && (interval < 60 || interval >= 86400))) {
        return opt.value >= 3600 && opt.value <= interval;
      }

      if (!minsPolling) {
        return opt.value > getDefaultAggInterval(interval, fastData) && opt.value <= interval;
      }

      return opt.value > minsPollingSeconds && opt.value <= maxChunkWidthHours * 3600 && opt.value <= interval;
    })
  );
}

function getStartingTime({ lookback_seconds, starting_time }) {
  // "this month"
  if (lookback_seconds === 1) {
    return moment.utc().startOf('month').format(DEFAULT_DATETIME_FORMAT);
  }

  // "last month"
  if (lookback_seconds === 2) {
    return moment.utc().subtract(1, 'month').startOf('month').format(DEFAULT_DATETIME_FORMAT);
  }

  if (lookback_seconds >= 60) {
    return moment.utc().subtract(lookback_seconds, 'second').startOf('minute').format(DEFAULT_DATETIME_FORMAT);
  }

  return starting_time;
}

/**
 * @param {Object} query
 * @param {number[]} appProtocol
 * @returns {boolean}
 */
function decoupleFromFlow(query, appProtocol) {
  const decoupleProtocols = [5, 12];
  const daysThreshold = 30;
  return (
    decoupleProtocols.some((protocol) => appProtocol.includes(protocol)) &&
    moment.utc().diff(moment.utc(getStartingTime(query)), 'days') > daysThreshold
  );
}

module.exports = {
  getSubLimit,
  getDuration,
  getFlowAggIntervalOptions,
  getDefaultAggInterval,
  getStartingTime,
  decoupleFromFlow,
  BASE_TYPES,
  SOURCE_NAMES,
  getApplicationName,
  parseNumberArray
};
