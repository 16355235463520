import React from 'react';
import { observer } from 'mobx-react';
import HealthAgg from 'app/views/synthetics/components/HealthAgg';
import LoadingPanel from './LoadingPanel';

@observer
export default class HealthAggPanel extends React.Component {
  render() {
    const { test, onChangeResultTimeMs } = this.props;
    const { healthTimeline, resultTimeMs, results, hasResults, loading, timelineBounds, displayOptions } = test.results;
    const { delta, ...bounds } = timelineBounds;

    return (
      <LoadingPanel
        test={test}
        testResults={test.results}
        loading={loading}
        empty={healthTimeline.length === 0 || !hasResults}
      >
        <HealthAgg
          {...bounds}
          results={results}
          hasPing={test?.hasPing}
          time={resultTimeMs}
          onHover={onChangeResultTimeMs}
          hideAgentSelector={displayOptions.hideHealthAggAgentSelector}
        />
      </LoadingPanel>
    );
  }
}
