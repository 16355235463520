class FormStore {
  forms = {};

  registerForm(form) {
    if (!form.options.name) {
      console.warn(
        "Form registered with no name!!! This won't be available on $forms and won't be reported in submissions.",
        form.options,
        form.fieldConfigs
      );
      return;
    }

    this.forms[form.options.name] = form;
  }

  unregisterForm(form) {
    if (form.options.name) {
      delete this.forms[form.options.name];
    }
  }

  getAllUnsuppressedFormValues() {
    return Object.keys(this.forms).reduce((result, formName) => {
      const form = this.forms[formName];
      try {
        if (!form?.options?.suppressFormValues) {
          result[formName] = form.getValues();
        }
      } catch (e) {
        console.warn('Error getting form information for ', formName);
      }
      return result;
    }, {});
  }
}

const forms = new FormStore();

window.$forms = forms.forms;

export default forms;
