import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import get from 'lodash/get';
import noop from 'lodash/noop';
import styled, { css, withTheme } from 'styled-components';
import { HiFilter, HiOutlineFilter } from 'react-icons/hi';

import { Collection } from 'core/model';
import Box from 'core/components/Box';
import Button from 'core/components/Button';
import Callout from 'core/components/Callout';
import Flex, { FlexColumn } from 'core/components/Flex';
import Text from 'core/components/Text';
import CELL_TYPES from 'core/components/table/CELL_TYPES';
import CELL_ACTIONS from 'core/components/table/CELL_ACTIONS';
import VirtualizedTable from 'core/components/table/VirtualizedTable';

import TabSelector from 'app/components/detailTabs/TabSelector';
import AdminTableSearch from 'app/components/admin/AdminTableSearch';
import { MAX_DETAIL_TABS } from 'app/util/constants';
import { Spinner } from 'core/components';
import { getTabPref } from 'core/util/table';
import { Table } from 'core/components/table';
import { formatDateTime } from 'core/util/dateUtils';
import LabelMapping from 'app/components/labels/LabelMapping';

const FilterSidebar = styled(FlexColumn)`
  transition:
    border-width 0.3s ease-in-out,
    width 0.3s ease-in-out;
  border-width: 0;
  border-radius: 4px;
  width: 0;
  padding: 0;
  margin-right: 0;
  overflow-x: hidden;
  ${(props) =>
    props.open &&
    css`
      border-width: 1px;
      min-width: 280px;
      width: 280px;
      margin-right: 12px;
    `}
  box-shadow: none;
`;

/* We could enable this to get rid of things that push content down during the close transition
${props =>
  !props.open &&
  css`
    * {
      white-space: nowrap !important;
      overflow: hidden !important;
    }
  `}
*/

@inject('$auth', '$app', '$exports', '$setup')
@withTheme
@observer
class AdminTable extends Component {
  static propTypes = {
    // use regular table instead of virtualized one, on demand
    noVirtualizedTable: PropTypes.bool,
    // props to spread on the top-most container, meant for final style adjustments
    containerProps: PropTypes.object,
    // props to spread on the table container, meant for final style adjustments
    tableContainerProps: PropTypes.object,
    // array of strings of filters to exclude from search bar integration
    excludeSearchFilters: PropTypes.array,
    collection: PropTypes.instanceOf(Collection).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    columns: PropTypes.array.isRequired,

    /**
     * controlledFilterValues and onControlledFilterValueChange can be used by the parent Component to take control of the filterValues state.
     * You can use just controlledFilterValues to pass in an initial value for the filters (see getDerivedStateFromProps)
     * You can also pass in a function for onControlledFilterValueChange to take control of state updates. If you do, AdminTable will skip
     * the setState for filterValues and call your function instead. For an example, see app/views/alerting/Alerting.js
     */
    // eslint-disable-next-line react/no-unused-prop-types
    controlledFilterValues: PropTypes.object,
    onControlledFilterValueChange: PropTypes.func,

    // eslint-disable-next-line react/no-unused-prop-types
    defaultColumns: PropTypes.array,
    expandedRowRenderer: PropTypes.func,
    fetchCollectionOnMount: PropTypes.bool,
    filterSidebar: PropTypes.object,
    footer: PropTypes.object,
    groupSummaryLookup: PropTypes.func,
    kebabActions: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    multiSelect: PropTypes.bool,
    onRowClick: PropTypes.func,
    hideNonIdealState: PropTypes.bool,
    onSort: PropTypes.func,
    rowAlign: PropTypes.string,
    rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    rowTags: PropTypes.func,
    searchBar: PropTypes.bool,
    labelType: PropTypes.any,
    selectedLabelPlural: PropTypes.string,
    selectedLabelSingular: PropTypes.string,
    showLabelActions: PropTypes.bool,
    showColumnSelector: PropTypes.bool,
    // eslint-disable-next-line react/require-default-props
    maxVisibleColumns: PropTypes.number,
    sidebarToolbar: PropTypes.object,
    // eslint-disable-next-line react/require-default-props
    tabPrefId(props, propName) {
      if (props.showColumnSelector === true && (props[propName] === undefined || typeof props[propName] !== 'string')) {
        return new Error('Please provide a tabPrefId string!');
      }
      return null;
    },
    tableHeaderControls: PropTypes.object,
    tableHeaderControlsPosition: PropTypes.oneOf(['beforeSearch', 'afterSearch']),
    groupByControls: PropTypes.object,
    toolbarActions: PropTypes.array,
    useDiscreteFilters: PropTypes.bool,
    useLoadedTimeAgo: PropTypes.bool,
    hideHeader: PropTypes.bool
  };

  static defaultProps = {
    labelType: '',
    showLabelActions: false,
    noVirtualizedTable: false,
    containerProps: {},
    tableContainerProps: {},
    controlledFilterValues: undefined,
    onControlledFilterValueChange: undefined,
    excludeSearchFilters: [],
    defaultColumns: [],
    expandedRowRenderer: undefined,
    fetchCollectionOnMount: false,
    /**
     * If passing in a filterSidebar be sure to pass your components props down to the AdminFilterSidebar so that
     * its props can be correctly overridden. See the React.cloneElement statement below.
     * ie. MyFilterSidebar = props => <AdminFilterSidebar {...props} filterFields={filterFields} />;
     */
    filterSidebar: null,
    footer: null,
    groupSummaryLookup: noop,
    kebabActions: [],
    maxVisibleColumns: MAX_DETAIL_TABS,
    multiSelect: false,
    hideNonIdealState: false,
    onRowClick: noop,
    onSort: noop,
    rowAlign: undefined,
    rowHeight: 42,
    rowTags: noop,
    searchBar: true,
    selectedLabelPlural: 'items',
    selectedLabelSingular: 'item',
    sidebarToolbar: null,
    showColumnSelector: false,
    tableHeaderControls: null,
    tableHeaderControlsPosition: 'beforeSearch',
    groupByControls: null,
    toolbarActions: [],
    useDiscreteFilters: true,
    useLoadedTimeAgo: false,
    hideHeader: false
  };

  state = {
    loading: false,
    filterSidebarOpen: true,
    filterValues: {},
    filterFields: [],
    columns: [],
    visibleColumns: undefined
  };

  static getDerivedStateFromProps(props) {
    const { columns, showColumnSelector, defaultColumns, tabPrefId, controlledFilterValues } = props;
    const newState = showColumnSelector ? getTabPref(tabPrefId, columns, defaultColumns) : { columns };

    if (controlledFilterValues) {
      newState.filterValues = controlledFilterValues;
    }

    return newState;
  }

  componentDidMount() {
    const { $exports, collection, fetchCollectionOnMount } = this.props;

    if (fetchCollectionOnMount) {
      collection.fetch().then(() => {
        this.setState({ loading: false });
        collection.totalCount = collection.totalCount || collection.size;
      });
    }

    $exports.getSettings().then(({ searchTerm }) => {
      if (searchTerm) {
        this.handleSearch({ target: { value: searchTerm } });
      }
    });
  }

  get columns() {
    const { $app, showColumnSelector, kebabActions, kebabProps, multiSelect, multiSelectProps, maxVisibleColumns } =
      this.props;
    const { columns, visibleColumns } = this.state;

    if (multiSelect && !columns.find((col) => col.key === 'select')) {
      columns.unshift({
        key: 'select',
        value: 'select',
        id: 'select',
        type: CELL_TYPES.ACTION,
        actions: [CELL_ACTIONS.SELECT],
        padding: '6px 0 6px 16px !important',
        headerPadding: '4px 0 0 16px !important',
        justifyContent: 'center',
        width: 40,
        minWidth: 40,
        verticalAlign: 'top',
        ...multiSelectProps
      });
    }

    if (kebabActions.length > 0 && !columns.find((col) => col.key === 'kebab')) {
      columns.push({
        key: 'kebab',
        value: 'kebab',
        id: 'kebab',
        type: CELL_TYPES.ACTION,
        actions: [CELL_ACTIONS.KEBAB(kebabActions)],
        padding: '6px 12px 6px 16px !important',
        justifyContent: 'center',
        width: 40,
        minWidth: 40,
        ...kebabProps
      });
    }

    if (!$app.isExport && showColumnSelector) {
      const activeColumns = columns.map((column) => {
        if (visibleColumns) {
          return {
            ...column,
            visible: visibleColumns.includes(column.id) || column.type === CELL_TYPES.ACTION,
            always_active: column.type === CELL_TYPES.ALWAYS_ACTIVE
          };
        }
        return column;
      });

      const tabSelector = (
        <TabSelector
          tabs={activeColumns.filter(
            (col) => col.type === CELL_TYPES.VALUE || col.type === CELL_TYPES.ALWAYS_ACTIVE || !col.type
          )}
          visibleTabs={visibleColumns}
          maxTabs={maxVisibleColumns}
          onChange={this.handleColumnsChange}
          buttonProps={{ small: true, intent: 'none', minimal: false }}
          entityName="columns"
        />
      );
      const lastColumn = activeColumns[activeColumns.length - 1];

      if (lastColumn.type === CELL_TYPES.ACTION) {
        lastColumn.width = Math.max(lastColumn.width || 0, 100);
        lastColumn.label = tabSelector;
        lastColumn.paddingRight = 0;
      } else {
        activeColumns.push({
          type: CELL_TYPES.ACTION,
          width: 100,
          label: tabSelector,
          paddingRight: 0,
          actions: [],
          visible: true
        });
      }

      return activeColumns.filter((col) => col.visible);
    }

    columns.forEach((col) => {
      col.padding = col.padding ?? '6px 12px';
    });

    return columns;
  }

  handleColumnsChange = (columns) => {
    const { $setup, tabPrefId } = this.props;
    const tabPrefs = get($setup, 'settings.tabPrefs') || {};

    const { columns: prevColumns } = this.state;
    const [firstColumn] = prevColumns;
    const [lastColumn] = prevColumns.slice(-1);

    if (firstColumn.type === CELL_TYPES.ACTION) {
      columns.unshift(firstColumn);
    }
    if (lastColumn.type === CELL_TYPES.ACTION) {
      columns.push(lastColumn);
    }

    tabPrefs[tabPrefId] = columns.map(({ id, visible }) => ({ id, visible }));
    $setup.updateSettings({ tabPrefs });
  };

  handleToggleFilterSidebar = () => {
    const { filterSidebarOpen } = this.state;
    this.setState({ filterSidebarOpen: !filterSidebarOpen });
  };

  handleSearch = (e) => {
    const { $exports, collection, onSearch } = this.props;

    // If it needs to be handled externally e.g. server-side
    if (onSearch) {
      onSearch(e.target.value);
      return;
    }

    collection.filter(e.target.value);

    $exports.setHash({ searchTerm: e.target.value });
  };

  handleRemoveFilter = (filterType) => {
    const { $exports, collection, useDiscreteFilters, onControlledFilterValueChange } = this.props;
    const { filterValues } = this.state;
    const newFilterValues = { ...filterValues };

    newFilterValues[filterType] = '';

    if (onControlledFilterValueChange) {
      onControlledFilterValueChange(newFilterValues);
    } else {
      this.setState({ filterValues: newFilterValues });
    }

    if (useDiscreteFilters) {
      collection.removeDiscreteFilter(filterType);
      collection.filter();
    }

    $exports.getSettings().then(({ hashedFilters }) => {
      delete hashedFilters[filterType];
      $exports.setHash({ hashedFilters });
    });
  };

  handleFilterStateChange = (filterValues, filterFields) => {
    const { onControlledFilterValueChange } = this.props;

    if (onControlledFilterValueChange) {
      onControlledFilterValueChange(filterValues);
      this.setState({ filterFields });
    } else {
      this.setState({ filterValues, filterFields });
    }
  };

  handleLabelPopoverClosed = (itemsHaveBeenUpdated) => {
    const { collection } = this.props;

    if (itemsHaveBeenUpdated) {
      collection.fetch({ force: true });
    }
  };

  handleRowClick = (model, e) => {
    const { onRowClick } = this.props;
    e.stopPropagation();
    onRowClick(model, e);
  };

  render() {
    const {
      noVirtualizedTable,
      excludeSearchFilters,
      collection,
      useDiscreteFilters,
      labelType,
      showLabelActions,
      toolbarActions,
      infiniteScroll,
      expandedRowRenderer,
      groupSummaryLookup,
      groupFn,
      hideResultCount,
      hideNonIdealState,
      rowKeyField,
      rowAlign,
      rowHeight,
      rowTags,
      width,
      selectedLabelSingular,
      selectedLabelPlural,
      tableHeaderControls,
      tableHeaderControlsPosition,
      groupByControls,
      filterSidebar,
      searchBar,
      sidebarToolbar,
      footer,
      onRowClick,
      onSort,
      theme,
      containerProps,
      searchTerm,
      useLoadedTimeAgo,
      tableContainerProps,
      hideHeader,
      isCollapsed,
      emptyState,
      $auth,
      $app,
      $setup
    } = this.props;
    const { loading, filterSidebarOpen, filterValues, filterFields } = this.state;
    const showToolbarActions = toolbarActions.length > 0 && collection.selected?.length > 0;

    // Do not remove. I'm needed for mobx to trigger re-render when settings change!
    // eslint-disable-next-line no-unused-expressions
    $setup.settings;

    const TableComponent = $app.isExport || noVirtualizedTable ? Table : VirtualizedTable;

    return (
      <Flex flex={1} overflow="hidden" p="1px" {...containerProps}>
        <FlexColumn flex="1 1 0%" width={width} overflow="hidden">
          {(sidebarToolbar || filterSidebar || searchBar || tableHeaderControls) && (
            <Flex py="3px" mb="5px" alignItems="center" overflow="hidden">
              {!$app.isExport && (sidebarToolbar || filterSidebar) && (
                <Button
                  minimal
                  mr={tableHeaderControls || groupByControls ? 1 : '5px'}
                  icon={filterSidebarOpen ? HiFilter : HiOutlineFilter}
                  active={filterSidebarOpen}
                  onClick={this.handleToggleFilterSidebar}
                />
              )}
              {groupByControls && <Box mr="5px">{groupByControls}</Box>}
              {tableHeaderControls && tableHeaderControlsPosition === 'beforeSearch' && (
                <Box mr="5px">{tableHeaderControls}</Box>
              )}
              {searchBar && (
                <AdminTableSearch
                  excludeFilters={excludeSearchFilters}
                  collection={collection}
                  filterFields={filterFields}
                  filterValues={filterValues}
                  onSearch={this.handleSearch}
                  onRemoveFilter={this.handleRemoveFilter}
                  searchTerm={searchTerm}
                />
              )}
              {tableHeaderControls && tableHeaderControlsPosition === 'afterSearch' && (
                <Box mr="1px">{tableHeaderControls}</Box>
              )}
            </Flex>
          )}

          <Flex flex={1} overflow="hidden">
            {(sidebarToolbar || filterSidebar) && (
              <FilterSidebar open={filterSidebarOpen} border="thinLighter">
                {sidebarToolbar}
                {filterSidebar && (
                  <Flex flex={1} overflow="hidden" boxShadow="none">
                    {React.cloneElement(filterSidebar, {
                      onClose: this.handleToggleFilterSidebar,
                      onFilterStateChange: this.handleFilterStateChange,
                      useDiscreteFilters,
                      showClose: true,
                      collection,
                      filterValues
                    })}
                  </Flex>
                )}
              </FilterSidebar>
            )}

            <FlexColumn flex="1 1 0%" overflow="hidden">
              {showToolbarActions && (
                <Box mb={1}>
                  <Flex flex={1} alignItems="center">
                    {showLabelActions && $auth.isAdministrator && (
                      <Box mr={1}>
                        <LabelMapping
                          showSelected={false}
                          collection={collection}
                          type={labelType}
                          onPopoverClosed={this.handleLabelPopoverClosed}
                        />
                      </Box>
                    )}
                    {toolbarActions.map((action) => {
                      if (action.component) {
                        return (
                          <Box mr={1} key={action.id} {...action.containerProps}>
                            {action.component}
                          </Box>
                        );
                      }
                      return (
                        <Button
                          key={action.id}
                          text={action.label}
                          onClick={action.handler}
                          intent={action.intent}
                          mr={1}
                          disabled={action.disabled}
                          icon={action.icon}
                        />
                      );
                    })}
                    {collection.selected?.length}{' '}
                    {collection.selected?.length > 1 ? selectedLabelPlural : selectedLabelSingular} selected
                  </Flex>
                </Box>
              )}
              <FlexColumn
                flex="1 1 0%"
                overflow="hidden"
                borderRadius="4px"
                border="thinLighter"
                {...tableContainerProps}
              >
                <TableComponent
                  headerStyle={{
                    alignItems: 'center',
                    background: theme.backgrounds.tableHeader,
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                    fontSize: '12px',
                    paddingBottom: '4px',
                    paddingTop: '4px',
                    borderBottom: theme.borders.thin
                  }}
                  infiniteScroll={infiniteScroll}
                  useSortTooltips
                  noHighlightSorted
                  collection={collection}
                  columns={this.columns}
                  expandedRowRenderer={expandedRowRenderer}
                  groupFn={groupFn}
                  groupSummaryLookup={groupSummaryLookup}
                  rowKeyField={rowKeyField}
                  rowAlign={rowAlign}
                  rowHeight={rowHeight}
                  rowTags={rowTags}
                  onRowClick={onRowClick && onRowClick !== noop ? this.handleRowClick : null}
                  onSort={onSort}
                  hideNonIdealState={hideNonIdealState}
                  selectOnRowClick={false}
                  isCollapsed={isCollapsed}
                  hideHeader={hideHeader}
                  emptyState={emptyState}
                  flexed
                />

                {!hideResultCount && (
                  <Callout
                    p="0 16px"
                    bg={theme.backgrounds.tableHeader}
                    textAlign="right"
                    borderTop="thin"
                    borderRadius="0 0 4px 4px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    minHeight={30}
                  >
                    {!loading && (
                      <>
                        {footer}
                        <Text fontWeight={500} fontSize={12} display="inline-flex">
                          {!useLoadedTimeAgo && collection.requestStatus === 'fetchingMore' ? (
                            <Box display="inline-flex" mr={1}>
                              <Spinner size={16} mx={1} />
                              <Text muted>Loading More...</Text>
                            </Box>
                          ) : null}
                          {useLoadedTimeAgo ? (
                            <Box display="inline-flex" mr={1}>
                              <Text muted>{`Updated ${formatDateTime(collection.lastFetched, 'HH:mm:ss')}`}</Text>
                            </Box>
                          ) : null}
                          Showing
                          {collection.size < collection.totalCount
                            ? ` ${Number(collection.size)?.toLocaleString()} of ${Number(
                                collection.totalCount
                              )?.toLocaleString()} `
                            : ` ${Number(collection.size)?.toLocaleString()} `}
                          results
                        </Text>
                      </>
                    )}
                  </Callout>
                )}
              </FlexColumn>
            </FlexColumn>
          </Flex>
        </FlexColumn>
      </Flex>
    );
  }
}

export default AdminTable;
