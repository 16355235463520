import $dictionary from 'app/stores/$dictionary';

export const applicationMetrics = ['application'];
export const asPathMetrics = ['dst_bgp_aspath', 'src_bgp_aspath'];
export const asnMetrics = [
  'AS_src',
  'AS_dst',
  'src_nexthop_asn',
  'dst_nexthop_asn',
  'src_second_asn',
  'dst_second_asn',
  'src_third_asn',
  'dst_third_asn'
];
export const cdnMetrics = ['dst_cdn', 'src_cdn'];
export const cityMetrics = ['src_geo_city', 'dst_geo_city'];
export const cloudMetrics = ['src_cloud', 'dst_cloud', 'kt_cloud_provider'];
export const countryMetrics = ['Geography_src', 'Geography_dst', 'i_device_site_country', 'i_ult_exit_site_country'];
export const deviceMetrics = [
  'i_device_id',
  'i_ult_exit_device_name',
  'ktappprotocol__snmp_device_metrics__i_device_name',
  'ktappprotocol__snmp__i_device_name',
  'ktappprotocol__st__i_device_name'
];
export const interfaceMetrics = [
  'InterfaceID_src',
  'InterfaceID_dst',
  'bgp_ult_exit_interface',
  'ktappprotocol__snmp__output_port',
  'ktappprotocol__st__output_port',
  'ktsubtype__advanced_sflow__INT00',
  'ktsubtype__advanced_sflow__INT01'
];
export const ipMetrics = ['IP_src', 'IP_dst', 'dst_nexthop_ip', 'src_nexthop_ip'];
export const ottProviderMetrics = ['service_provider'];
export const ottServiceMetrics = ['service_name'];
export const ottServiceTypeMetrics = ['service_type'];
export const regionMetrics = ['src_geo_region', 'dst_geo_region'];
export const serviceMetrics = ['src_proto_port', 'dst_proto_port'];
export const siteMetrics = [
  'i_device_site_name',
  'i_ult_exit_site',
  'ktappprotocol__snmp__i_device_site_name',
  'ktappprotocol__snmp_device_metrics__i_device_site_name',
  'ktappprotocol__st__i_device_site_name'
];
export const siteMarketMetrics = ['i_site_market', 'i_ult_exit_site_market'];
export const connectivityTypeMetrics = [
  'i_src_connect_type_name',
  'i_dst_connect_type_name',
  'i_ult_exit_connect_type_name',
  'ktappprotocol__snmp__i_dst_connect_type_name',
  'ktappprotocol__st__i_dst_connect_type_name'
];
export const networkBoundaryMetrics = [
  'i_src_network_bndry_name',
  'i_dst_network_bndry_name',
  'i_ult_exit_network_bndry_name',
  'ktappprotocol__snmp__i_dst_network_bndry_name',
  'ktappprotocol__st__i_dst_network_bndry_name'
];
export const providerMetrics = [
  'i_src_provider_classification',
  'i_dst_provider_classification',
  'i_ult_provider_classification',
  'ktappprotocol__snmp__i_dst_provider_classification',
  'ktappprotocol__st__i_dst_provider_classification'
];
export const interfacePercentageMetrics = [
  'ktappprotocol__snmp__INT05',
  'ktappprotocol__snmp__INT06',
  'perc_repeated_retransmits',
  'perc_retransmits_out',
  'perc_out_of_order_in',
  'perc_fragments',
  'perc_zero_windows'
];
export const interfaceKeyRegex = /^(?<interface_description>.*) : (?<snmp_alias>.*) \((?<snmp_id>.*)\)/;

export function findMetricOption(metric, options) {
  const opts = options || $dictionary.dimensionOptions;
  let option = null;

  Object.keys(opts).some((groupName) =>
    opts[groupName].some((optGroup) => {
      if (Array.isArray(optGroup)) {
        option = optGroup.find((opt) => opt.value === metric);
      } else {
        option = findMetricOption(metric, optGroup);
      }
      return !!option;
    })
  );

  return option;
}
