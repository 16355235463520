import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { polygonCentroid, polygonLength } from 'd3-polygon';
import { scaleOrdinal } from 'd3-scale';
import { select, event as d3Event } from 'd3-selection';
import { zoomIdentity } from 'd3-zoom';

import { Flex, Spinner, Text } from 'core/components';
import { marketColors } from 'app/stores/colors/Colors';
import {
  rescaleNode,
  centerElement,
  tweenXY,
  addInterGroupLinks,
  polygonGenerator,
  getCenterOfNodes,
  createForceSimulation
} from 'app/util/graphing';
import TopoWorker from 'app/assets/Topo.worker.js';

import Chart from './Chart';
import Group from './Group';
import GroupText from './GroupText';
import Node from './Node';
import Link from './Link';

const markerId = 'topoArrowMarker';
const backgroundFilterId = 'topoBackgroundFilter';

@inject('$topo')
@observer
class TrafficMap extends Component {
  static propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    colorScheme: PropTypes.array,
    highlightKey: PropTypes.string,
    onClick: PropTypes.func,
    onShowDetails: PropTypes.func,
    enableMouseWheelZoom: PropTypes.bool
  };

  static defaultProps = {
    height: 900,
    width: 1200,
    colorScheme: marketColors,
    highlightKey: undefined,
    enableMouseWheelZoom: true,
    onClick: () => {},
    onShowDetails: () => {}
  };

  state = {
    workerProgress: 0,
    highlightNodes: [],
    highlightLinks: []
  };

  simulation;

  svg;

  container;

  simulationSpeed = 20;

  simNodes = [];

  simLinks = [];

  zoom;

  initialTransform = false;

  needsRebuild = false;

  worker;

  @computed
  get colorScheme() {
    const { colorScheme } = this.props;
    return scaleOrdinal(colorScheme);
  }

  componentDidMount() {
    const { $topo } = this.props;

    if (!this.worker) {
      try {
        this.worker = new TopoWorker();
        this.worker.onerror = this.onWorkerError;
        this.worker.onmessage = this.onWorkerMessage;
        this.worker.postMessage({ type: 'test' });
      } catch (error) {
        this.onWorkerError({ message: 'Could not post a message to the worker.' });
      }
    }

    if (Object.keys($topo.links).length > 0) {
      this.buildSimulation();
    }
  }

  componentDidUpdate = (prevProps) => {
    const { width, height, $topo, allGroupsCollapsed } = this.props;
    const { loading } = $topo;

    if (prevProps.allGroupsCollapsed !== allGroupsCollapsed) {
      Object.keys($topo.groups).forEach((groupId) => {
        const group = $topo.getGroup(groupId);
        this.setGroupCollapsed(group, allGroupsCollapsed);
        this.restartSim();
      });
    }

    if (loading) {
      this.needsRebuild = true;
    }

    if (
      this.simulation &&
      !loading &&
      (this.needsRebuild || this.simNodes.map((node) => node.id).toString() !== Object.keys($topo.nodes).toString())
    ) {
      this.buildSimulation();
    }

    if (!this.simulation && (!loading || Object.keys($topo.nodes).length > 0)) {
      this.buildSimulation();
    }

    if (width !== prevProps.width || height !== prevProps.height) {
      this.onZoomToFit(width, height);
    }
  };

  componentWillUnmount() {
    if (this.worker) {
      this.worker.terminate();
    }
  }

  buildSimulation = () => {
    const { width, height, $topo } = this.props;
    const { nodes, nodeInfo, links, hasBeenSaved } = $topo;

    this.needsRebuild = false;
    this.initialTransform = false;

    // d3 mutates the links and nodes passed to it, so don't pass it our props directly
    this.simNodes = Object.values(nodes).map((node) => ({ ...node, ...nodeInfo[node.id] }));
    this.simLinks = Object.values(links).map((link) => ({ ...link }));

    // change the simulation speed depending on number of links
    if (this.simLinks.length < 30) {
      this.simulationSpeed = 10;
    }
    if (this.simLinks.length >= 100) {
      this.simulationSpeed = 300;
    }

    this.simulation = createForceSimulation({ width, height, center: getCenterOfNodes(this.simNodes) });

    // Use a web worker to do any intial layout
    if (this.worker && !hasBeenSaved) {
      this.worker.postMessage({
        type: 'init',
        width,
        height,
        nodes: this.simNodes,
        links: this.getAllLinks()
      });
    } else {
      this.startSim();
    }
  };

  getAllLinks = () => {
    const { $topo } = this.props;
    const { groups } = $topo;

    return this.simLinks.concat(addInterGroupLinks(groups, this.simNodes));
  };

  getNodes = () => select(this.container).select('.topoNodes').selectAll('.topoNode');

  getGroupNodes = (groupId, nodes = this.getNodes()) => nodes.filter((node) => node.siteId === groupId);

  startSim = () => {
    const svgNodes = this.getNodes();

    this.simulation
      .nodes(this.simNodes)
      .on('tick', this.onTicked)
      .on('end', this.onInitialLayoutEnded)
      .force('link')
      .links(this.getAllLinks());

    svgNodes.data(this.simNodes);

    this.setState({
      workerProgress: 1
    });
  };

  restartSim = (alphaTarget = 0.02) => {
    this.simulation.alphaTarget(alphaTarget).restart();
  };

  stopSim = () => this.simulation.alphaTarget(0);

  setGroupCollapsed = (group, collapsed) => {
    const { $topo } = this.props;
    const { setGroupInfo } = $topo;
    const centroid = polygonCentroid(polygonGenerator(this.getGroupNodes(group.id), collapsed));
    const nodes = this.getGroupNodes(group.id);

    setGroupInfo({
      id: group.id,
      centroid,
      collapsed
    });

    if (collapsed) {
      nodes.each((node) => {
        node.original = {
          x: node.x,
          y: node.y,
          fx: node.fx,
          fy: node.fy
        };
      });
    }

    nodes
      .attr('visibility', collapsed ? 'hidden' : 'visible')
      .transition()
      .duration(300)
      .tween('x', tweenXY('x', collapsed ? centroid[0] : undefined))
      .tween('y', tweenXY('y', collapsed ? centroid[1] : undefined))
      .on('end', this.onEndGroupTween(collapsed, nodes))
      .selectAll('text')
      .attr('filter', collapsed ? '' : `url(#${backgroundFilterId})`);
  };

  fixNodePositions = (nodes = this.getNodes()) => {
    const { $topo } = this.props;
    const { setNodeInfo, fixGroupPositions } = $topo;

    nodes[nodes.each ? 'each' : 'forEach']((node) => {
      node.fx = node.x;
      node.fy = node.y;
      setNodeInfo({ id: node.id, fx: node.fx, fy: node.fy });
    });

    fixGroupPositions();
  };

  updateLinkPoints = () => {
    const { $topo } = this.props;
    const { setLinkPoint } = $topo;

    this.simLinks.forEach((link) => {
      setLinkPoint(link.id, {
        x1: link.source.x,
        y1: link.source.y,
        x2: link.target.x,
        y2: link.target.y
      });
    });
  };

  updateGroupLinks = () => {
    const { $topo } = this.props;
    const { setLinkPoint, groupLinks, groups } = $topo;

    Object.keys(groupLinks).forEach((key) => {
      const groupLink = groupLinks[key];
      const source = groups[groupLink.source];
      const target = groups[groupLink.target];

      setLinkPoint(key, {
        x1: source.centroid ? source.centroid[0] : 0,
        y1: source.centroid ? source.centroid[1] : 0,
        x2: target.centroid ? target.centroid[0] : 0,
        y2: target.centroid ? target.centroid[1] : 0
      });
    });
  };

  updateGroups = () => {
    const { $topo } = this.props;
    const { setGroupInfo, groups } = $topo;

    Object.values(groups).forEach((group) => {
      const oldCentroid = group.centroid || [0, 0];
      const nodes = this.getGroupNodes(group.id);
      const polygon = polygonGenerator(nodes, group.collapsed);
      const newCentroid = polygonCentroid(polygon);

      if (
        Math.abs(newCentroid[0] - oldCentroid[0]) >= 2 || // must move at least 2 pixels
        Math.abs(newCentroid[1] - oldCentroid[1]) >= 2 ||
        !group.polygon ||
        Math.abs(polygonLength(polygon) - polygonLength(group.polygon)) >= 2
      ) {
        setGroupInfo({
          id: group.id,
          polygon,
          centroid: newCentroid
        });
      }
    });
  };

  onWorkerError = ({ message }) => {
    console.warn('Could not start web worker: ', message);
    this.worker = undefined;
  };

  onWorkerMessage = ({ data }) => {
    const { type, nodes, links, progress } = data;

    switch (type) {
      case 'tick': {
        this.setState({
          workerProgress: progress
        });
        break;
      }

      case 'end': {
        this.simNodes = nodes;
        this.simLinks = links;
        this.fixNodePositions(nodes);
        this.startSim();
        break;
      }

      default:
    }
  };

  // called once every d3 force "tick" until the graph has settled into position
  onTicked = () => {
    const { $topo } = this.props;
    const nodes = this.getNodes();

    // speed up the simulation by skipping a number of ticks
    for (let i = 0; i < this.simulationSpeed; i += 1) {
      this.simulation.tick();
    }

    // calculate new link x/y points
    this.updateLinkPoints();

    // move d3 nodes to their new positions
    nodes.attr('transform', (d) => `translate(${d.x}, ${d.y})`);

    // calculate new group link x/y points and group rectangles
    if ($topo.hasGroups) {
      this.updateGroups(); // do this first
      this.updateGroupLinks(); // do this last
    }
  };

  onInitialLayoutEnded = () => {
    // do this once
    if (!this.initialTransform) {
      this.onZoomToFit();
      this.initialTransform = true;
      this.fixNodePositions();
    }
  };

  onEndGroupTween = (collapsed, nodes) => () => {
    if (!collapsed) {
      nodes.each((node) => {
        node.fx = node.original.fx;
        node.fy = node.original.fy;
      });
    }

    this.stopSim();
  };

  onNodeDrag = (eventStr) => {
    if (eventStr === 'start' && !d3Event.active) {
      this.restartSim();
    }

    if (eventStr === 'end' && !d3Event.active) {
      this.stopSim();
    }

    this.onTicked();
  };

  onGroupDrag = (eventStr, group) => {
    const { $topo } = this.props;
    const { setNodeInfo } = $topo;
    const nodes = this.getGroupNodes(group.id);
    const dragX = d3Event.dx || 0;
    const dragY = d3Event.dy || 0;

    if (eventStr === 'start' && !d3Event.active) {
      this.restartSim();
    }

    if (eventStr === 'end' && !d3Event.active) {
      this.stopSim();
    }

    nodes.each((node) => {
      node.fx = node.fx ? node.fx + dragX : node.x + dragX;
      node.fy = node.fy ? node.fy + dragY : node.y + dragY;
      setNodeInfo({ id: node.id, fx: node.fx, fy: node.fy });

      if (group.collapsed && node.original) {
        node.x = node.fx;
        node.y = node.fy;
        node.original.fx += dragX;
        node.original.fy += dragY;
        node.original.x = node.original.fx;
        node.original.y = node.original.fy;
      }
    });

    this.onTicked();
  };

  // collapse the group by moving its nodes to the center and hiding them
  onGroupClick = (collapsed, group) => {
    this.setGroupCollapsed(group, collapsed);
    this.restartSim();
  };

  onHighlightNode = (node, highlight = false) => {
    const { $topo } = this.props;
    const { nodes, linkDelim } = $topo;
    const highlightLinks = [];
    let highlightNodes = [];
    let links = [...node.links];

    if (node.type === 'provider') {
      links = Object.values(nodes)
        .filter((topoNode) => topoNode.type === 'provider' && topoNode.name === node.name)
        .reduce((providerLinks, topoNode) => providerLinks.concat(topoNode.links), []);
    }

    if (highlight && links) {
      highlightNodes.push(node.id);
      links.forEach((link) => {
        const nodeIds = link.split(linkDelim);
        highlightNodes = highlightNodes.concat(nodeIds);
        highlightLinks.push(link);
      });
    }

    this.setState({
      highlightNodes,
      highlightLinks
    });
  };

  onHighlightLink = (link, highlight = false) => {
    const { $topo } = this.props;
    const highlightLinks = [];
    let highlightNodes = [];

    if (highlight) {
      highlightNodes = highlightNodes.concat(link.id.split($topo.linkDelim));
      highlightLinks.push(link.id);
    }

    this.setState({
      highlightNodes,
      highlightLinks
    });
  };

  onShowDetails = (type, data, el, options, e) => {
    const { width, height, onShowDetails } = this.props;
    onShowDetails(type, data, el, { width, height, ...options }, e);
  };

  // save references to the container and d3-zoom after the Chart has mounted
  onChartDidMount = (svg, container, zoom) => {
    this.svg = svg;
    this.container = container;
    this.zoom = zoom;
  };

  // eslint-disable-next-line react/destructuring-assignment
  onZoomToFit = (width = this.props.width, height = this.props.height) => {
    if (!this.container) {
      return;
    }

    const scale = rescaleNode(this.container, width, height);
    const center = centerElement(this.container, scale, width, height);
    const transform = zoomIdentity.translate(center[0], center[1]).scale(scale);

    select(this.container).transition().duration(300).call(this.zoom.transform, transform);
  };

  renderEmpty = () => {
    const { width } = this.props;
    return (
      <Flex alignItems="center" justifyContent="center" mt={2} width={width}>
        <Text as="div" ml={1} color="muted" fontSize="small">
          No Results
        </Text>
      </Flex>
    );
  };

  renderLoading = () => {
    const { width } = this.props;
    return (
      <Flex alignItems="center" justifyContent="center" mt={2} width={width}>
        <Spinner size={16} />
        <Text as="div" ml={1} color="muted" fontSize="small">
          Loading Topology...
        </Text>
      </Flex>
    );
  };

  render() {
    const { highlightNodes, highlightLinks, workerProgress } = this.state;
    const { width, height, $topo, highlightKey, onClick, enableMouseWheelZoom } = this.props;
    const { nodes, links, groupLinks, groups, loading } = $topo;

    const linkProps = {
      highlightKey,
      onHighlight: this.onHighlightLink,
      markerId,
      onShowDetails: this.onShowDetails
    };

    const loadingTopo = loading || workerProgress < 1;

    return (
      <>
        {loadingTopo && this.renderLoading()}
        {!loadingTopo && Object.keys(nodes).length === 0 && this.renderEmpty()}

        <Chart
          width={width}
          height={height}
          hidden={loadingTopo}
          markerId={markerId}
          backgroundFilterId={backgroundFilterId}
          onChartDidMount={this.onChartDidMount}
          onClick={onClick}
          enableMouseWheelZoom={enableMouseWheelZoom}
        >
          <g className="topoGroups">
            {Object.keys(groups).map((key) => (
              <Group
                key={key}
                groupId={key}
                color={this.colorScheme(key)}
                onClick={this.onGroupClick}
                onDrag={this.onGroupDrag}
                onShowDetails={this.onShowDetails}
              />
            ))}
          </g>

          <g className="topoGroupLinks">
            {Object.keys(groupLinks).map((key) => (
              <Link key={key} groupLink linkId={key} {...linkProps} highlight={highlightLinks.includes(key)} />
            ))}
          </g>

          <g className="topoLinks">
            {Object.keys(links).map((key) => (
              <Link key={key} linkId={key} {...linkProps} highlight={highlightLinks.includes(key)} />
            ))}
          </g>

          <g className="topoGroupText">
            {Object.keys(groups).map((key) => (
              <GroupText
                key={key}
                groupId={key}
                color={this.colorScheme(key)}
                backgroundFilter={backgroundFilterId}
                onShowDetails={this.onShowDetails}
              />
            ))}
          </g>

          <g className="topoNodes">
            {Object.keys(nodes).map((key) => {
              const mute = highlightKey ? '?' : false;
              return (
                <Node
                  key={key}
                  node={nodes[key]}
                  maybeMute={highlightNodes.length > 0 && !highlightNodes.includes(key) ? true : mute}
                  highlight={highlightNodes.includes(key)}
                  backgroundFilter={backgroundFilterId}
                  color={nodes[key].siteId && this.colorScheme(nodes[key].siteId)}
                  onDrag={this.onNodeDrag}
                  onShowDetails={this.onShowDetails}
                  onHighlight={this.onHighlightNode}
                />
              );
            })}
          </g>
        </Chart>
      </>
    );
  }
}

export default TrafficMap;
