import React, { Component } from 'react';
import moment from 'moment';
import { isEmpty, isEqual } from 'lodash';
import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';
import { HiFilter } from 'react-icons/hi';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import storeLoader from 'app/stores/storeLoader';
import Model from 'core/model/Model';
import { DEFAULT_DATE_FORMAT } from 'core/util/dateUtils';
import { Icon, Flex, Heading, Suspense, Spinner, Text, MenuItem } from 'core/components';
import { DEFAULT_SHORTCUTS as LOOKBACK_OPTIONS } from 'core/form/components/LookbackDateRange';
import {
  DEFAULT_LOOKBACK_SECONDS,
  DEFAULT_WIDGET_CONFIG
} from 'app/views/synthetics/components/incidentLogs/constants';
import IncidentLog from 'app/views/synthetics/components/incidentLogs/IncidentLog';
import IncidentLogForm from 'app/views/synthetics/components/incidentLogs/IncidentLogForm';
import IncidentLogMessage from 'app/views/synthetics/components/incidentLogs/IncidentLogMessage';
import WidgetFrame from '../WidgetFrame';

/**
 * Removes deliquent config values and updates the config to prevent invalid filtering
 * @returns Promise ({hasConfigChanges, updatedConfig}) {boolean, object}
 */
const pruneRemovedValuesFromConfig = ({ $syn, $labels, config, onConfigChange }) => {
  const { labels, global_agents, private_agents, test_ids, test_types } = config;
  const newConfig = { ...config };

  if (!isEmpty(labels)) {
    const latestLabelIds = $labels.getLabels('synth_test').map((label) => label.id);
    newConfig.labels = labels.filter((id) => latestLabelIds.includes(id));
  }
  if (!isEmpty(global_agents)) {
    const latestGlobalAgentIds = $syn.agents.globalAgents.map((m) => m.id);
    newConfig.global_agents = global_agents.filter((id) => latestGlobalAgentIds.includes(id));
  }
  if (!isEmpty(private_agents)) {
    const latestPrivateAgentIds = $syn.agents.privateAgents.map((m) => m.id);
    newConfig.private_agents = private_agents.filter((id) => latestPrivateAgentIds.includes(id));
  }
  if (!isEmpty(test_ids)) {
    const latestTestIds = $syn.tests.get().map((m) => m.id);
    newConfig.test_ids = test_ids.filter((id) => latestTestIds.includes(id));
  }
  if (!isEmpty(test_types)) {
    const latestTestTypes = $syn.tests.userConfiguredTestTypeOptions.map(({ value }) => value);
    newConfig.test_types = test_types.filter((value) => latestTestTypes.includes(value));
  }

  const hasConfigChanges = !isEqual(newConfig, config);

  if (hasConfigChanges && onConfigChange) {
    onConfigChange(newConfig);
  }

  return newConfig;
};

@storeLoader('$syn.tests', '$syn.agents')
@inject('$syn', '$labels')
@withTheme
@observer
class IncidentLogWidget extends Component {
  static defaultProps = {
    config: DEFAULT_WIDGET_CONFIG
  };

  incidentLogRequest = null;

  constructor(props) {
    super(props);

    const config = pruneRemovedValuesFromConfig(props);
    const initialState = {
      loading: true,
      alarms: {},
      totalAlarms: 0,
      isConfigurePanelOpen: false,
      config
    };

    if (!config?.selected_time) {
      // provide a default selected time
      initialState.config.selected_time = {
        lookbackSeconds: DEFAULT_LOOKBACK_SECONDS,
        startDate: null,
        endDate: null
      };
    }

    // initialize a model based on the loaded config
    initialState.formModel = new Model(initialState.config);

    // eslint-disable-next-line react/state-in-constructor
    this.state = initialState;
  }

  componentDidMount() {
    this.loadIncidentLogs();
  }

  componentDidUpdate(prevProps, prevState) {
    const { formModel } = this.state;
    const selectedTimeChanged = !isEqual(prevState.formModel.get('selected_time'), formModel.get('selected_time'));

    if (selectedTimeChanged) {
      this.loadIncidentLogs();
    }
  }

  componentWillUnmount() {
    if (this.incidentLogRequest) {
      this.incidentLogRequest.cancel();
    }
  }

  loadIncidentLogs = () => {
    const { $syn } = this.props;
    const { formModel } = this.state;
    const { lookbackSeconds, startDate, endDate } = formModel.get('selected_time', {});

    return this.setState({ loading: true }, () => {
      this.incidentLogRequest = makeCancelable(
        $syn.tests.loadIncidentLogTestAlarms({ lookbackSeconds, startDate, endDate })
      );

      return this.incidentLogRequest.promise
        .then((response) =>
          this.setState({ loading: false, alarms: response.alarms.alarms, totalAlarms: response.alarms.totalAlarms })
        )
        .catch((err) => {
          if (!(err instanceof CanceledError)) {
            this.setState({ loading: false });
            console.error('Error occurred attempting to load incident log widget alerts', err);
          }
        });
    });
  };

  handleShowConfigurePanel = () => {
    this.setState({ isConfigurePanelOpen: true });
  };

  handleCancel = (form) => {
    form.reset();
    this.setState({ isConfigurePanelOpen: false });
  };

  handleSave = (form) => {
    const { onConfigChange } = this.props;
    const config = form.getValues();
    this.setState({ isConfigurePanelOpen: false, formModel: new Model(config) });
    if (onConfigChange) {
      onConfigChange(config);
    }
  };

  get selectedTimeDisplay() {
    const { formModel } = this.state;
    const selectedTime = formModel.get('selected_time', {});

    if (selectedTime.startDate && selectedTime.endDate) {
      return `${moment.unix(selectedTime.startDate).utc().format(DEFAULT_DATE_FORMAT)} - ${moment
        .unix(selectedTime.endDate)
        .utc()
        .format(DEFAULT_DATE_FORMAT)}`;
    }
    return LOOKBACK_OPTIONS.find(({ lookbackSeconds }) => lookbackSeconds === selectedTime.lookbackSeconds)?.label;
  }

  get selectedFiltersCount() {
    const { formModel } = this.state;
    const config = formModel.toJS();
    const count = Object.keys(config).reduce((acc, key) => {
      if (key !== 'selected_time' && !isEmpty(config[key]) && config[key] !== 'all') {
        return acc + 1;
      }
      return acc;
    }, 0);
    return count;
  }

  renderMenuItems() {
    return <MenuItem icon="cog" text="Configure" onClick={this.handleShowConfigurePanel} />;
  }

  renderConfigurePanel() {
    const { formModel } = this.state;

    return <IncidentLogForm p={2} model={formModel} onSave={this.handleSave} onCancel={this.handleCancel} />;
  }

  render() {
    const { canCustomize, onRemove, approxWidth, theme } = this.props;
    const { loading, isConfigurePanelOpen, alarms, formModel, totalAlarms = 0 } = this.state;
    return (
      <WidgetFrame
        canCustomize={canCustomize}
        menuOptions={!canCustomize && this.renderMenuItems()}
        onRemove={onRemove}
        title={
          <Flex gap={2} alignItems="center">
            <Heading level={5} mb={0} fontSize={14}>
              Incident Log
            </Heading>
            <Flex alignItems="center" gap={1}>
              <Text fontSize={12} muted>
                {this.selectedTimeDisplay}
              </Text>
              <Flex borderLeft="thin" alignItems="center" gap="4px" pl={1}>
                <Icon
                  icon={HiFilter}
                  iconSize={14}
                  color={theme.name === 'dark' ? theme.colors.gray4 : theme.colors.gray1}
                />
                <Text fontSize={12} muted>
                  {this.selectedFiltersCount} Filter
                </Text>
              </Flex>
              <IncidentLogMessage alarms={alarms} totalAlarms={totalAlarms} />
            </Flex>
          </Flex>
        }
        titleOverride
        configAction={this.handleShowConfigurePanel}
      >
        <Suspense loading={loading} fallback={<Spinner intent="primary" my={2} size={36} />}>
          {isConfigurePanelOpen && this.renderConfigurePanel()}
          {!isConfigurePanelOpen && !loading && (
            <IncidentLog height="100%" alarms={alarms} model={formModel} width={approxWidth} />
          )}
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default IncidentLogWidget;
