import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Card, Flex, Icon, Text, Box, Sparkline, Spinner, Suspense, Link } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import SiteLink from 'app/components/links/SiteLink';
import { getAggregateUnitLabel } from 'app/components/metric/metricRenderers';
import { zeroToText, adjustByGreekPrefix, getToFixed } from 'app/util/utils';
import { CLOUD_SUB_TYPES } from 'app/util/constants';
import { addFilters } from 'app/stores/query/FilterUtils';

function getValue(seriesOrValue, results, queryModel) {
  const [aggregateType] = queryModel.get('aggregateTypes');
  return zeroToText(
    adjustByGreekPrefix(
      seriesOrValue.get ? seriesOrValue.get(aggregateType) : seriesOrValue,
      results.prefix[queryModel.outsortUnit]
    ),
    {
      fix: getToFixed(queryModel.outsortUnit)
    }
  );
}

function CardHeader({ site, title, cloud }) {
  return (
    <Flex alignItems="center" mb="4px">
      {site && (
        <>
          <Icon icon="map-marker" color="muted" />
          <SiteLink siteId={site.id}>
            <Text as="div" ml="4px" fontSize="large" fontWeight="bold">
              {title}
            </Text>
          </SiteLink>
        </>
      )}
      {cloud && (
        <Flex ml="4px" alignItems="center" fontSize="large" fontWeight="bold">
          <Box mr={1}>{CLOUD_SUB_TYPES[cloud].logo}</Box>
          <Link to={`/v4/core/quick-views/cloud/${CLOUD_SUB_TYPES[cloud].id}`}>{CLOUD_SUB_TYPES[cloud].name}</Link>
        </Flex>
      )}
    </Flex>
  );
}

function Result({ value, row }) {
  const name = row.get('key');
  return (
    <Flex alignItems="center" justifyContent="space-between" fontSize="small" color="muted">
      <Text>{name.charAt(0).toUpperCase() + name.slice(1)}</Text>
      <Text alignItems="center" textAlign="right">
        <Text>{value}</Text>
      </Text>
    </Flex>
  );
}

@inject('$query', '$explorer')
@observer
class InboundOutboundCard extends Component {
  renderData(results, queryModel) {
    const { prefix } = results;
    const [aggregateType] = queryModel.get('aggregateTypes');
    const [aggregate] = queryModel.aggregates;
    const [totalOverlay] = results.overlayRows;
    const total = (totalOverlay && totalOverlay.get(aggregateType)) || 0;

    const [, ...aggTypeParts] = aggregateType.split('_');
    const rawDataAttr = `rawData.both_${aggTypeParts.join('_')}.flow`;

    const sparklineData =
      totalOverlay && totalOverlay.get(rawDataAttr) ? totalOverlay.get(rawDataAttr).map((col) => col[1]) : [];

    return (
      <>
        <Flex justifyContent="flex-end" fontSize="small" color="muted">
          {getAggregateUnitLabel({ aggregate, prefix })}
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" fontSize="small">
          <Text fontWeight="bold">Total</Text>
          <Text fontWeight="bold" alignItems="center" textAlign="right">
            <Text>{getValue(total, results, queryModel)}</Text>
          </Text>
        </Flex>

        {results.nonOverlayRows.map((row) => {
          const value = getValue(row, results, queryModel);
          return <Result key={row.id} row={row} value={value} />;
        })}

        <Flex flexDirection="column" justifyContent="flex-end" mt={1} mx={-2} flex={1}>
          <Sparkline data={sparklineData} height={20} margin={0} color="primary" />
        </Flex>
      </>
    );
  }

  render() {
    const { $query, site, cloud, queryOverrides } = this.props;

    let query;
    const updatedQueryOverrides = Object.assign({}, queryOverrides, {
      filterDimensionsEnabled: false,
      metric: ['simple_trf_prof']
    });

    const title = site ? site.get('title') : CLOUD_SUB_TYPES[cloud].name;
    const key = site ? site.id : cloud;

    if (site) {
      query = $query.get('inboundOutboundInternalQuery', updatedQueryOverrides);
      addFilters(query, [
        {
          filterField: 'i_device_site_name',
          operator: '=',
          filterValue: `${site.get('title')}`
        }
      ]);
    } else {
      Object.assign(updatedQueryOverrides, queryOverrides, {
        all_devices: false,
        device_types: [cloud]
      });
      query = $query.get('inboundOutboundInternalQuery', updatedQueryOverrides);
    }

    return (
      <Card key={key} p={2} pb={0} display="flex" flexDirection="column">
        <CardHeader site={site} title={title} cloud={cloud} />

        <LightweightDataViewWrapper title={title} query={query}>
          {({ results, queryModel, loading }) => (
            <Suspense
              loading={loading}
              fallback={
                <Flex alignItems="center" fontSize="small" pb={2}>
                  <Spinner size={12} />
                  <Text color="muted" as="div" ml="4px">
                    Loading...
                  </Text>
                </Flex>
              }
            >
              {this.renderData(results, queryModel, query)}
            </Suspense>
          )}
        </LightweightDataViewWrapper>
      </Card>
    );
  }
}

export default InboundOutboundCard;
