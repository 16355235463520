import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Box, Button, Callout, Card, Flex, Heading, Text } from 'core/components';
import { Field, FormDialog, InputMultiple, Select } from 'core/form';
import storeLoader from 'app/stores/storeLoader';
import { ReactComponent as PeeringdbIcon } from 'app/assets/logos/peeringdb-icon.svg';

import Icon from 'core/components/Icon';
import InterfaceField from './InterfaceField';

const BOUNDARY_ASN_OVERRIDE = 4102444800000000000;

const fields = {
  device_id: {
    label: 'Device',
    rules: 'required'
  },
  snmp_id: {
    label: 'SNMP ID',
    rules: 'required|numeric|min:0|max:4294967295'
  },
  interface_description: {
    label: 'Name',
    rules: 'required|string|max:128'
  },
  snmp_alias: {
    label: 'Description',
    rules: 'string|max:128'
  },
  snmp_speed: {
    label: 'Capacity (Mbps)',
    rules: 'numeric|required'
  },
  interface_ip: {
    label: 'IP Address',
    rules: 'ipNoCidr'
  },
  interface_ip_netmask: {
    label: 'Netmask',
    rules: 'ipNoCidr',
    messages: {
      ipNoCidr: 'Enter a netmask format'
    }
  },
  connectivity_type: {
    label: 'Connectivity Type'
  },
  network_boundary: {
    label: 'Network Boundary'
  },
  provider: {
    label: 'Provider/Customer'
  },
  ix_id: {
    label: (
      <>
        <Icon icon={PeeringdbIcon} mr={1} />
        PeeringDB Internet Exchange
      </>
    ),
    placeholder: 'IX name'
  },
  top_nexthop_asns: {
    label: 'Boundary ASN',
    defaultValue: [],
    isComplexArray: true
  },
  'top_nexthop_asns[].Asn': {},
  'top_nexthop_asns[].Packets': { defaultValue: BOUNDARY_ASN_OVERRIDE },
  'top_nexthop_asns[].EditDateUnixNano': { defaultValue: BOUNDARY_ASN_OVERRIDE }
};

const options = { name: 'Interface' };

@storeLoader('$devices')
@inject('$interfaces', '$peering', '$lookups', '$auth')
@withRouter
@observer
class InterfaceFormDialog extends Component {
  state = {
    loadingPeeringOptions: true,
    peeringIxOptions: []
  };

  componentDidMount() {
    const { $peering } = this.props;
    $peering.fetchPeeringdbIxOptions().then((ixs) => {
      this.setState({ peeringIxOptions: ixs, loadingPeeringOptions: false });
    });
  }

  render() {
    const { $devices, $interfaces, $lookups, $auth, model, onClose } = this.props;
    const { peeringIxOptions, loadingPeeringOptions } = this.state;

    let device_name;
    const deviceSummary = $devices.deviceSummariesById[model.get('device_id')];
    if (deviceSummary) {
      device_name = deviceSummary.device_name;
    }

    return (
      <FormDialog
        entityName="Interface"
        fields={fields}
        options={options}
        model={model}
        onClose={onClose}
        maxWidth={600}
        formActionsProps={{ onCancel: onClose }}
        isOpen
      >
        {!model.isNew && model.isManuallyAdded && (
          <Callout mb={2}>
            <Text muted>This interface was manually added (not SNMP-discovered).</Text>
          </Callout>
        )}
        {model.isNew && (
          <Field name="device_id" options={$devices.deviceSummaryByIdOptions} width={300} large>
            <Select showFilter />
          </Field>
        )}
        {!model.isNew && (
          <Box mb={2}>
            <Text as="div" fontWeight="bold">
              Device
            </Text>
            <Text as="div">{device_name}</Text>
          </Box>
        )}
        <Box>
          <Flex alignItems="flex-start">
            <Box flex={1} mr={2}>
              <InterfaceField model={model} fieldName="snmp_id" />
            </Box>
            <Box flex={2}>
              <InterfaceField model={model} fieldName="interface_description" />
            </Box>
          </Flex>
          <Flex alignItems="flex-start">
            <Box flex={1} mr={2}>
              <InterfaceField model={model} fieldName="snmp_speed" />
            </Box>
            <Box flex={2}>
              <InterfaceField model={model} fieldName="snmp_alias" />
            </Box>
          </Flex>
          <Flex>
            <Box flex={1} mr={2}>
              <InterfaceField model={model} fieldName="interface_ip" />
            </Box>
            <Box flex={1}>
              <InterfaceField model={model} fieldName="interface_ip_netmask" />
            </Box>
          </Flex>
          <Flex>
            <InterfaceField
              model={model}
              loading={loadingPeeringOptions}
              fieldName="ix_id"
              options={peeringIxOptions}
              childProps={{ showFilter: true, optionLimit: 250, clearable: true }}
            />
          </Flex>
          {$auth.hasPermission('boundaryasn_override.enabled') && (
            <Box>
              <Field large mb={0} name="top_nexthop_asns" />
              <InputMultiple name="top_nexthop_asns" addButtonText="Add ASN">
                {({ field, addButton }) => {
                  if (field.fieldStates.length === 0) {
                    return <Box>{addButton}</Box>;
                  }
                  return field.map((f, index) => (
                    <Flex key={f.Asn._id} alignItems="flex-start">
                      <Box>
                        <Field large field={f.Asn} onQuery={$lookups.asNumbers}>
                          <Select autoComplete clearable showFilter menuWidth={350} />
                        </Field>
                      </Box>
                      <Box ml={1}>
                        <Button
                          intent="danger"
                          minimal
                          css={{ alignSelf: 'center' }}
                          onClick={() => {
                            field.form.getField('top_nexthop_asns').remove(index);
                          }}
                          icon="trash"
                        />
                      </Box>
                      {index === 0 && <Box ml={1}>{addButton}</Box>}
                    </Flex>
                  ));
                }}
              </InputMultiple>
            </Box>
          )}
        </Box>

        <Card mt={1} p={2} mb="-15px" bg="transparent">
          <Heading level={5} mb={1}>
            Classification
          </Heading>
          <Flex mb={1}>
            <Box flex={1} mr={2}>
              <InterfaceField
                model={model}
                fieldName="connectivity_type"
                options={$interfaces.connectivityTypeOptions}
                mb={0}
              />
            </Box>
            <Box flex={1}>
              <InterfaceField
                model={model}
                fieldName="network_boundary"
                options={$interfaces.networkBoundaryOptions}
                mb={0}
              />
            </Box>
          </Flex>
          <Box flex={1}>
            <InterfaceField model={model} fieldName="provider" mb={0} />
          </Box>
        </Card>
      </FormDialog>
    );
  }
}

export default InterfaceFormDialog;
