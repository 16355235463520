import React from 'react';
import { inject } from 'mobx-react';

import Link from 'core/components/Link';

const ApplicationLink = ({ $app, application, children, ...rest }) => {
  if ($app.isSubtenant) {
    return children || application;
  }

  return (
    <Link to={`/v4/core/quick-views/application/${encodeURIComponent(application)}`} {...rest}>
      {children || application}
    </Link>
  );
};

export default inject('$app')(ApplicationLink);
