/*
  displays a row of aggregate values, eg:

  Avg: 100ms • Min: 50ms • Max: 200ms
*/
import React, { Component, Fragment } from 'react';
import { Flex, Icon, Text } from 'core/components';
import { percentToText } from 'app/util/utils';

export default class AggregateView extends Component {
  static defaultProps = {
    aggregate: {}, // expected to be 'avg', 'min', 'max' keys
    isPercentage: false
  };

  render() {
    const { aggregate, isPercentage, isThroughput, toFixed, unit, ...containerProps } = this.props;

    return (
      <Flex alignItems="center" gap="4px" mb={2} {...containerProps}>
        {/* let's ensure our aggregates are rendered in a predictable order */}
        {['avg', 'min', 'max'].map((aggType, idx) => {
          const aggValue = aggregate?.[aggType];
          let value = '--'; // default value if we're working with anything other than a number

          if (typeof aggValue === 'number') {
            if (isPercentage) {
              value = percentToText(aggValue * 100); // packet loss
            } else if (isThroughput) {
              value = `${aggValue.toFixed(toFixed)} ${unit}`; // throughput
            } else {
              value = `${aggValue / 1000}${unit || 'ms'}`; // latency, jitter
            }
          }

          return (
            <Fragment key={aggType}>
              <Text fontWeight="bold" textTransform="capitalize" small>
                {aggType}:
              </Text>
              <Text small>{value}</Text>
              {idx < 2 && <Icon icon="symbol-circle" iconSize={11} color="lightGray1" />}
            </Fragment>
          );
        })}
      </Flex>
    );
  }
}
