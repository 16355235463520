import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Text } from 'core/components';
import { formConsumer } from 'core/form';

import FilterGroupDisplay from './FilterGroupDisplay';
import FilterGroupConnector from './FilterGroupConnector';

@inject('$dictionary')
@formConsumer
@observer
class FilterOptionsDisplay extends Component {
  static defaultProps = {
    readOnly: false,
    showEmptyText: true,
    showButtonText: true,
    field: undefined,
    fieldName: 'filters'
  };

  render() {
    const { form, field, fieldName, loading, ...rest } = this.props;

    const filterGroups = field ? field['filters.filterGroups'] : form.getField(`${fieldName}.filterGroups`);
    const filterGroupsValue = field
      ? field['filters.filterGroups'].getValue()
      : form.getValue(`${fieldName}.filterGroups`);
    const groupConnector = field ? field['filters.connector'] : form.getField(`${fieldName}.connector`);

    const numFilterGroups = filterGroupsValue && filterGroupsValue.length;

    return (
      <Box {...rest}>
        {numFilterGroups > 0 && (
          <div>
            {numFilterGroups > 1 && (
              <Box mb={1}>
                <FilterGroupConnector connector={groupConnector} readOnly />
              </Box>
            )}

            {filterGroups.map((group, index) => (
              <FilterGroupDisplay
                {...this.props}
                key={group.connector._id}
                form={form}
                group={group}
                index={index}
                groups={filterGroups}
                mt={index > 0 ? 1 : 0}
              />
            ))}
          </div>
        )}
        {numFilterGroups === 0 && <Text color="muted">No filters selected</Text>}
      </Box>
    );
  }
}

export default FilterOptionsDisplay;
