import * as React from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { AZURE_ENTITY_TYPES } from 'shared/hybrid/constants';
import { Box, Card, Flex, Icon, Spinner, Tag, Text, Tooltip } from 'core/components';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';

const DropZoneCard = styled(Card)`
  &.droppable {
    border: 1px solid ${({ theme }) => theme.colors.success};
    background: ${({ theme }) => theme.colors.successBackground};
  }

  ${({ intent, theme }) => {
    if (intent) {
      return css`
        border: 1px solid ${theme.colors[intent]};
        background: ${theme.colors[`${intent}Background`]};
      `;
    }

    return null;
  }}
`;

export default class SubscriptionIdDropZone extends React.PureComponent {
  static defaultProps = {
    breakdown: { valid: 0, invalid: 0 },
    loading: false,
    onSubscriptionIdListChange: null
  };

  state = {
    droppable: false
  };

  componentDidMount() {
    window.addEventListener('paste', this.handlePaste, true);
    window.addEventListener('dragover', this.dropTargetToggle, true);
    window.addEventListener('drop', this.handleDrop, true);
  }

  componentWillUnmount() {
    window.removeEventListener('paste', this.handlePaste, true);
    window.removeEventListener('dragover', this.dropTargetToggle, true);
    window.removeEventListener('drop', this.handleDrop, true);
  }

  handlePaste = (e) => {
    this.handleSubscriptionIdListChange(e.clipboardData.getData('text/plain'));
  };

  dropTargetToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ droppable: true });
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ droppable: false }, () => {
      if (e.dataTransfer.files?.length > 0) {
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();

        reader.onload = () => {
          this.handleSubscriptionIdListChange(reader.result);
        };

        reader.readAsText(file);
      }
    });
  };

  // when we have subscription ids, trim them up and make sure we have something
  handleSubscriptionIdListChange = (idString) => {
    const { onSubscriptionIdListChange } = this.props;

    if (onSubscriptionIdListChange) {
      const ids = idString
        .split(',')
        .map((id) => id.trim())
        .filter((id) => !!id);

      onSubscriptionIdListChange(ids);
    }
  };

  /*
    There are four types of status we can report on:

    - default: an icon of an Azure subscription
    - droppable: a file has been dragged over the drop zone, this shows a success intent circle tick icon
    - loading: indicates subscription ids are being checked for validity against the API
    - loaded: shows a breakdown of how many are valid and invalid
  */
  get statusIndicator() {
    const { loading, breakdown } = this.props;
    const { droppable } = this.state;
    let status = null;

    if (loading) {
      return <Spinner size={32} />;
    }

    if (droppable) {
      return <Icon icon="tick-circle" intent="success" iconSize={32} />;
    }

    if (breakdown?.valid || breakdown?.invalid) {
      const { valid, invalid } = breakdown;

      status = (
        <Flex mt={1} alignItems="center" minHeight={20}>
          {valid > 0 && <Tag icon="tick-circle" intent="success" minimal>{`${valid} valid`}</Tag>}
          {invalid > 0 && <Tag ml={1} icon="error" intent="danger" minimal>{`${invalid} invalid`}</Tag>}
        </Flex>
      );
    }

    return (
      <Flex flex={1} flexDirection="column" alignItems="center" justifyContent="center">
        <CloudIcon cloudProvider="azure" entity={AZURE_ENTITY_TYPES.SUBSCRIPTION} iconSize={status ? 24 : 32} />
        {status}
      </Flex>
    );
  }

  /*
    The drop zone can display intent styling once something has been loaded.

    success: all subscription ids are confirmed valid
    warning: at least one of the subscriptions is invalid whether that be via local validation rules or API check
    danger: all the subscriptions are invalid via local validation rules or API check
  */
  get intent() {
    const { breakdown, loading } = this.props;
    const { valid, invalid } = breakdown;
    let intent = null;

    if (!loading && (valid > 0 || invalid > 0)) {
      // if we've loaded some subscriptions...
      if (invalid > 0) {
        // if all subscriptions are invalid, mark as danger, otherwise just a warning
        intent = valid === 0 ? 'danger' : 'warning';
      } else {
        // all are valid
        intent = 'success';
      }
    }

    return intent;
  }

  /*
    When we have at least one invalid subscription an additional tooltip is shown to let the user
    know that the invalid subscription ids will not be included in the scope.
  */
  get tooltip() {
    const { intent } = this;

    if (intent === 'warning' || intent === 'danger') {
      const content =
        intent === 'warning'
          ? 'At least one subscription ID is invalid and will not be included in the metadata enrichment scope'
          : 'All subscription IDs are invalid and will not be included in the metadata enrichment scope';

      return (
        <Tooltip content={<Box width={200}>{content}</Box>}>
          <Icon ml={1} icon="info-sign" color={this.intent} />
        </Tooltip>
      );
    }

    return null;
  }

  render() {
    const { breakdown, loading, onSubscriptionIdListChange, ...cardProps } = this.props;
    const { droppable } = this.state;

    return (
      <DropZoneCard
        alignItems="center"
        alignSelf="center"
        className={classNames({ droppable })}
        display="flex"
        flexDirection="column"
        height={165}
        justifyContent="space-between"
        p={2}
        width={165}
        intent={this.intent}
        {...cardProps}
      >
        <Flex>
          <Text fontWeight="bold">Subscription IDs</Text>
          {this.tooltip}
        </Flex>
        {this.statusIndicator}
        <Text fontSize={10} textAlign="center" muted>
          Paste or drag a file of comma-delimited subscription IDs here
        </Text>
      </DropZoneCard>
    );
  }
}
