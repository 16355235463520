import React, { Component } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';

import { Flex, Tabs, Tab } from 'core/components';
import { MAX_DETAIL_TABS } from 'app/util/constants';

import TabResultsTable from './TabResultsTable';
import TabSelector from './TabSelector';
import { defaultTabWhitelist } from './tabLists';

function mergeTabs(tabs1, tabs2) {
  const mergedTabs = tabs1.slice(0);
  tabs2.forEach((tab) => {
    if (mergedTabs.some((t) => t.id === tab.id)) {
      mergedTabs.push(tab);
    }
  });
  return mergedTabs;
}

function filterTabs(tabs, { hasGCEDevice, hasAzureDevice, hasAWSDevice }, { sort }) {
  const filteredTabs = tabs.filter(
    (tab) =>
      tab &&
      tab.id &&
      !(
        (!hasAzureDevice && tab.id.startsWith('az_')) ||
        (!hasGCEDevice && tab.id.startsWith('gce_')) ||
        (!hasAWSDevice && tab.id.startsWith('aws_'))
      )
  );

  return sort ? filteredTabs.sort((a, b) => (a.title < b.title ? -1 : 1)) : filteredTabs;
}

@inject('$app', '$devices', '$exports', '$setup', '$tabs')
@observer
class DetailTabs extends Component {
  state = {
    activeTab: undefined,
    visibleTabs: undefined
  };

  static getDerivedStateFromProps(props, state) {
    const { $devices, $setup, $tabs, defaultTabs, tabPrefsId, whitelist, cloud, maxTabs = MAX_DETAIL_TABS } = props;
    let { tabs, visibleTabs } = state;

    const tabDefinitions = $tabs.getTabs(cloud);
    const wl = whitelist || defaultTabWhitelist;
    const allowableTabs = Object.values(tabDefinitions).filter((td) => td.id && wl.includes(td.id));
    const allowableDefaultTabs = filterTabs(defaultTabs, $devices, { sort: !!tabPrefsId });

    if (!tabs) {
      const allTabs = mergeTabs(filterTabs(allowableTabs, $devices, { sort: !!tabPrefsId }), allowableDefaultTabs);

      if (tabPrefsId) {
        const tabPrefs = get(toJS($setup.settings), `tabPrefs.${tabPrefsId}`);
        if (tabPrefs) {
          // tabPrefs can reference tabs that are no longer in allTabs so be careful not to assume
          tabs = [];
          tabPrefs.forEach(({ id, visible }) => {
            const tab = allTabs.find((t) => t.id === id);

            if (tab) {
              tabs.push({
                ...tab,
                visible,
                value: id,
                label: tab.title
              });
            }
          });

          const newTabs = allTabs
            .filter((tab) => !tabs.find((findTab) => findTab.id === tab.id))
            .map((newTab) => ({ ...newTab, visible: false, value: newTab.id, label: newTab.title }));

          tabs = [...tabs, ...newTabs];

          visibleTabs = tabs.filter((tab) => tab.visible).map((tab) => tab.id);
        }
      }

      if (!tabs) {
        tabs = allowableDefaultTabs
          .map(({ id }) => ({ ...allTabs.find((tab) => tab.id === id), visible: true }))
          .concat(allTabs.filter((tab) => !allowableDefaultTabs.find((defaultTab) => tab.id === defaultTab.id)))
          .map((tab) => ({ ...tab, value: tab.id, label: tab.title }));
      }
    }

    if (!visibleTabs) {
      visibleTabs = allowableDefaultTabs.map((tab) => tab.id);
    }

    // TODO: this check here isn't good enough to prevent the case where there are no visible tabs *by default*
    let { activeTab } = state;
    if (!activeTab || !visibleTabs.includes(activeTab)) {
      [activeTab] = visibleTabs;
    }

    return {
      activeTab,
      tabs,
      visibleTabs: visibleTabs.slice(0, maxTabs)
    };
  }

  componentDidMount() {
    const { $exports } = this.props;

    $exports.getSettings().then(({ activeTab }) => {
      if (activeTab) {
        this.setState({
          activeTab
        });
      }
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleTabChange = (tab) => {
    const { $exports } = this.props;

    this.setState({ activeTab: tab });
    $exports.setHash({ activeTab: tab, sortResultsBy: undefined, sortResultsDir: undefined });
  };

  handleTabPrefsChange = (tabs) => {
    const visibleTabs = tabs.filter((tab) => tab.visible).map((tab) => tab.id);
    const { noPrefs } = this.props;

    this.setState({ tabs, visibleTabs }, () => {
      const { $setup, tabPrefsId } = this.props;
      const tabPrefs = get($setup, 'settings.tabPrefs') || {};
      tabPrefs[tabPrefsId] = tabs.map(({ id, visible }) => ({ id, visible }));
      if (!noPrefs) {
        $setup.updateSettings({ tabPrefs });
      }
    });
  };

  render() {
    const {
      queryOverrides,
      tabPrefsId,
      $setup,
      defaultTabs,
      simpleFilters,
      simpleFilterGroups,
      fixedTabs,
      ...tableProps
    } = this.props;

    const { activeTab, tabs, visibleTabs } = this.state;
    const visibleTabDefs = visibleTabs.map((tabId) => tabs.find((tab) => tab.id === tabId));

    return (
      <Tabs onChange={this.handleTabChange} selectedTabId={activeTab} renderActiveTabPanelOnly>
        {visibleTabDefs.map((tab) => (
          <Tab
            key={tab.id}
            {...tab}
            visible={undefined}
            panel={
              <TabResultsTable
                {...tableProps}
                {...tab}
                simpleFilters={simpleFilters}
                simpleFilterGroups={simpleFilterGroups}
                queryOverrides={queryOverrides}
              />
            }
          />
        ))}
        {tabPrefsId && (
          <Flex justifyContent="flex-end" flex={1}>
            <TabSelector
              activeTab={activeTab}
              tabs={tabs}
              visibleTabs={visibleTabs}
              onChange={this.handleTabPrefsChange}
            />
          </Flex>
        )}
      </Tabs>
    );
  }
}

export default DetailTabs;
