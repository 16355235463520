import { pixelsToPoints } from 'app/views/hybrid/utils/d3/converters';

/*
  Represents a value in pixels and points
*/
export default function PixelValue(pixels) {
  this.pixels = pixels;
}

PixelValue.prototype.toString = function toString() {
  return this.pixels;
};

PixelValue.prototype.valueOf = function valueOf() {
  return this.pixels;
};

PixelValue.prototype.toPoints = function toPoints() {
  return pixelsToPoints(this.pixels);
};
