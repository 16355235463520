import React from 'react';
import styled, { css } from 'styled-components';
import { Box, Flex, Icon, Link, Text } from 'core/components';
import AnimatedArrow, { animatedArrowStyles } from 'app/components/AnimatedArrow';
import ProductLabel from 'app/components/navbar/ProductLabel';

const StyledMenuHeader = styled(Box)`
  ${animatedArrowStyles};

  position: relative;
  right: -1px;

  // transparent borders so things don't shift
  border: ${({ borderBottom }) => borderBottom || '1px solid transparent'};

  a {
    color: ${({ theme, color }) => color || theme.colors.body};
  }

  ${({ active, theme }) =>
    active === true &&
    css`
      border-right: 1px solid ${theme.colors.navbarMenuCenterBackground};
      border-top: ${theme.borders.menuDivider};
      border-bottom: ${theme.borders.menuDivider};
      background-color: ${theme.colors.navbarMenuCenterBackground};
    `}

  &:hover {
    background-color: ${(props) => props.theme.colors.navbarMenuCenterBackground}
    border-right: ${(props) => props.theme.borders.menuDivider};

    ${({ hasSubItems, theme }) =>
      hasSubItems &&
      css`
        border-right: 1px solid ${(props) => props.theme.colors.navbarMenuCenterBackground};
        border-top: ${theme.borders.menuDivider};
        border-bottom: ${theme.borders.menuDivider};
      `};


    a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;

      .menu-header-icon.bp4-icon {
        color: ${({ theme }) => theme.colors.primary} !important;

      ${({ useFill, theme }) =>
        useFill &&
        css`
          svg {
            fill: ${theme.colors.primary} !important;
          }
        `}
    }
  }
`;

function MenuHeader({
  title,
  icon,
  to,
  blank,
  color,
  iconColor = 'muted',
  onClick,
  onMouseEnter,
  hasSubItems,
  showAnimatedArrow,
  isUpdated,
  isBeta,
  isNew,
  isDarkMode,
  isPreview,
  isProductLandingLink,
  fontWeight,
  ...rest
}) {
  if (to) {
    return (
      <StyledMenuHeader
        color={color}
        onMouseEnter={onMouseEnter ? () => onMouseEnter(title) : null}
        useFill={typeof icon === 'string'}
        {...rest}
      >
        {hasSubItems && (
          <Link to={to} blank={blank} onClick={onClick} display="flex" alignItems="center">
            {icon && <Icon className="menu-header-icon" icon={icon} color={iconColor} mr="8px" iconSize={16} />}
            <Text as="div" flex={1} fontWeight={fontWeight}>
              {title}
            </Text>
            <Icon icon="chevron-right" color="muted" ml="auto" />
          </Link>
        )}

        {!hasSubItems && (
          <Link to={to} blank={blank} onClick={onClick}>
            {icon && <Icon className="menu-header-icon" icon={icon} color={iconColor} mr="8px" iconSize={16} />}
            <Text fontWeight={fontWeight}>{title}</Text>
            <ProductLabel
              isUpdated={isUpdated}
              isBeta={isBeta}
              isPreview={isPreview}
              isDarkMode={isDarkMode}
              isNew={isNew}
            />
            {showAnimatedArrow && <AnimatedArrow />}
          </Link>
        )}
      </StyledMenuHeader>
    );
  }

  return (
    <Flex alignItems="center" {...rest}>
      {icon && <Icon icon={icon} color={iconColor} mr="8px" iconSize={16} />}
      <Text fontWeight={fontWeight}>{title}</Text>
    </Flex>
  );
}

export default MenuHeader;
