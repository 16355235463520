import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Classes } from '@blueprintjs/core';

import { Popover, Menu, MenuDivider, MenuItem, Button } from 'core/components';
import StandaloneDimensionSelector from 'app/components/dimensions/StandaloneDimensionSelector';

@inject('$app', '$dictionary', '$dataviews', '$devices')
@observer
class ChangeViewTypeMenu extends Component {
  static defaultProps = {
    showButtonText: true
  };

  state = {
    dimensionSelectorOpen: false,
    isOpen: false
  };

  matrixBy = (matrixBy) => {
    // Note: we don't need to change the state because the cmp is destroyed from the click event
    // suppress fetch if matrixBy has no length; invalid query anyway so we just leave the form in error state
    const { dataview } = this.props;
    dataview.applyToAllQueries({ matrixBy }, matrixBy.length === 0);
  };

  hideMatrixBySelect = () => {
    this.setState({ dimensionSelectorOpen: false });
  };

  handleInteraction = (isOpen) => {
    this.setState({ isOpen });
  };

  handleViewTypeChange(newViewType) {
    const { dataview, onViewTypeChange } = this.props;
    const oldViewType = dataview.viewType;

    if (newViewType === 'matrix') {
      dataview.eachBucket('applyToEachQuery', [
        { viz_type: newViewType, show_total_overlay: false, show_overlay: false, generatorMode: false }
      ]);
      this.setState({ dimensionSelectorOpen: true });
    } else {
      dataview.viewType = newViewType;
    }

    if (onViewTypeChange) {
      onViewTypeChange(oldViewType, newViewType);
    }
  }

  renderViewTypeMenu(options = {}) {
    const { $app, $dataviews, dataview, small, filterView } = this.props;
    const { asMenuItem, icon } = options;

    let Root = Menu;
    if (asMenuItem === true) {
      Root = MenuItem;
    }
    return (
      <Root text={asMenuItem ? 'Change Visualization' : undefined} icon={asMenuItem ? icon : undefined}>
        {$dataviews.categoryTitles.reduce((opts, category) => {
          let filteredOptions = $dataviews.viewTypeOptions.filter(
            (view) =>
              view.category === category &&
              !view.hideFromViewMenu &&
              (!$app.isSubtenant || !view.hideInMkp || dataview.viewType === view.value)
          );

          if (filterView) {
            filteredOptions = filteredOptions.filter(filterView);
          }

          if (filteredOptions.length) {
            opts.push(<MenuDivider key={category} title={category} />);
          }

          return opts.concat(
            filteredOptions.map((view) => (
              <MenuItem
                key={view.value}
                text={view.label}
                className={dataview.viewType === view.value ? Classes.ACTIVE : undefined}
                icon={view.icon}
                onClick={() => this.handleViewTypeChange(view.value)}
                small={small}
              />
            ))
          );
        }, [])}
      </Root>
    );
  }

  render() {
    const {
      $dictionary,
      $dataviews,
      $devices,
      dataview,
      showButtonText,
      buttonProps,
      small,
      asMenuItem = false
    } = this.props;
    const { dimensionSelectorOpen, isOpen } = this.state;

    if (!dataview) {
      return null;
    }

    let viz_type = dataview.viewType;
    let disabledDimensions;
    let field;

    if (dataview.formState) {
      field = dataview.formState.getField('matrixBy');
      viz_type = dataview.formState.getValue('viz_type');
      const device_name = dataview.formState.getValue('device_name');
      const device_labels = dataview.formState.getValue('device_labels');
      const device_sites = dataview.formState.getValue('device_sites');
      const device_types = dataview.formState.getValue('device_types');
      const all_devices = dataview.formState.getValue('all_devices');

      // eslint-disable-next-line no-unused-vars
      const hasKProbeSelected = all_devices
        ? $devices.hasDnsProbe
        : $devices.containsDnsProbe({ device_name, device_labels, device_sites, device_types });
      disabledDimensions = !hasKProbeSelected ? $dictionary.dictionary.kprobeDimensions : [];
    } else {
      disabledDimensions = [];
    }

    const { icon, label } = $dataviews.getViewType(viz_type);

    const buttonText = label || 'Visualization';
    return (
      <>
        {asMenuItem && this.renderViewTypeMenu({ asMenuItem: true, icon })}
        {!asMenuItem && (
          <Popover
            isOpen={isOpen}
            content={this.renderViewTypeMenu()}
            onInteraction={this.handleInteraction}
            position="bottom-right"
            modifiers={{
              keepTogether: { enabled: true },
              flip: { enabled: true, boundariesElement: 'viewport' },
              preventOverflow: { enabled: true, boundariesElement: 'viewport' },
              offset: { enabled: true, offset: '0,2px' }
            }}
          >
            <Button
              {...buttonProps}
              icon={showButtonText ? undefined : icon}
              text={showButtonText ? buttonText : undefined}
              rightIcon={showButtonText ? 'caret-down' : undefined}
              active={isOpen}
              small={small}
            />
          </Popover>
        )}
        {dimensionSelectorOpen && (
          <StandaloneDimensionSelector
            title="Matrix By Dimensions"
            isOpen={dimensionSelectorOpen}
            onSave={this.matrixBy}
            onClose={this.hideMatrixBySelect}
            saveButtonText="Matrix By Selected Dimensions"
            multi
            field={field}
            disabledValues={disabledDimensions}
            showLabel={false}
          />
        )}
      </>
    );
  }
}

export default ChangeViewTypeMenu;
