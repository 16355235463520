import Validator from 'validatorjs';
import { getBaseSorts } from 'app/stores/query/ExplorerQueryModel';
import { getAppProtocol } from 'app/util/dimensions';
import { decoupleFromFlow, getDuration, getFlowAggIntervalOptions } from '@kentik/ui-shared/query/queryUtils';
import { FPA_ADVANCED_DEFAULTS } from '@kentik/ui-shared/fpa/constants';
import { generateFilterFields, generateTimeFields } from './helpers';

const filterFields = generateFilterFields();
const filterDimensions = generateFilterFields('filterDimensions', [], {
  filterGroupsRules: [{ required_if: ['filterDimensionsEnabled', true] }]
});
const timeFields = generateTimeFields();

function skipDimensionValidation(attribute) {
  return (
    (attribute === 'generatorDimensions' && !this.validator.input.generatorMode) ||
    (attribute === 'metric' && this.validator.input.generatorMode)
  );
}

Validator.registerImplicit(
  'customRequireDimensions',
  function customRequireDimensions(val, req, attribute) {
    const chartTypes = ['matrix'];
    const { input } = this.validator;

    if (attribute === 'generatorDimensions' && !input.generatorMode) {
      return true;
    }

    if (
      (attribute === 'metric' && input.generatorMode && !input.filterDimensionsEnabled) ||
      chartTypes.includes(input.viz_type)
    ) {
      return this.validator.getRule('required').validate(val);
    }

    return true;
  },
  'A dimension is required'
);

Validator.register(
  'customMinDimensions',
  function customMinDimensions(val, req, attribute) {
    const [type, min] = this.getParameters();

    if (skipDimensionValidation.call(this, attribute)) {
      return true;
    }

    // allow sankey single dst_bgp_aspath dimension
    if (this.validator.input.viz_type === 'sankey' && val.length === 1 && val[0] === 'dst_bgp_aspath') {
      return true;
    }

    if (this.validator.input.viz_type === type) {
      return this.validator.getRule('min').validate(val, min);
    }

    return true;
  },
  'Too few dimensions selected for this chart type'
);

Validator.register(
  'customGeoHeatMapDimensions',
  function customGeoHeatMapDimensions(val, req, attribute) {
    const dimensions = [
      'i_device_site_country',
      'i_device_site_name',
      'kt_dst_market',
      'Geography_dst',
      'dst_geo_region',
      'dst_geo_city',
      'kt_src_market',
      'src_geo_region',
      'src_geo_city',
      'Geography_src',
      'i_ult_exit_site_country',
      'i_ult_exit_site'
    ];

    if (skipDimensionValidation.call(this, attribute)) {
      return true;
    }

    if (this.validator.input.viz_type === 'geoHeatMap') {
      return val.length === 1 && dimensions.includes(val[0]);
    }

    return true;
  },
  'Only a single geographical dimension (either Country, Region, City, Site, Site Country, Ultimate Exit Site, Ultimate Exit Site Country, or Custom Geo) can be selected'
);

Validator.register(
  'validateOutsort',
  function validateOutsort(val) {
    const viz = this.validator.input.viz_type;
    return !!(getBaseSorts([val], viz) || []).length;
  },
  'Invalid Primary Display/Sort Metric for this visualization'
);

Validator.register(
  'validateMinsPolling',
  function validateMinsPolling(val) {
    const { metric, filters, fastData, lookback_seconds, starting_time, ending_time, reAggFn, reAggInterval } =
      this.validator.input;
    if (val) {
      const interval = getDuration({ lookback_seconds, starting_time, ending_time });
      const appProtocolId = getAppProtocol(metric, filters.filterGroups, true);
      let requestedBucketSize = reAggInterval;
      if (appProtocolId && decoupleFromFlow({ lookback_seconds, starting_time }, [appProtocolId])) {
        return true;
      }
      if (reAggFn === 'none') {
        let storageBucketSizeSecs = 60;
        if (fastData === 'Fast' || (fastData === 'Auto' && (interval < 60 || interval > 86400))) {
          storageBucketSizeSecs = 3600;
        }
        requestedBucketSize = val * storageBucketSizeSecs;
      } else if (reAggInterval === 'auto') {
        const options = getFlowAggIntervalOptions(this.validator.input, { ignoreDefault: true });
        if (options.length === 0) {
          return true;
        }
        requestedBucketSize = options[0].value;
      }
      return interval / requestedBucketSize <= 10080; // arbitrarily limit to 10k datapoints per returned time series
    }
    return true;
  },
  'Your selected aggregation window is too small. To keep this window size, use bucket aggregation or a smaller time range'
);

const options = {
  name: 'Explorer Sidebar',
  showPristineErrors: true,
  groups: {
    advanced: [
      'customAsGroups',
      '^cutFn',
      'fastData',
      'generator.*',
      'mirror',
      'overlay_day',
      '^secondaryTopx',
      '^show.*overlay',
      'hostname_lookup'
    ],
    dimensions: ['metric', '^filterDimension', 'cidr.*'],
    matrix: ['matrixBy'],
    metrics: ['^aggregate', 'outsort', 'secondaryOutsort'],
    time: Object.keys(timeFields),
    filtering: ['filters.filterGroups', 'filters.connector'],
    devices: ['device_types', 'device_labels', 'device_sites', 'device_name', 'all_devices'],
    bracketing: ['bracketOptions'],
    view: [
      'sync_axes',
      'topx',
      'use_log_axis',
      'use_secondary_log_axis',
      'sync_extents',
      'update_frequency',
      'viz_type'
    ]
  }
};

const dimensionsFieldValidation = {
  rules: [
    'customRequireDimensions',
    'customGeoHeatMapDimensions',
    'customMinDimensions:sankey,2',
    'max:20',
    'noConflictingUniques'
  ],
  messages: {
    customMinDimensions: 'At least 2 dimensions must be selected for this chart type',
    max: 'A maximum of 20 dimensions can be selected at once'
  }
};

const fields = {
  /*
   * Query
   */
  metric: {
    ...dimensionsFieldValidation,
    closeOnSelect: false,
    reorderable: true,
    filterable: true,
    optionsBlacklist: new Set(['Traffic']),
    suppressAutoSubmit: true
  },
  cidr: {
    label: 'v4 CIDR'
  },
  cidr6: {
    label: 'v6 CIDR'
  },
  customAsGroups: {
    label: 'Use AS Groups'
  },
  matrixBy: {
    rules: 'required_if:viz_type,matrix',
    messages: {
      required_if: 'Matrix With Dimensions are required'
    },
    suppressAutoSubmit: true
  },
  hostname_lookup: {
    label: 'Reverse DNS Lookups'
  },
  fastData: {
    label: 'Dataseries'
  },
  aggregateTypes: {
    rules: 'required|noConflictingAggregateTypes|maxGenericAggregates:4',
    messages: {
      required: 'At least one metric is required'
    },
    suppressAutoSubmit: true
  },
  aggregateThresholds: {},
  unitsLegacy: { label: 'Use Preset Selections for:', suppressAutoSubmit: true },
  outsort: {
    label: 'Primary Display & Sort Metric',
    rules: 'required|validateOutsort',
    suppressAutoSubmit: true
  },
  secondaryOutsort: {
    rules: 'different:outsort|validMirrorAndSecondaryOutsort',
    messages: {
      different: 'Secondary Sort must be different from Primary Sort'
    },
    suppressAutoSubmit: true
  },
  bracketOptions: {
    messages: {
      required_if: 'Bracket Options are required for this chart type'
    }
  },
  secondaryTopxSeparate: {
    label: 'Sort Secondary Metric Separately',
    suppressAutoSubmit: true
  },
  secondaryTopxMirrored: {
    label: 'Show Secondary Metric on Negative Axis',
    rules: 'mirrorOrSecondaryMirrorOnly',
    suppressAutoSubmit: true
  },
  topx: {
    label: 'Visualization Depth'
  },
  depth: {},
  sync_all_axes: { suppressAutoSubmit: true },
  sync_axes: { suppressAutoSubmit: true },
  use_log_axis: { suppressAutoSubmit: true },
  use_secondary_log_axis: { suppressAutoSubmit: true },
  sync_extents: { suppressAutoSubmit: true },
  show_site_markers: {},
  update_frequency: {},
  show_overlay: {
    label: 'Historical Overlay'
  },
  show_total_overlay: {
    label: 'Total Overlay'
  },
  overlay_day: {
    label: 'Days Back',
    type: 'number',
    transform: {
      in: (value) => (value ? value * -1 : value),
      out: (value) => (value ? value * -1 : value)
    }
  },
  mirror: {
    label: 'Bi-directional Charting',
    rules: 'validMirrorAndSecondaryOutsort|mirrorOrSecondaryMirrorOnly',
    beta: true
  },
  filterDimensionsEnabled: { suppressAutoSubmit: true },
  filterDimensionName: {
    label: 'Dimension Name',
    rules: [{ required_if: ['filterDimensionsEnabled', true] }],
    messages: {
      required_if: 'Filter Dimension Name is required when filter-based dimension is enabled'
    },
    suppressAutoSubmit: true
  },
  filterDimensionOther: {
    helpText: 'Includes all traffic that does not match any of the series defined below',
    suppressAutoSubmit: true
  },
  ...filterDimensions,
  ...filterFields,
  ...timeFields,

  /*
   * Devices
   */
  all_devices: {
    defaultValue: false,
    rules: 'atLeastOneIfNotAllDevices'
  },
  device_name: {
    defaultValue: []
  },
  device_labels: {
    defaultValue: []
  },
  device_sites: {
    defaultValue: []
  },
  device_types: {
    defaultValue: []
  },

  /*
   * Display
   */
  viz_type: {
    label: 'Visualization Type',
    suppressAutoSubmit: true
  },
  generatorDimensions: {
    ...dimensionsFieldValidation,
    label: 'Chart-level Group By Dimensions'
  },
  generatorMode: {
    label: 'Generate one chart per series'
  },
  generatorColumns: {
    label: 'Charts per row'
  },
  generatorQueryTitle: {
    label: 'Chart Titles'
  },
  generatorTopx: {
    label: 'Chart Visualization Depth'
  },
  generatorPanelMinHeight: {
    label: 'Chart Height'
  },

  // reAgg Control
  forceMinsPolling: {},
  minsPolling: {
    label: 'Flow Aggregation Window',
    defaultValue: 0,
    rules: 'validateMinsPolling'
  },
  reAggInterval: {
    label: 'Width',
    defaultValue: 'auto'
  },
  reAggFn: {
    label: 'Bucket Aggregation',
    defaultValue: 'none',
    options: [
      {
        value: 'none',
        label: 'None'
      },
      {
        value: 'avg',
        label: 'Average'
      },
      // Temporarily removing until we find a use for them
      // {
      //   value: 'count',
      //   label: 'Count'
      // },
      {
        value: 'sum',
        label: 'Sum'
      },
      {
        value: 'max',
        label: 'Maximum'
      },
      {
        value: 'min',
        label: 'Minimum'
      }
    ]
  },

  // kde miner
  use_fpa: {
    label: 'Enable Probable Cause Analysis',
    suppressAutoSubmit: true
  },
  'fpa.type': {
    label: 'Analysis Method',
    defaultValue: 'cpd_fpa',
    options: [
      {
        value: 'cpd_fpa',
        label: 'Change Point Detection + FPA'
      },
      {
        value: 'fpa',
        label: 'Single Window',
        disabled: true
      },
      {
        value: 'diff_fpa',
        label: 'Comparison (2 Windows)',
        disabled: true
      },
      {
        value: 'kde_fpa',
        label: 'KDE FPA',
        disabled: true
      },
      {
        value: 'kde_diff_fpa',
        label: 'KDE Diff FPA',
        disabled: true
      },
      {
        value: 'kde_fsitis',
        label: 'KDE FSITIS',
        disabled: true
      }
    ]
  },

  ...generateTimeFields('fpa.time'),
  ...generateTimeFields('fpa.compare_time'),

  'fpa.dimensions': {
    label: 'Dimensions',
    defaultValue: [],
    reorderable: false,
    filterable: true,
    optionsBlacklist: new Set(['Traffic'])
  },

  // When true, only the selected dimensions here will be used for FPA
  'fpa.overrideDimensions': {
    label: 'Override Dimensions',
    defaultValue: false
  },

  'fpa.min_support_pct': {
    label: 'Min Support Percent',
    helpText:
      'Defines the minimum percentage in which an itemset must appear to be considered frequent. Higher values will filter out less common itemsets.',
    defaultValue: FPA_ADVANCED_DEFAULTS.MIN_SUPPORT_PCT,
    transform: {
      out: (val) => parseInt(val) || 0
    }
  },
  'fpa.viz_perc': {
    label: 'Pruning Percent',
    helpText:
      'Removes redundant itemsets by comparing their frequencies. If a larger itemset contains a smaller one, it is removed if their frequency difference is less than this percentage',
    defaultValue: FPA_ADVANCED_DEFAULTS.VIZ_PERC,
    transform: {
      out: (val) => parseInt(val) || 0
    }
  },
  'fpa.max_itemset_length': {
    label: 'Max Itemset Length',
    helpText:
      'Limits the number of items in an itemset. A lower value reduces complexity but may exclude larger, meaningful patterns.',
    defaultValue: FPA_ADVANCED_DEFAULTS.MAX_ITEMSET_LENGTH,
    transform: {
      out: (val) => parseInt(val) || 0
    }
  },
  'fpa.window_size': {
    label: 'CPD Window Size (samples)',
    helpText:
      'Determines how many consecutive data points are analyzed together when detecting significant changes. A larger window may smooth out noise but delay detection.',
    defaultValue: FPA_ADVANCED_DEFAULTS.WINDOW_SIZE,
    transform: {
      out: (val) => parseInt(val) || 0
    }
  },
  'fpa.chg_thr': {
    label: 'CPD Change Threshold',
    helpText:
      'Specifies the minimum percentage change required for a change point to be considered significant. Helps filter out minor fluctuations that may not be meaningful.',
    defaultValue: FPA_ADVANCED_DEFAULTS.CHG_THR,
    transform: {
      out: (val) => parseFloat(val) || 0
    }
  },
  'fpa.top_num_cpds': {
    label: 'CPD Limit',
    helpText: 'Limits the number of change points detected.',
    defaultValue: FPA_ADVANCED_DEFAULTS.TOP_NUM_CPDS,
    transform: {
      out: (val) => parseInt(val) || 0
    }
  },
  'fpa.perc_thr_cpds': {
    label: 'CPD Percentage Threshold',
    helpText: 'Only changes within this percentage of the max change are shown.',
    defaultValue: FPA_ADVANCED_DEFAULTS.PERC_THR_CPDS,
    transform: {
      out: (val) => parseFloat(val) || 0
    }
  },

  'tsp.forecasting.enabled': {
    label: 'Enable Forecasting'
  },
  'tsp.forecasting.samples': {
    label: 'Forecast samples',
    transform: {
      out: (val) => parseInt(val) || 0
    }
  },
  'tsp.forecasting.seasonalities': {
    label: 'Seasonalities',
    transform: {
      in: (val) => val.toString(),
      out: (val) => val.split(',').map((v) => parseInt(v.trim()))
    }
  },

  'tsp.baseline.enabled': {
    label: 'Enable Baseline'
  },
  'tsp.baseline.seasonalities': {
    label: 'Seasonalities',
    transform: {
      in: (val) => val.toString(),
      out: (val) => val.split(',').map((v) => parseInt(v.trim()))
    }
  }
};

export { fields, options };
