import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';

const RoutePrefixLink = ({ $app, routePrefix, ...rest }) => {
  if ($app.isSubtenant) {
    return routePrefix;
  }

  return (
    <Link to={`/v4/core/quick-views/route-prefix/${routePrefix}`} {...rest}>
      {routePrefix}
    </Link>
  );
};

export default inject('$app')(RoutePrefixLink);
