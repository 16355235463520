import * as React from 'react';
import { inject } from 'mobx-react';
import { withRouter, generatePath } from 'react-router-dom';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function withUrlPathTabs(WrappedComponent) {
  @inject('$app')
  @withRouter
  class WrapperComponent extends React.Component {
    static defaultProps = {
      historyAction: 'replace',
      useUrlPathParam: false,
      urlPathParam: 'tab'
    };

    componentDidMount() {
      const { $app, useUrlPathParam, match, urlPathParam, defaultSelectedTabId } = this.props;

      if (!$app.isExport && useUrlPathParam && !match.params[urlPathParam] && defaultSelectedTabId) {
        this.handleTabChange(defaultSelectedTabId);
      }
    }

    handleTabChange = (tabId) => {
      const { historyAction, history, match, urlPathParam, useUrlPathParam, onChange } = this.props;

      if (useUrlPathParam) {
        const params = {
          ...match.params,
          [urlPathParam]: tabId
        };

        history[historyAction](`${generatePath(match.path, params)}${history.location.search}`);
      }

      if (onChange) {
        onChange(tabId);
      }
    };

    render() {
      const { useUrlPathParam, urlPathParam, selectedTabId, defaultSelectedTabId, history, match, children, ...rest } =
        this.props;

      const derivedSelectedTabId = useUrlPathParam ? match.params[urlPathParam] : selectedTabId;

      return (
        <WrappedComponent
          {...rest}
          defaultSelectedTabId={defaultSelectedTabId}
          selectedTabId={derivedSelectedTabId}
          onChange={this.handleTabChange}
        >
          {children}
        </WrappedComponent>
      );
    }
  }

  WrapperComponent.displayName = `WithConfigOptions(${getDisplayName(WrappedComponent)})`;

  return WrapperComponent;
}
