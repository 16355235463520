import React from 'react';
import { observer } from 'mobx-react';
import ResultsView from 'app/views/synthetics/components/agentResults/ResultsView';
import LoadingPanel from './LoadingPanel';

@observer
export default class ResultsPanel extends React.Component {
  render() {
    const { test, loading, targetResults, trendTargetResults, onChangeResultTimeMs } = this.props;
    const { timelineBounds, resultTimeMs, loadingTrendDetailResults } = test.agentResults;
    const { httpHealthTs, httpHealthAggregates, screenshotsTsResult } = targetResults;
    // we classify this panel has having results by loading state because as trend results are loaded we don't want to flash a blanket "No Results" empty state before showing actual results
    // target results are hydrated by results from AgentResultsState so check both
    const hasResults = loading || (test.agentResults.hasResults && targetResults.hasResults);

    return (
      <LoadingPanel
        loading={!loadingTrendDetailResults && loading}
        test={test}
        testResults={test.results}
        empty={!hasResults}
      >
        <ResultsView
          test={test}
          trendResults={trendTargetResults}
          httpHealthTs={httpHealthTs}
          httpHealthAggregates={httpHealthAggregates}
          screenshotsTsResult={screenshotsTsResult}
          resultTimeMs={resultTimeMs}
          timelineBounds={timelineBounds}
          onHover={onChangeResultTimeMs}
        />
      </LoadingPanel>
    );
  }
}
