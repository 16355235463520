import React, { Component } from 'react';
import { interpolateSpectral, interpolateRdYlBu } from 'd3-scale-chromatic';
import { validateOrigin, validateUpstream } from 'shared/synthetics/bgp';
import GraphContainer from '../../GraphContainer';
import Graph from './graph';

const addColor = (asns, lookups, scheme, rangeLimiter) =>
  asns.reduce((acc, id, i, arr) => {
    if (lookups.asns) {
      const name = lookups.asns[id];
      const colorIndex = rangeLimiter(i ? i / arr.length : i);

      acc[id] = {
        id,
        name,
        color: scheme(colorIndex)
      };
    }

    return acc;
  }, {});

const parseData = ({ config, data }) => {
  if (data) {
    const { origin, check_rpki, upstream } = config;
    const { lookups = {}, visibleASNs, origins, upstreamASNs, ...rest } = data;

    const [expected, unexpected] = visibleASNs.reduce(
      (acc, curr) => {
        if (origins[curr] && (origin || check_rpki)) {
          const { invalid } = validateOrigin(config, {
            origin_asn: parseInt(curr, 10),
            rpki_status: origins[curr].rpki_status
          });
          const i = invalid ? 1 : 0;

          acc[i].push(curr);
        } else if (upstream && upstreamASNs.includes(curr)) {
          const { invalid } = validateUpstream(config, { upstream_asn: parseInt(curr, 10) });
          const i = invalid ? 1 : 0;

          acc[i].push(curr);
        } else {
          acc[0].push(curr);
        }

        return acc;
      },
      [[], []]
    );
    return {
      data: {
        ...rest,
        lookups: {
          ...lookups,
          asns: {
            ...addColor(expected, lookups, interpolateSpectral, (n) => n * 0.25 + 0.75),
            ...addColor(unexpected, lookups, interpolateRdYlBu, (n) => n * 0.25)
          }
        }
      }
    };
  }

  return {
    data: {}
  };
};

const checkForResults = ({ data }) => !!data.numberOfColumns;
class BgpPathsGraphWrapper extends Component {
  render() {
    const { startDate, endDate, ...rest } = this.props;
    return (
      <GraphContainer
        {...rest}
        startDate={endDate - 60}
        endDate={endDate}
        key="bgp-paths-graph"
        type="pathGraph"
        graphComponent={Graph}
        heading="AS Path Visualization"
        text={() =>
          'Network graph showing all key AS paths associated with the monitored prefixes. Hover over any AS node or link to see more information'
        }
        noResultsDescription="No AS path was observed at this time"
        parseData={parseData}
        checkForResults={checkForResults}
      />
    );
  }
}

export default BgpPathsGraphWrapper;
