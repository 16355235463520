import React from 'react';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import Box from 'core/components/Box';
import Flex from 'core/components/Flex';
import HeaderCell from './HeaderCell';

const Header = (props) => {
  const { collection, headerRef, columns, style, useSortTooltips, noHighlightSorted, onSort } = props;

  return (
    <Box ref={headerRef} className="thead">
      <Flex className="tr" style={style} borderBottom="thin">
        {columns.map((column, idx) => (
          <HeaderCell
            {...props}
            useSortTooltips={useSortTooltips}
            noHighlightSorted={noHighlightSorted}
            key={column.key || column.name || column.type || column.id}
            column={column}
            {...collection.sortState}
            isAllSelected={collection.isAllSelected}
            columns={columns}
            index={idx}
            onSort={onSort}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default withTheme(observer(Header));
