import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Form } from 'core/form';
import { fields, options } from 'app/forms/config/explorerQuery';
import { itemExplorerFields } from 'app/forms/config/dashboardItemDetails';
import ExplorerSidebar from 'app/views/core/explorer/ExplorerSidebar';

@Form({ fields: { ...fields, ...itemExplorerFields }, options })
@inject('$explorer')
@withRouter
@observer
class ItemExplorerOptions extends Component {
  componentDidMount() {
    const { $explorer, form, onQueryChange } = this.props;

    if ($explorer.formState && form && $explorer.formState !== form) {
      // we had an old formstate, and now we have a new form, so let's restore old formstate's values
      // this is to support tab switching inside of the dash item edit dialog
      form.init($explorer.formState.getValues());
    }

    $explorer.registerFormState(form, onQueryChange);
  }

  componentDidUpdate({ $explorer, form }) {
    const { onQueryChange } = this.props;

    if (form !== $explorer.formState) {
      $explorer.registerFormState(form, onQueryChange);
    }
  }

  componentWillUnmount() {
    const { $explorer } = this.props;
    $explorer.clear();
  }

  handleControlledByViewChange = (field, value) => {
    const { form, dashboard } = this.props;
    const query = dashboard.get('query');

    if (field.name === 'filter_source' && !!query.filters && value === 'dashboard') {
      form.setValue('filters.connector', query.filters.connector);
      form.setValue('filters.filterGroups', query.filters.filterGroups);
    }

    if (field.name === 'time_locked' && value === true) {
      form.setValues({
        lookback_seconds: dashboard.get('query').lookback_seconds
      });
    }

    if (field.name === 'device_locked' && value === true) {
      form.setValues({
        device_name: query.device_name || [],
        device_sites: query.device_sites || [],
        device_labels: query.device_labels || [],
        device_types: query.device_types || [],
        all_devices: query.all_devices
      });
    }
  };

  render() {
    const { model, onQueryChange } = this.props;

    return (
      <ExplorerSidebar
        model={model}
        sourceLink
        onControlledByViewChange={this.handleControlledByViewChange}
        width={300}
        onSubmit={onQueryChange}
      />
    );
  }
}

export default ItemExplorerOptions;
