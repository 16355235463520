import request from 'superagent';

import $dictionary from 'app/stores/$dictionary';

function getAddressString({ address, city, country, region, postal }) {
  const addressParts = [];

  if (address) {
    addressParts.push(address.trim());
  }

  if (city) {
    addressParts.push(city.trim());
  }

  if (postal && region) {
    addressParts.push(`${region} ${postal}`);
  } else if (region) {
    addressParts.push(region);
  } else if (postal) {
    addressParts.push(postal);
  }

  if (country) {
    addressParts.push(country);
  }

  return addressParts.join(', ');
}

function geocode(address) {
  const addressString = getAddressString(address);

  return request
    .get('https://maps.googleapis.com/maps/api/geocode/json', {
      address: addressString.replace(/\s/g, '+'),
      key: $dictionary.get('googleMapsPublicApiKey')
    })
    .then(
      (response) => {
        const data = JSON.parse(response.text);
        if (data.results.length > 1 && data.results.every((result) => result.formatted_address !== addressString)) {
          throw new Error('Location was not sufficiently specific for geocoding.');
        } else if (data.results.length === 0) {
          throw new Error('Location could not be geocoded.');
        }

        return data.results[0].geometry.location;
      },
      (error) => {
        console.warn('Geocoding error', error);
        throw new Error('Geocoding could not be performed at this time.');
      }
    );
}

export { geocode, getAddressString };
