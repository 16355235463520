import React, { Component } from 'react';
import styled from 'styled-components';

import { Box, Button } from 'core/components';

const FadeOut = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(transparent, ${(props) => props.fadeTo} 50%);
  padding-bottom: 4px;
`;

class ExpandableBox extends Component {
  static defaultProps = {
    maxHeight: 'none',
    fadeTo: '#fff'
  };

  state = {
    expanded: false,
    hasOverflow: false
  };

  boxRef = React.createRef();

  componentDidMount() {
    this.updateOverflow();
  }

  componentDidUpdate() {
    this.updateOverflow();
  }

  updateOverflow() {
    const { maxHeight } = this.props;
    const { hasOverflow: currentHasOverflow } = this.state;

    if (typeof maxHeight === 'number') {
      const box = this.boxRef.current;
      const hasOverflow = box.scrollHeight > maxHeight;

      if (hasOverflow !== currentHasOverflow) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ hasOverflow });
      }
    }
  }

  handleExpandToggle = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  render() {
    const { children, fadeTo, maxHeight, ...rest } = this.props;
    const { expanded, hasOverflow } = this.state;

    const style = {
      maxHeight: !expanded ? maxHeight : 'none',
      position: 'relative',
      overflow: 'hidden'
    };

    return (
      <div ref={this.boxRef} style={style}>
        <Box {...rest}>
          {children}
          {hasOverflow && (
            <FadeOut fadeTo={!expanded ? fadeTo : 'transparent'}>
              <Button
                icon={!expanded ? 'arrow-down' : 'arrow-up'}
                text={!expanded ? 'Expand' : 'Collapse'}
                onClick={this.handleExpandToggle}
                small
              />
            </FadeOut>
          )}
        </Box>
      </div>
    );
  }
}

export default ExpandableBox;
