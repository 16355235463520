import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Position } from '@blueprintjs/core';

import { Box, Button, ButtonGroup, Flex, Menu, MenuItem, Popover, Text } from 'core/components';
import { Field, FormComponent, MultiSelect } from 'core/form';
import storeLoader from 'app/stores/storeLoader';

const fields = {
  selectedSites: {
    label: 'Filter Sites'
  }
};

const options = {
  name: 'Topology Controls'
};

@storeLoader('$sites')
@inject('$auth', '$topo')
@observer
class Legend extends Component {
  static propTypes = {
    onLegendClick: PropTypes.func,
    onFilterChange: PropTypes.func
  };

  static defaultProps = {
    onLegendClick: () => {},
    onFilterChange: () => {}
  };

  @observable
  highlightKey;

  handleLegendClick = (key) => {
    const { onLegendClick } = this.props;
    const newKey = this.highlightKey === key ? '' : key;

    this.highlightKey = newKey;

    onLegendClick(newKey);
  };

  handleSaveClick = () => {
    const { $topo } = this.props;
    $topo.saveTopology();
  };

  handleReloadClick = () => {
    const { $topo } = this.props;
    $topo.reload();
  };

  handleShowProviders = (type) => {
    const { $topo, onFilterChange } = this.props;
    $topo.setShowProviders(type);
    onFilterChange();
  };

  handleShowUnlinked = () => {
    const { $topo, onFilterChange } = this.props;
    $topo.setShowUnlinked();
    onFilterChange();
  };

  handleShowDevices = () => {
    const { $topo, onFilterChange } = this.props;
    $topo.setLevel();
    onFilterChange();
  };

  handleSiteSelect = (field, value) => {
    const { $topo, onFilterChange } = this.props;
    $topo.setSelectedSites(value);
    onFilterChange();
  };

  get siteOptions() {
    const { $sites } = this.props;
    return $sites.collection.map((site) => ({ label: site.get('title'), value: site.id }));
  }

  get selectedSites() {
    const { $topo, siteId } = this.props;
    return siteId ? $topo.selectedSites.filter((id) => siteId !== id) : $topo.selectedSites;
  }

  render() {
    const { $auth, $topo, siteId } = this.props;
    const { legend } = $topo;

    return (
      <FormComponent fields={fields} options={options} values={{ selectedSites: $topo.selectedSites }}>
        <Box px={2} pb={2} borderBottom="thin">
          <Flex mb={2}>
            <Button text="Refresh" disabled={!$topo.mayNeedReload} onClick={this.handleReloadClick} fill />
            {$auth.isAdministrator && (
              <Button intent="primary" text="Save Layout" onClick={this.handleSaveClick} ml={2} fill />
            )}
          </Flex>

          <Text as="div" color="muted" fontWeight="medium" fontSize="small" mb="4px" mt={1}>
            Show
          </Text>
          <ButtonGroup mb={1} fill>
            <Popover
              position={Position.BOTTOM_LEFT}
              content={
                <Menu p="4px">
                  <MenuItem
                    key="none"
                    onClick={() => this.handleShowProviders(false)}
                    active={!$topo.showProviders}
                    text="None"
                  />
                  <MenuItem
                    key="name"
                    onClick={() => this.handleShowProviders('name')}
                    active={$topo.showProviders === 'name'}
                    text="By Name"
                  />
                  <MenuItem
                    key="connectivity"
                    onClick={() => this.handleShowProviders('connectivity')}
                    active={$topo.showProviders === 'connectivity'}
                    text="By Connectivity Type"
                  />
                </Menu>
              }
            >
              <Button
                text="Providers"
                active={$topo.showProviders}
                disabled={!legend}
                rightIcon="caret-down"
                boundary="window"
              />
            </Popover>
            <Button text="Unlinked" active={$topo.showUnlinked} disabled={!legend} onClick={this.handleShowUnlinked} />
            <Button
              text={$topo.level === 'device' ? 'Devices' : 'Sites'}
              active={$topo.level === 'device'}
              disabled={!legend}
              onClick={this.handleShowDevices}
            />
          </ButtonGroup>

          {!siteId && (
            <>
              <Text as="div" color="muted" fontWeight="medium" fontSize="small" mb="4px" mt={1}>
                Filter Sites
              </Text>
              <Field
                name="selectedSites"
                options={this.siteOptions}
                showLabel={false}
                disabled={!legend}
                placeholder="Showing all sites"
                onChange={this.handleSiteSelect}
              >
                <MultiSelect menuWidth={242} showFilter />
              </Field>
            </>
          )}

          <Text as="div" color="muted" fontWeight="medium" fontSize="small" mb="4px" mt={1}>
            Highlight Flows
          </Text>
          <Popover
            position={Position.BOTTOM_LEFT}
            content={
              <Menu p="4px">
                <MenuItem key="none" onClick={() => this.handleLegendClick()} active={!this.highlightKey} text="None" />
                {legend &&
                  legend.map((key) => (
                    <MenuItem
                      key={key}
                      onClick={() => this.handleLegendClick(key)}
                      active={this.highlightKey === key}
                      text={key.replace(/\(.*\)/gi, '')}
                    />
                  ))}
              </Menu>
            }
          >
            <Button
              text={this.highlightKey ? this.highlightKey.replace(/\(.*\)/gi, '') : 'None'}
              disabled={!legend}
              justifyContent="space-between"
              rightIcon="caret-down"
              boundary="window"
              minWidth={144}
            />
          </Popover>
        </Box>
      </FormComponent>
    );
  }
}

export default Legend;
