const NMS_POLICY_CONDITION_CONNECTORS = Object.freeze({
  ANY: 'CONDITION_CONNECTOR_ANY',
  ALL: 'CONDITION_CONNECTOR_ALL',
  UNSPECIFIED: 'CONDITION_CONNECTOR_UNSPECIFIED'
});

const NMS_POLICY_CONDITION_OPERATORS = Object.freeze({
  EQUALS: 'CONDITION_OPERATOR_EQUALS',
  LESS_THAN: 'CONDITION_OPERATOR_LESS_THAN',
  GREATER_THAN: 'CONDITION_OPERATOR_GREATER_THAN',
  LESS_THAN_OR_EQUALS: 'CONDITION_OPERATOR_LESS_THAN_OR_EQUALS',
  GREATER_THAN_OR_EQUALS: 'CONDITION_OPERATOR_GREATER_THAN_OR_EQUALS'
});

const NMS_POLICY_CONDITION_OPERATOR_SYMBOLS = Object.freeze({
  [NMS_POLICY_CONDITION_OPERATORS.EQUALS]: '=',
  [NMS_POLICY_CONDITION_OPERATORS.LESS_THAN]: '<',
  [NMS_POLICY_CONDITION_OPERATORS.GREATER_THAN]: '>',
  [NMS_POLICY_CONDITION_OPERATORS.LESS_THAN_OR_EQUALS]: '≤',
  [NMS_POLICY_CONDITION_OPERATORS.GREATER_THAN_OR_EQUALS]: '≥'
});

const NMS_ENTITY_TYPES = Object.freeze({
  DEVICE: 'device',
  COMPONENT: 'component',
  INTERFACE: 'interface',
  BGP: 'bgpneighbor',
  AGENT: 'agent'
});

/**
 * @typedef {T[keyof T]} Enum<T>
 * @template T
 *
 */
/**
 *
 * @typedef {{
 *   dimensions: string[];
 * }} ApiNmsPolicyCustomTarget
 *
 */
/**
 * entityType and custom should only be one of two
 * @typedef {{
 *   measurements: string[];
 *   entity_type?: string;
 *   custom?: ApiNmsPolicyCustomTarget;
 * }} ApiNmsPolicyTarget
 *
 */
/**
 * @typedef {{
 *   not: boolean;
 *   states: number[];
 * }} ApiNmsPolicyConditionState
 *
 */
/**
 * @typedef {{
 *   operator: Enum<NMS_POLICY_CONDITION_OPERATORS>;
 *   condition_value: number;
 * }} ApiNmsPolicyConditionThreshold
 *
 */
/**
 * @typedef {{
 *   from: ApiNmsPolicyConditionState;
 *   to: ApiNmsPolicyConditionState;
 * }} ApiNmsPolicyConditionStateChange
 *
 */
/**
 * Threshold, stateChange, and stateInCondition should only be one of three
 * @typedef {{
 *   measurement: string;
 *   metric: string;
 *   threshold?: ApiNmsPolicyConditionThreshold;
 *   state_change?: ApiNmsPolicyConditionStateChange;
 *   state_in_condition?: ApiNmsPolicyConditionState;
 * }} ApiNmsPolicyCondition
 *
 */
/**
 * @typedef {{
 *   connector: Enum<NMS_POLICY_CONDITION_CONNECTORS>;
 *   not: boolean;
 *   conditions: ApiNmsPolicyCondition[];
 *   condition_groups: ApiNmsPolicyConditionGroup[]
 * }} ApiNmsPolicyConditionGroup
 *
 */
/**
 * @typedef {{
 *   connector: Enum<NMS_POLICY_CONDITION_CONNECTORS>;
 *   condition_groups: ApiNmsPolicyConditionGroup[];
 * }} ApiNmsPolicyConditionRoot
 *
 */
/**
 * conditional, clearWhenNotActivated, and clearManual should be one of three
 * single and sum should be one of two
 * @typedef {{
 *   severity: import('../alerting/constants.js').ApiAlertSeverity;
 *   activate: ApiNmsPolicyConditionRoot;
 *   conditional?: ApiNmsPolicyConditionRoot;
 *   clear_when_not_activated?: {
 *     clear_when_not_activated: boolean;
 *   };
 *   clear_manual?: {
 *     clear_manual: boolean;
 *   };
 *   single?: {
 *     match_single: boolean;
 *   };
 *   sum?: {
 *     threshold: number;
 *   };
 * }} ApiNmsPolicyLevel
 *
 */
/**
 * @typedef {{
 *   all: boolean;
 *   names: string[];
 *   ids: string[];
 *   labels: number[];
 *   sites: number[];
 * }} ApiNmsPolicyDeviceSelector
 */
/**
 * @typedef {{
 *   id: string;
 *   userId: number;
 *   version: number;
 *   archived: boolean;
 *   name: string;
 *   description?: string;
 *   grouping_dimensions?: string[];
 *   enabled: boolean;
 *   device_selector: ApiNmsPolicyDeviceSelector;
 *   scope: import('../alerting/constants.js').ApiFilterRoot;
 *   target: ApiNmsPolicyTarget;
 *   levels: ApiNmsPolicyLevel[];
 *   rule: import('../alerting/constants.js').ApiAlertManagerRule;
 *   policy_created_at: string;
 *   version_created_at: string;
 * }} ApiNmsPolicy
 */

module.exports = {
  NMS_POLICY_CONDITION_CONNECTORS,
  NMS_POLICY_CONDITION_OPERATORS,
  NMS_POLICY_CONDITION_OPERATOR_SYMBOLS,
  NMS_ENTITY_TYPES
};
