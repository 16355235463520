import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Text from 'core/components/Text';
import DimensionToLink from 'app/components/links/DimensionToLink';

@inject('$insights', '$app')
@observer
class InsightDimensionToLink extends Component {
  render() {
    const { $app, $insights, model, dimension, lookup, value, children } = this.props;
    const { insightName, alerting = {} } = model.get ? model.get() : model;
    const { dimensionToValue } = model;
    const dimensionName = $insights.getNonKdeDimension(dimension, insightName);

    let linkProps = {
      dimension: dimensionName,
      value: dimensionName === 'device_id' || dimensionName === 'i_device_id' ? lookup : value
    };

    if (['src_geo_region', 'dst_geo_region', 'dst_geo_city', 'src_geo_city'].includes(dimension)) {
      linkProps = {
        dimension,
        dimensionToKeyPart: Array.isArray(dimensionToValue)
          ? dimensionToValue.find((dim) => dim === value)
          : dimensionToValue
      };
    }

    if (dimension === 'snmp_id' || alerting.dimensionToKeyPart) {
      linkProps = {
        dimension,
        dimensionToKeyPart: alerting.dimensionToKeyPart || dimensionToValue,
        dimensionToKeyDetail: alerting.dimensionToKeyDetail
      };
    }

    if ($app.isSubtenant) {
      return <Text>{children}</Text>;
    }

    return <DimensionToLink {...linkProps}>{children}</DimensionToLink>;
  }
}

export default InsightDimensionToLink;
