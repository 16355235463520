import { buildNodeData } from './info';
import { buildNodeStyle } from './style';

export default function (traces, options) {
  const { getNodeIDs, width: minWidth } = options;
  const nodes = new Map();
  const links = {};

  let numberOfColumns = 0;
  const setNumberOfColumns = (node) => {
    if (numberOfColumns < node.column + 1 && node.type !== 'target') {
      numberOfColumns = node.column + 1;
    }
  };

  const adjustNodeColumnPlacement = (node, prevNode, checks = []) => {
    // ensure the node is in a column after the previous node
    if (node.column <= prevNode.column && !checks.includes(node.id)) {
      node.column = prevNode.column + 1;
      // stop infinite circles
      // todo: find a better way
      checks.push(node.id);

      setNumberOfColumns(node);

      // ensure nodes linked to are in columns after the current node
      if (links[node.id]) {
        links[node.id].forEach((_, id) => {
          adjustNodeColumnPlacement(nodes.get(id), node, checks);
        });
      }
    }
  };

  /*
   * Create list of nodes, links between nodes, and provide positional context via column.
   * Note: The use of a map allows for iterating later over the nodes in the order they were created,
   * which allows for positioning the node with the agent which first defined it.
   */
  traces.forEach((trace, traceIndex) => {
    const { agentId, probes, target_ip } = trace;
    const agentID = `${agentId}`;

    if (!nodes.get(agentID)) {
      nodes.set(agentID, { id: agentID, type: 'agent', column: 0, group: new Map(), traces: [trace] });
    } else {
      nodes.get(agentID).traces.push(trace);
    }

    if (!nodes.get(agentID).group.get(agentID)) {
      nodes.get(agentID).group.set(agentID, new Map());
    }

    if (!nodes.get(agentID).group.get(agentID).has(target_ip)) {
      nodes.get(agentID).group.get(agentID).set(target_ip, new Map());
    }

    probes.forEach(({ hops, completed }, probeIndex) => {
      if (!nodes.get(agentID).group.get(agentID).get(target_ip).has(probeIndex)) {
        nodes.get(agentID).group.get(agentID).get(target_ip).set(probeIndex, { count: 0 });
      }

      nodes.get(agentID).group.get(agentID).get(target_ip).get(probeIndex).count += 1;

      let prev = nodes.get(agentID);
      let linkedFrom = agentID;

      const hopCount = hops.length;

      const dealWithLoops = (nextHopIndex) => {
        if (hops[nextHopIndex]) {
          const { id: nextNodeId, type: nextType } = getNodeIDs(
            {
              ...hops[nextHopIndex],
              agentID,
              traceIndex,
              probeIndex,
              hopIndex: nextHopIndex,
              target_ip,
              hopCount
            },
            prev,
            completed
          );

          if (nextType === 'timeout') {
            return dealWithLoops(nextHopIndex + 1);
          }

          // ignore timeouts
          if (nextNodeId === prev.id) {
            return true;
          }
        }

        return false;
      };

      for (let hopIndex = 0; hopIndex < hopCount; hopIndex += 1) {
        const hop = {
          ...hops[hopIndex],
          agentID,
          traceIndex,
          probeIndex,
          hopIndex,
          target_ip,
          hopCount
        };
        const { id: nodeId, type } = getNodeIDs(hop, prev, completed);
        const loop =
          type === 'timeout' &&
          (prev.type === 'site' || prev.type === 'region' || prev.type === 'asn') &&
          dealWithLoops(hopIndex + 1);

        if (!loop) {
          // default and/or get node
          let node = nodes.get(nodeId);

          if (!node) {
            nodes.set(nodeId, {
              id: nodeId,
              hops: [],
              column: prev.column + 1,
              type,
              group: new Map()
            });

            node = nodes.get(nodeId);
          }

          setNumberOfColumns(node);

          // add hop to node
          node.hops.push(hop);

          // document agents on node
          if (!node.group.has(agentID)) {
            node.group.set(agentID, new Map());
          }

          // document target_ip on node
          if (!node.group.get(agentID).has(target_ip)) {
            node.group.get(agentID).set(target_ip, new Map());
          }

          // document probes on node
          if (!node.group.get(agentID).get(target_ip).has(probeIndex)) {
            node.group.get(agentID).get(target_ip).set(probeIndex, { count: 0 });
          }

          node.group.get(agentID).get(target_ip).get(probeIndex).count += 1;

          // Store link to node, adjust column placement
          if (prev !== node) {
            adjustNodeColumnPlacement(node, prev);

            const fromHop = prev.hops && prev.hops.length > 0 ? prev.hops[prev.hops.length - 1] : null;

            if (links[prev.id] && links[prev.id].has(nodeId)) {
              // update hops on link
              links[prev.id].get(nodeId).toHops.push(hop);

              if (fromHop) {
                links[prev.id].get(nodeId).fromHops.push(fromHop);
              }
            } else if (links[prev.id]) {
              // add new path to link, with hops
              links[prev.id].set(nodeId, {
                toHops: [hop],
                fromHops: fromHop ? [fromHop] : []
              });
            } else {
              // create new link and path with hops
              links[prev.id] = new Map();
              links[prev.id].set(nodeId, {
                toHops: [hop],
                fromHops: fromHop ? [fromHop] : []
              });
            }

            // note the new link
            linkedFrom = prev.id;
          } else if (links[linkedFrom] && links[linkedFrom].has(nodeId)) {
            // note hops along link path
            links[linkedFrom].get(nodeId).toHops.push(hop);
          }

          // Handle probes that do not reach their target
          if (hop.traceEnd && !hop.target) {
            // default and/or get target
            let target = nodes.get(target_ip);

            if (!target) {
              nodes.set(target_ip, {
                id: target_ip,
                hops: [],
                type: 'target',
                group: new Map()
              });

              target = nodes.get(target_ip);
            }

            const lostTargetHop = {
              id: target_ip,
              loss: true,
              type: 'traget',
              agentID,
              traceIndex,
              probeIndex,
              target_ip
            };

            target.hops.push(lostTargetHop);

            // document agents on target
            if (!target.group.has(agentID)) {
              target.group.set(agentID, new Map());
            }

            // document target_ip on target
            if (!target.group.get(agentID).has(target_ip)) {
              target.group.get(agentID).set(target_ip, new Map());
            }

            // document probes on target
            if (!target.group.get(agentID).get(target_ip).has(probeIndex)) {
              target.group.get(agentID).get(target_ip).set(probeIndex, { count: 0 });
            }

            target.group.get(agentID).get(target_ip).get(probeIndex).count += 1;

            // store link to target
            const isLinked = links[linkedFrom] && links[linkedFrom].has(node.id);
            const fromHops = isLinked ? links[linkedFrom].get(node.id).toHops : [];

            if (!links[node.id]) {
              links[node.id] = new Map();
              links[node.id].set(target_ip, {
                toHops: [lostTargetHop],
                fromHops
              });
            } else if (!links[node.id].has(target_ip)) {
              links[node.id].set(target_ip, {
                toHops: [lostTargetHop],
                fromHops
              });
            } else {
              links[node.id].get(target_ip).toHops.push(lostTargetHop);
              links[node.id].get(target_ip).fromHops = fromHops;
            }
          } else {
            prev = node;
          }
        }
      }
    });
  });

  // Todo: https://github.com/kentik/ui-app/issues/8161
  // if (numberOfColumns > 10) {
  //   const closedPathAdjustment = (opts) => {
  //     const closedNode = nodes.get(opts.key);
  //     const base = {
  //       maxColumnBeforeTarget: numberOfColumns,
  //       maxColumnBeforeTargetDepth: 0,
  //       numberOfHopsBeforeTarget: 0,
  //       maxReached: false,
  //       depth: 0
  //     };
  //     const calcPossibleAdjustment = (key, depth, max) => {
  //       links[key].forEach(
  //         (link, id) => {
  //           if (!base.maxReached) { // && base.maxColumnBeforeTarget === numberOfColumns) {
  //             if (depth < max) {
  //               const node = nodes.get(id);

  //               if (node.column > closedNode.column + depth && base.maxColumnBeforeTarget > node.column) {
  //                 base.maxColumnBeforeTarget = node.column;
  //                 base.maxColumnBeforeTargetDepth = depth;
  //               }

  //               if (node.type !== 'target') {
  //                 if (base.numberOfHopsBeforeTarget < depth) {
  //                   base.numberOfHopsBeforeTarget = depth;
  //                 }

  //                 if (links[id]) {
  //                   calcPossibleAdjustment(id, depth + 1, max);
  //                 }
  //               }
  //             } else {
  //               base.maxReached = true;
  //             }
  //           }
  //         }
  //       )

  //       calcPossibleAdjustment;
  //     }

  //     calcPossibleAdjustment(opts.key, 1, 6);

  //     return base;
  //   }

  //   const adjustPath = (key, i, stop, count, max) => {
  //     links[key].forEach(
  //       (link, id) => {
  //         const node = nodes.get(id);

  //         if (node.type !== 'target' && node.column < stop) {
  //           node.column = i;

  //           adjustPath(id, i + 1, stop);
  //         }

  //         // if (node.type !== 'target' && node.column !== stop) {
  //         //   node.column = i;
  //         // }
  //       }
  //     )
  //   }

  //   Array.from(nodes.keys())
  //     .filter(key => nodes.get(key).type === 'closed_path')
  //     .forEach(key => {
  //       const { maxColumnBeforeTarget, numberOfHopsBeforeTarget, maxReached, maxColumnBeforeTargetDepth } = closedPathAdjustment({ key });
  //       if (!maxReached) {
  //         const delta = maxColumnBeforeTarget - numberOfHopsBeforeTarget;

  //         if (delta > 2) {
  //           if (Math.floor((delta - 2) / 2) ) {
  //             nodes.get(key).column += Math.floor((delta - 2) / 2);
  //           }

  //           console.log('{ maxColumnBeforeTarget, numberOfHopsBeforeTarget, maxReached } ', { maxColumnBeforeTarget, numberOfHopsBeforeTarget, maxReached, maxColumnBeforeTargetDepth } );

  //           adjustPath(key, delta, maxColumnBeforeTarget);
  //         }
  //       }
  //     });
  // }

  // Add virtual nodes for positioning links that span multiple columns
  Object.keys(links).forEach((curr) => {
    const node = nodes.get(curr);

    links[curr].forEach((link, key) => {
      const toNode = nodes.get(key);
      const toColumn = toNode.type === 'target' ? numberOfColumns : toNode.column;
      const columnSpan = toColumn - node.column;
      const virtualId = `${node.id}_${toNode.id}`;

      if (columnSpan > 1 && !(toNode.type === 'target' && node.type === 'closed_path')) {
        for (let i = node.column + 1; i < toColumn; i += 1) {
          nodes.set(`${virtualId}_${i}`, {
            id: `${virtualId}_${i}`,
            column: i,
            type: 'virtual',
            group: node.group
          });
        }
      }
    });
  });

  /*
   * Group nodes by agents, define node and column size, prep for positioning
   */
  const structure = Array.from(nodes.keys()).reduce(
    (acc, curr) => {
      const node = nodes.get(curr);
      const { group, column } = node;
      const agentGroup = group.entries().next().value;
      const agentId = agentGroup[0];
      const targetGroup = agentGroup[1].entries().next().value;
      const target_ip = targetGroup[0];

      if (node.type === 'agent') {
        node.timedOut =
          !node.traces ||
          node.traces.length === 0 ||
          !node.traces.reduce((acc2, curr2) => (acc2 += curr2.hopCount), 0) ||
          node.traces.every((trace) => trace.probes.every((probe) => probe.hops.every((hop) => hop.timeout)));
      }

      node.info = buildNodeData(node);

      buildNodeStyle(node);

      // Ignore targets when grouping by agents
      if (node.type === 'target') {
        acc.targets.push(node);

        return acc;
      }

      // default agent and/or get agent
      let agent = acc.agents.get(agentId);
      if (!agent) {
        acc.agents.set(agentId, {
          targets: new Map()
        });
        agent = acc.agents.get(agentId);
      }

      // default target and/or get target
      let target = agent.targets.get(target_ip);
      if (!target) {
        agent.targets.set(target_ip, {
          height: 0,
          columns: new Map()
        });
        target = agent.targets.get(target_ip);
      }

      // default agent column
      if (!target.columns.has(column)) {
        target.columns.set(column, { nodes: [], height: 0 });
      }

      const targetColumn = target.columns.get(column);

      // add node to agent column and update agent column height
      targetColumn.nodes.push(node);
      targetColumn.height += node.height + acc.gutterHeight;

      // update agent height
      if (targetColumn.height > target.height) {
        target.height = targetColumn.height;
      }

      // default column width in structure
      if (!acc.columns.has(column)) {
        acc.columns.set(column, { width: 0 });
      }

      // update column width in structure
      const columnWidth = acc.columns.get(column).width;

      if (columnWidth < node.width) {
        acc.columns.get(column).width = node.width;
      }

      return acc;
    },
    {
      agents: new Map(),
      columns: new Map(),
      targets: [],
      height: 0,
      width: 0,
      gutterWidth: 15,
      gutterHeight: 15,
      maxTargetWidth: 0
    }
  );

  structure.columnKeys = Array.from(structure.columns.keys()).sort((a, b) => a - b);

  // Define strucure width, gutterWidth, and width of target column
  const calculateStructureWidth = () => {
    // does not include target column
    const totalWidthOfColumns = structure.columnKeys.reduce((acc, curr) => {
      const column = structure.columns.get(curr);

      return acc + column.width;
    }, 0);

    // target column width
    structure.maxTargetWidth = structure.targets.reduce((acc, target) => (target.width > acc ? target.width : acc), 0);

    // calculate gutter width
    const availableLinkWidth = minWidth - (totalWidthOfColumns + structure.maxTargetWidth);
    const numberOfGutters = numberOfColumns;
    const minGutterWidth = numberOfGutters * structure.gutterWidth;

    if (availableLinkWidth > minGutterWidth) {
      structure.gutterWidth = availableLinkWidth / numberOfGutters;
    }

    // calculate structure width
    const totalGutterWidth = numberOfGutters * structure.gutterWidth;

    structure.width = totalWidthOfColumns + totalGutterWidth + structure.maxTargetWidth;
  };

  calculateStructureWidth();

  return {
    ...structure,
    nodes,
    links,
    numberOfColumns
  };
}
