import { getMetadata } from 'app/util/bracketing';

import BaseTagGenerator from './BaseTagGenerator';

class Percentages extends BaseTagGenerator {
  constructor() {
    super({ type: 'percentages', display: 'Percentage Ranges' });
  }

  tagResults({ queryResults, tagGenData: bracketOptions, options = {} }) {
    const { tagKey } = bracketOptions;
    const metadata = getMetadata({ queryResults, bracketOptions });
    queryResults.nonOverlayRows.forEach((row) => {
      this.tagRow({ row, tagKey, tagContent: this.generateTagContent({ row, metadata, bracketOptions, options }) });
    });
  }
}

export { Percentages };
