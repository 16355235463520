// NOTE: This will be deprecated in favor of src/shared/util/safelyParseJson.js
export default function safelyParseJson(json) {
  let parsed;
  // value is already JSON
  if (typeof json === 'object') {
    return json;
  }
  try {
    parsed = JSON.parse(json);
  } catch (e) {
    // Oh well, but whatever...
    parsed = undefined;
  }

  return parsed;
}
