import React from 'react';

import { Box, Button, Card, Flex, Icon } from 'core/components';

import { FiUsers } from 'react-icons/fi';
import { inject, observer } from 'mobx-react';
import FilterItem from './FilterItem';
import { FilterGroupBase } from './FilterGroup';
import FilterConnectorDisplay from './FilterConnectorDisplay';
import SavedFilterDisplay from './SavedFilterDisplay';

@inject('$app', '$savedFilters')
@observer
class FilterGroupDisplay extends FilterGroupBase {
  static defaultProps = {
    showRemoveGroup: true
  };

  handleRemoveGroup = (e) => {
    const { groups, index, onClose, dontCloseOnRemove } = this.props;

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    groups.remove(index);

    if (onClose && !dontCloseOnRemove) {
      onClose(false);
    }
  };

  render() {
    const { group, readOnly, showRemoveGroup, mt } = this.props;
    const isTenantPreviewGroup = group.name.getValue().startsWith('mkp_preview');

    return (
      <Card p={1} mt={mt}>
        {isTenantPreviewGroup && (
          <Flex alignItems="center" mb="4px">
            <Icon icon={FiUsers} iconSize={16} />
            <Box ml="4px">{group.name.getValue().split(':')[1]}</Box>
          </Flex>
        )}
        <Flex alignItems="center" justifyContent="space-between">
          <FilterConnectorDisplay {...this.props} />
          {showRemoveGroup && (
            <Button
              icon="cross"
              disabled={readOnly}
              onClick={this.handleRemoveGroup}
              intent="danger"
              ml={1}
              small
              minimal
              minHeight={20}
              minWidth={20}
            />
          )}
        </Flex>

        {group.filters.map((filter, idx) => (
          <FilterItem
            {...this.props}
            key={filter.filterValue._id}
            filter={filter}
            filterField={filter.filterField.getValue()}
            operator={filter.operator.getValue()}
            filterValue={filter.filterValue.getValue()}
            rightFilterField={filter.rightFilterField.getValue()}
            index={idx}
            onRemove={this.handleRemoveFilter}
          />
        ))}

        {group.saved_filters && group.saved_filters.fieldStates.length > 0 && (
          <Box p={0}>
            {group.saved_filters.map((filter) => (
              <SavedFilterDisplay
                key={filter.filter_id._id}
                filter_id={filter.filter_id.getValue()}
                is_not={filter.is_not.getValue()}
              />
            ))}
          </Box>
        )}

        {group.filterGroups && group.filterGroups.fieldStates.length > 0 && (
          <Box p={0}>
            {group.filterGroups.map((nestedGroup, index) => (
              <FilterGroupDisplay
                {...this.props}
                key={nestedGroup.connector._id}
                group={nestedGroup}
                index={index}
                groups={group.filterGroups}
                mt={1}
              />
            ))}
          </Box>
        )}
      </Card>
    );
  }
}

export default FilterGroupDisplay;
