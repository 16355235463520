import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Box, Flex, Heading } from 'core/components';
import AggregationSelector from 'app/components/AggregationSelector';
import MetricSelector from 'app/components/MetricSelector';
import TimeRangeSelector from 'app/components/TimeRangeSelector';
import SimpleFilters from 'app/components/filters/simple/SimpleFilters';
import ViewHeader from 'app/components/ViewHeader';
import FavoriteButton from 'app/views/core/FavoriteButton';

@inject('$app', '$exports')
@withRouter
@observer
class AggregateViewHeader extends Component {
  static defaultProps = {
    filters: [],
    navLink: '',
    navLinkLabel: 'Core'
  };

  constructor(props) {
    super(props);

    const { aggregateType, filters, lookbackSeconds } = props;

    this.state = {
      aggregateType,
      filters,
      lookbackSeconds
    };
  }

  componentDidMount() {
    const { $exports } = this.props;

    $exports.getSettings().then(({ hashedAggregateType, hashedFilters, hashedLookbackSeconds }) => {
      if (hashedAggregateType) {
        this.handleAggregateChange(hashedAggregateType);
      }
      if (hashedFilters) {
        this.handleFiltersChange(hashedFilters);
      }
      if (hashedLookbackSeconds) {
        this.handleTimeRangeChange(hashedLookbackSeconds);
      }
    });
  }

  handleTimeRangeChange = (value) => {
    const { onTimeRangeChange } = this.props;

    this.setState({ lookbackSeconds: value });
    onTimeRangeChange(value);
  };

  handleFiltersChange = (value) => {
    const { onFiltersChange } = this.props;

    this.setState({ filters: value });
    onFiltersChange(value);
  };

  handleMetricsChange = (value) => {
    const { onMetricsChange } = this.props;

    this.setState({ aggregateType: value });
    onMetricsChange(value);
  };

  handleAggregateChange = (value) => {
    const { onAggregateChange } = this.props;

    this.setState({ aggregateType: value });
    onAggregateChange(value);
  };

  render() {
    const {
      $app,
      $auth,
      onFiltersChange,
      onAggregateChange,
      onMetricsChange,
      onTimeRangeChange,
      fieldBlacklist,
      fieldWhitelist,
      hashEnabled = true,
      showTitleLeft = true,
      showButtons = true,
      children,
      title,
      hideFavorite = false,
      match,
      ...rest
    } = this.props;

    const { aggregateType, filters, lookbackSeconds } = this.state;

    const showSimple = showButtons === true || showButtons.simple === true;
    const showAgg = showButtons === true || showButtons.agg === true;
    const showMetric = showButtons === true || showButtons.metric === true;
    const showTime = showButtons === true || showButtons.time === true;
    const spacing = $app.isExport ? '0' : '8px';

    return (
      <>
        {showTitleLeft && title && (
          <Flex alignItems="center" mb={1}>
            <Flex flex="1 1 auto" gap="4px" mr={2}>
              {!hideFavorite && <FavoriteButton type="page" id={match.url} name={title.toString()} />}
              <Heading level={1} mb={0}>
                {title}
              </Heading>
            </Flex>
          </Flex>
        )}
        <ViewHeader {...rest} {...(!showTitleLeft ? { title } : {})}>
          {showSimple && (
            <Box mr={spacing}>
              <SimpleFilters
                values={{ filters }}
                onFiltersChange={this.handleFiltersChange}
                hashEnabled={hashEnabled}
                fieldBlacklist={fieldBlacklist}
                fieldWhitelist={fieldWhitelist}
              />
            </Box>
          )}
          {showAgg && (
            <Box borderLeft={$app.isExport && showSimple ? 'thin' : undefined} mr={spacing}>
              <AggregationSelector
                aggregateType={aggregateType}
                onChange={this.handleAggregateChange}
                hashEnabled={hashEnabled}
              />
            </Box>
          )}
          {showMetric && (
            <Box borderLeft={$app.isExport && (showSimple || showAgg) ? 'thin' : undefined} mr={spacing}>
              <MetricSelector
                aggregateType={aggregateType}
                onChange={this.handleMetricsChange}
                hashEnabled={hashEnabled}
              />
            </Box>
          )}
          {showTime && (
            <Box
              borderLeft={$app.isExport && (showSimple || showAgg || showMetric) ? 'thin' : undefined}
              borderRight={$app.isExport && children ? 'thin' : undefined}
              mr={children && spacing}
            >
              <TimeRangeSelector
                lookback_seconds={lookbackSeconds}
                onChange={this.handleTimeRangeChange}
                hashEnabled={hashEnabled}
              />
            </Box>
          )}
          {children}
        </ViewHeader>
      </>
    );
  }
}

export default AggregateViewHeader;
