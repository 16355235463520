import styled from 'styled-components';
import { space, width } from 'styled-system';
import { NavbarGroup as BlueprintNavbarGroup } from '@blueprintjs/core';

const NavbarGroup = styled(BlueprintNavbarGroup)`
  ${space};
  ${width};
`;

export default NavbarGroup;
