import Model from 'core/model/Model';
import { showSuccessToast } from 'core/components';

class PromptGroupModel extends Model {
  get defaults() {
    return {
      prompt: '',
      models: [],
      journey: {}
    };
  }

  destroyAll(options = {}) {
    return Promise.all(
      this.get('models').map((model) =>
        model.destroy({
          ...options,
          toast: false
        })
      )
    ).then(() => this.destroy(options));
  }

  async restoreAll(options = {}) {
    // note: toast true by default
    const { toast = true, journey } = options;

    const models = this.get('models');
    if (journey && !journey.isDeleted) {
      return Promise.resolve(options)
        .then(() => Promise.all(models.map((m) => m.restore(options))))
        .then(
          (success) => {
            if (toast) {
              showSuccessToast(this.messages.restore);
            }

            return success;
          },
          (error) => {
            this.error = { label: 'updating' };
            this.requestStatus = null;
            throw error;
          }
        );
    }

    return false;
  }

  // same as prompt to query models since they represent the same prompt under a prompt group model
  get messages() {
    return {
      create: 'Prompt was added successfully',
      update: 'Thank you for your feedback!',
      destroy: 'Prompt was removed successfully',
      restore: 'Prompt was restored successfully'
    };
  }
}

export default PromptGroupModel;
