import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import storeLoader from 'app/stores/storeLoader';

import { Box, Button, Icon, Menu, MenuDivider, MenuItem, Popover } from 'core/components';
import { RemoveMenuItem, formConsumer } from 'core/form';
import { getPolicyManageUrl } from 'app/stores/alerting/policyUtils';

import { FiMoreVertical } from 'react-icons/fi';
import { ReactComponent as EnvelopeRemove } from 'app/assets/icons/envelope-remove.svg';
import DashboardDetailsDialog from 'app/views/core/dashboards/DashboardDetailsDialog';
import DashboardItemModel from 'app/stores/dashboard/DashboardItemModel';
import DashboardPanelFormDialog from 'app/views/core/dashboards/dashboardItem/DashboardPanelFormDialog';

import ViewSqlMenuItem from './options/ViewSqlMenuItem';
import ShowApiCallDialog from './options/ShowApiCallDialog';
import AlertPolicyDialog from './options/AlertPolicyDialog';
import ExportMenuItem from './options/ExportMenuItem';

@storeLoader('$subscriptions')
@inject('$app', '$alerting', '$auth', '$dashboards', '$explorer', '$decks', '$mkp', '$rawFlow')
@withRouter
@formConsumer
@observer
export default class OptionsMenu extends Component {
  state = {
    isOpen: false,
    showApiJsonDialogOpen: false,
    showChartApiCurlDialogOpen: false,
    showDataApiCurlDialogOpen: false,
    addToDashboardDialogOpen: false,
    showAlertPolicyDialogOpen: false,
    loadingTenants: false
  };

  handleInteraction = (isOpen) => {
    this.setState({ isOpen });
  };

  componentDidMount() {
    const { $app, $mkp } = this.props;
    if (!$app.isSubtenant) {
      this.setState({ loadingTenants: true });
      $mkp.tenants.fetch().then(() => {
        this.setState({ loadingTenants: false });
      });
    }
  }

  toggleDialog(e, name) {
    e.preventDefault();
    e.stopPropagation();
    this.setState((state) => ({
      [name]: !state[name]
    }));
  }

  toggleShowChartApiCurl = (e) => {
    this.toggleDialog(e, 'showChartApiCurlDialogOpen');
  };

  toggleShowDataApiCurl = (e) => {
    this.toggleDialog(e, 'showDataApiCurlDialogOpen');
  };

  toggleShowApiJson = (e) => {
    this.toggleDialog(e, 'showApiJsonDialogOpen');
  };

  createDashboardItem = () => {
    const { dataview } = this.props;

    if (dataview.hasUpdateFrequency) {
      dataview.setUpdateFrequency();
    }

    // create a new DashboardItemModel model that we'll manipulate in the Dialog. We default the
    // `title` to whatever the current DataView title is.
    const dashboardItem = new DashboardItemModel();

    dashboardItem.set({
      panel_title: dataview.title || 'My View Panel',
      saved_query_id: dataview.hash,
      queries: [dataview.queryBuckets.selectedQuery]
    });
    return dashboardItem;
  };

  handleAddToDashboard = () => {
    const { $dashboards } = this.props;
    const dashboardItem = this.createDashboardItem();

    const newDash = $dashboards.collection.forge({ share_level: 'self' });
    $dashboards.collection.select(newDash);

    this.setState({ addToNewDashboardDialogOpen: true, dashboardItem });
  };

  handleAddToObservationDeck = () => {
    const { $decks, dataview, history, savedViewId } = this.props;

    $decks.getDefaultDeck({ type: 'home' }).then((decks) => {
      const deck = decks.find((d) => d.get('share_level') === 'self');
      if (savedViewId) {
        deck.addWidget('savedViewBrowser', { savedViewId });
      } else {
        deck.addWidget('dataExplorerQuery', { hash: dataview.hash });
      }
      history.push('/v4/home');
    });
  };

  handleAddToDashboardItem = () => {
    const dashboardItem = this.createDashboardItem();

    this.setState({ addToDashboardDialogOpen: true, dashboardItem });
  };

  handleCreateSavedView = () => {
    const { dataview, history } = this.props;
    history.push(`/v4/library/saved-views/hash/${dataview.hash}`);
  };

  handleCreateAlertPolicy = (forceCreate = false) => {
    const { $alerting, dataview, history, form } = this.props;
    const { queryBuckets } = dataview;
    const serializedQueries = queryBuckets.serialize();
    const metrics = Array.from(new Set(serializedQueries.flatMap(({ query }) => query.units)));
    const {
      all_devices,
      device_labels,
      device_name,
      device_sites,
      device_types,
      filters,
      metric: dimensions // Corresponds to policy dimensions
    } = form.getValues();

    const {
      unsupportedMetrics = [],
      unsupportedDimensions = [],
      policyAttrs
    } = $alerting.convertExplorerQueryToPolicyAttrs({
      selected_devices: { all_devices, device_labels, device_name, device_sites, device_types },
      filters,
      dimensions,
      metrics
    });

    if (forceCreate) {
      const policyPath = getPolicyManageUrl(policyAttrs.application, false);
      return history.push(policyPath, { explorerPolicy: policyAttrs });
    }

    // If missing/unsupported selections and user hasn't confirmed

    return this.setState({
      showAlertPolicyDialogOpen: true,
      alertDialogProps: {
        unsupportedMetrics,
        unsupportedDimensions,
        metrics: policyAttrs.metrics,
        dimensions: policyAttrs.dimensions
      }
    });
  };

  handleRawFlow = () => {
    const { dataview, $rawFlow } = this.props;
    $rawFlow.openQuery(dataview.queryBuckets.selectedQuery);
  };

  handleReset = () => {
    const { $explorer } = this.props;
    $explorer.reset();
  };

  handlePreviewTenant = (tenant) => {
    const { $explorer } = this.props;
    $explorer.applyTenantFilter(tenant);
  };

  onAddNewDashboardSubmit = (dashboard) => {
    const { $dashboards } = this.props;
    $dashboards.navigateToDashboard(dashboard);
  };

  onClose = () => {
    this.setState({
      showApiJsonDialogOpen: false,
      showChartApiCurlDialogOpen: false,
      showDataApiCurlDialogOpen: false,
      addToDashboardDialogOpen: false,
      addToNewDashboardDialogOpen: false,
      showAlertPolicyDialogOpen: false
    });
  };

  renderOptionsMenu() {
    const {
      $auth,
      $mkp,
      dataview,
      removeProps,
      savedViewId,
      hideResetToDefaultQuery,
      onSubscribeClick,
      onUnsubscribeClick
    } = this.props;

    const showCreateAlertPolicy = $auth.hasPermission('alerts.policies.create');

    return (
      <Menu>
        <ExportMenuItem dataview={dataview} />
        {onSubscribeClick && <MenuItem icon="envelope" text="Subscribe" onClick={onSubscribeClick} />}
        {onSubscribeClick && (
          <MenuItem
            icon={EnvelopeRemove}
            text="Unsubscribe"
            onClick={onUnsubscribeClick}
            disabled={!onUnsubscribeClick}
          />
        )}

        <MenuDivider />
        <MenuItem icon="new-grid-item" text="Add to Observation Deck" onClick={this.handleAddToObservationDeck} />
        {!savedViewId && (
          <>
            <MenuDivider />
            <MenuItem icon="floppy-disk" text="Create Saved View" onClick={this.handleCreateSavedView} />
            {showCreateAlertPolicy && (
              <MenuItem icon="shield" text="Create Alert Policy" onClick={() => this.handleCreateAlertPolicy(false)} />
            )}
          </>
        )}
        <MenuDivider />
        <MenuItem icon="plus" text="Add To New Dashboard" onClick={this.handleAddToDashboard} />
        <MenuItem icon="new-grid-item" text="Add To Existing Dashboard" onClick={this.handleAddToDashboardItem} />
        {$mkp.tenants.size > 0 && (
          <>
            <MenuDivider />
            <MenuItem icon="user" text="Preview as Tenant" popoverProps={{ openOnTargetFocus: false }}>
              <Box maxHeight={300} overflow="auto">
                {$mkp.tenants.map((tenant) => (
                  <MenuItem
                    key={tenant.id}
                    className="preview-as-tenant"
                    text={tenant.get('name')}
                    onClick={() => this.handlePreviewTenant(tenant)}
                  />
                ))}
              </Box>
            </MenuItem>
          </>
        )}
        <MenuDivider />
        <MenuItem icon="code" text="Show API Call" popoverProps={{ openOnTargetFocus: false }}>
          <MenuItem text="For Chart" tagName="button" onClick={this.toggleShowChartApiCurl} />
          <MenuItem text="For Data" tagName="button" onClick={this.toggleShowDataApiCurl} />
          <MenuItem text="JSON input" tagName="button" onClick={this.toggleShowApiJson} />
        </MenuItem>
        <MenuItem icon="search-template" text="Explore Raw Flows" onClick={this.handleRawFlow} />

        {($auth.hasSudo || $auth.isSpoofed) && <ViewSqlMenuItem dataview={dataview} />}
        {!hideResetToDefaultQuery && (
          <>
            <MenuDivider />
            <MenuItem icon="reset" text="Reset to Default Query" onClick={this.handleReset} />
          </>
        )}
        {removeProps && removeProps.show && (
          <>
            <MenuDivider />
            <RemoveMenuItem
              text="Remove"
              entityName={removeProps.entityName}
              model={removeProps.model}
              onRemove={removeProps.onRemove}
              confirmText={removeProps.confirmText}
            />
          </>
        )}
      </Menu>
    );
  }

  render() {
    const { $dashboards, dataview, buttonProps, showText } = this.props;
    const {
      isOpen,
      showAlertPolicyDialogOpen,
      addToDashboardDialogOpen,
      addToNewDashboardDialogOpen,
      dashboardItem,
      loadingTenants,
      alertDialogProps
    } = this.state;

    if (!dataview) {
      return null;
    }

    return (
      <>
        <Popover
          isOpen={isOpen}
          content={this.renderOptionsMenu()}
          onInteraction={this.handleInteraction}
          position="bottom-right"
        >
          <Button
            disabled={loadingTenants}
            icon={showText ? undefined : FiMoreVertical}
            text={showText ? 'Actions' : undefined}
            rightIcon={<Icon icon="caret-down" iconSize={16} mx="-2px" />}
            active={isOpen}
            {...buttonProps}
          />
        </Popover>
        <ShowApiCallDialog {...this.props} {...this.state} onClose={this.onClose} />
        {addToDashboardDialogOpen && (
          <DashboardPanelFormDialog
            isOpen={addToDashboardDialogOpen}
            onCancel={this.onClose}
            model={dashboardItem}
            dataview={dataview}
          />
        )}
        {addToNewDashboardDialogOpen && (
          <DashboardDetailsDialog
            isOpen={addToNewDashboardDialogOpen}
            onSubmit={this.onAddNewDashboardSubmit}
            onClose={this.onClose}
            model={$dashboards.collection.selected}
            collection={$dashboards.collection}
            title="New Dashboard"
            dashboardItem={dashboardItem}
          />
        )}
        {showAlertPolicyDialogOpen && (
          <AlertPolicyDialog
            isOpen={!!showAlertPolicyDialogOpen}
            onSubmit={() => this.handleCreateAlertPolicy(true)}
            onClose={this.onClose}
            {...alertDialogProps}
          />
        )}
      </>
    );
  }
}
