import React from 'react';
import { observer } from 'mobx-react';
import { Button, Flex, FlexColumn, Heading } from 'core/components';
import { AGGREGATE_VIEWS } from 'app/views/core';
import { groupBy } from 'lodash';
import { MenuLinks, MenuSpacer } from './NavbarMenu';

const NavbarTopTalkers = observer(({ MenuSection, MenuLink, onCloseSection }) => {
  const groupedQuickViews = groupBy(AGGREGATE_VIEWS, 'group');

  const renderCategoryLinks = (items) => items.map(({ path, name }) => <MenuLink key={path} text={name} to={path} />);

  return (
    <FlexColumn className="nav-top-talkers" flex={1} onMouseLeave={onCloseSection}>
      <Flex alignItems="center" justifyContent="space-between" mt="12px" mb={2}>
        <Heading level={4} color="kentik" m={0}>
          Top Talkers
        </Heading>
        <Button icon="cross" minimal onClick={onCloseSection} />
      </Flex>
      <Flex>
        <FlexColumn flex={1} minWidth="min-content" flexBasis={180}>
          <MenuSection title="Network & Traffic">
            <MenuLinks>{renderCategoryLinks(groupedQuickViews['Network & Traffic'])}</MenuLinks>
          </MenuSection>
          <MenuSection title="Cloud">
            <MenuLinks>{renderCategoryLinks(groupedQuickViews.Cloud)}</MenuLinks>
          </MenuSection>
        </FlexColumn>

        <MenuSpacer />

        <FlexColumn flex={1} minWidth="min-content" flexBasis={180}>
          <MenuSection title="Geographic">
            <MenuLinks>{renderCategoryLinks(groupedQuickViews.Geographic)}</MenuLinks>
          </MenuSection>
          <MenuSection title="IP & BGP Routing">
            <MenuLinks>{renderCategoryLinks(groupedQuickViews['IP & BGP Routing'])}</MenuLinks>
          </MenuSection>
        </FlexColumn>

        <MenuSpacer />

        <FlexColumn flex={1} minWidth="min-content" flexBasis={180}>
          <MenuSection title="Application Context">
            <MenuLinks>{renderCategoryLinks(groupedQuickViews['Application Context'])}</MenuLinks>
          </MenuSection>
          <MenuSection title="Host Monitoring">
            <MenuLinks>
              <MenuLink text="TCP Traffic" to="/v4/core/quick-views/tcp-traffic" />
              <MenuLink text="DNS Traffic" to="/v4/core/quick-views/dns-traffic/dns-analytics" />
            </MenuLinks>
          </MenuSection>
        </FlexColumn>
      </Flex>
    </FlexColumn>
  );
});

export default NavbarTopTalkers;
