import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Intent } from '@blueprintjs/core';

import { Button, Dialog, Box } from 'core/components';
import { formConsumer } from 'core/form';

import { groupHasFilters } from './FilterGroup';
import FilterOptions from './FilterOptions';

@formConsumer
@observer
export default class FilterOptionsDialog extends Component {
  static defaultProps = {
    fieldName: 'filters',
    filterGroupsField: undefined,
    connectorField: undefined,
    showSaveFiltersButton: true,
    showSavedFilters: true,
    allowRightFilterField: true
  };

  componentDidMount() {
    this.lastValue = JSON.stringify(this.getField().getValue());
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    if (!prevProps.isOpen && isOpen) {
      this.lastValue = JSON.stringify(this.getField().getValue());
    }
  }

  handleCancel = (e) => {
    const { onClose } = this.props;

    // in this handler, we always want to close the dialog and prevent any other hotkeys/actions from firing.
    // method 1 to get here: ESC key (27). In this case, we want to revert changes
    // method 2 to get here: Click cancel button. In this case, we want to revert changes
    // method 3 to get here: click outside dialog. In this case, we want to preserve changes

    // standard event stoppage
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // handle revert cases
    if (e && (e.keyCode === 27 || e.currentTarget.className.includes('bp4-button'))) {
      onClose();

      if (this.lastValue) {
        this.getField().setValue(JSON.parse(this.lastValue));
      }
    } else {
      onClose();
    }
  };

  removeEmptyGroups(groups) {
    groups = groups || this.getField();

    groups.forEach((group, index) => {
      // check if the group contains empty groups and if so, remove those first
      if (group.filterGroups?.fieldStates.length > 0) {
        this.removeEmptyGroups(group.filterGroups);
      }

      // if there are no filters in a group, the group removes itself.
      if (!groupHasFilters(group)) {
        groups.remove(index);
      }
    });
  }

  handleSave = () => {
    const { onClose } = this.props;

    this.removeEmptyGroups();
    onClose();
  };

  getField() {
    const { form, filterGroupsField, fieldName } = this.props;

    return filterGroupsField || form.getField(`${fieldName}.filterGroups`);
  }

  render() {
    const { isOpen, title = 'Filters' } = this.props;

    const field = this.getField();

    const hasErrors = field.errors && field.errors.length > 0;

    return (
      <Dialog
        {...this.props}
        isOpen={isOpen}
        onClose={this.handleCancel}
        width={836}
        position="absolute"
        top={50}
        maxHeight="calc(100vh - 150px)"
        title={title}
        tracker="filters-dialog"
      >
        <Dialog.Body>
          <Box flex={1}>
            <FilterOptions {...this.props} />
          </Box>
        </Dialog.Body>
        <Dialog.Footer>
          <Button text="Cancel" onClick={this.handleCancel} mr={1} width={110} />
          <Button
            intent={Intent.PRIMARY}
            text="Save"
            onClick={this.handleSave}
            disabled={hasErrors}
            width={110}
            ml={1}
          />
        </Dialog.Footer>
      </Dialog>
    );
  }
}
