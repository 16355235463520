import React, { useState } from 'react';
import { Flex, Text, Button, Icon } from 'core/components';
import { formatDate } from 'core/util/dateUtils';
import { observer } from 'mobx-react';

const PingCard = ({ id, ping, agent }) => {
  const [showAll, setShowAll] = useState(false);

  if (!ping || !agent) {
    return null;
  }

  const responses = ping.responses || [];
  const displayedResponses = showAll ? responses : responses.slice(-5);

  return (
    <Flex className="bp4-card" key={id} flexDirection="column" gap="12px">
      <Flex flexDirection="column" gap="4px">
        <Flex alignItems="center" gap="2px">
          <Text fontSize={14} fontWeight="bold">
            {agent?.display_name || agent?.host_name}
          </Text>
          <Icon icon="chevron-right" color="muted" />
          <Text>{ping?.dst}</Text>
        </Flex>
        <Text muted small>
          {agent.host_ip}
        </Text>
      </Flex>

      {responses.length > 5 && (
        <Button
          small
          minimal
          onClick={() => setShowAll(!showAll)}
          style={{ alignSelf: 'flex-start' }}
          icon={showAll ? 'chevron-down' : 'chevron-up'}
        >
          {showAll ? 'Show less' : 'Show all'}
        </Button>
      )}

      <Flex flexDirection="column" gap="4px">
        {displayedResponses.map((r) => {
          let timestamp = r.tx;
          if (r.tx?.seconds) {
            timestamp = r.tx.seconds * 1000;
          }
          const replySize = r.replySize || r.reply_size;
          const { rtt } = r;
          let initialText = `${replySize} bytes `;
          if (rtt === undefined) {
            initialText = 'timeout  ';
          } else if (rtt === 0) {
            initialText = 'pending  ';
          }

          return (
            <Flex key={r.seq} gap={3} alignItems="center">
              <Text monospace muted small>
                {formatDate(new Date(timestamp).getTime(), 'HH:mm:ss')}
              </Text>
              <Text monospace fontSize={11}>
                <Text whiteSpace="pre">{initialText}</Text>
                from {r.from} icmp_seq={r.seq} ttl=
                {r.reply_ttl || r.replyTtl}{' '}
                {rtt && (
                  <Text>
                    <Text fontWeight="medium">time={r.rtt}</Text>ms
                  </Text>
                )}
              </Text>
            </Flex>
          );
        })}
      </Flex>

      <Flex flexDirection="column" gap={1}>
        <Text muted monospace fontSize={11}>
          --- {ping.dst} ping statistics ---
        </Text>
        <Text muted monospace fontSize={11}>
          {ping.ping_sent || ping.pingSent} packets transmitted
        </Text>
      </Flex>

      <Flex flexDirection="row" gap={2}>
        {Object.keys(ping.statistics).map((key) => (
          <Flex key={key} alignItems="center">
            <Text monospace fontSize={11}>
              {key}:{' '}
            </Text>
            <Text monospace fontSize={11} fontWeight="bold">
              {ping.statistics[key]}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default observer(PingCard);
