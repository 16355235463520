import React, { Component } from 'react';
import { Box, Flex, Icon, Text } from 'core/components';
import { observer } from 'mobx-react';
import LabelList from 'app/components/labels/LabelList';

export class BaseAgentRenderer extends Component {
  static defaultProps = {
    iconProps: {},
    locationInfoProps: {},
    showLocationInfo: false
  };

  componentDidUpdate(prevProps) {
    const { labels, showLabels, updateGrid } = this.props;
    if (showLabels && labels.length !== prevProps.labels.length && updateGrid && typeof updateGrid === 'function') {
      updateGrid();
    }
  }

  // this component is rendered by spreading the result of getOptionsForAgents as props
  // that util will return (among other things) a 'value' property that is the agent id
  // a truthy value for that will tell us that we are working with a valid agent
  get isValidAgent() {
    const { value } = this.props;
    return !!value;
  }

  get icon() {
    const { icon, iconProps } = this.props;

    if (this.isValidAgent) {
      return <Icon icon={icon} my="2px" mr="4px" {...iconProps} />;
    }

    return <Icon icon="warning-sign" color="warning" iconSize={12} mr="4px" />;
  }

  get label() {
    const { small, label, wrapAgentLabel } = this.props;

    if (this.isValidAgent) {
      return (
        <Text small={small} ellipsis={!wrapAgentLabel}>
          {label}
        </Text>
      );
    }

    return <Text small={small}>Unknown or removed agent</Text>;
  }

  get statusIcon() {
    const { status, statusIconProps } = this.props;

    if (this.isValidAgent && status?.icon) {
      return (
        <Icon
          ml="4px"
          icon={status.icon}
          color={status.color}
          title={status.text}
          iconSize={12}
          cursor="pointer"
          {...statusIconProps}
        />
      );
    }

    return null;
  }

  get locationInfo() {
    const { locationInfo, showLocationInfo, locationInfoProps } = this.props;

    if (this.isValidAgent && showLocationInfo) {
      return (
        <Text as="div" muted fontWeight="normal" small {...locationInfoProps}>
          {locationInfo}
        </Text>
      );
    }

    return null;
  }

  get labelList() {
    const { labels, showLabels, wrapLabels, labelFilters } = this.props;

    if (this.isValidAgent && showLabels) {
      return <LabelList labels={labels} ml="20px" wrapLabels={wrapLabels} priorityLabels={labelFilters} />;
    }

    return null;
  }

  render() {
    const { children, wrapAgentLabel } = this.props;

    if (typeof children === 'function') {
      return children({
        icon: this.icon,
        statusIcon: this.statusIcon,
        label: this.label,
        locationInfo: this.locationInfo,
        labelList: this.labelList
      });
    }

    return (
      <>
        <Flex alignItems={wrapAgentLabel ? 'flex-start' : 'center'} overflow="hidden">
          {this.icon}
          <Box overflow="hidden">
            <Flex alignItems="center" overflow="hidden">
              {this.label}
              {this.statusIcon}
            </Flex>
            {this.locationInfo}
          </Box>
          {children}
        </Flex>
        {this.labelList}
      </>
    );
  }
}

const AgentRenderer = observer(({ agent, locationInfo: locationInfoProp, ...rest }) => {
  // "label" is field, "labels" are tags
  const { id, status, locationInfo, label, labels, icon, collection } = agent;
  const labelFilters = collection?.discreteFilterValues.labelMultiSelect ?? [];
  return (
    <BaseAgentRenderer
      {...rest}
      value={id}
      status={status}
      locationInfo={locationInfoProp || locationInfo}
      label={label}
      labels={labels}
      icon={icon}
      labelFilters={labelFilters}
    />
  );
});

export default AgentRenderer;
