import React, { Component } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import CoreSelect from 'core/components/select/Select';

@observer
class Select extends Component {
  render() {
    const { field, options, multi, ...rest } = this.props;
    const values = multi ? field.getValue() || [] : field.getValue() || '';

    return (
      <CoreSelect
        {...rest}
        multi={multi}
        label={field.label}
        options={toJS(field.options || options || [])}
        values={values}
      />
    );
  }
}

export default Select;
