import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import { BsXOctagonFill, BsExclamationTriangleFill } from 'react-icons/bs';
import { FiShare2 } from 'react-icons/fi';
import { ReactComponent as EnvelopeRemove } from 'app/assets/icons/envelope-remove.svg';
import { Position } from '@blueprintjs/core';
import CapacityPlanModel from 'app/stores/capacityPlan/CapacityPlanModel';

import {
  Box,
  Flex,
  FlexColumn,
  Text,
  Spinner,
  LinkButton,
  ConfirmPopover,
  Button,
  Paragraph,
  Icon,
  Callout,
  Menu,
  MenuItem,
  Popover
} from 'core/components';
import Page, { NAV_HEIGHT } from 'app/components/page/Page';
import PageHeading from 'app/components/page/PageHeading';
import ExportCoverPage from 'app/views/core/ExportCoverPage';
import ShareViewDialog from 'app/views/core/ShareViewDialog';
import SubscribeDialog from 'app/views/settings/subscriptions/SubscribeDialog';
import CapacityPlan from './CapacityPlan';
import PlanInterfaceSeverity from './PlanInterfaceSeverity';

const parentLinks = [{ link: '/v4/core/capacity', label: 'Capacity Planning' }];

@inject('$app', '$auth', '$capacity', '$exports', '$subscriptions')
@withRouter
@observer
class CapacityPlanDetail extends Component {
  state = {
    loading: true,
    plan: undefined,
    shareDialogOpen: false,
    subscribeDialogOpen: false,
    unsubscribeDialogOpen: false,
    isSubscribed: undefined,
    selectedModel: undefined,
    isActionsOpen: false,
    hasFetchedHealthy: false
  };

  setStateIncSubscriptionStatus = (model, otherNewState) => {
    const { $subscriptions, $auth } = this.props;
    $subscriptions
      .isUserSubscribedToContent($auth.activeUser.user_email, 'capacityplans', model.id)
      .then((isSubscribed) => {
        this.setState({ ...otherNewState, isSubscribed });
      });
  };

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const model = new CapacityPlanModel({ id: params.id, healthyInterfacesOnly: false });

    model.fetch().then(() => {
      this.setStateIncSubscriptionStatus(model, {
        plan: model,
        loading: false
      });
    });
  }

  handleActionsInteraction = (isActionsOpen) => {
    const { plan } = this.state;
    this.setStateIncSubscriptionStatus(plan, { isActionsOpen });
  };

  onRemovePlan = () => {
    const { $capacity, history } = this.props;
    const { plan } = this.state;
    return plan.destroy().then(() => {
      $capacity.collection.remove(plan.id);
      history.push('/v4/core/capacity');
    });
  };

  onSeverityFilterClick = (options) => {
    const { hasFetchedHealthy, plan } = this.state;
    const { showHealthy } = options;

    if (showHealthy && !hasFetchedHealthy) {
      this.setState({ loading: true }, () => {
        plan.set('healthyInterfacesOnly', true);
        plan.fetch().then(() => {
          this.setState({ plan, loading: false, hasFetchedHealthy: true });
        });
      });
    }
  };

  toggleShareDialog = (dialogType) => {
    const { $subscriptions } = this.props;
    const { plan } = this.state;
    const attributes = { content_type: 'capacityplans', content_metadata: { values: [plan.id] } };

    const dialogToOpen = {
      share: 'shareDialogOpen',
      subscribe: 'subscribeDialogOpen',
      unsubscribe: 'unsubscribeDialogOpen'
    }[dialogType];

    this.setState(() => ({
      [dialogToOpen]: true,
      selectedModel: $subscriptions.collection.forge(attributes, { select: false })
    }));
  };

  onShareDialogClose = () => {
    this.setState({
      shareDialogOpen: false,
      subscribeDialogOpen: false,
      unsubscribeDialogOpen: false,
      selectedModel: undefined
    });
  };

  setSelectedModel(selectedModel) {
    this.setState({ selectedModel });
  }

  render() {
    const { $app, $auth, $capacity } = this.props;
    const {
      plan,
      loading,
      shareDialogOpen,
      subscribeDialogOpen,
      unsubscribeDialogOpen,
      selectedModel,
      isSubscribed,
      isActionsOpen
    } = this.state;

    const planName = plan ? plan.get('name') : undefined;
    let planIcon;
    if (plan) {
      const severity = plan.get('severity');
      if (severity === 'critical') {
        planIcon = <Icon icon={BsXOctagonFill} color="danger" mb="5px" />;
      } else if (severity === 'warning') {
        planIcon = <Icon icon={BsExclamationTriangleFill} color="warning" mb="5px" />;
      } else {
        planIcon = <Icon icon={FaCheckCircle} color="success" mb="5px" />;
      }
    }

    const subnavTools = (
      <>
        <Button icon={FiShare2} text="Share" minimal onClick={() => this.toggleShareDialog('share')} />
        <Popover
          isOpen={isActionsOpen}
          content={
            <Menu>
              <MenuItem icon="export" text="Export" popoverProps={{ openOnTargetFocus: false }}>
                <MenuItem
                  icon="document"
                  text="Visual Report"
                  label=".pdf"
                  onClick={() => $capacity.exportPlanPdf(plan)}
                />
                <MenuItem
                  icon="th"
                  text="Data Table"
                  label=".csv"
                  onClick={() => $capacity.exportPlanCsv(plan)}
                  className="export_csv"
                />
              </MenuItem>
              <MenuItem icon="envelope" text="Subscribe" onClick={() => this.toggleShareDialog('subscribe')} />
              {isSubscribed && (
                <MenuItem
                  icon={EnvelopeRemove}
                  text="Unsubscribe"
                  onClick={() => this.toggleShareDialog('unsubscribe')}
                />
              )}
            </Menu>
          }
          onInteraction={this.handleActionsInteraction}
          position={Position.BOTTOM_RIGHT}
        >
          <Button
            text="Actions"
            rightIcon={<Icon icon="caret-down" iconSize={16} mx="-2px" />}
            ml="2px"
            active={!!isActionsOpen}
            minimal
          />
        </Popover>
      </>
    );

    return (
      <>
        {$app.isExport && (
          <ExportCoverPage title={`Capacity Plan - ${planName}`} description={plan && plan.get('description')} />
        )}
        <Page title={planName} parentLinks={parentLinks} subnavTools={subnavTools}>
          <Flex flexDirection="column" flex={1}>
            <Flex alignItems="flex-start" justifyContent="space-between" mb={2}>
              <Box>
                <Flex alignItems="center" gap={1}>
                  <PageHeading
                    title={planName || 'Capacity Plan'}
                    level={2}
                    mb={0}
                    favoriteMetadata={{ resultType: 'capacityPlan' }}
                  />
                  {planIcon}
                </Flex>
                {plan && plan.get('description') && (
                  <Box mt={1}>
                    <Paragraph muted>{plan.get('description')}</Paragraph>
                  </Box>
                )}

                {plan && (
                  <>
                    {!plan.get('hasInsights') && (
                      <Callout px={2} mt={1} intent="warning">
                        Initial capacity data is processing and will be available soon.
                      </Callout>
                    )}
                  </>
                )}
              </Box>
              {!$app.isExport && $auth.isAdministrator && plan && (
                <Flex>
                  <LinkButton to={`/v4/core/capacity/${plan.id}/edit`} text="Edit Capacity Plan" mr={1} />

                  <ConfirmPopover onConfirm={this.onRemovePlan} entityName="Capacity Plan">
                    <Button intent="danger" text="Remove Capacity Plan" icon="trash" />
                  </ConfirmPopover>
                </Flex>
              )}
            </Flex>

            {plan && (
              <Flex mb={1}>
                <PlanInterfaceSeverity plan={plan} loading={loading} width="100%" />
              </Flex>
            )}

            <FlexColumn width="100%" height={$app.isExport ? 'auto' : `calc(100vh - ${NAV_HEIGHT + 154}px)`}>
              {loading && (
                <Flex alignItems="center">
                  <Spinner size={16} mr={1} />
                  <Text muted>Loading Plan Details...</Text>
                </Flex>
              )}
              {plan && (
                <CapacityPlan plan={plan} loading={loading} onSeverityFilterClick={this.onSeverityFilterClick} />
              )}
            </FlexColumn>
          </Flex>

          <ShareViewDialog
            isOpen={shareDialogOpen}
            model={selectedModel}
            setModel={(model) => this.setSelectedModel(model)}
            onClose={this.onShareDialogClose}
            legacyLinkShare
          />
          <SubscribeDialog
            isOpen={subscribeDialogOpen}
            model={selectedModel}
            setModel={(model) => this.setSelectedModel(model)}
            onClose={this.onShareDialogClose}
            subscriptionDialogType="subscribe"
          />
          <SubscribeDialog
            isOpen={unsubscribeDialogOpen}
            model={selectedModel}
            setModel={(model) => this.setSelectedModel(model)}
            onClose={this.onShareDialogClose}
            subscriptionDialogType="unsubscribe"
          />
        </Page>
      </>
    );
  }
}

export default CapacityPlanDetail;
