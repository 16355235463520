import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';

import storeLoader from 'app/stores/storeLoader';
import { formConsumer, Field, Switch } from 'core/form';
import { Text, Icon, Tooltip, Flex } from 'core/components';
import NotificationsSelect from 'app/components/notifications/NotificationsSelect';

const DISABLE_WARNING_NOTIFICATIONS_FIELD = 'config.alerting.disable_warning_notifications';

@formConsumer
@storeLoader('$notifications')
@withTheme
@observer
export default class NotificationChannels extends Component {
  static defaultProps = {
    fieldName: 'config.notifications.channels'
  };

  componentDidMount() {
    const { $notifications, form, fieldName } = this.props;
    // Note: Value that are disabled not selectable in Options causes validation error
    // We need to exclude those values
    const selectedValues = this.excludeDisabledValues(
      form.getValue(fieldName),
      $notifications?.collection?.notificationOptions
    );

    form.setValue(fieldName, selectedValues);
  }

  handleNotificationChannelsChange = (fieldState, newValue) => {
    const { form } = this.props;

    if (form.hasField(DISABLE_WARNING_NOTIFICATIONS_FIELD) && newValue.length === 0) {
      form.getField(DISABLE_WARNING_NOTIFICATIONS_FIELD).setToDefault();
    }
  };

  excludeDisabledValues = (values, options = []) => {
    const optionsValues = options
      .filter((option) => !parseInt(option.userGroupId, 10) > 0)
      .map((option) => option.value);
    return values.filter((value) => optionsValues.includes(value));
  };

  get hasChannels() {
    const { form, fieldName } = this.props;
    return form.getValue(fieldName).length > 0;
  }

  render() {
    const { disabled = false, tooltip, theme, fieldName, form } = this.props;

    return (
      <Flex flexDirection="column" gap={1}>
        <Text as="div" fontSize={14} fontWeight="bold">
          Notification Channel (Optional)
          {tooltip && (
            <Tooltip content={tooltip}>
              <Icon iconSize={14} icon="info-sign" color={theme.colors.gray3} mt={0} ml={1} />
            </Tooltip>
          )}
        </Text>

        <NotificationsSelect
          toggle
          keepOpen
          tagInput
          showAddButton
          disabled={disabled}
          fieldName={fieldName}
          fieldProps={{ width: 300, mb: 0, placeholder: 'Select an Existing...' }}
          onChange={this.handleNotificationChannelsChange}
        />

        {form.hasField(DISABLE_WARNING_NOTIFICATIONS_FIELD) && this.hasChannels && (
          <Field name={DISABLE_WARNING_NOTIFICATIONS_FIELD} large>
            <Switch />
          </Field>
        )}
      </Flex>
    );
  }
}
