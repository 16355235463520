import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';
import BaseMultiSeriesLineOrAreaGraph from '../BaseMultiSeriesLineOrAreaGraph';

const chartOptions = {
  tooltip: {
    pointFormatter() {
      return `<div>
        <span style="color:${this.color}">●</span> ${this.series.name}: <b>${this.y.toFixed(1)}%</b><br/>
      </div>`;
    },
    sort: true
  },
  yAxis: {
    min: 0,
    max: 100,
    labels: {
      formatter() {
        return `${this.value}%`;
      }
    }
  }
};

@inject('$app', '$auth', '$dataviews')
@observer
class BgpReachabilityTimeline extends BaseMultiSeriesLineOrAreaGraph {
  constructor(props) {
    super(props, { chartOptions });
  }
}

export default withTheme(BgpReachabilityTimeline);
