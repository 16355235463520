import React, { Component } from 'react';
import { TwitterPicker } from 'react-color';
import { Button, Box, Popover } from 'core/components';
import styled from 'styled-components';

const ColorBlock = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  padding: 0;
  box-shadow: inset 4px 4px 0px rgba(255, 255, 255, 0.5);
  background: ${({ value }) => value || '#5c7080'};
`;

// TODO: Make more configurable (i.e. select different pickers react-color has to offer)
class ColorPicker extends Component {
  static defaultProps = {
    pickerWidth: 300
  };

  state = {
    isOpen: false
  };

  handleClick = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleInteraction = (nextOpenState) => {
    this.setState({ isOpen: nextOpenState });
  };

  handleChangeComplete = (value) => {
    const { onChange } = this.props;
    onChange(value.hex);
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen } = this.state;
    const { field, pickerWidth } = this.props;
    const { value } = field.getProps();

    return (
      <Popover position="bottom-left" isOpen={isOpen} onInteraction={this.handleInteraction}>
        <Button
          large
          onClick={this.handleClick}
          active={isOpen}
          rightIcon="chevron-down"
          text={<ColorBlock value={value} />}
        />
        <Box maxWidth={300}>
          <TwitterPicker
            width={pickerWidth || null}
            color={value}
            triangle="hide"
            colors={field.options && field.options.length ? field.options.concat([]) : undefined /* use stock colors */}
            onChangeComplete={this.handleChangeComplete}
          />
        </Box>
      </Popover>
    );
  }
}

export default ColorPicker;
