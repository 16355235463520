import { observer, inject } from 'mobx-react';
import { debounce, merge } from 'lodash';
import { withTheme } from 'styled-components';
import { lighten } from 'polished';
import PropTypes from 'prop-types';
import { HEALTH } from 'shared/synthetics/constants';
import { formatDateTime, dateRangeDisplay } from 'core/util/dateUtils';
import BaseHighchartsNonStyledDataview from 'app/components/dataviews/views/BaseHighchartsNonStyledDataview';
import { healthToThemeColor, getRawHealthTimeRange } from 'app/views/synthetics/utils/syntheticsUtils';
import { FOCUS_TIME_RANGE_BUTTON_ID, tooltipPointFormatter } from './tooltipPointFormatter';

@withTheme
@inject('$app', '$auth', '$dataviews') // $app and $dataviews are required by the parent class
@observer
export default class AlarmTimeline extends BaseHighchartsNonStyledDataview {
  // shapes and descriptions of props we care about most
  // we won't include things like stores and themes here
  static propTypes = {
    // the test results model --- parent or subtest (agent)
    // optional because dashboards can control their date ranges through various means and we don't have a clear path in how to implement that
    // so for cases like those, we can elect to not pass in a test results model which effectively disables the ability to focus the time range via alarm timeline
    testResults: PropTypes.object,
    delta: PropTypes.number, // minimum time between time slices
    xMin: PropTypes.number, // minimum selectable time
    xMax: PropTypes.number, // maximum selectable time
    xAxisMin: PropTypes.number, // minimum x axis time
    xAxisMax: PropTypes.number, // maximum x axis time
    data: PropTypes.arrayOf(
      PropTypes.shape({
        time: PropTypes.number, // alarm time
        alarm: PropTypes.bool, // whether the alarm actually fired inside this timeslice
        alarms: PropTypes.array, // alarms that cover this timeslice
        alarmSummary: PropTypes.shape({
          severities: PropTypes.object, // severity as key, count as value
          metrics: PropTypes.object // metric as key, count as value
        }),
        health: PropTypes.string // healthy|warning|critical
      })
    ),
    ignoreAdjustment: PropTypes.bool, // indicates whether to ignore calculations that adjust timeline width fill
    onHover: PropTypes.func,
    onSelect: PropTypes.func,
    onShowHealthClick: PropTypes.func // allows focusing on a time slice from the tooltip --- requires the testResults prop to show the button
  };

  state = {};

  removePreviousSelection = null;

  constructor(props) {
    super(props);
    const { $auth, theme, onSelect, onHover } = this.props;
    const { colors, fonts } = theme;
    const { handleShowHealthClick } = this;

    this.state = {
      stickyPointer: true,
      highlightPointsByEvent: true,
      chartOptions: {
        chart: {
          type: 'xrange',
          style: { fontFamily: fonts.body },
          height: 82,
          spacing: [11, 15, 15, -20],
          backgroundColor: colors.chart.tooltipBackground,
          animation: false,
          events: {
            load: (e) => {
              setTimeout(() => {
                const selectedPoint = (e.target.series?.[0]?.points || []).find((point) => point.selected);

                if (selectedPoint) {
                  selectedPoint.select(true, false);
                }
              }, 0); // hIghChArTs
            }
          }
        },
        title: { text: '' },
        credits: { enabled: false },
        legend: { enabled: false },
        xAxis: {
          type: 'datetime',
          top: 2,
          tickColor: 'transparent',
          labels: {
            style: { color: colors.muted },
            formatter() {
              if (this.dateTimeLabelFormat === '%b %e') {
                return `<strong>${formatDateTime(this.value, 'MMM Do')}</strong>`;
              }

              return this.axis.defaultLabelFormatter.call(this);
            }
          },
          dateTimeLabelFormats: { day: '%b %e', week: '%b %e' }
        },
        yAxis: {
          title: { text: '' },
          labels: { enabled: false },
          gridLineColor: 'transparent',
          minorGridLineColor: 'transparent',
          categories: ['Health'],
          offset: 35
        },
        plotOptions: {
          xrange: {
            cursor: 'pointer'
          },
          series: {
            turboThreshold: 0, // disabled to handle up 1 week+ lookback results
            point: {
              events: {
                select: debounce((e) => {
                  // style the point with a light primary background and a down arrow icon over top
                  this.markSelection(e.target);
                }, 100),
                mouseOver: debounce((e) => {
                  const button = document.getElementById(FOCUS_TIME_RANGE_BUTTON_ID);

                  if (button) {
                    // add a click handler for focusing the time range
                    button.addEventListener('click', handleShowHealthClick, true);
                  }

                  if (onHover) {
                    onHover(e.target.options.time, e.target.options, e);

                    if (this.removePreviousSelection) {
                      this.removePreviousSelection();
                      this.removePreviousSelection = null;
                    }

                    e.target.select(true, false);
                  }
                }, 100),
                mouseOut: (e) => {
                  if (onHover) {
                    this.removePreviousSelection = () => e.target.select && e.target.select(false, false);
                  }
                },
                click: (e) => {
                  if (onSelect) {
                    e.point.select(true, false);
                    onSelect(e.point.options.time, e.point.options, e);
                  }
                }
              }
            }
          }
        },
        tooltip: {
          outside: true,
          useHTML: true,
          backgroundColor: colors.chart.tooltipBackground,
          headerFormat: '',
          distance: 12,
          hideDelay: 1000,
          pointFormatter() {
            return tooltipPointFormatter.call(this, theme, $auth.userTimezone);
          }
        },
        series: [
          {
            name: 'Test',
            borderRadius: 0,
            borderWidth: 0,
            pointWidth: 8,
            animation: { duration: 0 },
            data: []
          }
        ],
        annotations: [
          // down arrow svg graphic
          {
            cursor: 'pointer',
            crop: false,
            draggable: '',
            shapeOptions: {
              src:
                // eslint-disable-next-line max-len
                'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOSA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTguOTIwMDUgMC41MDAwMzJDOC45MjAwNSAwLjIyMDAzMiA4LjcwMDA1IDMuMTgyNTJlLTA1IDguNDIwMDUgMy4xODI1MmUtMDVIMS40MjAwNUMxLjMyMzQ0IC0wLjAwMTA2MzQ4IDEuMjI4NjMgMC4wMjYxMzUxIDEuMTQ3MjkgMC4wNzgyNzQ2QzEuMDY1OTYgMC4xMzA0MTQgMS4wMDE2NiAwLjIwNTIxNSAwLjk2MjMyMiAwLjI5MzQ1NkMwLjkyMjk4NiAwLjM4MTY5NyAwLjkxMDMzMiAwLjQ3OTUyIDAuOTI1OTE5IDAuNTc0ODY2QzAuOTQxNTA3IDAuNjcwMjEyIDAuOTg0NjU2IDAuNzU4OTEyIDEuMDUwMDUgMC44MzAwMzJMNC41NTAwNSA0LjgzMDAzQzQuNjQwMDUgNC45MzAwMyA0Ljc3MDA1IDUuMDAwMDMgNC45MjAwNSA1LjAwMDAzQzUuMDcwMDUgNS4wMDAwMyA1LjIwMDA1IDQuOTMwMDMgNS4yOTAwNSA0LjgzMDAzTDguNzkwMDUgMC44MzAwMzJDOC44NzAwNSAwLjc0MDAzMiA4LjkyMDA1IDAuNjMwMDMyIDguOTIwMDUgMC41MDAwMzJaIiBmaWxsPSIjNUM3MDgwIi8+Cjwvc3ZnPgo=',
              verticalAlign: 'bottom',
              type: 'image',
              x: -2,
              y: -2,
              width: 8,
              height: 5,
              cursor: 'pointer'
            },
            shapes: []
          },

          // light primary blue rectangle
          {
            cursor: 'pointer',
            crop: false,
            draggable: '',
            zIndex: 0,
            shapeOptions: {
              fill: lighten(0.4, colors.primary), // 'primaryBackground' uses transparentize, use lighten instead so axis lines don't show
              strokeWidth: 0,
              type: 'rect',
              cursor: 'pointer',
              y: -3
            },
            shapes: []
          }
        ]
      }
    };
  }

  componentWillUnmount() {
    const elButton = document.getElementById(FOCUS_TIME_RANGE_BUTTON_ID);

    if (elButton) {
      elButton.removeEventListener('click', this.handleShowHealthClick);
    }

    super.componentWillUnmount();
  }

  handleShowHealthClick = (e) => {
    const { testResults, onShowHealthClick } = this.props;

    if (onShowHealthClick) {
      // navigate to a time range beginning at the start of the alarm, extending forward by the max raw lookback value
      onShowHealthClick(
        getRawHealthTimeRange({ startDate: e.target.dataset.time / 1000, maxRawLookback: testResults.maxRawLookback })
      );
    }
  };

  get type() {
    return 'timeline';
  }

  get containerStyle() {
    const { ignoreAdjustment } = this.props;
    const offset = ignoreAdjustment ? '0' : '15';

    return { marginLeft: `-${offset}px`, marginRight: `-${offset}px`, width: `calc(100% + ${offset * 2}px)` };
  }

  // returns series data
  get data() {
    const { data, ignoreAdjustment, delta, testResults } = this.props;

    return data.map((item, index) => {
      const { time, health } = item;
      const color = this.getColor(health);
      const adjustment = ignoreAdjustment ? 0 : delta / 2; // fills data flush to the left edge
      const lastX2adjustment = ignoreAdjustment ? delta : delta / 2; // fills data flush to the right edge

      return {
        ...item,
        x: time - adjustment,
        x2: data[index + 1] ? data[index + 1].time - adjustment : time + lastX2adjustment,
        y: 0,
        color,
        borderColor: color,
        selected: !(index < data.length - 1),
        id: `${index}`, // important when referencing annotations
        testResults,
        states: {
          select: {
            color,
            borderColor: color
          }
        }
      };
    });
  }

  getColor(health) {
    const { theme } = this.props;

    if (health === HEALTH.HEALTHY) {
      // we no longer show healthy status in the timeline
      return 'transparent';
    }

    return healthToThemeColor({ health, theme });
  }

  // installs a blue box and down arrow graphic annotations on selected points
  markSelection(point) {
    this.setState(({ chartOptions }) => {
      const { width } = point.shapeArgs;
      const selectedArrow = chartOptions.annotations[0]; // the down arrow icon that sits over the selected slice
      const selectedBox = chartOptions.annotations[1]; // the blue box in the background of the selected slice
      const selectedPoint = { point: `${point.index}` };

      selectedBox.shapeOptions = {
        ...selectedBox.shapeOptions,
        width, // make the selected box the same width at the time slice
        height: 12, // the height will be slightly larger than the time slice to create a border
        x: (width / 2) * -1 - 1 // x lands on the center of the slice so nudge it half the width to the left so it's flush, then compsensate a pixel to account for border
      };

      if (point?.health === HEALTH.HEALTHY) {
        // display over the timeline to prevent a line intersecting the middle of the shape
        selectedBox.zIndex = 2;
      }

      // render the annotations at the selected point
      selectedBox.shapes = [selectedPoint];
      selectedArrow.shapes = [selectedPoint];

      return { chartOptions };
    });
  }

  redraw() {
    if (this.chart) {
      const { $auth, theme } = this.props;
      const { colors } = theme;

      // reload colors after theme switch
      // we merge with the previous chart options because it appears that things like annotations do not survive on chart update
      this.setState(({ chartOptions }) => ({
        chartOptions: merge(chartOptions, {
          chart: {
            backgroundColor: colors.chart.tooltipBackground
          },
          tooltip: {
            backgroundColor: colors.chart.tooltipBackground,
            pointFormatter() {
              return tooltipPointFormatter.call(this, theme, $auth.userTimezone); // tooltip needs to know the updated theme
            }
          },
          xAxis: {
            labels: {
              style: {
                color: colors.muted
              }
            }
          }
        })
      }));
    }
  }

  renderData() {
    if (this.chart) {
      const { data, xAxisMin, xAxisMax } = this.props;

      this.setState(({ chartOptions }) => {
        chartOptions.series[0].data = this.data;
        chartOptions.xAxis.title = {
          text:
            data.length && dateRangeDisplay({ starting_time: data[0].time, ending_time: data[data.length - 1].time })
        };

        if (xAxisMin && xAxisMax) {
          chartOptions.xAxis.min = xAxisMin;
          chartOptions.xAxis.max = xAxisMax;
        }

        return { chartOptions };
      });
    }
  }
}
