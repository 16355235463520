import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { uniqueId } from 'lodash';
import { Position } from '@blueprintjs/core';
import { MdCompareArrows, MdFilterList } from 'react-icons/md';

import { Box, Button, Flex, Icon, Popover, Text } from 'core/components';
import { CELL_TYPES, Table, VirtualizedTable } from 'core/components/table';
import { getQueryTimeInterval } from 'core/util/dateUtils';
import Dimension from 'app/components/dimensions/Dimension';
import FilterGroup from 'app/components/filters/display/FilterGroup';
import { getAggregateTypeLabel, getAggregateUnitLabel } from 'app/components/metric/metricRenderers';
import { all as cloudDetailMetrics } from 'app/views/core/cloud/cloudDetailMetrics';

import LegendRowOptions from './LegendRowOptions';
import {
  applicationMetrics,
  asnMetrics,
  asPathMetrics,
  cdnMetrics,
  cityMetrics,
  cloudMetrics,
  connectivityTypeMetrics,
  countryMetrics,
  deviceMetrics,
  interfaceMetrics,
  ipMetrics,
  networkBoundaryMetrics,
  ottProviderMetrics,
  ottServiceMetrics,
  ottServiceTypeMetrics,
  providerMetrics,
  regionMetrics,
  serviceMetrics,
  siteMarketMetrics,
  siteMetrics
} from './legendUtils';
import {
  applicationRenderer,
  asnRenderer,
  asPathRenderer,
  bgpCommunityRenderer,
  bracketRenderer,
  cdnRenderer,
  cityRenderer,
  cloudDetailRenderer,
  cloudRenderer,
  comparisonPercentageRenderer,
  comparisonRankRenderer,
  connectivityTypeRenderer,
  countryRenderer,
  deviceNameRenderer,
  durationMsRenderer,
  getAggregateRenderer,
  hideRawValueRenderer,
  interfaceRenderer,
  ipRenderer,
  networkBoundaryRenderer,
  nonToggleableColorRenderer,
  ottProviderRenderer,
  ottServiceRenderer,
  ottServiceTypeRenderer,
  packetSizeRenderer,
  portRenderer,
  protocolRenderer,
  providerRenderer,
  regionRenderer,
  routePrefixRenderer,
  serviceRenderer,
  siteMarketRenderer,
  siteNameRenderer,
  sparklineRenderer,
  timestampMsRenderer,
  toggleRenderer,
  valueOrNameRenderer
} from './legendRenderers';

const metricRendererMap = {
  'timestamp-ms': timestampMsRenderer,
  'duration-ms': durationMsRenderer
};

const totalRowFilter = (model) => !model.get('isOverlay') || !model.get('isFpa');

export const LegendAggregateLabel = (props) => {
  const { bucketLabel } = props;
  return (
    <Box>
      {bucketLabel && (
        <Text as="div" muted>
          {bucketLabel}
        </Text>
      )}
      <Text as="div" muted>
        {getAggregateTypeLabel(props)}
      </Text>
      <Text as="div" fontWeight="bold">
        {getAggregateUnitLabel(props)}
      </Text>
    </Box>
  );
};

export const MetricRenderer = ({ metric }) => (
  <Dimension dimension={metric} useChartTypes>
    {({ group, label }) => (
      <Box>
        <Text as="div">{group}</Text>
        <Text as="div" fontWeight="bold">
          {label}
        </Text>
      </Box>
    )}
  </Dimension>
);

export const LegendLastDatapointLabel = (props) => (
  <span>
    <Text as="div" muted>
      Last Datapoint
    </Text>
    <Text as="div" fontWeight="bold">
      {getAggregateUnitLabel(props)}
    </Text>
  </span>
);

const groupSummaryLookup = ({ /* groupBy, */ groupKey, group }) => `${groupKey} : ${group.length}`;

@inject('$app', '$auth', '$dataviews', '$dictionary', '$lookups')
@observer
export default class LegendTable extends Component {
  static defaultProps = {
    visibleKeys: [],
    selectedKeys: [],
    showSparklines: false,
    showLastDatapoints: true
  };

  componentDidUpdate(prevProps) {
    const { visibleKeys, bucket } = this.props;

    if (bucket && prevProps.visibleKeys.length !== visibleKeys.length) {
      if (visibleKeys.length > 0) {
        bucket.queryResults.filter((model) => visibleKeys.includes(model.get('key')));
      } else {
        bucket.queryResults.clearFilters();
      }
    }
  }

  getTableColumns(prefix) {
    const {
      $app,
      $auth,
      $dataviews,
      $dictionary,
      bucket,
      hideLegendOptions,
      compactMode,
      isViewCmp,
      isComparisonSummary,
      showSparklines,
      showLastDatapoints
    } = this.props;
    let { hideToggle } = this.props;

    const aggregateTypes = bucket.firstQuery.get('aggregateTypes');
    // filter to ensure that only selected aggregates are displayed
    const aggregates = bucket.firstQuery
      .get('aggregates')
      .filter((agg) => aggregateTypes.findIndex((aggType) => aggType === agg.value) > -1);
    let metrics = bucket.firstQuery.get('metric');
    const outsort = bucket.firstQuery.get('outsort');
    const bracketOptions = bucket.firstQuery.get('bracketOptions');
    const filterDimensionsEnabled = bucket.firstQuery.get('filterDimensionsEnabled');
    const filterDimensionName = bucket.firstQuery.get('filterDimensionName');
    const filterDimensions = bucket.firstQuery.get('filterDimensions');
    const viz_type = bucket.firstQuery.get('viz_type');
    const fastData = bucket.firstQuery.get('fastData');
    const { appProtocolQuery, metricColumns, metricWidths, metricRenderers } = $dictionary.dictionary;
    const { rawLegendUnits = [], requiredCols = {} } = appProtocolQuery;

    const rawLegend = rawLegendUnits.includes(bucket.firstQuery.outsortUnit);
    if (rawLegend) {
      const appProto = bucket.firstQuery.outsortUnit.split('__')[1];
      const required = requiredCols[appProto] || [];
      metrics = [...required, ...metrics.filter((metric) => metric !== 'Traffic' && !required.includes(metric))];
      hideToggle = true;
    }

    const columns = [];
    const { enableToggle, enableNonToggleableColor } = $dataviews.getConfig(viz_type);
    this.rowHeight = 39;

    if (!hideToggle && !enableToggle && enableNonToggleableColor) {
      columns.push({
        name: 'toggled',
        className: 'legend-toggle-column',
        referencedFields: ['toggled', 'color'],
        renderer: nonToggleableColorRenderer,
        ellipsis: false,
        width: 40,
        colSpan: (model, totalRow) => (totalRow ? 2 : 1),
        sortable: false
      });
    }

    if (!hideToggle && enableToggle) {
      const selectableModels = bucket?.queryResults?.getRawDataRows(true) || [];
      const numSelected = selectableModels.filter((item) => !item.get('toggled')).length;
      let selected = 'all';
      let iconProps = {
        icon: 'full-circle',
        htmlTitle: 'All selected: click to select none'
      };
      if (numSelected === 0) {
        selected = 'none';
        iconProps = {
          icon: 'circle',
          htmlTitle: 'None selected: click to select all'
        };
      } else if (numSelected < selectableModels.length) {
        selected = 'manual';
        iconProps = {
          icon: 'remove',
          htmlTitle: 'Manual selection: click to select none'
        };
      }

      columns.push({
        name: 'toggled',
        className: 'legend-toggle-column',
        referencedFields: ['toggled', 'color'],
        renderer: toggleRenderer,
        ellipsis: false,
        width: 40,
        colSpan: (model, totalRow) => (totalRow ? 2 : 1),
        label: <Icon p="2px 6px" iconSize={14} {...iconProps} />,
        onHeaderClick: () => {
          selectableModels.forEach((model, i) => {
            model.set('preventRedraw', i !== selectableModels.length - 1); // redraw on last
            model.toggled = selected === 'all' || selected === 'manual';
          });
        },
        sortable: false
      });
    }

    if (bucket.collection.hasPeriodOverPeriod && !$app.isExport) {
      columns.push({
        name: 'comparing',
        ellipsis: false,
        width: 65,
        label: 'Compare',
        sortable: false,
        renderer: ({ model, value }) =>
          model.hasRawData &&
          model.otherPeriodModel?.hasRawData && (
            // click is handled by row
            <Button
              intent="primary"
              icon={MdCompareArrows}
              minimal
              active={value}
              title="Compare current and previous periods"
            />
          )
      });
    }

    if (isComparisonSummary) {
      columns.push({
        name: 'rank',
        label: 'Rank',
        renderer: comparisonRankRenderer,
        title: () => null,
        width: 85
      });
    }

    if (showSparklines) {
      columns.push({
        name: 'sparklines',
        renderer: sparklineRenderer,
        ellipsis: false,
        width: 85,
        colSpan: () => 1
      });
    }

    if (filterDimensionsEnabled) {
      columns.push({
        name: metricColumns.Traffic,
        flexBasis: 100,
        minWidth: 80,
        ellipsis: false,
        label: (
          <span>
            <div className="pt-normal pt-text-muted">Filter-Based</div>
            {filterDimensionName}
          </span>
        ),
        colSpan: () => 1,
        renderer: ({ value, model }) => {
          let filterGroup = filterDimensions.filterGroups.find((group) => group.name === value);
          if (!filterGroup && value === 'Other') {
            filterGroup = {
              name: 'Other',
              connector: 'Any',
              filterGroups: filterDimensions.filterGroups,
              not: true
            };
          }

          return (
            <>
              <span>{valueOrNameRenderer(!isViewCmp)({ value, model })}</span>
              {filterGroup && !model.get('isOverlay') && (
                <Box alignSelf="center">
                  <Popover
                    position={Position.RIGHT_BOTTOM}
                    content={
                      <Box p={2}>
                        This value is derived from the following filter:
                        <FilterGroup group={filterGroup} connector={filterDimensions.connector} isLastGroup />
                      </Box>
                    }
                  >
                    <Button icon={MdFilterList} ml={1} minimal />
                  </Popover>
                </Box>
              )}
            </>
          );
        }
      });
    } else {
      metrics.forEach((metric, index) => {
        const column = {
          name: metricColumns[metric] || metric,
          flexBasis: metricWidths[metric] || 100,
          minWidth: 80,
          label: <MetricRenderer metric={metric} />,
          colSpan: (model, totalRow) =>
            ((totalRow && !enableToggle) || (model && model.isOverlay)) && index === 0 ? metrics.length : 1,
          wrapText: $app.isExport
        };

        if (metric === 'Port_src' || metric === 'Port_dst') {
          const lookback_seconds = bucket.firstQuery.get('lookback_seconds');
          const starting_time = bucket.firstQuery.get('starting_time');
          const ending_time = bucket.firstQuery.get('ending_time');
          const queryInterval = getQueryTimeInterval({ lookback_seconds, starting_time, ending_time });
          column.renderer = portRenderer(index === 0, !isViewCmp, fastData, queryInterval);
        } else if (serviceMetrics.includes(metric)) {
          const lookback_seconds = bucket.firstQuery.get('lookback_seconds');
          const starting_time = bucket.firstQuery.get('starting_time');
          const ending_time = bucket.firstQuery.get('ending_time');
          const queryInterval = getQueryTimeInterval({ lookback_seconds, starting_time, ending_time });
          column.renderer = serviceRenderer(index === 0, !isViewCmp, fastData, queryInterval);
        } else if (cloudMetrics.includes(metric)) {
          column.renderer = cloudRenderer(!isViewCmp);
        } else if (cloudDetailMetrics.includes(metric)) {
          column.renderer = cloudDetailRenderer(!isViewCmp);
        } else if (countryMetrics.includes(metric)) {
          column.renderer = countryRenderer(!isViewCmp);
        } else if (cityMetrics.includes(metric)) {
          column.renderer = cityRenderer();
        } else if (regionMetrics.includes(metric)) {
          column.renderer = regionRenderer();
        } else if (asnMetrics.includes(metric)) {
          column.renderer = asnRenderer(!isViewCmp);
        } else if (asPathMetrics.includes(metric)) {
          column.renderer = asPathRenderer(!isViewCmp);
        } else if (deviceMetrics.includes(metric) || metric.endsWith('__i_device_name')) {
          column.renderer = deviceNameRenderer(!isViewCmp);
        } else if (siteMetrics.includes(metric)) {
          column.renderer = siteNameRenderer(!isViewCmp);
        } else if (siteMarketMetrics.includes(metric)) {
          column.renderer = siteMarketRenderer(!isViewCmp);
        } else if (
          interfaceMetrics.includes(metric) ||
          metric.endsWith('__input_port') ||
          metric.endsWith('__output_port')
        ) {
          column.renderer = interfaceRenderer(!isViewCmp);
          column.padding = '7px 0px 7px 12px';
          column.ellipsis = false;
          column.minWidth = 120;
          this.rowHeight = 60;
        } else if (ipMetrics.includes(metric)) {
          column.renderer = ipRenderer(!isViewCmp);
        } else if (cdnMetrics.includes(metric)) {
          column.renderer = cdnRenderer(!isViewCmp);
        } else if (ottProviderMetrics.includes(metric)) {
          column.renderer = ottProviderRenderer(!isViewCmp);
        } else if (ottServiceMetrics.includes(metric)) {
          column.renderer = ottServiceRenderer(!isViewCmp);
        } else if (ottServiceTypeMetrics.includes(metric)) {
          column.renderer = ottServiceTypeRenderer(!isViewCmp);
        } else if (applicationMetrics.includes(metric)) {
          column.renderer = applicationRenderer(!isViewCmp);
        } else if (metric === 'sampledpktsize') {
          column.renderer = packetSizeRenderer();
        } else if (metric === 'Proto') {
          column.renderer = protocolRenderer();
        } else if (metric === 'src_route_prefix_len' || metric === 'dst_route_prefix_len') {
          column.renderer = routePrefixRenderer();
        } else if (networkBoundaryMetrics.includes(metric)) {
          column.renderer = networkBoundaryRenderer();
        } else if (metric === 'src_bgp_community' || metric === 'dst_bgp_community') {
          column.renderer = bgpCommunityRenderer();
        } else if (connectivityTypeMetrics.includes(metric)) {
          column.renderer = connectivityTypeRenderer();
        } else if (providerMetrics.includes(metric)) {
          column.renderer = providerRenderer();
        } else if ($dictionary.get('hideRawValueDimensions', []).includes(metric)) {
          column.renderer = hideRawValueRenderer();
        } else if (metricRenderers[metric]) {
          column.renderer = metricRendererMap[metricRenderers[metric]];
        } else if (index === 0) {
          column.renderer = valueOrNameRenderer(!isViewCmp);
        }

        columns.push(column);
      });
    }

    if (bracketOptions && bracketOptions.ranges && bracketOptions.ranges.length) {
      columns.unshift({
        name: 'tag_table',
        type: 'bracket',
        key: uniqueId(),
        renderer: bracketRenderer,
        padding: '0px',
        width: 10,
        height: this.rowHeight,
        ellipsis: false,
        showTotal: false,
        colSpan: (model, totalRow) => {
          let span = 1;
          if (totalRow && enableToggle) {
            span = 3;
          } else if (totalRow && !enableToggle) {
            span = 2;
          }
          if (totalRow && metrics && metrics.length) {
            span += metrics.length - 1;
          }
          return span;
        }
      });
    }

    const lastDatapointColumns = [];
    if (isComparisonSummary) {
      const aggregate = aggregates.find((agg) => agg.value === outsort) || aggregates[0];
      const { activeBuckets } = bucket.collection;

      columns.push({
        name: 'percentage',
        label: 'Percentage Change',
        renderer: comparisonPercentageRenderer,
        title: ({ value }) => (value ? `${(value * 100).toFixed(2)}%` : null),
        width: 75,
        align: 'left'
      });

      activeBuckets.forEach((aggBucket) => {
        const { column } = this.getAggregateColumn(aggregate, aggBucket, prefix);
        columns.push(column);
      });
    } else if (!rawLegend) {
      aggregates.forEach((aggregate) => {
        if (aggregate.label) {
          const { column, lastColumn } = this.getAggregateColumn(aggregate, bucket, prefix);
          columns.push(column);

          if (lastColumn) {
            lastDatapointColumns.push(lastColumn);
          }
        }
      });
    }

    if (showLastDatapoints && lastDatapointColumns) {
      columns.push(...lastDatapointColumns);
    }

    if (
      !$auth.isSharedUser &&
      !hideLegendOptions &&
      !compactMode &&
      !filterDimensionsEnabled &&
      metrics[0] !== 'Traffic'
    ) {
      columns.push({
        name: 'options',
        type: CELL_TYPES.ACTION,
        actions: [
          (model) =>
            !model.get('isOverlay') && (
              <LegendRowOptions
                {...this.props}
                key="legend-tool"
                model={model}
                showRowSelectionOptions={enableToggle}
              />
            )
        ],
        width: 45
      });
    }

    return columns;
  }

  getAggregateColumn(aggregate, bucket, prefix) {
    const { isComparisonSummary } = this.props;
    const { firstQuery } = bucket;
    const show_total_overlay = firstQuery.get('show_total_overlay');
    const showPreviousPeriod =
      isComparisonSummary && bucket.get('isPreviousPeriod') && firstQuery.get('outsort') === aggregate.value;

    const renderer = getAggregateRenderer({ aggregate, prefix, bucket, isComparisonSummary });
    const bucketLabel = isComparisonSummary ? bucket.shortLabel : null;
    const isAverage = show_total_overlay && aggregate.fn === 'average' && !isComparisonSummary;
    const align = !isAverage ? 'right' : undefined;
    const headerAlign = isAverage ? 'center' : 'right';
    const showTotal = !aggregate.value.includes('perc');

    let width = 113;
    if (firstQuery.get('period_over_period') && !isComparisonSummary) {
      width = 180;
    } else if (isAverage) {
      width = 150;
    }

    const column = {
      key: `${bucket.get('name')} ${aggregate.value}`,
      name: showPreviousPeriod ? `${aggregate.value}_comparison` : aggregate.value,
      label: <LegendAggregateLabel aggregate={aggregate} prefix={prefix} bucketLabel={bucketLabel} />,
      renderer,
      totalRenderer: renderer,
      width,
      ellipsis: false,
      showTotal,
      align,
      headerAlign
    };

    let lastColumn;

    if (
      aggregate.raw &&
      !aggregate.value.includes('agg_total') &&
      !(aggregate.parentUnit || aggregate.unit).includes('sample_rate')
    ) {
      lastColumn = {
        name: `${aggregate.column}__k_last`,
        label: <LegendLastDatapointLabel aggregate={aggregate} prefix={prefix} />,
        renderer,
        totalRenderer: renderer,
        width,
        ellipsis: false,
        showTotal,
        align,
        headerAlign
      };
    }

    return { column, lastColumn };
  }

  onRowClick = (rowModel, e) => {
    const { bucket, onModelSelect, sourceLink } = this.props;

    console.warn('onRowClick', { rowModel, bucket });

    if (onModelSelect && !rowModel.get('isOverlay')) {
      const { model } = sourceLink;
      onModelSelect(rowModel, model, e.metaKey);
    }

    if (bucket.collection.hasPeriodOverPeriod) {
      if (rowModel.get('comparing')) {
        // allow deselecting row if it got selected from Compare Over option
        rowModel.setComparing(false);
      } else if (rowModel.hasRawData && rowModel.otherPeriodModel?.hasRawData) {
        // otherwise only allow selecting when both models have raw data to show
        rowModel.setComparing(true);
      }
    }
  };

  rowHeight = 39;

  getRowHeight = ({ model }) => (model && (model.isGroupSummary || model.get('isOverlay')) ? 39 : this.rowHeight);

  render() {
    const {
      $app,
      $dictionary,
      bucket,
      isViewCmp,
      loading,
      selectedKeys,
      isComparisonSummary,
      isVirtualized = true,
      includeTotalRow = true
    } = this.props;
    if (!bucket) {
      return null;
    }
    const { firstQuery } = bucket;
    const queryResults = isComparisonSummary ? bucket.collection.comparisonQueryResults : bucket.queryResults;
    const { comparingModel, prefix } = bucket.queryResults;
    const aggregateTypes = firstQuery.get('aggregateTypes');
    const showTotalRow = includeTotalRow
      ? aggregateTypes.some((aggregateType) => aggregateType.includes('per_sec'))
      : includeTotalRow;

    const {
      dictionary: {
        appProtocolQuery: { rawLegendUnits = [] }
      }
    } = $dictionary;

    let modelField;
    if (firstQuery.get('use_fpa')) {
      modelField = 'nonFpaRows';
    } else if (rawLegendUnits.includes(firstQuery.outsortUnit)) {
      modelField = 'rawRows';
    }

    if ($app.isExport || !isVirtualized) {
      return (
        <Table
          collection={queryResults}
          modelField={modelField}
          columns={this.getTableColumns(prefix)}
          groupSummaryLookup={groupSummaryLookup}
          showTotalRow={showTotalRow}
          totalRowFilter={totalRowFilter}
        />
      );
    }

    return (
      <Flex flex={1}>
        <VirtualizedTable
          loading={loading}
          collection={queryResults}
          columns={this.getTableColumns(prefix)}
          modelField={modelField}
          groupSummaryLookup={groupSummaryLookup}
          flexed
          stickyHeader
          rowHeight={this.getRowHeight}
          hideNonIdealState={!isViewCmp}
          onRowClick={this.onRowClick}
          selectOnRowClick={false}
          isRowSelected={(model) =>
            model.get('key') === comparingModel?.get('key') || selectedKeys.includes(model.get('key'))
          }
          onRowMouseOver={(model) => {
            if (this.mouseoverModel) {
              this.mouseoverModel.set({ mouseover: false });
            }
            model.set({ mouseover: true });
            this.mouseoverModel = model;
          }}
          onRowMouseOut={(model) => {
            model.set({ mouseover: false });
            this.mouseoverModel = null;
          }}
          showTotalRow={showTotalRow}
          totalRowFilter={totalRowFilter}
        />
      </Flex>
    );
  }
}
