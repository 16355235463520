import { SYN_CONSTANTS } from 'shared/synthetics/constants';

const baseHTTPFields = {
  'config.http': {},

  'config.period': {
    label: 'Test Frequency',
    defaultValue: 60,
    rules: ['numeric', 'min:60', 'max:3600'],
    options: [
      { label: 'Every minute', value: 60 },
      { label: 'Every 2 minutes', value: 120 },
      { label: 'Every 5 minutes', value: 300 },
      { label: 'Every 10 minutes', value: 600 },
      { label: 'Every 15 minutes', value: 900 },
      { label: 'Every 30 minutes', value: 1800 },
      { label: 'Every 60 minutes', value: 3600 }
    ]
  },

  'config.http.ignore_tls_errors': {
    defaultValue: false
  },

  'config.expiry': {
    label: 'HTTP Timeout (ms)',
    defaultValue: SYN_CONSTANTS.httpExpiryDefaultMs,
    rules: ['numeric', `min: ${SYN_CONSTANTS.httpExpiryMinMs}`, `max: ${SYN_CONSTANTS.httpExpiryMaxMs}`]
  }
};

export { baseHTTPFields };
