import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Callout, Icon } from 'core/components';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { Field, InputGroup } from 'core/form';

import { MAX_SAMPLING_RATE, MIN_SAMPLING_RATE } from 'shared/hybrid/constants';

@inject('$cloudExportWizard')
@observer
class SamplingRatePanel extends Component {
  render() {
    const { $cloudExportWizard } = this.props;
    const { form } = $cloudExportWizard;

    return (
      <div style={{ width: '70%', padding: '5px 0' }}>
        {form.getValue('properties.sampling_rate') > MAX_SAMPLING_RATE && (
          <Callout intent="danger">
            <Icon icon={BsFillExclamationTriangleFill} color="warning" mr={1} mt="1px" />
            Sampling Rate too high. Try a lower number.
          </Callout>
        )}

        {form.getValue('properties.sampling_rate') < MIN_SAMPLING_RATE && (
          <Callout intent="danger">
            <Icon icon={BsFillExclamationTriangleFill} color="warning" mr={1} mt="1px" />
            Sampling Rate must be a positive integer greater than {MIN_SAMPLING_RATE}.
          </Callout>
        )}

        <div>
          <Field name="properties.sampling_rate">
            <InputGroup type="number" width="4em" />
          </Field>
          <p>Sampling 1 out of {form.getValue('properties.sampling_rate')} records.</p>
        </div>
      </div>
    );
  }
}

export default SamplingRatePanel;
