import React from 'react';

import { Flex } from 'core/components';
import InterfaceMetadata from './info/InterfaceMetadata';
import InterfaceKvsMetadata from './info/InterfaceKvsMetadata';
import InterfaceClassification from './info/InterfaceClassification';

const InterfaceInfoTab = ({ capacityPlans, selectedInterface }) => (
  <Flex p={3} className="InterfaceInfoTab" style={{ overflowX: 'auto', flexGrow: 1 }}>
    <InterfaceMetadata capacityPlans={capacityPlans} selectedInterface={selectedInterface} />
    <InterfaceKvsMetadata selectedInterface={selectedInterface} />
    <InterfaceClassification selectedInterface={selectedInterface} />
  </Flex>
);

export default InterfaceInfoTab;
