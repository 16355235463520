import React from 'react';
import { LinkButton, Link } from 'core/components';
import { getHashForObject } from 'app/stores/query/urlHash';

class MitigationLink extends React.Component {
  state = {
    readyToRender: false
  };

  componentDidMount() {
    const { isSubtenant, id } = this.props;

    const filterObject = {
      hashedFilters: {
        mitigations: id,
        lookback: 7776000,
        statuses: ['Active', 'Failed', 'Waiting', 'Inactive'],
        sources: []
      }
    };

    getHashForObject(filterObject).then((hash) => {
      const to = isSubtenant ? `/v4/mitigations?q=${hash}` : `/v4/protect/mitigations?q=${hash}`;

      this.setState({
        to,
        readyToRender: true
      });
    });
  }

  render() {
    const { readyToRender, to } = this.state;
    const { as, children = 'View Details', id, isSubtenant, ...rest } = this.props;
    const isButton = as === 'Button';

    if (!readyToRender) {
      return null;
    }

    if (isButton) {
      return (
        <LinkButton to={to} {...rest}>
          {children}
        </LinkButton>
      );
    }

    return (
      <Link to={to} {...rest}>
        {children}
      </Link>
    );
  }
}

export default MitigationLink;
