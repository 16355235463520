import { inject, observer } from 'mobx-react';
import { adjustByGreekPrefix } from 'core/util';
import { getToFixed, zeroToText, escapeHtml } from 'app/util/utils';
import Highcharts from 'highcharts';
import Sunburst from 'highcharts/modules/sunburst';
import { getBaseSorts } from 'app/stores/query/ExplorerQueryModel';
import BaseHighchartsDataview from './BaseHighchartsDataview';

Sunburst(Highcharts);

@inject('$app', '$dictionary')
@observer
export default class SunburstView extends BaseHighchartsDataview {
  useQualitativeColors = true;

  chartOptions = {
    chart: {
      className: 'sunburst',
      events: {
        redraw() {
          const button = this.container.querySelector('.highcharts-button');
          if (button) {
            // HC only uses a tspan if the text has at least one whitespace in it.
            // So we do this instead of trying to select text > tspan in order to
            // cover cases where the button text has no whitespace.
            button.querySelector('text').innerHTML = '<tspan>Zoom Out</tspan>';
            button.querySelector('rect').setAttribute('width', 70);
            button.setAttribute(
              'transform',
              `translate(${this.container.querySelector('.highcharts-plot-border').getAttribute('width') - 70}, 20)`
            );
          }
        }
      }
    },
    title: {
      text: ''
    },
    credits: {
      enabled: true,
      position: { align: 'center' },
      style: { cursor: 'default' },
      href: null,
      text: 'Click to Zoom In/Out'
    },
    legend: { enabled: false },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          formatter() {
            if (this.key.startsWith('Total of Top')) {
              return '';
            }
            const { innerArcLength } = this.point;
            const maxKeySize = Math.floor(innerArcLength / 15);
            if (this.key.length > maxKeySize) {
              // hacky ellipsis here because HC isn't forcing sizing anymore...?
              return `${this.key.slice(0, maxKeySize)}...`;
            }
            return this.key;
          },
          filter: {
            property: 'innerArcLength',
            operator: '>',
            value: 16
          }
        }
      }
    },
    series: [
      {
        type: 'sunburst',
        allowDrillToNode: true,
        cursor: 'pointer',
        data: [],
        levels: [
          {
            level: 1,
            levelIsConstant: false,
            dataLabels: {
              filter: {
                property: 'outerArcLength',
                operator: '>',
                value: 64
              }
            }
          },
          {
            level: 2,
            colorByPoint: true
          },
          {
            level: 3,
            colorVariation: {
              key: 'brightness',
              to: -0.5
            }
          },
          {
            level: 4,
            colorVariation: {
              key: 'brightness',
              to: 0.5
            }
          },
          {
            level: 5,
            colorVariation: {
              key: 'brightness',
              to: -0.5
            }
          },
          {
            level: 6,
            colorVariation: {
              key: 'brightness',
              to: 0.5
            }
          },
          {
            level: 7,
            colorVariation: {
              key: 'brightness',
              to: -0.5
            }
          },
          {
            level: 8,
            colorVariation: {
              key: 'brightness',
              to: 0.5
            }
          }
        ]
      }
    ],
    tooltip: {
      formatter() {
        const { prefix, suffix, unit } = this.point.options;
        const val = escapeHtml(zeroToText(adjustByGreekPrefix(this.point.value, prefix), { fix: getToFixed(unit) }));
        return `${this.key}:<br/><b>${val} ${prefix || ''}${suffix}</b>`;
      }
    }
  };

  buildSeriesInternal(bucket, models) {
    this.clear();

    if (!this.chart || !models || models.length === 0) {
      return;
    }

    const { $app, $dictionary } = this.props;
    const metricColumns = $dictionary.get('metricColumns');
    const query = bucket.firstQuery;
    const metric = query.get('metric');
    const outsort = query.get('outsort');
    const units = query.get('units');
    const topx = query.get('topx');
    const { aggregates, outsortUnit, outsortDataKey } = query;
    const prefix = bucket.queryResults.prefix[outsortUnit];
    let suffix = $dictionary.dictionary.units[outsortUnit];
    if (units.includes('sample_rate')) {
      suffix = ` : 1 ${suffix}`;
    }

    const [series] = this.chart.series;
    const nodes = [
      {
        id: 'node0',
        parent: '',
        name: `Total of Top ${topx}`,
        prefix,
        suffix,
        color: 'transparent'
      }
    ];

    const aspathMetrics = metric.reduce(
      (arr, mtrc, index) => (mtrc.includes('bgp_aspath') ? arr.concat([index]) : arr),
      []
    );

    let colorCounter = 0;
    models.forEach((model) => {
      const isOverlay = model.get('isOverlay');
      let data = model.get(outsortDataKey);

      if (units.includes('sample_rate') && aggregates.find((agg) => agg.name === outsort).fn !== 'percentile') {
        data /= 100;
      }

      if (!isOverlay) {
        const metrics = [].concat(metric);
        const path = metrics.map((m) => model.get(metricColumns[m] || m));
        if (aspathMetrics.length) {
          aspathMetrics.forEach((index) => {
            path.splice(index, 1, ...path[index].split(' '));
          });
        }
        path.reduce((parent, name, index) => {
          let node = nodes.find((n) => n.name === name && n.parent === parent);
          if (!node) {
            node = { id: `node${nodes.length}`, parent, name, prefix, suffix };
            if (index === 0) {
              node.color = this.chartColors[colorCounter % this.chartColors.length];
              colorCounter += 1;
            }
            nodes.push(node);
          }
          if (index === path.length - 1) {
            node.value = node.value || 0;
            node.value += data;
          }
          return node.id;
        }, 'node0');
        model.set({
          colorIndex: colorCounter,
          color: this.getColorFromIndex(colorCounter)
        });
      }
    });

    series.setData(nodes, false);

    $app.renderSync(() => {
      if (this.chart) {
        this.chart.redraw();
      }
    });
    $app.renderSync(() => {
      this.dismissSpinner();
    });
  }

  redraw({ setColors = false } = {}) {
    const { $app } = this.props;

    if (this.chart) {
      if (setColors && this.chart.series[0].points) {
        this.chart.series[0].points.reduce((counter, node) => {
          if (node.parent === 'node0') {
            node.update(
              {
                color: this.chartColors[counter % this.chartColors.length]
              },
              false
            );
            return counter + 1;
          }
          return counter;
        }, 0);
      }
      $app.renderSync(() => {
        if (this.chart) {
          this.chart.redraw();
        }
      });
      $app.renderSync(() => {
        this.dismissSpinner();
      });
    }
  }

  clear() {
    if (this.chart?.series?.length > 0) {
      const [series] = this.chart.series;
      if (series.drillUpButton) {
        series.drillToNode('');
      }
      series.setData([]);
    }
  }

  getComponent() {
    const { chartOptions } = this;
    const { dataview } = this.props;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const outsort = activeBuckets[0].firstQuery.get('outsort');

    // limit to valid outsorts
    if (!(getBaseSorts([outsort], 'pie') || []).length || outsort.includes('perc_') || outsort.includes('latency')) {
      throw new Error('Invalid Primary Display/Sort Metric for this visualization');
    }

    if (chartOptions.plotOptions && chartOptions.plotOptions.sunburst) {
      const { sunburst } = chartOptions.plotOptions;

      if (this.props.onModelSelect) {
        if (!sunburst.point) {
          sunburst.point = { events: {} };
        }
        sunburst.point.events.click = this.getLegendItemClick();
      }
    }

    return super.getComponent();
  }
}

const config = {
  showTotalTrafficOverlay: false,
  showLegend: true,
  timeBased: false,
  isSVG: true,
  enableToggle: false,
  enableNonToggleableColor: true,
  buckets: [
    {
      name: 'Sunburst'
    }
  ]
};

export { config };
