import React from 'react';

import $devices from 'app/stores/device/$devices';

import { Box, LinkButton, Text } from 'core/components';
import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: [
    'kt_az_src_inst_name',
    'kt_az_src_inst_id',
    'kt_az_src_region',
    'kt_az_src_zone',
    'kt_az_src_inst_type',
    'kt_az_src_fqdn',
    'kt_az_src_vnet',
    'kt_az_src_subnet',
    'kt_az_src_rsrc_group',
    'kt_az_src_public_ip',
    'kt_az_src_sub_id',
    'kt_az_src_sub_name',
    'kt_az_src_nsg_name',
    'kt_az_dst_inst_name',
    'kt_az_dst_inst_id',
    'kt_az_dst_region',
    'kt_az_dst_zone',
    'kt_az_dst_inst_type',
    'kt_az_dst_fqdn',
    'kt_az_dst_vnet',
    'kt_az_dst_subnet',
    'kt_az_dst_rsrc_group',
    'kt_az_dst_public_ip',
    'kt_az_dst_sub_id',
    'kt_az_dst_sub_name',
    'kt_az_dst_nsg_name'
  ],
  filterFields: [
    'kt_az_src_inst_name',
    'kt_az_src_inst_id',
    'kt_az_src_region',
    'kt_az_src_zone',
    'kt_az_src_inst_type',
    'kt_az_src_fqdn',
    'kt_az_src_vnet',
    'kt_az_src_subnet',
    'kt_az_src_rsrc_group',
    'kt_az_src_public_ip',
    'kt_az_src_sub_id',
    'kt_az_src_sub_name',
    'kt_az_src_nsg_name',
    'kt_az_dst_inst_name',
    'kt_az_dst_inst_id',
    'kt_az_dst_region',
    'kt_az_dst_zone',
    'kt_az_dst_inst_type',
    'kt_az_dst_fqdn',
    'kt_az_dst_vnet',
    'kt_az_dst_subnet',
    'kt_az_dst_rsrc_group',
    'kt_az_dst_public_ip',
    'kt_az_dst_sub_id',
    'kt_az_dst_sub_name',
    'kt_az_dst_nsg_name'
  ]
};

class AzureDependency extends AbstractDependency {
  get key() {
    return 'azure';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return $devices.hasAzureDevice;
  }

  get preventQuery() {
    return true;
  }

  get message() {
    return (
      <>
        <Box mb="4px">
          <Text small>
            <strong>Azure</strong> devices are required by dimensions you&apos;re using, but have not been detected for
            any devices in your organization. Results shown herein may be highly inaccurate.
          </Text>
        </Box>
        <LinkButton text="Configure" icon="cog" small to="/v4/settings/clouds" />
      </>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || $devices.hasAzureDevice;
  }
}

export default AzureDependency;
