import { getFilterFields } from '@kentik/ui-shared/filters';
import $dictionary from 'app/stores/$dictionary';

export const getNmsDimensionLabel = (dimensionKey, dimensionObject = {}) => {
  if (!dimensionKey) {
    return null;
  }

  return dimensionObject.Label || dimensionObject.name || dimensionKey;
};

const findProtocolMetric = (metrics, filters) => {
  const startsWithName = (item) => item && item.startsWith('ktappprotocol');
  const filterFields = getFilterFields(filters);

  return metrics.find(startsWithName) || filterFields.find(startsWithName);
};

export const getAppProtocol = (metrics = [], filters = [], idOnly) => {
  const protocolMetric = findProtocolMetric(metrics, filters);

  if (protocolMetric) {
    const protocolName = protocolMetric.split('__')[1];
    const appProtocol = $dictionary.dictionary.app_protocols[protocolName];

    if (idOnly) {
      return appProtocol && appProtocol.id;
    }

    return appProtocol;
  }

  return undefined;
};

export const getDisabledAppProtocolDimensions = (metrics, filters) => {
  const protocolMetric = findProtocolMetric(metrics, filters);

  if (protocolMetric) {
    const protocolName = protocolMetric.split('__')[1];
    const appProtocol = $dictionary.dictionary.app_protocols[protocolName];
    if (!appProtocol.add_to_standard_fields) {
      const disabledSubgroups = [];
      const disabled = Object.keys($dictionary.dictionary.chartTypes).filter((groupName) => {
        const same = groupName !== appProtocol.display_name;
        const group = $dictionary.dictionary.chartTypes[groupName];

        if (same && group.length === 4) {
          const childGroups = Object.keys(group[3]);
          const childGroupFilter = childGroups.filter((childGroup) => childGroup !== appProtocol.display_name);
          if (childGroupFilter.length !== childGroups.length) {
            disabledSubgroups.push(...childGroupFilter);
            return false;
          }
          return same;
        }
        return same;
      });

      return disabled.concat(disabledSubgroups);
    }

    return Object.values($dictionary.dictionary.app_protocols).reduce((acc, protocol) => {
      if (protocol.id !== appProtocol.id) {
        acc.push(protocol.display_name);
      }
      return acc;
    }, []);
  }
  return [];
};
