import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('RegionAggregate'))
class RegionAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="Regions"
        resultsKey="Region"
        insightsQuery="fetchRegionInsights"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'Region',
          aggregateFilters: [
            { metric: ['src_geo_region', 'dst_geo_region'] },
            { metric: ['src_geo_region'] },
            { metric: ['dst_geo_region'] },
            { metric: ['src_geo_region', 'dst_geo_region'] },
            { metric: ['src_geo_region', 'dst_geo_region'] }
          ]
        }}
        chartProps={{
          metrics: {
            Internal: ['dst_geo_region', 'src_geo_region'],
            Inbound: 'src_geo_region',
            Outbound: 'dst_geo_region'
          },
          topxMetricField: 'src|dst_geo_region'
        }}
      />
    );
  }
}

export default RegionAggregate;
