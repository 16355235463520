import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { inject } from 'mobx-react';
import { PopoverPosition } from '@blueprintjs/core';
import { groupBy } from 'lodash';
import { Flex, Popover, Box, Text, Divider, ButtonLink, Icon } from 'core/components';
import DeviceLink from 'app/components/links/DeviceLink';
import InterfaceLink from 'app/components/links/InterfaceLink';
import SiteLink from 'app/components/links/SiteLink';
import { addCommas, greekIt, zeroToText } from 'app/util/utils';

@withTheme
@inject('$dictionary')
class TraceroutePopover extends Component {
  renderIcon = (node) => {
    const { style, isCircle, agent, info } = node;

    if ((agent && agent.icon) || (info && info.agent && info.agent.icon)) {
      return <Icon icon={(agent && agent.icon) || info.agent.icon} width={16} height={16} mr="4px" />;
    }

    return (
      <Box
        position="relative"
        display="inline-block"
        mr="4px"
        borderRadius={isCircle ? '50%' : '2px'}
        width={20}
        height={20}
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          display="block"
          borderRadius={isCircle ? '50%' : '2px'}
          width={20}
          height={20}
          bg={style.color}
          opacity={0.5}
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          display="block"
          borderRadius={isCircle ? '50%' : '2px'}
          width={20}
          height={20}
          border={`2px solid ${style.stroke || style.color}`}
          bg="transparent"
        />
      </Box>
    );
  };

  renderHeading = () => {
    const { data } = this.props;
    const { info } = data;
    const { name } = info;

    return (
      <Flex alignItems="center">
        {this.renderIcon(data)}
        <Text large fontWeight="bold">
          {name}
        </Text>
      </Flex>
    );
  };

  renderTraceCount = () => {
    const { data } = this.props;
    const { info } = data;
    const { traceCount } = info;

    if (traceCount) {
      return (
        <Text as="div" muted mt="4px">
          {`${traceCount} trace${traceCount > 1 ? 's' : ''}`}
        </Text>
      );
    }

    return null;
  };

  renderNodeDetails = () => {
    const { data } = this.props;
    const { info, traces } = data;
    const { asn, location, dns, agent, ingress } = info;
    const agentIp = agent && agent.ip && agent.agent_alias ? agent.ip : null;
    const use_private_ip = agent?.agent_type === 'private';
    const ip = use_private_ip ? traces?.map((trace) => trace.target_ip).join(', ') : agentIp;

    return (
      (location || (asn && asn.name) || (dns && dns.name)) && (
        <>
          <Divider my={1} />
          <Flex flexDirection="column" alignItems="flex-start">
            <Box mb="4px">
              <Text muted>Details</Text>
            </Box>
            {location && (
              <Text as="div" my="2px">
                {location}
              </Text>
            )}
            {asn && asn.name && (
              <Text as="div" my="2px">
                {`AS${asn.id} - ${asn.name}`}
              </Text>
            )}
            {ip && (
              <Text as="div" my="2px">
                {ip}
              </Text>
            )}
            {agent && ingress && ingress.interface_ip_cidr && (
              <Text as="div" my="2px">
                {ingress.interface_ip_cidr}
              </Text>
            )}
            {dns && (
              <Text as="div" my="2px">
                {dns.name}
              </Text>
            )}
          </Flex>
        </>
      )
    );
  };

  renderNodeMetrics = () => {
    const { theme, data, percSuccessThreshold } = this.props;
    const { colors } = theme;

    const { nodeMetrics } = data.info;

    return (
      nodeMetrics && (
        <>
          <Divider my={1} />
          <Flex flexDirection="column" alignItems="flex-start">
            <Box mb="4px">
              <Text muted>Metrics</Text>
            </Box>
            {nodeMetrics.latency && (
              <Box>
                {nodeMetrics.latency.min === nodeMetrics.latency.max ? (
                  <Box my="2px">
                    <Text mr="2px">Latency:</Text>
                    <Text>{`${(nodeMetrics.latency.average / 1000).toLocaleString()}ms`}</Text>
                  </Box>
                ) : (
                  <>
                    <Box my="2px">
                      <Text mr="2px">Max Latency:</Text>
                      <Text>{`${(nodeMetrics.latency.max / 1000).toLocaleString()}ms`}</Text>
                    </Box>
                    <Box my="2px">
                      <Text mr="2px">Avg Latency:</Text>
                      <Text>{`${(nodeMetrics.latency.average / 1000).toLocaleString()}ms`}</Text>
                    </Box>
                    <Box my="2px">
                      <Text mr="2px">Min Latency:</Text>
                      <Text>{`${(nodeMetrics.latency.min / 1000).toLocaleString()}ms`}</Text>
                    </Box>
                  </>
                )}
              </Box>
            )}
            {nodeMetrics.success !== undefined && nodeMetrics.success >= 0 && (
              <Box my="2px">
                <Text mr="2px">Success Rate:</Text>
                <Text color={nodeMetrics.success / 100 > percSuccessThreshold ? colors.success : colors.danger}>
                  {`${zeroToText(nodeMetrics.successes, { fix: 0 })}/${zeroToText(nodeMetrics.total, { fix: 0 })} `}
                  {`(${zeroToText(nodeMetrics.success, { fix: 2 })}%)`}
                </Text>
              </Box>
            )}
          </Flex>
        </>
      )
    );
  };

  renderDevice() {
    const { data } = this.props;
    const { info } = data;
    const { device } = info;

    if (device) {
      return (
        <Box flex="1 50%" px={1}>
          <Text as="div" muted mb="4px">
            Device
          </Text>
          <Text as="div" my="2px">
            <DeviceLink name={info.device.name} />
          </Text>
          {device.metrics && device.metrics.cpuBusy && (
            <Text as="div" my="2px">
              CPU: {device.metrics.cpuBusy}%
            </Text>
          )}
          {device.metrics && device.metrics.cpuBusy && (
            <Text as="div" my="2px">
              Mem: {device.metrics.memUsed}%
            </Text>
          )}
        </Box>
      );
    }

    return null;
  }

  renderSite() {
    const { data } = this.props;
    const { info } = data;
    const { site } = info;

    if (site) {
      return (
        <Text as="div" my="2px">
          <SiteLink siteId={site.id}>{site.title}</SiteLink>
        </Text>
      );
    }

    return null;
  }

  renderInterfaces() {
    const { data, $dictionary } = this.props;
    const { info } = data;
    const { ingress, egress } = info;

    if (ingress || egress) {
      const interfaces = [ingress, egress];

      const rows = interfaces.reduce((acc, intf, index) => {
        if (intf) {
          const label = index === 0 ? 'Ingress' : 'Egress';
          const traffic = index === 0 ? intf.metrics.inBytes : intf.metrics.outBytes;
          const capacity = intf.snmp_speed * 1000000;
          const connectivityType =
            $dictionary.get(`interfaceClassification.connectivityTypes.${intf.connectivity_type}`) || 'None';
          const hop = {};
          const { totalErrors, totalDiscards } = intf.metrics || {};

          hop.flow = ingress && egress && (
            <>
              <Text as="div" muted mb="4px">
                {label}
              </Text>
              <Text as="div" my="2px">
                {ingress.interface_ip_cidr} →
              </Text>
              <Text as="div" my="2px">
                {egress.interface_ip_cidr}
              </Text>
              {traffic && (
                <Text as="div" my="2px">
                  Traffic: {greekIt(traffic, { fix: 2 }).displayFull}
                </Text>
              )}
            </>
          );

          hop.intf = (
            <>
              <Text as="div" muted mb="4px">
                Interface
              </Text>
              <Text as="div" my="2px">
                <InterfaceLink {...intf} />
              </Text>
              {traffic && !!capacity && (
                <Text as="div" my="2px">
                  Utilization: {Math.round((traffic / capacity) * 100)}%
                </Text>
              )}
              {!!capacity && (
                <Text as="div" my="2px">
                  Capacity: {greekIt(capacity, { fix: 0 }).displayFull}
                </Text>
              )}
              <Text as="div" my="2px">
                Type: {connectivityType}
              </Text>
              {totalErrors !== null && totalErrors !== undefined && (
                <Text as="div" my="2px">
                  Errors: {addCommas(totalErrors)}
                </Text>
              )}
              {totalDiscards !== null && totalDiscards !== undefined && (
                <Text as="div" my="2px">
                  Discards: {addCommas(totalDiscards)}
                </Text>
              )}
            </>
          );

          acc.push(hop);
        }

        return acc;
      }, []);

      return (
        <Flex flexWrap="wrap">
          {ingress && egress && (
            <>
              <Divider my={1} flex="1 0 100%" width="100%" />
              <Flex key="hop-flows" flex="1 100%" mx={-1}>
                {rows.map(({ flow }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Text as="div" key={`hop-flows_${index}`} flex="1 50%" px={1}>
                    {flow}
                  </Text>
                ))}
              </Flex>
            </>
          )}
          <Divider my={1} flex="1 0 100%" width="100%" />
          <Flex key="hop-interfaces" flex="1 100%" mx={-1}>
            {rows.map(({ intf }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Text as="div" key={`hop-interfaces_${index}`} flex="1 50%" px={1}>
                {intf}
              </Text>
            ))}
          </Flex>
        </Flex>
      );
    }

    return null;
  }

  renderTextTrace() {
    const { data } = this.props;
    const { traces } = data;

    if (traces && traces.length === 0 && !traces.reduce((acc, curr) => (acc += curr.hopCount))) {
      return null;
    }

    const tracesByTarget = groupBy(traces, 'target_ip');
    const tracesData = Object.entries(tracesByTarget).map(([target_ip, targetTraces]) => {
      // const len = targetTraces.length ? Math.max(...targetTraces.map(trace => (trace ? trace.hopCount : 0))) : 0;
      const traceData = { target_ip, trace: [] };

      traceData.trace = targetTraces[0].probes.reduce((acc, curr) => {
        curr.hops.forEach((hop, hopIndex) => {
          if (!acc[hopIndex]) {
            acc.push({ ttl: hopIndex, timeouts: 0, hops: [] });
          }

          if (hop.ip) {
            acc[hopIndex].hops.push(hop);
          }

          if (hop.timeout) {
            acc[hopIndex].timeouts += 1;
          }
        });

        return acc;
      }, []);

      return traceData;
    });

    return (
      <Flex p={3} flexWrap="wrap" maxWidth="80vw" maxHeight="60vh" overflow="auto">
        <Text as="div" large fontWeight="bold" flex="1 0 100%">
          Text Trace
        </Text>
        {tracesData.map(({ trace, target_ip }, i) => {
          const key = `${target_ip}_${i}`;
          return (
            <Box overflow="auto" key={key} flex="1 0 auto" mr={1} mt={1}>
              <Text as="div" fontSize={12} monospace>
                <Box>traceroute to {target_ip}</Box>
                {trace.map(({ ttl, timeouts, hops }) => (
                  <Flex key={`trace_${ttl}`}>
                    <Box flex={`0 0 ${String(hops.length).length}ch`} mr="2ch" textAlign="right">
                      {ttl}
                    </Box>
                    <Box pr={1}>
                      <div>{Array(timeouts).fill('*').join(' ')}</div>
                      {hops.map(({ ip, latency }) => (
                        <div key={`${ttl}_${ip}_${latency}`}>
                          {ip}
                          <Text ml="2ch">{(latency / 1000).toFixed(3)}ms</Text>
                        </div>
                      ))}
                    </Box>
                  </Flex>
                ))}
              </Text>
            </Box>
          );
        })}
      </Flex>
    );
  }

  renderButtons = () => {
    const { data, openPaths, setPaths, closeOpenPaths } = this.props;
    const { traces } = data;

    if (data.type === 'agent') {
      const pathOpen = openPaths && openPaths.filter((path) => path.agentID === data.id).length > 0;
      const otherPathsOpen = openPaths && openPaths.length - Boolean(pathOpen) > 0;

      return (
        (pathOpen || otherPathsOpen || traces) && (
          <>
            <Divider my={1} />
            <Flex flexDirection="column" alignItems="flex-start">
              {otherPathsOpen && (
                <Text as="div" small my="2px">
                  <ButtonLink onClick={() => setPaths(pathOpen ? [data.id] : [])}>Collapse other paths</ButtonLink>
                </Text>
              )}
              {pathOpen && (
                <Text as="div" small my="2px">
                  <ButtonLink onClick={() => closeOpenPaths(data.id)}>Collapse this path</ButtonLink>
                </Text>
              )}
              {traces && (
                <Popover
                  minimal={false}
                  position={PopoverPosition.RIGHT}
                  content={this.renderTextTrace()}
                  modifiers={{
                    arrow: { enabled: true },
                    flip: { enabled: true },
                    keepTogether: { enabled: true },
                    preventOverflow: { enabled: true, boundariesElement: 'window' }
                  }}
                >
                  <Text as="div" small my="2px">
                    <ButtonLink>Show raw trace data</ButtonLink>
                  </Text>
                </Popover>
              )}
            </Flex>
          </>
        )
      );
    }

    return null;
  };

  getSimpleNodeContent = (text) => (
    <Flex flexDirection="column" alignItems="center">
      <Text>{text}</Text>
    </Flex>
  );

  getComplexNodeContent = () => {
    const { data } = this.props;
    const { ingress, egress, device, site } = data.info;

    return (
      <Flex maxWidth={640}>
        <Box minWidth={220} maxWidth={320}>
          {this.renderHeading()}
          {this.renderNodeMetrics()}
          {this.renderNodeDetails()}
          {this.renderSite()}
          {this.renderTraceCount()}
          {this.renderButtons()}
        </Box>
        {(ingress || egress || device) && (
          <>
            <Divider height="100%" width="1px" mx={3} />
            <Box>
              <Text as="div" mb="4px">
                More Info
              </Text>
              <Text muted small>
                Device and Interface Statistics are for current time <br />
                (not aggregated/ historical)
              </Text>
              {(device || site) && (
                <>
                  <Divider my={1} />
                  <Flex flex="1 100%" mx={-1}>
                    {this.renderDevice()}
                    {site && (
                      <Box flex="1 50%" px={1}>
                        <Text muted mb="4px">
                          Site
                        </Text>
                        {this.renderSite()}
                      </Box>
                    )}
                  </Flex>
                </>
              )}
              {this.renderInterfaces()}
            </Box>
          </>
        )}
      </Flex>
    );
  };

  getNodeContent = () => {
    const { data } = this.props;

    let content = null;

    if (data.type === 'timeout') {
      content = this.getSimpleNodeContent(data.info.name);
    } else if (data.type === 'closed_path') {
      content = this.getSimpleNodeContent('Click to Open');
    } else {
      content = this.getComplexNodeContent();
    }

    return <Box p={3}>{content}</Box>;
  };

  getLinkContent = () => {
    const { theme, data, deltaLatencyThreshold, percSuccessThreshold } = this.props;
    const { colors } = theme;
    const { to, from, linkMetrics, traceCount } = data.info;

    return (
      <Box p={2} minWidth={240} maxWidth={480}>
        <Box mb="4px">
          <Text large fontWeight="bold">
            Link
          </Text>
        </Box>
        {to.nodeMetrics && (
          <Flex flexDirection="column" alignItems="flex-start">
            {linkMetrics &&
              (linkMetrics.min === linkMetrics.max ? (
                <Box my="2px">
                  <Text mr="2px">Latency:</Text>
                  <Text
                    color={linkMetrics.average / 1000 > deltaLatencyThreshold ? colors.danger : colors.success}
                  >{`${(linkMetrics.average / 1000).toLocaleString()}ms`}</Text>
                </Box>
              ) : (
                <>
                  <Text>Latency</Text>
                  <Box my="2px">
                    <Text mr="2px">Max:</Text>
                    <Text color={linkMetrics.max / 1000 > deltaLatencyThreshold ? colors.danger : colors.success}>{`${(
                      linkMetrics.max / 1000
                    ).toLocaleString()}ms`}</Text>
                  </Box>
                  <Box my="2px">
                    <Text mr="2px">Avg:</Text>
                    <Text
                      color={linkMetrics.average / 1000 > deltaLatencyThreshold ? colors.danger : colors.success}
                    >{`${(linkMetrics.average / 1000).toLocaleString()}ms`}</Text>
                  </Box>
                  <Box my="2px">
                    <Text mr="2px">Min:</Text>
                    <Text color={linkMetrics.min / 1000 > deltaLatencyThreshold ? colors.danger : colors.success}>{`${(
                      linkMetrics.min / 1000
                    ).toLocaleString()}ms`}</Text>
                  </Box>
                </>
              ))}
            {to.nodeMetrics.success !== undefined && to.nodeMetrics.success >= 0 && (
              <Box my="2px">
                <Text mr="2px">Success Rate:</Text>
                <Text
                  color={to.nodeMetrics.success / 100 > percSuccessThreshold ? colors.success : colors.danger}
                >{`${zeroToText(to.nodeMetrics.success, { fix: 2 })}%`}</Text>
              </Box>
            )}
          </Flex>
        )}
        <Divider my={1} />
        <Box>
          <Text as="div" muted mb="4px">
            From
          </Text>
          <Flex alignItems="center">
            {this.renderIcon(from)}
            <Text>{from.name}</Text>
          </Flex>
        </Box>
        <Divider my={1} />
        <Box>
          <Text as="div" muted mb="4px">
            To
          </Text>
          <Flex alignItems="center">
            {this.renderIcon(to)}
            <Text>{to.name}</Text>
          </Flex>
        </Box>
        <Divider my={1} />
        <Box muted mt={1}>
          <Text as="div">{`${traceCount} trace${traceCount > 1 ? 's' : ''}`}</Text>
        </Box>
      </Box>
    );
  };

  getContent = () => {
    const { data, onMouseOver, onMouseOut } = this.props;

    if (!data) {
      return null;
    }

    const content = data.to || data.from ? this.getLinkContent() : this.getNodeContent();

    return (
      <Flex onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
        {content}
      </Flex>
    );
  };

  render() {
    const { isOpen, position } = this.props;
    const content = this.getContent();

    return (
      <Popover
        isOpen={isOpen}
        boundary="viewport"
        position={position.anchor}
        content={content}
        target={<></>}
        targetProps={{ style: { pointerEvents: 'none', position: 'absolute', ...position } }}
        minimal={false}
      />
    );
  }
}

export default TraceroutePopover;
