import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { LinkButton, Link } from 'core/components';
import PropTypes from 'prop-types';

@inject('$auth', '$alerting')
@observer
class PolicyLink extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  };

  static defaultProps = {
    children: 'Configure Policy'
  };

  get canEditPolicy() {
    const { $auth } = this.props;

    return $auth.hasPermission('alerts.policies.edit');
  }

  render() {
    const { $auth, $alerting, as, children, id, model, tab, ...rest } = this.props;

    const isButton = as === 'Button';
    const policyModel = model || (id && $alerting.policyCollection.get(id));

    const to = this.canEditPolicy && policyModel ? policyModel.manageUrl : '/v4/alerting/policies';

    const LinkComponent = isButton ? LinkButton : Link;

    if ($auth.isDemoUser) {
      return null;
    }

    return (
      <LinkComponent to={to} state={{ selectedTabId: tab }} {...rest}>
        {children}
      </LinkComponent>
    );
  }
}

export default PolicyLink;
