import React, { useState } from 'react';

import { Card, Text, Spinner, Suspense, Box } from 'core/components';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import { generateSnmpQuery, generateStreamingQuery } from './InterfaceMetricQueries';
import { MetricRollupValues } from './MetricRollupValues';
import { MetricVisualization, formatRollupNumber } from './MetricVisualization';

const viewProps = {
  showTooltips: true,
  flex: 1,
  height: 'calc(100% - 5px)',
  showNativeLegend: true,
  chartConfig: {
    yAxis: [
      {
        title: {
          text: 'Utilization In %'
        }
      },
      {
        title: {
          text: 'Utilization Out %'
        }
      }
    ]
  }
};

const scaleToCapacity = (value, capacity) => (value / (capacity * 1_000_000)) * 100; // we want bits not Mbits, hence the 1_000_000
const CapacitySTVisualization = ({ title, subTitle, query, capacity = 100, metricSuffix }) => {
  const [avgValue, setAvgValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [lastValue, setLastValue] = useState(0);

  const [avgSecondaryValue, setAvgSecondaryValue] = useState(0);
  const [maxSecondaryValue, setMaxSecondaryValue] = useState(0);
  const [lastSecondaryValue, setLastSecondaryValue] = useState(0);

  return (
    <Card p={2} width="100%" height="360px" flex={1} position="relative">
      <Text as="h3" m={0}>
        {title}
      </Text>
      <Text small muted>
        {subTitle}
      </Text>

      <LightweightDataViewWrapper
        allowCache
        query={query}
        onQueryComplete={({ results, dataview }) => {
          const data = results.toJS();

          if (data.length) {
            // should only have one query
            const dataSet = data[0];

            // outsort
            setAvgValue(scaleToCapacity(dataSet[query.aggregateTypes[0]], capacity));
            setMaxValue(scaleToCapacity(dataSet[query.aggregateTypes[1]], capacity));
            setLastValue(scaleToCapacity(dataSet[query.aggregateTypes[2]], capacity));

            // secondaryOutsort
            setAvgSecondaryValue(scaleToCapacity(dataSet[query.aggregateTypes[3]], capacity));
            setMaxSecondaryValue(scaleToCapacity(dataSet[query.aggregateTypes[4]], capacity));
            setLastSecondaryValue(scaleToCapacity(dataSet[query.aggregateTypes[5]], capacity));

            // update the raw data, dividing the actual usage (in bits) by the total capacity
            const queryResultCollection = dataview.queryBuckets.activeBuckets[0].queryResults;
            queryResultCollection.each((queryResultModel) => {
              const dataClone = queryResultModel.get('rawData');

              // rawData will have 2 keys (names dependent on the query)
              // loop over both to update the raw data
              Object.entries(dataClone).forEach((series) => {
                const [seriesName] = series;

                dataClone[seriesName].flow.forEach((seriesData, i) => {
                  const rawFlowData = dataClone[seriesName].flow[i];

                  // scale the data to "% of total capacity"
                  rawFlowData[1] = scaleToCapacity(rawFlowData[1], capacity);
                });
              });

              // reassign the rawData now that it's updated
              queryResultModel.set('rawData', dataClone);
            });

            // Nesting the DVW inside the LWDVW caused some sizing issues
            // so force it to recalculate before anyone notices
            setTimeout(() => dataview.reflow(), 250);
          }
        }}
      >
        {({ loading, dataview }) => (
          <Suspense loading={loading} fallback={<Spinner pt={1} size={24} />}>
            <DataViewWrapper dataview={dataview} viewProps={viewProps} />
          </Suspense>
        )}
      </LightweightDataViewWrapper>

      <Box direction="row" mt={1} mr={2} style={{ position: 'absolute', right: 0, top: 0, gap: 20 }} width="50%">
        <MetricRollupValues
          avgValue={formatRollupNumber(avgValue, undefined, metricSuffix)}
          maxValue={formatRollupNumber(maxValue, undefined, metricSuffix)}
          lastValue={formatRollupNumber(lastValue, undefined, metricSuffix)}
          avgSecondaryValue={formatRollupNumber(avgSecondaryValue, undefined, metricSuffix)}
          maxSecondaryValue={formatRollupNumber(maxSecondaryValue, undefined, metricSuffix)}
          lastSecondaryValue={formatRollupNumber(lastSecondaryValue, undefined, metricSuffix)}
        />
      </Box>
    </Card>
  );
};

export const UtilizationMetricPair = ({
  title,
  snmpQuery,
  snmpResponse,
  streamingQuery,
  streamingResponse,
  selectedInterface,
  unit,
  metricSuffix
}) => {
  const snmpQueryObj = generateSnmpQuery(selectedInterface, snmpQuery);
  const streamingQueryObj = generateStreamingQuery(selectedInterface, streamingQuery);

  return (
    <>
      <MetricVisualization
        title={title}
        subTitle="SNMP"
        query={snmpQueryObj}
        unit={unit}
        metricSuffix={metricSuffix}
        responseKeys={snmpResponse}
      />

      {selectedInterface.get('device').stEnabled && (
        <CapacitySTVisualization
          title={title}
          subTitle="Streaming Telemetry"
          query={streamingQueryObj}
          capacity={selectedInterface.attributes.snmp_speed}
          metricSuffix={metricSuffix}
          responseKeys={streamingResponse}
        />
      )}
    </>
  );
};
