import React, { Component, Suspense } from 'react';
import { observer } from 'mobx-react';
import { Box, Spinner } from 'core/components';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import DataViewModel from 'app/stores/query/DataViewModel';
import ViewInExplorerMenuItem from 'app/components/dataviews/tools/ViewInExplorerMenuItem';
import QueryModel from 'app/stores/query/QueryModel';
import { checkPreventQuery } from 'app/components/dataviews/dependencies/dependencyChecks';
import withConfigOptions from '../withConfigOptions';
import WidgetFrame from '../WidgetFrame';

@withConfigOptions
@observer
class DataExplorerQueryWidget extends Component {
  state = {
    loading: true,
    dataview: new DataViewModel()
  };

  componentDidMount() {
    const { config } = this.props;
    const { dataview } = this.state;

    const { hash, query } = config;
    if (hash) {
      dataview.initializeHash(config.hash);
    } else if (query) {
      const queryModel = QueryModel.create(query);
      const serializedQuery = queryModel.serialize();
      checkPreventQuery(serializedQuery, dataview);
      dataview.setQuery(serializedQuery);
    } else {
      console.warn('DataExplorerQueryWidget contains no hash or query. Config:', config);
    }
  }

  renderWidgetContent = () => {
    const { canCustomize, onRemove } = this.props;
    const { dataview } = this.state;

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        menuOptions={<ViewInExplorerMenuItem dataview={dataview} />}
        onRemove={onRemove}
        title={dataview.title}
      >
        <DataViewWrapper
          dataview={dataview}
          viewProps={{ showTooltips: true, height: '100%', flex: 1, showNativeLegend: true }}
          allowCache
        />
      </WidgetFrame>
    );
  };

  render() {
    const { loading } = this.state;

    return (
      <Suspense
        loading={loading}
        fallback={
          <Box pt={3}>
            <Spinner size={24} />
          </Box>
        }
      >
        {this.renderWidgetContent()}
      </Suspense>
    );
  }
}

export default DataExplorerQueryWidget;
