import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import ViewInExplorerMenuItem from 'app/components/dataviews/tools/ViewInExplorerMenuItem';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import WidgetFrame from '../WidgetFrame';

const tabbedChartExclChartHeight = 141;
const allCloudTypes = ['aws_subnet', 'gcp_subnet', 'azure_subnet', 'oci_subnet'];
const deviceTypeFilterMap = {
  AWS: 'aws_subnet',
  GCP: 'gcp_subnet',
  OCI: 'oci_subnet',
  Azure: 'azure_subnet'
};

@inject('$query')
@observer
class CloudTrafficOverviewWidget extends Component {
  filterQuery = () => {
    const { config } = this.props;
    let deviceTypes = allCloudTypes;
    if (config?.cloudFilter) {
      deviceTypes = [deviceTypeFilterMap[config.cloudFilter]];
    }
    return { all_devices: false, device_types: deviceTypes };
  };

  render() {
    const { $query, canCustomize, onRemove, height, approxWidth } = this.props;
    const title = 'Cloud Traffic Overview';
    const tooltip =
      'The Cloud Traffic Overview is useful to see all of your cloud traffic in one place and jump into the Data Explorer to launch queries.';

    const queryOverrides = this.filterQuery();
    const baseQuery = $query.get('inboundOutboundInternalQuery', queryOverrides);
    baseQuery.filterDimensionsEnabled = false;
    baseQuery.filterDimensionSort = false;
    baseQuery.metric = ['simple_trf_prof'];

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        menuOptions={<ViewInExplorerMenuItem query={baseQuery} />}
        onRemove={onRemove}
        title={title}
        tooltip={tooltip}
      >
        <TabbedChart
          height={height - 41 - tabbedChartExclChartHeight}
          overrides={queryOverrides}
          availableWidth={approxWidth}
          viewInExplorer={false}
        />
      </WidgetFrame>
    );
  }
}

export default CloudTrafficOverviewWidget;
