import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { MdCompareArrows } from 'react-icons/md';
import { GiRadarSweep } from 'react-icons/gi';
import { IoIosGitNetwork } from 'react-icons/io';
import { FiInfo, FiBarChart, FiGrid } from 'react-icons/fi';
import { ReactComponent as PeeringdbIcon } from 'app/assets/logos/peeringdb-icon.svg';

import { Box, FlexColumn } from 'core/components';
import PageTabs, { PageTab } from 'app/components/page/PageTabs';

const ContentWrapper = ({ children, ...rest }) => (
  <Box mt="-16px" pt={2} width="100%" {...rest}>
    {children}
  </Box>
);

@inject('$app')
@withRouter
@observer
class NetworkExplorerTabs extends Component {
  static defaultProps = {
    initialSelectedTab: 'traffic'
  };

  get activeTab() {
    const { match } = this.props;
    return match.params.tab;
  }

  render() {
    const {
      $app,
      trafficTabContent,
      topologyTabContent,
      syntheticTabContent,
      moreInfoTabContent,
      metricsTabContent,
      interfacesTabContent,
      componentsTabContent,
      bgpPeersTabContent,
      capacityTabContent,
      peeringDbTabContent,
      initialSelectedTab,
      ...rest
    } = this.props;

    // If there will only be a traffic tab, just show traffic tab's contents
    if (
      !topologyTabContent &&
      !syntheticTabContent &&
      !moreInfoTabContent &&
      !metricsTabContent &&
      !peeringDbTabContent
    ) {
      return trafficTabContent;
    }

    // Just render selected tab content if this is an export
    if ($app.isExport) {
      if (this.activeTab === 'traffic') {
        return (
          <>
            <h2>Traffic</h2>
            <ContentWrapper>{trafficTabContent}</ContentWrapper>
          </>
        );
      }
      if (this.activeTab === 'topo' && topologyTabContent) {
        return (
          <>
            <h2>Kentik Map</h2>
            <ContentWrapper>{topologyTabContent}</ContentWrapper>
          </>
        );
      }
      if (this.activeTab === 'synthetics' && syntheticTabContent) {
        return (
          <>
            <h2>Synthetics</h2>
            <ContentWrapper>{syntheticTabContent}</ContentWrapper>
          </>
        );
      }
      if (this.activeTab === 'components' && syntheticTabContent) {
        return (
          <>
            <h2>Components</h2>
            <ContentWrapper>{componentsTabContent}</ContentWrapper>
          </>
        );
      }
      if (this.activeTab === 'more' && moreInfoTabContent) {
        return (
          <>
            <h2>More Info</h2>
            <ContentWrapper>{moreInfoTabContent}</ContentWrapper>
          </>
        );
      }
      if (this.activeTab === 'metrics' && metricsTabContent) {
        return (
          <>
            <h2>Metrics</h2>
            <ContentWrapper>{metricsTabContent}</ContentWrapper>
          </>
        );
      }
      if (this.activeTab === 'capacity' && capacityTabContent) {
        return (
          <>
            <h2>Capacity</h2>
            <ContentWrapper>{capacityTabContent}</ContentWrapper>
          </>
        );
      }
      if (this.activeTab === 'peeringdb' && peeringDbTabContent) {
        return (
          <>
            <h2>PeeringDB Info</h2>
            <ContentWrapper>{peeringDbTabContent}</ContentWrapper>
          </>
        );
      }
      return null;
    }

    return (
      <PageTabs
        defaultSelectedTabId={initialSelectedTab}
        onChange={this.handleTabChange}
        renderActiveTabPanelOnly
        className="do-not-print"
        useUrlPathParam
        {...rest}
      >
        <PageTab
          id="traffic"
          panel={<ContentWrapper>{trafficTabContent}</ContentWrapper>}
          icon={MdCompareArrows}
          title="Traffic"
        />
        {topologyTabContent && (
          <PageTab
            id="topo"
            panel={<ContentWrapper>{topologyTabContent}</ContentWrapper>}
            icon={IoIosGitNetwork}
            title="Kentik Map"
          />
        )}
        {syntheticTabContent && (
          <PageTab
            id="synthetics"
            panel={<ContentWrapper>{syntheticTabContent}</ContentWrapper>}
            icon={GiRadarSweep}
            title="Synthetics"
          />
        )}
        {moreInfoTabContent && (
          <PageTab
            id="more"
            panel={<ContentWrapper>{moreInfoTabContent}</ContentWrapper>}
            icon={FiInfo}
            title="More Info"
          />
        )}
        {metricsTabContent && (
          <PageTab
            id="metrics"
            panel={<ContentWrapper>{metricsTabContent}</ContentWrapper>}
            icon={FiBarChart}
            title="Metrics"
          />
        )}
        {interfacesTabContent && (
          <PageTab id="interfaces" panel={<ContentWrapper>{interfacesTabContent}</ContentWrapper>} title="Interfaces" />
        )}

        {componentsTabContent && (
          <PageTab id="components" panel={<ContentWrapper>{componentsTabContent}</ContentWrapper>} title="Components" />
        )}

        {bgpPeersTabContent && (
          <PageTab id="bgpPeers" panel={<ContentWrapper>{bgpPeersTabContent}</ContentWrapper>} title="BGP Peers" />
        )}

        {capacityTabContent && (
          <PageTab
            id="capacity"
            panel={<ContentWrapper>{capacityTabContent}</ContentWrapper>}
            icon={FiGrid}
            title="Capacity"
          />
        )}
        {peeringDbTabContent && (
          <PageTab
            id="peeringdb"
            panel={
              <ContentWrapper as={FlexColumn} flex="1 auto">
                {peeringDbTabContent}
              </ContentWrapper>
            }
            icon={PeeringdbIcon}
            title="PeeringDB Info"
          />
        )}
      </PageTabs>
    );
  }
}

export default NetworkExplorerTabs;
