import { action, configure } from 'mobx';
import { createMemoryHistory } from 'history';
import { createHistogram, initializeMetrics } from 'core/util/uiMetrics';

import Collection from 'core/model/Collection';
import Socket from 'core/util/Socket';

import $alerting from 'app/stores/alerting/$alerting';
import $app from 'app/stores/$app';
import $auditLog from 'app/stores/audit/$auditLog';
import $auth from 'app/stores/$auth';
import $capacity from 'app/stores/capacityPlan/$capacity';
import $clouds from 'app/stores/clouds/$clouds';
import $cloudExportWizard from 'app/stores/clouds/cloudExport/$cloudExportWizard';
import $cdns from 'app/stores/cdn/$cdns';
import $colors from 'app/stores/colors/$colors';
import $comments from 'app/stores/comments/$comments';
import $cost from 'app/stores/cost/$cost';
import $companySettings from 'app/stores/companySettings/$companySettings';
import $credentials from 'app/stores/credential/$credentials';
import $customers from 'app/stores/customer/$customers';
import $customApplications from 'app/stores/customApplications/$customApplications';
import $customDimensions from 'app/stores/customDimension/$customDimensions';
import $dashboard from 'app/stores/dashboard/$dashboard';
import $dashboards from 'app/stores/dashboard/$dashboards';
import $dataviews from 'app/stores/$dataviews';
import $decks from 'app/stores/decks/$decks';
import $dictionary from 'app/stores/$dictionary';
import $discovery from 'app/stores/discovery/$discovery';
import $devices from 'app/stores/device/$devices';
import $explorer from 'app/stores/explorer/$explorer';
import $exports from 'app/stores/$exports';
import $flowTags from 'app/stores/flowTags/$flowTags';
import $geo from 'app/stores/$geo';
import $fpa from 'app/stores/$fpa';
import $hybridMap from 'app/stores/hybrid/$hybridMap';
import $interfaceClass from 'app/stores/interface/$interfaceClass';
import $interfaceGroup from 'app/stores/interfaceGroup/$interfaceGroup';
import $interfaces from 'app/stores/interface/$interfaces';
import $insights from 'app/stores/insight/$insights';
import $journeys from 'app/stores/ai/$journeys';
import $kproxyAgents from 'app/stores/kproxyAgent/$kproxyAgents';
import $logging from 'app/stores/$logging';
import $lookups from 'app/stores/$lookups';
import $messages from 'app/stores/$messages';
import $mkp from 'app/stores/mkp/$mkp';
import $moduleConfig from 'app/stores/moduleConfig/$moduleConfig';
import $metrics from 'app/stores/metrics/$metrics';
import $mitigations from 'app/stores/mitigations/$mitigations';
import $nav from 'app/stores/nav/$nav';
import $networkBi from 'app/stores/networkBi/$networkBi';
import $networkClass from 'app/stores/$networkClass';
import $networkHealth from 'app/stores/networkHealth/$networkHealth';
import $notifications from 'app/stores/notifications/$notifications';
import $peering from 'app/stores/peering/$peering';
import $ott from 'app/stores/ott/$ott';
import $query from 'app/stores/query/$query';
import $queryEditor from 'app/stores/$queryEditor';
import $rawFlow from 'app/stores/rawFlow/$rawFlow';
import $search from 'app/stores/$search';
import $setup from 'app/stores/$setup';
import $savedFilters from 'app/stores/savedFilter/$savedFilters';
import $savedViews from 'app/stores/savedView/$savedViews';
import $sites from 'app/stores/site/$sites';
import $siteMarkets from 'app/stores/siteMarket/$siteMarkets';
import $subscriptions from 'app/stores/subscriptions/$subscriptions';
import $sudo from 'app/stores/sudo/$sudo';
import $topo from 'app/stores/topology/$topo';
import $traffic from 'app/stores/trafficEng/$traffic';
import $marketIntel from 'app/stores/marketIntel/$marketIntel';
import $tour from 'app/stores/tour/$tour';
import $users from 'app/stores/user/$users';
import $customerAnalytics from 'app/stores/customerAnalytics/$customerAnalytics';
import $tabs from 'app/components/detailTabs/$tabs';
import $syn from 'app/stores/synthetics/$syn';
import $bgp from 'app/stores/synthetics/$bgp';
import $labels from 'app/stores/label/$labels';
import $recentlyViewed from 'app/stores/recentlyViewed/$recentlyViewed';
import $apiTester from 'app/stores/apiTester/$apiTester';
import $suppressions from 'app/stores/suppressions/$suppressions';
import $sharedLinks from 'app/stores/sharedLinks/$sharedLinks';
import $rbac from 'app/stores/rbac/$rbac';
import $kentikAgents from 'app/stores/kentikAgent/$kentikAgents';
import { isPathSharedLink } from 'app/util/sharedLink';
import { initializeOpenTelemetry } from 'core/util/uiOtel';
import { initializeSentry } from 'core/util/uiSentry';

// Force all observables everywhere to be modified only via actions
configure({
  // enforceActions: 'observed'
});

class Store {
  constructor() {
    Object.assign(this, {
      $alerting,
      $app,
      $auditLog,
      $auth,
      $capacity,
      $cdns,
      $colors,
      $comments,
      $companySettings,
      $cost,
      $clouds,
      $cloudExportWizard,
      $credentials,
      $customers,
      $customApplications,
      $customDimensions,
      $dashboard,
      $dashboards,
      $dataviews,
      $decks,
      $devices,
      $dictionary,
      $discovery,
      $explorer,
      $exports,
      $flowTags,
      $geo,
      $fpa,
      $hybridMap,
      $interfaceClass,
      $interfaceGroup,
      $interfaces,
      $insights,
      $journeys,
      $kproxyAgents,
      $lookups,
      $logging,
      $messages,
      $metrics,
      $mitigations,
      $moduleConfig,
      $mkp,
      $nav,
      $networkBi,
      $networkClass,
      $networkHealth,
      $notifications,
      $ott,
      $query,
      $queryEditor,
      $rawFlow,
      $peering,
      $search,
      $savedFilters,
      $savedViews,
      $setup,
      $sites,
      $siteMarkets,
      $subscriptions,
      $sudo,
      $topo,
      $traffic,
      $marketIntel,
      $tour,
      $users,
      $customerAnalytics,
      $tabs,
      $syn,
      $bgp,
      $labels,
      $recentlyViewed,
      $apiTester,
      $suppressions,
      $sharedLinks,
      $rbac,
      $kentikAgents
    });

    this.getStores().forEach((store) => (store.store = this));
  }

  /**
   * Common app initialization logic here, prevents duplication between login and AppWrapper's componentDidMount
   * function when user is already authenticated and login's logic after successful authentication.
   * @returns {Promise}
   */
  async initializeApp(options = {}) {
    initializeMetrics({ $auth: this.$auth, prefix: 'uiBrowser_' });
    const h1 = createHistogram({
      name: 'store_loading_duration_seconds',
      description: '$store initializing in seconds'
    });
    h1.start();
    return initializeOpenTelemetry(this.$auth).then(() =>
      this.initialize(options).then(() => {
        if (!options.minimal) {
          initializeSentry($auth);
          this.$auth.initializePendo();
          this.$auth.socketSessionSubscribe();
          this.$sudo.sudoSubscribe();
        }
        h1.end();
      })
    );
  }

  initializeTesting(requestedStores = [], history = createMemoryHistory()) {
    this.setHistory(history);

    const defaultStores = [
      '$dictionary',
      '$app',
      '$comments',
      '$devices',
      '$insights',
      '$labels',
      '$notifications',
      '$savedFilters',
      '$syn'
      // '$sites' TODO - need countries api data
    ];
    const stores = [...new Set([...defaultStores, ...requestedStores])].map((key) => this[key]);
    return Promise.all(
      stores.map((store) => {
        if (store.initialize) {
          return store.initialize();
        }

        return Promise.resolve(true);
      })
    );
  }

  logout = async (code) => {
    if (code === 401) {
      const lastVisitedPage = this.history.location?.pathname;
      if (lastVisitedPage) {
        localStorage.setItem('kentik.lastVisitedPage', lastVisitedPage);
      }
    } else {
      localStorage.removeItem('kentik.lastVisitedPage');
    }
    const isMkpSso = $app.isSubtenant && $auth.getActiveUserProperty('authenticationType') === 'sso';

    $auth.logout().then((loggedOut) => {
      if (loggedOut) {
        this.destroy();

        if (isMkpSso) {
          // Force going back to v3 login that has proper SSO login capabilities if MKP+SSO.
          window.location = '/';
        }

        // manage pt-dark here so that the login/signup pages don't get contaminated
        if (document.body.classList.contains('pt-dark')) {
          document.body.classList.remove('pt-dark');
        }
      }
    });
  };

  getStores() {
    return Object.keys(this).map((key) => this[key]);
  }

  getCollections(store) {
    return Object.keys(store)
      .map((key) => store[key])
      .filter((value) => value instanceof Collection && value.reset);
  }

  setHistory(history) {
    this.history = history;
    this.getStores().forEach((store) => (store.history = history));
  }

  @action
  initialize(options = {}) {
    const { minimal = false } = options;
    const allStores = this.getStores();
    let stores = [];

    if (minimal) {
      stores = [$devices, $sites, $labels, $query, $dashboards, $cost, $insights, $syn, $suppressions, $metrics];
    } else if (isPathSharedLink()) {
      stores = [$explorer, $query, $labels, $syn, $metrics];
    } else {
      stores = allStores.filter((store) => store !== $dictionary && store !== $auth);
    }

    this.socket = new Socket();
    return $dictionary.initialize().then(
      action(() =>
        Promise.all(
          // ideally store initialize functions would not throw errors, but we do not want to block the entire app from functioning if one is thrown
          stores.map((store) => store.initialize?.()?.catch((e) => console.error('error initializing store', store, e)))
        )
      )
    );
  }

  @action
  destroy() {
    // we're destroying the whole app state, so destroy socket
    if (this.socket) {
      this.socket.destroy();
    }

    this.getStores().forEach((store) => {
      // Call destroy on store if present if more specific cleanup required.
      if (store.destroy) {
        store.destroy();
      }

      // Reset all collections in store
      this.getCollections(store).forEach((collection) => collection.reset({ hard: true }));
    });
  }
}

const store = new Store();

window.$store = store;

export default store;
