import ExpandableRow from 'app/components/admin/ExpandableRow';
import { greekIt } from 'app/util/utils';
import { Flex, Icon, Spinner, Text, Tag } from 'core/components';
import { Table } from 'core/components/table';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import Markdown from 'app/views/core/journeys/Markdown';
import styled from 'styled-components';
import TreeTable from './fpa/TreeTable';

const MarkdownWrapper = styled.div`
  p,
  ul {
    line-height: 20px;
  }
`;

const SummaryRenderer = observer(({ model }) => {
  if (model.get('isLoadingSummary')) {
    return (
      <Flex alignItems="center" gap="4px">
        <Spinner size={10} />
        <Text muted>Loading...</Text>
      </Flex>
    );
  }

  return (
    <MarkdownWrapper>
      <Markdown>{model.get('summary')}</Markdown>
    </MarkdownWrapper>
  );
});

@observer
class ChangePointDetectionResultsTable extends Component {
  getColumns() {
    const { dataview } = this.props;

    return [
      {
        label: '#',
        name: 'index',
        id: 'index',
        sortable: false,
        width: 35,
        renderer: ({ model }) => model.index + 1
      },
      {
        label: 'Type',
        name: 'type',
        id: 'type',
        sortable: false,
        width: 80,
        renderer: ({ value }) => {
          if (!value) {
            return '';
          }

          return <Text fontWeight="medium">{value.charAt(0).toUpperCase() + value.slice(1)}</Text>;
        }
      },
      {
        label: 'Change',
        name: 'metricChange',
        id: 'metricChange',
        sortable: false,
        width: 140,
        renderer: ({ model }) => {
          // this value is `rec_tree.root.metric`
          const unit = model.get('rec_tree.root.units');
          const value = model.get('rec_tree.root.metric');

          // the PCA service expects "bytes" as the metric, but we want to display bits.
          const { displayFull } = greekIt(value, {
            fix: 2,
            suffix: `${unit === 'bytes' ? 'bits' : unit}/s`
          });

          const intent = ['spike', 'jump'].includes(model.get('type')) ? 'success' : 'danger';

          return (
            <Tag minimal intent={intent}>
              <Flex alignItems="center" gap="4px">
                <Icon icon={['spike', 'jump'].includes(model.get('type')) ? 'caret-up' : 'caret-down'} color={intent} />
                <Text fontWeight="medium">{displayFull}</Text>
              </Flex>
            </Tag>
          );
        }
      },
      {
        label: 'Time',
        name: 'change_point.seconds',
        id: 'change_point',
        sortable: false,
        width: 125,
        renderer: ({ value }) => {
          const ts = moment.unix(value);
          const time_format = dataview.queryBuckets.selectedQuery.get('time_format');
          return time_format === 'Local'
            ? ts.format(DEFAULT_DATETIME_FORMAT)
            : ts.utc().format(DEFAULT_DATETIME_FORMAT);
        }
      },
      {
        label: 'Summary',
        name: 'summary',
        id: 'summary',
        sortable: false,
        wrapText: true,
        renderer: ({ model }) => <SummaryRenderer model={model} />
      }
    ];
  }

  render() {
    const { collection, onViewInDataExplorer } = this.props;
    return (
      <Table
        flexed
        onRowClick={(model) => {
          if (!model.isSelected) {
            model.select();
          } else {
            model.deselect();
          }
        }}
        collection={collection}
        columns={this.getColumns()}
        // eslint-disable-next-line react/no-unstable-nested-components
        expandedRowRenderer={(model) => (
          <ExpandableRow rowExpansionHeight={500} p={0} pt={1}>
            <TreeTable collection={model.get('nestedTreeCollections')} onViewInDataExplorer={onViewInDataExplorer} />
          </ExpandableRow>
        )}
      />
    );
  }
}

export default ChangePointDetectionResultsTable;
