import { capitalize, kebabCase } from 'lodash';

export const convertToReadableId = (id, cloud) => {
  // Azure ids are super long, need to cut subscription portions to make it look nicely
  if (cloud === 'azure') {
    const idArr = id.split('/');
    return idArr[idArr.length - 1];
  }

  return id;
};

/** will remove trailing S, example Subnets -> Subnet */
export const convertToReadableType = (type) => {
  const kebabString = kebabCase(type.replace(/s+$/, ''));
  return kebabString
    .split('-')
    .map((text) => capitalize(text))
    .join(' ');
};
