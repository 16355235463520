export default function addFilter(filtersObj, filterField, operator, filterValue, atIndex = 0) {
  if (filtersObj && filtersObj.filterGroups && filtersObj.filterGroups[atIndex]) {
    filtersObj.filterGroups[atIndex].filters.push({
      filterField,
      operator,
      filterValue
    });
  }
  return filtersObj;
}
