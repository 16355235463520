import React from 'react';
import BorderedTabs from 'core/components/BorderedTabs';
import BorderedTab from 'core/components/BorderedTab';

const PageTabs = ({ children, ...rest }) => (
  <BorderedTabs pl="18px" mx={-3} width="calc(100% + 48px)" maxWidth="calc(100% + 48px)" {...rest}>
    {children}
  </BorderedTabs>
);

export const PageTab = BorderedTab;

export default PageTabs;
