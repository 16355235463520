import React, { Component } from 'react';

import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import Flex from 'core/components/Flex';
import Text from 'core/components/Text';
import Icon from 'core/components/Icon';
import Popover from 'core/components/Popover';

const CopyBox = styled(Flex)`
  overflow: visible;

  .copy-icon {
    visibility: hidden;
  }

  &:hover {
    .copy-icon {
      visibility: visible;
    }
  }
`;

export default class CopyText extends Component {
  static defaultProps = {
    iconLeft: false,
    copyConfirmationText: 'Copied!',
    timeout: undefined
  };

  state = {
    copied: false
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  onClick = () => {
    const { text, options } = this.props;

    copy(text, options);

    // change button text on success, then change back
    this.setState({ copied: true });
    this.timeout = setTimeout(() => {
      this.setState({ copied: false });
    }, 1000);
  };

  renderText = () => {
    const { text, textProps, hideText } = this.props;
    if (hideText) {
      return null;
    }

    return (
      <Text as="div" style={{ wordBreak: 'break-word' }} {...textProps}>
        {text}
      </Text>
    );
  };

  render() {
    const { children, text, copyConfirmationText, iconLeft, textProps, hideText, ...rest } = this.props;
    const { copied } = this.state;

    if (!text || text === '' || text?.length === 0) {
      return null;
    }

    return (
      <CopyBox alignItems="center" justifyContent="flex-end" onClick={this.onClick} {...rest}>
        <Popover
          isOpen={copied}
          usePortal
          fill
          position={iconLeft ? 'left' : 'right'}
          minimal={false}
          modifiers={{
            offset: { enabled: false },
            flip: { enabled: false },
            preventOverflow: { enabled: true, boundariesElement: 'window' }
          }}
          content={
            <Text as="div" fontWeight="bold" muted p={1}>
              {copyConfirmationText}
            </Text>
          }
        >
          <Flex justifyContent="flex-start" alignItems="center" cursor="pointer">
            {iconLeft && <Icon icon="duplicate" color="gray5" iconSize={14} className="copy-icon" pr={1} />}
            {children || this.renderText()}
            {!iconLeft && <Icon icon="duplicate" color="gray5" iconSize={14} className="copy-icon" pl={1} />}
          </Flex>
        </Popover>
      </CopyBox>
    );
  }
}
