/*
  Offers centralized management of alarm suppression form fields that can be used
  for both alerting 1.0 and 2.0 systems.

  Alerting 1.0 did not have restrictions on start time, the system could assume that the suppression would start 'now'
  However alerting 2.0 requires the start time to be at least 15 minutes past 'now'
  As a result, start time is given a cushion of 20 minutes which allows 5 minutes to complete the configuration without reporting errors from client/server-side validation

  Both the start and expiration times are transformed to cut off milliseconds and produce iso date strings

  These form fields are currently used in:
    - silent mode
    - edit test > alerting and notifications
    - agent details maintenance mode

  @TODO
    default start and expiration times will be api-driven in the future
*/

import moment from 'moment';
import Validator from 'validatorjs';

/*
  An alarm suppression cannot be saved with a start time less than 15 minutes from submission
  This custom validator will ensure of a valid start time but only evaluate when the field is dirty
  In the case of an update that requests to extend an alarm suppression's end time, the api will
  perform a patch, allowing us to preserve the start time
*/
Validator.register(
  'alarmSuppressionStartTime',
  function alarmSuppressionStartTime(value) {
    const { initialValue } = this.validator.input;

    if (initialValue !== value) {
      const startTime = moment(value);
      const minTime = moment().add(15, 'minutes');

      return startTime.isSameOrAfter(minTime);
    }

    return true;
  },
  'Start time must be at least 15 minutes from now'
);

const fields = {
  startTime: {
    defaultValue: moment().add(20, 'minutes').toDate(),
    transform: {
      in: (value) => moment(value).milliseconds(0).toISOString()
    }
  },

  expirationTime: {
    label: 'Expires',
    defaultValue: moment().add(7, 'days').add(20, 'minutes').toDate(),
    transform: {
      in: (value) => {
        if (value) {
          return moment(value).milliseconds(0).toISOString();
        }

        return value; // this will be undefined when neverExpire is true
      }
    }
  },

  neverExpire: {
    defaultValue: false
  }
};

export default fields;
