import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { GiRadarSweep } from 'react-icons/gi';
import { Flex } from 'core/components';
import SidebarCard from 'app/components/landing/SidebarCard';
import ModuleLinkButton from 'app/components/landing/ModuleLinkButton';
import LargeLabeledValue from '../LargeLabeledValue';

@inject('$syn')
@observer
class SyntheticsCard extends Component {
  constructor(props) {
    super(props);
    const { $syn } = props;
    $syn.tests.loadTestHealth();
  }

  render() {
    const { $syn } = this.props;
    const { testHealthBreakdown } = $syn.tests;

    return (
      <SidebarCard
        icon={GiRadarSweep}
        title="Synthetics"
        loading={$syn.tests.healthLoading}
        isConfigured={$syn.tests.size > 0}
        notConfiguredText="You are not currently monitoring your network with Synthetics."
        to="/v4/synthetics/dashboard"
        mb={2}
      >
        <Flex mb={2}>
          {testHealthBreakdown.critical && (
            <LargeLabeledValue
              label="Critical"
              value={testHealthBreakdown.critical}
              valueSize={18}
              valueColor="danger"
              mr={3}
            />
          )}
          {testHealthBreakdown.warning && (
            <LargeLabeledValue
              label="Warning"
              value={testHealthBreakdown.warning}
              valueSize={18}
              valueColor="warning"
              mr={3}
            />
          )}
          {testHealthBreakdown.healthy && (
            <LargeLabeledValue
              label="Healthy"
              value={testHealthBreakdown.healthy}
              valueSize={18}
              valueColor="success"
            />
          )}
          {testHealthBreakdown.pending && (
            <LargeLabeledValue
              label="Pending"
              value={testHealthBreakdown.pending}
              valueSize={18}
              valueColor="muted"
              ml={3}
            />
          )}
        </Flex>
        <ModuleLinkButton pt={1} to="/v4/synthetics/dashboard" text="Synthetics Dashboard" />
      </SidebarCard>
    );
  }
}

export default SyntheticsCard;
