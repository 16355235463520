import React, { Component } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { FormComponent, FormActions } from 'core/form';
import Drawer from './Drawer';

@observer
@withRouter
class DrawerForm extends Component {
  @computed
  get title() {
    const { title, model, entityName } = this.props;
    const isNew = model && model.isNew;
    return title || `${isNew ? 'Add' : 'Edit'} ${entityName}`;
  }

  get icon() {
    const { model } = this.props;
    const isNew = model && model.isNew;
    return isNew ? 'add-to-artifact' : 'edit';
  }

  handleCancel = () => {
    const { onClose, model, collection, history, rootUrl } = this.props;

    if (onClose) {
      onClose();
      return;
    }

    if (collection) {
      collection.clearSelection();
    }

    if (model) {
      model.deselect();
    }

    if (rootUrl) {
      history.push(rootUrl);
    }
  };

  render() {
    const { entityName, formComponent, model, fields, options, ...rest } = this.props;

    return (
      <FormComponent fields={fields} options={options} model={model}>
        {(formProps) => (
          <Drawer
            icon={this.icon}
            onClose={this.handleCancel}
            title={this.title}
            isOpen={model && model.isSelected}
            footer={<FormActions {...this.props} {...formProps} />}
            {...rest}
          >
            {React.createElement(formComponent)}
          </Drawer>
        )}
      </FormComponent>
    );
  }
}

export default DrawerForm;
