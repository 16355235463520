import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';
import { formatDateTime } from 'core/util/dateUtils';
import BaseHighchartsNonStyledDataview from 'app/components/dataviews/views/BaseHighchartsNonStyledDataview';
import { getMsValue } from 'app/views/synthetics/utils/syntheticsUtils';

@withTheme
@inject('$app', '$dataviews')
@observer
class MetricAggregateChart extends BaseHighchartsNonStyledDataview {
  constructor(props) {
    super(props);

    const { theme, onHover, suffix } = this.props;
    const { colors, fonts } = theme;

    this.state = {
      chartOptions: {
        chart: {
          type: 'line',
          style: { fontFamily: fonts.body },
          height: 200,
          spacingLeft: 0,
          spacingRight: 0,
          backgroundColor: colors.chart.tooltipBackground
        },
        title: { text: '' },
        credits: { enabled: false },
        legend: { enabled: false },
        xAxis: {
          type: 'datetime',
          crosshair: {
            color: colors.chart.crosshair
          },
          lineColor: colors.chart.axisLine,
          gridLineColor: colors.chart.gridLine,
          tickColor: colors.chart.axisTick,
          labels: {
            style: {
              color: colors.muted
            },
            formatter() {
              if (this.dateTimeLabelFormat === '%b %e') {
                return `<strong>${formatDateTime(this.value, 'MMM Do')}</strong>`;
              }

              return this.axis.defaultLabelFormatter.call(this);
            }
          },
          dateTimeLabelFormats: {
            day: '%b %e',
            week: '%b %e'
          }
        },
        yAxis: [
          {
            title: { text: '' },
            gridLineColor: colors.chart.gridLine,
            labels: {
              align: 'left',
              x: 0,
              y: -2,
              style: {
                color: colors.muted
              },
              formatter() {
                return `${suffix === 'ms' ? getMsValue(this.value, 0) : this.value.toFixed(0) + suffix}`;
              }
            },
            allowDecimals: false,
            min: 0,
            softMax: suffix === 'ms' ? 10000 : 100
          }
        ],
        tooltip: {
          valueSuffix: suffix,
          outside: true,
          useHTML: true,
          backgroundColor: colors.chart.tooltipBackground,
          shared: true,
          style: {
            color: colors.body,
            fontFamily: fonts.body
          },
          headerFormat: '',
          pointFormatter() {
            return `<div>
              <span style="color:${this.color}">●</span> ${this.series.name}: <b>${
                suffix === 'ms' ? getMsValue(this.y, 0) : this.y.toFixed(0) + suffix
              }</b><br/>
            </div>`;
          },
          formatter() {
            if (this.points[0]) {
              const sortedPoints = this.points.sort((a, b) => (b.y < a.y ? -1 : 1));
              return `
                <div>
                  <strong>${formatDateTime(this.x, 'MMM D, HH:mm')}</strong>
                  <hr/>
                </div>
                <div>
                  ${sortedPoints.map((point) => point.series.tooltipOptions.pointFormatter.call(point)).join('')}
                </div>
              `;
            }
            return null;
          }
        },
        series: [],
        plotOptions: {
          series: {
            turboThreshold: 0,
            marker: {
              enabled: false,
              symbol: 'circle'
            },
            // cursor: 'pointer',
            point: {
              events: {
                mouseOver(e) {
                  if (onHover) {
                    onHover(e.target.options.time);
                  }
                }
              }
            }
          }
        }
      }
    };
  }

  get type() {
    return 'timeline';
  }

  redraw() {
    if (this.chart) {
      const { theme } = this.props;
      const { colors, fonts } = theme;

      this.setState({
        chartOptions: {
          chart: {
            backgroundColor: colors.chart.tooltipBackground,
            style: { fontFamily: fonts.body }
          },
          tooltip: {
            backgroundColor: colors.chart.tooltipBackground,
            style: {
              color: colors.body,
              fontFamily: fonts.body
            }
          },
          xAxis: {
            lineColor: colors.chart.axisLine,
            gridLineColor: colors.chart.gridLine,
            tickColor: colors.chart.axisTick,
            labels: {
              style: {
                color: colors.muted
              }
            }
          },
          yAxis: {
            labels: {
              style: {
                color: colors.muted
              }
            },
            gridLineColor: colors.chart.gridLine
          }
        }
      });
    }
  }

  renderData() {
    if (this.chart) {
      const { data, xAxisMin, xAxisMax, yAxisMax } = this.props;
      this.setState(({ chartOptions }) => {
        const series = JSON.parse(JSON.stringify(data)).map((s) => ({
          ...s,
          marker: {
            states: {
              hover: {
                enabled: false,
                lineWidthPlus: 0
              },
              select: {
                color: s.color,
                fillColor: s.color,
                lineColor: 'rgba(255, 255, 255, 0.5)',
                lineWidth: 5,
                radius: 8
              }
            }
          }
        }));

        chartOptions.series = series;
        chartOptions.xAxis = { min: xAxisMin, max: xAxisMax };

        if (yAxisMax) {
          chartOptions.yAxis = { max: yAxisMax };
        }

        return { chartOptions };
      });
    }
  }
}

export default MetricAggregateChart;
