import React from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Heading, Text } from 'core/components';
import IncidentLogTable from 'app/views/synthetics/components/incidentLogs/IncidentLogTable';

@inject('$syn')
@observer
export default class IncidentLogPanel extends React.Component {
  get hasAlarms() {
    const { alarms, testResults } = this.props;
    const { hasAlarmResults, selectedTimelineAlarms } = testResults;

    // bgp alarms will come in via 'alarms' prop
    return alarms?.length > 0 || (hasAlarmResults && selectedTimelineAlarms.length > 0);
  }

  get alarms() {
    const { alarms, testResults } = this.props;

    return alarms || testResults.selectedTimelineAlarms;
  }

  // determines the columns and grouping to load in the table
  get columnType() {
    const { testResults } = this.props;
    const isBgp = testResults?.test?.isBgp;

    return {
      bgp: isBgp,
      testResults: !isBgp
    };
  }

  get alertingTypeLabel() {
    const { test } = this.props;

    if (test) {
      return <Text small muted>{`Per-${test.isPerAgentAlerting ? 'Agent' : 'Test'} Alerting`}</Text>;
    }

    return null;
  }

  render() {
    const { children, setPrefixes } = this.props;

    if (!this.hasAlarms) {
      return null;
    }

    const label = (
      <Box>
        <Heading level={6}>Incident Log</Heading>
        {this.alertingTypeLabel}
      </Box>
    );
    const content = (
      <IncidentLogTable
        flex={1}
        maxHeight={300}
        activeTimeslot={{ alarms: this.alarms }}
        virtualized={false}
        filterable
        setPrefixes={setPrefixes}
        {...this.columnType}
      />
    );

    if (typeof children === 'function') {
      // bgp test results want to include some additional details after the incident log panel
      // offer a way to get the label and content and render as they please
      return children({ label, content });
    }

    return (
      <>
        {label}
        {content}
      </>
    );
  }
}
