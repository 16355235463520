import React, { Component } from 'react';
import { inject } from 'mobx-react';
import moment from 'moment';
import { Classes } from '@blueprintjs/core';
import { FiMoreVertical } from 'react-icons/fi';
import { Button, DropdownMenu, Menu, MenuDivider, MenuItem, Text } from 'core/components';
import { getQueryTimeInterval } from 'core/util/dateUtils';
import StandaloneDimensionSelector from 'app/components/dimensions/StandaloneDimensionSelector';
import { getModelFilters, overriddenModelFilterFields } from 'app/util/filters';

@inject('$dictionary')
export default class LegendRowOptions extends Component {
  state = {
    dimensionSelectorOpen: false
  };

  get comparePreviousOptions() {
    const { bucket } = this.props;
    const { firstQuery } = bucket;
    const { lookback_seconds, starting_time, ending_time } = firstQuery.get();
    const interval = getQueryTimeInterval({ lookback_seconds, starting_time, ending_time });

    const options = [
      { label: 'Previous Hour', unit: 'hour' },
      { label: 'Previous Day', unit: 'day' },
      { label: 'Previous Week', unit: 'week' },
      { label: 'Previous Month', unit: 'month' }
    ];

    return options.filter(({ unit }) => moment.duration(1, unit).asSeconds() >= interval);
  }

  getModelFilters({ include = true, overrideMetric, createNewGroup } = {}) {
    const {
      model,
      bucket,
      dataview: { formState }
    } = this.props;
    return getModelFilters({ model, bucket, formState }, { include, overrideMetric, createNewGroup });
  }

  applyOverrides(overrides, options) {
    const { dataview, onChange } = this.props;
    const { formState } = dataview;
    if (formState) {
      if (overrides.filters) {
        overrides['filters.connector'] = overrides.filters.connector;
        overrides['filters.filterGroups'] = overrides.filters.filterGroups;
        delete overrides.filters;
      }
      formState.setValuesDeep(overrides);
    } else {
      dataview.applyToAllQueries(overrides);
    }

    if (onChange) {
      const { suppress } = options || {};
      onChange({ form: formState, formValues: formState.getValues(), field: 'filters.filterGroups', suppress });
    }
  }

  include(dimension) {
    // try to keep all dimensions in the same group with ANY per #16870
    const filters = this.getModelFilters({
      include: true,
      overrideMetric: dimension,
      createNewGroup: !dimension
    });
    this.applyOverrides({ filters }, { suppress: true });
  }

  exclude(dimension) {
    const filters = this.getModelFilters({ include: false, overrideMetric: dimension, createNewGroup: true });
    this.applyOverrides({ filters }, { suppress: true });
  }

  showBy = (metric) => {
    // Note: we don't need to change the state because the cmp is destroyed from the click event
    this.applyOverrides({ filters: this.getModelFilters(), metric }, { suppress: false });
  };

  showShowBySelector = () => {
    this.setState({ dimensionSelectorOpen: true });
  };

  hideShowBySelect = () => {
    this.setState({ dimensionSelectorOpen: false });
  };

  setSelectedRowByModel = (model, modelToggled, otherModelsToggled) => {
    const { bucket } = this.props;
    const selectableModels = bucket?.queryResults?.getRawDataRows(true) || [];
    selectableModels.forEach((selectableModel, i) => {
      selectableModel.set('preventRedraw', i !== selectableModels.length - 1); // redraw on last
      selectableModel.toggled = selectableModel.get('key') === model.get('key') ? modelToggled : otherModelsToggled;
    });
  };

  compareOver = (value, unit) => {
    const { dataview, model } = this.props;
    this.applyOverrides(
      { period_over_period: true, period_over_period_lookback: value, period_over_period_lookback_unit: unit },
      { suppress: false }
    );
    dataview.queryBuckets.comparisonKey = model.get('key');
  };

  render() {
    const { dimensionSelectorOpen } = this.state;
    const {
      model,
      bucket,
      $dictionary: { dictionary },
      showRowSelectionOptions
    } = this.props;
    const { firstQuery } = bucket;
    let subincludes = null;
    let subexcludes = null;

    const metric = bucket.firstQuery.get('metric');
    if (metric.length > 1) {
      subincludes = [];
      subexcludes = [];
      const values = model.get('isFpa') ? [] : (model.get('lookup') || model.get('key')).split(' ---- ');

      metric.forEach((dimension, index) => {
        const value = values[index];
        subincludes.push(
          <MenuItem
            key={dimension}
            icon="th-derived"
            text={
              <>
                <Text strong>{value}</Text>
                <br />
                <Text muted small>
                  {dictionary.chartTypesValidations[dimension]}
                </Text>
              </>
            }
            onClick={() => this.include(dimension)}
          />
        );
        subexcludes.push(
          <MenuItem
            key={dimension}
            icon="exclude-row"
            text={
              <>
                <Text strong>{value}</Text>
                <br />
                <Text muted small>
                  {dictionary.chartTypesValidations[dimension]}
                </Text>
              </>
            }
            onClick={() => this.exclude(dimension)}
          />
        );
      });
    }

    return (
      <>
        <DropdownMenu
          content={
            <Menu>
              {showRowSelectionOptions && model.hasRawData && (
                <>
                  <MenuItem
                    icon="full-circle"
                    text="Only display this row"
                    onClick={() => this.setSelectedRowByModel(model, false, true)}
                  />
                  <MenuItem
                    icon="remove"
                    text="Display all rows but this one"
                    onClick={() => this.setSelectedRowByModel(model, true, false)}
                  />
                  <MenuDivider />
                </>
              )}
              <MenuItem
                icon="th-derived"
                text="Include this in a new query"
                className={Classes.POPOVER_DISMISS}
                onClick={() =>
                  this.include(
                    metric?.length > 1 || (metric?.length === 1 && !overriddenModelFilterFields.includes(metric))
                      ? undefined
                      : metric
                  )
                }
                popoverProps={{ openOnTargetFocus: false }}
              >
                {subincludes}
              </MenuItem>
              <MenuItem
                icon="exclude-row"
                text="Exclude this in a new query"
                className={Classes.POPOVER_DISMISS}
                onClick={() =>
                  this.exclude(
                    metric?.length === 1 && !overriddenModelFilterFields.includes(metric) ? undefined : metric
                  )
                }
                popoverProps={{ openOnTargetFocus: false }}
              >
                {subexcludes}
              </MenuItem>
              <MenuDivider />
              <MenuItem icon="pivot-table" text="Show By..." onClick={this.showShowBySelector} />
              {!firstQuery.get('period_over_period') && (
                <MenuItem icon="series-derived" text="Compare over...">
                  {this.comparePreviousOptions.map(({ label, unit }) => (
                    <MenuItem key={label} text={label} onClick={() => this.compareOver(1, unit)} />
                  ))}
                </MenuItem>
              )}
            </Menu>
          }
        >
          <Button icon={FiMoreVertical} minimal color="muted" small />
        </DropdownMenu>
        {dimensionSelectorOpen && (
          <StandaloneDimensionSelector
            title="Show By Dimensions"
            isOpen={dimensionSelectorOpen}
            onSave={this.showBy}
            onClose={this.hideShowBySelect}
            saveButtonText="Show By Selected Dimensions"
            multi
          />
        )}
      </>
    );
  }
}
