import { getDSCPOptions } from 'shared/synthetics/constants';

const DSCP_OPTIONS = getDSCPOptions();

const pingFields = {
  'config.protocol': {
    defaultValue: 'tcp',
    label: 'Protocol',
    options: [
      {
        label: 'ICMP',
        value: 'icmp'
      },
      {
        label: 'TCP',
        value: 'tcp'
      }
    ],
    rules: ['required', 'in:icmp,tcp']
  },

  'config.port': {
    defaultValue: 443,
    label: 'Target Port',
    rules: ['numeric', 'MinPingPortValidator', 'max:65535']
  },

  // for backwards compatibility, have both config.port, and config.ping.port
  'config.ping.port': {
    defaultValue: 443,
    label: 'Target Port',
    rules: ['numeric', 'MinPingPortValidator', 'max:65535'],
    transform: {
      out: (value) => parseInt(value)
    }
  },

  'config.family': {
    defaultValue: 'v4',
    label: 'IP Version',
    options: [
      {
        value: 'DUAL',
        label: 'v4 + v6'
      },
      {
        value: 'v4',
        label: 'v4 only'
      },
      {
        value: 'v6',
        label: 'v6 only'
      }
    ],
    rules: ['agentsSupportIPv6']
  },

  'config.ping.period': {
    label: 'Test Frequency',
    defaultValue: 60,
    rules: ['required', 'numeric', 'isValidTestFrequency'],
    options: [
      { label: 'Every second', value: 1 },
      { label: 'Every 15 seconds', value: 15 },
      { label: 'Every minute', value: 60 },
      { label: 'Every 2 minutes', value: 120 },
      { label: 'Every 5 minutes', value: 300 },
      { label: 'Every 10 minutes', value: 600 },
      { label: 'Every 15 minutes', value: 900 },
      { label: 'Every 30 minutes', value: 1800 },
      { label: 'Every 60 minutes', value: 3600 }
    ]
  },

  'config.ping.count': {
    label: 'Number of probes per ping',
    defaultValue: 5,
    rules: ['required', 'numeric', 'min:1', 'max:10', 'pingDurationLessThanPeriod']
  },

  'config.ping.delay': {
    label: 'Inter-probe delay (ms)',
    defaultValue: 0,
    rules: ['required', 'numeric', 'pingDurationLessThanPeriod']
  },

  'config.ping.expiry': {
    label: 'Overall Ping timeout (ms)',
    defaultValue: 3000,
    showPristineErrors: true,
    rules: ['required', 'numeric', 'min: 1', 'max:10000', 'timeoutLessThanPeriod']
  },

  'config.ping.dscp': {
    label: 'DSCP',
    defaultValue: 0,
    options: DSCP_OPTIONS,
    rules: ['required', 'numeric', 'min: 0', 'max:63', 'DSCPValidator']
  },

  // NOTE: this is not displayed in UI
  'config.trace.period': {
    label: 'Trace Frequency',
    defaultValue: 60,
    rules: ['required', 'numeric', 'min:1', 'max:3600'],
    options: [
      { label: 'Every minute', value: 60 },
      { label: 'Every 2 minutes', value: 120 },
      { label: 'Every 5 minutes', value: 300 },
      { label: 'Every 10 minutes', value: 600 },
      { label: 'Every 15 minutes', value: 900 },
      { label: 'Every 30 minutes', value: 1800 },
      { label: 'Every 60 minutes', value: 3600 }
    ]
  },

  'config.trace.expiry': {
    label: 'Overall Trace timeout (ms)',
    defaultValue: 22500,
    rules: ['required', 'numeric', 'min: 1', 'max:300000', 'traceTimeoutLessThanPeriod']
  },

  'config.trace.count': {
    label: 'Number of probes per hop',
    defaultValue: 3,
    rules: ['required', 'numeric', 'min:1', 'max:5']
  },

  'config.trace.delay': {
    label: 'Inter-probe delay (ms)',
    defaultValue: 0,
    rules: ['required', 'numeric']
  },

  'config.trace.limit': {
    label: 'Max number of hops (Max TTL)',
    defaultValue: 30,
    rules: ['required', 'numeric', 'min:1', 'max:255']
  },

  'config.trace.protocol': {
    label: 'Protocol',
    defaultValue: 'tcp',
    options: [
      {
        label: 'UDP',
        value: 'udp'
      },
      {
        label: 'TCP',
        value: 'tcp'
      },
      {
        label: 'ICMP',
        value: 'icmp'
      }
    ],
    rules: ['required', 'in:udp,tcp,icmp']
  },

  'config.trace.port': {
    defaultValue: 443,
    label: 'Target Port',
    rules: ['numeric', 'MinTraceroutePortValidator', 'max:65535']
  },

  'config.trace.dscp': {
    label: 'DSCP',
    defaultValue: 0,
    options: DSCP_OPTIONS,
    rules: ['required', 'numeric', 'min: 0', 'max:63', 'DSCPValidator']
  }
};

export { pingFields };
