import React from 'react';
import { Label } from 'app/components/labels/Label';
import { getOption } from 'core/form/components/modalSelect/selectHelpers';
import { observer } from 'mobx-react';

const LabelValueTagRenderer = (props) => {
  const { onUnselectBuilder, options, values } = props;

  if (!options) {
    return null;
  }

  return values.map((val) => {
    const valueOption = getOption(options, val);
    if (!valueOption) {
      return null;
    }

    const { label, bg, isPreset } = valueOption;

    const onRemove = onUnselectBuilder(valueOption.value || valueOption);

    return <Label key={valueOption.key || val} name={label} onRemove={onRemove} bg={bg} isPreset={isPreset} />;
  });
};

export default observer(LabelValueTagRenderer);
