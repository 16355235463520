import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  Box,
  Button,
  Collapse,
  Flex,
  Grid,
  Icon,
  Menu,
  MenuItem,
  Popover,
  Spinner,
  Text,
  Tooltip
} from 'core/components';
import Page, { NAV_HEIGHT } from 'app/components/page/Page';
import storeLoader from 'app/stores/storeLoader';
import { BiFolder } from 'react-icons/bi';
import { Position } from '@blueprintjs/core';
import JourneyChat from './JourneyChat';
import JourneyEmptyState from './JourneyEmptyState';
import JourneyInput from './JourneyInput';
import JourneyList from './JourneyList';
import QueryAssistantTipsDialog from './QueryAssistantTipsDialog';
import JourneysPaintedDoor from './JourneysPaintedDoor';
import SudoJourney from './SudoJourney';

const INPUT_HEIGHT = 110;
const HOVER_OPEN_DELAY = 359;
const FancyInput = styled.div`
  padding: 12px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -30px;
    bottom: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 85px;
    pointer-events: none;
    background: ${(props) => `linear-gradient(rgba(255, 255, 255, 0), ${props.theme.colors.decks.background} 60%)`};
  }
`;

const Sidebar = styled(Box)`
  background: ${(props) => props.theme.colors.cardBackground};
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  padding: 16px;
  width: 100%;
  opacity: ${(props) => (props.isCollapsed ? 0 : 1)};
  overflow-y: auto;
  pointer-events: ${(props) => (props.isCollapsed ? 'none' : 'all')};
  scrollbar-width: thin;
`;

const JourneysLayout = styled(Grid)`
  transition: 300ms;
`;

const JourneyScrollArea = styled(Flex)`
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  padding: 24px;
  overflow: auto;
  gap: 12px;
  scrollbar-width: thin;
`;

const SmallHeading = styled(Text)`
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.muted};
`;

@storeLoader('$users', '$journeys')
@inject('$auth', '$companySettings')
@withRouter
@observer
class Journeys extends Component {
  state = {
    isActionsOpen: false,
    isMyJourneysOpen: true,
    isCompanyJourneysOpen: true,
    isScrolledToBottom: false,
    loading: true
  };

  inputRef = React.createRef();

  scrollAreaRef = React.createRef();

  componentDidMount() {
    const { history, match, location, $journeys } = this.props;
    const { journeyId } = match.params;
    const { state } = location;

    if (this.hasAi) {
      $journeys.collection.fetch().then(() => {
        this.setState({ loading: false }, () => {
          if (state?.prompt?.length) {
            // incoming, i.e. `history.push('/v4/core/journeys', { prompt: 'CDN traffic by country' })`
            this.handleCreateJourney().then(() => {
              this.handleSubmitPrompt(state.prompt);
            });
          } else if ($journeys.collection.size > 0) {
            if (journeyId) {
              const journey = $journeys.collection.get(journeyId);
              if (journey) {
                this.handleSelectJourney(journey);
              } else {
                // journey not found
                history.push('/v4/core/journeys');
              }
            } else {
              const journey = $journeys.collection.lastEditedUserJourney;
              if (journey) {
                // select the users last journey by edate
                this.handleSelectJourney($journeys.collection.lastEditedUserJourney);
              }
            }
          }
          this.scrollToBottom();
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { history, location, match, $journeys } = this.props;
    const { state } = location;
    const {
      location: { state: prevState },
      match: prevMatch
    } = prevProps;

    if (state?.prompt && state?.prompt !== prevState?.prompt) {
      this.handleCreateJourney().then(() => {
        this.handleSubmitPrompt(state.prompt);
      });
    }

    if (match.params.journeyId && match.params.journeyId !== prevMatch.params.journeyId) {
      const journey = $journeys.collection.get(match.params.journeyId);
      if (journey) {
        this.handleSelectJourney(journey);
      } else {
        // journey not found
        history.push('/v4/core/journeys');
      }
    }
  }

  handleScroll = () => {
    const { scrollHeight, scrollTop } = this.scrollAreaRef.current;
    const scrollAreaHeight = this.scrollAreaRef.current.clientHeight;

    // 	Math.abs(... <= 1) → Checks if the difference is very small (≤ 1px) to account for rounding errors in different browsers.
    const isBottom = Math.abs(scrollHeight - Math.ceil(scrollTop) - scrollAreaHeight) <= 1;
    this.setState({ isScrolledToBottom: isBottom });
  };

  scrollToBottom = () => {
    if (this.scrollAreaRef?.current) {
      setTimeout(() => {
        const offsetBottom = this.scrollAreaRef.current.scrollTop + this.scrollAreaRef.current.scrollHeight + 100;
        this.scrollAreaRef.current.scrollTo({ top: offsetBottom, behavior: 'smooth' });
      }, 200);
    }
  };

  handleCreateJourney = () => {
    const { $journeys } = this.props;
    const newJourney = $journeys.collection.forge();
    this.setState({ isCreatingJourney: true });
    return newJourney.save({ title: 'New Journey', queries: [] }, { clearSelection: false, toast: false }).then(() => {
      this.setState({ isCreatingJourney: false });

      this.inputRef.current.focus();
      this.handleSelectJourney(newJourney);
      return newJourney;
    });
  };

  handleToggleMyJourneys = () => {
    this.setState((prevState) => {
      const { isMyJourneysOpen } = prevState;
      return { isMyJourneysOpen: !isMyJourneysOpen };
    });
  };

  handleToggleCompanyJourneys = () => {
    this.setState((prevState) => {
      const { isCompanyJourneysOpen } = prevState;
      return { isCompanyJourneysOpen: !isCompanyJourneysOpen };
    });
  };

  handleSubmitPrompt = (prompt) => {
    const { $journeys } = this.props;
    $journeys.collection.selected?.addPrompt(prompt);
  };

  handleSelectSuggestion = (suggestion) => {
    const { $journeys } = this.props;

    if (!$journeys.collection.selected) {
      this.handleCreateJourney().then(() => {
        this.handleSubmitPrompt(suggestion);
      });
    } else {
      $journeys.collection.selected.set('title', suggestion);
      this.handleSubmitPrompt(suggestion);
    }

    this.setState({ prompt: suggestion });
  };

  handleSelectJourney = (journey) => {
    const { history } = this.props;
    history.push(`/v4/core/journeys/${journey.id}`);
    journey.selectAndSubscribe({ fetch: true }).then(() => this.setState({ isSidebarCollapsed: false }));
  };

  handleShowTipsDialog = () => {
    this.setState({ isTipsDialogOpen: true });
  };

  handleCloseTipsDialog = () => {
    this.setState({ isTipsDialogOpen: false });
  };

  handleSidebarCollapse = () => {
    const { isSidebarCollapsed } = this.state;
    this.setState({ isSidebarCollapsed: !isSidebarCollapsed });
  };

  renderLoading = () => (
    <Flex
      width="100%"
      height="100%"
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bg="decks.background"
      pt={0}
      overflow="auto"
    >
      <Spinner />
    </Flex>
  );

  handleViewPrompt() {
    window.open('/api/ui/journeys/context', '_blank');
  }

  handleActionsInteraction = (isActionsOpen) => {
    this.setState({ isActionsOpen });
  };

  get hasAi() {
    const { $companySettings } = this.props;
    return !!$companySettings.enableKentikAi;
  }

  render() {
    const { $auth, $journeys, history, loading: loadingStores } = this.props;
    const {
      prompt,
      loading,
      isTipsDialogOpen,
      isActionsOpen,
      isCreatingJourney,
      isSidebarCollapsed,
      isCompanyJourneysOpen,
      isMyJourneysOpen,
      isScrolledToBottom
    } = this.state;
    const { isSudoer } = $auth;

    if (!this.hasAi) {
      return <JourneysPaintedDoor />;
    }

    const selectedJourney = $journeys.collection?.selected;

    let title = '';
    if (selectedJourney) {
      title = selectedJourney.get('title');
    }

    return (
      <Page
        pt={0}
        pb={0}
        title={title}
        subnavTools={
          <Flex>
            {selectedJourney && (
              <Popover
                isOpen={isActionsOpen}
                content={
                  <Menu>
                    <MenuItem
                      icon="trash"
                      text="Delete"
                      disabled={!selectedJourney.canDelete}
                      onClick={(e) => {
                        e.stopPropagation();
                        selectedJourney.destroy({ toast: false }).then(() => {
                          history.push('/v4/core/journeys');
                        });
                      }}
                    />
                  </Menu>
                }
                onInteraction={this.handleActionsInteraction}
                position={Position.BOTTOM_RIGHT}
              >
                <Button text="Actions" rightIcon="caret-down" ml="2px" active={!!isActionsOpen} minimal />
              </Popover>
            )}
            <Tooltip
              placement="right"
              hoverOpenDelay={HOVER_OPEN_DELAY}
              content="Toggle Journeys List"
              boundary="viewport"
            >
              <Button
                minimal
                icon={BiFolder}
                text="Journeys"
                active={!isSidebarCollapsed}
                onClick={this.handleSidebarCollapse}
                ml={1}
              />
            </Tooltip>
          </Flex>
        }
      >
        {(loading || loadingStores) && this.renderLoading()}
        {!loading && !loadingStores && (
          <JourneysLayout
            gridTemplateColumns={isSidebarCollapsed ? '1fr 0px' : '1fr 325px'}
            gridTemplateRows={`minmax(200px, calc(100vh - ${NAV_HEIGHT}px))`}
            height="100%"
            mx={-3}
            gridRowGap={0}
            gridGap={0}
            bg="decks.background"
          >
            <Grid
              gridTemplateRows={`minmax(200px, calc(100vh - ${INPUT_HEIGHT}px)) min-content`}
              gridTemplateColumns="1fr"
              gridGap={0}
              gridRowGap={0}
              width="100%"
              height="100%"
              pt={0}
              overflowY="scroll"
              bg="decks.background"
            >
              <JourneyScrollArea ref={this.scrollAreaRef} onScroll={this.handleScroll}>
                {(!$journeys.collection.selected ||
                  $journeys.collection?.selected?.get('queries')?.size === 0 ||
                  $journeys.collection.size === 0) && (
                  <JourneyEmptyState onSelectSuggestion={this.handleSelectSuggestion} />
                )}
                {$journeys.collection?.selected?.id && $journeys.collection?.selected?.get('queries')?.size > 0 && (
                  <Flex flexDirection="column" gap={4} flex={1}>
                    <JourneyChat
                      journey={$journeys.collection?.selected}
                      scrollToBottom={this.scrollToBottom}
                      isScrolledToBottom={isScrolledToBottom}
                    />
                  </Flex>
                )}
              </JourneyScrollArea>
              <FancyInput>
                <JourneyInput
                  scrollToBottom={this.scrollToBottom}
                  inputRef={this.inputRef}
                  prompt={prompt}
                  key={prompt}
                  placeholder="Query your data with Kentik AI..."
                  journey={$journeys.collection?.selected}
                  createJourney={this.handleCreateJourney}
                />
                <Flex alignItems="center" gap={2}>
                  <Button icon="help" onClick={this.handleShowTipsDialog} small minimal>
                    <Text muted>Tips for building good prompts</Text>
                  </Button>
                  {$journeys.collection?.selected && $journeys.collection?.selected?.get('queries')?.size > 0 && (
                    <Button icon="arrow-down" onClick={this.scrollToBottom} small minimal>
                      <Text muted>Scroll to Bottom</Text>
                    </Button>
                  )}
                  {isSudoer ? (
                    <Button icon="bring-data" onClick={this.handleViewPrompt} small minimal>
                      <Text muted>[sudo] View prompt context</Text>
                    </Button>
                  ) : null}
                </Flex>
              </FancyInput>
            </Grid>

            <Sidebar borderLeft="thin" isCollapsed={isSidebarCollapsed}>
              <Button
                alignItems="center"
                justifyContent="center"
                onClick={this.handleCreateJourney}
                loading={isCreatingJourney}
              >
                <Icon icon="plus" mr={1} />
                <Text>Start a new Journey</Text>
              </Button>

              {$journeys.collection?.selected?.get('metadata.compare') && (
                <SudoJourney journeyModel={$journeys.collection?.selected} />
              )}

              {$journeys.collection?.size === 0 && (
                <Text muted textAlign="center">
                  Your Journeys will appear here
                </Text>
              )}
              {$journeys.collection.userJourneys?.length > 0 && (
                <Flex flexDirection="column" gap={1}>
                  <Button
                    textAlign="left"
                    fill
                    icon={isMyJourneysOpen ? 'caret-down' : 'caret-right'}
                    minimal
                    onClick={this.handleToggleMyJourneys}
                  >
                    <SmallHeading>My Journeys</SmallHeading>
                  </Button>
                  <Collapse isOpen={isMyJourneysOpen}>
                    <JourneyList
                      onSelectJourney={this.handleSelectJourney}
                      hideUserName
                      hideShareLevel
                      journeys={$journeys.collection.userJourneys}
                    />
                  </Collapse>
                </Flex>
              )}
              <Flex flexDirection="column" gap={1}>
                <Button
                  textAlign="left"
                  fill
                  icon={isCompanyJourneysOpen ? 'caret-down' : 'caret-right'}
                  minimal
                  onClick={this.handleToggleCompanyJourneys}
                >
                  <SmallHeading>Company</SmallHeading>
                </Button>
                <Collapse isOpen={isCompanyJourneysOpen}>
                  <JourneyList
                    onSelectJourney={this.handleSelectJourney}
                    hideEditedDate
                    hideShareLevel
                    journeys={$journeys.collection.companyJourneys}
                  />
                </Collapse>
              </Flex>
            </Sidebar>
          </JourneysLayout>
        )}
        <QueryAssistantTipsDialog isOpen={isTipsDialogOpen} onClose={this.handleCloseTipsDialog} />
      </Page>
    );
  }
}

export default Journeys;
