import React from 'react';
import { observer, inject } from 'mobx-react';
import Image from './Image';

const ThemedImage = inject('$app')(
  observer((props) => {
    const { $app, src, darkSrc, ...rest } = props;

    return <Image {...rest} src={$app.darkThemeEnabled ? darkSrc : src} />;
  })
);

export default ThemedImage;
