import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { formConsumer } from 'core/form';

import { Box, Checkbox } from 'core/components';
import { Field, Select } from 'core/form/components';

import { BaseAgentRenderer } from 'app/views/synthetics/components/AgentRenderer';

function agentOptionRenderer(opts) {
  const { key, className, selected, selectItem, style, ...agent } = opts;
  return (
    <Box mx={1} key={key || agent.value}>
      <Checkbox
        onChange={() => selectItem(agent.value)}
        checked={selected}
        style={{ display: 'flex', alignItems: 'center', paddingBottom: '8px' }}
        key={key || agent.value}
      >
        <Box ml={1}>
          <BaseAgentRenderer {...agent} locationInfoProps={{ ellipsis: true }} showLocationInfo small />
        </Box>
      </Checkbox>
    </Box>
  );
}

@formConsumer
@observer
class AgentSelectorCompact extends Component {
  render() {
    const { agentOptions, mb = '12px', selectedAgents, showLabel = true } = this.props;
    return (
      <Field name="selectedAgents" options={agentOptions} small showLabel={showLabel} mb={mb}>
        <Select
          valueRenderer={() => `Show ${selectedAgents.length} of ${agentOptions.length} agents`}
          optionRenderer={agentOptionRenderer}
          fill
          showFilter
          multi
          keepOpen
          toggle
          renderAsButton
        />
      </Field>
    );
  }
}

export default AgentSelectorCompact;
