import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';

import Banner from 'app/components/Banner';
import { Box, Callout, KeyCombo } from 'core/components';
import InterfaceClassDependency from 'app/components/dataviews/dependencies/InterfaceClassDependency';
import NetworkClassDependency from 'app/components/dataviews/dependencies/NetworkClassDependency';
import ProviderClassDependency from 'app/components/dataviews/dependencies/ProviderClassDependency';
import SnmpDependency from 'app/components/dataviews/dependencies/SnmpDepdendency';
import SiteCountryDependency from 'app/components/dataviews/dependencies/SiteCountryDependency';
import SiteDependency from 'app/components/dataviews/dependencies/SiteDependency';
import GCEDependency from 'app/components/dataviews/dependencies/GCEDependency';
import AWSDependency from 'app/components/dataviews/dependencies/AWSDependency';
import AzureDependency from 'app/components/dataviews/dependencies/AzureDependency';
import CloudDependency from 'app/components/dataviews/dependencies/CloudDependency';
import KProbeDependency from 'app/components/dataviews/dependencies/KProbeDependency';

@inject('$app')
@observer
export default class UnappliedChanges extends Component {
  static defaultProps = {
    buttonText: 'Run Query'
  };

  dependencies = [];

  componentDidMount() {
    this.setupDependencies();
  }

  componentDidUpdate() {
    this.setupDependencies();
  }

  setupDependencies() {
    const { dataview, dataviews, form, $app } = this.props;
    const dataviewParam = dataview ? [dataview] : dataviews;

    this.dependencies = $app.isSubtenant
      ? []
      : [
          new InterfaceClassDependency(dataviewParam, form),
          new NetworkClassDependency(dataviewParam, form),
          new ProviderClassDependency(dataviewParam, form),
          new SnmpDependency(dataviewParam, form),
          new SiteDependency(dataviewParam, form),
          new SiteCountryDependency(dataviewParam, form),
          new GCEDependency(dataviewParam, form),
          new AWSDependency(dataviewParam, form),
          new AzureDependency(dataviewParam, form),
          new CloudDependency(dataviewParam, form),
          new KProbeDependency(dataviewParam, form)
        ];
  }

  get isDirty() {
    const { form } = this.props;
    return form && form.dirty;
  }

  get hasErrors() {
    const { form } = this.props;
    return form && form.errors.length > 0;
  }

  get dependencyCheckFailures() {
    return this.dependencies.filter((dep) => !dep.meets && dep.requires && !dep.validate() && dep.showMessage);
  }

  get hadDependencyCheckFailures() {
    return this.dependencies.some((dep) => !dep.meets && dep.queryRequires && dep.showMessage);
  }

  render() {
    const { form, buttonText } = this.props;

    const dependencyFailures = this.dependencyCheckFailures;

    if (!this.isDirty && dependencyFailures.length === 0) {
      return null;
    }

    let title = 'You have unapplied changes.';
    let intent;
    let icon;
    let description = (
      <span>
        Click <strong>{buttonText}</strong> or press <KeyCombo combo="shift+enter" inline position="relative" top={3} />{' '}
        to apply.
      </span>
    );

    if (this.hasErrors) {
      title = 'You have unapplied changes, but there are errors.';
      intent = 'danger';
      icon = 'error';
      description = (
        <span>
          Please check your{' '}
          {Object.keys(Object.assign(form.invalidGroups, form.incompleteGroups)).map((section, index, arr) => (
            <Fragment key={section}>
              {index > 1 || (index === 1 && index < arr.length - 1) ? ', ' : ''}
              {index > 0 && index === arr.length - 1 ? ' and ' : ''}
              {section}
            </Fragment>
          ))}{' '}
          options.
        </span>
      );
    } else if (dependencyFailures.length > 0) {
      const [firstFailure] = dependencyFailures;
      title = this.isDirty
        ? `You have unapplied changes, but there are ${
            this.hadDependencyCheckFailures ? 'still ' : ''
          }dependency check failures.`
        : firstFailure.title;
      intent = firstFailure.intent;
      icon = firstFailure.icon;
      description = (
        <Fragment>
          {dependencyFailures.map((failure) => (
            <Callout key={failure.key} mb="2px" mt={1} mx={0}>
              {failure.message}
            </Callout>
          ))}
          {this.isDirty && (
            <Box mt={1} ml={-4} mr={-1}>
              However, you may run this query anyway. {description}
            </Box>
          )}
        </Fragment>
      );
    }

    return <Banner boxProps={this.props} title={title} intent={intent} icon={icon} description={description} />;
  }
}
