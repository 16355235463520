import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { get } from 'lodash';
import { FiMenu } from 'react-icons/fi';
import { Hotkey, Hotkeys, HotkeysTarget, Position } from '@blueprintjs/core';
import copy from 'copy-to-clipboard';

import { showSuccessToast } from 'core/components/toast';
import { Box, Button, Flex, Icon, KeyCombo, Popover, Text, Tooltip } from 'core/components';
import { InputGroup } from 'core/form';
import SudoDialog from 'app/views/sudo/SudoDialog';
import TenantLogo from 'app/views/mkpConfig/components/TenantLogo';
import ReportIssuePopover from 'app/views/reportIssue/ReportIssuePopover';
import EnvironmentSelector from 'app/components/demo/EnvironmentSelector';
import DiscoveryProgressChip from 'app/views/metrics/components/DiscoveryProgressChip';

import { ReactComponent as PersonIcon } from 'app/assets/icons/person.svg';
import { ReactComponent as OfficeIcon } from 'app/assets/icons/office.svg';

import { generateSpoofUrl } from 'app/util/generateSpoofUrl';
import KentikLogo from '../KentikLogo';
import ProductUpdatesButton from './ProductUpdatesButton';
import UserMenu from './UserMenu';
import CompanyMenu from './CompanyMenu';
import KDEDebugModeDialog from './KDEDebugModeDialog';

export const NAVBAR_HEIGHT = 60;

const NavWrapper = styled(Box)`
  position: fixed;
  top: 0;
  height: ${NAVBAR_HEIGHT}px;
  width: 100vw;
  padding-right: 15px; // scrollbar
  z-index: 10;
  background-color: ${({ menuOpen, theme: { colors } }) =>
    menuOpen ? colors.navbarBackgroundOpen : colors.navbarBackground};
`;

const EnvBadgeWrapper = styled(Box)`
  border-radius: 18px;
  font-size: 12px;
  padding: 0 8px;
  line-height: 18px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: #ffffff;
  background-color: ${({ theme }) => theme.colors.kentik};
  font-family: ${({ theme }) => theme.fonts.monospace};
  text-transform: lowercase;
`;

const SearchInput = styled(InputGroup)`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  width: 35%;
  margin-top: -5px;

  input {
    background: ${({ theme }) => (theme.name === 'dark' ? '#3B3B3B' : '#223045')};
    color: #ffffff;
    border-color: ${({ theme }) => (theme.name === 'dark' ? '#686A6D' : '#37383B')};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.45);
    ::placeholder {
      color: ${({ theme }) => (theme.name === 'dark' ? '#6D767E' : '#7F868F')};
    }

    &.bp4-input:focus,
    .bp4-input.bp4-active {
      box-shadow:
        0 0 0 1px #157ff3,
        0 0 0 3px rgba(21, 127, 243, 0.3),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
    }
  }

  svg {
    fill: #e3e3e3;
  }
`;

function EnvBadge({ openConfig, isNext }) {
  const { region: env } = openConfig;

  if (env === 'US' && !isNext) {
    return null;
  }

  return (
    <EnvBadgeWrapper>
      {env !== 'US' && env} {isNext && 'next'}
    </EnvBadgeWrapper>
  );
}

export function NavButton(props) {
  const { disabled } = props;
  return (
    <Button
      color={disabled ? 'white.5' : 'white.10'}
      textColor={disabled ? 'white.3' : 'white.8'}
      fontWeight="heavy"
      minimal
      {...props}
    />
  );
}

@inject('$auth', '$app', '$discovery', '$users')
@withRouter
@HotkeysTarget
@observer
class Navbar extends Component {
  state = {
    userMenuOpen: false,
    companyMenuOpen: false
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderHotkeys() {
    const { $app, $auth, match, onMenuClose } = this.props;

    return (
      <Hotkeys>
        <Hotkey global combo="esc" label="Close Menu" onKeyDown={onMenuClose} />
        <Hotkey
          allowInInput
          global
          combo="mod + /"
          label="Open Search"
          preventDefault
          onKeyDown={() => this.handleToggleSearch('keyboard')}
        />
        <Hotkey
          global
          combo="shift + s"
          label="Open Search (Alternative)"
          preventDefault
          onKeyDown={() => this.handleToggleSearch('keyboard')}
        />

        <Hotkey
          global
          combo="esc"
          label="Close Support Popover"
          preventDefault
          onKeyDown={() => $app.toggleReportIssuePopover(false)}
        />

        <Hotkey
          global
          combo="shift + e"
          label="Data Explorer"
          preventDefault
          onKeyDown={() => this.handleNavShortcut('/v4/core/explorer', 'Data Explorer')}
        />
        <Hotkey
          global
          combo="shift + l"
          label="Library"
          preventDefault
          onKeyDown={() => this.handleNavShortcut('/v4/library', 'Library')}
        />
        <Hotkey
          global
          combo="shift + o"
          label="Observation Deck"
          preventDefault
          onKeyDown={() => this.handleNavShortcut('/v4/home', 'Observation Deck')}
        />

        {($auth.hasSudo || $auth.isSpoofed) && (
          <Hotkey
            global
            combo="mod + option + c"
            label="Copy Kentik Admin URL"
            preventDefault
            onKeyDown={() => {
              const url = generateSpoofUrl($auth, { persist: true, match });
              copy(url);
              showSuccessToast('URL copied');
            }}
          />
        )}
      </Hotkeys>
    );
  }

  handleToggleSearch = (method) => {
    const { $app, menuOpen, onMenuClose } = this.props;
    document.activeElement.blur();
    $app.toggleSearch(method);
    if (menuOpen) {
      onMenuClose();
    }
  };

  handleNavShortcut = (url, toast) => {
    const { history } = this.props;
    if (toast) {
      showSuccessToast(`Shortcut: Go to ${toast}`);
    }
    history.push(url);
  };

  handleHomeClick = () => {
    const { history, $app } = this.props;
    history.push(`${$app.isSubtenant ? '/v4/landing' : '/v4'}`);
  };

  handleToggleUserMenu = (force) => {
    this.setState((prevState) => ({ userMenuOpen: force !== undefined ? force : !prevState.userMenuOpen }));
  };

  handleToggleCompanyMenu = (force) => {
    this.setState((prevState) => ({ companyMenuOpen: force !== undefined ? force : !prevState.companyMenuOpen }));
  };

  onUserMenuInteraction = (nextState) => this.setState({ userMenuOpen: nextState });

  onCompanyMenuInteraction = (nextState) => this.setState({ companyMenuOpen: nextState });

  handleFeedbackInteraction = (isOpen) => {
    const { $app } = this.props;
    $app.toggleReportIssuePopover(isOpen);
  };

  render() {
    const { $app, $auth, $discovery, $users, menuOpen, onMenuClose, onMenuToggle, locked, ...wrapperProps } =
      this.props;
    const { reportIssuePopoverOpen } = $app;

    const { userMenuOpen, companyMenuOpen } = this.state;

    let LogoCmp = <KentikLogo style={{ width: 125, height: 26 }} isNavbar />;
    let feedbackEnabled = true;

    if ($app.isSubtenant) {
      if (get($auth.openConfig, 'subtenancy.config.icon', false)) {
        LogoCmp = <TenantLogo companyId={$auth.getActiveUserProperty('company_id')} height={21} mt="2px" />;
      }
      if ($auth.hasPermission('subtenancy.advancedMode')) {
        feedbackEnabled = get($auth.openConfig, 'subtenancy.config.supportEmail', false);
      }
    }

    return (
      <>
        <NavWrapper menuOpen={menuOpen} {...wrapperProps}>
          <Flex height={NAVBAR_HEIGHT} alignItems="center" justifyContent="space-between" pl="12px" pr={3}>
            <Flex alignItems="center" flex={1}>
              {!$auth.isSharedUser && !$app.isSubtenant && (
                <NavButton
                  active={menuOpen}
                  icon={FiMenu}
                  onClick={() => onMenuToggle()}
                  disabled={locked}
                  tracker="nav-menu-toggle-btn"
                  ml="2px"
                  p="4px 8px"
                  large
                />
              )}
              {($auth.isSharedUser || locked) && LogoCmp}
              {!$auth.isSharedUser && !locked && (
                <NavButton onClick={this.handleHomeClick} ml="2px" p="4px 8px" large>
                  {LogoCmp}
                </NavButton>
              )}
              {($auth.hasSudo || $auth.isSpoofed) && <EnvBadge openConfig={$auth.openConfig} isNext={$app.isNext} />}
            </Flex>

            {!$app.isSubtenant && (
              <SearchInput
                className="jumbo"
                placeholder="Search..."
                leftIcon="search"
                rightElement={
                  <Flex className="bp4-dark" gap={1} alignItems="center" height="100%" mr={2}>
                    <KeyCombo combo="mod" showIcon={false} />
                    <Text color="#ffffff">+</Text>
                    <KeyCombo combo="/" />
                  </Flex>
                }
                onClick={() => this.handleToggleSearch('mouse')}
                onContextMenu={() => this.handleToggleSearch('mouse')}
                fill
                value=""
                onChange={() => {}}
              />
            )}

            <Flex alignItems="center" justifyContent="flex-end" flex={1}>
              <DiscoveryProgressChip />

              {!$auth.isSharedUser && (
                <>
                  {$auth.needsActivation && (
                    <Box mr="4px">
                      <Popover
                        position={Position.BOTTOM}
                        content={
                          <Box maxWidth={400} p={2} textAlign="center">
                            Check your email and click on the activation link to confirm your email address and create
                            your password.
                            <Button
                              intent="primary"
                              text="Resend Activation Email"
                              onClick={$users.resendActivationEmail}
                              loading={$auth.passwordReqInProgress}
                              mt={2}
                            />
                          </Box>
                        }
                      >
                        <NavButton text="Activate Your Account" textColor="danger" fontWeight="normal" />
                      </Popover>
                    </Box>
                  )}

                  {$auth.isDemoUser && (
                    <Box mr="4px">
                      <EnvironmentSelector />
                    </Box>
                  )}

                  {!$app.isSubtenant && (
                    <Box mr="4px">
                      <Tooltip content="Product Updates" position={Position.BOTTOM} openOnTargetFocus={false} minimal>
                        <ProductUpdatesButton />
                      </Tooltip>
                    </Box>
                  )}

                  {feedbackEnabled && (
                    <Popover
                      isOpen={reportIssuePopoverOpen}
                      position={Position.BOTTOM}
                      onInteraction={this.handleFeedbackInteraction}
                      content={<ReportIssuePopover />}
                    >
                      <Tooltip content="Help and Support" position={Position.BOTTOM} openOnTargetFocus={false} minimal>
                        <NavButton
                          icon="help"
                          onClick={() => $app.toggleReportIssuePopover()}
                          mr="4px"
                          active={reportIssuePopoverOpen}
                          aria-label="Help and Support"
                        />
                      </Tooltip>
                    </Popover>
                  )}
                  {!$app.isSubtenant && (
                    <Popover
                      position="bottom-right"
                      isOpen={companyMenuOpen}
                      onInteraction={this.onCompanyMenuInteraction}
                      boundary="viewport"
                    >
                      <NavButton
                        icon={OfficeIcon}
                        rightIcon={<Icon icon="caret-down" iconSize={16} mx="-2px" />}
                        active={companyMenuOpen}
                        onClick={this.handleToggleCompanyMenu}
                        color={$auth.isSpoofed && !$auth.isDemoUser ? 'warning' : 'white.10'}
                        aria-label="Company Menu"
                      />
                      <CompanyMenu handle />
                    </Popover>
                  )}
                  <Popover
                    position="bottom-right"
                    isOpen={userMenuOpen}
                    onInteraction={this.onUserMenuInteraction}
                    boundary="viewport"
                  >
                    <NavButton
                      icon={PersonIcon}
                      rightIcon={<Icon icon="caret-down" iconSize={16} mx="-2px" />}
                      active={userMenuOpen}
                      onClick={this.handleToggleUserMenu}
                      color={$auth.isSpoofed && !$auth.isDemoUser ? 'warning' : 'white.10'}
                      aria-label="User Menu"
                    />
                    <UserMenu handle />
                  </Popover>
                </>
              )}
            </Flex>
          </Flex>
        </NavWrapper>
        <SudoDialog />
        <KDEDebugModeDialog isOpen={$app.debugModeOpen} />
      </>
    );
  }
}

export default Navbar;
