import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import Box from './Box';
import Icon from './Icon';
import Heading from './Heading';

const Callout = ({ title, textAlign, intent, icon, className, children, ...rest }) => {
  let pl;
  if (title) {
    pl = '29px';
  }

  return (
    <Box
      className={classNames(Classes.CALLOUT, className, {
        [Classes.intentClass(intent)]: intent,
        [Classes.CALLOUT_ICON]: icon
      })}
      style={{ textAlign }}
      pl={pl}
      {...rest}
    >
      {icon && <Icon icon={icon} />}
      {title && <Heading level={5}>{title}</Heading>}
      {children}
    </Box>
  );
};

Callout.defaultProps = {
  p: 1
};

export default Callout;
