import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Box, Text } from 'core/components';
import { toCIDRNotation } from 'core/util/ip';
import { formatBytesGreek } from 'core/util';
import { hexToMac } from 'core/util/macAddress';

import DeviceLink from 'app/components/links/DeviceLink';
import SiteLink from 'app/components/links/SiteLink';
import IpLink from 'app/components/links/IpLink';
import InterfaceBoundaryASNs from 'app/components/InterfaceBoundaryASNs';

import KeyValueTable from 'core/components/KeyValueTable';
import storeLoader from 'app/stores/storeLoader';
import InterfaceDisplayValue from '../../InterfaceDisplayValue';

const OverrideIndicator = ({ displayValue, initialValue, originalValue }) => (
  <>
    <>{displayValue}</>
    {originalValue !== initialValue && (
      <Text ml="4px" muted small>
        (Overridden)
      </Text>
    )}
  </>
);

@storeLoader('$sites')
@inject('$dictionary')
@observer
class InterfaceMetadata extends Component {
  render = () => {
    const { capacityPlans, selectedInterface, $sites, $dictionary } = this.props;
    const siteId = selectedInterface && selectedInterface.get('device').get('site_id');
    const siteTitle = siteId && $sites.collection.get(siteId) && $sites.collection.get(siteId).get('title');

    const keyValuePairs = [
      {
        key: 'Device',
        value: <DeviceLink name={selectedInterface.get('device').get('device_name')} fontWeight="bold" />,
        asText: false
      },
      {
        key: 'Site',
        value: siteId ? <SiteLink siteId={siteId}>{siteTitle}</SiteLink> : 'None',
        asText: true
      },
      {
        key: 'SNMP ID',
        value: selectedInterface.get('snmp_id')
      },
      {
        key: 'Alias',
        value: (
          <OverrideIndicator
            displayValue={selectedInterface.attributes.snmp_alias}
            originalValue={selectedInterface.attributes.snmp_alias}
            initialValue={selectedInterface.attributes.initial_snmp_alias}
          />
        )
      },
      // Lower Sublayer Interface & Parent Interface: conditionally added below
      {
        key: 'Type',
        value: (
          <>
            {$dictionary.get('snmpMetadata.snmpType')[selectedInterface.get('snmp_type')]}(
            {selectedInterface.get('snmp_type')})
          </>
        )
      },
      {
        key: 'Speed',
        value: (
          <OverrideIndicator
            displayValue={formatBytesGreek(selectedInterface.attributes.snmp_speed * 1000000, 'bits/s')}
            originalValue={selectedInterface.attributes.snmp_speed}
            initialValue={selectedInterface.attributes.initial_snmp_speed}
          />
        )
      },
      {
        key: 'MTU',
        value: selectedInterface.attributes.interfaceKvs?.ifMtu
      },
      {
        key: 'Physical Address',
        value: hexToMac(selectedInterface.attributes.interfaceKvs?.ifPhysAddress)
      },
      {
        key: 'Connector Present',
        value: selectedInterface.attributes.interfaceKvs?.ifConnectorPresent
      },
      {
        key: 'IP',
        value: !selectedInterface.get('interface_ip') ? (
          'None'
        ) : (
          <IpLink
            ip_address={toCIDRNotation(
              selectedInterface.get('interface_ip'),
              selectedInterface.get('interface_ip_netmask')
            )}
          />
        )
      },
      // Additional IPs: conditionally added below
      {
        key: 'VRF',
        value: selectedInterface.get('vrf.name') || 'None'
      },
      {
        key: 'Boundary ASNs',
        value: <InterfaceBoundaryASNs model={selectedInterface} />,
        asText: false
      }
    ];

    if (selectedInterface.get('secondary_ips').length > 0) {
      keyValuePairs.splice(-2, 0, {
        key: 'Additional IPs',
        value: (
          <>
            {selectedInterface.get('secondary_ips').map((ip) => (
              <Text key={ip.address} fontWeight="bold" as="div" ellipsis>
                <IpLink ip_address={toCIDRNotation(ip.address, ip.netmask)} />
              </Text>
            ))}
          </>
        ),
        asText: false
      });
    }

    if (selectedInterface.attributes.interfaceKvs?.isAbove) {
      keyValuePairs.splice(3, 0, {
        key: 'Lower Sublayer Interface',
        value: <InterfaceDisplayValue model={selectedInterface} property="isAbove" />
      });
    }

    if (selectedInterface.attributes.interfaceKvs?.isBelow) {
      keyValuePairs.splice(3, 0, {
        key: 'Parent Interface',
        value: <InterfaceDisplayValue model={selectedInterface} property="isBelow" />
      });
    }

    if (capacityPlans.length === 0) {
      keyValuePairs.push({
        key: 'Capacity',
        value: selectedInterface.capacityLabel
      });
    }

    return (
      <Box flex={1} pl={3}>
        <Text as="div" fontWeight="bold" fontSize={16} mb={1}>
          Metadata
        </Text>

        <KeyValueTable keyValuePairs={keyValuePairs} />
      </Box>
    );
  };
}

export default InterfaceMetadata;
