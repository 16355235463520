import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ReconQueryWidget from './ReconQueryWidget';

const defaultWidgetTitle = 'Interface Status';

const QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/interfaces/counters',
    dimensions: ['device_name', 'name', 'speed'],
    metrics: [
      {
        name: 'admin-status',
        type: 'gauge'
      },
      {
        name: 'oper-status',
        type: 'gauge'
      }
    ],
    range: {
      lookback: 'PT3600S'
    },
    window: {
      size: 0,
      fn: {
        'admin-status': 'last',
        'oper-status': 'last'
      }
    },
    rollups: {
      last_if_AdminStatus: {
        metric: 'admin-status',
        aggregate: 'last'
      },
      last_if_OperStatus: {
        metric: 'oper-status',
        aggregate: 'last'
      }
    },
    sort: [
      {
        name: 'last_if_AdminStatus',
        direction: 'desc'
      },
      {
        name: 'last_if_OperStatus',
        direction: 'desc'
      }
    ],
    limit: 100,
    includeTimeseries: 0,
    viz: {
      type: 'table'
    }
  }
};

@observer
class ReconInterfaceStatusWidget extends Component {
  static defaultProps = {
    defaultWidgetTitle,
    showDefaultOptions: false,
    useSimplifiedRollupDisplay: false,
    hideTotalRow: true,
    config: {
      query: QUERY
    }
  };

  render() {
    return <ReconQueryWidget {...this.props} />;
  }
}

export default ReconInterfaceStatusWidget;
