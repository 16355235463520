/**
 * Wrapper for CSV stringify that safely handles escaping special characters and other niceties.
 * To be preferred over manually building CSV structures.
 * ESM module included in __mocks__ to appease Jest.
 */
import { stringify } from 'csv-stringify/browser/esm/sync';

export function arrayToCsv(lines, columns, delimiter = ',') {
  return stringify(lines, { header: true, columns, delimiter });
}
