import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import styled from 'styled-components';

const AnchorComponent = ({ node, href, children, ...props }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
    {children}
  </a>
);

// align center is the default, but leaving this here in case Dave changes his mind.
const KatexWrap = styled.div`
  .katex-display > .katex {
    text-align: center;
  }
  strong {
    font-weight: ${({ theme }) => theme.fontWeights.heavy};
  }
  font-size: ${({ theme }) => theme.fontSizes.body};
`;

class Markdown extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <KatexWrap>
        <ReactMarkdown
          components={{
            a: AnchorComponent
          }}
          remarkPlugins={[remarkGfm, remarkMath]}
          rehypePlugins={[rehypeKatex]}
        >
          {children}
        </ReactMarkdown>
      </KatexWrap>
    );
  }
}

export default Markdown;
