import React from 'react';

import { Box, Card, HTMLTable, Text } from 'core/components';
import formatMetricValue from 'app/util/formatMetricValue';

const FlowResultsTable = ({ flowData, totalFlow }) => (
  <Card>
    <HTMLTable condensed width="100%" className="no-header-bordered">
      <tbody>
        {flowData
          .sort((a, b) => b.value - a.value)
          .map((flowItem) => {
            const percentage = totalFlow ? Math.round((flowItem.value / totalFlow) * 100) : 0;
            const { value: formattedValue, metric: formattedMetric } = formatMetricValue(flowItem.value, 'bits');

            return (
              <tr key={flowItem.key}>
                <Box as="td" color="muted">
                  <Text>{flowItem.key.replace(/\(.*\)/gi, '')}</Text>
                  {totalFlow && <Text color="muted">({`${percentage}%`})</Text>}
                </Box>
                <Box as="td" textAlign="right" color="muted">
                  {formattedValue} {formattedMetric}
                </Box>
              </tr>
            );
          })}
      </tbody>
    </HTMLTable>
  </Card>
);

export default FlowResultsTable;
