import React from 'react';
import styled from 'styled-components';
import { display, lineHeight, space, overflow, minHeight } from 'styled-system';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import position from './utils/position';
import size from './utils/size';
import flex from './utils/flex';

const DialogBodyStyled = styled.div`
  ${display};
  ${lineHeight};
  ${overflow};
  ${space};

  ${flex};
  ${position};
  ${size};
  ${minHeight};
`;

const DialogBody = ({ children, className, ...rest }) => (
  <DialogBodyStyled className={classNames(className, Classes.DIALOG_BODY)} {...rest}>
    {children}
  </DialogBodyStyled>
);

DialogBody.defaultProps = {
  lineHeight: 1.285,
  m: 0,
  p: 2,
  overflow: 'auto'
};

export default DialogBody;
