import React from 'react';
import { Tag } from 'core/components';

import { flatten } from 'app/util/dictionaryUtils';
import DimensionButton from 'app/components/DimensionButton';

import Dimension from './Dimension';

const DimensionValueRenderer = ({
  option,
  options,
  onClick,
  readOnly,
  noRemove,
  onUnselect,
  field,
  useNmsDimensions
}) => {
  let group = option && option.group;
  if (group === 'Filter-Based') {
    return (
      <Tag intent="info" fontWeight="bold" outline round useChartTypes p="3px 6px 3px 8px" m={0}>
        <Tag bg="black.3" color="#ffffff" fontSize={10} ml="3px" mr="5px">
          {group}
        </Tag>
        {option.label}
      </Tag>
    );
  }

  if (onUnselect && option) {
    const onRemove =
      !readOnly && !noRemove
        ? (e) => {
            e.preventDefault();
            e.stopPropagation();
            onUnselect(field, option.value);
          }
        : undefined;

    return (
      <Dimension
        useNmsDimensions={useNmsDimensions}
        dimension={option.value}
        options={field.useFieldOptions && flatten(options)}
        fontWeight="bold"
        outline
        round
        useChartTypes
        onRemove={onRemove}
        p="3px 6px 3px 8px"
        m={0}
      />
    );
  }

  if (!option && field.getValue()) {
    group = 'Invalid';
  }

  return (
    <DimensionButton
      option={option}
      group={group}
      rightIcon="chevron-down"
      onClick={onClick}
      readOnly={readOnly}
      small
    />
  );
};

export default DimensionValueRenderer;
