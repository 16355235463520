import React from 'react';
import { Text, Icon } from 'core/components';
import { ReactComponent as BsArrowUpRightCircleFill } from 'app/assets/icons/arrow-up-right-circle-fill.svg';
import { ReactComponent as BsArrowDownRightCircleFill } from 'app/assets/icons/arrow-down-right-circle-fill.svg';
import { ReactComponent as BsArrowRightCircleFill } from 'app/assets/icons/arrow-right-circle-fill.svg';

const TrendChange = ({
  trendValue,
  trendRawValue,
  unitsLabel,
  increaseIntent = 'success',
  decreaseIntent = 'danger',
  noChange = 'No Change',
  ...textProps
}) => {
  if (trendRawValue !== 0) {
    return (
      <Text color={trendRawValue < 0 ? decreaseIntent : increaseIntent} {...textProps}>
        <Icon icon={trendRawValue < 0 ? BsArrowDownRightCircleFill : BsArrowUpRightCircleFill} iconSize={12} m="2px" />
        {trendValue} {unitsLabel}
      </Text>
    );
  }

  return (
    <Text {...textProps}>
      <Icon icon={BsArrowRightCircleFill} iconSize={12} m="2px" />
      {noChange}
    </Text>
  );
};

export default TrendChange;
