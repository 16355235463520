import React from 'react';
import { observer } from 'mobx-react';
import { Flex, Icon, Text } from 'core/components';

const Trend = observer(({ direction, loading, containerProps, iconProps, ...rest }) => {
  const color = direction === 'up' ? 'success' : 'danger';
  const icon = direction === 'up' ? 'arrow-up' : 'arrow-down';

  if (loading) {
    return (
      <Text fontSize="small" color="muted">
        Calculating trend...
      </Text>
    );
  }

  return (
    <Flex alignItems="center" {...containerProps}>
      <Icon color={color} icon={icon} css={{ position: 'relative', top: 2 }} {...iconProps} />
      <Text fontSize="small" pt="3px" color={color} {...rest} />
    </Flex>
  );
});

export default Trend;
