import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('CityAggregate'))
class CityAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="Cities"
        resultsKey="City"
        insightsQuery="fetchCityInsights"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'City',
          aggregateFilters: [
            { metric: ['src_geo_city', 'dst_geo_city'] },
            { metric: ['src_geo_city'] },
            { metric: ['dst_geo_city'] },
            { metric: ['src_geo_city', 'dst_geo_city'] },
            { metric: ['src_geo_city', 'dst_geo_city'] }
          ]
        }}
        chartProps={{
          metrics: {
            Internal: ['dst_geo_city', 'src_geo_city'],
            Inbound: 'src_geo_city',
            Outbound: 'dst_geo_city'
          },
          topxMetricField: 'src|dst_geo_city'
        }}
      />
    );
  }
}

export default CityAggregate;
