/* eslint-disable no-use-before-define */
import * as React from 'react';
import { get } from 'lodash';
import { ENTITY_TYPES, MAP_TYPES } from 'shared/hybrid/constants';
import { Flex, Heading } from 'core/components';
import $devices from 'app/stores/device/$devices';
import $sites from 'app/stores/site/$sites';
import { cloudProviderData } from 'app/util/utils';
import { groupConnections, getLinkLabel, getConnectionsForInterface } from 'app/views/hybrid/utils/links';
import { VPNStatusTag } from 'app/views/hybrid/maps/gcp/components/VPNStatus';
import Model from 'core/model/Model';
import { uriToObject, getCustomProperties, isGoogleCloud } from 'shared/util/map';
import {
  getInterconnectAttachmentMetadata,
  getVpnGatewayMetadata,
  getVpnTunnelMetadata,
  getGcpNatGatewayMetadata
} from './gcp/utils';

import {
  getOCILinkQueryOptions,
  getCloudPopoverQueryOptions,
  getCloudRegionPopoverQueryOptions,
  getCloudVpcPopoverQueryOptions,
  getCloudSubnetPopoverQueryOptions,
  getCloudGatewayPopoverQueryOptions,
  getDevicePopoverQueryOptions,
  getInterfacePopoverQueryOptions,
  getInterfaceCountersConfig,
  getInternetPopoverQueryOptions,
  getLinkPopoverQueryOptions,
  getSitePopoverQueryOptions,
  getSiteDetailPopoverQueryOptions,
  getSiteToSiteLinkPopoverQueryOptions,
  getCloudToCloudLinkPopoverQueryOptions,
  getK8OriginNetworksQueryOptions,
  getServicePopoverQueryOptions,
  getCloudInterconnectQueryOptions,
  getCustomerGatewayQueryOptions,
  getClusterPopoverTrafficQueryOptions,
  getClusterPopoverPerformanceQueryOptions,
  getKubePopoverTrafficQueryOptions,
  getKubePopoverPerformanceQueryOptions,
  getKubeToKubeLinkPopoverQueryOptions,
  getK8NodePopoverQueryOptions,
  getK8PodPopoverQueryOptions,
  getAWSLinkQueryOptions,
  getVpcEndpointQueryOptions,
  getAzureExpressRouteCircuitQueryOptions,
  getAzureLoadBalancerQueryOptions,
  getAzureFirewallQueryOptions,
  getGCPInterconnectAttachmentQueryOptions,
  getGCPVpnGatewayQueryOptions,
  getGCPNatGatewayQueryOptions
} from './queryOptionsHelper';
import { getYAML } from '../../kube/YAMLRenderer';

const OCI_ENTITY_TYPES = ENTITY_TYPES.get('oci');
const AWS_ENTITY_TYPES = ENTITY_TYPES.get('aws');
const GCP_ENTITY_TYPES = ENTITY_TYPES.get('gcp');
const AZURE_ENTITY_TYPES = ENTITY_TYPES.get('azure');
const KUBE_ENTITY_TYPES = ENTITY_TYPES.get('kube');

const internetTypeLabels = {
  asn: 'ASN',
  provider: 'Provider',
  nextHopASN: 'Next-Hop Network'
};

/*
  Takes an initial config of type 'internet' (asn, provider, next-hop network)
  and decorates with additional properties used for determining its popover content

  Config Properties:
  - type: internet
  - subType: asn|provider|nextHopASN
  - value: original string value
  - id: determined by parsing the value. asn and next-hop networks will be asNumber, provider will be asName
  - asNumber: parsed from the value
  - asName: parsed from the value
  - label: determined by the subType
*/
function makeInternetConfig(initialConfig) {
  const { nodeData, value, subType } = initialConfig;
  const regex = /(.+)\((.+)\)$/;
  const match = value.match(regex);

  // initialize the config
  const config = {
    ...initialConfig,
    id: value,
    label: internetTypeLabels[subType],
    asName: value,
    asNumber: null
  };

  if (match) {
    // uses the parsed values to break out asName and number for asn and next-hop types
    const [, asName, asNumber] = match;

    config.id = asNumber;
    config.asName = asName;
    config.asNumber = asNumber;
  }

  const queryOptions =
    nodeData?.subType === 'internet-k8'
      ? getK8OriginNetworksQueryOptions(config)
      : getInternetPopoverQueryOptions(config);

  return {
    ...config,
    queryOptions
  };
}

function makeServiceConfig(initialConfig) {
  const config = {
    ...initialConfig
  };

  return {
    ...config,
    queryOptions: getServicePopoverQueryOptions(config)
  };
}

function getSiteToSiteConnectionSummary({ source, target, links }) {
  const sites = [source, target];
  const siteLinks = links.filter(
    (link) =>
      (link.site1_id === source.model.get('id') || link.site1_id === target.model.get('id')) &&
      (link.site2_id === source.model.get('id') || link.site2_id === target.model.get('id'))
  );

  if (siteLinks.length > 0) {
    return siteLinks.reduce(
      (acc, link) => {
        const capacity =
          link.snmp_speed ??
          link.connections?.reduce((total, connection) => total + (connection.interface1.snmp_speed || 0), 0) ??
          0;

        return {
          site1: sites.find((site) => site.id === link.site1_id),
          site2: sites.find((site) => site.id === link.site2_id),
          capacity: acc.capacity + capacity,
          siteToSiteLinks: acc.siteToSiteLinks.concat(link),
          connections: acc.connections.concat(link.connections || [])
        };
      },
      {
        site1: null,
        site2: null,
        capacity: 0,
        siteToSiteLinks: [],
        connections: []
      }
    );
  }

  return {
    site1: source,
    site2: target,
    capacity: 0,
    siteToSiteLinks: [],
    connections: []
  };
}

function makeSiteConfig(initialConfig) {
  const { value } = initialConfig;
  // @TODO - fix site ids being either an id or a title
  const model = $sites.collection.models.find((m) => {
    if (typeof value === 'string') {
      // topology response may not return a site name cased like it is in the $sites collection
      return m.get('title')?.toLowerCase() === value?.toLowerCase();
    }

    return m.id === value;
  });
  const config = { ...initialConfig, id: model.get('id'), model };
  const isSiteDetail = config.detail && config.detail.type === 'site';

  return {
    ...config,
    queryOptions: isSiteDetail ? getSiteDetailPopoverQueryOptions(config) : getSitePopoverQueryOptions(config)
  };
}

function makeSiteToSiteLinkConfig(initialConfig) {
  const { source, target, links } = initialConfig;
  const connectionSummary = getSiteToSiteConnectionSummary({ source, target, links });
  const groupedConnections = groupConnections(connectionSummary?.siteToSiteLinks ?? []);
  const config = { ...initialConfig, connectionSummary };

  return {
    ...config,
    interfaceCountersConfig: getInterfaceCountersConfig(connectionSummary?.connections ?? []),
    queryOptions: getSiteToSiteLinkPopoverQueryOptions(config),
    linkLabel: getLinkLabel(groupedConnections),
    groupedConnections
  };
}

function makeDeviceToDeviceConfig(initialConfig) {
  const { links } = initialConfig;
  const groupedConnections = groupConnections(links);
  const connections = [].concat(groupedConnections.layer3, groupedConnections.layer2);

  return {
    ...initialConfig,
    groupedConnections,
    linkLabel: getLinkLabel(groupedConnections),
    queryOptions: getInterfacePopoverQueryOptions(connections),
    interfaceCountersConfig: getInterfaceCountersConfig(connections)
  };
}

function makeCloudToCloudLinkConfig(initialConfig) {
  return { ...initialConfig, queryOptions: getCloudToCloudLinkPopoverQueryOptions(initialConfig) };
}

function makeKubeToKubeLinkConfig(initialConfig) {
  const queryOptions = getKubeToKubeLinkPopoverQueryOptions(initialConfig);

  return {
    ...initialConfig,
    performance: true,
    isKubeDetail: true,
    queryOptions,
    performanceQueryOptions: queryOptions
  };
}

function makeCloudConfig(initialConfig) {
  const { value } = initialConfig;
  const cloudDetails = cloudProviderData(value);
  const config = { ...initialConfig, ...cloudDetails };

  return {
    ...config,
    queryOptions: getCloudPopoverQueryOptions(config)
  };
}

function makeCloudRegionConfig(initialConfig) {
  const { cloudProvider } = initialConfig;
  const cloudDetails = cloudProviderData(cloudProvider);
  const config = { ...initialConfig, ...cloudDetails };

  return {
    ...config,
    queryOptions: getCloudRegionPopoverQueryOptions(config)
  };
}

function makeCloudVpcConfig(initialConfig) {
  const { cloudProvider } = initialConfig;
  const cloudDetails = cloudProviderData(cloudProvider);
  const config = { ...initialConfig, ...cloudDetails, subType: cloudProvider === 'azure' ? 'VNet' : 'VPC' };

  return {
    ...config,
    queryOptions: getCloudVpcPopoverQueryOptions(config)
  };
}

function makeCloudInterconnectConfig(initialConfig) {
  const { cloudProvider } = initialConfig;
  const cloudDetails = cloudProviderData(cloudProvider);
  const config = { ...initialConfig, ...cloudDetails };
  const routeTableSummary = config.transitGatewayRouteTableSummary || config.routeTableSummary;

  if (config.subType === 'VpnConnection') {
    const { routeTableSummary: routeTables, ...vpnConnectionConfig } = config;
    return { ...vpnConnectionConfig, performance: true };
  }

  return {
    ...config,
    queryOptions:
      config.subType === 'CustomerGateway'
        ? getCustomerGatewayQueryOptions(config)
        : getCloudInterconnectQueryOptions(config),
    performance: true,
    routeTableSummary
  };
}

function makeCloudSubnetConfig(initialConfig) {
  const { cloudProvider } = initialConfig;
  const cloudDetails = cloudProviderData(cloudProvider);
  const config = { ...initialConfig, ...cloudDetails };

  return {
    ...config,
    queryOptions: getCloudSubnetPopoverQueryOptions(config)
  };
}

function makeCloudGatewayConfig(initialConfig) {
  const { cloudProvider } = initialConfig;
  const cloudDetails = cloudProviderData(cloudProvider);
  const config = { ...initialConfig, ...cloudDetails };

  return {
    ...config,
    queryOptions: getCloudGatewayPopoverQueryOptions(config)
  };
}

function makeVpcEndpointsConfig(initialConfig) {
  const { cloudProvider } = initialConfig;
  const cloudDetails = cloudProviderData(cloudProvider);
  const config = { ...initialConfig, ...cloudDetails };

  return {
    ...config,
    queryOptions: getVpcEndpointQueryOptions(config)
  };
}

function makeCoreNetworkAttachmentConfig(initialConfig) {
  const { cloudProvider } = initialConfig;
  const cloudDetails = cloudProviderData(cloudProvider);
  // prioritize passed config over default
  const config = { ...cloudDetails, ...initialConfig };

  return {
    ...config,
    queryOptions: getCloudGatewayPopoverQueryOptions(config)
  };
}

function makeCoreNetworkEdgeConfig(initialConfig) {
  const { cloudProvider } = initialConfig;
  const cloudDetails = cloudProviderData(cloudProvider);
  // prioritize passed config over default
  const config = { ...cloudDetails, ...initialConfig };

  return {
    ...config,
    queryOptions: getCloudGatewayPopoverQueryOptions(config)
  };
}

function makeCustomerGatewayConfig(initialConfig) {
  const { cloudProvider } = initialConfig;
  const cloudDetails = cloudProviderData(cloudProvider);
  // prioritize passed config over default
  const config = { ...cloudDetails, ...initialConfig };

  return {
    ...config,
    queryOptions: getCustomerGatewayQueryOptions(config)
  };
}

function makeDeviceConfig(initialConfig) {
  const { value } = initialConfig;
  const model = $devices.collection.models.find((d) => d.get('id') === value.toString());
  const config = { ...initialConfig, id: model.get('id'), model };

  return {
    ...config,
    queryOptions: getDevicePopoverQueryOptions(config)
  };
}

function makeLayeredDeviceConfig(initialConfig) {
  // upstream, downstream, and parallel configs come in a bit different
  // this is just to normalize them into a standard device config we use elsewhere
  return makeDeviceConfig({
    ...initialConfig,
    type: 'device',
    value: initialConfig.value.id
  });
}

function makeInterfaceConfig(initialConfig) {
  const { value, links } = initialConfig;
  const groupedConnections = groupConnections(links, { forInterface: value });

  if (groupedConnections.layer3.length === 0 && groupedConnections.layer2.length === 0) {
    if (links.length === 0 && value) {
      groupedConnections.unknown = [{ interface1: value, interface2: null }];
    } else {
      groupedConnections.unknown = links.map(({ connections }) => connections).flat();
    }
  }

  let connections = [].concat(groupedConnections.layer3, groupedConnections.layer2, groupedConnections.unknown || []);

  if (value) {
    connections = getConnectionsForInterface(connections, value);
  }

  return {
    ...initialConfig,
    groupedConnections,
    linkLabel: getLinkLabel(groupedConnections),
    queryOptions: getInterfacePopoverQueryOptions(connections, value),
    interfaceCountersConfig: getInterfaceCountersConfig(connections)
  };
}

function makeOCILinkConfig(initialConfig) {
  const { source, target } = initialConfig;
  const segmentTypes = [source?.type, target?.type];
  const onPremEntities = MAP_TYPES.get('oci.on_prem');
  // we cannot create traffic queries that involve on prem entities (lags, direct connections, direct connect gateways, vpn connections)
  // in this situation we will return a null config which is the same behavior as clicking the background on the map
  const isInvalid = segmentTypes.some((segmentType) => onPremEntities.includes(segmentType));

  if (isInvalid) {
    return null;
  }

  return {
    ...initialConfig,
    queryOptions: getOCILinkQueryOptions(initialConfig)
  };
}

function makeAWSLinkConfig(initialConfig) {
  const { source, target } = initialConfig;
  const segmentTypes = [source?.type, target?.type];
  const onPremEntities = MAP_TYPES.get('aws.on_prem');
  // we cannot create traffic queries that involve on prem entities (lags, direct connections, direct connect gateways, vpn connections)
  // in this situation we will return a null config which is the same behavior as clicking the background on the map
  const isInvalid = segmentTypes.some((segmentType) => onPremEntities.includes(segmentType));

  if (isInvalid) {
    return null;
  }

  return {
    ...initialConfig,
    queryOptions: getAWSLinkQueryOptions(initialConfig)
  };
}

function makeLinkConfig(initialConfig) {
  const { target, links = [], internetSubType, queryOptions } = initialConfig;
  const connectedLinks = links.filter((link) => link?.target?.type === target.value);
  const connectedTargets = connectedLinks.map((link) => {
    const config = makeConfig(link.target);

    if (config.type === 'internet') {
      // internet links aren't shipped with their sub types so we'll add them here
      config.subType = internetSubType;
    }

    return config;
  });

  const config = {
    ...initialConfig,
    target: {
      ...target,
      connectedTargets
    }
  };

  return {
    ...config,
    queryOptions: queryOptions || getLinkPopoverQueryOptions(config)
  };
}

function makeK8NodeConfig(initialConfig) {
  const { value, nodeData } = initialConfig;
  const id = value.toString();
  const model = new Model({ id });
  const config = { ...initialConfig, id: value.toString(), model, nodeData };

  return {
    ...config,
    performance: true,
    isKubeDetail: true,
    pods: true,
    queryOptions: getK8NodePopoverQueryOptions(config),
    performanceQueryOptions: getK8NodePopoverQueryOptions(config)
  };
}

function makeKubeResourceConfig(initialConfig) {
  const config = initialConfig;
  const { nodeData } = config;
  let tabs;

  if (['namespaces', 'services', 'ingresses', 'deployments', 'pods', 'nodes'].includes(nodeData.type)) {
    tabs = [{ title: 'YAML', panel: getYAML(nodeData) }];
  }

  return {
    ...config,
    showHealthCallout: true,
    performance: true,
    isKubeDetail: true,
    tabs,
    queryOptions: getKubePopoverTrafficQueryOptions(config),
    performanceQueryOptions: getKubePopoverPerformanceQueryOptions(config)
  };
}

function makeClusterConfig(initialConfig) {
  const { nodeData } = initialConfig;
  const config = { ...initialConfig, id: nodeData.id, nodeData };

  return {
    ...config,
    performance: true,
    isKubeDetail: true,
    queryOptions: getClusterPopoverTrafficQueryOptions(config),
    performanceQueryOptions: getClusterPopoverPerformanceQueryOptions(config)
  };
}

function makeK8PodConfig(initialConfig) {
  const { value } = initialConfig;
  const config = { ...initialConfig, id: value.toString() };

  return {
    ...config,
    performance: true,
    isKubeDetail: true,
    containers: true,
    queryOptions: getK8PodPopoverQueryOptions(config),
    performanceQueryOptions: getK8PodPopoverQueryOptions(config)
  };
}

/*
  Represents an Azure Location entity
*/
function makeLocationConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const cloudDetails = cloudProviderData(cloudProvider);
  const config = { ...initialConfig, ...cloudDetails };
  const customProperties = getCustomProperties(nodeData);

  return {
    ...config,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Cloud Location Details',
      subTitle: nodeData.displayName,
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        'Physical Location': nodeData.metadata?.physicalLocation,
        Latitude: nodeData.metadata?.latitude,
        Longitude: nodeData.metadata?.longitude
      }
    },
    queryOptions: getCloudRegionPopoverQueryOptions(config)
  };
}

/*
  Represents an Azure VNet Gateway entity
*/
function makeVNetGatewayConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'VNet Gateway Details',
      subTitle: nodeData.name,
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        resourceGroup: customProperties.resourceGroup
      }
    }
  };
}

/**
 * Represents an Azure Firewall entity
 */
function makeFirewallConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  const publicIpAddresses = get(nodeData, 'properties.hubIPAddresses.publicIPs.addresses', []).map(
    ({ address }) => address
  );
  const privateIpAdress = get(nodeData, 'properties.hubIPAddresses.privateIPAddress');

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Firewall Details',
      subTitle: nodeData.name,
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        resourceGroup: customProperties.resourceGroup,
        privateIpAdress,
        publicIpAddresses
      }
    },
    queryOptions: getAzureFirewallQueryOptions(initialConfig)
  };
}

/**
 * Represents an Azure VPN Gateway entity
 */
function makeAzureVpnGatewayConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'VPN Gateway Details',
      subTitle: nodeData.name,
      keys: ['tenantId', 'subscriptionId', 'location', 'sku', 'provisioningState'],
      node: {
        ...nodeData,
        ...customProperties,
        ...nodeData.properties
      }
    }
  };
}

/*
  Represents an Azure VNet Peering entity
*/
function makeVNetPeeringConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);
  const fromVNet = uriToObject(customProperties.fromVNet);
  const toVNet = uriToObject(customProperties.toVNet);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'VNet Peering Details',
      subTitle: nodeData.name,
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        resourceGroup: customProperties.resourceGroup,
        'Local Network': fromVNet?.virtualNetwork,
        'Local Network Subscription': fromVNet?.subscription,
        'Local Network Resource Group': fromVNet?.resourceGroup,
        'Local VNET ID': customProperties.fromVNet,
        'Remote Network': toVNet?.virtualNetwork,
        'Remote Network Subscription': toVNet?.subscription,
        'Remote Network Resource Group': toVNet?.resourceGroup,
        'Remote VNET ID': customProperties.toVNet,
        'Remote VNET CIDR': nodeData.properties?.remoteVirtualNetworkAddressSpace?.addressPrefixes
      }
    }
  };
}

/*
  Represents an Express Route Circuit entity
*/
function makeExpressRouteCircuitConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    queryOptions: getAzureExpressRouteCircuitQueryOptions(initialConfig),
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Express Route Circuit Details',
      subTitle: nodeData.name,
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        location: nodeData?.location,
        ServiceProvider: nodeData?.properties?.serviceProviderProperties?.serviceProviderName,
        ServiceProviderLocation: nodeData?.properties?.serviceProviderProperties?.peeringLocation
      }
    }
  };
}

/*
  Represents a Virtual WAN entity
*/
function makeVirtualWanConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Virtual Wan Details',
      subTitle: nodeData.name,
      keys: ['tenantId', 'subscriptionId', 'location', 'type', 'provisioningState', 'allowBranchToBranchTraffic'],
      node: {
        ...nodeData,
        ...customProperties,
        ...nodeData.properties
      }
    }
  };
}
/*
  Represents a Virtual HUB entity
*/
function makeVirtualHubConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Virtual Hub Details',
      subTitle: nodeData.name,
      keys: [
        'tenantId',
        'subscriptionId',
        'location',
        'sku',
        'provisioningState',
        'addressPrefix',
        'hubRoutingPreference',
        'virtualRouterIps',
        'virtualRouterAsn',
        'virtualRouterAutoScaleConfiguration.minCapacity'
      ],
      node: {
        ...nodeData,
        ...customProperties,
        ...nodeData.properties
      }
    }
  };
}

/*
  Represents a HUB VNET Connection
*/
function makeHubVirtualNetworkConnectionConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'VNet Connection Details',
      subTitle: nodeData.name,
      keys: [
        'tenantId',
        'subscriptionId',
        'resourceGroup',
        'Provisioning State',
        'Connectivity Status',
        'Virtual Network'
      ],
      node: {
        ...nodeData,
        ...customProperties,
        'Provisioning State': nodeData?.properties?.provisioningState,
        'Connectivity Status': nodeData?.properties?.connectivityStatus,
        'Virtual Network': uriToObject(nodeData?.properties?.remoteVirtualNetwork?.id).virtualNetwork
      }
    }
  };
}

/*
  Represents an Azure VNet Gateway Connection entity
*/
function makeVNetGatewayConnectionConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);
  const vnetGateway = uriToObject(nodeData.properties?.virtualNetworkGateway1?.id);
  const localNetworkGateway = uriToObject(nodeData.properties?.localNetworkGateway2?.id);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'VNet Gateway Connection Details',
      subTitle: nodeData.name,
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        resourceGroup: customProperties.resourceGroup,
        'VNet Gateway': vnetGateway.virtualNetworkGateway,
        'Local Gateway': localNetworkGateway.localNetworkGateway
      }
    }
  };
}

/*
  Represents an Azure NAT Gateway entity
*/
function makeNATGatewayConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);
  const subnets = (nodeData.properties?.subnets || []).map((subnet) => uriToObject(subnet.id).subnet);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'NAT Gateway Details',
      subTitle: nodeData.name,
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        Subnets: subnets.join(', ')
      }
    }
  };
}

/*
  Represents an Azure NAT Gateway entity
*/
function makeExpressRouteGatewayConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Express Route Gateway',
      subTitle: nodeData.name,
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId
      }
    }
  };
}

/*
  Represents an Azure P2S VPN Gateway entity
*/
function makeP2SVpnGatewayConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Express Route Gateway',
      subTitle: nodeData.name,
      keys: [
        'tenantId',
        'subscriptionId',
        'location',
        'vpnGatewayDetachStatus',
        'customDnsServers',
        'vpnServerConfigurationLocation'
      ],
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        ...nodeData,
        ...nodeData?.properties
      }
    }
  };
}

/*
  Represents an Azure VPN Site entity
*/
function makeVPNSiteConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Express Route Gateway',
      subTitle: nodeData.name,
      keys: [
        'name',
        'tenantId',
        'subscriptionId',
        'location',
        'addressSpace.addressPrefixes',
        'deviceProperties.deviceVendor',
        'deviceProperties.linkSpeedInMbps',
        'vpnServerConfigurationLocation'
      ],
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        ...nodeData,
        ...nodeData?.properties
      }
    }
  };
}

/*
  Represents an Azure Local Network Gateway entity
*/
function makeLocalNetworkGatewayConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Local Network Gateway Details',
      subTitle: nodeData.name,
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        resourceGroup: customProperties.resourceGroup,
        'Gateway Address': nodeData.properties?.gatewayIpAddress,
        'Local Networks': (nodeData.properties?.localNetworkAddressSpace?.addressPrefixes || []).join(', ')
      }
    }
  };
}

/*
  Represents a VPN Link Connection
*/
function makeVpnLinkConnectionConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'VPN Link Connection Details',
      subTitle: nodeData.name,
      keys: [
        'name',
        'tenantId',
        'subscriptionId',
        'provisioningState',
        'connectionBandwidth',
        'vpnConnectionProtocolType',
        'ingressBytesTransferred',
        'egressBytesTransferred',
        'connectionStatus'
      ],
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        ...nodeData,
        ...nodeData?.properties
      }
    }
  };
}

function makeExpressRouteConnectionConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'VPN Link Connection Details',
      subTitle: nodeData.name,
      keys: ['name', 'tenantId', 'subscriptionId', 'provisioningState'],
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        ...nodeData,
        ...nodeData?.properties
      }
    }
  };
}

function makeLoadBalancerConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Load Balancer Details',
      subTitle: nodeData.name,
      keys: ['name', 'tenantId', 'subscriptionId', 'provisioningState'],
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        ...nodeData,
        ...nodeData?.properties
      }
    },
    queryOptions: getAzureLoadBalancerQueryOptions(initialConfig)
  };
}

function makeApplicationGatewayConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Application Gateway Details',
      subTitle: nodeData.name,
      keys: [
        'name',
        'tenantId',
        'subscriptionId',
        'provisioningState',
        'sku.tier',
        'operationalState',
        'autoscaleConfiguration.minCapacity',
        'autoscaleConfiguration.maxCapacity'
      ],
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        ...nodeData,
        ...nodeData?.properties
      }
    }
  };
}

function makeRouteTableConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Route Table Details',
      subTitle: nodeData.name,
      keys: ['name', 'tenantId', 'subscriptionId', 'provisioningState'],
      node: {
        tenantId: customProperties.tenantId,
        subscriptionId: customProperties.subscriptionId,
        ...nodeData,
        ...nodeData?.properties
      }
    }
  };
}

function makeGCPExternalVPNGatewayConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'External VPN Gateway Details',
      subTitle: nodeData.name,
      node: {
        ...nodeData,
        ...customProperties,
        Redundancy: nodeData.redundancyType,
        'IP Addresses': nodeData.interfaces.map((inf) => inf.ipAddress)
      },
      keys: ['selfLink', 'project', 'id', 'name', 'description', 'Redundancy', 'IP Addresses']
    }
  };
}

function makeCloudRouterConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);
  const { network } = uriToObject(nodeData.network);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Cloud Router Details',
      subTitle: nodeData.name,
      node: {
        ...nodeData,
        ...customProperties,
        Network: network
      },
      keys: ['selfLink', 'project', 'Network', 'region', 'id', 'name', 'description']
    }
  };
}

function makeGcpInterconnectConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const {
    state,
    operationalStatus,
    peerIpAddress,
    googleIpAddress,
    googleReferenceId,
    provisionedLinkCount,
    interconnectType,
    linkType
  } = nodeData;
  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Interconnect Details',
      subTitle: nodeData.name,
      node: {
        'Admin Enabled': nodeData.adminEnabled ? 'true' : 'false',
        State: state,
        'Operational Status': operationalStatus,
        'Peer IP Address': peerIpAddress,
        'Google IP Address': googleIpAddress,
        'Google Reference Id': googleReferenceId,
        'Provisioned Link Count': provisionedLinkCount,
        'Interconnect Type': interconnectType,
        'Link Type': linkType,
        ...nodeData
      },
      keys: [
        'id',
        'State',
        'Operational Status',
        'region',
        'location',
        'Peer IP Address',
        'Google IP Address',
        'Google Reference Id',
        'Provisioned Link Count',
        'Interconnect Type',
        'Link Type',
        'Admin Enabled',
        'description'
      ]
    }
  };
}

function makeInterconnectAttachmentConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const config = getInterconnectAttachmentMetadata(nodeData);

  return {
    ...initialConfig,
    queryOptions: getGCPInterconnectAttachmentQueryOptions(initialConfig),
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Interconnect Attachment Details',
      subTitle: nodeData.name,
      ...config
    }
  };
}

function makeGcpVpnGatewayConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const config = getVpnGatewayMetadata(nodeData);

  return {
    ...initialConfig,
    queryOptions: getGCPVpnGatewayQueryOptions(initialConfig),
    details: {
      icon: { entity: type, cloudProvider },
      title: 'VPN Gateway Details',
      subTitle: nodeData.name,
      ...config
    }
  };
}

function makeGcpNatGwConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const config = getGcpNatGatewayMetadata(nodeData);

  return {
    ...initialConfig,
    queryOptions: getGCPNatGatewayQueryOptions(initialConfig),
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Nat Gateway Details',
      subTitle: nodeData.name,
      ...config
    }
  };
}

function makeVPNTunnelConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const config = getVpnTunnelMetadata(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'VPN Tunnel Details',
      subTitle: nodeData.name,
      ...config
    }
  };
}

function makeOciInternetGatewaysConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Internet Gateway Details',
      Name: nodeData.displayName,
      node: {
        ...nodeData
      },
      keys: ['id', 'Name', 'region', 'compartmentId', 'tenancyId', 'lifecycleState']
    }
  };
}

function makeOciServiceGatewaysConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;

  const serviceNames = (nodeData?.services ?? []).map((service) => service.serviceName);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Service Gateway Details',
      Name: nodeData.displayName,
      serviceNames,
      node: {
        Services: serviceNames,
        ...nodeData
      },
      keys: ['id', 'Name', 'region', 'compartmentId', 'tenancyId', 'lifecycleState', 'Services']
    }
  };
}

function makeOCINatGatewaysConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'NAT Gateway Details',
      Name: nodeData.displayName,
      node: {
        ...nodeData
      },
      keys: ['id', 'Name', 'region', 'compartmentId', 'tenancyId', 'natIp']
    }
  };
}

function makeDRGConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'DRG Details',
      subTitle: nodeData.displayName,
      node: {
        ...nodeData
      },
      keys: ['id', 'displayName', 'region', 'compartmentId', 'tenancyId']
    }
  };
}

function makeIpSecConnectionConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'IPSec Connection Details',
      subTitle: nodeData.displayName,
      node: {
        ...nodeData,
        ipAddress: nodeData?.cpeLocalIdentifier
      },
      keys: ['id', 'displayName', 'region', 'compartmentId', 'tenancyId', 'ipAddress']
    }
  };
}

function makeTargetVPNGatewayConfig(initialConfig) {
  const { cloudProvider, nodeData, type } = initialConfig;
  const customProperties = getCustomProperties(nodeData);

  return {
    ...initialConfig,
    details: {
      icon: { entity: type, cloudProvider },
      title: 'Target VPN Gateway Details',
      subTitle: nodeData.name,
      collapsedPanelTitle: (
        <Flex gap={1}>
          <Heading level={5} m={0}>
            Details
          </Heading>
          <VPNStatusTag status={nodeData.status} />
        </Flex>
      ),
      node: {
        ...nodeData,
        ...customProperties,
        Status: nodeData.status
      },
      keys: ['selfLink', 'project', 'id', 'name', 'description', 'Status'],
      renderItem: (key, value) => {
        if (key === 'Status') {
          return <VPNStatusTag status={value} />;
        }

        return null;
      }
    }
  };
}

export function makeConfig(initialConfig) {
  const { source, target, cloudProvider, isKubeDetail = false } = initialConfig;

  // aws started with a hard-coded scheme of types from topology preserving the plural form but stripping it to describe things like links and entity types that expect to create a config
  // we've been integrating a single-source entity type constants for azure and gcp entity types and prefer to preserve the original entity type in its plural form
  // over time, we'll probably do the same for aws but for now we'll support both scenarios for backwards compatibility
  const type =
    // excluding kube details as it has its own topology entity types
    ['azure', 'gcp', 'oci', 'aws'].includes(cloudProvider) || isKubeDetail
      ? initialConfig.type
      : String(initialConfig.type).replace(/s$/, '');

  // these are 'single' popovers and do not describe a 'link' relationship
  if (type === 'internet' || type === 'internet-k8') {
    return makeInternetConfig(initialConfig);
  }

  if (type === 'infrastructure') {
    return makeServiceConfig(initialConfig);
  }

  if (type === 'site') {
    return makeSiteConfig(initialConfig);
  }

  if (type === 'cloud') {
    return makeCloudConfig(initialConfig);
  }

  if (
    type === 'region' ||
    type === 'location' ||
    type === AWS_ENTITY_TYPES.REGION ||
    type === GCP_ENTITY_TYPES.REGION ||
    type === OCI_ENTITY_TYPES.REGION
  ) {
    return makeCloudRegionConfig(initialConfig);
  }

  if (
    type === 'vpc' ||
    type === AWS_ENTITY_TYPES.VPC ||
    type === AZURE_ENTITY_TYPES.VNET ||
    type === GCP_ENTITY_TYPES.NETWORK ||
    type === OCI_ENTITY_TYPES.VIRTUAL_CLOUD_NETWORK
  ) {
    return makeCloudVpcConfig(initialConfig);
  }

  if (
    type === 'subnet' ||
    type === AWS_ENTITY_TYPES.SUBNET ||
    type === OCI_ENTITY_TYPES.SUBNET ||
    type === AZURE_ENTITY_TYPES.SUBNET
  ) {
    return makeCloudSubnetConfig(initialConfig);
  }

  if (type === 'gateway') {
    return makeCloudGatewayConfig(initialConfig);
  }

  if (type === AWS_ENTITY_TYPES.VPC_ENDPOINT) {
    return makeVpcEndpointsConfig(initialConfig);
  }

  if (type === AWS_ENTITY_TYPES.CUSTOMER_GATEWAY) {
    return makeCustomerGatewayConfig(initialConfig);
  }

  if (type === AWS_ENTITY_TYPES.CORE_NETWORK_EDGE) {
    return makeCoreNetworkEdgeConfig(initialConfig);
  }

  if (type === AWS_ENTITY_TYPES.CORE_NETWORK_ATTACHMENT) {
    return makeCoreNetworkAttachmentConfig(initialConfig);
  }

  if (type === 'device') {
    return makeDeviceConfig(initialConfig);
  }

  if (type === 'downstream' || type === 'upstream' || type === 'parallel') {
    return makeLayeredDeviceConfig(initialConfig);
  }

  if (type === 'interface') {
    return makeInterfaceConfig(initialConfig);
  }

  if (type === KUBE_ENTITY_TYPES.CLUSTER) {
    return makeClusterConfig(initialConfig);
  }

  if (type === KUBE_ENTITY_TYPES.NAMESPACE || type === KUBE_ENTITY_TYPES.RESOURCE) {
    return makeKubeResourceConfig(initialConfig);
  }

  if (type === 'k8node') {
    return makeK8NodeConfig(initialConfig);
  }

  if (type === 'k8pod') {
    return makeK8PodConfig(initialConfig);
  }

  if (type === 'link' && initialConfig.cloudProvider === 'aws') {
    return makeAWSLinkConfig(initialConfig);
  }

  if (type === 'link' && initialConfig.cloudProvider === 'oci') {
    return makeOCILinkConfig(initialConfig);
  }

  if (type === 'link') {
    const initialLinkConfig = {
      ...initialConfig,
      source: makeConfig(source),
      target: makeConfig(target)
    };

    const kubeTypes = [KUBE_ENTITY_TYPES.CLUSTER, KUBE_ENTITY_TYPES.NAMESPACE, KUBE_ENTITY_TYPES.RESOURCE];
    if (kubeTypes.includes(initialLinkConfig.source.type) || kubeTypes.includes(initialLinkConfig.target.type)) {
      return makeKubeToKubeLinkConfig(initialLinkConfig);
    }

    if (initialLinkConfig.source.type === 'site' && initialLinkConfig.target.type === 'site') {
      return makeSiteToSiteLinkConfig(initialLinkConfig);
    }

    if (initialLinkConfig.source.type === 'device' && initialLinkConfig.target.type === 'device') {
      return makeDeviceToDeviceConfig(initialLinkConfig);
    }

    if (initialLinkConfig.source.type === 'cloud' && initialLinkConfig.target.type === 'cloud') {
      return makeCloudToCloudLinkConfig(initialLinkConfig);
    }

    return makeLinkConfig(initialLinkConfig);
  }

  if (type === 'cloudInterconnect') {
    return makeCloudInterconnectConfig(initialConfig);
  }

  // azure types

  if (type === AZURE_ENTITY_TYPES.LOCATION) {
    return makeLocationConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.FIREWALL) {
    return makeFirewallConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.VPN_GATEWAY && cloudProvider === 'azure') {
    return makeAzureVpnGatewayConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.VNET_PEERING) {
    return makeVNetPeeringConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.VNET_GATEWAY_CONNECTION) {
    return makeVNetGatewayConnectionConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.EXPRESS_ROUTE_CIRCUIT) {
    return makeExpressRouteCircuitConfig(initialConfig);
  }

  if (cloudProvider === 'azure' && type === AZURE_ENTITY_TYPES.NAT_GATEWAY) {
    return makeNATGatewayConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.LOCAL_NETWORK_GATEWAY) {
    return makeLocalNetworkGatewayConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.VNET_GATEWAY) {
    return makeVNetGatewayConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.VIRTUAL_WAN) {
    return makeVirtualWanConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.VIRTUAL_HUB) {
    return makeVirtualHubConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.HUB_VIRTUAL_NETWORK_CONNECTION) {
    return makeHubVirtualNetworkConnectionConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.EXPRESS_ROUTE_GATEWAY) {
    return makeExpressRouteGatewayConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.P2S_VPN_GATEWAY) {
    return makeP2SVpnGatewayConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.VPN_SITE) {
    return makeVPNSiteConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.VPN_LINK_CONNECTION) {
    return makeVpnLinkConnectionConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.EXPRESS_ROUTE_CONNECTION) {
    return makeExpressRouteConnectionConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.LOAD_BALANCER) {
    return makeLoadBalancerConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.APPLICATION_GATEWAY) {
    return makeApplicationGatewayConfig(initialConfig);
  }

  if (type === AZURE_ENTITY_TYPES.ROUTE_TABLE) {
    return makeRouteTableConfig(initialConfig);
  }

  // gcp types
  if (type === GCP_ENTITY_TYPES.EXTERNAL_VPN_GATEWAY && isGoogleCloud(cloudProvider)) {
    return makeGCPExternalVPNGatewayConfig(initialConfig);
  }

  if (type === GCP_ENTITY_TYPES.CLOUD_ROUTER && isGoogleCloud(cloudProvider)) {
    return makeCloudRouterConfig(initialConfig);
  }

  if (type === GCP_ENTITY_TYPES.INTERCONNECT && isGoogleCloud(cloudProvider)) {
    return makeGcpInterconnectConfig(initialConfig);
  }

  if (type === GCP_ENTITY_TYPES.INTERCONNECT_ATTACHMENT && isGoogleCloud(cloudProvider)) {
    return makeInterconnectAttachmentConfig(initialConfig);
  }

  if (type === GCP_ENTITY_TYPES.VPN_GATEWAY && isGoogleCloud(cloudProvider)) {
    return makeGcpVpnGatewayConfig(initialConfig);
  }

  if (type === GCP_ENTITY_TYPES.VPN_TUNNEL && isGoogleCloud(cloudProvider)) {
    return makeVPNTunnelConfig(initialConfig);
  }

  if (type === GCP_ENTITY_TYPES.TARGET_VPN_GATEWAY && isGoogleCloud(cloudProvider)) {
    return makeTargetVPNGatewayConfig(initialConfig);
  }

  if (type === GCP_ENTITY_TYPES.NAT_GATEWAY && isGoogleCloud(cloudProvider)) {
    return makeGcpNatGwConfig(initialConfig);
  }

  // OCI types
  if (cloudProvider === 'oci' && type === OCI_ENTITY_TYPES.IP_SEC_CONNECTION) {
    return makeIpSecConnectionConfig(initialConfig);
  }

  if (cloudProvider === 'oci' && type === OCI_ENTITY_TYPES.DYNAMIC_ROUTING_GATEWAY) {
    return makeDRGConfig(initialConfig);
  }

  if (cloudProvider === 'oci' && type === OCI_ENTITY_TYPES.NAT_GATEWAY) {
    return makeOCINatGatewaysConfig(initialConfig);
  }

  if (cloudProvider === 'oci' && type === OCI_ENTITY_TYPES.SERVICE_GATEWAY) {
    return makeOciServiceGatewaysConfig(initialConfig);
  }

  if (cloudProvider === 'oci' && type === OCI_ENTITY_TYPES.INTERNET_GATEWAY) {
    return makeOciInternetGatewaysConfig(initialConfig);
  }

  return initialConfig;
}
