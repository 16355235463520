import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Card } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import { applyFiltersToAggFilterQuery } from 'app/components/filters/simple/simpleFilterUtils';
import ResultsTable from 'app/components/resultsTable/ResultsTable';
import Page from 'app/components/page/Page';
import InsightsMenu from 'app/components/navbar/InsightsMenu';
import AggregateViewHeader from 'app/views/core/aggregate/AggregateViewHeader';
import parentLinks from 'app/views/core/NetworkExplorerParentLink';

const simpleFiltersBlacklist = ['interface_description', 'snmp_alias'];

@inject('$exports', '$query')
@observer
class Interfaces extends Component {
  state = {
    aggregateType: 'p95th_bits_per_sec',
    filters: [],
    lookback_seconds: 86400,
    sortResultsBy: 'Egress',
    sortResultsDir: 'desc'
  };

  // Set initial state from hash
  componentDidMount() {
    const { $exports } = this.props;

    $exports.getSettings().then(({ sortResultsBy, sortResultsDir }) => {
      this.setState({
        sortResultsBy,
        sortResultsDir
      });
    });
  }

  handleFiltersApply = (filters) => {
    this.setState({ filters });
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds });
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType });
  };

  handleSort = (sortState) => {
    const { $exports } = this.props;
    this.setState({ sortResultsBy: sortState.field, sortResultsDir: sortState.direction });
    $exports.setHash({ sortResultsBy: sortState.field, sortResultsDir: sortState.direction });
  };

  handleQueryComplete = ({ results }) => {
    const { sortResultsBy, sortResultsDir } = this.state;
    const { field, direction } = results ? results.sortState : {};

    if (sortResultsBy && (field !== sortResultsBy || direction !== sortResultsDir)) {
      results.sort(sortResultsBy, sortResultsDir);
    }
  };

  get tableQuery() {
    const { $query } = this.props;
    const { filters, lookback_seconds, aggregateType } = this.state;

    const query = $query.get('interfaceIngressEgressAggregateQuery', {
      lookback_seconds,
      aggregateTypes: [aggregateType],
      topx: 350,
      depth: 350
    });

    applyFiltersToAggFilterQuery(query, filters);

    return query;
  }

  render() {
    const { filters, lookback_seconds, aggregateType, sortResultsBy, sortResultsDir } = this.state;

    const queryOverrides = {
      lookback_seconds,
      aggregateTypes: [aggregateType],
      topx: 350,
      depth: 350
    };

    return (
      <Page
        title="Interfaces"
        insightsMenu={<InsightsMenu query="fetchInterfaceInsights" />}
        parentLinks={parentLinks}
        scrolls
        showExport
      >
        <AggregateViewHeader
          title="Interfaces"
          aggregateType={aggregateType}
          filters={filters}
          lookbackSeconds={lookback_seconds}
          onFiltersChange={this.handleFiltersApply}
          onAggregateChange={this.handleAggregationChange}
          onMetricsChange={this.handleAggregationChange}
          onTimeRangeChange={this.handleLookbackChange}
          fieldBlacklist={simpleFiltersBlacklist}
        />

        <Card mb={2}>
          <TabbedChart
            overrides={queryOverrides}
            simpleFilters={filters}
            metrics={{
              Inbound: 'InterfaceID_dst',
              Outbound: 'InterfaceID_src'
            }}
            showNativeLegend
          />
        </Card>

        <LightweightDataViewWrapper
          query={this.tableQuery}
          onQueryComplete={this.handleQueryComplete}
          resultsSortState={{ field: sortResultsBy, direction: sortResultsDir }}
        >
          {({ loading, dataview, queryModel, results, bucket }) => (
            <Card>
              <ResultsTable
                showSparklines={false}
                showActionsMenu
                query={this.tableQuery}
                loading={loading}
                dataview={dataview}
                queryModel={queryModel}
                queryResultsCollection={results}
                bucket={bucket}
                onSort={this.handleSort}
              />
            </Card>
          )}
        </LightweightDataViewWrapper>
      </Page>
    );
  }
}

export default Interfaces;
