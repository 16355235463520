import React, { Component } from 'react';
import storeLoader from 'app/stores/storeLoader';
import { observer } from 'mobx-react';
import { EmptyState, Flex, Spinner } from 'core/components';
import TestResultsStateByTimestamp from 'app/stores/synthetics/TestResultsStateByTimestamp';
import SynthTestDashboardItem from './SynthTestDashboardItem';
import SynthTestDashboardItemMobx from './SynthTestDashboardItemMobx';

@storeLoader('$syn.tests', '$syn.agents', '$syn.communityPerformanceTests')
@observer
class SynthTestDashboardItemMapper extends Component {
  render() {
    const { $syn, dataview, loading } = this.props;
    const { synth_test_id, synth_test_display } = dataview.query;

    if (loading) {
      return (
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <Spinner intent="primary" />
        </Flex>
      );
    }

    if (['mesh', 'network-grid', 'grid'].includes(synth_test_display)) {
      const testModel = $syn.tests.get(synth_test_id) || $syn.communityPerformanceTests.get(synth_test_id);

      if (!testModel) {
        return (
          <EmptyState
            icon="disable"
            title="No results"
            description="Results are not currently available for this time range or this test no longer exists."
          />
        );
      }

      if (!testModel.dataviews.get(dataview.hash)) {
        testModel.dataviews.set(
          dataview.hash,
          new TestResultsStateByTimestamp({ test: testModel, store: testModel.store })
        );
      }
      return <SynthTestDashboardItemMobx {...this.props} test={testModel} />;
    }

    return <SynthTestDashboardItem {...this.props} updateFrequency={dataview.updateFrequency} />;
  }
}

export default SynthTestDashboardItemMapper;
