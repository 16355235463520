import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Box, Callout, Text } from 'core/components';
import { Field, InputGroup, Select } from 'core/form';

/**
 * If the Dashboard is in one of these parametric modes, the value_type will always
 * be set to "predefined". @TODO probably do this on the server.
 */
const predefinedOptionsTypes = {
  as_name: 'AS Names',
  cdn: 'CDNs',
  city: 'Cities',
  connectivity_type: 'Connectivity Types',
  country: 'Countries',
  device: 'Devices',
  device_label: 'Device Labels',
  interface_desc: 'Interface Descriptions',
  interface_name: 'Interface Names',
  network_boundary: 'Network Boundaries',
  provider: 'Providers',
  region: 'Regions',
  site: 'Sites',
  tag: 'Tags'
};

const presetQuestions = {
  as_name: 'Select an AS Name',
  as_number: 'Select an AS Number',
  bgp_aspath: 'Enter a BGP AS Path',
  bgp_community: 'Enter a BGP Community',
  cdn: 'Select a CDN',
  city: 'Enter a City',
  connectivity_type: 'Select a Connectivity Type',
  country: 'Select a Country',
  device: 'Select a Device',
  device_label: 'Select a Label',
  dns_query: 'DNS Query contains',
  http_host_header: 'ILIKE',
  http_status: 'Enter an HTTP Status code',
  http_url: 'Enter a URL (partial matches accepted)',
  interface_desc: 'Select an Interface',
  interface_name: 'Select an Interface',
  ip: 'Enter an IP',
  network_boundary: 'Select a Network Boundary',
  port: 'Enter a Port',
  provider: 'Select a Provider',
  region: 'Select a Region',
  site: 'Select a Site',
  tag: 'Select a Flow Tag'
};

@inject('$dictionary')
@observer
class ParametricOptionsForm extends Component {
  handleTypeChange = (field, value) => {
    const { $dictionary } = this.props;
    const questionField = field.adjacentFields.question;
    let questionValue = presetQuestions[value];
    if (!questionValue) {
      questionValue = $dictionary.parametricDashboardFilterOptions.find((option) => option.type === value).label;
    }
    questionField.setValue(questionValue);
  };

  handleValueTypeChange = (field, value) => {
    const { form } = this.props;
    const predefinedValueOptionsField = form.getField('parametric_value_options');

    if (value === 'predefined') {
      predefinedValueOptionsField.setRules('required');
    } else {
      predefinedValueOptionsField.setRules();
    }
  };

  render() {
    const { form, $dictionary } = this.props;
    const parametric_fields = form.getField('parametric_fields');
    const { parametric, parametric_value_type } = form.getValues();
    const { queryFilters } = $dictionary.dictionary;

    return (
      <Box mt={2}>
        {parametric_fields.map((field) => {
          const type = field.type.getValue();
          const parametricOperator = queryFilters.parametricOperators[type];
          const isExactMatch =
            parametricOperator !== 'ILIKE' &&
            parametricOperator !== '~' &&
            !queryFilters.parametricNeverExactMatch.includes(type);

          return (
            <Box key={field.type._id}>
              <Field
                labelStyle={{ textTransform: 'none' }}
                field={field.type}
                options={$dictionary.parametricDashboardFilterOptions}
                onChange={this.handleTypeChange}
                disabled={!parametric}
              >
                <Select fill showFilter />
              </Field>

              <Field labelStyle={{ textTransform: 'none' }} field={field.question} disabled={!parametric}>
                <InputGroup fill />
              </Field>

              <Box>
                <Field
                  name="parametric_value_type"
                  disabled={!parametric}
                  labelStyle={{ textTransform: 'none' }}
                  onChange={this.handleValueTypeChange}
                  options={[
                    {
                      value: 'freeform',
                      label: isExactMatch ? 'System Defined' : 'Freeform'
                    },
                    {
                      value: 'predefined',
                      label: 'Predefined'
                    }
                  ]}
                  helpText={
                    isExactMatch
                      ? 'Allow the user to select from all possible values or define a set of options to choose from'
                      : 'Allow the user to enter freeform values or define a set of options to choose from'
                  }
                >
                  <Select fill />
                </Field>

                {parametric_value_type === 'predefined' && (
                  <Field
                    name="parametric_value_options"
                    placeholder="Enter a comma separated list of options"
                    rules={parametric && parametric_value_type === 'predefined' ? 'required' : []}
                    disabled={!parametric}
                    labelStyle={{ textTransform: 'none' }}
                  >
                    <InputGroup />
                  </Field>
                )}
              </Box>
              {parametric && (
                <Callout mt={2} p={2}>
                  <Text small>
                    {parametric_value_type !== 'predefined' && Object.keys(predefinedOptionsTypes).includes(type) && (
                      <p>
                        If no predefined options are provided, users will select from a list of{' '}
                        {predefinedOptionsTypes[type]}.
                      </p>
                    )}
                    By default, users will be shown data that {queryFilters.filterOperators[parametricOperator]}
                    {parametricOperator.indexOf('~') === -1 ? ' their input' : ''}. The operator can be changed in the
                    panel settings.
                  </Text>
                </Callout>
              )}
            </Box>
          );
        })}
      </Box>
    );
  }
}

export default ParametricOptionsForm;
