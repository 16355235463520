import React, { Component } from 'react';
import { action, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Icon } from '@blueprintjs/core';
import classNames from 'classnames';

import { Box, Flex, FlexColumn } from 'core/components';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import DataViewExportWrapper from 'app/components/dataviews/DataViewExportWrapper';
import AppliedFiltersButton from 'app/components/dataviews/tools/AppliedFiltersButton';
import { DashboardItemEditControls, DashboardItemViewControls } from 'app/components/dataviews/tools';
import { FiInfo } from 'react-icons/fi';
import SynthTestDashboardItem from './SynthTestDashboardItemMapper';

import DashboardItemFooter from './DashboardItemFooter';

const viewTypeWrapperConfigs = {
  stackedArea: {
    pr: 2
  },
  stackedAreaDeep: {
    pr: 2
  },
  stackedAreaHundredPercent: {
    pr: 2
  },
  line: {
    pr: 2
  },
  stackedBar: {
    pr: 2
  },
  bar: {
    pr: 2
  },
  pie: {
    px: 2
  },
  sankey: {
    overflow: 'hidden'
  }
};

function getViewTypeWrapperConfig(dataview) {
  return viewTypeWrapperConfigs[dataview.viewType] || {};
}

@inject('$app', '$dashboard', '$dashboards')
@observer
class DashboardItem extends Component {
  viewTypeDisposer;

  wrapperRef = React.createRef();

  constructor(props) {
    const { item, isLast, $dashboard } = props;
    super(props);

    item.component = this;

    if (isLast && !$dashboard.renderedLastItem) {
      $dashboard.renderedLastItem = true;
    }
  }

  componentDidMount() {
    const { item } = this.props;
    this.selectedModelsDisposer = reaction(
      () => item.selectedModels.length,
      () => {
        this.onSelectedModelsChange();
      }
    );
  }

  componentWillUnmount() {
    const { item } = this.props;
    item.component = undefined;
    if (this.viewTypeDisposer) {
      this.viewTypeDisposer();
    }

    if (this.selectedModelsDisposer) {
      this.selectedModelsDisposer();
    }
  }

  get showFooter() {
    const { item, $dashboard, collapsed } = this.props;
    const { dataview } = item;
    const { isEditing } = $dashboard;
    const isNavigationEnabled = item.get('dashboard_navigation');
    const hasDashboardDestination = isNavigationEnabled && item.get('destination_dashboard');

    return (
      dataview.isFlowVisualization &&
      !collapsed &&
      !isEditing &&
      (item.selectedModels.length > 0 || hasDashboardDestination)
    );
  }

  onSelectedModelsChange() {
    const { item } = this.props;
    setTimeout(() => item.reflow(), 150);
  }

  @action
  handleEdit = () => {
    const { $dashboard, item } = this.props;
    $dashboard.isEditingDashboardItem = true;
    item.set({ editActiveTab: 'query' });
    item.openDashboardItemConfigurationDialog();
  };

  @action
  handleClone = (cloneType) => {
    const { $dashboard, item } = this.props;
    if (cloneType === 'new') {
      item.select();
      $dashboard.setCloningDashboardItem(true);
    } else {
      $dashboard.addDashboardItem(item.get('panel_type'), item.toJS(), cloneType === 'this', item);
    }
  };

  onNestedDashboardClick = () => {
    const { item, $dashboards, $dashboard } = this.props;
    const parametric = item.get('dashboard_navigation_options.parametric');

    $dashboard.setCompletedInitialParametricSubmit(parametric !== 'prompt');
    $dashboards.navigateToNestedDashboard($dashboard.dashboard, item);
  };

  onClearSelectionsClick = () => {
    const { item, $dashboard } = this.props;
    $dashboard.clearSelectedModels(item);
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  getIsInitiallyVisibleByLayout(layout) {
    const top = layout.y * 100;
    return top <= window.innerHeight;
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  get isVisible() {
    const { top, bottom } = this.wrapperRef.current.getBoundingClientRect();
    return top < window.innerHeight && bottom >= 0;
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  get scrollOffset() {
    const { top } = this.wrapperRef.current.getBoundingClientRect();
    return window.innerHeight - top;
  }

  render() {
    const { item, $app, $dashboard, $dashboards, navigationHistory, onToggleCollapsed, collapsed } = this.props;
    const { dataview } = item;
    const { viewType, viewShowTitleLink } = dataview;
    const panelStyle = { height: '100%', width: '100%' };
    const { dashboard, isEditing, selectModel } = $dashboard;
    const isNavigationEnabled = item.get('dashboard_navigation');
    const hasDashboardDestination = isNavigationEnabled && item.get('destination_dashboard');

    let DataViewWrapperCmp = DataViewWrapper;
    if ($app.isExport) {
      DataViewWrapperCmp = DataViewExportWrapper;

      panelStyle.width = `${144 * 11 - 75}px`;
      panelStyle.height = viewType === 'table' || viewType === 'generator' ? 'auto' : `${144 * 8.5 - 100}px`;
    }

    if (
      dashboard.isIncompleteParametric ||
      ($dashboard.dashboard.isParametric && !$dashboard.completedInitialParametricSubmit)
    ) {
      return (
        <Box className="dataview-wrapper">
          <Box p={2}>
            <h6 className="header">{item.get('panel_title')}</h6>
          </Box>
          <Flex px={2} className="pt-text-muted" align="center">
            <Icon icon={FiInfo} style={{ marginRight: 6 }} />{' '}
            <span>
              {dashboard.parametricField.question} from the sidebar and press <strong>Apply</strong>.
            </span>
          </Flex>
        </Box>
      );
    }

    const buttonProps = {
      ml: '4px',
      small: true
    };

    const dataViewTools = (
      <Flex onMouseDown={(e) => e.stopPropagation()}>
        {isEditing && <DashboardItemEditControls item={item} onEdit={this.handleEdit} onClone={this.handleClone} />}
        {isEditing && dataview.appliedFilters && <AppliedFiltersButton item={item} />}
        {onToggleCollapsed && !isEditing && (
          <Button onClick={onToggleCollapsed} text={collapsed ? 'Expand' : 'Collapse'} {...buttonProps} />
        )}
        {!isEditing && !$app.isSharedLink && <DashboardItemViewControls item={item} buttonProps={buttonProps} />}
      </Flex>
    );

    return (
      <FlexColumn
        style={panelStyle}
        boxRef={this.wrapperRef}
        className={classNames('dashboard-item', { 'has-selection': item.selectedModels.length > 0 })}
      >
        <DataViewWrapperCmp
          customView={viewType === 'syntheticsTest' ? SynthTestDashboardItem : null}
          dataview={dataview}
          sourceLink={{ type: 'dashboard', model: item }}
          hasDashboardDestination={hasDashboardDestination}
          onModelSelect={selectModel}
          fitToHeight
          padChart
          viewProps={{
            isDashboardView: true,
            showNativeLegend: true,
            hideLegendOptions: true,
            hasFooter: this.showFooter,
            height: '100%'
          }}
          headerProps={{
            showTitleLink: !$app.isSubtenant && !$dashboard.isEditing && viewShowTitleLink,
            tools: dataViewTools,
            isDashboardView: true,
            dashboard,
            navigationHistory
          }}
        >
          {({ component, header, className }) => (
            <Flex className={className} flexDirection="column" flex={1} overflow="hidden">
              <Flex flexDirection="column" p={2}>
                {header}
              </Flex>
              <Flex flex={1} overflow="auto" {...getViewTypeWrapperConfig(dataview)}>
                {component}
              </Flex>
              {this.showFooter && (
                <DashboardItemFooter
                  dashboard={$dashboards.collection.get(item.get('destination_dashboard'))}
                  item={item}
                  onClear={this.onClearSelectionsClick}
                  onClick={this.onNestedDashboardClick}
                  selectedModels={item.selectedModels}
                />
              )}
            </Flex>
          )}
        </DataViewWrapperCmp>
      </FlexColumn>
    );
  }
}

export default DashboardItem;
