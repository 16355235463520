import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FromNowDate, Text, Icon, Flex, Box } from 'core/components';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$users', '$comments')
@inject('$dictionary', '$alerting', '$auth')
@observer
export class AlertAckChip extends Component {
  state = {};

  get ackUserName() {
    const { $users, model } = this.props;
    const user = $users.collection.get(model.ackUserId);

    let displayUser = model.ackUserId;

    if (user?.get) {
      displayUser = user.get('user_full_name');
    }

    return displayUser;
  }

  get ackComment() {
    const { $comments, model } = this.props;
    return $comments.collection.getAckCommentForEntityById(
      model.isAutoAcked ? model.autoAckId : model.id,
      model.isAutoAcked ? 'alertAutoAck' : 'alertAck'
    );
  }

  render() {
    const { $auth, model } = this.props;

    return (
      <Box borderBottom="thin" pb={1}>
        <Flex gap="4px" mb="4px" flexWrap="wrap">
          <Icon icon="endorsed" color="textColor" iconSize="16px" />
          <Text fontWeight="bold">{model.isAutoAcked ? 'Auto-acked' : 'Acked'}</Text> <Text>by</Text>{' '}
          <Text fontWeight="bold">{this.ackUserName}</Text>{' '}
          <FromNowDate
            timeStamp={model.ackTimestamp}
            userTimeZone={$auth.userTimezone}
            tooltipPosition="top"
            textOptions={{ small: false }}
          />
        </Flex>
        {this.ackComment && <Text as="div">{this.ackComment.get('comment')}</Text>}
      </Box>
    );
  }
}
