import React, { useMemo } from 'react';
import MetricsResultCollection from 'app/stores/metrics/MetricsResultCollection';
import { Table, VirtualizedTable } from 'core/components/table';
import { Card } from 'core/components';
import { observer } from 'mobx-react';

export const MetricsResultLegend = observer(
  ({ hideTotalRow, rows, isWidget, columns, isRowSelected, isExport, style }) => {
    const rollupCollection = useMemo(() => new MetricsResultCollection(rows), [rows]);
    const TableComponent = isExport ? Table : VirtualizedTable;

    const reconTable = (
      <TableComponent
        collection={rollupCollection}
        columns={columns}
        flexed
        selectOnRowClick={false}
        showTotalRow={!hideTotalRow && rows.length > 5}
        rowHeight={34}
        isRowSelected={isRowSelected}
        style={style}
      />
    );

    return !isWidget ? (
      <Card display="flex" flex={1} m="1px" style={{ overflowX: 'auto', overflowY: 'hidden' }}>
        {reconTable}
      </Card>
    ) : (
      reconTable
    );
  }
);
