const baseFields = {
  filters: {
    isComplexArray: true
  },
  'filters[].filterField': {
    rules: 'required'
  },
  'filters[].filterValue': {
    placeholder: 'Enter value...'
  }
};

export default function getFormConfig(name, fields, options) {
  return { fields: { ...baseFields, ...fields }, options: { name, ...options } };
}
