import React from 'react';
import { Icon } from 'core/components';
import { observer } from 'mobx-react';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';

const FormGroupIndicator = ({ form, fields, children, icon, ...rest }) => {
  let isIncomplete = false;
  let isError = false;
  let isValid = true;
  fields.forEach((fieldName) => {
    form.getMatchingFields(fieldName).forEach((field) => {
      if (!field.valid) {
        if (!field.pristine) {
          isError = true;
        } else {
          isIncomplete = true;
        }
        isValid = false;
      }
    });
  });

  if (children) {
    return children({ isValid, isError, isIncomplete, ...rest });
  }

  const invalidIntent = isIncomplete ? 'warning' : 'danger';

  return !isValid && <Icon icon={BsFillExclamationTriangleFill} color={invalidIntent} {...rest} />;
};

export default observer(FormGroupIndicator);
