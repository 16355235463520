export function flatten(options) {
  return Object.keys(options).reduce((acc, category) => {
    const categoryOptions = options[category];

    categoryOptions.forEach((subCategory) => {
      if (Array.isArray(subCategory)) {
        acc.push(...subCategory);
      } else {
        acc.push(...flatten(subCategory));
      }
    });

    return acc;
  }, []);
}

export function findCategoryOptions(options, categoryKey) {
  let categoryOptions;

  Object.keys(options).some((optionKey) => {
    if (optionKey === categoryKey) {
      categoryOptions = options[optionKey];
    }

    if (optionKey !== categoryKey && options[optionKey] && typeof options[optionKey] === 'object') {
      categoryOptions = findCategoryOptions(options[optionKey], categoryKey);
    }

    return categoryOptions !== undefined;
  });

  return categoryOptions;
}
