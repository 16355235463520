import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Button, CalloutOutline, Flex, Heading, Icon, Link, Text } from 'core/components';
import { Checkbox, Field, FormComponent, Select, TextArea } from 'core/form';
import $forms from 'core/form/util/$forms';
import { persistHash } from 'app/stores/query/urlHash';

const reportTypeToLabelMap = {
  'report issue': 'Help and Support',
  credits: 'Add Synthetics Test Credits',
  'kube request': 'Request Kentik Kube access',
  'journeys request': 'Request Journeys access',
  'ai request': 'Request Kentik AI access',
  'add populators request': 'Add Populators for Custom Dimensions',
  'training request': 'Training',
  'account question': 'Account Question',
  'product question': 'Product Question',
  bug: 'Bug',
  'feature request': 'Feature Request'
};

// outer component to observe isSubtenant & then pass in updated fields
const ReportIssuePopover = inject('$app')(
  observer((props) => {
    const userSelectableTypeOptions = () => {
      const { $app } = props;
      return [
        { label: reportTypeToLabelMap['account question'], value: 'account question' },
        { label: reportTypeToLabelMap['product question'], value: 'product question' },
        { label: reportTypeToLabelMap.bug, value: 'bug' },
        { label: reportTypeToLabelMap['training request'], value: 'training request' },
        ...($app.isSubtenant ? [{ label: reportTypeToLabelMap['feature request'], value: 'feature request' }] : [])
      ];
    };

    const fields = {
      type: {
        label: 'Type',
        rules: 'required',
        defaultValue: '',
        validateOptions: false, // Since we allow anything that's in reportTypeToLabelMap
        // Request Type: Question, Bug, Training, (MKP) Feature Request
        options: userSelectableTypeOptions()
      },
      summary: {
        label: 'Summary',
        rules: 'required',
        placeholder: 'What can we help you with?'
      },
      description: {
        label: 'Additional Details',
        rules: 'required',
        placeholder: "Let us know if you have any feedback or questions. We'd love to hear from you!"
      },
      bug_bounty: {
        label: 'Bug Bounty'
      },
      for_customer: {
        label: 'Report on Behalf of Customer'
      },
      customer_email: {
        label: 'Customer Email',
        rules: [{ required_if: ['for_customer', true] }, 'email'],
        placeholder: 'Who@where.tld'
      }
    };

    const options = {
      name: 'Report Issue',
      suppressFormValues: true
    };

    return (
      <FormComponent fields={fields} options={options}>
        {(formProps) => (
          <ReportIssuePopoverContent
            userSelectableTypeOptions={userSelectableTypeOptions()}
            {...props}
            {...formProps}
          />
        )}
      </FormComponent>
    );
  })
);

@inject('$auth', '$app')
@observer
class ReportIssuePopoverContent extends Component {
  state = {};

  static getDerivedStateFromProps(props) {
    const { form, $app } = props;
    const { reportIssuePopoverProps } = $app;
    const formValues = {};
    ['type', 'summary', 'description'].forEach((key) => {
      if (reportIssuePopoverProps?.[key]) {
        formValues[key] = reportIssuePopoverProps[key];
      }
    });
    form.setValues(formValues);

    return null;
  }

  handleSubmit = () => {
    const { form, $app, location, match } = this.props;
    const { platform, userAgent } = window.navigator;
    const { pathname } = location;
    const { clientWidth, clientHeight } = document.querySelector('body');

    const payload = {
      clientHeight,
      clientWidth,
      info: {
        history: $app.routeHistory,
        ...form.getValues(),
        allForms: $forms.getAllUnsuppressedFormValues(),
        typeTitle: reportTypeToLabelMap[form.getValue('type')]
      },
      pathname,
      platform,
      userAgent,
      isNext: $app.isNext
    };

    if ($app.productArea) {
      payload.productArea = $app.productArea;
    } else {
      payload.productArea = 'unknown';
    }

    $app.submitReportIssue(payload).then(() => form.reset());

    const { urlHash, hash, queryHash } = match?.params || {};
    if (urlHash || hash || queryHash) {
      persistHash(urlHash || hash || queryHash);
    }
  };

  handleClose = () => {
    const { form, $app } = this.props;
    form.reset();
    $app.toggleReportIssuePopover(false);
  };

  trackFeatureRequest = (e) => {
    e.preventDefault();

    window.pendo.feedback.loginAndRedirect({ anchor: { nodeName: 'a' } });

    return false;
  };

  isTypeUserSelectable(type) {
    const { userSelectableTypeOptions } = this.props;
    return !type || userSelectableTypeOptions.some((option) => option.value === type);
  }

  render() {
    const { form, $app, $auth } = this.props;
    const { sendingIssueRequest } = $app;

    const type = form.getValue('type');
    const title = reportTypeToLabelMap[type];
    const customer = form.getValue('for_customer');

    return (
      <Flex flexDirection="column" gap={2} mt="12px" maxWidth={500}>
        <Heading
          display="flex"
          alignItems="center"
          justifyContent={title ? 'space-between' : 'end'}
          px={3}
          py={1}
          level={3}
          m={0}
          borderBottom="thin"
        >
          {title}
          <Button icon="cross" onClick={this.handleClose} title="Close" minimal />
        </Heading>

        <Flex flexDirection="column" px={3} gap={2}>
          <Heading
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            level={5}
            borderBottom="thin"
            pb="4px"
            m={0}
          >
            Check our Knowledge Base
          </Heading>
          <CalloutOutline intent="primary" flex={1}>
            <Flex alignItems="center" gap={2}>
              <Icon icon="help" iconSize={20} color="primary" />
              <Flex flexDirection="column">
                <Text as="div" small>
                  Before submitting a support request...
                </Text>
                <Link
                  blank
                  href={`https://kb.kentik.com/zDir/HelpHandler.htm?portalPath=${encodeURIComponent(
                    window.location.pathname
                  )}`}
                >
                  See the Knowledge Base entry for this page. <Icon icon="new-tab" iconSize={12} minimal small />
                </Link>
              </Flex>
            </Flex>
          </CalloutOutline>
        </Flex>

        <Flex flexDirection="column" px={3} gap={2} pb={$app.isSubtenant ? 3 : 0}>
          <Heading
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            level={5}
            borderBottom="thin"
            pb="4px"
            m={0}
          >
            Submit a Support Request
          </Heading>

          <Field name="summary" disabled={sendingIssueRequest} large m={0}>
            <TextArea rows={2} fill />
          </Field>

          {this.isTypeUserSelectable(type) && (
            <Field name="type" disabled={sendingIssueRequest} large m={0}>
              <Select menuWidth={200} />
            </Field>
          )}

          <Field name="description" disabled={sendingIssueRequest} large m={0}>
            <TextArea rows={6} fill />
          </Field>
          {$app.isNext && ($auth.hasSudo || $auth.isSpoofed) && (
            <Field name="bug_bounty" showLabel={false} large m={0}>
              <Checkbox showBoxLabel />
            </Field>
          )}
          {($auth.hasSudo || $auth.isSpoofed) && (
            <Field name="for_customer" showLabel={false} large m={0}>
              <Checkbox showBoxLabel />
            </Field>
          )}
          {customer && (
            <Field name="customer_email" disabled={sendingIssueRequest} large m={0}>
              <TextArea rows={1} fill />
            </Field>
          )}
          <Button
            fill
            disabled={!form.valid || !form.dirty}
            intent={!form.valid ? 'none' : 'primary'}
            loading={sendingIssueRequest}
            text="Submit"
            onClick={this.handleSubmit}
          />
          {!$app.isSubtenant && (
            <Link small blank href="/api/ui/zendesk/jwt_token?return_to=https://kentik.zendesk.com/hc/requests">
              Track your support requests
            </Link>
          )}
        </Flex>
        {!$app.isSubtenant && (
          <Flex flexDirection="column" gap={2} px={3} pb={3}>
            <Flex flexDirection="column">
              <Text muted>What do you wish you could do with Kentik?</Text>
              <Text muted>Share your dream features!</Text>
            </Flex>
            <Button
              fill
              mt={1}
              loading={sendingIssueRequest}
              text="Manage Feature Requests"
              onClick={this.trackFeatureRequest}
            />
          </Flex>
        )}
      </Flex>
    );
  }
}

export default withRouter(ReportIssuePopover);
