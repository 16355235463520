import { action, observable } from 'mobx';
import { defaultsDeep } from 'lodash';

import { mapDataExplorerFilters } from '@kentik/ui-shared/nms/filters';
import $metrics from 'app/stores/metrics/$metrics';
import AbstractDataViewModel from 'app/stores/query/AbstractDataViewModel';
import { getObjectForHash } from 'app/stores/query/urlHash';
import { mergeFilterGroups } from 'core/util/filters';

export default class MetricsExplorerDataViewModel extends AbstractDataViewModel {
  @observable.ref
  query = {};

  get isFlowVisualization() {
    return false;
  }

  get viewType() {
    return 'metrics';
  }

  get viewShowTitleLink() {
    // TODO: base on selectedMeasurement?
    return true;
  }

  @action
  setTitle = (title) => {
    this.dvTitle = title;
  };

  @action
  refresh(saved_query_id) {
    return this.initializeHashWithOverrides(saved_query_id);
  }

  @action
  initializeHash(hash) {
    this.loading = true;
    this.hash = hash;

    return getObjectForHash(hash).then((query) => {
      Object.assign(query, { lastUpdated: Date.now() });
      this.query = query;
      this.setFullyLoaded();
      return query;
    });
  }

  /**
   * @param {string} hash
   * @param {object} overrides
   * @param {{ syncDepth?: boolean, overlayFilters?: boolean, parametricOverrides?: object }} [options]
   */
  @action
  initializeHashWithOverrides = async (hash, overrides = {}, { overlayFilters = true } = {}) => {
    this.loading = true;
    this.hash = hash;

    return getObjectForHash(hash).then((query) => {
      const { lookback_seconds, starting_time, ending_time } = overrides;
      let range = {};
      if (starting_time && ending_time) {
        query.starting_time = starting_time;
        query.ending_time = ending_time;
        query.lookback_seconds = 0;
        range = {
          start: starting_time,
          end: ending_time,
          lookback_seconds: 0,
          lookback: 'PT0S'
        };
      } else if (lookback_seconds) {
        range = {
          lookback: `PT${lookback_seconds}S` // start, end
        };
      }

      const newQuery = defaultsDeep(
        {
          lastUpdated: Date.now(),
          kmetrics: { range }
        },
        query
      );

      if (overlayFilters) {
        const measurement = $metrics.measurementModelByName(query.kmetrics.measurement);
        newQuery.kmetrics.filters = mergeFilterGroups(
          newQuery.kmetrics.filters,
          mapDataExplorerFilters(overrides.filters, measurement.toJS())
        );
      }

      this.query = newQuery;
      this.setFullyLoaded();
      return this.query;
    });
  };

  setUpdateFrequency(frequency = 0) {
    if (frequency) {
      this.updateFrequency = setInterval(this.refresh, frequency * 1000);
    } else if (this.updateFrequency) {
      clearInterval(this.updateFrequency);
      delete this.updateFrequency;
    }
  }

  destroy() {
    clearInterval(this.updateFrequency);
  }

  reflow() {
    if (this.component && this.component.reflow) {
      this.component.reflow();
    }
  }
}
