import React from 'react';
import { Text } from 'core/components';
import { getAggregateTypeLabel, getAggregateUnitLabel } from 'app/util/aggregateLabels';

export default function Aggregate({ typeLabel, aggregate, ...rest }) {
  return (
    <>
      <Text as="div">{typeLabel || getAggregateTypeLabel({ aggregate, ...rest })}</Text>
      <Text muted fontWeight="normal">
        {getAggregateUnitLabel({ aggregate, ...rest })}
      </Text>
    </>
  );
}
