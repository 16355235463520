import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Box, Button } from 'core/components';
import { Field, Select } from 'core/form';
import { AgentValueRenderer, AgentOptionRenderer } from './renderers';

@observer
export default class AgentFilterField extends Component {
  render() {
    const { field, onQuery, onChange } = this.props;

    return (
      <Field field={field} onQuery={onQuery} onChange={onChange} mb={0}>
        {(fieldProps) => (
          <Box position="relative">
            <Select
              {...fieldProps}
              showFilter
              exactMatch
              menuWidth={300}
              valueRenderer={AgentValueRenderer}
              optionRenderer={AgentOptionRenderer}
              buttonStyle={{ rightIcon: null }}
            />
            {field.getValue() !== '' && (
              <Button
                icon="small-cross"
                position="absolute"
                top={4}
                right={3}
                onClick={() => fieldProps.onChange('')}
                minimal
                small
              />
            )}
          </Box>
        )}
      </Field>
    );
  }
}
