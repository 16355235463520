import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, Text } from 'core/components';
import { formatMetricsValueForDisplay } from 'app/views/metrics/utils/formatMetricValues';
import ListRenderer from './ListRenderer';

/**
 * This component renders a list of metrics and their associated values for an alert for an NMS policy.
 * The component accepts the following properties:
 * - alertModel: This is required and expected to be an AlertModel instance. If this AlertModel does not originate from an NMS policy, this component will render nothing.
 * - maxMetricsToShow [optional]: Indicate how many entries should be shown in the list. If this number is less than the total number of items, we will show max-1 items and then truncate the rest to "Plus x more". Defaults to showing all metrics.
 * - inline [optional]: set to true if you want each k/v pair to be displayed inline.
 * - allowRichContent [optional]: set to false if you want each k/v pair to be displayed without React components
 *
 * Example usage:
 * <NmsNativeAlertMetricListRenderer
 *   alertModel={model}
 *   maxMetricsToShow={1}
 *   inline={false}
 * />
 */
@inject('$metrics')
@observer
export default class NmsNativeAlertMetricListRenderer extends Component {
  static defaultProps = {
    alertModel: {},
    allowRichContent: true
  };

  renderMetric = ({ measurement, metric, value }) => {
    const { $metrics, allowRichContent } = this.props;
    const measurementModel = $metrics.measurementModel(measurement);
    let metricLabel = metric;
    let metricValue = value;

    if (measurementModel) {
      metricLabel = measurementModel.metricLabel(metric);
      metricValue = formatMetricsValueForDisplay(
        value,
        {
          metric,
          measurementModel,
          measurement
        },
        allowRichContent
      );
    }

    if (!allowRichContent) {
      return `${metricLabel}: ${metricValue}`;
    }

    return (
      <Flex gap="4px" alignItems="center" flexWrap="wrap">
        <Text as="div" whiteSpace="noWrap" ellipsis muted>
          {metricLabel}:
        </Text>
        {metricValue}
      </Flex>
    );
  };

  render() {
    const { alertModel, maxMetricsToShow, inline, allowRichContent } = this.props;

    const alarmContext = alertModel?.nmsAlarmContext;

    // Alerts from NMS policies that use a single match aggregate threshold will have associated metrics on their context
    if (alertModel?.isNmsPolicy && alarmContext?.metrics) {
      const metrics = Object.entries(alarmContext.metrics).reduce((metricsList, [measurement, measurementMetrics]) => {
        Object.entries(measurementMetrics || {}).forEach(([metric, value]) => {
          metricsList.push({
            measurement,
            metric,
            value
          });
        });
        return metricsList;
      }, []);
      if (!metrics.length) {
        return null;
      }

      if (!allowRichContent) {
        return metrics.map(this.renderMetric).join(', ');
      }

      return (
        <ListRenderer
          list={metrics}
          renderListItem={this.renderMetric}
          getItemKey={({ metric, measurement }) => `${measurement}-${metric}`}
          maxItemsToShow={maxMetricsToShow}
          inline={inline}
        />
      );
    }

    // Alerts from NMS policies that use a sum match aggregate threshold will not have associated metrics on their context, and thus cannot be displayed in the same way as policies that use a single match aggregate threshold.
    // We do not support users configuring the match aggregate threshold on NMS policies today, but at such time as we expose this option, we will need to revisit how to display metrics for these alerts.
    if (alertModel?.isNmsPolicy && !alarmContext?.metrics) {
      return null;
    }

    // This model is not the right shape for this component to render;
    // It is either not an alert from an NMS policy, or it lacks the expected NMS alarm context
    return null;
  }
}
