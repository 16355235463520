import React from 'react';
import { orderBy } from 'lodash';
import { Box, Button, Flex, Popover, Text } from 'core/components';

const InterfaceBoundaryASNs = ({ model }) => {
  const boundaryASNs = model.get('top_nexthop_asns');
  if (!boundaryASNs || !boundaryASNs.length) {
    return <Text muted>N/A</Text>;
  }

  const sortedValues = orderBy(boundaryASNs, ['Packets'], ['desc']);
  const [first] = sortedValues;

  if (boundaryASNs.length === 1) {
    return (
      <Flex>
        <Text>{first.Asn || '-'}</Text>
        <Text ml="4px" muted>
          100%
        </Text>
      </Flex>
    );
  }

  const sum = boundaryASNs.reduce((total, { Packets }) => total + Packets, 0);

  return (
    <>
      <Flex key={first.Asn} mb={1}>
        <Text>{first.Asn || '-'}</Text>
        <Text ml="4px" muted>
          ({((first.Packets / sum) * 100).toFixed(2)}
          %)
        </Text>
      </Flex>
      <Flex justifyContent="flex-start">
        <Popover
          minimal={false}
          position="left"
          content={
            <Box p={2} maxHeight="calc(100vh - 80px)" overflow="auto">
              <Box pb={1}>
                <strong>Boundary ASNs</strong>
              </Box>
              {sortedValues.map((nextHop) => (
                <Flex key={nextHop.Asn} justifyContent="space-between">
                  <Text textAlign="right" flex={1}>
                    {nextHop.Asn || '-'}
                  </Text>
                  <Text ml="4px" textAlign="right" flex={1} muted>
                    ({((nextHop.Packets / sum) * 100).toFixed(2)}
                    %)
                  </Text>
                </Flex>
              ))}
            </Box>
          }
        >
          <Button small>View {sortedValues.length} ASNs</Button>
        </Popover>
      </Flex>
    </>
  );
};

export default InterfaceBoundaryASNs;
