/* eslint-disable max-len */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FiCopy } from 'react-icons/fi';
import { Heading, Box, Flex, FlexColumn, Callout, Text, Paragraph, Button, ConfigSnippet } from 'core/components';
import { isChinaRegion } from 'shared/hybrid/azureUtils';
import { ReactComponent as AzureLogo } from 'app/assets/logos/azure-logo.svg';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';

@observer
class CloudAzureConfigureExportPowershell extends Component {
  static defaultProps = {
    showHeading: true
  };

  /*
    examples:

    [EnrichmentScope]@{
        SubscriptionID="7bfe20eb-5115-4880-ab42-407e2f628768";
        ResourceGroupNames=@(
            "rg-nova"
            "rg-test"
        )
    }
    [EnrichmentScope]@{
        SubscriptionID="sub1";
    }
  */
  get enrichmentScopeSource() {
    const { enrichmentScope } = this.props;
    return enrichmentScope
      .filter((scope) => scope.isValid)
      .reduce((source, scope) => {
        let resourceGroupSource = '';

        if (scope.resourceGroups?.length > 0) {
          const groups = scope.resourceGroups.map((rg) => `\t\t"${rg}"`);

          resourceGroupSource = `
          ResourceGroupNames=@(
${groups.join('\n')}
          )`;
        }

        const scopeSource = `
        [EnrichmentScope]@{
          SubscriptionID="${scope.subscriptionId}";${resourceGroupSource}
        }
      `;

        return `${source}${scopeSource}`;
      }, '');
  }

  get scriptSource() {
    const { storageAccount } = this.props;
    return `
      #############################################
      # Source code for powershell script maintained at:
      # https://github.com/kentik/customer-scripts/blob/master/azure-setup.ps1
      $subscriptionID = "{{subscriptionID}}"
      $resourceGroup = "{{resourceGroup}}"
      $storageAccount = "{{storageAccount}}"
      $location = "{{location}}"

      class EnrichmentScope {
        [string]$SubscriptionID
        [string[]]$ResourceGroupNames
      }

      $enrichmentScopes = @({{enrichmentScope}})
      #############################################

      # set the subscription context
      Set-AzContext -Subscription $subscriptionID
      Write-Output ""

      $ErrorActionPreference = "Stop"

      # Verify subscription ID
      Write-Output "Verifying subscription ID '$subscriptionID"
      $ret = Get-AzSubscription -SubscriptionId $subscriptionID -ErrorAction SilentlyContinue
      if ( $ret.ID.Length -eq 0 ) {
          Write-Error "Fail: Could not find subscriptionID '$subscriptionID'"
      } else {
          Write-Output "Success: verified subscription ID"
      }
      Write-Output ""

      # Verify the location
      Write-Output "Verifying location '$location'"
      $foundLocation = $false
      Get-AzLocation | ForEach-Object {
          if ( $_.Location.length -gt 0 -AND $location.toLower().equals($_.Location.toLower()) ) {
              $foundLocation = $true
          }
      }
      if ( -NOT $foundLocation ) {
          Write-Error "Fail: Could not find location '$location"
      } else {
          Write-Output "Success: Location verified"
      }
      Write-Output ""

      # Verify the resource group
      Write-Output "Verifying resource group '$resourceGroup'"
      $ret = Get-AzResourceGroup -Name $resourceGroup -ErrorAction SilentlyContinue
      if ( $ret.ResourceGroupName.length -eq 0 ) {
          Write-Error "Fail: Could not find resource group '$resourceGroup'"
      } else {
          Write-Output "Success: Resource group verified"
      }
      Write-Output ""

      # Verify the subscription has a service principal for the Kentik app
      Write-Output "Ensuring Kentik service principal {{kentikClientID}} exists"
      $ret = Get-AzADServicePrincipal -ApplicationID {{kentikClientID}}
      if ( $ret.DisplayName.length -eq 0 ) {
          Write-Error "Fail: Could not find the service principal"
      } else {
          $appPrincipalID = $ret.Id
          $kentikPrincipalName = $ret.DisplayName
          Write-Output "Success: service principal found with ID '$appPrincipalID', display name '$kentikPrincipalName'"
      }
      Write-Output ""

      # Verify the service principal has Reader access to the resource group
      Write-Output "Ensuring service principal '$kentikPrincipalName' has Reader access to resource group '$resourceGroup'"
      $ret = Get-AzRoleAssignment -ObjectId $appPrincipalID -ResourceGroupName $resourceGroup -RoleDefinitionName Reader -ErrorAction SilentlyContinue
      if ( $ret.RoleDefinitionName.length -eq 0 ) {
          $ret = New-AzRoleAssignment -ObjectId $appPrincipalID -ResourceGroupName $resourceGroup -RoleDefinitionName Reader
          if ( $ret.RoleDefinitionName.length -gt 0 -AND $ret.RoleDefinitionName.toLower().equals("reader") ) {
              Write-Output "Success: Access granted"
          } else {
              Write-Error "Fail: Could not grant access"
          }
      } else {
          Write-Output "Success: Access was already granted"
      }
      Write-Output ""

      # Verify service principal has Reader access to enrichment subscriptions and resource groups
      Foreach ($enrichmentScope in $enrichmentScopes) {
        if ($enrichmentScope.ResourceGroupNames.length -eq 0 ) {
            # Verify subscription Reader role
            $subscriptionScope = "/subscriptions/"+$enrichmentScope.SubscriptionID
            Write-Output "Ensuring service principal '$kentikPrincipalName' has Reader access to '$subscriptionScope'"
            $ret = Get-AzRoleAssignment -ObjectId $appPrincipalID -Scope $subscriptionScope -RoleDefinitionName Reader
            if ( $ret.Scope -eq $subscriptionScope ) {
                Write-Output "Success: Access was already granted to subscription scope: " + $subscriptionScope
            } else {
                Write-Output "Reader role not found. Creating new role"
                $ret = New-AzRoleAssignment -ObjectId $appPrincipalID -Scope $subscriptionScope -RoleDefinitionName Reader
                if ( $ret.Scope -eq $subscriptionScope ) {
                    Write-Output "Success: Access granted"
                } else {
                    Write-Error "Fail: Could not grant Reader role to $subscriptionScope"
                }
            }
        } else {
            # Verify individual resource group reader roles
            Foreach ($enrichmentResourceGroupName in $enrichmentscope.ResourceGroupNames) {
                $resourceGroupScope = "/subscriptions/"+$enrichmentScope.SubscriptionID+"/resourceGroups/"+$enrichmentResourceGroupName
                Write-Output "Ensuring service principal '$kentikPrincipalName' has Reader access to '$resourceGroupScope'"
                $ret = Get-AzRoleAssignment -ObjectId $appPrincipalID -Scope $resourceGroupScope -RoleDefinitionName Reader
                if ( $ret.length -gt 0 ) {
                    Write-Output "Success: Access was already granted"
                } else {
                    Write-Output "Reader role not found. Creating new role"
                    $ret = New-AzRoleAssignment -ObjectId $appPrincipalID -Scope "$resourceGroupScope" -RoleDefinitionName Reader
                    if ( $ret.Scope -eq $resourceGroupScope ) {
                        Write-Output "Success: Access granted"
                    } else {
                        Write-Error "Fail: Could not grant Reader role to $resourceGroupScope"
                    }
                }
            }
        }
      }

      ${
        storageAccount &&
        storageAccount !== 'kentik-no-flow' &&
        `# Verify storage account
        $storageAccount = $storageAccount.toLower()
        Write-Output "Ensuring storage account '$storageAccount' in resource group '$resourceGroup', location '$location'"
        $ret = Get-AzStorageAccount -ResourceGroupName $resourceGroup -Name $storageAccount -ErrorAction SilentlyContinue
        $storageAccountID = $ret.ID
        if ( $ret.ProvisioningState -ne 'Succeeded' ) {
            # Create storage account
            Write-Output "Storage account does not yet exist - creating it now"
            $ret = New-AzStorageAccount -ResourceGroupName $resourceGroup -Name $storageAccount -Location $location -SkuName Standard_LRS -Kind StorageV2
            if ( $ret.ResourceGroupName.toLower().equals($resourceGroup.toLower()) ){
                $storageAccountID = $ret.ID
                Write-Output "Success: Created storage account"
            } else {
                Write-Error "Fail: Could not create storage account"
            }
        } else {
            if ( -NOT $ret.Location.toLower().equals($location.toLower()) ) {
                Write-Error "Fail: storage account '$storageAccount' found, but in location '$($ret.Location)'. Must be in location '$location'"
            }

            $storageAccountID = $ret.ID
            Write-Output "Success: storage account already exists"
        }
        Write-Output ""

        # Verify the service principal has Contributor access to the storage account, so it can fetch the access keys to then check for NSG flow logs
        Write-Output "Ensuring service principal '$kentikPrincipalName' has Contributor access to storage account '$storageAccount'"
        $ret = Get-AzRoleAssignment -ObjectId $appPrincipalID -ResourceGroupName $resourceGroup \`
            -ResourceType "Microsoft.Storage/storageAccounts" \`
            -ResourceName $storageAccount -RoleDefinitionName Contributor -ErrorAction SilentlyContinue
        if ( $ret.RoleDefinitionName.length -eq 0 ) {
            $ret = New-AzRoleAssignment -ObjectId $appPrincipalID \`
                -ResourceGroupName $resourceGroup -ResourceType "Microsoft.Storage/storageAccounts" \`
                -ResourceName $storageAccount -RoleDefinitionName Contributor
            if ( $ret.RoleDefinitionName.length -gt 0 -AND $ret.RoleDefinitionName.toLower().equals("contributor") ) {
                Write-Output "Success: Access granted"
            } else {
                Write-Error "Fail: Could not grant access"
            }
        } else {
            Write-Output "Success: Access was already granted"
        }`
      }

      # Ensure the network watcher feature is enabled for the resource group and location
      # By default, Network Watcher is automatically enabled.
      # When you create or update a virtual network in your subscription, Network Watcher will be automatically enabled in your Virtual Network's region.
      # https://learn.microsoft.com/en-us/azure/network-watcher/network-watcher-create?tabs=powershell
      Write-Output "Ensuring network watcher for resource group '$resourceGroup', location '$location'"
      $ret = Get-AzNetworkWatcher -Location $location -ErrorAction SilentlyContinue
      $networkWatcherObj = $ret
      if ( $ret.ProvisioningState.length -eq 0 -OR -Not $ret.ProvisioningState.toLower().equals("succeeded") ) {
          $ret = New-AzNetworkWatcher -Name "NetworkWatcher_$($location)" -ResourceGroupName $resourceGroup -Location $location
          if ( -Not $ret.Name.equals("") ) {
              $networkWatcherObj = $ret
              Write-Output "Success: Network watcher created"
          } else {
              Write-Error "Fail: Could not create network watcher"
          }
      } else {
          $networkWatcherObj = $ret
          Write-Output "Success: Network watcher already exists"
      }
      Write-Output ""

      # Ensure the Insights provider is registered
      Write-Output "Ensuring the Microsoft Insights provider is registered. Please be patient, this may take several minutes"
      Do {
          $ret = Register-AzResourceProvider -ProviderNamespace microsoft.insights
          $ret = $ret.RegistrationState
          Write-Output "Insights provider registration state: $ret"
          Start-Sleep -Milliseconds 5000
      } While ( -Not $ret.toLower().equals("registered") )
      Write-Output "Success: Microsoft Insights provider is registered"
      Write-Output ""

      ${
        storageAccount &&
        storageAccount !== 'kentik-no-flow' &&
        `# Add VNet support
        Write-Output "Looking for Virtual Networks (VNets) in resource group '$resourceGroup'"
        Write-Output ""
        Get-AzVirtualNetwork -ResourceGroupName $resourceGroup | ForEach-Object {
            $VNet = $_
            if ($VNet.Location.length -gt 0 -AND $VNet.Location.toLower().equals($location.toLower())) {
                Write-Output "Found Virtual Network '$($VNet.Name)' in location '$location'"

                # Check if VNet flow logs are already enabled
                Write-Output "Checking if VNet flow logs are already enabled for Virtual Network '$($VNet.Name)'"
                $existingFlowLogs = Get-AzNetworkWatcherFlowLog -NetworkWatcherName "NetworkWatcher_$location" -ResourceGroupName "NetworkWatcherRG" -ErrorAction SilentlyContinue
                $existingFlowLog = $existingFlowLogs | Where-Object { $_.TargetResourceId -eq $VNet.Id }

                if ($existingFlowLog -and $existingFlowLog.Enabled) {
                    Write-Output "Success: VNet flow logs already exist for Virtual Network '$($VNet.Name)' with Name: '$($existingFlowLog.Name)'"
                }
                else {
                    # Enable VNet flow logs
                    # https://learn.microsoft.com/en-us/azure/network-watcher/vnet-flow-logs-powershell
                    Write-Output "Enabling VNet flow logs for Virtual Network '$($VNet.Name)'"
                    $ret = New-AzNetworkWatcherFlowLog -Enabled $true -Name "flowLog_$($VNet.Name)"  \`
                      -NetworkWatcherName "NetworkWatcher_$location" -ResourceGroupName NetworkWatcherRG  \`
                      -StorageId $storageAccountID -TargetResourceId $VNet.Id -FormatVersion 2 \`
                      -EnableRetention $true -RetentionPolicyDays 7
                    if ( $ret.TargetResourceId.toLower().length -ne 0 -AND $ret.Enabled ) {
                        Write-Output "Success: VNet flow logs are enabled with Name: '$($ret.Name)'"
                    }
                    else {
                        Write-Error "Fail: Could not enable VNet flow logs"
                    }
                }
            }
            Write-Output ""
        }`
      }

      Write-Output ""
      Write-Output "Please provide Kentik with the following:"
      Write-Output "\`tSubscription ID:            $subscriptionID"
      Write-Output "\`tResource Group:             $resourceGroup"
      Write-Output "\`tLocation:                   $location"
      Write-Output "\`tStorage Account:            $storageAccount"
      Write-Output ""
      Write-Output ""
    `;
  }

  render() {
    const { subscriptionID, resourceGroup, storageAccount, location, showHeading } = this.props;

    return (
      <FlexColumn maxHeight="800px">
        {showHeading && (
          <Heading level={2} fontWeight="black">
            <Flex alignItems="center">
              <AzureLogo style={{ width: 40, height: 40, marginRight: 10 }} />
              <Text as="span" pr="4px">
                Configure Azure:
              </Text>
              <Text fontWeight="normal"> Run Powershell Script</Text>
            </Flex>
          </Heading>
        )}

        <Callout p={2}>
          <Paragraph>
            When run in your{' '}
            <Text as="a" fontWeight="bold" href="https://shell.azure.com" rel="noopener noreferrer" target="_blank">
              Azure Cloud Shell
            </Text>
            , the script below will automatically configure the export of flow logs from the resources in the Resource
            Group and Location shown below to the Storage Account shown below.
          </Paragraph>
          <Box as="ul" mb={0}>
            <li>
              <strong>Resource Group: </strong>
              <span>{resourceGroup}</span>
            </li>
            <li>
              <strong>Location: </strong>
              <span>{location}</span>
            </li>
            <li>
              <strong>Storage Account: </strong>
              <span>{storageAccount}</span>
            </li>
          </Box>
        </Callout>

        <ConfigSnippet
          source={this.scriptSource}
          data={{
            subscriptionID: subscriptionID || '[subscription ID]',
            kentikClientID: isChinaRegion(location)
              ? '4ae79b1f-b55b-412f-892a-157a534b1c42'
              : 'a20ce222-63c0-46db-86d5-58551eeee89f',
            resourceGroup: resourceGroup || '[resource group]',
            storageAccount: storageAccount || '[storage account]',
            location: location || '[location]',
            enrichmentScope: this.enrichmentScopeSource || ''
          }}
          themeName="steel"
          p={2}
          flex
          overflow="hidden"
        >
          {({ snippet, rawContent }) => (
            <Flex position="relative" mt={2} flex={1} overflow="hidden">
              <CopyToClipboardButton text={rawContent} intent="none">
                <Button icon={FiCopy} title="Copy to Clipboard" position="absolute" top="16px" right="16px" />
              </CopyToClipboardButton>
              <Box flex={1} overflow="auto" display="flex">
                {snippet}
              </Box>
            </Flex>
          )}
        </ConfigSnippet>
      </FlexColumn>
    );
  }
}

export default CloudAzureConfigureExportPowershell;
