import React from 'react';
import styled from 'styled-components';

import { MetricVisualizationPair } from './metrics/MetricVisualizationPair';
import { UtilizationMetricPair } from './metrics/UtilizationMetricPair';
import {
  BITS_SNMP_QUERY,
  BITS_ST_QUERY,
  DISCARDS_SNMP_QUERY,
  DISCARDS_ST_QUERY,
  PACKETS_SNMP_QUERY,
  PACKETS_ST_QUERY,
  ERRORS_SNMP_QUERY,
  ERRORS_ST_QUERY,
  CAPACITY_SNMP_QUERY,
  CAPACITY_SNMP_RESPONSE_KEYS,
  BITS_SNMP_RESPONSE_KEYS,
  BITS_ST_RESPONSE_KEYS,
  DISCARDS_SNMP_RESPONSE_KEYS,
  DISCARDS_ST_RESPONSE_KEYS,
  ERRORS_SNMP_RESPONSE_KEYS,
  ERRORS_ST_RESPONSE_KEYS,
  PACKETS_SNMP_RESPONSE_KEYS,
  PACKETS_ST_RESPONSE_KEYS
} from './metrics/InterfaceMetricQueries';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 360px);
  gap: 24px;
  min-width: 100%;
  width: 100%;
`;

const InterfaceMetricsTab = ({ selectedInterface }) => (
  <Container mt={2} className="break-after">
    <UtilizationMetricPair
      selectedInterface={selectedInterface}
      title="Utilization"
      snmpQuery={CAPACITY_SNMP_QUERY}
      snmpResponse={CAPACITY_SNMP_RESPONSE_KEYS}
      streamingQuery={BITS_ST_QUERY /* See note in ./queries/Capacity.js about why BITS and not CAPACITY */}
      streamingResponse={BITS_ST_RESPONSE_KEYS /* Ditto */}
      metricSuffix="%"
    />
    <MetricVisualizationPair
      selectedInterface={selectedInterface}
      title="Bits/s"
      snmpQuery={BITS_SNMP_QUERY}
      snmpResponse={BITS_SNMP_RESPONSE_KEYS}
      streamingQuery={BITS_ST_QUERY}
      streamingResponse={BITS_ST_RESPONSE_KEYS}
      unit="bits"
      metricSuffix="/s"
    />
    <MetricVisualizationPair
      selectedInterface={selectedInterface}
      title="Packets/s"
      snmpQuery={PACKETS_SNMP_QUERY}
      streamingQuery={PACKETS_ST_QUERY}
      snmpResponse={PACKETS_SNMP_RESPONSE_KEYS}
      streamingResponse={PACKETS_ST_RESPONSE_KEYS}
    />
    <MetricVisualizationPair
      selectedInterface={selectedInterface}
      title="Discards/s"
      snmpQuery={DISCARDS_SNMP_QUERY}
      streamingQuery={DISCARDS_ST_QUERY}
      snmpResponse={DISCARDS_SNMP_RESPONSE_KEYS}
      streamingResponse={DISCARDS_ST_RESPONSE_KEYS}
    />
    <MetricVisualizationPair
      selectedInterface={selectedInterface}
      title="Errors/s"
      snmpQuery={ERRORS_SNMP_QUERY}
      streamingQuery={ERRORS_ST_QUERY}
      snmpResponse={ERRORS_SNMP_RESPONSE_KEYS}
      streamingResponse={ERRORS_ST_RESPONSE_KEYS}
    />
  </Container>
);

export default InterfaceMetricsTab;
