import React from 'react';
import styled from 'styled-components';

import {
  alignContent,
  alignItems,
  backgroundImage,
  backgroundPosition,
  backgroundRepeat,
  backgroundSize,
  borderRadius,
  borders,
  boxShadow,
  color,
  display,
  flex as _flex,
  flexBasis as _flexBasis,
  flexDirection,
  flexWrap,
  fontFamily,
  fontSize,
  gridArea,
  gridAutoColumns,
  gridAutoFlow,
  gridAutoRows,
  gridColumn,
  gridColumnGap,
  gridGap,
  gridRow,
  gridRowGap,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  height as _height,
  justifyContent,
  lineHeight,
  maxHeight as _maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  overflow as _overflow,
  position as _position,
  space,
  textAlign,
  width as _width
} from 'styled-system';

const WrappedGrid = styled.div`
  ${flexDirection};
  ${flexWrap};
  ${alignItems};
  ${justifyContent};
  ${alignContent};

  ${backgroundImage};
  ${backgroundPosition};
  ${backgroundRepeat};
  ${borderRadius};
  ${boxShadow};
  ${backgroundSize};
  ${borders};
  ${color};
  ${display};
  ${fontFamily};
  ${fontSize};
  ${lineHeight};
  ${minHeight};
  ${minWidth};
  ${space};
  ${textAlign};
  ${gridGap};
  ${gridRowGap};
  ${gridColumnGap};
  ${gridColumn};
  ${gridRow};
  ${gridArea};
  ${gridAutoFlow};
  ${gridAutoRows};
  ${gridAutoColumns};
  ${gridTemplateRows};
  ${gridTemplateColumns};
  ${gridTemplateAreas};

  ${maxWidth};
  ${_maxHeight};
  ${_position};

  ${_flex};
  ${_flexBasis};
  ${_width};
  ${_height};
`;

/**
 * This element is used when more complex styling is required, in particular:
 * - Overflow behavior in conjunction with grid rules is unspecified behavior with cross-browser differences.
 *     This particularly affects Firefox
 * - MaxHeight and MaxWidth behavior can be ambiguous when applied to the same element, applying one to a wrapper
 *     div gives a specified order of precedence. This also helps to clear up ambiguity with overflow behavior.
 */
const GridOverflowWrapper = styled.div`
  ${_overflow /* if you want overflow then you cannot set it on the same component as grid rules */};
  ${_maxHeight /* need to set maxHeight on a different element from the one used for maxWidth */};
  ${_position};
  ${_flex};
  ${_flexBasis};
  ${_width};
  ${_height};
`;

const ComplexGrid = ({ overflow, maxHeight, width, height, position, flex, flexBasis, ...props }) => {
  const wrapperProps = { overflow, maxHeight, width, height, position, flex, flexBasis };
  return (
    <GridOverflowWrapper {...wrapperProps}>
      <WrappedGrid {...props} height="100%" width="100%" />
    </GridOverflowWrapper>
  );
};

const Grid = (props) => {
  const { overflow, maxHeight, maxWidth: _maxWidth } = props;
  return overflow || (maxHeight && _maxWidth) ? <ComplexGrid {...props} /> : <WrappedGrid {...props} />;
};

Grid.defaultProps = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit,minmax(275px,1fr))',
  gridGap: 16,
  gridRowGap: '8px'
};

export default Grid;
