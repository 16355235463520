import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { ButtonLink, Box, Flex, FlexColumn, Text } from 'core/components';
import { Table } from 'core/components/table';
import AlarmSwatch from 'app/views/alerting/components/AlarmSwatch';
import { ALERT_SEVERITIES, ALERT_SEVERITY_COLORS, ALERT_SEVERITY_LABELS } from 'shared/alerting/constants';

@observer
class AlertingSiteHealthTable extends Component {
  @computed
  get summaryTotals() {
    const { collection } = this.props;
    const totalCounts = {
      critical: 0,
      major2: 0,
      major: 0,
      minor2: 0,
      minor: 0
    };

    collection.models.forEach((model) => {
      const severities = model.get('severities');
      ALERT_SEVERITIES.forEach((severity) => {
        totalCounts[severity] += severities[severity];
      });
    });

    return totalCounts;
  }

  getColumns() {
    const { navigateToSiteFilterView, groupBy } = this.props;

    let columns = [
      {
        label: (
          <Box>
            {groupBy === 'severity' && (
              <Text as="div" fontSize={18}>
                &nbsp;
              </Text>
            )}
            <Text>Site</Text>
          </Box>
        ),
        name: 'title',
        alignItems: 'flex-end',
        padding: '6px 0px 6px 0px',
        renderer: ({ model, value }) => (
          <Text fontSize="12px">
            <ButtonLink onClick={() => navigateToSiteFilterView(model.id)}>{value}</ButtonLink>
          </Text>
        )
      }
    ];

    if (groupBy === 'none') {
      columns.push({
        label: 'Total Alerts',
        name: 'totalCount',
        ellipsis: false,
        sortable: true,
        computed: true,
        renderer: ({ value }) => {
          const alertCount = Number(value);
          return (
            <Text small muted={alertCount === 0}>
              {alertCount.toLocaleString()}
            </Text>
          );
        }
      });
    }

    if (groupBy === 'severity') {
      const severityColumns = ALERT_SEVERITIES.map((severity) => ({
        label: (
          <Box>
            <Text as="div" fontSize={18} fontWeight={700} textAlign="right">
              {this.summaryTotals[severity].toLocaleString()}
            </Text>
            <Flex alignItems="center" gap="4px" justifyContent="flex-end">
              <AlarmSwatch bg={ALERT_SEVERITY_COLORS[severity]} dot mr={0} />
              <Text small fontWeight={400}>
                {ALERT_SEVERITY_LABELS[severity]}
              </Text>
            </Flex>
          </Box>
        ),
        name: severity,
        justifyContent: 'flex-end',
        ellipsis: false,
        sortable: false,
        minWidth: 80,
        padding: '6px 0px 6px 12px',
        renderer: ({ model }) => {
          const alertCount = Number(model.get(`severities.${severity}`));
          return (
            <Text small muted={alertCount === 0}>
              {alertCount.toLocaleString()}
            </Text>
          );
        }
      }));

      columns = columns.concat(severityColumns);
    }

    return columns;
  }

  render() {
    const { collection, loading } = this.props;

    return (
      <FlexColumn justifyContent="flex-start" height="100%">
        {collection && (
          <Box pb={1}>
            <Table
              collection={collection}
              headerStyle={{
                alignItems: 'center',
                fontSize: '14px'
              }}
              loading={loading}
              useSortTooltips
              noHighlightSorted
              columns={this.getColumns()}
              selectOnRowClick={false}
              flexed
            />
          </Box>
        )}
      </FlexColumn>
    );
  }
}

export default AlertingSiteHealthTable;
