import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Card, Flex, Heading, Link, Spinner, Suspense, Text } from 'core/components';
import { formatNumber } from 'app/util/utils';
import CreditsWarningMessage from '../CreditsWarningMessage';

const scaleMax = 0.999;

@inject('$syn')
@observer
class CreditUtilization extends Component {
  renderContent() {
    const { $syn, loading } = this.props;
    const hasTests = $syn.tests.size > 0;

    const { projectedEomCreditUsage, currentMonthCreditUsage, maxCredits } = $syn.plan;
    const isOverLimit = maxCredits - currentMonthCreditUsage < 0;
    const formattedAvailableCredits = formatNumber(maxCredits, { fix: 0, scaleMax });
    const formattedActualUsage = formatNumber(currentMonthCreditUsage, { fix: 0, scaleMax });
    const formattedRemainingCredits = formatNumber(maxCredits - projectedEomCreditUsage, {
      fix: 0,
      scaleMax
    });
    const formattedProjectedTotal = formatNumber($syn.plan.projectedEomCreditUsage, {
      fix: 0
    });

    return (
      <Suspense loading={loading} fallback={<Spinner intent="primary" my={2} size={36} />}>
        {isOverLimit && (
          <Box pb={3}>
            <CreditsWarningMessage small />
          </Box>
        )}
        {hasTests && (
          <Box>
            <CreditUtilizationRow
              label="Monthly Allocation"
              to="/v4/licenses"
              value={formattedAvailableCredits.adjusted}
              unit={formattedAvailableCredits.valueSuffix}
              precision={formattedAvailableCredits.valueSuffix ? 1 : 0}
            />
            <CreditUtilizationRow
              label="Actual Usage"
              value={formattedActualUsage.adjusted}
              unit={formattedActualUsage.valueSuffix}
              precision={formattedActualUsage.valueSuffix ? 1 : 0}
              borderTop="thinLight"
            />
            <CreditUtilizationRow
              label="Projected Total Usage"
              value={formattedProjectedTotal.adjusted}
              unit={formattedProjectedTotal.valueSuffix}
              precision={formattedProjectedTotal.valueSuffix ? 1 : 0}
              borderTop="thinLight"
            />
            <CreditUtilizationRow
              label="Projected Remaining"
              value={formattedRemainingCredits.adjusted}
              unit={formattedRemainingCredits.valueSuffix}
              precision={formattedRemainingCredits.valueSuffix ? 1 : 0}
              valueColor={isOverLimit ? 'red' : null}
              borderTop="thinLight"
            />
          </Box>
        )}
      </Suspense>
    );
  }

  render() {
    const { $syn, loading, isWidgetView, ...rest } = this.props;

    return isWidgetView ? (
      <Box pt={1} pb={2} px={2}>
        {this.renderContent()}
      </Box>
    ) : (
      <Card p={2} mb={3} className="avoid-break" {...rest}>
        <Heading level={5} fontWeight="heavy" mb={2}>
          Credit Utilization
        </Heading>
        {this.renderContent()}
      </Card>
    );
  }
}

const CreditUtilizationRow = ({ label, to, value, valueColor, precision, unit, ...props }) => (
  <Flex alignItems="center" justifyContent="space-between" pt={1} pb={1} {...props}>
    {to ? (
      <Link to={to} small>
        {label}
      </Link>
    ) : (
      <Text small>{label}</Text>
    )}
    <Text fontWeight={500} color={valueColor}>
      {precision ? value.toFixed(precision) : value}
      {unit}
    </Text>
  </Flex>
);

export default CreditUtilization;
