import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Button, Text, ButtonGroup, Box } from 'core/components';
import { TextArea } from 'core/form';

@inject('$insights')
@observer
class InsightVote extends Component {
  static defaultProps = {
    showBorder: true
  };

  state = {
    reason: '',
    loading: false,
    showThanks: false
  };

  componentDidMount() {
    const { insight } = this.props;
    this.setState({ voteScore: insight.voteScore || '0', temporaryVoteScore: insight.voteScore || '0' });
  }

  setVoted = (voteScore) => {
    this.setState({ voteScore, loading: false, showThanks: true });
  };

  setTemporaryVoted = ({ isUpvote }) => {
    this.setState({ temporaryVoteScore: isUpvote ? '1' : '-1' });
  };

  handleVote = () => {
    const { insight, $insights } = this.props;
    const { reason, temporaryVoteScore } = this.state;

    const isUpvote = temporaryVoteScore === '1';

    this.setState({ loading: true });

    $insights.voteInsight({ insight, isUpvote, reason }).finally(() => this.setVoted(temporaryVoteScore));
  };

  handleReasonChange = (evt) => {
    this.setState({ reason: evt.target.value });
  };

  render() {
    const { voteScore, loading, showThanks, reason, temporaryVoteScore } = this.state;
    const { showBorder } = this.props;
    const border = showBorder ? 'thin' : 'none';

    // only show this after a button click
    if (showThanks) {
      return (
        <Box p={1} borderTop={border}>
          <Text color="muted" as="div" fontSize="small" fontWeight="bold">
            Thank you for your feedback!
          </Text>
        </Box>
      );
    }

    if (voteScore === '0' || voteScore === undefined) {
      return (
        <Box p={1} borderTop={border}>
          <Text color="muted" as="div" mb="4px" fontSize="small">
            Was this insight helpful?
          </Text>
          <ButtonGroup>
            <Button
              active={temporaryVoteScore === '1'}
              small
              px={3}
              text="Yes"
              icon="thumbs-up"
              onClick={() => this.setTemporaryVoted({ isUpvote: true })}
              disabled={loading}
            />
            <Button
              active={temporaryVoteScore === '-1'}
              small
              px={3}
              text="No"
              icon="thumbs-down"
              onClick={() => this.setTemporaryVoted({ isUpvote: false })}
              disabled={loading}
            />
          </ButtonGroup>
          {temporaryVoteScore !== '0' && (
            <TextArea
              disabled={loading}
              value={reason}
              fill
              autoGrow
              maxHeight={300}
              mt={2}
              placeholder="You can leave a comment here ..."
              maxLength="1000"
              onChange={this.handleReasonChange}
            />
          )}
          {temporaryVoteScore !== '0' && (
            <Button small mt={1} px={3} text="Submit" onClick={this.handleVote} loading={loading} />
          )}
        </Box>
      );
    }

    // if they have already voted, show nothing.
    return null;
  }
}

export default InsightVote;
