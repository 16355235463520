import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Button from 'core/components/Button';

@observer
class CancelButton extends Component {
  static defaultProps = {
    text: 'Cancel'
  };

  render() {
    const { large, model, onCancel, text, ...rest } = this.props;

    const isCreating = model && model.isRequestActive('creating');
    const isSaving = model && model.isRequestActive('updating');

    return (
      <Button
        disabled={isSaving || isCreating}
        onClick={onCancel}
        text={text}
        mr={1}
        large={large}
        minWidth={110}
        {...rest}
      />
    );
  }
}

export default CancelButton;
