import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { space, width, maxHeight, fontWeight, fontFamily, fontSize, color, display } from 'styled-system';
import { AnchorButton as BlueprintAnchorButton } from '@blueprintjs/core';
import Icon from './Icon';
import typography from './utils/typography';

const StyledAnchorButton = styled(BlueprintAnchorButton)`
  ${color};
  ${display};
  ${fontFamily};
  ${fontSize};
  ${fontWeight};
  ${maxHeight};
  ${space};
  ${width};
  ${typography};

  ${(props) =>
    props.small &&
    css`
      font-size: ${props.theme.fontSizes.small};

      .bp4-icon {
        &:first-child {
          margin-right: 4px;
        }

        &:first-child:last-child {
          margin: 0 -7px;
        }
      }
    `}
`;

const AnchorButton = (props) => {
  const {
    staticContext,
    borderRadius,
    icon,
    large,
    small,
    rightIcon,
    whiteSpace,
    style,
    minWidth,
    minHeight,
    alignItems,
    theme,
    iconSize,
    ...rest
  } = props;

  let finalIconSize = 16;
  if (iconSize) {
    finalIconSize = iconSize;
  } else if (large) {
    finalIconSize = 20;
  } else if (small) {
    finalIconSize = 12;
  }

  return (
    <StyledAnchorButton
      {...rest}
      large={large}
      small={small}
      icon={icon ? <Icon icon={icon} iconSize={finalIconSize} /> : undefined}
      rightIcon={rightIcon ? <Icon icon={rightIcon} iconSize={finalIconSize} /> : undefined}
      style={{
        borderRadius,
        whiteSpace,
        minWidth,
        minHeight,
        alignItems,
        ...style
      }}
    />
  );
};

AnchorButton.defaultProps = {
  text: undefined
};

export default withTheme(AnchorButton);
