import { action, computed, observable } from 'mobx';
import { escapeRegExp, isArray, isMatchWith, isString, orderBy, sortBy as _sortBy, union, uniqueId } from 'lodash';

import processLargeArrayAsync from 'core/util/processLargeArrayAsync';
import collatePromises from 'core/util/collatePromises';
import api from 'core/util/api';

import Model from './Model';

class Collection {
  @observable
  requestStatus = null;

  models = observable.array([], { deep: false });

  modelById = {};

  // list of fields that, when used to sort, will trigger a server-side data load
  serverSortableFields = [];

  // list of fields that, when used to sort, will need ui-driven logic to create a sorting filter for API
  uiSortLogicFields = [];

  @observable.shallow error;

  id = uniqueId('c_');

  @observable
  filterState = '';

  @observable
  activePresetFilter;

  @observable
  discreteFilters = [];

  @observable
  sortState = {};

  @observable
  groupBy = null;

  @observable
  selected = null;

  @observable
  hasFetched = false;

  @observable.ref
  original = null;

  @observable
  lastUpdated;

  @observable
  lastFetched;

  @observable
  useAsyncAdd = false;

  fetchPromise = null;

  @observable
  totalCount;

  hashModelsById = () => {
    const modelHash = {};
    this.get().forEach((model) => {
      if (model && model.id) {
        modelHash[model.id] = model;
      }
    });
    this.modelById = modelHash;
  };

  constructor(data = [], options = {}) {
    const {
      activePresetFilter = this.defaultPresetFilter,
      sortState = this.defaultSortState,
      discreteFilters = this.defaultDiscreteFilters,
      groupBy: currGroupBy = this.defaultGroupBy,
      getSortValue,
      hasFetched = false,
      models,
      threeWaySort = false,
      store = null
    } = options;

    if (store) {
      this.store = store;
    }

    // allows for custom getSortValue in generic Collection usage
    if (typeof getSortValue === 'function') {
      this.getSortValue = getSortValue;
    }

    // manual bind for converted arrow functions. Allows subclasses to override but keeps arrow function "this" behavior.
    this.processData = this.processData.bind(this);
    this.reset = this.reset.bind(this);

    this.activePresetFilter = activePresetFilter;

    this.threeWaySort = threeWaySort;
    this.sortState = sortState;
    this.discreteFilters = discreteFilters;
    this.groupBy = currGroupBy;

    if (models) {
      this.models.replace(models);
      this.hashModelsById();
    } else {
      this.set(data);
    }

    // Most common use case for this would be when a collection is initialized with data upfront.
    // When a collection is initialized with data and a PresetFilterButton group is applied to it,
    // you may encounter a situation where the filter buttons all become disabled when filtering by a property
    // that yields 0 results. Setting this option will prevent that.
    this.hasFetched = hasFetched;

    this.parent = options.parent || undefined;
  }

  get urlPaths() {
    return false;
  }

  get fetchMethod() {
    return 'get';
  }

  get queuedFetchKey() {
    return null;
  }

  get defaultSortState() {
    return {};
  }

  get defaultDiscreteFilters() {
    return [];
  }

  get baseSort() {
    return null;
  }

  get secondarySort() {
    return null;
  }

  get defaultGroupBy() {
    return null;
  }

  get defaultPresetFilter() {
    return (this.presetFilters && this.presetFilters.find((filter) => filter.default)) || undefined;
  }

  get presetFilters() {
    return false;
  }

  get discreteFiltersTypes() {
    return this.discreteFilters.map((filter) => filter.type);
  }

  get minFetchInterval() {
    return 300000;
  }

  /**
   * Returns the URL where the model's resource would be located on the server.
   *
   * @abstract
   */
  get url() {
    return undefined;
  }

  /**
   * Specifies the model class for that collection
   */
  get model() {
    return Model;
  }

  /**
   * Returns this Collection's Models as raw JSON.
   */
  toJS() {
    return this.models.map((model) => model.toJS());
  }

  /**
   * Describes the behavior of what a server side sort means, can be overridden at the collection level
   */
  serverSortFn() {
    return this.loadMoreItems({ startIndex: 0, force: true, reset: true });
  }

  /**
   * Creates a new model instance with the given attributes
   */
  build = (attributes, options = {}) => {
    const { select = false, deserialize = true } = options;
    const ModelClass = this.model;
    const model = new ModelClass();

    model.store = this.store;
    model.collection = this;
    model.set(deserialize ? model.deserialize(attributes) : attributes);

    if (select) {
      this.selected = model;
    }

    return model;
  };

  /**
   * Questions whether the request exists and matches a certain label
   * If no label are passed it will check if any requests are active.
   */
  isRequestActive(label) {
    if (!label) {
      return this.requestStatus !== null;
    }

    return !!this.requestStatus && this.requestStatus === label;
  }

  /**
   * Whether the collection is empty
   */
  isEmpty() {
    return this.models.length === 0;
  }

  /**
   * Get a resource at a given position
   */
  at(index) {
    if (!this.models[index]) {
      console.warn(`No model found at index ${index}`);
      return false;
    }

    return this.models[index];
  }

  /**
   * Get a resource with the given id or uuid or if no id is provided, will return all the models
   */
  get(id) {
    if (id !== undefined) {
      return this.modelById[id];
    }
    const models = this.original || this.models;
    return models.slice();
  }

  [Symbol.iterator]() {
    return this.models[Symbol.iterator]();
  }

  each(iterator) {
    this.models.forEach(iterator);
  }

  map(iterator) {
    return this.models.map(iterator);
  }

  some(iterator) {
    return this.models.some(iterator);
  }

  every(iterator) {
    return this.models.every(iterator);
  }

  reduce(iterator, initialValue) {
    return this.models.reduce(iterator, initialValue);
  }

  slice(start, end) {
    return this.models.slice(start, end);
  }

  /**
   * Finds an element with the given matcher
   *
   * @param {function|string|object|array} query supports function or lodash iteratee shorthand
   * @param {function} [customizer] function used for lodash match (ignored by function match)
   */
  find(query, customizer) {
    // standard JS array.find()
    if (typeof query === 'function') {
      return this.models.find(query);
    }
    // lodash predicate find
    return this.models.find((model) => isMatchWith(model.attributes, query, customizer));
  }

  @action
  group(value) {
    const loadMore =
      (this.groupBy !== value && this.serverSortableFields.includes(value)) ||
      (!value && this.sortFieldIsServerSortable);

    this.groupBy = value;

    // if grouping by a new column that's server sortable, we also need to re-fetch
    if (loadMore) {
      this.loadMoreItems({ startIndex: 0, force: true, reset: true });
    }
  }

  get isTreeGroupBy() {
    return false;
  }

  get groupedData() {
    if (!this.groupBy) {
      return {};
    }

    const groups = {};

    this.models.forEach((model) => {
      // add ability to set this.groupBy to object. Expecting type(string) and groupByFn(function) properties.
      const group =
        typeof this.groupBy === 'object'
          ? this.groupBy.groupByFn(model)
          : (model[this.groupBy] ?? model.get(this.groupBy));

      if (Array.isArray(group)) {
        if (group.length > 0) {
          // add model to multiple groups (for labels, etc.)
          group.forEach((g) => {
            groups[g] ??= [];
            groups[g].push(model);
          });
        } else {
          groups.None ??= [];
          groups.None.push(model);
        }
      } else {
        groups[group] ??= [];
        groups[group].push(model);
      }
    });

    return groups;
  }

  get sortedGroupKeys() {
    if (!this.groupBy) {
      return [];
    }

    const groupedModels = this.groupedData;

    // This assumed ALL the models are sorted, then this sorts the group indexes based on their current order in the whole collection
    return _sortBy(Object.keys(groupedModels), (groupKey) => this.models.indexOf(groupedModels[groupKey][0]));
  }

  @action
  move(oldIndex, newIndex) {
    this.models.splice(newIndex, 0, this.models.splice(oldIndex, 1)[0]);
  }

  @action
  sort(field, direction) {
    let sortField = this.sortState.field;
    let sortDir = this.sortState.direction;

    // just clear the sort if the user clicked the same field while already sorted descending or sorted by the default direction
    if (
      this.threeWaySort &&
      sortField === field &&
      sortDir &&
      ((this.defaultSortState.direction && sortDir !== this.defaultSortState.direction) ||
        (!this.defaultSortState.direction && sortDir === 'desc'))
    ) {
      this.clearSort();

      return;
    }

    // If a field was explicitly passed, use it
    if (field !== undefined) {
      sortField = field;
      sortDir = direction || this.defaultSortState.direction || 'asc';

      // If the field passed was the same as current, toggle the direction (or turn off)
      if (field === this.sortState.field && !direction) {
        sortDir = this.sortState.direction === 'asc' ? 'desc' : 'asc';
      }
    }

    if (sortField) {
      if (this.sortState.field !== sortField || this.sortState.direction !== sortDir) {
        this.sortState = {
          field: sortField,
          direction: sortDir
        };
      }

      // no need to sort locally if we already sorted on the server
      if (field === undefined && this.sortFieldIsServerSortable) {
        return;
      }

      this.original = this.get();

      const iteratees = [];
      const orders = [];

      let baseSortFn;
      let sortFn;
      if (this.getSortValue) {
        baseSortFn = (model) => this.getSortValue(this.baseSort.field, model);
        sortFn = (model) => this.getSortValue(sortField, model);
      } else {
        baseSortFn = (model) => model.getSortValue(this.baseSort.field);
        sortFn = (model) => model.getSortValue(sortField);
      }

      if (this.baseSort) {
        iteratees.push(baseSortFn);
        orders.push(this.baseSort.direction || 'asc');
      }

      iteratees.push(sortFn);
      orders.push(sortDir);

      if (this.secondarySort) {
        iteratees.push((model) => model.getSortValue(this.secondarySort.field));
        orders.push(this.secondarySort.direction || 'asc');
      }

      this.models.replace(orderBy(this.models, iteratees, orders));
    }
  }

  @action
  serverSort(field, direction) {
    let sortField = this.sortState.field;
    let sortDir = this.sortState.direction;

    // just clear the sort if the user clicked the same field while already sorted descending or sorted by the default direction
    if (
      this.threeWaySort &&
      sortField === field &&
      sortDir &&
      ((this.defaultSortState.direction && sortDir !== this.defaultSortState.direction) ||
        (!this.defaultSortState.direction && sortDir === 'desc'))
    ) {
      this.clearSort(true);

      return;
    }

    // If a field was explicitly passed, use it
    if (field !== undefined) {
      sortField = field;
      sortDir = direction || this.defaultSortState.direction || 'asc';

      // If the field passed was the same as current, toggle the direction (or turn off)
      if (field === this.sortState.field && !direction) {
        sortDir = this.sortState.direction === 'asc' ? 'desc' : 'asc';
      }
    }

    if (sortField) {
      if (this.sortState.field !== sortField || this.sortState.direction !== sortDir) {
        this.sortState = {
          field: sortField,
          direction: sortDir
        };
      }
    }

    if (this.serverSortFn) {
      this.serverSortFn();
    }
  }

  /**
   * Sets the sort state without forcing a sort. Useful to do prior to fetching.
   */
  @action
  setSortState(field, direction = 'asc') {
    this.sortState = { field, direction };
  }

  @action
  clearSort(serverSort) {
    this.sortState = {};

    if (this.threeWaySort && !serverSort) {
      this.set(this.original);
    } else if (serverSort) {
      this.serverSort();
    } else {
      this.sort();
    }
  }

  // filter finishes with a sort
  @action
  filter(query, options = {}) {
    const { immutable = false, idPath = 'id', customFilter, guardForDuplicates } = options;

    if (immutable) {
      return this.get().filter(query);
    }

    if (query !== undefined) {
      this.filterState = query;
    }
    this.original = this.get();

    if (customFilter) {
      this.models.replace(this.original.filter(customFilter));
    } else if (this.filterState) {
      if (isString(this.filterState)) {
        const regex = new RegExp(escapeRegExp(this.filterState), 'i');

        this.models.replace(
          this.original.filter((model) => this.getFilterValues(model).some((value) => regex.test(value)))
        );
      } else {
        this.models.replace(this.original.filter(this.filterState));
      }
    } else {
      // this is needed to get initial data in collections when filter is applied on first renders
      this.models.replace(this.get());
    }

    if (this.activePresetFilter) {
      this.models.replace(this.models.filter(this.activePresetFilter.fn));
    }

    this.discreteFilters.forEach((filter) => {
      this.models.replace(this.models.filter(filter.fn));
    });

    if (guardForDuplicates) {
      const uniqueIds = [];

      const uniqueModels = this.models.filter((model) => {
        const id = model.get(idPath);
        const isDuplicate = uniqueIds.includes(id);

        if (!isDuplicate) {
          uniqueIds.push(id);

          return true;
        }

        return false;
      });

      this.models.replace(uniqueModels);
    }

    this.sort();

    return this.models;
  }

  @action
  setPresetFilter(filter) {
    this.activePresetFilter = filter;
    this.filter();
  }

  @action
  resetDiscreteFilters() {
    this.discreteFilters = this.defaultDiscreteFilters;
  }

  @action
  setDiscreteFilters(discreteFilters) {
    this.discreteFilters = [...discreteFilters];
  }

  @action
  removeDiscreteFilter(type) {
    this.discreteFilters = this.discreteFilters.filter((filter) => filter.type !== type);
  }

  @action
  clearFilters() {
    this.filterState = '';
    this.activePresetFilter = null;
    this.discreteFilters = [];
    this.models.replace(this.get());
    this.sort();
  }

  @computed
  get hasFilter() {
    return !!(this.filterState || this.activePresetFilter || this.discreteFilters.length);
  }

  /**
   * Returns an array of values that filters can match against.
   * This can support matching against model properties and any other observables and computed properties.
   * To support observables and computed properties, define the property name in the collection's "filterFieldWhitelist" set.
   */
  getFilterValues(model) {
    const modelKeys = Object.keys(model.attributes);
    const whiteListKeys = this.filterFieldWhitelist && Array.from(this.filterFieldWhitelist);
    const keys = union(modelKeys, whiteListKeys);

    return keys
      .filter((name) => !this.filterFieldBlacklist || !this.filterFieldBlacklist.has(name))
      .filter((name) => !this.filterFieldWhitelist || this.filterFieldWhitelist.has(name))
      .map((name) => model.get(name) || model[name])
      .filter((value) => value !== undefined);
  }

  get filterFieldBlacklist() {
    return null;
  }

  get filterFieldWhitelist() {
    return null;
  }

  @computed
  get size() {
    return this.models.length;
  }

  @computed
  get selectedSize() {
    return this.selected?.length ?? 0;
  }

  @computed
  get unfilteredSize() {
    return this.get().length;
  }

  @computed
  get unfiltered() {
    return this.original || this.models;
  }

  @computed
  get status() {
    return this.requestStatus || 'none';
  }

  @computed
  get loading() {
    return this.requestStatus === 'fetching' || this.requestStatus === 'fetchingMore';
  }

  @computed
  get discreteFilterValues() {
    return this.discreteFilters.reduce((acc, f) => {
      acc[f.type] = f.value;
      return acc;
    }, {});
  }

  @computed
  get isAllSelected() {
    const selectableModels = this.models?.filter((model) => model.isSelectable);
    return (
      this.selected &&
      this.selected.length >= selectableModels?.length &&
      /** ensure that all the selected models are the same as the models that are currently being displayed.
       * This is useful when the models are paginated, as changing the page will cause the selected.length to be the same as the selectableModels length. */
      selectableModels?.every((model) =>
        this.selected?.find((selectedModel) => model.get('id') === selectedModel.get('id'))
      )
    );
  }

  @computed
  get sortFieldIsServerSortable() {
    return this.serverSortableFields.includes(this.sortState.field);
  }

  @computed
  get sortFieldIsUISortLogicDriven() {
    return this.uiSortLogicFields.includes(this.sortState.field);
  }

  @computed
  get groupByFieldIsUISortLogicDriven() {
    return this.uiSortLogicFields.includes(this.groupBy);
  }

  generateSelectOptions = ({ valueKey = 'id', labelKey, sortBy, unfiltered = false }) => {
    const models = unfiltered ? this.unfiltered : this.models;
    const options = models.map((model) => ({
      value: model.get(valueKey) || model[valueKey],
      label: labelKey ? model.get(labelKey) : model.get(valueKey),
      ...model.get()
    }));

    if (sortBy) {
      return orderBy(options, sortBy);
    }

    return options;
  };

  @action
  selectAll() {
    this.selected = this.isAllSelected ? null : this.models.filter((model) => model.isSelectable);
  }

  @action
  unselectAll = () => {
    this.selected = null;
  };

  @action
  select = (model, options = {}) => {
    const { multi = false, shiftKey } = options;
    const m = model && this.get(model.id);

    if (!m) {
      console.warn(`Model (id: ${model && model.id}) not found in ${this.constructor.name}`);
    }

    if (multi) {
      if (!this.selected || !Array.isArray(this.selected)) {
        this.selected = [];
      }

      if (model.isSelected) {
        const indexOfModel = this.selected.indexOf(model);
        if (indexOfModel >= 0) {
          this.selected = this.selected.slice(0, indexOfModel).concat(this.selected.slice(indexOfModel + 1));
        }

        if (this.selected.length === 0) {
          this.selected = null;
        }

        // adding to a multi selection
      } else {
        // select all the models in between
        if (shiftKey) {
          const firstIndex = this.selected[0].index;

          if (firstIndex > model.index) {
            this.selected = this.models.slice(model.index, firstIndex + 1).filter((item) => item.isSelectable);
          } else {
            this.selected = this.models.slice(firstIndex, model.index).filter((item) => item.isSelectable);
          }

          // Browsers (on some platforms) will try to select text in between the 2 cursor points. Revert that.
          document.getSelection().removeAllRanges();
        }

        this.selected = this.selected.concat(model);
      }
    } else {
      this.selected = model;
    }
  };

  @action
  clearSelection = () => {
    this.selected = null;
  };

  @action
  setLastUpdated = () => {
    this.lastUpdated = Date.now();
  };

  @action
  setLastFetched = (when) => {
    this.lastFetched = when || Date.now();
  };

  @action
  setRequestStatus = (status) => {
    this.requestStatus = status;
  };

  @action
  reset(options = {}) {
    this.models.clear();
    this.original = null;
    this.hasFetched = false;
    this.lastFetched = null;
    this.hashModelsById();
    this.requestStatus = null;
    this.fetchPromise = null;
    this.setLastUpdated();

    if (options.hard) {
      this.resetState();
    }
  }

  @action
  resetState() {
    this.filterState = '';
    this.activePresetFilter = this.defaultPresetFilter;
    this.discreteFilters = this.defaultDiscreteFilters;
    this.sortState = this.defaultSortState;
    this.groupBy = this.defaultGroupBy;
    this.filter();
  }

  @action
  resetSortState() {
    this.sortState = this.defaultSortState;
    this.sort();
  }

  @action
  resetFetchCache() {
    this.lastFetched = null;
  }

  /**
   * Adds one or more models to the collection. Accepts an array of models/raw objects or a simple model/object.
   * Returns the added models.
   */
  @action
  add = (data) => {
    if (!isArray(data)) {
      data = [data];
    }

    const models = data.map((row) => {
      if (row instanceof Model) {
        row.collection = this;
        return row;
      }
      return this.build(row);
    });
    this.original = this.get().concat(models);
    this.filter();
    this.hashModelsById();
    this.setLastUpdated();

    return models;
  };

  @action
  remove = (ids, destroyModels = false) => {
    if (ids.forEach) {
      ids.forEach((id) => this.remove(id, destroyModels));
    } else {
      const model = this.get(ids);
      if (model) {
        if (this.original) {
          this.original = this.original.filter((m) => m !== model);
        }

        this.models.remove(model);

        if (destroyModels) {
          model.destroy({ confirm: false });
        }
        if (this.selected && this.selected.id === model.id) {
          this.clearSelection();
        }
      }
    }
    this.hashModelsById();
    this.setLastUpdated();
  };

  /**
   * Sets the model(s) in the collection. Accepts an array of models/raw objects or a simple model/object.
   */
  @action
  set = (models) => {
    // This is a hack to pretend to clear out the existing data and append the new models w/o triggering two observed changes to this.models
    this.original = [];
    this.add(models);
    this.setLastUpdated();
  };

  @action
  processData(response, fetchOptions) {
    // do not clear original if we're adding more on top of it (for example loading more data via infinite scroll)
    if (!this.useAsyncAdd || !(fetchOptions?.data?.offset || fetchOptions?.data?.pagination?.offset)) {
      this.original = [];
    }

    const data = this.deserialize(response);

    if (this.useAsyncAdd || fetchOptions?.data?.offset > 0 || fetchOptions?.data?.pagination?.offset > 0) {
      let models = [];
      return processLargeArrayAsync(data, (row) => {
        models = models.concat(this.build(row));
      }).then(
        action(() => {
          this.original = this.get().concat(models);
          this.filter(undefined, {
            idPath: fetchOptions?.idPath,
            guardForDuplicates: fetchOptions?.pagedFetch
          });
          this.setLastUpdated();
          this.hashModelsById();

          return response;
        })
      );
    }

    this.set(data);
    return Promise.resolve(response);
  }

  /**
   * Creates a model object of the type the Collection instance uses with the provided data
   * optionally selecting it (this is the default behavior). The resultant model is not added
   * to the collection!
   */
  @action
  forge = (attributes, options = {}) => {
    const { select = true } = options;
    return (attributes && attributes.id && this.get(attributes.id)) || this.build(attributes, { ...options, select });
  };

  /**
   * Fetches collection data from the backend and overwrites the models, applying filter/sorts/groups.
   */
  @action
  async fetch(options = {}) {
    const { data, fetchUrl, fetchMethod, preserveSelection, query } = options;

    // fetching more in case we're doing a paged fetch, to update the UI somehow, indicate to the user we are loading more
    const label = options.pagedFetch ? 'fetchingMore' : 'fetching';

    if (options.pagedFetch) {
      this.useAsyncAdd = true;
    }

    // Never fetch if already fetching or if too recent - unless parameters are diff
    if (
      !options.pagedFetch &&
      !options.query &&
      !options.force &&
      ((this.requestStatus === label && this.fetchPromise) || this.lastFetched > Date.now() - this.minFetchInterval)
    ) {
      return this.fetchPromise;
    }

    this.lastFetched = Date.now();
    this.requestStatus = label;
    this.hasFetched = true;

    if (!preserveSelection) {
      this.clearSelection();
    }

    const httpMethod = fetchMethod || this.fetchMethod;
    const url = fetchUrl || (this.urlPaths && this.urlPaths.fetch ? this.urlPaths.fetch : this.url);

    this.fetchPromise = api[httpMethod](url, { data, query })
      .then(
        (response) => this.processData(response, options),
        (error) => {
          this.error = { label, body: error };
          this.models.clear();
          this.hashModelsById();
          this.fetchPromise = null;
          throw error;
        }
      )
      .finally(() => {
        this.requestStatus = null;
      });

    return this.fetchPromise;
  }

  getServerSortBy = () => {
    const canServerSort = this.sortFieldIsServerSortable || this.serverSortableFields.includes(this.groupBy);
    let sortBy;

    if (canServerSort) {
      // first sorting is done by grouping in terms of api search
      sortBy = this.groupBy || '';

      if (this.sortState.field && this.sortFieldIsServerSortable) {
        // second sorting is done by actual sort on a specific field - comma separated
        // format: groupBy,sortBy:order
        sortBy = sortBy
          ? `${sortBy},${this.sortState.field}:${this.sortState.direction}`
          : `${this.sortState.field}:${this.sortState.direction}`;
      }
    }

    return sortBy;
  };

  loadMoreItems = ({ startIndex, reset = false, force = false }) => {
    const sortBy = this.getServerSortBy();
    const isUnfiltered = this.size === this.unfilteredSize;
    // If there's a collection filter, use the unfiltered size as our offset
    const offset = isUnfiltered ? startIndex : this.unfilteredSize;

    if (reset) {
      this.reset();
    }

    // this.totalCount represents total possible results for a query.
    // If our offset is greater/equal to that, no need to kick off another fetch.
    if (offset >= this.totalCount) {
      return Promise.resolve();
    }

    return this.fetch({
      pagedFetch: startIndex > 0,
      data: {
        force,
        offset,
        sortBy: sortBy || undefined
      }
    });
  };

  /**
   * This is very similar to fetch but allows for multiple fetch requests to go out at same time, but ONLY
   * last one will actually update the collection with results (paging, server-based collection filtering, etc.)
   */
  @action
  async queuedFetch(options = {}) {
    const { data, fetchUrl, fetchMethod, preserveSelection, query } = options;

    const label = 'fetching';

    this.requestStatus = label;
    this.hasFetched = true;

    if (!preserveSelection) {
      this.clearSelection();
    }

    const key = this.queuedFetchKey;
    if (!key) {
      return Promise.reject(new Error('Collection must provide queuedFetchKey'));
    }
    const qfHttpMethod = fetchMethod || this.fetchMethod;
    const qfUrl = fetchUrl || (this.urlPaths && this.urlPaths.fetch ? this.urlPaths.fetch : this.url);

    return collatePromises({
      key,
      promise: api[qfHttpMethod](qfUrl, { data, query })
    })
      .then(
        (results) => this.processData(results),
        (error) => {
          this.error = { label, body: error };
          this.models.clear();
          this.hashModelsById();
          throw error;
        }
      )
      .finally(() => {
        this.requestStatus = null;
      });
  }

  serialize(data) {
    return data || this.map((model) => model.serialize());
  }

  deserialize(data) {
    return data && this.jsonRoot ? data[this.jsonRoot] : data;
  }
}

export default Collection;
