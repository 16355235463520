import React from 'react';
import { withTheme } from 'styled-components';
import { Box, Flex, Text, Tag, Grid } from 'core/components';

const ComparisonBars = (props) => {
  const { bars, theme, ...rest } = props;
  const total = bars.reduce((acc, bar) => acc + (parseFloat(bar.value) || 0), 0);
  const formatter = Intl.NumberFormat('en', {
    notation: 'compact',
    trailingZeroDisplay: 'stripIfInteger',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1
  });

  return (
    <Grid
      gridGap={0}
      gridRowGap={0}
      gridTemplateColumns="1fr 1fr"
      gridTemplateRows="1fr min-content min-content"
      justifyItems="center"
      {...rest}
    >
      {bars.map(({ label, value = 0, active }) => {
        const pct = Math.max(Math.round((value / total) * 100), 1);
        const scientific = value.toString().includes('e+')
          ? `${value.toString().slice(0, 4)}${value.toString().slice(-4)}`
          : undefined;

        return (
          <Flex key={`bar-${label}`} flexDirection="column" justifyContent="flex-end" alignItems="center">
            <Tag
              minimal
              textAlign="center"
              fontWeight={active ? 'heavy' : undefined}
              intent={active ? 'primary' : undefined}
              title={value}
              mb={1}
            >
              {scientific || formatter.format(value)}
            </Tag>
            <Box
              borderRadius="4px 4px 0px 0px"
              width={80}
              bg={active ? theme.colors.primary : theme.colors.muted}
              height={`${pct}%`}
              mb={1}
              mx={1}
            />
          </Flex>
        );
      })}
      {bars.map(({ label, value }) => (
        <Flex overflow="hidden" key={`${label + value}-label`} justifyContent="center">
          <Text as="div" ellipsis>
            {label}
          </Text>
        </Flex>
      ))}
      {bars.map(({ subLabel, value, label }) => (
        <Flex overflow="hidden" key={`${label + value}-sublabel`} justifyContent="center">
          {!!subLabel && subLabel}
        </Flex>
      ))}
    </Grid>
  );
};

export default withTheme(ComparisonBars);
