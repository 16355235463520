import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Flex, Text } from 'core/components';
import Tooltip from 'core/components/Tooltip';

/**
 * This component renders a generic list of items
 * The component accepts the following properties:
 * - items: An array of items of any type.
 * - renderListItem: A function that accepts a list item and returns its rendered content.
 * - getItemKey: A function that accepts a list item and returns a unique string for that item.
 * - maxItemsToShow [optional]: Indicate how many entries should be shown in the list. If this number is less than the total number of items, we will show max-1 items and then truncate the rest to "Plus x more". Defaults to showing all items.
 * - inline [optional]: set to true if you want each k/v pair to be displayed inline.
 *
 * Example usage:
 * <ListRenderer
 *   items={[{id: 1}, {id: 2}]}
 *   renderListItem={(item) => <div>{item.id}</div>}
 *   getItemKey={(item) => item.id}
 *   maxItemsToShow={1}
 *   inline={false}
 * />
 */
@observer
export default class ListRenderer extends Component {
  renderItem = (item) => {
    const { renderListItem, getItemKey } = this.props;
    return <Fragment key={getItemKey?.(item)}>{renderListItem?.(item)}</Fragment>;
  };

  renderList = (list) => {
    const { maxItemsToShow = list.length } = this.props;

    let numItemsToShow = maxItemsToShow;

    if (maxItemsToShow < list.length) {
      // If the max items to show is less than the total number of items,
      // we will show one less than the max so that the last spot can be held for the '+ x more' truncation text.
      // However, if maxItemsToShow is set to 1, if there is more than 1 item in the list, we will still show 1 item plus the truncation text
      numItemsToShow = Math.max(maxItemsToShow - 1, 1);
    }

    const itemsToShow = list.slice(0, numItemsToShow);
    const overflowItems = list.slice(numItemsToShow);

    return (
      <>
        {itemsToShow.map(this.renderItem)}
        {overflowItems?.length ? (
          <Tooltip content={<>{overflowItems.map(this.renderItem)}</>}>
            <Text as="div" muted small>
              + {overflowItems.length} more
            </Text>
          </Tooltip>
        ) : null}
      </>
    );
  };

  render() {
    const { list = [], inline } = this.props;

    if (!list.length) {
      return null;
    }

    const contents = this.renderList(list);

    return (
      <Flex flexDirection={inline ? 'row' : 'column'} gap={inline ? 1 : undefined}>
        {contents}
      </Flex>
    );
  }
}
