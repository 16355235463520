import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { PopoverInteractionKind } from '@blueprintjs/core';

import { Text, Tag, Flex, Box, Popover, Heading, Link } from 'core/components';
import AsnLink from 'app/components/links/AsnLink';
import ASNRenderer from './ASNRenderer';

/**
 * This Cmp is for rendering an ASGroup
 * The asns prop should be [{ id, description }, ...], otherwise the doLookup prop is needed
 * The lookup is done based on the group name
 */
@inject('$lookups')
@observer
export default class ASGroupRenderer extends Component {
  static defaultProps = {
    largeTag: false,
    showLinks: false,
    showNameAsLink: false
  };

  state = {
    asns: undefined,
    loadingDetails: true
  };

  componentDidMount() {
    const { asns } = this.props;
    if (!asns) {
      this.lookupASNs();
    } else {
      this.setState({ asns, loadingDetails: false });
    }
  }

  lookupASNs() {
    const { $lookups, name } = this.props;

    return $lookups.asGroup(name, { debounce: false }).then((res) => {
      const groupDetails = res.find((result) => result.value.toLowerCase() === name.toLowerCase());
      if (groupDetails) {
        this.setState({ asns: groupDetails.matcher, loadingDetails: false });
      }
    });
  }

  render() {
    const { name, level, largeTag, showNameAsLink, showLinks, asn, ...rest } = this.props;
    const { asns = [], loadingDetails } = this.state;

    return (
      <Flex alignItems="center" {...rest} gap={1}>
        {level && (
          <Heading level={level} mb={0}>
            {name}
          </Heading>
        )}
        {!level && (showNameAsLink ? <AsnLink asn={name}>{name}</AsnLink> : <Text fontWeight="bold">{name}</Text>)}

        <Popover
          position="right"
          interactionKind={PopoverInteractionKind.HOVER}
          minimal={false}
          disabled={!showLinks}
          usePortal
          target={
            <Tag interactive={showLinks} large={largeTag} round minimal intent="primary">
              {asn ? `AS${asn}` : 'AS Group'}
            </Tag>
          }
          content={
            <Box p={2} style={{ maxWidth: 500, maxHeight: 250 }} overflow="auto">
              <Flex mb={2}>
                <Text muted mr={1}>
                  AS Group:{' '}
                </Text>
                <Link fontWeight="bold" to={`/v4/core/quick-views/asn/${name}`}>
                  {name}
                </Link>
              </Flex>

              {!loadingDetails &&
                asns.map(({ id, description }, idx) => (
                  <Box key={`${id}-${name}`}>
                    <ASNRenderer asn={id} description={description} showLinks pt={idx ? 1 : ''} />
                  </Box>
                ))}
            </Box>
          }
        />
      </Flex>
    );
  }
}
