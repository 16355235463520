import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Field, FormComponent, InputGroup, Select } from 'core/form';
import { Box, Button, Flex, Highcharts, Link, Suspense, Tag } from 'core/components';
import LoadingSkeleton from 'app/views/metrics/LoadingSkeleton.js';
import { timezone, DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';

import getErrorBoundary from 'core/util/getErrorBoundary';
import { Table } from 'core/components/table';
import { Collection } from 'core/model';
import { DeviceStatusTag } from 'app/views/metrics/tables/StatusIndicators';
import { zeroToText } from 'app/util/utils';
import { countBy } from 'lodash';
import withConfigOptions from '../withConfigOptions';
import WidgetFrame from '../WidgetFrame';

const ErrorBoundaryCmp = getErrorBoundary('ReconDeviceAvailabilityWidget');

const LOOKBACK_OPTIONS = [
  { value: 7, label: '7 days' },
  { value: 14, label: '14 days' },
  { value: 30, label: '30 days' }
];

@withConfigOptions
@inject('$metrics', '$devices', '$colors')
@observer
class ReconDeviceAvailabilityWidget extends Component {
  static defaultProps = {
    defaultWidgetTitle: 'Device Availability',
    showDefaultOptions: true
  };

  state = {
    isLoading: true,
    isSaving: false,
    tableCollection: new Collection([], {
      sortState: {
        field: 'overall_availability',
        direction: 'asc'
      }
    }),
    data: []
  };

  componentDidMount() {
    this.fetchAvailabilityData(this.config);
  }

  get config() {
    const { config } = this.props;
    const { lookback = 7 } = config;
    return { lookback };
  }

  handleSave(form) {
    const { handleSaveConfiguration } = this.props;
    const lookback = form.getValue('lookback');
    this.setState({ isSaving: true });

    this.fetchAvailabilityData({ lookback }).then(() => {
      handleSaveConfiguration(form);
      this.setState({ isSaving: false });
    });
  }

  handleChartSelection = (segmentStart, segmentEnd) => {
    const { lookback } = this.config;
    if (segmentStart && segmentEnd) {
      this.fetchAvailabilityData({ lookback, segmentStart, segmentEnd });
    }
    // zoom was reset, disregard segmentStart and segmentEnd
    else {
      this.fetchAvailabilityData({ lookback });
    }
  };

  renderConfigurePanel = () => {
    const { handleCancelConfiguration, model, defaultWidgetTitle } = this.props;
    const { isSaving } = this.state;
    const { lookback } = this.config;

    const fields = {
      title: {
        label: 'Title',
        rules: 'required',
        defaultValue: defaultWidgetTitle
      },
      lookback: {
        label: 'Lookback:',
        rules: 'required',
        defaultValue: lookback,
        options: LOOKBACK_OPTIONS
      }
    };

    return (
      <FormComponent fields={fields} model={model} options={{ name: 'Configure NMS Device Availability' }}>
        {({ form }) => (
          <Flex flexDirection="column" height="100%" p={2}>
            <Field name="title" large>
              <InputGroup />
            </Field>
            <Field name="lookback" large>
              <Select />
            </Field>

            <Box flex={1} />

            <Flex gap="8px">
              <Button text="Cancel" onClick={() => handleCancelConfiguration(form)} fill large />
              <Button
                text="Save"
                intent="primary"
                fontWeight="medium"
                loading={isSaving}
                disabled={!form.valid}
                onClick={() => this.handleSave(form)}
                fill
                large
              />
            </Flex>
          </Flex>
        )}
      </FormComponent>
    );
  };

  generateConfig = (data) => {
    const handleChartSelection = this.handleChartSelection.bind(this);

    // get the min availability value
    const min = Math.min(...data.map((d) => d[1]));

    return {
      chart: {
        type: 'area',
        zoomType: 'x',

        events: {
          selection(event) {
            if (event.resetSelection) {
              handleChartSelection();
            } else if (event?.xAxis && event?.xAxis[0]) {
              const start = event.xAxis[0].min;
              const end = event.xAxis[0].max;
              handleChartSelection(start, end);
            }
          }
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        max: 100,
        // when the minimum values are high, we want to emphasize dips in availability
        min: min >= 90 ? 90 : 0,
        labels: {
          format: '{value}%'
        },
        title: {
          text: ''
        }
      },
      legend: {
        enabled: false
      },
      series: [
        {
          name: 'Availability',
          marker: {
            radius: 2
          },
          data
        }
      ],
      scrollbar: {
        enabled: true
      },
      tooltip: {
        formatter() {
          const date = timezone.momentFn(this.x).format(DEFAULT_DATETIME_FORMAT);
          return `${date}: ${zeroToText(this.y, {
            fix: this.y >= 99 && this.y < 100 ? 1 : 0,
            useAbsVal: false,
            useAbsParens: false
          })}%`;
        }
      }
    };
  };

  fetchAvailabilityData = (options) => {
    const { tableCollection } = this.state;
    const { $metrics, $devices } = this.props;

    return $metrics.fetchDevicesAvailability(options).then(({ chartData, tableData }) => {
      // decorate the tableData with Device metadata
      tableData.forEach((row) => {
        const id = row.km_device_id;
        const deviceSummary = $devices.deviceSummariesById[id];
        row.status = deviceSummary?.nms_status;
        row.device_name = deviceSummary?.device_name;
      });

      tableCollection.set(tableData);
      const deviceStatusCounts = countBy(tableData, (m) => m.status);
      this.setState({ data: chartData, isLoading: false, deviceStatusCounts });
    });
  };

  get tableColumns() {
    return [
      {
        label: 'Status',
        id: 'status',
        name: 'status',
        value: 'status',
        width: 125,
        renderer: ({ value }) => <DeviceStatusTag value={value} minWidth={110} />
      },
      {
        label: 'Device',
        id: 'device_name',
        name: 'device_name',
        value: 'device_name',
        flexBasis: 200,
        renderer: ({ value, model }) => {
          const id = model.get('km_device_id');
          const target = `/v4/nms/devices/${id}`;

          if (!value) {
            return `Deleted Device ${id}`;
          }

          return (
            <Link to={target} fontWeight="medium">
              {value}
            </Link>
          );
        }
      },
      {
        name: 'overall_availability',
        label: 'Overall',
        value: 'overall_availability',
        width: 80,
        renderer: ({ value }) =>
          `${zeroToText(value, { fix: value >= 99 && value < 100 ? 1 : 0, useAbsVal: false, useAbsParens: false })}%`
      },
      {
        name: 'segment_availability',
        label: 'Segment',
        value: 'segment_availability',
        width: 80,
        renderer: ({ value }) =>
          `${zeroToText(value, { fix: value >= 99 && value < 100 ? 1 : 0, useAbsVal: false, useAbsParens: false })}%`
      }
    ];
  }

  renderWidgetContent = () => {
    const { $colors } = this.props;
    const { data, tableCollection } = this.state;

    return (
      <Flex flexDirection="column">
        <Highcharts options={this.generateConfig(data)} colors={$colors.chartColors} height={130} />
        <Table collection={tableCollection} columns={this.tableColumns} onRowClick={null} />
      </Flex>
    );
  };

  render() {
    const { onRemove, isConfigurePanelOpen, model, handleShowConfigurePanel, defaultWidgetTitle } = this.props;
    const { isLoading, deviceStatusCounts } = this.state;

    return (
      <WidgetFrame
        canCustomize
        configAction={handleShowConfigurePanel}
        title={
          isConfigurePanelOpen ? (
            'Configure'
          ) : (
            <Flex alignItems="center" gap="4px">
              {model.get('title', defaultWidgetTitle)}
              <Tag minimal round small fontWeight="normal">
                Last {this.config.lookback} days
              </Tag>
              {!isLoading && (
                <>
                  {deviceStatusCounts.up && deviceStatusCounts.up > 0 && (
                    <Tag minimal round small fontWeight="normal" intent="success">
                      {zeroToText(deviceStatusCounts.up, { fix: 0 })} Up
                    </Tag>
                  )}
                  {deviceStatusCounts.down && deviceStatusCounts.down > 0 && (
                    <Tag minimal round small fontWeight="normal" intent="danger">
                      {zeroToText(deviceStatusCounts.down, { fix: 0 })} Down
                    </Tag>
                  )}
                  {deviceStatusCounts.unknown && deviceStatusCounts.unknown > 0 && (
                    <Tag minimal round small fontWeight="normal">
                      {zeroToText(deviceStatusCounts.unknown, { fix: 0 })} Unknown
                    </Tag>
                  )}
                </>
              )}
            </Flex>
          )
        }
        onRemove={onRemove}
        display="flex"
        flexDirection="column"
      >
        <Suspense loading={isLoading} fallback={<LoadingSkeleton />}>
          <ErrorBoundaryCmp>
            {isConfigurePanelOpen && this.renderConfigurePanel()}
            {!isConfigurePanelOpen && this.renderWidgetContent()}
          </ErrorBoundaryCmp>
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default ReconDeviceAvailabilityWidget;
