import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PopoverPosition } from '@blueprintjs/core';
import { omit } from 'lodash';
import { Box, Card, Flex, Text, Icon, Button, Popover, Tag, Tooltip } from 'core/components';
import { Field, Form, InputGroup, Select, Switch } from 'core/form/components';
import MultiSlider, { Handle } from 'core/components/MultiSlider';
import { safelyParseJSON } from 'core/util';
import { getTraceroutes } from 'app/stores/synthetics/utils';
import AgentSelectorCompact from 'app/views/synthetics/components/agentSelector/AgentSelectorCompact';
import { FiInfo } from 'react-icons/fi';
import Traceroute from './Traceroute';
import TestPathHops from './TestPathHops';

const fields = {
  selectedAgents: {
    label: 'Show',
    defaultValue: []
  },
  groupBy: {
    label: 'Group hops by',
    options: [
      { label: 'No Grouping', value: 'none' },
      // { label: 'Consecutive Nodes', value: 'path' },
      { label: 'ASN', value: 'asn' },
      { label: 'Site', value: 'site' }
      // { label: 'Region', value: 'region' }
    ],
    defaultValue: 'none'
  },
  percSuccessThreshold: {
    defaultValue: 0.9,
    rules: 'numeric',
    messages: { numeric: 'Not a valid number' },
    transform: {
      in: (value) => {
        if (value === '') {
          return 0;
        }
        return value * 100;
      },
      out: (value) => {
        if (value === '') {
          return 0;
        }
        return value / 100;
      }
    }
  },
  deltaLatencyThreshold: {
    defaultValue: 50,
    rules: 'numeric',
    messages: { numeric: 'Not a valid number' }
  },
  moreThanExpected: {
    label: 'Highlight links exceeding geo latency estimate',
    defaultValue: true
  },
  collapseFromStart: {
    label: 'After Agents',
    defaultValue: 0
  },
  collapseFromEnd: {
    label: 'Before Target',
    defaultValue: 0
  },
  openPaths: {
    defaultValue: null
  },
  collapseTimeouts: {
    label: 'Collapse Timeouts',
    defaultValue: true
  }
};

@inject('$app', '$exports', '$auth', '$dataviews', '$syn')
@Form({ fields, options: { name: 'Traceroute Options' } })
@observer
class TestPath extends Component {
  static defaultProps = {
    allowExport: true,
    isPreview: false
  };

  state = {
    isOpen: false
  };

  constructor(props) {
    super(props);

    const { $exports, form, allowExport } = this.props;

    if (allowExport) {
      $exports.getSettings().then(({ hashedTracerouteOpts }) => {
        let savedTracerouteOpts = {};

        if (hashedTracerouteOpts) {
          savedTracerouteOpts = hashedTracerouteOpts || {};
        } else if (window.localStorage) {
          savedTracerouteOpts = safelyParseJSON(localStorage.getItem('tracerouteOpts')) || {};
        }

        form.setValues({ ...savedTracerouteOpts });
      });
    }

    form.setValues({ selectedAgents: this.defaultSelectedAgents });

    form.onChange = () => {
      this.saveFormChange();
    };
  }

  componentDidUpdate(prevProps) {
    const { form, traceroutes } = this.props;

    if (traceroutes !== prevProps.traceroutes) {
      form.setValues({
        selectedAgents: this.defaultSelectedAgents
      });
    }
  }

  saveFormChange = () => {
    const { $exports, form, allowExport } = this.props;
    const values = form.getValues();

    if (allowExport) {
      if (window.localStorage) {
        localStorage.setItem('tracerouteOpts', JSON.stringify(omit(values, ['selectedAgents'])));
      }
      $exports.setHash({ hashedTracerouteOpts: omit(values, ['selectedAgents']) });
    }
  };

  onGroupByChange = ({ value: groupBy }) => {
    const { form } = this.props;

    form.setValues({
      groupBy,
      collapseFromStart: 0,
      collapseFromEnd: 0
    });

    this.saveFormChange();
  };

  onOpenPaths = (agentID, target_ip) => {
    const { form } = this.props;
    const { openPaths } = form.getValues();

    form.setValues({
      openPaths: openPaths ? [...openPaths, { agentID, target_ip }] : [{ agentID, target_ip }]
    });

    this.saveFormChange();
  };

  closeOpenPaths = (agentID) => {
    const { form } = this.props;
    const { openPaths } = form.getValues();

    form.setValues({
      openPaths: openPaths ? openPaths.filter((path) => path.agentID !== agentID) : null
    });

    this.saveFormChange();
  };

  setPaths = (agentIDs) => {
    const { form } = this.props;
    const { openPaths } = form.getValues();

    form.setValues({
      openPaths: openPaths ? openPaths.filter((path) => agentIDs.find((agentID) => path.agentID === agentID)) : null
    });

    this.saveFormChange();
  };

  get agentOptions() {
    const { $syn, agents } = this.props;

    if (agents && agents.length > 0) {
      return $syn.agents.getOptionsForAgentsByIds(agents);
    }

    return [];
  }

  get defaultSelectedAgents() {
    const { agentOptions } = this;
    const limit = 15;

    if (agentOptions.length > limit) {
      agentOptions.sort((a, b) => a.label.localeCompare(b.label));
    }

    return agentOptions.map(({ value }) => value).slice(0, limit);
  }

  resetForm = () => {
    const { form } = this.props;

    form.reset();
    form.setValues({
      selectedAgents: this.defaultSelectedAgents
    });

    this.saveFormChange();
  };

  render() {
    const {
      resultTimeMs,
      traceroutes,
      xMin,
      xMax,
      xAxisMin,
      xAxisMax,
      delta,
      onHover,
      form,
      onNodeSelect,
      selectedNode,
      traceResults,
      isPreview
    } = this.props;

    const { lookups, maxAsnPathCount, maxSitePathCount, maxRegionPathCount, isTraceResultsTruncated } = traceResults;

    const { isOpen } = this.state;

    const {
      deltaLatencyThreshold,
      collapseFromStart,
      collapseFromEnd,
      groupBy,
      openPaths,
      percSuccessThreshold,
      selectedAgents,
      collapseTimeouts,
      moreThanExpected
    } = form.getValues();

    const { label: groupByLabel } = fields.groupBy.options.find((o) => o.value === groupBy);
    const groupBtnText =
      groupBy === 'none'
        ? 'None or ASN...' // 'ASN, Region, or Nodes...'
        : `${groupByLabel}: ${collapseFromStart} after agent, ${collapseFromEnd} before target`;
    const maxPathCount =
      (groupBy === 'asn' && maxAsnPathCount) ||
      (groupBy === 'site' && maxSitePathCount) ||
      (groupBy === 'region' && maxRegionPathCount) ||
      0;
    const time = isPreview ? traceroutes?.[traceroutes?.length || 0 - 1]?.time || 0 * 1000 : resultTimeMs;

    return (
      <Box>
        <Card position="sticky" top="-16px" zIndex={2} display="flex" justifyContent="space-between" p={1} mb={2}>
          <Text fontWeight="bold">Filters</Text>
          <Flex flexDirection="column" alignItems="flex-end">
            <Flex mb={2}>
              <Flex alignItems="center" mr={2}>
                <Text as="div" mr="4px" small heavy>
                  Show
                </Text>
                <Box width={230}>
                  <AgentSelectorCompact
                    mb={0}
                    agentOptions={this.agentOptions}
                    selectedAgents={selectedAgents}
                    showLabel={false}
                  />
                </Box>
              </Flex>
              <Box mr={2}>
                <Popover
                  minimal={false}
                  position={PopoverPosition.BOTTOM}
                  content={
                    <Box p={2}>
                      <Field name="groupBy" onChange={this.onGroupByChange} small>
                        <Select />
                      </Field>
                      {maxPathCount > 1 && (
                        <Box mx={1} mb={1}>
                          <MultiSlider min={0} max={maxPathCount} disabled={maxPathCount <= 1}>
                            <Handle
                              intentBefore="primary"
                              intentAfter="none"
                              value={collapseFromStart}
                              onChange={(val) => form.getField('collapseFromStart').onChange(val)}
                            />
                            <Handle
                              intentAfter="primary"
                              value={maxPathCount - collapseFromEnd}
                              onChange={(val) => form.getField('collapseFromEnd').onChange(maxPathCount - val)}
                            />
                          </MultiSlider>
                        </Box>
                      )}
                    </Box>
                  }
                >
                  <Flex alignItems="center">
                    <Text as="div" small mr="4px">
                      Group hops by
                    </Text>
                    <Button rightIcon="caret-down" active={isOpen} small>
                      {groupBtnText}
                    </Button>
                  </Flex>
                </Popover>
              </Box>
              <Flex alignItems="center">
                <Text as="div" small mr="4px">
                  Highlight
                </Text>
                <Box mr={1}>
                  <Popover
                    minimal={false}
                    position={PopoverPosition.BOTTOM}
                    content={
                      <Box p={1}>
                        <Flex alignItems="center" m={1}>
                          <Text as="div" whiteSpace="nowrap" mr={1} small>
                            When latency is at least
                          </Text>
                          <Field name="deltaLatencyThreshold" mb={0}>
                            <InputGroup type="number" min="0" rightElement={<Tag minimal>ms</Tag>} width={100} />
                          </Field>
                        </Flex>
                      </Box>
                    }
                  >
                    <Button rightIcon="caret-down" small>
                      Latency &gt;= {deltaLatencyThreshold}ms
                    </Button>
                  </Popover>
                </Box>
                <Box>
                  <Popover
                    minimal={false}
                    position={PopoverPosition.BOTTOM}
                    content={
                      <Box p={1}>
                        <Flex justifyContent="space-between" alignItems="center">
                          <Box whiteSpace="nowrap" mr={1}>
                            When success rate is at or below
                          </Box>
                          <Field name="percSuccessThreshold" mb={0}>
                            <InputGroup
                              type="number"
                              min="0"
                              max="100"
                              rightElement={<Tag minimal>%</Tag>}
                              width={120}
                            />
                          </Field>
                        </Flex>
                      </Box>
                    }
                  >
                    <Button rightIcon="caret-down" small>
                      {/* round the output to prevent precision goofiness such as 0.55 * 100 = 55.00000000000001  */}
                      Success Rate &lt;= {Math.round(percSuccessThreshold * 100)}%
                    </Button>
                  </Popover>
                </Box>
              </Flex>
            </Flex>
            <Flex alignItems="center">
              <Field name="collapseTimeouts" showLabel={false} small mb={0} mr={2}>
                <Switch showFieldLabel />
              </Field>
              <Flex alignItems="center">
                <Field name="moreThanExpected" showLabel={false} small mb={0}>
                  <Switch showFieldLabel />
                </Field>
                <Tooltip content="Turn this on if you want us to compare latency measured with latency calculated using geo-ip distance">
                  <Icon iconSize={14} icon={FiInfo} color="primary" ml="4px" />
                </Tooltip>
              </Flex>
              <Button onClick={this.resetForm} ml={2} small>
                Reset
              </Button>
            </Flex>
          </Flex>
        </Card>
        {!isPreview && (
          <TestPathHops
            isTraceResultsTruncated={isTraceResultsTruncated}
            traceroutes={getTraceroutes({ selectedAgentIds: selectedAgents, traceResults })} // filtered traceroutes by selected agents, the Traceroute component handles its own logic for that
            resultTimeMs={resultTimeMs}
            onHover={onHover}
            xMin={xMin}
            xMax={xMax}
            xAxisMin={xAxisMin}
            xAxisMax={xAxisMax}
            delta={delta}
            mb={5}
          />
        )}
        {!!selectedAgents.length && (
          <Traceroute
            time={time}
            traceroutes={traceroutes}
            lookups={lookups}
            selectedAgents={selectedAgents}
            groupBy={groupBy}
            collapseFromStart={collapseFromStart}
            collapseFromEnd={collapseFromEnd}
            openPaths={openPaths}
            onOpenPaths={this.onOpenPaths}
            closeOpenPaths={this.closeOpenPaths}
            setPaths={this.setPaths}
            deltaLatencyThreshold={deltaLatencyThreshold}
            percSuccessThreshold={percSuccessThreshold}
            onNodeSelect={onNodeSelect}
            selectedNode={selectedNode}
            collapseTimeouts={collapseTimeouts}
            moreThanExpected={moreThanExpected}
          />
        )}
      </Box>
    );
  }
}

export default TestPath;
