import get from 'lodash/get';
import { formatDateTime } from 'core/util/dateUtils';
import BaseHighchartsNonStyledDataview from 'app/components/dataviews/views/BaseHighchartsNonStyledDataview';

class BaseMultiSeriesLineOrAreaGraph extends BaseHighchartsNonStyledDataview {
  state = {};

  constructor(props, { chartOptions }) {
    super(props);
    const { $auth, theme, nullFormat } = this.props;
    const { colors, fonts } = theme;
    const { userTimezone } = $auth;

    this.state = {
      userTimezone,
      stickyPointer: true,
      highlightPointsByEvent: true,
      chartOptions: {
        chart: {
          type: 'line',
          style: { fontFamily: fonts.body },
          height: 200,
          spacingLeft: 0,
          spacingRight: 0,
          backgroundColor: colors.chart.tooltipBackground
        },
        title: { text: '' },
        credits: { enabled: false },
        legend: { enabled: false },
        xAxis: {
          type: 'datetime',
          gridLineColor: colors.chart.gridLine,
          lineColor: colors.chart.axisLine,
          tickColor: colors.chart.axisTick,
          labels: {
            align: 'center',
            style: {
              color: colors.muted
            },
            formatter() {
              if (this.dateTimeLabelFormat === '%b %e') {
                return `<strong>${formatDateTime(this.value, 'MMM Do')}</strong>`;
              }

              return this.axis.defaultLabelFormatter.call(this);
            }
          },
          dateTimeLabelFormats: {
            day: '%b %e',
            week: '%b %e'
          }
        },
        yAxis: {
          title: { text: '' },
          labels: {
            align: 'left',
            x: 0,
            y: -2,
            style: {
              color: colors.muted
            },
            ...(get(chartOptions, 'yAxis.labels') || {})
          },
          gridLineColor: colors.chart.gridLine,
          min: get(chartOptions, 'yAxis.min'),
          max: get(chartOptions, 'yAxis.max'),
          type: get(chartOptions, 'yAxis.type') || 'linear',
          custom: get(chartOptions, 'yAxis.custom') || {}
        },
        tooltip: {
          outside: true,
          useHTML: true,
          backgroundColor: colors.chart.tooltipBackground,
          shared: true,
          style: {
            color: colors.body,
            fontFamily: fonts.body
          },
          headerFormat: '',
          formatter() {
            if (this.points[0]) {
              const numOfPoints = this.points.length;
              const columnLimit = numOfPoints > 50 ? Math.ceil(numOfPoints / 4) : 50;
              const excludeZeros = get(chartOptions, 'tooltip.excludeZeros') || false;
              const sort = get(chartOptions, 'tooltip.sort') || false;
              const points = excludeZeros ? this.points.filter((p) => p.y > 0) : this.points;
              const sortedPoints = sort ? points.sort((a, b) => (b.y > a.y ? 1 : -1)) : points;
              let content;

              if (points.length) {
                content = sortedPoints.reduce((acc, curr, i) => {
                  // break the list into 4 columns if there are more than 50 items
                  if (i % columnLimit === 0) {
                    if (i !== 0) {
                      acc += '</div>';
                      acc += '<div style="margin-left: 8px;">';
                    } else {
                      acc += '<div>';
                    }
                  }
                  acc += curr.series.tooltipOptions.pointFormatter.call(curr);
                  if (i === numOfPoints - 1) {
                    acc += '</div>';
                  }
                  return acc;
                }, '');
              } else {
                content = nullFormat || 'No Data';
              }

              return `
                <div>
                  <strong>${formatDateTime(this.x, 'MMM D, HH:mm')}</strong>
                  <hr/>
                </div>
                <div style="display: flex; flex: 1 0 auto;">
                  ${content}
                </div>
              `;
            }

            return null;
          },
          ...(get(chartOptions, 'tooltip') || {})
        },
        series: [],
        plotOptions: {
          series: {
            turboThreshold: 0,
            marker: {
              enabled: false
            },
            cursor: 'pointer'
          },
          area: {
            stacking: undefined,
            lineWidth: 0,
            marker: {
              enabled: false
            }
          }
        }
      }
    };
  }

  get type() {
    return 'timeline';
  }

  redraw() {
    if (this.chart) {
      const { theme } = this.props;
      const { colors, fonts } = theme;

      this.setState({
        chartOptions: {
          chart: {
            backgroundColor: colors.chart.tooltipBackground,
            style: { fontFamily: fonts.body }
          },
          tooltip: {
            backgroundColor: colors.chart.tooltipBackground,
            style: {
              color: colors.body,
              fontFamily: fonts.body
            }
          },
          xAxis: {
            lineColor: colors.chart.axisLine,
            gridLineColor: colors.chart.gridLine,
            tickColor: colors.chart.axisTick,
            labels: {
              style: {
                color: colors.muted
              }
            }
          },
          yAxis: {
            labels: {
              style: {
                color: colors.muted
              }
            },
            gridLineColor: colors.chart.gridLine
          }
        }
      });
    }
  }

  renderData() {
    if (this.chart) {
      const { data, title, type, stacking, xAxisMin, xAxisMax, xAxisTitle, onHover, onClick } = this.props;
      const unmutableData = JSON.parse(JSON.stringify(data));
      const series = [];

      for (let i = 0; i < unmutableData.length; i++) {
        const pilot = unmutableData[i];
        pilot.point = {
          events: {
            click: (e) => {
              if (onClick) {
                onClick(e.point.options.time);
              }
            },
            mouseOver: (e) => {
              if (onHover) {
                onHover(e.target.options.time);
              }
            }
          }
        };

        if (!pilot.marker) {
          pilot.marker = {};
        }

        pilot.marker.states = {
          hover: {
            enabled: false,
            lineWidthPlus: 0
          },
          select: {
            color: pilot.color,
            fillColor: pilot.color,
            lineColor: 'rgba(255, 255, 255, 0.5)',
            lineWidth: 5,
            radius: 8
          }
        };

        series.push(pilot);
      }

      this.setState(({ chartOptions }) => {
        chartOptions.series = series;
        chartOptions.xAxis = { min: xAxisMin, max: xAxisMax };
        chartOptions.tooltip.headerFormat = title;
        chartOptions.chart.type = type;
        chartOptions.plotOptions.area.stacking = stacking;

        if (xAxisTitle) {
          chartOptions.xAxis.title = { text: xAxisTitle };
        }

        return { chartOptions };
      });
    }
  }
}

export default BaseMultiSeriesLineOrAreaGraph;
