import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Position } from '@blueprintjs/core';
import { Button, Popover, Box } from 'core/components';
import { Field, Select } from 'core/form/components';
import { safelyParseJSON } from 'core/util';
import { dateRangeDisplay, DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import GraphWrapper from './GraphWrapper';

const DEFAULT_STATE = {
  init: false,
  loading: true,
  results: null,
  hasResults: false,
  graphProps: {}
};

@inject('$exports', '$auth', '$dataviews', '$bgp')
@observer
class GraphContainer extends Component {
  static defaultProps = {
    type: '' // must match up with $bgp store and node/synthetics/bgp/requestHandler options
  };

  state = { ...DEFAULT_STATE };

  cleaned = false;

  static getDerivedStateFromProps(props, state) {
    const { $bgp, type, parseData, prefix, asn, checkForResults = () => true, model, routeviewerConfig, form } = props;
    const { results, init } = state;
    const request = $bgp.requests[type];

    if (!!request && !!request.data && !results) {
      let graphProps = {};

      if (parseData) {
        const { data, graphProps: newGraphProps } = parseData({
          data: request.data,
          prefix,
          asn,
          config: routeviewerConfig || (!!model && model.get('config.bgp'))
        });

        if (newGraphProps) {
          graphProps = newGraphProps;
        }

        return {
          loading: false,
          results: data,
          hasResults: !!data && checkForResults(data, request.data),
          graphProps
        };
      }
    }

    if (!!request && !request.data && !!results) {
      return {
        loading: true,
        results: null,
        hasResults: false
      };
    }

    if (!form && !init) {
      return {
        init: true
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { $exports, form, type, model } = props;

    if (form) {
      const settingsKey = `BGPGraph${type.toUpperCase()}Opts`;
      const exportKey = `hashed${settingsKey}`;
      const storageKey = `${settingsKey}-${model.id}`;
      $exports.getSettings().then((settings) => {
        let savedGraphingOpts;
        const hashedGraphingOpts = settings[exportKey];

        if (hashedGraphingOpts) {
          savedGraphingOpts = hashedGraphingOpts;
        } else if (window.localStorage) {
          savedGraphingOpts = safelyParseJSON(localStorage.getItem(storageKey)) || {};
        }

        form.setValues({ ...savedGraphingOpts });
        this.setState({ init: true });
      });

      form.onChange = () => {
        const values = form.getValues();

        this.fetch(true);
        $exports.setHash({ [exportKey]: values });

        if (window.localStorage) {
          localStorage.setItem(storageKey, JSON.stringify(values));
        }
      };
    }
  }

  componentDidMount() {
    const { init } = this.state;

    if (init) {
      this.fetch();
    }

    window.addEventListener('beforeunload', this.unsubscribe);
  }

  componentDidUpdate(prevProps, prevState) {
    const { shouldUpdate, prefix, asn, specificPrefix } = this.props;
    const { init } = this.state;

    if (
      shouldUpdate(this.props, prevProps) ||
      prefix !== prevProps.prefix ||
      asn !== prevProps.asn ||
      specificPrefix !== prevProps.specificPrefix ||
      init !== prevState.init
    ) {
      this.fetch(true);
    }
  }

  componentWillUnmount() {
    if (!this.cleaned) {
      this.unsubscribe();
    }

    window.removeEventListener('beforeunload', this.unsubscribe);
  }

  unsubscribe = () => {
    const { $bgp, type } = this.props;
    $bgp.unsubscribe(type);
    this.cleaned = true;
  };

  fetch(change) {
    const { model, form, $bgp, startDate, endDate, prefix, asn, specificPrefix, type, routeviewerConfig } = this.props;
    const graphFormValues = form?.getValues() || {};
    const config = routeviewerConfig || (!!model && model.get('config.bgp'));

    if (change) {
      this.setState({ ...DEFAULT_STATE });
    }

    $bgp.requestData({
      type,
      req: $bgp.getPayload(config, { prefix, asn, specificPrefix, graphFormValues }),
      startDate,
      endDate
    });
  }

  onClickTimeline = (startDate) => {
    const { onClickTimeline } = this.props;
    const { delta = 60000 } = this.state;

    if (onClickTimeline) {
      onClickTimeline(startDate, startDate + delta);
    }
  };

  getTimelineTitle = () => {
    const { startDate, endDate } = this.props;
    const { delta = 60000 } = this.state;
    const dateRangeText = dateRangeDisplay({ starting_time: startDate * 1000, ending_time: endDate * 1000 });

    return `${dateRangeText} (${delta / 1000 / 60} minute increments)`;
  };

  getGraphTitle = () => {
    const { startDate, endDate } = this.props;
    const dateRangeText = dateRangeDisplay({
      starting_time: startDate * 1000,
      ending_time: endDate - startDate > 60 ? endDate * 1000 : null,
      format: DEFAULT_DATETIME_FORMAT
    });

    return `${dateRangeText}`;
  };

  getProgress() {
    const { $bgp, type } = this.props;

    return {
      loaded: $bgp[`${type}Loaded`],
      loadingCount: $bgp[`${type}LoadingCount`],
      parsed: $bgp[`${type}Parsed`]
    };
  }

  getData() {
    const { $bgp, type } = this.props;
    const { hasResults } = this.state;

    return hasResults ? $bgp.requests[type].data || {} : {};
  }

  getText() {
    const { text } = this.props;

    return text(this.getData());
  }

  canFilterByAsn() {
    const { type } = this.props;

    return type !== 'pathChangesTS';
  }

  // todo: move to node
  getFilteredResults() {
    const { state, props } = this;
    const { filterData, filters } = props;
    const { results, hasResults } = state;

    if (hasResults && filters) {
      const filtersObj = filters.reduce((acc, filter) => {
        acc[filter] = props[filter];
        return acc;
      }, {});
      const filteredResults = filterData(results, filtersObj);

      return {
        results: filteredResults,
        hasResults: !!filteredResults.length
      };
    }

    return {
      results,
      hasResults
    };
  }

  buildGraphFormContent() {
    const { form } = this.props;

    return (
      <Box width={262} p={2} pb={1}>
        {form.fieldConfigs.map(({ type, name }) => {
          if (type === 'select') {
            return (
              <Field key={name} name={name} mb={1}>
                <Select />
              </Field>
            );
          }

          return null;
        })}
      </Box>
    );
  }

  renderGraphForm() {
    const { form } = this.props;

    if (form) {
      return (
        <Popover key="graph-container-menu" position={Position.BOTTOM_RIGHT} content={this.buildGraphFormContent()}>
          <Button
            icon="more"
            minimal
            // cheaper alternative to a whole new svg icon
            style={{ transform: 'rotate(90deg)' }}
          />
        </Popover>
      );
    }

    return null;
  }

  render() {
    const {
      xAxisMin,
      xAxisMax,
      onHover,
      heading,
      subHeading,
      noResultsDescription,
      timelineComponent: Timeline,
      tableComponent: Table,
      graphComponent: Graph,
      setASN,
      setPrefix,
      collapsePath,
      asn,
      prefix,
      specificPrefix,
      form
    } = this.props;
    const { loading, graphProps } = this.state;
    const { results, hasResults } = this.getFilteredResults();
    const progress = this.getProgress();
    const text = this.getText();
    const title = !Timeline ? this.getGraphTitle() : '';
    const isAbleToRender = !asn || this.canFilterByAsn();

    return (
      <GraphWrapper
        key={this.key}
        loading={loading}
        progress={progress}
        renderContent={isAbleToRender && hasResults}
        heading={heading}
        subHeading={subHeading?.({ form })}
        text={text}
        title={title}
        noResultsDescription={isAbleToRender ? noResultsDescription : 'Unable to filter AS path changes by asn'}
        expand={!!Table}
        menu={this.renderGraphForm()}
      >
        {Timeline !== undefined && (
          <Timeline
            data={results}
            onHover={onHover}
            onClick={this.onClickTimeline}
            xAxisMin={xAxisMin}
            xAxisMax={xAxisMax}
            xAxisTitle={this.getTimelineTitle()}
            {...graphProps}
          />
        )}
        {Table !== undefined && <Table results={results} {...graphProps} />}
        {Graph !== undefined && (
          <Graph
            {...results}
            setASN={setASN}
            setPrefix={setPrefix}
            collapsePath={collapsePath}
            asn={asn}
            prefix={prefix}
            specificPrefix={specificPrefix}
          />
        )}
      </GraphWrapper>
    );
  }
}

export default GraphContainer;
