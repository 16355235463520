import React from 'react';

import { Box, Text } from 'core/components';

import FlowResultsTable from './FlowResultsTable';

function NodeFlowDetails({ details, getNodeFlowTotal }) {
  const flowTotals = getNodeFlowTotal(details.data);

  return (
    <>
      {flowTotals.ingress.length > 0 && (
        <Box mb={flowTotals.egress.length > 0 ? 2 : 0}>
          <Text color="muted" as="div" fontWeight="medium" fontSize="small" pb="4px">
            Total Ingress
          </Text>
          <FlowResultsTable flowData={flowTotals.ingress} />
        </Box>
      )}

      {flowTotals.egress.length > 0 && (
        <>
          <Text color="muted" as="div" fontWeight="medium" fontSize="small" pb="4px">
            Total Egress
          </Text>
          <FlowResultsTable flowData={flowTotals.egress} />
        </>
      )}
    </>
  );
}

export default NodeFlowDetails;
