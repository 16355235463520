import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Flex, Callout, Heading, Box, Card, Suspense } from 'core/components';
import MultiSlider, { Handle } from 'core/components/MultiSlider';
import { Field, InputGroup, Select } from 'core/form/components';
import { formConsumer, TextArea } from 'core/form';

@inject('$capacity')
@formConsumer
@observer
export default class CapacityPlanConfigure extends Component {
  handleThresholdChange = (thresholdType, state, value) => {
    const { form } = this.props;
    form.setValue(`thresholds.${thresholdType}.${state}`, value);
  };

  render() {
    const { $capacity, form, model, hasInterfaces } = this.props;

    return (
      <Suspense loading={!model}>
        {!hasInterfaces && (
          <Callout
            my={3}
            intent="danger"
            title="No interfaces assigned to this Capacity Plan"
            icon={$capacity.severityTypes.critical.icon}
          >
            Interfaces must be assigned to a Capacity Plan in order for them to be tracked. Interfaces may be added to
            this Capacity Plan under the &ldquo;Interfaces&rdquo; tab.
          </Callout>
        )}
        <Grid gridTemplateColumns="320px 1fr" gridColumnGap="32px">
          <Box mr={2}>
            <Field name="name" autoFocus large>
              <InputGroup fill />
            </Field>
            <Field name="description" large>
              <TextArea rows={2} fill />
            </Field>
          </Box>
          <Box>
            <Heading level={5}>Thresholds</Heading>
            <Card p={2} mb={2}>
              <Flex gap="24px">
                <Box>
                  <Field name="thresholds.runout.strategy" component={Select} m={0} />
                </Box>
                <Box mx={2} flex={1}>
                  <Field
                    name="thresholds.runout"
                    m={0}
                    label={`Runout (${
                      form.getField('thresholds.runout.strategy').value === 'monthOverMonth' ? 'months' : 'weeks'
                    })`}
                  />
                  <MultiSlider
                    min={0}
                    max={6}
                    labelStepSize={1}
                    labelRenderer={(value) => (value === 0 ? '6+' : Math.abs(value - 6))}
                  >
                    <Handle
                      intentBefore="success"
                      intentAfter="warning"
                      value={form.getValue('thresholds.runout.warningDisplay')}
                      onChange={(value) => this.handleThresholdChange('runout', 'warningDisplay', value)}
                    />
                    <Handle
                      intentAfter="danger"
                      value={form.getValue('thresholds.runout.criticalDisplay')}
                      onChange={(value) => this.handleThresholdChange('runout', 'criticalDisplay', value)}
                    />
                  </MultiSlider>
                </Box>
              </Flex>
            </Card>
            <Card p={2}>
              <Flex gap="24px">
                <Box>
                  <Field name="thresholds.utilization.aggregate" component={Select} m={0} />
                </Box>

                <Box mx={2} flex={1}>
                  <Field name="thresholds.utilization" m={0} />

                  <MultiSlider min={0} max={100} mb={0} labelStepSize={10} labelRenderer={(value) => `${value}%`}>
                    <Handle
                      intentBefore="success"
                      intentAfter="warning"
                      value={form.getValue('thresholds.utilization.warning')}
                      onChange={(value) => this.handleThresholdChange('utilization', 'warning', value)}
                    />
                    <Handle
                      intentAfter="danger"
                      value={form.getValue('thresholds.utilization.critical')}
                      onChange={(value) => this.handleThresholdChange('utilization', 'critical', value)}
                    />
                  </MultiSlider>
                </Box>
              </Flex>
            </Card>
          </Box>
        </Grid>
      </Suspense>
    );
  }
}
