import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import { Classes, Overlay } from '@blueprintjs/core';
import { isEmpty } from 'lodash';

import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import { Box, Card, Flex, Heading, Spinner, Image, Text } from 'core/components';

const placeholderUrl =
  'https://user-images.githubusercontent.com/47315479/81145216-7fbd8700-8f7e-11ea-9d49-bd5fb4a888f1.png';

const LazyThumbnail = ({ src, error, onClick }) => {
  const containerRef = useRef();
  const image = new window.Image();
  const [state, setState] = useState({ isLoading: true, src: placeholderUrl, visible: false });

  const handleObservation = (observables, observer) => {
    const [observable] = observables;
    const { isIntersecting } = observable;
    if (!state.visible && isIntersecting) {
      setState((prevState) => ({ ...prevState, visible: isIntersecting }));
      if (observer && containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    }
  };

  const visibleObserver = new IntersectionObserver(
    (observables) => {
      handleObservation(observables, visibleObserver);
    },
    { threshold: 1 }
  );

  useEffect(() => {
    if (!state.visible && state.isLoading && containerRef.current) {
      visibleObserver.observe(containerRef.current);
    }
    return () => visibleObserver.disconnect();
  }, [containerRef.current, state.isLoading, state.visible]);

  useEffect(() => {
    let isMounted = true;

    if (state.visible) {
      image.src = error ? placeholderUrl : src;
      image.onload = () => {
        if (isMounted) {
          setState((prevState) => ({ ...prevState, isLoading: false, src: error ? placeholderUrl : src }));
        }
      };
      image.onerror = () => {
        if (isMounted) {
          setState((prevState) => ({ ...prevState, isLoading: false, src: placeholderUrl }));
        }
      };
    }

    return () => {
      isMounted = false;
    };
  }, [state.visible, error, src]);

  return (
    <Card
      ref={containerRef}
      width={285}
      height={122}
      style={{ overflow: 'hidden', cursor: error ? 'auto' : 'pointer' }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      onClick={error ? () => {} : onClick}
    >
      {state.isLoading ? (
        <Spinner size={18} />
      ) : (
        <img style={{ width: '100%', height: 'auto', objectFit: 'cover' }} src={state.src} alt="" />
      )}
    </Card>
  );
};

const TransactionScreenshots = ({ data: trxScreenshots }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [screenshots, setScreenshots] = useState(trxScreenshots);
  const [screenshotCount, setScreenshotCount] = useState(trxScreenshots?.length);

  useEffect(() => {
    setScreenshots(trxScreenshots);
    setScreenshotCount(trxScreenshots?.length);
  }, [setScreenshots, setScreenshotCount, trxScreenshots]);

  const handleSelectImage = (image) => (e) => {
    e.preventDefault();
    setSelectedImage(image);
  };

  const handleCloseImageView = () => {
    setSelectedImage(null);
  };

  return (
    <Box>
      <Heading level={5} fontWeight="heavy" mt={2}>
        Screenshots ({screenshotCount})
      </Heading>
      {!isEmpty(trxScreenshots) && (
        <Text fontWeight="normal" muted mb={4}>
          Click a thumbnail to view full size.
        </Text>
      )}
      <Flex flex={1} flexWrap="wrap" py={4}>
        {screenshots?.map(({ key, name, path, time, error }) => {
          let prettyName = name;
          if (name.includes('-')) {
            prettyName = name.split('-')[0].replace('-', '');
          }
          return (
            <Flex flexDirection="column" key={key} mx={2} mb={4}>
              <Box mb={1}>
                <LazyThumbnail src={path} error={error} onClick={handleSelectImage(path)} />
              </Box>
              {name && (
                <Text as="div" mb={0.75} fontWeight="medium">
                  {prettyName}
                </Text>
              )}
              {/* We parse this time from the AWS Url */}
              {!!time && !Number.isNaN(time) && (
                <Text as="div" small muted>
                  {moment
                    .unix(time / 1000)
                    .utc()
                    .format(DEFAULT_DATETIME_FORMAT)}
                </Text>
              )}
            </Flex>
          );
        })}
      </Flex>

      <Overlay
        onClose={handleCloseImageView}
        className={Classes.OVERLAY_SCROLL_CONTAINER}
        autoFocus
        canEscapeKeyClose
        canOutsideClickClose
        enforceFocus
        hasBackdrop
        isOpen={!!selectedImage}
      >
        <Flex m={4} alignItems="center" justifyContent="center" left="50%" right="50%">
          <Image my={4} width="auto" src={selectedImage} />
        </Flex>
      </Overlay>
    </Box>
  );
};

export default TransactionScreenshots;
