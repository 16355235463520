import React from 'react';
import { Box, LinkButton } from 'core/components';
import { inject } from 'mobx-react';

function ModuleLinkButton(props) {
  const { to, text, blank, $app, ...rest } = props;

  if ($app.isExport) {
    return null;
  }

  return (
    <Box {...rest}>
      <LinkButton to={to} text={text} blank={blank} rightIcon="chevron-right" />
    </Box>
  );
}

export default inject('$app')(ModuleLinkButton);
