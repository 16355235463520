import { select } from 'd3-selection';
import { makeText, traceCurve, verticalTraceCurve, getPointAndAngleOfPathAtLength } from 'core/util/svgUtils';

// nodes
export const makeNodeRect = (nodeGroup) =>
  nodeGroup
    .append('rect')
    .attr('rx', 2)
    .attr('ry', 2)
    .attr('x', (d) => d.bounds.x)
    .attr('y', (d) => d.bounds.y)
    .attr('width', (d) => d.bounds.width)
    .attr('height', (d) => d.bounds.height)
    .style('cursor', 'pointer')
    .attr('stroke', (d) => d.style.stroke || d.style.color)
    .attr('stroke-width', 2)
    .attr('fill', (d) => d.style.color)
    .attr('fill-opacity', 0.5);

export const makePrefixNodeText = (nodeGroup, color) =>
  makeText(nodeGroup)
    .attr('x', (d) => d.bounds.cx)
    .attr('y', (d) => d.bounds.cy)
    .attr('dx', 0)
    .attr('stroke', 'none')
    .attr('fill', color)
    .attr('fill-opacity', 0.8)
    .attr('alignment-baseline', 'central')
    .attr('dominant-baseline', 'central')
    .attr('text-anchor', 'middle')
    .text((d) => d.text);

export const makeASNNodeIDText = (nodeGroup, color, collapsePath) =>
  makeText(nodeGroup)
    .attr('x', (d) => d.bounds.cx + (collapsePath && (d.pruned || d.isVp) ? 6 : 0))
    .attr('y', (d) => d.bounds.cy - (collapsePath ? 0 : 9))
    .attr('dx', 0)
    .attr('stroke', 'none')
    .attr('fill', color)
    .attr('fill-opacity', 0.8)
    .attr('alignment-baseline', 'central')
    .attr('dominant-baseline', 'central')
    .attr('text-anchor', 'middle')
    .attr('font-weight', 'bold')
    .text((d) => d.info.name);

export const makeASNNodeNameText = (nodeGroup, color) =>
  makeText(nodeGroup)
    .attr('x', (d) => d.bounds.cx)
    .attr('y', (d) => d.bounds.cy + 9)
    .attr('dx', 0)
    .attr('stroke', 'none')
    .attr('fill', color)
    .attr('fill-opacity', 0.8)
    .attr('alignment-baseline', 'central')
    .attr('dominant-baseline', 'central')
    .attr('text-anchor', 'middle')
    .text((d) => d.info.title);

export const makeVPASNNodeIcon = (nodeGroup, color) => {
  const size = 14;
  const icon = nodeGroup
    .append('svg')
    .attr('width', size)
    .attr('height', size)
    .attr('viewBox', '0 0 24 24')
    .attr('x', (d) => d.bounds.left + 6)
    .attr('y', (d) => d.bounds.top + 6)
    .attr('stroke-width', '0')
    .attr('fill', 'none')
    .style('pointer-events', 'none');

  icon.append('path').attr('stroke', color).attr('stroke-width', 1.5).attr(
    'd',
    // eslint-disable-next-line max-len
    'M18.5,21 C20.9852814,21 23,18.9852814 23,16.5 C23,14.0147186 20.9852814,12 18.5,12 C16.0147186,12 14,14.0147186 14,16.5 C14,18.9852814 16.0147186,21 18.5,21 L18.5,21 Z M10,7 L14,7 M1.5,14.5 C1.5,14.5 5.5,5 6,4 C6.5,3 7.5,3 8,3 C8.5,3 10,3 10,5 L10,16 M5.5,21 C3.01471863,21 1,18.9852814 1,16.5 C1,14.0147186 3.01471863,12 5.5,12 C7.98528137,12 10,14.0147186 10,16.5 C10,18.9852814 7.98528137,21 5.5,21 L5.5,21 L5.5,21 Z M22.5,14.5 C22.5,14.5 18.5,5 18,4 C17.5,3 16.5,3 16,3 C15.5,3 14,3 14,5 L14,16 M10,16 L14,16'
  );
  return icon;
};

// paths
export const makeLinkPaths = (linkGroup, color) =>
  linkGroup
    .append('path')
    .style('cursor', 'pointer')
    .attr('stroke-width', 2)
    .attr('stroke', color)
    .attr('d', (d) => {
      if (d.direction === 'same') {
        return verticalTraceCurve(d);
      }
      return traceCurve(d);
    });

export const makeLinkHoverPaths = (linkGroup) =>
  linkGroup
    .append('path')
    .attr('stroke-width', 10)
    .attr('stroke', 'transparent')
    .attr('d', (d) => {
      if (d.direction === 'same') {
        return verticalTraceCurve(d);
      }
      return traceCurve(d);
    });

const littleArrow = 'M0,-4L6,0L0,4';
const bigArrow = 'M0,-6L6,0L0,6';

export const makeLinkArrows = (linkGroup, color) => {
  // eslint-disable-next-line func-names
  linkGroup.each(function () {
    const pathSvg = select(this.childNodes[0]).node();
    const length = pathSvg.getTotalLength();

    for (let i = 0.3; i < 0.5; i += 0.3) {
      const {
        point: [x, y],
        angle
      } = getPointAndAngleOfPathAtLength(pathSvg, length * i);

      select(this)
        .append('path')
        .attr('class', 'arrow')
        .attr('d', littleArrow)
        .attr('fill', 'none')
        .attr('stroke', color)
        .attr('stroke-width', 2)
        .attr('transform-origin', `${x}px ${y}px`)
        .attr('transform', `rotate(${angle}) translate(${x},${y})`);
    }
  });

  return linkGroup.selectAll('.arrow');
};

export const linkHover = ({ paths, arrows, isSelected, hoverColor, color }, group = null) => {
  paths.attr('stroke', (d) => (isSelected(d, group) ? hoverColor : color));

  arrows
    .attr('stroke', (d) => (isSelected(d, group) ? hoverColor : color))
    .attr('d', (d) => (isSelected(d, group) ? bigArrow : littleArrow));
};
