import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Text, Box } from 'core/components';
import { formConsumer } from 'core/form';
import FilterOptionsDialog from 'app/components/filters/FilterOptionsDialog';
import FilterOptionsDisplay from 'app/components/filters/FilterOptionsDisplay';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$savedFilters')
@formConsumer
@observer
class SidebarFilterOptions extends Component {
  static defaultProps = {
    fieldName: 'filters',
    smallText: true,
    emptyText: 'No filtering criteria have been applied',
    readOnly: false,
    muted: true,
    isOpen: false,
    showNames: false,
    showEmptyText: true,
    allowNestedFilters: false,
    showSaveFiltersButton: true,
    allowRightFilterField: true
  };

  handleCloseFilterDialog = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const {
      form,
      className,
      emptyText,
      fieldName,
      readOnly,
      muted,
      isOpen,
      smallText,
      showEmptyText,
      showSaveFiltersButton
    } = this.props;
    const numFilters = form.getValue(`${fieldName}.filterGroups`).length;

    return (
      <Box className={className} opacity={readOnly && muted ? 0.4 : 1}>
        {numFilters === 0 && showEmptyText && (
          <Text muted small={smallText}>
            {emptyText}
          </Text>
        )}
        {numFilters > 0 && <FilterOptionsDisplay {...this.props} />}
        {isOpen && (
          <FilterOptionsDialog
            {...this.props}
            showSaveFiltersButton={showSaveFiltersButton}
            onClose={this.handleCloseFilterDialog}
          />
        )}
      </Box>
    );
  }
}

export default SidebarFilterOptions;
