import buildStructure from './structure';
import addPositioning from './position';
import { setOptions as setInfoOptions, buildLinkData } from './info';
import { setOptions as setStyleOptions } from './style';

export function pathgraph(data, options) {
  setInfoOptions(options);
  setStyleOptions(options);

  const structure = buildStructure(data, options);
  const { bounds, nodes, links } = addPositioning(structure, options);

  return {
    bounds,
    nodes,
    links: links.map((link) => ({ ...link, info: buildLinkData(link, nodes) }))
  };
}
