import React from 'react';
import { Box, Flex, Text } from 'core/components';
import { zeroToText } from 'app/util/utils';
import { metricListForMeshOptions } from 'app/views/synthetics/components/mesh/util/constants';

import PopoverDot from './PopoverDot';

const metricNameMap = Object.fromEntries(metricListForMeshOptions.map(({ value, label }) => [value, label]));

function formatMetricValue({ metric, value }) {
  if (metric === 'latency' || metric === 'jitter') {
    return `${zeroToText(value / 1000, { fix: 3 })}ms`;
  }

  if (metric === 'loss') {
    return `${zeroToText(value * 100, { fix: 3 })}%`;
  }

  return null;
}

const MeshMetrics = ({ metrics = {}, metricLabels = {}, isAggregated = false }) => {
  const labels = Object.assign({ ...metricNameMap }, metricLabels);
  return (
    <>
      {Object.keys(metrics).map((metric, index) => {
        const { value, health } = metrics[metric];
        if (value !== null && value !== undefined) {
          return (
            <Flex alignItems="center" key={metric} mb={index !== 2 ? '4px' : 0}>
              <PopoverDot health={health} value={value} isAggregated={isAggregated} />
              <Box>
                <Text fontWeight="bold">{labels[metric]}:</Text> <span>{formatMetricValue({ metric, value })}</span>
              </Box>
            </Flex>
          );
        }
        return null;
      })}
    </>
  );
};

export default MeshMetrics;
