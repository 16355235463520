import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import { FlexColumn } from 'core/components';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import { ViewInExplorerButton } from 'app/components/dataviews/tools';
import { lineClassNames } from './AlertChart';

@inject('$alerting', '$colors')
@observer
class EventAlertChart extends Component {
  static defaultProps = {
    showExplorerButton: true,
    showExtraChart: false,
    onDataViewCreate: () => {}
  };

  getViewProps() {
    const { alertModel } = this.props;

    const plotLines = [];
    const xPlotBands = [];
    const zones = [];

    const xLines = [
      {
        value: moment(alertModel.startTime),
        id: 'alert',
        className: lineClassNames.alarm,
        zIndex: 5,
        label: {
          text: 'Start',
          rotation: 0,
          align: 'right',
          y: 15,
          x: -5,
          className: `${lineClassNames.alarm} outlinedText`
        }
      }
    ];

    if (alertModel.endTime) {
      xLines.push({
        value: moment(alertModel.endTime),
        className: lineClassNames.cleared,
        id: 'cleared',
        zIndex: 5,
        label: {
          text: 'Clear',
          align: 'left',
          rotation: 0,
          y: 15,
          className: `${lineClassNames.cleared} outlinedText`
        }
      });
    }

    return {
      showNativeLegend: true,
      chartConfig: {
        plotOptions: { series: { zones } },
        xAxis: {
          plotLines: xLines
        }
      },
      plotLines,
      xPlotBands
    };
  }

  render() {
    const {
      $colors,
      $alerting,
      alertModel,
      query,
      viewProps,
      showExplorerButton,
      showSnmpChart,
      onDataViewCreate,
      ...props
    } = this.props;

    const alertQuery = query || $alerting.getEventAlertQuery(alertModel);

    if (!alertQuery) {
      return null;
    }

    return (
      <FlexColumn flex={1}>
        <FlexColumn flex={1} alignItems="flex-end">
          <DataViewWrapper
            allowCache
            {...props}
            query={alertQuery}
            onDataViewCreate={onDataViewCreate}
            viewProps={{
              colors: alertQuery.filterDimensionsEnabled ? $colors.getAllTabbedChartColors() : undefined,
              ...this.getViewProps(),
              ...viewProps
            }}
          />

          {showExplorerButton && <ViewInExplorerButton query={alertQuery} minimal small />}
        </FlexColumn>
      </FlexColumn>
    );
  }
}

export default EventAlertChart;
