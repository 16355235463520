import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled, { withTheme } from 'styled-components';
import { themeGet } from 'styled-system';

import { Callout, Card, Flex, Box, Button, Icon, Text } from 'core/components';
import { toCIDRNotation } from 'core/util/ip';
import ConnectivityTypeLink from 'app/components/links/ConnectivityTypeLink';
import DeviceLink from 'app/components/links/DeviceLink';
import InterfaceLink from 'app/components/links/InterfaceLink';
import IpLink from 'app/components/links/IpLink';
import NetworkBoundaryLink from 'app/components/links/NetworkBoundaryLink';
import ProviderLink from 'app/components/links/ProviderLink';
import SiteLink from 'app/components/links/SiteLink';
import formatMetricValue from 'app/util/formatMetricValue';

import FlowResultsTable from './FlowResultsTable';
import NodeFlowDetails from './NodeFlowDetails';

const StyledIcon = styled(Icon)`
  padding: 0 ${themeGet('space.1')}px;
`;

const InfoItem = ({ label, value }) => (
  <Box mt={2}>
    <Text as="div" color="muted" fontWeight="medium" fontSize="small" pb="4px">
      {label}
    </Text>
    <Box flex={1}>{value}</Box>
  </Box>
);

@inject('$topo')
@observer
class Info extends Component {
  static propTypes = {
    details: PropTypes.object,
    pinned: PropTypes.bool,
    onPinClick: PropTypes.func
  };

  static defaultProps = {
    details: {
      type: '',
      el: undefined,
      data: {},
      options: {}
    },
    pinned: false,
    onPinClick: () => {}
  };

  getNodeName = (id) => {
    const { $topo, details } = this.props;
    const { groups, nodes } = $topo;
    const { data } = details;

    if (data.isGroup && groups[id]) {
      return groups[id].name;
    }

    return nodes[id] ? nodes[id].name : '';
  };

  // open the flow details in Explorer
  handleViewFlows = (dir) => {
    const { $topo, details } = this.props;
    const { data } = details;

    $topo.navigateToExplorer(data.id, dir);
  };

  renderGroup = () => {
    const { details } = this.props;
    const { data } = details;

    return (
      data.description && (
        <Box mt={2}>
          <Text as="div" color="muted" fontWeight="medium" fontSize="small" pb="4px">
            Location
          </Text>
          <Box>{data.description}</Box>
        </Box>
      )
    );
  };

  renderNode = () => {
    const { details } = this.props;
    const { data } = details;

    if (data.level === 'site') {
      return this.renderGroup();
    }

    return (
      <>
        {data.description && <InfoItem label="Description" value={data.description} />}
        {data.type !== 'provider' && <InfoItem label="Type" value={data.deviceType || data.type} />}
        {data.group && <InfoItem label="Site" value={<SiteLink siteId={data.siteId}>{data.group}</SiteLink>} />}
      </>
    );
  };

  renderPoint = (point) => {
    const { value: formattedValue, metric: formattedMetric } = formatMetricValue(point.snmp_speed * 1000000, 'bits');

    return (
      <Card key={point.id} fontSize="small" mb={1} p={1}>
        <Box>
          <Text as="div">
            Device: <DeviceLink name={point.device_name} />
          </Text>

          <Text as="div">
            Interface: <InterfaceLink {...point} />
          </Text>

          <Text as="div">
            IP:{' '}
            <IpLink ip_address={point.interface_ip}>
              {toCIDRNotation(point.interface_ip, point.interface_ip_netmask)}
            </IpLink>
          </Text>

          <Text as="div">Capacity: {`${formattedValue} ${formattedMetric}`}</Text>

          <Text as="div">
            Boundary:{' '}
            {point.network_boundary ? (
              <NetworkBoundaryLink value={point.network_boundary} />
            ) : (
              <Text color="muted">(none)</Text>
            )}
          </Text>

          <Text as="div">
            Connectivity Type:{' '}
            {point.connectivity_type ? (
              <ConnectivityTypeLink value={point.connectivity_type} />
            ) : (
              <Text color="muted">(none)</Text>
            )}
          </Text>

          {point.provider && (
            <Text as="div">
              Provider: <ProviderLink provider={point.provider} />
            </Text>
          )}

          {point.vrf && <Text as="div">VRF: {point.vrf}</Text>}
        </Box>
      </Card>
    );
  };

  renderLink = () => {
    const { $topo, details } = this.props;
    const { data } = details;
    const { sourceConnectionPoints = [], targetConnectionPoints = [] } = data;
    const { value: snmpSpeed, highest, isAggregated } = $topo.getLinkSpeed(data.id);
    const { value: formattedValue, metric: formattedMetric } = formatMetricValue(snmpSpeed * 1000000, 'bits');

    return (
      <>
        <InfoItem
          label={`${isAggregated ? 'Aggregated ' : ''}Capacity (${highest})`}
          value={`${formattedValue} ${formattedMetric}`}
        />

        {Object.keys(sourceConnectionPoints).length > 0 && (
          <Box mt={2}>
            <Text as="div" color="muted" fontWeight="medium" fontSize="small" pb="4px">
              Source
            </Text>

            {Object.values(sourceConnectionPoints).map((points) => points.map(this.renderPoint))}
          </Box>
        )}

        {Object.keys(targetConnectionPoints).length > 0 && (
          <Box mt={2}>
            <Text as="div" color="muted" fontWeight="medium" fontSize="small" pb="4px">
              Destination
            </Text>
            {Object.values(targetConnectionPoints).map((points) => points.map(this.renderPoint))}
          </Box>
        )}

        <Box mt={2}>
          {this.renderFlows('out')}
          {this.renderFlows('in')}
        </Box>
      </>
    );
  };

  renderFlows = (dir) => {
    const { details } = this.props;
    const { data, options } = details;
    const { flowIn = [], flowOut = [] } = options;
    const flowData = dir === 'out' ? flowOut : flowIn;
    const totalFlow = flowData.reduce((total, item) => total + item.value, 0);

    if (flowData.length === 0) {
      return null;
    }

    return (
      <Box mt={3}>
        <Flex alignItems="center" color="muted" fontWeight="medium" fontSize="small" pb={1}>
          {this.getNodeName(data[dir === 'out' ? 'source' : 'target'])}
          <StyledIcon icon="arrow-right" />
          {this.getNodeName(data[dir === 'out' ? 'target' : 'source'])}
        </Flex>

        <Card>
          <FlowResultsTable flowData={flowData} totalFlow={totalFlow} />
        </Card>

        <Flex justifyContent="flex-end" mt="4px">
          <Button small minimal intent="primary" fontWeight="bold" onClick={() => this.handleViewFlows(dir)}>
            View Flows
          </Button>
        </Flex>
      </Box>
    );
  };

  renderHeading() {
    const { details } = this.props;
    const { options, data, type } = details;

    const contents = (
      <Text fontSize={18} fontWeight="bold" color={!data.deviceType ? options.color : undefined}>
        {type === 'node' || type === 'group' ? data.name : data.description}
      </Text>
    );

    if (data.type === 'site') {
      return <SiteLink siteId={data.id || data.siteId}>{contents}</SiteLink>;
    }

    if (data.deviceType) {
      return <DeviceLink name={data.name}>{contents}</DeviceLink>;
    }

    if (data.type === 'provider') {
      return data.isCtProvider || data.id.includes('-unspecified') ? (
        <ConnectivityTypeLink value={data.connectivity_type} label={contents} />
      ) : (
        <ProviderLink provider={data.name}>{contents}</ProviderLink>
      );
    }

    return contents;
  }

  render() {
    const { $topo, details } = this.props;
    const { data, type } = details;

    return (
      <Box p={2} overflow="auto">
        {!details.type && (
          <Callout>
            <Box textAlign="center">
              <Icon icon="select" iconSize={32} />
              <Text as="div" color="muted" fontSize="small" mt={1}>
                Select a node or link to view details
              </Text>
            </Box>
          </Callout>
        )}
        {details.type && (
          <>
            <Box mb={1}>
              {data && data.type && (
                <Text as="div" color="muted" fontWeight="medium" textTransform="capitalize" fontSize="small">
                  {data.type}
                </Text>
              )}
              {this.renderHeading()}
            </Box>
            <NodeFlowDetails details={details} getNodeFlowTotal={$topo.getNodeFlowTotal} />
            {type === 'group' && this.renderGroup()}
            {type === 'node' && this.renderNode()}
            {type === 'link' && this.renderLink()}
          </>
        )}
      </Box>
    );
  }
}

export default withTheme(Info);
