import { generateFilterFields, generateTimeFields } from './helpers';

const timeFields = generateTimeFields();

const fields = {
  ...timeFields,
  ...generateFilterFields(),

  flow_fields: {
    label: 'Dimensions',
    useFieldOptions: true,
    defaultValue: [
      'in_bytes',
      'in_pkts',
      'out_bytes',
      'out_pkts',
      'i_device_id',
      'Proto',
      'IP_src',
      'Port_src',
      'IP_dst',
      'Port_dst'
    ]
  },

  all_devices: {
    defaultValue: false,
    rules: 'atLeastOneIfNotAllDevices'
  },
  device_name: {
    defaultValue: []
  },
  device_labels: {
    defaultValue: []
  },
  device_sites: {
    defaultValue: []
  },
  device_types: {
    defaultValue: []
  },

  order_by: {
    label: 'Order By',
    useFieldOptions: true,
    defaultValue: 'ctimestamp'
  },

  row_count: {
    label: 'Row Count',
    defaultValue: 1000,
    rules: 'required|integer|min:1|max:15000'
  }
};

const options = {
  name: 'Raw Flow',
  showPristineErrors: false,
  groups: {
    options: ['flow_fields'],
    time: Object.keys(timeFields),
    filters: ['filters'],
    devices: ['device_name']
  }
};

export { fields, options };
