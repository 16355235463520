import React, { Component } from 'react';
import { observer } from 'mobx-react';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import ExpandableRow from 'app/components/admin/ExpandableRow';
import { greekIt } from 'app/util/utils';

@observer
export default class CapacityPlanRowItem extends Component {
  render() {
    const { model, warningThresholdBps, dangerThresholdBps, warningThreshold, criticalThreshold, getTrafficQuery } =
      this.props;

    return (
      <ExpandableRow rowExpansionHeight={250} p={2}>
        <DataViewWrapper
          allowCache={false}
          query={getTrafficQuery(model)}
          viewProps={{
            height: 250,
            chartConfig: {
              legend: {
                enabled: true,
                symbolWidth: 0.01,
                symbolHeight: 0.01,
                useHTML: true,
                labelFormatter: () =>
                  '<div style="position: absolute; top: -14px; left: -85px;">' +
                  '<div style="width: 8px;height: 8px;border-radius: 8px;background-color: rgb(238,126,15);display: inline-block;"></div>' +
                  `<span style="padding-left: 2px; padding-right: 10px">Warning (${warningThreshold}%)</span>` +
                  '<div style="width: 8px;height: 8px;border-radius: 8px;background-color: rgb(219,55,55);display: inline-block;"></div>' +
                  `<span style="padding-left: 2px;">Critical (${criticalThreshold}%)</span>` +
                  '</div>'
              },
              yAxis: [
                {
                  startOnTick: false,
                  endOnTick: false,
                  plotLines: [
                    {
                      id: 'average',
                      className: 'major-level',
                      value: dangerThresholdBps,
                      width: 2,
                      zIndex: 5
                    },
                    {
                      id: 'average',
                      className: 'minor2-level',
                      value: warningThresholdBps,
                      width: 2,
                      zIndex: 5
                    }
                  ],
                  tickPositioner: (min, max) => [min, 0, max],
                  labels: {
                    formatter() {
                      return `${this.value < 0 ? '-' : ''}${greekIt(Math.abs(this.value), { fix: 1 }).displayFull}`;
                    }
                  }
                },
                {
                  startOnTick: false,
                  endOnTick: false,
                  plotLines: [
                    {
                      id: 'average',
                      className: 'major-level',
                      value: -dangerThresholdBps,
                      width: 2,
                      zIndex: 5
                    },
                    {
                      id: 'average',
                      className: 'minor2-level',
                      value: -warningThresholdBps,
                      width: 2,
                      zIndex: 5
                    }
                  ],
                  tickPositioner: (min, max) => [min, 0, max],
                  labels: {
                    formatter() {
                      return `${this.value < 0 ? '-' : ''}${greekIt(Math.abs(this.value), { fix: 1 }).displayFull}`;
                    }
                  }
                }
              ],
              plotOptions: {
                series: {
                  events: {
                    legendItemClick: () => false
                  },
                  zoneAxis: 'y',
                  zones: [
                    {
                      value: -dangerThresholdBps,
                      className: 'zone-danger'
                    },
                    {
                      value: -warningThresholdBps,
                      className: 'zone-warning'
                    },
                    {
                      value: warningThresholdBps,
                      className: 'zone-green-clear'
                    },
                    {
                      value: dangerThresholdBps,
                      className: 'zone-warning'
                    },
                    { className: 'zone-danger' }
                  ]
                }
              }
            }
          }}
        />
      </ExpandableRow>
    );
  }
}
