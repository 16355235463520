import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Box, Button, Heading, Text } from 'core/components';
import MenuSection from 'app/components/navbar/MenuSection';

@inject('$auth', '$sudo')
@withRouter
@observer
class DemoMenuOption extends Component {
  handleDemoClick = () => {
    const { $sudo, location } = this.props;
    $sudo.launchDemoSession({ redirect: location.pathname });
  };

  render() {
    const { $auth } = this.props;
    const demoEnv = $auth.getEnvironmentSettings('demo');
    const textColor = 'white.8';

    if ($auth.isTrial) {
      return (
        <Box p="12px" mt="12px" width={255} bg={demoEnv.envColor} color={textColor} borderRadius={4}>
          <Heading level="6" color={textColor} fontWeight="heavy">
            Want a demo?
          </Heading>

          <Text small color="white.6">
            This environment will allow you to explore all the great features of Kentik.
          </Text>

          <Button
            p={0}
            mt={1}
            small
            minimal
            icon="cube"
            color="white.8"
            fontWeight="heavy"
            onClick={this.handleDemoClick}
          >
            <Text>Switch to the {demoEnv.envName} Environment</Text>
          </Button>
        </Box>
      );
    }

    return (
      <MenuSection
        title={`${demoEnv.envName} Environment`}
        icon="cube"
        to="#"
        onClick={this.handleDemoClick}
        mt="12px"
      />
    );
  }
}

export default DemoMenuOption;
