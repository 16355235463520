import { Flex } from 'core/components';
import React, { Component } from 'react';
import { ControlGroup, Field, FormDialog, FormGroup, InputGroup, PasswordInput, RadioGroup, Select } from 'core/form';
import { inject, observer } from 'mobx-react';
import LabelSelector from 'app/components/labels/LabelSelector';

const snmpCredentialFields = {
  credentialName: {
    label: 'Credential Name',
    rules: 'required|alpha_dash',
    transform: {
      out: (value) => value.trim()
    },
    messages: {
      not_in: 'Name must be unique'
    }
  },

  labels: {
    label: 'Labels',
    defaultValue: []
  },

  type: {
    label: 'Type',
    defaultValue: 'snmp_v2c',
    options: [
      { label: 'SNMP v1', value: 'snmp_v1' },
      { label: 'SNMP v2c', value: 'snmp_v2c' },
      { label: 'SNMP v3', value: 'snmp_v3' },
      { label: 'Streaming Telemetry', value: 'streaming_telemetry' }
    ]
  },

  community: {
    label: 'Community',
    rules: [{ required_if: ['version', 'v2'] }],
    messages: {
      required_if: ':attribute is required.'
    }
  },

  username: {
    label: 'User Name',
    rules: [{ required_if: ['version', 'v3'] }],
    messages: {
      required_if: ':attribute is required.'
    }
  },

  password: {
    label: 'Password',
    rules: [{ required_if: ['type', 'streaming_telemetry'] }],
    messages: {
      required_if: ':attribute is required.'
    }
  },

  'authentication.protocol': {
    label: 'Type',
    defaultValue: 'none',
    options: [
      { value: 'none', label: 'None' },
      { value: 'MD5', label: 'MD5' },
      { value: 'SHA', label: 'SHA' }
    ]
  },

  'authentication.password': {
    label: 'Passphrase'
  },

  'privacy.protocol': {
    label: 'Privacy Type',
    defaultValue: 'none',
    options: [
      { value: 'none', label: 'None' },
      { value: 'DES', label: 'DES' },
      { value: 'AES', label: 'AES' }
    ]
  },

  'privacy.password': {
    label: 'Privacy Passphrase'
  },

  port: {
    label: 'Port',
    defaultValue: 161
  }
};

@inject('$credentials')
@observer
class SetupCredentials extends Component {
  static defaultProps = {
    types: ['snmp_v1', 'snmp_v2c', 'snmp_v3', 'streaming_telemetry'],
    title: 'Add Credential',
    hideTypeSelector: false
  };

  get typeOptions() {
    const { types } = this.props;
    return snmpCredentialFields.type.options.filter(({ value }) => types.includes(value));
  }

  handleSubmit = (form) => {
    const { $credentials, model, onClose } = this.props;
    const formValues = form.getValues();
    const { type, credentialName, labels, community, username, password, authentication, privacy } = formValues;
    const tokens = [];

    if (type === 'snmp_v2c' || type === 'snmp_v1') {
      tokens.push({
        key: 'community',
        value: community
      });
    } else if (type === 'streaming_telemetry') {
      tokens.push({
        key: 'username',
        value: username
      });
      tokens.push({
        key: 'password',
        value: password
      });
    } else {
      tokens.push({
        key: 'username',
        value: username
      });

      tokens.push({
        key: 'authentication',
        value: authentication.password
      });

      tokens.push({
        key: 'privacy',
        value: privacy.password
      });

      tokens.push({
        key: 'authentication_protocol',
        value: authentication.protocol
      });

      tokens.push({
        key: 'privacy_protocol',
        value: privacy.protocol
      });
    }

    return model
      .save({
        name: credentialName,
        labels,
        type,
        tokens
      })
      .then((res) => {
        console.warn('Saved credential', res);
        // refresh the $credentials collection
        $credentials.groupedCollection.fetch({ force: true }).then(() => {
          onClose(formValues);
        });
      });
  };

  render() {
    const { onClose, isOpen, model, hideTypeSelector, title } = this.props;
    const { typeOptions } = this;
    const nameRules = `required|alpha_dash|not_in:${model.collection.map((cred) => cred.get('name'))}`;

    return (
      <FormDialog
        fields={snmpCredentialFields}
        options={{ name: 'snmp-credential', showPristineErrors: false }}
        model={model}
        isOpen={isOpen}
        canOutsideClickClose={false}
        entityName="Credential"
        onClose={onClose}
        width={600}
        title={title}
        formActionsProps={{
          onSubmit: this.handleSubmit,
          onCancel: onClose,
          closeAfterSave: false
        }}
      >
        {({ form }) => {
          const isSnmpV3 = form.getValue('type') === 'snmp_v3';
          const isStreamingTelemetry = form.getValue('type') === 'streaming_telemetry';

          return (
            <Flex flexDirection="column" gap={3}>
              {!hideTypeSelector && (
                <Field name="type" options={typeOptions} large m={0}>
                  <RadioGroup />
                </Field>
              )}

              <Field name="credentialName" large rules={nameRules} m={0}>
                <InputGroup width={400} />
              </Field>

              <LabelSelector fieldName="labels" width={400} m={0} />

              {isStreamingTelemetry && (
                <>
                  <Field name="username" large mb={0}>
                    <InputGroup width={400} />
                  </Field>

                  <Field name="password" large m={0}>
                    <PasswordInput width={400} />
                  </Field>
                </>
              )}

              {!isSnmpV3 && !isStreamingTelemetry && (
                <Field large name="community" mb={0}>
                  <PasswordInput width={400} />
                </Field>
              )}

              {isSnmpV3 && (
                <>
                  <Field large name="username" mb={0}>
                    <InputGroup width={400} />
                  </Field>

                  <FormGroup label="Authentication" fontSize="small" mb={0}>
                    <ControlGroup>
                      <Field large name="authentication.protocol" showLabel={false} m={0}>
                        <Select menuWidth={100} />
                      </Field>
                      <Field large name="authentication.password" showLabel={false} placeholder="Passphrase" flex={1}>
                        <PasswordInput leftIcon="lock" />
                      </Field>
                    </ControlGroup>
                  </FormGroup>

                  <FormGroup label="Privacy" mb={0}>
                    <ControlGroup>
                      <Field large name="privacy.protocol" showLabel={false} m={0}>
                        <Select menuWidth={100} />
                      </Field>

                      <Field large name="privacy.password" showLabel={false} placeholder="Passphrase" flex={1} m={0}>
                        <PasswordInput leftIcon="lock" />
                      </Field>
                    </ControlGroup>
                  </FormGroup>
                </>
              )}
            </Flex>
          );
        }}
      </FormDialog>
    );
  }
}

export default SetupCredentials;
