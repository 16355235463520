import React, { Component } from 'react';
import PropTypes from 'prop-types';

import storeLoader from 'app/stores/storeLoader';
import Field from 'core/form/components/Field';
import InputGroup from 'core/form/components/InputGroup';
import InputMultiple from 'core/form/components/InputMultiple';
import Switch from 'core/form/components/Switch';
import Select from 'core/form/components/select/Select';
import TextArea from 'core/form/components/TextArea';
import ToggleButtonGroup from 'core/form/components/ToggleButtonGroup';
import formConsumer from 'core/form/formConsumer';
import Box from 'core/components/Box';
import Flex from 'core/components/Flex';
import Icon from 'core/components/Icon';
import Image from 'core/components/Image';
import Link from 'core/components/Link';
import Spinner from 'core/components/Spinner';
import Tag from 'core/components/Tag';
import Text from 'core/components/Text';
import Heading from 'core/components/Heading';

import { fields } from 'app/forms/config/notification';
import { Checkbox } from 'core/form';
import channelTypes from './channelTypes';

@storeLoader('$notifications.supportedChannels')
@formConsumer
export default class Settings extends Component {
  static propTypes = {
    model: PropTypes.object.isRequired,
    filter: PropTypes.array
  };

  static defaultProps = {
    filter: []
  };

  componentDidMount() {
    const { form } = this.props;

    this.handleTypeChange(undefined, this.selectedType);

    this.setState({
      url: form.getValue('config.sendingConfig.url'),
      requestTlsVerify: form.getValue('config.sendingConfig.requestTlsVerify'),
      oauth2TlsVerify: form.getValue('config.sendingConfig.oauth2TlsVerify'),
      oauth2AccessTokenURL: form.getValue('config.sendingConfig.oauth2AccessTokenURL')
    });
  }

  state = {
    selectedType: undefined,
    sendDaily: undefined
  };

  get sendDaily() {
    const { sendDaily } = this.state;
    const { form } = this.props;
    const insightsDigestField = form.getField('config.renderingConfig.insightsDigest');

    return sendDaily === undefined ? !!insightsDigestField.value : sendDaily;
  }

  get selectedType() {
    const { model } = this.props;
    const { selectedType } = this.state;

    return selectedType || model.get('channelType');
  }

  get selectedChannel() {
    const { $notifications } = this.props;

    return $notifications.supportedChannels.models.find((model) => model.get('channelType') === this.selectedType);
  }

  get channelOptions() {
    const { $notifications, filter } = this.props;
    let channels = $notifications.supportedChannels.get();

    if (filter.length > 0) {
      channels = channels.filter((channel) => filter.includes(channel.get('channelType')));
    }

    if (this.readOnly) {
      channels = channels.filter((channel) => channel.get('channelType') === this.selectedType);
    }

    return channels
      .map((channel) => channel.get())
      .sort(({ channelDisplayName }, { channelDisplayName: otherDisplayName }) =>
        (channelDisplayName || '').localeCompare(otherDisplayName)
      )
      .map((channel) => {
        const { channelType, channelDisplayName } = channel;
        const integration = channelTypes[channelType]
          ? {
              ...channelTypes[channelType],
              type: channelType,
              name: channelDisplayName
            }
          : { name: channelDisplayName, type: channelType };

        return this.renderIntegration(integration);
      });
  }

  get readOnly() {
    const { model } = this.props;
    const { config = {} } = model.get();
    return !!config.userChannelUserID;
  }

  handleValueChange = (field, value) => {
    const { form } = this.props;
    // we track url changes, to update, if necessary, OAuth 2.0 fields
    // we're essentially making this component re-render, because otherwise it wouldn't
    if (field.name === 'config.sendingConfig.url') {
      this.setState({ url: value });
    } else if (field.name === 'config.sendingConfig.oauth2AccessTokenURL') {
      this.setState({ oauth2AccessTokenURL: value });
    } else if (field.name === 'config.sendingConfig.requestTlsVerify') {
      this.setState({ requestTlsVerify: value });

      // clear out corresponding PEM
      if (!value) {
        form.setValue('config.sendingConfig.requestTlsPem', '');
      }
    } else if (field.name === 'config.sendingConfig.oauth2TlsVerify') {
      this.setState({ oauth2TlsVerify: value });

      // clear out corresponding PEM
      if (!value) {
        form.setValue('config.sendingConfig.oauth2TlsPem', '');
      }
    }
  };

  handleTypeChange = (field, newValue) => {
    const { form } = this.props;
    const usernamesField = form.getField('config.sendingConfig.usernames');
    const customHeadersField = form.getField('config.sendingConfig.customHeaders');
    const urlField = form.getField('config.sendingConfig.url');

    if (['custom-webhook', 'json', 'msteams', 'slack', 'splunk', 'victorops', 'xmatters'].includes(newValue)) {
      urlField.setRules('required|url');
    } else {
      urlField.setRules('');
    }

    if (newValue === 'email') {
      usernamesField.setRules('required|array|min:1');
      usernamesField.setChildren({
        rules: 'required|email'
      });

      if (usernamesField.fieldStates.length < 1) {
        usernamesField.add(undefined);
      }
    } else {
      usernamesField.setValue([]);
      usernamesField.setRules('');
    }

    if (newValue === 'custom-webhook') {
      if (customHeadersField.fieldStates.length < 1) {
        customHeadersField.add('');
      }
    } else {
      customHeadersField.clearValue();
    }

    this.setState({
      selectedType: newValue
    });
  };

  handleSendDailyChange = (field, newValue) => {
    this.setState({
      sendDaily: newValue
    });
  };

  renderIntegration = ({ name, type, logo, icon, image, text, height, showName, beta }) => {
    const imgHeight = height || 32;
    const logoProps = {
      height: imgHeight,
      width: '100%',
      style: { width: 'auto' }
    };

    return {
      value: type,
      label: (
        <Flex
          key={type}
          width={84}
          height={64}
          m={1}
          flexDirection={showName ? 'column' : 'row'}
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          {icon && <Icon icon={icon} {...logoProps} color="muted" iconSize={imgHeight} />}
          {logo && logo}
          {image && <Image src={image} {...logoProps} />}
          {text && (
            <Text as="div" mb="4px" fontSize={24} fontWeight="bold" muted>
              {text}
            </Text>
          )}
          {!logo && !icon && !image && !text && (
            <Text large fontWeight="bold">
              {name}
            </Text>
          )}

          {showName && (
            <Text small fontWeight="bold" pt="4px">
              {name}
            </Text>
          )}

          {beta && (
            <Tag small minimal intent="warning" style={{ position: 'absolute', top: '-7px', right: '-7px' }}>
              <Text small fontSize="10px" fontWeight="bold">
                Beta
              </Text>
            </Tag>
          )}
        </Flex>
      )
    };
  };

  renderOAuthFields = () => {
    const { $notifications } = this.props;
    const { oauth2AccessTokenURL, oauth2TlsVerify } = this.state;

    if ($notifications.channelTypeSupportOAuth(this.selectedType)) {
      const showTlsVerify = (oauth2AccessTokenURL || '').startsWith('https://');
      const showOauthTlsPem = showTlsVerify && oauth2TlsVerify;

      return (
        <Box>
          <Heading level={4} mb={1} mt={2}>
            OAuth 2.0
          </Heading>
          <Field name="config.sendingConfig.oauth2AccessTokenURL" labelInfo="" onChange={this.handleValueChange}>
            <InputGroup />
          </Field>
          {showTlsVerify && (
            <Field
              name="config.sendingConfig.oauth2TlsVerify"
              labelInfo=""
              showLabel={false}
              onChange={this.handleValueChange}
            >
              <Checkbox />
            </Field>
          )}
          {showOauthTlsPem && (
            <Field name="config.sendingConfig.oauth2TlsPem" labelInfo="">
              <TextArea width="100%" autoGrow style={{ maxHeight: '300px' }} />
            </Field>
          )}
          <Field name="config.sendingConfig.oauth2ClientId" labelInfo="">
            <InputGroup />
          </Field>
          <Field name="config.sendingConfig.oauth2ClientSecret" labelInfo="">
            <InputGroup />
          </Field>
          <Field name="config.sendingConfig.oauth2Scopes">
            <InputGroup />
          </Field>
        </Box>
      );
    }

    return null;
  };

  renderBaseFields = () => {
    const requiredFields = this.selectedChannel.get('requiredFields') || [];
    const urlField = requiredFields.find((field) => field.path === 'sendingConfig.url');
    const isCustomWebhook = this.selectedType === 'custom-webhook';

    if (requiredFields.length > 0 && urlField && isCustomWebhook) {
      const { url, requestTlsVerify } = this.state;
      const name = 'config.sendingConfig.url';
      const formConfig = fields[name];
      const helpText = formConfig?.helpText || urlField.description;
      const showTlsVerify = (url || '').startsWith('https://');
      const showRequestTlsPem = showTlsVerify && requestTlsVerify;

      return (
        <>
          <Field
            key={`${urlField.path}-${helpText}`}
            flex={1}
            mr="20px"
            name={name}
            helpText={helpText}
            disabled={this.readOnly}
            onChange={this.handleValueChange}
          >
            <InputGroup type={formConfig.type || 'text'} />
          </Field>
          {showTlsVerify && (
            <Field
              name="config.sendingConfig.requestTlsVerify"
              labelInfo=""
              showLabel={false}
              onChange={this.handleValueChange}
            >
              <Checkbox />
            </Field>
          )}
          {showRequestTlsPem && (
            <Field name="config.sendingConfig.requestTlsPem" labelInfo="">
              <TextArea width="100%" autoGrow style={{ maxHeight: '300px' }} />
            </Field>
          )}
        </>
      );
    }

    return null;
  };

  renderRequiredFields = () => {
    const requiredFields = this.selectedChannel.get('requiredFields') || [];

    return requiredFields
      .filter(
        (field) =>
          // we don't want to show the URL field here for custom webhook, we handle that separately and there's additional logic related to it
          field.path !== 'sendingConfig.url' || this.selectedType !== 'custom-webhook'
      )
      .map((field) => {
        const name = `config.${field.path}`;
        const formConfig = fields[name] || fields[`${name}[]`];
        const useSlider = formConfig && (formConfig.defaultValue === true || formConfig.defaultValue === false);
        const useSelect = formConfig?.options?.length > 0;
        let helpText = formConfig?.helpText || field.description;
        let FieldComponent = <InputGroup type={formConfig && formConfig.type ? formConfig.type : 'text'} />;

        if (!formConfig) {
          return null;
        }

        if (useSlider || helpText === formConfig.label) {
          helpText = '';
        }

        if (useSlider) {
          FieldComponent = <Switch pt={1} />;
        }

        if (useSelect) {
          FieldComponent = <Select pt={1} />;
        }

        if (name === 'config.renderingConfig.customTemplate') {
          FieldComponent = <TextArea rows={3} fill autoGrow />;
          helpText = (
            <>
              Get help with Go templates{' '}
              <Link blank to="https://blog.gopheracademy.com/advent-2017/using-go-templates/">
                here
              </Link>
            </>
          );
        }

        if (formConfig.children) {
          FieldComponent = (
            <InputMultiple containerProps={{ width: '100%' }} addButtonText="Add" disabled={this.readOnly} />
          );
        }

        return (
          <Field
            key={`${field.path}-${helpText}`}
            flex={1}
            mr="20px"
            name={name}
            helpText={helpText}
            disabled={this.readOnly}
            onChange={this.handleValueChange}
          >
            {FieldComponent}
          </Field>
        );
      });
  };

  render() {
    const { loading } = this.props;

    if (loading) {
      return <Spinner />;
    }

    return (
      <Box>
        <Flex mb={1}>
          <Box flex={1}>
            <Field name="name" autoFocus disabled={this.readOnly}>
              <InputGroup />
            </Field>
          </Box>

          <Field name="enabled" width={115} ml={2} onChange={this.handleEnabledChange}>
            <Switch switchLabel="Enabled" pt={1} />
          </Field>
        </Flex>

        <Flex flex={1} mt={1} mb={2}>
          <Field
            name="channelType"
            onChange={this.handleTypeChange}
            options={this.channelOptions}
            disabled={this.readOnly}
          >
            <ToggleButtonGroup
              containerProps={{ groupProps: { wrap: 'wrap' } }}
              buttonProps={{ mt: '4px', ml: '4px' }}
            />
          </Field>
        </Flex>

        {this.renderBaseFields()}
        {this.renderRequiredFields()}
        {this.renderOAuthFields()}

        {/* this is currently hidden - need to figure out what the exact approach should be - v4 needs parity work */}
        {/* <Field name="reminder_interval"> */}
        {/*  <Select /> */}
        {/* </Field> */}
      </Box>
    );
  }
}
