/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import { Box, Text, Dialog, Heading } from 'core/components';

class QueryAssistantTipsDialog extends Component {
  render() {
    const { onClose, isOpen } = this.props;

    return (
      <Dialog
        title="Tips for building good queries"
        isOpen={isOpen}
        onClose={onClose}
        width={825}
        height={750}
        canEscapeKeyClose
      >
        <Dialog.Body p={4} pb={0} style={{ lineHeight: '22px' }}>
          <Text as="p" mb={1}>
            By incorporating these tips, you can create queries that effectively communicate your data visualization
            needs, allowing Kentik AI to translate them into the most relevant and insightful results.
          </Text>

          <Heading level={4} mb="4px">
            Product surface
          </Heading>

          <Text as="p">At this moment, Journeys AI support queries related to:</Text>

          <Box as="ul" pl={3}>
            <Text as="li">Flow traffic collected and visible in Data Explorer (only flows are supported)</Text>
            <Text as="li">Metrics collected by NMS and visible in Metrics Explorer</Text>
          </Box>

          <Heading level={4} mb="4px">
            Be Specific
          </Heading>

          <Text as="p">
            Clearly define what you're looking for, including specific metrics, dimensions, filters, time ranges and
            visualizations. Only the metric is mandatory to provide, use other elements as necessary.
          </Text>

          <Heading level={4} mb="4px">
            Metrics and Dimensions
          </Heading>

          <Text as="p">
            To query Data Explorer flows, always use the word “traffic”. To query Metrics Explorer data use the name of
            the metric you want to visualize.
          </Text>

          <Text as="p">
            To express dimensions that should be included in the query, use keywords “group by” before stating which
            dimensions you want to include
          </Text>

          <Box as="ul" pl={3}>
            <Text as="li">'show traffic grouped by application and destination AS number'</Text>
            <Text as="li">'show CPU utilization grouped by device name'</Text>
          </Box>

          <Heading level={4} mb="4px">
            Filters
          </Heading>

          <Text as="p">
            You can specify additional filters for your query. DE filters can be based on dimensions, while ME filters
            can be based on measurement dimensions and/or metrics results.
          </Text>

          <Box as="ul" pl={3}>
            <Text as="li">'...for interfaces with descriptions containing 'PEERING''</Text>
            <Text as="li">'...for device 'jnx-nyc-01' where peer ASN is 65001'</Text>
            <Text as="li">'...where CPU usage is more than 70%',</Text>
            <Text as="li">'...with input utilization exceeding 80%, and errors greater than 0'</Text>
          </Box>

          <Heading level={4} mb="4px">
            Time Range Specification
          </Heading>
          <Text as="p">Clearly define the time period of interest to ensure relevant data is presented</Text>

          <Box as="ul" pl={3}>
            <Text as="li" fontStyle="italic">
              'In the last hour/day/week'
            </Text>
            <Text as="li">'Since 1 hour/day/week ago'</Text>
            <Text as="li">'From 11-25 till 11-26'</Text>
          </Box>

          <Heading level={4} mb="4px">
            Visualizations
          </Heading>
          <Text as="p">You can specify the visualization that you would like to get, e.g.</Text>
          <Box as="ul" pl={3}>
            <Text as="li">in a table</Text>
            <Text as="li">as a bar chart</Text>
            <Text as="li">on a line chart</Text>
          </Box>

          <Heading level={4} mb="4px">
            SQL Semantics (Optional)
          </Heading>

          <Text as="p">
            If you&apos;re familiar with SQL, you can experiment with SQL semantics in a relatively free form. For
            example:
          </Text>
          <Box as="ul" pl={3}>
            <Text as="li">
              select cpu utilization where device name contains jnx grouped by device name and IP address in the last
              day
            </Text>
          </Box>

          <Heading level={4} mb="4px">
            Use Contextual Keywords
          </Heading>

          <Text as="p">
            If the SQL style is too complex, or you are not familiar with the exact metrics and dimensions available in
            Kentik, try to incorporate key terms that are commonly understood, for example: TCP, UDP, AS number, IP
            address, IP route, CDN, cloud, AWS, GCP, etc.
          </Text>

          <Box as="ul" pl={3}>
            <Text as="li">'show udp traffic with destination port 53'</Text>
            <Text as="li">'show inbound traffic from AS Google'</Text>
          </Box>

          <Heading level={4} mb="4px">
            Additional Tips
          </Heading>

          <Text as="p">
            Start with a general overview and progressively add details with follow-up prompts to drill down into the
            data. Review the response if it did not returned the expected results, and try to refine your query based on
            the insights gained. Use thumbs up/down buttons to provide us feedback about successful or unsuccessful
            queries, as it will help us to make your AI Journeys even better.
          </Text>
        </Dialog.Body>
      </Dialog>
    );
  }
}

export default QueryAssistantTipsDialog;
