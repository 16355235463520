import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Flex, Icon, Link, Tag, Text, Tooltip } from 'core/components';
import { METRICS_STATUS_LABEL_MAP } from 'app/stores/metrics/metricsConstants';
import { percentToText } from 'app/util/utils';

export const StatusTag = styled(Tag)`
  // we want to override the default .bp4-fill behavior, so the text inside these Tags is centered
  &.bp4-tag > .bp4-fill {
    flex-grow: 0;
  }
`;

export const DeviceStatusAndNameIndicator = observer(({ value, model }) => (
  <Link to={`/v4/nms/devices/${model.id}`}>
    <Flex alignItems="center" gap="4px">
      {model.isICMPOnly && (
        <Tooltip content="ICMP only" position="bottom">
          <Icon icon="pulse" iconSize={12} color="muted" />
        </Tooltip>
      )}
      <Text fontWeight="bold" ellipsis>
        {value}
      </Text>
    </Flex>
  </Link>
));

const statusIntentMap = {
  up: 'success',
  down: 'danger',
  unobservable: 'warning',
  possibly_down: 'warning',
  unknown: 'none'
};

export const DeviceStatusTag = observer(({ model, value, percentage, minWidth, style }) => {
  let status;
  const tagProps = { minimal: true, fontWeight: 'medium', minWidth, style };
  const displayPercent = typeof percentage === 'number' ? percentToText(percentage * 100) : null;

  if (value) {
    status = value.toLowerCase();
  } else if (model?.get('status')) {
    status = model.get('status');
  } else {
    status = 'Unknown';
  }

  const statusDisplay = METRICS_STATUS_LABEL_MAP[status] || status;
  const intent = statusIntentMap[status] || 'none';

  return (
    <StatusTag minimal intent={intent} {...tagProps}>
      {statusDisplay}
      {displayPercent && ` (${displayPercent})`}
    </StatusTag>
  );
});

export const ComponentOperStatusTag = observer(({ value, small = false }) => {
  if (value === 'Unknown') {
    return null;
  }

  if (value === 'Up') {
    return (
      <Tag minimal small={small} intent="success">
        {value}
      </Tag>
    );
  }

  if (value === 'Disabled') {
    return (
      <Tag minimal small={small} intent="none">
        {value}
      </Tag>
    );
  }

  return (
    <Tag minimal small={small} intent="danger">
      {value}
    </Tag>
  );
});

export const AdjacencyStateRenderer = observer(({ value, model, ...rest }) => {
  const adjacencyState = value !== null && value !== undefined ? value : model.get('adjacency-state') || 'Unknown';
  const props = { intent: 'success', fontWeight: 'medium', minimal: true };

  if (['Down', 'Failed', 'Initializing'].includes(adjacencyState)) {
    props.intent = 'danger';
    props.fontWeight = 'normal';
  }

  return (
    <Tag {...props} {...rest}>
      {adjacencyState}
    </Tag>
  );
});

export const SessionStateRenderer = observer(
  ({ value, model, percentage, availableStates, showRawValue = false, ...rest }) => {
    //  handle 0 as a truthy value:
    const sessionState = value !== null && value !== undefined ? value : model?.get('session-state') || 'Unknown';
    const props = { intent: 'success', fontWeight: 'medium', minimal: true };
    const displayPercent = typeof percentage === 'number' ? percentToText(percentage * 100) : null;

    if (
      (sessionState >= 1 && sessionState <= 5) ||
      (typeof sessionState === 'string' && sessionState !== 'Established')
    ) {
      props.intent = 'danger';
      props.fontWeight = 'normal';
    }

    const display = (availableStates ? availableStates[value] || value : value) || 'Unknown';

    return (
      <Tag {...props} {...rest}>
        {display}
        {displayPercent && ` (${displayPercent})`}
        {showRawValue && ` (${value})`}
      </Tag>
    );
  }
);

export const SessionTypeRenderer = observer(({ value }) => <Tag minimal={value === 'iBGP'}>{value}</Tag>);

export const InterfaceOperAdminStatusTag = observer(({ value = 'Unknown', percentage, small = false }) => {
  const label = METRICS_STATUS_LABEL_MAP[value] || value;
  const displayPercent = typeof percentage === 'number' ? percentToText(percentage * 100) : null;

  let tagProps;
  if (value === 'up') {
    tagProps = { intent: 'success', fontWeight: 'medium' };
  } else if (value === 'testing') {
    tagProps = {};
  } else if (value === 'down') {
    tagProps = { intent: 'danger', fontWeight: 'medium' };
  } else {
    // these statuses are less important, so we don't bold them, make them less visible
    tagProps = { style: { opacity: 0.5 } };
  }

  return (
    <Tag minimal small={small} {...tagProps}>
      {label}
      {displayPercent && ` (${displayPercent})`}
    </Tag>
  );
});
