import api from './api';

let loadedVersion;

export default function getPortalVersion() {
  return api
    .get('/api/ui/hc-full', { showErrorToast: false })
    .then((response) => ({
      uiapp: {
        rev: response.rev.uiapp,
        commit: response.versionControl.uiapp,
        release: response.release
      }
    }))
    .then((latestVersion) => {
      // Only set loadedVersion the first time. Otherwise getPortalVersion() is not idempotent.
      if (loadedVersion === undefined) {
        loadedVersion = latestVersion;
      }

      return { latestVersion, loadedVersion, hasOutdatedBundle: loadedVersion.uiapp.rev !== latestVersion.uiapp.rev };
    });
}
