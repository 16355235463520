import React, { Component } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { Box, Button, Menu, MenuDivider, MenuItem, Popover } from 'core/components';

class WidgetMenu extends Component {
  render() {
    const { onRemove, menuOptions, canCustomize, configAction } = this.props;
    return (
      <Box className="config-menu">
        <Popover
          position="bottom-right"
          content={
            <Menu>
              {menuOptions}
              {canCustomize && (
                <>
                  {!!menuOptions && <MenuDivider />}
                  {configAction && <MenuItem icon="cog" text="Configure" onClick={configAction} />}
                  <MenuItem icon="trash" iconColor="danger" text="Remove" onClick={onRemove} />
                </>
              )}
            </Menu>
          }
        >
          <Button
            icon={FiMoreVertical}
            minimal
            onMouseDown={(e) => e.stopPropagation()}
            style={{
              padding: 0,
              width: 42,
              height: 38,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            }}
          />
        </Popover>
      </Box>
    );
  }
}

export default WidgetMenu;
