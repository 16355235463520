import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Box, Flex, Text } from 'core/components';
import LargeLabeledValue from 'app/components/LargeLabeledValue';
import NmsDeviceLink from 'app/components/links/NmsDeviceLink';
import NmsInterfaceLink from 'app/components/links/NmsInterfaceLink';
import { getNmsAlertDimensionDisplay } from '../util/nms/nmsNativeAlertDimensionDisplay';
import NmsNativeAlertMetricValue from './NmsNativeAlertMetricValue';
import { getEntityData } from '../util/nms/nmsAlertEntity';

const LabelSection = ({ title, labels }) => {
  if (!labels?.length) {
    return null;
  }
  return (
    <Flex flexDirection="column" gap={1}>
      <Box borderBottom="thin">
        <Text small muted>
          {title}
        </Text>
      </Box>
      <Flex flexWrap="wrap" gap={2}>
        {labels.map(({ label, value }) => (
          <LargeLabeledValue
            label={label}
            value={value}
            shouldFormatValue={false}
            valueSize={20}
            key={`${label}-${value}`}
          />
        ))}
      </Flex>
    </Flex>
  );
};

/**
 * This component displays summary information about an NMS alert:
 * - A list of metrics for the triggered policy level and their values at time of alert
 * - If the alert policy's target is defined with an entity type: shows entity details (device, interface)
 * - If not, show a list of dimensions and their values
 * */
@inject('$alerting', '$devices', '$metrics')
@observer
export default class NmsNativeAlertDetailsSummaryLabels extends Component {
  static propTypes = {
    // This must be an AlertModel instance
    alertModel: PropTypes.object.isRequired
  };

  renderMetricsForMeasurement(measurement, metrics) {
    const { $metrics, alertModel } = this.props;
    const measurementModel = $metrics.measurementModel(measurement);

    if (!measurementModel) {
      return null;
    }

    const metricsList = Object.entries(metrics || {}).map(([metric, value]) => {
      const metricLabel = measurementModel.metricLabel(metric) || metric;
      const displayValue = (
        <NmsNativeAlertMetricValue
          metric={metric}
          value={value}
          measurement={measurement}
          measurementModel={measurementModel}
          alertModel={alertModel}
          display="inline-flex"
          key={`${measurement}-${metric}`}
        />
      );

      return {
        label: metricLabel,
        value: displayValue
      };
    });

    const sectionTitle = `Metrics: ${measurement}`;

    return <LabelSection title={sectionTitle} labels={metricsList} />;
  }

  renderMetrics() {
    const { alertModel } = this.props;

    if (!alertModel.nmsAlarmContext?.metrics) {
      return null;
    }

    const {
      nmsAlarmContext: { metrics }
    } = alertModel;

    return (
      Object.entries(metrics || {})?.map(([measurement, measurementMetrics]) =>
        this.renderMetricsForMeasurement(measurement, measurementMetrics)
      ) || null
    );
  }

  renderDimensions() {
    const { $alerting, $devices, $metrics, alertModel } = this.props;
    const {
      nmsAlarmContext: { groupKey }
    } = alertModel;

    const dimensions = Object.entries(groupKey || {})
      ?.map(([dimension, value]) => {
        const dimensionDisplay = getNmsAlertDimensionDisplay({
          $alerting,
          $devices,
          $metrics,
          dimension,
          value,
          alertModel
        });

        if (!dimensionDisplay) {
          return null;
        }

        return dimensionDisplay;
      })
      .filter(Boolean);

    if (!dimensions?.length) {
      return null;
    }

    return <LabelSection title="Dimensions" labels={dimensions} />;
  }

  renderEntity(entityData) {
    const entities = [];

    if (!entityData) {
      return null;
    }

    const {
      device,
      interface: interfaceEntity,
      bgp,
      hasBgpData,
      hasBgpDisplayData,
      component,
      agent,
      hasAgentData
    } = entityData;

    if (device) {
      const { id, name } = device;
      entities.push({
        label: 'Device',
        value: <NmsDeviceLink id={id}>{name || id}</NmsDeviceLink>
      });
    }

    if (interfaceEntity) {
      const { id, deviceId, name, description } = interfaceEntity;
      const displayName = name || id;
      const linkText = description ? `${displayName} (${description})` : displayName;
      entities.push({
        label: 'Interface',
        value: (
          <NmsInterfaceLink deviceId={deviceId} snmpId={id}>
            {linkText}
          </NmsInterfaceLink>
        )
      });
    }

    if (hasBgpData) {
      if (hasBgpDisplayData) {
        if (bgp?.peerAs) {
          entities.push({
            label: 'Peer AS',
            value: bgp.peerAs
          });
        }
        if (bgp?.remoteIp) {
          entities.push({
            label: 'Remote IP',
            value: bgp.remoteIp
          });
        }
      } else {
        // Here we have bgp data but not the desired display values so we fall back to showing index (not super helpful to the user)
        // TODO: replace this with a link to the filtered BGP table https://github.com/kentik/recon/issues/1534
        entities.push({
          label: 'Index',
          value: bgp?.index
        });
      }
    }

    const componentEntity = component?.name || component?.index;
    if (componentEntity) {
      entities.push({
        label: 'Component',
        value: componentEntity
      });
    }

    if (hasAgentData) {
      entities.push({
        label: 'Agent',
        value: agent.name || agent.id
      });
    }

    if (!entities.length) {
      return null;
    }

    return <LabelSection title={`Target ${entityData.typeLabel}`} labels={entities} />;
  }

  render() {
    const { alertModel } = this.props;

    if (!alertModel || !alertModel.isNmsPolicy || !alertModel.nmsAlarmContext) {
      return null;
    }

    const entityData = getEntityData(alertModel);

    return (
      <>
        {entityData ? this.renderEntity(entityData) : this.renderDimensions()}
        {this.renderMetrics()}
      </>
    );
  }
}
