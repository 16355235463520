import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('InetFamilyAggregate'))
class InetFamilyAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="INET Families"
        resultsKey="Inet Family"
        insightsQuery="fetchInetFamilyInsights"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'Inet Family',
          metric: ['inet_family']
        }}
        chartProps={{
          metrics: {
            Internal: 'inet_family',
            Inbound: 'inet_family',
            Outbound: 'inet_family'
          }
        }}
      />
    );
  }
}

export default InetFamilyAggregate;
