import { notesFields } from './notesFields';

const baseBgpFields = {
  ...notesFields,

  'config.bgp.prefix': {
    rules: [
      'basicBGPRequirementValidator',
      'commaSeparatedCIDR',
      'bgpPrefixMaxValidator',
      'bgpPrefixMaxAddressSpaceValidator'
    ],
    placeholder: 'Enter up to ten comma separated prefixes, e.g. 193.177.128.0/22, 141.193.36.0/22, 2620:129::/44'
  },

  'config.bgp.origin': {
    rules: ['BGPOriginRequirementValidator', 'asnArrayValidator'],
    placeholder: 'Enter one or more comma separated Autonomous System Numbers, e.g. 6169, 207063',
    helpText: 'ASNs that are expected to originate these prefixes',
    showPristineErrors: true
  },

  'config.bgp.include_covered': {
    defaultValue: true
  },

  'config.bgp.check_rpki': {
    defaultValue: true,
    helpText:
      'Turn this on to get alerted on invalid ROAs. Alerts will not be generated for ASNs listed in the ‘Origin Hijack Detection’ field.'
  },

  'config.bgp.upstream': {
    rules: ['BGPUpstreamRequirementValidator', 'asnArrayValidator'],
    placeholder: 'Enter one or more comma separated Autonomous System Numbers, e.g. 6169, 207063',
    helpText: 'ASNs that are expected to be upstream of originating ASNs for these prefixes',
    showPristineErrors: true
  },

  'config.healthSettings.disableAlerts': {
    defaultValue: false,
    label: 'Disable Alerts',
    rules: ['boolean']
  },

  'config.healthSettings.reachabilityWarning': {
    defaultValue: 80,
    rules: ['numeric', 'min:0'],
    transform: {
      out: (value) => parseInt(value)
    }
  },

  'config.healthSettings.reachabilityCritical': {
    defaultValue: 50,
    rules: ['numeric', 'min:0'],
    transform: {
      out: (value) => parseInt(value)
    }
  }
};

export { baseBgpFields };
