import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Form } from 'core/form';
import { itemExplorerFields } from 'app/forms/config/dashboardItemDetails';
import MetricsExplorerSidebar from 'app/views/metrics/MetricsExplorerSidebar';
import {
  metricsExplorerFormFields,
  metricsExplorerFormOptions
} from 'app/views/metrics/utils/metricsExplorerFormConfig';

@inject('$auth')
@Form({ fields: { ...metricsExplorerFormFields, ...itemExplorerFields }, options: metricsExplorerFormOptions })
@observer
class MetricsItemExplorerOptions extends Component {
  componentDidMount() {
    const { $auth, form, model, formValues } = this.props;

    if (formValues) {
      form.init({ time_format: $auth.userTimezone, ...formValues, time_locked: model.get('time_locked') });
    }
  }

  handleControlledByViewChange = (field, value) => {
    const { form, dashboard, dashboardForm } = this.props;

    if (field.name === 'time_locked') {
      if (value === true) {
        form.setValues({
          lookback_seconds: dashboard.get('query').lookback_seconds
        });
      }

      dashboardForm.setValue('time_locked', field.getValue());
    }
  };

  render() {
    const { onQueryChange } = this.props;

    return (
      <MetricsExplorerSidebar
        integratedMode
        onChange={onQueryChange}
        onControlledByViewChange={this.handleControlledByViewChange}
        width={500}
      />
    );
  }
}

export default MetricsItemExplorerOptions;
