import React from 'react';
import { css } from 'styled-components';

export const animatedArrowStyles = css`
  .arrow-icon {
    overflow: visible;
    margin-left: 6px;
    width: 6px;
    height: 9px;
    color: ${({ theme, color }) => color || theme.colors.muted};
  }

  .arrow-head {
    transform: translateX(0);
    transition: transform 150ms ease-in-out;
  }

  .arrow-body {
    opacity: 0;
    transform: scaleX(1);
    transition:
      transform 150ms ease-in-out,
      opacity 150ms ease-in-out;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};

    .arrow-head {
      transform: translateX(3px);
      path {
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }

    .arrow-body {
      opacity: 1;
      transform: scaleX(2);
      path {
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

const AnimatedArrow = () => (
  <svg viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow-icon">
    <g className="arrow-head">
      <path d="M1 1C4.5 4 5 4.38484 5 4.5C5 4.61516 4.5 5 1 8" stroke="currentColor" strokeWidth="2" />
    </g>
    <g className="arrow-body">
      <path d="M3.5 4.5H0" stroke="currentColor" strokeWidth="2" />
    </g>
  </svg>
);

export default AnimatedArrow;
