import Konva from 'konva/lib/Core';
// shared test results wants these konva references brought in explicitly
import 'konva/lib/shapes/Circle';
import 'konva/lib/shapes/Image';
import 'konva/lib/shapes/Rect';
import { rgba } from 'polished';
import { sum } from 'lodash';
import { HEALTH } from 'shared/synthetics/constants';
import { healthToThemeColor, getWorstHealth } from 'app/views/synthetics/utils/syntheticsUtils';
import { DENSITY } from './constants';

const DOTS = {
  MARGIN: 2
};

const cellCache = {};
const dotsCache = {};

/*
  Higher densities (medium, high) get some special styling treatment
*/
function isHigherDensity(density) {
  return density.id === DENSITY.HIGH.id || density.id === DENSITY.MAX.id;
}

/*
  Make serious issues appear more prominently
*/
function getDotSizeByHealth(health) {
  const { HEALTHY, FAILING } = HEALTH;
  return !health || health === HEALTHY || health === FAILING ? 6 : 8;
}

/*
  Determines the style to be applied to a given cell canvas
  Expected styles include: cornerRadius, fill, dash, stroke, and strokeWidth
*/
function getCellCanvasStyle({ density, col, theme, hovered, highlighted }) {
  const { empty } = col;
  const higherDensity = isHigherDensity(density);

  const defaultProps = {
    stroke: theme.borderColors.thin,
    strokeWidth: higherDensity ? 0 : 1,
    cornerRadius: higherDensity ? 0 : 4
  };

  if (empty) {
    return {
      ...defaultProps,
      fill: higherDensity ? 'transparent' : theme.colors.subnavBackground,
      dash: [1, 1]
    };
  }

  if (hovered) {
    return {
      ...defaultProps,
      fill: theme.colors.calloutOutlineBackgrounds.primary,
      stroke: theme.colors.primary,
      strokeWidth: higherDensity ? 1 : 2
    };
  }

  if (highlighted) {
    return {
      ...defaultProps,
      fill: rgba(theme.colors.rose4, 0.15),
      stroke: theme.colors.rose4,
      strokeWidth: higherDensity ? 1 : 2
    };
  }

  return defaultProps;
}

/*
  Determine the background fill of a cell based on the worst health metric
  Only health of type missing, warning and critical will provide colored backgrounds
*/
function getCellFill({ col, theme, isAggregated }) {
  // determine which is the worst health
  const worstHealth = Object.values(col?.metrics || {}).reduce(
    (acc, metric) => getWorstHealth(acc, metric.health),
    HEALTH.HEALTHY
  );

  if (!worstHealth || worstHealth === HEALTH.WARNING || worstHealth === HEALTH.CRITICAL) {
    // only styled warning and critical health status
    return rgba(healthToThemeColor({ health: worstHealth, theme, isAggregated }), 0.15);
  }

  return 'transparent';
}

/*
  Returns the canvas, dimensions, and styles for a given cell based on state and size
*/
export function getCellCanvas({ density, col, theme, cellSize, hovered, highlighted, isAggregated }) {
  const { saturationSummary } = col;
  const health = saturationSummary?.worstHealth || 'missing';
  const saturation = saturationSummary?.saturation || 'none';
  const higherDensity = isHigherDensity(density);
  const partialSaturation = saturation === 'partial';

  // cache the cell properties by state
  // eslint-disable-next-line prettier/prettier
  const key = `${density.name}-${theme.name}-health:${health}-saturation:${saturation}-empty:${!!col?.empty}-hovered:${!!hovered}-highlighted:${!!highlighted}`;

  if (!cellCache[key]) {
    // calculate styles
    const style = getCellCanvasStyle({ density, col, theme, hovered, highlighted });

    // initialize rectangle canvas
    let cell = new Konva.Rect({ width: cellSize, height: cellSize, ...style });

    if (higherDensity && saturation === 'partial') {
      cell = new Konva.Circle({
        x: 0,
        y: 0,
        radius: density.size / 2,
        fill: rgba(healthToThemeColor({ health: saturationSummary.worstHealth, theme, isAggregated }), 0.15)
      });
    }

    // cache the calculated canvas, dimensions, and styles
    cellCache[key] = {
      canvas: cell.toCanvas({ pixelRatio: window.devicePixelRatio }),
      dimensions: {
        offset: -(style.strokeWidth || 0),
        size: cellSize + 2 * (style.strokeWidth || 0)
      },
      style
    };
  }

  return {
    ...cellCache[key],
    cellFill: higherDensity && partialSaturation ? 'transparent' : getCellFill({ col, theme, isAggregated }) // not a unique property that we cache with the rest of the cell
  };
}

/*
  Returns visual properties used to render canvas circles calculated against a list of metric health
  
  {
    dots: list of dots defined by size and fill determined by health,
    sizes: aggregated list of all sizes in the set of dots,
    fills: aggregated list of all fills in the set of dots
  }
*/
function getDotStyles({ density, theme, metrics, isAggregated }) {
  const higherDensity = isHigherDensity(density);

  if (higherDensity) {
    // consolidate to a single worst health dot we got
    const health = Object.values(metrics || {}).reduce(
      (acc, metric) => getWorstHealth(acc, metric.health),
      HEALTH.HEALTHY
    );

    // calculate size and fill for the worst health
    const size = getDotSizeByHealth(health);
    let fill = healthToThemeColor({ health, theme, isAggregated });

    if (health === HEALTH.HEALTHY) {
      // use a custom, lighter healthy color to help distinguish the missing/warning/critical status in a higher density grid
      fill = theme.colors.networkMesh.highDensityHealthy;
    }

    // only report the consolidated dot
    return { dots: [{ size, fill }], sizes: [size], fills: [fill] };
  }

  return ['latency', 'loss', 'jitter'].reduce(
    (acc, metricKey) => {
      const metric = metrics[metricKey];

      if (metric) {
        const { health } = metric;
        const size = getDotSizeByHealth(health);
        const fill = healthToThemeColor({ health, theme, isAggregated });

        return {
          dots: acc.dots.concat({ size, fill }),
          sizes: acc.sizes.concat(size),
          fills: acc.fills.concat(fill)
        };
      }

      return acc;
    },
    { dots: [], sizes: [], fills: [] }
  );
}

/*
  Returns the canvas for a set of 'dots' representing health states of metrics
*/
export function getDotsCanvas({ density, col, cellSize, theme, isAggregated }) {
  const metrics = col?.metrics || {};
  const { dots, sizes, fills } = getDotStyles({ density, theme, metrics, isAggregated });
  const key = `${cellSize}-${fills.join('-')}`;

  if (!dotsCache[key]) {
    // canvas container where circle children (dots) will be tucked under
    const dotGroup = new Konva.Group({
      x: (cellSize - sum(sizes) - DOTS.MARGIN * (sizes.length - 1)) / 2,
      y: cellSize / 2
    });

    dots.forEach(({ size, fill }, idx) => {
      const radius = size / 2;

      dotGroup.add(
        new Konva.Circle({
          x: radius + sum(sizes.slice(0, idx)) + DOTS.MARGIN * idx,
          y: 0,
          radius,
          fill
        })
      );
    });

    dotsCache[key] = dotGroup.toCanvas({
      x: 0,
      y: 0,
      width: cellSize,
      height: cellSize,
      pixelRatio: window.devicePixelRatio
    });
  }

  return dotsCache[key];
}
