import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Grid, Box, Card, Flex, Text, Select, Popover, Tag, Icon } from 'core/components';
import styled, { withTheme } from 'styled-components';
import { formatDateTime } from 'core/util/dateUtils';
import { PopoverInteractionKind } from '@blueprintjs/core';
import MetricsExplorerButton from 'app/views/metrics/MetricsExplorerButton';
import { ALERT_LOOKBACK_OPTIONS } from 'shared/alerting/constants';
import { percentToText } from 'app/util/utils';
import { FILTER_OPERATORS } from '@kentik/ui-shared/filters/constants';
import { NMS_ENTITY_TYPES } from '@kentik/ui-shared/nms/policies';
import { getEntityData } from '../util/nms/nmsAlertEntity';

const MEASUREMENTS = {
  PROTOCOLS_BGP_NEIGHBORS: '/protocols/bgp/neighbors',
  INTERFACES_COUNTERS: '/interfaces/counters',
  SYSTEM: '/system'
};

const METRICS = {
  SESSION_STATE: 'session-state',
  OPER_STATUS: 'oper-status',
  LAST_IF_OPER_STATUS: 'last_if_OperStatus',
  AVAILABLE: 'available'
};

const HEALTH = {
  HEALTHY: 'healthy',
  UNHEALTHY: 'unhealthy',
  UNKNOWN: 'unknown'
};

const Candy = styled(Flex)`
  position: relative;
  border-radius: 2px;
  min-width: 2px;
  flex: 1;
  background: ${({ fill }) => fill};

  &:hover {
    background: ${({ fillHover }) => fillHover};
  }

  .bp4-popover-target,
  .bp4-popover-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const EventPin = styled(Flex)`
  width: 16px;
  height: 16px;
  top: -24px;
  box-shadow: ${({ theme }) => `0px 0px 0px 1px ${theme.colors.appBackground}`};
  left: 50%;
  border-radius: 100% 100% 0px;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translateX(-50%) rotate(45deg);
`;

const EventPinIcon = styled(Icon)`
  transform: rotate(-45deg);
`;

const CandyNecklaceLoader = ({ chunkCount }) => (
  <Flex gap="2px" flex={1} width="100%" alignItems="stretch">
    {[...Array(chunkCount || 90).keys()].map((key) => (
      <Flex borderRadius="2px" minWidth="2px" showSkeleton flex={1} key={key} />
    ))}
  </Flex>
);

const percentOfTotal = (whole, part) => {
  if (whole === 0 || part === 0) {
    return '0%';
  }

  return percentToText((part / whole) * 100);
};

/**
 * MetricsUpDownChart displays a chart for metrics that have an enumerated list of possible
 * values (eg, /interfaces/counters oper-status). This chart can be used in 3 different
 * contexts:
 * 1. Device context: we are showing metrics for a device, in which case the /system available
 *    metric will be visualized. The `device` prop is minimally required here. It is expected
 *    that the `alertModel`, `measurement`, and `metric` props will be omitted.
 * 2. Alert for a standard up/down policy. The `device` and `alertModel` props are minimally
 *    required here. It is expected that the `measurement` and `metric` props will be omitted.
 * 3. Alert for a native NMS policy based on any enum-valued metric. The `measurement` and
 *    `metric` props are required in addition to `alertModel` prop to display these charts.
 *    `device` is not required, as not all measurements are tied to a device.
 */
@inject('$query', '$metrics', '$alerting')
@withTheme
@observer
class MetricsUpDownChart extends Component {
  static defaultProps = {
    hideEvents: false,
    small: false,
    inline: false,
    showViewInMetricsExplorerButton: true
  };

  state = {
    loading: true,
    lookbackSeconds: this.timeRangeOptions.defaultValue,
    chunks: 90,
    gloppy: {},
    metricValueToLabel: {},
    firstTimeStamp: 0,
    lastTimeStamp: 0,
    uptimePercent: null
  };

  componentDidMount() {
    this.runQuery();
  }

  componentWillReceiveProps(nextProps) {
    const { alertModel } = this.props;

    if (alertModel?.id !== nextProps?.alertModel?.id) {
      // Trigger a refetch if the alertModel has updated
      this.handleLookbackChange();
    }
  }

  get measurement() {
    const { alertModel, measurement } = this.props;
    return measurement || alertModel?.reconMeasurement;
  }

  get timeRangeOptions() {
    const { alertModel } = this.props;

    // If we have an
    const options = alertModel ? ALERT_LOOKBACK_OPTIONS : ALERT_LOOKBACK_OPTIONS.filter((option) => option.value >= 0);
    const defaultValue = alertModel ? options[0].value : options[1].value;

    return { options, defaultValue };
  }

  get metric() {
    const { metric, alertModel } = this.props;

    if (metric) {
      return metric;
    }

    if (alertModel?.isInterfaceUpDownPolicy) {
      return METRICS.OPER_STATUS;
    }

    if (alertModel?.isBgpNeighborPolicy) {
      return METRICS.SESSION_STATE;
    }

    // device up/down
    return alertModel?.sortedMetrics[0] || METRICS.AVAILABLE;
  }

  handleResultsGloppySwamp = ({ results, fullyLoaded }) => {
    const { alertModel } = this.props;
    let upEntries = 0;
    let downEntries = 0; // Prevent dividing by 0
    let alarmMillis;
    let clearMillis;

    if (alertModel) {
      alarmMillis = alertModel.startTime && moment.utc(alertModel.startTime).valueOf();
      clearMillis = alertModel.endTime && moment.utc(alertModel.endTime).valueOf();
    }

    // if there are no results, fill the gloppy with empty chunks
    if (results.size === 0) {
      const { chunks } = this.state;
      const gloppy = {};
      const chunksize = chunks * 2;
      const now = moment.utc().valueOf();
      let ts = now - chunksize * 1000;

      for (let i = 0; i < chunks; i++) {
        gloppy[ts] = { values: { 3: [ts] } };
        ts += chunksize * 1000;
      }

      this.setState({ loading: false, gloppy, uptimePercent: undefined });
      return;
    }

    if (fullyLoaded && results.size > 0) {
      const { chunks } = this.state;
      const [result] = results.toJS();
      const { timeseries, metrics } = result;
      const interval = timeseries.length > 1 ? timeseries[1][0] - timeseries[0][0] : 0;
      const firstTimeStamp = timeseries.at(0)[0];
      const lastTimeStamp = timeseries.at(-1)[0];
      const chunksize = Math.ceil(timeseries.length / chunks);

      let stop = chunksize;
      let indexTs = firstTimeStamp;
      let alarmTimeStamp = null;
      let clearTimeStamp = null;

      const gloppy = {};
      gloppy[indexTs] = { values: {} };
      timeseries.forEach((ts, idx) => {
        const [timestampMillis, ...metricValues] = ts;
        const metricValue = metricValues[metrics.indexOf(this.metric) || 0];
        const entryHealth = this.getValueHealth(metricValue);

        if (entryHealth === HEALTH.HEALTHY) {
          upEntries += 1;
        } else if (entryHealth === HEALTH.UNHEALTHY) {
          downEntries += 1;
        }

        if (idx === stop) {
          stop += chunksize;
          indexTs = timestampMillis;
          gloppy[indexTs] = { values: {} };
        }
        const insert = gloppy[indexTs].values[metricValue];
        if (insert) {
          insert.push(timestampMillis);
        } else {
          gloppy[indexTs].values[metricValue] = [timestampMillis];
        }
        gloppy[indexTs].endTs = timestampMillis + interval; // end of this chunk and start of next chunk

        // Associate event with chunk time
        if (alarmMillis && alarmMillis >= indexTs) {
          alarmTimeStamp = indexTs;
        }

        if (clearMillis && clearMillis >= indexTs) {
          clearTimeStamp = indexTs;
        }
      });

      this.setState({
        gloppy,
        firstTimeStamp,
        lastTimeStamp,
        alarmTimeStamp,
        clearTimeStamp,
        uptimePercent: percentOfTotal(upEntries + downEntries, upEntries)
      });
    }
    this.setState({ loading: false });
  };

  runQuery = () => {
    const { $metrics, $query, device, alertModel } = this.props;
    const query = this.baseQuery();
    let metricValueMap;

    if (alertModel) {
      metricValueMap = $metrics.measurementModel(this.measurement)?.get(`storage.Metrics.${this.metric}.Values`, {});
    } else {
      // This is a device summary, not an alert
      metricValueMap = $metrics.measurementModel(MEASUREMENTS.SYSTEM)?.get('storage.Metrics.available.Values', {});
    }

    // set the labels based on the measurement
    // for enums like session-state, etc
    this.setState({ metricValueToLabel: metricValueMap || {}, metricsExplorerQuery: query });
    if (device) {
      device.set('query', query);
    }
    $query.runQuery(query).then(this.handleResultsGloppySwamp);
  };

  getWindowSize = (value, minValueMinutes = 1) => {
    const { alertModel } = this.props;
    const { chunks } = this.state;
    const durationMinutes =
      value < 0 && alertModel
        ? moment
            .utc(alertModel.endTime)
            .add(Math.abs(value), 'seconds')
            .diff(moment.utc(alertModel.startTime).subtract(Math.abs(value), 'seconds'), 'minutes')
        : value / 60;
    const sizeMinutes = durationMinutes / (chunks * 2);
    const sizePreset = [240, 120, 60, 30, 15, 10, 5].find((size) => size <= sizeMinutes) || minValueMinutes;
    return Math.max(sizePreset, minValueMinutes) * 60;
  };

  getTimeRange = (value) => {
    const { alertModel } = this.props;

    if (alertModel) {
      const nowMillis = moment.utc().valueOf();
      const startTimeMillis = moment.utc(alertModel.startTime).subtract(Math.abs(value), 'seconds').valueOf();
      const endTimeMillis = moment.utc(alertModel.endTime).add(Math.abs(value), 'seconds').valueOf();

      // For preset lookbacks
      if (value > 0) {
        return {
          lookback: `PT${value}S`
        };
      }

      // For event windows
      if (value < 0 && alertModel.startTime) {
        return {
          start: startTimeMillis,
          end: Math.min(endTimeMillis, nowMillis),
          lookback_seconds: 0
        };
      }
    }
    return { lookback: `PT${value}S` };
  };

  interfaceQuery = () => {
    const { device, alertModel } = this.props;
    const deviceName = device.get('name');
    const interfaceName = alertModel.reconDimensions.name || alertModel.reconDimensions.if_interface_name;
    const { lookbackSeconds } = this.state;
    return {
      measurement: MEASUREMENTS.INTERFACES_COUNTERS,
      dimensions: ['device_name', 'name'],
      window: { size: this.getWindowSize(lookbackSeconds), fn: { [METRICS.OPER_STATUS]: 'min' } },
      metrics: [
        {
          name: METRICS.OPER_STATUS,
          type: 'gauge'
        }
      ],
      range: this.getTimeRange(lookbackSeconds),
      rollups: {
        last_if_OperStatus: {
          metric: METRICS.OPER_STATUS,
          aggregate: 'last'
        }
      },
      sort: [
        {
          name: METRICS.LAST_IF_OPER_STATUS,
          direction: 'desc'
        }
      ],
      limit: 1,
      includeTimeseries: 1,
      viz: {
        type: 'line'
      },
      filters: {
        connector: 'All',
        filterGroups: [
          {
            name: '',
            named: false,
            connector: 'All',
            not: false,
            autoAdded: '',
            filters: [
              {
                filterField: 'device_name',
                metric: '',
                aggregate: '',
                operator: '=',
                filterValue: deviceName
              },
              {
                filterField: 'name',
                metric: '',
                aggregate: '',
                operator: '=',
                filterValue: interfaceName
              }
            ],
            saved_filters: [],
            filterGroups: []
          }
        ]
      }
    };
  };

  deviceQuery = () => {
    const { device } = this.props;
    const { lookbackSeconds } = this.state;

    return {
      measurement: MEASUREMENTS.SYSTEM,
      dimensions: ['device_name'],
      window: { size: this.getWindowSize(lookbackSeconds), fn: { available: 'min' } },
      metrics: [
        {
          name: METRICS.AVAILABLE,
          type: 'gauge'
        }
      ],
      range: this.getTimeRange(lookbackSeconds),
      rollups: {
        last_available: {
          metric: METRICS.AVAILABLE,
          aggregate: 'last'
        }
      },
      sort: [
        {
          name: 'last_available',
          direction: 'desc'
        }
      ],
      limit: 1,
      includeTimeseries: 1,
      viz: {
        type: 'line'
      },
      filters: {
        connector: 'All',
        filterGroups: [
          {
            name: '',
            named: false,
            connector: 'All',
            not: false,
            autoAdded: '',
            filters: [
              {
                filterField: 'km_device_id',
                metric: '',
                aggregate: '',
                operator: '=',
                filterValue: device.id
              }
            ],
            saved_filters: [],
            filterGroups: []
          }
        ]
      }
    };
  };

  customQuery = () => {
    const { device, alertModel, metric: passedMetric } = this.props;
    const { lookbackSeconds } = this.state;
    const metric = passedMetric || alertModel.sortedMetrics[0];
    const minWindowSize =
      this.measurement.startsWith(MEASUREMENTS.SYSTEM) || this.measurement === MEASUREMENTS.INTERFACES_COUNTERS ? 1 : 5;
    const dimensions = Object.keys(alertModel.reconDimensions);
    const filters = dimensions.map((dimension) => {
      const value = alertModel.reconDimensions[dimension];
      return {
        filterField: dimension,
        metric: '',
        aggregate: '',
        operator: '=',
        filterValue: typeof value === 'string' ? `${value}` : value
      };
    });

    if (device?.id) {
      filters.unshift({
        filterField: 'km_device_id',
        metric: '',
        aggregate: '',
        operator: '=',
        filterValue: device.id
      });
    }

    return {
      measurement: this.measurement,
      dimensions,
      window: { size: this.getWindowSize(lookbackSeconds, minWindowSize), fn: { [metric]: 'last' } },
      metrics: [
        {
          name: metric,
          type: 'gauge'
        }
      ],
      range: this.getTimeRange(lookbackSeconds),
      rollups: {
        [`last_${metric}`]: {
          metric,
          aggregate: 'last'
        }
      },
      sort: [
        {
          name: `last_${metric}`,
          direction: 'desc'
        }
      ],
      limit: 1,
      includeTimeseries: 1,
      viz: {
        type: 'line'
      },
      filters: {
        connector: 'All',
        filterGroups: [
          {
            name: '',
            named: false,
            connector: 'All',
            not: false,
            autoAdded: '',
            filters,
            saved_filters: [],
            filterGroups: []
          }
        ]
      }
    };
  };

  nmsNativeAlertQuery() {
    const { alertModel, metric } = this.props;
    const { lookbackSeconds } = this.state;

    const entityData = getEntityData(alertModel);

    const deviceDimensions = ['device_name', 'vendor', 'device-model'];

    if (entityData) {
      if (entityData.type === NMS_ENTITY_TYPES.COMPONENT && entityData.component?.index) {
        return {
          measurement: this.measurement,
          dimensions: [...deviceDimensions, 'name', 'type'],
          metrics: [
            {
              name: metric,
              type: 'gauge'
            }
          ],
          range: this.getTimeRange(lookbackSeconds),
          window: {
            size: this.getWindowSize(lookbackSeconds, 10),
            fn: {
              [metric]: 'last'
            }
          },
          rollups: {
            [metric]: {
              metric,
              aggregate: 'last'
            }
          },
          sort: [
            {
              name: metric,
              direction: 'desc'
            }
          ],
          limit: 1,
          includeTimeseries: 1,
          viz: {
            type: 'line'
          },
          filters: {
            connector: 'All',
            filterGroups: [
              {
                name: '',
                named: false,
                connector: 'All',
                not: false,
                autoAdded: '',
                filters: [
                  {
                    filterField: 'km_device_id',
                    metric: '',
                    aggregate: '',
                    operator: '=',
                    filterValue: entityData?.device?.id
                  },
                  {
                    filterField: 'index',
                    metric: '',
                    aggregate: '',
                    operator: '=',
                    filterValue: entityData?.component?.index
                  }
                ],
                saved_filters: [],
                filterGroups: []
              }
            ]
          }
        };
      }

      if (entityData.type === NMS_ENTITY_TYPES.INTERFACE && entityData.interface) {
        return {
          measurement: this.measurement,
          dimensions: [...deviceDimensions, 'name', 'type'],
          metrics: [
            {
              name: metric,
              type: 'gauge'
            }
          ],
          range: this.getTimeRange(lookbackSeconds),
          window: {
            size: this.getWindowSize(lookbackSeconds, 10),
            fn: {
              [metric]: 'last'
            }
          },
          rollups: {
            [metric]: {
              metric,
              aggregate: 'last'
            }
          },
          sort: [
            {
              name: metric,
              direction: 'desc'
            }
          ],
          limit: 1,
          includeTimeseries: 1,
          viz: {
            type: 'line'
          },
          filters: {
            connector: 'All',
            filterGroups: [
              {
                name: '',
                named: false,
                connector: 'All',
                not: false,
                autoAdded: '',
                filters: [
                  {
                    filterField: 'km_device_id',
                    metric: '',
                    aggregate: '',
                    operator: '=',
                    filterValue: entityData?.device?.id
                  },
                  {
                    filterField: 'ifindex',
                    metric: '',
                    aggregate: '',
                    operator: '=',
                    filterValue: entityData?.interface?.id
                  }
                ],
                saved_filters: [],
                filterGroups: []
              }
            ]
          }
        };
      }

      if (entityData.type === NMS_ENTITY_TYPES.BGP && entityData.hasBgpData) {
        return {
          measurement: this.measurement,
          dimensions: [...deviceDimensions, 'peer-as', 'transport/remote-address'],
          metrics: [
            {
              name: metric,
              type: 'gauge'
            }
          ],
          range: this.getTimeRange(lookbackSeconds),
          window: {
            size: this.getWindowSize(lookbackSeconds, 10),
            fn: {
              [metric]: 'last'
            }
          },
          rollups: {
            [metric]: {
              metric,
              aggregate: 'last'
            }
          },
          sort: [
            {
              name: metric,
              direction: 'desc'
            }
          ],
          limit: 1,
          includeTimeseries: 1,
          filters: {
            connector: 'All',
            filterGroups: [
              {
                connector: 'All',
                not: false,
                filters: [
                  {
                    filterField: 'km_device_id',
                    metric: '',
                    aggregate: '',
                    operator: '=',
                    filterValue: entityData.device?.id
                  },
                  {
                    filterField: 'index',
                    metric: '',
                    aggregate: '',
                    operator: '=',
                    filterValue: entityData.bgp?.index
                  }
                ]
              }
            ]
          }
        };
      }

      if (entityData.type === NMS_ENTITY_TYPES.AGENT && entityData.agent) {
        return {
          measurement: this.measurement,
          dimensions: ['agent_id', 'agent_name', 'kagent_host'],
          metrics: [
            {
              name: metric,
              type: 'gauge'
            }
          ],
          range: this.getTimeRange(lookbackSeconds),
          window: {
            size: this.getWindowSize(lookbackSeconds, 10),
            fn: {
              [metric]: 'last'
            }
          },
          rollups: {
            [metric]: {
              metric,
              aggregate: 'last'
            }
          },
          sort: [
            {
              name: metric,
              direction: 'desc'
            }
          ],
          limit: 1,
          includeTimeseries: 1,
          viz: {
            type: 'line'
          },
          filters: {
            connector: 'All',
            filterGroups: [
              {
                name: '',
                named: false,
                connector: 'All',
                not: false,
                autoAdded: '',
                filters: [
                  {
                    filterField: 'agent_id',
                    metric: '',
                    aggregate: '',
                    operator: '=',
                    filterValue: entityData?.agent?.id
                  }
                ],
                saved_filters: [],
                filterGroups: []
              }
            ]
          }
        };
      }
    }

    return this.customQuery();
  }

  bgpNeighborsQuery() {
    const { device, alertModel } = this.props;
    const deviceName = device.get('name');
    const { lookbackSeconds } = this.state;
    const { index } = alertModel.reconDimensions;

    return {
      measurement: MEASUREMENTS.PROTOCOLS_BGP_NEIGHBORS,
      dimensions: ['device_name', 'device_ip', 'transport/remote-address'],
      metrics: [
        {
          name: METRICS.SESSION_STATE,
          type: 'gauge'
        }
      ],

      range: this.getTimeRange(lookbackSeconds),
      window: {
        size: this.getWindowSize(lookbackSeconds, 10),
        fn: {
          [METRICS.SESSION_STATE]: 'min'
        }
      },
      rollups: {
        [METRICS.SESSION_STATE]: {
          metric: METRICS.SESSION_STATE,
          aggregate: 'last'
        }
      },
      sort: [
        {
          name: METRICS.SESSION_STATE,
          direction: 'desc'
        }
      ],
      limit: 1,
      includeTimeseries: 1,
      filters: {
        connector: 'All',
        filterGroups: [
          {
            connector: 'All',
            not: false,
            filters: [
              {
                filterField: 'device_name',
                metric: '',
                aggregate: '',
                operator: '=',
                filterValue: deviceName
              },
              {
                filterField: 'index',
                operator: '=',
                filterValue: index
              }
            ]
          }
        ]
      }
    };
  }

  baseQuery = () => {
    const { alertModel } = this.props;

    let kmetrics = {};

    // On device pages, there is no alert alertModel
    if (!alertModel) {
      kmetrics = this.deviceQuery();
    } else if (alertModel?.isInterfaceUpDownPolicy) {
      kmetrics = this.interfaceQuery();
    } else if (alertModel?.isBgpNeighborPolicy) {
      kmetrics = this.bgpNeighborsQuery();
    } else if (alertModel?.isNmsPolicy) {
      kmetrics = this.nmsNativeAlertQuery();
    } else {
      kmetrics = this.customQuery();
    }

    return {
      use_kmetrics: true,
      show_overlay: false,
      show_total_overlay: false,
      kmetrics
    };
  };

  get condition() {
    const { alertModel } = this.props;
    if (!alertModel) {
      return null;
    }

    return alertModel.threshold.conditions[0];
  }

  get presetHealthyValue() {
    const { alertModel, metric, measurement } = this.props;

    // The way we determine whether we are showing a BGP metric depends on whether this is a standard or NMS alert.
    // For a standard kmetrics alert, we can check the alert model as these alerts will only ever have 1 metric.
    // For NMS alerts, we must look at the specific metric/measurement we are displaying as an alert level can contain multiple measurements/metrics.
    const isKmetricsBgp = alertModel?.isBgpNeighborPolicy;
    const isNmsBgp = metric === METRICS.SESSION_STATE && measurement === MEASUREMENTS.PROTOCOLS_BGP_NEIGHBORS;
    const isBgp = isKmetricsBgp || isNmsBgp;

    // For BGP, 6 means 'Established'. For Interfaces & Devices, 1 means 'up'.
    return isBgp ? '6' : '1';
  }

  getValueHealth = (value, label) => {
    const { alertModel } = this.props;

    if (value === 'null') {
      return HEALTH.UNKNOWN;
    }

    // As written, this will be applicable to standard NMS alerts but not native NMS alerts
    if (alertModel && alertModel.isCustomStateChangeAlert && this.condition) {
      const shouldNotEqual = this.condition.operator === FILTER_OPERATORS.NOT_EQUALS;
      const shouldEqual = this.condition.operator === FILTER_OPERATORS.EQUALS;
      const doesNotEqual = `${value}` !== `${this.condition.comparisonValue}`;
      const doesEqual = `${value}` === `${this.condition.comparisonValue}`;
      return (shouldNotEqual && doesNotEqual) || (shouldEqual && doesEqual) ? HEALTH.UNHEALTHY : HEALTH.HEALTHY;
    }

    if (`${value}` === `${this.presetHealthyValue}`) {
      return HEALTH.HEALTHY;
    }

    // For presets, show any non-up state having a label as unhealthy
    if (label || `${value}` === '0') {
      return HEALTH.UNHEALTHY;
    }

    return HEALTH.UNKNOWN;
  };

  handleLookbackChange = (lookbackSecondsArg) => {
    const { lookbackSeconds } = this.state;
    this.setState(
      {
        lookbackSeconds: lookbackSecondsArg || lookbackSeconds,
        loading: true
      },
      () => {
        this.runQuery();
      }
    );
  };

  handleClickCandy = (timestamp) => {
    const { filterAlertTable } = this.props;
    if (filterAlertTable) {
      const { gloppy } = this.state;
      const candy = gloppy[timestamp];
      const { endTs } = candy;
      filterAlertTable(Number.parseInt(timestamp), Number.parseInt(endTs));
    }
  };

  getChunkTags = (statusValues) => {
    const { $metrics } = this.props;
    const statusKeys = Object.keys(statusValues);
    const { metricValueToLabel } = this.state;
    return (
      statusKeys
        .map((key) => {
          const healthToIntent = {
            [HEALTH.HEALTHY]: 'success',
            [HEALTH.UNHEALTHY]: 'danger'
          };
          const label = $metrics.getMetricValueLabel(metricValueToLabel[key]);
          const valueHealth = this.getValueHealth(key, label);

          return {
            key,
            intent: healthToIntent[valueHealth],
            ts: statusValues[key][0],
            label: label || (valueHealth === HEALTH.UNKNOWN ? 'Unknown' : key),
            state: valueHealth
          };
        })
        // Sort by timestamp
        .sort((a, b) => a.ts - b.ts)
    );
  };

  getChunkFill = (tags) => {
    const { theme } = this.props;
    // Will sort to: healthy, unhealthy, unknown
    const states = tags.map((tag) => tag.state);
    let fill = theme.colors.stateTimeline[states[0] || HEALTH.UNKNOWN].background;
    let fillHover = theme.colors.stateTimeline[states[0] || HEALTH.UNKNOWN].backgroundHover;

    if (states.length === 2) {
      fill = `linear-gradient(${theme.colors.stateTimeline[states[0]].background} 0 50%, ${
        theme.colors.stateTimeline[states[1]].background
      } 50% 100%)`;
      fillHover = `linear-gradient(${theme.colors.stateTimeline[states[0]].backgroundHover} 0 50%, ${
        theme.colors.stateTimeline[states[1]].backgroundHover
      } 50% 100%)`;
    }

    if (states.length >= 3) {
      fill = `linear-gradient(${theme.colors.stateTimeline[states[0]].background} 0 33%, ${
        theme.colors.stateTimeline[states[1]].background
      } 33% 66%, ${theme.colors.stateTimeline[states[2]].background} 66% 100%)`;
      fillHover = `linear-gradient(${theme.colors.stateTimeline[states[0]].backgroundHover} 0 33%, ${
        theme.colors.stateTimeline[states[1]].backgroundHover
      } 33% 66%, ${theme.colors.stateTimeline[states[2]].backgroundHover} 66% 100%)`;
    }

    return { fill, fillHover };
  };

  renderCandyPopover({ tags, showAlarmPin, showClearPin }) {
    const { $alerting, alertModel } = this.props;

    const chunkEvents = [...tags];

    if (showAlarmPin) {
      chunkEvents.push({ key: 'alarm', label: 'Start', ts: moment.utc(alertModel.startTime).valueOf() });
    }

    if (showClearPin) {
      chunkEvents.push({ key: 'clear', label: 'Cleared', ts: moment.utc(alertModel.endTime).valueOf() });
    }

    return (
      <Grid
        gridTemplateColumns="auto auto"
        gridRowGap="4px"
        justifyContent="flex-start"
        justifyItems="flex-start"
        alignItems="center"
        gridColumnGap="12px"
        p={1}
      >
        <Text as="div" small muted fontWeight="bold">
          Starting at
        </Text>{' '}
        <Text as="div" fontWeight="bold" small muted>
          Status
        </Text>
        {chunkEvents
          // Mix alarm events in with status events
          .sort((a, b) => a.ts - b.ts)
          .map(({ key, intent, label, ts }) => (
            <Fragment key={key}>
              <Text as="div" small>
                {formatDateTime(ts)}
              </Text>
              {['alarm', 'clear'].includes(key) ? (
                <Flex gap="4px" alignItems="center">
                  <Icon icon={$alerting.getStateIcon(key)} color={$alerting.getStateColor(key)} iconSize={12} />
                  <Text small fontWeight="bold" as="div">
                    {label}
                  </Text>
                </Flex>
              ) : (
                <Box>
                  <Tag intent={intent} minimal fontWeight="medium">
                    {label}
                  </Tag>
                </Box>
              )}
            </Fragment>
          ))}
      </Grid>
    );
  }

  renderCandyNecklace() {
    const { $alerting, hideEvents, inline } = this.props;
    const { gloppy, alarmTimeStamp, clearTimeStamp } = this.state;

    return Object.keys(gloppy).map((timestamp) => {
      const chunkValues = gloppy[timestamp]?.values;
      const tags = this.getChunkTags(chunkValues);
      const { fill, fillHover } = this.getChunkFill(tags);

      const showAlarmPin = `${alarmTimeStamp}` === `${timestamp}`;
      const showClearPin = `${clearTimeStamp}` === `${timestamp}`;
      const hasAlarmEvent = !inline && !hideEvents && (showAlarmPin || showClearPin);

      return (
        <Candy
          fill={fill}
          fillHover={fillHover}
          key={timestamp}
          onClick={() => this.handleClickCandy(timestamp)}
          style={{ transform: hasAlarmEvent ? 'translateY(-3px)' : 'none' }}
          zIndex={showAlarmPin ? 10 : 'auto'}
        >
          <Popover
            interactionKind={PopoverInteractionKind.HOVER}
            hoverOpenDelay={100}
            minimal={false}
            content={this.renderCandyPopover({ tags, showAlarmPin, showClearPin })}
            isDisabled={false}
            position="bottom"
            targetTagName="div"
            wrapperTagName="div"
          >
            <Box
              style={{
                height: '100%',
                width: '100%'
              }}
            >
              {hasAlarmEvent && showAlarmPin && (
                <EventPin bg={$alerting.getStateColor('alarm')} zIndex={10}>
                  <EventPinIcon icon={$alerting.getStateIcon('alarm')} color="appBackground" iconSize={12} />
                </EventPin>
              )}
              {hasAlarmEvent && showClearPin && !showAlarmPin && (
                <EventPin bg={$alerting.getStateColor('clear')}>
                  <EventPinIcon icon={$alerting.getStateIcon('clear')} color="appBackground" iconSize={12} />
                </EventPin>
              )}
            </Box>
          </Popover>
        </Candy>
      );
    });
  }

  render() {
    const {
      $alerting,
      alertModel,
      hideEvents,
      small,
      showViewInMetricsExplorerButton,
      inline,
      title,
      ...containerProps
    } = this.props;
    const { loading, gloppy, firstTimeStamp, lastTimeStamp, lookbackSeconds, metricsExplorerQuery, uptimePercent } =
      this.state;
    let errorText;

    if (!loading && isEmpty(gloppy)) {
      errorText = 'No results available.';
    }

    return (
      <Flex width="100%" flexDirection="column" {...containerProps}>
        {!inline && (
          <Flex justifyContent="space-between" alignItems="flex-start" mb={1}>
            <Flex justifyContent="flex-end">
              {showViewInMetricsExplorerButton && metricsExplorerQuery && (
                <MetricsExplorerButton query={metricsExplorerQuery} small={small} />
              )}
            </Flex>
            <Box>
              <Select
                options={this.timeRangeOptions.options}
                values={lookbackSeconds}
                onChange={this.handleLookbackChange}
                width={155}
                menuWidth={155}
                minimal
                small={small}
              />
            </Box>
          </Flex>
        )}
        {inline && (
          <Flex justifyContent="center" alignItems="center" gap={2} mb="4px">
            <Box>
              <Select
                options={this.timeRangeOptions.options}
                values={lookbackSeconds}
                onChange={this.handleLookbackChange}
                width="auto"
                menuWidth="auto"
                minimal
                small
              />
            </Box>
            {!loading && (
              <>
                <Box flex={1} borderBottom="thin" />
                <Box>
                  <Text small fontWeight="heavy">
                    {uptimePercent ? `${uptimePercent} Uptime` : '--'}
                  </Text>
                </Box>
              </>
            )}
            <Box flex={1} borderBottom="thin" />

            <Box>
              <Text muted small>
                Now
              </Text>
            </Box>
          </Flex>
        )}
        <Flex
          display="flex"
          alignItems="stretch"
          flexBasis={inline ? '30px' : '50px'}
          gap="2px"
          flex={1}
          mt={inline || hideEvents ? 0 : 3}
        >
          {loading && !errorText && <CandyNecklaceLoader chunkCount={Object.keys(gloppy).length} />}
          {!loading && !errorText && this.renderCandyNecklace()}
          {!loading && errorText && (
            <Card as={Flex} width="100%" alignItems="center" justifyContent="center">
              <Text as="div" small={small || inline} muted>
                {errorText}
              </Text>
            </Card>
          )}
        </Flex>
        {!inline && (
          <Flex justifyContent="space-between" alignItems="center" mt={1}>
            {!errorText && (
              <Text flex={1} as="div" muted small={small}>
                {firstTimeStamp > 0 ? moment(firstTimeStamp).fromNow() : ' '}
              </Text>
            )}
            {title && (
              <Box flex={2} as="div" textAlign="center">
                <Text fontWeight="bold" muted small={small}>
                  {title}
                </Text>
              </Box>
            )}
            {!errorText && (
              <Text flex={1} as="div" textAlign="right" muted small={small}>
                {lastTimeStamp > 0 ? moment(lastTimeStamp).fromNow() : ' '}
              </Text>
            )}
          </Flex>
        )}
      </Flex>
    );
  }
}

export default MetricsUpDownChart;
