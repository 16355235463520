import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';
import { formatDateTime } from 'core/util/dateUtils';
import BaseHighchartsNonStyledDataview from 'app/components/dataviews/views/BaseHighchartsNonStyledDataview';

const CHART_HEIGHT = 200;

@inject('$app', '$dataviews')
@observer
class TracerouteCounter extends BaseHighchartsNonStyledDataview {
  state = {};

  delta = 0;

  constructor(props) {
    super(props);

    const { theme, onHover, xAxisMin, xAxisMax, delta } = this.props;
    const { colors, fonts } = theme;

    this.delta = delta;

    const yAxis = ({ name, color, symbol, i }) => ({
      type: 'line',
      name,
      color,
      yAxis: i,
      data: [],
      marker: {
        enabled: true,
        symbol,
        lineWidth: 1,
        radius: 4,
        states: {
          select: {
            enabled: true,
            fillColor: color,
            lineColor: 'rgba(255, 255, 255, 0.5)',
            lineWidth: 5,
            radius: 6
          },
          hover: {
            enabled: false
          }
        }
      },
      states: {
        hover: {
          enabled: false,
          lineWidthPlus: 0
        }
      },
      point: {
        events: {
          mouseOver: (e) => {
            if (onHover) {
              onHover(e.target.options.time);
            }
          }
        }
      }
    });

    this.state = {
      chartOptions: {
        chart: {
          type: 'line',
          style: { fontFamily: fonts.body },
          height: CHART_HEIGHT,
          spacingLeft: 0,
          spacingRight: 0,
          backgroundColor: colors.chart.tooltipBackground
        },
        title: { text: '' },
        credits: { enabled: false },
        legend: { enabled: false },
        xAxis: {
          type: 'datetime',
          crosshair: {
            color: colors.chart.crosshair
          },
          lineColor: colors.chart.axisLine,
          gridLineColor: colors.chart.gridLine,
          tickColor: colors.chart.axisTick,
          labels: {
            style: {
              color: colors.muted
            },
            formatter() {
              if (this.dateTimeLabelFormat === '%b %e') {
                return `<strong>${formatDateTime(this.value, 'MMM Do')}</strong>`;
              }

              return this.axis.defaultLabelFormatter.call(this);
            }
          },
          dateTimeLabelFormats: {
            day: '%b %e',
            week: '%b %e'
          },
          min: xAxisMin,
          max: xAxisMax
        },
        yAxis: [
          {
            title: { text: '' },
            gridLineColor: colors.chart.gridLine,
            labels: {
              align: 'left',
              x: 0,
              y: -2,
              style: {
                color: colors.muted
              }
            },
            allowDecimals: false
          },
          {
            title: { text: '' },
            gridLineColor: colors.chart.gridLine,
            labels: {
              align: 'right',
              x: 0,
              y: -2,
              style: {
                color: colors.muted
              },
              formatter() {
                return `${this.value.toLocaleString()} km`;
              }
            },
            opposite: true
          }
        ],
        tooltip: {
          pointFormatter() {
            const value = this.y.toLocaleString();
            const suffix = this.series.name === 'Count' ? ' Hops' : 'km';
            const symbol = '●';

            return `<div><span style="color:${this.color}">${symbol}</span> ${value} ${suffix}</div>`;
          },
          outside: true,
          useHTML: true,
          backgroundColor: colors.chart.tooltipBackground,
          shared: true,
          style: {
            color: colors.body,
            fontFamily: fonts.body
          },
          headerFormat: ''
        },
        series: [
          yAxis({ name: 'Count', color: colors.blue1, i: 0, symbol: 'circle' }),
          yAxis({ name: 'Distance', color: colors.blue5, i: 1, symbol: 'circle' })
        ]
      }
    };
  }

  get type() {
    return 'timeline';
  }

  redraw() {
    if (this.chart) {
      const { theme } = this.props;
      const { colors, fonts } = theme;

      this.setState({
        chartOptions: {
          chart: {
            height: CHART_HEIGHT,
            backgroundColor: colors.chart.tooltipBackground,
            style: { fontFamily: fonts.body }
          },
          tooltip: {
            backgroundColor: colors.chart.tooltipBackground,
            style: {
              color: colors.body,
              fontFamily: fonts.body
            }
          },
          xAxis: {
            lineColor: colors.chart.axisLine,
            gridLineColor: colors.chart.gridLine,
            tickColor: colors.chart.axisTick,
            crosshair: {
              color: colors.chart.crosshair
            },
            labels: {
              style: {
                color: colors.muted
              }
            }
          },
          yAxis: [
            {
              gridLineColor: colors.chart.gridLine,
              labels: {
                style: {
                  color: colors.muted
                }
              }
            },
            {
              gridLineColor: colors.chart.gridLine,
              labels: {
                style: {
                  color: colors.muted
                }
              }
            }
          ]
        }
      });
    }
  }

  renderData() {
    if (this.chart) {
      const { data, xMin, xMax, theme } = this.props;
      const { colors } = theme;

      this.setState(({ chartOptions }) => {
        if (Array.isArray(chartOptions?.series)) {
          const { averageHops, averageDistance, ...seriesData } = data.reduce(
            (acc, curr) => {
              const { time, actualHopCount, actualDistance } = curr;
              const base = { time, x: time };

              if (time >= xMin && time <= xMax) {
                const hopCountAverage = Math.ceil(actualHopCount);
                const hopDistanceAverage = Math.ceil(actualDistance);

                acc.hops.push({ ...base, y: hopCountAverage });
                acc.distance.push({ ...base, y: hopDistanceAverage });
                acc.averageHops += hopCountAverage;
                acc.averageDistance += hopDistanceAverage;
              }

              return acc;
            },
            {
              hops: [],
              distance: [],
              averageHops: 0,
              averageDistance: 0
            }
          );

          chartOptions.series[0].data = seriesData.hops;
          chartOptions.series[1].data = seriesData.distance;
          chartOptions.yAxis[0].plotLines = [
            {
              value: averageHops / seriesData.hops.length,
              color: colors.blue1,
              width: 1,
              dashStyle: 'Dash'
            }
          ];
          chartOptions.yAxis[1].plotLines = [
            {
              value: averageDistance / seriesData.distance.length,
              color: colors.blue5,
              width: 1,
              dashStyle: 'Dash'
            }
          ];

          return { chartOptions };
        }

        return null;
      });
    }
  }
}

export default withTheme(TracerouteCounter);
