import React, { Component } from 'react';
import { Collapse } from '@blueprintjs/core';
import classNames from 'classnames';

import ModalOptionGroupTitle from './ModalOptionGroupTitle';
import ModalOptionItem from './ModalOptionItem';

const subSectionClassMap = {
  'Amazon Web Services': 'aws',
  'Google Cloud Platform': 'gcp'
};

const threeColMap = ['src', 'dst', 'full'];
const fourColMap = ['src', 'dst', 'src_or_dst', 'full'];

function getSubGroupClassName(col, numColumns) {
  return numColumns === 4 ? fourColMap[col] : threeColMap[col];
}

class ModalOptionGroup extends Component {
  render() {
    const {
      disabled,
      disabledValues = [],
      className,
      group,
      maxColumns = 3,
      options,
      optionTitleRenderer,
      selectState,
      isSubGroup = false,
      isSubSection = false,
      collapsedState,
      collapsed = false,
      field,
      multi
    } = this.props;
    const { values } = selectState;

    const hasSubGroups = options.some((option) => Array.isArray(option) || !option.value);

    let numColumns;
    if (!isSubGroup) {
      numColumns = options.filter((option) => Array.isArray(option)).length;
    }

    const GroupTitle = optionTitleRenderer || ModalOptionGroupTitle;
    const groupClassName = classNames({
      'modal-options-subgroup': isSubSection,
      'modal-options-group': !isSubGroup && !isSubSection
    });

    return (
      <div
        key={group}
        className={groupClassName}
        style={{
          flex: isSubGroup ? 1 : undefined,
          overflow: isSubGroup ? 'hidden' : undefined
        }}
      >
        <div className={className}>
          {!isSubGroup && <GroupTitle {...this.props} />}
          <Collapse isOpen={!collapsed}>
            <div
              style={{
                display: hasSubGroups ? 'flex' : undefined,
                columnCount: !isSubGroup ? maxColumns : undefined,
                flexWrap: hasSubGroups ? 'wrap' : undefined,
                margin: !isSubGroup && maxColumns >= 3 ? '0 4px' : undefined
              }}
            >
              {options.map((option, idx) => {
                if (Array.isArray(option)) {
                  const key = getSubGroupClassName(idx, numColumns);
                  let subGroupClass;
                  if (option.length > 0) {
                    subGroupClass = option[0].excludeGroupClassName ? 'none' : key;
                  }

                  return (
                    <ModalOptionGroup
                      {...this.props}
                      key={key}
                      options={option}
                      isSubGroup
                      isSubSection={false}
                      className={subGroupClass}
                    />
                  );
                }

                if (!option.value) {
                  return Object.keys(option).map((subGroup) => (
                    <ModalOptionGroup
                      {...this.props}
                      key={subGroup}
                      group={subGroup}
                      options={option[subGroup]}
                      className={subSectionClassMap[subGroup]}
                      isSubSection
                      collapsed={collapsedState[subGroup]}
                      disabled={disabled || disabledValues.includes(subGroup)}
                    />
                  ));
                }

                return (
                  <ModalOptionItem
                    key={option.value}
                    option={option}
                    field={field}
                    multi={multi}
                    selectItem={selectState.selectItem}
                    selected={values.includes(option.value)}
                    disabled={disabled || disabledValues.includes(option.value)}
                  />
                );
              })}
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default ModalOptionGroup;
