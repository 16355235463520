import React from 'react';
import { observer } from 'mobx-react';

import { Button, Box, Flex } from 'core/components';
import { Field, InputGroup, ColorPicker } from 'core/form';

const StaticRange = (props) => {
  const { range, onRemove, valueLabel, allowRemove, count, valueMaxWidth } = props;

  return (
    <Box>
      <Flex alignItems="baseline" mb={2}>
        <Field className="no-margin" field={range['data.value']} fieldStyle={{ minWidth: 137 }} showLabel={false}>
          <ColorPicker />
        </Field>
        <Box whiteSpace="nowrap" mx={1}>
          {count === 1 ? 'values up to (inclusive)' : ' values above previous range up to (inclusive)'}
        </Box>
        <Field
          className="no-margin"
          field={range.to}
          inputClassName="small-number-field"
          showLabel={false}
          fieldStyle={{ maxWidth: valueMaxWidth || 125 }}
          mr={1}
          helperText={null}
        >
          <InputGroup />
        </Field>
        <Flex>{valueLabel}</Flex>
        {allowRemove && (
          <Box>
            <Button ml={1} minWidth={10} mt="-2px" minimal onClick={onRemove} icon="cross" intent="danger" />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default observer(StaticRange);
