import React, { Component } from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { debounce } from 'lodash';
import { Box } from 'core/components';
import { updateTimelineChartSelection } from 'app/views/synthetics/utils/syntheticsUtils';
import { AlertTimelinePanel, SummaryPanel, IncidentLogPanel } from './panels';
import TestResultsGrid from './TestResultsGrid';
import DataTabs from './DataTabs';

@inject('$dataviews', '$syn', '$auth')
@observer
class TestResultsPageContent extends Component {
  componentDidMount() {
    const { test } = this.props;
    this.disposer = reaction(
      () => test.agentResults.resultTimeMs,
      () => {
        this.selectTimelinePoints();
        this.updateMapMarkers();
      }
    );
  }

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
    }
  }

  onMapLoad = (map, mapCmp) => {
    this.mapCmp = mapCmp;
  };

  onChangeResultTimeMs = (resultTimeMs) => {
    const { test } = this.props;
    test.results.onChangeResultTimeMs(resultTimeMs);
  };

  onChangeTraceResultTimeMs = (traceResultTimeMs) => {
    const { test } = this.props;
    test.results.onChangeTraceResultTimeMs(traceResultTimeMs);
  };

  selectTimelinePoints = debounce(() => {
    const { $dataviews, test } = this.props;

    updateTimelineChartSelection($dataviews, test.results.resultTimeMs);
  }, 150);

  updateMapMarkers = debounce(() => {
    if (this.mapCmp) {
      this.mapCmp.removeMarkers();
      this.mapCmp.addMarkers();
    }
  }, 150);

  handleShowHealthClick = ({ startDate, endDate, lookbackSeconds }) => {
    const { test } = this.props;
    test.results.onDateRangeChange({ startDate, endDate, lookbackSeconds });
  };

  render() {
    const { test } = this.props;

    return (
      <TestResultsGrid>
        <SummaryPanel test={test} alarms={test.results.alarmResults} />

        <AlertTimelinePanel
          testResults={test.results}
          onSelect={this.onChangeResultTimeMs}
          onShowHealthClick={this.handleShowHealthClick}
        />

        <IncidentLogPanel test={test} testResults={test.results} />

        <Box gridColumn="1/3">
          <DataTabs
            test={test}
            onChangeResultTimeMs={this.onChangeResultTimeMs}
            onChangeTraceResultTimeMs={this.onChangeTraceResultTimeMs}
            onMapLoad={this.onMapLoad}
          />
        </Box>
      </TestResultsGrid>
    );
  }
}

export default TestResultsPageContent;
