import React, { useContext } from 'react';
import { MobXProviderContext, useObserver } from 'mobx-react';
import { ReactComponent as AwsLogoLight } from 'app/assets/logos/aws-logo.svg';
import { ReactComponent as AwsLogoDark } from 'app/assets/logos/aws-logo-dark.svg';

export default function AwsLogo({ width = 12, height = 12, forceDark = false, iconSize, color, ...props }) {
  const { $app } = useContext(MobXProviderContext);
  const Logo = $app.darkThemeEnabled || forceDark ? AwsLogoDark : AwsLogoLight;
  return useObserver(() => <Logo width={width} height={height} {...props} />);
}
