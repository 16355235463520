import React, { Component, Fragment } from 'react';

import { inject, observer } from 'mobx-react';
import { MdArrowForward, MdDashboard, MdFilterList, MdStorage } from 'react-icons/md';
import { Box, Button, Callout, Card, Flex, Icon, Heading, Text } from 'core/components';
import { Label, Field, Switch, RadioGroup, InputGroup, formConsumer } from 'core/form';
import FiltersDisplay from 'app/components/filters/display/FiltersDisplay';
import FilterOptions from 'app/components/filters/FilterOptions';
import TimeDisplay from 'app/views/core/explorer/sidebar/TimeDisplay';
import TimeOptions from 'app/views/core/explorer/sidebar/TimeOptions';
import DeviceDisplay from 'app/views/core/explorer/sidebar/DeviceDisplay';
import PopoverLabel from 'app/components/PopoverLabel';
import DeviceSelector from 'app/components/device/DeviceSelector';
import DashboardSelector from '../../DashboardSelector';

@inject('$dashboards')
@formConsumer
@observer
export default class NavigateDashboard extends Component {
  constructor(props) {
    super(props);

    const { $dashboards, form } = props;
    let selectedDashboard = form.getValue('destination_dashboard');
    if (selectedDashboard) {
      selectedDashboard = $dashboards.collection.get(selectedDashboard);
    }

    this.setDeviceRules(form.getValue('dashboard_navigation_options.devices'));

    this.state = {
      isDashboardNavigationEnabled: form.getValue('dashboard_navigation'),
      isEditingFilters: false,
      selectedDashboard
    };
  }

  getDeviceOptions = (dashboard) => [
    {
      label: 'Use Data Sources from SOURCE',
      value: 'source_dashboard'
    },
    {
      label: (
        <PopoverLabel
          label="Use"
          popoverText="Data Sources from DESTINATION"
          content={<DeviceDisplay query={dashboard.originalQuery} />}
          targetProps={{ style: { display: 'inline-block' } }}
        />
      ),
      value: 'destination_dashboard'
    },
    {
      label: 'Use Data Sources from SOURCE panel',
      value: 'source_panel'
    },
    {
      label: 'Use Custom Data Source List',
      value: 'custom'
    }
  ];

  getTimeRangeOptions = (dashboard) => [
    {
      label: 'Use Time Range from SOURCE',
      value: 'source_dashboard'
    },
    {
      label: (
        <PopoverLabel
          label="Use"
          popoverText="Time Range from DESTINATION"
          content={<TimeDisplay {...dashboard.originalQuery} />}
        />
      ),
      value: 'destination_dashboard'
    },
    {
      label: 'Use Time Range from SOURCE panel',
      value: 'source_panel'
    },
    {
      label: 'Use Custom Time Range',
      value: 'custom'
    }
  ];

  getFilterOptions = (dashboard) => [
    {
      label: 'Use Filters from SOURCE',
      value: 'source_dashboard'
    },
    {
      label: (
        <PopoverLabel
          label="Use"
          popoverText="Filters from DESTINATION"
          content={<FiltersDisplay filters={dashboard.originalQuery.filters} />}
        />
      ),
      value: 'destination_dashboard'
    },
    {
      label: 'Use SOURCE and DESTINATION filters',
      value: 'source_destination'
    },
    {
      label: 'Use Filters from SOURCE panel',
      value: 'source_panel'
    },
    {
      label: 'Use Custom Filters',
      value: 'custom'
    }
  ];

  onDashboardNavigationChange = () => {
    const { isDashboardNavigationEnabled } = this.state;
    const { form } = this.props;

    const dashNavEnabled = !isDashboardNavigationEnabled;

    this.setState({ isDashboardNavigationEnabled: dashNavEnabled }, () => {
      if (!dashNavEnabled) {
        // "Clear out" fields that could have validation issues
        form.setValue('dashboard_navigation_options.parametric', 'prompt');
      }
    });
  };

  onDeviceOptionsChange = ({ value }) => {
    this.setDeviceRules(value);
  };

  handleDashboardSelect = (field, value) => {
    const { $dashboards } = this.props;
    this.setState(() => ({ selectedDashboard: $dashboards.collection.get(value) }));
  };

  handleToggleEditFilters = (isOpen) => {
    this.setState({
      isEditingFilters: isOpen
    });
  };

  setDeviceRules(value) {
    const { form } = this.props;
    form.getField('dashboard_navigation_options.custom.all_devices').setRules(
      value === 'custom'
        ? [
            {
              atLeastOneIfNotAllDevices: [
                'dashboard_navigation_options.custom.device_name',
                'dashboard_navigation_options.custom.device_labels',
                'dashboard_navigation_options.custom.device_types',
                'dashboard_navigation_options.custom.device_sites'
              ]
            }
          ]
        : ''
    );
  }

  render() {
    const { $dashboards, form } = this.props;
    const { isDashboardNavigationEnabled, selectedDashboard, isEditingFilters } = this.state;
    const parametric = selectedDashboard && selectedDashboard.get('parametric');
    const dashboardToAddTo = $dashboards.collection.get(form.getValue('dashboard_id'));

    const isCustomDevicesSelected = form.getValue('dashboard_navigation_options.devices') === 'custom';
    const isCustomTimeRangeSelected = form.getValue('dashboard_navigation_options.time_range') === 'custom';
    const isCustomFiltersSelected = form.getValue('dashboard_navigation_options.filters') === 'custom';

    const parametricOptions = [
      {
        label: 'Use filter value from this Guided View',
        value: 'applyFrom',
        disabled: dashboardToAddTo && !dashboardToAddTo.get('parametric')
      },
      {
        label: (
          <span>
            Use Drill Down selection
            <br />
            <span className="pt-text-muted">(if no selection, user will be prompted)</span>
          </span>
        ),
        value: 'drillDown'
      },
      {
        label: 'Prompt User',
        value: 'prompt'
      }
    ];

    return (
      <Flex flexDirection="column" flex={1} overflow="auto" p="1px">
        <Heading level={5}>
          <Icon icon={MdArrowForward} iconSize={20} />
          <Icon icon={MdDashboard} iconSize={20} />
          Navigate To
        </Heading>

        <Text muted>Configure navigation from this panel to another dashboard.</Text>
        <Box mt={2}>
          <Field name="dashboard_navigation" onChange={this.onDashboardNavigationChange} mb={0} large>
            <Switch showOptionLabel />
          </Field>
        </Box>

        {isDashboardNavigationEnabled && (
          <Card p={2} mt={2}>
            <Flex flex={1}>
              <div>
                <DashboardSelector
                  name="destination_dashboard"
                  className="no-margin"
                  dashboard={selectedDashboard}
                  hiddenDashboards={[parseInt(form.getValue('dashboard_id'), 10)]}
                  onChange={this.handleDashboardSelect}
                  menuWidth={355}
                  mb={0}
                />
                {isDashboardNavigationEnabled && selectedDashboard && (
                  <Box mt={3}>
                    <Label fontWeight="medium">Destination Settings</Label>
                    <Box ml={2} mt={2}>
                      <Label fontWeight="medium">
                        <Icon icon={MdStorage} mr="4px" />
                        Data Sources
                      </Label>
                      <Field
                        name="dashboard_navigation_options.devices"
                        options={this.getDeviceOptions(selectedDashboard)}
                        onChange={this.onDeviceOptionsChange}
                        showLabel={false}
                      >
                        <RadioGroup inline={false} />
                      </Field>
                      {isCustomDevicesSelected && (
                        <Box mb={2} ml={2} pl={1.5} style={{ width: 270 }}>
                          <DeviceSelector
                            allDevicesFieldName="dashboard_navigation_options.custom.all_devices"
                            deviceNameFieldName="dashboard_navigation_options.custom.device_name"
                            deviceTypesFieldName="dashboard_navigation_options.custom.device_types"
                            deviceSitesFieldName="dashboard_navigation_options.custom.device_sites"
                            deviceLabelsFieldName="dashboard_navigation_options.custom.device_labels"
                          />
                        </Box>
                      )}
                      <Label fontWeight="medium">
                        <Icon icon="time" mr="4px" />
                        Time Range
                      </Label>
                      <Field
                        name="dashboard_navigation_options.time_range"
                        options={this.getTimeRangeOptions(selectedDashboard)}
                        showLabel={false}
                      >
                        <RadioGroup inline={false} />
                      </Field>
                      {isCustomTimeRangeSelected && (
                        <Box mb={2} ml={2} pl={1.5} style={{ width: 270 }}>
                          <TimeOptions showJumpButtons={false} rootFieldName="dashboard_navigation_options.custom" />
                        </Box>
                      )}
                      <Label fontWeight="medium">
                        <Icon icon={MdFilterList} mr="4px" />
                        Filters
                      </Label>
                      <Field
                        name="dashboard_navigation_options.filters"
                        options={this.getFilterOptions(selectedDashboard)}
                        showLabel={false}
                        className="no-margin"
                      >
                        <RadioGroup inline={false} />
                      </Field>
                      {isCustomFiltersSelected && (
                        <div>
                          <Flex ml={2} pl={1.5} width={360}>
                            <FilterOptions
                              fieldName="dashboard_navigation_options.custom.filters"
                              isOpen={isEditingFilters}
                              onClose={() => this.handleToggleEditFilters(false)}
                            />
                          </Flex>
                          <Button
                            ml={28}
                            mt={1}
                            onClick={() => this.handleToggleEditFilters(true)}
                            icon="edit"
                            text="Edit Filters"
                            small
                          />
                        </div>
                      )}
                    </Box>
                  </Box>
                )}
              </div>
              <Box ml={4} flex={1}>
                {parametric && (
                  <Callout my={3}>
                    <Label>
                      <Icon icon="chat" iconSize={18} mr={1} />
                      Guided Mode Settings
                    </Label>
                    <Field
                      name="dashboard_navigation_options.parametric"
                      options={parametricOptions}
                      inputGroupClassName="destination-parametric-radio-group"
                      style={{ marginLeft: 16, marginBottom: 0 }}
                    >
                      <RadioGroup inline={false} />
                    </Field>
                  </Callout>
                )}
                {selectedDashboard && (
                  <Fragment>
                    <Field name="dashboard_navigation_options.nesting.drillDownButtonText" width={150}>
                      <InputGroup />
                    </Field>
                  </Fragment>
                )}
              </Box>
            </Flex>
          </Card>
        )}
      </Flex>
    );
  }
}
