import React from 'react';
import { LinkButton, Link } from 'core/components';

const InsightLink = ({ as, children = 'View Details', id, ...rest }) => {
  const isButton = as === 'Button';
  const to = `/v4/core/insights/${id}`;

  if (isButton) {
    return (
      <LinkButton to={to} {...rest}>
        {children}
      </LinkButton>
    );
  }

  return (
    <Link to={to} {...rest}>
      {children}
    </Link>
  );
};

export default InsightLink;
