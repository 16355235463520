import React from 'react';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { Icon, Link, Spinner, Text } from 'core/components';

const cssTemplate = css`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 8px 12px;
  width: max-content;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-weight: 500;
  transition: all 150ms ease-in-out;

  ${space};

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.4);
    text-decoration: none;
  }

  &:focus {
    outline: 1px dotted #ffffff;
    outline-offset: 4px;
  }
`;

const Button = styled.button([cssTemplate]);
const LinkButton = styled(Link)([cssTemplate]);

const GettingStartedCardButton = (props) => {
  const { onClick, text, icon, to, loading, ...rest } = props;
  const Component = to ? LinkButton : Button;

  return (
    <Component to={to} onClick={onClick} {...rest}>
      {loading && <Spinner size={16} />}
      {!loading && (
        <>
          <Icon iconSize={18} color="white.6" icon={icon} mr={1} />
          <Text fontSize={14} color="#ffffff">
            {text}
          </Text>
        </>
      )}
    </Component>
  );
};

export default GettingStartedCardButton;
