import React from 'react';
import { observer } from 'mobx-react';

import { Flex } from 'core/components';

import AbstractSelect from './AbstractSelect';
import DefaultValueRenderer from './DefaultValueRenderer';
import ModalOptions from './ModalOptions';
import ModalSelectValuesPanel from './ModalSelectValuesPanel';
import SelectFilter from './SelectFilter';

@observer
class ModalSelect extends AbstractSelect {
  static defaultProps = {
    canEscapeKeyClose: true,
    closeOnSelect: false,
    filterable: true,
    multi: false,
    reorderable: false,
    valueRenderer: DefaultValueRenderer,
    valuesPanelWidth: 260
  };

  renderDialogBody() {
    const { className, multi, dialogRef, tools } = this.props;
    const { filterValue } = this.selectState;

    return (
      <Flex className={className} flexDirection="column" ref={dialogRef} flex={1} overflow="hidden">
        <Flex p={2} alignItems="center" justifyContent="space-between">
          {tools ? tools(filterValue) : <span />}
          <SelectFilter {...this.props} selectState={this.selectState} />
        </Flex>
        <Flex flex={1} overflow="hidden">
          {multi && (
            <Flex pl={2}>
              <ModalSelectValuesPanel {...this.props} noRemove={false} selectState={this.selectState} />
            </Flex>
          )}
          <ModalOptions {...this.props} selectState={this.selectState} />
        </Flex>
      </Flex>
    );
  }
}

export default ModalSelect;
