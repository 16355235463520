import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Button, ButtonGroup, Flex } from 'core/components';
import { Field, Form, Select } from 'core/form';
import LookbackDateRange from 'core/form/components/LookbackDateRange';
import incidentLogFields from 'app/forms/config/synthetics/fields/incidentLogFields';
import { LabelMultiSelect } from 'app/components/labels/LabelMultiSelect';

const MIN_LOOKBACK_SECONDS = 300; // 5mins

@Form({
  fields: incidentLogFields,
  options: { name: 'Incident Log Form' }
})
@inject('$syn', '$labels')
@observer
export default class IncidentLogForm extends Component {
  static defaultProps = {
    minimal: false,
    onSave: () => null,
    onCancel: () => null
  };

  constructor(props) {
    super(props);

    const { $syn, $labels, form, model } = props;

    // initialize the form field options
    form.getField('test_ids').options = $syn.tests.testNamesOptions;
    form.getField('test_types').options = $syn.tests.userConfiguredTestTypeOptions;
    form.getField('labels').options = $labels.getLabels('synth_test').map((label) => label.option);
    form.getField('private_agents').options = $syn.agents.privateAgentOptions;
    form.getField('global_agents').options = $syn.agents.globalAgentOptions;

    form.setModel(model);
  }

  handleDateRangeChange = (datesOrLookback) => {
    const { form } = this.props;
    form.setValue('selected_time', datesOrLookback);
  };

  handleCancel = () => {
    const { form, onCancel } = this.props;
    onCancel(form);
  };

  handleSave = () => {
    const { form, onSave } = this.props;
    onSave(form);
  };

  get boxProps() {
    const {
      $syn,
      $labels,
      form,
      model,
      error,
      handleCancel,
      handleRemove,
      handleSave,
      minimal,
      onCancel,
      onSave,
      ...boxProps
    } = this.props;
    return boxProps;
  }

  get minimalForm() {
    const { form } = this.props;
    const selectedTime = form.getValue('selected_time');

    return (
      <Flex alignItems="center" gap={1} {...this.boxProps}>
        <Flex alignItems="center" gap={1}>
          <LookbackDateRange
            startDate={selectedTime.startDate}
            endDate={selectedTime.endDate}
            minLookbackSeconds={MIN_LOOKBACK_SECONDS}
            lookbackSeconds={selectedTime.lookbackSeconds}
            onChange={this.handleDateRangeChange}
            minimal
          />
          <Field name="severity" showLabel={false} mb={0}>
            <Select inlineLabel menuWidth={75} />
          </Field>
          <Field name="state" showLabel={false} mb={0}>
            <Select inlineLabel menuWidth={75} />
          </Field>
        </Flex>
        <ButtonGroup>
          <Button icon="small-cross" onClick={this.handleCancel} small />
          <Button icon="small-tick" intent="primary" disabled={!form.valid} onClick={this.handleSave} small />
        </ButtonGroup>
      </Flex>
    );
  }

  render() {
    const { form, minimal } = this.props;
    const selectedTime = form.getValue('selected_time');

    if (minimal) {
      return this.minimalForm;
    }

    return (
      <Flex flexDirection="column" gap={1} {...this.boxProps}>
        <Box mb={3}>
          <LookbackDateRange
            startDate={selectedTime.startDate}
            endDate={selectedTime.endDate}
            minLookbackSeconds={MIN_LOOKBACK_SECONDS}
            lookbackSeconds={selectedTime.lookbackSeconds}
            onChange={this.handleDateRangeChange}
          />
        </Box>
        <Field name="severity" large>
          <Select />
        </Field>
        <Field name="state" large>
          <Select />
        </Field>
        <Field name="test_ids" large>
          <Select fill multi keepOpen toggle showFilter />
        </Field>
        <Field name="test_types" large>
          <Select fill multi keepOpen toggle showFilter />
        </Field>
        <Field name="labels" large>
          <LabelMultiSelect />
        </Field>
        <Field name="private_agents" large>
          <Select fill multi showFilter />
        </Field>
        <Field name="global_agents" large>
          <Select fill multi showFilter />
        </Field>
        <Flex justifyContent="flex-end" gap={1}>
          <Button text="Cancel" onClick={this.handleCancel} minWidth={100} />
          <Button text="Save" intent="primary" disabled={!form.valid} onClick={this.handleSave} minWidth={100} />
        </Flex>
      </Flex>
    );
  }
}
