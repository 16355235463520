import React, { Component } from 'react';
import { inject } from 'mobx-react';

import Page from 'app/components/page/Page';
import InsightsMenu from 'app/components/navbar/InsightsMenu';
import { OnboardingWarning } from 'app/views/core/onboardingWarning';

import ExportCoverPage from 'app/views/core/ExportCoverPage';
import NetworkExplorerTraffic from './NetworkExplorerTraffic';
import ObservationDeckInterstitial from './ObservationDeckInterstitial';

@inject('$app')
class NetworkExplorer extends Component {
  render() {
    const { $app } = this.props;
    return (
      <>
        {$app.isExport && <ExportCoverPage title="Network Explorer" />}
        <Page
          title="Network Explorer"
          insightsMenu={<InsightsMenu query="fetchNetworkExplorerInsights" />}
          scrolls
          showExport
        >
          {!$app.isExport && <OnboardingWarning />}

          <NetworkExplorerTraffic />

          <ObservationDeckInterstitial />
        </Page>
      </>
    );
  }
}

export default NetworkExplorer;
