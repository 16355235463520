import React, { Component } from 'react';

import ShareViewDialog from 'app/views/core/ShareViewDialog';

export default class SubscribeDialog extends Component {
  render() {
    const { subscriptionDialogType, ...props } = this.props;
    return <ShareViewDialog hideLink hideEmail subscriptionDialogType={subscriptionDialogType} {...props} />;
  }
}
