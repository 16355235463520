import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { getOutsortAggregateUnit } from 'app/forms/config/bracketOptions';
import { uniqueId } from 'lodash';
import { Box, Flex, Tag, Text } from 'core/components';
import { generatorMap } from 'app/util/tagging/tagEngine';
import { getRangeMetadata } from 'app/util/bracketing';
import { formConsumer } from 'core/form';

@inject('$explorer', '$dictionary')
@formConsumer
@observer
class StaticRangesDisplay extends Component {
  static defaultProps = {
    fieldName: 'bracketOptions'
  };

  render() {
    const { form, fieldName } = this.props;

    const units = getOutsortAggregateUnit(form.model.toJS());
    const fieldValue = form.getValue(fieldName);
    const rangeMetadata = getRangeMetadata({
      bracketOptions: Object.assign(fieldValue, { bracketAggregationUnit: units })
    });
    const bracketType = fieldValue ? fieldValue.type : 'N/A';
    const displayName = generatorMap[bracketType] ? generatorMap[bracketType].display : 'N/A';
    const ranges = rangeMetadata.metaRanges.map((metaRange, index) => {
      // metaRanges include all ranges plus the "over" range, the field ranges are just user input ranges (one less), so account for that.
      const backgroundColor =
        index === fieldValue.ranges.length ? fieldValue.over : fieldValue.ranges[index].data.value;
      return (
        <Box key={uniqueId()} mb={1}>
          <div>
            <Tag minimal style={{ backgroundColor, width: '100%', textAlign: 'left' }}>
              <Text color="#ffffff">{metaRange.bracketLabel}</Text>
            </Tag>
          </div>
        </Box>
      );
    });

    return (
      <Flex flexDirection="column">
        <Box flex={1} mb={1}>
          <Text small>{displayName}:</Text>
        </Box>
        <Box>{ranges}</Box>
      </Flex>
    );
  }
}

export default StaticRangesDisplay;
