import { MeterProvider } from '@opentelemetry/sdk-metrics';
import { JsonMetricExporter } from './uiMetricsExporter';

let oneMeter;
let authStore;

// for repeated start/stop with the same object.
class HistogramProxy {
  constructor({ histogram, includeCid = false }) {
    this._histogram = histogram;
    this._startTime = Date.now();
    this._includeCid = includeCid;
  }

  start() {
    this._startTime = Date.now();
  }

  end(attributes = {}) {
    if (this._histogram && this._startTime > 0) {
      const duration = (Date.now() - this._startTime) / 1000;
      const labels = { ...attributes };
      if (this._includeCid) {
        labels.company_id = authStore?.companyId;
      }
      this._histogram.record(duration, labels);
    }
    this._startTime = 0;
  }
}

class MeterProxy {
  constructor({ meter, prefix }) {
    this.meter = meter;
    this.prefix = prefix;
  }

  _validate({ name, description }) {
    if (!name || !description) {
      throw new Error('Validation Error: missing name or description');
    }
    return true;
  }

  createHistogram(options) {
    this._validate(options);
    const { name, description } = options;
    const h1 = this.meter.createHistogram(`${this.prefix}${name}`, { description });
    return new HistogramProxy({ histogram: h1 });
  }

  createCounter(options) {
    this._validate(options);
    const { name, description } = options;
    const c1 = this.meter.createCounter(`${this.prefix}${name}`, { description });
    return (attributes = {}, v = 1) => {
      const labels = { ...attributes };
      if (options.includeCid) {
        labels.company_id = authStore?.companyId;
      }
      c1.add(v, labels);
    };
  }
}

export function createHistogram(options) {
  return oneMeter?.createHistogram(options) || new HistogramProxy({});
}

export function createCounter(options) {
  const noOp = () => {};
  return oneMeter?.createCounter(options) || noOp;
}

export function initializeMetrics(options = { prefix: 'uiBrowser_' }) {
  if (!oneMeter) {
    const meterProvider = new MeterProvider();
    const reader = new JsonMetricExporter({});
    meterProvider.addMetricReader(reader);
    const meter = meterProvider.getMeter('uiBrowser');
    oneMeter = new MeterProxy({ ...options, meter });
  }
  authStore = options.$auth;
  return oneMeter;
}
