import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { PopoverInteractionKind, Position } from '@blueprintjs/core';

import { Box, Button, Callout, Flex, Heading, Icon, Popover, Tab, Tabs, Text } from 'core/components';
import FiltersDisplay from 'app/components/filters/display/FiltersDisplay';

import LegendTable from './LegendTable';
import FpaResult from '../FpaResult';

const ExportBox = styled.div`
  & + & {
    margin-top: 64px;
  }

  @media print {
    .break-before {
      break-before: page;
    }
  }
`;

const TabTitle = styled(Flex)`
  align-items: center
    ${({ hasTwoLines }) => {
      if (hasTwoLines) {
        return `
        line-height: 20px;
        text-align: left;
      `;
      }

      return `
      line-height: inherit;
      text-align: center;
    `;
    }};
`;

@inject('$app', '$dictionary')
@observer
export default class Legend extends Component {
  state = {
    selectedTabId: '', // Default empty tab selection
    selectedKeys: [],
    visibleKeys: [],
    isFirstLoad: true // Track first load
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { dataview } = nextProps;
    const { activeBuckets } = dataview.queryBuckets;

    if (prevState.isFirstLoad) {
      if (dataview.useFpa) {
        return {
          selectedTabId: 'fpa',
          selectedKeys: [],
          visibleKeys: [],
          isFirstLoad: false
        };
      }

      if (activeBuckets.length > 0) {
        return {
          selectedTabId: activeBuckets[0].get('name'),
          selectedKeys: [],
          visibleKeys: [],
          isFirstLoad: false
        };
      }

      return {
        selectedTabId: 'Left +X Axis',
        selectedKeys: [],
        visibleKeys: []
      };
    }

    return null;
  }

  componentDidUpdate() {
    const { isViewCmp, dataview } = this.props;
    const { loading } = dataview;

    if (isViewCmp) {
      dataview.component = this;

      if (!loading) {
        dataview.setFullyLoaded();
      }
    }
  }

  handleTabChange = (tabId) => {
    const { dataview } = this.props;
    const { activeBuckets } = dataview.queryBuckets;

    this.setState({ selectedTabId: tabId });

    if (tabId === 'compare') {
      dataview.bucketTab = activeBuckets.length > 0 ? activeBuckets[0].get('name') : 'Left +X Axis';
    } else {
      dataview.bucketTab = tabId;
    }
  };

  handleCancelFpaAnalysis = () => {
    const { dataview } = this.props;
    const explorerQueryModel = dataview.createExplorerQueryModelFromSelectedQuery();

    explorerQueryModel.set({
      use_fpa: false,
      fpa: {
        type: 'cpd_fpa'
      }
    });

    dataview.applyToSelectedQuery(explorerQueryModel, false);
  };

  renderTabTitle(bucket) {
    const { $dictionary } = this.props;
    const name = bucket.get('name');
    const { firstQuery } = bucket;
    const mirror = firstQuery.get('mirror');
    const mirrored = firstQuery.get('mirrored');
    const secondaryOutsort = firstQuery.get('secondaryOutsort');
    const isSecondary = firstQuery.get('isSecondary');
    const metric = firstQuery.get('metric');
    const filters = firstQuery.get('filters');
    const unit = firstQuery.outsortUnit;
    const periodOverPeriod = firstQuery.get('period_over_period');
    const periodOverPeriodLookback = firstQuery.get('period_over_period_lookback');
    const periodOverPeriodLookbackUnit = firstQuery.get('period_over_period_lookback_unit');
    const isPreviousPeriod = bucket.get('isPreviousPeriod');
    const hasTwoLines = mirror || secondaryOutsort || isSecondary;

    return (
      <TabTitle hasTwoLines={hasTwoLines} px={2} py={1}>
        <span style={{ paddingRight: 4 }}>
          {mirror && !mirrored && (
            <Text as="div" fontWeight="bold">
              Original Query
            </Text>
          )}
          {mirrored && (
            <Text as="div" fontWeight="bold">
              Opposite Query
            </Text>
          )}
          {(secondaryOutsort || isSecondary) && (
            <Text as="div" fontWeight="bold">
              {$dictionary.get('units')[unit]}
            </Text>
          )}
          {hasTwoLines && (
            <Text muted small>
              {name}
            </Text>
          )}
          {periodOverPeriod && !isPreviousPeriod && <Text fontWeight="bold">{bucket.label}</Text>}
          {periodOverPeriod && isPreviousPeriod && (
            <>
              <Text fontWeight="bold">{name}</Text>
              <Text ml={1} muted small>
                {periodOverPeriodLookback} {periodOverPeriodLookbackUnit}
                {periodOverPeriodLookback > 1 ? 's' : ''} back
              </Text>
            </>
          )}
          {!mirror && !secondaryOutsort && !isSecondary && !periodOverPeriod && name}
        </span>
        {isSecondary && (
          <Popover
            content={
              <Box maxWidth={500} p={2}>
                <Heading level={5}>Secondary Metrics</Heading>
                <Callout intent="primary" mt={1}>
                  This tab&#39;s results were generated using your selected Secondary Metric --{' '}
                  <strong>{$dictionary.get('units')[unit]}</strong> -- and the resulting flow data was chart for direct
                  chart for direct comparison.
                </Callout>
              </Box>
            }
            position={Position.TOP}
            interactionKind={PopoverInteractionKind.HOVER}
          >
            <Icon icon="comparison" />
          </Popover>
        )}
        {mirrored && (
          <Popover
            content={
              <Box maxWidth={500} p={2}>
                <Heading level={5}>Bi-directional Charting</Heading>
                <Callout intent="primary" mt={1}>
                  This tab&#39;s results were generated by automatic bi-directional charting. When charting is enabled,
                  it takes your original settings from the left, swaps any Dimensions, Metrics, and/or Filters you have
                  with their native opposite (Source vs. Destination) ones, and plots the resulting flow data on your
                  chart for direct comparison. A summary of your resultant settings can be seen below:
                </Callout>
                <Box mt={1}>
                  <Text muted>Opposite Group By Dimensions: </Text>
                  {metric.length === 0 && <Text fontWeight="bold">None</Text>}
                  {metric.map((dimension) => (
                    <Box key={dimension} pl={1}>
                      <Text fontWeight="bold">{$dictionary.get('chartTypesValidations')[dimension]}</Text>
                    </Box>
                  ))}
                </Box>
                <Box mt={1}>
                  <Text muted>Opposite Metric: </Text>
                  <Text fontWeight="bold">{$dictionary.get('units')[unit]}</Text>
                </Box>
                <Box mt={1}>
                  <Text muted>Opposite Filters: </Text>
                  <Box mt={1} pl={1}>
                    <FiltersDisplay filters={filters} />
                  </Box>
                </Box>
              </Box>
            }
            position={Position.TOP}
            interactionKind={PopoverInteractionKind.HOVER}
          >
            <Icon icon="swap-horizontal" />
          </Popover>
        )}
      </TabTitle>
    );
  }

  render() {
    const { $app, dataview } = this.props;
    const { selectedKeys, visibleKeys, selectedTabId } = this.state;
    const { activeBucketCount, activeBuckets, hasPeriodOverPeriod, fullyLoaded } = dataview.queryBuckets;
    const loading = hasPeriodOverPeriod ? !fullyLoaded : dataview.loading;

    if (activeBucketCount > 1 || dataview.useFpa) {
      if ($app.isExport) {
        return (
          <Box>
            {activeBuckets.map((bucket) => (
              <ExportBox key={bucket.get('name')}>
                <Heading level={5} mb={2}>
                  {bucket.get('name')}
                </Heading>
                <LegendTable
                  bucket={bucket}
                  loading={loading}
                  selectedKeys={selectedKeys}
                  visibleKeys={visibleKeys}
                  {...this.props}
                />
              </ExportBox>
            ))}
            {hasPeriodOverPeriod && (
              <ExportBox key="compare">
                <Heading level={5} mb={2}>
                  Comparison Summary
                </Heading>
                <LegendTable
                  bucket={activeBuckets[0]}
                  loading={loading}
                  selectedKeys={selectedKeys}
                  visibleKeys={visibleKeys}
                  isComparisonSummary
                  {...this.props}
                />
              </ExportBox>
            )}
          </Box>
        );
      }

      return (
        <Tabs
          width="100%"
          display="flex"
          bordered
          flexDirection="column"
          onChange={this.handleTabChange}
          selectedTabId={selectedTabId}
        >
          {activeBuckets.map((bucket) => {
            const name = bucket.get('name');

            return (
              <Tab
                key={name}
                id={name}
                className="legend-tab"
                title={this.renderTabTitle(bucket)}
                panel={
                  <Flex flex={1} p={0} borderRadius={0}>
                    <LegendTable
                      bucket={bucket}
                      loading={loading}
                      selectedKeys={selectedKeys}
                      visibleKeys={visibleKeys}
                      {...this.props}
                    />
                  </Flex>
                }
              />
            );
          })}
          {hasPeriodOverPeriod && (
            <Tab
              key="compare"
              id="compare"
              className="legend-tab"
              title={
                <TabTitle px={2} py={1}>
                  <Text fontWeight="bold">Comparison Summary</Text>
                </TabTitle>
              }
              panel={
                <Flex flex={1}>
                  <LegendTable
                    bucket={activeBuckets[0]}
                    loading={loading}
                    selectedKeys={selectedKeys}
                    visibleKeys={visibleKeys}
                    isComparisonSummary
                    {...this.props}
                  />
                </Flex>
              }
            />
          )}
          {dataview.useFpa && (
            <Tab
              key="fpa"
              id="fpa"
              className="legend-tab"
              title={
                <TabTitle px={2} py={1} gap="12px">
                  <Text fontWeight="bold">Probable Cause</Text>
                  <Button minimal icon="cross" onClick={this.handleCancelFpaAnalysis} />
                </TabTitle>
              }
              panel={
                <Flex flexDirection="column" flex={1} overflow="auto">
                  <FpaResult dataview={dataview} />
                </Flex>
              }
            />
          )}
        </Tabs>
      );
    }

    return (
      <LegendTable
        bucket={activeBuckets[0]}
        loading={dataview.loading}
        selectedKeys={selectedKeys}
        visibleKeys={visibleKeys}
        {...this.props}
      />
    );
  }
}

const type = 'legendTable';
const config = {
  showTotalTrafficOverlay: true,
  showHistoricalOverlay: false,
  timeBased: false,
  isSVG: false,
  showLegend: false,
  buckets: [
    {
      name: 'Table'
    }
  ]
};

export { type, config };
