import React, { Component } from 'react';
import classNames from 'classnames';

import Button from 'core/components/Button';

class ModalOptionGroupTitle extends Component {
  static defaultProps = {
    allowSelectAll: true,
    collapsible: true,
    collapsed: false
  };

  shouldComponentUpdate(nextProps) {
    const { collapsed, disabled } = this.props;
    return collapsed !== nextProps.collapsed || disabled !== nextProps.disabled;
  }

  render() {
    const { disabled, collapsed, collapsible, onTitleClick, group, multi, allowSelectAll, onCollapse } = this.props;

    const className = classNames('modal-options-group-title', { 'pt-disabled': disabled, collapsed });

    return (
      <div className={className}>
        {collapsible && (
          <Button
            icon={collapsed ? 'caret-right' : 'caret-down'}
            onClick={() => onCollapse(group)}
            mr="4px"
            small
            minimal
          />
        )}
        <span className="title">{group}</span>
        {multi && allowSelectAll && !collapsed && (
          <Button text="Select All" onClick={onTitleClick} disabled={disabled} ml={1} intent="primary" small minimal />
        )}
      </div>
    );
  }
}

export default ModalOptionGroupTitle;
