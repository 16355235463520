import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Card, Text } from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import VerticalDetailTabs from 'app/components/detailTabs/VerticalDetailTabs';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import Page from 'app/components/page/Page';
import InsightsMenu from 'app/components/navbar/InsightsMenu';

import AggregateViewHeader from '../aggregate/AggregateViewHeader';
import parentLinks from '../NetworkExplorerParentLink';

@inject('$lookups', '$tabs')
@storeLoader('$sites')
@observer
class ApplicationDetails extends Component {
  constructor(props) {
    super(props);

    const { $tabs } = props;
    const tabs = $tabs.getTabs();

    this.state.tabBlacklist = [tabs.APPLICATIONS.id];
  }

  state = {
    appDetails: undefined,
    filters: [],
    lookback_seconds: 86400,
    aggregateType: 'p95th_bits_per_sec'
  };

  componentDidMount() {
    this.fetchAppDetails();
  }

  componentDidUpdate(prevProps) {
    if (this.application !== prevProps.match.params.application) {
      this.fetchAppDetails();
    }
  }

  fetchAppDetails() {
    const { $lookups } = this.props;
    $lookups.applications(this.application).then((results) => {
      this.setState({ appDetails: results.find((result) => result.value === this.application) });
    });
  }

  get application() {
    const { match } = this.props;
    return decodeURIComponent(match.params.application);
  }

  get filters() {
    const { filters } = this.state;
    const detailFilter = {
      filterField: 'application',
      filterValue: this.application
    };

    return filters.concat(detailFilter);
  }

  handleFiltersApply = (filters) => {
    this.setState({ filters });
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds });
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType });
  };

  render() {
    const { appDetails, aggregateType, filters, lookback_seconds, tabBlacklist } = this.state;

    const title = (
      <>
        {this.application}
        {appDetails && appDetails !== this.application && (
          <Text as="div" fontSize={14} fontWeight="normal">
            {appDetails.matcher}
          </Text>
        )}
      </>
    );

    return (
      <Page
        title={this.application}
        parentLinks={[...parentLinks, { link: '/v4/core/quick-views/application', label: 'Applications' }]}
        insightsMenu={<InsightsMenu query="fetchApplicationInsights" params={this.application} />}
        scrolls
        showExport
      >
        <AggregateViewHeader
          title={title}
          aggregateType={aggregateType}
          filters={filters}
          lookbackSeconds={lookback_seconds}
          onFiltersChange={this.handleFiltersApply}
          onAggregateChange={this.handleAggregationChange}
          onMetricsChange={this.handleAggregationChange}
          onTimeRangeChange={this.handleLookbackChange}
        />

        <Card className="break-after" mb={2}>
          <TabbedChart overrides={{ lookback_seconds, aggregateTypes: [aggregateType] }} simpleFilters={this.filters} />
        </Card>

        <VerticalDetailTabs
          blacklist={tabBlacklist}
          simpleFilters={this.filters}
          queryOverrides={{ lookback_seconds, aggregateTypes: [aggregateType] }}
        />
      </Page>
    );
  }
}

export default ApplicationDetails;
