import React, { Component } from 'react';
import { withSize } from 'react-sizeme';
import { withTheme } from 'styled-components';
import { Stage } from 'react-konva/lib/ReactKonvaCore'; // https://github.com/konvajs/react-konva#minimal-bundle
import { isEmpty } from 'lodash';
import { Box, Flex } from 'core/components';
import getScrollbarWidth from 'core/util/getScrollbarWidth';
import {
  CELL_MARGIN,
  DENSITY,
  getDensityCellSize,
  getDensityFromMeshSize
} from 'app/views/synthetics/components/mesh/util/constants';
import { buildMesh } from 'app/views/synthetics/components/mesh/util/utils';
import TextLayer from './layers/TextLayer';
import CellLayer from './layers/CellLayer';
import HoverLayer from './layers/HoverLayer';
import MeshPopover from './MeshPopover';
import Toolbar from './toolbar/Toolbar';
import HeaderCellPopover from './HeaderCellPopover';

const DEFAULT_HOVERED_POSITION = { left: -100, top: -100 };

@withSize()
@withTheme
export default class AgentMesh extends Component {
  static defaultProps = {
    centered: true
  };

  state = {
    density: DENSITY.LOW,
    filteredMetrics: ['latency', 'jitter', 'loss'],
    mesh: [],
    meshCount: 0,
    resultTimeMs: 0,
    hoveredCell: false,
    hoveredPosition: DEFAULT_HOVERED_POSITION,
    hoveredHeaderCellPopoverProperties: { type: 'row', boxProperties: {}, agentDetails: {} },
    hoveredRow: 0,
    hoveredCol: 1,
    highlightedRow: null,
    highlightedCol: null,
    highlightedRowAgent: '',
    highlightedColAgent: ''
  };

  scrollContainer = React.createRef();

  rowLabelStage = React.createRef();

  colLabelStage = React.createRef();

  constructor(props) {
    super(props);

    const { test } = props;
    if (!!test && !isEmpty(test.results.meshState)) {
      // eslint-disable-next-line react/state-in-constructor
      this.state = {
        ...this.state,
        ...test.results.meshState
      };
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { data, resultTimeMs, meshCount } = props;
    const { filteredMetrics } = state;

    if (data) {
      const timeChanged = resultTimeMs !== state.resultTimeMs;
      const dataChanged = meshCount !== state.meshCount;

      if (timeChanged || dataChanged) {
        return {
          mesh: buildMesh(data, resultTimeMs, filteredMetrics),
          resultTimeMs,
          meshCount,
          hoveredCell: false
        };
      }
    }

    return null;
  }

  componentDidMount() {
    const { test } = this.props;
    const { mesh } = this.state;

    if (!test?.results.meshState.density) {
      this.setState({ density: getDensityFromMeshSize(mesh) });
    }
  }

  get size() {
    const { size } = this.props;
    const { mesh, density } = this.state;
    const { labelWidth: LABEL_WIDTH } = density;

    const cellSize = getDensityCellSize(density);
    const fullSize = LABEL_WIDTH + CELL_MARGIN + mesh.length * (cellSize + CELL_MARGIN);
    const visibleWidth = Math.min(size.width, fullSize);
    const visibleHeight = Math.min(size.height ? size.height : 0.75 * window.innerHeight, fullSize);

    const scrollsX = fullSize > visibleWidth;
    const scrollsY = fullSize > visibleHeight;

    return {
      fullSize, // width and height of the entire mesh
      visibleWidth, // width of mesh container
      visibleHeight, // height of mesh container
      scrolls: scrollsX || scrollsY,
      scrollsX,
      scrollsY,
      cellSize
    };
  }

  handleWheel = (evt) => {
    if (this.size.scrolls) {
      const { deltaX, deltaY } = evt;
      evt.preventDefault();

      this.scrollContainer.current.scrollTop += deltaY;
      this.scrollContainer.current.scrollLeft += deltaX;
    }
  };

  handleScroll = (evt) => {
    const { scrollLeft, scrollTop } = evt.target;
    this.colLabelStage.current.container().style.transform = `translateX(${-scrollLeft}px)`;
    this.rowLabelStage.current.container().style.transform = `translateY(${-scrollTop}px)`;
    clearTimeout(this.cellMouseOverTimeout);
  };

  handleCellMouseOver = (target, hoveredRow, hoveredCol) => {
    target.getStage().container().style.cursor = 'pointer';

    this.cellMouseOverTimeout = setTimeout(() => {
      const { x: left, y: top } = target.absolutePosition();
      this.setState({
        hoveredPosition: { left: left - CELL_MARGIN, top: top - CELL_MARGIN },
        hoveredRow,
        hoveredCol
      });
    }, 20);
  };

  handleCellMouseOut = (target) => {
    clearTimeout(this.cellMouseOverTimeout);
    target.getStage().container().style.cursor = 'default';
  };

  handlePopoverOpen = () => {
    this.setState({ hoveredCell: true });
  };

  handlePopoverClose = () => {
    this.setState({ hoveredCell: false });
  };

  handleToolbarChange = ({
    density,
    filteredMetrics: nextFilteredMetrics,
    highlightedRow,
    highlightedCol,
    highlightedRowAgent,
    highlightedColAgent
  }) => {
    const { data, resultTimeMs, test } = this.props;
    const { filteredMetrics } = this.state;
    const nextState = {
      density,
      filteredMetrics: nextFilteredMetrics,
      highlightedRow,
      highlightedCol,
      highlightedRowAgent,
      highlightedColAgent,
      hoveredPosition: DEFAULT_HOVERED_POSITION // reset the hovered position so it doesn't render unnecessary scrollbars when changing densities
    };

    if (test) {
      test.results.meshState = { ...nextState };
    }

    if (nextFilteredMetrics.length !== filteredMetrics.length) {
      nextState.mesh = buildMesh(data, resultTimeMs, nextFilteredMetrics);
    }

    this.setState(nextState);
  };

  handleHeaderCellMouseOver = ({ type, target, popoverProperties }) => {
    target.getStage().container().style.cursor = 'pointer';

    const { boxProperties, agentDetails } = popoverProperties;
    const { scrollLeft, scrollTop } = this.scrollContainer.current;

    this.setState({
      hoveredHeaderCellPopoverProperties: {
        type,
        agentDetails,
        boxProperties: {
          ...boxProperties,
          // we might be scrolled so account for that in the left/top calculations
          left: type === 'row' ? boxProperties.left : boxProperties.left - scrollLeft,
          top: type === 'col' ? boxProperties.top : boxProperties.top - scrollTop
        }
      }
    });
  };

  handleHeaderCellMouseOut = ({ target }) => {
    target.getStage().container().style.cursor = 'default';
  };

  /*
    Determines aggregation state for the purposes of health coloring logic
    This will primarily come from test.results (TestResultsState) but it could also come from a library widget (TestResultsStateByTimestamp)
    In the case of the library widget, we pass it as an overridden prop (isAggregated)
  */
  get isAggregated() {
    const { test, isAggregated } = this.props;
    return test.results.isAggregated || isAggregated;
  }

  render() {
    const {
      data,
      theme,
      size,
      lookbackSeconds,
      startDate,
      endDate,
      test_id: testId,
      heading,
      mr,
      ml,
      mx,
      my,
      allowAgentDetails,
      test,
      centered,
      ...rest
    } = this.props;
    const {
      density,
      filteredMetrics,
      mesh,
      resultTimeMs,
      hoveredCell,
      hoveredPosition,
      hoveredHeaderCellPopoverProperties,
      hoveredRow,
      hoveredCol,
      meshCount,
      highlightedRow,
      highlightedCol,
      highlightedRowAgent,
      highlightedColAgent
    } = this.state;
    const { fullSize, visibleWidth, visibleHeight, scrollsX, scrollsY, cellSize } = this.size;
    const { labelWidth: LABEL_WIDTH } = density;

    const colLabelWidth = visibleWidth - LABEL_WIDTH - (scrollsY ? getScrollbarWidth() : 0);
    const rowLabelHeight = visibleHeight - LABEL_WIDTH - (scrollsX ? getScrollbarWidth() : 0);
    const offset = (parseInt(mr) || 0) + (parseInt(ml) || 0);

    const { type: headerCellType, boxProperties, agentDetails } = hoveredHeaderCellPopoverProperties;

    return (
      <Flex flexDirection="column" flex={1}>
        <Toolbar
          mesh={mesh}
          density={density}
          metrics={filteredMetrics}
          highlightedRow={highlightedRow}
          highlightedCol={highlightedCol}
          highlightedRowAgent={highlightedRowAgent}
          highlightedColAgent={highlightedColAgent}
          onToolbarChange={this.handleToolbarChange}
        />
        <Flex
          flexDirection="column"
          alignSelf={centered ? 'center' : 'flex-start'}
          maxWidth={offset ? `calc(100% - ${offset}px)` : '100%'}
          mr={mr}
          ml={ml}
          mx={mx} // 'auto' will break size.width expanding on resize
          my={my}
        >
          {heading}
          <Box position="relative" width={visibleWidth} height={visibleHeight} {...rest}>
            {/* Column labels */}
            <Box
              position="absolute"
              top={0}
              left={LABEL_WIDTH}
              width={colLabelWidth + LABEL_WIDTH}
              height={LABEL_WIDTH}
              style={{ clipPath: `polygon(${LABEL_WIDTH}px 0, 100% 0, ${colLabelWidth}px 100%, 0 100%)` }}
              overflow="hidden"
            >
              <Stage
                ref={this.colLabelStage}
                x={CELL_MARGIN}
                width={fullSize}
                height={LABEL_WIDTH}
                onWheel={(e) => this.handleWheel(e.evt)}
              >
                <TextLayer
                  density={density}
                  mesh={mesh}
                  theme={theme}
                  onHeaderCellMouseOver={this.handleHeaderCellMouseOver}
                  onHeaderCellMouseOut={this.handleHeaderCellMouseOut}
                />
              </Stage>
            </Box>

            {/* Row labels */}
            <Box
              position="absolute"
              top={LABEL_WIDTH}
              left={0}
              width={LABEL_WIDTH}
              height={rowLabelHeight}
              overflow="hidden"
            >
              <Stage
                ref={this.rowLabelStage}
                y={CELL_MARGIN}
                width={LABEL_WIDTH}
                height={fullSize}
                onWheel={(e) => this.handleWheel(e.evt)}
              >
                <TextLayer
                  density={density}
                  mesh={mesh}
                  theme={theme}
                  onHeaderCellMouseOver={this.handleHeaderCellMouseOver}
                  onHeaderCellMouseOut={this.handleHeaderCellMouseOut}
                  vertical
                />
              </Stage>
            </Box>

            {/* Mesh content */}
            <Box
              ref={this.scrollContainer}
              position="absolute"
              left={LABEL_WIDTH + CELL_MARGIN / 2}
              top={LABEL_WIDTH + CELL_MARGIN / 2}
              width={visibleWidth - (LABEL_WIDTH + CELL_MARGIN / 2) + (scrollsY && !scrollsX ? getScrollbarWidth() : 0)}
              height={
                visibleHeight - (LABEL_WIDTH + CELL_MARGIN / 2) + (scrollsX && !scrollsY ? getScrollbarWidth() : 0)
              }
              overflow="auto"
              onScroll={this.handleScroll}
            >
              <Stage
                x={CELL_MARGIN / 2}
                y={CELL_MARGIN / 2}
                width={fullSize - (LABEL_WIDTH + CELL_MARGIN / 2)}
                height={fullSize - (LABEL_WIDTH + CELL_MARGIN / 2)}
                onWheel={(e) => this.handleWheel(e.evt)}
              >
                {/* Static layer, used for cell event handling */}
                <CellLayer
                  density={density}
                  filteredMetrics={filteredMetrics}
                  mesh={mesh}
                  resultTimeMs={resultTimeMs}
                  theme={theme}
                  meshCount={meshCount}
                  isAggregated={this.isAggregated}
                  onCellMouseOver={this.handleCellMouseOver}
                  onCellMouseOut={this.handleCellMouseOut}
                />
                {/* Hovered and reverse path cells, drawn over cells on CellLayer */}
                <HoverLayer
                  density={density}
                  mesh={mesh}
                  theme={theme}
                  hoveredCell={hoveredCell}
                  hoveredRow={hoveredRow}
                  hoveredCol={hoveredCol}
                  highlightedRow={highlightedRow}
                  highlightedCol={highlightedCol}
                  isAggregated={this.isAggregated}
                />
              </Stage>
              {mesh.length > 0 && (
                <MeshPopover
                  key={`${hoveredRow}-${hoveredCol}`}
                  onOpen={this.handlePopoverOpen}
                  onClose={this.handlePopoverClose}
                  mesh={mesh}
                  row={hoveredRow}
                  col={hoveredCol}
                  lookbackSeconds={lookbackSeconds}
                  startDate={startDate}
                  endDate={endDate}
                  testId={testId}
                  allowAgentDetails={allowAgentDetails}
                  isAggregated={this.isAggregated}
                  targetProps={{
                    style: {
                      cursor: 'pointer',
                      position: 'absolute',
                      ...hoveredPosition,
                      width: cellSize + CELL_MARGIN,
                      height: cellSize + CELL_MARGIN
                    }
                  }}
                >
                  <div />
                </MeshPopover>
              )}
            </Box>

            <HeaderCellPopover
              key={`${boxProperties.top}-${boxProperties.left}`}
              type={headerCellType}
              boxProperties={boxProperties}
              agentDetails={agentDetails}
              position="left-top"
            />
          </Box>
        </Flex>
      </Flex>
    );
  }
}
