import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Flex, FlexColumn, Heading } from 'core/components';
import $rbac from 'app/stores/rbac/$rbac';
import { MenuLinks, MenuSpacer } from './NavbarMenu';

const NavbarSettings = inject('$auth')(
  observer(({ MenuSection, MenuLink, $auth, onCloseSection }) => (
    <FlexColumn className="nav-settings" flex={1} onMouseLeave={onCloseSection}>
      <Flex alignItems="center" justifyContent="space-between" mt="12px" mb={2}>
        <Heading level={4} color="kentik" m={0}>
          Settings
        </Heading>
        <Button icon="cross" minimal onClick={onCloseSection} />
      </Flex>
      <Flex>
        <FlexColumn flex={1} minWidth="min-content" flexBasis={180}>
          <MenuSection title="Data Sources">
            <MenuLinks>
              <MenuLink text="Networking Devices" to="/v4/infrastructure/devices?view=monitor" />
              <MenuLink text="Public Clouds" to="/v4/settings/clouds" />
            </MenuLinks>
          </MenuSection>
          <MenuSection title="Alerting">
            <MenuLinks>
              <MenuLink text="Alert Policies" to="/v4/protect/policies" />
              <MenuLink text="Alert Suppressions" to="/v4/settings/suppressions" />
              <MenuLink text="Auto-acknowledgements" to="/v4/settings/auto-ack" />
              <MenuLink text="Mitigations" to="/v4/settings/mitigations" />
              <MenuLink text="Notifications" to="/v4/settings/notifications" />
            </MenuLinks>
          </MenuSection>
        </FlexColumn>

        <MenuSpacer />

        <FlexColumn flex={1} minWidth="min-content" flexBasis={180}>
          <MenuSection title="Data Enrichment">
            <MenuLinks>
              <MenuLink text="AS Groups" to="/v4/settings/as-groups" />
              <MenuLink text="Custom Applications" to="/v4/settings/custom-applications" />
              <MenuLink text="Custom Dimensions" to="/v4/settings/custom-dimensions" />
              <MenuLink text="Custom Geos" to="/v4/settings/custom-geos" />
              <MenuLink text="Flow Tags" to="/v4/settings/flow-tags" />
            </MenuLinks>
          </MenuSection>
          <MenuSection title="Agents">
            <MenuLinks>
              <MenuLink text="Kproxy Agents" to="/v4/settings/kproxy-agents" />
              <MenuLink text="Synthetic Agents" to="/v4/synthetics/agents" />
              <MenuLink text="Universal Agents" to="/v4/settings/universal-agents" />
            </MenuLinks>
          </MenuSection>
        </FlexColumn>

        <MenuSpacer />

        <FlexColumn flex={1} minWidth="min-content" flexBasis={180}>
          <MenuSection title="Network Metadata">
            <MenuLinks>
              {$auth.isSudoer && <MenuLink text="Auto Classification" to="/v4/settings/auto-classification" />}
              {$rbac.hasUnrestrictedPermissions(['device::read']) && (
                <MenuLink text="Interface Classification" to="/v4/settings/interface-classification" />
              )}
              <MenuLink text="Labels" to="/v4/settings/labels" />
              <MenuLink text="Interfaces" to="/v4/settings/interfaces" />
              <MenuLink text="Sites" to="/v4/settings/sites" />
              <MenuLink text="Site Markets" to="/v4/settings/site-markets" />
              <MenuLink text="Network Classification" to="/v4/settings/network-classification" />
              <MenuLink text="Saved Filters" to="/v4/settings/saved-filters" />
            </MenuLinks>
          </MenuSection>
        </FlexColumn>
      </Flex>
    </FlexColumn>
  ))
);

export default NavbarSettings;
