import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { debounce, orderBy } from 'lodash';

import AdminFilterSidebar from 'app/components/admin/AdminFilterSidebar';
import { InputGroup } from 'core/form';
import storeLoader from 'app/stores/storeLoader';

const filterTypeHash = {
  site_id: {
    label: 'Site',
    placeholder: 'No sites selected',
    allowMultiSelect: true,
    showCheckboxes: false
  },
  label_id: {
    label: 'Device Label',
    placeholder: 'No device labels selected',
    allowMultiSelect: true,
    showCheckboxes: false
  },
  device_id: {
    label: 'Device',
    placeholder: 'No devices selected',
    allowMultiSelect: true,
    showCheckboxes: false
  },
  device_name: {
    label: 'Device Name',
    placeholder: 'No device name matched',
    helpText: 'Regular Expressions allowed',
    rules: 'regexSanitary',
    defaultValue: ''
  },
  admin_status: {
    label: 'Admin Status',
    defaultValue: '',
    options: [
      { value: '', label: 'All' },
      { value: 'up', label: 'Up' }
    ]
  },
  connectivity_type: {
    label: 'Connectivity Type',
    placeholder: 'No connectivity types selected',
    allowMultiSelect: true,
    showCheckboxes: true
  },
  network_boundary: {
    label: 'Network Boundary',
    placeholder: 'No network boundaries selected',
    allowMultiSelect: true,
    showCheckboxes: true
  },
  provider: {
    label: 'Provider',
    placeholder: 'No providers selected',
    allowMultiSelect: true,
    showCheckboxes: false
  },
  interface_description: {
    label: 'Interface Name',
    placeholder: 'No name matched',
    helpText: 'Regular Expressions allowed',
    rules: 'regexSanitary',
    defaultValue: ''
  },
  snmp_alias: {
    label: 'Interface Description',
    placeholder: 'No description matched',
    helpText: 'Regular Expressions allowed',
    rules: 'regexSanitary',
    defaultValue: ''
  },
  snmp_speed: {
    label: 'Capacity',
    placeholder: 'No capacity selected',
    allowMultiSelect: true,
    showCheckboxes: false
  },
  interface_type: {
    label: 'Interface Type',
    options: [
      { value: 'all', label: 'All Interfaces' },
      { value: 'logical', label: 'Logical Interfaces' },
      { value: 'physical', label: 'Physical Interfaces' }
    ],
    defaultValue: 'all'
  },
  interface_ip: {
    label: 'IP',
    placeholder: 'No IP / CIDR matched',
    rules: 'ip',
    defaultValue: ''
  }
};

@storeLoader('$sites')
@inject('$devices', '$dictionary', '$labels')
@observer
class DynamicInterfaceFilterSidebar extends Component {
  static defaultProps = {
    showSite: true,
    showCapacity: true,
    showInterfaceIp: true,
    showDeviceLabel: true,
    showProvider: true,
    showConnectivityType: true,
    showNetworkBoundary: true,
    showInterfaceType: true,
    showAdminStatus: false // NMS field
  };

  handleFilterChange = debounce((filters, form) => {
    const { collection, onFilterChange } = this.props;

    if (!form || !form.validate()) {
      return null;
    }

    const query = { includeSite: true };
    Object.keys(filters).forEach((type) => {
      const filter = filters[type];

      if (filters[type]) {
        query[type] = Array.isArray(filter) ? filters[type].join(',') : filter;
        if (query[type] === '') {
          delete query[type];
        }
      } else {
        delete filters[type];
      }
    });

    collection.fetch({ query });

    if (onFilterChange) {
      onFilterChange(filters);
    }
    return filters;
  }, 1000);

  get siteOptions() {
    const { $sites, collection } = this.props;

    if (!collection.filterOptions) {
      return [];
    }

    const options = collection.filterOptions.site_id
      .map((value) => {
        if (value === null) {
          return { value: '-', label: 'None' };
        }
        const site = $sites.collection.get(value);
        if (!site) {
          return null;
        }
        return { value: value.toString(), label: site.get('title') };
      })
      .filter((option) => option);

    return orderBy(options, ['label'], ['asc']);
  }

  get deviceOptions() {
    const { $devices, collection } = this.props;

    if (!collection.filterOptions) {
      return [];
    }

    const options = collection.filterOptions.device_id
      .map((value) => {
        const deviceSummary = $devices.deviceSummariesById[value];
        return deviceSummary ? { value, label: deviceSummary.device_name } : { value, label: '<missing device>' };
      })
      .filter((option) => option);

    return orderBy(options, ['label'], ['asc']);
  }

  get deviceLabelOptions() {
    const { $labels, collection } = this.props;

    if (!collection.filterOptions) {
      return [];
    }

    const options = collection.filterOptions.label_id
      .map((value) => {
        if (value === null) {
          return { value: '-', label: 'None' };
        }
        const label = $labels.labels.get(value);
        if (!label) {
          return null;
        }
        return { value, label: label.get('name'), bg: label.get('color') };
      })
      .filter((option) => option);

    return orderBy(options, ['label'], ['asc']);
  }

  get connectivityTypeOptions() {
    const { $dictionary, collection } = this.props;

    if (!collection.filterOptions) {
      return [];
    }

    const options = collection.filterOptions.connectivity_type
      .filter((value) => value)
      .map((value) => ({
        value,
        label: $dictionary.get(`interfaceClassification.connectivityTypes.${value}`)
      }));

    return orderBy(options, ['label'], ['asc']);
  }

  get networkBoundaryOptions() {
    const { $dictionary, collection } = this.props;

    if (!collection.filterOptions) {
      return [];
    }

    const options = collection.filterOptions.network_boundary
      .filter((value) => value)
      .map((value) => ({
        value,
        label: $dictionary.get(`interfaceClassification.networkBoundaryTypes.${value}`)
      }));

    return orderBy(options, ['label'], ['asc']);
  }

  get providerOptions() {
    const { collection } = this.props;

    if (!collection.filterOptions) {
      return [];
    }

    const options = collection.filterOptions.provider
      .filter((value) => value)
      .map((value) => ({ value, label: value }));

    return orderBy(options, ['label'], ['asc']);
  }

  get capacityOptions() {
    const { collection } = this.props;

    if (!collection.filterOptions) {
      return [];
    }

    const options = collection.filterOptions.snmp_speed
      .filter((value) => value)
      .map((value) => {
        const snmp_speed = parseInt(value);
        let label;
        label = `${snmp_speed}Mbits/s`;

        if (snmp_speed >= 1000) {
          label = `${snmp_speed / 1000}Gbits/s`;
        }

        return { value: parseInt(value), label };
      });

    return orderBy(options, ['value'], ['asc']);
  }

  render() {
    const {
      collection,
      showSite,
      showCapacity,
      showInterfaceIp,
      showDeviceLabel,
      showProvider,
      showConnectivityType,
      showInterfaceType,
      showNetworkBoundary,
      showAdminStatus,
      defaultValues = {}
    } = this.props;

    const filterFields = [
      {
        name: 'interface_description',
        ...filterTypeHash.interface_description,
        customField: <InputGroup helpertext={filterTypeHash.interface_description.helpText} />
      },
      {
        name: 'snmp_alias',
        ...filterTypeHash.snmp_alias,
        customField: <InputGroup helpertext={filterTypeHash.snmp_alias.helpText} />
      }
    ];

    if (showAdminStatus) {
      filterFields.push({ name: 'admin_status', ...filterTypeHash.admin_status });
    }

    if (showInterfaceIp) {
      filterFields.push({
        name: 'interface_ip',
        ...filterTypeHash.interface_ip,
        customField: <InputGroup />
      });
    }

    filterFields.push(
      {
        name: 'device_id',
        options: this.deviceOptions,
        ...filterTypeHash.device_id
      },
      {
        name: 'device_name',
        ...filterTypeHash.device_name,
        customField: <InputGroup helpertext={filterTypeHash.device_name.helpText} />
      }
    );

    if (showDeviceLabel) {
      filterFields.push({
        name: 'label_id',
        options: this.deviceLabelOptions,
        ...filterTypeHash.label_id
      });
    }

    if (showSite) {
      filterFields.push({
        name: 'site_id',
        options: this.siteOptions,
        ...filterTypeHash.site_id
      });
    }

    if (showCapacity) {
      filterFields.push({
        name: 'snmp_speed',
        options: this.capacityOptions,
        ...filterTypeHash.snmp_speed
      });
    }

    if (showInterfaceType) {
      filterFields.push({
        name: 'interface_type',
        ...filterTypeHash.interface_type
      });
    }

    if (showProvider) {
      filterFields.push({
        name: 'provider',
        options: this.providerOptions,
        ...filterTypeHash.provider
      });
    }

    if (showConnectivityType) {
      filterFields.push({
        name: 'connectivity_type',
        options: this.connectivityTypeOptions,
        validateOptions: false,
        ...filterTypeHash.connectivity_type
      });
    }

    if (showNetworkBoundary) {
      filterFields.push({
        name: 'network_boundary',
        options: this.networkBoundaryOptions,
        validateOptions: false,
        ...filterTypeHash.network_boundary
      });
    }

    return (
      <AdminFilterSidebar
        collection={collection}
        filterFields={filterFields}
        defaultValues={defaultValues}
        onFilterChange={this.handleFilterChange}
        title="Filters"
      />
    );
  }
}

export default DynamicInterfaceFilterSidebar;
