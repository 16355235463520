import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';

const NmsInterfaceLink = ({ $app, $devices, deviceId, deviceName: _deviceName, snmpId, children, ...rest }) => {
  if ($app.isSubtenant) {
    return children || snmpId;
  }
  const deviceName = _deviceName || $devices.deviceSummariesById[deviceId]?.device_name;
  return (
    <Link to={`/v4/infrastructure/devices/${deviceName}/interfaces/${snmpId}`} {...rest}>
      {children || snmpId}
    </Link>
  );
};

export default inject('$app', '$devices')(NmsInterfaceLink);
