/**
 * @typedef {'device' | 'credentials' | 'rbac.role' | 'connectivity_costs' | 'credential_vault' | 'dashboards' | 'saved_views' | 'synthetics.agent' | 'synthetics.test' | 'rbac.role.users' | 'rbac.permission' | 'rbac.role'} RBACType
 * */

/**
 * @typedef {'create' | 'read' | 'update' | 'delete'} RBACAction
 */

/**
 * @typedef {`${RBACType}::${RBACAction}` | 'rbac.role.user::assign_to_role' | 'rbac.role.user::remove_from_role'} RBACString
 */

/**
 * TODO WARNING: Unfortunately you can't add any more roles to this collection as the backend does not support this!
 * TODO You will first need to verify that fetchPermittedLabelItems has been updated to allow for multi-permission fetches.
 * TODO then remove these TODOs so nobody else worries about it :)
 * @type RBACString[]
 */
const NECESSARY_RBAC_ROLES_FOR_SUBSCRIPTIONS = ['device::read'];

module.exports = { NECESSARY_RBAC_ROLES_FOR_SUBSCRIPTIONS };
