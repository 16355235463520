import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { Text } from 'core/components';

@withTheme
class InlineCode extends Component {
  render() {
    const { theme, children } = this.props;
    const codeColor = theme.name === 'dark' ? theme.colors.yellow : '#AB3060';

    return (
      <Text monospace fontSize={12} color={codeColor}>
        {children}
      </Text>
    );
  }
}

export default InlineCode;
