import React from 'react';
import { Classes } from '@blueprintjs/core';
import styled, { css } from 'styled-components';
import { space, width, themeGet } from 'styled-system';
import classNames from 'classnames';

import tableLayout from './utils/tableLayout';
import { whiteSpace } from './utils/typography';

const StyledTable = styled.table`
  font-size: ${themeGet('fontSizes.small')};

  ${space};
  ${tableLayout};
  ${whiteSpace};
  ${width};

  ${(props) =>
    props.ellipsisCells &&
    css`
      &.${Classes.HTML_TABLE} {
        td {
          vertical-align: middle;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    `};

  /* Blueprint has very specific styles for this particular element, so we have to go pretty far to override it */
  ${(props) =>
    props.theme.name === 'dark' &&
    css`
      .bp4-dark &.${Classes.HTML_TABLE_STRIPED} {
        tbody tr:nth-child(odd) td {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      .bp4-dark &.${Classes.HTML_TABLE_STRIPED} {
        tbody tr:first-child td {
          box-shadow: inset 0 1px 0 0 rgb(35, 40, 43);
        }
      }
    `};

  &.no-header-bordered {
    tr {
      &:last-child td {
        border-bottom-width: 0;
      }

      td {
        border-bottom: ${themeGet('borders.thin')};
        box-shadow: none;
      }
    }
  }

  &.row-bordered {
    thead th {
      border-bottom: ${themeGet('borders.thin')};

      &:not(:first-child) {
        box-shadow: none;
      }
    }

    tbody tr td {
      box-shadow: none;
      border-bottom: ${themeGet('borders.thin')};

      &:not(:first-child) {
        box-shadow: none;
      }
    }
  }

  ${(props) =>
    props.stickyHeader === true &&
    css`
      &.${Classes.HTML_TABLE} {
        th {
          position: sticky;
          top: 0px;
          background-color: ${props.theme.name === 'dark' ? props.theme.colors.cardBackground : '#fff'};
          border-bottom: ${themeGet('borders.thin')};
        }

        tbody tr:first-child td {
          box-shadow: none;
        }
      }
    `}
`;

function Table({ elevation, interactive, bordered, condensed, striped, className, ...rest }) {
  return (
    <StyledTable
      className={classNames(
        Classes.HTML_TABLE,
        {
          [Classes.HTML_TABLE_BORDERED]: bordered,
          [Classes.HTML_TABLE_CONDENSED]: condensed,
          [Classes.HTML_TABLE_STRIPED]: striped,
          [Classes.INTERACTIVE]: interactive
        },
        className
      )}
      {...rest}
    />
  );
}

Table.defaultProps = {
  width: '100%',
  ellipsisCells: false
};

export default Table;
