import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'core/components';
import { formConsumer } from 'core/form';
import QueryModel from 'app/stores/query/QueryModel';
import MetricsExplorerResult from 'app/views/metrics/MetricsExplorerResult';
import MetricsItemExplorerOptions from 'app/views/core/dashboards/dashboardItem/form/MetricsItemExplorerOptions';

@inject('$metrics', '$setup')
@formConsumer
@observer
export default class MetricsItemExplorer extends Component {
  handleQueryChange = ({ form, formValues }) => {
    const { $metrics, innerFormSetter, onQueryChange } = this.props;
    const query = $metrics.formValuesToQuery(formValues, { allowMultiAggregate: true });

    if (innerFormSetter) {
      innerFormSetter(form);
    }

    if (form.valid) {
      onQueryChange(new QueryModel(query));
    }
  };

  render() {
    const { $metrics, $setup, dashboard, dashboardItem, form } = this.props;
    const query = dashboardItem.editQuery;

    // Not loaded yet
    if (!query) {
      return null;
    }

    const formValues = $metrics.measurementModel(query.get('kmetrics.measurement'))
      ? $metrics.queryToFormValues(query.serialize())
      : {};
    const { selectedMeasurement, selectedMetrics } = formValues;

    const isLeftSidebar = $setup.getSettings('layout.drawerLocation') === 'left';
    const flexDirection = isLeftSidebar ? 'row-reverse' : 'row';
    const sidebarBorders = { [isLeftSidebar ? 'borderRight' : 'borderLeft']: 'thin' };

    return (
      <Flex
        flexDirection={flexDirection}
        flex={1}
        overflow="hidden"
        height="calc(100vh - 420px)"
        minHeight={400}
        mb="-1px"
      >
        <Flex flexDirection="column" flex={1} overflow="hidden" position="relative" pt={1}>
          <MetricsExplorerResult
            query={query.serialize()}
            isWidget
            isReadyForQuery={selectedMeasurement && selectedMetrics.length > 0}
          />
        </Flex>
        <Box {...sidebarBorders} mb="-1px" overflow="auto">
          <MetricsItemExplorerOptions
            model={dashboardItem}
            formValues={formValues}
            dashboard={dashboard}
            dashboardForm={form}
            onQueryChange={this.handleQueryChange}
          />
        </Box>
      </Flex>
    );
  }
}
