export const InternalFilters = {
  filters: [
    {
      filterField: 'simple_trf_prof',
      operator: '=',
      filterValue: 'internal'
    }
  ]
};

export const InboundFilters = {
  filters: [
    {
      filterField: 'simple_trf_prof',
      operator: '=',
      filterValue: 'inbound'
    }
  ]
};

export const OutboundFilters = {
  filters: [
    {
      filterField: 'simple_trf_prof',
      operator: '=',
      filterValue: 'outbound'
    }
  ]
};

export default {
  metric: ['Traffic'],
  aggregateTypes: ['p95th_bits_per_sec'],
  lookback_seconds: 86400,
  all_devices: false, // default - restricted to cloud providers via device_types.
  device_types: [],
  show_overlay: false,
  show_total_overlay: true,
  filterDimensionsEnabled: true,
  filterDimensionName: 'Total',
  filterDimensionOther: false,
  filterDimensionSort: true,
  filterDimensions: {
    connector: 'All',
    filterGroups: [
      {
        name: 'Internal',
        named: true,
        connector: 'All',
        not: false,
        autoAdded: '',
        saved_filters: [],
        ...InternalFilters,
        filterGroups: []
      },
      {
        name: 'Inbound',
        named: true,
        connector: 'All',
        not: false,
        autoAdded: '',
        saved_filters: [],
        ...InboundFilters,
        filterGroups: []
      },
      {
        name: 'Outbound',
        named: true,
        connector: 'All',
        not: false,
        autoAdded: '',
        saved_filters: [],
        ...OutboundFilters,
        filterGroups: []
      }
    ]
  }
};
