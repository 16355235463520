import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { cloneDeep, isEqual } from 'lodash';
import { Flex, FlexColumn, Spinner, Suspense } from 'core/components';
import DeckHeading from 'app/components/decks/DeckHeading';
import CustomizeDrawer from 'app/components/decks/CustomizeDrawer';
import Page, { NAV_HEIGHT } from 'app/components/page/Page';
import DeckGridLayout from 'app/components/decks/DeckGridLayout';
import { OnboardingWarning } from 'app/views/core/onboardingWarning';
import DeckHomepageCallout from './DeckHomepageCallout';

@inject('$auth', '$decks')
@observer
class Deck extends Component {
  static defaultProps = {
    title: undefined,
    description: undefined,
    hideSubnav: true,
    icon: undefined,
    tools: undefined,
    fullWidthTools: false,
    type: undefined,
    canCustomize: false,
    canReset: true,
    canSaveOnly: false,
    deckOverrides: {},
    defaultDeckConfig: { widgets: [] },
    showOnboarding: false
  };

  state = {
    loading: true,
    deck: null,
    decks: [],
    initialConfig: { widgets: [] }
  };

  componentDidMount() {
    this.updateDeckType();
  }

  componentDidUpdate(prevProps) {
    const { type } = this.props;

    if (prevProps.type !== type) {
      this.updateDeckType();
    }
  }

  updateDeckType() {
    const { $decks, type, canCustomize, canSaveOnly } = this.props;
    $decks.getDefaultDeck({ canCustomize: canCustomize || canSaveOnly, type }).then((decks) => {
      const deck = decks.find((d) => d.get('share_level') === 'self');
      this.setState({ deck, decks, loading: false, initialConfig: cloneDeep(deck.config) });
    });
  }

  handleAddWidget = (widgetName) => {
    const { onCloseCustomizeDrawer } = this.props;
    const { deck } = this.state;
    deck.addWidget(widgetName);
    onCloseCustomizeDrawer();
  };

  handleWidgetConfigChange = (id, config) => {
    const { canCustomize, canSaveOnly } = this.props;
    const { deck, decks } = this.state;
    deck.updateWidget(id, config);
    if (!canCustomize && canSaveOnly) {
      // Force save config bypassing Header Save Button
      deck.save({ share_level: 'self' }).then(() => {
        this.handleSave({ deck, decks });
      });
    }
  };

  handleRemoveWidget = (idx) => {
    const { deck } = this.state;
    deck.removeWidget(idx);
  };

  handleReset = () => {
    const { type, $decks } = this.props;
    const decks = $decks.resetDeck({ type });
    const deck = decks.find((d) => d.get('share_level') === 'self');
    this.setState({ deck, initialConfig: cloneDeep(deck.config) });
  };

  handleSave = ({ deck, decks }) => {
    deck.set({ isDefault: false });
    this.setState({ deck, decks, initialConfig: cloneDeep(deck.config) });
  };

  @computed
  get isCustomized() {
    const { deck } = this.state;
    return deck && (!deck.get('isDefault') || this.isDirty);
  }

  @computed
  get isDirty() {
    const { initialConfig, deck } = this.state;

    if (deck) {
      return !isEqual(initialConfig, deck.config);
    }

    return false;
  }

  render() {
    const {
      canCustomize,
      canSaveOnly,
      description,
      deckOverrides,
      hideSetupGauge,
      hideSubnav,
      showLandingPageCallout,
      showOnboarding,
      icon,
      iconSize,
      isCustomizeDrawerOpen,
      onCloseCustomizeDrawer,
      onAddWidgets,
      customizeDrawerContents,
      title,
      tools,
      fullWidthTools,
      type
    } = this.props;

    const { loading, deck, decks } = this.state;

    return (
      <Page
        title={title}
        px={0}
        py={0}
        hideSubnav={hideSubnav}
        hideSetupGauge={hideSetupGauge}
        overflow="hidden"
        overrideFullScreen
      >
        <FlexColumn overflow="hidden" flex={1} position="relative" height={`calc(100vh - ${NAV_HEIGHT}px)`}>
          <DeckHeading
            title={title}
            description={description}
            icon={icon}
            iconSize={iconSize}
            px={3}
            py={2}
            width="100%"
            deck={deck}
            decks={decks}
            type={type}
            isCustomized={this.isCustomized}
            isDirty={this.isDirty}
            onAddWidgets={onAddWidgets}
            onReset={this.handleReset}
            onSave={this.handleSave}
            canCustomize={canCustomize}
          >
            <FlexColumn flex={fullWidthTools ? 1 : 'auto'} alignItems="flex-end" justifyContent="center">
              {tools}
            </FlexColumn>
          </DeckHeading>
          {showOnboarding && <OnboardingWarning style={{ mb: 0, bg: 'decks.background', pt: 1, pr: 3, pl: '10px' }} />}
          {showLandingPageCallout && <DeckHomepageCallout />}
          <Flex flexDirection="column" bg="decks.background" flex={1} py={0} overflow="hidden scroll" zIndex={0}>
            <Flex flex={1}>
              <Suspense
                loading={loading}
                fallback={
                  <Flex mt={2} alignItems="flex-start" justifyContent="center" width="100%">
                    <Spinner />
                  </Flex>
                }
              >
                {deck && (
                  <DeckGridLayout
                    deck={deck}
                    onWidgetConfigChange={this.handleWidgetConfigChange}
                    onRemoveWidget={this.handleRemoveWidget}
                    canCustomize={canCustomize}
                    canSaveOnly={canSaveOnly}
                    deckOverrides={deckOverrides}
                  />
                )}
              </Suspense>
            </Flex>
          </Flex>
          {canCustomize && (
            <CustomizeDrawer
              isOpen={isCustomizeDrawerOpen}
              onClose={onCloseCustomizeDrawer}
              drawerContents={customizeDrawerContents}
              onAddWidget={this.handleAddWidget}
            />
          )}
        </FlexColumn>
      </Page>
    );
  }
}

export default Deck;
