import { BITS_SNMP_QUERY, BITS_ST_QUERY, BITS_SNMP_RESPONSE_KEYS, BITS_ST_RESPONSE_KEYS } from './queries/Bits';
import {
  DISCARDS_SNMP_QUERY,
  DISCARDS_ST_QUERY,
  DISCARDS_SNMP_RESPONSE_KEYS,
  DISCARDS_ST_RESPONSE_KEYS
} from './queries/Discards';
import {
  PACKETS_SNMP_QUERY,
  PACKETS_ST_QUERY,
  PACKETS_SNMP_RESPONSE_KEYS,
  PACKETS_ST_RESPONSE_KEYS
} from './queries/Packets';
import {
  ERRORS_SNMP_QUERY,
  ERRORS_ST_QUERY,
  ERRORS_SNMP_RESPONSE_KEYS,
  ERRORS_ST_RESPONSE_KEYS
} from './queries/Errors';
import { CAPACITY_SNMP_QUERY, CAPACITY_SNMP_RESPONSE_KEYS } from './queries/Capacity';

const COMMON_query_partial = {
  bracketOptions: '',
  hideCidr: false,
  cidr: 32,
  cidr6: 128,
  customAsGroups: true,
  cutFn: {},
  cutFnRegex: {},
  cutFnSelector: {},
  depth: 75,
  descriptor: '',
  device_name: ['tallinn_gw'],
  device_labels: [],
  device_sites: [],
  device_types: [],
  fastData: 'Full',
  filterDimensionsEnabled: false,
  filterDimensionName: 'Total',
  filterDimensionOther: false,
  filterDimensionSort: false,
  filterDimensions: {},
  aggregateFiltersEnabled: false,
  aggregateFiltersDimensionLabel: '',
  aggregateFilters: [],
  hostname_lookup: true,
  isOverlay: false,
  isPreviousPeriod: false,
  lookback_seconds: 86400,
  from_to_lookback: 3600,
  generatorDimensions: [],
  generatorPanelMinHeight: 250,
  generatorMode: false,
  generatorColumns: 1,
  generatorQueryTitle: '{{generator_series_name}}',
  generatorTopx: 8,
  matrixBy: [],
  minsPolling: 5,
  forceMinsPolling: true,
  reAggInterval: 3600,
  reAggFn: 'auto',
  mirrorUnits: true,
  overlay_day: -7,
  overlay_timestamp_adjust: false,
  period_over_period: false,
  period_over_period_lookback: 1,
  period_over_period_lookback_unit: 'week',
  query_title: '',
  secondaryTopxSeparate: false,
  secondaryTopxMirrored: false,
  show_overlay: false,
  show_total_overlay: false,
  starting_time: null,
  ending_time: null,
  sync_all_axes: false,
  sync_axes: true,
  sync_extents: true,
  show_site_markers: false,
  topx: 8,
  update_frequency: 0,
  use_log_axis: false,
  use_secondary_log_axis: false,
  use_alt_timestamp_field: false,

  all_devices: false,
  mirror: false,
  viz_type: 'stackedArea'

  // the following fields are added inline when the queries are assembled:
  // * device_name
  // * metric
  // * filters

  // the following fields have partials below (SNMP vs Streaming Telemetry):
  // * dimension

  // the remaining fields get set on the query provided to the component:
  // * outsort
  // * secondaryOutsort
  // * aggregateTypes
  // * aggregateThresholds
  // * aggregates
};

const generateSnmpQuery = (selectedInterface, queryData) => {
  const device = selectedInterface.get('device');

  const query = Object.assign({}, COMMON_query_partial, {
    device_name: [device.get('device_name')],
    filters: {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'Any',
          not: false,
          autoAdded: true,
          filters: [
            {
              filterField: 'ktappprotocol__snmp__output_port',
              operator: '=',
              filterValue: selectedInterface.get('snmp_id')
            }
          ],
          saved_filters: [],
          filterGroups: []
        }
      ]
    },
    ...queryData
  });

  return query;
};

const generateStreamingQuery = (selectedInterface, queryData) => {
  const device = selectedInterface.get('device');

  const query = Object.assign({}, COMMON_query_partial, {
    device_name: [device.get('device_name')],
    metric: ['Traffic'],
    filters: {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'Any',
          not: false,
          autoAdded: true,
          filters: [
            {
              filterField: 'ktappprotocol__st__output_port',
              operator: '=',
              filterValue: selectedInterface.get('snmp_id')
            }
          ],
          saved_filters: [],
          filterGroups: []
        }
      ]
    },
    ...queryData
  });

  return query;
};

export {
  generateSnmpQuery,
  generateStreamingQuery,
  BITS_SNMP_QUERY,
  BITS_ST_QUERY,
  DISCARDS_SNMP_QUERY,
  DISCARDS_ST_QUERY,
  PACKETS_SNMP_QUERY,
  PACKETS_ST_QUERY,
  ERRORS_SNMP_QUERY,
  ERRORS_ST_QUERY,
  CAPACITY_SNMP_QUERY,
  CAPACITY_SNMP_RESPONSE_KEYS,
  BITS_SNMP_RESPONSE_KEYS,
  BITS_ST_RESPONSE_KEYS,
  DISCARDS_SNMP_RESPONSE_KEYS,
  DISCARDS_ST_RESPONSE_KEYS,
  ERRORS_SNMP_RESPONSE_KEYS,
  ERRORS_ST_RESPONSE_KEYS,
  PACKETS_SNMP_RESPONSE_KEYS,
  PACKETS_ST_RESPONSE_KEYS
};
