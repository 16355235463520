import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Flex, Text } from 'core/components';

const filterKeyMap = {
  interface_description: 'Name',
  snmp_alias: 'Description',
  device_id: 'Device',
  interface_ip: 'IP Address',
  snmp_speed: 'Capacity',
  connectivity_type: 'Connectivity Type',
  network_boundary: 'Network Boundary',
  provider: 'Provider',
  is_logical: 'Is Logical',
  interface_type: 'Interface Type',
  label_id: 'Device Label',
  site_id: 'Site',
  admin_status: 'Admin Status'
};

@inject('$dictionary', '$devices', '$labels', '$sites')
@observer
export default class FilterGroupSummary extends Component {
  getLabel = (key, value) => {
    const { $dictionary, $devices, $labels, $sites } = this.props;
    if (key === 'connectivity_type') {
      return $dictionary.get(`interfaceClassification.connectivityTypes.${value}`);
    }
    if (key === 'snmp_speed') {
      const snmp_speed = parseInt(value);
      let label;
      label = `${snmp_speed}Mbits/s`;

      if (snmp_speed >= 1000) {
        label = `${snmp_speed / 1000}Gbits/s`;
      }
      return label;
    }
    if (key === 'network_boundary') {
      return $dictionary.get(`interfaceClassification.networkBoundaryTypes.${value}`);
    }
    if (key === 'device_id') {
      return $devices.deviceSummariesById[value]?.device_name || value;
    }
    if (key === 'label_id') {
      return $labels.labels.get(value)?.get('name') || value;
    }
    if (key === 'site_id') {
      return $sites.collection.get(value)?.get('title') || value;
    }
    return value;
  };

  render() {
    const { filterGroup } = this.props;
    const { filters } = filterGroup;

    return (
      <Flex alignItems="stretch" gap="16px" mx={2}>
        {Object.keys(filters).map((field) => {
          const filterValue = filters[field];
          const filterValueDisplay = Array.isArray(filterValue)
            ? filterValue.map((val) => this.getLabel(field, val)).join(', ')
            : this.getLabel(field, filterValue);

          return (
            <Box key={field} flex="1" flexBasis="max-content" ml="4px" borderLeft="thin" pl={2}>
              <Box>
                <Text fontWeight="bold" whiteSpace="nowrap" small muted>
                  {filterKeyMap[field] || field}
                </Text>
                :
              </Box>
              <Text as="div" small muted>
                {filterValueDisplay}
              </Text>
            </Box>
          );
        })}
      </Flex>
    );
  }
}
