import DashboardSidebar from 'app/views/core/dashboards/DashboardSidebar';
import ViewSidebar from 'app/views/core/ViewSidebar';
import React from 'react';
import $dashboards from 'app/stores/dashboard/$dashboards';
import { FormWrapper } from 'core/form/components/FormWrapper';
import $dashboard from 'app/stores/dashboard/$dashboard';
import { fields, options } from 'app/forms/config/dashboardQuery';

const DashboardDrawerContent = ({ form, sidebarOpen, handleSidebarClose }) => {
  if (sidebarOpen === 'query') {
    return <DashboardSidebar form={form} width={400} />;
  }
  if (sidebarOpen === 'dashboards') {
    return (
      <ViewSidebar
        collection={$dashboards.collection}
        fieldMap={{
          title: 'dash_title',
          description: 'description',
          created_at: 'ctime',
          updated_at: 'etime'
        }}
        labelOptions={$dashboards.labelOptions}
        ownerOptions={$dashboards.ownerOptions}
        privacyOptions={$dashboards.privacyOptions}
        name="Dashboard"
        onClose={() => handleSidebarClose()}
        toLink={(id) => `/v4/library/dashboards/${id}`}
      />
    );
  }
  return null;
};

export const DashboardDrawer = ({ model, sidebarOpen, handleSidebarClose }) => (
  <FormWrapper
    fields={fields}
    options={options}
    model={model}
    onFormCreated={(form) => $dashboard.registerFormState(form)}
  >
    <DashboardDrawerContent sidebarOpen={sidebarOpen} handleSidebarClose={handleSidebarClose} />
  </FormWrapper>
);
