import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { LinkButton, Link } from 'core/components';

@inject('$auth', '$mkp')
@observer
class TenantLink extends Component {
  static defaultProps = {
    children: 'Configure Tenant'
  };

  render() {
    const { $mkp, $auth, as, children, id, label, tab, ...rest } = this.props;
    const isButton = as === 'Button';
    const isValidTab = $mkp.tabs.includes(tab);
    const to = `/v4/mkp/tenants/${id}${isValidTab ? `?tab=${tab}` : ''}`;

    const LinkComponent = isButton ? LinkButton : Link;

    if ($auth.isDemoUser) {
      return null;
    }

    return (
      <LinkComponent to={to} blank {...rest}>
        {label || children}
      </LinkComponent>
    );
  }
}

export default TenantLink;
