/*
  Displays an aggregated count of incidents by status type (critical, warning)
  along with the reported time slot value
*/
import React, { Component } from 'react';
import { Flex, InfoBox, Text } from 'core/components';
import { timezone, dateTimeFormatter } from 'core/util/dateUtils';
import { SIDEBAR_WIDTH } from './constants';

export default class IncidentLogSummary extends Component {
  static defaultProps = {
    activeTimeslot: null,
    resultTimeMs: null,
    width: 0
  };

  render() {
    const { resultTimeMs, activeTimeslot, width, ...boxProps } = this.props;
    // when rendered in a sidebar, space the summary out to either end
    const justify = width >= SIDEBAR_WIDTH ? 'flex-start' : 'space-between';

    if (activeTimeslot) {
      return (
        <Flex p={2} gap={2} justifyContent={justify} {...boxProps}>
          <InfoBox mb={0} heading="Incidents">
            <Flex gap={1}>
              {activeTimeslot.critical > 0 && <Text color="danger">{activeTimeslot.critical} Critical</Text>}
              {activeTimeslot.major > 0 && <Text color="warning">{activeTimeslot.major} Warning</Text>}
            </Flex>
          </InfoBox>

          <InfoBox mb={0} heading={`Time (${timezone.value})`}>
            {dateTimeFormatter({ value: resultTimeMs })}
          </InfoBox>
        </Flex>
      );
    }

    return null;
  }
}
