import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Highcharts from 'core/components/Highcharts';

function getChartConfig(insight) {
  const flowsLastWeek = insight.values.find((val) => val.metric === 'flows_last_week');
  const flowsThisWeek = insight.values.find((val) => val.metric === 'flows_this_week');

  return {
    chart: {
      type: 'bar'
    },

    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        }
      },
      series: {
        pointPadding: 0
      }
    },

    legend: {
      enabled: false
    },

    xAxis: {
      type: 'category',
      labels: {
        rotation: -45
      }
    },

    yAxis: {
      min: 0,
      title: undefined
    },

    tooltip: {
      enabled: false
    },

    series: [
      {
        name: 'Total FPS',
        dataLabels: {
          enabled: true,
          align: 'right',
          formatter() {
            return `<b>${this.point.formattedValue}</b>`;
          }
        },
        data: [
          {
            name: 'Last week',
            y: flowsLastWeek.value,
            formattedValue: flowsLastWeek.formattedValue,
            colorIndex: 4
          },
          {
            name: 'This week',
            y: flowsThisWeek.value,
            formattedValue: flowsThisWeek.formattedValue,
            colorIndex: 0
          }
        ]
      }
    ]
  };
}

@inject('$colors')
@observer
class FlowsThisWeekOverLastWeek extends Component {
  render() {
    const { $colors, insight } = this.props;

    return (
      <Highcharts
        options={getChartConfig(insight)}
        colors={$colors.chartColors}
        containerProps={{ style: { width: '100%' } }}
      />
    );
  }
}

export default FlowsThisWeekOverLastWeek;
