import styled from 'styled-components';
import { Box } from 'core/components';

const DrawerBackdrop = styled(Box)`
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.35);
  z-index: 30;

  transition: opacity, visibility;
  transition-delay: 0ms, 250ms;
  transition-duration: 350ms, 350ms;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
`;

export default DrawerBackdrop;
