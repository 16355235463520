import React from 'react';
import { orderBy } from 'lodash';

import { secondsIntervalToText } from 'core/util/dateUtils';
import $dictionary from 'app/stores/$dictionary';
import QueryResultsCollection from 'app/stores/query/QueryResultsCollection';
import { getAggregateRenderer } from 'app/components/dataviews/views/legend/legendRenderers';
import { interfaceKeyRegex } from 'app/components/dataviews/views/legend/legendUtils';
import { LegendLastDatapointLabel } from 'app/components/dataviews/views/legend/LegendTable';
import Aggregate from 'app/components/resultsTable/Aggregate';

const parsedMetrics = {
  IP_src: /(?:.*\((\S{3,15}).*\)|(?:^(.*)\s\())/,
  IP_dst: /(?:.*\((\S{3,15}).*\)|(?:^(.*)\s\())/,
  src_nexthop_ip: /(?:.*\((\S{3,15}).*\)|(?:^(.*)\s\())/,
  dst_nexthop_ip: /(?:.*\((\S{3,15}).*\)|(?:^(.*)\s\())/,
  src_route_prefix_len: /(?:.*\((\S{3,15}).*\)|(?:^(.*)\s\())/,
  dst_route_prefix_len: /(?:.*\((\S{3,15}).*\)|(?:^(.*)\s\())/,
  InterfaceID_src: /(?:(?:.*:\s((?!---\s).{2,15}).*\s\()|(?:^((?!---\s).{2,15}).*\s:)|\(([0-9]+)\))/,
  InterfaceID_dst: /(?:(?:.*:\s((?!---\s).{2,15}).*\s\()|(?:^((?!---\s).{2,15}).*\s:)|\(([0-9]+)\))/,
  bgp_ult_exit_interface: /(?:(?:.*:\s((?!---\s).{2,15}).*\s\()|(?:^((?!---\s).{2,15}).*\s:)|\(([0-9]+)\))/,
  AS_src: /(^.{6}).*(\([0-9]+\))/,
  src_nexthop_asn: /(^.{6}).*(\([0-9]+\))/,
  src_second_asn: /(^.{6}).*(\([0-9]+\))/,
  src_third_asn: /(^.{6}).*(\([0-9]+\))/,
  AS_dst: /(^.{6}).*(\([0-9]+\))/,
  dst_nexthop_asn: /(^.{6}).*(\([0-9]+\))/,
  dst_second_asn: /(^.{6}).*(\([0-9]+\))/,
  dst_third_asn: /(^.{6}).*(\([0-9]+\))/
};

export function getOverlaySeries(raw) {
  return raw.find((series) => series.isOverlay);
}

export function getSeries(raw) {
  return raw.find((series) => !series.isOverlay);
}

export function getSparklineData(raw) {
  const seriesData = getSeries(raw);

  if (!seriesData || !seriesData.rawData) {
    return [];
  }

  return seriesData.rawData.both_bits_per_sec.flow.map((rawFlow) => rawFlow[1]);
}

export function getTrend(raw, aggregateName) {
  const series = getSeries(raw);
  const overlaySeries = getOverlaySeries(raw);

  if (!series || !overlaySeries) {
    // Adding this as a temporary fix for when we show `undefined` for the trend at times.
    return {
      value: 0,
      direction: 'flat',
      text: 'Unknown',
      percentage: 0
    };
  }

  const value = (series[aggregateName] - overlaySeries[aggregateName]) / series[aggregateName];
  const percentage = Math.round(value * 100 * 100) / 100;

  let direction = 'flat';
  if (value > 0) {
    direction = 'up';
  } else if (value < 0) {
    direction = 'down';
  }

  return {
    value,
    percentage,
    direction,
    text: direction === 'flat' ? 'No change' : `${value.toFixed(2)}% ${direction === 'up' ? 'increase' : 'decrease'}`
  };
}

export function calculateTrendData({ current, last, lookback = 3600, showInterval = true }) {
  const value = (current - last) / current;
  const direction = value > 0 ? 'up' : 'down';
  const percentage = Math.round(value * 100 * 100) / 100;
  let interval = '';

  if (showInterval) {
    switch (lookback) {
      case 1:
        interval = '(this month)';
        break;
      case 2:
        interval = '(last month)';
        break;
      default:
        interval = `(${secondsIntervalToText(lookback)})`;
        break;
    }
  }

  return {
    value,
    percentage,
    direction,
    text: `${percentage}% ${interval}`
  };
}

export function getTopx({ queryResults, key, aggregate, topx = 8, mapFn }) {
  return orderBy(
    queryResults.filter((result) => result[aggregate] !== undefined),
    [aggregate],
    ['desc']
  )
    .slice(0, topx)
    .map(mapFn || ((result) => result[key]));
}

export function getLastDatapointColumns({ aggregates, bucket, prefix }) {
  const lastDatapointColumns = [];

  aggregates.forEach((aggregate) => {
    const renderer = getAggregateRenderer({ aggregate, prefix, bucket });
    const name = `${aggregate.column}__k_last`;

    if (!aggregate.value.includes('agg_total')) {
      lastDatapointColumns.push({
        ...aggregate,
        column: name,
        value: name,
        key: name,
        name,
        label: <LegendLastDatapointLabel aggregate={aggregate} prefix={prefix} />,
        renderer,
        totalRenderer: renderer
      });
    }
  });

  return lastDatapointColumns;
}

// @TODO Average aggregate renderer doesn't work right.
export function getValueColumns({
  queryModel,
  bucket,
  showCombinedTotalColumn,
  showLastDatapoints,
  queryResultsCollection
}) {
  const prefix = { ...queryResultsCollection.prefix };
  let columns = [];

  if (queryModel.get('aggregateFiltersEnabled')) {
    const [aggregate] = queryModel.aggregates;
    const renderer = getAggregateRenderer({ aggregate, prefix, bucket, emptyText: '-' });

    columns = queryModel.get('aggregateFilters').map((aggregateFilter) => {
      const { name } = aggregateFilter;
      return {
        ...aggregate,
        key: name,
        label: <Aggregate typeLabel={name} aggregate={aggregate} prefix={prefix} />,
        value: name,
        renderer
      };
    });

    if (showCombinedTotalColumn) {
      columns.push({
        ...aggregate,
        key: 'total',
        label: <Aggregate typeLabel="Total" aggregate={aggregate} prefix={prefix} />,
        renderer
      });
    }
  } else {
    columns = queryModel.aggregates.map((aggregate) => ({
      ...aggregate,
      key: aggregate.value,
      label: <Aggregate aggregate={aggregate} prefix={prefix} />,
      renderer: getAggregateRenderer({ aggregate, prefix, bucket })
    }));
  }

  if (showLastDatapoints) {
    columns.push(...getLastDatapointColumns({ aggregates: queryModel.aggregates, bucket, prefix }));
  }

  return columns;
}

export function combineResults({
  results,
  assets = [],
  filters = [],
  matcherFn,
  matcherMergeFn = (match) => match.get(),
  buildResultFn,
  sort = 'Outbound'
}) {
  const combinedResults = new QueryResultsCollection();
  const assetAry = assets.get ? assets.get() : assets;

  if (!results) {
    return results;
  }

  combinedResults.prefixableFieldUnits = results.prefixableFieldUnits;
  combinedResults.sort(sort);

  results.each((result) => {
    const match = assetAry.find((asset) => matcherFn(asset, result));
    combinedResults.add({
      ...result.get(),
      ...(match ? matcherMergeFn(match) : {})
    });
  });

  // seed the results with the asset collection
  if (filters.length === 0) {
    assetAry.forEach((asset) => {
      const match = results.get().find((result) => matcherFn(asset, result));

      if (!match && asset) {
        combinedResults.add(buildResultFn(asset));
      }
    });
  }

  return combinedResults;
}

export function getShortName(metric, name = ' ') {
  let matches;

  if (metric === 'AS_src' || metric === 'AS_dst') {
    // match AS Group with multiple ASNs
    const match = name.match(/\(\d+(,\s*\d+)+\)/);
    if (match) {
      return name.substring(0, match.index - 1);
    }
  }

  if (metric === 'InterfaceID_src' || metric === 'InterfaceID_dst' || metric === 'bgp_ult_exit_interface') {
    const match = interfaceKeyRegex.exec(name);
    if (match) {
      const { interface_description, snmp_alias, snmp_id } = match.groups;
      return `${interface_description.substring(0, 8)} (${snmp_id})${
        snmp_alias !== '---' ? `: ${snmp_alias.substring(0, 8)}` : ''
      }`;
    }
  }

  if (metric === 'i_src_connect_type_name' || metric === 'i_dst_connect_type_name') {
    return $dictionary.get(`interfaceClassification.connectivityTypes.${name}`, 'None');
  }

  if (metric === 'i_src_network_bndry_name' || metric === 'i_dst_network_bndry_name') {
    return $dictionary.get(`interfaceClassification.networkBoundaryTypes.${name}`, 'none');
  }

  if (parsedMetrics[metric]) {
    matches = parsedMetrics[metric].exec(name);
    // in this first scenario, there were matches (matches not null), but some are undefined meaning it's a conditional parser
    if (matches && matches.indexOf(undefined) !== -1) {
      while (matches[matches.length - 1] === undefined) {
        matches.splice(-1, 1);
      }
      return matches[matches.length - 1];
      // in this scenario, there were matches (matches not null), but no undefined so we want to join them all together
    }
    if (matches) {
      matches.splice(0, 1);
      return matches.map((match) => match.replace(/---/g, '')).join(' ');
    }

    // no matches, so just return the name (guard)
    return name;
  }

  return name;
}

export function getTrimmedName(metric, name) {
  if (metric === 'AS_src' || metric === 'AS_dst') {
    // match AS Group with multiple ASNs
    const match = name.match(/^(.*?)\s*\((\d+(?:,\s*\d+)*)\)$/);

    if (match && match[2].includes(',')) {
      return match[1];
    }
  }
  // no modification for single ASN
  return name;
}

/**
 * Given an array of query models with a Total overlay model and rawData, it returns an object with the peak value
 * from the total model and the values from the other models at that same peak point in time.
 * @param models Array of query models
 * @param aggregateType preferably max_bits_per_sec or p95th_bits_per_Sec
 * @returns {
 *   totalValue: number,
 *   totalIndex: number,
 *   totalModel: model,
 *   modelValues: [
 *     { key: string, value: number, model: object }
 *   ]
 * }
 */
export function getPeakValues(models, aggregateType = 'max_bits_per_sec') {
  const totalModel = models.find((model) => model.get('name') === 'Total');
  let rawTotals = [];
  const peakValues = {
    totalValue: 0,
    totalIndex: -1,
    totalModel,
    modelValues: []
  };

  if (!totalModel || !totalModel.hasRawData) {
    return peakValues;
  }

  peakValues.totalValue = totalModel.get(aggregateType);
  rawTotals = totalModel.get('rawData').both_bits_per_sec.flow || [];
  peakValues.totalIndex = rawTotals.findIndex((item) => item[1].toFixed(2) === peakValues.totalValue.toFixed(2));

  if (peakValues.totalIndex > -1) {
    peakValues.modelValues = models
      .filter((model) => !model.isOverlay)
      .map((model) => ({
        key: model.get('key'),
        value: model.get('rawData').both_bits_per_sec.flow[peakValues.totalIndex][1],
        model
      }));
  }

  return peakValues;
}

/*
  Supports values in the form:

  Akamai ASNs (12222, 16625, 16702)
  (21396) NetConnex,GB

  Example return:

  {
    name: 'Akamai ASNs',
    asns: ['12222', '16625', '16702'],
    ansList: '12222,16625,16702'
  }
*/
export function getASNValues(asn) {
  const asnValue = { name: null, asns: [] };

  if (typeof asn === 'string') {
    // attempt to match the as name followed by numbers in parens
    let match = asn.match(/(.*)\(([^)]*)\)*$/);
    asnValue.name = match && match[1].trim();
    asnValue.asns = (match && match[2].split(/,\s*/)) || [];

    if (!match) {
      // attempt to match the numbers in parens followed by the name
      // this is a special case found in the cloud map
      // there's a limited amount of space for the asns to be listed so those with a single number
      // are shown in front of the name and as groups are shown after with ellipsis
      match = asn.match(/.*\(([^)]*)\)(.*)$/);

      if (match) {
        asnValue.name = match && match[2].trim();
        asnValue.asns = (match && match[1].split(/,\s*/)) || [];
      }
    }
  }

  return {
    ...asnValue,
    asnList: asnValue.asns.join(',')
  };
}
