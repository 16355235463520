import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import { AutoSizer } from 'react-virtualized';
import { isEqual } from 'lodash';

import { Button, Flex, Text } from 'core/components';
import getErrorBoundary from 'core/util/getErrorBoundary';
import QueryModel from 'app/stores/query/QueryModel';
import DataViewModel from 'app/stores/query/DataViewModel';

import NoFlowCallout from 'app/components/NoFlowCallout';
import DataViewHeader from './DataViewHeader';
import DataViewTools from './DataViewTools';
// import ConfigureDataViewDialog from './ConfigureDataViewDialog';

const ErrorBoundaryCmp = getErrorBoundary('DataViewWrapper');

@inject('$app', '$dataviews', '$query', '$auth', '$devices')
@observer
class DataViewWrapper extends Component {
  static defaultProps = {
    className: 'dataview-wrapper',
    dataview: null,
    isExport: false,
    onSelectFpaAnalysisOption: null,
    viewProps: {
      showNativeLegend: false
    },
    headerProps: {
      showTitleLink: true,
      shouldArrangeVertically: false,
      showLastUpdated: true,
      showLiveUpdate: false
    },
    allowCache: false
  };

  // Note: v3 utilized a dataview prop passed in to the wrapper. As of v4, we just support a query prop.
  static getDerivedStateFromProps(props, state) {
    const {
      $dataviews,
      $query,
      forceLoad,
      query,
      allowCache,
      onQueryComplete,
      onDataViewCreate,
      onSelectFpaAnalysisOption
    } = props; // Accept from props, never overwrite
    let { key, queryModel } = state; // Default to existing state and overwrite in case below
    let { dataview } = props;

    if (query) {
      if (!isEqual(state.query, query)) {
        const { viz_type: propsViewType, ...restPropsQuery } = query;
        const { viz_type: stateViewType, ...restStateQuery } = state.query || {};

        if (
          !forceLoad &&
          isEqual(restPropsQuery, restStateQuery) &&
          propsViewType !== stateViewType &&
          $dataviews.isViewTypeCompatible(stateViewType, propsViewType)
        ) {
          // an explicit reload hasn't been specified, viz type is the only thing that changed in the query, and we're attempting to swap to a compatible view type.
          // in this case, just use the same dataview and the viz type in the query will be used to get the proper component when rendering
          dataview = state.dataview;
        } else {
          key = $query.getQueryKey(query);
          queryModel = QueryModel.create(query);
          dataview = new DataViewModel();

          if (onSelectFpaAnalysisOption) {
            dataview.setOnSelectFpaAnalysisOption(onSelectFpaAnalysisOption);
          }

          if (onDataViewCreate) {
            onDataViewCreate(dataview);
          }

          const maxAge = $query.getMaxAgeByLookback(query.lookback_seconds);
          const cachedResults = $query.getCachedQueryResults(key, maxAge);
          if (allowCache && cachedResults) {
            dataview.loadCachedResults(queryModel, cachedResults);

            if (onQueryComplete) {
              const [bucket] = dataview.queryBuckets.activeBuckets;
              onQueryComplete({
                results: bucket.queryResults,
                dataview,
                query,
                queryModel,
                fullyLoaded: true
              });
            }
          } else {
            dataview.setQuery(queryModel.serialize());
          }
        }
      } else {
        dataview = state.dataview;
      }
    }

    return {
      key,
      query,
      queryModel,
      dataview
    };
  }

  state = {};

  componentDidMount() {
    const { $query, onQueryComplete, allowCache } = this.props;

    this.loadedDisposer = reaction(
      () => {
        const { dataview } = this.state;
        return dataview.loadedCount;
      },
      (loadedCount) => {
        const { dataview, key, query, queryModel } = this.state;
        if (loadedCount > 0) {
          const [bucket] = dataview.queryBuckets.activeBuckets;
          const { fullyLoaded } = dataview.queryBuckets;

          // Single query key may have overlays (multiple queries), ONLY cache once after fully loaded.
          if (fullyLoaded && allowCache) {
            // keep cache call async so heavy lifting doesn't interfere with data render
            setTimeout(() => {
              $query.cacheQueryResults(key, dataview.queryBuckets.activeBucketResults);
            }, 500);
          }

          if (onQueryComplete) {
            onQueryComplete({
              results: bucket.queryResults,
              dataview,
              query,
              queryModel,
              fullyLoaded
            });
          }
        }
      }
    );
  }

  componentDidUpdate() {
    const { $app, dataview } = this.props;
    $app.renderSync(() => dataview && dataview.reflow());
  }

  componentWillUnmount() {
    if (this.loadedDisposer) {
      this.loadedDisposer();
    }
  }

  get headerTools() {
    const { dataview, headerProps } = this.props;

    if (headerProps?.tools) {
      return headerProps.tools;
    }

    if (dataview?.isFlowVisualization && dataview.queryBuckets.activeBuckets?.length) {
      const comparingModel = dataview.queryBuckets.activeBuckets[0]?.queryResults.comparingModel;

      if (comparingModel) {
        return (
          <DataViewTools dataview={dataview}>
            {({ Wrapper, ...rest }) => (
              <Wrapper>
                <Button
                  {...rest}
                  icon="undo"
                  title="Show complete chart"
                  onClick={() => comparingModel.setComparing(false)}
                />
              </Wrapper>
            )}
          </DataViewTools>
        );
      }
    }

    return null;
  }

  dismissSpinner = (timeout = 200, setFullyLoaded = true) => {
    const { dataview } = this.props;
    const emptyBuckets = dataview.queryBuckets?.activeBuckets?.length === 0;

    setTimeout(() => {
      if (setFullyLoaded && !emptyBuckets) {
        dataview.setFullyLoaded();
      }
    }, timeout);
  };

  render() {
    const {
      $auth,
      $dataviews,
      className,
      sourceLink,
      viewProps,
      headerProps,
      children,
      isExport,
      customView,
      $devices,
      $app
    } = this.props;
    const { dataview } = this.state;

    if (!dataview) {
      return null;
    }

    if (!$app.isSharedLink && !$devices.hasReceivedFlow) {
      return <NoFlowCallout small />;
    }

    const { viewType, lastUpdated } = dataview;

    if (!$dataviews.hasViewModel(viewType) && !customView) {
      this.dismissSpinner();
      return (
        <Flex alignItems="center" justifyContent="center" p={3}>
          <Text muted>
            Visualization type <strong>{viewType}</strong> is not available at this time.
          </Text>
        </Flex>
      );
    }

    const dataViewConfig = $dataviews.getConfig(viewType) || {};
    const ViewComponent = customView || $dataviews.getComponent(viewType);

    const viewComponent = (
      <ViewComponent
        {...this.props}
        dataview={dataview}
        lastUpdated={lastUpdated}
        isViewCmp
        showNativeLegend={viewProps.showNativeLegend}
      />
    );
    if (!children) {
      return viewComponent;
    }

    const viewHeader = <DataViewHeader {...this.props} dataview={dataview} {...headerProps} tools={this.headerTools} />;

    /**
     * Dataviews can contain their own configurations now, and we'll ship a Dialog that opens
     * those options. Allows the consumer to optionally render it if they want it.
     */
    // const ConfigureDialog = dataview.hasConfigurationOptions ? ConfigureDataViewDialog : null;

    if (isExport) {
      return (
        <ErrorBoundaryCmp>
          {children({
            className,
            dataview,
            sourceLink,
            loading: dataview.loading,
            component: viewComponent,
            header: viewHeader,
            dataViewConfig
          })}
        </ErrorBoundaryCmp>
      );
    }

    return (
      <ErrorBoundaryCmp>
        <AutoSizer
          onResize={() => {
            if (this.debouncedResize) {
              clearTimeout(this.debouncedResize);
            }

            this.debouncedResize = setTimeout(() => dataview.reflow(), 250);
          }}
        >
          {({ width, height }) => {
            const childProps = {
              className,
              dataview,
              sourceLink,
              loading: dataview.loading,
              component: viewComponent,
              header: viewHeader,
              dataViewConfig,
              // ConfigureDialog,
              size: { width, height }
            };

            const childElements = React.Children.count(children)
              ? React.Children.map(children, (child) => React.cloneElement(child, childProps))
              : children(childProps);

            return (
              <Flex
                flexDirection="column"
                flex={1}
                style={{ width, height }}
                onContextMenu={(e) => {
                  if ((e.shiftKey || e.metaKey) && $auth.isSudoer) {
                    e.stopPropagation();
                    e.preventDefault();

                    console.info({
                      hash: dataview.hash,
                      requestIds: dataview.queryBuckets.models.flatMap((qb) => qb.socketIdHistory)
                    });
                  }
                }}
              >
                {childElements}
              </Flex>
            );
          }}
        </AutoSizer>
      </ErrorBoundaryCmp>
    );
  }
}

export default DataViewWrapper;
