import React from 'react';
import styled from 'styled-components';
import {
  backgroundImage,
  backgroundPosition,
  backgroundRepeat,
  backgroundSize,
  borders,
  borderRadius,
  borderColor,
  boxShadow,
  height,
  width,
  color,
  display,
  fontSize,
  overflow,
  lineHeight,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  textAlign,
  flexWrap,
  space,
  style,
  opacity
} from 'styled-system';
import classNames from 'classnames';
import { whiteSpace } from './utils/typography';

import cursor from './utils/cursor';
import flex from './utils/flex';
import grid from './utils/grid';
import position from './utils/position';

const WrappedFlex = styled.div`
  ${backgroundImage};
  ${backgroundPosition};
  ${backgroundRepeat};
  ${borderRadius};
  ${borderColor};
  ${boxShadow};
  ${backgroundSize};
  ${borders};
  ${color};
  ${display};
  ${height};
  ${width};
  ${flexWrap};
  ${overflow};
  ${style({
    prop: 'overflowX'
  })};
  ${style({
    prop: 'overflowY'
  })};
  ${fontSize};
  ${lineHeight};
  ${maxHeight};
  ${maxWidth};
  ${minHeight};
  ${minWidth};
  ${space};
  ${textAlign};
  ${whiteSpace};

  ${cursor};
  ${flex};
  ${grid};
  ${position};
  ${opacity};
`;

const Flex = React.forwardRef(({ className, runningText, showSkeleton, large, boxRef, ...rest }, ref) => (
  <WrappedFlex
    {...rest}
    ref={ref || boxRef}
    className={classNames(className, {
      'bp4-running-text': runningText,
      'bp4-text-large': large,
      'bp4-skeleton': showSkeleton
    })}
  />
));

export const FlexColumn = (props) => <Flex {...props} flexDirection="column" />;

Flex.defaultProps = {
  display: 'flex',
  flexDirection: 'row'
};

export default Flex;
