import React from 'react';
import classNames from 'classnames';

import { Flex } from 'core/components';

import DimensionMultiValuesRenderer from './DimensionMultiValuesRenderer';
import DimensionValueRenderer from './DimensionValueRenderer';

const ExplorerDimensionMultiValuesRenderer = (props) => {
  const { values, field } = props;
  const { form } = field;

  if (form.getValue('filterDimensionsEnabled')) {
    const className = classNames('dimensions', {
      'read-only': false,
      'pt-intent-danger-text': field.hasError && field.dirty,
      'pt-intent-warning-text': field.hasError && field.pristine,
      'pt-text-muted': values.length === 0 && !field.hasError
    });

    return (
      <span className={className}>
        <div className="pt-input-group">
          <Flex className="pt-menu-item" alignItems="center" justifyContent="space-between">
            <Flex flexDirection="column" className="overflow-hidden" flex={1}>
              <DimensionValueRenderer
                option={{
                  group: 'Filter-Based',
                  label: form.getValue('filterDimensionName'),
                  className: 'dimension-tag-filter'
                }}
                readOnly
                onUnselect={() => {}}
              />
            </Flex>
          </Flex>
        </div>
      </span>
    );
  }

  return <DimensionMultiValuesRenderer {...props} />;
};

export default ExplorerDimensionMultiValuesRenderer;
