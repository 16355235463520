import React from 'react';
import styled, { withTheme, css } from 'styled-components';
import { space, borders, display, borderColor } from 'styled-system';
import { Classes, Dialog as BlueprintDialog } from '@blueprintjs/core';

import classNames from 'classnames';
import position from './utils/position';
import size from './utils/size';
import flex from './utils/flex';

import DialogBody from './DialogBody';
import DialogFooter from './DialogFooter';

const DialogStyled = styled(BlueprintDialog)`
  ${space};
  ${display};
  ${borders};
  ${borderColor};

  ${flex};
  ${position};
  ${size};

  ${({ top, maxHeight }) =>
    !maxHeight &&
    css`
      max-height: calc(100vh - ${(top || 0) * 2 + 48}px);
    `}

  ${({ white, theme }) =>
    white &&
    theme.name === 'light' &&
    css`
      background: ${theme.colors.appBackground};
      .${Classes.DIALOG_BODY} {
        background: ${theme.colors.appBackground};
        border-bottom: ${theme.borders.thin};
      }
      .${Classes.DIALOG_FOOTER} {
        padding-top: 16px;
        background: ${theme.colors.appBackground};
      }
    `}

  .${Classes.DIALOG_HEADER} {
    z-index: 1; /* force the box-shadow to overlay the top of the body */

    h4.${Classes.HEADING} {
      font-size: 16px;
    }
  }
`;

const Dialog = ({ children, actions, theme, className, tracker, ...rest }) => {
  const dialogClassName = classNames(className, tracker);

  return (
    <DialogStyled
      {...rest}
      className={dialogClassName}
      portalClassName={theme.name === 'dark' ? 'bp4-dark' : undefined}
    >
      {children}
    </DialogStyled>
  );
};

Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;

export default withTheme(Dialog);
