import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Box, Button, Collapse, Flex, Icon, Text, Spinner, Suspense } from 'core/components';
import { VirtualizedTable } from 'core/components/table';
import Collection from 'core/model/Collection';
import { InputGroup } from 'core/form';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import { generateEntityMap } from './EntityExplorerMap';
import { EntityRow } from './EntityExplorerRenderers';

@inject('$hybridMap')
@withRouter
@observer
class AWSEntityExplorerWidget extends Component {
  static defaultProps = {
    entityRowHeight: 37,
    contentRef: React.createRef()
  };

  state = {
    loading: true,
    selected: null,
    selectedId: null,
    searchValue: '',
    contentOverflow: 'auto'
  };

  componentDidMount() {
    const { $hybridMap } = this.props;

    $hybridMap.awsCloudMapCollection.fetch().then(() => {
      const topology = this.parseTopology($hybridMap.awsCloudMapCollection.topology);
      this.setState({ loading: false, topology });
    });

    this.ENTITY_MAP = generateEntityMap({ jumpToFn: this.jumpTo });
  }

  componentDidUpdate(prevProps, prevState) {
    const { $hybridMap } = this.props;
    const { topology, selected, selectedId, searchValue } = this.state;
    if (prevState.selected !== selected) {
      if (selected !== null) {
        // Wait for Collapse animation
        setTimeout(() => {
          prevProps.contentRef.current.scrollTo({
            top: selected * prevProps.entityRowHeight,
            left: 0,
            behavior: 'smooth'
          });
          if (selectedId) {
            topology[selected].list.get(selectedId).select();
            this.setState({ selectedId: null });
          }

          if (selected === 0) {
            this.hideScrollbars();
          }

          // hide the scrollbars when scrolling has ended
          prevProps.contentRef.current.addEventListener('scrollend', () => this.hideScrollbars(), { once: true });
        }, 200);
      } else {
        // Wait for Collapse animation, otherwise you get can scrollbars popping in/out
        setTimeout(() => {
          this.setState({ contentOverflow: 'auto' });
        }, 200);
      }
      if (prevState.selected !== null) {
        topology[prevState.selected].list.clearSelection();
        topology[prevState.selected].list.clearSort();
      }
    }
    if (prevState.searchValue !== searchValue) {
      const [region, ...searchableTopology] = topology;

      const regionNames = searchableTopology.reduce((acc, config) => {
        const { name, list } = config;

        if (name === 'instance') {
          // skip the instance collection, it'll need a special search like regions
          return acc;
        }

        // filter the collections we can search directly with the input value
        list.filter(searchValue);

        // get unique regions from the models remaining after the search
        list.models.forEach((model) => {
          let regionName = null;

          if (name === 'nat') {
            // nat gateways have their region a little deeper, in their associated subnet
            const subnetEntity = $hybridMap.awsCloudMapCollection.getEntity({
              entityType: 'Subnets',
              entityId: model.get('SubnetId')
            });

            regionName = subnetEntity?.RegionName;
          } else {
            // most entities have a 'RegionName' property so try for that
            regionName = model.get('RegionName');
          }

          if (regionName) {
            acc.add(regionName);
          }
        });

        return acc;
      }, new Set());

      // assemble a list of unique region names
      const regionFilter = Array.from(regionNames);

      // run the search against the region and instance collection with the unique region names collected from the others
      region.list.filter(undefined, { customFilter: (model) => regionFilter.includes(model.get('Name')) });
      searchableTopology[2].list.filter(undefined, {
        customFilter: (model) => regionFilter.includes(model.get('name'))
      });
    }
  }

  hideScrollbars = () => {
    this.setState({ contentOverflow: 'hidden' });
  };

  parseTopology(data) {
    const topology = [
      {
        name: 'region',
        list: new Collection(data.Hierarchy.Regions.map((reg) => ({ key: reg.Name, ...reg })))
      },
      { name: 'vpc', list: this.objToCollection(data.Entities.Vpcs) },
      { name: 'subnet', list: this.objToCollection(data.Entities.Subnets) },
      {
        name: 'instance',
        list: new Collection(
          Object.keys(data.Entities.InstanceSummary.Regions).map((reg) => ({
            name: reg,
            ...data.Entities.InstanceSummary.Regions[reg]
          }))
        )
      },
      { name: 'directConnect', list: this.objToCollection(data.Entities.DirectConnections) },
      { name: 'directGateway', list: this.objToCollection(data.Entities.DirectConnectGateways) },
      { name: 'internet', list: this.objToCollection(data.Entities.InternetGateways) },
      {
        name: 'nat',
        list: this.objToCollection(
          Object.values(data.Entities.NatGateways).map((natGateway) => {
            const privateIps = natGateway?.NatGatewayAddresses?.map(
              (nateGatewayAddress) => nateGatewayAddress.PrivateIp
            );
            const publicIps = natGateway?.NatGatewayAddresses?.map((nateGatewayAddress) => nateGatewayAddress.PublicIp);
            return {
              ...natGateway,
              publicIps,
              privateIps
            };
          })
        )
      },
      { name: 'transit', list: this.objToCollection(data.Entities.TransitGateways) },
      { name: 'virtual', list: this.objToCollection(data.Entities.VpnGateways) }
    ];

    // Only show instance columns with data
    this.ENTITY_MAP.instance.tableProps.columns.forEach(
      (col) => (col.hidden = !(Object.keys(data.Entities.InstanceSummary).includes(col.name) || col.name === 'name'))
    );

    return topology.filter((entity) => entity.list.size !== 0);
  }

  objToCollection(obj) {
    return new Collection(Object.keys(obj).map((key) => ({ key, ...obj[key] })));
  }

  // Toggles row selection to expand/collapse entity table
  toggleRow({ index }) {
    const { selected } = this.state;
    if (index === selected) {
      this.setState({ selected: null, selectedId: null });
    } else {
      this.setState({ selected: index, selectedId: null });
    }
  }

  // Jumps to the Entity id in the given section
  jumpTo = ({ sectionType, id }) => {
    const { topology } = this.state;
    const sectionIdx = topology.findIndex((entity) => entity.name === sectionType);
    this.setState({ selected: sectionIdx, selectedId: id });
  };

  renderData() {
    const { topology } = this.state;
    if (!topology) {
      return null;
    }
    return (
      <Flex flexDirection="column" position="relative" top="38px" zIndex={1}>
        {topology.map((entity, index) => this.invItemRenderer({ entity, index }))}
      </Flex>
    );
  }

  invItemRenderer({ entity, index }) {
    const { height, entityRowHeight } = this.props;
    const { selected, selectedId } = this.state;
    const { label, icon, renderer, tableProps, rowHeight = 48, expandHeight = 0 } = this.ENTITY_MAP[entity.name];

    return (
      <Box key={entity.name}>
        <EntityRow onClick={() => this.toggleRow({ index })} height={entityRowHeight} selected={index === selected}>
          <Icon icon={icon} iconSize={20} />
          <Text px={1}>{label}</Text>
          <Box flex={1} />
          {renderer && renderer(entity)}
          {!renderer && (
            <Flex justifyContent="flex-end">
              <Text as="div" fontWeight="bold">
                {entity.list.size}
              </Text>
              {entity.list.hasFilter && entity.list.unfilteredSize !== entity.list.size && (
                <Text as="div" muted>
                  &nbsp;{`of ${entity.list.unfilteredSize}`}
                </Text>
              )}
            </Flex>
          )}
        </EntityRow>
        <Collapse isOpen={index === selected}>
          <Flex flexDirection="column" height={height - 115} borderBottom="thin">
            <VirtualizedTable
              collection={entity.list}
              rowHeight={({ model }) => (model && model.isSelected ? rowHeight + expandHeight : rowHeight)}
              scrollToIndex={selectedId ? entity.list.get(selectedId)?.index : undefined}
              scrollToAlignment="start"
              flexed
              {...tableProps}
            />
          </Flex>
        </Collapse>
      </Box>
    );
  }

  handleSearch = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  clearSearch() {
    this.setState({ searchValue: '' });
  }

  render() {
    const { canCustomize, onRemove, contentRef } = this.props;
    const { loading, searchValue, selected, contentOverflow } = this.state;
    const title = 'AWS Entity Explorer';

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        onRemove={onRemove}
        title={title}
        ref={contentRef}
        overflow={contentOverflow}
      >
        <Suspense
          loading={loading}
          fallback={
            <Box pt={2}>
              <Spinner size={24} />
            </Box>
          }
        >
          <Flex width="100%" position="absolute" zIndex={5} bg="appBackground" maxHeight={39} borderBottom="thin">
            <Box width={selected === null ? 0 : 100} overflow="hidden" style={{ transition: 'width 0.2s ease-out' }}>
              <Button
                m="4px 0 4px 4px"
                icon="chevron-left"
                text="Go Back"
                onClick={() => this.toggleRow({ index: selected })}
                width={96}
                outlined
              />
            </Box>
            <Box flex={1} p="4px">
              <InputGroup
                leftIcon="search"
                value={searchValue}
                onChange={this.handleSearch}
                placeholder="Search by name, id, etc..."
                fill
                rightElement={
                  searchValue !== '' ? (
                    <Button small minimal icon="cross" iconSize={16} onClick={() => this.clearSearch()} />
                  ) : null
                }
              />
            </Box>
          </Flex>
          {this.renderData()}
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default AWSEntityExplorerWidget;
