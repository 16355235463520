import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import { AiOutlineLineChart } from 'react-icons/ai';
import { FiGrid } from 'react-icons/fi';
import { Box, Suspense, Spinner, Link, Flex } from 'core/components';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$dashboards.collection', '$savedViews.collection')
@inject('$recentlyViewed')
@observer
class RecentViewsMenuList extends Component {
  state = {
    loading: true
  };

  static getDerivedStateFromProps(props, state) {
    const { $recentlyViewed, $dashboards, $savedViews, loading } = props;

    if (!loading && state.loading) {
      const allViews = $recentlyViewed.collection
        .filter((rv) => rv.id && rv.get('view_type'))
        .map((rv) => {
          const id = rv.get('view_id');
          const type = rv.get('view_type');
          if (type === 'Dashboard') {
            const dashboard = $dashboards.collection.get(id);
            if (dashboard) {
              return {
                id,
                type: 'Dashboard',
                title: dashboard.get('dash_title'),
                link: `/v4/library/dashboards/${id}`,
                model: dashboard,
                icon: FiGrid
              };
            }
            return {};
          }
          const savedView = $savedViews.collection.get(id);
          if (savedView) {
            return {
              id,
              type: 'Saved View',
              title: savedView.get('view_name'),
              link: `/v4/library/saved-views/${savedView.id}`,
              model: savedView,
              icon: AiOutlineLineChart
            };
          }
          return {};
        })
        .filter((view) => view);

      return {
        loading: false,
        recentViews: allViews
          .filter((view) => view.id)
          .slice(-3)
          .reverse()
      };
    }

    return { loading };
  }

  render() {
    const { onClose } = this.props;
    const { loading, recentViews } = this.state;

    if (!recentViews || recentViews.length === 0) {
      return null;
    }

    return (
      <Suspense
        loading={loading}
        fallback={
          <Box mt={2}>
            <Spinner size={24} />
          </Box>
        }
      >
        <Flex flexDirection="column" mt="12px" gap={1} className="recently-viewed-list">
          {recentViews &&
            recentViews.map((view) => (
              <Link
                key={`${view.id}-${view.type}`}
                to={view.link}
                color="body"
                hoverColor="primary"
                onClick={onClose}
                className={Classes.TEXT_OVERFLOW_ELLIPSIS}
                display="block"
              >
                {view.title}
              </Link>
            ))}
        </Flex>
      </Suspense>
    );
  }
}

export default RecentViewsMenuList;
