import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Flex } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';

import ResultsTable from './ResultsTable';

@inject('$exports')
@observer
class ResultsTableDataView extends Component {
  static defaultProps = {
    urlSort: true,
    onQueryComplete: () => {},
    hashEnabled: false,
    hashSortBy: 'sortResultsBy',
    hashSortDir: 'sortResultsDir'
  };

  state = {};

  handleSort = (sortState) => {
    const { $exports, hashSortBy, hashSortDir, hashEnabled } = this.props;

    if (hashEnabled) {
      $exports.setHash({ [hashSortBy]: sortState.field, [hashSortDir]: sortState.direction });
    }
  };

  handleQueryComplete = ({ results, dataview, query, queryModel, fullyLoaded }) => {
    const { onQueryComplete, $exports, hashSortBy, hashSortDir, hashEnabled } = this.props;
    const { field, direction } = results ? results.sortState : {};

    onQueryComplete({ results, dataview, query, queryModel, fullyLoaded });

    if (hashEnabled) {
      $exports.getSettings().then((hashSettings) => {
        const sortResultsBy = hashSettings[hashSortBy];
        const sortResultsDir = hashSettings[hashSortDir];

        if (sortResultsBy && (field !== sortResultsBy || direction !== sortResultsDir)) {
          results.sort(sortResultsBy, sortResultsDir);
        }
      });
    }
  };

  render() {
    const {
      footerActions,
      query,
      showRefresh,
      showLastUpdated,
      urlSort,
      onQueryComplete,
      resultsPresetFilter,
      ...tableProps
    } = this.props;

    return (
      <LightweightDataViewWrapper
        query={query}
        onQueryComplete={this.handleQueryComplete}
        resultsPresetFilter={resultsPresetFilter}
      >
        {({ loading, dataview, queryModel, results: queryResultsCollection, bucket }) => (
          <>
            <ResultsTable
              {...tableProps}
              query={query}
              loading={loading}
              dataview={dataview}
              queryModel={queryModel}
              queryResultsCollection={queryResultsCollection}
              bucket={bucket}
              onSort={this.handleSort}
            />
            {footerActions && (
              <Flex justifyContent="flex-end" alignItems="center" borderRadius="0px 0px 3px 3px" borderTop="thin" p={1}>
                <Flex flex={1} alignItems="center" justifyContent="space-between">
                  {footerActions}
                </Flex>
              </Flex>
            )}
          </>
        )}
      </LightweightDataViewWrapper>
    );
  }
}

export default ResultsTableDataView;
