import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled, { withTheme } from 'styled-components';

import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import InsightsCollection, { ignoreInsightNamePrefixes } from 'app/stores/insight/InsightsCollection';

import { Box, Button, Collapse, EmptyState, Flex, Icon, Spinner, Suspense, Text } from 'core/components';
import { Field, FormComponent, Select } from 'core/form';

import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import InsightList from 'app/components/insights//InsightList';
import withConfigOptions from '../withConfigOptions';

const FamilyRow = styled(Flex)`
  align-items: center;
  cursor: pointer;
  background: ${({ theme, selected }) => (selected ? theme.colors.subnavBackground : '')};
  border-bottom: ${(props) => props.theme.borders.thin};
  padding: 4px;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryBackground};
  }
`;

@inject('$insights')
@withTheme
@withConfigOptions
@observer
class InsightsOverviewWidget extends Component {
  static defaultProps = {
    query: 'fetchInsights',
    familyRowHeight: 34,
    contentRef: React.createRef(),
    fetchParams: {
      pastOrPresent: 'both',
      limit: 200,
      groupLimit: -1,
      creationTimeDuration: '720h',
      ignoreInsightNamePrefixes: ignoreInsightNamePrefixes.concat('custom')
    }
  };

  state = {
    loading: true,
    hasLoaded: false,
    insights: [],
    expandedInsightFamily: undefined,
    contentOverflow: 'hidden'
  };

  componentDidMount() {
    this.refreshInsights();
  }

  componentDidUpdate(prevProps, prevState) {
    const { insights, expandedInsightFamily } = this.state;
    if (prevState.expandedInsightFamily !== expandedInsightFamily) {
      if (expandedInsightFamily) {
        // Wait for Collapse animation
        setTimeout(() => {
          prevProps.contentRef.current?.scrollTo({
            top: insights.families.findIndex((fam) => fam.family === expandedInsightFamily) * prevProps.familyRowHeight,
            left: 0,
            behavior: 'smooth'
          });
        }, 200);
      } else {
        // Wait for Collapse animation, otherwise you get can scrollbars popping in/out
        setTimeout(() => {
          this.setState({ contentOverflow: 'auto' });
        }, 200);
      }
    }
  }

  componentWillUnmount() {
    this.insightsRequest.cancel();
  }

  refreshInsights() {
    const { $insights, fetchParams, model } = this.props;
    const config = model.get();
    const collection = new InsightsCollection({ $insights });

    this.setState({ loading: true, hasLoaded: false });

    // config contains any widget overrides we want to apply
    let insightNames;
    if (config.families) {
      collection.serverFilter = { families: config.families };
      insightNames = collection.getServerFilter().insightNames;
    }
    this.insightsRequest = makeCancelable(
      $insights.fetchInsights({
        ...fetchParams,
        insightNames,
        ...config
      })
    );

    this.insightsRequest.promise
      .then((insightData) => {
        collection.processData(insightData);

        this.setState({
          loading: false,
          hasLoaded: true,
          insights: collection,
          expandedInsightFamily: collection.families[0]?.family
        });
      })
      .catch((err) => {
        if (!(err instanceof CanceledError)) {
          this.setState({ loading: false, insights: collection });
          console.error('Error handling insights request', err);
        }
      });
  }

  renderConfigurePanel() {
    const { handleCancelConfiguration } = this.props;
    const { model } = this.props;

    const fields = {
      creationTimeDuration: {
        label: 'Lookback',
        rules: 'required',
        options: [
          {
            label: '1 day',
            value: '24h'
          },
          {
            label: '2 days',
            value: '48h'
          },
          {
            label: '1 week',
            value: '168h'
          },
          {
            label: '2 weeks',
            value: '336h'
          }
        ]
      }
    };

    return (
      <FormComponent fields={fields} model={model} options={{ name: 'Configure Insights Widget' }}>
        {({ form }) => (
          <Flex flexDirection="column" flex={1} p={2}>
            <Box flex={1}>
              <Field name="creationTimeDuration" large>
                <Select menuWidth={160} />
              </Field>
            </Box>
            <Flex>
              <Button text="Cancel" onClick={() => handleCancelConfiguration(form)} mr={1} minWidth={100} />
              <Button
                text="Save"
                intent="primary"
                disabled={!form.valid}
                onClick={() => this.handleSave(form)}
                minWidth={100}
              />
            </Flex>
          </Flex>
        )}
      </FormComponent>
    );
  }

  handleSave(form) {
    const { handleSaveConfiguration } = this.props;
    handleSaveConfiguration(form);
    this.refreshInsights();
  }

  handleToggleFamily = (family) => {
    const { expandedInsightFamily } = this.state;
    if (expandedInsightFamily === family) {
      this.setState({ expandedInsightFamily: undefined });
    } else {
      this.setState({ expandedInsightFamily: family, contentOverflow: 'hidden' });
    }
  };

  renderWidgetContent() {
    const { $insights, height } = this.props;
    const { insights, expandedInsightFamily, hasLoaded } = this.state;

    if (insights?.size === 0 && hasLoaded) {
      return <EmptyState title="No Insights Found" />;
    }

    return (
      <>
        {insights?.families?.map(({ family, models }) => (
          <Box key={family}>
            <FamilyRow
              selected={expandedInsightFamily === family}
              height={34}
              onClick={() => this.handleToggleFamily(family)}
            >
              <Icon icon={expandedInsightFamily === family ? 'caret-down' : 'caret-right'} p="4px" />
              <Text fontWeight="medium" m={0}>
                {family}{' '}
                <Text fontWeight="normal" color="muted">
                  ({models.length})
                </Text>
              </Text>
            </FamilyRow>

            <Collapse isOpen={expandedInsightFamily === family}>
              <Flex flexDirection="column" height={height - 75} overflow="auto">
                <InsightList insights={new InsightsCollection({ $insights, data: models })} />
              </Flex>
            </Collapse>
          </Box>
        ))}
      </>
    );
  }

  render() {
    const { canCustomize, onRemove, handleShowConfigurePanel, isConfigurePanelOpen, contentRef } = this.props;
    const { loading, hasLoaded, contentOverflow } = this.state;
    const tooltip = 'Insights help you by finding interesting issues with your data and configurations.';

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        configAction={handleShowConfigurePanel}
        onRemove={onRemove}
        title={isConfigurePanelOpen ? 'Configure Insights' : 'Insights Overview'}
        tooltip={tooltip}
        ref={contentRef}
        overflow={contentOverflow}
      >
        <Suspense
          loading={loading && !hasLoaded}
          fallback={
            <Box pt={3}>
              <Spinner size={24} />
            </Box>
          }
        >
          {isConfigurePanelOpen && this.renderConfigurePanel()}
          {!isConfigurePanelOpen && this.renderWidgetContent()}
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default InsightsOverviewWidget;
