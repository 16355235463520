import styled from 'styled-components';
import { healthToThemeColor } from 'app/views/synthetics/utils/syntheticsUtils';

const PopoverDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 6px;
  margin-right: 4px;
  background: ${({ theme, health, isAggregated }) => healthToThemeColor({ health, theme, isAggregated })};
`;

export default PopoverDot;
