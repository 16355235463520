import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Box, Card, LinkButton, EmptyState, Suspense } from 'core/components';
import InterfaceCapacityBitrate from './capacity/InterfaceCapacityBitrate';

@inject('$auth', '$exports', '$interfaces', '$capacity', '$tabs')
@observer
class InterfaceCapacityTab extends Component {
  render() {
    const { loading, capacityPlans, selectedInterface } = this.props;

    return (
      <Box mt={2} className="break-after">
        <Suspense loading={loading}>
          {capacityPlans.length > 0 && (
            <InterfaceCapacityBitrate
              capacity={selectedInterface.get('snmp_speed')}
              plans={capacityPlans}
              selectedInterface={selectedInterface}
            />
          )}
          {capacityPlans.length === 0 && (
            <Card p={2}>
              <EmptyState
                width="100%"
                description="This interface is not being monitored by a capacity plan."
                action={<LinkButton to="/v4/core/capacity" intent="primary" minimal text="Manage Capacity Plans" />}
              />
            </Card>
          )}
        </Suspense>
      </Box>
    );
  }
}

export default InterfaceCapacityTab;
