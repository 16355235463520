import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { round, sortBy, countBy } from 'lodash';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { themeGet, width, space } from 'styled-system';

import { DEFAULT_DATE_FORMAT } from 'core/util/dateUtils';
import { Form, Field, Select } from 'core/form/components';
import { Box, Flex, Link, Tag, Text, Suspense, Spinner } from 'core/components';
import LabelList from 'app/components/labels/LabelList';
import { DEFAULT_SHORTCUTS as LOOKBACK_OPTIONS } from 'core/form/components/LookbackDateRange';
import { getTestTypeLabel, getTypeAndTargetDisplay } from 'app/views/synthetics/utils/syntheticsUtils';

const fields = {
  testSearchFilter: {
    placeholder: 'Filter tests...',
    defaultValue: []
  }
};

const TabsWrapper = styled.div`
  display: flex;
  position: relative;
  ${width};
  border: none;
  border-bottom: ${({ theme, borderless }) => (borderless ? 'none' : `1px solid ${theme.borderColors.tabs}`)};
  margin-bottom: 16px;
  min-height: fit-content;
  ${space}
`;

const StatusDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 6px;
  background: ${({ theme, health }) => theme.colors[health] ?? theme.colors.muted};
`;

const StyledTab = styled(Box)`
  border-bottom: 2px solid transparent;
  background-color: ${themeGet('colors.white')};
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid ${themeGet('borderColors.thin')};
  }

  ${({ active }) =>
    active &&
    css`
      border-bottom: 2px solid ${themeGet('colors.primary')} !important;
      cursor: default;
    `};
`;

@inject('$syn', '$labels')
@Form({ fields, options: { name: 'Test Availability' } })
@observer
class TestAvailability extends Component {
  state = {
    loadingHealth: false,
    healthCounts: {},
    healthFilter: 'total'
  };

  constructor(props) {
    super(props);

    this.setHealthFilter = this.setHealthFilter.bind(this);
  }

  get selectedTimeDisplay() {
    const { selectedTime } = this.props;
    if (selectedTime.starting_time && selectedTime.ending_time) {
      return `${moment.unix(selectedTime.starting_time).utc().format(DEFAULT_DATE_FORMAT)} - ${moment
        .unix(selectedTime.ending_time)
        .utc()
        .format(DEFAULT_DATE_FORMAT)}`;
    }
    return LOOKBACK_OPTIONS.find(({ lookbackSeconds }) => lookbackSeconds === selectedTime.lookback_seconds)?.label;
  }

  get testSearchFilterOptions() {
    const { $syn, testResults } = this.props;

    if (testResults?.availability?.length) {
      return testResults.availability
        .map(({ test_id }) => ({ label: $syn.tests.get(test_id).get('display_name'), value: test_id }))
        .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
    }

    return [];
  }

  componentDidMount() {
    const { testResults } = this.props;
    const { availability } = testResults;

    this.setState({ loadingHealth: true });
    const counts = {
      healthy: countBy(availability, (a) => a.health === 'healthy').true,
      critical: countBy(availability, (a) => a.health === 'critical').true,
      warning: countBy(availability, (a) => a.health === 'warning').true
    };
    this.setState({ loadingHealth: false, healthCounts: counts });
  }

  setHealthFilter(status) {
    this.setState({ healthFilter: status });
  }

  render() {
    const { $syn, $labels, testResults, labels, form, selectedTime } = this.props;
    const testSearchFilter = form.getValue('testSearchFilter');
    const { availability, availabilitySummary } = testResults;
    const selectedLabelIds = [...labels];
    const selectedLabels = $labels.getLabels('synth_test').filter((m) => selectedLabelIds.includes(m.id));
    const { healthCounts, healthFilter, loadingHealth } = this.state;

    const tabs = [
      { label: 'Total', value: 'total', count: availability.length ?? 0, intent: 'muted' },
      { label: 'Healthy', value: 'healthy', count: healthCounts.healthy ?? 0, intent: 'success' },
      { label: 'Critical', value: 'critical', count: healthCounts.critical ?? 0, intent: 'danger' },
      { label: 'Warning', value: 'warning', count: healthCounts.warning ?? 0, intent: 'warning' }
    ];

    return (
      <Box px={2}>
        <Flex justifyContent="space-between" py={0} mb={0} borderTop="thinLight">
          <Box>
            <Flex alignItems="center" mt="5px">
              <Text mr={2} fontSize={12} muted>
                {this.selectedTimeDisplay}
              </Text>
              <Tag
                minWidth={65}
                minimal
                intent={availabilitySummary.intent}
                mr={2}
                fontWeight="bold"
                textAlign="center"
                alignItems="right"
              >
                {round(availabilitySummary.availability * 100, 2)}%
              </Tag>
              <LabelList labels={selectedLabels} fixedWidth />
            </Flex>
          </Box>
        </Flex>
        <Box>
          <Field
            name="testSearchFilter"
            options={this.testSearchFilterOptions}
            small
            m={0}
            mb={1}
            py={1}
            showLabel={false}
          >
            <Select fill multi keepOpen showFilter />
          </Field>
        </Box>

        <Suspense loading={loadingHealth} fallback={<Spinner intent="primary" mx="auto" />}>
          {!loadingHealth && (
            <TabsWrapper>
              {tabs.map(({ label, count, value, intent }) => (
                <StyledTab
                  key={label}
                  px={2}
                  active={healthFilter === value}
                  onClick={() => this.setHealthFilter(value)}
                >
                  <Box>
                    <Flex alignItems="center">
                      <StatusDot health={intent} />
                      <Text muted fontWeight="bold" mr="4px">
                        {label}
                      </Text>
                    </Flex>

                    <Flex alignItems="flex-end" flexWrap="wrap">
                      <Text fontSize={24} fontWeight="bold" mr="4px">
                        {count}
                      </Text>
                    </Flex>
                  </Box>
                </StyledTab>
              ))}
            </TabsWrapper>
          )}
          {sortBy(availability, ['availability'])
            .filter((a) => {
              let include = true;

              if (healthFilter !== 'total') {
                include = a.health === healthFilter;
              }

              if (include && !!testSearchFilter?.length) {
                include = testSearchFilter.includes(a.test_id);
              }

              return include;
            })
            .map(({ test_id, availability: testAvail, intent }) => {
              const testModel = $syn.tests.get(test_id);
              const testType = testModel.get('test_type');
              const target = testModel.get('config.target.value');

              return (
                <Flex key={`${test_id}-availability`} alignItems="center" py={1} borderTop="thinLight">
                  <Tag minWidth={65} minimal intent={intent} mr={2} fontWeight="bold" textAlign="center">
                    {round(testAvail * 100, 2)}%
                  </Tag>
                  <Box>
                    <Text as="div" small muted ellipsis>
                      {getTestTypeLabel(testType)}
                      {getTypeAndTargetDisplay(testType, target)}
                    </Text>
                    <Link
                      to={`/v4/synthetics/tests/${testModel.id}/results`}
                      state={{
                        lookbackSeconds: selectedTime.lookback_seconds,
                        startDate: selectedTime.starting_time,
                        endDate: selectedTime.ending_time
                      }}
                      small
                    >
                      {testModel.get('display_name') || `Test ${testModel.id}`}
                    </Link>
                  </Box>
                </Flex>
              );
            })}
        </Suspense>
      </Box>
    );
  }
}

export default TestAvailability;
