import moment from 'moment';
import Validator from 'validatorjs';

import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import $savedFilters from 'app/stores/savedFilter/$savedFilters';

function transformFilterGroups(value = []) {
  return value.map((filterGroup) => ({ filterGroups: [], not: false, connector: 'All', ...filterGroup }));
}

Validator.register(
  'isValidSavedFilterId',
  /* eslint-disable prefer-arrow-callback */
  function isValidSavedFilterId(value) {
    if (!value) {
      return false;
    }

    return !!$savedFilters.getSavedFilterById(value);
  },
  'Invalid saved filter'
);

function getFilterGroups(prefix, blacklist, options, depth = 0) {
  const canNestFilterGroups = depth < 5;

  const filterGroups = {
    [`${prefix}.filterGroups`]: {
      isComplexArray: true,
      transform: {
        in: (value) => (canNestFilterGroups ? transformFilterGroups(value) : value)
      },
      rules: options.filterGroupsRules,
      suppressAutoSubmit: true
    },

    [`${prefix}.filterGroups[].name`]: {
      label: 'Name',
      rules: [{ requiredIfMultiple: ['filterDimensionsEnabled', true, 'named', true] }],
      messages: {
        required_if: 'Series name is required'
      },
      suppressAutoSubmit: true
    },

    [`${prefix}.filterGroups[].named`]: {
      defaultValue: false,
      suppressAutoSubmit: true
    },

    [`${prefix}.filterGroups[].connector`]: {
      defaultValue: 'All',
      options: [
        {
          label: 'All',
          value: 'All'
        },
        {
          label: 'Any',
          value: 'Any'
        }
      ],
      suppressAutoSubmit: true
    },

    [`${prefix}.filterGroups[].not`]: {
      defaultValue: false,
      options: [
        {
          label: 'Exclude',
          value: true
        },
        {
          label: 'Include',
          value: false
        }
      ],
      suppressAutoSubmit: true
    },

    [`${prefix}.filterGroups[].autoAdded`]: {
      suppressAutoSubmit: true
    },

    [`${prefix}.filterGroups[].filters`]: {
      isComplexArray: true,
      suppressAutoSubmit: true
    },

    [`${prefix}.filterGroups[].saved_filters`]: {
      isComplexArray: true,
      suppressAutoSubmit: true
    },

    [`${prefix}.filterGroups[].saved_filters[].filter_id`]: {
      rules: 'required|isValidSavedFilterId',
      suppressAutoSubmit: true
    },

    [`${prefix}.filterGroups[].saved_filters[].is_not`]: {
      defaultValue: false,
      options: [
        {
          label: 'Exclude Results',
          value: true,
          icon: 'cross'
        },
        {
          label: 'Include Results',
          value: false,
          icon: 'tick'
        }
      ],
      suppressAutoSubmit: true
    },

    [`${prefix}.filterGroups[].filters[].filterField`]: {
      defaultValue: 'dst_as',
      rules: 'required|noConflictingUniques',
      optionsBlacklist: new Set(blacklist),
      label: 'field',
      suppressAutoSubmit: true
    },

    // recon
    [`${prefix}.filterGroups[].filters[].metric`]: {
      label: 'metric',
      suppressAutoSubmit: true
    },

    // recon
    [`${prefix}.filterGroups[].filters[].aggregate`]: {
      label: 'aggregate',
      suppressAutoSubmit: true
    },

    [`${prefix}.filterGroups[].filters[].operator`]: {
      defaultValue: '=',
      rules: 'required',
      label: 'operator',
      suppressAutoSubmit: true
    },

    [`${prefix}.filterGroups[].filters[].filterValue`]: {
      placeholder: 'Value...',
      label: 'value',
      messages: {
        required: 'Please enter a value'
      },
      suppressAutoSubmit: true
    },

    [`${prefix}.filterGroups[].filters[].rightFilterField`]: {
      label: 'value',
      messages: {
        required: 'A right filter field is required'
      },
      suppressAutoSubmit: true
    }
  };

  if (canNestFilterGroups) {
    Object.assign(filterGroups, getFilterGroups(`${prefix}.filterGroups[]`, blacklist, {}, depth + 1));
  }

  return filterGroups;
}

export function generateFilterFields(fieldName = 'filters', blacklist = [], options = {}) {
  const filterFields = {
    [`${fieldName}.connector`]: {
      defaultValue: 'All',
      options: [
        {
          label: 'All',
          value: 'All'
        },
        {
          label: 'Any',
          value: 'Any'
        }
      ],
      suppressAutoSubmit: true
    }
  };

  Object.assign(filterFields, getFilterGroups(fieldName, blacklist, options));

  return filterFields;
}

export function generateTimeFields(rootFieldName) {
  const prefix = rootFieldName ? `${rootFieldName}.` : '';

  const timeInTransform = (value, form) => {
    const time_format = form.getValue(`${prefix}time_format`);
    const lookback_seconds = form.getValue(`${prefix}lookback_seconds`);
    if (!value || lookback_seconds) {
      return value;
    }

    if (!time_format || time_format === 'UTC') {
      // we're assuming UTC here but we want to force the format so timezone offsets don't throw moment off inside the field
      return moment.utc(value).format(DEFAULT_DATETIME_FORMAT);
    }
    // converting UTC time to local time.
    return moment(moment.utc(value).valueOf()).format(DEFAULT_DATETIME_FORMAT);
  };

  const timeOutTransform = (value, form) => {
    const time_format = form.getValue(`${prefix}time_format`);
    const lookback_seconds = form.getValue(`${prefix}lookback_seconds`);
    if (!value || lookback_seconds) {
      return value;
    }

    // sometimes we get a Date object here because the user used the form field to change something
    // Blueprint Date components return Date objects.
    if (value instanceof Date) {
      value = moment(value).format(DEFAULT_DATETIME_FORMAT);
    }
    if (!time_format || time_format === 'UTC') {
      // UTC mode, so just return the value that came out of the field
      return value;
    }
    // Local mode, so take value and convert it to UTC.
    return moment(value).utc().format(DEFAULT_DATETIME_FORMAT);
  };

  return {
    [`${prefix}lookback_seconds`]: {
      label: 'Show The',
      defaultValue: 3600
    },
    [`${prefix}from_to_lookback`]: {
      label: 'Show The',
      defaultValue: 0
    },
    [`${prefix}time_format`]: {
      label: 'Time Zone',
      options: [
        { value: 'UTC', label: 'UTC' },
        { value: 'Local', label: 'Local' }
      ]
    },
    [`${prefix}starting_time`]: {
      label: 'From',
      messages: {
        before: 'Start date must be before end date'
      },
      transform: {
        in: timeInTransform,
        out: timeOutTransform
      }
    },
    [`${prefix}ending_time`]: {
      label: 'To',
      transform: {
        in: timeInTransform,
        out: timeOutTransform
      }
    },
    [`${prefix}period_over_period`]: {
      label: 'Compare over previous period'
    },
    [`${prefix}period_over_period_lookback`]: {
      label: 'previous offset',
      rules: 'integer'
    },
    [`${prefix}period_over_period_lookback_unit`]: {
      options: [
        { label: 'hour', value: 'hour' },
        { label: 'day', value: 'day' },
        { label: 'week', value: 'week' },
        { label: 'month', value: 'month' }
      ]
    },
    [`${prefix}use_alt_timestamp_field`]: {
      label: 'Use AWS Timestamps (Beta)'
    }
  };
}
