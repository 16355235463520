import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CalloutOutline, Flex, Icon } from 'core/components';
import { VirtualizedTable } from 'core/components/table';
import DataViewSpinner from 'app/components/dataviews/DataViewSpinner';
import Collection from 'core/model/Collection';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import RawFlowDataViewModel from './RawFlowDataViewModel';

export const config = {
  model: RawFlowDataViewModel,
  hideFromViewMenu: true
};

@inject('$rawFlow')
@observer
class RawFlow extends Component {
  state = {
    sortField: '',
    sortDir: 'desc'
  };

  getSortField() {
    const { sortField } = this.state;
    const { dataview } = this.props;
    return sortField || dataview.viewModel.get('rawFlowQuery.order_by');
  }

  handleSort = (column) => {
    const { sortDir } = this.state;
    const sortField = this.getSortField();

    if (sortField === column.name) {
      this.setState({ sortDir: sortDir === 'desc' ? 'asc' : 'desc' });
    } else {
      this.setState({ sortField: column.name, sortDir: 'desc' });
    }
  };

  getColumns(columnNames) {
    const { $rawFlow } = this.props;
    const { sortDir } = this.state;
    const sortField = this.getSortField();

    return $rawFlow.getTableColumns(columnNames).map((column) => {
      const sorted = sortField === column.name;
      return { ...column, sorted, sortDir: sorted ? sortDir : 'desc' };
    });
  }

  render() {
    const { dataview } = this.props;
    const { sortDir } = this.state;
    const { viewModel } = dataview;

    if (dataview.loading) {
      return (
        <Flex flex="1 1 auto" flexDirection="column" mr={-2}>
          <Flex flex="1 1 auto">
            <DataViewSpinner loadingText="Loading Raw Flow data..." showSpinner={dataview.loading} />
          </Flex>
        </Flex>
      );
    }

    if (viewModel.get('error')) {
      return (
        <Flex justifyContent="center" top={0} position="absolute" mt="40px" width="100%">
          <CalloutOutline intent="danger" textAlign="center" width="90%" maxWidth={400}>
            <Flex alignItems="flex-start">
              <Icon icon={BsFillExclamationTriangleFill} color="danger" mr={1} mt="1px" />
              {viewModel.get('error')}
            </Flex>
          </CalloutOutline>
        </Flex>
      );
    }
    const columns = viewModel.get('columns');
    const results = viewModel.get('results');
    const sortField = this.getSortField();
    const collection = new Collection(results, { sortState: { field: sortField, direction: sortDir } });
    const showTable = !collection.isEmpty();

    return (
      <Flex flex="1 1 auto" flexDirection="column" mr={-2}>
        {showTable && (
          <VirtualizedTable
            collection={collection}
            columns={this.getColumns(columns)}
            selectOnRowClick={false}
            onSortClick={this.handleSort}
            flexed
          />
        )}
      </Flex>
    );
  }
}

export default RawFlow;
