import React from 'react';
import { Icon } from 'core/components';

const iconProps = { mr: '4px', iconSize: 12, style: { position: 'relative', top: -1 } };

const KeyTrafficIndicator = ({ percentage, factor }) => {
  const opacity = factor ? factor * 0.8 + 0.2 : 1;

  if (!percentage) {
    return <Icon {...iconProps} icon="blank" />;
  }

  if (percentage > 0) {
    return <Icon {...iconProps} icon="arrow-up" color="success" css={{ opacity }} />;
  }

  return <Icon {...iconProps} icon="arrow-down" color="danger" css={{ opacity }} />;
};

export default KeyTrafficIndicator;
