import React from 'react';
import styled, { useTheme } from 'styled-components';
import { isEmpty, isPlainObject, omit, orderBy, pick } from 'lodash';
import { Box, Text } from 'core/components';

// TODO: figure out better way to do ordering?
const order = {
  0: ['apiVersion', 'kind', 'metadata', 'spec'],
  1: ['name', 'labels', 'finalizers']
};

function isPrimitive(value) {
  return typeof value !== 'object' || value === null;
}

function renderPrimitive(value) {
  if (typeof value === 'boolean') {
    return value ? 'true' : 'false';
  }

  if (value === null) {
    return 'null';
  }

  return value;
}

const Item = styled(Box)`
  position: relative;

  &::before {
    content: '-';
    position: absolute;
    font-family: ${(props) => props.theme.fonts.monospace};
    line-height: 22px;
  }
`;

const RecursiveDataRenderer = ({ data, level }) => {
  const theme = useTheme();

  const textProps = { monospace: true, lineHeight: '22px' };
  const indent = level === 0 ? 0 : '2ch';

  return (
    <>
      {isPrimitive(data) && (
        <Text ml={indent} {...textProps}>
          {renderPrimitive(data)}
        </Text>
      )}
      {Array.isArray(data) &&
        data?.map((key, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Item ml={indent} key={idx}>
            <RecursiveDataRenderer data={key} level={level + 1} />
          </Item>
        ))}

      {/* order keys if associated order list exists */}
      {isPlainObject(data) &&
        (order[level] ? orderBy(Object.keys(data), (item) => order[level].indexOf(item)) : Object.keys(data))?.map(
          (key) => {
            if ((isPlainObject(data[key]) || Array.isArray(data[key])) && isEmpty(data[key])) {
              return null;
            }

            return (
              <Box key={key} ml={indent}>
                <Text color={theme.name === 'light' ? 'green2' : 'green4'} {...textProps}>
                  <b>{`${key}:`}</b>
                </Text>
                {isPrimitive(data[key]) ? (
                  <Text pl="1ch" {...textProps}>
                    {renderPrimitive(data[key])}
                  </Text>
                ) : (
                  <RecursiveDataRenderer data={data[key]} level={level + 1} />
                )}
              </Box>
            );
          }
        )}
    </>
  );
};

export default function YAMLRenderer({ yaml }) {
  const theme = useTheme();

  return (
    <Box bg={theme.name === 'light' ? 'lightGray5' : 'darkGray1'} mx={2} p={1} overflow="auto" whiteSpace="nowrap">
      <RecursiveDataRenderer data={yaml} level={0} />
    </Box>
  );
}

export function getYAML(nodeData) {
  // parse all the fields here based on type and pass it into code block
  const yaml = omit(pick(nodeData, ['apiVersion', 'metadata', 'spec']), [
    'metadata.creationTimestamp',
    'metadata.resourceVersion',
    'metadata.uid'
  ]);

  Object.assign(yaml, { kind: nodeData.typeLabel });

  return <YAMLRenderer yaml={yaml} />;
}
