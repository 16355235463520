import { Model } from 'core/model';
import { computed } from 'mobx';
import PeeringExchangeCollection from './PeeringExchangeCollection';
import PeeringNetworkCollection from './PeeringNetworkCollection';

export default class PeeringFacilityModel extends Model {
  exchanges = new PeeringExchangeCollection();

  networks = new PeeringNetworkCollection();

  get hasFetched() {
    return !!this.get('networks');
  }

  get urlRoot() {
    return '/api/ui/peering/peeringdb/facility';
  }

  get type() {
    return 'facility';
  }

  get sortValues() {
    return {
      common: () => (this.get('common') ? 0 : 1),
      name: () => this.get('name').toLowerCase()
    };
  }

  /**
   * address without city, state, country
   */
  @computed
  get address() {
    return [
      this.get('address1'),
      this.get('address2'),
      this.get('floor') && `Floor ${this.get('floor')}`,
      this.get('suite') && `Suite ${this.get('suite')}`
    ]
      .filter(Boolean)
      .join(', ');
  }

  @computed
  get location() {
    return [this.address, this.get('city'), this.get('state'), this.get('zipcode'), this.get('country')]
      .filter(Boolean)
      .join(', ');
  }

  @computed
  get multiLineLocation() {
    return [
      [this.get('address1')],
      [this.get('address2')],
      [this.get('floor') && `Floor ${this.get('floor')}`, this.get('suite') && `Suite ${this.get('suite')}`],
      [this.get('city'), this.get('state'), this.get('zipcode')],
      [this.get('country')]
    ]
      .map((line) => line.filter(Boolean).join(', '))
      .filter(Boolean)
      .join('\n');
  }

  @computed
  get peered() {
    const asns = this.get('asns') || [];
    const peeredAsns = this.collection.peeredAsns || new Set();
    return asns.some((asn) => peeredAsns.has(asn));
  }

  @computed
  get isMappable() {
    return this.get('latitude') && this.get('longitude');
  }

  deserialize(data = {}) {
    const { exchanges, networks } = data;

    if (Array.isArray(exchanges) && exchanges.length > 0) {
      this.exchanges.set(exchanges);
      data.ix_count = exchanges.length;
    }

    if (Array.isArray(networks) && networks.length > 0) {
      this.networks.set(networks);
      data.net_count = networks.length;
    }

    return data;
  }
}
