import styled, { css } from 'styled-components';
import { Classes } from '@blueprintjs/core';
import { Grid } from 'core/components';

// embedded mode will render the grid in a single column
// this is used in tests such as page load with bgp where we have bgp test results that would normally have a TestResultsGrid of its own
const TestResultsGrid = styled(Grid)`
  ${({ theme, labelWidth = '150px', embedded }) => css`
    margin-top: ${embedded ? '0px' : '24px'};
    grid-template-columns: ${embedded ? '1fr' : `${labelWidth} 1fr`};

    .${Classes.TAB_LIST} {
      width: ${labelWidth};
      top: 24px;
    }

    .${Classes.TAB_LIST}, h6 {
      position: sticky;
      height: fit-content;
    }

    .${Classes.TAB_PANEL} {
      min-width: 0px; // prevent tab panels from overflowing their parents
    }

    h6 {
      background: ${theme.colors.appBackground};
      top: 0px;
    }
  `}
`;

export default TestResultsGrid;
