import React from 'react';
import { Classes, PopoverInteractionKind } from '@blueprintjs/core';
import { Flex, Icon, Popover } from 'core/components';

const PopoverLabel = ({ label = '', popoverText, ...popoverProps }) => (
  <span>
    {label}{' '}
    <Popover
      interactionKind={PopoverInteractionKind.HOVER}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      targetProps={{ style: { display: 'inline-block' } }}
      {...popoverProps}
    >
      <Flex alignItems="center" color="primary">
        {popoverText}
        <Icon icon="search" ml="4px" iconSize={10} />
      </Flex>
    </Popover>
  </span>
);

export default PopoverLabel;
