import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import classNames from 'classnames';

import Box from 'core/components/Box';
import Callout from 'core/components/Callout';
import Card from 'core/components/Card';
import Flex from 'core/components/Flex';
import Icon from 'core/components/Icon';

import { getOption } from 'core/form/components/modalSelect/selectHelpers';

const DragHandle = SortableHandle(() => (
  <Box small css={{ cursor: 'move', position: 'relative', left: -3 }}>
    <Icon icon="drag-handle-vertical" color="muted" />
  </Box>
));

export const ModalValueItem = (props) => {
  const { reorderable, option, onClose, valueRenderer: SingleValue, itemStyle, itemFlexed = true } = props;

  return (
    <Flex alignItems="center" css={itemStyle} className="sortable-helper" mb="4px">
      {reorderable && <DragHandle />}
      <Flex flexDirection="column" flex={itemFlexed ? 1 : 0} overflow="hidden">
        <SingleValue {...props} onUnselect={onClose} option={option} style={{ marginBottom: 0 }} />
      </Flex>
    </Flex>
  );
};

const ModalValueList = (props) => {
  const {
    values,
    options,
    noSelectedValuesText,
    onClose,
    reorderable,
    valuesItemStyle,
    selectedValuesSubitle,
    mustHaveDimensions = []
  } = props;

  const ValueItem = reorderable ? SortableElement(ModalValueItem) : ModalValueItem;

  const items = values.map((value, index) => {
    const valueOption = getOption(options, value) || {
      value,
      label: value,
      className: Classes.INTENT_DANGER,
      group: 'Invalid'
    };

    if (valueOption.always_active) {
      return null;
    }

    return (
      <ValueItem
        key={value}
        {...props}
        option={valueOption}
        onClose={() => onClose(index)}
        index={index}
        readOnly={mustHaveDimensions.includes(value)}
      />
    );
  });

  if (selectedValuesSubitle) {
    items.splice(
      selectedValuesSubitle.position,
      0,
      <Box key="selectedValuesSubtitle" mt={1} mb="4px" fontWeight="bold">
        {selectedValuesSubitle.text}
      </Box>
    );
  }

  return (
    <Box css={valuesItemStyle}>
      {items}
      {values.length === 0 && <Callout textAlign="center">{noSelectedValuesText}</Callout>}
    </Box>
  );
};

const SortableModalValueList = SortableContainer(ModalValueList);

@observer
class SelectedValuesPanel extends Component {
  static defaultProps = {
    selectedValuesTitle: 'Selected Items',
    noSelectedValuesText: 'No items selected',
    selectedValuesSubitle: undefined,
    showCard: true,
    allowSelection: true,
    itemStyle: {},
    valuesItemStyle: {},
    valueListProps: {},
    reorderable: false
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { values, onChange } = this.props;
    onChange(arrayMove(values, oldIndex, newIndex), true);
  };

  onCloseItem = (index) => {
    const { values, onChange } = this.props;
    onChange(values.slice(0, index).concat(values.slice(index + 1)));
  };

  render() {
    const { reorderable = true, selectedValuesTitle, valueListProps, showCard, disabled } = this.props;

    const ValueList = reorderable ? SortableModalValueList : ModalValueList;
    const valueList = (
      <ValueList
        {...this.props}
        lockToContainerEdges
        lockAxis="y"
        onClose={this.onCloseItem}
        onSortEnd={this.onSortEnd}
        useDragHandle
      />
    );

    if (!showCard) {
      return valueList;
    }

    const cardClassName = classNames({ [Classes.DISABLED]: disabled });

    return (
      <Card p={1} display="flex" flexDirection="column" flex={1} overflow="hidden" className={cardClassName}>
        <Box fontWeight="bold" mb="4px">
          {selectedValuesTitle}
        </Box>
        <Box flex={1} overflow="auto" {...valueListProps}>
          {valueList}
        </Box>
      </Card>
    );
  }
}

export default SelectedValuesPanel;
