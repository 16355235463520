import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';
import { hexToRgbA } from 'core/util/colors';
import { formatDateTime } from 'core/util/dateUtils';
import BaseHighchartsNonStyledDataview from 'app/components/dataviews/views/BaseHighchartsNonStyledDataview';
import { checkSeriesDataForFailure } from 'app/views/synthetics/utils/syntheticsUtils';
import withTrendChart from './withTrendChart';

@withTrendChart
@inject('$app', '$dataviews')
@observer
class CSSSelectorSeries extends BaseHighchartsNonStyledDataview {
  state = {};

  constructor(props) {
    super(props);

    const { theme, onHover, xAxisMin, xAxisMax } = this.props;
    const { colors, fonts } = theme;

    const seriesDefault = (color) => ({
      color,
      borderColor: colors.chart.gridLine,
      borderWidth: 1,
      point: {
        events: {
          mouseOver: (e) => {
            if (onHover) {
              onHover(e.target.options.time);
            }
          }
        }
      },
      marker: {
        lineWidth: 0,
        lineWidthPlus: 0,
        radius: 4,
        symbol: '●',
        states: {
          hover: {
            enabled: false,
            lineWidthPlus: 0
          },
          select: {
            enabled: true,
            fillColor: hexToRgbA(color, 0.7),
            lineColor: 'rgba(255, 255, 255, 0.5)',
            lineWidth: 5,
            radius: 6
          }
        }
      },
      dataGrouping: {
        enabled: false
      }
    });

    this.state = {
      chartOptions: {
        chart: {
          type: 'line',
          style: { fontFamily: fonts.body },
          height: 200,
          spacingLeft: 0,
          spacingRight: 0,
          backgroundColor: colors.chart.tooltipBackground
        },
        title: { text: '' },
        credits: { enabled: false },
        legend: { enabled: false },
        xAxis: {
          type: 'datetime',
          lineColor: colors.chart.axisLine,
          gridLineColor: colors.chart.gridLine,
          tickColor: colors.chart.axisTick,
          labels: {
            style: {
              color: colors.muted
            },
            formatter() {
              if (this.dateTimeLabelFormat === '%b %e') {
                return `<strong>${formatDateTime(this.value, 'MMM Do')}</strong>`;
              }

              return this.axis.defaultLabelFormatter.call(this);
            }
          },
          dateTimeLabelFormats: {
            day: '%b %e',
            week: '%b %e'
          },
          min: xAxisMin,
          max: xAxisMax
        },
        yAxis: [
          {
            title: { text: '' },
            gridLineColor: colors.chart.gridLine,
            labels: {
              align: 'left',
              x: 0,
              y: -2,
              style: {
                color: colors.muted
              }
            },
            allowDecimals: false,
            offset: 20
          }
        ],
        plotOptions: {
          series: {
            turboThreshold: 0,
            marker: {
              enabled: false
            }
          },
          column: {
            stacking: 'normal',
            groupPadding: 0,
            pointPadding: 0
          }
        },
        tooltip: {
          outside: true,
          useHTML: true,
          backgroundColor: colors.chart.tooltipBackground,
          shared: true,
          style: {
            color: colors.body,
            fontFamily: fonts.body
          },
          headerFormat: '',
          pointFormatter() {
            return this.point.results
              .map(
                (result) => `
                  <p style="margin: 6px 0 0 0 ;">Name: <strong>${result.key}</strong> - ${
                    result.found ? 'Found' : 'Not Found'
                  }</p>
                `
              )
              .join('');
          },
          formatter() {
            if (this.points) {
              return `
                <p style="margin: 0"><strong>${formatDateTime(this.x, 'MMM D, HH:mm')}</strong></p>
                <div>
                  ${this.points.map((point) => point.series.tooltipOptions.pointFormatter.call(point)).join('')}
                </div>
              `;
            }

            return null;
          }
        },
        series: [
          {
            data: [],
            ...seriesDefault('#3b75af')
          }
        ]
      }
    };
  }

  get type() {
    return 'timeline';
  }

  redraw() {
    if (this.chart) {
      const { theme } = this.props;
      const { colors, fonts } = theme;

      this.setState({
        chartOptions: {
          chart: {
            backgroundColor: colors.chart.tooltipBackground
          },
          tooltip: {
            backgroundColor: colors.chart.tooltipBackground,
            style: {
              color: colors.body,
              fontFamily: fonts.body
            }
          },
          xAxis: {
            lineColor: colors.chart.axisLine,
            gridLineColor: colors.chart.gridLine,
            tickColor: colors.chart.axisTick,
            crosshair: {
              color: colors.chart.crosshair
            },
            labels: {
              style: {
                color: colors.muted
              }
            }
          },
          yAxis: [
            {
              gridLineColor: colors.chart.gridLine,
              labels: {
                style: {
                  color: colors.muted
                }
              }
            }
          ]
        }
      });
    }
  }

  renderData() {
    if (this.chart) {
      const { baseline, data, theme } = this.props;
      const { colors } = theme;

      this.setState(({ chartOptions }) => {
        const series0 = data.reduce((acc, curr, i) => {
          const currentData = curr?.data || [];
          const parsedData = typeof currentData === 'string' ? JSON.parse(currentData) : currentData;
          const { css_selector_data } = parsedData[0] || {};
          const { time } = curr;
          const base = { time, x: time };

          if (checkSeriesDataForFailure(curr)) {
            acc.push({ ...base, y: null, results: [] });
          } else {
            const marker = {
              enabled: checkSeriesDataForFailure(data[i - 1]) || checkSeriesDataForFailure(data[i + 1])
            };

            if (css_selector_data && css_selector_data.length) {
              acc.push({
                ...base,
                y: css_selector_data.filter((result) => result.found).length,
                results: css_selector_data,
                marker
              });
            } else {
              acc.push({ ...base, y: 0, results: [], marker });
            }
          }

          return acc;
        }, []);

        chartOptions.series[0].data = series0;
        chartOptions.yAxis[0].max = baseline + 1;
        chartOptions.yAxis[0].min = 0;
        chartOptions.yAxis[0].plotLines = [
          {
            value: baseline,
            color: colors.muted,
            width: 1,
            dashStyle: 'shortdash',
            zIndex: 1,
            label: { style: { color: colors.muted }, x: 20, text: 'Total Configured' }
          }
        ];

        return { chartOptions };
      });
    }
  }
}

export default withTheme(CSSSelectorSeries);
