import React, { Component } from 'react';
import moment from 'moment';
import { Position } from '@blueprintjs/core';
import { formatDateTime } from 'core/util/dateUtils';
import { inject, observer } from 'mobx-react';
import { Dialog, Button, Text, Box, Flex, FlexColumn } from 'core/components';
import { InputGroup, Checkbox, TextArea, Form, Field, DateTime, Select, Radio, SingleLineErrors } from 'core/form';

const fields = {
  comment: {
    name: 'comment',
    label: 'Comment',
    placeholder: 'Add optional comment...',
    defaultValue: ''
  },
  silence: {
    name: 'silence',
    label: 'Silence notifications for this alert',
    defaultValue: false
  },
  autoAck: {
    name: 'autoAck',
    label: 'Acknowledge additional occurrences (auto-ack)',
    defaultValue: false
  },
  durationType: {
    label: 'Duration type',
    defaultValue: 'for',
    options: [
      { label: 'for', value: 'for' },
      { label: 'until', value: 'until' }
    ]
  },
  endTime: {
    label: 'End Time',
    placeholder: 'Select an end time...',
    rules: [{ required_if: ['durationType', 'until'] }],
    messages: {
      required_if: ':attribute is required.'
    }
  },
  durationLength: {
    label: 'Duration length',
    defaultValue: 3,
    rules: [], // Set dynamically in component
    messages: {
      required_if: ':attribute is required.'
    }
  },
  durationUnit: {
    label: 'Duration unit',
    options: [
      { label: 'hours', value: 'hours' },
      { label: 'days', value: 'days' }
    ],
    defaultValue: 'hours'
  }
};

@inject('$auth', '$alerting')
@Form({ fields, options: { name: 'Alert Ack' } })
@observer
export default class AckDialog extends Component {
  static defaultProps = {
    onSubmit: () => {},
    onClose: () => {}
  };

  componentDidMount() {
    this.handleDurationUnitChange();
  }

  componentWillUnmount() {
    const { form } = this.props;
    form.reset();
  }

  handleClose = (e) => {
    const { form, onClose } = this.props;
    form.reset();
    onClose(e);
  };

  handleSubmit = (formValues) => {
    const { form, onSubmit } = this.props;
    const { comment, autoAck, silence, endTime, durationType, durationLength, durationUnit } = formValues || {};
    const formData = { comment };
    let calculatedEndTime = moment(endTime).toISOString();

    if (durationType === 'for') {
      calculatedEndTime = moment.utc().add(durationLength, durationUnit).toISOString();
    }

    if (autoAck) {
      formData.autoAck = { endTime: calculatedEndTime };
    }

    if (silence) {
      formData.silence = { endTime: calculatedEndTime };
    }

    form.reset();
    onSubmit(formData);
  };

  handleDurationTypeChange = (value) => {
    const { form } = this.props;
    form.setValue('durationType', value);
    this.handleDurationUnitChange();
  };

  handleDurationUnitChange = (value) => {
    const { $auth, form } = this.props;
    const durationUnit = value || form.getValue('durationUnit');
    const durationLengthField = form.getField('durationLength');
    const durationType = form.getValue('durationType');
    let rules = ['integer', 'min:1'];

    if ($auth.isAdministrator) {
      rules.push(durationUnit === 'days' ? 'max:365' : 'max:24');
    } else {
      rules.push(durationUnit === 'days' ? 'max:7' : 'max:24');
    }

    if (durationType === 'for') {
      rules.push('required');
    } else {
      rules = [];
    }

    durationLengthField.setRules(rules);
  };

  render() {
    const { $auth, alertModel, isOpen, form } = this.props;
    const showTimeControls = form.getValue('autoAck') || form.getValue('silence');
    const durationType = form.getValue('durationType');

    return (
      <Dialog
        title="Acknowledge Alert"
        isOpen={isOpen}
        onClose={this.handleClose}
        top={100}
        width={450}
        position="fixed"
      >
        <Dialog.Body>
          <Text as="div" muted>
            Alert will be shown as acknowledged by <Text fontWeight="500">{$auth.activeUser.user_full_name}</Text>.
          </Text>
          <Box mt={2}>
            <Field name="comment" large showLabel={false}>
              <TextArea fill rows={3} autoFocus autoGrow />
            </Field>
            <FlexColumn gap="4px">
              <Field name="autoAck" large showLabel={false} mb={0}>
                <Checkbox large />
              </Field>
              <Field name="silence" disabled={!!alertModel.silenceModel} large showLabel={false} mb={0}>
                <Checkbox
                  label={`${fields.silence.label}${alertModel.silenceModel ? '. (Alert already silenced)' : ''}`}
                  large
                />
              </Field>
            </FlexColumn>
            {showTimeControls && (
              <FlexColumn gap={1} mt={1}>
                <Text muted>Choose a duration for the selected options:</Text>
                <Flex gap={1} alignItems="center">
                  <Radio
                    value="for"
                    checked={durationType === 'for'}
                    onChange={() => this.handleDurationTypeChange('for')}
                    inline
                    label="for"
                    style={{ marginBottom: 0, marginRight: 0 }}
                  />
                  <Field
                    name="durationLength"
                    showLabel={false}
                    showError={false}
                    width="45px"
                    mb={0}
                    disabled={durationType !== 'for'}
                    large
                  >
                    <InputGroup type="number" />
                  </Field>
                  <Field
                    name="durationUnit"
                    showLabel={false}
                    showError={false}
                    disabled={durationType !== 'for'}
                    onChange={(field, value) => this.handleDurationUnitChange(value)}
                    width="auto"
                    mb={0}
                    large
                  >
                    <Select width="auto" />
                  </Field>
                  <Box>
                    <Text muted>
                      (
                      {formatDateTime(
                        moment.utc().add(form.getValue('durationLength'), form.getValue('durationUnit')).toISOString()
                      )}
                      )
                    </Text>
                  </Box>
                </Flex>
                <Flex gap={1} alignItems="center">
                  <Radio
                    value="until"
                    checked={durationType === 'until'}
                    onChange={() => this.handleDurationTypeChange('until')}
                    inline
                    label="until"
                    style={{ marginBottom: 0, marginRight: 0 }}
                  />
                  <Field
                    name="endTime"
                    showLabel={false}
                    showError={false}
                    disabled={durationType !== 'until'}
                    large
                    mb={0}
                  >
                    <DateTime
                      width="auto"
                      timePrecision={null}
                      minDate={moment().toDate()}
                      maxDate={moment()
                        .add(1, $auth.isAdministrator ? 'year' : 'week')
                        .toDate()}
                      popoverProps={{
                        minimal: true,
                        modifiers: {
                          flip: { enabled: true, boundariesElement: 'viewport' },
                          preventOverflow: { enabled: true, boundariesElement: 'window' },
                          offset: { enabled: true, offset: '0, 8' }
                        },
                        position: Position.BOTTOM_RIGHT
                      }}
                    />
                  </Field>
                </Flex>
                <SingleLineErrors fields={[form.getField('durationLength'), form.getField('endTime')]} />
              </FlexColumn>
            )}
          </Box>
        </Dialog.Body>
        <Dialog.Footer justifyContent="space-between">
          <Flex width="100%" gap={1}>
            <Button flex={1} text="Cancel" onClick={this.handleClose} />
            <Button
              flex={1}
              onClick={() => this.handleSubmit(form.getValues())}
              text="Confirm"
              intent="primary"
              disabled={!form.valid}
            />
          </Flex>
        </Dialog.Footer>
      </Dialog>
    );
  }
}
