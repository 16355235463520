import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';

import { formatDateTime, dateRangeDisplay } from 'core/util/dateUtils';
import BaseHighchartsNonStyledDataview from 'app/components/dataviews/views/BaseHighchartsNonStyledDataview';
import { INVALID_ORIGIN_COLOR, WARNING_COLOR } from 'app/views/synthetics/utils/syntheticsUtils';

@withTheme
@inject('$app', '$auth', '$dataviews')
@observer
class StackedAlarmTimeSeries extends BaseHighchartsNonStyledDataview {
  removePreviousSelection = null;

  constructor(props) {
    super(props);
    const { $auth, onHover, theme } = this.props;
    const { colors, fonts } = theme;
    const { userTimezone } = $auth;

    this.state = {
      userTimezone,
      stickyPointer: true,
      highlightPointsByEvent: true,
      chartOptions: {
        chart: {
          type: 'column',
          style: { fontFamily: fonts.body },
          height: 200,
          spacingLeft: 0,
          spacingRight: 0,
          backgroundColor: colors.chart.tooltipBackground
        },
        colors: [
          INVALID_ORIGIN_COLOR, // RED
          WARNING_COLOR, // ORANGE
          'transparent' // Healthy Series
        ],
        title: { text: '' },
        credits: { enabled: false },
        legend: { enabled: false },
        xAxis: {
          type: 'datetime',
          gridLineColor: colors.chart.gridLine,
          lineColor: colors.chart.axisLine,
          tickColor: colors.chart.axisTick,
          labels: {
            align: 'center',
            style: {
              color: colors.muted
            },
            formatter() {
              if (this.dateTimeLabelFormat === '%b %e') {
                return `<strong>${formatDateTime(this.value, 'MMM Do')}</strong>`;
              }

              return this.axis.defaultLabelFormatter.call(this);
            }
          },
          dateTimeLabelFormats: {
            day: '%b %e',
            week: '%b %e'
          }
        },
        yAxis: {
          title: { text: '' },
          offset: 30,
          stackLabels: { enabled: false },
          gridLineColor: colors.chart.gridLine,
          labels: {
            align: 'left',
            x: 0,
            y: 0,
            style: {
              color: colors.muted
            }
          }
        },
        tooltip: {
          shared: true
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: { enabled: false },
            states: {
              hover: {
                borderColor: colors.turquoise5,
                borderWidth: 1
              },
              select: { enabled: true, borderColor: colors.turquoise5, borderWidth: 1, color: null },
              inactive: {
                opacity: 1
              }
            },
            point: {
              events: {
                mouseOver: (e) => {
                  if (onHover) {
                    onHover(e.target.options.time, e.target.options, e);
                  }
                  if (this.removePreviousSelection) {
                    this.removePreviousSelection();
                    this.removePreviousSelection = null;
                  }
                  e.target.select(true, false);
                },
                mouseOut: (e) => {
                  this.removePreviousSelection = () => e.target.select && e.target.select(false, false);
                  if (this.chart) {
                    setTimeout(() => this.chart?.tooltip?.hide());
                  }
                }
              }
            },
            pointRange: 0,
            pointPadding: 0,
            borderWidth: 0,
            groupPadding: 0
          },
          series: {
            turboThreshold: 0 // disabled to handle up 1 week+ lookback results
          }
        },
        series: [
          {
            name: 'Critical',
            data: []
          },
          {
            name: 'Warning',
            data: []
          }
        ]
      }
    };
  }

  getAlarmsColumnSeriesByHealthAttr = (alarms, healthAttr) => {
    const alarmsArr = Object.keys(alarms);
    const lastKey = alarmsArr[alarmsArr.length - 1]; // For selecting last item
    return alarmsArr.map((key) => ({
      x: alarms[key].time * 1000,
      y: alarms[key][healthAttr],
      selected: key === lastKey,
      time: alarms[key].time * 1000,
      healthAttr
    }));
  };

  redraw() {
    if (this.chart) {
      const { theme } = this.props;
      const { colors } = theme;

      this.setState({
        chartOptions: {
          chart: {
            backgroundColor: colors.chart.tooltipBackground
          },
          tooltip: {
            backgroundColor: colors.chart.tooltipBackground,
            style: {
              color: colors.muted
            }
          },
          xAxis: {
            labels: {
              style: {
                color: colors.muted
              }
            }
          }
        }
      });
    }
  }

  renderData() {
    if (this.chart) {
      const { data, minutesIncrement } = this.props;
      const alarmsArr = Object.keys(data);
      const dateRangeText = alarmsArr.length
        ? dateRangeDisplay({
            starting_time: data[alarmsArr[0]]?.time * 1000,
            ending_time: data[alarmsArr[alarmsArr.length - 1]]?.time * 1000
          })
        : '';
      const criticalAlarms = this.getAlarmsColumnSeriesByHealthAttr(data, 'critical');
      const majorAlarms = this.getAlarmsColumnSeriesByHealthAttr(data, 'major');

      this.setState({
        chartOptions: {
          xAxis: {
            title: { text: `${dateRangeText} (${minutesIncrement} minute increments)` }
          },
          series: [
            {
              name: 'Critical',
              data: criticalAlarms
            },
            {
              name: 'Warning',
              data: majorAlarms
            }
          ]
        }
      });
    }
  }
}

export default StackedAlarmTimeSeries;
