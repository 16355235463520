export default class Cache {
  _cache = {
    // name: {
    //   ttl: 1234,
    //   expireEntries: {
    //     2345: [key],
    //     ...
    //   },
    //   cacheEntries: {
    //     key: value,
    //     ...
    //   }
    // },
    // ...
  };

  constructor() {
    setInterval(() => this.invalidateAll(), 60000 * 5); // 5 minutes
  }

  /**
   *
   * @param name - unique name for this cache
   * @param ttl - time to live in milliseconds
   */
  create(name, ttl) {
    if (!this._cache[name]) {
      this._cache[name] = {
        ttl,
        expireEntries: {},
        cacheEntries: {},
        put: (key, value) => this.put(name, key, value),
        get: (key) => this.get(name, key)
      };
    }

    return this._cache[name];
  }

  put(name, key, value) {
    if (!this._cache[name]) {
      console.warn(`No cache found "${name}", ${key} will not be cached!!!`);
    } else {
      const { ttl, expireEntries, cacheEntries } = this._cache[name];
      const ttlKey = Date.now() + ttl;
      expireEntries[ttlKey] = (expireEntries[ttlKey] || []).concat(key);
      cacheEntries[key] = value;
    }
  }

  get(name, key) {
    return this._cache[name]?.cacheEntries[key];
  }

  invalidate(name) {
    setTimeout(() => {
      if (this._cache[name]) {
        console.info(`Invalidating ${name} cache`);
        const { expireEntries, cacheEntries } = this._cache[name];
        const ttlKeys = Object.keys(expireEntries);
        const now = Date.now();
        for (let i = 0; i < ttlKeys.length; i += 1) {
          if (ttlKeys[i] < now) {
            delete cacheEntries[expireEntries[ttlKeys[i]]];
            delete expireEntries[ttlKeys[i]];
          }
        }
      }
    }, 500);
  }

  invalidateAll() {
    console.info('Invalidating all caches');
    Object.keys(this._cache).forEach((name) => this.invalidate(name));
  }
}
