import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { Remarkable } from 'remarkable';
import remarkableExtLink from 'remarkable-extlink';
import Box from 'core/components/Box';

const md = new Remarkable({ html: true });
md.use(remarkableExtLink, { host: window.location.host });

const SnippetBox = styled(Box)`
  color: ${({ theme }) => theme.colors.white[10]};
  overflow: auto;
  padding: 16px;
  flex: 1;

  pre {
    font-size: ${({ theme }) => `${theme.fontSizes.tiny}px`};
    line-height: 18px;
    margin: 0;
    /* Github monospace font-stack */
    font-family: ${({ theme }) => theme.fonts.monospace};
  }

  .comment {
    color: ${({ theme }) => theme.colors.green4};
    font-size: ${({ theme }) => `${theme.fontSizes.tiny}px`};
    font-style: italic;
  }

  .highlight {
    background-color: ${({ theme }) => theme.colors.orange5};
    color: ${({ theme }) => theme.colors.black};
    padding: 1px 4px;
    border-radius: 4px;
  }

  .replace {
    background-color: ${({ theme }) => theme.colors.primaryBackground};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => `${theme.fontSizes.tiny}px`};
    padding: 1px 4px;
    border-radius: 4px;
  }

  ${({ themeName, theme }) => {
    if (themeName === 'steel') {
      return css`
        background-color: ${theme.colors.onboarding.configSnippetBackground};
      `;
    }

    return null;
  }}
`;

@observer
class ConfigSnippet extends Component {
  static defaultProps = {
    data: {},
    highlightClassname: 'highlight',
    html: true,
    replacementClassname: 'replace'
  };

  getReplacementValue = (value, className) => {
    const { html } = this.props;

    if (html && className) {
      return `<span class="${className}">${value}</span>`;
    }

    return value;
  };

  replaceValues = (source, data) =>
    Object.keys(data).reduce((acc, key) => acc.replace(new RegExp(`{{${key}}}`, 'g'), data[key]), source);

  render() {
    const { data, highlightClassname, html, markdown, replacementClassname, source, children, ...boxProps } =
      this.props;

    if (!source) {
      return null;
    }

    // highlight the <> codes
    let content = source.replace(/<(.*?)>/g, this.getReplacementValue('$1', highlightClassname));

    // highlight the replacement patterns
    content = content.replace(new RegExp('({{.*?}})', 'g'), this.getReplacementValue('$1', replacementClassname));

    // run applicable replacements
    content = this.replaceValues(content, data);

    let snippet = content;

    if (markdown) {
      snippet = (
        <SnippetBox {...boxProps}>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: md.render(content) }} />
        </SnippetBox>
      );
    } else if (html) {
      // process the comments pattern
      content = content.replace(/((#|!).*)\n/g, `${this.getReplacementValue('$1', 'comment')}\n`);

      snippet = (
        <SnippetBox {...boxProps}>
          {/* eslint-disable react/no-danger */}
          <pre dangerouslySetInnerHTML={{ __html: content }} />
        </SnippetBox>
      );
    }

    if (typeof children === 'function') {
      return children({
        snippet,
        rawContent: this.replaceValues(source, data)
      });
    }

    return snippet;
  }
}

export default ConfigSnippet;
