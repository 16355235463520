import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Box } from 'core/components';
import { formConsumer } from 'core/form';
import { TimeWindow, ExpiresTag } from 'app/views/synthetics/components/timeWindow';

@formConsumer
@observer
export default class MaintenanceMode extends Component {
  render() {
    const { form } = this.props;

    return (
      <Box>
        <TimeWindow
          expiresTitle="Maintenance Window"
          neverExpireFieldName="agent_alert_rule_config.suppression.neverExpire"
          startTimeFieldName="agent_alert_rule_config.suppression.startTime"
          endTimeFieldName="agent_alert_rule_config.suppression.endTime"
        />
        <ExpiresTag
          my={1}
          neverExpire={form.getValue('agent_alert_rule_config.suppression.neverExpire')}
          endTime={form.getValue('agent_alert_rule_config.suppression.endTime')}
        />
      </Box>
    );
  }
}
