import { baseFields } from './baseFields';
import { pingFields } from './pingFields';
import { flowBaseFields } from './flowBaseFields';

const cdnOptions = {
  name: 'CDN Test'
};

const cdnFields = {
  ...baseFields,
  ...pingFields,
  ...flowBaseFields
};

export { cdnOptions, cdnFields };
