import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import { isEqual } from 'lodash';

import { showErrorToast } from 'core/components/toast';
import getErrorBoundary from 'core/util/getErrorBoundary';
import { checkPreventQuery } from 'app/components/dataviews/dependencies/dependencyChecks';
import DataViewModel from 'app/stores/query/DataViewModel';
import QueryModel from 'app/stores/query/QueryModel';

const ErrorBoundaryCmp = getErrorBoundary('LightweightDataViewWrapper');

@inject('$query')
@observer
class LightweightDataViewWrapper extends Component {
  static defaultProps = {
    allowCache: true,
    resultsSortState: {},
    resultsPresetFilter: undefined,
    toastOnError: true,
    showBannerOnDepFailures: false,
    checkDependencies: true
  };

  static getDerivedStateFromProps(props, state) {
    const { $query, query, allowCache, resultsSortState, resultsPresetFilter, checkDependencies } = props; // Accept from props, never overwrite
    let { key, dataview, queryModel } = state; // Default to existing state and overwrite in case below

    if (query && !isEqual(state.query, query)) {
      key = $query.getQueryKey(query);
      queryModel = QueryModel.create(query);
      if (dataview) {
        dataview.destroy();
      }
      dataview = new DataViewModel();
      const maxAge = $query.getMaxAgeByLookback(query.lookback_seconds);
      const cachedResults = $query.getCachedQueryResults(key, maxAge);

      if (allowCache && cachedResults) {
        dataview.loadCachedResults(queryModel, cachedResults);
      } else {
        const serializedQuery = queryModel.serialize();

        if (checkDependencies) {
          checkPreventQuery(serializedQuery, dataview);
        }

        dataview.setQuery(serializedQuery);
      }
    }

    if (!isEqual(state.resultsSortState, resultsSortState)) {
      const [bucket] = dataview.queryBuckets.activeBuckets;
      if (bucket) {
        bucket.queryResults.sortState = resultsSortState || {};
      }
    }

    if (!isEqual(state.resultsPresetFilter, resultsPresetFilter)) {
      const [bucket] = dataview.queryBuckets.activeBuckets;
      if (bucket) {
        bucket.queryResults.activePresetFilter = resultsPresetFilter;
      }
    }

    return {
      query,
      key,
      queryModel,
      dataview,
      resultsSortState
    };
  }

  state = {};

  componentDidMount() {
    const { $query, onQueryComplete, onQueryError, allowCache, toastOnError } = this.props;

    this.loadedDisposer = reaction(
      () => {
        const { dataview } = this.state;
        return dataview.loadedCount;
      },
      (loadedCount) => {
        const { dataview, key, query, queryModel } = this.state;
        if (loadedCount > 0) {
          const [bucket] = dataview.queryBuckets.activeBuckets;
          const { queryResults: results } = bucket;
          const { fullyLoaded } = dataview.queryBuckets;

          // Single query key may have overlays (multiple async results), ONLY cache once after fully loaded.
          if (fullyLoaded && allowCache) {
            // keep cache call async so heavy lifting doesn't interfere with data render
            setTimeout(() => {
              $query.cacheQueryResults(key, dataview.queryBuckets.activeBucketResults);
            }, 500);
          }

          if (onQueryComplete) {
            onQueryComplete({ results, dataview, query, queryModel, fullyLoaded });
          }
        }
      }
    );

    this.errorDisposer = reaction(
      () => {
        const { dataview } = this.state;

        if (dataview.preventQuery && onQueryError) {
          onQueryError('Query was prevented due to dependency checks.');
        }

        if (dataview.errorMessage.length > 0) {
          return dataview.errorMessage;
        }

        return false;
      },
      (errorMessage) => {
        if (errorMessage) {
          errorMessage = Array.isArray(errorMessage) ? errorMessage.join('. ') : errorMessage;
          if (toastOnError) {
            showErrorToast(errorMessage || 'An unknown error has occurred when running your query');
          }

          if (onQueryError) {
            onQueryError(errorMessage);
          }
        }
      }
    );
  }

  componentWillUnmount() {
    const { dataview } = this.state;

    if (this.loadedDisposer) {
      this.loadedDisposer();
    }
    if (this.errorDisposer) {
      this.errorDisposer();
    }

    if (dataview) {
      dataview.destroy();
    }
  }

  render() {
    const { children } = this.props;
    const { dataview, queryModel } = this.state;
    if (!dataview.queryBuckets) {
      return null;
    }

    const [bucket] = dataview.queryBuckets.activeBuckets;

    if (!children || !bucket || !bucket.queryResults) {
      return null;
    }

    const results = bucket.queryResults;
    // this loading state allows rendering of items as they are loaded and prevents flashes of empty state on query change
    const loading = dataview.loading || (results.size === 0 && !dataview.queryBuckets.fullyLoaded);

    return (
      <ErrorBoundaryCmp>
        {children({
          loading,
          fullyLoaded: dataview.queryBuckets.fullyLoaded,
          dataview,
          bucket,
          results: bucket.queryResults,
          queryModel
        })}
      </ErrorBoundaryCmp>
    );
  }
}

export default LightweightDataViewWrapper;
