import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FiShare2 } from 'react-icons/fi';
import { ReactComponent as EnvelopeRemove } from 'app/assets/icons/envelope-remove.svg';
import { Position } from '@blueprintjs/core';

import {
  Grid,
  Box,
  Text,
  EmptyState,
  Callout,
  Icon,
  Flex,
  Button,
  LinkButton,
  Select,
  Suspense,
  Menu,
  MenuItem,
  Popover
} from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import Page from 'app/components/page/Page';
import PageHeading from 'app/components/page/PageHeading';
import ExportCoverPage from 'app/views/core/ExportCoverPage';
import ShareViewDialog from 'app/views/core/ShareViewDialog';
import SubscribeDialog from 'app/views/settings/subscriptions/SubscribeDialog';
import InsightsMenu from 'app/components/navbar/InsightsMenu';
import PlanOverviewCard from './PlanOverviewCard';
import SeverityFilters from './SeverityFilters';

@storeLoader('$devices.nonCloudCollection', '$subscriptions')
@inject('$auth', '$app', '$capacity')
@withRouter
@observer
export default class Capacity extends Component {
  state = {
    loadingSummaries: true,
    selectedFilter: ['healthy', 'warning', 'critical'],
    sortField: 'highestUtilization',
    shareDialogOpen: false,
    subscribeDialogOpen: false,
    unsubscribeDialogOpen: false,
    isSubscribed: undefined,
    selectedModel: undefined,
    isActionsOpen: false
  };

  setSubscriptionStatus = (otherNewState) => {
    const { $subscriptions, $auth } = this.props;
    $subscriptions.isUserSubscribedToContent($auth.activeUser.user_email, 'capacitysummary').then((isSubscribed) => {
      this.setState({ ...otherNewState, isSubscribed });
    });
  };

  componentDidMount() {
    const { $capacity } = this.props;

    this.setSubscriptionStatus();

    if ($capacity.collection.hasFetched) {
      this.setState({ loadingSummaries: false });
    } else {
      $capacity.collection.fetch().then(() => {
        this.setState({ loadingSummaries: false });
      });
    }
    $capacity.collection.clearFilters();
  }

  handleFilterChange = (selectedFilter) => {
    const { $capacity } = this.props;
    this.setState({ selectedFilter }, () => {
      const showHealthy = selectedFilter.includes('healthy');
      const showWarning = selectedFilter.includes('warning');
      const showCritical = selectedFilter.includes('critical');
      $capacity.collection.filter((model) => {
        const severity = model.get('severity');
        return (
          (showHealthy && severity === 'healthy') ||
          (showWarning && severity === 'warning') ||
          (showCritical && severity === 'critical')
        );
      });
    });
  };

  handleActionsInteraction = (isActionsOpen) => {
    // sending the other status to reduce rerender by updating state at once
    this.setSubscriptionStatus({ isActionsOpen });
  };

  renderDeviceWarning = () => {
    const { $app, $capacity, $devices } = this.props;
    const enabledDevices = $devices.nonCloudCollection.reduce(
      (acc, model) => (acc += model.get('snmp_enabled') ? 1 : 0),
      0
    );

    if ($app.isExport || $devices.nonCloudCollection.size === enabledDevices) {
      return null;
    }
    return (
      <Callout
        border="thin"
        intent="warning"
        icon={$capacity.severityTypes.warning.icon}
        title={<>SNMP Polling Disabled on {$devices.nonCloudCollection.size - enabledDevices} Devices</>}
      >
        Devices must have SNMP polling enabled for their interfaces to be used in a Capacity Plan. Currently,
        <b> {enabledDevices}</b> of your <b>{$devices.nonCloudCollection.size}</b> network devices have SNMP enabled.
      </Callout>
    );
  };

  toggleShareDialog = (dialogType) => {
    const { $subscriptions } = this.props;
    const attributes = { content_type: 'capacitysummary' };

    const dialogToOpen = {
      share: 'shareDialogOpen',
      subscribe: 'subscribeDialogOpen',
      unsubscribe: 'unsubscribeDialogOpen'
    }[dialogType];

    this.setState(() => ({
      [dialogToOpen]: true,
      selectedModel: $subscriptions.collection.forge(attributes, { select: false })
    }));
  };

  onShareDialogClose = () => {
    this.setState({
      shareDialogOpen: false,
      subscribeDialogOpen: false,
      unsubscribeDialogOpen: false,
      selectedModel: undefined
    });
  };

  setSelectedModel(selectedModel) {
    this.setState({ selectedModel });
  }

  onSortChange = (sortField) => {
    this.setState({ sortField }, () => {
      const { $capacity } = this.props;
      $capacity.collection.sort(sortField, sortField === 'highestUtilization' ? 'desc' : 'asc');
    });
  };

  capacitySortValueRenderer = (value) => (
    <Box>
      Sort By: <Text fontWeight="bold">{value.label}</Text>
    </Box>
  );

  render() {
    const {
      loadingSummaries,
      selectedFilter,
      sortField,
      shareDialogOpen,
      subscribeDialogOpen,
      unsubscribeDialogOpen,
      selectedModel,
      isSubscribed,
      isActionsOpen
    } = this.state;
    const { $auth, $app, $capacity, loading } = this.props;

    const subnavTools = (
      <>
        <Button icon={FiShare2} text="Share" minimal onClick={() => this.toggleShareDialog('share')} />
        <Popover
          isOpen={isActionsOpen}
          content={
            <Menu>
              <MenuItem icon="export" text="Export" popoverProps={{ openOnTargetFocus: false }}>
                <MenuItem icon="document" text="Visual Report" label=".pdf" onClick={() => $capacity.exportPlanPdf()} />
                <MenuItem
                  icon="th"
                  text="Data Table"
                  label=".csv"
                  onClick={() => $capacity.exportPlanCsv()}
                  className="export_csv"
                />
              </MenuItem>
              <MenuItem icon="envelope" text="Subscribe" onClick={() => this.toggleShareDialog('subscribe')} />
              {isSubscribed && (
                <MenuItem
                  icon={EnvelopeRemove}
                  text="Unsubscribe"
                  onClick={() => isSubscribed && this.toggleShareDialog('unsubscribe')}
                />
              )}
            </Menu>
          }
          onInteraction={this.handleActionsInteraction}
          position={Position.BOTTOM_RIGHT}
        >
          <Button
            text="Actions"
            rightIcon={<Icon icon="caret-down" iconSize={16} mx="-2px" />}
            active={!!isActionsOpen}
            ml={2}
            minimal
          />
        </Popover>
      </>
    );

    return (
      <>
        {$app.isExport && <ExportCoverPage title="Capacity Planning Summary" />}
        <Page
          title="Capacity Planning"
          scrolls
          subnavTools={subnavTools}
          insightsMenu={
            <InsightsMenu
              query="fetchCapacityPlanningInsights"
              notifyOnInsightName={[
                { name: 'capacity.errors.highutilization', frequency: 'weekly' },
                'capacity.errors.maxinterfacesinplan'
              ]}
            />
          }
        >
          <Flex alignItems="center" justifyContent="space-between" mb={2}>
            <PageHeading title="Capacity Planning" />
            <Flex>
              {!loadingSummaries && !$app.isExport && (
                <>
                  <SeverityFilters onChange={this.handleFilterChange} selectedFilter={selectedFilter} />
                  <Box ml={2}>
                    <Select
                      valueRenderer={this.capacitySortValueRenderer}
                      onChange={this.onSortChange}
                      options={[
                        { label: 'Utilization', value: 'highestUtilization' },
                        { label: 'Runout', value: 'earliestRunout' },
                        { label: 'Name', value: 'name' }
                      ]}
                      values={sortField}
                    />
                  </Box>
                </>
              )}
              {$auth.isAdministrator && $capacity.collection.unfilteredSize > 0 && (
                <Box ml={2}>
                  <LinkButton to="/v4/core/capacity/new" intent="primary" icon="plus" text="Configure New Plan" />
                </Box>
              )}
            </Flex>
          </Flex>
          <Box mb={3}>{this.renderDeviceWarning(true)}</Box>
          <Box>
            <Suspense loading={loading || loadingSummaries}>
              {$auth.isAdministrator && $capacity.collection.unfilteredSize === 0 && (
                <EmptyState
                  icon="git-repo"
                  title="No Capacity Plans configured"
                  description="A Capacity Plan defines the strategy for tracking the runout and utilization for a set of interfaces."
                  action={
                    <LinkButton to="/v4/core/capacity/new" intent="primary" icon="plus" text="Configure New Plan" />
                  }
                />
              )}
              <Grid
                gridColumnGap={24}
                gridRowGap={24}
                gridTemplateColumns="repeat(auto-fill,minmax(max(320px,100%/4),1fr))"
              >
                {!loading &&
                  !loadingSummaries &&
                  $capacity.collection.map((plan) => (
                    <PlanOverviewCard key={plan.id} plan={plan} onExportPlan={() => $capacity.exportPlanCsv(plan)} />
                  ))}
              </Grid>
            </Suspense>
          </Box>

          <ShareViewDialog
            isOpen={shareDialogOpen}
            model={selectedModel}
            setModel={(model) => this.setSelectedModel(model)}
            onClose={this.onShareDialogClose}
            legacyLinkShare
          />
          <SubscribeDialog
            isOpen={subscribeDialogOpen}
            model={selectedModel}
            setModel={(model) => this.setSelectedModel(model)}
            onClose={this.onShareDialogClose}
            subscriptionDialogType="subscribe"
          />
          <SubscribeDialog
            isOpen={unsubscribeDialogOpen}
            model={selectedModel}
            setModel={(model) => this.setSelectedModel(model)}
            onClose={this.onShareDialogClose}
            subscriptionDialogType="unsubscribe"
          />
        </Page>
      </>
    );
  }
}
