import React from 'react';
import { FiActivity } from 'react-icons/fi';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { uriToObject } from 'shared/util/map';
import { CopyText, Flex, InfoBox, LinkButton, Text, FlexColumn } from 'core/components';
import ExpandableRow from 'app/components/admin/ExpandableRow';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { NameIdState, EntityIdPopover } from './EntityExplorerRenderers';

const {
  LOCATION,
  VNET,
  LOCAL_NETWORK_GATEWAY,
  NAT_GATEWAY,
  VNET_GATEWAY,
  EXPRESS_ROUTE_CIRCUIT,
  FIREWALL,
  LOAD_BALANCER,
  APPLICATION_GATEWAY,
  VIRTUAL_HUB,
  SUBNET
} = ENTITY_TYPES.get('azure');

function renderOpenInMapLinkButton({ model }) {
  const { type, name, id, subscriptionId } = model.get();
  const value = type === SUBNET ? id : name;

  return (
    <LinkButton
      icon="layout-auto"
      iconSize={16}
      to={`/v4/kentik-map/cloud/azure?sidebar=${encodeURIComponent(JSON.stringify({ type, value, subscriptionId }))}`}
      blank
      small
      mb={1}
    >
      Open in Map
    </LinkButton>
  );
}

function getCommonDisplayProperties({ model }) {
  const rawModel = model?.get() || {};
  const properties = [
    { label: 'Tenant ID', key: 'tenantId' },
    { label: 'Subscription ID', key: 'subscriptionId' },
    { label: 'Resource Group', key: 'resourceGroup' },
    { label: 'Location', key: 'location' }
  ];
  const components = [];

  properties.forEach(({ label, key }) => {
    const value = rawModel[key];

    if (value) {
      components.push(<InfoBox key={key} heading={label} value={value} fontWeight="medium" copyable />);
    }
  });

  return components;
}

export const generateEntityMap = ({ topology }) => {
  const locationMap = Object.keys(topology[LOCATION]).reduce((acc, curr) => {
    acc[curr] = {
      [LOCAL_NETWORK_GATEWAY]: [],
      [NAT_GATEWAY]: [],
      [VNET_GATEWAY]: []
    };
    return acc;
  }, {});

  [LOCAL_NETWORK_GATEWAY, NAT_GATEWAY, VNET_GATEWAY].forEach((entityType) => {
    Object.values(topology[entityType] ?? {}).forEach((entity) => {
      locationMap[entity.location][entityType] = [...locationMap[entity.location][entityType], entity.id];
    });
  });

  const ENTITY_MAP = {
    [LOCATION]: {
      label: 'Locations',
      icon: <CloudIcon cloudProvider="azure" entity={LOCATION} iconSize={20} />,
      rowHeight: 28,
      expandHeight: 267,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { vnets, coordinates, id } = model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[LOCATION].expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  <InfoBox heading="VNETs" fontWeight="medium">
                    {vnets}
                  </InfoBox>
                  <InfoBox heading="Local Network Gateways" fontWeight="medium">
                    {locationMap[id]?.localNetworkGateways?.length ?? 0}
                  </InfoBox>
                  <InfoBox heading="Nat Gateways" fontWeight="medium">
                    {locationMap[id]?.natGateways?.length ?? 0}
                  </InfoBox>
                  <InfoBox heading="VNET Gateways" fontWeight="medium">
                    {locationMap[id]?.vnetGateways?.length ?? 0}
                  </InfoBox>
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  <InfoBox
                    heading="Lat/Long"
                    value={coordinates ? `${coordinates.lat} / ${coordinates.lon}` : '--'}
                    fontWeight="medium"
                    copyable
                  />
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  <LinkButton
                    icon={FiActivity}
                    iconSize={16}
                    to={`/v4/core/quick-views/cloud/azure/region/${id}`}
                    blank
                    small
                    mb={2}
                  >
                    Open Quick-View
                  </LinkButton>
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Key',
            renderer: ({ value }) => <Text fontWeight="bold">{value}</Text>
          },
          {
            name: 'name',
            label: 'Name',
            renderer: ({ value }) => <Text fontWeight="bold">{value}</Text>
          },
          {
            name: 'vnets',
            label: 'VNETs',
            ellipsis: false,
            renderer: ({ value = 0 }) => <Text>{value}</Text>
          },
          {
            name: 'localNetworkGateways',
            label: 'Local Network Gateways',
            ellipsis: false,
            renderer: ({ value = 0 }) => <Text>{value}</Text>
          },
          {
            name: 'natGateways',
            label: 'NAT Gateways',
            ellipsis: false,
            renderer: ({ value = 0 }) => <Text>{value}</Text>
          },
          {
            name: 'vnetGateways',
            label: 'VNET Gateways',
            ellipsis: false,
            renderer: ({ value = 0 }) => <Text>{value}</Text>
          }
        ]
      }
    },
    [VNET]: {
      label: 'VNETs',
      icon: <CloudIcon cloudProvider="azure" entity={VNET} iconSize={20} />,
      expandHeight: 185,
      searchable: true,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { name, location, tenantId, subscriptionId, resourceGroup, vnetAddressPrefixes } = model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[VNET].expandHeight} p={1}>
              <Flex mb={2}>
                <FlexColumn flex={2}>
                  <InfoBox heading="Name" value={name} fontWeight="medium" copyable />
                  <InfoBox heading="Tenant ID" value={tenantId} fontWeight="medium" copyable />
                  <InfoBox heading="Location" value={location} fontWeight="medium" copyable />
                </FlexColumn>
                <FlexColumn flex={2}>
                  <InfoBox heading="Subscription ID" value={subscriptionId} fontWeight="medium" copyable />
                  <InfoBox heading="Resource Group" value={resourceGroup} fontWeight="medium" copyable />
                  <Text as="div" color="muted" fontWeight="bold" fontSize="small" pb="4px">
                    CIDRs
                  </Text>
                  {vnetAddressPrefixes.map((addr) => (
                    <CopyText
                      key={addr}
                      text={addr}
                      textProps={{ fontWeight: 'bold' }}
                      justifyContent="flex-start"
                      pb={1}
                    />
                  ))}
                </FlexColumn>
                <FlexColumn alignItems="flex-start" minWidth={145} flex={1} p={1}>
                  {renderOpenInMapLinkButton({ model })}
                  <LinkButton
                    icon={FiActivity}
                    iconSize={16}
                    to={`/v4/core/quick-views/cloud/azure/vnet_id/${name}`}
                    blank
                    small
                    my={1}
                  >
                    Open Quick-View
                  </LinkButton>
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / Id',
            renderer: ({ value, model }) => {
              const { name, provisioningState } = model.get();
              return <NameIdState name={name} id={value} state={provisioningState} azure />;
            }
          },
          {
            name: 'vnetAddressPrefixes',
            label: 'Address',
            width: 100,
            renderer: ({ value }) => <Text>{value}</Text>
          },
          {
            name: 'subnetsCount',
            label: 'Subnets',
            width: 100,
            renderer: ({ value }) => <Text>{value}</Text>
          }
        ]
      }
    },
    [LOCAL_NETWORK_GATEWAY]: {
      label: 'Local Network Gateways',
      icon: <CloudIcon cloudProvider="azure" entity={LOCAL_NETWORK_GATEWAY} iconSize={20} />,
      searchable: true,
      expandHeight: 180,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { tenantId, subscriptionId, resourceGroup, gatewayIpAddress, localNetworkAddressPrefixes } =
            model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[LOCAL_NETWORK_GATEWAY].expandHeight} p={1}>
              <Flex>
                <FlexColumn flex={2}>
                  <InfoBox heading="Tenant ID" value={tenantId} fontWeight="medium" copyable />
                  <InfoBox heading="Subscription ID" value={subscriptionId} fontWeight="medium" copyable />
                </FlexColumn>
                <FlexColumn flex={2}>
                  <InfoBox heading="Resource Group" value={resourceGroup} fontWeight="medium" copyable />
                  <InfoBox heading="Gateway Address" value={gatewayIpAddress} fontWeight="medium" copyable />
                  <Text as="div" color="muted" fontWeight="bold" fontSize="small" pb="4px">
                    Local Networks
                  </Text>
                  {localNetworkAddressPrefixes.map((addr) => (
                    <CopyText
                      key={addr}
                      text={addr}
                      textProps={{ fontWeight: 'bold' }}
                      justifyContent="flex-start"
                      pb={1}
                    />
                  ))}
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  {renderOpenInMapLinkButton({ model })}
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ value, model }) => {
              const { name, provisioningState } = model.get();
              return <NameIdState name={name} id={value} state={provisioningState} azure />;
            }
          }
        ]
      }
    },
    [NAT_GATEWAY]: {
      label: 'NAT Gateways',
      icon: <CloudIcon cloudProvider="azure" entity={NAT_GATEWAY} iconSize={20} />,
      searchable: true,
      expandHeight: 180,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { subnets } = model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[NAT_GATEWAY].expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  {getCommonDisplayProperties({ model })}
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  <Text as="div" color="muted" fontWeight="bold" fontSize="small" pb="4px">
                    Subnets
                  </Text>
                  {subnets.map(({ id }) => (
                    <CopyText
                      key={id}
                      text={uriToObject(id).subnet}
                      textProps={{ fontWeight: 'bold' }}
                      justifyContent="flex-start"
                      pb={1}
                    />
                  ))}
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  {renderOpenInMapLinkButton({ model })}
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ value, model }) => {
              const { name, provisioningState } = model.get();
              return <NameIdState name={name} id={value} state={provisioningState} azure />;
            }
          }
        ]
      }
    },
    [VNET_GATEWAY]: {
      label: 'VNET Gateways',
      icon: <CloudIcon cloudProvider="azure" entity={VNET_GATEWAY} iconSize={20} />,
      searchable: true,
      expandHeight: 180,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => (
          <ExpandableRow rowExpansionHeight={ENTITY_MAP[VNET_GATEWAY].expandHeight} p={1}>
            <Flex>
              <FlexColumn alignItems="flex-start" flex={2} p={1}>
                {getCommonDisplayProperties({ model })}
              </FlexColumn>
              <FlexColumn alignItems="flex-start" flex={1} p={1}>
                {renderOpenInMapLinkButton({ model })}
              </FlexColumn>
            </Flex>
          </ExpandableRow>
        ),
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ value, model }) => {
              const { name, provisioningState } = model.get();
              return <NameIdState name={name} id={value} state={provisioningState} azure />;
            }
          }
        ]
      }
    },
    [EXPRESS_ROUTE_CIRCUIT]: {
      label: 'Express Route Circuits',
      icon: <CloudIcon cloudProvider="azure" entity={EXPRESS_ROUTE_CIRCUIT} iconSize={20} />,
      searchable: true,
      expandHeight: 180,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const serviceProvider = model.get('properties.serviceProviderProperties.serviceProviderName', '--');
          const serviceProviderLocation = model.get('properties.serviceProviderProperties.peeringLocation', '--');
          const bandwidth = model.get('properties.serviceProviderProperties.bandwidthInMbps', 0);
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[EXPRESS_ROUTE_CIRCUIT].expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  {getCommonDisplayProperties({ model })}
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  <InfoBox heading="Service Provider" value={serviceProvider} fontWeight="medium" copyable />
                  <InfoBox
                    heading="Service Provider Location"
                    value={serviceProviderLocation}
                    fontWeight="medium"
                    copyable
                  />
                  <InfoBox
                    heading="Bandwidth"
                    value={bandwidth ? `${bandwidth}Mbps` : '--'}
                    fontWeight="medium"
                    copyable
                  />
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  {renderOpenInMapLinkButton({ model })}
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ value, model }) => {
              const { name, provisioningState } = model.get();
              return <NameIdState name={name} id={value} state={provisioningState} azure />;
            }
          }
        ]
      }
    },
    [FIREWALL]: {
      label: 'Firewalls',
      icon: <CloudIcon cloudProvider="azure" entity={FIREWALL} iconSize={20} />,
      searchable: true,
      expandHeight: 180,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const privateIP = model.get('properties.hubIPAddresses.privateIPAddress', null);
          const publicIPs = model
            .get('properties.hubIPAddresses.publicIPs.addresses', [])
            .map(({ address }) => address);
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[FIREWALL].expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  {getCommonDisplayProperties({ model })}
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  {privateIP && <InfoBox heading="Private IP" value={privateIP} fontWeight="medium" copyable />}
                  {publicIPs.length > 0 && (
                    <InfoBox heading="Public IPs" value={publicIPs.join(', ')} fontWeight="medium" copyable />
                  )}
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  {renderOpenInMapLinkButton({ model })}
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ value, model }) => {
              const { name, provisioningState } = model.get();
              return <NameIdState name={name} id={value} state={provisioningState} azure />;
            }
          }
        ]
      }
    },
    [LOAD_BALANCER]: {
      label: 'Load Balancers',
      icon: <CloudIcon cloudProvider="azure" entity={LOAD_BALANCER} iconSize={20} />,
      searchable: true,
      expandHeight: 180,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => (
          <ExpandableRow rowExpansionHeight={ENTITY_MAP[LOAD_BALANCER].expandHeight} p={1}>
            <Flex>
              <Flex alignItems="flex-start" flexWrap="wrap" flex={3} p={1} gap={1}>
                {getCommonDisplayProperties({ model })}
              </Flex>
              <FlexColumn alignItems="flex-start" flex={1} p={1}>
                {renderOpenInMapLinkButton({ model })}
              </FlexColumn>
            </Flex>
          </ExpandableRow>
        ),
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ value, model }) => {
              const { name, provisioningState } = model.get();
              return <NameIdState name={name} id={value} state={provisioningState} azure />;
            }
          }
        ]
      }
    },
    [APPLICATION_GATEWAY]: {
      label: 'Application Gateways',
      icon: <CloudIcon cloudProvider="azure" entity={APPLICATION_GATEWAY} iconSize={20} />,
      searchable: true,
      expandHeight: 180,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const tier = model.get('tier', '--');
          const operationalState = model.get('operationalState', '--');
          const minCapacity = model.get('minCapacity', '--');
          const maxCapacity = model.get('maxCapacity', '--');
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[APPLICATION_GATEWAY].expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  {getCommonDisplayProperties({ model })}
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  <InfoBox heading="Tier" value={tier} fontWeight="medium" copyable />
                  <InfoBox heading="Operational State" value={operationalState} fontWeight="medium" copyable />
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  <InfoBox heading="Min Capacity" value={minCapacity} fontWeight="medium" copyable />
                  <InfoBox heading="Max Capacity" value={maxCapacity} fontWeight="medium" copyable />
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  {renderOpenInMapLinkButton({ model })}
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ value, model }) => {
              const { name, provisioningState } = model.get();
              return <NameIdState name={name} id={value} state={provisioningState} azure />;
            }
          }
        ]
      }
    },
    [VIRTUAL_HUB]: {
      label: 'vWAN Hubs',
      icon: <CloudIcon cloudProvider="azure" entity={VIRTUAL_HUB} iconSize={20} />,
      searchable: true,
      expandHeight: 180,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const addressPrefix = model.get('properties.addressPrefix', '--');
          const hubRoutingPreference = model.get('properties.hubRoutingPreference', '--');
          const virtualRouterIPs = model.get('properties.virtualRouterIps', []);
          const virtualRouterASN = model.get('properties.virtualRouterAsn', '--');
          const minCapacity = model.get('properties.virtualRouterAutoScaleConfiguration.minCapacity', '--');
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[VIRTUAL_HUB].expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  {getCommonDisplayProperties({ model })}
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  <InfoBox heading="Address Prefix" value={addressPrefix} fontWeight="medium" copyable />
                  <InfoBox heading="Hub Routing Preference" value={hubRoutingPreference} fontWeight="medium" copyable />
                  <InfoBox
                    heading="Virtual Router IPs"
                    value={virtualRouterIPs.join(', ')}
                    fontWeight="medium"
                    copyable
                  />
                  <InfoBox heading="Virtual Router ASN" value={virtualRouterASN} fontWeight="medium" copyable />
                  <InfoBox heading="Min Capacity" value={minCapacity} fontWeight="medium" copyable />
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  {renderOpenInMapLinkButton({ model })}
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ value, model }) => {
              const { name, provisioningState } = model.get();
              return <NameIdState name={name} id={value} state={provisioningState} azure />;
            }
          }
        ]
      }
    },
    [SUBNET]: {
      label: 'Subnets',
      icon: <CloudIcon cloudProvider="azure" entity={SUBNET} iconSize={20} />,
      searchable: true,
      expandHeight: 180,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { name, vnetName, subscriptionId } = model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[SUBNET].expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  {getCommonDisplayProperties({ model })}
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  <EntityIdPopover
                    title="VNet"
                    id={vnetName}
                    detailType="vnet_id"
                    mapType={VNET}
                    sidebarProperties={{ subscriptionId }}
                    azure
                  />
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  {renderOpenInMapLinkButton({ model })}

                  <LinkButton
                    icon={FiActivity}
                    iconSize={16}
                    to={`/v4/core/quick-views/cloud/azure/subnet_name/${name}`}
                    blank
                    small
                    my={1}
                  >
                    Open Quick-View
                  </LinkButton>
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ model }) => {
              const { name, vnetName, provisioningState } = model.get();
              return <NameIdState name={vnetName} id={name} state={provisioningState} azure />;
            }
          },

          {
            name: 'addressPrefix',
            label: 'CIDR Block',
            renderer: ({ value, model }) => {
              if (value) {
                return <CopyText text={value} />;
              }

              return model
                .get('properties.addressPrefixes', [])
                ?.map((addressPrefix) => <CopyText key={addressPrefix} text={addressPrefix} />);
            }
          }
        ]
      }
    }
  };
  return ENTITY_MAP;
};
