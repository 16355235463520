import React, { useEffect } from 'react';
import { FormComponent } from 'core/form';
import { useFormContext } from 'core/form/FormContext';
import { observer } from 'mobx-react';

export const FormWrapper = observer(
  ({ fields, options, children, onFormCreated, model, values, handleSave, handleCancel, handleRemove }) => (
    <FormComponent
      fields={fields}
      options={options}
      model={model}
      values={values}
      handleSave={handleSave}
      handleCancel={handleCancel}
      handleRemove={handleRemove}
    >
      {onFormCreated && <FormListener onFormCreated={onFormCreated} />}
      {children}
    </FormComponent>
  )
);

const FormListener = observer(({ onFormCreated }) => {
  const form = useFormContext();
  useEffect(() => onFormCreated(form), [onFormCreated, form]);
  // renders nothing, purely a support component
  return null;
});
