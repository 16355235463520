/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';
import { computed, autorun, action, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { isEqual } from 'lodash';
import { FiInfo } from 'react-icons/fi';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';

import { Flex, Button, EmptyState, Box, CalloutOutline, Icon } from 'core/components';
import $app from 'app/stores/$app';
import $colors from 'app/stores/colors/$colors';
import $dictionary from 'app/stores/$dictionary';
import $dataviews from 'app/stores/$dataviews';
import DataViewSpinner from 'app/components/dataviews/DataViewSpinner';
import { getTrimmedName } from 'app/util/queryResults';

@observer
export default class BaseDataview extends Component {
  alwaysShowDataView = false;

  // Apply different css to dataviewSpinner
  series = {};

  overlaySeries = {};

  comparisonSeries = null;

  buildPromise = Promise.resolve();

  togglerDisposers = [];

  allowScroll = false;

  useQualitativeColors = false;

  @action
  dismissSpinner = (timeout = 200, setFullyLoaded = true) => {
    const { dataview } = this.props;
    const emptyBuckets = dataview.queryBuckets.activeBuckets.length === 0;

    setTimeout(() => {
      if (setFullyLoaded && !emptyBuckets) {
        dataview.setFullyLoaded();
      }
    }, timeout);
  };

  @computed
  get darkThemeEnabled() {
    return $app.darkThemeEnabled;
  }

  @computed
  get chartColors() {
    const { viewProps } = this.props;
    let colors;
    if (viewProps.colors) {
      const propColors = viewProps.colors[$app.darkThemeEnabled ? 'dark' : 'standard'];

      if (this.useQualitativeColors) {
        if (propColors.qualitativeCustom && propColors.qualitativeCustom.length) {
          colors = propColors.qualitativeCustom;
        }

        if (!colors && propColors.qualitative && propColors.qualitative !== 'default') {
          colors = $colors.getDefaultQualitativePaletteColor(propColors.qualitative);
        }
      } else {
        if (propColors.paletteCustom && propColors.paletteCustom.length) {
          colors = propColors.paletteCustom;
        }

        if (!colors && propColors.palette && propColors.palette !== 'default') {
          colors = $colors.getDefaultPaletteColor(propColors.palette);
        }
      }
    }

    if (colors) {
      return colors;
    }
    return this.useQualitativeColors ? $colors.qualitativeColors : $colors.chartColors;
  }

  @computed
  get primaryOverlayColor() {
    const { viewProps } = this.props;

    let colors;
    if (viewProps.colors) {
      colors = viewProps.colors[$app.darkThemeEnabled ? 'dark' : 'standard'].primaryOverlay;
    }

    return colors || $colors.primaryOverlayColor;
  }

  @computed
  get overlayColor() {
    const { viewProps } = this.props;
    let colors;
    if (viewProps.colors) {
      colors = viewProps.colors[$app.darkThemeEnabled ? 'dark' : 'standard'].overlay;
    }

    return colors || $colors.overlayColor;
  }

  getColorFromIndex = (colorIndex) => {
    if (colorIndex === 50) {
      return this.primaryOverlayColor;
    }

    if (colorIndex === 500) {
      return this.overlayColor;
    }

    return this.chartColors[colorIndex % this.chartColors.length];
  };

  getRawDataKey(query, outsortOverride) {
    const aggregates = query.get('aggregates');
    const outsort = outsortOverride || query.get('outsort');
    const rawAgg = aggregates.find(
      (agg) =>
        agg.raw &&
        agg.column &&
        (agg.name === outsort || (outsort.startsWith('sum_logsum') && agg.unit === outsort.replace('sum_logsum_', '')))
    );

    let newColName = '';
    if (rawAgg && rawAgg.column) {
      newColName = rawAgg.column.replace('f_sum_', '').replace('bytes', 'bits').replace('trautocount', 'flows');
      if (
        !$dictionary.get('countColumns').includes(rawAgg.column) &&
        !$dictionary.get('countRegex').some((regex) => new RegExp(regex).test(rawAgg.column)) &&
        !rawAgg.is_count
      ) {
        newColName += '_per_sec';
      }
    }

    return newColName;
  }

  buildBucketSeries() {
    const { dataview } = this.props;
    const { queryBuckets } = dataview;
    const { activeBuckets, activeBucketCount, fullyLoaded } = queryBuckets;

    if (!activeBucketCount) {
      return;
    }

    const { hasPeriodOverPeriod } = queryBuckets;

    activeBuckets.forEach((bucket) => {
      if (!bucket.loading) {
        const outsort = bucket.firstQuery.get('outsort');
        const topx = bucket.firstQuery.get('topx');

        if (outsort.includes('agg_total')) {
          this.buildSeries(
            bucket,
            bucket.queryResults.nonOverlayRows.slice(0, topx).concat(bucket.queryResults.overlayRows)
          );
        } else {
          this.buildSeries(bucket, bucket.queryResults.getRawDataRows(true));
        }
      }
    });

    this.togglerDisposers.push(
      autorun(() => {
        const { bucketTab } = dataview;
        if (hasPeriodOverPeriod) {
          this.showActivePeriod(bucketTab);
        }
      })
    );

    if (fullyLoaded && queryBuckets.comparisonKey) {
      const model = activeBuckets[0].queryResults.find({ key: queryBuckets.comparisonKey });
      if (model) {
        $app.renderSync(() => model.set('comparing', true));
      }
      queryBuckets.comparisonKey = null;
    }
  }

  buildSeries(bucket, models) {
    const { dataview } = this.props;
    this.buildPromise = this.buildPromise.then(() => {
      this.buildSeriesInternal(bucket, models);
      const secondaryOutsort = bucket.firstQuery.get('secondaryOutsort');
      const secondaryBucketIndex = bucket.firstQuery.get('secondaryTopxMirrored')
        ? bucket.get('secondaryMirrorBucket')
        : bucket.get('secondaryOverlayBucket');
      if (
        secondaryOutsort &&
        secondaryBucketIndex !== undefined &&
        bucket.firstQuery.get('secondaryTopxSeparate') === false
      ) {
        const secondaryBucket = dataview.queryBuckets.at(secondaryBucketIndex);
        this.buildSeriesInternal(bucket, models, secondaryBucket, secondaryOutsort);
      }
    });
  }

  buildSeriesInternal(primaryBucket, models, bucketOverride, outsortOverride) {
    if (!models || !models.length) {
      return;
    }

    const bucket = bucketOverride || primaryBucket;

    const query = primaryBucket.firstQuery;
    const period_over_period = query.get('period_over_period');
    const metric = query.get('metric');
    const bucketName = bucket.get('name');
    const rawDataKey = this.getRawDataKey(query, outsortOverride);
    const names = [];

    if (!this.series[bucketName]) {
      this.series[bucketName] = {};
    }

    models.forEach((model) => {
      if (model.get('isOverlay')) {
        return this.buildOverlaySeries(primaryBucket, model, bucketOverride, outsortOverride);
      }

      const lookup = model.get('lookup');
      const key = model.get('key');
      const rawData = model.get('rawData');
      if (!rawData || !rawData[rawDataKey]) {
        return null;
      }

      const name = (lookup || key)
        .split(' ---- ')
        .map((part, idx) => getTrimmedName(metric[idx], part))
        .join(' ---- ');
      names.push(name);

      if (this.series[bucketName][name]) {
        return this.updateRenderedSeries(bucket, name, rawData[rawDataKey], model.get('forecast'));
      }

      const series = this.renderSeries(bucket, name, rawData[rawDataKey].flow, query);
      if (series) {
        this.series[bucketName][name] = model;
        series.options.bucketName = bucketName;
        series.options.model = model;
        if (series.userOptions) {
          series.userOptions.model = model;
        }

        if (series.colorIndex !== undefined) {
          model.set({
            colorIndex: series.colorIndex,
            color: this.getColorFromIndex(series.colorIndex),
            toggled: false,
            // bind setVisible and redraw so can do batch actions without performance death (i.e. using toggle a bunch)
            setVisible: series.setVisible.bind(series),
            redraw: this.redraw.bind(this)
          });
          this.togglerDisposers.push(
            autorun(() => {
              const toggled = model.get('toggled');
              const comparing = model.get('comparing');
              const mouseover = model.get('mouseover');
              const preventRedraw = model.get('preventRedraw');
              model.set('preventRedraw', false);
              if (period_over_period) {
                if (comparing) {
                  this.compareSeries(series);
                } else if (this.comparisonSeries === series.name) {
                  this.removeComparison();
                }
              } else if (toggled === series.visible) {
                this.toggleSeries(series, toggled, preventRedraw);
              }
              if (mouseover) {
                this.highlightSeries(series);
              } else {
                this.unhighlightSeries(series);
              }
            })
          );
        }
      }
      return series;
    });

    this.removeOldSeries(bucketName, names);

    this.redraw();
  }

  buildOverlaySeries(primaryBucket, model, bucketOverride, outsortOverride) {
    const bucket = bucketOverride || primaryBucket;
    const name = model.get('name');
    const rawData = model.get('rawData');
    const bucketName = bucket.get('name');
    const query = primaryBucket.overlayQueries.find((q) => q.get('descriptor') === name) || primaryBucket.firstQuery;
    const rawDataKey = this.getRawDataKey(query, outsortOverride);
    const period_over_period = query.get('period_over_period');

    if (!rawData || !rawData[rawDataKey]) {
      return null;
    }

    if (!this.overlaySeries[bucketName]) {
      this.overlaySeries[bucketName] = {};
    }

    if (this.overlaySeries[bucketName][name]) {
      return this.updateRenderedOverlay(bucket, name, rawData[rawDataKey], query);
    }

    const series = this.renderOverlay(bucket, name, rawData[rawDataKey].flow, query);
    if (series) {
      this.overlaySeries[bucketName][name] = model;
      series.options.model = model;
      if (series.userOptions) {
        series.userOptions.model = model;
      }

      if (series.colorIndex !== undefined) {
        model.set({ colorIndex: series.colorIndex, color: this.getColorFromIndex(series.colorIndex), toggled: false });
        this.togglerDisposers.push(
          autorun(() => {
            const toggled = model.get('toggled');
            const comparing = model.get('comparing');
            const mouseover = model.get('mouseover');
            const preventRedraw = model.get('preventRedraw');
            model.set('preventRedraw', false);
            if (period_over_period) {
              if (comparing) {
                this.compareSeries(series);
              } else if (this.comparisonSeries === series.name) {
                this.removeComparison();
              }
            } else if (toggled === series.visible) {
              this.toggleSeries(series, toggled, preventRedraw);
            }
            if (mouseover) {
              this.highlightSeries(series);
            } else {
              this.unhighlightSeries(series);
            }
          })
        );
      }
    }
    return series;
  }

  removeOldSeries(bucketName, names) {
    Object.keys(this.series[bucketName]).forEach((name) => {
      if (!names.includes(name)) {
        this.removeSeries(bucketName, name);
        delete this.series[bucketName][name];
      }
    });
  }

  renderSeries() {
    console.warn('renderSeries not implemented');
  }

  renderOverlay() {
    console.warn('renderOverlay not implemented');
  }

  updateRenderedSeries() {
    console.warn('updateRenderedSeries not implemented');
  }

  updateRenderedOverlay() {
    console.warn('updateRenderedOverlay not implemented');
  }

  removeSeries() {
    console.warn('removeSeries not implemented');
  }

  removeOverlay() {
    console.warn('removeOverlay not implemented');
  }

  toggleSeries() {
    console.warn('toggleSeries not implemented');
  }

  compareSeries() {
    console.warn('compareSeries not implemented');
  }

  removeComparison() {
    console.warn('removeComparison not implemented');
  }

  renderTimeReset() {
    console.warn('renderTimeReset not implemented');
  }

  clear() {
    console.warn('clear not implemented');
  }

  redraw() {
    console.warn('redraw not implemented');
  }

  reflow() {
    console.warn('reflow not implemented');
  }

  syncAxes() {
    // making sure we don't get an error if dataview doesn't have this
  }

  getExtremes() {
    // making sure we don't get an error if dataview doesn't have this
  }

  setExtremes() {
    // making sure we don't get an error if dataview doesn't have this
  }

  showActivePeriod() {
    // making sure we don't get an error if dataview doesn't have this
  }

  getComponent() {
    throw new Error('You must implement getComponent when extending BaseDataview');
  }

  componentDidMount() {
    this.darkThemeDisposer = reaction(
      () => $app.darkThemeEnabled,
      () => this.redraw()
    );
    // It's weird that this is called what it's called when more things happen still,
    // But this is because all of this was previously componentWillMount
    this.afterComponentDidMount();

    const { dataview } = this.props;
    dataview.component = this;

    this.buildBucketSeries();

    $dataviews.register(this);
  }

  componentWillUnmount() {
    this.darkThemeDisposer();
    this.darkThemeDisposer = null;
    this.togglerDisposers.forEach((disposer) => disposer());
    this.togglerDisposers = [];
    this.afterComponentWillUnmount();

    $dataviews.unregister(this);
  }

  afterComponentDidMount() {
    // override this if need logic on mount
  }

  afterComponentWillUnmount() {
    // override this if need logic on unmount
  }

  componentDidUpdate(prevProps) {
    const { dataview, forceLoad, lastUpdated, viewProps } = this.props;
    const { queryBuckets } = dataview;

    dataview.component = this;

    if (dataview.loading) {
      this.series = {};
      this.overlaySeries = {};
      this.comparisonSeries = null;
      this.togglerDisposers.forEach((disposer) => disposer());
      this.togglerDisposers = [];
      this.clear();
    }

    if (!isEqual(viewProps, prevProps.viewProps)) {
      this.redraw();
    }

    // we do this here (for now?) because mobx-react is calling forceUpdate()
    // perhaps we need to figure out how to decouple this from observables better...
    if (dataview.loading || (!forceLoad && lastUpdated <= prevProps.lastUpdated)) {
      // nothing's updated, return;
      return;
    }

    // This is needed to get the initial chart sized correctly
    if (!this.showNoResults) {
      this.reflow();
    }

    this.buildBucketSeries();

    if (dataview.hasTimeReset) {
      $app.renderSync(() => this.renderTimeReset());
    }

    if (queryBuckets.selectedQuery.get('sync_axes')) {
      this.syncAxes(true);
    }

    if (this.selectedModels) {
      setTimeout(() => this.setSelectedModels(this.selectedModels), 50);
    }
  }

  @computed
  get showNoResults() {
    const { dataview } = this.props;

    return (
      dataview.preventQuery ||
      (dataview.queryBuckets.fullyLoaded &&
        dataview.queryBuckets.activeBuckets.every((bucket) => bucket.queryResults.size === 0))
    );
  }

  _getShowSpinner() {
    const { dataview } = this.props;
    return !dataview.hideLoadMask && (dataview.loading || !dataview.lastUpdated);
  }

  @computed
  get showSpinner() {
    return this._getShowSpinner();
  }

  _getShowCallout() {
    const { dataview } = this.props;
    return !dataview.loading && (dataview.errors.length > 0 || (this.showNoResults && this.alwaysShowDataView));
  }

  @computed
  get showCallout() {
    return this._getShowCallout();
  }

  _getCalloutIntent() {
    const { dataview } = this.props;

    if (dataview.errors.length > 0) {
      return 'danger';
    }

    if (this.showNoResults) {
      return 'primary';
    }

    return 'none';
  }

  @computed
  get calloutIntent() {
    return this._getCalloutIntent();
  }

  _getCalloutIcon() {
    const { dataview } = this.props;

    if (dataview.errors.length > 0) {
      return BsFillExclamationTriangleFill;
    }

    if (this.showNoResults) {
      return FiInfo;
    }

    return null;
  }

  @computed
  get calloutIcon() {
    return this._getCalloutIcon();
  }

  _getCalloutMessage() {
    const { dataview } = this.props;

    if (dataview.errors.length > 0) {
      return dataview.errorMessage;
    }

    if (this.showNoResults) {
      return 'No results were returned';
    }

    return null;
  }

  @computed
  get calloutMessage() {
    return this._getCalloutMessage();
  }

  render() {
    const { dataview, viewProps, className, minHeight, spinnerProps = {} } = this.props;
    const { loading } = dataview;
    let DataViewComponent;

    if (this.showNoResults || !loading) {
      this.dismissSpinner();
    }

    if (this.showNoResults && !this.alwaysShowDataView) {
      DataViewComponent = (
        <Flex
          flex="1 1 auto"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{ height: '100%', minHeight }}
        >
          <EmptyState
            title="No Results"
            description={
              dataview.hasTimeReset && (
                <Button intent="primary" onClick={() => dataview.resetTimeRange()} large>
                  Zoom out
                </Button>
              )
            }
          />
        </Flex>
      );
    } else {
      try {
        DataViewComponent = this.getComponent();
      } catch (err) {
        this.dismissSpinner();
        DataViewComponent = (
          <Flex justifyContent="center" top={0} position="absolute" mt="40px" width="100%">
            <CalloutOutline intent="warning" width="90%" maxWidth={400}>
              <Flex alignItems="flex-start">
                <Icon icon={BsFillExclamationTriangleFill} color="warning" mr={1} mt="1px" />
                {err.message}
              </Flex>
            </CalloutOutline>
          </Flex>
        );
      }
    }

    return (
      <Box
        className={className}
        position="relative"
        width="100%"
        height={viewProps ? viewProps.height : undefined}
        minHeight={minHeight}
      >
        {((!loading && !this.showCallout) || this.alwaysShowDataView) && DataViewComponent}
        <DataViewSpinner {...this.props} showSpinner={this.showSpinner} mt="40px" {...spinnerProps} />
        {this.showCallout && (
          <Flex justifyContent="center" top={0} position="absolute" mt="40px" width="100%">
            <CalloutOutline intent={this.calloutIntent} textAlign="center" width="90%" maxWidth={400}>
              <Flex alignItems="flex-start">
                <Icon icon={this.calloutIcon} color={this.calloutIntent} mr={1} mt="1px" />
                {this.calloutMessage}
              </Flex>
            </CalloutOutline>
          </Flex>
        )}
      </Box>
    );
  }
}
