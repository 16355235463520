import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Suspense, Box, Flex, Text, Highcharts, Button } from 'core/components';
import { OPERATOR_LABELS } from 'app/views/alerting/policies/PolicyDetails/conditions/ConditionsTags';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import { VirtualizedTable } from 'core/components/table';
import TargetRenderer from 'app/views/protect/ddos/analyze/components/TargetRenderer';
import MetricRenderer from 'app/views/protect/ddos/analyze/components/MetricRenderer';
import NmsNativeAlertMetricListRenderer from 'app/views/alerting/components/nms/NmsNativeAlertMetricListRenderer';
import NmsNativeAlertDimensionRenderer from 'app/views/alerting/components/nms/NmsNativeAlertDimensionRenderer';
import AlertCollection from 'app/stores/alerting/AlertCollection';
import { Field, FormComponent, Select } from 'core/form';
import LoadingSkeleton from 'app/views/metrics/LoadingSkeleton';
import classNames from 'classnames';
import { Classes } from '@blueprintjs/core';
import { DEFAULT_QUALITATIVE_PALETTES } from '../../../../stores/colors/Colors';

import { fetchAlertsParameters } from './shared';
import withConfigOptions from '../withConfigOptions';

const DEFAULT_ALERT_FETCH_OPTIONS = {
  ...fetchAlertsParameters,
  states: ['alarm']
};

function getSubtitle(total) {
  return `
    <div style="text-align: center;">
      <div style="font-size:20px; font-weight: bold; margin: 2px 0 0 0; line-height: 1em;">${total}</div>
      <div style="font-size:13px; font-weight: normal; opacity: 0.65;">Active</div>
    </div>
  `;
}

@inject('$app', '$insights')
@withConfigOptions
@withRouter
@observer
class ActiveAlertsWidget extends Component {
  state = {
    loading: true,
    hasLoaded: false,
    alertFilters: DEFAULT_ALERT_FETCH_OPTIONS,
    alertCollection: new AlertCollection()
  };

  componentDidMount() {
    this.fetchAlerts();
  }

  componentWillUnmount() {
    if (this.resizeWatcher && this.el) {
      this.resizeWatcher.unobserve(this.el);
    } else if (this.resizeWatcher) {
      this.resizeWatcher.disconnect();
    }
  }

  get config() {
    const { config } = this.props;
    const { groupBy = 'policyName' } = config;
    return { groupBy };
  }

  fetchAlerts() {
    const { alertFilters, alertCollection } = this.state;
    alertCollection.setServerFilter(alertFilters).then(() => {
      this.setState({ loading: false, hasLoaded: true });
    });
  }

  handleSave(form) {
    const { handleSaveConfiguration } = this.props;
    handleSaveConfiguration(form);
  }

  generateConfig = (series) => {
    const { $app } = this.props;

    const total = series[0].data.reduce((accumulator, currentDataPoint) => accumulator + currentDataPoint.y, 0);

    return {
      series,
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        spacingTop: 10,
        spacingBottom: 10,
        margin: 12
      },

      title: {
        useHTML: true,
        text: getSubtitle(total),
        floating: true,
        verticalAlign: 'middle',
        y: 12
      },

      legend: {
        enabled: false
      },

      tooltip: {
        outside: true,
        className: classNames('highcharts-outside-tooltip', {
          [Classes.DARK]: $app.isDarkTheme
        }),
        formatter() {
          return `${Math.round(this.percentage)}%</b> of total`;
        }
      },

      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          size: 120,
          startAngle: 90,
          // center: "['50% ', 105]",
          innerSize: '70%', // Set the inner hole size for donut chart
          dataLabels: {
            distance: 0.1,
            alignTo: 'connectors',
            connectorShape: 'crookedLine',
            padding: 2,
            verticalAlign: 'top',
            crookDistance: '90%',
            enabled: true,
            format: '<span style="font-weight: normal;">{point.name}</span><br /> <b>{point.y} alerts</b>'
          }
        },
        series: {
          allowPointSelect: true,
          pie: {
            states: {
              hover: {
                enabled: true
              },
              inactive: {
                enabled: true,
                opacity: 0.2
              }
            }
          },
          point: {
            events: {
              select: this.handleSelectSeries,
              unselect: this.handleUnselectSeries
            }
          }
        }
      }
    };
  };

  handleSetAlertFilters = (filters) => {
    const { alertFilters } = this.state;
    const { alertIds, ...restFilters } = filters;
    const newAlertFilters = { ...alertFilters, ...restFilters };

    if (alertIds.length > 0) {
      newAlertFilters.filter = { alarmIds: alertIds };
    }

    this.setState({ alertFilters: newAlertFilters }, () => {
      this.fetchAlerts();
    });
  };

  handleResetAlertFilters = () => {
    this.setState({ alertFilters: DEFAULT_ALERT_FETCH_OPTIONS }, () => {
      this.fetchAlerts();
    });
  };

  chartCallback = (chart) => {
    this.chart = chart.container ? chart : null;
    this.el = chart.container;
    this.resizeWatcher = new ResizeObserver(() => {
      try {
        this.chart.reflow();
      } catch (e) {
        // OK to ignore
      }
    });
    this.resizeWatcher.observe(this.el);
  };

  // when a Series is selected, filter the Table below to only include those Alerts
  handleSelectSeries = (e) => {
    const { alertIds } = e.target;
    this.handleSetAlertFilters({ alertIds });
  };

  handleUnselectSeries = (e) => {
    // if there are new selected points, we don't need to reset
    if (e.target.series?.chart?.getSelectedPoints().length) {
      return false;
    }

    return this.handleResetAlertFilters();
  };

  renderConfigurePanel() {
    const { handleCancelConfiguration, model, config } = this.props;
    const { groupBy } = this.config;

    const fields = {
      groupBy: {
        label: 'Visualization Grouping',
        helpText: 'Choose the grouping for the visualization.',
        defaultValue: groupBy,
        options: [
          {
            label: 'Policy Name',
            value: 'policyName'
          },
          {
            label: 'Severity',
            value: 'severity'
          }
        ]
      }
    };

    return (
      <FormComponent fields={fields} model={model} options={{ name: 'Configure Alert History', large: true }}>
        {({ form }) => (
          <Flex p={2} gap={3}>
            <Box>
              <Field name="groupBy" large>
                <Select />
              </Field>

              <Flex gap={1} mt={3}>
                {config.lookback !== undefined && config.interval !== undefined && (
                  <Button text="Cancel" flex={1} minWidth={100} onClick={() => handleCancelConfiguration(form)} />
                )}
                <Button
                  text="Save"
                  flex={1}
                  intent="primary"
                  disabled={!form.valid}
                  onClick={() => this.handleSave(form)}
                />
              </Flex>
            </Box>
          </Flex>
        )}
      </FormComponent>
    );
  }

  renderWidgetContent() {
    const { history, height } = this.props;
    const { hasLoaded, alertCollection } = this.state;
    const { groupBy } = this.config;

    const columns = [
      {
        label: 'Policy',
        name: 'policyName',
        id: 'policyName',
        value: 'policyName',
        computed: true,
        flexBasis: 225,
        minWidth: 225,
        sortable: true,
        sortField: 'policyName'
      },
      {
        label: 'Dimensions',
        name: 'primaryDimension',
        id: 'primaryDimension',
        value: 'primaryDimension',
        flexBasis: 200,
        minWidth: 200,
        computed: true,
        ellipsis: false,
        renderer: ({ model }) =>
          model.isNmsPolicy ? (
            <NmsNativeAlertDimensionRenderer maxDimensionsToShow={3} alertModel={model} />
          ) : (
            <TargetRenderer model={model} />
          )
      },
      {
        label: 'Metric',
        name: 'metric',
        id: 'metric',
        value: 'metric',
        ellipsis: false,
        minWidth: 125,
        flexBasis: 125,
        renderer: ({ model }) => {
          let operator = '';
          if (model.threshold?.conditions?.length > 0) {
            operator = model.threshold.conditions[0].operator;
          }
          const operatorLabel = OPERATOR_LABELS[operator];
          return (
            <Flex gap="4px" alignItems="center">
              {!model.isNmsPolicy && model.isStateChangeAlert && operatorLabel !== '=' && <Text>{operatorLabel}</Text>}
              {!model.isNmsPolicy && <MetricRenderer model={model} showLabel={false} />}
              {model.isNmsPolicy && <NmsNativeAlertMetricListRenderer alertModel={model} numMetricsToShow={2} />}
            </Flex>
          );
        }
      },
      {
        label: 'Duration',
        name: 'displayDuration',
        id: 'displayDuration',
        value: 'displayDuration',
        computed: true,
        sortable: true
      }
    ];

    function handleRowClick(model) {
      history.push(model.alertDetailLink);
    }

    if (!hasLoaded) {
      return <Text muted>Loading...</Text>;
    }

    const series = alertCollection.getActiveAlertsChartData(groupBy);

    if (series?.length === 0) {
      return (
        <Flex flexDirection="column" flex={1} height="100%" alignItems="center" justifyContent="center">
          <Text muted minimal margin="0px auto" p={2}>
            No active alerts on your network!
          </Text>
        </Flex>
      );
    }
    const hasSeries = series.length > 0;
    const hasMultipleDatasets = series?.[0]?.data?.length > 1;
    const config = this.generateConfig(series);
    return (
      <Flex flexDirection="column" flex={1} height="100%">
        {hasSeries && (
          <Box height={hasMultipleDatasets ? height / 3 : 'auto'}>
            <Highcharts
              height={height / 3}
              width="100%"
              callback={this.chartCallback}
              colors={DEFAULT_QUALITATIVE_PALETTES.qualitative4}
              options={config}
            />
          </Box>
        )}

        <Flex flexDirection="column" flex={1} height="100%">
          <VirtualizedTable
            flexed
            collection={alertCollection}
            groupSummaryLookup={this.groupSummaryLookup}
            columns={columns}
            onRowClick={(model) => handleRowClick(model)}
          />
        </Flex>
      </Flex>
    );
  }

  render() {
    const { canCustomize, onRemove, handleShowConfigurePanel, isConfigurePanelOpen } = this.props;
    const { loading, hasLoaded } = this.state;

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        onRemove={onRemove}
        configAction={handleShowConfigurePanel}
        title="Active Alerts"
      >
        <Suspense loading={loading && !hasLoaded} fallback={<LoadingSkeleton />}>
          {isConfigurePanelOpen && this.renderConfigurePanel()}
          {!isConfigurePanelOpen && this.renderWidgetContent()}
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default ActiveAlertsWidget;
