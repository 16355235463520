import React from 'react';

import { Box, Text, LinkButton } from 'core/components';
import $auth from 'app/stores/$auth';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: ['i_trf_origination', 'i_trf_termination', 'i_trf_profile'],
  filterFields: ['i_trf_origination', 'i_trf_termination', 'i_trf_origination|termination', 'i_trf_profile']
};

class NetworkClassDependency extends AbstractDependency {
  get key() {
    return 'nc';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return $auth.networkClassEstablished;
  }

  get message() {
    return (
      <>
        <Box mb="4px">
          <Text small>
            <strong>Network Classification</strong> is required by dimensions you&apos;re using, but your organization
            does not have its internal IP space defined. Results shown herein may be highly inaccurate.
          </Text>
        </Box>
        <LinkButton icon="cog" small text="Configure" to="/v4/settings/network-classification" />
      </>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || $auth.networkClassEstablished;
  }
}

export default NetworkClassDependency;
