import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { Box, Flex, Icon, Sparkline, Text } from 'core/components';
import { greekPrefix } from 'core/util/greekPrefixing';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import { getMetricsResultDimensionColumns } from 'app/views/metrics/result/dimensionColumns';
import ReconExplorerChart from 'app/views/metrics/ReconExplorerChart';
import LoadingSkeleton from 'app/views/metrics/LoadingSkeleton';
import { formatMetricsValueForDisplay, formatMetricsValueForTooltip } from 'app/views/metrics/utils/formatMetricValues';
import { capitalizeFirstLetter } from 'app/util/utils';
import { getActionColumns } from 'app/views/metrics/result/actionColumn';
import MetricsExplorerDateRange from 'app/views/metrics/MetricsExplorerDateRange';
import { SizeMe } from 'react-sizeme';
import SplitPane from 'react-split-pane';
import MetricsExplorerAppliedFiltersButton from 'app/components/dataviews/tools/MetricsExplorerAppliedFiltersButton';
import { MetricsResultLegend } from 'app/views/metrics/result/MetricsResultLegend';

const colorColumn = {
  name: 'color',
  label: '',
  width: 24,
  renderer: ({ value }) => (value ? <Icon icon="full-circle" color={value} iconSize={12} /> : null),
  colSpan: (model, totalRow) => (totalRow ? 2 : 1),
  totalRenderer: ({ value }) => `Total results: ${value}`
};

@inject('$app', '$auth', '$colors', '$metrics', '$query')
@observer
export default class MetricsResult extends Component {
  static defaultProps = {
    isWidget: false,
    showAxisLabels: true,
    showSeriesRowColors: true,
    showLegend: false,
    noLegend: false,
    showVisualizationSelector: false,
    showSparklines: false,
    enableSplitPane: false,
    onQueryComplete: undefined,
    useSimplifiedRollupDisplay: false,
    useHorizontalLayout: false,
    splitPaneProps: {},
    dimensionValueMap: {}
  };

  state = {
    loading: true,
    queryResults: undefined,
    dataview: undefined
  };

  componentDidMount() {
    const { isReadyForQuery } = this.props;

    if (isReadyForQuery !== false) {
      this.runQuery();
    }
  }

  componentWillUnmount() {
    const { dataview } = this.state;
    if (dataview) {
      dataview.clear();
    }

    if (this.activeQuery) {
      this.activeQuery.cancel();
    }

    if (this.queryInterval) {
      clearInterval(this.queryInterval);
    }
  }

  get enableTimeSeries() {
    const { query } = this.props;
    return query.kmetrics?.includeTimeseries > 0;
  }

  get showChart() {
    const { query } = this.props;
    return query.kmetrics?.viz?.type !== 'table';
  }

  get showLegend() {
    const { showLegend } = this.props;
    return showLegend || !this.showChart;
  }

  get dimensions() {
    const { query } = this.props;
    return query.kmetrics.dimensions;
  }

  get measurement() {
    const { query } = this.props;
    return query.kmetrics.measurement;
  }

  get metrics() {
    const { query } = this.props;
    return query.kmetrics.metrics.map((metric) => metric.name);
  }

  /**
   * Get the query along with all relevant fields which are expected to be contained within the query.
   * @returns {{query, metrics, measurement, enableTimeSeries, rollups, dimensions}}
   */
  get decoratedQuery() {
    const { query } = this.props;
    return {
      query,
      metrics: this.metrics,
      measurement: this.measurement,
      dimensions: this.dimensions,
      enableTimeSeries: this.enableTimeSeries,
      rollups: this.rollups
    };
  }

  shouldPrefix({ metric, unit = '' }) {
    return !(
      unit?.startsWith('timestamp') ||
      unit === 'duration' ||
      (this.measurement === 'IF-MIB::if' && ['if_AdminStatus', 'if_OperStatus'].includes(metric)) ||
      (this.measurement === '/interfaces/counters' && ['admin-status', 'oper-status'].includes(metric)) ||
      (this.measurement === '/protocols/bgp/neighbors' && metric === 'session-state')
    );
  }

  getMetricAlign({ metric, unit = '' }) {
    if (
      unit === 'duration' ||
      (this.measurement === 'IF-MIB::if' && ['if_AdminStatus', 'if_OperStatus'].includes(metric)) ||
      (this.measurement === '/interfaces/counters' && ['admin-status', 'oper-status'].includes(metric)) ||
      (this.measurement === '/protocols/bgp/neighbors' && metric === 'session-state')
    ) {
      return 'left';
    }

    return 'right';
  }

  @computed
  get prefixes() {
    const { $metrics } = this.props;
    const { queryResults } = this.state;
    const prefixes = {};
    const fullSelectedMeasurement = $metrics.measurementModelByName(this.measurement);
    if (!fullSelectedMeasurement) {
      return {};
    }
    const unitMeta = this.metrics.map((m) => fullSelectedMeasurement.storage.Metrics[m]?.Unit).filter((unit) => !!unit);
    const units = new Set(unitMeta);
    units.forEach((unit) => {
      const data = [];
      Object.keys(this.rollups).forEach((rollupName) => {
        const { metric } = this.rollups[rollupName];

        if (this.shouldPrefix({ metric, unit }) && fullSelectedMeasurement.storage.Metrics[metric]?.Unit === unit) {
          data.push(...queryResults.map((model) => model.get(rollupName)));
        }
      });
      prefixes[unit] = greekPrefix(data);
    });
    return prefixes;
  }

  get rollups() {
    const { query } = this.props;
    return query.kmetrics.rollups;
  }

  seriesColor = (colorIndex) => {
    const { $colors } = this.props;
    const { chartColors } = $colors;
    return chartColors[colorIndex % chartColors.length];
  };

  adjustSample = (sample, unit) => {
    if (unit === 'duration:microseconds') {
      return sample / 1000;
    }
    return sample;
  };

  getGroupedQueryResults = () => {
    // Transforms query result to chart format.
    const { queryResults } = this.state;
    const { query, showSparklines, $metrics, dimensionValueMap } = this.props;
    const numSeries = query.kmetrics?.includeTimeseries || 0;
    const measurementMeta = $metrics.measurementModelByName(this.measurement);
    const units = Object.fromEntries(this.metrics.map((m) => [m, measurementMeta?.storage.Metrics[m]?.Unit]));

    const resultSeries = [];
    const resultRows = [];

    queryResults.each((row, rowIndex) => {
      const { timeseries, metrics, ...rowData } = row.get();
      const dimensionNameArray = this.dimensions.map((dimension) => {
        const dimensionValue = row.get(dimension);
        return dimensionValueMap[dimensionValue] || dimensionValue;
      });

      const seriesName = dimensionNameArray.join('-');
      rowData.seriesName = seriesName;

      if (rowIndex < numSeries && this.enableTimeSeries && showSparklines) {
        rowData.sparklines = {};

        this.metrics.forEach((metric) => {
          const idx = metrics.indexOf(metric) || 0;
          rowData.sparklines[metric] = timeseries.map(([, ...metricValues]) => metricValues[idx]);
        });
      }

      if (rowIndex < numSeries) {
        rowData.color = this.seriesColor(rowIndex);
      }

      const timeseriesMetrics = {};
      this.metrics.forEach((metric) => {
        timeseriesMetrics[metric] = [];
      });

      timeseries.forEach((timeSlice) => {
        this.metrics.forEach((metric) => {
          const idx = metrics.indexOf(metric) || 0;
          const metricValue = timeSlice[idx + 1];
          timeseriesMetrics[metric].push([timeSlice[0], this.adjustSample(metricValue, units[metric])]);
        });
      });

      const series = Object.keys(timeseriesMetrics).map((metric) => {
        const seriesRow = {
          name: seriesName,
          metric,
          data: timeseriesMetrics[metric]
        };

        if (query.kmetrics.streamingUpdate) {
          const startIndex = timeseries.findIndex((timeSlice) => timeSlice.includes('$FRESH_UPDATE$'));
          seriesRow.zoneAxis = 'x';
          seriesRow.zones = [];

          if (startIndex === -1) {
            seriesRow.zones.push({ className: 'zone-faded' });
          } else if (startIndex === 0) {
            seriesRow.zones.push({ className: '' });
          } else {
            const value = (timeseries[startIndex - 1][0] + timeseries[startIndex][0]) / 2;
            seriesRow.zones.push({ value, className: 'zone-faded' }, { className: '' });
          }
        }

        return seriesRow;
      });

      resultSeries.push(series);
      resultRows.push(rowData);
    });

    return {
      series: resultSeries.slice(0, numSeries),
      rows: resultRows
    };
  };

  doQuery = () => {
    const { query, $query, hideToast, $metrics } = this.props;
    const { dataview } = this.state;
    if (dataview) {
      dataview.destroy();
    }

    if (!$metrics?.measurementModel(this.measurement)) {
      this.setState({ loading: false });
    } else {
      this.activeQuery = makeCancelable($query.runQuery(query, false, hideToast));
      this.activeQuery.promise
        .then((response) => {
          this.handleQueryComplete(response);
          if (!this.queryInterval && query.update_frequency) {
            this.queryInterval = setInterval(this.doQuery, query.update_frequency * 1000);
          }
        })
        .catch(this.onQueryError);
    }
  };

  runQuery = () => {
    this.setState({ loading: true }, () => this.doQuery());
  };

  handleQueryComplete = (response) => {
    const { results, dataview, fullyLoaded } = response;
    const { query, onQueryComplete } = this.props;

    this.activeQuery = null;

    if (results && fullyLoaded) {
      this.setState(
        {
          queryResults: results,
          dataview,
          loading: false
        },
        () => {
          if (onQueryComplete) {
            onQueryComplete({
              results,
              dataview,
              query,
              fullyLoaded: true
            });
          }
        }
      );
    } else {
      this.setState(
        {
          queryResults: undefined,
          dataview,
          loading: false
        },
        () => {
          if (onQueryComplete) {
            onQueryComplete({
              results,
              dataview,
              query,
              fullyLoaded: true
            });
          }
        }
      );
    }
  };

  onQueryError = (error) => {
    const { onQueryError } = this.props;

    if (!(error instanceof CanceledError)) {
      if (onQueryError) {
        onQueryError(error);
      } else {
        console.error(error);
      }
    }
  };

  handleContextMenu = (e) => {
    const { $auth } = this.props;
    const { dataview } = this.state;

    if ((e.shiftKey || e.metaKey) && $auth.isSudoer) {
      e.stopPropagation();
      e.preventDefault();
      console.info({
        requestIds: dataview.queryBuckets.models.flatMap((qb) => qb.socketIdHistory)
      });
    }
  };

  getLegendColumns() {
    const { query, showSparklines, useSimplifiedRollupDisplay, $metrics, onShowDimensionSelector } = this.props;
    const fullSelectedMeasurement = $metrics.measurementModelByName(this.measurement);
    const { prefixes } = this; // call this only once!
    const { queryResults } = this.state;

    const dimensionColumns = getMetricsResultDimensionColumns({
      decoratedQuery: this.decoratedQuery,
      fullSelectedMeasurement
    });

    const displayedSparklineMetrics = new Set();
    const rollupColumns = Object.keys(this.rollups).map((rollup) => {
      const { aggregate, metric } = this.rollups[rollup];
      const metricMeta = fullSelectedMeasurement?.get(`storage.Metrics.${metric}`);
      const showSparklineForMetric = this.enableTimeSeries && showSparklines && !displayedSparklineMetrics.has(metric);

      if (showSparklineForMetric) {
        displayedSparklineMetrics.add(metric);
      }

      return {
        name: rollup,
        flexBasis: showSparklineForMetric ? 200 : 150,
        minWidth: showSparklineForMetric ? 100 : 75,
        align: this.getMetricAlign({ metric, unit: metricMeta.Unit }),
        label: useSimplifiedRollupDisplay ? (
          <Text>{capitalizeFirstLetter(aggregate)}</Text>
        ) : (
          <Flex flexDirection="column">
            <Text muted style={{ fontSize: '10px' }}>
              {capitalizeFirstLetter(aggregate)} {metricMeta.Unit?.split(':')[0]}
            </Text>
            <span>{metricMeta.Label || metric}</span>
          </Flex>
        ),
        renderer: ({ model, value }) => {
          const sparklineData = model.get(`sparklines.${metric}`) || [];

          let valueToRender = formatMetricsValueForDisplay(
            value,
            {
              metric,
              model,
              measurementModel: fullSelectedMeasurement,
              kmetricsQuery: query?.kmetrics,
              prefixes,
              rollup
            },
            true
          );

          // if we're showing sparklines, we want a reasonable minimum width on the value so the
          // sparklines line up.
          if (showSparklineForMetric) {
            valueToRender = <div style={{ minWidth: 30 }}>{valueToRender}</div>;
          }

          return (
            <Flex alignItems="center" gap="4px" width="min-content">
              {showSparklineForMetric && (
                <Box height={16} minWidth={40}>
                  <Sparkline
                    data={sparklineData}
                    height={20}
                    width={50}
                    strokeWidth={2}
                    color="primary"
                    min={0}
                    max={metricMeta.Unit === 'percent' ? Math.max(100, ...sparklineData) : undefined}
                  />
                </Box>
              )}
              {valueToRender}
            </Flex>
          );
        },
        title: ({ model, value }) =>
          formatMetricsValueForTooltip(value, { metric, model, measurementModel: fullSelectedMeasurement, rollup })
      };
    });

    return (this.showChart ? [colorColumn] : []).concat(
      dimensionColumns,
      rollupColumns,
      getActionColumns(this.decoratedQuery, onShowDimensionSelector, queryResults.lastUpdated)
    );
  }

  getQueryTools() {
    const { query } = this.props;
    return (
      <Flex className="me-tools" alignItems="center" gap={1} mb={1} width="100%" p="12px">
        <MetricsExplorerDateRange query={query} />
        <Box width="1px" height="100%" borderRight="thin" />
        <Text muted small>
          {this.measurement}
        </Text>
        {query?.kmetrics?.filters?.filterGroups.length || query?.kmetrics?.rollupFilters?.filterGroups.length ? (
          <Box width="1px" height="100%" borderRight="thin" />
        ) : null}
        <MetricsExplorerAppliedFiltersButton query={query} />
      </Flex>
    );
  }

  isRowSelected = (model) => {
    const { selectedItem } = this.props;
    if (!selectedItem) {
      return false;
    }
    const row = model.get();
    for (const [key, value] of Object.entries(selectedItem)) {
      if (value && row[key] !== value) {
        return false;
      }
    }
    return true;
  };

  render() {
    const {
      query,
      isWidget,
      showAxisLabels,
      showSharedTooltip,
      showVisualizationSelector,
      showTools,
      enableSplitPane,
      splitPaneProps,
      hideTotalRow,
      containerHeight,
      containerWidth,
      highChartOptions,
      useHorizontalLayout,
      chartHeight = 400,
      isReadyForQuery,
      onSplitPaneResize,
      useUTC,
      noLegend,
      $app,
      showLegend,
      legendProps,
      isExport
    } = this.props;

    const { loading, queryResults } = this.state;

    if (isReadyForQuery === false) {
      return (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          px={3}
          width={containerWidth}
          height={containerHeight}
          minHeight={100}
        >
          <Text small muted textAlign="center">
            Please enter a valid query
          </Text>
        </Flex>
      );
    }

    if (loading) {
      return <LoadingSkeleton height={containerHeight} width={containerWidth} />;
    }

    if (!queryResults || queryResults.size === 0) {
      return (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={containerWidth}
          height={containerHeight}
          className="nms-result"
        >
          {showTools && this.getQueryTools()}
          <Text small muted textAlign="center">
            No data to display for the measurement <b>{this.measurement}</b>
          </Text>
        </Flex>
      );
    }

    const rollupNames = Object.keys(this.rollups);
    const { series, rows } = this.getGroupedQueryResults();

    const reconChart = (
      <ReconExplorerChart
        enableTimeSeries={this.enableTimeSeries}
        groupedQueryResults={series}
        query={query}
        rollupRows={rows}
        rollupNames={rollupNames}
        showAxisLabels={showAxisLabels}
        showSharedTooltip={showSharedTooltip}
        showVisualizationSelector={showVisualizationSelector}
        showTools={showTools}
        highChartOptions={highChartOptions}
        useHorizontalLayout={useHorizontalLayout}
        showLegend={!this.showLegend && !noLegend}
        useUTC={useUTC}
        metricPrefixes={this.prefixes}
      />
    );

    const reconChartWithMenu = (
      <Box flex={1} onContextMenu={this.handleContextMenu}>
        {reconChart}
      </Box>
    );

    const legend = this.showLegend ? (
      <MetricsResultLegend
        hideTotalRow={hideTotalRow}
        rows={rows}
        isWidget={isWidget}
        isRowSelected={this.isRowSelected}
        columns={this.getLegendColumns()}
        isExport={isExport || $app.isExport}
        {...legendProps}
      />
    ) : null;

    const paneProps = {
      defaultSize: 250,
      minSize: 150,
      pane2Style: {
        display: 'flex'
      },
      split: useHorizontalLayout ? 'vertical' : 'horizontal',
      ...splitPaneProps,
      onDragFinished: onSplitPaneResize
    };

    if (isExport || $app.isExport) {
      return (
        <Box width="100%" height={this.showLegend ? 'auto' : '100%'} className="nms-result">
          <Flex alignItems="center" gap={1} mb={1} p="8px 12px">
            <MetricsExplorerDateRange query={query} />
            <Box width="1px" height="100%" borderRight="thin" />
            <Text muted small>
              {this.measurement}
            </Text>
          </Flex>
          {this.showChart && (
            <Box width="100%" height={this.showLegend ? chartHeight : '100%'}>
              {reconChart}
            </Box>
          )}
          {legend}
        </Box>
      );
    }

    if (useHorizontalLayout) {
      return (
        <SizeMe monitorWidth monitorHeight noPlaceholder>
          {({ size }) => {
            // For horizontal orientation, when the size is small, we want to
            // - disable the SplitPane
            // - hide the chart
            const showChart = this.showChart && size.width > 550;
            const showSplitPane = showChart && enableSplitPane && showLegend;
            const HorizontalWrapper = showSplitPane ? SplitPane : Flex;
            const wrapperProps = showSplitPane ? paneProps : { flex: 1, height: '100%' };
            const chartWrapperProps = showSplitPane ? { mt: '6px', mx: '-4px', width: 'calc(100% + 14px)' } : {};

            return (
              <Flex height="100%" alignItems="flex-start">
                <HorizontalWrapper {...wrapperProps}>
                  {showChart && (
                    <Flex flex={1} height="100%" {...chartWrapperProps}>
                      {reconChartWithMenu}
                    </Flex>
                  )}
                  {this.showLegend && (
                    <Flex flex={1} height="100%" overflow="hidden">
                      {legend}
                    </Flex>
                  )}
                </HorizontalWrapper>
              </Flex>
            );
          }}
        </SizeMe>
      );
    }

    const showSplitPane = this.showChart && enableSplitPane && this.showLegend;
    const Wrapper = showSplitPane ? SplitPane : Flex;
    const wrapperProps = showSplitPane
      ? paneProps
      : { flex: 1, width: containerWidth || '100%', overflow: 'hidden', flexDirection: 'column' };

    return (
      <Flex
        flex={1}
        position="relative"
        height={containerHeight || '100%'}
        width={containerWidth || '100%'}
        overflow="auto"
      >
        <Wrapper {...wrapperProps}>
          {showTools && !showSplitPane && this.getQueryTools()}
          {this.showChart && reconChartWithMenu}
          {legend}
        </Wrapper>
      </Flex>
    );
  }
}
