import React, { Component } from 'react';

import { Button, Flex, Popover } from 'core/components';

class ModalSelectTitle extends Component {
  state = {
    isOpen: false
  };

  onToggle = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { title, helpContents } = this.props;
    const { isOpen } = this.state;
    return (
      <Flex alignItems="center">
        {title}
        {helpContents && (
          <Popover
            placement="bottom-start"
            rootElement="div"
            popoverClassName="pt-minimal"
            content={helpContents}
            onClose={this.onClose}
          >
            <Button icon="help" intent="primary" ml="4px" active={isOpen} onClick={this.onToggle} minimal />
          </Popover>
        )}
      </Flex>
    );
  }
}

export default ModalSelectTitle;
