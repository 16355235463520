/**
 * This is the default subset of tab definitions, this will be used by DetailTabs if no other whitelist is provided.
 * @type {string[]}
 */
export const defaultTabWhitelist = [
  'ips',
  'sites',
  'devices',
  'interfaces',
  'asns',
  'applications',
  'bgp',
  'src_bgp_aspath',
  'countries',
  'regions',
  'cities',
  'providers',
  'i_src_connect_type_name',
  'i_src_network_bndry_name',
  'sampledpktsize',
  'Proto',
  'inet_family',
  'service',
  'nexthop',
  'prefix/len',
  'i_trf_profile',
  'az_region',
  'aws_region',
  'gce_region'
];

export const azureTabIds = ['az_region', 'az_inst_name', 'az_zone', 'az_fqdn', 'az_vnet'];
export const gcpTabIds = ['gce_region', 'gce_proj_id', 'gce_vm_name', 'gce_zone', 'gce_vpc_snn'];
export const awsTabIds = [
  'aws_region',
  'aws_vm_name',
  'aws_zone',
  'aws_zone_id',
  'aws_sg',
  'aws_pub_dns',
  'aws_priv_dns',
  'aws_vpc_id'
];

export const cloudFlowTabIds = [
  'ips',
  'sites',
  'devices',
  'interfaces',
  'asns',
  'applications',
  'bgp',
  'src_bgp_aspath',
  'countries',
  'regions',
  'cities',
  'sampledpktsize',
  'Proto',
  'inet_family',
  'service',
  'nexthop',
  'prefix/len',
  'i_trf_profile'
];

export const gcpTabIdPrefixes = ['gcp_', 'gce_'];
export const azureTabIdPrefixes = ['az_', 'azure_'];
export const awsTabIdPrefixes = ['aws_'];
export const cloudTabIdPrefixes = [...gcpTabIdPrefixes, ...azureTabIdPrefixes, ...awsTabIdPrefixes];

/**
 * Helper for removing items from defaultWhitelist by tabId, prefix or strictEquals
 * @param removalPrefixes tabIds (or tabId prefixes) to blacklist.
 * @param strictEqual [Default: false] If true, entries in removals must match exactly before they are blacklisted, otherwise they are treated as tabId prefixes
 * @returns {unknown[]} DefaultWhitelist blacklisted against params.
 */
export function blacklistWhitelistByTabId(removals, strictEqual = false) {
  const results = strictEqual
    ? defaultTabWhitelist.filter((tabId) => !removals.includes(tabId))
    : defaultTabWhitelist.filter((tabId) => !removals.some((rp) => tabId.startsWith(rp)));
  return results;
}

export function getCloudTabIdsByProvider(provider) {
  switch (provider) {
    case 'azure':
      return azureTabIds;
    case 'aws':
      return awsTabIds;
    case 'gcp':
      return gcpTabIds;
    default:
      return [];
  }
}

/**
 * Return defaultTabWhitelist with ONLY the specified cloud provider's applicable cloud tabs
 * @param provider
 * @returns {*[]}
 */
export function getDefaultTabWhitelistCloudProviderAdjusted(provider) {
  return [...cloudFlowTabIds, ...getCloudTabIdsByProvider(provider)];
}
