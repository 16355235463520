import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Icon } from 'core/components';
import { formatMetricsValueForDisplay } from 'app/views/metrics/utils/formatMetricValues';

/** Returns the display value of a metric for an NMS alert */
const NmsNativeAlertMetricValue = ({ metric, value, measurement, measurementModel, alertModel = {}, ...props }) => {
  if (!metric || !measurement || !measurementModel) {
    return null;
  }

  const stateChangePreviousStatus = alertModel?.nmsAlarmContext?.stateChangePreviousStatus;

  const currentMetricValue = formatMetricsValueForDisplay(value, { metric, measurementModel, measurement }, true);

  // If metric is part of a state change condition
  const rawPreviousValue = stateChangePreviousStatus?.[measurement]?.[metric];
  const previousMetricValue =
    rawPreviousValue && rawPreviousValue !== value
      ? formatMetricsValueForDisplay(rawPreviousValue, { metric, measurementModel, measurement }, true)
      : null;

  const displayValue = previousMetricValue ? (
    <Flex alignItems="center" gap={1} {...props}>
      {previousMetricValue} <Icon icon="arrow-right" aria-label="transitioned to state" /> {currentMetricValue}
    </Flex>
  ) : (
    currentMetricValue
  );

  return displayValue;
};

NmsNativeAlertMetricValue.propTypes = {
  // Metric key
  metric: PropTypes.string.isRequired,
  // Raw metric value
  value: PropTypes.oneOfType([(PropTypes.string, PropTypes.number)]).isRequired,
  measurement: PropTypes.string.isRequired,
  measurementModel: PropTypes.object.isRequired,
  // This must be an AlertModel instance
  alertModel: PropTypes.object
};

NmsNativeAlertMetricValue.defaultProps = {
  alertModel: {}
};

export default NmsNativeAlertMetricValue;
