import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Card } from 'core/components';
import VerticalDetailTabs from 'app/components/detailTabs/VerticalDetailTabs';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import Page from 'app/components/page/Page';

import AggregateViewHeader from '../aggregate/AggregateViewHeader';
import parentLinks from '../NetworkExplorerParentLink';

@inject('$tabs')
@observer
class RoutePrefixDetails extends Component {
  constructor(props) {
    super(props);

    const { $tabs } = props;
    const tabs = $tabs.getTabs();

    this.state.tabBlacklist = [tabs.PREFIX_LEN.id];
  }

  state = {
    filters: [],
    lookback_seconds: 86400,
    aggregateType: 'p95th_bits_per_sec'
  };

  get routePrefix() {
    const { match } = this.props;
    const { prefix, len } = match.params;
    return { prefix, len };
  }

  get filters() {
    const { filters } = this.state;
    const { prefix, len } = this.routePrefix;

    const prefixFilter = {
      filterField: 'route_prefix',
      filterValue: prefix
    };

    const lengthFilter = {
      filterField: 'route_prefix_len',
      filterValue: len
    };

    return filters.concat(prefixFilter, lengthFilter);
  }

  handleFiltersApply = (filters) => {
    this.setState({ filters });
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds });
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType });
  };

  render() {
    const { aggregateType, filters, lookback_seconds, tabBlacklist } = this.state;
    const { prefix, len } = this.routePrefix;

    return (
      <Page
        title={`${prefix}/${len}`}
        parentLinks={[...parentLinks, { link: '/v4/core/quick-views/route-prefix', label: 'Route Prefixes' }]}
        scrolls
        showExport
      >
        <AggregateViewHeader
          title={`${prefix}/${len}`}
          aggregateType={aggregateType}
          filters={filters}
          lookbackSeconds={lookback_seconds}
          onFiltersChange={this.handleFiltersApply}
          onAggregateChange={this.handleAggregationChange}
          onMetricsChange={this.handleAggregationChange}
          onTimeRangeChange={this.handleLookbackChange}
        />

        <Card mb={2}>
          <TabbedChart overrides={{ lookback_seconds, aggregateTypes: [aggregateType] }} simpleFilters={this.filters} />
        </Card>

        <VerticalDetailTabs
          blacklist={tabBlacklist}
          simpleFilters={this.filters}
          queryOverrides={{ lookback_seconds, aggregateTypes: [aggregateType] }}
        />
      </Page>
    );
  }
}

export default RoutePrefixDetails;
