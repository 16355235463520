import React from 'react';
import { Classes } from '@blueprintjs/core';
import { Box, Flex } from 'core/components';
import ParametricTag from 'app/components/ParametricTag';
import ShareLevelBadge from 'app/components/ShareLevelBadge';

const DashLink = ({ dashboard, ...boxProps }) => (
  <Flex justify="space-between" style={{ whiteSpace: 'nowrap' }} {...boxProps}>
    <Box mr="4px" className={Classes.TEXT_OVERFLOW_ELLIPSIS}>
      <a href={dashboard.navigatePath}>{dashboard.get('dash_title')}</a>
    </Box>
    <Flex flex={1} justifyContent="flex-end">
      {dashboard.get('parametric') && <ParametricTag />}
      <ShareLevelBadge showIcon={false} item={dashboard} />
    </Flex>
  </Flex>
);

export default DashLink;
