import lazyLoad from 'core/util/lazyLoad';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex } from 'core/components';

const OnboardingWarningComponent = lazyLoad(() => import('./OnboardingWarnings'), { fallback: null });

@inject('$auth', '$devices', '$kentikAgents')
@observer
export class OnboardingWarning extends Component {
  state = {
    loading: true,
    flowStatus: false,
    downAgents: null
  };

  componentWillUnmount() {
    if (this.request) {
      this.request.cancel();
    }
  }

  tryFetchData() {
    const { $devices, $kentikAgents, $auth } = this.props;

    const { loading } = this.state;
    if (this.request || !loading) {
      // Don't fetch again if already fetched or currently fetching
      return;
    }

    const fetchDownAgents = $auth.hasPermission('recon.enabled') ? $kentikAgents.fetchDownAgents() : Promise.resolve();

    this.request = makeCancelable(
      Promise.all([$devices.incompleteDevices.fetch(), $devices.deviceWarnings.fetch(), fetchDownAgents])
    );

    this.request.promise
      .then(([, flowStatus, downAgents]) => {
        this.request = null;
        this.setState({ flowStatus, downAgents, loading: false });
      })
      .catch((err) => {
        if (!(err instanceof CanceledError)) {
          this.setState({ loading: false });
        }
      });
  }

  getStyling = (style = {}, small = false) => (small ? { gap: 1, ...style } : { gap: 2, mb: 2, ...style });

  render() {
    const { $auth, small, showDeviceWarning, showICWarning, style } = this.props;
    const { flowStatus, loading, downAgents } = this.state;

    // Don't bother spamming API requests if we're not going to show anything
    if ($auth.isAdministrator) {
      this.tryFetchData();
    }

    // Don't show anything to non-admin or while loading (no loading spinner)
    return !$auth.isAdministrator || loading ? null : (
      <Flex flexDirection="column" {...this.getStyling(style, small)}>
        <OnboardingWarningComponent
          flowStatus={flowStatus}
          small={small}
          showDeviceWarning={showDeviceWarning}
          showICWarning={showICWarning}
          downAgents={downAgents}
        />
      </Flex>
    );
  }
}
