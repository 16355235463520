import React, { Component } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { Classes } from '@blueprintjs/core';

import { Dialog } from 'core/components';

import ModalSelectTitle from './ModalSelectTitle';
import ModalValuesPanel from './ModalValuesPanel';
import SelectState from './SelectState';
import ModalSelectFooter from './ModalSelectFooter';

const ModalSelectDialog = styled(Dialog)`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 100px);
  max-width: 1200px;
  min-width: 180px;
  top: 50px;
  width: calc(100vw - 100px);

  .pt-menu-item {
    overflow: visible;
    padding: 4px 6px;
    white-space: normal;

    &.pt-menu-item-group-title {
      align-items: center;
      border-radius: 2px 2px 0 0;
      display: flex;
      height: 30px;
      padding: 8px 12px;
    }
  }

  .modal-select-tab {
    background-color: ${themeGet('colors.sidebarBackground')};
    max-height: calc(100vh - 300px);
    min-height: calc(100vh - 300px);
  }

  .${Classes.TAB_LIST} [aria-selected='true'] {
    box-shadow: inset 0 4px 0 ${themeGet('colors.primary')};
  }
`;

class AbstractSelect extends Component {
  constructor(props, context) {
    super(props, context);

    this.selectState = new SelectState(props);
  }

  componentDidUpdate(prevProps) {
    const { isEditing } = this.props;
    if (!prevProps.isEditing && isEditing) {
      this.selectState.initLastOpenedValue();
      this.selectState.setFilterValue('');
    }
  }

  renderDialog() {
    const { isEditing, dialogStyle, canEscapeKeyClose, title, helpContents } = this.props;
    const { closeOptions } = this.selectState;

    return (
      <ModalSelectDialog
        title={<ModalSelectTitle title={title || 'Select Values'} helpContents={helpContents} />}
        canEscapeKeyClose={canEscapeKeyClose}
        isOpen={isEditing}
        onClose={closeOptions}
        style={dialogStyle}
      >
        {this.renderDialogBody()}
        {this.renderDialogFooter()}
      </ModalSelectDialog>
    );
  }

  renderDialogFooter() {
    return <ModalSelectFooter {...this.props} selectState={this.selectState} />;
  }

  swallowEvent(e) {
    e.stopPropagation();
  }

  render() {
    const { multi, dialogOnly, selectClassName, style } = this.props;

    const dialog = this.renderDialog();

    if (dialogOnly) {
      return dialog;
    }

    return (
      <div className={classNames('modal-select-input', selectClassName, { multi })} style={style}>
        <div onClick={this.swallowEvent}>{dialog}</div>
        <ModalValuesPanel {...this.props} selectState={this.selectState} />
      </div>
    );
  }
}

export default AbstractSelect;
