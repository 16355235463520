import React from 'react';
import styled from 'styled-components';
import { space, width, fontWeight, fontSize, color, borders } from 'styled-system';
import { MenuItem as BlueprintMenuItem, Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import Icon from './Icon';

const StyledMenuItem = styled(BlueprintMenuItem)`
  ${space};
  ${width};
  ${fontSize};
  ${fontWeight};
  ${color};
  ${borders};

  /* tone down the padding a bit to compress the items */
  &.${Classes.MENU_ITEM} {
    padding: 4px 7px;
  }

  &.${Classes.ACTIVE} {
    .${Classes.TAG}.${Classes.MINIMAL} {
      /* this is intended in both dark/light mode */
      background-color: #ffffff;
      color: ${(props) => props.theme.colors.blackText};
    }
  }

  &.${Classes.SMALL} {
    .${Classes.ICON} > svg {
      width: 12px;
      height: 12px;
    }
  }

  .pre-wrap {
    white-space: pre-wrap;
  }
`;

const MenuItem = (props) => {
  const {
    staticContext,
    alignItems,
    lineHeight,
    textTransform,
    borderRadius,
    icon,
    iconColor,
    minWidth,
    small,
    className,
    monospace,
    active,
    style = {},
    ...rest
  } = props;

  return (
    <StyledMenuItem
      {...rest}
      className={classNames(className, {
        [Classes.SMALL]: small,
        [Classes.ACTIVE]: active,
        [Classes.MONOSPACE_TEXT]: monospace
      })}
      style={{ alignItems, borderRadius, lineHeight, textTransform, minWidth, ...style }}
      icon={icon ? <Icon icon={icon} color={iconColor} iconSize={16} /> : undefined}
    />
  );
};

export default MenuItem;
