import React from 'react';
import { observer } from 'mobx-react';
import AgentMesh from 'app/views/synthetics/components/mesh/AgentMesh';
import LoadingPanel from './LoadingPanel';

@observer
export default class MeshPanel extends React.Component {
  render() {
    const { test } = this.props;
    const {
      healthTimeline,
      mesh,
      resultTimeMs,
      testResultsTaskCount,
      lookbackSeconds,
      startDate,
      endDate,
      hasResults,
      loading,
      displayOptions
    } = test.results;

    return (
      <LoadingPanel
        test={test}
        testResults={test.results}
        loading={loading && !hasResults}
        empty={healthTimeline.length === 0 || !hasResults}
      >
        <AgentMesh
          test={test}
          data={mesh}
          resultTimeMs={resultTimeMs}
          lookbackSeconds={lookbackSeconds}
          startDate={startDate}
          endDate={endDate}
          test_id={test.id}
          meshCount={testResultsTaskCount}
          allowAgentDetails={displayOptions.allowAgentDetails}
          mt={1}
          mb={2}
          centered={false}
        />
      </LoadingPanel>
    );
  }
}
