import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';
import { Hotkeys, Hotkey, HotkeysTarget } from '@blueprintjs/core';

import {
  Box,
  Button,
  ButtonLink,
  Checkbox,
  Flex,
  Icon,
  Select,
  Spinner,
  Suspense,
  Text,
  ToggleButtonGroup
} from 'core/components';
import { InputGroup } from 'core/form';
import { ESC } from 'core/util/keyCodes';

import { PiClockCounterClockwiseBold } from 'react-icons/pi';
import { TbBaselineDensityMedium, TbBaselineDensitySmall } from 'react-icons/tb';

import LabelOptionRenderer from 'app/components/labels/LabelOptionRenderer';

import ViewBox from './ViewBox';

function optionRenderer(opts) {
  const { description, icon, key, label, value, selected, selectItem, user_email, user_full_name } = opts;
  return (
    <Box mx={1} pt={1} key={key || value}>
      <Checkbox
        checked={selected}
        onChange={() => selectItem(value)}
        key={key || value}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Flex alignItems="center" display="inline-flex" gap={1} mt="-4px" p="4px">
          {icon && <Icon icon={icon} />}
          <Flex flexDirection="column">
            <Text small>{user_full_name || label}</Text>
            <Text small color="muted">
              {description || user_email}
            </Text>
          </Flex>
        </Flex>
      </Checkbox>
    </Box>
  );
}

@inject('$labels', '$recentlyViewed', '$app', '$users')
@withRouter
@HotkeysTarget
@observer
export default class ViewSidebar extends Component {
  state = {
    density: 'compact',
    expanded: {},
    label: [],
    loading: true,
    owner: [],
    privacy: [],
    search: '',
    selectedItem: false,
    showingType: 'favorites',
    sort: 'relevance'
  };

  favorites = [];

  recents = [];

  componentDidMount() {
    const { $users, $app, $recentlyViewed, collection, name } = this.props;
    this.selectedItemRef = React.createRef();

    if (!$app.isSubtenant) {
      $users.collection.fetch();
    }
    collection.fetch().then(() => {
      this.favorites = collection.favorites;
      this.recents = $recentlyViewed.collection
        .get()
        .filter((view) => view.get('view_type') === name)
        .map((view) => collection.get(view.get('view_id')))
        .filter(Boolean);

      this.sort('relevance');
      this.setState({ loading: false, showingType: this.favorites.length ? 'favorites' : 'all' });
    });
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderHotkeys() {
    const { name, toLink } = this.props;
    const group = `${name}s Drawer`;
    return (
      <Hotkeys>
        <Hotkey
          allowInInput
          preventDefault
          global
          group={group}
          combo="left"
          label="Switch tabs left"
          onKeyDown={() => {
            const { showingType } = this.state;
            if (showingType !== 'favorites') {
              this.setState({ showingType: showingType === 'recents' ? 'favorites' : 'recents', selectedItem: false });
            }
          }}
        />
        <Hotkey
          allowInInput
          preventDefault
          global
          group={group}
          combo="right"
          label="Switch tabs right"
          onKeyDown={() => {
            const { showingType } = this.state;
            if (showingType !== 'all') {
              this.setState({ showingType: showingType === 'recents' ? 'all' : 'recents', selectedItem: false });
            }
          }}
        />
        <Hotkey
          allowInInput
          preventDefault
          global
          group={group}
          combo="up"
          label="Select results up"
          onKeyDown={() => {
            const { collection } = this.props;
            const { selectedItem, showingType } = this.state;
            let items = showingType === 'all' ? collection.models : this.favorites;
            items = showingType === 'recents' ? this.recents : items;
            if (items?.length) {
              const idx = selectedItem ? items.findIndex((item) => item.id === selectedItem.id) : false;
              if (Number.isFinite(idx) && idx > 0) {
                this.setState({ selectedItem: items[idx - 1] }, () => {
                  /* eslint-disable no-unused-expressions */
                  this.selectedItemRef?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest' });
                });
              }
            }
          }}
        />
        <Hotkey
          allowInInput
          preventDefault
          global
          group={group}
          combo="down"
          label="Select results down"
          onKeyDown={() => {
            const { collection } = this.props;
            const { selectedItem, showingType } = this.state;
            let items = showingType === 'all' ? collection.models : this.favorites;
            items = showingType === 'recents' ? this.recents : items;
            if (items?.length) {
              const idx = selectedItem ? items.findIndex((item) => item.id === selectedItem.id) : -1;
              if (Number.isFinite(idx) && idx < items.length - 1) {
                this.setState({ selectedItem: items[idx + 1] }, () => {
                  /* eslint-disable no-unused-expressions */
                  this.selectedItemRef?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest' });
                });
              }
            }
          }}
        />
        <Hotkey
          allowInInput
          preventDefault
          global
          group={group}
          combo="enter"
          label="Open selected result"
          onKeyDown={() => {
            const { history } = this.props;
            const { selectedItem } = this.state;
            if (selectedItem) {
              history.push(toLink(selectedItem.id));
            }
          }}
        />
      </Hotkeys>
    );
  }

  handleDensityChange = (density) => {
    const { collection } = this.props;
    const { expanded } = this.state;
    collection.unfiltered.forEach((item) => {
      expanded[`all||${item.id}`] = density === 'expanded';
      expanded[`favorites||${item.id}`] = density === 'expanded';
      expanded[`recents||${item.id}`] = density === 'expanded';
    });
    this.setState({ density, expanded });
  };

  handleFilterChange = (filter, value) => {
    const { fieldMap } = this.props;
    const { title } = fieldMap;
    this.setState(
      { [filter]: value },
      debounce(() => {
        const { $labels, collection } = this.props;
        const { label, owner, privacy, search, sort, showingType } = this.state;
        const discreteFilters = [];

        if (label.length) {
          discreteFilters.push({
            type: 'label',
            fn: (model) =>
              $labels.labelMultiSelectModelFilter(model.labels, label) ||
              (label.includes('[NONE]') && model.labels.length === 0)
          });
        }
        if (owner.length) {
          discreteFilters.push({ type: 'owner', fn: (model) => owner.includes(`${model.get('user_id')}`) });
        }
        if (privacy.length) {
          discreteFilters.push({ type: 'privacy', fn: (model) => privacy.includes(`${model.shareLevel}`) });
        }
        if (search.length) {
          discreteFilters.push({
            type: 'search',
            fn: (model) => model.get(title).toLowerCase().includes(search.toLowerCase())
          });
        }

        collection.setDiscreteFilters(discreteFilters);
        collection.filter();

        this.sort(sort);

        if (discreteFilters.length) {
          this.setState({ showingType: 'all', selectedItem: false });
        } else {
          const defaultShowingType = this.favorites.length ? 'favorites' : 'all';
          this.setState({ showingType: showingType === 'all' ? defaultShowingType : showingType, selectedItem: false });
        }
      }, 100)
    );
  };

  handleClear = () => {
    const { collection } = this.props;
    this.setState(
      {
        label: [],
        owner: [],
        privacy: [],
        search: '',
        selectedItem: false,
        showingType: this.favorites.length ? 'favorites' : 'all',
        sort: 'relevance'
      },
      () => {
        collection.clearFilters();
        this.sort('relevance');
      }
    );
  };

  handleSortChange = (sort) => {
    this.setState({ sort }, () => {
      this.sort(sort);
    });
  };

  sort = (sortBy) => {
    const { collection, fieldMap } = this.props;
    const { title } = fieldMap;
    if (sortBy === 'relevance') {
      collection.sort(title, 'asc');
      this.favorites = collection.favorites.sort((a, b) => (a.get(title) < b.get(title) ? -1 : 1));
      this.recents.sort((a, b) => (new Date(a.lastViewedDate) > new Date(b.lastViewedDate) ? -1 : 1));
    } else if (sortBy === title) {
      collection.sort(title, 'asc');
      this.favorites = collection.favorites.sort((a, b) => (a.get(sortBy) < b.get(sortBy) ? -1 : 1));
      this.recents.sort((a, b) => (a.get(title) < b.get(title) ? -1 : 1));
    } else {
      collection.sort(sortBy, 'desc');
      this.favorites = collection.favorites.sort((a, b) => (a.get(sortBy) < b.get(sortBy) ? 1 : -1));
      this.recents.sort((a, b) => (a.get(sortBy) < b.get(sortBy) ? 1 : -1));
    }
  };

  toggleExpand(id) {
    const { expanded } = this.state;
    this.setState({ expanded: { ...expanded, [id]: !expanded[id] } });
  }

  onFavorite = () => {
    const { collection } = this.props;
    const { sort } = this.state;
    this.favorites = collection.favorites;
    this.sort(sort);
  };

  onKeyUp = (e) => {
    const { onClose } = this.props;
    const { label, owner, privacy, search } = this.state;
    const key = e.keyCode ? e.keyCode : e.which;
    const isFilter = label.length || owner.length || privacy.length || search.length;

    if (key === ESC) {
      if (isFilter) {
        this.handleClear();
      } else if (onClose) {
        onClose();
      }
    }
  };

  render() {
    const { collection, fieldMap, labelOptions, name, ownerOptions, privacyOptions, toLink, $app } = this.props;
    const { density, expanded, label, loading, owner, privacy, search, selectedItem, showingType, sort } = this.state;
    const { created_at, updated_at, title } = fieldMap;
    let items = showingType === 'all' ? collection.models : this.favorites;
    items = showingType === 'recents' ? this.recents : items;
    const hasFilters = label.length || owner.length || privacy.length || search.length;

    return (
      <Flex flexDirection="column" height="100%">
        <Suspense
          fallback={
            <Flex flex={1} justifyContent="center">
              <Spinner />
            </Flex>
          }
          loading={loading}
        >
          <Flex flexDirection="column" gap={2} mt="-1px" p={2} width="600px" height="95%" overflow="hidden">
            <InputGroup
              autoFocus
              clearable
              fill
              large
              leftIcon="search"
              onChange={(e) => this.handleFilterChange('search', e.target?.value || '')}
              onKeyUp={this.onKeyUp}
              placeholder={`Search ${name.toLowerCase()}s`}
              value={search}
            />
            <Flex gap={1}>
              <Flex>
                <Select
                  buttonStyle={{ icon: 'tag' }}
                  keepOpen
                  menuWidth={200}
                  multi
                  onChange={(val) => this.handleFilterChange('label', val)}
                  optionRenderer={LabelOptionRenderer}
                  options={labelOptions}
                  placeholder="Label"
                  renderAsButton
                  showCount
                  showFilter
                  small
                  toggle
                  values={label}
                  width={115}
                />
              </Flex>
              <Flex>
                <Select
                  buttonStyle={{ icon: 'lock' }}
                  keepOpen
                  menuWidth={300}
                  multi
                  onChange={(val) => this.handleFilterChange('privacy', val)}
                  optionRenderer={optionRenderer}
                  options={privacyOptions}
                  placeholder="Privacy"
                  renderAsButton
                  showCount
                  small
                  toggle
                  values={privacy}
                  width={115}
                />
              </Flex>
              {!$app.isSubtenant && (
                <Flex>
                  <Select
                    buttonStyle={{ icon: 'person' }}
                    keepOpen
                    menuWidth={250}
                    multi
                    onChange={(val) => this.handleFilterChange('owner', val)}
                    optionRenderer={optionRenderer}
                    options={ownerOptions}
                    placeholder="Owner"
                    renderAsButton
                    showCount
                    showFilter
                    small
                    toggle
                    values={owner}
                    width={115}
                  />
                </Flex>
              )}
              {!!hasFilters && (
                <ButtonLink key="clear" intent="primary" onClick={() => this.handleClear()} minimal small>
                  Clear all
                </ButtonLink>
              )}
              <Flex ml="auto">
                <Select
                  buttonStyle={{ icon: 'sort' }}
                  menuWidth={125}
                  onChange={this.handleSortChange}
                  options={[
                    { label: 'Relevance', value: 'relevance' },
                    { label: 'Name', value: title },
                    { label: 'Created Date', value: created_at },
                    { label: 'Last Updated', value: updated_at }
                  ]}
                  small
                  values={sort}
                />
              </Flex>
            </Flex>
            <Flex alignItems="center" gap={2}>
              <ToggleButtonGroup selectedValue={showingType}>
                <Button
                  borderRadius="15px"
                  icon="star"
                  minimal
                  style={{ marginRight: '12px' }}
                  onClick={() => this.setState({ showingType: 'favorites' })}
                  value="favorites"
                >
                  Favorites ({this.favorites.length})
                </Button>
                <Button
                  borderRadius="15px"
                  icon={PiClockCounterClockwiseBold}
                  minimal
                  style={{ marginRight: '12px' }}
                  onClick={() => this.setState({ showingType: 'recents' })}
                  value="recents"
                >
                  Recents ({this.recents.length})
                </Button>
                <Button
                  borderRadius="15px"
                  icon="search"
                  minimal
                  onClick={() => this.setState({ showingType: 'all' })}
                  value="all"
                >
                  Browser ({collection.models.length})
                </Button>
              </ToggleButtonGroup>
              <Flex ml="auto">
                <Select
                  buttonStyle={{ icon: density === 'compact' ? TbBaselineDensitySmall : TbBaselineDensityMedium }}
                  menuWidth={140}
                  minimal
                  onChange={this.handleDensityChange}
                  options={[
                    { icon: TbBaselineDensitySmall, label: 'Compact', value: 'compact' },
                    { icon: TbBaselineDensityMedium, label: 'Expanded', value: 'expanded' }
                  ]}
                  placeholder=" "
                  small
                  values={density}
                  width={48}
                />
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              gap={density === 'compact' ? 0 : 2}
              overflowY="auto"
              height="100%"
              pt="1px"
              style={{ scrollbarGutter: 'stable' }}
            >
              {items.map((item, idx) => {
                const id = `${showingType}||${item.id}`;
                const borderRadius = `${idx === 0 ? '4px 4px' : '0 0'} ${idx === items.length - 1 ? '4px 4px' : '0 0'}`;
                const isSelectedItem = selectedItem?.id === item.id;
                return (
                  <div ref={isSelectedItem ? this.selectedItemRef : null} key={id}>
                    <ViewBox
                      expanded={expanded[id]}
                      fieldMap={fieldMap}
                      highlighted={isSelectedItem}
                      item={item}
                      key={id}
                      onExpand={() => this.toggleExpand(id)}
                      onFavorite={this.onFavorite}
                      style={{ borderRadius }}
                      toLink={toLink}
                    />
                  </div>
                );
              })}
              {items.length === 0 && (
                <Flex alignItems="center" justifyContent="center" minHeight="100px">
                  <Text muted>
                    {showingType === 'recents'
                      ? `Recently viewed ${name.toLowerCase()}s will appear here.`
                      : `Favorite ${name.toLowerCase()}s will appear here.`}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Suspense>

        <Flex mt="auto" p={2} gap={3}>
          <Flex gap={1} alignItems="center">
            <Text muted small>
              Close search
            </Text>
            <kbd className="bp4-key">esc</kbd>
          </Flex>
          <Flex gap={1} alignItems="center">
            <Text muted small>
              Switch tabs
            </Text>
            <kbd className="bp4-key">←</kbd>
            <kbd className="bp4-key">→</kbd>
          </Flex>
          <Flex gap={1} alignItems="center">
            <Text muted small>
              Select results
            </Text>
            <kbd className="bp4-key">↑</kbd>
            <kbd className="bp4-key">↓</kbd>
          </Flex>
        </Flex>
      </Flex>
    );
  }
}
