import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { BiFolder } from 'react-icons/bi';
import { FaServer } from 'react-icons/fa6';
import { FiActivity, FiChevronRight, FiGlobe, FiHome, FiShield, FiUsers } from 'react-icons/fi';
import { GiRadarSweep } from 'react-icons/gi';
import { IoCloudCircleSharp } from 'react-icons/io5';
import { ReactComponent as OfficeIcon } from 'app/assets/icons/office.svg';

import { Box, Flex, Icon, Link } from 'core/components';

import MenuHeaderWithIcon from './MenuHeaderWithIcon';
import ExportMenu from './ExportMenu';
import QuickViewSelector from './QuickViewSelector';

export const parentSections = {
  admin: { title: 'Admin', icon: OfficeIcon, to: '/v4/admin' },
  alerting: { title: 'Alerting', icon: <Icon mr={1} icon="shield" />, to: '/v4/alerting' },
  core: { title: 'Core', icon: FiActivity },
  cloud: { title: 'Cloud', icon: IoCloudCircleSharp, to: '/v4/cloud' },
  edge: { title: 'Edge', icon: FiGlobe },
  home: { title: 'Home', icon: FiHome },
  infrastructure: { title: 'Infrastructure', icon: FaServer },
  journeys: { title: 'Journeys', icon: 'route' },
  library: { title: 'Library', icon: BiFolder, to: '/v4/library' },
  'kentik-map': { title: 'Kentik Map', icon: 'layout-auto', to: '/v4/kentik-map' },
  mkp: { title: 'My Kentik Portal', icon: FiUsers, to: '/v4/mkp' },
  'my-network': { title: 'My Network', icon: 'folder-close' },
  nms: { title: 'NMS', icon: 'shapes', to: '/v4/nms' },
  protect: { title: 'Protect', icon: FiShield },
  'service-provider': { title: 'Service Provider', icon: 'heatmap' },
  settings: { title: 'Settings', icon: 'cog', to: '/v4/settings' },
  synthetics: { title: 'Synthetics', icon: GiRadarSweep, to: '/v4/synthetics' }
};

export const SUBNAV_HEIGHT = 40;

const SubNavWrapper = styled(Box)`
  position: fixed;
  top: 60px;
  height: ${SUBNAV_HEIGHT}px;
  width: 100vw;
  z-index: 8;
  border-bottom: ${(props) => props.theme.borders.thin};
  background-color: ${(props) => props.theme.colors.subnavBackground};
`;

@withRouter
@inject('$app', '$auth')
@observer
class Subnav extends Component {
  static defaultProps = {
    exportButtonProps: null,
    shareButtonProps: null,
    exportMenuItems: null,
    additionalExportMenuItems: null,
    showExport: null,
    showJumpMenu: false,
    toolPosition: 'start'
  };

  componentDidMount() {
    const { parentLinks, title } = this.props;
    const { section } = this;
    this.setProductArea(section, parentLinks, title);
  }

  componentDidUpdate() {
    const { parentLinks, title } = this.props;
    const { section } = this;
    this.setProductArea(section, parentLinks, title);
  }

  setProductArea(section, parentLinks, title) {
    const { $app } = this.props;

    let prodArea = section ? section.title : '';

    parentLinks.forEach(({ label }) => {
      prodArea += ` > ${label}`;
    });
    prodArea = `${prodArea} > ${title}`;

    $app.productArea = prodArea;
  }

  get section() {
    const { $app, $auth, match } = this.props;

    if ($app.isSubtenant) {
      return {
        title: $auth.hasPermission('subtenancy.advancedMode') ? 'Home' : 'My Kentik Portal',
        icon: FiHome,
        to: '/v4/landing'
      };
    }

    if (match.path.startsWith('/v4/setup/clouds')) {
      return parentSections.settings;
    }

    if (match.path.startsWith('/v4/core/quick-views/devices')) {
      return parentSections.infrastructure;
    }

    if (match.path.startsWith('/v4/core/journeys')) {
      return parentSections.journeys;
    }

    if (match.path === '/v4') {
      return parentSections.home;
    }

    return parentSections[match.path.trim().split('/v4')[1].split('/')[1]];
  }

  render() {
    const {
      parentLinks,
      insightsMenu,
      showJumpMenu,
      showExport,
      exportButtonProps,
      shareButtonProps,
      exportMenuItems,
      additionalExportMenuItems,
      showQuickViewSelector,
      title,
      tools,
      toolsPosition,
      centerElement,
      rightElement,
      bottomElement,
      ...wrapperProps
    } = this.props;
    const { section } = this;

    return (
      <SubNavWrapper {...wrapperProps}>
        <Box pr="15px">
          <Box className="subNavRow">
            <Flex flexDirection="column" height={39}>
              <Flex position="relative" alignItems="center" justifyContent="space-between" px={3} height="100%">
                <Box flex="1">
                  <Flex alignItems="center" flex={1}>
                    <MenuHeaderWithIcon {...section} />
                    {parentLinks.map(({ link, label, state }, idx) => (
                      <Fragment key={label || link || idx}>
                        <Icon icon={FiChevronRight} ml="4px" mr="2px" color="muted" />
                        {link && (
                          <Link to={link} state={state}>
                            {label}
                          </Link>
                        )}
                        {!link && label}
                      </Fragment>
                    ))}
                    {title && (
                      <>
                        <Icon icon={FiChevronRight} ml="4px" mr="2px" color="muted" />
                        <QuickViewSelector fallbackContent={title} />
                      </>
                    )}
                  </Flex>
                </Box>
                <Flex flex={centerElement ? '1' : undefined} justifyContent="flex-end" alignItems="center">
                  {toolsPosition === 'start' && tools}
                  {showExport && (
                    <ExportMenu
                      fileName={title}
                      menuItems={exportMenuItems}
                      additionalExportMenuItems={additionalExportMenuItems}
                      exportOptions={showExport}
                      exportButtonProps={exportButtonProps}
                      shareButtonProps={shareButtonProps}
                    />
                  )}
                  {insightsMenu}
                  {toolsPosition === 'end' && tools}
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </SubNavWrapper>
    );
  }
}

export default Subnav;
