import React from 'react';
import { observer, inject } from 'mobx-react';
import { Flex, FlexColumn } from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import TestTable from 'app/views/synthetics/tests/TestTable';
import TestCollection from 'app/stores/synthetics/TestCollection';
import LoadingSkeleton from './LoadingSkeleton';
import PromptFeedback from './PromptFeedback';

@storeLoader('$syn.tests', '$syn.agents')
@inject('$syn')
@observer
class SynResponse extends React.Component {
  state = { loading: true };

  applyFilters = (tests) => {
    const { query } = this.props;
    const test_type = query.get('query.test_type');
    const health = query.get('query.health');
    const display_name = query.get('query.test_name');
    const labels = query.get('query.tag_label');
    const filters = { test_type, health, display_name, labels };
    Object.entries(filters).forEach(([key, values]) => {
      if (!values) {
        delete filters[key];
      }
    });
    if (Object.keys(filters).length === 0) {
      return tests;
    }
    return tests.filter((test) =>
      Object.entries(filters)
        .map(([key, value]) => {
          if (key === 'labels' && test.labels?.length > 0) {
            const testLabels = test.labels.map((label) => label.get('name'));
            if (value?.some((label) => testLabels.includes(label))) {
              return true;
            }
          }
          let hasMatch = false;
          if (test.get(key)) {
            hasMatch = value.filter((i) => i.toLowerCase().includes(test.get(key).toLowerCase())).some((x) => x);
          }
          if (hasMatch) {
            return true;
          }
          return false;
        })
        .every((result) => result)
    );
  };

  componentDidMount() {
    const { $syn } = this.props;
    // load usage data after call to load test health to handle timing issues with test deserialization after cache clears
    // see https://github.com/kentik/ui-app/issues/20806
    $syn.tests
      .loadTestHealth()
      .then(() => $syn.plan.loadUsageData())
      .then(() => {
        const collection = new TestCollection(this.applyFilters($syn.tests.original));
        this.setState({ loading: false, collection });
      });
  }

  render() {
    const { query, promptHeight, onDelete } = this.props;
    const { loading, collection } = this.state;
    if (loading) {
      return <LoadingSkeleton />;
    }
    return (
      <FlexColumn flex={1} position="relative" overflow="hidden">
        <Flex height={promptHeight} mb={1}>
          <TestTable testCollection={collection} showSelectTest={false} />
        </Flex>
        <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between">
          <PromptFeedback model={query} onDelete={onDelete} />
        </Flex>
      </FlexColumn>
    );
  }
}

export default SynResponse;
