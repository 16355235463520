import { baseFields } from './baseFields';
import { pingFields } from './pingFields';
import { flowBaseFields } from './flowBaseFields';

const regionOptions = {
  name: 'Region Test'
};

const regionFields = {
  ...baseFields,
  ...pingFields,
  ...flowBaseFields
};

export { regionOptions, regionFields };
