import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Box, Callout, Flex, Heading, Spinner } from 'core/components';

@observer
export default class Sidebar extends Component {
  renderBody() {
    const { sidebar, toolbar, loading, onSubmit, formError, children } = this.props;

    if (formError) {
      return (
        <Box p={2}>
          <Callout intent="danger" icon="error">
            <Heading level={6}>A fatal error occurred</Heading>
            Kentik has been notified of this error, but feel free to report an issue if the problem persists. You will
            need to refresh the page to continue.
          </Callout>
        </Box>
      );
    }

    if (loading) {
      return (
        <Flex alignItems="center" justifyContent="center" my={4}>
          <Spinner />
        </Flex>
      );
    }

    return (
      <div>
        {toolbar &&
          React.cloneElement(toolbar, {
            onSubmit,
            sidebar
          })}
        <div>
          {React.Children.map(children, (child) =>
            child ? React.cloneElement(child, { onSubmit, sidebar }) : undefined
          )}
        </div>
      </div>
    );
  }

  render() {
    const { width } = this.props;

    return (
      <Box width={width} mt="-1px">
        {this.renderBody()}
      </Box>
    );
  }
}
