import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Classes } from '@blueprintjs/core';

import { getFilterCount } from 'core/util/filters';
import { Box, Button, Callout, Flex, Icon, Text } from 'core/components';
import DeviceSelector from 'app/components/device/DeviceSelector';
import FilterOptions from 'app/views/core/explorer/sidebar/FilterOptions';
import SidebarSection from 'app/views/core/explorer/sidebar/SidebarSection';
import TimeOptions from 'app/views/core/explorer/sidebar/TimeOptions';
import { getAllDisabledDimensions } from 'app/util/devices';

import { FiClock } from 'react-icons/fi';
import { formConsumer } from 'core/form';
import ApplyParametricOptions from './ApplyParametricOptions';

const SectionHeader = ({ title }) => (
  <Text as="h6" fontSize={12} mt={0} mb="12px" textTransform="uppercase" muted>
    {title}
  </Text>
);

const TimeOptionsWrapper = styled(Box)`
  .${Classes.FORM_GROUP} {
    padding: 0 4px;
  }
`;

@formConsumer
@inject('$dashboard', '$auth', '$devices', '$dictionary', '$app')
@observer
export default class DashboardSidebar extends Component {
  state = {
    isEditingFilters: false
  };

  handleToggleEditFilters = (isOpen) => {
    this.setState({
      isEditingFilters: isOpen
    });
  };

  handleCancel = () => {
    const { form } = this.props;
    form.reset();
  };

  handleSubmit = () => {
    const { $dashboard } = this.props;
    $dashboard.formState.submit(() => {
      const values = $dashboard.formState.getValues();

      $dashboard.setCompletedInitialParametricSubmit(true);
      $dashboard.dashboard.set(values);
      $dashboard.propagateQuery(values, { forceQueryUpdate: true });
    });
  };

  render() {
    const { $app, $auth, $dashboard, $devices, form } = this.props;
    const { isEditingFilters } = this.state;

    const { dashboard } = $dashboard;
    const device_name = form.getValue('device_name');
    const device_labels = form.getValue('device_labels');
    const device_sites = form.getValue('device_sites');
    const device_types = form.getValue('device_types');
    const all_devices = form.getValue('all_devices');
    const filter_groups = form.getValue('filters.filterGroups');

    const disabledDimensions = getAllDisabledDimensions({
      all_devices,
      device_name,
      device_labels,
      device_sites,
      device_types,
      filter_groups,
      includeAppProtocolDimensions: false
    });

    const filterCount = getFilterCount(filter_groups);

    const selectedDevices = !all_devices
      ? $devices.getUniqueSelectedDevices({ device_types, device_labels, device_sites, device_name })
      : $devices.activeDeviceSummaries;

    const maxDateRange = $app.isSubtenant && $auth.getActiveUserProperty('userGroup.config.max_date_range');

    return (
      <Box width={325}>
        <Flex>
          <Button text="Reset" onClick={this.handleCancel} disabled={!form.dirty} borderRadius={0} fill height={40} />
          <Button
            text="Apply"
            onClick={this.handleSubmit}
            disabled={(!dashboard.isIncompleteParametric && !form.dirty) || !form.valid}
            intent="primary"
            height={40}
            borderRadius={0}
            fill
          />
        </Flex>

        {dashboard && dashboard.isParametric && (
          <Box m={1}>
            <ApplyParametricOptions handleSubmit={this.handleSubmit} dashboard={dashboard}>
              {({ inputField, question }) => (
                <Callout icon={null} intent="primary">
                  <SectionHeader
                    title={
                      <Box>
                        <Icon icon="chat" color="primary" mr={1} iconSize={16} />
                        <Text color="primary">{question}</Text>
                      </Box>
                    }
                  />
                  {inputField}
                </Callout>
              )}
            </ApplyParametricOptions>
          </Box>
        )}
        {dashboard && (
          <>
            <SidebarSection sectionName="dashboard.time" fieldGroup="time" label="Time" icon={FiClock}>
              <TimeOptionsWrapper>
                <TimeOptions maxDateRange={maxDateRange} disabledDimensions={disabledDimensions} />
              </TimeOptionsWrapper>
            </SidebarSection>

            {!$app.isSubtenant && (
              <>
                <SidebarSection
                  fieldGroup="filtering"
                  sectionName="dashboard.filters"
                  label="Filtering"
                  icon="filter"
                  labelTag={filterCount > 0 ? filterCount : 'No Filters'}
                  labelTagIntent={filterCount > 0 ? 'primary' : undefined}
                >
                  <FilterOptions
                    isOpen={isEditingFilters}
                    onClose={() => this.handleToggleEditFilters(false)}
                    disabledDimensions={disabledDimensions}
                    allowNestedFilters
                  />
                  <Button
                    onClick={() => this.handleToggleEditFilters(true)}
                    text="Edit Filters"
                    icon="edit"
                    small
                    mt="12px"
                  />
                </SidebarSection>

                <SidebarSection
                  fieldGroup="devices"
                  label="Data Sources"
                  labelTag={`${selectedDevices.length} of ${$devices.activeDeviceSummaries.length}`}
                  labelTagIntent={selectedDevices.length > 0 ? 'primary' : undefined}
                  sectionName="dashboard.devices"
                >
                  <DeviceSelector />
                </SidebarSection>
              </>
            )}
          </>
        )}
      </Box>
    );
  }
}
