import React from 'react';

import { Icon, Text, Flex } from 'core/components';

import $dashboards from 'app/stores/dashboard/$dashboards';
import $mitigations from 'app/stores/mitigations/$mitigations';
import $savedViews from 'app/stores/savedView/$savedViews';
import $capacity from 'app/stores/capacityPlan/$capacity';
import $cost from 'app/stores/cost/$cost';

function getExportOption(value, label, extension, icon) {
  return {
    value,
    label: (
      <Flex width={170} alignItems="center" justifyContent="space-between">
        <Flex>
          <Icon icon={icon} mr={1} />
          <Text>{label}</Text>
        </Flex>
        <Text color="muted" alignItems="center" textAlign="right">
          {extension}
        </Text>
      </Flex>
    )
  };
}

function getContentLabel(label) {
  return (
    <Flex justifyContent="space-between" alignItems="center" flex={1}>
      {label}
    </Flex>
  );
}

function exportTypeSelectRenderer(option, placeholder) {
  if (!option) {
    return <span className="bp4-text-muted">{placeholder || 'Select a value...'}</span>;
  }

  const iconName = option ? option.iconCls || option.iconName || option.icon : false;
  return (
    <Flex justifyContent="flex-start" alignItems="center" className={option.className}>
      {iconName && <Icon icon={iconName} style={{ marginRight: 6 }} />}
      {option && (
        <Text justifyContent="space-between" width={170} ellipsis>
          {option.label}
        </Text>
      )}
    </Flex>
  );
}

const exportContentMap = {
  alerts: {
    name: 'Alerts',
    associatedContentTypes: ['alerts'],
    selectLabel: 'Alerts',
    link: () => '/v4/alerting',
    exportOptions: [getExportOption('pdf', 'Visual Report', '.pdf', 'document')],
    isSummary: true,
    lookbackDays: false,
    hasSubscription: true
  },
  alertOverview: {
    name: 'Alert Overview',
    associatedContentTypes: ['alert-overview'],
    selectLabel: 'Alert Overview',
    link: () => '/v4/alerting/overview',
    exportOptions: [getExportOption('pdf', 'Visual Report', '.pdf', 'document')],
    isSummary: true,
    lookbackDays: false,
    hasSubscription: true
  },
  mitigations: {
    name: 'Mitigations',
    fetch: () => $mitigations.activeMitigationCollection.fetch(),
    associatedContentTypes: ['mitigations'],
    selectLabel: 'Mitigations',
    link: () => '/v4/protect/mitigations',
    exportOptions: [getExportOption('pdf', 'Visual Report', '.pdf', 'document')],
    isSummary: true,
    lookbackDays: false,
    hasSubscription: true
  },
  dashboard: {
    name: 'Dashboard',
    fetch: () => $dashboards.collection.fetch(),
    associatedContentTypes: ['dashboard'],
    selectLabel: 'Dashboard',
    link: (id) => `/v4/library/dashboards/${id}`,
    contentOptions: () => $dashboards.dashboardOptions,
    exportOptions: [getExportOption('pdf', 'Visual Report', '.pdf', 'document')],
    lookbackDays: true,
    hasSubscription: true
  },
  savedview: {
    name: 'Saved View',
    fetch: () => $savedViews.collection.fetch(),
    associatedContentTypes: ['savedview'],
    selectLabel: 'Saved View',
    link: (id) => `/v4/library/saved-views/${id}`,
    contentOptions: () => $savedViews.savedViewOptions,
    exportOptions: [
      getExportOption('pdf', 'Chart + Data Table', '.pdf', 'document'),
      getExportOption('csv-chart', 'Chart Data', '.csv', 'th'),
      getExportOption('csv', 'Data Table', '.csv', 'th')
    ],
    lookbackDays: true,
    hasSubscription: true
  },
  capacitysummary: {
    name: 'Capacity Planning Summary',
    fetch: () => $capacity.collection.fetchModuleConfig(),
    associatedContentTypes: ['capacitysummary', 'capacityplans'],
    link: () => '/v4/core/capacity',
    exportOptions: [
      getExportOption('pdf', 'Visual Report', '.pdf', 'document'),
      getExportOption('csv', 'Data Table', '.csv', 'th')
    ],
    isSummary: true,
    hasSubscription: true
  },
  capacityplans: {
    name: 'Specific Capacity Plan(s)',
    fetch: () => $capacity.collection.fetchModuleConfig(),
    associatedContentTypes: ['capacitysummary', 'capacityplans'],
    selectLabel: 'Capacity Plan(s)',
    link: () => '/v4/core/capacity',
    contentOptions: () => $capacity.capacityOptions,
    exportOptions: [
      getExportOption('pdf', 'Visual Report', '.pdf', 'document'),
      getExportOption('csv', 'Data Table', '.csv', 'th')
    ],
    isMultiSelectable: true,
    hasSubscription: true
  },
  costsummary: {
    name: 'Connectivity Cost - Summary',
    fetch: () => $cost.costHistory.fetch().then(() => $cost.costSummaries.set($cost.costHistory.summaries)),
    associatedContentTypes: ['costsummary', 'costproviders', 'costconnectivitytypes', 'costsites', 'costsitemarkets'],
    link: () => '/v4/edge/costs',
    exportOptions: [
      getExportOption('pdf', 'Visual Report', '.pdf', 'document'),
      getExportOption('csv', 'Data Table', '.csv', 'th')
    ],
    isSummary: true,
    hasSubscription: true
  },
  costproviders: {
    name: 'Connectivity Cost - Providers',
    fetch: () => $cost.costHistory.fetch().then(() => $cost.costSummaries.set($cost.costHistory.summaries)),
    associatedContentTypes: ['costsummary', 'costproviders', 'costconnectivitytypes', 'costsites', 'costsitemarkets'],
    selectLabel: 'Provider(s)',
    link: () => '/v4/edge/costs',
    contentOptions: () => $cost.costProvidersOptions,
    exportOptions: [
      getExportOption('pdf', 'Visual Report', '.pdf', 'document'),
      getExportOption('csv', 'Data Table', '.csv', 'th')
    ],
    isMultiSelectable: true,
    hasSubscription: true
  },
  costconnectivitytypes: {
    name: 'Connectivity Cost - Connectivity Types',
    fetch: () => $cost.costHistory.fetch().then(() => $cost.costSummaries.set($cost.costHistory.summaries)),
    associatedContentTypes: ['costsummary', 'costproviders', 'costconnectivitytypes', 'costsites', 'costsitemarkets'],
    selectLabel: 'Connectivity Type(s)',
    link: () => '/v4/edge/costs',
    contentOptions: () => $cost.costConnTypesOptions,
    exportOptions: [
      getExportOption('pdf', 'Visual Report', '.pdf', 'document'),
      getExportOption('csv', 'Data Table', '.csv', 'th')
    ],
    isMultiSelectable: true,
    hasSubscription: true
  },
  costsites: {
    name: 'Connectivity Cost - Sites',
    fetch: () => $cost.costHistory.fetch().then(() => $cost.costSummaries.set($cost.costHistory.summaries)),
    associatedContentTypes: ['costsummary', 'costproviders', 'costconnectivitytypes', 'costsites', 'costsitemarkets'],
    selectLabel: 'Site(s)',
    link: () => '/v4/edge/costs',
    contentOptions: () => $cost.costSitesOptions,
    exportOptions: [
      getExportOption('pdf', 'Visual Report', '.pdf', 'document'),
      getExportOption('csv', 'Data Table', '.csv', 'th')
    ],
    isMultiSelectable: true,
    hasSubscription: true
  },
  costsitemarkets: {
    name: 'Connectivity Cost - Site Markets',
    fetch: () => $cost.costHistory.fetch().then(() => $cost.costSummaries.set($cost.costHistory.summaries)),
    associatedContentTypes: ['costsummary', 'costproviders', 'costconnectivitytypes', 'costsites', 'costsitemarkets'],
    selectLabel: 'Site Market(s)',
    link: () => '/v4/edge/costs',
    contentOptions: () => $cost.costSiteMarketsOptions,
    exportOptions: [
      getExportOption('pdf', 'Visual Report', '.pdf', 'document'),
      getExportOption('csv', 'Data Table', '.csv', 'th')
    ],
    isMultiSelectable: true,
    hasSubscription: true
  },
  dataview: {
    name: 'Data Explorer',
    associatedContentTypes: ['dataview'],
    exportOptions: [
      getExportOption('pdf', 'Chart + Data Table', '.pdf', 'document'),
      getExportOption('csv-chart', 'Chart Data', '.csv', 'th'),
      getExportOption('csv', 'Data Table', '.csv', 'th')
    ],
    hasSubscription: false
  },
  rawFlow: {
    name: 'Raw Flow',
    associatedContentTypes: ['rawFlow'],
    exportOptions: [getExportOption('csv', 'Data Table', '.csv', 'th')],
    hasSubscription: false
  },
  users: {
    name: 'Users',
    associatedContentTypes: ['users'],
    link: () => '/v4/settings/users',
    exportOptions: [
      getExportOption('pdf', 'Visual Report', '.pdf', 'document'),
      getExportOption('csv', 'Data Table', '.csv', 'th')
    ],
    isSummary: true,
    hasSubscription: true
  },
  sites: {
    name: 'Sites',
    associatedContentTypes: ['sites'],
    link: () => '/v4/settings/sites',
    exportOptions: [
      getExportOption('pdf', 'Visual Report', '.pdf', 'document'),
      getExportOption('csv', 'Data Table', '.csv', 'th')
    ],
    isSummary: true,
    hasSubscription: true
  },
  devices: {
    name: 'Devices',
    associatedContentTypes: ['devices'],
    link: () => '/v4/settings/devices',
    exportOptions: [
      getExportOption('pdf', 'Visual Report', '.pdf', 'document'),
      getExportOption('csv', 'Data Table', '.csv', 'th')
    ],
    isSummary: true,
    hasSubscription: true
  }
};

const exportContentTypes = Object.entries(exportContentMap).map(([value, { name }]) => ({
  value,
  label: getContentLabel(name)
}));

export { exportContentMap, exportContentTypes, getContentLabel, getExportOption, exportTypeSelectRenderer };
