/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { isFunction } from 'lodash';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import Flex from 'core/components/Flex';

import Cell from './Cell';
import CELL_TYPES from './CELL_TYPES';

export const StyledRow = styled(Flex)`
  height: inherit;

  &.selected {
    background-color: ${({ theme }) => theme.colors.primaryBackground};

    .row-expander {
      visibility: visible;
    }
  }

  .${Classes.HTML_TABLE}.${Classes.INTERACTIVE} & {
    cursor: pointer;
  }

  .tr&.${Classes.DISABLED} {
    cursor: default;
  }
  .tr&.${Classes.DISABLED}:hover {
    background-color: initial !important;
  }

  .${Classes.HTML_TABLE}.${Classes.INTERACTIVE} &:not(.selected):hover {
    background-color: ${({ theme }) => (theme.name === 'dark' ? theme.colors.black[2] : theme.colors.appBackground)};

    .row-expander {
      visibility: visible;
    }
  }

  &.row-warning {
    background-color: ${({ theme }) => (theme.name === 'dark' ? theme.colors.warningBackground : '#FFF9F3')};
  }
`;

@observer
class Row extends Component {
  static defaultProps = {
    highlightSelected: true
  };

  handleRowClick = (e) => {
    const { selectOnRowClick, model, onRowClick, history, rootUrl, multiSelect = false } = this.props;

    /* detect if the click was on a `pt-button`, which generally *always* means that we
        want to not propagate the event upwards towards a `select() which is the default */
    e.persist();
    const { classList, nodeName } = e && e.target;
    const shouldPreventSelection =
      classList.contains(Classes.CONTROL_INDICATOR) ||
      classList.contains(Classes.BUTTON) ||
      classList.contains(Classes.SWITCH) ||
      nodeName === 'INPUT' ||
      nodeName === 'BUTTON' ||
      false;

    if (onRowClick) {
      onRowClick(model, e);
    } else if (selectOnRowClick !== false) {
      if (shouldPreventSelection) {
        e.stopPropagation();
      } else if (!shouldPreventSelection) {
        const isCurrentlySelected = model.isSelected;
        model.select({ multi: multiSelect });

        if (isCurrentlySelected) {
          if (history && rootUrl) {
            history.push(`${rootUrl}/${model.get('id')}`);
          }
        }
      }
    }
  };

  handleRowDoubleClick = () => {
    const { onRowDoubleClick, model } = this.props;
    if (onRowDoubleClick) {
      onRowDoubleClick(model);
    }
  };

  handleMouseOver = (e) => {
    e.persist();
    const { onRowMouseOver, model } = this.props;
    if (onRowMouseOver) {
      onRowMouseOver(model);
    }
  };

  handleMouseOut = (e) => {
    e.persist();
    const { onRowMouseOut, model } = this.props;
    if (onRowMouseOut) {
      onRowMouseOut(model);
    }
  };

  get rowTags() {
    const { model, rowTags } = this.props;

    return rowTags ? rowTags({ model }) : {};
  }

  render() {
    const {
      model,
      style,
      className,
      rowClass,
      rowAlign,
      expandedRowRenderer,
      columns,
      disabled,
      selected,
      noBorder,
      highlightSelected,
      showRowExpandedColumn
    } = this.props;

    const rowClassName = classNames(
      'tr',
      `row-id-${model.id}`,
      { selected: selected && highlightSelected },
      isFunction(rowClass) ? rowClass(model) : rowClass,
      className,
      { [Classes.TEXT_DISABLED]: disabled },
      { [Classes.DISABLED]: disabled },
      { 'row-no-border': noBorder }
    );

    return (
      <Flex
        flexDirection="column"
        css={{ zIndex: selected ? 1 : 0 }}
        className="avoid-break"
        style={style}
        data-testid="rowOuterFlex"
      >
        <StyledRow
          className={rowClassName}
          onClick={this.handleRowClick}
          onDoubleClick={this.handleRowDoubleClick}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
          alignItems={rowAlign}
          {...this.rowTags}
        >
          {columns.map((column, idx) => {
            let value;
            if (column.name && column.type !== CELL_TYPES.ACTION) {
              value = column.computed ? model[column.name] : model.get(column.name);
            }

            const values = column.referencedFields
              ? column.referencedFields.map((name) => model.getSortValue(name)).join('|')
              : model.getSortValue(column.name);

            return (
              <Cell
                {...this.props}
                key={column.key || column.name || column.type || column.id}
                isSelected={selected}
                modelId={model.id}
                column={column}
                value={values}
                rawValue={value}
                index={idx}
                columns={columns}
                loading={column.loading}
                treeExpander={idx === 0 && model.collection.isTreeGroupBy}
                collection={model.collection}
              />
            );
          })}
        </StyledRow>
        {(model.isSelected || showRowExpandedColumn === false) && expandedRowRenderer && expandedRowRenderer(model)}
      </Flex>
    );
  }
}

export default withRouter(Row);
