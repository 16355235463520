import $dictionary from 'app/stores/$dictionary';

const metricColumns = {
  Metrics: [
    [
      {
        excludeGroupClassName: true,
        group: 'none',
        label: 'Sampled at Ingress bytes',
        value: 'in_bytes'
      },
      {
        group: 'none',
        label: 'Sampled at Ingress packets',
        value: 'in_pkts'
      }
    ],
    [
      {
        excludeGroupClassName: true,
        group: 'none',
        label: 'Sampled at Egress bytes',
        value: 'out_bytes'
      },
      {
        group: 'none',
        label: 'Sampled at Egress packets',
        value: 'out_pkts'
      }
    ],
    [
      {
        excludeGroupClassName: true,
        group: 'none',
        label: 'Client Latency (ms)',
        value: 'client_nw_latency_ms'
      }
    ]
  ]
};

const timeColumns = {
  Time: [
    [
      {
        excludeGroupClassName: true,
        group: 'none',
        label: 'Timestamp',
        value: 'ctimestamp'
      }
    ],
    [],
    [
      {
        excludeGroupClassName: true,
        group: 'none',
        label: 'Flow Start',
        value: 'flow_start_timestamp'
      },
      {
        excludeGroupClassName: true,
        group: 'none',
        label: 'Flow End',
        value: 'flow_end_timestamp'
      },
      {
        excludeGroupClassName: true,
        group: 'none',
        label: 'Flow Duration',
        value: 'flow_duration'
      }
    ]
  ]
};

const timeDimensionSelectorColumns = {
  Time: [
    [],
    [],
    [
      {
        excludeGroupClassName: true,
        group: 'none',
        label: 'Flow Start',
        value: 'flow_start_timestamp'
      },
      {
        excludeGroupClassName: true,
        group: 'none',
        label: 'Flow End',
        value: 'flow_end_timestamp'
      },
      {
        excludeGroupClassName: true,
        group: 'none',
        label: 'Flow Duration',
        value: 'flow_duration'
      }
    ]
  ]
};

const deviceColumn = {
  Device: [
    [],
    [],
    [
      {
        // excludeGroupClassName: true,
        group: '',
        label: 'Device',
        value: 'device_id'
      }
    ]
  ]
};

function flowColumns() {
  return Object.assign({}, timeDimensionSelectorColumns, metricColumns, $dictionary.dimensionOptions);
}

function orderByColumns() {
  return Object.assign({}, timeColumns, metricColumns, $dictionary.dimensionOptions);
}

export { flowColumns, orderByColumns, metricColumns, timeColumns, deviceColumn };
