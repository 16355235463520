import { get } from 'lodash';
import { OCI_ENTITY_TYPES } from 'shared/hybrid/constants';

/*
  Using the defined src/dst dimensions defined for a cloud provider,
  this will generate a map based on dimension name and give a map that can reference that dimension's other siblings.

  For example given the key ktsubtype__gcp_subnet__STR08 (gcp subnet src) we could find its sibling vpc name via:

  GCP.SIBLING['ktsubtype__gcp_subnet__STR08].VPC_NAME

  This is used in the traffic link generator to find the matching vpc name for subnets in Azure and GCP
  This is critical because subnet names on their own are not guaranteed unique.
  The matching vpc name is used to create a unique key for the subnet so we can accurately draw links

  ex:

  GCP: {
    SIBLING: {
      'ktsubtype__gcp_subnet__STR08': {
        VPC_NAME: 'ktsubtype__gcp_subnet__STR12'
        ...
      }
      ...
    }
  }
*/
function generateSiblingEntries(providerGroup) {
  const { SRC, DST } = providerGroup;
  const entries = {};

  [SRC, DST].forEach((directionGroup) =>
    Object.entries(directionGroup).forEach(([, parentValue], i, all) => {
      entries[parentValue] = all.reduce((acc, [childKey, childValue]) => ({ ...acc, [childKey]: childValue }), {});
    })
  );

  return entries;
}

export const AWS = {
  DATASET: 'aws_subnet'
};
AWS.SRC = {
  INSTANCE_ID: 'kt_aws_src_vm_id',
  INTERFACE_TYPE: 'ktsubtype__aws_subnet__INT02',
  REGION: 'kt_aws_src_region',
  SUBNET_ID: 'kt_aws_src_subnet_id',
  VPC_ID: 'kt_aws_src_vpc_id',
  // generic fields defined across multiple providers
  VIRTUAL_NETWORK_ID: 'kt_aws_src_vpc_id',
  INTERFACE_ID: 'ktsubtype__aws_subnet__STR20',
  CORE_NETWORK_ATTACHMENT: 'ktsubtype__aws_subnet__STR41',
  CORE_NETWORK_EDGE_LOCATION: 'ktsubtype__aws_subnet__STR39'
};
AWS.DST = {
  INSTANCE_ID: 'kt_aws_dst_vm_id',
  INTERFACE_TYPE: 'ktsubtype__aws_subnet__INT03',
  REGION: 'kt_aws_dst_region',
  SUBNET_ID: 'kt_aws_dst_subnet_id',
  VPC_ID: 'kt_aws_dst_vpc_id',
  // gateway id and type is a destination-only dimension
  GATEWAY_ID: 'ktsubtype__aws_subnet__STR17',
  GATEWAY_TYPE: 'ktsubtype__aws_subnet__STR19',
  // generic fields defined across multiple providers
  VIRTUAL_NETWORK_ID: 'kt_aws_dst_vpc_id',
  INTERFACE_ID: 'ktsubtype__aws_subnet__STR09',
  CORE_NETWORK_ATTACHMENT: 'ktsubtype__aws_subnet__STR42',
  CORE_NETWORK_EDGE_LOCATION: 'ktsubtype__aws_subnet__STR40'
};
AWS.BI = {
  INSTANCE_ID: 'kt_aws_src|dst_vm_id',
  INTERFACE_TYPE: 'ktsubtype__aws_subnet__INT02src|dstktsubtype__aws_subnet__INT03',
  REGION: 'kt_aws_src|dst_region',
  SUBNET_ID: 'kt_aws_src|dst_subnet_id',
  VPC_ID: 'kt_aws_src|dst_vpc_id',
  FIREWALL_ACTION: 'kt_aws_action',
  // generic fields defined across multiple providers
  VIRTUAL_NETWORK_ID: 'kt_aws_src|dst_vpc_id',
  INTERFACE_ID: `${AWS.SRC.INTERFACE_ID}src|dst${AWS.DST.INTERFACE_ID}`,
  CORE_NETWORK_ATTACHMENT: 'ktsubtype__aws_subnet__STR41src|dstktsubtype__aws_subnet__STR42',
  CORE_NETWORK_EDGE_LOCATION: 'ktsubtype__aws_subnet__STR39src|dstktsubtype__aws_subnet__STR40'
};

AWS.NON_DIRECTIONAL = {
  CORE_NETWORK_ID: 'ktsubtype__aws_subnet__STR45'
};

AWS['SRC|DST'] = AWS.BI;

export const AZURE = {
  DATASET: 'azure_subnet',
  EXPRESS_ROUTE_CIRCUIT: 'ktsubtype__azure_subnet__STR15',
  EXPRESS_ROUTE_PEERING_TYPE: 'ktsubtype__azure_subnet__STR16',

  SRC: {
    REGION: 'kt_az_src_region',
    VNET_ID: 'kt_az_src_vnet',
    SUBNET_NAME: 'kt_az_src_subnet',
    GATEWAY_TYPE: 'ktsubtype__azure_subnet__STR11',
    GATEWAY_NAME: 'ktsubtype__azure_subnet__STR09',
    INTERFACE_NAME: 'ktsubtype__azure_subnet__STR06',
    FIREWALL_ACTION: 'ktsubtype__azure_subnet__STR03',
    LOAD_BALANCER: 'ktsubtype__azure_subnet__STR23',
    // generic fields defined across multiple providers
    VIRTUAL_NETWORK_ID: 'kt_az_src_vnet',
    INTERFACE_ID: 'ktsubtype__azure_subnet__STR06'
  },

  DST: {
    REGION: 'kt_az_dst_region',
    VNET_ID: 'kt_az_dst_vnet',
    SUBNET_NAME: 'kt_az_dst_subnet',
    GATEWAY_TYPE: 'ktsubtype__azure_subnet__STR12',
    GATEWAY_NAME: 'ktsubtype__azure_subnet__STR10',
    INTERFACE_NAME: 'ktsubtype__azure_subnet__STR07',
    FIREWALL_ACTION: 'ktsubtype__azure_subnet__STR02',
    LOAD_BALANCER: 'ktsubtype__azure_subnet__STR24',
    // generic fields defined across multiple providers
    VIRTUAL_NETWORK_ID: 'kt_az_dst_vnet',
    INTERFACE_ID: 'ktsubtype__azure_subnet__STR07'
  },

  BI: {
    REGION: 'kt_az_src|dst_region',
    VNET_ID: 'kt_az_src|dst_vnet',
    SUBNET_NAME: 'kt_az_src|dst_subnet',
    GATEWAY_TYPE: 'ktsubtype__azure_subnet__STR11src|dstktsubtype__azure_subnet__STR12',
    GATEWAY_NAME: 'ktsubtype__azure_subnet__STR09src|dstktsubtype__azure_subnet__STR10',
    INTERFACE_NAME: 'ktsubtype__azure_subnet__STR06src|dstktsubtype__azure_subnet__STR07',
    FIREWALL_ACTION: 'ktsubtype__azure_subnet__STR03src|dstktsubtype__azure_subnet__STR02',
    LOAD_BALANCER: 'ktsubtype__azure_subnet__STR23src|dstktsubtype__azure_subnet__STR24',
    LOGGING_RESOURCE_CATEGORY: 'ktsubtype__azure_subnet__STR17',
    LOGGING_RESOURCE_NAME: 'ktsubtype__azure_subnet__STR18',
    // generic fields defined across multiple providers
    VIRTUAL_NETWORK_ID: 'kt_az_src|dst_vnet',
    INTERFACE_ID: 'ktsubtype__azure_subnet__STR06src|dstktsubtype__azure_subnet__STR07'
  }
};

AZURE['SRC|DST'] = AZURE.BI;

export const GCP = {
  DATASET: 'gcp_subnet',
  INTERCONNECT_NAME: 'ktsubtype__gcp_subnet__STR25',
  INTERCONNECT_TYPE: 'ktsubtype__gcp_subnet__STR26',

  SRC: {
    VPC_NAME: 'ktsubtype__gcp_subnet__STR11',
    REGION: 'ktsubtype__gcp_subnet__STR04',
    SUBNET_NAME: 'ktsubtype__gcp_subnet__STR08',
    PROJECT_ID: 'ktsubtype__gcp_subnet__STR00',
    GATEWAY_NAME: 'ktsubtype__gcp_subnet__STR27',
    GATEWAY_TYPE: 'ktsubtype__gcp_subnet__STR29',
    // generic fields defined across multiple providers
    VIRTUAL_NETWORK_ID: 'ktsubtype__gcp_subnet__STR11'
  },

  DST: {
    VPC_NAME: 'ktsubtype__gcp_subnet__STR12',
    REGION: 'ktsubtype__gcp_subnet__STR05',
    SUBNET_NAME: 'ktsubtype__gcp_subnet__STR09',
    PROJECT_ID: 'ktsubtype__gcp_subnet__STR01',
    GATEWAY_NAME: 'ktsubtype__gcp_subnet__STR28',
    GATEWAY_TYPE: 'ktsubtype__gcp_subnet__STR30',
    // generic fields defined across multiple providers
    VIRTUAL_NETWORK_ID: 'ktsubtype__gcp_subnet__STR12'
  },

  BI: {
    VPC_NAME: 'ktsubtype__gcp_subnet__STR11src|dstktsubtype__gcp_subnet__STR12',
    REGION: 'ktsubtype__gcp_subnet__STR04src|dstktsubtype__gcp_subnet__STR05',
    SUBNET_NAME: 'ktsubtype__gcp_subnet__STR08src|dstktsubtype__gcp_subnet__STR09',
    PROJECT_ID: 'ktsubtype__gcp_subnet__STR00|ktsubtype__gcp_subnet__STR01',
    GATEWAY_NAME: 'ktsubtype__gcp_subnet__STR27src|dstktsubtype__gcp_subnet__STR28',
    GATEWAY_TYPE: 'ktsubtype__gcp_subnet__STR29src|dstktsubtype__gcp_subnet__STR30',
    // generic fields defined across multiple providers
    VIRTUAL_NETWORK_ID: 'ktsubtype__gcp_subnet__STR11src|dstktsubtype__gcp_subnet__STR12'
  }
};

GCP['SRC|DST'] = GCP.BI;

// based on https://github.com/kentik/transfluo/blob/c188fb65f64894309349942e265886d33c6cf200/pkg/oci/flow_processor.go#L16-L48
export const OCI = {
  DATASET: 'oci_subnet',

  SRC: {
    VCN: 'ktsubtype__oci_subnet__STR08',
    REGION: 'ktsubtype__oci_subnet__STR10',
    SUBNET: 'ktsubtype__oci_subnet__STR06',
    [OCI_ENTITY_TYPES.LOCAL_PEERING_GATEWAY]: 'ktsubtype__oci_subnet__STR18',
    [OCI_ENTITY_TYPES.DYNAMIC_ROUTING_GATEWAY]: 'ktsubtype__oci_subnet__STR16'
  },

  DST: {
    VCN: 'ktsubtype__oci_subnet__STR09',
    REGION: 'ktsubtype__oci_subnet__STR11',
    SUBNET: 'ktsubtype__oci_subnet__STR07',
    [OCI_ENTITY_TYPES.LOCAL_PEERING_GATEWAY]: 'ktsubtype__oci_subnet__STR19',
    [OCI_ENTITY_TYPES.NAT_GATEWAY]: 'ktsubtype__oci_subnet__STR21',
    [OCI_ENTITY_TYPES.SERVICE_GATEWAY]: 'ktsubtype__oci_subnet__STR22',
    [OCI_ENTITY_TYPES.INTERNET_GATEWAY]: 'ktsubtype__oci_subnet__STR20',
    [OCI_ENTITY_TYPES.IP_SEC_CONNECTION]: 'ktsubtype__oci_subnet__STR23',
    [OCI_ENTITY_TYPES.DYNAMIC_ROUTING_GATEWAY]: 'ktsubtype__oci_subnet__STR17'
  },

  BI: {
    VCN: 'ktsubtype__oci_subnet__STR08src|dstktsubtype__oci_subnet__STR09',
    REGION: 'ktsubtype__oci_subnet__STR10src|dstktsubtype__oci_subnet__STR11',
    SUBNET: 'ktsubtype__oci_subnet__STR06src|dstktsubtype__oci_subnet__STR07',
    [OCI_ENTITY_TYPES.LOCAL_PEERING_GATEWAY]: 'ktsubtype__oci_subnet__STR18src|dstktsubtype__oci_subnet__STR19',
    [OCI_ENTITY_TYPES.DYNAMIC_ROUTING_GATEWAY]: 'ktsubtype__oci_subnet__STR16src|dstktsubtype__oci_subnet__STR17'
  }
};

OCI['SRC|DST'] = OCI.BI;

GCP.SIBLING = generateSiblingEntries(GCP);
OCI.SIBLING = generateSiblingEntries(OCI);
AZURE.SIBLING = generateSiblingEntries(AZURE);

const CLOUD_DIMENSION_CONSTANTS = { AWS, AZURE, GCP, OCI };

export function getDimensionFieldName({ cloudProvider, direction, type }) {
  return get(CLOUD_DIMENSION_CONSTANTS, `${cloudProvider}.${direction}.${type}`.toUpperCase(), '');
}

export function getDimensionFilter({ cloudProvider, direction = 'src|dst', type, operator = '=', filterValue }) {
  const filterField = getDimensionFieldName({ cloudProvider, direction, type });

  if (filterField) {
    return {
      filterField,
      operator,
      filterValue
    };
  }

  return null;
}
