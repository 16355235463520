import React from 'react';
import { toJS } from 'mobx';
import { Intent, Tooltip } from '@blueprintjs/core';
import { MdFormatListBulleted } from 'react-icons/md';
import { uniqueId } from 'lodash';

import { Box, Icon, Button, CollectionLookup, Flex, Popover, Sparkline, Tag, Text } from 'core/components';
import { adjustByGreekPrefix } from 'core/util';
import $sites from 'app/stores/site/$sites';
import $siteMarkets from 'app/stores/siteMarket/$siteMarkets';
import { getToFixed, zeroToText, getOrdinalSuffix } from 'app/util/utils';
import KeyTrafficIndicator from 'app/components/insights/KeyTrafficIndicator';
import ApplicationLink from 'app/components/links/ApplicationLink';
import AsPathLink from 'app/components/links/AsPathLink';
import AsnLink from 'app/components/links/AsnLink';
import BgpCommunityLink from 'app/components/links/BgpCommunityLink';
import CdnLink from 'app/components/links/CdnLink';
import CityLink from 'app/components/links/CityLink';
import CloudDetailLink from 'app/components/links/CloudDetailLink';
import ConnectivityTypeLink from 'app/components/links/ConnectivityTypeLink';
import CountryLink from 'app/components/links/CountryLink';
import DeviceLink from 'app/components/links/DeviceLink';
import InetFamilyLink from 'app/components/links/InetFamilyLink';
import InterfaceLink from 'app/components/links/InterfaceLink';
import IpLink from 'app/components/links/IpLink';
import NetworkBoundaryLink from 'app/components/links/NetworkBoundaryLink';
import OttProviderLink from 'app/components/links/OttProviderLink';
import OttServiceLink from 'app/components/links/OttServiceLink';
import OttServiceTypeLink from 'app/components/links/OttServiceTypeLink';
import PacketSizeLink from 'app/components/links/PacketSizeLink';
import ProtocolLink from 'app/components/links/ProtocolLink';
import ProviderLink from 'app/components/links/ProviderLink';
import RegionLink from 'app/components/links/RegionLink';
import RoutePrefixLink from 'app/components/links/RoutePrefixLink';
import ServiceLink from 'app/components/links/ServiceLink';
import SiteLink from 'app/components/links/SiteLink';
import { InterfaceDescription, InterfaceMetadata } from 'app/components/Interface';
import ASGroupRenderer from 'app/components/asn/ASGroupRenderer';
import ASNRenderer from 'app/components/asn/ASNRenderer';
import * as cloudDetailMetrics from 'app/views/core/cloud/cloudDetailMetrics';

// import { ReactComponent as KubeNodeIcon } from 'app/assets/icons/kubernetes/node.svg';
import { ReactComponent as KubePodIcon } from 'app/assets/icons/kubernetes/pod.svg';

import { formatDateTime, formatDuration } from 'core/util/dateUtils';
import { interfaceKeyRegex, interfacePercentageMetrics } from './legendUtils';

export const overlayRenderer = (showOverlayTag, model) => (
  <Text>
    {model.get('name')}
    {showOverlayTag && (
      <Tag minimal small round intent={Intent.PRIMARY} ml={1}>
        Overlay
      </Tag>
    )}
  </Text>
);

export const sampleRateValueRenderer =
  (adjust) =>
  ({ value }) => (
    <Flex flex={1}>
      <Box flex={1} mr="4px" textAlign="right">
        {Math.round(value / (adjust ? 100 : 1))}
      </Box>
      <Text as="div" color="muted" width={50} flex={1}>
        : 1
      </Text>
    </Flex>
  );

export const valueRenderer =
  ({ fix, prefix, emptyText }) =>
  ({ value }) => {
    if (emptyText && value === undefined) {
      return emptyText;
    }

    return zeroToText(adjustByGreekPrefix(value, prefix) || 0, { fix });
  };

export const percentRenderer =
  ({ fix, prefix, emptyText }) =>
  ({ value }) => {
    if (emptyText && value === undefined) {
      return emptyText;
    }

    return `${zeroToText(adjustByGreekPrefix(value, prefix) || 0, { fix })}%`;
  };

export const averageRenderer = ({ fix, prefix, query, emptyText }) => {
  if (query.get('show_total_overlay')) {
    return ({ value, model, column, collection: queryResultsCollection }) => {
      let pctValue;

      if (queryResultsCollection && model && !model.get('isOverlay')) {
        let totalOverlayIndex = 0;
        if (query.get('show_overlay') && queryResultsCollection.at(0).get('name') !== 'Total') {
          totalOverlayIndex = 1;
        }
        const totalOverlay = queryResultsCollection.at(totalOverlayIndex);
        if (totalOverlay && column) {
          const totalValue = totalOverlay.get(column.name) || 0;
          pctValue = totalValue === 0 ? (100).toFixed(fix) : (((value || 0) / totalValue) * 100).toFixed(fix);
        }
      }

      return (
        <>
          <Box pr={1} flex={1} textAlign="right">
            {valueRenderer({ fix, prefix, emptyText })({ value })}
          </Box>
          <Text color="muted" flex={1}>
            {pctValue !== undefined && `(${pctValue}%)`}
          </Text>
        </>
      );
    };
  }

  return valueRenderer({ fix, prefix, emptyText });
};

export const deltaRenderer =
  ({ fix, prefix, emptyText }) =>
  ({ value, model, column, name = column?.name }) => {
    const otherPeriodModel = model?.otherPeriodModel;
    let delta = 0;
    let percentage = 0;
    let intent;

    if (otherPeriodModel) {
      const otherPeriodValue = otherPeriodModel.get(name);
      delta = value - otherPeriodValue;
      percentage = Math.round((delta / otherPeriodValue) * 100) || 0;

      if (percentage > 0) {
        intent = 'success';
      } else if (percentage < 0) {
        intent = 'danger';
      }
    }

    return (
      <>
        <Box pr={1} flex={1} textAlign="right">
          {valueRenderer({ fix, prefix, emptyText })({ value })}
        </Box>
        {model && (
          <Tag intent={intent} minimal>
            {otherPeriodModel ? `${percentage}%` : '-'}
          </Tag>
        )}
      </>
    );
  };

export const comparisonRankRenderer = ({ model, value }) => {
  const thisRank = value;
  const previousRank = model.get('previousRank');

  return (
    <Flex gap="4px">
      <Box width="3ch">{thisRank}</Box>
      {thisRank !== previousRank && (
        <Box>
          {!previousRank && (
            <Text color="success" title="New key">
              <Flex gap="4px" alignItems="center">
                <Icon icon="double-chevron-up" size={12} />
                New
              </Flex>
            </Text>
          )}
          {!thisRank && (
            <Text color="danger" title="Removed key">
              <Icon icon="double-chevron-down" size={12} />
            </Text>
          )}
          {thisRank && thisRank < previousRank && (
            <Text color="success" title={`Up from ${previousRank}`}>
              <Flex gap="4px" alignItems="center">
                <Icon icon="chevron-up" size={12} />+{previousRank - thisRank}
              </Flex>
            </Text>
          )}
          {previousRank && previousRank < thisRank && (
            <Text color="danger" title={`Down from ${previousRank}`}>
              <Flex gap="4px" alignItems="center">
                <Icon icon="chevron-down" size={12} />-{thisRank - previousRank}
              </Flex>
            </Text>
          )}
        </Box>
      )}
    </Flex>
  );
};

export const comparisonPercentageRenderer = ({ value }) => {
  if (typeof value === 'number') {
    const highThreshold = 0.5;
    const lowThreshold = -0.95;
    const factor = value > 0 ? Math.min(value / highThreshold, 1) : Math.min(value / lowThreshold, 1);
    const color = value > 0 ? 'success' : 'danger';
    const significantChange = value > highThreshold || value < lowThreshold;

    return (
      <>
        <KeyTrafficIndicator percentage={value} factor={factor} />
        <Text color={significantChange ? color : 'body'} fontWeight={significantChange ? 'bold' : 'normal'}>
          {Math.round(value * 100) || 0}%
        </Text>
      </>
    );
  }

  return (
    <>
      <KeyTrafficIndicator percentage={0} />-
    </>
  );
};

const Port65535Warning = (props) => {
  const { children, value, fastData, queryInterval } = props;

  if (`${value}` === '65535' && (fastData === 'Fast' || (fastData === 'Auto' && queryInterval > 86400))) {
    return (
      <Tooltip
        content={
          <div>
            <div>
              When viewing fast data, all port numbers above 32767 are aggregated and represented as port 65535.
            </div>
            <div>Please switch to full data to see a complete port breakdown.</div>
          </div>
        }
      >
        <div>
          {children || value}
          <Text color="danger" fontSize={20} lineHeight="14px" ml="3px">
            &bull;
          </Text>
        </div>
      </Tooltip>
    );
  }

  return children || value || null;
};

export const portRenderer =
  (checkOverlay, showOverlayTag, fastData, queryInterval) =>
  ({ value, model }) => {
    if (checkOverlay && model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    return <Port65535Warning value={value} fastData={fastData} queryInterval={queryInterval} />;
  };

export const serviceRenderer =
  (checkOverlay, showOverlayTag, fastData, queryInterval) =>
  ({ value, model }) => {
    if (checkOverlay && model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    if (value && /[^-]/.test(value)) {
      return (
        <Port65535Warning value={value.split(' ')[0]} fastData={fastData} queryInterval={queryInterval}>
          <ServiceLink service={value} />
        </Port65535Warning>
      );
    }

    return value;
  };

export const cdnRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    if (value && /[^-]/.test(value)) {
      return <CdnLink cdn={value} />;
    }

    return value;
  };

export const ottProviderRenderer =
  (showOverlayTag) =>
  ({ value, model, ...rest }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    if (value && /[^-]/.test(value)) {
      return <OttProviderLink provider={value} {...rest} />;
    }

    return value;
  };

export const ottServiceRenderer =
  (showOverlayTag, showIcon = false) =>
  ({ value, model, ...rest }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    if (value && /[^-]/.test(value)) {
      return <OttServiceLink service={value} provider={model.get('service_provider')} showIcon={showIcon} {...rest} />;
    }

    return value;
  };

export const ottServiceTypeRenderer =
  (showOverlayTag, showIcon = true) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    if (value && /[^-]/.test(value)) {
      return <OttServiceTypeLink serviceType={value} showIcon={showIcon} />;
    }

    return value;
  };

export const countryRenderer =
  (showOverlayTag) =>
  ({ value, model, name, column }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }
    if (value && /[^-]/.test(value) && value !== 'Unassigned') {
      const key = column?.name || name;
      const countryCode = model.get(`${key}_raw`) || value;
      const display = model.get(key.toLowerCase()) || value;
      return <CountryLink country={countryCode}>{display}</CountryLink>;
    }
    return value;
  };

export const cityRenderer =
  (showOverlayTag) =>
  ({ value, model, name, column }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    const key = model.get('keyMetric') || column?.name || name;
    const isDst = model.has(key) ? key.includes('dst') : model.has('dst_geo_city');

    const country = isDst ? model.get('Geography_dst') : model.get('Geography_src');
    const region = isDst ? model.get('dst_geo_region') : model.get('src_geo_region');

    if (value && /[^-]/.test(value)) {
      return <CityLink city={value} country={country} region={region} />;
    }

    return value;
  };

export const regionRenderer =
  (showOverlayTag) =>
  ({ value, model, name, column }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    const key = model.get('keyMetric') || column?.name || name;
    const isDst = model.has(key) ? key.includes('dst') : model.has('dst_geo_region');

    const country = isDst ? model.get('Geography_dst') : model.get('Geography_src');

    if (value && /[^-]/.test(value)) {
      return <RegionLink region={value} country={country} />;
    }
    return value;
  };

export const inetFamilyRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    return <InetFamilyLink inetFamily={value} />;
  };

export const packetSizeRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    return <PacketSizeLink packetSize={value} />;
  };

export const routePrefixRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    return <RoutePrefixLink routePrefix={value} />;
  };

export const protocolRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    const digits = (value || '').match(/\d+/g);
    const protocol = digits ? digits[digits.length - 1] : '';

    return <ProtocolLink protocol={protocol}>{value}</ProtocolLink>;
  };

export const ipRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    return <IpLink ip_address={value} />;
  };

export const providerRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }
    if (value && /[^-]/.test(value)) {
      return <ProviderLink provider={value} />;
    }
    return value;
  };

export const applicationRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }
    if (value && /[^-]/.test(value)) {
      return <ApplicationLink application={value} />;
    }
    return value;
  };

export const bgpCommunityRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }
    if (value && /[^-]/.test(value)) {
      return <BgpCommunityLink bgpCommunity={value} />;
    }
    return value;
  };

/**
 * If metric = 'i_device_id'
 */
export const deviceNameRenderer =
  (showOverlayTag) =>
  ({ value, model: queryResultModel }) => {
    if (queryResultModel.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, queryResultModel);
    }

    return <DeviceLink name={value} />;
  };

/**
 * If metric = 'i_device_site_name'
 * The query engine returns us the Site "title", but that doesn't help us much when
 * we actually need the ID to link to the detail views.
 */
export const siteNameRenderer =
  (showOverlayTag) =>
  ({ value, model: queryResultModel }) => {
    if (queryResultModel.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, queryResultModel);
    }

    return (
      <CollectionLookup
        collection={$sites.collection}
        predicate={(model) => model.get('title') === value}
        value={value}
        fallback="Unassigned"
      >
        {({ model }) => <SiteLink siteId={model.id}>{model.get('title')}</SiteLink>}
      </CollectionLookup>
    );
  };

export const siteLocationRenderer = ({ model }) =>
  !model.get('unassigned') && (
    <>
      <div>{model.get('address')}</div>
      {model.get('city')}, {model.get('region')}, {model.get('country')}
    </>
  );

export const siteMarketRenderer =
  (showOverlayTag) =>
  ({ value, model: queryResultModel }) => {
    if (queryResultModel.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, queryResultModel);
    }

    return (
      <CollectionLookup
        collection={$siteMarkets.collection}
        predicate={(model) => model.get('name') === value}
        value={value}
        fallback="Unassigned"
      >
        {({ model }) => <Text>{model.get('name')}</Text>}
      </CollectionLookup>
    );
  };

/**
 * Utility for grabbing additional, optionally populated interface data from queryResultModel.
 * @param metricName Metric name in queryResultModel
 * @param queryResultModel
 * @returns {{}} Object with following fields sparsely populated depending on metric/queryResultModel.
 *  connectivityType
 *  networkBoundary
 *  provider
 */
function getInterfaceMetadata(metricName, queryResultModel) {
  const interfaceMetadata = {};
  if (metricName && queryResultModel) {
    // grab interface metadata if it's there.
    let interfaceMetadataFields;
    switch (metricName) {
      case 'output_port':
        interfaceMetadataFields = {
          connectivityTypes: 'i_dst_connect_type_name',
          networkBoundaryTypes: 'i_dst_network_bndry_name',
          provider: 'i_dst_provider_classification'
        };
        break;
      case 'input_port':
        interfaceMetadataFields = {
          connectivityTypes: 'i_src_connect_type_name',
          networkBoundaryTypes: 'i_src_network_bndry_name',
          provider: 'i_src_provider_classification'
        };
        break;
      case 'bgp_ult_exit_interface':
        interfaceMetadataFields = {
          connectivityTypes: 'i_ult_exit_connect_type_name',
          networkBoundaryTypes: 'i_ult_exit_network_bndry_name',
          provider: 'i_ult_provider_classification'
        };
        break;
      default:
        interfaceMetadataFields = {};
    }

    Object.keys(interfaceMetadataFields).reduce((acc, mdKey) => {
      const modelValue = queryResultModel.get(interfaceMetadataFields[mdKey]);
      if (modelValue && !['none', '---'].includes(modelValue)) {
        acc[mdKey] = modelValue;
      }
      return acc;
    }, interfaceMetadata);
  }
  return interfaceMetadata;
}

export const interfaceRenderer =
  (showOverlayTag) =>
  ({ value, model: queryResultModel, column, name }) => {
    if (queryResultModel.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, queryResultModel);
    }

    if (!value) {
      return null;
    }

    const regexResult = interfaceKeyRegex.exec(value);
    if (!regexResult) {
      return value;
    }

    const columnName = queryResultModel.get('keyMetric') || column?.name || name;
    const interfaceMetadata = getInterfaceMetadata(columnName, queryResultModel);
    const { groups } = regexResult;
    const deviceName =
      columnName === 'bgp_ult_exit_interface'
        ? queryResultModel.get('i_ult_exit_device_name')
        : queryResultModel.get('i_device_name');

    return (
      <div style={{ overflow: 'hidden' }}>
        <InterfaceLink {...groups} device_name={deviceName} />
        <InterfaceDescription snmp_alias={groups.snmp_alias} ellipsis={!column || !column.wrapText} />
        <InterfaceMetadata interfaceMetadata={interfaceMetadata} />
      </div>
    );
  };

export const networkBoundaryRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    return <NetworkBoundaryLink value={value} />;
  };

export const connectivityTypeRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    return <ConnectivityTypeLink value={value} />;
  };

export const cloudDetailRenderer =
  (showOverlayTag) =>
  ({ value, model, name, column, label }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }
    let detail;
    let cloud;

    const key = model.get('keyMetric') || column?.name || name;

    // get the detail field (region, zone, etc) based on metric
    Object.keys(cloudDetailMetrics).some((cloudDetailName) => {
      if (cloudDetailName === 'all') {
        return false;
      } // don't check all, waste of time.
      const isInGroup = cloudDetailMetrics[cloudDetailName].includes(key);
      if (isInGroup) {
        detail = cloudDetailName;
      }
      return isInGroup;
    });

    // determine provider by metric
    if (key.includes('_aws_')) {
      cloud = 'aws';
    } else if (key.includes('_gcp_') || key.includes('_gce_')) {
      cloud = 'gcp';
    } else if (key.includes('_az_') || key.includes('_azure_')) {
      cloud = 'azure';
    }
    if (detail && cloud && value && value !== '---') {
      return (
        <CloudDetailLink cloud={cloud} detail={detail} value={value}>
          {label}
        </CloudDetailLink>
      );
    }
    return value;
  };

export const kappaRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    return (
      <Flex alignItems="center">
        <Icon icon={KubePodIcon} iconSize={14} mr="4px" />
        <Text>{value}</Text>
      </Flex>
    );
  };

export const asnRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }
    const asnRegEx = /(?<name>.*?)(?:,(?<country>[A-Z]{2}))?\s\((?<asn>[^)]*)\)$/;
    const match = ((value && value.toString()) || '').match(asnRegEx);
    const { name, country, asn = '' } = match?.groups || {};
    const asns = asn.split(/,\s*/);

    if (!name || !asns.length) {
      return value;
    }

    if (asns && asns.length > 1) {
      return <ASGroupRenderer name={name} showLinks showNameAsLink />;
    }

    return <ASNRenderer asn={asns[0]} name={name} country={country} showLinks showNameAsLink />;
  };

export const asPathRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    if (value && typeof value === 'string') {
      const asns = value.split(' ');
      const validAspath = /[^-]/.test(value);

      return (
        <Flex alignItems="center">
          <Text as="div" mr="2px">
            {validAspath ? <AsPathLink asPath={value} /> : value}
          </Text>

          {validAspath && (
            <Popover position="bottom-left" minimal={false}>
              <Button small minimal icon={MdFormatListBulleted} />
              <Box p={1}>
                <Box mb={1}>
                  <Text>AS Path Breakdown</Text>
                </Box>
                {asns.map((asn, i) => (
                  <Flex key={uniqueId()} justifyContent="space-between">
                    <Text muted pr={2}>
                      {i === 0 ? 'Next' : `${i + 1}${getOrdinalSuffix(i + 1)}`} Hop
                    </Text>
                    <AsnLink key={asn} asn={asn}>
                      <Text fontWeight="bold">{asn}</Text>
                    </AsnLink>
                  </Flex>
                ))}
              </Box>
            </Popover>
          )}
        </Flex>
      );
    }

    return null;
  };

export const cloudRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    const providers = {
      aws: 'Amazon Web Services (AWS)',
      gcp: 'Google Cloud Platform (GCP)',
      azure: 'Microsoft Azure'
    };

    return (value && value.toLowerCase && providers[value.toLowerCase()]) || value;
  };

export const valueOrNameRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }
    return value;
  };

export const hideRawValueRenderer =
  (showOverlayTag) =>
  ({ value, model }) => {
    if (model.get('isOverlay')) {
      return overlayRenderer(showOverlayTag, model);
    }

    return (
      (value &&
        value
          .toString()
          .replace(/\((.*)\)/g, '')
          .trim()) ||
      value
    );
  };

export const nonToggleableColorRenderer = ({ model }) => {
  const color = model.get('color');
  const rawData = model.get('rawData');

  if (!rawData || !color) {
    return null;
  }

  return (
    <Box
      alignSelf="center"
      ml="5.6px"
      mr="5.6px"
      bg={color}
      border={`2px solid ${color}`}
      borderRadius={8}
      height={14}
      width={14}
    />
  );
};

export const toggleRenderer = ({ model }) => {
  const color = model.get('color');
  const { toggled } = model;
  const rawData = model.get('rawData');

  if (!rawData || !color) {
    return null;
  }

  const handleClick = (evt) => {
    evt.stopPropagation();
    model.toggled = !toggled;
  };

  return (
    <Button
      small
      alignSelf="center"
      onClick={handleClick}
      title={toggled ? 'Click to enable this row' : 'Click to disable this row'}
      minimal
    >
      <Box bg={toggled ? 'transparent' : color} border={`2px solid ${color}`} borderRadius={8} height={14} width={14} />
    </Button>
  );
};

export const bracketRenderer = ({ model, column }) => {
  const tagDataKey = Object.keys(model.toJS()).find((prop) => prop.startsWith('tag_'));
  const tagInfo = tagDataKey ? model.get(tagDataKey) : undefined;
  if (!tagInfo) {
    return null;
  }
  const { value, bracketLabel, bracketAggregationDisplay, bracketAggregationDisplayUnit } = toJS(tagInfo);
  // not sure if this is how we want to show bracket range, but will work for now.
  return (
    <Tooltip
      content={
        <span>
          {bracketLabel} <br /> ({bracketAggregationDisplay} {bracketAggregationDisplayUnit})
        </span>
      }
    >
      <div
        style={{
          borderColor: value,
          backgroundColor: value,
          width: column.width,
          height: column.height
        }}
      />
    </Tooltip>
  );
};

export const getAggregateRenderer = ({ aggregate, prefix, bucket, emptyText, isComparisonSummary }) => {
  const show_total_overlay = bucket.firstQuery.get('show_total_overlay');
  const period_over_period = bucket.firstQuery.get('period_over_period');
  const unit = aggregate.parentUnit || aggregate.unit;

  if (unit.includes('sample_rate')) {
    return sampleRateValueRenderer(false);
  }

  if (period_over_period && !isComparisonSummary) {
    return deltaRenderer({
      fix: aggregate.fix || getToFixed(unit),
      prefix: prefix[unit],
      emptyText
    });
  }
  if (interfacePercentageMetrics.includes(aggregate.unit)) {
    return percentRenderer({ fix: aggregate.fix || getToFixed(unit), prefix: prefix[unit], emptyText });
  }
  if (show_total_overlay && aggregate.fn === 'average' && !isComparisonSummary) {
    return averageRenderer({
      fix: aggregate.fix || getToFixed(unit),
      prefix: prefix[unit],
      query: bucket.firstQuery,
      emptyText
    });
  }

  return valueRenderer({
    fix: aggregate.fix || getToFixed(unit),
    prefix: prefix[unit],
    emptyText
  });
};

export const sparklineRenderer = ({ model }) => (
  <Box width={85} pr={2}>
    <Sparkline data={model.sparklineData} height={60} strokeWidth={0.5} color="primary" />
  </Box>
);

export const timestampMsRenderer = ({ value }) => formatDateTime(value, 'YYYY-MM-DD HH:mm:ss.SSS');

export const durationMsRenderer = ({ value }) => formatDuration(value / 1000);
