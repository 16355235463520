import { Rect } from './rect';

export default function (structure, options) {
  const { nodes, numberOfColumns } = structure;
  const { width: minWidth } = options;

  // Define horizontal positioning for structure columns
  structure.columnKeys.reduce((acc, curr) => {
    const column = structure.columns.get(curr);
    column.left = acc;

    return (acc += structure.gutterWidth + column.width);
  }, 0);

  // Define node positioning
  Array.from(structure.agents.keys()).forEach((agentKey) => {
    const agent = structure.agents.get(agentKey);
    let agentHeight = 0;

    // loop over target groups by agent
    Array.from(agent.targets.values()).forEach((target) => {
      agentHeight += target.height;
      const targetColumnKeys = Array.from(target.columns.keys()).sort((a, b) => a - b);

      // loop over group columns
      targetColumnKeys.forEach((key) => {
        const targetColumn = target.columns.get(key);
        const column = structure.columns.get(key);
        // default starting position to half the available vertical space
        let relativeNodeTop = (target.height - targetColumn.height) / 2;

        // loop over column rows
        targetColumn.nodes.forEach(({ id }) => {
          const node = nodes.get(id);
          let left;

          if (node.type === 'agent') {
            left = 0;
          } else {
            // handle closed paths
            const closedPath = column === 1 && node.type === 'closed_path';
            const closedPathLinkWidth =
              (minWidth - (structure.maxTargetWidth + structure.columns.get(0).width + node.size[0])) / 2;

            // define node bounds
            left = closedPath
              ? structure.columns.get(0).width + closedPathLinkWidth
              : column.left + (column.width - node.size[0]) / 2;
          }

          const top = structure.height + agentHeight - target.height + relativeNodeTop;

          nodes.get(node.id).bounds = new Rect(left, top, left + node.size[0], top + node.size[1]);

          // start next node in column at the end of this one
          relativeNodeTop += node.height + structure.gutterHeight;
        });
      });

      agentHeight += structure.gutterHeight;
    });

    // update structure height, will be used to define the starting position of the next agent
    structure.height += agentHeight;
  });

  // adjust structure height
  const targetHeight =
    structure.targets.reduce((acc, curr) => (acc += curr.height + structure.gutterHeight), 0) - structure.gutterHeight;

  if (targetHeight > structure.height) {
    structure.height = targetHeight;
  }

  // Define target positioning
  structure.targets.forEach((target, i) => {
    const ySpace = structure.height - targetHeight;
    const yOffset = ySpace ? ySpace / 2 - target.size[1] / 2 : 0;
    let top = yOffset + (target.height + structure.gutterHeight) * i;
    const xSpace = structure.maxTargetWidth - target.size[0];
    const xOffset = xSpace ? xSpace / 2 : 0;
    const xWidth = structure.width || minWidth;
    const left = xWidth - structure.maxTargetWidth + xOffset;

    if (top < 5) {
      top = 5;
    }

    nodes.get(target.id).bounds = new Rect(left, top, left + target.size[0], top + target.size[1]);
  });

  // Define link positioning
  const links = Object.keys(structure.links).reduce((acc, curr) => {
    const node = nodes.get(curr);
    const { cy: y0, right: x0 } = node.bounds;

    structure.links[curr].forEach((link, key) => {
      const toNode = nodes.get(key);
      const { cy: y1, left: x1 } = toNode.bounds;
      // add first set of points
      const points = [[x0, y0]];

      // add in virtual points
      const toColumn = toNode.type === 'target' ? numberOfColumns : toNode.column;
      const columnSpan = toColumn - node.column;
      const virtualId = `${node.id}_${toNode.id}`;

      if (node.type === 'agent' && toNode.type === 'closed_path') {
        const { left, width } = structure.columns.get(toColumn);

        points.push([left + width / 2, y1]);
      }

      if (columnSpan > 1 && !(toNode.type === 'target' && node.type === 'closed_path')) {
        for (let i = node.column + 1; i < toColumn; i += 1) {
          const { cy } = nodes.get(`${virtualId}_${i}`).bounds;
          const { left, width } = structure.columns.get(i);

          points.push([left, cy]);
          points.push([left + width, cy]);
        }
      }

      // adjust closed_path link to target
      if (node.type === 'closed_path' && toNode.type === 'target') {
        const lastColumn = structure.columns.get(numberOfColumns - 1);
        points.push([lastColumn.left + lastColumn.width / 2, y0]);
      }

      // add final set of points
      points.push([x1, y1]);

      // store new link
      acc.push({
        ...structure.links[curr].get(key),
        from: curr,
        to: key,
        points,
        type: toNode.type
      });
    });

    return acc;
  }, []);

  return {
    bounds: new Rect(0, 0, structure.width, structure.height),
    nodes: Array.from(nodes.values()).filter((node) => node.type !== 'virtual'),
    links
  };
}
