import { action } from 'mobx';
import api from 'core/util/api';
import RawFlowQueryModel from 'app/stores/query/RawFlowQueryModel';
import { nestFilterGroup } from 'core/util/filters';

import AbstractDataViewModel from 'app/stores/query/AbstractDataViewModel';
import { getQueriesForHash } from 'app/stores/query/urlHash';
import { fields as rawFlowFormFields } from 'app/forms/config/rawFlow';

import { getDuration } from '@kentik/ui-shared/query/queryUtils';
import ConfigureRawFlowForm from './ConfigureRawFlowForm';

export default class RawFlowDataViewModel extends AbstractDataViewModel {
  constructor(viewModel) {
    super();
    this.viewModel = viewModel || new RawFlowQueryModel();
  }

  get formConfig() {
    return rawFlowFormFields;
  }

  get formComponent() {
    return ConfigureRawFlowForm;
  }

  get isFlowVisualization() {
    return false;
  }

  get viewType() {
    return 'rawFlow';
  }

  get viewName() {
    return 'Raw Flow';
  }

  get viewShowTitleLink() {
    return false;
  }

  get queryTooLongError() {
    return 'Raw Flow query duration can not exceed 1 week.';
  }

  @action
  setTitle = (title) => {
    this.dvTitle = title;
  };

  @action
  refresh = async () => {
    this.loading = true;
    const rawFlowQuery = await getQueriesForHash(this.hash, { normalize: false });
    if (this.dashboardQuery) {
      this.overlaySettings(rawFlowQuery, this.dashboardQuery);
    }

    if (this.parametricOverrides) {
      this.overlayParametricFilters(rawFlowQuery, this.parametricOverrides);
    }

    if (getDuration(rawFlowQuery) > 7 * 24 * 60 * 60) {
      this.viewModel.set({ error: this.queryTooLongError });
      return;
    }

    const rawFlowData = await this.fetchRawFlowData(rawFlowQuery);
    this.viewModel.set({
      id: this.hash,
      error: undefined,
      rawFlowQuery,
      ...rawFlowData
    });
    this.loading = false;
  };

  fetchRawFlowData = (queryData) => {
    const data = Object.assign({}, queryData, {
      filters: queryData.filters,
      devices: queryData.device_name
    });
    return api.post('/api/ui/raw-flow', { data }).catch(() => null);
  };

  @action
  initializeHash = async (hash) => {
    this.loading = true;
    if (hash) {
      this.hash = hash;
    }
    await this.refresh();
    this.setFullyLoaded();
    return this.viewModel.get();
  };

  @action
  addFilters(filters, filters_overlay) {
    if (filters) {
      if (filters.connector === 'Any') {
        nestFilterGroup(filters);
      }
      if (filters_overlay && filters_overlay.filterGroups && filters_overlay.filterGroups.length) {
        if (filters_overlay.connector === 'Any') {
          nestFilterGroup(filters_overlay);
        }
        filters.filterGroups = (filters.filterGroups || []).concat(filters_overlay.filterGroups);
      }
      return filters;
    }
    return filters_overlay;
  }

  @action
  addSavedFilters(saved_filters1, saved_filters2) {
    return (saved_filters1 || []).concat(saved_filters2 || []);
  }

  @action
  overlaySettings(rawFlowQuery, query) {
    const { time_locked, device_locked, filter_source } = rawFlowQuery;
    if (!time_locked) {
      const { starting_time, ending_time, lookback_seconds, time_format } = query;
      Object.assign(rawFlowQuery, { starting_time, ending_time, lookback_seconds, time_format });
    }
    if (!device_locked) {
      const { all_devices, device_labels, device_name, device_sites, device_types } = query;
      Object.assign(rawFlowQuery, { all_devices, device_labels, device_name, device_sites, device_types });
    }
    let dashboardFilters;
    if (filter_source === 'dashboard') {
      const { filters, saved_filters } = query;
      dashboardFilters = filters;
      Object.assign(rawFlowQuery, { filters: dashboardFilters, saved_filters });
    } else if (filter_source === 'additive') {
      const { filters, saved_filters } = query;
      dashboardFilters = filters;
      rawFlowQuery.filters = this.addFilters(rawFlowQuery.filters, dashboardFilters);
      rawFlowQuery.saved_filters = this.addSavedFilters(rawFlowQuery.saved_filters, saved_filters);
    }
  }

  @action
  overlayParametricFilters(rawFlowQuery, parametricOverrides) {
    const { filters } = rawFlowQuery;

    if (filters) {
      if (filters.connector === 'Any') {
        nestFilterGroup(filters);
      }
      let filtersObj;
      if (parametricOverrides && parametricOverrides.filters) {
        filtersObj = parametricOverrides.filters;
      } else {
        filtersObj = filters;
      }

      if (filtersObj && filtersObj.filterGroups && filtersObj.filterGroups.length) {
        if (filtersObj.connector === 'Any') {
          nestFilterGroup(filtersObj);
        }

        filtersObj.filterGroups = (filters.filterGroups || []).concat(filtersObj.filterGroups);

        /**
         * If override_specific, go through filtersObj and override any of the filterFields
         * which matched what the user selected
         * */
        if (parametricOverrides && parametricOverrides.specific) {
          filtersObj.filterGroups.forEach((group) => {
            group.filters.forEach((filter) => {
              if (filter.filterField === parametricOverrides.filterField) {
                filter.filterValue = parametricOverrides.filterValue;
              }
            });
          });
        }

        rawFlowQuery.filters = filtersObj;
      } else {
        rawFlowQuery.filters = filters;
      }
    }
  }

  /**
   * @param {string} hash
   * @param {object} overrides
   * @param {{ syncDepth?: boolean, overlayFilters?: boolean, parametricOverrides?: object }} [options]
   */
  @action
  initializeHashWithOverrides = async (saved_query_id, query, { parametricOverrides } = {}) => {
    this.loading = true;
    this.hash = saved_query_id;
    this.dashboardQuery = query;
    this.parametricOverrides = parametricOverrides;
    await this.refresh();
    this.setFullyLoaded();
    return this.viewModel.get();
  };

  setUpdateFrequency(frequency = 0) {
    if (frequency) {
      this.updateFrequency = setInterval(this.refresh, frequency * 1000);
    } else if (this.updateFrequency) {
      clearInterval(this.updateFrequency);
      delete this.updateFrequency;
    }
  }

  destroy() {
    clearInterval(this.updateFrequency);
  }
}
