import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import { MenuItem, MenuDivider, Text } from 'core/components';

@inject('$dashboard', '$dataviews', '$exports')
@observer
export default class ExportMenuItem extends Component {
  generateSeriesMenuItems() {
    const { $dataviews, $exports, dataview } = this.props;
    const result = [];

    Object.values(dataview.component?.dataviewModels || {}).forEach((model) => {
      const { queryBuckets, key, viewType: generatorViewType } = model;
      const { timeBased: generatorTimeBased } = $dataviews.getConfig(generatorViewType);

      result.push(
        ...queryBuckets.activeBuckets.map((b) => {
          const name = b.get('name');
          let keyLabel = key.length > 60 ? `${key.substring(0, 60)}...` : key;

          if (queryBuckets.activeBuckets.length > 1) {
            keyLabel = (
              <Text ellipsis display="inline-block" minWidth={name.length * 8 + 80} paddingRight={50}>
                <strong>{name}</strong> {keyLabel}
              </Text>
            );
          }

          return (
            <MenuItem
              key={`${key}${name}ChartCsv`}
              icon="series-derived"
              label=".csv"
              text={keyLabel}
              onClick={() => $exports.exportBucketCsv(dataview, b, generatorTimeBased)}
            />
          );
        })
      );
    });

    return result;
  }

  getCsvExportOptions(timeBased) {
    const { $exports, dataview } = this.props;
    const { activeBuckets } = dataview.queryBuckets;
    const [firstBucket] = activeBuckets;

    if (dataview.viewType === 'generator') {
      return [
        <MenuDivider key="generatorDataTitle" title="Data" />,
        ...this.generateSeriesMenuItems(),
        <MenuItem
          key="generatorLegendCsv"
          text="Data Table"
          icon="th"
          label=".csv"
          onClick={() => $exports.exportBucketCsv(dataview, firstBucket)}
        />
      ];
    }

    if (activeBuckets.length && activeBuckets.length > 1) {
      return activeBuckets.reduce((arr, bucket) => {
        const { name } = bucket.get();

        arr.push(
          <MenuDivider key={`${name}Title`} title={name} />,
          <MenuItem
            key={`${name}ChartCsv`}
            text="Chart Data"
            icon="th"
            label=".csv"
            onClick={() => $exports.exportBucketCsv(dataview, bucket, timeBased)}
          />,
          <MenuItem
            key={`${name}LegendCsv`}
            text="Data Table"
            icon="th"
            label=".csv"
            onClick={() => $exports.exportBucketCsv(dataview, bucket)}
          />
        );

        return arr;
      }, []);
    }

    return [
      <MenuDivider key="dataTitle" title="Data" />,
      <MenuItem
        key="chartDataCsv"
        text="Chart Data"
        icon="th"
        label=".csv"
        onClick={() => $exports.exportBucketCsv(dataview, firstBucket, timeBased)}
      />,
      <MenuItem
        key="legendDataCsv"
        text="Data Table"
        icon="th"
        label=".csv"
        onClick={() => $exports.exportBucketCsv(dataview, firstBucket)}
      />
    ];
  }

  render() {
    const { $dashboard, $dataviews, $exports, dataview, dashboard, toggleGuidedDialog } = this.props;

    const time_format = dataview.queryBuckets.selectedQuery?.get('time_format');
    const urlParams = time_format === 'Local' ? { time_format: moment().utcOffset() } : null;
    const path = `/api/ui/export/explorer/${dataview.hash}`;
    const fileName = `export-${dataview.hash}`;
    const { timeBased, isSVG } = $dataviews.getConfig(dataview.viewType);
    const csvItems = this.getCsvExportOptions(timeBased);

    return (
      <MenuItem icon="export" text="Export" popoverProps={{ openOnTargetFocus: false }}>
        {dashboard ? (
          <MenuItem
            icon="document"
            text="Visual Report"
            label=".pdf"
            onClick={() => (dashboard.isParametric ? toggleGuidedDialog() : $dashboard.export({ dashboard }))}
          />
        ) : (
          <>
            <MenuDivider title="Visual Report" />
            <MenuItem
              icon="document"
              text="Chart + Data Table"
              label=".pdf"
              onClick={() =>
                $exports.fetchExport({
                  path,
                  fileName,
                  urlParams,
                  type: 'pdf',
                  viewType: 'explorer',
                  viewId: dataview.hash
                })
              }
            />
            <MenuItem
              icon="media"
              text="Chart Image"
              label=".png"
              onClick={() =>
                $exports.fetchExport({
                  path,
                  fileName,
                  urlParams,
                  type: 'png',
                  viewType: 'explorer',
                  viewId: dataview.hash
                })
              }
            />
            <MenuItem
              icon="media"
              disabled={!isSVG}
              text="Chart Vector"
              label=".svg"
              onClick={() =>
                $exports.fetchExport({
                  path,
                  fileName,
                  urlParams,
                  type: 'svg',
                  viewType: 'explorer',
                  viewId: dataview.hash
                })
              }
            />
            {csvItems}
          </>
        )}
      </MenuItem>
    );
  }
}
