import React, { Component } from 'react';
import { transparentize } from 'polished';
import { withTheme } from 'styled-components';

import { Flex, Button, Box, Callout, Heading, Tabs, Tab, Card, Tag, Text } from 'core/components';
import { FormComponent } from 'core/form';
import { DEFAULT_PALETTES } from 'app/stores/colors/Colors';
import EditSiteArchitecture from 'app/views/settings/sites/EditSiteArchitecture';

const architectureTemplates = [
  {
    name: 'Data Center',
    templates: [
      {
        name: '3 Stage Clos Data Center Fabric',
        description: 'A multi-layer fabric architecture consisting of Spine, Leaf, and Top of Rack (Access) devices.',
        layers: ['Spine', 'Leaf', 'Top of Rack']
      },
      {
        name: '4 Stage Clos Data Center Fabric',
        description:
          'A multi-layer fabric architecture consisting of Super Spine, Spine, Leaf, and Top of Rack (Access) devices.',
        layers: ['Super Spine', 'Spine', 'Leaf', 'Top of Rack']
      },
      {
        name: '3-Tier Data Center',
        description: 'A hierarchical 3-tier design consisting of Core, Aggregation and Access layers.',
        layers: ['Core', 'Aggregation', 'Access']
      }
    ]
  },
  {
    name: 'PoP',
    templates: [
      {
        name: 'Edge PoP',
        description:
          'A simple routing design consisting of one or more edge/border routers, chiefly supporting interconnectivity with external networks (this design accommodates “collapsed core/edge” devices).',
        layers: ['Edge']
      },
      {
        name: 'Edge/Core PoP',
        description:
          'A simple routing site consisting of edge/border routers, and core devices that interconnect with other network sites/devices.',
        layers: ['Edge', 'Core']
      },
      {
        name: 'Core',
        description: 'An internal aggregation point consisting of core devices, with no external connectivity.',
        layers: ['Core']
      }
    ]
  },
  {
    name: 'Enterprise Network',
    templates: [
      {
        name: 'Campus Core',
        description: 'A network designed to facilitate interconnection between two or more sites.',
        layers: ['Border', 'Core', 'Aggregation']
      },
      {
        name: 'Branch/Building',
        description:
          'An internal aggregation point consisting of one or more devices, optionally connected to internal switching, external networks/internet, or other WAN connections.',
        layers: ['Border', 'Distribution', 'Access']
      }
    ]
  },
  {
    name: 'Other',
    templates: [
      {
        name: 'Custom Template',
        description: 'Define your own architecture.',
        layers: ['Layer 1', 'Layer 2', 'Layer 3']
      }
    ]
  }
];

const colors = ['none', 'success', 'warning', 'primary'];

const fields = {
  layers: {
    isComplexArray: true
  },
  'layers[].subLayers': {
    isComplexArray: true
  },
  'layers[].subLayers[].name': { placeholder: 'Layer', label: 'Layer', rules: 'required' },
  'layers[].subLayers[].devices': { placeholder: 'Add devices', label: 'Devices' }
};
const options = { name: 'Site Architecture' };

@withTheme
export default class SiteArchitectureDialog extends Component {
  state = {
    architecture: undefined,
    selectedTemplate: undefined
  };

  layers;

  constructor(props) {
    super(props);
    if (props.architecture && props.architecture.length > 0) {
      this.state.architecture = { layers: props.architecture };
    }
  }

  getColor = (layers, idx) => {
    // Ensure all tags are gray for "Custom Template"
    if (layers[0] === 'Layer 1') {
      return 'none';
    }

    if (layers.length === 4) {
      return colors[idx];
    }

    return colors[idx + 1];
  };

  getStyle = (layers, idx) => {
    if (layers.length === 4 && idx === 0) {
      return {
        background: transparentize(0.85, DEFAULT_PALETTES.purpleHue2[5]),
        color: DEFAULT_PALETTES.purpleHue2[5]
      };
    }
    return undefined;
  };

  onTemplateSelect = (selectedTemplate) => {
    const layers = selectedTemplate.layers.map((layer) => ({ subLayers: [{ name: layer, devices: [] }] }));

    const architecture = {
      layers
    };

    this.layers = layers;

    this.setState({ architecture });
  };

  handleSiteArchitectureChange = ({ formValues }) => {
    this.layers = formValues.layers;
  };

  onSave = () => {
    const { onSave, onClose } = this.props;

    // check for layers before saving to prevent blasting away all architecture settings
    if (this.layers) {
      onSave(this.layers);
    } else {
      onClose();
    }
  };

  renderTemplateTab = (architecture) => {
    const { theme } = this.props;
    const { selectedTemplate } = this.state;

    return (
      <Flex flexWrap="wrap">
        {architecture.templates.map((template) => (
          <Card
            key={template.name}
            width={350}
            mr={2}
            mb={2}
            p={2}
            boxShadow={selectedTemplate === template ? `0 0 0 2px ${theme.colors.primary}` : 'none'}
            interactive={!(selectedTemplate === template)}
            onClick={() => this.onTemplateSelect(template)}
          >
            <Flex>
              <Flex flexDirection="column" mr={2} mb={-1}>
                {template.layers.map((layer, idx) => (
                  <Tag
                    as={Box}
                    mb={1}
                    minWidth={80}
                    key={layer}
                    minimal
                    style={this.getStyle(template.layers, idx)}
                    intent={this.getColor(template.layers, idx)}
                  >
                    {layer}
                  </Tag>
                ))}
              </Flex>
              <Box>
                <Text as="div" fontSize={14} fontWeight="bold">
                  {template.name}
                </Text>
                <Text muted>{template.description}</Text>
              </Box>
            </Flex>
          </Card>
        ))}
      </Flex>
    );
  };

  render() {
    const { onClose, devices } = this.props;
    const { architecture } = this.state;

    return (
      <>
        {!architecture && (
          <Box>
            <Callout mb={2}>
              <Heading level={6}>Site Architecture and Device Roles</Heading>
              Help us accurately and completely render your site architecture by telling us the layers of your site and
              the devices assigned to those layers. Start by clicking the customizable template below that&#39;s closest
              to your situation, or customize your own.
            </Callout>
            <Tabs>
              {architectureTemplates.map((architectureTemplate) => (
                <Tab
                  key={architectureTemplate.name}
                  id={architectureTemplate.name}
                  title={architectureTemplate.name}
                  panel={this.renderTemplateTab(architectureTemplate)}
                />
              ))}
            </Tabs>
          </Box>
        )}
        {architecture && (
          <FormComponent
            fields={fields}
            options={options}
            model={architecture}
            onChange={this.handleSiteArchitectureChange}
          >
            <Callout mb={2}>
              Each box below represents a layer in your site. Use the fields in each box to assign devices to the layers
              and to rename the layers as needed. You can also move layers up or down in level and add layers between or
              at the same level as existing layers.
            </Callout>
            <EditSiteArchitecture devices={devices} />
          </FormComponent>
        )}
        <Flex justifyContent={architecture ? 'flex-end' : 'space-between'} mt={2} mb="-16px">
          <Button text={architecture ? 'Cancel' : 'Back'} onClick={onClose} />
          {architecture && <Button intent="primary" text="Save Architecture" ml={1} onClick={this.onSave} />}
        </Flex>
      </>
    );
  }
}
