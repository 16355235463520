import React, { Component } from 'react';
import { CustomPicker, GithubPicker } from 'react-color';

import { labelColors } from 'app/stores/colors/Colors';
import { findClosestMatch } from '../util/colors';

/* eslint-disable react/prefer-stateless-function */
class ColorPicker extends Component {
  render() {
    const { color, onChange, width = 112 } = this.props;

    const closestColor = findClosestMatch(color || labelColors[0], labelColors);

    return (
      <GithubPicker
        width={width}
        style={{ padding: 3, border: 'none', boxShadow: 'none', borderRadius: 0 }}
        onChange={onChange}
        color={closestColor}
        colors={labelColors}
      />
    );
  }
}

export default CustomPicker(ColorPicker);
