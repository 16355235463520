import React from 'react';

import FormComponent from './FormComponent';

function Form({ fields, options = {} }) {
  return (WrappedComponent) =>
    ({ model, values, handleSave, handleCancel, handleRemove, ...rest }) => (
      <FormComponent
        fields={fields}
        options={options}
        model={model}
        values={values}
        handleSave={handleSave}
        handleCancel={handleCancel}
        handleRemove={handleRemove}
      >
        <WrappedComponent {...rest} />
      </FormComponent>
    );
}

export default Form;
