import { Model } from 'core/model';

export default class PeeringNetworkModel extends Model {
  get type() {
    return 'network';
  }

  get sortValues() {
    return { name: () => this.get('name').toLowerCase() };
  }
}
