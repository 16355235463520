import { toJS } from 'mobx';
import mergeFilterGroups from './mergeFilterGroups';

export default function getQueryMapFromAggregateFilterQuery(aggregateFilterQuery) {
  const aggregateFilters = toJS(aggregateFilterQuery.get('aggregateFilters'));
  const filtersObj = toJS(aggregateFilterQuery.get('filters'));
  const queryMap = {};

  aggregateFilters.forEach((filterGroup) => {
    const { metric: filterGroupMetric, name, ...filterGroupContents } = filterGroup;
    const query = aggregateFilterQuery.serialize();

    query.descriptor = name;
    if (filterGroupMetric) {
      query.metric = toJS(filterGroupMetric);
    }
    if (Object.keys(filterGroupContents).length > 0) {
      query.filters = mergeFilterGroups(filtersObj, {
        connector: 'All',
        filterGroups: [filterGroupContents]
      });
    } else {
      query.filters = {
        connector: 'All',
        filterGroups: [
          {
            autoAdded: '',
            connector: 'All',
            filterGroups: [],
            filters: [],
            name: '',
            named: false,
            not: false,
            saved_filters: []
          }
        ]
      };
    }

    queryMap[name] = query;
  });
  return queryMap;
}
