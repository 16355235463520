function getGroupFilterCount(filterGroup = {}) {
  const { filters = [], saved_filters = [], filterGroups = [] } = filterGroup;
  return (
    filters.length + saved_filters.length + filterGroups.reduce((sum, group) => sum + getGroupFilterCount(group), 0)
  );
}

function getFilterCount(filterGroups) {
  return (filterGroups || []).reduce((sum, group) => sum + getGroupFilterCount(group), 0);
}

export default getFilterCount;
