import React from 'react';
import { Sparklines, SparklinesCurve, SparklinesBars } from 'react-sparklines';
import styled, { withTheme } from 'styled-components';

const Wrap = styled.span`
  /* fixes an svg spacing issue */
  svg {
    margin-bottom: -5px;
  }
`;

function Sparkline({ color, theme, strokeWidth, fill, bars, children, ...rest }) {
  const themeColor = theme.colors[color] || color;
  const fillColor = theme.colors[fill] || fill;

  const SparklinesComp = bars ? SparklinesBars : SparklinesCurve;

  return (
    <Wrap>
      <Sparklines {...rest}>
        <SparklinesComp color={themeColor} style={{ strokeWidth, stroke: themeColor, fill: fillColor }} />
      </Sparklines>
    </Wrap>
  );
}

Sparkline.defaultProps = {
  data: [],
  margin: 2,
  height: 12,
  strokeWidth: 1,
  bars: false,
  color: 'primary',

  // prevents clipping at the bottom of the sparkline
  min: 0
};

export default withTheme(Sparkline);
