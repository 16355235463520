import React from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import Highcharts from 'highcharts';
import Heatmap from 'highcharts/modules/heatmap';

import { Flex, Box, Card, Dialog } from 'core/components';
import { greekPrefix } from 'core/util';
import DataViewModel from 'app/stores/query/DataViewModel';
import QueryModel from 'app/stores/query/QueryModel';
import { getToFixed, zeroToText, adjustByGreekPrefix, escapeHtml } from 'app/util/utils';
import { getBracketingDataClasses } from 'app/util/bracketing';
import BaseHighchartsDataview from './BaseHighchartsDataview';
import DataViewTools from '../DataViewTools';
import DataViewWrapper from '../DataViewWrapper';
import { ChangeViewTypeMenu } from '../tools';

Heatmap(Highcharts);

@inject('$app', '$dictionary')
@observer
class MatrixView extends BaseHighchartsDataview {
  state = {
    subview: null
  };

  constructor(props) {
    super(props);

    const { theme } = props;

    this.chartOptions = {
      chart: {
        className: 'matrix'
      },
      title: {
        text: ''
      },
      legend: {
        margin: 0,
        symbolHeight: 300,
        layout: 'vertical',
        verticalAlign: 'bottom',
        align: 'right'
      },
      colorAxis: {
        min: 0,
        minColor: theme.colors.appBackground,
        maxColor: theme.colors.orange3
      },
      xAxis: {
        categories: [],
        opposite: true,
        title: {
          text: ''
        },
        labels: {
          rotation: 0
        },
        gridZIndex: 3
      },
      yAxis: {
        categories: [],
        reversed: true,
        title: {
          text: ''
        },
        gridZIndex: 3
      },
      series: [
        {
          type: 'heatmap',
          name: '',
          allowPointSelect: false,
          cursor: 'pointer',
          data: [],
          dataLabels: {
            enabled: true,
            allowOverlap: true,
            overflow: 'allow',
            formatter() {
              const { point } = this;
              const { metadata } = point.options;
              return escapeHtml(zeroToText(adjustByGreekPrefix(point.value, metadata.prefix), { fix: metadata.fix }));
            }
          },
          point: {
            events: {
              click() {
                const { options } = this.series;
                const { dataview } = options;

                dataview.setState({ subview: new DataViewModel() });
                const parentQuery = JSON.parse(
                  JSON.stringify(dataview.props.dataview.queryBuckets.activeBuckets[0].firstQuery.toJS())
                );
                parentQuery.metric = parentQuery.metric.concat(parentQuery.matrixBy);
                parentQuery.matrixBy = [];
                parentQuery.viz_type = 'sankey'; // 'line';
                parentQuery.bucket = 'Flow'; // 'Left +Y Axis';
                // TODO: other options with filters ?
                const query = QueryModel.create();
                query.set(parentQuery);
                dataview.state.subview.initializeQueries([
                  {
                    bucket: 'Flow', // 'Left +Y Axis',
                    query: query.serialize()
                  }
                ]);
              }
            }
          }
        }
      ],
      tooltip: {
        formatter() {
          const { point } = this;
          const { metadata } = point.options;
          const { prefix, displayUnit, fix } = metadata;
          return `<b>${this.series.yAxis.categories[this.point.y]} \u2192 ${
            this.series.xAxis.categories[this.point.x]
          }</b><br/>${escapeHtml(
            zeroToText(adjustByGreekPrefix(point.value, prefix), { fix })
          )} ${prefix}${displayUnit}`;
        }
      }
    };
  }

  componentDidUpdate() {
    const { $app, dataview } = this.props;
    const { activeBuckets, activeBucketCount } = dataview.queryBuckets;
    if (!activeBucketCount || !activeBuckets[0].queryResults.size || !this.chart) {
      return;
    }

    this.clear();
    const row = activeBuckets[0].queryResults.at(0);
    const matrix = row.get('matrix');
    if (matrix && this.chart.xAxis && this.chart.yAxis) {
      // assumes matrix implies primary and secondary are present
      this.chart.yAxis[0].setCategories(row.get('primary'), false);
      this.chart.xAxis[0].setCategories(row.get('secondary'), false);
      const matrixMetadata = this.matrixMetadata(matrix);
      this.chart.series[0].setData(
        matrix.map((map) => ({ x: map[1], y: map[0], value: map[2], metadata: matrixMetadata })),
        false
      );

      // TODO: for now ignore where specific type of tag_
      const tagDataKey = Object.keys(row.toJS()).find((prop) => prop.startsWith('tag_'));
      const tagData = tagDataKey ? row.get(tagDataKey) : null;
      // add bracketing dataclasses
      if (tagData && tagData.isMatrix) {
        const { metadata, bracketOptions } = tagData;
        this.updateChartForBracketing({ metadata, bracketOptions, matrixMetadata });
      }

      $app.renderSync(() => {
        if (this.chart) {
          this.chart.redraw();
          this.reflow();
        }
      });
      $app.renderSync(() => {
        this.dismissSpinner();
      });
    }
  }

  updateChartForBracketing({ metadata, bracketOptions, matrixMetadata }) {
    const { theme } = this.props;
    const legendTitle =
      bracketOptions.type === 'staticRanges' ? '' : `Units: ${matrixMetadata.prefix}${matrixMetadata.displayUnit}`;

    this.chart.colorAxis[0].update(
      { dataClassColor: 'category', dataClasses: getBracketingDataClasses({ metadata, bracketOptions }) },
      false
    );
    this.chart.legend.update(
      {
        symbolHeight: undefined,
        layout: 'horizontal',
        title: {
          text: legendTitle
        }
      },
      false
    );
    this.chart.series[0].update({ dataLabels: { color: theme.colors.white } }, false);
  }

  matrixMetadata(matrix) {
    const { $dictionary, dataview } = this.props;
    const { activeBuckets } = dataview.queryBuckets;
    const unit = activeBuckets[0].firstQuery.outsortUnit;
    return {
      unit,
      prefix: $dictionary.get('unitsToPrefix').includes(unit)
        ? greekPrefix(
            matrix.map((matrixPoint) => matrixPoint[2]),
            1
          )
        : '',
      displayUnit: $dictionary.get('units')[unit],
      fix: getToFixed(unit)
    };
  }

  redraw() {
    const { $app } = this.props;

    if (this.chart) {
      $app.renderSync(() => {
        const { theme } = this.props;
        if (this.chart) {
          // update colorAxis if theme changed
          if (this.chart.colorAxis[0].options.minColor !== theme.colors.appBackground) {
            this.chart.colorAxis[0].update({ minColor: theme.colors.appBackground }, false);
          }

          this.chart.redraw(false);
        }
      });
    }
  }

  clear() {
    if (this.chart?.series?.length > 0) {
      this.chart.series[0].setData([]);
    }
  }

  getComponent() {
    const { chartOptions, state, props } = this;
    const { $dictionary, dataview } = props;
    const { subview } = state;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const query = activeBuckets[0].firstQuery;
    const { metric: metrics, matrixBy } = query.toJS();

    const chartTypesValidations = $dictionary.get('chartTypesValidations');

    chartOptions.xAxis.title.text = matrixBy.map((metric) => chartTypesValidations[metric]).join(', ');
    chartOptions.yAxis.title.text = metrics.map((metric) => chartTypesValidations[metric]).join(', ');
    chartOptions.series[0].name = $dictionary.get('units')[query.outsortUnit];
    chartOptions.series[0].dataview = this;

    const dataViewTools = (
      <DataViewTools dataview={subview}>
        {({ Wrapper, ...rest }) => (
          <Wrapper>
            <ChangeViewTypeMenu {...rest} showButtonText={false} />
          </Wrapper>
        )}
      </DataViewTools>
    );

    return (
      <div style={{ height: '100%' }}>
        {super.getComponent()}
        {!!subview && (
          <Dialog
            canEscapeKeyClose
            isOpen={!!subview}
            style={{ height: '60vh', width: '80vw', padding: 8 }}
            onClose={() => {
              subview.destroy();
              this.setState({ subview: null });
            }}
          >
            <Card p={1} display="flex" flexDirection="column" flex="1 1 auto" style={{ height: '100%' }}>
              <DataViewWrapper
                dataview={subview}
                viewProps={{
                  showNativeLegend: true
                }}
                headerProps={{
                  showTitleLink: false,
                  tools: dataViewTools
                }}
              >
                {({ component, header, className }) => (
                  <Flex className={className} flex="1 1 auto" flexDirection="column">
                    <Box m={2} pb={2} mb={0}>
                      {header}
                    </Box>
                    <Flex flex={1}>{component}</Flex>
                  </Flex>
                )}
              </DataViewWrapper>
            </Card>
          </Dialog>
        )}
      </div>
    );
  }
}

const config = {
  showTotalTrafficOverlay: false,
  showHistoricalOverlay: false,
  showLegend: false,
  timeBased: false,
  isSVG: true,
  enableToggle: false,
  suppressLastPointBracketing: true,
  suppressBracketingGroupOption: true,
  suppressGeneratorMode: true,
  buckets: [
    {
      name: 'Matrix'
    }
  ]
};

export { config };
export default withTheme(MatrixView);
