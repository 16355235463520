import React from 'react';
import { Card, Box, Icon, Heading, Link, Text } from 'core/components';
import { MdDashboard } from 'react-icons/md';

const PresetCard = ({ dashboard, navPath }) => (
  <Card runningText p={0} position="relative">
    <Box position="absolute" right={8} top={8} opacity={0.15}>
      <Icon icon={MdDashboard} color="muted" iconSize={25} />
    </Box>
    <Box p={2}>
      <Heading level={5} mt={0} mb={1}>
        <Link to={`/v4/${navPath}/${dashboard.slugName}`}>{dashboard.get('dash_title')}</Link>
      </Heading>
      <Text as="div" color="muted" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {dashboard.get('description')}
      </Text>
    </Box>
  </Card>
);

export default PresetCard;
