function convertToSeconds(value, unit) {
  let multiplier = 1;

  if (unit === 'm') {
    multiplier = 60;
  } else if (unit === 'h') {
    multiplier = 3600;
  }

  return value * multiplier;
}

function deserializeCidrDimension(dim, cidrMetrics = []) {
  if (dim.indexOf('_cidr') >= 0) {
    const prefix = cidrMetrics.find((m) => dim.startsWith(m));
    if (prefix) {
      return prefix;
    }
  }
  return dim;
}

module.exports = {
  convertToSeconds,
  deserializeCidrDimension
};
