import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { FlexColumn, Suspense } from 'core/components';

import Topology from './Topology';

@inject('$topo', '$customers')
@observer
class CustomerTopology extends Component {
  static defaultProps = {
    embedded: false
  };

  state = {
    customer: undefined,
    loading: false
  };

  static getDerivedStateFromProps(props) {
    const { customer } = props;
    if (customer) {
      return { customer };
    }
    return null;
  }

  componentDidMount() {
    const { customer, match = {} } = this.props;
    if (customer) {
      return this.getTopologyData();
    }
    const { customerId } = match.params;

    return this.fetchCustomer(customerId);
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading, customer = {} } = this.state;
    if (!loading) {
      if (customer !== prevState.customer) {
        return this.getTopologyData();
      }
    }

    return null;
  }

  fetchCustomer = (customerId) => {
    const { $customers } = this.props;
    const { customers } = $customers;

    this.setState({ loading: true });

    customers.fetch().then(() => {
      this.setState({ customer: customers.get(customerId), loading: false });
    });
  };

  getTopologyData = () => {
    const { $topo } = this.props;
    const { customer } = this.state;
    $topo.getTopologyData({
      selectedInterfaces: customer.get('interfaceGroup').interfaces,
      type: 'provider',
      level: 'device',
      showProviders: 'name'
    });
  };

  render() {
    const { maxHeight } = this.props;
    const { loading } = this.state;

    return (
      <Suspense loading={loading}>
        <FlexColumn height="100%" maxHeight={maxHeight}>
          <Topology title={this.provider} enableMouseWheelZoom={false} embedded />
        </FlexColumn>
      </Suspense>
    );
  }
}

export default CustomerTopology;
