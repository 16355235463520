import React from 'react';
import { get, set, isEmpty, isBoolean } from 'lodash';
import moment from 'moment';
import { FiBell as BellIcon, FiBellOff as MutedIcon } from 'react-icons/fi';

import { HEALTH, HEALTH_SORT } from 'shared/synthetics/constants';
import { isAlarmActive, getMaxRawLookbackByTestFreq } from 'shared/synthetics/utils';
import { trimmedArrFromStr, safelyParseJSON } from 'core/util';
import { isIpV6Valid } from 'core/util/ip';
import { DEFAULT_MIN_LOOKBACK, TEST_TYPES, DNS_RECORD_TYPES, MIN_LOOKBACK_BY_TEST_FREQUENCY } from 'app/util/constants';
import { zeroToText, parseQueryString } from 'app/util/utils';
import { SEVERITY_MAP } from 'shared/alerting/constants';
import { Spinner, Tag, Tooltip } from 'core/components';
import { dnsStatusCodes, ipV6ErrorMessage, tcpErrorMessage } from 'app/forms/config/syntheticTests';

export const ALLOWED_COLOR = '#0f9960';
export const WITHDRAWAL_COLOR = '#8F398F';
export const INVALID_ORIGIN_COLOR = '#db3737';
export const WARNING_COLOR = '#fdba7f';

// we can opt in to not using colors in an aggregated state
export function healthToIntent({ health, isAggregated = false } = {}) {
  if (isAggregated) {
    return 'none';
  }

  switch (health) {
    case 'healthy':
      return 'success';
    case 'warning':
      return 'warning';
    case 'critical':
      return 'danger';
    default:
      return 'none';
  }
}

export function healthToMarkerColor({ health, isAggregated = false } = {}) {
  if (isAggregated) {
    return 'none';
  }

  switch (health) {
    case 'warning':
      return 'yellow';
    case 'critical':
      return 'red';
    case 'healthy':
      return 'green';
    default:
      return 'grey';
  }
}

/*
  Attempts to match a health status with a theme color,
  falling back to using the marker color util if necessary

  we can opt in to not using colors in an aggregated state
*/
export function healthToThemeColor({ health, theme, isAggregated = false } = {}) {
  if (isAggregated) {
    return theme.colors.muted;
  }

  if (theme) {
    switch (health) {
      case 'warning':
        return theme.colors.warning;
      case 'critical':
        return theme.colors.danger;
      case 'healthy':
        return theme.colors.success;
      default:
        return theme.colors.muted;
    }
  }

  console.warn('Theme was not passed in, falling back to marker colors instead');
  return healthToMarkerColor({ health });
}

export function getWorstHealthInList(list, metricName) {
  const filtered = list.map((model) => HEALTH_SORT.indexOf(model.get(metricName))).filter((value) => value >= 0);
  return HEALTH_SORT[Math.min(...filtered)];
}

export function getWorstHealth(health1, health2) {
  if (HEALTH_SORT.indexOf(health1) <= HEALTH_SORT.indexOf(health2)) {
    return health1;
  }
  return health2;
}

export function healthRenderer(model, isMuted) {
  const showAlertStatus = isBoolean(isMuted);
  const alertStatusIcon = isMuted ? MutedIcon : BellIcon;

  if (model.get('test_status') === 'P') {
    return (
      <Tag fontWeight="bold" minimal color="muted">
        Paused
      </Tag>
    );
  }

  if (!model.get('healthLoaded')) {
    return <Spinner mx="auto" size={14} />;
  }

  const health = model.get('health');

  if (!health) {
    let status = model.get('results_first_valid') ? 'Failing' : 'Pending';

    if (model.isScheduled && !model.isWithinScheduledWindow) {
      // for a scheduled test that is currently outside its defined window,
      // fall back to a 'Pending' state to avoid a potentially confusing 'Failing' state label
      status = 'Pending';
    }

    return (
      <Tooltip content={model.get('healthErrors').join(' ')}>
        <Tag fontSize="small" fontWeight="bold" interactive minimal icon={showAlertStatus && alertStatusIcon}>
          {status}
        </Tag>
      </Tooltip>
    );
  }

  return (
    <Tag
      fontWeight="bold"
      intent={healthToIntent({ health })}
      textTransform="capitalize"
      minimal
      icon={showAlertStatus && alertStatusIcon}
    >
      {health}
    </Tag>
  );
}

const testTypeLabels = {
  [TEST_TYPES.IP_ADDRESS]: 'Server IP Addresses',
  [TEST_TYPES.NETWORK_GRID]: 'Network Grid',
  [TEST_TYPES.PAGE_LOAD]: 'Page Load',
  [TEST_TYPES.ASN]: 'ASN',
  [TEST_TYPES.CDN]: 'CDN',
  [TEST_TYPES.COUNTRY]: 'Country',
  [TEST_TYPES.REGION]: 'Region',
  [TEST_TYPES.CITY]: 'City',
  [TEST_TYPES.HOSTNAME]: 'Server Hostname',
  [TEST_TYPES.URL]: 'HTTP(S) or API',
  [TEST_TYPES.APPLICATION_MESH]: 'Network Mesh',
  [TEST_TYPES.AGENT]: 'Agent-to-Agent',
  [TEST_TYPES.DNS]: 'DNS Server Monitor',
  [TEST_TYPES.DNS_GRID]: 'DNS Server Grid',
  [TEST_TYPES.BGP_MONITOR]: 'BGP Monitor',
  [TEST_TYPES.TRANSACTION]: 'Transaction'
};

export function getAgentRelationshipErrors(form) {
  const errors = {
    ipV6: !form.valid && form.errors.some((err) => err.includes(ipV6ErrorMessage)),
    tcp: !form.valid && form.errors.some((err) => err.includes(tcpErrorMessage))
  };
  const string = Object.keys(errors)
    .map((key) => `${key}-${errors[key]}`)
    .join('-');
  return { errors, string };
}

/**
 * Returns form fields errors by field name
 * @param {object} form
 * @param {array} fieldNames
 * @returns
 */
export function getFormFieldsErrors(form, fieldNames = []) {
  const healthAlertsFieldsStates = form.fieldStates.filter(({ name }) => fieldNames.includes(name));
  const fieldStatesWithErrors = healthAlertsFieldsStates.filter(({ errors }) => !isEmpty(errors));
  const fieldsErrors = fieldStatesWithErrors?.map(({ name, errors }) => ({ [name]: errors?.[0] }));
  return fieldsErrors;
}

export function getSubtestUrl({ agent_id, target, test_id }) {
  const finalPath = target ? `/${target}` : '';
  return `/v4/synthetics/tests/${test_id}/results/agent/${agent_id}${finalPath}`;
}

export function handleCopyTest(history, test) {
  history.push(`/v4/synthetics/tests/${test.get('test_type')}`, {
    clone: test.id,
    labels: test.labels.map((label) => ({ id: label.id }))
  });
}

export function dnsCodeLookup(code) {
  return dnsStatusCodes[code] || { short: 'UNKNOWN', long: 'UNKNOWN' };
}

export function convertDnsHealthDataToArray(health) {
  const dataStr = get(health, 'data', []);
  const status = get(health, 'status');

  if (status === 0) {
    const data = dataStr.charAt(0) === '[' ? safelyParseJSON(dataStr) : dataStr.split(',');
    // check for objects like { code: 408, message: 'Request timed out' }
    return data.map((item) => item?.message || item);
  }
  return [dnsCodeLookup(status).short];
}

export function getTestTypeLabel(value) {
  return testTypeLabels[value];
}

export function getHttpStages(isPageLoad) {
  const durations = [];
  if (isPageLoad) {
    durations.push({ name: 'Navigation Time', key: 'domainLookupStart', stagesKey: 'navigation_time' });
  }
  durations.push(
    { name: 'Domain Lookup Time', key: 'domainLookupEnd', stagesKey: 'domain_lookup_time' },
    { name: 'Connect Time', key: 'connectEnd', stagesKey: 'connect_time' }
  );
  if (isPageLoad) {
    durations.push(
      { name: 'Response Time', key: 'responseEnd', stagesKey: 'response_time' },
      { name: 'DOM Processing Time', key: 'duration', stagesKey: 'dom_processing_time' }
    );
  } else {
    durations.push({ name: 'Response Time', key: 'duration', stagesKey: 'response_time' });
  }
  return durations;
}

/*
  Given a test and lookback seconds, the test's frequency is used to consult a standard list of frequencies and min lookback thresholds
  If we find a match for one of these thresholds, we'll reset the min lookback and optionally reset the lookback seconds if it's less
  than the matched minimum
*/
export function getMinLookback(test, lookbackSeconds) {
  // attempt to get the test frequency, first checking the configured period, falling back to the ping period
  const testFrequency = test?.get('config.period') || test?.get('config.ping.period');

  if (testFrequency) {
    // find the first entry where the frequency of our supplied test is >= than the entry's test frequency
    // a match here indicates a desired min lookback
    // no match and we'll fall back to the default min lookback
    const { minLookbackSeconds: foundMinLookbackSeconds } =
      MIN_LOOKBACK_BY_TEST_FREQUENCY.find((config) => testFrequency >= config.testFrequency) || {};

    if (foundMinLookbackSeconds) {
      // found a min lookback as defined in our MIN_LOOKBACK_BY_TEST_FREQUENCY list
      // now check to see if we need to overwrite the lookback seconds
      // we do this when the supplied lookback seconds is less than the min lookback we'll show in the date picker
      // in this case, we'll reset the lookback seconds to the min lookback
      const hasLookbackSeconds = !!lookbackSeconds;
      const lookbackIsLessThanMinLookback = hasLookbackSeconds && lookbackSeconds < foundMinLookbackSeconds;

      return {
        lookbackSeconds: lookbackIsLessThanMinLookback ? foundMinLookbackSeconds : lookbackSeconds,
        minLookbackSeconds: foundMinLookbackSeconds
      };
    }
  }

  return { minLookbackSeconds: DEFAULT_MIN_LOOKBACK };
}

export function mutateTestResultsHealthTS(testResults) {
  testResults.health[0].health_ts = testResults.health[0].health_ts.sort((a, b) => a.time - b.time);
}

export function getResultTimeMsFromTestResults(hasTestResults, testResults) {
  let resultTimeMs;
  if (hasTestResults) {
    mutateTestResultsHealthTS(testResults);
    resultTimeMs = testResults.health[0].health_ts[testResults.health[0].health_ts.length - 1].time * 1000;
  }
  return resultTimeMs;
}

// Based on PerformanceTimelineHC display calculations
// Useful for aligning multiple timelines
export function getSortedHealthTimelineAndBounds(agentHealthTs, ignoreAdjustment) {
  // passed data sorted by time
  const sorted = agentHealthTs
    .map((point) => ({ ...point, time: parseInt(point.time) * 1000 }))
    .sort((a, b) => a.time - b.time);

  // minimum time between timeslices
  const delta =
    sorted.length > 1
      ? sorted.reduce(
          (min, slice, index) => (sorted[index + 1] ? Math.min(min, sorted[index + 1].time - slice.time) : min),
          Infinity
        )
      : 60 * 1000;

  const adjustment = ignoreAdjustment ? 0 : delta / 2;
  const xMin = sorted[0].time;
  const xMax = sorted[sorted.length - 1].time;
  const xAxisMin = xMin - adjustment;
  const xAxisMax = xMax + adjustment;

  return {
    sorted, // passed data sorted by time
    delta, // minimum time between timeslices
    xMin, // minimum selectable time
    xMax, // maximum selectable time
    xAxisMin, // minimum x axis time
    xAxisMax // maximum x axis time
  };
}

// todo: make this function irrelevant
export function getTimelinebounds(health, ignoreAdjustment) {
  // passed data sorted by time
  const incrementsTs = (Array.isArray(health) ? health : Object.keys(health)).map((ts) => +ts * 1000);

  // does not work with new syngest timestamping
  const delta =
    incrementsTs.length > 1
      ? incrementsTs.reduce(
          (min, slice, index) => (incrementsTs[index + 1] ? Math.min(min, incrementsTs[index + 1] - slice) : min),
          Infinity
        )
      : 60 * 1000;

  const adjustment = ignoreAdjustment ? 0 : delta / 2;
  const xMin = incrementsTs[0];
  const xMax = incrementsTs[incrementsTs.length - 1];
  const xAxisMin = xMin - adjustment;
  const xAxisMax = xMax + adjustment;

  return {
    delta, // minimum time between timeslices
    xMin, // minimum selectable time
    xMax, // maximum selectable time
    xAxisMin, // minimum x axis time
    xAxisMax // maximum x axis time
  };
}

export function updateTimelineChartSelection($dataviews, resultTimeMs) {
  $dataviews.renderedDataviews
    .filter((timeline) => timeline.type === 'timeline')
    .forEach((timeline, i) => {
      const { chart, delta } = timeline;
      const points = [];
      chart?.series?.forEach((series) =>
        series.points.forEach((point) => {
          const { selected } = point;
          const shouldBeSelected = point.options.time === resultTimeMs || point.options.x === resultTimeMs;

          if (selected && !shouldBeSelected) {
            // deselect point, don't deselect existing
            point.select(false, true);
          } else if (!selected && shouldBeSelected) {
            points.push(point);
          }
        })
      );

      if (points.length > 0) {
        const validPoints = points.filter((point) => !point.isNull).length > 0;
        // select points, don't deselect existing
        points.forEach((point) => {
          if (!point.isNull) {
            point.select(true, true);
          }
        });

        if (chart.tooltip) {
          if (!chart.tooltip.isHidden) {
            chart.tooltip.hide();
          }

          if (chart.tooltip.shared) {
            // shared tooltips take multiple points
            if (timeline.visible && validPoints) {
              chart.tooltip.refresh(points);
            }

            chart.xAxis[0].drawCrosshair(null, points[0]);

            if (delta) {
              const plotBandID = `plot-band-${i}`;
              chart.xAxis[0].removePlotBand(plotBandID);
              chart.xAxis[0].addPlotBand({
                from: resultTimeMs - delta / 2,
                to: resultTimeMs + delta / 2,
                color: 'rgba(18, 128, 228, 0.1)',
                id: plotBandID
              });
            }
          } else if (timeline.visible && validPoints) {
            chart.tooltip.refresh(points[0]);
          }
        }
      }
    });
}

export function hideTooltips($dataviews) {
  $dataviews.renderedDataviews.forEach((dataview) => {
    const { chart } = dataview;

    if (chart.tooltip && !chart.tooltip.isHidden) {
      chart.pointer.chartPosition = null;
      chart.tooltip.hide();
    }
  });
}

export function inputHasIpV6(input) {
  if (typeof input === 'string') {
    return trimmedArrFromStr(input).some((ip) => isIpV6Valid(ip));
  }
  return false;
}

export function getPacketLossValue(value) {
  return typeof value !== 'number' ? value : `${value === 1 ? 100 : zeroToText(value * 100, { fix: 3 })}%`;
}

export function getMsValue(value, precision = 3) {
  if (Number.isNaN(value)) {
    // protect against NaN because it's a number type
    return '---';
  }

  return typeof value !== 'number' ? value : `${zeroToText(value / 1000, { fix: precision })} ms`;
}

export function checkSeriesDataForFailure({ task_type } = {}) {
  return task_type === 'timeout' || task_type === 'error';
}

export function checkSeriesDataForError({ task_type } = {}) {
  return task_type === 'error';
}

export const getTimeRangeLookbackOptions = () => [
  { value: 300, label: 'Last 5 Minutes' },
  { value: 900, label: 'Last 15 Minutes' },
  { value: 3600, label: 'Last Hour' },
  { value: 10800, label: 'Last 3 Hours' },
  { value: 21600, label: 'Last 6 Hours' },
  { value: 86400, label: 'Last 1 Day' },
  { value: 172800, label: 'Last 2 Days' },
  { value: 259200, label: 'Last 3 Days' },
  { value: 604800, label: 'Last Week' },
  { value: 1209600, label: 'Last 2 Weeks' },
  { value: 2592000, label: 'Last 30 Days' },
  { value: 1, label: 'This Month' },
  { value: 2, label: 'Last Month' }
];

export function getDnsRecordType(type) {
  return DNS_RECORD_TYPES[type] || type;
}

const setLookbackFromHistoryState = ({ $exports, history }) => {
  const { state } = history.location;
  if (state && (state.lookbackSeconds || state.startDate || state.endDate)) {
    const { startDate, endDate, lookbackSeconds } = state;

    return $exports
      .setHash({
        hashedLookbackSeconds: lookbackSeconds,
        hashedStartDate: startDate,
        hashedEndDate: endDate
      })
      .then(() => {
        history.replace({ ...history.location, state: {} });
      });
  }

  return Promise.resolve();
};

const setLookbackFromHistorySearch = ({ $exports, history, test }) => {
  const { search } = history.location;
  const { start, end } = parseQueryString(search);

  // If there is a start and end provided, set those, no need to buffer
  if (start && end) {
    const urlStartDate = Math.floor(parseInt(start) / 60) * 60;
    let urlEndDate = Math.floor(parseInt(end) / 60) * 60;
    // If the end date is more than 3 hours after the start date, cap it at 3 hours
    if (urlEndDate > urlStartDate + 179 * 60) {
      urlEndDate = urlStartDate + 179 * 60;
    }
    return $exports.setHash(
      {
        hashedStartDate: urlStartDate,
        hashedEndDate: urlEndDate,
        hashedLookbackSeconds: null
      },
      true,
      true
    );
  }
  // if there is only a start date, use the maxRawLookbackByTestFreq function to determine end date.
  if (start) {
    const urlStartDate = Math.floor(parseInt(start) / 60) * 60;
    const maxRawLookback = getMaxRawLookbackByTestFreq(test);
    const urlEndDate = urlStartDate + maxRawLookback;
    return $exports.setHash(
      {
        hashedStartDate: urlStartDate,
        hashedEndDate: urlEndDate,
        hashedLookbackSeconds: null
      },
      true,
      true
    );
  }

  return Promise.resolve(null);
};

export const setLookbackFromHistory = ({ $exports, history, test }) =>
  setLookbackFromHistorySearch({ $exports, history, test }).then(
    (resp) => resp || setLookbackFromHistoryState({ $exports, history })
  );

// NOTE: Transaction Tests Script Errors are base64 Encoded to appease Ingest from choking
export const getStatusMessage = (statusMessage, statusEncoding) =>
  statusEncoding === 'base64' ? atob(statusMessage) : statusMessage;

const buildAlarmsTimeslots = ({ lookbackSeconds, startDate, endDate, creationDate, ignoreAdjustment }) => {
  const end = endDate ? moment.unix(endDate) : moment();
  const start = startDate ? moment.unix(startDate) : moment(end).subtract(lookbackSeconds, 'seconds');
  const duration = moment.duration(end.diff(start));
  const hours = duration.asHours();
  const normalizeStartByHour = moment(start).startOf('hour');
  const normalizeStartByMinute = moment(start).startOf('minute');
  const minuteOnTheHour = moment.duration(normalizeStartByMinute.diff(normalizeStartByHour)).asMinutes();
  const minutesOffset = minuteOnTheHour % 5;
  const beforeTime = creationDate && moment(creationDate).unix();

  let minutesPerIncrement;
  let whereToStart;
  let increments;

  if (ignoreAdjustment || hours < 3) {
    minutesPerIncrement = 1;
    whereToStart = moment(normalizeStartByMinute);
    increments = Math.ceil(moment.duration(end.diff(whereToStart)).asMinutes());
  } else if (hours < 12) {
    minutesPerIncrement = 5;
    whereToStart = moment(normalizeStartByMinute).subtract(minutesOffset, 'minute');
    increments = Math.ceil(moment.duration(end.diff(whereToStart)).asMinutes() / 5);
  } else {
    minutesPerIncrement = 60;
    whereToStart = moment(normalizeStartByHour);
    increments = Math.ceil(moment.duration(end.diff(whereToStart)).asHours());
  }

  const incrementStart = whereToStart.unix();
  const timeslots = {};

  for (let i = 0; i < increments; i += 1) {
    const incrementMS = minutesPerIncrement * 60;
    const offsetIncrementStart = i * incrementMS;
    const time = incrementStart + offsetIncrementStart;
    const endOfIncrement = time + incrementMS;
    const health = beforeTime && beforeTime > time ? 'absent' : 'healthy';

    timeslots[time] = { time, total: 0, major: 0, critical: 0, error: 0, alarms: [], endOfIncrement, health };
  }

  return { timeslots, end: end.unix(), minutesPerIncrement };
};

export const aggregateAgentDowntimeAlarmsIntoTimeslots = ({
  alarms,
  lookbackSeconds,
  startDate,
  endDate,
  creationDate,
  ignoreAdjustment
}) => {
  const { timeslots, end, minutesPerIncrement } = buildAlarmsTimeslots({
    lookbackSeconds,
    startDate,
    endDate,
    creationDate,
    ignoreAdjustment
  });
  const timeKeys = Object.keys(timeslots);

  for (let i = 0; i < alarms.length; i += 1) {
    const alarm = alarms[i];
    const { start_time, end_time, id, severity } = alarm;
    const alarmStart = moment(start_time).unix();
    const alarmEnd = isAlarmActive(alarm.state) ? end : moment(end_time).unix();

    for (let j = 0; j < timeKeys.length; j += 1) {
      const { time, endOfIncrement } = timeslots[timeKeys[j]];
      const alarmStartInIncrement = time <= alarmStart && endOfIncrement >= alarmStart;
      const alarmEndInIncrement = time <= alarmEnd && endOfIncrement >= alarmEnd;
      const alarmInRange = time >= alarmStart && time <= alarmEnd;
      const endOfTime = j === timeKeys.length - 1 && time < alarmEnd;

      if (
        severity === SEVERITY_MAP.CRITICAL.ALERT_MANAGER &&
        (alarmInRange || alarmStartInIncrement || alarmEndInIncrement || endOfTime)
      ) {
        timeslots[time].error += 1;
        timeslots[time].alarms.push(id);
        timeslots[time].health = 'error';
        timeslots[time].total += 1;
      }
    }
  }

  const activeTestTime = parseInt(timeKeys[timeKeys.length - 1]) * 1000;

  return { alarms, timeslots, minutesPerIncrement, activeTestTime };
};

export const aggregateAlarmsIntoTimeslots = ({
  alarms,
  lookbackSeconds,
  startDate,
  endDate,
  creationDate,
  ignoreAdjustment
}) => {
  const { timeslots, end, minutesPerIncrement } = buildAlarmsTimeslots({
    lookbackSeconds,
    startDate,
    endDate,
    creationDate,
    ignoreAdjustment
  });
  const timeKeys = Object.keys(timeslots);

  // looping over potentially 10k alarms
  for (let i = 0; i < alarms.alarms.length; i += 1) {
    const alarm = alarms.alarms[i];
    const { startTime, endTime } = alarm;
    const alarmStart = moment(startTime).unix();
    const alarmEnd = alarm.state === 'alarm' ? end : moment(endTime).unix();

    // BGP alarms will have the test_id in alarm.dimensionToKeyPart.synthetic field so lets normalize...
    if (alarm.dimensionToKeyPart?.synthetic) {
      alarm.dimensionToKeyPart.test_id = alarm.dimensionToKeyPart.synthetic;
    }

    for (let j = 0; j < timeKeys.length; j += 1) {
      const { time, endOfIncrement } = timeslots[timeKeys[j]];

      const alarmStartInIncrement = time <= alarmStart && endOfIncrement >= alarmStart;
      const alarmEndInIncrement = time <= alarmEnd && endOfIncrement >= alarmEnd;
      const alarmInRange = time >= alarmStart && time <= alarmEnd;
      const endOfTime = j === timeKeys.length - 1 && time < alarmEnd;

      if (alarmInRange || alarmStartInIncrement || alarmEndInIncrement || endOfTime) {
        const { id, severity } = alarm;

        if (severity === 'critical' || severity === 'major') {
          timeslots[time][severity] += 1;
          timeslots[time].alarms.push(id);

          if (timeslots[time].health !== 'critical') {
            if (severity === 'critical') {
              timeslots[time].health = 'critical';
            }

            if (severity === 'major' && timeslots[time].health !== 'warning') {
              timeslots[time].health = 'warning';
            }
          }
        }
      }
    }
  }

  const activeTestTime = parseInt(timeKeys[timeKeys.length - 1]) * 1000;

  return Object.assign(alarms, { timeslots, minutesPerIncrement, activeTestTime });
};

export const aggregateAlertManagerAlarmsIntoTimeslots = ({
  alarms,
  lookbackSeconds,
  startDate,
  endDate,
  creationDate,
  ignoreAdjustment
}) => {
  const { timeslots, end, minutesPerIncrement } = buildAlarmsTimeslots({
    lookbackSeconds,
    startDate,
    endDate,
    creationDate,
    ignoreAdjustment
  });
  const timeKeys = Object.keys(timeslots);

  for (let i = 0; i < alarms.length; i += 1) {
    const alarm = alarms[i];
    const { start_time: startTime, end_time: endTime } = alarm;
    const alarmStart = moment(startTime).unix();
    const alarmEnd = isAlarmActive(alarm.state) ? end : moment(endTime).unix();

    for (let j = 0; j < timeKeys.length; j += 1) {
      const { time, endOfIncrement } = timeslots[timeKeys[j]];

      const alarmStartInIncrement = time <= alarmStart && endOfIncrement >= alarmStart;
      const alarmEndInIncrement = time <= alarmEnd && endOfIncrement >= alarmEnd;
      const alarmInRange = time >= alarmStart && time <= alarmEnd;
      const endOfTime = j === timeKeys.length - 1 && time < alarmEnd;

      if (alarmInRange || alarmStartInIncrement || alarmEndInIncrement || endOfTime) {
        const { id, severity } = alarm;
        const isWarning =
          severity === SEVERITY_MAP.WARNING.ALERT_MANAGER || severity === SEVERITY_MAP.MAJOR.ALERT_MANAGER;
        let severityHealth = HEALTH.HEALTHY;

        if (isWarning) {
          severityHealth = SEVERITY_MAP.MAJOR.STANDARD_ALERT;
        } else if (severity === SEVERITY_MAP.CRITICAL.ALERT_MANAGER) {
          severityHealth = SEVERITY_MAP.CRITICAL.STANDARD_ALERT;
        }

        if (severity === SEVERITY_MAP.CRITICAL.ALERT_MANAGER || isWarning) {
          timeslots[time][severityHealth] += 1;
          timeslots[time].alarms.push(id);

          if (timeslots[time].health !== HEALTH.CRITICAL) {
            if (severity === SEVERITY_MAP.CRITICAL.ALERT_MANAGER) {
              timeslots[time].health = HEALTH.CRITICAL;
            }

            if (isWarning && timeslots[time].health !== HEALTH.WARNING) {
              timeslots[time].health = HEALTH.WARNING;
            }
          }
        }
      }
    }
  }

  const activeTestTime = parseInt(timeKeys[timeKeys.length - 1]) * 1000;

  return { alarms, timeslots, minutesPerIncrement, activeTestTime };
};

export const combineSynAndBgpAlarmsResponse = (synAlarms, bgpAlarms) => {
  const combo = {
    alarms: [],
    count: '0',
    policyIDToDetails: {},
    thresholdIDToDetails: {},
    totals: {
      policies: {},
      severities: {},
      states: {}
    }
  };

  if (synAlarms) {
    combo.alarms.push(...synAlarms.alarms);
    Object.assign(combo.policyIDToDetails, synAlarms.policyIDToDetails);
    Object.assign(combo.thresholdIDToDetails, synAlarms.thresholdIDToDetails);
    Object.assign(combo.totals.policies, synAlarms.totals.policies);
    Object.assign(combo.totals.severities, synAlarms.totals.severities);
    Object.assign(combo.totals.states, synAlarms.totals.states);
  }

  if (bgpAlarms) {
    combo.alarms.push(...bgpAlarms.alarms);
    Object.assign(combo.policyIDToDetails, bgpAlarms.policyIDToDetails);
    Object.assign(combo.thresholdIDToDetails, bgpAlarms.thresholdIDToDetails);
    Object.assign(combo.totals.policies, bgpAlarms.totals.policies);

    if (synAlarms) {
      const severities = Object.entries(synAlarms.totals.severities);

      for (let i = 0; i < severities.length; i += 1) {
        const [severityKey, severityVal] = severities[i];

        if (combo.totals.severities[severityKey]) {
          combo.totals.severities[severityKey] = (
            parseInt(combo.totals.severities[severityKey], 10) + parseInt(severityVal, 10)
          ).toString();
        } else {
          combo.totals.severities[severityKey] = severityVal;
        }
      }

      const states = Object.entries(synAlarms.totals.states);

      for (let i = 0; i < states.length; i += 1) {
        const [stateKey, stateVal] = states[i];

        if (combo.totals.states[stateKey]) {
          combo.totals.states[stateKey] = (
            parseInt(combo.totals.states[stateKey], 10) + parseInt(stateVal, 10)
          ).toString();
        } else {
          combo.totals.states[stateKey] = stateVal;
        }
      }
    } else {
      Object.assign(combo.totals.severities, synAlarms.totals.severities);
      Object.assign(combo.totals.states, synAlarms.totals.states);
    }
  }

  combo.count = combo.alarms.length.toString();

  return combo;
};

export const getTypeAndTargetDisplay = (test_type, target) => {
  let targetStr = '';
  if (target && test_type !== TEST_TYPES.NETWORK_GRID && test_type !== TEST_TYPES.TRANSACTION) {
    if (test_type === TEST_TYPES.IP_ADDRESS && target.split(',').length > 1) {
      targetStr = ': IP Grid';
    } else {
      targetStr = `: ${target}`;
    }
  }
  return targetStr;
};

export function canTestTypeSupportOptionalTarget(testType) {
  return [TEST_TYPES.HOSTNAME, TEST_TYPES.PAGE_LOAD, TEST_TYPES.TRANSACTION, TEST_TYPES.URL].includes(testType);
}

/*
uses the max raw lookback based on test frequency to focus the time range into the largest raw data range
by default this date range is provided by calculating a new start date from the current end date based on the max raw lookback
optionally we can pass in our own timestamp --- this is done for the alarm timeline where the timestamp is the currently hovered slice
*/
export function getRawHealthTimeRange({ startDate, endDate, maxRawLookback } = {}) {
  if (startDate) {
    // if provided a start date, we want to return a range beginning there and extending forward using the max raw lookback value
    // the start date will drop to the nearest whole minute
    const referenceDate = moment.unix(startDate).startOf('minute').unix();

    return {
      startDate: referenceDate,
      endDate: moment.unix(referenceDate).add(maxRawLookback, 'seconds').unix(),
      lookbackSeconds: 0
    };
  }

  if (endDate) {
    // if provided an end date, we want to return a range beginning there and extending back using the max raw lookback value
    // the end date will round up to the nearest whole minute
    const referenceDate = moment.unix(endDate).add(1, 'minute').startOf('minute').unix();

    return {
      startDate: moment.unix(referenceDate).subtract(maxRawLookback, 'seconds').unix(),
      endDate: referenceDate,
      lookbackSeconds: 0
    };
  }

  // use the max raw lookback to get raw data
  return { lookbackSeconds: maxRawLookback };
}

/*
  Reflows/renders any rendered dataviews
  This is used primarily in test/agent results where we switch tabs and require a reflow
  for charting to render edge-to-edge
*/
export function reflowRenderedDataviews({ $app, $dataviews }) {
  if ($app && $dataviews) {
    const { renderedDataviews = [] } = $dataviews;

    $app.renderSync(() => {
      renderedDataviews.forEach((dataview) => {
        if (dataview.reflow) {
          dataview.reflow();
        }

        if (dataview.renderData) {
          dataview.renderData();
        }

        if (dataview.forceUpdate) {
          dataview.forceUpdate();
        }
      });
    });
  }
}

// returns a humanized label indicating the aggregation level of a parent/subtest syngest result
export function getAggregationLabel(aggregation) {
  let label = null;

  if (aggregation) {
    label = moment.duration(aggregation, 's').humanize(); // ex: 6 hours

    // rather than change the whole locale, just chop off the plural
    label = label.replace(/s$/, '');

    if (label === 'a minute') {
      label = '1 minute';
    }

    if (label === 'a day') {
      // we only go as far as 1 day of aggregates so do a straight replacement
      label = '1 day';
    }
  }

  return label;
}

// used in preview test results, this will use only agents detected to be online
// to develop a count of tasks we can expect to eventually hydrate with results via polling
export function getExpectedTaskCount({ $syn, results }) {
  return Object.entries(results?.agents || {}).reduce((expectedCount, [agentId, agentConfig]) => {
    const agentModel = $syn.agents.get(agentId);

    if (agentModel?.status?.offline === false) {
      expectedCount += (agentConfig?.targets || []).length;
    }

    return expectedCount;
  }, 0);
}

// accumulates test results data during preview polling, by updating a single timestamp with relevant data found from available timestamps
// while filling in results, a task count is kept so we know when we're finishing polling by comparing against the expected task count
export function processPreviewTestResults({ $syn, results, eDate }) {
  const health_ts = results?.health_ts || {};
  const tasks = results?.tasks || {};
  const time = Object.keys(health_ts)[0];
  const taskIds = Object.keys(tasks);
  const allHealth = Object.values(health_ts);
  const expectedTaskCount = getExpectedTaskCount({ $syn, results });
  let taskCount = 0;

  taskIds.forEach((taskId) => {
    const task = tasks[taskId];
    const agentIds = Object.keys(task.agents);

    agentIds.forEach((agentId) => {
      const agentTaskPath = `tasks.${taskId}.agents.${agentId}`;

      const relevantHealth = allHealth.find((h) => {
        const itemTs = get(h, `${agentTaskPath}.time`, 0);
        return +itemTs >= eDate;
      });

      if (relevantHealth) {
        set(results, `health_ts.${time}.${agentTaskPath}`, {
          ...get(relevantHealth, agentTaskPath, {}),
          time
        });

        taskCount += 1;
      }
    });
  });

  return { taskCount, expectedTaskCount, previewResults: results, ts: time ? +time : 0 };
}
