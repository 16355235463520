import React from 'react';

import { Box, Text, LinkButton } from 'core/components';
import $auth from 'app/stores/$auth';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: ['InterfaceID_src', 'InterfaceID_dst'],
  filterFields: [
    'input_port',
    'i_input_snmp_alias',
    'i_input_interface_description',
    'output_port',
    'i_output_snmp_alias',
    'i_output_interface_description',
    'input|output_port',
    'i_input|output_snmp_alias',
    'i_input|output_interface_description'
  ]
};

class SnmpDependency extends AbstractDependency {
  get key() {
    return 'snmp';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return $auth.snmpEstablished;
  }

  get message() {
    return (
      <>
        <Box mb="4px">
          <Text small>
            <strong>SNMP</strong> data is required by dimensions you&apos;re using, but has not been detected for any
            devices in your organization. Results shown herein may be highly inaccurate.
          </Text>
        </Box>
        <LinkButton icon="cog" small text="Configure" to="/v4/settings/devices" />
      </>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || $auth.snmpEstablished;
  }
}

export default SnmpDependency;
