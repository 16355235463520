import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';

const DeviceLink = ({ $app, name, children, snmp_id, ...rest }) => {
  if ($app.isSubtenant) {
    return children || name;
  }
  let path = `/v4/infrastructure/devices/${name}`;
  if (snmp_id) {
    path += `/interfaces/${snmp_id}`;
  }
  return (
    <Link to={path} {...rest}>
      {children || name}
    </Link>
  );
};

export default inject('$app')(DeviceLink);
