/**
 * A button that renders a `rightIcon` when `active`.
 * Might add some additional styles here.
 */
import React from 'react';
import Button from './Button';

function SelectableButton({ active, ...rest }) {
  return <Button active={active} rightIcon={active ? 'tick' : undefined} {...rest} />;
}

SelectableButton.defaultProps = {
  alignText: 'left',
  p: '12px'
};

export default SelectableButton;
