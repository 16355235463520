import React, { Component } from 'react';
import { inject } from 'mobx-react';

import { BorderedTab, BorderedTabs, Button, Dialog, Flex, Spinner, Suspense, Text } from 'core/components';
import { FormDialog } from 'core/form';
import FormGroupIndicator from 'core/form/components/FormGroupIndicator';
import storeLoader from 'app/stores/storeLoader';
import { fields, options } from 'app/forms/config/deviceDetails';
import GeneralSettings from 'app/views/settings/devices/form/GeneralSettings';
// import PlanSite from 'app/views/settings/devices/form/PlanSite';
import BGPSettings from 'app/views/settings/devices/form/BGPSettings';
import IpSnmpSettings from 'app/views/settings/devices/form/IpSnmpSettings';
import Integrations from 'app/views/settings/devices/form/Integrations';
import StSettings from 'app/views/settings/devices/form/StSettings';
import { getAssociatedUserRbacLabels } from 'app/stores/auth/AuthRbac';
import FlowSettings from './form/FlowSettings';

@storeLoader('$sites', '$mitigations.platformsCollection', '$metrics.monitoringTemplatesCollection')
@inject('$devices', '$dictionary', '$setup', '$credentials', '$kentikAgents', '$auth')
export default class DeviceFormDialog extends Component {
  state = {
    loadingBgp: false,
    loadingDevice: true
  };

  componentDidMount() {
    const { $devices, $setup, $credentials, $kentikAgents, $auth, model } = this.props;
    if (model && !model.isNew) {
      model.fetch().then(() => this.setState({ loadingDevice: false }));
    } else {
      model.set('labels', getAssociatedUserRbacLabels('device'));
      this.setState({ loadingDevice: false });
    }

    $devices.fetchMasterBGPDevices();
    $setup.getPlans();
    $credentials.groupedCollection.fetch();
    if ($auth.hasPermission('recon.enabled', { overrideForSudo: false })) {
      $kentikAgents.collection.fetch({ force: true });
    }
    this.getBgpIngestIps();
  }

  onSubmit = async (form, values) => {
    const { onClose, onSubmit, model } = this.props;
    let snmp_enabled = false;
    const bgp_enabled = values.device_bgp_type !== 'none';

    const device_snmp_v3_conf_enabled = !!values.device_snmp_v3_conf;

    if (device_snmp_v3_conf_enabled) {
      snmp_enabled = 'V3';
    }

    if (values.device_snmp_ip) {
      snmp_enabled = 'V2';
    }

    if (bgp_enabled) {
      values.use_asn_from_flow = false;
    }

    await model.save({ ...values, snmp_enabled, bgp_enabled });

    if (typeof onSubmit === 'function') {
      await onSubmit(values);
    }

    if (typeof onClose === 'function') {
      await onClose();
    }
  };

  getBgpIngestIps = (field, value) => {
    const { $devices, model } = this.props;
    const isBgpIngestSet = !!model.get('bgpPeerIP4');
    const bgpType = value || model.get('device_bgp_type');
    const deviceId = model.get('id');

    if (!isBgpIngestSet && bgpType === 'device') {
      this.setState({ loadingBgp: true });

      $devices.fetchBgpIngestIps(deviceId).then((result) => {
        if (result) {
          model.set('bgpPeerIP4', result.bgpPeerIP4);
          model.set('bgpPeerIP6', result.bgpPeerIP6);
        }

        this.setState({ loadingBgp: false });
      });
    }
  };

  renderTabTitle = (form, tab) => (
    <Flex alignItems="center">
      <FormGroupIndicator form={form} fields={tab.fields} icon={tab.icon} mr="6px" />
      <Text>{tab.title}</Text>
    </Flex>
  );

  render() {
    const { $dictionary, $metrics, onClose, model, ...rest } = this.props;
    const { loadingBgp, loadingDevice } = this.state;

    const tabs = [
      {
        id: 'general',
        title: 'General',
        fields: ['device_name', 'device_description', 'device_type', 'device_flow_type', 'site.id'],
        panel: <GeneralSettings />
      },
      {
        id: 'flow',
        title: 'Flow',
        fields: ['device_flow_type', 'sending_ips', 'device_sample_rate', 'plan.id'],
        panel: <FlowSettings />
      },
      {
        id: 'ipSnmp',
        title: 'SNMP',
        fields: [
          'snmp_collection_method',
          'device_snmp_ip',
          'kentik_agent_snmp_options.credential_name',
          'kentik_agent_snmp_options.agent_id',
          'kentik_agent_snmp_options.port',
          'kentik_agent_snmp_options.timeout',
          'kentik_agent_snmp_options.ranger_snmp_ip',
          'device_snmp_v3_conf_enabled',
          'minimize_snmp',
          'device_snmp_v3_conf.UserName',
          'device_snmp_v3_conf.AuthenticationProtocol',
          'device_snmp_v3_conf.AuthenticationPassphrase',
          'device_snmp_v3_conf.PrivacyProtocol',
          'device_snmp_v3_conf.PrivacyPassphrase',
          'monitoring_template_id'
        ],
        panel: <IpSnmpSettings />
      },
      {
        id: 'st',
        title: 'Streaming Telemetry',
        fields: ['streaming_telemetry_collection_method'],
        panel: <StSettings />
      },
      {
        id: 'bgp',
        title: 'BGP',
        fields: [
          'device_bgp_type',
          'device_bgp_neighbor_ip',
          'device_bgp_neighbor_ip6',
          'device_bgp_neighbor_asn',
          'device_bgp_password',
          'device_bgp_flowspec',
          'device_bgp_label_unicast',
          'bgp_lookup_strategy',
          'use_bgp_device_id'
        ],
        panel: <BGPSettings onChangeBgpType={this.getBgpIngestIps} loadingBgp={loadingBgp} />
      },
      {
        id: 'integrations',
        title: 'Integrations',
        fields: ['device_alert'],
        panel: <Integrations />
      }
    ];

    return (
      <Suspense
        loading={loadingDevice}
        fallback={
          <Dialog isOpen width={820} maxHeight="calc(100vh - 80px)">
            <Dialog.Body p={3} flexDirection="column" display="flex">
              <Spinner />
            </Dialog.Body>
          </Dialog>
        }
      >
        {!loadingDevice && (
          <FormDialog
            {...rest}
            model={model}
            fields={fields}
            options={options}
            title={model.isNew ? 'Device' : `Device: ${model.get('device_name')}`}
            entityName="Device"
            isOpen
            onClose={onClose}
            formActionsProps={{ onCancel: onClose, onSubmit: this.onSubmit }}
            width={820}
            alignSelf="flex-start"
            maxHeight="calc(100vh - 80px)"
            dialogBodyProps={{ overflow: 'hidden', p: 0, display: 'flex', flexDirection: 'column' }}
            formComponentProps={{ large: true }}
          >
            {({ form }) => (
              <>
                {!model.isNew && model.isArchived && (
                  <Flex flexDirection="column" alignItems="center" justifyContent="center" p={3}>
                    <Text style={{ fontSize: 16, marginBottom: 8 }}>
                      {model.get('device_name')} has been <strong>archived</strong>.
                    </Text>
                    <Button intent="primary" onClick={() => this.onSubmit(form, { device_status: 'V' })}>
                      Unarchive now
                    </Button>
                  </Flex>
                )}
                {(model.isNew || model.isActive || model.isIncomplete) && (
                  <BorderedTabs
                    selectedTabBackgroundColor="dialogBackground"
                    pt={2}
                    overflow="hidden"
                    width="auto"
                    tabIndent={16}
                    minimal
                  >
                    {tabs.map((tab) => (
                      <BorderedTab key={tab.id} id={tab.id} title={this.renderTabTitle(form, tab)} panel={tab.panel} />
                    ))}
                  </BorderedTabs>
                )}
              </>
            )}
          </FormDialog>
        )}
      </Suspense>
    );
  }
}
