import React from 'react';
import { Box } from 'core/components';
import { Classes } from '@blueprintjs/core';
import { Label } from 'app/components/labels/Label';

export default (option) => {
  const { label, value, bg, selectItem, className, isPreset } = option;
  return (
    <Box
      pt="4px"
      pb="4px"
      onClick={() => selectItem(value)}
      key={value}
      className={className}
      css={{
        [`&.${Classes.ACTIVE} .${Classes.TEXT_MUTED}`]: { color: 'inherit' },
        [`&:hover .${Classes.TEXT_MUTED}`]: { color: 'inherit' }
      }}
    >
      <Label name={label} bg={bg} isPreset={isPreset} />
    </Box>
  );
};
