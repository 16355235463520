import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Flex, Highcharts } from 'core/components';
import { DEFAULT_QUALITATIVE_PALETTES } from 'app/stores/colors/Colors';

@inject('$colors')
@observer
class PingResponseChart extends Component {
  chart;

  chartCallback = (chart) => {
    this.chart = chart.container ? chart : null;
    this.el = chart.container;
    this.resizeWatcher = new ResizeObserver(() => {
      try {
        this.chart.reflow();
      } catch (e) {
        // OK to ignore
      }
    });
    this.resizeWatcher.observe(this.el);
  };

  render() {
    const { chartData, selectedTab } = this.props;

    const options = {
      chart: {
        type: 'line'
      },
      title: {
        text: '',
        enabled: false
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          }
        }
      },
      xAxis: {
        type: 'datetime',
        crosshair: true,
        dateTimeLabelFormats: {
          day: '%m/%e',
          minute: '%H:%M'
        },
        title: {
          text: 'Time'
        }
      },
      yAxis: {
        title: {
          text: selectedTab === 'loss' ? 'Packet Loss (%)' : 'RTT (ms)'
        },
        min: 0,
        max: selectedTab === 'loss' ? 100 : null
      },
      // legend: {
      //   symbolWidth: 0 // Hides the shapes in the legend
      // },
      tooltip: {
        crosshairs: true,
        enabled: true,
        shared: true
      },
      series: chartData
    };

    return (
      <Flex width="100%" height={350} mt={2}>
        <Highcharts
          key="ping-response-chart"
          allowChartUpdate
          options={options}
          colors={DEFAULT_QUALITATIVE_PALETTES.qualitative4}
          callback={this.chartCallback}
          width="100%"
          height={350}
          containerHeight="100%"
          flex={1}
        />
      </Flex>
    );
  }
}

export default PingResponseChart;
