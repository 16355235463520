/*
  Displays an aggregated count of incidents by status type (critical, warning)
  along with the reported time slot value
*/
import React, { Component } from 'react';
import { Text } from 'core/components';

export default class IncidentLogMessage extends Component {
  static defaultProps = {
    totalAlarms: 0,
    alarms: []
  };

  render() {
    const { totalAlarms, alarms } = this.props;
    if (totalAlarms >= 10000) {
      return (
        <Text fontSize={12} fontWeight="bold">
          ** NOTE: Showing the {alarms.length.toLocaleString()} most recent incidents, out of{' '}
          {totalAlarms.toLocaleString()} total, for this time range. (Select a shorter range to get all incidents) **
        </Text>
      );
    }

    return null;
  }
}
