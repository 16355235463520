import { baseFields } from './baseFields';
import { pingFields } from './pingFields';

const userDefinedOptions = {
  name: 'User-defined Test'
};

const userDefinedFields = {
  ...baseFields,
  ...pingFields,
  'config.tasks': {
    defaultValue: ['ping', 'traceroute']
  },
  'config.target.value': {
    rules: 'required'
  }
};

export { userDefinedOptions, userDefinedFields };
