const fields = {
  id: {
    label: 'Subscription',
    placeholder: 'Create New or Select'
  },
  title: {
    label: 'Subscription',
    rules: 'required|between:2,200|htmlSanitary',
    placeholder: 'e.g. Weekly Executive Dashboard Report'
  },
  recipients: {
    label: 'To',
    placeholder: 'Enter a comma-separated list of internal or external email addresses to send a report to',
    rules: ['commaSeparatedEmails', { requiredIfMultiple: ['content_metadata.cc', '', 'content_metadata.bcc', ''] }]
  },
  'content_metadata.cc': {
    label: 'CC',
    placeholder: 'Enter a comma-separated list of internal or external email addresses to CC a report to',
    rules: ['commaSeparatedEmails', { requiredIfMultiple: ['recipients', '', 'content_metadata.bcc', ''] }]
  },
  'content_metadata.bcc': {
    label: 'BCC',
    placeholder: 'Enter a comma-separated list of internal or external email addresses to BCC a report to',
    rules: ['commaSeparatedEmails', { requiredIfMultiple: ['recipients', '', 'content_metadata.cc', ''] }]
  },
  content_type: {
    label: 'Share',
    placeholder: 'Select Content Type',
    rules: 'required'
  },
  'content_metadata.filename': {
    label: 'Report File Name',
    placeholder: 'Enter a file name for the report',
    helpText: 'To include date, use YYYYMMDD (e.g. YYYYMMDD-Report)',
    rules: 'required|between:2,200|htmlSanitary'
  },
  export_type: {
    label: 'Report File Type',
    placeholder: 'Select Report File Type',
    rules: 'required',
    defaultValue: 'pdf',
    transform: {
      // legacy subscriptions may have null value
      in: (value) => value || 'pdf'
    }
  },
  content_id: {
    label: 'Content',
    placeholder: 'Select Content',
    transform: {
      // falsy values need to be null for postgres
      in: (value) => value || null
    },
    rules: ['required_if:content_type,dashboard', 'required_if:content_type,savedview']
  },
  'content_metadata.values': {
    label: 'Content to Email',
    placeholder: 'Select Content',
    rules: [
      'required_if:content_type,capacityplans',
      'required_if:content_type,costproviders',
      'required_if:content_type,costconnectivitytypes',
      'required_if:content_type,costsites',
      'required_if:content_type,costsitemarkets'
    ]
  },
  delivery_period: {
    label: 'Schedule',
    options: [
      {
        value: 'day',
        label: 'Daily'
      },
      {
        value: 'week',
        label: 'Weekly'
      },
      {
        value: 'month',
        label: 'Monthly'
      },
      {
        value: 'lastdaymonth',
        label: 'Last day of the month'
      }
    ]
  },
  recurrence_ordinal: {
    label: 'on',
    options: [
      { value: '0', label: 'Sunday' },
      { value: '1', label: 'Monday' },
      { value: '2', label: 'Tuesday' },
      { value: '3', label: 'Wednesday' },
      { value: '4', label: 'Thursday' },
      { value: '5', label: 'Friday' },
      { value: '6', label: 'Saturday' }
    ]
  },
  recurrence_time: {
    label: 'at',
    defaultValue: '00:00:00+00',
    options: [
      { label: '00:00 UTC', value: '00:00:00+00' },
      { label: '00:30 UTC', value: '00:30:00+00' },
      { label: '01:00 UTC', value: '01:00:00+00' },
      { label: '02:00 UTC', value: '02:00:00+00' },
      { label: '02:30 UTC', value: '02:30:00+00' },
      { label: '03:00 UTC', value: '03:00:00+00' },
      { label: '03:30 UTC', value: '03:30:00+00' },
      { label: '04:00 UTC', value: '04:00:00+00' },
      { label: '04:30 UTC', value: '04:30:00+00' },
      { label: '05:00 UTC', value: '05:00:00+00' },
      { label: '05:30 UTC', value: '05:30:00+00' },
      { label: '06:00 UTC', value: '06:00:00+00' },
      { label: '06:30 UTC', value: '06:30:00+00' },
      { label: '07:00 UTC', value: '07:00:00+00' },
      { label: '07:30 UTC', value: '07:30:00+00' },
      { label: '08:00 UTC', value: '08:00:00+00' },
      { label: '08:30 UTC', value: '08:30:00+00' },
      { label: '09:00 UTC', value: '09:00:00+00' },
      { label: '09:30 UTC', value: '09:30:00+00' },
      { label: '10:00 UTC', value: '10:00:00+00' },
      { label: '10:30 UTC', value: '10:30:00+00' },
      { label: '11:00 UTC', value: '11:00:00+00' },
      { label: '11:30 UTC', value: '11:30:00+00' },
      { label: '12:00 UTC', value: '12:00:00+00' },
      { label: '12:30 UTC', value: '12:30:00+00' },
      { label: '13:00 UTC', value: '13:00:00+00' },
      { label: '13:30 UTC', value: '13:30:00+00' },
      { label: '14:00 UTC', value: '14:00:00+00' },
      { label: '14:30 UTC', value: '14:30:00+00' },
      { label: '15:00 UTC', value: '15:00:00+00' },
      { label: '15:30 UTC', value: '15:30:00+00' },
      { label: '16:00 UTC', value: '16:00:00+00' },
      { label: '16:30 UTC', value: '16:30:00+00' },
      { label: '17:00 UTC', value: '17:00:00+00' },
      { label: '17:30 UTC', value: '17:30:00+00' },
      { label: '18:00 UTC', value: '18:00:00+00' },
      { label: '18:30 UTC', value: '18:30:00+00' },
      { label: '19:00 UTC', value: '19:00:00+00' },
      { label: '19:30 UTC', value: '19:30:00+00' },
      { label: '20:00 UTC', value: '20:00:00+00' },
      { label: '20:30 UTC', value: '20:30:00+00' },
      { label: '21:00 UTC', value: '21:00:00+00' },
      { label: '21:30 UTC', value: '21:30:00+00' },
      { label: '22:00 UTC', value: '22:00:00+00' },
      { label: '22:30 UTC', value: '22:30:00+00' },
      { label: '23:00 UTC', value: '23:00:00+00' },
      { label: '23:30 UTC', value: '23:30:00+00' }
    ]
  },
  lookback_period: {
    label: 'Lookback',
    defaultValue: 'days',
    options: [
      { label: 'Days', value: 'days' },
      { label: 'This Month (month to date)', value: 'this_month' },
      { label: 'Last Month', value: 'last_month' }
    ]
  },
  lookback_days: {
    label: 'Days',
    rules: 'min:1|max:90|numeric|required'
  },

  parametric_fields: {
    isComplexArray: true
  },
  'parametric_fields[].type': {},
  'parametric_fields[].label': {},
  'parametric_fields[].question': {},
  'parametric_fields[].value': {
    label: 'Filter Value'
  }
};

const options = {
  name: 'Subscription'
};

export { fields, options };
