import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormDialog, Form } from 'core/form';
import { getHashForQueries } from 'app/stores/query/urlHash';

import {
  fields as dashbaordDetailsFields,
  options as dashboardDetailsOptions
} from 'app/forms/config/dashboardDetails';
import { fields as dashboardItemFields, options as dashboardItemOptions } from 'app/forms/config/dashboardItemDetails';

import DashboardModel from 'app/stores/dashboard/DashboardModel';
import DashboardItemModel from 'app/stores/dashboard/DashboardItemModel';
import QueryModel from 'app/stores/query/QueryModel';
import DashboardItemForm from './DashboardItemForm';
import DashboardDetailsForm from '../DashboardDetailsForm';

@Form({
  fields: {
    ...dashboardItemFields,
    ...dashbaordDetailsFields
  },
  options: {
    ...dashboardDetailsOptions,
    ...dashboardItemOptions
  }
})
@inject('$dashboard', '$dashboards', '$explorer')
@observer
export default class CloneDashboardItemDialog extends Component {
  state = {
    cloneNewDashboardStage: undefined,
    dashboardItem: undefined,
    dashboard: undefined,
    dashboardItemFormValues: undefined,
    dashboardItemGroupValues: undefined,
    dataviewValues: undefined
  };

  constructor(props) {
    super(props);
    const { srcDashboardItem, isOpen } = props;
    if (isOpen && srcDashboardItem) {
      const dashboardItem = this.createNewDashboardItem(srcDashboardItem);

      if (dashboardItem.dataview.formConfig) {
        this.initializeDataViewFields(dashboardItem);
      }

      this.state = {
        ...this.state,
        cloneNewDashboardStage: 'dashboardItem',
        dashboardItem
      };
    }
  }

  componentWillUnmount = () => {
    const { $explorer } = this.props;
    $explorer.destroy();
  };

  /**
   * Adds the required form fields required by this DashboardItemModel's dataview for configuration,
   * and initializes their values.
   */
  initializeDataViewFields = (dashboardItem) => {
    const { form } = this.props;

    const dataviewFields = dashboardItem.dataview.formConfig;
    const dashboardItemValues = dashboardItem.get();
    const dataviewValues = dashboardItem.dataview.viewModel.get();

    const formValues = {
      ...dashboardItemValues,
      ...dataviewValues
    };

    // add the dataviews fields to the form
    form.addFields(dataviewFields);

    // add them to the `dataviews` field group so it's easier to pull them out later.
    form.addFieldsToGroup(dataviewFields, 'dataview');

    form.init(formValues);
    form.getField('dashboard_id').setRules('');
    form.getField('dash_title').setRules('');
    form.getField('share_level').setRules('');
  };

  createNewDashboardItem = (sourceDashboardItem) => {
    const { form } = this.props;
    const { id, dashboard, dashboard_id, panel_title, ...rest } = sourceDashboardItem.toJS();
    const { viewModel } = sourceDashboardItem.dataview;
    const data = {
      panel_title: `[COPY] ${panel_title}`,
      ...rest
    };

    if (viewModel) {
      data.viewModel = viewModel.duplicate();
    }

    const dashboardItem = new DashboardItemModel();
    dashboardItem.set(dashboardItem.deserialize(data));

    form.setModel(dashboardItem);
    dashboardItem.fetchSavedQuery();
    form.getField('dashboard_id').setRules('');
    form.getField('dash_title').setRules('');
    form.getField('share_level').setRules('');
    return dashboardItem;
  };

  handleClose = () => {
    const { $dashboard, $explorer, form } = this.props;
    const { cloneNewDashboardStage } = this.state;

    // when we're closing the dashboard item dialog and wanting to now open the dashboard detail dialog
    if (cloneNewDashboardStage === 'dashboardItem' && form.model.type === 'Dashboard') {
      return;
    }

    this.setState(() => ({
      cloneNewDashboardStage: undefined,
      dashboardItem: undefined,
      dashboard: undefined,
      dashboardItemFormValues: undefined,
      dashboardItemGroupValues: undefined,
      dataviewValues: undefined
    }));
    $explorer.destroy();
    $dashboard.setCloningDashboardItem(false);
  };

  handleDashboardItemSave = (form) => {
    const { $explorer } = this.props;
    const { dashboardItem } = this.state;
    const { queryModel, formState } = $explorer;

    const dashboardItemFormValues = form.getValues();
    const dashboardItemGroupValues = form.getFieldGroupValues('item');

    const dataviewValues = {
      ...form.getFieldGroupValues('dataview'),
      query_title: dashboardItemFormValues.panel_title
    };
    dashboardItem.set(form.getValues());
    let explorerQuerySwitches = {};
    if (formState) {
      formState.submit(() => {
        queryModel.set(formState.getValues());
        $explorer.apply(QueryModel.create(queryModel.serialize()), true);
        formState.setModel(queryModel);
      });
      explorerQuerySwitches = {
        time_locked: formState.getValue('time_locked'),
        device_locked: formState.getValue('device_locked'),
        filter_source: formState.getValue('filter_source')
      };
    }

    const dashboard = new DashboardModel();
    dashboard.set({ dash_title: 'My New View', share_level: 'self' });

    form.setModel(dashboard);
    form.getField('dashboard_id').setRules('');
    form.getField('dash_title').setRules('required');
    form.getField('share_level').setRules('required');

    this.setState(() => ({
      cloneNewDashboardStage: 'dashboard',
      dashboard,
      dashboardItemFormValues,
      dashboardItemGroupValues,
      explorerQuerySwitches,
      dataviewValues
    }));
  };

  handleDashboardSave = async (form) => {
    const { $explorer, $dashboards } = this.props;
    const { dashboardItem, dashboardItemFormValues, dashboardItemGroupValues, dataviewValues, explorerQuerySwitches } =
      this.state;
    const { dataview } = dashboardItem;

    const { panel_title, parametric_mode, parametric_overrides, ...formValues } = form.getValues();
    let saved_query_id;

    if (!dataview.isFlowVisualization || (dashboardItem.isNew && dataview.isFlowVisualization)) {
      // TODO: get this await out of here
      saved_query_id = await getHashForQueries(dataviewValues, { persist: true });
    } else {
      saved_query_id = dashboardItem.get('saved_query_id');
    }
    const attributes = { ...dashboardItemGroupValues, saved_query_id };

    const dashboardModel = $dashboards.collection.forge();
    return dashboardModel.save(formValues, { clearSelection: false }).then(() => {
      dashboardItem.setRequestStatus('updating');

      if (dataview.isFlowVisualization) {
        $explorer.dataview.queryBuckets.save({ persist: true }).then((hash) =>
          dashboardItem
            .save(
              Object.assign(
                {
                  ...dashboardItemFormValues,
                  ...{
                    dashboard_id: dashboardModel.get('id'),
                    panel_title,
                    parametric_mode,
                    parametric_overrides
                  }
                },
                { saved_query_id: hash, ...explorerQuerySwitches }
              ),
              { toast: false }
            )
            .then(() => {
              this.handleClose();
              $dashboards.navigateToDashboard(dashboardModel);
            })
        );
      } else {
        dashboardItem
          .save(
            Object.assign({
              ...attributes,
              ...dashboardItemFormValues,
              ...{
                dashboard_id: dashboardModel.get('id'),
                panel_title
              }
            })
          )
          .then(() => {
            this.handleClose();
            $dashboards.navigateToDashboard(dashboardModel);
          });
      }
    });
  };

  renderDashboardItemForm = () => {
    const { dashboardItem } = this.state;
    return (
      <FormDialog
        {...this.props}
        model={dashboardItem}
        style={{
          height: 'calc(100vh - 100px)',
          width: 'calc(100vw - 200px)',
          maxWidth: 1600
        }}
        entityName="View Panel"
        onClose={this.handleClose}
        formActionsProps={{ onSubmit: this.handleDashboardItemSave, onCancel: this.handleClose }}
      >
        <DashboardItemForm
          {...this.props}
          model={dashboardItem}
          handleSave={this.handleDashboardItemSave}
          showDashboardSelect={false}
        />
      </FormDialog>
    );
  };

  renderDashboardDetailsForm = () => {
    const { dashboard, dashboardItem } = this.state;

    return (
      <FormDialog
        {...this.props}
        model={dashboard}
        style={{ width: 850 }}
        entityName="New View"
        onClose={this.handleClose}
        formActionsProps={{ onSubmit: this.handleDashboardSave, onCancel: this.handleClose }}
      >
        <DashboardDetailsForm {...this.props} dashboardItem={dashboardItem} />
      </FormDialog>
    );
  };

  render() {
    const { isOpen } = this.props;
    const { cloneNewDashboardStage } = this.state;

    if (cloneNewDashboardStage === 'dashboard') {
      return this.renderDashboardDetailsForm();
    }

    if (cloneNewDashboardStage === 'dashboardItem') {
      return this.renderDashboardItemForm(isOpen);
    }

    return null;
  }
}
