import React from 'react';
import { observer } from 'mobx-react';

import Tag from 'core/components/Tag';

import { getOption } from '../../form/components/modalSelect/selectHelpers';

const SelectValuesRenderer = (props) => {
  const { onUnselectBuilder, options, tagFill, valueRenderer, valueTagProps, values } = props;

  if (!options) {
    return null;
  }

  return values.map((val) => {
    const valueOption = getOption(options, val);

    if (!valueOption) {
      return null;
    }

    const onRemove = onUnselectBuilder(valueOption.value || valueOption);

    return (
      <Tag
        key={valueOption.key || val}
        minimal
        fill={tagFill}
        onRemove={onRemove}
        round
        bg={valueOption.bg}
        color={valueOption.color}
        {...valueTagProps}
      >
        {valueRenderer(valueOption)}
      </Tag>
    );
  });
};

export default observer(SelectValuesRenderer);
