import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { Box, Button, Suspense, Spinner, Select as CoreSelect } from 'core/components';
import { Field, FormComponent, Select } from 'core/form';
import Model from 'core/model/Model';
import TabResultsTable from 'app/components/detailTabs/TabResultsTable';
import WidgetFrame from '../WidgetFrame';

const FORM_OPTIONS = {
  name: 'Traffic Breakdown Widget Config'
};

@inject('$tabs')
@withTheme
@observer
class TrafficBreakdownWidget extends Component {
  state = {
    isConfigurePanelOpen: false,
    config: null,
    loading: true
  };

  static getDerivedStateFromProps(props, state) {
    const { config } = props;

    // If there's no config in state, let's get setup
    if (!state.config) {
      // If there's no config, we show the config panel
      if (!config) {
        return { config: {}, isConfigurePanelOpen: true, model: new Model() };
      }
      return { config, model: new Model(config) };
    }

    return null;
  }

  componentDidMount() {
    const { $tabs } = this.props;
    const { config } = this.state;

    const tabs = Object.values($tabs.getTabs());
    const tabOptions = tabs.map((tab) => ({ value: tab.id, label: tab.title }));
    this.setState({ tabOptions });

    if (config.default_tab_id) {
      if (config.default_tab_id === 'last') {
        this.setState({ loading: false, tab: tabs.find((tab) => tab.id === config.tab_id) });
      } else {
        this.setState({ loading: false, tab: tabs.find((tab) => tab.id === config.default_tab_id) });
      }
    } else {
      this.setState({ loading: false });
    }
  }

  handleSave = (form) => {
    const { $tabs, onConfigChange } = this.props;
    const { model, config } = this.state;

    let newConfig;
    const { default_tab_id } = form.getValues();
    model.set('default_tab_id', default_tab_id);
    if (default_tab_id === 'last') {
      newConfig = { ...config, default_tab_id };
      this.setState({ isConfigurePanelOpen: false, config: newConfig });
    } else {
      model.set('tab_id', default_tab_id);
      const newTab = Object.values($tabs.getTabs()).find((tab) => tab.id === default_tab_id);
      newConfig = { tab_id: default_tab_id, default_tab_id };
      this.setState({ isConfigurePanelOpen: false, config: newConfig, tab: newTab });
    }

    if (onConfigChange) {
      onConfigChange(newConfig);
    }
  };

  handleCancel = (form) => {
    form.reset();
    this.setState({ isConfigurePanelOpen: false });
  };

  handleShowConfigurePanel = () => {
    this.setState({ isConfigurePanelOpen: true });
  };

  renderConfigurePanel() {
    const { tabOptions = [], model, config } = this.state;
    const fields = {
      default_tab_id: {
        label: 'Default Traffic Breakdown Dimension',
        options: [{ value: 'last', label: 'Last Selected' }, ...tabOptions],
        rules: 'required'
      }
    };

    return (
      <FormComponent fields={fields} options={FORM_OPTIONS} model={model}>
        {({ form }) => (
          <Box p={2}>
            <Box pt={2}>
              <Field name="default_tab_id" large>
                <Select />
              </Field>
            </Box>
            {config.default_tab_id && (
              <Button text="Cancel" onClick={() => this.handleCancel(form)} mr={1} minWidth={100} small />
            )}
            <Button
              text="Save"
              intent="primary"
              disabled={!form.valid}
              onClick={() => this.handleSave(form)}
              minWidth={100}
              small
            />
          </Box>
        )}
      </FormComponent>
    );
  }

  handleTabChange = (tab_id) => {
    const { onConfigChange, $tabs } = this.props;
    const { config, model } = this.state;

    model.set('tab_id', tab_id);
    const newTab = Object.values($tabs.getTabs()).find((tab) => tab.id === tab_id);
    const newConfig = { ...config, tab_id };
    this.setState({ config: newConfig, tab: newTab });

    if (onConfigChange && config.default_tab_id === 'last') {
      onConfigChange(newConfig);
    }
  };

  renderData() {
    const { tab, tabOptions } = this.state;

    const queryOverrides = {
      lookback_seconds: 86400,
      aggregateType: 'p95th_bits_per_sec'
    };

    return (
      <>
        <Box p={1}>
          <CoreSelect options={tabOptions} values={tab?.id} onChange={this.handleTabChange} />
        </Box>
        {tab && <TabResultsTable queryOverrides={queryOverrides} useCard={false} {...tab} />}
      </>
    );
  }

  render() {
    const { onRemove, canCustomize } = this.props;
    const { tab, isConfigurePanelOpen, loading } = this.state;

    const title = tab ? `Traffic By ${tab.title}` : 'Traffic Breakdown';

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        configAction={this.handleShowConfigurePanel}
        onRemove={onRemove}
        title={title}
        display="flex"
        flexDirection="column"
      >
        <Suspense loading={loading} fallback={<Spinner size={24} />}>
          {isConfigurePanelOpen && this.renderConfigurePanel()}
          {!isConfigurePanelOpen && this.renderData()}
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default TrafficBreakdownWidget;
