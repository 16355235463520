import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { Box, Button, Callout, Flex, Text } from 'core/components';
import { formConsumer } from 'core/form';
// import { applyRestrictions } from 'core/utils';
import { addFilterGroup } from 'app/util/filters';
import SavedFilter from 'app/stores/savedFilter/SavedFilter';

import { Classes } from '@blueprintjs/core';
import FilterGroup from './FilterGroup';
import FilterGroupConnector from './FilterGroupConnector';
import SaveFiltersDialog from './SaveFiltersDialog';
import SavedFilterSelector from './SavedFilterSelector';

@inject('$savedFilters', '$auth')
@formConsumer
@observer
export default class FilterOptions extends Component {
  static defaultProps = {
    disabled: false,
    readOnly: false,
    showEmptyText: true,
    showButtonText: true,
    showNames: false,
    fieldName: 'filters',
    filterGroupsField: undefined,
    connectorField: undefined,
    groupLabel: 'Group',
    groupLabelPlural: 'Groups',
    filterGroupLabel: 'filter group',
    filterGroupLabelPlural: 'filter groups',
    adHocLabel: 'Filter Groups',
    showTopLevelConnector: true,
    allowSavedFilters: true,
    allowRightFilterField: true
  };

  state = {
    savedFilterModel: null
  };

  handleAddFilterGroupAdHoc = () => {
    const { form, filterGroupsField, fieldName, showNames } = this.props;

    const filterGroups = filterGroupsField || form.getField(`${fieldName}.filterGroups`);

    addFilterGroup(filterGroups, { named: !!showNames });
  };

  handleAddFilterGroupSavedFilter = ({ id }) => {
    const { form, filterGroupsField, fieldName, showNames } = this.props;
    const filterGroups = filterGroupsField || form.getField(`${fieldName}.filterGroups`);

    addFilterGroup(filterGroups, { named: !!showNames, saved_filters: [{ filter_id: parseInt(id) }] });
  };

  /**
   * When we "Save" a set of filters, we're just creating a new "SavedFilter". Take whatever
   * values are in the form, and clone them into a new SavedFilter and show a form.
   */
  handleSaveActiveFilters = () => {
    const { form } = this.props;
    const { filters } = form.getValues();
    this.setState({ savedFilterModel: new SavedFilter({ filters }) });
  };

  closeSaveActiveFiltersDialog = () => {
    const { $savedFilters } = this.props;

    $savedFilters.collection.fetch();

    this.setState({ savedFilterModel: null });
  };

  handleRemoveAllFilterGroups = () => {
    const { form, filterGroupsField, fieldName } = this.props;

    const filterGroups = filterGroupsField || form.getField(`${fieldName}.filterGroups`);
    filterGroups.setValue([]);
  };

  render() {
    const { savedFilterModel } = this.state;
    const {
      form,
      $auth,
      readOnly,
      disabled,
      fieldName,
      showButtonText,
      showSaveFiltersButton,
      showSavedFilters,
      groupLabelPlural,
      filterGroupLabel,
      filterGroupLabelPlural,
      adHocLabel,
      showTopLevelConnector,
      required,
      allowSavedFilters,
      filterGroupsField,
      connectorField,
      loading,
      ...rest
    } = this.props;

    const filterGroups = filterGroupsField || form.getField(`${fieldName}.filterGroups`);
    const filterGroupsValue = filterGroupsField
      ? filterGroupsField.getValue()
      : form.getValue(`${fieldName}.filterGroups`);
    const groupConnector = connectorField || form.getField(`${fieldName}.connector`);

    const filterGroupClassName = classNames('filter-groups', {
      'filter-groups-readonly': readOnly
    });

    const numFilterGroups = filterGroupsValue && filterGroupsValue.length;

    return (
      <Box {...rest}>
        <Flex mb={1} gap={1} alignItems="center">
          <Text as="div" fontWeight="medium" fontSize="small">
            {required && numFilterGroups === 0 && <Text color="danger">{adHocLabel}</Text>}
            {(!required || numFilterGroups > 0) && adHocLabel}
          </Text>

          {showTopLevelConnector && numFilterGroups > 1 && !disabled && (
            <FilterGroupConnector connector={groupConnector} readOnly={readOnly} />
          )}
          <Flex flex={1} justifyContent="flex-end">
            {showSaveFiltersButton && $auth.isAdministrator && numFilterGroups > 0 && (
              <Button
                onClick={this.handleSaveActiveFilters}
                icon="floppy-disk"
                text="Save Filters"
                disabled={disabled}
                small
                minimal
              />
            )}
            {numFilterGroups > 0 && (
              <Button
                onClick={this.handleRemoveAllFilterGroups}
                disabled={disabled || numFilterGroups === 0}
                text={`Remove All ${groupLabelPlural}`}
                small
                intent="danger"
                minimal
              />
            )}
          </Flex>
        </Flex>

        {numFilterGroups === 0 && !disabled && (
          <Callout textAlign="center" p={2}>
            {!required && (
              <Text mb={1} muted>
                No {filterGroupLabelPlural} have been applied
              </Text>
            )}
            {required && (
              <Box className={Classes.FORM_GROUP} mt={0} mb={1}>
                <Text className={Classes.FORM_HELPER_TEXT} color="danger" style={{ fontSize: 14, marginTop: 0 }}>
                  At least one {filterGroupLabel} with an ad hoc or saved filter is required
                </Text>
              </Box>
            )}
            <Flex mt={1} alignItems="center" justifyContent="center">
              <Button onClick={this.handleAddFilterGroupAdHoc} icon="plus" mr={allowSavedFilters ? 1 : 0}>
                Add Ad Hoc Filter
              </Button>
              {allowSavedFilters && (
                <SavedFilterSelector onAdd={this.handleAddFilterGroupSavedFilter} width={150} large />
              )}
            </Flex>
          </Callout>
        )}

        {numFilterGroups > 0 && (
          <Box className={filterGroupClassName}>
            {filterGroups.map((group, index) => (
              <FilterGroup
                {...this.props}
                key={group.connector._id}
                form={form}
                group={group}
                index={index}
                groups={filterGroups}
                readOnly={readOnly}
                showButtonText={showButtonText}
                mb={2}
              />
            ))}
            <Box flex={1} mt={1}>
              <Text as="div" color="muted" fontWeight="medium" fontSize="small">
                Add {filterGroupLabel}
              </Text>
              <Flex mt={1} alignItems="center">
                <Button icon="plus" onClick={this.handleAddFilterGroupAdHoc} disabled={disabled} mr={1}>
                  Add Ad Hoc Filter
                </Button>
                {allowSavedFilters && (
                  <SavedFilterSelector onAdd={this.handleAddFilterGroupSavedFilter} width={150} large />
                )}
              </Flex>
            </Box>
          </Box>
        )}

        {showSavedFilters && savedFilterModel && (
          <SaveFiltersDialog
            isOpen={savedFilterModel}
            onClose={this.closeSaveActiveFiltersDialog}
            model={savedFilterModel}
          />
        )}
      </Box>
    );
  }
}
