import React from 'react';
import { Classes } from '@blueprintjs/core';

import Text from './Text';

const Label = (props) => <Text className={Classes.LABEL} {...props} />;

Label.displayName = 'Label';

export default Label;
