import $dictionary from 'app/stores/$dictionary';
import $metrics from 'app/stores/metrics/$metrics';
import { applyRestrictions } from 'core/util/restrictions';
import { buildFilterGroup } from 'core/util/filters';
import { getFullSelectedMeasurement } from 'app/util/policies';
import { INTERFACE_FILTER_MAP } from 'shared/alerting/constants';

export const overriddenModelFilterFields = [
  'src_geo_city',
  'dst_geo_city',
  'src_geo_region',
  'dst_geo_region',
  'bgp_ult_exit_interface',
  'InterfaceID_dst',
  'InterfaceID_src',
  'kt_aws_src_vpc_name',
  'kt_aws_dst_vpc_name',
  'kt_aws_src_subnet_name',
  'kt_aws_dst_subnet_name',
  'ktappprotocol__snmp__output_port'
];

function determineOperatorForMetric(metric, operator, cutFn) {
  const specialOps = $dictionary.dictionary.queryFilters.operators;
  const likeSwitch = operator === '=' ? 'ILIKE' : 'NOT ILIKE';

  if (cutFn && cutFn[metric]) {
    return likeSwitch;
  }

  if (specialOps[metric] && !specialOps[metric][operator] && specialOps[metric][likeSwitch]) {
    return likeSwitch;
  }

  return operator;
}

function getDistinctFilterFieldsForGroup(group = {}) {
  const { filters = [], filterGroups = [] } = group;
  let groupFilters = [];

  if (!filters.length && filterGroups.length) {
    // no filters, but has filterGroups, so use first group for "distinct" list
    groupFilters = getDistinctFilterFieldsForGroup(filterGroups[0]);

    // but if there's more than one group, make sure all the groups match
    if (
      filterGroups.length > 1 &&
      !filterGroups.every((filterGroup) => {
        const filterGroupFilters = getDistinctFilterFieldsForGroup(filterGroup);
        return groupFilters.every((filterField) => filterGroupFilters.includes(filterField));
      })
    ) {
      groupFilters = [];
    }
  } else if (filters.length && !filterGroups.length) {
    // has filters and no filterGroups, so just grab the filterFields
    groupFilters = filters.map((filter) => filter.filterField);
  }

  // uniq the list before sending it back
  return Array.from(new Set(groupFilters));
}

function getDistinctIncludeForGroup(group = {}) {
  const { filters = [], filterGroups = [] } = group;
  let groupInclude = {};

  if (!filters.length && filterGroups.length) {
    // no filters, but has filterGroups, so use first group for the include info
    groupInclude = {
      connector: filterGroups[0].connector,
      not: filterGroups[0].not
    };

    // but if there's more than one group, make sure all the groups match
    if (
      filterGroups.length > 1 &&
      !filterGroups.every(
        (filterGroup) => groupInclude.connector === filterGroup.connector && groupInclude.not === filterGroup.not
      )
    ) {
      groupInclude = {};
    }
  } else if (filters.length && !filterGroups.length) {
    // has filters and no filterGroups, so just grab the include info from the group
    groupInclude = {
      connector: group.connector,
      not: group.not
    };
  }

  return groupInclude;
}

export function stripMetricFromFilterGroups(group = {}) {
  if (group.metric) {
    delete group.metric;
  }
  if (group.filterGroups) {
    return group.filterGroups.forEach((filterGroup) => stripMetricFromFilterGroups(filterGroup));
  }
  return group;
}

export function addToQueryFilters({ queryFilters, filters, field, include, createNewGroup }) {
  let connector = include ? 'Any' : 'All';

  if (include && field.length && (field.length > 1 || filters.length > 1)) {
    connector = 'All';
  }

  const newGroup = buildFilterGroup({ connector, filters: filters || [], not: !include, autoAdded: true });

  const metricToFilterParsers = $dictionary.get('queryFilters.metricToFilterParsers');
  const targetFilterFields = field.reduce((arr, dimension) => {
    if (metricToFilterParsers[dimension]) {
      metricToFilterParsers[dimension].forEach((parser) => arr.push(parser.field));
    } else {
      arr.push(dimension);
    }
    return arr;
  }, []);

  let existingGroup;

  if (!createNewGroup) {
    // try and find an existing filter group to add the new filter to
    existingGroup = queryFilters.filterGroups.find((filterGroup) => {
      const filterGroupFilterFields = getDistinctFilterFieldsForGroup(filterGroup);
      const groupInclude = getDistinctIncludeForGroup(filterGroup);

      // a matching group must have the same filter fields and the same include/exclude boolean
      return (
        targetFilterFields.length === filterGroupFilterFields.length &&
        targetFilterFields.every((filterField) => filterGroupFilterFields.includes(filterField)) &&
        !include === groupInclude.not
      );
    });
  }

  if (existingGroup) {
    if (existingGroup.filters.length) {
      if (field.length && field.length === 1) {
        existingGroup.connector = 'Any';
        existingGroup.filters.push(...filters);
      } else {
        existingGroup.connector = existingGroup.not ? 'All' : 'Any';
        existingGroup.not = false;
        existingGroup.filterGroups = [
          buildFilterGroup({ connector, filters: existingGroup.filters || [], not: !include, autoAdded: true })
        ];
        existingGroup.filters = [];
      }
    }

    if (existingGroup.filterGroups && existingGroup.filterGroups.length) {
      existingGroup.filterGroups.push(newGroup);
    }
  } else {
    queryFilters.filterGroups.push(
      field.length === 1 && filters.length > 1
        ? buildFilterGroup({ connector: include ? 'Any' : 'All', filterGroups: [newGroup], autoAdded: true })
        : newGroup
    );
  }

  if (!include || queryFilters.filterGroups.find((filterGroup) => filterGroup.not)) {
    queryFilters.connector = 'All';
  }

  return queryFilters;
}

/**
 * Takes a QueryResult model, a QueryBucket model and a form (which contains filters)
 * and generates a new set of filters combining existing filters with a filter
 * to include or exclude the provided model.
 *
 * @param model - the model from which to get data to build the filter
 * @param bucket - the bucket from which to get metrics for filters
 * @param formState - the form from which to merge the resultant filters
 * @param filtersAcc - the parent filters group which will be used to merge the filters
 * @param options - { include } to build a filter that includes/excludes the provided model (defaults to true)
 * @param options - { overrideMetric } to build a filter that includes/excludes only the overrideMetric from the provided model (defaults to null)
 * @returns Filter Object
 */
export function getModelFilters({ model, bucket, formState, filtersAcc, query }, options = {}) {
  const { include = true, overrideMetric = null, createNewGroup = false } = options;
  const lookup = model.get('lookup');
  const key = model.get('key');

  // we are doing an array here in case we decide to handle all selected rows later
  const values = [lookup || key];

  const activeFilters = {
    connector: filtersAcc ? filtersAcc.connector : formState.getValue('filters.connector'),
    filterGroups: filtersAcc ? filtersAcc.filterGroups : formState.getValue('filters.filterGroups')
  };
  const metric = query ? query.metric : bucket.firstQuery.get('metric');
  const cutFn = query ? query.filters : bucket.firstQuery.get('filters');
  const parsedMetrics = $dictionary.get('queryFilters.metricToFilterParsers');
  const metricColumns = $dictionary.get('metricColumns');
  const connector = include ? 'Any' : 'All';

  let matches;
  let filters = [];
  let filterVal;
  let filterValOverrideField; // Field to pull filterVal from
  let queryFilters = activeFilters;
  if (!activeFilters || !Array.isArray(activeFilters.filterGroups)) {
    queryFilters = {
      connector,
      filterGroups: []
    };
  }

  const fieldOverrides = [];
  if (overrideMetric) {
    fieldOverrides.push(overrideMetric);
  }
  const field = metric.slice();
  if (field.includes('src_geo_city') && !field.includes('src_geo_region')) {
    field.push('src_geo_region');
    if (overrideMetric === field) {
      fieldOverrides.push('src_geo_region');
    }
  }

  if (field.includes('dst_geo_city') && !field.includes('dst_geo_region')) {
    field.push('dst_geo_region');
    if (overrideMetric === field) {
      fieldOverrides.push('dst_geo_region');
    }
  }

  if (field.includes('src_geo_region') && !field.includes('Geography_src')) {
    field.push('Geography_src');
    if (overrideMetric === field) {
      fieldOverrides.push('Geography_src');
    }
  }

  if (field.includes('dst_geo_region') && !field.includes('Geography_dst')) {
    field.push('Geography_dst');
    if (overrideMetric === field) {
      fieldOverrides.push('Geography_dst');
    }
  }

  if (field.includes('bgp_ult_exit_interface') && !field.includes('i_ult_exit_device_name')) {
    field.push('i_ult_exit_device_name');
    if (overrideMetric === field) {
      fieldOverrides.push('i_ult_exit_device_name');
    }
  }

  if (
    (field.includes('InterfaceID_dst') ||
      field.includes('InterfaceID_src') ||
      field.includes('ktappprotocol__snmp__output_port')) &&
    !field.includes('i_device_id')
  ) {
    field.push('i_device_id');
    if (overrideMetric === field) {
      fieldOverrides.push('i_device_id');
    }
  }

  if (field.includes('kt_aws_src_vpc_name')) {
    field.push('kt_aws_src_vpc_id');
    if (overrideMetric === field) {
      fieldOverrides.push('kt_aws_src_vpc_id');
    }
  }
  if (field.includes('kt_aws_dst_vpc_name')) {
    field.push('kt_aws_dst_vpc_id');
    if (overrideMetric === field) {
      fieldOverrides.push('kt_aws_dst_vpc_id');
    }
  }
  if (field.includes('kt_aws_src_subnet_name')) {
    field.push('kt_aws_src_subnet_id');
    if (overrideMetric === field) {
      fieldOverrides.push('kt_aws_src_subnet_id');
    }
  }
  if (field.includes('kt_aws_dst_subnet_name')) {
    field.push('kt_aws_dst_subnet_id');
    if (overrideMetric === field) {
      fieldOverrides.push('kt_aws_dst_subnet_id');
    }
  }

  if (field.length && field.length > 1) {
    for (let y = 0, ylen = values.length; y < ylen; y += 1) {
      filters = [];
      const allFilterVals = values[y].split(' ---- ');
      for (let z = 0, zlen = Math.min(field.length, allFilterVals.length); z < zlen; z += 1) {
        if (!overrideMetric || field[z] === overrideMetric) {
          if (parsedMetrics[field[z]]) {
            for (let x = 0, xlen = parsedMetrics[field[z]].length; x < xlen; x += 1) {
              matches = new RegExp(parsedMetrics[field[z]][x].pattern).exec(allFilterVals[z]);
              if (matches) {
                filterVal = matches[matches.length - 1];
                if (filterVal === '---') {
                  filterVal = '';
                }
              } else {
                filterVal = '';
              }
              let filterField = parsedMetrics[field[z]][x].field;
              if (field[z].startsWith('ktappprotocol' || field[z].startsWith('ktsubtype'))) {
                filterField = field[z];
              }
              filters.push({
                filterField,
                operator: determineOperatorForMetric(filterField, '=', cutFn),
                filterValue: `${filterVal}`
              });
            }
          } else {
            filterVal = allFilterVals[z];

            // filterValOverrideField is used to substitute a different field as the filter value for a given filter
            if (
              field[z] === 'kt_aws_src_vpc_name' ||
              field[z] === 'kt_aws_dst_vpc_name' ||
              field[z] === 'kt_aws_src_subnet_name' ||
              field[z] === 'kt_aws_dst_subnet_name'
            ) {
              filterValOverrideField = field[z].replace('name', 'id');
            }

            if (filterValOverrideField) {
              const overrideFieldIdx = field.indexOf(filterValOverrideField);
              filterVal = overrideFieldIdx > -1 && allFilterVals[overrideFieldIdx];
            }

            if (filterVal === '---') {
              filterVal = '';
            }
            filters.push({
              filterField: metricColumns[field[z]] || field[z],
              operator: determineOperatorForMetric(metricColumns[field[z]] || field[z], '=', cutFn),
              filterValue: `${filterVal}`
            });
          }
        }
      }

      addToQueryFilters({
        queryFilters,
        filters,
        field: overrideMetric ? fieldOverrides : field,
        include,
        createNewGroup
      });
    }
    return queryFilters;
  }

  if (parsedMetrics[field]) {
    for (let y = 0, ylen = values.length; y < ylen; y += 1) {
      filters = [];
      for (let x = 0, xlen = parsedMetrics[field].length; x < xlen; x += 1) {
        matches = new RegExp(parsedMetrics[field][x].pattern).exec(values[y]);
        filterVal = matches ? matches[matches.length - 1] : '';
        if (filterVal === '---') {
          filterVal = '';
        }
        filters.push({
          filterField: parsedMetrics[field][x].field,
          operator: determineOperatorForMetric(parsedMetrics[field][x].field, '=', cutFn),
          filterValue: `${filterVal}`
        });
      }

      addToQueryFilters({ queryFilters, filters, field, include, createNewGroup });
    }
  } else {
    for (let x = 0, xlen = values.length; x < xlen; x += 1) {
      filterVal = values[x];
      if (filterVal === '---') {
        filterVal = '';
      }
      filters.push({
        filterField: metricColumns[field] || field[0],
        operator: determineOperatorForMetric(metricColumns[field] || field[0], '=', cutFn),
        filterValue: `${filterVal}`
      });
    }

    addToQueryFilters({ queryFilters, filters, field, include, createNewGroup });
  }

  return queryFilters;
}

export function injectLegacySavedFilters(saved_filters, filters) {
  const resultantFilters = {
    connector: 'All',
    filterGroups: [
      buildFilterGroup({ saved_filters: saved_filters.map(({ filter_id, is_not = false }) => ({ filter_id, is_not })) })
    ]
  };

  if (filters.filterGroups.length > 0) {
    if (filters.connector === 'All') {
      // If it's 'all' then we can just combine groups
      resultantFilters.filterGroups.push(...filters.filterGroups);
    } else {
      // Otherwise we need to add as a sub-group
      resultantFilters.filterGroups.push(filters);
    }
  }

  return resultantFilters;
}

export function getSavedFilters(filters) {
  if (filters && filters.filterGroups && filters.filterGroups.length > 0) {
    return filters.filterGroups.reduce((acc, group) => {
      if (group.saved_filters) {
        acc.push(...group.saved_filters);
      }

      if (group.filterGroups) {
        acc.push(...getSavedFilters(group));
      }

      return acc;
    }, []);
  }

  return [];
}

export function addFilterGroup(filterGroupsField, { named = false, filters, saved_filters, filterGroups, connector }) {
  const { flatFilterFieldOptions } = $dictionary;
  const group = filterGroupsField.add({
    filters: filterGroups || saved_filters ? filters : [{}], // if saved_filters are provided don't make an empty
    saved_filters,
    named,
    filterGroups,
    connector
  });

  if (!filters && !filterGroups && !saved_filters) {
    const { filterField } = group.filters.at(0);
    const options = applyRestrictions(flatFilterFieldOptions, filterField);

    if (
      options.length > 0 &&
      (!filterField.defaultValue || !options.some((option) => option.value === filterField.defaultValue))
    ) {
      filterField.setValue(options[0].value);
    }
  }

  // TODO: we need to decide whether or not this makes sense. It's obtrusive, but helpful.
  if (named) {
    group.name.setPristine(false);
  }
}

// removes all filters with a filter field that matches any in the 'fields' list provided
export function cleanFilterFields(group = {}, fields = []) {
  const { filterGroups = [] } = group;

  const metricToFilterParsers = $dictionary.get('queryFilters.metricToFilterParsers');
  const metricToFilterParsersFields = fields.reduce((arr, field) => {
    if (metricToFilterParsers[field]) {
      metricToFilterParsers[field].forEach((parser) => arr.push(parser.field));
    }
    return arr;
  }, []);
  const cleanedFilterGroups = filterGroups
    .map((filterGroup) => ({
      ...filterGroup,
      filterGroups: filterGroup.filterGroups ? filterGroup.filterGroups.map((g) => cleanFilterFields(g, fields)) : [],
      filters: filterGroup.filters
        ? filterGroup.filters.filter(
            (g) => !fields.includes(g.filterField) && !metricToFilterParsersFields.includes(g.filterField)
          )
        : []
    }))
    .filter((filterGroup) => filterGroup.filters?.length || filterGroup.filterGroups?.length);

  return { ...group, filterGroups: cleanedFilterGroups };
}

export function getKmetricMeasurementFilterGroup(measurement) {
  const fullMeasurement = getFullSelectedMeasurement(measurement);

  if (!fullMeasurement) {
    return null;
  }

  return {
    connector: 'all',
    not: false,
    filters: [
      {
        filterField: $metrics.getAppProtocolDimension(fullMeasurement.measurement, 'km_measurement_name'),
        operator: 'equals',
        filterValue: fullMeasurement.measurement
      }
    ],
    filterGroups: [],
    savedFilters: []
  };
}

// For Interface Up/Down policies, we need to convert the selected interface generated by
// the interface selector to a filter that can be used by the policy.
export function convertInterfacesToFilterGroup(interfaces, measurement) {
  const { interface_type, device_id, ...rest } = interfaces;
  const otherKeys = Object.keys(rest);

  const filterGroups = [];
  const filterGroupBase = {
    named: true,
    name: 'INTERFACE_FILTERS', // this gets ignored by chf_alert :sadpanda:
    not: false
  };

  // There can be multiple device_ids. They are also their own filter group,
  // so users can 'AND' these with other interface filters to narrow results.
  if (Array.isArray(device_id)) {
    filterGroups.push({
      ...filterGroupBase,
      connector: 'any',
      filters: device_id.map((id) => ({
        filterField: 'km_device_id',
        operator: 'equals',
        filterValue: Number(id)
      }))
    });
  }

  const otherFilters = otherKeys.map((key) => {
    const { dimension, operator } = INTERFACE_FILTER_MAP[key];
    // transform into the "ktappprotocol__kmetrics__<>" format
    const { Column } = measurement?.storage?.Dimensions[dimension];
    const filterField = $metrics.getAppProtocolDimension(measurement?.measurement, Column);
    const filterValue = interfaces[key];

    return {
      filterField,
      operator,
      filterValue
    };
  });

  if (otherFilters.length > 0) {
    filterGroups.push({
      ...filterGroupBase,
      connector: 'all',
      filters: otherFilters
    });
  }

  return filterGroups;
}
