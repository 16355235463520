import React, { Component } from 'react';
import { inject } from 'mobx-react';

import { FlexColumn, Box } from 'core/components';
import MetricsThresholdChart from 'app/views/alerting/components/charts/MetricsThresholdChart';
import MetricsUpDownChart from 'app/views/alerting/components/charts/MetricsUpDownChart';

@inject('$metrics')
export default class NmsAlertCharts extends Component {
  render() {
    const { $metrics, alertModel, small, numChartsToShow = 3 } = this.props;
    const alarmContext = alertModel?.nmsAlarmContext;

    if (!alertModel.isNmsPolicy || !alarmContext) {
      return null;
    }

    const alarmContextMetrics = alarmContext.metrics || {};
    const dimensionToValue = alarmContext.groupKey || {};
    const measurements = Object.keys(alarmContextMetrics);
    const measurementMetricPairs = measurements.flatMap((measurement) => {
      const metricsEntry = alarmContextMetrics[measurement];
      return Object.keys(metricsEntry).map((metric) => [measurement, metric]);
    });

    return (
      <FlexColumn width="100%">
        {measurementMetricPairs.slice(0, Math.max(1, numChartsToShow)).map(([measurement, metric], idx) => {
          const key = `${measurement}-${metric}`;
          const measurementModel = $metrics.measurementModel(measurement);
          const isUpDownMetric = $metrics.metricIsEnumType(measurement, metric);
          const metricLabel = measurementModel.metricLabel(metric, true);
          const containerProps = small ? { py: 2, mx: 2, pb: isUpDownMetric ? 2 : 0 } : { py: 2 };

          return (
            <Box key={key} {...containerProps} borderTop={idx > 0 ? 'thin' : undefined}>
              {isUpDownMetric ? (
                <MetricsUpDownChart
                  device={alertModel.reconDevice}
                  measurement={measurement}
                  metric={metric}
                  dimensionToValue={dimensionToValue}
                  alertModel={alertModel}
                  title={metricLabel}
                  small={small}
                />
              ) : (
                <MetricsThresholdChart
                  model={alertModel}
                  measurement={measurement}
                  metric={metric}
                  dimensionToValue={dimensionToValue}
                  hideXAxisDate
                  small={small}
                />
              )}
            </Box>
          );
        })}
      </FlexColumn>
    );
  }
}
