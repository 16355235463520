import React from 'react';
import { Box, Card, Icon, Flex, Grid, Heading, Link, Text } from 'core/components';
import { Radio } from 'core/form';
import styled from 'styled-components';

export function FormCrumb({ backLink, title, ...rest }) {
  return (
    <Box {...rest}>
      <Link to={backLink} muted>
        <Flex alignItems="center">
          <Icon icon="chevron-left" iconSize={12} />
          <Text small>Back</Text>
        </Flex>
      </Link>
      {title && (
        <Heading level={5} mb={0}>
          {title}
        </Heading>
      )}
    </Box>
  );
}

FormCrumb.defaultProps = {
  backLink: '/v4/settings/clouds',
  title: null
};

export function FormGrid({ children, ...gridProps }) {
  return (
    <Grid gridTemplateColumns="auto 1fr" gridGap={24} gridRowGap={32} alignItems="flex-start" {...gridProps}>
      {children}
    </Grid>
  );
}

const FormStepCircle = styled(Flex)`
  background: ${(props) => props.theme.colors.onboarding.stepNumberBackground};
`;

export function FormStep({ stepNumber, title, children, ...boxProps }) {
  return (
    <>
      <FormStepCircle
        alignItems="center"
        justifyContent="center"
        bg="onboarding.stepBackground"
        width="24px"
        height="24px"
        borderRadius="12px"
      >
        <Text color="white.10" fontSize={11} fontWeight="bold">
          {stepNumber}
        </Text>
      </FormStepCircle>
      <Box {...boxProps}>
        {title && <Heading level={5}>{title}</Heading>}
        {children}
      </Box>
    </>
  );
}

FormStep.defaultProps = {
  title: null
};

export function RadioCard({ children, field, onChange, value, ...cardProps }) {
  const selectedValue = field.value;
  const isChecked = value === selectedValue;

  return (
    <Card
      display="flex"
      justifyContent="space-between"
      p={2}
      onClick={() => !isChecked && onChange(value)}
      border={isChecked ? 'active' : 'thin'}
      bg={isChecked ? 'white.9' : 'transparent'}
      style={{ cursor: 'pointer', boxShadow: 'none' }}
      {...cardProps}
    >
      <Box mr={1}>{children}</Box>
      <Radio value={value} checked={isChecked} readOnly />
    </Card>
  );
}

// TODO I think this is the only thing being used here now...delete stuff above after verifying we don't need it
export function planRenderer(plan) {
  if (!plan) {
    return null;
  }

  return (
    <Box py="4px" overflow="hidden">
      <Text as="div">
        {plan.name} ({plan.device_count}/{plan.max_devices})
      </Text>
      <Text muted ellipsis>
        {plan.description}
      </Text>
    </Box>
  );
}
