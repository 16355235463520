import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';

const ProviderLink = ({ $app, provider, children, ...rest }) => {
  if ($app.isSubtenant) {
    return children || provider;
  }

  return (
    <Link to={`/v4/core/quick-views/provider/${encodeURIComponent(provider)}`} {...rest}>
      {children || provider}
    </Link>
  );
};

export default inject('$app')(ProviderLink);
