/*
  a memoized factory for cloud icons

  props are used to obtain a match based on theme, cloud provider, and entity
*/
import PropTypes from 'prop-types';
import React, { PureComponent, memo } from 'react';
import { withTheme } from 'styled-components';
import { ENTITY_TYPES } from 'shared/hybrid/constants';

import { ReactComponent as CloudEmptyStateIcon } from 'app/assets/icons/isocon-question-exchange.svg';

// aws icons
import { ReactComponent as CustomerGatewayIcon } from 'app/assets/icons/aws/customer-gateway.svg';
import { ReactComponent as NetworkInterfaceIcon } from 'app/assets/icons/aws/network-interface.svg';
import { ReactComponent as DarkCustomerGatewayIcon } from 'app/assets/icons/aws/customer-gateway-dark.svg';
import { ReactComponent as DirectConnectionIcon } from 'app/assets/icons/aws/direct-connection.svg';
import { ReactComponent as DarkDirectConnectionIcon } from 'app/assets/icons/aws/direct-connection-dark.svg';
import { ReactComponent as DirectConnectGatewayIcon } from 'app/assets/icons/aws/direct-connect-gateway.svg';
import { ReactComponent as DarkDirectConnectGatewayIcon } from 'app/assets/icons/aws/direct-connect-gateway-dark.svg';
import { ReactComponent as InternetGatewayIcon } from 'app/assets/icons/aws/internet-gateway.svg';
import { ReactComponent as DarkInternetGatewayIcon } from 'app/assets/icons/aws/internet-gateway-dark.svg';
import { ReactComponent as NatGatewayIcon } from 'app/assets/icons/aws/nat-gateway.svg';
import { ReactComponent as DarkNatGatewayIcon } from 'app/assets/icons/aws/nat-gateway-dark.svg';
import { ReactComponent as RouterIcon } from 'app/assets/icons/aws/router-icon.svg';
import { ReactComponent as RouteIcon } from 'app/assets/icons/aws/route-icon.svg';
import { ReactComponent as CoreNetworkPolicyIcon } from 'app/assets/icons/aws/core-network-policy-icon.svg';
import { ReactComponent as DarkRouterIcon } from 'app/assets/icons/aws/router-icon-dark.svg';
import { ReactComponent as AWSCoreNetworkEdgeIcon } from 'app/assets/icons/aws/core-network-edge.svg';
import { ReactComponent as AWSCoreNetworkAttachmentIcon } from 'app/assets/icons/aws/core-network-attachment.svg';
import { ReactComponent as AWSSubnetIcon } from 'app/assets/icons/subnet.svg';
import { ReactComponent as TransitGatewayIcon } from 'app/assets/icons/aws/transit-gateway.svg';
import { ReactComponent as DarkTransitGatewayIcon } from 'app/assets/icons/aws/transit-gateway-dark.svg';
import { ReactComponent as VirtualGatewayIcon } from 'app/assets/icons/aws/vpn-gateway.svg';
import { ReactComponent as DarkVirtualGatewayIcon } from 'app/assets/icons/aws/vpn-gateway-dark.svg';
import { ReactComponent as VpcIcon } from 'app/assets/icons/aws/vpc.svg';
import { ReactComponent as DarkVpcIcon } from 'app/assets/icons/aws/vpc-dark.svg';
import { ReactComponent as VpcPeeringIcon } from 'app/assets/icons/aws/vpc-peering.svg';
import { ReactComponent as DarkVpcPeeringIcon } from 'app/assets/icons/aws/vpc-peering-dark.svg';
import { ReactComponent as VPNConnectionIcon } from 'app/assets/icons/aws/vpn-connection.svg';
import { ReactComponent as DarkVPNConnectionIcon } from 'app/assets/icons/aws/vpn-connection-dark.svg';
import { ReactComponent as VpcEndpointGatewayIcon } from 'app/assets/icons/aws/vpc-endpoint-gateway.svg';
import { ReactComponent as VpcEndpointGatewayDarkIcon } from 'app/assets/icons/aws/vpc-endpoint-gateway-dark.svg';
import { ReactComponent as KubeNodeIcon } from 'app/assets/icons/kubernetes/node.svg';
import { ReactComponent as InstanceIcon } from 'app/assets/icons/cloud-instance.svg';
import { ReactComponent as CloudWatchIcon } from 'app/assets/icons/aws/services/cloudwatch.svg';
// azure icons
import { ReactComponent as LocalNetworkGatewayIcon } from 'app/assets/icons/azure/local-network-gateway.svg';
import { ReactComponent as LocationIcon } from 'app/assets/icons/azure/location.svg';
import { ReactComponent as NATIcon } from 'app/assets/icons/azure/nat.svg';
import { ReactComponent as ResourceGroupIcon } from 'app/assets/icons/azure/resource-group.svg';
import { ReactComponent as AzureSubnetIcon } from 'app/assets/icons/azure/subnet.svg';
import { ReactComponent as SubscriptionIcon } from 'app/assets/icons/azure/subscription.svg';
import { ReactComponent as VirtualNetworkGatewayIcon } from 'app/assets/icons/azure/virtual_network_gateways.svg';
import { ReactComponent as VNetIcon } from 'app/assets/icons/azure/vnet.svg';
import { ReactComponent as VNetGatewayConnectionIcon } from 'app/assets/icons/azure/vnet-gateway-connection.svg';
import { ReactComponent as VNetPeeringIcon } from 'app/assets/icons/azure/vnet-peering.svg';
import { ReactComponent as ExpressRouteCircuitIcon } from 'app/assets/icons/azure/express-route.svg';
import { ReactComponent as LoadBalancerIcon } from 'app/assets/icons/azure/load-balancer.svg';
import { ReactComponent as VpnGatewayIcon } from 'app/assets/icons/azure/vpn-gateway.svg';
import { ReactComponent as RouteTableIcon } from 'app/assets/icons/azure/route-table.svg';
import { ReactComponent as ApplicationGatewayIcon } from 'app/assets/icons/azure/application-gateway.svg';
import { ReactComponent as FirewallIcon } from 'app/assets/icons/azure/firewalls.svg';
import { ReactComponent as VirtualWanIcon } from 'app/assets/icons/azure/virtual-wans.svg';
import { ReactComponent as VirtualHubIcon } from 'app/assets/icons/azure/virtual-hubs.svg';
import { ReactComponent as AzureVirtualMachineIcon } from 'app/assets/icons/azure/virtual-machine.svg';
import { ReactComponent as VPNSiteIcon } from 'app/assets/icons/azure/vpn-site.svg';
import { ReactComponent as PublicIPAddressIcon } from 'app/assets/icons/azure/public-ip-address.svg';
import { ReactComponent as MetricsIcon } from 'app/assets/icons/azure/metrics.svg';
import { ReactComponent as AzureBGPPeerIcon } from 'app/assets/icons/azure/bgp-peer.svg';
// gcp icons
import { ReactComponent as GCPCloudIcon } from 'app/assets/icons/gcp/cloud.svg';
import { ReactComponent as NetworkIcon } from 'app/assets/icons/gcp/network.svg';
import { ReactComponent as GCPVPNGatewayIcon } from 'app/assets/icons/gcp/vpn.svg';
import { ReactComponent as CloudRouterIcon } from 'app/assets/icons/gcp/cloud-router.svg';
import { ReactComponent as InterconnectIcon } from 'app/assets/icons/gcp/interconnect.svg';
import { ReactComponent as DarkInterconnectIcon } from 'app/assets/icons/gcp/interconnect-dark.svg';
import { ReactComponent as DarkGCPVPNGatewayIcon } from 'app/assets/icons/gcp/vpn-dark.svg';
import { ReactComponent as GcpFirewallRuleIcon } from 'app/assets/icons/gcp/cloud_firewall_rules.svg';
import { ReactComponent as GcpCloudNatIcon } from 'app/assets/icons/gcp/cloudNAT.svg';
import { ReactComponent as DarkGcpCloudNatIcon } from 'app/assets/icons/gcp/cloudNAT-dark.svg';
import { ReactComponent as DarkGcpFirewallRuleIcon } from 'app/assets/icons/gcp/dark_cloud_firewall_rules.svg';
import { ReactComponent as transferIcon } from 'app/assets/icons/gcp/Transfer.svg';
import GoogleLogo from 'app/components/GoogleLogo';

// oci icons
import OracleLogo from 'app/components/OracleLogo';
import { ReactComponent as SubnetIcon } from 'app/assets/icons/oci/subnet.svg';
import { ReactComponent as OciRouteTableIcon } from 'app/assets/icons/oci/route-table.svg';
import { ReactComponent as OciMonitoringIcon } from 'app/assets/icons/oci/monitoring.svg';
import { ReactComponent as OciNateGatewayIcon } from 'app/assets/icons/oci/nat-gateway.svg';
import { ReactComponent as OciVcnIcon } from 'app/assets/icons/oci/virtual-cloud-network.svg';
import { ReactComponent as OciCloudServiceIcon } from 'app/assets/icons/oci/cloud-service.svg';
import { ReactComponent as OciServiceGatewayIcon } from 'app/assets/icons/oci/service-gateway.svg';
import { ReactComponent as OciInternetGatewayIcon } from 'app/assets/icons/oci/internet-gateway.svg';
import { ReactComponent as OciIpSecConnectionIcon } from 'app/assets/icons/oci/ip-sec-connection.svg';
import { ReactComponent as OciLocalPeeringGatewayIcon } from 'app/assets/icons/oci/local-peering-gateway.svg';
import { ReactComponent as OciDynamicRoutingGatewayIcon } from 'app/assets/icons/oci/dynamic-routing-gateway.svg';

const GCP_ENTITY_TYPES = ENTITY_TYPES.get('gcp');
const OCI_ENTITY_TYPES = ENTITY_TYPES.get('oci');
const AWS_ENTITY_TYPES = ENTITY_TYPES.get('aws');
const AZURE_ENTITY_TYPES = ENTITY_TYPES.get('azure');

const icons = {
  emptyState: CloudEmptyStateIcon,
  light: {
    aws: {
      instance: InstanceIcon,
      [AWS_ENTITY_TYPES.INSTANCE]: InstanceIcon,
      [AWS_ENTITY_TYPES.RESERVATION]: InstanceIcon,
      eni: NetworkInterfaceIcon,
      [AWS_ENTITY_TYPES.NETWORK_INTERFACE]: NetworkInterfaceIcon,
      cluster: KubeNodeIcon,
      customerGateway: CustomerGatewayIcon,
      [AWS_ENTITY_TYPES.CUSTOMER_GATEWAY]: CustomerGatewayIcon,
      directConnection: DirectConnectionIcon,
      [AWS_ENTITY_TYPES.DIRECT_CONNECTION]: DirectConnectionIcon,
      directConnectGateway: DirectConnectGatewayIcon,
      [AWS_ENTITY_TYPES.DIRECT_CONNECTION_GATEWAY]: DirectConnectGatewayIcon,
      internetGateway: InternetGatewayIcon,
      [AWS_ENTITY_TYPES.INTERNET_GATEWAY]: InternetGatewayIcon,
      natGateway: NatGatewayIcon,
      [AWS_ENTITY_TYPES.NAT_GATEWAY]: NatGatewayIcon,
      route: RouteIcon,
      coreNetworkPolicy: CoreNetworkPolicyIcon,
      router: RouterIcon,
      [AWS_ENTITY_TYPES.ROUTER]: RouterIcon,
      subnet: AWSSubnetIcon,
      [AWS_ENTITY_TYPES.SUBNET]: AWSSubnetIcon,
      [AWS_ENTITY_TYPES.CORE_NETWORK_EDGE]: AWSCoreNetworkEdgeIcon,
      [AWS_ENTITY_TYPES.CORE_NETWORK]: AWSCoreNetworkAttachmentIcon,
      [AWS_ENTITY_TYPES.CORE_NETWORK_ATTACHMENT]: AWSCoreNetworkAttachmentIcon,
      transitGateway: TransitGatewayIcon,
      [AWS_ENTITY_TYPES.TRANSIT_GATEWAY]: TransitGatewayIcon,
      [AWS_ENTITY_TYPES.TRANSIT_GATEWAY_ATTACHMENT]: TransitGatewayIcon,
      virtualGateway: VirtualGatewayIcon,
      virtualPrivateGateway: VirtualGatewayIcon,
      [AWS_ENTITY_TYPES.VPN_GATEWAY]: VirtualGatewayIcon,
      vpc: VpcIcon,
      [AWS_ENTITY_TYPES.VPC]: VpcIcon,
      vpcPeering: VpcPeeringIcon,
      vpnConnection: VPNConnectionIcon,
      [AWS_ENTITY_TYPES.VPN_CONNECTION]: VPNConnectionIcon,
      vpcEndpointGateway: VpcEndpointGatewayIcon,
      cloudWatch: CloudWatchIcon,

      virtualNetwork: VpcIcon
    },

    azure: {
      [AZURE_ENTITY_TYPES.ROUTER]: RouterIcon,
      [AZURE_ENTITY_TYPES.LOCAL_NETWORK_GATEWAY]: LocalNetworkGatewayIcon,

      [AZURE_ENTITY_TYPES.FIREWALL]: FirewallIcon,
      [AZURE_ENTITY_TYPES.LOCATION]: LocationIcon,
      [AZURE_ENTITY_TYPES.ROUTE_TABLE]: RouteTableIcon,
      [AZURE_ENTITY_TYPES.SUBSCRIPTION]: SubscriptionIcon,
      [AZURE_ENTITY_TYPES.LOAD_BALANCER]: LoadBalancerIcon,
      [AZURE_ENTITY_TYPES.RESOURCE_GROUP]: ResourceGroupIcon,

      [AZURE_ENTITY_TYPES.SUBNET]: AzureSubnetIcon,

      [AZURE_ENTITY_TYPES.VNET]: VNetIcon,
      [AZURE_ENTITY_TYPES.NAT_GATEWAY]: NATIcon,
      [AZURE_ENTITY_TYPES.VNET_PEERING]: VNetPeeringIcon,
      [AZURE_ENTITY_TYPES.VNET_GATEWAY]: VirtualNetworkGatewayIcon,
      [AZURE_ENTITY_TYPES.APPLICATION_GATEWAY]: ApplicationGatewayIcon,
      [AZURE_ENTITY_TYPES.VNET_GATEWAY_CONNECTION]: VNetGatewayConnectionIcon,
      [AZURE_ENTITY_TYPES.HUB_VIRTUAL_NETWORK_CONNECTION]: VNetGatewayConnectionIcon,

      [AZURE_ENTITY_TYPES.VIRTUAL_WAN]: VirtualWanIcon,
      [AZURE_ENTITY_TYPES.VIRTUAL_HUB]: VirtualHubIcon,
      [AZURE_ENTITY_TYPES.VIRTUAL_MACHINE]: AzureVirtualMachineIcon,

      [AZURE_ENTITY_TYPES.VPN_SITE]: VPNSiteIcon,
      [AZURE_ENTITY_TYPES.VPN_GATEWAY]: VpnGatewayIcon,
      [AZURE_ENTITY_TYPES.VPN_LINK_CONNECTION]: VpnGatewayIcon,
      [AZURE_ENTITY_TYPES.P2S_VPN_GATEWAY]: VpnGatewayIcon,

      [AZURE_ENTITY_TYPES.EXPRESS_ROUTE_CIRCUIT]: ExpressRouteCircuitIcon,
      [AZURE_ENTITY_TYPES.EXPRESS_ROUTE_GATEWAY]: VpnGatewayIcon,
      [AZURE_ENTITY_TYPES.EXPRESS_ROUTE_CONNECTION]: ExpressRouteCircuitIcon,
      [AZURE_ENTITY_TYPES.PUBLIC_IP_ADDRESSES]: PublicIPAddressIcon,

      // Azure Monitor metrics icon
      metrics: MetricsIcon,
      bgpPeer: AzureBGPPeerIcon,

      virtualNetwork: VNetIcon
    },

    gcp: {
      [GCP_ENTITY_TYPES.NETWORK]: NetworkIcon,
      [GCP_ENTITY_TYPES.REGION]: GoogleLogo,
      [GCP_ENTITY_TYPES.SUBNET]: GCPCloudIcon,
      [GCP_ENTITY_TYPES.EXTERNAL_VPN_GATEWAY]: GCPVPNGatewayIcon,
      [GCP_ENTITY_TYPES.VPN_GATEWAY]: GCPVPNGatewayIcon,
      [GCP_ENTITY_TYPES.TARGET_VPN_GATEWAY]: GCPVPNGatewayIcon,
      [GCP_ENTITY_TYPES.ROUTER]: RouterIcon,
      [GCP_ENTITY_TYPES.INTERCONNECT]: InterconnectIcon,
      [GCP_ENTITY_TYPES.INTERCONNECT_ATTACHMENT]: InterconnectIcon,
      [GCP_ENTITY_TYPES.CLOUD_ROUTER]: CloudRouterIcon,
      [GCP_ENTITY_TYPES.LOAD_BALANCER]: LoadBalancerIcon,
      [GCP_ENTITY_TYPES.PUBLIC_IP_ADDRESS]: PublicIPAddressIcon,
      [GCP_ENTITY_TYPES.FIREWALL]: FirewallIcon,
      [GCP_ENTITY_TYPES.FIREWALL_POLICY]: FirewallIcon,
      [GCP_ENTITY_TYPES.FIREWALL_RULE]: GcpFirewallRuleIcon,
      [GCP_ENTITY_TYPES.VPN_TUNNEL]: transferIcon,
      [GCP_ENTITY_TYPES.NAT_GATEWAY]: GcpCloudNatIcon,
      metrics: MetricsIcon
    },

    oci: {
      cloudService: OciCloudServiceIcon,
      [OCI_ENTITY_TYPES.REGION]: OracleLogo,
      [OCI_ENTITY_TYPES.ROUTER]: RouterIcon,
      [OCI_ENTITY_TYPES.SUBNET]: SubnetIcon,
      [OCI_ENTITY_TYPES.ROUTE_TABLE]: OciRouteTableIcon,
      [OCI_ENTITY_TYPES.NAT_GATEWAY]: OciNateGatewayIcon,
      [OCI_ENTITY_TYPES.VIRTUAL_CLOUD_NETWORK]: OciVcnIcon,
      [OCI_ENTITY_TYPES.DRG_ROUTE_TABLE]: OciRouteTableIcon,
      [OCI_ENTITY_TYPES.SERVICE_GATEWAY]: OciServiceGatewayIcon,
      [OCI_ENTITY_TYPES.INTERNET_GATEWAY]: OciInternetGatewayIcon,
      [OCI_ENTITY_TYPES.IP_SEC_CONNECTION]: OciIpSecConnectionIcon,
      [OCI_ENTITY_TYPES.LOCAL_PEERING_GATEWAY]: OciLocalPeeringGatewayIcon,
      [OCI_ENTITY_TYPES.DYNAMIC_ROUTING_GATEWAY]: OciDynamicRoutingGatewayIcon,
      [OCI_ENTITY_TYPES.DYNAMIC_ROUTING_GATEWAY_ATTACHMENT]: OciDynamicRoutingGatewayIcon,

      // oci metrics monitor
      metrics: OciMonitoringIcon
    }
  },

  dark: {
    aws: {
      customerGateway: DarkCustomerGatewayIcon,
      [AWS_ENTITY_TYPES.CUSTOMER_GATEWAY]: DarkCustomerGatewayIcon,
      directConnection: DarkDirectConnectionIcon,
      [AWS_ENTITY_TYPES.DIRECT_CONNECTION]: DarkDirectConnectionIcon,
      directConnectGateway: DarkDirectConnectGatewayIcon,
      [AWS_ENTITY_TYPES.DIRECT_CONNECTION_GATEWAY]: DarkDirectConnectGatewayIcon,
      internetGateway: DarkInternetGatewayIcon,
      [AWS_ENTITY_TYPES.INTERNET_GATEWAY]: DarkInternetGatewayIcon,
      natGateway: DarkNatGatewayIcon,
      [AWS_ENTITY_TYPES.NAT_GATEWAY]: DarkNatGatewayIcon,
      router: DarkRouterIcon,
      [AWS_ENTITY_TYPES.ROUTER]: DarkRouterIcon,
      transitGateway: DarkTransitGatewayIcon,
      [AWS_ENTITY_TYPES.TRANSIT_GATEWAY]: DarkTransitGatewayIcon,
      virtualGateway: DarkVirtualGatewayIcon,
      [AWS_ENTITY_TYPES.VPN_GATEWAY]: DarkVirtualGatewayIcon,
      virtualPrivateGateway: DarkVirtualGatewayIcon,
      vpc: DarkVpcIcon,
      [AWS_ENTITY_TYPES.VPC]: DarkVpcIcon,
      vpcPeering: DarkVpcPeeringIcon,
      vpnConnection: DarkVPNConnectionIcon,
      [AWS_ENTITY_TYPES.VPN_CONNECTION]: DarkVPNConnectionIcon,
      vpcEndpointGateway: VpcEndpointGatewayDarkIcon,
      virtualNetwork: DarkVpcIcon
    },
    gcp: {
      [GCP_ENTITY_TYPES.INTERCONNECT_ATTACHMENT]: DarkInterconnectIcon,
      [GCP_ENTITY_TYPES.VPN_GATEWAY]: DarkGCPVPNGatewayIcon,
      [GCP_ENTITY_TYPES.FIREWALL_RULE]: DarkGcpFirewallRuleIcon,
      [GCP_ENTITY_TYPES.NAT_GATEWAY]: DarkGcpCloudNatIcon,
      metrics: MetricsIcon
    }
  }
};

@withTheme
class CloudIcon extends PureComponent {
  static defaultProps = {
    width: 24,
    height: 24,
    isEmptyState: false
  };

  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isEmptyState: PropTypes.bool
  };

  render() {
    const { theme, cloudProvider, entity, iconSize, isEmptyState = false, ...iconProps } = this.props;
    // attempt a complete match based on theme, provider, and entity
    let Icon = isEmptyState ? icons.emptyState : icons[theme.name][cloudProvider]?.[entity];
    let additionalIconProps = {};

    if (cloudProvider === 'oci') {
      additionalIconProps = { fill: theme.colors.oci.primary, stroke: theme.colors.oci.primary };
    }

    // if iconSize implicitly passed, ovveride width and height of the icon
    if (iconSize) {
      Object.assign(iconProps, { width: iconSize, height: iconSize });
    }

    if (theme.name === 'dark' && !Icon) {
      // we can't find a dark icon, attempt to fall back to light
      Icon = icons.light[cloudProvider]?.[entity];
    }

    if (!Icon) {
      console.warn(`The icon ${theme.name}.${cloudProvider}.${entity} was not found in the cloud icon factory`);
      return null;
    }

    return <Icon {...additionalIconProps} {...iconProps} />;
  }
}

export default memo(CloudIcon);
