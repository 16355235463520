import React, { Component } from 'react';

import CoreToggleButtonGroup from 'core/components/ToggleButtonGroup';
import EmptyState from 'core/components/EmptyState';
import Button from 'core/components/Button';
import { Intent } from '@blueprintjs/core';

export default class ToggleButtonGroup extends Component {
  handleChange = (value) => {
    const { onChange, multi, field } = this.props;
    let newValue;

    if (multi) {
      const currentValue = field.value || [];
      if (currentValue.includes(value)) {
        newValue = currentValue.filter((v) => v !== value);
      } else {
        newValue = [...currentValue, value];
      }
    } else {
      newValue = value;
    }

    onChange(newValue);
  };

  render() {
    const { containerProps, buttonProps, field, options, disabled, highlightedActive, small, multi } = this.props;
    const buttonOptions = options || field.options;
    const fieldValue = field.value || (multi ? [] : '');
    if (!buttonOptions.length) {
      return <EmptyState description="There are no available options" />;
    }

    return (
      <CoreToggleButtonGroup {...containerProps} selectedValue={fieldValue} disabled={disabled}>
        {buttonOptions.map(({ label, value }) => (
          <Button
            key={`${field.id || field.name}-${value}`}
            value={value}
            text={label}
            onClick={() => this.handleChange(value)}
            small={small}
            intent={
              highlightedActive && (fieldValue === value || fieldValue.includes(value)) ? Intent.PRIMARY : undefined
            }
            {...buttonProps}
          />
        ))}
      </CoreToggleButtonGroup>
    );
  }
}
