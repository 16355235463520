/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Flex } from 'core/components';

@observer
class Row extends Component {
  render() {
    const { model, className, rowRenderer, collection, style } = this.props;
    const rowClassName = classNames('tr', className);

    return (
      <Flex className={rowClassName} style={style}>
        {rowRenderer({ model, collection })}
      </Flex>
    );
  }
}

export default Row;
