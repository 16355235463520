import React from 'react';
import { Tooltip, Button } from 'core/components';

const DescriptionButton = ({ viewType, hasDescription, onClick, ...rest }) => (
  <Tooltip
    content={hasDescription ? `View ${viewType} description` : `No description provided for this ${viewType}`}
    minimal
  >
    <Button icon="comment" minimal onClick={onClick} disabled={!hasDescription} openOnTargetFocus={false} {...rest} />
  </Tooltip>
);

export default DescriptionButton;
