import React, { useState } from 'react';

import { formatBytesGreek } from 'core/util';
import { formatNumber } from 'app/util/utils';
import { Card, Text, Box } from 'core/components';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import { ViewInExplorerButton } from 'app/components/dataviews/tools';
import { MetricRollupValues } from './MetricRollupValues';

const viewProps = {
  showTooltips: true,
  flex: 1,
  height: 'calc(100% - 5px)',
  showNativeLegend: true
};

export const formatRollupNumber = (value, unit = '', suffix = '') => {
  if (unit === 'bits') {
    return formatBytesGreek(value, `${unit}/s`);
  }
  const formattedNumber = formatNumber(value, { fix: 2 });
  return `${formattedNumber.displayFull}${suffix}`;
};

export const MetricVisualization = ({ title, subTitle, query, unit, metricSuffix, responseKeys }) => {
  const [avgValue, setAvgValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [lastValue, setLastValue] = useState(0);

  const [avgSecondaryValue, setAvgSecondaryValue] = useState(0);
  const [maxSecondaryValue, setMaxSecondaryValue] = useState(0);
  const [lastSecondaryValue, setLastSecondaryValue] = useState(0);

  return (
    <Card p={2} width="100%" height="360px" flex={1} position="relative">
      <Text as="h3" m={0}>
        {title}
      </Text>
      <Text small muted>
        {subTitle}
      </Text>

      <ViewInExplorerButton
        query={query}
        minimal
        small
        m={2}
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          zIndex: 1000
        }}
      />

      <DataViewWrapper
        allowCache
        query={query}
        viewProps={viewProps}
        style={{ marginTop: '10px' }}
        onQueryComplete={({ results }) => {
          const data = results.toJS();

          if (data.length && responseKeys) {
            // should only have one query
            const dataSet = data[0];

            // outsort
            setAvgValue(dataSet[responseKeys.avgIn]);
            setMaxValue(dataSet[responseKeys.maxIn]);
            setLastValue(dataSet[responseKeys.lastIn]);

            // secondaryOutsort
            setAvgSecondaryValue(dataSet[responseKeys.avgOut]);
            setMaxSecondaryValue(dataSet[responseKeys.maxOut]);
            setLastSecondaryValue(dataSet[responseKeys.lastOut]);
          }
        }}
      />

      <Box direction="row" mt={1} mr={2} style={{ position: 'absolute', right: 0, top: 0, gap: 20 }} width="50%">
        <MetricRollupValues
          avgValue={formatRollupNumber(avgValue, unit, metricSuffix)}
          maxValue={formatRollupNumber(maxValue, unit, metricSuffix)}
          lastValue={formatRollupNumber(lastValue, unit, metricSuffix)}
          avgSecondaryValue={formatRollupNumber(avgSecondaryValue, unit, metricSuffix)}
          maxSecondaryValue={formatRollupNumber(maxSecondaryValue, unit, metricSuffix)}
          lastSecondaryValue={formatRollupNumber(lastSecondaryValue, unit, metricSuffix)}
        />
      </Box>
    </Card>
  );
};
