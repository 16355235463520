import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Validator from 'validatorjs';

import { Box, Flex, Text } from 'core/components';
import { formConsumer, Field, Select, Switch, InputGroup } from 'core/form';
import { DEFAULT_DATETIME_FORMAT, getQueryTimeInterval } from 'core/util/dateUtils';

import { Classes } from '@blueprintjs/core';
import JumpButtons from './JumpButtons';
import TimeSelector from './TimeSelector';

Validator.register(
  'periodOverPeriodOffset',
  function periodOverPeriodOffset() {
    const {
      lookback_seconds,
      starting_time,
      ending_time,
      period_over_period_lookback,
      period_over_period_lookback_unit
    } = this.validator.input;
    const queryInterval = getQueryTimeInterval({ lookback_seconds, starting_time, ending_time });
    const periodOffset = moment.duration(period_over_period_lookback, period_over_period_lookback_unit).asSeconds();
    return periodOffset >= queryInterval;
  },
  'The previous offset must be at least as large as the current time interval'
);

@inject('$dictionary', '$explorer')
@formConsumer
@observer
export default class TimeOptions extends Component {
  static defaultProps = {
    showJumpButtons: true,
    showFieldLabels: true,
    showTimeZoneSelector: true,
    showAwsTimezoneSelector: true,
    rootFieldName: false,
    disabled: false,
    disabledDimensions: [],
    large: false
  };

  getFullFieldName(fieldName) {
    const { rootFieldName } = this.props;
    return rootFieldName ? `${rootFieldName}.${fieldName}` : fieldName;
  }

  get lookback_seconds() {
    return this.getFullFieldName('lookback_seconds');
  }

  get starting_time() {
    return this.getFullFieldName('starting_time');
  }

  get ending_time() {
    return this.getFullFieldName('ending_time');
  }

  get time_format() {
    return this.getFullFieldName('time_format');
  }

  get period_over_period() {
    return this.getFullFieldName('period_over_period');
  }

  get period_over_period_lookback() {
    return this.getFullFieldName('period_over_period_lookback');
  }

  get period_over_period_lookback_unit() {
    return this.getFullFieldName('period_over_period_lookback_unit');
  }

  get use_alt_timestamp_field() {
    return this.getFullFieldName('use_alt_timestamp_field');
  }

  get periodOverPeriodLookbackOptions() {
    const { form } = this.props;
    const values = ['hour', 'day', 'week', 'month'];
    const num = Number(form.getValue(this.period_over_period_lookback));
    const suffix = num > 1 ? 's' : '';
    return values.map((value) => ({ value, label: `${value}${suffix}` }));
  }

  handleTimeChange = ({ lookbackSeconds, startDate, endDate }) => {
    const { form, submitChanges, $explorer } = this.props;

    // remove zoom history & prevent override
    $explorer.dataview.resetTimeRange(true);

    form.setValue(this.lookback_seconds, lookbackSeconds);

    if (startDate && endDate) {
      form.setValue(this.starting_time, moment.utc(startDate).format(DEFAULT_DATETIME_FORMAT));
      form.setValue(this.ending_time, moment.utc(endDate).format(DEFAULT_DATETIME_FORMAT));
    } else {
      form.setValue(this.starting_time, '');
      form.setValue(this.ending_time, '');
    }

    if (form.onChange) {
      form.onChange({
        form,
        formValues: form.getValues(),
        field: form.getField(this.lookback_seconds),
        fieldValue: lookbackSeconds
      });
    }

    if (submitChanges) {
      submitChanges();
    }
  };

  handleTimeZoneChange = (timezoneField, timezone) => {
    const { form, submitChanges } = this.props;
    const startField = form.getField(this.starting_time);
    const endField = form.getField(this.ending_time);

    // update FPA time fields too
    if (form.getValue('use_fpa')) {
      form.setValue('fpa.time.time_format', timezone);
      form.setValue('fpa.compare_time.time_format', timezone);
    }

    if (startField.value && endField.value) {
      // changing TZ out from under start/end values will make getters return incorrectly. Get current values (via .value, NOT getValue()
      // which will incorrectly run "out transform" before returning value) and normalize them back to UTC based on how values were previously set,
      // then set them via setField(), which will correctly convert as needed based on current time_format via the in transform.
      const prevMomentFn = timezone === 'Local' ? moment.utc : moment;
      startField.setValue(prevMomentFn(startField.value).utc().format(DEFAULT_DATETIME_FORMAT));
      endField.setValue(prevMomentFn(endField.value).utc().format(DEFAULT_DATETIME_FORMAT));
      if (submitChanges) {
        submitChanges();
      }
    }
  };

  getLookbackOptions() {
    const { $dictionary, omitValues = [], maxDateRange } = this.props;
    const dictionaryOptions = $dictionary.getSelectOptions('showLast', { parseKeys: true, omitValues });

    // move special options to the end of the list
    const specialOptions = dictionaryOptions.filter((option) => option.value > 0 && option.value < 60);
    return dictionaryOptions
      .filter((option) => option.value === 0 || option.value > 60)
      .concat(specialOptions)
      .filter((option) => {
        if (maxDateRange && option.value > 0) {
          if (option.value < 60) {
            return maxDateRange >= 2592000;
          }
          return option.value <= maxDateRange;
        }
        return true;
      });
  }

  render() {
    const {
      form,
      showJumpButtons,
      submitChanges,
      disabled,
      disabledDimensions,
      showTimeZoneSelector,
      showAwsTimezoneSelector,
      periodOverPeriodDisabled,
      maxDateRange,
      large,
      lookbackOptions
    } = this.props;

    const fieldProps = { mb: 0, disabled, small: !large, large };

    const periodOverPeriodEnabled = form.getValue(this.period_over_period);
    const periodOverPeriodLookbackField = form.getField(this.period_over_period_lookback);
    const awsDisabled = disabledDimensions.includes('Amazon Web Services');

    const timeSelector = (
      <TimeSelector
        lookbackSeconds={form.getValue(this.lookback_seconds)}
        startDate={moment.utc(form.getValue(this.starting_time)).toDate()}
        endDate={moment.utc(form.getValue(this.ending_time)).toDate()}
        timeFormat={form.getValue(this.time_format)}
        lookbackOptions={lookbackOptions || this.getLookbackOptions()}
        onChange={this.handleTimeChange}
        maxDateRange={maxDateRange}
        {...fieldProps}
      />
    );

    return (
      <Flex flexDirection="column" gap={1}>
        {!periodOverPeriodDisabled && (
          <Field name={this.period_over_period} showLabel={false} {...fieldProps} disabled={form.getValue('use_fpa')}>
            <Switch showFieldLabel />
          </Field>
        )}
        <Flex gap={1} alignItems="center">
          {periodOverPeriodEnabled && (
            <Text width={50} small>
              Current:
            </Text>
          )}
          {showJumpButtons ? (
            <JumpButtons disabled={disabled} form={form} submitChanges={submitChanges} large={large}>
              {timeSelector}
            </JumpButtons>
          ) : (
            <Box className={Classes.BUTTON_GROUP} flex={1} mb={0}>
              {timeSelector}
            </Box>
          )}
        </Flex>
        {showTimeZoneSelector && (
          <Field name={this.time_format} showLabel={false} {...fieldProps} onChange={this.handleTimeZoneChange}>
            <Select menuWidth={113} />
          </Field>
        )}
        {periodOverPeriodEnabled && (
          <>
            <Flex gap={1} alignItems="center">
              <Text width={50} small>
                Previous:
              </Text>
              <Field
                name={this.period_over_period_lookback}
                rules={['required', 'integer', 'min:1', 'periodOverPeriodOffset']}
                showLabel={false}
                showError={false}
                {...fieldProps}
              >
                <InputGroup width={50} />
              </Field>
              <Field
                name={this.period_over_period_lookback_unit}
                options={this.periodOverPeriodLookbackOptions}
                showLabel={false}
                {...fieldProps}
              >
                <Select menuWidth={100} />
              </Field>
              <Text small>back</Text>
            </Flex>
            {periodOverPeriodLookbackField.hasError && (
              <Flex gap={1} mt="4px">
                <Box flex="0 0 50px" />
                <Text small color="danger">
                  {periodOverPeriodLookbackField.errorString}
                </Text>
              </Flex>
            )}
          </>
        )}
        {!awsDisabled && showAwsTimezoneSelector && (
          <Flex>
            <Field name={this.use_alt_timestamp_field} showLabel={false} {...fieldProps}>
              <Switch showFieldLabel />
            </Field>
          </Flex>
        )}
      </Flex>
    );
  }
}
