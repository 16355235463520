import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'core/components';

@inject('$metrics')
@observer
class MetricsExplorerLink extends Component {
  state = {
    hash: undefined,
    loading: true
  };

  componentDidMount() {
    const { $metrics, query } = this.props;

    $metrics.queryToHash(query).then((hash) => this.setState({ hash, loading: false }));
  }

  get link() {
    const { hash } = this.state;
    return `/v4/nms/explorer/${hash}`;
  }

  get disabled() {
    const { loading } = this.state;
    return loading;
  }

  render() {
    const { style, children, as = 'a', blank } = this.props;
    return (
      <Link to={this.link} as={as} style={style} disabled={this.disabled} blank={blank}>
        {children}
      </Link>
    );
  }
}

export default MetricsExplorerLink;
