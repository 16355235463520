import React from 'react';
import { inject } from 'mobx-react';

import Link from 'core/components/Link';

const IpLink = ({ $app, ip_address, children, ...rest }) => {
  if ($app.isSubtenant) {
    return children || ip_address;
  }

  return (
    // ip addresses shouldn't have spaces in them, so just strip it to the first whitespace.
    <Link to={`/v4/core/quick-views/ip/${typeof ip_address === 'string' ? ip_address.split(' ')[0] : ''}`} {...rest}>
      {children || ip_address}
    </Link>
  );
};

export default inject('$app')(IpLink);
