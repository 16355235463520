import React from 'react';
import { get } from 'lodash';
import { withTheme } from 'styled-components';
import { Box } from 'core/components';

const Swatch = ({ round, bg, theme, fillOpacity, dot, ...props }) => {
  const color = get(theme.colors, bg) || bg;

  if (round) {
    return (
      <Box mt="2px" ml="2px" width={16} height={16} {...props}>
        <svg viewBox="0 0 10 10" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="4.5" fill={color} fillOpacity={fillOpacity} stroke={color} />
        </svg>
      </Box>
    );
  }

  if (dot) {
    return <Box mr="4px" ml="2px" borderRadius={8} width={8} height={8} bg={color} {...props} />;
  }

  return <Box mr="4px" ml="2px" borderRadius={3} width={5} height={12} bg={color} {...props} />;
};

Swatch.defaultProps = {
  round: false,
  fillOpacity: 0.2
};

export default withTheme(Swatch);
