import { isIpValid } from 'core/util/ip';

function fn(value) {
  if (value) {
    return isIpValid(value);
  }

  return true;
}

export default {
  message: 'Enter a valid IPv4 or IPv6 address. CIDR/netmask supported. ',
  fn
};
