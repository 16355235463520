import React from 'react';
import { observer } from 'mobx-react';
import { Classes, Position } from '@blueprintjs/core';
import classNames from 'classnames';
import HighchartsLib from 'highcharts';
import { debounce, defaultsDeep, get, isEqual } from 'lodash';

import { Flex, Highcharts, Icon, Menu, MenuDivider, MenuItem, Popover, Text } from 'core/components';
import $app from 'app/stores/$app';
import $auth from 'app/stores/$auth';

import {
  Timezone,
  DEFAULT_DATETIME_FORMAT,
  formatDateTime,
  formatDuration,
  getStartAndEndFromLookback
} from 'core/util/dateUtils';
import moment from 'moment';
import { TinyWindowIcon } from 'app/views/core/explorer/TinyWindowIcon';
import BaseDataview from './BaseDataview';

(function updateHighchartsToSupportNegativeLogAxes(H) {
  H.addEvent(H.Axis, 'afterInit', function updateHighchartsToSupportNegativeLogAxesHandler() {
    const { logarithmic } = this;

    if (logarithmic && this.options.custom?.allowNegativeLog) {
      // Avoid errors on negative numbers on a log axis
      this.positiveValuesOnly = false;

      // Override the converter functions
      logarithmic.log2lin = (num) => {
        const isNegative = num < 0;

        let adjustedNum = Math.abs(num);

        if (adjustedNum < 10) {
          adjustedNum += (10 - adjustedNum) / 10;
        }

        const result = Math.log(adjustedNum) / Math.LN10;
        return isNegative ? -result : result;
      };

      logarithmic.lin2log = (num) => {
        const isNegative = num < 0;
        const absNum = Math.abs(num);

        let result = 10 ** absNum;
        if (result < 10) {
          result = (10 * (result - 1)) / (10 - 1);
        }
        return isNegative ? -result : result;
      };
    }
  });
})(HighchartsLib);

(function updateHighchartsToSupportOutsideTooltips(H) {
  H.wrap(H.Tooltip.prototype, 'getLabel', function modifyGetLabel(proceed) {
    // eslint-disable-next-line prefer-rest-params
    const result = proceed.apply(this, Array.prototype.slice.call(arguments, 1));

    if ((this.container || {}).parentElement === H.doc.body) {
      // make sure the container is appended to the app-wrapper div, so we inherit styling
      H.doc.getElementById('app-wrapper').appendChild(this.container);
    }

    return result;
  });
})(HighchartsLib);

function roundToNearestMinute(timestamp) {
  const oneMinuteInMs = 60 * 1000; // 60 seconds * 1000 ms
  const halfMinuteInMs = 30 * 1000; // 30 seconds * 1000 ms

  // Calculate the remainder when dividing by one minute
  const remainder = timestamp % oneMinuteInMs;

  // If the remainder is 30 seconds or more, round up
  if (remainder >= halfMinuteInMs) {
    return Math.ceil(timestamp / oneMinuteInMs) * oneMinuteInMs;
  }
  // Otherwise, round down
  return Math.floor(timestamp / oneMinuteInMs) * oneMinuteInMs;
}

@observer
export default class BaseHighchartsDataview extends BaseDataview {
  state = {
    showRangeSelectionMenu: false,
    popoverTargetStyles: {},
    canPerformAnalysis: true
  };

  selectionAreaRects = [];

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    const { onModelSelect, viewProps } = this.props;

    if (this.chart && this.chart.series && prevProps.onModelSelect !== onModelSelect) {
      this.chart.series.forEach((series) => {
        series.setOptions({
          events: {
            legendItemClick: this.getLegendItemClick()
          }
        });
      });
    }
    // Update xPlotBands
    if (
      this.chart &&
      viewProps?.xPlotBands?.length &&
      !isEqual(viewProps?.xPlotBands, prevProps.viewProps?.xPlotBands)
    ) {
      viewProps.xPlotBands.forEach((band) => {
        this.chart.xAxis[0].addPlotBand(band);
      });
    }

    // Update zones
    if (this.chart && this.chart.series && viewProps.zones) {
      this.chart.series.forEach((series) => {
        series.setOptions({
          zoneAxis: viewProps.zoneAxis,
          zones: viewProps.zones
        });
      });
    }
  }

  drawChangeDetectionIndicators() {
    const { chart } = this;
    const { dataview } = this.props;

    this.clearPlotBandsAndZones();

    const isCpdFpa = dataview.fpaType === 'cpd_fpa';
    const results = dataview.queryBuckets.activeBuckets.at(0).queryResults.fpaRows.at(0)?.get();

    if (chart && isCpdFpa && results?.events?.length) {
      const zones = [];
      results.events.forEach((event, index) => {
        zones.push({ value: moment.unix(event.start.seconds).valueOf() });
        zones.push({
          value: moment.unix(event.end.seconds).valueOf(),
          className: 'change-detection'
        });

        // add a plotBand around each change detection event
        chart.xAxis[0].addPlotBand({
          className: 'change-detection-band',
          from: moment.unix(event.start.seconds).toDate(),
          to: moment.unix(event.end.seconds).toDate(),
          zIndex: 10,
          label: {
            className: 'change-detection-label',
            text: `${index + 1}`,
            align: 'center',
            style: {
              textOverflow: 'none'
            }
          }
        });
      });

      chart.series.forEach((series) => {
        series.update(
          {
            zones,
            zoneAxis: 'x'
          },
          false
        );
      });

      const { seriesIntervalSeconds } = dataview;

      const data = results.events.flatMap((event) => {
        const { times, metric } = results;
        const time = moment.unix(event.change_point.seconds).valueOf();
        const index = times.findIndex((t) => t === parseInt(event.change_point.seconds));

        // the metric[] array contains bytes, multiply by 8 to get bits from bytes
        // then divide by the number of seconds in the interval
        // @TODO this needs to be moved to Node and stored in the event, probably?
        const value = (metric[index] * 8) / seriesIntervalSeconds;

        return [
          { x: time, y: value, name: `${event.type} at ...` },

          // insert nulls to create a gap between the points
          { x: null, y: null }
        ];
      });

      // add a new series to the chart with the points that represent the change detection events
      chart.addSeries({
        type: 'line',
        name: 'Change Detection Events',
        className: 'change-detection-series',
        index: 2,
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 4
        },
        data
      });
    }

    this.redraw();
  }

  /**
   * Draws the FPA "Windows" on the chart
   */
  drawFpaPlotBands() {
    const { chart } = this;
    const { dataview, viewProps } = this.props;

    this.clearPlotBandsAndZones();

    // need to get `fpa.time` and `fpa.compare_time from the query model
    const explorerQueryModel = dataview.createExplorerQueryModelFromSelectedQuery();

    const starting_time = explorerQueryModel.get('fpa.time.starting_time');
    const ending_time = explorerQueryModel.get('fpa.time.ending_time');

    const zones = [];
    chart.xAxis[0].addPlotBand({
      className: 'selected-window',
      from: moment.utc(starting_time).toDate(),
      to: moment.utc(ending_time).toDate(),
      label: {
        text: viewProps?.selectedWindowLabel || 'Window',
        align: 'left',
        y: -1,
        x: 1
      }
    });

    zones.push({ value: moment.utc(starting_time).toDate() });
    zones.push({
      value: moment.utc(ending_time).toDate(),
      className: 'selected-window'
    });

    // draw a second plotBand if we have a compare_time
    if (dataview.fpaType === 'diff_fpa') {
      const compare_starting_time = explorerQueryModel.get('fpa.compare_time.starting_time');
      const compare_ending_time = explorerQueryModel.get('fpa.compare_time.ending_time');

      chart.xAxis[0].addPlotBand({
        className: 'comparison-window',
        from: moment.utc(compare_starting_time).toDate(),
        to: moment.utc(compare_ending_time).toDate(),
        label: {
          text: viewProps?.comparisonWindowLabel || 'Comparison',
          align: 'left',
          y: -1,
          x: 1
        }
      });

      zones.push({ value: moment.utc(compare_starting_time).toDate() });
      zones.push({
        value: moment.utc(compare_ending_time).toDate(),
        className: 'comparison-window'
      });
    }

    chart.series.forEach((series) => {
      series.update({
        zones,
        zoneAxis: 'x'
      });
    });

    this.redraw();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }
  }

  clearPlotBandsAndZones() {
    const { chart } = this;

    if (!chart.yAxis && !chart.xAxis && !chart.series) {
      return;
    }

    if (chart?.yAxis) {
      chart.yAxis.forEach((axis) => {
        axis.plotBands?.forEach((plotBand) => axis.removePlotBand(plotBand.id));
      });
    }

    if (chart?.xAxis) {
      chart.xAxis.forEach((axis) => {
        axis.plotBands?.forEach((plotBand) => axis.removePlotBand(plotBand.id));
      });
    }

    // Clear zones and zoneAxis for each series
    if (chart?.series) {
      chart.series.forEach((series) => {
        series.update(
          {
            zones: [],
            zoneAxis: null
          },
          false // 'false' to prevent multiple redraws
        );
      });
    }

    chart.redraw(); // Redraw the chart once after updating all series
  }

  dismissSpinner = (timeout = 200, setFullyLoaded = true) => {
    const { dataview } = this.props;
    const emptyBuckets = dataview.queryBuckets.activeBuckets.length === 0;

    setTimeout(() => {
      const seriesLoaded = Array.isArray(this.chart?.series)
        ? this.chart.series.every((i) => i.hasRendered || i.userOptions.isPreviousPeriod)
        : true;
      if (setFullyLoaded && !emptyBuckets && seriesLoaded) {
        dataview.setFullyLoaded();
      }
    }, timeout);
  };

  drawSelectionArea = (width, height, x, y) => {
    const rect = this.chart.renderer
      .rect(x, y, width, height - 1)
      .attr({
        class: 'dataview-time-selection-rect',
        'stroke-dasharray': 20,
        stroke: '#00429D',
        fill: 'rgba(28, 143, 250, 0.15)',
        'stroke-width': 1,
        rx: 2,
        ry: 2
      })
      .add();

    this.selectionAreaRects = [...(this.selectionAreaRects || []), rect];
  };

  clearSelectionRects = () => {
    this.selectionAreaRects.forEach((rect) => rect.destroy());
    this.selectionAreaRects = [];
    this.setState({ showRangeSelectionMenu: false });
  };

  // setup container resize observer
  chartCallback = (chart) => {
    const { dataview } = this.props;
    this.chart = chart.container ? chart : null;
    this.el = chart.container;

    this.resizeListener = debounce(() => dataview.reflow(), 200);

    // when the chart is ready...
    setTimeout(() => {
      if (!dataview.useFpa) {
        this.clearPlotBandsAndZones();
      } else {
        if (['fpa', 'diff_fpa'].includes(dataview.fpaType)) {
          this.drawFpaPlotBands();
        }

        if (dataview.fpaType === 'cpd_fpa') {
          this.drawChangeDetectionIndicators();
        }
      }
    }, 20);

    window.addEventListener('resize', this.resizeListener);
  };

  handleTimeZoom = (event) => {
    const { dataview, preventQueryOnZoom } = this.props;

    // shared users can only zoom with local data; re-queries won't be whitelisted, so bail on the rest of this
    if ($app.store.$auth.isSharedUser || preventQueryOnZoom) {
      return;
    }

    event.preventDefault();

    const { originalEvent, width, height, x, y } = event;

    if (originalEvent) {
      const { offsetX, offsetY } = originalEvent;

      // position the div where the cursor was when the mouse was released
      this.setState({
        popoverTargetStyles: {
          top: `${offsetY}px`,
          left: `${offsetX}px`
        }
      });
    }

    if (event.xAxis) {
      let { min, max } = event.xAxis[0];
      this.setState({ min, max });

      // Selection event does not take into account minRange setting, so need to adjust the time range based on how HC implements minRange
      if (this.chart && this.chart.xAxis) {
        const minRange = parseInt(this.chart.xAxis[0].minRange);
        const parsedMin = Math.floor(parseFloat(min));
        const parsedMax = Math.floor(parseFloat(max));

        if (parsedMax - parsedMin < minRange) {
          const overage = minRange - parsedMax + parsedMin;

          min -= overage / 2;
          max += overage / 2;
        }

        min = roundToNearestMinute(min);
        max = roundToNearestMinute(max);
      }

      if (!$auth.hasPermission('fpa')) {
        this.handleZoomToSelection();
        return;
      }

      dataview.addTimeSelection(min, max);
      this.drawSelectionArea(width, height, x, y);

      const time_format = dataview.queryBuckets.selectedQuery.get('time_format');
      const timezone = new Timezone(time_format);

      const duration = max - min;
      const selectedDurationText = formatDuration(Math.floor(duration / 1000));
      const durationInMinutes = Math.floor(duration / (1000 * 60));

      const selectionStart = formatDateTime(min, DEFAULT_DATETIME_FORMAT, timezone);
      const selectionEnd = formatDateTime(max, DEFAULT_DATETIME_FORMAT, timezone);

      this.setState({
        showRangeSelectionMenu: true,
        min,
        max,
        canPerformAnalysis: durationInMinutes <= 120,
        selectedDurationText,
        selectionStart,
        selectionEnd
      });
    } else {
      setTimeout(() => dataview.resetTimeRange(), 0);
    }
  };

  handleTimeSelectionPopoverInteraction = (nextOpenState, e) => {
    const { dataview } = this.props;
    const clickedCompareButton = !!e?.target?.closest('a.compare-traffic-button');

    if (dataview.timeSelections.length && dataview.isComparisonWorkflow) {
      console.warn(`dataview has ${dataview.timeSelections.length} selections`);
    } else {
      if (clickedCompareButton && !nextOpenState) {
        this.setState({ showRangeSelectionMenu: false });
        dataview.toggleComparisonWorkflow();
      } else if (!nextOpenState && this.selectionAreaRects?.length) {
        this.clearSelectionRects();
      }
      this.setState({ showRangeSelectionMenu: nextOpenState });
    }
  };

  handleZoomToSelection = () => {
    const { dataview } = this.props;
    const { min, max } = this.state;
    setTimeout(() => {
      dataview.updateTimeRange(min, max);
      this.setState({ showRangeSelectionMenu: false });
    }, 0);
  };

  handlePerformPatternAnalysis = () => {
    const { dataview } = this.props;
    const selections = dataview.timeSelections.toJS();
    const explorerQueryModel = dataview.createExplorerQueryModelFromSelectedQuery();

    const commonFields = {
      use_fpa: true
    };

    // when FPA selections are made, and the original query has a lookback_seconds config, we want to
    // convert that to a starting_time and ending_time so the FPA can be performed correctly.

    const lookback_seconds = explorerQueryModel.get('lookback_seconds');
    const currentFpaSelections = explorerQueryModel.get('fpa');
    const time_format = dataview.queryBuckets.selectedQuery.get('time_format');

    if (lookback_seconds) {
      const { start, end } = getStartAndEndFromLookback(lookback_seconds);
      commonFields.starting_time = start.format(DEFAULT_DATETIME_FORMAT);
      commonFields.ending_time = end.format(DEFAULT_DATETIME_FORMAT);
      commonFields.lookback_seconds = 0;
    }

    // diff fpa
    if (selections.length > 1) {
      explorerQueryModel.set({
        ...commonFields,
        fpa: {
          ...currentFpaSelections,
          type: 'diff_fpa',
          time: {
            lookback_seconds: 0,
            time_format,
            starting_time: selections[0].start,
            ending_time: selections[0].end
          },
          compare_time: {
            lookback_seconds: 0,
            time_format,
            starting_time: selections[1].start,
            ending_time: selections[1].end
          }
        }
      });

      dataview.toggleComparisonWorkflow();
    }

    // single window FPA
    else {
      explorerQueryModel.set({
        ...commonFields,
        fpa: {
          ...currentFpaSelections,
          type: 'fpa',
          time: {
            lookback_seconds: 0,
            time_format,
            starting_time: selections[0].start,
            ending_time: selections[0].end
          },
          compare_time: {}
        }
      });
    }

    // we can attach a custom callback to the DataView to handle the FPA analysis option
    // there is a lot of logic in this BaseHighchartsDataview component that is specific to the FPA analysis
    // how could we isolate this code more?
    if (dataview.onSelectFpaAnalysisOption) {
      dataview.onSelectFpaAnalysisOption(explorerQueryModel.serialize());
    }

    // or just apply the new options to the existing query model
    else {
      dataview.applyToSelectedQuery(explorerQueryModel, false);
    }

    $auth.track('Run Probable Cause from Dataview', {
      query: explorerQueryModel.serialize()
    });

    this.clearSelectionRects();
    this.setState({ showRangeSelectionMenu: false });
  };

  renderTimeReset() {
    if (!this.chart) {
      return;
    }

    let btn = this.chart.container.querySelector('.highcharts-button');
    if (!btn) {
      this.chart.showResetZoom();
      btn = this.chart.container.querySelector('.highcharts-button');
      btn.querySelector('text').innerHTML = 'Zoom out';
      btn.querySelector('rect').setAttribute('width', 68);
    }
  }

  findSeries(bucket, name) {
    return this.chart.series.find((series) => series.name === name);
  }

  getYAxis(bucket) {
    const bucketName = typeof bucket === 'string' ? bucket : bucket.get('name');
    return bucketName.includes('Left') || bucketName.includes('Period') ? 0 : 1;
  }

  updateRenderedSeries(bucket, name, data, forecast) {
    const { added, removed, flow } = data;
    if (!data.updated && !forecast) {
      return;
    }

    data.updated = false;
    const existingSeries = this.findSeries(bucket, name);
    if (existingSeries) {
      if (added) {
        const dataToAdd = flow.slice(-added);
        dataToAdd.forEach((point) => existingSeries.addPoint(point, false));
      }
      if (removed && existingSeries.data) {
        let toRemove = removed;
        while (toRemove > 0 && existingSeries.data.length) {
          existingSeries.data[0].remove(false);
          toRemove -= 1;
        }
      }

      if (forecast) {
        const {
          is_baseline,
          ts: { timestamps_ms, values }
        } = forecast;
        const { xData } = existingSeries;
        const rangeData = timestamps_ms.map((ts, i) => [
          parseInt(ts),
          values[1].double_values[i],
          values[2].double_values[i]
        ]);

        const lastRealValue = xData.slice(-1)[0];

        if (is_baseline) {
          if (!existingSeries.linkedSeries.find((s) => s.name === 'Baseline')) {
            this.chart.addSeries(
              {
                type: 'line',
                name: 'Baseline',
                linkedTo: existingSeries.name,
                className: 'highcharts-series-dot',
                data: timestamps_ms.map((ts, i) => [parseInt(ts), values[0].double_values[i]]),
                colorIndex: existingSeries.colorIndex
              },
              false
            );
            this.chart.addSeries(
              {
                type: 'arearange',
                name: 'Baseline Range',
                linkedTo: existingSeries.name,
                className: 'forecast-arearange-series',
                data: rangeData,
                colorIndex: existingSeries.colorIndex
              },
              false
            );
          }
        } else {
          const { yData } = existingSeries;
          const timestamps = xData.concat(timestamps_ms);
          const newValues = yData.concat(values[0].double_values);

          existingSeries.update(
            {
              data: timestamps.map((ts, i) => [parseInt(ts), newValues[i]]),
              zoneAxis: 'x',
              zones: [{ value: new Date(lastRealValue) }, { className: 'highcharts-series-dot' }]
            },
            false
          );

          if (!existingSeries.linkedSeries.find((s) => s.name === 'Forecast Range')) {
            this.chart.addSeries(
              {
                type: 'arearange',
                name: 'Forecast Range',
                linkedTo: existingSeries.name,
                className: 'forecast-arearange-series',
                data: rangeData,
                colorIndex: existingSeries.colorIndex
              },
              false
            );
          }
        }

        this.chart.redraw();
      }
    } else {
      console.warn('no series matching', name, 'found');
    }
  }

  updateRenderedOverlay(bucket, name, data) {
    this.updateRenderedSeries(bucket, name, data);
  }

  removeSeries(bucketName, name) {
    const existingSeries = this.chart && this.chart.series.find((series) => series.name === name);
    if (existingSeries) {
      existingSeries.remove(false);
    }
  }

  toggleSeries(series, toggled, preventRedraw) {
    series.setVisible(!toggled, !preventRedraw);
    if (!preventRedraw) {
      this.redraw();
    }
  }

  compareSeries(series) {
    if (this.comparisonSeries !== series.name) {
      this.comparisonSeries = series.name;

      this.chart.series.forEach((s) => {
        if (s.name === series.name) {
          s.update({ type: 'line', colorIndex: 50 }, false);
          s.setVisible(true, false);
        } else {
          s.setVisible(false, false);
        }
      });

      this.redraw();
    }
  }

  removeComparison() {
    const { dataview } = this.props;
    const { bucketTab } = dataview;

    this.chart.series.forEach((s) => {
      const { model } = s.options;
      const visible = this.shouldShowSeries(model, bucketTab);
      s.update({ type: s.initialType, colorIndex: model.get('colorIndex') }, false);
      s.setVisible(visible, false);
    });

    this.comparisonSeries = null;
    this.redraw();
  }

  showActivePeriod(bucketName) {
    if (this.chart && !this.comparisonSeries) {
      this.chart.series.forEach((s) => {
        const { model } = s.options;
        const visible = this.shouldShowSeries(model, bucketName);
        s.setVisible(visible, false);
      });

      this.redraw();
    }
  }

  shouldShowSeries(model, bucketName) {
    return (
      !model.get('toggled') &&
      (model.isOverlay || model.get('key') === 'Total' || model.collection.bucket?.get('name') === bucketName)
    );
  }

  highlightSeries(series) {
    series.setState('hover');
    series.linkedSeries.forEach((s) => s.setState('hover'));
    this.chart.renderer.boxWrapper.addClass('highcharts-legend-series-active');
  }

  unhighlightSeries(series) {
    this.chart.renderer.boxWrapper.removeClass('highcharts-legend-series-active');
    series.setState();
    series.linkedSeries.forEach((s) => s.setState());
  }

  addNativeLegendHoverEvents(series) {
    if (this.props.showNativeLegend && (!series.events || !series.events.afterAnimate)) {
      series.update(
        {
          events: Object.assign({}, series.events, {
            afterAnimate: function afterAnimate() {
              if (this.legendItem) {
                const allMatchingSeries = this.chart.series.filter((s) => s.name === this.name);

                this.legendItem.on('mouseover', () => {
                  allMatchingSeries.forEach((s) => s.setState('hover'));
                });
                this.legendItem.on('mouseout', () => {
                  allMatchingSeries.forEach((s) => s.setState());
                });
              }
            }
          })
        },
        false
      );
    }
  }

  setSelectedModels(models) {
    this.selectedModels = models; // Keeping this around in case it needs to be used later
    this.setVisibleModels(models);
  }

  setVisibleModels(models) {
    if (this.chart && this.chart.series) {
      this.chart.series.forEach((series) => {
        if (series.options && series.options.model) {
          const { model } = series.options;
          const seriesKey = model.get('key');

          const visible = models.length === 0 || models.some((m) => m.get('key') === seriesKey);
          series.setVisible(visible, false);
        }
      });

      this.chart.redraw();
    }
  }

  clear() {
    if (this.chart && this.chart.series) {
      this.chart.series.forEach((series) => series.remove(false));
      this.chart.redraw();
    }
  }

  updateColors() {
    if (!this.chart || !this.chart.series) {
      return;
    }

    const seriesMap = this.chart.series.reduce((map, series) => {
      map[series.name] = series.options.model;
      return map;
    }, {});

    // chart color palette (defaults for series)
    this.chart.update({ colors: this.chartColors }, false);

    // update overlay colors
    this.chart.series
      .filter((series) => series.name === 'Total')
      .forEach((series) => series.update({ color: this.primaryOverlayColor }, false));

    this.chart.series
      .filter((series) => series.name.includes('Historical Total'))
      .forEach((series) => series.update({ color: this.overlayColor }, false));

    // axis colors
    const axisUpdate = {
      labels: { style: { color: this.chartLabelColor } },
      title: { style: { color: this.chartLabelColor } }
    };
    this.chart.xAxis.forEach((axis) => axis.update(axisUpdate, false));
    this.chart.yAxis.forEach((axis) => {
      axis.plotLinesAndBands.forEach((plotLine) => {
        plotLine.options.label.style = { color: this.chartLabelColor };
      });
      axis.update(axisUpdate, false);
    });

    // legend colors
    const { legend } = this.chart;
    if (legend.options.enabled) {
      legend.update(
        {
          itemStyle: { color: `${this.chartLabelColor} !important` },
          itemHoverStyle: { color: `${this.chartLabelColor} !important` }
        },
        false
      );
    }

    $app.renderSync(() => {
      if (this.chart) {
        this.chart.redraw();
        this.chart.series.forEach((series) => {
          if (seriesMap[series.name]) {
            if (series.options.color) {
              const matchingSeries = this.chart.series.find((s) => s.name === series.name && s !== series);
              if (matchingSeries) {
                series.update({ color: matchingSeries.color });
              }
            }
            series.options.model = seriesMap[series.name];
            series.options.model.set({ color: series.color });
          }
        });
      }
    });
  }

  redraw({ setColors = false } = {}) {
    if (this.chart) {
      if (setColors) {
        this.updateColors();
      } else {
        $app.renderSync(() => {
          if (this.chart && this.chart.series) {
            this.chart.redraw();
          }
        });
      }
      $app.renderSync(() => {
        this.dismissSpinner();
      });
    }
  }

  reflow() {
    if (this.chart && this.chart.hasRendered && this.el) {
      this.chart.setSize(this.el.offsetWidth, this.el.offsetHeight);
      this.chart.reflow();
    }
  }

  enableTooltips = () => {
    if (this.chart) {
      const { viewProps } = this.props;

      // We have to fallback on true here because otherwise they'll never be enabled.
      const finishedAnimating = this.chart.series
        ? this.chart.series.every((series) => series.finishedAnimating === true || series.visible === false)
        : true;

      if (viewProps.showTooltips !== false && this.chart.options && finishedAnimating) {
        this.chart.options.tooltip.enabled = true;
      }
    }
  };

  syncAxes(sync) {
    this.sync = sync;
    this.redraw();
  }

  useLogAxis(use) {
    if (this.chart.yAxis.length === 1) {
      this.chart.yAxis[0].update({ min: use ? 0 : 0.001 }, false);
    }
    this.chart.yAxis[0].update({ custom: use ? { allowNegativeLog: true } : {} });

    this.chart.yAxis[0].update({ type: use ? 'logarithmic' : 'linear' });
  }

  useSecondaryLogAxis(use) {
    if (this.chart.yAxis[1]) {
      this.chart.yAxis[1].update({ custom: use ? { allowNegativeLog: true } : {} });
      this.chart.yAxis[1].update({ type: use ? 'logarithmic' : 'linear' });
    }
  }

  getExtremes() {
    let min = 0;
    let max = 0;
    if (this.chart && this.chart.yAxis) {
      this.chart.yAxis.forEach((axis) => {
        const { dataMin, dataMax } = axis.getExtremes();
        min = Math.min(min, dataMin);
        max = Math.max(max, dataMax);
      });
    }
    return { min, max };
  }

  setExtremes(min, max) {
    if (min !== null && max !== null) {
      this.hasOverriddenExtremes = true;
      this.chart.yAxis.forEach((axis) => axis.setExtremes(min, max));
    } else {
      this.hasOverriddenExtremes = false;
      this.chart.yAxis.forEach((axis) => axis.setExtremes());
      this.chart.redraw();
    }
  }

  getLegendItemClick() {
    const { onModelSelect, sourceLink } = this.props;
    const config = this.chartOptions;
    config.chart = config.chart || {};

    if (!$app.isExport) {
      return function onLegendItemClick(e) {
        const resultModel = get(this.options, 'model');
        const sourceQueryModel = get(sourceLink, 'model.dataview.queryBuckets.selectedQuery');

        if (
          onModelSelect &&
          resultModel &&
          !resultModel.get('isOverlay') &&
          sourceQueryModel &&
          !isEqual(sourceQueryModel.get('metric'), ['Traffic'])
        ) {
          const { metaKey } = e.browserEvent || e;
          e.preventDefault();

          onModelSelect(this.options.model, sourceLink.model, metaKey);
        }
      };
    }

    return null;
  }

  getComponent() {
    const { dataview, viewProps } = this.props;
    const config = this.chartOptions;

    const time_format = dataview.queryBuckets.selectedQuery.get('time_format');
    const highchartsTimeOptions = {};
    if (typeof time_format === 'number') {
      highchartsTimeOptions.timezoneOffset = -time_format;
      highchartsTimeOptions.useUTC = true;
    } else if (time_format === 'Local') {
      highchartsTimeOptions.useUTC = false;
    } else {
      highchartsTimeOptions.useUTC = true;
    }
    config.time = highchartsTimeOptions;

    config.chart.spacingRight = 0;
    config.chart.spacingBottom = 16;

    config.chart = config.chart || {};
    if ($app.isExport) {
      config.chart.animation = false;
      config.colors = this.chartColors;
      config.plotOptions = config.plotOptions || {};
      config.plotOptions.series = config.plotOptions.series || {};
      config.plotOptions.series.animation = false;
    } else {
      config.chart.animation = { duration: 250 };
      if (config.plotOptions && config.plotOptions.series) {
        const { series } = config.plotOptions;
        if (series.animation === undefined) {
          series.animation = { duration: 350 };
        }

        if (!series.events) {
          series.events = {};
        }
        series.events.legendItemClick = this.getLegendItemClick();
        series.events.afterAnimate = () => {
          setTimeout(this.enableTooltips, 100); // give highcharts time to update the series.finishedAnimating flag
        };
      }
    }

    const options = defaultsDeep({}, viewProps.chartConfig || {}, config);

    if (options.tooltip) {
      // if showing tooltips, disable them for now and re-enable after all series animations have finished
      options.tooltip.enabled = false;
      // TODO: re-enable outside tooltips once we get rid of styledMode
      // break tooltips out of chart container to allow better positioning and readability, esp. for shared tooltips
      // options.tooltip.outside = true;
      // options.tooltip.useHTML = true;

      // we're offering an opt-in outside tooltip implementation here in styled mode
      // I don't think this qualifies yet as a complete replacement as asked for above, but more of a stopgap
      if (options.tooltip.outside) {
        // the outside tooltip styles are in core/components/styles/_highchartOutsideTooltip.scss
        // these are minimal styles that support light and dark themes and svg and html flavored tooltips
        // when we use outside tooltips, we don't get the benefits of the styles in the Highcharts wrapper component
        // nor do we get the dark mode css class as part of regular css inheritance
        // to fix this, we affix a new css class indicating the use of this outside tooltip and the dark mode css class so we can style on theme change
        options.tooltip.className = classNames(options.tooltip.className, 'highcharts-outside-tooltip', {
          [Classes.DARK]: this.darkThemeEnabled
        });
      }
    }

    const {
      showRangeSelectionMenu,
      canPerformAnalysis,
      selectedDurationText,
      selectionStart,
      selectionEnd,
      popoverTargetStyles
    } = this.state;

    return (
      <div style={{ position: 'relative', height: '100%', width: '100%', overflow: 'hidden' }}>
        <Highcharts
          {...viewProps}
          dataview={dataview}
          options={options}
          callback={this.chartCallback}
          colors={this.chartColors}
          primaryOverlayColor={this.primaryOverlayColor}
          overlayColor={this.overlayColor}
        />
        <Popover
          usePortal
          minimal={false}
          boundary="viewport"
          modifiers={{
            preventOverflow: { enabled: true },
            flip: { enabled: true }
          }}
          position={Position.RIGHT_TOP}
          isOpen={showRangeSelectionMenu}
          onInteraction={this.handleTimeSelectionPopoverInteraction}
          content={
            <Flex flexDirection="column" gap="4px" p={1} minWidth={325}>
              <Flex alignItems="center" gap="4px" ml="11px">
                <TinyWindowIcon />
                <Text fontWeight="bold">Selection</Text>
                <Flex alignItems="center" gap="4px">
                  <Text muted>
                    {selectionStart} - {selectionEnd} ({selectedDurationText})
                  </Text>
                </Flex>
              </Flex>

              <Menu>
                {!dataview.isComparisonWorkflow && (
                  <MenuItem text="Zoom to Selection" icon="zoom-in" onClick={this.handleZoomToSelection} />
                )}

                {dataview.isQueryMetricValidForFpa && (
                  <>
                    <MenuDivider title="Probable Cause Analysis" />

                    {!canPerformAnalysis && (
                      <MenuDivider
                        title={
                          <Flex alignItems="center" gap="4px" ml="6px" my="6px" lineHeight="12px">
                            <Icon icon="warning-sign" iconSize={12} color="warning" />
                            <Text small muted>
                              Selection exceeds 2 hours. Please shorten to run analysis.
                            </Text>
                          </Flex>
                        }
                      />
                    )}

                    {dataview.isComparisonWorkflow && (
                      <MenuItem
                        text="Run Comparison"
                        icon="comparison"
                        disabled={!canPerformAnalysis}
                        onClick={this.handlePerformPatternAnalysis}
                      />
                    )}

                    {!dataview.isComparisonWorkflow && (
                      <MenuItem
                        className="analyze-traffic-button"
                        icon="clean"
                        disabled={!canPerformAnalysis}
                        onClick={this.handlePerformPatternAnalysis}
                        text={
                          <Flex flexDirection="column">
                            <Text as="div">Analyze Traffic</Text>
                            {canPerformAnalysis && (
                              <Text muted small>
                                Explore probable causes of traffic variations
                              </Text>
                            )}
                          </Flex>
                        }
                      />
                    )}

                    {!dataview.isComparisonWorkflow && (
                      <MenuItem
                        icon="comparison"
                        className="compare-traffic-button"
                        disabled={!canPerformAnalysis}
                        text={
                          <Flex flexDirection="column">
                            <Text as="div">Compare Traffic</Text>
                            {canPerformAnalysis && (
                              <Text muted small>
                                Select two time periods to analyze differences and uncover probable causes
                              </Text>
                            )}
                          </Flex>
                        }
                      />
                    )}
                  </>
                )}

                {dataview.isComparisonWorkflow && (
                  <>
                    <MenuDivider />
                    <MenuItem text="Cancel" icon="cross" onClick={dataview.toggleComparisonWorkflow} />
                  </>
                )}
              </Menu>
            </Flex>
          }
          targetProps={{
            style: {
              pointerEvents: 'none',
              position: 'absolute',
              width: '5px',
              height: '5px',
              ...popoverTargetStyles
            }
          }}
          target={<div ref={this.popoverTargetRef} />}
        />
      </div>
    );
  }
}
