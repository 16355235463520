import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import AlertModel from 'app/stores/alerting/AlertModel';
import AlertCollection from 'app/stores/alerting/AlertCollection';
import { isValidUUID } from 'app/views/alerting/util/uuid';

import { Flex, Spinner, Suspense } from 'core/components';
import NotFound from 'app/views/NotFound';
import Page from 'app/components/page/Page';

import AlertDetails from 'app/views/alerting/components/details/AlertDetails';
import AlertingSidebar from 'app/views/alerting/components/details/AlertingSidebar';
import storeLoader from 'app/stores/storeLoader';
import { getEntityData } from './components/util/nms/nmsAlertEntity';

const ONE_WEEK_IN_SECS = 3600 * 24 * 7;

@storeLoader('$alerting.policyCollection')
@inject('$exports', '$alerting', '$metrics')
@observer
class AlertingDetails extends Component {
  state = {
    error: false,
    loading: true,
    severityHistory: [],
    policyAlarmCount: 0,
    alertHistoryCollection: new AlertCollection(),
    alertModel: new AlertModel()
  };

  get alertId() {
    const { match } = this.props;
    return match.params.id;
  }

  componentDidMount() {
    if (isValidUUID(this.alertId)) {
      this.loadAlertManagerAlert();
    } else {
      this.setState({ error: true, loading: false });
    }
  }

  loadAlertManagerAlert() {
    const { alertModel } = this.state;

    alertModel
      .set({ id: this.alertId })
      .fetch()
      .then(({ history: severityHistory }) => {
        this.setState({ loading: false, severityHistory });
        this.loadPolicyAlarmCount();
        this.loadAlertHistory();
      });
  }

  loadPolicyAlarmCount() {
    const { $alerting } = this.props;
    const { alertModel } = this.state;
    const { ruleId } = alertModel;

    if (ruleId) {
      $alerting
        .getPolicyAlarmCount(alertModel.ruleId, ONE_WEEK_IN_SECS)
        .then(({ count }) => this.setState({ policyAlarmCount: count }));
    }
  }

  loadAlertHistory() {
    const { alertModel, alertHistoryCollection } = this.state;
    const { ruleId, dimensionToValue } = alertModel;

    if (ruleId) {
      // Construct an exact dimension key/value filter
      const dimensionKeys = Object.keys(alertModel.dimensionToValue);
      const dimensionFilters = dimensionKeys.reduce(
        (acc, key) => ({ ...acc, [key]: { equals: dimensionToValue[key] } }),
        {}
      );

      alertHistoryCollection.setServerFilter(
        {
          lookback: ONE_WEEK_IN_SECS,
          ruleIds: [ruleId],
          states: ['alarm', 'clear'],
          applications: [],
          keys: { filter: dimensionFilters }
        },
        { force: true }
      );
    }
  }

  get device() {
    const { $metrics } = this.props;
    const { alertModel } = this.state;

    if (alertModel?.isMetricPolicy) {
      return alertModel.reconDevice;
    }

    if (alertModel?.isNmsPolicy) {
      const entityData = getEntityData(alertModel);
      if (entityData?.device) {
        return $metrics.deviceCollection.get(entityData.device.id);
      }
    }

    return null;
  }

  render() {
    const { alertHistoryCollection, policyAlarmCount, alertModel, loading, severityHistory, error } = this.state;
    const { policy, id } = alertModel;
    const isLoading = loading || alertModel.requestStatus === 'fetching';

    // if there's an error OR we're missing the policy object
    if (error || alertModel?.error || (!loading && (!policy || !id))) {
      return <NotFound hideSubnav={false} title="Alert Not Found" text={`Alert ${this.alertId} was not found.`} />;
    }

    return (
      <Page
        pt={0}
        pb={0}
        className="insight-detail"
        title={loading ? 'Loading...' : `Alert Details: ${policy.get('name') || ''}`}
        scrolls
        showExport
      >
        <Suspense loading={isLoading} fallback={<Spinner intent="primary" m="auto" />}>
          {!alertModel?.error && !isLoading && (
            <Flex flex="1">
              <AlertDetails
                model={alertModel}
                policy={policy}
                alertHistoryCollection={alertHistoryCollection}
                device={this.device}
              />
              <AlertingSidebar
                device={this.device}
                model={alertModel}
                policy={policy}
                policyAlarmCount={policyAlarmCount}
                severityHistory={severityHistory}
              />
            </Flex>
          )}
        </Suspense>
      </Page>
    );
  }
}

export default AlertingDetails;
