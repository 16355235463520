import React from 'react';
import { inject } from 'mobx-react';

import Link from 'core/components/Link';

const PacketSizeLink = ({ $app, packetSize, ...rest }) => {
  if ($app.isSubtenant) {
    return packetSize;
  }

  return (
    <Link to={`/v4/core/quick-views/packet-size/${packetSize}`} {...rest}>
      {packetSize}
    </Link>
  );
};

export default inject('$app')(PacketSizeLink);
