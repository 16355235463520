import React, { Component } from 'react';
import Button from './Button';
import Text from './Text';

const ShowMore = ({ length, setLength, maxLength, showButtonProps, text }) => {
  const handleShowMore = () => {
    setLength(length > maxLength ? maxLength : text.length);
  };

  if (text.length > maxLength) {
    return (
      <>
        {text.length > length && '...'}
        <Button mb="2px" minimal small fontWeight={700} {...showButtonProps} onClick={handleShowMore}>
          Show {text.length > length ? 'More' : 'Less'}
        </Button>
      </>
    );
  }

  return null;
};

class Paragraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      length: props.maxLength
    };
  }

  setLength = (length) => {
    this.setState({
      length
    });
  };

  render() {
    const { children, maxLength, showMoreProps, ...otherProps } = this.props;
    const { length } = this.state;

    return (
      <Text as="p" {...otherProps}>
        {typeof children === 'string' && length > 0 ? children.substring(0, length) : children}
        {typeof children === 'string' && maxLength > 0 && (
          <ShowMore
            length={length}
            setLength={this.setLength}
            maxLength={maxLength}
            showMoreProps={showMoreProps}
            text={children}
          />
        )}
      </Text>
    );
  }
}

export default Paragraph;
