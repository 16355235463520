//  Scan filtersObj and look for fields that match filterMapping, apply the value of the filterMapping to the filter.
//  Example filterMapping:
//  { i_device_type: { filterField: 'i_device_subtype', filterValue: 'router' }}
function overwriteFilter(filtersObj, filterMapping, valueMatch) {
  if (filtersObj) {
    if (filtersObj.filters) {
      filtersObj.filters = filtersObj.filters.map((filter) => {
        const overrides = filterMapping[filter.filterField] || {};
        // override only if filterValue matches valueMatch
        if (valueMatch && filter.filterValue !== valueMatch) {
          return { ...filter };
        }
        return {
          ...filter,
          ...overrides
        };
      });
    }

    if (filtersObj.filterGroups && !filtersObj.filterGroups?.some((group) => group.name?.startsWith('mkp_preview'))) {
      filtersObj.filterGroups.forEach((filterGroup) => overwriteFilter(filterGroup, filterMapping, valueMatch));
    }
  }
}

module.exports = overwriteFilter;
