import React from 'react';
import classNames from 'classnames';
import { Classes } from '@blueprintjs/core';

import { Box, Flex, Icon, Text } from 'core/components';

const DeviceValueRenderer = (props) => {
  const { option, onUnselect, field, asTag = false, readOnly, value } = props;

  const className = classNames(option.className, {
    [Classes.TAG]: asTag,
    [Classes.TAG_REMOVE]: asTag && !readOnly && onUnselect,
    [Classes.MINIMAL]: asTag
  });

  return (
    <Flex position="relative" pr="20px" className={className}>
      <Icon icon={option.icon} ml="2px" mr={1} iconSize={14} />
      <Box>
        <Text as="div" fontWeight="bold" ellipsis>
          {option.label}
        </Text>
        <Text as="div" muted ellipsis>
          {option.group}
        </Text>
      </Box>
      {!readOnly && onUnselect && (
        <button
          type="button"
          className={Classes.TAG_REMOVE}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onUnselect(field, value || option.value);
          }}
        />
      )}
    </Flex>
  );
};

export default DeviceValueRenderer;
