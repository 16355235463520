import React from 'react';
import { action, extendObservable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Box, Card, Flex } from 'core/components';
import { cleanFilterFields, getModelFilters } from 'app/util/filters';
import DataViewModel from 'app/stores/query/DataViewModel';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import { deepClone } from 'core/util';
import { buildFilterGroup, mergeFilterGroups } from 'core/util/filters';
import { colors } from 'core/util/colors';
import BaseDataview from './BaseDataview';

@inject('$app')
@observer
export default class GeneratorView extends BaseDataview {
  state = {
    lastUpdateTs: undefined
  };

  dataviewModels = {};

  allowScroll = true;

  dataviewDisposers = [];

  componentDidUpdate() {
    const { activeBuckets, selectedQuery } = this.props.dataview.queryBuckets;
    if (activeBuckets[0]?.queryResults.size) {
      this.dataviewDisposers = [];
      this.dismissSpinner(0, false);
      const bracketOptions = selectedQuery.get('bracketOptions');
      const rows = activeBuckets[0].queryResults.nonOverlayRows.slice(0, selectedQuery.get('topx'));
      rows.forEach((row) => {
        const lookup = row.get('lookup');
        const key = row.get('key');
        let tagData;
        if (bracketOptions) {
          tagData = row.get(bracketOptions.tagKey);
        }
        if (!Object.keys(this.dataviewModels).find((existingKey) => existingKey === key)) {
          this.dataviewModels[key] = new DataViewModel();
          const subDataview = this.dataviewModels[key];
          this.dataviewDisposers.push(
            reaction(
              () => subDataview.fullyLoaded,
              () => {
                if (selectedQuery.get('sync_all_axes')) {
                  this.syncAllAxes(true);
                }
                if (Object.values(this.dataviewModels).every((model) => model.fullyLoaded)) {
                  this.props.dataview.setFullyLoaded();
                  this.dataviewDisposers.forEach((disposer) => disposer());
                  this.dataviewDisposers = [];
                }
              }
            )
          );
          extendObservable(this.dataviewModels[key], {
            lookup,
            key,
            bracketColor: (tagData && tagData.value) || null
          });

          const seriesName = (lookup || key)
            .split('----')
            .slice(0, selectedQuery.get('metric').length)
            .join(' \u2192 ');

          const filters = deepClone(selectedQuery.get('filters'));
          const filterDimensionsEnabled = selectedQuery.get('filterDimensionsEnabled');
          const filterDimensions = selectedQuery.get('filterDimensions');
          const filterDimensionOther = selectedQuery.get('filterDimensionOther');

          this.dataviewModels[key].setQuery(
            Object.assign({}, selectedQuery.serialize(), {
              filters: filterDimensionsEnabled
                ? mergeFilterGroups(filters, {
                    filterGroups: [
                      key === 'Other' && filterDimensionOther
                        ? buildFilterGroup({
                            name: 'Other',
                            connector: 'Any',
                            filterGroups: filterDimensions.filterGroups,
                            not: true
                          })
                        : filterDimensions.filterGroups.find((group) => group.name === key)
                    ]
                  })
                : getModelFilters({
                    model: row,
                    bucket: activeBuckets[0],
                    // getModelFilters will add its own filters based on model and metrics
                    // here we need to clean out any pre-existing filters that use those metrics so the chart data is ensured to be unique
                    filtersAcc: cleanFilterFields(filters, activeBuckets[0].firstQuery.get('metric'))
                  }),
              filterDimensionsEnabled: false,
              generatorMode: false,
              metric: selectedQuery.get('generatorDimensions'),
              query_title: selectedQuery.get('generatorQueryTitle').replace(/{{generator_series_name}}/g, seriesName),
              topx: selectedQuery.get('generatorTopx')
            }),
            { save: true }
          );
          this.dataviewModels[key].queryBuckets.activeBuckets[0].queryResults.dataview = this.dataviewModels[key];
          this.finishLoading();
        }
      });
      Object.keys(this.dataviewModels).forEach((existingKey) => {
        if (!rows.some((row) => row.get('key') === existingKey)) {
          delete this.dataviewModels[existingKey];
        }
      });
    }
  }

  finishLoading = () => {
    // Because of React 17's lifecycle "enhancements" and fighting with MobX we have to force an update
    this.setState({ lastUpdateTs: new Date() });
  };

  clear() {
    this.dataviewModels = {};
  }

  reflow() {
    Object.values(this.dataviewModels).forEach((model) => {
      if (model.component && model.component.reflow) {
        model.component.reflow();
      }
    });
  }

  syncAxes(sync) {
    Object.values(this.dataviewModels).forEach((model) => model.component.syncAxes(sync));
  }

  useLogAxis(use) {
    Object.values(this.dataviewModels).forEach((model) => model.component.useLogAxis(use));
  }

  useSecondaryLogAxis(use) {
    Object.values(this.dataviewModels).forEach((model) => model.component.useSecondaryLogAxis(use));
  }

  syncAllAxes(sync) {
    let min = null;
    let max = null;
    if (sync) {
      Object.values(this.dataviewModels).forEach((model) => {
        const extremes = model.component.getExtremes();
        if (extremes) {
          min = Math.min(min, extremes.min);
          max = Math.max(max, extremes.max);
        }
      });
    }
    Object.values(this.dataviewModels).forEach((model) => model.component.setExtremes(min, max));
  }

  syncExtents(sync_extents) {
    Object.values(this.dataviewModels).forEach((model) => {
      model.component.syncExtents();
      model.queryBuckets.selectedQuery.set({ sync_extents });
      model.saveAndReinitialize(true);
    });
  }

  @action
  setSelectedModels(selectedModels) {
    if (Array.isArray(selectedModels) && typeof selectedModels[0] === 'string') {
      selectedModels = selectedModels.reduce((arr, selectedModelKey) => {
        let selectedModel;
        Object.values(this.dataviewModels).forEach((dataviewModel) => {
          if (dataviewModel.queryBuckets.activeBuckets[0]) {
            const { queryResults } = dataviewModel.queryBuckets.activeBuckets[0];
            selectedModel = queryResults?.find({ key: selectedModelKey });
          }
        });
        if (selectedModel) {
          arr.push(selectedModel);
        }
        return arr;
      }, []);
    }
    Object.values(this.dataviewModels).forEach((model) => {
      model.bracketColor = null;

      model.setSelectedModels(selectedModels);
    });
    selectedModels.forEach(action((model) => (model.collection.dataview.bracketColor = colors.ORANGE3)));
  }

  getComponent() {
    const { $app, dataview, size, ...restOfProps } = this.props;
    const { selectedQuery } = dataview.queryBuckets;
    const subpanelCount = Object.keys(this.dataviewModels).length;
    const generatorColumns = Math.min(subpanelCount, selectedQuery.get('generatorColumns'));
    const subpanelRows = Math.ceil(subpanelCount / generatorColumns);
    const panelWrapperStyle = {
      width: `${100 / generatorColumns}%`,
      minHeight: subpanelRows === 1 ? '100%' : selectedQuery.get('generatorPanelMinHeight'),
      padding: 5
    };

    return (
      <Flex
        flex="1 1 auto"
        flexWrap="wrap"
        style={{ padding: 5, width: '100%', height: '100%' }}
        className="generator-wrap"
      >
        {Object.values(this.dataviewModels).map((model) => (
          <Box flex="1 1 auto" style={panelWrapperStyle} key={model.key}>
            <DataViewWrapper
              {...restOfProps}
              dataview={model}
              fitToHeight
              viewProps={{
                showNativeLegend: !!model.queryBuckets.selectedQuery.get('metric').length,
                height: subpanelRows === 1 ? '100%' : panelWrapperStyle.minHeight && panelWrapperStyle.minHeight - 66
              }}
              headerProps={{
                isGenerated: true,
                showTitleLink: !$app.isSubtenant,
                showViewTypeTag: false,
                shouldArrangeVertically: true,
                showLastUpdated: false,
                showLiveUpdate: false
              }}
            >
              {({ component, header }) => (
                <Card
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  overflow="auto"
                  style={{ outline: model.bracketColor ? `4px auto ${model.bracketColor}` : null, height: '100%' }}
                >
                  <Box p={2} overflow="hidden">
                    {header}
                  </Box>
                  <Flex flex={1} px={2} overflow="auto">
                    {component}
                  </Flex>
                </Card>
              )}
            </DataViewWrapper>
          </Box>
        ))}
      </Flex>
    );
  }
}

const config = {
  showLegend: true,
  enableToggle: false,
  buckets: [
    {
      name: 'Generator'
    }
  ]
};

export { config };
