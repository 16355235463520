const NULL_COUNTRY = 'ZZ';

function lookupAsnPrivateReserved(asnParam) {
  const asn = parseInt(asnParam);
  if (asnParam === null || asnParam === undefined || Number.isNaN(asn)) {
    return null;
  }

  // reservedRanges lists ranges of reserved ASNs. Taken from
  // https://www.iana.org/assignments/as-numbers/as-numbers.xhtml
  const reservedRanges = [
    [0, 0],
    [23456, 23456], // AS_TRANS
    [64496, 64511], // Reserved for use in documentation and sample code
    [65535, 65535],
    [65536, 65551], // Reserved for use in documentation and sample code
    [65552, 131071],
    [4294967295, 4294967295]
  ];

  // privateRanges lists ranges of private ASNs Taken from
  // https://www.iana.org/assignments/as-numbers/as-numbers.xhtml
  const privateRanges = [
    [64512, 65534],
    [4200000000, 4294967294]
  ];

  for (const r of reservedRanges) {
    if (asn < r[0]) {
      break; // not found, but give up early
    }
    if (asn >= r[0] && asn <= r[1]) {
      return `-Reserved AS-,${NULL_COUNTRY}`;
    }
  }
  for (const r of privateRanges) {
    if (asn < r[0]) {
      break; // not found, but give up early
    }
    if (asn >= r[0] && asn <= r[1]) {
      return `-Private Use AS-,${NULL_COUNTRY}`;
    }
  }

  return null;
}

module.exports = { NULL_COUNTRY, lookupAsnPrivateReserved };
