import { matchPath } from 'react-router-dom';
import { isDev } from 'core/util';

import * as Sentry from '@sentry/react';

let initialized = false;
let initializing;

const _initialize = ({ currentRegion, sentryUrl, history }) => {
  // path we want to parameterize vs displaying full URL.
  const routes = [{ path: '/v4/core/explorer/:urlHash' }];

  const routerV5 = Sentry.reactRouterV5BrowserTracingIntegration({
    history,
    routes,
    matchPath
  });
  Sentry.init({
    dsn: isDev ? '' : sentryUrl, // disable sending metrics to sentry.io
    integrations: [routerV5],
    tracesSampleRate: 0.1, // 10 percent for now
    environment: isDev ? 'local' : currentRegion.toLowerCase()
  });
  initialized = true;

  return initialized;
};

export function initializeSentry(authStore) {
  if (!initializing) {
    initializing = _initialize(authStore);
  }
  return initializing;
}
