import React from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import {
  borderColor,
  borderRadius,
  borders,
  boxShadow,
  display,
  color,
  fontSize,
  fontWeight,
  overflow,
  space,
  textAlign,
  style
} from 'styled-system';

import { Classes } from '@blueprintjs/core';
import borderColors from './utils/borderColors';
import flex from './utils/flex';
import grid from './utils/grid';
import size from './utils/size';
import position from './utils/position';

const StyledCard = styled.div`
  ${borderColor}
  ${borderRadius}
  ${borders}
  ${boxShadow}
  ${display}
  ${color}
  ${display}
  ${fontSize}
  ${fontWeight}
  ${space}
  ${overflow}
  ${position}
  ${style({
    prop: 'overflowX'
  })}
  ${style({
    prop: 'overflowY'
  })}
  ${style({
    prop: 'transition'
  })}
  ${textAlign}

  ${borderColors}
  ${flex}
  ${grid}
  ${size}
  ${({ theme, bg }) =>
    theme.name === 'dark' &&
    css`
      &.${Classes.CARD} {
        background: ${bg ? theme.colors[bg] : theme.colors.cardBackground};
        border: 1px solid ${theme.colors.cardBorder};
        box-shadow: none;
      }
    `}
`;

const Card = React.forwardRef(
  ({ elevation, interactive, showSkeleton, className, runningText, loading, ...rest }, ref) => (
    <StyledCard
      ref={ref}
      className={classNames(Classes.CARD, className, {
        [`bp4-elevation-${elevation}`]: true,
        [Classes.RUNNING_TEXT]: runningText,
        [Classes.INTERACTIVE]: interactive,
        [Classes.SKELETON]: showSkeleton
      })}
      {...rest}
    />
  )
);

export default Card;

Card.defaultProps = {
  elevation: 0,
  p: 0,
  interactive: false
};
