import React from 'react';

import LargeLabeledValue from 'app/components/LargeLabeledValue';

export default function LabelValue({ label, value, labelWidth = 125, labelTextProps, ...props }) {
  if (value === null) {
    return null;
  }

  return (
    <LargeLabeledValue
      valueSize={14}
      valueWeight="medium"
      shouldFormatValue={false}
      label={label}
      value={value}
      display="flex"
      alignItems="flex-start"
      labelSize={14}
      labelTextProps={{ width: labelWidth, ...labelTextProps }}
      valueBoxProps={{ flex: 1 }}
      {...props}
    />
  );
}
