import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { isEqual } from 'lodash';

import ErrorMessage from 'core/components/ErrorMessage';
import { componentDidCatch } from 'core/components/AbstractErrorBoundary';

import FormState from '../FormState';
import FormContext from '../FormContext';
import $forms from '../util/$forms';

@observer
class FormComponent extends Component {
  state = {};

  static getDerivedStateFromError(error) {
    console.error('error', error);
    return { error };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { fields, options, onChange, permissions, model, values, large } = nextProps;

    if (!prevState.form) {
      const onSubmit = FormComponent.getHandleSave(nextProps);
      const onCancel = FormComponent.getHandleCancel(nextProps);
      const onRemove = FormComponent.getHandleRemove(nextProps);

      const form =
        nextProps.form ||
        new FormState({
          model,
          values,
          fields,
          options: { ...options, large },
          permissions,
          onChange,
          onSubmit,
          onCancel,
          onRemove
        });

      $forms.registerForm(form);

      return { form };
    }

    const { form } = prevState;

    if (nextProps.model && nextProps.model !== form.model) {
      form.setModel(nextProps.model);
    }

    if (!isEqual(nextProps.values, form.values)) {
      form.init(nextProps.values);
    }

    return { form };
  }

  static getHandleSave(props) {
    const { handleSave } = props;

    return (form, values) => {
      if (handleSave) {
        return handleSave(values);
      }

      return form.model.save(values);
    };
  }

  static getHandleCancel(props) {
    const { handleCancel } = props;

    return (form) => {
      if (handleCancel) {
        handleCancel();
      }
      // TODO: there's no reason we should be doing this by default
      if (form.model) {
        form.model.deselect();
      }
    };
  }

  static getHandleRemove(props) {
    const { handleRemove } = props;

    return (form) => {
      if (handleRemove) {
        return handleRemove();
      }

      return form.model.destroy();
    };
  }

  componentDidCatch(error, errorInfo) {
    componentDidCatch({ componentName: 'FormComponent', error, errorInfo, component: this });
  }

  componentDidMount() {
    const { form } = this.state;
    const { onLoad } = this.props;

    if (onLoad) {
      onLoad(form);
    }
  }

  componentWillUnmount() {
    const { form } = this.state;
    $forms.unregisterForm(form);
  }

  render() {
    const { children, options, values, fields, onChange, onLoad, ...rest } = this.props;
    const { form, error } = this.state;

    if (error) {
      return <ErrorMessage fullScreen showDescription={false} hasOutdatedBundle={false} />;
    }

    if (!form) {
      return null;
    }

    const { onSubmit, onCancel, onRemove, model } = form;

    const formProps = {
      ...rest,
      form,
      model,
      handleSave: onSubmit,
      handleCancel: onCancel,
      handleRemove: onRemove,
      error
    };

    const formBody =
      typeof children === 'function'
        ? children(formProps)
        : React.Children.map(children, (child) => child && React.cloneElement(child, formProps));

    return <FormContext.Provider value={form}>{formBody}</FormContext.Provider>;
  }
}

export default FormComponent;
