import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { capitalize, get, isEqual, uniqBy } from 'lodash';
import { FiCheckCircle } from 'react-icons/fi';

import {
  Box,
  Button,
  Callout,
  Card,
  Dialog,
  Dismissible,
  EmptyState,
  Flex,
  FlexColumn,
  Icon,
  Tag,
  Text,
  ToggleButtonGroup
} from 'core/components';
import { CELL_TYPES, VirtualizedTable } from 'core/components/table';
import { InterfaceNameAndDescription, InterfaceNameDeviceNameAndDescription } from 'app/components/Interface';
import InterfaceGroupInterfaceCollection from 'app/stores/interfaceGroup/InterfaceGroupInterfaceCollection';
import DynamicInterfaceFilterSidebar from 'app/components/interfaceGroup/DynamicInterfaceFilterSidebar';
import SelectedFiltersSummary from 'app/components/interfaceGroup/SelectedFiltersSummary';
import { AiOutlineSearch } from 'react-icons/ai';

@inject('$dictionary')
@observer
class InterfaceSelectorDialog extends Component {
  static defaultProps = {
    defaultValues: {},

    // NOTE: This is a bit of a hack to allow the dialog to be used in a different context
    // during Recon Up/Down policy selection. We don't want to allow individual interface selection,
    // The use-case is build out a set of filters, see what Interfaces match, and apply those filters to a policy.
    isInterfacePolicySelector: false
  };

  state = {
    interfaceCollection: new InterfaceGroupInterfaceCollection([], { groupBy: 'device.device_name' }),
    availableInterfaceGroupBy: 'device.device_name',
    selectedFilterGroups: [],
    staticInterfaces: [],
    selectedInterfaces: [],
    filters: {}
  };

  /**
   * NOTE: Because this is done in a constructor and expects the model to already be fetched, this component must be conditionally rendered.
   */
  constructor(props) {
    super(props);

    const { interfaceGroup } = props;

    const staticInterfaces = [...(interfaceGroup.get('staticInterfaces') || [])];
    const selectedFilterGroups = [...(interfaceGroup.get('filters') || [])];
    const selectedInterfaces = [...(interfaceGroup.get('interfaces') || [])];

    if (!interfaceGroup.isNew || staticInterfaces.length > 0 || selectedFilterGroups.length > 0) {
      this.state.selectedFilterGroups = selectedFilterGroups;
      this.state.staticInterfaces = staticInterfaces;
      this.state.selectedInterfaces = selectedInterfaces;
    }
  }

  componentDidMount() {
    const { defaultValues } = this.props;
    this.setState({ filters: defaultValues || {} });
    this.lookupInterfaces();
  }

  handleSave = () => {
    const { onSave } = this.props;
    const { staticInterfaces, selectedFilterGroups, filters: selectedSidebarFilters } = this.state;
    const filteredInterfaces = selectedFilterGroups.flatMap(({ interfaces }) => interfaces);
    const uniqInterfaces = uniqBy(
      [...staticInterfaces, ...filteredInterfaces],
      (model) => model.snmp_id + model.device_id
    );

    onSave({
      filters: selectedFilterGroups,
      staticInterfaces,
      interfaces: uniqInterfaces,
      selectedSidebarFilters
    });
  };

  onFilterChange = (filters) => {
    this.setState({ filters });
  };

  onGroupByChange = (availableInterfaceGroupBy) => {
    const { interfaceCollection } = this.state;
    interfaceCollection.group(availableInterfaceGroupBy);
    this.setState({ availableInterfaceGroupBy });
  };

  get hasFilters() {
    const { filters } = this.state;

    return Object.values(filters).some(Boolean);
  }

  get isDupeFilterGroup() {
    const { filters, selectedFilterGroups } = this.state;
    return selectedFilterGroups.some((filterGroup) => isEqual(filters, filterGroup.filters));
  }

  lookupInterfaces() {
    const { defaultValues } = this.props;
    const { interfaceCollection } = this.state;
    return interfaceCollection.fetch({ query: { includeSite: true, ...defaultValues } });
  }

  selectInterface = (model) => {
    const serializedModel = model.toJS();
    this.setState((prevState) => ({
      selectedInterfaces: [...prevState.selectedInterfaces, serializedModel],
      staticInterfaces: [...prevState.staticInterfaces, serializedModel]
    }));
  };

  selectAllInterfacesDynamic = () => {
    const { interfaceCollection } = this.state;
    if (interfaceCollection.size) {
      this.setState((prevState) => ({
        selectedInterfaces: [...prevState.selectedInterfaces, ...interfaceCollection.toJS()],
        selectedFilterGroups: [
          ...prevState.selectedFilterGroups,
          {
            filters: { ...prevState.filters },
            interfaces: [...interfaceCollection.toJS()],
            total_count: interfaceCollection.size
          }
        ]
      }));
    }
  };

  handleRemoveGroup = (filterGroup) => {
    const { selectedInterfaces, selectedFilterGroups } = this.state;
    const { interfaces } = filterGroup;

    const filterGroupIdx = selectedFilterGroups.indexOf(filterGroup);
    interfaces.forEach((iface) => {
      const idx = selectedInterfaces.findIndex((selectedInterface) => selectedInterface.id === iface.id);
      if (idx !== -1) {
        selectedInterfaces.splice(idx, 1);
      }
    });

    selectedFilterGroups.splice(filterGroupIdx, 1);
    this.setState({ selectedInterfaces, selectedFilterGroups });
  };

  handleRemoveInterface = (model) => {
    const { selectedInterfaces, staticInterfaces } = this.state;
    const idx = selectedInterfaces.indexOf(model);
    const staticIdx = staticInterfaces.indexOf(model);
    selectedInterfaces.splice(idx, 1);
    staticInterfaces.splice(staticIdx, 1);
    this.setState({ selectedInterfaces, staticInterfaces });
  };

  getGroupRenderer = ({ groupKey, group }) => {
    const { availableInterfaceGroupBy } = this.state;
    const { $dictionary } = this.props;
    if (availableInterfaceGroupBy === 'device.device_name') {
      return (
        <>
          {groupKey}{' '}
          {group.length > 0 && group[0].get('device') && (
            <Text muted>({get(group[0].get('device'), 'site.title', 'No Site')})</Text>
          )}
        </>
      );
    }
    if (availableInterfaceGroupBy === 'connectivity_type') {
      return <>{$dictionary.get(`interfaceClassification.connectivityTypes.${groupKey}`) || 'Unclassified'} </>;
    }
    if (availableInterfaceGroupBy === 'network_boundary') {
      return <>{$dictionary.get(`interfaceClassification.networkBoundaryTypes.${groupKey}`) || 'Unclassified'} </>;
    }
    return groupKey;
  };

  getInterfaceTableColumns = () => {
    const { isInterfacePolicySelector } = this.props;
    const { selectedInterfaces, availableInterfaceGroupBy } = this.state;
    const columns = [
      {
        label: 'Interface',
        name: 'interface_description',
        flexBasis: 200,
        renderer: ({ model }) => (
          <Box overflow="hidden" lineHeight="14px">
            {availableInterfaceGroupBy !== 'device.device_name' && (
              <InterfaceNameDeviceNameAndDescription
                device_name={model.get('device').device_name}
                interface_description={model.get('interface_description')}
                snmp_alias={model.get('snmp_alias')}
              />
            )}
            {availableInterfaceGroupBy === 'device.device_name' && (
              <InterfaceNameAndDescription
                device_name={model.get('device').device_name}
                interface_description={model.get('interface_description')}
                snmp_alias={model.get('snmp_alias')}
              />
            )}
            <Box my="2px">
              <Tag small minimal mr={1}>
                {model.capacityLabel}
              </Tag>
              {model.get('connectivity_type') && (
                <Tag small minimal mr={1}>
                  {model.connectivityType}
                </Tag>
              )}
              {model.get('network_boundary') && (
                <Tag small minimal mr={1}>
                  {model.networkBoundary}
                </Tag>
              )}
            </Box>
          </Box>
        )
      }
    ];

    // disable actions for interface policy selector
    if (!isInterfacePolicySelector) {
      columns.push({
        type: CELL_TYPES.ACTION,
        width: 48,
        actions: [
          (model) => {
            const showButton = !selectedInterfaces.find(
              (selectedInterface) =>
                selectedInterface.snmp_id === model.get('snmp_id') &&
                selectedInterface.device_id === model.get('device_id')
            );
            if (showButton) {
              return (
                <Button
                  key={model.get('snmp_id') + model.get('device_id')}
                  minimal
                  icon="plus"
                  intent="primary"
                  onClick={() => this.selectInterface(model)}
                  alignSelf="center"
                />
              );
            }
            return (
              <Icon
                style={{
                  display: 'inline-flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  verticalAlign: 'middle',
                  width: 30,
                  height: 30
                }}
                py="4px"
                px={1}
                key={model.get('snmp_id') + model.get('device_id')}
                color="success"
                icon={FiCheckCircle}
              />
            );
          }
        ]
      });
    }

    return columns;
  };

  groupSummaryLookup = ({ groupKey, group }) => <Text mx={1}>{this.getGroupRenderer({ groupKey, group })}</Text>;

  render() {
    const {
      emptyText,
      onClose,
      type,
      showSite,
      showInterfaceIp,
      showCapacity,
      showDeviceLabel,
      showProvider,
      showConnectivityType,
      showInterfaceType,
      showNetworkBoundary,
      showAdminStatus,
      staticInterfacesTitle,
      isInterfacePolicySelector,
      defaultValues,
      title,
      staticOnly
    } = this.props;
    const { interfaceCollection, selectedFilterGroups, staticInterfaces, availableInterfaceGroupBy } = this.state;
    const totalCount = interfaceCollection.totalCount || 0;
    const collectionSize = (interfaceCollection && interfaceCollection.size) || 0;

    const filteredInterfaces = selectedFilterGroups.flatMap(({ interfaces }) => interfaces);
    const totalSelectedInterfaces = uniqBy(
      [...staticInterfaces, ...filteredInterfaces],
      (model) => model.snmp_id + model.device_id
    ).length;
    const NUM_MAX_SELECTED_INTERFACES = 10000;
    const exceedsInterfaceLimit = totalSelectedInterfaces > NUM_MAX_SELECTED_INTERFACES;

    return (
      <Dialog
        isOpen
        onClose={onClose}
        title={title || `Configure ${capitalize(type)} Group`}
        width="calc(100vw - 100px)"
        height="calc(100vh - 40px)"
        top={100}
        maxWidth={1100}
        maxHeight={925}
      >
        <Dialog.Body p={2} pb={0} overflow="hidden">
          <FlexColumn height="100%" pb="1px" overflow="hidden" gap={1}>
            <Dismissible name="interface-selector-dialog">
              {({ onDismiss }) => (
                <Callout p={2} position="relative">
                  <Button position="absolute" icon="cross" large minimal right={8} top={8} onClick={onDismiss} />
                  <Flex gap={3}>
                    <Flex width={222} borderRight="thin" gap={1}>
                      <Tag round intent="primary" alignSelf="flex-start">
                        1
                      </Tag>
                      <Text>Find your interfaces using the filters below</Text>
                    </Flex>
                    {!staticOnly && (
                      <Flex flex={1} borderRight="thin" gap={1}>
                        <Tag round intent="primary" alignSelf="flex-start">
                          2
                        </Tag>
                        <Text>
                          Select your interfaces individually, or save your filters as{' '}
                          <Text whiteSpace="nowrap">
                            <Icon icon="layers" color="primary" /> Dynamic Interface Groups
                          </Text>{' '}
                          that will automatically associate matching interfaces as they are added to Kentik
                        </Text>
                      </Flex>
                    )}
                    {staticOnly && (
                      <Flex flex={1} borderRight="thin" gap={1}>
                        <Tag round intent="primary" alignSelf="flex-start">
                          2
                        </Tag>
                        <Text>
                          {isInterfacePolicySelector
                            ? 'Validate the filters are returning the Interfaces you want this Policy to monitor'
                            : 'Select your interfaces individually'}
                        </Text>
                      </Flex>
                    )}
                    {!isInterfacePolicySelector && (
                      <Flex width={367} gap={1}>
                        <Tag round intent="primary" alignSelf="flex-start">
                          3
                        </Tag>
                        <Text>Review all of your interfaces</Text>
                      </Flex>
                    )}
                  </Flex>
                </Callout>
              )}
            </Dismissible>

            {exceedsInterfaceLimit && !isInterfacePolicySelector && (
              <Text color="danger">* The number of selected interfaces cannot exceed 10K.</Text>
            )}

            <Flex flex={1} overflow="hidden" pb="1px" pr="1px">
              <Flex flex={1} overflow="hidden" p="1px">
                <Card mr={1} width={230} minWidth={230} overflow="hidden">
                  <DynamicInterfaceFilterSidebar
                    collection={interfaceCollection}
                    onFilterChange={this.onFilterChange}
                    defaultValues={defaultValues}
                    showSite={showSite}
                    showCapacity={showCapacity}
                    showInterfaceIp={showInterfaceIp}
                    showDeviceLabel={showDeviceLabel}
                    showProvider={showProvider}
                    showConnectivityType={showConnectivityType}
                    showNetworkBoundary={showNetworkBoundary}
                    showInterfaceType={showInterfaceType}
                    showAdminStatus={showAdminStatus}
                  />
                </Card>
                <Flex flexDirection="column" flex={1} ml={1}>
                  {showConnectivityType && (
                    <Box mb={2}>
                      <ToggleButtonGroup label="Group By" selectedValue={availableInterfaceGroupBy}>
                        <Button
                          text="Device"
                          value="device.device_name"
                          onClick={() => this.onGroupByChange('device.device_name')}
                          small
                        />
                        <Button
                          text="Connectivity Type"
                          value="connectivity_type"
                          onClick={() => this.onGroupByChange('connectivity_type')}
                          small
                        />
                        <Button
                          text="Network Boundary"
                          value="network_boundary"
                          onClick={() => this.onGroupByChange('network_boundary')}
                          small
                        />
                      </ToggleButtonGroup>
                    </Box>
                  )}
                  {!staticOnly && (
                    <Flex justifyContent="flex-end" mb={1}>
                      <Button
                        text="Create Dynamic Group"
                        icon={<Icon color="primary" icon="layers" />}
                        onClick={this.selectAllInterfacesDynamic}
                        disabled={!this.hasFilters || this.isDupeFilterGroup}
                      />
                    </Flex>
                  )}
                  <Card flex={1} display="flex" flexDirection="column" overflow="hidden">
                    <VirtualizedTable
                      hideHeader
                      collection={interfaceCollection}
                      selectOnRowClick={false}
                      columns={this.getInterfaceTableColumns()}
                      emptyState={
                        <Box mt={3}>
                          <EmptyState
                            title="No results"
                            description="No interfaces were found matching your applied filters"
                            icon={<Icon icon={AiOutlineSearch} iconSize={22} />}
                          />
                        </Box>
                      }
                      rowHeight={({ model }) => {
                        if (model?.isGroupSummary) {
                          return 38;
                        }

                        return model && model.get('snmp_alias') ? 59 : 45;
                      }}
                      groupSummaryLookup={this.groupSummaryLookup}
                      flexed
                    />
                  </Card>
                  <Text muted small mt={1} lineHeight="12px" mb="4px" textAlign="right">
                    <strong>{collectionSize}</strong> of <strong>{totalCount}</strong>{' '}
                    {this.hasFilters ? 'matching' : ''} interfaces shown
                  </Text>
                </Flex>
              </Flex>

              {!isInterfacePolicySelector && (
                <Card display="flex" flexDirection="column" maxWidth={375} minWidth={375} ml={2} overflow="hidden">
                  <SelectedFiltersSummary
                    filterGroups={selectedFilterGroups}
                    staticInterfaces={staticInterfaces}
                    onRemoveGroup={this.handleRemoveGroup}
                    onRemoveInterface={this.handleRemoveInterface}
                    emptyText={emptyText}
                    staticInterfacesTitle={staticInterfacesTitle}
                    staticOnly={staticOnly}
                  />
                </Card>
              )}
            </Flex>
          </FlexColumn>
        </Dialog.Body>
        <Dialog.Footer>
          <Button onClick={onClose} text="Cancel" minWidth={125} />
          <Button
            onClick={this.handleSave}
            text="Save"
            intent="primary"
            minWidth={125}
            ml={1}
            fontWeight="medium"
            disabled={exceedsInterfaceLimit}
          />
        </Dialog.Footer>
      </Dialog>
    );
  }
}

export default InterfaceSelectorDialog;
