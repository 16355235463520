import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { groupBy } from 'lodash';

import { Grid, MenuItem, Menu, MenuDivider } from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import { AGGREGATE_VIEWS } from 'app/views/core';

@storeLoader('$dashboards')
@withRouter
@observer
class CoreJumpMenu extends Component {
  groupedQuickViews = groupBy(AGGREGATE_VIEWS, 'group');

  handleSelect = (path) => {
    const { history } = this.props;
    history.push(path);
  };

  get hostMonitoringMenu() {
    const { $dashboards, location } = this.props;
    const dnsDash = $dashboards.collection.models.find((dash) => dash.get('dash_title') === 'DNS Analytics');
    const tcpPath = '/v4/core/quick-views/tcp-traffic';
    let dnsPath = '/v4/core/quick-views/dns-traffic';

    // because our1 has broken data
    if (dnsDash) {
      dnsPath = `${dnsPath}/${dnsDash.slugName}`;
    }

    return (
      <Menu>
        <MenuDivider m={0} mb="4px" title="Host Monitoring" />
        <MenuItem
          text="TCP Traffic"
          active={location.pathname === tcpPath}
          color={location.pathname.startsWith(tcpPath) ? 'primary' : undefined}
          fontWeight={location.pathname.startsWith(tcpPath) ? 'bold' : undefined}
          onClick={() => this.handleSelect(tcpPath)}
        />
        <MenuItem
          text="DNS Traffic"
          active={location.pathname === dnsPath}
          color={location.pathname.startsWith(dnsPath) ? 'primary' : undefined}
          fontWeight={location.pathname.startsWith(dnsPath) ? 'bold' : undefined}
          onClick={() => this.handleSelect(dnsPath)}
        />
      </Menu>
    );
  }

  renderCategory = (items, category) => {
    const { location } = this.props;
    return (
      <Menu p={0}>
        <MenuDivider m={0} mb="4px" title={category} />
        {(items[category] || []).map(({ path, name }) => (
          <MenuItem
            key={path}
            text={name}
            active={location.pathname === path}
            color={location.pathname.startsWith(path) ? 'primary' : undefined}
            fontWeight={location.pathname.startsWith(path) ? 'bold' : undefined}
            onClick={() => this.handleSelect(path)}
          />
        ))}
      </Menu>
    );
  };

  render() {
    return (
      <Grid
        gridTemplateColumns="repeat(auto-fit,minmax(150px,1fr))"
        maxWidth={600}
        overflow="auto"
        p="12px"
        maxHeight={800}
      >
        {this.renderCategory(this.groupedQuickViews, 'Network & Traffic')}
        {this.renderCategory(this.groupedQuickViews, 'IP & BGP Routing')}
        {this.renderCategory(this.groupedQuickViews, 'Geographic')}
        {this.hostMonitoringMenu}
        {this.renderCategory(this.groupedQuickViews, 'Application Context')}
        {this.renderCategory(this.groupedQuickViews, 'Cloud')}
      </Grid>
    );
  }
}

export default CoreJumpMenu;
