import React from 'react';

import { Paragraph, Tag } from 'core/components';

const PanelTitleHelpText = ({ parametric }) => {
  if (!parametric) {
    return null;
  }

  return (
    <div>
      <p>
        Note: For a title that reflects current Guided mode settings, insert{' '}
        <Tag minimal monospace small>
          {'{{filter_dimension}}'}
        </Tag>{' '}
        and{' '}
        <Tag minimal monospace small>
          {'{{filter_value}}'}
        </Tag>{' '}
        as placeholders.
      </p>
      <Paragraph mb={0}>
        Ex: To show <strong>&quot;Top Talkers: Dest Port 443&quot;</strong>, enter{' '}
        {'"Top Talkers: {{filter_name}} {{filter_value}}"'}
      </Paragraph>
    </div>
  );
};

export default PanelTitleHelpText;
