import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, FlexColumn, Button, Spinner } from 'core/components';
import { Field, Form, TextArea } from 'core/form/components';
import storeLoader from 'app/stores/storeLoader';

const fields = {
  comment: {
    name: 'comment',
    label: 'Comment',
    defaultValue: ''
  }
};

@storeLoader('$comments')
@inject('$auth')
@Form({ fields, options: { name: 'AddEditComment' } })
@observer
export default class AddEditComment extends Component {
  state = {
    saving: false
  };

  componentDidMount() {
    const { form, initialValue } = this.props;

    if (initialValue) {
      form.setValue('comment', initialValue);
    }
  }

  handleCancelClick = () => {
    const { form, onCancel } = this.props;

    form.setValue('comment', '');

    if (onCancel) {
      onCancel();
    }
  };

  handleSave = ({ comment }) => {
    const { $auth, form, onComment, entityType, commentModel: editModel, entityId, $comments } = this.props;

    this.setState({
      saving: true
    });

    // if it's edit, we update an existing model
    if (editModel) {
      editModel
        .save({ comment })
        .then((savedComment) => {
          editModel.set(savedComment);
          form.setValue('comment', '');

          onComment(editModel);
        })
        .finally(() => {
          this.setState({
            saving: false
          });
        });
    } else {
      // otherwise, we're creating a new one
      $comments
        .saveNewComment({
          comment,
          entityType,
          entityId,
          activeUser: $auth.activeUser
        })
        .then((commentModel) => {
          form.setValue('comment', '');

          if (onComment) {
            onComment(commentModel);
          }
        })
        .finally(() => {
          this.setState({
            saving: false
          });
        });
    }
  };

  render() {
    const { saving } = this.state;
    const { form, editMode, large = true } = this.props;
    const commentValue = form.getValue('comment');
    const commentIsEmpty = commentValue.trim() === '';

    return (
      <FlexColumn style={{ position: 'relative' }} gap={1}>
        <Field name="comment" large={large} showLabel={false} placeholder="Add a comment..." mb={0}>
          <TextArea fill rows={large ? 1 : 2} autoFocus autoGrow style={{ maxHeight: '400px' }} />
        </Field>
        {!editMode && (
          <Button
            intent="primary"
            icon="upload"
            disabled={commentIsEmpty}
            minimal
            style={{ position: 'absolute', right: '3px', bottom: '3px' }}
            onClick={() => this.handleSave(form.getValues())}
          />
        )}
        {editMode && (
          <Flex justifyContent="flex-end" gap={1} mb="4px">
            <Button small disabled={saving} text="Cancel" onClick={this.handleCancelClick} />
            <Button
              small
              minWidth={120}
              disabled={!form.getValue('comment') || form.getValue('comment').length < 3 || saving}
              onClick={() => this.handleSave(form.getValues())}
              text={saving ? <Spinner size={24} /> : 'Save'}
              fontWeight="bold"
              intent="primary"
            />
          </Flex>
        )}
      </FlexColumn>
    );
  }
}
