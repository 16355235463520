import React, { Component } from 'react';
import styled from 'styled-components';
import { RadioGroup as BlueprintRadioGroup, Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import Text from 'core/components/Text';
import EmptyState from 'core/components/EmptyState';

import omitFieldProps from './util/omitFieldProps';

const StyledRadioGroup = styled(BlueprintRadioGroup)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.${Classes.INLINE} {
    flex-direction: row;

    .${Classes.CONTROL} {
      margin-right: 8px;
      margin-bottom: 0;
    }
  }

  > .${Classes.CONTROL} {
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &.${Classes.SMALL} > .${Classes.CONTROL} {
    font-size: 12px;
    margin-bottom: 2px;

    .${Classes.CONTROL_INDICATOR} {
      width: 14px;
      height: 14px;
      margin: 1px 4px 0 1px;

      &:before {
        width: 14px;
        height: 14px;
      }
    }
  }

  &.${Classes.LARGE} > .${Classes.CONTROL} {
    font-size: 14px;
    min-height: 18px;
    margin-bottom: 6px;

    .${Classes.CONTROL_INDICATOR} {
      width: 14px;
      height: 14px;
      margin: 1px 6px 0 1px;

      &:before {
        width: 14px;
        height: 14px;
      }
    }

    > span:last-child {
      line-height: 16px;
    }
  }
`;

export default class RadioGroup extends Component {
  handleChange = (evt) => {
    const { field, onChange, options = field.options, readOnly } = this.props;
    const { value } = evt.target;
    const option = options && options.find((o) => String(o.value) === value); // eslint-disable-line eqeqeq

    if (!readOnly) {
      onChange(option ? option.value : value);
    }
  };

  renderItem = ({ label, helpText, value, ...rest }) => {
    const { itemRenderer } = this.props;

    if (itemRenderer) {
      return itemRenderer({ label, helpText, value, ...rest });
    }

    return (
      <span key={label}>
        {label}
        {helpText && (
          <Text as="div" mb={1} muted>
            {helpText}
          </Text>
        )}
      </span>
    );
  };

  render() {
    const { children, field, options: optionsProp, small, large, inline, name, readOnly, value, ...props } = this.props;

    const radioGroupProps = {
      ...omitFieldProps(props),
      className: classNames({ [Classes.SMALL]: small, [Classes.INLINE]: inline, [Classes.LARGE]: large }),
      onChange: this.handleChange,
      selectedValue: field ? field.value : value
    };

    if (children) {
      return <StyledRadioGroup {...radioGroupProps}>{children}</StyledRadioGroup>;
    }

    const options = optionsProp || field.options;

    if (!options.length) {
      return <EmptyState description="There are no available options" />;
    }

    return (
      <StyledRadioGroup
        {...radioGroupProps}
        options={options.map(({ value: optionValue, disabled, ...rest }) => ({
          label: this.renderItem({ value, ...rest }),
          value: optionValue,
          disabled
        }))}
      />
    );
  }
}
