import AWSDependency from 'app/components/dataviews/dependencies/AWSDependency';
import GCEDependency from 'app/components/dataviews/dependencies/GCEDependency';
import OCIDependency from 'app/components/dataviews/dependencies/OCIDependency';
import SiteDependency from 'app/components/dataviews/dependencies/SiteDependency';
import SnmpDependency from 'app/components/dataviews/dependencies/SnmpDepdendency';
import AzureDependency from 'app/components/dataviews/dependencies/AzureDependency';
import CloudDependency from 'app/components/dataviews/dependencies/CloudDependency';
import KProbeDependency from 'app/components/dataviews/dependencies/KProbeDependency';
import SiteCountryDependency from 'app/components/dataviews/dependencies/SiteCountryDependency';
import NetworkClassDependency from 'app/components/dataviews/dependencies/NetworkClassDependency';
import ProviderClassDependency from 'app/components/dataviews/dependencies/ProviderClassDependency';
import InterfaceClassDependency from 'app/components/dataviews/dependencies/InterfaceClassDependency';
import MatchingDevicesDependency from 'app/components/dataviews/dependencies/MatchingDevicesDependency';

const preventDependencies = [
  AWSDependency,
  OCIDependency,
  GCEDependency,
  AzureDependency,
  CloudDependency,
  KProbeDependency,
  MatchingDevicesDependency
];

const nonPreventDependencies = [
  InterfaceClassDependency,
  NetworkClassDependency,
  ProviderClassDependency,
  SiteDependency,
  SiteCountryDependency,
  SnmpDependency
];

export function checkPreventQuery(query, dataview) {
  dataview.preventQuery = preventDependencies.some((dependency) => !dependency.queryMeets(query));
}

export function getWarnings(query) {
  return nonPreventDependencies.filter((dependency) => !dependency.queryMeets(query));
}
