import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Flex, Box, Card, Text, Heading, Icon, Link, Popover, Button } from 'core/components';
import { FiMoreVertical } from 'react-icons/fi';
import ShareViewDialog from 'app/views/core/ShareViewDialog';
import SubscribeDialog from 'app/views/settings/subscriptions/SubscribeDialog';
import CapacityPlanMenu from './CapacityPlanMenu';
import PlanInterfaceSeverity from './PlanInterfaceSeverity';

@inject('$app', '$auth', '$subscriptions')
@withRouter
export default class PlanOverviewCard extends Component {
  state = {
    shareDialogOpen: false,
    subscribeDialogOpen: false,
    unsubscribeDialogOpen: false,
    isSubscribed: undefined,
    selectedModel: undefined
  };

  toggleShareDialog = (dialogType) => {
    const { $subscriptions, plan } = this.props;
    const attributes = { content_type: 'capacityplans', content_metadata: { values: [plan.id] } };

    const dialogToOpen = {
      share: 'shareDialogOpen',
      subscribe: 'subscribeDialogOpen',
      unsubscribe: 'unsubscribeDialogOpen'
    }[dialogType];

    this.setState(() => ({
      [dialogToOpen]: true,
      selectedModel: $subscriptions.collection.forge(attributes, { select: false })
    }));
  };

  onShareDialogClose = () => {
    this.setState({
      shareDialogOpen: false,
      subscribeDialogOpen: false,
      unsubscribeDialogOpen: false,
      selectedModel: undefined
    });
    this.setSubscriptionStatus();
  };

  setSelectedModel(selectedModel) {
    this.setState({ selectedModel });
  }

  setSubscriptionStatus = () => {
    const { $auth, $subscriptions, plan } = this.props;

    $subscriptions
      .isUserSubscribedToContent($auth.activeUser.user_email, 'capacityplans', plan.id)
      .then((isSubscribed) => {
        this.setState({ isSubscribed });
      });
  };

  handleClickEdit = () => {
    const { history, plan } = this.props;
    history.push(`/v4/core/capacity/${plan.id}/edit`);
  };

  render() {
    const { shareDialogOpen, subscribeDialogOpen, unsubscribeDialogOpen, isSubscribed, selectedModel } = this.state;
    const { $app, plan } = this.props;
    const { id, planSeverity } = plan;
    const description = plan.get('description');
    const name = plan.get('name');

    return (
      <Card
        display="flex"
        maxWidth="640px"
        flexDirection="column"
        alignItems="stretch"
        p={3}
        className={`avoid-break capacity-overview-card plan-${id}`}
        overflow="hidden"
      >
        <Flex justifyContent="space-between" pb={1} flex={1}>
          <Box>
            <Flex alignItems="start" mt="4px">
              <Icon icon={planSeverity.icon} color={planSeverity.color} mr={1} my="1px" />
              <Heading level={5} mb={0} fontWeight="heavy">
                <Link to={`/v4/core/capacity/${id}`}>{name}</Link>
              </Heading>
            </Flex>
            <Text small as="div" mt={1} pb={3}>
              {description}
            </Text>
          </Box>
          {!$app.isExport && (
            <Popover
              position="bottom-right"
              ml="4px"
              content={
                <CapacityPlanMenu
                  plan={plan}
                  toggleShareDialog={(dialogType) => this.toggleShareDialog(dialogType)}
                  setSubscriptionStatus={() => this.setSubscriptionStatus()}
                  isSubscribed={isSubscribed}
                />
              }
            >
              <Button icon={FiMoreVertical} textAlign="left" minimal />
            </Popover>
          )}
        </Flex>

        <PlanInterfaceSeverity plan={plan} small />

        <ShareViewDialog
          isOpen={shareDialogOpen}
          model={selectedModel}
          setModel={(model) => this.setSelectedModel(model)}
          onClose={this.onShareDialogClose}
          legacyLinkShare
          privateShareUrl={`/v4/core/capacity/${id}`}
        />
        <SubscribeDialog
          isOpen={subscribeDialogOpen}
          model={selectedModel}
          setModel={(model) => this.setSelectedModel(model)}
          onClose={this.onShareDialogClose}
          subscriptionDialogType="subscribe"
        />
        <SubscribeDialog
          isOpen={unsubscribeDialogOpen}
          model={selectedModel}
          setModel={(model) => this.setSelectedModel(model)}
          onClose={this.onShareDialogClose}
          subscriptionDialogType="unsubscribe"
        />
      </Card>
    );
  }
}
