import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ReconQueryWidget from './ReconQueryWidget';

const defaultWidgetTitle = 'Interfaces with Highest Utilization';

const QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/interfaces/counters',
    dimensions: ['device_name', 'name'],
    metrics: [
      {
        name: 'in-utilization',
        type: 'gauge'
      },
      {
        name: 'out-utilization',
        type: 'gauge'
      }
    ],
    range: {
      lookback: 'PT1800S'
    },
    window: {
      size: 0,
      fn: {
        'in-utilization': 'last',
        'out-utilization': 'last'
      }
    },
    rollups: {
      last_IfInUtilization: {
        metric: 'in-utilization',
        aggregate: 'last'
      },
      last_IfOutUtilization: {
        metric: 'out-utilization',
        aggregate: 'last'
      }
    },
    sort: [
      {
        name: 'last_IfInUtilization',
        direction: 'desc'
      },
      {
        name: 'last_IfOutUtilization',
        direction: 'desc'
      }
    ],
    limit: 10,
    includeTimeseries: 10,
    viz: {
      type: 'table',
      rollup: '',
      metric: ''
    },
    filters: {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          filters: [
            {
              filterField: 'logical',
              metric: '',
              aggregate: '',
              operator: '=',
              filterValue: 'false'
            }
          ],
          saved_filters: [],
          filterGroups: []
        }
      ]
    }
  }
};

@observer
class ReconInterfaceUtilizationWidget extends Component {
  static defaultProps = {
    config: {
      query: QUERY
    }
  };

  render() {
    return (
      <ReconQueryWidget
        {...this.props}
        defaultWidgetTitle={defaultWidgetTitle}
        showDefaultOptions={false}
        hideTotalRow
        showSparklines
      />
    );
  }
}

export default ReconInterfaceUtilizationWidget;
