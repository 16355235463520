import React, { Component } from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Box, Label, Text } from 'core/components';
import { formConsumer, Field, Switch, FormGroup, Select, Slider } from 'core/form';
import StandaloneDimensionSelector from 'app/components/dimensions/StandaloneDimensionSelector';

@inject('$dataviews', '$devices', '$dictionary', '$explorer', '$auth')
@formConsumer
@observer
export default class VisualizationOptions extends Component {
  state = {
    dimensionSelectorOpen: false
  };

  matrixBy = (matrixBy) => {
    // Note: we don't need to change the state because the cmp is destroyed from the click event
    // suppress fetch if matrixBy has no length; invalid query anyway so we just leave the form in error state
    const { dataview } = this.props;
    dataview.applyToAllQueries({ matrixBy }, matrixBy.length === 0);
  };

  hideMatrixBySelect = () => {
    this.setState({ dimensionSelectorOpen: false });
  };

  handleViewTypeChange = action((field, newViewType) => {
    const { dataview, $dataviews, $explorer, form, handleSubmit } = this.props;
    const oldViewType = dataview.viewType;
    const newConfig = $dataviews.getConfig(newViewType);

    if (form.getValue('use_log_axis') && !newConfig.supportsLogAxis) {
      // disable log scale that is not supported by the new view type
      this.handleToggleUseLogAxis(form.getField('use_log_axis'), false);
    }

    if (newViewType === 'matrix') {
      dataview.eachBucket('applyToEachQuery', [
        { viz_type: newViewType, show_total_overlay: false, show_overlay: false, generatorMode: false }
      ]);
      this.setState({ dimensionSelectorOpen: true });
    } else {
      dataview.viewType = newViewType;
    }

    if ($dataviews.isViewTypeCompatible(oldViewType, newViewType, dataview.queryBuckets.selectedQuery)) {
      handleSubmit(true);
    } else {
      $explorer.onFormChange({ form, formValues: form.getValues(), field: 'viz_type' });
    }
  });

  handleToggleSyncAxes = (field, value) => {
    const { dataview } = this.props;
    const sync_axes = value;
    dataview.component.syncAxes(sync_axes);
    dataview.queryBuckets.selectedQuery.set({ sync_axes });
    dataview.saveAndReinitialize(true);
    field.init(sync_axes);
  };

  handleToggleUseLogAxis = (field, value) => {
    const { dataview } = this.props;
    const use_log_axis = value;
    dataview.component.useLogAxis(use_log_axis);
    dataview.queryBuckets.selectedQuery.set({ use_log_axis });
    dataview.saveAndReinitialize(true);
    field.init(use_log_axis);
  };

  handleToggleSyncAllAxes = (field, value) => {
    const { dataview } = this.props;
    const sync_all_axes = value;
    dataview.component.syncAllAxes(sync_all_axes);
    dataview.queryBuckets.selectedQuery.set({ sync_all_axes });
    dataview.saveAndReinitialize(true);
    field.init(sync_all_axes);
  };

  handleToggleUseSecondaryLogAxis = (field, value) => {
    const { dataview } = this.props;
    const use_secondary_log_axis = value;
    dataview.component.useSecondaryLogAxis(use_secondary_log_axis);
    dataview.queryBuckets.selectedQuery.set({ use_secondary_log_axis });
    dataview.saveAndReinitialize(true);
    field.init(use_secondary_log_axis);
  };

  handleToggleSyncExtents = (field, value) => {
    const { dataview } = this.props;
    const sync_extents = value;
    dataview.component.syncExtents(sync_extents);
    dataview.queryBuckets.selectedQuery.set({ sync_extents });
    dataview.saveAndReinitialize(true);
    field.init(sync_extents);
  };

  handleShowSiteMarkers = (field, value) => {
    const { dataview } = this.props;
    const show_site_markers = value;
    if (dataview.viewType === 'geoHeatMap') {
      dataview.component.showSiteMarkers(show_site_markers);
      dataview.queryBuckets.selectedQuery.set({ show_site_markers });
      dataview.saveAndReinitialize(true);
      field.init(show_site_markers);
    }
  };

  render() {
    const {
      $dataviews,
      $devices,
      $dictionary,
      showSyncAxes,
      showUseLogAxis,
      showSyncExtents,
      showSiteMarkers,
      dataview,
      form
    } = this.props;
    const { dimensionSelectorOpen } = this.state;
    const { selectedQuery, activeBucketCount } = dataview.queryBuckets;
    const period_over_period = selectedQuery.get('period_over_period');

    const displaySyncAxes =
      selectedQuery &&
      showSyncAxes &&
      ((!period_over_period && activeBucketCount > 1) ||
        selectedQuery.get('secondaryOutsort') ||
        selectedQuery.get('mirror'));
    const loading = dataview.loading || !dataview.lastUpdated;

    let viz_type = dataview.viewType;
    let disabledDimensions;
    let matrixField;

    if (dataview.formState) {
      matrixField = dataview.formState.getField('matrixBy');
      viz_type = dataview.formState.getValue('viz_type');
      const device_name = dataview.formState.getValue('device_name');
      const device_labels = dataview.formState.getValue('device_labels');
      const device_sites = dataview.formState.getValue('device_sites');
      const device_types = dataview.formState.getValue('device_types');
      const all_devices = dataview.formState.getValue('all_devices');

      // eslint-disable-next-line no-unused-vars
      const hasKProbeSelected = all_devices
        ? $devices.hasDnsProbe
        : $devices.containsDnsProbe({ device_name, device_labels, device_sites, device_types });
      disabledDimensions = !hasKProbeSelected ? $dictionary.dictionary.kprobeDimensions : [];
    } else {
      disabledDimensions = [];
    }

    const isAggTotal = form.getValue('outsort').includes('agg_total');
    let topxMax = 40;
    if (viz_type === 'table') {
      if (isAggTotal) {
        topxMax = 50000;
      } else {
        topxMax = 350;
      }
    }
    const vizOptions = $dataviews.viewTypeOptions
      .filter((option) => !option.hideFromViewMenu)
      .map((option) => ({ ...option, disabled: period_over_period && !option.supportsPeriodOverPeriod }));

    return (
      <>
        <Field name="viz_type" onChange={this.handleViewTypeChange} options={vizOptions}>
          <Select fill />
        </Field>

        {(displaySyncAxes || showUseLogAxis || showSyncExtents || showSiteMarkers) && (
          <Label fontWeight={600} fontSize={12}>
            {$dataviews.getViewType(viz_type).label} Options
          </Label>
        )}
        {displaySyncAxes && (
          <Field name="sync_axes" disabed={loading} onChange={this.handleToggleSyncAxes} small mb={1}>
            <Switch switchLabel="Sync Axes" />
          </Field>
        )}
        {showSyncAxes && selectedQuery.get('generatorMode') && (
          <Field name="sync_all_axes" disabed={loading} onChange={this.handleToggleSyncAllAxes} small mb={1}>
            <Switch switchLabel="Sync Axes Across Panels" />
          </Field>
        )}
        {showUseLogAxis && (
          <Field name="use_log_axis" disabled={loading} onChange={this.handleToggleUseLogAxis} small mb={1}>
            <Switch switchLabel={`Use Logarithmic Scale${displaySyncAxes ? ' on Primary Axis' : ''}`} />
          </Field>
        )}
        {displaySyncAxes && showUseLogAxis && (
          <Field
            name="use_secondary_log_axis"
            disabed={loading}
            onChange={this.handleToggleUseSecondaryLogAxis}
            small
            mb={1}
          >
            <Switch switchLabel="Use Logarithmic Scale on Secondary Axis" />
          </Field>
        )}
        {showSyncExtents && (
          <Field name="sync_extents" disabed={loading} onChange={this.handleToggleSyncExtents} small mb={1}>
            <Switch switchLabel="Sync Density Scale Across Series" />
          </Field>
        )}
        {showSiteMarkers && !selectedQuery.get('generatorMode') && (
          <Field name="show_site_markers" disabed={loading} onChange={this.handleShowSiteMarkers} small mb={1}>
            <Switch switchLabel="Show Site Markers" />
          </Field>
        )}
        <Field name="topx" onChange={this.handleVizDepthChange} className={`render-topx-max-change-${topxMax}`}>
          {({ field, name, ...rest }) => (
            <FormGroup labelFor={`field-${name}`} {...rest} label={<Text small>{field.label}</Text>} mt={2} mb={0}>
              <Box px="12px">
                <Slider
                  {...rest}
                  min={1}
                  max={topxMax}
                  stepSize={1}
                  labelStepSize={topxMax - 1}
                  labelRenderer={this.visualizationDepthLabel}
                />
              </Box>
            </FormGroup>
          )}
        </Field>
        {dimensionSelectorOpen && (
          <StandaloneDimensionSelector
            title="Matrix By Dimensions"
            isOpen={dimensionSelectorOpen}
            onSave={this.matrixBy}
            onClose={this.hideMatrixBySelect}
            saveButtonText="Matrix By Selected Dimensions"
            multi
            field={matrixField}
            disabledValues={disabledDimensions}
            showLabel={false}
          />
        )}
      </>
    );
  }
}
