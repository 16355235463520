import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { InputGroup } from '@blueprintjs/core';

import { Box, Button, Callout, Card, Dialog, Flex, Heading, LinkButton, Text } from 'core/components';
import ShortcutGroup from 'app/components/device/ShortcutGroup';
import { getDeviceTypeOptions, getLabelOptionsForDeviceSelector } from 'app/util/devices';
import SelectedValueButton from 'app/components/device/SelectedValueButton';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$sites')
@inject('$devices')
@observer
class DeviceSelectorDialog extends Component {
  static defaultProps = {
    dialogTitle: 'Data Sources',
    allDevicesLabel: 'Use all data sources',
    deviceFilter: () => true
  };

  state = {
    searchInputValue: ''
  };

  handleSearchInputChange = (e) => {
    this.setState({ searchInputValue: e.target.value });
  };

  handleToggleAllDevices = () => {
    const { allDevicesFieldName, onChange, selectedValues } = this.props;
    onChange(!selectedValues.get('all'), allDevicesFieldName);
  };

  renderDialogTitle() {
    const { dialogTitle } = this.props;
    const { searchInputValue } = this.state;

    return (
      <Flex justifyContent="space-between" alignItems="center" py="3px">
        <Box flex={1}>{dialogTitle}</Box>
        <Flex justifyContent="flex-end" py="3px" pr="6px">
          <InputGroup
            autoFocus
            leftIcon="search"
            placeholder="Search..."
            value={searchInputValue}
            onChange={this.handleSearchInputChange}
            type="search"
          />
        </Flex>
      </Flex>
    );
  }

  @computed
  get noneSelected() {
    const { selectedValues } = this.props;

    return (
      selectedValues.get('labels').length === 0 &&
      selectedValues.get('sites').length === 0 &&
      selectedValues.get('types').length === 0 &&
      selectedValues.get('devices').length === 0
    );
  }

  render() {
    const {
      $devices,
      $sites,
      allowAllDevices,
      allDevicesLabel,
      devicesLabel,
      isOpen,
      onAdd,
      onAddAll,
      onCancel,
      onChange,
      onRemove,
      onSave,
      required,
      selectedValues,
      showLabels,
      showSites,
      showTypes,
      deviceFilter,
      allDevicesFieldName,
      allowOnlyActiveDeviceAttributes
    } = this.props;

    const all_devices = selectedValues.get('all');
    const device_types = selectedValues.get('types');
    const device_labels = selectedValues.get('labels');
    const device_sites = selectedValues.get('sites');
    const device_name = selectedValues.get('devices');

    const { searchInputValue } = this.state;

    const allDevicesCount = $devices.activeDeviceSummaries.length;

    const labelOptions = getLabelOptionsForDeviceSelector(allowOnlyActiveDeviceAttributes);
    const siteOptions = $sites.siteOptions.filter((opt) => opt.activeDevices.length > 0);

    const selectedDevices = !all_devices
      ? $devices.getUniqueSelectedDevices({
          device_types,
          device_labels,
          device_sites,
          device_name,
          activeDevicesOnly: allowOnlyActiveDeviceAttributes
        })
      : $devices.activeDeviceSummaries;

    const selectedDeviceNames = selectedDevices.map((d) => d.device_name);

    const hasLabels = showLabels && labelOptions.length > 0;
    const showSiteSelection = showSites && siteOptions.length > 0;

    // if a label / site / type has selected a device, that device should be disabled (or hidden?) from selection individually
    const deviceNameOptions = $devices.deviceSummaryOptions.filter(
      (opt) => deviceFilter(opt) && !selectedDeviceNames.includes(opt.label)
    );

    return (
      <Dialog
        isOpen={isOpen}
        title={this.renderDialogTitle()}
        onClose={onCancel}
        width="auto"
        height={550}
        top={50}
        canEscapeKeyClose
      >
        <Dialog.Body p={0} overflow="hidden" display="flex">
          <Card display="flex" overflow="hidden" flexDirection="column" width={276} borderRadius={0}>
            <Box flex={1} p={1} overflow="auto">
              {allowAllDevices && (
                <Button
                  minimal
                  fill
                  textAlign="left"
                  active={all_devices}
                  mb={2}
                  onClick={this.handleToggleAllDevices}
                  icon={all_devices ? 'small-tick' : 'multi-select'}
                  text={allDevicesLabel}
                  rightIcon={
                    <Text small muted style={{ width: 'auto' }}>
                      {allDevicesCount}
                    </Text>
                  }
                />
              )}

              {showTypes && (
                <ShortcutGroup
                  searchInputValue={searchInputValue}
                  disabled={all_devices}
                  expandable={false}
                  filterable={false}
                  field="device_types"
                  onAdd={onAdd}
                  onAddAll={onAddAll}
                  onChange={onChange}
                  options={getDeviceTypeOptions()}
                  selectedValues={selectedValues.get('types')}
                  showClearButton={false}
                  title="Types"
                  type="deviceType"
                  sorted
                />
              )}

              {showLabels && (
                <ShortcutGroup
                  searchInputValue={searchInputValue}
                  disabled={all_devices}
                  field="device_labels"
                  onAdd={onAdd}
                  onAddAll={onAddAll}
                  onChange={onChange}
                  options={labelOptions}
                  selectedValues={selectedValues.get('labels')}
                  noResultsText={
                    !hasLabels ? (
                      <>
                        Labels enable dynamic grouping of your devices. <br />
                        <LinkButton to="/admin4/devices/labels" mt="6px" minimal intent="primary" blank>
                          Configure Labels
                        </LinkButton>
                      </>
                    ) : null
                  }
                  title="Labels"
                  type="label"
                />
              )}

              {showSiteSelection && (
                <ShortcutGroup
                  searchInputValue={searchInputValue}
                  disabled={all_devices}
                  field="device_sites"
                  onAdd={onAdd}
                  onAddAll={onAddAll}
                  onChange={onChange}
                  options={siteOptions}
                  selectedValues={selectedValues.get('sites')}
                  title="Sites"
                  type="site"
                />
              )}

              <ShortcutGroup
                searchInputValue={searchInputValue}
                disabled={all_devices}
                expandable={false}
                field="device_names"
                onAdd={onAdd}
                onAddAll={onAddAll}
                onChange={onChange}
                options={deviceNameOptions}
                selectedValues={selectedValues.get('devices')}
                title={`${devicesLabel} by name`}
                type="device"
                mb={0}
              />
            </Box>
          </Card>
          <Flex flexDirection="column" overflow="hidden" flex={1} width={475}>
            {required && this.noneSelected && !all_devices && (
              <Box p={2}>
                <Callout intent="danger">
                  <Heading level={5}>No {devicesLabel} selected</Heading>
                  Select at least 1 group or individual {devicesLabel} from the left pane.
                </Callout>
              </Box>
            )}
            <Box flex={1} overflow="auto" p={2} pb={1}>
              {all_devices && (
                <SelectedValueButton
                  label={allDevicesLabel}
                  iconName="multi-select"
                  devices={selectedValues.get('all')}
                  onRemove={() => onChange(false, allDevicesFieldName)}
                />
              )}

              {!all_devices && !this.noneSelected && (
                <>
                  {selectedValues.get('types').map((option) => (
                    <SelectedValueButton option={option} onRemove={onRemove} key={option.value} field="device_types" />
                  ))}
                  {selectedValues
                    .get('labels')
                    .map(
                      (option) =>
                        option && (
                          <SelectedValueButton
                            option={option}
                            icon="tag"
                            onRemove={onRemove}
                            key={option.value}
                            field="device_labels"
                          />
                        )
                    )}
                  {selectedValues
                    .get('sites')
                    .map(
                      (option) =>
                        option && (
                          <SelectedValueButton
                            icon="map-marker"
                            onRemove={onRemove}
                            key={option.value}
                            option={option}
                            field="device_sites"
                          />
                        )
                    )}

                  {selectedValues
                    .get('devices')
                    .map(
                      (device) =>
                        device && (
                          <SelectedValueButton
                            onRemove={onRemove}
                            key={device.value}
                            option={device}
                            field="device_names"
                            icon={device.icon}
                          />
                        )
                    )}
                </>
              )}
            </Box>
          </Flex>
        </Dialog.Body>
        <Dialog.Footer pt={2} mt={0} borderTop="thin">
          <Button text="Cancel" mr={1} width={110} onClick={onCancel} />
          <Button
            text="Save"
            disabled={this.noneSelected && !all_devices}
            onClick={onSave}
            intent="primary"
            width={110}
          />
        </Dialog.Footer>
      </Dialog>
    );
  }
}

export default DeviceSelectorDialog;
