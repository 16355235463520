export const isPathSharedLink = () => window.location.pathname.includes('/v4/shared/');

export const encodeQuery = (query) => {
  const encodedQuery = escape(JSON.stringify(query));
  return btoa(encodedQuery);
};

export const decodeQuery = (query) => {
  const decodedQuery = atob(query);
  return JSON.parse(unescape(decodedQuery));
};
