import { Box, Flex, Tag, Text } from 'core/components';
import KentikLogo from 'app/components/KentikLogo';
import React from 'react';

function ColorChip({ color }) {
  return <Box width={5} height={18} borderRadius="4px 0 0 4px" bg={color} />;
}

export function Label({ label, bg, name, shareLevel, isPreset = shareLevel === 'Preset', onRemove, testId }) {
  // if isPreset is true, we want to show the Kentik logo instead of the color chip
  // and adjust the left padding accordingly.
  const pl = isPreset ? '6px' : 0;
  const color = label?.get('color') || bg;
  const text = label?.get('name') || name;

  return (
    <Tag minimal pl={pl} py={0} minHeight={18} onRemove={onRemove} data-testid={testId}>
      <Flex alignItems="center" maxWidth={250} gap="4px">
        {isPreset && <KentikLogo onlyMark alt="Kentik" style={{ minWidth: 16, maxWidth: 16 }} />}
        {!isPreset && <ColorChip color={color} />}
        <Text maxWidth={250} ellipsis>
          {text}
        </Text>
      </Flex>
    </Tag>
  );
}
