import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Card, Collapse, Flex, Box, Text, Suspense } from 'core/components';
import { Field, Select } from 'core/form';

import FiltersDisplay from './display/FiltersDisplay';

@inject('$savedFilters')
@observer
class SavedFilter extends Component {
  state = {
    isOpen: false,
    model: null
  };

  componentDidMount() {
    this.getModel();
  }

  componentDidUpdate() {
    this.getModel();
  }

  getModel() {
    const { $savedFilters, filter_id } = this.props;
    const { model: currentModel } = this.state;
    const model = $savedFilters.getSavedFilterById(filter_id);
    if (model && model !== currentModel) {
      this.setState({ model }, () => model.loadFilters());
    }
  }

  handleToggleDetails = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    const { filter_id, filterIdx, is_not, onCustomize, onRemove, ...rest } = this.props;
    const { model: savedFilter, isOpen } = this.state;

    if (!savedFilter) {
      return (
        <Card p={1} {...rest}>
          <Flex justifyContent="space-between">
            <Flex alignItems="center" flex={1}>
              <Text as="div" color="danger">
                Invalid saved filter ({filter_id})
              </Text>
            </Flex>
            <Box>
              <Button
                icon="cross"
                onClick={() => onRemove(filterIdx)}
                ml="5px"
                mr="-3px"
                intent="danger"
                small
                minimal
              />
            </Box>
          </Flex>
        </Card>
      );
    }

    return (
      <Card p={1} {...rest}>
        <Flex justifyContent="space-between">
          <Flex alignItems="center" flex={1}>
            <Box mr="4px">
              <Button icon={isOpen ? 'caret-down' : 'caret-right'} onClick={this.handleToggleDetails} minimal small />
            </Box>
            <Box>
              <strong>{savedFilter.get('filter_name')}</strong>
              <div style={{ lineHeight: '12px' }}>
                <Text small muted>
                  {savedFilter.get('filter_description')}
                </Text>
              </div>
            </Box>
          </Flex>
          <Box>
            <Field field={is_not} showLabel={false} mb={0} small>
              <Select />
            </Field>
          </Box>
          <Box>
            <Button icon="edit" onClick={() => onCustomize(filterIdx)} text="Customize" small ml="5px" />
            <Button icon="cross" onClick={() => onRemove(filterIdx)} ml="5px" mr="-3px" intent="danger" small minimal />
          </Box>
        </Flex>
        <Collapse isOpen={isOpen}>
          <Suspense loading={savedFilter.isRequestActive()}>
            <Box pt={1}>
              <FiltersDisplay filters={savedFilter.get('filters')} />
            </Box>
          </Suspense>
        </Collapse>
      </Card>
    );
  }
}

export default SavedFilter;
