import React from 'react';

import $auth from 'app/stores/$auth';
import { Text, Box, LinkButton } from 'core/components';
import AbstractDependency from './AbstractDependency';

const CONSTRAINTS = {
  metrics: ['i_src_provider_classification', 'i_dst_provider_classification'],
  filterFields: ['i_src_provider_classification', 'i_dst_provider_classification']
};

class ProviderClassDependency extends AbstractDependency {
  get key() {
    return 'pc';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return !$auth.providerClassBelowThreshold;
  }

  get message() {
    return (
      <>
        <Box mb="4px">
          <Text small>
            <strong>Provider Classification</strong> is in use, but your organization currently has only{' '}
          </Text>
        </Box>

        <LinkButton icon="cog" small text="Configure" to="/v4/settings/network-classification" />
      </>
    );
  }
}

export default ProviderClassDependency;
