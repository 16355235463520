import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FiExternalLink } from 'react-icons/fi';
import { Box, Button, Flex, Icon, Link, Menu, MenuItem, Popover, Text } from 'core/components';
import { Search } from 'core/components/table';
import MultiSelectInput from 'core/components/select/MultiSelectInput';
import { Label } from 'app/components/labels/Label';
import LabelValueTagRenderer from './LabelValueTagRenderer';

function selectedAsArray(selected) {
  if (selected) {
    return Array.isArray(selected) ? selected : [selected];
  }
  return [];
}

const defaultValueRenderer = (option) => (
  <Flex justifyContent="flex-start" alignItems="center" className={option.className}>
    {option && <Text ellipsis>{option.label}</Text>}
  </Flex>
);

@inject('$labels')
@observer
class LabelMapping extends Component {
  // use state to track which MenuItem components are active
  // since we do not necessarily want to apply all labels to all models selected
  // this also allows a single mounted component to toggle active labels as props.collection changes
  state = {
    active: {},
    c_id: '',
    lastUpdated: '',
    selectedLen: 0,
    // track whether or not labels have been updated since the popover was opened
    itemsHaveBeenUpdated: false
  };

  static defaultProps = {
    showSelected: true
  };

  static getDerivedStateFromProps(props, state) {
    const { collection, $labels } = props;
    const selected = selectedAsArray(collection.selected);
    const selectedLen = selected.length;

    if (
      collection.id !== state.c_id ||
      $labels.labels.lastUpdated !== state.lastUpdated ||
      selectedLen !== state.selectedLen
    ) {
      // loop through selected models in a collection to get the labels for that model
      // and create an object of active labels across all those models
      // if no selectedLen, then set active to empty obj
      const active =
        selectedLen !== 0
          ? selected.reduce((ids, model) => {
              model.labels.forEach((label) => (ids[label.id] = true));
              return ids;
            }, {})
          : {};

      return {
        active,
        c_id: collection.id,
        lastUpdated: $labels.labels.lastUpdated,
        selectedLen
      };
    }

    return null;
  }

  isLabelActive = (label_id) => {
    const { active } = this.state;
    return active[label_id];
  };

  getSelectedModelIds = (options = {}) => {
    const { collection } = this.props;
    const { filter = () => true } = options;

    return selectedAsArray(collection.selected)
      .filter(filter)
      .map((model) => model.id || model.get('id')); // i.e. agent_id, test_id, policy.id;
  };

  handleUpdateLabels = (method, options = {}) => {
    const { $labels, onLabelChange, type } = this.props;
    const { label_id } = options;

    if (Array.isArray(type)) {
      const promises = type.map(({ value, filter }) => {
        const selectedModelIds = this.getSelectedModelIds({ filter });
        if (selectedModelIds.length === 0) {
          return Promise.resolve();
        }
        return $labels.updateItems(value, method, selectedModelIds, label_id ? [label_id] : undefined).then(() => {
          if (onLabelChange) {
            onLabelChange(value, method, selectedModelIds);
          }
        });
      });
      return Promise.all(promises).then(() => {
        this.setState({ itemsHaveBeenUpdated: true });
      });
    }

    return $labels.updateItems(type, method, this.getSelectedModelIds(), label_id ? [label_id] : undefined).then(() => {
      if (onLabelChange) {
        onLabelChange(type, method, this.getSelectedModelIds());
      }

      this.setState({ itemsHaveBeenUpdated: true });
    });
  };

  handleClearLabels = () => {
    this.handleUpdateLabels('DELETE');
  };

  handleLabelMenuSelect = (label) => {
    const { active } = this.state;
    const label_id = label.get('id');
    const nextActive = { ...active };

    let method;

    if (this.isLabelActive(label_id)) {
      method = 'DELETE';
      delete nextActive[label_id];
    } else {
      method = 'INSERT';
      nextActive[label_id] = true;
    }

    this.setState({ active: nextActive });

    this.handleUpdateLabels(method, { label_id });
  };

  handleOnClosed = () => {
    const { onPopoverClosed } = this.props;
    const { itemsHaveBeenUpdated } = this.state;

    if (onPopoverClosed) {
      onPopoverClosed(itemsHaveBeenUpdated);
    }

    this.setState({ itemsHaveBeenUpdated: false });
  };

  render() {
    const { $labels, collection, selectedStr, applyPopoverPosition, showSelected, small } = this.props;
    const { active } = this.state;
    // NOTE: we can NOT pull selectedLen here from this.state as we need render() to care that the collection
    // has changed in some way to force getDerivedStateFromProps to execute
    const selectedLen = selectedAsArray(collection.selected).length;
    const disabled = selectedLen === 0;
    const pluralizedSelectedStr = `${selectedStr}${selectedLen === 1 ? '' : 's'}`;

    return (
      <Flex justifyContent="space-between" alignItems="center">
        {showSelected && (
          <Text>
            {selectedLen} {pluralizedSelectedStr} selected
          </Text>
        )}
        <Flex flexWrap="nowrap" alignItems="center">
          <Link to="/v4/settings/labels" mr={1}>
            Add/Edit Labels
            <Icon icon={FiExternalLink} iconSize={12} color="inherit" pl="2px" pb="2px" />
          </Link>
          <Button
            disabled={disabled}
            icon="eraser"
            text="Clear Labels"
            mr={1}
            onClick={this.handleClearLabels}
            small={small}
          />
          <Popover
            disabled={disabled}
            position={applyPopoverPosition}
            onClosed={this.handleOnClosed}
            content={
              <Box maxHeight="42vh" overflow="auto">
                <Menu>
                  <Search
                    p="4px"
                    collection={$labels.companyLabels}
                    onChange={(e) => $labels.companyLabels.filter(e.target.value)}
                  />
                  {$labels.companyLabels.models.map((label) => (
                    <MenuItem
                      key={label.get('id')}
                      disabled={$labels.labels.loading}
                      active={this.isLabelActive(label.get('id'))}
                      shouldDismissPopover={false}
                      text={<Label label={label} />}
                      onClick={() => this.handleLabelMenuSelect(label)}
                    />
                  ))}
                </Menu>
              </Box>
            }
          >
            <MultiSelectInput
              disabled={disabled}
              options={$labels.companyLabels.map((label) => ({
                value: label.get('id'),
                label: label.get('name'),
                bg: label.get('color'),
                color: '#ffffff'
              }))}
              placeholder="Apply Labels"
              onUnselectBuilder={(labelId) => () => this.handleLabelMenuSelect($labels.labels.get(labelId))}
              values={Object.keys(active).map(Number)}
              valueRenderer={defaultValueRenderer}
              valueTagRenderer={LabelValueTagRenderer}
              style={{
                minWidth: 240,
                minHeight: small ? 24 : 30,
                maxHeight: small ? 24 : 30,
                padding: '0 4px'
              }}
            />
          </Popover>
        </Flex>
      </Flex>
    );
  }
}

export default LabelMapping;
