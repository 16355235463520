import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled, { withTheme } from 'styled-components';

import { Box, Flex, FlexColumn, Link } from 'core/components';
import DemoMenuOption from 'app/components/demo/DemoMenuOption';
import MenuSetupTasks from 'app/components/setup/MenuSetupTasks';

import ProductLabel from 'app/components/navbar/ProductLabel';
import $devices from 'app/stores/device/$devices';
import { DEFAULT_METRICS_EXPLORER_QUERY } from 'app/views/metrics/queries';
import MenuSectionBase from './MenuSection';
import NavbarTopTalkers from './NavbarTopTalkers';
import NavbarSettings from './NavbarSettings';
import RecentViewsMenuList from './RecentViewsMenuList';

export const MenuDivider = styled.div`
  max-width: 16px;
  border-left: ${({ theme }) => theme.borders.menuDivider};
  content: '&nbsp;';
`;

export const MenuSpacer = styled.div`
  flex: 1;
  flex-basis: 16px;
  min-width: 16px;
  max-width: 40px;
`;

export const MenuLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  margin-bottom: 16px;
`;

export const MenuSideSection = styled.h5`
  font-weight: ${({ theme }) => theme.fontWeights.heavy};
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.muted};
  margin: 0;
  padding-bottom: 4px;
  border-bottom: ${({ theme }) => theme.borders.thinDashed};
`;

const StyledBackdrop = styled.div`
  position: fixed;
  top: ${({ topOffset }) => topOffset}px;
  width: 100vw;
  height: calc(100vh - ${({ topOffset }) => topOffset}px);
  background: rgba(0, 0, 0, 0.7);
  z-index: 110;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
`;

const StyledMenuWrapper = styled.div`
  position: fixed;
  top: ${({ topOffset }) => topOffset}px;
  width: 100%;
  height: 450px;
  background: ${(props) => props.theme.colors.navbarMenuBackground};
  z-index: 120;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};

  // Hide new tags because there isn't room for them in < 1200px
  @media (max-width: 1100px) {
    .product-meta {
      &.new,
      &.updated {
        display: none;
      }
    }
  }
`;

function getMenuLinkCmp({ onClose, isDarkMode }) {
  return function MenuLink({ to, text, disabled, isUpdated, isBeta, isPreview, isNew, ...rest }) {
    const hasTag = isUpdated || isBeta || isNew;
    return (
      <Box as="li" mt={hasTag ? '8px' : '10px'} mb={hasTag ? '-2px' : '0'} fontWeight="normal" lineHeight="1.1">
        <Link to={to} disabled={disabled} onClick={onClose} color="navbarMenuLinkColor" {...rest}>
          {text}
        </Link>
        <ProductLabel
          isDarkMode={isDarkMode}
          isUpdated={isUpdated}
          isBeta={isBeta}
          isPreview={isPreview}
          isNew={isNew}
        />
      </Box>
    );
  };
}

function getMenuSectionCmp({ onClose, onMouseEnter, onMouseLeave, isDarkMode }) {
  return function MenuSection(props) {
    return (
      <MenuSectionBase
        {...props}
        onClick={onClose}
        isDarkMode={isDarkMode}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    );
  };
}

@withTheme
@inject('$auth', '$cost', '$syn', '$hybridMap', '$companySettings')
@observer
class NavbarMenu extends Component {
  state = {
    hoveringSection: null
  };

  handleMouseEnterMenuSection = (section) => {
    this.setState({ hoveringSection: section });
  };

  renderMenuContents = () => {
    const { hoveringSection } = this.state;
    const { $auth, $cost, $syn, $hybridMap, $companySettings } = this.props;
    const { presetsUiEnabled, bgpEnabled } = $syn;
    const hasFlow = $devices.hasReceivedFlow;

    const { MenuLink, MenuSection } = this.getMenuComponents();

    if (hoveringSection === 'Top Talkers') {
      return (
        <NavbarTopTalkers
          MenuLink={MenuLink}
          MenuSection={MenuSection}
          onCloseSection={() => this.handleMouseEnterMenuSection(null)}
          hasFlow={hasFlow}
        />
      );
    }

    if (hoveringSection === 'Settings') {
      return (
        <NavbarSettings
          MenuLink={MenuLink}
          MenuSection={MenuSection}
          onCloseSection={() => this.handleMouseEnterMenuSection(null)}
        />
      );
    }

    return (
      <>
        <FlexColumn py={3} flex={1} minWidth="min-content" flexBasis={180}>
          <MenuSection title="Core">
            <MenuLinks>
              <MenuLink text="Network Explorer" to="/v4/core" />
              <MenuLink text="Data Explorer" to="/v4/core/explorer" />
              <MenuLink text="Capacity Planning" to="/v4/core/capacity" />
              <MenuLink text="Insights" to="/v4/core/insights" />
              {hasFlow && $auth.hasPermission('rawFlow.forensicViewer') && (
                <MenuLink text="Forensic Raw Flow" to="/v4/core/forensic-raw-flow" />
              )}
              {hasFlow && $auth.hasPermission('rawFlow.viewer') && (
                <MenuLink text="Raw Flow Explorer" to="/v4/core/raw-flow" />
              )}
            </MenuLinks>
          </MenuSection>

          <MenuSection
            title="Synthetics"
            color="primary"
            to="/v4/synthetics/dashboard"
            whiteSpace="nowrap"
            showAnimatedArrow
          >
            <MenuLinks>
              <MenuLink text="Test Control Center" to="/v4/synthetics/tests" />
              <MenuLink text="Agent Management" to="/v4/synthetics/agents" />
              {bgpEnabled && <MenuLink text="BGP Route Viewer" to="/v4/synthetics/bgp" />}
              {presetsUiEnabled && <MenuLink text="State of the Internet" to="/v4/synthetics/state-of-the-internet" />}
            </MenuLinks>
          </MenuSection>
        </FlexColumn>

        <MenuSpacer />

        <Box py={3} flex={1} minWidth="min-content" flexBasis={180}>
          {$auth.hasPermission('recon.enabled', { overrideForSudo: false }) && (
            <MenuSection
              color="primary"
              title="Network Monitoring System"
              to="/v4/nms"
              whiteSpace="nowrap"
              showAnimatedArrow
            >
              <MenuLinks>
                <MenuLink
                  text="Metrics Explorer"
                  to="/v4/nms/explorer"
                  state={{ query: DEFAULT_METRICS_EXPLORER_QUERY }}
                />
                {$companySettings.enableKentikAi && <MenuLink text="Query Assistant" to="/v4/nms/nlq" />}
                <MenuLink text="Devices" to="/v4/infrastructure/devices?view=monitor" />
                <MenuLink text="Interfaces" to="/v4/nms/interfaces" />
              </MenuLinks>
            </MenuSection>
          )}

          <MenuSection title="Edge">
            <MenuLinks>
              <MenuLink text="Traffic Engineering" to="/v4/edge/traffic" />
              {$cost.canViewCosts && <MenuLink text="Connectivity Costs" to="/v4/edge/costs" />}
              {$cost.canViewCosts && $auth.hasPermission('trafficCosts.enabled', { overrideForSudo: false }) && (
                <MenuLink text="Traffic Costs" to="/v4/edge/traffic-costs" isBeta />
              )}
              <MenuLink text="Discover Peers" to="/v4/edge/peering" />
            </MenuLinks>
          </MenuSection>

          <MenuSection title="Protect" pt={1}>
            <MenuLinks>
              <MenuLink text="DDoS Defense" to="/v4/protect/ddos" />
              <MenuLink text="Mitigations" to="/v4/protect/mitigations" />
              {$auth.hasPermission('protect.advancedEnabled') && (
                <MenuLink text="Botnet & Threat Analysis" to="/v4/protect/views/botnet-&-threat-analysis" />
              )}
              <MenuLink text="RPKI Analysis" to="/v4/protect/views/rpki-analysis" />
            </MenuLinks>
          </MenuSection>
        </Box>

        <MenuSpacer />

        <Box py={3} flex={1} minWidth="min-content" flexBasis={180}>
          <MenuSection title="Service Provider" whiteSpace="nowrap">
            <MenuLinks>
              <MenuLink text="CDN Analytics" to="/v4/service-provider/cdn" />
              <MenuLink text="OTT Service Tracking" to="/v4/service-provider/ott" />
              <MenuLink text="Kentik Market Intelligence" to="/v4/service-provider/market-intel" />
            </MenuLinks>
          </MenuSection>

          <MenuSection
            title="Cloud"
            color="primary"
            showAnimatedArrow={$hybridMap.clouds.length > 0}
            to={$hybridMap.clouds.length > 0 ? '/v4/cloud' : undefined}
          >
            <MenuLinks>
              {$auth.hasPermission('kubeMap.enabled', { overrideForSudo: false }) && (
                <MenuLink text="Kentik Kube" to="/v4/cloud/kube" />
              )}
              <MenuLink text="AWS Traffic Overview" to="/v4/cloud/views/aws-traffic-trends-and-overview" />
              <MenuLink text="GCP Traffic Overview" to="/v4/cloud/views/gcp-traffic-trends-and-overview" />
              <MenuLink text="Azure Traffic Overview" to="/v4/cloud/views/azure-traffic-trends-and-overview" />
              <MenuLink text="Performance Monitor" to="/v4/cloud/performance" />
              <MenuLink text="Cloud Pathfinder" to="/v4/cloud/pathfinder" />
            </MenuLinks>
          </MenuSection>
        </Box>
      </>
    );
  };

  getMenuComponents = () => {
    const { onClose, theme } = this.props;
    const isDarkMode = theme.name === 'dark';

    const MenuLink = getMenuLinkCmp({ onClose, isDarkMode });

    const MenuSection = getMenuSectionCmp({
      onClose,
      isDarkMode,
      onMouseEnter: this.handleMouseEnterMenuSection,
      onMouseLeave: () => {
        this.setState({ hoveringSection: null });
      }
    });

    return {
      MenuLink,
      MenuSection
    };
  };

  render() {
    const { isOpen, onClose, topOffset, $auth } = this.props;
    const { MenuLink, MenuSection } = this.getMenuComponents();
    const { hoveringSection } = this.state;

    return (
      <>
        <StyledBackdrop topOffset={topOffset} onClick={onClose} isOpen={isOpen} />
        <StyledMenuWrapper topOffset={topOffset} isOpen={isOpen}>
          {isOpen && (
            <Flex height="100%">
              <Flex
                display="flex"
                flexDirection="column"
                pt="12px"
                pb={0}
                flex={1}
                minWidth={250}
                maxWidth={250}
                flexBasis={250}
                className="nav-left"
              >
                <Flex
                  flexDirection="column"
                  flex={1}
                  onMouseEnter={() => this.handleMouseEnterMenuSection(null)}
                  className="nav-left-inner"
                >
                  <MenuSection py="7px" px={2} title="Alerting" icon="shield" to="/v4/alerting" />
                  <MenuSection py="7px" px={2} title="Journeys" icon="route" to="/v4/core/journeys" />
                  <MenuSection py="7px" px={2} title="Kentik Map" icon="layout-auto" to="/v4/kentik-map" />
                  <MenuSection py="7px" px={2} title="Library" icon="folder-close" to="/v4/library" />
                  {!$auth.isDemoUser && (
                    <MenuSection py="7px" px={2} title="My Kentik Portal" icon="key" to="/v4/mkp" />
                  )}
                  <MenuSection py="7px" px={2} title="Observation Deck" icon="control" to="/v4/home" />
                </Flex>

                <MenuSection
                  py="10px"
                  px={2}
                  title="Top Talkers"
                  icon="lightning"
                  to="/v4/core"
                  hasSubItems
                  fontWeight={hoveringSection === 'Top Talkers' ? 'bold' : 'normal'}
                  active={hoveringSection === 'Top Talkers'}
                />

                <MenuSection
                  py="10px"
                  px={2}
                  title="Settings"
                  icon="cog"
                  to="/v4/settings"
                  hasSubItems
                  fontWeight={hoveringSection === 'Settings' ? 'bold' : 'normal'}
                  active={hoveringSection === 'Settings'}
                />
              </Flex>

              <MenuDivider />

              <Flex className="nav-center" bg="navbarMenuCenterBackground" flex={1} px="36px">
                {this.renderMenuContents(MenuSection, MenuLink)}
              </Flex>

              <MenuDivider />

              <Flex
                pb={3}
                pt="20px"
                px={3}
                flex={1}
                minWidth="min-content"
                flexBasis={320}
                maxWidth={320}
                className="nav-right"
                flexDirection="column"
              >
                <Flex flexDirection="column" mb={4}>
                  <MenuSideSection>Discover</MenuSideSection>
                  <MenuSection
                    title="Knowledge Base"
                    icon="lightbulb"
                    to="https://kb.kentik.com/v4/index.html"
                    mt="12px"
                    blank
                  />

                  <MenuSection
                    title="Product Blog"
                    icon="horizontal-bar-chart-desc"
                    to="https://kentik.com/product-updates/"
                    mt="12px"
                    blank
                  />
                  {!$auth.isDemoUser && (
                    <MenuSection title="Integrations" icon="offline" to="/v4/integrations" mt="12px" />
                  )}
                  {$auth.isAbleToDemo && <DemoMenuOption />}
                  <MenuSection title="API Tester" icon="code-block" to="/v4/core/api-tester" mt="12px" blank />
                </Flex>

                <MenuSideSection>Recently Viewed</MenuSideSection>
                <RecentViewsMenuList />

                <MenuSetupTasks onClose={onClose} />
              </Flex>
            </Flex>
          )}
        </StyledMenuWrapper>
      </>
    );
  }
}

export default NavbarMenu;
