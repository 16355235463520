import Validator from 'validatorjs';

const registered = {};

/**
 * Drop-in replacement for `Validator.register()` which returns the name of the function (so you can call it without relying on
 * side effects or global state) and does not retrigger if already declared.
 *
 * @param name {string}
 * @param fn {(value) => boolean}
 * @param message {string}
 * @returns {() => string}
 */
export const registerValidator = (name, fn, message) => () => {
  if (!registered[name]) {
    Validator.register(name, fn, message);
    registered[name] = true;
  }

  return name;
};
