import { Box, Button, Flex, Menu, MenuItem, Popover, Tag, Text } from 'core/components';
import { Field, FieldState, InputGroup, Select } from 'core/form';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Dimension from 'app/components/dimensions/Dimension';
import DimensionSelector from 'app/components/dimensions/DimensionSelector';
import PropTypes from 'prop-types';

const operatorMenuWidths = {
  i_device_id: 265,
  i_device_name: 289,
  i_device_site_name: 231,
  i_site_market: 231
};

const SwapButton = (props) => (
  <Button icon="swap-horizontal" title="Invert Perspective" ml="4px" small minimal {...props} />
);

@inject('$dictionary', '$lookups')
@observer
class FilterItemEdit extends Component {
  static propTypes = {
    filter: PropTypes.shape({
      filterField: PropTypes.instanceOf(FieldState).isRequired,
      filterValue: PropTypes.instanceOf(FieldState).isRequired,
      operator: PropTypes.instanceOf(FieldState)
    }).isRequired,
    autoFocus: PropTypes.bool,
    onChange: PropTypes.func,
    onRemove: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    useChartTypes: PropTypes.bool,
    operatorConstant: PropTypes.string,
    disabledDimensions: PropTypes.array,
    restrictions: PropTypes.shape({
      optionsWhitelist: PropTypes.instanceOf(Set)
    }),
    useFilterHeadings: PropTypes.bool,
    allowRightFilterField: PropTypes.bool
  };

  static defaultProps = {
    autoFocus: true,
    onChange: undefined,
    useChartTypes: false,
    operatorConstant: undefined,
    disabledDimensions: [],
    restrictions: undefined,
    useFilterHeadings: true,
    allowRightFilterField: true
  };

  state = {
    collapsedDimensions: {}
  };

  get filterFieldInverses() {
    const { filter, $dictionary } = this.props;
    const { filterTypeInverses, filterTypeInverseGroups } = $dictionary.get('queryFilters');
    const filterField = filter.filterField.getValue();

    const inverseGroup = filterTypeInverseGroups.find((group) => group.includes(filterField));

    if (inverseGroup) {
      return inverseGroup.filter((field) => field !== filterField);
    }

    if (filterTypeInverses[filterField]) {
      return [filterTypeInverses[filterField]];
    }

    return [];
  }

  handleDimensionSectionCollapse = (group) => {
    const { collapsedDimensions } = this.state;
    const newDimensions = { ...collapsedDimensions };
    newDimensions[group] = !collapsedDimensions[group];
    this.setState({ collapsedDimensions: newDimensions });
  };

  handleFilterFieldChange = (field, value, previousValue) => {
    const { filter, $dictionary, operatorConstant } = this.props;
    const {
      getFilterValueValidator,
      standardFilterOperators,
      filterOperatorOptions,
      dictionary: {
        queryFilters: { defaultValues, flatParametricFilterTypes }
      }
    } = $dictionary;
    const filterValidatorRules = getFilterValueValidator(value);
    filter.filterValue.setRules(filterValidatorRules);

    // reset the operator, and select the first value.
    let operator = (filterOperatorOptions[value] || standardFilterOperators)[0].value;
    // use special default operators for certain filter fields
    if (value.includes('_bgp_aspath') || /_bgp_(\w+_)?community/.test(value)) {
      operator = '~';
    } else if (
      value.indexOf('as_name') !== -1 ||
      value.indexOf('asn_name') !== -1 ||
      value.indexOf('interface_description') !== -1 ||
      value.indexOf('snmp_alias') !== -1
    ) {
      operator = 'ILIKE';
    }

    if (!operatorConstant && filter.operator.getValue() !== operator) {
      filter.operator.setValue(operator);
    }

    // grab the filter family for the previousValue
    const previousFilterFamily = Object.values(flatParametricFilterTypes).find((family) =>
      family.includes(previousValue)
    );
    // if the prior filter field and the new one are in the same family, preserve the value
    if (previousFilterFamily && previousFilterFamily.includes(value)) {
      filter.filterValue.setPristine(false); // junk for lint
    } else if (defaultValues[value] !== undefined) {
      // if there's a default value, set it.
      filter.filterValue.setValue(defaultValues[value]);
    } else {
      filter.filterValue.setValue('');
    }
  };

  handleFilterOperatorChange = (field, value, previousValue) => {
    const { filter, onChange } = this.props;
    if (value.startsWith('misc_flags')) {
      filter.filterValue.setRules('');
    }
    if (value === 'field_equals' || value === 'field_not_equal') {
      filter.filterValue.setRules('');
      filter.rightFilterField.setRules('required');
    } else {
      filter.rightFilterField.setRules('');
    }
    if (onChange) {
      onChange(field, value, previousValue);
    }
  };

  componentDidMount() {
    const { $dictionary, filter, operatorConstant } = this.props;
    const { filterField, operator } = filter;
    const { getFilterValueValidator } = $dictionary;
    if (operatorConstant || !operator.getValue().startsWith('misc_flags')) {
      filter.filterValue.setRules(getFilterValueValidator(filterField.getValue()));
    }
    filter.filterValue.setPristine(false);
  }

  render() {
    const {
      autoFocus,
      disabledDimensions,
      filter,
      onRemove,
      onChange,
      index,
      $lookups,
      $dictionary,
      operatorConstant,
      restrictions,
      useFilterHeadings,
      useChartTypes,
      useNmsDimensions,
      allowRightFilterField,
      operatorOptionsOverride
    } = this.props;
    const {
      standardFilterOperators,
      filterOperatorOptions,
      filterFieldValueOptions,
      rightFilterFieldOptions,
      dictionary: {
        queryFilters: { suppressValueOperators, neverExactMatch, nms }
      }
    } = $dictionary;

    const filterField = filter.filterField.getValue();
    let valueOptions; // the options that are allowed for a specific filter.filterField
    let filterFieldOptions;

    if (useNmsDimensions) {
      filterFieldOptions = useNmsDimensions;
      const { values } = filterFieldOptions.find((option) => option.value === filterField) || {};
      if (values) {
        valueOptions = Object.entries(values).map(([value, label]) => ({ label, value }));
      }
    } else {
      filterFieldOptions = useChartTypes ? $dictionary.dimensionOptions : $dictionary.filterFieldOptions;
      valueOptions = filterFieldValueOptions[filterField] || null;
    }

    const { collapsedDimensions } = this.state;
    const { filterFieldInverses } = this;

    const filterOperator = operatorConstant || filter.operator.getValue();

    // the set of operators for a filter.filterField
    let operatorOptions = filterOperatorOptions[filterField] || standardFilterOperators;

    if (useNmsDimensions) {
      operatorOptions = nms.operatorOptions[filterField] || nms.standardOperatorOptions;
    }

    if (!allowRightFilterField) {
      operatorOptions = operatorOptions.filter(
        (opt) => opt.value !== 'field_equals' && opt.value !== 'field_not_equal'
      );
    }

    if (operatorOptionsOverride) {
      operatorOptions = operatorOptionsOverride;
    }

    const showValueField = !suppressValueOperators.includes(filterOperator);
    const showRightField = filterOperator === 'field_equals' || filterOperator === 'field_not_equal';

    let operatorMenuWidth = 155;
    if (operatorMenuWidths[filterField]) {
      operatorMenuWidth = operatorMenuWidths[filterField];
    } else if (operatorOptions.find((opt) => opt.value === 'field_equals')) {
      operatorMenuWidth = 185;
    }
    const operatorFieldWidth = showValueField || showRightField ? 155 : operatorMenuWidth;

    const onQuery = $lookups.getAutoCompleteFilterHandler(filterField);
    const exactMatch = !neverExactMatch.includes(filterField) && (filterOperator === '=' || filterOperator === '<>');

    return (
      <Flex alignItems="flex-start" px={1} mt={1}>
        <Flex flex={1} alignItems="flex-start">
          {useNmsDimensions && (
            <Field
              showLabel={false}
              mb={0}
              small
              field={filter.filterField}
              options={filterFieldOptions}
              onChange={this.handleFilterFieldChange}
            >
              <Select showFilter autoComplete />
            </Field>
          )}

          {!useNmsDimensions && (
            <DimensionSelector
              disabledValues={disabledDimensions}
              showLabel={false}
              field={filter.filterField}
              multi={false}
              isPopover
              onChange={this.handleFilterFieldChange}
              onCollapse={this.handleDimensionSectionCollapse}
              collapsedState={collapsedDimensions}
              options={filterFieldOptions}
              useFilterHeadings={useFilterHeadings}
              maxColumns={4}
              restrictions={restrictions}
              position="bottom-left"
            />
          )}

          {filterFieldInverses.length === 1 && (
            <SwapButton onClick={() => filter.filterField.setValue(filterFieldInverses[0])} />
          )}
          {filterFieldInverses.length > 1 && (
            <Popover
              content={
                <Menu>
                  {filterFieldInverses.map((field) => (
                    <MenuItem
                      key={field}
                      text={
                        <Dimension dimension={field}>
                          {({ group, label, intent }) => (
                            <Box>
                              {group && (
                                <Tag intent={intent} minimal>
                                  {group}
                                </Tag>
                              )}{' '}
                              {label}
                            </Box>
                          )}
                        </Dimension>
                      }
                      onClick={() => filter.filterField.setValue(field)}
                    />
                  ))}
                </Menu>
              }
            >
              <SwapButton />
            </Popover>
          )}
        </Flex>
        <Box mx={1} pt={operatorConstant ? '2px' : undefined}>
          {operatorConstant ? (
            <Text fontSize={11} muted>
              {operatorConstant}
            </Text>
          ) : (
            <Field
              showLabel={false}
              field={filter.operator}
              onChange={this.handleFilterOperatorChange}
              options={operatorOptions}
              mb={0}
              small
            >
              <Select width={operatorFieldWidth} menuWidth={operatorMenuWidth} />
            </Field>
          )}
        </Box>
        {showRightField && (
          <Box style={{ width: 280 }}>
            <Field
              showLabel={false}
              field={filter.rightFilterField}
              placeholder="Select dimension..."
              options={rightFilterFieldOptions[filterField] || null}
              onChange={onChange}
              mb={0}
              autoFocus={autoFocus}
              small
            >
              <Select boundary="window" fill menuWidth="auto" />
            </Field>
          </Box>
        )}
        {!showRightField && showValueField && (
          <Box style={{ width: 280 }}>
            {(valueOptions || onQuery) && (
              <Field
                showLabel={false}
                field={filter.filterValue}
                placeholder="Select value..."
                options={valueOptions}
                onQuery={onQuery}
                onChange={onChange}
                validateOptions={exactMatch}
                mb={0}
                autoFocus={autoFocus}
                small
              >
                <Select
                  autoComplete
                  exactMatch={exactMatch}
                  showFilter={exactMatch}
                  boundary="window"
                  fill
                  menuWidth="auto"
                />
              </Field>
            )}
            {!valueOptions && !onQuery && (
              <Field
                showLabel={false}
                field={filter.filterValue}
                onChange={onChange}
                placeholder={null}
                options={null} /* need this to handle toggling from options to no options */
                autoFocus={autoFocus}
                mb={0}
                small
              >
                <InputGroup />
              </Field>
            )}
          </Box>
        )}
        <Button icon="cross" onClick={() => onRemove(index)} intent="danger" ml={1} small minimal />
      </Flex>
    );
  }
}

export default FilterItemEdit;
