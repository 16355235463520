import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import storeLoader from 'app/stores/storeLoader';
import styled from 'styled-components';
import { Tooltip } from 'core/components';

function getInitials(fullName) {
  const names = fullName.split(' ');
  let initials = '';

  if (names.length >= 2) {
    initials += names[0][0];
    initials += names[names.length - 1][0];
  } else if (names.length === 1) {
    initials += names[0][0];
  }

  return initials.toUpperCase();
}

function generateHexForName(fullName) {
  let hash = 0;
  for (let i = 0; i < fullName.length; i++) {
    hash = fullName.charCodeAt(i) + (hash * 5 - hash);
  }
  let color = '#';
  for (let j = 0; j < 3; j++) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >>> (j * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

const Bubble = styled.div`
  background: ${(props) => props.bg};
  background-image: linear-gradient(122deg, #ffffff59, transparent);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  height: 30px;
  width: 30px;
  font-size: 11px;
`;

@storeLoader('$users.collection')
@observer
class UserInitials extends React.Component {
  @computed
  get user() {
    const { $users, userId } = this.props;
    return $users.collection.get(userId);
  }

  render() {
    const name = this.user?.get('user_full_name');

    if (!name) {
      return null;
    }

    return (
      <Tooltip content={name} position="top" hoverOpenDelay={300}>
        <Bubble bg={generateHexForName(name)}>{getInitials(name)}</Bubble>
      </Tooltip>
    );
  }
}

export default UserInitials;
