import React, { Component } from 'react';
import { Layer, Rect } from 'react-konva/lib/ReactKonvaCore'; // https://github.com/konvajs/react-konva#minimal-bundle
import { rgba } from 'polished';
import { CELL_MARGIN } from 'app/views/synthetics/components/mesh/util/constants';
import MeshCell from '../MeshCell';

export default class HoverLayer extends Component {
  shouldComponentUpdate(newProps) {
    const { density, mesh, hoveredCell, hoveredRow, hoveredCol, highlightedRow, highlightedCol, theme } = this.props;
    return (
      density?.size !== newProps.density?.size ||
      mesh.length !== newProps.mesh.length ||
      hoveredCell !== newProps.hoveredCell ||
      hoveredRow !== newProps.hoveredRow ||
      hoveredCol !== newProps.hoveredCol ||
      highlightedRow !== newProps.highlightedRow ||
      highlightedCol !== newProps.highlightedCol ||
      theme.name !== newProps.theme.name
    );
  }

  get highlightBars() {
    const { density, mesh, highlightedRow, highlightedCol, theme } = this.props;
    const totalCellSize = density.size + CELL_MARGIN;
    const hasRow = typeof highlightedRow === 'number';
    const hasCol = typeof highlightedCol === 'number';
    const highlights = [];

    if (hasRow) {
      highlights.push(
        <Rect
          key="highlighted-row"
          y={highlightedRow * totalCellSize}
          width={totalCellSize * mesh.length}
          height={density.size}
          fill={rgba(theme.colors.primary, 0.15)}
        />
      );
    }

    if (hasCol) {
      highlights.push(
        <Rect
          key="highlighted-col"
          x={highlightedCol * totalCellSize}
          width={density.size}
          height={totalCellSize * mesh.length}
          fill={rgba(theme.colors.primary, 0.15)}
        />
      );
    }

    if (hasRow && hasCol) {
      highlights.push(
        <MeshCell
          key="highlighted-search-cell"
          density={density}
          row={mesh[highlightedRow]}
          col={mesh[highlightedRow].columns[highlightedCol]}
          theme={theme}
          hovered
        />
      );
    }

    return highlights;
  }

  render() {
    const { density, mesh, hoveredCell, hoveredRow, hoveredCol, theme, isAggregated } = this.props;
    return (
      <Layer listening={false} transformsEnabled="position">
        {hoveredCell && (
          <>
            <MeshCell
              density={density}
              row={mesh[hoveredRow]}
              col={mesh[hoveredRow].columns[hoveredCol]}
              theme={theme}
              isAggregated={isAggregated}
              hovered
            />
            <MeshCell
              density={density}
              row={mesh[hoveredCol]}
              col={mesh[hoveredCol].columns[hoveredRow]}
              theme={theme}
              isAggregated={isAggregated}
              highlighted
            />
          </>
        )}
        {this.highlightBars}
      </Layer>
    );
  }
}
