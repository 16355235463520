import React from 'react';

const Svg = React.forwardRef(({ width, height, marginTop, marginRight, marginBottom, marginLeft, children }, ref) => {
  const marginX = marginLeft + marginRight;
  const marginY = marginTop + marginBottom;
  const adjustedWidth = width - marginX;
  const adjustedHeight = height - marginY;

  return (
    <svg ref={ref} width={width} height={height} viewBox={`-${marginLeft} -${marginTop} ${width} ${height}`}>
      {children({ adjustedWidth, adjustedHeight })}
    </svg>
  );
});

Svg.defaultProps = {
  width: 400,
  height: 400,
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0
};

export default Svg;
