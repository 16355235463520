/** NOTE: This component is shared with MKP!
 * Prevent regressions by spoofing as a tenant when testing. */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import styled from 'styled-components';
import { Card, Box, Flex, FlexColumn, Heading, Tab, Tabs, Icon, Text, Link, Tag, Paragraph } from 'core/components';
import { DDOS_QUERIES } from 'app/stores/alerting/DdosFactorQueries';
import VirtualizedTable from 'core/components/table/VirtualizedTable';
import { formatDateTime } from 'core/util/dateUtils';
import { stopBubbling } from 'app/util/utils';
import { AckAlertButton } from 'app/views/alerting/components/AckAlertButton';
import LargeLabeledValue from 'app/components/LargeLabeledValue';
import MetricsUpDownChart from 'app/views/alerting/components/charts/MetricsUpDownChart';
import MetricsThresholdChart from 'app/views/alerting/components/charts/MetricsThresholdChart';
import NmsAlertCharts from 'app/views/alerting/components/nms/NmsAlertCharts';
import NmsNativeAlertConditions from 'app/views/alerting/components/nms/NmsNativeAlertConditions';
import EventAlertChart from 'app/views/alerting/components/charts/EventAlertChart';
import AlertChart from 'app/views/alerting/components/charts/AlertChart';
import AlertWhy from 'app/views/alerting/components/AlertWhy';
import { hasSubMinuteEvaluationPeriod, formatEvaluationPeriod } from 'app/stores/alerting/policyUtils';

import { deserializeConditionRoot } from 'app/stores/alerting/nms/nmsSerializations';
import DDosFactorsTab from './DDosFactorsTab';
import AlertDimensionInfo from './AlertDimensionInfo';
import NmsNativeAlertDetailsSummaryLabels from '../nms/NmsNativeAlertDetailsSummaryLabels';

const labelValueProps = {
  labelTextProps: { fontSize: 14 },
  valueTextProps: { fontSize: 18, fontWeight: 600 },
  shouldFormatValue: false
};

const ALERT_TABS = {
  ALERT_DETAILS: 'alert_details',
  INGRESS_INTERFACES: DDOS_QUERIES.INGRESS_INTERFACES,
  TRAFFIC_PATTERNS: DDOS_QUERIES.TRAFFIC_PATTERNS,
  SOURCE_COUNTRIES: DDOS_QUERIES.SOURCE_COUNTRIES,
  SOURCE_SERVICES: DDOS_QUERIES.SOURCE_SERVICES,
  PACKET_SIZE_DISTRIBUTION: DDOS_QUERIES.PACKET_SIZE_DISTRIBUTION
};

const Section = styled(Box)`
  padding-top: 16px;
  padding-bottom: 16px;

  &:first-child {
    padding-top: 0;
  }
`;

@inject('$app', '$metrics', '$alerting', '$exports')
@observer
class AlertDetails extends Component {
  state = {
    selectedTabId: ALERT_TABS.ALERT_DETAILS
  };

  componentDidMount() {
    const { device } = this.props;

    if (device) {
      device.fetch();
    }
  }

  getColumns = () => {
    const { $app, model: alertModel } = this.props;

    const columns = [
      {
        label: 'State',
        name: 'stateLabel',
        id: 'stateLabel',
        value: 'stateLabel',
        computed: true,
        width: 80,
        renderer: ({ model, value }) => (
          <Tag
            minimal
            intent={model.isActive ? 'danger' : 'success'}
            width="80px"
            round
            textAlign="center"
            fontWeight="500"
            py="4px"
          >
            {value}
          </Tag>
        )
      },
      {
        name: 'severity',
        label: 'Severity',
        computed: true,
        renderer: ({ model, value }) => (
          <Flex gap="4px" alignItems="center">
            <Icon icon="symbol-circle" color={model.severityColor} iconSize="12px" />
            {value}
          </Flex>
        )
      },
      {
        name: 'displayDuration',
        sortField: 'duration',
        label: 'Duration',
        computed: true
      },
      {
        name: 'startTime',
        computed: true,
        label: 'Start',
        renderer: ({ value }) => formatDateTime(value)
      },
      {
        name: 'endTime',
        computed: true,
        label: 'End',
        renderer: ({ value }) => (value ? formatDateTime(value) : 'Currently Active')
      },
      {
        name: 'id',
        label: 'ID',
        flexBasis: 225,
        computed: true,
        renderer: ({ value }) =>
          alertModel.id === value ? (
            <Text muted>(Selected)</Text>
          ) : (
            <Link to={`/v4/alerting/${value}`} blank>
              {value}
            </Link>
          )
      }
    ];

    if (!$app.isSubtenant) {
      columns.push({
        label: '',
        name: 'ackStateLabel',
        id: 'ackStateLabel',
        value: 'ackStateLabel',
        sortable: false,
        flexBasis: 100,
        minWidth: 160,
        renderer: ({ model }) => (
          <span onClick={stopBubbling}>
            <AckAlertButton model={model} buttonProps={{ icon: null, small: true }} />
          </span>
        )
      });
    }

    return columns;
  };

  filterAlertTable = (firstTs, lastTs) => {
    const { alertHistoryCollection } = this.props;
    const windowStart = moment(firstTs);
    const windowEnd = moment(lastTs);

    alertHistoryCollection.filter((alertModel) => {
      const { startTime, endTime } = alertModel;
      const start = moment(startTime);
      const end = moment(endTime);
      return start <= windowEnd && end >= windowStart;
    });

    this.setState({
      windowStart,
      windowEnd
    });
  };

  handleShowAll = () => {
    const { alertHistoryCollection } = this.props;

    alertHistoryCollection.filter();

    this.setState({
      windowStart: null,
      windowEnd: null
    });
  };

  alertTable = () => {
    const { alertHistoryCollection } = this.props;
    return (
      <Card>
        <FlexColumn height="400px">
          <VirtualizedTable
            rowHeight={40}
            collection={alertHistoryCollection}
            columns={this.getColumns()}
            selectOnRowClick={false}
            flexed
          />
        </FlexColumn>
      </Card>
    );
  };

  get summaryLabels() {
    const { $metrics, model } = this.props;

    if (model.isNmsPolicy) {
      return <NmsNativeAlertDetailsSummaryLabels alertModel={model} />;
    }

    if (model.isMetricPolicy) {
      const metricToValue = model.get('metricToValue');
      const measurement = model.reconMeasurement;
      const measurementModel = $metrics.measurementModel(measurement);
      const dimensions = Object.entries(model.reconDimensions).map(([label, value]) => {
        // TODO: remove this when backend is stripping this out again
        if (value && typeof value === 'string') {
          value = value.replaceAll('<kntkcolon>', ':');
        }

        return [measurementModel?.dimensionLabel(label) || label, value || '--'];
      });

      const metrics = [];

      Object.keys(metricToValue).forEach((metric) => {
        const value = metricToValue[metric];
        metrics.push(['Metric', measurementModel?.metricLabel(metric)]);
        metrics.push(['Value', measurementModel?.metricValueLabel(metric, value)]);
      });

      return [...metrics, ...dimensions, ['Measurement', measurement]].map(([label, value]) => (
        <LargeLabeledValue key={`${label}-${value}`} label={label} {...labelValueProps} value={value} />
      ));
    }

    return <AlertDimensionInfo model={model} />;
  }

  handleTabChange = (tab) => {
    const { $exports } = this.props;

    this.setState({ selectedTabId: tab });

    $exports.setHash({ hashedSelectedTabId: tab });
  };

  get statusSegment() {
    const { model } = this.props;

    const { startTime, endTime } = model;
    const startAgo = startTime ? moment(startTime).fromNow() : moment(endTime).fromNow();

    return (
      <LargeLabeledValue
        flex="0 0 100px"
        label="Alert State"
        {...labelValueProps}
        value={
          <FlexColumn>
            <Flex alignItems="center" gap={1}>
              <Tag
                my="4px"
                minimal
                intent={model.isActive ? 'danger' : 'success'}
                width="100px"
                round
                large
                textAlign="center"
                fontWeight="500"
                py="4px"
              >
                {model.stateLabel}
              </Tag>
            </Flex>
            <Text muted small fontWeight="normal">
              {startAgo}
            </Text>
          </FlexColumn>
        }
      />
    );
  }

  get DDoSTabs() {
    const { selectedTabId } = this.state;

    return (
      <Tabs selectedTabId={selectedTabId} onChange={this.handleTabChange} large>
        <Tab id={ALERT_TABS.ALERT_DETAILS} title="Alert" />
        <Tab key={1} id={ALERT_TABS.INGRESS_INTERFACES} title="Ingress Interfaces" />
        <Tab key={2} id={ALERT_TABS.TRAFFIC_PATTERNS} title="Traffic Patterns" />
        <Tab key={3} id={ALERT_TABS.SOURCE_COUNTRIES} title="Source Countries" />
        <Tab key={4} id={ALERT_TABS.SOURCE_SERVICES} title="Source Services" />
        <Tab key={5} id={ALERT_TABS.PACKET_SIZE_DISTRIBUTION} title="Packet Size Distribution" />
      </Tabs>
    );
  }

  renderCharts() {
    const { model } = this.props;
    const { policy, isEventPolicy, isNmsPolicy, isMetricPolicy } = model;

    if (isNmsPolicy) {
      return <NmsAlertCharts alertModel={model} numChartsToShow={3} />;
    }

    const evaluationPeriod = policy.get('evaluationPeriod');
    const evaluationDisclosure = hasSubMinuteEvaluationPeriod(evaluationPeriod) && (
      <Text as="div" muted small>
        This graph may be inaccurate due to the policy&rsquo;s short evaluation frequency of{' '}
        {formatEvaluationPeriod(evaluationPeriod)}.
      </Text>
    );

    if (isMetricPolicy) {
      return (
        <>
          <Box mt={3}>
            {policy.isUpDownPolicy && model.reconDevice ? (
              <MetricsUpDownChart device={model.reconDevice} model={model} filterAlertTable={this.filterAlertTable} />
            ) : (
              <MetricsThresholdChart model={model} />
            )}
          </Box>
          {evaluationDisclosure}
        </>
      );
    }

    if (isEventPolicy) {
      return (
        <Flex flexDirection="column" flex="1 1 auto" borderBottom="thin" alignItems="stretch">
          <Flex flex="1 0 300px" my={2} mr={2}>
            <EventAlertChart
              alertModel={model}
              small
              viewProps={{
                height: '100%',
                showAxisLabels: false,
                showTooltips: false,
                className: 'no-markers'
              }}
            />
          </Flex>
        </Flex>
      );
    }

    return (
      <>
        <AlertChart alertModel={model} showSnmpChart viewProps={{ height: 400 }} />
        {evaluationDisclosure}
      </>
    );
  }

  get detailSegment() {
    const { $alerting, model } = this.props;
    const { policy } = model;
    const thresholds = $alerting.getThresholds(model);

    return (
      <Box py={2} pr={3}>
        <Section>
          <Flex>
            <Box flex={1} minWidth="200px">
              {this.renderCharts()}
            </Box>
          </Flex>
        </Section>
        {policy.isNmsPolicy && (
          <Box maxWidth="max-content">
            <NmsNativeAlertConditions
              conditionRoot={deserializeConditionRoot(model?.nmsAlarmContext?.triggeredLevel?.activate)}
              title="Triggering Conditions"
            />
          </Box>
        )}
        {!policy.isMetricPolicy && !policy.isNmsPolicy && (thresholds.length > 0 || model.alarmTriggerText) && (
          <Section>
            <AlertWhy model={model} maxWidth="640px" />
          </Section>
        )}
      </Box>
    );
  }

  get relatedAlertsSegment() {
    const { windowStart, windowEnd } = this.state;
    const { alertHistoryCollection } = this.props;

    return (
      <Box mt={3}>
        <Box mb={1}>
          <Flex mb="4px" alignItems="center">
            <Text as="div" fontWeight="bold" fontSize={16} mb={0}>
              Alert History
              {!alertHistoryCollection.loading && (
                <Tag minimal round ml={1}>
                  {alertHistoryCollection.unfilteredSize}
                </Tag>
              )}
            </Text>

            {windowStart ? (
              <Flex gap={1} ml={2}>
                <Text muted>
                  {formatDateTime(windowStart)} -{formatDateTime(windowEnd)}
                </Text>
                <Text as="a" onClick={this.handleShowAll}>
                  Show all alerts
                </Text>
              </Flex>
            ) : null}
          </Flex>
          <Text as="div" small muted>
            Alerts with matching dimensions, triggered by the same policy.
          </Text>
        </Box>
        {this.alertTable()}
      </Box>
    );
  }

  render() {
    const { selectedTabId } = this.state;
    const { model } = this.props;
    const isDDosAlert = model.isDDoSPolicy;
    const { policy, policyName } = model;
    const policyDescription = policy.get('description');

    return (
      <FlexColumn flex="1">
        <FlexColumn p={3} pb={0} pl={0}>
          <Heading level={1}>
            Alert Details:
            {policyName}
          </Heading>
          {policyDescription && (
            <Box>
              <Paragraph maxLength={240}>{policyDescription}</Paragraph>
            </Box>
          )}
          <Section>
            <Flex display="flex" flexWrap="wrap" gridColumnGap={4} gridRowGap={2} mb={2}>
              {this.statusSegment}
              {this.summaryLabels}
            </Flex>

            {isDDosAlert && this.DDoSTabs}

            {(!isDDosAlert || selectedTabId === ALERT_TABS.ALERT_DETAILS) && this.detailSegment}

            {isDDosAlert && selectedTabId !== ALERT_TABS.ALERT_DETAILS && (
              <DDosFactorsTab model={model} queryId={selectedTabId} />
            )}
            {this.relatedAlertsSegment}
          </Section>
        </FlexColumn>
      </FlexColumn>
    );
  }
}

export default AlertDetails;
