import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import storeLoader from 'app/stores/storeLoader';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import { Grid, Heading, Spinner, Text } from 'core/components';
import MitigationLink from 'app/components/links/MitigationLink';

@storeLoader('$mitigations.platformsCollection')
@inject('$app', '$mitigations')
@observer
export default class MitigationDetails extends Component {
  state = {
    mitigation: null,
    loading: false
  };

  static defaultProps = {
    showHeading: true
  };

  componentDidMount() {
    const { $mitigations, model } = this.props;

    this.setState({ loading: true });

    $mitigations
      .fetchMitigation(model.mitigationID)
      .then((mitigation) => {
        this.setState({ mitigation: mitigation ?? {}, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  get mitigationDetails() {
    const { $mitigations } = this.props;
    const { mitigation } = this.state;

    if (mitigation) {
      const platformMethod = $mitigations.platformsCollection.platformMethods.find(
        (option) => option.value === mitigation.methodID
      );

      const mitigationDetails = {
        id: mitigation.id,
        start: moment(mitigation.start).utc().format(DEFAULT_DATETIME_FORMAT)
      };

      if (platformMethod) {
        return {
          ...mitigationDetails,
          platform_name: platformMethod.platform.get('name'),
          method_name: platformMethod.method.get('name')
        };
      }
      return mitigationDetails;
    }

    return {};
  }

  render() {
    const { loading, mitigation } = this.state;
    const { $app, showHeading } = this.props;
    const { id, start, platform_name, method_name } = this.mitigationDetails;

    return (
      <>
        {showHeading && (
          <Heading level={6} mb={1} fontWeight="heavy" borderBottom="thin">
            Mitigation Details
          </Heading>
        )}

        {loading || !mitigation ? (
          <Spinner size={20} />
        ) : (
          <>
            {!mitigation.id ? (
              'None'
            ) : (
              <Grid gridTemplateColumns="max-content 1fr" gap="8px" gridRowGap="4px">
                <Text muted>ID:</Text>
                <MitigationLink id={id} isSubtenant={$app.isSubtenant}>
                  {id}
                </MitigationLink>

                {start && (
                  <>
                    <Text muted>Started:</Text>
                    <Text>{start}</Text>
                  </>
                )}

                {platform_name && (
                  <>
                    <Text muted>Platform:</Text>
                    <Text>{platform_name}</Text>
                  </>
                )}

                {method_name && (
                  <>
                    <Text muted>Method:</Text>
                    <Text>{method_name}</Text>
                  </>
                )}
              </Grid>
            )}
          </>
        )}
      </>
    );
  }
}
