import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Position } from '@blueprintjs/core';
import { withTheme } from 'styled-components';
import moment from 'moment';
import { sumBy, isObject, isEmpty, isNumber, isNaN } from 'lodash';

import {
  Box,
  Button,
  Card,
  Collapse,
  Flex,
  FlexColumn,
  Icon,
  Link,
  LinkButton,
  Popover,
  Tag,
  Text,
  Tooltip
} from 'core/components';
import { CELL_TYPES, VirtualizedTable, Table } from 'core/components/table';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import healthSerializer, { getTimestamp } from 'app/stores/synthetics/healthSerializer';
import { TEST_TYPES, urlDefinedTaskTypes, TASK_TYPES } from 'app/util/constants';
import { getShortName } from 'app/util/queryResults';
import { greekIt } from 'app/util/utils';
import AgentRenderer, { BaseAgentRenderer } from 'app/views/synthetics/components/AgentRenderer';
import HighDensityItems from 'app/views/synthetics/components/HighDensityItems';

import {
  getSubtestUrl,
  healthToIntent,
  getPacketLossValue,
  getMsValue,
  dnsCodeLookup,
  checkSeriesDataForFailure
} from 'app/views/synthetics/utils/syntheticsUtils';
import { safelyParseJSON } from 'core/util';
import TestErrorPopover from 'app/views/synthetics/TestErrorPopover';
import TestResultsLegend from 'app/views/synthetics/TestResultsLegend';
import DnssecButton from 'app/views/synthetics/components/DnssecButton';

function getFormattedTime(timestampString) {
  return moment.unix(timestampString).utc().format(DEFAULT_DATETIME_FORMAT);
}

function getFormattedDate(timestampString) {
  return moment.unix(timestampString).utc().format(DEFAULT_DATE_FORMAT);
}

function getHealthAvg(results, timestamp, agentId) {
  const taskKeys = Object.keys(results.health_agg);
  let healthAvg = {};
  for (let i = 0; i < taskKeys.length; i += 1) {
    const taskType = taskKeys[i];
    const { agg_agents } = results.health_agg[taskType];
    const measurement = agg_agents[timestamp]?.agents[agentId];

    if (TASK_TYPES.DNS === taskType) {
      healthAvg = { ...healthAvg, dns_avg_latency: measurement?.avg_latency, dns_health: measurement?.latency_health };
    } else if (!urlDefinedTaskTypes.includes(taskType)) {
      healthAvg = { ...measurement, ...healthAvg };
    } else {
      healthAvg = { ...healthAvg, avg_ttlb: measurement?.avg_latency, ttlb_health: measurement?.latency_health };
    }
  }

  return healthAvg;
}

// column utils
const NoValue = ({ useNotApplicable }) => <Text muted>{useNotApplicable ? 'N/A' : '---'}</Text>;
const columnTagRenderer = (data = {}, opts = {}) => {
  const { value } = data;
  const { displayZero = true, intent = 'none', isFailing = false } = opts;
  if (isFailing || (!value && (value !== 0 || !displayZero))) {
    return <NoValue useNotApplicable={isFailing} />;
  }
  return (
    <Tag fontSize="small" fontWeight="bold" minimal intent={intent}>
      {value}
    </Tag>
  );
};
const dnsTagRenderer = ({ model, collection }) => {
  const dataString = model.get('healthDnssec.data');
  if (!dataString || !!collection?.groupBy) {
    return <NoValue />;
  }
  return <DnssecButton model={model} />;
};

function getColumns({
  $app,
  $syn,
  lookbackSeconds,
  startDate,
  endDate,
  hasFlow,
  hasPing,
  isMultiTarget,
  showTargetHealth,
  showAgentError,
  test_id,
  test,
  allowAgentDetails
}) {
  const columns = {};

  columns.agent = {
    disabled: isMultiTarget,
    label: 'Agent',
    name: 'agent',
    computed: true,
    minWidth: 180,
    ellipsis: false,
    renderer: ({ model }) => {
      const agent = model.get('agentModel');

      if (agent) {
        return <AgentRenderer agent={agent} showLocationInfo />;
      }

      return (
        <Text fontStyle="italic" muted>
          Unknown or removed agent
        </Text>
      );
    }
  };

  columns.health_status = {
    label: 'Health Status',
    name: 'overall_health',
    computed: true,
    minWidth: 100,
    ellipsis: false,
    renderer: ({ model }) => {
      const health = model.get('overall_health');
      if (!health) {
        return <NoValue />;
      }
      const tagIntent = healthToIntent({ health, isAggregated: model.get('isAggregated') });
      return (
        <Tag fontSize="small" fontWeight="bold" minimal textTransform="capitalize" intent={tagIntent}>
          {health}
        </Tag>
      );
    }
  };

  columns.screenshots = {
    label: 'Screenshots',
    name: 'transaction_summary',
    computed: true,
    minWidth: 100,
    maxWidth: 100,
    ellipsis: false,
    renderer: ({ model }) => {
      const trxSummary = model.get('transaction_summary', {});
      return columnTagRenderer({ value: trxSummary.screenshots });
    }
  };

  columns.target = (hasLink) => ({
    label: 'Target',
    name: 'target',
    computed: true,
    referencedFields: ['overall_health', 'target'],
    ellipsis: false,
    renderer: ({ model }) => {
      const value = model.target;
      const { target_agent, taskTarget } = model.get();
      let target = target_agent ? $syn.agents.get(target_agent) : null;

      if (!target) {
        const agentByIp = $syn.agents.getTargetAgentsByIps([taskTarget]);
        target = agentByIp ? agentByIp[0] : null;
      }

      if (hasLink) {
        const to = getSubtestUrl({ agent_id: model.get('agent_id'), target: value, test_id });
        return (
          // eslint-disable-next-line
            <Link
            disabled={!model.get('agentModel') || model.get('noQueryData') || $app.isSubtenant || !allowAgentDetails}
            to={allowAgentDetails ? to : undefined}
            state={{ lookbackSeconds, startDate, endDate }}
            keepSearch
          >
            {value}
          </Link>
        );
      }
      let comp = !test.isHostname ? (
        value
      ) : (
        <>
          {model.get('taskTarget')} {value !== '::' ? `(${value})` : ''}
        </>
      );
      if (target) {
        const agentOption = $syn.agents.getOptionsForAgents(target);
        comp = (
          <BaseAgentRenderer {...agentOption}>
            <Tag ml={1} minimal small>
              {agentOption.locationInfo}
            </Tag>
          </BaseAgentRenderer>
        );
      }

      if (!target && (test.isMesh || test.isAgentTest)) {
        comp = (
          <Flex gap="4px" alignItems="center">
            <Text>{value}</Text>
            <Tooltip content="Agent details cannot be found">
              <Icon icon="warning-sign" iconSize={12} color="warning" />
            </Tooltip>
          </Flex>
        );
      }

      const health = model.get('overall_health');
      return (
        <>
          {showTargetHealth && (
            <Tag
              fontWeight="bold"
              minimal
              mr={1}
              intent={healthToIntent({ health, isAggregated: model.get('isAggregated') })}
              textTransform="capitalize"
            >
              {health}
            </Tag>
          )}
          {comp}
        </>
      );
    }
  });

  columns.flow = {
    disabled: !hasFlow,
    label: 'Potentially Impacted Traffic',
    name: 'flow',
    ellipsis: false,
    width: 90,
    renderer: ({ value }) => {
      if (!value) {
        return <NoValue />;
      }
      return greekIt(value[1], { fix: 0, suffix: 'bps' }).displayFull;
    }
  };

  columns.connectivity_type = {
    disabled: !hasFlow,
    label: 'Connectivity Type',
    name: 'connectivityType',
    ellipsis: false,
    width: 90,
    renderer: ({ value }) => {
      if (!value) {
        return <NoValue />;
      }
      return getShortName('i_dst_connect_type_name', value);
    }
  };

  columns.provider = {
    disabled: !hasFlow,
    label: 'Provider',
    name: 'provider',
    ellipsis: false,
    renderer: ({ value }) => {
      if (!value) {
        return <NoValue />;
      }
      return value;
    }
  };

  const dns_latency = {
    name: 'dns_avg_latency',
    ellipsis: false,
    width: 120,
    renderer: ({ model, value }) =>
      columnTagRenderer(
        { value: getMsValue(value) },
        {
          isFailing: model.get('dns_latency_health') === 'failing' || model.get('packet_loss') === 1,
          intent: healthToIntent({ health: model.get('dns_latency_health'), isAggregated: model.get('isAggregated') })
        }
      )
  };

  const base_latency = {
    name: 'avg_latency',
    ellipsis: false,
    width: 120,
    renderer: ({ model, value }) =>
      columnTagRenderer(
        { value: getMsValue(value) },
        {
          isFailing: model.get('latency_health') === 'failing' || model.get('packet_loss') === 1,
          intent: healthToIntent({ health: model.get('latency_health'), isAggregated: model.get('isAggregated') })
        }
      )
  };

  columns.dns_resolution_latency = Object.assign({ label: 'Resolution Time' }, dns_latency);
  columns.avg_latency = Object.assign({ label: 'Avg Latency', disabled: !hasPing }, base_latency);

  columns.throughput_bandwidth = {
    label: 'Throughput Bandwidth',
    name: 'avg_throughput_bandwidth',
    ellipsis: false,
    width: 200,
    renderer: ({ model, value }) => {
      const throughputTaskDataJson = safelyParseJSON(model.get('throughputTask.taskHealth.data'));
      let errorMessage = null;
      if (throughputTaskDataJson && throughputTaskDataJson.length > 0) {
        errorMessage = throughputTaskDataJson[0]?.message;
      }

      return (
        <Popover
          content={
            <Flex gap={1} p={1}>
              <FlexColumn>Error: {errorMessage}</FlexColumn>
            </Flex>
          }
          position={Position.BOTTOM}
          interactionKind="hover"
          hoverOpenDelay={500}
          disabled={!errorMessage}
        >
          <>
            {errorMessage ? <Icon icon="info-sign" iconSize={16} color="warning" /> : null}
            {isNumber(value)
              ? columnTagRenderer(
                  { value: greekIt(value, { fix: 2, suffix: 'mbps' }).displayFull },
                  {
                    intent: healthToIntent({
                      health: model.get('other_healths.throughput_health') || model.get('other_healths.error_health'),
                      isAggregated: model.get('isAggregated')
                    })
                  }
                )
              : '---'}
          </>
        </Popover>
      );
    }
  };

  columns.avg_jitter = {
    disabled: !hasPing,
    label: 'Avg Jitter',
    name: 'avg_jitter',
    ellipsis: false,
    width: 120,
    renderer: ({ model, value }) =>
      columnTagRenderer(
        { value: getMsValue(value) },
        {
          isFailing: model.get('jitter_health') === 'failing' || model.get('packet_loss') === 1,
          intent: healthToIntent({ health: model.get('jitter_health'), isAggregated: model.get('isAggregated') })
        }
      )
  };

  columns.packet_loss = {
    disabled: !hasPing,
    label: 'Packet Loss',
    name: 'packet_loss',
    width: 120,
    ellipsis: false,
    renderer: ({ model, value }) =>
      columnTagRenderer(
        { value: getPacketLossValue(value) },
        {
          intent: healthToIntent({ health: model.get('packet_loss_health'), isAggregated: model.get('isAggregated') })
        }
      )
  };

  if (allowAgentDetails) {
    columns.actions = {
      type: CELL_TYPES.ACTION,
      width: 80,
      actions: [
        (model) => {
          let targetQueryParam = '';
          // We want ping target (ip address) for flow based tests, ip address tests, and network grid tests
          // Hostname tests no longer need a target query param (because ips can change over time)
          if (test.isIpTargetSubTest) {
            targetQueryParam = `${model.get('pingTarget')}`;
          }
          // Mesh tests and agent to agent tests now use agentId as the target query param
          if (test.isMesh || test.isAgentTest) {
            targetQueryParam = `${model.get('target_agent')}`;
          }
          if (test.isDns) {
            targetQueryParam = `${model.get('server')}`;
          }
          const to = getSubtestUrl({ agent_id: model.get('agent_id'), target: targetQueryParam, test_id, v2: true });
          return (
            <LinkButton
              disabled={!model.get('agentModel') || model.get('noQueryData')}
              small
              text="Details"
              key="details"
              to={to}
              state={{ lookbackSeconds, startDate, endDate }}
              keepSearch
            />
          );
        }
      ]
    };
  }

  columns.status = {
    label: 'DNS Response Code',
    name: 'dns_status',
    ellipsis: false,
    width: 180,
    renderer: ({ model, value }) =>
      isNumber(value)
        ? columnTagRenderer(
            { value: `${value} ${dnsCodeLookup(value).long}` },
            // For now, jitter health is overloaded to represent DNS Response Code health.
            // This is done on the DnsResultModel deserializer
            {
              intent: healthToIntent({
                health: model.get('dns_status_health'),
                isAggregated: model.get('isAggregated')
              })
            }
          )
        : '---'
  };

  columns.dns_results = {
    label: 'DNS Results',
    name: 'dns_data',
    width: 300,
    renderer: ({ value = [], model }) => {
      const { hasValidDnsIpsConfig } = test;

      if (value.length === 1) {
        const tagIntent = healthToIntent({
          health: model.get('other_healths.dns_valid_ips_health'),
          isAggregated: model.get('isAggregated')
        });
        return (
          <Tag fontSize="small" fontWeight="bold" minimal intent={hasValidDnsIpsConfig ? tagIntent : 'none'}>
            {value[0]}
          </Tag>
        );
      }

      if (value.length > 1) {
        const dnsValidIps = hasValidDnsIpsConfig ? test.validDnsIpsConfig.split(', ') : [];

        if (!hasValidDnsIpsConfig) {
          const [resultsHead, ...resultsTails] = value;
          return (
            <Popover
              content={
                <Flex gap={1} p={1}>
                  <FlexColumn>
                    {resultsTails.map((ip) => (
                      <Tag mb="1px" fontWeight="bold" key={ip}>
                        {ip}
                      </Tag>
                    ))}
                  </FlexColumn>
                </Flex>
              }
              position={Position.BOTTOM}
              interactionKind="hover"
              hoverOpenDelay={500}
            >
              <Tag fontSize="small" fontWeight="bold" minimal>
                {resultsHead}
                {resultsTails.length && <Text ml={1}>+ {resultsTails.length}</Text>}
              </Tag>
            </Popover>
          );
        }

        if (hasValidDnsIpsConfig) {
          const validDnsIps = hasValidDnsIpsConfig ? value.filter((ip) => dnsValidIps.includes(ip)) : value;
          const invalidDnsIps = hasValidDnsIpsConfig ? value.filter((ip) => !dnsValidIps.includes(ip)) : value;
          const [validDnsIpsHead, ...validDnsIpsTail] = validDnsIps;
          const [invalidDnsIpsHead, ...invalidDnsIpsTail] = invalidDnsIps;

          return (
            <Popover
              content={
                <Flex gap={1} p={1}>
                  {!isEmpty(invalidDnsIpsTail) && (
                    <FlexColumn>
                      <Box mb={1} pb="3px" borderBottom="thin">
                        <Text small muted>
                          NOT ALLOWED
                        </Text>
                      </Box>
                      {invalidDnsIpsTail.map((ip) => (
                        <Tag mb="1px" fontWeight="bold" key={ip} intent="danger">
                          {ip}
                        </Tag>
                      ))}
                    </FlexColumn>
                  )}
                  {!isEmpty(validDnsIpsTail) && (
                    <FlexColumn>
                      <Box mb={1} pb="3px" borderBottom="thin">
                        <Text small muted>
                          ALLOWED
                        </Text>
                      </Box>
                      {validDnsIpsTail.map((ip) => (
                        <Tag mb="1px" fontWeight="bold" key={ip} intent="success">
                          {ip}
                        </Tag>
                      ))}
                    </FlexColumn>
                  )}
                </Flex>
              }
              position={Position.BOTTOM}
              interactionKind="hover"
              hoverOpenDelay={500}
            >
              <Flex gap={1}>
                {!isEmpty(invalidDnsIps) && (
                  <Tag fontSize="small" fontWeight="bold" minimal intent="danger">
                    {invalidDnsIpsHead}
                    {invalidDnsIpsTail.length && <Text ml={1}>+ {invalidDnsIpsTail.length}</Text>}
                  </Tag>
                )}
                {!isEmpty(validDnsIps) && (
                  <Tag fontSize="small" fontWeight="bold" minimal intent="success">
                    {validDnsIpsHead}
                    {validDnsIpsTail.length && <Text ml={1}>+ {validDnsIpsTail.length}</Text>}
                  </Tag>
                )}
              </Flex>
            </Popover>
          );
        }
      }

      return <NoValue />;
    }
  };

  columns.dnssec = {
    label: 'DNSSEC',
    name: 'dnssec',
    width: 120,
    renderer: dnsTagRenderer
  };

  columns.css_selector_status = {
    label: 'CSS Validation',
    name: 'css_selector_status',
    ellipsis: false,
    width: 120,
    renderer: ({ value }) => {
      let columnValue;
      if (typeof value === 'undefined') {
        columnValue = 'N/A';
      } else {
        columnValue = value ? 'PASS' : 'FAIL';
      }
      return columnTagRenderer({ value: columnValue });
    }
  };

  columns.avg_ttlb = {
    label: 'Avg HTTP Latency',
    name: 'avg_ttlb',
    ellipsis: false,
    width: 120,
    renderer: ({ model, value }) =>
      // it is unrealistic to have 0 latency, so we can assume that is due to timeout or error
      value === 0
        ? '---'
        : columnTagRenderer(
            { value: getMsValue(value) },
            { intent: healthToIntent({ health: model.get('ttlb_health'), isAggregated: model.get('isAggregated') }) }
          )
  };

  columns.total_trx_time = {
    label: 'Total Transaction Time',
    name: 'avg_ttlb',
    ellipsis: false,
    width: 220,
    renderer: ({ model, value }) =>
      columnTagRenderer(
        { value: getMsValue(value) },
        { intent: healthToIntent({ health: model.get('ttlb_health'), isAggregated: model.get('isAggregated') }) }
      )
  };

  columns.transaction_completion = {
    label: 'Transaction Completion',
    name: 'http_status',
    ellipsis: false,
    width: 280,
    minWidth: 240,
    renderer: ({ model, value }) => {
      const statusMessage = model.get('statusMessage');
      const isUnhealthy = (value && value !== 0) || checkSeriesDataForFailure({ task_type: model.get('task_type') });
      const hasError = !isEmpty(statusMessage) && statusMessage !== 'success';
      const isAggregated = model.get('isAggregated');
      let intent = isUnhealthy ? 'danger' : 'success';

      if (isAggregated) {
        intent = 'none';
      }

      return (
        <Flex alignItems="center">
          {columnTagRenderer({ value: isUnhealthy ? 'FAIL' : 'PASS' }, { intent })}
          {hasError && <TestErrorPopover error={statusMessage} isAggregated={isAggregated} />}
        </Flex>
      );
    }
  };

  columns.http_navigation_time = {
    label: 'Navigation Time',
    name: 'http_navigation_time',
    ellipsis: false,
    width: 120,
    renderer: ({ value }) => (!isNaN(value) ? columnTagRenderer({ value: getMsValue(value) }) : '---')
  };

  columns.http_domain_lookup_time = {
    label: 'Domain Lookup Time',
    name: 'http_domain_lookup_time',
    ellipsis: false,
    width: 120,
    renderer: ({ value }) => (!isNaN(value) ? columnTagRenderer({ value: getMsValue(value) }) : '---')
  };

  columns.http_connect_time = {
    label: 'Connect Time',
    name: 'http_connect_time',
    ellipsis: false,
    width: 120,
    renderer: ({ value }) => (!isNaN(value) ? columnTagRenderer({ value: getMsValue(value) }) : '---')
  };

  columns.http_response_time = {
    label: 'Response Time',
    name: 'http_response_time',
    ellipsis: false,
    width: 120,
    renderer: ({ value }) => (!isNaN(value) ? columnTagRenderer({ value: getMsValue(value) }) : '---')
  };

  columns.http_dom_processing_time = {
    label: 'DOM Processing Time',
    name: 'http_dom_processing_time',
    ellipsis: false,
    width: 120,
    renderer: ({ value }) => (!isNaN(value) ? columnTagRenderer({ value: getMsValue(value) }) : '---')
  };

  columns.https_validity = {
    label: 'Certificate Expiry',
    name: 'https_expiry_timestamp',
    ellipsis: false,
    width: 140,
    renderer: ({ model }) => {
      const https_validity = model.get('https_validity');
      const hasCertError = isObject(https_validity) && https_validity?.code === 609;
      const httpsExpiryTimestamp = model.get('https_expiry_timestamp');

      return httpsExpiryTimestamp || hasCertError
        ? columnTagRenderer(
            {
              value: hasCertError
                ? (https_validity?.message ?? 'SSL Certificate Error')
                : getFormattedDate(httpsExpiryTimestamp)
            },
            {
              intent: healthToIntent({
                health: hasCertError ? 'critical' : model.get('other_healths.http_cert_health'),
                isAggregated: model.get('isAggregated')
              })
            }
          )
        : '---';
    }
  };

  columns.http_status = {
    label: 'Status Code',
    name: 'http_status',
    ellipsis: false,
    width: 120,
    renderer: ({ model, value }) =>
      columnTagRenderer(
        { value },
        {
          displayZero: false,
          intent: healthToIntent({ health: model.get('http_status_health'), isAggregated: model.get('isAggregated') })
        }
      )
  };

  columns.agent_error = {
    label: 'Agent Error',
    name: 'overall_health',
    ellipsis: false,
    width: 120,
    renderer: ({ model }) => {
      const { message } = model.get('agent_error') || {};
      return (
        <Flex pl={2} alignItems="center" justifyContent="center">
          {message && <TestErrorPopover error={message} />}
        </Flex>
      );
    }
  };

  columns.http_size = {
    label: 'Response Size',
    name: 'http_size',
    ellipsis: false,
    width: 120,
    renderer: ({ value }) =>
      value ? columnTagRenderer({ value: greekIt(value, { fix: 0, suffix: 'B' }).displayFull }) : '---'
  };

  columns.http_response_headers_health = {
    label: 'Response Headers Health',
    name: 'http_response_headers_health',
    ellipsis: false,
    width: 140,
    renderer: ({ model }) => {
      const value = model.get('other_healths.http_response_headers_health');
      return columnTagRenderer(
        { value },
        {
          intent: healthToIntent({
            health: model.get('other_healths.http_response_headers_health'),
            isAggregated: model.get('isAggregated')
          })
        }
      );
    }
  };

  const test_type = test.get('test_type');
  const config_ignore_tls_errors =
    [TEST_TYPES.URL, TEST_TYPES.PAGE_LOAD].includes(test_type) && test.get('config.http.ignore_tls_errors');
  let filteredColumns = [];

  if (test_type === TEST_TYPES.URL) {
    filteredColumns = [
      columns.agent,
      ...(!config_ignore_tls_errors && test.isTargetHttps ? [columns.https_validity] : []),
      columns.http_status,
      columns.http_domain_lookup_time,
      columns.http_connect_time,
      columns.http_response_time,
      columns.avg_ttlb,
      columns.avg_latency,
      columns.avg_jitter,
      columns.packet_loss,
      columns.http_response_headers_health
    ];
  } else if (test_type === TEST_TYPES.PAGE_LOAD) {
    filteredColumns = [
      columns.agent,
      ...(!config_ignore_tls_errors && test.isTargetHttps ? [columns.https_validity] : []),
      columns.http_status,
      columns.http_navigation_time,
      columns.http_domain_lookup_time,
      columns.http_connect_time,
      columns.http_response_time,
      columns.http_dom_processing_time,
      columns.avg_ttlb,
      columns.avg_latency,
      columns.avg_jitter,
      columns.packet_loss,
      columns.http_response_headers_health
    ];
    const config_css_selectors = test.get('config.http.css_selectors');
    if (Array.isArray(config_css_selectors) && config_css_selectors.length !== 0) {
      filteredColumns.splice(2, 0, columns.css_selector_status);
    }
  } else if (test_type === TEST_TYPES.TRANSACTION) {
    filteredColumns = [columns.agent, columns.health_status, columns.total_trx_time, columns.transaction_completion];
  } else if (test.isFlowBased) {
    filteredColumns = [
      columns.target(true),
      columns.provider,
      columns.connectivity_type,
      columns.flow,
      columns.avg_latency,
      columns.avg_jitter,
      columns.packet_loss
    ];
  } else if (test.isDns) {
    filteredColumns = [
      columns.agent,
      columns.target(),
      columns.dns_resolution_latency,
      columns.status,
      columns.dns_results,
      columns.avg_latency,
      columns.avg_jitter,
      columns.packet_loss
    ];
    const tasks = test.get('config.tasks') || [];
    if (tasks.includes('dns-sec')) {
      filteredColumns.push(columns.dnssec);
    }
  } else {
    filteredColumns = [
      columns.agent,
      columns.target(),
      columns.flow,
      columns.connectivity_type,
      columns.provider,
      ...(test.isThroughputTest ? [columns.throughput_bandwidth] : []),
      columns.avg_latency,
      columns.avg_jitter,
      columns.packet_loss
    ];
  }

  if (!$app.isSubtenant && !test.isFlowBased && allowAgentDetails) {
    filteredColumns.push(columns.actions);
  }

  // handle tables with a lot of columns
  if (filteredColumns.length > 8) {
    filteredColumns = filteredColumns.map((column) => {
      const clone = { ...column };
      if (clone.width === 100 && column.type !== CELL_TYPES.ACTION) {
        clone.width = 100;
      }
      return clone;
    });
  }

  if (showAgentError) {
    filteredColumns.splice(1, 0, columns.agent_error);
  }

  return filteredColumns.filter((column) => column && !column.disabled);
}

@withTheme
@inject('$app', '$exports', '$syn')
@observer
class TestResultsTable extends Component {
  static defaultProps = {
    showAllResultsTable: true,
    showAllResultsTablePrefix: true,
    showUnhealthyResults: true,
    mb: 2
  };

  state = {
    isIdentifiedIssuesOpen: true,
    resultsCollection: null,
    formattedTime: null
  };

  static getDerivedStateFromProps(props, state) {
    const {
      $app,
      $exports,
      results,
      resultTimeMs,
      $syn,
      $sharedLinks,
      lookbackSeconds,
      startDate,
      endDate,
      test,
      allowAgentDetails,
      agentTaskConfig
    } = props;

    if (resultTimeMs !== state.resultTimeMs || results !== state.results) {
      const { hasAgentError, hasFlow, hasPing, isMultiTarget, resultsCollection, unhealthyCollection, timestamp } =
        healthSerializer({
          test,
          results,
          resultTimeMs,
          $syn,
          agentTaskConfig
        });
      const formattedTime = getFormattedTime(timestamp);

      $exports.getSettings().then(({ hashedSortField, hashedSortDirection }) => {
        if (hashedSortField) {
          resultsCollection.sort(hashedSortField, hashedSortDirection || 'asc');
        }
      });
      const columnsOpts = {
        $app,
        $syn,
        $sharedLinks,
        lookbackSeconds,
        startDate,
        endDate,
        hasAgentError,
        hasFlow,
        hasPing,
        isMultiTarget,
        test_id: test.id,
        test,
        allowAgentDetails: allowAgentDetails === undefined ? true : allowAgentDetails
      };

      return {
        formattedTime,
        resultTimeMs,
        results,
        resultsCollection,
        unhealthyCollection,
        columns: getColumns(columnsOpts),
        unhealthyColumns: unhealthyCollection
          ? getColumns(Object.assign({ showTargetHealth: true, showAgentError: hasAgentError }, columnsOpts))
          : null
      };
    }

    return null;
  }

  handleOnSort = ({ field, direction }) => {
    const { $exports } = this.props;
    $exports.setHash({
      hashedSortField: field,
      hashedSortDirection: direction
    });
  };

  renderAgent = (agent_id) => {
    const { $syn } = this.props;
    const agent = $syn.agents.get(agent_id);
    if (agent) {
      const agentOption = $syn.agents.getOptionsForAgents(agent);
      if (agentOption?.label) {
        return (
          <BaseAgentRenderer {...agentOption}>
            <Tag ml={1} minimal small>
              {agentOption.locationInfo}
            </Tag>
          </BaseAgentRenderer>
        );
      }
    }
    return 'Unknown agent';
  };

  getGroupSumByType = (group, type) => {
    const validGroup = group.filter((pingModel) => isNumber(pingModel.get(type)));
    return sumBy(validGroup, (pingModel) => pingModel.get(type)) / (validGroup.length || 1);
  };

  groupSummary = (group) => {
    const { resultTimeMs, test } = this.props;
    let { results } = this.props;
    const agentId = group[0].get('agent_id');
    // In the case we load multiple tests and havent selected a specific test results,
    // we need to find the results for the test we are looking at
    if (Object.hasOwn(results, 'health') && Array.isArray(results.health) && results.health.length > 0) {
      for (const health of results.health) {
        if (health.test_id === test.id) {
          results = health;
          break;
        }
      }
    }
    const timestamp = getTimestamp(results, resultTimeMs);

    const healthAvg = test.isPreview ? {} : getHealthAvg(results, timestamp, agentId);
    const jitterHealth = healthAvg.jitter_health;
    const latencyHealth = healthAvg.latency_health;
    const packetLossHealth = healthAvg.packet_loss_health;
    const httpLatencyHealth = healthAvg.ttlb_health;
    const dnsLatencyHealth = healthAvg.dns_health;
    let avgJitter = healthAvg.avg_jitter;
    let avgLatency = healthAvg.avg_latency;
    let packetLoss = healthAvg.packet_loss;
    let avgHTTPLatency = healthAvg.avg_ttlb;
    const avgDnsLatency = healthAvg.dns_avg_latency;
    const isAggregated = group.every((model) => model.get('isAggregated'));

    if (test.isFlowBased) {
      avgJitter = this.getGroupSumByType(group, 'avg_jitter');
      avgLatency = this.getGroupSumByType(group, 'avg_latency');
      packetLoss = this.getGroupSumByType(group, 'packet_loss');
      avgHTTPLatency = this.getGroupSumByType(group, 'avg_ttlb');
    }

    return {
      groupType: 'summary',
      label: (
        <Flex justifyContent="flex-start" overflow="hidden">
          <Box>{this.renderAgent(agentId)}</Box>
          <Tag minimal small ml={1}>
            Targets: {group.length}
          </Tag>
        </Flex>
      ),
      avg_jitter: columnTagRenderer(
        { value: getMsValue(avgJitter) },
        {
          isFailing: jitterHealth === 'failing' || (packetLoss === 1 && !test.isFlowBased),
          intent: healthToIntent({ health: jitterHealth, isAggregated })
        }
      ),
      avg_latency: columnTagRenderer(
        { value: getMsValue(avgLatency) },
        {
          isFailing: latencyHealth === 'failing' || (packetLoss === 1 && !test.isFlowBased),
          intent: healthToIntent({ health: latencyHealth, isAggregated })
        }
      ),
      avg_ttlb: columnTagRenderer(
        { value: getMsValue(avgHTTPLatency) },
        {
          isFailing: httpLatencyHealth === 'failing',
          intent: healthToIntent({ health: httpLatencyHealth, isAggregated })
        }
      ),
      dns_avg_latency: columnTagRenderer(
        { value: getMsValue(avgDnsLatency) },
        {
          isFailing: dnsLatencyHealth === 'failing',
          intent: healthToIntent({ health: dnsLatencyHealth, isAggregated })
        }
      ),
      packet_loss: columnTagRenderer(
        { value: getPacketLossValue(packetLoss) },
        {
          isFailing: packetLoss === 'failing' || (packetLoss === 1 && !test.isFlowBased),
          intent: healthToIntent({ health: packetLossHealth, isAggregated })
        }
      ),
      dnssec: dnsTagRenderer({ model: group[0] })
    };
  };

  renderFlow = (TableComp, columns, resultsCollection) => {
    resultsCollection.group('agent_provider');

    return (
      <TableComp
        columns={columns}
        collection={resultsCollection}
        selectOnRowClick={false}
        onSort={this.handleOnSort}
        isCollapsed
        groupSummaryLookup={({ group }) => {
          const flowGroupSummary = this.groupSummary(group);
          const agentId = group[0].get('agent_id');
          const provider = group[0].get('provider');
          flowGroupSummary.label = (
            <Flex justifyContent="flex-start" overflow="hidden">
              <Box maxWidth="300px">{this.renderAgent(agentId)}</Box>
              <Tag minimal small ml={1}>
                Targets: {group.length}
              </Tag>
            </Flex>
          );
          flowGroupSummary.provider = provider || '---';

          let totalTraffic = sumBy(group, (item) => item.get('flow.1') || 0);
          totalTraffic = greekIt(totalTraffic, { fix: 0, suffix: 'bps' }).displayFull;
          flowGroupSummary.flow = totalTraffic;

          return flowGroupSummary;
        }}
        flexed
        staticColumns
      />
    );
  };

  get grid() {
    const { test, gridMargins, showHighDensityGrid, lookbackSeconds, startDate, endDate } = this.props;
    const { resultsCollection, unhealthyCollection } = this.state;

    if (test.isHighDensityGrid || showHighDensityGrid) {
      return (
        <HighDensityItems
          gridMargins={gridMargins}
          lookbackSeconds={lookbackSeconds}
          startDate={startDate}
          endDate={endDate}
          resultsCollection={resultsCollection}
          test={test}
          unhealthyCollection={unhealthyCollection}
          isAggregated={this.isAggregated}
        />
      );
    }

    return null;
  }

  get unhealthyResults() {
    const { $app, test, showUnhealthyResults, showAllResultsTablePrefix, showAllResultsTable, mb } = this.props;
    const { unhealthyColumns, unhealthyCollection, isIdentifiedIssuesOpen } = this.state;
    const hasUnhealthyResults = unhealthyCollection.size > 0;
    const isUnhealthyVirtualTable = unhealthyCollection.size > 99;
    const UnhealthyTableComp = $app.isExport || !isUnhealthyVirtualTable ? Table : VirtualizedTable;

    if (hasUnhealthyResults && showUnhealthyResults) {
      return (
        <Box mb={showAllResultsTablePrefix || showAllResultsTable ? 2 : mb}>
          <Button
            mb={isIdentifiedIssuesOpen ? '4px' : 0}
            text="Identified Issues"
            icon={isIdentifiedIssuesOpen ? 'caret-down' : 'caret-right'}
            intent="none"
            large
            minimal
            fontWeight="bold"
            onClick={() => this.setState({ isIdentifiedIssuesOpen: !isIdentifiedIssuesOpen })}
          />
          <Collapse isOpen={isIdentifiedIssuesOpen}>
            <Card
              flex={1}
              display="flex"
              minHeight={isUnhealthyVirtualTable ? 300 : 0}
              style={{ overflowX: 'auto', overflowY: 'hidden' }}
            >
              <UnhealthyTableComp
                columns={unhealthyColumns}
                collection={unhealthyCollection}
                selectOnRowClick={false}
                groupSummaryLookup={({ group }) => {
                  if (test.isFlowBased) {
                    const flowGroupSummary = this.groupSummary(group);
                    const agentId = group[0].get('agent_id');
                    flowGroupSummary.label = (
                      <Flex justifyContent="flex-start" overflow="hidden">
                        <Box maxWidth="300px">{this.renderAgent(agentId)}</Box>
                        <Tag minimal small ml={1}>
                          Targets: {group.length}
                        </Tag>
                      </Flex>
                    );
                    let totalTraffic = sumBy(group, (item) => item.get('flow.1') || 0);
                    totalTraffic = greekIt(totalTraffic, { fix: 0, suffix: 'bps' }).displayFull;
                    flowGroupSummary.flow = totalTraffic;

                    return flowGroupSummary;
                  }

                  return this.renderAgent(group[0].get('agent_id'));
                }}
                flexed
                staticColumns
              />
            </Card>
          </Collapse>
        </Box>
      );
    }

    return null;
  }

  get allResults() {
    const { $app, showAllResultsTable, mb, test } = this.props;
    const { unhealthyCollection, resultsCollection, columns } = this.state;
    const AllResultsTableComp = $app.isExport || resultsCollection.size < 100 ? Table : VirtualizedTable;
    const hasUnhealthyResults = unhealthyCollection.size > 0;

    if (showAllResultsTable) {
      return (
        <Card
          flex={1}
          display="flex"
          minHeight={Math.min(28 + resultsCollection.size * 45, 300)}
          mb={mb}
          style={{ overflowX: 'auto', overflowY: 'hidden' }}
        >
          {test.isFlowBased ? (
            this.renderFlow(AllResultsTableComp, columns, resultsCollection)
          ) : (
            <AllResultsTableComp
              columns={columns}
              collection={resultsCollection}
              isCollapsed={hasUnhealthyResults}
              selectOnRowClick={false}
              onSort={this.handleOnSort}
              groupSummaryLookup={({ group }) => this.groupSummary(group)}
              flexed
              staticColumns
            />
          )}
        </Card>
      );
    }

    return null;
  }

  get footer() {
    const { showAllResultsTablePrefix, mb } = this.props;
    const { formattedTime } = this.state;

    if (showAllResultsTablePrefix) {
      return (
        <Flex alignItems="center" justifyContent="space-between" mb={showAllResultsTablePrefix ? 2 : mb}>
          <Text fontWeight="heavy" as="div">
            Test Timestamp: <Text fontWeight="normal">{formattedTime} UTC</Text>
          </Text>
          <TestResultsLegend />
        </Flex>
      );
    }

    return null;
  }

  get isAggregated() {
    const { results } = this.props;
    return results?.book?.aggregationData?.isAggregated;
  }

  render() {
    const { children, showAllResultsTablePrefix = true } = this.props;
    const { resultsCollection } = this.state;
    const hasResults = resultsCollection && resultsCollection.size > 0;

    if (typeof children === 'function') {
      return children({
        hasResults,
        grid: this.grid,
        unhealthyResults: this.unhealthyResults,
        allResults: this.allResults,
        footer: this.footer
      });
    }

    if (!hasResults) {
      return null;
    }

    return (
      <FlexColumn flex={1}>
        {this.grid}
        {this.unhealthyResults}
        {showAllResultsTablePrefix && this.footer}
        {this.allResults}
      </FlexColumn>
    );
  }
}

export default TestResultsTable;
