import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DateRangePicker } from '@blueprintjs/datetime';
import { Box, Button, Flex, Menu, MenuDivider, MenuItem, Popover } from 'core/components';
import SelectPopover from 'core/components/select/SelectPopover';
import { DateAndTime, Field, FormComponent, SubmitButton } from 'core/form';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import moment from 'moment';

function timeInTransform(value, form) {
  return value && form.getValue('timeFormat') === 'Local'
    ? moment.utc(value).local().format(DEFAULT_DATETIME_FORMAT)
    : value;
}

function timeOutTransform(value, form) {
  return value && form.getValue('timeFormat') === 'Local' ? moment(value).utc().format(DEFAULT_DATETIME_FORMAT) : value;
}

const fields = {
  timeFormat: { label: 'Time Zone' }, // not visible, just used for transform
  startDate: { label: 'From', transform: { in: timeInTransform, out: timeOutTransform } },
  endDate: { label: 'To', transform: { in: timeInTransform, out: timeOutTransform } }
};

const dividers = [0, 900, 21600, 259200, 2592000];

function getRangeFromLookback(lookbackSeconds) {
  const startDate = moment
    .utc()
    .subtract(lookbackSeconds || 300, 'second')
    .toDate();
  const endDate = moment.utc().toDate();

  return [startDate, endDate];
}

@observer
export default class TimeSelector extends Component {
  static defaultProps = {
    maxDateRange: null,
    maxDate: null, // optionally specify a maximum selectable date
    timeFormat: 'UTC',
    showDateRangePicker: false, // when overridden to true, will always display the date picker next to the lookback options menu
    buttonTextRenderer: null, // optionally allow for custom rendering of select button content
    lookbackMenuRenderer: null, // optionally allow for custom rendering of lookback options menu
    buildRules: null // optional callback to modify validation rules for start/end
  };

  static getDerivedStateFromProps(props, state) {
    const { lookbackSeconds, startDate, endDate } = props;
    const { initialLookbackSeconds, initialStartDate, initialEndDate } = state;

    if (lookbackSeconds !== initialLookbackSeconds) {
      if (lookbackSeconds === 0 && (!startDate || !endDate)) {
        const [newStartDate, newEndDate] = getRangeFromLookback(initialLookbackSeconds || 3600);

        return {
          initialLookbackSeconds: lookbackSeconds,
          initialStartDate: newStartDate,
          initialEndDate: newEndDate,
          lookbackSeconds,
          startDate: newStartDate,
          endDate: newEndDate
        };
      }

      if (lookbackSeconds === 0 && startDate && endDate) {
        return {
          initialLookbackSeconds: lookbackSeconds,
          initialStartDate: startDate,
          initialEndDate: endDate,
          lookbackSeconds,
          startDate,
          endDate
        };
      }

      return { initialLookbackSeconds: lookbackSeconds, lookbackSeconds };
    }

    if (
      (startDate?.getTime() && startDate.getTime() !== initialStartDate?.getTime()) ||
      (endDate?.getTime() && endDate.getTime() !== initialEndDate?.getTime())
    ) {
      return { initialStartDate: startDate, initialEndDate: endDate, startDate, endDate };
    }

    return null;
  }

  state = {
    isOpen: false,
    initialLookbackSeconds: -1,
    initialStartDate: null,
    initialEndDate: null,
    startDate: null,
    endDate: null
  };

  get startDateRules() {
    const { maxDateRange, buildRules } = this.props;
    const rules = ['required', 'date', 'before:endDate', 'beforeNow'];

    if (maxDateRange) {
      const earliestStartDate = moment.utc().subtract(604800, 'second');

      rules.push(`maxDateRange:${maxDateRange},endDate`);
      rules.push(`afterDate:${earliestStartDate.format(DEFAULT_DATETIME_FORMAT)}`);
    }

    if (buildRules) {
      return buildRules({ type: 'start', rules });
    }

    return rules;
  }

  get endDateRules() {
    const { maxDateRange, buildRules } = this.props;
    const rules = ['required', 'date', 'beforeNow'];

    if (maxDateRange) {
      rules.push(`maxDateRange:${maxDateRange},startDate`);
    }

    if (buildRules) {
      return buildRules({ type: 'end', rules });
    }

    return rules;
  }

  formatDate(date) {
    const { timeFormat } = this.props;
    const momentFn = timeFormat === 'UTC' ? moment.utc : moment;
    const momentDate = momentFn(date);

    if (momentDate.isValid()) {
      return momentDate.format(DEFAULT_DATETIME_FORMAT);
    }

    return '';
  }

  formatDateUTC(date) {
    const { timeFormat } = this.props;

    if (date) {
      if (timeFormat === 'UTC') {
        return moment.utc(date).format(DEFAULT_DATETIME_FORMAT);
      }

      return moment(date).utc().format(DEFAULT_DATETIME_FORMAT);
    }

    return '';
  }

  getLocalTimeRange() {
    const { timeFormat } = this.props;
    const { startDate, endDate } = this.state;

    if (timeFormat === 'UTC') {
      return [
        startDate ? moment.utc(startDate).local(true).toDate() : null,
        endDate ? moment.utc(endDate).local(true).toDate() : null
      ];
    }

    return [startDate, endDate];
  }

  getLocalMaxDate() {
    const { timeFormat, maxDate } = this.props;

    if (maxDate) {
      return maxDate;
    }

    if (timeFormat === 'UTC') {
      return moment.utc().local(true).toDate();
    }

    return new Date();
  }

  handleInteraction = (nextOpenState) => {
    this.setState({ isOpen: nextOpenState });
  };

  handleSelectLookback = (value) => {
    const { lookbackSeconds, onChange } = this.props;
    const newState = { lookbackSeconds: value };

    if (value === 0) {
      const [startDate, endDate] = getRangeFromLookback(lookbackSeconds);
      Object.assign(newState, { initialStartDate: startDate, initialEndDate: endDate, startDate, endDate });
    }

    this.setState(newState);

    if (onChange && value !== 0) {
      onChange(newState);
    }
  };

  handleChangeDateInput = ({ field, fieldValue }) => {
    const { timeFormat } = this.props;

    if (field.name === 'startDate' || field.name === 'endDate') {
      const momentFn = timeFormat === 'UTC' ? moment.utc : moment;
      const date = momentFn(fieldValue);

      if (date.isValid()) {
        this.setState({ [field.name]: date.toDate() });
      }
    } else if (field.name === 'timeFormat') {
      const { onChangeTimeZone } = this.props;

      if (onChangeTimeZone) {
        onChangeTimeZone(fieldValue);
      }
    }
  };

  handleSelectRange = (range) => {
    const { timeFormat } = this.props;
    let [startDate, endDate] = range;

    if (timeFormat === 'UTC') {
      startDate = startDate ? moment(startDate).utc(true).toDate() : null;
      endDate = endDate ? moment(endDate).utc(true).toDate() : null;
    }

    this.setState({ startDate, endDate });
  };

  handleCancel = () => {
    const { lookbackSeconds } = this.props;
    const { initialStartDate, initialEndDate } = this.state;
    this.setState({ lookbackSeconds, startDate: initialStartDate, endDate: initialEndDate, isOpen: false });
  };

  handleApply = () => {
    const { onChange } = this.props;
    const { startDate, endDate } = this.state;

    this.setState({ isOpen: false });

    if (onChange) {
      onChange({ lookbackSeconds: 0, startDate, endDate });
    }
  };

  get lookbackMenuOptions() {
    const { lookbackOptions, lookbackSeconds, lookbackMenuRenderer } = this.props;

    if (lookbackMenuRenderer) {
      // use the custom lookback options menu
      return lookbackMenuRenderer({ lookbackOptions, lookbackSeconds });
    }

    return lookbackOptions.map((option, idx) => (
      <React.Fragment key={option.value}>
        <MenuItem
          text={option.label}
          intent={option.value === lookbackSeconds ? 'primary' : undefined}
          onClick={() => this.handleSelectLookback(option.value)}
          shouldDismissPopover={option.value !== 0}
          popoverProps={{ isOpen: false }}
          small
        >
          {/* empty child to show right caret on Custom item */}
          {option.value === 0 ? '' : null}
        </MenuItem>
        {dividers.includes(option.value) && idx < lookbackOptions.length - 1 && <MenuDivider />}
      </React.Fragment>
    ));
  }

  render() {
    const { lookbackOptions, timeFormat, small, disabled, showDateRangePicker, buttonTextRenderer } = this.props;
    const { isOpen, lookbackSeconds, startDate, endDate, initialStartDate, initialEndDate } = this.state;

    const selectedOption = lookbackOptions.find((option) => option.value === lookbackSeconds);
    const isCustom = showDateRangePicker || lookbackSeconds === 0;
    let buttonText = isCustom
      ? `${this.formatDate(initialStartDate)} → ${this.formatDate(initialEndDate)}`
      : selectedOption?.label || '';

    if (buttonTextRenderer) {
      // use the custom button text renderer
      buttonText = buttonTextRenderer({
        isCustom,
        lookbackOption: selectedOption,
        startDate: initialStartDate,
        endDate: initialEndDate,
        timeFormat
      });
    }

    return (
      <Popover
        isOpen={isOpen}
        position={SelectPopover.defaultProps.popoverPosition}
        modifiers={SelectPopover.defaultProps.modifiers}
        onInteraction={this.handleInteraction}
        target={
          <Button
            active={isOpen}
            text={buttonText}
            rightIcon="caret-down"
            small={small}
            textAlign="left"
            flex={1}
            disabled={disabled}
          />
        }
        content={
          <Flex>
            {this.lookbackMenuOptions?.length > 0 && <Menu>{this.lookbackMenuOptions}</Menu>}
            {isCustom ? (
              <Box p={1} borderLeft="thin" width={490}>
                <FormComponent
                  fields={fields}
                  values={{
                    startDate: this.formatDateUTC(startDate),
                    endDate: this.formatDateUTC(endDate),
                    timeFormat
                  }}
                  options={{ name: 'LookbackTimeSelector', showPristineErrors: true }}
                  onChange={this.handleChangeDateInput}
                >
                  {() => (
                    <>
                      <Flex gap="4px" justifyContent="flex-end" mb={1}>
                        <Button text="Cancel" small={small} minWidth={65} onClick={this.handleCancel} />
                        <SubmitButton text="Apply" small={small} minWidth={65} onSubmit={this.handleApply} />
                      </Flex>
                      <Flex gap={1}>
                        <Field name="startDate" rules={this.startDateRules} flex="1 1 50%" small>
                          <DateAndTime />
                        </Field>
                        <Field name="endDate" rules={this.endDateRules} flex="1 1 50%" small>
                          <DateAndTime />
                        </Field>
                      </Flex>
                    </>
                  )}
                </FormComponent>
                <DateRangePicker
                  allowSingleDayRange
                  maxDate={this.getLocalMaxDate()}
                  shortcuts={false}
                  value={this.getLocalTimeRange()}
                  onChange={this.handleSelectRange}
                />
              </Box>
            ) : null}
          </Flex>
        }
      />
    );
  }
}
