import { getTrafficQueryFromNms } from 'app/stores/query/TrafficQuery';
import { CELL_TYPES } from 'core/components/table';
import { Button, DropdownMenu, Menu, MenuItem, Popover, Text } from 'core/components';
import { FiMoreVertical } from 'react-icons/fi';
import React from 'react';
import { observer } from 'mobx-react';
import { PopoverInteractionKind } from '@blueprintjs/core';
import Box from 'core/components/Box';
import { getDeviceFromMetricsQueryModel } from 'app/stores/device/queries/MetricsDeviceToDevice';
import { DataExplorerQuery } from 'app/views/metrics/result/dataExplorerQuery';

const VALID_SITE_DIMENSIONS = new Set(['i_device_site_name']);
const VALID_DEVICE_DIMENSIONS = new Set(['device_name', 'device_ip']);
const VALID_INTERFACE_DIMENSIONS = new Set(['mac-address', 'ifindex']);

/**
 * @param validDimensions {Set<string>}
 * @param targetDimensions {string[]}
 * @returns {boolean}
 * @private
 */
const _isValidQuery = (validDimensions, targetDimensions) =>
  targetDimensions.some((dimension) => validDimensions.has(dimension));

const isValidQuery = {
  site: _isValidQuery.bind(null, VALID_SITE_DIMENSIONS),
  /**
   * @param dimensions {string[]}
   * @param measurement {string}
   * @returns {boolean}
   */
  interface: (dimensions, measurement) =>
    _isValidQuery(VALID_INTERFACE_DIMENSIONS, dimensions) ||
    // special case - interface can be looked up by "name" but the meaning of this field changes depending on measurement
    (measurement === '/interfaces/counters' && dimensions.some((dimension) => dimension === 'name')),
  // can always look up device based on interface
  /**
   * @param dimensions {string[]}
   * @param measurement {string}
   * @returns {boolean}
   */
  device: (dimensions, measurement) =>
    isValidQuery.interface(dimensions, measurement) || _isValidQuery(VALID_DEVICE_DIMENSIONS, dimensions)
};

/**
 * @param dimensions {string[]}
 * @param measurement {string}
 * @returns {boolean}
 */
const shouldRenderActionDropdown = (dimensions, measurement) =>
  isValidQuery.device(dimensions, measurement) ||
  isValidQuery.site(dimensions) ||
  isValidQuery.interface(dimensions, measurement);

/**
 * Prepares the dimension selector with the relevant fields populated and notifies the parent component so that it can be opened.
 * @param params
 * @param params.model {import('core/Model').default}
 * @param params.query
 * @param params.type {'site' | 'device' | 'interface'}
 * @param [params.direction] {'ingress' | 'egress'}
 * @param params.onShowDimensionSelector {(queryFields: object) => void}
 * @param params.lastUpdated {number} update time for the query, used to ensure that DE queries are aligned with ME lookback queries
 */
const handleShowDimensionSelector = ({ model, query, type, onShowDimensionSelector, direction, lastUpdated }) => {
  const props = model.get();

  const { queryModel, queryFields } = getTrafficQueryFromNms({
    ...props,
    range: query.kmetrics.range,
    type,
    interface_name: props.name,
    site_name: props.i_device_site_name,
    mac_address: props['mac-address'],
    direction,
    lastUpdated
  });

  DataExplorerQuery.setQuery(queryModel);
  onShowDimensionSelector(queryFields);
};

/**
 * @param params {object}
 * @param params.text {string}
 * @param params.onClick {function}
 * @param [params.children] {React.JSX.Element[] | null}
 * @returns {React.JSX.Element}
 */
const MetricsResultMenuItem = ({ text, onClick, children }) => (
  <MenuItem text={text} onClick={!children ? onClick : undefined}>
    {children}
  </MenuItem>
);

/**
 * @typedef Props
 * @property {string[]} dimensions
 * @property {string} measurement
 * @property {import('core/Model').default} model
 * @property query
 * @property {() => void} onShowDimensionSelector
 * @property {number} lastUpdated update time for the query, used to ensure that DE queries are aligned with ME lookback queries
 */

const MetricsResultActionDropdown = observer(
  /**
   * @param params {Props}
   * @returns {React.JSX.Element|null}
   */
  ({ model, dimensions, query, measurement, onShowDimensionSelector, lastUpdated }) => {
    if (!shouldRenderActionDropdown(dimensions, measurement)) {
      return null;
    }

    const device = getDeviceFromMetricsQueryModel(model.get());
    const hasFlow = device?.hasFlow ?? false;

    /**
     * @param type {'device' | 'interface' | 'site'}
     * @param [direction] {'ingress' | 'egress'}
     */
    const onDataExplorerClick = (type, direction) =>
      handleShowDimensionSelector({ model, query, type, onShowDimensionSelector, direction, lastUpdated });

    return (
      <Popover
        interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
        fill
        lazy
        disabled={hasFlow}
        content={
          <Box p={1}>
            <Text>This device does not have flow data.</Text>
          </Box>
        }
      >
        <>
          <DropdownMenu
            disabled={!hasFlow}
            content={
              hasFlow ? (
                <Menu display="flex" flexDirection="column">
                  {isValidQuery.site(dimensions) ? (
                    <MetricsResultMenuItem text="Inspect External Traffic for Site">
                      <MetricsResultMenuItem
                        text="Inbound by..."
                        onClick={() => onDataExplorerClick('site', 'ingress')}
                      />
                      <MetricsResultMenuItem
                        text="Outbound by..."
                        onClick={() => onDataExplorerClick('site', 'egress')}
                      />
                    </MetricsResultMenuItem>
                  ) : null}

                  {isValidQuery.device(dimensions, measurement) ? (
                    <MetricsResultMenuItem
                      hasFlow={hasFlow}
                      text="Inspect Device Traffic"
                      onClick={() => onDataExplorerClick('device')}
                    />
                  ) : null}

                  {isValidQuery.interface(dimensions, measurement) ? (
                    <MetricsResultMenuItem text="Inspect Interface Traffic">
                      <MetricsResultMenuItem
                        text="Inbound by..."
                        onClick={() => onDataExplorerClick('interface', 'ingress')}
                      />
                      <MetricsResultMenuItem
                        text="Outbound by..."
                        onClick={() => onDataExplorerClick('interface', 'egress')}
                      />
                    </MetricsResultMenuItem>
                  ) : null}
                </Menu>
              ) : null
            }
          >
            <Button icon={FiMoreVertical} color="muted" minimal />
          </DropdownMenu>{' '}
        </>
      </Popover>
    );
  }
);

/**
 * @param params
 * @param params.query {object}
 * @param params.dimensions {string[]}
 * @param params.measurement {string}
 * @param onShowDimensionSelector
 * @param lastUpdated {number} update time for the query, used to ensure that DE queries are aligned with ME lookback queries
 * @returns {[{name: string, width: number, type: string, actions: (model) => React.JSX.Element}] | []}
 */
export const getActionColumns = ({ query, dimensions, measurement }, onShowDimensionSelector, lastUpdated) =>
  onShowDimensionSelector && shouldRenderActionDropdown(dimensions, measurement)
    ? [
        {
          name: 'options',
          type: CELL_TYPES.ACTION,
          width: 50,
          actions: [
            (model) => (
              <MetricsResultActionDropdown
                key={model.id}
                model={model}
                dimensions={dimensions}
                query={query}
                measurement={measurement}
                onShowDimensionSelector={onShowDimensionSelector}
                lastUpdated={lastUpdated}
              />
            )
          ]
        }
      ]
    : [];
