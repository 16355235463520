import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Classes } from '@blueprintjs/core';
import storeLoader from 'app/stores/storeLoader';
import { getChannelUsageMap } from '@kentik/ui-shared/notifications/notificationUtilities';

import Text from 'core/components/Text';
import Box from 'core/components/Box';
import Spinner from 'core/components/Spinner';
import NotificationUsageListContainer from './NotificationUsageListContainer';

const UsageLinkContainer = styled(Box)`
  cursor: pointer;
  padding: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.backgrounds.specialMuted}};
  }
`;

@storeLoader('$alerting.policyCollection', '$mitigations.methodsCollection', '$syn.tests', '$mkp.tenants')
@observer
export default class NotificationUsageList extends Component {
  static propTypes = {
    notificationModel: PropTypes.object.isRequired
  };

  state = {
    detailsDialogOpen: false
  };

  get notificationUsageMap() {
    const { $alerting, $mitigations, notificationModel, $syn, $mkp } = this.props;
    const { policyCollection } = $alerting;
    const { methodsCollection } = $mitigations;

    return getChannelUsageMap(policyCollection, methodsCollection, $syn.tests, $mkp.tenants, notificationModel);
  }

  get usagesLoaded() {
    const { $alerting, $mitigations, $syn } = this.props;
    const { policyCollection } = $alerting;
    const { methodsCollection } = $mitigations;
    const { tests } = $syn;

    return tests.hasFetched && policyCollection.hasFetched && methodsCollection.hasFetched;
  }

  toggleDetailDialog = () => {
    this.setState((state) => ({
      detailsDialogOpen: !state.detailsDialogOpen
    }));
  };

  get usageTexts() {
    if (!this.usagesLoaded) {
      return [];
    }

    const usages = this.notificationUsageMap;
    const hasPolicyUsage = Object.keys(usages.policies).length > 0;
    const hasInsightUsage = usages.insights.length > 0;
    const hasInsightFamilyUsage = usages.insightFamilies.length > 0;
    const hasMitigationMethodUsage = usages.mitigationMethods.length > 0;
    const hasSynthPolicies = Object.keys(usages.synthTest).length > 0;
    const hasSubpolicies = Object.keys(usages.subpolicies).length > 0;

    const texts = [];

    if (hasPolicyUsage) {
      texts.push(`Policies (${Object.keys(usages.policies).length})`);
    }

    if (hasInsightUsage) {
      texts.push(`Insights (${usages.insights.length})`);
    }

    if (hasInsightFamilyUsage) {
      texts.push(`Insight Families (${usages.insightFamilies.length})`);
    }

    if (hasMitigationMethodUsage) {
      texts.push(`Mitigation Methods (${usages.mitigationMethods.length})`);
    }

    if (hasSynthPolicies) {
      texts.push(`Synthetic Tests (${Object.keys(usages.synthTest).length})`);
    }

    if (hasSubpolicies) {
      texts.push(`Tenant policies (${Object.keys(usages.subpolicies).length})`);
    }

    return texts;
  }

  render() {
    const { notificationModel, inline, asTable, addDeleteColumn } = this.props;
    const { detailsDialogOpen } = this.state;

    return (
      <>
        <NotificationUsageListContainer
          notificationModel={notificationModel}
          asTable={asTable}
          addDeleteColumn={addDeleteColumn}
          usages={this.notificationUsageMap}
          isOpen={detailsDialogOpen}
          onClose={this.toggleDetailDialog}
        />
        {!asTable && (
          <Box display={inline ? 'inline-block' : 'block'}>
            {this.usageTexts.length > 0 ? (
              <UsageLinkContainer onClick={this.toggleDetailDialog} className={Classes.TEXT_OVERFLOW_ELLIPSIS}>
                {this.usageTexts.map((usageText, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Text key={idx}>
                    {usageText}
                    {this.usageTexts.length - 1 > idx ? ', ' : ''}
                  </Text>
                ))}
              </UsageLinkContainer>
            ) : (
              ''
            )}
            {this.usageTexts.length === 0 && this.usagesLoaded ? <Text ml="4px">Currently not in use</Text> : ''}
            {!this.usagesLoaded ? <Spinner size={16} ml="36px" /> : null}
          </Box>
        )}
      </>
    );
  }
}
