import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Box, Callout, Flex, Menu, MenuDivider, MenuItem, Text } from 'core/components';
import { showSuccessToast } from 'core/components/toast';
import { ReactComponent as GlobalAgentIcon } from 'app/assets/agents/global_agent_icon.svg';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';
import { generateSpoofUrl } from 'app/util/generateSpoofUrl';

@inject('$app', '$auth', '$dictionary', '$mkp', '$sudo')
@withRouter
@observer
class UserMenu extends Component {
  isActiveTheme(theme) {
    const { $app, $auth } = this.props;
    const currentThemeColor = $app.darkThemeEnabled ? 'dark' : 'light';
    const themeSetting = $auth.getActiveUserProperty('themeSetting');

    return themeSetting ? themeSetting === theme : theme === currentThemeColor;
  }

  render() {
    const { $app, $auth, $dictionary, $sudo, $mkp, history, match } = this.props;
    const isSudoer = !$auth.isDemoUser && ($auth.hasSudo || $auth.isSpoofed);
    const currentlySpoofing = $auth.isSpoofed && !$auth.isLandlordSpoofed;
    let spoofRole = '';
    // we seem to be missing get('userLevels') when trial expires
    if ($dictionary.get('userLevels')) {
      spoofRole = $dictionary.get('userLevels')[$auth.getActiveUserProperty('user_level')];
    }

    const spoofDiv = (
      <Callout intent="warning">
        <Flex justifyContent="space-between" gap={1}>
          <Text as="div" mb="1px" small fontWeight="bold">
            {$auth.getActiveUserProperty('company.company_name')}
          </Text>
          <Text as="div" small mr={1} fontWeight="bold">
            {$auth.getActiveUserProperty('company_id')}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" gap={1}>
          <Text as="div" muted small>
            {$auth.getActiveUserProperty('user_email')}
          </Text>
          <Text as="div" muted small>
            {spoofRole}
          </Text>
        </Flex>
      </Callout>
    );

    return (
      <Menu>
        {!currentlySpoofing && (
          <Box mb={1} mt="4px">
            <Text fontSize="large" as="div" fontWeight="bold" ml={1}>
              {$auth.getActiveUserProperty('user_full_name')}
            </Text>
            <Flex justifyContent="space-between" gap={1} ml={1}>
              <Text as="div" muted small>
                {$auth.getActiveUserProperty('user_email')}
              </Text>
              <Text as="div" muted small mr={1}>
                {$auth.getActiveUserProperty('id')}
              </Text>
            </Flex>
          </Box>
        )}

        {isSudoer && (
          <>
            {currentlySpoofing && (
              <>
                {/* Since we are spoofing, let's show the user who we actually are here */}
                <Text fontSize="large" as="div" fontWeight="bold" ml={1} mt="4px">
                  {$auth.getUnderlyingUserProperty('user_full_name')}
                </Text>
                <Flex justifyContent="space-between" gap={1} mb={1}>
                  <Text as="div" muted small ml={1}>
                    {$auth.getUnderlyingUserProperty('user_name')}
                  </Text>
                  <Text as="div" muted small mr={1}>
                    {$auth.getUnderlyingUserProperty('id')}
                  </Text>
                </Flex>

                <Text small ml={1} width="100%" color="warning">
                  You are currently spoofing
                </Text>
                <MenuItem text={spoofDiv} onClick={() => $sudo.clearSpoofSession({ reload: true })} />
              </>
            )}

            {/* NOTE: Admin and Release notes and Asset Migration, spoof url sections stay unchanged as they are internal-only */}
            <MenuItem text="Admin" icon={GlobalAgentIcon} onClick={() => $sudo.setSudoOpen(true)} />
            {!currentlySpoofing && (
              <MenuItem text="Companies" icon={GlobalAgentIcon} onClick={() => history.push('/v4/admin')} />
            )}
            {isSudoer && $app.version && (
              <MenuItem text={`Version ${$app.version} Notes`} icon={GlobalAgentIcon} onClick={$app.openReleaseNotes} />
            )}
            {isSudoer && (
              <>
                <MenuItem
                  text="Query Editor"
                  icon={GlobalAgentIcon}
                  onClick={() => history.push('/v4/core/query-editor')}
                />
                <MenuItem
                  text="Asset Migration"
                  icon={GlobalAgentIcon}
                  onClick={() => history.push('/v4/asset-migration')}
                />
                <CopyToClipboardButton
                  text={generateSpoofUrl($auth, { persist: true, match })}
                  intent=""
                  onCopyComplete={() => showSuccessToast('URL copied')}
                >
                  <MenuItem text="Generate Spoof URL" icon={GlobalAgentIcon} shouldDismissPopover={false} />
                </CopyToClipboardButton>
                <MenuItem
                  text="Clear Dictionary Cache"
                  icon={GlobalAgentIcon}
                  onClick={() => $dictionary.clearDictionaryCache()}
                />
                <MenuItem
                  text="KDE Debug Mode"
                  icon={GlobalAgentIcon}
                  color={$app.debugModeEnabled ? 'warning' : undefined}
                  onClick={$app.toggleDebugMode}
                />
              </>
            )}
            <MenuDivider />
          </>
        )}
        {/* END NOTE: Admin and Release notes and Asset Migration, spoof url sections stay unchanged as they are internal-only */}

        {!$auth.isDemoUser && (
          <MenuItem text="User Profile" icon="settings" onClick={() => history.push('/v4/profile/general')} />
        )}
        {!$auth.isDemoUser && (
          <MenuItem
            text="Authentication"
            icon="key"
            data-testid="authentication-menu-item"
            onClick={() => history.push('/v4/profile/auth')}
          />
        )}
        {!$auth.isDemoUser && (
          <MenuItem text="Recent Exports" icon="import" onClick={() => history.push('/v4/recent-exports')} />
        )}

        <MenuDivider />

        <MenuItem text="Theme" icon="contrast" popoverProps={{ openOnTargetFocus: false }}>
          <MenuItem
            text="Auto (OS)"
            onClick={() => $app.toggleDarkTheme({ forceTheme: 'auto' })}
            active={this.isActiveTheme('auto')}
          />
          <MenuItem
            text="Light"
            onClick={() => $app.toggleDarkTheme({ forceTheme: 'light' })}
            active={this.isActiveTheme('light')}
          />
          <MenuItem
            text="Dark"
            onClick={() => $app.toggleDarkTheme({ forceTheme: 'dark' })}
            active={this.isActiveTheme('dark')}
          />
        </MenuItem>

        {!$auth.isDemoUser && (
          <MenuItem text="Chart Colors" icon="highlight" onClick={() => history.push('/v4/profile/viz')} />
        )}

        {!$auth.isDemoUser && !$app.isSubtenant && (
          <MenuItem
            text="UI Layout Preferences"
            icon="page-layout"
            onClick={() => history.push('/v4/settings/layout')}
          />
        )}

        <MenuDivider />

        {$auth.isLandlordSpoofed && (
          <MenuItem
            intent="warning"
            text={<span>Exit Tenant ({$auth.getActiveUserProperty('userGroup.name')}) View</span>}
            onClick={() => $mkp.clearSpoofSession({ reload: true })}
          />
        )}
        <MenuItem
          text="Log Out"
          icon="log-out"
          onClick={() => {
            $auth.store.logout();
          }}
        />
      </Menu>
    );
  }
}

export default withRouter(UserMenu);
