import styled, { css } from 'styled-components';

export const TinyWindowIcon = styled.div`
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px dashed ${(props) => (props.comparison ? '#54108F' : '#3c64c7')};
  background: ${(props) => (props.comparison ? '#E6DBEF' : '#f0f4fe')};

  ${(props) =>
    props.theme.name === 'dark' &&
    css`
      border: 1px dashed ${props.comparison ? '#927CD7' : '#598EEE'};
      background: ${props.comparison ? '#393545' : '#323A49'};
    `}
`;
