import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { FormGroup, Intent } from '@blueprintjs/core';
import { Text } from 'core/components';

// Use for when you have 1 or more inputs on a single line with each other or labels

const ErrorGroup = ({ errors, style }) =>
  errors.length ? (
    <FormGroup
      inline
      intent={Intent.DANGER}
      helperText={errors.map((error) => (
        <Text key={`error-${error}`} mr="2px">
          {error}
        </Text>
      ))}
      // We want to minimize how obtrusive this form group is
      style={{ margin: '0 4px', lineHeight: '10px', ...style }}
    />
  ) : null;

const shouldShowFieldErrors = (field, showPristineErrors) =>
  !field.valid && (showPristineErrors || field.dirty || !field.pristine);

const SingleLineErrors = (props) => {
  const { fields = [], formStyle, showPristineErrors = false, inline = false } = props;

  // Merge all errors into a single form group
  if (inline) {
    const errors = fields.reduce((acc, field) => {
      if (shouldShowFieldErrors(field, showPristineErrors)) {
        acc.push(...field.errors);
      }
      return acc;
    }, []);
    return <ErrorGroup errors={errors} style={formStyle} />;
  }

  // Separate errors per field
  return fields.map((field) => (
    <Fragment key={field._id}>
      {shouldShowFieldErrors(field, showPristineErrors) && <ErrorGroup errors={field.errors} style={formStyle} />}
    </Fragment>
  ));
};

export default observer(SingleLineErrors);
