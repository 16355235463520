import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { Card } from 'core/components';

const DeckCard = styled(Card)`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: none !important; // override bp4-card transition that interferes with moving

  .drag-header {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &:hover {
      background-color: ${themeGet('colors.decks.headerHover')};
    }
  }

  .widget-tooltip {
    visibility: hidden;
  }

  &:hover {
    .widget-tooltip {
      visibility: visible;
    }

    &.react-resizable {
      .react-resizable-handle {
        position: absolute;
        border: solid ${themeGet('colors.decks.handles')};
        padding: 10px;
        margin: 4px;
        z-index: 10;

        &:hover {
          border-color: ${themeGet('colors.decks.handlesHover')};
        }

        &.react-resizable-handle-se {
          cursor: se-resize;
          bottom: -2px;
          right: -2px;
          border-width: 0px 2px 2px 0px;
          border-radius: 0 0 3px 0;
        }

        &.react-resizable-handle-sw {
          cursor: sw-resize;
          bottom: -2px;
          left: -2px;
          border-width: 0px 0px 2px 2px;
          border-radius: 0 0 3px 0;
        }
      }
    }
  }

  &.resizing {
    border: 2px solid ${themeGet('colors.decks.cardBorderDragging')};

    .react-resizable-handle {
      border-color: ${themeGet('colors.decks.handles')};
    }
  }

  &.react-draggable-dragging {
    border: 2px solid ${themeGet('colors.decks.cardBorderDragging')};

    > div {
      opacity: 0.25;
      /* display: none; */
    }
  }
`;

export default DeckCard;
