import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { PopoverInteractionKind } from '@blueprintjs/core';

import { Text, Tag, Flex, Box, Popover, Menu, MenuItem, MenuDivider, Lookup, Heading } from 'core/components';
import ASNLogo from 'app/components/asn/ASNLogo';
import AsnLink from 'app/components/links/AsnLink';
import { lookupAsnPrivateReserved } from '@kentik/ui-shared/util/asn';

@inject('$auth', '$lookups', '$app')
@withRouter
@observer
export default class ASNRenderer extends Component {
  static defaultProps = {
    showLogo: false,
    showLinks: false,
    subtextCountry: false,
    showNameAsLink: false,
    showAsnAsTag: true
  };

  state = {
    asn: undefined
  };

  static getDerivedStateFromProps(props) {
    const { asn, name: propName, country: propCountry, description } = props;
    const derivState = { asn };

    // Only push props into state if they're populated!
    if (propName) {
      derivState.name = propName;
    }
    if (propCountry) {
      derivState.country = propCountry;
    }

    // If we can derive name/country from description, do so
    if (description) {
      derivState.name = description.slice(0, -3);
      derivState.country = description.slice(-2);
    }

    return derivState;
  }

  componentDidMount() {
    const { name, description } = this.props;
    if (!description && !name) {
      this.doLookup();
    }
  }

  componentDidUpdate(prevProps) {
    const { asn, name, description } = this.props;
    if (prevProps.asn !== asn && !description && !name) {
      this.doLookup();
    }
  }

  doLookup() {
    const { $lookups, asn } = this.props;

    const privateReservedName = lookupAsnPrivateReserved(parseInt(asn));
    if (privateReservedName !== null) {
      const name = privateReservedName.slice(0, -3);
      const country = privateReservedName.slice(-2);
      this.setState({ name, country });
    } else {
      $lookups.asData(asn, { limit: 3, promiseKey: asn }).then((res) => {
        const full_name = res.length
          ? res
              .filter(({ value }) => value === asn.toString())
              .map(({ label }) => label)
              .pop()
          : '---,--';

        const name = full_name.slice(0, -3);
        const country = full_name.slice(-2);
        this.setState({ name, country });
      });
    }
  }

  handleOnClick = (e, path, options) => {
    const { history } = this.props;
    e.preventDefault();
    e.stopPropagation();
    history.push(path, options);
  };

  get menuLinks() {
    const { $auth, showLinks, asn } = this.props;

    const links = {
      kmi: (
        <MenuItem
          intent="primary"
          key="kmi"
          text="Kentik Market Intelligence"
          href={`/v4/service-provider/market-intel/network/${asn}`}
          onClick={(e) => this.handleOnClick(e, `/v4/service-provider/market-intel/network/${asn}`)}
        />
      ),
      pdb: (
        <MenuItem
          intent="primary"
          key="pdb"
          text="PeeringDB record"
          href={`/v4/core/quick-views/asn/${asn}/peeringdb`}
          onClick={(e) => this.handleOnClick(e, `/v4/core/quick-views/asn/${asn}/peeringdb`)}
        />
      ),
      quick: (
        <MenuItem
          intent="primary"
          key="quick"
          text="ASN Traffic View"
          href={`/v4/core/quick-views/asn/${asn}`}
          onClick={(e) => this.handleOnClick(e, `/v4/core/quick-views/asn/${asn}`)}
        />
      )
    };

    const privateReservedName = lookupAsnPrivateReserved(parseInt(asn));
    if ($auth.hasPermission('trafficCosts.enabled', { overrideForSudo: false }) && privateReservedName === null) {
      links.trafficcost = (
        <MenuItem
          intent="primary"
          key="trafficcost"
          text="View Traffic Costs"
          href="/v4/edge/traffic-costs"
          onClick={(e) => this.handleOnClick(e, '/v4/edge/traffic-costs', { filterField: 'src_as', filterValue: asn })}
        />
      );
    }

    if (showLinks) {
      if (Array.isArray(showLinks)) {
        return showLinks.map((key) => links[key]);
      }
      return Object.values(links);
    }
    return [];
  }

  render() {
    const {
      $app,
      showLogo,
      subtextCountry,
      showLinks,
      showNameAsLink,
      showAsnAsTag,
      largeTag,
      iconProps,
      level,
      ...rest
    } = this.props;
    const { asn, name, country } = this.state;

    const shouldShowLinks = !$app.isSubtenant && showLinks;
    return (
      <Flex display="inline-flex" alignItems="center" gap={showLogo ? '8px' : '4px'} {...rest}>
        {showLogo && <ASNLogo asn={asn} minWidth="20px" maxWidth="80px" height="20px" {...iconProps} />}
        {subtextCountry && (
          <Flex flexDirection="column" justifyContent="center">
            {level && (
              <Heading level={level} mb={0}>
                {name}
              </Heading>
            )}
            {!level && (showNameAsLink ? <AsnLink asn={asn}>{name}</AsnLink> : <Text>{name}</Text>)}
            <Text muted>
              <Lookup lookup="country" value={country} />
            </Text>
          </Flex>
        )}
        {!subtextCountry && level && (
          <Heading level={level} mb={0}>
            {name}
          </Heading>
        )}
        {!subtextCountry &&
          !level &&
          (showNameAsLink ? <AsnLink asn={asn}>{name}</AsnLink> : <Text ellipsis>{name}</Text>)}

        {!showAsnAsTag && <Text muted>({asn})</Text>}

        {showAsnAsTag && (
          <div
            onClick={(evt) => {
              evt.stopPropagation();
            }}
          >
            <Popover
              position="right"
              interactionKind={PopoverInteractionKind.CLICK}
              minimal={false}
              usePortal
              modifiers={{
                flip: { enabled: true },
                preventOverflow: { enabled: true, boundariesElement: 'window' }
              }}
              disabled={!shouldShowLinks}
              target={
                <Tag
                  interactive={shouldShowLinks}
                  round
                  large={largeTag}
                  minimal
                  intent="primary"
                  rightIcon={shouldShowLinks ? 'caret-down' : ''}
                >
                  AS{asn}
                </Tag>
              }
              content={
                <Box style={{ maxWidth: 500, maxHeight: 250 }} overflow="auto">
                  <Menu>
                    {country && (
                      <>
                        <Box p={1}>
                          <Text fontStyle="italic" pr={1}>
                            Registration Country:
                          </Text>
                          <Text fontWeight="bold">
                            <Lookup lookup="country" value={country} />
                          </Text>
                        </Box>
                        <MenuDivider />
                      </>
                    )}
                    {this.menuLinks}
                  </Menu>
                </Box>
              }
            />
          </div>
        )}
      </Flex>
    );
  }
}
