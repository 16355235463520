import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { Classes, RangeSlider as BlueprintRangeSlider, Slider as BlueprintSlider } from '@blueprintjs/core';
import { Box, Flex, Text } from 'core/components';
import size from 'core/components/utils/size';

const StyledSlider = styled(BlueprintSlider)`
  ${size};
  ${space};

  .${Classes.SLIDER_LABEL} {
    text-align: center;
  }

  .description {
    margin-left: 0;
    font-size: 9px;
  }

  .${Classes.SLIDER_AXIS} {
    .description {
      display: none;
    }
  }
`;

const StyledRangeSlider = styled(BlueprintRangeSlider)`
  ${size};
  ${space};

  .${Classes.SLIDER_LABEL} {
    text-align: center;
  }

  .description {
    margin-left: 0;
    font-size: 9px;
  }

  .${Classes.SLIDER_AXIS} {
    .description {
      display: none;
    }
  }
`;

const Slider = (props) => {
  const { defaultValue, multi, showInputs, style, ...rest } = props;
  let { value } = props;
  value = value || defaultValue;
  const Component = multi ? StyledRangeSlider : StyledSlider;
  return (
    <Flex flexDirection="column">
      {multi && showInputs && (
        <Flex mb={1}>
          <Flex alignItems="center" gap="4px" width="50%" pr={2}>
            <Text>Min</Text>
            <input type="number" value={value[0]} style={{ width: '100%' }} disabled />
          </Flex>
          <Flex alignItems="center" gap="4px" width="50%" pl={2}>
            <Text>Max</Text>
            <input type="number" value={value[1]} style={{ width: '100%' }} disabled />
          </Flex>
        </Flex>
      )}
      <Box style={style}>
        <Component {...rest} defaultValue={defaultValue} value={value} />
      </Box>
    </Flex>
  );
};

export default Slider;
