/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Box, Flex, Icon } from 'core/components';

const Column = ({ field, label, pl, onClick, sortState, justifyContent = 'flex-end' }) => (
  <Box as="th" verticalAlign="bottom">
    <Flex
      pl={pl}
      fontSize="small"
      alignItems="flex-end"
      justifyContent={justifyContent}
      cursor="pointer"
      data-column={field}
      onClick={onClick}
    >
      <Box>{label}</Box>
      {sortState.field === field ? (
        <Icon
          color={sortState.field === field ? 'primary' : undefined}
          icon={sortState.direction === 'asc' ? 'chevron-up' : 'chevron-down'}
        />
      ) : (
        <Box width={16}> </Box>
      )}
    </Flex>
  </Box>
);

export default Column;
