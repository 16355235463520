import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Field, InputGroup, Select, Checkbox } from 'core/form';
import { Flex, Link, Text } from 'core/components';
import AdditionalMetadataRoles from 'app/views/setup/tasks/cloud/aws/AdditionalMetadataRoles';

import SamplingPanelWrapper from '../sampling';
import AccessCheckButton from '../validation/AccessCheckButton';

@inject('$cloudExportWizard', '$clouds')
@observer
class AwsExportConfigFields extends Component {
  state = {
    checkingRole: false,
    roleAccessValid: undefined,
    roleAccessMsg: null,

    checkingRegion: false,
    regionAccessValid: undefined,
    regionAccessMsg: null,

    checkingBucket: false,
    bucketAccessValid: undefined,
    bucketAccessMsg: null
  };

  handleCheckRoleAccess = () => {
    const {
      $cloudExportWizard: { form },
      $clouds
    } = this.props;
    this.setState({ roleAccessValid: null, roleAccessMsg: null, checkingRole: true }, () =>
      $clouds.validateAwsRole(form.getValues()).then((res) => {
        this.setState({ roleAccessValid: res.success, checkingRole: false, roleAccessMsg: res.message });
      })
    );
  };

  getEntryResults(res) {
    // use set to keep results unique
    const entrySet = new Set();

    res.forEach((entry) => {
      // if we're sending $clouds.validateAwsRegion api request without 'all' set to false, we get back results for all exports for this cloud provider
      // in those cases, entry will have a results obj containing actual success: bool and message
      const { results } = entry;
      if (results) {
        results.forEach((result) => {
          entrySet.add(result);
        });
      } else {
        //  $clouds.validateAwsRegion with all: false, entry itself should contain success: bool and message
        entrySet.add(entry);
      }
    });

    // return as array to allow filter/map etc.
    return Array.from(entrySet);
  }

  handleCheckRegionAccess = () => {
    const {
      $cloudExportWizard: { form },
      $clouds
    } = this.props;
    const formValues = form.getValues();
    this.setState({ regionAccessValid: null, regionAccessMsg: null, checkingRegion: true }, () =>
      $clouds.validateAwsRegion({ force: true, all: false, ...formValues }).then((res) => {
        // TODO this is currently broken in prod, no error messages get shown
        // however the logic of validateAwsRegion seems to be wrong for this field
        // why do we show results for all existing cloud exports?
        if (Array.isArray(res)) {
          const entryResults = this.getEntryResults(res);

          if (entryResults.every((entry) => entry.success)) {
            this.setState({ regionAccessValid: true, checkingRegion: false });
          } else {
            const failureMessages = new Set();

            this.setState({
              checkingRegion: false,
              regionAccessValid: false,
              regionAccessMsg: entryResults
                .filter((entry) => !entry.success)
                .map((entry) => {
                  if (!failureMessages.has(entry.message)) {
                    failureMessages.add(entry.message);
                    return <div key={entry.fn}>{entry.message}</div>;
                  }
                  return null;
                })
            });
          }
        } else {
          this.setState({
            regionAccessValid: !!res.success,
            regionAccessMsg: res.message,
            checkingRegion: false
          });
        }
      })
    );
  };

  handleCheckBucketAccess = () => {
    const {
      $cloudExportWizard: { form },
      $clouds
    } = this.props;
    this.setState({ bucketAccessValid: null, bucketAccessMsg: null, checkingBucket: true }, () =>
      $clouds.validateAwsBucket(form.getValues()).then((res) => {
        const validationResponse = res.reduce(
          (response, item) => {
            if (item.success === false) {
              // if we encounter a failure in any step, fail the whole thing and add a message to the bank
              response.success = false;
              response.message = `${response.message} ${item.service}.${item.fn}: ${item.message}`;
            }

            return response;
          },
          { success: true, message: '' }
        );

        this.setState({
          bucketAccessValid: validationResponse.success,
          bucketAccessMsg: validationResponse.message,
          checkingBucket: false
        });
      })
    );
  };

  renderDefaultFields(isFlowLogCollectionEnabled) {
    const { roleAccessValid, roleAccessMsg, regionAccessValid, regionAccessMsg, checkingRole, checkingRegion } =
      this.state;
    const flowCopy = ', IAM Role ARN, S3 bucket name and (optionally) bucket prefix';
    const defaultCopy = `Use the form below to tell us the AWS region ${
      isFlowLogCollectionEnabled ? flowCopy : 'and IAM Role ARN'
    }`;

    return (
      <>
        <Text as="p" pb={2} muted>
          {defaultCopy}.
          <br />
          <Link to="https://kb.kentik.com/v0/Bd06.htm" blank>
            Click here to learn more.
          </Link>
        </Text>

        <Flex flexDirection="column">
          <Field name="properties.aws_iam_role_arn" placeholder="arn:aws:iam::unique-id/your-role" width="100%" large>
            <InputGroup />
          </Field>
        </Flex>

        <Field name="properties.aws_iam_role_arn_is_org" showLabel={false} width="100%" large mt={2}>
          <Checkbox />
        </Field>

        <Flex flexDirection="column" mb={5}>
          <AccessCheckButton
            formField="properties.aws_iam_role_arn"
            loading={checkingRole}
            validationFn={this.handleCheckRoleAccess}
            validState={roleAccessValid}
            message={roleAccessMsg}
            btnText="Verify Role"
          />
        </Flex>

        <Flex justifyContent="space-between">
          <Field name="properties.aws_region" large mb={2}>
            {/* test id for CloudExportConfigWizard jest tests */}
            <Select showFilter data-testid="properties.aws_region" />
          </Field>
        </Flex>

        <Flex flexDirection="column" mb={5}>
          <AccessCheckButton
            formField="properties.aws_region"
            loading={checkingRegion}
            validationFn={this.handleCheckRegionAccess}
            validState={regionAccessValid}
            message={regionAccessMsg}
            btnText="Verify Region"
          />
        </Flex>
      </>
    );
  }

  renderFlowFields() {
    const { bucketAccessValid, bucketAccessMsg, checkingBucket } = this.state;
    return (
      <>
        <Field name="properties.aws_bucket" large flex={1}>
          <InputGroup width="100%" />
        </Field>
        <Field name="properties.aws_bucket_prefix" large flex={1}>
          <InputGroup width="100%" />
        </Field>

        <AccessCheckButton
          formField="properties.aws_bucket"
          loading={checkingBucket}
          validationFn={this.handleCheckBucketAccess}
          validState={bucketAccessValid}
          message={bucketAccessMsg}
          btnText="Verify S3 Bucket"
        />
      </>
    );
  }

  renderLeftColumn() {
    const { $cloudExportWizard } = this.props;
    const { isFlowLogCollectionEnabled } = $cloudExportWizard;

    return (
      <Flex flexDirection="column" gap="1" flex={1} mb={2}>
        <Flex flexDirection="column">
          {this.renderDefaultFields(isFlowLogCollectionEnabled)}
          {isFlowLogCollectionEnabled && this.renderFlowFields()}
          <AdditionalMetadataRoles />
          {isFlowLogCollectionEnabled && <SamplingPanelWrapper />}
        </Flex>
      </Flex>
    );
  }

  renderRightColumn() {
    return (
      <Flex flex={1} flexDirection="column">
        <Flex height={750} flexWrap="wrap" justifyContent="flex-end" />
      </Flex>
    );
  }

  render() {
    return (
      <Flex gap={1}>
        {this.renderLeftColumn()}
        {this.renderRightColumn()}
      </Flex>
    );
  }
}

export default AwsExportConfigFields;
