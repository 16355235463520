import { baseFields } from './baseFields';
import { pingFields } from './pingFields';
import { flowBaseFields } from './flowBaseFields';

const cityOptions = {
  name: 'City Test'
};

const cityFields = {
  ...baseFields,
  ...pingFields,
  ...flowBaseFields
};

export { cityOptions, cityFields };
