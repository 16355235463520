import React from 'react';
import { observer } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';
import { Box } from 'core/components';

const UnsavedChangesCallout = ({ sourceLink, onSaveChanges, onCancel, onDismiss }) => {
  const { model, type } = sourceLink;

  let modelType = '';
  if (type === 'view') {
    modelType = 'Saved View';
  } else if (type === 'dashboard') {
    modelType = 'View Panel';
  }

  return (
    <Box className="pt-callout pt-icon-warning-sign">
      <small>You have applied changes to your query that have not been persisted to your {modelType}.</small>
      <Button
        loading={model.isRequestActive('updating')}
        intent={Intent.PRIMARY}
        className="pt-small pt-medium"
        text="Save Changes"
        onClick={onSaveChanges}
        style={{ marginLeft: 8 }}
      />
      <Button
        loading={model.isRequestActive('updating')}
        intent={Intent.NONE}
        className="pt-small"
        text={type === 'view' ? 'Revert' : 'Cancel'}
        onClick={onCancel}
        style={{ marginLeft: 8 }}
      />
      {type === 'view' && (
        <Button
          loading={model.isRequestActive('updating')}
          intent={Intent.WARNING}
          className="pt-small"
          text="Separate"
          onClick={onDismiss}
          style={{ marginLeft: 8 }}
        />
      )}
    </Box>
  );
};

export default observer(UnsavedChangesCallout);
