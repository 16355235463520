import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';
import { MenuItem } from 'core/components';

@inject('$app', '$explorer')
@observer
export default class ViewInExplorerMenuItem extends Component {
  static defaultProps = {
    dataview: {}
  };

  handleOnClick = () => {
    const { $explorer, dataview, query, openInNewWindow } = this.props;
    const { hash } = dataview;
    const selectedQuery = query || get(dataview, 'queryBuckets.selectedQuery');

    if (hash) {
      $explorer.loadHash(hash);
    }

    if (selectedQuery) {
      $explorer.navigateToExplorer(selectedQuery, openInNewWindow);
    }
  };

  render() {
    const { $app, ...rest } = this.props;
    return (
      !$app.isExport && (
        <MenuItem icon="series-search" text="View in Data Explorer" onClick={this.handleOnClick} {...rest} />
      )
    );
  }
}
