import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Flex, FlexColumn, Text, Box, Grid, Checkbox as CoreCheckbox } from 'core/components';

import Model from 'core/model/Model';
import MultiSlider, { Handle } from 'core/components/MultiSlider';

import {
  Field,
  FormComponent,
  RadioGroup,
  TextArea,
  Checkbox,
  InputGroup,
  SubmitButton,
  CancelButton
} from 'core/form';
import { KMI_INSIGHT_TYPES } from 'app/util/constants';

import { MarketFilterForm } from 'app/views/serviceProvider/marketIntel/Filter/MarketFilter';

const fields = {
  includeGeo: {
    label: 'Include Geo Filter'
  },
  'types._1': {
    label: 'Market Entrance'
  },
  'types._2': {
    label: 'Market Exit'
  },
  'types._3': {
    label: 'Ranking Gain'
  },
  'types._4': {
    label: 'Ranking Loss'
  },
  'types._11': {
    label: 'Start Prefix Origination'
  },
  'types._12': {
    label: 'Stop Prefix Origination'
  },
  'types._13': {
    label: 'Increase Prefix Origination'
  },
  'types._14': {
    label: 'Decrease Prefix Origination'
  },
  'types._101': {
    label: 'Customer Gain'
  },
  'types._102': {
    label: 'Customer Loss'
  },
  'types._103': {
    label: 'Customer Routing Gain'
  },
  'types._104': {
    label: 'Customer Routing Loss'
  },
  'types._201': {
    label: 'Provider Gain'
  },
  'types._202': {
    label: 'Provider Loss'
  },
  'types._203': {
    label: 'Provider Routing Gain'
  },
  'types._204': {
    label: 'Provider Routing Loss'
  },
  magnitude: {
    label: 'Minimum Magnitude'
  },
  lookback: {
    label: 'Lookback range',
    rules: 'required',
    options: [
      {
        label: 'past 24h',
        value: 1
      },
      {
        label: 'past 7 Days',
        value: 7
      },
      {
        label: 'past 30 Days',
        value: 30
      }
    ]
  },
  ip: {
    label: 'IP Address Family',
    rules: 'required',
    options: [
      {
        label: 'IPv4',
        value: 'v4'
      },
      {
        label: 'IPv6',
        value: 'v6'
      }
    ],
    defaultValue: 'v4'
  },
  market: {
    label: 'Geo Market',
    defaultValue: 'planet$earth'
  },
  widgetTitle: {
    label: 'Title',
    placeholder: 'Add a custom widget title'
  },
  asn: {
    label: 'ASN(s)',
    placeholder: 'Enter comma-separated list of ASNs, e.g. 6123, 3124',
    rules: 'commaSeparatedASNs'
  }
};

const DEFAULT_CONFIG = {
  ip: 'v4',
  lookback: 1,
  includeGeo: true,
  types: {
    _1: true,
    _2: true,
    _3: true,
    _4: true,
    _101: true,
    _102: true,
    _103: false,
    _104: false,
    _201: false,
    _202: false,
    _203: false,
    _204: false
  },
  magnitude: [1],
  market: 'planet$earth',
  title: ''
};

export function getDefaultConfig(props = {}) {
  const { asn } = props;
  const config = DEFAULT_CONFIG;
  if (asn) {
    config.types._201 = true;
    config.types._202 = true;
    config.asn = asn.toString();
  }
  return config;
}

@inject('$auth', '$marketIntel', '$users')
@observer
export default class InsightsConfigForm extends Component {
  state = {
    model: undefined,
    config: undefined
  };

  static getDerivedStateFromProps(props, state) {
    const { $auth, config } = props;

    if (!state.config) {
      const landOnDeck = $auth.getActiveUserProperty('defaultLandingPageType') === 'observationDeck';

      if (config) {
        return { config, model: new Model(config), landOnDeck, setLandOnDeck: landOnDeck };
      }

      const { marketIntelConfig } = $auth.userSettings;
      if (marketIntelConfig) {
        return {
          config: marketIntelConfig,
          model: new Model(marketIntelConfig),
          landOnDeck,
          setLandOnDeck: landOnDeck
        };
      }
      return { config: DEFAULT_CONFIG, model: new Model(DEFAULT_CONFIG), landOnDeck, setLandOnDeck: landOnDeck };
    }
    return null;
  }

  handleSave = (config) => {
    const { $users, onClose } = this.props;
    const { model, landOnDeck, setLandOnDeck } = this.state;

    model.set(config);
    if (!landOnDeck && setLandOnDeck) {
      $users.updateUserProfile(
        { defaultLandingPageType: 'observationDeck' },
        { toast: false, label: 'Default Landing Page' }
      );
    }
    if (onClose) {
      onClose({ config });
    }
  };

  handleCancel = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose({});
    }
  };

  handleLandingCheck = (value) => {
    this.setState({ setLandOnDeck: value });
  };

  render() {
    const { $marketIntel, widgetize, changeDefault, showTitle = true } = this.props;
    const { model, landOnDeck, setLandOnDeck } = this.state;

    if (!model) {
      return null;
    }

    const { marketName } = $marketIntel.filterObj;

    return (
      <FormComponent
        fields={fields}
        model={model}
        options={{ name: 'Configure Insights' }}
        handleSave={this.handleSave}
      >
        {({ form }) => (
          <FlexColumn justifyContent="space-between" pb={2}>
            {showTitle && (
              <Text as="div" fontWeight="heavy" large pl={2} mt={2}>
                Configure Insights
              </Text>
            )}

            <FlexColumn flex={1} mt={showTitle ? 2 : 1} px={2} alignItems="flex-start">
              {widgetize && (
                <>
                  <Field name="widgetTitle" alignSelf="stretch">
                    <InputGroup />
                  </Field>
                  <Field name="asn" py={1} alignSelf="stretch">
                    <TextArea rows={2} fill />
                  </Field>
                </>
              )}

              <Flex>
                <Field name="includeGeo" showLabel={false} mb="4px">
                  <Checkbox
                    showBoxLabel={false}
                    labelElement={
                      <Text fontSize={12} fontWeight={600}>
                        Use Geo Market Filter
                      </Text>
                    }
                    m={0}
                  />
                </Field>
                {!widgetize && form.getField('includeGeo').getValue() && (
                  <Text small muted pl={1}>
                    {`(${marketName})`}
                  </Text>
                )}
              </Flex>
              {widgetize && (
                <>
                  <Field
                    name="market"
                    disabled={!form.getField('includeGeo').getValue()}
                    showLabel={false}
                    onChange={(field, value) => field.setValue(value?.id)}
                    mb={0}
                  >
                    <MarketFilterForm />
                  </Field>
                  <Field name="ip" pt={2}>
                    <RadioGroup />
                  </Field>
                </>
              )}

              <Field name="lookback" pt={2}>
                <RadioGroup />
              </Field>
              <Field name="magnitude" ml={1} pt={2} labelProps={{ ml: -1 }}>
                <MultiSlider min={1} max={5} showTrackFill>
                  <Handle value={parseInt(form.getField('magnitude').getValue())} intentAfter="primary" />
                </MultiSlider>
              </Field>

              <Box py={1}>
                <Text fontSize={12} fontWeight={600}>
                  Insight Types
                </Text>
                <Grid gridTemplateColumns="1fr 1fr" gridAutoFlow="row dense" gridRowGap={0} gridColumnGap="4px" pt={1}>
                  {Object.values(KMI_INSIGHT_TYPES).map((type) => {
                    const { value } = type;
                    return (
                      <Field name={`types._${value}`} key={`type-check${value}`} showLabel={false}>
                        <Checkbox />
                      </Field>
                    );
                  })}
                </Grid>
              </Box>
            </FlexColumn>
            {changeDefault && (
              <Box py={1} px={2}>
                <Flex alignItems="center">
                  <CoreCheckbox
                    checked={setLandOnDeck}
                    disabled={landOnDeck}
                    onChange={(check) => this.handleLandingCheck(check)}
                    pr={1}
                  />
                  <Text muted={landOnDeck}>Make Observation Deck my default landing page</Text>
                </Flex>
              </Box>
            )}
            <Flex pt={1} pl={1}>
              <SubmitButton mr={2} />
              <CancelButton onCancel={this.handleCancel} />
            </Flex>
          </FlexColumn>
        )}
      </FormComponent>
    );
  }
}
