export default function deepClone(obj) {
  if (typeof obj !== 'object') {
    return obj;
  }

  let res = obj;
  try {
    res = JSON.parse(JSON.stringify(obj));
  } catch (e) {
    console.error('Attempted to deep clone an invalid JSON object');
  }
  return res;
}
