import React from 'react';

import $dictionary from 'app/stores/$dictionary';

const aggregateTypeLabelOverrides = {
  p95th_bits_per_sec: 'p95th',
  p95th_in_bits_per_sec: (
    <span>
      p95th
      <br />
      Ingress
    </span>
  ),
  p95th_out_bits_per_sec: (
    <span>
      p95th
      <br />
      Egress
    </span>
  ),
  p95th_in_pkts_per_sec: (
    <span>
      p95th
      <br />
      Ingress
    </span>
  ),
  p95th_out_pkts_per_sec: (
    <span>
      p95th
      <br />
      Egress
    </span>
  )
};

const aggregateUnitLabelOverrides = {
  repeated_retransmits: (
    <span>
      Repeated
      <br />
      Retransmits/s
    </span>
  ),
  perc_repeated_retransmits: (
    <span>
      % Repeated
      <br />
      Retransmits
    </span>
  ),
  client_latency: (
    <span>
      Client
      <br />
      Latency (ms)
    </span>
  ),
  server_latency: (
    <span>
      Server
      <br />
      Latency (ms)
    </span>
  ),
  appl_latency: (
    <span>
      Application
      <br />
      Latency (ms)
    </span>
  ),
  unique_src_route_prefix: (
    <span>
      Unique Src
      <br />
      Prefixes
    </span>
  ),
  unique_dst_route_prefix: (
    <span>
      Unique Dst
      <br />
      Prefixes
    </span>
  ),
  unique_src_port: (
    <span>
      Unique Src
      <br />
      Ports
    </span>
  ),
  unique_dst_port: (
    <span>
      Unique Dst
      <br />
      Ports
    </span>
  ),
  unique_src_as: (
    <span>
      Unique Src
      <br />
      ASNs
    </span>
  ),
  unique_dst_as: (
    <span>
      Unique Dst
      <br />
      ASNs
    </span>
  ),
  unique_dst_nexthop_asn: (
    <span>
      Unique Dst
      <br />
      Nexthop ASNs
    </span>
  ),
  unique_src_geo: (
    <span>
      Unique Src
      <br />
      Countries
    </span>
  ),
  unique_dst_geo: (
    <span>
      Unique Dst
      <br />
      Countries
    </span>
  ),
  unique_src_geo_region: (
    <span>
      Unique Src
      <br />
      Regions
    </span>
  ),
  unique_dst_geo_region: (
    <span>
      Unique Dst
      <br />
      Regions
    </span>
  ),
  unique_src_geo_city: (
    <span>
      Unique Src
      <br />
      Cities
    </span>
  ),
  unique_dst_geo_city: (
    <span>
      Unique Dst
      <br />
      Cities
    </span>
  ),
  max_sample_rate: (
    <span>
      Max Sampling
      <br />
      Rate
    </span>
  ),
  avg_sample_rate: (
    <span>
      Avg Sampling
      <br />
      Rate
    </span>
  )
};

export const getAggregateTypeLabel = ({ aggregate, useLineBreaks = true }) => {
  const { value, label } = aggregate;

  if (useLineBreaks && aggregateTypeLabelOverrides[value]) {
    return aggregateTypeLabelOverrides[value];
  }

  const labelName = value.startsWith('sum_logsum') ? 'sum_logsum' : value;

  return $dictionary.get(`aggregateLabels.${labelName}`, label);
};

export const getAggregateUnitLabel = ({ aggregate, prefix = {}, useLineBreaks = true, wrap = true }) => {
  let unit = aggregate.parentUnit || aggregate.unit;
  if (unit === 'kt_intell_order') {
    unit = aggregate.value.replace('sum_logsum_', '');
  }
  const unitPrefix = prefix[unit] || '';

  let unitLabel;
  if (useLineBreaks && aggregateUnitLabelOverrides[unit]) {
    unitLabel = aggregateUnitLabelOverrides[unit];
  } else {
    unitLabel = $dictionary.get(`units.${unit}`, 'Unknown');
  }
  if (unitPrefix) {
    unitLabel = unitLabel.toLowerCase();
  }

  if (wrap) {
    return (
      <span>
        {unitPrefix}
        {unitLabel}
      </span>
    );
  }

  return `${unitPrefix} ${unitLabel}`;
};
