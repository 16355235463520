import React from 'react';
import { observer } from 'mobx-react';

import Checkbox from 'core/components/Checkbox';

import omitFieldProps from './util/omitFieldProps';

const FormCheckbox = observer((props) => {
  const { field, label, showBoxLabel } = props;

  return (
    <Checkbox
      {...field.getProps()}
      {...omitFieldProps(props)}
      checked={field.value}
      label={showBoxLabel && (label || field.label)}
    />
  );
});

FormCheckbox.displayName = 'Checkbox';

FormCheckbox.defaultProps = {
  showBoxLabel: true
};

export default FormCheckbox;
