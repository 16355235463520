// from rfc2616-sec10
// https://www.iana.org/assignments/http-status-codes/http-status-codes.xhtml
export const httpStatusCodes = {
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  307: 'Temporary Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  429: 'Too Many Requests',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported'
};

// from http://www.iana.org/assignments/dns-parameters/dns-parameters.xhtml#dns-parameters-6
export const dnsStatusCodes = {
  0: { short: 'NoError', long: 'No Error' },
  1: { short: 'FormErr', long: 'Format Error' },
  2: { short: 'ServFail', long: 'Server Failure' },
  3: { short: 'NXDomain', long: 'Non-Existent Domain' },
  4: { short: 'NotImp', long: 'Not Implemented' },
  5: { short: 'Refused', long: 'Query Refused' },
  6: { short: 'YXDomain', long: 'Name Exists when it should not' },
  7: { short: 'YXRRSet', long: 'RR Set Exists when it should not' },
  8: { short: 'NXRRSet', long: 'RR Set that should exist does not' },
  9: { short: 'NotAuth', long: 'Not Authorized' },
  10: { short: 'NotZone', long: 'Name not contained in zone' },
  11: { short: 'DSOTYPENI', long: 'DSO-TYPE Not Implemented' },
  16: { short: 'BADVERS', long: 'Bad OPT Version' },
  17: { short: 'BADKEY', long: 'Key not recognized' },
  18: { short: 'BADTIME', long: 'Signature out of time window' },
  19: { short: 'BADMODE', long: 'Bad TKEY Mode' },
  20: { short: 'BADNAME', long: 'Duplicate key name' },
  21: { short: 'BADALG', long: 'Algorithm not supported' },
  22: { short: 'BADTRUNC', long: 'Bad Truncation' },
  23: { short: 'BADCOOKIE', long: 'Bad/missing Server Cookie' }
};
