import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { formConsumer } from 'core/form';
import SelectedValueButtonCompact from 'app/components/device/SelectedValueButtonCompact';
import { getDeviceValues } from 'app/util/devices';

@formConsumer
@observer
class DeviceSelectorDisplay extends Component {
  static defaultProps = {
    showLabels: true,
    showSites: true,
    showTypes: true,
    readOnly: false
  };

  handleRemove = (option, fieldName) => {
    const { onRemove } = this.props;
    onRemove(option, fieldName);
  };

  /*
    Handles reverse lookups where applicable, since we're using IDs for values
   */
  get selectedValuesFromFields() {
    const {
      form,
      useFieldsInsteadOfFieldNames,
      allDevicesField,
      allDevicesFieldName,
      deviceTypesField,
      deviceTypesFieldName,
      deviceLabelsField,
      deviceLabelsFieldName,
      deviceSitesField,
      deviceSitesFieldName,
      deviceNameField,
      deviceNameFieldName,
      showTypes,
      showLabels,
      showSites,
      allowOnlyActiveDeviceAttributes
    } = this.props;

    if (useFieldsInsteadOfFieldNames) {
      return getDeviceValues({
        all_devices: allDevicesField.getValue(),
        device_types: showTypes ? deviceTypesField.getValue() : [],
        device_labels: showLabels ? deviceLabelsField.getValue() : [],
        device_sites: showSites ? deviceSitesField.getValue() : [],
        device_name: deviceNameField.getValue(),
        allowOnlyActiveDeviceAttributes
      });
    }

    const all_devices = form.getValue(allDevicesFieldName);
    const device_types = showTypes ? form.getValue(deviceTypesFieldName) : [];
    const device_labels = showLabels ? form.getValue(deviceLabelsFieldName) : [];
    const device_sites = showSites ? form.getValue(deviceSitesFieldName) : [];
    const device_name = form.getValue(deviceNameFieldName);

    return getDeviceValues({
      all_devices,
      device_types,
      device_labels,
      device_sites,
      device_name,
      allowOnlyActiveDeviceAttributes
    });
  }

  render() {
    const { disabled, onRemove, readOnly, selectedValues, allDevicesLabel } = this.props;
    const selectedDisplayValues = selectedValues || this.selectedValuesFromFields;

    return (
      <>
        {selectedDisplayValues.get('all') && (
          <SelectedValueButtonCompact label={allDevicesLabel} icon="multi-select" disabled={disabled} />
        )}
        {selectedDisplayValues.get('types').map((selectedType) => (
          <SelectedValueButtonCompact
            option={selectedType}
            key={selectedType.value}
            onRemove={readOnly ? undefined : this.handleRemove}
            field="device_types"
            disabled={disabled}
          />
        ))}
        {selectedDisplayValues
          .get('labels')
          .map(
            (selectedLabel) =>
              selectedLabel && (
                <SelectedValueButtonCompact
                  icon="tag"
                  option={selectedLabel}
                  key={selectedLabel.value}
                  onRemove={readOnly ? undefined : onRemove}
                  field="device_labels"
                />
              )
          )}
        {selectedDisplayValues
          .get('sites')
          .map(
            (selectedSite) =>
              selectedSite && (
                <SelectedValueButtonCompact
                  icon="map-marker"
                  option={selectedSite}
                  key={selectedSite.value}
                  onRemove={readOnly ? undefined : onRemove}
                  field="device_sites"
                />
              )
          )}

        {selectedDisplayValues
          .get('devices')
          .map(
            (device) =>
              device && (
                <SelectedValueButtonCompact
                  option={device}
                  key={device.value}
                  onRemove={readOnly ? undefined : onRemove}
                  field="device_names"
                />
              )
          )}
      </>
    );
  }
}

export default DeviceSelectorDisplay;
