import io from 'socket.io-client';
import uuid from 'uuid';
import { activateSpan, injectB3, getActiveSpan } from 'core/util/uiOtel';
import { getApplicationName } from '@kentik/ui-shared/query/queryUtils';

import { isDev } from './env';

const socketBaseUrl = isDev
  ? `http://${window.location.hostname}:3000`
  : `${window.location.protocol}//${window.location.host}`;
let socket = null;
const reconnectStatus = { attempt: 0 };
const socketID = uuid.v4();

export default class Socket {
  frequency = 'once';

  constructor(options = {}) {
    if (socket === null) {
      socket = io.connect(socketBaseUrl, { path: '/v4/socket.io' });
      socket.on('reconnect', () => {
        let timeout = 1;

        reconnectStatus.attempt += 1;
        reconnectStatus.previousAttemptTime = reconnectStatus.lastAttemptTime || Date.now();
        reconnectStatus.lastAttemptTime = Date.now();

        if (reconnectStatus.attempt > 1 && Date.now() - reconnectStatus.previousAttemptTime < 60000) {
          timeout = (reconnectStatus.attempt ** 2 - 1) * 0.5;
        }
        if (timeout > 3600000) {
          this.destroy();
        }
        const { attempt: currAttempt } = reconnectStatus;

        setTimeout(
          () => {
            if (currAttempt > 1 && currAttempt >= reconnectStatus.attempt) {
              reconnectStatus.attempt = 0;
            }
          },
          timeout * 1000 * 20
        );
      });

      if (isDev) {
        socket.on('event', (data) => {
          console.info('socket event', data);
        });
      }
    }

    this.id = uuid.v4();

    Object.assign(this, options);

    if (options.type) {
      this.inType = options.type;
      this.outType = options.type;
    }
  }

  setID() {
    this.id = uuid.v4();
  }

  setFrequency(frequency) {
    this.frequency = frequency;
  }

  setInType(inType) {
    this.inType = inType;
  }

  setOutType(outType) {
    this.outType = outType;
  }

  setPayload(payload) {
    this.payload = payload;
  }

  send = activateSpan((payload, handler) => {
    if (!socket) {
      console.warn('Send called but socket not initialized', this.getOutChannel());
      return;
    }

    if (!socket.connected) {
      socket.once('connect', () => setTimeout(() => this.send(payload, handler), 500));
      socket.connect();
    } else {
      const channel = this.getOutChannel();
      this.listen(handler);

      let newPath = window.location.pathname;
      if (newPath.includes('/export')) {
        newPath = newPath.replace('/export', '');
      }

      const span = getActiveSpan();
      // allow payload param to serve as an ultimate override;
      // if no payload param, then try this.payload, or default to {}
      this.payload = Object.assign(payload || this.payload || {}, {
        requestID: this.id,
        socketID,
        spoofURL: span.attributes.spoof_url,
        source: getApplicationName(newPath)
      });

      injectB3(this.payload);

      span.setAttributes({
        request_id: this.id,
        socket_id: socketID
      });
      socket.emit(channel, this.payload);
    }
  }, 'socket - send');

  listen(handler) {
    if (!socket || (!handler && this.listening)) {
      return;
    }

    const channel = this.getInChannel();
    let handlerFn = handler;
    if (!handler) {
      handlerFn = (data) => {
        const span = getActiveSpan();
        if (data.error) {
          setTimeout(() => this.onError(data.error), 10);
          span.setAttributes({
            status: 'error',
            message: data.error
          });
        } else {
          setTimeout(() => this.onSuccess(data), 10);
          span.setAttributes({ status: 'success', len: data.length });
        }
      };
    }

    const traceHandlerFn = activateSpan(
      handlerFn,
      'socket - handler',
      { request_id: this.id, socket_id: socketID },
      this.payload
    );

    if (this.frequency === 'once') {
      socket.once(channel, traceHandlerFn);
    } else {
      socket.on(channel, traceHandlerFn);
    }

    if (!this.listening) {
      socket.on('reconnect', () => {
        let timeout = 1;

        if (reconnectStatus.attempt > 1 && Date.now() - reconnectStatus.previousAttemptTime < 60000) {
          timeout = (reconnectStatus.attempt ** 2 - 1) * 0.5;
        }
        if (timeout > 1) {
          return setTimeout(() => this.listening && this.onReconnect(), timeout * 1000);
        }
        return this.onReconnect();
      });
    }

    this.listening = true;
  }

  cancelChannel() {
    if (socket) {
      socket.removeAllListeners(this.getInChannel());
    }
  }

  cancelAll() {
    if (socket) {
      socket.removeAllListeners(this.getInChannel());
      socket.removeListener('reconnect', this.onReconnect);
    }
    this.listening = false;
  }

  destroy() {
    if (socket) {
      socket.disconnect();
      socket = null;
    }
  }

  getInChannel() {
    if (this.listenToBroadcast) {
      return this.inType;
    }
    return `${this.inType}/${this.id}`;
  }

  getOutChannel() {
    return this.outType; // + "/" + this.id;
  }

  onSuccess = () => {
    if (isDev) {
      console.info(`Success on Socket: "${this.id}", but no handler defined`);
    }
  };

  onReconnect = () => {
    if (isDev) {
      console.warn(`Reconnecting Socket: "${this.id}" but no handler defined`);
    }
  };

  onError = (error) => {
    if (isDev) {
      console.error(`Error from Socket: "${this.id}"`, error);
    }
  };
}
