import React from 'react';
import { observer } from 'mobx-react';
import { Box, Text } from 'core/components';
import KeyValueTable from 'core/components/KeyValueTable';

const InterfaceClassification = ({ selectedInterface }) => {
  const keyValuePairs = [
    {
      key: 'Status',
      value: selectedInterface.isUnclassified ? 'Unclassified' : 'Classified'
    }
  ];

  // If not classified we can omit the below fields.
  if (!selectedInterface.isUnclassified) {
    keyValuePairs.push(
      {
        key: 'Network Boundary',
        value: selectedInterface.networkBoundary
      },
      {
        key: 'Connectivity Type',
        value: selectedInterface.connectivityType
      },
      {
        key: 'Provider',
        value: selectedInterface.get('provider') || 'None'
      }
    );
  }

  return (
    <Box flex={1} pl={3}>
      <Text as="div" fontWeight="bold" fontSize={16} mb={1}>
        Classification
      </Text>

      <KeyValueTable keyValuePairs={keyValuePairs} />
    </Box>
  );
};

export default observer(InterfaceClassification);
