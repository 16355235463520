import getScrollbarWidth from 'core/util/getScrollbarWidth';
import GroupRow from 'core/components/table/GroupRow';
import React from 'react';

const StickyHeader = (props) => {
  const { headerProps } = props;
  if (!headerProps.style) {
    return null;
  }

  // prefer the height in the style definition if it's there
  // this will handle a case where it's being defined via callback
  let height = headerProps.style.height || 39;

  // override if an explicit numeric row height is provided
  if (Number.isInteger(headerProps.rowHeight)) {
    height = headerProps.rowHeight;
  }

  const headerStyle = {
    ...headerProps.style,
    top: 0,
    width: `calc(100% - ${getScrollbarWidth()}px)`,
    height
  };

  return (
    <GroupRow {...headerProps} key={headerProps.groupKey || headerProps.key} className="sticky" style={headerStyle} />
  );
};

export default StickyHeader;
