export default function buildFilterGroup(group) {
  return {
    name: '',
    named: false,
    connector: 'All',
    not: false,
    autoAdded: '',
    saved_filters: [],
    filters: [],
    filterGroups: [],
    ...group
  };
}
