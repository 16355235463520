import React from 'react';
import { observer } from 'mobx-react';
import TestResultsMap from 'app/views/synthetics/components/testResults/TestResultsMap';
import LoadingPanel from './LoadingPanel';

@observer
export default class MapPanel extends React.Component {
  render() {
    const { test, onMapLoad } = this.props;
    const { loading, hasResults } = test.results;

    return (
      <LoadingPanel test={test} testResults={test.results} loading={loading && !hasResults}>
        <TestResultsMap test={test} onLoad={onMapLoad} />
      </LoadingPanel>
    );
  }
}
