import * as React from 'react';
import { merge } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Flex, Spinner, Suspense } from 'core/components';
import { deepClone, safelyParseJSON } from 'core/util';
import { timezone } from 'core/util/dateUtils';

export default function withHybridTopoSettings(WrappedComponent, wrapperOptions = {}) {
  @inject('$hybridMap', '$moduleConfig', '$auth')
  @observer
  class WrapperComponent extends React.Component {
    state = {
      isLoading: true
    };

    get module() {
      return wrapperOptions.module || 'hybridmap';
    }

    // currently the only distinct setting we have for cloud performance is the time options
    // however for future proofing, we'd still expect this object to be in the same form as full hybrid map settings.
    get cloudPerformanceSettings() {
      if (this.module === 'cloudperformance') {
        return safelyParseJSON(window.localStorage.getItem('cloud-performance-settings')) || {};
      }

      return {};
    }

    componentDidMount() {
      const { $moduleConfig } = this.props;

      $moduleConfig.fetchModuleConfig('hybridmap', { force: true }).then((legacyCompanySettings) => {
        // when we first migrated off local storage, we moved settings to a module config
        // however the module config saves at the company level when we want to save at the user level

        // We previously persisted the top toolbar settings in localstorage
        // The saved searches are of particular interest because someone may have spent significant time building them
        // Check if any exist in local storage and migrate to the module config
        const legacySettings =
          safelyParseJSON(window.localStorage.getItem('hybrid-map-settings')) || legacyCompanySettings;

        // if we had a module config, once cleaned up it would just be an empty object
        if (legacySettings && Object.keys(legacySettings).length > 0) {
          this.saveSettings(legacySettings).then(() => {
            // load settings fresh
            this.loadSettings();

            if (legacySettings) {
              // clean up after the legacy settings
              window.localStorage.removeItem('hybrid-map-settings');
            }

            if (legacyCompanySettings && Object.keys(legacyCompanySettings).length > 0) {
              // clean up after the legacy company settings
              this.cleanLegacyCompanySettings();
            }
          });
        } else {
          this.loadSettings();
        }
      });
    }

    getTimeOptions = (settings) => {
      if (settings && settings.sidebarQueryOverrides) {
        const { filters, ...timeOptions } = settings.sidebarQueryOverrides;
        return timeOptions;
      }

      return {};
    };

    loadSettings = () => {
      const { $auth, $hybridMap } = this.props;
      const loadedSettings = merge(deepClone($auth.userSettings.hybridMap || {}), this.cloudPerformanceSettings);
      if (loadedSettings?.sidebarQueryOverrides) {
        loadedSettings.sidebarQueryOverrides.time_format = timezone.value;
      }

      this.setState({ isLoading: false }, () => {
        $hybridMap.settingsModel.set({
          ...loadedSettings,
          searchTerm: '' // always start with a fresh search box
        });
      });
    };

    // save the time options to local storage and pass through the original settings
    saveCloudPerformanceSettings = (settings) => {
      const cloudPerformanceSettings = {
        sidebarQueryOverrides: this.getTimeOptions(settings)
      };

      window.localStorage.setItem('cloud-performance-settings', JSON.stringify(cloudPerformanceSettings));

      return Promise.resolve(settings);
    };

    cleanLegacyCompanySettings = () => {
      const { $moduleConfig } = this.props;
      $moduleConfig.saveModuleConfig({ module: 'hybridmap', settings: {} });
    };

    saveSettings = (settings, options = {}) => {
      const { $hybridMap, $auth } = this.props;
      const settingsToSave = settings || $hybridMap.sidebarSettings;

      // check for bypass of module save
      if (options && options.persist === false) {
        return Promise.resolve();
      }

      if (this.module === 'cloudperformance') {
        // we don't want to overwrite the time options for hybrid maps so get the last settings
        // but we do want to save any potential new saved searches that came in from cloud performance
        // overwrite the time options for hybrid map but keep everything else
        const lastHybridMapSettings = $auth.userSettings.hybridMap || {};
        const nextHybridMapSettings = {
          ...settings,
          sidebarQueryOverrides: {
            ...settings.sidebarQueryOverrides,
            ...this.getTimeOptions(lastHybridMapSettings)
          }
        };

        // save settings specific to cloud performance
        this.saveCloudPerformanceSettings(settingsToSave);

        return $auth.setUserSettings({ hybridMap: nextHybridMapSettings });
      }

      return $auth.setUserSettings({ hybridMap: settingsToSave });
    };

    render() {
      const { $hybridMap, $moduleConfig, $auth, ...restProps } = this.props;
      const { isLoading } = this.state;

      return (
        <Suspense
          loading={isLoading}
          fallback={
            <Flex flex={1} justifyContent="center">
              <Spinner />
            </Flex>
          }
        >
          <WrappedComponent
            settingsModel={$hybridMap.settingsModel}
            sidebarSettings={$hybridMap.sidebarSettings}
            saveSettings={this.saveSettings}
            {...restProps}
          />
        </Suspense>
      );
    }
  }

  return WrapperComponent;
}
