class BaseTagGenerator {
  constructor(options) {
    Object.assign(this, options);
  }

  tagRow({ row, tagKey, tagContent }) {
    row.set({ [`${tagKey}`]: tagContent });
  }

  tagResults({ queryResults, tagGenData, options }) {
    console.warn('BaseTagGenerator.tagResults - this should be overridden', { tagGenData, queryResults, options });
  }

  generateTagContent({ row, metadata, bracketOptions }) {
    const {
      type,
      bracketAggregation,
      over,
      rangeMetadata,
      ranges,
      bracketAggregationDisplay,
      bracketAggregationDisplayUnit
    } = bracketOptions;
    const { metaRanges } = rangeMetadata;
    const { rangeValues, min, max } = metadata;
    let tagData;
    const isMatrix = row.get('matrix');
    const value = row.get(bracketAggregation);
    // tagData special case for matrix
    if (isMatrix) {
      tagData = { metadata, bracketOptions, isMatrix };
    } else if (value < min) {
      tagData = {
        value: '#5c7080',
        isExcluded: true,
        bracketLabel: 'Excluded - under range min.',
        bracketAggregationDisplay,
        bracketAggregationDisplayUnit
      };
    } else if (value > max) {
      tagData = {
        value: '#5c7080',
        isExcluded: true,
        bracketLabel: 'Excluded - over range max.',
        bracketAggregationDisplay,
        bracketAggregationDisplayUnit
      };
    } else {
      // If percentage based and min === max (all rows values same or only one row) then they are 100% range, skip over
      // 'normal' range logic as that will drop them in first range available (value will always <= rangeValue).
      // This oddness is because rather than calculate percentage/percentile value for every row and compare with
      // ranges directly, only the values for each range percentage/percentiles are calculated, and each row value is
      // compared against the calculated value. While this does save lots of calculations for larger rowsets, in retrospect
      // it's most likely not worth the edge case complications. If this ends up getting even weirder, strongly consider
      // just reworking the logic to just do the calculations per row and simplify everything else, but for now, this
      // will work.
      let selectedRangeIdx;
      if ((type === 'percentages' || type === 'percentiles') && min === max) {
        selectedRangeIdx = ranges.findIndex((range) => range.to === 100);
      } else {
        selectedRangeIdx = rangeValues.findIndex((range) => value <= range.to);
      }

      if (selectedRangeIdx === -1) {
        tagData = Object.assign({ value: over }, metaRanges[ranges.length], {
          bracketAggregationDisplay,
          bracketAggregationDisplayUnit
        });
      } else {
        tagData = Object.assign(ranges[selectedRangeIdx].data, metaRanges[selectedRangeIdx], {
          bracketAggregationDisplay,
          bracketAggregationDisplayUnit
        });
      }
    }

    return tagData;
  }
}

export default BaseTagGenerator;
