import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import {
  alignContent,
  alignItems,
  alignSelf,
  background,
  backgroundImage,
  backgroundPosition,
  backgroundRepeat,
  backgroundSize,
  borders,
  borderColor,
  borderRadius,
  boxShadow,
  height,
  width,
  color,
  display,
  flex,
  flexBasis,
  flexDirection,
  flexWrap,
  fontSize,
  fontStyle,
  fontWeight,
  fontFamily,
  gridArea,
  gridColumn,
  gridRow,
  justifyContent,
  justifySelf,
  overflow,
  opacity,
  lineHeight,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  textAlign,
  space,
  verticalAlign,
  zIndex,
  style
} from 'styled-system';
import positionHelpers from './utils/position';
import columnCount from './utils/columnCount';
import breakInside from './utils/breakInside';
import { textTransform, textDecoration, whiteSpace } from './utils/typography';
import cursor from './utils/cursor';

const borderWidth = style({
  prop: 'borderWidth',
  cssProperty: 'borderWidth'
});

const WrappedBox = styled.div`
  ${alignContent};
  ${alignItems};
  ${alignSelf};
  ${background};
  ${backgroundImage};
  ${backgroundPosition};
  ${backgroundRepeat};
  ${borderRadius};
  ${boxShadow};
  ${backgroundSize};
  ${borders};
  ${borderColor};
  ${borderWidth};
  ${breakInside};
  ${color};
  ${columnCount};
  ${cursor};
  ${display};
  ${flex};
  ${height};
  ${width};
  ${opacity};
  ${fontFamily};
  ${flexBasis};
  ${overflow};
  ${flexDirection};
  ${flexWrap};
  ${fontSize};
  ${fontStyle};
  ${fontWeight};
  ${gridArea};
  ${gridColumn};
  ${gridRow};
  ${justifyContent};
  ${justifySelf};
  ${lineHeight};
  ${maxHeight};
  ${maxWidth};
  ${minHeight};
  ${minWidth};
  ${space};
  ${textAlign};
  ${textTransform};
  ${textDecoration};
  ${positionHelpers};
  ${verticalAlign};
  ${whiteSpace};
  ${zIndex};
`;

// support `runningText`, `large`, and `showSkeleton` shortcuts on Box
const Box = React.forwardRef(({ className, runningText, showSkeleton, large, boxRef, ...rest }, ref) => (
  <WrappedBox
    {...rest}
    ref={ref || boxRef}
    className={classNames(className, {
      'bp4-running-text': runningText,
      'bp4-text-large': large,
      'bp4-skeleton': showSkeleton
    })}
  />
));

export default Box;
