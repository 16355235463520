import styled from 'styled-components';
import { space, width, fontWeight, fontSize, color, borders, boxShadow } from 'styled-system';
import { Navbar as BlueprintNavbar } from '@blueprintjs/core';

const Navbar = styled(BlueprintNavbar)`
  align-items: center;

  /* defaults to elevation 1 box shadow */
  box-shadow:
    0 0 0 1px rgba(16, 22, 26, 0.1),
    0 0 0 rgba(16, 22, 26, 0),
    0 0px 1px rgba(16, 22, 26, 0.2);

  /* navbar uses a slightly different color for dark mode */
  &.bp4-dark {
    background: #30404d;
  }

  ${space};
  ${width};
  ${fontSize};
  ${fontWeight};
  ${color};
  ${borders};
  ${boxShadow};
`;

export default Navbar;
