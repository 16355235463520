import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Flex } from 'core/components';
import { formConsumer, Field, InputGroup } from 'core/form';
import DimensionSelector from 'app/components/dimensions/DimensionSelector';
import ExplorerDimensionSelector from 'app/components/dimensions/ExplorerDimensionSelector';

@inject('$devices', '$dictionary')
@formConsumer
@observer
class QueryOptions extends Component {
  state = {
    collapsedDimensions: {}
  };

  static getDerivedStateFromProps(props, state) {
    const collapsedDimensions = {};
    const { $devices } = props;

    if (!$devices.hasDnsProbe) {
      collapsedDimensions['Layer 7'] = true;
    }
    props.disabledDimensions.forEach((dimension) => {
      if (!dimension.includes('_')) {
        collapsedDimensions[dimension] = true;
      }
    });

    return { collapsedDimensions: { ...state.collapsedDimensions, ...collapsedDimensions } };
  }

  handleDimensionSectionCollapse = (group) => {
    const { collapsedDimensions } = this.state;
    const newDimensions = { ...collapsedDimensions };
    newDimensions[group] = !collapsedDimensions[group];
    this.setState({ collapsedDimensions: newDimensions });
  };

  render() {
    const { $dictionary, disabledDimensions, isEditingDimensions, onEditComplete, form, isMatrix } = this.props;
    const { collapsedDimensions } = this.state;

    const metrics = form.getValue('metric');

    const cidrMetrics = $dictionary.get('cidrMetrics');

    const showCidr = metrics.some((metric) => cidrMetrics.includes(metric));

    if (isMatrix) {
      return (
        <DimensionSelector
          title="Matrix By Dimensions"
          showClearButton
          fieldName="matrixBy"
          isEditing={isEditingDimensions}
          onEditComplete={onEditComplete}
          disabledValues={disabledDimensions}
          onCollapse={this.handleDimensionSectionCollapse}
          collapsedState={collapsedDimensions}
          showEditButton
          small
        />
      );
    }

    return (
      <>
        <ExplorerDimensionSelector
          onCollapse={this.handleDimensionSectionCollapse}
          collapsedState={collapsedDimensions}
          showClearButton
          fieldName="metric"
          disabledValues={disabledDimensions}
          isEditing={isEditingDimensions}
          onEditComplete={onEditComplete}
        />
        {showCidr && (
          <Flex mt={2}>
            <Field name="cidr" width={50} small>
              <InputGroup />
            </Field>
            <Field name="cidr6" width={50} ml={2} small>
              <InputGroup />
            </Field>
          </Flex>
        )}
      </>
    );
  }
}

export default QueryOptions;
