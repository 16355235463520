import React, { Component } from 'react';
import Button from 'core/components/Button';
import Collapse from 'core/components/Collapse';
import PropTypes from 'prop-types';

class ToggleCollapse extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    defaultOpen: PropTypes.bool
  };

  static defaultProps = {
    defaultOpen: false
  };

  state = {
    open: false
  };

  constructor(props) {
    super(props);

    this.state = {
      open: props.defaultOpen
    };
  }

  toggleOpen = () => {
    this.setState(({ open }) => ({
      open: !open
    }));
  };

  render() {
    const { label, children, buttonProps, ...props } = this.props;
    const { open } = this.state;

    return (
      <>
        <Button
          icon={open ? 'caret-down' : 'caret-right'}
          onClick={this.toggleOpen}
          text={label}
          intent="primary"
          mt={2}
          mb={1}
          minimal
          {...buttonProps}
        />
        <Collapse isOpen={open} {...props}>
          {children}
        </Collapse>
      </>
    );
  }
}

export default ToggleCollapse;
