/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { withTheme } from 'styled-components';
import { drag } from 'd3-drag';
import { select, event } from 'd3-selection';
import { getPolygonInfo } from 'app/util/graphing';
import { ContextMenuTarget } from '@blueprintjs/core';
import { ThemeProvider, Menu, MenuItem } from 'core/components';

@inject('$topo')
@ContextMenuTarget
@observer
class Group extends Component {
  static propTypes = {
    groupId: PropTypes.string.isRequired,
    color: PropTypes.string,
    onClick: PropTypes.func,
    onShowDetails: PropTypes.func,
    onDrag: PropTypes.func
  };

  static defaultProps = {
    color: undefined,
    onClick: () => {},
    onShowDetails: () => {},
    onDrag: () => {}
  };

  @observable
  dragging = false;

  groupEl;

  @computed
  get group() {
    const { $topo, groupId } = this.props;
    const { getGroup } = $topo;
    return getGroup(groupId);
  }

  @computed
  get polygonInfo() {
    return getPolygonInfo(this.group.polygon, this.group.collapsed);
  }

  getGroupRef = (groupEl) => {
    this.groupEl = groupEl;

    select(groupEl).call(
      drag().on('start', this.handleDragStarted).on('drag', this.handleDragged).on('end', this.handleDragEnded)
    );
  };

  handleShowDetails = (e) => {
    const { color, onShowDetails } = this.props;
    if (this.group.id !== 'unlinked') {
      onShowDetails('group', this.group, this.groupEl, { color }, e);
    }
  };

  handleClick = (e) => {
    e.stopPropagation();
    const { onClick } = this.props;
    onClick(!this.group.collapsed, this.group, this.groupEl);
  };

  handleDragStarted = () => {
    const { onDrag } = this.props;
    event.sourceEvent.stopPropagation();
    this.dragging = true;
    onDrag('start', this.group);
  };

  handleDragged = () => {
    const { onDrag } = this.props;
    onDrag('drag', this.group);
  };

  handleDragEnded = () => {
    const { onDrag } = this.props;
    this.dragging = false;
    onDrag('end', this.group);
  };

  renderContextMenu() {
    const { theme, onClick } = this.props;
    const isCollapsed = this.group.collapsed;

    return (
      <ThemeProvider theme={theme}>
        <Menu>
          <MenuItem text="Show Details" icon="search" onClick={this.handleShowDetails} />
          <MenuItem
            text={isCollapsed ? 'Expand Site' : 'Collapse Site'}
            icon={isCollapsed ? 'maximize' : 'minimize'}
            onClick={() => onClick(!this.group.collapsed, this.group, this.groupEl)}
          />
        </Menu>
      </ThemeProvider>
    );
  }

  render() {
    const { theme, color } = this.props;
    const groupColor = color || theme.colors.gray4;

    return (
      <g
        ref={this.getGroupRef}
        transform={`translate(${this.polygonInfo.centroid[0]},${this.polygonInfo.centroid[1]})
          scale(${this.polygonInfo.scaleFactor})`}
        className="group"
        style={{ cursor: 'move' }}
      >
        <path
          d={this.polygonInfo.pathD}
          stroke={
            this.dragging ? `${theme.name === 'dark' ? theme.colors.lightGray5 : theme.colors.darkGray5}` : groupColor
          }
          fill={groupColor}
          fillOpacity="0.2"
          strokeOpacity="1"
          strokeWidth="0.5"
        />
      </g>
    );
  }
}

export default withTheme(Group);
