import InterfaceGroupSelector from 'app/components/interfaceGroup/InterfaceGroupSelector';
import { Box, Button, Flex, Heading, Switch, Text } from 'core/components';
import Table from 'core/components/HTMLTable';
import { Checkbox, CheckboxGroup, Field, Form, FormDialog, InputGroup } from 'core/form';
import Duration from 'core/form/components/Duration';
import { inject, observer } from 'mobx-react';
import { opacify } from 'polished';
import React, { Component } from 'react';
import {
  DEFAULT_GNMI_INTERVAL,
  fastMeasurements,
  MINIMUM_GNMI_INTERVAL,
  MINIMUM_METADATA_INTERVAL,
  MINIMUM_SNMP_INTERVAL
} from 'shared/nms/deviceMonitoringTemplates';
import styled from 'styled-components';

const commonMeasurements = ['/components', '/interfaces/counters', '/system', '/system/cpus', '/system/memory'];

const fields = {
  name: { label: 'Name', rules: ['required'], messages: { not_in: 'This name has already been used' } },
  description: { label: 'Description' },
  'settings.intervals.fastMetrics': {
    label: 'Fast Metrics',
    rules: ['required', 'validDuration', `minDuration:${MINIMUM_SNMP_INTERVAL}`],
    helpText: 'CPU, memory, interface statistics'
  },
  'settings.intervals.slowMetrics': {
    label: 'Slow Metrics',
    rules: ['required', 'validDuration', `minDuration:${MINIMUM_SNMP_INTERVAL}`],
    helpText: 'BGP, IS-IS, hardware'
  },
  'settings.intervals.metadata': {
    label: 'Metadata',
    rules: ['required', 'validDuration', `minDuration:${MINIMUM_METADATA_INTERVAL}`],
    helpText: 'Model, manufacturer, etc.'
  },
  'simple.measurements.system': {
    label: 'System',
    options: [
      { label: 'CPU', value: '/system/cpus' },
      { label: 'Memory', value: '/system/memory' },
      { label: 'Temperature', value: '/components/temperature' }
    ]
  },
  'simple.measurements.hardware': {
    label: 'Hardware',
    options: [
      { label: 'Power supplies', value: '/components/power-supply' },
      { label: 'Port sensors', value: '/components/sensor' },
      { label: 'Fans', value: '/components/fan' },
      { label: 'Other', value: '/components' }
    ]
  },
  'simple.measurements.protocols': {
    label: 'Routing Protocols',
    options: [
      { label: 'BGP', value: '/protocols/bgp/*' },
      { label: 'IS-IS', value: '/protocols/isis/*' }
    ]
  },
  _interfaceGroup: {
    defaultValue: undefined
  }
};

/**
settings schema
{
    "intervals": { // default intervals, can be overridden in measurements
        "fastMetrics": "1m",
        "slowMetrics": "5m",
        "metadata": "4h"
    },
    "measurements": { // all measurements with all metrics are enabled with above intervals by default
        "/system/cpus": {
            "interval": "5m",
            "st_interval": "1m",
            "enabled": true,
            "metrics": {
                "total/instant": { "enabled": true }
                "ssCpuRawIdle": { "enabled": false }
            }
        }
    }
}
*/

const options = { name: 'Monitoring Template' };

const HR = styled.hr`
  margin-top: 16px;
  margin-bottom: 16px;
  border-color: ${({ theme }) => opacify(0.18, theme.borderColors.thin)};
  border-style: solid;
  border-width: 1px 0 0;
`;

/**
 * @param {string} measurement
 * @returns {string}
 */
function measurementPath(measurement) {
  if (measurement.includes('.')) {
    // use square brackets to handle measurements that have dots in their name
    // TODO this notation doesn't work in validatorjs, so these fields don't get validated
    return `settings.measurements['${measurement}']`;
  }
  return `settings.measurements.${measurement}`;
}

@Form({ fields, options })
@inject('$metrics')
@observer
export default class MonitoringTemplateForm extends Component {
  state = {
    advancedMode: false,
    interfaceSelectorOpen: false,
    showStInterval: false,
    expandedMeasurements: {}
  };

  get uniqueNameRule() {
    const { model } = this.props;
    return `not_in:${model.collection.models.filter((m) => m !== model).map((m) => m.get('name'))}`;
  }

  get measurements() {
    const { $metrics } = this.props;
    return $metrics.availableMetricsCollection.models.filter(
      (model) => !model.get('measurement').startsWith('/kentik/')
    );
  }

  constructor(props) {
    super(props);
    const { form, model } = this.props;
    const { measurements } = this;
    const settings = model.get('settings');
    const fastInterval = form.getValue('settings.intervals.fastMetrics');
    const slowInterval = form.getValue('settings.intervals.slowMetrics');
    let showStInterval = false;

    measurements.forEach((m) => {
      const name = m.get('measurement');
      const metrics = m.get('storage').Metrics || {};
      const isFast = fastMeasurements.includes(name);
      const defaultInterval = isFast ? fastInterval : slowInterval;

      const measurementFields = {
        [`${measurementPath(name)}.enabled`]: { label: name, defaultValue: true },
        [`${measurementPath(name)}.interval`]: {
          rules: ['validDuration', `minDuration:${MINIMUM_SNMP_INTERVAL}`],
          defaultValue: defaultInterval
        },
        [`${measurementPath(name)}.st_interval`]: {
          rules: ['validDuration', `minDuration:${MINIMUM_GNMI_INTERVAL}`],
          defaultValue: DEFAULT_GNMI_INTERVAL
        }
      };

      Object.entries(metrics).forEach(([metric, data]) => {
        measurementFields[`${measurementPath(name)}.metrics.${metric}.enabled`] = {
          label: data.Label || metric,
          defaultValue: true
        };
      });

      showStInterval =
        showStInterval ||
        (settings.measurements?.[name]?.st_interval &&
          settings.measurements[name].st_interval !== DEFAULT_GNMI_INTERVAL);

      form.addFields(measurementFields);
    });

    // re-set model to init new fields
    form.setModel(model);

    this.state.showStInterval = showStInterval;

    form.getMatchingFields('simple.measurements').forEach((simpleField) => {
      const values = simpleField.options
        .map((option) => option.value)
        .filter((optionValue) => {
          if (optionValue.endsWith('*')) {
            const search = optionValue.slice(0, -1);
            return measurements
              .filter((m) => m.get('measurement').startsWith(search))
              .some((m) => {
                const name = m.get('measurement');
                return settings.measurements?.[name]?.enabled !== false;
              });
          }

          return settings.measurements?.[optionValue]?.enabled !== false;
        });

      simpleField.setValue(values);
    });
  }

  handleAdvancedToggle = () => {
    this.setState(({ advancedMode }) => ({ advancedMode: !advancedMode }));
  };

  handleIntervalChange = () => {
    const { form } = this.props;
    const { measurements } = this;
    const fastInterval = form.getValue('settings.intervals.fastMetrics');
    const slowInterval = form.getValue('settings.intervals.slowMetrics');

    measurements.forEach((m) => {
      const name = m.get('measurement');
      const isFast = fastMeasurements.includes(name);
      const field = form.getField(`${measurementPath(name)}.interval`);

      if (field.getValue() === field.getInitialValue()) {
        field.init(isFast ? fastInterval : slowInterval);
      }
    });
  };

  handleSimpleMeasurementChange = (field, values) => {
    const { form } = this.props;
    const { measurements } = this;

    field.options.forEach((option) => {
      const enabled = values.includes(option.value);

      if (option.value.endsWith('*')) {
        const search = option.value.slice(0, -1);

        measurements
          .filter((m) => m.get('measurement').startsWith(search))
          .forEach((m) => {
            const name = m.get('measurement');
            form.getField(`${measurementPath(name)}.enabled`).setValue(enabled);
          });
      } else {
        const measurementField = form.getField(`${measurementPath(option.value)}.enabled`);
        if (measurementField) {
          measurementField.setValue(enabled);
        }
      }
    });
  };

  handleInterfaceSelectorOpen = () => {
    this.setState({ interfaceSelectorOpen: true });
  };

  handleInterfaceSelectorClose = () => {
    this.setState({ interfaceSelectorOpen: false });
  };

  handleInterfaceGroupSave = () => {
    const { form } = this.props;
    form.setValue('_interfaceGroup', 'dirty');
    form.validate();
  };

  handleStIntervalChange = (checked) => {
    const { form } = this.props;
    this.setState({ showStInterval: checked });

    if (!checked) {
      // reset st_interval
      form.getMatchingFields(/settings\.measurements.+\.st_interval/).forEach((field) => {
        field.setToDefault({ validate: false });
      });
    }
  };

  handleMeasurementToggle = (name) => {
    this.setState(({ expandedMeasurements }) => ({
      expandedMeasurements: { ...expandedMeasurements, [name]: !expandedMeasurements[name] }
    }));
  };

  handleSubmit = (form, values) => {
    const { model } = form;
    // merge modified settings onto current settings to prevent storing all the defaults
    // const settings = merge({}, model.get('settings'), form.getDirtyValues().settings || {});
    // return model.save({ ...values, settings });
    // save full settings until the bundler can handle empty fields
    return model.save(values);
  };

  render() {
    const { model, form, isDialog, isOpen, onClose } = this.props;

    if (isDialog) {
      return (
        <FormDialog
          form={form}
          model={model}
          isOpen={isOpen}
          canClickOutsideToClose={false}
          entityName="Monitoring Template"
          onClose={onClose}
          width={750}
          formActionsProps={{ onSubmit: this.handleSubmit, otherActions: <>{this.renderAdvancedToggleButton()}</> }}
        >
          {this.renderForm()}
        </FormDialog>
      );
    }

    return this.renderForm();
  }

  renderAdvancedToggleButton() {
    const { advancedMode } = this.state;
    return (
      <Button onClick={this.handleAdvancedToggle}>
        Switch to <span>{!advancedMode ? 'advanced mode' : 'basic mode'}</span>
      </Button>
    );
  }

  renderForm() {
    const { model, isDialog } = this.props;
    const { advancedMode, interfaceSelectorOpen, showStInterval } = this.state;
    const { measurements } = this;

    return (
      <Box>
        {!isDialog && (
          <Flex gap={2} alignItems="center" justifyContent="space-between" mb={2}>
            <Heading level={4} mb={0}>
              <span>{model.isNew ? 'Add' : 'Edit'}</span> Monitoring Template
            </Heading>
            {this.renderAdvancedToggleButton()}
          </Flex>
        )}

        <Field name="name" rules={`required|${this.uniqueNameRule}`} component={InputGroup} large />
        <Field name="description" component={InputGroup} large />

        <HR />
        <Heading level={5} mb="4px">
          Interface selection
        </Heading>
        <Text muted>Choose which interfaces will be monitored</Text>
        <Box mt={2}>
          <InterfaceGroupSelector
            isOpen={interfaceSelectorOpen}
            interfaceGroup={model.interfaceGroup}
            moduleName={model.moduleName}
            onInterfaceGroupSave={this.handleInterfaceGroupSave}
            onInterfaceGroupDialogClose={this.handleInterfaceSelectorClose}
            staticInterfacesTitle="Static Group"
            showAdminStatus
          />
          <Button icon="edit" text="Edit Selections" onClick={this.handleInterfaceSelectorOpen} />
        </Box>

        {!advancedMode && (
          <>
            <HR />
            <Heading level={5} mb="4px">
              Polling intervals
            </Heading>
            <Text muted>Determine how often data is collected for specific metrics</Text>
            <Flex gap={2} mt={2} width={600}>
              <Box flex="1">
                <Field
                  name="settings.intervals.fastMetrics"
                  onChange={this.handleIntervalChange}
                  component={Duration}
                  large
                />
              </Box>
              <Box flex="1">
                <Field
                  name="settings.intervals.slowMetrics"
                  onChange={this.handleIntervalChange}
                  component={Duration}
                  large
                />
              </Box>
              <Box flex="1">
                <Field name="settings.intervals.metadata" component={Duration} large />
              </Box>
            </Flex>
          </>
        )}

        <HR />
        <Heading level={5} mb="4px">
          Measurement selection
        </Heading>
        <Text muted>Choose which metrics to collect for this template</Text>
        {!advancedMode && (
          <Flex gap={52} alignItems="flex-start" mt={2}>
            <Box>
              <Field
                name="simple.measurements.system"
                onChange={this.handleSimpleMeasurementChange}
                component={CheckboxGroup}
                large
              />
            </Box>
            <Box>
              <Field
                name="simple.measurements.hardware"
                onChange={this.handleSimpleMeasurementChange}
                component={CheckboxGroup}
                large
              />
            </Box>
            <Box>
              <Field
                name="simple.measurements.protocols"
                onChange={this.handleSimpleMeasurementChange}
                component={CheckboxGroup}
                large
              />
            </Box>
          </Flex>
        )}
        {advancedMode && (
          <Box mt={2}>
            <Switch
              checked={showStInterval}
              onChange={this.handleStIntervalChange}
              labelElement={
                <>
                  Specify streaming telemetry intervals
                  <Text as="div" muted>
                    Streaming telemetry collection will be preferred over SNMP where available
                  </Text>
                </>
              }
            />
            <Table condensed width="auto" mt={2}>
              <thead>
                <tr>
                  <th style={{ width: 30 }} />
                  <th>Enabled</th>
                  <th>SNMP Interval</th>
                  {showStInterval && <th>Streaming telemetry interval</th>}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th />
                  <th colSpan={2 + (showStInterval ? 1 : 0)}>
                    <Text fontWeight="normal" muted>
                      Common
                    </Text>
                  </th>
                </tr>
                {measurements
                  .filter((m) => commonMeasurements.includes(m.get('measurement')))
                  .map(this.renderMeasurementRow)}
                <tr>
                  <th />
                  <th colSpan={2 + (showStInterval ? 1 : 0)}>
                    <Text fontWeight="normal" muted>
                      Less common
                    </Text>
                  </th>
                </tr>
                {measurements
                  .filter((m) => !commonMeasurements.includes(m.get('measurement')))
                  .map(this.renderMeasurementRow)}
              </tbody>
            </Table>
          </Box>
        )}
      </Box>
    );
  }

  renderMeasurementRow = (measurementModel) => {
    const { form } = this.props;
    const { showStInterval, expandedMeasurements } = this.state;
    const name = measurementModel.get('measurement');
    const metrics = measurementModel.get('storage').Metrics || {};
    const expanded = !!expandedMeasurements[name];
    const enabledMeasurement = form.getValue(`${measurementPath(name)}.enabled`);
    const enabledMetrics = form
      .getMatchingFields(`${measurementPath(name)}.metrics`)
      .filter((f) => f.getValue()).length;
    const numMetrics = Object.keys(metrics).length;
    const indeterminate = enabledMeasurement && enabledMetrics < numMetrics;
    return (
      <tr key={measurementModel.id}>
        <td style={{ padding: 0 }}>
          <Button
            onClick={() => this.handleMeasurementToggle(name)}
            icon={!expanded ? 'caret-right' : 'caret-down'}
            minimal
            title="Expand"
          />
        </td>
        <td>
          <Field name={`${measurementPath(name)}.enabled`} showLabel={false} mb={0} large>
            <Checkbox
              indeterminate={indeterminate}
              label={
                <>
                  <Text>{name}</Text>
                  {indeterminate && (
                    <Text muted fontSize="0.8em" pt="0.2em" pl="1.5ex">
                      {enabledMetrics} of {numMetrics} <span>{enabledMetrics === 1 ? 'metric' : 'metrics'}</span>
                    </Text>
                  )}
                </>
              }
            />
          </Field>
          {expanded && (
            <Box pt="10px" pl="20px">
              {Object.keys(metrics).map((metric) => (
                <Field
                  key={metric}
                  name={`${measurementPath(name)}.metrics.${metric}.enabled`}
                  component={Checkbox}
                  showLabel={false}
                  mb={1}
                  large
                />
              ))}
            </Box>
          )}
        </td>
        <td>
          <Field name={`${measurementPath(name)}.interval`} component={Duration} mb={0} />
        </td>
        {showStInterval && (
          <td>
            <Field name={`${measurementPath(name)}.st_interval`} component={Duration} mb={0} />
          </td>
        )}
      </tr>
    );
  };
}
