import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Field, InputGroup } from 'core/form';
import { Box } from 'core/components';
import DimensionSelector from 'app/components/dimensions/DimensionSelector';
import { flowColumns, orderByColumns } from './flowColumns';

@inject('$devices', '$setup')
@observer
class RawFlowOptions extends Component {
  state = {
    collapsedDimensions: {},
    collapsedOrderBy: {}
  };

  static getDerivedStateFromProps(props, state) {
    const collapsedDimensions = {};
    const { $devices } = props;

    if (!$devices.hasDnsProbe) {
      collapsedDimensions['Layer 7'] = true;
    }
    props.disabledDimensions.forEach((dimension) => {
      if (!dimension.includes('_')) {
        collapsedDimensions[dimension] = true;
      }
    });

    return { collapsedDimensions: { ...state.collapsedDimensions, ...collapsedDimensions } };
  }

  handleDimensionSectionCollapse = (group) => {
    const { collapsedDimensions } = this.state;
    const newDimensions = { ...collapsedDimensions };
    newDimensions[group] = !collapsedDimensions[group];
    this.setState({ collapsedDimensions: newDimensions });
  };

  handleOrderBySectionCollapse = (group) => {
    const { collapsedOrderBy } = this.state;
    const newDimensions = { ...collapsedOrderBy };
    newDimensions[group] = !collapsedOrderBy[group];
    this.setState({ collapsedOrderBy: newDimensions });
  };

  render() {
    const { collapsedDimensions, collapsedOrderBy } = this.state;
    const { $setup, disabledDimensions } = this.props;
    const disabledValues = ['Traffic', ...disabledDimensions];
    const leftSidebar = $setup.getSettings('layout.drawerLocation') === 'left';

    return (
      <div>
        <DimensionSelector
          options={flowColumns()}
          onCollapse={this.handleDimensionSectionCollapse}
          collapsedState={collapsedDimensions}
          disabledValues={disabledValues}
          fieldName="flow_fields"
          small
          showClearButton
          showEditButton
        />

        <Box my={2}>
          <DimensionSelector
            options={orderByColumns()}
            onCollapse={this.handleOrderBySectionCollapse}
            collapsedState={collapsedOrderBy}
            disabledValues={disabledValues}
            className="filter-item-field"
            fieldName="order_by"
            multi={false}
            position={leftSidebar ? 'bottom-left' : 'bottom-right'}
            isPopover
            small
          />
        </Box>

        <Field name="row_count" small mb={0}>
          <InputGroup />
        </Field>
      </div>
    );
  }
}

export default RawFlowOptions;
