import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import classNames from 'classnames';
import { MdError } from 'react-icons/md';

import { Box, Flex, Collapse, Icon, Tag, Text } from 'core/components';
import { formConsumer } from 'core/form';

import SidebarSectionWarnings from './SidebarSectionWarnings';

const SectionHeader = styled(Flex)`
  &.active,
  &:hover {
    background-color: ${(props) => (props.theme.name === 'dark' ? 'rgba(138,155,168,0.15)' : 'rgba(16,22,26,0.05)')};
  }
`;

const Content = styled(Box)`
  &.can-edit {
    cursor: pointer;

    &:hover {
      background-color: ${(props) => (props.theme.name === 'dark' ? 'rgba(138,155,168,0.15)' : 'rgba(16,22,26,0.05)')};
    }
  }
`;

@inject('$app')
@formConsumer
@observer
class SidebarSection extends Component {
  state = {
    isOpen: false,
    isHoveringContent: false
  };

  static defaultProps = {
    hasChanges: true,
    readOnly: false,
    editOnChildClick: true,
    defaultIsOpen: false,
    showSectionWarnings: true,
    showIcon: true,
    iconProps: {},
    labelTagProps: {}
  };

  componentDidMount() {
    const { sectionName, defaultIsOpen } = this.props;
    const { isOpen } = this.state;
    const isOpenStored = localStorage.getItem(`kentik.${sectionName}.open`);

    const storedOpenState = (defaultIsOpen && isOpenStored == null) || isOpenStored === 'true';

    if (storedOpenState !== isOpen) {
      this.setState({ isOpen: storedOpenState });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.state;
    if (prevState.isOpen !== isOpen) {
      const { sectionName } = this.props;
      localStorage.setItem(`kentik.${sectionName}.open`, isOpen);
    }
  }

  handleEdit = (e) => {
    const childClicked = e.currentTarget.className.includes('sidebar-child');
    const { editOnChildClick, isEditing, onEdit } = this.props;

    if (!isEditing && onEdit) {
      if (childClicked) {
        e.preventDefault();
        e.stopPropagation();

        if (editOnChildClick) {
          onEdit();
        }
      } else {
        onEdit();
      }
    }
  };

  isDirty() {
    const { isDirty, fieldGroup, form } = this.props;

    if (isDirty) {
      return isDirty(form);
    }

    return form.isGroupDirty(fieldGroup);
  }

  render() {
    const {
      $app,
      form,
      fieldGroup,
      label,
      icon,
      labelTag,
      labelTagIntent,
      children,
      sidebar,
      onEdit,
      iconProps,
      labelTagProps,
      showSectionWarnings,
      showIcon
    } = this.props;
    const { isOpen, isHoveringContent } = this.state;
    const canEdit = !!onEdit;
    const showEdit = isOpen && canEdit && isHoveringContent;
    const isSectionDirty = this.isDirty();

    const hasErrors = form.invalidGroups[fieldGroup] || form.incompleteGroups[fieldGroup];

    return (
      <>
        <SectionHeader
          className={classNames({ active: isOpen })}
          justifyContent="space-between"
          alignItems="center"
          height={24}
          onClick={() => this.setState((prevState) => ({ isOpen: !prevState.isOpen }))}
          px={2}
          py={3}
          borderBottom={isOpen ? '' : 'thin'}
          cursor="pointer"
        >
          <Flex alignItems="center" gap={1}>
            {showIcon && icon && typeof icon === 'object' ? (
              icon
            ) : (
              <Icon iconSize={16} icon={icon || 'database'} color="warning" {...iconProps} />
            )}
            <Text as="div" fontWeight="bold">
              {label}
            </Text>
            {!isOpen && labelTag !== undefined && (
              <Tag ml="4px" intent={labelTagIntent} round minimal={labelTagIntent === undefined} {...labelTagProps}>
                {labelTag}
              </Tag>
            )}
          </Flex>
          <Flex alignItems="center">
            {showEdit && (
              <Text className="edit-button" color="muted" fontSize={12} pr="4px">
                Edit
              </Text>
            )}
            {!isOpen && hasErrors && <Icon ml="4px" color="danger" icon={MdError} iconSize={20} />}
            <Icon color="muted" icon={isOpen ? 'chevron-down' : 'chevron-right'} />
          </Flex>
        </SectionHeader>
        <Collapse isOpen={isOpen}>
          <Content
            className={classNames({ 'can-edit': canEdit, dark: $app.darkThemeEnabled })}
            p="12px"
            tabIndex={0}
            borderBottom="thin"
            canEdit={canEdit}
            onClick={this.handleEdit}
            onMouseEnter={() => this.setState({ isHoveringContent: true })}
            onMouseLeave={() => this.setState({ isHoveringContent: false })}
          >
            {React.Children.map(children, (child) => (
              <Box className="sidebar-child" onClick={this.handleEdit}>
                {child && React.cloneElement(child, { sidebar })}
              </Box>
            ))}

            {showSectionWarnings && (
              <SidebarSectionWarnings fieldGroup={fieldGroup} sidebar={sidebar} isSectionDirty={isSectionDirty} />
            )}
          </Content>
        </Collapse>
      </>
    );
  }
}

export default SidebarSection;
