import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import { Box, Flex, Text, Icon, Link } from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import SetupGauge from 'app/components/setup/SetupGauge';
import MenuSectionBase from 'app/components/navbar/MenuSection';

function getMenuSectionCmp({ onClose }) {
  return function MenuSection(props) {
    return <MenuSectionBase {...props} onClick={onClose} />;
  };
}

@storeLoader('$setup.setupTasks')
@inject('$auth')
@observer
class MenuSetupTasks extends Component {
  render() {
    const { $auth, $setup, onClose } = this.props;

    const MenuSection = getMenuSectionCmp({ onClose });
    const { percentComplete, size } = $setup.setupTasks;

    if (!$auth.isAdministrator || size === 0 || percentComplete === 100) {
      return null;
    }

    return (
      <Box minWidth={230} mt="24px">
        <Flex flexDirection="column">
          <Flex alignItems="center" mb="12px">
            <Text as="div" fontWeight={300} textTransform="uppercase" small>
              Setup Tasks
            </Text>
            <SetupGauge width={45} pt={2} />
            <Text small ml={-1}>{`${percentComplete}% complete`}</Text>
          </Flex>
          {$setup.setupTasks.models.slice(0, 3).map((task) => (
            <Flex key={task.id} alignItems="center" mb="12px">
              <Icon
                icon={task.completed ? IoIosCheckmarkCircle : task.icon}
                color={task.completed ? 'success' : undefined}
              />
              <MenuSection title={task.title} to={task.navigationUrl} ml={1} />
            </Flex>
          ))}
          {$setup.setupTasks.size > 3 && <Link to="/v4/setup">See all</Link>}
        </Flex>
      </Box>
    );
  }
}

export default MenuSetupTasks;
