import React, { useState } from 'react';
import { InputGroup } from 'core/form';
import { Button } from 'core/components';

const PasswordInput = (props) => {
  const { showVisibleToggle } = props;

  const [passwordVisible, setPasswordVisible] = useState(false);

  const onPasswordVisibleClick = () => {
    setPasswordVisible(!passwordVisible);
  };
  const onKeyDown = (e) => {
    const { field } = props;

    if (
      !field.dirty &&
      !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) &&
      (e.key === 'Backspace' || e.key === 'Clear')
    ) {
      e.stopPropagation();
      e.preventDefault();
      field.setValue('');
    }
  };
  if (showVisibleToggle) {
    return (
      <InputGroup
        type={passwordVisible ? 'text' : 'password'}
        {...props}
        onKeyDown={onKeyDown}
        rightElement={
          <Button
            small
            minimal
            icon={passwordVisible ? 'eye-open' : 'eye-off'}
            onClick={() => onPasswordVisibleClick()}
          />
        }
      />
    );
  }
  return <InputGroup type="password" {...props} onKeyDown={onKeyDown} />;
};

export default PasswordInput;
