/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { Classes, InputGroup as BlueprintInputGroup } from '@blueprintjs/core';

import { Button, Flex } from 'core/components';
import omitFieldProps from './util/omitFieldProps';
import shiftInputCursor from './util/shiftInputCursor';

const StyledInputGroup = styled(BlueprintInputGroup)`
  ${space};

  /* have to force the font-family here, because Blueprint does not allow this to be overridden with SCSS */
  .${Classes.INPUT} {
    font-family: ${({ theme }) => theme.fonts.body};
  }

  &.${Classes.LARGE} {
    /* force large to be the normal Blueprint sized inputs, since by default we use small */
    input {
      height: 30px;
      font-size: 14px;
    }

    .bp4-input:not(:first-child) {
      padding-left: 35px;
    }

    > .bp4-icon,
    .bp4-input-action > .bp4-spinner {
      margin: 7px;
    }

    .${Classes.TAG} {
      min-width: 20px;
      min-height: 20px;
      padding: 0 8px;
      margin: 5px;
    }

    .bp4-button {
      min-width: 24px;
      min-height: 24px;
      margin: 3px;
    }
  }

  &.large {
    > .bp4-icon,
    .bp4-input-action > .bp4-spinner {
      margin: 10px;
    }

    .bp4-input-action .bp4-button {
      font-size: 14px;
    }
  }

  &.bp4-large {
    .bp4-input-action .bp4-button {
      font-size: 14px;
    }
  }

  &.jumbo {
    input {
      height: 40px;
      font-size: 16px;
      padding-left: 10px;
    }

    .bp4-input:not(:first-child) {
      padding-left: 40px;
    }

    .bp4-input-action {
      height: 100%;
    }

    > .bp4-icon,
    .bp4-input-action > .bp4-spinner {
      margin: 12px;
    }

    .bp4-button {
      min-width: 30px;
      min-height: 30px;
      margin: 0;
    }
  }

  &.round {
    input {
      border-radius: 50px;
    }
  }

  &.chips {
    .bp4-input-left-container {
      left: 5px;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // number input spinner arrows, be gone!
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const InputGroup = (props) => {
  const {
    autoComplete,
    autoFocus,
    clearable,
    field,
    maxWidth,
    onQuery,
    onEnterKey,
    display,
    width,
    height,
    textAlign,
    rightElement,
    ...rest
  } = props;

  let { onFocus } = props;
  if (autoFocus) {
    onFocus = (e) => {
      shiftInputCursor(e);

      // eslint-disable-next-line react/destructuring-assignment
      if (props.onFocus) {
        // eslint-disable-next-line react/destructuring-assignment
        props.onFocus(e);
      }
    };
  }

  const onBlur = (e) => {
    if (field) {
      field.setPristine(false);
    }

    // eslint-disable-next-line react/destructuring-assignment
    if (props.onBlur) {
      // eslint-disable-next-line react/destructuring-assignment
      props.onBlur(e);
    }
  };

  const dataAttributes = {};
  if (autoComplete === 'off') {
    dataAttributes['data-lpignore'] = 'true';
    dataAttributes.autoComplete = 'off';
  } else {
    dataAttributes.autoComplete = autoComplete;
  }

  return (
    <StyledInputGroup
      {...omitFieldProps(rest)}
      autoFocus={autoFocus}
      onFocus={onFocus}
      onBlur={onBlur}
      rightElement={
        rightElement ||
        (clearable && rest.value && (
          <Flex alignItems="center" height="100%">
            <Button minimal icon="cross" onClick={() => rest.onChange('')} />
          </Flex>
        ))
      }
      style={{ display, maxWidth, width, height, textAlign }}
      {...dataAttributes}
    />
  );
};

InputGroup.defaultProps = {
  autoComplete: 'off'
};

export default InputGroup;
