import sass from 'core/components/styles/_exports.scss';

export const sassVariables = {
  ...sass,
  ptIntentTextColors: {
    primary: sass.ptIntentPrimary,
    success: sass.ptIntentSuccess,
    warning: sass.ptIntentWarning,
    danger: sass.ptIntentDanger
  },
  ptDarkIntentTextColors: {
    primary: sass.ptDarkIntentPrimary,
    success: sass.ptDarkIntentSuccess,
    warning: sass.ptDarkIntentWarning,
    danger: sass.ptDarkIntentDanger
  }
};
