import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';

const InetFamilyLink = ({ $app, inetFamily, ...rest }) => {
  if ($app.isSubtenant) {
    return inetFamily;
  }
  return (
    <Link to={`/v4/core/quick-views/inet-family/${inetFamily}`} {...rest}>
      {inetFamily}
    </Link>
  );
};

export default inject('$app')(InetFamilyLink);
