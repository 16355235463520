import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import { Box, FlexColumn, Heading } from 'core/components';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import { ViewInExplorerButton } from 'app/components/dataviews/tools';

export const zoneClassNames = {
  critical: 'zone-critical',
  severe: 'zone-major',
  major2: 'zone-major',
  major: 'zone-major',
  warning: 'zone-minor',
  minor2: 'zone-minor',
  minor: 'zone-minor',
  notice: 'zone-minor'
};

export const lineClassNames = {
  critical: 'critical-level',
  severe: 'major-level',
  major2: 'major-level',
  major: 'major-level',
  warning: 'minor-level',
  minor2: 'minor-level',
  minor: 'minor-level',
  cleared: 'cleared-event',
  alarm: 'alarm-event'
};

const overTimeMatchers = {
  day: /(Today|Day)Over(Yesterday|Day)/,
  week: /(ThisWeek|Week)Over(LastWeek|Week)/
};

const overTimeLabels = {
  day: ['Yesterday', 'Today'],
  week: ['Last week', 'This week']
};

@inject('$alerting', '$colors', '$insights', '$auth')
@observer
class InsightChart extends Component {
  static defaultProps = {
    showExplorerButton: true,
    showExtraChart: false,
    onDataViewCreate: () => {}
  };

  getViewProps(chartType) {
    const { $alerting, attackModel, insight, $insights, viewProps } = this.props;

    const {
      alerting,
      insightName = '',
      severity,
      startTime,
      endTime,
      interfaceIDToDetail
    } = insight.get ? insight.get() : insight;

    const baseline = alerting ? $alerting.getBaseline(alerting) : insight.baseline;
    let threshold = alerting ? $alerting.getThreshold(alerting) : insight.threshold;
    const overTimeUnit = Object.keys(overTimeMatchers).find((unit) => overTimeMatchers[unit].test(insightName));

    const endTimeMoment = $insights.getAlertEndTimeMoment(endTime, attackModel, insight);

    const plotLines = [];
    const xPlotBands = [];
    const zones = [];

    if (viewProps.xPlotBands?.length) {
      xPlotBands.push(...viewProps.xPlotBands);
    }

    if (chartType === 'snmp' && interfaceIDToDetail) {
      threshold = {
        value: insight.capacity.value * 0.85
      };
    }

    if (threshold) {
      plotLines.push({
        id: 'threshold-line',
        className: `thin-line ${lineClassNames[severity]}`,
        label: {
          text: `Threshold (${threshold.formatted})`,
          align: 'left',
          x: 0,
          className: `highcharts-plot-line-label ${lineClassNames[severity]}`
        },
        value: threshold.value,
        zIndex: 5
      });

      const over = threshold.direction === 'currentToHistory' ? threshold.over : !threshold.over;

      zones.push(
        {
          value: threshold.value,
          className: over ? '' : zoneClassNames[severity]
        },
        {
          className: over ? zoneClassNames[severity] : ''
        }
      );
    }

    if (baseline) {
      plotLines.push({
        id: 'baseline-line',
        className: 'baseline-level baseline-insight-level',
        label: {
          text: `Baseline (${baseline.formatted})`,
          align: 'left',
          x: 0,
          className: 'highcharts-plot-line-label baseline-insight-level'
        },
        value: baseline.value,
        zIndex: 5
      });
    }

    if (!baseline && insight.capacity && (!insight.isFactorialInsight || chartType === 'snmp')) {
      plotLines.push({
        id: 'capacity-line',
        className: 'capacity-level',
        label: {
          text: `Capacity (${insight.capacity.formatted})`,
          align: 'left',
          x: -1,
          className: 'capacity-level'
        },
        value: insight.capacity.value,
        zIndex: 5
      });
    }

    if (insight.isFactorialInsight) {
      xPlotBands.push({
        className: 'from-level',
        from: moment.utc(startTime).toDate(),
        to: endTimeMoment.toDate(),
        zIndex: 2
      });
    }

    if (overTimeUnit) {
      const [fromLabel, toLabel] = overTimeLabels[overTimeUnit];
      const midTime = endTimeMoment.subtract(1, overTimeUnit);

      xPlotBands.push(
        {
          className: 'from-level',
          label: {
            text: fromLabel
          },
          from: endTimeMoment.subtract(2, overTimeUnit).toDate(),
          to: midTime.toDate(),
          zIndex: 2
        },
        {
          className: 'to-level',
          label: {
            text: toLabel
          },
          from: midTime.toDate(),
          to: endTimeMoment.toDate(),
          zIndex: 2
        }
      );
    }

    return {
      showNativeLegend: true,
      chartConfig: { plotOptions: { series: { zones } } },
      plotLines,
      xPlotBands
    };
  }

  render() {
    const {
      $auth,
      $insights,
      $colors,
      insight,
      query,
      viewProps,
      enableKentikAi,
      showExplorerButton,
      showSnmpChart,
      onDataViewCreate,
      onQueryComplete,
      ...props
    } = this.props;

    let insightQuery = query || $insights.getInsightQuery(insight, props.attackModel);
    const snmpQuery = $insights.getSnmpQuery(insight);
    const title = showSnmpChart && insight.isFactorialInsight ? 'What may have caused this?' : '';

    if (!insightQuery) {
      return null;
    }

    if ($auth.hasPermission('fpa') && enableKentikAi && insight.isFrequentPatternAnalysisCompatible) {
      const insightStartTime = moment(insight.get('startTime'));
      const windowSize = 8;

      insightQuery = {
        ...insightQuery,
        use_fpa: true,
        fpa: {
          type: 'diff_fpa',

          // we want the first window to be *before* the insight happens
          time: {
            lookback_seconds: 0,
            starting_time: insightStartTime
              .clone()

              // and a 5 minute window before the insight
              .subtract(windowSize + 5, 'minutes')
              .toISOString(),
            ending_time: insightStartTime.clone().subtract(windowSize, 'minutes').toISOString()
          },

          // the 2nd window starts at the insight time, minus the window size
          compare_time: {
            lookback_seconds: 0,
            starting_time: insightStartTime.clone().subtract(windowSize, 'minutes').toISOString(),
            ending_time: insightStartTime.clone().toISOString()
          }
        }
      };
    }

    return (
      <FlexColumn flex={1}>
        {showSnmpChart && insight.isFactorialInsight && (
          <FlexColumn flex={1} mb={2} alignItems="flex-end">
            <Heading level={5} alignSelf="flex-start">
              {insight.label}
            </Heading>
            <DataViewWrapper
              allowCache
              {...props}
              query={snmpQuery}
              viewProps={{
                colors: insightQuery.filterDimensionsEnabled ? $colors.getAllTabbedChartColors() : undefined,
                ...this.getViewProps('snmp'),
                ...viewProps
              }}
              onDataViewCreate={onDataViewCreate}
            />
            {showExplorerButton && (
              <ViewInExplorerButton query={snmpQuery} minimal small style={{ alignSelf: 'flex-end' }} />
            )}
          </FlexColumn>
        )}

        <FlexColumn flex={1} alignItems="flex-end">
          {title && (
            <Heading level={5} alignSelf="flex-start">
              {title}
            </Heading>
          )}

          <Box height={315} width="100%">
            <DataViewWrapper
              allowCache
              {...props}
              fitToHeight
              query={insightQuery}
              onDataViewCreate={onDataViewCreate}
              onQueryComplete={onQueryComplete}
              viewProps={{
                colors: insightQuery.filterDimensionsEnabled ? $colors.getAllTabbedChartColors() : undefined,
                ...this.getViewProps(),
                className: 'no-markers',
                ...viewProps
              }}
            >
              {({ component }) => (
                <>
                  <FlexColumn flex={1} overflow="auto">
                    {component}
                  </FlexColumn>
                  {showExplorerButton && (
                    <ViewInExplorerButton query={insightQuery} minimal small style={{ alignSelf: 'flex-end' }} />
                  )}
                </>
              )}
            </DataViewWrapper>
          </Box>
        </FlexColumn>
      </FlexColumn>
    );
  }
}

export default InsightChart;
