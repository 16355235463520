import ExpandableRow from 'app/components/admin/ExpandableRow';
import { Box, Flex, Icon, Text, Tooltip } from 'core/components';
import { CELL_ACTIONS, CELL_TYPES, Table } from 'core/components/table';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

const getTableColumns = (onViewInDataExplorer, dataview) => [
  {
    name: 'name',
    label: 'Dimensions',
    minWidth: 250,
    renderer: ({ model }) => {
      const color = model.get('color');
      const dimensionValueHash = model.get('dimensionValueHash');
      return (
        <Flex alignItems="flex-start" gap="6px" height="100%" position="relative">
          <Icon iconSize={10} icon="full-circle" color={color} />
          <Flex flexDirection="column" gap="3px">
            {Object.keys(dimensionValueHash)
              .sort((a, b) => a.localeCompare(b))
              .map((key) => (
                <Flex alignItems="center" key={key} gap="2px">
                  <Text muted>{key}:</Text> <Text fontWeight="medium">{dimensionValueHash[key]}</Text>
                </Flex>
              ))}
          </Flex>
        </Flex>
      );
    }
  },
  {
    name: 'formattedValue',
    // for Diff FPA and CPD+FPA, the label is 'Change'
    label: (
      <Flex alignItems="center" gap="4px">
        {dataview?.fpaType === 'fpa' ? 'Traffic' : 'Change'}
        <Tooltip
          position="top"
          content={
            <>
              {dataview?.fpaType === 'fpa'
                ? 'The total estimated traffic for this grouping of dimensions.'
                : 'The difference in traffic, showing an increase or decrease in volume.'}
            </>
          }
        >
          <Icon iconSize={14} icon="info-sign" />
        </Tooltip>
      </Flex>
    ),
    width: 150,
    renderer: ({ model }) => {
      const value = model.get('value');
      const formattedValue = model.get('formattedValue');

      if (value === 0) {
        return '';
      }

      return formattedValue;
    }
  },
  {
    name: 'percentage',
    label: (
      <Flex alignItems="center" gap="4px">
        Percentage
        <Tooltip
          position="top"
          content="The estimated percentage of the total traffic represented by this grouping of dimensions."
        >
          <Icon iconSize={14} icon="info-sign" />
        </Tooltip>
      </Flex>
    ),
    width: 120,
    renderer: ({ model }) => {
      const value = model.get('value');

      if (value === 0) {
        return '';
      }

      return `${model.get('percentage')}%`;
    }
  },
  {
    type: CELL_TYPES.ACTION,
    actions: [
      CELL_ACTIONS.KEBAB([
        {
          id: 'edit',
          label: 'View in Data Explorer',
          handler: (model) => onViewInDataExplorer(model)
        }
      ])
    ]
  }
];
const expandedRowRenderer = (model, onViewInDataExplorer, dataview) => {
  const hasChildren = model.get('children') && model.get('children').size > 0;
  if (!hasChildren) {
    return null;
  }
  return (
    <ExpandableRow
      p="0px 0px 0px 28px"
      rowExpansionHeight={hasChildren ? 'min-content' : 0}
      style={{ boxShadow: 'none', borderBottom: '1px solid #e8e8e8' }}
    >
      {hasChildren && (
        <Box borderLeft="thin">
          <Table
            collection={model.get('children')}
            // hides the column headers for sub-tables
            hideHeader
            columns={getTableColumns(onViewInDataExplorer, dataview)}
            onRowClick={(m) => console.warn('row clicked', m.get())}
            showRowExpandedColumn={false}
            expandedRowRenderer={
              hasChildren ? (childModel) => expandedRowRenderer(childModel, onViewInDataExplorer) : undefined
            }
            rowAlign="center"
            className="sub-table-row"
          />
        </Box>
      )}
    </ExpandableRow>
  );
};

@observer
class TreeTable extends Component {
  componentDidMount() {
    const { collection } = this.props;
    collection.children.sort('percentage', 'desc');
  }

  render() {
    const { dataview, collection, onViewInDataExplorer } = this.props;

    return (
      <Table
        rowAlign="center"
        stickyHeader
        showRowExpandedColumn={false}
        onRowClick={(m) => console.warn('row clicked', m.get())}
        collection={collection.children}
        expandedRowRenderer={(model) => expandedRowRenderer(model, onViewInDataExplorer, dataview)}
        columns={getTableColumns(onViewInDataExplorer, dataview)}
        onRowMouseOver={(model) => {
          if (this.mouseoverModel) {
            this.mouseoverModel.set({ mouseover: false });
          }
          model.set({ mouseover: true });
          this.mouseoverModel = model;
        }}
        onRowMouseOut={(model) => {
          model.set({ mouseover: false });
          this.mouseoverModel = null;
        }}
      />
    );
  }
}

export default observer(TreeTable);
