/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Box } from 'core/components';
import Column from './Column';

export default function Header({
  dimensionColumns,
  valueColumns,
  showActionsMenu,
  actions,
  allowSelection,
  showSparklines,
  onColumnClick,
  sortState
}) {
  return (
    <>
      <colgroup>
        {allowSelection && <col key="row-select" style={{ width: 38 }} />}
        {showSparklines && <col key="sparkline-spacer" style={{ width: '92px' }} />}
        {dimensionColumns.map(({ key, width = '100%' }, index) => (
          <col key={`col-${key}-${index}`} style={{ width }} />
        ))}
        {valueColumns.map(({ key, width = 96 }, index) => (
          <col key={`col-${key}-${index}`} style={{ width, textAlign: 'right' }} />
        ))}
        {(showActionsMenu || actions) && <col key="actions" style={{ width: 38 }} />}
      </colgroup>

      <thead>
        <tr>
          {allowSelection && (
            <Box as="th" key="row-select">
              &nbsp;
            </Box>
          )}
          {showSparklines && (
            <Box as="th" key="sparkline-spacer">
              &nbsp;
            </Box>
          )}
          {dimensionColumns.map(({ key, label }, index) => (
            <Column
              key={`th-${key}-${index}`}
              field={key}
              label={label}
              sortState={sortState}
              justifyContent="flex-start"
              onClick={onColumnClick}
            />
          ))}
          {valueColumns.map(({ label, value, headerJustify }, index) => (
            <Column
              key={`th-${value}-${index}`}
              field={value}
              label={label}
              justifyContent={headerJustify}
              sortState={sortState}
              onClick={onColumnClick}
            />
          ))}
          {(showActionsMenu || actions) && (
            <Box as="th" key="actions">
              &nbsp;
            </Box>
          )}
        </tr>
      </thead>
    </>
  );
}
