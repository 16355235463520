import React from 'react';

import { Box, Text, LinkButton } from 'core/components';
import $devices from 'app/stores/device/$devices';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: ['kt_cloud_provider'],
  filterFields: ['kt_cloud_provider']
};

class CloudDependency extends AbstractDependency {
  get key() {
    return 'cloud';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get preventQuery() {
    return true;
  }

  get meets() {
    return $devices.hasAWSDevice || $devices.hasGCEDevice || $devices.hasOCIDevice || $devices.hasAzureDevice;
  }

  get message() {
    return (
      <>
        <Box mb="4px">
          <Text>
            <strong>Cloud</strong> devices are required by dimensions you&apos;re using, but have not been detected for
            any devices in your organization. Results shown herein may be highly inaccurate.
          </Text>
        </Box>
        <LinkButton text="Configure" icon="cog" small to="/v4/settings/clouds" />
      </>
    );
  }

  static queryMeets(query) {
    return (
      !queryContains(query, CONSTRAINTS) ||
      $devices.hasAWSDevice ||
      $devices.hasGCEDevice ||
      $devices.hasOCIDevice ||
      $devices.hasAzureDevice
    );
  }
}

export default CloudDependency;
