/**
 * iterates through each filter, calling the callback to map each one, to construct new filters
 * @param group
 * @param {(filter: Object) => Object} callback
 */
function mapFilters(group = {}, callback) {
  const filterGroups = Array.isArray(group.filterGroups) ? group.filterGroups.map((g) => mapFilters(g, callback)) : [];
  const filters = Array.isArray(group.filters) ? group.filters.map((filter) => callback(filter)).filter(Boolean) : [];
  return Object.assign({}, group, { filterGroups, filters });
}

/**
 * returns true if at least one filter causes the callback to return true
 * @param group
 * @param {(filter: Object) => boolean} callback
 * @returns {boolean}
 */
function someFilters(group, callback) {
  let result = false;

  if (group) {
    if (Array.isArray(group.filterGroups)) {
      result = group.filterGroups.some((g) => someFilters(g, callback));
    }

    if (!result && Array.isArray(group.filters)) {
      result = group.filters.some((g) => callback(g));
    }
  }

  return result;
}

function addFilterGroup(filters, filterGroup, connector = 'All') {
  if (filters?.filterGroups?.length > 0) {
    // add default top level connector if missing
    filters.connector ??= 'All';

    if (filters.connector === connector) {
      return Object.assign({}, filters, { filterGroups: filters.filterGroups.concat(filterGroup) });
    }

    return { connector, filterGroups: [{ filters: [], ...filters }, filterGroup] };
  }

  return { connector, filterGroups: [filterGroup] };
}

module.exports = { mapFilters, someFilters, addFilterGroup };
