import styled, { css } from 'styled-components';
import { space, width } from 'styled-system';
import { MenuDivider as BlueprintMenuDivider, Classes } from '@blueprintjs/core';

export const menuDividerStyles = css`
  &.${Classes.MENU_HEADER} {
    border-top: 0;
    padding: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray5};
  }

  .${Classes.HEADING} {
    color: ${({ theme }) => (theme.name === 'dark' ? theme.colors.gray5 : theme.colors.darkGray3)};
    font-size: 12px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1px;
    margin: 0;
  }
`;

const MenuDivider = styled(BlueprintMenuDivider)`
  ${space};
  ${width};
  ${menuDividerStyles};
`;

export default MenuDivider;
