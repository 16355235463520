import React from 'react';

import { Box, CopyText, Text } from 'core/components';

const InfoBox = ({ heading, value, children, textTransform, fontWeight = 'bold', hideIfEmpty, copyable, ...rest }) => {
  if (hideIfEmpty && !value) {
    return null;
  }
  return (
    <Box mb={2} {...rest}>
      <Text as="div" color="muted" fontWeight={fontWeight} fontSize="small" pb="4px">
        {heading}
      </Text>
      {copyable && <CopyText text={value} textProps={{ fontWeight, textTransform }} justifyContent="flex-start" />}
      {!copyable && (
        <Text fontWeight={fontWeight} as="div" textTransform={textTransform}>
          {value || children}
        </Text>
      )}
    </Box>
  );
};

export default InfoBox;
