import { RECENTLY_ADDED_DAYS } from 'shared/synthetics/constants';

import OciEntityExplorerWidget from 'app/components/decks/widgets/Cloud/entityExplorer/OciEntityExplorerWidget';
import AzureEntityExplorerWidget from 'app/components/decks/widgets/Cloud/entityExplorer/AzureEntityExplorerWidget';
import MeshesOverviewWidget from './Synthetics/MeshesOverviewWidget';
import AlertingHistoryWidget from './Alerting/AlertingHistoryWidget';
import ActiveAlertsWidget from './Alerting/ActiveAlertsWidget';
import ConfigStatusWidget from './Cloud/ConfigStatusWidget';
import GatewayTrafficWidget from './Cloud/GatewayTrafficWidget';
import AWSEntityExplorerWidget from './Cloud/entityExplorer/AWSEntityExplorerWidget';
import AWSRejectActionsWidget from './Cloud/AWSRejectActionsWidget';
import CapacityWidget from './Core/CapacityWidget';
import CloudFlowWidget from './Cloud/CloudFlowWidget';
import CloudTrafficOverviewWidget from './Cloud/CloudTrafficOverviewWidget';
import CostsWidget from './Edge/CostsWidget';
import CdnWidget from './ServiceProvider/CdnWidget';
import DataExplorerQueryWidget from './Core/DataExplorerQueryWidget';
import DdosWidget from './Protect/DdosWidget';
import SiteTrafficProfileWidget from './Core/SiteTrafficProfileWidget';
import CloudTrafficProfileWidget from './Cloud/CloudTrafficProfileWidget';
import OttWidget from './ServiceProvider/OttWidget';
import PeeringWidget from './Edge/PeeringWidget';
import TrafficOverviewWidget from './Core/TrafficOverviewWidget';
import TrafficBreakdownWidget from './Core/TrafficBreakdownWidget';
import SavedViewWidget from './Core/SavedViewWidget';
import AWSTransitGatewaysWidget from './Cloud/AWSTransitGatewaysWidget';
import RecentQueriesWidget from './Core/RecentQueriesWidget';
import InsightsOverviewWidget from './Insights/InsightsOverviewWidget';
import AgentsOverviewWidget from './Synthetics/AgentsOverviewWidget';
import RecentlyAddedTestsWidget from './Synthetics/RecentlyAddedTestsWidget';
import CreditUtilizationWidget from './Synthetics/CreditUtilizationWidget';
import TestStatusSummaryWidget from './Synthetics/TestStatusSummaryWidget';
import IncidentLogWidget from './Synthetics/IncidentLogWidget';
import ReconQueryWidget from './Recon/ReconQueryWidget';
import ReconCPUWidget from './Recon/ReconCPUWidget';
import ReconInterfaceStatusWidget from './Recon/ReconInterfaceStatusWidget';
import ReconMEMWidget from './Recon/ReconMEMWidget';
import ReconDeviceAvailabilityWidget from './Recon/ReconDeviceAvailabilityWidget';
import HealthMapWidget from './Protect/AlertingSiteHealthWidget';
import ReconInterfaceUtilizationWidget from './Recon/ReconInterfaceUtilizationWidget';
import KMIInsightWidget from './ServiceProvider/KMIInsightWidget';

/*
 * Each entry here must have a unique name, a title, description, category and Component.
 * Additionally it can provide:
 *  - canSelect - to say whether the widget can appear in the customize drawer
 *  - canView - to say whether the widget can be shown in a deck at all
 *  - layoutDefaults - to specify the default size and position of the widget
 */

export default {
  hmbs: {
    title: 'Health Map by Sites',
    description: 'View a map of your sites coded by alert severity with list of site alert counts by severity',
    category: 'Alerting',
    Component: HealthMapWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 12,
        h: 9,
        minH: 4,
        minW: 6
      },
      sm: {
        x: 0,
        y: 0,
        w: 6,
        h: 9,
        minH: 6,
        minW: 9
      }
    }
  },
  cloudTrafficOverview: {
    title: 'Cloud Traffic Overview',
    description:
      'Total Cloud traffic graph using Simple Traffic Profile into inbound, outbound, internal, through, and other',
    category: 'Cloud',
    Component: CloudTrafficOverviewWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 6,
        h: 7,
        minH: 2,
        minW: 4
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 3,
        minH: 2,
        minW: 1
      }
    }
  },
  trafficOverview: {
    title: 'Traffic Overview',
    description:
      'Total traffic graph using Simple Traffic Profile into inbound, outbound, internal, through, and other',
    category: 'Core',
    Component: TrafficOverviewWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 6,
        h: 7,
        minH: 2,
        minW: 5
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 3,
        minH: 2,
        minW: 1
      }
    }
  },
  trafficBreakdown: {
    title: 'Traffic Breakdown',
    description:
      'Internal, inbound, outbound, internal, through, and other traffic broken down by a user chosen dimension',
    category: 'Core',
    Component: TrafficBreakdownWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 7,
        h: 6,
        minH: 2,
        minW: 6
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 3,
        minH: 2,
        minW: 1
      }
    }
  },
  capacity: {
    title: 'Capacity Planning',
    description: 'Health overview of interfaces monitored via capacity plans',
    category: 'Core',
    Component: CapacityWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 3,
        h: 4,
        minH: 3,
        minW: 2
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 1,
        minH: 1,
        minW: 1
      }
    }
  },
  cloudFlow: {
    title: 'Public Cloud Flow',
    description: 'See total flows per second broken down by cloud type',
    category: 'Cloud',
    Component: CloudFlowWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 5,
        h: 5,
        minH: 3,
        minW: 3
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 1,
        minH: 1,
        minW: 1
      }
    }
  },
  cloudTrafficProfile: {
    title: 'Cloud - Simple Traffic Profile',
    description: 'Internal, Inbound and Outbound traffic though a cloud or cloud region',
    category: 'Cloud',
    Component: CloudTrafficProfileWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 3,
        h: 4,
        minH: 3,
        minW: 2
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 1,
        minH: 1,
        minW: 1
      }
    }
  },
  siteTrafficProfile: {
    title: 'Site - Simple Traffic Profile',
    description: 'Internal, Inbound and Outbound traffic though a single site',
    category: 'Core',
    Component: SiteTrafficProfileWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 3,
        h: 4,
        minH: 3,
        minW: 2
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 1,
        minH: 1,
        minW: 1
      }
    }
  },
  recentQueries: {
    title: 'Recent Data Explorer Queries',
    description: 'List of recent data explorer queries',
    category: 'Core',
    Component: RecentQueriesWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 5,
        h: 10,
        minH: 3,
        minW: 3
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 1,
        minH: 1,
        minW: 1
      }
    }
  },
  testStatusSummary: {
    title: 'Test Status Summary',
    description: 'See a summary of health statuses for all Synthetics tests',
    category: 'Synthetics',
    Component: TestStatusSummaryWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 12,
        h: 2,
        minH: 2,
        minW: 2
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 2,
        minW: 1
      }
    }
  },
  agentsOverview: {
    title: 'Synthetic Agents',
    description: 'See all synthetic agents grouped by agent type',
    category: 'Synthetics',
    Component: AgentsOverviewWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 4,
        h: 4,
        minH: 4,
        minW: 2
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 2,
        minW: 1
      }
    }
  },
  recentlyAddedTests: {
    title: 'Recently Added Tests',
    description: `See tests that have been added in the last ${RECENTLY_ADDED_DAYS} days, along with their health and test type`,
    category: 'Synthetics',
    Component: RecentlyAddedTestsWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 4,
        h: 4,
        minH: 4,
        minW: 2
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 2,
        minW: 1
      }
    }
  },
  creditUtilization: {
    title: 'Credit Utilization',
    description: 'See monthly credit utilization, including actual and projected usage',
    category: 'Synthetics',
    Component: CreditUtilizationWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 4,
        h: 4,
        minH: 4,
        minW: 2
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 2,
        minW: 1
      }
    }
  },
  meshes: {
    title: 'Synthetics Meshes',
    description: 'Add one or more mesh views from configured Network Mesh tests',
    category: 'Synthetics',
    Component: MeshesOverviewWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 6,
        h: 6,
        minH: 6,
        minW: 6
      },
      sm: {
        x: 0,
        y: 0,
        w: 4,
        h: 4,
        minH: 4,
        minW: 4
      }
    }
  },
  incidentLog: {
    title: 'Incident Log',
    description: 'Monitor Synthetic tests health by time rage',
    category: 'Synthetics',
    Component: IncidentLogWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 12,
        h: 12,
        minH: 8,
        minW: 6
      },
      sm: {
        x: 0,
        y: 0,
        w: 6,
        h: 10,
        minH: 8,
        minW: 4
      }
    }
  },
  cdn: {
    title: 'CDN Analytics',
    description: 'Overview of top CDNs on your network',
    category: 'Service Provider',
    Component: CdnWidget,
    canSelect: ({ $auth }) => $auth.hasPermission('cdn.enabled', { overrideForSudo: false }),
    canView: ({ $auth }) => $auth.hasPermission('cdn.enabled', { overrideForSudo: false }),
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 3,
        h: 3,
        minH: 3,
        minW: 2
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 3,
        minH: 2,
        minW: 1
      }
    }
  },
  cost: {
    title: 'Connectivity Costs',
    description: 'Overview of current billing cycle connectivity costs',
    category: 'Edge',
    Component: CostsWidget,
    canSelect: ({ $cost }) => $cost.canViewCosts,
    canView: ({ $cost }) => $cost.canViewCosts,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 2,
        h: 3,
        minH: 3,
        minW: 2
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 2,
        minW: 1
      }
    }
  },
  ddos: {
    title: 'DDoS Defense',
    description: 'Overview of DDoS attacks and mitigations',
    category: 'Protect',
    Component: DdosWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 2,
        h: 3,
        minH: 3,
        minW: 2
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 2,
        minW: 1
      }
    }
  },
  ott: {
    title: 'OTT Service Tracking',
    description: 'Overview of OTT traffic on your network',
    category: 'Service Provider',
    Component: OttWidget,
    canSelect: ({ $auth }) => $auth.hasPermission('ott.enabled', { overrideForSudo: false }),
    canView: ({ $auth }) => $auth.hasPermission('ott.enabled', { overrideForSudo: false }),
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 3,
        h: 3,
        minH: 3,
        minW: 2
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 3,
        minH: 2,
        minW: 1
      }
    }
  },
  peering: {
    title: 'Peering Overview',
    description: 'See total inbound and outbound peering traffic broken out by specific connectivity type',
    category: 'Edge',
    Component: PeeringWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 3,
        h: 4,
        minH: 3,
        minW: 3
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 2,
        minW: 1
      }
    }
  },
  vpcByTransitGateway: {
    title: 'AWS VPC traffic by Transit Gateway',
    description: 'See flow between VPCs on select transit gateways',
    category: 'Cloud',
    Component: AWSTransitGatewaysWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 4,
        h: 6,
        minH: 3,
        minW: 3
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 2,
        minW: 1
      }
    }
  },
  dataExplorerQuery: {
    title: 'Data Explorer Query',
    description: 'A simple wrapper around a DE query',
    category: 'Core',
    canSelect: false,
    Component: DataExplorerQueryWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 4,
        h: 6,
        minH: 4,
        minW: 4
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 2,
        minW: 1
      }
    }
  },
  insightsOverview: {
    title: 'Insights Overview',
    description: "An at a glance view of your network's most important insights",
    category: 'Insights',
    Component: InsightsOverviewWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 4,
        h: 6,
        minH: 3,
        minW: 2
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 2,
        minW: 1
      }
    }
  },
  kmiInsights: {
    title: 'KMI Insights',
    description: 'List of KMI Insights',
    category: 'Service Provider',
    Component: KMIInsightWidget,
    canSelect: ({ $auth }) => $auth.hasPermission('marketIntel.enabled', { overrideForSudo: false }),
    canView: ({ $auth }) => $auth.hasPermission('marketIntel.enabled', { overrideForSudo: false }),
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 3,
        h: 8,
        minH: 3,
        minW: 2
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 2,
        minW: 1
      }
    }
  },
  awsConfigStatus: {
    title: 'Cloud Configuration Status Summary',
    description: 'See a summary of cloud account configuration statuses by region',
    category: 'Cloud',
    Component: ConfigStatusWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 2,
        h: 4,
        minH: 3,
        minW: 2
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 2,
        minW: 1
      }
    }
  },
  awsInventory: {
    title: 'AWS Entity Explorer',
    description: 'Explore all your AWS entities and their details',
    category: 'Cloud',
    Component: AWSEntityExplorerWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 4,
        h: 6,
        minH: 5,
        minW: 4
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 1,
        minW: 1
      }
    }
  },
  azureInventory: {
    title: 'Azure Entity Explorer',
    description: 'Explore all your Azure entities and their details',
    category: 'Cloud',
    Component: AzureEntityExplorerWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 4,
        h: 6,
        minH: 5,
        minW: 4
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 1,
        minW: 1
      }
    }
  },
  ociInventory: {
    title: 'OCI Entity Explorer',
    description: 'Explore all your OCI entities and their details',
    category: 'Cloud',
    Component: OciEntityExplorerWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 4,
        h: 6,
        minH: 5,
        minW: 4
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 1,
        minW: 1
      }
    }
  },
  gatewayTraffic: {
    title: 'AWS Gateway Traffic',
    description:
      'Breakdown of last hour traffic across gateway types in select region(s) with configurable comparison time frame',
    category: 'Cloud',
    Component: GatewayTrafficWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 4,
        h: 6,
        minH: 3,
        minW: 3
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minH: 2,
        minW: 1
      }
    }
  },
  awsRejectActions: {
    title: 'AWS Security Group Reject Actions',
    description:
      'See count of Firewall REJECT Actions on Inbound, Outbound and Internal traffic by VPC, Subnet, ASN and more',
    category: 'Cloud',
    Component: AWSRejectActionsWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 4,
        h: 6,
        minH: 3,
        minW: 3
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 3,
        minH: 2,
        minW: 1
      }
    }
  },
  savedViewBrowser: {
    title: 'Saved View',
    description: 'Choose a Saved View to display',
    category: 'Core',
    Component: SavedViewWidget,
    layoutDefaults: {
      lg: {
        x: 0,
        y: 0,
        w: 5,
        h: 6,
        minH: 3,
        minW: 3
      },
      sm: {
        x: 0,
        y: 0,
        w: 1,
        h: 3,
        minH: 2,
        minW: 1
      }
    }
  },
  reconQueryWidget: {
    title: 'Recon Chart',
    category: 'NMS',

    // can't select from the Add Visualization drawer, only from ME for now
    canSelect: false,
    description: 'Create a chart from a Metrics Explorer query',
    Component: ReconQueryWidget,
    layoutDefaults: {
      lg: {
        w: 6,
        h: 4,
        minH: 4,
        minW: 4
      },
      sm: {
        minH: 2,
        minW: 1
      }
    }
  },
  reconCpuWidget: {
    title: 'Devices with Highest CPU',
    category: 'NMS',
    description: 'Top devices by CPU utilization',
    Component: ReconCPUWidget,
    canSelect: ({ $auth }) => $auth.hasPermission('recon.enabled', { overrideForSudo: false }),
    layoutDefaults: {
      lg: {
        w: 4,
        h: 4,
        minH: 3,
        minW: 3,
        x: 0,
        y: 0
      },
      sm: {
        minH: 2,
        minW: 1
      }
    }
  },
  reconMemWidget: {
    title: 'Devices with Highest Memory',
    category: 'NMS',
    description: 'Top devices by memory utilization',
    Component: ReconMEMWidget,
    canSelect: ({ $auth }) => $auth.hasPermission('recon.enabled', { overrideForSudo: false }),
    layoutDefaults: {
      lg: {
        w: 4,
        h: 4,
        minH: 3,
        minW: 3,
        x: 0,
        y: 0
      },
      sm: {
        minH: 2,
        minW: 1
      }
    }
  },
  reconInterfaceUtilizationWidget: {
    title: 'Interface Utilization',
    category: 'NMS',
    description: 'Top devices by interface utilization',
    canSelect: ({ $auth }) => $auth.hasPermission('recon.enabled', { overrideForSudo: false }),
    Component: ReconInterfaceUtilizationWidget,
    layoutDefaults: {
      lg: {
        w: 6,
        h: 6,
        minH: 3,
        minW: 3,
        x: 0,
        y: 0
      },
      sm: {
        minH: 2,
        minW: 1
      }
    }
  },
  reconInterfaceStatusWidget: {
    title: 'Interface Status',
    category: 'NMS',
    description: 'List of your top interfaces and their status',
    Component: ReconInterfaceStatusWidget,
    canSelect: ({ $auth }) => $auth.hasPermission('recon.enabled', { overrideForSudo: false }),
    layoutDefaults: {
      lg: {
        w: 6,
        h: 6,
        minH: 3,
        minW: 4
      },
      sm: {
        minH: 2,
        minW: 1
      }
    }
  },
  alertingHistoryWidget: {
    title: 'Alert History',
    category: 'NMS',
    description: 'Graph alerts triggered per day and inspect a list to understand your alert history.',
    Component: AlertingHistoryWidget,
    layoutDefaults: {
      lg: {
        w: 6,
        h: 12,
        minH: 3,
        minW: 3
      },
      sm: {
        minH: 6,
        minW: 6
      }
    }
  },
  activeAlertsWidget: {
    title: 'Active Alerts',
    category: 'NMS',
    description: 'Pie chart of active alerts by policy and a list with more details.',
    Component: ActiveAlertsWidget,
    layoutDefaults: {
      lg: {
        w: 6,
        h: 12,
        minH: 3,
        minW: 3
      },
      sm: {
        minH: 6,
        minW: 6
      }
    }
  },
  reconDevicesAvailability: {
    title: 'Device Availability',
    category: 'NMS',
    description: 'List of your top devices and their availability',
    Component: ReconDeviceAvailabilityWidget,
    canSelect: ({ $auth }) => $auth.hasPermission('recon.enabled', { overrideForSudo: false }),
    layoutDefaults: {
      lg: {
        w: 6,
        h: 4,
        minH: 2,
        minW: 2
      },
      sm: {
        minH: 4,
        minW: 4
      }
    }
  }
};
