import React from 'react';
import { observer } from 'mobx-react';
import { Position } from '@blueprintjs/core';
import { Popover } from 'core/components';

const DropdownMenu = ({ children, className, ...props }) => (
  <Popover position={Position.BOTTOM_RIGHT} {...props} popoverClassName={className} openOnTargetFocus minimal>
    {children}
  </Popover>
);

export default observer(DropdownMenu);
