/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { FiActivity } from 'react-icons/fi';
import { ReactComponent as VpcIcon } from 'app/assets/icons/vpc.svg';
import { ReactComponent as SubnetIcon } from 'app/assets/icons/subnet.svg';
import { ReactComponent as InstanceIcon } from 'app/assets/icons/cloud-instance.svg';
import { ReactComponent as DirectConnectIcon } from 'app/assets/icons/direct-connection.svg';
import { ReactComponent as TransitGatewayIcon } from 'app/assets/icons/transit-gateway.svg';
import { ReactComponent as NatGatewayIcon } from 'app/assets/icons/nat-gateway.svg';
import { ReactComponent as VirtualGatewayIcon } from 'app/assets/icons/vpn-gateway.svg';
import { ReactComponent as InternetGatewayIcon } from 'app/assets/icons/internet-gateway.svg';
import { ReactComponent as DirectConnectGatewayIcon } from 'app/assets/icons/direct-connect-gateway.svg';
import { Box, CopyText, Flex, Icon, InfoBox, LinkButton, Text, FlexColumn } from 'core/components';
import ExpandableRow from 'app/components/admin/ExpandableRow';
import { EntityIdPopover, EntitySparkline, TagPopover, TablePopover, NameIdState } from './EntityExplorerRenderers';

export const generateEntityMap = ({ jumpToFn }) => {
  const ENTITY_MAP = {
    region: {
      label: 'Regions',
      icon: 'globe-network',
      rowHeight: 28,
      expandHeight: 170,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { Name, AvailabilityZones, InternetGateways, TransitGateways, Vpcs, VpnGateways } = model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP.region.expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  <InfoBox heading="Transit Gateways" fontWeight="medium">
                    {TransitGateways.length}
                  </InfoBox>
                  <InfoBox heading="Internet Gateways" fontWeight="medium">
                    {Object.keys(InternetGateways).length}
                  </InfoBox>
                  <InfoBox heading="Vpn Gateways" mb={0} fontWeight="medium">
                    {Object.keys(VpnGateways).length}
                  </InfoBox>
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  <LinkButton
                    icon={FiActivity}
                    iconSize={16}
                    to={`/v4/core/quick-views/cloud/aws/region/${Name}`}
                    blank
                    small
                    mb={2}
                  >
                    Open Quick-View
                  </LinkButton>
                  <InfoBox heading="Vpcs" fontWeight="medium">
                    {Vpcs.length}
                  </InfoBox>
                  <InfoBox heading="Availability Zones" mb={0} fontWeight="medium">
                    {AvailabilityZones.length}
                  </InfoBox>
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Region',
            renderer: ({ value }) => <Text fontWeight="bold">{value}</Text>
          },
          {
            name: 'AvailabilityZones',
            label: 'Zones',
            ellipsis: false,
            renderer: ({ value }) => <Text>{value.length}</Text>
          },
          {
            name: 'Vpcs',
            label: 'VPCs',
            ellipsis: false,
            renderer: ({ value }) => <Text>{value.length}</Text>
          }
        ]
      }
    },
    vpc: {
      label: 'VPCs',
      icon: VpcIcon,
      expandHeight: 210,
      searchable: true,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { id, OwnerId, RegionName, DhcpOptionsId, InstanceTenancy, Tags } = model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP.vpc.expandHeight} p={1}>
              <Flex>
                <Flex flex={2}>
                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="VPC ID" value={id} fontWeight="medium" copyable />
                    <InfoBox heading="Owner Account ID" value={OwnerId} fontWeight="medium" copyable />
                    <InfoBox heading="RegionName" value={RegionName} fontWeight="medium" mb={0} />
                  </FlexColumn>
                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="DhcpOptionsId" value={DhcpOptionsId} fontWeight="medium" copyable />
                    <InfoBox heading="InstanceTenancy" value={InstanceTenancy} fontWeight="medium" />
                  </FlexColumn>
                </Flex>

                <FlexColumn alignItems="flex-start" minWidth={145} flex={1} p={1}>
                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/aws?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: 'Vpcs', value: id })
                    )}`}
                    blank
                    small
                    mb={1}
                  >
                    Open in Map
                  </LinkButton>
                  <TablePopover data={Tags} title="Tags" hideHeader py={1} />
                  <LinkButton
                    icon={FiActivity}
                    iconSize={16}
                    to={`/v4/core/quick-views/cloud/aws/vpc_id/${id}`}
                    blank
                    small
                    my={1}
                  >
                    Open Quick-View
                  </LinkButton>
                </FlexColumn>
              </Flex>
              <EntitySparkline id={id} filter="kt_aws_src|dst_vpc_id" />
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / Id',
            renderer: ({ value, model }) => {
              const { Name, State } = model.get();
              return <NameIdState name={Name} id={value} state={State} />;
            }
          },
          {
            name: 'CidrBlock',
            label: 'CidrBlock'
          }
        ]
      }
    },
    subnet: {
      label: 'Subnets',
      icon: SubnetIcon,
      dimension: 'kt_aws_src_subnet_id',
      searchable: true,
      expandHeight: 310,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const {
            id,
            OwnerId,
            SubnetArn,
            DefaultForAz,
            Ipv6CidrBlockAssociationSet,
            VpcId,
            AvailabilityZone,
            AvailabilityZoneId,
            AvailableIpAddressCount,
            Tags
          } = model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP.subnet.expandHeight} p={1}>
              <Flex mb={2}>
                <FlexColumn alignItems="flex-start" flex={2} pt={1} px={1}>
                  <InfoBox heading="Subnet ID" value={id} fontWeight="medium" copyable />
                  <InfoBox heading="Owner Account ID" value={OwnerId} fontWeight="medium" copyable />
                  <InfoBox heading="Available IPs" fontWeight="medium">
                    {AvailableIpAddressCount}
                  </InfoBox>
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={2}>
                  <EntityIdPopover
                    title="Vpc ID"
                    id={VpcId}
                    entityType="VPC"
                    detailType="vpc_id"
                    mapType="Vpcs"
                    jumpToFn={jumpToFn}
                  />
                  <InfoBox heading="Availability Zone Name" value={AvailabilityZone} fontWeight="medium" px={1} />
                  <InfoBox heading="Availability Zone ID" value={AvailabilityZoneId} fontWeight="medium" px={1} />
                  <TablePopover
                    data={Ipv6CidrBlockAssociationSet}
                    buttonText="Show Ipv6 CidrBlock Associations"
                    icon="ip-address"
                    iconSize={16}
                    labels={['Association Id', 'IPV6 Cidr Block']}
                    copyKey
                    px={1}
                  />
                </FlexColumn>

                <FlexColumn alignItems="flex-start" minWidth={145} flex={1} p={1}>
                  <TagPopover text="Default for AZ" value={DefaultForAz} pb={1} />
                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/aws?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: 'Subnets', value: id })
                    )}`}
                    blank
                    small
                    my={1}
                  >
                    Open in Map
                  </LinkButton>
                  <TablePopover data={Tags} title="Tags" hideHeader py={1} />
                  <LinkButton
                    icon={FiActivity}
                    iconSize={16}
                    to={`/v4/core/quick-views/cloud/aws/subnet_id/${id}`}
                    blank
                    small
                    my={1}
                  >
                    Open Quick-View
                  </LinkButton>
                </FlexColumn>
              </Flex>
              <Box height={100}>
                <InfoBox
                  heading="Subnet Arn"
                  value={SubnetArn}
                  fontWeight="medium"
                  hideIfEmpty
                  copyable
                  px={1}
                  border="1px hidden"
                  mb={3}
                />
                <EntitySparkline id={id} filter="kt_aws_src|dst_subnet_id" />
              </Box>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / Id',
            renderer: ({ value, model }) => {
              const { Name, State } = model.get();
              return <NameIdState name={Name} id={value} state={State} />;
            }
          },
          {
            name: 'CidrBlock',
            label: 'CidrBlock'
          }
        ]
      }
    },
    instance: {
      label: 'Instances',
      icon: InstanceIcon,
      renderer: ({ list }) => {
        const { running, stopped } = list.models.reduce(
          (acc, model) => ({
            running: (acc.running += model.get('running', 0)),
            stopped: (acc.stopped += model.get('stopped', 0))
          }),
          { running: 0, stopped: 0 }
        );

        return (
          <Flex flexWrap="wrap" justifyContent="flex-end">
            <Flex alignItems="center" maxHeight="18px">
              <Icon icon="dot" color="success" iconSize={26} />
              <Text as="div" fontWeight="bold" ml="-4px">
                {running}
              </Text>
            </Flex>
            <Flex alignItems="center" maxHeight="18px">
              <Icon icon="dot" color="danger" iconSize={26} />
              <Text as="div" fontWeight="bold" ml="-4px">
                {stopped}
              </Text>
            </Flex>
          </Flex>
        );
      },
      rowHeight: 30,
      tableProps: {
        selectOnRowClick: false,
        columns: [
          {
            name: 'name',
            label: 'Region',
            ellipsis: false,
            renderer: ({ value }) => <Text fontWeight="bold">{value}</Text>
          },
          {
            name: 'pending',
            label: (
              <Flex alignItems="center" maxHeight="15px">
                <Icon icon="dot" color="warning" iconSize={26} />
                <Text as="div" fontWeight="bold" ml="-4px">
                  Pending
                </Text>
              </Flex>
            ),
            wrapText: false,
            ellipsis: false,
            renderer: ({ value }) => {
              if (value) {
                return (
                  <Text as="div" fontWeight="bold" color="warning" pl={1}>
                    {value}
                  </Text>
                );
              }
              return null;
            }
          },
          {
            name: 'running',
            label: (
              <Flex alignItems="center" maxHeight="15px">
                <Icon icon="dot" color="success" iconSize={26} />
                <Text as="div" fontWeight="bold" ml="-4px">
                  Running
                </Text>
              </Flex>
            ),
            wrapText: false,
            ellipsis: false,
            renderer: ({ value }) => (
              <Text as="div" fontWeight="bold" color="success" pl={1}>
                {value}
              </Text>
            )
          },
          {
            name: 'shutting-down',
            label: (
              <Flex alignItems="center" maxHeight="15px">
                <Icon icon="dot" color="gray" iconSize={26} />
                <Text as="div" fontWeight="bold" ml="-4px">
                  Shutting Down
                </Text>
              </Flex>
            ),
            wrapText: false,
            ellipsis: false,
            renderer: ({ value }) => (
              <Text as="div" fontWeight="bold" color="gray" pl={1}>
                {value}
              </Text>
            )
          },
          {
            name: 'stopped',
            label: (
              <Flex alignItems="center" maxHeight="15px">
                <Icon icon="dot" color="danger" iconSize={26} />
                <Text as="div" fontWeight="bold" ml="-4px">
                  Stopped
                </Text>
              </Flex>
            ),
            wrapText: false,
            ellipsis: false,
            renderer: ({ value }) => (
              <Text as="div" fontWeight="bold" color="danger" pl={1}>
                {value}
              </Text>
            )
          },
          {
            name: 'terminated',
            label: (
              <Flex alignItems="center" maxHeight="15px">
                <Icon icon="dot" iconSize={26} />
                <Text as="div" fontWeight="bold" ml="-4px">
                  Terminated
                </Text>
              </Flex>
            ),
            wrapText: false,
            ellipsis: false,
            renderer: ({ value }) => (
              <Text as="div" fontWeight="bold" pl={1}>
                {value}
              </Text>
            )
          }
        ]
      }
    },
    directConnect: {
      label: 'Direct Connections',
      icon: DirectConnectIcon,
      dimension: null,
      searchable: true,
      expandHeight: 320,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const {
            id,
            ConnectionName,
            ConnectionState,
            AwsDevice,
            AwsDeviceV2,
            Bandwidth,
            Region,
            OwnerAccount,
            EncryptionMode,
            HasLogicalRedundancy,
            JumboFrameCapable,
            Location,
            MacSecCapable,
            PortEncryptionStatus,
            ConnectionId,
            Tags
          } = model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP.directConnect.expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  <InfoBox heading="Owner Account ID" value={OwnerAccount} fontWeight="medium" copyable />
                  <InfoBox heading="Connection Name" value={ConnectionName} fontWeight="medium" copyable />
                  <InfoBox heading="AWS Device" value={AwsDevice} fontWeight="medium" copyable />
                  <InfoBox heading="Encryption Mode" value={EncryptionMode} fontWeight="medium" />
                  <InfoBox heading="Region" value={Region} fontWeight="medium" />
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  <InfoBox heading="Connection Id" value={ConnectionId} fontWeight="medium" copyable />
                  <InfoBox heading="Connection State" value={ConnectionState} fontWeight="medium" />
                  <InfoBox heading="AWS Device V2" value={AwsDeviceV2} fontWeight="medium" copyable />
                  <InfoBox heading="Port Encryption Status" value={PortEncryptionStatus} fontWeight="medium" />
                  <InfoBox heading="Location" value={Location} fontWeight="medium" copyable />
                </FlexColumn>
                <FlexColumn alignItems="flex-start" minWidth={175} flex={1} p={1}>
                  <InfoBox heading="Bandwidth" value={Bandwidth} fontWeight="medium" />
                  <TagPopover text="Logical Redundancy" value={HasLogicalRedundancy} successState="yes" mb={1} />
                  <TagPopover text="Jumbo Frame Capable" value={JumboFrameCapable} my={1} />
                  <TagPopover text="Mac Sec Capable" value={MacSecCapable} my={1} />

                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/aws?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: 'DirectConnections', value: id })
                    )}`}
                    blank
                    small
                    my={1}
                  >
                    Open in Map
                  </LinkButton>

                  <TablePopover data={Tags} title="Tags" hideHeader py={1} />
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ value, model }) => <NameIdState name={model.get('ConnectionName')} id={value} />
          },
          {
            name: 'Bandwidth',
            label: 'Bandwidth'
          }
        ]
      }
    },
    directGateway: {
      label: 'Direct Connect Gateways',
      icon: DirectConnectGatewayIcon,
      searchable: true,
      expandHeight: 200,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { id, OwnerAccount, AmazonSideAsn, DirectConnectGatewayName, Associations, Attachments } = model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP.directGateway.expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  <InfoBox heading="Owner Account ID" value={OwnerAccount} fontWeight="medium" copyable />
                  <InfoBox heading="Amazon Side ASN" value={AmazonSideAsn} fontWeight="medium" copyable />
                  <Box>
                    <Text as="div" color="muted" fontWeight="bold" fontSize="small" pb="4px">
                      Attached Interface Ids
                    </Text>
                    {Attachments.map((att) => (
                      <Text key={att.VirtualInterfaceId} fontWeight="bold" pb={1}>
                        {att.VirtualInterfaceId}
                      </Text>
                    ))}
                  </Box>
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  <InfoBox heading="Gateway ID" value={id} fontWeight="medium" copyable />
                  <InfoBox heading="Gateway Name" value={DirectConnectGatewayName} fontWeight="medium" copyable />
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={1} minWidth={175}>
                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/aws?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: 'DirectConnectGateways', value: id })
                    )}`}
                    blank
                    small
                    m={1}
                  >
                    Open in Map
                  </LinkButton>

                  <Box py={1}>
                    <Text as="div" color="muted" fontWeight="bold" fontSize="small" pb="4px" pl={1}>
                      Associated Gateway Ids
                    </Text>
                    {Associations.map((ass) => (
                      <Box key={ass.AssociationId}>
                        <EntityIdPopover
                          id={ass.AssociatedGateway.Id}
                          entityType={ass.AssociatedGateway.Id.startsWith('tgw') ? 'Transit' : 'Virtual'}
                          mapType={ass.AssociatedGateway.Id.startsWith('tgw') ? 'TransitGateways' : 'VpnGateways'}
                          jumpToFn={jumpToFn}
                        />
                      </Box>
                    ))}
                  </Box>
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ value, model }) => {
              const { DirectConnectGatewayName, DirectConnectGatewayState } = model.get();
              return <NameIdState name={DirectConnectGatewayName} id={value} state={DirectConnectGatewayState} />;
            }
          },
          {
            name: 'Associations',
            label: 'Associations',
            align: 'center',
            ellipsis: false,
            renderer: ({ value }) => <Text>{value.length}</Text>
          }
        ]
      }
    },
    internet: {
      label: 'Internet Gateways',
      icon: InternetGatewayIcon,
      searchable: true,
      expandHeight: 140,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { id, OwnerId, Attachments, RegionName, Tags } = model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP.internet.expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  <InfoBox heading="Gateway ID" value={id} fontWeight="medium" copyable />
                  <InfoBox heading="Owner ID" value={OwnerId} fontWeight="medium" copyable />
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={2}>
                  {Attachments[0] && (
                    <EntityIdPopover
                      title="Attached Vpc"
                      id={Attachments[0].VpcId}
                      entityType="VPC"
                      detailType="vpc_id"
                      mapType="Vpcs"
                      jumpToFn={jumpToFn}
                    />
                  )}

                  <InfoBox heading="Region" value={RegionName} fontWeight="medium" px={1} />
                </FlexColumn>
                <FlexColumn alignItems="flex-start" minWidth={125} flex={1} p={1}>
                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/aws?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: 'InternetGateways', value: id })
                    )}`}
                    blank
                    small
                    mb={1}
                  >
                    Open in Map
                  </LinkButton>

                  <TablePopover data={Tags} title="Tags" hideHeader py={1} />
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ value, model }) => <NameIdState name={model.get('Name')} id={value} />
          }
        ]
      }
    },
    nat: {
      label: 'NAT Gateways',
      icon: NatGatewayIcon,
      searchable: true,
      expandHeight: 160,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { NatGatewayId, NatGatewayAddresses, VpcId, SubnetId, Tags } = model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP.nat.expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  <InfoBox heading="Gateway ID" value={NatGatewayId} fontWeight="medium" copyable />
                  <Text as="div" color="muted" fontWeight="bold" fontSize="small" pb="4px">
                    Gateway Addresses (Public IPs)
                  </Text>
                  {NatGatewayAddresses.map((addr) => (
                    <CopyText
                      key={addr.PublicIp}
                      text={addr.PublicIp}
                      textProps={{ fontWeight: 'bold' }}
                      justifyContent="flex-start"
                      pb={1}
                    />
                  ))}

                  <Text as="div" color="muted" fontWeight="bold" fontSize="small" pb="4px">
                    Gateway Addresses (Private IPs)
                  </Text>
                  {NatGatewayAddresses.map((addr) => (
                    <CopyText
                      key={addr.PrivateIp}
                      text={addr.PrivateIp}
                      textProps={{ fontWeight: 'bold' }}
                      justifyContent="flex-start"
                      pb={1}
                    />
                  ))}
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={2}>
                  <EntityIdPopover
                    title="Vpc ID"
                    id={VpcId}
                    entityType="VPC"
                    detailType="vpc_id"
                    mapType="Vpcs"
                    jumpToFn={jumpToFn}
                  />
                  <EntityIdPopover
                    title="Subnet ID"
                    id={SubnetId}
                    entityType="Subnet"
                    detailType="subnet_id"
                    mapType="Subnets"
                    jumpToFn={jumpToFn}
                  />
                </FlexColumn>
                <FlexColumn alignItems="flex-start" minWidth={125} flex={1} p={1}>
                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/aws?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: 'NatGateways', value: NatGatewayId })
                    )}`}
                    blank
                    small
                    mb={1}
                  >
                    Open in Map
                  </LinkButton>

                  <TablePopover data={Tags} title="Tags" hideHeader py={1} />
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ model }) => {
              const { id, Name, State } = model.get();
              return <NameIdState name={Name} id={id} state={State} />;
            }
          }
        ]
      }
    },
    transit: {
      label: 'Transit Gateways',
      icon: TransitGatewayIcon,
      searchable: true,
      expandHeight: 220,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { id, OwnerId, TransitGatewayArn, RegionName, TransitGatewayRouteTables, Options, Tags } = model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP.transit.expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  <InfoBox heading="Gateway ID" value={id} fontWeight="medium" copyable />
                  <InfoBox heading="Owner ID" value={OwnerId} fontWeight="medium" copyable />
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  <InfoBox heading="Region" value={RegionName} fontWeight="medium" />
                  {TransitGatewayRouteTables && (
                    <>
                      <Text as="div" color="muted" fontWeight="bold" fontSize="small" pb="4px">
                        Route Table Ids:
                      </Text>
                      {Object.values(TransitGatewayRouteTables).map((table) => (
                        <CopyText
                          key={table.TransitGatewayRouteTableId}
                          text={table.TransitGatewayRouteTableId}
                          justifyContent="flex-start"
                          textProps={{ fontWeight: 'bold' }}
                          pb={1}
                        />
                      ))}
                    </>
                  )}
                </FlexColumn>
                <FlexColumn alignItems="flex-start" minWidth={125} flex={1} p={1}>
                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/aws?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: 'TransitGateways', value: id })
                    )}`}
                    blank
                    small
                    mb={1}
                  >
                    Open in Map
                  </LinkButton>

                  <TablePopover data={Options} title="Options" icon="settings" hideHeader py={1} />
                  <TablePopover data={Tags} title="Tags" hideHeader py={1} />
                </FlexColumn>
              </Flex>
              <InfoBox heading="Transit Gateway Arn" value={TransitGatewayArn} fontWeight="medium" copyable px={1} />
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ value, model }) => {
              const { Name, State } = model.get();
              return <NameIdState name={Name} id={value} state={State} />;
            }
          },
          {
            name: 'TransitGatewayRouteTables',
            label: 'Routes',
            align: 'center',
            ellipsis: false,
            renderer: ({ value }) => {
              if (value) {
                const total = Object.values(value).reduce((acc, curr) => acc + curr.NumRoutes, 0);
                return <Text>{total}</Text>;
              }
              return '---';
            }
          }
        ]
      }
    },
    virtual: {
      label: 'Virtual Gateways',
      icon: VirtualGatewayIcon,
      searchable: true,
      expandHeight: 160,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { id, Type, AmazonSideAsn, VpcAttachments, Tags } = model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP.virtual.expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={2} p={1}>
                  <InfoBox heading="Gateway ID" value={id} fontWeight="medium" copyable />
                  <InfoBox heading="Type" value={Type} fontWeight="medium" copyable />
                </FlexColumn>
                <FlexColumn alignItems="flex-start" flex={2}>
                  <InfoBox
                    heading="Amazon Side ASN"
                    value={AmazonSideAsn}
                    fontWeight="medium"
                    copyable
                    p="8px 8px 0 8px"
                    mb={1}
                  />
                  {VpcAttachments && (
                    <>
                      <Text as="div" color="muted" fontWeight="bold" fontSize="small" p="8px 8px 4px 8px">
                        Attached VPC(s):
                      </Text>
                      {VpcAttachments.map((att) => (
                        <Box key={att.VpcId}>
                          <EntityIdPopover
                            id={att.VpcId}
                            entityType="VPC"
                            detailType="vpc_id"
                            mapType="Vpcs"
                            jumpToFn={jumpToFn}
                          />
                        </Box>
                      ))}
                    </>
                  )}
                </FlexColumn>
                <FlexColumn alignItems="flex-start" minWidth={125} flex={1} p={1}>
                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/aws?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: 'VpnGateways', value: id })
                    )}`}
                    blank
                    small
                    mb={1}
                  >
                    Open in Map
                  </LinkButton>

                  <TablePopover data={Tags} title="Tags" hideHeader py={1} />
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'key',
            label: 'Name / ID',
            renderer: ({ value, model }) => {
              const { State, Tags } = model.get();
              const Name = Tags.find((tag) => tag.Key === 'Name')?.Value;
              return <NameIdState name={Name} id={value} state={State} />;
            }
          }
        ]
      }
    }
  };
  return ENTITY_MAP;
};
