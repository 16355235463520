/**
 * Applies necessary transforms for the filters from the alerting API
 */

const connectors = { all: 'All', any: 'Any' };
export const operators = {
  equals: '=',
  notEquals: '<>',
  bitwiseAnd: '&',
  bitwiseOr: '|',
  lessThan: '<',
  greaterThan: '>',
  lessThanOrEquals: '<=',
  greaterThanOrEquals: '>=',
  notContains: 'NOT ILIKE',
  contains: 'ILIKE',
  notContainsRe: '!~',
  containsRe: '~',
  notContainsReStar: '!~*',
  containsReStar: '~*'
};

const defaultFilterGroup = {
  autoAdded: '',
  name: '',
  named: false,
  not: false,
  filters: [],
  filterGroups: [],
  saved_filters: []
};

function transformFilter(filter) {
  if (operators[filter.operator]) {
    filter.operator = operators[filter.operator];
  }

  filter.filterValue = filter.filterValue || '';

  return filter;
}

function transformFilterGroup(filterGroup) {
  if (connectors[filterGroup.connector]) {
    filterGroup.connector = connectors[filterGroup.connector];
  }

  if (filterGroup.filters) {
    filterGroup.filters = filterGroup.filters.map(transformFilter);
  }

  if (filterGroup.filterGroups) {
    filterGroup.filterGroups = filterGroup.filterGroups.map(transformFilterGroup);
  }

  // Alerting/Insight API does not match our casing and they aren't changing it because
  // they will be responsible for the public API soon, and want it to be consistent
  if (filterGroup.savedFilters) {
    filterGroup.saved_filters = filterGroup.savedFilters.map((savedFilter) => ({
      filter_id: parseInt(savedFilter.id),
      is_not: !!savedFilter.not
    }));

    delete filterGroup.savedFilters;
  }

  return { ...defaultFilterGroup, ...filterGroup };
}

export default function transformPolicyFilters(filterGroup = {}) {
  return transformFilterGroup(filterGroup);
}
