import { Intent } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';

import { Field, TagInput } from 'core/form';
import Collection from 'core/model/Collection';
import EmptyState from 'core/components/EmptyState';
import { Filter, Table } from 'core/components/table';
import { Button, ButtonGroupTab, ButtonGroupTabs, Callout, Card, CopyText, Dialog, Flex, Text } from 'core/components';

const TAB_ALL_CHILD_ACCOUNTS = 'all_child_accounts';
const TAB_ALLOW_LIST_ACCOUNTS = 'allow_list_accounts';
const TAB_BLOCK_LIST_ACCOUNTS = 'block_list_accounts';

const collection = new Collection([], {
  sortState: { field: 'initialSelected', direction: 'desc' }
});

const columns = [
  {
    name: 'Name',
    label: 'Name',
    width: 250,
    renderer: ({ value }) => (
      <CopyText text={value} textProps={{ fontWeight: 'bold' }} justifyContent="flex-start" pb={1} />
    )
  },
  {
    name: 'Email',
    label: 'Email',
    width: 450,
    renderer: ({ value }) => (
      <CopyText text={value} textProps={{ fontWeight: 'bold' }} justifyContent="flex-start" pb={1} />
    )
  },
  {
    name: 'Arn',
    label: 'Arn',
    renderer: ({ value }) => (
      <CopyText text={value} textProps={{ fontWeight: 'bold' }} justifyContent="flex-start" pb={1} />
    )
  }
];

const AdditionalAccountsSelectorPanel = ({ form, $clouds }) => {
  const allowedAccounts = form.getValue('properties.secondary_aws_accounts');
  const blockedAccounts = form.getValue('properties.secondary_aws_blocked_accounts');
  let defaultTab = TAB_ALL_CHILD_ACCOUNTS;
  if (blockedAccounts.length > 0) {
    defaultTab = TAB_BLOCK_LIST_ACCOUNTS;
  }

  if (allowedAccounts.length > 0) {
    defaultTab = TAB_ALLOW_LIST_ACCOUNTS;
  }

  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(defaultTab);

  const iamRoleValue = form.getValue('properties.aws_iam_role_arn');
  const loadChildAccountsHandler = useCallback(async () => {
    if (collection.size > 0) {
      return;
    }

    if (!iamRoleValue) {
      return;
    }

    // reset error
    setError('');

    const requestData = {
      ...form.getValues()
    };
    // mark as loading
    collection.setRequestStatus('fetching');
    const response = await $clouds.listAwsChildAccount(requestData);

    const { data = [], message, success } = response;

    if (!success) {
      setError(message);
    }

    collection.set(data);
    collection.setRequestStatus(null);
    collection.hasFetched = true;
  }, [$clouds, form, iamRoleValue]);

  useEffect(() => {
    loadChildAccountsHandler();
  }, [loadChildAccountsHandler]);

  const handleCloseModal = () => setShowModal(false);
  const handleTabChange = (tabId) => {
    if (tabId === TAB_ALL_CHILD_ACCOUNTS) {
      form.setValue('properties.secondary_aws_accounts', []);
      form.setValue('properties.secondary_aws_blocked_accounts', []);
    }

    if (tabId === TAB_ALLOW_LIST_ACCOUNTS) {
      if (blockedAccounts.length > 0) {
        form.setValue('properties.secondary_aws_accounts', blockedAccounts);
      } else if (form.model?.get('properties.secondary_aws_accounts', []).length > 0) {
        form.setValue('properties.secondary_aws_accounts', form.model?.get('properties.secondary_aws_accounts') ?? []);
      }

      form.setValue('properties.secondary_aws_blocked_accounts', []);
    }

    if (tabId === TAB_BLOCK_LIST_ACCOUNTS) {
      if (allowedAccounts.length > 0) {
        form.setValue('properties.secondary_aws_blocked_accounts', allowedAccounts);
      } else if (form.model?.get('properties.secondary_aws_blocked_accounts', []).length > 0) {
        form.setValue(
          'properties.secondary_aws_blocked_accounts',
          form.model?.get('properties.secondary_aws_blocked_accounts') ?? []
        );
      }

      form.setValue('properties.secondary_aws_accounts', []);
    }

    setActiveTab(tabId);
  };

  return (
    <>
      <ButtonGroupTabs omitTabsWrapper onChange={handleTabChange} selectedTabId={activeTab}>
        <ButtonGroupTab
          id={TAB_ALL_CHILD_ACCOUNTS}
          title="All Child Accounts"
          panel={
            <Text muted intent={error ? 'error' : 'default'}>
              {collection.loading
                ? 'Loading organization accounts....'
                : error || `${collection.size} total accounts in organization.`}
            </Text>
          }
        />
        <ButtonGroupTab
          id={TAB_ALLOW_LIST_ACCOUNTS}
          text="Accounts Allowlist"
          panel={
            <Field
              large
              mb={0}
              flex={1}
              showLabel={false}
              name="properties.secondary_aws_accounts"
              placeholder="Custom selection of child accounts."
            >
              <TagInput fill dataTestId="properties.secondary_aws_accounts" />
            </Field>
          }
        />
        <ButtonGroupTab
          id={TAB_BLOCK_LIST_ACCOUNTS}
          text="Accounts Blocklist"
          panel={
            <Field
              large
              mb={0}
              flex={1}
              showLabel={false}
              name="properties.secondary_aws_blocked_accounts"
              placeholder="Custom exclusion of child accounts."
            >
              <TagInput fill dataTestId="properties.secondary_aws_blocked_accounts" />
            </Field>
          }
        />
      </ButtonGroupTabs>

      <Flex width="50%">
        {!collection.loading && (
          <Button small onClick={() => setShowModal(true)}>
            View Detected Accounts
          </Button>
        )}
      </Flex>

      <Dialog
        isOpen={showModal}
        onClose={handleCloseModal}
        style={{ width: 'calc(100vw - 200px)', height: 'calc(100vh - 100px)' }}
        title="Select Organization Accounts"
        canOutsideClickClose
        canEscapeKeyClose
        isCloseButtonShown
      >
        <Dialog.Body>
          <Card>
            <Flex flexDirection="column" gap={1} border="thin">
              <Filter placeholder="Search accounts..." collection={collection} />
              {error && <Callout intent="danger"> {error}</Callout>}
              <Table
                isExpanded
                rowAlign="center"
                searchBar={false}
                emptyState={<EmptyState title="No accounts found." />}
                collection={collection}
                tableContainerProps={{ bg: 'cardBackground' }}
                columns={columns}
              />
            </Flex>
          </Card>
        </Dialog.Body>
        <Dialog.Footer>
          <Button intent={Intent.PRIMARY} text="close" onClick={handleCloseModal} width={110} ml={1} />
        </Dialog.Footer>
      </Dialog>
    </>
  );
};

export default inject('$clouds')(observer(AdditionalAccountsSelectorPanel));
