import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { PopoverInteractionKind } from '@blueprintjs/core';
import { Flex, Box, Icon, Popover, Text, Button, Tag } from 'core/components';
import MeshMetrics from './MeshMetrics';

@inject('$app', '$syn', '$sharedLinks')
@withRouter
export default class MeshPopover extends Component {
  static defaultProps = {
    usePortal: true,
    allowAgentDetails: true
  };

  render() {
    const {
      usePortal,
      allowAgentDetails,
      onOpen,
      onClose,
      mesh,
      row: rowIndex,
      col: colIndex,
      testId,
      lookbackSeconds,
      startDate,
      endDate,
      targetProps,
      children,
      isAggregated,
      $app,
      $syn
    } = this.props;

    const row = mesh[rowIndex];
    const col = mesh[rowIndex].columns[colIndex];
    const returnRow = mesh[colIndex];
    const returnCol = mesh[colIndex].columns[rowIndex];

    const { alias: rowName, agent_id: rowAgentId } = row;
    const { alias: colName, agent_id: colAgentId, metrics } = col;

    const rowAgent = $syn.agents.get(rowAgentId);
    const colAgent = $syn.agents.get(colAgentId);
    const tagProps = { ml: 1, minimal: true, small: true };
    return (
      <Popover
        minimal={false}
        usePortal={usePortal}
        interactionKind={PopoverInteractionKind.HOVER}
        position="right-top"
        onOpening={onOpen}
        onClose={onClose}
        targetProps={targetProps}
        modifiers={{
          offset: { enabled: true, offset: '0, -2px' },
          flip: { boundariesElement: usePortal ? 'viewport' : 'scrollParent' },
          preventOverflow: { boundariesElement: usePortal ? 'viewport' : 'scrollParent' }
        }}
        content={
          <Box p={2}>
            <Flex alignItems="center" fontSize={12} mb={0.5}>
              <Text fontWeight="heavy">{rowName}</Text>
              {rowAgent && <Tag {...tagProps}>{rowAgent.locationInfo}</Tag>}
              <Icon icon="arrow-right" iconSize={20} color="primary" mx="6px" css={{ top: 1, position: 'relative' }} />
            </Flex>
            <Flex alignItems="center" fontSize={12} mb={1}>
              <Text fontWeight="heavy">{colName}</Text>
              {colAgent && <Tag {...tagProps}>{colAgent.locationInfo}</Tag>}
            </Flex>
            <MeshMetrics metrics={metrics} isAggregated={isAggregated} />
            {allowAgentDetails && !$app.isSubtenant && (
              <Button
                mt={1}
                fill
                text="View Details"
                onClick={() => {
                  const { history } = this.props;
                  const hasTime = lookbackSeconds || startDate || endDate;
                  history.push(
                    `/v4/synthetics/tests/${testId}/results/agent/${rowAgentId}/${colAgentId}`,
                    hasTime
                      ? {
                          lookbackSeconds,
                          startDate,
                          endDate
                        }
                      : null
                  );
                }}
              />
            )}
            <Text as="div" mt={2} fontSize="small" color="rose4">
              Reverse Path
            </Text>
            <Flex alignItems="center" fontSize={16} mb={0.5}>
              <Text fontWeight="heavy">{rowName}</Text>
              {rowAgent && <Tag {...tagProps}>{rowAgent.locationInfo}</Tag>}
              <Icon icon="arrow-left" iconSize={20} color="rose4" mx="6px" css={{ top: 1, position: 'relative' }} />
            </Flex>
            <Flex alignItems="center" fontSize={16} mb={1}>
              <Text fontWeight="heavy">{colName}</Text>
              {colAgent && <Tag {...tagProps}>{colAgent.locationInfo}</Tag>}
            </Flex>
            {!returnCol && 'No reverse path data found'}
            {returnCol && (
              <>
                <MeshMetrics metrics={returnCol.metrics} isAggregated={isAggregated} />
                {allowAgentDetails && !$app.isSubtenant && (
                  <Button
                    mt={1}
                    fill
                    text="View Details"
                    onClick={() => {
                      const { history } = this.props;
                      const hasTime = lookbackSeconds || startDate || endDate;
                      history.push(
                        `/v4/synthetics/tests/${testId}/results/agent/${returnRow.agent_id}/${returnCol.agent_id}`,
                        hasTime
                          ? {
                              lookbackSeconds,
                              startDate,
                              endDate
                            }
                          : null
                      );
                    }}
                  />
                )}
              </>
            )}
          </Box>
        }
      >
        {children}
      </Popover>
    );
  }
}
