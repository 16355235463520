import { css } from 'styled-components';
import { minWidth, minHeight, width, height, maxWidth, maxHeight } from 'styled-system';

const flexHelper = css`
  ${minWidth};
  ${minHeight};
  ${width};
  ${height};
  ${maxWidth};
  ${maxHeight};
`;

export default flexHelper;
