import { SEVERITY_MAP } from 'shared/alerting/constants';
import { CONDITION_TYPE_LABELS } from 'shared/synthetics/bgp';

// fallback value for when an agent cannot be determined whether that be because of removal or old per-test alerting
// we also use this as a fallback if we don't have a metric --- this would most usually happen with v1 alarms that have been transformed to v2
export const UNKNOWN = 'Unknown';

// a set of location constants used for managing selection
export const LOCATION_TYPES = {
  COUNTRY: 'country',
  REGION: 'region',
  CITY: 'city'
};

// reduces a list of alarms into a collection of groups by interesting properties such as severity, metric, agent, city, region, and country
// all the groups are returned in a descending order
export function getAlarmSummaries({ $syn, alarms = [] } = {}) {
  const alarmSummary = alarms.reduce(
    (summary, alarm) => {
      const { key, severity } = alarm;
      const { agentId, subtest } = key.value;
      const agent = agentId && $syn.agents.get(agentId);
      const agentName = agentId || UNKNOWN;
      let metric = key.value.metric || UNKNOWN;

      const severityKey = severity === SEVERITY_MAP.CRITICAL.ALERT_MANAGER ? 'critical' : 'warning';

      // By severity
      summary.bySeverity[severityKey] = (summary.bySeverity[severityKey] || 0) + 1;

      // By agent
      if (!summary.byAgent[agentName]) {
        summary.byAgent[agentName] = { warning: 0, critical: 0 };
        summary.byAgent[agentName].model = agent;
      }
      summary.byAgent[agentName][severityKey] += 1;
      summary.byAgent[agentName].total = (summary.byAgent[agentName].total || 0) + 1;

      // By metric
      // For BGP tests
      if (subtest) {
        metric = CONDITION_TYPE_LABELS[subtest];
      }

      // For non-BGP tests
      summary.byMetric[metric] = (summary.byMetric[metric] || 0) + 1;

      let city = UNKNOWN;
      let region = UNKNOWN;
      let country = UNKNOWN;

      // By location (country, region or city)
      if (agent) {
        city = agent.city;
        region = agent.region;
        country = agent.country;
      }

      summary.byCity[city] = (summary.byCity[city] || 0) + 1;
      summary.byRegion[region] = (summary.byRegion[region] || 0) + 1;
      summary.byCountry[country] = (summary.byCountry[country] || 0) + 1;

      // keep a running total --- we can use that in the ui when calculating percentages for locations
      summary.total += 1;

      return summary;
    },
    {
      bySeverity: { warning: 0, critical: 0 },
      byMetric: {},
      byAgent: {},
      byCity: {},
      byRegion: {},
      byCountry: {},
      total: 0
    }
  );

  return Object.entries(alarmSummary).reduce((sortedGroups, [groupName, groupData]) => {
    if (groupName === 'total') {
      // pass through the total count, there's nothing to sort
      return {
        ...sortedGroups,
        total: groupData
      };
    }

    // process each of the groups into sorted lists of objects with 'name' and 'value' properties
    // most of these groups will have the count as their value, but agents have a more complex breakdown
    // including by severity and the addition of the agent model
    sortedGroups[groupName] = Object.entries(groupData)
      .sort(([nameA, valueA], [, valueB]) => {
        if (groupName === 'byAgent') {
          // use the more complex value system for agents by grabbing the total property
          return valueB.total - valueA.total;
        }

        if (groupName === 'bySeverity') {
          // sort by severity, critical first
          return nameA === 'critical' ? -1 : 1;
        }

        // all others have values of straight-up numeric counts
        return valueB - valueA;
      })
      .map(([name, value]) => ({
        name,
        value
      }));

    return sortedGroups;
  }, {});
}
