import React from 'react';

function TenantLogo({ companyId, width, height }) {
  // Math.random() is for cachebusting cc @stan
  return <img alt="Company Logo" src={`/api/ui/icons/${companyId}?v=${Math.random()}`} style={{ width, height }} />;
}

TenantLogo.defaultProps = {
  width: 'auto',
  height: 40
};

export default TenantLogo;
