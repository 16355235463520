const { Address4, Address6 } = require('ip-address');
const { parse } = require('@aws-sdk/util-arn-parser');
const { get: _get, uniqWith: _uniqWith, isEqual: _isEqual } = require('lodash');

const getTagValue = require('./getTagValue');
const { ENTITY_TYPES } = require('../../hybrid/constants');

const { CORE_NETWORK, CORE_NETWORK_ATTACHMENT, SUBNET } = ENTITY_TYPES.get('aws');

const idToType = (id) => {
  if (id.startsWith('dxcon-')) {
    return 'DirectConnections';
  }

  if (id.startsWith('dxlag-')) {
    return 'Lags';
  }

  if (id.startsWith('igw-')) {
    return 'InternetGateways';
  }

  if (id.startsWith('nat-')) {
    return 'NatGateways';
  }

  if (id.startsWith('tgw-attach-')) {
    return 'TransitGatewayAttachments';
  }

  if (id.startsWith('tgw-')) {
    return 'TransitGateways';
  }

  if (id.startsWith('vgw-')) {
    return 'VpnGateways';
  }

  if (id.startsWith('vpc-')) {
    return 'Vpcs';
  }

  if (id.startsWith('vpn-')) {
    return 'VpnConnections';
  }

  if (id.startsWith('subnet-')) {
    return 'Subnets';
  }

  if (id.startsWith('i-')) {
    return 'Instances';
  }

  if (id.startsWith('pcx-')) {
    return 'VpcPeeringConnections';
  }

  if (id.startsWith('vpce-')) {
    return 'VpcEndpoints';
  }

  if (id.startsWith('eni-')) {
    return 'NetworkInterfaces';
  }

  if (id.startsWith('core-network-')) {
    return 'CoreNetworks';
  }

  return null;
};

const findInstanceInEntities = (entities, instanceId) => {
  for (const reservation of Object.values(entities.Reservations)) {
    for (const instance of reservation.Instances) {
      if (instance.InstanceId === instanceId) {
        return instance;
      }
    }
  }

  return null;
};

function isIpV4Valid(ip, options = {}) {
  const { returnAddress = false } = options;
  let ipAddress;
  try {
    ipAddress = new Address4(ip.trim());
    const isCorrect = ipAddress.isCorrect();
    return returnAddress ? isCorrect && ipAddress : isCorrect;
  } catch (e) {
    return returnAddress ? null : false;
  }
}
function isIpV6Valid(ip, options = {}) {
  const { returnAddress = false } = options;
  let ipAddress;
  try {
    ipAddress = new Address6(ip.trim());
    // ip-address v6 is very strict with their check for correctness
    const isCorrect = !!ipAddress;
    return returnAddress ? isCorrect && ipAddress : isCorrect;
  } catch (e) {
    return returnAddress ? null : false;
  }
}

function isIpValid(ip, options = {}) {
  const { returnAddress = false } = options;
  return isIpV4Valid(ip, { returnAddress }) || isIpV6Valid(ip, { returnAddress });
}

const toIp = (ipAddr) => {
  if (ipAddr instanceof Address4 || ipAddr instanceof Address6) {
    return ipAddr;
  }

  return isIpValid(ipAddr, { returnAddress: true });
};

const getEntity = (Entities, value, params = {}) => {
  const { type = null } = params;

  let calculatedType = type;
  if (!calculatedType) {
    calculatedType = idToType(value);
  }

  if (calculatedType === 'Instances' || type === 'Instances') {
    return findInstanceInEntities(Entities, value);
  }

  return _get(Entities, `${calculatedType}.${value}`, null);
};

/** arn:aws:networkmanager::891377065471:core-network/core-network-051b14f169301c567 into core-network-051b14f169301c567 */
const parseArnToId = (arn) => {
  if (!arn) {
    return null;
  }
  const { resource } = parse(arn);
  return resource.split('/')[1] ?? null;
};

function processCoreNetworkDestination(entities, destination, moreSpecificRoute) {
  const coreNetworkAttachment = _get(entities, `${CORE_NETWORK_ATTACHMENT}.${destination.CoreNetworkAttachmentId}`);

  if (destination.ResourceType === 'vpc') {
    const subnetEntity = _get(entities, `${SUBNET}.${coreNetworkAttachment.SubnetId}`);
    destination.SubnetId = subnetEntity?.id;
    destination.VpcId = subnetEntity?.VpcId;

    moreSpecificRoute.destination = subnetEntity?.CidrBlock;
    moreSpecificRoute.routeTarget = subnetEntity?.id;

    return destination;
  }

  if (destination.ResourceType === 'connect') {
    const peeringAttachmentId = parseArnToId(coreNetworkAttachment.ResourceArn ?? '');
    const peeringAttachment = _get(entities, `${CORE_NETWORK_ATTACHMENT}.${peeringAttachmentId}`);

    if (!peeringAttachment) {
      return destination;
    }

    const subnetEntity = _get(entities, `${SUBNET}.${peeringAttachment.SubnetId}`);

    destination.peeringAttachmentId = peeringAttachmentId;
    destination.SubnetId = subnetEntity?.id;
    destination.VpcId = subnetEntity?.VpcId;

    moreSpecificRoute.destination = subnetEntity?.CidrBlock;
    moreSpecificRoute.routeTarget = subnetEntity?.id;

    return destination;
  }

  return destination;
}

const getRouteTables = (entities, routeTableIds, type = 'RouteTables') =>
  routeTableIds.map((tableId) => {
    const routeTable = _get(entities, `${type}.${tableId}`);

    if (!routeTable) {
      return null;
    }

    routeTable.Routes = _uniqWith(routeTable.Routes || [], _isEqual)
      .map((route) => {
        const keys = Object.keys(route);
        const gatewayIdKey = keys.find((key) => key.endsWith('Id') && !key.includes('Destination'));
        const destinationKey = keys.find((key) => key.includes('Destination') && route[key] !== null);
        const coreNetworkId = parseArnToId(route.CoreNetworkArn ?? '');

        Object.assign(route, {
          routeTableId: routeTable.id,
          coreNetworkId,
          gatewayId: route[gatewayIdKey] || null,
          destination: route[destinationKey] || null,
          state: route.State
        });

        if (type === 'RouteTables') {
          const destinationIp = toIp(route.destination);
          route.routeTarget = route.gatewayId;

          if (route.routeTarget?.startsWith('tgw-') && destinationIp?.isCorrect()) {
            route.moreSpecificRoutes = Object.values(entities.TransitGatewayRouteTables).flatMap((rt) => {
              if (rt.TransitGatewayId === route.routeTarget) {
                const tgwAttachmentsAssociatedWithTgwRouteTable = Object.values(
                  entities?.TransitGatewayAttachments ?? {}
                ).filter((tgwAttachment) => tgwAttachment?.Association?.TransitGatewayRouteTableId === rt.id);

                return rt.Routes.filter((r) => {
                  if (r.DestinationCidrBlock) {
                    const routeIp = toIp(r.DestinationCidrBlock);
                    return routeIp.subnetMask >= destinationIp.subnetMask && routeIp.isInSubnet(destinationIp);
                  }

                  return false;
                }).map((moreSpecificRoute) => {
                  moreSpecificRoute.routeTgwAttachmentIds = tgwAttachmentsAssociatedWithTgwRouteTable.map(
                    (tgwAttachment) => tgwAttachment.id
                  );

                  return moreSpecificRoute;
                });
              }

              return [];
            });

            return route;
          }

          if (route.coreNetworkId && destinationIp?.isCorrect()) {
            const coreNetwork = _get(entities, `${CORE_NETWORK}.${route.coreNetworkId}`);
            route.routeTarget = route.coreNetworkId;

            if (!coreNetwork) {
              return route;
            }

            route.coreNetworkRoutes = coreNetwork.NetworkRoutes ?? [];

            route.moreSpecificRoutes = route.coreNetworkRoutes
              .map((coreNetworkRoute) => {
                const { SegmentName, EdgeLocation, Routes = [] } = coreNetworkRoute;
                return Routes.filter((r) => {
                  if (r.DestinationCidrBlock) {
                    const routeIp = toIp(r.DestinationCidrBlock);
                    return routeIp.subnetMask >= destinationIp.subnetMask && routeIp.isInSubnet(destinationIp);
                  }

                  return false;
                })
                  .map((r) => {
                    const destinationIsAnotherSegment = r.Destinations.find(
                      (destination) =>
                        !destination.ResourceId &&
                        !destination.ResourceType &&
                        destination.SegmentName === SegmentName &&
                        destination.EdgeLocation !== EdgeLocation
                    );

                    if (destinationIsAnotherSegment) {
                      return (coreNetwork.NetworkRoutes ?? [])
                        .filter(
                          (segmentRoute) =>
                            segmentRoute.SegmentName === destinationIsAnotherSegment.SegmentName &&
                            segmentRoute.EdgeLocation === destinationIsAnotherSegment.EdgeLocation
                        )
                        .map((segmentRoute) => segmentRoute.Routes)
                        .flat()
                        .filter((segmentRoute) => {
                          if (segmentRoute.DestinationCidrBlock) {
                            const routeIp = toIp(segmentRoute.DestinationCidrBlock);
                            return routeIp.subnetMask > destinationIp.subnetMask && routeIp.isInSubnet(destinationIp);
                          }

                          return false;
                        });
                    }

                    return r;
                  })
                  .flat()
                  .map((moreSpecificRoute) => {
                    const { Destinations = [] } = moreSpecificRoute;

                    Destinations.forEach((destination) =>
                      processCoreNetworkDestination(entities, destination, moreSpecificRoute, coreNetwork)
                    );

                    return {
                      ...moreSpecificRoute,
                      SegmentName,
                      EdgeLocation
                    };
                  });
              })
              .flat();
          }
        }

        if (type === 'TransitGatewayRouteTables') {
          let attachment;
          let nextHopResource;
          let resourceType;

          if (route.TransitGatewayAttachments && route.TransitGatewayAttachments.length > 0) {
            const { ResourceId, TransitGatewayAttachmentId, ResourceType } = route.TransitGatewayAttachments[0];
            const resource = getEntity(entities, ResourceId);
            let gatewayAttachment = _get(entities, `TransitGatewayAttachments.${TransitGatewayAttachmentId}`);

            if (ResourceType === 'peering') {
              // peering connections share a 'TransitGatewayAttachmentId' for each side of the relationship
              // we strengthened the key for this in the backend service to include the 'TransitGatewayId'
              gatewayAttachment = _get(
                entities,
                `TransitGatewayAttachments.${TransitGatewayAttachmentId}-${ResourceId}`
              );
            }

            if (resource) {
              const id = ResourceId;
              const name = getTagValue(resource, 'Name');

              nextHopResource = {
                id,
                type: ResourceType,
                name: name || id,
                copyValue: name ? `${name} (${id})` : id
              };
            }

            if (gatewayAttachment) {
              const id = TransitGatewayAttachmentId;
              const name = getTagValue(gatewayAttachment, 'Name');

              attachment = {
                id,
                name: name || id,
                copyValue: name ? `${name} (${id})` : id
              };
            }

            resourceType = ResourceType;
          }

          route.routeTarget = attachment ? { attachment, nextHopResource, resourceType } : null;

          return route;
        }

        return route;
      })
      .flatMap((route) => {
        if (route.DestinationPrefixListId) {
          const prefixList = entities.PrefixLists[route.DestinationPrefixListId];
          return (prefixList?.Cidrs || []).map((destination) => ({ ...route, destination }));
        }

        return route;
      });

    routeTable.id = tableId;
    routeTable.name = getTagValue(routeTable, 'Name') || tableId;

    if (type === 'RouteTables') {
      const associations = routeTable.Associations || [];
      const subnetAssociations = associations.filter((association) => !!association.SubnetId);

      routeTable.main = associations.some((association) => association.Main);
      routeTable.subnetIds = subnetAssociations.map((association) => association.SubnetId);
      routeTable.vpcId = routeTable.VpcId;
      routeTable.gatewayIds = Array.from(new Set(routeTable.Routes.map((r) => r.gatewayId)));
    }

    return routeTable;
  });

const getRouteTableSummary = (entities, routeTablesOrIds, type = 'RouteTables') => {
  const routeTableSummary = {
    totalActiveRoutes: 0,
    totalBlackholeRoutes: 0,
    routeTables: [],
    routes: [],
    totalsByTable: {}
  };

  const routeTables = (
    routeTablesOrIds.length > 0 && typeof routeTablesOrIds[0] === 'string'
      ? getRouteTables(entities, routeTablesOrIds, type)
      : routeTablesOrIds
  ).filter((routeTable) => !!routeTable);

  routeTables.forEach((routeTable) => {
    const { id: tableId, Routes = [], name: routeTableName } = routeTable;

    routeTableSummary.totalsByTable[tableId] = routeTableSummary.totalsByTable[tableId] || {
      totalActiveRoutes: 0,
      totalBlackholeRoutes: 0,
      routeTableName
    };

    routeTableSummary.routeTables.push(routeTable.id);

    Routes.forEach((route) => {
      if (route.State === 'blackhole') {
        routeTableSummary.totalBlackholeRoutes += 1;
        routeTableSummary.totalsByTable[tableId].totalBlackholeRoutes += 1;
      } else {
        routeTableSummary.totalActiveRoutes += 1;
        routeTableSummary.totalsByTable[tableId].totalActiveRoutes += 1;
      }

      route.routeTableName = routeTableName;

      routeTableSummary.routes.push(route);
    });
  });

  return routeTableSummary;
};

const getCoreNetworkAttachmentsForSubnetId = (entities, subnetId) =>
  Object.values(entities[CORE_NETWORK_ATTACHMENT] ?? {}).filter((attachment) => attachment?.SubnetId === subnetId);

const getSubnetRouteTableSummary = (entities, vpcRouteTables, subnetId) => {
  const subnetRouteTables = vpcRouteTables.filter((routeTable) => routeTable.subnetIds.includes(subnetId));
  const subnetEntity = _get(entities, `${SUBNET}.${subnetId}`);
  const mainRouteTable = vpcRouteTables.find((routeTable) => routeTable.main);
  const routeTables = subnetRouteTables.length > 0 ? subnetRouteTables : [mainRouteTable];
  const routeTableSummary = getRouteTableSummary(entities, routeTables);

  const coreNetworkAttachments = getCoreNetworkAttachmentsForSubnetId(entities, subnetId);

  if (!coreNetworkAttachments?.length) {
    return routeTableSummary;
  }

  const subnetCoreNetworkSegmentNames = coreNetworkAttachments.map((attachment) => attachment.SegmentName);

  routeTableSummary.routes = routeTableSummary.routes.map((route) => {
    if (!route.coreNetworkId) {
      return route;
    }

    // only keep routes for segments in the same region as subnet
    route.moreSpecificRoutes = route.moreSpecificRoutes.filter(
      (specificRoute) =>
        subnetCoreNetworkSegmentNames.includes(specificRoute?.SegmentName) &&
        specificRoute?.EdgeLocation === subnetEntity?.RegionName &&
        specificRoute.routeTarget !== subnetId
    );

    return route;
  });
  return routeTableSummary;
};

module.exports = {
  idToType,
  getEntity,
  parseArnToId,
  getRouteTables,
  getRouteTableSummary,
  findInstanceInEntities,
  getSubnetRouteTableSummary,
  getCoreNetworkAttachmentsForSubnetId
};
