import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Flex, Text, Heading } from 'core/components';
import { FormDialog, Field, RadioGroup, TextArea } from 'core/form';

const fields = {
  user_rating: {
    options: [
      { value: 3, label: 'Yes' },
      { value: 1, label: 'No' }
    ],
    label: 'User Rating',
    rules: ['required', 'numeric', 'max:3', 'min:0']
  },
  comment: {
    label: 'Comment',
    rules: ['min:5', 'max:250']
  }
};

const options = {
  name: 'Feedback on text to query',
  showPristineErrors: false
};

@observer
export default class PromptToQueryDialog extends Component {
  handleSave = (form, values) => {
    const { model } = this.props;
    return model.save(values, { sendProvidedValuesOnly: true });
  };

  handleClose = () => {
    const { onClose, model } = this.props;
    if (onClose) {
      onClose(model);
    }
  };

  render() {
    const { onClose, model } = this.props;
    return (
      <FormDialog
        entityName=""
        model={model}
        fields={fields}
        options={options}
        onClose={this.handleClose}
        title="Provide additional feedback"
        maxWidth={550}
        isOpen
        formActionsProps={{
          onSubmit: this.handleSave,
          onCancel: onClose
        }}
      >
        {() => (
          <Flex flexDirection="column" gap={2}>
            <Heading level={5} m={0} fontWeight="normal">
              Did the response answer your query? <br />
              <br />
              <Text muted>{model.get('prompt')}</Text>
            </Heading>
            <Field name="user_rating" large showLabel={false} m={0}>
              <RadioGroup inline />
            </Field>
            <Field
              name="comment"
              large
              m={0}
              placeholder="What was the issue with the response? How could it be improved?"
            >
              <TextArea rows={6} fill />
            </Field>
          </Flex>
        )}
      </FormDialog>
    );
  }
}
