import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { Classes, TimePicker as BlueprintTimePicker } from '@blueprintjs/datetime';
import { whitelistFieldProps } from 'core/form/components/util';

const TimePicker = styled(BlueprintTimePicker)`
  ${space};

  .${Classes.TIMEPICKER_INPUT_ROW} {
    height: 30px;
    line-height: 30px;
  }
`;

export default observer((props) => {
  const { field } = props;
  return <TimePicker {...field.getProps()} {...whitelistFieldProps(props)} />;
});
