import React, { Component } from 'react';
import styled, { withTheme, css } from 'styled-components';
import AnimatedArrow, { animatedArrowStyles } from 'app/components/AnimatedArrow';
import { Card, Flex } from 'core/components';

const Wrapper = styled(Card)`
  ${({ theme, centerIcon }) => css`
    transition: all 150ms ease-in-out;
    box-shadow: ${theme.shadows.elevation[0]};
    ${animatedArrowStyles};

    :hover {
      background-color: ${theme.colors.primaryBackground};
      box-shadow: ${theme.shadows.elevation[1]};
    }

    .arrow-icon {
      width: 8px;
      height: 12px;

      ${centerIcon &&
      css`
        align-self: center;
      `}
    }
  `}
`;

@withTheme
class AgentInstallOption extends Component {
  render() {
    const { onChange, children, centerIcon } = this.props;
    return (
      <Wrapper mt={2} onClick={onChange} centerIcon={centerIcon}>
        <Flex justifyContent="space-between" cursor="pointer" p={2}>
          <Flex flex={1} flexDirection="column" mr={2}>
            {children}
          </Flex>
          <AnimatedArrow />
        </Flex>
      </Wrapper>
    );
  }
}

export default AgentInstallOption;
