const getMetricsFromConditionGroup = (conditionGroup, onFindMetric) => {
  conditionGroup.conditionGroupFields?.conditions?.forEach((condition) => {
    if (condition?.metric) {
      onFindMetric(condition.metric);
    }
  });
  conditionGroup.conditionGroupFields?.condition_groups?.forEach((cg) =>
    getMetricsFromConditionGroup(cg, onFindMetric)
  );
};

export const getPolicyMetricsFromActivateLevels = (levels) => {
  const metrics = new Set();
  levels?.forEach((level) => {
    level.activate?.condition_groups?.forEach((conditionGroup) =>
      getMetricsFromConditionGroup(conditionGroup, (metric) => {
        metrics.add(metric);
      })
    );
  });
  return Array.from(metrics);
};
