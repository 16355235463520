import React from 'react';
import { Image } from 'core/components';

import apcLogo from 'app/assets/logos/apc-logo.svg';
import aristaLogo from 'app/assets/logos/arista-logo.svg';
import ciscoLogo from 'app/assets/logos/cisco-logo.svg';
import ciscoMerakiLogo from 'app/assets/logos/cisco-meraki-logo.svg';
import extremeLogo from 'app/assets/logos/extreme-networks.svg';
import f5Logo from 'app/assets/logos/f5-logo.svg';
import infobloxLogo from 'app/assets/logos/infoblox-logo.svg';
import huaweiLogo from 'app/assets/vendor-logos/Huawei.png';
import juniperLogo from 'app/assets/logos/juniper-networks.svg';
import lantronixLogo from 'app/assets/logos/lantronix-logo.svg';
import nokiaLogo from 'app/assets/logos/nokia-logo.svg';
import paloAltoLogo from 'app/assets/logos/palo-alto.svg';
import vyattaLogo from 'app/assets/vendor-logos/Vyatta.png';
import ubiquitiLogo from 'app/assets/logos/ubiquiti.svg';
import silverpeakLogo from 'app/assets/logos/silver-peak-logo.svg';

const vendorLogos = {
  APC: apcLogo,
  Arista: aristaLogo,
  'Arista Networks': aristaLogo,
  Cisco: ciscoLogo,
  'Meraki Networks, Inc.': ciscoMerakiLogo,
  'IronPort Systems, Inc.': ciscoLogo,
  Extreme: extremeLogo,
  F5: f5Logo,
  Huawei: huaweiLogo,
  'HUAWEI Technology Co.,Ltd': huaweiLogo,
  'InfoBlox Inc.': infobloxLogo,
  Juniper: juniperLogo,
  'Juniper Networks': juniperLogo,
  Lantronix: lantronixLogo,
  Nokia: nokiaLogo,
  'Palo-Alto': paloAltoLogo,
  Vyatta: vyattaLogo,
  Ubiquiti: ubiquitiLogo,
  'Ubiquiti Networks, Inc.': ubiquitiLogo,
  'Ubiquiti EdgeMax': ubiquitiLogo,
  'Silver-Peak': silverpeakLogo
};

function VendorLogo({ name, logoStyle, fallback = false }) {
  const vendorLogo = vendorLogos[name];
  if (vendorLogo) {
    return <Image src={vendorLogo} alt={name} style={logoStyle} />;
  }
  if (fallback) {
    return name || '';
  }
  return null;
}

export default VendorLogo;
