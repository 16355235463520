import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Callout, Flex } from 'core/components';
import { formConsumer, Field, InputGroup, Switch } from 'core/form';
import FilterOptions from 'app/components/filters/FilterOptions';
import { addFilterGroup } from 'app/util/filters';

import DimensionSelector from './DimensionSelector';
import ExplorerDimensionMultiValuesRenderer from './ExplorerDimensionMultiValuesRenderer';

function clearGroupRules(group) {
  group.filters.forEach((filter) => filter.filterValue.setRules([], { validate: false }));

  if (group.filterGroups) {
    group.filterGroups.forEach(clearGroupRules);
  }
}

function resetGroupRules(group, getFilterValueValidator) {
  group.filters.forEach((filter) => {
    filter.filterValue.setRules(getFilterValueValidator(filter.filterField.getValue()), { validate: false });
  });

  if (group.filterGroups) {
    group.filterGroups.forEach((filterGroup) => resetGroupRules(filterGroup, getFilterValueValidator));
  }
}

@inject('$dictionary')
@formConsumer
@observer
class ExplorerDimensionSelector extends Component {
  onFilterDimensionsToggled = (field, value) => {
    const { form, $dictionary } = this.props;

    const filterDimensionGroups = form.getField('filterDimensions.filterGroups');

    if (value) {
      if (filterDimensionGroups.size() === 0) {
        const filterGroups = form.getField('filterDimensions.filterGroups');
        addFilterGroup(filterGroups, { named: true });
      }

      // Reset all of the rules on the filterValue fields to what they need to be
      const { getFilterValueValidator } = $dictionary;
      filterDimensionGroups.forEach((group) => resetGroupRules(group, getFilterValueValidator));
    } else {
      // Because of dynamic field rules on filterValue we need to clear the rules
      filterDimensionGroups.forEach(clearGroupRules);
    }

    form.validate();
  };

  render() {
    const { form, collapsedState, disabledValues, isEditing, onEditComplete, onCollapse } = this.props;
    const filterDimensionsEnabled = form.getValue('filterDimensionsEnabled');

    const tabName = 'Preset';
    const tabs = (
      <Flex
        key="split-filters"
        tab="Filter-Based"
        tabId="split-filters"
        flexDirection="column"
        flex={1}
        overflow="auto"
        className="modal-select-tab"
        p={2}
      >
        <Callout intent="primary" mb={2} p={2}>
          <p>
            A filter-based dimension is a set of independent series that are each based on complex filter criteria.
            Settings made on this tab will always overwrite all currently selected preset dimensions.
          </p>
          <Field name="filterDimensionsEnabled" onChange={this.onFilterDimensionsToggled} large>
            <Switch switchLabel="Enable filter-based dimension" />
          </Field>
          <Field name="filterDimensionName" disabled={!filterDimensionsEnabled} inputStyle={{ width: 300 }} large>
            <InputGroup />
          </Field>
          <Field name="filterDimensionOther" disabled={!filterDimensionsEnabled} style={{ width: 300 }}>
            <Switch switchLabel='Auto-Add "Other" Series' />
          </Field>
        </Callout>
        <FilterOptions
          fieldName="filterDimensions"
          showSaveFiltersButton={false}
          disabled={!filterDimensionsEnabled}
          showNames
          allowNestedFilters
          groupLabel="Series"
          groupLabelPlural="Series"
          filterGroupLabel="series"
          filterGroupLabelPlural="series"
          adHocLabel="Series"
          showTopLevelConnector={false}
          required={filterDimensionsEnabled}
        />
      </Flex>
    );

    return (
      <DimensionSelector
        onCollapse={onCollapse}
        collapsedState={collapsedState}
        showClearButton
        fieldName="metric"
        disabledValues={disabledValues}
        tabName={tabName}
        tabs={tabs}
        isTabbed
        small
        mainTabDisabled={filterDimensionsEnabled}
        multiValuesRenderer={ExplorerDimensionMultiValuesRenderer}
        isEditing={isEditing}
        onEditComplete={onEditComplete}
        showEditButton
      />
    );
  }
}

export default ExplorerDimensionSelector;
