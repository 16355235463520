import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Overlay } from '@blueprintjs/core';
import { Text, Heading, Card, Box, Tag, Icon, Button } from 'core/components';

const width = 750;

const StyledOverlay = styled(Overlay)`
  position: absolute;
  top: 180px;
  left: ${() => `calc(50% - ${width / 2}px)`};
`;

const Header = styled.div`
  width: 100%;
  background: linear-gradient(145.76deg, #09224b 9.7%, #1e4c74 59.09%);
  padding: 32px;

  .bp4-heading {
    font-weight: 800;
    font-size: 38px;
    line-height: 46px;
    color: #ffffff;
  }
`;

@inject('$app', '$auth', '$setup')
@withRouter
@observer
class ObservationDeckInterstitial extends Component {
  handleClose = () => {
    const { $setup } = this.props;

    $setup.updateSettings({ hideObservationDeckInterstitial: true });
  };

  handleClick = () => {
    const { $setup, history } = this.props;

    $setup.updateSettings({ hideObservationDeckInterstitial: true }).then(() => {
      history.push('/v4/home');
    });
  };

  render() {
    const { $app, $auth, $setup } = this.props;

    const isOpen =
      !$setup.getSettings('hideObservationDeckInterstitial') &&
      !$setup.getSettings('why') &&
      !$app.isExport &&
      !$auth.isSpoofed &&
      $auth.getActiveUserProperty('defaultLandingPageType') !== 'observationDeck';

    return (
      <StyledOverlay hasBackdrop isOpen={isOpen} onClose={this.handleClose}>
        <Card p={0} width={width} elevation={4}>
          <Header>
            <Tag intent="warning" round fontWeight="black" fontSize={16} p="4px">
              New!
            </Tag>
            <Heading level="5" fontWeight="black" m={0}>
              Observation Deck
            </Heading>
          </Header>

          <Box p={4}>
            <Text as="div" mb={2} fontSize={16} fontWeight="medium" color="muted" lineHeight="24px">
              Network observability starts with seeing everything— and that’s what the Observation Deck is all about!
              Make the Observation Deck your front page to Kentik by personalizing it with favorite views from Kentik’s
              ever-expanding library of widgets and feature your own content to get answers fast and drill into your
              data.
            </Text>

            <Button
              large
              fontWeight="medium"
              text="Get Started Now!"
              rightIcon={<Icon icon="circle-arrow-right" />}
              onClick={this.handleClick}
            />
          </Box>
        </Card>
      </StyledOverlay>
    );
  }
}

export default ObservationDeckInterstitial;
