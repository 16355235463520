import React, { Component } from 'react';
import { Layer } from 'react-konva/lib/ReactKonvaCore'; // https://github.com/konvajs/react-konva#minimal-bundle
import MeshCell from '../MeshCell';

export default class CellLayer extends Component {
  shouldComponentUpdate(newProps) {
    const { density, filteredMetrics, mesh, resultTimeMs, theme, meshCount } = this.props;
    return (
      density?.size !== newProps.density?.size ||
      filteredMetrics.length !== newProps.filteredMetrics.length ||
      mesh.length !== newProps.mesh.length ||
      resultTimeMs !== newProps.resultTimeMs ||
      theme.name !== newProps.theme.name ||
      meshCount !== newProps.meshCount
    );
  }

  render() {
    const { density, filteredMetrics, mesh, resultTimeMs, onCellMouseOver, onCellMouseOut, theme, isAggregated } =
      this.props;

    return (
      <Layer transformsEnabled="position">
        {mesh.map((row) =>
          row.columns.map((col) => (
            <MeshCell
              key={`cell-${row.agent_id}-${col.agent_id}-${resultTimeMs}`}
              density={density}
              filteredMetrics={filteredMetrics}
              row={row}
              col={col}
              onMouseOver={onCellMouseOver}
              onMouseOut={onCellMouseOut}
              theme={theme}
              isAggregated={isAggregated}
            />
          ))
        )}
      </Layer>
    );
  }
}
