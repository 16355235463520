import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Flex from 'core/components/Flex';
import Box from 'core/components/Box';
import { getCellStyle } from './Cell';

const TotalCell = observer((props) => {
  const { collection, column, index, models, expandedRowRenderer } = props;
  const { className, computed, name, showTotal, totalRenderer } = column;
  // if we have rows with expanded details, we will start to look for columns with totals beyond the second column instead
  const rootColumnIndex = expandedRowRenderer ? 1 : 0;
  let value = '';

  if (index === 0) {
    if (totalRenderer) {
      value = totalRenderer({ collection, models, value: models.length });
    } else {
      value = `Total of Top ${models.length}`;
    }
  }

  if (index > rootColumnIndex && showTotal) {
    value = models.reduce((total, model) => total + (computed ? model[name] : model.get(name)), 0.0);
    if (totalRenderer) {
      value = totalRenderer({ collection, models, value });
    }
  }

  // normally the 'Total of top...' messaging would appear in the first column
  // however when rendering a table with expandable columns, we want that message to span to the first 'real' column
  const colSpan = column.name === 'row-expander' ? 2 : column.colSpan;

  return (
    <Box
      className={classNames('td', className, column.align === 'right' ? 'align-right' : 'align-left')}
      style={getCellStyle({
        ...props,
        column: {
          ...column,
          colSpan
        }
      })}
    >
      {value}
    </Box>
  );
});

const TotalRow = (props) => {
  const { collection, columns, totalRowFilter, style, totalRowStyle } = props;

  return (
    <Flex className="tr total" style={style || totalRowStyle}>
      {columns.map((column, idx) => (
        <TotalCell
          {...props}
          key={column.key || column.name || column.type}
          column={column}
          index={idx}
          columns={columns}
          totalRow
          models={totalRowFilter ? collection.models.filter(totalRowFilter) : collection.models}
        />
      ))}
    </Flex>
  );
};

export default observer(TotalRow);
