import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FiInfo } from 'react-icons/fi';

import { Box, Button, Callout, CalloutOutline, Collapse, Flex, Icon, Text } from 'core/components';
import { Checkbox, Field, formConsumer, InputGroup, Select, Switch } from 'core/form';
import SiteSelect from 'app/components/site/SiteSelect';
import LabelSelector from 'app/components/labels/LabelSelector';
import NotificationChannels from 'app/views/synthetics/tests/TestConfigWizard/components/NotificationChannels';
import MaintenanceMode from './MaintenanceMode';

@inject('$auth', '$dictionary', '$lookups', '$notifications')
@formConsumer
@observer
export default class AgentConfigForm extends Component {
  constructor(props) {
    super(props);
    const {
      $auth: { hasSudo },
      form
    } = props;

    const allowSudoMode = hasSudo;
    const forceSudoMode = allowSudoMode && form.model.get('agent_type') === 'global';
    const sudoMode = allowSudoMode && forceSudoMode;
    this.state = {
      sudoMode,
      allowSudoMode,
      forceSudoMode
    };
    this.updateFormSudoMode(sudoMode);
  }

  componentDidMount() {
    const { form } = this.props;
    form.setValue('agent_labels', form.model.labels.length !== 0 ? form.model.labels.map((label) => label.id) : []);

    this.updateAlarmSuppressionStartTimeRules();
  }

  getRegionInfo = (country) => {
    const { $lookups } = this.props;
    const onQuery = (filter, options) => $lookups.regionsByCountry(filter, options, country);

    const cmpProps = {
      showFilter: true,
      clearable: true
    };

    if (country === 'US') {
      return { label: 'State', onQuery, cmpProps };
    }

    if (country === 'CA') {
      return { label: 'Province', onQuery, cmpProps };
    }

    return { label: 'Region', onQuery, cmpProps };
  };

  get cloudProviderOptions() {
    const { $dictionary } = this.props;
    const clouds = $dictionary.get('cloudMetadata');
    return [{ value: '', label: 'None' }].concat(
      Object.keys(clouds).map((key) => ({ value: key, label: clouds[key].name }))
    );
  }

  getCloudRegionOptions(cloud) {
    const { $dictionary } = this.props;
    const regions = $dictionary.getCloudMetadataKeyToNameMap(cloud);
    return Object.keys(regions).map((key) => ({
      value: key,
      label: (
        <Flex flex={1} alignItems="center" height={38}>
          <div>
            {regions[key]}
            <br />
            <Text muted small>
              {key}
            </Text>
          </div>
        </Flex>
      )
    }));
  }

  updateFormSudoMode(sudoMode) {
    const { form } = this.props;
    // set "secret handshake" field on form for now.
    form.sudoMode = !!sudoMode;
  }

  handleSudoModeToggle = () => {
    const { sudoMode: prevSudoMode, forceSudoMode } = this.state;
    const sudoMode = forceSudoMode || !prevSudoMode;
    this.setState({ sudoMode });
    this.updateFormSudoMode(sudoMode);
  };

  handleCountryChange = () => {
    const { form } = this.props;
    form.setValue('region', '');
    form.setValue('city', '');
  };

  handleRegionChange = () => {
    const { form } = this.props;
    form.setValue('city', '');
  };

  handleCityChange = (field, value) => {
    const { cityOptions = [] } = this.state;
    const { form } = this.props;
    const city = cityOptions.find((co) => co.value === value);
    if (city) {
      const { coord_lat, coord_long } = city;
      form.setValue('lat', coord_lat);
      form.setValue('long', coord_long);
    }
  };

  getPrivateIPs = () => {
    const { form } = this.props;
    const privateIPv4 = form.getField('metadata.private_ipv4_addresses');
    const privateIPv6 = form.getField('metadata.private_ipv6_addresses');

    return {
      privateIPv4: {
        field: privateIPv4,
        value: ((privateIPv4 && privateIPv4.getValue()[0]) || {}).value
      },
      privateIPv6: {
        field: privateIPv6,
        value: ((privateIPv6 && privateIPv6.getValue()[0]) || {}).value
      }
    };
  };

  getPublicIPs = () => {
    const { form } = this.props;
    const publicIPv4 = form.getField('metadata.public_ipv4_addresses');
    const publicIPv6 = form.getField('metadata.public_ipv6_addresses');

    return {
      publicIPv4: {
        field: publicIPv4,
        value: ((publicIPv4 && publicIPv4.getValue()[0]) || {}).value
      },
      publicIPv6: {
        field: publicIPv6,
        value: ((publicIPv6 && publicIPv6.getValue()[0]) || {}).value
      }
    };
  };

  ipsToShow = () => {
    const { form } = this.props;
    const agentFamily = form.getValue('agent_family');
    const showBothIPs = agentFamily === 'DUAL';
    const showIPv4 = showBothIPs || agentFamily === 'v4';
    const showIPv6 = showBothIPs || agentFamily === 'v6';

    return [showIPv4, showIPv6];
  };

  handleIPValue = ({ field, value = '' }, empty) => {
    field.setValue(empty ? [] : [{ value }]);
  };

  handleAgentFamilyChange = (field, value) => {
    const showBothIPs = value === 'DUAL';
    const showIPv4 = showBothIPs || value === 'v4';
    const showIPv6 = showBothIPs || value === 'v6';
    const { privateIPv4, privateIPv6 } = this.getPrivateIPs();
    const { publicIPv4, publicIPv6 } = this.getPublicIPs();

    if (showIPv4) {
      this.handleIPValue(privateIPv4);
      this.handleIPValue(publicIPv4);

      if (!showBothIPs) {
        this.handleIPValue(privateIPv6, true);
        this.handleIPValue(publicIPv6, true);
      }
    }

    if (showIPv6) {
      this.handleIPValue(privateIPv6);
      this.handleIPValue(publicIPv6);

      if (!showBothIPs) {
        this.handleIPValue(privateIPv4, true);
        this.handleIPValue(publicIPv4, true);
      }
    }
  };

  buildPrivateIPField = ({ field }, { value: publicIP }, version) =>
    field.map(({ value }, i) => {
      if (i === 0) {
        return (
          <Box mb={2} key={`private-${version}`}>
            <Field
              field={value}
              large
              helpText={publicIP ? `Public ${version}: ${publicIP}` : `No public ${version} detected`}
              mb={0}
            >
              <InputGroup />
            </Field>
          </Box>
        );
      }

      return null;
    });

  renderPrivateIPs = () => {
    const { privateIPv4, privateIPv6 } = this.getPrivateIPs();
    const { publicIPv4, publicIPv6 } = this.getPublicIPs();
    const [showIPv4, showIPv6] = this.ipsToShow();
    const missingIP = (showIPv4 && !privateIPv4.value) || (showIPv6 && !privateIPv6.value);
    const v4Block = showIPv4 ? this.buildPrivateIPField(privateIPv4, publicIPv4, 'IPv4') : null;
    const v6Block = showIPv6 ? this.buildPrivateIPField(privateIPv6, publicIPv6, 'IPv6') : null;

    return (
      <>
        {v4Block}
        {v6Block}
        {missingIP && (
          <CalloutOutline intent="primary" mb={2}>
            <Flex alignItems="center">
              <Icon icon={FiInfo} iconSize={30} mr="8px" color="primary" />
              <Box flex="1 1 auto">
                <Text small fontWeight="heavy">
                  Note:{' '}
                </Text>
                <Text small>
                  Setting the agent’s Private IP allows you to establish agent-to-agent or agent mesh tests using your
                  internal network via the Use Private IP test option.
                </Text>
              </Box>
            </Flex>
          </CalloutOutline>
        )}
      </>
    );
  };

  buildPublicIPField = ({ field }, version) => {
    if (field) {
      return field.map(({ value }, i) => {
        if (i === 0) {
          return (
            <Box mb={2} key={`public-${version}`}>
              <Field field={value} large mb={0}>
                <InputGroup />
              </Field>
            </Box>
          );
        }

        return null;
      });
    }

    return null;
  };

  renderPublicIPs = () => {
    const { publicIPv4, publicIPv6 } = this.getPublicIPs();
    const [showIPv4, showIPv6] = this.ipsToShow();
    const v4Block = showIPv4 ? this.buildPublicIPField(publicIPv4, 'IPv4') : null;
    const v6Block = showIPv6 ? this.buildPublicIPField(publicIPv6, 'IPv6') : null;

    return (
      <>
        {v4Block}
        {v6Block}
      </>
    );
  };

  handleAgentAlertStatusChange = () => {
    const { form } = this.props;
    const agentAlertStatus = form.getValue('agent_alert_rule_status');
    const agentAlertThresholdSeconds = form.getValue('agent_alert_rule_config.thresholds[0].thresholdSeconds');
    const agentAlertThresholdType = form.getValue('agent_alert_rule_config.thresholds[0].thresholdType');

    if (agentAlertStatus) {
      if (!agentAlertThresholdSeconds) {
        form.setValues({
          'agent_alert_rule_config.thresholds[0].thresholdSeconds': form.getField(
            'agent_alert_rule_config.thresholds[0].thresholdSeconds'
          ).default
        });
      }

      if (!agentAlertThresholdType) {
        form.setValues({
          'agent_alert_rule_config.thresholds[0].thresholdType': form.getField(
            'agent_alert_rule_config.thresholds[0].thresholdType'
          ).default
        });
      }
    }
  };

  handleAgentAlertStatusThresholdTypeChange = (fieldState, value) => {
    const { form } = this.props;
    const agentAlertThresholdSeconds = form.getValue('agent_alert_rule_config.thresholds[0].thresholdSeconds');

    if (value === 'minutes') {
      form.setValues({
        'agent_alert_rule_config.thresholds[0].thresholdSeconds': agentAlertThresholdSeconds / 60
      });
    } else {
      form.setValues({
        'agent_alert_rule_config.thresholds[0].thresholdSeconds': agentAlertThresholdSeconds * 60
      });
    }
  };

  /*
    Enables the alarm suppression start time validator only when suppression is enabled
  */
  updateAlarmSuppressionStartTimeRules = () => {
    const { form } = this.props;
    const enabled = form.getValue('agent_alert_rule_config.suppression.enabled');
    const rules = [];

    if (enabled) {
      rules.push('alarmSuppressionStartTime');
    }

    form.getField('agent_alert_rule_config.suppression.startTime').setRules(rules);
  };

  render() {
    const { $lookups, form } = this.props;
    const { allowSudoMode, sudoMode } = this.state;
    const country = form.getValue('country');
    const region = form.getValue('region');
    const regionInfo = this.getRegionInfo(country);
    const cloud = form.getValue('metadata.cloud_provider');
    const isPrivateAgent = form.model.get('agent_type') === 'private';
    const agentAlertStatus = form.getValue('agent_alert_rule_status');
    const maintenanceWindowEnabled = form.getValue('agent_alert_rule_config.suppression.enabled');

    let showCloudRegion = false;
    let cloudRegionOptions = [];
    if (cloud) {
      showCloudRegion = true;
      cloudRegionOptions = this.getCloudRegionOptions(cloud);
    }

    return (
      <Box>
        <Field name="agent_alias" large autoFocus>
          <InputGroup />
        </Field>

        {isPrivateAgent && <SiteSelect isRequired siteFieldName="site_id" />}

        <Box mb={2}>
          <Field name="metadata.cloud_provider" large options={this.cloudProviderOptions}>
            <Select />
          </Field>
          {showCloudRegion && (
            <>
              <Field name="metadata.cloud_region" large options={cloudRegionOptions}>
                <Select autoComplete showFilter />
              </Field>
              <Field name="metadata.cloud_vpc" large>
                <InputGroup />
              </Field>
            </>
          )}
        </Box>
        {isPrivateAgent && this.renderPrivateIPs()}
        <Box mb={2}>
          <Field name="agent_family" large onChange={this.handleAgentFamilyChange}>
            <Select />
          </Field>
        </Box>
        <Box mb={2} width={230}>
          <LabelSelector fieldName="agent_labels" />
        </Box>

        {isPrivateAgent && (
          <>
            <Box mb={2}>
              <Field name="agent_alert_rule_status" large onChange={this.handleAgentAlertStatusChange}>
                <Switch />
              </Field>
            </Box>
            {agentAlertStatus && (
              <>
                <Flex gap={1} mb={2}>
                  <Field
                    name="agent_alert_rule_config.thresholds[0].thresholdSeconds"
                    showLabel={false}
                    width="160px"
                    large
                    fieldStyle={{ marginBottom: 0 }}
                  >
                    <InputGroup
                      type="number"
                      rightElement={
                        <Field
                          name="agent_alert_rule_config.thresholds[0].thresholdType"
                          onChange={this.handleAgentAlertStatusThresholdTypeChange}
                          showLabel={false}
                          showError={false}
                          fieldStyle={{ marginBottom: 0 }}
                        >
                          <Select menuWidth={96} minimal buttonStyle={{ alignText: 'right', minimal: true }} />
                        </Field>
                      }
                    />
                  </Field>
                  <Text mt="6px">of downtime</Text>
                </Flex>
                <NotificationChannels mb={2} fieldName="notificationChannels" />
              </>
            )}

            <Box mb={2}>
              <Field
                name="agent_alert_rule_config.suppression.enabled"
                onChange={this.updateAlarmSuppressionStartTimeRules}
                large
              >
                <Switch />
              </Field>
            </Box>
            {maintenanceWindowEnabled && <MaintenanceMode />}
          </>
        )}

        {allowSudoMode && (
          <>
            <Button
              icon={sudoMode ? 'caret-down' : 'caret-right'}
              onClick={this.handleSudoModeToggle}
              text="Advanced Options"
              intent="primary"
              mb={1}
              minimal
            />
            <Collapse isOpen={sudoMode}>
              <Box mb={2}>
                <Field name="os" fill large>
                  <InputGroup />
                </Field>
              </Box>
              {this.renderPublicIPs()}
              {!isPrivateAgent && (
                <Field name="metadata.broadband" large showLabel={false}>
                  <Checkbox />
                </Field>
              )}
              <Callout intent="primary" mb={1}>
                {form.model.get('agent_type') === 'private' && (
                  <Box mb={2}>
                    <Text muted>
                      NOTE: Any country/region/city or manual lat/lon changes will override site geo-data for a private
                      agent. Save <Text fontStyle="italic">with Advanced Options collapsed</Text> to get site geo-data.
                    </Text>
                  </Box>
                )}
                <Field name="country" onQuery={$lookups.countries} onChange={this.handleCountryChange} large>
                  <Select showFilter clearable fill />
                </Field>
                <Field
                  name="region"
                  label={regionInfo.label}
                  onQuery={regionInfo.onQuery}
                  onChange={this.handleRegionChange}
                  large
                >
                  <Select {...regionInfo.cmpProps} fill />
                </Field>
                <Field
                  name="city"
                  onQuery={(filter, options) =>
                    $lookups.citiesByCountry(country, region, filter, options).then((results) => {
                      this.setState({ cityOptions: results });
                      return results;
                    })
                  }
                  onChange={this.handleCityChange}
                  large
                >
                  <Select showFilter clearable fill />
                </Field>
                <Flex flex={1} justifyContent="space-between">
                  <Box flex={1}>
                    <Field name="lat" fill large>
                      <InputGroup />
                    </Field>
                  </Box>
                  <Box flex={1} ml={1}>
                    <Field name="long" fill large>
                      <InputGroup />
                    </Field>
                  </Box>
                </Flex>
              </Callout>
            </Collapse>
          </>
        )}
      </Box>
    );
  }
}
