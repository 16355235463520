import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from 'core/components';
import { ValueCell } from './ValueCell';
import Cell from './Cell';
import ActionsMenu from './ActionsMenu';

function Row({
  bucket,
  dimensionColumns,
  valueColumns,
  loading,
  showActionsMenu,
  createActionMenuItems,
  actions,
  allowSelection,
  onSelect,
  selections,
  selectionDisabled,
  showSparklines,
  queryModel,
  queryResultsCollection,
  onClick,
  index,
  model,
  shouldAddDeviceColumn,
  onRowMouseOver,
  onRowMouseOut,
  selectionRenderer,
  mergeOverlayCells
}) {
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <tr
      onMouseOver={() => onRowMouseOver && onRowMouseOver(model)}
      onMouseOut={() => onRowMouseOut && onRowMouseOut(model)}
    >
      {allowSelection && <td>{selectionRenderer({ model, onSelect, selections, selectionDisabled })}</td>}
      {dimensionColumns.map(({ key, ...column }, idx) => (
        <Cell
          key={`${key}-${model.id}`}
          {...column}
          model={model}
          value={model.get(key || 'name')}
          loading={loading}
          queryModel={queryModel}
          queryResultsCollection={queryResultsCollection}
          showSparklines={showSparklines && idx === 0}
          onClick={onClick}
          index={index}
          columnIndex={idx}
          columnCount={dimensionColumns.length}
          mergeOverlayCells={mergeOverlayCells}
        />
      ))}
      {valueColumns.map(({ key, ...column }) => (
        <ValueCell
          key={`${key}-${model.id}`}
          {...column}
          model={model}
          loading={loading}
          queryModel={queryModel}
          queryResultsCollection={queryResultsCollection}
          index={index}
        />
      ))}
      {showActionsMenu && (
        <td>
          <ActionsMenu
            queryModel={queryModel}
            bucket={bucket}
            model={model}
            createActionMenuItems={createActionMenuItems}
            shouldAddDeviceColumn={shouldAddDeviceColumn}
          />
        </td>
      )}
      {actions && <td>{actions(model)}</td>}
    </tr>
  );
}

Row.defaultProps = {
  selectionRenderer: ({ model, onSelect, selections, selectionDisabled }) => (
    <Checkbox
      onChange={() => onSelect(model)}
      checked={selections.includes(model)}
      disabled={selectionDisabled && selectionDisabled(model)}
      mb={0}
    />
  )
};

export default observer(Row);
