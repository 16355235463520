import React from 'react';
import { Flex, Icon, Text } from 'core/components';

const ItemExplorerOptionTitle = ({ title, icon }) => (
  <Flex alignItems="center" mb={1}>
    <Icon icon={icon} color="orange4" mr={1} iconSize={14} />
    <Text as="div" fontWeight="bold">
      {title}
    </Text>
  </Flex>
);

export default ItemExplorerOptionTitle;
