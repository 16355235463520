import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Heading, Card, Text, Flex, Link, Typewriter, LinkButton, Button, CalloutOutline } from 'core/components';
import Page from 'app/components/page/Page';

import { ReactComponent as GenAiIcon } from 'app/assets/icons/journeys-icon-1.svg';
import { ReactComponent as ModernNetworksIcon } from 'app/assets/icons/journeys-icon-2.svg';
import { ReactComponent as DemocratizeIcon } from 'app/assets/icons/journeys-icon-3.svg';
import journeysVideo from 'app/assets/videos/journeys-teaser.mp4';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import UserInitials from './UserInitials';

const Feature = ({ title, children, icon }) => (
  <Card p={3} style={{ lineHeight: '22px', minHeight: 170, minWidth: 300 }} elevation={1} flex={1}>
    <Flex flexDirection="column" gap="8px">
      {icon}
      <Heading level={5} m={0}>
        {title}
      </Heading>
      <Text muted>{children}</Text>
    </Flex>
  </Card>
);

const iconStyle = { width: 32, height: 32, marginBottom: 8 };

@withRouter
@inject('$app', '$auth', '$users')
@observer
class JourneysPaintedDoor extends Component {
  handleRequestAccess = () => {
    const { $app } = this.props;
    $app.setReportIssuePopoverPropsAndOpen({
      type: 'ai request',
      summary: 'Request to enable Kentik AI',
      description: ''
    });
  };

  render() {
    const { location, $auth, $users } = this.props;
    const { state } = location;
    const { companyHasSuperAdmins } = $users;

    return (
      <Page title="Journeys" pt={0} pb={0} hideSubnav scrolls>
        <Flex flexDirection="column" pt="40px" pb="24px" m="0px auto" maxWidth={950} gap="32px">
          <Flex flexDirection="column" gap="12px">
            {state?.prompt && (
              <Card p="12px" elevation={1} mb={1}>
                <Flex alignItems="center" gap={1}>
                  <UserInitials userId={$auth.activeUser.id} />
                  <Heading level={5} m={0} fontWeight="medium" flex={1}>
                    <Typewriter
                      removeCursor={false}
                      text={state?.prompt?.includes('?') ? state?.prompt : `${state.prompt}?`}
                      options={{ autoStart: true, loop: true, delay: 100, deleteSpeed: 50 }}
                    />
                  </Heading>
                </Flex>
              </Card>
            )}

            <Heading m={0} level={1}>
              Let Kentik AI answer any question about your network.
            </Heading>

            <Text as="p" large muted lineHeight="24px">
              Welcome to our new intelligent, conversational interface that transforms network problem-solving into a
              systematic and documented expedition. With Journeys, you can ask queries about your network Data, Flows
              and Metrics, and instantly visualize the results, enabling faster, more informed decisions.
            </Text>
            <Flex alignItems="flex-start" gap={3} flexWrap="wrap">
              <Feature title="The first network GenAI" icon={<GenAiIcon style={iconStyle} />}>
                Answer any question about your network in any natural language.
              </Feature>
              <Feature title="Built for modern networks" icon={<ModernNetworksIcon style={iconStyle} />}>
                Analyze diverse telemetry from any device, container, or cloud at scale.
              </Feature>
              <Feature title="Democratize network insights" icon={<DemocratizeIcon style={iconStyle} />}>
                Level up your entire engineering team.
              </Feature>
            </Flex>

            <hr />

            <Flex alignItems="center" gap={4}>
              <Flex flexDirection="column">
                <Heading level={3}>Enable Kentik AI for Access</Heading>
                <Text as="p" large muted lineHeight="24px">
                  To use the Journeys feature, please enable Kentik AI. We are focused on gathering user feedback,
                  improving accuracy, expanding supported questions, and integrating additional Kentik features.
                </Text>
              </Flex>
              <Flex gap={1} minWidth={365} flexDirection="column" alignItems="center">
                {$auth.isSuperAdministrator && (
                  <LinkButton
                    to="/v4/settings/kentik-ai"
                    fontWeight="bold"
                    intent="primary"
                    large
                    fill
                    style={{ padding: 16 }}
                  >
                    Enable Kentik AI
                  </LinkButton>
                )}

                {!$auth.isSuperAdministrator && (
                  <>
                    {companyHasSuperAdmins && (
                      <CalloutOutline intent="primary" p="24px" textAlign="center">
                        <Text fontWeight="bold">
                          To access Journeys, please ask your company Super Admin to enable Kentik AI.
                        </Text>
                      </CalloutOutline>
                    )}
                    {!companyHasSuperAdmins && (
                      <Button
                        onClick={this.handleRequestAccess}
                        fontWeight="bold"
                        intent="primary"
                        large
                        fill
                        style={{ padding: 16 }}
                      >
                        Request Access
                      </Button>
                    )}
                  </>
                )}
                <Link
                  small
                  blank
                  to="https://www.kentik.com/blog/reinventing-network-monitoring-and-observability-with-kentik-ai/"
                >
                  Read the blog post
                </Link>
              </Flex>
            </Flex>
          </Flex>

          <Card p="4px">
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video width="100%" height="100%" style={{ width: '100%', height: '100%' }} autoPlay controls>
              <source src={journeysVideo} type="video/mp4" />
            </video>
          </Card>
        </Flex>
      </Page>
    );
  }
}

export default JourneysPaintedDoor;
