import * as React from 'react';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { AZURE_ENTITY_TYPES } from 'shared/hybrid/constants';
import { Box, Button, Card, Flex, Text } from 'core/components';
import { formConsumer, Field, Select } from 'core/form';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';

@formConsumer
@withTheme
@observer
export default class SubscriptionCard extends React.Component {
  render() {
    const { field, scopeField, scopeFieldIndex, onQuery, theme } = this.props;
    const subscriptionId = field.subscriptionId.getValue();
    const isValid = field.isValid.getValue();
    const message = field.message.getValue();

    return (
      <Card key={subscriptionId} p={1} css={{ '&:hover': { background: theme.colors.sectionBackground } }}>
        <Flex mb={1} alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <CloudIcon cloudProvider="azure" entity={AZURE_ENTITY_TYPES.SUBSCRIPTION} />
            <Text ml={1} fontWeight="bold">
              {subscriptionId}
            </Text>
          </Flex>

          <Button intent="danger" onClick={() => scopeField.remove(scopeFieldIndex)} icon="small-cross" minimal />
        </Flex>

        <Flex>
          <CloudIcon cloudProvider="azure" entity={AZURE_ENTITY_TYPES.RESOURCE_GROUP} />
          <Box width="100%">
            <Field
              ml={1}
              field={field.resourceGroups}
              placeholder="All Resource Groups"
              onQuery={(search) => onQuery(field, search)}
              disabled={!isValid}
            >
              <Select multi showFilter fill />
            </Field>
          </Box>
        </Flex>

        {!isValid && (
          <Text color="danger" small>
            {`* ${message || 'Subscription ID not found'}`}
          </Text>
        )}
      </Card>
    );
  }
}
