import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FiCheck } from 'react-icons/fi';
import Button from 'core/components/Button';
import DeckModel from 'app/stores/decks/DeckModel';

@inject('$decks')
@observer
class SaveDeckButton extends Component {
  handleSave = (share_level) => {
    const { $decks, decks, deck, type, selectedShareLevel, onSave } = this.props;
    let deckToSave = deck;

    // If we're changing the share_level, we need to update the deck that already exists
    // or create a new one if there isn't already one
    if (share_level !== selectedShareLevel) {
      deckToSave = decks.find((d) => d.get('share_level') === share_level);
      if (!deckToSave) {
        const { id, ...deckConfig } = deck.get();
        deckToSave = new DeckModel({ ...deckConfig, share_level });
      }
    }

    deckToSave.save({ share_level }).then(() => {
      $decks.getDefaultDeck({ type, canCustomize: true }).then((updatedDecks) => {
        onSave({ deck: deckToSave, decks: updatedDecks, selectedShareLevel: share_level });
      });
    });
  };

  render() {
    const { $decks, selectedShareLevel, deck, decks, type, onSave, ...rest } = this.props;

    return (
      <Button icon={FiCheck} intent="primary" text="Save Changes" onClick={() => this.handleSave('self')} {...rest} />
    );
  }
}

export default SaveDeckButton;
