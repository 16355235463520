import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Collection from 'core/model/Collection';
import moment from 'moment';
import { adjustByGreekPrefix, greekPrefix } from 'core/util';
import safelyParseJSON from 'core/util/safelyParseJson';
import { Box, Card, Flex, Tag, Text, Button, Select, Icon, Popover, Menu, MenuItem } from 'core/components';
import { FiGlobe, FiMoreVertical, FaEthernet } from 'react-icons/fi';
import { Table, VirtualizedTable, Filter, CELL_TYPES } from 'core/components/table';
import { InterfaceNameSnmpIdAndDescription } from 'app/components/Interface';
import ViewInExplorerMenuItem from 'app/components/dataviews/tools/ViewInExplorerMenuItem';
import CapacityMeter from 'app/views/edge/cost/components/CapacityMeter';
import TrendChange from 'app/components/TrendChange';
import { zeroToText } from 'app/util/utils';
import { FaCheckCircle } from 'react-icons/fa';
import { BsXOctagonFill, BsExclamationTriangleFill } from 'react-icons/bs';
import { ReactComponent as RouterIconCircle } from 'app/assets/icons/router_icon_circle.svg';
import SeverityFilters from './SeverityFilters';
import CapacityPlanRowItem from './CapacityPlanRowItem';

// To go in next release
// import PlanChart from './PlanChart';

@inject('$app', '$exports', '$dictionary', '$interfaces', '$ott')
@withRouter
@observer
class CapacityPlan extends Component {
  state = {
    selectedFilter: ['warning', 'critical'],
    sorting: true,
    grouping: ''
  };

  componentDidMount() {
    const { $app, $exports, plan } = this.props;

    $exports.getSettings().then(({ hashedSortField, hashedSortDirection }) => {
      if (hashedSortField) {
        plan.interfaceGroup.interfaceCollection.sort(hashedSortField, hashedSortDirection || 'asc');
      }

      this.setState({ sorting: false });

      if (!$app.isExport) {
        if (window.localStorage) {
          const selectedFilter = safelyParseJSON(
            window.localStorage.getItem(`kentik.capacityplan.${plan.id}.severityfilters`)
          );
          if (selectedFilter) {
            this.onSeverityFilterClick(selectedFilter);
          }
        }
      } else {
        this.onSeverityFilterClick(['healthy', 'warning', 'critical']);
      }

      // To go in next release
      // this.chartSeries = this.getChartSeries(plan.interfaceGroup.interfaceCollection);
    });
  }

  componentDidUpdate(prevProps) {
    const { plan } = this.props;

    if (plan !== prevProps.plan || plan.id !== prevProps.plan.id) {
      plan.interfaceGroup.fetch();
    }
  }

  getTableColumns = () => {
    const { $app, $dictionary, plan } = this.props;
    const { grouping } = this.state;

    const { warning: warningThreshold, critical: criticalThreshold, aggregate } = plan.get('thresholds').utilization;

    const columns = [
      {
        label: 'Sev.',
        computed: true,
        name: 'severity',
        width: 40,
        align: 'center',
        renderer: ({ value }) => {
          if (value === 1) {
            return <Icon icon={BsExclamationTriangleFill} color="warning" />;
          }
          if (value === 2) {
            return <Icon icon={BsXOctagonFill} color="danger" />;
          }
          return <Icon icon={FaCheckCircle} color="success" />;
        }
      },
      {
        label: 'Interface',
        name: 'interface_description',
        flexBasis: 175,
        ellipsis: false,
        renderer: ({ model }) => {
          const capacity = parseInt(model.get('snmp_speed')) * 1000000;
          const util = model.get('util');
          const snmp_traffic = Math.max(
            ...[util?.in.current.bps, util?.out.current.bps].filter((value) => Number.isFinite(value))
          );
          const usagePercent = capacity > 0 ? (snmp_traffic / capacity) * 100 : 100;
          return (
            <Box overflow="hidden">
              <InterfaceNameSnmpIdAndDescription
                interface_description={model.get('interface_description')}
                snmp_alias={model.get('snmp_alias')}
                snmp_id={model.get('snmp_id')}
                device_name={model.get('device_name')}
                link
              />
              <Box mt="1">
                <CapacityMeter
                  small={false}
                  showText={false}
                  useCap={capacity}
                  dangerThreshold={criticalThreshold}
                  warningThreshold={warningThreshold}
                  usePercent={usagePercent}
                />
              </Box>
            </Box>
          );
        }
      },
      {
        label: 'Capacity',
        name: 'capacity',
        renderer: ({ model }) => {
          const capacity = parseInt(model.get('snmp_speed')) * 1000000;
          const capacityGreek = greekPrefix([capacity]);
          return (
            <Box>
              <Text>
                {capacity !== 0 && Number.isFinite(capacity)
                  ? `${adjustByGreekPrefix(capacity, capacityGreek)} ${capacityGreek}bits/s`
                  : 'No Capacity'}
              </Text>
            </Box>
          );
        }
      }
    ];
    if (grouping === '') {
      // When no grouping, show site and device columns
      columns.push(
        {
          label: 'Site',
          name: 'title'
        },
        {
          label: 'Device',
          name: 'device_name'
        }
      );
    } else if (grouping === 'title') {
      // When grouping by site, show device column
      columns.push({
        label: 'Device',
        name: 'device_name'
      });
    }
    columns.push(
      {
        label: 'Network Boundary',
        name: 'network_boundary',
        width: 100,
        renderer: ({ value }) => $dictionary.get(`interfaceClassification.networkBoundaryTypes.${value}`) || value
      },
      {
        label: 'Connectivity Type',
        name: 'connectivity_type',
        renderer: ({ value }) => $dictionary.get(`interfaceClassification.connectivityTypes.${value}`) || value
      },
      {
        label: 'Provider',
        name: 'provider'
      },
      {
        label: `Traffic (${aggregate})`,
        key: 'traffic',
        name: 'traffic',
        computed: true,
        ellipsis: false,
        renderer: ({ model }) => {
          const value = model.get('util');
          if (value) {
            const { inTextProps, outTextProps } = model.get('utilizationSeverity');

            // 80% (40 of 50 Gb) [+8% (7d)]
            return (
              <Box overflow="hidden">
                <Flex alignItems="center" my="1px">
                  <Text as="div" width={30} muted fontWeight="bold">
                    In
                  </Text>
                  <Text {...inTextProps}>
                    {inTextProps?.color === 'warning' && (
                      <Icon iconSize={12} m="2px" icon={BsExclamationTriangleFill} color="warning" />
                    )}
                    {inTextProps?.color === 'danger' && (
                      <Icon iconSize={12} m="2px" icon={BsXOctagonFill} color="danger" />
                    )}
                    {Number.isFinite(value.in.current.bps)
                      ? `${zeroToText(
                          adjustByGreekPrefix(value.in.current.bps, greekPrefix([value.in.current.bps]))
                        )} ${greekPrefix([value.in.current.bps])}bits/s`
                      : 'N/A'}
                  </Text>
                </Flex>
                <Flex alignItems="center" my="1px">
                  <Text as="div" width={30} muted fontWeight="bold">
                    Out
                  </Text>
                  <Text {...outTextProps}>
                    {outTextProps?.color === 'warning' && (
                      <Icon iconSize={12} m="2px" icon={BsExclamationTriangleFill} color="warning" />
                    )}
                    {outTextProps?.color === 'danger' && (
                      <Icon iconSize={12} m="2px" icon={BsXOctagonFill} color="danger" />
                    )}
                    {Number.isFinite(value.out.current.bps)
                      ? `${zeroToText(
                          adjustByGreekPrefix(value.out.current.bps, greekPrefix([value.out.current.bps]))
                        )} ${greekPrefix([value.out.current.bps])}bits/s`
                      : 'N/A'}
                  </Text>
                </Flex>
              </Box>
            );
          }
          return <Text muted>N/A</Text>;
        }
      },
      {
        label: `Utilization (${aggregate})`,
        name: 'util',
        computed: true,
        ellipsis: false,
        width: 100,
        renderer: ({ model }) => {
          const value = model.get('util');
          if (value) {
            const { inTextProps, outTextProps } = model.get('utilizationSeverity');

            // 80% (40 of 50 Gb) [+8% (7d)]
            return (
              <Box overflow="hidden">
                <Flex alignItems="center" my="1px">
                  <Text {...inTextProps}>
                    {inTextProps?.color === 'warning' && (
                      <Icon iconSize={12} m="2px" icon={BsExclamationTriangleFill} color="warning" />
                    )}
                    {inTextProps?.color === 'danger' && (
                      <Icon iconSize={12} m="2px" icon={BsXOctagonFill} color="danger" />
                    )}
                    {Number.isFinite(parseFloat(value.in.current.pct)) ? `${value.in.current.pct}%` : 'N/A'}
                  </Text>
                </Flex>
                <Flex alignItems="center" my="1px">
                  <Text {...outTextProps}>
                    {outTextProps?.color === 'warning' && (
                      <Icon iconSize={12} m="2px" icon={BsExclamationTriangleFill} color="warning" />
                    )}
                    {outTextProps?.color === 'danger' && (
                      <Icon iconSize={12} m="2px" icon={BsXOctagonFill} color="danger" />
                    )}
                    {Number.isFinite(parseFloat(value.out.current.pct)) ? `${value.out.current.pct}%` : 'N/A'}
                  </Text>
                </Flex>
              </Box>
            );
          }
          return <Text muted>N/A</Text>;
        }
      },
      {
        label: 'Runout',
        name: 'runout',
        computed: true,
        ellipsis: false,
        renderer: ({ model }) => {
          const value = model.get('runoutSeverity');
          if (value?.inDate || value?.outDate) {
            const { inDate, outDate, showInDate, showOutDate, inColor, outColor } = value;

            return (
              <Box overflow="hidden">
                <Flex as="div" my="1px">
                  <Text as="div" width={30} muted fontWeight="bold">
                    In
                  </Text>
                  {inColor === 'warning' && (
                    <Icon iconSize={12} m="2px" icon={BsExclamationTriangleFill} color="warning" />
                  )}
                  {inColor === 'danger' && <Icon iconSize={12} m="2px" icon={BsXOctagonFill} color="danger" />}
                  <Text color={inColor}>{showInDate ? inDate : 'N/A'}</Text>
                </Flex>
                <Flex as="div" my="1px">
                  <Text as="div" width={30} muted fontWeight="bold">
                    Out
                  </Text>
                  {outColor === 'warning' && (
                    <Icon iconSize={12} m="2px" icon={BsExclamationTriangleFill} color="warning" />
                  )}
                  {outColor === 'danger' && <Icon iconSize={12} m="2px" icon={BsXOctagonFill} color="danger" />}
                  <Text color={outColor}>{showOutDate ? outDate : 'N/A'}</Text>
                </Flex>
              </Box>
            );
          }
          return <Text muted>N/A</Text>;
        }
      },
      {
        label: 'Trend',
        key: 'trend',
        name: 'trend',
        computed: true,
        ellipsis: false,
        width: 150,
        renderer: ({ model }) => {
          const value = model.get('runoutSeverity');
          if (value?.bpsInTrend || value?.bpsOutTrend) {
            const { prefixInTrend, prefixOutTrend, bpsInTrend, bpsOutTrend, inColor, outColor, unitsLabel } = value;

            return (
              <Box overflow="hidden">
                <Flex as="div" my="1px">
                  <TrendChange
                    small
                    minimal
                    style={{ marginLeft: 4, paddingLeft: 2, paddingRight: 2, minHeight: 15, height: 15 }}
                    increaseIntent={inColor}
                    decreaseIntent={inColor}
                    trendRawValue={bpsInTrend}
                    trendValue={prefixInTrend || '---'}
                    unitsLabel={unitsLabel}
                    fontWeight="bold"
                  />
                </Flex>
                <Flex as="div" my="1px">
                  <TrendChange
                    small
                    minimal
                    style={{ marginLeft: 4, paddingLeft: 2, paddingRight: 2, minHeight: 15, height: 15 }}
                    increaseIntent={outColor}
                    decreaseIntent={outColor}
                    trendRawValue={bpsOutTrend}
                    trendValue={prefixOutTrend || '---'}
                    unitsLabel={unitsLabel}
                    fontWeight="bold"
                  />
                </Flex>
              </Box>
            );
          }
          return <Text muted>N/A</Text>;
        }
      },
      {
        type: CELL_TYPES.ACTION,
        width: 48,
        actions: [
          (model) =>
            !$app.isExport ? (
              <Popover position="bottom-right" ml="4px" content={this.renderOptionsMenu(model)}>
                <Button
                  key={`${model.get('device_id')}|${model.get('snmp_id')}`}
                  minimal
                  icon={FiMoreVertical}
                  alignSelf="center"
                />
              </Popover>
            ) : null
        ]
      }
    );
    return columns;
  };

  renderOptionsMenu(model) {
    const { $interfaces } = this.props;
    return (
      <Menu minWidth="180px">
        <MenuItem
          icon={FaEthernet}
          text="Interface Details"
          onClick={() => $interfaces.navigateToInterface(model.get('snmp_id'), model.get('device_name'))}
        />
        {model.get('network_boundary') === 'external' && (
          <MenuItem
            icon={FiGlobe}
            text="Traffic Engineering"
            onClick={() =>
              this.handleEngineerTraffic({ device_name: model.get('device_name'), snmp_id: model.get('snmp_id') })
            }
          />
        )}
        <ViewInExplorerMenuItem
          query={this.getTrafficQuery(model)}
          text="Show in Data Explorer"
          icon="pivot-table"
          openInNewWindow
        />
      </Menu>
    );
  }

  onSeverityFilterClick = (selectedFilter) => {
    const { onSeverityFilterClick } = this.props;
    this.setState({ selectedFilter }, () => {
      const { plan } = this.props;
      const showHealthy = selectedFilter.includes('healthy');
      const showWarning = selectedFilter.includes('warning');
      const showCritical = selectedFilter.includes('critical');
      onSeverityFilterClick({ showHealthy, showWarning, showCritical });
      const healthyCondition = (iface) => showHealthy && iface.severity === 0;
      const warnCondition = (iface) => showWarning && iface.severity === 1;
      const criticalCondition = (iface) => showCritical && iface.severity === 2;
      plan.interfaceGroup.interfaceCollection.setPresetFilter({
        fn: (iface) => healthyCondition(iface) || warnCondition(iface) || criticalCondition(iface)
      });

      if (window.localStorage) {
        window.localStorage.setItem(`kentik.capacityplan.${plan.id}.severityfilters`, JSON.stringify(selectedFilter));
      }
    });
  };

  groupSummaryLookupFn = ({ groupKey, group }) => {
    const { $dictionary } = this.props;
    const { grouping } = this.state;
    const groupRep = group[0];
    const { device_name, title } = groupRep.get();
    const groupCount = (
      <Tag minimal round ml={1}>
        {group.length}
      </Tag>
    );

    if (grouping === 'deviceSiteInfo') {
      return (
        <>
          <Text fontWeight="bold" mx={1}>
            <Text mr={2}>
              <Icon icon={RouterIconCircle} mr={1} />
              {device_name}
            </Text>
            <Text>
              <Icon icon="map-marker" mr={1} />
              {title}
            </Text>
          </Text>
          {groupCount}
        </>
      );
    }

    if (grouping === 'title') {
      return (
        <>
          <Text>
            <Icon icon="map-marker" mr={1} />
            {groupKey}
          </Text>
          {groupCount}
        </>
      );
    }

    if (grouping === 'connectivity_type') {
      return (
        <>
          <Text>{$dictionary.get(`interfaceClassification.connectivityTypes.${groupKey}`) || groupKey}</Text>
          {groupCount}
        </>
      );
    }

    if (grouping === 'providerConnType') {
      return (
        <>
          <Text>{groupKey}</Text>
          {groupCount}
        </>
      );
    }

    return <></>;
  };

  groupByValueRenderer = (value) => (
    <Box>
      Group By: <Text fontWeight="bold">{value.label}</Text>
    </Box>
  );

  renderFilters() {
    const { plan } = this.props;
    const { selectedFilter, grouping } = this.state;

    if (!plan) {
      return null;
    }

    const discreteFilters = [...plan.interfaceGroup.interfaceCollection.discreteFilters];

    return (
      <Box mt={1} mb={1}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex pt={1}>
            <Filter
              mr={1}
              inputProps={{ width: 300 }}
              collection={plan.interfaceGroup.interfaceCollection}
              placeholder="Search..."
            />
            <SeverityFilters onChange={this.onSeverityFilterClick} selectedFilter={selectedFilter} />
            <Box ml={1}>
              <Select
                menuWidth={300}
                valueRenderer={this.groupByValueRenderer}
                onChange={(newGrouping) => {
                  this.setState({ grouping: newGrouping });

                  setTimeout(() => {
                    plan.interfaceGroup.interfaceCollection.group(newGrouping || null);
                  }, 0);
                }}
                options={[
                  { label: 'None', value: '' },
                  { label: 'Site', value: 'title', icon: 'map-marker' },
                  { label: 'Device (Site)', value: 'deviceSiteInfo', icon: RouterIconCircle },
                  { label: 'Connectivity Type', value: 'connectivity_type' },
                  { label: 'Provider (Connectivity Type)', value: 'providerConnType' }
                ]}
                values={grouping}
              />
            </Box>
          </Flex>
        </Flex>

        {discreteFilters.map((filter, idx) => (
          <Tag
            mr={1}
            interactive
            key={filter.label}
            minimal
            onRemove={() => {
              discreteFilters.splice(idx, 1);
              plan.interfaceGroup.interfaceCollection.setDiscreteFilters(discreteFilters);
              plan.interfaceGroup.interfaceCollection.filter();
            }}
          >
            {filter.label}
          </Tag>
        ))}
        <Box m={1} />
      </Box>
    );
  }

  getTrafficQuery = (model) => {
    const { $ott } = this.props;

    return {
      ...$ott.queries.getInterfaceTrafficQuery({
        viz_type: 'stackedArea',
        snmp_id: model.get('snmp_id'),
        device_name: model.get('device_name'),
        aggregateType: 'max_ktappprotocol__snmp__INT64_00',
        lookback_seconds: 2592000
      }),
      device_types: [],
      device_name: [model.get('device_name')],
      mirror: true,
      sync_axes: true,
      forceMinsPolling: true,
      minsPolling: 5
    };
  };

  getChartSeries = (collection) => {
    const { plan } = this.props;

    let hasData = false;
    const totalCapacity = {};
    const series = {
      inData: {
        success: { name: 'successIn', key: 'successIn', type: 'column', data: [] },
        warning: { name: 'warningIn', key: 'warningIn', type: 'column', data: [] },
        danger: { name: 'dangerIn', key: 'dangerIn', type: 'column', data: [] }
      },
      outData: {
        success: { name: 'successOut', key: 'successOut', type: 'column', data: [] },
        warning: { name: 'warningOut', key: 'warningOut', type: 'column', data: [] },
        danger: { name: 'dangerOut', key: 'dangerOut', type: 'column', data: [] }
      }
    };

    collection.models.forEach((model) => {
      model.get('allCapacityInsights').forEach((insight) => {
        const {
          endTime,
          metricToValue: { inBps, outBps }
        } = insight;
        const { inTextProps, outTextProps } = this.get('utilizationSeverity');
        const { warning: warningThreshold, critical: dangerThreshold } = plan.get('thresholds').utilization;
        const options = {
          interface_description: model.get('interface_description'),
          snmp_alias: model.get('snmp_alias'),
          snmp_speed: model.get('snmp_speed'),
          snmp_id: model.get('snmp_id'),
          device_name: model.get('device_name'),
          title: model.get('title'),
          connectivity_type: model.get('connectivity_type'),
          provider: model.get('provider'),
          warningThreshold,
          dangerThreshold
        };
        const x = moment(endTime).unix() * 1000;

        if (inBps) {
          hasData = true;
          const severity = inTextProps?.color ? inTextProps.color : 'success';
          if (severity === 'success') {
            // all healthy/success traffic as one block in time x
            const currentDatapoint = series.inData[severity].data.find(({ x: currX }) => currX === x);
            if (currentDatapoint) {
              currentDatapoint.y += inBps;
            } else {
              series.inData[severity].data.push({ x, y: inBps });
            }
          } else {
            options.snmp_traffic = inBps;
            options.service_traffic = inBps;
            options.parent = severity;
            series.inData[severity].data.push({ x, y: inBps, ...options, insight });
          }
        }

        if (outBps) {
          hasData = true;
          const severity = outTextProps?.color ? outTextProps.color : 'success';
          if (severity === 'success') {
            // all healthy/success traffic as one block in time x
            const currentDatapoint = series.outData[severity].data.find(({ x: currX }) => currX === x);
            if (currentDatapoint) {
              currentDatapoint.y += -outBps;
            } else {
              series.outData[severity].data.push({ x, y: -outBps });
            }
          } else {
            options.snmp_traffic = outBps;
            options.service_traffic = outBps;
            options.parent = severity;
            series.outData[severity].data.push({ x, y: -outBps, ...options, insight });
          }
        }
        totalCapacity[x] = (totalCapacity[x] || 0) + model.get('snmp_speed') * 1000000;
      });
    });

    if (!hasData) {
      return null;
    }

    const results = [
      series.inData.danger,
      series.inData.warning,
      series.inData.success,
      series.outData.danger,
      series.outData.warning,
      series.outData.success
    ];
    results.forEach((result) => {
      result.data.sort((a, b) => a.y - b.y);
    });

    // capacity line data
    results.push({
      name: 'capacityIn',
      key: 'capacityIn',
      type: 'line',
      data: Object.entries(totalCapacity).flatMap(([x, y]) => [
        { x: parseInt(x) - 86400000, y },
        { x, y, dataLabels: { enabled: true, format: 'Capacity', verticalAlign: 'bottom' } },
        { x: parseInt(x) + 86400000, y }
      ])
    });
    results.push({
      name: 'capacityOut',
      key: 'capacityOut',
      type: 'line',
      data: Object.entries(totalCapacity).flatMap(([x, y]) => [
        { x: parseInt(x) - 86400000, y: -y },
        { x, y: -y, dataLabels: { enabled: true, format: 'Capacity', verticalAlign: 'top' } },
        { x: parseInt(x) + 86400000, y: -y }
      ])
    });

    return results;
  };

  handleEngineerTraffic = ({ device_name, snmp_id }, direction = 'outbound') => {
    const { history } = this.props;
    history.push(`/v4/edge/traffic/eng/${device_name}-${snmp_id}?direction=${direction}`);
  };

  expandedRowRenderer = (model) => {
    const { plan } = this.props;
    const { warning: warningThreshold, critical: criticalThreshold } = plan.get('thresholds').utilization;
    const capacity = parseInt(model.get('snmp_speed')) * 1000000;
    const dangerThresholdBps = (criticalThreshold / 100) * capacity;
    const warningThresholdBps = (warningThreshold / 100) * capacity;
    const inBps = model.util?.in?.current?.bps || 0;
    const outBps = model.util?.out?.current?.bps || 0;

    return (
      <CapacityPlanRowItem
        model={model}
        warningThresholdBps={warningThresholdBps}
        dangerThresholdBps={dangerThresholdBps}
        warningThreshold={warningThreshold}
        criticalThreshold={criticalThreshold}
        getTrafficQuery={this.getTrafficQuery}
        inBps={inBps}
        outBps={outBps}
      />
    );
  };

  render() {
    const { $app, plan, loading } = this.props;
    const { sorting } = this.state;

    if (!plan) {
      return null;
    }

    const TableComponent = $app.isExport ? Table : VirtualizedTable;

    const MAX_INTERFACES_SHOWN_ON_EXPORT = 200;
    const collection = !$app.isExport
      ? plan.interfaceGroup.interfaceCollection
      : new Collection(plan.interfaceGroup.interfaceCollection.models.slice(0, MAX_INTERFACES_SHOWN_ON_EXPORT));

    return (
      <>
        {/* To go in next release */}
        {/* {this.chartSeries && <PlanChart series={this.chartSeries} />} */}

        {!$app.isExport && this.renderFilters()}
        {$app.isExport && plan.interfaceGroup.interfaceCollection.models.length > MAX_INTERFACES_SHOWN_ON_EXPORT && (
          <Text m={1}>
            {MAX_INTERFACES_SHOWN_ON_EXPORT} of {plan.interfaceGroup.interfaceCollection.models.length} interfaces shown
          </Text>
        )}
        <Card display="flex" flexDirection="column" flex="1 1 auto">
          <TableComponent
            collection={collection}
            columns={this.getTableColumns()}
            expandedRowRenderer={this.expandedRowRenderer}
            rowHeight={({ model }) => (model && model.isGroupSummary ? 38 : 64) + (model && model.isSelected ? 260 : 0)}
            loading={loading || sorting}
            flexed
            groupSummaryLookup={this.groupSummaryLookupFn}
          />
        </Card>
      </>
    );
  }
}

export default CapacityPlan;
