import React from 'react';
import { observer } from 'mobx-react';
import { Box, Card, EmptyState, LinkButton } from 'core/components';

@observer
export default class LoadingPanel extends React.Component {
  static defaultProps = {
    test: null,
    loading: false,
    empty: null,
    emptyFallback: null
  };

  get emptyStateContent() {
    const { test = {}, testResults = {} } = this.props;
    const { hasResults } = testResults;

    if (test?.isScheduled) {
      const lastKnownResult = test?.lastKnownScheduledResult;

      return {
        icon: 'time',
        title: 'Test Paused After User-Specified Period',
        description: 'Resume this test to continue gathering data',
        action: lastKnownResult && (
          <LinkButton
            intent="primary"
            text="Go to last known result"
            onClick={(e) => {
              e.preventDefault();

              // reset the date range which registers and routes a new query hash and triggers a new fetch
              testResults.onDateRangeChange(lastKnownResult);
            }}
            minimal
          />
        )
      };
    }

    if (test?.isPreview) {
      return {
        title: 'No results',
        description: 'Results are not available'
      };
    }

    const action = <LinkButton to="/v4/synthetics/tests" intent="primary" minimal text="View All Tests" />;

    if (test && !hasResults && test.get('healthErrors').length !== 0) {
      return {
        title: 'Test Activation Error!',
        description: test.get('healthErrors').join(' '),
        action
      };
    }

    return {
      title: 'No results',
      description: 'Results are not currently available for this time range',
      action
    };
  }

  render() {
    const { children, test, loading, empty, emptyFallback, ...containerProps } = this.props;

    if (loading) {
      return <Box height={125} showSkeleton {...containerProps} />;
    }

    if (empty === true) {
      return (
        <Card p={1}>
          {emptyFallback ?? <EmptyState icon="disable" {...this.emptyStateContent} {...containerProps} />}
        </Card>
      );
    }

    return children;
  }
}
