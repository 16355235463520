import React from 'react';
import { observer } from 'mobx-react';
import { Text } from 'core/components';

function FrequencyLabel({ test, ...textProps }) {
  const { frequencyLabel } = test || {};

  if (frequencyLabel) {
    return <Text muted small {...textProps}>{`Testing ${frequencyLabel}`}</Text>;
  }

  return null;
}

export default observer(FrequencyLabel);
