import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Card, Box, Tag, Flex, Heading, Icon, Text } from 'core/components';
import { RemoveButton } from 'core/form';
import { uniqBy } from 'lodash';
import { VirtualizedTable, CELL_TYPES } from 'core/components/table';
import InterfaceGroupInterfaceCollection from 'app/stores/interfaceGroup/InterfaceGroupInterfaceCollection';
import { InterfaceNameAndDescription } from 'app/components/Interface';
import FilterGroupSummary from 'app/components/interfaceGroup/FilterGroupSummary';

@inject('$dictionary', '$devices')
@observer
export default class SelectedFiltersDisplay extends Component {
  state = {
    filterGroups: [],
    staticInterfaces: []
  };

  componentDidMount() {
    const { filterGroups, staticInterfaces } = this.props;
    this.setState({ filterGroups, staticInterfaces });
  }

  static getDerivedStateFromProps(props, state) {
    const { filterGroups, staticInterfaces } = props;
    if (
      filterGroups.length !== state.filterGroups.length ||
      staticInterfaces.length !== state.staticInterfaces.length
    ) {
      return {
        filterGroups,
        staticInterfaces
      };
    }
    return null;
  }

  get columns() {
    const { columns } = this.props;
    if (columns) {
      return columns;
    }

    return [
      {
        label: 'Interface',
        name: 'interface_description',
        renderer: ({ model }) => (
          <Box overflow="hidden" lineHeight="14px">
            <InterfaceNameAndDescription
              device_name={model.get('device.device_name')}
              interface_description={model.get('interface_description')}
              snmp_alias={model.get('snmp_alias')}
            />
          </Box>
        )
      },
      {
        label: 'Capacity',
        computed: true,
        name: 'capacity',
        renderer: ({ model }) => model.capacityLabel
      },
      { label: 'Network Boundary', computed: true, name: 'networkBoundary' },
      { label: 'Connectivity Type', computed: true, name: 'connectivityType' },
      { label: 'Provider', name: 'provider' }
    ];
  }

  get staticColumns() {
    return [
      ...this.columns,
      {
        type: CELL_TYPES.ACTION,
        actions: [
          (model) => (
            <RemoveButton
              key="remove"
              hideText
              showIcon
              minimal
              entityName="Static Interface"
              onRemove={() => this.removeStaticInterface(model)}
              small
            />
          )
        ]
      }
    ];
  }

  removeDynamicGroup = (idx) => {
    const { filterGroups, staticInterfaces } = this.state;
    filterGroups.splice(idx, 1);
    this.handleSave({ filterGroups, staticInterfaces });
  };

  removeStaticInterface = (model) => {
    const { filterGroups, staticInterfaces } = this.state;
    const staticIdx = staticInterfaces.indexOf(model);
    staticInterfaces.splice(staticIdx, 1);
    model.collection.remove(model.id);
    this.handleSave({ filterGroups, staticInterfaces });
  };

  handleSave = ({ filterGroups, staticInterfaces }) => {
    const { onSave } = this.props;
    const filteredInterfaces = filterGroups.flatMap(({ interfaces }) => interfaces);
    const uniqInterfaces = uniqBy(
      [...staticInterfaces, ...filteredInterfaces],
      (model) => model.snmp_id + model.device_id
    );

    this.setState({ filterGroups, staticInterfaces }, () =>
      onSave({ filters: filterGroups, staticInterfaces, interfaces: uniqInterfaces })
    );
  };

  tableCollection = (interfaces) => {
    const interfaceCollection = new InterfaceGroupInterfaceCollection(interfaces);
    interfaceCollection.group('device_id');
    return interfaceCollection;
  };

  getGroupRenderer = ({ groupKey, group }) => {
    const { $devices } = this.props;
    const deviceSummary = $devices.deviceSummariesById[groupKey];

    if (!deviceSummary) {
      return (
        <>
          <Text fontWeight="bold" mr={1}>
            Unknown Device
          </Text>
          <Tag minimal round>
            {group.length}
          </Tag>
        </>
      );
    }

    const { device_name, title } = deviceSummary;
    return (
      <>
        <Text fontWeight="bold" mr={1}>
          {device_name}
        </Text>
        <Text fontWeight="normal" muted mr={1}>
          {title}
        </Text>
        <Tag minimal round>
          {group.length}
        </Tag>
      </>
    );
  };

  render() {
    const {
      staticInterfacesTitle,
      staticInterfacesEmptyText = 'No static interfaces selected',
      staticOnly = false
    } = this.props;
    const { filterGroups, staticInterfaces } = this.state;

    return (
      <Box>
        {!staticOnly && (
          <>
            {filterGroups.length === 0 && (
              <Card mb={3} overflow="hidden">
                <Flex bg="subnavBackground" mb={0} p="12px" alignItems="center">
                  <Box>
                    <Icon icon="antenna" color={filterGroups.length === 0 ? 'muted' : 'primary'} iconSize={18} />
                    <Heading level={5} mb={0} ml={1} display="inline-block" whiteSpace="nowrap">
                      Dynamic Group
                    </Heading>
                    {filterGroups.length === 0 && (
                      <Text as="div" fontStyle="italic" small muted mt={1}>
                        No dynamic interface groups created
                      </Text>
                    )}
                  </Box>
                </Flex>
              </Card>
            )}

            {filterGroups.length > 0 &&
              filterGroups.map((filterGroup, idx) => (
                <Card mb={3} overflow="hidden" key={JSON.stringify(filterGroup.filters)}>
                  <Flex
                    bg="subnavBackground"
                    mb={0}
                    justifyContent="space-between"
                    borderBottom="thin"
                    p="12px"
                    alignItems="center"
                  >
                    <Flex flexWrap="nowrap" alignItems="center">
                      <Icon icon="antenna" color={filterGroups.length === 0 ? 'muted' : 'primary'} iconSize={18} />
                      <Heading color="primary" level={5} mb={0} ml={1} display="inline-block" whiteSpace="nowrap">
                        Dynamic Group
                        <Tag ml={1} fontWeight="normal" round intent="primary">
                          {filterGroup.total_count}
                        </Tag>
                      </Heading>
                      <FilterGroupSummary flex={1} filterGroup={filterGroup} />
                    </Flex>
                    <RemoveButton
                      entityName="Dynamic Group"
                      small
                      showIcon
                      minimal
                      onRemove={() => this.removeDynamicGroup(idx)}
                    />
                  </Flex>
                  <Flex flexDirection="column" height="350px">
                    <VirtualizedTable
                      collection={this.tableCollection(filterGroup.interfaces)}
                      columns={this.columns}
                      groupSummaryLookup={this.getGroupRenderer}
                      rowHeight={({ model }) => (model && model.isGroupSummary ? 38 : 64)}
                      isCollapsed={false}
                      selectOnRowClick={false}
                      rowAlign="center"
                      flexed
                    />
                  </Flex>
                </Card>
              ))}
          </>
        )}

        <Card mb={3} overflow="hidden" display="flex" flexDirection="column">
          <Flex
            bg="subnavBackground"
            mb={0}
            borderBottom={staticInterfaces.length > 0 ? 'thin' : 'none'}
            p="12px"
            alignItems="center"
          >
            <Box>
              <Icon icon="confirm" color={staticInterfaces.length === 0 ? 'muted' : 'primary'} iconSize={18} />
              <Heading
                level={5}
                mb={0}
                ml={1}
                color={staticInterfaces.length > 0 ? 'primary' : null}
                display="inline-block"
              >
                {staticInterfacesTitle || 'Selected Interfaces'}
                {staticInterfaces.length > 0 && (
                  <Tag ml={1} fontWeight="normal" round intent="primary">
                    {staticInterfaces.length}
                  </Tag>
                )}
              </Heading>
              {staticInterfaces.length === 0 && (
                <Text as="div" fontStyle="italic" small muted mt={1}>
                  {staticInterfacesEmptyText}
                </Text>
              )}
            </Box>
          </Flex>

          {staticInterfaces.length > 0 && (
            <Flex flexDirection="column" minHeight="350px" flex={1}>
              <VirtualizedTable
                collection={this.tableCollection(staticInterfaces)}
                columns={this.staticColumns}
                groupSummaryLookup={this.getGroupRenderer}
                selectOnRowClick={false}
                rowHeight={({ model }) => (model && model.isGroupSummary ? 38 : 64)}
                flexed
              />
            </Flex>
          )}
        </Card>
      </Box>
    );
  }
}
