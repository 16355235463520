import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { MdInfoOutline } from 'react-icons/md';
import { PopoverPosition, Position } from '@blueprintjs/core';
import { Flex, Popover, Box, Text, Divider, ButtonLink, Tooltip, Icon } from 'core/components';

// Todo: https://github.com/kentik/ui-app/issues/8161
// move and util
const getGeo = (geo = { city: {}, country: {}, region: {} }) => {
  const { latitude, longitude } = geo.city || {};
  const { code, name: country } = geo.country || {};
  const { name: region } = geo.region || {};

  if (!latitude || !longitude || !code || !country || !region) {
    return null;
  }

  return geo;
};

const getLocationName = (geo) => {
  const { country, region, city } = geo || {};
  const parts = [];

  [city, region, country].forEach((area) => {
    const name = (area && area.name) || '';
    if (name && name !== '-' && parts[parts.length - 1] !== name) {
      parts.push(name);
    }
  });

  return parts.length ? parts.join(', ') : null;
};

@withTheme
class BgpPathsPopover extends Component {
  renderIcon = (node) => {
    const { style } = node;

    return (
      <Box position="relative" display="inline-block" mr={1} borderRadius="2px" width={20} height={20}>
        <Box
          position="absolute"
          top={0}
          left={0}
          display="block"
          borderRadius="2px"
          width={20}
          height={20}
          bg={style.color}
          opacity={0.5}
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          display="block"
          borderRadius="2px"
          width={20}
          height={20}
          border={`2px solid ${style.stroke || style.color}`}
          bg="transparent"
        />
      </Box>
    );
  };

  renderHeading = () => {
    const { data } = this.props;
    const { info } = data;
    const { name, tooltipName } = info;

    return (
      <Flex alignItems="center">
        {this.renderIcon(data)}
        <Text large fontWeight="bold" style={{ wordBreak: 'break-word' }}>
          {tooltipName || name}
        </Text>
      </Flex>
    );
  };

  renderPathCount = () => {
    const { data } = this.props;
    const { info } = data;
    const { pathCount } = info;

    return (
      pathCount && (
        <Text as="div" muted mt="4px">
          {`${pathCount} path${pathCount > 1 ? 's' : ''}`}
        </Text>
      )
    );
  };

  renderInfoTooltip = (description) => (
    <Tooltip
      openOnTargetFocus={false}
      position={Position.TOP}
      content={<div style={{ maxWidth: 200 }}>{description}</div>}
    >
      <Icon icon={MdInfoOutline} iconSize={14} color="muted" mr="8px" />
    </Tooltip>
  );

  renderPathData = () => {
    const { data } = this.props;
    if (data.type === 'asn') {
      const routesByVP = this.getRoutesByVP();
      const originate = routesByVP.reduce((acc, curr) => (curr.isVp ? (acc += 1) : acc), 0);
      const pruned = routesByVP.reduce((acc, curr) => (curr.isPruned ? (acc += 1) : acc), 0);
      const transiting = routesByVP.reduce((acc, curr) => (!curr.isVp ? (acc += 1) : acc), 0);

      return (
        <Box>
          <Divider my={1} />
          <Box my="2px">
            {this.renderInfoTooltip(
              'Public and Private BGP peer devices that Kentik collects data from, to build this visualization. A number here indicates that there are one or more VPs located in this AS'
            )}
            <Text fontWeight="bold">Vantage Points (VPs): </Text>
            <Text>{originate}</Text>
          </Box>
          <Box my="2px">
            {this.renderInfoTooltip('AS Paths for which this AS is an intermediary AS (not an origin AS)')}
            <Text fontWeight="bold">Transiting Paths: </Text>
            <Text>{transiting}</Text>
          </Box>
          <Box my="2px">
            {this.renderInfoTooltip(
              'Downstream sub-paths that were excluded from the result set to reduce noise. Kentik excludes any links that are not present in 3% of the AS Paths that its Vantage Points see'
            )}
            <Text ml={1}>- Pruned Paths: </Text>
            <Text>{pruned}</Text>
          </Box>
        </Box>
      );
    }
    return this.renderPathCount();
  };

  renderNodeDetails = () => {
    const { data, lookups } = this.props;

    if (data.type === 'prefix') {
      const { id: ip } = data;
      const ipInfo = lookups.ips[ip] || {};
      const { asn } = ipInfo;
      const dns = lookups.dns[ip];
      const geo = getGeo(ipInfo.geo);
      const location = getLocationName(geo);
      return location || (asn && asn.name) || (dns && dns.name) ? (
        <>
          <Divider my={1} />
          <Flex flexDirection="column" alignItems="flex-start">
            {location && (
              <Text as="div" my="2px">
                {location}
              </Text>
            )}
            {asn && asn.name && (
              <Text as="div" my="2px">
                {`AS${asn.id} - ${asn.name}`}
              </Text>
            )}
            {dns && (
              <Text as="div" my="2px">
                {dns.name}
              </Text>
            )}
          </Flex>
          <Divider my={1} />
        </>
      ) : (
        <Divider my={1} />
      );
    }

    return null;
  };

  getRoutesByVP = () => {
    const { data } = this.props;
    const routesByVP = data.hops.reduce((acc, curr) => {
      const { origin, pruned_hops, cleaned_hops, vantage_point, dataset, collector, peer_asn, peer_ip } = curr;
      const isVp = cleaned_hops[0] === origin;
      const isPruned = !isVp && pruned_hops[0] === origin;

      if (!acc.get(vantage_point)) {
        acc.set(vantage_point, {
          vantage_point,
          dataset,
          collector,
          peer_asn,
          peer_ip,
          routes: [],
          isVp,
          isPruned
        });
      }

      acc.get(vantage_point).routes.push({ ...curr });

      return acc;
    }, new Map());

    return Array.from(routesByVP.values());
  };

  renderVPContent = () => {
    const { lookups, data } = this.props;

    if (data.type === 'asn' && (data.pruned || data.isVp)) {
      const renderASPath = ({ full_hops, origin, prefix }, routeKey) => (
        <>
          {full_hops.map((hop, i) => (
            /* eslint-disable react/no-array-index-key */
            <Text key={`${routeKey}_${hop}_${i}`} fontWeight={hop === origin ? 'bold' : 'normal'}>
              {hop}{' '}
            </Text>
          ))}
          <Text>{prefix}</Text>
        </>
      );

      return (
        <Flex p={3} flexDirection="column" maxWidth="80vw" maxHeight="60vh" overflow="auto">
          {this.getRoutesByVP()
            .filter(({ isVp, isPruned }) => isVp || isPruned)
            .map((vp, i) => {
              const { dataset, collector, peer_asn, peer_ip, routes, vantage_point } = vp;
              const ipInfo = lookups.ips[peer_ip] || {};
              const dns = lookups.dns[peer_ip];
              const geo = getGeo(ipInfo.geo);
              const location = getLocationName(geo);
              const { name: asnName } = lookups.asns[peer_asn] || {};
              const tooltipKey = vantage_point;

              return (
                <Box key={tooltipKey} flex="1 0 auto" mt={i === 0 ? 0 : 3}>
                  <Box my="2px">
                    <Text fontWeight="bold">Collector: </Text>
                    <Text>
                      {dataset} {collector}
                    </Text>
                  </Box>
                  <Box my="2px">
                    <Text fontWeight="bold">VP (peer) ASN: </Text>
                    <Text>{`AS${peer_asn} - ${asnName}`}</Text>
                  </Box>
                  <Box my="2px">
                    <Text fontWeight="bold">VP (peer) IP: </Text>
                    <Text>{peer_ip}</Text>
                    {location && <Text> - {location}</Text>}
                    {dns && dns !== ' - ' && <Text> - {dns}</Text>}
                  </Box>
                  <Box>
                    <Text fontWeight="bold">AS Path: </Text>
                    {routes.length > 1
                      ? routes.map((route) => {
                          const routeKey = `${tooltipKey}-${route.full_hops.join('|')}-${route.prefix}`;
                          return (
                            <Box key={routeKey}>
                              <Text mr="4px">- </Text>
                              {renderASPath(route, routeKey)}
                            </Box>
                          );
                        })
                      : renderASPath(routes[0])}
                  </Box>
                </Box>
              );
            })}
        </Flex>
      );
    }

    return null;
  };

  renderButtons = () => {
    const { data } = this.props;

    if (data.type === 'asn' && (data.pruned || data.isVp)) {
      return (
        <>
          <Divider my={1} />
          <Flex flexDirection="column" alignItems="flex-start">
            <Popover
              minimal={false}
              position={PopoverPosition.RIGHT}
              content={this.renderVPContent()}
              modifiers={{
                arrow: { enabled: true },
                flip: { enabled: true },
                keepTogether: { enabled: true },
                preventOverflow: { enabled: true, boundariesElement: 'viewport' }
              }}
            >
              <Text as="div" small my="2px">
                <ButtonLink>Show Vantage Points (VPs)</ButtonLink>
              </Text>
            </Popover>
          </Flex>
        </>
      );
    }

    return null;
  };

  getNodeContent = () => (
    <Box p={3}>
      <Flex maxWidth={640}>
        <Box minWidth={220}>
          {this.renderHeading()}
          {this.renderNodeDetails()}
          {this.renderPathData()}
          {this.renderButtons()}
        </Box>
      </Flex>
    </Box>
  );

  getLinkContent = () => {
    const { data } = this.props;
    const { to, from } = data.info;

    return (
      <Box p={2} minWidth={240} maxWidth={480}>
        <Box mb="4px">
          <Text large fontWeight="bold">
            Link
          </Text>
        </Box>
        <Divider my={1} />
        <Box>
          <Text as="div" muted mb="4px">
            From
          </Text>
          <Flex alignItems="center">
            {this.renderIcon(from)}
            <Text style={{ wordBreak: 'break-word' }}>{from.tooltipName || from.name}</Text>
          </Flex>
        </Box>
        <Divider my={1} />
        <Box>
          <Text as="div" muted mb="4px">
            To
          </Text>
          <Flex alignItems="center">
            {this.renderIcon(to)}
            <Text style={{ wordBreak: 'break-word' }}>{to.tooltipName || to.name}</Text>
          </Flex>
        </Box>
        <Divider my={1} />
        {this.renderPathCount()}
      </Box>
    );
  };

  getContent = () => {
    const { data, onMouseOver, onMouseOut } = this.props;

    if (!data || !data.info) {
      return null;
    }

    const content = data.info.to || data.info.from ? this.getLinkContent() : this.getNodeContent();

    return (
      <Flex onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
        {content}
      </Flex>
    );
  };

  render() {
    const { isOpen, position } = this.props;
    const content = this.getContent();

    return (
      <Popover
        isOpen={isOpen}
        boundary="viewport"
        position={position.anchor}
        content={content}
        target={<></>}
        targetProps={{ style: { pointerEvents: 'none', position: 'absolute', ...position } }}
        minimal={false}
      />
    );
  }
}

export default BgpPathsPopover;
