import React, { Component } from 'react';
import { observer } from 'mobx-react';

import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import ConfigStatusOverview from 'app/components/decks/widgets/Cloud/ConfigStatusOverview';

@observer
class ConfigStatusWidget extends Component {
  render() {
    const { canCustomize, onRemove } = this.props;

    return (
      <WidgetFrame canCustomize={canCustomize} onRemove={onRemove} title="Cloud Config Status">
        <ConfigStatusOverview />
      </WidgetFrame>
    );
  }
}

export default ConfigStatusWidget;
