import React from 'react';
import { withTheme } from 'styled-components';
import { Flex } from 'core/components';

import { ReactComponent as A1TelekomLogo } from 'app/assets/logos/a1.svg';
import { ReactComponent as AkamaiLogo } from 'app/assets/logos/akamai.svg';
import { ReactComponent as AlgarTelecomLogo } from 'app/assets/logos/algar.svg';
import { ReactComponent as AlibabaLogo } from 'app/assets/logos/alibabacloud.svg';
import { ReactComponent as AmazonLogo } from 'app/assets/logos/amazon.svg';
import { ReactComponent as AngolaCablesLogo } from 'app/assets/logos/angolacables.svg';
import { ReactComponent as ArelionLogo } from 'app/assets/logos/arelion.svg';
import { ReactComponent as Atom86Logo } from 'app/assets/logos/atom86.svg';
import { ReactComponent as ATTLogo } from 'app/assets/logos/att.svg';
import { ReactComponent as BelgacomBICSLogo } from 'app/assets/logos/bics.svg';
import { ReactComponent as BellCanadaLogo } from 'app/assets/logos/bellcanada.svg';
import { ReactComponent as BezeqLogo } from 'app/assets/logos/bezeq.svg';
import { ReactComponent as BhartiAirtelLogo } from 'app/assets/logos/airtel.svg';
import { ReactComponent as BritishTelecommunicationsLogo } from 'app/assets/logos/bt.svg';
import { ReactComponent as CenturyLinkLogo } from 'app/assets/logos/centurylink.svg';
import { ReactComponent as CernetLogo } from 'app/assets/logos/cernet.svg';
import { ReactComponent as CharterCommunicationsLogo } from 'app/assets/logos/charter.svg';
import { ReactComponent as ChinaMobileLogo } from 'app/assets/logos/chinamobile.svg';
import { ReactComponent as ChinaTelecomLogo } from 'app/assets/logos/china_telecom.svg';
import { ReactComponent as ChinaUnicomLogo } from 'app/assets/logos/china-unicom.svg';
import { ReactComponent as ChunghwaTelecomLogo } from 'app/assets/logos/chunghwa_telecom.svg';
import { ReactComponent as ClaroLogo } from 'app/assets/logos/claro.svg';
import { ReactComponent as cloudflareLogo } from 'app/assets/logos/Cloudflare_DNS.svg';
import { ReactComponent as CogentLogo } from 'app/assets/logos/cogent.svg';
import { ReactComponent as ColttechnologyservicesLogo } from 'app/assets/logos/colt.svg';
import { ReactComponent as ColumbusNetworksLogo } from 'app/assets/logos/cw_networks_columbus_networks.svg';
import { ReactComponent as ComcastLogo } from 'app/assets/logos/comcast_group.svg';
import { ReactComponent as CoxCommunicationsLogo } from 'app/assets/logos/cox.svg';
import { ReactComponent as DeutscheTelekomLogo } from 'app/assets/logos/deutsche-telecom.svg';
import { ReactComponent as DoDNetworkInformationCenterLogo } from 'app/assets/logos/DOD.svg';
import { ReactComponent as EdgecastLogo } from 'app/assets/logos/edgecast.svg';
import { ReactComponent as EdgeUnoLogo } from 'app/assets/logos/edgeuno.svg';
import { ReactComponent as EquinixLogo } from 'app/assets/logos/equinix.svg';
import { ReactComponent as ERTelecomLogo } from 'app/assets/logos/ertelecom.svg';
import { ReactComponent as EtisalatLogo } from 'app/assets/logos/etisalat.svg';
import { ReactComponent as FastlyLogo } from 'app/assets/logos/fastly.svg';
import { ReactComponent as GeantLogo } from 'app/assets/logos/geant.svg';
import { ReactComponent as GlobalCloudxChangeLogo } from 'app/assets/logos/gcx.svg';
import { ReactComponent as GlobenetLogo } from 'app/assets/logos/globenet.svg';
import { ReactComponent as GoogleLogo } from 'app/assets/logos/google.svg';
import { ReactComponent as GTTCommunicationsLogo } from 'app/assets/logos/gtt.svg';
import { ReactComponent as HGCGlobalLogo } from 'app/assets/logos/hgc.svg';
import { ReactComponent as HurricaneElectricLogo } from 'app/assets/logos/hurricaneelectric.svg';
import { ReactComponent as Internet2Logo } from 'app/assets/logos/internet2.svg';
import { ReactComponent as InternetInitiativeJapanLogo } from 'app/assets/logos/internetinitiativejapan.svg';
import { ReactComponent as InternexaLogo } from 'app/assets/logos/internexa.svg';
import { ReactComponent as IrideosLogo } from 'app/assets/logos/irideos.svg';
import { ReactComponent as JupiterTelecommunicationsLogo } from 'app/assets/logos/jupitertelecom.svg';
import { ReactComponent as KDDICorporationLogo } from 'app/assets/logos/kddi.svg';
import { ReactComponent as KoreaTelecomLogo } from 'app/assets/logos/korea_telecom.svg';
import { ReactComponent as LGUplusLogo } from 'app/assets/logos/lgu.svg';
import { ReactComponent as LibertyGlobalLogo } from 'app/assets/logos/liberty_global.svg';
import { ReactComponent as LimelightLogo } from 'app/assets/logos/limelight.svg';
import { ReactComponent as LumenLogo } from 'app/assets/logos/lumen.svg';
import { ReactComponent as MarocTelecomLogo } from 'app/assets/logos/maroctelecom.svg';
import { ReactComponent as MegaFonLogo } from 'app/assets/logos/megafon.svg';
import { ReactComponent as MTSLogo } from 'app/assets/logos/mts.svg';
import { ReactComponent as NTTAmericaLogo } from 'app/assets/logos/ntt.svg';
import { ReactComponent as OiLogo } from 'app/assets/logos/oi.svg';
import { ReactComponent as OoredooLogo } from 'app/assets/logos/ooredoo.svg';
import { ReactComponent as OrangeLogo } from 'app/assets/logos/orange.svg';
import { ReactComponent as PartnerCommunicationsLogo } from 'app/assets/logos/partner_communications.svg';
import { ReactComponent as PCCWGlobalLogo } from 'app/assets/logos/pccw.svg';
import { ReactComponent as PLDTLogo } from 'app/assets/logos/pldt.svg';
import { ReactComponent as ProximusLogo } from 'app/assets/logos/proximus.svg';
import { ReactComponent as RascomLogo } from 'app/assets/logos/rascom.svg';
import { ReactComponent as RelianceJIOLogo } from 'app/assets/logos/jio.svg';
import { ReactComponent as RENATERLogo } from 'app/assets/logos/renater.svg';
import { ReactComponent as RETNLogo } from 'app/assets/logos/retn.svg';
import { ReactComponent as RostelecomLogo } from 'app/assets/logos/rostelecom.svg';
import { ReactComponent as SaudiTelecomLogo } from 'app/assets/logos/stc.svg';
import { ReactComponent as SeabornLogo } from 'app/assets/logos/seaborn.svg';
import { ReactComponent as SHAWLogo } from 'app/assets/logos/shaw.svg';
import { ReactComponent as SingtelLogo } from 'app/assets/logos/singtel.svg';
import { ReactComponent as SKBroadbandLogo } from 'app/assets/logos/sk_telecom.svg';
import { ReactComponent as SoftbankLogo } from 'app/assets/logos/softbank.svg';
import { ReactComponent as SpectrumLogo } from 'app/assets/logos/spectrum.svg';
import { ReactComponent as SprintLogo } from 'app/assets/logos/sprint.svg';
import { ReactComponent as StarhubLogo } from 'app/assets/logos/starhub.svg';
import { ReactComponent as SURFNetLogo } from 'app/assets/logos/surfnet.svg';
import { ReactComponent as TalkTalkCommunicationsLogo } from 'app/assets/logos/talktalk.svg';
import { ReactComponent as TATACommunicationsLogo } from 'app/assets/logos/tata.svg';
import { ReactComponent as Tele2Logo } from 'app/assets/logos/tele2.svg';
import { ReactComponent as TelecallLogo } from 'app/assets/logos/telecall.svg';
import { ReactComponent as TelecomArgentinaLogo } from 'app/assets/logos/telecom_argentina.svg';
import { ReactComponent as TelecomEgyptLogo } from 'app/assets/logos/telecom_egypt.svg';
import { ReactComponent as TelecomItaliaLogo } from 'app/assets/logos/telecom-italia.svg';
import { ReactComponent as TelecomItaliaSparkleLogo } from 'app/assets/logos/ti_sparkle.svg';
import { ReactComponent as TelefonicaLogo } from 'app/assets/logos/telefonica.svg';
import { ReactComponent as TeliaLogo } from 'app/assets/logos/telia.svg';
import { ReactComponent as TelmexLogo } from 'app/assets/logos/telmex.svg';
import { ReactComponent as TelstraGlobalLogo } from 'app/assets/logos/telstra.svg';
import { ReactComponent as TelxiusLogo } from 'app/assets/logos/telxius.svg';
import { ReactComponent as TencentLogo } from 'app/assets/logos/tencent.svg';
import { ReactComponent as TIMCellularLogo } from 'app/assets/logos/TIMCellular.svg';
import { ReactComponent as TPGTelecomLogo } from 'app/assets/logos/tpgtelecom.svg';
import { ReactComponent as TranstelecomLogo } from 'app/assets/logos/transtelekom.svg';
import { ReactComponent as TurkTelekomLogo } from 'app/assets/logos/turktelekom.svg';
import { ReactComponent as USAISCLogo } from 'app/assets/logos/usaisc.svg';
import { ReactComponent as USNavyLogo } from 'app/assets/logos/usnavy.svg';
import { ReactComponent as VeonLogo } from 'app/assets/logos/veon.svg';
import { ReactComponent as VerizonLogo } from 'app/assets/logos/verizon.svg';
import { ReactComponent as ViettelLogo } from 'app/assets/logos/viettel.svg';
import { ReactComponent as VNPTLogo } from 'app/assets/logos/vnpt.svg';
import { ReactComponent as VocusConnectLogo } from 'app/assets/logos/vocus.svg';
import { ReactComponent as VodafoneLogo } from 'app/assets/logos/vodafone.svg';
import { ReactComponent as WindstreamLogo } from 'app/assets/logos/windstream.svg';
import { ReactComponent as ZainLogo } from 'app/assets/logos/zain.svg';
import { ReactComponent as ZayoLogo } from 'app/assets/logos/zayo.svg';

const logoMap = {
  1: LumenLogo,
  13: USAISCLogo,
  22: USNavyLogo,
  37: USNavyLogo,
  39: DoDNetworkInformationCenterLogo,
  48: USNavyLogo,
  51: USAISCLogo,
  54: USAISCLogo,
  56: DoDNetworkInformationCenterLogo,
  66: USAISCLogo,
  78: BritishTelecommunicationsLogo,
  83: USAISCLogo,
  84: USNavyLogo,
  89: USNavyLogo,
  93: NTTAmericaLogo,
  94: USAISCLogo,
  95: DoDNetworkInformationCenterLogo,
  96: DoDNetworkInformationCenterLogo,
  97: NTTAmericaLogo,
  114: NTTAmericaLogo,
  115: DoDNetworkInformationCenterLogo,
  118: DoDNetworkInformationCenterLogo,
  121: DoDNetworkInformationCenterLogo,
  124: DoDNetworkInformationCenterLogo,
  126: DoDNetworkInformationCenterLogo,
  129: DoDNetworkInformationCenterLogo,
  135: DoDNetworkInformationCenterLogo,
  138: DoDNetworkInformationCenterLogo,
  139: USNavyLogo,
  140: DoDNetworkInformationCenterLogo,
  141: USNavyLogo,
  142: USAISCLogo,
  145: VerizonLogo,
  147: DoDNetworkInformationCenterLogo,
  148: USNavyLogo,
  149: DoDNetworkInformationCenterLogo,
  150: DoDNetworkInformationCenterLogo,
  153: USAISCLogo,
  154: USAISCLogo,
  155: DoDNetworkInformationCenterLogo,
  162: USNavyLogo,
  164: DoDNetworkInformationCenterLogo,
  165: DoDNetworkInformationCenterLogo,
  170: DoDNetworkInformationCenterLogo,
  172: USAISCLogo,
  174: CogentLogo,
  189: LumenLogo,
  190: USNavyLogo,
  199: CenturyLinkLogo,
  200: CenturyLinkLogo,
  201: CenturyLinkLogo,
  202: CenturyLinkLogo,
  203: CenturyLinkLogo,
  209: CenturyLinkLogo,
  213: USAISCLogo,
  215: USAISCLogo,
  252: USAISCLogo,
  253: NTTAmericaLogo,
  257: USNavyLogo,
  258: USAISCLogo,
  260: GTTCommunicationsLogo,
  261: RENATERLogo,
  262: NTTAmericaLogo,
  263: NTTAmericaLogo,
  272: USAISCLogo,
  275: NTTAmericaLogo,
  279: LumenLogo,
  280: NTTAmericaLogo,
  281: LumenLogo,
  289: USAISCLogo,
  306: DoDNetworkInformationCenterLogo,
  367: DoDNetworkInformationCenterLogo,
  523: USAISCLogo,
  524: LumenLogo,
  531: USAISCLogo,
  560: LumenLogo,
  575: USAISCLogo,
  577: BellCanadaLogo,
  594: CenturyLinkLogo,
  595: CenturyLinkLogo,
  596: CenturyLinkLogo,
  597: CenturyLinkLogo,
  598: CenturyLinkLogo,
  603: EquinixLogo,
  615: USAISCLogo,
  637: DoDNetworkInformationCenterLogo,
  666: USAISCLogo,
  668: DoDNetworkInformationCenterLogo,
  670: USAISCLogo,
  671: USAISCLogo,
  672: USAISCLogo,
  673: USAISCLogo,
  685: NTTAmericaLogo,
  701: VerizonLogo,
  702: VerizonLogo,
  703: VerizonLogo,
  704: VerizonLogo,
  705: VerizonLogo,
  721: DoDNetworkInformationCenterLogo,
  747: USAISCLogo,
  749: DoDNetworkInformationCenterLogo,
  774: RENATERLogo,
  776: RENATERLogo,
  781: RENATERLogo,
  782: RENATERLogo,
  789: RENATERLogo,
  1101: SURFNetLogo,
  1102: SURFNetLogo,
  1103: SURFNetLogo,
  1105: SURFNetLogo,
  1106: SURFNetLogo,
  1107: SURFNetLogo,
  1108: SURFNetLogo,
  1127: SURFNetLogo,
  1128: SURFNetLogo,
  1129: SURFNetLogo,
  1130: SURFNetLogo,
  1131: SURFNetLogo,
  1132: SURFNetLogo,
  1138: SURFNetLogo,
  1139: SURFNetLogo,
  1141: SURFNetLogo,
  1142: SURFNetLogo,
  1143: SURFNetLogo,
  1144: SURFNetLogo,
  1145: SURFNetLogo,
  1146: SURFNetLogo,
  1147: SURFNetLogo,
  1148: SURFNetLogo,
  1149: SURFNetLogo,
  1150: SURFNetLogo,
  1151: SURFNetLogo,
  1152: SURFNetLogo,
  1153: SURFNetLogo,
  1154: SURFNetLogo,
  1155: SURFNetLogo,
  1156: SURFNetLogo,
  1157: SURFNetLogo,
  1158: SURFNetLogo,
  1159: SURFNetLogo,
  1160: SURFNetLogo,
  1161: SURFNetLogo,
  1162: SURFNetLogo,
  1163: SURFNetLogo,
  1164: SURFNetLogo,
  1165: SURFNetLogo,
  1166: SURFNetLogo,
  1167: SURFNetLogo,
  1168: SURFNetLogo,
  1169: SURFNetLogo,
  1170: SURFNetLogo,
  1171: SURFNetLogo,
  1172: SURFNetLogo,
  1173: SURFNetLogo,
  1174: SURFNetLogo,
  1175: SURFNetLogo,
  1176: SURFNetLogo,
  1177: SURFNetLogo,
  1178: SURFNetLogo,
  1179: SURFNetLogo,
  1180: SURFNetLogo,
  1181: SURFNetLogo,
  1182: SURFNetLogo,
  1183: SURFNetLogo,
  1184: SURFNetLogo,
  1185: SURFNetLogo,
  1186: SURFNetLogo,
  1187: SURFNetLogo,
  1188: SURFNetLogo,
  1189: SURFNetLogo,
  1190: SURFNetLogo,
  1191: SURFNetLogo,
  1192: SURFNetLogo,
  1193: SURFNetLogo,
  1194: SURFNetLogo,
  1195: SURFNetLogo,
  1196: SURFNetLogo,
  1197: SURFNetLogo,
  1198: SURFNetLogo,
  1199: SURFNetLogo,
  1221: TelstraGlobalLogo,
  1225: NTTAmericaLogo,
  1239: SprintLogo,
  1257: Tele2Logo,
  1273: VodafoneLogo,
  1281: CogentLogo,
  1282: CogentLogo,
  1283: CogentLogo,
  1284: CogentLogo,
  1290: TelstraGlobalLogo,
  1299: ArelionLogo,
  1303: RENATERLogo,
  1307: RENATERLogo,
  1309: RENATERLogo,
  1451: USAISCLogo,
  1452: USAISCLogo,
  1453: USAISCLogo,
  1456: USAISCLogo,
  1457: USAISCLogo,
  1458: USAISCLogo,
  1460: USAISCLogo,
  1461: USAISCLogo,
  1462: USAISCLogo,
  1463: USAISCLogo,
  1464: USAISCLogo,
  1466: USAISCLogo,
  1467: USAISCLogo,
  1468: USAISCLogo,
  1471: USAISCLogo,
  1472: USAISCLogo,
  1474: USAISCLogo,
  1475: USAISCLogo,
  1476: USAISCLogo,
  1477: USAISCLogo,
  1479: USAISCLogo,
  1480: USAISCLogo,
  1482: USAISCLogo,
  1483: USAISCLogo,
  1484: USAISCLogo,
  1488: USAISCLogo,
  1489: USAISCLogo,
  1491: USAISCLogo,
  1493: USAISCLogo,
  1494: USAISCLogo,
  1495: USAISCLogo,
  1498: USAISCLogo,
  1500: USAISCLogo,
  1501: USAISCLogo,
  1502: USAISCLogo,
  1503: USAISCLogo,
  1504: USAISCLogo,
  1505: USAISCLogo,
  1506: USAISCLogo,
  1508: USAISCLogo,
  1509: USAISCLogo,
  1510: USAISCLogo,
  1511: USAISCLogo,
  1515: USAISCLogo,
  1516: USAISCLogo,
  1518: USAISCLogo,
  1519: USAISCLogo,
  1524: USAISCLogo,
  1525: USAISCLogo,
  1526: USAISCLogo,
  1527: USAISCLogo,
  1528: USAISCLogo,
  1530: USAISCLogo,
  1531: USAISCLogo,
  1533: USAISCLogo,
  1535: USAISCLogo,
  1536: USAISCLogo,
  1537: USAISCLogo,
  1538: USAISCLogo,
  1539: USAISCLogo,
  1540: USAISCLogo,
  1541: USAISCLogo,
  1544: USAISCLogo,
  1554: DoDNetworkInformationCenterLogo,
  1602: USAISCLogo,
  1637: USAISCLogo,
  1649: USAISCLogo,
  1712: RENATERLogo,
  1715: RENATERLogo,
  1724: RENATERLogo,
  1729: ArelionLogo,
  1759: TeliaLogo,
  1772: USNavyLogo,
  1790: SprintLogo,
  1901: A1TelekomLogo,
  1935: RENATERLogo,
  1936: RENATERLogo,
  1937: RENATERLogo,
  1938: RENATERLogo,
  1939: RENATERLogo,
  1940: RENATERLogo,
  1941: RENATERLogo,
  1943: RENATERLogo,
  1944: RENATERLogo,
  1946: RENATERLogo,
  1947: RENATERLogo,
  1948: RENATERLogo,
  1949: RENATERLogo,
  1950: RENATERLogo,
  1951: RENATERLogo,
  1952: RENATERLogo,
  1953: RENATERLogo,
  1954: RENATERLogo,
  1971: DoDNetworkInformationCenterLogo,
  1973: USNavyLogo,
  1976: USNavyLogo,
  1977: USNavyLogo,
  1978: USNavyLogo,
  1979: USNavyLogo,
  1980: USNavyLogo,
  1981: USNavyLogo,
  2060: RENATERLogo,
  2065: RENATERLogo,
  2067: RENATERLogo,
  2072: RENATERLogo,
  2103: RENATERLogo,
  2110: BritishTelecommunicationsLogo,
  2149: CogentLogo,
  2174: RENATERLogo,
  2175: RENATERLogo,
  2176: RENATERLogo,
  2177: RENATERLogo,
  2178: RENATERLogo,
  2179: RENATERLogo,
  2180: RENATERLogo,
  2181: RENATERLogo,
  2182: RENATERLogo,
  2183: RENATERLogo,
  2184: RENATERLogo,
  2185: RENATERLogo,
  2186: RENATERLogo,
  2187: RENATERLogo,
  2188: RENATERLogo,
  2189: RENATERLogo,
  2190: RENATERLogo,
  2191: RENATERLogo,
  2192: RENATERLogo,
  2193: RENATERLogo,
  2194: RENATERLogo,
  2195: RENATERLogo,
  2196: RENATERLogo,
  2197: RENATERLogo,
  2198: RENATERLogo,
  2199: RENATERLogo,
  2200: RENATERLogo,
  2201: RENATERLogo,
  2202: RENATERLogo,
  2203: RENATERLogo,
  2204: RENATERLogo,
  2205: RENATERLogo,
  2206: RENATERLogo,
  2207: RENATERLogo,
  2208: RENATERLogo,
  2209: RENATERLogo,
  2210: RENATERLogo,
  2211: RENATERLogo,
  2212: RENATERLogo,
  2213: RENATERLogo,
  2214: RENATERLogo,
  2215: RENATERLogo,
  2216: RENATERLogo,
  2217: RENATERLogo,
  2218: RENATERLogo,
  2219: RENATERLogo,
  2220: RENATERLogo,
  2221: RENATERLogo,
  2222: RENATERLogo,
  2223: RENATERLogo,
  2224: RENATERLogo,
  2225: RENATERLogo,
  2226: RENATERLogo,
  2227: RENATERLogo,
  2228: RENATERLogo,
  2229: RENATERLogo,
  2230: RENATERLogo,
  2231: RENATERLogo,
  2232: RENATERLogo,
  2233: RENATERLogo,
  2234: RENATERLogo,
  2235: RENATERLogo,
  2236: RENATERLogo,
  2237: RENATERLogo,
  2238: RENATERLogo,
  2239: RENATERLogo,
  2240: RENATERLogo,
  2241: RENATERLogo,
  2242: RENATERLogo,
  2243: RENATERLogo,
  2244: RENATERLogo,
  2245: RENATERLogo,
  2246: RENATERLogo,
  2247: RENATERLogo,
  2248: RENATERLogo,
  2249: RENATERLogo,
  2250: RENATERLogo,
  2251: RENATERLogo,
  2252: RENATERLogo,
  2253: RENATERLogo,
  2254: RENATERLogo,
  2255: RENATERLogo,
  2256: RENATERLogo,
  2257: RENATERLogo,
  2258: RENATERLogo,
  2259: RENATERLogo,
  2260: RENATERLogo,
  2261: RENATERLogo,
  2262: RENATERLogo,
  2263: RENATERLogo,
  2264: RENATERLogo,
  2265: RENATERLogo,
  2266: RENATERLogo,
  2267: RENATERLogo,
  2268: RENATERLogo,
  2269: RENATERLogo,
  2270: RENATERLogo,
  2271: RENATERLogo,
  2272: RENATERLogo,
  2273: RENATERLogo,
  2278: OrangeLogo,
  2279: OrangeLogo,
  2280: OrangeLogo,
  2281: OrangeLogo,
  2282: OrangeLogo,
  2283: OrangeLogo,
  2284: OrangeLogo,
  2285: OrangeLogo,
  2286: OrangeLogo,
  2287: OrangeLogo,
  2288: OrangeLogo,
  2289: OrangeLogo,
  2290: OrangeLogo,
  2291: OrangeLogo,
  2292: OrangeLogo,
  2293: OrangeLogo,
  2294: OrangeLogo,
  2295: OrangeLogo,
  2296: OrangeLogo,
  2297: OrangeLogo,
  2298: OrangeLogo,
  2299: OrangeLogo,
  2300: OrangeLogo,
  2301: OrangeLogo,
  2302: OrangeLogo,
  2303: OrangeLogo,
  2304: OrangeLogo,
  2305: OrangeLogo,
  2306: OrangeLogo,
  2307: OrangeLogo,
  2308: OrangeLogo,
  2309: OrangeLogo,
  2310: OrangeLogo,
  2311: OrangeLogo,
  2312: OrangeLogo,
  2313: OrangeLogo,
  2314: OrangeLogo,
  2315: OrangeLogo,
  2316: OrangeLogo,
  2317: OrangeLogo,
  2318: OrangeLogo,
  2319: OrangeLogo,
  2320: OrangeLogo,
  2321: OrangeLogo,
  2322: OrangeLogo,
  2323: OrangeLogo,
  2324: OrangeLogo,
  2325: OrangeLogo,
  2326: OrangeLogo,
  2327: OrangeLogo,
  2328: OrangeLogo,
  2329: OrangeLogo,
  2330: OrangeLogo,
  2331: OrangeLogo,
  2332: OrangeLogo,
  2333: OrangeLogo,
  2334: OrangeLogo,
  2335: OrangeLogo,
  2336: OrangeLogo,
  2337: OrangeLogo,
  2338: OrangeLogo,
  2339: OrangeLogo,
  2340: OrangeLogo,
  2341: OrangeLogo,
  2342: OrangeLogo,
  2343: OrangeLogo,
  2344: OrangeLogo,
  2345: OrangeLogo,
  2346: OrangeLogo,
  2347: OrangeLogo,
  2348: OrangeLogo,
  2349: OrangeLogo,
  2350: OrangeLogo,
  2351: OrangeLogo,
  2352: OrangeLogo,
  2353: OrangeLogo,
  2354: OrangeLogo,
  2355: OrangeLogo,
  2356: OrangeLogo,
  2357: OrangeLogo,
  2358: OrangeLogo,
  2359: OrangeLogo,
  2360: OrangeLogo,
  2361: OrangeLogo,
  2362: OrangeLogo,
  2363: OrangeLogo,
  2364: OrangeLogo,
  2365: OrangeLogo,
  2366: OrangeLogo,
  2367: OrangeLogo,
  2368: OrangeLogo,
  2369: OrangeLogo,
  2370: OrangeLogo,
  2371: OrangeLogo,
  2372: OrangeLogo,
  2373: OrangeLogo,
  2374: OrangeLogo,
  2375: OrangeLogo,
  2376: OrangeLogo,
  2377: OrangeLogo,
  2379: CenturyLinkLogo,
  2386: ATTLogo,
  2387: RENATERLogo,
  2388: RENATERLogo,
  2389: RENATERLogo,
  2390: RENATERLogo,
  2391: RENATERLogo,
  2392: RENATERLogo,
  2393: RENATERLogo,
  2394: RENATERLogo,
  2395: RENATERLogo,
  2396: RENATERLogo,
  2397: RENATERLogo,
  2398: RENATERLogo,
  2399: RENATERLogo,
  2400: RENATERLogo,
  2401: RENATERLogo,
  2402: RENATERLogo,
  2403: RENATERLogo,
  2404: RENATERLogo,
  2405: RENATERLogo,
  2406: RENATERLogo,
  2407: RENATERLogo,
  2408: RENATERLogo,
  2409: RENATERLogo,
  2410: RENATERLogo,
  2411: RENATERLogo,
  2412: RENATERLogo,
  2413: RENATERLogo,
  2414: RENATERLogo,
  2415: RENATERLogo,
  2416: RENATERLogo,
  2417: RENATERLogo,
  2418: RENATERLogo,
  2419: RENATERLogo,
  2420: RENATERLogo,
  2421: RENATERLogo,
  2422: RENATERLogo,
  2423: RENATERLogo,
  2424: RENATERLogo,
  2425: RENATERLogo,
  2426: RENATERLogo,
  2427: RENATERLogo,
  2428: RENATERLogo,
  2429: RENATERLogo,
  2430: RENATERLogo,
  2431: RENATERLogo,
  2432: RENATERLogo,
  2433: RENATERLogo,
  2434: RENATERLogo,
  2435: RENATERLogo,
  2436: RENATERLogo,
  2437: RENATERLogo,
  2438: RENATERLogo,
  2439: RENATERLogo,
  2440: RENATERLogo,
  2441: RENATERLogo,
  2442: RENATERLogo,
  2443: RENATERLogo,
  2444: RENATERLogo,
  2445: RENATERLogo,
  2446: RENATERLogo,
  2447: RENATERLogo,
  2448: RENATERLogo,
  2449: RENATERLogo,
  2450: RENATERLogo,
  2451: RENATERLogo,
  2452: RENATERLogo,
  2453: RENATERLogo,
  2454: RENATERLogo,
  2455: RENATERLogo,
  2456: RENATERLogo,
  2457: RENATERLogo,
  2497: InternetInitiativeJapanLogo,
  2516: KDDICorporationLogo,
  2529: VodafoneLogo,
  2551: LumenLogo,
  2577: DoDNetworkInformationCenterLogo,
  2599: VeonLogo,
  2647: OrangeLogo,
  2649: CogentLogo,
  2685: ATTLogo,
  2686: ATTLogo,
  2687: ATTLogo,
  2688: ATTLogo,
  2749: USNavyLogo,
  2766: VeonLogo,
  2828: VerizonLogo,
  2854: OrangeLogo,
  2856: BritishTelecommunicationsLogo,
  2874: OrangeLogo,
  2914: NTTAmericaLogo,
  2917: OrangeLogo,
  3150: NTTAmericaLogo,
  3209: VodafoneLogo,
  3215: OrangeLogo,
  3216: VeonLogo,
  3235: VeonLogo,
  3239: RostelecomLogo,
  3246: Tele2Logo,
  3249: TeliaLogo,
  3253: VeonLogo,
  3257: GTTCommunicationsLogo,
  3269: TelecomItaliaLogo,
  3273: VodafoneLogo,
  3291: GTTCommunicationsLogo,
  3300: BritishTelecommunicationsLogo,
  3301: TeliaLogo,
  3302: IrideosLogo,
  3308: TeliaLogo,
  3320: DeutscheTelekomLogo,
  3329: VodafoneLogo,
  3356: LumenLogo,
  3378: VerizonLogo,
  3423: ATTLogo,
  3462: ChunghwaTelecomLogo,
  3475: USNavyLogo,
  3491: PCCWGlobalLogo,
  3549: LumenLogo,
  3559: KoreaTelecomLogo,
  3561: LumenLogo,
  3643: SprintLogo,
  3644: SprintLogo,
  3645: SprintLogo,
  3646: SprintLogo,
  3647: SprintLogo,
  3648: SprintLogo,
  3649: SprintLogo,
  3650: SprintLogo,
  3651: SprintLogo,
  3652: SprintLogo,
  3757: KoreaTelecomLogo,
  3786: LGUplusLogo,
  3825: KoreaTelecomLogo,
  3830: CogentLogo,
  3844: NTTAmericaLogo,
  3908: CenturyLinkLogo,
  3909: CenturyLinkLogo,
  3910: CenturyLinkLogo,
  3934: NTTAmericaLogo,
  3936: NTTAmericaLogo,
  3937: NTTAmericaLogo,
  3938: NTTAmericaLogo,
  3939: NTTAmericaLogo,
  3941: NTTAmericaLogo,
  3942: NTTAmericaLogo,
  3944: NTTAmericaLogo,
  3945: NTTAmericaLogo,
  3946: NTTAmericaLogo,
  3947: NTTAmericaLogo,
  3948: NTTAmericaLogo,
  3949: NTTAmericaLogo,
  4004: OrangeLogo,
  4006: CogentLogo,
  4010: USAISCLogo,
  4040: KoreaTelecomLogo,
  4060: KoreaTelecomLogo,
  4064: CogentLogo,
  4134: ChinaTelecomLogo,
  4185: ATTLogo,
  4200: CogentLogo,
  4230: ClaroLogo,
  4388: CogentLogo,
  4436: GTTCommunicationsLogo,
  4473: ATTLogo,
  4538: CernetLogo,
  4550: CogentLogo,
  4632: TelstraGlobalLogo,
  4637: TelstraGlobalLogo,
  4657: StarhubLogo,
  4659: ChinaUnicomLogo,
  4713: NTTAmericaLogo,
  4725: SoftbankLogo,
  4755: TATACommunicationsLogo,
  4761: OoredooLogo,
  4763: VodafoneLogo,
  4766: KoreaTelecomLogo,
  4768: VodafoneLogo,
  4789: CernetLogo,
  4799: ChinaUnicomLogo,
  4805: OrangeLogo,
  4808: ChinaUnicomLogo,
  4809: ChinaTelecomLogo,
  4810: ChinaTelecomLogo,
  4811: ChinaTelecomLogo,
  4812: ChinaTelecomLogo,
  4813: ChinaTelecomLogo,
  4814: ChinaUnicomLogo,
  4815: ChinaTelecomLogo,
  4816: ChinaTelecomLogo,
  4826: VocusConnectLogo,
  4830: VodafoneLogo,
  4835: ChinaTelecomLogo,
  4837: ChinaUnicomLogo,
  4860: VerizonLogo,
  4862: OrangeLogo,
  4991: CogentLogo,
  4992: CogentLogo,
  4993: CogentLogo,
  4994: CogentLogo,
  4997: ZayoLogo,
  5051: KoreaTelecomLogo,
  5058: USNavyLogo,
  5378: VodafoneLogo,
  5384: EtisalatLogo,
  5396: IrideosLogo,
  5400: BritishTelecommunicationsLogo,
  5423: A1TelekomLogo,
  5424: A1TelekomLogo,
  5432: ProximusLogo,
  5486: PartnerCommunicationsLogo,
  5488: ProximusLogo,
  5511: OrangeLogo,
  5518: TeliaLogo,
  5522: TeliaLogo,
  5543: TranstelecomLogo,
  5562: EquinixLogo,
  5573: RostelecomLogo,
  5574: Tele2Logo,
  5580: GTTCommunicationsLogo,
  5583: OrangeLogo,
  5602: IrideosLogo,
  5604: TalkTalkCommunicationsLogo,
  5617: OrangeLogo,
  5669: GTTCommunicationsLogo,
  5730: ATTLogo,
  5800: DoDNetworkInformationCenterLogo,
  5801: DoDNetworkInformationCenterLogo,
  5802: DoDNetworkInformationCenterLogo,
  5803: DoDNetworkInformationCenterLogo,
  5804: DoDNetworkInformationCenterLogo,
  5805: DoDNetworkInformationCenterLogo,
  5806: DoDNetworkInformationCenterLogo,
  5807: DoDNetworkInformationCenterLogo,
  5808: DoDNetworkInformationCenterLogo,
  5809: DoDNetworkInformationCenterLogo,
  5810: DoDNetworkInformationCenterLogo,
  5811: DoDNetworkInformationCenterLogo,
  5812: DoDNetworkInformationCenterLogo,
  5813: DoDNetworkInformationCenterLogo,
  5814: DoDNetworkInformationCenterLogo,
  5815: DoDNetworkInformationCenterLogo,
  5816: DoDNetworkInformationCenterLogo,
  5817: DoDNetworkInformationCenterLogo,
  5818: DoDNetworkInformationCenterLogo,
  5819: DoDNetworkInformationCenterLogo,
  5820: DoDNetworkInformationCenterLogo,
  5821: DoDNetworkInformationCenterLogo,
  5822: DoDNetworkInformationCenterLogo,
  5823: DoDNetworkInformationCenterLogo,
  5824: DoDNetworkInformationCenterLogo,
  5825: DoDNetworkInformationCenterLogo,
  5826: DoDNetworkInformationCenterLogo,
  5827: DoDNetworkInformationCenterLogo,
  5828: DoDNetworkInformationCenterLogo,
  5829: DoDNetworkInformationCenterLogo,
  5830: DoDNetworkInformationCenterLogo,
  5831: DoDNetworkInformationCenterLogo,
  5832: DoDNetworkInformationCenterLogo,
  5833: DoDNetworkInformationCenterLogo,
  5834: DoDNetworkInformationCenterLogo,
  5835: DoDNetworkInformationCenterLogo,
  5836: DoDNetworkInformationCenterLogo,
  5837: DoDNetworkInformationCenterLogo,
  5838: DoDNetworkInformationCenterLogo,
  5839: DoDNetworkInformationCenterLogo,
  5840: DoDNetworkInformationCenterLogo,
  5841: DoDNetworkInformationCenterLogo,
  5842: DoDNetworkInformationCenterLogo,
  5843: DoDNetworkInformationCenterLogo,
  5844: DoDNetworkInformationCenterLogo,
  5845: DoDNetworkInformationCenterLogo,
  5846: DoDNetworkInformationCenterLogo,
  5847: DoDNetworkInformationCenterLogo,
  5848: DoDNetworkInformationCenterLogo,
  5849: DoDNetworkInformationCenterLogo,
  5850: DoDNetworkInformationCenterLogo,
  5851: DoDNetworkInformationCenterLogo,
  5852: DoDNetworkInformationCenterLogo,
  5853: DoDNetworkInformationCenterLogo,
  5854: DoDNetworkInformationCenterLogo,
  5855: DoDNetworkInformationCenterLogo,
  5856: DoDNetworkInformationCenterLogo,
  5857: DoDNetworkInformationCenterLogo,
  5858: DoDNetworkInformationCenterLogo,
  5859: DoDNetworkInformationCenterLogo,
  5860: DoDNetworkInformationCenterLogo,
  5861: DoDNetworkInformationCenterLogo,
  5862: DoDNetworkInformationCenterLogo,
  5863: DoDNetworkInformationCenterLogo,
  5864: DoDNetworkInformationCenterLogo,
  5865: DoDNetworkInformationCenterLogo,
  5866: DoDNetworkInformationCenterLogo,
  5867: DoDNetworkInformationCenterLogo,
  5868: DoDNetworkInformationCenterLogo,
  5869: DoDNetworkInformationCenterLogo,
  5870: DoDNetworkInformationCenterLogo,
  5871: DoDNetworkInformationCenterLogo,
  5872: DoDNetworkInformationCenterLogo,
  5873: DoDNetworkInformationCenterLogo,
  5874: DoDNetworkInformationCenterLogo,
  5875: DoDNetworkInformationCenterLogo,
  5876: DoDNetworkInformationCenterLogo,
  5877: DoDNetworkInformationCenterLogo,
  5878: DoDNetworkInformationCenterLogo,
  5879: DoDNetworkInformationCenterLogo,
  5880: DoDNetworkInformationCenterLogo,
  5881: DoDNetworkInformationCenterLogo,
  5882: DoDNetworkInformationCenterLogo,
  5883: DoDNetworkInformationCenterLogo,
  5884: DoDNetworkInformationCenterLogo,
  5885: DoDNetworkInformationCenterLogo,
  5886: DoDNetworkInformationCenterLogo,
  5887: DoDNetworkInformationCenterLogo,
  5888: DoDNetworkInformationCenterLogo,
  5889: DoDNetworkInformationCenterLogo,
  5890: DoDNetworkInformationCenterLogo,
  5891: DoDNetworkInformationCenterLogo,
  5892: DoDNetworkInformationCenterLogo,
  5893: DoDNetworkInformationCenterLogo,
  5894: DoDNetworkInformationCenterLogo,
  5895: DoDNetworkInformationCenterLogo,
  5896: DoDNetworkInformationCenterLogo,
  5897: DoDNetworkInformationCenterLogo,
  5898: DoDNetworkInformationCenterLogo,
  5899: DoDNetworkInformationCenterLogo,
  5900: DoDNetworkInformationCenterLogo,
  5901: DoDNetworkInformationCenterLogo,
  5902: DoDNetworkInformationCenterLogo,
  5903: DoDNetworkInformationCenterLogo,
  5904: DoDNetworkInformationCenterLogo,
  5905: DoDNetworkInformationCenterLogo,
  5906: DoDNetworkInformationCenterLogo,
  5907: DoDNetworkInformationCenterLogo,
  5908: DoDNetworkInformationCenterLogo,
  5909: DoDNetworkInformationCenterLogo,
  5910: DoDNetworkInformationCenterLogo,
  5911: DoDNetworkInformationCenterLogo,
  5912: DoDNetworkInformationCenterLogo,
  5913: DoDNetworkInformationCenterLogo,
  5914: DoDNetworkInformationCenterLogo,
  5915: DoDNetworkInformationCenterLogo,
  5916: DoDNetworkInformationCenterLogo,
  5917: DoDNetworkInformationCenterLogo,
  5918: DoDNetworkInformationCenterLogo,
  5919: DoDNetworkInformationCenterLogo,
  5920: DoDNetworkInformationCenterLogo,
  5921: DoDNetworkInformationCenterLogo,
  5922: DoDNetworkInformationCenterLogo,
  5923: DoDNetworkInformationCenterLogo,
  5924: DoDNetworkInformationCenterLogo,
  5925: DoDNetworkInformationCenterLogo,
  5926: DoDNetworkInformationCenterLogo,
  5927: DoDNetworkInformationCenterLogo,
  5928: DoDNetworkInformationCenterLogo,
  5929: DoDNetworkInformationCenterLogo,
  5930: DoDNetworkInformationCenterLogo,
  5931: DoDNetworkInformationCenterLogo,
  5932: DoDNetworkInformationCenterLogo,
  5933: DoDNetworkInformationCenterLogo,
  5934: DoDNetworkInformationCenterLogo,
  5935: DoDNetworkInformationCenterLogo,
  5936: DoDNetworkInformationCenterLogo,
  5937: DoDNetworkInformationCenterLogo,
  5938: DoDNetworkInformationCenterLogo,
  5939: DoDNetworkInformationCenterLogo,
  5940: DoDNetworkInformationCenterLogo,
  5941: DoDNetworkInformationCenterLogo,
  5942: DoDNetworkInformationCenterLogo,
  5943: DoDNetworkInformationCenterLogo,
  5944: DoDNetworkInformationCenterLogo,
  5945: DoDNetworkInformationCenterLogo,
  5946: DoDNetworkInformationCenterLogo,
  5947: DoDNetworkInformationCenterLogo,
  5948: DoDNetworkInformationCenterLogo,
  5949: DoDNetworkInformationCenterLogo,
  5950: DoDNetworkInformationCenterLogo,
  5951: DoDNetworkInformationCenterLogo,
  5952: DoDNetworkInformationCenterLogo,
  5953: DoDNetworkInformationCenterLogo,
  5954: DoDNetworkInformationCenterLogo,
  5955: DoDNetworkInformationCenterLogo,
  5956: DoDNetworkInformationCenterLogo,
  5957: DoDNetworkInformationCenterLogo,
  5958: DoDNetworkInformationCenterLogo,
  5959: DoDNetworkInformationCenterLogo,
  5960: DoDNetworkInformationCenterLogo,
  5961: DoDNetworkInformationCenterLogo,
  5962: DoDNetworkInformationCenterLogo,
  5963: DoDNetworkInformationCenterLogo,
  5964: DoDNetworkInformationCenterLogo,
  5965: DoDNetworkInformationCenterLogo,
  5966: DoDNetworkInformationCenterLogo,
  5967: DoDNetworkInformationCenterLogo,
  5968: DoDNetworkInformationCenterLogo,
  5969: DoDNetworkInformationCenterLogo,
  5970: DoDNetworkInformationCenterLogo,
  5971: DoDNetworkInformationCenterLogo,
  5972: DoDNetworkInformationCenterLogo,
  5973: DoDNetworkInformationCenterLogo,
  5974: DoDNetworkInformationCenterLogo,
  5975: DoDNetworkInformationCenterLogo,
  5976: DoDNetworkInformationCenterLogo,
  5977: DoDNetworkInformationCenterLogo,
  5978: DoDNetworkInformationCenterLogo,
  5979: DoDNetworkInformationCenterLogo,
  5980: DoDNetworkInformationCenterLogo,
  5981: DoDNetworkInformationCenterLogo,
  5982: DoDNetworkInformationCenterLogo,
  5983: DoDNetworkInformationCenterLogo,
  5984: DoDNetworkInformationCenterLogo,
  5985: DoDNetworkInformationCenterLogo,
  5986: DoDNetworkInformationCenterLogo,
  5987: DoDNetworkInformationCenterLogo,
  5988: DoDNetworkInformationCenterLogo,
  5989: DoDNetworkInformationCenterLogo,
  5990: DoDNetworkInformationCenterLogo,
  5991: DoDNetworkInformationCenterLogo,
  5992: DoDNetworkInformationCenterLogo,
  5993: DoDNetworkInformationCenterLogo,
  5994: DoDNetworkInformationCenterLogo,
  5995: DoDNetworkInformationCenterLogo,
  5996: DoDNetworkInformationCenterLogo,
  5997: DoDNetworkInformationCenterLogo,
  5998: DoDNetworkInformationCenterLogo,
  5999: DoDNetworkInformationCenterLogo,
  6000: DoDNetworkInformationCenterLogo,
  6001: DoDNetworkInformationCenterLogo,
  6002: DoDNetworkInformationCenterLogo,
  6003: DoDNetworkInformationCenterLogo,
  6004: DoDNetworkInformationCenterLogo,
  6005: DoDNetworkInformationCenterLogo,
  6006: DoDNetworkInformationCenterLogo,
  6007: DoDNetworkInformationCenterLogo,
  6008: DoDNetworkInformationCenterLogo,
  6009: DoDNetworkInformationCenterLogo,
  6010: DoDNetworkInformationCenterLogo,
  6011: DoDNetworkInformationCenterLogo,
  6012: DoDNetworkInformationCenterLogo,
  6013: DoDNetworkInformationCenterLogo,
  6014: DoDNetworkInformationCenterLogo,
  6015: DoDNetworkInformationCenterLogo,
  6016: DoDNetworkInformationCenterLogo,
  6017: DoDNetworkInformationCenterLogo,
  6018: DoDNetworkInformationCenterLogo,
  6019: DoDNetworkInformationCenterLogo,
  6020: DoDNetworkInformationCenterLogo,
  6021: DoDNetworkInformationCenterLogo,
  6022: DoDNetworkInformationCenterLogo,
  6023: DoDNetworkInformationCenterLogo,
  6024: DoDNetworkInformationCenterLogo,
  6025: DoDNetworkInformationCenterLogo,
  6026: DoDNetworkInformationCenterLogo,
  6027: DoDNetworkInformationCenterLogo,
  6028: DoDNetworkInformationCenterLogo,
  6029: DoDNetworkInformationCenterLogo,
  6030: DoDNetworkInformationCenterLogo,
  6031: DoDNetworkInformationCenterLogo,
  6032: DoDNetworkInformationCenterLogo,
  6033: DoDNetworkInformationCenterLogo,
  6034: DoDNetworkInformationCenterLogo,
  6035: DoDNetworkInformationCenterLogo,
  6036: DoDNetworkInformationCenterLogo,
  6037: DoDNetworkInformationCenterLogo,
  6038: DoDNetworkInformationCenterLogo,
  6039: DoDNetworkInformationCenterLogo,
  6040: DoDNetworkInformationCenterLogo,
  6041: DoDNetworkInformationCenterLogo,
  6042: DoDNetworkInformationCenterLogo,
  6043: DoDNetworkInformationCenterLogo,
  6044: DoDNetworkInformationCenterLogo,
  6045: DoDNetworkInformationCenterLogo,
  6046: DoDNetworkInformationCenterLogo,
  6047: DoDNetworkInformationCenterLogo,
  6048: DoDNetworkInformationCenterLogo,
  6049: DoDNetworkInformationCenterLogo,
  6050: DoDNetworkInformationCenterLogo,
  6051: DoDNetworkInformationCenterLogo,
  6052: DoDNetworkInformationCenterLogo,
  6053: DoDNetworkInformationCenterLogo,
  6054: DoDNetworkInformationCenterLogo,
  6055: DoDNetworkInformationCenterLogo,
  6066: VerizonLogo,
  6157: SprintLogo,
  6161: ComcastLogo,
  6167: VerizonLogo,
  6259: CogentLogo,
  6270: CogentLogo,
  6271: CogentLogo,
  6272: CogentLogo,
  6273: CogentLogo,
  6274: CogentLogo,
  6275: CogentLogo,
  6276: CogentLogo,
  6277: CogentLogo,
  6299: CogentLogo,
  6327: SHAWLogo,
  6389: ATTLogo,
  6395: LumenLogo,
  6421: TATACommunicationsLogo,
  6427: HurricaneElectricLogo,
  6431: ATTLogo,
  6432: GoogleLogo,
  6453: TATACommunicationsLogo,
  6461: ZayoLogo,
  6484: LumenLogo,
  6494: CogentLogo,
  6496: CogentLogo,
  6660: VodafoneLogo,
  6675: ColttechnologyservicesLogo,
  6682: RostelecomLogo,
  6713: MarocTelecomLogo,
  6728: TalkTalkCommunicationsLogo,
  6731: MTSLogo,
  6739: VodafoneLogo,
  6762: TelecomItaliaSparkleLogo,
  6774: BelgacomBICSLogo,
  6808: GTTCommunicationsLogo,
  6810: BezeqLogo,
  6814: GTTCommunicationsLogo,
  6830: LibertyGlobalLogo,
  6847: VodafoneLogo,
  6850: MegaFonLogo,
  6853: OrangeLogo,
  6854: MegaFonLogo,
  6863: RostelecomLogo,
  6871: BritishTelecommunicationsLogo,
  6939: HurricaneElectricLogo,
  6944: NTTAmericaLogo,
  7015: ComcastLogo,
  7016: ComcastLogo,
  7018: ATTLogo,
  7019: NTTAmericaLogo,
  7029: WindstreamLogo,
  7061: CogentLogo,
  7132: ATTLogo,
  7176: LumenLogo,
  7195: EdgeUnoLogo,
  7224: AmazonLogo,
  7262: PCCWGlobalLogo,
  7287: ATTLogo,
  7303: TelecomArgentinaLogo,
  7458: CogentLogo,
  7473: SingtelLogo,
  7474: SingtelLogo,
  7545: TPGTelecomLogo,
  7552: ViettelLogo,
  7589: ArelionLogo,
  7643: VNPTLogo,
  7657: VodafoneLogo,
  7714: VodafoneLogo,
  7725: ComcastLogo,
  7738: OiLogo,
  7757: ComcastLogo,
  7843: CharterCommunicationsLogo,
  7853: ComcastLogo,
  7891: ATTLogo,
  7892: ATTLogo,
  7893: ATTLogo,
  7894: ATTLogo,
  7908: BritishTelecommunicationsLogo,
  7911: LumenLogo,
  7922: ComcastLogo,
  8043: LumenLogo,
  8151: TelmexLogo,
  8167: OiLogo,
  8190: GTTCommunicationsLogo,
  8213: Tele2Logo,
  8218: ZayoLogo,
  8220: ColttechnologyservicesLogo,
  8235: EquinixLogo,
  8244: TelecomItaliaSparkleLogo,
  8249: RostelecomLogo,
  8263: MegaFonLogo,
  8297: TATACommunicationsLogo,
  8331: ERTelecomLogo,
  8338: OrangeLogo,
  8350: VeonLogo,
  8359: MTSLogo,
  8371: VeonLogo,
  8372: ColttechnologyservicesLogo,
  8382: RostelecomLogo,
  8386: VodafoneLogo,
  8402: VeonLogo,
  8418: OrangeLogo,
  8427: TranstelecomLogo,
  8443: RostelecomLogo,
  8447: A1TelekomLogo,
  8452: TelecomEgyptLogo,
  8455: Atom86Logo,
  8485: TranstelecomLogo,
  8545: EquinixLogo,
  8551: BezeqLogo,
  8557: RostelecomLogo,
  8562: A1TelekomLogo,
  8563: VeonLogo,
  8568: RostelecomLogo,
  8570: RostelecomLogo,
  8576: VodafoneLogo,
  8580: MTSLogo,
  8582: GTTCommunicationsLogo,
  8586: TalkTalkCommunicationsLogo,
  8591: A1TelekomLogo,
  8624: EquinixLogo,
  8672: A1TelekomLogo,
  8675: RostelecomLogo,
  8717: A1TelekomLogo,
  8755: VeonLogo,
  8764: TeliaLogo,
  8773: VeonLogo,
  8781: OoredooLogo,
  8828: RostelecomLogo,
  8837: TeliaLogo,
  8891: OrangeLogo,
  8923: OrangeLogo,
  8953: OrangeLogo,
  8959: EtisalatLogo,
  8960: EtisalatLogo,
  8966: EtisalatLogo,
  8968: BritishTelecommunicationsLogo,
  8987: AmazonLogo,
  8996: Tele2Logo,
  8997: RostelecomLogo,
  9002: RETNLogo,
  9045: Tele2Logo,
  9046: Tele2Logo,
  9047: Tele2Logo,
  9049: ERTelecomLogo,
  9057: LumenLogo,
  9105: TalkTalkCommunicationsLogo,
  9116: PartnerCommunicationsLogo,
  9121: TurkTelekomLogo,
  9126: ColttechnologyservicesLogo,
  9152: OrangeLogo,
  9231: ChinaMobileLogo,
  9237: PCCWGlobalLogo,
  9238: TATACommunicationsLogo,
  9277: SKBroadbandLogo,
  9299: PLDTLogo,
  9304: HGCGlobalLogo,
  9318: SKBroadbandLogo,
  9327: VodafoneLogo,
  9345: VodafoneLogo,
  9394: ChinaMobileLogo,
  9402: CernetLogo,
  9498: BhartiAirtelLogo,
  9500: VodafoneLogo,
  9505: ChunghwaTelecomLogo,
  9506: SingtelLogo,
  9555: VerizonLogo,
  9559: VodafoneLogo,
  9680: ChunghwaTelecomLogo,
  9689: SKBroadbandLogo,
  9756: SKBroadbandLogo,
  9760: KoreaTelecomLogo,
  9768: KoreaTelecomLogo,
  9800: ChinaUnicomLogo,
  9808: ChinaMobileLogo,
  9824: JupiterTelecommunicationsLogo,
  9874: StarhubLogo,
  9896: VodafoneLogo,
  9901: VodafoneLogo,
  9929: ChinaUnicomLogo,
  9964: KoreaTelecomLogo,
  9971: SKBroadbandLogo,
  9989: EquinixLogo,
  10099: ChinaUnicomLogo,
  10124: AmazonLogo,
  10164: SKBroadbandLogo,
  10199: TATACommunicationsLogo,
  10206: ChinaUnicomLogo,
  10282: OrangeLogo,
  10429: TelefonicaLogo,
  10753: LumenLogo,
  10768: CogentLogo,
  11024: CogentLogo,
  11025: ComcastLogo,
  11158: NTTAmericaLogo,
  11164: Internet2Logo,
  11213: LumenLogo,
  11220: CogentLogo,
  11359: ZayoLogo,
  11526: CogentLogo,
  11537: Internet2Logo,
  11992: ATTLogo,
  12085: EquinixLogo,
  12155: EquinixLogo,
  12207: CogentLogo,
  12222: AkamaiLogo,
  12302: VodafoneLogo,
  12332: RostelecomLogo,
  12353: VodafoneLogo,
  12357: VodafoneLogo,
  12361: VodafoneLogo,
  12380: RostelecomLogo,
  12386: OrangeLogo,
  12389: RostelecomLogo,
  12396: MegaFonLogo,
  12400: PartnerCommunicationsLogo,
  12430: VodafoneLogo,
  12479: OrangeLogo,
  12493: OrangeLogo,
  12543: VeonLogo,
  12576: BritishTelecommunicationsLogo,
  12582: TeliaLogo,
  12600: EquinixLogo,
  12640: ColttechnologyservicesLogo,
  12663: VodafoneLogo,
  12683: RostelecomLogo,
  12688: TranstelecomLogo,
  12708: TalkTalkCommunicationsLogo,
  12715: OrangeLogo,
  12716: A1TelekomLogo,
  12730: RostelecomLogo,
  12743: OrangeLogo,
  12755: ColttechnologyservicesLogo,
  12761: ColttechnologyservicesLogo,
  12768: ERTelecomLogo,
  12772: ERTelecomLogo,
  12793: A1TelekomLogo,
  12807: OrangeLogo,
  12810: A1TelekomLogo,
  12846: RostelecomLogo,
  12850: IrideosLogo,
  12878: ColttechnologyservicesLogo,
  12929: TeliaLogo,
  12956: TelxiusLogo,
  12969: VodafoneLogo,
  13017: VodafoneLogo,
  13056: RostelecomLogo,
  13062: MTSLogo,
  13083: VodafoneLogo,
  13095: VeonLogo,
  13116: Tele2Logo,
  13118: RostelecomLogo,
  13124: A1TelekomLogo,
  13129: CogentLogo,
  13144: VodafoneLogo,
  13155: MTSLogo,
  13174: MTSLogo,
  13205: BritishTelecommunicationsLogo,
  13257: VeonLogo,
  13285: TalkTalkCommunicationsLogo,
  13335: cloudflareLogo,
  13367: ComcastLogo,
  13385: ComcastLogo,
  13436: Internet2Logo,
  13531: EquinixLogo,
  13555: ZayoLogo,
  13786: SeabornLogo,
  13879: OrangeLogo,
  14153: EdgecastLogo,
  14187: BritishTelecommunicationsLogo,
  14210: EdgecastLogo,
  14609: EquinixLogo,
  14618: AmazonLogo,
  14668: ComcastLogo,
  14789: cloudflareLogo,
  15133: EdgecastLogo,
  15169: GoogleLogo,
  15404: ColttechnologyservicesLogo,
  15412: GlobalCloudxChangeLogo,
  15468: RostelecomLogo,
  15480: VodafoneLogo,
  15502: VodafoneLogo,
  15589: IrideosLogo,
  15640: MTSLogo,
  15734: EquinixLogo,
  15759: RostelecomLogo,
  15824: A1TelekomLogo,
  15830: EquinixLogo,
  15897: VodafoneLogo,
  15924: VodafoneLogo,
  15934: RostelecomLogo,
  15962: OrangeLogo,
  15974: TranstelecomLogo,
  15994: A1TelekomLogo,
  16012: MTSLogo,
  16019: VodafoneLogo,
  16028: OrangeLogo,
  16043: VeonLogo,
  16054: RostelecomLogo,
  16179: ERTelecomLogo,
  16243: EquinixLogo,
  16256: MTSLogo,
  16260: GTTCommunicationsLogo,
  16285: TranstelecomLogo,
  16287: RostelecomLogo,
  16301: RostelecomLogo,
  16305: A1TelekomLogo,
  16338: VodafoneLogo,
  16345: VeonLogo,
  16372: OrangeLogo,
  16375: AlgarTelecomLogo,
  16397: EquinixLogo,
  16503: ZayoLogo,
  16509: AmazonLogo,
  16550: GoogleLogo,
  16553: EquinixLogo,
  16591: GoogleLogo,
  16625: AkamaiLogo,
  16631: CogentLogo,
  16702: AkamaiLogo,
  16735: AlgarTelecomLogo,
  16748: ComcastLogo,
  16852: LumenLogo,
  17025: ZayoLogo,
  17207: AkamaiLogo,
  17224: ATTLogo,
  17225: ATTLogo,
  17226: ATTLogo,
  17227: ATTLogo,
  17228: ATTLogo,
  17229: ATTLogo,
  17230: ATTLogo,
  17231: ATTLogo,
  17232: ATTLogo,
  17233: ATTLogo,
  17334: AkamaiLogo,
  17435: VodafoneLogo,
  17493: AmazonLogo,
  17558: OrangeLogo,
  17573: SKBroadbandLogo,
  17597: SKBroadbandLogo,
  17621: ChinaUnicomLogo,
  17622: ChinaUnicomLogo,
  17623: ChinaUnicomLogo,
  17629: ChinaTelecomLogo,
  17633: ChinaTelecomLogo,
  17638: ChinaTelecomLogo,
  17649: VodafoneLogo,
  17672: ChinaTelecomLogo,
  17676: SoftbankLogo,
  17785: ChinaTelecomLogo,
  17796: PLDTLogo,
  17799: ChinaTelecomLogo,
  17808: VodafoneLogo,
  17816: ChinaUnicomLogo,
  17819: EquinixLogo,
  17846: SKBroadbandLogo,
  17849: SKBroadbandLogo,
  17854: SKBroadbandLogo,
  17857: SKBroadbandLogo,
  17858: LGUplusLogo,
  17864: SKBroadbandLogo,
  17871: SKBroadbandLogo,
  17883: ChinaTelecomLogo,
  17896: ChinaTelecomLogo,
  17897: ChinaTelecomLogo,
  17908: TATACommunicationsLogo,
  17923: ChinaTelecomLogo,
  17941: EquinixLogo,
  17984: PCCWGlobalLogo,
  17993: VodafoneLogo,
  17998: ChinaTelecomLogo,
  17999: TPGTelecomLogo,
  18291: VodafoneLogo,
  18310: SKBroadbandLogo,
  18680: AkamaiLogo,
  18717: AkamaiLogo,
  18756: LumenLogo,
  18826: ATTLogo,
  18881: TelefonicaLogo,
  19025: VerizonLogo,
  19026: VerizonLogo,
  19027: VerizonLogo,
  19028: VerizonLogo,
  19047: AmazonLogo,
  19092: ZayoLogo,
  19094: LumenLogo,
  19158: ZayoLogo,
  19164: CogentLogo,
  19448: GoogleLogo,
  19483: ATTLogo,
  19484: ATTLogo,
  19485: ATTLogo,
  19486: ATTLogo,
  19487: ATTLogo,
  19488: ATTLogo,
  19489: ATTLogo,
  19490: ATTLogo,
  19491: ATTLogo,
  19492: ATTLogo,
  19493: ATTLogo,
  19494: ATTLogo,
  19495: ATTLogo,
  19496: ATTLogo,
  19497: ATTLogo,
  19498: ATTLogo,
  19527: GoogleLogo,
  19557: ATTLogo,
  19591: LumenLogo,
  19930: EquinixLogo,
  19962: LumenLogo,
  19965: ATTLogo,
  20057: ATTLogo,
  20110: NTTAmericaLogo,
  20115: SpectrumLogo,
  20189: AkamaiLogo,
  20214: ComcastLogo,
  20476: LumenLogo,
  20485: TranstelecomLogo,
  20519: RostelecomLogo,
  20597: VeonLogo,
  20603: OrangeLogo,
  20632: MegaFonLogo,
  20663: MegaFonLogo,
  20730: ERTelecomLogo,
  20764: RascomLogo,
  20825: VodafoneLogo,
  20838: OrangeLogo,
  20852: A1TelekomLogo,
  20870: TranstelecomLogo,
  20921: OrangeLogo,
  20940: AkamaiLogo,
  20965: GeantLogo,
  21017: RostelecomLogo,
  21103: MTSLogo,
  21127: TranstelecomLogo,
  21183: VodafoneLogo,
  21191: TranstelecomLogo,
  21268: VodafoneLogo,
  21283: A1TelekomLogo,
  21311: ERTelecomLogo,
  21332: VeonLogo,
  21334: VodafoneLogo,
  21342: AkamaiLogo,
  21353: ERTelecomLogo,
  21357: AkamaiLogo,
  21365: MTSLogo,
  21371: EquinixLogo,
  21378: RostelecomLogo,
  21395: OrangeLogo,
  21399: AkamaiLogo,
  21435: VodafoneLogo,
  21438: TranstelecomLogo,
  21442: ERTelecomLogo,
  21479: RostelecomLogo,
  21480: VeonLogo,
  21483: VeonLogo,
  21487: RostelecomLogo,
  21497: VodafoneLogo,
  21508: ComcastLogo,
  21625: VodafoneLogo,
  22085: ClaroLogo,
  22099: CogentLogo,
  22207: AkamaiLogo,
  22258: ComcastLogo,
  22394: VerizonLogo,
  22452: AkamaiLogo,
  22561: CenturyLinkLogo,
  22773: CoxCommunicationsLogo,
  22822: LimelightLogo,
  22909: ComcastLogo,
  22969: ZayoLogo,
  23059: LimelightLogo,
  23135: LimelightLogo,
  23164: LimelightLogo,
  23253: ComcastLogo,
  23266: ComcastLogo,
  23454: AkamaiLogo,
  23455: AkamaiLogo,
  23487: ClaroLogo,
  23520: ColumbusNetworksLogo,
  23563: SKBroadbandLogo,
  23575: SKBroadbandLogo,
  23577: KoreaTelecomLogo,
  23578: SKBroadbandLogo,
  23637: EquinixLogo,
  23650: ChinaTelecomLogo,
  23686: EquinixLogo,
  23724: ChinaTelecomLogo,
  23903: AkamaiLogo,
  24059: ChinaMobileLogo,
  24086: ViettelLogo,
  24115: EquinixLogo,
  24319: AkamaiLogo,
  24400: ChinaMobileLogo,
  24424: GoogleLogo,
  24429: AlibabaLogo,
  24444: ChinaMobileLogo,
  24445: ChinaMobileLogo,
  24458: PLDTLogo,
  24547: ChinaMobileLogo,
  24560: BhartiAirtelLogo,
  24588: ERTelecomLogo,
  24600: OrangeLogo,
  24626: TranstelecomLogo,
  24654: OrangeLogo,
  24665: RostelecomLogo,
  24699: RostelecomLogo,
  24767: MegaFonLogo,
  24783: RostelecomLogo,
  24789: RostelecomLogo,
  24810: RostelecomLogo,
  24835: VodafoneLogo,
  24866: MegaFonLogo,
  24873: RostelecomLogo,
  24923: TranstelecomLogo,
  24964: A1TelekomLogo,
  24989: EquinixLogo,
  24990: EquinixLogo,
  25019: SaudiTelecomLogo,
  25086: MTSLogo,
  25135: VodafoneLogo,
  25159: MegaFonLogo,
  25186: OrangeLogo,
  25290: MegaFonLogo,
  25307: EquinixLogo,
  25310: VodafoneLogo,
  25346: TalkTalkCommunicationsLogo,
  25400: TeliaLogo,
  25436: RostelecomLogo,
  25446: ERTelecomLogo,
  25454: OrangeLogo,
  25490: RostelecomLogo,
  25515: RostelecomLogo,
  25531: RostelecomLogo,
  25804: LimelightLogo,
  25993: ATTLogo,
  26008: AkamaiLogo,
  26506: LimelightLogo,
  26592: EquinixLogo,
  26599: TelefonicaLogo,
  26615: TIMCellularLogo,
  26641: VodafoneLogo,
  26684: GoogleLogo,
  26910: GoogleLogo,
  27032: DoDNetworkInformationCenterLogo,
  27033: DoDNetworkInformationCenterLogo,
  27034: DoDNetworkInformationCenterLogo,
  27035: DoDNetworkInformationCenterLogo,
  27036: DoDNetworkInformationCenterLogo,
  27037: DoDNetworkInformationCenterLogo,
  27038: DoDNetworkInformationCenterLogo,
  27039: DoDNetworkInformationCenterLogo,
  27040: DoDNetworkInformationCenterLogo,
  27041: DoDNetworkInformationCenterLogo,
  27042: DoDNetworkInformationCenterLogo,
  27043: DoDNetworkInformationCenterLogo,
  27044: DoDNetworkInformationCenterLogo,
  27045: DoDNetworkInformationCenterLogo,
  27046: DoDNetworkInformationCenterLogo,
  27047: DoDNetworkInformationCenterLogo,
  27048: DoDNetworkInformationCenterLogo,
  27049: DoDNetworkInformationCenterLogo,
  27050: DoDNetworkInformationCenterLogo,
  27051: DoDNetworkInformationCenterLogo,
  27052: DoDNetworkInformationCenterLogo,
  27053: DoDNetworkInformationCenterLogo,
  27054: DoDNetworkInformationCenterLogo,
  27055: DoDNetworkInformationCenterLogo,
  27056: DoDNetworkInformationCenterLogo,
  27057: DoDNetworkInformationCenterLogo,
  27058: DoDNetworkInformationCenterLogo,
  27059: DoDNetworkInformationCenterLogo,
  27060: DoDNetworkInformationCenterLogo,
  27061: DoDNetworkInformationCenterLogo,
  27062: DoDNetworkInformationCenterLogo,
  27063: DoDNetworkInformationCenterLogo,
  27064: DoDNetworkInformationCenterLogo,
  27065: DoDNetworkInformationCenterLogo,
  27066: DoDNetworkInformationCenterLogo,
  27067: DoDNetworkInformationCenterLogo,
  27068: DoDNetworkInformationCenterLogo,
  27069: DoDNetworkInformationCenterLogo,
  27070: DoDNetworkInformationCenterLogo,
  27071: DoDNetworkInformationCenterLogo,
  27072: DoDNetworkInformationCenterLogo,
  27073: DoDNetworkInformationCenterLogo,
  27074: DoDNetworkInformationCenterLogo,
  27075: DoDNetworkInformationCenterLogo,
  27076: DoDNetworkInformationCenterLogo,
  27077: DoDNetworkInformationCenterLogo,
  27078: DoDNetworkInformationCenterLogo,
  27079: DoDNetworkInformationCenterLogo,
  27080: DoDNetworkInformationCenterLogo,
  27081: DoDNetworkInformationCenterLogo,
  27082: DoDNetworkInformationCenterLogo,
  27083: DoDNetworkInformationCenterLogo,
  27084: DoDNetworkInformationCenterLogo,
  27085: DoDNetworkInformationCenterLogo,
  27086: DoDNetworkInformationCenterLogo,
  27087: DoDNetworkInformationCenterLogo,
  27088: DoDNetworkInformationCenterLogo,
  27089: DoDNetworkInformationCenterLogo,
  27090: DoDNetworkInformationCenterLogo,
  27091: DoDNetworkInformationCenterLogo,
  27092: DoDNetworkInformationCenterLogo,
  27093: DoDNetworkInformationCenterLogo,
  27094: DoDNetworkInformationCenterLogo,
  27095: DoDNetworkInformationCenterLogo,
  27096: DoDNetworkInformationCenterLogo,
  27097: DoDNetworkInformationCenterLogo,
  27098: DoDNetworkInformationCenterLogo,
  27099: DoDNetworkInformationCenterLogo,
  27100: DoDNetworkInformationCenterLogo,
  27101: DoDNetworkInformationCenterLogo,
  27102: DoDNetworkInformationCenterLogo,
  27103: DoDNetworkInformationCenterLogo,
  27104: DoDNetworkInformationCenterLogo,
  27105: DoDNetworkInformationCenterLogo,
  27106: DoDNetworkInformationCenterLogo,
  27107: DoDNetworkInformationCenterLogo,
  27108: DoDNetworkInformationCenterLogo,
  27109: DoDNetworkInformationCenterLogo,
  27110: DoDNetworkInformationCenterLogo,
  27111: DoDNetworkInformationCenterLogo,
  27112: DoDNetworkInformationCenterLogo,
  27113: DoDNetworkInformationCenterLogo,
  27114: DoDNetworkInformationCenterLogo,
  27115: DoDNetworkInformationCenterLogo,
  27116: DoDNetworkInformationCenterLogo,
  27117: DoDNetworkInformationCenterLogo,
  27118: DoDNetworkInformationCenterLogo,
  27119: DoDNetworkInformationCenterLogo,
  27120: DoDNetworkInformationCenterLogo,
  27121: DoDNetworkInformationCenterLogo,
  27122: DoDNetworkInformationCenterLogo,
  27123: DoDNetworkInformationCenterLogo,
  27124: DoDNetworkInformationCenterLogo,
  27125: DoDNetworkInformationCenterLogo,
  27126: DoDNetworkInformationCenterLogo,
  27127: DoDNetworkInformationCenterLogo,
  27128: DoDNetworkInformationCenterLogo,
  27129: DoDNetworkInformationCenterLogo,
  27130: DoDNetworkInformationCenterLogo,
  27131: DoDNetworkInformationCenterLogo,
  27132: DoDNetworkInformationCenterLogo,
  27133: DoDNetworkInformationCenterLogo,
  27134: DoDNetworkInformationCenterLogo,
  27135: DoDNetworkInformationCenterLogo,
  27136: DoDNetworkInformationCenterLogo,
  27137: DoDNetworkInformationCenterLogo,
  27138: DoDNetworkInformationCenterLogo,
  27139: DoDNetworkInformationCenterLogo,
  27140: DoDNetworkInformationCenterLogo,
  27141: DoDNetworkInformationCenterLogo,
  27142: DoDNetworkInformationCenterLogo,
  27143: DoDNetworkInformationCenterLogo,
  27144: DoDNetworkInformationCenterLogo,
  27145: DoDNetworkInformationCenterLogo,
  27146: DoDNetworkInformationCenterLogo,
  27147: DoDNetworkInformationCenterLogo,
  27148: DoDNetworkInformationCenterLogo,
  27149: DoDNetworkInformationCenterLogo,
  27150: DoDNetworkInformationCenterLogo,
  27151: DoDNetworkInformationCenterLogo,
  27152: DoDNetworkInformationCenterLogo,
  27153: DoDNetworkInformationCenterLogo,
  27154: DoDNetworkInformationCenterLogo,
  27155: DoDNetworkInformationCenterLogo,
  27156: DoDNetworkInformationCenterLogo,
  27157: DoDNetworkInformationCenterLogo,
  27158: DoDNetworkInformationCenterLogo,
  27159: DoDNetworkInformationCenterLogo,
  27191: LimelightLogo,
  27224: EquinixLogo,
  27272: EquinixLogo,
  27330: EquinixLogo,
  27540: ZayoLogo,
  27566: EquinixLogo,
  27652: ClaroLogo,
  27699: TelefonicaLogo,
  27995: ClaroLogo,
  28469: ATTLogo,
  28513: TelmexLogo,
  28573: ClaroLogo,
  28703: VeonLogo,
  28704: ProximusLogo,
  28708: OrangeLogo,
  28744: EquinixLogo,
  28745: TranstelecomLogo,
  28769: TranstelecomLogo,
  28832: MTSLogo,
  28860: RostelecomLogo,
  28884: MTSLogo,
  28906: TranstelecomLogo,
  29069: RostelecomLogo,
  29125: VeonLogo,
  29154: EquinixLogo,
  29190: MTSLogo,
  29194: MTSLogo,
  29209: MTSLogo,
  29225: MTSLogo,
  29456: RostelecomLogo,
  29497: MTSLogo,
  29535: OrangeLogo,
  29562: VodafoneLogo,
  29571: OrangeLogo,
  29580: A1TelekomLogo,
  29648: MegaFonLogo,
  29884: EquinixLogo,
  30371: GTTCommunicationsLogo,
  30675: AkamaiLogo,
  30686: LumenLogo,
  30722: VodafoneLogo,
  30747: TranstelecomLogo,
  30749: RostelecomLogo,
  30751: MTSLogo,
  30868: ERTelecomLogo,
  30881: MTSLogo,
  30922: MTSLogo,
  30985: OrangeLogo,
  30995: VodafoneLogo,
  31036: ERTelecomLogo,
  31088: MegaFonLogo,
  31107: AkamaiLogo,
  31108: AkamaiLogo,
  31109: AkamaiLogo,
  31110: AkamaiLogo,
  31133: MegaFonLogo,
  31163: MegaFonLogo,
  31195: MegaFonLogo,
  31205: MegaFonLogo,
  31208: MegaFonLogo,
  31213: MegaFonLogo,
  31224: MegaFonLogo,
  31268: MegaFonLogo,
  31286: MTSLogo,
  31302: ERTelecomLogo,
  31334: VodafoneLogo,
  31338: MTSLogo,
  31359: VeonLogo,
  31363: ERTelecomLogo,
  31364: TranstelecomLogo,
  31377: AkamaiLogo,
  31408: OrangeLogo,
  31425: VeonLogo,
  31452: ZainLogo,
  31483: ERTelecomLogo,
  31487: TalkTalkCommunicationsLogo,
  31494: ERTelecomLogo,
  31496: RostelecomLogo,
  31501: TranstelecomLogo,
  31558: MTSLogo,
  31585: ERTelecomLogo,
  31654: VodafoneLogo,
  31692: ERTelecomLogo,
  31713: PCCWGlobalLogo,
  31932: ZayoLogo,
  31933: ZayoLogo,
  32323: EquinixLogo,
  32421: LumenLogo,
  32550: EquinixLogo,
  32787: AkamaiLogo,
  33047: AkamaiLogo,
  33287: ComcastLogo,
  33321: ZayoLogo,
  33351: ComcastLogo,
  33489: ComcastLogo,
  33490: ComcastLogo,
  33491: ComcastLogo,
  33542: ComcastLogo,
  33650: ComcastLogo,
  33651: ComcastLogo,
  33652: ComcastLogo,
  33653: ComcastLogo,
  33654: ComcastLogo,
  33655: ComcastLogo,
  33656: ComcastLogo,
  33657: ComcastLogo,
  33658: ComcastLogo,
  33659: ComcastLogo,
  33660: ComcastLogo,
  33661: ComcastLogo,
  33662: ComcastLogo,
  33663: ComcastLogo,
  33664: ComcastLogo,
  33665: ComcastLogo,
  33666: ComcastLogo,
  33667: ComcastLogo,
  33668: ComcastLogo,
  33894: MTSLogo,
  33900: OrangeLogo,
  33905: AkamaiLogo,
  33915: VodafoneLogo,
  33934: RostelecomLogo,
  34038: VeonLogo,
  34137: RostelecomLogo,
  34145: ERTelecomLogo,
  34150: ERTelecomLogo,
  34164: AkamaiLogo,
  34168: RostelecomLogo,
  34205: RostelecomLogo,
  34209: EquinixLogo,
  34267: RostelecomLogo,
  34351: MTSLogo,
  34508: MTSLogo,
  34533: ERTelecomLogo,
  34552: MegaFonLogo,
  34584: RostelecomLogo,
  34590: ERTelecomLogo,
  34747: VeonLogo,
  34769: OrangeLogo,
  34850: AkamaiLogo,
  34875: RostelecomLogo,
  34892: RostelecomLogo,
  34894: VeonLogo,
  34912: VodafoneLogo,
  34925: ERTelecomLogo,
  34974: RostelecomLogo,
  35054: EquinixLogo,
  35125: RostelecomLogo,
  35141: A1TelekomLogo,
  35154: RostelecomLogo,
  35177: RostelecomLogo,
  35204: AkamaiLogo,
  35210: OrangeLogo,
  35298: MegaFonLogo,
  35389: RostelecomLogo,
  35473: MTSLogo,
  35516: RostelecomLogo,
  35641: TranstelecomLogo,
  35728: MTSLogo,
  35993: AkamaiLogo,
  35994: AkamaiLogo,
  36031: EquinixLogo,
  36039: GoogleLogo,
  36040: GoogleLogo,
  36183: AkamaiLogo,
  36196: ComcastLogo,
  36263: AmazonLogo,
  36377: ComcastLogo,
  36384: GoogleLogo,
  36385: GoogleLogo,
  36492: GoogleLogo,
  36678: ChinaTelecomLogo,
  36732: ComcastLogo,
  36841: ZayoLogo,
  36912: OrangeLogo,
  36935: VodafoneLogo,
  36977: BhartiAirtelLogo,
  36987: GoogleLogo,
  36998: ZainLogo,
  37030: BhartiAirtelLogo,
  37037: OrangeLogo,
  37075: BhartiAirtelLogo,
  37094: OrangeLogo,
  37133: BhartiAirtelLogo,
  37164: OrangeLogo,
  37173: OrangeLogo,
  37218: DoDNetworkInformationCenterLogo,
  37233: OrangeLogo,
  37277: LimelightLogo,
  37303: BhartiAirtelLogo,
  37343: BhartiAirtelLogo,
  37376: ZainLogo,
  37440: BhartiAirtelLogo,
  37447: OrangeLogo,
  37460: OrangeLogo,
  37461: OrangeLogo,
  37468: AngolaCablesLogo,
  37492: OrangeLogo,
  37531: BhartiAirtelLogo,
  37550: BhartiAirtelLogo,
  37559: OrangeLogo,
  37577: OrangeLogo,
  37616: BhartiAirtelLogo,
  37693: OoredooLogo,
  37963: AlibabaLogo,
  37978: TelstraGlobalLogo,
  38019: ChinaMobileLogo,
  38095: SKBroadbandLogo,
  38097: SKBroadbandLogo,
  38103: SKBroadbandLogo,
  38109: SKBroadbandLogo,
  38266: VodafoneLogo,
  38283: ChinaTelecomLogo,
  38369: AlibabaLogo,
  38370: ChinaMobileLogo,
  38442: VodafoneLogo,
  38621: LimelightLogo,
  38622: LimelightLogo,
  38623: ViettelLogo,
  38748: VodafoneLogo,
  38861: StarhubLogo,
  38895: AmazonLogo,
  38951: RostelecomLogo,
  38985: RostelecomLogo,
  38996: MTSLogo,
  39001: MTSLogo,
  39028: ERTelecomLogo,
  39301: Tele2Logo,
  39386: SaudiTelecomLogo,
  39407: RostelecomLogo,
  39435: ERTelecomLogo,
  39660: TranstelecomLogo,
  39757: EquinixLogo,
  39799: MTSLogo,
  39811: MTSLogo,
  39836: AkamaiLogo,
  39858: MTSLogo,
  39891: SaudiTelecomLogo,
  40993: MTSLogo,
  41002: ERTelecomLogo,
  41128: OrangeLogo,
  41134: RostelecomLogo,
  41145: MTSLogo,
  41209: MTSLogo,
  41264: GoogleLogo,
  41279: MTSLogo,
  41341: TranstelecomLogo,
  41403: ERTelecomLogo,
  41426: SaudiTelecomLogo,
  41661: ERTelecomLogo,
  41668: ERTelecomLogo,
  41682: ERTelecomLogo,
  41691: RostelecomLogo,
  41727: ERTelecomLogo,
  41733: ERTelecomLogo,
  41754: ERTelecomLogo,
  41771: MTSLogo,
  41786: ERTelecomLogo,
  41798: TranstelecomLogo,
  41822: MTSLogo,
  41843: ERTelecomLogo,
  41854: ERTelecomLogo,
  41976: MegaFonLogo,
  42087: MTSLogo,
  42110: VeonLogo,
  42115: MTSLogo,
  42116: ERTelecomLogo,
  42145: ERTelecomLogo,
  42157: A1TelekomLogo,
  42218: ERTelecomLogo,
  42245: VeonLogo,
  42322: MTSLogo,
  42358: RostelecomLogo,
  42362: RostelecomLogo,
  42368: ERTelecomLogo,
  42548: RostelecomLogo,
  42610: RostelecomLogo,
  42682: ERTelecomLogo,
  42683: ERTelecomLogo,
  42772: A1TelekomLogo,
  42836: Atom86Logo,
  42842: VeonLogo,
  42891: MegaFonLogo,
  42961: ZainLogo,
  43038: MTSLogo,
  43132: RostelecomLogo,
  43147: EquinixLogo,
  43148: MTSLogo,
  43218: MTSLogo,
  43275: VeonLogo,
  43302: RostelecomLogo,
  43318: MTSLogo,
  43408: OrangeLogo,
  43447: OrangeLogo,
  43478: ERTelecomLogo,
  43515: GoogleLogo,
  43574: RostelecomLogo,
  43583: MegaFonLogo,
  43639: AkamaiLogo,
  43687: VeonLogo,
  43782: TranstelecomLogo,
  43811: TeliaLogo,
  43841: MegaFonLogo,
  43970: VeonLogo,
  44143: A1TelekomLogo,
  44237: RostelecomLogo,
  44257: MTSLogo,
  44412: RostelecomLogo,
  44467: RostelecomLogo,
  44579: MTSLogo,
  44659: OrangeLogo,
  44677: MTSLogo,
  44736: MTSLogo,
  44775: TranstelecomLogo,
  44787: IrideosLogo,
  44895: MTSLogo,
  44905: ERTelecomLogo,
  44940: ATTLogo,
  44957: VodafoneLogo,
  44987: MTSLogo,
  45048: MTSLogo,
  45090: TencentLogo,
  45051: ERTelecomLogo,
  45057: ChinaMobileLogo,
  45069: ChinaMobileLogo,
  45096: AlibabaLogo,
  45102: AlibabaLogo,
  45103: AlibabaLogo,
  45104: AlibabaLogo,
  45108: AlibabaLogo,
  45116: VodafoneLogo,
  45120: ChinaMobileLogo,
  45143: SingtelLogo,
  45368: SKBroadbandLogo,
  45396: LimelightLogo,
  45400: KoreaTelecomLogo,
  45508: EquinixLogo,
  45566: GoogleLogo,
  45609: BhartiAirtelLogo,
  45664: PLDTLogo,
  45700: AkamaiLogo,
  45851: GTTCommunicationsLogo,
  45879: OrangeLogo,
  45898: VNPTLogo,
  45899: VNPTLogo,
  46004: SKBroadbandLogo,
  47111: ERTelecomLogo,
  47123: TelecomItaliaSparkleLogo,
  47205: TeliaLogo,
  47218: MegaFonLogo,
  47241: TranstelecomLogo,
  47313: TranstelecomLogo,
  47377: OrangeLogo,
  47395: MegaFonLogo,
  47398: ERTelecomLogo,
  47543: Atom86Logo,
  47579: MTSLogo,
  47659: ERTelecomLogo,
  47886: EquinixLogo,
  47899: MTSLogo,
  47911: ERTelecomLogo,
  48000: MTSLogo,
  48100: MTSLogo,
  48103: OrangeLogo,
  48123: MTSLogo,
  48124: MTSLogo,
  48212: MTSLogo,
  48322: MTSLogo,
  48400: MTSLogo,
  48541: MTSLogo,
  48612: MTSLogo,
  48615: MegaFonLogo,
  48728: VodafoneLogo,
  48780: RostelecomLogo,
  48796: MTSLogo,
  48832: ZainLogo,
  49048: ERTelecomLogo,
  49144: VeonLogo,
  49154: MTSLogo,
  49182: BritishTelecommunicationsLogo,
  49301: TranstelecomLogo,
  49350: MTSLogo,
  49455: TeliaLogo,
  49665: MTSLogo,
  49816: MTSLogo,
  49874: ERTelecomLogo,
  50010: OoredooLogo,
  50011: MTSLogo,
  50071: MTSLogo,
  50173: GTTCommunicationsLogo,
  50315: MegaFonLogo,
  50397: MTSLogo,
  50406: MTSLogo,
  50498: ERTelecomLogo,
  50512: ERTelecomLogo,
  50521: Tele2Logo,
  50542: ERTelecomLogo,
  50543: ERTelecomLogo,
  50544: ERTelecomLogo,
  50646: MTSLogo,
  50714: TranstelecomLogo,
  50928: MegaFonLogo,
  50973: VodafoneLogo,
  51003: TranstelecomLogo,
  51035: ERTelecomLogo,
  51091: Atom86Logo,
  51095: EdgeUnoLogo,
  51116: MTSLogo,
  51372: OrangeLogo,
  51375: SaudiTelecomLogo,
  51543: TranstelecomLogo,
  51570: ERTelecomLogo,
  51604: ERTelecomLogo,
  51638: MegaFonLogo,
  51645: ERTelecomLogo,
  51813: TranstelecomLogo,
  51819: ERTelecomLogo,
  51964: OrangeLogo,
  52207: ERTelecomLogo,
  52320: GlobenetLogo,
  53400: GTTCommunicationsLogo,
  54113: FastlyLogo,
  55023: GoogleLogo,
  55038: Internet2Logo,
  55409: AkamaiLogo,
  55410: VodafoneLogo,
  55429: LimelightLogo,
  55430: StarhubLogo,
  55436: VodafoneLogo,
  55460: TATACommunicationsLogo,
  54588: EquinixLogo,
  55644: VodafoneLogo,
  55770: AkamaiLogo,
  54825: EquinixLogo,
  55836: RelianceJIOLogo,
  55889: EquinixLogo,
  55940: PCCWGlobalLogo,
  55944: OoredooLogo,
  56040: ChinaMobileLogo,
  56041: ChinaMobileLogo,
  56042: ChinaMobileLogo,
  56044: ChinaMobileLogo,
  56045: ChinaMobileLogo,
  56046: ChinaMobileLogo,
  56047: ChinaMobileLogo,
  56048: ChinaMobileLogo,
  56330: ERTelecomLogo,
  56341: RostelecomLogo,
  56369: OrangeLogo,
  56377: ERTelecomLogo,
  56420: ERTelecomLogo,
  56665: ProximusLogo,
  56761: MTSLogo,
  56768: MegaFonLogo,
  56830: TranstelecomLogo,
  56969: ERTelecomLogo,
  56981: ERTelecomLogo,
  57026: ERTelecomLogo,
  57041: ERTelecomLogo,
  57044: ERTelecomLogo,
  57079: ERTelecomLogo,
  57378: ERTelecomLogo,
  58157: ERTelecomLogo,
  58189: ERTelecomLogo,
  58344: ERTelecomLogo,
  58428: VerizonLogo,
  58429: VerizonLogo,
  58453: ChinaMobileLogo,
  58461: ChinaTelecomLogo,
  58466: ChinaTelecomLogo,
  58517: ChinaTelecomLogo,
  58518: ChinaTelecomLogo,
  58519: ChinaTelecomLogo,
  58539: ChinaTelecomLogo,
  58541: ChinaTelecomLogo,
  58542: ChinaTelecomLogo,
  58543: ChinaTelecomLogo,
  58563: ChinaTelecomLogo,
  58571: ChinaTelecomLogo,
  58572: ChinaTelecomLogo,
  58588: AmazonLogo,
  58682: LumenLogo,
  58772: ChinaTelecomLogo,
  58807: ChinaMobileLogo,
  59028: AlibabaLogo,
  59051: AlibabaLogo,
  59052: AlibabaLogo,
  59053: AlibabaLogo,
  59054: AlibabaLogo,
  59055: AlibabaLogo,
  59476: TranstelecomLogo,
  59588: ZainLogo,
  59605: ZainLogo,
  59713: ERTelecomLogo,
  59981: EtisalatLogo,
  59982: EtisalatLogo,
  59983: EtisalatLogo,
  59991: EquinixLogo,
  59997: EquinixLogo,
  60000: EquinixLogo,
  60013: EquinixLogo,
  60043: ERTelecomLogo,
  60066: Tele2Logo,
  60261: LimelightLogo,
  60300: VodafoneLogo,
  60490: MTSLogo,
  60496: MTSLogo,
  60640: EquinixLogo,
  60891: MTSLogo,
  60940: OrangeLogo,
  61143: BhartiAirtelLogo,
  61245: TranstelecomLogo,
  62211: VodafoneLogo,
  62245: EquinixLogo,
  62287: ERTelecomLogo,
  62347: MTSLogo,
  62348: MTSLogo,
  62399: ERTelecomLogo,
  62421: EquinixLogo,
  62663: TelecallLogo,
  62785: AmazonLogo,
  63524: EquinixLogo,
  63525: EquinixLogo,
  63835: ChinaTelecomLogo,
  63838: ChinaTelecomLogo,
  64049: RelianceJIOLogo,
  64124: EdgeUnoLogo,
  64275: EquinixLogo,
  64292: EquinixLogo,
  131285: ChinaTelecomLogo,
  131325: ChinaTelecomLogo,
  132029: TelstraGlobalLogo,
  132045: BhartiAirtelLogo,
  132147: ChinaTelecomLogo,
  132153: ChinaTelecomLogo,
  132167: OoredooLogo,
  132203: TencentLogo,
  132225: ChinaTelecomLogo,
  132281: ChinaUnicomLogo,
  132292: TelstraGlobalLogo,
  132389: ChinaMobileLogo,
  132401: ComcastLogo,
  132410: RelianceJIOLogo,
  132411: RelianceJIOLogo,
  132501: ChinaMobileLogo,
  132508: PLDTLogo,
  132510: ChinaMobileLogo,
  132892: cloudflareLogo,
  133103: AkamaiLogo,
  133580: VodafoneLogo,
  133612: VodafoneLogo,
  133666: VodafoneLogo,
  133774: ChinaTelecomLogo,
  133775: ChinaTelecomLogo,
  133776: ChinaTelecomLogo,
  133877: cloudflareLogo,
  134071: VodafoneLogo,
  134238: ChinaTelecomLogo,
  134419: ChinaTelecomLogo,
  134420: ChinaTelecomLogo,
  134755: ChinaTelecomLogo,
  134756: ChinaTelecomLogo,
  134757: ChinaTelecomLogo,
  134758: ChinaTelecomLogo,
  134759: ChinaTelecomLogo,
  134760: ChinaTelecomLogo,
  134761: ChinaTelecomLogo,
  134762: ChinaTelecomLogo,
  134763: ChinaTelecomLogo,
  134764: ChinaTelecomLogo,
  134765: ChinaTelecomLogo,
  134766: ChinaTelecomLogo,
  134767: ChinaTelecomLogo,
  134768: ChinaTelecomLogo,
  134769: ChinaTelecomLogo,
  134770: ChinaTelecomLogo,
  134771: ChinaTelecomLogo,
  134772: ChinaTelecomLogo,
  134773: ChinaTelecomLogo,
  134774: ChinaTelecomLogo,
  134775: ChinaTelecomLogo,
  134810: ChinaMobileLogo,
  134927: VodafoneLogo,
  135061: ChinaUnicomLogo,
  135146: PCCWGlobalLogo,
  135341: OrangeLogo,
  135621: PCCWGlobalLogo,
  135630: AmazonLogo,
  135720: OrangeLogo,
  135887: TelstraGlobalLogo,
  135905: VNPTLogo,
  135939: VNPTLogo,
  136167: ChinaTelecomLogo,
  136188: ChinaTelecomLogo,
  136190: ChinaTelecomLogo,
  136192: ChinaTelecomLogo,
  136193: ChinaTelecomLogo,
  136194: ChinaTelecomLogo,
  136195: ChinaTelecomLogo,
  136197: ChinaTelecomLogo,
  136198: ChinaTelecomLogo,
  136200: ChinaTelecomLogo,
  136750: ChinaMobileLogo,
  136987: VodafoneLogo,
  137046: PCCWGlobalLogo,
  137191: ChinaTelecomLogo,
  137453: OrangeLogo,
  137686: ChinaTelecomLogo,
  137687: ChinaTelecomLogo,
  137688: ChinaTelecomLogo,
  137689: ChinaTelecomLogo,
  137690: ChinaTelecomLogo,
  137691: ChinaTelecomLogo,
  137692: ChinaTelecomLogo,
  137693: ChinaTelecomLogo,
  137694: ChinaTelecomLogo,
  137695: ChinaTelecomLogo,
  137697: ChinaTelecomLogo,
  137699: ChinaTelecomLogo,
  137702: ChinaTelecomLogo,
  137840: EquinixLogo,
  137858: EquinixLogo,
  137859: EquinixLogo,
  137860: EquinixLogo,
  137872: ChinaMobileLogo,
  138035: VodafoneLogo,
  138345: StarhubLogo,
  138407: ChinaMobileLogo,
  138421: ChinaUnicomLogo,
  138949: ChinaTelecomLogo,
  138950: ChinaTelecomLogo,
  138982: ChinaTelecomLogo,
  138991: ChinaTelecomLogo,
  139007: ChinaUnicomLogo,
  139018: ChinaTelecomLogo,
  139070: GoogleLogo,
  139090: GoogleLogo,
  139190: GoogleLogo,
  139201: ChinaTelecomLogo,
  139203: ChinaTelecomLogo,
  139220: ChinaTelecomLogo,
  139242: cloudflareLogo,
  139281: EquinixLogo,
  139286: EquinixLogo,
  139316: ChinaTelecomLogo,
  139462: ChinaTelecomLogo,
  139584: ChinaTelecomLogo,
  139619: ChinaMobileLogo,
  139721: ChinaTelecomLogo,
  139767: ChinaTelecomLogo,
  139887: ChinaTelecomLogo,
  140056: ChinaTelecomLogo,
  140061: ChinaTelecomLogo,
  140083: ChinaTelecomLogo,
  140265: ChinaTelecomLogo,
  140292: ChinaTelecomLogo,
  140308: ChinaTelecomLogo,
  140310: ChinaTelecomLogo,
  140312: ChinaTelecomLogo,
  140313: ChinaTelecomLogo,
  140314: ChinaTelecomLogo,
  140315: ChinaTelecomLogo,
  140316: ChinaTelecomLogo,
  140317: ChinaTelecomLogo,
  140330: ChinaTelecomLogo,
  140345: ChinaTelecomLogo,
  140485: ChinaTelecomLogo,
  140486: ChinaTelecomLogo,
  140527: ChinaTelecomLogo,
  140553: ChinaTelecomLogo,
  140636: ChinaTelecomLogo,
  140638: ChinaTelecomLogo,
  140647: ChinaTelecomLogo,
  140726: ChinaUnicomLogo,
  140903: ChinaTelecomLogo,
  140928: StarhubLogo,
  140929: StarhubLogo,
  141157: ChinaTelecomLogo,
  141415: ChinaMobileLogo,
  141419: ChinaMobileLogo,
  141425: ChinaMobileLogo,
  141679: ChinaTelecomLogo,
  141771: ChinaTelecomLogo,
  141998: ChinaTelecomLogo,
  142404: ChinaTelecomLogo,
  142608: ChinaTelecomLogo,
  146966: ChinaTelecomLogo,
  148969: ChinaTelecomLogo,
  196747: RostelecomLogo,
  196797: TranstelecomLogo,
  197023: MTSLogo,
  197070: MTSLogo,
  197226: SprintLogo,
  197333: TranstelecomLogo,
  197585: ERTelecomLogo,
  198148: PCCWGlobalLogo,
  198477: TelecomItaliaSparkleLogo,
  199054: MTSLogo,
  199140: OrangeLogo,
  199253: ERTelecomLogo,
  199749: OrangeLogo,
  200765: OrangeLogo,
  201495: ERTelecomLogo,
  201917: VodafoneLogo,
  202623: cloudflareLogo,
  202818: LumenLogo,
  203237: VodafoneLogo,
  203966: A1TelekomLogo,
  203989: cloudflareLogo,
  204354: RostelecomLogo,
  205476: OrangeLogo,
  205508: VeonLogo,
  207018: IrideosLogo,
  208728: Atom86Logo,
  209024: MTSLogo,
  209141: ChinaMobileLogo,
  209144: ChinaMobileLogo,
  209240: VodafoneLogo,
  209242: cloudflareLogo,
  209308: SaudiTelecomLogo,
  211251: A1TelekomLogo,
  211559: VodafoneLogo,
  211913: BhartiAirtelLogo,
  212465: ComcastLogo,
  212475: ComcastLogo,
  212661: VodafoneLogo,
  262589: InternexaLogo,
  264220: EquinixLogo,
  265376: EquinixLogo,
  266849: EquinixLogo,
  268862: ChinaMobileLogo,
  270119: EquinixLogo,
  327707: BhartiAirtelLogo,
  327708: BhartiAirtelLogo,
  327756: BhartiAirtelLogo,
  327799: ViettelLogo,
  328126: OrangeLogo,
  328787: ChinaMobileLogo,
  328794: VodafoneLogo,
  393232: ComcastLogo,
  393234: AkamaiLogo,
  393338: HurricaneElectricLogo,
  393560: AkamaiLogo,
  393789: CenturyLinkLogo,
  394192: FastlyLogo,
  394507: GoogleLogo,
  394536: cloudflareLogo,
  394639: GoogleLogo,
  394699: GoogleLogo,
  394749: EquinixLogo,
  395973: GoogleLogo,
  395974: ComcastLogo,
  395976: ComcastLogo,
  395980: ComcastLogo,
  396017: ComcastLogo,
  396019: ComcastLogo,
  396021: ComcastLogo,
  396415: ComcastLogo,
  396450: Internet2Logo,
  396955: Internet2Logo,
  396961: Internet2Logo,
  396982: GoogleLogo,
  398811: EquinixLogo,
  398814: EquinixLogo
};

const getASNLogo = ({ asn, theme, width, height }) => {
  const baseStyle = { width, maxWidth: '100%' };
  const flipBlackToWhite = theme.name === 'dark' ? { ...baseStyle, filter: 'brightness(0) invert(1)' } : baseStyle;
  // const flipWhiteToBlack = theme.name === 'dark' ? baseStyle : { ...baseStyle, filter: 'brightness(0) invert(0)' };
  const logoProps = { width, height, style: flipBlackToWhite };

  const Logo = logoMap[asn];

  if (Logo) {
    return <Logo {...logoProps} />;
  }
  return null;
};

const ASNLogo = ({
  asn,
  notFoundValue = false,
  showValue = true,
  style = {},
  theme,
  height = 'auto',
  width = '100%',
  ...rest
}) => {
  const Logo = getASNLogo({ asn, showValue, theme, width, height });
  return Logo ? (
    <Flex {...rest} alignItems="center" style={style}>
      {Logo}
    </Flex>
  ) : (
    notFoundValue || null
  );
};

export const willRenderLogo = (asn) => !!logoMap[asn];

export default withTheme(ASNLogo);
