import React from 'react';
import Icon from 'core/components/Icon';
import Box from 'core/components/Box';
import Button from 'core/components/Button';

const TreeExpander = (props) => {
  const { isExpanded, onToggle, depth, hasChildren, small, ...rest } = props;

  // The button is itself 24px (only supporting small for now) and where there are not children, we want to use the parent's depth so they line up
  const width = Math.max(hasChildren ? 24 : 0, (hasChildren ? depth + 1 : depth) * 12);

  return (
    <Box textAlign="right" minWidth={width} title="" {...rest}>
      {hasChildren && (
        <Button
          minimal
          onClick={onToggle}
          icon={
            <Icon
              icon="chevron-right"
              style={{
                transition: 'transform 150ms ease-in-out',
                transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)'
              }}
            />
          }
          small={small}
        />
      )}
    </Box>
  );
};

export default TreeExpander;
