import InterfaceClassDependency from './InterfaceClassDependency';
import NetworkClassDependency from './NetworkClassDependency';
import SiteCountryDependency from './SiteCountryDependency';
import SiteDependency from './SiteDependency';
import SnmpDependency from './SnmpDepdendency';
import GCEDependency from './GCEDependency';
import AWSDependency from './AWSDependency';
import AzureDependency from './AzureDependency';
import CloudDependency from './CloudDependency';
import KProbeDependency from './KProbeDependency';

export const savedViewHasDependencyFailures = (model) => {
  const modelQuery = model.get('query');

  if (modelQuery && modelQuery.length > 0) {
    const { query } = modelQuery[0];

    return (
      !InterfaceClassDependency.queryMeets(query) ||
      !NetworkClassDependency.queryMeets(query) ||
      !SnmpDependency.queryMeets(query) ||
      !SiteDependency.queryMeets(query) ||
      !SiteCountryDependency.queryMeets(query) ||
      !GCEDependency.queryMeets(query) ||
      !AWSDependency.queryMeets(query) ||
      !AzureDependency.queryMeets(query) ||
      !CloudDependency.queryMeets(query) ||
      !KProbeDependency.queryMeets(query)
    );
  }

  return false;
};
