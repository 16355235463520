const fields = {
  message: {
    label: 'Message'
  },

  invitees: {
    isComplexArray: true
  },

  'invitees[].name': {
    label: 'Name'
  },

  'invitees[].email': {
    label: 'Email',
    rules: 'required|email'
  },

  'invitees[].role': {
    label: 'User Level',
    type: 'select',
    placeholder: 'Select User Level',
    defaultValue: 'Member',
    options: [
      {
        value: 'Administrator',
        label: 'Administrator'
      },
      {
        value: 'Member',
        label: 'Member'
      }
    ]
  },

  'invitees[].defaultLandingPageType': { defaultValue: 'observationDeck' },

  'invitees[].defaultLandingPageValue': {
    defaultValue: null,
    messages: {
      required: 'Please select a dashboard or a saved view'
    }
  },

  'invitees[].settings': { label: 'User Permissions' },

  'invitees[].settings.canCancelRawFlow': {
    defaultValue: false
  }
};

const options = {
  name: 'Invite Users'
};

export { fields, options };
