import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import $app from 'app/stores/$app';

import Box from 'core/components/Box';
import Icon from 'core/components/Icon';

import GroupRow from './GroupRow';
import Row from './Row';
import Header from './Header';
import TotalRow from './TotalRow';
import EmptyBody from './EmptyBody';
import SpinnerBody from './SpinnerBody';

const TableContainer = styled.div`
  ${(props) =>
    props.stickyHeader === true &&
    css`
      &.${Classes.HTML_TABLE} {
        .thead {
          position: sticky;
          top: ${props.stickyHeaderOffset}px;
          background-color: ${props.theme.name === 'dark' ? props.theme.colors.cardBackground : '#fff'};
          z-index: 1;
        }
      }
    `}

  ${(props) =>
    props.stickyGroups === true &&
    css`
      &.${Classes.HTML_TABLE} {
        .group-summary {
          position: sticky;
          top: ${(props.stickyHeader ? 28 : 0) + props.stickyHeaderOffset + props.stickyGroupsOffset}px;
        }
      }
    `}
`;

const groupSummaryFn = (props) => {
  const { collection, onToggle, collapsedRows } = props;
  let keys = Object.keys(collection.groupedData);

  if (collection.sortedGroupKeys?.length > 0) {
    keys = collection.sortedGroupKeys;
  }

  return keys.reduce((rows, groupKey, idx, arr) => {
    const group = collection.groupedData[groupKey];
    const isExpanded = !collapsedRows.has(groupKey);
    const collapseCls = classNames('group-details', { collapsed: !isExpanded });
    const last = idx === arr.length - 1;

    return rows.concat([
      <GroupRow
        {...props}
        key={groupKey}
        groupKey={groupKey}
        onToggle={() => onToggle(groupKey)}
        last={last}
        isExpanded={isExpanded}
      />,
      ...group.map((model) => (
        <Row
          {...props}
          key={model.id + groupKey}
          model={model}
          className={collapseCls}
          selected={model.isSelected}
          lastUpdated={model.lastUpdated}
          last={last}
        />
      ))
    ]);
  }, []);
};

const expanderColumn = {
  label: '',
  key: 'row-expander',
  name: 'isSelected',
  computed: true,
  className: 'row-expander',
  width: 25,
  renderer: ({ value }) => <ExpanderIcon icon={value ? 'chevron-down' : 'chevron-right'} />
};

const ExpanderIcon = styled(Icon)`
  color: ${({ theme }) => (theme.name === 'dark' ? theme.colors.lightGray2 : theme.colors.gray1)};
`;

@observer
export default class Table extends Component {
  static defaultProps = {
    clearFiltersOnUnmount: true,
    selectOnRowClick: true,
    showTotalRow: false,
    isCollapsed: false,
    noBorder: false,
    breakpoint: 1024,
    hideNonIdealState: false,
    staticColumns: false,
    hideHeader: false,
    stickyHeader: false,
    stickyGroups: false,
    stickyHeaderOffset: 0,
    stickyGroupsOffset: 0,
    flexed: false
  };

  state = {
    collapseChanged: false,
    visibleColumns: null
  };

  @observable
  collapsedRows = observable.map();

  static getDerivedStateFromProps(props, state) {
    const { columns, expandedRowRenderer, showRowExpandedColumn = true } = props;

    if (!isEqual(columns, state.columns)) {
      const visibleColumns = columns.filter((column) => !column.hidden);

      if (expandedRowRenderer && showRowExpandedColumn) {
        visibleColumns.unshift(expanderColumn);
      }

      return {
        columns,
        visibleColumns
      };
    }

    return null;
  }

  componentDidMount() {
    const { isCollapsed, collection, collapsedGroups } = this.props;
    const { collapseChanged } = this.state;

    if (!$app.isExport) {
      if (isCollapsed && !collapseChanged) {
        this.collapseAllRows(collection);
      }
      if (collapsedGroups) {
        this.collapseRows(collapsedGroups);
      }
    }
  }

  componentWillUnmount() {
    const { collection, clearFiltersOnUnmount } = this.props;
    if (clearFiltersOnUnmount && collection && collection.models.length) {
      collection.clearFilters();
    }
  }

  componentDidUpdate(nextProps) {
    const { isCollapsed } = this.props;
    const { collapseChanged } = this.state;
    if (isCollapsed && !collapseChanged) {
      if (nextProps && nextProps.collection && nextProps.collection.groupedData) {
        this.collapseAllRows(nextProps.collection);
      }
    }
  }

  collapseAllRows = (collection) => {
    const groupKeys = Object.keys(collection.groupedData);
    this.collapseRows(groupKeys);
  };

  @action
  collapseRows = (groupKeys) => {
    groupKeys.forEach((groupKey) => {
      this.collapsedRows.set(groupKey, true);
    });
  };

  @action
  toggleGroup = (rowId) => {
    this.setState({ collapseChanged: true });
    if (this.collapsedRows.has(rowId)) {
      this.collapsedRows.delete(rowId);
    } else {
      this.collapsedRows.set(rowId, true);
    }
  };

  isRowSelected(model) {
    const { isRowSelected } = this.props;
    return isRowSelected ? isRowSelected(model) : model.isSelected;
  }

  isRowDisabled(model) {
    const { isRowDisabled } = this.props;
    if (isRowDisabled) {
      return isRowDisabled(model);
    }
    return false;
  }

  renderEmpty() {
    const { hideNonIdealState } = this.props;

    return hideNonIdealState ? null : <EmptyBody {...this.props} />;
  }

  renderBody(options = {}) {
    const { style } = options;
    const { loading, collection, groupFn = groupSummaryFn, groupSummaryLookup, modelField = 'models' } = this.props;
    const { visibleColumns } = this.state;

    let body;
    if (loading !== undefined ? loading : collection.isRequestActive('fetching')) {
      body = <SpinnerBody />;
    } else if (collection[modelField].length === 0) {
      body = this.renderEmpty();
    } else if (collection.groupBy) {
      body = groupFn({
        collection,
        columns: visibleColumns,
        isExpanded: this.collapsedRows,
        collapsedRows: this.collapsedRows,
        onToggle: this.toggleGroup,
        groupSummaryLookup
      });
    } else {
      body = collection[modelField].map((model) => (
        <Row
          {...this.props}
          key={model.id}
          columns={visibleColumns}
          model={model}
          selected={this.isRowSelected(model)}
          disabled={this.isRowDisabled(model)}
          lastUpdated={model.lastUpdated}
        />
      ));
    }

    return (
      <Box className="tbody" style={style}>
        {body}
      </Box>
    );
  }

  renderTotals(options = {}) {
    const { collection, showTotalRow } = this.props;
    const { visibleColumns } = this.state;

    if (showTotalRow && !collection.isRequestActive('fetching') && collection.models.length > 0) {
      return <TotalRow {...this.props} {...options} columns={visibleColumns} />;
    }

    return null;
  }

  render() {
    const {
      style,
      headerStyle,
      className,
      selectOnRowClick,
      onRowClick,
      onSort,
      hideHeader,
      stickyHeader,
      stickyGroups,
      stickyHeaderOffset,
      stickyGroupsOffset,
      flexed
    } = this.props;
    const { visibleColumns } = this.state;

    const tableClassName = classNames(Classes.HTML_TABLE, className, {
      [Classes.INTERACTIVE]: selectOnRowClick || onRowClick,
      flexed
    });

    return (
      <TableContainer
        style={style}
        className={tableClassName}
        stickyHeader={stickyHeader}
        stickyGroups={stickyGroups}
        stickyHeaderOffset={stickyHeaderOffset}
        stickyGroupsOffset={stickyGroupsOffset}
      >
        {!hideHeader && <Header {...this.props} style={headerStyle} columns={visibleColumns} onSort={onSort} />}
        {this.renderBody()}
        {this.renderTotals()}
      </TableContainer>
    );
  }
}
