import { ReactComponent as AwsLogo } from 'app/assets/logos/aws-logo.svg';
import { ReactComponent as AzureLogo } from 'app/assets/logos/azure-logo.svg';
import { ReactComponent as GcpLogo } from 'app/assets/logos/google-cloud-logo.svg';
import { ReactComponent as OCILogo } from 'app/assets/logos/oracle-logo.svg';

export const metadataOnlyPrefix = 'metadata-only-';
export const METADATA_ONLY_BUCKET_NAME = 'KENTIK-NO-FLOW';

// This array is used to set feature flag functionality
// only cids listed here should be able to create metadata-less cloud exports
export const PROD_COMPANIES_ALLOW_NO_METADATA = [
  '194153', // kentikglobalcloudlab
  '181517', // walmartstoresinc
  '98837' // Mike K cid
];

export const awsTile = {
  logo: AwsLogo,
  provider: 'aws',
  title: 'AWS Cloud',
  description:
    'Collect data to analyze traffic, visualize topology, check connectivity and get insights for your AWS regions, availability zones and VPCs.',
  selectionStatus: null
};
export const gcpTile = {
  logo: GcpLogo,
  title: 'GCP Cloud',
  provider: 'gcp',
  description:
    'Collect data to analyze traffic, visualize topology, inspect run logs and get insights for your GCP networks by project and region.',
  selectionStatus: null
};
export const azureTile = {
  logo: AzureLogo,
  title: 'Azure Cloud',
  provider: 'azure',
  description:
    'Collect data to analyze traffic, visualize topology, check connectivity, inspect firewall logs and get insights for your Azure VPCs, regions and resource groups.',
  selectionStatus: null
};
export const ociTile = {
  logo: OCILogo,
  title: 'OCI Cloud',
  provider: 'oci',
  description:
    'Collect data to analyze traffic, visualize topology and get insights for your OCI regions, VCNs and network interfaces.',
  selectionStatus: null
};

export const defaultsForAll = {
  device_bgp_type: 'other_device',
  use_bgp_device_id: null,
  azure_transfluo_region: null,
  error_message: ''
};

export const azureNoFlowDefault = {
  azure_storage_account: 'kentik-no-flow',
  log_collection_types: [],
  azure_collect_flow_logs: false,
  sampling: null,
  sampling_rate: null,
  sampling_type: null,
  ...defaultsForAll
};

// fills in legacy "properties" fields before saving
export const defaultValues = {
  'aws-metadata-only': {
    aws_bucket: 'KENTIK-NO-FLOW',
    aws_metadata_only: true,
    ...azureNoFlowDefault
  },
  'aws-collect-logs': {
    aws_metadata_only: false,
    ...azureNoFlowDefault,
    log_collection_types: ['flow']
  },
  'gcp-metadata-only': {
    gcp_metadata_only: true,
    ...azureNoFlowDefault
  },
  'gcp-collect-logs': {
    gcp_metadata_only: false,
    ...azureNoFlowDefault
  },
  'azure-metadata-only': {
    azure_metadata_only: true,
    ...azureNoFlowDefault
  },
  'azure-metrics-only': {
    azure_metadata_only: false,
    ...azureNoFlowDefault
  },
  'azure-collect-logs': {
    // azure_collect_flow_logs should already be set
    azure_metadata_only: false,
    log_collection_types: [],
    azure_collect_flow_logs: true,
    ...defaultsForAll
  },
  'azure-collect-fw-logs': {
    azure_storage_account: 'kentik-no-flow',
    azure_metadata_only: false,
    log_collection_types: ['firewall'],
    azure_collect_flow_logs: false,
    sampling: null,
    sampling_rate: null,
    sampling_type: null,
    ...defaultsForAll
  },
  'oci-metadata-only': {
    oci_metadata_only: true,
    oci_collect_flow_logs: false,
    ...azureNoFlowDefault
  },
  'oci-collect-logs': {
    oci_metadata_only: false,
    ...azureNoFlowDefault,
    log_collection_types: ['flow'],
    oci_collect_flow_logs: true
  }
};
