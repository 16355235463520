import React, { Component } from 'react';
import { observer } from 'mobx-react';

import InterfaceSelectorDialog from './InterfaceSelectorDialog';
import InterfaceGroupDisplay from './InterfaceGroupDisplay';

@observer
class InterfaceGroupSelector extends Component {
  static defaultProps = {
    showSite: true,
    showCapacity: true,
    showInterfaceIp: true,
    showDeviceLabel: true,
    showProvider: true,
    showConnectivityType: true,
    showNetworkBoundary: true,
    showInterfaceType: true,
    showAdminStatus: false, // NMS field
    fetch: true
  };

  state = {
    loading: false
  };

  componentDidMount() {
    const { fetch, interfaceGroup } = this.props;

    if (fetch && interfaceGroup && !interfaceGroup.isNew) {
      this.setState({ loading: true });
      interfaceGroup.fetch().then(() => {
        this.setState({ loading: false });
      });
    }
  }

  handleCloseInterfaceSelector = () => {
    const { onInterfaceGroupDialogClose } = this.props;

    if (onInterfaceGroupDialogClose) {
      onInterfaceGroupDialogClose();
    }
  };

  handleSaveInterfaceSelector = ({ filters, staticInterfaces, selectedSidebarFilters, interfaces }) => {
    const { interfaceGroup, onInterfaceGroupSave } = this.props;

    interfaceGroup.set({
      filters: filters || interfaceGroup.get('filters') || [],
      selectedSidebarFilters,
      staticInterfaces: staticInterfaces || interfaceGroup.get('staticInterfaces') || [],
      interfaces: interfaces || interfaceGroup.get('interfaces') || []
    });

    if (onInterfaceGroupSave) {
      onInterfaceGroupSave(interfaceGroup);
    }

    this.handleCloseInterfaceSelector();
  };

  render() {
    const {
      columns,
      emptyText,
      isOpen,
      moduleName,
      interfaceGroup,
      defaultValues,
      showSite,
      showCapacity,
      showInterfaceIp,
      showDeviceLabel,
      showProvider,
      showConnectivityType,
      showNetworkBoundary,
      showInterfaceType,
      showAdminStatus,
      staticInterfacesTitle,
      title,
      containerProps,
      staticOnly,
      staticInterfacesEmptyText,
      isInterfacePolicySelector,
      showDisplay = true
    } = this.props;
    const { loading } = this.state;

    return (
      <>
        {showDisplay && (
          <InterfaceGroupDisplay
            columns={columns}
            loading={loading}
            interfaceGroup={interfaceGroup}
            staticInterfacesTitle={staticInterfacesTitle}
            containerProps={containerProps}
            onSave={this.handleSaveInterfaceSelector}
            staticOnly={staticOnly}
            staticInterfacesEmptyText={staticInterfacesEmptyText}
            isInterfacePolicySelector={isInterfacePolicySelector}
          />
        )}
        {isOpen && (
          /* Note: this must be conditionally rendered or things break. */
          <InterfaceSelectorDialog
            emptyText={emptyText}
            onClose={this.handleCloseInterfaceSelector}
            onSave={this.handleSaveInterfaceSelector}
            interfaceGroup={interfaceGroup}
            type={moduleName}
            defaultValues={defaultValues}
            showSite={showSite}
            showCapacity={showCapacity}
            showInterfaceIp={showInterfaceIp}
            showDeviceLabel={showDeviceLabel}
            showProvider={showProvider}
            showConnectivityType={showConnectivityType}
            showNetworkBoundary={showNetworkBoundary}
            showInterfaceType={showInterfaceType}
            showAdminStatus={showAdminStatus}
            staticInterfacesTitle={staticInterfacesTitle}
            title={title}
            staticOnly={staticOnly}
            isInterfacePolicySelector={isInterfacePolicySelector}
          />
        )}
      </>
    );
  }
}

export default InterfaceGroupSelector;
