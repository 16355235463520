import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';
import moment from 'moment';
import { Box, Flex, Select, Text } from 'core/components';
import { lineClassNames, zoneClassNames } from 'app/components/insights/InsightChart';
import MetricsExplorerButton from 'app/views/metrics/MetricsExplorerButton';
import { formatDate } from 'core/util/dateUtils';
import { ALERT_LOOKBACK_OPTIONS } from 'shared/alerting/constants';
import MetricsExplorerResult from 'app/views/metrics/MetricsExplorerResult';
import { getEntityData } from '../util/nms/nmsAlertEntity';

@inject('$alerting', '$metrics')
@observer
class MetricsThresholdChart extends Component {
  state = {
    lookbackSeconds: ALERT_LOOKBACK_OPTIONS[0].value,
    day: null
  };

  getQuery = () => {
    const { $alerting, model, metric, measurement: passedMeasurement } = this.props;
    const { lookbackSeconds } = this.state;
    const { metrics, reconMeasurement, startTime, endTime } = model;

    return $alerting.getMetricsThresholdChartQuery({
      startTime,
      endTime,
      metrics: metric ? [metric] : metrics,
      measurement: passedMeasurement || reconMeasurement,
      dimensionToValue: this.dimensionToValue,
      lookbackSeconds
    });
  };

  handleLookbackChange = (lookbackSeconds) => {
    this.setState({
      lookbackSeconds
    });
  };

  get dimensionToValue() {
    const { model, dimensionToValue } = this.props;
    const { reconDimensions } = model;

    return dimensionToValue || reconDimensions;
  }

  // Dimension values are used to set chart series names.
  // If we want more human-readable series names, while still
  // querying with values like IDs, we can set the mapping here.
  get dimensionValueMap() {
    const { model } = this.props;
    const { isNmsPolicy } = model;
    const valueMap = {};

    if (isNmsPolicy) {
      const { interface: intf, device } = getEntityData(model) || {};

      if (device?.name) {
        valueMap[this.dimensionToValue.device_id] = device.name;
      }

      if (intf?.name) {
        valueMap[this.dimensionToValue.ifindex] = intf.name;
      }
    }

    return valueMap;
  }

  highChartOptions = () => {
    const { model, measurement: passedMeasurement, metric: passedMetric, $alerting, $metrics } = this.props;
    const { startTime, endTime, severityKey, severityLabel, metricToValue, reconMeasurement } = model;

    const measurement = passedMeasurement || reconMeasurement;
    const measurementModel = $metrics.measurementModel(measurement);
    const modelMetrics = Object.keys(metricToValue);
    const metric = passedMetric || modelMetrics[0];
    const metricLabel = measurementModel?.metricLabel(metric, true);
    const { value: thresholdValue } = $alerting.getThreshold(model, metric) || {};

    const zones = [];
    const xLines = [];
    const yLines = [];

    // Start Time indicator line
    xLines.push({
      value: moment(startTime),
      id: 'alert',
      className: lineClassNames.alarm,
      zIndex: 5,
      label: {
        text: 'Start',
        rotation: 0,
        align: 'right',
        y: 15,
        x: -5,
        className: lineClassNames.alarm
      }
    });

    // Clear Time indicator line
    if (endTime) {
      xLines.push({
        value: moment(endTime),
        className: lineClassNames.cleared,
        id: 'cleared',
        zIndex: 5,
        label: {
          text: 'Clear',
          align: 'left',
          rotation: 0,
          y: 15,
          className: lineClassNames.cleared
        }
      });
    }

    if (thresholdValue) {
      zones.push({ className: '', value: thresholdValue });
      zones.push({ className: zoneClassNames[severityKey] });
      yLines.push({
        value: thresholdValue,
        id: 'threshold',
        zIndex: 4,
        className: lineClassNames[severityKey],
        label: {
          text: `${severityLabel} (${thresholdValue})`,
          rotation: 0,
          className: lineClassNames[severityKey]
        }
      });
    }

    return {
      plotOptions: { series: { zones } },
      xAxis: { plotLines: xLines },
      yAxis: { title: { text: metricLabel }, plotLines: yLines }
    };
  };

  resultCallback = ({ results }) => {
    const ts = get(results, '[0].timeseries');
    let day;
    if (ts) {
      const firstTs = ts.at(0)?.at(0);
      const lastTs = ts.at(-1)?.at(0);
      if (firstTs && lastTs) {
        const firstDay = formatDate(firstTs);
        const lastDay = formatDate(lastTs);
        if (firstDay === lastDay) {
          day = firstDay;
        } else {
          day = `${firstDay} - ${lastDay}`;
        }
        this.setState({ day });
      }
    }
  };

  render() {
    const { day, lookbackSeconds } = this.state;
    const { hideXAxisDate, small } = this.props;
    const query = this.getQuery();
    return (
      <>
        <Flex justifyContent="space-between" alignItems="flex-start" mb={1}>
          <Box>
            <MetricsExplorerButton query={query} small={small} />
          </Box>
          <Box>
            <Select
              options={ALERT_LOOKBACK_OPTIONS}
              values={lookbackSeconds}
              onChange={this.handleLookbackChange}
              menuWidth={155}
              minimal
              small={small}
            />
          </Box>
        </Flex>
        <MetricsExplorerResult
          query={query}
          isWidget
          containerHeight="300px"
          highChartOptions={this.highChartOptions()}
          onQueryComplete={this.resultCallback}
          dimensionValueMap={this.dimensionValueMap}
        />
        {hideXAxisDate ? null : (
          <Flex justifyContent="center" alignItems="flex-start" mb={1}>
            <Box>
              <Text muted small>
                {day}
              </Text>
            </Box>
          </Flex>
        )}
      </>
    );
  }
}

export default MetricsThresholdChart;
