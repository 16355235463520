const flowBaseFields = {
  'config.agents': {
    defaultValue: [],
    rules: ['required', 'agentsSupportIPv6'],
    messages: {
      required: 'One or more agent selections is required.'
    }
  },
  'config.tasks': {
    defaultValue: ['ping', 'traceroute']
  },
  'config.target.value': { rules: 'required' },
  'config.target_refresh_interval_millis': {
    label: 'How often should we scan your flow for new targets?',
    helpText: 'Frequency of scan to check for new targets based on traffic, this does not affect test frequency',
    rules: 'required|numeric',
    defaultValue: 12 * 3600 * 1000, // 12 hours
    options: [
      { value: 1 * 3600 * 1000, label: 'Every hour' },
      { value: 3 * 3600 * 1000, label: 'Every 3 hours' },
      { value: 6 * 3600 * 1000, label: 'Every 6 hours' },
      { value: 12 * 3600 * 1000, label: 'Every 12 hours' },
      { value: 24 * 3600 * 1000, label: 'Every day' },
      { value: 48 * 3600 * 1000, label: 'Every 2 days' },
      { value: 72 * 3600 * 1000, label: 'Every 3 days' },
      { value: 168 * 3600 * 1000, label: 'Every week' }
    ]
  },
  'config.max_tasks': {
    label: 'Maximum number of providers to track autonomously',
    rules: 'required|numeric',
    defaultValue: 5
  },
  'config.max_targets': {
    label: 'Maximum number of IP targets',
    rules: 'numeric',
    defaultValue: 10
  },
  'config.inet_direction': {
    label: 'Find the top',
    rules: 'required',
    defaultValue: 'dst',
    options: [
      { value: 'src', label: 'Sources' },
      { value: 'dst', label: 'Destinations' }
    ]
  },
  'config.direction': {
    label: 'Create tests using target as',
    rules: 'required',
    defaultValue: 'dst',
    options: [
      { value: 'src', label: 'Source of inbound traffic' },
      { value: 'dst', label: 'Destination of outbound traffic' }
    ]
  }
};

export { flowBaseFields };
