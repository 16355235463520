import styled from 'styled-components';
import React, { Component } from 'react';
import { ReactComponent as OracleLogoLight } from 'app/assets/logos/oracle-logo.svg';

const StyledOracleLogo = styled(OracleLogoLight)`
  fill: none;
  path {
    stroke: #c74634 !important;
  }
`;

export default class OCILogo extends Component {
  static defaultProps = {
    width: 12,
    height: 12
  };

  render() {
    return <StyledOracleLogo {...this.props} />;
  }
}
