import Validator from 'validatorjs';

Validator.registerImplicit(
  'interfaceGroupRequired',
  function interfaceGroupRequired() {
    const { model: capacityPlan } = this.validator.input;
    const { interfaceGroup } = capacityPlan;

    if (interfaceGroup) {
      return (
        (interfaceGroup.get('filters') && interfaceGroup.get('filters').length) ||
        (interfaceGroup.get('staticInterfaces') && interfaceGroup.get('staticInterfaces').length)
      );
    }
    return false;
  },
  'A Capacity Planning Group is required'
);

const fields = {
  name: {
    label: 'Plan Name',
    rules: 'required'
  },
  description: {
    label: 'Description'
  },
  trafficSource: {
    label: 'Source',
    defaultValue: 'snmp',
    options: [
      { label: 'SNMP', value: 'snmp' },
      { label: 'Netflow', value: 'netflow' }
    ]
  },
  thresholds: {},
  'thresholds.utilization': { label: 'Utilization' },
  'thresholds.utilization.aggregate': {
    label: 'Utilization Aggregate',
    rules: 'required',
    defaultValue: 'P95',
    options: [
      { label: 'Average', value: 'Avg' },
      { label: 'Max', value: 'Max' },
      { label: '95th Percentile', value: 'P95' },
      { label: '98th Percentile', value: 'P98' },
      { label: '99th Percentile', value: 'P99' }
    ]
  },
  'thresholds.utilization.warning': { defaultValue: 70 },
  'thresholds.utilization.critical': { defaultValue: 85 },
  'thresholds.runout': {},
  'thresholds.runout.strategy': {
    label: 'Runout Calculation Strategy',
    rules: 'required',
    defaultValue: 'weekOverWeek',
    options: [
      { label: 'Week over Week', value: 'weekOverWeek' },
      { label: 'Month over Month', value: 'monthOverMonth' }
    ]
  },
  'thresholds.runout.warningDisplay': { defaultValue: 4 },
  'thresholds.runout.criticalDisplay': { defaultValue: 5 },
  _interfaceGroup: {
    defaultValue: undefined,
    rules: 'interfaceGroupRequired'
  }
};

const options = { name: 'Capacity Plan' };

export { fields, options };
