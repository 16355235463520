import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import Button from 'core/components/Button';
import Box from 'core/components/Box';
import InputGroup from 'core/form/components/InputGroup';
import { debounce } from 'lodash';

const Filter = (props) => {
  const {
    // omit this props to be passed into box to prevent warning as React assumes and tries to bind it with javascript
    onQuery,
    onEditComplete,

    disabled,
    collection,
    inputProps,
    autoFocus = true,
    placeholder = 'Filter',
    filterCallback = () => {},
    clearSelectionsOnChange = false,
    debounceTimer = null,
    ...rest
  } = props;

  const rightElement = collection.filterState ? (
    <Button
      icon="cross"
      onClick={() => {
        collection.filter('');
        if (clearSelectionsOnChange) {
          collection.clearSelection();
        }

        filterCallback('');
      }}
      minimal
    />
  ) : null;

  const [value, setValue] = useState(collection.filterState);

  const onFilterUpdate = useCallback(
    (e) => {
      if (collection.filterState !== e.target.value) {
        collection.filter(e.target.value);
        if (clearSelectionsOnChange) {
          collection.clearSelection();
        }

        filterCallback(e.target.value);
      }
    },
    [clearSelectionsOnChange, collection, filterCallback]
  );

  const debouncedOnChange = useMemo(
    () => (debounceTimer ? debounce(onFilterUpdate, debounceTimer) : onFilterUpdate),
    [debounceTimer, onFilterUpdate]
  );

  const onChange = (e) => {
    setValue(e.target.value);
    debouncedOnChange(e);
  };

  return (
    <Box {...rest}>
      <InputGroup
        autoFocus={autoFocus}
        leftIcon="search"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        rightElement={rightElement}
        disabled={disabled}
        {...inputProps}
      />
    </Box>
  );
};

export default observer(Filter);
