import React, { Component } from 'react';
import { Button, ColorBlock, Icon, Text } from 'core/components';

export default class ShortcutButton extends Component {
  shouldComponentUpdate(props) {
    const { active, disabled } = this.props;

    return props.active !== active || props.disabled !== disabled;
  }

  render() {
    const { icon, count, active, type, label, disabled, numDevices, activeDevices, ...rest } = this.props;
    const numberOfDevices = (activeDevices && activeDevices.length) || numDevices;

    let shortcutIcon;
    if (type === 'site') {
      shortcutIcon = 'map-marker';
    } else if (type === 'label') {
      shortcutIcon = (
        <ColorBlock
          disabled={disabled}
          color={rest.color}
          style={{ width: 14, height: 14, minHeight: 14, marginRight: 7 }}
        />
      );
    } else {
      shortcutIcon = icon;
    }

    return (
      <Button
        fill
        textAlign="left"
        minimal
        active={active}
        mb="1px"
        disabled={disabled}
        {...rest}
        icon={shortcutIcon}
        rightIcon={
          <>
            {!active && (
              <Text small muted style={{ opacity: numberOfDevices === 0 ? 0.45 : 1 }}>
                {numberOfDevices}
              </Text>
            )}
            {active && <Icon icon="small-tick" style={{ marginRight: 0 }} />}
          </>
        }
      />
    );
  }
}
