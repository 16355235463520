import React, { Component } from 'react';
import { Flex } from 'core/components';
import BgpEventsTable from '../events/Table';
import BgpPathGraph from '../path/Graph';

class BgpTestResultsGraphs extends Component {
  render() {
    const { graphs, ...rest } = this.props;

    return (
      <Flex flex="1 0 auto" flexDirection="column">
        {graphs === 'showEvents' && <BgpEventsTable {...rest} />}
        {graphs === 'showPath' && <BgpPathGraph {...rest} />}
      </Flex>
    );
  }
}

export default BgpTestResultsGraphs;
