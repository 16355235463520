// https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html

export class CanceledError extends Error {
  name = 'CanceledError';
}

const makeCancelable = (promise) => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled ? reject(new CanceledError('Promise canceled')) : resolve(val)),
      (error) => (hasCanceled ? reject(new CanceledError('Promise canceled')) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    }
  };
};

export default makeCancelable;
