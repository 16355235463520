import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Flex, Text } from 'core/components';
import formatMetricValue from 'app/util/formatMetricValue';
import {
  DeviceStatusTag,
  InterfaceOperAdminStatusTag,
  SessionStateRenderer
} from 'app/views/metrics/tables/StatusIndicators';

@inject('$dictionary')
@observer
export default class MetricRenderer extends Component {
  static defaultProps = {
    showLabel: true
  };

  render() {
    const { $dictionary, model, value: passedValue, showLabel, noColor } = this.props;
    const metricValues = model.get('metric') || model.metric || {};
    const threshold = model.get('threshold') || model.threshold;

    const conditionMetrics = threshold.conditions.map((condition) => condition.metric);
    const color = noColor;

    if (model.isDeviceStateChangeAlert) {
      const map = model.policyMeasurement?.storage?.Metrics?.[conditionMetrics[0]]?.Values;
      const value = passedValue || metricValues.available;
      return <DeviceStatusTag value={map?.[value]} />;
    }

    if (model.isInterfaceStateChangeAlert) {
      const map = model.policyMeasurement?.storage?.Metrics?.[conditionMetrics[0]]?.Values;
      const value = passedValue || metricValues[conditionMetrics[0]];
      return <InterfaceOperAdminStatusTag value={map?.[value]} showPrefix />;
    }

    if (model.isBGPNeighborStateChangeAlert) {
      const availableStates = model.policyMeasurement?.storage?.Metrics?.['session-state']?.Values;
      const value = passedValue || metricValues['session-state'];
      return <SessionStateRenderer availableStates={availableStates} value={value} />;
    }

    return (
      <>
        {Object.keys(metricValues).map((metric) => {
          const reconMetricFormatting = model.policyMeasurement?.storage?.Metrics?.[metric];
          const metricFormat = formatMetricValue(metricValues[metric], metric);
          const isViolation = conditionMetrics.indexOf(metric) > -1;

          // short circuit for NMS Alerting, don't want to disturb the existing logic
          if (reconMetricFormatting) {
            const { Label, Unit } = reconMetricFormatting;
            return (
              <Flex
                alignItems="center"
                gap="4px"
                key={metric}
                overflow="hidden"
                maxWidth="100%"
                color={isViolation ? color : 'auto'}
                ellipsis
              >
                {showLabel && <Text ellipsis>{Label || metric}</Text>}
                <Text>
                  {metricFormat.value}
                  {Unit === 'percent' ? '%' : Unit}
                </Text>
              </Flex>
            );
          }

          if (isViolation) {
            return (
              <Box key={metric} overflow="hidden" maxWidth="100%">
                {showLabel && (
                  <Text muted ellipsis>
                    {$dictionary.get(`units.${metric}`, metric)}:
                  </Text>
                )}
                <Text
                  color={isViolation ? color : 'auto'}
                  ellipsis
                >{`${metricFormat.value} ${metricFormat.metric}`}</Text>
              </Box>
            );
          }

          return null;
        })}
      </>
    );
  }
}
