import { Classes, Position } from '@blueprintjs/core';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import storeLoader from 'app/stores/storeLoader';
import classNames from 'classnames';
import { Box, Button, Flex, Link, Menu, Popover, Suspense, Text } from 'core/components';
import { inject } from 'mobx-react';
import React, { Component } from 'react';
import MonitoringTemplateForm from './MonitoringTemplateForm';

@storeLoader('$metrics.monitoringTemplatesCollection')
@inject('$metrics')
export default class MonitoringTemplateSelector extends Component {
  static defaultProps = {
    label: 'Monitoring Template',
    inlineLabel: false,
    placeholder: 'Select…',
    withDefault: true
  };

  componentDidUpdate(prevProps) {
    const { loading, model, value, withDefault, $metrics } = this.props;

    // storeLoader finished
    if (prevProps.loading && !loading) {
      const id = value || model?.get('monitoring_template_id');
      const { defaultTemplate } = $metrics.monitoringTemplatesCollection;

      if (withDefault && defaultTemplate && !Number(id)) {
        this.handleChange(defaultTemplate.id);
      }
    }
  }

  state = {
    creatingTemplateModel: null,
    isOpen: false
  };

  get selectedTemplate() {
    const { value, model, $metrics } = this.props;
    const collection = $metrics.monitoringTemplatesCollection;
    const id = value || model?.get('monitoring_template_id');
    return id ? collection.get(id) : null;
  }

  handleChange = (value) => {
    const { onChange, model } = this.props;

    if (onChange) {
      onChange(value);
    } else if (model) {
      model.set('monitoring_template_id', value);
    }
  };

  handleOpen = (isOpen = true) => {
    this.setState({ isOpen });
  };

  handleCreate = (sourceTemplate = null) => {
    const { $metrics } = this.props;
    const collection = $metrics.monitoringTemplatesCollection;
    const model = sourceTemplate ? sourceTemplate.duplicate() : collection.defaultTemplate.duplicate({ isNew: true });
    this.setState({ creatingTemplateModel: model });
  };

  handleCloseTemplateDialog = () => {
    this.setState(({ creatingTemplateModel }) => {
      if (creatingTemplateModel?.isNew === false) {
        this.handleChange(creatingTemplateModel.id);
      }
      return { creatingTemplateModel: null };
    });
  };

  optionRenderer = (template, selected) => (
    <li key={template.id}>
      <Button
        className={classNames(Classes.MENU_ITEM, Classes.POPOVER_DISMISS, {
          [Classes.ACTIVE]: template.id === selected?.id
        })}
        textAlign="left"
        onClick={() => (template.id === selected?.id ? null : this.handleChange(template.id))}
        minimal
        ellipsis
      >
        <Box>{template.get('name')}</Box>
        <Text muted small>
          {template.get('description')}
        </Text>
      </Button>
    </li>
  );

  get buttonLabel() {
    const { showButtonLabel, label, loading, placeholder } = this.props;
    const { selectedTemplate } = this;
    if (loading) {
      return 'Loading…';
    }

    if (showButtonLabel && label) {
      return label;
    }
    if (!selectedTemplate) {
      return placeholder;
    }
    if (selectedTemplate) {
      return selectedTemplate.get('name');
    }
    return label;
  }

  render() {
    const { $metrics, inlineLabel, label, loading } = this.props;
    const { creatingTemplateModel, isOpen } = this.state;
    const { selectedTemplate } = this;
    const collection = $metrics.monitoringTemplatesCollection;

    return (
      <>
        <Popover
          canEscapeKeyClose
          modifiers={{
            flip: { enabled: true, boundariesElement: 'viewport' },
            preventOverflow: { enabled: true, boundariesElement: 'viewport' },
            offset: { enabled: true, offset: '0,2px' }
          }}
          content={
            <Menu width={315} overflow="hidden" borderRadius={3}>
              <Suspense loading={loading}>
                <Box maxHeight={350} overflow="auto">
                  <li className={Classes.MENU_HEADER}>
                    <h6 className={Classes.HEADING}>Presets</h6>
                  </li>
                  {collection.presets.map((template) => this.optionRenderer(template, selectedTemplate))}
                  {collection.customTemplates.length > 0 && (
                    <>
                      <li className={Classes.MENU_HEADER}>
                        <h6 className={Classes.HEADING}>Your templates</h6>
                      </li>
                      {collection.customTemplates.map((template) => this.optionRenderer(template, selectedTemplate))}
                    </>
                  )}
                </Box>
                <li style={{ padding: '7px' }}>
                  <Button
                    onClick={() => this.handleCreate()}
                    className={classNames(Classes.MENU_ITEM, Classes.POPOVER_DISMISS)}
                    icon="add"
                    rightIcon="blank"
                    text="Create Template"
                    fill
                  />
                  <Flex mt="6px" justifyContent="center">
                    <Link to="/v4/settings/monitoring-templates" blank>
                      Manage templates
                    </Link>
                  </Flex>
                </li>
              </Suspense>
            </Menu>
          }
          isOpen={isOpen}
          onClose={() => this.handleOpen(false)}
          position={Position.BOTTOM_LEFT}
          minimal
        >
          <Button
            active={isOpen}
            onClick={() => this.handleOpen(!isOpen)}
            rightIcon="caret-down"
            textAlign="left"
            height={inlineLabel ? 38 : undefined}
            minWidth={145}
          >
            {inlineLabel && (
              <Text as="div" muted small pb="1px">
                {label}
              </Text>
            )}
            <Text
              as="div"
              fontWeight={inlineLabel ? 'bold' : 'normal'}
              muted={loading || !selectedTemplate}
              small={inlineLabel}
            >
              {this.buttonLabel}
            </Text>
          </Button>
        </Popover>
        {creatingTemplateModel && (
          <MonitoringTemplateForm
            model={creatingTemplateModel}
            isDialog
            isOpen
            onClose={this.handleCloseTemplateDialog}
          />
        )}
      </>
    );
  }
}
