import React from 'react';
import { Box, Flex, Heading, Icon, Text } from 'core/components';
import TracerouteCounter from './TracerouteCounter';

const TestPathHops = ({
  isTraceResultsTruncated,
  traceroutes,
  resultTimeMs,
  onHover,
  xMin,
  xMax,
  xAxisMin,
  xAxisMax,
  delta,
  ...rest
}) => (
  <Box {...rest}>
    <Flex justifyContent="space-between" mb={2}>
      <Flex flexDirection="column" flex="0 0 50%" mr={4}>
        <Heading level={5} mb={0.5}>
          Path Hops and Geo Distance
        </Heading>
        <Box>
          <Text muted>
            Number of hops and distance are derived from traceroute data and IP Geocoding.{' '}
            {isTraceResultsTruncated && (
              <>
                This is a very large traceroute dataset, some results may be truncated. Refine time range to get more
                results.
              </>
            )}
          </Text>
        </Box>
      </Flex>
      <Flex alignItems="flex-start">
        <Flex alignItems="center" mr={2}>
          <Icon iconSize={10} icon="full-circle" color="blue1" mr={1} />
          <Text small muted>
            Sum of traceroute hops
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Icon iconSize={10} icon="full-circle" color="blue5" mr={1} />
          <Text small muted>
            Sum of distance over all hops in traceroute
          </Text>
        </Flex>
      </Flex>
    </Flex>
    <TracerouteCounter
      data={traceroutes || []}
      time={resultTimeMs}
      onHover={onHover}
      xMin={xMin}
      xMax={xMax}
      xAxisMin={xAxisMin}
      xAxisMax={xAxisMax}
      delta={delta}
    />
  </Box>
);

export default TestPathHops;
