import React, { Component } from 'react';
import { observer } from 'mobx-react';
import storeLoader from 'app/stores/storeLoader';
import { AiOutlineComment } from 'react-icons/ai';
import { Box, Text, Flex, FlexColumn, Icon, Heading } from 'core/components';
import styled from 'styled-components';
import Comment from './Comment';
import AddEditComment from './AddEditComment';

const CommentWrapper = styled(FlexColumn)`
  padding-bottom: 4px;
  padding-left: ${(props) => (props.integratedMode ? '8px' : '')};
  margin-left: ${(props) => (props.integratedMode ? '18px' : '')};
  overflow: ${(props) => (props.integratedMode ? 'auto' : '')};
  max-height: ${(props) => (props.integratedMode ? '600px' : '')};
`;

@storeLoader('$comments')
@observer
export default class CommentList extends Component {
  static defaultProps = {
    // when put in sidebar, we run it as "integrated", translates into defined maxHeight and adds icon on title
    integratedMode: false,
    // add reply textarea to the end of the list
    addReply: false
  };

  get commentsForEntity() {
    const { $comments, entityType, entityId, model } = this.props;

    if (entityType === 'alert' && model) {
      return $comments.collection.getAlertComments(model);
    }
    return $comments.collection.getCommentsForEntityById(entityId);
  }

  render() {
    const { integratedMode, addReply, entityId, entityType } = this.props;
    const comments = this.commentsForEntity;

    return (
      <FlexColumn flex={1} gap={1} maxWidth="600px">
        <Flex>
          {integratedMode && (
            <Box width={26}>
              <Icon icon={AiOutlineComment} iconSize={18} color="muted" />
            </Box>
          )}
          <Heading level={6} mb={0}>
            Comments{' '}
            <Text fontWeight="normal" muted>
              ({comments.length})
            </Text>
          </Heading>
        </Flex>
        {comments.length > 0 && (
          <CommentWrapper integratedMode={integratedMode} gap={1}>
            {comments.map((comment) => (
              <Comment key={comment.id} model={comment} entityType={comment.get('entityType')} />
            ))}
          </CommentWrapper>
        )}
        {addReply && (
          <div style={{ marginLeft: integratedMode ? '26px' : 0 }}>
            <AddEditComment entityId={entityId} entityType={entityType} />
          </div>
        )}
      </FlexColumn>
    );
  }
}
