import { Collection } from 'core/model';
import PeeringExchangeModel from './PeeringExchangeModel';

export default class PeeringExchangeCollection extends Collection {
  get defaultSortState() {
    return { field: 'common', direction: 'asc' };
  }

  get secondarySort() {
    return { field: 'name', direction: 'asc' };
  }

  get model() {
    return PeeringExchangeModel;
  }
}
