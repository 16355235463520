import React, { PureComponent } from 'react';
import { Classes } from '@blueprintjs/core';
import styled from 'styled-components';
import classNames from 'classnames';

import Box from 'core/components/Box';

const StyledInput = styled.input`
  &.small {
    font-size: 12px;
    height: 26px;
    line-height: 26px;
    padding: 0 4px;
  }
`;

class SelectPopoverInput extends PureComponent {
  componentDidMount() {
    setTimeout(() => this.focus(), 0);
  }

  focus() {
    this.inputEl.focus();
  }

  render() {
    const { small, rightElement, ...rest } = this.props;
    const className = classNames(Classes.INPUT, { small });

    return (
      <Box p="4px">
        <div className={Classes.INPUT_GROUP}>
          <StyledInput type="text" className={className} ref={(inputEl) => (this.inputEl = inputEl)} {...rest} />
          {rightElement}
        </div>
      </Box>
    );
  }
}

export default SelectPopoverInput;
