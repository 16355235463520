import React, { Component } from 'react';
import HighchartsInstance from 'highcharts';
import HCMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import highchartsDraggable from 'highcharts/modules/draggable-points';
import xrange from 'highcharts/modules/xrange';
import solidGauge from 'highcharts/modules/solid-gauge';
import tilemapModule from 'highcharts/modules/tilemap';
import heatmapModule from 'highcharts/modules/heatmap';
import HighchartsPatternFill from 'highcharts-pattern-fill';
import HighchartsAnnotations from 'highcharts/modules/annotations';
import { withTheme } from 'styled-components';

import { timezone } from 'core/util/dateUtils';
import HighchartsWrapper from 'core/components/HighchartsWrapper';

// by default, highcharts filters unknown tags and attributes
// add an escape hatch for us to pass timeslice data in synthetics alarm timeline tooltips in click event handlers
HighchartsInstance.AST.allowedAttributes.push('data-time');

HCMore(HighchartsInstance);
highchartsDraggable(HighchartsInstance);
xrange(HighchartsInstance);
heatmapModule(HighchartsInstance);
tilemapModule(HighchartsInstance);
solidGauge(HighchartsInstance);
HighchartsPatternFill(HighchartsInstance);
HighchartsAnnotations(HighchartsInstance);

@withTheme
class Highcharts extends Component {
  static defaultProps = {
    allowChartUpdate: false,
    immutable: false,
    height: 400,
    width: '100%',
    colors: [],
    styledMode: true,
    highlightPointsByEvent: false,
    containerProps: { style: { width: '100%' } },
    containerHeight: undefined
  };

  render() {
    const {
      allowChartUpdate,
      immutable,
      options,
      callback,
      highcharts,
      colors,
      styledMode,
      highlightPointsByEvent,
      containerProps,
      containerHeight,
      height,
      ...rest
    } = this.props;

    if (!styledMode) {
      if (highlightPointsByEvent) {
        HighchartsInstance.Point.prototype.highlight = function highlight(event) {
          event = this.series.chart.pointer.normalize(event);
          this.onMouseOver(); // Show the hover marker
          this.series.chart.tooltip.refresh([this], event); // Show the tooltip
          this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
        };
      }
      options.time = options.time || {};
      options.time.useUTC = timezone.value === 'UTC';
      return (
        <HighchartsReact
          highcharts={HighchartsInstance}
          options={options}
          callback={callback}
          styledMode={styledMode}
          containerProps={containerProps}
        />
      );
    }

    if (colors.length === 0) {
      console.warn('Highcharts in use with no colors!');
    }

    const chartOptions = {
      title: {
        text: null
      },
      ...options,
      credits: {
        enabled: false,
        ...options.credits
      },
      chart: {
        className: options.chart.type,
        ...options.chart,
        styledMode,
        height
      }
    };

    return (
      <HighchartsWrapper {...rest} height={containerHeight || height} colors={colors}>
        <HighchartsReact
          highcharts={HighchartsInstance}
          options={chartOptions}
          callback={callback}
          allowChartUpdate={allowChartUpdate}
          immutable={immutable}
        />
      </HighchartsWrapper>
    );
  }
}

export default Highcharts;
