import $app from 'app/stores/$app';
import $dictionary from 'app/stores/$dictionary';
import $lookups from 'app/stores/$lookups';

export const FILTER_OPTIONS = [
  {
    label: 'ASN',
    value: 'asn'
  },
  {
    label: 'Next Hop ASN',
    value: 'next_hop_asn'
  },
  {
    label: 'Provider',
    value: 'provider_classification'
  },
  {
    label: 'AS Path',
    value: 'as_path'
  },
  {
    label: 'BGP Community',
    value: 'bgp_community'
  },
  {
    label: 'BGP Extended Community',
    value: 'bgp_ext_community'
  },
  {
    label: 'BGP Large Community',
    value: 'bgp_large_community'
  },
  {
    label: 'BGP Origin',
    value: 'bgp_origin'
  },
  {
    label: 'Site Country',
    value: 'i_device_site_country'
  },
  {
    label: 'Country',
    value: 'country'
  },
  {
    label: 'Region',
    value: 'region'
  },
  {
    label: 'City',
    value: 'city'
  },
  {
    label: 'Site',
    value: 'i_device_site_name'
  },
  {
    label: 'Device',
    value: 'i_device_name'
  },
  {
    label: 'Interface Name',
    value: 'interface_description'
  },
  {
    label: 'Interface Description',
    value: 'snmp_alias'
  },
  {
    label: 'Application',
    value: 'application'
  },
  {
    label: 'Protocol',
    value: 'i_protocol_name'
  },
  {
    label: 'Port',
    value: 'port'
  },
  {
    label: 'Connectivity Type',
    value: 'connectivity_type'
  },
  {
    label: 'Network Boundary',
    value: 'network_boundary'
  },
  {
    label: 'INET Family',
    value: 'inet_family'
  }
];

const sourceMap = {
  provider_classification: 'i_src_provider_classification',
  asn: 'src_as',
  next_hop_asn: 'src_nexthop_as',
  as_path: 'src_bgp_aspath',
  network_boundary: 'i_src_network_bndry_name',
  connectivity_type: 'i_src_connect_type_name',
  country: 'src_geo',
  region: 'src_geo_region',
  city: 'src_geo_city',
  interface: 'input_port',
  interface_description: 'i_input_interface_description',
  snmp_alias: 'i_input_snmp_alias',
  port: 'l4_src_port',
  bgp_community: 'src_bgp_community',
  ip: 'inet_src_addr',
  route_prefix: 'inet_src_route_prefix',
  route_prefix_len: 'src_route_length',
  as_group: 'kt_src_as_group',
  aws_region: 'kt_aws_dst_region',
  gcp_region: 'ktsubtype__gcp_subnet__STR05',
  azure_region: 'kt_az_dst_region',
  aws_zone: 'kt_aws_dst_zone',
  aws_zone_id: 'kt_aws_dst_zone_id',
  gcp_zone: 'ktsubtype__gcp_subnet__STR07',
  azure_zone: 'kt_az_dst_zone',
  aws_instance: 'kt_aws_dst_vm_name',
  azure_instance: 'kt_az_dst_inst_name',
  aws_security_group: 'kt_aws_dst_sg',
  azure_security_group: 'ktsubtype__azure_subnet__STR04',
  aws_public_dns: 'kt_aws_dst_pub_dns',
  azure_public_dns: 'kt_az_dst_fqdn',
  aws_private_dns: 'kt_aws_dst_priv_dns',
  aws_vpc_id: 'kt_aws_dst_vpc_id',
  aws_subnet_id: 'kt_aws_dst_subnet_id',
  aws_interface_id: 'ktsubtype__aws_subnet__STR03',
  aws_firewall_action: 'kt_aws_action',
  azure_firewall_action: 'ktsubtype__azure_subnet__STR02',
  aws_logging_status: 'kt_aws_status',
  gcp_project_id: 'ktsubtype__gcp_subnet__STR01',
  gcp_vm_name: 'ktsubtype__gcp_subnet__STR03',
  gcp_subnet_name: 'ktsubtype__gcp_subnet__STR09',
  azure_subnet_name: 'kt_az_dst_subnet',
  gcp_reporter: 'ktsubtype__gcp_subnet__STR10',
  azure_vnet_id: 'kt_az_dst_vnet',
  azure_public_ip: 'kt_az_dst_public_ip',
  azure_subscription: 'kt_az_dst_sub_id',
  azure_subscription_name: 'kt_az_dst_sub_name',
  azure_security_rule: 'ktsubtype__azure_subnet__STR00'
};

const destinationMap = {
  provider_classification: 'i_dst_provider_classification',
  asn: 'dst_as',
  next_hop_asn: 'dst_nexthop_as',
  as_path: 'dst_bgp_aspath',
  network_boundary: 'i_dst_network_bndry_name',
  connectivity_type: 'i_dst_connect_type_name',
  country: 'dst_geo',
  region: 'dst_geo_region',
  city: 'dst_geo_city',
  interface: 'output_port',
  interface_description: 'i_output_interface_description',
  snmp_alias: 'i_output_snmp_alias',
  port: 'l4_dst_port',
  bgp_community: 'dst_bgp_community',
  ip: 'inet_dst_addr',
  route_prefix: 'inet_dst_route_prefix',
  route_prefix_len: 'dst_route_length',
  as_group: 'kt_dst_as_group',
  aws_region: 'kt_aws_src_region',
  gcp_region: 'ktsubtype__gcp_subnet__STR04',
  azure_region: 'kt_az_src_region',
  aws_zone: 'kt_aws_src_zone',
  aws_zone_id: 'kt_aws_src_zone_id',
  gcp_zone: 'ktsubtype__gcp_subnet__STR06',
  azure_zone: 'kt_az_src_zone',
  aws_instance: 'kt_aws_src_vm_name',
  azure_instance: 'kt_az_src_inst_name',
  aws_security_group: 'kt_aws_src_sg',
  azure_security_group: 'ktsubtype__azure_subnet__STR05',
  aws_public_dns: 'kt_aws_src_pub_dns',
  azure_public_dns: 'kt_az_src_fqdn',
  aws_private_dns: 'kt_aws_src_priv_dns',
  aws_vpc_id: 'kt_aws_src_vpc_id',
  aws_subnet_id: 'kt_aws_src_subnet_id',
  aws_interface_id: 'ktsubtype__aws_subnet__STR03',
  aws_firewall_action: 'kt_aws_action',
  azure_firewall_action: 'ktsubtype__azure_subnet__STR03',
  aws_logging_status: 'kt_aws_status',
  gcp_project_id: 'ktsubtype__gcp_subnet__STR00',
  gcp_vm_name: 'ktsubtype__gcp_subnet__STR02',
  gcp_subnet_name: 'ktsubtype__gcp_subnet__STR08',
  azure_subnet_name: 'kt_az_src_subnet',
  gcp_reporter: 'ktsubtype__gcp_subnet__STR10',
  azure_vnet_id: 'kt_az_src_vnet',
  azure_public_ip: 'kt_az_src_public_ip',
  azure_subscription: 'kt_az_src_sub_id',
  azure_subscription_name: 'kt_az_src_sub_name',
  azure_security_rule: 'ktsubtype__azure_subnet__STR01'
};

const sourceDestMap = {
  provider_classification: 'i_src|dst_provider_classification',
  asn: 'src|dst_as',
  next_hop_asn: 'src|dst_nexthop_as',
  as_path: 'src|dst_bgp_aspath',
  network_boundary: 'i_src|dst_network_bndry_name',
  connectivity_type: 'i_src|dst_connect_type_name',
  country: 'src|dst_geo',
  region: 'src|dst_geo_region',
  city: 'src|dst_geo_city',
  interface: 'input|output_port',
  interface_description: 'i_input|output_interface_description',
  snmp_alias: 'i_input|output_snmp_alias',
  port: 'l4_src|dst_port',
  bgp_community: 'src|dst_bgp_community',
  ip: 'inet_src|dst_addr',
  route_prefix: 'inet_src|dst_route_prefix',
  route_prefix_len: 'src|dst_route_length',
  as_group: 'kt_src|dst_as_group',
  aws_region: 'kt_aws_src|dst_region',
  gcp_region: 'ktsubtype__gcp_subnet__STR04src|dstktsubtype__gcp_subnet__STR05',
  azure_region: 'kt_az_src|dst_region',
  aws_zone: 'kt_aws_src|dst_zone',
  aws_zone_id: 'kt_aws_src|dst_zone_id',
  gcp_zone: 'ktsubtype__gcp_subnet__STR06src|dstktsubtype__gcp_subnet__STR07',
  azure_zone: 'kt_az_src|dst_zone',
  aws_instance: 'kt_aws_src|dst_vm_name',
  azure_instance: 'kt_az_src|dst_inst_name',
  aws_security_group: 'kt_aws_src|dst_sg',
  azure_security_group: 'ktsubtype__azure_subnet__STR05src|dstktsubtype__azure_subnet__STR04',
  aws_public_dns: 'kt_aws_src|dst_pub_dns',
  azure_public_dns: 'kt_az_src|dst_fqdn',
  aws_private_dns: 'kt_aws_src|dst_priv_dns',
  aws_vpc_id: 'kt_aws_src|dst_vpc_id',
  aws_subnet_id: 'kt_aws_src|dst_subnet_id',
  aws_interface_id: 'ktsubtype__aws_subnet__STR03',
  aws_firewall_action: 'kt_aws_action',
  azure_firewall_action: 'ktsubtype__azure_subnet__STR03src|dstktsubtype__azure_subnet__STR02',
  aws_logging_status: 'kt_aws_status',
  gcp_project_id: 'ktsubtype__gcp_subnet__STR00src|dstktsubtype__gcp_subnet__STR01',
  gcp_vm_name: 'ktsubtype__gcp_subnet__STR02src|dstktsubtype__gcp_subnet__STR03',
  gcp_subnet_name: 'ktsubtype__gcp_subnet__STR08src|dstktsubtype__gcp_subnet__STR09',
  azure_subnet_name: 'kt_az_src|dst_subnet',
  gcp_reporter: 'ktsubtype__gcp_subnet__STR10',
  azure_vnet_id: 'kt_az_src|dst_vnet',
  azure_public_ip: 'kt_az_src|dst_public_ip',
  azure_subscription: 'kt_az_src|dst_sub_id',
  azure_subscription_name: 'kt_az_src|dst_sub_name',
  azure_security_rule: 'ktsubtype__azure_subnet__STR01src|dstktsubtype__azure_subnet__STR00'
};

const operatorMap = {
  interface_description: 'ILIKE',
  snmp_alias: 'ILIKE',
  as_path: 'ILIKE',
  bgp_community: 'ILIKE',
  ip: 'ILIKE'
};

export function getInboundFilters(filters) {
  filters = Array.isArray(filters) ? filters : [];
  return filters.map(({ filterField, filterValue, operator }) => ({
    filterField: ($app.isSubtenant ? destinationMap : sourceMap)[filterField] || filterField,
    operator: operator || operatorMap[filterField] || '=',
    filterValue
  }));
}

export function getOutboundFilters(filters) {
  filters = Array.isArray(filters) ? filters : [];
  return filters.map(({ filterField, filterValue, operator }) => ({
    filterField: ($app.isSubtenant ? sourceMap : destinationMap)[filterField] || filterField,
    operator: operator || operatorMap[filterField] || '=',
    filterValue
  }));
}

export function getInternalFilters(filters) {
  filters = Array.isArray(filters) ? filters : [];
  return filters.map(({ filterField, filterValue, operator }) => ({
    filterField: sourceDestMap[filterField] || filterField,
    operator: operator || operatorMap[filterField] || '=',
    filterValue
  }));
}

const getFiltersDirectionMap = {
  Inbound: getInboundFilters,
  Ingress: getInboundFilters,
  Outbound: getOutboundFilters,
  Egress: getOutboundFilters,
  Internal: getInternalFilters,
  Through: getInternalFilters,
  Other: getInternalFilters
};

function getFilterGroups(direction, filterGroups) {
  return filterGroups.map((group) => {
    const resultantGroup = {};
    if (group.filterGroups) {
      resultantGroup.filterGroups = getFilterGroups(direction, group.filterGroups);
      resultantGroup.connector = group.connector;
    }
    resultantGroup.filters = (group.filters && getFiltersDirectionMap[direction](group.filters)) || [];
    return resultantGroup;
  });
}

export function getFilterValueSelectProps(filterField) {
  const dictFilterField = sourceMap[filterField] || filterField;
  const valueOptions = $dictionary.filterFieldValueOptions[dictFilterField] || null;
  const onQuery = $lookups.getAutoCompleteFilterHandler(dictFilterField);

  return {
    valueOptions,
    onQuery
  };
}

export function applyFilterGroupsToFbdQuery(fbdQuery, newFilterGroups) {
  const { filterGroups } = fbdQuery.filterDimensions;

  Object.keys(getFiltersDirectionMap).forEach((direction) => {
    const group = filterGroups.find((g) => g.name === direction);
    if (group) {
      group.filterGroups.push(...getFilterGroups(direction, newFilterGroups));
    }
  });
}

export function applyFiltersToFbdQuery(fbdQuery, filters) {
  const { filterGroups } = fbdQuery.filterDimensions;

  Object.keys(getFiltersDirectionMap).forEach((direction) => {
    const group = filterGroups.find((g) => g.name === direction);
    if (group) {
      group.filters.push(...getFiltersDirectionMap[direction](filters));
    }
  });
}
export function applyFilterGroupsToAggFilterQuery(aggFilterQuery, newFilterGroups) {
  const { aggregateFilters } = aggFilterQuery;

  Object.keys(getFiltersDirectionMap).forEach((direction) => {
    const group = aggregateFilters.find((g) => g.name === direction);
    if (group) {
      group.filterGroups.push(...getFilterGroups(direction, newFilterGroups));
    }
  });
}

export function applyFiltersToAggFilterQuery(aggFilterQuery, filters) {
  const { aggregateFilters } = aggFilterQuery;

  Object.keys(getFiltersDirectionMap).forEach((direction) => {
    const group = aggregateFilters.find((g) => g.name === direction);
    if (group) {
      group.filters.push(...getFiltersDirectionMap[direction](filters));
    }
  });
}

export function getFilterField(direction, filterField) {
  // WARNING: this is totally not brittle and will only break if someone reorganizes the dictionary in any way. :)
  const cloudFilters = $dictionary.get('queryFilters.filterTypes.Cloud')[4]; // 4th entry is cloud subcategories.
  const cloudFilterFields = Object.keys(cloudFilters).reduce((fields, cloud) => {
    const [src, dst] = cloudFilters[cloud]; // we only need to do directional filters, ignore bi-di and non-di
    return fields.concat(Object.keys(src)).concat(Object.keys(dst));
  }, []);

  if (!cloudFilterFields.includes(filterField) && (direction === 'Inbound' || direction === 'Ingress')) {
    return filterField.replace(/dst/, 'src').replace(/output/, 'input');
  }

  if (!cloudFilterFields.includes(filterField) && (direction === 'Outbound' || direction === 'Egress')) {
    return filterField.replace(/src/, 'dst').replace(/input/, 'output');
  }

  if (direction === 'Internal' || direction === 'Other' || direction === 'Through') {
    if (filterField.includes('src|dst') || filterField.includes('input|output')) {
      return filterField;
    }
    return filterField.replace(/(src|dst)/, 'src|dst').replace(/(input|output)/, 'input|output');
  }

  return filterField;
}
