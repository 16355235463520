import { inject } from 'mobx-react';
import React, { Component } from 'react';
import { FiCopy } from 'react-icons/fi';
import styled from 'styled-components';
import CopyToClipboard from 'app/components/CopyToClipboardButton';
import { greekIt } from 'app/util/utils';
import { Box, Button, Callout, Dialog, Grid, Suspense, Switch, Text } from 'core/components';

const CodeBlock = styled.div`
  background-color: ${({ theme }) => (theme.name === 'light' ? theme.colors.lightGray5 : theme.colors.darkGray1)};
  border: ${({ theme }) => theme.borders.thin};
  padding: 16px;
  max-height: 600px;
  overflow: auto;
  font-size: 12px;
  line-height: 15px;

  > pre {
    margin: 0;
  }
`;

function textTable(options) {
  const { data } = options;
  const columnWidths = [];

  data.forEach((row) => {
    row.forEach((col, colIdx) => {
      columnWidths[colIdx] = Math.max(columnWidths[colIdx] || 0, col?.length);
    });
  });

  return data
    .map((row) =>
      row
        .map((col, colIdx) => (col || '').toString().padEnd(columnWidths[colIdx], ' '))
        .join('    ')
        .trimEnd()
    )
    .join('\n');
}

@inject('$peering')
export default class PeeringInfoDialog extends Component {
  state = {
    loading: false,
    loaded: false,
    error: null,
    selectedAsns: [],
    networks: [],
    unfilteredFacilities: [],
    facilities: [],
    unfilteredExchanges: [],
    exchanges: [],
    showExchanges: true,
    showFacilities: true,
    showUrl: true
  };

  componentDidMount() {
    const { isOpen } = this.props;

    if (isOpen) {
      this.fetchDetails();
    }
  }

  componentDidUpdate() {
    const { isOpen } = this.props;
    const { loading, loaded, error } = this.state;

    if (isOpen && !loading && !loaded && !error) {
      this.fetchDetails();
    }
  }

  fetchDetails() {
    const { $peering } = this.props;
    this.setState({ loading: true });

    $peering
      .fetchPeeringNetworkEntities()
      .then((networks) => {
        const facilitiesMap = new Map();
        const exchangesMap = new Map();

        networks.forEach((network) => {
          const { asn } = network;

          network.facilities.forEach((facility) => {
            if (facilitiesMap.has(facility.id)) {
              facilitiesMap.get(facility.id).asns.push(asn);
            } else {
              facility.asns = [asn];
              facilitiesMap.set(facility.id, facility);
            }
          });

          network.exchanges.forEach((exchange) => {
            if (exchangesMap.has(exchange.id)) {
              exchangesMap.get(exchange.id).asns.push(asn);
            } else {
              exchange.asns = [asn];
              exchangesMap.set(exchange.id, exchange);
            }
          });
        });

        const facilities = Array.from(facilitiesMap.values()).sort((a, b) => a.name.localeCompare(b.name));
        const exchanges = Array.from(exchangesMap.values()).sort((a, b) => a.name.localeCompare(b.name));

        this.setState({
          loading: false,
          loaded: true,
          selectedAsns: networks.map((network) => network.asn),
          networks,
          unfilteredFacilities: facilities,
          facilities,
          unfilteredExchanges: exchanges,
          exchanges
        });
      })
      .catch(() => {
        this.setState({ loading: false, error: 'Unable to load peering details.' });
      });
  }

  getText(options = {}) {
    const { html = false } = options;
    const { exchanges, facilities, selectedAsns, showExchanges, showFacilities, showUrl } = this.state;
    const sections = [];

    if (selectedAsns.length === 0) {
      return '';
    }

    if (showUrl) {
      sections.push(
        `PeeringDB record\n--\n${selectedAsns
          .map((asn) => {
            const url = `https://peeringdb.com/asn/${asn}`;
            return html ? `<a href="${url}" target="_blank" rel="noreferrer">${url}</a>` : url;
          })
          .join('\n')}`
      );
    }

    if (showExchanges && exchanges.length > 0) {
      sections.push(
        `Internet exchanges\n--\n${textTable({
          data: exchanges.flatMap((exchange) => {
            const rows = exchange.lans.flatMap((lan) => {
              const twoRows = lan.ipaddr4 && lan.ipaddr6;
              const speed = greekIt(lan.speed * 10 ** 6, { fix: 0, suffix: 'bps' }).displayFull;

              if (twoRows) {
                return [
                  [exchange.name, speed, lan.ipaddr4],
                  ['', '', lan.ipaddr6]
                ];
              }

              return [[exchange.name, speed, lan.ipaddr4 || lan.ipaddr6]];
            });

            return rows;
          })
        })}`
      );
    }

    if (showFacilities && facilities.length > 0) {
      sections.push(
        `PNI-enabled facilities\n--\n${textTable({
          data: facilities.map((facility) => [
            facility.name,
            [facility.city, facility.state].filter(Boolean).join(', '),
            facility.country
          ])
        })}`
      );
    }

    return sections.join('\n\n\n');
  }

  handleSwitchChange(key, value) {
    this.setState({ [key]: value });
  }

  handleSelectedAsnChange(changedAsn, checked) {
    this.setState((prevState) => {
      const { networks, unfilteredExchanges, unfilteredFacilities } = prevState;
      const selectedAsns = networks
        .map((network) => network.asn)
        .filter((asn) => (asn === changedAsn ? checked : prevState.selectedAsns.includes(asn)));
      const exchanges = unfilteredExchanges.filter((exchange) =>
        exchange.asns.some((asn) => selectedAsns.includes(asn))
      );
      const facilities = unfilteredFacilities.filter((facility) =>
        facility.asns.some((asn) => selectedAsns.includes(asn))
      );
      this.setState({ selectedAsns, exchanges, facilities });
    });
  }

  render() {
    const { isOpen, onClose } = this.props;
    const {
      loading,
      loaded,
      error,
      selectedAsns,
      networks,
      exchanges,
      facilities,
      showExchanges,
      showFacilities,
      showUrl
    } = this.state;

    return (
      <Dialog title="My Peering Details" width={900} isOpen={isOpen} onClose={onClose}>
        <Dialog.Body minHeight={400} pt={3}>
          <Suspense loading={loading}>
            {error && <Callout intent="danger" title={error} icon="error" />}
            {!error && (
              <>
                <Box mb={3}>
                  <Grid gridTemplateColumns="repeat(3, 1fr)">
                    <Switch
                      labelElement={<Text fontWeight="heavy">PeeringDB record URL</Text>}
                      checked={showUrl}
                      onChange={(val) => this.handleSwitchChange('showUrl', val)}
                      small
                    />
                    <Switch
                      labelElement={
                        <>
                          <Text fontWeight="heavy">Exchanges</Text>
                          <Text ml={1}>{exchanges.length || 0}</Text>
                        </>
                      }
                      checked={showExchanges}
                      onChange={(val) => this.handleSwitchChange('showExchanges', val)}
                      small
                    />
                    <Switch
                      labelElement={
                        <>
                          <Text fontWeight="heavy">Facilities</Text>
                          <Text ml={1}>{facilities.length || 0}</Text>
                        </>
                      }
                      checked={showFacilities}
                      onChange={(val) => this.handleSwitchChange('showFacilities', val)}
                      small
                    />
                  </Grid>
                  {networks.length > 0 && (
                    <Box mt={2}>
                      <Box fontWeight="medium" mb="4px">
                        Show data from ASNs:
                      </Box>
                      {networks.map((network) => (
                        <Box key={network.asn} mb="2px">
                          <Switch
                            labelElement={
                              <>
                                <Text fontWeight="heavy">AS{network.asn}</Text> - {network.data.name}
                              </>
                            }
                            checked={selectedAsns.includes(network.asn)}
                            onChange={(checked) => this.handleSelectedAsnChange(network.asn, checked)}
                            small
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
                <CodeBlock>
                  {/* eslint-disable-next-line react/no-danger */}
                  <pre dangerouslySetInnerHTML={{ __html: this.getText({ html: true }) }} />
                </CodeBlock>
              </>
            )}
          </Suspense>
        </Dialog.Body>
        <Dialog.Footer>
          {loaded && (
            <CopyToClipboard text={this.getText()}>
              <Button text="Copy to Clipboard" icon={FiCopy} minWidth={160} />
            </CopyToClipboard>
          )}
        </Dialog.Footer>
      </Dialog>
    );
  }
}
