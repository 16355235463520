import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Box, Button, Collapse, Flex, Text, Tag } from 'core/components';
import { Field, formConsumer, Slider, Switch } from 'core/form';
import DimensionSelector from 'app/components/dimensions/DimensionSelector';
import { Timezone, DEFAULT_DATETIME_FORMAT, formatDateTime, getStartAndEndFromLookback } from 'core/util/dateUtils';

import { computed } from 'mobx';
import moment from 'moment';
import { TinyWindowIcon } from '../TinyWindowIcon';

const { VALID_FPA_METRICS } = require('@kentik/ui-shared/fpa/constants');

const FPA_TYPES = {
  fpa: 'Single Window',
  diff_fpa: 'Window Comparison',
  cpd_fpa: 'Change Point Detection'
};

@inject('$auth', '$app', '$explorer', '$devices')
@formConsumer
@observer
class FpaOptions extends Component {
  state = {
    showAdvancedFpaOptions: false,
    collapsedDimensions: {}
  };

  static getDerivedStateFromProps(props, state) {
    const collapsedDimensions = {};
    const { $devices } = props;

    if (!$devices.hasDnsProbe) {
      collapsedDimensions['Layer 7'] = true;
    }

    props.disabledDimensions.forEach((dimension) => {
      if (!dimension.includes('_')) {
        collapsedDimensions[dimension] = true;
      }
    });

    return { collapsedDimensions: { ...state.collapsedDimensions, ...collapsedDimensions } };
  }

  handleToggleAdvancedFpaOptions = () => {
    this.setState(({ showAdvancedFpaOptions }) => ({ showAdvancedFpaOptions: !showAdvancedFpaOptions }));
  };

  handleUseFpaChange = () => {
    const { $auth, handleSubmit, form } = this.props;
    const lookback_seconds = form.getValue('lookback_seconds');

    if (lookback_seconds) {
      const { start, end } = getStartAndEndFromLookback(lookback_seconds);

      form.setValues({
        starting_time: start.format(DEFAULT_DATETIME_FORMAT),
        ending_time: end.format(DEFAULT_DATETIME_FORMAT),
        lookback_seconds: 0
      });
    }

    const { use_fpa, fpa } = form.getValues();

    // when disabling FPA, clear out the FPA form values, and reset to cpd+fpa
    if (use_fpa === false) {
      form.setValue('fpa.type', 'cpd_fpa');
      form.setValue('fpa.dimensions', []);
      form.setValue('fpa.overrideDimensions', false);
    }

    $auth.track('Toggle Probable Cause', {
      use_fpa,
      fpa
    });

    handleSubmit();
  };

  handleDimensionSectionCollapse = (group) => {
    const { collapsedDimensions } = this.state;
    const newDimensions = { ...collapsedDimensions };
    newDimensions[group] = !collapsedDimensions[group];
    this.setState({ collapsedDimensions: newDimensions });
  };

  formatDateTime = (date) => {
    const { form } = this.props;
    const { time_format } = form.getValues();
    const timezone = new Timezone(time_format);
    return formatDateTime(date, DEFAULT_DATETIME_FORMAT, timezone);
  };

  @computed
  get isValidFpaMetric() {
    const { model } = this.props;
    return VALID_FPA_METRICS.includes(model?.get('outsort'));
  }

  render() {
    const { form } = this.props;
    const { showAdvancedFpaOptions, collapsedDimensions } = this.state;

    const use_fpa = form.getValue('use_fpa');
    const fpaType = form.getValue('fpa.type');

    if (!this.isValidFpaMetric) {
      form.setValue('use_fpa', false);
    }

    const isDiffFpa = fpaType === 'diff_fpa';
    const isSqlFpa = ['fpa', 'cpd_fpa', 'diff_fpa'].includes(fpaType);
    const isCpdFpa = fpaType === 'cpd_fpa';

    const windowStartTime = form.getValue('fpa.time.starting_time');
    const windowEndTime = form.getValue('fpa.time.ending_time');
    const comparisonWindowStartTime = form.getValue('fpa.compare_time.starting_time');
    const comparisonWindowEndTime = form.getValue('fpa.compare_time.ending_time');

    return (
      <>
        {!this.isValidFpaMetric && (
          <Text small color="danger" as="div" mb="12px">
            Probable Cause Analysis is only available when the Primary Display & Sort Metric is bits, packets, or flows.
          </Text>
        )}

        <Field
          name="use_fpa"
          className="enable-probable-cause"
          onChange={this.handleUseFpaChange}
          showLabel={false}
          disabled={!this.isValidFpaMetric}
        >
          <Switch switchLabel="Enable Probable Cause Analysis" />
        </Field>

        {use_fpa && (
          <Flex flexDirection="column" gap={2}>
            <Flex flexDirection="column" gap={1}>
              <Text small>
                Analysis Type:{' '}
                <Tag minimal>
                  <Text fontWeight="bold">{FPA_TYPES[fpaType]}</Text>
                </Tag>
              </Text>
              {fpaType === 'fpa' && (
                <Text small muted>
                  Select a single window of time to analyze the data for patterns that may indicate the root cause of
                  traffic fluctuations.
                </Text>
              )}

              {fpaType === 'diff_fpa' && (
                <Text small muted>
                  Select two windows of time to compare and analyze the data for patterns that may indicate the root
                  cause of traffic fluctuations.
                </Text>
              )}

              {fpaType === 'cpd_fpa' && (
                <Text small muted>
                  Detect shifts in data trends or patterns to quickly identify potential root causes affecting system
                  performance.
                </Text>
              )}
            </Flex>

            {!isCpdFpa && (
              <Flex flexDirection="column" gap="4px">
                <Flex alignItems="center" gap="4px">
                  <TinyWindowIcon />
                  <Text small fontWeight="bold">
                    Selected Window
                  </Text>
                </Flex>
                <Text small>
                  {this.formatDateTime(windowStartTime)} - {this.formatDateTime(windowEndTime)}
                </Text>
                <Text small muted>
                  {moment.duration(moment(windowEndTime).diff(moment(windowStartTime))).humanize({ ss: 1 })}
                </Text>
              </Flex>
            )}

            {isDiffFpa && (
              <Flex flexDirection="column" gap="4px">
                <Flex alignItems="center" gap="4px">
                  <TinyWindowIcon comparison />
                  <Text small fontWeight="bold">
                    Comparison Window
                  </Text>
                </Flex>
                <Text small>
                  {this.formatDateTime(comparisonWindowStartTime)} - {this.formatDateTime(comparisonWindowEndTime)}
                </Text>
                <Text small muted>
                  {moment
                    .duration(moment(comparisonWindowEndTime).diff(moment(comparisonWindowStartTime)))
                    .humanize({ ss: 1 })}
                </Text>
              </Flex>
            )}

            {isSqlFpa && (
              <Flex flexDirection="column" gap={2}>
                <Field name="fpa.min_support_pct" small m={0}>
                  <Slider
                    min={0}
                    max={100}
                    stepSize={1}
                    labelStepSize={20}
                    width={300}
                    ml="8px"
                    labelRenderer={(value) => `${value}%`}
                  />
                </Field>
              </Flex>
            )}

            {isCpdFpa && (
              <Flex flexDirection="column" gap={2}>
                <Field name="fpa.top_num_cpds" small m={0}>
                  <Slider min={1} max={15} stepSize={1} labelStepSize={2} width={300} ml="8px" />
                </Field>
                <Field name="fpa.chg_thr" small m={0}>
                  <Slider
                    min={0}
                    max={1}
                    stepSize={0.1}
                    labelStepSize={0.2}
                    width={300}
                    labelRenderer={(value) => `${Math.floor(value * 100)}%`}
                    ml="8px"
                  />
                </Field>
              </Flex>
            )}

            <>
              <Button
                mb={1}
                small
                alignSelf="flex-start"
                onClick={this.handleToggleAdvancedFpaOptions}
                icon={showAdvancedFpaOptions ? 'caret-up' : 'caret-down'}
                text={showAdvancedFpaOptions ? 'Hide Advanced Options' : 'Show Advanced Options'}
              />

              <Collapse isOpen={showAdvancedFpaOptions}>
                <Flex flexDirection="column" gap={2}>
                  <Box flex={1}>
                    <DimensionSelector
                      title="FPA Dimensions"
                      showClearButton
                      fieldName="fpa.dimensions"
                      onCollapse={this.handleDimensionSectionCollapse}
                      collapsedState={collapsedDimensions}
                      showEditButton
                      editButtonText="Edit Dimensions"
                      small
                    />
                  </Box>

                  <Text small muted>
                    Optionally select additional dimensions to include in the analysis.
                  </Text>

                  <Flex flexDirection="column" gap={1}>
                    <Field name="fpa.overrideDimensions" m={0} showLabel={false}>
                      <Switch switchLabel="Only use selected dimensions in analysis" />
                    </Field>
                    <Text small muted>
                      When selected, only the selected dimensions will be used for analysis.
                    </Text>
                  </Flex>

                  {isSqlFpa && (
                    <>
                      <Field name="fpa.viz_perc" small m={0}>
                        <Slider
                          min={0}
                          max={100}
                          stepSize={1}
                          labelStepSize={20}
                          width={300}
                          ml="8px"
                          labelRenderer={(value) => `${value}%`}
                        />
                      </Field>
                      <Field name="fpa.max_itemset_length" small m={0}>
                        <Slider min={0} max={20} stepSize={1} labelStepSize={5} width={300} ml="8px" />
                      </Field>

                      {fpaType === 'cpd_fpa' && (
                        <>
                          <Field name="fpa.window_size" small m={0}>
                            <Slider min={0} max={20} stepSize={1} labelStepSize={5} width={300} ml="8px" />
                          </Field>
                          <Field name="fpa.perc_thr_cpds" small m={0}>
                            <Slider
                              min={0}
                              max={100}
                              stepSize={5}
                              labelStepSize={20}
                              width={300}
                              labelRenderer={(value) => `${value}%`}
                              ml="8px"
                            />
                          </Field>
                        </>
                      )}
                    </>
                  )}
                </Flex>
              </Collapse>
            </>
          </Flex>
        )}
      </>
    );
  }
}

export default FpaOptions;
