import React from 'react';
import moment from 'moment';
import { isEqual } from 'lodash';
import { observer, inject } from 'mobx-react';
import { getDateRangeDisplay, timezone } from 'core/util/dateUtils';
import { BaseAgentRenderer } from 'app/views/synthetics/components/AgentRenderer';
import { Heading, Card, Flex, Grid, Box, Popover, Text, Select, EmptyState, Icon, Highcharts } from 'core/components';
import { formatNumber } from 'app/util/utils';
import { healthToIntent, getTimeRangeLookbackOptions } from 'app/views/synthetics/utils/syntheticsUtils';
import { LOCATION_TYPES, UNKNOWN, getAlarmSummaries } from './summaryUtils';
import LoadingPanel from '../LoadingPanel';

@inject('$syn', '$colors')
@observer
export default class SummaryPanel extends React.Component {
  state = {
    alarmResults: null, // raw alarm results captured from props --- used for comparison when deriving state
    selectedLocationType: LOCATION_TYPES.COUNTRY, // country, region, city
    byAgent: [], // a list of { name: <agentId>, value: { warning: <count>, critical: <count>, model: <agentModel>, total: <count> } }
    byMetric: [], // a list of { name: <metricName>, value: <count> }
    bySeverity: [], // a list of { name: <severity>, value: <count> }
    byCity: [], // a list of { name: <city>, value: <count> }
    byRegion: [], // a list of { name: <region>, value: <count> }
    byCountry: [], // a list of { name: <country>, value: <count> }
    total: 0 // the total number of alarms
  };

  static getDerivedStateFromProps(props, state) {
    const { alarms, $syn } = props;
    const { alarmResults } = state;

    if (alarms !== null && !isEqual(alarms, alarmResults)) {
      // process the alarms into groups by agent, metric, severity, and location
      return {
        ...getAlarmSummaries({ $syn, alarms: alarms?.alarms || [] }),
        alarmResults: alarms // capture the current alarm results for comparison
      };
    }

    return null;
  }

  renderAgent = (model) => {
    const { $syn } = this.props;
    const agentOption = $syn.agents.getOptionsForAgents(model);

    if (agentOption?.label) {
      return (
        <BaseAgentRenderer {...agentOption} small>
          {({ icon, label }) => (
            <Flex alignItems="center" gap="4px">
              {icon}
              {label}
            </Flex>
          )}
        </BaseAgentRenderer>
      );
    }

    return UNKNOWN;
  };

  get selectedDateInterval() {
    const {
      test: { results },
      startDate,
      endDate,
      lookbackSeconds
    } = this.props;
    // check the start/end date and lookback coming in via props --- this is what bgp test results uses
    // all other test types will have their start/end date and lookback hung off the results state
    const selectedStartDate = startDate || results.actualStartDate;
    const selectedEndDate = endDate || results.actualEndDate;
    // if we don't also validate that the lookback coming in is not 0, then bgp results will inaccurately fall back to the lookback in the test results
    // since bgp test results do not use this, the value will always be the default value (3600)
    const selectedLookbackSeconds = lookbackSeconds !== 0 && (lookbackSeconds || results.lookbackSeconds);

    if (selectedLookbackSeconds) {
      // if we have a lookback, find a matching entry in the lookback options and display the label
      const lookbackOption = getTimeRangeLookbackOptions().find((option) => option.value === selectedLookbackSeconds);

      if (lookbackOption) {
        return <Text>{lookbackOption.label}</Text>;
      }
    }

    if (selectedStartDate && selectedEndDate) {
      const dStartDate = moment.unix(selectedStartDate);
      const dEndDate = moment.unix(selectedEndDate);

      if (dStartDate.isSame(dEndDate, 'day')) {
        // if the start and end dates are the same day, display a date range just like we use in the DatePicker value renderer
        // this will be the month, day, start hour/min, end hour/min, and timezone
        return (
          <Text>
            {getDateRangeDisplay(dStartDate, dEndDate)} ({timezone.value})
          </Text>
        );
      }

      return (
        // the range is wider, just show the start and end month and day
        <Text>
          {dStartDate.format('MMM D')} to {dEndDate.format('MMM D')}
        </Text>
      );
    }

    return null;
  }

  renderTopAlertSources() {
    const { byAgent } = this.state;
    const alertSources = byAgent.slice(0, 3);

    return (
      <Grid alignItems="center" gridTemplateColumns="auto max-content">
        {alertSources.map(({ name, value }, index) => {
          const { warning, critical } = value;

          return (
            <React.Fragment key={name}>
              <Flex alignItems="center" gap={1}>
                <Box>{index + 1}.</Box>
                {this.renderAgent(value.model)}
              </Flex>
              <Flex alignItems="center" justifyContent="end" width="100%">
                {critical > 0 && this.renderSeverityCount(critical, 'danger')}
                {warning > 0 && this.renderSeverityCount(warning, 'warning')}
              </Flex>
            </React.Fragment>
          );
        })}
      </Grid>
    );
  }

  renderTopAlertTypes(showAll = false, truncate = true) {
    const { $colors } = this.props;
    const { byMetric } = this.state;
    let displayedAlertTypes = byMetric;
    let otherCount = 0;
    const cutoffLength = 10;

    if (!showAll) {
      displayedAlertTypes = byMetric.slice(0, 3);
    } else if (byMetric.length > cutoffLength) {
      displayedAlertTypes = byMetric.slice(0, cutoffLength);
      otherCount = byMetric.slice(cutoffLength).reduce((acc, { value }) => acc + value, 0);
    }

    return (
      <Grid gridTemplateColumns="auto max-content" alignItems="center">
        {displayedAlertTypes.map(({ name, value }, index) => {
          const formattedName = this.stringToNiceFormat(name);
          const nameElement = (
            <Text as="div" title={formattedName} ellipsis={truncate}>
              {formattedName}
            </Text>
          );

          return (
            <React.Fragment key={name}>
              <Flex alignItems="center" gap={1} maxWidth={truncate ? '125px' : '325px'}>
                <Box>{index + 1}.</Box>
                <Icon color={$colors.qualitativeColors[index]} icon="symbol-square" />
                {nameElement}
              </Flex>
              <Flex justifyContent="end" ml={2}>
                {formatNumber(value, { fix: value < 999 ? 0 : 1, scaleMax: 0.999 }).display}
              </Flex>
            </React.Fragment>
          );
        })}

        {showAll && otherCount > 0 && (
          <>
            <Box>Other</Box>
            <Box justifySelf="end">
              {formatNumber(otherCount, { fix: otherCount < 999 ? 0 : 1, scaleMax: 0.999 }).display}
            </Box>
          </>
        )}
      </Grid>
    );
  }

  renderLocationDropdown() {
    const { selectedLocationType } = this.state;

    return (
      <Select
        options={[
          { value: LOCATION_TYPES.CITY, label: 'City' },
          { value: LOCATION_TYPES.REGION, label: 'Region' },
          { value: LOCATION_TYPES.COUNTRY, label: 'Country' }
        ]}
        values={selectedLocationType}
        onChange={this.handleLocationTypeChange}
        // eslint-disable-next-line react/no-unstable-nested-components
        valueRenderer={({ label }) => (
          <Heading level={5} mb="1px">
            {label}
          </Heading>
        )}
        menuWidth={105}
        minimal
      />
    );
  }

  renderTopAlertSourcesByLocation() {
    const { total } = this.state;
    const topLocations = this.locationSummary.slice(0, 3);

    return (
      <Grid alignItems="center" gridTemplateColumns="auto max-content">
        {topLocations.map(({ name, value }, index) => (
          <React.Fragment key={name}>
            <Flex alignItems="center" gap={1}>
              <Box>{index + 1}.</Box>
              <Box>{name}</Box>
            </Flex>
            <Flex ml={2} justifyContent="end">
              {((value / total) * 100).toFixed(2)}%
            </Flex>
          </React.Fragment>
        ))}
      </Grid>
    );
  }

  handleLocationTypeChange = (selectedLocationType) => {
    this.setState({ selectedLocationType });
  };

  stringToNiceFormat(str) {
    return str
      .replace(/_/g, ' ')
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  renderSeverityCount(count, color) {
    return (
      <Flex alignItems="center">
        <Box ml="15px" mr="5px">
          <Icon color={color} icon="symbol-circle" />
        </Box>
        {count}
      </Flex>
    );
  }

  get isLoading() {
    const { test, alarms } = this.props;
    const { loadingAlarmResults } = test.results;

    return loadingAlarmResults || alarms === null;
  }

  get emptyState() {
    const title = 'No Alerts';
    const description = 'There were no issues during this time range';
    const icon = <Icon color="success" icon="thumbs-up" iconSize={52} />;

    return <EmptyState icon={icon} title={title} description={description} />;
  }

  get alarmResultsIsEmpty() {
    const { total } = this.state;

    return total === 0;
  }

  get chartConfig() {
    const { byMetric } = this.state;

    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        spacing: [-10, -10, -10, -10]
      },
      plotOptions: {
        series: {
          states: { hover: { enabled: false } },
          animation: false
        },
        pie: {
          allowPointSelect: false,
          size: '100%',
          innerSize: '60%', // make it a donut
          dataLabels: {
            enabled: false
          }
        }
      },
      tooltip: { enabled: false },
      series: [
        {
          name: 'Metric',
          data: byMetric.slice(0, 10).map(({ name, value }) => [this.stringToNiceFormat(name), value])
        }
      ]
    };
  }

  get locationSummary() {
    const { selectedLocationType, byCity, byRegion, byCountry } = this.state;

    switch (selectedLocationType) {
      case LOCATION_TYPES.CITY:
        return byCity;
      case LOCATION_TYPES.REGION:
        return byRegion;
      default:
        return byCountry;
    }
  }

  get canRenderBySource() {
    const { test } = this.props;
    const { byAgent } = this.state;

    // we will show summary by agent and agent location when the test is configured for per agent alerting
    // or when configured for per test alerting and the number of agent source summaries is greater than 1
    // in this case, we know that there is something other than 'Unknown' to render - the result of editing between per-(agent|test)
    return test.isPerAgentAlerting || (test.isPerTestAlerting && byAgent.length > 1);
  }

  render() {
    const { test, $colors } = this.props;
    const { bySeverity } = this.state;
    const { displayOptions } = test.results;
    const { allowSummary } = displayOptions;
    const { isBgp } = test;

    if (allowSummary) {
      return (
        <>
          <Flex flexDirection="column">
            <Heading level={6}>Summary</Heading>
            {this.selectedDateInterval}
          </Flex>
          <LoadingPanel
            empty={this.alarmResultsIsEmpty}
            emptyFallback={this.emptyState}
            loading={this.isLoading}
            test={test}
            testResults={test.results}
          >
            <Card display="flex" width="100%" p={30} mb={2} justifyContent="space-evenly">
              <Box>
                <Heading level={5} mb={20}>
                  Alerts Overview
                </Heading>
                <Flex gap={2}>
                  {bySeverity.map(({ name, value }) => (
                    <Box key={name}>
                      <Flex alignItems="center" justifyContent="start" mb={1}>
                        <Icon color={healthToIntent({ health: name })} icon="symbol-circle" mr="5px" />
                        <Box textTransform="uppercase">{name}</Box>
                      </Flex>
                      <Heading fontWeight={500} level={1}>
                        {value}
                      </Heading>
                    </Box>
                  ))}
                </Flex>
              </Box>

              <Box>
                <Heading level={5} mb={20}>
                  Top Alerts By Metric
                </Heading>
                <Flex>
                  <Popover
                    content={
                      <Box p={1} width="250px">
                        {this.renderTopAlertTypes(true, false)}
                      </Box>
                    }
                    interactionKind="hover"
                    position="bottom"
                    minimal={false}
                  >
                    <Highcharts options={this.chartConfig} colors={$colors.qualitativeColors} height={75} width={75} />
                  </Popover>
                  <Box ml={2}>{this.renderTopAlertTypes()}</Box>
                </Flex>
              </Box>
              {!isBgp && this.canRenderBySource && (
                <>
                  <Box>
                    <Heading level={5} mb={20}>
                      Top Alerts By Source
                    </Heading>
                    <Box>{this.renderTopAlertSources()}</Box>
                  </Box>

                  <Box>
                    <Flex>
                      <Heading level={5} mb={20}>
                        Alert Sources By
                      </Heading>
                      <Box mt="-5px" ml="2px">
                        {this.renderLocationDropdown()}
                      </Box>
                    </Flex>
                    <Flex flexDirection="column">{this.renderTopAlertSourcesByLocation()}</Flex>
                  </Box>
                </>
              )}
            </Card>
          </LoadingPanel>
        </>
      );
    }

    return null;
  }
}
