import $dictionary from 'app/stores/$dictionary';
import { isIpValid } from 'core/util/ip';

let ipParser;
function initializeIpParser() {
  ipParser = $dictionary.get('queryFilters.metricToFilterParsers.IP_src[0]');
}

export function getIPSortValue(value) {
  if (value === undefined || value === null) {
    return -1;
  }

  if (!ipParser) {
    initializeIpParser();
  }

  if (ipParser) {
    const matches = new RegExp(ipParser.pattern).exec(value);
    if (matches) {
      value = matches[matches.length - 1];
    }
  }
  const ip = isIpValid(value, { returnAddress: true });
  if (ip) {
    return parseInt(ip.bigInteger().toString());
  }

  return value;
}
