import React, { Component } from 'react';
import { Box, ThemedImage } from 'core/components';
import { withRouter } from 'react-router-dom';
import Page from './page/Page';
import NoFlowCallout from './NoFlowCallout';

class NoFlowOverlay extends Component {
  render() {
    const { title, imageSrc, darkImageSrc } = this.props;

    return (
      <Page title={title}>
        <Box position="relative">
          <NoFlowCallout position="absolute" top="30px" left="calc(50% - 400px)" zIndex={2} width={800} elevation={4} />
          <Box opacity={0.25} zIndex={1}>
            <ThemedImage src={imageSrc} darkSrc={darkImageSrc} />
          </Box>
        </Box>
      </Page>
    );
  }
}

export default withRouter(NoFlowOverlay);
