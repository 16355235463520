export default function processLargeArrayAsync(array, processFn, options = {}) {
  return new Promise((resolve) => {
    const { maxTimePerChunk = 20, context = this, timeoutDuration = 1 } = options;
    let index = 0;

    function doChunk() {
      const startTime = Date.now();
      while (index < array.length && Date.now() - startTime <= maxTimePerChunk) {
        processFn.call(context, array[index], index, array);
        index += 1;
      }
      if (index < array.length) {
        setTimeout(doChunk, timeoutDuration);
      } else {
        resolve();
      }
    }

    doChunk();
  });
}
