import React, { Component } from 'react';
import { Classes } from '@blueprintjs/core';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { AutoSizer, List } from 'react-virtualized';
import classNames from 'classnames';
import { Flex, Box, EmptyState } from 'core/components';
import Row from './Row';

@observer
export default class VirtualizedList extends Component {
  static defaultProps = {
    minimal: false,
    rowHeight: 52,
    breakpoint: 1024,
    selectOnRowClick: false
  };

  componentDidUpdate(prevProps) {
    const { collection } = this.props;
    if (collection !== prevProps.collection) {
      this.updateGrid();
    }
  }

  listRefHandler = (ref) => {
    if (ref) {
      this.listRef = ref;
    }
  };

  @action
  updateGrid = () => {
    if (this.listRef && this.listRef.Grid) {
      this.listRef.recomputeRowHeights();
      this.listRef.forceUpdateGrid();
    }
  };

  getRowHeight = ({ index }) => {
    const { collection, rowHeight } = this.props;

    if (typeof rowHeight === 'number') {
      return rowHeight;
    }

    const model = collection.at(index);
    return rowHeight({ index, model }, this);
  };

  renderEmpty() {
    const { emptyText, emptyState } = this.props;
    return (
      emptyState || (
        <Flex className="tr empty">
          <Box flex={1} p={3}>
            <EmptyState title={emptyText || 'No Results'} icon="inbox" />
          </Box>
        </Flex>
      )
    );
  }

  getModel = (collection, index) => {
    if (collection.models) {
      return index < collection.size ? collection.at(index) : null;
    }

    return index < collection.length ? collection[index] : null;
  };

  renderRow = ({ index, key, style }) => {
    const { collection, showTotalRow } = this.props;
    const model = this.getModel(collection, index);

    if (!model) {
      if (showTotalRow) {
        return this.renderTotals({ key, style });
      }
      return null;
    }

    return <Row {...this.props} key={key} model={model} style={style} virtualized />;
  };

  renderBody() {
    const { bodyStyle, collection, flexed, className, showTotalRow } = this.props;
    const style = Object.assign({}, flexed ? { flex: 1 } : {}, bodyStyle);

    if (!collection) {
      return this.renderEmpty();
    }

    const fullSize = collection.models ? collection.size : collection.length;
    let rowCount = fullSize;
    if (showTotalRow) {
      rowCount += 1;
    }

    return (
      <div className={classNames('tbody', className)} style={style}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              height={height}
              noRowsRenderer={() => this.renderEmpty()}
              rowCount={rowCount}
              rowHeight={this.getRowHeight}
              rowRenderer={this.renderRow}
              ref={this.listRefHandler}
              width={width}
            />
          )}
        </AutoSizer>
      </div>
    );
  }

  render() {
    const { className, flexed, minimal, selectOnRowClick, onRowClick, style: styleProps } = this.props;

    const tableClassName = classNames(Classes.HTML_TABLE, className, {
      [Classes.INTERACTIVE]: !minimal && (selectOnRowClick || onRowClick)
    });

    const style = Object.assign({}, flexed ? { display: 'flex', flexDirection: 'column', flex: 1 } : {}, styleProps);

    return (
      <div style={style} className={tableClassName}>
        {this.renderBody()}
      </div>
    );
  }
}
