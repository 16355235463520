import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Card, Flex, Heading, Link, Spinner, Suspense, Tag, Text } from 'core/components';
import { getTestTypeLabel, healthRenderer } from 'app/views/synthetics/utils/syntheticsUtils';

@inject('$app', '$syn')
@observer
class RecentlyAddedTests extends Component {
  renderContent() {
    const { $app, $syn, loading } = this.props;
    const hasTests = $syn.tests.size > 0;

    return (
      <Suspense loading={loading} fallback={<Spinner intent="primary" my={4} size={36} />}>
        {hasTests && (
          <Flex flexDirection={$app.isExport ? 'row' : 'column'} justifyContent="space-between">
            {$syn.tests.recentlyAddedTests.length === 0 && (
              <Box pt={2} pb={2}>
                <Text as="div" muted small>
                  No tests added in last 7 days
                </Text>
              </Box>
            )}
            {$syn.tests.recentlyAddedTests.slice(0, 10).map((model, index) => (
              <Flex
                alignItems="center"
                key={model.id}
                justifyContent="space-between"
                pt={1}
                pb={1}
                borderTop={index === 0 ? 'none' : 'thinLight'}
              >
                <Flex flex={1} alignItems="center">
                  <Box width="65px">{healthRenderer(model)}</Box>
                  <Link flex={1} to={`/v4/synthetics/tests/${model.id}/results`} small>
                    {model.get('display_name') || `Test ${model.id}`}
                  </Link>
                </Flex>
                <Tag minimal small ml={1}>
                  {getTestTypeLabel(model.get('test_type'))}
                </Tag>
              </Flex>
            ))}
          </Flex>
        )}
      </Suspense>
    );
  }

  render() {
    const { $app, $syn, loading, isWidgetView, ...rest } = this.props;

    return isWidgetView ? (
      <Box pt={1} pb={2} px={2}>
        {this.renderContent()}
      </Box>
    ) : (
      <Card p={2} mb={3} className="avoid-break" {...rest}>
        <Heading level={5} fontWeight="heavy" mb={2}>
          Recently Added Tests
        </Heading>
        {this.renderContent()}
      </Card>
    );
  }
}

export default RecentlyAddedTests;
