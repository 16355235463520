import moment from 'moment';
import { arrayToCsv } from 'app/util/arrayToCsv';
import Model from 'core/model/Model';
import api from 'core/util/api';
import { showSuccessToast } from 'core/components';
import Socket from 'core/util/Socket';

class PromptToQueryModel extends Model {
  socket;

  get urlRoot() {
    return '/api/ui/journeys/prompttoquery';
  }

  get defaults() {
    return {
      prompt: '',
      query: {},
      app_protocol: 16
    };
  }

  setDataviewCsv(dataview) {
    const numRows = 2 * (this.get('query.topx') || 10);
    const fullCsv = dataview?.queryBuckets?.activeBuckets?.[0]?.csv;
    const trimmedCsv = fullCsv.split(/\r?\n/).slice(0, numRows).join('\n');
    this.set('query_result_csv', trimmedCsv);
  }

  setNmsCsv(resultCollection, query) {
    let columns = query?.kmetrics?.dimensions || [];
    const numRows = 2 * (query?.kmetrics?.includeTimeseries || 10);
    const metricColumns = Object.keys(query?.kmetrics?.rollups || {});
    columns = columns.concat(metricColumns);
    const rows = resultCollection.toJS();
    const csvString = arrayToCsv(rows.slice(0, numRows), columns);
    this.set('query_result_csv', csvString);
  }

  restore(options = {}) {
    // note: toast false by default
    const { toast = false } = options;
    this.requestStatus = 'updating';
    return api.put(`${this.urlRoot}/restore/${this.id}`).then(
      (success) => {
        if (toast) {
          showSuccessToast(this.messages.restore);
        }
        return success;
      },
      (error) => {
        this.error = { label: 'updating' };
        this.requestStatus = null;
        throw error;
      }
    );
  }

  onReceiveLiveSynthResults = (data) => {
    const {
      id,
      result: { results }
    } = data;

    const query = this.get('query');
    if (query.result[id]) {
      const newQuery = structuredClone(query);
      newQuery.result[id].ping = results[0]?.ping;
      this.set('query', newQuery);
    }
  };

  subscribe(id, agent_id) {
    this.socket = new Socket({
      outType: 'subscribeLiveSynth',
      inType: `liveSynthResults-${id}`,
      frequency: 60,
      delaySend: true,
      listenToBroadcast: true,
      onSuccess: this.onReceiveLiveSynthResults,
      onError(err) {
        console.warn('Received Journeys Subscription Socket Error', err);
      },
      onReconnect: () => {
        this.socket.setOutType('subscribeLiveSynth');
        this.socket.send({ id, agent_id });
      }
    });

    this.socket.send({ id, agent_id });
  }

  unsubscribe(id) {
    this.socket?.setOutType('unsubscribeLiveSynth');
    this.socket?.send({ id });
  }

  get messages() {
    return {
      create: 'Prompt was added successfully',
      update: 'Thank you for your feedback!',
      destroy: 'Prompt was removed successfully',
      restore: 'Prompt was restored successfully'
    };
  }

  deserialize(data) {
    const { app_protocol, query, edate } = data;
    let lookback;
    if (app_protocol === 16) {
      // metrics explorer
      lookback = query.kmetrics.range.lookback;
    } else if (app_protocol === 0) {
      // data explorer
      lookback = query.lookback_seconds * 1000;
    }

    if (lookback) {
      const lookback_millis = moment.duration(lookback).asMilliseconds();
      const edateTime = moment.utc(edate).valueOf();
      if (edateTime < Date.now() - lookback_millis / 2) {
        if (app_protocol === 16) {
          query.kmetrics.range.end = edateTime;
          query.kmetrics.range.start = edateTime - lookback_millis;
          query.kmetrics.range.lookback = null;
        } else if (app_protocol === 0) {
          query.ending_time = edateTime;
          query.starting_time = edateTime - lookback_millis;
          query.lookback_seconds = 0;
          query.update_frequency = 0;
        }
      }
    }

    return super.deserialize(data);
  }
}

export default PromptToQueryModel;
