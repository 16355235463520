import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withSize } from 'react-sizeme';
import { Box, Flex, Text } from 'core/components';
import { BaseAgentRenderer } from 'app/views/synthetics/components/AgentRenderer';
import DensityGrid from 'app/views/synthetics/components/DensityGrid';

@withSize()
@inject('$syn')
@observer
class HighDensityItems extends Component {
  onGridCellMouseOver = (model) => {
    const { unhealthyCollection } = this.props;
    const unhealthyCollectionModel = unhealthyCollection.models.find((cModel) => cModel.id === model.id);
    if (unhealthyCollectionModel) {
      unhealthyCollectionModel.select();
    }
  };

  render() {
    const { $syn, size, test, resultsCollection, lookbackSeconds, startDate, endDate, isAggregated } = this.props;
    const agents = test.get('config.agents');

    return (
      <Box>
        {agents.map((agentId) => {
          const agent = $syn.agents.getOptionsForAgents($syn.agents.get(agentId));
          const isAgent = !!agent.value;
          const models = resultsCollection.models.filter((model) => model.get('agent_id') === agentId);
          const showGrid = isAgent && (agent.agent_type === 'global' || !agent.status.offline);

          return (
            <Box key={agentId} mb={2}>
              <Box mb={1}>
                <BaseAgentRenderer {...agent} showLocationInfo small>
                  {({ icon, label, locationInfo }) => (
                    <Flex alignItems="center" gap="4px">
                      {icon}
                      {label}
                      {locationInfo}
                    </Flex>
                  )}
                </BaseAgentRenderer>
              </Box>
              <Box position="relative">
                {!showGrid && (
                  <Text small>This agent is currently offline and is not testing against selected targets.</Text>
                )}
                {showGrid && (
                  <DensityGrid
                    test={test}
                    onCellMouseOver={this.onGridCellMouseOver}
                    lookbackSeconds={lookbackSeconds}
                    startDate={startDate}
                    endDate={endDate}
                    gridWidth={size.width}
                    models={models}
                    keyId={agentId}
                    isAggregated={isAggregated}
                  />
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  }
}

export default HighDensityItems;
