import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Card, Flex, Heading } from 'core/components';
import VerticalDetailTabs from 'app/components/detailTabs/VerticalDetailTabs';
import InsightsMenu from 'app/components/navbar/InsightsMenu';
import Page from 'app/components/page/Page';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import FavoriteButton from 'app/views/core/FavoriteButton';
import IpDetailsInfoBlock from './IpDetailsInfoBlock';
import AggregateViewHeader from '../aggregate/AggregateViewHeader';
import parentLinks from '../NetworkExplorerParentLink';
import NetworkExplorerTabs from '../NetworkExplorerTabs';

@inject('$tabs')
@observer
class IPDetails extends Component {
  constructor(props) {
    super(props);

    const { $tabs } = props;
    const tabs = $tabs.getTabs();

    this.state.tabBlacklist = [tabs.IPS.id];
  }

  state = {
    filters: [],
    lookback_seconds: 86400,
    aggregateType: 'p95th_bits_per_sec'
  };

  get ip_address() {
    const { match } = this.props;
    return match.params.ip_address;
  }

  get cidr() {
    const { match } = this.props;
    return match.params.cidr;
  }

  get full_ip_address() {
    const { ip_address, cidr } = this;
    return ip_address + (cidr ? `/${cidr}` : '');
  }

  get queryOverrides() {
    const { lookback_seconds, aggregateType } = this.state;
    return {
      lookback_seconds,
      aggregateTypes: [aggregateType]
    };
  }

  get filters() {
    const { filters } = this.state;
    const detailFilter = {
      filterField: 'ip',
      filterValue: this.full_ip_address
    };

    return filters.concat(detailFilter);
  }

  handleFiltersApply = (filters) => {
    this.setState({ filters });
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds });
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType });
  };

  render() {
    const { match } = this.props;
    const { aggregateType, filters, lookback_seconds, tabBlacklist } = this.state;

    return (
      <Page
        title={this.full_ip_address}
        parentLinks={[...parentLinks, { link: '/v4/core/quick-views/ip', label: 'IP Addresses' }]}
        insightsMenu={<InsightsMenu query="fetchIpInsights" params={this.ip_address} />}
        scrolls
        showExport
      >
        <Flex alignItems="center" mb={1}>
          <Flex flex="1 1 auto" gap="4px" mr={2}>
            <FavoriteButton type="page" id={match.url} name={this.full_ip_address} metadata={{ resultType: 'ip' }} />
            <Heading level={1} mb={0}>
              {this.full_ip_address}
            </Heading>
          </Flex>
        </Flex>

        <NetworkExplorerTabs
          trafficTabContent={
            <>
              <AggregateViewHeader
                aggregateType={aggregateType}
                filters={filters}
                lookbackSeconds={lookback_seconds}
                onFiltersChange={this.handleFiltersApply}
                onAggregateChange={this.handleAggregationChange}
                onMetricsChange={this.handleAggregationChange}
                onTimeRangeChange={this.handleLookbackChange}
              />

              <Card className="break-after" mb={2}>
                <TabbedChart overrides={this.queryOverrides} simpleFilters={this.filters} />
              </Card>

              <VerticalDetailTabs
                blacklist={tabBlacklist}
                queryOverrides={this.queryOverrides}
                simpleFilters={this.filters}
              />
            </>
          }
          moreInfoTabContent={<IpDetailsInfoBlock ip_address={this.ip_address} cidr={this.cidr} />}
        />
      </Page>
    );
  }
}

export default IPDetails;
