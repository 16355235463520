import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { Box, Highcharts, Flex, Text, Spinner, Suspense } from 'core/components';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import ModuleLinkButton from '../../../landing/ModuleLinkButton';

function generateConfig(summary) {
  const data = [
    ['Healthy', summary.interfaces - summary.interfacesWarning - summary.interfacesCritical],
    ['Warning', summary.interfacesWarning],
    ['Critical', summary.interfacesCritical]
  ];

  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    plotOptions: {
      series: {
        states: { hover: { enabled: false } }
      },
      pie: {
        allowPointSelect: false,
        size: '100%',
        innerSize: '60%', // make it a donut
        dataLabels: {
          enabled: false
        }
      }
    },
    tooltip: { enabled: false },
    series: [
      {
        type: 'pie',
        data
      }
    ]
  };
}

@inject('$capacity')
@withTheme
@observer
class CapacityWidget extends Component {
  state = {
    capacitySummary: {},
    fetchingCapacitySummary: true
  };

  componentDidMount() {
    const { $capacity } = this.props;

    $capacity.fetchCapacityPlansOverview().then((res) => {
      this.setState({
        capacitySummary: res,
        fetchingCapacitySummary: false
      });
    });
  }

  renderGauge() {
    const { capacitySummary } = this.state;
    const { theme } = this.props;

    if (capacitySummary.interfacesCritical === 0 && capacitySummary.interfacesWarning === 0) {
      return null;
    }

    const [healthyColor, warningColor, criticalColor] = theme.colors.pie;

    return (
      <Box width={150} mt={-2} mx={-2} mb={-3}>
        <Highcharts
          options={generateConfig(capacitySummary)}
          colors={[healthyColor, warningColor, criticalColor]}
          height={120}
        />
      </Box>
    );
  }

  renderData() {
    const { theme } = this.props;
    const [healthyColor, warningColor, , criticalColor] = theme.colors.pie;
    const { capacitySummary } = this.state;
    const plans = capacitySummary.plans > 0;

    if (!plans) {
      return (
        <Text color="muted" as="div" flex={1}>
          You are not currently monitoring interface utilization.
        </Text>
      );
    }

    return (
      <Box flex={1}>
        <Flex alignItems="center">
          {this.renderGauge()}
          <Box pl={2}>
            {capacitySummary.interfacesCritical > 0 && (
              <Flex alignItems="flex-start" mb={1}>
                <Box bg={criticalColor} borderRadius={4} mt="6px" width={16} height={5} mr={1} />
                <Text as="div">
                  <Text fontWeight="bold">{capacitySummary.interfacesCritical}</Text> critical
                </Text>
              </Flex>
            )}
            {capacitySummary.interfacesWarning > 0 && (
              <Flex alignItems="flex-start" mb={1}>
                <Box bg={warningColor} borderRadius={4} mt="6px" width={16} height={5} mr={1} />
                <Text as="div">
                  <Text fontWeight="bold">{capacitySummary.interfacesWarning}</Text> warning
                </Text>
              </Flex>
            )}

            <Flex alignItems="flex-start">
              <Box bg={healthyColor} borderRadius={4} mt="6px" width={16} height={5} mr={1} />
              <Text as="div">
                <Text fontWeight="bold">
                  {capacitySummary.interfaces - capacitySummary.interfacesCritical - capacitySummary.interfacesWarning}
                </Text>{' '}
                healthy
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
    );
  }

  render() {
    const { canCustomize, onRemove } = this.props;
    const { fetchingCapacitySummary } = this.state;
    const title = 'Capacity Planning';
    const route = '/v4/core/capacity';

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        onRemove={onRemove}
        title={title}
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Suspense
          loading={fetchingCapacitySummary}
          fallback={
            <Box flex={1} pt={2}>
              <Spinner size={24} />
            </Box>
          }
        >
          {this.renderData()}
        </Suspense>
        <ModuleLinkButton mt={2} to={route} text={title} />
      </WidgetFrame>
    );
  }
}

export default CapacityWidget;
