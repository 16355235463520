import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTheme } from 'styled-components';
import Validator from 'validatorjs';
import copy from 'copy-to-clipboard';

import { Box, Button, Callout, Dialog, EmptyState, Flex, Heading, Icon, Link, Text } from 'core/components';
import { Field, InputGroup, TextArea } from 'core/form';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';
import FormComponent from 'core/form/components/FormComponent';
import FormActions from 'core/form/components/FormActions';
import sharedLinksStore from 'app/stores/sharedLinks/$sharedLinks';
import { showErrorToast, showSuccessToast } from 'core/components/toast';

import { getHashForQueries } from 'app/stores/query/urlHash';
import QueryModel from 'app/stores/query/QueryModel';
import DataViewModel from 'app/stores/query/DataViewModel';

import { BsCaretRightFill } from 'react-icons/bs';
import { FiCopy, FiInfo } from 'react-icons/fi';
import { encodeQuery } from 'app/util/sharedLink';

Validator.registerAsync('public_hash_available', function validateAvailability(value, args, attribute, passes) {
  const { initialValue, isEditAction } = this?.validator?.input;

  // when editing a field, allow the user to keep the existing hash
  if (isEditAction && value === initialValue) {
    passes();
    return;
  }

  sharedLinksStore
    .checkAvailability(value)
    .then(({ isAvailable }) => {
      if (isAvailable) {
        passes();
      } else {
        passes(false, 'This link (hash) has already been taken.');
      }
    })
    .catch(() => {
      passes(false, 'This link (hash) has already been taken.');
    });
});

@inject('$auth', '$dashboards', '$dashboard', '$sharedLinks', '$users')
@withTheme
export default class SharePublicViewDialog extends Component {
  static defaultProps = {
    link_type: 'data-explorer',
    editing: false,
    showManagePublicShares: true,
    defaultName: '',
    defaultDescription: ''
  };

  componentDidMount() {
    const { $users } = this.props;

    if (!$users.collection.hasFetched) {
      $users.collection.fetch();
    }
  }

  onSave = (formValues) => {
    const {
      link_type,

      // only present for DataExplorer and SavedViews
      dataview,

      // only present for Synthetics
      test_id,

      start_time,
      end_time,

      // only present for Dashboards
      dashboard,

      // etc
      $dashboards,
      $sharedLinks,
      onClose = () => {}
    } = this.props;

    let promise = Promise.resolve();
    let queries = [];
    let newDataView = null;
    let dashboardClone;
    let parametric_fields;
    let query;
    let dashSynthTestIds;
    let dashboardModel;

    if (dataview) {
      // create a copy of current DataViewModel with adjusted lookback_seconds
      newDataView = new DataViewModel();

      query = QueryModel.create(dataview.queryBuckets.selectedQuery.serialize()).serialize();
      query = $sharedLinks.removeLookbacksFromQuery(query);

      newDataView.setQuery(query);
      promise = getHashForQueries(newDataView.queryBuckets.serialize(), { persist: true });
    } else if (dashboard) {
      dashSynthTestIds = [];
      if (dashboard.get('parametric')) {
        parametric_fields = dashboard.get('parametric_fields');
      }

      dashboardModel = { ...dashboard.get(), items: dashboard.get('items').serialize() };
      // NOTE: dashboardClone will not have any "items" of its own because they do not get serialized
      dashboardClone = $dashboards.collection.build(dashboard.get());
      promise = dashboardClone.fetch();
    }

    return promise.then((promiseResult) => {
      let hash;
      if (link_type === $sharedLinks.types.dataExplorer || link_type === $sharedLinks.types.savedView) {
        hash = promiseResult;
        newDataView.queryBuckets.models.forEach((x) => (queries = queries.concat(x.encodedQueries)));
      } else if (link_type === $sharedLinks.types.synthetics) {
        // currently nothing else required for synthetics (no dataviews)
      } else if (link_type === $sharedLinks.types.dashboards) {
        query = $sharedLinks.removeLookbacksFromQuery(promiseResult.query);
        queries.push(encodeQuery(query));
        dashboard.get('items').each((item) => {
          if (item.get('panel_type') === 'synth_test') {
            dashSynthTestIds.push(item.dataview.query.synth_test_id);
            dashSynthTestIds = dashSynthTestIds.concat(item.dataview.matchingTestsByLabelIds);
          } else {
            const componentDvms = Object.values(item.dataview.component?.dataviewModels || {});
            const metricsQueries = item.dataview.query
              ? [{ subscribedQueries: [[QueryModel.create(item.dataview.query).serialize()]] }]
              : [];
            const queryBuckets = [
              ...(item.dataview.queryBuckets?.models || []),
              ...componentDvms.map((dvm) => dvm.queryBuckets.models),
              ...metricsQueries
            ].flat();
            queryBuckets.forEach((qbm) => {
              const encodedQueries = qbm.subscribedQueries.map((subscribedQuery) => {
                const subQueryClone = Array.isArray(subscribedQuery) ? [...subscribedQuery] : { ...subscribedQuery };
                if (Array.isArray(subQueryClone)) {
                  subQueryClone.forEach((subQuery) => {
                    if (!subscribedQuery.starting_time) {
                      $sharedLinks.setQueryTimeRanges(subQuery, query.starting_time, query.ending_time);
                    }
                  });
                } else if (!subscribedQuery.starting_time) {
                  $sharedLinks.setQueryTimeRanges(subQueryClone, query.starting_time, query.ending_time);
                }

                return encodeQuery(subQueryClone);
              });
              queries = queries.concat(encodedQueries);
            });
          }
        });
      }

      const values = Object.assign(
        {
          metadata: {
            link_type,

            // only present for DataExplorer and SavedViews
            hash,
            queries,

            // only present for Dashboards
            dashboard: dashboardModel,
            parametric_fields,

            // only present for Synthetics
            test_id: test_id || dashSynthTestIds?.flat().filter(Boolean),

            // available on multiple...
            start_time: start_time || query?.starting_time,
            end_time: end_time || query?.ending_time
          }
        },
        formValues
      );

      $sharedLinks
        .createSharedLink(values)
        .then(({ entropy }) => {
          const url = `${window.location.origin}/v4/shared/${link_type}/${entropy}/${formValues.public_hash}`;

          copy(url, {});
          showSuccessToast('Public share created! The link has been copied to your clipboard.');
          onClose(values);
        })
        .catch(() => {
          showErrorToast('An unknown error has occurred when saving your public share');
        });
    });
  };

  onEdit = (formValues) => {
    const { editing, $sharedLinks, onClose = () => {} } = this.props;

    const values = Object.assign(
      {
        metadata: editing.metadata
      },
      formValues
    );

    $sharedLinks
      .updateSharedLink(editing.id, values)
      .then(() => {
        showSuccessToast('Public share updated!');
        onClose(Object.assign({ id: editing.id }, values));
      })
      .catch(() => {
        showErrorToast('An unknown error has occurred when saving your public share');
      });
  };

  onSuggest = ({ form, value }) => {
    const { editing } = this.props;

    // TODO: find a way to turn off suggesting if the user enters a different hash manually?
    if (!editing) {
      const strippedTitle = this.suggestedUrl(value);

      form.setValue('public_hash', strippedTitle);
    }
  };

  suggestedUrl = (title) =>
    title
      .trim()
      // replace existing spaces with hyphens to preserve readability
      .replaceAll(' ', '-')
      // ignore other characters that could give us problems
      .replaceAll(/(?![a-z0-9-_])./gi, '');

  render() {
    const {
      $auth,
      $dashboard,
      $sharedLinks,
      theme,
      editing,
      link_type,
      defaultName,
      defaultDescription,
      onClose = () => {},
      showManagePublicShares,
      legacyLinkShare,
      privateShareUrl,
      isPublicLink
    } = this.props;
    const urlPrefix = `${window.location.origin}/v4/shared/${link_type}/`;

    const fieldConfigs = {
      name: {
        rules: 'required',
        messages: {
          required: 'Title is required'
        }
      },
      description: {},
      public_hash: {
        async: true,
        rules: 'required|public_hash_available|regex:/^[a-z0-9-_]+$/i',
        messages: {
          required: 'URL segment is required',
          regex: 'Alpha/Numeric characters only',
          public_hash_available: 'This link (hash) is already taken'
        }
      }
    };

    if (editing) {
      fieldConfigs.name.defaultValue = editing.name;
      fieldConfigs.description.defaultValue = editing.description;
      fieldConfigs.public_hash.defaultValue = editing.public_hash;
    } else {
      fieldConfigs.recipients = {
        label: 'Recipients',
        placeholder: 'Enter a comma separated list of internal or external email addresses',
        rules: ''
      };

      fieldConfigs.message = {
        label: 'Message',
        placeholder: 'Enter a message that will be emailed to your recipients',
        rules: ''
      };

      // keep the name/description of the saved view/dashboard, unless the user elects to change them
      if (link_type === $sharedLinks.types.savedView || link_type === $sharedLinks.types.dashboards) {
        fieldConfigs.name.defaultValue = defaultName;
        fieldConfigs.description.defaultValue = defaultDescription;
        fieldConfigs.public_hash.defaultValue = this.suggestedUrl(defaultName);
      }
    }

    let link = `${window.location.origin}${privateShareUrl || window.location.pathname}`;
    if (!privateShareUrl && link_type === $sharedLinks.types.dashboards && $dashboard.dashboard.get('parametric')) {
      const parametricFields = $dashboard.dashboard.get('parametric_fields');
      if (parametricFields?.length && parametricFields[0].type && parametricFields[0].value) {
        link = `${link}/guided?${new URLSearchParams([[parametricFields[0].type, parametricFields[0].value]])}`;
      }
    }

    return (
      <FormComponent
        fields={fieldConfigs}
        options={{
          showPristineErrors: false,
          isEditAction: !!editing,
          name: 'Share Public View'
        }}
        model={{
          isRequestActive: () => false,
          save: editing ? this.onEdit : this.onSave
        }}
        large
      >
        {(formProps) => (
          <>
            <Dialog.Body>
              {!isPublicLink && (
                <Box border="thin" p="10px" borderRadius={4} bg={theme.backgrounds.app} mb="10px">
                  <Text fontWeight="bold">Internal Share</Text>

                  <Flex justifyContent="space-between" p="2px 0">
                    <Link
                      to={link}
                      href={link}
                      blank
                      fontSize="small"
                      muted
                      ellipsis
                      whiteSpace="nowrap"
                      overflow="hidden"
                      mr="3px"
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    />

                    <CopyToClipboardButton text={link} intent="primary" minimal small mt="-4px">
                      <Button text="Copy Link" icon={FiCopy} style={{ minWidth: 90 }} />
                    </CopyToClipboardButton>
                  </Flex>
                </Box>
              )}

              {$auth.hasPermission('linkSharing.disabled', { overrideForSudo: false }) && (
                <Box border="thin" p="10px" borderRadius={4} bg={theme.backgrounds.app}>
                  <EmptyState description="Public Link Sharing has been disabled for your account." />{' '}
                </Box>
              )}
              {!$auth.hasPermission('linkSharing.disabled', { overrideForSudo: false }) && (
                <Box border="thin" p="10px" borderRadius={4} bg={theme.backgrounds.app}>
                  <Flex justifyContent="space-between">
                    <Text fontWeight="bold">Public Share</Text>
                    {showManagePublicShares && (
                      <Flex justifyContent="flex-end">
                        <Link small to="/v4/settings/shared-links">
                          Manage Public Shares
                          <Icon icon={BsCaretRightFill} iconSize={12} color="primary" />
                        </Link>
                      </Flex>
                    )}
                  </Flex>

                  {legacyLinkShare ? (
                    <Text small as="div" m="5px 0">
                      Public Link Sharing is not yet available for this view.
                    </Text>
                  ) : (
                    <>
                      <Callout border="thin" icon={FiInfo} pl={4} m="10px 0">
                        Please ensure the display date is safe for public viewing, as external users may access the
                        content. This view requires a definite time range and will automatically lock your time
                        selection into it.
                      </Callout>

                      <Heading level={6}>Title</Heading>
                      <Field
                        name="name"
                        style={{ flex: 1 }}
                        placeholder="Total by Average bits/s"
                        onChange={this.onSuggest}
                      >
                        <InputGroup />
                      </Field>

                      <Heading level={6}>Description</Heading>
                      <Field
                        name="description"
                        // eslint-disable-next-line max-len
                        placeholder="Description such as reasons for sharing, incident details, what timestamps to pay close attention to, etc. can help provide good context, especially for external users"
                      >
                        <TextArea fill />
                      </Field>

                      <Heading level={6}>Share Link</Heading>
                      <Text
                        as="div"
                        fontWeight="bold"
                        fontSize="small"
                        style={{ display: 'flex', flexDirection: 'row' }}
                      >
                        <Text pt="6px" muted>
                          {urlPrefix}
                        </Text>

                        <Field
                          name="public_hash"
                          style={{
                            flex: 1,
                            paddingLeft: '6px',
                            fontWeight: 'normal'
                          }}
                          placeholder="SharedLink"
                        >
                          <InputGroup />
                        </Field>
                      </Text>

                      {!editing && (
                        <>
                          <Field
                            name="recipients"
                            mt={1}
                            // options={$users.collection.generateSelectOptions({
                            //   valueKey: 'user_email',
                            //   labelKey: 'user_full_name'
                            // })}
                            // allowMultiSelect
                            defaultValue=""
                          >
                            {/* <CreateMultiSelect autoComplete clearable fill multi toggle exactMatch={false} showButton={false} /> */}
                            <TextArea fill />
                          </Field>
                          <Field name="message">
                            <TextArea fill />
                          </Field>
                        </>
                      )}
                    </>
                  )}
                </Box>
              )}
            </Dialog.Body>
            <Dialog.Footer>
              <FormActions
                entityName="Link"
                submitButtonText="Share"
                submitButtonProps={{
                  tracker: `share-link-${link_type}`
                }}
                onCancel={onClose}
                {...formProps}
                large={false}
              />
            </Dialog.Footer>
          </>
        )}
      </FormComponent>
    );
  }
}
