import React from 'react';
import moment from 'moment';
import { Tag } from 'core/components';

function ExpiresTag({ endTime, neverExpire, ...tagProps }) {
  let label = 'Expires Never';

  if (!neverExpire) {
    const expireDate = moment(endTime);
    const now = moment();
    const isExpired = expireDate.isBefore(now);
    const duration = moment.duration(expireDate.diff(now)).humanize();

    if (isExpired) {
      label = `Expired ${duration} ago`;
    } else {
      label = `Expires in ${duration}`;
    }
  }

  return (
    <Tag height={22} {...tagProps}>
      <strong>{label}</strong>
    </Tag>
  );
}

ExpiresTag.defaultProps = {
  neverExpire: false
};

export default ExpiresTag;
