import React from 'react';
import { Classes } from '@blueprintjs/core';
import { Flex, Tag } from 'core/components';
import DeviceValuesRenderer from 'app/components/device/DeviceValuesRenderer';
import $devices from 'app/stores/device/$devices';

const DeviceDisplay = ({ model, query, getDevices }) => {
  let all_devices;
  let device_name;

  if (model) {
    all_devices = model.get('all_devices');
    device_name = model.get(getDevices ? 'devices' : 'device_name');
  } else {
    all_devices = query.all_devices;
    device_name = query[getDevices ? 'devices' : 'device_name'];
  }

  if (all_devices) {
    return (
      <Tag intent="success" minimal round>
        <strong>All Data Sources</strong>
      </Tag>
    );
  }

  return (
    <Flex className={Classes.INPUT_GROUP}>
      <DeviceValuesRenderer values={device_name} options={$devices.getDeviceOptions()} />
    </Flex>
  );
};

export default DeviceDisplay;
