import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';

import BollingerBandChart from 'app/views/synthetics/components/BollingerBandChart';
import { checkSeriesDataForFailure } from 'app/views/synthetics/utils/syntheticsUtils';

import withTrendChart from './withTrendChart';

@withTrendChart
@withTheme
@inject('$app', '$dataviews')
@observer
class HttpStatusTimeSeries extends BollingerBandChart {
  static defaultProps = {
    isAggregated: false // we do not show health colors in aggregated results
  };

  get valueSuffix() {
    return '';
  }

  getColor(health) {
    const {
      theme: { colors },
      isAggregated
    } = this.props;

    if (!isAggregated) {
      if (health === 'warning') {
        return colors.warning;
      }

      if (health === 'critical') {
        return colors.danger;
      }

      return colors.success;
    }

    return colors.muted;
  }

  renderData() {
    if (this.chart) {
      const { data, seriesName, xAxisMin, xAxisMax, delta } = this.props;

      const averages = [];
      const zones = [];
      let currHealth;

      const offset = delta || 60000;

      data.forEach((point, i) => {
        const { time, jitter_health, status } = point; // jitter_health is where the backend ships status-related health back to the UI

        if (checkSeriesDataForFailure(point)) {
          averages.push({ time, x: time, y: null });
        } else {
          averages.push({
            time,
            x: time,
            y: status,
            marker: {
              enabled: checkSeriesDataForFailure(data[i - 1]) || checkSeriesDataForFailure(data[i + 1])
            }
          });
        }

        if (currHealth !== jitter_health) {
          zones.push({
            value: time - offset * (currHealth === 'healthy' ? 1.1 : 0.9), // offset to center the values, but give more room to non-healthy values
            color: this.getColor(currHealth)
          });
          currHealth = jitter_health;
        }
      });

      zones.push({ color: this.getColor(currHealth), fillColor: this.getFillColor(currHealth) });

      const marker = {
        enabled: false,
        states: {
          select: {
            fillColor: this.getColor(currHealth),
            lineColor: 'rgba(255, 255, 255, 0.5)',
            lineWidth: 5,
            radius: 8
          }
        }
      };

      this.setState({
        chartOptions: {
          series: [{ step: 'center', name: seriesName, data: averages, zones, marker }],
          yAxis: {
            max: 599,
            offset: 20
          },
          xAxis: {
            min: xAxisMin,
            max: xAxisMax
          }
        }
      });
    }
  }
}

export default HttpStatusTimeSeries;
