import { queryHasDevices } from 'app/util/devices';
import $auth from 'app/stores/$auth';

import AbstractDependency from './AbstractDependency';

class MatchingDevicesDependency extends AbstractDependency {
  get key() {
    return 'devices';
  }

  get preventQuery() {
    return true;
  }

  get showMessage() {
    return false;
  }

  get requires() {
    return true;
  }

  get meets() {
    return false;
  }

  validate() {
    if (this.dataviews.length && !$auth.isPresetCompany) {
      this.dataviews.forEach(
        (dataview) =>
          (dataview.preventQuery =
            dataview.queryBuckets &&
            dataview.queryBuckets.some((bucket) =>
              bucket.queries.some((queryModel) => !queryHasDevices(queryModel.get()))
            ))
      );
    }
  }

  get message() {
    return 'This query cannot be performed because no active devices match its criteria';
  }

  static queryMeets(query) {
    return queryHasDevices(query);
  }
}

export default MatchingDevicesDependency;
