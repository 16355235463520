import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Flex, Box, Icon, Text, Button } from 'core/components';
import { Field, Select } from 'core/form';

@observer
class FilterConnector extends Component {
  render() {
    const { group, readOnly, groupLabel, onRemoveGroup, disableRemoveGroup } = this.props;
    const connector = group.connector.getValue();
    const shouldExcludeResults = group.not.getValue();

    if (readOnly) {
      return (
        <Box p="4px">
          <Icon
            color={shouldExcludeResults ? 'danger' : 'success'}
            icon={shouldExcludeResults ? 'small-cross' : 'small-tick'}
            style={{ fontSize: 12, marginRight: 2 }}
          />
          <Text color={shouldExcludeResults ? 'danger' : 'success'}>
            {shouldExcludeResults ? 'Exclude ' : 'Include'}
          </Text>{' '}
          <Box className="any-all-operator pt-monospace-text" mr="4px">
            {connector}
          </Box>
          <span>of the following:</span>
        </Box>
      );
    }

    return (
      <Flex alignItems="center" justifyContent="space-between" flex={1}>
        <Flex alignItems="center" gap={1} flex={1}>
          <Field field={group.not} showLabel={false} small mb={0}>
            <Select
              small
              buttonStyle={{
                fontWeight: 'medium',
                icon: group.not.getValue() ? 'cross' : 'tick'
              }}
              width="auto"
              menuWidth={150}
            />
          </Field>
          <Box lineHeight="24px">
            <small>results matching</small>
          </Box>
          <Field field={group.connector} showLabel={false} mb={0} small>
            <Select small buttonStyle={{ fontWeight: 'medium' }} width="auto" menuWidth={150} />
          </Field>
          <Box lineHeight="24px">
            <small>of the following conditions:</small>
          </Box>
        </Flex>

        {!disableRemoveGroup && (
          <Button text={`Remove ${groupLabel}`} onClick={onRemoveGroup} intent="danger" minimal small />
        )}
      </Flex>
    );
  }
}

export default FilterConnector;
