const {
  RESOURCE_GRAPH_VNET,
  RESOURCE_GRAPH_VIRTUAL_NAT_GATEWAY,
  RESOURCE_GRAPH_LOCAL_NETWORK_GATEWAY,
  RESOURCE_GRAPH_VIRTUAL_NETWORK_GATEWAY,
  RESOURCE_GRAPH_EXPRESS_ROUTE_CIRCUIT,
  RESOURCE_GRAPH_FIREWALL,
  RESOURCE_GRAPH_LOAD_BALANCER,
  RESOURCE_GRAPH_APPLICATION_GATEWAY,
  RESOURCE_GRAPH_VIRTUAL_HUB
} = require('./constants');

module.exports = `
Resources
| where type in~ (
  '${RESOURCE_GRAPH_VNET}',
  '${RESOURCE_GRAPH_VIRTUAL_NETWORK_GATEWAY}',
  '${RESOURCE_GRAPH_LOCAL_NETWORK_GATEWAY}',
  '${RESOURCE_GRAPH_VIRTUAL_NAT_GATEWAY}',
  '${RESOURCE_GRAPH_EXPRESS_ROUTE_CIRCUIT}',
  '${RESOURCE_GRAPH_FIREWALL}',
  '${RESOURCE_GRAPH_LOAD_BALANCER}',
  '${RESOURCE_GRAPH_APPLICATION_GATEWAY}',
  '${RESOURCE_GRAPH_VIRTUAL_HUB}'
)
| project id,
type,
name,
subscriptionId,
resourceGroup,
tenantId,
location,
subnetsCount = array_length(properties.subnets),
gatewayIpAddress = properties.gatewayIpAddress,
provisioningState = properties.provisioningState,
vnetAddressPrefixes = properties.addressSpace.addressPrefixes,
localNetworkAddressPrefixes = properties.localNetworkAddressSpace.addressPrefixes,
tier = properties.sku.tier,
minCapacity = properties.autoscaleConfiguration.minCapacity,
maxCapacity = properties.autoscaleConfiguration.maxCapacity,
operationalState = properties.operationalState,
subnets = properties.subnets
`;
