import Validator from 'validatorjs';
import $setup from 'app/stores/$setup';

Validator.register(
  'cloudWithKappaOnly',
  function cloudWithKappaOnly(value) {
    const { device_subtype } = this.validator.input;
    const isCloudPak = $setup.planOptions.some((plan) => plan.value === value && plan.plan_type === 'cloud');
    return !isCloudPak || device_subtype === 'kappa';
  },
  'Cloudpaks can only be used here with process-aware telemetry agents'
);

Validator.register(
  'metricsPlanNoSendingIps',
  function metricsPlanNoSendingIps(value) {
    const { sending_ips } = this.validator.input;
    const isMetricPlan = $setup.planOptions.some((plan) => plan.value === value && plan.plan_type === 'metrics');

    return !(sending_ips?.length && isMetricPlan);
  },
  'Metrics Plans can only be used with devices that are not sending flow. Please remove all Sending IPs if you want to add this device to an NMS plan.'
);

Validator.registerImplicit(
  'flowRequired',
  function flowRequired(value) {
    const { snmp_collection_method, device_subtype, model } = this.validator.input;
    const ranger_icmp_ip = model.get('ranger_icmp_ip');

    if (device_subtype !== 'router' || snmp_collection_method?.includes('kentik_agent') || ranger_icmp_ip) {
      return true;
    }

    return this.validator.getRule('required').validate(value);
  },
  ':attribute is required'
);

const options = {
  name: 'Device',
  groups: {
    general: ['device_name', 'device_description', 'device_type', 'device_flow_type', 'sending_ips'],
    planSite: ['plan.id', 'site.id'],
    ipSnmp: [
      'device_snmp_v3_conf_enabled',
      'minimize_snmp',
      'device_snmp_v3_conf.UserName',
      'device_snmp_v3_conf.AuthenticationProtocol',
      'device_snmp_v3_conf.AuthenticationPassphrase',
      'device_snmp_v3_conf.PrivacyProtocol',
      'device_snmp_v3_conf.PrivacyPassphrase'
    ],
    bgp: [
      'device_bgp_type',
      'device_bgp_neighbor_ip',
      'device_bgp_neighbor_ip6',
      'device_bgp_neighbor_asn',
      'device_bgp_password',
      'device_bgp_flowspec',
      'use_bgp_device_id'
    ],
    st: ['device_gnmi_v1_conf']
  }
};

const fields = {
  device_name: {
    label: 'Name',
    rules: 'required|max:256',
    messages: {
      required: 'The Device Name field is required'
    }
  },

  device_description: {
    label: 'Description',
    rules: 'max:100'
  },

  device_subtype: {
    label: 'Type',
    rules: 'required',
    defaultValue: 'router'
  },

  cdn_attr: {
    label: 'Contribute to CDN Attribution',
    helpText: "If this is a DNS server, you can contribute its queries to Kentik's CDN attribution database",
    transform: {
      in: (value) => value === 'Y',
      out: (value) => (value && value === true ? 'Y' : 'N')
    }
  },

  device_flow_type: {
    label: 'Flow Type',
    defaultValue: 'Auto',
    helpText: 'The type of flow the router is sending to Kentik'
  },

  labels: {
    label: 'Label(s)',
    transform: {
      in: (value) => value && value.map((label) => (label.id ? label.id : label))
    }
  },

  'plan.id': {
    label: 'Flow Plan',
    placeholder: 'Select a Flow plan',
    rules: 'required|cloudWithKappaOnly|metricsPlanNoSendingIps',
    messages: {
      required: 'The Flow Plan field is required'
    }
  },

  'site.id': {
    label: 'Site',
    placeholder: 'Select a site',
    helpText: 'The site to which this device is assigned',
    transform: {
      out: (value) => value && Number(value).toString()
    }
  },
  'site.title': {
    label: 'Name'
  },
  'site.address': {
    label: 'Street Address',
    helpText: 'We will geocode this address and provide additional Geographic dimensions that involve this'
  },
  'site.country': {
    label: 'Country',
    defaultValue: ''
  },
  'site.city': {
    label: 'City'
  },
  'site.region': {},
  'site.postal': {},
  'site.lat': {},
  'site.lon': {},
  'site.metadata': {
    defaultValue: {}
  },

  role: {
    label: 'Role'
  },

  snmp_collection_method: {
    label: 'SNMP Collection Method',
    options: [
      {
        value: 'kentik_agent',
        label: 'For NMS via Universal Agent',
        tooltip: 'Consumes metrics-per-second (MPS) from your Kentik NMS license.'
      },
      {
        value: 'kproxy',
        label: 'For Flow Enrichment'
      }
    ]
  },

  'kentik_agent_snmp_options.ranger_snmp_ip': {
    label: 'Device SNMP IP',
    rules: 'ipNoCidr|requiredIfIncludes:snmp_collection_method,kentik_agent'
  },

  'kentik_agent_snmp_options.credential_name': {
    label: 'Credential',
    rules: 'requiredIfIncludes:snmp_collection_method,kentik_agent'
  },

  'kentik_agent_snmp_options.agent_id': {
    label: 'Collection Agent',
    rules: 'requiredIfIncludes:snmp_collection_method,kentik_agent'
  },

  'kentik_agent_snmp_options.port': {
    label: 'Port',
    placeholder: '161',
    rules: 'numeric'
  },

  'kentik_agent_snmp_options.timeout': {
    label: 'Timeout',
    helpText: 'in seconds',
    placeholder: '2',
    rules: 'numeric'
  },

  monitoring_template_id: {
    label: 'Monitoring Template'
  },

  device_snmp_v3_conf_enabled: {
    label: 'SNMP v3 Auth'
  },

  'device_snmp_v3_conf.UserName': {
    label: 'SNMP v3 User Name'
  },

  'device_snmp_v3_conf.AuthenticationProtocol': {
    label: 'SNMP v3 Auth Type',
    defaultValue: 'NoAuth',
    options: [
      { value: 'NoAuth', label: 'None' },
      { value: 'MD5', label: 'MD5' },
      { value: 'SHA', label: 'SHA' }
    ]
  },

  'device_snmp_v3_conf.AuthenticationPassphrase': {
    label: 'SNMP v3 Auth Passphrase'
  },

  'device_snmp_v3_conf.PrivacyProtocol': {
    label: 'SNMP v3 Privacy Type',
    defaultValue: 'NoPriv',
    options: [
      { value: 'NoPriv', label: 'None' },
      { value: 'DES', label: 'DES' },
      { value: 'AES', label: 'AES' }
    ]
  },

  'device_snmp_v3_conf.PrivacyPassphrase': {
    label: 'SNMP v3 Privacy Passphrase'
  },

  minimize_snmp: {
    label: 'SNMP Polling',
    rules: 'boolean',
    helpText:
      'Standard polling is highly recommended and if selected will take interface counter readings every 5 minutes and 3 hrs for descriptions, is necessary for the Kentik Map to properly populate with health statistics and is necessary for Connectivity costs to accurately reflect common ISP billing intervals. If Minimum, interface polling is disabled and descriptions are polled every 6 hours.',
    defaultValue: false,
    options: [
      { value: false, label: 'Standard' },
      { value: true, label: 'Minimum' }
    ]
  },

  'sending_ips[]': {
    label: 'Sending IPs',
    defaultValue: [''],
    helpText: 'IP address(es) from which the router sends flow',
    messages: {
      flowRequired: 'You must add at least 1 Sending IP address'
    },
    rules: 'flowRequired',
    children: {
      rules: 'required|ip',
      messages: {
        required: 'You must enter a Sending IP address',
        ip: 'Sending IP must be a valid IP address'
      }
    },
    validateDebounceDurationMs: 500,
    transform: {
      out: (value) => (value && value.trim ? value.trim() : value)
    }
  },

  device_snmp_ip: {
    label: 'Device SNMP IP',
    helpText: 'IP Address for the SNMP server on the device',
    rules: 'ipNoCidr|requiredIfIncludes:snmp_collection_method,kproxy'
  },

  device_snmp_community: {
    label: 'SNMP Community',
    helpText: 'The SNMP community to use when polling the router'
  },

  device_sample_rate: {
    label: 'Sample Rate',
    rules: 'flowRequired|numeric|between:0,4000000',
    type: 'number',
    helpText: 'Total packets transiting the device for each packet processed for flow data',
    messages: {
      flowRequired: 'You must define a Sample Rate',
      'between:0,4000000': 'Sample Rate must be between 0 and 4,000,000,',
      numeric: 'Sample Rate must be a number'
    }
  },

  device_bgp_type: {
    label: 'BGP Type',
    rules: 'required',
    defaultValue: 'none',
    helpText: 'Choose whether Kentik will BGP peer with this device.',
    options: [
      {
        value: 'none',
        label: 'No peer, use generic IP/ASN mapping'
      },
      {
        value: 'device',
        label: 'Peer with device'
      },
      {
        value: 'other_device',
        label: 'Use table from another peered device'
      }
    ]
  },

  device_bgp_neighbor_ip: {
    label: 'IPv4 Peering Address',
    rules: [{ requiredIfMultiple: ['device_bgp_type', 'device', 'device_bgp_neighbor_ip6', ''] }, 'ip'],
    placeholder: 'Enter IP Address...',
    messages: {
      required: 'Peering IPv4 is required when IPv6 is not present.'
    }
  },

  device_bgp_neighbor_ip6: {
    label: 'IPv6 Peering Address',
    rules: [{ requiredIfMultiple: ['device_bgp_type', 'device', 'device_bgp_neighbor_ip', ''] }, 'ip'],
    placeholder: 'Enter IP Address...',
    messages: {
      required: 'Peering IPv6 is required when IPv4 is not present.'
    }
  },

  device_bgp_neighbor_asn: {
    label: 'ASN',
    rules: ['required_if:device_bgp_type,device', 'integer', 'min:1', 'max:4294967295'],
    helpText: 'The number of the autonomous system (AS) that the peering device belongs to.',
    messages: {
      required: 'Required when BGP is set to Peer with device.'
    }
  },

  device_bgp_password: {
    label: 'BGP MD5 password',
    rules: 'bgpPassword',
    helpText: 'Optional BGP MD5 password.'
  },

  device_bgp_flowspec: {
    label: 'BGP Flowspec Compatible',
    defaultValue: false
  },

  use_asn_from_flow: {
    label: 'Use AS Numbers from Flows',
    defaultValue: false
  },

  use_bgp_device_id: {
    label: 'Primary BGP device',
    placeholder: 'Select a Peered Device...',
    rules: [
      {
        required_if: ['device_bgp_type', 'other_device']
      }
    ]
  },
  device_alert: {
    label: 'Kentik Firehose URL Endpoint',
    placeholder: 'https://host.com'
  },
  device_bgp_label_unicast: {
    label: 'Segment Routing',
    defaultValue: false
  },
  bgp_lookup_strategy: {
    label: 'BGP Route Selection',
    defaultValue: 'auto',
    options: [
      {
        label: 'VPN table for VRF interface, Unicast table for non-VRF interface',
        value: 'auto'
      },
      {
        label: 'VPN table, fallback to Unicast table',
        value: 'global_fallback'
      },
      {
        label: 'VPN table, fallback to Labeled-Unicast table, fallback to Unicast table',
        value: 'lu_global_fallback'
      }
    ]
  },

  streaming_telemetry_collection_method: {
    label: 'Streaming Telemetry Collection Method',
    options: [
      {
        value: 'kentik_agent',
        label: 'Dial-In from Universal Agent',
        helpText: 'Advanced Streaming Telemetry using Kentik NMS.',
        tooltip: 'Consumes metrics-per-second (MPS) from your Kentik NMS license.'
      },
      {
        value: 'kproxy',
        label: 'Dial-Out to kProxy',
        helpText: 'Basic Streaming Telemetry included with Kentik Platform.'
      }
    ]
  },

  'streaming_telemetry_collection_options.credential_name': {
    label: 'Credential'
  },

  'streaming_telemetry_collection_options.use_ssl': {
    label: 'Use Secure Connection (SSL)',
    defaultValue: false
  },

  'streaming_telemetry_collection_options.timeout': {
    label: 'Timeout',
    helpText: 'in seconds',
    placeholder: '2',
    rules: 'numeric'
  },

  'streaming_telemetry_collection_options.port': {
    label: 'Port',
    rules: 'numeric'
  },

  'streaming_telemetry_collection_options.verify_ssl': {
    label: 'Verify certificate on this device',
    defaultValue: false
  },

  'streaming_telemetry_collection_options.enable_certificate_auth': {
    label: 'Enable client certificate authentication',
    defaultValue: false
  },

  'streaming_telemetry_collection_options.certificate': {
    label: 'Certificate',
    helpText: 'the path to the public client key for this connection'
  },

  'streaming_telemetry_collection_options.private_key': {
    label: 'Private Key',
    helpText: 'the path to the private key for'
  },

  device_gnmi_v1_conf: {
    label: 'Enable Streaming Telemetry collection',
    transform: {
      in: (i) => i && i?.dialoutServer === 'auto',
      out: (o) => (o ? { dialoutServer: 'auto' } : null)
    }
  }
};

export { fields, options };
