import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Field, InputGroup, Select, TextArea } from 'core/form';
import { Flex, Paragraph } from 'core/components';

@inject('$cloudExportWizard')
@observer
class NameAndPlanInput extends Component {
  componentDidMount() {
    this.generateExporterDefaultName();
    this.setDefaultDescription();
  }

  generateExporterDefaultName() {
    const { $cloudExportWizard } = this.props;
    const {
      form,
      isMetadataOnlyEnabled,
      isMetricsNothingElse,
      isSelectedCloudProviderAws,
      isSelectedCloudProviderGcp
    } = $cloudExportWizard;

    if (!form.hasField('name')) {
      return '';
    }
    const nameField = form.getField('name');
    const isPristine = nameField?.pristine;

    if (!isPristine) {
      return '';
    }

    const metadataOnlyPrefix = 'metadata-only-';
    const prefix = isMetadataOnlyEnabled ? metadataOnlyPrefix : '';
    if (isSelectedCloudProviderAws) {
      const arnValue = form.getValue('properties.aws_iam_role_arn');
      const regionValue = form.getValue('properties.aws_region');
      nameField.setValue(`${prefix}${regionValue}-${arnValue}`);
    }
    if (isSelectedCloudProviderGcp) {
      const project = form.getValue('properties.gce_project');
      const subscription = form.getValue('properties.gce_subscription');
      const defaultName = isMetadataOnlyEnabled || isMetricsNothingElse ? `${project}` : `${project}-${subscription}`;
      nameField.setValue(defaultName);
    }

    return '';
  }

  setDefaultDescription() {
    let defaultDescription = '';
    const { $cloudExportWizard } = this.props;
    const {
      form,
      isSelectedCloudProviderAws,
      isSelectedCloudProviderGcp,
      isMetadataOnlyEnabled,
      isFlowLogCollectionEnabled,
      isGcpCloudRunLogEnabled,
      isMetricsNothingElse
    } = $cloudExportWizard;
    const descriptionField = form.getField('properties.description');

    // TODO: should we include something about metrics collection here? Not currenty in phase 1

    if (isSelectedCloudProviderGcp) {
      const projectField = form.getField('properties.gce_project');
      const subscriptionField = form.getField('properties.gce_subscription');
      const project = projectField.getValue();
      const subscription = isMetadataOnlyEnabled || isMetricsNothingElse ? '' : subscriptionField.getValue();

      if (isMetadataOnlyEnabled) {
        defaultDescription = `All metadata found in project ${project}.`;
      }

      if (isMetricsNothingElse) {
        defaultDescription = `All metadata found in project ${project}. Metrics will also be collected.`;
      }

      if (isFlowLogCollectionEnabled) {
        defaultDescription = `All flow found in project ${project} in subscription ${subscription}.`;
      }

      if (isGcpCloudRunLogEnabled) {
        defaultDescription = `All GCP Cloud Run logs found in project ${project} in subscription ${subscription}.`;
      }

      if (isFlowLogCollectionEnabled && isGcpCloudRunLogEnabled) {
        defaultDescription = `All flow found in project ${project} in subscription ${subscription}. A separate Cloud Export will automatically be created to capture GCP Cloud Run logs.`;
      }
    }

    if (isSelectedCloudProviderAws) {
      const regionField = form.getField('properties.aws_region');
      const region = regionField.getValue();
      const bucketField = form.getField('properties.aws_bucket');

      if (isMetadataOnlyEnabled) {
        defaultDescription = `Collect metadata for ${region}.`;
      }

      if (isFlowLogCollectionEnabled) {
        const bucket = bucketField?.getValue();
        const bucketPrefixField = form.getField('properties.aws_bucket_prefix');
        const bucketPrefix = bucketPrefixField.getValue();
        defaultDescription = `All flow found in project ${bucket} ${
          bucketPrefix ? `with prefix ${bucketPrefix}` : ''
        } in region ${region}.`;
      }
    }
    descriptionField.setValue(defaultDescription);
  }

  render() {
    const { $cloudExportWizard } = this.props;
    const { isFlowLogCollectionEnabled, isSelectedCloudProviderAws, isSelectedCloudProviderGcp } = $cloudExportWizard;

    const flowText =
      'For each VPC, subnet or interface that we find flow for in the configured subscription, Kentik will automatically create a new device.';
    const nameText = `You can specify a name for this cloud export below${
      isSelectedCloudProviderAws || isSelectedCloudProviderGcp ? ' or accept our default values' : ''
    }.`;

    const planOptions = $cloudExportWizard.getCloudPlanOptions();
    const helpTextMsg = planOptions?.length
      ? ''
      : 'There are no available device slots in your plans. Please contact sales@kentik.com to add more devices.';

    return (
      <Flex>
        <Flex flexDirection="column" gap={1} flex={1}>
          <Paragraph maxWidth={600} muted>
            {isFlowLogCollectionEnabled ?? flowText}
            {nameText}
          </Paragraph>

          <Field name="name" label="Cloud export name" large>
            <InputGroup width="100%" />
          </Field>

          <Field name="properties.description" large>
            <TextArea rows={3} width="100%" />
          </Field>

          <Field name="plan_id" large options={planOptions} helpText={helpTextMsg}>
            <Select width={400} menuWidth={400} />
          </Field>
        </Flex>
        <Flex flex={1} />
      </Flex>
    );
  }
}

export default NameAndPlanInput;
