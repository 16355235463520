import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';
import { Flex, Tabs, Tab, Text, Box } from 'core/components';
import {
  Field,
  FormDialog,
  InputGroup,
  ToggleButtonGroup,
  TextArea,
  SelectManaged,
  MultiSelect,
  Select
} from 'core/form';
import SiteArchitectureDialog from 'app/views/settings/sites/SiteArchitectureDialog';
import ArchitectureDisplay from 'app/views/settings/sites/ArchitectureDisplay';
import AddressInput from 'app/components/AddressInput';
import { ReactComponent as PeeringdbIcon } from 'app/assets/logos/peeringdb-icon.svg';
import Icon from 'core/components/Icon';

const fields = {
  title: {
    label: 'Name',
    rules: 'required|max:200|min:1',
    transform: {
      out: (value) => value.trim()
    }
  },
  country: {
    label: 'Country'
  },
  address: {
    label: 'Address'
  },
  city: {
    label: 'City'
  },
  region: {
    label: 'Region'
  },
  postal: {},
  lat: {
    defaultValue: null
  },
  lon: {
    defaultValue: null
  },
  site_market_id: {
    label: 'Site Market',
    defaultValue: null,
    transform: {
      out: (value) => (value ? `${value}` : null)
    }
  },
  peeringdbSiteMapping: {
    placeholder: 'Facility Name',
    label: (
      <>
        <Icon icon={PeeringdbIcon} mr={1} />
        PeeringDB Facility Mapping
      </>
    ),
    transform: {
      in: (values) => (values && Array.isArray(values) ? values.map((mapping) => parseInt(mapping.fac_id)) : [])
    }
  },
  'metadata.type': {
    label: 'Type',
    defaultValue: 'other',
    options: [
      {
        label: 'Data Center',
        value: 'dataCenter'
      },
      {
        label: 'Cloud',
        value: 'cloud'
      },
      {
        label: 'Branch/Building',
        value: 'branch'
      },
      {
        label: 'Connectivity PoP',
        value: 'connectivity'
      },
      {
        label: 'Customer/Partner',
        value: 'customer'
      },
      {
        label: 'Other',
        value: 'other'
      }
    ]
  },
  'metadata.architecture': {
    label: 'Architecture'
  },
  'metadata.classification.host': {
    placeholder: 'Enter a comma-separated list of IP addresses and/or subnets, e.g. 10.2.1.23, 1.2.3.0/24',
    rules: 'commaSeparatedIPsCIDR',
    transform: {
      out: (value) =>
        value
          .split(',')
          .map((ip) => ip.trim())
          .join(',')
    }
  },
  'metadata.classification.employee': {
    placeholder: 'Enter a comma-separated list of IP addresses and/or subnets, e.g. 10.2.1.23, 1.2.3.0/24',
    rules: 'commaSeparatedIPsCIDR',
    transform: {
      out: (value) =>
        value
          .split(',')
          .map((ip) => ip.trim())
          .join(',')
    }
  },
  'metadata.classification.other': {
    placeholder: 'Enter a comma-separated list of IP addresses and/or subnets, e.g. 10.2.1.23, 1.2.3.0/24',
    rules: 'commaSeparatedIPsCIDR',
    transform: {
      out: (value) =>
        value
          .split(',')
          .map((ip) => ip.trim())
          .join(',')
    }
  }
};

const options = { name: 'Site' };

@inject('$sites', '$siteMarkets', '$peering', '$lookups')
@observer
class SiteFormDialog extends Component {
  state = {
    selectedAddress: undefined,
    isEditArchitectureOpen: false,
    showSubnetConfirm: false
  };

  constructor(props) {
    super(props);
    const { model } = props;
    if (model.get('address') && model.get('lat') && model.get('lon')) {
      this.state.selectedAddress = {
        lat: model.get('lat'),
        lon: model.get('lon'),
        address: model.get('address')
      };
    }
  }

  componentDidMount() {
    const { $peering } = this.props;
    $peering.fetchPeeringdbFacOptions().then((facs) => {
      this.setState({ peeringFacOptions: facs });
    });
  }

  get title() {
    const { model } = this.props;
    const { isEditArchitectureOpen } = this.state;

    if (isEditArchitectureOpen) {
      return 'Edit Site Architecture';
    }

    return `${model && model.isNew ? 'Add' : 'Edit'} Site`;
  }

  hasLargeSubnet = (classification) => {
    if (classification) {
      return Object.values(classification)
        .filter(Boolean)
        .some((ips) =>
          ips.split(',').some((ip) => {
            const splitIP = ip.split('/');
            const subnetNumber = splitIP.length === 2 ? Number.parseInt(splitIP[1]) : 32;
            return subnetNumber < 8;
          })
        );
    }

    return false;
  };

  handleSave = (form, values) => {
    const { model, onSave } = this.props;
    const { selectedAddress } = this.state;

    const refetch =
      model.isNew ||
      model.get('site_market_id') !== values.site_market_id ||
      model.get('peeringdbSiteMapping') !== values.peeringdbSiteMapping;
    return model.save({ ...values, ...selectedAddress }).then(() => {
      if (onSave) {
        onSave(refetch);
      }
    });
  };

  onGeocodeComplete = (selectedAddress, form) => {
    this.setState({ selectedAddress });

    form.setValue('city', selectedAddress?.city);
    form.setValue('region', selectedAddress?.region);
    form.setValue('country', selectedAddress?.country);
  };

  onEditArchitectureClick = () => {
    this.setState({ isEditArchitectureOpen: true });
  };

  handleEditArchitectureClose = () => {
    this.setState({ isEditArchitectureOpen: false });
  };

  handleEditArchitectureSave = (form, architecture) => {
    form.setValue('metadata.architecture', architecture);
    this.setState({ isEditArchitectureOpen: false });
  };

  getMarkers = () => {
    const { selectedAddress } = this.state;
    if (selectedAddress) {
      if (selectedAddress.place_id) {
        return [
          {
            id: selectedAddress.place_id,
            lat: selectedAddress.geometry.location.lat(),
            lon: selectedAddress.geometry.location.lng(),
            title: selectedAddress.formatted_address,
            modelId: selectedAddress.place_id,
            color: 'green-small',
            element: 'dot'
          }
        ];
      }
      return [
        {
          id: `${selectedAddress.lat}|${selectedAddress.lon}`,
          lat: selectedAddress.lat,
          lon: selectedAddress.lon,
          title: selectedAddress.address,
          modelId: `${selectedAddress.lat}|${selectedAddress.lon}`,
          color: 'green-small',
          element: 'dot'
        }
      ];
    }
    return undefined;
  };

  onPeeringdbSiteSelection = (selection, form) => {
    const { peeringFacOptions } = this.state;
    const values = selection.getValue();

    if (values && values.length > 0) {
      const selectedFacOption = peeringFacOptions.find((fac) => values[0] === fac.value);
      const addr = form.getField('address');
      if (selectedFacOption && addr.value.length === 0) {
        addr.setValue(selectedFacOption.address);

        form.setValue('city', selectedFacOption.city);
        form.setValue('region', selectedFacOption.state);
        form.setValue('country', selectedFacOption.country);

        this.setState({
          selectedAddress: {
            lat: selectedFacOption.latitude,
            lon: selectedFacOption.longitude,
            address: selectedFacOption.address,
            city: selectedFacOption.city,
            region: selectedFacOption.state,
            country: selectedFacOption.country
          }
        });
      }
    }
  };

  handleFormChange = ({ form }) => {
    const classification = get(form.getValues(), 'metadata.classification');
    this.setState({ showSubnetConfirm: this.hasLargeSubnet(classification) });
  };

  render() {
    const { model, onClose, $siteMarkets, $lookups } = this.props;
    const { isEditArchitectureOpen, peeringFacOptions, selectedAddress, showSubnetConfirm } = this.state;
    return (
      <FormDialog
        title={this.title}
        entityName="Site"
        fields={fields}
        options={options}
        model={model}
        onClose={onClose}
        maxWidth={750}
        minHeight={600}
        isOpen
        formActionsProps={{
          onSubmit: this.handleSave,
          onCancel: onClose,
          confirmSubmit: showSubnetConfirm,
          confirmBodyContent: 'One or more of your subnets is larger than a /8. Are you sure you want to proceed?'
        }}
        formComponentProps={{ large: true, onChange: this.handleFormChange }}
        showFormActions={!isEditArchitectureOpen}
      >
        {({ form }) => (
          <>
            {!isEditArchitectureOpen && (
              <>
                <Field name="title" autoFocus>
                  <InputGroup width={200} />
                </Field>
                <Field
                  name="peeringdbSiteMapping"
                  options={peeringFacOptions}
                  onChange={(selection) => this.onPeeringdbSiteSelection(selection, form)}
                >
                  <MultiSelect showFilter menuWidth={400} optionLimit={250} fill />
                </Field>
                <Field name="address">
                  <AddressInput
                    onGeocodeComplete={(address) => this.onGeocodeComplete(address, form)}
                    handleMarkers={this.getMarkers}
                  />
                </Field>
                {selectedAddress ? (
                  <>
                    <Flex>
                      <Box flex={1}>
                        <Field
                          name="city"
                          onQuery={$lookups.cityNames}
                          onChange={({ value }) =>
                            this.setState({ selectedAddress: { ...selectedAddress, city: value } })
                          }
                        >
                          <Select autoComplete showFilter />
                        </Field>
                      </Box>
                      <Box flex={1}>
                        <Field
                          name="region"
                          onQuery={$lookups.regions}
                          onChange={({ value }) =>
                            this.setState({ selectedAddress: { ...selectedAddress, region: value } })
                          }
                        >
                          <Select autoComplete showFilter />
                        </Field>
                      </Box>
                      <Box flex={1}>
                        <Field
                          name="country"
                          onQuery={$lookups.countries}
                          onChange={({ value }) =>
                            this.setState({ selectedAddress: { ...selectedAddress, country: value } })
                          }
                        >
                          <Select autoComplete clearable showFilter />
                        </Field>
                      </Box>
                    </Flex>
                  </>
                ) : null}
                <SelectManaged
                  field={form.getField('site_market_id')}
                  modelCollection={$siteMarkets.collection}
                  managePath="/v4/settings/site-markets"
                />
                <Field name="metadata.type">
                  <ToggleButtonGroup />
                </Field>
                <Field name="metadata.architecture">
                  <ArchitectureDisplay onEditArchitectureClick={this.onEditArchitectureClick} />
                </Field>
                <Text as="div" fontWeight="bold" fontSize={14} mb="4px">
                  Site IP Classification
                </Text>
                <Flex justifyContent="space-between">
                  <Tabs vertical flex={1}>
                    <Tab
                      id="host"
                      title="Infrastructure Networks"
                      panelClassName="flexed"
                      panel={
                        <Field name="metadata.classification.host" mb={0}>
                          <TextArea fill rows={4} />
                        </Field>
                      }
                    />
                    <Tab
                      id="employee"
                      title="User Access Networks"
                      panelClassName="flexed"
                      panel={
                        <Field name="metadata.classification.employee" mb={0}>
                          <TextArea fill rows={4} />
                        </Field>
                      }
                    />
                    <Tab
                      id="other"
                      title="Other IPs"
                      panelClassName="flexed"
                      panel={
                        <Field name="metadata.classification.other" mb={0}>
                          <TextArea fill rows={4} />
                        </Field>
                      }
                    />
                  </Tabs>
                </Flex>
              </>
            )}

            {isEditArchitectureOpen && (
              <SiteArchitectureDialog
                architecture={form.getValue('metadata.architecture')}
                devices={form.model.devices}
                onClose={this.handleEditArchitectureClose}
                onSave={(architecture) => this.handleEditArchitectureSave(form, architecture)}
              />
            )}
          </>
        )}
      </FormDialog>
    );
  }
}

export default SiteFormDialog;
