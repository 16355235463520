import React from 'react';
import { withTheme } from 'styled-components';
import { Box } from 'core/components';

import { ReactComponent as A1Logo } from 'app/assets/logos/a1.svg';
import { ReactComponent as AirtelLogo } from 'app/assets/logos/airtel.svg';
import { ReactComponent as AkamaiLogo } from 'app/assets/logos/akamai.svg';
import { ReactComponent as AltiboxLogo } from 'app/assets/logos/altibox.svg';
import { ReactComponent as AlticeLogo } from 'app/assets/logos/altice-optimum.svg';
import { ReactComponent as AmazonLogo } from 'app/assets/logos/amazon.svg';
import { ReactComponent as AppleLogo } from 'app/assets/logos/apple.svg';
import { ReactComponent as ArelionLogo } from 'app/assets/logos/arelion.svg';
import { ReactComponent as Ask4Logo } from 'app/assets/logos/ask4.svg';
import { ReactComponent as Atom86Logo } from 'app/assets/logos/atom86.svg';
import { ReactComponent as ATTLogo } from 'app/assets/logos/att.svg';
import { ReactComponent as AwsLogo } from 'app/assets/logos/aws.svg';
import { ReactComponent as AzureLogo } from 'app/assets/logos/azure.svg';
import { ReactComponent as BatelcoLogo } from 'app/assets/logos/batelco.svg';
import { ReactComponent as BellaliantLogo } from 'app/assets/logos/bellaliant.svg';
import { ReactComponent as BellCALogo } from 'app/assets/logos/bell-canada.svg';
import { ReactComponent as BezeqLogo } from 'app/assets/logos/bezeq.svg';
import { ReactComponent as BicsLogo } from 'app/assets/logos/bics.svg';
import { ReactComponent as BouyguesLogo } from 'app/assets/logos/bouyguestelecom.svg';
import { ReactComponent as BTLogo } from 'app/assets/logos/bt.svg';
import { ReactComponent as CenturyLinkLogo } from 'app/assets/logos/centurylink.svg';
import { ReactComponent as CharterLogo } from 'app/assets/logos/charter_communications.svg';
import { ReactComponent as ChinaMobileLogo } from 'app/assets/logos/chinamobile.svg';
import { ReactComponent as ChinaTelecomLogo } from 'app/assets/logos/china_telecom.svg';
import { ReactComponent as ChinaUnicomLogo } from 'app/assets/logos/china_unicom.svg';
import { ReactComponent as CirionLogo } from 'app/assets/logos/cirion.svg';
import { ReactComponent as ClaroLogo } from 'app/assets/logos/claro.svg';
import { ReactComponent as CloudflareLogo } from 'app/assets/logos/cloudflare.svg';
import { ReactComponent as CogecoLogo } from 'app/assets/logos/cogeco.svg';
import { ReactComponent as CogentLogo } from 'app/assets/logos/cogent.svg';
import { ReactComponent as CologixLogo } from 'app/assets/logos/cologix.svg';
import { ReactComponent as ColtLogo } from 'app/assets/logos/colt.svg';
import { ReactComponent as ComcastLogo } from 'app/assets/logos/comcast.svg';
import { ReactComponent as ComhemLogo } from 'app/assets/logos/comhem.svg';
import { ReactComponent as ConsolidatedLogo } from 'app/assets/logos/consolidated.svg';
import { ReactComponent as CoxLogo } from 'app/assets/logos/cox.svg';
import { ReactComponent as DatafoundryLogo } from 'app/assets/logos/datafoundry.svg';
import { ReactComponent as DigitalRealtyLogo } from 'app/assets/logos/digital_realty.svg';
import { ReactComponent as DNALogo } from 'app/assets/logos/dna.svg';
import { ReactComponent as DTAGLogo } from 'app/assets/logos/deutsche-telecom.svg';
import { ReactComponent as EeLogo } from 'app/assets/logos/ee.svg';
import { ReactComponent as ElisaLogo } from 'app/assets/logos/elisa.svg';
import { ReactComponent as EmbratelLogo } from 'app/assets/logos/embratel.svg';
import { ReactComponent as EquinixLogo } from 'app/assets/logos/equinix.svg';
import { ReactComponent as ErTelecomLogo } from 'app/assets/logos/ertelecom.svg';
import { ReactComponent as EtisalatLogo } from 'app/assets/logos/etisalat.svg';
import { ReactComponent as EUNetworksLogo } from 'app/assets/logos/eunetworks.svg';
import { ReactComponent as EuroFiberLogo } from 'app/assets/logos/eurofiber.svg';
import { ReactComponent as ExatelLogo } from 'app/assets/logos/exatel.svg';
import { ReactComponent as FacebookLogo } from 'app/assets/logos/facebook.svg';
import { ReactComponent as FastlyLogo } from 'app/assets/logos/fastly.svg';
import { ReactComponent as FreeLogo } from 'app/assets/logos/free.svg';
import { ReactComponent as GCXLogo } from 'app/assets/logos/gcx.svg';
import { ReactComponent as GlobenetLogo } from 'app/assets/logos/globenet.svg';
import { ReactComponent as GoogleCloudLogo } from 'app/assets/logos/google_cloud.svg';
import { ReactComponent as GoogleFiberLogo } from 'app/assets/logos/google-fiber.svg';
import { ReactComponent as GoogleLogo } from 'app/assets/logos/google.svg';
import { ReactComponent as GTDLogo } from 'app/assets/logos/gtd.svg';
import { ReactComponent as GTTLogo } from 'app/assets/logos/gtt.svg';
import { ReactComponent as HeLogo } from 'app/assets/logos/hurricaneelectric.svg';
import { ReactComponent as HopusLogo } from 'app/assets/logos/hopus.svg';
import { ReactComponent as InapLogo } from 'app/assets/logos/inap.svg';
import { ReactComponent as IX_AmsixLogo } from 'app/assets/logos/ix_amsix.svg';
import { ReactComponent as IX_Any2Logo } from 'app/assets/logos/ix_coresite_any2.svg';
import { ReactComponent as IX_AsteroidLogo } from 'app/assets/logos/ix_asteroid.svg';
import { ReactComponent as IX_BalcanixLogo } from 'app/assets/logos/ix_balcanix.svg';
import { ReactComponent as IX_BbixLogo } from 'app/assets/logos/ix_bbix.svg';
import { ReactComponent as IX_BcixLogo } from 'app/assets/logos/ix_bcix.svg';
import { ReactComponent as IX_BixLogo } from 'app/assets/logos/ix_bix.svg';
import { ReactComponent as IX_CabaseLogo } from 'app/assets/logos/ix_cabase.svg';
import { ReactComponent as IX_ChixLogo } from 'app/assets/logos/ix_chix.svg';
import { ReactComponent as IX_CixatlLogo } from 'app/assets/logos/ix_cixatl_community.svg';
import { ReactComponent as IX_DataixLogo } from 'app/assets/logos/ix_dataix.svg';
import { ReactComponent as IX_DecixLogo } from 'app/assets/logos/ix_decix.svg';
import { ReactComponent as IX_IxDenverLogo } from 'app/assets/logos/ix_denver.svg';
import { ReactComponent as IX_EcixLogo } from 'app/assets/logos/ix_ecix.svg';
import { ReactComponent as IX_EpixLogo } from 'app/assets/logos/ix_epix.svg';
import { ReactComponent as IX_EspanixLogo } from 'app/assets/logos/ix_espanix.svg';
import { ReactComponent as IX_ExtremeixLogo } from 'app/assets/logos/ix_extremeix.svg';
import { ReactComponent as IX_FlixLogo } from 'app/assets/logos/ix_flix_community.svg';
import { ReactComponent as IX_FranceixLogo } from 'app/assets/logos/ix_franceix.svg';
import { ReactComponent as IX_GlobalixLogo } from 'app/assets/logos/ix_globalix.svg';
import { ReactComponent as IX_GrixLogo } from 'app/assets/logos/ix_grix.svg';
import { ReactComponent as IX_HkixLogo } from 'app/assets/logos/ix_hkix.svg';
import { ReactComponent as IX_InexLogo } from 'app/assets/logos/ix_inex.svg';
import { ReactComponent as IX_InterlanLogo } from 'app/assets/logos/ix_interlan.svg';
import { ReactComponent as IX_IxBrLogo } from 'app/assets/logos/ix_ixbr.svg';
import { ReactComponent as IX_JinxLogo } from 'app/assets/logos/ix_jinx.svg';
import { ReactComponent as IX_JpixLogo } from 'app/assets/logos/ix_jpix.svg';
import { ReactComponent as IX_JpnapLogo } from 'app/assets/logos/ix_jpnap.svg';
import { ReactComponent as IX_KinxLogo } from 'app/assets/logos/ix_kinx.svg';
import { ReactComponent as IX_LinxLogo } from 'app/assets/logos/ix_linx.svg';
import { ReactComponent as IX_LonapLogo } from 'app/assets/logos/ix_lonap.svg';
import { ReactComponent as IX_MexixLogo } from 'app/assets/logos/ix_mex-ix.svg';
import { ReactComponent as IX_MixLogo } from 'app/assets/logos/ix_mix.svg';
import { ReactComponent as IX_MskixLogo } from 'app/assets/logos/ix_msk-ix.svg';
import { ReactComponent as IX_NamexLogo } from 'app/assets/logos/ix_namex.svg';
import { ReactComponent as IX_NapafricaLogo } from 'app/assets/logos/ix_napafrica.svg';
import { ReactComponent as IX_NetixLogo } from 'app/assets/logos/ix_netix.svg';
import { ReactComponent as IX_NetnodLogo } from 'app/assets/logos/ix_netnod.svg';
import { ReactComponent as IX_NixiLogo } from 'app/assets/logos/ix_nixi.svg';
import { ReactComponent as IX_NlixLogo } from 'app/assets/logos/ix_nlix.svg';
import { ReactComponent as IX_NswixAustraliaLogo } from 'app/assets/logos/ix_nswix_australia.svg';
import { ReactComponent as IX_NxczLogo } from 'app/assets/logos/ix_nxcz.svg';
import { ReactComponent as IX_NwaxLogo } from 'app/assets/logos/ix_nwax.svg';
import { ReactComponent as IX_NxskLogo } from 'app/assets/logos/ix_nxsk.svg';
import { ReactComponent as IX_NyiixLogo } from 'app/assets/logos/ix_nyiix.svg';
import { ReactComponent as IX_PeeringCzLogo } from 'app/assets/logos/ix_peering_cz.svg';
import { ReactComponent as IX_PitPeruLogo } from 'app/assets/logos/IX_pit-peru.svg';
import { ReactComponent as IX_PhillyixLogo } from 'app/assets/logos/ix_phillyix.svg';
import { ReactComponent as IX_PipeixLogo } from 'app/assets/logos/ix_pipeIX.svg';
import { ReactComponent as IX_PitLogo } from 'app/assets/logos/ix_pit.svg';
import { ReactComponent as IX_QixMontrealLogo } from 'app/assets/logos/ix_qix-montreal.svg';
import { ReactComponent as IX_RonixLogo } from 'app/assets/logos/ix_ronix.svg';
import { ReactComponent as IX_SFMixLogo } from 'app/assets/logos/ix_sfmix.svg';
import { ReactComponent as IX_SgixLogo } from 'app/assets/logos/ix_sgix.svg';
import { ReactComponent as IX_SixLogo } from 'app/assets/logos/ix_six.svg';
import { ReactComponent as IX_SoxLogo } from 'app/assets/logos/ix_sox.svg';
import { ReactComponent as IX_SpeedixLogo } from 'app/assets/logos/ix_speedix.svg';
import { ReactComponent as IX_SthixLogo } from 'app/assets/logos/ix_sthix.svg';
import { ReactComponent as IX_SwissExchangeLogo } from 'app/assets/logos/ix_swiss_exchange.svg';
import { ReactComponent as IX_TelxLogo } from 'app/assets/logos/ix_telx.svg';
import { ReactComponent as IX_TopixLogo } from 'app/assets/logos/ix_topix.svg';
import { ReactComponent as IX_TorixLogo } from 'app/assets/logos/ix_torix.svg';
import { ReactComponent as IX_VanixLogo } from 'app/assets/logos/ix_vanix.svg';
import { ReactComponent as IX_VixLogo } from 'app/assets/logos/ix_vix.svg';
import { ReactComponent as JapanTelecomLogo } from 'app/assets/logos/japan_telecom.svg';
import { ReactComponent as JioLogo } from 'app/assets/logos/jio.svg';
import { ReactComponent as JupitertelecomLogo } from 'app/assets/logos/jupitertelecom.svg';
import { ReactComponent as KazakhtelecomLogo } from 'app/assets/logos/kazakhtelecom.svg';
import { ReactComponent as KDDILogo } from 'app/assets/logos/kddi.svg';
import { ReactComponent as KoreaTelecomLogo } from 'app/assets/logos/korea_telecom.svg';
import { ReactComponent as KvanttLogo } from 'app/assets/logos/kvantt.svg';
import { ReactComponent as LeasewebLogo } from 'app/assets/logos/leaseweb.svg';
import { ReactComponent as Level3Logo } from 'app/assets/logos/level3.svg';
import { ReactComponent as LGULogo } from 'app/assets/logos/lgu.svg';
import { ReactComponent as LibertyGlobalLogo } from 'app/assets/logos/liberty_global.svg';
import { ReactComponent as LightowerLogo } from 'app/assets/logos/lightower.svg';
import { ReactComponent as LimelightLogo } from 'app/assets/logos/limelight.svg';
import { ReactComponent as LumenLogo } from 'app/assets/logos/lumen.svg';
import { ReactComponent as MediacomLogo } from 'app/assets/logos/mediacom.svg';
import { ReactComponent as MegafonLogo } from 'app/assets/logos/megafon.svg';
import { ReactComponent as MegaportLogo } from 'app/assets/logos/megaport.svg';
import { ReactComponent as MicrosoftLogo } from 'app/assets/logos/microsoft.svg';
import { ReactComponent as MoratelindoLogo } from 'app/assets/logos/moratelindo.svg';
import { ReactComponent as MTNLogo } from 'app/assets/logos/mtn.svg';
import { ReactComponent as MTSLogo } from 'app/assets/logos/mts.svg';
import { ReactComponent as NeteaseLogo } from 'app/assets/logos/netease.svg';
import { ReactComponent as NetflixLogo } from 'app/assets/logos/netflix.svg';
import { ReactComponent as NexellentLogo } from 'app/assets/logos/netrics_nexellent.svg';
import { ReactComponent as NOSLogo } from 'app/assets/logos/nos.svg';
import { ReactComponent as NTTLogo } from 'app/assets/logos/ntt.svg';
import { ReactComponent as OmantelLogo } from 'app/assets/logos/omantel.svg';
import { ReactComponent as OptusLogo } from 'app/assets/logos/optus.svg';
import { ReactComponent as OrangeLogo } from 'app/assets/logos/orange.svg';
import { ReactComponent as OTELogo } from 'app/assets/logos/ote.svg';
import { ReactComponent as PacketFabricLogo } from 'app/assets/logos/packetfabric.svg';
import { ReactComponent as PCCWLogo } from 'app/assets/logos/pccw.svg';
import { ReactComponent as ProximusLogo } from 'app/assets/logos/proximus.svg';
import { ReactComponent as RascomLogo } from 'app/assets/logos/rascom.svg';
import { ReactComponent as RetnLogo } from 'app/assets/logos/retn.svg';
import { ReactComponent as RogersLogo } from 'app/assets/logos/rogers.svg';
import { ReactComponent as RostelecomLogo } from 'app/assets/logos/rostelecom.svg';
import { ReactComponent as SavvisLogo } from 'app/assets/logos/savvis.svg';
import { ReactComponent as SeabornLogo } from 'app/assets/logos/seaborn.svg';
import { ReactComponent as SeacomLogo } from 'app/assets/logos/seacom.svg';
import { ReactComponent as SfrLogo } from 'app/assets/logos/sfr.svg';
import { ReactComponent as ShawLogo } from 'app/assets/logos/shaw.svg';
import { ReactComponent as SiminnLogo } from 'app/assets/logos/siminn.svg';
import { ReactComponent as SingtelLogo } from 'app/assets/logos/singtel.svg';
import { ReactComponent as SKTelecomLogo } from 'app/assets/logos/sk_telecom.svg';
import { ReactComponent as SkyUkLogo } from 'app/assets/logos/skyuk.svg';
import { ReactComponent as SonicLogo } from 'app/assets/logos/sonic.svg';
import { ReactComponent as SparklightLogo } from 'app/assets/logos/sparklight.svg';
import { ReactComponent as SparknzLogo } from 'app/assets/logos/sparknz.svg';
import { ReactComponent as SpectrumLogo } from 'app/assets/logos/spectrum.svg';
import { ReactComponent as SprintLogo } from 'app/assets/logos/sprint.svg';
import { ReactComponent as StackpathLogo } from 'app/assets/logos/stackpath.svg';
import { ReactComponent as StarhubLogo } from 'app/assets/logos/starhub.svg';
import { ReactComponent as STCLogo } from 'app/assets/logos/stc.svg';
import { ReactComponent as SuddenlinkLogo } from 'app/assets/logos/suddenlink.svg';
import { ReactComponent as SuperloopLogo } from 'app/assets/logos/superloop.svg';
import { ReactComponent as SwisscomLogo } from 'app/assets/logos/swisscom.svg';
import { ReactComponent as SymphonyLogo } from 'app/assets/logos/symphony.svg';
import { ReactComponent as TalktalkLogo } from 'app/assets/logos/talktalk.svg';
import { ReactComponent as TataCommunicationsLogo } from 'app/assets/logos/tata.svg';
import { ReactComponent as TDCLogo } from 'app/assets/logos/tdc.svg';
import { ReactComponent as Tele2Logo } from 'app/assets/logos/tele2.svg';
import { ReactComponent as TelecomItaliaSparkleLogo } from 'app/assets/logos/ti_sparkle.svg';
import { ReactComponent as TelefonicaLogo } from 'app/assets/logos/telefonica.svg';
import { ReactComponent as TelenetLogo } from 'app/assets/logos/telenet.svg';
import { ReactComponent as TeliaLogo } from 'app/assets/logos/telia.svg';
import { ReactComponent as TelkomindonesiaLogo } from 'app/assets/logos/telkomindonesia.svg';
import { ReactComponent as TelmexLogo } from 'app/assets/logos/telmex.svg';
import { ReactComponent as TelstraLogo } from 'app/assets/logos/telstra.svg';
import { ReactComponent as TelusLogo } from 'app/assets/logos/telus.svg';
import { ReactComponent as TelxiusLogo } from 'app/assets/logos/telxius.svg';
import { ReactComponent as TotalplayLogo } from 'app/assets/logos/totalplay.svg';
import { ReactComponent as TPGLogo } from 'app/assets/logos/tpgtelecom.svg';
import { ReactComponent as TranstelekomLogo } from 'app/assets/logos/transtelekom.svg';
import { ReactComponent as TurkCellLogo } from 'app/assets/logos/turkcell.svg';
import { ReactComponent as TurkTelekomLogo } from 'app/assets/logos/turk_telekom.svg';
import { ReactComponent as TwitchLogo } from 'app/assets/logos/twitch.svg';
import { ReactComponent as VeonLogo } from 'app/assets/logos/veon.svg';
import { ReactComponent as VerizonLogo } from 'app/assets/logos/verizon.svg';
import { ReactComponent as VideotronLogo } from 'app/assets/logos/videotron.svg';
import { ReactComponent as ViettelLogo } from 'app/assets/logos/viettel.svg';
import { ReactComponent as VirginMediaLogo } from 'app/assets/logos/virginmedia.svg';
import { ReactComponent as VocusLogo } from 'app/assets/logos/vocus.svg';
import { ReactComponent as VodafoneLogo } from 'app/assets/logos/vodafone.svg';
import { ReactComponent as WebwerksLogo } from 'app/assets/logos/webwerks.svg';
import { ReactComponent as XOCommuncationsLogo } from 'app/assets/logos/xo_communications.svg';
import { ReactComponent as Xs4allLogo } from 'app/assets/logos/xs4all.svg';
import { ReactComponent as ZayoLogo } from 'app/assets/logos/zayo.svg';
import { ReactComponent as ZiggoLogo } from 'app/assets/logos/ziggo.svg';
import { ReactComponent as ArmstrongLogo } from 'app/assets/logos/armstrong.svg';
import { ReactComponent as AtlanticBroadbandLogo } from 'app/assets/logos/atlantic-broadband.svg';
import { ReactComponent as EarthlinkLogo } from 'app/assets/logos/earthlink.svg';
import { ReactComponent as EdgeunoLogo } from 'app/assets/logos/edgeuno.svg';
import { ReactComponent as FrontierLogo } from 'app/assets/logos/frontier.svg';
import { ReactComponent as GciLogo } from 'app/assets/logos/gci.svg';
import { ReactComponent as IX_LucixLogo } from 'app/assets/logos/ix_lucix.svg';
import { ReactComponent as RcnLogo } from 'app/assets/logos/rcn.svg';
import { ReactComponent as IX_TpixLogo } from 'app/assets/logos/ix_tpix.svg';
import { ReactComponent as ViasatLogo } from 'app/assets/logos/viasat.svg';
import { ReactComponent as WaveLogo } from 'app/assets/logos/wave.svg';
import { ReactComponent as WowLogo } from 'app/assets/logos/wow.svg';
import { ReactComponent as MidcoLogo } from 'app/assets/logos/midco.svg';
import { ReactComponent as AlgarLogo } from 'app/assets/logos/algar.svg';
import { ReactComponent as AlibabacloudLogo } from 'app/assets/logos/alibabacloud.svg';
import { ReactComponent as AngolacablesLogo } from 'app/assets/logos/angolacables.svg';
import { ReactComponent as CommcorpLogo } from 'app/assets/logos/commcorp_brdigital.svg';
import { ReactComponent as DitoLogo } from 'app/assets/logos/dito.svg';
import { ReactComponent as DocomoLogo } from 'app/assets/logos/docomo.svg';
import { ReactComponent as EastlinkLogo } from 'app/assets/logos/eastlink.svg';
import { ReactComponent as ExetelLogo } from 'app/assets/logos/exetel.svg';
import { ReactComponent as FptLogo } from 'app/assets/logos/fpt.svg';
import { ReactComponent as GiganetLogo } from 'app/assets/logos/giganet.svg';
import { ReactComponent as HinetLogo } from 'app/assets/logos/hinet.svg';
import { ReactComponent as HikommLogo } from 'app/assets/logos/hikomm_pyur.svg';
import { ReactComponent as IX_TrueLogo } from 'app/assets/logos/ix_true.svg';
import { ReactComponent as KopticomLogo } from 'app/assets/logos/kopticom.svg';
import { ReactComponent as M1Logo } from 'app/assets/logos/m1.svg';
import { ReactComponent as MoldTelecomLogo } from 'app/assets/logos/moldtelecom.svg';
import { ReactComponent as MttLogo } from 'app/assets/logos/mtt.svg';
import { ReactComponent as PldtLogo } from 'app/assets/logos/pldt.svg';
import { ReactComponent as RakutenMobileLogo } from 'app/assets/logos/rakutenmobile.svg';
import { ReactComponent as SinetLogo } from 'app/assets/logos/sinet.svg';
import { ReactComponent as SoftbankLogo } from 'app/assets/logos/softbank.svg';
import { ReactComponent as TeksavvyLogo } from 'app/assets/logos/teksavvy.svg';
import { ReactComponent as TencentLogo } from 'app/assets/logos/tencent.svg';
import { ReactComponent as TmobileLogo } from 'app/assets/logos/tmobile.svg';
import { ReactComponent as TranstelcoLogo } from 'app/assets/logos/transtelco.svg';
import { ReactComponent as VerisignLogo } from 'app/assets/logos/verisign.svg';
import { ReactComponent as VnptLogo } from 'app/assets/logos/vnpt.svg';

export const providerLogos = [
  { value: 'a1', logo: A1Logo, label: 'A1' },
  { value: 'airtel', logo: AirtelLogo, label: 'Airtel' },
  { value: 'akamai', logo: AkamaiLogo, label: 'Akamai' },
  { value: 'altibox', logo: AltiboxLogo, label: 'Altibox' },
  { value: 'altice', logo: AlticeLogo, label: 'Altice (Optimum, Cablebvision)' },
  { value: 'amazon', logo: AmazonLogo, label: 'Amazon' },
  { value: 'amsix', logo: IX_AmsixLogo, label: 'AMSIX' },
  { value: 'any2', logo: IX_Any2Logo, label: 'Coresite Any2' },
  { value: 'apple', logo: AppleLogo, label: 'Apple' },
  { value: 'arelion', logo: ArelionLogo, label: 'Arelion' },
  { value: 'ask4', logo: Ask4Logo, label: 'ASK4' },
  { value: 'asteroid', logo: IX_AsteroidLogo, label: 'Asteroid IX' },
  { value: 'balcanix', logo: IX_BalcanixLogo, label: 'Balcan-IX' },
  { value: 'atom86', logo: Atom86Logo, label: 'Atom86' },
  { value: 'att', logo: ATTLogo, label: 'AT&T' },
  { value: 'aws', logo: AwsLogo, label: 'Amazon AWS' },
  { value: 'azure', logo: AzureLogo, label: 'Microsoft Azure' },
  { value: 'batelco', logo: BatelcoLogo, label: 'Batelco' },
  { value: 'bbix', logo: IX_BbixLogo, label: 'BBIX' },
  { value: 'bcix', logo: IX_BcixLogo, label: 'BCIX' },
  { value: 'bell', logo: BellCALogo, label: 'Bell Canada' },
  { value: 'bellaliant', logo: BellaliantLogo, label: 'Bell Aliant' },
  { value: 'bezeq', logo: BezeqLogo, label: 'Bezeq' },
  { value: 'bics', logo: BicsLogo, label: 'BICS (Belgacom)' },
  { value: 'bixbg', logo: IX_BixLogo, label: 'BIX.BG' },
  { value: 'bouygues', logo: BouyguesLogo, label: 'Bouygues Telecom' },
  { value: 'bt', logo: BTLogo, label: 'British Telecom' },
  { value: 'cabase', logo: IX_CabaseLogo, label: 'Cabase IX' },
  { value: 'centurylink', logo: CenturyLinkLogo, label: 'CenturyLink' },
  { value: 'charter', logo: CharterLogo, label: 'Charter Communications' },
  { value: 'chinamobile', logo: ChinaMobileLogo, label: 'China Mobile' },
  { value: 'chinatelecom', logo: ChinaTelecomLogo, label: 'China Telecom' },
  { value: 'chinaunicom', logo: ChinaUnicomLogo, label: 'China Unicom' },
  { value: 'chix', logo: IX_ChixLogo, label: 'CHIX United Exchange ' },
  { value: 'cixatl', logo: IX_CixatlLogo, label: 'CIX-ATL' },
  { value: 'cirion', logo: CirionLogo, label: 'Cirion' },
  { value: 'claro', logo: ClaroLogo, label: 'Claro' },
  { value: 'cloudflare', logo: CloudflareLogo, label: 'Cloudflare' },
  { value: 'cogeco', logo: CogecoLogo, label: 'Cogeco' },
  { value: 'cogent', logo: CogentLogo, label: 'Cogent' },
  { value: 'cologix', logo: CologixLogo, label: 'Cologix' },
  { value: 'colt', logo: ColtLogo, label: 'Colt Technology Services' },
  { value: 'comcast', logo: ComcastLogo, label: 'Comcast' },
  { value: 'comhem', logo: ComhemLogo, label: 'Comhem' },
  { value: 'consolidated', logo: ConsolidatedLogo, label: 'Consolidated' },
  { value: 'cox', logo: CoxLogo, label: 'Cox Communications' },
  { value: 'datafoundry', logo: DatafoundryLogo, label: 'Data Foundry' },
  { value: 'dataix', logo: IX_DataixLogo, label: 'DATAIX' },
  { value: 'decix', logo: IX_DecixLogo, label: 'DECIX' },
  { value: 'denver', logo: IX_IxDenverLogo, label: 'IX-Denver' },
  { value: 'digitalrealty', logo: DigitalRealtyLogo, label: 'Digital Realty' },
  { value: 'dna', logo: DNALogo, label: 'DNA' },
  { value: 'dtag', logo: DTAGLogo, label: 'Deutsche Telekom AG' },
  { value: 'ecix', logo: IX_EcixLogo, label: 'ECIX' },
  { value: 'ee', logo: EeLogo, label: 'EE (Everything Everywhere)' },
  { value: 'elisa', logo: ElisaLogo, label: 'ELISA' },
  { value: 'embratel', logo: EmbratelLogo, label: 'Embratel' },
  { value: 'epix', logo: IX_EpixLogo, label: 'EPIX' },
  { value: 'equinix', logo: EquinixLogo, label: 'Equinix' },
  { value: 'ertelecom', logo: ErTelecomLogo, label: 'ER Telecom' },
  { value: 'espanix', logo: IX_EspanixLogo, label: 'Espanix' },
  { value: 'etisalat', logo: EtisalatLogo, label: 'Etisalat' },
  { value: 'eunetworks', logo: EUNetworksLogo, label: 'EUNetworks' },
  { value: 'eurofiber', logo: EuroFiberLogo, label: 'EuroFiber' },
  { value: 'exatel', logo: ExatelLogo, label: 'Exatel' },
  { value: 'extremeix', logo: IX_ExtremeixLogo, label: 'ExtremeIX' },
  { value: 'facebook', logo: FacebookLogo, label: 'Facebook' },
  { value: 'fastly', logo: FastlyLogo, label: 'Fastly' },
  { value: 'flix', logo: IX_FlixLogo, label: 'FLIX Community IX' },
  { value: 'franceix', logo: IX_FranceixLogo, label: 'France-IX' },
  { value: 'free', logo: FreeLogo, label: 'Free (Proxad)' },
  { value: 'gcx', logo: GCXLogo, label: 'GCX Global Cloud Exchange' },
  { value: 'globenet', logo: GlobenetLogo, label: 'GlobeNet' },
  { value: 'globalix', logo: IX_GlobalixLogo, label: 'Global IX' },
  { value: 'google', logo: GoogleLogo, label: 'Google' },
  { value: 'googlecloud', logo: GoogleCloudLogo, label: 'Google Cloud' },
  { value: 'googlefiber', logo: GoogleFiberLogo, label: 'Google Fiber' },
  { value: 'grix', logo: IX_GrixLogo, label: 'GRIX' },
  { value: 'gtd', logo: GTDLogo, label: 'GTD' },
  { value: 'gtt', logo: GTTLogo, label: 'GTT' },
  { value: 'he', logo: HeLogo, label: 'Hurricane Electric' },
  { value: 'hkix', logo: IX_HkixLogo, label: 'HKIX' },
  { value: 'hopus', logo: HopusLogo, label: 'HOP.us' },
  { value: 'inap', logo: InapLogo, label: 'INAP (InterNap)' },
  { value: 'inex', logo: IX_InexLogo, label: 'INEX' },
  { value: 'interlan', logo: IX_InterlanLogo, label: 'Interlan IX' },
  { value: 'ixbr', logo: IX_IxBrLogo, label: 'IX.BR' },
  { value: 'japantelecom', logo: JapanTelecomLogo, label: 'Japan Telecom' },
  { value: 'jinx', logo: IX_JinxLogo, label: 'JINX / INX-ZA' },
  { value: 'jio', logo: JioLogo, label: 'JIO' },
  { value: 'jpix', logo: IX_JpixLogo, label: 'JPIX' },
  { value: 'jpnap', logo: IX_JpnapLogo, label: 'JPNAP' },
  { value: 'jupitertelecom', logo: JupitertelecomLogo, label: 'J:COM Jupiter Telecom' },
  { value: 'kazakh', logo: KazakhtelecomLogo, label: 'Kazakh Telecom' },
  { value: 'kddi', logo: KDDILogo, label: 'KDDI' },
  { value: 'kinx', logo: IX_KinxLogo, label: 'KINX' },
  { value: 'koreatelecom', logo: KoreaTelecomLogo, label: 'Korea Telecom' },
  { value: 'kvantt', logo: KvanttLogo, label: 'Kvantt' },
  { value: 'leaseweb', logo: LeasewebLogo, label: 'Leaseweb' },
  { value: 'level3', logo: Level3Logo, label: 'Level3' },
  { value: 'lgu', logo: LGULogo, label: 'LGU' },
  { value: 'libertyglobal', logo: LibertyGlobalLogo, label: 'Liberty Global' },
  { value: 'lightower', logo: LightowerLogo, label: 'Lightower' },
  { value: 'limelight', logo: LimelightLogo, label: 'Limelight Networks' },
  { value: 'linx', logo: IX_LinxLogo, label: 'Linx' },
  { value: 'lonap', logo: IX_LonapLogo, label: 'Lonap' },
  { value: 'lumen', logo: LumenLogo, label: 'Lumen' },
  { value: 'mediacom', logo: MediacomLogo, label: 'Mediacom' },
  { value: 'megafon', logo: MegafonLogo, label: 'Megafon' },
  { value: 'megaport', logo: MegaportLogo, label: 'Megaport' },
  { value: 'mexix', logo: IX_MexixLogo, label: 'Mex-IX' },
  { value: 'microsoft', logo: MicrosoftLogo, label: 'Microsoft' },
  { value: 'mix', logo: IX_MixLogo, label: 'MIX (Milan)' },
  { value: 'moratelindo', logo: MoratelindoLogo, label: 'Moratelindo' },
  { value: 'mskix', logo: IX_MskixLogo, label: 'MSK-IX' },
  { value: 'mtn', logo: MTNLogo, label: 'MTN' },
  { value: 'mts', logo: MTSLogo, label: 'MTS' },
  { value: 'namex', logo: IX_NamexLogo, label: 'Namex' },
  { value: 'napafrica', logo: IX_NapafricaLogo, label: 'NapAfrica' },
  { value: 'netease', logo: NeteaseLogo, label: 'Netease' },
  { value: 'netflix', logo: NetflixLogo, label: 'Netflix' },
  { value: 'nexellent', logo: NexellentLogo, label: 'Netrics (Nexellent)' },
  { value: 'netix', logo: IX_NetixLogo, label: 'NetIX' },
  { value: 'netnod', logo: IX_NetnodLogo, label: 'NetNod' },
  { value: 'nixi', logo: IX_NixiLogo, label: 'NIXI' },
  { value: 'nlix', logo: IX_NlixLogo, label: 'NLix' },
  { value: 'nos', logo: NOSLogo, label: 'NOS' },
  { value: 'nswix', logo: IX_NswixAustraliaLogo, label: 'NSWIX Australia' },
  { value: 'ntt', logo: NTTLogo, label: 'NTT' },
  { value: 'nixcz', logo: IX_NxczLogo, label: 'NIX.CZ' },
  { value: 'nwax', logo: IX_NwaxLogo, label: 'NWAX IX' },
  { value: 'nixsk', logo: IX_NxskLogo, label: 'NIX.SK' },
  { value: 'nyiix', logo: IX_NyiixLogo, label: 'NYIIX' },
  { value: 'omantel', logo: OmantelLogo, label: 'OmanTel' },
  { value: 'optus', logo: OptusLogo, label: 'Optus' },
  { value: 'orange', logo: OrangeLogo, label: 'Orange' },
  { value: 'ote', logo: OTELogo, label: 'OTE' },
  { value: 'packetfabric', logo: PacketFabricLogo, label: 'PacketFabric' },
  { value: 'pccw', logo: PCCWLogo, label: 'PCCW Global' },
  { value: 'peeringcz', logo: IX_PeeringCzLogo, label: 'peering.cz' },
  { value: 'peru', logo: IX_PitPeruLogo, label: 'Pit - Peru IX' },
  { value: 'philly', logo: IX_PhillyixLogo, label: 'Philly IX' },
  { value: 'pipeix', logo: IX_PipeixLogo, label: 'PipeIX' },
  { value: 'pit', logo: IX_PitLogo, label: 'PIT - IXP Chile' },
  { value: 'proximus', logo: ProximusLogo, label: 'Proximus' },
  { value: 'qix', logo: IX_QixMontrealLogo, label: 'QIX Montreal' },
  { value: 'rascom', logo: RascomLogo, label: 'Rascom' },
  { value: 'retn', logo: RetnLogo, label: 'RETN' },
  { value: 'rogers', logo: RogersLogo, label: 'Rogers' },
  { value: 'ronix', logo: IX_RonixLogo, label: 'RonIX (Romania IX)' },
  { value: 'rostelecom', logo: RostelecomLogo, label: 'RosTelecom' },
  { value: 'savvis', logo: SavvisLogo, label: 'Seacom' },
  { value: 'seaborn', logo: SeabornLogo, label: 'Seaborn' },
  { value: 'seacom', logo: SeacomLogo, label: 'Savvis' },
  { value: 'sfmix', logo: IX_SFMixLogo, label: 'SFMIX' },
  { value: 'sfr', logo: SfrLogo, label: 'SFR' },
  { value: 'sgix', logo: IX_SgixLogo, label: 'SGIX' },
  { value: 'shaw', logo: ShawLogo, label: 'Shaw' },
  { value: 'siminn', logo: SiminnLogo, label: 'Siminn' },
  { value: 'singtel', logo: SingtelLogo, label: 'SingTel' },
  { value: 'six', logo: IX_SixLogo, label: 'Seattle Internet Exchange' },
  { value: 'sktelecom', logo: SKTelecomLogo, label: 'SK Telecom' },
  { value: 'sky', logo: SkyUkLogo, label: 'Sky UK' },
  { value: 'sonic', logo: SonicLogo, label: 'Sonic' },
  { value: 'sox', logo: IX_SoxLogo, label: 'SOX (Serbian Open eXchange)' },
  { value: 'sparklight', logo: SparklightLogo, label: 'Sparklight (CableONE)' },
  { value: 'sparknz', logo: SparknzLogo, label: 'SPARK nz' },
  { value: 'spectrum', logo: SpectrumLogo, label: 'Spectrum' },
  { value: 'speedix', logo: IX_SpeedixLogo, label: 'SPEEDIX' },
  { value: 'sprint', logo: SprintLogo, label: 'Sprint' },
  { value: 'stackpath', logo: StackpathLogo, label: 'Stackpath / Highwinds / MaxCDN' },
  { value: 'starhub', logo: StarhubLogo, label: 'Starhub' },
  { value: 'stc', logo: STCLogo, label: 'Saudi Telecom STC' },
  { value: 'sthix', logo: IX_SthixLogo, label: 'STHIX' },
  { value: 'suddenlink', logo: SuddenlinkLogo, label: 'Suddenlink' },
  { value: 'superloop', logo: SuperloopLogo, label: 'Superloop' },
  { value: 'swisscom', logo: SwisscomLogo, label: 'SwissCom' },
  { value: 'swissexchange', logo: IX_SwissExchangeLogo, label: 'Swiss Exchange' },
  { value: 'telx', logo: IX_TelxLogo, label: 'Telx Exchange' },
  { value: 'symphony', logo: SymphonyLogo, label: 'Symphony' },
  { value: 'talktalk', logo: TalktalkLogo, label: 'Talk Talk' },
  { value: 'tata', logo: TataCommunicationsLogo, label: 'Tata Communications' },
  { value: 'tdc', logo: TDCLogo, label: 'TDC' },
  { value: 'tele2', logo: Tele2Logo, label: 'Tele2' },
  { value: 'telecomitalia', logo: TelecomItaliaSparkleLogo, label: 'Telecom Italia Sparkle' },
  { value: 'telefonica', logo: TelefonicaLogo, label: 'Telefonica' },
  { value: 'telenet', logo: TelenetLogo, label: 'Telenet' },
  { value: 'telia', logo: TeliaLogo, label: 'Telia Sonera' },
  { value: 'telkomindonesia', logo: TelkomindonesiaLogo, label: 'Telkom Indonesia' },
  { value: 'telmex', logo: TelmexLogo, label: 'Telmex' },
  { value: 'telstra', logo: TelstraLogo, label: 'Telstra' },
  { value: 'telus', logo: TelusLogo, label: 'Telus' },
  { value: 'telxius', logo: TelxiusLogo, label: 'Telxius' },
  { value: 'topix', logo: IX_TopixLogo, label: 'TOPIX' },
  { value: 'torix', logo: IX_TorixLogo, label: 'TORIX' },
  { value: 'totalplay', logo: TotalplayLogo, label: 'TotalPlay' },
  { value: 'tpg', logo: TPGLogo, label: 'TPG Telecom' },
  { value: 'transtelekom', logo: TranstelekomLogo, label: 'TransTelekom' },
  { value: 'turkcell', logo: TurkCellLogo, label: 'Turkcell' },
  { value: 'turktelekom', logo: TurkTelekomLogo, label: 'Turk Telekom' },
  { value: 'twitch', logo: TwitchLogo, label: 'Twitch' },
  { value: 'vanix', logo: IX_VanixLogo, label: 'VANIX' },
  { value: 'veon', logo: VeonLogo, label: 'Veon' },
  { value: 'verizon', logo: VerizonLogo, label: 'Verizon' },
  { value: 'videotron', logo: VideotronLogo, label: 'Videotron' },
  { value: 'viettel', logo: ViettelLogo, label: 'Viettel' },
  { value: 'virgin', logo: VirginMediaLogo, label: 'Virgin Media' },
  { value: 'vix', logo: IX_VixLogo, label: 'VIX (Vienna Internet eXchange)' },
  { value: 'vocus', logo: VocusLogo, label: 'Vocus' },
  { value: 'vodafone', logo: VodafoneLogo, label: 'Vodafone' },
  { value: 'webwerks', logo: WebwerksLogo, label: 'Webwerks' },
  { value: 'xo', logo: XOCommuncationsLogo, label: 'XO Communications (Verizon)' },
  { value: 'xs4all', logo: Xs4allLogo, label: 'xs4all' },
  { value: 'zayo', logo: ZayoLogo, label: 'Zayo' },
  { value: 'ziggo', logo: ZiggoLogo, label: 'Ziggo' },
  { value: 'armstrong', logo: ArmstrongLogo, label: 'Armstrong' },
  { value: 'atlantic', logo: AtlanticBroadbandLogo, label: 'Atlantic Broadband' },
  { value: 'earthlink', logo: EarthlinkLogo, label: 'Earthlink Communications' },
  { value: 'edgeuno', logo: EdgeunoLogo, label: 'Edgeuno' },
  { value: 'frontier', logo: FrontierLogo, label: 'Frontier Communications' },
  { value: 'gci', logo: GciLogo, label: 'GCI' },
  { value: 'lucix', logo: IX_LucixLogo, label: 'LU-CIX' },
  { value: 'midco', logo: MidcoLogo, label: 'Midco' },
  { value: 'rcn', logo: RcnLogo, label: 'RCN' },
  { value: 'tpix', logo: IX_TpixLogo, label: 'TP-IX Taipei' },
  { value: 'viasat', logo: ViasatLogo, label: 'Viasat' },
  { value: 'wave', logo: WaveLogo, label: 'Wave Broadband' },
  { value: 'wow', logo: WowLogo, label: 'WOW' },
  { value: 'algar', logo: AlgarLogo, label: 'Algar Telecom' },
  { value: 'alibaba', logo: AlibabacloudLogo, label: 'Alibaba Cloud' },
  { value: 'angolacables', logo: AngolacablesLogo, label: 'Angola Cables' },
  { value: 'commcorp', logo: CommcorpLogo, label: 'Commcorp / br.digital' },
  { value: 'dito', logo: DitoLogo, label: 'Dito' },
  { value: 'docomo', logo: DocomoLogo, label: 'Docomo' },
  { value: 'eastlink', logo: EastlinkLogo, label: 'Eastlink' },
  { value: 'exetel', logo: ExetelLogo, label: 'Exetel' },
  { value: 'fpt', logo: FptLogo, label: 'FPT Telecom' },
  { value: 'giganet', logo: GiganetLogo, label: 'Giganet.ua' },
  { value: 'hinet', logo: HinetLogo, label: 'Hinet / Chunghwa Telecom' },
  { value: 'hikomm', logo: HikommLogo, label: 'Pyur / Hikomm' },
  { value: 'true', logo: IX_TrueLogo, label: 'True International Gateway' },
  { value: 'kopticom', logo: KopticomLogo, label: 'Kopticom' },
  { value: 'm1', logo: M1Logo, label: 'M1' },
  { value: 'moldtelecom', logo: MoldTelecomLogo, label: 'Moldtelecom' },
  { value: 'mtt', logo: MttLogo, label: 'MTT' },
  { value: 'pldt', logo: PldtLogo, label: 'PLDT' },
  { value: 'rakuten', logo: RakutenMobileLogo, label: 'Rakuten Mobile' },
  { value: 'sinet', logo: SinetLogo, label: 'Sinet' },
  { value: 'softbank', logo: SoftbankLogo, label: 'Softbank' },
  { value: 'teksavvy', logo: TeksavvyLogo, label: 'Teksavvy' },
  { value: 'tencent', logo: TencentLogo, label: 'Tencent' },
  { value: 'tmobile', logo: TmobileLogo, label: 'T-Mobile' },
  { value: 'transtelco', logo: TranstelcoLogo, label: 'Transtelco' },
  { value: 'verisign', logo: VerisignLogo, label: 'Verisign' },
  { value: 'vnpt', logo: VnptLogo, label: 'VNPT / Vietnam Posts' }
];

const providerLogoAliases = {
  vietnamposts: 'vnpt',
  tmo: 'tmobile',
  tencentcloud: 'tencent',
  rakutenmobile: 'rakuten',
  moldavia: 'moldtelecom',
  pyur: 'hikomm',
  chunghwa: 'hinet',
  chunghwatelecom: 'hinet',
  fpttelecom: 'fpt',
  brdigigal: 'commcorp',
  alibabacloud: 'alibaba',
  nttdocomo: 'docomo',
  amsterdaminternetexchange: 'amsix',
  deutschetelekom: 'dtag',
  deutschetelecom: 'dtag',
  teliasonera: 'telia',
  linxix: 'linx',
  londoninternetexchange: 'linx',
  belgacom: 'bics',
  sparkle: 'telecomitalia',
  tisparkle: 'telecomitalia',
  highwinds: 'stackpath',
  maxcdn: 'stackpath',
  netdna: 'stackpath',
  saudi: 'stc',
  ttk: 'transtelekom',
  francetelecom: 'orange',
  opentransit: 'orange',
  llnw: 'limelight',
  liberty: 'libertyglobal',
  kt: 'koreatelecom',
  gcp: 'googlecloud',
  britishtelecom: 'bt',
  seattle: 'six',
  montreal: 'qix',
  flcix: 'flix',
  ggc: 'google',
  emix: 'etisalat',
  globalcloudexchange: 'gcx',
  cablevision: 'altice',
  optimum: 'altice',
  bouyguetel: 'bouygues',
  bytel: 'bouygues',
  surewest: 'consolidated',
  proxad: 'free',
  iliad: 'free',
  interlanix: 'interlan',
  vimpel: 'veon',
  vimpelcom: 'veon',
  virginmedia: 'virgin',
  l3: 'level3',
  cableone: 'sparklight',
  bulgariainternetexchange: 'bixgb',
  unitedexchange: 'chix',
  johannesburginternetexchange: 'jinx',
  inxza: 'jinx',
  sfoix: 'sfmix',
  cnlk: 'centurylink',
  telst: 'telstra',
  bharti: 'airtel',
  nttco: 'ntt',
  nttcommunications: 'ntt',
  skbroadband: 'sktelecom',
  skbb: 'sktelecom',
  hwng: 'stackpath',
  turktel: 'turktelekom',
  turktelecom: 'turktelekom',
  chicagoix: 'chix',
  beeline: 'veon',
  telin: 'telkomindonesia',
  jcom: 'jupitertelecom',
  kinxix: 'kinx',
  jpnapix: 'jpnap',
  uix: 'chix',
  serbianopenexchange: 'sox',
  viennainternetexchange: 'vix',
  netrics: 'nexellent',
  wavebroadband: 'wave',
  taipeiix: 'tpix',
  midcontinent: 'midco',
  atlanticbroadband: 'atlantic'
};

const parseLogoName = (name) => {
  if (typeof name !== 'string') {
    return '';
  }

  return name.replace(/([&\-. ]|Cost Group)+/gi, '').toLowerCase();
};

const findLogo = (value) => {
  const parsedValue = parseLogoName(value || '');
  return providerLogos.find((l) => l.value === (providerLogoAliases[parsedValue] || parsedValue));
};

const getProviderLogo = ({ value, showValue, theme, width, height }) => {
  const baseStyle = { width, maxWidth: '100%' };
  const flipBlackToWhite = theme.name === 'dark' ? { ...baseStyle, filter: 'brightness(0) invert(1)' } : baseStyle;
  // const flipWhiteToBlack = theme.name === 'dark' ? baseStyle : { ...baseStyle, filter: 'brightness(0) invert(0)' };
  const logoProps = { width, height, style: flipBlackToWhite };

  const providerMatch = findLogo(value);
  const Logo = providerMatch && providerMatch.logo;

  if (Logo) {
    return <Logo {...logoProps} />;
  }
  if (showValue) {
    return value;
  }
  return null;
};

const ProviderLogo = ({
  value,
  notFoundValue = false,
  showValue = true,
  style = {},
  theme,
  height = 'auto',
  width = '100%',
  ...rest
}) => {
  const Logo = getProviderLogo({ value, showValue, theme, width, height });
  return Logo ? (
    <Box {...rest} style={style}>
      {Logo}
    </Box>
  ) : (
    notFoundValue || null
  );
};

export const willRenderLogo = (value) => !!findLogo(value);

export default withTheme(ProviderLogo);
