import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Button, Card, Flex, Heading, Icon, Text } from 'core/components';
import { Field, formConsumer, InputGroup, Select, ShareLevelSelector, Switch, TextArea } from 'core/form';
import DeviceSelector from 'app/components/device/DeviceSelector';
import FilterOptions from 'app/views/core/explorer/sidebar/FilterOptions';

import LabelSelector from 'app/components/labels/LabelSelector';
import ParametricOptionsForm from './ParametricOptionsForm';
import DashboardNavigationDisplay from './DashboardNavigationDisplay';
import GuidedModeBehavior from './dashboardItem/form/GuidedModeBehavior';

const lookbackValueRenderer = (option, placeholder) => {
  if (!option) {
    return <Text muted>{placeholder || 'Select a value...'}</Text>;
  }

  return (
    <span>
      <Icon name="time" style={{ marginRight: 7 }} />
      {option && option.label}
    </span>
  );
};

@inject('$dictionary')
@formConsumer
@observer
class DashboardDetailsForm extends Component {
  state = {
    isEditingFilters: false
  };

  componentDidMount() {
    const { dashboardItem, form } = this.props;
    if (dashboardItem) {
      const field = form.getField('panel_title');
      field.setRules('required');
      field.setValue(dashboardItem.get('panel_title'));

      form.setValue('parametric_mode', dashboardItem.get('parametric_mode'));
      form.setValue('parametric_overrides.filterField', dashboardItem.get('parametric_overrides.filterField'));
      form.setValue('parametric_overrides.operator', dashboardItem.get('parametric_overrides.operator'));
    } else if (!form.getValue('query.lookback_seconds')) {
      // dashboard-level query is not allowed to have custom (or missing!) lookback_seconds, we initialize to a valid value instead
      form.setValue('query.lookback_seconds', 3600);
    }
  }

  handleParametricToggle = (field, value) => {
    const { form, dashboardItem } = this.props;

    // if they're toggling to "on", set a value
    if (value && !form.getValue('parametric_fields').length) {
      form.setValue('parametric_fields', [
        { type: 'as_name', value: '', label: 'AS Name', question: 'Which AS Name would you like to see?' }
      ]);
    }

    if (dashboardItem) {
      form.getField('parametric_overrides.filterField').setRules(value ? 'required' : '');
      form.getField('parametric_overrides.operator').setRules(value ? 'required' : '');
    }
  };

  handleToggleEditFilters = (isOpen) => {
    this.setState({
      isEditingFilters: isOpen
    });
  };

  getAvailableFiltersConfig = (parametricType) => {
    const { $dictionary, form, dashboardItem: item } = this.props;
    const { activeFilterFields } = item.get('queries')[0];

    const { parametric_mode } = form.getValues();

    // we disable the `override_specific` option if there are no filters in this Items existing filters
    // that can be overridden by the selected parametric type
    const availableFilters = $dictionary.getAvailableFiltersForParametricType(parametricType);
    const canOverrideSpecific = availableFilters.some((filter) => activeFilterFields.includes(filter.value));

    if (canOverrideSpecific) {
      if (parametric_mode === 'override_specific') {
        const filters = availableFilters.filter((filter) => activeFilterFields.includes(filter.value));
        return { availableFilters: filters, canOverrideSpecific };
      }
    }

    return { availableFilters, canOverrideSpecific };
  };

  render() {
    const { $dictionary, form, model, dashboardItem } = this.props;
    const { isEditingFilters } = this.state;
    const isParametric = form.getValue('parametric');

    const {
      filterOperatorOptions,
      standardFilterOperators,
      dictionary: { queryFilters }
    } = $dictionary;
    let availableFiltersConfig = {};
    if (dashboardItem && isParametric) {
      const parametricFields = form.getValue('parametric_fields');
      const parametricType = parametricFields && parametricFields[0];
      availableFiltersConfig = this.getAvailableFiltersConfig(parametricType);
    }

    const lookbackOptions = $dictionary
      .getSelectOptions('showLast', { parseKeys: true })
      .filter((opt) => opt.label !== 'Custom')
      .map((opt) => ({ ...opt, iconCls: 'clock' }));

    return (
      <Flex>
        <Card p={3} flex={1}>
          <Field name="dash_title" mb={2} large autoFocus>
            <InputGroup />
          </Field>
          <Field name="description" mb={2} large>
            <TextArea rows={4} fill />
          </Field>

          <Box my={2}>
            <LabelSelector fieldName="labelIds" useDialog />
          </Box>

          <Box mb={2}>
            <ShareLevelSelector
              label="Visibility"
              field={form.getField('share_level')}
              privateValue="self"
              sharedValue="org"
              globalValue="org"
            />
          </Box>

          <Text as="div" fontSize={14} fontWeight="bold">
            Default Query Options
          </Text>
          <Text as="p" muted small>
            Define query options that will be applied to all dashboard panels
          </Text>

          <Field
            name="query.lookback_seconds"
            label="Time Range"
            options={lookbackOptions}
            onChange={this.handleRunQuery}
          >
            <Select valueRenderer={lookbackValueRenderer} menuWidth={243} fill />
          </Field>

          <Box mb={2}>
            <Flex mb={1} justifyContent="space-between">
              <Text fontWeight="bold" small>
                Data Sources
              </Text>
            </Flex>
            <DeviceSelector
              deviceNameFieldName="query.device_name"
              deviceTypesFieldName="query.device_types"
              deviceLabelsFieldName="query.device_labels"
              deviceSitesFieldName="query.device_sites"
              allDevicesFieldName="query.all_devices"
            />
          </Box>

          <Box>
            <Flex mb={1} justifyContent="space-between">
              <Text fontWeight="bold" small>
                Filters
              </Text>
            </Flex>
            <FilterOptions
              fieldName="query.filters"
              isOpen={isEditingFilters}
              onClose={() => this.handleToggleEditFilters(false)}
              showEmptyText={false}
            />
            <Button
              onClick={() => this.handleToggleEditFilters(true)}
              text="Edit Filters"
              icon="edit"
              small
              mt="12px"
            />
          </Box>
        </Card>
        <Box flex={1}>
          {dashboardItem && (
            <Card ml={2} p={3} mb={2}>
              <Flex alignItems="center" mb={1}>
                <Icon name="assessment" mr={1} iconSize={20} />
                <Heading level={5} m={0}>
                  Dashboard Panel
                </Heading>
              </Flex>
              <Field name="panel_title">
                <InputGroup />
              </Field>
            </Card>
          )}
          <Card ml={2} p={3} mb={2} pb={form.getValue('parametric') ? 3 : 1}>
            <Flex alignItems="center" justifyContent="space-between" mb={1}>
              <Heading level={5} mb={0}>
                Guided Mode
              </Heading>
              <Field name="parametric" showLabel={false} onChange={this.handleParametricToggle} mb={0} large>
                <Switch switchLabel={form.getValue('parametric') ? 'Enabled' : 'Disabled'} />
              </Field>
            </Flex>
            <Text muted small>
              Guided Mode lets users see data about a dimension from a preselected dimension family without setting
              complex query options. When enabled, the dashboard will include the prompt specified below, and the user’s
              response will determine the value of a predefined filter that is applied to each panel.
            </Text>
            <ParametricOptionsForm form={form} />
            {dashboardItem && isParametric && (
              <GuidedModeBehavior
                availableFiltersConfig={availableFiltersConfig}
                parametric_mode={form.getValue('parametric_mode')}
                filterOperatorOptions={filterOperatorOptions}
                standardFilterOperators={standardFilterOperators}
                queryFilters={queryFilters}
                boxProps={{ mt: 2 }}
                fieldBoxProps={{}}
                disabled={!isParametric}
                form={form}
              />
            )}
          </Card>
          {model && !model.isNew && (
            <Card ml={2} p={3}>
              <DashboardNavigationDisplay model={model} />
            </Card>
          )}
        </Box>
      </Flex>
    );
  }
}

export default DashboardDetailsForm;
