import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { isNumber, isEmpty } from 'lodash';

import { Flex, Box, EmptyState, Spinner, Suspense } from 'core/components';
import { TEST_TYPES, TASK_TYPES } from 'app/util/constants';
import { getStartAndEndFromLookback, timezone } from 'core/util/dateUtils';
import TestAvailability from 'app/views/synthetics/components/TestAvailability';
import AggregationTag from 'app/views/synthetics/components/AggregationTag';
import SynthDashboardGridGroup from './SynthDashboardGridGroup';
import SynthDashboardAggregateTests from './SynthDashboardAggregateTests';
import SynthDashboardGauge from './SynthDashboardGauge';

const getNormalizedIds = (ids) => (Array.isArray(ids) ? `${ids}` : ids);
const DEFAULT_QUERY_STATE = {
  testResults: null,
  hasTestResults: false,
  resultTimeMs: '',
  lastUpdated: null,
  synth_test_id: null,
  synth_test_display: null,
  synth_test_labels: null,
  synth_test_metric: null,
  lookback_seconds: null,
  starting_time: null,
  ending_time: null
};

@inject('$app', '$dashboard', '$labels', '$syn')
@observer
class SynthTestDashboardItem extends Component {
  state = {
    loading: false,
    loadingAgents: false,
    loadingCommunityTests: false,
    allAgents: [],
    refresh: false,
    ...DEFAULT_QUERY_STATE
  };

  constructor(props) {
    super(props);
    this.refreshCallback = this.refreshCallback.bind(this);
  }

  static getDerivedStateFromProps(props) {
    const { synth_test_id, synth_test_display } = props.dataview.query;

    if (!synth_test_id && synth_test_display !== 'availability') {
      return { ...DEFAULT_QUERY_STATE };
    }

    return null;
  }

  getDateOpts() {
    const { $dashboard, dataview } = this.props;
    const { lookback_seconds, starting_time, ending_time } = dataview.query;

    // NOTE: syngest is expecting a start_time/end_time in seconds, so we need to convert from lookback_seconds
    // These are special cases where we want to use the current month or last month's data
    let start = starting_time;
    let end = ending_time;
    if (lookback_seconds === 1 && !start && !end) {
      // Get the beginning of this month, to the current date
      start = timezone.momentFn().date(1).hour(0).minute(0).second(0).millisecond(0);
      end = timezone.momentFn();
    } else if (lookback_seconds === 2 && !start && !end) {
      // Get the beginning of this month, to the current date
      start = timezone.momentFn().date(1).hour(0).minute(0).second(0).millisecond(0).subtract(1, 'month');
      end = timezone.momentFn(start).date(31);
    } else if (lookback_seconds > 2 && !start && !end) {
      const { start: start_time, end: end_time } = getStartAndEndFromLookback(lookback_seconds, 60);
      start = start_time;
      end = end_time;
    }

    return {
      preset: $dashboard.dashboard.isPreset,
      start_time: isNumber(start) ? start : moment.utc(start).unix(),
      end_time: isNumber(end) ? end : moment.utc(end).unix()
    };
  }

  fetchBasedOnProps() {
    const { dataview } = this.props;
    const {
      loading,
      loadingAgents,
      loadingCommunityTests,
      lastUpdated,
      synth_test_id,
      synth_test_display,
      synth_test_labels,
      lookback_seconds,
      starting_time,
      ending_time,
      refresh
    } = this.state;
    const dq_synth_test_id = getNormalizedIds(dataview.query.synth_test_id);
    const st_synth_test_id = getNormalizedIds(synth_test_id);
    const dq_synth_test_labels = getNormalizedIds(dataview.query.synth_test_labels);
    const st_synth_test_labels = getNormalizedIds(synth_test_labels);

    if (
      !!dataview.query.synth_test_id &&
      !loading &&
      !loadingAgents &&
      !loadingCommunityTests &&
      // NOTE: test ids are optional for gauge widgets
      (dq_synth_test_id ||
        (dataview.query.synth_test_display !== null &&
          ['gauge-min', 'gauge-max', 'gauge-avg'].includes(dataview.query.synth_test_display))) &&
      (dq_synth_test_id !== st_synth_test_id ||
        dataview.query.synth_test_display !== synth_test_display ||
        dataview.query.lookback_seconds !== lookback_seconds ||
        dataview.query.lastUpdated !== lastUpdated ||
        dataview.query.starting_time !== starting_time ||
        dataview.query.ending_time !== ending_time ||
        refresh) &&
      dataview.query.synth_test_display !== 'availability'
    ) {
      this.setState({ refresh: false });
      this.fetchTestResults();
    }

    if (
      (!!dq_synth_test_labels &&
        !loading &&
        !loadingAgents &&
        !loadingCommunityTests &&
        dataview.query.synth_test_display === 'availability' &&
        dq_synth_test_labels !== st_synth_test_labels) ||
      (refresh && dataview.query.synth_test_display === 'availability') ||
      (!loading &&
        dataview.query.synth_test_display === 'availability' &&
        (dataview.query.lastUpdated !== lastUpdated ||
          dataview.query.starting_time !== starting_time ||
          dataview.query.ending_time !== ending_time))
    ) {
      this.setState({ refresh: false });
      this.fetchTestsAvailability();
    }
  }

  refreshCallback() {
    this.setState({ refresh: true });
    this.fetchBasedOnProps();
  }

  componentDidMount() {
    const { $syn, dataview } = this.props;
    const allAgents = []
      .concat(
        $syn.agents.privateAgents,
        $syn.agents.globalAgents,
        // TODO: deprecate browserAgents #7660
        $syn.agents.browserAgents,
        $syn.agents.cloudAgents
      )
      .map((agent) => ({
        labels: agent.labels.map((label) => label.id),
        tests: agent.get('tests')
      }));
    $syn.tests.loadTestHealth();
    this.setState({ allAgents });

    const { updateFrequency } = this.props;
    if (updateFrequency) {
      this.refreshInterval = setInterval(() => {
        this.setState({ refresh: true });
        this.fetchBasedOnProps();
      }, updateFrequency * 1000); // Assuming updateFrequency is in seconds
    }

    dataview.on('refresh', this.refreshCallback);
  }

  componentWillUnmount() {
    const { dataview } = this.props;
    // Clear the interval when the component is unmounted
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
    // Passing the same callback to let the .off function know which one to remove
    dataview.off('refresh', this.refreshCallback);
  }

  componentDidUpdate(prevProps) {
    this.fetchBasedOnProps();

    const { updateFrequency } = this.props;
    if (prevProps.updateFrequency !== updateFrequency) {
      // Clear the old interval
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
      }

      // Set a new interval if updateFrequency is set
      if (updateFrequency) {
        this.refreshInterval = setInterval(() => {
          this.setState({ refresh: true });
          this.fetchBasedOnProps();
        }, updateFrequency * 1000); // Assuming updateFrequency is in seconds
      }
    }
  }

  // NOTE: this is only used for grid/table and network-grid tests where there is a single synth_test_id
  // eslint-disable-next-line react/no-unused-class-component-methods
  get test() {
    const { $dashboard, $syn, dataview } = this.props;
    if ($dashboard.dashboard.isPreset) {
      return $syn.communityPerformanceTests.get(dataview.query.synth_test_id);
    }
    return $syn.tests.get(dataview.query.synth_test_id);
  }

  processSyngestResultsForDashboard(result, testResults) {
    const { $syn } = this.props;
    for (const testId in testResults?.tests_health) {
      if (Object.hasOwn(testResults.tests_health, testId)) {
        const test = $syn.tests.get(testId);
        if (test) {
          test.agentResults.processFetchTestResultsResponse(test, testResults.tests_health[testId]);
          const { results, hasResults, agentTaskConfig, timelineBounds, mesh } = test.agentResults;
          let overallHealth = null;
          if (results) {
            const { health_ts } = results;
            overallHealth = Object.keys(health_ts).map((ts) => ({
              health: health_ts[ts].overall_health.health,
              time: Number(health_ts[ts].overall_health.time) * 1000
            }));
          }
          // this is the heath object that is used in the dashboard widgets
          result.health.push({
            test_id: test.id,
            ...results,
            agentTaskConfig,
            hasResults,
            timelineBounds,
            mesh,
            overallHealth
          });
        }
      }
    }
  }

  fetchTestAvailabilityResults(tids, opts = {}) {
    const { $syn } = this.props;
    const testIds = Array.isArray(tids) ? tids : [tids];
    return $syn.requests
      .fetchTestAvailabilityResults({ ...opts, ids: testIds }, { preset: opts.preset })
      .then((testResults) => {
        const result = {
          status: testResults.status,
          health: [],
          availabilitySummary: testResults?.availabilitySummary || {},
          availability: testResults?.availability || []
        };
        this.processSyngestResultsForDashboard(result, testResults);
        return result;
      })
      .catch(() => ({ status: { ok: false }, health: [] }));
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  fetchAggregateTestResults(tids, opts = {}) {
    const { $syn } = this.props;
    const testIds = Array.isArray(tids) ? tids : [tids];
    return $syn.requests
      .fetchAggregateTestResults({ ...opts, ids: testIds }, { preset: opts.preset })
      .catch(() => ({ status: { ok: false }, health: [] }));
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  fetchDashboardTestResults(tids, opts = {}) {
    const { $syn } = this.props;
    const testIds = Array.isArray(tids) ? tids : [tids];

    return $syn.requests
      .fetchTestResults({ ...opts, ids: testIds }, { preset: opts.preset })
      .then((testResults) => {
        const result = { status: testResults.status, health: [] };
        this.processSyngestResultsForDashboard(result, testResults);
        return result;
      })
      .catch(() => ({ status: { ok: false }, health: [] }));
  }

  getTaskTypeByTestType(testType) {
    switch (testType) {
      case TEST_TYPES.URL:
        return TASK_TYPES.HTTP;
      case TEST_TYPES.PAGE_LOAD:
        return TASK_TYPES.PAGE_LOAD;
      case TEST_TYPES.TRANSACTION:
        return TASK_TYPES.TRANSACTION;
      case 'isDns':
        return TEST_TYPES.DNS;
      default:
        return TASK_TYPES.PING;
    }
  }

  isTestOfDataviewTestType(test) {
    const { $syn, dataview } = this.props;
    const { synth_test_type } = dataview.query;
    // $syn.tests.get(test.id)[synth_test_type] could be a string so must check === true
    return test.test_type === synth_test_type || $syn.tests.get(test.id)[synth_test_type] === true;
  }

  fetchTestsAvailability() {
    const { dataview } = this.props;
    const { lastUpdated, synth_test_display, synth_test_labels, lookback_seconds, starting_time, ending_time } =
      dataview.query;

    const matchingTestIds = dataview.matchingTestsByLabelIds;

    if (isEmpty(matchingTestIds)) {
      this.setState({
        loading: false,
        refresh: false,
        testResults: {},
        hasTestResults: false,
        lastUpdated,
        synth_test_display,
        synth_test_labels
      });
    }

    if (!isEmpty(matchingTestIds)) {
      this.setState({ loading: true });

      this.fetchTestAvailabilityResults(matchingTestIds, this.getDateOpts()).then((testResults) => {
        this.setState({
          loading: false,
          refresh: false,
          lastUpdated,
          synth_test_display,
          synth_test_labels,
          lookback_seconds,
          starting_time,
          ending_time,
          testResults,
          hasTestResults: testResults.health.length > 0 && testResults.availability.length > 0
        });
      });
    }
  }

  getResultTimeMsFromResults(testResults) {
    const [firstHealth] = testResults.health;
    if (firstHealth?.overall_health) {
      return +firstHealth.overall_health.time * 1000;
    }

    if (firstHealth?.time) {
      return +firstHealth.time * 1000;
    }
    return null;
  }

  fetchTestResults() {
    const { $syn, dataview } = this.props;
    const { allAgents } = this.state;
    const {
      lastUpdated,
      synth_test_id,
      synth_test_display,
      synth_test_metric,
      synth_test_type,
      lookback_seconds,
      starting_time,
      ending_time
    } = dataview.query;
    this.setState({ loading: true });

    // NOTE: for 'aggregate-label' display, synth_test_id is actually an array of [label_id]
    let test_ids = Array.isArray(synth_test_id) ? synth_test_id : [synth_test_id];

    let func = 'fetchDashboardTestResults';

    const opts = this.getDateOpts();

    // TODO: bring back 'aggregate-agent' when we have agent selector in form
    if (['aggregate-label', 'gauge-min', 'gauge-max', 'gauge-avg'].includes(synth_test_display)) {
      func = 'fetchAggregateTestResults';
      const group_by = synth_test_display.includes('aggregate') ? synth_test_display.split('-')[1] : 'agent';
      // this is kind of meh, but since the change where health_agg results are grouped by task
      // we need to let the agg service know which task to grab metrics from
      const task_type = this.getTaskTypeByTestType(synth_test_type);
      Object.assign(opts, { group_by, task_type });
    }
    // NOTE: test ids are optional for gauge widgets
    if (['gauge-min', 'gauge-max', 'gauge-avg'].includes(synth_test_display) && !test_ids[0]) {
      test_ids = $syn.tests.models.reduce((next_test_ids, test) => {
        if (!next_test_ids.includes(test.id) && this.isTestOfDataviewTestType(test.get())) {
          next_test_ids.push(test.id);
        }
        return next_test_ids;
      }, []);
    }
    if (synth_test_display === 'aggregate-label') {
      // loop through all agent models to find any that have matching label id
      // then reset test_ids back to actual synth tests that use the agents which had matching label ids
      const filteredAllAgentModels = allAgents.filter((agent) =>
        agent.labels.some((label_id) => test_ids.includes(label_id))
      );
      test_ids = filteredAllAgentModels.reduce((next_test_ids, agent) => {
        agent.tests.forEach((test) => {
          if (!next_test_ids.includes(test.id) && this.isTestOfDataviewTestType(test)) {
            next_test_ids.push(test.id);
          }
        });
        return next_test_ids;
      }, []);
    }

    this[func](test_ids, opts).then((testResults) => {
      const hasAggregateTestResults = testResults?.health?.[0]?.all_tests;
      const state = {
        loading: false,
        refresh: false,
        lastUpdated,
        synth_test_id,
        synth_test_display,
        synth_test_type,
        synth_test_metric,
        lookback_seconds,
        starting_time,
        ending_time,
        hasTestResults: testResults.health.length > 0 || hasAggregateTestResults,
        testResults,
        resultTimeMs: this.getResultTimeMsFromResults(testResults)
      };
      this.setState(state);
      return state;
    });
  }

  get isAggregated() {
    const { testResults } = this.state;
    return (testResults?.health || []).every((healthResult) => healthResult?.book?.aggregationData?.isAggregated);
  }

  render() {
    const { $syn } = this.props;
    const {
      loading,
      loadingCommunityTests,
      synth_test_display,
      synth_test_id,
      synth_test_metric,
      synth_test_labels,
      synth_test_type,
      hasTestResults,
      testResults,
      lookback_seconds
    } = this.state;

    const suspenseLoading = testResults === null || loading || loadingCommunityTests || $syn.agents.loading;
    const isAggregateDisplay = ['aggregate-agent', 'aggregate-label'].includes(synth_test_display);
    const isGaugeDisplay = ['gauge-min', 'gauge-max', 'gauge-avg'].includes(synth_test_display);
    const isGridGroupDisplay = synth_test_display === 'grid-group';
    const isTestAvailability = synth_test_display === 'availability';
    const dateOptions = this.getDateOpts();

    return (
      <Suspense loading={suspenseLoading} fallback={<Spinner intent="primary" mx="auto" />}>
        {!hasTestResults ? (
          <Box px={4}>
            <EmptyState
              icon="disable"
              title="No results"
              description="Results are not currently available for this time range or this test no longer exists."
            />
          </Box>
        ) : (
          <Flex flex={1} flexDirection="column" width="100%">
            <Box position="absolute" top={18} right={16}>
              <AggregationTag isAggregated={this.isAggregated} />
            </Box>
            {isAggregateDisplay && (
              <SynthDashboardAggregateTests
                group_ids={synth_test_id}
                synth_test_metric={synth_test_metric}
                testResults={testResults}
              />
            )}
            {isGaugeDisplay && (
              <SynthDashboardGauge
                measure={synth_test_display.split('-')[1]}
                synth_test_metric={synth_test_metric}
                synth_test_type={synth_test_type}
                testResults={testResults}
              />
            )}
            {isGridGroupDisplay && (
              <SynthDashboardGridGroup
                test_ids={synth_test_id}
                testResults={testResults}
                lookbackSeconds={lookback_seconds}
                startDate={dateOptions.start_time}
                endDate={dateOptions.end_time}
              />
            )}
            {isTestAvailability && (
              <TestAvailability
                testResults={testResults}
                labels={synth_test_labels}
                selectedTime={{
                  lookback_seconds,
                  starting_time: dateOptions.start_time,
                  ending_time: dateOptions.end_time
                }}
              />
            )}
          </Flex>
        )}
      </Suspense>
    );
  }
}

export default SynthTestDashboardItem;
