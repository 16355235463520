import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Icon, Flex, Box, Text, LinkButton } from 'core/components';

import { ReactComponent as DiscoveryIcon } from 'app/assets/recon/icon-discovery.svg';

@inject('$discovery')
@observer
export default class DiscoveryProgressChip extends Component {
  render() {
    const { $discovery } = this.props;
    const { activeDiscoveries } = $discovery.collection;

    if (activeDiscoveries.length > 1) {
      return (
        <Flex mx={2} gap={1} alignItems="center" minWidth={260}>
          <Box color="white.10">
            <Icon icon={DiscoveryIcon} iconSize={30} />
          </Box>

          <Box>
            <LinkButton to="/v4/nms/discovery" minimal bg="white.1" fontSize={12} color="white.10" minWidth={70}>
              <Text as="div" textAlign="left" small fontWeight="heavy" fontSize={12} color="white.10">
                {activeDiscoveries.length} discoveries to review
              </Text>
              <Text as="div" textAlign="left" small color="white.10">
                View All
              </Text>
            </LinkButton>
          </Box>
        </Flex>
      );
    }

    return (
      <Flex mx={2} gap={1} minWidth={260}>
        {activeDiscoveries.map((model) => {
          const { percentComplete, devicesFound } = model.status;
          const progressPercent = Math.round(percentComplete * 100);
          return (
            <Flex key={model.id} gap={2} alignItems="center">
              <Box color="white.10">
                <Icon icon={DiscoveryIcon} iconSize={30} />
              </Box>
              <Box>
                <Text as="div" small fontWeight="heavy" fontSize={12} color="white.10">
                  Discovery to review
                </Text>
                <Text as="div" color="whiteMuted" small>
                  {progressPercent}% &middot; {Number(devicesFound).toLocaleString()}{' '}
                  {devicesFound === 1 ? 'device' : 'devices'}
                </Text>
              </Box>
              <Box>
                <LinkButton
                  to={`/v4/nms/discovery/${model.id}`}
                  minimal
                  bg="white.1"
                  fontSize={12}
                  color="white.10"
                  minWidth={70}
                >
                  View
                </LinkButton>
              </Box>
            </Flex>
          );
        })}
      </Flex>
    );
  }
}
