import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';
import Text from 'core/components/Text';

const CityLink = ({ $app, city, country, region, ...rest }) => {
  if (!$app.isSubtenant && region && country) {
    return (
      <Link
        to={`/v4/core/quick-views/city/${encodeURIComponent(city)}/${encodeURIComponent(region)}/${country}`}
        {...rest}
      >
        {city}
      </Link>
    );
  }

  return <Text>{city}</Text>;
};

export default inject('$app')(CityLink);
