import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { FiUploadCloud } from 'react-icons/fi';

import { Box, Text } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import SidebarCard from 'app/components/landing/SidebarCard';
import ModuleLinkButton from 'app/components/landing/ModuleLinkButton';
import OttServiceLink from 'app/components/links/OttServiceLink';
import formatMetricValue from 'app/util/formatMetricValue';
import { getPeakValues } from 'app/util/queryResults';

@inject('$auth', '$ott')
class OttCard extends Component {
  state = {
    totalClassified: 0
  };

  aggregateType = 'max_bits_per_sec';

  componentDidMount() {
    const { $auth, $ott } = this.props;

    if ($auth.hasPermission('ott.enabled', { overrideForSudo: false })) {
      $ott.getModuleConfig().then(() => {
        this.setState({ loadingModuleConfig: false });
      });
    } else {
      this.setState({ loadingModuleConfig: false });
    }
  }

  getTrafficPct = (topTraffic, totalTraffic) => (totalTraffic > 0 ? Math.round((topTraffic / totalTraffic) * 100) : 0);

  handleClassificationQueryComplete = ({ results }) => {
    const peakValues = getPeakValues(results.models);
    const total = peakValues.modelValues
      .filter((item) => item.key === 'Fully Classified' || item.key === 'Provider-Only')
      .reduce((acc, model) => acc + model.value, 0);

    this.setState({ totalClassified: total });
  };

  renderServiceLink = (model, idx, models) => (
    <span key={model.key}>
      <OttServiceLink service={model.key} />
      {idx !== models.length - 1 ? ', ' : ''}
    </span>
  );

  renderCard({ loading = false, enabled = true, results, peakValues, formattedValue, metric, topTraffic } = {}) {
    const { totalClassified } = this.state;
    const title = 'OTT Service Tracking';
    const route = '/v4/service-provider/ott';

    return (
      <SidebarCard
        icon={FiUploadCloud}
        title={title}
        loading={loading}
        isConfigured={enabled && results?.size > 0}
        notConfiguredText={enabled ? 'You do not have any OTT traffic' : 'This module is not enabled'}
        to={route}
      >
        {results && (
          <Box my={2}>
            <Text as="div" fontSize={14}>
              Total traffic detected is <Text fontWeight="heavy">{formattedValue} </Text>
              <Text color="muted">{metric}</Text>
            </Text>

            <Text as="div" mt={1} fontSize={14}>
              Top <Text fontWeight="bold">{peakValues.modelValues.length}</Text> services are{' '}
              <Text fontWeight="bold">{this.getTrafficPct(topTraffic, totalClassified)}%</Text> of total traffic:
            </Text>

            <Text fontSize={12}>{peakValues.modelValues.map(this.renderServiceLink)}</Text>
          </Box>
        )}

        <ModuleLinkButton mt={1} text={title} to={route} />
      </SidebarCard>
    );
  }

  render() {
    const { $auth, $ott } = this.props;
    const { loadingModuleConfig, totalClassified } = this.state;

    if (!$auth.hasPermission('ott.enabled', { overrideForSudo: false })) {
      return this.renderCard({ loading: false, enabled: false });
    }

    return (
      <>
        <LightweightDataViewWrapper
          query={$ott.queries.getClassificationQuery({})}
          onQueryComplete={this.handleClassificationQueryComplete}
        />

        {loadingModuleConfig && this.renderCard({ loading: true })}

        {!loadingModuleConfig && (
          <LightweightDataViewWrapper
            query={$ott.queries.getTopServicesQuery({
              topx: 5,
              depth: 5,
              filters: [
                {
                  filterField: 'service_name',
                  operator: '<>',
                  filterValue: 'unknown'
                }
              ]
            })}
          >
            {({ loading, results }) => {
              const peakValues = getPeakValues(results.models);
              const { value: formattedValue, metric } = formatMetricValue(totalClassified, 'bits');
              const topTraffic = peakValues.modelValues.reduce((accum, item) => accum + item.value, 0);
              return this.renderCard({ loading, results, peakValues, formattedValue, metric, topTraffic });
            }}
          </LightweightDataViewWrapper>
        )}
      </>
    );
  }
}

export default OttCard;
