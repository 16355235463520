import React, { Component } from 'react';
import { Layer, Text } from 'react-konva/lib/ReactKonvaCore'; // https://github.com/konvajs/react-konva#minimal-bundle
import 'konva/lib/shapes/Text';
import { isLayerRenderable } from 'app/views/synthetics/components/mesh/util/utils';
import { CELL_MARGIN, DENSITY, getDensityCellSize } from 'app/views/synthetics/components/mesh/util/constants';

const FONT_SIZE = 11;
const LINE_HEIGHT = 1.15;
const ABS_LINE_HEIGHT = FONT_SIZE * LINE_HEIGHT;

export default class TextLayer extends Component {
  shouldComponentUpdate(newProps) {
    const { density, mesh, theme } = this.props;
    return (
      density?.size !== newProps.density?.size ||
      mesh.length !== newProps.mesh.length ||
      theme.name !== newProps.theme.name
    );
  }

  get cellSize() {
    const { density } = this.props;
    return getDensityCellSize(density);
  }

  get height() {
    const { vertical } = this.props;

    if (vertical) {
      return this.cellSize;
    }

    return Math.ceil(Math.floor((0.75 * this.cellSize) / ABS_LINE_HEIGHT) * ABS_LINE_HEIGHT);
  }

  get isHighDensity() {
    const { density } = this.props;
    return density?.id === DENSITY.HIGH.id;
  }

  /*
    Properties to spread on the actual Text canvas element based on:
      - orientation - vertical is a row, otherwise it's a column
      - density - each density has their own cell size assigned to it
      - index position - in high density, this is used for the text content
      - name - all densities other than high will show this as the text content
  */
  getTextProps({ index, name }) {
    const { density, vertical } = this.props;
    const { labelWidth: LABEL_WIDTH } = density;

    // the default, horizontal text layer (labels on top of the grid)
    const textProps = {
      x: index * (this.cellSize + CELL_MARGIN) + this.height,
      y: LABEL_WIDTH,
      width: LABEL_WIDTH,
      offsetY: this.height,
      rotation: -45,
      text: name
    };

    // vertical overrides (labels to the left of the grid)
    if (vertical) {
      textProps.x = 0;
      textProps.y = index * (this.cellSize + CELL_MARGIN);
      textProps.offsetY = 0;
      textProps.rotation = 0;
    }

    // overrides for when density is of type HIGH
    // in this density the primary difference is we render labels not of names, but index position
    if (this.isHighDensity) {
      textProps.rotation = 0;
      textProps.text = index + 1;

      if (!vertical) {
        textProps.y = LABEL_WIDTH - ABS_LINE_HEIGHT;
        textProps.width = 19; // bit of a magic number but is required for when we reach the triple-digit row/column headers
        textProps.height = ABS_LINE_HEIGHT;
      }
    }

    return textProps;
  }

  /*
    Calculates the box dimensions used for header cell popover targets
    These properties are spread onto the 'targetProps' for that popover
    to make sure it's positioned and sized to the header cell element
  */
  getBoxProperties({ target, textProps }) {
    const { vertical, density } = this.props;
    const { x: left, y: top } = target.absolutePosition();
    const { labelWidth: LABEL_WIDTH } = density;

    // initialize the box properties for headers of type 'column'
    const boxProperties = {
      left: left + LABEL_WIDTH - CELL_MARGIN,
      top: top - CELL_MARGIN,
      width: textProps.width + CELL_MARGIN,
      height: ABS_LINE_HEIGHT + CELL_MARGIN
    };

    if (vertical) {
      // overrides for headers of type 'row'
      boxProperties.left = LABEL_WIDTH - density.size - CELL_MARGIN;
      boxProperties.width = density.size + CELL_MARGIN;
      boxProperties.top += LABEL_WIDTH;
    }

    return boxProperties;
  }

  render() {
    const { density, mesh, vertical, theme, onHeaderCellMouseOver, onHeaderCellMouseOut } = this.props;

    if (!isLayerRenderable({ density, type: 'text' })) {
      return null;
    }

    return (
      <Layer>
        {mesh.map((row, index) => {
          const { name, agent_id } = row;
          const textProps = this.getTextProps({ index, name });

          return (
            <Text
              key={`label-${agent_id}`}
              height={this.height}
              verticalAlign="middle"
              fontSize={FONT_SIZE}
              lineHeight={LINE_HEIGHT}
              fill={theme.colors.body}
              align={vertical ? 'right' : 'center'}
              ellipsis
              {...textProps}
              onMouseOver={(e) => {
                if (this.isHighDensity && onHeaderCellMouseOver) {
                  const { target } = e;

                  onHeaderCellMouseOver({
                    type: vertical ? 'row' : 'col',
                    target,
                    popoverProperties: {
                      boxProperties: this.getBoxProperties({ target, textProps }),
                      agentDetails: { agent_id, index }
                    }
                  });
                }
              }}
              onMouseOut={(e) => {
                if (this.isHighDensity && onHeaderCellMouseOut) {
                  onHeaderCellMouseOut({ target: e.target });
                }
              }}
            />
          );
        })}
      </Layer>
    );
  }
}
