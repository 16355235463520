import React from 'react';
import { observer, inject } from 'mobx-react';
import { Button, ButtonGroup, Popover, Menu, MenuItem, MenuDivider } from 'core/components';
import { FiRefreshCcw } from 'react-icons/fi';
import { PopoverPosition } from '@blueprintjs/core';

const RefreshButton = ({ $auth, model, buttonProps, allowLiveUpdate }) => {
  const { updateFrequency, timeRange } = model;
  const isLookback = timeRange.lookback_seconds !== 0;

  return (
    <ButtonGroup>
      <Button {...buttonProps} icon={FiRefreshCcw} text="Refresh" onClick={() => model.refresh()} />
      {allowLiveUpdate && (
        <Popover
          position={PopoverPosition.BOTTOM_RIGHT}
          content={
            <Menu minWidth={120}>
              <MenuDivider title="Auto Update" />
              {!isLookback && <MenuItem text="Only available in lookback time mode" disabled />}
              {isLookback && <MenuItem text="Off" onClick={() => model.setUpdateFrequency()} />}
              {($auth.hasSudo || $auth.isSpoofed) && isLookback && (
                <>
                  <MenuItem text="5 Seconds" onClick={() => model.setUpdateFrequency(5)} />
                  <MenuItem text="10 Seconds" onClick={() => model.setUpdateFrequency(10)} />
                </>
              )}
              {isLookback && (
                <>
                  <MenuItem text="60 Seconds" onClick={() => model.setUpdateFrequency(60)} />
                  <MenuItem text="90 Seconds" onClick={() => model.setUpdateFrequency(90)} />
                  <MenuItem text="120 Seconds" onClick={() => model.setUpdateFrequency(120)} />
                </>
              )}
            </Menu>
          }
        >
          <Button
            {...buttonProps}
            ml={0}
            rightIcon="caret-down"
            fontSize={12}
            text={updateFrequency ? `Every ${updateFrequency}s` : undefined}
            intent={updateFrequency ? 'warning' : undefined}
          />
        </Popover>
      )}
    </ButtonGroup>
  );
};

export default inject('$auth')(observer(RefreshButton));
