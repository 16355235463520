import React from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { Card, Flex, Box, Icon, Text, Tag } from 'core/components';
import { formatMetricsValueForDisplay } from 'app/views/metrics/utils/formatMetricValues';
import { CONDITION_GROUP_CONNECTOR_TYPES } from 'app/forms/config/nms/constants';
import { NMS_POLICY_CONDITION_CONNECTORS, NMS_POLICY_CONDITION_OPERATOR_SYMBOLS } from '@kentik/ui-shared/nms/policies';

const ConditionConnector = ({ connector, not, ...props }) => {
  let connectorLabel = CONDITION_GROUP_CONNECTOR_TYPES.ANY;

  // Allowable connectors:
  // - All
  // - Any
  // - None (All + not)
  // Any + not is not allowed, though it is technically supported.

  if (connector === NMS_POLICY_CONDITION_CONNECTORS.ALL) {
    connectorLabel = not ? CONDITION_GROUP_CONNECTOR_TYPES.NONE : CONDITION_GROUP_CONNECTOR_TYPES.ALL;
  }

  return (
    <Text small muted {...props}>
      <b style={{ textTransform: 'capitalize' }}>{connectorLabel}</b> of the following:
    </Text>
  );
};

/**
 * This component renders a brief summary of conditions for a given condition root.
 * */
const NmsNativeAlertConditions = ({ $metrics, conditionRoot, title, showTitle }) => {
  if (!conditionRoot) {
    return null;
  }

  const { condition_groups: rootConditionGroups, connector: rootConnector } = conditionRoot || {};

  const renderConditionValues = (condition = {}, measurementModel, style) => {
    const { measurement, metric, state_change, state_in_condition, threshold } = condition;

    const getMetricValueLabel = (value) =>
      formatMetricsValueForDisplay(
        value,
        {
          metric,
          measurementModel,
          measurement
        },
        true
      );

    let conditionSentence = null;

    if (state_change) {
      const { from, to } = state_change;
      const { not: notFrom, states: fromStates } = from;
      const { not: notTo, states: toStates } = to;

      conditionSentence = (
        <Text small style={style}>
          was {notFrom && <b>not</b>} {fromStates.map(getMetricValueLabel)} and is {notTo && <b>not</b>}{' '}
          {toStates.map(getMetricValueLabel)}
        </Text>
      );
    }

    if (state_in_condition) {
      const { not: notIn, states } = state_in_condition;
      const displayStates = states.map(getMetricValueLabel);
      conditionSentence = (
        <Text small style={style}>
          is {notIn && <b>not</b>} {displayStates}
        </Text>
      );
    }

    if (threshold) {
      const { operator, condition_value } = threshold;
      const displayValue = getMetricValueLabel(condition_value);
      const displayOperator = NMS_POLICY_CONDITION_OPERATOR_SYMBOLS[operator];

      conditionSentence = (
        <Text small style={style}>
          {displayOperator} {displayValue}
        </Text>
      );
    }

    return conditionSentence;
  };

  const renderConditionItem = (condition, key) => {
    const { measurement, metric } = condition || {};
    const measurementModel = $metrics.measurementModel(measurement);

    if (!condition || !measurementModel) {
      return null;
    }

    const metricLabel = measurementModel.metricLabel(metric, true);

    return (
      <Flex alignItems="flex-start" key={key}>
        <Box position="relative" top={-3} mr="2px">
          <Icon icon="nest" color="muted" iconSize={11} />
        </Box>
        <Flex gap="4px" alignItems="flex-start" flexWrap="wrap">
          <Tag minimal round intent="primary">
            {metricLabel}
          </Tag>
          {/* Specify line height so that plain text condition values are centered with metric label tag when not flex-wrapped */}
          {renderConditionValues(condition, measurementModel, { lineHeight: '20px' })}
        </Flex>
      </Flex>
    );
  };

  const renderConditionGroups = (conditionGroups = [], key) => {
    if (!Array.isArray(conditionGroups) || conditionGroups.length === 0) {
      return null;
    }

    return conditionGroups.map((conditionGroup, idx) => {
      const {
        connector,
        conditions = [],
        condition_groups: childConditionGroups,
        not
      } = conditionGroup?.conditionGroupFields || {};
      const index = idx;

      return (
        <Card key={`${key}-${index}`} p={1} minWidth="100%">
          <Flex flexDirection="column" gap="6px">
            <ConditionConnector connector={connector} not={not} />
            <Flex flexDirection="column" gap="4px">
              {conditions.map(renderConditionItem)}
            </Flex>

            {/* Append index for unique child condition group key, e.g.: 'root-0-0' for grandchild group */}
            {renderConditionGroups(childConditionGroups, `${key}-${idx}`)}
          </Flex>
        </Card>
      );
    });
  };

  return (
    <Flex flexDirection="column" gap={1}>
      {showTitle && (
        <Text as="div" fontWeight="bold" fontSize={14}>
          {title}
        </Text>
      )}
      {rootConnector && <ConditionConnector connector={rootConnector} />}
      {renderConditionGroups(rootConditionGroups, 'ROOT')}
    </Flex>
  );
};

NmsNativeAlertConditions.propTypes = {
  conditionRoot: PropTypes.object.isRequired,
  title: PropTypes.string,
  showTitle: PropTypes.bool
};

NmsNativeAlertConditions.defaultProps = {
  title: 'Conditions',
  showTitle: true
};

export default inject('$metrics')(NmsNativeAlertConditions);
