/**
 * Attach to a Button
 * Used for confirming actions, but mostly used for deletion.
 */

import React, { Component } from 'react';
import { noop } from 'lodash';
import { Popover, Classes, Position } from '@blueprintjs/core';
import PropTypes from 'prop-types';

import Flex from './Flex';
import Box from './Box';
import Button from './Button';
import Text from './Text';

class ConfirmPopover extends Component {
  static propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onInteraction: PropTypes.func,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    entityName: PropTypes.string,
    confirmIntent: PropTypes.node,
    wrapperTagName: PropTypes.string,
    // should confirmation button be disabled
    confirmDisabled: PropTypes.bool
  };

  static defaultProps = {
    onInteraction: noop,
    entityName: 'item',
    confirmIntent: 'danger',
    confirmText: 'Remove',
    cancelText: 'Cancel',
    wrapperTagName: 'div',
    confirmDisabled: false
  };

  state = {
    isOpen: false,
    isRequestActive: false
  };

  handleInteraction = (isOpen, evt) => {
    const { onInteraction } = this.props;

    if (evt) {
      evt.stopPropagation();
    }

    if (onInteraction) {
      onInteraction(isOpen);
    }

    this.setState({ isOpen });
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    const { onConfirm } = this.props;
    this.setState({ isRequestActive: true });
    const promise = onConfirm();
    if (promise && promise.then) {
      promise.then(() => this.setState({ isOpen: false, isRequestActive: false }));
    } else {
      this.setState({ isOpen: false, isRequestActive: false });
    }
  };

  render() {
    const {
      children,
      entityName,
      confirmBodyContent,
      confirmIntent,
      confirmText,
      cancelText,
      onConfirm,
      onInteraction,
      confirmDisabled,
      ...rest
    } = this.props;
    const { isOpen, isRequestActive } = this.state;

    return (
      <Popover
        position={Position.BOTTOM_RIGHT}
        isOpen={isOpen}
        onInteraction={this.handleInteraction}
        content={
          <Box className={Classes.POPOVER_CONTENT} p={2} onClick={(evt) => evt.stopPropagation()}>
            {confirmBodyContent || (
              <Text>
                Are you sure you want to {confirmText.toLowerCase()} this {entityName}?
              </Text>
            )}

            <Flex justifyContent="flex-end" mt={2}>
              <Button onClick={this.handleCancel} mr={1} disabled={isRequestActive} title="Cancel">
                {cancelText}
              </Button>
              <Button
                intent={confirmIntent}
                onClick={this.handleConfirm}
                loading={isRequestActive}
                disabled={confirmDisabled}
                title="Confirm"
              >
                {confirmText}
              </Button>
            </Flex>
          </Box>
        }
        {...rest}
      >
        {children}
      </Popover>
    );
  }
}

export default ConfirmPopover;
