import React, { Component } from 'react';
import { action, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';

import { Flex, Box } from 'core/components';
import { formConsumer, Field, InputGroup, Select } from 'core/form';

export function getCutFnFieldName(fieldConfigName) {
  return fieldConfigName.slice('cutFn.'.length);
}

@inject('$dictionary', '$explorer')
@formConsumer
@observer
class CutFnSection extends Component {
  componentDidMount() {
    const { form, model } = this.props;

    this.dimensionChangeDisposer = reaction(
      () => form.getValue('metric'),
      () => this.updateCutFnFields()
    );

    this.updateCutFnFields();
    if (model) {
      this.initializeCutFnFields(model);
    }
  }

  componentDidUpdate(prevProps) {
    const { model } = this.props;
    if (prevProps.model !== model) {
      this.updateCutFnFields();
      this.initializeCutFnFields(model);
    }
  }

  componentWillUnmount() {
    if (this.dimensionChangeDisposer) {
      this.dimensionChangeDisposer();
    }
  }

  @action
  updateCutFnFields = () => {
    const { $dictionary, form } = this.props;
    const cutFnFields = $dictionary.get('cutFn.fields');

    const metric = form.getValue('metric');

    const cutFnFieldConfigs = form.fieldConfigs.filter((config) => config.name.startsWith('cutFn.'));

    cutFnFieldConfigs.forEach((field) => {
      const fieldName = getCutFnFieldName(field.name);
      if (!metric.includes(fieldName)) {
        form.removeField(field.name);
        form.removeField(`cutFnRegex.${fieldName}`);
        form.removeField(`cutFnSelector.${fieldName}`);
      }
    });

    metric.forEach((field) => {
      if (cutFnFields.includes(field)) {
        const currCutFn = cutFnFieldConfigs.find((config) => config.name.startsWith(`cutFn.${field}`));
        if (!currCutFn) {
          form.addField(`cutFn.${field}`, { suppressAutoSubmit: true }, '');
          form.addField(`cutFnRegex.${field}`, {}, '');
          form.addField(`cutFnSelector.${field}`, {}, '');
        }
      }
    });
  };

  @action
  initializeCutFnFields(model) {
    const { form } = this.props;
    const cutFn = model.get('cutFn');
    const cutFnRegex = model.get('cutFnRegex');
    const cutFnSelector = model.get('cutFnSelector');

    Object.keys(cutFn).forEach((metric) => form.getField(`cutFn.${metric}`).init(cutFn[metric]));
    Object.keys(cutFnRegex).forEach((metric) => form.getField(`cutFnRegex.${metric}`).init(cutFnRegex[metric]));
    Object.keys(cutFnSelector).forEach((metric) =>
      form.getField(`cutFnSelector.${metric}`).init(cutFnSelector[metric])
    );
  }

  getLabel = (field) => {
    const { $dictionary } = this.props;
    const { chartTypesValidations } = $dictionary.dictionary;

    return chartTypesValidations[field];
  };

  handleCutFnChange = (field, value) => {
    const { form, $dictionary, $explorer } = this.props;
    const cutFunction = $dictionary.dictionary.cutFn.functions.find((fn) => fn.value === value);

    const name = getCutFnFieldName(field.name);
    const cutFnRegexField = form.getField(`cutFnRegex.${name}`);
    const cutFnSelectorField = form.getField(`cutFnSelector.${name}`);

    cutFnRegexField.setValue(value);
    cutFnSelectorField.setValue((cutFunction && cutFunction.selector) || '');

    $explorer.onFormChange({ form, formValues: form.getValues(), field: field.name });
  };

  render() {
    const { form, $dictionary } = this.props;
    const { functions: cutFunctions, urlFields: cutFnUrlFields } = $dictionary.dictionary.cutFn;
    const cutFnFields = form.fieldStates.filter((field) => field.name.startsWith('cutFn.'));
    const cutFnRegexFields = form.fieldStates.filter((field) => field.name.startsWith('cutFnRegex.'));
    const cutFnSelectorFields = form.fieldStates.filter((field) => field.name.startsWith('cutFnSelector.'));

    if (cutFnFields.length > 0) {
      return (
        <Box mt={1}>
          {cutFnFields.map((field, idx) => {
            const fieldName = getCutFnFieldName(field.name);
            const label = this.getLabel(fieldName);
            const regexField = cutFnRegexFields[idx];
            const selectorField = cutFnSelectorFields[idx];
            const type = cutFnUrlFields.includes(fieldName) ? 'url' : 'host';
            const cutFns = [{ value: '', label: 'None', selector: '' }].concat(cutFunctions.filter((fn) => fn[type]));

            return (
              <Box key={fieldName} className="pt-callout" mb={1}>
                <Field field={field} label={`Extract from ${label}`} onChange={this.handleCutFnChange} options={cutFns}>
                  <Select className="pt-small" />
                </Field>
                <Flex>
                  <Box col={8} mr={1}>
                    <Field field={regexField} label="Regex" className="no-margin">
                      <InputGroup className="pt-small" />
                    </Field>
                  </Box>
                  <Box col={4}>
                    <Field field={selectorField} label="Selector" className="no-margin">
                      <InputGroup className="pt-small" />
                    </Field>
                  </Box>
                </Flex>
              </Box>
            );
          })}
        </Box>
      );
    }

    return null;
  }
}

export default CutFnSection;
