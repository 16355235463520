import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Text, Suspense, Spinner } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import TopFiveTraffic from 'app/views/serviceProvider/cdn/TopFiveTraffic';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import ModuleLinkButton from '../../../landing/ModuleLinkButton';

function LoadingSpinner() {
  return (
    <Box mt={2}>
      <Spinner size={24} />
    </Box>
  );
}

@inject('$cdns')
@observer
class CdnWidget extends Component {
  state = {
    loadingModuleConfig: true,
    loadingNonCdnTraffic: true,
    nonCdnTraffic: null
  };

  aggregateType = 'max_bits_per_sec';

  componentDidMount() {
    const { $cdns } = this.props;

    $cdns.getModuleConfig().then(() => {
      this.setState({ loadingModuleConfig: false });
    });
  }

  getTotalTraffic(results) {
    const model = results.models.find((item) => item.get('name') === 'Total');
    return model ? model.get(this.aggregateType) : 0;
  }

  getNonCdnTraffic(results) {
    const model = results.models && results.models[0];
    return model ? model.get(this.aggregateType) : 0;
  }

  handleNonCdnQueryComplete = ({ results }) => {
    this.setState({ nonCdnTraffic: this.getNonCdnTraffic(results), loadingNonCdnTraffic: false });
  };

  render() {
    const { $cdns, canCustomize, onRemove } = this.props;
    const { loadingModuleConfig, loadingNonCdnTraffic, nonCdnTraffic } = this.state;
    const title = 'CDN Analytics';
    const route = '/v4/service-provider/cdn';

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        onRemove={onRemove}
        title={title}
        display="flex"
        flexDirection="column"
        p={2}
      >
        {!nonCdnTraffic && (
          <LightweightDataViewWrapper
            onQueryComplete={this.handleNonCdnQueryComplete}
            query={$cdns.queries.getNonCdnQuery({})}
          />
        )}

        <Suspense loading={loadingModuleConfig} fallback={<LoadingSpinner />}>
          <LightweightDataViewWrapper query={$cdns.queries.getTrafficQuery({})}>
            {({ loading, results }) => {
              if (loading || loadingNonCdnTraffic) {
                return <LoadingSpinner />;
              }

              const total = this.getTotalTraffic(results);
              const traffic = total > 0;

              return (
                <>
                  {!traffic && (
                    <>
                      <Text as="div" muted mb={2} flex={1}>
                        You do not have any CDN traffic.
                      </Text>
                      <ModuleLinkButton text={`Setup ${title}`} to={route} />
                    </>
                  )}
                  {traffic && (
                    <>
                      <Box mb={2} flex={1}>
                        <TopFiveTraffic
                          altLayout
                          aggregateType={this.aggregateType}
                          nonCdnTraffic={nonCdnTraffic}
                          total={total}
                        />
                      </Box>
                      <ModuleLinkButton text={title} to={route} />
                    </>
                  )}
                </>
              );
            }}
          </LightweightDataViewWrapper>
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default CdnWidget;
