import { Menu, MenuItem, Popover } from 'core/components';
import SelectPopover from 'core/components/select/SelectPopover';
import { minBy } from 'lodash';
import React, { Component } from 'react';
import { ControlGroup, InputGroup } from '.';
import formConsumer from '../formConsumer';

const timeOptions = [];

// populate timeOptions with 00:00, 00:30, 01:00, etc.
for (let hour = 0; hour < 24; hour++) {
  const interval = 30;
  for (let i = 0; i < 60 / interval; i++) {
    const time = `${hour.toString().padStart(2, '0')}:${(interval * i).toString().padStart(2, '0')}`;
    timeOptions.push({ value: time, label: time });
  }
}

function timeToMinutes(time) {
  const [, h, m] = time.match(/(\d{2}):(\d{2})/);
  return h * 60 + m;
}

@formConsumer
export default class DateAndTime extends Component {
  static getDerivedStateFromProps(props, state) {
    const { value } = props;

    if (value !== state.value) {
      const [date = '', time = ''] = value.split(' ', 2);
      return { value, date, time };
    }

    return null;
  }

  state = {
    value: null,
    date: null,
    time: null
  };

  constructor(props) {
    super(props);
    const { value } = props;
    const [date = '', time = ''] = value.split(' ', 2);
    Object.assign(this.state, { value, date, time });
  }

  menuRef = React.createRef();

  get closestTime() {
    const { time } = this.state;

    if (/^\d{2}:\d{2}$/.test(time)) {
      const minutes = timeToMinutes(time);
      return minBy(timeOptions, (option) => Math.abs(timeToMinutes(option.value) - minutes)).value;
    }

    return null;
  }

  handleMenuOpen = () => {
    if (this.menuRef.current) {
      const activeItem = this.menuRef.current.querySelector('.active');

      if (activeItem) {
        activeItem.scrollIntoView({ block: 'center' });
      }
    }
  };

  handleSelectTime = (time) => {
    const { onChange } = this.props;
    const { date } = this.state;
    const newValue = [date, time].join(' ');

    this.setState({ time });
    onChange(newValue);
  };

  handleChangeDate = (evt) => {
    const { onChange } = this.props;
    const { time } = this.state;
    const date = evt.target.value;

    this.setState({ date });

    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      const newValue = [date, time].join(' ');
      onChange(newValue);
    }
  };

  handleChangeTime = (evt) => {
    const { onChange } = this.props;
    const { date } = this.state;
    const time = evt.target.value;

    const timeRegex = /^(\d{1,2}):?(\d{2})$/;
    if (timeRegex.test(time)) {
      const matches = time.match(timeRegex);
      const formattedTime = matches.splice(1).join(':');
      const newValue = [date, formattedTime].join(' ');
      onChange(newValue);
    }
  };

  render() {
    const { small } = this.props;
    const { date, time } = this.state;
    const { closestTime } = this;

    return (
      <ControlGroup fill>
        <InputGroup value={date} small={small} onChange={this.handleChangeDate} />
        <Popover
          position={SelectPopover.defaultProps.popoverPosition}
          modifiers={SelectPopover.defaultProps.modifiers}
          autoFocus={false}
          onOpening={this.handleMenuOpen}
          captureDismiss
          target={
            <InputGroup
              value={time}
              small={small}
              onChange={(evt) => this.setState({ time: evt.target.value })}
              onBlur={this.handleChangeTime}
            />
          }
          content={
            <Menu maxHeight={200} overflow="auto" ulRef={this.menuRef}>
              {timeOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  text={option.label}
                  intent={option.value === closestTime ? 'primary' : undefined}
                  className={option.value === closestTime ? 'active' : ''}
                  small={small}
                  onClick={() => this.handleSelectTime(option.value)}
                />
              ))}
            </Menu>
          }
        />
      </ControlGroup>
    );
  }
}
