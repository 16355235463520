/*
  A date picker for test results that supports:
  - ability to jump back, forward, and to latest results using the selected interval
  - rendering a custom lookback option menu, categorized by 'raw' and 'aggregate' options
  - rendering a custom select button value indicating lookback (if selected) and time range
*/
import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { IoPlaySkipForwardSharp } from 'react-icons/io5';
import { FiInfo } from 'react-icons/fi';
import { DEFAULT_LOOKBACK_OPTIONS } from 'shared/synthetics/constants';
import { getLookbackOptions } from 'shared/synthetics/utils';
import { getDateRangeDisplay, getQueryTimeInterval, secondsIntervalToText } from 'core/util/dateUtils';
import { Box, Button, Flex, Icon, MenuItem, Text, Tooltip } from 'core/components';
import TimeSelector from 'app/views/core/explorer/sidebar/TimeSelector';

@inject('$auth')
@observer
export default class DatePicker extends React.Component {
  state = { startDate: undefined, endDate: undefined };

  static defaultProps = {
    navigationEnabled: true, // displays left/right chevron and 'Latest' buttons for navigating time ranges
    buildRules: null, // optional callback to modify validation rules for start/end
    maxDate: null // optionally specify a maximum selectable date
  };

  static getDerivedStateFromProps(props) {
    // ensures TimeSelector gets the date objects it requires
    // blank the seconds out so we can work with clean, accurate date ranges --- especially important for BGP time range restrictions
    // we'll also make sure to do the same with date ranges dispatched from the TimeSelector
    return {
      startDate: moment.unix(props.startDate).seconds(0).toDate(),
      endDate: moment.unix(props.endDate).seconds(0).toDate()
    };
  }

  // dispatches time change events as unix dates
  handleTimeChange = ({ lookbackSeconds, startDate, endDate }) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange({
        lookbackSeconds,
        startDate: moment(startDate).seconds(0).unix(),
        endDate: moment(endDate).seconds(0).unix()
      });
    }
  };

  /*
    jumps to specified time ranges using the selected interval
    supports jumping back, forward, and to latest results
  */
  jump = ({ direction }) => {
    // get the date range based on the desired direction
    const { startDate, endDate } = this.jumpInterval[`${direction}Range`];

    if (startDate && endDate) {
      this.handleTimeChange({ lookbackSeconds: 0, startDate, endDate });
    }
  };

  // returns the number of seconds between the start and end date whether it be from lookback option or date range
  get interval() {
    const { lookbackSeconds } = this.props;
    const { startDate, endDate } = this.state;

    return getQueryTimeInterval({
      // anything falsey should fallback down to a value of 0 which is what this util is expecting
      // this ensures we don't end up with a start and end date of the same value as a result
      lookback_seconds: lookbackSeconds || 0,
      starting_time: startDate,
      ending_time: endDate
    });
  }

  // returns the interval (see above) in milliseconds
  get msInterval() {
    return this.interval * 1000;
  }

  // readable label indicating the size of the interval
  get textInterval() {
    return secondsIntervalToText(this.interval);
  }

  // returns calculated value used for navigation and determining button disable state
  get jumpInterval() {
    const { startDate } = this.state;
    const currentTs = moment().seconds(0).valueOf(); // current unix time in milliseconds
    const startTs = moment(startDate).valueOf(); // selected start time in unix milliseconds
    const nextInterval = this.msInterval * 2;

    return {
      currentTs,
      startTs,
      nextInterval,
      backRange: { startDate: startTs - this.msInterval, endDate: startTs }, // move the window left
      nextRange: { startDate: startTs + this.msInterval, endDate: startTs + nextInterval }, // move the window right
      latestRange: { startDate: currentTs - this.msInterval, endDate: currentTs } // move the end of the window to the current timestamp
    };
  }

  // disable the forward navigation if the window will overrun the current timestamp
  get isForwardDisabled() {
    const { currentTs, startTs, nextInterval } = this.jumpInterval;

    if (startTs) {
      return startTs + nextInterval > currentTs;
    }

    return true;
  }

  // help info to describe 'raw' vs 'aggregate' menu options
  getLookbackMenuTooltipContent({ type, test }) {
    let content = 'Larger time ranges will show  averaged metrics from multiple test runs';

    if (type === 'raw') {
      const label = test.frequencyLabel ? `(${test.frequencyLabel})` : '';
      content = `Time ranges with granular data, based on this test's frequency ${label}`;
    }

    return <Box width={180}>{content}</Box>;
  }

  // renders the list of lookback options as a categorized list of 'raw' and 'aggregate' options
  // allows for overriding via custom prop
  renderLookbackOptions = (opts) => {
    const { test, lookbackMenuRenderer, maxRawLookback } = this.props;
    const { lookbackOptions, lookbackSeconds } = opts;

    if (!test) {
      // allow use as a straight-up date picker
      return null;
    }

    if (lookbackMenuRenderer) {
      return lookbackMenuRenderer(opts);
    }

    const optionsByType = getLookbackOptions({ test, lookbackOptions, maxRawLookback });

    return Object.entries(optionsByType).reduce((allOptions, [type, options]) => {
      if (options.length > 0) {
        return allOptions.concat([
          <Flex key={type} gap="4px" my="4px" ml="4px" alignItems="center">
            <Text fontWeight="bold" textTransform="capitalize" small>
              {type}
            </Text>
            <Tooltip content={this.getLookbackMenuTooltipContent({ type, test })} minimal>
              <Icon icon={FiInfo} color="primary" />
            </Tooltip>
          </Flex>,
          options.map(({ value, label }) => (
            <MenuItem
              key={value}
              text={label}
              selected={value === lookbackSeconds}
              onClick={() => this.handleTimeChange({ lookbackSeconds: value })}
              small
            />
          ))
        ]);
      }

      return allOptions;
    }, []);
  };

  // returns lookback options used to hydrate the lookback options menu
  // by default the options are filtered to use options supported by a test's minimum lookback
  // allow for overriding via custom prop
  get lookbackOptions() {
    const { lookbackOptions, minLookbackSeconds, maxLookbackSeconds } = this.props;

    if (lookbackOptions) {
      return lookbackOptions;
    }

    return DEFAULT_LOOKBACK_OPTIONS.filter((option) => {
      const greaterThanMin = option.value >= minLookbackSeconds;

      if (maxLookbackSeconds) {
        return greaterThanMin && option.value <= maxLookbackSeconds;
      }

      return greaterThanMin;
    });
  }

  // by default, renders select button text with lookback (where supported) and time range and format (utc or local)
  renderButtonText = ({ lookbackOption, startDate, endDate, timeFormat }) => {
    const { buttonTextRenderer } = this.props;

    if (buttonTextRenderer) {
      return buttonTextRenderer({ lookbackOption, startDate, endDate, timeFormat });
    }

    let startDateToRender = startDate;
    let endDateToRender = endDate;
    let lookbackLabel = '';

    if (lookbackOption) {
      lookbackLabel = `(Last ${lookbackOption.label})`;
      endDateToRender = moment();
      startDateToRender = endDateToRender.clone().subtract(lookbackOption.value, 'seconds');
    }

    return (
      <Box my="4px">
        <Text as="div" small color="muted" mb="1px">
          Time Range {lookbackLabel}
        </Text>
        <Text fontWeight="bold" small>
          {getDateRangeDisplay(startDateToRender, endDateToRender)} ({timeFormat})
        </Text>
      </Box>
    );
  };

  render() {
    const {
      $auth,
      lookbackSeconds,
      startDate: s,
      endDate: e,
      maxDate,
      test,
      lookbackMenuRenderer,
      maxRawLookback,
      lookbackOptions,
      minLookbackSeconds,
      maxLookbackSeconds,
      buttonTextRenderer,
      buildRules,
      navigationEnabled,
      onChange,
      ...containerProps
    } = this.props;
    const { startDate, endDate } = this.state;

    return (
      <Flex alignItems="center" gap={1} {...containerProps}>
        {navigationEnabled && (
          <Button
            icon="chevron-left"
            title={`Back ${this.textInterval}`}
            onClick={() => this.jump({ direction: 'back' })}
            small
          />
        )}
        <TimeSelector
          lookbackSeconds={lookbackSeconds}
          startDate={startDate}
          endDate={endDate}
          maxDate={maxDate}
          timeFormat={$auth.userTimezone}
          lookbackOptions={this.lookbackOptions}
          lookbackMenuRenderer={this.renderLookbackOptions}
          buttonTextRenderer={this.renderButtonText}
          buildRules={buildRules}
          onChange={this.handleTimeChange}
          showDateRangePicker
          small
        />
        {navigationEnabled && (
          <>
            <Button
              icon="chevron-right"
              disabled={this.isForwardDisabled}
              title={this.isForwardDisabled ? undefined : `Forward ${this.textInterval}`}
              onClick={() => this.jump({ direction: 'next' })}
              small
            />
            <Button
              text="Latest"
              rightIcon={IoPlaySkipForwardSharp}
              onClick={() => this.jump({ direction: 'latest' })}
              small
            />
          </>
        )}
      </Flex>
    );
  }
}
