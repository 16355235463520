import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { PopoverPosition } from '@blueprintjs/core';

import { Flex, Button, Menu, MenuItem, Popover, Text } from 'core/components';

const METRIC_OPTIONS = [
  { value: 'bits_per_sec', label: 'Bits/s' },
  { value: 'pkts_per_sec', label: 'Packets/s' },
  { value: 'flows_per_sec', label: 'Flows/s' }
];

@inject('$app', '$exports')
class MetricSelector extends Component {
  state = {
    isOpen: false
  };

  handleInteraction = (nextOpenState) => {
    this.setState({ isOpen: nextOpenState });
  };

  handleSelect = (metric) => {
    const { $exports, aggregateType, onChange, hashEnabled } = this.props;

    const [aggregateFn] = aggregateType.split('_');
    const newAggregateType = [aggregateFn, metric].join('_');

    onChange(newAggregateType);

    if (hashEnabled) {
      $exports.setHash({
        hashedAggregateType: newAggregateType
      });
    }
  };

  render() {
    const { $app, aggregateType } = this.props;
    const { isOpen } = this.state;

    const [, ...rest] = aggregateType.split('_');
    const metric = rest.join('_');
    const selectedOption = METRIC_OPTIONS.find((option) => option.value === metric);
    const buttonText = selectedOption ? selectedOption.label : `Last ${metric}s`;

    const width = 94;

    if ($app.isExport) {
      return (
        <Flex px="12px" alignItems="center">
          <Text as="div" fontSize="small" color="muted" pb="1px">
            {buttonText}
          </Text>
        </Flex>
      );
    }
    return (
      <Popover
        isOpen={isOpen}
        onInteraction={this.handleInteraction}
        position={PopoverPosition.BOTTOM_RIGHT}
        content={
          <Menu minWidth={width}>
            {METRIC_OPTIONS.map((option) => (
              <MenuItem
                key={option.value}
                active={metric === option.value}
                text={option.label}
                onClick={() => this.handleSelect(option.value)}
                minWidth={width - 8}
              />
            ))}
          </Menu>
        }
      >
        <Button textAlign="left" rightIcon="caret-down" active={isOpen} width={width}>
          <Text as="div" fontSize="small" color="muted" pb="1px">
            Metric
          </Text>
          <Text as="div" fontWeight="bold" fontSize="small">
            {buttonText}
          </Text>
        </Button>
      </Popover>
    );
  }
}

export default MetricSelector;
