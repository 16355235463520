import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';
import OttIcon from 'app/views/serviceProvider/ott/OttIcon';

const OttServiceLink = ({ $app, service, provider, showIcon = false, tab, children, ...rest }) => {
  const content =
    children || (showIcon ? <OttIcon type="service" value={service} provider={provider} height={14} /> : service);

  if ($app.isSubtenant) {
    return content;
  }
  return (
    <Link to={`/v4/service-provider/ott/service/${encodeURIComponent(service)}${tab ? `/${tab}` : ''}`} {...rest}>
      {content}
    </Link>
  );
};

export default inject('$app')(OttServiceLink);
