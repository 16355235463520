import { inject, observer } from 'mobx-react';
import moment from 'moment';
import BaseHighchartsDataview from './BaseHighchartsDataview';
import { getPeriodOverPeriodTooltip, getSharedTooltip, sharedTooltipPositioner } from './tooltipRenderers';

class StackedAreaView extends BaseHighchartsDataview {
  sync = false;

  chartOptions = {
    chart: {
      zoomType: 'x',
      events: {
        selection: this.handleTimeZoom
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      minRange: 60000,
      dateTimeLabelFormats: {
        day: '%m/%e',
        minute: '%H:%M'
      },
      title: {
        text: 'UTC',
        style: { color: this.chartLabelColor }
      },
      labels: { style: { color: this.chartLabelColor } }
    },
    yAxis: [
      {
        min: 0.001,
        title: {
          text: 'units go here',
          style: { color: this.chartLabelColor }
        },
        labels: { style: { color: this.chartLabelColor } }
      }
    ],
    plotOptions: {
      series: {
        marker: {
          enabled: false
        }
      },
      area: {
        stacking: 'normal'
      }
    },
    legend: { enabled: false },
    tooltip: {
      shared: true,
      crosshairs: true,
      outside: true,
      useHTML: true,
      followPointer: true,
      hideDelay: 0,
      positioner: sharedTooltipPositioner.bind(this)
    },
    series: []
  };

  renderSeries(bucket, name, data, query) {
    const { viewProps } = this.props;

    if (!this.chart) {
      return null;
    }

    const yAxis = this.getYAxis(bucket);
    const isPreviousPeriod = bucket.get('isPreviousPeriod');
    let type = 'area';
    let className = '';
    let visible = true;

    if (isPreviousPeriod) {
      className = 'highcharts-series-dash';

      if (name === 'Total') {
        type = 'line';
      } else {
        // hide previous period areas by default
        visible = false;
      }
    }

    const seriesData = { type, name, data, yAxis, className, visible, isPreviousPeriod };
    const matchingSeries = this.chart.series.find((series) => series.name === name);
    if (matchingSeries) {
      seriesData.linkedTo = matchingSeries.name;
      seriesData.color = matchingSeries.color;
      seriesData.colorIndex = matchingSeries.colorIndex;
      this.addNativeLegendHoverEvents(matchingSeries);
    } else {
      seriesData.id = name;
      if (query.get('filterDimensionsEnabled') && query.get('filterDimensionSort')) {
        const { filterGroups } = query.get('filterDimensions');
        seriesData.colorIndex = filterGroups.findIndex((group) => group.name === name);
      } else if (viewProps.getColorIndex) {
        seriesData.colorIndex = viewProps.getColorIndex(name, seriesData);
      }
    }
    return this.chart.addSeries(seriesData, false);
  }

  findSeries(bucket, name) {
    const yAxisIdx = this.getYAxis(bucket);
    return this.chart.series.find((series) => series.name === name && series.yAxis === this.chart.yAxis[yAxisIdx]);
  }

  removeSeries(bucketName, name) {
    const yAxisIndex = this.getYAxis(bucketName);
    const existingSeries =
      this.chart &&
      this.chart.series.find((series) => series.name === name && series.yAxis === this.chart.yAxis[yAxisIndex]);
    if (existingSeries) {
      existingSeries.remove(false);
    } else {
      console.warn('Attempted to remove series', name, 'from bucket', bucketName, ', but no series was found.');
    }
  }

  renderOverlay(bucket, name, data) {
    if (!this.chart) {
      return null;
    }

    const type = 'line';
    const yAxis = this.getYAxis(bucket);
    const isPreviousPeriod = bucket.get('isPreviousPeriod');
    let colorIndex = 50;
    let className = '';

    if (name.startsWith('Historical Total')) {
      colorIndex = 500;
    }

    if (isPreviousPeriod) {
      className = 'highcharts-series-dash';
    }

    const seriesData = { type, name, data, yAxis, colorIndex, className, isPreviousPeriod, zIndex: 1 };
    const matchingSeries = this.chart.series.find((series) => series.name === name);
    if (matchingSeries) {
      seriesData.linkedTo = name;
      this.addNativeLegendHoverEvents(matchingSeries);
    } else {
      seriesData.id = name;
    }

    return this.chart.addSeries(seriesData, false);
  }

  redraw(opts) {
    const { $app, viewProps } = this.props;
    super.redraw(opts);

    $app.renderSync(() => {
      if (this.chart && this.chart.series && this.chart.series.length > 0) {
        const { dateRangeDisplay } = this.props.dataview.queryBuckets.activeBuckets[0].firstQuery;
        const { data } = this.chart.series[0].userOptions;
        if (viewProps.showAxisLabels !== false && data && data.length) {
          const minuteIntervals = (data[1][0] - data[0][0]) / 60000;
          const dateRangeIntervalsDisplay = `${
            minuteIntervals >= 1 ? `${minuteIntervals} minute intervals` : `${minuteIntervals * 60} second intervals`
          }`;
          this.chart.xAxis[0].setTitle({
            text: `${dateRangeDisplay} (${dateRangeIntervalsDisplay})`
          });
        }
      }

      if (!this.hasOverriddenExtremes && this.chart && this.chart.yAxis && this.chart.yAxis.length > 1) {
        if (this.sync) {
          const { dataMin } = this.chart.yAxis[1].getExtremes();
          const { dataMax } = this.chart.yAxis[0].getExtremes();
          const extreme = Math.max(Math.abs(dataMin), dataMax);
          if (extreme) {
            this.chart.yAxis.forEach((axis) => axis.setExtremes(dataMin < 0 ? extreme * -1 : 0.001, extreme));
          }
        } else {
          const { dataMin: extremeMin } = this.chart.yAxis[1].getExtremes();
          this.chart.yAxis.forEach((axis) => {
            const { dataMin, dataMax } = axis.getExtremes();
            const extreme = Math.max(Math.abs(dataMin), Math.abs(dataMax));
            if (extreme) {
              axis.setExtremes(extremeMin < 0 ? extreme * -1 : 0.001, extreme);
            }
          });
        }
      }

      if (this.chart && this.chart.yAxis && viewProps.plotLines) {
        this.chart.yAxis[0].update({ plotLines: viewProps.plotLines });
      }
      if (viewProps.chartConfig && viewProps.chartConfig.plotOptions) {
        const { plotOptions } = viewProps.chartConfig;
        if (plotOptions.series && plotOptions.series.zones) {
          const { dataMax } = this.chart.yAxis[0].getExtremes();
          plotOptions.series.zones.forEach((zone) => {
            if (zone.value && dataMax < zone.value) {
              zone.className += ' zone-green';
            }
          });
        }
        this.chart.update({ plotOptions });
      }
    });
  }

  getComponent() {
    const { chartOptions } = this;
    const { $dictionary, dataview, showNativeLegend, viewProps } = this.props;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const query = activeBuckets[0].firstQuery;

    if (query.get('outsort').includes('agg_total')) {
      throw new Error('This Time-Series chart does not support Display & Sort By Total.');
    }

    chartOptions.xAxis.momentFn = query.get('time_format') === 'UTC' ? moment.utc : moment;
    chartOptions.xAxis.title.text = query.dateRangeDisplay;
    chartOptions.xAxis.plotBands = viewProps.xPlotBands;
    chartOptions.yAxis[0].title.text = `${$dictionary.get('units')[query.outsortUnit]}`;
    chartOptions.yAxis[0].unit = query.outsortUnit;
    chartOptions.yAxis[0].type = query.get('use_log_axis') ? 'logarithmic' : 'linear';
    chartOptions.yAxis[0].custom = query.get('use_log_axis') ? { allowNegativeLog: true } : {};
    chartOptions.yAxis[0].min = query.get('use_log_axis') ? 0 : 0.001;

    if (viewProps.plotLines) {
      chartOptions.yAxis[0].endOnTick = false;
      chartOptions.yAxis[0].softMax = viewProps.plotLines.reduce((max, { value }) => Math.max(max, value), 0) * 1.1;
      chartOptions.yAxis[0].plotLines = viewProps.plotLines;
    }

    if (showNativeLegend) {
      chartOptions.legend = {
        itemStyle: {
          color: `${this.chartLabelColor} !important`,
          fontWeight: 'normal'
        },
        itemHoverStyle: {
          color: `${this.chartLabelColor} !important`,
          fontWeight: 'bold !important'
        },
        labelFormatter() {
          return this.name.substr(0, 16);
        },
        verticalAlign: 'bottom',
        align: 'center'
      };
    }

    if (
      (activeBucketCount > 1 && !dataview.queryBuckets.hasPeriodOverPeriod) ||
      (query.get('secondaryOutsort') && query.get('secondaryTopxSeparate') === false)
    ) {
      chartOptions.yAxis[0].min = null;
      chartOptions.yAxis[0].title.text = `${$dictionary.get('units')[query.outsortUnit]} \u2192`;
      const outsortUnit = activeBuckets[1] ? activeBuckets[1].firstQuery.outsortUnit : query.secondaryOutsortUnit;
      if (!chartOptions.yAxis[1]) {
        chartOptions.yAxis.push({
          title: {
            text: `${$dictionary.get('units')[outsortUnit]} \u2192`
          },
          opposite: true,
          gridLineColor: '#DEDEDE',
          gridLineWidth: 1,
          plotLines: [
            {
              id: 'zero-plot',
              value: 0,
              width: 2,
              color: '#c0c0c0',
              zIndex: 5
            }
          ],
          unit: outsortUnit,
          type: query.get('use_secondary_log_axis') ? 'logarithmic' : 'linear',
          custom: query.get('use_secondary_log_axis') ? { allowNegativeLog: true } : {}
        });
      } else {
        chartOptions.yAxis[1].title.text = `${$dictionary.get('units')[outsortUnit]} \u2192`;
        chartOptions.yAxis[1].unit = outsortUnit;
      }
    } else if (chartOptions.yAxis.length > 1) {
      chartOptions.yAxis = chartOptions.yAxis.slice(0, 1);
    }

    const { getColorFromIndex } = this;
    if (dataview.queryBuckets.hasPeriodOverPeriod) {
      chartOptions.tooltip.formatter = function formatter() {
        return getPeriodOverPeriodTooltip({
          points: this.points,
          offset: moment
            .duration(query.get('period_over_period_lookback'), query.get('period_over_period_lookback_unit'))
            .asMilliseconds(),
          getColorFromIndex,
          showSeconds: query.get('reAggInterval') ? query.get('reAggInterval') < 60 : false
        });
      };
    }
    const overrideFormatter = query.get('reAggInterval') && query.get('reAggInterval') < 60;
    chartOptions.tooltip.formatter =
      chartOptions.tooltip.formatter && !overrideFormatter
        ? chartOptions.tooltip.formatter
        : function formatter() {
            return getSharedTooltip({
              points: this.points,
              seriesType: 'area',
              tooltip: getSharedTooltip({
                points: this.points,
                getColorFromIndex,
                showSeconds: query.get('reAggInterval') ? query.get('reAggInterval') < 60 : false
              }),
              getColorFromIndex
            });
          };

    if (viewProps.showAxisLabels === false) {
      chartOptions.xAxis.title.text = null;
      chartOptions.yAxis.forEach((yAxis) => (yAxis.title.text = null));
    }

    if (viewProps.showTooltips === false) {
      chartOptions.tooltip.enabled = false;
      chartOptions.tooltip.shared = false;
      chartOptions.tooltip.crosshairs = false;
    }

    return super.getComponent();
  }
}

export default inject('$app', '$dictionary')(observer(StackedAreaView));

const config = {
  showTotalTrafficOverlay: true,
  showLegend: true,
  timeBased: true,
  isSVG: true,
  enableToggle: true,
  mirrorable: true,
  allowsSecondaryOverlay: true,
  allowsSyncAxes: true,
  supportsLogAxis: true,
  buckets: [
    {
      name: 'Left +Y Axis',
      mirrorBucket: 1,
      secondaryOverlayBucket: 1
    },
    {
      name: 'Right -Y Axis',
      sampleRateFactor: -1
    }
  ]
};
const uninjectedComponent = StackedAreaView;

export { config, uninjectedComponent };
