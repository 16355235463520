import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FiMoreVertical } from 'react-icons/fi';

import { Button, Menu, MenuItem, Popover } from 'core/components';
import { getQueryMapFromAggregateFilterQuery } from 'core/util/filters';
import { timezone } from 'core/util/dateUtils';
import { getFilterField } from 'app/components/filters/simple/simpleFilterUtils';
import { getModelFilters } from 'app/util/filters';

@inject('$app', '$dictionary', '$explorer')
@observer
class ActionsMenu extends Component {
  state = {
    isOpen: false
  };

  handleInteraction = (nextOpenState) => {
    this.setState({ isOpen: nextOpenState });
  };

  renderMenuItems() {
    const { $dictionary, $explorer, queryModel, model, shouldAddDeviceColumn, createActionMenuItems } = this.props;
    const additionalMenuItems = createActionMenuItems ? createActionMenuItems({ model }) : [];
    if (queryModel.get('aggregateFiltersEnabled')) {
      const queryMap = getQueryMapFromAggregateFilterQuery(queryModel);
      return [
        ...queryModel.get('aggregateFilters').map((filter) => (
          <MenuItem
            key={filter.name}
            text={`Open '${filter.name}' in Data Explorer`}
            onClick={() => {
              const query = queryMap[filter.name];
              const filtersAcc = {
                connector: 'All',
                filterGroups: []
              };

              const origMetric = query.metric;

              // add in the model filters to filtersAcc
              query.metric = query.metric.slice(0, 1); // we want to only one metric to build model filters, i think?
              getModelFilters({ model, query, filtersAcc });

              // now set to be a total query if we're using an average outsort
              // if we're not using avg, we need to use the original metrics so that the sums line up
              if (query.outsort.includes('avg')) {
                query.metric = [];
              } else {
                query.metric = origMetric;
              }

              // correct the filterField based on the direction of the query and add to existing filters
              filtersAcc.filterGroups.forEach((group, groupIdx) => {
                group.filterGroups.forEach((subGroup) => {
                  subGroup.filters.forEach((groupFilter) => {
                    if (query.filters && query.filters.filterGroups.length > 0) {
                      query.filters.filterGroups[groupIdx].filters.push({
                        ...groupFilter,
                        filterField: getFilterField(filter.name, groupFilter.filterField)
                      });
                    }
                  });
                });

                group.filters.forEach((groupFilter) => {
                  if (query.filters && query.filters.filterGroups.length > 0) {
                    query.filters.filterGroups[groupIdx].filters.push({
                      ...groupFilter,
                      filterField: getFilterField(filter.name, groupFilter.filterField)
                    });
                  }
                });
              });

              if (shouldAddDeviceColumn) {
                query.filters.filterGroups[0].filters.push({
                  filterField: 'i_device_name',
                  operator: '=',
                  filterValue: model.get('i_device_name')
                });
              }

              query.aggregateFiltersEnabled = false;
              query.aggregateFilters = [];
              query.topx = 8;
              query.depth = 100;

              if (query.aggregateTypes[0].includes('bits_per_sec')) {
                query.aggregateTypes = $dictionary.get('unitsLegacy').bytes;
              } else if (query.aggregateTypes[0].includes('pkts_per_sec')) {
                query.aggregateTypes = $dictionary.get('unitsLegacy').packets;
              } else if (query.aggregateTypes[0].includes('flows_per_sec')) {
                query.aggregateTypes = $dictionary.get('unitsLegacy').fps;
              }

              query.time_format = timezone.value;
              $explorer.navigateToExplorer(query);
            }}
          />
        )),
        ...additionalMenuItems
      ];
    }

    return null;
  }

  render() {
    const { $app } = this.props;
    const { isOpen } = this.state;

    if ($app.isExport) {
      return null;
    }

    return (
      <Popover isOpen={isOpen} onInteraction={this.handleInteraction} position="bottom-right" minimal>
        <Button active={isOpen} icon={FiMoreVertical} minimal small />
        <Menu>{this.renderMenuItems()}</Menu>
      </Popover>
    );
  }
}

export default ActionsMenu;
