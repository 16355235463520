import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';

import { formatDateTime } from 'core/util/dateUtils';
import { zeroToText } from 'app/util/utils';
import BaseHighchartsNonStyledDataview from 'app/components/dataviews/views/BaseHighchartsNonStyledDataview';
import { getHttpStages } from 'app/views/synthetics/utils/syntheticsUtils';

import withTrendChart from './withTrendChart';

@withTrendChart
@withTheme
@inject('$app', '$auth', '$dataviews')
@observer
class StackedColumnTimeSeries extends BaseHighchartsNonStyledDataview {
  constructor(props) {
    super(props);
    const { $auth, onHover, theme, isPageLoad } = this.props;
    const { colors, fonts } = theme;
    const { userTimezone } = $auth;

    this.state = {
      userTimezone,
      stickyPointer: true,
      highlightPointsByEvent: true,
      chartOptions: {
        chart: {
          type: 'column',
          style: { fontFamily: fonts.body },
          height: 200,
          spacingLeft: 0,
          spacingRight: 0,
          backgroundColor: colors.chart.tooltipBackground
        },
        colors: [
          '#8073ac', // LIGHT PURPLE
          '#8fcea0', // GREEN
          '#fdba7f', // ORANGE
          '#542788', // DARK PURPLE
          '#e9f4b0' // YELLOW
        ],
        title: { text: '' },
        credits: { enabled: false },
        legend: {
          enabled: true,
          // @TODO we'll enable this once we have aggregates to show under the chart title
          // align: 'right',
          // verticalAlign: 'top',
          itemStyle: {
            color: colors.chart.legendLabels
          },
          itemHoverStyle: {
            color: colors.chart.legendLabels
          },
          itemHiddenStyle: {
            color: colors.chart.legendHiddenLabels
          }
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
          lineColor: colors.chart.axisLine,
          gridLineColor: colors.chart.gridLine,
          tickColor: colors.chart.axisTick,
          labels: {
            style: {
              color: colors.muted
            },
            formatter() {
              if (this.dateTimeLabelFormat === '%b %e') {
                return `<strong>${formatDateTime(this.value, 'MMM Do')}</strong>`;
              }

              return this.axis.defaultLabelFormatter.call(this);
            }
          },
          dateTimeLabelFormats: {
            day: '%b %e',
            week: '%b %e'
          }
        },
        yAxis: {
          title: { text: '' },
          stackLabels: { enabled: false },
          gridLineColor: colors.chart.gridLine,
          labels: {
            align: 'left',
            x: 0,
            y: -2,
            style: {
              color: colors.muted
            }
          }
        },
        tooltip: {
          outside: true,
          useHTML: true,
          backgroundColor: colors.chart.tooltipBackground,
          valueSuffix: 'ms',
          shared: true,
          style: {
            color: colors.body,
            fontFamily: fonts.body
          },
          headerFormat: ''
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: { enabled: false },
            states: { select: { enabled: false } },
            point: {
              events: {
                mouseOver: (e) => {
                  if (onHover) {
                    onHover(e.target.options.x);
                  }
                }
              }
            },
            pointRange: 0,
            pointPadding: 0
          }
        },
        series: getHttpStages(isPageLoad)
          .map((stage) => ({ name: stage.name, data: [] }))
          .reverse()
      }
    };
  }

  get type() {
    return 'timeline';
  }

  redraw() {
    if (this.chart) {
      const { theme } = this.props;
      const { colors } = theme;

      this.setState({
        chartOptions: {
          chart: { backgroundColor: colors.chart.tooltipBackground },
          tooltip: {
            backgroundColor: colors.chart.tooltipBackground,
            style: { color: colors.body }
          },
          legend: {
            itemStyle: {
              color: colors.chart.legendLabels
            },
            itemHoverStyle: {
              color: colors.chart.legendLabels
            },
            itemHiddenStyle: {
              color: colors.chart.legendHiddenLabels
            }
          },
          xAxis: {
            lineColor: colors.chart.axisLine,
            gridLineColor: colors.chart.gridLine,
            tickColor: colors.chart.axisTick,
            labels: { style: { color: colors.muted } }
          },
          yAxis: {
            gridLineColor: colors.chart.gridLine,
            labels: { style: { color: colors.muted } }
          }
        }
      });
      this.renderData();
    }
  }

  renderData() {
    if (this.chart) {
      const { data, xAxisMin, xAxisMax, isPageLoad } = this.props;
      const series = getHttpStages(isPageLoad).map((stage) => ({ name: stage.name, key: stage.key, data: [] }));

      data.forEach((point) => {
        const { time } = point;
        let last = 0;
        series.forEach((item) => {
          if (point.data.length > 0) {
            const curr = point.data[0][item.key];
            const val = (curr - last) / 1000;
            item.data.push([time, Number(zeroToText(val, { fix: 3, useCommas: false }))]);
            last = curr;
          }
        });
      });

      this.setState({
        chartOptions: {
          chart: { spacingRight: 20 },
          yAxis: { min: 0, offset: 35 },
          xAxis: { min: xAxisMin, max: xAxisMax },
          series: series.reverse() // reverse so response time is at the top
        }
      });
    }
  }
}

export default StackedColumnTimeSeries;
