import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Box, Card, Flex, Grid, Link, Spinner, Suspense, Text } from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import DetailTabs from 'app/components/detailTabs/DetailTabs';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import AggregationSelector from 'app/components/AggregationSelector';
import MetricSelector from 'app/components/MetricSelector';
import TimeRangeSelector from 'app/components/TimeRangeSelector';

import PageHeading from 'app/components/page/PageHeading';
import InboundOutboundCard from './InboundOutboundCard';
import LandingSidebar from './LandingSidebar';

@storeLoader('$sites')
@inject('$app', '$devices', '$exports', '$query', '$tabs')
@withRouter
class NetworkExplorerTraffic extends Component {
  constructor(props) {
    super(props);

    const { location } = props;

    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      lookback_seconds: 86400,
      aggregateType: 'p95th_bits_per_sec',
      ...location.state
    };
  }

  componentDidMount() {
    const { $exports } = this.props;
    $exports.getSettings().then((results) => {
      const { lookback_seconds, aggregateType } = results;
      const updatedState = {};
      if (lookback_seconds) {
        updatedState.lookback_seconds = lookback_seconds;
      }
      if (aggregateType) {
        updatedState.aggregateType = aggregateType;
      }
      this.setState(updatedState);
    });
  }

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds }, this.updateRouteState);
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType }, this.updateRouteState);
  };

  updateRouteState = () => {
    const { $exports, history, location } = this.props;
    const { aggregateType, lookback_seconds } = this.state;

    $exports.setHash({ aggregateType, lookback_seconds });

    history.replace(location.pathname, {
      aggregateType,
      lookback_seconds
    });
  };

  render() {
    const { $app, $sites, $devices, $query, $tabs } = this.props;
    const { lookback_seconds, aggregateType } = this.state;

    const deviceTypes = $devices.activeDeviceSummaries.map((d) => d.device_subtype);
    const { APPLICATIONS, ASNS, COUNTRIES, DEVICES, INTERFACES, IPS } = $tabs.getTabs();

    const queryOverrides = {
      lookback_seconds,
      aggregateTypes: [aggregateType]
    };

    return (
      <Grid gridTemplateColumns="1fr minmax(225px, 300px)" gridGap={3}>
        <Box>
          <Flex flex={1}>
            <PageHeading title="Network Explorer" level={1} mb={0} />

            <Flex alignItems="center" justifyContent="flex-end" flex={1} mb={2}>
              <Box mr={1}>
                <AggregationSelector aggregateType={aggregateType} onChange={this.handleAggregationChange} />
              </Box>
              <Box mr={1}>
                <MetricSelector aggregateType={aggregateType} onChange={this.handleAggregationChange} />
              </Box>
              <TimeRangeSelector lookback_seconds={lookback_seconds} onChange={this.handleLookbackChange} />
            </Flex>
          </Flex>

          <Card mb={2} p={0} overflow="hidden">
            <TabbedChart overrides={queryOverrides} height={200} />
          </Card>

          <Flex justifyContent="space-between" alignItems="flex-end" py={1}>
            <Text as="div" color="muted" fontWeight="bold" fontSize={16}>
              Clouds and Sites
            </Text>
            <Link to="/v4/core/quick-views/sites" fontSize={14}>
              See All Sites
            </Link>
          </Flex>

          <Grid gridGap={2} gridRowGap={2} gridTemplateColumns="repeat(3,minmax(150px,1fr))" mb={3}>
            {['gcp_subnet', 'azure_subnet', 'aws_subnet'].map((cloudType) => {
              if (!deviceTypes.includes(cloudType)) {
                return null;
              }

              return <InboundOutboundCard key={cloudType} cloud={cloudType} queryOverrides={queryOverrides} />;
            })}

            <LightweightDataViewWrapper query={$query.get('topSitesQuery', queryOverrides)}>
              {({ loading: topSitesLoading, results }) => {
                const raw = results.getRawData().filter((series) => series.key !== 'Unassigned');
                const topSites = raw.slice(0, 6).map((series) => series.key);

                return (
                  <Suspense
                    loading={topSitesLoading}
                    fallback={
                      <Flex justifyContent="flex-start" p={2}>
                        <Spinner size={36} />
                      </Flex>
                    }
                  >
                    {$sites.collection
                      .filter((site) => topSites.includes(site.get('title')), { immutable: true })
                      .map((site) => (
                        <InboundOutboundCard key={site.id} site={site} queryOverrides={queryOverrides} />
                      ))}
                  </Suspense>
                );
              }}
            </LightweightDataViewWrapper>
          </Grid>

          <Box>
            <DetailTabs
              tabPrefsId="landing-page"
              queryOverrides={queryOverrides}
              defaultTabs={[APPLICATIONS, DEVICES, INTERFACES, ASNS, IPS, COUNTRIES]}
            />
          </Box>
        </Box>

        {!$app.isExport && <LandingSidebar />}
      </Grid>
    );
  }
}

export default NetworkExplorerTraffic;
