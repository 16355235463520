import React, { Component } from 'react';
import MetricsResult from './MetricsResult';

export default class MetricsExplorerResult extends Component {
  get queryReactKey() {
    const { query } = this.props;
    const { viz, ...rest } = query.kmetrics;
    return JSON.stringify(rest);
  }

  render() {
    return <MetricsResult {...this.props} key={this.queryReactKey} />;
  }
}
