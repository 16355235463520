import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Menu, MenuItem } from 'core/components';
import { RemoveMenuItem } from 'core/form';
import { FiShare2 } from 'react-icons/fi';
import { ReactComponent as EnvelopeRemove } from 'app/assets/icons/envelope-remove.svg';

@inject('$auth', '$capacity', '$subscriptions')
@withRouter
@observer
export default class CapacityPlanMenu extends Component {
  static defaultProps = {
    path: '/api/ui/export/exportPage'
  };

  handleClickEdit = () => {
    const { history, plan } = this.props;
    history.push(`/v4/core/capacity/${plan.id}/edit`);
  };

  handlePdfExport = (plan) => {
    const { $capacity } = this.props;
    $capacity.exportPlanPdf(plan);
  };

  handleCsvExport = (plan) => {
    const { $capacity } = this.props;
    $capacity.exportPlanCsv(plan);
  };

  render() {
    const { plan, toggleShareDialog, setSubscriptionStatus, isSubscribed } = this.props;

    if (isSubscribed === undefined) {
      setSubscriptionStatus();
    }

    return (
      <Menu>
        <MenuItem icon="edit" text="Edit Plan" onClick={this.handleClickEdit} />
        <MenuItem icon="export" text="Export" popoverProps={{ openOnTargetFocus: false }}>
          <MenuItem
            icon="document"
            text="Visual Report"
            label=".pdf"
            onClick={() => this.handlePdfExport(plan)}
            className="export_pdf"
          />
          <MenuItem
            icon="th"
            text="Data Table"
            label=".csv"
            onClick={() => this.handleCsvExport(plan)}
            className="export_csv"
          />
        </MenuItem>
        <MenuItem icon="envelope" text="Subscribe" onClick={() => toggleShareDialog('subscribe')} />
        {isSubscribed && (
          <MenuItem icon={EnvelopeRemove} text="Unsubscribe" onClick={() => toggleShareDialog('unsubscribe')} />
        )}
        <MenuItem icon={FiShare2} text="Share" onClick={() => toggleShareDialog('share')} />

        <RemoveMenuItem
          text="Remove"
          model={plan}
          entityName="Plan"
          confirmText={`Are you sure you want to remove "${plan.get('name')}"?`}
        />
      </Menu>
    );
  }
}
