import React from 'react';
import { observer } from 'mobx-react';
import { Intent } from '@blueprintjs/core';
import Tooltip from 'core/components/Tooltip';

const getFormErrorsTooltipProps = ({ form, errorRenderer }) => {
  const { valid, errors } = form;

  let content;
  const displayErrors = errorRenderer ? errorRenderer(errors) : errors;

  if (!valid) {
    content = (
      <ul style={{ marginBlockStart: 4, marginBlockEnd: 4, paddingInlineStart: 20, maxWidth: 600 }}>
        {displayErrors.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    );
  }

  return {
    content,
    intent: Intent.DANGER
  };
};

const FormErrorsButton = ({ form, button, errorRenderer, ...extraTooltipProps }) => {
  const tooltipProps = { ...getFormErrorsTooltipProps({ form, errorRenderer }), ...extraTooltipProps };

  if (tooltipProps.content) {
    return <Tooltip {...tooltipProps}>{button}</Tooltip>;
  }

  return button;
};

export default observer(FormErrorsButton);
