import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled, { withTheme, css } from 'styled-components';
import { MdTrendingUp, MdTrendingDown } from 'react-icons/md';
import { Text, Tag, Grid, Flex, Box, FromNowDate } from 'core/components';
import ASNRenderer from 'app/components/asn/ASNRenderer';

const InsightRow = styled.div`
  display: grid;
  grid: 25px 1fr / 1fr;
  column-gap: 0px;
  row-gap: 8px;

  place-content: stretch start;
  margin: 8px 16px 8px 16px;
`;

const SeverityBar = styled.div`
  height: 100%;
  width: 5px;
  border-radius: 2.5px;
  ${({ theme }) => `background-color: ${theme.colors.primary} `};

  ${(props) =>
    props.disabled &&
    css`
      ${({ theme }) => `background-color: ${theme.colors.gray5} `};
    `}
`;

const severityIndicator = (sev) => (
  <Grid gridTemplateColumns="1fr 1fr 1fr 1fr 1fr" gridTemplateRows="18px" gridGap="2px">
    <SeverityBar disabled={false} mr="4px" />
    <SeverityBar disabled={sev < 2} mr="4px" />
    <SeverityBar disabled={sev < 3} mr="4px" />
    <SeverityBar disabled={sev < 4} mr="4px" />
    <SeverityBar disabled={sev < 5} />
  </Grid>
);

@inject('$auth')
@withTheme
@observer
export default class InsightRowRenderer extends Component {
  render() {
    const { insightData, $auth } = this.props;
    const { creationTime, dimensionToValue, insightName, severity, descriptionTemplate, insightID, metricToValue } =
      insightData;
    const { ins_type } = metricToValue;
    const { asnArr, nameArr } = dimensionToValue;

    if (asnArr[0] === asnArr[1] || asnArr[1] === '0') {
      asnArr.pop();
    }

    return (
      <Box flex={1}>
        <InsightRow>
          <Flex className="insTag" alignItems="center">
            {severityIndicator(severity)}
            <Tag
              round
              outline
              intent={ins_type % 2 ? 'success' : 'danger'}
              icon={ins_type % 2 ? MdTrendingUp : MdTrendingDown}
              ml={2}
            >
              {insightName}
            </Tag>
            <Box flex={1} />
            <Text as="div" small muted>
              <FromNowDate timeStamp={creationTime} userTimeZone={$auth.userTimezone} />
            </Text>
          </Flex>

          <div className="insDesc">
            {asnArr.map((asn, idx) => {
              const description = nameArr[idx];
              return (
                <span key={`${insightID}-${asn}`} display="inline-block">
                  <ASNRenderer asn={asn} description={description} showLinks pr="4px" />
                  <Text>{` ${descriptionTemplate[idx]} `}</Text>
                </span>
              );
            })}
          </div>
        </InsightRow>
      </Box>
    );
  }
}
