import moment from 'moment';
import { groupBy, uniq, isEqual } from 'lodash';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled, { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';

import { FiCopy, FiCheckCircle, FiXCircle, FiCloud } from 'react-icons/fi';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import LoadingSkeleton from 'app/views/metrics/LoadingSkeleton';

import Page from 'app/components/page/Page';
import { cloudProviderData } from 'app/util/utils';
import { getQueriesForHash } from 'app/stores/query/urlHash';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import LargeLabeledValue from 'app/components/LargeLabeledValue';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import CloudItem from 'app/views/hybrid/maps/components/CloudItem';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';
import { getConsoleUrl } from 'app/views/hybrid/utils/aws';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import CloudLinkWithIcon from 'app/views/cloudConnectivity/CloudLinkWithIcon';
import withPopover from 'app/views/hybrid/maps/components/popovers/withPopover';
import { getCustomProperties } from 'shared/util/map';
import {
  Box,
  Flex,
  Card,
  Icon,
  Text,
  Suspense,
  Tooltip,
  Heading,
  Button,
  LinkButton,
  CalloutOutline,
  Dismissible
} from 'core/components';

import Markdown from 'app/views/core/journeys/Markdown';
import CalloutKentikAi from 'core/components/CalloutKentikAI';
import { convertToReadableId, convertToReadableType } from './utils';

const ICON_SIZE = 16;
const { VPC } = ENTITY_TYPES.get('aws');
const { VNET } = ENTITY_TYPES.get('azure');

const GLOBAL_REGION = 'Global';

const SummaryWrapper = styled.div`
  h5 {
    margin: 24px 0 8px 0;
  }

  h5:first-child {
    margin-top: 0;
  }
`;

const PageWrapper = ({ usePage, children, report }) => {
  const title = `Pathfinder Report: ${report?.get('name') ?? ''}`;
  const cloudProviderLabel = report?.get('cloud') === 'aws' ? 'AWS' : 'Azure';
  return usePage ? (
    <Page
      title={title}
      parentLinks={[
        { link: '/v4/cloud/pathfinder', label: 'Cloud Pathfinder' },
        {
          link: `/v4/cloud/pathfinder/${report?.get('cloud') ?? 'aws'}`,
          label: `${report?.get('name') ? cloudProviderLabel : ''} Pathfinder Reports`
        }
      ]}
      gray
      pageProps={{
        overflow: 'auto'
      }}
    >
      {children}
    </Page>
  ) : (
    children
  );
};

@withPopover
@withTheme
@withRouter
@inject('$clouds', '$auth')
@observer
export default class ConnectivityReport extends Component {
  static defaultProps = {
    loadingText: 'Your Cloud Pathfinder Report is on its way, thanks for your patience...'
  };

  // TODO - rename all "Connectivity Checker" components to reflect new ***marketing-approved*** name "Cloud Pathfinder"
  state = {
    paths: [],
    loading: true,
    returnPaths: [],
    error: undefined,
    report: undefined,
    srcEntity: undefined,
    dstEntity: undefined
  };

  get cloud() {
    const { report } = this.state;
    return report?.get('cloud') ?? 'aws';
  }

  async convertQueryParamsToReport() {
    const { match, $clouds, reportObject } = this.props;
    const { reportIdOrHash } = match.params;

    // Accept report object as prop, for embedding component
    if (reportObject) {
      return $clouds.connectivityReportCollection.forge(reportObject);
    }

    // if reportIdOrHash is a number, it's a report id
    if (+reportIdOrHash) {
      // try to get it from collection first, it will be loaded if page was opened from main report page
      const collectionModel = $clouds.connectivityReportCollection.get(+reportIdOrHash);
      if (collectionModel) {
        return collectionModel;
      }

      // if direct link, initialize the model from database
      const reportModel = $clouds.connectivityReportCollection.forge({ id: +reportIdOrHash });
      return reportModel.fetch().then(() => reportModel);
    }

    // url is opened by hash, decode the hash and initialize report model
    return getQueriesForHash(reportIdOrHash).then((queries) => {
      if (!Array.isArray(queries)) {
        return null;
      }

      if (queries.length === 0) {
        return null;
      }

      return $clouds.connectivityReportCollection.forge({ ...queries[0] });
    });
  }

  componentDidMount() {
    let originalReportResults;
    this.convertQueryParamsToReport()
      .then((reportModel) => {
        originalReportResults = reportModel.get('results');
        return this.fetchReportDetails(reportModel);
      })
      .then((report) => {
        // save report to db to sync status
        if (!report.isNew && !isEqual(originalReportResults, report.get('results'))) {
          report.save();
        }
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { reportIdOrHash } = match.params;

    if (reportIdOrHash !== prevProps.match.params.reportIdOrHash) {
      this.componentDidMount();
    }
  }

  async fetchReportDetails(report) {
    const { $clouds } = this.props;

    if (!report) {
      this.setState({ loading: false, error: 'Unable to initialize report.' });
      return report;
    }

    return $clouds.buildConnectivityReport(report).then((connectivityReport) => {
      const { paths, returnPaths, error, results, srcEntity, dstEntity } = connectivityReport;
      report.set('results', results);
      this.setState({ report, paths, returnPaths, error, srcEntity, dstEntity, loading: false });

      return report;
    });
  }

  renderCloudItem = (segment) => {
    const { openPopover } = this.props;

    const hasErrors = segment.errors?.length > 0;
    const { type, value, region, entity } = segment;

    const title = convertToReadableId(
      segment.entity?.Name ?? segment.entity?.id ?? segment.entity?.CidrBlock ?? segment.value,
      this.cloud
    );

    const subtitle = this.getSegmentIpAddress(segment) ?? [];

    const popoverMenuItems = [
      {
        key: `${this.cloud} Map`,
        text: `${this.cloud === 'aws' ? 'AWS' : 'Azure'} Map`,
        icon: cloudProviderData(this.cloud, 'logo'),
        action: () => window.open(`/v4/kentik-map/cloud/${this.cloud}`, '_blank')
      }
    ];

    if (this.cloud === 'aws') {
      popoverMenuItems.push({
        key: 'AWS Console',
        text: 'Show in AWS Console',
        icon: 'panel-stats',
        action: () => {
          const url = getConsoleUrl({
            type,
            value,
            nodeData: {
              ...entity,
              RegionName: region
            }
          });
          window.open(url, '_blank');
        }
      });

      (segment?.errors ?? []).forEach((error) => {
        (error?.nodes ?? []).forEach((nodeId) => {
          popoverMenuItems.push({
            key: nodeId,
            icon: 'offline',
            bg: 'warningBackground',
            text: `Resolve ${nodeId} in AWS Console`,
            action: () => {
              const url = getConsoleUrl({
                type: '',
                value: nodeId,
                nodeData: {
                  RegionName: region
                }
              });
              window.open(url, '_blank');
            }
          });
        });
      });
    }

    return (
      <Tooltip
        intent="danger"
        content={
          hasErrors ? (
            <Box>
              {segment.errors?.map((error) => (
                <Text key={error.message} as="div">
                  {error.message}
                </Text>
              ))}
            </Box>
          ) : null
        }
      >
        <Box>
          <svg width={200} height={35}>
            <CloudItem
              width={200}
              height={35}
              stroke={hasErrors ? '#db3737' : '#3d7fb2'}
              title={title}
              subtitle={subtitle}
              icon={
                <CloudIcon
                  cloudProvider={this.cloud}
                  entity={segment.type}
                  width={ICON_SIZE + 5}
                  height={ICON_SIZE + 5}
                />
              }
              onClick={() =>
                openPopover({
                  isEmbedded: true,
                  shortcutMenu: {
                    hideShowConnectionsMenuItem: true,
                    customItems: popoverMenuItems
                  }
                })
              }
            />
          </svg>
        </Box>
      </Tooltip>
    );
  };

  renderRightArrow = (size = 32) => (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <Icon icon="arrow-right" color="muted" iconSize={size} />
    </Flex>
  );

  renderTextCopyButton = (text) => (
    <CopyToClipboardButton text={text} copyConfirmationText={null} intent="none" ml="4px" minimal small>
      <Button icon={FiCopy} />
    </CopyToClipboardButton>
  );

  getPathNetworkName = (pathSegment) => {
    const { entity, type } = pathSegment;

    if (this.cloud === 'aws') {
      if (type === VPC) {
        return entity.Name ?? null;
      }

      return entity?.Vpc?.Name ?? null;
    }

    if (this.cloud === 'azure') {
      return convertToReadableId(getCustomProperties(entity)?.vnetId ?? '', 'azure');
    }

    return null;
  };

  getPathSegmentNetworkId = (pathSegment) => {
    const { entity, type } = pathSegment;

    if (this.cloud === 'aws') {
      if (type === VPC) {
        return entity.id;
      }

      return entity?.VpcId ?? entity?.Vpc?.id ?? null;
    }

    if (this.cloud === 'azure') {
      return convertToReadableId(getCustomProperties(entity)?.vnetId ?? '', 'azure');
    }

    return null;
  };

  getEntityIpAddress = (entity, isNetwork = false) => {
    if (this.cloud === 'aws') {
      if (isNetwork) {
        // most likely entity is VPC or Subnet
        return entity?.Vpc?.CidrBlock ?? entity?.CidrBlock;
      }
      return entity?.PrivateIpAddress ?? entity?.CidrBlock ?? entity?.Vpc?.CidrBlock;
    }

    if (this.cloud === 'azure') {
      if (isNetwork) {
        return entity?.vnet?.properties?.addressSpace?.addressPrefixes;
      }
      return entity?.properties?.addressPrefix;
    }

    return null;
  };

  getSegmentIpAddress = (pathSegment, isNetwork = false) => {
    const { entity } = pathSegment;

    return this.getEntityIpAddress(entity, isNetwork);
  };

  renderPathCard = (path, isReversePath) => {
    const { report } = this.state;
    const key = path.map((segment) => segment.value).join('-');
    const regionMap = groupBy(
      path.filter((segment) => segment.region ?? GLOBAL_REGION),
      'region'
    );

    const networkMap = groupBy(
      path.filter((segment) => this.getPathSegmentNetworkId(segment)),
      (segment) => this.getPathSegmentNetworkId(segment)
    );

    const pathHasErrors = path.some((segment) => segment.errors?.length);
    const isPathReachable = isReversePath ? report.get('results.reachable') : report.get('results.returnReachable');
    const renderedIds = [];
    return (
      <Card key={key} mb={2} p={2} style={{ width: 'fit-content' }}>
        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap={1} fontSize="12px">
            <Icon
              icon={pathHasErrors || !isPathReachable ? FiXCircle : FiCheckCircle}
              color={pathHasErrors || !isPathReachable ? 'danger' : 'success'}
            />
            {path.map((segment, idx) => {
              const readableSegmentId = convertToReadableId(segment.value, this.cloud);
              const readableSegmentType = convertToReadableType(segment.type);
              return (
                <Flex key={segment.value} alignItems="center">
                  <Flex flexDirection="column" px={1}>
                    <Flex gap={1} width="100%" justifyContent="start">
                      <CloudIcon
                        entity={segment.type}
                        cloudProvider={this.cloud}
                        width={ICON_SIZE}
                        height={ICON_SIZE}
                      />
                      <Text as="b">{readableSegmentType}</Text>
                    </Flex>
                    <Text as="div" whiteSpace="nowrap" textOverflow="ellipsis">
                      {readableSegmentId}
                      {this.renderTextCopyButton(readableSegmentId)}
                    </Text>
                  </Flex>
                  {segment.errors?.length > 0 && (
                    <Tooltip
                      intent="danger"
                      content={
                        <Box>
                          {segment.errors?.map((error) => (
                            <Text key={`${readableSegmentId}-${error.message}`} as="div">
                              {error.message}
                            </Text>
                          ))}
                        </Box>
                      }
                    >
                      <Flex gap={1}>
                        {uniq(segment.errors, (error) => error.message).map((error) => {
                          const errorUrl = getConsoleUrl({
                            type: '',
                            value: error.nodes?.[0] ?? '',
                            nodeData: {
                              RegionName: segment.region ?? GLOBAL_REGION
                            }
                          });
                          return (
                            <LinkButton key={errorUrl} intent="danger" to={errorUrl} minimal blank ml="4px">
                              <Icon icon={BsFillExclamationTriangleFill} color="danger" />/{' '}
                            </LinkButton>
                          );
                        })}
                      </Flex>
                    </Tooltip>
                  )}
                  {idx < path.length - 1 && this.renderRightArrow(16)}
                </Flex>
              );
            })}
          </Flex>
        </Flex>

        <Flex gap={1} width="100%" justifyContent="space-between">
          {Object.keys(regionMap).map((region, regionKey) => (
            <Box
              bg="subnavBackground"
              p={2}
              my={2}
              key={region ?? GLOBAL_REGION}
              flex={regionKey > 0 && regionKey < Object.keys(regionMap).length - 1 ? 2 : 1}
            >
              <Text mb={2} small as="div" fontWeight="bold">
                {region ?? GLOBAL_REGION}
              </Text>
              <Flex justifyContent="space-evenly">
                {regionMap[region].map((segment, idx) => {
                  const readableSegmentId = convertToReadableId(segment.value, this.cloud);
                  const readableSegmentType = convertToReadableType(segment.type);
                  if (renderedIds.includes(segment.value)) {
                    return null;
                  }

                  const networkId = this.getPathSegmentNetworkId(segment);
                  const networkName = this.getPathNetworkName(segment);
                  const hasNetworkWrapper = !!networkId;
                  const hasErrors = segment.errors?.length > 0;

                  if (hasNetworkWrapper) {
                    const segmentsInNetwork = networkMap[networkId].filter(({ type }) => type !== VPC);
                    const NetworkBox = (
                      <Flex border="thinDotted" flexDirection="column" p={2} key={segment.value}>
                        <Box mb={1}>
                          <Flex justifyContent="center" gap={1}>
                            <CloudIcon
                              entity={this.cloud === 'aws' ? 'vpc' : VNET}
                              cloudProvider={this.cloud}
                              width={24}
                              height={24}
                            />
                            <Box>
                              <Text as="div">
                                {networkName ? `${networkName} (${networkId})` : networkId}
                                {this.renderTextCopyButton(networkId)}
                              </Text>
                              <Text as="div" muted small>
                                {this.getSegmentIpAddress(segment, true)}
                              </Text>
                            </Box>
                          </Flex>
                        </Box>

                        <Flex flex={1} justifyContent="center" gap={1} alignItems="center">
                          {segmentsInNetwork.map((segmentInNetwork, index) => {
                            renderedIds.push(segmentInNetwork.value);
                            return (
                              <React.Fragment key={segmentInNetwork.value}>
                                {hasErrors && (
                                  <Tooltip
                                    intent="danger"
                                    content={
                                      <Box>
                                        {segmentInNetwork.errors?.map((error) => (
                                          <Text key={`${segmentInNetwork.value}-${error.message}`} as="div">
                                            {error.message}
                                          </Text>
                                        ))}
                                      </Box>
                                    }
                                  >
                                    {this.renderCloudItem(segmentInNetwork)}
                                  </Tooltip>
                                )}
                                {!hasErrors && this.renderCloudItem(segmentInNetwork)}
                                {index !== segmentsInNetwork.length - 1 && this.renderRightArrow(16)}
                              </React.Fragment>
                            );
                          })}
                        </Flex>
                      </Flex>
                    );

                    if (idx > 0) {
                      return (
                        <React.Fragment key={readableSegmentId}>
                          {/* we check against 2nd item rendered because we add ids upfront rendering */}
                          {renderedIds.length > 2 && Object.keys(networkMap).length > 1 && this.renderRightArrow()}
                          <Flex>{NetworkBox}</Flex>
                        </React.Fragment>
                      );
                    }
                    return NetworkBox;
                  }

                  // if entity does not exist in topology
                  if (!segment.entity) {
                    return (
                      <Flex gap={3} key={readableSegmentId}>
                        <Flex flexDirection="column" justifyContent="center" alignItems="center" gap="1">
                          <Text as="div" fontWeight="bold">
                            {readableSegmentType}
                          </Text>
                          {this.renderCloudItem(segment)}
                          <CalloutOutline intent="danger">Entity does not exist in topology.</CalloutOutline>
                        </Flex>
                      </Flex>
                    );
                  }

                  if (
                    idx !== regionMap[region].length - 1 &&
                    this.getPathSegmentNetworkId(regionMap[region][idx + 1].entity) === readableSegmentId
                  ) {
                    return null;
                  }

                  // transit gateway, NAT gateway, vpc peering and etc.. render
                  return (
                    <React.Fragment key={readableSegmentId}>
                      {this.renderRightArrow()}
                      <Flex flexDirection="column" justifyContent="center" alignItems="center" gap="1">
                        <Text as="div" fontWeight="bold">
                          {readableSegmentType}
                        </Text>
                        {this.renderCloudItem(segment)}
                      </Flex>
                      {idx === regionMap[region].length - 1 &&
                        // do not show error when there is missing VNC
                        regionKey < Object.keys(regionMap).length - 1 &&
                        this.renderRightArrow()}
                    </React.Fragment>
                  );
                })}
                {['InternetGateways', 'NatGateways'].includes(regionMap[region][regionMap[region].length - 1].type) && (
                  <React.Fragment>
                    {this.renderRightArrow()}
                    <Flex flexDirection="column" justifyContent="center" alignItems="center" ml={2}>
                      <Icon icon={FiCloud} iconSize={64} />
                      <Text as="div" textAlign="center">
                        Internet
                      </Text>
                    </Flex>
                  </React.Fragment>
                )}
              </Flex>
            </Box>
          ))}
        </Flex>
      </Card>
    );
  };

  typeToDetail(type) {
    if (type === 'Subnets') {
      return 'subnet_id';
    }

    if (type === 'vpc') {
      return 'vpc_id';
    }

    if (type === 'Instance') {
      return 'vm_id';
    }

    if (type === 'NetworkInterfaces') {
      return 'eni';
    }

    return type;
  }

  saveReport() {
    const { report } = this.state;
    const { history } = this.props;

    if (report.isNew) {
      this.setState({ loading: true });

      report.save().then(() => {
        history.replace(`/v4/cloud/pathfinder/${this.cloud}/${report.get('id')}`);
      });
    }
  }

  render() {
    const { $auth, theme, reportObject, loadingText } = this.props;
    const { report, paths, returnPaths, loading, error, srcEntity, dstEntity } = this.state;

    return (
      <PageWrapper report={report} usePage={!reportObject}>
        <Suspense
          loading={loading}
          fallback={
            <Flex flexDirection="column" alignItems="center" justifyContent="center">
              <LoadingSkeleton height={300} />
              <Text muted as="div" ml={1}>
                {loadingText}
              </Text>
            </Flex>
          }
        >
          {report && (
            <>
              <Flex mb={2} justifyContent="space-between">
                <Flex gap={4}>
                  <LargeLabeledValue
                    label={`Source ${report.get('src_type')}`}
                    valueElement={
                      <CloudLinkWithIcon
                        cloud={this.cloud}
                        type={report.get('src_type')}
                        detail={this.typeToDetail(report.get('src_type'))}
                        value={convertToReadableId(report.get('src'), this.cloud)}
                        ipAddress={this.getEntityIpAddress(srcEntity)}
                      />
                    }
                  />
                  <LargeLabeledValue
                    label={`Destination ${report.get('dst_type')}`}
                    valueElement={
                      <CloudLinkWithIcon
                        cloud={this.cloud}
                        type={report.get('dst_type')}
                        detail={this.typeToDetail(report.get('dst_type'))}
                        value={convertToReadableId(report.get('dst'), this.cloud)}
                        ipAddress={this.getEntityIpAddress(dstEntity)}
                      />
                    }
                  />
                  <LargeLabeledValue label="Port" valueElement={report.get('dst_port')} />
                  <LargeLabeledValue label="Protocol" valueElement={report.get('protocol')} />
                  <LargeLabeledValue
                    label="Last Run"
                    valueElement={moment(report.get('edate')).format(DEFAULT_DATETIME_FORMAT)}
                  />
                  <LargeLabeledValue
                    label="Start Time"
                    valueElement={moment(report.get('start_time')).format(DEFAULT_DATETIME_FORMAT)}
                  />
                  <LargeLabeledValue
                    label="End Time"
                    valueElement={moment(report.get('end_time')).format(DEFAULT_DATETIME_FORMAT)}
                  />
                </Flex>
                <Box>
                  {report.isNew && !reportObject && (
                    <Button icon="floppy-disk" intent="warning" onClick={() => this.saveReport()}>
                      Save Report
                    </Button>
                  )}
                </Box>
              </Flex>
            </>
          )}
          {error && (
            <Box bg={theme.colors.dangerBackground} textAlign="center">
              <Text as="h3">Error: {error}</Text>
            </Box>
          )}

          {!error && report && (
            <>
              <Flex gap={4}>
                {$auth.hasPermission('journeys', { overrideForSudo: false }) && (
                  <Dismissible name={`con-checker-${report.get('id')}`} noPersist>
                    {({ onDismiss }) => (
                      <CalloutKentikAi minWidth={350} width={350} className="bp4-running-text">
                        <SummaryWrapper>
                          <Markdown>{report.get('results.summary')}</Markdown>
                        </SummaryWrapper>
                        <Button small onClick={onDismiss} text="Close Summary" />
                      </CalloutKentikAi>
                    )}
                  </Dismissible>
                )}

                <Flex flexDirection="column">
                  <Heading level={5}>Forward Paths</Heading>
                  <Box>{paths.map((path) => this.renderPathCard(path))}</Box>

                  <Heading level={5}>Return Paths</Heading>
                  <Box>{returnPaths.map((path) => this.renderPathCard(path, true))}</Box>
                </Flex>
              </Flex>
            </>
          )}
        </Suspense>
      </PageWrapper>
    );
  }
}
