import { css } from 'styled-components';
import { style } from 'styled-system';

const borderLeftColor = style({
  prop: 'borderLeftColor',
  cssProperty: 'borderLeftColor',
  key: 'colors'
});

const borderRightColor = style({
  prop: 'borderRightColor',
  cssProperty: 'borderRightColor',
  key: 'colors'
});

const borderTopColor = style({
  prop: 'borderTopColor',
  cssProperty: 'borderTopColor',
  key: 'colors'
});

const borderBottomColor = style({
  prop: 'borderBottomColor',
  cssProperty: 'borderBottomColor',
  key: 'colors'
});

const bordersHelper = css`
  ${borderLeftColor};
  ${borderRightColor};
  ${borderTopColor};
  ${borderBottomColor};
`;

export default bordersHelper;
