import styled from 'styled-components';
import { themeGet } from 'styled-system';

const Text = styled.text`
  fill: ${({ fill }) => themeGet(`colors.${fill}`, fill)};
  stroke: ${({ stroke }) => themeGet(`colors.${stroke}`, stroke)};
  font-weight: ${({ fontWeight }) => themeGet(`fontWeights.${fontWeight}`, fontWeight)};

  a {
    fill: ${themeGet('colors.primary')};
  }
`;

Text.defaultProps = {
  fill: 'body'
};

export default Text;
