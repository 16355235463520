import React from 'react';
import { PopoverInteractionKind } from '@blueprintjs/core';
import { Box, Text, Icon, Popover } from 'core/components';

import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import Flex from 'core/components/Flex';

const STATUS_MAP = {
  success: { icon: 'tick-circle', intent: 'success' },
  warning: { icon: 'error', color: 'warning' },
  error: { icon: 'ban-circle', intent: 'danger' },
  critical: { icon: 'error', color: 'danger' }
};

const CopyBox = styled(Flex)`
  overflow: visible;

  .copy-icon {
    visibility: visible;
  }

  &:hover {
    .copy-icon {
      visibility: visible;
    }
  }
`;

const renderStyledCopyClickText = () => (
  <p
    style={{
      fontWeight: '100',
      fontSize: '0.85em',
      fontStyle: 'italic',
      textAlign: 'center',
      fontFamily: 'Roboto Flex',
      color: '#5c7080'
    }}
  >
    (click icon to copy)
  </p>
);

const renderPopover = ({ value, message, messageProps, allowCopy, hasInlineCopyText }) => {
  let statusIcon;

  if (value.toLowerCase() === 'none') {
    statusIcon = <Text>---</Text>;
  } else {
    const statusIconProps = STATUS_MAP[value.toLowerCase()] ?? { icon: 'help' };
    if (hasInlineCopyText && !message) {
      // if statusIcon has CopyText component inline (e.g. for errors),
      // we need to offset 'success' icons to keep vertical alignment in table
      statusIconProps.style = { marginRight: '20px' };
    } else {
      statusIconProps.style = undefined;
    }
    statusIcon = <Icon {...statusIconProps} />;
  }

  if (message) {
    return (
      <Popover
        interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
        position="right"
        minimal={false}
        usePortal
        modifiers={{
          offset: { enabled: false },
          flip: { enabled: false },
          preventOverflow: { enabled: true, boundariesElement: 'window' }
        }}
        content={
          <Box maxWidth={350}>
            <Text as="div" fontWeight="bold" p={1} style={{ overflowWrap: 'break-word' }}>
              {message}
            </Text>
            {allowCopy && renderStyledCopyClickText()}
          </Box>
        }
        target={statusIcon}
        {...messageProps}
      />
    );
  }

  return statusIcon;
};

function AccountStatusIcon(props) {
  const { value, message, allowCopy } = props;
  if (!value) {
    return null;
  }

  if (allowCopy) {
    return (
      <CopyBox alignItems="center" justifyContent="flex-end" onClick={() => copy(message)}>
        {renderPopover(props)}
      </CopyBox>
    );
  }
  return renderPopover(props);
}

export default AccountStatusIcon;
