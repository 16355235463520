import React from 'react';
import moment from 'moment';

import { Text } from 'core/components';
import { getQueryTimeInterval, secondsIntervalToText } from 'core/util/dateUtils';

export default function MetricsExplorerDateRange({ query }) {
  if (!query) {
    return null;
  }

  const { end, start, lookback } = query.kmetrics.range;

  const duration = moment.duration(lookback);
  const lookback_seconds = duration.asSeconds();

  const secondsInterval = getQueryTimeInterval({ lookback_seconds, starting_time: start, ending_time: end });
  const intervalText = secondsIntervalToText(secondsInterval, true);

  if (lookback) {
    const interval = `Last ${intervalText}`;

    return (
      <Text small muted>
        {interval}
      </Text>
    );
  }

  const isTimeZoneLocal = query?.kmetrics?.time_format === 'Local';
  const startTime = (isTimeZoneLocal ? moment.utc(start).local() : moment.utc(start)).format('MM/DD/YYYY HH:mm');
  const endTime = (isTimeZoneLocal ? moment.utc(end).local() : moment.utc(end)).format('MM/DD/YYYY HH:mm');

  return (
    <Text small muted>
      {startTime} to {endTime} ({intervalText})
    </Text>
  );
}
