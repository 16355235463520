import React, { Component } from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { debounce } from 'lodash';

import { Box, Button, Callout, Dialog, Flex, Icon, Spinner } from 'core/components';
import { Field, Form, InputGroup, Select } from 'core/form';
import { showErrorToast } from 'core/components/toast';
import { getReasonOptions } from 'app/util/sudo/getReasonOptions';

const fields = {
  company: {
    label: 'Company'
  },
  target_user_id: {
    label: 'User'
  },
  token: {
    label: 'Please enter your TOTP code'
  },
  redirect: {
    label: 'Redirect URL'
    // Going to stay on current page by default
    // defaultValue: '/v4'
  }
};

const reasonFields = {
  reason: {
    label: 'Reason',
    rules: 'required',
    type: 'select',
    placeholder: 'Select a Reason',
    options: getReasonOptions
  },
  additional_info: {
    label: 'Additional Information',
    placeholder: 'Additional information about SUDO reason'
  }
};

const options = {
  name: 'Sudo'
};

@Form({ fields, options })
@inject('$app', '$sudo', '$auth')
@observer
class SudoDialog extends Component {
  state = {
    isMakingRequest: false,
    isClearing: false,
    isFetchingUsers: false,
    isOpen: false,
    companyUserOptions: []
  };

  componentDidMount() {
    this.isOpenDisposer = reaction(
      () => {
        const { $sudo } = this.props;
        return $sudo.sudoOpen;
      },
      (isOpen) => {
        if (isOpen) {
          const { isDialogOpened } = this.state;
          if (isDialogOpened) {
            this.setState({ isOpen: true });
          } else {
            setTimeout(() => {
              this.setState({ isOpen: true });
            }, 200);
          }
        }
      }
    );

    this.updateFormFields();
  }

  componentDidUpdate() {
    const { $sudo } = this.props;

    if ($sudo.selectedCompanyId !== null) {
      this.handleCompanyChange(null, $sudo.selectedCompanyId);
      $sudo.clearSelectedCompanyId();
    }

    this.updateFormFields();
  }

  componentWillUnmount() {
    if (this.isOpenDisposer) {
      this.isOpenDisposer();
    }
  }

  shouldShowReason = () => {
    const { form, $sudo } = this.props;
    return $sudo.isSpoofReasonEnabled(form.getValue('company'));
  };

  updateFormFields = () => {
    const { form, $auth } = this.props;
    const hasReasonField = form.hasField('reason');

    if (this.shouldShowReason()) {
      // prevent redundant adds when component updates
      if (!hasReasonField) {
        form.addFields(reasonFields);
      }
    } else if (hasReasonField) {
      form.removeField('reason');
      form.removeField('additional_info');
    }

    form.getField('token').setRules($auth.isTotpExpired ? 'required' : '');
  };

  handleDialogOpened = () => {
    const { $app } = this.props;
    if ($app.searchIsOpen) {
      $app.hideSearch();
    }

    this.setState({ isDialogOpened: true });
  };

  handleKeyboardSubmit = () => {
    const { form } = this.props;

    if (form.valid) {
      this.handleSubmit();
    }
  };

  handleUnspoof = () => {
    const { $sudo } = this.props;

    this.setState({ isClearing: true });

    $sudo.clearSpoofSession({ reload: true });
  };

  handleSubmit = () => {
    const { form, $sudo } = this.props;
    this.setState({ isMakingRequest: true });

    $sudo.clearSpoofSession().then(() => {
      $sudo.launchSudoSession(form.getValues(), { showErrorToast: false }).then(
        () => {
          $sudo.redirectOrReloadWindow(form.getValue('redirect'));
        },
        (err) => {
          this.setState({ isMakingRequest: false });
          // ensures error toast is shown before redirect
          showErrorToast(err).then(() => {
            $sudo.redirectOrReloadWindow();
          });
        }
      );
    });
  };

  handleClose = () => {
    this.setState({ isOpen: false, isDialogOpened: false });
  };

  handleCancel = () => {
    const { $sudo, form } = this.props;
    $sudo.setSudoOpen(false);

    setTimeout(() => {
      form.reset();
      this.setState({ companyUserOptions: [] });
    }, 500);
  };

  handleCompanyChange = (field, companyId) => {
    const { form, $sudo } = this.props;

    form.setValue('company', companyId);
    this.setState({ isFetchingUsers: true, companyUserOptions: [] });

    $sudo.getCompanyUserOptions(companyId).then((companyUserOptions) => {
      this.setState({ isFetchingUsers: false, companyUserOptions });

      const target =
        companyUserOptions.find((o) => o.user_level === 2) ||
        companyUserOptions.find((o) => o.user_level === 1 && o.user_group_id === null) ||
        companyUserOptions.find((o) => o.user_group_id === null) ||
        companyUserOptions[0];

      form.setValue('target_user_id', target.value);
    });
  };

  handleReasonChange = (reason) => {
    const { form } = this.props;
    form.getField('additional_info').setRules(reason.value === 'other' ? 'required' : '');
  };

  asyncDebounce = (func, wait) => {
    const debounced = debounce((resolve, reject, args) => {
      func(...args)
        .then(resolve)
        .catch(reject);
    }, wait);
    return (...args) =>
      new Promise((resolve, reject) => {
        debounced(resolve, reject, args);
      });
  };

  handleCompanyQuery = this.asyncDebounce((term) => {
    const { $sudo } = this.props;

    const getSelectOptions = () => $sudo.companyCollection.selectOptions;
    return term && term.length >= 3
      ? $sudo.companyCollection.fetch({ query: { term } }).then(getSelectOptions)
      : Promise.resolve().then(getSelectOptions);
  }, 250);

  render() {
    const { form, $auth, $sudo } = this.props;
    const { companyUserOptions, isMakingRequest, isFetchingUsers, isOpen, isClearing } = this.state;

    return (
      <Dialog
        title="Spoof"
        isOpen={$sudo.sudoOpen}
        position="absolute"
        top={100}
        width={460}
        minHeight={408}
        onClose={this.handleCancel}
        onOpened={this.handleDialogOpened}
      >
        <Dialog.Body>
          <Field
            name="company"
            onChange={this.handleCompanyChange}
            onQuery={this.handleCompanyQuery}
            loading={$sudo.companyCollection.loading}
            isOpen={isOpen}
            mb={3}
          >
            <Select onClose={this.handleClose} height={38} minFilterChars={3} showFilter fill />
          </Field>
          {isFetchingUsers && (
            <Flex justifyContent="center" alignItems="center">
              <Spinner />
            </Flex>
          )}
          {!isFetchingUsers && companyUserOptions.length > 0 && (
            <>
              <Field name="target_user_id" options={companyUserOptions} mb={3}>
                <Select height={38} showFilter fill />
              </Field>
              <Callout p="12px" pt={1} mb={1}>
                <Field name="redirect" onEnterKey={this.handleKeyboardSubmit} autoFocus>
                  <InputGroup leftIcon="document-open" />
                </Field>
                {this.shouldShowReason() && (
                  <>
                    <Field name="reason" onEnterKey={this.handleKeyboardSubmit} onChange={this.handleReasonChange}>
                      {/* <InputGroup leftIcon="comment" /> */}
                      <Select buttonStyle={{ icon: 'high-priority' }} fill showFilter height={30} />
                    </Field>
                    <Flex alignItems="center" gap={1} mb="12px">
                      <Icon icon="arrow-right" iconSize={10} />
                      <Field name="additional_info" m={0} showLabel={false} flex={1}>
                        <InputGroup />
                      </Field>
                    </Flex>
                  </>
                )}
                {$auth.isTotpExpired && (
                  <Field name="token" onEnterKey={this.handleKeyboardSubmit} mb={0}>
                    <InputGroup leftIcon="key" />
                  </Field>
                )}
              </Callout>
            </>
          )}
        </Dialog.Body>
        <Dialog.Footer justifyContent="space-between">
          <Box flex={1}>
            {$auth.isSpoofed && (
              <Button text="Unspoof" intent="danger" onClick={this.handleUnspoof} loading={isClearing} minWidth={110} />
            )}
          </Box>
          <Box>
            <Button text="Cancel" onClick={this.handleCancel} minWidth={110} mr={1} />
            <Button
              disabled={!form.valid || !form.dirty}
              intent="primary"
              loading={isMakingRequest}
              text="Spoof"
              onClick={this.handleSubmit}
              minWidth={110}
            />
          </Box>
        </Dialog.Footer>
      </Dialog>
    );
  }
}

export default SudoDialog;
