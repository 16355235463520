import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, Spinner, Text, Card, Dialog, Select } from 'core/components';
import TargetRenderer from 'app/views/protect/ddos/analyze/components/TargetRenderer';
import { HOUR_IN_SECONDS, DAY_IN_SECONDS } from 'shared/alerting/constants';
import { getTimeRange } from '../util/debugChartHelpers';
import NmsNativeAlertDebugChart from './nms/NmsNativeAlertDebugChart';
import NmsNativeAlertDimensionRenderer from './nms/NmsNativeAlertDimensionRenderer';
import AlertDebugChart from './AlertDebugChart';

const TIME_RANGES = [
  { value: HOUR_IN_SECONDS / 2, label: 'Alert +/- 30 minutes' },
  { value: HOUR_IN_SECONDS, label: 'Alert +/- 1 hour' },
  { value: HOUR_IN_SECONDS * 7, label: 'Alert +/- 7 hours' },
  { value: DAY_IN_SECONDS, label: 'Alert +/- 24 hours' },
  { value: DAY_IN_SECONDS * 7, label: 'Alert +/- 1 week' },
  { value: DAY_IN_SECONDS * 15, label: 'Alert +/- 15 days' }
];

@inject('$alerting')
@observer
export default class AlertDebugDialog extends Component {
  static defaultProps = {
    isOpen: () => {},
    onClose: () => {}
  };

  state = {
    lookbackSeconds: TIME_RANGES[0].value,
    debugData: [],
    loading: true
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { $alerting, alertModel } = this.props;
    const { lookbackSeconds } = this.state;
    const sentBetween = getTimeRange(lookbackSeconds, alertModel.startTime, alertModel.endTime);

    this.setState({ loading: true });
    $alerting
      .fetchDebugAlertData(alertModel, { sentBetween })
      .then((debugData) => this.setState({ debugData, loading: false }));
  }

  handleRangeSelect = (value) => {
    this.setState({ lookbackSeconds: value }, this.fetchData);
  };

  render() {
    const { debugData, lookbackSeconds, loading } = this.state;
    const { isOpen, onClose, alertModel } = this.props;
    const title = `Debug ${alertModel.policy.applicationLabel} Alert`;
    const ChartComponent = alertModel.isNmsPolicy ? NmsNativeAlertDebugChart : AlertDebugChart;

    return (
      <Dialog
        isOpen={isOpen}
        title={title}
        canOutsideClickClose
        canEscapeKeyClose
        isCloseButtonShown
        onClose={() => onClose()}
        iconName="close"
        width={900}
        height={600}
        pb={0}
        position="fixed"
      >
        <Flex p={2} pb={1} justifyContent="space-between" alignItems="center">
          <Text as="div" fontSize="16px" fontWeight="bold">
            Policy: {alertModel.policyObject.name}
          </Text>
          <Text as="div" muted>
            Alert ID: {alertModel.id}
          </Text>
        </Flex>
        <Dialog.Body py={1}>
          <Card p={2}>
            <Flex alignItems="center" borderBottom="thin" justifyContent="space-between" width="100%" pb={2} mb={1}>
              <Flex gap={2} flexWrap="wrap" overflow="hidden" width="70%">
                {alertModel.isNmsPolicy ? (
                  <NmsNativeAlertDimensionRenderer maxDimensionsToShow={3} alertModel={alertModel} />
                ) : (
                  <TargetRenderer model={alertModel} showAll inline />
                )}
              </Flex>
              <Flex gap={1}>
                {debugData && loading && <Spinner size={16} />}
                <Select
                  options={TIME_RANGES}
                  values={lookbackSeconds}
                  onChange={this.handleRangeSelect}
                  menuWidth="auto"
                  width="auto"
                />
              </Flex>
            </Flex>

            <ChartComponent data={debugData} alertModel={alertModel} lookbackSeconds={lookbackSeconds} />
          </Card>
        </Dialog.Body>
      </Dialog>
    );
  }
}
