import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Box, FlexColumn, Grid, Tab, Tabs, Text } from 'core/components';
import { isEmpty, isEqual } from 'lodash';
import PingResponseChart from './PingResponseChart';
import PingCard from './PingCard';
import PingAgentDetails from './PingAgentDetails';

function convertToHighchartsSeriesData(data) {
  const latency = [];
  const loss = [];
  let progress = 0;
  let totalPingSent = 0;
  let totalPingCount = 0;

  Object.keys(data).forEach((key) => {
    const agentData = data[key];
    const { agentName, command } = agentData;
    const pingData = agentData.ping;

    if (!pingData) {
      latency.push({
        name: agentName,
        data: []
      });
      loss.push({
        name: agentName,
        data: []
      });
    } else {
      const totalPings = command?.ping?.probe_count?.value || 0;
      const waitSecs = command?.ping?.wait?.value || 1;

      if (totalPings) {
        const pingWindowSize = Math.min(totalPings, Math.max(totalPings / 20, 10));
        const { responses = [], start, dst, timeout } = pingData;
        const pingSent = pingData.pingSent || pingData.ping_sent || 0;
        const timeoutBufferMillis = 5000;

        totalPingSent += pingSent;
        totalPingCount += totalPings;

        if (pingSent > 0) {
          const latencyData = [];
          const lossData = [];

          for (
            let i = 0, time = new Date(typeof start === 'string' ? start : start.seconds * 1000).getTime();
            i < pingSent;
            i += 1
          ) {
            const now = Date.now();
            if (responses[i]?.seq !== i && i <= pingSent - waitSecs * 2) {
              // if the entry at this index doesn't match the seq number, assume loss
              responses.splice(i, 0, { seq: i, from: dst, reply_ttl: 64, tx: { seconds: time / 1000 } });
            } else if (responses[i]?.tx) {
              // sequence matches, so set the time
              time = new Date(
                typeof responses[i].tx === 'string' ? responses[i].tx : responses[i].tx.seconds * 1000
              ).getTime();
              if (now > time + timeoutBufferMillis + timeout * 1000 && responses[i].rtt === 0) {
                responses[i].rtt = undefined;
              }
            }

            if (responses[i] && responses[i].rtt !== 0) {
              // RTT will be populated, or undefined for loss
              // 0 RTT will mean pending (for now?) because loss will be undefined to create gaps in the latency chart
              latencyData.push([time, responses[i].rtt]);
            }

            // calculate loss
            const lost = responses
              .slice(Math.max(0, i - pingWindowSize + 1), i + 1)
              .filter((resp) => resp.rtt === undefined).length;

            if (now > time + timeoutBufferMillis + timeout * 1000) {
              lossData.push([time, (lost / Math.min(pingWindowSize, i + 1)) * 100]);
            }

            // update time in case the next probe is lost
            time += waitSecs * 1000;
          }

          latency.push({ name: agentName, data: latencyData });
          loss.push({ name: agentName, data: lossData });
        }
      }
    }
  });

  progress = totalPingCount > 0 ? totalPingSent / totalPingCount : 0;

  return { latency, loss, progress, totalPingSent, totalPingCount };
}

@observer
class PingResponse extends Component {
  state = {
    chartData: [],
    prevResults: null,
    selectedTab: 'latency' // 'loss', 'raw'
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { results } = nextProps;
    if (results.result && !isEqual(structuredClone(results), prevState.prevResults)) {
      return {
        ...prevState,
        chartData: convertToHighchartsSeriesData(results.result),
        prevResults: structuredClone(results)
      };
    }
    return null;
  }

  componentDidMount() {
    const {
      model,
      results: { result }
    } = this.props;

    if (result) {
      Object.keys(result).forEach((key) => {
        model.subscribe(result[key].id, result[key].agent.agent_id);
      });
    }
  }

  componentWillUnmount() {
    const {
      model,
      results: { result }
    } = this.props;

    if (result) {
      Object.keys(result).forEach((key) => {
        model.unsubscribe(result[key].id);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      model,
      results: { result }
    } = this.props;
    if (result && model !== prevProps.model) {
      Object.keys(result).forEach((key) => {
        model.unsubscribe(result[key].id);
        model.subscribe(result[key].id, result[key].agent.agent_id);
      });
    }
  }

  handleTabChange = (tab) => {
    this.setState({ selectedTab: tab });
  };

  render() {
    const { results } = this.props;
    const { chartData, selectedTab } = this.state;

    if (isEmpty(results.result)) {
      return (
        <FlexColumn flex={1} position="relative" overflow="hidden">
          <Text>Something went wrong.</Text>
        </FlexColumn>
      );
    }

    const rawData = results.result;

    return (
      <FlexColumn flex={1} position="relative">
        <Box borderBottom="thin" pl={2} pt={1}>
          <Tabs selectedTab={selectedTab} onChange={this.handleTabChange}>
            <Tab id="latency" title="Latency" />
            <Tab id="loss" title="Loss" />
            <Tab id="raw" title="Raw" />
          </Tabs>
        </Box>

        {['latency', 'loss'].includes(selectedTab) && (
          <Grid gridTemplateColumns="1fr 525px" flex={1} position="relative" overflow="hidden">
            <PingResponseChart
              chartData={structuredClone(chartData[selectedTab])}
              rawData={rawData}
              selectedTab={selectedTab}
            />
            <PingAgentDetails results={rawData} chartData={chartData} />
          </Grid>
        )}

        {selectedTab === 'raw' && (
          <Grid
            gridTemplateColumns="repeat(auto-fit, minmax(525px, 1fr))"
            alignItems="flex-start"
            gridRowGap={3}
            gridGap={3}
            p={1}
          >
            {Object.values(rawData).map(({ id, ping, agent }) => (
              <PingCard key={id} id={id} ping={ping} agent={agent} />
            ))}
          </Grid>
        )}
      </FlexColumn>
    );
  }
}

export default PingResponse;
