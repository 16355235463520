import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';

const AsnLink = ({ $app, asn, children, nextHop = false, peeringdb = false, ...rest }) => {
  if ($app.isSubtenant) {
    return children;
  }

  return (
    <Link
      to={`/v4/core/quick-views/${nextHop ? 'nextHopAsn' : 'asn'}/${asn || 0}${peeringdb ? '/peeringdb' : ''}`}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default inject('$app')(AsnLink);
