import { inject, observer } from 'mobx-react';
import { adjustByGreekPrefix } from 'core/util';
import { bracketingColors } from 'core/components/theme';
import { getShortName } from 'app/util/queryResults';
import { escapeHtml, getToFixed, greekIt, zeroToText } from 'app/util/utils';
import BaseHighchartsDataview from './BaseHighchartsDataview';

@inject('$app', '$dictionary')
@observer
export default class BarView extends BaseHighchartsDataview {
  chartOptions = {
    chart: {
      className: 'bar',
      zoomType: 'x'
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'category',
      labels: {
        useHTML: true,
        format: '<span title="{value}">{value}</span>'
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      plotLines: []
    },
    plotOptions: {
      bar: {},
      series: {
        cursor: 'pointer',
        stacking: 'normal',
        marker: {
          enabled: false
        },
        groupPadding: 0,
        pointPadding: 0.1
      }
    },
    legend: { enabled: false },
    series: [
      {
        type: 'bar',
        name: '',
        data: []
      }
    ]
  };

  buildSeriesInternal(bucket, models) {
    const {
      viewProps: { showTotalOverlay = true }
    } = this.props;
    this.clear();

    if (!this.chart || !models || models.length === 0) {
      return;
    }

    const { $app, $dictionary } = this.props;
    const name = bucket.get('name');
    const seriesIndex = name.startsWith('Negative') ? 1 : 0;
    const { outsort, metric } = bucket.firstQuery.get();
    const { aggregates, outsortUnit, outsortDataKey } = bucket.firstQuery;
    const bracketOptions = bucket.firstQuery.get('bracketOptions');
    const axisText = $dictionary.get('units')[outsortUnit];
    let suffix = axisText;

    if (outsortUnit.includes('sample_rate')) {
      suffix = ` : 1 ${suffix}`;
    }

    if (!this.chart.series[seriesIndex]) {
      this.chart.xAxis[0].update(
        {
          opposite: true
        },
        false
      );
      this.chart.addAxis(
        {
          type: 'category',
          categories: [],
          labels: {
            useHTML: true,
            format: '<span title="{value}">{value}</span>'
          }
        },
        true,
        false
      );
      this.chart.yAxis[0].setTitle({ text: `${axisText}, ${this.chart.yAxis[0].options.title.text}` }, false);
      this.chart.addSeries({
        type: 'bar',
        name: outsort,
        data: [],
        xAxis: 1,
        suffix
      });
    }

    models.forEach((model) => {
      const lookup = model.get('lookup');
      const key = model.get('key');
      const isOverlay = model.get('isOverlay');
      let data = model.get(outsortDataKey);

      if (outsortUnit.includes('sample_rate') && aggregates.find((agg) => agg.name === outsort).fn !== 'percentile') {
        data /= 100;
      }

      if (isOverlay && showTotalOverlay) {
        if (!this.chart.yAxis[seriesIndex].plotLinesAndBands.find((pl) => pl.id === key)) {
          const { displayFull } = greekIt(data, { fix: 1, suffix });

          this.chart.yAxis[seriesIndex].addPlotLine({
            className: key === 'Total' ? 'highcharts-color-50' : 'highcharts-color-500',
            id: key,
            label: {
              text: `${escapeHtml(displayFull)}<br /><span class="faded">${key}</span>`
            },
            value: data,
            width: 3,
            zIndex: 5
          });
        }
      } else {
        const point = {
          name: (lookup || key)
            .split(' ---- ')
            .map((part, idx) => getShortName(metric[idx], part))
            .join(' ---- '),
          y: data,
          model
        };
        if (bracketOptions) {
          const tagData = model.get(bracketOptions.tagKey);
          const colorIndex = tagData ? bracketingColors.indexOf(tagData.value) : -1;
          Object.assign(point, { metadata: { tagData }, className: 'bracketing', colorIndex });
        }
        this.chart.series[seriesIndex].addPoint(point, false);
      }
      if (!this.chart.yAxis[0].max || this.chart.yAxis[0].max < data) {
        this.chart.yAxis[0].update({ max: data * 1.05 });
      }
    });

    $app.renderSync(() => {
      if (this.chart) {
        this.chart.redraw();
      }
    });
    $app.renderSync(() => {
      this.dismissSpinner();
    });
  }

  redraw({ setColors = false } = {}) {
    const { $app } = this.props;

    if (this.chart) {
      if (setColors) {
        this.chart.update({ colors: this.chartColors }, false);
        this.chart.yAxis.forEach((axis) => {
          axis.plotLinesAndBands.forEach((plotLine) => {
            plotLine.options.label.style = { color: this.chartLabelColor };
          });
        });
      }
      $app.renderSync(() => {
        if (this.chart) {
          this.chart.redraw();
        }
      });
    }
  }

  clear() {
    if (this.chart?.series?.length > 0) {
      this.chart.series[0].setData([], false);
      if (this.chart.series[1]) {
        this.chart.series[1].setData([], false);
      }
      this.chart.yAxis.forEach((axis) => {
        axis.plotLinesAndBands.forEach((plotLine) => plotLine.axis.removePlotBandOrLine(plotLine.id), false);
      });

      this.chart.redraw();
    }
  }

  setVisibleModels(models) {
    if (this.chart?.series?.length) {
      this.chart.series[0].data.forEach((point) => {
        if (point.options && point.options.model) {
          const { model } = point.options;
          const pointKey = model.get('key');

          const selected = models.some((m) => m.get('key') === pointKey);
          point.select(selected, true);
        }
      });

      this.chart.redraw();
    }
  }

  getComponent() {
    const { chartOptions } = this;
    const { dataview } = this.props;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const { $dictionary } = this.props;
    const query = activeBuckets[0].firstQuery;
    const { outsort } = query.get();
    const { outsortUnit } = query;

    const axisText = $dictionary.get('units')[outsortUnit];
    let suffix = axisText;
    if (outsortUnit.includes('sample_rate')) {
      suffix = ` : 1 ${suffix}`;
    }
    chartOptions.yAxis.title.text = axisText;
    chartOptions.series[0].name = outsort;
    chartOptions.series[0].suffix = suffix;

    chartOptions.tooltip = {
      formatter() {
        const { prefix } = activeBuckets[0].queryResults;

        const val = escapeHtml(
          zeroToText(adjustByGreekPrefix(this.y, prefix[query.prefixUnit]), { fix: getToFixed(outsortUnit) })
        );
        const label = `${prefix[query.prefixUnit] || ''}${suffix}`;

        return `${this.point.name.replace(/----/g, '\u2192')}:<br/><b>${val} ${label}</b><br/>${
          (this.point.metadata && this.point.metadata.tagData && this.point.metadata.tagData.bracketLabel) || ''
        }`;
      }
    };

    if (chartOptions.plotOptions && chartOptions.plotOptions.bar) {
      const { bar } = chartOptions.plotOptions;

      if (this.props.onModelSelect) {
        if (!bar.point) {
          bar.point = { events: {} };
        }
        bar.point.events.click = this.getLegendItemClick();
      }
    }

    return super.getComponent();
  }
}

const config = {
  showTotalTrafficOverlay: true,
  showLegend: true,
  timeBased: false,
  isSVG: true,
  enableToggle: false,
  buckets: [
    {
      name: 'Positive X Axis'
    },
    {
      name: 'Negative X Axis',
      sampleRateFactor: -1
    }
  ]
};

export { config };
