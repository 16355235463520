import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { InputGroup } from '@blueprintjs/core';

import { Box, Button } from 'core/components';

@observer
class SelectFilter extends Component {
  static defaultProps = {
    filterable: true,
    filterPlaceholder: 'Search options...',
    multi: true
  };

  handleFilterValueChange = (e) => {
    const { selectState } = this.props;
    selectState.setFilterValue(e.target.value);
  };

  handleClearFilterValue = () => {
    const { selectState } = this.props;
    selectState.setFilterValue('');
  };

  render() {
    const { filterable, filterPlaceholder, filterRef, multi, selectState, disabled } = this.props;
    const { flatFilteredOptions, filterValue } = selectState;

    const selectSearchResults = (
      <Box ml="4px">
        <Button minimal onClick={this.handleClearFilterValue} icon="cross" />
      </Box>
    );

    const hasResults = flatFilteredOptions.length > 0 && filterValue;

    let rightElement = null;

    if (multi) {
      if (hasResults) {
        rightElement = selectSearchResults;
      } else if (filterValue !== '') {
        rightElement = <Button minimal disabled text="No Results" />;
      }
    }

    if (filterable === true) {
      return (
        <Box flex={1} maxWidth={350}>
          <InputGroup
            className="pt-fill"
            autoFocus
            leftIcon="search"
            onChange={this.handleFilterValueChange}
            placeholder={filterPlaceholder}
            value={filterValue}
            rightElement={rightElement}
            inputRef={filterRef}
            disabled={disabled}
          />
        </Box>
      );
    }

    return null;
  }
}

export default SelectFilter;
