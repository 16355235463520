import React from 'react';
import { observer } from 'mobx-react';
import SyntheticsSankey from 'app/views/synthetics/components/SyntheticsSankey';
import LoadingPanel from './LoadingPanel';

@observer
export default class SankeyPanel extends React.Component {
  render() {
    const { test } = this.props;
    const { healthTimeline, resultTimeMs, results, hasResults, loading } = test.results;
    const hasFlow = results?.flow && results.flow.length > 0;

    return (
      <LoadingPanel
        test={test}
        testResults={test.results}
        loading={loading}
        empty={healthTimeline.length === 0 || !hasResults || !hasFlow}
      >
        <SyntheticsSankey results={results} test_id={test.id} resultTimeMs={resultTimeMs} />
      </LoadingPanel>
    );
  }
}
