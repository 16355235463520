import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from 'core/components';
import { AckAlertButton } from 'app/views/alerting/components/AckAlertButton';
import { UnackAlertButton } from 'app/views/alerting/components/UnackAlertButton';
import SilenceAlertButton from 'app/views/alerting/components/SilenceAlertButton';
import SuppressAlertButton from 'app/views/alerting/components/SuppressAlertButton';
import ClearAlarmButton from 'app/views/alerting/components/ClearAlarmButton';
import DashboardLink from 'app/components/links/DashboardLink';
import PolicyLink from 'app/components/links/PolicyLink';
import { AiFillBug } from 'react-icons/ai';
import AlertDebugDialog from 'app/views/alerting/components/AlertDebugDialog';

/**
 * This component displays various actions that can be take on an alert:
 * - Link to its detail page
 * - Ack/unack
 * - Clear
 * - Silence
 * - Suppress
 * - Dashboard link
 * - Policy link
 * - Debug
 */
const AlertActions = ({ alertModel = {}, isSubtenant, tenant, showDetailsLink, showPolicyLink }) => {
  const [showDebugDialog, setShowDebugDialog] = useState(false);
  const { policy } = alertModel;
  const dashboardId = policy?.get('dashboardID');

  const toggleDebugDialogVisibility = () => {
    setShowDebugDialog(!showDebugDialog);
  };

  return (
    <>
      {showDetailsLink && (
        <Button textAlign="left" icon="zoom-in" fill onClick={() => window.open(alertModel.alertDetailLink, '_blank')}>
          View Details
        </Button>
      )}

      {!isSubtenant && !alertModel.isAcked && <AckAlertButton model={alertModel} />}
      {!isSubtenant && alertModel.isAcked && <UnackAlertButton model={alertModel} />}
      {!isSubtenant && alertModel.isActive && <ClearAlarmButton models={alertModel} />}
      {!isSubtenant && <SilenceAlertButton alertModel={alertModel} buttonProps={{ fill: true }} />}
      {!isSubtenant && <SuppressAlertButton alertModel={alertModel} buttonProps={{ fill: true }} />}

      {dashboardId && !alertModel.isMetricPolicy && (
        <DashboardLink as="Button" fill withIcon id={dashboardId} query={alertModel.dashboardQuery} alignText="left">
          Open Dashboard
        </DashboardLink>
      )}

      {!isSubtenant && policy && showPolicyLink && (
        <PolicyLink id={policy?.get('policy_id') || policy?.id} as="Button" fill icon="edit" alignText="left">
          {tenant ? 'Edit Parent Policy' : 'Edit Policy'}
        </PolicyLink>
      )}

      {!isSubtenant && (
        <Button fill textAlign="left" onClick={toggleDebugDialogVisibility} icon={AiFillBug}>
          Debug Alert
        </Button>
      )}

      {!!showDebugDialog && (
        <AlertDebugDialog isOpen={showDebugDialog} alertModel={alertModel} onClose={toggleDebugDialogVisibility} />
      )}
    </>
  );
};

AlertActions.propTypes = {
  // This must be an AlertModel instance
  alertModel: PropTypes.object.isRequired,
  isSubtenant: PropTypes.bool,
  // TenantModel
  tenant: PropTypes.object,
  showDetailsLink: PropTypes.bool,
  showPolicyLink: PropTypes.bool
};

AlertActions.defaultProps = {
  isSubtenant: undefined,
  tenant: undefined,
  showDetailsLink: undefined,
  showPolicyLink: undefined
};

export default withTheme(AlertActions);
