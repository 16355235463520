export default {
  aggregateTypes: ['p95th_bits_per_sec'],
  topx: 0,
  lookback_seconds: 86400,
  all_devices: true,
  show_overlay: false,
  show_total_overlay: false,
  aggregateFiltersEnabled: true,
  aggregateFilters: [
    {
      name: 'Inbound',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      saved_filters: [],
      filterGroups: [],
      filters: [
        {
          filterField: 'i_src_connect_type_name',
          operator: '<>',
          filterValue: 'customer'
        },
        {
          filterField: 'i_src_network_bndry_name',
          operator: '=',
          filterValue: 'external'
        },
        {
          filterField: 'i_ult_exit_connect_type_name',
          operator: '=',
          filterValue: 'customer'
        }
      ]
    },
    {
      name: 'Outbound',
      named: true,
      connector: 'All',
      not: false,
      autoAdded: '',
      saved_filters: [],
      filterGroups: [],
      filters: [
        {
          filterField: 'i_src_connect_type_name',
          operator: '=',
          filterValue: 'customer'
        },
        {
          filterField: 'i_ult_exit_network_bndry_name',
          operator: '=',
          filterValue: 'external'
        },
        {
          filterField: 'i_ult_exit_connect_type_name',
          operator: '<>',
          filterValue: 'customer'
        }
      ]
    }
  ]
};
