import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import { getHashForObject } from 'app/stores/query/urlHash';
import { withRouter } from 'react-router-dom';
import { Hotkey, Hotkeys, HotkeysTarget, Position } from '@blueprintjs/core';
import { debounce } from 'lodash';
import { MdAddCircleOutline } from 'react-icons/md';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { ReactComponent as EnvelopeRemove } from 'app/assets/icons/envelope-remove.svg';
import { PROVIDER_AND_FEATURE_SELECTION_STEP } from 'app/stores/clouds/cloudExport/steps';
import { CLOUD_KB_LINKS } from 'shared/hybrid/constants';

import {
  Box,
  Text,
  Button,
  EmptyState,
  Flex,
  FlexColumn,
  Heading,
  Menu,
  Link,
  MenuDivider,
  MenuItem,
  Popover,
  Spinner,
  AnchorButton
} from 'core/components';
import storeLoader from 'app/stores/storeLoader';
import ExplorerQueryModel from 'app/stores/query/ExplorerQueryModel';
import QueryModel from 'app/stores/query/QueryModel';
import Page from 'app/components/page/Page';
import NotFound from 'app/views/NotFound';

import RefreshButton from 'app/components/dataviews/tools/RefreshButton';
import { BiFolder } from 'react-icons/bi';
import { FiShare2 } from 'react-icons/fi';
import ShareViewDialog from 'app/views/core/ShareViewDialog';
import SubscribeDialog from 'app/views/settings/subscriptions/SubscribeDialog';
import DashboardRemoveMenuItem from 'app/views/core/dashboards/DashboardRemoveMenuItem';
import { DashboardDrawer } from 'app/views/core/dashboards/DashboardDrawer';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import DashboardHeader from './DashboardHeader';
import DashboardItemGrid from './DashboardItemGrid';
import DashboardDetailsDialog from './DashboardDetailsDialog';
import DashboardItemDialog from './dashboardItem/DashboardItemDialog';
import CloneDashboardItemDialog from './dashboardItem/CloneDashboardItemDialog';
import networkExplorerParentLinks from '../NetworkExplorerParentLink';
import MKPParentLink from '../MKPParentLink';
import DescriptionDialog from '../DescriptionDialog';

@storeLoader('$dashboards')
@inject('$explorer', '$app', '$auth', '$dashboard', '$mkp', '$sharedLinks', '$subscriptions', '$hybridMap')
@withRouter
@observer
@HotkeysTarget
class DashboardView extends Component {
  static defaultProps = {
    showSidebar: true,
    showHeader: true
  };

  state = {
    isLoading: false,
    isActionsOpen: false,
    sidebarOpen: false,
    dockCollapsed: false,
    shareDialogOpen: false,
    subscribeDialogOpen: false,
    unsubscribeDialogOpen: false,
    descriptionDialogOpen: false,
    isSubscribed: undefined,
    selectedModel: undefined
  };

  constructor(props) {
    super(props);

    this.grid = React.createRef();
  }

  componentDidMount() {
    const { $app, $mkp, $dashboard, $subscriptions, $dashboards, match, location, loading } = this.props;

    if (!$app.isSubtenant) {
      $mkp.tenants.fetch();
      $subscriptions.collection.fetch();
    }

    if (!loading) {
      $dashboards.loadDashboard(match.params, location.state, location.search);
    }

    this.dashboardDisposer = reaction(
      () => $dashboard.dashboard,
      () => this.setDefaultSidebarOpen()
    );
  }

  componentDidUpdate(prevProps) {
    const { $dashboards, match, location, loading } = this.props;

    if (
      (!loading && prevProps.loading) ||
      this.getDashboardId() !== this.getDashboardId(prevProps) ||
      this.getDashboardSlug() !== this.getDashboardSlug(prevProps)
    ) {
      $dashboards.loadDashboard(match.params, location.state, location.search);
    }
  }

  componentWillUnmount() {
    const { $dashboard } = this.props;
    $dashboard.reset();

    if (this.dashboardDisposer) {
      this.dashboardDisposer();
    }
  }

  setDefaultSidebarOpen() {
    const { $dashboard } = this.props;
    const { sidebarOpen } = this.state;
    if ($dashboard.dashboard) {
      this.setState({ sidebarOpen: $dashboard.dashboard.isParametric ? 'query' : sidebarOpen });
    }
  }

  getDashboardId(props) {
    const { match } = props || this.props;
    return match.params.dashboardId;
  }

  getDashboardSlug(props) {
    const { match } = props || this.props;
    return match.params.dashboardSlug;
  }

  get model() {
    const { $dashboard } = this.props;

    if ($dashboard.dashboard) {
      const query = $dashboard.dashboard.get('query');
      const model = ExplorerQueryModel.createFromQueryModel(QueryModel.create(query));

      model.set('parametric_fields', $dashboard.dashboard.get('parametric_fields'));

      return model;
    }

    return null;
  }

  handleDashboardRemove = () => {
    const { $dashboard, history } = this.props;

    return $dashboard.dashboard.destroy().then((success) => success && history.push('/v4/library'));
  };

  handleAddDashboardItem = (panel_type) => {
    const { $dashboard } = this.props;

    $dashboard.addDashboardItem(panel_type || 'explorer_query');
  };

  handleCloseDashboardItem = () => {
    const { $dashboard } = this.props;
    $dashboard.setEditingDashboardItem(false);
  };

  toggleShareDialog = (dialogType) => {
    const { $subscriptions, $dashboard } = this.props;
    const { dashboard: model } = $dashboard;
    const attributes = { content_type: 'dashboard', content_id: model.id };

    Object.assign(attributes, { parametric_fields: model.get('parametric_fields') });

    const dialogToOpen = {
      share: 'shareDialogOpen',
      subscribe: 'subscribeDialogOpen',
      unsubscribe: 'unsubscribeDialogOpen'
    }[dialogType];

    this.setState(() => ({
      [dialogToOpen]: true,
      selectedModel: $subscriptions.collection.forge(attributes, { select: false })
    }));
  };

  onClose = () => {
    this.setState({
      shareDialogOpen: false,
      subscribeDialogOpen: false,
      unsubscribeDialogOpen: false,
      selectedModel: undefined
    });
    this.setSubscriptionStatus();
  };

  setSelectedModel(selectedModel) {
    this.setState({ selectedModel });
  }

  setSubscriptionStatus = () => {
    const { $app, $auth, $dashboard, $subscriptions } = this.props;
    const { dashboard } = $dashboard;
    if (dashboard && !$app.isSubtenant) {
      $subscriptions
        .isUserSubscribedToContent($auth.activeUser.user_email, 'dashboard', dashboard.get('id'))
        .then((isSubscribed) => {
          this.setState({ isSubscribed });
        });
    }
  };

  handleToggleDockCollapsed = () => {
    const { dockCollapsed } = this.state;
    this.setState({ dockCollapsed: !dockCollapsed });
  };

  handleActionsInteraction = (isActionsOpen) => {
    this.setState({ isActionsOpen });
  };

  handleSidebarToggle = (type) => {
    const { sidebarOpen } = this.state;
    this.setState({ sidebarOpen: sidebarOpen === type ? false : type });
  };

  handleSidebarClose = () => {
    this.setState({ sidebarOpen: false });
  };

  handlePreviewTenant = (tenant) => {
    const { $dashboard } = this.props;
    $dashboard.applyTenantFilter(tenant);
  };

  checkNeedsScrollToBottom = () => {
    const { $dashboard } = this.props;
    if ($dashboard.needsScrollToBottom) {
      this.grid.current.scrollIntoView(false);
      $dashboard.needsScrollToBottom = false;
    }
  };

  handleScroll = debounce(() => {
    const { $dashboard } = this.props;
    $dashboard.reorderPendingItems();
  }, 300);

  handleToggleDescriptionDialog = () => {
    const { descriptionDialogOpen } = this.state;
    this.setState({ descriptionDialogOpen: !descriptionDialogOpen });
  };

  handleCloseDescriptionDialog = () => {
    this.setState({ descriptionDialogOpen: false });
  };

  handleCreateCloudExporterRedirect = async () => {
    const { history, $dashboard } = this.props;
    this.setState({ isLoading: true });

    const cloudProvider = $dashboard.dashboardCloudProviders?.[0] ?? 'aws';

    try {
      const hash = await getHashForObject({
        currentStepId: PROVIDER_AND_FEATURE_SELECTION_STEP,
        formValues: {
          cloud_provider: cloudProvider,
          properties: {}
        }
      });

      history.push(`/v4/setup/clouds?hash=${hash}`);
    } catch (error) {
      console.warn('Unable to generate hash for object', error);
    }

    this.setState({ isLoading: true });
  };

  // Used by @HotkeysTarget TODO switch over to HotkeysTarget2
  // eslint-disable-next-line react/no-unused-class-component-methods
  renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
          global
          group="Dashboard/Saved View"
          combo="shift + D"
          label="Toggle Description"
          onKeyDown={this.handleToggleDescriptionDialog}
        />
      </Hotkeys>
    );
  }

  get isCloudEmptyState() {
    const { $dashboard, $hybridMap } = this.props;
    // empty stats should be shown for cloud dashboards without any exporters
    if (!$dashboard.isCloudDashboard) {
      return false;
    }

    return $dashboard.dashboard?.dashboardCloudProviders?.some(
      (cloudProvider) => $hybridMap.hasCloud(cloudProvider) === false
    );
  }

  render() {
    const { $app, $dashboards, $dashboard, $mkp, $sharedLinks, location, loading, $hybridMap } = this.props;
    const {
      isLoading,
      isActionsOpen,
      sidebarOpen,
      shareDialogOpen,
      subscribeDialogOpen,
      unsubscribeDialogOpen,
      descriptionDialogOpen,
      selectedModel,
      isSubscribed
    } = this.state;

    const { dashboard, isFetching } = $dashboard;

    if (isSubscribed === undefined) {
      this.setSubscriptionStatus();
    }

    let parentLinks = this.getDashboardId() ? undefined : networkExplorerParentLinks;

    if ($app.isSubtenant) {
      parentLinks = MKPParentLink;
    }

    if (loading || isFetching) {
      return (
        <Page py={0} canFullScreen scrolls parentLinks={parentLinks}>
          <Flex pt="100px" flex={1} justifyContent="center">
            <Spinner />
          </Flex>
        </Page>
      );
    }

    if (!dashboard) {
      return <NotFound />;
    }

    const showEmptyView = !$dashboard.isFetching && !$dashboard.dashboard.hasItems;
    const showItemGrid = !$dashboard.isFetching && $dashboard.dashboard.hasItems;
    const dashboardNavigationHistory = location.state && location.state.dashboardNavigationHistory;

    const subnavTools = (
      <>
        <RefreshButton
          model={dashboard}
          buttonProps={{
            minimal: true,
            mx: '2px'
          }}
          allowLiveUpdate
        />

        {!$app.isSubtenant && (
          <>
            <Button icon={FiShare2} text="Share" minimal onClick={() => this.toggleShareDialog('share')} />
            <Popover
              isOpen={isActionsOpen}
              content={
                <Menu>
                  {dashboard.canEdit && (
                    <MenuItem
                      icon="edit"
                      text="Edit"
                      onClick={$dashboard.toggleEditing}
                      disabled={$dashboard.isEditing}
                    />
                  )}
                  {dashboard.canEdit && (
                    <MenuItem
                      icon="cog"
                      text="Settings"
                      onClick={() => $dashboard.setEditingProperties(true)}
                      disabled={$dashboard.isEditingProperties}
                    />
                  )}
                  <MenuItem
                    icon="duplicate"
                    text="Clone"
                    onClick={() =>
                      $dashboards.cloneDashboardById(dashboard.id).then((newDashboard) => {
                        $dashboards.navigateToDashboard(newDashboard);
                      })
                    }
                  />

                  <MenuDivider />
                  <MenuItem icon="export" text="Export" popoverProps={{ openOnTargetFocus: false }}>
                    <MenuItem icon="document" text="Visual Report" label=".pdf" onClick={$dashboard.export} />
                  </MenuItem>
                  <MenuItem icon="envelope" text="Subscribe" onClick={() => this.toggleShareDialog('subscribe')} />
                  {isSubscribed && (
                    <MenuItem
                      icon={EnvelopeRemove}
                      text="Unsubscribe"
                      onClick={() => this.toggleShareDialog('unsubscribe')}
                    />
                  )}
                  {$mkp.tenants.size > 0 && (
                    <>
                      <MenuDivider />
                      <MenuItem icon="user" text="Preview as Tenant" popoverProps={{ openOnTargetFocus: false }}>
                        <Box maxHeight={300} overflow="auto">
                          {$mkp.tenants.map((tenant) => (
                            <MenuItem
                              key={tenant.id}
                              className="preview-as-tenant"
                              text={tenant.get('name')}
                              onClick={() => this.handlePreviewTenant(tenant)}
                            />
                          ))}
                        </Box>
                      </MenuItem>
                    </>
                  )}
                  {dashboard.canEdit && (
                    <>
                      <MenuDivider />
                      <DashboardRemoveMenuItem onDashboardRemove={this.handleDashboardRemove} />
                    </>
                  )}
                </Menu>
              }
              onInteraction={this.handleActionsInteraction}
              position={Position.BOTTOM_RIGHT}
            >
              <Button text="Actions" rightIcon="caret-down" ml="2px" active={!!isActionsOpen} minimal />
            </Popover>
          </>
        )}

        {$app.isSubtenant && <Button icon="export" ml="2px" minimal text="Export" onClick={$dashboard.export} />}
        <Button
          text="Dashboards"
          icon={BiFolder}
          ml="2px"
          active={sidebarOpen === 'dashboards'}
          onClick={() => this.handleSidebarToggle('dashboards')}
          minimal
        />
        <Button
          text="Query"
          icon={AiOutlineMenuFold}
          ml="2px"
          active={sidebarOpen === 'query'}
          onClick={() => this.handleSidebarToggle('query')}
          minimal
        />
      </>
    );

    const dashTitle = dashboard.get('dash_title');
    const dashDesc = dashboard.get('description');
    const hasDashDesc = dashDesc?.length > 0;

    if (this.isCloudEmptyState) {
      const cloudProvider = $dashboard.cloudDashboardProvider;

      return (
        <Page
          title={dashTitle}
          flexDirection="row"
          parentLinks={parentLinks}
          py={0}
          canFullScreen
          scrolls
          minHeight="100%"
        >
          <FlexColumn flex={1}>
            <Heading level={1} m={0} ml="4px">
              {dashboard.get('dash_title')}
            </Heading>
            <Box
              mb={2}
              mt={1}
              flex={1}
              display="flex"
              border="thin"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <Flex flexDirection="column" gap={1}>
                <Box mx="auto" mb={1}>
                  <CloudIcon iconSize={74} isEmptyState />
                </Box>
                <Heading level={3}>No Configured Exporter</Heading>
                <Text>
                  You must configure an exporter for{' '}
                  {$hybridMap.providerMap[$dashboard.dashboardCloudProviders.join(', ')]} data to display here.
                </Text>
                <Flex justifyContent="space-between" alignItems="center">
                  <Link to={CLOUD_KB_LINKS.get(`${cloudProvider}.main`)} blank>
                    Read Documentation
                  </Link>
                  <AnchorButton
                    loading={isLoading}
                    icon={MdAddCircleOutline}
                    intent="primary"
                    text="Create Cloud Export"
                    onClick={this.handleCreateCloudExporterRedirect}
                  />
                </Flex>
              </Flex>
            </Box>
          </FlexColumn>
        </Page>
      );
    }

    return (
      <Page
        title={dashTitle}
        flexDirection="row"
        parentLinks={parentLinks}
        py={0}
        subnavTools={subnavTools}
        drawerContents={
          <DashboardDrawer model={this.model} sidebarOpen={sidebarOpen} handleSidebarClose={this.handleSidebarClose} />
        }
        drawerIsOpen={sidebarOpen}
        drawerOnClose={this.handleSidebarClose}
        onScroll={this.handleScroll}
        canFullScreen
        scrolls
        minHeight="100%"
        canDockDrawer
      >
        <FlexColumn flex={1}>
          <DashboardHeader
            navigationHistory={dashboardNavigationHistory}
            onAddItem={this.handleAddDashboardItem}
            descriptionButtonOnClick={this.handleToggleDescriptionDialog}
            hasDashDesc={hasDashDesc}
          />
          {$dashboard.isFetching && (
            <Flex flex={1} justifyContent="center" alignItems="center">
              <Spinner />
            </Flex>
          )}
          {showEmptyView && (
            <Box height="calc(100vh - 400px)">
              <EmptyState
                title="No Panels"
                icon="new-grid-item"
                description="This view does not have any panels added yet."
              />
            </Box>
          )}
          <Flex flex={1} ref={this.grid} mx={-3}>
            {showItemGrid && (
              <DashboardItemGrid
                checkScroll={this.checkNeedsScrollToBottom}
                collapsed={this.dockCollapsed}
                navigationHistory={dashboardNavigationHistory}
                onToggleDockCollapsed={this.handleToggleDockCollapsed}
                dockCollapsed={this.dockCollapsed}
              />
            )}
          </Flex>
        </FlexColumn>
        {$dashboard.isEditingProperties && (
          <DashboardDetailsDialog
            isOpen={$dashboard.isEditingProperties}
            onSubmit={$dashboard.handleDashboardSave}
            onClose={() => $dashboard.setEditingProperties(false)}
            model={$dashboard.dashboard}
          />
        )}
        {$dashboard.isEditingDashboardItem && $dashboard.dashboard.selectedPanel && (
          <DashboardItemDialog
            collection={$dashboard.dashboard.get('items')}
            dashboard={$dashboard.dashboard}
            isOpen={$dashboard.isEditingDashboardItem && $dashboard.dashboard.selectedPanel}
            onClose={this.handleCloseDashboardItem}
            model={$dashboard.dashboard.selectedPanel}
            activeTab={$dashboard.dashboard.selectedPanel && $dashboard.dashboard.selectedPanel.get('editActiveTab')}
            showDashboardSelect={
              $dashboard.dashboard.selectedPanel && !$dashboard.dashboard.selectedPanel.get('dashboard')
            }
          />
        )}
        {$dashboard.isCloningDashboardItem && (
          <CloneDashboardItemDialog
            isOpen={$dashboard.isCloningDashboardItem}
            srcDashboardItem={$dashboard.dashboard.selectedPanel}
          />
        )}
        <ShareViewDialog
          isOpen={shareDialogOpen}
          model={selectedModel}
          setModel={(model) => this.setSelectedModel(model)}
          onClose={this.onClose}
          defaultName={dashTitle}
          defaultDescription={dashDesc}
          link_type={$sharedLinks.types.dashboards}
          dashboard={dashboard}
        />
        <SubscribeDialog
          isOpen={subscribeDialogOpen}
          model={selectedModel}
          setModel={(model) => this.setSelectedModel(model)}
          onClose={this.onClose}
          subscriptionDialogType="subscribe"
        />
        <SubscribeDialog
          isOpen={unsubscribeDialogOpen}
          model={selectedModel}
          setModel={(model) => this.setSelectedModel(model)}
          onClose={this.onClose}
          subscriptionDialogType="unsubscribe"
        />
        {dashDesc?.length > 0 && (
          <DescriptionDialog
            title="Dashboard Description"
            isOpen={descriptionDialogOpen}
            onClose={this.handleCloseDescriptionDialog}
            description={dashDesc}
          />
        )}
      </Page>
    );
  }
}

export default DashboardView;
