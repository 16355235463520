import React from 'react';
import Flex from 'core/components/Flex';
import Box from 'core/components/Box';
import Spinner from 'core/components/Spinner';

const SpinnerBody = () => (
  <Flex className="tr loading">
    <Box flex={1} p={4}>
      <Spinner />
    </Box>
  </Flex>
);

export default SpinnerBody;
