import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Box, Flex, Heading, SmallCaps, Text, Button } from 'core/components';
import FavoriteButton from 'app/views/core/FavoriteButton';
import { TEST_TYPES } from 'shared/synthetics/constants';
import { getTestTypeLabel } from 'app/views/synthetics/utils/syntheticsUtils';
import FrequencyLabel from 'app/views/synthetics/components/FrequencyLabel';
import TestNotesPopover from 'app/views/synthetics/components/TestNotesPopover';
import DatePicker from 'app/views/synthetics/components/DatePicker';
import AggregationTag from 'app/views/synthetics/components/AggregationTag';

@inject('$auth', '$syn')
@withRouter
@observer
class TestResultsPageHeader extends Component {
  state = {
    submitting: false,
    rerunning: false
  };

  onDateRangeChange = (opts) => {
    const { test } = this.props;
    test.results.onDateRangeChange(opts);
  };

  handleSubmit = () => {
    const { $syn, test, history } = this.props;
    const config = test.get('config');

    this.setState({ submitting: true });
    test.save({ config: { ...config }, test_status: 'A' }).then(
      () => {
        const { id } = test;
        $syn.tests.add({ ...test.get(), id });
        history.push('/v4/synthetics/tests');
      },
      () => this.setState({ submitting: false })
    );
  };

  handleRerun = () => {
    const { test, history } = this.props;
    const config = test.get('config');

    this.setState({ rerunning: true });
    test.save({ config: { ...config }, test_status: 'I' }).then(
      () => history.go(0),
      () => this.setState({ rerunning: false })
    );
  };

  render() {
    const { $syn, test, match } = this.props;
    const { submitting, rerunning } = this.state;
    const { lookbackSeconds, minLookbackSeconds, actualStartDate, actualEndDate, targetValue, displayOptions } =
      test.results;
    const loadingAgents = $syn.agents.loading;
    const loadingSynthPlan = $syn.plan.loading;
    const showTargetValueWithDisplayName = [
      TEST_TYPES.HOSTNAME,
      TEST_TYPES.IP_ADDRESS,
      TEST_TYPES.DNS,
      TEST_TYPES.PAGE_LOAD,
      TEST_TYPES.URL
    ].includes(test?.get('test_type'));
    const testName = test.get('display_name');

    return (
      <Flex justifyContent="space-between" alignItems="center">
        {!displayOptions.hideTitle && (
          <Box whiteSpace="nowrap" overflow="hidden" mb={1} height={50}>
            {test && (
              <>
                <Flex gap={1}>
                  <SmallCaps muted fontSize={12}>
                    {getTestTypeLabel(test?.get('test_type'))}
                  </SmallCaps>
                  {showTargetValueWithDisplayName && (
                    <Text muted small>
                      {targetValue}
                    </Text>
                  )}
                  <FrequencyLabel test={test} />
                </Flex>
                <Flex alignItems="center" gap="4px">
                  <FavoriteButton
                    type="page"
                    id={match.url}
                    name={testName}
                    metadata={{ resultType: 'syntheticTest' }}
                  />
                  <Heading level={1} mb={0} overflow="hidden" style={{ textOverflow: 'ellipsis' }} title={testName}>
                    {testName}
                  </Heading>
                  <TestNotesPopover test={test} />

                  <AggregationTag testResults={test.results} ml={1} minWidth="fit-content" />
                </Flex>
              </>
            )}
          </Box>
        )}
        {test.isPreview ? (
          <Flex flexDirection="column" alignItems="flex-end">
            <Flex mb={1}>
              <Button
                mr="8px"
                text="Run Preview Again"
                onClick={this.handleRerun}
                loading={rerunning}
                disabled={submitting || rerunning}
              />
              <Button
                intent="primary"
                text="Save Test"
                onClick={this.handleSubmit}
                loading={submitting}
                disabled={submitting || rerunning}
              />
            </Flex>
            {!loadingAgents && !loadingSynthPlan && (
              <Text
                as="div"
                small
              >{`Saving test will use ${test.calculateTestCredits().toLocaleString()} credits`}</Text>
            )}
          </Flex>
        ) : (
          <DatePicker
            ml={2}
            whiteSpace="nowrap"
            test={test}
            minLookbackSeconds={minLookbackSeconds}
            lookbackSeconds={lookbackSeconds}
            startDate={actualStartDate}
            endDate={actualEndDate}
            onChange={this.onDateRangeChange}
          />
        )}
      </Flex>
    );
  }
}

export default TestResultsPageHeader;
