/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { observer } from 'mobx-react';
import { Flex, Heading, Spinner, Tabs, Tab, Tag, Text, Tooltip } from 'core/components';
import {
  AgentsPanel,
  BGPPanel,
  GridPanel,
  HealthAggPanel,
  MapPanel,
  MeshPanel,
  PathPanel,
  SankeyPanel
} from './panels';

@observer
export default class DataTabs extends React.Component {
  get gridTitle() {
    const { test } = this.props;

    if (test.isHighDensityGrid) {
      let targetCount = 0;
      let targetCountLabel = '';
      const targets = test.isDns ? test.get('config.servers') : test.get('config.target.value');

      if (typeof targets === 'string') {
        targetCount = targets.split(',').length;
        targetCountLabel = `${targetCount} Target${targetCount === 1 ? '' : 's'}`;

        return (
          <Flex gap={1}>
            <Text>Grid</Text>
            <Tooltip content={targetCountLabel}>
              <Tag round minimal>
                {targetCount}
              </Tag>
            </Tooltip>
          </Flex>
        );
      }
    }

    return 'Grid';
  }

  handleTabChange = (nextTabId) => {
    const { test } = this.props;

    // update test results tab state
    test.results.onPageTabChange(nextTabId);
  };

  get tabs() {
    const { test, tabs, onChangeResultTimeMs, onChangeTraceResultTimeMs, onMapLoad } = this.props;
    const {
      loading,
      loadingTraceResults,
      previewPollingTestResults,
      previewPollingTraceResults,
      displayOptions,
      traceroutes
    } = test.results;
    const { allowMesh, allowSankey, allowHealthAgg, allowPath, allowBgp, allowMap } = displayOptions;

    return (
      tabs || [
        {
          id: 'mesh',
          title: 'Mesh',
          panel: () => <MeshPanel test={test} />,
          loading: loading || previewPollingTestResults,
          show: allowMesh && test.isMesh
        },

        {
          id: 'sankey',
          title: 'Sankey',
          panel: () => <SankeyPanel test={test} />,
          loading,
          show: allowSankey && test.isFlowBased
        },

        {
          id: 'healthAgg',
          title: 'Time Series',
          panel: () => <HealthAggPanel test={test} onChangeResultTimeMs={onChangeResultTimeMs} />,
          loading,
          show: allowHealthAgg
        },

        {
          id: 'agents',
          title: 'Agents',
          panel: () => <AgentsPanel test={test} />,
          loading: loading || previewPollingTestResults,
          show: true
        },

        {
          id: 'grid',
          title: this.gridTitle,
          panel: () => <GridPanel test={test} />,
          loading: loading || previewPollingTestResults,
          show: test.isHighDensityGrid
        },

        {
          id: 'path',
          title: 'Path View',
          panel: () => (
            <PathPanel
              test={test}
              testResults={test.results}
              traceroutes={traceroutes}
              onChangeTraceResultTimeMs={onChangeTraceResultTimeMs}
            />
          ),
          loading: loadingTraceResults || previewPollingTraceResults,
          show: allowPath
        },

        {
          id: 'bgp',
          title: 'BGP',
          panel: () => <BGPPanel test={test} />,
          loading: false,
          show: allowBgp && test.get('config.bgp')
        },

        {
          id: 'map',
          title: 'Map',
          panel: () => <MapPanel test={test} onMapLoad={onMapLoad} />,
          loading: loading || previewPollingTestResults,
          show: allowMap
        }
      ]
    ).filter(({ show }) => show);
  }

  /*
    Attempts to honor the currently selected tab id the best it can
    by checking against the list of valid tab ids for the current test type.

    If we don't have a match, then we fallback to the first tab in the list.

    Although test types can share tab types between them such as 'agents', we also
    have tabs that are specific to a test type such as 'mesh'. This is one of the main reasons
    we use this approach.
  */
  get selectedTabId() {
    const { test } = this.props;
    const { pageTabId } = test.results;
    const validTabIds = this.tabs.map(({ id }) => id);

    if (validTabIds.includes(pageTabId)) {
      return pageTabId;
    }

    return validTabIds[0];
  }

  getTitle({ title, loading }) {
    return (
      <Flex width="100%" justifyContent="space-between">
        <Text>{title}</Text>
        {loading && <Spinner size={12} />}
      </Flex>
    );
  }

  render() {
    return (
      <>
        <Heading level={6}>Data</Heading>
        <Tabs
          selectedTabId={this.selectedTabId}
          onChange={this.handleTabChange}
          renderActiveTabPanelOnly
          flexed
          vertical
        >
          {this.tabs.map(({ id, title, loading, panel }) => (
            <Tab key={id} id={id} title={this.getTitle({ title, loading })} panel={panel()} />
          ))}
        </Tabs>
      </>
    );
  }
}
