import Validator from 'validatorjs';

import { generateFilterFields, generateTimeFields } from './helpers';

Validator.register(
  'customDrillDownParametric',
  function customDrillDownParametric(value) {
    if (value === 'drillDown') {
      const { model: dashboardItem, destination_dashboard } = this.validator.input;
      if (destination_dashboard) {
        const destinationDashboard = dashboardItem.get('dashboard').collection.get(destination_dashboard);

        if (dashboardItem.queryBuckets.length && destinationDashboard && destinationDashboard.get('parametric')) {
          const parametric_fields = destinationDashboard.get('parametric_fields');
          const [field] = parametric_fields;

          return dashboardItem.canMapToParametricKey(field);
        }
      }
    }

    return true;
  },
  "This panel has dimensions which are incompatible with the destination view's guided settings."
);

Validator.register(
  'maxGridGroupTestIds',
  function maxGridGroupTestIds(value) {
    const { synth_test_display } = this.validator.input;
    if (synth_test_display === 'grid-group' && Array.isArray(value)) {
      return value.length < 9;
    }
    return true;
  },
  'Please select 8 tests or less.' // we can change this as needed if grid table columns get too narrow
);

const nestedDashboardFields = [
  'destination_dashboard',
  'dashboard_navigation',
  'dashboard_navigation_options.parametric',
  'dashboard_navigation_options.parametric_value',
  'dashboard_navigation_options.devices',
  'dashboard_navigation_options.time_range',
  'dashboard_navigation_options.filters',
  'dashboard_navigation_options.custom',

  ...Object.keys(generateTimeFields('dashboard_navigation_options.custom')),
  ...Object.keys(generateFilterFields('dashboard_navigation_options.custom.filters')),

  'dashboard_navigation_options.custom.filters',
  'dashboard_navigation_options.custom.device_name',
  'dashboard_navigation_options.custom.all_devices',
  'dashboard_navigation_options.custom.device_types',
  'dashboard_navigation_options.custom.device_sites',
  'dashboard_navigation_options.custom.device_labels'
];

const options = {
  name: 'Dashboard Item',
  groups: {
    item: ['panel_title', 'dashboard_id', 'filter_source', 'device_locked', 'time_locked', 'panel_filtering'],
    parametric: ['parametric_mode', 'parametric_overrides.filterField', 'parametric_overrides.operator'],
    nestedDashboard: nestedDashboardFields,

    // will be populated when a DashboardItemForm is edited, and it's Dataview fields are inserted here.
    dataview: []
  }
};

const fields = {
  panel_title: {
    label: 'Title',
    rules: 'required'
  },

  panel_type: {},

  dashboard_id: {
    label: 'Dashboard',
    rules: 'required'
  },

  synth_test_id: {
    label: 'Synthetic Test(s)',
    rules: [
      'required_if:synth_test_display,aggregate-label',
      'required_if:synth_test_display,grid',
      'required_if:synth_test_display,network-grid',
      'required_if:synth_test_display,grid-group',
      'required_if:synth_test_display,mesh',
      'maxGridGroupTestIds'
    ],
    messages: { required_if: 'The Synthetic Test/Label(s) field is required.' }
  },

  synth_panel_type: {
    label: 'Panel Type',
    placeholder: 'Single or Multiple Test...',
    options: [
      { label: 'Single Test', value: 'single' },
      { label: 'Multiple Test', value: 'multi' }
    ],
    rules: ['required_if:panel_type,synth_test'],
    messages: { required_if: 'The Panel Type field is required.' }
  },

  synth_test_display: {
    placeholder: 'Select a display type...',
    label: 'Display Type',
    rules: ['required_if:panel_type,synth_test'],
    messages: { required_if: 'The Display Type field is required.' }
  },

  synth_test_type: {
    label: 'Test Type',
    placeholder: 'Select a test type...',
    rules: [
      'required_if:synth_test_display,grid',
      'required_if:synth_test_display,network-grid',
      'required_if:synth_test_display,mesh',
      'required_if:synth_test_display,aggregate-label',
      'required_if:synth_test_display,gauge-min',
      'required_if:synth_test_display,gauge-max',
      'required_if:synth_test_display,gauge-avg',
      'required_if:synth_test_display,grid-group'
    ],
    messages: { required_if: 'The Test Type field is required.' }
  },

  synth_test_labels: {
    label: 'Test Label(s)',
    placeholder: 'Select a test label...',
    rules: ['required_if:synth_test_display,availability'],
    messages: { required_if: 'The Test Label(s) field is required.' }
  },

  synth_test_metric: {
    label: 'Metric Type',
    placeholder: 'Select a metric...',
    rules: [
      'required_if:synth_test_display,aggregate-label',
      'required_if:synth_test_display,gauge-min',
      'required_if:synth_test_display,gauge-max',
      'required_if:synth_test_display,gauge-avg'
    ],
    messages: { required_if: 'The Metric Type field is required.' }
  },

  panel_description: {
    label: 'Description'
  },

  filter_source: {
    label: 'Filter Setting',
    defaultValue: 'additive',
    options: [
      {
        value: 'dashboard',
        label: 'Controlled by Dashboard',
        iconName: 'grid-view',
        helpText:
          "The filters represented in the panel's graph are dynamic; they will change whenever the default filters specified in the Filters Pane of the dashboard are changed."
      },
      {
        value: 'additive',
        label: 'Additive',
        iconName: 'insert',
        helpText:
          'The graph will always represent the filters that are applied in the Filters pane at the time that the panel is edited and saved.'
      },
      {
        value: 'locked',
        label: 'Locked to Specific Filters',
        iconName: 'lock',
        helpText:
          "The filters represented in the panel's graph are locked to a specific filter set that will never be altered by the view."
      }
    ]
  },

  device_locked: {
    label: 'Device Locking',
    defaultValue: false,
    options: [
      {
        value: false,
        label: 'Controlled by View',
        iconName: 'grid-view',
        helpText:
          "The devices represented in this panel's graph are dynamic; they will change whenever the Devices Pane of the view is changed."
      },
      {
        value: true,
        label: 'Locked to Specific Devices',
        iconName: 'lock',
        helpText:
          "The devices represented in the panel's graph are static; they will not change when the default devices for the view are changed."
      }
    ]
  },

  parametric_mode: {
    label: 'Guided Mode',
    defaultValue: 'add_group',
    options: [
      {
        label: 'Override all filters',
        helpText:
          "This panel's existing filters will be ignored, and a Filter Group that contains the Guided Mode value will be created",
        value: 'override_all'
      },
      {
        label: 'Override specific filters',
        value: 'override_specific',
        helpText:
          "This panel's existing filters will remain unchanged, and any existing Filters that contain overridable fields will be set to the Guided Mode value"
      },
      {
        label: 'Add filter group',
        helpText:
          "This panel's existing filters will remain unchanged, and a new Filter Group that contains the View's Guided Mode value will be added.",
        value: 'add_group'
      },
      {
        label: 'Ignore',
        helpText: 'This panel will ignore the Guided Mode value and retains all existing Filters.',
        value: 'ignore'
      }
    ]
  },

  'parametric_overrides.filterField': {
    label: 'Dimension'
  },

  'parametric_overrides.operator': {
    label: 'Operator'
  },

  time_locked: {
    label: 'Time Locking',
    defaultValue: false,
    options: [
      {
        value: false,
        label: 'Controlled by View',
        iconCls: 'grid-view',
        helpText: "The time range represented in this panel's graph will be controlled by the view."
      },
      {
        value: true,
        label: 'Locked to Specific Lookback Timeframe',
        iconCls: 'lock',
        helpText:
          "The time range represented in this panel's graph will be controlled by the panel's lookback timeframe."
      }
    ]
  },

  panel_filtering: {
    label: 'Cross-Panel Filtering',
    helpText:
      'Selecting one or more series in a panel will filter all other panels accordingly. Filtering cannot be initiated from Sankey, Map, Matrix and Gauge visualizations at this time.',
    defaultValue: false,
    options: [
      {
        value: false,
        label: 'Disabled'
      },
      {
        value: true,
        label: 'Enabled'
      }
    ]
  },

  dashboard_navigation: {
    label: 'Dashboard Navigation',
    options: [
      {
        value: false,
        label: 'Disabled'
      },
      {
        value: true,
        label: 'Enabled'
      }
    ]
  },

  destination_dashboard: {
    label: 'Destination Dashboard',
    defaultValue: undefined
  },
  'dashboard_navigation_options.parametric': {
    defaultValue: 'prompt',
    rules: 'customDrillDownParametric'
  },
  'dashboard_navigation_options.parametric_value': {},
  'dashboard_navigation_options.devices': {
    defaultValue: 'source_dashboard'
  },
  'dashboard_navigation_options.time_range': {
    defaultValue: 'source_dashboard'
  },
  'dashboard_navigation_options.filters': {
    defaultValue: 'source_dashboard'
  },
  'dashboard_navigation_options.custom': {},

  ...generateTimeFields('dashboard_navigation_options.custom'),
  'dashboard_navigation_options.custom.filters': {},
  ...generateFilterFields('dashboard_navigation_options.custom.filters'),

  'dashboard_navigation_options.custom.device_name': {
    label: 'device',
    defaultValue: [],
    transform: {
      in: (value) => {
        if (Array.isArray(value)) {
          return value;
        }
        if (value && typeof value === 'string') {
          return value.split(',');
        }
        return [];
      }
    }
  },
  'dashboard_navigation_options.custom.all_devices': {
    label: 'Devices',
    defaultValue: false,
    transform: {
      in: (value) => {
        if (value === true || value === false) {
          return value;
        }
        return false;
      }
    }
  },

  'dashboard_navigation_options.custom.device_labels': {
    defaultValue: []
  },
  'dashboard_navigation_options.custom.device_sites': {
    defaultValue: []
  },
  'dashboard_navigation_options.custom.device_types': {
    defaultValue: []
  },

  'dashboard_navigation_options.nesting.drillDownButtonText': {
    label: 'Drill Down Button Text',
    defaultValue: 'Drill Down',
    rules: 'required'
  }
};

const itemExplorerFields = {
  device_locked: Object.assign({}, fields.device_locked, {
    transform: { in: (value) => !value, out: (value) => !value }
  }),
  time_locked: Object.assign({}, fields.time_locked, { transform: { in: (value) => !value, out: (value) => !value } }),
  filter_source: fields.filter_source
};

export { fields, options, itemExplorerFields };
