import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { isEmpty } from 'lodash';
import { FiExternalLink } from 'react-icons/fi';

import { Icon, Link } from 'core/components';
import { Field, formConsumer } from 'core/form';
import { LabelMultiSelect } from 'app/components/labels/LabelMultiSelect';
import AddLabelDialog from './AddLabelDialog';

@inject('$auth', '$labels')
@withRouter
@formConsumer
class LabelSelector extends Component {
  state = {
    showEditDialog: false
  };

  componentDidMount() {
    const { $labels, form, fieldName = 'config.labels', model, location } = this.props;
    const fieldState = form.getField(fieldName);
    const selectedLabels = $labels.getLabelsFromModelOrLocation(model, location).map((label) => label.id);
    if (!isEmpty(selectedLabels)) {
      fieldState.init(selectedLabels);
    }
  }

  handleOpenDialog = () => {
    this.setState({ showEditDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ showEditDialog: false });
  };

  render() {
    const { $auth, $labels, fieldName = 'config.labels', form, useDialog, ...props } = this.props;
    const { showEditDialog } = this.state;

    if ($auth.isAdministrator) {
      const fieldConfig = form.fieldConfigs.find((config) => config.name === fieldName) || {};
      const { label = 'Labels' } = fieldConfig;
      Object.assign(props, {
        label: (
          <>
            {label}
            <Link
              {...(useDialog ? { onClick: this.handleOpenDialog } : { to: '/v4/settings/labels' })}
              small
              pl={1}
              style={{ fontWeight: 'normal' }}
            >
              {useDialog ? 'Add Label' : 'Add / Edit'}
              <Icon icon={FiExternalLink} iconSize={12} color="inherit" pl="2px" pb="2px" />
            </Link>
          </>
        )
      });
    }
    return (
      <>
        <Field name={fieldName} large options={$labels.options} {...props}>
          <LabelMultiSelect tagInput />
        </Field>
        {useDialog && $auth.isAdministrator && (
          <AddLabelDialog isOpen={showEditDialog} onClose={this.handleCloseDialog} />
        )}
      </>
    );
  }
}

export default LabelSelector;
