import * as React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Box, Flex, Icon, Text } from 'core/components';
import { Field, Form, Select } from 'core/form';

const formFields = {
  asset: {}
};

const formOptions = {
  name: 'Quick View Selector Form'
};

// params from the quick-views route matched against a lookup query
const QUICK_VIEW_LOOKUP_QUERY = {
  application: 'applications',
  asn: 'asNumbers',
  country: 'countries',
  devices: 'devices',
  provider: 'providers',
  sites: 'sites'
};

const QUICK_VIEW_MENU_WIDTH = {
  application: 450,
  asn: 450,
  country: 350
};

const pathnamePrefixes = ['/v4/core/quick-views', '/v4/infrastructure'];
const pathnameRegExp = new RegExp(`(${pathnamePrefixes.map((path) => `${path}/`).join('|')})`);

// ensure white text in a selected state since lookups can return their own label component
// this is done at the select item label because select popovers use portals
// so we don't have the luxury of inherited styles
const SelectItemWrapper = styled.div`
  ${({ selected }) => {
    if (selected) {
      return '* { color: #fff !important; }';
    }

    return null;
  }}
`;

@Form({ fields: formFields, options: formOptions })
@inject('$lookups')
@withRouter
@observer
export default class QuickViewSelector extends React.Component {
  state = {
    assetType: null,
    assetValue: null,
    assetLookup: null
  };

  static getDerivedStateFromProps(props) {
    const { $lookups, location } = props;

    if (location && location.pathname) {
      const path = location.pathname.replace(pathnameRegExp, '');
      const [assetType, assetValue] = path.split('/');

      if (assetType && assetValue) {
        const query = QUICK_VIEW_LOOKUP_QUERY[assetType];

        if (query && $lookups[query]) {
          // ensure the asset type is valid and matches a lookup query
          return {
            assetType,
            assetValue,
            assetLookup: $lookups[query]
          };
        }
      }
    }

    return null;
  }

  componentDidMount() {
    const { form } = this.props;
    const { assetValue } = this.state;

    if (assetValue) {
      form.setValue('asset', assetValue);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { form } = this.props;
    const { assetValue } = this.state;

    if (assetValue && prevState.assetValue !== assetValue) {
      form.setValue('asset', assetValue);
    }
  }

  valueRenderer = (option, placeholder) => (
    <Flex alignItems="center">
      <Box overflow="hidden">
        <Text as="div" fontWeight="bold" small ellipsis>
          {(option && option.label) || placeholder || 'Select a value...'}
        </Text>
      </Box>
      <Icon icon="caret-down" iconSize={16} ml="4px" mr="-2px" />
    </Flex>
  );

  optionFormatter = (option) => <SelectItemWrapper selected={option.selected}>{option.label}</SelectItemWrapper>;

  handleQuickViewChange = (field) => {
    const { history, location } = this.props;
    const { assetType } = this.state;
    const prefix = pathnamePrefixes.find((p) => location.pathname.startsWith(p)) || pathnamePrefixes[0];

    history.push({
      ...history.location,
      pathname: `${prefix}/${assetType}/${field.getValue()}`
    });
  };

  render() {
    const { fallbackContent } = this.props;
    const { assetLookup, assetType, assetValue } = this.state;

    if (assetLookup) {
      return (
        <Field
          name="asset"
          onQuery={assetLookup}
          showLabel={false}
          mb={0}
          onChange={this.handleQuickViewChange}
          placeholder={assetValue}
          large
        >
          <Select
            showFilter
            valueRenderer={this.valueRenderer}
            optionFormatter={this.optionFormatter}
            width="auto"
            menuWidth={QUICK_VIEW_MENU_WIDTH[assetType]}
            buttonStyle={{ rightIcon: null }}
            minimal
          />
        </Field>
      );
    }

    if (fallbackContent) {
      return fallbackContent;
    }

    return null;
  }
}
