import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Dialog from 'core/components/Dialog';
import FormComponent from 'core/form/components/FormComponent';
import FormActions from 'core/form/components/FormActions';

@withRouter
@observer
class FormDialog extends Component {
  static defaultProps = {
    showFormActions: true
  };

  get title() {
    const { title, model, entityName } = this.props;
    const isNew = model && model.isNew;
    return title || `${isNew ? 'Add' : 'Edit'} ${entityName}`;
  }

  get icon() {
    const { model } = this.props;
    const isNew = model && model.isNew;
    return isNew ? 'add-to-artifact' : 'edit';
  }

  handleCancel = () => {
    const { onClose, model, collection, history, rootUrl } = this.props;

    if (onClose) {
      onClose();
      return;
    }

    if (collection) {
      collection.clearSelection();
    }

    if (model) {
      model.deselect();
    }

    if (rootUrl) {
      history.push(rootUrl);
    }
  };

  render() {
    const {
      form,
      fields,
      options,
      model,
      isOpen,
      formActionsProps,
      canOutsideClickClose,
      children,
      entityName,
      formComponentProps,
      showFormActions,
      dialogBodyProps,
      title,
      ...rest
    } = this.props;

    return (
      <FormComponent form={form} fields={fields} options={options} model={model} {...formComponentProps}>
        {(formProps) => (
          <Dialog
            isOpen={isOpen}
            title={this.title}
            canOutsideClickClose={canOutsideClickClose}
            onClose={this.handleCancel}
            iconName={this.icon}
            top={40}
            width={1100}
            position="fixed"
            {...rest}
          >
            <Dialog.Body {...dialogBodyProps}>
              {typeof children === 'function' ? children(formProps) : children}
            </Dialog.Body>
            {showFormActions && (
              <Dialog.Footer>
                <FormActions
                  entityName={entityName}
                  onCancel={this.handleCancel}
                  {...formActionsProps}
                  {...formProps}
                  large={false}
                />
              </Dialog.Footer>
            )}
          </Dialog>
        )}
      </FormComponent>
    );
  }
}

export default FormDialog;
