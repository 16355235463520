import { persistHash } from 'app/stores/query/urlHash';

export function generateSpoofUrl($auth, options) {
  const { persist = false, match } = options || {};
  const { search, pathname, origin } = window.location;
  const companyId = $auth.getActiveUserProperty('company_id');
  const userId = $auth.getActiveUserProperty('id');
  const updatedSearch = search ? `&${search.substring(1)}` : '';

  // Spoof links should persist hashes if they're present
  if (persist && match) {
    const { urlHash, hash, queryHash } = match.params || {};
    if (urlHash || hash || queryHash) {
      persistHash(urlHash || hash || queryHash);
    }
  }

  return `${origin}/v4/spoof/${companyId}/${userId}?redirectUrl=${encodeURIComponent(pathname)}${updatedSearch}`;
}
