/*
FROM Aaron K (need to confirm)
  ranger = Metrics
  ksynth = Synthetics
  kproxy = Traffic
  kbgp = BGP
  kappa = Kubernetes
  kubeinv = Kubernetes topology
*/

const KAPPA = 'kappa';
const KBGP = 'kbgp';
const KDNS = 'kdns';
const KPROXY = 'kproxy';
const KSNMPTRAP = 'ksnmptrap';
const KSYNTH = 'ksynth';
const KSYSLOG = 'ksyslog';
const OTT = 'ott';
const RANGER = 'ranger';

// NOT YET
const KUBEINV = 'kubeinv';
const KDUMMY = 'kdummy';

// Danger Will Robinson - will be designated as "internal" in the near future
const RMT_CTL = 'rmtctl';

const CAPABILITY_TITLES = {
  kappa: 'Kubernetes (AKA kappa)',
  kbgp: 'BGP Proxy (AKA kbgp)',
  kdummy: 'Dummy Capability',
  kdns: 'DNS OTT Tap',
  kproxy: 'Flow Proxy',
  ksnmptrap: 'SNMP Trap Receiver',
  ksynth: 'Synthetics (AKA ksynth)',
  ksyslog: 'Syslog Server',
  kubeinv: 'Kubernetes topology',
  ranger: 'NMS'
};

const CAPABILITY_DESCRIPTIONS = {
  kappa: 'Monitor Kubernetes clusters (cloud or on premises) using eBPF and securely transport to Kentik SaaS cluster',
  kbgp: 'Proxy local iBGP Route Reflector Client session from multiple hosts and multiplex them securely to Kentik SaaS cluster',
  kdns: 'Upon installation on a DNS server, collect and export DNS Query Responses to match against Flow Telemetry for OTT detection',
  kproxy:
    'Deploy this Agent Capability as an on-prem relay to export Network Flow  Telemetry (Netflow5,9, SFlow, IPFIX) ' +
    'from multiple registered devices to Kentik: point flow exports from your devices to this Agent IP and it will ' +
    'multiplex flow records securely to your KentiK SaaS Cluster.',
  ksnmptrap: 'Receive SNMP Traps and securely transport them to Kentik',
  ksynth: 'Turns host into Kentik Synthetics Agent to perform Network, HTTP and DNS synthetic tests',
  ksyslog: 'Provides syslog server that transports logs to Kentik',
  ranger: 'Base layer of network visibility including up/down, interface stats, and hardware components',

  // NOT YET:
  kubeinv:
    'Monitoring and analyzing DNS activity to ensure availability, performance, and security of the DNS infrastructure',
  kdummy: 'Test Capability'

  // Is these a thing?
  // Understand traffic and telemetry across clouds, data centers, edge, SaaS, WAN, and SD-WAN
  // Process and analysis of data from network devices, providing valuable insights for monitoring and troubleshooting
};

// GUESSES - NO?
const SYNTHETICS = 'Synthetics';

const NOT_AUTHORIZED = 'Not Authorized';
const RUNNING = 'Running';
const DOWN = 'Down';
const DISABLED = 'Disabled';
const WARNING = 'Warning';
const UP = 'Up';
const PENDING = 'Pending';

module.exports = {
  AGENT_HEALTH: {
    AGENT_HEALTH_STATE_HEALTHY: 'AGENT_HEALTH_STATE_HEALTHY',
    AGENT_HEALTH_STATE_UNHEALTHY: 'AGENT_HEALTH_STATE_UNHEALTHY',
    AGENT_HEALTH_STATE_OFFLINE: 'AGENT_HEALTH_STATE_OFFLINE'
  },
  CAPABILITY_TITLES,
  CAPABILITY_DESCRIPTIONS,
  CAPABILITY_HEALTH: {
    CAPABILITY_HEALTH_STATE_HEALTHY: 'CAPABILITY_HEALTH_STATE_HEALTHY',
    CAPABILITY_HEALTH_STATE_UNHEALTHY: 'CAPABILITY_HEALTH_STATE_UNHEALTHY',
    CAPABILITY_HEALTH_STATE_DISABLED: 'CAPABILITY_HEALTH_STATE_DISABLED'
  },
  CAPABILITY_KEYS: {
    KAPPA,
    KBGP,
    KDNS,
    KDUMMY,
    KPROXY,
    KSNMPTRAP,
    KSYNTH,
    KSYSLOG,
    KUBEINV,
    OTT,
    RANGER
  },
  Capabilities: {
    // maybe call Capability_Keys? replacing with above is good
    KAPPA,
    KBGP,
    KDNS,
    KDUMMY,
    KPROXY,
    KSNMPTRAP,
    KSYNTH,
    KSYSLOG,
    KUBEINV,
    OTT,
    RANGER,
    RMT_CTL, // NEVER DISABLE
    // the guesses
    SYNTHETICS
  },
  Statuses: {
    NOT_AUTHORIZED,
    RUNNING,
    DISABLED,
    DOWN,
    WARNING,
    PENDING,
    UP
  },
  CAPABILITIES_DEFAULT_CONFIGS: {
    [KDNS]: {
      args: {
        interface: 'any',
        fanoutCount: '4',
        enableCompression: true
      }
    },
    [KPROXY]: {
      args: {
        interface: 'any',
        fanoutCount: '4'
      }
    },
    [KSNMPTRAP]: {
      args: {}
    },
    [KSYSLOG]: {
      args: {}
    }
  },
  CAPABILITIES_W_CONFIGS: [KDNS, KPROXY, KSNMPTRAP, KSYSLOG]
};
