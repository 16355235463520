import React from 'react';

import { Box, Text, LinkButton } from 'core/components';
import $devices from 'app/stores/device/$devices';
import $dictionary from 'app/stores/$dictionary';

import AbstractDependency, { queryContains } from './AbstractDependency';

let queryConstraints;

function getConstraints() {
  const aggregates = $dictionary.get('aggregates');

  if (!queryConstraints) {
    const tcpFields = Object.values(aggregates.TCP);
    const httpFields = Object.values(aggregates.HTTP);
    const dnsFields = Object.values(aggregates.DNS);
    const allFields = tcpFields.concat(httpFields).concat(dnsFields).flat();
    const aggregateTypes = allFields.map((field) => field.value);

    queryConstraints = {
      metrics: [],
      filterFields: [],
      aggregateTypes
    };
  }

  return queryConstraints;
}

class KProbeDependency extends AbstractDependency {
  get key() {
    return 'kprobe';
  }

  get constraints() {
    return getConstraints();
  }

  get preventQuery() {
    return true;
  }

  get meets() {
    return $devices.hasDnsProbe;
  }

  get message() {
    return (
      <>
        <Box mb="4px">
          <Text>
            <strong>Kprobe</strong> devices are required by metrics you&apos;re using, but have not been detected for
            any devices in your organization. Results shown herein may be highly inaccurate.
          </Text>
        </Box>
        <LinkButton text="Configure" icon="cog" small to="/v4/settings/devices" />
      </>
    );
  }

  get exceptions() {
    const aggregates = $dictionary.get('aggregates');
    const tcpAggregates = Object.values(aggregates.TCP)
      .flat()
      .map((field) => field.value);

    return [
      {
        constraints: {
          metrics: [],
          filterFields: ['client_nw_latency_ms'],
          aggregateTypes: [
            'avg_appl_latency_ms',
            'p98th_appl_latency_ms',
            'max_appl_latency_ms',
            'avg_client_latency_ms',
            'p98th_client_latency_ms',
            'max_client_latency_ms'
          ]
        },
        meets: $devices.hasGCEDevice
      },
      {
        constraints: {
          metrics: [],
          filterFields: [],
          aggregateTypes: tcpAggregates
        },
        meets: $devices.hasKappaDevice
      }
    ];
  }

  static queryMeets(query) {
    return !queryContains(query, getConstraints()) || $devices.hasDnsProbe;
  }
}

export default KProbeDependency;
