import React from 'react';
import { PopoverInteractionKind, Position } from '@blueprintjs/core';
import { Box, Flex, Icon, Popover, Text } from 'core/components';
import { FiInfo } from 'react-icons/fi';

export default ({ error, isAggregated = false }) => (
  <Popover
    position={Position.BOTTOM}
    minimal={false}
    interactionKind={PopoverInteractionKind.HOVER}
    content={
      <Box p={2} maxWidth={500}>
        <Flex alignItems="start">
          <Text as="div" small>
            {error}
          </Text>
        </Flex>
      </Box>
    }
  >
    <Icon
      style={{ cursor: 'pointer' }}
      iconSize={14}
      icon={FiInfo}
      color={isAggregated ? 'muted' : 'danger'}
      ml="4px"
    />
  </Popover>
);
