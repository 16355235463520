import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { hexToRgbA } from 'core/util/colors';
import Box from 'core/components/Box';
import Button from 'core/components/Button';
import Flex from 'core/components/Flex';
import { getCellStyle } from './Cell';

const GroupSummaryCell = observer((props) => {
  const { column, index, expandedRowRenderer, groupSummary, onToggle, isExpanded } = props;
  const { className, name } = column;
  // if we have rows with expanded details, we will start to look for columns with totals beyond the second column instead
  const rootColumnIndex = expandedRowRenderer ? 1 : 0;
  const icon = isExpanded ? 'caret-down' : 'caret-right';

  let value =
    index === 0 ? (
      <Fragment>
        <Button icon={icon} mr={1} onClick={onToggle} minimal />
        <Box>{groupSummary.label || 'Unassigned'}</Box>
      </Fragment>
    ) : (
      ''
    );

  if (index > rootColumnIndex) {
    value = groupSummary[name];
  }

  // normally the 'Total of top...' messaging would appear in the first column
  // however when rendering a table with expandable columns, we want that message to span to the first 'real' column
  const colSpan = column.name === 'row-expander' ? 2 : column.colSpan;

  return (
    <Box
      className={classNames('td', className, column.align === 'right' ? 'align-right' : 'align-left')}
      style={getCellStyle({
        ...props,
        column: {
          ...column,
          colSpan
        }
      })}
    >
      {value}
    </Box>
  );
});

const GroupRow = (props) => {
  const { className, collection, columns, groupSummaryLookup, groupKey, onToggle, isExpanded, last, style } = props;
  const { groupBy } = collection;
  const group = collection.groupedData[groupKey];

  if (!group) {
    return null;
  }

  const groupSummary = groupSummaryLookup({ groupBy: collection.groupBy, groupKey, group });

  if (!groupSummary) {
    return null;
  }

  const groupCls = classNames('tr', 'group-summary', className, { last, collapsed: !isExpanded });
  const icon = isExpanded ? 'caret-down' : 'caret-right';

  let contents = (
    <Fragment>
      <Button icon={icon} mr={1} onClick={onToggle} minimal />
      <Box className="group-summary-wrapper">{groupSummary || 'Unassigned'}</Box>
    </Fragment>
  );

  let p = '10px 16px';

  // For special case of bracket grouping, set background based on bracket color
  if (typeof groupBy === 'object' && groupBy.type && groupBy.type === 'bracketing') {
    const groupItem = collection.groupedData[groupKey][0];
    const tagDataKey = Object.keys(groupItem.toJS()).find((prop) => prop.startsWith('tag_'));
    const tagData = groupItem.get(tagDataKey);

    contents = (
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        flex={1}
        style={{ background: hexToRgbA(tagData.value).replace('1)', '.25)') }}
        p={p}
      >
        {contents}
      </Flex>
    );

    p = 0;
  } else if (groupSummary.groupType === 'summary') {
    return (
      <Flex className={groupCls} style={style}>
        {columns.map((column, idx) => (
          <GroupSummaryCell
            {...props}
            key={column.key || column.name || column.type}
            column={column}
            index={idx}
            columns={columns}
            totalRow
            models={collection.models}
            groupSummary={groupSummary}
          />
        ))}
      </Flex>
    );
  }

  if (groupSummary.renderer) {
    return (
      <Flex className={groupCls} p={p} style={style} alignItems="center" justifyContent="flex-start">
        <Button icon={icon} mr={1} onClick={onToggle} minimal />
        {groupSummary.renderer()}
      </Flex>
    );
  }

  return (
    <Flex className={groupCls} p={p} style={style} alignItems="center" justifyContent="flex-start">
      {contents}
    </Flex>
  );
};

export default observer(GroupRow);
