import { action, observable, computed } from 'mobx';
import { chunk } from 'lodash';
import Collection from './Collection';

export default class PagedCollection extends Collection {
  @observable
  pageState = { offset: 0, pageCount: 1, currentPage: 1, perPage: 10, totalCount: 0 };

  // should searchState and associated move up to Collection? Leaving here for now until searchState get solidified
  @observable
  searchState = {};

  constructor(data = [], options = {}) {
    super(data, options);
    this.processData = this.processData.bind(this);
    this.reset = this.reset.bind(this);

    this.calculatePageCount();
  }

  get pagedFetchMethod() {
    return 'post';
  }

  @action
  setPageState(state = {}) {
    this.pageState = Object.assign(this.pageState, state);
  }

  @action
  setSearchState(state = {}) {
    this.searchState = Object.assign(this.searchState, state);
  }

  @computed
  get pageModels() {
    const { page, perPage } = this.pageState;

    const chunks = chunk(this.models, perPage);
    return chunks[page];
  }

  /**
   * Just a shortcut helper for setting pageState offset and currentPage values when pagination page is selected.
   * @param page
   */
  @action
  setPage(page) {
    this.setPageState({ offset: Math.ceil((page - 1) * this.pageState.perPage), currentPage: page });
  }

  @action
  applyPageChangeFilter() {
    const { currentPage, perPage } = this.pageState;

    const paginationDiscreteFilter = {
      type: 'pagination',
      fn: (_model, index) => index >= (currentPage - 1) * perPage && index < currentPage * perPage
    };

    this.addDiscreteFilter(paginationDiscreteFilter);
    this.filter();
  }

  @action
  calculatePageCount() {
    const { perPage, currentPage } = this.pageState;
    const pageCount = Math.ceil(this.size / perPage);

    this.setPageState({ totalCount: this.size, pageCount, currentPage: currentPage > pageCount ? 1 : currentPage });
  }

  /**
   * Resets all pageState EXCEPT for perPage back to initial values
   */
  @action
  resetPageState() {
    this.setPageState({ offset: 0, pageCount: 1, currentPage: 1, totalCount: 0 });
  }

  @action
  processData(response) {
    const { metadata } = response;
    const totalCount = (metadata && metadata.totalCount) || 0;
    let pageCount = 1;
    if (totalCount) {
      pageCount = Math.ceil(totalCount / this.pageState.perPage);
    }
    this.setPageState({ totalCount, pageCount });
    return super.processData(response);
  }

  @action
  selectAll(options = {}) {
    const { pageCount } = this.pageState;
    const { selectedLimit = Number.MAX_VALUE } = options;

    if (pageCount === 1) {
      super.selectAll();
      return;
    }

    this.models.forEach((model) => {
      if (this.selectedSize >= selectedLimit) {
        return;
      }

      model.select(options);
    });
  }

  @action
  reset(options = {}) {
    this.setPageState({ offset: 0, pageCount: 1, currentPage: 1, perPage: 10, totalCount: 0 });
    this.searchState = {};
    return super.reset(options);
  }

  /**
   * Just a helper so don't have to specify search options on queuedFetch all the time.
   */
  @action
  async pagedFetch(options = {}) {
    const { data, url, httpMethod, preserveSelection, query } = options;

    const pfHttpMethod = httpMethod || this.pagedFetchMethod;
    const pfUrl = url || (this.urlPaths && this.urlPaths.pagedFetch ? this.urlPaths.pagedFetch : this.url);

    return this.queuedFetch({ data, fetchUrl: pfUrl, fetchMethod: pfHttpMethod, preserveSelection, query });
  }

  addDiscreteFilter = (discreteFilter) => {
    this.removeDiscreteFilter(discreteFilter.type);
    this.setDiscreteFilters([...this.discreteFilters, discreteFilter]);
  };
}
