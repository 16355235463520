import React from 'react';
import { observer } from 'mobx-react';

import { Flex, TabPanel } from 'core/components';

import AbstractSelect from './AbstractSelect';
import DefaultValueRenderer from './DefaultValueRenderer';
import ModalOptions from './ModalOptions';
import ModalSelectValuesPanel from './ModalSelectValuesPanel';
import SelectFilter from './SelectFilter';

@observer
class TabbedModalSelect extends AbstractSelect {
  static defaultProps = {
    canEscapeKeyClose: true,
    closeOnSelect: false,
    filterable: true,
    multi: false,
    reorderable: false,
    valueRenderer: DefaultValueRenderer,
    valuesPanelWidth: 260
  };

  state = {
    activeTabId: 'main'
  };

  handleTabChange = (tabId) => {
    this.setState({ activeTabId: tabId });
  };

  renderDialogBody() {
    const { multi, dialogRef, tabName, tabs, mainTabDisabled, valuesPanelWidth } = this.props;
    const { activeTabId } = this.state;

    let tools;
    if (activeTabId === 'main') {
      tools = (
        <Flex justifyContent="flex-end" p="3px">
          <SelectFilter {...this.props} selectState={this.selectState} disabled={mainTabDisabled} />
        </Flex>
      );
    }

    const selectValuesStyle = { width: valuesPanelWidth + 16, minWidth: valuesPanelWidth + 16, overflow: 'hidden' };

    return (
      <Flex ref={dialogRef} flex={1} flexDirection="column" overflow="hidden">
        <TabPanel
          animate={false}
          activeTabId={mainTabDisabled ? 'split-filters' : activeTabId}
          flex={1}
          onTabChange={this.handleTabChange}
          tools={tools}
        >
          <Flex
            tab={tabName}
            tabId="main"
            flexDirection="column"
            flex={1}
            overflow="hidden"
            className="modal-select-tab"
          >
            <Flex overflow="hidden" flex={1}>
              {multi && (
                <Flex flexDirection="column" pl={2} py={2} style={selectValuesStyle}>
                  <ModalSelectValuesPanel
                    {...this.props}
                    selectState={this.selectState}
                    mb={0}
                    disabled={mainTabDisabled}
                  />
                </Flex>
              )}
              <ModalOptions {...this.props} selectState={this.selectState} disabled={mainTabDisabled} padTop />
            </Flex>
          </Flex>
          {tabs}
        </TabPanel>
      </Flex>
    );
  }
}

export default TabbedModalSelect;
