import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { FormComponent, formConsumer } from 'core/form';

const fields = {};
@formConsumer
@inject('$cloudExportWizard', '$auth', '$users')
export default class AzureAutomatedSetup extends Component {
  /**
   * This is obviously just a placeholder until Azure Terraform support exists.
   */

  renderTerraformFields() {}

  render() {
    return (
      <FormComponent fields={fields} options={{ name: 'Automated Azure Setup' }}>
        {({ form: terraformForm }) => this.renderTerraformFields(terraformForm)}
      </FormComponent>
    );
  }
}
