import React, { Component, Fragment } from 'react';
import { inject } from 'mobx-react';
import { Card, Text, Flex, Heading, Box, Tag } from 'core/components';
import InlineCode from 'app/components/agent/InlineCode';

@inject('$auth')
class AgentInstallInstructions extends Component {
  getInstructionTitle({ i, instruction }) {
    return (
      <Flex alignItems="flex-start" mb={1}>
        {instruction.showNumber !== false && (
          <Tag circle mr={1}>
            {i + 1}
          </Tag>
        )}
        <Text>{instruction.text}</Text>
      </Flex>
    );
  }

  getInstructionCommand = (command) => {
    const { company_id, regionParam } = this.getCompanyAndRegion();
    return (
      <Card p={1} mt={1} mb={1} bg="appBackground">
        <InlineCode>
          {command.replace('$COMPANY_ID$', company_id).replace('$REGION_CLI_PARAM$', regionParam)}
        </InlineCode>
      </Card>
    );
  };

  getInstruction({ i, instruction, mt }) {
    const { key, text, help, command, commands } = instruction;
    return (
      <Box mt={mt} key={key}>
        {text && this.getInstructionTitle({ i, instruction })}
        {help && (
          <Card p={1} mt={1} mb={1} bg="appBackground">
            <Text monospace fontSize={12}>
              {help}
            </Text>
          </Card>
        )}
        {command && this.getInstructionCommand(command)}
        {commands &&
          commands.map((cmd, cI) => (
            <Fragment key={cmd.key}>
              {cI !== 0 && (
                <Box my={1}>
                  <Text>Or...</Text>
                </Box>
              )}
              {this.getInstructionCommand(cmd.command)}
            </Fragment>
          ))}
      </Box>
    );
  }

  buildInstruction({ i, instruction }) {
    if (instruction.instructions) {
      return (
        <Card mt={2} p={2} pb={1} key={instruction.key}>
          {instruction.text && this.getInstructionTitle({ i, instruction })}
          <Box pl="28px">
            {instruction.instructions.map((sub, sI) =>
              this.getInstruction({ i, instruction: { showNumber: false, ...sub }, mt: sI !== 0 ? 2 : 0 })
            )}
          </Box>
        </Card>
      );
    }

    return (
      <Card mt={2} p={2} pb={1} key={instruction.key}>
        {this.getInstruction({ i, instruction })}
      </Card>
    );
  }

  getCompanyAndRegion = () => {
    const { $auth } = this.props;
    const { currentRegion } = $auth;
    const company_id = $auth.getActiveUserProperty('company_id');
    const addRegionParam = currentRegion && currentRegion !== 'US';

    return { company_id, regionParam: addRegionParam ? `--region ${currentRegion.toLowerCase()}` : '' };
  };

  render() {
    const { agentName, deploymentOption } = this.props;

    return (
      <>
        <Text as="div" muted mb="2px">{`${agentName} / ${deploymentOption.name}`}</Text>
        <Heading level={6}>Follow these instructions to get started:</Heading>
        {deploymentOption.instructions.map((instruction, i) => this.buildInstruction({ i, instruction }))}
      </>
    );
  }
}

export default AgentInstallInstructions;
