import { Component } from 'react';
import { inject } from 'mobx-react';

@inject('$lookups')
class Lookup extends Component {
  state = {
    label: ''
  };

  componentDidMount() {
    this.doLookup();
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.doLookup();
    }
  }

  doLookup() {
    const { $lookups, value, lookup } = this.props;
    const lookupFn =
      $lookups[lookup] ||
      $lookups.autoCompleteDimensionHandlers[lookup] ||
      $lookups.autoCompleteFilterQueryHandlers[lookup];

    if (!lookupFn || !value) {
      this.setState({ label: value });
      return;
    }

    lookupFn(value, { debounce: false }).then((options) => {
      /* eslint-disable-next-line eqeqeq */
      const option = options.find((o) => o.value == value);

      if (option) {
        this.setState({ label: option.label });
      } else {
        this.setState({ label: value });
      }
    });
  }

  render() {
    const { value } = this.props;
    const { label } = this.state;

    return label || value || null;
  }
}

export default Lookup;
