import { alertFields } from './alertFields';
import { labelFields } from './labelFields';
import { baseBgpFields } from './baseBgpFields';

const bgpOptions = {
  name: 'BGP Monitor'
};

const bgpFields = {
  ...alertFields,
  ...labelFields,
  ...baseBgpFields,

  'config.tasks': {
    defaultValue: ['bgp-monitor']
  },

  'config.target.value': {
    defaultValue: ''
  },

  'config.agents': {
    defaultValue: []
  },

  'config.name': {
    rules: 'required',
    messages: {
      required: 'A name is required for this test.'
    }
  },

  // BGP tests need different policy activation defaults
  'config.activate.times': {
    label: 'Number of alerts',
    defaultValue: '1',
    rules: ['numeric', 'min:1']
  },
  'config.activate.timeWindow': {
    label: 'Alerts time window',
    defaultValue: '1',
    rules: ['numeric']
  },
  'config.activate.timeUnit': {
    defaultValue: 'm',
    options: [
      {
        value: 'h',
        label: 'Hours'
      },
      {
        value: 'm',
        label: 'Minutes'
      }
    ]
  },
  'config.activate.gracePeriod': {
    label: 'Alerts grace period',
    defaultValue: '15',
    rules: ['numeric']
  }
};

export { bgpOptions, bgpFields };
