import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withSize } from 'react-sizeme';
import { withTheme } from 'styled-components';
import { PopoverInteractionKind, Position } from '@blueprintjs/core';
import { event, mouse, select } from 'd3-selection';
import { interpolateYlGnBu } from 'd3-scale-chromatic';
import { zoom } from 'd3-zoom';
import { debounce } from 'lodash';
import { Box, Button, Flex, Icon, Popover, Text } from 'core/components';
import { makeText, traceCurve } from 'core/util/svgUtils';
import { FiInfo } from 'react-icons/fi';
import { tracegraph } from 'app/views/synthetics/components/traceroutes';
import {
  makeNodeAgentIcon,
  makeNodeAgentWarningIcon,
  makeNodeIcon,
  makeNodeText,
  makeNodeTextCount,
  makeNodeTitle,
  makeRectNodeSubText,
  makeRectNodeSupText
} from 'app/views/synthetics/components/traceroutes/util';
import TraceroutePopover from './TraceroutePopover';

@inject('$exports', '$app', '$dataviews')
@withTheme
@withSize()
@observer
class Traceroute extends Component {
  state = {
    popoverOpen: false,
    popoverData: null,
    popoverPosition: { left: 0, top: 0, width: 0, height: 0, anchor: Position.BOTTOM },
    xScroll: false
  };

  wrapperRef = React.createRef();

  asns = {};

  constructor(props) {
    super(props);

    if (props.lookups) {
      this.setASNs();
    }
  }

  componentDidMount() {
    this.renderData();
    this.renderDataDebounced = debounce(() => this.renderData(), 150);
    window.addEventListener('resize', this.renderDataDebounced);
  }

  componentDidUpdate(prevProps) {
    const { traceroutes, time, selectedAgents, groupBy, openPaths, lookups } = this.props;

    if (lookups !== prevProps.lookups) {
      this.setASNs();
    }

    if (
      traceroutes !== prevProps.traceroutes ||
      time !== prevProps.time ||
      selectedAgents !== prevProps.selectedAgents ||
      groupBy !== prevProps.groupBy ||
      openPaths !== prevProps.openPaths
    ) {
      this.renderData();
    }
  }

  componentWillUnmount() {
    const { $dataviews } = this.props;
    $dataviews.unregister(this);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  get type() {
    return 'traceroute';
  }

  setASNs = () => {
    const { lookups } = this.props;

    if (lookups && lookups.asns) {
      const asnKeys = Object.keys(lookups.asns);

      this.asns = asnKeys.reduce((acc, curr, i) => {
        const colorIndex = (i ? i / asnKeys.length : i) * 0.75 + 0.25;

        acc[curr] = {
          id: curr,
          name: lookups.asns[curr],
          color: this.asnColorScheme(colorIndex)
        };

        return acc;
      }, {});
    }
  };

  @computed
  get traces() {
    const { time, traceroutes = [], selectedAgents } = this.props;
    const traceroute = traceroutes.find((trace) => trace.time === time) || traceroutes[traceroutes.length - 1];
    const { traces } = traceroute || {};

    if (!traces) {
      return [];
    }

    return traces.filter(({ agentId }) => selectedAgents.includes(agentId));
  }

  get asnColorScheme() {
    return interpolateYlGnBu;
  }

  chartRef = (ref) => {
    if (ref) {
      this.chart = ref;
    }

    const { $dataviews } = this.props;
    $dataviews.register(this);
  };

  getNodeIDs = (hop, prev, completed) => {
    const { traces } = this;
    const { openPaths, groupBy, collapseFromStart, collapseFromEnd, collapseTimeouts } = this.props;
    const { agentID, traceIndex, probeIndex, hopIndex, target_ip, hopCount } = hop;
    const pathOpen = () =>
      openPaths && openPaths.filter((path) => path.agentID === agentID && path.target_ip === target_ip).length > 0;

    const getInfo = () => {
      if (hop.agentId) {
        return {
          id: hop.agentId,
          type: 'agent'
        };
      }

      if (hop.target) {
        return {
          id: target_ip,
          type: 'target'
        };
      }

      if (groupBy) {
        const { asPath, sitePath, regionPath } = traces[traceIndex].probes[probeIndex];

        /* eslint-disable-next-line no-restricted-globals */
        if (groupBy === 'asn' && !isNaN(hop.asn)) {
          if (
            (collapseFromStart === 0 && collapseFromEnd === 0) ||
            (collapseFromStart > 0 && hop.asn < collapseFromStart) ||
            (collapseFromEnd > 0 && hop.asn > asPath.length - 1 - collapseFromEnd)
          ) {
            return {
              id: `${asPath[hop.asn]}`,
              type: 'asn'
            };
          }
        }

        /* eslint-disable-next-line no-restricted-globals */
        if (groupBy === 'site' && !isNaN(hop.site)) {
          if (
            (collapseFromStart === 0 && collapseFromEnd === 0) ||
            (collapseFromStart > 0 && hop.site < collapseFromStart) ||
            (collapseFromEnd > 0 && hop.site > sitePath.length - 1 - collapseFromEnd)
          ) {
            return {
              id: `${sitePath[hop.site]}`,
              type: 'site'
            };
          }
        }

        /* eslint-disable-next-line no-restricted-globals */
        if (groupBy === 'region' && !isNaN(hop.region)) {
          if (
            (collapseFromStart === 0 && collapseFromEnd === 0) ||
            (collapseFromStart > 0 && hop.region < collapseFromStart) ||
            (collapseFromEnd > 0 && hop.region > sitePath.length - 1 - collapseFromEnd)
          ) {
            return {
              id: `${regionPath[hop.region]}_${agentID}_${target_ip}_${hopIndex}`,
              type: 'region'
            };
          }
        }
      }

      if (hop.ip) {
        return {
          id: hop.ip,
          type: 'ip'
        };
      }

      return {
        id:
          collapseTimeouts && prev && prev.type === 'timeout' && pathOpen()
            ? prev.id
            : `timeout_${agentID}_${traceIndex}_${probeIndex}_${hopIndex}`,
        type: 'timeout'
      };
    };

    const { id, type } = getInfo();

    if (id !== prev.id && hopIndex >= 2 && !pathOpen() && hopIndex <= hopCount - (completed ? 4 : 3)) {
      return {
        id: `closed_path_${agentID}_${target_ip}`,
        type: 'closed_path'
      };
    }

    return { id, type };
  };

  isHopSelected = (hop, group) => {
    if (group instanceof Map) {
      const hasAgent = group.has(hop.agentID);
      const hasTarget = hasAgent && group.get(hop.agentID).has(hop.target_ip);
      const hasProbe = hasTarget && group.get(hop.agentID).get(hop.target_ip).has(hop.probeIndex);

      return hasProbe;
    }

    return false;
  };

  checkNodeLoss = ({ info = {} }) => {
    const { nodeMetrics } = info;
    const { percSuccessThreshold } = this.props;

    if (nodeMetrics) {
      return nodeMetrics.success < percSuccessThreshold * 100;
    }

    return false;
  };

  isPathSelected = ({ toHops }, group) => toHops && toHops.filter((hop) => this.isHopSelected(hop, group)).length > 0;

  checkLinkLatency = ({ info }) => {
    const { deltaLatencyThreshold, moreThanExpected } = this.props;

    if (info) {
      const { linkMetrics } = info;

      if (linkMetrics) {
        const average = linkMetrics.average / 1000;

        if (average > deltaLatencyThreshold) {
          return true;
        }

        if (moreThanExpected && linkMetrics.minExpectedLatency && average > linkMetrics.minExpectedLatency) {
          return true;
        }
      }
    }

    return false;
  };

  checkLinkLoss = ({ info, type }) => {
    const { percSuccessThreshold } = this.props;

    if (info && type === 'target') {
      // 'success' node metric is 0-100, make it a decimal to compare against the form threshold
      const successRate = (info?.to?.nodeMetrics?.success || 0) / 100;

      // link loss occurs when the success rate is at or below the form threshold
      return successRate <= percSuccessThreshold;
    }

    return false;
  };

  isPathCritical = (data) => this.checkLinkLatency(data) || this.checkLinkLoss(data);

  openLinkPopover = (data, i, paths) => {
    clearTimeout(this.popoverTimer);

    const [mouseX, mouseY] = mouse(paths[i]);

    this.popoverTimer = setTimeout(() => {
      const popoverPosition = {
        left: mouseX,
        top: mouseY,
        width: 0,
        height: 0,
        anchor: Position.TOP
      };

      this.setState({
        popoverOpen: true,
        popoverPosition,
        popoverData: data
      });
    }, 100);
  };

  openNodePopover = (data, i, nodes) => {
    clearTimeout(this.popoverTimer);

    this.popoverTimer = setTimeout(() => {
      const wrapperRect = this.wrapperRef.current.getBoundingClientRect();
      const nodeRect = nodes[i].getBoundingClientRect();
      const popoverPosition = {
        left: nodeRect.x - wrapperRect.x,
        top: nodeRect.y - wrapperRect.y,
        width: nodeRect.width,
        height: nodeRect.height,
        anchor: Position.TOP
      };

      this.setState({
        popoverOpen: true,
        popoverPosition,
        popoverData: data
      });
    }, 100);
  };

  closePopover = () => {
    clearTimeout(this.popoverTimer);

    this.popoverTimer = setTimeout(() => {
      this.setState({ popoverOpen: false, popoverData: null });
    }, 100);
  };

  onPopoverHover = () => {
    clearTimeout(this.popoverTimer);
  };

  renderData() {
    const { traces, getNodeIDs, asns } = this;
    const { $app, onOpenPaths, theme, lookups, selectedAgents } = this.props;
    const { xScroll } = this.state;
    const { colors } = theme;

    if (!this.chart) {
      return;
    }

    if (selectedAgents.length === 0) {
      this.chart.innerHTML = '<div>Select at least 1 agent to view trace data</div>';
      return;
    }

    if (traces.length === 0) {
      this.chart.innerHTML = '<div>No trace data available</div>';
      return;
    }

    if (traces.every((trace) => trace.probes.every((probe) => probe.hops.every((hop) => hop.timeout)))) {
      this.chart.innerHTML =
        // eslint-disable-next-line max-len
        '<div>Not enough data from the selected agents to construct a trace. Agents that time out before the trace can complete will show no hop data. Please check the Advanced Settings for this test.</div>';
      return;
    }

    this.chart.innerHTML = '';
    const chartContainer = select(this.chart);
    const width = parseInt(chartContainer.style('width')) - 8 || 0;
    const tmpSvg = select('body').append('svg');
    const tmpText = makeText(tmpSvg);
    const graph = tracegraph();
    const options = {
      getNodeIDs,
      width,
      lookups,
      tmpText,
      asns
    };
    const layout = graph(traces, options);
    const vb = layout.bounds.expanded(4);
    const xOverflow = vb.width > width + 8;

    const svg = chartContainer
      .append('svg')
      .attr('viewBox', `${vb.x} ${vb.y} ${width + 8}, ${vb.height}`)
      .attr('height', $app.isExport ? '7in' : vb.height)
      .attr('cursor', xOverflow ? 'grab' : 'default');

    if ($app.isExport) {
      svg.attr('width', '100%');
    }

    const g = svg.append('g').attr('cursor', 'default');

    if (xOverflow) {
      const zoomBehavior = zoom()
        .extent([
          [vb.x, vb.y],
          [vb.x + width, vb.y + vb.height]
        ])
        .scaleExtent([1, 1])
        .translateExtent([
          [vb.x, vb.y],
          [vb.x + vb.width, vb.y + vb.height]
        ])
        .on('start', () => svg.attr('cursor', 'grabbing'))
        .on('end', () => svg.attr('cursor', 'grab'))
        .on('zoom', () => g.attr('transform', event.transform));

      svg.call(zoomBehavior);
    }

    const linkGroup = g
      .selectAll('.link')
      .data(layout.links)
      .enter()
      .append('g')
      .attr('class', 'link')
      .attr('fill', 'none');

    const nodeGroup = g
      .selectAll('.node')
      .data(layout.nodes)
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('stroke', (d) => (this.checkNodeLoss(d) ? colors.danger : d.style.stroke || d.style.color))
      .attr('stroke-width', (d) => (this.checkNodeLoss(d) ? 5 : 2))
      .attr('fill', (d) => d.style.color)
      .attr('fill-opacity', (d) => (d.type === 'timeout' ? 0 : 0.5));

    const textColor = theme.name === 'light' ? '#252525' : '#fff';

    makeNodeIcon(nodeGroup);
    makeNodeAgentIcon(nodeGroup.filter((d) => d.type === 'agent'));
    makeNodeAgentWarningIcon(
      nodeGroup.filter((d) => d.type === 'agent' && d.timedOut),
      colors.danger
    );
    makeNodeText(
      nodeGroup.filter((d) => d.text && (!d.info || !d.info.supText)),
      textColor
    );
    makeNodeTextCount(
      nodeGroup.filter((d) => d.info && d.info.title),
      textColor
    );
    makeNodeTitle(
      nodeGroup.filter((d) => d.info && d.info.title),
      textColor
    );
    makeRectNodeSupText(
      nodeGroup.filter((d) => d.text && d.info && d.info.supText),
      textColor
    );
    makeRectNodeSubText(
      nodeGroup.filter((d) => d.text && d.info && d.info.supText),
      textColor
    );

    const linkPaths = linkGroup
      .append('path')
      .attr('stroke-width', (d) => (this.isPathSelected(d) ? 5 : 3))
      .attr('stroke', (d) => (this.isPathCritical(d) ? colors.danger : colors.muted))
      .attr('stroke-opacity', (d) => (this.isPathSelected(d) ? 0.8 : 0.5))
      .attr('stroke-dasharray', (d) => (this.checkLinkLoss(d) || d.type === 'timeout' ? '2 2' : ''))
      .attr('d', (d) => traceCurve(d));

    linkPaths
      .style('cursor', 'pointer')
      .on('mouseenter', (data, i, paths) => {
        linkPaths
          .attr('stroke-width', (d) => (this.isPathSelected(d, data.info.group) ? 5 : 3))
          .attr('stroke-opacity', (d) => (this.isPathSelected(d, data.info.group) ? 0.8 : 0.5));

        this.openLinkPopover(data, i, paths);
      })
      .on('mouseleave', () => {
        linkPaths
          .attr('stroke-width', (d) => (this.isPathSelected(d) ? 5 : 3))
          .attr('stroke-opacity', (d) => (this.isPathSelected(d) ? 0.8 : 0.5));
        this.closePopover();
      });

    nodeGroup
      .on('mouseenter', (data, i, nodes) => {
        linkPaths
          .attr('stroke-width', (d) => (this.isPathSelected(d, data.group) ? 5 : 3))
          .attr('stroke-opacity', (d) => (this.isPathSelected(d, data.group) ? 0.8 : 0.5));

        nodeGroup.attr('stroke-width', (d) => (data.id === d.id || this.checkNodeLoss(d) ? 5 : 2));

        this.openNodePopover(data, i, nodes);
      })
      .on('mouseleave', () => {
        linkPaths
          .attr('stroke-width', (d) => (this.isPathSelected(d) ? 5 : 3))
          .attr('stroke-opacity', (d) => (this.isPathSelected(d) ? 0.8 : 0.5));

        nodeGroup.attr('stroke-width', (d) => (this.checkNodeLoss(d) ? 5 : 2));

        this.closePopover();
      });

    nodeGroup
      .filter((d) => d.type === 'closed_path')
      .style('cursor', 'pointer')
      .on('click', (d) => {
        event.stopPropagation();

        const group = d.id.split('closed_path_')[1];
        const [agent, target_ip] = group.split('_');

        onOpenPaths(agent, target_ip);
      });

    if (xOverflow !== xScroll) {
      this.setState({ xScroll: xOverflow });
    }
  }

  render() {
    const { asns, traces } = this;
    const { $app, lookups, openPaths, setPaths, closeOpenPaths, deltaLatencyThreshold, percSuccessThreshold } =
      this.props;
    const { popoverOpen, popoverData, popoverPosition, xScroll } = this.state;
    const someAgentsTimedOut = traces.filter(({ hopCount }) => !!hopCount);
    if (!traces.length) {
      return (
        <Flex height={200} alignItems="center" justifyContent="center">
          <Text>
            Not enough data to construct a trace. Agents that time out before the trace can complete will show no hop
            data. Please check the Advanced Settings for this test.
          </Text>
        </Flex>
      );
    }

    return (
      <Flex
        flexDirection="column"
        display={$app.isExport ? 'block' : 'flex'}
        flex={1}
        mb={4}
        position="relative"
        zIndex={1}
        ref={this.wrapperRef}
      >
        <div
          style={{
            minHeight: '200px',
            overflow: 'auto'
          }}
          className="break-before"
          ref={this.chartRef}
        />
        <Box mb={1}>
          <Text large fontWeight="bold">
            Legend
          </Text>
        </Box>
        <Flex justifyContent="flex-start" flexWrap="wrap">
          {Object.values(asns).map(({ id, name, color }) => (
            <Flex key={id} alignItems="center" my="6px" mr={2}>
              <Box position="relative" display="inline-block" mr="4px" width={20} height={20}>
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  display="block"
                  borderRadius="50%"
                  width={20}
                  height={20}
                  bg={color}
                  opacity={0.5}
                />
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  display="block"
                  borderRadius="50%"
                  width={20}
                  height={20}
                  border={`2px solid ${color}`}
                  bg="transparent"
                />
              </Box>
              <Text small>
                AS{id} - {name}
              </Text>
            </Flex>
          ))}
          {(xScroll || someAgentsTimedOut) && (
            <Flex flex="1 0 100%" flexWrap="wrap" mx={-1}>
              {xScroll && (
                <Flex justifyContent="flex-start" mt={2}>
                  <Popover
                    position="top"
                    minimal={false}
                    interactionKind={PopoverInteractionKind.HOVER}
                    content={
                      <Box maxWidth={300} p={2}>
                        Click and hold to scroll the trace graph horizontally.
                      </Box>
                    }
                  >
                    <Button minimal small>
                      <Flex alignItems="center">
                        <span>Why is a portion of the trace graph not visible?</span>
                        <Icon iconSize={14} icon={FiInfo} color="primary" ml="4px" />
                      </Flex>
                    </Button>
                  </Popover>
                </Flex>
              )}
              {someAgentsTimedOut && (
                <Flex justifyContent="flex-start" mt={2}>
                  <Popover
                    position="top"
                    minimal={false}
                    interactionKind={PopoverInteractionKind.HOVER}
                    content={
                      <Box maxWidth={300} p={2}>
                        Agents that time out before the trace can complete will show no hop data. Please check the
                        Advanced Settings for this test.
                      </Box>
                    }
                  >
                    <Button minimal small>
                      <Flex alignItems="center">
                        <span>Why do some agents not have complete traces?</span>
                        <Icon iconSize={14} icon={FiInfo} color="primary" ml="4px" />
                      </Flex>
                    </Button>
                  </Popover>
                </Flex>
              )}
            </Flex>
          )}
        </Flex>

        <TraceroutePopover
          isOpen={popoverOpen}
          data={popoverData}
          lookups={lookups}
          position={popoverPosition}
          openPaths={openPaths}
          setPaths={setPaths}
          closeOpenPaths={closeOpenPaths}
          deltaLatencyThreshold={deltaLatencyThreshold}
          percSuccessThreshold={percSuccessThreshold}
          onMouseOver={this.onPopoverHover}
          onMouseOut={this.closePopover}
        />
      </Flex>
    );
  }
}

export default Traceroute;
