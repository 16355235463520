import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { PopoverPosition } from '@blueprintjs/core';

import { Flex, Button, Menu, MenuItem, Popover, Text } from 'core/components';

const AGGREGATION_OPTIONS = [
  { value: 'avg', label: 'Average' },
  { value: 'p95th', label: '95th Percentile' },
  { value: 'max', label: 'Maximum' }
];

@inject('$app', '$exports')
class AggregationSelector extends Component {
  state = {
    isOpen: false
  };

  static defaultProps = {
    showLabel: true
  };

  handleInteraction = (nextOpenState) => {
    this.setState({ isOpen: nextOpenState });
  };

  handleSelect = (aggregateFn) => {
    const { $exports, aggregateType, onChange, hashEnabled } = this.props;

    const [, ...rest] = aggregateType.split('_');
    const newAggregateType = [aggregateFn, ...rest].join('_');

    onChange(newAggregateType);

    if (hashEnabled) {
      $exports.setHash({
        hashedAggregateType: newAggregateType
      });
    }
  };

  render() {
    const { $app, aggregateType, showLabel, minimal } = this.props;
    const { isOpen } = this.state;

    const [aggregationFn] = aggregateType.split('_');
    const selectedOption = AGGREGATION_OPTIONS.find((option) => option.value === aggregationFn);
    const buttonText = selectedOption ? selectedOption.label : `Last ${aggregationFn}s`;

    const width = 250;

    if ($app.isExport) {
      return (
        <Flex px="12px" alignItems="center">
          <Text small muted>
            {buttonText}
          </Text>
        </Flex>
      );
    }

    return (
      <Popover
        isOpen={isOpen}
        onInteraction={this.handleInteraction}
        position={PopoverPosition.BOTTOM_RIGHT}
        content={
          <Menu minWidth={width}>
            {AGGREGATION_OPTIONS.map((option) => (
              <MenuItem
                key={option.value}
                active={aggregationFn === option.value}
                text={option.label}
                onClick={() => this.handleSelect(option.value)}
                minWidth={width - 8}
              />
            ))}
          </Menu>
        }
      >
        <Button
          textAlign="left"
          rightIcon="caret-down"
          active={isOpen}
          width={width}
          minimal={minimal}
          className={minimal ? 'translucent' : undefined}
        >
          {showLabel && (
            <Text as="div" fontSize="small" color="muted" pb="1px">
              Aggregate
            </Text>
          )}
          <Text as="div" fontWeight="bold" fontSize={showLabel ? 'small' : null}>
            {buttonText}
          </Text>
        </Button>
      </Popover>
    );
  }
}

export default AggregationSelector;
