import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MdCompareArrows } from 'react-icons/md';

import { Box, Flex, Text, CalloutOutline, Heading, Icon, Button } from 'core/components';

@withRouter
class NoFlowCallout extends Component {
  static defaultProps = {
    title: 'Add your first traffic source',
    body: 'You don’t have any traffic data yet! Adding your first traffic source takes just a few clicks.',
    showButton: true,
    small: false
  };

  handleGoToOnboarding = () => {
    const { history } = this.props;
    history.push('/v4/onboarding/what');
  };

  render() {
    const { title, body, small, showButton, ...rest } = this.props;

    return (
      <CalloutOutline intent="warning" p={2} {...rest}>
        <Flex flexDirection={small ? 'column' : 'row'} justifyContent={small ? 'flex-start' : 'space-between'} flexWrap>
          <Flex alignItems="center" mb={small ? 2 : 0}>
            {!small && <Icon icon={MdCompareArrows} iconSize={52} mr={2} color="warning" />}

            <Box flex="1 1 auto">
              <Heading level={small ? 6 : 4} fontWeight="heavy" color="warning">
                {title}
              </Heading>
              <Text small={small}>{body}</Text>
            </Box>
          </Flex>

          {showButton && (
            <Button
              large
              text="Get Started"
              alignSelf={small ? 'flex-start' : 'center'}
              onClick={this.handleGoToOnboarding}
              ml={small ? 0 : 2}
              small={small}
            />
          )}
        </Flex>
      </CalloutOutline>
    );
  }
}

export default NoFlowCallout;
