import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Flex, Icon, Text } from 'core/components';
import Dimension from '../dimensions/Dimension';

@inject('$dictionary', '$lookups')
@observer
class FilterItem extends Component {
  state = {
    selectedLabel: ''
  };

  componentDidMount() {
    this.lookupValues();
  }

  componentDidUpdate(prevProps) {
    const { filterValue, filterField, operator, rightFilterField } = this.props;
    const {
      filterValue: prevFilterValue,
      filterField: prevFilterField,
      operator: prevOperator,
      rightFilterField: prevRightFilterField
    } = prevProps;

    if (
      filterValue !== prevFilterValue ||
      filterField !== prevFilterField ||
      operator !== prevOperator ||
      rightFilterField !== prevRightFilterField
    ) {
      this.lookupValues();
    }
  }

  get filterField() {
    const { filterField, filter } = this.props;
    return filterField || filter?.filterField.getValue();
  }

  get operator() {
    const { operator, filter } = this.props;
    return operator || filter?.operator.getValue();
  }

  get filterValue() {
    const { filterValue, filter } = this.props;
    return filterValue !== undefined ? filterValue : filter?.filterValue.getValue();
  }

  get rightFilterField() {
    const { rightFilterField, filter } = this.props;
    return rightFilterField !== undefined ? rightFilterField : filter.rightFilterField.getValue();
  }

  lookupValues() {
    const { $lookups, $dictionary } = this.props;
    const { autoCompleteFilterQueryHandlers } = $lookups;
    const {
      rightFilterFieldOptions,
      dictionary: {
        queryFilters: { neverExactMatch }
      }
    } = $dictionary;

    const onQuery = autoCompleteFilterQueryHandlers[this.filterField];
    const exactMatch = !neverExactMatch.includes(this.filterField) && (this.operator === '=' || this.operator === '<>');

    if (onQuery && exactMatch && this.filterValue) {
      onQuery(this.filterValue, { debounce: false }).then((newOptions) => {
        /* eslint-disable-next-line eqeqeq */
        const selectedOption = newOptions.find((option) => option.value == this.filterValue);
        if (selectedOption) {
          this.setState({ selectedLabel: selectedOption.label });
        } else {
          this.setState({ selectedLabel: '' });
        }
      });
    } else if (this.operator === 'field_equals' || this.operator === 'field_not_equal') {
      const selectedOption = rightFilterFieldOptions[this.filterField].find(
        (option) => option.value === this.rightFilterField
      );
      if (selectedOption) {
        this.setState({ selectedLabel: selectedOption.label });
      } else {
        this.setState({ selectedLabel: '' });
      }
    } else {
      this.setState({ selectedLabel: '' });
    }
  }

  render() {
    const { $dictionary, useNmsDimensions } = this.props;
    const { selectedLabel } = this.state;
    const { dictionary, filterFieldValueOptions, filterOperatorOptions, standardFilterOperators } = $dictionary;
    const { neverExactMatch, nms } = dictionary.queryFilters;

    let operatorDisplay;

    if (useNmsDimensions) {
      operatorDisplay = nms.operatorOptions[this.filterField]
        ? nms.operatorOptions[this.filterField].find((operator) => operator.value === this.operator)
        : nms.standardOperatorOptions.find((operator) => operator.value === this.operator);
    } else {
      operatorDisplay = filterOperatorOptions[this.filterField]
        ? filterOperatorOptions[this.filterField].find((operator) => operator.value === this.operator)
        : standardFilterOperators.find((operator) => operator.value === this.operator);
    }

    const exactMatch = !neverExactMatch.includes(this.filterField) && (this.operator === '=' || this.operator === '<>');
    const showValueField = !$dictionary.get('queryFilters.suppressValueOperators').includes(this.operator);

    const value = this.filterValue;
    const options = filterFieldValueOptions[this.filterField];

    let valueDisplay = value || '';
    if (options) {
      const option = options.find((opt) => opt.value === value);
      if (option) {
        valueDisplay = option.label;
      } else if (value && exactMatch) {
        valueDisplay = `Invalid: ${value}`;
      }
    } else if (selectedLabel) {
      valueDisplay = selectedLabel;
    }
    if (useNmsDimensions) {
      const values = useNmsDimensions.find((option) => option.value === this.filterField)?.values;
      if (values) {
        valueDisplay = values?.[value] || value;
      }
    }

    if (this.filterField === 'i_device_type') {
      return null;
    }

    return (
      <Flex alignItems="center" fontSize="small" mt="4px" pl="4px">
        <Box position="relative" top={-1} mr="2px">
          <Icon icon="nest" color="muted" iconSize={11} />
        </Box>
        <Flex flexWrap="wrap" alignItems="center" gap="4px">
          <Dimension outline round fontWeight="bold" dimension={this.filterField} useNmsDimensions={useNmsDimensions} />
          {operatorDisplay && (
            <Text as="div" color="muted">
              {operatorDisplay.label.toLowerCase()}
            </Text>
          )}
          {showValueField && (
            <Text style={{ maxWidth: 250 }} ellipsis>
              {valueDisplay}
            </Text>
          )}
        </Flex>
      </Flex>
    );
  }
}

export default FilterItem;
