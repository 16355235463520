import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Box, Button, Flex, Heading, Tab, Tabs, Text } from 'core/components';
import { InputGroup } from 'core/form';
import SelectWidgetCard from 'app/components/decks/SelectWidgetCard';
import DrawerBackdrop from './DrawerBackdrop';
import DrawerMenu from './DrawerMenu';

const WidgetGrid = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-row-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;

  @media (min-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

@inject('$decks')
@observer
class CustomizeDrawer extends Component {
  state = {
    selectedTabId: null,
    filter: ''
  };

  getCategories(widgets) {
    const categorySet = Object.keys(widgets).reduce((categories, widgetName) => {
      categories.add(widgets[widgetName].category);
      return categories;
    }, new Set());

    // sort by localCompare
    return Array.from(categorySet).sort((a, b) => a.localeCompare(b));
  }

  getWidgetsByCategory(widgets, category) {
    return widgets.filter((widget) => widget.category === category);
  }

  handleCategoryChange = (selectedTabId) => {
    this.setState({ selectedTabId });
  };

  handleCategoryFilterChange = (e) => {
    this.setState({ filter: e.target.value });
  };

  renderTab(widgets, category) {
    const { onAddWidget } = this.props;
    // Excluding Meshes widgets reserved for Performance Dashboard - Observation Deck should use AgentMesh Widget instead
    const categoryWidgets = this.getWidgetsByCategory(widgets, category)
      .filter((widget) => widget.name !== 'meshes')
      .sort((a, b) => a.name.localeCompare(b.name));

    return (
      <WidgetGrid>
        {categoryWidgets.map((widget) => (
          <SelectWidgetCard key={widget.name} onClick={() => onAddWidget(widget.name)} interactive>
            <Heading level={6} fontWeight="heavy">
              {widget.title}
            </Heading>
            <Text muted lineHeight="19px">
              {widget.description}
            </Text>
          </SelectWidgetCard>
        ))}
      </WidgetGrid>
    );
  }

  render() {
    const { $decks, isOpen, onClose } = this.props;
    const { selectedTabId, filter } = this.state;

    const className = classNames({ hidden: !isOpen });

    const widgets = $decks.getWidgets(filter);
    const categories = this.getCategories(widgets);

    return (
      <>
        <DrawerBackdrop bottom={0} onClick={onClose} className={className} top={0} />
        <DrawerMenu
          className={classNames('bp4-elevation-2', 'bottom-aligned', { hidden: !isOpen })}
          bottom={0}
          bg="subnavBackground"
          height="60vh"
          maxHeight="650px"
        >
          <Flex
            bg="appBackground"
            borderBottom="thin"
            alignItems="center"
            justifyContent="space-between"
            p="8px 39px 8px 24px"
          >
            <Heading level={5} fontWeight="heavy" mb={0}>
              Add Visualizations
            </Heading>
            <Box>
              <Button minimal color="muted" text="Close" onClick={onClose} />
            </Box>
          </Flex>
          <Box bg="decks.customizeDrawerBackground" flex={1} py={2} px={3} style={{ overflowY: 'scroll' }}>
            <Tabs onChange={this.handleCategoryChange} selectedTabId={selectedTabId || categories[0]}>
              {categories.map((category) => (
                <Tab key={category} id={category} title={category} panel={this.renderTab(widgets, category)} />
              ))}
              <Flex justifyContent="flex-end" flex={1}>
                <Box flex={1} maxWidth={250}>
                  <InputGroup
                    type="search"
                    value={filter}
                    placeholder="Search visualizations..."
                    onChange={this.handleCategoryFilterChange}
                    leftIcon="search"
                    fill
                  />
                </Box>
              </Flex>
            </Tabs>
          </Box>
        </DrawerMenu>
      </>
    );
  }
}

export default CustomizeDrawer;
