import React from 'react';
import FormContext from './FormContext';

export default function (Component) {
  return (props) => (
    <FormContext.Consumer>
      {(form) => {
        if (!form) {
          console.warn('@formConsumer put on a component that is not in a form!');
          return <Component {...props} />;
        }
        return <Component {...props} model={form.model} form={form} />;
      }}
    </FormContext.Consumer>
  );
}
