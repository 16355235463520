const getTagValue = require('./aws/getTagValue');
const { findInstanceInEntities } = require('./aws/utils');
/*
  Helper function that cleans a given Azure or GCP uri so we can safely evaluate key/value pairs
  Returns an array of values split from a '/' delimited uri string
*/
function splitUri(uri) {
  if (typeof uri === 'string') {
    // these are pieces of the uri we'll always want to filter
    const filteredItems = ['https:', 'www.googleapis.com', ''];

    // determines whether this is a google global entity such as a network or external vpn gateway
    // this causes problems evaluating as a key/value pair because of the 'global' piece in the uri
    // removing that piece will allow us to continue getting key/value pairs from the uri
    const re = /^https:\/\/www.googleapis.com\/.*global\//;

    if (re.test(uri)) {
      filteredItems.push('global');
    }

    // split up the uri, eliminating all the pieces we want filtered out so we can get clean key/value pairs
    return uri.split('/').filter((uriItem) => !filteredItems.includes(uriItem));
  }

  return [];
}

/*
  Entities returned from the Azure and GCP API are in uri format with some useful information contained in them for free
  This utility parses them out into an object that we can glob into the custom properties

  ex:

  /subscriptions/sub-1/resourceGroups/rg-1/providers/Microsoft.Network/virtualNetworks/vnet-1/subnets/subnet-1

  returns:

  {
    subscription: 'sub-1',
    resourceGroup: 'rg-1',
    provider: 'Microsoft.Network',
    virtualNetwork: 'vnet-1',
    subnet: 'subnet-1'
  }

  ex:

  https://www.googleapis.com/compute/v1/projects/kentik-vpc-flow/global/networks/default

  returns:

  {
    compute: 'v1',
    project: 'kentik-vpc-flow',
    network: 'default'
  }
*/
function uriToObject(uri, noTrim, suppressError) {
  if (!uri) {
    return {};
  }

  if (typeof uri === 'string') {
    const uriItems = splitUri(uri);

    if (uriItems.length % 2 === 0) {
      // we know we're at least in a position for key/value pairs
      return uriItems.reduce((acc, item, i) => {
        if (i % 2 === 0) {
          // current value is a 'key'
          // trim off the 's' on the end (providers -> provider)
          // note this won't always work right (e.g. proxies -> proxie instead of proxy)
          const key = noTrim ? item : item.replace(/s$/, '');
          // the value will be the next entry
          const value = uriItems[i + 1];

          acc[key] = value;
        }

        return acc;
      }, {});
    }
  }

  if (!suppressError) {
    console.error('Incorrect uri format:', uri);
  }

  return {};
}

/*
  Returns a sort callback that attempts sorting by 'Name' tag and falls back to the entity id
*/
function getEntitySorter(entityIdKey = 'id') {
  return (a, b) => {
    // first try to sort by 'Name' tag, but fallback to the generic id
    const aValue = getTagValue(a, 'Name') || a[entityIdKey];
    const bValue = getTagValue(b, 'Name') || b[entityIdKey];

    return aValue?.localeCompare(bValue) ?? 0;
  };
}

function getEntity({ entities, entityType, entityId }) {
  return entities?.[entityType]?.[entityId] || {};
}

/*
  All custom properties are tucked under a 'kentik' namespace
  This is where anything non-standard in the eyes of the cloud provider is kept
*/
function addCustomPropertiesToEntity({ entity, customProperties = {} }) {
  if (entity && customProperties) {
    return Object.assign({}, entity, {
      kentik: Object.assign({}, entity.kentik || {}, customProperties)
    });
  }

  return entity;
}

/*
  Quick helper to get the custom (non-standard) properties from an entity
  Supports both plain objects as well as models
*/
function getCustomProperties(entity = {}) {
  if (typeof entity?.get === 'function') {
    return entity.get('kentik') || {};
  }

  return entity?.kentik || {};
}

function createTimePath({ date = new Date(), includeMinutes = true }) {
  const day = String(date.getUTCDate()).padStart(2, '0'); // 24
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // 10 (Month is 0-based, so 10 means 11th Month)
  const year = date.getUTCFullYear(); // 2023
  const hour = String(date.getUTCHours()).padStart(2, '0'); // 15 (0-23)
  // always keep latest topology for current hour to limit files we store
  const minute = '00';

  return `${year}/${month}/${day}/${hour}${includeMinutes ? `/${minute}` : ''}`;
}

function isGoogleCloud(cloud_provider_string) {
  // Google Cloud abbreviation is generally 'gcp', but 'gce' has been used in backend and cloud exports
  return cloud_provider_string === 'gcp' || cloud_provider_string === 'gce';
}

/*
  Given a project, network, and region, returns a 'selectId' used to uniquely identify a region in the GCP map
*/
function getGCPMapSelectId({ project = '', network = '', region = '' } = {}) {
  return `project:${project}|network:${network}|region:${region}`;
}

/*
  We use a "selectId" property for GCP to disabiguate regions (see above getGCPMapSelectId)
  e.g.:  "project:slb-it-infrastructure-01|network:slb-inf-dmz-hub-test|region:europe-west1"
  This utility takes a sselectId string and turns it into an object:

    {
      project: slb-it-infrastructure-01,
      network: slb-inf-dmz-hub-test
      region: europe-west1
    }

 */
function selectIdToObj({ selectId }) {
  return selectId?.split('|').reduce((acc, cur) => {
    const obj = {};
    const splitArr = cur.split(':');
    /* eslint-disable prefer-destructuring */
    obj[splitArr[0]] = splitArr[1];
    return Object.assign(acc, obj);
  }, {});
}

/**
 * subscriptionId is here because, for some weird reason using ...rest causing a crash
 * but its getting passed in azure topology
 */
function generateRedisKey({ companyId, cloud, end, subscriptionId = '', ...rest }) {
  const timePath = createTimePath({ date: new Date(end), includeMinutes: false }).replaceAll('/', '-');

  // sort keys alphabetically to ensure consistent base64 string
  const sortedRest = Object.keys(rest ?? {})
    .sort()
    .reduce((carry, key) => {
      carry[key] = rest[key];
      return carry;
    }, {});

  const restPart =
    Object.keys(sortedRest).length > 0 ? Buffer.from(JSON.stringify(sortedRest), 'binary').toString('base64') : '';
  return `${cloud}-topo-${companyId}-${timePath}${subscriptionId ? `-${subscriptionId}` : ''}${
    restPart ? `-${restPart}` : ''
  }`;
}

function sortByTimestamp(inputArray, timestampFieldName) {
  return inputArray.sort((a, b) => a[timestampFieldName] - b[timestampFieldName]);
}

module.exports = {
  getEntity,
  uriToObject,
  getTagValue,
  isGoogleCloud,
  createTimePath,
  getEntitySorter,
  generateRedisKey,
  getGCPMapSelectId,
  getCustomProperties,
  findInstanceInEntities,
  addCustomPropertiesToEntity,
  selectIdToObj,
  sortByTimestamp
};
