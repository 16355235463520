import { generateFilterFields, generateTimeFields } from 'app/forms/config/helpers';

const timeFields = generateTimeFields();
const filterFields = generateFilterFields();
const rollupFilterFields = generateFilterFields('rollupFilters');

export const metricsExplorerFormOptions = {
  name: 'Metrics Explorer',
  showPristineErrors: false,
  groups: {
    measurement: ['selectedMeasurement'],
    metrics: ['selectedMetrics'],
    dimensions: ['selectedDimensions'],
    time: Object.keys(timeFields),
    filtering: ['filters.filterGroups', 'filters.connector'],
    timeseries: ['selectedAggFunc', 'selectedWindowSize', 'selectedLimitCount', 'selectedTransformation'],
    rollups: ['selectedRollupsAggFunc', 'selectedRollupsLimit', 'selectedSortOrder']
  }
};

export const metricsExplorerFormFields = {
  selectedMeasurement: {
    label: 'Measurement',
    required: true,
    placeholder: 'Select a measurement'
  },
  selectedMetrics: {
    label: 'Metrics',
    required: true,
    rules: 'min:1',
    messages: { min: 'You must select one or more metrics' },
    defaultValue: []
  },
  selectedDimensions: {
    label: 'Group by Dimensions',
    required: false,
    defaultValue: []
  },
  selectedMergeDimensions: {
    label: 'Merge Series',
    required: false,
    defaultValue: []
  },
  selectedMergeAggregation: {
    label: 'Aggregation',
    required: false,
    defaultValue: 'sum'
  },
  selectedVisualization: {
    label: 'Type',
    required: true,
    defaultValue: 'line'
  },
  selectedVisualizationRollup: {
    label: 'Metric',
    required: false
  },
  selectedVisualizationMetric: {
    label: 'Vis Metric',
    required: false
  },
  selectedTransformation: {
    label: 'Transformation',
    required: true,
    defaultValue: 'none'
  },
  selectedAggFunc: {
    label: 'Aggregation',
    required: true,
    defaultValue: 'avg'
  },
  selectedSortOrder: {
    label: 'Sort',
    required: true,
    defaultValue: 'desc'
  },
  selectedRollupsLimit: {
    label: 'Rows',
    required: true,
    defaultValue: 10
  },
  selectedRollupsAggFunc: {
    label: 'Aggregation',
    required: true,
    defaultValue: ['avg', 'max']
  },
  selectedLimitCount: {
    label: 'Series Count',
    required: true,
    defaultValue: 10
  },
  selectedWindowSize: {
    label: 'Sample Size',
    required: true,
    defaultValue: 0
  },

  ...timeFields,

  filters: {},
  ...filterFields,

  rollupFilters: {},
  ...rollupFilterFields,

  streamingUpdate: {
    label: 'Streaming Updates',
    defaultValue: false
  },

  update_frequency: {
    label: 'Update every (secs)',
    defaultValue: 60,
    transform: {
      out: (val) => parseInt(val)
    }
  }
};
