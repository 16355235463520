import styled from 'styled-components';
import { space, width, borders, display, borderColor } from 'styled-system';
import { Divider as BlueprintDivider } from '@blueprintjs/core';

const Divider = styled(BlueprintDivider)`
  ${display};
  ${space};
  ${width};
  ${borders};
  ${borderColor};
`;

Divider.defaultProps = {
  m: 0
};

export default Divider;
