import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';
import { reaction } from 'mobx';

import { Highcharts } from 'core/components';

@observer
class BaseHighchartsNonStyledDataview extends Component {
  static propTypes = {
    $app: PropTypes.any.isRequired,
    $dataviews: PropTypes.any.isRequired
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  visible = false;

  componentDidMount() {
    const { $app, $dataviews } = this.props;
    this.darkThemeDisposer = reaction(
      () => $app.darkThemeEnabled,
      () => setTimeout(() => this.redraw(), 100)
    );

    $dataviews.register(this);
  }

  componentDidUpdate(prevProps) {
    const { data, width } = this.props;
    if (this.chart) {
      if (data !== prevProps.data) {
        this.renderData();
      }

      // Used for Observation Deck widget resize
      if (prevProps.width && width !== prevProps.width) {
        this.reflow();
      }
    }
  }

  componentWillUnmount() {
    const { $dataviews } = this.props;

    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }
    if (this.darkThemeDisposer) {
      this.darkThemeDisposer();
      this.darkThemeDisposer = null;
    }

    $dataviews.unregister(this);
  }

  handleObservation = (observables) => {
    const [observable] = observables;
    const { isIntersecting } = observable;

    // eslint-disable-next-line react/no-unused-class-component-methods
    this.visible = isIntersecting;
  };

  get containerStyle() {
    return { width: '100%' };
  }

  // abstract
  redraw() {
    console.warn('BaseHighchartsNonStyledDataview.redraw not implemented');
  }

  // abstract
  renderData() {
    console.warn('BaseHighchartsNonStyledDataview.renderData not implemented');
  }

  // setup container resize observer
  chartCallback = (chart) => {
    const { stickyPointer } = this.state;

    this.chart = chart.container ? chart : null;
    this.el = chart.container;

    this.resizeListener = debounce(() => this.reflow(), 200);
    window.addEventListener('resize', this.resizeListener);

    this.renderData();

    const chartObserver = new IntersectionObserver(
      (observables) => {
        this.handleObservation(observables);
      },
      /*
        The threshold option here defines the ratio of intersection area to the bounding box area of the viewport.
        In other words, this dataview needs to be 99% visible in the viewport in order for the observable to report back isIntersecting=true.
        This 'isIntersection' value is used in the handler called above to manage the dataview's 'visible' property.

        In synth subtest results, when visible=true and we're hovering over the dataview, we update the selected points and tooltips for all other currently rendered dataviews of type timeline.
        The threshold value below was originally set to 1 (100%) which worked well for Chrome and Safari,
        however this proved too restrictive for Firefox as that browser would report back an intersectionRatio of just over 99% for most dataviews.

        The differences in intersectionRatio between Chrome/Safari and Firefox is probably related to how each browser calculates box clipping for these views.
      */
      { threshold: 0.99 }
    );

    chartObserver.observe(this.el);

    if (stickyPointer && this.chart && this.chart.pointer) {
      this.chart.pointer.reset = function reset() {};
    }
  };

  reflow() {
    if (this.chart && this.chart.hasRendered && this.el) {
      this.chart.setSize(this.el.parentElement.offsetWidth, this.el.parentElement.offsetHeight);
      this.chart.reflow();
    }
  }

  render() {
    const { chartOptions, highlightPointsByEvent } = this.state;

    return (
      <Highcharts
        options={chartOptions}
        callback={this.chartCallback}
        styledMode={false}
        highlightPointsByEvent={highlightPointsByEvent}
        containerProps={{ style: this.containerStyle }}
      />
    );
  }
}

export default BaseHighchartsNonStyledDataview;
