import React from 'react';
import { formatDateTime } from 'core/util/dateUtils';
import { maxBy, startCase } from 'lodash';
import moment from 'moment';
import { adjustByGreekPrefix, isNumber, percentToText, zeroToText } from 'app/util/utils';
import {
  DeviceStatusTag,
  InterfaceOperAdminStatusTag,
  SessionStateRenderer
} from 'app/views/metrics/tables/StatusIndicators';

export const Measurement = {
  System: '/system',
  IfMIBif: 'IF-MIB::if',
  InterfacesCounters: '/interfaces/counters',
  ProtocolsBgpNeighbors: '/protocols/bgp/neighbors'
};

export const Metric = {
  ifAdminStatus: 'if_AdminStatus',
  ifOperStatus: 'if_OperStatus',
  AdminStatus: 'admin-status',
  OperStatus: 'oper-status',
  Available: 'available',
  Status: 'status',
  SessionState: 'session-state'
};

// Map of measurement to a list of metrics for that measurement that should display their value in a DeviceStatusTag
export const DeviceStatusTagMetrics = {
  [Measurement.System]: [Metric.Available, Metric.Status]
};

export const InterfaceStatusTagMetrics = {
  [Measurement.IfMIBif]: [Metric.ifAdminStatus, Metric.ifOperStatus],
  [Measurement.InterfacesCounters]: [Metric.AdminStatus, Metric.OperStatus]
};

// Map of measurement to a list of metrics for that measurement that should display their value in a SessionStateRenderer
export const SessionStateRendererMetrics = {
  [Measurement.ProtocolsBgpNeighbors]: [Metric.SessionState]
};

function getTopAverageValue(model, rollup) {
  const valueAverages = model.get(`${rollup}_values`);
  if (valueAverages && Object.keys(valueAverages).length > 0) {
    const [value, percentage] = maxBy(Object.entries(valueAverages), ([, p]) => p);
    return { value: Number(value), percentage };
  }

  return null;
}

const deviceStatusTagFallback = (value, measurement, metric) => {
  if (metric === Metric.Available && measurement === Measurement.System) {
    return percentToText(value * 100);
  }

  return value;
};

/**
 * Returns a metric value formatted for display in eg table or tooltip.
 * Both measurement and kmetricsQuery are marked optional, but one of them must be provided for this function to return formatted results.
 * If both are provided, kmetricsQuery will take precedence.
 * Example: 3434.4666666666667 -> 3.43 K
 * @param {number} value
 * @param {{
 *   metric: string,
 *   model: object,
 *   measurementModel: {storage: {[key: string]: {Values?: object, Unit: string, Type: string}}},
 *   measurement?: string;
 *   kmetricsQuery?: {measurement: string, window: {fn: {[key: string]: string}, rollups: {[key: string]: {aggregate: string}}}},
 *   rollup?: string,
 *   prefixes?: {[key: string]: string}
 * }} metricInfo
 * @param {boolean} allowRichContent set to true to receive JSX elements as return values in some cases
 * @returns string | number | JSX.Element
 */
export const formatMetricsValueForDisplay = (
  value,
  {
    metric,
    model,
    measurementModel,
    measurement: measurementParam,
    kmetricsQuery = {},
    rollup,
    prefixes = {},
    sampleAdjusted = false
  } = {},
  allowRichContent = false
) => {
  const measurement = kmetricsQuery.measurement || measurementParam;
  if (!measurement || !metric || !measurementModel) {
    return value;
  }

  const { Unit, Type, Values } = measurementModel?.storage?.Metrics?.[metric] || {};
  const { window, rollups } = kmetricsQuery;
  const windowAgg = window?.fn?.[metric];
  let rollupAgg;
  if (rollup) {
    rollupAgg = rollups?.[rollup]?.aggregate;
  }

  const topAverageValue = rollupAgg === 'avg' && model && Values ? getTopAverageValue(model, rollup, Values) : null;
  let valueToRender = value;

  if (Unit === 'percent') {
    valueToRender = percentToText(value);
  } else if (Unit?.startsWith('timestamp')) {
    // ex. timestamp:nanoseconds
    const [, unit] = Unit.split(':');
    // factor to get from given unit to milliseconds
    const factors = { seconds: 1000, milliseconds: 1, microseconds: 0.001, nanoseconds: 0.000001 };
    const factor = factors[unit || 'nanoseconds'] || 1;
    valueToRender = formatDateTime(value * factor);
  } else if (Unit === 'duration') {
    valueToRender = moment.duration(value, 'seconds').humanize({ d: 365 });
  } else if (Unit === 'duration:microseconds') {
    valueToRender = `${zeroToText(!sampleAdjusted ? value / 1000 : value, { fix: 2 })} ms`;
  } else if (DeviceStatusTagMetrics[measurement]?.includes(metric)) {
    const statusValue = topAverageValue?.value ?? value;
    const valueName = Values?.[statusValue];
    const fallbackValue = deviceStatusTagFallback(value, measurement, metric);
    if (!allowRichContent) {
      return fallbackValue;
    }
    valueToRender = valueName ? (
      <DeviceStatusTag value={valueName} percentage={topAverageValue?.percentage} />
    ) : (
      fallbackValue
    );
  } else if (allowRichContent && InterfaceStatusTagMetrics[measurement]?.includes(metric)) {
    const statusValue = topAverageValue?.value ?? value;
    const valueName = Values?.[statusValue];
    valueToRender = <InterfaceOperAdminStatusTag value={valueName} percentage={topAverageValue?.percentage} />;
  } else if (allowRichContent && SessionStateRendererMetrics[measurement]?.includes(metric)) {
    const sessionValue = topAverageValue?.value ?? value;
    valueToRender = (
      <SessionStateRenderer value={sessionValue} percentage={topAverageValue?.percentage} availableStates={Values} />
    );
  } else if (Unit in prefixes) {
    const greekResult = zeroToText(adjustByGreekPrefix(value, prefixes[Unit]));
    valueToRender = `${greekResult} ${prefixes[Unit]}`;
  } else if (topAverageValue) {
    valueToRender = `${startCase(Values[topAverageValue.value])} (${percentToText(topAverageValue.percentage)})`;
  } else if (Values && isNumber(value) && Number.isInteger(Number(value))) {
    const valueName = Values[parseInt(value, 10).toString()];
    valueToRender = valueName ? `${valueName} (${value})` : value;
  } else if (Type === 'float' || rollupAgg === 'avg' || windowAgg === 'avg' || windowAgg === 'rate') {
    valueToRender = zeroToText(value, { fix: 2, useAbsVal: false, useAbsParens: false });
  }

  return valueToRender;
};

export function formatMetricsValueForTooltip(value, { metric, model, measurementModel, rollup }) {
  const { Values } = measurementModel?.storage?.Metrics?.[metric] || {};

  const averageValues = model.get(`${rollup}_values`);

  if (averageValues && Object.keys(averageValues).length > 0) {
    return Object.entries(averageValues)
      .filter(([, percentage]) => percentage > 0)
      .map(([v, percentage]) => `${startCase(Values[v])}: ${percentToText(percentage * 100, { fix: 2 })}`)
      .join('\n');
  }

  return value;
}
