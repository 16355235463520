import lazyLoad from 'core/util/lazyLoad';

const GeoHeatMapView = lazyLoad(() => import('./GeoHeatMap'));

export default GeoHeatMapView;

export const config = {
  showTotalTrafficOverlay: false,
  showLegend: true,
  timeBased: false,
  isSVG: false,
  enableToggle: true,
  buckets: [
    {
      name: 'HeatMap'
    }
  ]
};
