import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PopoverInteractionKind, Position } from '@blueprintjs/core';

import { getBaseSorts } from 'app/stores/query/ExplorerQueryModel';
import { Box, Button, Card, Flex, Popover, Text } from 'core/components';
import { Field, Select, Switch, formConsumer } from 'core/form';
import MetricSelectorBase from './MetricSelectorBase';
import { MetricRenderer, MetricsRenderer, getAggregateTypeLabel, getAggregateUnitLabel } from './metricRenderers';

const dialogStyle = {
  width: 'calc(100vw - 80px)',
  maxHeight: 'calc(100vh - 80px)',
  maxWidth: 1300
};

const helpContents = (
  <Box p={2}>
    The metric(s) you select below will be shown in the Chart Legend (or Table widget).
    <br />
    The order of your selected metrics determines the order they appear in the legend. <br />
    You can use the shortcut menu below to automatically select preset groups of metrics.
  </Box>
);

@inject('$dataviews', '$dictionary', '$explorer', '$auth')
@formConsumer
@observer
class MetricSelector extends Component {
  static defaultProps = {
    multi: true,
    readOnly: false,
    field: undefined,
    fieldName: 'aggregateTypes',
    aggregateThresholdsFieldName: 'aggregateThresholds',
    rootPrefix: undefined,
    noSelectedValuesText: 'No metrics selected',
    reorderable: true,
    showClearButton: false,
    isPopover: false,
    itemFlexed: true,
    rules: undefined,
    allowSelectAll: true,
    showTools: true,
    showPrimaryDisplaySortMetric: true,
    allowMinimumThresholds: true,
    onUnitsLegacyChange: () => {}
  };

  componentDidMount() {
    this.updateThresholdFieldStates();
  }

  componentDidUpdate() {
    this.updateThresholdFieldStates();
  }

  updateThresholdFieldStates() {
    const { form, rootPrefix, vizType, $dictionary } = this.props;
    const aggTypes = this.getField().getValue();
    const thresholds = this.getFieldValue('aggregateThresholds');
    const aggregates = getBaseSorts(aggTypes, vizType || this.getFieldValue('viz_type'));

    form
      .getFields()
      .filter((field) =>
        field.name.startsWith(rootPrefix ? `${rootPrefix}.aggregateThresholds.` : 'aggregateThresholds.')
      )
      .map((f) => (rootPrefix ? f.name.replace(`${rootPrefix}.`, '') : f.name))
      .forEach((name) => {
        if (!aggTypes.includes(name.split('.')[1])) {
          form.removeField(rootPrefix ? `${rootPrefix}.${name}` : name);
        }
      });
    aggTypes.forEach((agg) => {
      const fieldName = rootPrefix ? `${rootPrefix}.aggregateThresholds.${agg}` : `aggregateThresholds.${agg}`;
      const matchingAgg = aggregates.find((a) => a.value === agg);
      if (matchingAgg && !form.getFields().find((field) => field.name === fieldName)) {
        const { origLabel, unit } = matchingAgg;
        const unitLabel = $dictionary.get(`units.${unit}`);
        form.addField(
          fieldName,
          {
            defaultValue: 0,
            label: 'Set Minimum Threshold',
            helpText: `Excludes any series where ${origLabel} ${unitLabel} falls below the specified value.`,
            rules: 'numeric|min:0',
            transform: { out: (value) => parseFloat(value) }
          },
          thresholds[agg],
          { validate: false }
        );
      }
    });
  }

  getField(name) {
    const { form, field, fieldName, rootPrefix } = this.props;

    if (field) {
      return field;
    }

    return rootPrefix ? form.getField(`${rootPrefix}.${name || fieldName}`) : form.getField(name || fieldName);
  }

  getFieldValue(name) {
    return this.getField(name).getValue();
  }

  getFieldNameString(name) {
    const { rootPrefix } = this.props;
    return rootPrefix ? `${rootPrefix}.${name}` : name;
  }

  getTools = () => (
    <Flex alignItems="center" className="metric-selector-tools">
      <Field
        name={this.getFieldNameString('unitsLegacy')}
        onChange={(field, value) => {
          const { onUnitsLegacyChange } = this.props;
          onUnitsLegacyChange(field, value);
          this.updateThresholdFieldStates();
        }}
        m={0}
        inputStyle={{ minWidth: 150 }}
        large
        inline
      >
        <Select />
      </Field>
      <Button icon="remove" text="Clear Selections" onClick={this.handleClearValues} ml={1} />
    </Flex>
  );

  getBaseSorts() {
    const { vizType } = this.props;
    const viz_type = vizType || this.getFieldValue('viz_type');
    return getBaseSorts(this.getField().getValue(), viz_type);
  }

  getOutsortOptions(disabledValue) {
    const baseSorts = this.getBaseSorts();
    const options = [];

    if (disabledValue) {
      options.push({
        label: 'None',
        value: ''
      });
    }

    return options.concat(
      baseSorts.map((option) => {
        const labelJSXString = (
          <>
            {getAggregateTypeLabel({ aggregate: option, useLineBreaks: false })}{' '}
            {option.value && getAggregateUnitLabel({ aggregate: option, useLineBreaks: false })}
          </>
        );

        return {
          ...option,
          value: option.value === 'sum_logsum' ? `${option.value}_${option.unit}` : option.value,
          disabled: option.value === disabledValue,
          labelString: labelJSXString,
          label: <span>{labelJSXString}</span>
        };
      })
    );
  }

  getSelectedUnits() {
    const baseSorts = this.getBaseSorts();

    return baseSorts.reduce(
      (units, { unit }) => (unit && unit !== 'kt_intell_order' && !units.includes(unit) ? units.concat(unit) : units),
      []
    );
  }

  getSelectedUnitOptions() {
    const { $dictionary } = this.props;
    const baseSorts = this.getBaseSorts();
    const outsort = this.getFieldValue('outsort');
    let outsortAggregate = baseSorts.find((agg) => agg.value === outsort) || baseSorts[0];
    if (outsort.startsWith('sum_logsum')) {
      outsortAggregate = { unit: outsort.replace('sum_logsum_', ''), fn: 'logsum' };
    }

    return [
      {
        label: 'None',
        value: ''
      }
    ].concat(
      this.getSelectedUnits().reduce((options, unit) => {
        if (outsortAggregate && unit === outsortAggregate.unit) {
          return options;
        }

        const matchedAggregate =
          baseSorts.find((agg) => agg.unit === unit && agg.fn === outsortAggregate.fn) ||
          baseSorts.find((agg) => agg.unit === unit);

        let label = $dictionary.get(`units.${unit}`);
        if (unit.startsWith('in_')) {
          label = `Ingress ${label}`;
        } else if (unit.startsWith('out_')) {
          label = `Egress ${label}`;
        }

        options.push({
          label,
          value: matchedAggregate.value
        });

        return options;
      }, [])
    );
  }

  handleOutsortChange = (field, value) => {
    const selectedUnitOptions = this.getSelectedUnitOptions();
    const secondaryOutsortField = this.getField('secondaryOutsort');
    const secondaryOutsort = secondaryOutsortField.getValue();
    if (value === secondaryOutsort || !selectedUnitOptions.find((opt) => opt.value === secondaryOutsort)) {
      secondaryOutsortField.setValue('');
    }
  };

  handleSecondaryOutsortChange = (field, value) => {
    if (!value) {
      this.getField('secondaryTopxSeparate').setValue(false);
      this.getField('secondaryTopxMirrored').setValue(false);
    }
  };

  renderButton = () => {
    const { $auth } = this.props;
    const { defaultAggregates } = $auth.userSettings;

    if (defaultAggregates?.length > 0) {
      return (
        <Popover
          content={this.popoverContent()}
          position={Position.TOP_LEFT}
          interactionKind={PopoverInteractionKind.HOVER}
        >
          <Button icon="folder-open" text="Load Defaults" onClick={this.handleLoadDefaults} ml={1} />
        </Popover>
      );
    }
    return <Button icon="folder-open" disabled text="Load Defaults" onClick={this.handleLoadDefaults} ml={1} />;
  };

  popoverContent = () => {
    const { $auth } = this.props;

    const { defaultAggregates, defaultAggregateSorts } = $auth.userSettings;

    const alignBothList = defaultAggregates?.map(({ origLabel, group, value }) => {
      let leftItem = <span>{group}</span>;
      if (value === defaultAggregateSorts.outsortValue) {
        leftItem = (
          <>
            <Text color="primary" fontWeight={700} fontSize="12px" fontStyle="normal">
              {'\u2022'}
            </Text>{' '}
            1. {group}
          </>
        );
      }
      if (value === defaultAggregateSorts.secondaryOutsortValue) {
        leftItem = (
          <>
            <Text color="warning" fontWeight={700} fontSize="12px" fontStyle="normal">
              {'\u2022'}
            </Text>{' '}
            2. {group}
          </>
        );
      }

      return (
        <Flex key={`row-${origLabel}`}>
          <Box width={240} fontWeight="bold" verticalAlign="center">
            {leftItem}
          </Box>
          <Box width={140} verticalAlign="center" lineHeight="16px">
            {origLabel}
          </Box>
        </Flex>
      );
    });

    return (
      <Flex p={3} flexDirection="column" maxWidth="60vw" maxHeight="60vh" overflow="auto" fontSize="small">
        <Box>{alignBothList}</Box>
      </Flex>
    );
  };

  getConditionalControls = () => (
    <Card p={2} display="flex" flexDirection="column">
      <Box fontWeight="bold" mb="4px">
        Default Metrics
      </Box>
      <Flex>
        <Button
          icon="floppy-disk"
          text="Set as Default"
          title="Saves the Selected Metrics as default for future queries"
          onClick={this.handleSaveDefaults}
        />
        {this.renderButton()}
      </Flex>
      <Box mt="4px" fontSize="small">
        Saves & loads Metrics as default, including primary and secondary
      </Box>
    </Card>
  );

  getBottomTools = () => {
    const { vizType } = this.props;
    const outsort = this.getFieldValue('outsort');
    const secondaryOutsort = this.getFieldValue('secondaryOutsort');
    const secondaryTopxSeparate = this.getFieldValue('secondaryTopxSeparate');
    // const viz_type = this.getFieldValue('viz_type');
    const viz_type = vizType || this.getFieldValue('viz_type');
    const aggregateTypes = this.getField().getValue();

    if (!aggregateTypes.length || (!outsort.startsWith('sum_logsum') && !aggregateTypes.includes(outsort))) {
      return null;
    }

    const { $dataviews, $dictionary } = this.props;
    const { allowsSecondaryOverlay, suppressSecondaryTopxSeparate, buckets, timeBased } =
      $dataviews.getConfig(viz_type);
    let outsortHelpText = '';
    if (!timeBased && outsort.startsWith('sum_logsum')) {
      const vizLabel = $dataviews.getViewDisplayLabel(viz_type);
      const metricLabel = $dictionary.get(`units.${outsort.replace('sum_logsum_', '')}`);
      outsortHelpText = `With Kentik IntelliSort enabled, your ${vizLabel} will display values for the first ${metricLabel} metric in your Selected Metrics list.`;
    }

    const selectedUnits = this.getSelectedUnits();

    const secondaryOutsortOptions = secondaryTopxSeparate
      ? this.getOutsortOptions(outsort)
      : this.getSelectedUnitOptions();
    const secondaryMirrorable =
      secondaryOutsort && buckets.some((bucket) => bucket.secondaryMirrorBucket !== undefined);

    return (
      <Card p={2}>
        <Field
          options={this.getOutsortOptions()}
          name={this.getFieldNameString('outsort')}
          helpText={outsortHelpText}
          onChange={this.handleOutsortChange}
          maxWidth={268}
        >
          <Select menuWidth={265} />
        </Field>
        {allowsSecondaryOverlay && selectedUnits.length > 1 && (
          <Field
            options={secondaryOutsortOptions}
            name={this.getFieldNameString('secondaryOutsort')}
            label={`Secondary Display ${secondaryTopxSeparate ? '& Sort ' : ''}Metric`}
            onChange={this.handleSecondaryOutsortChange}
          >
            <Select menuWidth={265} />
          </Field>
        )}
        {allowsSecondaryOverlay &&
          suppressSecondaryTopxSeparate !== true &&
          selectedUnits.length > 1 &&
          secondaryOutsort && (
            <Field
              name={this.getFieldNameString('secondaryTopxSeparate')}
              style={{ marginBottom: secondaryMirrorable ? 16 : 0, maxWidth: 268 }}
            >
              <Switch />
            </Field>
          )}
        {secondaryMirrorable && (
          <Field flex={1} name={this.getFieldNameString('secondaryTopxMirrored')} style={{ marginBottom: 0 }}>
            <Switch />
          </Field>
        )}
      </Card>
    );
  };

  handleClearValues = () => {
    const { onChange } = this.props;
    const field = this.getField();
    field.onChange([]);
    if (onChange) {
      onChange(field, []);
    }
  };

  handleLoadDefaults = () => {
    const { $explorer } = this.props;

    if ($explorer) {
      $explorer.loadDefaults();
    }
  };

  handleSaveDefaults = () => {
    const { $auth } = this.props;
    const { vizType } = this.props;
    const aggTypes = this.getField().getValue();

    const aggregateThresholds = this.getFieldValue('aggregateThresholds');
    const aggregates = getBaseSorts(aggTypes, vizType || this.getFieldValue('viz_type'));

    const outsortField = this.getField('outsort');
    const secondaryOutsortField = this.getField('secondaryOutsort');
    const secondaryTopxSeparate = this.getFieldValue('secondaryTopxSeparate');

    $auth.setUserSettings({
      defaultAggregateTypes: aggTypes,
      defaultAggregates: aggregates,
      defaultAggregateThresholds: aggregateThresholds,
      defaultAggregateSorts: {
        outsortValue: outsortField.getValue(),
        secondaryOutsortValue: secondaryOutsortField.getValue(),
        secondaryTopxSeparateValue: secondaryTopxSeparate
      }
    });
  };

  render() {
    return (
      <MetricSelectorBase
        {...this.props}
        tools={this.getTools}
        bottomTools={this.getBottomTools}
        conditionalControls={this.getConditionalControls}
        multiValuesRenderer={MetricsRenderer}
        valueRenderer={MetricRenderer}
        helpContents={helpContents}
        dialogStyle={dialogStyle}
      />
    );
  }
}

export default MetricSelector;
