import React from 'react';
import { Box, Button, Flex, Text } from 'core/components';

const InterfaceGroupListItem = (props) => {
  const { intf, isLast, onRemoveInterface } = props;

  return (
    <Flex
      borderTop="thin"
      borderBottom={isLast ? 'thin' : undefined}
      alignItems="center"
      justifyContent="space-between"
      overflow="hidden"
      px={2}
      py={1}
      minHeight={48}
    >
      <Box fontSize="small">
        <Text as="div">{intf.interface_description}</Text>
        {intf.snmp_alias && <Text muted>{intf.snmp_alias}</Text>}
      </Box>
      {onRemoveInterface && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onRemoveInterface(intf);
          }}
          icon="cross"
          intent="danger"
          small
          minimal
        />
      )}
    </Flex>
  );
};

export default InterfaceGroupListItem;
