import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Box, Link, Text } from 'core/components';
import LabelList from 'app/components/labels/LabelList';
import { getTestTypeLabel, getTypeAndTargetDisplay } from 'app/views/synthetics/utils/syntheticsUtils';

class BaseTestTableNameRenderer extends Component {
  componentDidUpdate(prevProps) {
    const { labels, updateGrid } = this.props;
    if (labels.length !== prevProps.labels.length && updateGrid && typeof updateGrid === 'function') {
      updateGrid();
    }
  }

  render() {
    const { display_name, id, labels, target, test_type, labelFilters } = this.props;
    const typeAndTarget = getTypeAndTargetDisplay(test_type, target);

    return (
      <Box width="100%" overflow="hidden">
        <Text as="div" muted ellipsis>
          {getTestTypeLabel(test_type)}
          {typeAndTarget}
        </Text>
        <Link
          to={`/v4/synthetics/tests/${id}/results`}
          data-test-type={test_type}
          fontWeight="bold"
          display="block"
          ellipsis
        >
          {display_name}
        </Link>
        <LabelList labels={labels} fixedWidth priorityLabels={labelFilters} />
      </Box>
    );
  }
}

// NOTE: Need to structure TestTableNameRenderer like this to ensure computed labels trigger componentDidUpdate
const TestTableNameRenderer = observer(({ model, ...rest }) => {
  const { id, labels } = model;
  const target = model.get('config.target.value');
  return (
    <BaseTestTableNameRenderer
      {...rest}
      display_name={model.get('display_name')}
      id={id}
      labels={labels}
      target={target}
      test_type={model.get('test_type')}
      labelFilters={model.collection.discreteFilterValues.labelMultiSelect}
    />
  );
});

export default TestTableNameRenderer;
