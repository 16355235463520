import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Flex, Spinner, Suspense, Text, Box, Icon, Sparkline, Button, MenuItem, Heading } from 'core/components';
import { Field, FormComponent, Select } from 'core/form';
import Model from 'core/model/Model';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import { getAggregateUnitLabel } from 'app/components/metric/metricRenderers';
import { zeroToText, adjustByGreekPrefix, getToFixed } from 'app/util/utils';
import storeLoader from 'app/stores/storeLoader';
import { addFilters } from 'app/stores/query/FilterUtils';
import WidgetFrame from '../WidgetFrame';

const FORM_OPTIONS = {
  name: 'InboundOutbound Widget Config'
};

function getValue(seriesOrValue, results, queryModel) {
  const [aggregateType] = queryModel.get('aggregateTypes');
  return zeroToText(
    adjustByGreekPrefix(
      seriesOrValue.get ? seriesOrValue.get(aggregateType) : seriesOrValue,
      results.prefix[queryModel.outsortUnit]
    ),
    {
      fix: getToFixed(queryModel.outsortUnit)
    }
  );
}

function DetailMenuItem({ source, history }) {
  return (
    <MenuItem
      icon="series-search"
      onClick={() => history.push(`/v4/core/quick-views/sites/${source.id}`)}
      text="View Site Details"
    />
  );
}

function SourceHeader({ source }) {
  if (!source) {
    return (
      <Heading level={6} mb={0}>
        Select Source
      </Heading>
    );
  }

  return (
    <Flex alignItems="center">
      <Icon icon="map-marker" color="muted" mr="4px" />
      <Heading level={6} mb={0}>
        {source.get('title')}
      </Heading>
    </Flex>
  );
}

function Result({ value, row }) {
  const name = row.get('key');
  return (
    <Flex alignItems="center" justifyContent="space-between" color="muted" mb="4px">
      <Text>{name.charAt(0).toUpperCase() + name.slice(1)}</Text>
      <Text alignItems="center" textAlign="right">
        <Text>{value}</Text>
      </Text>
    </Flex>
  );
}

@storeLoader('$sites')
@inject('$query')
@withRouter
@observer
class SiteTrafficProfileWidget extends Component {
  state = {
    loading: true,
    isConfigurePanelOpen: false,
    queryOverrides: {
      lookback_seconds: 86400,
      aggregateType: 'max_bits_per_sec'
    }
  };

  static getDerivedStateFromProps(props, state) {
    const { config } = props;
    // If there's no config in state, let's get setup
    if (!state.config) {
      // If there's no config, we show the config panel
      if (!config) {
        return { config: {}, isConfigurePanelOpen: true, model: new Model() };
      }
      return { config, model: new Model(config) };
    }

    return null;
  }

  componentDidMount() {
    const { $sites } = this.props;
    const { config } = this.state;
    const { siteOptions } = $sites;

    if (config.site_id) {
      const source = $sites.collection.models.find((site) => site.get('id') === config.site_id);
      this.setState({ loading: false, source, siteOptions });
    } else {
      this.setState({ loading: false, siteOptions });
    }
  }

  handleSave = (form) => {
    const { $sites, onConfigChange } = this.props;
    const { model } = this.state;

    const config = form.getValues();
    model.set(config);

    const newSource = $sites.collection.models.find((site) => site.get('id') === config.site_id);
    this.setState({ isConfigurePanelOpen: false, config, source: newSource });

    if (onConfigChange) {
      onConfigChange(config);
    }
  };

  handleCancel = (form) => {
    form.reset();
    this.setState({ isConfigurePanelOpen: false });
  };

  handleShowConfigurePanel = () => {
    this.setState({ isConfigurePanelOpen: true });
  };

  renderConfigurePanel() {
    const { siteOptions, model, config } = this.state;
    const fields = {
      site_id: {
        label: 'Site',
        options: siteOptions
      }
    };

    return (
      <FormComponent fields={fields} options={FORM_OPTIONS} model={model}>
        {({ form }) => (
          <Flex flexDirection="column" flex={1}>
            <Box flex={1}>
              <Field name="site_id" large>
                <Select />
              </Field>
            </Box>
            <Flex pb={2}>
              {config.source_type && (
                <Button text="Cancel" onClick={() => this.handleCancel(form)} mr={1} minWidth={100} />
              )}
              <Button
                text="Save"
                intent="primary"
                disabled={!form.valid}
                onClick={() => this.handleSave(form)}
                minWidth={100}
              />
            </Flex>
          </Flex>
        )}
      </FormComponent>
    );
  }

  renderSparkline(results, queryModel) {
    const { prefix } = results;
    const [aggregateType] = queryModel.get('aggregateTypes');
    const [aggregate] = queryModel.aggregates;
    const [totalOverlay] = results.overlayRows;
    const total = (totalOverlay && totalOverlay.get(aggregateType)) || 0;

    const [, ...aggTypeParts] = aggregateType.split('_');
    const rawDataAttr = `rawData.both_${aggTypeParts.join('_')}.flow`;

    const sparklineData =
      totalOverlay && totalOverlay.get(rawDataAttr) ? totalOverlay.get(rawDataAttr).map((col) => col[1]) : [];

    return (
      <>
        <Flex
          position="absolute"
          left={0}
          bottom={0}
          flexDirection="column"
          justifyContent="flex-end"
          width="100%"
          overflow="hidden"
        >
          <Sparkline data={sparklineData} strokeWidth={1.5} height={25} margin={4} color="primary" />
        </Flex>

        <Flex justifyContent="flex-end" color="muted" fontSize="small" mb="4px">
          {getAggregateUnitLabel({ aggregate, prefix })}
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mb="4px">
          <Text fontWeight="bold">Total</Text>
          <Text fontWeight="bold" alignItems="center" textAlign="right">
            <Text>{getValue(total, results, queryModel)}</Text>
          </Text>
        </Flex>

        {results.nonOverlayRows.map((row) => {
          const value = getValue(row, results, queryModel);
          return <Result key={row.id} row={row} value={value} />;
        })}
      </>
    );
  }

  renderData() {
    const { $query } = this.props;
    const { queryOverrides, isConfigurePanelOpen, source } = this.state;
    if (!source || isConfigurePanelOpen) {
      return null;
    }

    const updatedQueryOverrides = Object.assign({}, queryOverrides, {
      filterDimensionsEnabled: false,
      metric: ['simple_trf_prof']
    });

    const query = $query.get('inboundOutboundInternalQuery', updatedQueryOverrides);
    addFilters(query, [
      {
        filterField: 'i_device_site_name',
        operator: '=',
        filterValue: `${source.get('title')}`
      }
    ]);

    return (
      <LightweightDataViewWrapper query={query}>
        {({ loading, results, queryModel }) => (
          <Suspense loading={loading} fallback={<Spinner size={24} />}>
            {this.renderSparkline(results, queryModel)}
          </Suspense>
        )}
      </LightweightDataViewWrapper>
    );
  }

  render() {
    const { canCustomize, onRemove, history } = this.props;
    const { isConfigurePanelOpen, loading, source, config } = this.state;
    const tooltip =
      'Provides an at-a-glance view of the total traffic of your site, broken out by various traffic profiles.';

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        menuOptions={<DetailMenuItem source={source} type={config.source_type} history={history} />}
        configAction={this.handleShowConfigurePanel}
        onRemove={onRemove}
        title={<SourceHeader source={source} type={config.source_type} />}
        titleOverride
        tooltip={tooltip}
        display="flex"
        flexDirection="column"
        position="relative"
        pt={1}
        px={2}
        pb={0}
      >
        <Suspense
          loading={loading}
          fallback={
            <Box pt={2}>
              <Spinner size={24} />
            </Box>
          }
        >
          {(isConfigurePanelOpen || !source) && this.renderConfigurePanel()}
          {!isConfigurePanelOpen && this.renderData()}
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default SiteTrafficProfileWidget;
