import React from 'react';
import { inject, observer } from 'mobx-react';
import Collection from 'core/model/Collection';
import { Box, Button, Card, Flex, Heading, Icon, Link, Spinner, Tag, Text, showErrorToast } from 'core/components';
import { Table } from 'core/components/table';
import { CLOUD_PROVIDERS } from 'app/util/constants';

@inject('$clouds')
@observer
export default class ConfigStatusOverview extends React.Component {
  static defaultProps = {
    showTitle: false
  };

  state = {
    loading: true
  };

  collection = new Collection([], { groupBy: 'cloudProvider' });

  componentDidMount() {
    this.loadCloudConfigStatus();
  }

  loadCloudConfigStatus({ force = false } = {}) {
    const { $clouds } = this.props;

    this.setState({ loading: true }, () => {
      Promise.all(
        $clouds.configStatusCloudProviders.map((cloudProvider) =>
          $clouds.getCloudConfigStatus({ cloudProvider, force }).then(({ statusData }) => ({
            cloudProvider,
            data: statusData
          }))
        )
      )
        .then((results) => {
          const data = results.flatMap((r) => this.processStatusData(r));
          this.collection.processData(data);
        })
        .catch((e) => {
          showErrorToast('Error occurred attempting to process the cloud config status results');
          console.error(e);
        })
        .finally(() => this.setState({ loading: false }));
    });
  }

  handleRefreshConfigStatus = () => {
    this.loadCloudConfigStatus({ force: true });
  };

  getExportStatus(exports) {
    return exports.reduce(
      (acc, model) => {
        if (model.get('enabled') && model.get('properties.task_status') === 'ERROR') {
          acc.error += 1;
        }

        return acc;
      },
      { error: 0 }
    );
  }

  processStatusData({ cloudProvider, data }) {
    if (!data || data.length === 0) {
      return [];
    }

    const statusData = {};
    data.forEach((acct) => {
      const { apiStatus, flowStatus, region, accountId, exports } = acct;

      if (!statusData[region]) {
        statusData[region] = {
          api: { success: 0, warning: 0, error: 0, critical: 0 },
          flow: { success: 0, error: 0, none: 0 },
          accountId,
          cloudExport: this.getExportStatus(exports),
          total: 0,
          region,
          cloudProvider
        };
      }

      statusData[region].api[apiStatus.toLowerCase()] += 1;
      statusData[region].flow[flowStatus.toLowerCase()] += 1;
      statusData[region].total += 1;
    });

    return Object.values(statusData);
  }

  get columns() {
    return [
      {
        name: 'region',
        label: 'Region',
        width: 100,
        renderer: ({ model }) => (
          <Flex flexDirection="column">
            <Text>{model.get('region')}</Text>
            <Text small muted fontSize="10px">
              {model.get('accountId')}
            </Text>
          </Flex>
        )
      },

      /*
        Creates a list of tags to indicate api and flow status.
        When there are API severity of type 'warning' or 'error', the label will be pluralized if the count requires, the rest are left as-is.
        For example, we could have "2 api warnings" and "5 api critical"
      */
      {
        name: 'status',
        label: 'Status',
        wrapText: true,
        renderer: ({ model }) => {
          const api = model.get('api');
          const flow = model.get('flow');
          const cloudExport = model.get('cloudExport');
          const total = model.get('total');

          const tagProps = { m: '4px', minimal: true };
          const tags = [];

          if (api.success === total && flow.success + flow.none === total && cloudExport.error === 0) {
            return (
              <Tag intent="success" {...tagProps}>
                No Issues
              </Tag>
            );
          }

          // fill in any api problem tags
          ['warning', 'error', 'critical'].forEach((severity) => {
            const itemCount = api[severity];

            if (itemCount > 0) {
              const intent = severity === 'warning' ? 'warning' : 'danger';
              let label = `${api[severity]} api ${severity}`;

              if (severity === 'warning' || severity === 'error') {
                // pluralize if need be
                label = `${label}${api[severity] === 1 ? '' : 's'}`;
              }

              tags.push(
                <Tag key={`api-${severity}`} intent={intent} {...tagProps}>
                  {label}
                </Tag>
              );
            }
          });

          // check for flow errors
          if (flow.error > 0) {
            tags.push(<Tag key="flow-error" intent="danger" {...tagProps}>{`${flow.error} missing flow`}</Tag>);
          }

          // check for export errors
          if (cloudExport.error > 0) {
            tags.push(
              <Tag key="cloud-export-error" intent="danger" {...tagProps}>{`${cloudExport.error} export error${
                cloudExport.error > 1 ? 's' : ''
              }`}</Tag>
            );
          }

          return tags;
        }
      }
    ];
  }

  // Returns either a success tick icon for error-free conditions, or a tag with the rolled up count of issues, colored by severity
  getIssueRollup(group = []) {
    let count = 0;
    let intent = 'warning';

    group.forEach((model) => {
      const { critical, error, warning } = model.get('api');
      const { error: flowErrors } = model.get('flow');
      const { error: exportErrors } = model.get('cloudExport');
      const majorErrors = critical + error + flowErrors + exportErrors;

      count += majorErrors + warning;

      // if we have errors above warning level, bump up the intent
      if (majorErrors > 0) {
        intent = 'danger';
      }
    });

    if (count > 0) {
      return (
        <Tag intent={intent} round>
          {count}
        </Tag>
      );
    }

    return <Icon icon="tick-circle" intent="success" />;
  }

  groupSummary = ({ groupKey, group }) => {
    const { logo, code } = CLOUD_PROVIDERS.byId(groupKey);
    const label = groupKey !== 'aws' ? code : null; // the aws logo already says 'aws'

    return (
      <Flex alignItems="center" justifyContent="space-between" position="sticky" top={0} left={0}>
        <Flex alignItems="center">
          {logo}
          <Text pl={1}>{label}</Text>
          <Box pl={1}>{this.getIssueRollup(group)}</Box>
        </Flex>
        <Link to={`/v4/settings/cloud-config/${groupKey}`}>View Details</Link>
      </Flex>
    );
  };

  render() {
    const { $clouds, showTitle } = this.props;
    const { loading } = this.state;

    return (
      <Card display="flex" flexDirection="column" overflow="hidden" {...this.props}>
        {showTitle && (
          <Flex justifyContent="space-between" borderBottom="thin">
            <Heading m={1} level={5} fontWeight="heavy">
              Cloud Config Status
            </Heading>

            <Button
              icon="refresh"
              intent="success"
              onClick={this.handleRefreshConfigStatus}
              disabled={loading}
              minimal
            />
          </Flex>
        )}
        {loading && (
          <Flex flexDirection="column" flex={1} alignItems="center" justifyContent="center">
            <Spinner size={24} />
            <Text pt={1} muted>
              Checking APIs... This may take a minute...
            </Text>
          </Flex>
        )}
        {!loading && (
          <Table
            collection={this.collection}
            columns={this.columns}
            groupSummaryLookup={this.groupSummary}
            selectOnRowClick={false}
            hideHeader
            style={{ overflow: 'auto' }}
            css={{
              '.group-summary-wrapper': { flex: 1 }
            }}
            isCollapsed={$clouds.configStatusCloudProviders.length > 1}
          />
        )}
      </Card>
    );
  }
}
