import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Box, Button, Flex, Heading, Icon, Text } from 'core/components';

@inject('$auth')
@observer
class EnvironmentCard extends Component {
  static defaultProps = {
    showLearnMore: false,
    width: 360,
    borderRadius: '4px 0 4px 4px'
  };

  render() {
    const { $auth, showLearnMore, width, borderRadius, children, ...otherProps } = this.props;
    const { envName, envColor, envDesc } = $auth.getEnvironmentSettings();
    const textColor = 'white.8';

    return (
      <Box p={2} width={width} bg={envColor} color={textColor} borderRadius={borderRadius} {...otherProps}>
        <Heading level="6" color={textColor} fontWeight="heavy" fontSize={16}>
          <Flex alignItems="center">
            <Box mr="4px">
              <Icon icon="cube" iconSize={24} color={textColor} />
            </Box>
            Now Using the {envName} Environment
          </Flex>
        </Heading>

        <Box mt={1} ml={1}>
          <Text as="div" mb={1} small color="white.6">
            {envDesc}
          </Text>

          {children}
        </Box>

        {showLearnMore && (
          <Flex justifyContent="flex-end">
            <Button small minimal icon="help" color={textColor} mb="-4px" mr="-4px">
              Learn More
            </Button>
          </Flex>
        )}
      </Box>
    );
  }
}

export default EnvironmentCard;
