/*
  Returns a tag value for a given key
  Currently supports only AWS-style tags
*/
function getTagValue(entity, key) {
  const tags = entity.Tags || entity.TagSet || [];
  const tag = tags.find((t) => t.Key === key);
  return tag ? tag.Value : undefined;
}

module.exports = getTagValue;
