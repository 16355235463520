import React from 'react';
import styled from 'styled-components';
import { space, width, display } from 'styled-system';
import { ControlGroup as BlueprintControlGroup } from '@blueprintjs/core';

import omitFieldProps from './util/omitFieldProps';

const ControlGroup = styled(BlueprintControlGroup)`
  ${display};
  ${space};
  ${width};
`;

const Component = (props) => <ControlGroup {...omitFieldProps(props)} />;

Component.displayName = 'ControlGroup';

export default Component;
