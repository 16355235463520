import { get } from 'lodash';
import { greekPrefix, adjustByGreekPrefix } from 'core/util/greekPrefixing';
import { timezone, DEFAULT_DATETIME_FORMAT, DEFAULT_DATETIME_FORMAT_WITH_SECONDS } from 'core/util/dateUtils';
import { getToFixed, zeroToText, escapeHtml } from 'app/util/utils';

function getTimestamp(point, options = {}) {
  const { offset = 0, showSeconds = false } = options;
  const momentFn = point.series.xAxis.options.momentFn || timezone.momentFn;
  const time = point.x - offset;
  return momentFn(time).format(showSeconds ? DEFAULT_DATETIME_FORMAT_WITH_SECONDS : DEFAULT_DATETIME_FORMAT);
}

function getPointData({ point, getColorFromIndex }) {
  const percentage = point.series.options.stacking === 'percent';

  const prefix = point.series.yAxis.prefix || greekPrefix([percentage ? point.y : point.series.yAxis.tickInterval]);
  point.series.yAxis.prefix = prefix;

  let suffix = (point.series.yAxis.options.title.text || '').replace(/(\u2014|\u2011|\u2192)/g, '').trimEnd();
  if (suffix.includes(' % of ')) {
    [suffix] = suffix.split(' % of ');
  }

  const pointValue = zeroToText(adjustByGreekPrefix(point.y, prefix), {
    fix: getToFixed(point.series.yAxis.options.unit)
  });

  const nameFormatter = get(point, 'series.tooltipOptions.seriesNameFormatter', (name) =>
    name.replace(/----/g, '\u2192')
  );

  return {
    color: getColorFromIndex(point.series.colorIndex),
    name: nameFormatter(point.series.name, point),
    value: `${escapeHtml(pointValue)} ${suffix.includes('sampling rate') ? ': 1 ' : ''}${prefix}${suffix}`,
    percent: percentage ? `${point.percentage.toFixed(1)}%` : null
  };
}

export function sharedTooltipPositioner(labelWidth, labelHeight, point) {
  const chartPos = this.chart.pointer.getChartPosition();
  const mouseX = chartPos.left + this.chart.xAxis[0].pos + point.plotX;
  const mouseY = chartPos.top + this.chart.yAxis[0].pos + point.plotY;

  const windowMargin = 35;

  const x =
    mouseX < window.innerWidth - labelWidth - windowMargin ? mouseX : Math.max(windowMargin, mouseX - labelWidth - 30);
  const y =
    mouseY < window.innerHeight - labelHeight - windowMargin
      ? mouseY
      : Math.max(windowMargin, mouseY - labelHeight - 30);

  return { x, y };
}

export function getSharedTooltip({
  points,
  seriesType = 'line',
  tooltip = '',
  getColorFromIndex,
  showSecondaryPointColor = false,
  showSeconds = false
}) {
  if (!points.length) {
    return tooltip;
  }

  if (!tooltip) {
    const timestamp = getTimestamp(points[0], { showSeconds });
    tooltip = `<span class="bp4-tag bp4-minimal bp4-round bp4-monospace-text timestamp">${timestamp}</span>`;
  }

  const primarySeriesPoints = points.filter(
    (point) => point.series.type === seriesType && point.series.chart.yAxis[0] === point.series.yAxis
  );

  const secondarySeriesPoints = points.filter(
    (point) => point.series.type === seriesType && point.series.chart.yAxis[1] === point.series.yAxis
  );

  const seriesPoints = primarySeriesPoints;

  if (secondarySeriesPoints.length) {
    secondarySeriesPoints.forEach((point) => {
      const matchingPrimarySeries = primarySeriesPoints.find(
        (primaryPoint) => primaryPoint.series.name === point.series.name
      );
      if (matchingPrimarySeries) {
        matchingPrimarySeries.secondary = point;
      } else {
        seriesPoints.push(point);
      }
    });
  }

  const rows = seriesPoints.reduce((acc, point) => {
    const { color, name, value: pointValue, percent } = getPointData({ point, getColorFromIndex });
    let rowMarkup = `
      <div class="series-row">
        <div class="series-marker" style="color:${color};">\u25CF</div>
        <div class="series-name">${name}</div>
        ${percent ? `<div class="series-value">${percent}</div>` : ''}
        <div class="series-value">${pointValue}</div>`;

    if (point.secondary) {
      const {
        color: secondaryColor,
        name: secondaryName,
        value: secondaryPointValue,
        percent: secondaryPercent
      } = getPointData({
        point: point.secondary,
        getColorFromIndex
      });

      let secondarySeparator = seriesPoints.length === 1 ? '</div><div class="series-row">' : ', ';

      if (showSecondaryPointColor) {
        secondarySeparator = '';
        rowMarkup += `
          </div>
          <div class="series-row">
            <div class="series-marker" style="color:${secondaryColor};">\u25CF</div>
            <div class="series-name">${secondaryName}</div>`;
      }

      rowMarkup += `
        ${secondarySeparator}
        ${secondaryPercent ? `<div class="series-value">${secondaryPercent}</div>` : ''}
        <div class="series-value">${secondaryPointValue}</div>`;
    }

    rowMarkup += '</div>';

    return `${acc}${rowMarkup}`;
  }, '');

  return `${tooltip}${rows}`;
}

export function getPeriodOverPeriodTooltip({ points, offset, getColorFromIndex, showSeconds }) {
  if (points.length === 0) {
    return '';
  }

  const legendSpace = '\u00A0\u00A0\u00A0';
  const solidLegend = '\u2014';
  const dashLegend = '\u2011\u2011\u2011';

  const currentLabel = `<div class="period-over-period-label">Current${legendSpace}${solidLegend}</div>`;
  const previousLabel = `<div class="period-over-period-label previous">Previous${legendSpace}${dashLegend}</div>`;

  const currentPoints = points.filter((point) => !point.series.options.isPreviousPeriod);
  const previousPoints = points.filter((point) => point.series.options.isPreviousPeriod);

  const currentPoint = currentPoints[0];
  const previousPoint = previousPoints[0];

  let tooltip = '';

  // slightly different layout when in comparing mode
  if (
    currentPoints.length === 1 &&
    previousPoints.length === 1 &&
    currentPoint.series.name === previousPoint.series.name
  ) {
    const { color, name, value: currentValue } = getPointData({ point: currentPoint, getColorFromIndex });
    const { value: previousValue } = getPointData({ point: previousPoint, getColorFromIndex });

    const currentTimestamp = getTimestamp(currentPoint, { showSeconds });
    const previousTimestamp = getTimestamp(previousPoint, { offset, showSeconds });

    tooltip += `<div class="series-row comparison-row">
      <div class="series-marker" style="color:${color};">\u25CF</div>
      <div class="series-name">${name}</div>
    </div>`;

    tooltip += currentLabel;
    tooltip += `<div class="series-row comparison-row">
      <div class="bp4-tag bp4-minimal bp4-round bp4-monospace-text timestamp">${currentTimestamp}</div>
      <div class="series-value">${currentValue}</div>
    </div>`;

    tooltip += previousLabel;
    tooltip += `<div class="series-row comparison-row">
      <div class="bp4-tag bp4-minimal bp4-round bp4-monospace-text timestamp">${previousTimestamp}</div>
      <div class="series-value">${previousValue}</div>
    </div>`;

    return tooltip;
  }

  if (currentPoints.length > 0) {
    tooltip += currentLabel;
    const timestamp = getTimestamp(currentPoint, { showSeconds });
    tooltip += `<span class="bp4-tag bp4-minimal bp4-round bp4-monospace-text timestamp">${timestamp}</span>`;

    currentPoints.forEach((point) => {
      const { color, name, value } = getPointData({ point, getColorFromIndex });
      tooltip += `<div class="series-row">
        <div class="series-marker" style="color:${color};">\u25CF</div>
        <div class="series-name">${name}</div>
        <div class="series-value">${value}</div>
        </div>`;
    });
  }

  if (previousPoints.length > 0) {
    tooltip += previousLabel;

    const timestamp = getTimestamp(previousPoint, { offset, showSeconds });
    tooltip += `<span class="bp4-tag bp4-minimal bp4-round bp4-monospace-text timestamp">${timestamp}</span>`;

    previousPoints.forEach((point) => {
      const { color, name, value } = getPointData({ point, getColorFromIndex });
      tooltip += `<div class="series-row">
        <div class="series-marker" style="color:${color};">\u25CF</div>
        <div class="series-name">${name}</div>
        <div class="series-value">${value}</div>
        </div>`;
    });
  }

  return tooltip;
}
