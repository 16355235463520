import centroid from '@turf/centroid';
import { multiPoint } from '@turf/helpers';
import { Model } from 'core/model';
import { getIPSortValue } from 'core/util/sortUtils';
import { max } from 'lodash';
import { computed } from 'mobx';
import PeeringFacilityCollection from './PeeringFacilityCollection';
import PeeringNetworkCollection from './PeeringNetworkCollection';

export default class PeeringExchangeModel extends Model {
  facilities = new PeeringFacilityCollection();

  networks = new PeeringNetworkCollection();

  get hasFetched() {
    return !!this.get('networks');
  }

  get urlRoot() {
    return '/api/ui/peering/peeringdb/exchange';
  }

  get type() {
    return 'exchange';
  }

  get sortValues() {
    return {
      name: () => this.get('name').toLowerCase(),
      speed: () => max(this.speed),
      ipaddr: () => getIPSortValue(this.lans?.[0].ipaddr4),
      common: () => {
        if (this.get('common')) {
          return 0;
        }
        if (this.facilities.common.length > 0) {
          return 1;
        }
        return 2;
      }
    };
  }

  get lans() {
    return this.get('lans') || [];
  }

  get speed() {
    return Array.from(new Set(this.lans.map((lan) => lan.speed)));
  }

  @computed
  get isMappable() {
    return this.facilities.some((facility) => facility.isMappable);
  }

  @computed
  get coordinates() {
    const facCoords = this.facilities.models
      .filter((facility) => facility.isMappable)
      .map((facility) => [facility.get('longitude'), facility.get('latitude')]);

    if (facCoords.length > 1) {
      return centroid(multiPoint(facCoords)).geometry.coordinates;
    }

    if (facCoords.length === 1) {
      return facCoords[0];
    }

    return null;
  }

  deserialize(data = {}) {
    const { facilities, networks } = data;

    if (Array.isArray(facilities) && facilities.length > 0) {
      this.facilities.set(facilities);
      data.fac_count = facilities.length;
    }

    if (Array.isArray(networks) && networks.length > 0) {
      this.networks.set(networks);
      data.net_count = networks.length;
    }

    return data;
  }
}
