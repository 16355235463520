import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';
import LinkButton from 'core/components/LinkButton';

const InterfaceLink = ({ $app, snmp_id, interface_description, device_name, as, children, ...rest }) => {
  const content = children || `${interface_description} ${snmp_id ? `(${snmp_id})` : ''}`;

  // Subtenants should not have these links, but also don't show a link if you know it won't work
  if ($app.isSubtenant || !device_name) {
    return content;
  }
  const LinkComponent = as === 'Button' ? LinkButton : Link;

  return (
    <LinkComponent to={`/v4/core/quick-views/interfaces/${device_name}-${snmp_id}`} {...rest}>
      {content}
    </LinkComponent>
  );
};

export default inject('$app')(InterfaceLink);
