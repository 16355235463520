import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';
import { formatDateTime } from 'core/util/dateUtils';
import { greekIt } from 'app/util/utils';
import BaseHighchartsNonStyledDataview from 'app/components/dataviews/views/BaseHighchartsNonStyledDataview';

@withTheme
@inject('$app', '$dataviews')
@observer
class TrafficTimeSeries extends BaseHighchartsNonStyledDataview {
  state = {};

  constructor(props) {
    super(props);

    const {
      theme: { colors, fonts },
      onHover
    } = this.props;

    this.state = {
      stickyPointer: true,
      highlightPointsByEvent: true,
      chartOptions: {
        type: 'area',
        chart: {
          style: { fontFamily: fonts.body },
          height: 200,
          spacingLeft: 0,
          spacingRight: 0,
          backgroundColor: colors.chart.tooltipBackground
        },
        title: { text: '' },
        credits: { enabled: false },
        legend: { enabled: false },
        xAxis: {
          type: 'datetime',
          crosshair: true,
          lineColor: colors.chart.axisLine,
          gridLineColor: colors.chart.gridLine,
          tickColor: colors.chart.axisTick,
          labels: {
            style: {
              color: colors.muted
            },
            formatter() {
              if (this.dateTimeLabelFormat === '%b %e') {
                return `<strong>${formatDateTime(this.value, 'MMM Do')}</strong>`;
              }

              return this.axis.defaultLabelFormatter.call(this);
            }
          },
          dateTimeLabelFormats: {
            day: '%b %e',
            week: '%b %e'
          }
        },
        yAxis: {
          title: { text: '' },
          gridLineColor: colors.chart.gridLine,
          labels: {
            align: 'left',
            x: 0,
            y: -2,
            style: {
              color: colors.muted
            }
          },
          plotLines: [
            {
              id: 'zero-plot',
              value: 0,
              width: 1,
              color: '#c0c0c0',
              zIndex: 5
            }
          ]
        },
        tooltip: {
          pointFormatter() {
            let symbol = '●';
            if (this.series.symbol === 'diamond') {
              symbol = '♦';
            }
            const value = greekIt(Math.abs(this.y), { fix: 0, suffix: 'bps' }).displayFull;
            return `<span style="color:${colors.primary}">${symbol}</span> ${this.series.name}: <b>${value}</b><br />`;
          },
          outside: true,
          useHTML: true,
          backgroundColor: colors.chart.tooltipBackground,
          shared: true,
          style: {
            color: colors.body,
            fontFamily: fonts.body
          },
          headerFormat: ''
        },
        series: [
          {
            type: 'area',
            name: 'Inbound',
            color: colors.primary,
            fillColor: colors.primaryBackground,
            data: [],
            zIndex: 1,
            marker: {
              enabled: false,
              symbol: 'circle',
              states: {
                select: {
                  enabled: true,
                  fillColor: colors.primary,
                  lineColor: 'rgba(255, 255, 255, 0.5)',
                  lineWidth: 5,
                  radius: 8
                },
                hover: {
                  enabled: false
                }
              }
            },
            states: {
              hover: {
                enabled: false,
                lineWidthPlus: 0
              }
            },
            point: {
              events: {
                mouseOver: (e) => {
                  if (onHover) {
                    onHover(e.target.options.x);
                  }
                }
              }
            }
          },
          {
            type: 'area',
            name: 'Outbound',
            color: colors.primary,
            fillColor: colors.primaryBackground,
            data: [],
            zIndex: 1,
            marker: {
              enabled: false,
              symbol: 'diamond',
              states: {
                select: {
                  enabled: true,
                  fillColor: colors.primary,
                  lineColor: 'rgba(255, 255, 255, 0.5)',
                  lineWidth: 5,
                  radius: 8
                },
                hover: {
                  enabled: false
                }
              }
            },
            states: {
              hover: {
                enabled: false,
                lineWidthPlus: 0
              }
            },
            point: {
              events: {
                mouseOver: (e) => {
                  if (onHover) {
                    onHover(e.target.options.x);
                  }
                }
              }
            }
          }
        ]
      }
    };
  }

  get type() {
    return 'timeline';
  }

  redraw() {
    if (this.chart) {
      const { theme } = this.props;
      const { colors, fonts } = theme;

      this.setState({
        chartOptions: {
          chart: {
            style: { fontFamily: fonts.body },
            backgroundColor: colors.chart.tooltipBackground
          },
          tooltip: {
            backgroundColor: colors.chart.tooltipBackground,
            style: {
              color: colors.body,
              fontFamily: fonts.body
            }
          },
          xAxis: {
            lineColor: colors.chart.axisLine,
            gridLineColor: colors.chart.gridLine,
            tickColor: colors.chart.axisTick,
            crosshair: {
              color: colors.chart.crosshair
            },
            labels: {
              style: {
                color: colors.muted
              }
            }
          },
          yAxis: {
            gridLineColor: colors.chart.gridLine,
            labels: {
              style: {
                color: colors.muted
              }
            }
          }
        }
      });
    }
  }

  renderData() {
    if (this.chart) {
      const { data, xAxisMin, xAxisMax } = this.props;
      this.setState(({ chartOptions }) => {
        chartOptions.series = [{ data: data.inbound }, { data: data.outbound }];
        chartOptions.xAxis.min = xAxisMin;
        chartOptions.xAxis.max = xAxisMax;
        return { chartOptions };
      });
    }
  }
}

export default TrafficTimeSeries;
