import React, { Component } from 'react';
import { debounce } from 'lodash';
import { Highcharts } from 'core/components';
import { inject } from 'mobx-react';

const chartOptions = {
  chart: {
    zoomType: 'x'
  },
  title: {
    text: ''
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%m/%e',
      minute: '%H:%M'
    }
  },
  yAxis: [],
  plotOptions: {
    series: {
      marker: {
        enabled: false
      }
    }
  },
  legend: { enabled: false },
  tooltip: {
    shared: true,
    crosshairs: true
  }
};

// const TimeseriesLineChart = () => {
//   const data = timestamps.map((timestamp, idx) => [timestamp, dataPoints[idx]]);
//   const options = { ...chartOptions, yAxis: [yAxis], series: [{ data, name }] };

//   return (
//     <Highcharts
//       options={options}
//       callback={chart => {
//         if (chart.container) {
//           const resizeListener = debounce(() => chart.reflow(), 200);
//           console.log('add resize');
//           window.addEventListener('resize', resizeListener);
//         }
//       }}
//       colors={$colors.chartColors}
//       primaryOverlayColor={$colors.primaryOverlayColor}
//       overlayColor={$colors.overlayColor}
//     />

//     // <div style={{ position: 'absolute', height: '100%', width: '100%', overflow: 'hidden' }}>
//     // </div>
//   );
// };

@inject('$colors')
export default class TimeseriesLineChart extends Component {
  chartCallback = (chart) => {
    this.chart = chart.container ? chart : null;
    this.el = chart.container;

    this.resizeListener = debounce(() => chart.reflow(), 200);
    window.addEventListener('resize', this.resizeListener);
  };

  componentDidUpdate() {
    this.updateData();
  }

  componentWillUnmount() {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }
  }

  get seriesData() {
    const { dataPoints, timestamps } = this.props;
    return timestamps.map((timestamp, idx) => [timestamp, dataPoints[idx]]);
  }

  updateData() {
    const { yAxis, name } = this.props;

    this.chart.yAxis[0].update(yAxis);
    this.chart.series[0].update({ data: this.seriesData, name });
    this.chart.reflow();
  }

  render() {
    const { $colors, yAxis, name, height } = this.props;

    const options = { ...chartOptions, yAxis: [yAxis], series: [{ data: this.seriesData, name }] };

    return (
      <Highcharts
        height={height}
        options={options}
        callback={this.chartCallback}
        colors={$colors.chartColors}
        primaryOverlayColor={$colors.primaryOverlayColor}
        overlayColor={$colors.overlayColor}
      />
    );
  }
}
