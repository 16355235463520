import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';

import { getHealthTimeline } from 'shared/synthetics/utils';
import AlarmTimeline from 'app/views/synthetics/components/alarmTimeline/AlarmTimeline';
import StackedAlarmsTimeSeries from 'app/views/synthetics/components/StackedAlarmsTimeSeries';
import { getSortedHealthTimelineAndBounds } from 'app/views/synthetics/utils/syntheticsUtils';

@withTheme
@observer
export default class AlarmsTimeSeries extends Component {
  componentDidMount() {
    this.onTimelineSelect();
  }

  componentDidUpdate(prevProps) {
    const { alarms } = this.props;

    if (prevProps.alarms !== alarms) {
      this.onTimelineSelect();
    }
  }

  /*
    produces props that drive the AlarmTimeline component such as timeline data and bounds (delta/min/max)
  */
  get healthTimelineAndBounds() {
    const {
      alarms: { alarms, timeslots },
      ignoreAdjustment
    } = this.props;

    // create a health timeline
    const healthTimeline = getHealthTimeline({
      alarms,
      timestamps: Object.keys(timeslots).sort(),
      convertToMs: false
    });

    // create timeline bounds properties such as delta/min/max
    const { sorted, ...timelineBounds } = getSortedHealthTimelineAndBounds(healthTimeline, ignoreAdjustment);

    return { data: sorted, ...timelineBounds };
  }

  onTimelineSelect = (time) => {
    const { alarms, onTimelineSelect } = this.props;
    const { timeslots } = alarms;
    if (onTimelineSelect) {
      onTimelineSelect(time || alarms.activeTestTime, timeslots);
    }
  };

  onSelect = (time, options) => {
    const { alarms, onSelect, ignoreAdjustment } = this.props;
    const { timeslots } = alarms;

    if (onSelect) {
      if (ignoreAdjustment) {
        onSelect(options.x, options.x2, timeslots);
      } else {
        onSelect(time, timeslots);
      }
    }
  };

  render() {
    const { ignoreAdjustment, stackedSeries, approxWidth, alarms } = this.props;
    const { timeslots, minutesPerIncrement } = alarms;

    if (!timeslots) {
      return null;
    }

    if (stackedSeries) {
      return (
        <StackedAlarmsTimeSeries
          width={approxWidth}
          data={timeslots}
          onHover={this.onTimelineSelect}
          minutesIncrement={minutesPerIncrement}
        />
      );
    }

    // uses the timeline data from getHealthTimeline so we can render the new tooltips with metrics breakdown
    return (
      <AlarmTimeline
        {...this.healthTimelineAndBounds}
        onHover={this.onTimelineSelect}
        onSelect={this.onSelect}
        ignoreAdjustment={ignoreAdjustment}
      />
    );
  }
}
