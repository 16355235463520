import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';
import { interpolateSpectral } from 'd3-scale-chromatic';

import { Card, Box, Flex } from 'core/components';
import { Form } from 'core/form/components';
import { urlDefinedTaskTypes, TASK_TYPES } from 'app/util/constants';
import AgentSelectorCompact from 'app/views/synthetics/components/agentSelector/AgentSelectorCompact';
import HealthAggChart from 'app/views/synthetics/components/HealthAggChart';

const DEFAULT_SERIES = [
  {
    label: 'Avg Latency',
    metric_key: 'avg_latency',
    format_fn: (val) => val,
    store_key: 'avg_latency'
  }
];

const ADD_PING_OR_KNOCK_SERIES = [
  {
    label: 'Packet Loss',
    metric_key: 'packet_loss',
    format_fn: (val) => Math.trunc(val * 10000) / 100,
    store_key: 'packet_loss'
  },
  {
    label: 'Avg Jitter',
    metric_key: 'avg_jitter',
    format_fn: (val) => val,
    store_key: 'avg_jitter'
  }
];

const HTTP_SERIES = [
  {
    label: 'HTTP Avg Latency',
    metric_key: 'avg_latency',
    format_fn: (val) => val,
    store_key: 'http_avg_latency'
  }
];

const TRANSACTION_SERIES = [
  {
    label: 'Avg Transaction Time',
    metric_key: 'avg_latency',
    format_fn: (val) => val,
    store_key: 'trx_avg_latency'
  }
];

@inject('$app', '$dataviews', '$syn')
@Form({
  fields: {
    selectedAgents: {
      label: 'Agents to show',
      defaultValue: []
    }
  },
  options: { name: 'Time Series Options' }
})
@observer
class HealthAgg extends Component {
  constructor(props) {
    super(props);
    const { form } = props;

    form.setValues({
      selectedAgents: []
    });
  }

  get agentOptions() {
    const { results, $syn } = this.props;
    return $syn.agents.getOptionsForAgentsByIds(Object.keys(results.agents));
  }

  dataSeriesRender = ({ agg_health, agg_agents, selectedAgents, seriesInfo }) => {
    const { results } = this.props;
    const { metric_key, label, format_fn } = seriesInfo;
    const avgSeries = Object.values(agg_health).map((series) => {
      const time = +series.time * 1000;
      return {
        time,
        x: time,
        y: format_fn(series[metric_key])
      };
    });
    const allSeries = [{ name: label, data: avgSeries.sort((a, b) => a.x - b.x) }];
    const agentsData = Object.values(agg_agents).reduce((acc, { agents }) => {
      Object.entries(agents).forEach(([id, measurement]) => {
        if (selectedAgents.includes(+id)) {
          if (!acc[id]) {
            acc[id] = { name: `${results.agents[id].agent.agent_alias} avg`, data: [] };
          }

          const time = +measurement.time * 1000;
          acc[id].data.push({
            time,
            x: time,
            y: format_fn(measurement[metric_key])
          });
        }
      });

      return acc;
    }, {});

    const series = allSeries.concat(Object.values(agentsData));
    const count = series.length - 1;
    return series.map((pilot, i) => ({
      ...pilot,
      color: interpolateSpectral((i ? i / count : 0) * 0.25 + 0.75)
    }));
  };

  organizeData({ selectedAgents }) {
    const { results, hasPing } = this.props;
    const { health_agg } = results;

    return Object.entries(health_agg).reduce(
      (acc, [key, { agg_agents, agg_health }]) => {
        const series = [];

        if (!urlDefinedTaskTypes.includes(key)) {
          series.push(...DEFAULT_SERIES);

          if (hasPing) {
            series.push(...ADD_PING_OR_KNOCK_SERIES);
          }
        } else {
          series.push(...(key === TASK_TYPES.TRANSACTION ? TRANSACTION_SERIES : HTTP_SERIES));
        }

        series.forEach(({ store_key, ...seriesInfo }) => {
          acc[store_key].series.push(...this.dataSeriesRender({ agg_health, agg_agents, selectedAgents, seriesInfo }));
        });

        return acc;
      },
      {
        http_avg_latency: {
          series: [],
          name: 'Avg HTTP Latency',
          suffix: 'ms'
        },
        trx_avg_latency: {
          series: [],
          name: 'Avg Transaction Time',
          suffix: 'ms'
        },
        avg_latency: {
          series: [],
          name: 'Avg Latency',
          suffix: 'ms'
        },
        packet_loss: {
          series: [],
          name: 'Packet Loss',
          suffix: '%'
        },
        avg_jitter: {
          series: [],
          name: 'Avg Jitter',
          suffix: 'ms'
        }
      }
    );
  }

  render() {
    const { form, hideAgentSelector } = this.props;
    const { selectedAgents } = form.getValues();
    const data = this.organizeData({ selectedAgents });

    return (
      <>
        {!hideAgentSelector && (
          <Card p={1}>
            <Flex justifyContent="flex-end" alignItems="center">
              <Box width="320px">
                <AgentSelectorCompact
                  agentOptions={this.agentOptions}
                  selectedAgents={selectedAgents}
                  showLabel={false}
                  mb={0}
                />
              </Box>
            </Flex>
          </Card>
        )}
        {Object.values(data).map(
          ({ series, name, suffix }) =>
            !!series.length && (
              <div key={`health_agg-${name}`}>
                <h4>{name}</h4>
                <HealthAggChart
                  {...this.props}
                  data={series}
                  yAxisMax={name === 'Packet Loss' ? 100 : null}
                  suffix={suffix}
                />
              </div>
            )
        )}
      </>
    );
  }
}

export default withTheme(HealthAgg);
