import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Text from './Text';

function Link({ $app, $auth, to, history, state, disabled, blank, children, onClick, keepSearch, ...rest }) {
  if ($app.isExport && $auth.hasPermission('suppressLinksInExports')) {
    return children || to;
  }

  if (history?.location?.search && keepSearch) {
    to += history.location.search;
  }

  const onClickWrapper = (e) => {
    e.stopPropagation();

    if (e.button === 0 && !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) && !blank && !disabled) {
      e.preventDefault();

      if (onClick) {
        if (onClick.then) {
          return onClick().then(() => history.push(to, state));
        }
        onClick();
      }

      history.push(to, state);
      return false;
    }

    return true;
  };

  let target;
  if (blank) {
    target = '_blank';
  }

  return (
    <Text
      as="a"
      href={disabled || $app.isSharedLink ? undefined : to}
      onClick={$app.isSharedLink ? undefined : onClickWrapper}
      disabled={disabled}
      target={target}
      {...rest}
    >
      {children !== undefined ? children : to}
    </Text>
  );
}

export default inject('$app', '$auth')(withRouter(Link));
