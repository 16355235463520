/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { isEqual } from 'lodash';
import { AutoSizer } from 'react-virtualized';
import { Classes } from '@blueprintjs/core';

import Flex from 'core/components/Flex';
import Icon from 'core/components/Icon';
import Tag from 'core/components/Tag';
import Text from 'core/components/Text';
import InputGroup from 'core/form/components/InputGroup';
import { DOWN, ENTER, ESC, TAB, UP } from 'core/util/keyCodes';
import makeCancelable from 'core/util/cancelablePromise';

import SelectPopover from './SelectPopover';
import MultiSelectInput from './MultiSelectInput';
import SelectButton from './SelectButton';

export const defaultValueRenderer = (option, placeholder, values, options, showCount) => {
  if (!option || showCount) {
    return (
      <Flex alignItems="center" gap="4px" justifyContent="flex-start">
        <span className={!values?.length ? Classes.TEXT_MUTED : ''}>{placeholder || 'Select a value...'}</span>
        {showCount && values?.length > 0 && (
          <Tag ellipsis={false} intent="primary" round>
            {values.length}
          </Tag>
        )}
      </Flex>
    );
  }

  const iconName = option ? option.iconCls || option.iconName || option.icon : false;
  return (
    <Flex justifyContent="flex-start" alignItems="center" className={option.className}>
      {iconName && <Icon icon={iconName} style={{ marginRight: 6 }} />}
      {option && <Text ellipsis>{option.label}</Text>}
    </Flex>
  );
};

@observer
class Select extends Component {
  static defaultProps = {
    exactMatch: true,
    onQuery: null,
    disabled: false,
    loading: false,
    multi: false,
    clearable: false,
    clearableLabel: 'None',
    valueRenderer: defaultValueRenderer,
    optionLimit: -1,
    minFilterChars: 0,
    menuWidth: 230,
    fill: false,
    showCount: false,
    showFilter: false,
    keepOpen: false,
    preventEnterSelection: false,

    hideSelected: false
  };

  state = {
    isOpen: false,
    options: null,
    optionsFilter: '',
    selectedIndex: -1
  };

  componentDidMount() {
    const { values, exactMatch } = this.props;

    if (exactMatch && values && (!Array.isArray(values) || values.length > 0)) {
      this.filterOptions(values, { debounce: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen, values, exactMatch } = this.props;

    if (!prevProps.isOpen && isOpen) {
      this.showOptions();
    }

    if (exactMatch && values && values !== prevProps.values && (!Array.isArray(values) || values.length > 0)) {
      this.filterOptions(values, { debounce: false });
    }
  }

  handleScrollRef = (refs) => {
    this.scrollEl = refs;
  };

  handleSelectItems = (values, options) => {
    const { onChange, values: currentValues, keepOpen } = this.props;

    if (options) {
      const { options: currentOptions } = this.state;

      if (currentOptions) {
        this.setState({
          options: currentOptions.concat(
            options.filter((option) => !currentOptions.find((o) => o.value === option.value))
          )
        });
      } else {
        this.setState({ options });
      }
    }

    const filteredOptions = this.getFilteredOptions();
    /* eslint-disable eqeqeq */
    const selectedIndex = filteredOptions.findIndex((opt) => opt.value == values[0] || isEqual(opt.value, values[0]));

    this.setState({ selectedIndex });

    onChange(currentValues.concat(values.filter((value) => !currentValues.find((v) => v === value))));

    if (!keepOpen) {
      this.closeOptions();
    }
  };

  handleSelectItem = (value, option) => {
    const { onChange, clearable, multi, values, keepOpen, toggle } = this.props;

    if (Array.isArray(value)) {
      onChange(
        [].concat(
          values,
          value.filter((v) => !values.includes(v))
        )
      );
    } else {
      if (option) {
        const { options: currentOptions } = this.state;
        if (multi && currentOptions && !currentOptions.some((opt) => opt.value === value)) {
          currentOptions.push(option);
        } else {
          this.state.options = [option];
        }
      }

      /* eslint-disable eqeqeq */
      const isValueEqual = (a, b) => a == b || isEqual(a, b);

      const options = this.getFilteredOptions();
      const selectedIndex = options.findIndex((opt) => isValueEqual(opt.value, value));

      this.setState({ selectedIndex });

      if (multi) {
        const alreadySelected = values.some((val) => isValueEqual(val, value));

        if (clearable && value === '') {
          onChange([]);
        } else if (!alreadySelected) {
          onChange(values.concat(value));
        } else if (toggle) {
          onChange(values.filter((val) => !isValueEqual(val, value)));
        }
      } else {
        onChange(value);
      }
    }

    if (!keepOpen) {
      this.closeOptions();
    }
  };

  handleUnselectItem = (value) => {
    const { onChange, values } = this.props;

    return (e) => {
      e.stopPropagation();

      /* eslint-disable eqeqeq */
      const idx = values.findIndex((currValue) => currValue == value || isEqual(currValue, value));
      const newValues = values.slice();
      newValues.splice(idx, 1);
      onChange(newValues);
    };
  };

  handleKeyboardEvents = (e) => {
    const { multi, values } = this.props;
    const { isOpen, selectedIndex } = this.state;
    const selectOptions = this.getFilteredOptions();

    const key = e.keyCode ? e.keyCode : e.which;
    const keyCodes = [UP, DOWN, ENTER, ESC, TAB];

    if (keyCodes.includes(key)) {
      e.stopPropagation();

      if (key === DOWN) {
        this.setState(
          {
            selectedIndex: selectedIndex < selectOptions.length - 1 ? selectedIndex + 1 : 0
          },
          this.updateScrollPosition
        );
      }
      if (key === UP) {
        this.setState(
          {
            selectedIndex: selectedIndex > 0 ? selectedIndex - 1 : selectOptions.length - 1
          },
          this.updateScrollPosition
        );
      }

      if (key === ENTER) {
        if (selectedIndex !== -1) {
          const option = selectOptions[selectedIndex];
          if (option) {
            if (multi) {
              /* eslint-disable eqeqeq */
              const idx = values.findIndex(
                (currValue) => currValue == option.value || isEqual(currValue, option.value)
              );
              if (idx === -1) {
                this.handleSelectItem(option.value, option);
              } else {
                this.handleUnselectItem(option.value);
              }
            } else {
              this.handleSelectItem(option.value, option);
            }
          } else {
            this.setState({ isOpen: false, selectedIndex: -1 });
          }
        } else {
          this.setState({ isOpen: false });
        }
      }

      if (key === ESC || key === TAB) {
        this.setState({ isOpen: false, selectedIndex: -1 });
      }
    } else if (!isOpen) {
      this.setState({ isOpen: true });
    }
  };

  handleEsc = (e) => {
    const key = e.keyCode ? e.keyCode : e.which;

    if (key === ESC) {
      e.stopPropagation();
      this.setState({ isOpen: false, selectedIndex: -1 });
    }
  };

  updateScrollPosition = () => {
    const { selectedIndex } = this.state;

    if (this.scrollEl) {
      const itemHeight = this.scrollEl.firstChild.offsetHeight;
      const selectedIndexY = (selectedIndex + 1) * itemHeight;

      if (selectedIndexY > this.scrollEl.scrollTop + this.scrollEl.offsetHeight) {
        this.scrollEl.scrollTop = selectedIndexY - this.scrollEl.offsetHeight + 8;
      } else if (selectedIndexY - itemHeight < this.scrollEl.scrollTop) {
        this.scrollEl.scrollTop = selectedIndexY - itemHeight;
      }
    }
  };

  handleRef = (refs) => {
    this.wrapperEl = refs;
  };

  handlePopoverRef = (refs) => {
    this.popoverEl = refs;
  };

  handleDocumentClick = (e) => {
    if (this.wrapperEl && !this.wrapperEl.contains(e.target) && this.popoverEl && !this.popoverEl.contains(e.target)) {
      this.closeOptions();
    }
  };

  handleShowOptions = () => {
    this.showOptions();
  };

  handleAutoCompleteChange = (e) => {
    const { onChange } = this.props;
    const { value } = e.target;

    this.filterOptions(value);

    onChange(value);
  };

  showOptions() {
    const { showFilter, values } = this.props;

    if (!showFilter) {
      this.filterOptions(values);
    }

    this.setState({ isOpen: true });
    document.addEventListener('click', this.handleDocumentClick);
  }

  closeOptions() {
    const { onClose } = this.props;

    this.setState({ isOpen: false });
    document.removeEventListener('click', this.handleDocumentClick);

    if (onClose) {
      onClose();
    }
  }

  filterOptions(optionsFilter, onQueryOpts = {}) {
    const { id, onQuery, values, exactMatch, multi, keepOpen } = this.props;

    if (onQuery) {
      if (this.onQueryPromise) {
        this.onQueryPromise.cancel();
      }

      this.setState({ fetchingOptions: !keepOpen });
      this.onQueryPromise = makeCancelable(onQuery(optionsFilter, { ...onQueryOpts, id, ignoreFilter: multi }));

      if (exactMatch) {
        const { selectedIndex, options } = this.state;
        const value =
          selectedIndex >= 0 && options && options.length > selectedIndex ? options[selectedIndex].value : values;

        this.onQueryPromise.promise.then((newOptions) => {
          const newSelectedIndex = newOptions.findIndex((option) => option.value === value);
          this.setState({ fetchingOptions: false, options: newOptions, selectedIndex: newSelectedIndex });
        });
      } else {
        this.onQueryPromise.promise.then((options) => {
          this.setState({ fetchingOptions: false, options, selectedIndex: -1 });
        });
      }
    } else {
      this.setState({ selectedIndex: -1 });
    }
  }

  getFilteredOptions() {
    const { exactMatch, minFilterChars, onQuery, options, optionLimit } = this.props;
    const { optionsFilter, options: dynamicOptions } = this.state;

    let filteredOptions = options;

    if (onQuery && dynamicOptions) {
      filteredOptions = dynamicOptions;
    } else if (!exactMatch && optionsFilter) {
      const filter = optionsFilter.toLowerCase();
      filteredOptions = options.filter(
        ({ label, value, filterLabel }) =>
          (filterLabel && filterLabel.toLowerCase().includes(filter)) ||
          (typeof label === 'string' && label.toLowerCase().includes(filter)) ||
          (typeof value === 'string' && value.toLowerCase().includes(filter)) ||
          (typeof value === 'number' && value.toString().startsWith(filter))
      );
    }

    if (minFilterChars > 0 && optionsFilter.length < minFilterChars && !exactMatch) {
      return [];
    }

    const allFilteredOptions = filteredOptions;

    if (optionLimit > 0 && !exactMatch) {
      return allFilteredOptions.slice(0, optionLimit);
    }

    return allFilteredOptions;
  }

  renderSelectPopoverBody(menuWidth) {
    const {
      disabled,
      buttonStyle,
      exactMatch,
      height,
      label,
      loading,
      small,
      large,
      inlineLabel,
      inputStyle,
      intent,
      minimal,
      onQuery,
      options,
      multi,
      placeholder,
      showCount,
      renderAsButton,
      valueRenderer,

      // replace the entirely implementation of <Tag> for <Select multi>
      valueTagRenderer,
      valueTagProps,
      className,
      values,
      tagFill,
      width,
      tagInput,
      hideSelected // use in conjunction with renderAsButton
    } = this.props;
    const { isOpen, options: stateOptions } = this.state;
    const showButton = renderAsButton || tagInput;
    const tagInputWithValues = tagInput && Array.isArray(values) && values.length > 0;

    let style = inputStyle;
    if (menuWidth) {
      style = { ...inputStyle, height, width: width || menuWidth };
    }

    if (multi && (!showButton || tagInputWithValues)) {
      return (
        <MultiSelectInput
          active={isOpen}
          disabled={disabled}
          onClick={this.handleShowOptions}
          onUnselectBuilder={this.handleUnselectItem}
          options={onQuery ? stateOptions : options}
          placeholder={placeholder}
          style={style}
          tagFill={tagFill}
          valueTagRenderer={valueTagRenderer}
          valueRenderer={valueRenderer}
          valueTagProps={valueTagProps}
          className={className}
          values={values}
        />
      );
    }

    if (!exactMatch && !showButton) {
      return (
        <InputGroup
          autoComplete="off"
          disabled={disabled}
          type="text"
          onFocus={this.handleShowOptions}
          onKeyDownCapture={this.handleKeyboardEvents}
          onChange={this.handleAutoCompleteChange}
          style={style}
          small={small}
          large={large}
          value={values}
          width={width || menuWidth}
        />
      );
    }

    return (
      <SelectButton
        {...buttonStyle}
        active={isOpen}
        disabled={disabled}
        inlineLabel={inlineLabel}
        intent={intent}
        label={label}
        loading={loading}
        minimal={minimal}
        onClick={this.handleShowOptions}
        options={onQuery ? stateOptions : options}
        placeholder={placeholder}
        showCount={showCount}
        small={small}
        valueRenderer={valueRenderer}
        values={values}
        width={width || menuWidth}
        height={height}
        onKeyDownCapture={this.handleEsc}
        hideSelected={hideSelected}
      />
    );
  }

  renderSelectPopover(autoSizerWidth) {
    const {
      exactMatch,
      onQuery,
      multi,
      showFilter,
      values,
      preventEnterSelection,
      keepOpen,
      menuWidth,
      showSelectAll
    } = this.props;

    // menuWidth could be 'auto' (which behaves like min-content) or a number representing pixels in width.
    // If an autosizer width is passed in, and the menuWidth is passed & is a number, take the max of the two.
    // Otherwise use the menuWidth prop (which is passed in, or the default), since it could be a string.

    const { isOpen, selectedIndex, fetchingOptions } = this.state;
    return (
      <SelectPopover
        {...this.props}
        menuWidth={autoSizerWidth && Number.isFinite(menuWidth) ? Math.max(menuWidth, autoSizerWidth) : menuWidth}
        canEscapeKeyClose
        isModal={multi}
        isOpen={isOpen}
        onSelectItem={this.handleSelectItem}
        onSelectAll={this.handleSelectItems}
        onClose={() => this.closeOptions()}
        options={this.getFilteredOptions()}
        selectedIndex={selectedIndex}
        values={values}
        popoverRef={this.handlePopoverRef}
        scrollRef={!exactMatch ? this.handleScrollRef : undefined}
        fetchingOptions={fetchingOptions}
        onQuery={showFilter ? onQuery : null}
        preventEnterSelection={preventEnterSelection}
        keepOpen={keepOpen}
        showSelectAll={showSelectAll}
      >
        {this.renderSelectPopoverBody(autoSizerWidth || menuWidth)}
      </SelectPopover>
    );
  }

  render() {
    const { style, fill, dataTestId } = this.props;
    const otherProps = {};
    if (dataTestId) {
      otherProps['data-testid'] = dataTestId;
    }

    let popover;
    if (fill) {
      popover = (
        <AutoSizer disableHeight>
          {({ width }) => {
            if (width === 0) {
              // this is really necessary for Safari rendering correctly the select element
              return null;
            }
            return this.renderSelectPopover(width);
          }}
        </AutoSizer>
      );
    } else {
      popover = this.renderSelectPopover();
    }

    return (
      <div ref={this.handleRef} style={style} {...otherProps}>
        {popover}
      </div>
    );
  }
}

export default Select;
