import React from 'react';
import { observer } from 'mobx-react';
import { Box, Heading, Text } from 'core/components';

const Fieldset = (props) => {
  const { children, title, description } = props;
  return (
    <Box mb={2} {...props}>
      <Box mb={1}>
        <Heading level={5}>{title}</Heading>
        {description && (
          <Text muted small>
            {description}
          </Text>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default observer(Fieldset);
