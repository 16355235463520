import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Box, Button, Flex } from 'core/components';
import { Field, formConsumer } from 'core/form';
import { ModalSelect, PopoverSelect, TabbedModalSelect } from 'core/form/components/modalSelect';

import DimensionValueRenderer from './DimensionValueRenderer';
import DimensionMultiValuesRenderer from './DimensionMultiValuesRenderer';

function filterWithRestrictions(array, restrictions) {
  const { optionsWhitelist, optionsBlacklist } = restrictions;

  return array
    .filter((option) => !optionsWhitelist || option.value === undefined || optionsWhitelist.has(option.value))
    .filter((option) => !optionsBlacklist || option.value === undefined || !optionsBlacklist.has(option.value));
}

function applyRestrictions(options, restrictions = {}) {
  const { optionsWhitelist, optionsBlacklist } = restrictions;
  const dimensionOptions = {};

  const categories = Object.keys(options).filter((category) => !optionsBlacklist || !optionsBlacklist.has(category));
  categories.forEach((category) => {
    let categoryOptions;
    let optionCount = 0;
    if (optionsWhitelist && optionsWhitelist.has(category)) {
      dimensionOptions[category] = options[category];
    } else {
      categoryOptions = options[category].map((subCategory) => {
        let matchingOptions;

        if (Array.isArray(subCategory)) {
          matchingOptions = filterWithRestrictions(subCategory, restrictions);
          optionCount += matchingOptions.length;
        } else if (subCategory.value !== undefined) {
          optionCount += 1;
          return subCategory;
        } else {
          matchingOptions = applyRestrictions(subCategory, restrictions);
          optionCount += Object.keys(matchingOptions).length;
        }

        return matchingOptions;
      });

      if (optionCount > 0) {
        dimensionOptions[category] = categoryOptions;
      }
    }
  });

  return dimensionOptions;
}

const filterHeadings = (
  <Flex gap={1} mx={1}>
    <Box flex={1} style={{ fontWeight: 700, paddingLeft: 12, paddingRight: 8 }}>
      Source
    </Box>
    <Box flex={1} style={{ fontWeight: 700, paddingLeft: 12, paddingRight: 8 }}>
      Destination
    </Box>
    <Box flex={1} style={{ fontWeight: 700, paddingLeft: 12, paddingRight: 8 }}>
      Source or Destination
    </Box>
    <Box flex={1} style={{ fontWeight: 700, paddingLeft: 12, paddingRight: 8 }}>
      Non-Directional / Other
    </Box>
  </Flex>
);

const dimensionHeadings = (
  <Flex gap={1} mx={1}>
    <Box flex={1} style={{ fontWeight: 700, paddingLeft: 12, paddingRight: 8 }}>
      Source
    </Box>
    <Box flex={1} style={{ fontWeight: 700, paddingLeft: 12, paddingRight: 8 }}>
      Destination
    </Box>
    <Box flex={1} style={{ fontWeight: 700, paddingLeft: 12, paddingRight: 8 }}>
      Non-Directional / Other
    </Box>
  </Flex>
);

@inject('$explorer', '$dictionary', '$auth')
@formConsumer
@observer
class DimensionSelector extends Component {
  state = {
    isEditing: false
  };

  static defaultProps = {
    useNmsDimensions: undefined,
    multi: true,
    showRequired: false,
    showError: true,
    readOnly: false,
    noRemove: false,
    fieldName: 'dimensions',
    placeholder: 'No dimensions selected',
    reorderable: true,
    showClearButton: false,
    isPopover: false,
    itemFlexed: true,
    rules: undefined,
    title: 'Group By Dimensions',
    selectedValuesTitle: 'Selected Dimensions',
    mainTabDisabled: false,
    showEditButton: false,
    editButtonText: 'Edit Dimensions',
    multiValuesRenderer: DimensionMultiValuesRenderer
  };

  static getDerivedStateFromProps(props) {
    if (props.isEditing !== undefined) {
      return { isEditing: props.isEditing };
    }
    return null;
  }

  getField() {
    const { form, field, fieldName } = this.props;

    return field || form.getField(fieldName);
  }

  getTools = () => {
    const { mainTabDisabled, multi } = this.props;
    return (
      multi && (
        <Button
          fill
          icon="remove"
          text="Clear Selections"
          onClick={this.handleClearValues}
          disabled={mainTabDisabled}
        />
      )
    );
  };

  handleClearValues = () => {
    this.getField().onChange([]);
  };

  handleToggleEditing = (forceValue, redrawOnly = false) => {
    const { onEditComplete } = this.props;

    this.getField().setPristine(false);
    this.setState((prevState) => ({ isEditing: forceValue === undefined ? !prevState.isEditing : forceValue }));

    if (onEditComplete) {
      onEditComplete(forceValue, redrawOnly);
    }
  };

  getSelectComponent() {
    const { isTabbed, isPopover } = this.props;

    let SelectComponent = ModalSelect;
    if (isTabbed) {
      SelectComponent = TabbedModalSelect;
    } else if (isPopover) {
      SelectComponent = PopoverSelect;
    }

    return SelectComponent;
  }

  render() {
    const {
      className,
      multi,
      options,
      onChange,
      readOnly,
      showLabel,
      showError,
      showRequired,
      inputStyle,
      dialogOnly,
      isEditing,
      restrictions,
      rules,
      $dictionary,
      useFilterHeadings,
      showEditButton,
      editButtonText,
      small,
      $auth
    } = this.props;
    const { isEditing: isEditingFromState } = this.state;
    const formField = this.getField();
    const selectOptions = applyRestrictions(
      // checking for keys here will satisfy checks for default filters as well as matrix dimensions
      options && Object.keys(options).length ? options : $dictionary.dimensionOptions,
      restrictions || formField.initialConfig
    );

    // hide bgp for now
    if (!$auth.hasSudo && !$auth.isSpoofed) {
      delete selectOptions.BGP;
    }

    const SelectComponent = this.getSelectComponent();

    const selectField = (
      <Field
        className={className}
        field={formField}
        isEditing={isEditing === undefined ? isEditingFromState : isEditing}
        onChange={onChange}
        options={selectOptions}
        showLabel={showLabel}
        showError={showError}
        toggleEditing={this.handleToggleEditing}
        readOnly={readOnly}
        showRequired={showRequired}
        inputStyle={inputStyle}
        rules={rules}
        small={small}
        mb={0}
      >
        <SelectComponent
          {...this.props}
          filterPlaceholder="Search Available Dimensions..."
          multi={multi}
          selectedValuesTitle="Selected Dimensions"
          valueRenderer={DimensionValueRenderer}
          bottomTools={this.getTools}
          allowSelectAll={false}
          headings={useFilterHeadings ? filterHeadings : dimensionHeadings}
        />
      </Field>
    );

    if (!dialogOnly && !readOnly && multi) {
      if (showEditButton) {
        return (
          <>
            {selectField}
            <Button icon="edit" text={editButtonText} onClick={this.handleToggleEditing} mt={1} small={small} />
          </>
        );
      }

      return (
        /* eslint-disable jsx-a11y/click-events-have-key-events */
        <div tabIndex="0" onClick={this.handleToggleEditing} style={{ cursor: 'pointer' }}>
          {selectField}
        </div>
      );
    }

    return selectField;
  }
}

export default DimensionSelector;
