import React, { Component } from 'react';
import { inject } from 'mobx-react';

import { Box, Card, EmptyState, Spinner, Suspense } from 'core/components';
import { Form } from 'core/form';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import aggregateConfig from 'app/forms/config/aggregate';
import Page from 'app/components/page/Page';

import AggregateViewHeader from 'app/views/core/aggregate/AggregateViewHeader';
import VerticalDetailTabs from 'app/components/detailTabs/VerticalDetailTabs';
import { defaultTabWhitelist } from 'app/components/detailTabs/tabLists';

import { cloudProviderData } from 'app/util/utils';
import { isGoogleCloud } from '@kentik/ui-shared/util/map';
import parentLinks from '../NetworkExplorerParentLink';

const topx = 8;

@Form(aggregateConfig('CloudDetails'))
@inject('$exports', '$query', '$devices', '$tabs')
class CloudProvider extends Component {
  constructor(props) {
    super(props);

    const { location } = props;

    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      filters: [],
      lookback_seconds: 86400,
      aggregateType: 'p95th_bits_per_sec',
      loading: true,
      ...location.state
    };
  }

  componentDidMount() {
    const { $devices } = this.props;
    const { hasGCEDevice, hasAzureDevice, hasAWSDevice } = $devices;
    const providerMap = {};
    if (hasGCEDevice) {
      providerMap.gcp = 1;
    }
    if (hasAWSDevice) {
      providerMap.aws = 1;
    }
    if (hasAzureDevice) {
      providerMap.azure = 1;
    }

    this.setState({ loading: false, providerMap });
  }

  handleFiltersApply = (filters) => {
    this.setState({ filters }, this.updateRouteState);
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds }, this.updateRouteState);
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType }, this.updateRouteState);
  };

  updateRouteState = () => {
    const { history, location } = this.props;
    const { aggregateType, filters, lookback_seconds } = this.state;

    history.replace(location.pathname, {
      aggregateType,
      filters,
      lookback_seconds
    });
  };

  getTabs(cloudProvider) {
    const { $tabs } = this.props;
    const tabs = $tabs.getTabs(cloudProvider);
    switch (cloudProvider) {
      case 'azure':
        return [tabs.AZURE_REGION, tabs.AZURE_INSTANCE, tabs.AZURE_ZONE, tabs.AZURE_PUBLIC_DNS, tabs.AZURE_VNET];
      case 'aws':
        return [
          tabs.AWS_REGION,
          tabs.AWS_INSTANCE,
          tabs.AWS_ZONE_ID,
          tabs.AWS_SECURITY_GROUP,
          tabs.AWS_PUBLIC_DNS,
          tabs.AWS_PRIVATE_DNS,
          tabs.AWS_VPC_ID
        ];
      case 'gcp':
        return [tabs.GCP_REGION, tabs.GCP_PROJECT_ID, tabs.GCP_VM_NAME, tabs.GCP_ZONE, tabs.GCP_SUBNET_NAME];
      default:
        return [];
    }
  }

  // quarantine 'gce' virus from spreading, convert to gcp.
  get cloudProvider() {
    const { match } = this.props;
    const { cloudProvider } = match.params;
    return isGoogleCloud(cloudProvider) ? 'gcp' : cloudProvider;
  }

  render() {
    const { lookback_seconds, aggregateType, filters, loading, providerMap } = this.state;
    const { cloudProvider } = this;
    const cloudProviderDisplay = (cloudProviderData(cloudProvider, 'name') || '').split(' (')[0];
    const hasClouds = !loading && cloudProvider && providerMap[cloudProvider];

    return (
      <Page title={cloudProviderDisplay} parentLinks={parentLinks} scrolls showExport>
        <Suspense loading={loading} fallback={<Spinner intent="primary" mt={4} />}>
          {!hasClouds && (
            <EmptyState
              icon="disable"
              title={`No devices for ${cloudProviderDisplay || 'this cloud provider'} present`}
              maxWidth={650}
              m="0px auto"
            />
          )}
          {hasClouds && (
            <>
              <AggregateViewHeader
                title={cloudProviderDisplay}
                aggregateType={aggregateType}
                filters={filters}
                lookbackSeconds={lookback_seconds}
                onFiltersChange={this.handleFiltersApply}
                onAggregateChange={this.handleAggregationChange}
                onMetricsChange={this.handleAggregationChange}
                onTimeRangeChange={this.handleLookbackChange}
              />

              <Card mb={2} p={0}>
                <TabbedChart
                  queryName="cloudInboundOutboundQuery"
                  overrides={{
                    lookback_seconds,
                    device_types: [`${cloudProvider}_subnet`],
                    aggregateTypes: [aggregateType],
                    topx,
                    depth: topx
                  }}
                  simpleFilters={filters}
                  showNativeLegend={false}
                  noTrend
                />
              </Card>

              <Box mt={2}>
                <VerticalDetailTabs
                  key={cloudProvider}
                  queryOverrides={{ lookback_seconds, aggregateTypes: [aggregateType] }}
                  defaultTab={this.getTabs(cloudProvider).at(0).id}
                  whitelist={[
                    ...defaultTabWhitelist.filter(
                      (tab) => !['az_region', 'aws_region', 'gce_region', 'interfaces'].includes(tab)
                    ),
                    ...this.getTabs(cloudProvider).map((t) => t.id)
                  ]}
                  cloud={cloudProvider}
                />
              </Box>
            </>
          )}
        </Suspense>
      </Page>
    );
  }
}

export default CloudProvider;
