import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FiBarChart2, FiClock, FiFilter, FiSliders } from 'react-icons/fi';
import { MdPhotoSizeSelectSmall } from 'react-icons/md';

import { Button } from 'core/components';
import { formConsumer, Field, Select, Switch } from 'core/form';
import { getFilterCount } from 'core/util/filters';
import DeviceSelector from 'app/components/device/DeviceSelector';
import QueryModel from 'app/stores/query/QueryModel';
import { getAllDisabledDimensions } from 'app/util/devices';
import BracketOptions from 'app/views/core/explorer/sidebar/BracketOptions';
import VisualizationOptions from 'app/views/core/explorer/sidebar/VisualizationOptions';
import SavedViewOptions from 'app/views/core/explorer/sidebar/SavedViewOptions';

import { AiOutlineLineChart } from 'react-icons/ai';
import KentikAiBadge from 'core/components/KentikAiBadge';
import FilterOptions from './sidebar/FilterOptions';
import Sidebar from './sidebar/Sidebar';
import SidebarSection from './sidebar/SidebarSection';
import TimeOptions from './sidebar/TimeOptions';
import QueryOptions from './sidebar/QueryOptions';
import MetricOptions from './sidebar/MetricOptions';
import AdvancedOptions from './sidebar/AdvancedOptions';
import FpaOptions from './sidebar/FpaOptions';

@inject('$app', '$auth', '$dictionary', '$dataviews', '$devices', '$explorer', '$companySettings')
@formConsumer
@observer
export default class ExplorerSidebar extends Component {
  state = {
    isEditingDimensions: false,
    isEditingMetrics: false,
    isEditingFilters: false,
    isEditingBracketing: false,
    isEditingMatrixDimensions: false
  };

  componentDidMount() {
    const { $explorer, form } = this.props;
    $explorer.registerFormState(form);
  }

  componentDidUpdate({ $explorer, form }) {
    if (form !== $explorer.formState) {
      $explorer.registerFormState(form);
    }
  }

  submitIfDirty(redrawOnly = false) {
    const { $explorer, form } = this.props;

    if (!$explorer.suppressed && form.dirty && form.validate()) {
      this.handleSubmit(redrawOnly);
    }
  }

  explorerDeviceFilter = (device) => {
    if (device.ranger_icmp_ip) {
      return false;
    }

    if (device.device_subtype === 'kmetrics') {
      return false;
    }

    return true;
  };

  handleSubmit = (redrawOnly = false) => {
    const { form, model, $explorer, sourceLink, onSubmit } = this.props;

    form.submit(() => {
      model.set(form.getValues());

      if (!sourceLink && model.get('query_title')) {
        model.set({ query_title: '', descriptor: '' });
      }

      const query = QueryModel.create(model.serialize());
      $explorer.apply(query, redrawOnly);
      form.setModel(model);

      if (onSubmit) {
        onSubmit(query);
      }
    });
  };

  handleToggleEditBracketing = () => {
    const { isEditingBracketing } = this.state;
    if (isEditingBracketing) {
      this.submitIfDirty();
    }

    this.setState((prevState) => ({
      isEditingBracketing: !prevState.isEditingBracketing
    }));
  };

  handleToggleEditFilters = (forceValue) => {
    const { isEditingFilters } = this.state;
    if (!forceValue || isEditingFilters) {
      this.submitIfDirty();
    }

    this.setState((prevState) => ({
      isEditingFilters: forceValue !== undefined ? forceValue : !prevState.isEditingFilters
    }));
  };

  handleToggleEditMetrics = (forceValue, redrawOnly = false) => {
    const { isEditingMetrics } = this.state;

    if (!forceValue || isEditingMetrics) {
      this.submitIfDirty(redrawOnly);
    }

    this.setState((prevState) => ({
      isEditingMetrics: forceValue !== undefined ? forceValue : !prevState.isEditingMetrics
    }));
  };

  handleToggleEditDimensions = (forceValue, redrawOnly = false) => {
    const { isEditingDimensions } = this.state;

    if (!forceValue || isEditingDimensions) {
      this.submitIfDirty(redrawOnly);
    }

    this.setState((prevState) => ({
      isEditingDimensions: forceValue !== undefined ? forceValue : !prevState.isEditingDimensions
    }));
  };

  handleToggleEditMatrix = (forceValue, redrawOnly = false) => {
    const { isEditingMatrixDimensions } = this.state;
    if (!forceValue || isEditingMatrixDimensions) {
      this.submitIfDirty(redrawOnly);
    }

    this.setState((prevState) => ({
      isEditingMatrixDimensions: forceValue !== undefined ? forceValue : !prevState.isEditingMatrixDimensions
    }));
  };

  handleTimeModeChange = (mode) => {
    const {
      $explorer: { dataview },
      model
    } = this.props;
    if (mode !== 'lookback' && dataview.hasUpdateFrequency) {
      model.set({ update_frequency: 0 });
    }
  };

  render() {
    const {
      form,
      model,
      $dataviews,
      $devices,
      $dictionary,
      $explorer,
      width,
      savedView,
      onControlledByViewChange,
      onSave,
      onRevert,
      $app,
      $auth
    } = this.props;

    const { isEditingBracketing, isEditingMetrics, isEditingDimensions, isEditingFilters, isEditingMatrixDimensions } =
      this.state;

    const { sidebar, dataview } = $explorer;
    const { dictionary } = $dictionary;

    const device_name = form.getValue('device_name');
    const device_labels = form.getValue('device_labels');
    const device_sites = form.getValue('device_sites');
    const device_types = form.getValue('device_types');
    const all_devices = form.getValue('all_devices');
    const viz_type = form.getValue('viz_type');
    const flow_fields = form.getValue('metric');
    const matrixBy = form.getValue('matrixBy') || [];
    const filter_groups = form.getValue('filters.filterGroups');
    const viewConfig = $dataviews.getConfig(viz_type);

    const filterCount = getFilterCount(filter_groups);

    let secondaryDataViewConfig = viewConfig;
    if (dataview.queryBuckets && dataview.queryBuckets.selectedQuery) {
      secondaryDataViewConfig = $dataviews.getConfig(dataview.queryBuckets.selectedQuery.get('viz_type'));
    }

    const allowBrackets = !viewConfig.suppressBracketing;
    const hasKProbeSelected = all_devices
      ? $devices.hasDnsProbe
      : $devices.containsDnsProbe({ device_name, device_labels, device_sites, device_types });
    const hasKappaSelected = all_devices
      ? $devices.hasKappaDevice
      : $devices.containsKappa({ device_name, device_labels, device_sites, device_types });

    const selectedDevices = !all_devices
      ? $devices.getUniqueSelectedDevices({ device_types, device_labels, device_sites, device_name })
      : $devices.activeDeviceSummaries;

    const disabledDimensions = getAllDisabledDimensions({
      all_devices,
      device_name,
      device_labels,
      device_sites,
      device_types,
      flow_fields,
      filter_groups,
      includeSegmentRoutingDimensions: true
    });

    const disabledMetrics = !hasKProbeSelected && !hasKappaSelected ? ['TCP'] : [];

    if ($dataviews.getConfig(viz_type).timeBased) {
      Object.keys(dictionary.aggregateLabels).forEach((agg) => {
        if (agg.startsWith('agg_total')) {
          disabledMetrics.push(agg);
        }
      });
    }

    const isDeviceLocked = onControlledByViewChange ? form.getValue('device_locked') : false;
    const isTimeLocked = onControlledByViewChange ? form.getValue('time_locked') : false;
    const isFilterLocked = onControlledByViewChange ? form.getValue('filter_source') === 'dashboard' : false;
    const maxDateRange = $app.isSubtenant && $auth.getActiveUserProperty('userGroup.config.max_date_range');

    return (
      <Sidebar onSubmit={this.handleSubmit} sidebar={sidebar} width={width}>
        {!$app.isSubtenant && (
          <>
            {savedView && savedView.canEdit && (
              <SidebarSection label="Saved View" icon={AiOutlineLineChart} defaultIsOpen>
                <SavedViewOptions onSave={onSave} onRevert={onRevert} savedView={savedView} />
              </SidebarSection>
            )}
            <SidebarSection fieldGroup="view" sectionName="explorer.view" label="Visualization" icon={FiBarChart2}>
              <VisualizationOptions
                dataview={dataview}
                handleSubmit={(redraw) => this.submitIfDirty(redraw)}
                showSyncAxes={secondaryDataViewConfig.allowsSyncAxes}
                showUseLogAxis={secondaryDataViewConfig.supportsLogAxis}
                showSyncExtents={secondaryDataViewConfig.supportsSyncExtents}
                showSiteMarkers={dataview.viewType === 'geoHeatmap'}
              />
            </SidebarSection>

            <SidebarSection
              fieldGroup="devices"
              label="Data Sources"
              labelTag={`${selectedDevices.length} of ${$devices.activeDeviceSummaries.length}`}
              labelTagIntent={selectedDevices.length > 0 ? 'primary' : undefined}
              sectionName="explorer.devices"
            >
              {onControlledByViewChange && (
                <Field name="device_locked" showLabel={false} onChange={onControlledByViewChange}>
                  <Switch switchLabel="Controlled by Dashboard" />
                </Field>
              )}
              <DeviceSelector
                autoSubmit
                deviceFilter={this.explorerDeviceFilter}
                readOnly={onControlledByViewChange && !isDeviceLocked}
                disabled={onControlledByViewChange && !isDeviceLocked}
                onSubmit={this.handleSubmit}
              />
            </SidebarSection>

            <SidebarSection
              sectionName="explorer.query"
              fieldGroup="dimensions"
              icon="layers"
              label="Dimensions"
              labelTag={flow_fields.length}
              labelTagIntent={flow_fields.length > 0 ? 'primary' : undefined}
            >
              <QueryOptions
                model={model}
                disabledDimensions={disabledDimensions}
                disabledMetrics={disabledMetrics}
                hasKProbeSelected={hasKProbeSelected}
                isEditingDimensions={isEditingDimensions}
                onEditComplete={this.handleToggleEditDimensions}
              />
            </SidebarSection>

            {viz_type === 'matrix' && (
              <SidebarSection
                sectionName="explorer.matrix"
                fieldGroup="matrix"
                label="Matrix With"
                labelTag={matrixBy.length}
                labelTagIntent={matrixBy.length > 0 ? 'primary' : undefined}
              >
                <QueryOptions
                  isMatrix
                  model={model}
                  disabledDimensions={disabledDimensions}
                  disabledMetrics={disabledMetrics}
                  hasKProbeSelected={hasKProbeSelected}
                  isEditingDimensions={isEditingMatrixDimensions}
                  onEditComplete={this.handleToggleEditMatrix}
                />
              </SidebarSection>
            )}

            <SidebarSection sectionName="explorer.metrics" fieldGroup="metrics" label="Metrics" icon="cog">
              <MetricOptions
                model={model}
                isEditingMetrics={isEditingMetrics}
                hasKProbeSelected={hasKProbeSelected}
                disabledDimensions={disabledDimensions}
                disabledMetrics={disabledMetrics}
                onEditComplete={this.handleToggleEditMetrics}
              />
            </SidebarSection>
          </>
        )}
        <SidebarSection
          sectionName="explorer.time"
          fieldGroup="time"
          label="Time"
          className="sidebar-time"
          icon={FiClock}
        >
          {onControlledByViewChange && (
            <Field name="time_locked" showLabel={false} onChange={onControlledByViewChange}>
              <Switch switchLabel="Controlled by Dashboard" />
            </Field>
          )}
          <TimeOptions
            disabled={onControlledByViewChange && !isTimeLocked}
            onModeChange={this.handleTimeModeChange}
            submitChanges={() => this.submitIfDirty()}
            maxDateRange={maxDateRange}
            disabledDimensions={disabledDimensions}
          />
        </SidebarSection>

        {!$app.isSubtenant && (
          <>
            <SidebarSection
              fieldGroup="filtering"
              sectionName="explorer.filters"
              label="Filters"
              icon={FiFilter}
              labelTag={filterCount > 0 ? filterCount : 'No Filters'}
              labelTagIntent={filterCount > 0 ? 'primary' : undefined}
            >
              {onControlledByViewChange && (
                <Field name="filter_source" onChange={onControlledByViewChange}>
                  <Select fill />
                </Field>
              )}
              <FilterOptions
                readOnly={onControlledByViewChange && isFilterLocked}
                sidebar={sidebar}
                isOpen={isEditingFilters}
                onClose={this.handleToggleEditFilters}
                disabledDimensions={disabledDimensions}
                allowNestedFilters
              />
              <Button
                disabled={onControlledByViewChange && isFilterLocked}
                onClick={this.handleToggleEditFilters}
                text="Edit Filters"
                icon="edit"
                small
                mt="12px"
              />
            </SidebarSection>

            {allowBrackets && (
              <SidebarSection
                fieldGroup="bracketing"
                icon={MdPhotoSizeSelectSmall}
                sectionName="explorer.bracketing"
                label="Brackets"
              >
                <BracketOptions
                  sidebar={sidebar}
                  isOpen={isEditingBracketing}
                  onClose={this.handleToggleEditBracketing}
                  disabledDimensions={disabledDimensions}
                />
                <Button onClick={this.handleToggleEditBracketing} text="Edit Bracketing" icon="edit" small mt="12px" />
              </SidebarSection>
            )}

            <SidebarSection fieldGroup="advanced" icon={FiSliders} sectionName="explorer.advanced" label="Advanced">
              <AdvancedOptions
                sidebar={sidebar}
                disabledDimensions={disabledDimensions}
                handleSubmit={this.handleSubmit}
                allowNestedFilters
              />
            </SidebarSection>

            {$auth.hasPermission('fpa', { overrideForSpoof: false }) && (
              <SidebarSection
                fieldGroup="fpa"
                icon={<KentikAiBadge small />}
                sectionName="explorer.fpa"
                label="Probable Cause Analysis"
              >
                <FpaOptions
                  sidebar={sidebar}
                  model={model}
                  disabledDimensions={disabledDimensions}
                  handleSubmit={(redraw) => this.submitIfDirty(redraw)}
                />
              </SidebarSection>
            )}
          </>
        )}
      </Sidebar>
    );
  }
}
