import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('ServiceAggregate'))
class ServiceAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="Services"
        resultsKey="Service (Port+Proto)"
        insightsQuery="fetchServiceInsights"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'Service (Port+Proto)',
          aggregateFilters: [
            { metric: ['src_proto_port', 'dst_proto_port'] },
            { metric: ['src_proto_port'] },
            { metric: ['dst_proto_port'] },
            { metric: ['src_proto_port', 'dst_proto_port'] },
            { metric: ['src_proto_port', 'dst_proto_port'] }
          ]
        }}
        chartProps={{
          metrics: {
            Internal: ['dst_proto_port', 'src_proto_port'],
            Inbound: 'src_proto_port',
            Outbound: 'dst_proto_port'
          }
        }}
      />
    );
  }
}

export default ServiceAggregate;
