import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Card, Flex, Heading, Spinner, Suspense, Text } from 'core/components';
import SyntheticsAgentsViewLink from 'app/components/links/SyntheticsAgentsViewLink';

@inject('$syn')
@observer
class AgentsOverview extends Component {
  renderContent() {
    const { $syn, loading } = this.props;
    const hasTests = $syn.tests.size > 0;

    return (
      <Suspense loading={loading} fallback={<Spinner intent="primary" my={3} size={36} />}>
        {hasTests && (
          <>
            <AgentsStatusRow label="Pending" tabId="pending" count={$syn.agents.pendingAgents.length} isPending />
            <AgentsStatusRow
              label="Private"
              tabId="private"
              count={$syn.agents.privateAgents.length}
              borderTop="thinLight"
            />
            <AgentsStatusRow
              label="Global"
              tabId="global"
              count={$syn.agents.globalAgents.length}
              borderTop="thinLight"
            />
            <AgentsStatusRow
              label="App"
              tabId="browser"
              count={$syn.agents.browserAgents.length}
              borderTop="thinLight"
            />
            <AgentsStatusRow
              label="Broadband"
              tabId="broadband"
              count={$syn.agents.broadbandAgents.length}
              borderTop="thinLight"
            />
            <AgentsStatusRow
              label="Public Cloud"
              tabId="cloud"
              count={$syn.agents.cloudAgents.length}
              borderTop="thinLight"
            />
          </>
        )}
      </Suspense>
    );
  }

  render() {
    const { isWidgetView } = this.props;
    return isWidgetView ? (
      <Box pt={1} pb={2} px={2}>
        {this.renderContent()}
      </Box>
    ) : (
      <Card p={2} mb={3} className="avoid-break">
        <Heading level={5} fontWeight="heavy" mb={2}>
          Agents
        </Heading>
        {this.renderContent()}
      </Card>
    );
  }
}

const AgentsStatusRow = ({ tabId, label, count, isPending, ...props }) => (
  <Flex alignItems="center" justifyContent="space-between" pt={1} pb={1} {...props}>
    <SyntheticsAgentsViewLink small tabId={tabId}>
      {label} {isPending && <Text small>(To be Activated)</Text>}
    </SyntheticsAgentsViewLink>
    <Text fontWeight={500} ml={1}>
      {count}
    </Text>
  </Flex>
);

export default AgentsOverview;
