import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Tag } from 'core/components';

export const dimensionCategoryIntentMap = {
  Destination: 'primary',
  Source: 'warning',
  'Source or Dest': 'danger',
  '': 'success',
  none: 'none'
};

/**
 * Responsible for displaying a Dimension in <Tag> form. Used when you need to display a dimension's display label
 * i.e. "Destination AS Name" instead of `dst_as'
 */
@inject('$dictionary')
@observer
class Dimension extends Component {
  static defaultProps = {
    useChartTypes: false
  };

  render() {
    const {
      $dictionary,
      children,
      dimension,
      style,
      useChartTypes,
      useNmsDimensions,
      options: dimensionOptions,
      ...rest
    } = this.props;
    const displayGroups = ['Source', 'Destination', 'Source or Destination'];
    let options;

    if (!dimension) {
      return null;
    }

    if (useNmsDimensions?.length > 0) {
      options = useNmsDimensions;
    } else if (dimensionOptions) {
      options = dimensionOptions;
    } else if (useChartTypes) {
      options = $dictionary.flatDimensionOptions;
    } else {
      options = $dictionary.flatFilterFieldOptions;
    }

    const dimensionDisplay = options.find((option) => option.value === dimension);

    if (useNmsDimensions) {
      return (
        <Tag round minimal intent="primary" fontWeight={500}>
          {dimensionDisplay?.label || dimension}
        </Tag>
      );
    }

    if (!dimensionDisplay && dimension.startsWith('c_')) {
      return (
        <Tag outline minimal round {...rest}>
          Custom: {dimension}
        </Tag>
      );
    }

    if (!dimensionDisplay) {
      return (
        <Tag outline round intent="danger" {...rest}>
          Invalid: {dimension}
        </Tag>
      );
    }

    const { group, label } = dimensionDisplay;
    const intent = dimensionCategoryIntentMap[group];

    if (children) {
      return children({
        group,
        label,
        dimensionClassName: dimensionDisplay.className,
        intent,
        name: `${group} ${label}`,
        minimal: true
      });
    }

    return (
      <Tag intent={intent} minimal {...rest} ellipsis={false}>
        {!useNmsDimensions && displayGroups.includes(dimensionDisplay.group) ? `${dimensionDisplay.group} ` : ''}
        {dimensionDisplay.tagLabel || dimensionDisplay.label}
      </Tag>
    );
  }
}

export default Dimension;
