import React, { Component } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Box, Icon, Card, Flex, Button } from 'core/components';
import { observer } from 'mobx-react';
import { InputGroup, Field, MultiSelect } from 'core/form';
import { FiPlusCircle } from 'react-icons/fi';
import { action } from 'mobx';

const DragHandle = SortableHandle(() => (
  <Box small css={{ cursor: 'move', position: 'relative', left: -3 }}>
    <Icon icon="drag-handle-vertical" color="muted" />
  </Box>
));

const Layer = ({ layers, layer, allDevices, devices, idx, isFirst, isLast }) => {
  const showDeviceField = allDevices && allDevices.length > 0;

  return (
    <Flex className="sortable-helper">
      {layers.size() > 1 && isFirst && (
        <Flex flexDirection="column" alignItems="center" justifyContent="flex-end">
          <Box borderLeft="thin" borderTop="thin" height="50%" width={25}>
            &nbsp;
          </Box>
        </Flex>
      )}
      {layers.size() > 1 && !isFirst && !isLast && (
        <Flex width="25px">
          <Flex flexDirection="column" alignItems="center" justifyContent="center" width="1px">
            <Box borderLeft="thin" height="100%" width="1px">
              &nbsp;
            </Box>
          </Flex>
          <Flex flexDirection="column" alignItems="center" justifyContent="center" width="24px">
            <Box border="thin" style={{ borderWidth: '0.5px' }} height={1} width="26px">
              &nbsp;
            </Box>
          </Flex>
        </Flex>
      )}
      {layers.size() > 1 && isLast && (
        <Flex flexDirection="column" alignItems="center" justifyContent="flex-start">
          <Box borderLeft="thin" borderBottom="thin" height="50%" width={25}>
            &nbsp;
          </Box>
        </Flex>
      )}
      {layer.subLayers.map((subLayer, subIdx) => {
        const deviceOptions = [
          ...devices,
          ...subLayer.devices.getValue().map((selectedDevice) => {
            const matchingDevice = allDevices.find((device) => device.id === `${selectedDevice}`);
            return { label: matchingDevice.device_name, value: matchingDevice.id };
          })
        ];

        return (
          <Flex key={subLayer.name._id + subLayer.devices._id}>
            <Card p={2} mb={1} width={350}>
              <Flex>
                {subIdx === 0 && <DragHandle />}
                <Box ml={1} flex={1}>
                  <Flex justifyContent="space-between">
                    <Field field={subLayer.name}>
                      <InputGroup width={250} />
                    </Field>
                    <Box>
                      <Button
                        small
                        minimal
                        icon="trash"
                        intent="danger"
                        onClick={() => {
                          layer.subLayers.remove(subIdx);
                          if (layer.subLayers.size() === 0) {
                            layers.remove(idx);
                          }
                          layers.form.onChange({ form: layers.form, formValues: layers.form.getValues() });
                        }}
                      />
                    </Box>
                  </Flex>

                  {showDeviceField && (
                    <Field field={subLayer.devices} mb={0} options={deviceOptions}>
                      <MultiSelect fill p={2} keepOpen showFilter />
                    </Field>
                  )}
                </Box>
              </Flex>
            </Card>
            {subIdx === 0 && (
              <Flex flexDirection="column" alignItems="center" justifyContent="center">
                <Box border="thin" style={{ borderWidth: '0.5px' }} height={1} width={25}>
                  &nbsp;
                </Box>
              </Flex>
            )}
            {subIdx === 0 && layer.subLayers.size() === 1 && (
              <Flex flexDirection="column" alignItems="center" justifyContent="center">
                <Button
                  text="Add Parallel Layer"
                  icon={FiPlusCircle}
                  minimal
                  intent="primary"
                  onClick={() => layer.subLayers.add({ name: '', devices: [] })}
                />
              </Flex>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

const SortableLayer = SortableElement(observer(Layer));

const Layers = ({ layers, onNameChange, onDeviceChange, devices }) => {
  const availableDevices = devices
    .filter(
      (device) =>
        !layers.find((layer) =>
          layer.subLayers.find((subLayer) =>
            subLayer.devices.getValue().find((subLayerDevice) => subLayerDevice.toString() === device.id.toString())
          )
        )
    )
    .map((device) => ({ label: device.device_name, value: device.id }))
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

  return (
    <Flex>
      <Box>
        {layers.map((layer, idx) => (
          <Box key={layer.subLayers._id}>
            <SortableLayer
              layer={layer}
              onNameChange={onNameChange}
              onDeviceChange={onDeviceChange}
              idx={idx}
              index={idx}
              isFirst={idx === 0}
              isLast={idx === layers.size() - 1}
              layers={layers}
              allDevices={devices}
              devices={availableDevices}
            />

            <Flex
              alignItems="center"
              justifyContent="center"
              width={375}
              pb={idx < layers.size() - 1 ? 1 : 0}
              borderLeft={idx < layers.size() - 1 ? 'thin' : ''}
            >
              <Button
                text="Add Layer"
                icon={FiPlusCircle}
                minimal
                intent="primary"
                onClick={() => layers.add({ subLayers: [{ name: '', devices: [] }] }, { insertIdx: idx + 1 })}
              />
            </Flex>
          </Box>
        ))}
        {layers.size() === 0 && (
          <Flex alignItems="center" justifyContent="center" width={375}>
            <Button
              text="Add Layer"
              icon={FiPlusCircle}
              minimal
              intent="primary"
              onClick={() => layers.add({ subLayers: [{ name: '', devices: [] }] }, { insertIdx: 0 })}
            />
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

const SortableLayers = SortableContainer(observer(Layers));

@observer
export default class EditSiteArchitecture extends Component {
  @action
  onSortEnd = (layers, oldIndex, newIndex) => {
    const { form } = this.props;
    const old = layers.fieldStates[oldIndex];
    layers.fieldStates[oldIndex] = layers.fieldStates[newIndex];
    layers.fieldStates[newIndex] = old;

    form.onChange({ formValues: form.getValues() });
  };

  render() {
    const { form, devices } = this.props;

    return (
      <SortableLayers
        layers={form.getField('layers')}
        devices={devices}
        lockAxis="y"
        useDragHandle
        onSortEnd={({ oldIndex, newIndex }) => this.onSortEnd(form.getField('layers'), oldIndex, newIndex)}
      />
    );
  }
}
