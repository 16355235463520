import React, { Component } from 'react';
import { Sparkline, Text, Box } from 'core/components';

class Cell extends Component {
  get hasClickHandler() {
    const { onClick, metric } = this.props;
    return onClick && onClick[metric];
  }

  handleClick = () => {
    const { onClick, metric, value, rowData } = this.props;
    onClick[metric](value, rowData);
  };

  shouldComponentUpdate(nextProps) {
    const { value, showSparklines } = this.props;
    return value !== nextProps.value || showSparklines !== nextProps.showSparklines;
  }

  renderValue() {
    const { name, value, renderer, model, queryResultsCollection, queryModel } = this.props;
    const renderedValue = renderer
      ? renderer({ value, model, queryModel, collection: queryResultsCollection, name })
      : value;

    let renderedElement = renderedValue;

    // a simplified Overlay renderer
    if (model.isOverlay) {
      renderedElement = <Text color="muted">{renderedValue}</Text>;
    } else if (this.hasClickHandler) {
      renderedElement = (
        <Text as="a" onClick={this.handleClick}>
          {renderedValue}
        </Text>
      );
    }

    return renderedElement;
  }

  renderSparkline() {
    const { showSparklines, loading, model } = this.props;
    const { hasRawData, sparklineData } = model;

    if (!showSparklines || !hasRawData) {
      return null;
    }

    return (
      <Box minWidth={85} width={85} mr={1} display="inline-block">
        <Sparkline data={sparklineData} height={60} strokeWidth={2} color={loading ? 'muted' : 'primary'} />
      </Box>
    );
  }

  render() {
    const { model, columnIndex, columnCount, mergeOverlayCells, showSparklines } = this.props;
    let colspan = showSparklines ? 2 : 1;

    if (mergeOverlayCells && model.isOverlay) {
      // do not render overlay cells past the first one
      if (columnIndex > 0) {
        return null;
      }

      if (columnIndex === 0) {
        // spread the first overlay cells across all columns
        colspan = columnCount;
      }
    }

    return (
      <td colSpan={colspan}>
        {this.renderSparkline()}
        {this.renderValue()}
      </td>
    );
  }
}

export default Cell;
