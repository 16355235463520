const PREFIX = {
  avg: 'avg_ktappprotocol__',
  p95: 'p95th_ktappprotocol__',
  max: 'max_ktappprotocol__'
};

const METRICS = ['00', '02'];

const SNMP_KEYS = {
  outsort: `snmp__INT64_${METRICS[0]}`,
  secondaryOutsort: `snmp__INT64_${METRICS[1]}`
};

export const BITS_SNMP_RESPONSE_KEYS = {
  avgIn: `${PREFIX.avg}${SNMP_KEYS.outsort}`,
  avgOut: `${PREFIX.avg}${SNMP_KEYS.secondaryOutsort}`,
  maxIn: `${PREFIX.max}${SNMP_KEYS.outsort}`,
  maxOut: `${PREFIX.max}${SNMP_KEYS.secondaryOutsort}`,
  lastIn: `f_sum_int64_${METRICS[0]}__k_last`,
  lastOut: `f_sum_int64_${METRICS[1]}__k_last`
};

export const BITS_SNMP_QUERY = {
  sync_axes: false,
  outsort: `${PREFIX.avg}${SNMP_KEYS.outsort}`,
  secondaryOutsort: `${PREFIX.avg}${SNMP_KEYS.secondaryOutsort}`,

  aggregateTypes: [
    `${PREFIX.avg}${SNMP_KEYS.outsort}`,
    `${PREFIX.p95}${SNMP_KEYS.outsort}`,
    `${PREFIX.max}${SNMP_KEYS.outsort}`,

    `${PREFIX.avg}${SNMP_KEYS.secondaryOutsort}`,
    `${PREFIX.p95}${SNMP_KEYS.secondaryOutsort}`,
    `${PREFIX.max}${SNMP_KEYS.secondaryOutsort}`
  ],

  aggregateThresholds: {
    // SNMP outsort
    avg_ktappprotocol__snmp__INT64_00: 0,
    p95th_ktappprotocol__snmp__INT64_00: 0,
    max_ktappprotocol__snmp__INT64_00: 0,

    // SNMP secondaryOutsort
    avg_ktappprotocol__snmp__INT64_02: 0,
    p95th_ktappprotocol__snmp__INT64_02: 0,
    max_ktappprotocol__snmp__INT64_02: 0
  }
};

const ST_KEYS = {
  outsort: `st__INT64_${METRICS[0]}`,
  secondaryOutsort: `st__INT64_${METRICS[1]}`
};

export const BITS_ST_RESPONSE_KEYS = {
  avgIn: `${PREFIX.avg}${ST_KEYS.outsort}`,
  avgOut: `${PREFIX.avg}${ST_KEYS.secondaryOutsort}`,
  maxIn: `${PREFIX.max}${ST_KEYS.outsort}`,
  maxOut: `${PREFIX.max}${ST_KEYS.secondaryOutsort}`,
  lastIn: `f_sum_int64_${METRICS[0]}__k_last`,
  lastOut: `f_sum_int64_${METRICS[1]}__k_last`
};

export const BITS_ST_QUERY = {
  sync_axes: false,
  outsort: `${PREFIX.avg}${ST_KEYS.outsort}`,
  secondaryOutsort: `${PREFIX.avg}${ST_KEYS.secondaryOutsort}`,

  aggregateTypes: [
    `${PREFIX.avg}${ST_KEYS.outsort}`,
    `${PREFIX.p95}${ST_KEYS.outsort}`,
    `${PREFIX.max}${ST_KEYS.outsort}`,

    `${PREFIX.avg}${ST_KEYS.secondaryOutsort}`,
    `${PREFIX.p95}${ST_KEYS.secondaryOutsort}`,
    `${PREFIX.max}${ST_KEYS.secondaryOutsort}`
  ],

  aggregateThresholds: {
    // ST outsort
    avg_ktappprotocol__st__INT64_00: 0,
    p95th_ktappprotocol__st__INT64_00: 0,
    max_ktappprotocol__st__INT64_00: 0,

    // ST secondaryOutsort
    avg_ktappprotocol__st__INT64_02: 0,
    p95th_ktappprotocol__st__INT64_02: 0,
    max_ktappprotocol__st__INT64_02: 0
  }
};
