import React, { Component } from 'react';
import { Icon, Image, Tooltip } from 'core/components';
import { isDev } from 'core/util';
import { inject } from 'mobx-react';
import { MdRouter } from 'react-icons/md';
import AwsLogo from 'app/components/AwsLogo';
import { ReactComponent as AzureLogo } from 'app/assets/logos/azure-logo.svg';
import { ReactComponent as GcpLogo } from 'app/assets/logos/google-cloud-logo.svg';
import { ReactComponent as OCILogo } from 'app/assets/logos/oracle-logo.svg';
import { ReactComponent as KprobeIcon } from 'app/assets/icons/kprobe-icon.svg';
import { ReactComponent as IstioLogo } from 'app/assets/logos/istio-logo.svg';
import { ReactComponent as RouterIcon } from 'app/assets/icons/dev_router_plain.svg';
import { ReactComponent as CiscoLogo } from 'app/assets/logos/cisco-logo.svg';
import PaloAltoIcon from 'app/assets/logos/palo-alto.png';
import GigamonLogo from 'app/assets/logos/gigamon-logo.png';
import SilverPeakLogo from 'app/assets/logos/silver-peak-logo.png';

const iconClassMap = {
  'pt-icon-dev-router-plain': RouterIcon,
  'pt-icon-paloalto': PaloAltoIcon,
  'pt-icon-cisco': CiscoLogo,
  'pt-icon-gigamon': GigamonLogo,
  'pt-icon-silverpeak': SilverPeakLogo,
  'pt-icon-send-to': 'send-to',
  'pt-icon-dev-kprobe': KprobeIcon,
  'pt-icon-istio': IstioLogo,
  'pt-icon-dev-cloud-azure': AzureLogo,
  'pt-icon-dev-cloud-aws': AwsLogo,
  'pt-icon-dev-cloud-gcp': GcpLogo,
  'pt-icon-dev-cloud-oci': OCILogo
};

export const deviceSubTypeIconMap = {
  kprobe: KprobeIcon,
  aws_subnet: AwsLogo,
  gcp_subnet: GcpLogo,
  oci_subnet: OCILogo,
  istio_beta: IstioLogo,
  azure_subnet: AzureLogo,
  router: RouterIcon,
  'host-nprobe-dns-www': KprobeIcon
};

@inject('$dictionary')
export default class DeviceSubtypeIcon extends Component {
  render() {
    const { $dictionary, tooltip, type, ...rest } = this.props;

    const subtype = $dictionary.get('device_subtypes')[type];

    const TypeIcon = iconClassMap[subtype && subtype.icon] || deviceSubTypeIconMap[type];

    if (!TypeIcon) {
      if (isDev) {
        console.warn(`Couldn't find icon for ${type}`);
      }
      return <Icon icon={MdRouter} {...rest} />;
    }

    let iconImage = null;
    if (typeof TypeIcon === 'string' && TypeIcon.includes('/')) {
      iconImage = <Image src={TypeIcon} alt={type} width="16px" {...rest} />;
    }

    if (tooltip) {
      return (
        <Tooltip content={subtype?.display_name || type} position="top">
          <Icon icon={iconImage || TypeIcon} {...rest} />
        </Tooltip>
      );
    }

    return <Icon icon={iconImage || TypeIcon} {...rest} />;
  }
}
