import React, { Component } from 'react';
import { Flex, Heading, Text } from 'core/components';
import { ReactComponent as DockerLogo } from 'app/assets/logos/docker.svg';
import { ReactComponent as DebianLogo } from 'app/assets/logos/debian.svg';
import { ReactComponent as RPMLogo } from 'app/assets/logos/rpm.svg';
import AgentInstallOption from './AgentInstallOption';

class AgentInstallChooseDeploymentOption extends Component {
  renderOption = ({ name, icon, type }) => {
    const { onChange } = this.props;

    return (
      <AgentInstallOption onChange={() => onChange(type)} centerIcon>
        <Flex justifyContent="center" alignSelf="flex-start" alignItems="center">
          {React.createElement(icon, { width: 30, height: 30 })}
          <Heading level={5} mb={0} ml={1}>
            {name}
          </Heading>
        </Flex>
      </AgentInstallOption>
    );
  };

  render() {
    const { agentName } = this.props;
    return (
      <>
        <Text as="div" muted mb="2px">
          {agentName}
        </Text>
        <Heading level={6}>Select a deployment option:</Heading>
        {this.renderOption({
          name: 'Debian/Ubuntu Package',
          icon: DebianLogo,
          type: 'debian-ubuntu'
        })}
        {this.renderOption({
          name: 'RPM Package',
          icon: RPMLogo,
          type: 'rpm'
        })}
        {this.renderOption({
          name: 'Docker',
          icon: DockerLogo,
          type: 'docker'
        })}
      </>
    );
  }
}

export default AgentInstallChooseDeploymentOption;
