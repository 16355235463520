import React from 'react';
import { Box, Flex, Text } from 'core/components';
import { Label } from 'app/components/labels/Label';

// not the first, not the last, and an even number
function lineBreakCheck(i, last) {
  return i !== 0 && i !== last && i % 2 === 1 && <br />;
}

const getLabelId = (label) =>
  typeof label.get === 'function' ? (label.get('id') ?? label.get('value')) : (label.id ?? label.value);

/**
 * Displays a list of Labels
 *
 * @param labels labels to display
 * @param wrapLabels wrap labels using standard flex-row rules.
 * @param onRemove adds X button allowing user to remove labels
 * @param priorityLabels labels to prioritize displaying before all others. Mostly relevant when used with
 *                       `model.collection.discreteFilterValues` in order to show user-relevant labels before others.
 * @param shareLevel displays special Kentik labels for Kentik presets
 * @param fixedWidth displays two labels per line with a break between. wrapLabels may be more useful.
 * @param cutoff maximum number of labels to display. Shows an indication of how many labels remain.
 * @param renderEmpty renders the label list (i.e. the container box) even if there are no labels to display. Beware of triggering errors with this.
 * @param endElement element to render at end of label list
 * @param rest remaining props (mostly expected to be styling) are transferred to the wrapper box
 * @returns {React.JSX.Element|null}
 */
export default ({
  labels = [],
  wrapLabels,
  onRemove = null,
  priorityLabels = [],
  shareLevel,
  fixedWidth,
  cutoff = null,
  renderEmpty = false,
  endElement = null,
  ...rest
}) => {
  if (!renderEmpty && (!Array.isArray(labels) || labels.length === 0)) {
    return null;
  }

  const sortLabels = (a, b) => {
    const idA = getLabelId(a);
    const idB = getLabelId(b);
    // Priority labels come first
    if (priorityLabels.includes(idA)) {
      if (!priorityLabels.includes(idB)) {
        return -1;
      }
    } else if (priorityLabels.includes(idB)) {
      return 1;
    }
    // Then use numeric sort if appropriate
    if (typeof idA === 'number' && typeof idB === 'number') {
      return idA - idB;
    }
    // Otherwise simple string sort
    return String.prototype.localeCompare.call(idA, idB);
  };

  // fixedWidth does nothing if there is only one tag
  fixedWidth = fixedWidth && labels.length > 1;

  if (wrapLabels) {
    Object.assign(rest, { whiteSpace: 'normal' });
  }

  const styleOverrides = fixedWidth ? { maxWidth: 'calc(50% - 4px)' } : {};

  return (
    <Box {...rest}>
      <Flex flexDirection="row" width="100%" gap="4px" flexWrap={wrapLabels && 'wrap'}>
        {labels.sort(sortLabels).map((label, i) => {
          if (cutoff && i >= cutoff) {
            return null;
          }
          const labelHasGet = typeof label.get === 'function';
          // If a `bg` is provided, use it, otherwise `color` is the bg.
          const bg = labelHasGet ? label.get('bg') : label.bg;
          const color = labelHasGet ? label.get('color') : label.color;
          const labelId = getLabelId(label);
          const testId = `labelId_${labelId}`;
          // display label name if available, otherwise label.label
          const name = labelHasGet ? label.get('name') || label.get('label') : label.name || label.label;

          const onRemoveFn = onRemove && ((event) => onRemove(labelId, event));
          return (
            <Flex {...styleOverrides} key={labelId} data-testid="labelWrapper">
              <Label
                color={bg ? color : undefined}
                bg={bg || color}
                name={name}
                onRemove={onRemoveFn}
                testId={testId}
                shareLevel={shareLevel}
              />
              {fixedWidth && lineBreakCheck(i, labels.length - 1)}
            </Flex>
          );
        })}
        {cutoff && labels.length > cutoff ? (
          <Text ml={1} mt="2px">
            +{labels.length - cutoff}
          </Text>
        ) : null}
        {endElement && <span key="endElement">{endElement}</span>}
      </Flex>
    </Box>
  );
};
