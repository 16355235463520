import React from 'react';
import { inject } from 'mobx-react';
import DeviceLink from 'app/components/links/DeviceLink';

/**
 * @deprecated use DeviceLink instead
 */
const NmsDeviceLink = ({ $app, $devices, id, name: _name, ...rest }) => {
  const name = _name || $devices.deviceSummariesById[id]?.device_name;
  return <DeviceLink name={name} {...rest} />;
};

export default inject('$app', '$devices')(NmsDeviceLink);
