import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';

import BollingerBandChart from 'app/views/synthetics/components/BollingerBandChart';
import { checkSeriesDataForFailure } from 'app/views/synthetics/utils/syntheticsUtils';

import withTrendChart from './withTrendChart';

@withTrendChart
@withTheme
@inject('$app', '$dataviews')
@observer
class ThroughputTimeSeries extends BollingerBandChart {
  get valueSuffix() {
    return 'mbps';
  }

  renderData() {
    if (this.chart) {
      const { data, xAxisMin, xAxisMax, delta } = this.props;

      const averages = [];
      const stddevs = [];
      const zones = [];
      let currHealth;

      const offset = delta || 60000;

      data.forEach((point, i) => {
        const {
          time,
          other_healths,
          avg_throughput_bandwidth,
          rolling_avg_throughput_bandwidth,
          rolling_stddev_throughput_bandwidth
        } = point;
        const { throughput_health } = other_healths;

        const avgThroughputBandwidth = parseFloat(parseFloat(avg_throughput_bandwidth).toFixed(2));
        const rollingThroughputAvg = parseFloat(parseFloat(rolling_avg_throughput_bandwidth).toFixed(2));
        const rollingThroughputStddev = parseFloat(parseFloat(rolling_stddev_throughput_bandwidth).toFixed(2));

        if (checkSeriesDataForFailure(point)) {
          averages.push({ time, x: time, y: null });
        } else {
          averages.push({
            time,
            x: time,
            y: avgThroughputBandwidth,
            marker: {
              enabled: checkSeriesDataForFailure(data[i - 1]) || checkSeriesDataForFailure(data[i + 1])
            }
          });
          const std = [
            time,
            Math.max(0, parseFloat((rollingThroughputAvg - rollingThroughputStddev).toFixed(2))),
            parseFloat((rollingThroughputAvg + rollingThroughputStddev).toFixed(2))
          ];
          stddevs.push(std);
        }

        if (currHealth !== throughput_health) {
          zones.push({
            value: time - offset,
            color: this.getColor(currHealth),
            fillColor: this.getFillColor(currHealth)
          });
          currHealth = throughput_health;
        }
      });

      zones.push({ color: this.getColor(currHealth), fillColor: this.getFillColor(currHealth) });

      const marker = {
        states: {
          select: {
            fillColor: this.getColor(currHealth),
            lineColor: 'rgba(255, 255, 255, 0.5)',
            lineWidth: 5,
            radius: 8
          }
        }
      };

      this.setState({
        chartOptions: {
          series: [
            { name: 'Avg Bandwidth', data: averages, zones, marker },
            { data: stddevs, zones, marker }
          ],
          xAxis: {
            min: xAxisMin,
            max: xAxisMax
          },
          yAxis: {
            offset: 20
          }
        }
      });
    }
  }
}

export default ThroughputTimeSeries;
