import React from 'react';
import { getMoment, DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';

import { Text, Tooltip } from 'core/components';

function FromNowDate({
  timeStamp,
  tooltipPosition,
  userTimeZone = 'UTC',
  format = DEFAULT_DATETIME_FORMAT,
  showDateTooltip = true,
  textOptions = {}
}) {
  // note tooltipPosition: default should be undefined!

  const moment = getMoment(timeStamp, userTimeZone);

  const timeFromNow = (
    <Text muted small {...textOptions}>
      {moment.fromNow()}
    </Text>
  );

  if (showDateTooltip) {
    return (
      <Tooltip content={moment.format(format)} position={tooltipPosition}>
        {timeFromNow}
      </Tooltip>
    );
  }
  return timeFromNow;
}

export default FromNowDate;
