import { darken, transparentize } from 'polished';
import { colorNames, space, bracketingColors } from './theme';

const GRID_SIZE = 8;

// temporary port of grays on dark from the `storybook` branch.
export const colorGrayOnDark900 = '#ffffff';
export const colorGrayOnDark800 = '#e3e3e3';
export const colorGrayOnDark700 = '#b9b9b9';
export const colorGrayOnDark600 = '#909090';
export const colorGrayOnDark500 = '#6e6e6e';
export const colorGrayOnDark400 = '#5a5a5a';
export const colorGrayOnDark300 = '#4a4a4a';
export const colorGrayOnDark200 = '#3e3e3e';
export const colorGrayOnDark100 = '#323232';
export const colorGrayOnDark75 = '#2f2f2f';
export const colorGrayOnDark50 = '#252525';
export const colorGrayOnDark25 = '#141414';

function generateTheme({
  ptFontSizeSmall,
  ptFontSize,
  ptFontSizeLarge,

  ptFontFamily,
  ptFontFamilyMonospace,

  ptIntentPrimary,
  ptIntentWarning,
  ptIntentDanger,
  ptIntentSuccess,
  ptTextColorMuted,

  ptDarkIntentPrimary,
  ptDarkIntentWarning,
  ptDarkIntentDanger,
  ptDarkIntentSuccess,
  ptDarkTextColorMuted,

  ptTextColor,
  ptDarkTextColor,

  ptAppBackgroundColor,
  ptDarkAppBackgroundColor,

  ptElevationShadow0,
  ptElevationShadow1,
  ptElevationShadow2,
  ptElevationShadow3,
  ptElevationShadow4,

  ptDarkElevationShadow0,
  ptDarkElevationShadow1,
  ptDarkElevationShadow2,
  ptDarkElevationShadow3,
  ptDarkElevationShadow4,

  ptIntentTextColors,
  ptDarkIntentTextColors,

  ...rest
}) {
  let colors = {};
  colorNames.forEach((color) => (colors[color] = rest[color]));

  colors = {
    ...colors,

    transparent: 'rgba(255, 255, 255, 0)',

    // allows `color` and `bg` props to use familiar intent names, as well as "muted"
    primary: ptIntentPrimary,
    warning: ptIntentWarning,
    danger: ptIntentDanger,
    info: '#E1E8F2',
    success: ptIntentSuccess,
    muted: ptTextColorMuted,
    body: ptTextColor,
    turqoise: '#0f97C4',
    cobalt: '#22548B',
    purple: '#9B51E0',
    yellow: '#F2C94C',

    blackText: ptTextColor,

    appBackground: '#ffffff',
    sidebarBackground: '#ffffff',
    drawerBackground: '#ffffff',
    subnavBackground: ptAppBackgroundColor,
    navbarMenuBackground: '#F7F8F9',
    navbarMenuCenterBackground: '#ffffff',
    sectionBackground: '#ECEEF0',
    dialogBackground: colors.lightGray4,
    popoverBackground: '#ffffff',
    cardBackground: '#ffffff',

    cloudPerformance: {
      node: {
        background: 'rgb(66, 66, 66)',
        primaryForeground: 'rgb(247, 247, 247)',
        secondaryForeground: 'rgb(162, 162, 162)'
      },

      link: {
        default: '#000',
        noTests: ptTextColorMuted
      }
    },

    primaryBackground: transparentize(0.85, ptIntentPrimary),
    warningBackground: transparentize(0.85, ptIntentWarning),
    dangerBackground: transparentize(0.85, ptIntentDanger),
    successBackground: transparentize(0.85, ptIntentSuccess),
    purpleBackground: transparentize(0.85, '#9B51E0'),
    yellowBackground: transparentize(0.85, '#F2C94C'),

    // a white, with transparency
    whiteMuted: 'rgba(255, 255, 255, 0.8)',

    white: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].map((alpha) => `rgba(255, 255, 255, ${alpha})`),
    black: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].map((alpha) => `rgba(0, 0, 0, ${alpha})`),

    chart: {
      gridLine: '#ebebeb',
      axisLine: '#ebebeb',
      axisTick: '#ebebeb',
      tooltipBackground: '#ffffff',
      legendLabels: colorGrayOnDark100,
      legendHiddenLabels: colorGrayOnDark600
    },

    healthStatusButton: {
      background: '#f6f7f9',
      activeBackground: '#ecf0f2'
    },

    pie: ['#55c379', '#ffcc24', '#8e66bc', '#ff4d4d', '#5a81ff'],

    severity: {
      critical: '#67009E',
      severe: '#CC007F',
      major2: '#CC007F',
      major: '#FF2754',
      warning: '#FF8226',
      minor2: '#FF8226',
      minor: '#FCCB08',
      notice: '#C4C4C4',
      clear: '#A2D98C',
      baseline: colors.violet5
    },

    alertStateColor: '#5C7080',

    bracketing: bracketingColors,

    calloutOutlineBackgrounds: {
      primary: '#dfeeff',
      warning: '#fff1e4',
      danger: '#f9e4e5',
      success: '#e4fff4',
      info: '#E1E8F2'
    },

    kentik: '#e05420',

    hybrid: {
      danger: '#C95151',
      filtered: colors.violet5
    },

    cloud: {
      blue: '#4285F4',
      blueBackground: transparentize(0.75, '#4285F4'),
      green: '#34A853',
      greenBackground: transparentize(0.75, '#34A853'),
      yellow: '#FBBC05',
      yellowBackground: transparentize(0.75, '#FBBC05'),
      red: '#EA4335',
      redBackground: transparentize(0.75, '#EA4335')
    },

    aws: {
      blue: '#2E27AD',
      green: '#1B660F',
      darkGreen: '#055F4E',
      purple: '#4D27A8',
      orange: '#C8511B',
      red: '#B0084D'
    },

    azure: {
      blue: 'rgb(21,127,243)',
      orange: '#C8511B'
    },

    oci: {
      primary: 'rgb(229, 88, 68)',
      primaryBackground: transparentize(0.85, 'rgb(255, 241, 240)'),
      vcn: '#06536f'
    },

    // sometimes referred to as the CandyNecklace
    stateTimeline: {
      unknown: {
        background: '#BABABA',
        backgroundHover: darken(0.3, '#BABABA')
      },
      healthy: {
        background: '#A2D98C',
        backgroundHover: darken(0.3, '#A2D98C')
      },
      unhealthy: {
        background: '#E367A2',
        backgroundHover: darken(0.3, '#E367A2')
      }
    },

    gcp: {
      blue: '#4285F4',
      blueBackground: transparentize(0.75, '#4285F4'),
      green: '#34A853',
      greenBackground: transparentize(0.75, '#34A853'),
      yellow: '#FBBC05',
      yellowBackground: transparentize(0.75, '#FBBC05'),
      red: '#EA4335',
      redBackground: transparentize(0.75, '#EA4335')
    },

    waterfall: {
      headerBackground: '#f0f1f4',
      oddRowsBackground: '#f0f1f4',
      evenRowsBackground: 'rgba(255, 255, 255, 0)',
      activeRowBackground: '#e5e5e5'
    },

    networkMesh: {
      highDensityHealthy: '#A1D9B0'
    }
  };

  const light = {
    name: 'light',
    gridSize: GRID_SIZE,

    space,
    colors: {
      ...colors,

      navbarBackground: '#141f2b',
      navbarMenuCenterBackground: '#ffffff',
      navbarBackgroundOpen: '#141f2b',
      navbarMenuLinkColor: ptTextColor,

      specialMuted: 'rgba(16,22,26,0.15)',

      // group headers in the select component are sticky so they require solid backgrounds
      // these backgrounds map to solid representations of intent backgrounds used in the dimension selector
      selectGroupHeader: {
        grayBackground: '#e5e5e5',
        warningBackground: '#e6d5c9',
        primaryBackground: '#c9d6e7',
        successBackground: '#cad9d1'
      },

      tags: {
        muted: 'rgba(138, 155, 168, 0.2)'
      },

      decks: {
        background: ptAppBackgroundColor,
        widgetBackground: '#ffffff',
        cardBorderDragging: ptIntentPrimary,
        customizeDrawerBackground: '#ebf1f5',
        customizeDrawerCardBackground: '#ffffff',
        handles: 'rgba(16,22,26,0.25)',
        handlesHover: 'rgba(16,22,26,0.45)',
        headerHover: 'rgba(16,22,26,0.10)'
      },

      onboarding: {
        stepNumberBackground: ptTextColor,
        configSnippetBackground: ptTextColor
      }
    },

    cardBorder: '#d4d4d8',

    backgrounds: {
      app: ptAppBackgroundColor,
      tableHeader: '#EEEFF2',
      tableHeaderHover: '#E4E6ED',
      info: '#E1E8F2',
      specialMuted: 'rgba(16,22,26,0.15)'
    },

    breakpoints: {
      sm: '320px',
      md: '768px',
      lg: '1024px'
    },

    borders: {
      thinLight: '1px solid rgba(227,227,228,1)',
      thin: '1px solid rgba(16,22,26,0.15)',
      thinLighter: '1px solid rgba(16,22,26,0.25)',
      menuDivider: '1px solid rgba(16,22,26,0.25)',
      thick: '5px solid rgba(16,22,26,0.15)',
      thinDotted: '1px dotted rgba(16,22,26,0.15)',
      thinDashed: '1px dashed rgba(16,22,26,0.15)',
      active: `1px solid ${colors.primary}`,
      activeThick: `5px solid ${colors.primary}`,
      transparent: '1px solid transparent',
      tabs: '1px solid rgba(16,22,26,0.15)'
    },

    borderColors: {
      thin: 'rgba(16,22,26,0.15)',
      thick: 'rgba(16,22,26,0.15)',
      tabs: 'rgba(16,22,26,0.15)'
    },

    fontSizes: {
      tiny: 12,
      small: ptFontSizeSmall,
      normal: ptFontSize,
      large: ptFontSizeLarge
    },

    fonts: {
      body: ptFontFamily,
      monospace: "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace"
    },

    fontWeights: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 600,
      heavy: 700,
      black: 800
    },

    shadows: {
      thin: '0 0 0 1px rgba(16,22,26,0.1), 0 0 0 rgba(16,22,26,0), 0 0px 1px rgba(16,22,26,0.2)',
      hovered:
        '0 0 0 1px rgb(0 0 0 / 10%), -8px 0 10px rgb(0 0 0 / 20%), -16px 0 24px 2px rgb(0 0 0 / 6%), 8px 0 10px rgb(0 0 0 / 20%), 16px 0 24px 2px rgb(0 0 0 / 6%)',
      focused: '0 0 0 1px #157ff3, 0 0 0 3px rgba(21,127,243,0.3), inset 0 1px 1px rgba(16,22,26,0.2)',

      elevation: [
        '0 0 0 1px rgba(16,22,26,0.15), 0 0 0 rgba(16,22,26,0), 0 0 0 rgba(16,22,26,0)',
        ptElevationShadow1,
        ptElevationShadow2,
        ptElevationShadow3,
        ptElevationShadow4
      ]
    },

    textIntents: ptIntentTextColors
  };

  const dark = {
    ...light,
    name: 'dark',

    colors: {
      ...colors,
      primary: ptDarkIntentPrimary,
      warning: ptDarkIntentWarning,
      danger: ptDarkIntentDanger,
      success: ptDarkIntentSuccess,
      muted: ptDarkTextColorMuted,
      body: ptDarkTextColor,

      // Severity colors

      severity: {
        critical: '#9578DD',
        severe: '#B662CB',
        major2: '#B662CB',
        major: '#CD6A7F',
        warning: '#FF8226',
        minor2: '#FF8226',
        minor: '#FDCC08',
        notice: '#C4C4C4',
        clear: '#85a578',
        baseline: colors.violet5
      },

      alertStateColor: colorGrayOnDark600,

      // used for Tags in dark mode
      invertedBody: colorGrayOnDark25,

      specialMuted: ptDarkTextColorMuted,
      blackText: ptTextColor,

      // cards in dark always have a solid background
      cardBackground: colorGrayOnDark75,
      cardBorder: colorGrayOnDark400,

      appBackground: colorGrayOnDark100,
      drawerBackground: colorGrayOnDark100,
      sectionBackground: 'rgba(0, 0, 0, 0.1)',
      navbarBackground: colorGrayOnDark50,
      navbarMenuCenterBackground: colorGrayOnDark75,
      navbarBackgroundOpen: colorGrayOnDark100,
      navbarMenuBackground: colorGrayOnDark50,
      navbarMenuLinkColor: colorGrayOnDark700,

      inputBackground: colorGrayOnDark50,

      subnavBackground: colorGrayOnDark75,

      // @TODO look at these
      sidebarBackground: colorGrayOnDark75,
      dialogBackground: colorGrayOnDark75,
      popoverBackground: colorGrayOnDark75,

      cloudPerformance: {
        ...colors.cloudPerformance,
        link: {
          default: '#fff',
          noTests: ptDarkTextColorMuted
        }
      },

      primaryBackground: transparentize(0.75, ptDarkIntentPrimary),
      warningBackground: transparentize(0.75, ptDarkIntentWarning),
      dangerBackground: transparentize(0.75, ptDarkIntentDanger),
      successBackground: transparentize(0.75, ptDarkIntentSuccess),

      tags: {
        muted: 'rgba(138, 155, 168, 0.2)'
      },

      chart: {
        gridLine: 'rgb(255, 255, 255, 0.10)',
        axisLine: 'rgb(255, 255, 255, 0.10)',
        axisTick: 'rgb(255, 255, 255, 0.10)',
        tooltipBackground: colorGrayOnDark100,
        legendLabels: colorGrayOnDark800,
        legendHiddenLabels: colorGrayOnDark600
      },

      healthStatusButton: {
        background: '#373c3f',
        activeBackground: '#2b2f31'
      },

      stateTimeline: {
        unknown: {
          background: '#7b7b7b',
          backgroundHover: '#BABABA'
        },
        healthy: {
          background: '#85a578',
          backgroundHover: '#BBE3AB'
        },
        unhealthy: {
          background: '#ad5c83',
          backgroundHover: '#E367A2'
        }
      },

      calloutOutlineBackgrounds: {
        primary: '#343B48',
        warning: '#393836',
        danger: '#49393A',
        success: '#374440'
      },

      // dark versions of group headers for the select component
      // these colors must be solid (see light versions above)
      selectGroupHeader: {
        grayBackground: '#313639',
        warningBackground: '#514237',
        primaryBackground: '#364358',
        successBackground: '#374b41'
      },

      decks: {
        background: colorGrayOnDark100,
        widgetBackground: colorGrayOnDark100,
        cardBorderDragging: ptIntentPrimary,
        customizeDrawerBackground: colorGrayOnDark100,
        customizeDrawerCardBackground: colorGrayOnDark75,
        handles: 'rgba(255, 255, 255, 0.25)',
        handlesHover: 'rgba(255, 255, 255, 0.45)',
        headerHover: 'rgba(255, 255, 255, 0.15)'
      },

      onboarding: {
        stepNumberBackground: '#2b2f31',
        configSnippetBackground: '#2b2f31'
      },

      waterfall: {
        headerBackground: colorGrayOnDark50,
        oddRowsBackground: colorGrayOnDark50,
        evenRowsBackground: 'rgba(255, 255, 255, 0)',
        activeRowBackground: colorGrayOnDark25
      }
    },

    borders: {
      ...light.borders,
      thinLight: `1px solid ${colorGrayOnDark400}`,
      thin: `1px solid ${colorGrayOnDark50}`,
      thinLighter: `1px solid ${colorGrayOnDark400}`,
      thinDotted: `1px dotted ${colorGrayOnDark400}`,
      thinDashed: `1px dotted ${colorGrayOnDark200}`,
      menuDivider: '1px solid #202020',
      thick: `3px solid ${colorGrayOnDark400}`,
      tabs: '1px solid rgb(69 79 83)'
    },

    backgrounds: {
      app: colorGrayOnDark100,
      tableHeader: colorGrayOnDark100,
      tableHeaderHover: colorGrayOnDark50,
      specialMuted: 'rgba(138,155,168,0.15)'
    },

    borderColors: {
      thin: colorGrayOnDark50,
      thick: colorGrayOnDark400,
      tabs: colorGrayOnDark400
    },

    shadows: {
      thin: '0 0 0 1px rgba(16,22,26,0.1), 0 0 0 rgba(16,22,26,0), 0 0px 1px rgba(16,22,26,0.2)',
      hovered:
        '0 0 0 1px rgb(0 0 0 / 10%), -8px 0 10px rgb(0 0 0 / 20%), -16px 0 24px 2px rgb(0 0 0 / 6%), 8px 0 10px rgb(0 0 0 / 20%), 16px 0 24px 2px rgb(0 0 0 / 6%)',
      focused:
        '0 0 0 1px #157ff3, 0 0 0 1px #157ff3, 0 0 0 3px rgba(21,127,243,0.3), inset 0 0 0 1px rgba(16,22,26,0.3), inset 0 1px 1px rgba(16,22,26,0.4)',

      elevation: [
        '0 0 0 1px rgba(16,22,26,0.4), 0 0 0 rgba(16,22,26,0), 0 0 0 rgba(16,22,26,0)',
        ptDarkElevationShadow1,
        ptDarkElevationShadow2,
        ptDarkElevationShadow3,
        ptDarkElevationShadow4
      ]
    },

    textIntents: ptDarkIntentTextColors
  };

  return { light, dark };
}

export default generateTheme;
