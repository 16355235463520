import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { MdHelp } from 'react-icons/md';
import { Flex, Heading, Text, Link, Icon, Box } from 'core/components';
import { ReactComponent as BrowserAgentIcon } from 'app/assets/agents/browser_agent_icon.svg';
import { ReactComponent as PrivateAgentIcon } from 'app/assets/agents/private_agent_icon.svg';
import AgentInstallOption from './AgentInstallOption';

@withTheme
class AgentInstallChooseAgentType extends Component {
  renderOption = ({ name, icon, text, disclaimer, tag, type, disclaimerIcon }) => {
    const { onChange } = this.props;

    return (
      <AgentInstallOption onChange={() => onChange(type)}>
        <Flex justifyContent="center" alignSelf="flex-start">
          {React.createElement(icon, { width: 20, height: 20 })}
          <Heading level={5} mb={0} ml={1} mr={1}>
            {name}
          </Heading>
          {tag && tag}
        </Flex>
        <Text muted mt={1}>
          {text}
        </Text>
        <Flex mt={2} pr={2} justifyContent="center" alignSelf="flex-start">
          {disclaimerIcon && <Icon icon={disclaimerIcon} iconSize={12} color="muted" mt="3px" mr={1} />}
          <Text muted fontWeight="700">
            {disclaimer}
          </Text>
        </Flex>
      </AgentInstallOption>
    );
  };

  render() {
    const { theme } = this.props;
    const { colors } = theme;

    return (
      <>
        <Heading level={6}>Select the agent type you want to deploy:</Heading>
        {this.renderOption({
          name: 'App Agent',
          icon: BrowserAgentIcon,
          text: 'The App Agent is capable of running a full browser instance to perform Page Load and/or Transaction tests, in addition to all the tests supported by the Network Test Agent',
          type: 'app-agent'
        })}
        {this.renderOption({
          name: 'Network Test Agent',
          icon: PrivateAgentIcon,
          text: 'This legacy agent supports all Network layer Synthetic test types and the HTTP web test',
          disclaimerIcon: 'warning-sign',
          disclaimer:
            'This agent does not support page load or transaction tests, and it will be deprecated sometime in 2023',
          type: 'network-test-agent'
        })}
        <Box as="div" mt={2} p={1} style={{ backgroundColor: colors.primaryBackground, borderRadius: '4px' }}>
          <Link alignItems="center" href="https://kb.kentik.com/v4/Ma01.htm" blank rel="noopener noreferrer">
            <Flex alignItems="center">
              <Icon icon={MdHelp} mr={1} iconSize={19} />
              <Text>Check documentation for hardware requirements, IP whitelist and more</Text>
            </Flex>
          </Link>
        </Box>
      </>
    );
  }
}

export default AgentInstallChooseAgentType;
