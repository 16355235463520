import { Box, Button, Flex, FlexColumn, Grid, Text } from 'core/components';
import { VirtualizedTable } from 'core/components/table';
import { Field, FormComponent, InputGroup, Select } from 'core/form';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import { CgClose } from 'react-icons/cg';
import { greekIt } from 'app/util/utils';
import ASNRenderer from 'app/components/asn/ASNRenderer';
import PolicyType from './PolicyType';

export default class PeeringDetailsSidebar extends Component {
  state = {
    loadingDetails: false
  };

  componentDidUpdate(prevProps) {
    const { model } = this.props;

    if (model && model.id !== prevProps.model?.id) {
      if (!model.hasFetched) {
        this.fetchModelDetails();
      }
    }
  }

  fetchModelDetails() {
    const { model } = this.props;

    this.setState({ loadingDetails: true });
    model.fetch().then(() => {
      this.setState({ loadingDetails: false });
    });
  }

  get type() {
    const { model } = this.props;
    const { type } = model;

    if (type) {
      return type.substring(0, 1).toUpperCase() + type.substring(1);
    }

    return '';
  }

  get isFacility() {
    const { model } = this.props;
    return model.type === 'facility';
  }

  get isExchange() {
    const { model } = this.props;
    return model.type === 'exchange';
  }

  get networksColumns() {
    const columns = [
      {
        name: 'name',
        label: 'Name',
        renderer: ({ model, value }) => <ASNRenderer description={value} asn={model.get('asn')} showLinks />
      },
      {
        name: 'policy_general',
        label: 'Policy',
        renderer: ({ value }) => <PolicyType value={value} />,
        width: 100
      },
      {
        name: 'info_ratio',
        label: 'Traffic Ratios',
        width: 130
      }
    ];

    if (this.isExchange) {
      columns.push(
        {
          name: 'speed',
          label: 'Speed',
          width: 80,
          renderer: ({ value }) => greekIt(value * 10 ** 6, { fix: 0, suffix: 'bps' }).displayFull
        },
        {
          name: 'ipaddr',
          label: 'IP Address',
          width: 220,
          renderer: ({ model }) => (
            <>
              <Text as="div" ellipsis>
                {model.get('ipaddr4')}
              </Text>
              <Text as="div" ellipsis>
                {model.get('ipaddr6')}
              </Text>
            </>
          ),
          title: ({ model }) => [model.get('ipaddr4'), model.get('ipaddr6')].filter(Boolean).join('\n')
        }
      );
    }

    return columns;
  }

  handleFilterChange = (form, collection) => {
    const filters = form.getValues();
    const discreteFilters = [];

    if (filters.policy_general) {
      discreteFilters.push({ type: 'policy', fn: (model) => model.get('policy_general') === filters.policy_general });
    }

    if (filters.info_ratio) {
      discreteFilters.push({ type: 'ratio', fn: (model) => model.get('info_ratio') === filters.info_ratio });
    }

    collection.setDiscreteFilters(discreteFilters);
    collection.filter();
  };

  handleSearchChange = debounce((form, collection, value) => {
    collection.filter(value);
  }, 100);

  renderTable(collection, columns) {
    if (!collection) {
      return null;
    }

    const { loadingDetails } = this.state;
    const rowHeight = this.isExchange ? 45 : 32;

    const fields = {
      policy_general: {
        label: 'Policy',
        defaultValue: ''
      },
      info_ratio: {
        label: 'Traffic Ratio Type',
        defaultValue: ''
      },
      search: {
        label: 'Search',
        defaultValue: ''
      }
    };

    const policies = new Set();
    const ratios = new Set();

    collection.unfiltered.forEach((model) => {
      const { policy_general, info_ratio } = model.get();

      if (policy_general) {
        policies.add(policy_general);
      }

      if (info_ratio) {
        ratios.add(info_ratio);
      }
    });

    const toOptions = (values) =>
      [{ value: '', label: 'All' }].concat(
        Array.from(values)
          .sort((a, b) => a.localeCompare(b))
          .map((value) => ({ value, label: value === 'No' ? 'None' : value }))
      );

    const policyOptions = toOptions(policies);
    const ratioOptions = toOptions(ratios);

    return (
      <FormComponent fields={fields} options={{ name: 'PeeringSidebarFilters' }}>
        {({ form }) => (
          <FlexColumn flex="1 1 auto" minHeight={200}>
            <Flex gap={1} px={2} mb={1}>
              <Field
                name="policy_general"
                options={policyOptions}
                mb={0}
                showLabel={false}
                onChange={() => this.handleFilterChange(form, collection)}
              >
                <Select inlineLabel menuWidth={100} />
              </Field>
              <Field
                name="info_ratio"
                options={ratioOptions}
                mb={0}
                showLabel={false}
                onChange={() => this.handleFilterChange(form, collection)}
              >
                <Select inlineLabel menuWidth={140} />
              </Field>
              <Field
                name="search"
                flex="1 1 auto"
                mb={0}
                showLabel={false}
                large
                onChange={(field, value) => this.handleSearchChange(form, collection, value)}
              >
                <InputGroup className="jumbo" leftIcon="search" fill clearable />
              </Field>
            </Flex>

            <VirtualizedTable
              loading={loadingDetails}
              collection={collection}
              columns={columns}
              rowHeight={rowHeight}
              selectOnRowClick={false}
              flexed
            />
          </FlexColumn>
        )}
      </FormComponent>
    );
  }

  render() {
    const { onClose, model } = this.props;
    const { loadingDetails } = this.state;

    if (!model) {
      return null;
    }

    const { exchanges, location, networks } = model;
    const { isFacility, isExchange } = this;

    return (
      <FlexColumn gap={3} pt={3} width={750 + (isExchange ? 300 : 0)} maxWidth="100vw" height="100%">
        <Flex gap="4px" px={2} justifyContent="space-between" alignItems="flex-start">
          <Box>
            <Text as="div" small muted mb="4px">
              {this.type}
            </Text>
            <Text as="div" fontSize={20} fontWeight="medium">
              {model.get('name')}
            </Text>
            {model.get('org_name') && (
              <Text as="div" small muted mt="4px" textTransform="uppercase">
                {model.get('org_name')}
              </Text>
            )}
          </Box>
          <Button icon={CgClose} color="muted" onClick={onClose} title="Close" minimal />
        </Flex>

        {isFacility && (
          <Grid gridTemplateColumns="auto 1fr" px={2}>
            {location && (
              <>
                <Text small fontWeight="bold">
                  Location
                </Text>
                <Text small>{location}</Text>
              </>
            )}
            {networks && (
              <>
                <Text small fontWeight="bold">
                  Networks
                </Text>
                <Text small>{loadingDetails ? '-' : networks.unfilteredSize}</Text>
              </>
            )}
            {exchanges && (
              <>
                <Text small fontWeight="bold">
                  Exchanges
                </Text>
                <Text small>{loadingDetails ? '-' : exchanges.unfilteredSize}</Text>
              </>
            )}
          </Grid>
        )}

        {isExchange && (
          <Grid gridTemplateColumns="1fr 1fr 1fr" px={2}>
            <Grid gridTemplateColumns="auto 1fr">
              <Text small fontWeight="bold">
                Peers
              </Text>
              <Text small>{loadingDetails ? '-' : networks.peers.length}</Text>

              <Text small fontWeight="bold">
                Open peers
              </Text>
              <Text small>{loadingDetails ? '-' : networks.openPeers.length}</Text>
            </Grid>

            <Grid gridTemplateColumns="auto 1fr">
              <Text small fontWeight="bold">
                Connections
              </Text>
              <Text small>{loadingDetails ? '-' : networks.unfilteredSize}</Text>

              <Text small fontWeight="bold">
                Total speed
              </Text>
              <Text small>
                {loadingDetails ? '-' : greekIt(networks.totalSpeed * 10 ** 6, { fix: 0, suffix: 'bps' }).displayFull}
              </Text>
            </Grid>

            <Grid gridTemplateColumns="auto 1fr">
              <Text small fontWeight="bold">
                % with IPv6
              </Text>
              <Text small>
                {loadingDetails ? '-' : `${Math.round((networks.withIPv6.length / networks.unfilteredSize) * 100)}%`}
              </Text>

              <Text small fontWeight="bold">
                Facilities
              </Text>
              <Text small>{model.facilities.unfilteredSize}</Text>
            </Grid>
          </Grid>
        )}

        {this.renderTable(networks, this.networksColumns)}
      </FlexColumn>
    );
  }
}
