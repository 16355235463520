import React, { Component } from 'react';
import { inject } from 'mobx-react';

import Model from 'core/model/Model';
import { BorderedTab, BorderedTabs, Dialog } from 'core/components';

import SharePublicViewDialog from 'app/views/core/explorer/SharePublicViewDialog';
import EmailReportFormDialog from 'app/components/dataviews/tools/options/EmailReportFormDialog';
import SubscriptionDetailsFormDialog from 'app/views/settings/subscriptions/SubscriptionDetailsFormDialog';

@inject('$auth')
export default class ShareViewDialog extends Component {
  state = {
    selectedTabId: undefined
  };

  static defaultProps = {
    summary: false,
    link_type: 'data-explorer',
    editingLink: false,
    subscriptionDialogType: 'share'
  };

  componentDidMount() {
    const { tabOnOpen, hideLink, hideEmail } = this.props;
    let selectedTabId;
    if (selectedTabId) {
      selectedTabId = tabOnOpen;
    } else if (hideLink) {
      selectedTabId = hideEmail ? 'subscription' : 'email';
    } else {
      selectedTabId = 'link';
    }
    this.setState({ selectedTabId });
  }

  handleTabChange = (selectedTabId) => {
    this.setState({ selectedTabId });
  };

  render() {
    const {
      $auth,
      model,
      setModel,
      isOpen,
      onClose = () => {},
      summary,
      hideEmail,
      hideLink,
      hideSubscription,
      disabledSubscription,
      dataview,
      editingLink,
      link_type,
      defaultName,
      defaultDescription,
      dashboard,
      test_id,
      start_time,
      end_time,
      legacyLinkShare,
      privateShareUrl,
      isPublicLink,
      subscriptionDialogType,
      showManageSubscriptions,
      showManagePublicShares,
      subscriptionSelectOptionRenderer,
      showContentType
    } = this.props;
    const { selectedTabId } = this.state;

    const tabs = [
      !hideLink && {
        id: 'link',
        title: 'Link',
        panel: (
          <SharePublicViewDialog
            dataview={dataview}
            editing={editingLink}
            link_type={link_type}
            defaultName={defaultName}
            defaultDescription={defaultDescription}
            test_id={test_id}
            start_time={start_time}
            end_time={end_time}
            dashboard={dashboard}
            legacyLinkShare={legacyLinkShare}
            privateShareUrl={privateShareUrl}
            isPublicLink={isPublicLink}
            showManagePublicShares={showManagePublicShares}
            onClose={onClose}
          />
        )
      },

      !hideEmail && {
        id: 'email',
        title: 'Email',
        panel: (
          <EmailReportFormDialog
            {...this.props}
            model={
              model &&
              new Model({
                share: model.get('content_type'),
                content: model.get('content_id') || model.get('content_metadata')?.values,
                parametric_fields: model.get('parametric_fields'),
                parametric_value: model.get('parametric_value'),
                parametric_value_options: model.get('parametric_value_options'),
                parametric_value_type: model.get('parametric_value_type')
              })
            }
            onClose={onClose}
          />
        )
      },

      !(hideSubscription || (!$auth.isAdministrator && subscriptionDialogType === 'share')) && {
        id: 'subscription',
        title: {
          share: 'Subscription',
          subscribe: 'Subscribe',
          unsubscribe: 'Unsubscribe'
        }[subscriptionDialogType],
        panel: (
          <SubscriptionDetailsFormDialog
            summary={summary}
            model={model}
            setModel={setModel}
            subscriptionSelectOptionRenderer={subscriptionSelectOptionRenderer}
            subscriptionDialogType={subscriptionDialogType}
            showManageSubscriptions={showManageSubscriptions}
            showContentType={showContentType}
            onClose={onClose}
          />
        )
      }
    ].filter((tab) => tab);
    const onlyOneTab = tabs.length === 1;
    const { panel, title } = (onlyOneTab ? tabs.find((tab) => tab.id === selectedTabId) : tabs[0]) || {};
    if (!panel) {
      return null;
    }
    return (
      <Dialog
        title={onlyOneTab ? title : 'Share'}
        isOpen={isOpen}
        onClose={onClose}
        top={40}
        width={600}
        position="fixed"
      >
        {onlyOneTab ? (
          <>{panel}</>
        ) : (
          <BorderedTabs
            selectedTabId={selectedTabId}
            onChange={this.handleTabChange}
            selectedTabBackgroundColor="dialogBackground"
            overflow="hidden"
            width="auto"
            tabIndent={16}
            mt={2}
            mb={0}
            minimal
          >
            {tabs.map((tab) => (
              <BorderedTab
                key={tab.id}
                id={tab.id}
                title={tab.title}
                panel={tab.panel}
                disabled={tab.id === 'subscription' && disabledSubscription}
              />
            ))}
          </BorderedTabs>
        )}
      </Dialog>
    );
  }
}
