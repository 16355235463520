import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { createHistogram, createCounter } from 'core/util/uiMetrics';

import { Flex, Text, Card, Spinner } from 'core/components';

const HUNG_SPINNER_DURATION = 60 * 1000;
const SLOW_SPINNER_DURATION = 30 * 1000;

@inject('$logging')
@observer
export default class DataViewSpinner extends Component {
  static defaultProps = {
    loadingText: 'Your query is running, please wait...'
  };

  histogram = createHistogram({
    name: 'DataViewSpinner_duration_seconds',
    description: 'DataViewSpinner spinning in seconds'
  });

  counter = createCounter({
    name: 'DataViewSpinner_long_spinning',
    description: 'Number of spinner taking too long'
  });

  componentDidMount() {
    this.startTime = Date.now();
    this.startTimer();
  }

  startTimer = () => {
    const { $logging, dataview } = this.props;
    this.histogram.start();
    this.timer = setTimeout(() => {
      // Getting strange behavior when startTimer is call but never getting clearTimeout call. Adding duration check to prevent miss fire.
      if (Date.now() - this.startTime > SLOW_SPINNER_DURATION) {
        $logging.dataviewEvent('hung', this.startTime, dataview);
        this.counter({ type: 'hung' });
      }
    }, HUNG_SPINNER_DURATION);
  };

  componentDidUpdate(prevProps) {
    const { showSpinner, dataview, $logging } = this.props;
    if (!prevProps.showSpinner && showSpinner) {
      this.startTime = Date.now();
      this.startTimer();
    }

    if (prevProps.showSpinner && !showSpinner) {
      clearTimeout(this.timer);
      this.histogram.end();
      if (Date.now() - this.startTime > SLOW_SPINNER_DURATION) {
        $logging.dataviewEvent('slow', this.startTime, dataview);
        this.counter({ type: 'slow' });
      }
    }
  }

  render() {
    const {
      loadingText,
      loading,
      showSpinner,
      onQueryComplete,
      onModelSelect,
      onDataViewCreate,
      onSelectFpaAnalysisOption,
      ...rest
    } = this.props;
    if (!showSpinner) {
      return null;
    }

    return (
      <Flex position="absolute" alignItems="center" justifyContent="center" width="100%" {...rest}>
        <Card p={1}>
          <Flex alignItems="center">
            <Spinner size={18} />
            <Text muted as="div" ml={1}>
              {loadingText}
            </Text>
          </Flex>
        </Card>
      </Flex>
    );
  }
}
