import React from 'react';
import { TextArea } from '@blueprintjs/core';
import { Button, Dialog } from 'core/components';
import { FiCopy } from 'react-icons/fi';
import CopyToClipboardButton from './CopyToClipboardButton';

const dialogStyles = {
  overflow: 'auto',
  width: 600
};

const copyTextAreaStyle = {
  width: '100%',
  resize: 'none'
};

const CopyToClipboardDialog = (props) => {
  const { dialogHeight: height, value } = props;

  return (
    <Dialog {...props} style={{ ...dialogStyles, height }}>
      <Dialog.Body>
        <TextArea
          style={Object.assign({ height: Math.max((height || 115) - 115, 288), fontSize: 12 }, copyTextAreaStyle)}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          readOnly
          value={value}
        />
      </Dialog.Body>
      <Dialog.Footer>
        <CopyToClipboardButton text={value}>
          <Button text="Copy to Clipboard" icon={FiCopy} style={{ minWidth: 150 }} />
        </CopyToClipboardButton>
      </Dialog.Footer>
    </Dialog>
  );
};

export default CopyToClipboardDialog;
