import React from 'react';
import MenuHeader from './MenuHeader';

function MenuSection({
  title,
  icon,
  children,
  to,
  blank,
  color,
  iconColor,
  onClick,
  onMouseEnter,
  onMouseLeave,
  hasSubItems,
  showAnimatedArrow,
  isUpdated,
  isBeta,
  isPreview,
  isDarkMode,
  isProductLandingLink,
  isNew,
  ...rest
}) {
  return (
    <>
      <MenuHeader
        title={title}
        icon={icon}
        color={color}
        iconColor={iconColor}
        to={to}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        blank={blank}
        hasSubItems={hasSubItems}
        showAnimatedArrow={showAnimatedArrow}
        isUpdated={isUpdated}
        isBeta={isBeta}
        isPreview={isPreview}
        isDarkMode={isDarkMode}
        isNew={isNew}
        borderBottom={children ? 'thinDashed' : undefined}
        fontWeight={children ? 'heavy' : undefined}
        {...rest}
      />
      {children}
    </>
  );
}

export default MenuSection;
