import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { Button, Flex, Spinner, Suspense } from 'core/components';
import { Field, FormComponent, Select, RadioGroup } from 'core/form';
import Model from 'core/model/Model';
import { ViewInExplorerButton } from 'app/components/dataviews/tools';
import ViewInExplorerMenuItem from 'app/components/dataviews/tools/ViewInExplorerMenuItem';
import CloudDetailLink from 'app/components/links/CloudDetailLink';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import ResultsTable from 'app/components/resultsTable/ResultsTable';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';

const TRAFFIC_OPTIONS = [
  { value: 'cloud internal', label: 'Cloud Internal' },
  { value: 'from cloud to outside', label: 'VPC -> Internet' },
  { value: 'from outside to cloud', label: 'Internet -> VPC' }
];

const METRIC_OPTIONS = [
  { value: 'avg_pkts_per_sec', label: 'Packets/s' },
  { value: 'avg_bits_per_sec', label: 'Bits/s' },
  { value: 'avg_flows_per_sec', label: 'Flows/s' }
];

const CLOUD_OPTIONS = [
  { value: 'vpc', label: 'VPC' },
  { value: 'subnet', label: 'Subnet' },
  { value: 'instance', label: 'Instance Name' },
  { value: 'ip', label: 'IP' }
];

const INTERNET_OPTIONS = [
  { value: 'ip', label: 'IP' },
  { value: 'asn', label: 'ASN' },
  { value: 'country', label: 'Country' }
];

const QUERY_DIMENSION_MAP = {
  vpc: {
    src: 'kt_aws_src_vpc_name',
    dst: 'kt_aws_dst_vpc_name',
    srcResult: 'kt_aws_src_vpc_id',
    dstResult: 'kt_aws_dst_vpc_id'
  },
  subnet: {
    src: 'kt_aws_src_subnet_id',
    dst: 'kt_aws_dst_subnet_id'
  },
  instance: {
    src: 'kt_aws_src_vm_id',
    dst: 'kt_aws_dst_vm_id'
  },
  ip: {
    src: 'IP_src',
    dst: 'IP_dst',
    srcResult: 'inet_src_addr',
    dstResult: 'inet_dst_addr'
  },
  asn: {
    src: 'AS_src',
    dst: 'AS_dst',
    srcResult: 'i_src_as_name',
    dstResult: 'i_dst_as_name'
  },
  country: {
    src: 'Geography_src',
    dst: 'Geography_dst',
    srcFilter: 'src_geo',
    dstFilter: 'dst_geo'
  }
};

const DEFAULT_CONFIG = {
  trf_profile: 'cloud internal',
  metric: 'avg_pkts_per_sec',
  src: 'vpc',
  dst: 'vpc'
};

const FORM_OPTIONS = {
  name: 'AWS Reject Widget Config'
};

function getRejectQuery(src, dst, trafficProfile, aggregate) {
  const srcMetric = QUERY_DIMENSION_MAP[src]?.src;
  const dstMetric = QUERY_DIMENSION_MAP[dst]?.dst;
  return {
    all_devices: false,
    device_types: ['aws_subnet'],
    aggregateTypes: [aggregate],
    show_total_overlay: false,
    show_overlay: false,
    lookback_seconds: 3600,
    metric: [srcMetric, dstMetric],
    topx: 100,
    depth: 100,
    viz_type: 'table',
    filters: {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          filters: [
            {
              filterField: 'kt_aws_action',
              operator: '=',
              filterValue: 'REJECT'
            },
            {
              filterField: 'i_trf_profile',
              operator: '=',
              filterValue: trafficProfile
            }
          ],
          saved_filters: [],
          filterGroups: []
        }
      ]
    }
  };
}

function getSelectRejectQuery(filters) {
  return {
    all_devices: false,
    device_types: ['aws_subnet'],
    aggregateTypes: ['avg_bits_per_sec', 'avg_pkts_per_sec', 'avg_flows_per_sec'],
    show_total_overlay: false,
    show_overlay: false,
    lookback_seconds: 3600,
    filters: {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          filters: [
            {
              filterField: 'kt_aws_action',
              operator: '=',
              filterValue: 'REJECT'
            },
            ...filters
          ],
          saved_filters: [],
          filterGroups: []
        }
      ]
    }
  };
}

@withTheme
@observer
class AWSRejectActionsWidget extends Component {
  state = {
    isConfigurePanelOpen: false,
    config: null
  };

  static getDerivedStateFromProps(props, state) {
    const { config } = props;

    // If there's no config in state, let's get setup
    if (!state.config) {
      // If there's no config, initialize one
      if (!config) {
        return { config: DEFAULT_CONFIG, isConfigurePanelOpen: true, model: new Model(DEFAULT_CONFIG) };
      }
      return { config, model: new Model(config) };
    }

    return null;
  }

  handleSave = (form) => {
    const { onConfigChange } = this.props;
    const { model } = this.state;

    const config = form.getValues();
    model.set(config);
    this.setState({ isConfigurePanelOpen: false, config });

    if (onConfigChange) {
      onConfigChange(config);
    }
  };

  handleCancel = (form) => {
    form.reset();
    this.setState({ isConfigurePanelOpen: false });
  };

  handleOpenConfigurePanel = () => {
    this.setState({ isConfigurePanelOpen: true });
  };

  renderConfigurePanel() {
    const { model } = this.state;

    const fields = {
      trf_profile: {
        label: 'Traffic Profile',
        options: TRAFFIC_OPTIONS
      },
      metric: {
        label: 'Metric',
        options: METRIC_OPTIONS
      },
      src: {
        label: 'Source',
        rules: 'required'
      },
      dst: {
        label: 'Destination',
        rules: 'required'
      }
    };

    return (
      <FormComponent fields={fields} options={FORM_OPTIONS} model={model}>
        {({ form }) => (
          <Flex flexDirection="column" flex={1} p={2}>
            <Field name="trf_profile" large>
              <Select />
            </Field>
            <Flex justifyContent="space-between" maxWidth={250}>
              <Field
                name="src"
                options={form.getValue('trf_profile').endsWith('cloud') ? INTERNET_OPTIONS : CLOUD_OPTIONS}
                large
                mr={2}
              >
                <RadioGroup />
              </Field>
              <Field
                name="dst"
                large
                options={form.getValue('trf_profile').endsWith('outside') ? INTERNET_OPTIONS : CLOUD_OPTIONS}
              >
                <RadioGroup />
              </Field>
            </Flex>

            <Field name="metric" large>
              <Select />
            </Field>

            <Flex>
              <Button
                text="Cancel"
                disabled={!form.valid}
                onClick={() => this.handleCancel(form)}
                mr={1}
                minWidth={100}
              />
              <Button
                text="Save"
                intent="primary"
                disabled={!form.valid}
                onClick={() => this.handleSave(form)}
                minWidth={100}
              />
            </Flex>
          </Flex>
        )}
      </FormComponent>
    );
  }

  generateExplorerQuery(rowModel) {
    const { config } = this.state;

    const srcMap = QUERY_DIMENSION_MAP[config.src];
    const dstMap = QUERY_DIMENSION_MAP[config.dst];
    const srcValue = rowModel.get(srcMap.srcResult || srcMap.src).split(' ')[0];
    const dstValue = rowModel.get(dstMap.dstResult || dstMap.dst).split(' ')[0];

    const filters = [
      {
        filterField: srcMap.srcFilter || srcMap.srcResult || srcMap.src,
        operator: '=',
        filterValue: srcValue === '---' ? '' : srcValue
      },
      {
        filterField: dstMap.dstFilter || dstMap.dstResult || dstMap.dst,
        operator: '=',
        filterValue: dstValue === '---' ? '' : dstValue
      }
    ];

    return getSelectRejectQuery(filters);
  }

  rowAction = (model) => <ViewInExplorerButton small text="" query={this.generateExplorerQuery(model)} />;

  getDimensionOverrides = ({ metric }) => {
    const nameDimensions = [QUERY_DIMENSION_MAP.vpc.src, QUERY_DIMENSION_MAP.vpc.dst];
    const overrides = [];
    metric.forEach((dimension) => {
      if (nameDimensions.includes(dimension)) {
        overrides.push({
          key: dimension,
          hidden: false,
          renderer: ({ model }) => {
            const dimensionPrefix = dimension.substring(0, dimension.length - 5);
            const dstValue = model.get(`${dimensionPrefix}_id`);
            const dstName = model.get(dimension);
            return <CloudDetailLink cloud="aws" detail="vpc_id" value={dstValue} text={dstName} />;
          }
        });
      }
    });
    return overrides.length ? overrides : [];
  };

  render() {
    const { canCustomize, onRemove, approxWidth } = this.props;
    const { isConfigurePanelOpen, config } = this.state;
    const { src, dst, trf_profile, metric } = config;
    const title = 'AWS Security Group Reject Actions';
    const tooltip =
      'Use this widget to quickly see where your environment is rejecting traffic. Useful for security analysis and connectivity troubleshooting.';
    const query = getRejectQuery(src, dst, trf_profile, metric);
    return (
      <WidgetFrame
        canCustomize={canCustomize}
        menuOptions={<ViewInExplorerMenuItem query={query} />}
        configAction={this.handleOpenConfigurePanel}
        onRemove={onRemove}
        title={title}
        tooltip={tooltip}
        display="flex"
        flexDirection="column"
      >
        {isConfigurePanelOpen && this.renderConfigurePanel()}
        {!isConfigurePanelOpen && (
          <LightweightDataViewWrapper query={query}>
            {({ loading, dataview, queryModel, results, bucket }) => (
              <Suspense loading={loading} fallback={<Spinner pt={1} size={24} />}>
                <Flex overflow="auto">
                  <ResultsTable
                    query={query}
                    loading={loading}
                    dataview={dataview}
                    queryModel={queryModel}
                    queryResultsCollection={results}
                    bucket={bucket}
                    showSparklines={approxWidth > 400}
                    actions={this.rowAction}
                    dimensionOverrides={this.getDimensionOverrides(query)}
                  />
                </Flex>
              </Suspense>
            )}
          </LightweightDataViewWrapper>
        )}
      </WidgetFrame>
    );
  }
}

export default AWSRejectActionsWidget;
