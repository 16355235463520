import React, { Component, Suspense } from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import storeLoader from 'app/stores/storeLoader';
import { Box, Button, Flex, Spinner, Text } from 'core/components';
import { Field, FormComponent, Select } from 'core/form';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import DataViewModel from 'app/stores/query/DataViewModel';
import ViewInExplorerMenuItem from 'app/components/dataviews/tools/ViewInExplorerMenuItem';
import { getObjectForHash } from 'app/stores/query/urlHash';
import MetricsExplorerResult from 'app/views/metrics/MetricsExplorerResult';
import MetricsExplorerMenuItem from 'app/views/metrics/MetricsExplorerMenuItem';
import WidgetFrame from '../WidgetFrame';
import withConfigOptions from '../withConfigOptions';

@inject('$metrics')
@storeLoader('$savedViews.collection')
@withTheme
@withConfigOptions
@observer
class SavedViewWidget extends Component {
  state = {
    loading: true,

    // for Flow
    dataview: new DataViewModel(),

    // store the model for the saved view
    savedView: undefined,

    // for NMS
    query: undefined
  };

  componentDidMount() {
    const { config } = this.props;
    const { savedViewId } = config;
    this.fetchAndRenderView(savedViewId);
  }

  fetchAndRenderView(viewId) {
    const { $savedViews } = this.props;
    const { dataview } = this.state;

    $savedViews.collection.fetch().then(() => {
      const view = $savedViews.collection.get(viewId);
      const hash = view.get('saved_query_id');

      if (view.isNMS) {
        getObjectForHash(hash).then((response) => {
          const { query } = response;
          this.setState({ query, loading: false, savedView: view });
        });
      } else if (view) {
        dataview.initializeHash(hash);
        this.setState({ loading: false, dataview, savedView: view });
      }
    });
  }

  renderConfigurePanel() {
    const { $savedViews } = this.props;
    const { handleCancelConfiguration } = this.props;
    const { model } = this.props;

    const savedViewOptions = $savedViews.collection.get().map((view) => ({ value: view.id, label: view.name }));

    const fields = {
      savedViewId: {
        label: 'Saved View',
        rules: 'required'
      }
    };

    return (
      <FormComponent fields={fields} model={model} options={{ name: 'Configure Saved Views Widget' }}>
        {({ form }) => (
          <Flex flexDirection="column" flex={1} p={2}>
            <Box flex={1}>
              <Field name="savedViewId" large options={savedViewOptions} fill>
                <Select fill showFilter />
              </Field>
            </Box>
            <Flex>
              <Button text="Cancel" onClick={() => handleCancelConfiguration(form)} mr={1} minWidth={100} />
              <Button
                text="Save"
                intent="primary"
                disabled={!form.valid}
                onClick={() => this.handleSave(form)}
                minWidth={100}
              />
            </Flex>
          </Flex>
        )}
      </FormComponent>
    );
  }

  renderWidgetContent() {
    const { theme } = this.props;
    const { dataview, loading, savedView, query } = this.state;

    if (loading) {
      return null;
    }

    if (savedView.isNMS) {
      if (!query) {
        return <Text>Something went wrong!</Text>;
      }

      return (
        <Flex flexDirection="column" flex={1}>
          <MetricsExplorerResult query={query} isWidget />
        </Flex>
      );
    }

    // we want the chart to update on theme change however attempting to use allowChartUpdate results in a half-baked chart
    // instead we're opting to go nuclear and change the key of the component on theme change
    return (
      <DataViewWrapper
        key={`${dataview.hash}-${theme.name}`}
        dataview={dataview}
        viewProps={{
          showTooltips: true,
          height: '100%',
          showNativeLegend: true,
          flex: 1,
          chartConfig: {
            tooltip: {
              outside: true,
              useHTML: true
            }
          }
        }}
        allowCache
      />
    );
  }

  handleSave(form) {
    const { handleSaveConfiguration } = this.props;
    const newSavedViewId = form.getValue('savedViewId');

    handleSaveConfiguration(form);
    this.fetchAndRenderView(newSavedViewId);
  }

  renderMenuOptions = () => {
    const { savedView, dataview, query } = this.state;

    if (!savedView) {
      return null;
    }

    if (savedView.isNMS) {
      return <MetricsExplorerMenuItem text="Edit in Metrics Explorer" query={query} savedViewId={savedView.id} />;
    }

    return <ViewInExplorerMenuItem dataview={dataview} />;
  };

  render() {
    const {
      $savedViews,
      canCustomize,
      onRemove,
      config,
      isConfigurePanelOpen: isConfigurePanelOpenProp,
      handleShowConfigurePanel
    } = this.props;
    const { loading } = this.state;
    const savedView = config.savedViewId ? $savedViews.collection.get(config.savedViewId) : null;
    const isConfigurePanelOpen = isConfigurePanelOpenProp || (!loading && !savedView);
    const title = isConfigurePanelOpen ? 'Select a Saved View' : 'Loading...';

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        menuOptions={!loading && this.renderMenuOptions()}
        configAction={handleShowConfigurePanel}
        onRemove={onRemove}
        title={!isConfigurePanelOpen && savedView ? savedView.name : title}
        display="flex"
      >
        <Suspense loading={loading} fallback={<Spinner size={20} />}>
          {isConfigurePanelOpen && this.renderConfigurePanel()}
          {!isConfigurePanelOpen && this.renderWidgetContent()}
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default SavedViewWidget;
