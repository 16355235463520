import { css } from 'styled-components';
import { position, top, right, bottom, left, zIndex } from 'styled-system';

const positionHelper = css`
  ${position};
  ${top};
  ${right};
  ${bottom};
  ${left};
  ${zIndex};
`;

export default positionHelper;
