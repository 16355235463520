import React, { Component } from 'react';

import { Tag, Text } from 'core/components';

class NotificationOption extends Component {
  render() {
    const { option } = this.props;

    return (
      <Text>
        <Tag round small minimal mr="4px" intent={option.disabled ? 'danger' : undefined}>
          {option.tenantID ? 'Tenant: ' : ''}
          {option.disabled ? 'disabled' : option.channelType}
        </Tag>
        {option.label}
      </Text>
    );
  }
}

export default NotificationOption;
