import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';

import { FlexColumn } from 'core/components';
import Topology from './Topology';
import EmbeddedTopo from './EmbeddedTopo';

@inject('$topo', '$devices')
@observer
class ProviderTopology extends Component {
  static propTypes = {
    provider: PropTypes.string,
    embedded: PropTypes.bool
  };

  static defaultProps = {
    provider: undefined,
    embedded: false
  };

  @computed
  get provider() {
    const { provider, match } = this.props;

    return provider || match.params.provider;
  }

  componentDidMount() {
    this.getTopologyData();
  }

  componentDidUpdate = (prevProps) => {
    const { provider, match = {} } = this.props;
    const { params = {} } = match;
    const prevParams = prevProps.match ? prevProps.match.params : {};

    if (provider !== prevProps.provider || params.provider !== prevParams.provider) {
      this.getTopologyData();
    }
  };

  getTopologyData = () => {
    const { $topo } = this.props;

    $topo.getTopologyData({
      selectedProviders: [this.provider.toLowerCase()],
      type: 'provider',
      level: 'device',
      showProviders: 'name'
    });
  };

  handleOpenClick = () => {
    const { $topo } = this.props;
    $topo.navigateToProviderTopo(this.provider);
  };

  render() {
    const { embedded } = this.props;

    return (
      <FlexColumn height="100%">
        {embedded && <EmbeddedTopo onOpen={this.handleOpenClick} />}
        {!embedded && <Topology title={this.provider} />}
      </FlexColumn>
    );
  }
}

export default ProviderTopology;
