import { Box, Button, CalloutOutline, Card, Flex, Text } from 'core/components';
import { Field, InputGroup } from 'core/form';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import PropTypes from 'prop-types';
import { addFilterGroup } from 'app/util/filters';
import FilterConnector from './FilterConnector';
import FilterItemEdit from './FilterItemEdit';
import SavedFilter from './SavedFilter';
import SavedFilterSelector from './SavedFilterSelector';

export function groupHasFilters(group) {
  return (
    group.filters.fieldStates.length > 0 ||
    (group.saved_filters && group.saved_filters.fieldStates.length > 0) ||
    (group.filterGroups && group.filterGroups.fieldStates.length > 0)
  );
}

export class FilterGroupBase extends Component {
  static propTypes = {
    allowNestedFilters: PropTypes.bool,
    flat: PropTypes.bool,

    // Following Props are related to added customizability so FilterGroup can be re-used in UIs like SilentMode
    customConnector: PropTypes.element,
    operatorConstant: PropTypes.string,
    disableRemoveGroup: PropTypes.bool,
    addButtonText: PropTypes.string,
    minimalAddButton: PropTypes.bool,
    allowRightFilterField: PropTypes.bool
  };

  static defaultProps = {
    allowNestedFilters: false,
    flat: false,
    customConnector: undefined,
    operatorConstant: undefined,
    disableRemoveGroup: false,
    addButtonText: 'Add Ad Hoc Filter',
    minimalAddButton: true,
    allowRightFilterField: true
  };

  handleAddNestedGroup = () => {
    const { group } = this.props;
    addFilterGroup(group.filterGroups, {});
  };

  handleAddFilter = () => {
    const { group } = this.props;
    const { filters } = group;

    const existingFilters = filters.getValue();

    const lastFilter = existingFilters.length && existingFilters[existingFilters.length - 1];

    // if a previous filter was added, use the same filterField and operator to speed up
    // multiple conditions being added.
    filters.add({
      filterField: lastFilter.filterField || 'AS_dst',
      operator: lastFilter.operator
    });
  };

  handleRemoveFilter = (filterIndex) => {
    const { group } = this.props;
    group.filters.remove(filterIndex);
  };

  handleAddSavedFilter = ({ id }) => {
    const { group } = this.props;
    group.saved_filters.add({ filter_id: id });
  };

  handleCustomizeSavedFilter = (filterIndex) => {
    const { group, $savedFilters } = this.props;
    const filter = group.saved_filters.at(filterIndex);
    const filter_id = filter.filter_id.getValue();
    const not = filter.is_not.getValue();
    const savedFilter = $savedFilters.getSavedFilterById(filter_id);

    group.saved_filters.remove(filterIndex);

    group.filterGroups.add({ ...savedFilter.get('filters'), not });
  };

  handleRemoveSavedFilter = (filterIndex) => {
    const { group } = this.props;
    group.saved_filters.remove(filterIndex);
  };

  handleRemoveGroup = (e) => {
    const { groups, index } = this.props;

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    groups.remove(index);
  };

  renderAddButtons() {
    const { group, allowNestedFilters, allowSavedFilters, required, addButtonText, minimalAddButton } = this.props;
    const showNestedFilterControls = allowNestedFilters && group.filterGroups;
    const hasFilters = groupHasFilters(group);

    const flexProps = hasFilters ? { p: 1 } : { p: 1, [required ? 'pb' : 'py']: 2 };

    return (
      <Flex justifyContent="space-between" {...flexProps}>
        <Flex flex="1 1 auto">
          <Button icon="plus" onClick={this.handleAddFilter} mr={1} small minimal={minimalAddButton}>
            {addButtonText}
          </Button>

          {allowSavedFilters && <SavedFilterSelector onAdd={this.handleAddSavedFilter} small minimal width={125} />}

          {showNestedFilterControls && (
            <Button
              icon="add-to-artifact"
              onClick={this.handleAddNestedGroup}
              ml={allowSavedFilters ? 1 : 0}
              small
              minimal
            >
              Add Nested Group
            </Button>
          )}
        </Flex>
      </Flex>
    );
  }

  render() {
    const {
      $app,
      group,
      showNames,
      groupLabel,
      filterGroupLabel,
      required,
      customConnector,
      operatorConstant,
      loading,
      disableRemoveGroup,
      ...rest
    } = this.props;

    let headerContents = customConnector || (
      <FilterConnector
        group={group}
        onRemoveGroup={this.handleRemoveGroup}
        groupLabel={groupLabel}
        disableRemoveGroup={disableRemoveGroup}
      />
    );

    if (showNames) {
      headerContents = (
        <Flex flexDirection="column" pt="2px">
          <Field field={group.name} mb="4px">
            <InputGroup />
          </Field>
          {headerContents}
        </Flex>
      );
    }

    return (
      <Card bg={$app.darkThemeEnabled ? 'transparent' : undefined} borderRadius={2} {...rest}>
        <Flex bg={$app.darkThemeEnabled ? 'black.2' : 'rgba(16,22,26,0.05)'} p="4px" borderBottom="thin">
          {headerContents}
        </Flex>
        <Flex flexDirection="column">
          {group.filters.map((filter, idx, fields) => (
            <Box key={filter.filterField._id}>
              <FilterItemEdit
                {...this.props}
                filter={filter}
                index={idx}
                autoFocus={idx === fields.length - 1}
                onRemove={this.handleRemoveFilter}
              />
            </Box>
          ))}

          {group.saved_filters && group.saved_filters.fieldStates.length > 0 && (
            <Box px={1} pt={1}>
              {group.saved_filters.map((filter, idx) => (
                <SavedFilter
                  key={filter.filter_id._id}
                  filter_id={filter.filter_id.getValue()}
                  is_not={filter.is_not}
                  filterIdx={idx}
                  onCustomize={this.handleCustomizeSavedFilter}
                  onRemove={this.handleRemoveSavedFilter}
                  mb={1}
                />
              ))}
            </Box>
          )}

          {group.filterGroups && group.filterGroups.fieldStates.length > 0 && (
            <Box px={1} pt={1}>
              {group.filterGroups.map((nestedGroup, index) => (
                <FilterGroup
                  {...this.props}
                  key={nestedGroup.connector._id}
                  group={nestedGroup}
                  index={index}
                  groups={group.filterGroups}
                  showNames={false}
                  groupLabel="Group"
                  mb={1}
                />
              ))}
            </Box>
          )}

          {required && !groupHasFilters(group) && (
            <Box p={2}>
              <CalloutOutline intent="danger">
                <Text small>At least one {filterGroupLabel} with an ad hoc or saved filter is required</Text>
              </CalloutOutline>
            </Box>
          )}

          {this.renderAddButtons()}
        </Flex>
      </Card>
    );
  }
}

@inject('$app', '$savedFilters')
@observer
class FilterGroup extends FilterGroupBase {}

export default FilterGroup;
