import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { capitalize } from 'lodash';

import safelyParseJSON from 'core/util/safelyParseJson';
import { Dialog, FlexColumn, Tag, Text } from 'core/components';
import { dnsCodeLookup, getDnsRecordType, healthToIntent } from 'app/views/synthetics/utils/syntheticsUtils';

function answerTemplate(result) {
  const { answers = [], Question = [{}] } = result;
  const { name, type } = Question[0];
  /* eslint-disable react/no-array-index-key */
  return (
    <>
      <Text mb="1px" fontWeight="bold">
        {name} {getDnsRecordType(type)}
      </Text>
      {answers.map((answer, dataIndex) => {
        const { data } = answer;
        return (
          data && (
            <Text ml="10px" mb="1px" key={dataIndex}>
              {data}
            </Text>
          )
        );
      })}
    </>
  );
}

@observer
class DnssecButton extends Component {
  state = {
    isOpen: false
  };

  render() {
    const { model } = this.props;
    const { isOpen } = this.state;

    const dataString = model.get('healthDnssec.data');
    if (!dataString) {
      return <></>;
    }
    const [dataObject = {}] = safelyParseJSON(dataString) || [];
    const { queryResult = {}, trustChain = [] } = dataObject;
    const { AD: flag_ad, Question: q = [{}], Status } = queryResult;
    const { name: hostname, type: recordType } = q[0];
    const { short: rcode } = dnsCodeLookup(Status);
    const title = 'DNSSEC Results';
    const health = model.get('healthDnssec.overall_health.health');
    const tagIntent = healthToIntent({ health, isAggregated: model.get('isAggregated') });
    const hasError = ['timeout', 'error'].includes(model.get('healthDnssec.task_type'));
    const dialogBody = (
      <>
        <Text>
          <strong>name:</strong> {hostname}
        </Text>
        <Text>
          <strong>qtype:</strong> {getDnsRecordType(recordType)}
        </Text>
        <Text>
          <strong>rcode:</strong> {rcode}
        </Text>
        <Text fontWeight="bold">
          Authenticated Data (flag AD):{' '}
          <Tag mb="1px" fontWeight="normal" intent={tagIntent}>
            {flag_ad ? 'True' : 'False'}
          </Tag>
        </Text>
        {answerTemplate(queryResult)}
        {trustChain.map((host = []) => host.map((result) => answerTemplate(result)))}
      </>
    );

    return (
      <>
        <Tag
          fontSize="small"
          fontWeight="bold"
          minimal
          intent={tagIntent}
          onClick={() => this.setState({ isOpen: true })}
          rightIcon="open-application"
        >
          {health}
        </Tag>
        <Dialog
          isOpen={isOpen}
          onClose={() => this.setState({ isOpen: false })}
          title={title}
          top={150}
          width={758}
          height={550}
          maxHeight="calc(100vh - 150px)"
        >
          <Dialog.Body p={0} overflow="scroll" display="flex">
            <FlexColumn p={1}>
              {hasError ? <Text>{capitalize(model.get('healthDnssec.task_type'))}</Text> : dialogBody}
            </FlexColumn>
          </Dialog.Body>
        </Dialog>
      </>
    );
  }
}

export default DnssecButton;
