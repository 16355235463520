import React from 'react';
import { observer } from 'mobx-react';
import { ButtonGroup, Button, Tooltip, Flex } from 'core/components';
import PromptToQueryDialog from 'app/views/metrics/nlq/PromptToQueryDialog';
import ManageResult from './ManageResult';

@observer
class PromptFeedback extends React.Component {
  state = {
    isFeedbackOpen: false
  };

  handleCloseDialog = () => {
    this.setState({ isFeedbackOpen: false });
  };

  handleThumbClick = (user_rating) => {
    const { model } = this.props;
    return model.save({ user_rating }, { sendProvidedValuesOnly: true, toast: false, optimistic: true });
  };

  handleFeedbackClick = () => {
    this.setState((prevState) => ({ isFeedbackOpen: !prevState.isFeedbackOpen }));
  };

  render() {
    const { model, onDelete, journey } = this.props;
    const { isFeedbackOpen } = this.state;
    const isThumbsUp = model.get('user_rating') === 3;
    const isThumbsDown = model.get('user_rating') === 1;
    const isRemoving = model.isRequestActive('destroying');

    if (journey?.isInvestigation) {
      return null;
    }

    return (
      <Flex gap={2}>
        <ButtonGroup minimal>
          <Button
            small
            active={isThumbsUp}
            icon="thumbs-up"
            style={{ minWidth: 50 }}
            onClick={() => this.handleThumbClick(3)}
          />
          <Button
            small
            active={isThumbsDown}
            icon="thumbs-down"
            style={{ minWidth: 50 }}
            onClick={() => this.handleThumbClick(1)}
          />
          <Button small loading={isRemoving} icon="trash" style={{ minWidth: 50 }} onClick={onDelete} />
          <Tooltip content="Provide additional feedback" position="bottom">
            <Button small icon="comment" style={{ minWidth: 50 }} onClick={this.handleFeedbackClick} />
          </Tooltip>
          <ManageResult model={model} />
        </ButtonGroup>

        {isFeedbackOpen ? <PromptToQueryDialog model={model} onClose={this.handleCloseDialog} /> : null}
      </Flex>
    );
  }
}

export default PromptFeedback;
