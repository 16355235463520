import React, { Component } from 'react';
import { pick } from 'lodash';
import { Group, Image } from 'react-konva/lib/ReactKonvaCore'; // https://github.com/konvajs/react-konva#minimal-bundle
import { CELL_MARGIN, getDensityCellSize } from 'app/views/synthetics/components/mesh/util/constants';
import { getCellCanvas, getDotsCanvas } from 'app/views/synthetics/components/mesh/util/canvas';

export default class MeshCell extends Component {
  state = {
    col: {}
  };

  static defaultProps = {
    row: {},
    col: { empty: true },
    hovered: false,
    highlighted: false,
    filteredMetrics: [],
    onMouseOver: () => {},
    onMouseOut: () => {}
  };

  static getDerivedStateFromProps(props) {
    const { col, filteredMetrics } = props;
    const metrics = col?.metrics;

    if (filteredMetrics.length > 0 && metrics) {
      // overwrite the metrics in the col using metric filters passed in from props
      return {
        col: {
          ...col,
          metrics: pick(col.metrics, filteredMetrics)
        }
      };
    }

    return { col };
  }

  shouldComponentUpdate(newProps) {
    const { row, col, theme, density, filteredMetrics } = this.props;
    return (
      density?.size !== newProps.density?.size ||
      filteredMetrics.length !== newProps.filteredMetrics.length ||
      row.agent_id !== newProps.row.agent_id ||
      col.agent_id !== newProps.col.agent_id ||
      theme.name !== newProps.theme.name ||
      col.health?.length !== newProps.health?.length ||
      (col.empty && !newProps.col.empty)
    );
  }

  get cellSize() {
    const { density } = this.props;
    return getDensityCellSize(density);
  }

  get cellPosition() {
    const { row } = this.props;
    const { col } = this.state;
    const { index: rowIndex } = row;
    const { index: colIndex } = col;

    return {
      x: colIndex * (this.cellSize + CELL_MARGIN),
      y: rowIndex * (this.cellSize + CELL_MARGIN)
    };
  }

  get cell() {
    const { density, row, theme, hovered, highlighted, isAggregated, onMouseOver, onMouseOut } = this.props;
    const { col } = this.state;
    const { index: rowIndex } = row;
    const { index: colIndex, empty } = col;
    const {
      canvas,
      dimensions: { offset, size },
      cellFill
    } = getCellCanvas({ density, col, theme, cellSize: this.cellSize, hovered, highlighted, isAggregated });

    return (
      <Image
        image={canvas}
        x={offset}
        y={offset}
        width={size}
        height={size}
        fill={cellFill}
        onMouseOver={empty ? undefined : (evt) => onMouseOver(evt.target, rowIndex, colIndex)}
        onMouseOut={empty ? undefined : (evt) => onMouseOut(evt.target)}
      />
    );
  }

  get dots() {
    const { density, theme, isAggregated } = this.props;
    const { col } = this.state;

    return (
      <Image
        image={getDotsCanvas({ density, col, cellSize: this.cellSize, theme, isAggregated })}
        x={0}
        y={0}
        width={this.cellSize}
        height={this.cellSize}
        listening={false}
      />
    );
  }

  render() {
    const { col } = this.state;
    const { x, y } = this.cellPosition;

    if (col.empty) {
      return (
        <Group x={x} y={y} listening={false}>
          {this.cell}
        </Group>
      );
    }

    return (
      <Group x={x} y={y}>
        {this.cell}
        {this.dots}
      </Group>
    );
  }
}
