import React from 'react';

import DictionaryLookupLink from 'app/components/DictionaryLookupLink';

const ConnectivityTypeLink = ({ ...rest }) => (
  <DictionaryLookupLink
    baseUrl="/v4/core/quick-views/connectivity-type"
    lookup="interfaceClassification.connectivityTypes"
    {...rest}
  />
);

export default ConnectivityTypeLink;
