import $query from 'app/stores/query/$query';

function getCloudInboundOutboundInternalQuery(overrides = {}, cloud = 'aws') {
  let aggregateFilters = [];

  if (overrides.aggregateFilters) {
    aggregateFilters = overrides.aggregateFilters.slice(0, 3); // force internal, inbound, outbound only
  }

  overrides.aggregateFilters = aggregateFilters;
  overrides.device_types = [`${cloud}_subnet`];

  return $query.get('cloudInboundOutboundAggregateQuery', {
    ...overrides,
    depth: 49,
    topx: 0
  });
}

function getInboundOutboundInternalQuery(overrides = {}) {
  return $query.get('inboundOutboundInternalAggregateQuery', {
    ...overrides,
    depth: 49,
    topx: 0
  });
}

function getMkpInboundOutboundQuery(overrides = {}) {
  return $query.get('mkp.inboundOutboundAggregateQuery', {
    ...overrides,
    depth: 49,
    topx: 0
  });
}

class TabStore {
  tabs = {};

  getTabs(cloud = 'nocloud') {
    if (this.tabs[cloud]) {
      return this.tabs[cloud];
    }

    const tabs = {};

    if (this.store.$app.isSubtenant) {
      const queryGetter = getMkpInboundOutboundQuery;

      tabs.ASNS = {
        title: 'ASNs',
        id: 'asns',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'AS Number',
            aggregateFilters: [{ metric: ['AS_dst'] }, { metric: ['AS_src'] }]
          },
          cloud
        )
      };

      tabs.IPS = {
        title: 'IPs',
        id: 'ips',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'IP Address',
            aggregateFilters: [{ metric: ['IP_dst'] }, { metric: ['IP_src'] }]
          },
          cloud
        )
      };

      tabs.COUNTRIES = {
        title: 'Countries',
        id: 'countries',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'Country',
            aggregateFilters: [{ metric: ['Geography_dst'] }, { metric: ['Geography_src'] }]
          },
          cloud
        )
      };

      tabs.REGIONS = {
        title: 'Regions',
        id: 'regions',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'Region',
            aggregateFilters: [{ metric: ['dst_geo_region'] }, { metric: ['src_geo_region'] }]
          },
          cloud
        )
      };

      tabs.CITIES = {
        title: 'Cities',
        id: 'cities',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'City',
            aggregateFilters: [{ metric: ['dst_geo_city'] }, { metric: ['src_geo_city'] }]
          },
          cloud
        )
      };
    } else {
      const queryGetter = cloud !== 'nocloud' ? getCloudInboundOutboundInternalQuery : getInboundOutboundInternalQuery;

      tabs.IPS = {
        title: 'IPs',
        id: 'ips',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'IP Address',
            aggregateFilters: [
              { metric: ['IP_src', 'IP_dst'] },
              { metric: ['IP_src'] },
              { metric: ['IP_dst'] },
              { metric: ['IP_src', 'IP_dst'] },
              { metric: ['IP_src', 'IP_dst'] }
            ]
          },
          cloud
        )
      };

      tabs.SITES = {
        title: 'Sites',
        id: 'sites',
        query: queryGetter({ metric: ['i_device_site_name'] }, cloud)
      };

      tabs.DEVICES = {
        title: 'Devices',
        id: 'devices',
        query: queryGetter({ metric: ['i_device_id'] }, cloud)
      };

      tabs.INTERFACES = {
        title: 'Interfaces',
        id: 'interfaces',
        query: $query.get(
          'interfaceIngressEgressAggregateQuery',
          {
            depth: 49,
            topx: 0
          },
          cloud
        )
      };

      tabs.ASNS = {
        title: 'ASNs',
        id: 'asns',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'AS Number',
            aggregateFilters: [
              { metric: ['AS_src', 'AS_dst'] },
              { metric: ['AS_src'] },
              { metric: ['AS_dst'] },
              { metric: ['AS_src', 'AS_dst'] },
              { metric: ['AS_src', 'AS_dst'] }
            ]
          },
          cloud
        )
      };

      tabs.APPLICATIONS = {
        title: 'Applications',
        id: 'applications',
        query: queryGetter({ metric: ['application'] }, cloud)
      };

      tabs.BGP_COMMUNITIES = {
        title: 'BGP Communities',
        id: 'bgp',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'BGP Community',
            aggregateFilters: [
              { metric: ['src_bgp_community', 'dst_bgp_community'] },
              { metric: ['src_bgp_community'] },
              { metric: ['dst_bgp_community'] },
              { metric: ['src_bgp_community', 'dst_bgp_community'] },
              { metric: ['src_bgp_community', 'dst_bgp_community'] }
            ]
          },
          cloud
        )
      };

      tabs.BGP_PATH = {
        title: 'BGP Path',
        id: 'src_bgp_aspath',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'AS Path',
            aggregateFilters: [
              { metric: ['src_bgp_aspath', 'dst_bgp_aspath'] },
              { metric: ['src_bgp_aspath'] },
              { metric: ['dst_bgp_aspath'] },
              { metric: ['src_bgp_aspath', 'dst_bgp_aspath'] },
              { metric: ['src_bgp_aspath', 'dst_bgp_aspath'] }
            ]
          },
          cloud
        )
      };

      tabs.COUNTRIES = {
        title: 'Countries',
        id: 'countries',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'Country',
            aggregateFilters: [
              { metric: ['Geography_src', 'Geography_dst'] },
              { metric: ['Geography_src'] },
              { metric: ['Geography_dst'] },
              { metric: ['Geography_src', 'Geography_dst'] },
              { metric: ['Geography_src', 'Geography_dst'] }
            ]
          },
          cloud
        )
      };

      tabs.REGIONS = {
        title: 'Regions',
        id: 'regions',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'Region',
            aggregateFilters: [
              { metric: ['src_geo_region', 'dst_geo_region'] },
              { metric: ['src_geo_region'] },
              { metric: ['dst_geo_region'] },
              { metric: ['src_geo_region', 'dst_geo_region'] },
              { metric: ['src_geo_region', 'dst_geo_region'] }
            ]
          },
          cloud
        )
      };

      tabs.CITIES = {
        title: 'Cities',
        id: 'cities',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'City',
            aggregateFilters: [
              { metric: ['src_geo_city', 'dst_geo_city'] },
              { metric: ['src_geo_city'] },
              { metric: ['dst_geo_city'] },
              { metric: ['src_geo_city', 'dst_geo_city'] },
              { metric: ['src_geo_city', 'dst_geo_city'] }
            ]
          },
          cloud
        )
      };

      tabs.PROVIDERS = {
        title: 'Providers',
        id: 'providers',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'Provider',
            aggregateFilters: [
              { metric: ['i_src_provider_classification', 'i_dst_provider_classification'] },
              { metric: ['i_src_provider_classification'] },
              { metric: ['i_dst_provider_classification'] },
              { metric: ['i_src_provider_classification', 'i_dst_provider_classification'] },
              { metric: ['i_src_provider_classification', 'i_dst_provider_classification'] }
            ]
          },
          cloud
        )
      };

      tabs.CONNECTIVITY_TYPE = {
        title: 'Connectivity Type',
        id: 'i_src_connect_type_name',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'Connectivity Type',
            aggregateFilters: [
              { metric: ['i_src_connect_type_name', 'i_dst_connect_type_name'] },
              { metric: ['i_src_connect_type_name'] },
              { metric: ['i_dst_connect_type_name'] },
              { metric: ['i_src_connect_type_name', 'i_dst_connect_type_name'] },
              { metric: ['i_src_connect_type_name', 'i_dst_connect_type_name'] }
            ]
          },
          cloud
        )
      };

      tabs.NETWORK_BOUNDARY = {
        title: 'Network Boundary',
        id: 'i_src_network_bndry_name',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'Network Boundary',
            aggregateFilters: [
              { metric: ['i_src_network_bndry_name', 'i_dst_network_bndry_name'] },
              { metric: ['i_src_network_bndry_name'] },
              { metric: ['i_dst_network_bndry_name'] },
              { metric: ['i_src_network_bndry_name', 'i_dst_network_bndry_name'] },
              { metric: ['i_src_network_bndry_name', 'i_dst_network_bndry_name'] }
            ]
          },
          cloud
        )
      };

      tabs.PACKET_SIZE = {
        title: 'Packet Size',
        id: 'sampledpktsize',
        query: queryGetter({ metric: ['sampledpktsize'] }, cloud)
      };

      tabs.PROTO = {
        title: 'Protocol',
        id: 'Proto',
        query: queryGetter({ metric: ['Proto'] }, cloud)
      };

      tabs.INET_FAMILY = {
        title: 'INET Family',
        id: 'inet_family',
        query: queryGetter({ metric: ['inet_family'] }, cloud)
      };

      tabs.SERVICE = {
        title: 'Service (Port+Proto)',
        id: 'service',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'Service (Port+Proto)',
            aggregateFilters: [
              { metric: ['src_proto_port', 'dst_proto_port'] },
              { metric: ['src_proto_port'] },
              { metric: ['dst_proto_port'] },
              { metric: ['src_proto_port', 'dst_proto_port'] },
              { metric: ['src_proto_port', 'dst_proto_port'] }
            ]
          },
          cloud
        )
      };

      tabs.NEXTHOP_ASNS = {
        title: 'Nexthop ASNs',
        id: 'nexthop',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'Next Hop AS Number',
            aggregateFilters: [
              { metric: ['src_nexthop_asn', 'dst_nexthop_asn'] },
              { metric: ['src_nexthop_asn'] },
              { metric: ['dst_nexthop_asn'] },
              { metric: ['src_nexthop_asn', 'dst_nexthop_asn'] },
              { metric: ['src_nexthop_asn', 'dst_nexthop_asn'] }
            ]
          },
          cloud
        )
      };

      tabs.PREFIX_LEN = {
        title: 'Prefix/LEN',
        id: 'prefix/len',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: 'Route Prefix/LEN',
            aggregateFilters: [
              { metric: ['src_route_prefix_len', 'dst_route_prefix_len'] },
              { metric: ['src_route_prefix_len'] },
              { metric: ['dst_route_prefix_len'] },
              { metric: ['src_route_prefix_len', 'dst_route_prefix_len'] },
              { metric: ['src_route_prefix_len', 'dst_route_prefix_len'] }
            ]
          },
          cloud
        )
      };

      tabs.TRAFFIC_PROFILE = {
        title: 'Traffic Profile',
        id: 'i_trf_profile',
        query: queryGetter({ metric: ['i_trf_profile'] }, cloud)
      };

      tabs.AZURE_INSTANCE = {
        title: 'Azure Instance',
        id: 'az_inst_name',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'Azure Instance Name',
            aggregateFilters: [
              { metric: ['kt_az_src_inst_name', 'kt_az_dst_inst_name'] },
              { metric: ['kt_az_dst_inst_name'] },
              { metric: ['kt_az_src_inst_name'] }
            ]
          },
          'azure'
        )
      };

      tabs.AZURE_REGION = {
        title: 'Azure Region',
        id: 'az_region',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'Azure Region',
            aggregateFilters: [
              { metric: ['kt_az_src_region', 'kt_az_dst_region'] },
              { metric: ['kt_az_dst_region'] },
              { metric: ['kt_az_src_region'] }
            ]
          },
          'azure'
        )
      };

      tabs.AZURE_ZONE = {
        title: 'Azure Zone',
        id: 'az_zone',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'Azure Zone',
            aggregateFilters: [
              { metric: ['kt_az_src_zone', 'kt_az_dst_zone'] },
              { metric: ['kt_az_dst_zone'] },
              { metric: ['kt_az_src_zone'] }
            ]
          },
          'azure'
        )
      };

      tabs.AZURE_PUBLIC_DNS = {
        title: 'Azure Public DNS Name',
        id: 'az_fqdn',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'Azure Public DNS Name',
            aggregateFilters: [
              { metric: ['kt_az_src_fqdn', 'kt_az_dst_fqdn'] },
              { metric: ['kt_az_dst_fqdn'] },
              { metric: ['kt_az_src_fqdn'] }
            ]
          },
          'azure'
        )
      };

      tabs.AZURE_VNET = {
        title: 'Azure VNet ID',
        id: 'az_vnet',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'Azure VNet ID',
            aggregateFilters: [
              { metric: ['kt_az_src_vnet', 'kt_az_dst_vnet'] },
              { metric: ['kt_az_dst_vnet'] },
              { metric: ['kt_az_src_vnet'] }
            ]
          },
          'azure'
        )
      };

      tabs.AZURE_SUBNET = {
        title: 'Azure Subnet Name',
        id: 'az_subnet',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'Azure Subnet Name',
            aggregateFilters: [
              { metric: ['kt_az_src_subnet', 'kt_az_dst_subnet'] },
              { metric: ['kt_az_dst_subnet'] },
              { metric: ['kt_az_src_subnet'] }
            ]
          },
          'azure'
        )
      };

      tabs.AZURE_PUBLIC_IP = {
        title: 'Azure Public IP Address',
        id: 'az_public_ip',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'Azure Public IP Address',
            aggregateFilters: [
              { metric: ['kt_az_src_public_ip', 'kt_az_dst_public_ip'] },
              { metric: ['kt_az_dst_public_ip'] },
              { metric: ['kt_az_src_public_ip'] }
            ]
          },
          'azure'
        )
      };

      tabs.AZURE_SUBSCRIPTION = {
        title: 'Azure Subscription',
        id: 'az_sub_id',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'Azure Subscription',
            aggregateFilters: [
              { metric: ['kt_az_src_sub_id', 'kt_az_dst_sub_id'] },
              { metric: ['kt_az_dst_sub_id'] },
              { metric: ['kt_az_src_sub_id'] }
            ]
          },
          'azure'
        )
      };

      tabs.AZURE_SECURITY_RULE = {
        title: 'Azure Security Rule',
        id: 'az_sec_rule',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'Azure Security Rule',
            aggregateFilters: [
              { metric: ['ktsubtype__azure_subnet__STR01', 'ktsubtype__azure_subnet__STR00'] },
              { metric: ['ktsubtype__azure_subnet__STR01'] },
              { metric: ['ktsubtype__azure_subnet__STR00'] }
            ]
          },
          'azure'
        )
      };

      tabs.AZURE_FIREWALL_ACTION = {
        title: 'Azure Firewall Action',
        id: 'az_firewall',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'Azure Firewall Action',
            aggregateFilters: [
              { metric: ['ktsubtype__azure_subnet__STR03', 'ktsubtype__azure_subnet__STR02'] },
              { metric: ['ktsubtype__azure_subnet__STR03'] },
              { metric: ['ktsubtype__azure_subnet__STR02'] }
            ]
          },
          'azure'
        )
      };

      tabs.AZURE_SECURITY_GROUP = {
        title: 'Azure Security Group',
        id: 'az_sec_group',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'Azure Security Group',
            aggregateFilters: [
              { metric: ['ktsubtype__azure_subnet__STR05', 'ktsubtype__azure_subnet__STR04'] },
              { metric: ['ktsubtype__azure_subnet__STR05'] },
              { metric: ['ktsubtype__azure_subnet__STR04'] }
            ]
          },
          'azure'
        )
      };

      tabs.K8S_POD_NAME = {
        title: 'Kubernetes Pod Name',
        id: 'k8s_pod_name',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: '(Kubernetes) Pod Name',
            aggregateFilters: [
              { metric: ['kt_k8s_src_pod_name', 'kt_k8s_dst_pod_name'] },
              { metric: ['kt_k8s_src_pod_name'] },
              { metric: ['kt_k8s_dst_pod_name'] }
            ]
          },
          cloud
        )
      };

      tabs.K8S_POD_NS = {
        title: 'Kubernetes Pod Namespace',
        id: 'k8s_pod_ns',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: '(Kubernetes) Pod Namespace',
            aggregateFilters: [
              { metric: ['kt_k8s_src_pod_ns', 'kt_k8s_dst_pod_ns'] },
              { metric: ['kt_k8s_src_pod_ns'] },
              { metric: ['kt_k8s_dst_pod_ns'] }
            ]
          },
          cloud
        )
      };

      tabs.K8S_LOAD_NAME = {
        title: 'Kubernetes Workload Name',
        id: 'k8s_load_name',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: '(Kubernetes) Workload Name',
            aggregateFilters: [
              { metric: ['kt_k8s_src_load_name', 'kt_k8s_dst_load_name'] },
              { metric: ['kt_k8s_src_load_name'] },
              { metric: ['kt_k8s_dst_load_name'] }
            ]
          },
          cloud
        )
      };

      tabs.K8S_LOAD_NS = {
        title: 'Kubernetes Workload Namespace',
        id: 'k8s_load_ns',
        query: queryGetter(
          {
            aggregateFiltersDimensionLabel: '(Kubernetes) Workload Namespace',
            aggregateFilters: [
              { metric: ['kt_k8s_src_load_ns', 'kt_k8s_dst_load_ns'] },
              { metric: ['kt_k8s_src_load_ns'] },
              { metric: ['kt_k8s_dst_load_ns'] }
            ]
          },
          cloud
        )
      };

      tabs.AWS_INSTANCE = {
        title: 'AWS Instance',
        id: 'aws_vm_name',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'AWS Instance Name',
            aggregateFilters: [
              { metric: ['kt_aws_src_vm_name', 'kt_aws_dst_vm_name'] },
              { metric: ['kt_aws_dst_vm_name'] },
              { metric: ['kt_aws_src_vm_name'] }
            ]
          },
          'aws'
        )
      };

      tabs.AWS_REGION = {
        title: 'AWS Region',
        id: 'aws_region',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'AWS Region',
            aggregateFilters: [
              { metric: ['kt_aws_src_region', 'kt_aws_dst_region'] },
              { metric: ['kt_aws_dst_region'] },
              { metric: ['kt_aws_src_region'] }
            ]
          },
          'aws'
        )
      };

      tabs.AWS_ZONE = {
        title: 'AWS Zone Name',
        id: 'aws_zone',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'AWS Zone',
            aggregateFilters: [
              { metric: ['kt_aws_src_zone', 'kt_aws_dst_zone'] },
              { metric: ['kt_aws_dst_zone'] },
              { metric: ['kt_aws_src_zone'] }
            ]
          },
          'aws'
        )
      };

      tabs.AWS_ZONE_ID = {
        title: 'AWS Zone ID',
        id: 'aws_zone_id',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'AWS Zone ID',
            aggregateFilters: [
              { metric: ['kt_aws_src_zone_id', 'kt_aws_dst_zone_id'] },
              { metric: ['kt_aws_dst_zone_id'] },
              { metric: ['kt_aws_src_zone_id'] }
            ]
          },
          'aws'
        )
      };

      tabs.AWS_SECURITY_GROUP = {
        title: 'AWS Security Group',
        id: 'aws_sg',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'AWS Security Group',
            aggregateFilters: [
              { metric: ['kt_aws_src_sg', 'kt_aws_dst_sg'] },
              { metric: ['kt_aws_dst_sg'] },
              { metric: ['kt_aws_src_sg'] }
            ]
          },
          'aws'
        )
      };

      tabs.AWS_PUBLIC_DNS = {
        title: 'AWS Public DNS Name',
        id: 'aws_pub_dns',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'AWS Public DNS Name',
            aggregateFilters: [
              { metric: ['kt_aws_src_pub_dns', 'kt_aws_dst_pub_dns'] },
              { metric: ['kt_aws_dst_pub_dns'] },
              { metric: ['kt_aws_src_pub_dns'] }
            ]
          },
          'aws'
        )
      };

      tabs.AWS_PRIVATE_DNS = {
        title: 'AWS Private DNS Name',
        id: 'aws_priv_dns',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'AWS Private DNS Name',
            aggregateFilters: [
              { metric: ['kt_aws_src_priv_dns', 'kt_aws_dst_priv_dns'] },
              { metric: ['kt_aws_dst_priv_dns'] },
              { metric: ['kt_aws_src_priv_dns'] }
            ]
          },
          'aws'
        )
      };

      tabs.AWS_VPC_ID = {
        title: 'AWS VPC ID',
        id: 'aws_vpc_id',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'AWS VPC ID',
            aggregateFilters: [
              { metric: ['kt_aws_src_vpc_id', 'kt_aws_dst_vpc_id'] },
              { metric: ['kt_aws_dst_vpc_id'] },
              { metric: ['kt_aws_src_vpc_id'] }
            ]
          },
          'aws'
        )
      };

      tabs.AWS_SUBNET_ID = {
        title: 'AWS Subnet ID',
        id: 'aws_subnet_id',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'AWS Subnet ID',
            aggregateFilters: [
              { metric: ['kt_aws_src_subnet_id', 'kt_aws_dst_subnet_id'] },
              { metric: ['kt_aws_dst_subnet_id'] },
              { metric: ['kt_aws_src_subnet_id'] }
            ]
          },
          'aws'
        )
      };

      tabs.AWS_INTERFACE_ID = {
        title: 'AWS Interface ID',
        id: 'aws_interface_id',
        query: getCloudInboundOutboundInternalQuery({ metric: ['ktsubtype__aws_subnet__STR03'] }, 'aws')
      };

      tabs.AWS_ACTION = {
        title: 'AWS Firewall Action',
        id: 'aws_action',
        query: getCloudInboundOutboundInternalQuery({ metric: ['kt_aws_action'] }, 'aws')
      };

      tabs.AWS_STATUS = {
        title: 'AWS Logging Status',
        id: 'aws_status',
        query: getCloudInboundOutboundInternalQuery({ metric: ['kt_aws_status'] }, 'aws')
      };

      tabs.GCP_PROJECT_ID = {
        title: 'GCP Project ID',
        id: 'gce_proj_id',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'GCP Project ID',
            aggregateFilters: [
              {
                metric: ['ktsubtype__gcp_subnet__STR00', 'ktsubtype__gcp_subnet__STR01']
              },
              { metric: ['ktsubtype__gcp_subnet__STR01'] }, // dst
              { metric: ['ktsubtype__gcp_subnet__STR00'] } // src
            ]
          },
          'gcp'
        )
      };

      tabs.GCP_VM_NAME = {
        title: 'GCP VM Name',
        id: 'gce_vm_name',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'GCP VM Name',
            aggregateFilters: [
              {
                metric: ['ktsubtype__gcp_subnet__STR02', 'ktsubtype__gcp_subnet__STR03']
              },
              { metric: ['ktsubtype__gcp_subnet__STR03'] }, // dst
              { metric: ['ktsubtype__gcp_subnet__STR02'] } // src
            ]
          },
          'gcp'
        )
      };

      tabs.GCP_REGION = {
        title: 'GCP Region',
        id: 'gce_region',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'GCP Region',
            aggregateFilters: [
              {
                metric: ['ktsubtype__gcp_subnet__STR04', 'ktsubtype__gcp_subnet__STR05']
              },
              { metric: ['ktsubtype__gcp_subnet__STR05'] }, // dst
              { metric: ['ktsubtype__gcp_subnet__STR04'] } // src
            ]
          },
          'gcp'
        )
      };

      tabs.GCP_ZONE = {
        title: 'GCP Zone',
        id: 'gce_zone',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'GCP Zone',
            aggregateFilters: [
              {
                metric: ['ktsubtype__gcp_subnet__STR06', 'ktsubtype__gcp_subnet__STR07']
              },
              { metric: ['ktsubtype__gcp_subnet__STR07'] }, // dst
              { metric: ['ktsubtype__gcp_subnet__STR06'] } // src
            ]
          },
          'gcp'
        )
      };

      tabs.GCP_SUBNET_NAME = {
        title: 'GCP Subnet Name',
        id: 'gce_vpc_snn',
        query: getCloudInboundOutboundInternalQuery(
          {
            aggregateFiltersDimensionLabel: 'GCP Subnet Name',
            aggregateFilters: [
              {
                metric: ['ktsubtype__gcp_subnet__STR08', 'ktsubtype__gcp_subnet__STR09']
              },
              { metric: ['ktsubtype__gcp_subnet__STR09'] }, // dst
              { metric: ['ktsubtype__gcp_subnet__STR08'] } // src
            ]
          },
          'gcp'
        )
      };

      tabs.GCP_REPORTER = {
        title: 'GCP Reporter',
        id: 'gce_reporter',
        query: getCloudInboundOutboundInternalQuery({ metric: ['ktsubtype__gcp_subnet__STR10'] }, 'gcp')
      };
    }

    this.tabs[cloud] = tabs;
    return tabs;
  }
}

export default new TabStore();
