import { availableIntegrations } from 'app/views/integrations/availableIntegrations';

const channelTypes = {
  ...availableIntegrations,

  json: {
    text: '{ }',
    showName: true
  }
};

export default channelTypes;
