import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FiEdit, FiCopy } from 'react-icons/fi';
import moment from 'moment';
import { Box, Button, Callout, Card, EmptyState, LinkButton, Text } from 'core/components';
import { Table, VirtualizedTable, CELL_ACTIONS, CELL_TYPES } from 'core/components/table';
import { RemoveButton } from 'core/form';
import { formatDate } from 'core/util/dateUtils';
import { formatNumber } from 'app/util/utils';
import TestPauseResumeButton from 'app/views/synthetics/tests/TestPauseResumeButton';
import TestTableNameRenderer from 'app/views/synthetics/tests/TestTableNameRenderer';
import { handleCopyTest, healthRenderer } from 'app/views/synthetics/utils/syntheticsUtils';

const scaleMax = 0.999;

@inject('$exports', '$app', '$auth', '$syn')
@withRouter
@observer
class TestTable extends Component {
  static defaultProps = {
    showSelectTest: true
  };

  getColumns = () => {
    const { showSelectTest, $auth, history } = this.props;
    const canEditTests = $auth.hasRbacPermissions(['synthetics.test::update']);
    const canCreateTests = $auth.hasRbacPermissions(['synthetics.test::create']);
    const canDeleteTests = $auth.hasRbacPermissions(['synthetics.test::delete']);

    const columns = [
      {
        label: 'Alert Status',
        name: 'healthStatusRaw',
        computed: true,
        width: 100,
        ellipsis: false,
        renderer: ({ model }) => healthRenderer(model)
      },
      {
        label: 'Test',
        name: 'display_name',
        flexBasis: 210,
        ellipsis: false,
        renderer: ({ model, updateGrid }) => <TestTableNameRenderer model={model} updateGrid={updateGrid} />
      },
      {
        label: 'Created by',
        name: 'created_by.user_full_name',
        flexBasis: 120,
        ellipsis: false,
        renderer: ({ model, value }) => (
          <Box overflow="hidden">
            <Text as="div" ellipsis>
              {value}
            </Text>
            <Text as="div" muted>
              on {formatDate(model.get('cdate'))}
            </Text>
          </Box>
        )
      },
      {
        label: 'Last updated',
        name: 'edate',
        flexBasis: 120,
        ellipsis: false,
        renderer: ({ model }) => (
          <Box overflow="hidden">
            <Text as="div" ellipsis>
              {model.get('last_updated_by.user_full_name') || model.get('created_by.user_full_name')}
            </Text>
            <Text as="div" muted>
              on {formatDate(model.get('edate'))}
            </Text>
          </Box>
        )
      },
      {
        label: 'Agents',
        name: 'config.agents.length',
        ellipsis: false,
        flexBasis: 50
      },
      {
        label: 'Monthly Credits',
        name: 'creditBurnRate',
        flexBasis: 70,
        ellipsis: false,
        renderer: ({ model }) => {
          const value = model.creditsPerMonth;
          return value > 999 ? formatNumber(value, { fix: 1, scaleMax }).displayFull : value;
        }
      }
    ];

    const adminColumnsActions = [];

    if (canEditTests) {
      if (showSelectTest) {
        columns.unshift({
          key: 'select',
          type: CELL_TYPES.ACTION,
          actions: [CELL_ACTIONS.SELECT],
          width: 36
        });
      }

      adminColumnsActions.push(
        (model) => (
          <TestPauseResumeButton
            key="playpause"
            model={model}
            title={`${model.get('test_status') === 'A' ? 'Pause' : 'Resume'} Test`}
            tracker="test-play-pause-btn"
            hideText
            minimal
            small
          />
        ),
        (model) => (
          <Button
            key="edit"
            icon={FiEdit}
            title="Edit"
            tracker="test-edit-btn"
            onClick={(e) => {
              e.stopPropagation();
              this.handleEdit(model);
            }}
            small
            minimal
          />
        )
      );
    }

    if (canCreateTests) {
      adminColumnsActions.push((model) => (
        <Button
          key="copy"
          icon={FiCopy}
          title="Copy"
          tracker="test-copy-btn"
          onClick={(e) => {
            e.stopPropagation();
            handleCopyTest(history, model);
          }}
          small
          minimal
        />
      ));
    }

    if (canDeleteTests) {
      adminColumnsActions.push((model) => (
        <RemoveButton
          model={model}
          key="remove"
          onRemove={() => this.handleRemove(model)}
          entityName="test"
          showIcon
          small
          hideText
        />
      ));
    }

    if (canCreateTests || canDeleteTests || canEditTests) {
      columns.push(
        {
          label: 'ID',
          name: 'id',
          width: 65
        },
        {
          type: CELL_TYPES.ACTION,
          actions: adminColumnsActions
        }
      );
    }

    return columns;
  };

  handleEdit(model) {
    const { history } = this.props;
    history.push(`/v4/synthetics/tests/${model.id}`);
  }

  handleRemove(model) {
    model.destroy();
  }

  handleOnSort = ({ field, direction }) => {
    const { $exports } = this.props;
    $exports.setHash({ hashedSortField: field, hashedSortDirection: direction });
  };

  render() {
    const { testCollection, $app, $auth, $syn } = this.props;
    const canCreateTests = $auth.hasRbacPermissions(['synthetics.test::create']);
    const TableComponent = $app.isExport ? Table : VirtualizedTable;

    return (
      <Card flex={1} display="flex" flexDirection="column" overflow="hidden" m="1px">
        <TableComponent
          collection={testCollection}
          selectOnRowClick={false}
          columns={this.getColumns()}
          rowHeight={({ model }) => {
            const labelsLen = model.labels ? model.labels.length : 0;
            const baseRowHeight = 52;
            return labelsLen === 0 ? baseRowHeight : baseRowHeight + 20 * Math.ceil(labelsLen / 2);
          }}
          onSort={this.handleOnSort}
          emptyState={
            <EmptyState
              icon="inbox"
              title={$syn.tests.unfilteredSize === 0 ? 'No tests have been created yet!' : 'No matching tests'}
              action={
                canCreateTests && testCollection.unfilteredSize === 0 ? (
                  <LinkButton to="/v4/synthetics/tests/add" intent="primary" text="Get Started" />
                ) : null
              }
            />
          }
          flexed
        />
        {testCollection.healthLoaded && (
          <Callout p="0 16px" textAlign="right" borderTop="thin" borderRadius="0 0 3px 3px">
            <Text lineHeight="30px" muted small>
              Status last updated:{' '}
              {!testCollection.loading && <>{moment.utc(testCollection.lastUpdated).format('HH:mm')} UTC</>}
            </Text>
          </Callout>
        )}
      </Card>
    );
  }
}

export default TestTable;
