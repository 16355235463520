import React, { Component } from 'react';
import { Flex, FlexColumn, Grid } from 'core/components';
import { ProductSkeleton } from 'core/components/Skeletons';

class LoadingSkeleton extends Component {
  render() {
    return (
      <FlexColumn flex={1} position="relative" overflow="hidden" p={3} mt={3}>
        <Grid
          gridGap="16px"
          gridRowGap="16px"
          gridTemplateColumns="1fr"
          gridTemplateRows="50px 200px 100px"
          minHeight={500}
        >
          <Flex flexDirection="column" gap="16px">
            <ProductSkeleton width="100%" height="100%" />
            <ProductSkeleton width="40%" height="100%" />
            <ProductSkeleton width="75%" height="100%" />
          </Flex>
          <ProductSkeleton width="100%" height="100%" />

          <Flex flexDirection="column" gap="16px">
            <ProductSkeleton width="100%" height="100%" />
            <ProductSkeleton width="100%" height="100%" />
            <ProductSkeleton width="100%" height="100%" />
            <ProductSkeleton width="100%" height="100%" />
          </Flex>
        </Grid>
      </FlexColumn>
    );
  }
}

export default LoadingSkeleton;
