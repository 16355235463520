import React, { Component } from 'react';
import { Select } from 'core/components';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react';

const VisualizationToolsWrapper = styled.div`
  position: absolute;
  top: -1px;
  right: 0;
  background: ${(props) => props.theme.colors.decks.widgetBackground};
  z-index: 5;
  border: 1px solid ${(props) => (props.theme.name === 'light' ? 'rgba(16,22,26,0.15)' : props.theme.colors.cardBorder)};
  border-right: 0;
  padding: 4px 8px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 0 6px rgb(0 0 0 / 25%);

  /* position the tools on the left side of the chart in horizontal mode */
  ${(props) =>
    props.useHorizontalLayout &&
    css`
      right: unset;
      left: -1px;
      top: -1px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 4px;
    `}
`;

@observer
class MetricsExplorerVisualizationTools extends Component {
  getMetricOptions = () => {
    const { metrics, measurementModel } = this.props;
    return metrics.map((metric) => {
      const metricName = metric?.name;
      const label = measurementModel.get(`storage.Metrics.${metricName}`)?.Label || metricName;

      return {
        value: metricName,
        label
      };
    });
  };

  render() {
    const { onChangeMetric, selectedMetric, useHorizontalLayout } = this.props;

    return (
      <VisualizationToolsWrapper useHorizontalLayout={useHorizontalLayout}>
        <Select
          options={this.getMetricOptions()}
          onChange={onChangeMetric}
          values={selectedMetric}
          menuWidth="min-content"
          small
          minimal
        />
      </VisualizationToolsWrapper>
    );
  }
}

export default MetricsExplorerVisualizationTools;
