import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';
import parseServiceString from 'core/util/parseServiceString';

const ServiceLink = ({ $app, service, ...rest }) => {
  const { port, protocol } = parseServiceString(service);

  if ($app.isSubtenant) {
    return service;
  }

  return (
    <Link to={`/v4/core/quick-views/service/${port}-${protocol}`} {...rest}>
      {service}
    </Link>
  );
};

export default inject('$app')(ServiceLink);
