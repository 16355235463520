import React from 'react';
import { observer } from 'mobx-react';
import { Box, Callout, Text } from 'core/components';

const ValidationErrorsOrHelpText = (props) => {
  const {
    field,
    helpText: helpTextProp,
    helpTextStyle,
    calloutStyle,
    style = {},
    small,
    filterErrorsFn = (e) => e
  } = props;

  const showError = field.hasError && (field.showPristineErrors || !field.pristine);
  const helpText = helpTextProp || field.helpText;

  if (showError || helpText) {
    if (calloutStyle) {
      return (
        <Callout intent={showError ? 'danger' : 'primary'} p={small ? 1 : 2}>
          {showError &&
            field.errors.filter(filterErrorsFn).map((error) => (
              <Text as="div" small={small} color="danger" key={error}>
                {error}
              </Text>
            ))}
          {!showError && helpText}
        </Callout>
      );
    }

    return (
      <Box style={style}>
        {showError &&
          field.errors.filter(filterErrorsFn).map((error) => (
            <Text as="div" small={small} color="danger" key={error}>
              {error}
            </Text>
          ))}
        {!showError && helpText && (
          <Text as="div" mt={1} small={small} muted {...helpTextStyle}>
            {helpText}
          </Text>
        )}
      </Box>
    );
  }

  return <div />;
};

export default observer(ValidationErrorsOrHelpText);
