import { observer } from 'mobx-react';
import EmptyState from 'core/components/EmptyState';
import Flex from 'core/components/Flex';
import Box from 'core/components/Box';
import React from 'react';

const EmptyBody = observer((props) => {
  const { collection, notFetchedText } = props;
  let { emptyState } = props;

  if (!collection.hasFetched && notFetchedText) {
    emptyState = <EmptyState title={notFetchedText} icon="help" />;
  } else if (collection.hasFilter && collection._original && collection._original.length > 0) {
    emptyState = <EmptyState title="No matches found" icon="inbox" />;
  }

  return (
    <Flex className="tr empty">
      <Box flex={1} p={3}>
        {emptyState ? React.cloneElement(emptyState) : <EmptyState title="No Results" icon="inbox" />}
      </Box>
    </Flex>
  );
});

export default EmptyBody;
