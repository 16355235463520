export const InboundFilters = {
  filters: []
};

export const OutboundFilters = {
  filters: []
};

export default {
  metric: ['i_device_site_name'],
  aggregateTypes: ['p95th_bits_per_sec'],
  lookback_seconds: 86400,
  all_devices: true,
  show_overlay: false,
  show_total_overlay: true,
  filterDimensionsEnabled: true,
  filterDimensionName: 'Total',
  filterDimensionOther: false,
  filterDimensionSort: true,
  filterDimensions: {
    connector: 'All',
    filterGroups: [
      {
        name: 'Inbound',
        named: true,
        connector: 'All',
        not: false,
        autoAdded: '',
        saved_filters: [],
        filterGroups: [],
        ...InboundFilters
      },
      {
        name: 'Outbound',
        named: true,
        connector: 'All',
        not: false,
        autoAdded: '',
        saved_filters: [],
        filterGroups: [],
        ...OutboundFilters
      }
    ]
  }
};
