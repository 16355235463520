import React from 'react';
import { observer } from 'mobx-react';
import TestPath from 'app/views/synthetics/components/TestPath';
import LoadingPanel from './LoadingPanel';

@observer
export default class PathPanel extends React.Component {
  render() {
    const { test, testResults, traceroutes, agents, onChangeTraceResultTimeMs } = this.props;
    const {
      loadingTraceResults,
      traceResults,
      traceTimelineBounds,
      traceResultTimeMs,
      displayOptions,
      hasTraceResults
    } = testResults;
    const { allowExport } = displayOptions;

    return (
      <LoadingPanel
        test={test}
        testResults={testResults}
        loading={loadingTraceResults && !hasTraceResults}
        empty={!hasTraceResults}
      >
        <TestPath
          {...traceTimelineBounds}
          traceResults={traceResults}
          traceroutes={traceroutes}
          resultTimeMs={traceResultTimeMs} // trace / syngest ts is not in sync
          onHover={onChangeTraceResultTimeMs}
          agents={agents || test.get('config.agents') || []}
          isPreview={test.isPreview}
          allowExport={allowExport}
        />
      </LoadingPanel>
    );
  }
}
