import { EVENT_POLICY_TYPES } from '@kentik/ui-shared/alerting/constants';

export const TARGET_TYPES = {
  ENTITY: 'entity',
  EVENT: 'event',
  CUSTOM: 'custom'
};

export const EVENT_TYPE_OPTIONS = [
  { label: 'Syslog', value: EVENT_POLICY_TYPES.SYSLOG },
  { label: 'SNMP Trap', value: EVENT_POLICY_TYPES.SNMP_TRAP }
];

export const defaultEventTypeDimensions = {
  [EVENT_POLICY_TYPES.SYSLOG]: ['ktappprotocol__event_syslog__STR02', 'ktappprotocol__event_syslog__STR05'],
  [EVENT_POLICY_TYPES.SNMP_TRAP]: ['ktappprotocol__event_snmp_traps__INT00']
};
