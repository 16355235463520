import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Card, Flex, Icon, Text, Tooltip } from 'core/components';
import { Field, Form, Select, Checkbox } from 'core/form/components';
import { safelyParseJSON } from 'core/util';
import { isInSubnet } from 'core/util/ip';
import Timelines from './Timelines';
import Graphs from './Graphs';

const fields = {
  prefix: {
    placeholder: 'Select a prefix',
    options: [],
    defaultValue: null
  },
  specificPrefix: {
    placeholder: 'Select a more specific prefix',
    options: [],
    defaultValue: null
  },
  timeline: {
    options: [
      {
        label: 'Show All',
        value: 'all'
      },
      {
        label: 'Show Reachability / Visibility',
        value: 'showReachability'
      },
      {
        label: 'Show AS Path Changes',
        value: 'showPathChange'
      }
      // {
      //   label: 'Show BGP Events',
      //   value: 'showEvents'
      // }
    ],
    defaultValue: 'showReachability'
  },
  graphs: {
    options: [
      {
        label: 'Show AS Path Visualization',
        value: 'showPath'
      }
      // {
      //   label: 'Show Events Table',
      //   value: 'showEvents'
      // }
    ],
    defaultValue: 'showPath'
  },
  disableTimelines: {
    label: 'Hide Timelines',
    defaultValue: false
  },
  disableGraphs: {
    label: 'Hide Graphs',
    defaultValue: false
  },
  eventsTableFilter: {
    placeholder: 'Filter Events Table',
    options: [
      {
        label: 'Clear',
        value: null
      },
      {
        label: 'Announcement',
        value: 'announcement'
      },
      {
        label: 'Unexpected Origin',
        value: 'unexpectedOrigin'
      },
      {
        label: 'Invalid RPKI',
        value: 'invalidRpki'
      },
      {
        label: 'Unexpected Upstream',
        value: 'unexpectedUpstream'
      },
      {
        label: 'Withdrawal',
        value: 'withdrawal'
      }
    ],
    defaultValue: null
  },
  collapsePath: {
    label: 'Hide ASN Name',
    defaultValue: false
  },
  asn: {
    defaultValue: null
  }
};

@inject('$exports', '$bgp')
@Form({ fields, options: { name: 'Bgp Test Results Graph Controller Options' } })
@observer
class BgpTestResultsGraphController extends Component {
  state = { init: false };

  constructor(props) {
    super(props);

    const { $exports, form, model, routeviewer } = this.props;
    const { prefix } = model.get('config.bgp');
    const prefixes = prefix.split(',').map((p) => p.trim());

    if (routeviewer) {
      form.setValues({ prefix: prefixes[0] });
      this.getSpecificPrefixes();

      this.state.init = true;
    } else {
      $exports.getSettings().then(({ hashedBGPGraphingOpts }) => {
        let savedBGPGraphingOpts;

        if (hashedBGPGraphingOpts) {
          savedBGPGraphingOpts = hashedBGPGraphingOpts;
        } else if (window.localStorage) {
          savedBGPGraphingOpts = safelyParseJSON(localStorage.getItem(`bgpGraphingOpts-${model.id}`)) || {};
        }

        Object.entries(savedBGPGraphingOpts).forEach(([key, value]) => {
          if ((key === 'timeline' || key === 'graphs') && !fields[key].options.find((field) => field.value === value)) {
            savedBGPGraphingOpts[key] = fields[key].defaultValue;
          }
        });

        if (prefixes.length === 1) {
          savedBGPGraphingOpts.prefix = prefix.trim();
        } else if (!prefixes.find((p) => p === savedBGPGraphingOpts.prefix)) {
          // eslint-disable-next-line prefer-destructuring
          savedBGPGraphingOpts.prefix = prefixes[0];
        }

        form.setValues({ ...savedBGPGraphingOpts });
        this.getSpecificPrefixes();
        this.setState({ init: true });
      });
    }

    form.onChange = ({ field, fieldValue }) => {
      if (field?.name === 'prefix') {
        form.setValue('specificPrefix', null);
        this.getSpecificPrefixes(fieldValue);
      }
      this.saveFormChange();
    };
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate, selectedPrefix } = this.props;

    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate) {
      this.getSpecificPrefixes();
    }

    if (prevProps.selectedPrefix !== selectedPrefix) {
      // allows the beta version of test results to update the prefix filter
      this.setPrefixes(selectedPrefix);
    }
  }

  getSpecificPrefixes = () => {
    const { $bgp, model, form, startDate, endDate } = this.props;
    const { include_covered } = model.get('config.bgp');
    const prefix = form.getValue('prefix');

    $bgp.requestData({
      type: 'specificPrefixes',
      req: { prefix, include_covered },
      startDate,
      endDate
    });
  };

  saveFormChange = () => {
    const { $exports, form, model, routeviewer } = this.props;
    const values = form.getValues();

    if (!routeviewer) {
      if (window.localStorage) {
        localStorage.setItem(`bgpGraphingOpts-${model.id}`, JSON.stringify(values));
      }

      $exports.setHash({ hashedBGPGraphingOpts: values });
    }
  };

  shouldUpdate = ({ startDate, endDate }, prevProps) =>
    prevProps.startDate !== startDate || prevProps.endDate !== endDate;

  setASN = (asn) => {
    const { form } = this.props;
    const currAsn = form.getValue('asn');
    const fieldValue = asn === currAsn ? null : asn;

    form.setValue('asn', fieldValue);
    form.onChange({ field: { name: 'asn' }, fieldValue });
  };

  setPrefix = (prefix) => {
    const { form } = this.props;
    const currPrefix = form.getValue('specificPrefix');
    const fieldValue = prefix === currPrefix ? null : prefix;

    form.setValue('specificPrefix', fieldValue);
    form.onChange({ field: { name: 'specificPrefix' }, fieldValue });
  };

  getBaseGraphProps = () => {
    const { model, startDate, endDate, loading } = this.props;
    const { shouldUpdate, setASN, setPrefix } = this;

    return {
      model,
      startDate,
      endDate,
      loading,
      shouldUpdate,
      setASN,
      setPrefix
    };
  };

  renderPrefixControl = () => {
    const { model } = this.props;
    const { prefix } = model.get('config.bgp');
    const options = [
      // { label: 'Clear', value: null },
      ...prefix.split(',').map((v) => ({ label: v.trim(), value: v.trim() }))
    ];

    return (
      <Field name="prefix" options={options} inline mb={0}>
        <Select />
      </Field>
    );
  };

  renderSpecificPrefixControl = () => {
    const { $bgp, form } = this.props;
    const specificPrefix = form.getValue('specificPrefix');
    const data = $bgp.requests.specificPrefixes.data || [];

    if (!!specificPrefix && !data.some(({ prefix }) => prefix === specificPrefix)) {
      data.push({ prefix: specificPrefix });
    }

    const options = [
      { label: 'Clear', value: null },
      ...data.map(({ prefix }) => ({ label: prefix.trim(), value: prefix.trim() }))
    ];

    return (
      <Field name="specificPrefix" options={options} inline mb={0}>
        <Select />
      </Field>
    );
  };

  renderAsnControl = () => {
    const { form } = this.props;
    const asn = form.getValue('asn');

    return (
      <Field name="asn" showLabel={false} mb={0} label={`AS${asn}`}>
        <Checkbox mb={0} />
      </Field>
    );
  };

  renderTimelineControl = () => {
    const { form } = this.props;
    const disabled = form.getValue('disableTimelines');

    return (
      <Field name="timeline" disabled={disabled} inline mb={0}>
        <Select />
      </Field>
    );
  };

  renderCollapsePathControls = () => (
    <Field name="collapsePath" showLabel={false} mb={0}>
      <Checkbox mb={0} />
    </Field>
  );

  renderDisableTimelinesControl = () => {
    const { form } = this.props;
    const { disableGraphs } = form.getValues();

    return (
      <Field name="disableTimelines" showLabel={false} disabled={disableGraphs} mb={0}>
        <Checkbox mb={0} />
      </Field>
    );
  };

  renderDisableGraphsControl = () => {
    const { form } = this.props;
    const { graphs, disableTimelines } = form.getValues();
    const label = graphs === 'showEvents' ? 'Hide Events Table' : 'Hide Paths Graph';

    return (
      <Field name="disableGraphs" showLabel={false} label={label} disabled={disableTimelines} mb={0}>
        <Checkbox mb={0} />
      </Field>
    );
  };

  exceedsMaxDateRange = () => {
    const { startDate, endDate, maxDateRange } = this.props;

    return endDate - startDate > maxDateRange;
  };

  badTestConfig = () => {
    const { model, form } = this.props;
    const { include_covered } = model.get('config').bgp;
    const prefix = form.getValue('prefix');

    return !!prefix && include_covered && parseInt(prefix.split('/')[1], 10) < 15;
  };

  setPrefixes = (ip) => {
    const { model, form } = this.props;
    const prefixes = model
      .get('config.bgp.prefix')
      .split(',')
      .map((p) => p.trim());
    const prefixFound = prefixes.includes(ip);
    const prefix = prefixFound ? ip : prefixes.find((p) => isInSubnet(ip, p));

    if (prefix) {
      const specificPrefix = !prefixFound ? ip : null;

      form.setValue('prefix', prefix);
      form.onChange({ field: { name: 'prefix' }, fieldValue: prefix });

      if (specificPrefix) {
        form.setValue('specificPrefix', specificPrefix);
        form.onChange({ field: { name: 'specificPrefix' }, fieldValue: specificPrefix });
      }
    }
  };

  renderFilters = () => {
    const { $bgp, form } = this.props;
    const formValues = form.getValues();
    const hasMoreSpecificPrefixes =
      !!$bgp.specificPrefixesLoaded &&
      !!$bgp.specificPrefixesParsed &&
      !!$bgp.requests.specificPrefixes.data &&
      $bgp.requests.specificPrefixes.data.length > 1;

    return (
      <Card position="sticky" top="-16px" zIndex={1} display="flex" justifyContent="space-between" p={1} mb={1}>
        <Text fontWeight="bold">Filters</Text>
        <Flex alignItems="center" gap={1}>
          {/* show reachability/visibility (timeline) */}
          {!formValues.disableTimelines && this.renderTimelineControl()}

          {/* prefix, specific prefix */}
          {this.renderPrefixControl()}
          {(hasMoreSpecificPrefixes || formValues.specificPrefix) && this.renderSpecificPrefixControl()}

          {/* selected asn */}
          {formValues.asn && this.renderAsnControl()}

          {this.badTestConfig() && (
            <Tooltip
              content={
                <div style={{ width: 150 }}>
                  Will only include more specifics for prefixes narrower (larger) than /14
                </div>
              }
              minimal
            >
              <Icon icon="info-sign" color="primary" />
            </Tooltip>
          )}

          {/* hide timelines */}
          {this.renderDisableTimelinesControl()}

          {/* hide asn name */}
          {formValues.graphs === 'showPath' && this.renderCollapsePathControls()}

          {/* hide paths graph */}
          {this.renderDisableGraphsControl()}
        </Flex>
      </Card>
    );
  };

  render() {
    const { xAxisMin, xAxisMax, form, setSubsetTimeRange, subsetStartDate, subsetEndDate, onTimelineHover } =
      this.props;
    const { init } = this.state;
    const formValues = form.getValues();
    const overrideStartEndDate =
      subsetStartDate && subsetEndDate ? { startDate: subsetStartDate, endDate: subsetEndDate } : {};

    if (!init) {
      return null;
    }

    if (this.exceedsMaxDateRange()) {
      return (
        <Flex flex="1 0 auto" flexDirection="column" justifyContent="center" p={2}>
          <Text as="div" textAlign="center">
            Please select a date range of one day or less.
          </Text>
        </Flex>
      );
    }

    return (
      <Flex flex="1 0 auto" flexDirection="column">
        {this.renderFilters()}
        {!formValues.disableTimelines && (
          <Timelines
            {...this.getBaseGraphProps()}
            {...formValues}
            xAxisMin={xAxisMin}
            xAxisMax={xAxisMax}
            onClickTimeline={setSubsetTimeRange}
            onHover={onTimelineHover}
          />
        )}
        {formValues.graphs === 'showPath' && (
          <Card p={1}>
            <Text muted small>
              Click any point on the charts to see the AS Paths at that time
            </Text>
          </Card>
        )}
        {!formValues.disableGraphs && (
          <Graphs {...this.getBaseGraphProps()} {...formValues} {...overrideStartEndDate} />
        )}
      </Flex>
    );
  }
}

export default BgpTestResultsGraphController;
