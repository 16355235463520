import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { InputGroup, Switch } from 'core/form';
import storeLoader from 'app/stores/storeLoader';
import { POLICY_APPLICATION_LABELS } from 'shared/alerting/constants';
import { STATUS_TO_STATES } from 'app/stores/mitigations/mitigationsConstants';
import AdminFilterSidebar from 'app/components/admin/AdminFilterSidebar';
import { timezone } from 'core/util/dateUtils';
import moment from 'moment';

@inject('$mkp', '$alerting', '$app', '$exports')
@storeLoader('$mitigations.platformsCollection', '$mitigations.methodsCollection', '$alerting.standardPolicyCollection')
@withRouter
@observer
class MitigationFilterSidebar extends Component {
  static defaultProps = {
    lookbackOnly: false,
    readOnly: false,
    filterValues: undefined,
    tenantMethodOptions: [],
    tenantPlatformOptions: [],
    updateHashOnFilterChange: true
  };

  state = { tenantMethodOptions: [], tenantPlatformOptions: [], showTenants: false };

  componentDidMount() {
    const { $app, $exports, $mitigations } = this.props;

    if ($app.isSubtenant) {
      // attempt to load mitigations for the last 90 days then extract the methods and platforms that were used
      $mitigations.allUsedPlatformsAndMethods.then((options) => {
        this.setState({ tenantMethodOptions: options.methodOptions, tenantPlatformOptions: options.platformOptions });
      });
    }

    $exports.getSettings().then(({ hashedFilters }) => {
      if (hashedFilters) {
        const { startDate, endDate, ...restHashedFilters } = hashedFilters;

        // Make sure we convert hashed dates into moment objects
        if (typeof startDate === 'string') {
          restHashedFilters.startDate = moment(startDate);
        }
        if (typeof endDate === 'string') {
          restHashedFilters.endDate = moment(endDate);
        }

        // Prefer lookback if we have it
        if (restHashedFilters.lookback) {
          restHashedFilters.startDate = moment().subtract(restHashedFilters.lookback, 'seconds');
          restHashedFilters.endDate = moment();
        }

        this.handleFilterChange(restHashedFilters);
      }
    });
  }

  // Initial state
  get defaultValues() {
    return {
      policies: [],
      mitigations: '',
      methodID: [],
      platformID: [],
      statuses: ['Waiting', 'Failed', 'Active'],
      sources: [],
      tenantID: [],
      lookback: 24 * 3600,
      alarms: '',
      ipCidrPattern: '',
      keyPartsSearch: '',
      exactSearch: false,
      showTenants: false
    };
  }

  // Cleared state
  get resetValues() {
    return {
      ...this.defaultValues,
      statuses: []
    };
  }

  get filterFields() {
    const { $app, $mitigations, $mkp, $alerting, lookbackOnly, filterValues } = this.props;
    const { tenantMethodOptions, tenantPlatformOptions, showTenants } = this.state;
    let methodOptions;
    let platformOptions;

    // set available method options - tenants should only see what they been assigned
    if (!$app.isSubtenant) {
      // not a tenant - show all defined methods and platforms
      methodOptions = $mitigations.methodsCollection.mitigationOptions;
      platformOptions = $mitigations.platformsCollection.generateSelectOptions({ labelKey: 'name', valueKey: 'id' });
    } else if (tenantMethodOptions?.length > 0) {
      // tenant - show methods and platforms used in the last 90 days (if loaded)
      methodOptions = tenantMethodOptions;
      platformOptions = tenantPlatformOptions;
    } else {
      // tenant - default to showing the methods and platforms used in the currently loaded collection
      methodOptions = $mitigations.activeMitigationCollection.platformMethodOptions.methodOptions;
      platformOptions = $mitigations.activeMitigationCollection.platformMethodOptions.platformOptions;
    }

    const filterFields = {
      lookback: {
        name: 'lookback',
        label: 'Time Range',
        defaultValue: this.defaultValues.lookback,
        hidden: !lookbackOnly,
        options: [
          { label: 'Last 24 hours', value: 24 * 3600 },
          { label: 'Last 7 days', value: 24 * 7 * 3600 },
          { label: 'Last 14 days', value: 24 * 14 * 3600 },
          { label: 'Last 30 days', value: 24 * 30 * 3600 },
          { label: 'Last 90 days', value: 24 * 90 * 3600 }
        ]
      },

      daterange: {
        name: 'daterange',
        label: `Time Range (${timezone.value})`,
        isDateRange: true,
        closeDateRangeOnSelection: true,
        startField: 'startDate',
        endField: 'endDate',
        asStrings: true,
        hidden: lookbackOnly,
        startDefaultValue: null,
        endDefaultValue: null,
        adjustEndTime: true,
        showShortcutLabelAsInterval: true,
        overrideShortcuts: [
          { label: 'Last hour', lookbackSeconds: 3600, includeTime: true },
          { label: 'Last 8 hours', lookbackSeconds: 28800, includeTime: true },
          { label: 'Last 24 hours', lookbackSeconds: 86400, includeTime: true },
          { label: 'Last 7 days', lookbackSeconds: 604800, includeTime: true },
          { label: 'Last 14 days', lookbackSeconds: 1209600, includeTime: true },
          { label: 'Last 30 days', lookbackSeconds: 2592000, includeTime: true },
          { label: 'Last 90 days', lookbackSeconds: 7776000, includeTime: true }
        ]
      },

      statuses: {
        name: 'statuses',
        label: 'Status',
        allowMultiSelect: true,
        showCheckboxes: true,
        defaultValue: this.defaultValues.statuses,
        options: [
          { label: 'Active', value: 'Active', color: $mitigations.getMitigationStatusColor('Active'), round: true },
          { label: 'Failed', value: 'Failed', color: $mitigations.getMitigationStatusColor('Failed'), round: true },
          { label: 'Waiting', value: 'Waiting', color: $mitigations.getMitigationStatusColor('Waiting'), round: true },
          {
            label: 'Inactive',
            value: 'Inactive',
            color: $mitigations.getMitigationStatusColor('Inactive'),
            round: true
          }
        ]
      },

      sources: {
        name: 'sources',
        label: 'Sources',
        defaultValue: this.defaultValues.sources,
        allowMultiSelect: true,
        showCheckboxes: true,
        options: [
          { label: 'Auto', value: 'auto' },
          { label: 'Manual', value: 'manual' }
        ]
      },

      mitigations: {
        name: 'mitigations',
        label: 'Mitigation ID',
        defaultValue: this.defaultValues.mitigations,
        customField: <InputGroup />
      },

      alarms: {
        name: 'alarms',
        label: 'Alert ID',
        customField: <InputGroup />,
        defaultValue: this.defaultValues.alarms
      },

      methodID: {
        name: 'methodID',
        label: 'Method',
        defaultValue: this.defaultValues.methodID,
        allowMultiSelect: true,
        placeholder: 'No methods selected',
        options: methodOptions
      },

      platformID: {
        name: 'platformID',
        label: 'Platform',
        defaultValue: this.defaultValues.platformID,
        allowMultiSelect: true,
        placeholder: 'No platforms selected',
        options: platformOptions
      },

      showTenants: {
        name: 'showTenants',
        defaultValue: this.defaultValues.showTenants,
        customField: <Switch switchLabel="Show Tenant Mitigations" />,
        hidden: $app.isSubtenant || $mkp.tenants.tenantAlertingOptions.length === 0
      },

      tenantID: {
        name: 'tenantID',
        label: 'Tenant',
        defaultValue: this.defaultValues.tenantID,
        allowMultiSelect: true,
        placeholder: 'No tenants selected',
        options: $mkp.tenants.tenantAlertingOptions,
        hidden: !showTenants
      },

      policies: {
        name: 'policies',
        options: $alerting.standardPolicyCollection.policyOptions.filter(
          (policy) => policy.applicationLabel !== POLICY_APPLICATION_LABELS.kmetrics
        ),
        label: 'Policy',
        allowMultiSelect: true,
        defaultValue: this.defaultValues.policies,
        placeholder: 'No policies selected'
      },

      ipCidrPattern: {
        name: 'ipCidrPattern',
        label: 'Target Search',
        customField: <InputGroup />,
        defaultValue: this.defaultValues.ipCidrPattern
      },

      keyPartsSearch: {
        name: 'keyPartsSearch',
        customField: <InputGroup />,
        label: 'Dimension Value',
        defaultValue: this.defaultValues.keyPartsSearch
      },

      exactSearch: {
        name: 'exactSearch',
        defaultValue: this.defaultValues.exactSearch,
        customField: <Switch switchLabel="Exact Dimension Value Match" />
      }
    };

    if (filterValues) {
      Object.keys(filterValues).forEach((key) => {
        let defaultValue = filterValues[key];
        const filterField = filterFields[key];

        // set the default form values for all of the incoming query values and prop values
        if (filterField) {
          if (filterField.allowMultiSelect && !Array.isArray(defaultValue)) {
            defaultValue = [defaultValue];
          }

          if (key === 'lookback') {
            defaultValue = parseInt(defaultValue);
            // if we're only showing lookback (and not start/end dates) make sure we have a valid value for the lookback (at least 24hrs)
            if (lookbackOnly && defaultValue < 86400) {
              defaultValue = 86400;
            }
          }

          filterField.defaultValue = defaultValue;
        } else {
          // could not find a matching filter form field for the incoming value - check start and end dates
          if (key === 'startDate' && !lookbackOnly) {
            filterFields.daterange.startDefaultValue = moment(defaultValue);
            filterFields.lookback.defaultValue = 0;
          }
          if (key === 'endDate' && !lookbackOnly) {
            filterFields.daterange.endDefaultValue = moment(defaultValue);
            filterFields.lookback.defaultValue = 0;
          }
        }
      });
    }

    return Object.values(filterFields);
  }

  get adjustedFilterValues() {
    const { filterValues, lookbackOnly } = this.props;

    // Prefer lookback for subscriptions and make sure its at least 24hrs
    if (lookbackOnly && filterValues?.lookback < 86400) {
      filterValues.lookback = 86400;
    }

    return filterValues;
  }

  handleFilterChange = (filters) => {
    const { collection, onChange } = this.props;

    this.setState({ showTenants: !!filters.showTenants });

    const lookback = filters?.lookback || this.defaultValues.lookback;
    const states = Array.isArray(filters.statuses)
      ? filters.statuses.flatMap((status) => STATUS_TO_STATES[status])
      : null;

    let mitigations = filters.mitigations || '';
    mitigations = mitigations.replace(/[^0-9]/g, '');
    const alarms = filters.alarms || '';

    // Clear tenant selections if disabling show tenants
    if (!filters.showTenants) {
      filters.tenantID = [];
    }

    if (onChange) {
      onChange(filters);
    }

    if (collection) {
      collection.setServerFilter({
        ...filters,
        states,
        lookback,
        startDate: filters.lookback ? null : filters.startDate,
        endDate: filters.lookback ? null : filters.endDate,
        mitigations,
        alarms
      });
    }
  };

  render() {
    const { filterValues, lookbackOnly, ...otherProps } = this.props;

    return (
      <AdminFilterSidebar
        {...otherProps}
        filterValues={this.adjustedFilterValues}
        filterFields={this.filterFields}
        resetValues={this.resetValues}
        defaultValues={this.defaultValues}
        onFilterChange={this.handleFilterChange}
      />
    );
  }
}

export default MitigationFilterSidebar;
