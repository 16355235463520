import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('CountryAggregate'))
class CountryAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="Countries"
        resultsKey="Country"
        insightsQuery="fetchCountryInsights"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'Country',
          aggregateFilters: [
            { metric: ['Geography_src', 'Geography_dst'] },
            { metric: ['Geography_src'] },
            { metric: ['Geography_dst'] },
            { metric: ['Geography_src', 'Geography_dst'] },
            { metric: ['Geography_src', 'Geography_dst'] }
          ]
        }}
        chartProps={{
          metrics: {
            Internal: ['Geography_dst', 'Geography_src'],
            Inbound: 'Geography_src',
            Outbound: 'Geography_dst'
          },
          topxMetricField: 'src|dst_geo'
        }}
      />
    );
  }
}

export default CountryAggregate;
