import React from 'react';
import Typewriter from 'typewriter-effect';

const defaultOptions = {
  autoStart: true,
  loop: false,
  delay: 10
};

const TypewriterComponent = ({ text, options = defaultOptions, removeCursor = true }) => (
  <Typewriter
    options={options}
    onInit={(typewriter) => {
      typewriter
        .typeString(text)
        .start()
        .callFunction(() => {
          if (removeCursor) {
            const cursor = document.querySelector('.Typewriter__cursor');
            if (cursor) {
              cursor.remove();
            }
          }
        });
    }}
  />
);

export default TypewriterComponent;
