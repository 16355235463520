import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Button from 'core/components/Button';

@inject('$metrics', '$app')
@withRouter
@observer
export default class MetricsExplorerButton extends Component {
  static defaultProps = {
    small: true,
    minimal: true,
    text: 'View in Metrics Explorer',
    openInNewWindow: false
  };

  state = {
    hash: undefined,
    loading: true
  };

  componentDidMount() {
    const { $metrics, query = '' } = this.props;

    $metrics.queryToHash(query).then((hash) => this.setState({ hash, loading: false }));
  }

  get link() {
    const { hash } = this.state;
    return `/v4/nms/explorer/${hash}`;
  }

  get disabled() {
    const { loading } = this.state;
    return loading;
  }

  handleOnClick = (e) => {
    const { history, openInNewWindow } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (openInNewWindow) {
      window.open(this.link, '_blank');
      return;
    }
    history.push(this.link);
  };

  render() {
    const { $app, small, minimal, text, history, location, match, query, openInNewWindow, ...buttonProps } = this.props;

    if ($app.isExport || $app.isSubtenant) {
      return null;
    }

    return (
      <Button
        small={small}
        minimal={minimal}
        icon="series-search"
        text={text}
        onClick={this.handleOnClick}
        style={{ width: 'max-content' }}
        disabled={this.disabled}
        {...buttonProps}
      />
    );
  }
}
