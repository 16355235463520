import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Button, Flex } from 'core/components';
import { formConsumer, Field, InputGroup } from 'core/form';

@formConsumer
@observer
class InviteUsers extends Component {
  componentDidMount() {
    const { form } = this.props;
    const inviteeFields = form.getField('invitees');

    if (inviteeFields.fieldStates.length === 0) {
      inviteeFields.add();
    }
  }

  handleAddInvitee = () => {
    const { form } = this.props;
    form.getField('invitees').add();
  };

  handleRemoveInvitee = (index) => {
    const { form } = this.props;
    form.getField('invitees').remove(index);
  };

  render() {
    const { form } = this.props;
    const inviteeFields = form.getField('invitees');

    return (
      <>
        {inviteeFields.map((inviteeField, index) => (
          <Flex key={inviteeField.name._id}>
            <Field field={inviteeField.name} flex={1} mr={2}>
              <InputGroup />
            </Field>

            <Field field={inviteeField.email} flex={1}>
              <InputGroup />
            </Field>

            <Button icon="cross" onClick={() => this.handleRemoveInvitee(index)} intent="danger" ml={1} small minimal />
          </Flex>
        ))}

        <Button text="Add User" icon="plus" intent="primary" small minimal onClick={this.handleAddInvitee} />
      </>
    );
  }
}

export default InviteUsers;
