import styled from 'styled-components';
import { space, width } from 'styled-system';
import { NavbarHeading as BlueprintNavbarHeading } from '@blueprintjs/core';

const NavbarHeading = styled(BlueprintNavbarHeading)`
  ${space};
  ${width};
`;

export default NavbarHeading;
