export default function injectLegacySavedFilters(saved_filters, filters) {
  const resultantFilters = {
    connector: 'All',
    filterGroups: [
      {
        autoAdded: '',
        connector: 'All',
        filterGroups: [],
        filters: [],
        name: '',
        named: false,
        not: false,
        saved_filters: saved_filters.map(({ filter_id, is_not = false }) => ({ filter_id, is_not }))
      }
    ]
  };

  if (filters.filterGroups.length > 0) {
    if (filters.connector === 'All') {
      // If it's 'all' then we can just combine groups
      resultantFilters.filterGroups.push(...filters.filterGroups);
    } else {
      // Otherwise we need to add as a sub-group
      resultantFilters.filterGroups.push(filters);
    }
  }

  return resultantFilters;
}
