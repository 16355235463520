import React from 'react';
import { observer } from 'mobx-react';
import { Flex, Text } from 'core/components';

@observer
export default class IncidentLogTableFooter extends React.Component {
  get label() {
    const {
      collection: { size, unfilteredSize }
    } = this.props;
    const totalAlarmsLabel = `${unfilteredSize} alarm${unfilteredSize === 1 ? '' : 's'}`;

    if (size !== unfilteredSize) {
      return `${size} of ${totalAlarmsLabel}`; // 3 of 100 alarms
    }

    return totalAlarmsLabel; // 100 alarms
  }

  render() {
    return (
      <Flex justifyContent="flex-end" p={1} borderTop="thin">
        <Text muted small>
          {this.label}
        </Text>
      </Flex>
    );
  }
}
