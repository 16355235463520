import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, FlexColumn, Text } from 'core/components';
import AlarmSwatch from 'app/views/alerting/components/AlarmSwatch';
import { formatDateTime } from 'core/util/dateUtils';

@inject('$alerting', '$auth')
@observer
class AlertSeverityHistory extends Component {
  render() {
    const { $alerting, severityHistory = [] } = this.props;

    if (!Array.isArray(severityHistory) || severityHistory.length < 2) {
      return null;
    }

    return (
      <FlexColumn gap="4px">
        {severityHistory.toReversed().map(({ severity, start_time }, idx) => (
          <Flex key={start_time} justifyContent="space-between" gap={1} pt="4px" borderTop="thin" alignItems="center">
            <Flex gap={1} alignItems="center">
              <AlarmSwatch bg={$alerting.getSeverityColor(severity)} dot mr={0} />
              <Text muted={idx !== 0} fontWeight={idx === 0 ? 'bold' : 'none'}>
                {$alerting.getSeverityLabel(severity)}
              </Text>
            </Flex>
            <Text as="div" small>
              {formatDateTime(start_time)}
            </Text>
          </Flex>
        ))}
      </FlexColumn>
    );
  }
}

export default AlertSeverityHistory;
