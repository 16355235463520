import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import storeLoader from 'app/stores/storeLoader';
import { withRouter } from 'react-router-dom';

import { Box, Flex, MenuItem, Menu, MenuDivider, Text } from 'core/components';

import { FiFileText, FiUsers } from 'react-icons/fi';
import { IoMdFingerPrint } from 'react-icons/io';
import { MdHistory } from 'react-icons/md';
import { HiUserGroup } from 'react-icons/hi';
import { RiFolderKeyholeLine } from 'react-icons/ri';

@inject('$app', '$auth')
@storeLoader('$sharedLinks', '$subscriptions')
@withRouter
@observer
class CompanyMenu extends Component {
  // eslint-disable-next-line react/no-unused-class-component-methods
  isActiveTheme(theme) {
    const { $app, $auth } = this.props;
    const currentThemeColor = $app.darkThemeEnabled ? 'dark' : 'light';
    const themeSetting = $auth.getActiveUserProperty('themeSetting');

    return themeSetting ? themeSetting === theme : theme === currentThemeColor;
  }

  render() {
    const { $auth, history, $sharedLinks, $subscriptions } = this.props;
    const showSsoLink = $auth.isSuperAdministrator && $auth.hasPermission('sso.featureEnabled');
    const vaultEnabled = $auth.hasPermission('credentialVault.enabled', { overrideForSudo: false });

    return (
      <Menu>
        <Text as={Box} fontSize="large" fontWeight="bold" ml={1} mt="4px">
          Organization Settings
        </Text>
        <Flex justifyContent="space-between" mb={1}>
          <Text as={Box} mb="1px" ml={1} muted small>
            {$auth.getActiveUserProperty('company.company_name')}
          </Text>
          <Text as={Box} muted small mr={1}>
            {$auth.getActiveUserProperty('company_id')}
          </Text>
        </Flex>

        {!$auth.isDemoUser && (
          <MenuItem text="Licenses" icon="briefcase" onClick={() => history.push('/v4/licenses')} />
        )}
        <MenuItem text="Users" icon={FiUsers} onClick={() => history.push('/v4/settings/users')} />
        <MenuItem text="RBAC Management" icon={HiUserGroup} onClick={() => history.push('/v4/settings/rbac/roles')} />
        <MenuDivider />
        {showSsoLink && !$auth.isDemoUser && (
          <MenuItem
            text="Authentication & SSO"
            icon={IoMdFingerPrint}
            onClick={() => history.push('/v4/settings/authentication')}
          />
        )}

        {/* Role Based Access Control Goes here */}

        {!$auth.isDemoUser &&
          $auth.isAdministrator &&
          $auth.hasPermission('auditLog.enabled', { overrideForSpoof: true }) && (
            <MenuItem text="Audit Log" icon={MdHistory} onClick={() => history.push('/v4/settings/audit')} />
          )}
        {!$auth.isDemoUser && (
          <MenuItem text="Access Control" icon="lock" onClick={() => history.push('/v4/settings/access')} />
        )}
        {!$auth.isDemoUser && vaultEnabled && (
          <MenuItem
            text="Credentials Vault"
            icon={RiFolderKeyholeLine}
            onClick={() => history.push('/v4/settings/credentials')}
          />
        )}

        {$auth.isSuperAdministrator && (
          <MenuItem text="Kentik AI" icon="clean" onClick={() => history.push('/v4/settings/kentik-ai')} />
        )}

        <MenuDivider />

        <MenuItem
          text="Public Shares"
          icon="share"
          onClick={() => history.push('/v4/settings/shared-links')}
          label={$sharedLinks.collection.models.length}
        />
        {!$auth.isDemoUser && (
          <MenuItem
            text="Report Subscriptions"
            icon="envelope"
            label={$subscriptions.collection.models.length}
            onClick={() => history.push('/v4/settings/subscriptions')}
          />
        )}
        <MenuItem
          text="Data Explorer Reverse DNS Settings"
          icon={FiFileText}
          onClick={() => history.push('/v4/settings/dns')}
        />
      </Menu>
    );
  }
}

export default withRouter(CompanyMenu);
