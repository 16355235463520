import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { renderToStaticMarkup } from 'react-dom/server';
import styled, { withTheme } from 'styled-components';
import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';
import { ALERT_SEVERITY_RANKS } from 'shared/alerting/constants';
import { lineClassNames } from 'app/components/insights/InsightChart';
import { getNmsAlarmContext } from '@kentik/ui-shared/nms/alerts';
import NmsNativeAlertMetricListRenderer from 'app/views/alerting/components/nms/NmsNativeAlertMetricListRenderer';

import { Highcharts } from 'core/components';

const StyledChart = styled(Highcharts)`
  .highcharts-axis-labels {
    font-size: 11px;
  }
`;

const SECOND_IN_MILLIS = 1000;
const MINUTE_IN_MILLIS = SECOND_IN_MILLIS * 60;

@inject('$alerting', '$metrics')
@withTheme
@observer
export default class NmsNativeAlertDebugChart extends Component {
  static defaultProps = {
    data: []
  };

  get chartColors() {
    const { theme } = this.props;
    const rankKeys = Object.keys(ALERT_SEVERITY_RANKS);
    const colors = Array(rankKeys.length).fill(theme.colors.severity.clear);

    Object.keys(ALERT_SEVERITY_RANKS).forEach((key) => {
      const keyLowerCase = key.toLowerCase();
      const keyRank = ALERT_SEVERITY_RANKS[key];

      colors[keyRank] = theme.colors.severity[keyLowerCase];
    });

    return colors;
  }

  handleChartCallback = (chart) => {
    this.chart = chart;
    this.resizeObserver.observe(chart.container);
  };

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  resizeObserver = new ResizeObserver(() => {
    this.chart.reflow();
  });

  get seriesData() {
    const { $alerting, data = [] } = this.props;

    return data.map(({ timestamp, severity, context }) => {
      const epochTime = Date.parse(timestamp);
      const severityLabel = $alerting.getSeverityLabel(severity);

      return {
        x: epochTime,
        y: ALERT_SEVERITY_RANKS[severityLabel],
        nmsAlarmContext: context ? getNmsAlarmContext(context?.event_view_model) : null,
        colorIndex: ALERT_SEVERITY_RANKS[severityLabel]
      };
    });
  }

  get lastTriggerMillis() {
    const { data } = this.props;
    let timestampMillis = 0;

    data.forEach(({ timestamp, severity }) => {
      const epochTime = Date.parse(timestamp);

      // Capture the last trigger time
      if (severity !== 'SEVERITY_CLEAR') {
        timestampMillis = epochTime > timestampMillis ? epochTime : timestampMillis;
      }
    });

    return timestampMillis;
  }

  get alertStartTimeMillis() {
    const { alertModel } = this.props;
    return new Date(alertModel.startTime).getTime();
  }

  get alertEndTimeMillis() {
    const { alertModel } = this.props;
    return alertModel.endTime && new Date(alertModel.endTime).getTime();
  }

  // Used to determine the chart render bounds (otherwise the chart x-axis shrinks to available data)
  get chartTimeMillis() {
    const { lookbackSeconds } = this.props;
    const lookbackMillis = SECOND_IN_MILLIS * lookbackSeconds;
    const start = this.alertStartTimeMillis - lookbackMillis;
    const end = this.alertEndTimeMillis ? this.alertEndTimeMillis + lookbackMillis : Date.now();

    return { start, end };
  }

  get plotLinesX() {
    const plotLines = [];

    plotLines.push({
      id: 'start-line',
      className: lineClassNames.alarm,
      value: this.alertStartTimeMillis,
      zIndex: 5,
      label: {
        text: 'Start',
        rotation: 0,
        align: 'right',
        y: 15,
        x: -5,
        className: `${lineClassNames.alarm} outlinedText`
      }
    });

    if (this.alertEndTimeMillis) {
      plotLines.push({
        id: 'end-line',
        className: lineClassNames.cleared,
        value: this.alertEndTimeMillis,
        zIndex: 5,
        label: {
          text: 'Clear',
          align: 'left',
          rotation: 0,
          y: 15,
          className: `${lineClassNames.cleared} outlinedText`
        }
      });
    }

    return plotLines;
  }

  get plotBandsX() {
    const { alertModel } = this.props;
    const { activation_delay, clearance_delay } = alertModel?.policyObject?.settings || {};

    const activationDelayMillis = MINUTE_IN_MILLIS * activation_delay;
    const clearanceDelayMillis = MINUTE_IN_MILLIS * clearance_delay;
    const plotBands = [];

    // Show clearance and activation delay as colored bands on the chart

    if (activationDelayMillis) {
      // Draw the activation delay ending on the alert activation time, and backwards the amount of delay time.
      plotBands.push({
        className: 'alarm-event',
        from: this.alertStartTimeMillis - activationDelayMillis,
        to: this.alertStartTimeMillis,
        zIndex: 5
      });
    }

    if (clearanceDelayMillis) {
      /**
       *
       * There are 2 clearance delay scenarios here:
       *
       * (1) The alert has already cleared
       * If the alert has cleared, the clearance delay necessarily has expired (otherwise it wouldn't clear).
       * So we draw a band that _ends_ on the clear time, and extends backward the amount of delay time.
       * It should never extend back past the start time, but we check to keep the bands from overlapping just in case.
       *
       * (2) The alert is still active
       * If the alert is not cleared, and since each trigger within the delay window restarts the delay timer,
       * we draw the clearance delay _forward_, starting at the last known trigger, and ending on the added delay time.
       *
       */

      const clearDelayFrom = this.alertEndTimeMillis
        ? Math.max(this.alertStartTimeMillis, this.alertEndTimeMillis - clearanceDelayMillis)
        : this.lastTriggerMillis;
      const clearDelayTo = this.alertEndTimeMillis || this.lastTriggerMillis + clearanceDelayMillis;

      plotBands.push({
        className: 'cleared-event',
        from: clearDelayFrom,
        to: clearDelayTo,
        zIndex: 5
      });
    }

    return plotBands;
  }

  get chartOptions() {
    const { props, chartColors, chartTimeMillis } = this;
    const { theme, $metrics } = props;

    return {
      chart: {
        type: 'scatter',
        legend: { enabled: false }
      },

      yAxis: {
        title: { text: 'Severity' },
        min: 0,
        max: 5,
        endOnTick: false,
        tickPositions: [0, 1, 2, 3, 4, 5], // These positions match to severity ranks
        labels: {
          type: 'category',
          y: 3,
          formatter() {
            const { value } = this;
            const severityLabel = Object.keys(ALERT_SEVERITY_RANKS).find(
              (label) => ALERT_SEVERITY_RANKS[label] === value
            );
            const severityColor = theme.colors.severity[severityLabel.toLowerCase()];

            return `<span style="color:${severityColor};">\u25CF</span> ${severityLabel}`;
          }
        }
      },
      xAxis: {
        type: 'datetime',
        min: chartTimeMillis.start,
        max: chartTimeMillis.end,
        plotLines: this.plotLinesX,
        plotBands: this.plotBandsX
      },
      tooltip: {
        crosshairs: true,
        formatter() {
          const { x, y, colorIndex, point = {} } = this;
          const formattedDate = moment.utc(x).format(DEFAULT_DATETIME_FORMAT);
          const severityLabel = Object.keys(ALERT_SEVERITY_RANKS).find((label) => ALERT_SEVERITY_RANKS[label] === y);
          const color = chartColors[colorIndex];

          let metricsSummary = '';

          if (point.nmsAlarmContext) {
            // Returns a string of comma-separated transformed metric key-value pairs
            metricsSummary = renderToStaticMarkup(
              <NmsNativeAlertMetricListRenderer
                alertModel={{ nmsAlarmContext: point?.nmsAlarmContext, isNmsPolicy: true }}
                $metrics={$metrics}
                allowRichContent={false}
              />
            );
          } else if (ALERT_SEVERITY_RANKS[severityLabel] === 0) {
            // If there is a clear event with no context, that means it did not
            // originate from the NMS engine, and was a manual clearing event.
            metricsSummary = 'Cleared Manually';
          }

          return `
            <span style="color:${color};">\u25CF</span>&nbsp;<b>${severityLabel}</b> (${formattedDate})
            <br/><span><br>${metricsSummary.split(', ').join('<br/>')}</div>`;
        }
      },
      series: [{ name: 'Triggers', showInLegend: false, data: this.seriesData }]
    };
  }

  render() {
    return (
      <StyledChart
        callback={this.handleChartCallback}
        options={this.chartOptions}
        colors={this.chartColors}
        allowChartUpdate
      />
    );
  }
}
