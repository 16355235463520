import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Button, Flex, Heading, Icon } from 'core/components';
import UnappliedChanges from 'app/views/core/explorer/sidebar/UnappliedChanges';

import FavoriteButton from 'app/views/core/FavoriteButton';
import LabelList from 'app/components/labels/LabelList';
import DashboardAddPanel from './DashboardAddPanel';
import DashboardNavigationBar from './DashboardNavigationBar';
import DescriptionButton from '../DescriptionButton';

@inject('$dashboard')
@observer
class DashboardHeader extends Component {
  render() {
    const { $dashboard, navigationHistory, descriptionButtonOnClick, hasDashDesc } = this.props;
    const { dashboard, formState, isFetching } = $dashboard;

    const dataviews = (dashboard.get('items') || []).map((item) => item.dataview);
    const showAddItem = dashboard.canEdit && !isFetching && (!dashboard.hasItems || $dashboard.isEditing);

    const title = (
      <Box pt={2}>
        {navigationHistory && <DashboardNavigationBar dashboard={dashboard} navigationHistory={navigationHistory} />}
        {!navigationHistory && (
          <>
            <Flex justifyContent="space-between">
              <Flex alignItems="center">
                <FavoriteButton model={dashboard} />

                <Heading level={1} m={0} ml="4px">
                  {dashboard.get('dash_title')}
                </Heading>
                {dashboard.isTrending ? (
                  <Flex alignItems="center" justifyContent="center" ml={1}>
                    <Icon icon="flame" color="warning" iconSize={20} />
                  </Flex>
                ) : null}
                <DescriptionButton
                  ml={1}
                  hasDescription={hasDashDesc}
                  onClick={descriptionButtonOnClick}
                  viewType="dashboard"
                />
              </Flex>

              {showAddItem && (
                <Flex gap={1}>
                  <Button onClick={() => $dashboard.toggleEditing({ updateLayout: false })} minimal text="Cancel" />
                  <Button onClick={$dashboard.toggleEditing} text="Save" intent="primary" px={2} />
                </Flex>
              )}
            </Flex>
            <LabelList mt={1} labels={dashboard.labels} shareLevel={dashboard.shareLevel} fontWeight="normal" />
          </>
        )}
      </Box>
    );

    return (
      <>
        {dashboard && !dashboard.isIncompleteParametric && (
          <UnappliedChanges
            form={formState}
            buttonText="Apply"
            dataviews={dataviews}
            mx={2}
            mt={1}
            style={{ width: 'auto' }}
            flexAuto
          />
        )}

        {title}

        {showAddItem && <DashboardAddPanel {...this.props} />}
      </>
    );
  }
}

export default DashboardHeader;
