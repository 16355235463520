import React, { Component } from 'react';
import Model from 'core/model/Model';
import { observer } from 'mobx-react';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function withConfigOptions(WrappedComponent) {
  @observer
  class WithConfigOptions extends Component {
    state = {
      isConfigurePanelOpen: false,
      config: null
    };

    static getDerivedStateFromProps(props, state) {
      const { config } = props;

      // If there's no config in state, let's get setup
      if (!state.config) {
        // If there's no config, we show the config panel
        if (!config) {
          return { config: {}, isConfigurePanelOpen: true, model: new Model() };
        }

        return { config, model: new Model(config) };
      }

      return null;
    }

    handleSaveConfiguration = (form) => {
      const { model } = this.state;
      const { onConfigChange } = this.props;

      const newConfig = { ...model.get(), ...form.getValues() };
      model.set(newConfig);

      this.setState({ isConfigurePanelOpen: false, config: newConfig });

      if (onConfigChange) {
        onConfigChange(newConfig);
      }
    };

    handleShowConfigurePanel = () => {
      this.setState({ isConfigurePanelOpen: true });
    };

    handleCancelConfiguration = (form) => {
      this.setState({ isConfigurePanelOpen: false });
      form.reset();
    };

    render() {
      const { isConfigurePanelOpen, config, model } = this.state;

      return (
        <WrappedComponent
          handleShowConfigurePanel={this.handleShowConfigurePanel}
          handleSaveConfiguration={this.handleSaveConfiguration}
          handleCancelConfiguration={this.handleCancelConfiguration}
          isConfigurePanelOpen={isConfigurePanelOpen}
          model={model}
          {...this.props}
          config={config}
        />
      );
    }
  }

  WithConfigOptions.displayName = `WithConfigOptions(${getDisplayName(WrappedComponent)})`;

  return WithConfigOptions;
}
