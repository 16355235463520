import Collection from 'core/model/Collection';
import PromptToQueryModel from './PromptToQueryModel';

class PromptToQueryCollection extends Collection {
  get model() {
    return PromptToQueryModel;
  }
}

export default PromptToQueryCollection;
