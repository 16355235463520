import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PopoverPosition } from '@blueprintjs/core';

import Button from 'core/components/Button';
import Checkbox from 'core/components/Checkbox';
import Popover from 'core/components/Popover';
import Text from 'core/components/Text';
import Box from 'core/components/Box';
import SelectedValuesPanel from 'core/form/components/modalSelect/SelectedValuesPanel';
import { MAX_DETAIL_TABS } from 'app/util/constants';

const valueRenderer = ({ option, onVisibleChange, selectedTabs, maxSelected }) => (
  <Checkbox
    label={option.selectorLabel || option.label}
    checked={option.visible}
    onChange={(checked) => onVisibleChange(option.value, checked)}
    disabled={!option.visible && selectedTabs.length >= maxSelected}
  />
);

@inject('$auth')
@observer
class TabSelector extends Component {
  static defaultProps = {
    maxTabs: MAX_DETAIL_TABS,
    entityName: 'tabs'
  };

  state = {
    isOpen: false
  };

  dragging = false;

  handleChange = (values) => {
    const { onChange, tabs } = this.props;
    this.dragging = false;
    onChange(values.map((tabId) => tabs.find((tab) => tab.id === tabId)));
  };

  handleVisibleChange = (tabId, checked) => {
    const { onChange, tabs } = this.props;
    onChange(tabs.map((tab) => (tab.id === tabId ? { ...tab, visible: checked } : tab)));
  };

  handleDragStart = () => {
    this.dragging = true;
  };

  handleInteraction = (nextOpenState) => {
    if (!this.dragging) {
      this.setState({ isOpen: nextOpenState });
    }
  };

  get title() {
    const { maxTabs, tabs, entityName } = this.props;
    const columnCount = tabs && Math.ceil(tabs.length / 30);

    return (
      <Box mb={2}>
        <Text fontWeight="heavy" textTransform="capitalize">
          Customize {entityName}
        </Text>
        <Text as="div" fontWeight="normal" width={270 * columnCount} small muted>
          Choose {entityName} you want visible on this page. This will be saved as part of your user profile. A maximum
          of {maxTabs} {entityName} may be visible at once.
        </Text>
      </Box>
    );
  }

  renderContent() {
    const { maxTabs, tabs, visibleTabs } = this.props;
    const columnCount = tabs && Math.ceil(tabs.length / 30);

    return (
      <SelectedValuesPanel
        selectedValuesTitle={this.title}
        values={tabs.map((tab) => tab.value)}
        options={tabs}
        valueRenderer={valueRenderer}
        onSortStart={this.handleDragStart}
        onChange={this.handleChange}
        onVisibleChange={this.handleVisibleChange}
        itemStyle={{ breakInside: 'avoid', zIndex: 100 }}
        valuesItemStyle={{ columnCount }}
        valueListProps={{ maxHeight: 'calc(100vh - 210px)' }}
        maxSelected={maxTabs}
        selectedTabs={visibleTabs}
        reorderable
      />
    );
  }

  render() {
    const { isOpen } = this.state;
    const { buttonProps, children } = this.props;
    return (
      <Popover
        content={this.renderContent()}
        disabled={buttonProps ? buttonProps.disabled : false}
        position={PopoverPosition.BOTTOM_RIGHT}
        isOpen={isOpen}
        onInteraction={this.handleInteraction}
      >
        <Button intent="primary" icon="cog" active={isOpen} minimal {...buttonProps}>
          {children || 'Customize'}
        </Button>
      </Popover>
    );
  }
}

export default TabSelector;
