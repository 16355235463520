import React from 'react';
import { Flex, Text, ProgressBar } from 'core/components';
import { observer } from 'mobx-react';
import Table from 'core/components/HTMLTable';
import styled from 'styled-components';

const numberCellStyles = {
  width: 60,
  minWidth: 60,
  maxWidth: 60,
  textAlign: 'right'
};

const TdWithMorePadding = styled.td`
  padding: 10px;
`;

const PingAgentDetails = ({ results, chartData }) => {
  if (!results) {
    return null;
  }

  return (
    <Flex borderLeft="thin" flexDirection="column" gap="12px">
      <Table striped interactive>
        <thead>
          <tr>
            <th style={{ width: '100%' }}>Agent</th>
            <th style={numberCellStyles}>Min</th>
            <th style={numberCellStyles}>Max</th>
            <th style={numberCellStyles}>Avg</th>
            <th style={numberCellStyles}>StdDev</th>
            <th style={numberCellStyles}>Loss</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(results).map(({ id, ping, agent }) => {
            if (!ping?.statistics || !agent) {
              return null;
            }

            return (
              <tr key={id}>
                <TdWithMorePadding>
                  <Text as="div" fontWeight="medium">
                    {agent?.display_name || agent?.host_name}
                  </Text>
                  <Text muted small>
                    {agent.host_ip}
                  </Text>
                </TdWithMorePadding>
                <TdWithMorePadding style={numberCellStyles}>
                  <Text monospace fontSize={11}>
                    {ping.statistics.min ? ping.statistics.min.toFixed(2) : 'N/A'}
                  </Text>
                </TdWithMorePadding>
                <TdWithMorePadding style={numberCellStyles}>
                  <Text monospace fontSize={11}>
                    {ping.statistics.max ? ping.statistics.max.toFixed(2) : 'N/A'}
                  </Text>
                </TdWithMorePadding>
                <TdWithMorePadding style={numberCellStyles}>
                  <Text monospace fontSize={11}>
                    {ping.statistics.avg ? ping.statistics.avg.toFixed(2) : 'N/A'}
                  </Text>
                </TdWithMorePadding>
                <TdWithMorePadding style={numberCellStyles}>
                  <Text monospace fontSize={11}>
                    {ping.statistics.stddev ? ping.statistics.stddev.toFixed(2) : 'N/A'}
                  </Text>
                </TdWithMorePadding>
                <TdWithMorePadding style={numberCellStyles}>
                  <Text monospace fontSize={11}>
                    {(ping.statistics.loss ? ping.statistics.loss * 100 : 0).toFixed(2)}%
                  </Text>
                </TdWithMorePadding>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {chartData.progress < 1 && (
        <Flex flexDirection="column" gap="4px" p={1}>
          <ProgressBar
            animated
            height="6px"
            intent={chartData.progress < 1 ? 'primary' : 'success'}
            value={chartData.progress}
          />
          {chartData.progress < 1 && (
            <Text small muted textAlign="right">
              {chartData.totalPingSent} / {chartData.totalPingCount}
            </Text>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default observer(PingAgentDetails);
