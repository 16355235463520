import React, { Component } from 'react';
import { any } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import { uniqBy } from 'lodash';

import { formConsumer, Field, InputGroup, Select } from 'core/form';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$sites')
@inject('$devices', '$dictionary', '$lookups')
@formConsumer
@observer
class ApplyParametricOptions extends Component {
  static contextTypes = {
    form: any
  };

  static defaultProps = {
    applyButtonText: 'Apply Changes',
    inputClassName: Classes.FILL
  };

  componentDidMount() {
    const { $dictionary, dashboard, form } = this.props;

    // if form was saved without parametric fields due to misconfiguration, set to form
    if (!form.getField('parametric_fields').at(0) && dashboard?.get('parametric_fields').length) {
      form.setValue('parametric_fields', dashboard.get('parametric_fields'));
    }

    const parametric_fields = form.getField('parametric_fields').at(0);
    const type = parametric_fields.type.getValue();
    const filter = $dictionary.dictionary.queryFilters.parametricFilterTypes.find(
      (filterType) => type === filterType.type
    );
    if (filter) {
      const filterValidatorRules = $dictionary.getFilterValueValidator(filter.filterFields[0]);

      // If you have a validation that's a regex and a value that's not a string, you're in trouble
      // Values here should always be strings anyway, but sometimes it appears they're not. /shrug
      if (filterValidatorRules.some((rule) => rule.startsWith && rule.startsWith('regex'))) {
        parametric_fields.value.transform = {
          in: (value) => `${value}`
        };
        parametric_fields.value.setValue(`${parametric_fields.value.getValue()}`);
      }

      // dynamically set the rule and error message for the parametric field
      parametric_fields.value.setRules(filterValidatorRules);
      parametric_fields.value.messages = {
        regex: `${type} ${$dictionary.dictionary.queryFilters.errorMessages[filter.filterFields[0]]}`
      };
    }
  }

  onButtonClick = () => {
    const { form, dashboard, handleSubmit } = this.props;

    handleSubmit(dashboard, form.getValues());
  };

  handleKeyPress = (e) => {
    const { form, handleSubmit } = this.props;

    if (e && e.key === 'Enter' && handleSubmit && form.valid) {
      handleSubmit();
    }
  };

  getFieldProps(field) {
    const { $devices, $dictionary, $lookups, $sites, dashboard } = this.props;
    const parametric_value_type = dashboard.get('parametric_value_type');
    const parametric_value_options = dashboard.get('parametric_value_options');
    const type = field.type.getValue();

    let options = null;
    let onQuery;

    if (
      Array.isArray(parametric_value_options) &&
      parametric_value_options.length &&
      parametric_value_type === 'predefined'
    ) {
      options = parametric_value_options.map((opt) => ({ value: opt, label: opt }));
    } else if (type === 'country') {
      onQuery = $lookups.countries;
    } else if (type === 'cdn') {
      onQuery = $lookups.cdns;
    } else if (type === 'service_provider') {
      onQuery = $lookups.ottServiceProviders;
    } else if (type === 'service_type') {
      onQuery = $lookups.ottServiceTypes;
    } else if (type === 'service_name') {
      onQuery = $lookups.ottServices;
    } else if (type === 'network_boundary') {
      options = $dictionary.getSelectOptions('interfaceClassification.networkBoundaryTypes');
    } else if (type === 'connectivity_type') {
      options = $dictionary.getSelectOptions('interfaceClassification.connectivityTypes');
    } else if (type === 'device') {
      options = $devices.deviceNameOptions;
    } else if (type === 'device_label') {
      options = $devices.activeDeviceLabelOptions;
    } else if (type === 'site') {
      options = $sites.collection.generateSelectOptions({ valueKey: 'title', sort: 'id' });
    } else if (type === 'custom_dimension') {
      options = null;
    } else if (type === 'provider') {
      onQuery = $lookups.providers;
    } else if (type === 'as_name') {
      onQuery = $lookups.asNames;
    } else if (type === 'as_number') {
      onQuery = $lookups.asNumbers;
    } else if (type === 'tag') {
      onQuery = $lookups.flowTags;
    } else if (type === 'city') {
      onQuery = $lookups.cities;
    } else if (type === 'region') {
      onQuery = $lookups.regions;
    } else if (type === 'interface_desc') {
      onQuery = $lookups.snmpAliases;
    } else if (type === 'interface_name') {
      onQuery = $lookups.interfaceDescriptions;
    } else if (type === 'market') {
      onQuery = $lookups.market;
    } else if (type === 'cloud_provider') {
      onQuery = $lookups.cloud_provider;
    } else if (type === 'gce_proj_id') {
      onQuery = $lookups.gceProject;
    } else if (type === 'aws_region') {
      onQuery = $lookups.awsRegion;
    } else {
      const fields = $dictionary.get('queryFilters').flatParametricFilterTypes[type];
      if (fields && Array.isArray(fields) && fields.length) {
        onQuery = $lookups.getAutoCompleteFilterHandler(fields[0]);
      }
    }

    const validateOptions = (onQuery || (options && options.length > 0)) && parametric_value_type === 'predefined';

    return {
      options,
      onQuery,
      validateOptions
    };
  }

  getField(field, index) {
    const { $dictionary, applyButtonText, children, dashboard, inputClassName, autoFocusInput } = this.props;
    const { onQuery, options: rawOptions, validateOptions } = this.getFieldProps(field);

    const parametric_value_type = dashboard.get('parametric_value_type');
    const fieldValue = field.value.getValue();
    const options = uniqBy(rawOptions, 'value');

    let inputComponent;
    if (onQuery || (options && options.length > 0)) {
      const {
        dictionary: {
          queryFilters: { parametricOperators, parametricNeverExactMatch }
        }
      } = $dictionary;
      const parametricFieldTypeValue = field.type.getValue();
      const exactMatch =
        parametricOperators[parametricFieldTypeValue] !== 'ILIKE' &&
        parametricOperators[parametricFieldTypeValue] !== '~' &&
        !parametricNeverExactMatch.includes(parametricFieldTypeValue);

      inputComponent = (
        <Select
          exactMatch={parametric_value_type === 'predefined' || (options && options.length) || exactMatch}
          showFilter={parametric_value_type === 'predefined' || (options && options.length > 10) || exactMatch}
          fill
        />
      );
    } else {
      inputComponent = <InputGroup onKeyPress={this.handleKeyPress} />;
    }

    const inputField = (
      <Field
        className="no-margin bp4-fill parametric-input"
        inputGroupClassName={inputClassName}
        field={field.value}
        options={options && options.length ? options : undefined}
        validateOptions={validateOptions}
        autoFocus={autoFocusInput}
        onQuery={!options || options.length === 0 ? onQuery : undefined}
        showLabel={false}
      >
        {inputComponent}
      </Field>
    );

    return (
      <div key={field.value._id}>
        {children({
          buttonProps: {
            onClick: this.onButtonClick,
            disabled: parametric_value_type === 'predefined' && [undefined, null].includes(fieldValue),
            text: applyButtonText
          },
          inputField,
          question: field.question.getValue(),
          index
        })}
      </div>
    );
  }

  render() {
    const { form } = this.props;
    const parametric_fields = form.getField('parametric_fields');

    return parametric_fields.map((field, index) => this.getField(field, index));
  }
}

export default ApplyParametricOptions;
