import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Box, Suspense } from 'core/components';
import SelectedFiltersDisplay from 'app/components/interfaceGroup/SelectedFiltersDisplay';

@observer
class InterfaceGroupDisplay extends Component {
  render() {
    const {
      columns,
      interfaceGroup,
      loading,
      staticInterfacesTitle,
      onSave,
      staticOnly,
      staticInterfacesEmptyText,
      containerProps
    } = this.props;

    if (!interfaceGroup || !interfaceGroup.get('staticInterfaces') || !interfaceGroup.get('filters')) {
      return (
        <SelectedFiltersDisplay
          columns={columns}
          filterGroups={[]}
          staticInterfaces={[]}
          staticInterfacesTitle={staticInterfacesTitle}
          staticOnly={staticOnly}
        />
      );
    }

    return (
      <Suspense loading={loading}>
        <Box {...containerProps}>
          {interfaceGroup && (
            <SelectedFiltersDisplay
              columns={columns}
              filterGroups={interfaceGroup.get('filters')}
              staticInterfaces={interfaceGroup.get('staticInterfaces')}
              staticInterfacesTitle={staticInterfacesTitle}
              onSave={onSave}
              staticOnly={staticOnly}
              staticInterfacesEmptyText={staticInterfacesEmptyText}
            />
          )}
        </Box>
      </Suspense>
    );
  }
}

export default InterfaceGroupDisplay;
