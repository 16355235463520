import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, MenuItem, Spinner } from 'core/components';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$users', '$comments')
@inject('$alerting')
@observer
export class UnackAlertButton extends Component {
  state = {
    unackRequestPending: false
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleUnackAlert = () => {
    const { model, $comments, $alerting } = this.props;

    this.setState({ unackRequestPending: true });

    $alerting
      .unacknowledgeAlarm(model, { toast: true })
      .then(() => {
        // Optimistically show acked
        model.setUnacknowledged();

        if (this._mounted) {
          this.setState({
            unackRequestPending: false
          });
        }

        // We delete comments in node, so force fetch to show updates
        $comments.collection.fetch({ force: true });
      })
      .catch(() => {
        if (this._mounted) {
          this.setState({
            unackRequestPending: false
          });
        }
      });
  };

  render() {
    const { model, asMenuItem, buttonProps } = this.props;
    const { unackRequestPending } = this.state;
    const buttonText = model.isAutoAcked ? 'Auto-acked Alert' : 'Remove Ack';

    return asMenuItem ? (
      <MenuItem
        icon="clean"
        text={buttonText}
        disabled={model.isAutoAcked || unackRequestPending}
        onClick={this.handleUnackAlert}
        {...buttonProps}
      />
    ) : (
      <Button
        disabled={model.isAutoAcked || unackRequestPending}
        fill
        icon="clean"
        textAlign="left"
        onClick={this.handleUnackAlert}
        text={buttonText}
        rightIcon={unackRequestPending ? <Spinner ml={1} size={14} /> : null}
        {...buttonProps}
      />
    );
  }
}
