import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Icon, Flex, Text } from 'core/components';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';

@observer
class FilterConnectorDisplay extends Component {
  render() {
    const { group } = this.props;
    const connector = group.connector.getValue();
    const shouldExcludeResults = group.not.getValue();

    return (
      <Flex alignItems="center" fontSize="small">
        <Icon
          color={shouldExcludeResults ? 'danger' : 'success'}
          icon={shouldExcludeResults ? FiMinusCircle : FiPlusCircle}
          mr="4px"
        />
        <Text color="muted">
          {shouldExcludeResults ? 'Exclude' : 'Include'} {connector.toLowerCase()}
        </Text>
      </Flex>
    );
  }
}

export default FilterConnectorDisplay;
