import React from 'react';
import classNames from 'classnames';

import { Box, Text } from 'core/components';
import SelectedValuesPanel from 'core/form/components/modalSelect/SelectedValuesPanel';
import DimensionValueRenderer from './DimensionValueRenderer';

const DimensionMultiValuesRenderer = (props) => {
  const { values, field, onChange, onEditComplete, onUnselect, readOnly, reorderable } = props;

  const onUnselectWrapper = (formField, value) => {
    onUnselect(formField, value);
    onEditComplete(false);
  };

  const onChangeWrapper = (changes, redrawOnly = false) => {
    if (onChange) {
      onChange(changes);
    }
    onEditComplete(false, redrawOnly);
  };

  const valueRenderer = (valueProps) => (
    <DimensionValueRenderer {...valueProps} field={field} onUnselect={onUnselectWrapper} />
  );

  const className = classNames('dimensions', {
    'read-only': readOnly,
    'pt-intent-danger-text': field.hasError && field.dirty,
    'pt-intent-warning-text': field.hasError && field.pristine,
    'pt-text-muted': values.length === 0 && !field.hasError
  });

  return (
    <Box mt="4px">
      <span className={className}>
        {values.length === 0 && (
          <Text muted small>
            No dimensions selected
          </Text>
        )}
        {values.length > 0 && (
          <SelectedValuesPanel
            {...props}
            onChange={onChangeWrapper}
            reorderable={!readOnly && values.length > 1 && reorderable !== false}
            valueRenderer={valueRenderer}
            showCard={false}
            allowSelection={false}
          />
        )}
      </span>
    </Box>
  );
};

export default DimensionMultiValuesRenderer;
