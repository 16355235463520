import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';

import { tabbedChartColors } from 'app/stores/colors/Colors';
import { Highcharts } from 'core/components';
import { greekIt } from 'app/util/utils';
import { getMetricOption } from 'app/util/policies';
import { lineClassNames } from 'app/components/insights/InsightChart';
import { parseBaselineUsed } from '../util/debugChartHelpers';

const StyledChart = styled(Highcharts)`
  .highcharts-axis-labels {
    font-size: 11px;
  }
`;

@observer
export default class AlertDebugChart extends Component {
  static defaultProps = {
    data: []
  };

  handleChartCallback = (chart) => {
    this.chart = chart;
    this.resizeObserver.observe(chart.container);
  };

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  resizeObserver = new ResizeObserver(() => {
    this.chart.reflow();
  });

  newSeries = (name, data = []) => ({ name, data, marker: { symbol: 'circle', radius: 4 } });

  get seriesData() {
    const { data = [] } = this.props;

    const series = data.map((trigger = {}) => {
      const { baseline_value, baseline_description, metrics = {} } = trigger?.context?.streaming_metrics || {};
      const values = Object.entries(metrics).map(([metric, value]) => ({ metric, value }));

      return { values, time: trigger.timestamp, baselineUsed: baseline_description, baselineValue: baseline_value };
    });

    const dataGroups = {
      matches: this.newSeries('Matches'),
      baseline: this.newSeries('Baseline'),
      baselineFallback: this.newSeries('Baseline Fallback'),
      noCurrent: this.newSeries('No Current Value')
    };

    series.forEach((d) => {
      const { time, values, baselineValue, baselineUsed } = d;
      const { value: alertValue } = values.find(({ metric }) => metric === this.primaryMetric.value) || {};
      const epochTime = Date.parse(time);
      const [isMatch, isFallback] = parseBaselineUsed(baselineUsed);

      if (!alertValue) {
        return;
      }

      if (isMatch) {
        dataGroups.matches.data.push([epochTime, alertValue]);
      } else {
        dataGroups.noCurrent.data.push([epochTime, alertValue]);
      }

      if (isFallback) {
        dataGroups.baselineFallback.data.push([epochTime, baselineValue]);
      } else {
        dataGroups.baseline.data.push([epochTime, baselineValue]);
      }
    });
    return Object.values(dataGroups);
  }

  get primaryMetric() {
    const { alertModel } = this.props;
    return getMetricOption(alertModel.primaryMetric, alertModel.reconMeasurement);
  }

  get plotLinesX() {
    const { alertModel } = this.props;
    const { startTime, endTime } = alertModel;
    const plotLines = [];

    plotLines.push({
      id: 'start-line',
      className: lineClassNames.alarm,
      value: new Date(startTime).getTime()
    });

    if (endTime) {
      plotLines.push({
        id: 'end-line',
        className: lineClassNames.cleared,
        value: new Date(endTime).getTime()
      });
    }

    return plotLines;
  }

  get plotLinesY() {
    const { alertModel } = this.props;
    const { conditions = [] } = alertModel.threshold;
    const { minTrafficValue } = alertModel.policyObject;
    const { value: fallbackValue } = conditions.find((condition) => condition.fallbackSettings) || {};
    const primaryCondition = conditions.find(
      ({ type, metric }) => type === 'static' && metric === this.primaryMetric.value
    );

    const plotLines = [];
    let staticThreshold;

    if (primaryCondition) {
      staticThreshold = primaryCondition.comparisonValue;
    }

    if (staticThreshold && primaryCondition?.metric && primaryCondition.metric.includes('Percent')) {
      staticThreshold /= 1000;
    }

    if (Number.isFinite(staticThreshold)) {
      plotLines.push({
        id: 'static-threshold-line',
        className: 'thin-line major-level',
        label: {
          text: 'Static Threshold',
          align: 'left',
          x: -1,
          className: 'highcharts-plot-line-label major-level'
        },
        value: staticThreshold,
        zIndex: 5
      });
    }

    if (Number.isFinite(fallbackValue)) {
      plotLines.push({
        id: 'fallback-line',
        className: 'baseline-level baseline-insight-level',
        label: {
          text: 'Fallback Value',
          align: 'left',
          x: -1,
          className: 'highcharts-plot-line-label baseline-insight-level'
        },
        value: fallbackValue,
        width: 1
      });
    }

    if (Number.isFinite(minTrafficValue)) {
      plotLines.push({
        id: 'policy-min-traffic-line',
        className: 'baseline-level thin-line',
        label: {
          text: 'Policy Min Traffic',
          align: 'right',
          x: -1,
          className: 'highcharts-plot-line-label baseline-insight-level'
        },
        value: minTrafficValue,
        width: 1,
        zIndex: 5
      });
    }

    return plotLines;
  }

  get chartOptions() {
    const { unitLabel } = this.primaryMetric;

    return {
      chart: {
        type: 'scatter'
      },
      yAxis: {
        title: { text: `Values (${this.primaryMetric.label})` },
        min: 0.001,
        plotLines: this.plotLinesY,
        endOnTick: false
      },
      xAxis: {
        type: 'datetime',
        plotLines: this.plotLinesX
      },
      tooltip: {
        formatter() {
          const { x, y, colorIndex } = this;
          const formattedDate = moment.utc(x).format(DEFAULT_DATETIME_FORMAT);
          const yDisplayValue = greekIt(y, { suffix: unitLabel, scaleMax: 0.999 }).displayFull;
          const color = tabbedChartColors[colorIndex];

          return `
            ${formattedDate}<br/>
            <span style="color:${color};">\u25CF</span> ${this.series.name}: <b>${yDisplayValue}</b>`;
        }
      },
      series: this.seriesData
    };
  }

  render() {
    return (
      <StyledChart
        callback={this.handleChartCallback}
        options={this.chartOptions}
        colors={tabbedChartColors}
        allowChartUpdate
      />
    );
  }
}
