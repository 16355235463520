import React from 'react';
import { observer } from 'mobx-react';

const Export = (props) => {
  const { fileName, type, payload } = props;

  let href;
  if (type === 'csv') {
    // href = `data:text/csv;charset=utf-8,${encodeURIComponent(payload)}`;
    const blob = new Blob([payload], { type: 'text/csv;charset=utf-8;' });
    href = URL.createObjectURL(blob);
  } else {
    href = window.URL.createObjectURL(payload);
  }

  return (
    <a href={href} download={`${fileName}.${type}`}>
      {fileName}.{type}
    </a>
  );
};

export default observer(Export);
