import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { Flex, Icon, Link, Tag, Text } from 'core/components';
import LabelValue from 'app/views/metrics/components/LabelValue';
import { formatDateTime } from 'core/util/dateUtils';

/**
 * This component displays the following information about an Alert:
 * - Tenant link (if tenant properties are specified)
 * - Alert ID
 * - Severity
 * - Alert state
 * - Ack state
 * - Alert start and end
 * - Clear status
 */
const AlertOverview = ({ alertModel, linkToDetail, labelWidth = 150 }) => (
  <>
    <LabelValue
      label="ID"
      value={
        linkToDetail ? (
          <Link to={alertModel.alertDetailLink} blank>
            {alertModel.id}
          </Link>
        ) : (
          alertModel.id
        )
      }
      labelWidth={labelWidth}
    />
    <LabelValue
      label="Severity"
      value={
        <Flex alignItems="center" gap="4px">
          <Icon icon="symbol-circle" color={alertModel.severityColor} iconSize={16} />
          <Text>{alertModel.severity}</Text>
        </Flex>
      }
      labelWidth={labelWidth}
    />
    <LabelValue
      label="Alert State"
      labelWidth={labelWidth}
      value={
        <Flex alignItems="center" gap="4px">
          <Tag
            intent={alertModel.stateColor}
            round
            minimal
            width="80px"
            py="4px"
            px="6px"
            textAlign="center"
            fontWeight="500"
          >
            {alertModel.stateLabel}
          </Tag>
        </Flex>
      }
    />
    <LabelValue label="Ack State" labelWidth={labelWidth} value={alertModel.ackStateLabel} />
    <LabelValue label="Start Time" value={<>{formatDateTime(alertModel.startTime)}</>} labelWidth={labelWidth} />
    {alertModel.eventEndTime && (
      <LabelValue label="Event End Time" value={formatDateTime(alertModel.eventEndTime)} labelWidth={labelWidth} />
    )}
    <LabelValue
      label="Clear Time"
      labelWidth={labelWidth}
      value={alertModel.endTime ? formatDateTime(alertModel.endTime) : 'Currently Active'}
    />
  </>
);

AlertOverview.propTypes = {
  // This must be an AlertModel instance
  alertModel: PropTypes.object.isRequired,

  // Set to true to display the alert ID as a link to the alert's detail page
  linkToDetail: PropTypes.bool,

  // Customize the width of the labels
  labelWidth: PropTypes.number
};

AlertOverview.defaultProps = {
  linkToDetail: undefined,
  labelWidth: 150
};

export default withTheme(AlertOverview);
