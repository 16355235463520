import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, Text } from 'core/components';
import { NMS_ENTITY_TYPES } from '@kentik/ui-shared/nms/policies';
import ListRenderer from './ListRenderer';
import { getNmsAlertDimensionDisplay } from '../util/nms/nmsNativeAlertDimensionDisplay';
import { ENTITY_DIMENSIONS, getEntityData } from '../util/nms/nmsAlertEntity';

/**
 * This component renders a list of dimensions and their associated values for an alert for an NMS policy.
 * The component accepts the following properties:
 * - alertModel: This is required and expected to be an AlertModel instance. If this AlertModel does not originate from an NMS policy, this component will render nothing.
 * - maxDimensionsToShow [optional]: Indicate how many entries should be shown in the list. If this number is less than the total number of items, we will show max-1 items and then truncate the rest to "Plus x more". Defaults to showing all dimensions.
 * - inline [optional]: set to true if you want each k/v pair to be displayed inline.
 *
 * Example usage:
 * <NmsNativeAlertDimensionRenderer
 *   alertModel={model}
 *   maxDimensionsToShow={1}
 *   inline={false}
 * />
 */
@inject('$alerting', '$devices', '$metrics')
@observer
export default class NmsNativeAlertDimensionRenderer extends Component {
  renderDimension = ({ dimension, value }) => {
    const { $alerting, $devices, $metrics, alertModel } = this.props;

    const dimensionDisplay = getNmsAlertDimensionDisplay({
      $alerting,
      $devices,
      $metrics,
      dimension,
      value,
      alertModel
    });

    if (!dimensionDisplay) {
      return null;
    }

    const { label, value: displayValue } = dimensionDisplay;

    return (
      <Flex key={dimension} maxWidth="100%">
        <Text pr="4px" muted>
          {label}:
        </Text>
        <Text fontWeight={500} ellipsis>
          {displayValue}
        </Text>
      </Flex>
    );
  };

  alertDimensions = () => {
    const { alertModel } = this.props;
    const entityData = getEntityData(alertModel);
    const alarmContext = alertModel?.nmsAlarmContext;
    const groupKeyDimensions = Object.entries(alarmContext?.groupKey || {}).map(([dimension, value]) => ({
      dimension,
      value
    }));

    if (entityData?.component?.name) {
      // For component entity alerts, index is not terribly helpful so if we have name we show that instead.
      const componentDimensions = groupKeyDimensions.filter(
        ({ dimension }) => dimension !== ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.COMPONENT]?.INDEX
      );
      componentDimensions.push({
        dimension: ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.COMPONENT]?.NAME,
        value: entityData.component.name
      });
      return componentDimensions;
    }

    if (entityData?.hasBgpDisplayData) {
      // For BGP entity alerts that have sufficient user-facing BGP display data,
      // we do not want to show what's in the alarm group key directly as index is not helpful to the user.
      // Instead of index, we show peer AS and remote IP
      const bgpDimensions = groupKeyDimensions.filter(
        ({ dimension }) => dimension !== ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.BGP]?.INDEX
      );
      if (entityData.bgp?.peerAs) {
        bgpDimensions.push({
          dimension: ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.BGP]?.PEER_AS,
          value: entityData.bgp?.peerAs
        });
      }
      if (entityData.bgp?.remoteIp) {
        bgpDimensions.push({
          dimension: ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.BGP]?.REMOTE_IP,
          value: entityData.bgp?.remoteIp
        });
      }
      return bgpDimensions;
    }

    if (entityData?.hasAgentDisplayData) {
      return [
        {
          dimension: ENTITY_DIMENSIONS[NMS_ENTITY_TYPES.AGENT]?.AGENT_NAME,
          value: entityData.agent?.name
        }
      ];
    }

    return groupKeyDimensions;
  };

  render() {
    const { alertModel, inline, maxDimensionsToShow } = this.props;

    const alarmContext = alertModel?.nmsAlarmContext;
    if (!alarmContext) {
      return null;
    }

    return (
      <ListRenderer
        list={this.alertDimensions()}
        maxItemsToShow={maxDimensionsToShow}
        inline={inline}
        renderListItem={this.renderDimension}
        getItemKey={({ dimension }) => dimension}
      />
    );
  }
}
