import { cloneDeep } from 'lodash';
import { addFilterGroup, getDefaultFiltersObject } from 'core/util/filters';
import { getASNValues } from 'app/util/queryResults';
import {
  getInterfacesFilterGroup,
  getKubeFilterGroup
} from 'app/views/hybrid/maps/components/popovers/queryOptionsHelper';
import $tabs from 'app/components/detailTabs/$tabs';
import $networkClass from 'app/stores/$networkClass';
import $savedFilters from 'app/stores/savedFilter/$savedFilters';

function addOriginAsnFilter(query, asn, dir = 'src|dst') {
  const filters = query.filters ? cloneDeep(query.filters) : getDefaultFiltersObject();
  const withASNFilter = addFilterGroup(filters, 'All', false, [
    {
      filterField: `${dir}_as`,
      operator: '=',
      filterValue: getASNValues(asn).asnList
    }
  ]);

  return {
    ...query,
    filters: withASNFilter
  };
}

function addProviderFilter(query, provider, dir = 'src|dst') {
  const filters = query.filters ? cloneDeep(query.filters) : getDefaultFiltersObject();
  const withASNFilter = addFilterGroup(filters, 'All', false, [
    {
      filterField: `i_${dir}_provider_classification`,
      operator: '=',
      filterValue: provider
    }
  ]);

  return {
    ...query,
    filters: withASNFilter
  };
}

function addNextHopAsnFilter(query, nextHopASN, dir = 'src|dst') {
  const filters = query.filters ? cloneDeep(query.filters) : getDefaultFiltersObject();
  const withASNFilter = addFilterGroup(filters, 'All', false, [
    {
      filterField: `${dir}_nexthop_as`,
      operator: '=',
      filterValue: getASNValues(nextHopASN).asnList
    }
  ]);

  return {
    ...query,
    filters: withASNFilter
  };
}

function addCloudFilter(query, cloud, dir = 'origination|termination') {
  const filters = query.filters ? cloneDeep(query.filters) : getDefaultFiltersObject();
  const withCloudFilter = addFilterGroup(filters, 'All', false, [
    {
      filterField: `i_trf_${dir}`,
      operator: '=',
      filterValue: cloud
    }
  ]);

  return {
    ...query,
    filters: withCloudFilter
  };
}

function addCloudRegionFilter(query, provider, region, dir) {
  const map = {
    azure: { src: 'kt_az_src_region', dst: 'kt_az_dst_region' },
    aws: { src: 'kt_aws_src_region', dst: 'kt_aws_dst_region' },
    gcp: { src: 'ktsubtype__gcp_subnet__STR04', dst: 'ktsubtype__gcp_subnet__STR05' }
  };

  const filters = query.filters ? cloneDeep(query.filters) : getDefaultFiltersObject();
  const withCloudRegionFilter = dir
    ? addFilterGroup(filters, 'All', false, [
        {
          filterField: map[provider][dir],
          operator: '=',
          filterValue: region
        }
      ])
    : addFilterGroup(filters, 'Any', false, [
        { filterField: map[provider].src, operator: '=', filterValue: region },
        { filterField: map[provider].dst, operator: '=', filterValue: region }
      ]);

  return {
    ...query,
    filters: withCloudRegionFilter
  };
}

function addCloudVpcFilter(query, provider, vpc, dir) {
  const map = {
    azure: { src: 'kt_az_src_vnet', dst: 'kt_az_dst_vnet' },
    aws: { src: 'kt_aws_src_vpc_id', dst: 'kt_aws_dst_vpc_id' }
  };

  const filters = query.filters ? cloneDeep(query.filters) : getDefaultFiltersObject();
  const withCloudVpcFilter = dir
    ? addFilterGroup(filters, 'All', false, [
        {
          filterField: map[provider][dir],
          operator: '=',
          filterValue: vpc
        }
      ])
    : addFilterGroup(filters, 'Any', false, [
        { filterField: map[provider].src, operator: '=', filterValue: vpc },
        { filterField: map[provider].dst, operator: '=', filterValue: vpc }
      ]);

  return {
    ...query,
    filters: withCloudVpcFilter
  };
}

function addCloudSubnetFilter(query, provider, subnet, dir) {
  const map = {
    azure: { src: 'kt_az_src_subnet', dst: 'kt_az_dst_subnet' },
    aws: { src: 'kt_aws_src_subnet_id', dst: 'kt_aws_dst_subnet_id' },
    gcp: { src: 'ktsubtype__gcp_subnet__STR08', dst: 'ktsubtype__gcp_subnet__STR09' }
  };

  const filters = query.filters ? cloneDeep(query.filters) : getDefaultFiltersObject();
  const withCloudSubnetFilter = dir
    ? addFilterGroup(filters, 'All', false, [
        {
          filterField: map[provider][dir],
          operator: '=',
          filterValue: subnet
        }
      ])
    : addFilterGroup(filters, 'Any', false, [
        { filterField: map[provider].src, operator: '=', filterValue: subnet },
        { filterField: map[provider].dst, operator: '=', filterValue: subnet }
      ]);

  return {
    ...query,
    filters: withCloudSubnetFilter
  };
}

function addSiteFilter(query, site, dir = 'unknown') {
  const map = { src: 'kt_src_site_title', dst: 'kt_dst_site_title', unknown: 'i_device_site_name' };
  const filters = query.filters ? cloneDeep(query.filters) : getDefaultFiltersObject();
  const withSiteFilter = addFilterGroup(filters, 'All', false, [
    {
      filterField: map[dir],
      operator: '=',
      filterValue: site
    }
  ]);

  return {
    ...query,
    filters: withSiteFilter
  };
}

function addDeviceFilter(query, device) {
  const filters = query.filters ? cloneDeep(query.filters) : getDefaultFiltersObject();
  const withDeviceFilter = addFilterGroup(filters, 'All', false, [
    {
      filterField: 'i_device_id',
      operator: '=',
      filterValue: String(device)
    }
  ]);

  return {
    ...query,
    filters: withDeviceFilter
  };
}

function addInterfaceFilter(query, intf, dir = 'input|output') {
  const filters = query.filters ? cloneDeep(query.filters) : getDefaultFiltersObject();
  const withDeviceFilter = addFilterGroup(filters, 'All', false, [
    {
      filterField: `${dir}_port`,
      operator: '=',
      filterValue: String(intf)
    }
  ]);

  return {
    ...query,
    filters: withDeviceFilter
  };
}

function addKubeFilter(query, { device, namespace }) {
  const inboundFilterGroup = {
    connector: 'All',
    name: 'Inbound',
    named: true,
    filterGroups: [getKubeFilterGroup({ entity: { namespace }, direction: 'dst', internet: true })]
  };

  const outboundFilterGroup = {
    connector: 'All',
    name: 'Outbound',
    named: true,
    filterGroups: [getKubeFilterGroup({ entity: { namespace }, direction: 'dst', internet: true })]
  };

  if (query.aggregateFiltersDimensionLabel === 'AS Number') {
    Object.assign(query, {
      aggregateFilters: [
        { ...inboundFilterGroup, metric: ['AS_src'] },
        { ...outboundFilterGroup, metric: ['AS_dst'] }
      ]
    });
  } else if (query.aggregateFiltersDimensionLabel === 'Service (Port+Proto)') {
    Object.assign(query, {
      aggregateFilters: [
        { ...inboundFilterGroup, metric: ['src_proto_port'] },
        { ...outboundFilterGroup, metric: ['dst_proto_port'] }
      ]
    });
  } else if (query.aggregateFiltersDimensionLabel === 'IP Address') {
    Object.assign(query, {
      aggregateFilters: [
        { ...inboundFilterGroup, metric: ['IP_src'] },
        { ...outboundFilterGroup, metric: ['IP_dst'] }
      ]
    });
  }

  return Object.assign(query, {
    all_devices: false,
    device_name: device ? [device] : [],
    device_types: device ? [] : ['kappa']
  });
}

function excludePrivateASNsFilter(query) {
  const filterNames = ['PRIVATE_ASN_SRC', 'PRIVATE_ASN_DST'];
  const filters = query.filters ? cloneDeep(query.filters) : getDefaultFiltersObject();

  filterNames.forEach((name) => {
    const savedFilterModel = $savedFilters.collection.find(
      (f) => f.get('filter_level') === 'global' && f.get('filter_name') === name
    );

    if (savedFilterModel) {
      const savedFilter = { filter_id: savedFilterModel.get('id'), is_not: true };
      if (filters.filterGroups.length === 0) {
        addFilterGroup(filters);
      }
      filters.filterGroups[0].saved_filters.push(savedFilter);
    }
  });

  return {
    ...query,
    filters
  };
}

// adds a filter group that excludes internal asns as defined in the $networkClass model
function excludeInternalASNsFilter(query) {
  const { internalASNs } = $networkClass.model;

  if (internalASNs.length > 0) {
    // assemble a list of filters to exclude internal as numbers
    // note this is broke down into distinct src/dst filters as the non-directional filter did not work
    const excludedASNs = internalASNs
      .map((asn) => [
        {
          filterField: 'src_as',
          operator: '<>',
          filterValue: asn.toString()
        },

        {
          filterField: 'dst_as',
          operator: '<>',
          filterValue: asn.toString()
        }
      ])
      .flat();

    const filters = query.filters ? cloneDeep(query.filters) : getDefaultFiltersObject();
    const withExcludedInternalASNs = addFilterGroup(filters, 'All', false, excludedASNs);

    return {
      ...query,
      filters: withExcludedInternalASNs
    };
  }

  return query;
}

export function getServicesQuery({ isKube = false, device, namespace }) {
  const { query } = $tabs.getTabs().SERVICE;

  if (isKube) {
    return addKubeFilter(query, { device, namespace });
  }

  return query;
}

export function getIPsQuery({ isKube = false, device, namespace }) {
  const { query } = $tabs.getTabs().IPS;

  if (isKube) {
    return addKubeFilter(query, { device, namespace });
  }

  return query;
}

export function getASNQuery({
  type,
  site,
  device,
  cloud = 'nocloud',
  cloudRegion,
  cloudVpc,
  isKube = false,
  namespace,
  excludeInternalASNs = false,
  excludePrivateASNs = true
}) {
  const tabs = $tabs.getTabs(cloud);
  const { query } = tabs[type];
  let asnQuery = cloneDeep(query);

  if (site) {
    asnQuery = {
      ...query,
      all_devices: false,
      device_sites: [site]
    };
  }

  if (device) {
    asnQuery = {
      ...query,
      all_devices: false,
      device_name: device
    };
  }

  if (cloud && cloud !== 'nocloud') {
    if (cloudRegion) {
      asnQuery = addCloudRegionFilter(asnQuery, cloud, cloudRegion);
    }

    if (cloudVpc) {
      asnQuery = addCloudVpcFilter(asnQuery, cloud, cloudVpc);
    }
  }

  if (isKube) {
    asnQuery = addKubeFilter(asnQuery, { device, namespace });
  }

  if (type === 'ASNS' && excludeInternalASNs === true) {
    asnQuery = excludeInternalASNsFilter(asnQuery);
  }

  if (type === 'ASNS' && excludePrivateASNs === true) {
    asnQuery = excludePrivateASNsFilter(asnQuery);
  }

  return asnQuery;
}

function getBaseBoxLinkQuery() {
  return {
    aggregateTypes: ['avg_bits_per_sec'],
    topx: 0,
    show_overlay: false,
    show_total_overlay: false,
    customAsGroups: false
  };
}

function getBaseBoxLinkCloudQuery(dir = 'origination|termination') {
  const query = getBaseBoxLinkQuery();
  const filters = addFilterGroup(getDefaultFiltersObject(), 'Any', true, [
    {
      filterField: `i_trf_${dir}`,
      operator: '=',
      filterValue: 'inside'
    },
    {
      filterField: `i_trf_${dir}`,
      operator: '=',
      filterValue: 'outside'
    }
  ]);

  return {
    ...query,
    filters
  };
}

export function getMultiCloudLinkQueries({
  cloud,
  awsRegion,
  awsVpc,
  awsSubnet,
  azureRegion,
  azureSubnet,
  azureVpc,
  gcpRegion,
  gcpSubnet
}) {
  const query = getBaseBoxLinkCloudQuery();
  const { filters } = query;

  let fromOtherCloudToThisCloudLinkQuery = {
    ...query,
    filters: addFilterGroup(cloneDeep(filters), 'All', false, [
      {
        filterField: 'i_trf_profile',
        operator: '=',
        filterValue: 'multi-cloud'
      }
    ])
  };

  let fromThisCloudToOtherCloudLinkQuery = {
    ...query,
    filters: addFilterGroup(cloneDeep(filters), 'All', false, [
      {
        filterField: 'i_trf_profile',
        operator: '=',
        filterValue: 'multi-cloud'
      }
    ])
  };

  if (cloud) {
    fromOtherCloudToThisCloudLinkQuery = addCloudFilter(fromOtherCloudToThisCloudLinkQuery, cloud, 'origination');
    fromThisCloudToOtherCloudLinkQuery = addCloudFilter(fromThisCloudToOtherCloudLinkQuery, cloud, 'termination');
  }

  if (azureRegion) {
    fromThisCloudToOtherCloudLinkQuery = addCloudRegionFilter(
      fromThisCloudToOtherCloudLinkQuery,
      'azure',
      azureRegion,
      'src'
    );
    fromOtherCloudToThisCloudLinkQuery = addCloudRegionFilter(
      fromOtherCloudToThisCloudLinkQuery,
      'azure',
      azureRegion,
      'dst'
    );
  }

  if (awsRegion) {
    fromThisCloudToOtherCloudLinkQuery = addCloudRegionFilter(
      fromThisCloudToOtherCloudLinkQuery,
      'aws',
      awsRegion,
      'src'
    );
    fromOtherCloudToThisCloudLinkQuery = addCloudRegionFilter(
      fromOtherCloudToThisCloudLinkQuery,
      'aws',
      awsRegion,
      'dst'
    );
  }

  if (gcpRegion) {
    fromThisCloudToOtherCloudLinkQuery = addCloudRegionFilter(
      fromThisCloudToOtherCloudLinkQuery,
      'gcp',
      gcpRegion,
      'src'
    );
    fromOtherCloudToThisCloudLinkQuery = addCloudRegionFilter(
      fromOtherCloudToThisCloudLinkQuery,
      'gcp',
      gcpRegion,
      'dst'
    );
  }

  if (awsVpc) {
    fromThisCloudToOtherCloudLinkQuery = addCloudVpcFilter(fromThisCloudToOtherCloudLinkQuery, 'aws', awsVpc, 'src');
    fromOtherCloudToThisCloudLinkQuery = addCloudVpcFilter(fromOtherCloudToThisCloudLinkQuery, 'aws', awsVpc, 'dst');
  }

  if (azureVpc) {
    fromThisCloudToOtherCloudLinkQuery = addCloudVpcFilter(
      fromThisCloudToOtherCloudLinkQuery,
      'azure',
      azureVpc,
      'src'
    );
    fromOtherCloudToThisCloudLinkQuery = addCloudVpcFilter(
      fromOtherCloudToThisCloudLinkQuery,
      'azure',
      azureVpc,
      'dst'
    );
  }

  if (awsSubnet) {
    fromThisCloudToOtherCloudLinkQuery = addCloudSubnetFilter(
      fromThisCloudToOtherCloudLinkQuery,
      'aws',
      awsSubnet,
      'src'
    );
    fromOtherCloudToThisCloudLinkQuery = addCloudSubnetFilter(
      fromOtherCloudToThisCloudLinkQuery,
      'aws',
      awsSubnet,
      'dst'
    );
  }

  if (azureSubnet) {
    fromThisCloudToOtherCloudLinkQuery = addCloudSubnetFilter(
      fromThisCloudToOtherCloudLinkQuery,
      'azure',
      azureSubnet,
      'src'
    );
    fromOtherCloudToThisCloudLinkQuery = addCloudSubnetFilter(
      fromOtherCloudToThisCloudLinkQuery,
      'azure',
      azureSubnet,
      'dst'
    );
  }

  if (gcpSubnet) {
    fromThisCloudToOtherCloudLinkQuery = addCloudSubnetFilter(
      fromThisCloudToOtherCloudLinkQuery,
      'gcp',
      gcpSubnet,
      'src'
    );
    fromOtherCloudToThisCloudLinkQuery = addCloudSubnetFilter(
      fromOtherCloudToThisCloudLinkQuery,
      'gcp',
      gcpSubnet,
      'dst'
    );
  }

  return {
    fromThisCloudToOtherCloudLinkQuery,
    fromOtherCloudToThisCloudLinkQuery
  };
}

export function getCloudMyNetworkLinkQueries({
  cloud,
  awsRegion,
  awsVpc,
  awsSubnet,
  azureRegion,
  azureSubnet,
  azureVpc,
  gcpRegion,
  gcpSubnet,
  site,
  device,
  intf,
  sites,
  device_types
}) {
  let fromCloudToMyNetworkLinkQuery = getBaseBoxLinkCloudQuery('origination');

  let fromMyNetworkToCloudLinkQuery = getBaseBoxLinkCloudQuery('termination');

  if (Array.isArray(sites)) {
    fromCloudToMyNetworkLinkQuery.all_devices = false;
    fromMyNetworkToCloudLinkQuery.all_devices = false;
    fromCloudToMyNetworkLinkQuery.device_sites = sites;
    fromMyNetworkToCloudLinkQuery.device_sites = sites;
  }

  if (Array.isArray(device_types)) {
    fromCloudToMyNetworkLinkQuery.all_devices = false;
    fromMyNetworkToCloudLinkQuery.all_devices = false;
    fromCloudToMyNetworkLinkQuery.device_types = device_types;
    fromMyNetworkToCloudLinkQuery.device_types = device_types;
  }

  if (cloud) {
    fromCloudToMyNetworkLinkQuery = addCloudFilter(fromCloudToMyNetworkLinkQuery, cloud, 'origination');
    fromMyNetworkToCloudLinkQuery = addCloudFilter(fromMyNetworkToCloudLinkQuery, cloud, 'termination');
  }

  if (awsRegion) {
    fromCloudToMyNetworkLinkQuery = addCloudRegionFilter(fromCloudToMyNetworkLinkQuery, 'aws', awsRegion, 'src');
    fromMyNetworkToCloudLinkQuery = addCloudRegionFilter(fromMyNetworkToCloudLinkQuery, 'aws', awsRegion, 'dst');
  }

  if (azureRegion) {
    fromCloudToMyNetworkLinkQuery = addCloudRegionFilter(fromCloudToMyNetworkLinkQuery, 'azure', azureRegion, 'src');
    fromMyNetworkToCloudLinkQuery = addCloudRegionFilter(fromMyNetworkToCloudLinkQuery, 'azure', azureRegion, 'dst');
  }

  if (gcpRegion) {
    fromCloudToMyNetworkLinkQuery = addCloudRegionFilter(fromCloudToMyNetworkLinkQuery, 'gcp', gcpRegion, 'src');
    fromMyNetworkToCloudLinkQuery = addCloudRegionFilter(fromMyNetworkToCloudLinkQuery, 'gcp', gcpRegion, 'dst');
  }

  if (awsVpc) {
    fromCloudToMyNetworkLinkQuery = addCloudVpcFilter(fromCloudToMyNetworkLinkQuery, 'aws', awsVpc, 'src');
    fromMyNetworkToCloudLinkQuery = addCloudVpcFilter(fromMyNetworkToCloudLinkQuery, 'aws', awsVpc, 'dst');
  }

  if (azureVpc) {
    fromCloudToMyNetworkLinkQuery = addCloudVpcFilter(fromCloudToMyNetworkLinkQuery, 'azure', azureVpc, 'src');
    fromMyNetworkToCloudLinkQuery = addCloudVpcFilter(fromMyNetworkToCloudLinkQuery, 'azure', azureVpc, 'dst');
  }

  if (awsSubnet) {
    fromCloudToMyNetworkLinkQuery = addCloudSubnetFilter(fromCloudToMyNetworkLinkQuery, 'aws', awsSubnet, 'src');
    fromMyNetworkToCloudLinkQuery = addCloudSubnetFilter(fromMyNetworkToCloudLinkQuery, 'aws', awsSubnet, 'dst');
  }

  if (azureSubnet) {
    fromCloudToMyNetworkLinkQuery = addCloudSubnetFilter(fromCloudToMyNetworkLinkQuery, 'azure', azureSubnet, 'src');
    fromMyNetworkToCloudLinkQuery = addCloudSubnetFilter(fromMyNetworkToCloudLinkQuery, 'azure', azureSubnet, 'dst');
  }

  if (gcpSubnet) {
    fromCloudToMyNetworkLinkQuery = addCloudSubnetFilter(fromCloudToMyNetworkLinkQuery, 'gcp', gcpSubnet, 'src');
    fromMyNetworkToCloudLinkQuery = addCloudSubnetFilter(fromMyNetworkToCloudLinkQuery, 'gcp', gcpSubnet, 'dst');
  }

  if (site) {
    fromCloudToMyNetworkLinkQuery = addSiteFilter(fromCloudToMyNetworkLinkQuery, site, 'dst');
    fromMyNetworkToCloudLinkQuery = addSiteFilter(fromMyNetworkToCloudLinkQuery, site, 'src');
  }

  if (device) {
    fromCloudToMyNetworkLinkQuery = addDeviceFilter(fromCloudToMyNetworkLinkQuery, device);
    fromMyNetworkToCloudLinkQuery = addDeviceFilter(fromMyNetworkToCloudLinkQuery, device);
  }

  if (intf) {
    fromCloudToMyNetworkLinkQuery = addInterfaceFilter(fromCloudToMyNetworkLinkQuery, intf);
    fromMyNetworkToCloudLinkQuery = addInterfaceFilter(fromMyNetworkToCloudLinkQuery, intf);
  }

  return {
    fromCloudToMyNetworkLinkQuery,
    fromMyNetworkToCloudLinkQuery
  };
}

export function getCloudInternetLinkQueries({
  cloud,
  awsRegion,
  awsVpc,
  awsSubnet,
  azureRegion,
  azureSubnet,
  azureVpc,
  gcpRegion,
  gcpSubnet,
  asn,
  provider,
  nextHopASN
}) {
  let fromCloudToInternetLinkQuery = getBaseBoxLinkCloudQuery('origination');

  let fromInternetToCloudLinkQuery = getBaseBoxLinkCloudQuery('termination');

  if (cloud) {
    fromCloudToInternetLinkQuery = addCloudFilter(fromCloudToInternetLinkQuery, cloud, 'origination');
    fromInternetToCloudLinkQuery = addCloudFilter(fromInternetToCloudLinkQuery, cloud, 'termination');
  }

  if (awsRegion) {
    fromCloudToInternetLinkQuery = addCloudRegionFilter(fromCloudToInternetLinkQuery, 'aws', awsRegion, 'src');
    fromInternetToCloudLinkQuery = addCloudRegionFilter(fromInternetToCloudLinkQuery, 'aws', awsRegion, 'dst');
  }

  if (azureRegion) {
    fromCloudToInternetLinkQuery = addCloudRegionFilter(fromCloudToInternetLinkQuery, 'azure', azureRegion, 'src');
    fromInternetToCloudLinkQuery = addCloudRegionFilter(fromInternetToCloudLinkQuery, 'azure', azureRegion, 'dst');
  }

  if (gcpRegion) {
    fromCloudToInternetLinkQuery = addCloudRegionFilter(fromCloudToInternetLinkQuery, 'gcp', gcpRegion, 'src');
    fromInternetToCloudLinkQuery = addCloudRegionFilter(fromInternetToCloudLinkQuery, 'gcp', gcpRegion, 'dst');
  }

  if (awsVpc) {
    fromCloudToInternetLinkQuery = addCloudVpcFilter(fromCloudToInternetLinkQuery, 'aws', awsVpc, 'src');
    fromInternetToCloudLinkQuery = addCloudVpcFilter(fromInternetToCloudLinkQuery, 'aws', awsVpc, 'dst');
  }

  if (azureVpc) {
    fromCloudToInternetLinkQuery = addCloudVpcFilter(fromCloudToInternetLinkQuery, 'azure', azureVpc, 'src');
    fromInternetToCloudLinkQuery = addCloudVpcFilter(fromInternetToCloudLinkQuery, 'azure', azureVpc, 'dst');
  }

  if (awsSubnet) {
    fromCloudToInternetLinkQuery = addCloudSubnetFilter(fromCloudToInternetLinkQuery, 'aws', awsSubnet, 'src');
    fromInternetToCloudLinkQuery = addCloudSubnetFilter(fromInternetToCloudLinkQuery, 'aws', awsSubnet, 'dst');
  }

  if (azureSubnet) {
    fromCloudToInternetLinkQuery = addCloudSubnetFilter(fromCloudToInternetLinkQuery, 'azure', azureSubnet, 'src');
    fromInternetToCloudLinkQuery = addCloudSubnetFilter(fromInternetToCloudLinkQuery, 'azure', azureSubnet, 'dst');
  }

  if (gcpSubnet) {
    fromCloudToInternetLinkQuery = addCloudSubnetFilter(fromCloudToInternetLinkQuery, 'gcp', gcpSubnet, 'src');
    fromInternetToCloudLinkQuery = addCloudSubnetFilter(fromInternetToCloudLinkQuery, 'gcp', gcpSubnet, 'dst');
  }

  if (asn) {
    fromCloudToInternetLinkQuery = addOriginAsnFilter(fromCloudToInternetLinkQuery, asn, 'dst');
    fromInternetToCloudLinkQuery = addOriginAsnFilter(fromInternetToCloudLinkQuery, asn, 'src');
  }

  if (provider) {
    fromCloudToInternetLinkQuery = addProviderFilter(fromCloudToInternetLinkQuery, provider, 'dst');
    fromInternetToCloudLinkQuery = addProviderFilter(fromInternetToCloudLinkQuery, provider, 'src');
  }

  if (nextHopASN) {
    fromCloudToInternetLinkQuery = addNextHopAsnFilter(fromCloudToInternetLinkQuery, nextHopASN, 'dst');
    fromInternetToCloudLinkQuery = addNextHopAsnFilter(fromInternetToCloudLinkQuery, nextHopASN, 'src');
  }

  return {
    fromCloudToInternetLinkQuery,
    fromInternetToCloudLinkQuery
  };
}

export function getInternetMyNetworkLinkQuery({ site, device, cloudProvider }) {
  let query = {
    ...getBaseBoxLinkQuery(),
    metric: [
      'i_src_connect_type_name',
      'i_src_network_bndry_name',
      'i_dst_connect_type_name',
      'i_dst_network_bndry_name',
      'i_trf_profile'
    ],
    aggregateTypes: ['agg_total_bytes'],
    viz_type: 'table',
    topx: 250
  };

  if (site) {
    query = addSiteFilter(query, site);
  }

  if (device) {
    query = addDeviceFilter(query, device);
  }

  if (cloudProvider) {
    query = addCloudFilter(query, cloudProvider);
  }

  return query;
}

// this query should tell us about ALL of the traffic between the Clusters and the Internet, Clusters and OnPrem.
// Top Device, Traffic Origination, Traffic Termination
// eslint-disable-next-line no-unused-vars
export function getAllClusterTrafficLinkQuery(props) {
  // console.log('getOnPremClustersLinkQuery', props);

  const query = {
    ...getBaseBoxLinkQuery(),
    aggregateTypes: ['agg_total_bytes'],
    all_devices: false,
    metric: ['i_device_id', 'i_trf_origination', 'i_trf_termination'],
    device_types: ['kappa'],
    viz_type: 'table',
    topx: 250
  };

  return query;
}

export function getInternetMyNetworkLinkQueries({ site, device, intf, asn, provider, nextHopASN, sites }) {
  const query = getBaseBoxLinkQuery();

  let fromInternetToMyNetworkLinkQuery = {
    ...query
  };

  let fromMyNetworkToInternetLinkQuery = {
    ...query
  };

  if (Array.isArray(sites)) {
    fromInternetToMyNetworkLinkQuery.all_devices = false;
    fromMyNetworkToInternetLinkQuery.all_devices = false;
    fromInternetToMyNetworkLinkQuery.device_sites = sites;
    fromMyNetworkToInternetLinkQuery.device_sites = sites;
  }

  if (site) {
    fromInternetToMyNetworkLinkQuery = addSiteFilter(fromInternetToMyNetworkLinkQuery, site, 'dst');
    fromMyNetworkToInternetLinkQuery = addSiteFilter(fromMyNetworkToInternetLinkQuery, site, 'src');
  }

  if (device) {
    fromInternetToMyNetworkLinkQuery = addDeviceFilter(fromInternetToMyNetworkLinkQuery, device);
    fromMyNetworkToInternetLinkQuery = addDeviceFilter(fromMyNetworkToInternetLinkQuery, device);
  }

  if (intf) {
    fromInternetToMyNetworkLinkQuery = addInterfaceFilter(fromInternetToMyNetworkLinkQuery, intf);
    fromMyNetworkToInternetLinkQuery = addInterfaceFilter(fromMyNetworkToInternetLinkQuery, intf);
  }

  if (asn) {
    fromInternetToMyNetworkLinkQuery = addOriginAsnFilter(fromInternetToMyNetworkLinkQuery, asn, 'src');
    fromMyNetworkToInternetLinkQuery = addOriginAsnFilter(fromMyNetworkToInternetLinkQuery, asn, 'dst');
  }

  if (provider) {
    fromInternetToMyNetworkLinkQuery = addProviderFilter(fromInternetToMyNetworkLinkQuery, provider, 'src');
    fromMyNetworkToInternetLinkQuery = addProviderFilter(fromMyNetworkToInternetLinkQuery, provider, 'dst');
  }

  if (nextHopASN) {
    fromInternetToMyNetworkLinkQuery = addNextHopAsnFilter(fromInternetToMyNetworkLinkQuery, nextHopASN, 'src');
    fromMyNetworkToInternetLinkQuery = addNextHopAsnFilter(fromMyNetworkToInternetLinkQuery, nextHopASN, 'dst');
  }

  return {
    fromInternetToMyNetworkLinkQuery,
    fromMyNetworkToInternetLinkQuery
  };
}

export function getSiteDeviceLinkQueries({ inboundSiteInterfaces, outboundSiteInterfaces }) {
  const query = getBaseBoxLinkQuery();
  const inboundFilters = getInterfacesFilterGroup({ interfaces: inboundSiteInterfaces, direction: 'input' });
  const outboundFilters = getInterfacesFilterGroup({ interfaces: outboundSiteInterfaces, direction: 'input' });

  const inboundSiteDeviceLinkQuery = {
    ...query,
    filters: inboundFilters
  };

  const outboundSiteDeviceLinkQuery = {
    ...query,
    filters: outboundFilters
  };

  return { inboundSiteDeviceLinkQuery, outboundSiteDeviceLinkQuery };
}
