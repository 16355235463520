import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { get } from 'lodash';

import { Box, Flex, Link, Tag, Text, Spinner, Suspense } from 'core/components';
import AsnLink from 'app/components/links/AsnLink';

@inject('$networkBi', '$networkClass')
class IpDetailsInfoBlock extends Component {
  state = {
    ipData: {},
    loadingNetworkClassification: true
  };

  componentDidMount() {
    this.fetchIpData();
  }

  componentDidUpdate(prevProps) {
    const { ip_address } = this.props;
    if (ip_address !== prevProps.ip_address) {
      this.fetchIpData();
    }
  }

  get hasMask() {
    const { ip_address, cidr } = this.props;
    return cidr && ((ip_address.includes('.') && cidr < 32) || (ip_address.includes(':') && cidr < 128));
  }

  fetchIpData() {
    const { $networkBi, $networkClass, ip_address, cidr } = this.props;
    const ip = ip_address + (cidr ? `/${cidr}` : '');

    $networkBi.fetchIpData(ip).then((ipData) => {
      this.setState({ ipData });
    });

    $networkClass.model.fetch().then(() => this.setState({ loadingNetworkClassification: false }));
  }

  renderAsn() {
    const { ipData } = this.state;

    const as = get(ipData, 'asn.id');
    const asName = get(ipData, 'asn.name');

    if (!as) {
      return (
        <Text as="p" muted mb={2}>
          AS Unknown
        </Text>
      );
    }

    return (
      <Box mb={2}>
        <Text as="div" fontWeight="bold" pb="4px">
          AS Details
        </Text>
        <AsnLink asn={as}>
          <Flex gap="4px" alignItems="flex-end">
            <Tag intent="primary" round>
              {`AS${as}`}
            </Tag>
            <Text>{asName}</Text>
          </Flex>
        </AsnLink>
      </Box>
    );
  }

  renderGeo() {
    const { ipData } = this.state;

    const city = get(ipData, 'geo.city.name', '-');
    const region = get(ipData, 'geo.region.name', '-');
    const country = get(ipData, 'geo.country.name');

    if (city === '-' || region === '-') {
      return (
        <Text as="p" muted mb={2}>
          Location Unknown
        </Text>
      );
    }

    return (
      <Box mb={2}>
        <Text as="div" fontWeight="bold" pb="4px">
          Location
        </Text>
        <Text as="p">
          {city}, {region} <br />
          {country}
        </Text>
      </Box>
    );
  }

  renderNetworkClassification() {
    const { $networkClass, ip_address } = this.props;
    const { loadingNetworkClassification, ipData } = this.state;
    const asn = get(ipData, 'asn.id');
    const classification = $networkClass.getIPClassification(ip_address, asn);

    return (
      <Box>
        <Text as="div" fontWeight="bold" pb="4px">
          Network Classification
        </Text>
        <Suspense
          loading={loadingNetworkClassification}
          fallback={
            <Flex pl={4}>
              <Spinner size={24} />
            </Flex>
          }
        >
          <Link to="/v4/settings/network-classification">{classification}</Link>
        </Suspense>
      </Box>
    );
  }

  render() {
    const { ipData } = this.state;
    const { cdns, threats } = ipData;
    const { hasMask } = this;

    return (
      <Box>
        {this.renderAsn()}
        {this.renderGeo()}

        {cdns && !hasMask && (
          <Box mb={2}>
            <Text as="div" fontWeight="bold" pb="4px">
              CDNs
            </Text>
            <Text as="p" mb={0}>
              {cdns.length !== 0 && cdns.map(({ name }) => name).join(', ')}
              {cdns.length === 0 && 'No associated CDNs'}
            </Text>
          </Box>
        )}

        {threats && !hasMask && (
          <Box mb={2}>
            <Text as="div" fontWeight="bold" pb="4px">
              Threat Feeds
            </Text>
            <Text as="p" mb={0}>
              {threats.controlling.length === 0 && threats.infected.length === 0 && 'Not on threat feeds'}
              {threats.controlling.length > 0 && `Controlling: ${threats.controlling.join(', ')}`}
              {threats.infected.length > 0 && `Infected: ${threats.infected.join(', ')}`}
            </Text>
          </Box>
        )}

        {this.renderNetworkClassification()}
      </Box>
    );
  }
}

export default IpDetailsInfoBlock;
