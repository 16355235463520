import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Card } from 'core/components';
import VerticalDetailTabs from 'app/components/detailTabs/VerticalDetailTabs';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import Page from 'app/components/page/Page';
import InsightsMenu from 'app/components/navbar/InsightsMenu';

import AggregateViewHeader from '../aggregate/AggregateViewHeader';
import parentLinks from '../NetworkExplorerParentLink';

@inject('$lookups', '$tabs')
@observer
class NextHopAsnDetails extends Component {
  constructor(props) {
    super(props);

    const { $tabs } = props;
    const tabs = $tabs.getTabs();

    this.state.tabBlacklist = [tabs.NEXTHOP_ASNS.id];
  }

  state = {
    filters: [],
    nextHopAsnDetails: undefined,
    lookback_seconds: 86400,
    aggregateType: 'p95th_bits_per_sec'
  };

  componentDidMount() {
    this.fetchAsDetails();
  }

  componentDidUpdate(prevProps) {
    if (this.nextHopAsn !== prevProps.match.params.nextHopAsn) {
      this.fetchAsDetails();
    }
  }

  fetchAsDetails() {
    const { $lookups } = this.props;
    $lookups.asNumbers(this.nextHopAsn).then((results) => {
      const nextHopAsnDetails = results.find((result) => result.value === this.nextHopAsn);
      this.setState({ nextHopAsnDetails: nextHopAsnDetails ? nextHopAsnDetails.matcher : null });
    });
  }

  get nextHopAsn() {
    const { match } = this.props;
    return match.params.nextHopAsn;
  }

  get filters() {
    const { filters } = this.state;
    const detailFilter = {
      filterField: 'next_hop_asn',
      filterValue: this.nextHopAsn
    };

    return filters.concat(detailFilter);
  }

  handleFiltersApply = (filters) => {
    this.setState({ filters });
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds });
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType });
  };

  render() {
    const { nextHopAsnDetails, aggregateType, filters, lookback_seconds, tabBlacklist } = this.state;
    const title = nextHopAsnDetails ? `${nextHopAsnDetails} (${this.nextHopAsn})` : this.nextHopAsn;

    return (
      <Page
        title={title}
        parentLinks={[...parentLinks, { link: '/v4/core/quick-views/nextHopAsn', label: 'Next Hop ASNs' }]}
        insightsMenu={<InsightsMenu query="fetchAsnInsights" params={this.nextHopAsn} />}
        scrolls
        showExport
      >
        <AggregateViewHeader
          title={title}
          aggregateType={aggregateType}
          filters={filters}
          lookbackSeconds={lookback_seconds}
          onFiltersChange={this.handleFiltersApply}
          onAggregateChange={this.handleAggregationChange}
          onMetricsChange={this.handleAggregationChange}
          onTimeRangeChange={this.handleLookbackChange}
        />
        <Card mb={2}>
          <TabbedChart overrides={{ lookback_seconds, aggregateTypes: [aggregateType] }} simpleFilters={this.filters} />
        </Card>

        <VerticalDetailTabs
          blacklist={tabBlacklist}
          simpleFilters={this.filters}
          queryOverrides={{ lookback_seconds, aggregateTypes: [aggregateType] }}
        />
      </Page>
    );
  }
}

export default NextHopAsnDetails;
