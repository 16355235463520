import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, Text } from 'core/components';
import DimensionToLink from 'app/components/links/DimensionToLink';
import Tooltip from 'core/components/Tooltip';
import ProtocolRenderer from './ProtocolRenderer';

@inject('$alerting', '$metrics')
@observer
export default class TargetRenderer extends Component {
  static defaultProps = {
    showAll: false
  };

  renderIP = (target) => {
    const { IP_dst, Port_src, Proto } = target;

    if (Port_src) {
      return `${IP_dst}:${Port_src}`;
    }

    if (Proto) {
      return (
        <ProtocolRenderer protocol={Proto}>
          {(config) => {
            if (config) {
              return `${IP_dst} ${config.matcher} (${config.value})`;
            }

            return null;
          }}
        </ProtocolRenderer>
      );
    }

    return IP_dst;
  };

  getMetricDimension = (dimensionKey, triggerMeasurement) => {
    const { model, $metrics } = this.props;
    const measurement = triggerMeasurement || model.policyMeasurement?.measurement;

    if (measurement) {
      const measurementModel = $metrics.measurementModel(measurement);
      const appProtocolPrefix = $metrics.getAppProtocolDimension(measurement);
      const nmsKey = dimensionKey.replace(appProtocolPrefix, '');
      if (measurementModel) {
        const { Label, name } = measurementModel.mergedMetricsDimensions[nmsKey] || {};

        return { label: Label || name, name };
      }

      return { label: dimensionKey, name: dimensionKey };
    }

    return { label: dimensionKey, name: dimensionKey };
  };

  renderItem = (dimensionEntry) => {
    const { $alerting, model, inline } = this.props;
    const { label, name, value } = dimensionEntry || {};
    let dimensionValue = value === '' ? '<empty>' : value;
    let dimensionToValue = value;

    const alarm = model.get();

    if (model.isMetricPolicy) {
      dimensionToValue = { [name]: dimensionValue };
    } else {
      dimensionValue = $alerting.lookupValue(model.get(), name, value, model);
    }

    return (
      <Flex key={name || value} flexDirection={inline ? 'column' : 'row'} maxWidth="100%">
        <Text pr="4px" muted ellipsis>
          {label}:
        </Text>
        <Text ellipsis maxWidth="100%">
          <DimensionToLink
            dimension={name}
            dimensionToValue={dimensionToValue}
            dimensionToKeyDetail={alarm.dimensionToKeyDetail}
            textOverflow="ellipsis"
          >
            {dimensionValue}
          </DimensionToLink>
        </Text>
      </Flex>
    );
  };

  renderDimensions = (dimensionMap, countIpDimension) => {
    const { model, showAll, $alerting } = this.props;
    const dimensionKeys = Object.keys(dimensionMap);
    const measurement = model.get('measurement');
    const maxVisibleDimensions = countIpDimension ? 2 : 3;
    const dimensionsToShow =
      dimensionKeys.length <= maxVisibleDimensions ? maxVisibleDimensions : maxVisibleDimensions - 1;

    let dimensionEntries = dimensionKeys.map((key) => {
      const entry = { value: dimensionMap[key], name: key, order: 9999 };

      if (model.isMetricPolicy && measurement) {
        const { label, name } = this.getMetricDimension(key, measurement);
        const indexOrder = model.policyObject.dimensions.indexOf(name);
        entry.label = label;
        entry.name = name;
        entry.kmetrics = key;
        if (indexOrder > -1) {
          entry.order = indexOrder;
        }
      } else {
        entry.label = key === 'Traffic' ? 'Traffic' : `${$alerting.getDimensionLabel(key)}`;
      }

      return entry;
    });

    if (model.isMetricPolicy) {
      dimensionEntries = dimensionEntries.sort((a, b) => a.order - b.order);
    }

    if (showAll) {
      return dimensionEntries.map(this.renderItem);
    }

    return (
      <>
        {dimensionEntries.slice(0, dimensionsToShow).map(this.renderItem)}
        {dimensionEntries.length > maxVisibleDimensions && (
          <Tooltip content={dimensionEntries.slice(dimensionsToShow).map(this.renderItem)}>
            <Text as="div" muted small>
              +{dimensionEntries.length - dimensionsToShow} more
            </Text>
          </Tooltip>
        )}
      </>
    );
  };

  render() {
    const { model, inline } = this.props;
    const dimensionToKeyPart = model.get('dimensionToKeyPart');
    const { IP_dst, Port_src, Proto, ...otherDimensions } = dimensionToKeyPart;
    const hasDimensions = Object.keys(dimensionToKeyPart).length > 0;

    if (model.isNmsPolicy) {
      return null;
    }

    if (!IP_dst && !hasDimensions) {
      return <Text as="div">Target not found</Text>;
    }

    const contents = (
      <>
        {IP_dst && (
          <Flex maxWidth="100%">
            <Text pr="4px" muted>
              Dest IP:
            </Text>
            <Text maxWidth="100%">{this.renderIP(dimensionToKeyPart)}</Text>
          </Flex>
        )}
        {this.renderDimensions(otherDimensions, IP_dst)}
      </>
    );

    if (inline) {
      return contents;
    }

    return (
      <Flex flexDirection="column" maxWidth="100%">
        {contents}
      </Flex>
    );
  }
}
