import React, { Component } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { ButtonGroup } from 'core/components';
import withUrlPathTabs from 'core/components/withUrlPathTabs';

const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 3;
  margin-bottom: 16px;
  ${space}

  &::after {
    content: ' ';
    border-bottom: ${(props) => props.theme.borders.thin};
    display: 'block';
    height: 1px;
    position: absolute;
    top: calc(50% - 1px);
    width: 100%;
    z-index: 2;
  }
`;

@withUrlPathTabs
class ButtonGroupTabs extends Component {
  static getDerivedStateFromProps({ selectedTabId }) {
    if (selectedTabId !== undefined) {
      // keep state in sync with controlled prop, so state is canonical source of truth
      return { selectedTabId };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const selectedTabId = this.getInitialSelectedTabId();
    this.state = { selectedTabId };
  }

  getInitialSelectedTabId() {
    const { defaultSelectedTabId, selectedTabId, children } = this.props;

    if (selectedTabId !== undefined) {
      return selectedTabId;
    }

    if (defaultSelectedTabId !== undefined) {
      return defaultSelectedTabId;
    }

    const tabs = React.Children.toArray(children);
    return tabs.length === 0 ? undefined : tabs[0].props.id;
  }

  handleTabClick = (newTabId) => {
    const { onChange, selectedTabId } = this.props;
    if (onChange) {
      onChange(newTabId);
    }

    if (selectedTabId === undefined) {
      this.setState({ selectedTabId: newTabId });
    }
  };

  renderButtonGroupComp = (children, selectedTabId) => (
    <ButtonGroup>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          selectedTabId,
          onClick: this.handleTabClick
        })
      )}
    </ButtonGroup>
  );

  render() {
    const { selectedTabId } = this.state;
    const { children, omitTabsWrapper = false, ...rest } = this.props;

    const tabs = React.Children.toArray(children);
    const activeTab = tabs.find((tab) => tab.props.id === selectedTabId);

    if (omitTabsWrapper) {
      return (
        <>
          {this.renderButtonGroupComp(children, selectedTabId)}
          {activeTab && activeTab.props.panel}
        </>
      );
    }

    return (
      <>
        <TabsWrapper {...rest}>{this.renderButtonGroupComp(children, selectedTabId)}</TabsWrapper>
        {activeTab && activeTab.props.panel}
      </>
    );
  }
}

export default ButtonGroupTabs;
