import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Text } from 'core/components';
import { Field, Select } from 'core/form';

const selectValueRenderer = (option, placeholder) => {
  if (!option) {
    return <Text muted>{placeholder || 'Select a value...'}</Text>;
  }

  return (
    <span>
      Match <Text fontWeight="bold">{option.label}</Text> of the following groups
    </span>
  );
};

@observer
class FilterGroupConnector extends Component {
  render() {
    const { readOnly, connector } = this.props;
    const connectorValue = connector.getValue();

    if (readOnly) {
      return (
        <Text muted fontSize="small">
          <Text fontWeight="bold">{connectorValue}</Text> of the following
        </Text>
      );
    }

    return (
      <Field field={connector} showLabel={false} mb={0} mx="4px" small>
        <Select menuWidth={225} valueRenderer={selectValueRenderer} buttonStyle={{ width: 225 }} />
      </Field>
    );
  }
}

export default FilterGroupConnector;
