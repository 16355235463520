import React from 'react';

import { Button, Text, Flex, Tag } from 'core/components';

const intentMap = {
  Destination: 'primary',
  Source: 'warning',
  'Source or Dest': 'danger',
  Full: 'success'
};

const Group = ({ children }) => (
  <Tag bg="black.3" color="#ffffff" fontSize={10}>
    {children}
  </Tag>
);

function DimensionButton({ option, ...rest }) {
  const intent = option ? intentMap[option.group || 'Full'] : 'danger';
  const hasGroup = option && option.group && option.group !== 'none';

  const text = option ? (
    <Flex alignItems="center">
      <Text as="div">
        {hasGroup && <Group>{option.group}</Group>} {option.tagLabel || option.label}
      </Text>
    </Flex>
  ) : (
    'Invalid Selection'
  );

  return <Button intent={intent} text={text} pl={hasGroup ? '2px' : undefined} {...rest} />;
}

export default DimensionButton;
