import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('ApplicationAggregate'))
class ApplicationAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="Applications"
        resultsKey="Application"
        insightsQuery="fetchApplicationInsights"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'Application',
          metric: ['application']
        }}
        chartProps={{
          metrics: {
            Internal: 'application',
            Inbound: 'application',
            Outbound: 'application'
          }
        }}
      />
    );
  }
}

export default ApplicationAggregate;
