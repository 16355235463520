import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Tag } from 'core/components';

@observer
class SelectedValueButtonCompact extends Component {
  static defaultProps = {
    round: true
  };

  handleRemove = (e) => {
    const { onRemove, option, field } = this.props;
    e.preventDefault();
    e.stopPropagation();
    onRemove(option, field);
  };

  render() {
    const {
      disabled,
      option,
      label: propLabel,
      icon: propIcon,
      intent = 'none',
      multiline,
      onRemove,
      round,
      isLast,
      ...rest
    } = this.props;
    const { label: optionLabel, iconName: optionIcon } = option || {};
    const iconName = propIcon || optionIcon;
    const label = propLabel || optionLabel;

    return (
      <Tag
        outline
        minimal
        icon={iconName}
        intent={intent}
        fill
        multiline={multiline}
        round={round}
        onRemove={onRemove && !disabled ? this.handleRemove : undefined}
        mb={isLast ? 0 : '4px'}
        p="3px 6px 3px 8px"
        {...rest}
      >
        {label}
      </Tag>
    );
  }
}

export default SelectedValueButtonCompact;
