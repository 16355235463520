import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Box, Popover, Button, Text } from 'core/components';
import { getFilterCount } from 'core/util/filters';
import FiltersDisplay from 'app/components/filters/display/FiltersDisplay';
import { computed } from 'mobx';

@inject('$metrics')
@observer
export default class MetricsExplorerAppliedFiltersButton extends Component {
  @computed
  get availableDimensionOptions() {
    const { query, $metrics } = this.props;
    return $metrics.getAvailableDimensionOptions(query.kmetrics.measurement);
  }

  @computed
  get availableMetricOptions() {
    const { query, $metrics } = this.props;
    return $metrics.metricFilterOptionsFromQuery(query);
  }

  render() {
    const { query } = this.props;

    if (!query) {
      return null;
    }

    const metricFilterCount = getFilterCount(query.kmetrics?.rollupFilters?.filterGroups);
    const dimensionFilterCount = getFilterCount(query.kmetrics?.filters?.filterGroups);
    const filterCount = metricFilterCount + dimensionFilterCount;

    if (filterCount === 0) {
      return null;
    }

    return (
      <Popover
        modifiers={{
          arrow: { enabled: true },
          flip: { enabled: true },
          keepTogether: { enabled: true },
          preventOverflow: { enabled: true, boundariesElement: 'window' }
        }}
        minimal={false}
      >
        <Button icon="filter" text={`${filterCount} Filter${filterCount > 1 ? 's' : ''}`} color="muted" minimal small />
        <Box p={1} maxHeight={450} maxWidth={600} overflow="auto">
          <Text fontWeight="bold" small>
            Applied Filters:
          </Text>
          <br />
          {dimensionFilterCount > 0 ? (
            <FiltersDisplay filters={query?.kmetrics?.filters} useNmsDimensions={this.availableDimensionOptions} />
          ) : null}
          {metricFilterCount > 0 ? (
            <FiltersDisplay filters={query?.kmetrics?.rollupFilters} useNmsDimensions={this.availableMetricOptions} />
          ) : null}
        </Box>
      </Popover>
    );
  }
}
