import React from 'react';
import styled from 'styled-components';
import Box from './Box';

// rainbows, unicorns, and kittens
const CalloutWrapperBorder = styled(Box)`
  align-items: center;
  background: linear-gradient(166deg, #5b79ef 8.54%, #b157d3 26.88%, #e0455a 51.82%, #eb812f 81.9%);
  border-radius: 0px 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3px;
  position: relative;
  margin-top: 16px;

  &:before {
    display: block;
    content: '✨ Kentik AI';
    position: absolute;
    top: -19px;
    left: 0px;
    color: white;
    background: linear-gradient(90deg, rgb(164, 94, 216) 8.54%, 51.82%, rgb(91, 121, 239) 81.9%);
    font-size: 11px;
    font-weight: 700;
    border-radius: 4px 20px 0px 0px;
    padding: 4px 15px 1px 8px;
  }
`;

const CalloutInner = styled.div`
  align-items: flex-start;
  align-self: stretch;
  background: ${(props) => props.theme.colors.cardBackground};
  border-radius: 0px 10px 10px;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  gap: 10px;
  padding: 16px;
`;

const CalloutKentikAi = ({ children, ...rest }) => (
  <CalloutWrapperBorder {...rest}>
    <CalloutInner>{children}</CalloutInner>
  </CalloutWrapperBorder>
);

export default CalloutKentikAi;
