import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Dialog, Flex, Text, Tag } from 'core/components';

@observer
export default class AlertPolicyDialog extends Component {
  static defaultProps = {
    onClose: () => {},
    onSubmit: () => {},
    unsupportedDimensions: [],
    unsupportedMetrics: []
  };

  get dialogTitle() {
    if (this.requiresAction) {
      return 'Create Alert Policy (Action Required)';
    }

    return 'Create Alert Policy';
  }

  get hasMissingSelections() {
    const { dimensions, metrics } = this.props;
    return dimensions.length === 0 || metrics.length === 0;
  }

  get hasUnsupportedSelections() {
    const { unsupportedDimensions, unsupportedMetrics } = this.props;
    return unsupportedDimensions.length > 0 || unsupportedMetrics.length > 0;
  }

  get requiresAction() {
    return this.hasMissingSelections || this.hasUnsupportedSelections;
  }

  renderConfirmBody() {
    return <Text>Would you like to create a policy from your query?</Text>;
  }

  renderActionRequiredBody() {
    const { metrics, dimensions, unsupportedDimensions, unsupportedMetrics } = this.props;

    return (
      <>
        {metrics.length === 0 && (
          <Text>
            An alert policy requires at least <b>1</b> supported metric.{' '}
          </Text>
        )}
        {unsupportedMetrics.length > 0 && (
          <>
            <Text>The following metrics are unsupported on alert policies:</Text>

            <Flex gap={1} my={2} flexWrap="wrap">
              {unsupportedMetrics.map((metric) => (
                <Tag py="3px" key={metric.value} intent="danger" round minimal>
                  {metric.label}
                </Tag>
              ))}
            </Flex>
          </>
        )}

        {dimensions.length === 0 && (
          <Text>
            An alert policy requires at least <b>1</b> supported dimension.{' '}
          </Text>
        )}

        {unsupportedDimensions.length > 0 && (
          <>
            <Text>The following dimensions are unsupported on alert policies:</Text>
            <Flex gap={1} my={2} flexWrap="wrap">
              {unsupportedDimensions.map((dimension) => (
                <Tag py="3px" key={dimension.value} intent="danger" round minimal>
                  {dimension.label}
                </Tag>
              ))}
            </Flex>
          </>
        )}
        <Text as="div" mt={1}>
          Please update your selection
          {this.hasUnsupportedSelections && ', or create a policy omitting these selections'}.
        </Text>
      </>
    );
  }

  render() {
    const { onClose, onSubmit, isOpen } = this.props;

    return (
      <Dialog
        title={this.dialogTitle}
        isOpen={isOpen}
        onClose={onClose}
        canEscapeKeyClose
        canClickOutside
        isCloseButtonShown
      >
        <Dialog.Body>
          {this.requiresAction && this.renderActionRequiredBody()}
          {!this.requiresAction && this.renderConfirmBody()}
        </Dialog.Body>
        <Dialog.Footer>
          <Flex justifyContent="space-between">
            <Button text="Cancel" onClick={onClose} />
            <Button ml={1} text="Continue" intent="primary" onClick={onSubmit} disabled={this.requiresAction} />
          </Flex>
        </Dialog.Footer>
      </Dialog>
    );
  }
}
