import { baseFields } from './baseFields';
import { pingFields } from './pingFields';
import { flowBaseFields } from './flowBaseFields';

const asnOptions = {
  name: 'ASN Test'
};

const asnFields = {
  ...baseFields,
  ...pingFields,
  ...flowBaseFields
};

export { asnOptions, asnFields };
