import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Card, Heading } from 'core/components';
import VerticalDetailTabs from 'app/components/detailTabs/VerticalDetailTabs';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import ProviderTopology from 'app/views/topology/ProviderTopology';
import Page from 'app/components/page/Page';

import AggregateViewHeader from '../aggregate/AggregateViewHeader';
import parentLinks from '../NetworkExplorerParentLink';

@inject('$tabs')
@observer
class ProviderDetails extends Component {
  constructor(props) {
    super(props);

    const { $tabs } = props;
    const tabs = $tabs.getTabs();

    this.state.tabBlacklist = [tabs.PROVIDERS.id];
  }

  state = {
    filters: [],
    lookback_seconds: 86400,
    aggregateType: 'p95th_bits_per_sec'
  };

  get provider() {
    const { match } = this.props;
    return decodeURIComponent(match.params.provider);
  }

  get filters() {
    const { filters } = this.state;
    const detailFilter = {
      filterField: 'provider_classification',
      filterValue: this.provider
    };

    return filters.concat(detailFilter);
  }

  handleFiltersApply = (filters) => {
    this.setState({ filters });
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds });
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType });
  };

  render() {
    const { aggregateType, filters, lookback_seconds, tabBlacklist } = this.state;

    return (
      <Page
        title={this.provider}
        parentLinks={[...parentLinks, { link: '/v4/core/quick-views/provider', label: 'Providers' }]}
        scrolls
        showExport
      >
        <AggregateViewHeader
          title={this.provider}
          aggregateType={aggregateType}
          filters={filters}
          lookbackSeconds={lookback_seconds}
          onFiltersChange={this.handleFiltersApply}
          onAggregateChange={this.handleAggregationChange}
          onMetricsChange={this.handleAggregationChange}
          onTimeRangeChange={this.handleLookbackChange}
        />

        <Card mb={2}>
          <TabbedChart overrides={{ lookback_seconds, aggregateTypes: [aggregateType] }} simpleFilters={this.filters} />
        </Card>

        <Box my={4} className="break-before break-after">
          <Heading fontWeight="normal" level={4} mb={1}>
            Logical Map
          </Heading>
          <Card>
            <ProviderTopology provider={this.provider} embedded />
          </Card>
        </Box>

        <VerticalDetailTabs
          blacklist={tabBlacklist}
          simpleFilters={this.filters}
          queryOverrides={{ lookback_seconds, aggregateTypes: [aggregateType] }}
        />
      </Page>
    );
  }
}

export default ProviderDetails;
