import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { getDescription } from 'app/stores/insight/InsightModel';

import { Box, Button, Text } from 'core/components';
import InsightLink from 'app/components/links/InsightLink';
import CompactInsight from './CompactInsight';

@inject('$insights')
@observer
class InsightList extends Component {
  static defaultProps = {
    limit: undefined,
    onDataViewCreate: () => {}
  };

  state = {
    showAll: false,
    expanded: null
  };

  handleMoreInsights = () => {
    this.setState({ showAll: true });
  };

  handleExpand = (model) => {
    const insight = model.get ? model.get() : model;
    this.setState({ expanded: insight.insightID });
  };

  getAllInsights() {
    const { insights } = this.props;
    return insights && insights.models ? insights.models.slice(0) : [];
  }

  getInsights() {
    const { limit } = this.props;
    const { showAll } = this.state;

    return showAll || !limit ? this.getAllInsights() : this.getAllInsights().slice(0, limit);
  }

  render() {
    const { $insights, limit, spoofUrl, onDataViewCreate, ...rest } = this.props;
    const { expanded } = this.state;

    const allInsights = this.getAllInsights();
    const insights = this.getInsights();

    return (
      <Box {...rest}>
        {insights.length === 0 && (
          <Box px={2} py={1}>
            <Text color="muted" fontStyle="italic" small>
              No insights available
            </Text>
          </Box>
        )}

        {insights.map((insight, index) => {
          const { insightID, creationTime } = insight.get ? insight.get() : insight;
          const insightDef = $insights.getType(insight);
          const { label, icon, hideTimestamp, detailsLink } = insightDef || {};
          const detailsLinkElement = detailsLink ? detailsLink(insight) : <InsightLink id={insightID} />;
          const spoofedUrl = spoofUrl && spoofUrl(insightID);

          return (
            <CompactInsight
              creationTime={creationTime}
              description={getDescription(insight)}
              expanded={limit ? index + 1 <= limit : expanded === insightID}
              hideTimestamp={hideTimestamp}
              detailsLink={detailsLinkElement}
              icon={typeof icon === 'function' ? icon(insight) : icon}
              index={index}
              insight={insight}
              key={insightID}
              label={label}
              onExpand={this.handleExpand}
              onDataViewCreate={onDataViewCreate}
              spoofedUrl={spoofedUrl}
            />
          );
        })}

        {allInsights.length > insights.length && (
          <Box p={1}>
            <Button
              text="More Insights"
              intent="primary"
              fontWeight="bold"
              onClick={this.handleMoreInsights}
              fill
              minimal
              small
            />
          </Box>
        )}
      </Box>
    );
  }
}

export default InsightList;
