import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Tag } from 'core/components';
import { formConsumer } from 'core/form';

@formConsumer
@observer
class SidebarSectionWarnings extends Component {
  render() {
    const { children, form, model, fieldGroup, isSectionDirty, ...rest } = this.props;

    const sectionHasErrors = form.invalidGroups[fieldGroup];
    const sectionIncomplete = form.incompleteGroups[fieldGroup];

    let label = null;
    let intent = 'none';

    if (isSectionDirty && !sectionHasErrors && !sectionIncomplete) {
      label = 'Unapplied changes';
      intent = 'warning';
    }

    if (sectionHasErrors) {
      label = 'Invalid changes';
      intent = 'danger';
    }

    if (sectionIncomplete) {
      label = 'Changes required';
      intent = 'danger';
    }

    if (children && typeof children === 'function') {
      return children({ label, intent });
    }

    if (label) {
      return (
        <Tag mt="12px" intent={intent} minimal {...rest}>
          {label}
        </Tag>
      );
    }

    return null;
  }
}

export default SidebarSectionWarnings;
