import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Button, Flex, Heading, Text } from 'core/components';
import GettingStartedCardButton from 'app/views/setup/components/GettingStartedCardButton';
import { FiHome } from 'react-icons/fi';
import { inject, observer } from 'mobx-react';

const Background = styled.div`
  width: 100%;
  background: linear-gradient(360deg, #2c3953 0%, #344d6f 16.89%, #344d6f 50.75%, #223151 100%);
  color: #fff;

  .bp4-heading {
    color: #fff;
  }
`;

@inject('$auth', '$setup', '$users')
@observer
class DeckHomepageCallout extends Component {
  state = {
    loading: false
  };

  handleHide = () => {
    const { $setup } = this.props;
    return $setup.updateSettings({ hideMakeObservationDeckDefaultCallout: true });
  };

  handleUpdateUserProfile = () => {
    this.setState({ loading: true });
    const { $users } = this.props;
    $users.updateUserProfile({ defaultLandingPageType: 'observationDeck' }).then(() =>
      this.handleHide().then(() => {
        this.setState({ loading: false });
      })
    );
  };

  render() {
    const { $auth, $setup } = this.props;
    const { loading } = this.state;

    if (
      $auth.isSpoofed ||
      !!$setup.getSettings('hideMakeObservationDeckDefaultCallout') ||
      $auth.getActiveUserProperty('defaultLandingPageType') !== 'observationDeck'
    ) {
      return null;
    }

    return (
      <Background>
        <Flex alignItems="center" justifyContent="space-between" py={4} px={3} width="100%">
          <Box flex={1} maxWidth={750} mr={3}>
            <Heading level={4} fontWeight="heavy" m={0} mb="4px">
              Welcome to your Observation Deck
            </Heading>
            <Text color="white.6" fontSize={14}>
              Unleash the power of Network Observability! Observation Deck is your customizable home page to tailor your
              Kentik experience however you see fit. Dive into your network data by adding curated Kentik content or add
              your favorite Data Explorer views through widgets. Make sure to check back frequently to check out new
              content highlighting new capabilities and specialize use cases.
            </Text>
          </Box>

          <Flex alignItems="center" pr="15px">
            <GettingStartedCardButton
              onClick={this.handleUpdateUserProfile}
              loading={loading}
              text="Set as Default Homepage"
              icon={FiHome}
              m={0}
              mr={2}
            />
            <Button minimal onClick={this.handleHide} color="white.6">
              Don&apos;t ask me again
            </Button>
          </Flex>
        </Flex>
      </Background>
    );
  }
}

export default DeckHomepageCallout;
