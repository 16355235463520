import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { parseQueryString } from 'app/util/utils';
import { Field, InputGroup, Select } from 'core/form';
import { showSuccessToast, showWarningToast, showErrorToast, Flex, Link, Text } from 'core/components';
import { getKentikAzureAuthUrlByLocation } from 'shared/hybrid/azureUtils';
import AccessCheckButton from '../validation/AccessCheckButton';
import SamplingPanelWrapper from '../sampling';

const BASE_ROUTE = '/v4/setup/clouds';

@withRouter
@inject('$cloudExportWizard', '$clouds')
@observer
class AzureExportConfigFields extends Component {
  state = {
    checkingServicePrincipal: false,
    servicePrincipalCheckComplete: false,
    servicePrincipalValid: undefined,
    servicePrincipalMsg: null,
    checkingAccess: false,
    accessCheckValid: undefined,
    accessCheckMessage: null
  };

  constructor(props) {
    super(props);

    const { $cloudExportWizard, location } = props;
    const { form, isSecurityPrincipalAlreadyEnabled } = $cloudExportWizard;
    form.setValue('properties.azure_security_principal_enabled', isSecurityPrincipalAlreadyEnabled);

    const { params = null, success, errorMsg } = parseQueryString(location.search);

    if (params) {
      // having params means we have a redirect to process from the authorize api
      const paramsObj = JSON.parse(params);
      const servicePrincipalValid = success === 'true';

      // unpack and set the form values to maintain state
      form.setValues({
        ...paramsObj,
        'properties.azure_security_principal_enabled': servicePrincipalValid
      });

      let servicePrincipalMsg;
      if (servicePrincipalValid) {
        servicePrincipalMsg = 'Service Principal Authorization Completed Successfully';
        showSuccessToast(servicePrincipalMsg);
      } else {
        servicePrincipalMsg = `Error enabling Azure Security Prinicpal: ${errorMsg}`;
        showErrorToast(servicePrincipalMsg);
      }

      if (window.localStorage) {
        window.localStorage.setItem(
          'azure_security_principal_state',
          JSON.stringify({ servicePrincipalValid, servicePrincipalMsg, servicePrincipalCheckComplete: true })
        );
      }
    }
  }

  componentDidMount() {
    const azure_security_principal_state = window?.localStorage?.getItem('azure_security_principal_state');
    if (azure_security_principal_state) {
      const { servicePrincipalValid, servicePrincipalMsg, servicePrincipalCheckComplete } =
        JSON.parse(azure_security_principal_state);
      window.localStorage.removeItem('azure_security_principal_state');
      this.setState({ servicePrincipalValid, servicePrincipalMsg, servicePrincipalCheckComplete });
    }
  }

  onEnableAzureSubscriptionClick = () => {
    const { $clouds, $cloudExportWizard, history, location: locationProp } = this.props;
    const { form, azureSubscriptionId } = $cloudExportWizard;
    const { hash = null } = parseQueryString(locationProp.search);

    this.setState({ checkingServicePrincipal: true, servicePrincipalCheckComplete: false }, () => {
      $clouds
        .checkCloudProviderIdEligibility({ cloudProvider: form.getValue('cloud_provider'), ids: [azureSubscriptionId] })
        .then(() => {
          const securityPrincipalEnabled = form.getValue('properties.azure_security_principal_enabled');
          if (securityPrincipalEnabled) {
            history.push(`${BASE_ROUTE}?hash=${hash}`);
          } else {
            const location = form.getValue('properties.azure_location');
            // these are the form parameters we'll set back on the form when we get redirected back
            const params = {
              'properties.azure_subscription_id': azureSubscriptionId,
              'properties.azure_resource_group': form.getValue('properties.azure_resource_group'),
              'properties.azure_location': location,
              'properties.azure_collect_flow_logs': form.getValue('properties.azure_collect_flow_logs'),
              'properties.azure_storage_account': form.getValue('properties.azure_storage_account'),
              'properties.azure_security_principal_enabled': securityPrincipalEnabled
            };

            const url = getKentikAzureAuthUrlByLocation(location);

            const redirect = `${url}/authorize?subscription_id=${encodeURIComponent(
              azureSubscriptionId
            )}&redirect=${encodeURIComponent(
              `${window.location.protocol}//${window.location.host}${BASE_ROUTE}?hash=${hash}&params=${JSON.stringify(
                params
              )}&success={0}&errorMsg={1}`
            )}`;

            window.location.href = redirect;
          }
        })
        .catch((e) => {
          // showErrorToast(e.message)
          this.setState({ servicePrincipalMsg: e.message });
        })
        .finally(() => this.setState({ checkingServicePrincipal: false }));
    });
  };

  /*
    This will check for api access, storage account access, and flow. It returns:
    It will return resource group and subscription id validation for metadata-only, using kentik-no-flow as the storage account
    Note: if storage account not provided or bool the transflou api that this hits will return JSON parsing err.
    Also Note: if skip_metadata_collection is true, api_access will return false, but we still want to allow user to collect flow  
    {
      error_msg: <string>,
      api_access: <boolean>,
      flow_found: <boolean>,
      storage_account_access: <boolean>
    }
  */
  checkAccess = () => {
    let accessCheckMessage;
    let accessCheckValid;
    const {
      $clouds,
      $cloudExportWizard: { form, isFlowLogCollectionEnabled }
    } = this.props;

    const flow_enabled = isFlowLogCollectionEnabled || form.getValue('properties.azure_collect_flow_logs');

    this.setState({ checkingAccess: true }, () => {
      $clouds
        .checkAzure({
          subscription_id: form.getValue('properties.azure_subscription_id'),
          resource_group: form.getValue('properties.azure_resource_group'),
          location: form.getValue('properties.azure_location'),
          flow_enabled,
          storage_account: form.getValue('properties.azure_storage_account'),
          force: true,
          skip_metadata_collection: form.getValue('properties.skip_metadata_collection')
        })
        .then((accessCheckResponse) => {
          // update the model with the access check result
          form.setValue('properties.azure_access_check', accessCheckResponse);

          if (accessCheckResponse.error_msg) {
            accessCheckMessage = accessCheckResponse.error_msg;
            showWarningToast(accessCheckMessage);
          } else {
            accessCheckValid = true;
            accessCheckMessage = 'Access Check Completed Successfully';
            showSuccessToast(accessCheckMessage);
          }
        })
        .finally(() => {
          this.setState({ accessCheckMessage, accessCheckValid, checkingAccess: false });
        });
    });
  };

  renderValidateServicePrincipalButton() {
    let field = 'properties.azure_subscription_id';

    const { checkingServicePrincipal, servicePrincipalCheckComplete, servicePrincipalValid, servicePrincipalMsg } =
      this.state;
    const {
      $cloudExportWizard: { form }
    } = this.props;

    const securityPrincipalEnabled = form.getValue('properties.azure_security_principal_enabled');
    const isValid = securityPrincipalEnabled ?? servicePrincipalValid;
    const subIdField = form.getField('properties.azure_subscription_id');
    const subIdFieldErrs = subIdField.errors;
    const locationFieldErrs = form.getField('properties.azure_location').errors;
    const errors = [...subIdFieldErrs, ...locationFieldErrs];
    const buttonText = securityPrincipalEnabled ? 'Re-Verify' : 'Validate Service Principal';

    // don't let validation happen if location hasn't been set first
    // this is necessary to auth against china vs non-china endpoints
    if (locationFieldErrs.length && !subIdFieldErrs.length) {
      field = 'properties.azure_location';
    }

    // if the field is not pristine, they've interacted with it, and if it has form errors, show those
    const hasFormErrors = !subIdField.pristine && errors.length;

    // but if servicePrincipalCheckComplete, field will be pristine b/c of reload, so we want to show the api err instead
    const formFieldErrMsg = hasFormErrors ? errors[0] : '';
    const validState = errors.length ? false : isValid;
    const errMsg = servicePrincipalCheckComplete ? servicePrincipalMsg : formFieldErrMsg;
    const message = securityPrincipalEnabled
      ? `Your Service Principal for Subscription ${form.getValue('properties.azure_subscription_id')} is Valid.`
      : errMsg;

    return (
      <AccessCheckButton
        formField={field}
        loading={checkingServicePrincipal}
        validationFn={this.onEnableAzureSubscriptionClick}
        validState={validState}
        message={message}
        btnText={buttonText}
      />
    );
  }

  renderAccessCheckButton(showStorageAcctField) {
    const { checkingAccess, accessCheckValid, accessCheckMessage } = this.state;
    const {
      $cloudExportWizard: { form }
    } = this.props;

    let field = showStorageAcctField ? 'properties.azure_storage_account' : 'properties.azure_resource_group';

    // don't swallow resource group field errors if they exist but storage account is valid
    const rgField = form.getField('properties.azure_resource_group');
    const storageAcctField = form.getField('properties.azure_storage_account');

    if (showStorageAcctField && storageAcctField.valid && rgField.errors.length) {
      field = 'properties.azure_resource_group';
    }

    return (
      <AccessCheckButton
        formField={field}
        validationFn={this.checkAccess}
        loading={checkingAccess}
        validState={accessCheckValid}
        message={accessCheckMessage}
        btnText="Verify Access"
      />
    );
  }

  renderDefaultFields(showStorageAcctField) {
    const flowCopy = 'location, and storage account';
    const defaultCopy = `Enter the subscription id, resource group, ${
      showStorageAcctField ? flowCopy : 'and location'
    }`;

    return (
      <>
        <Text as="p" pb={2} muted>
          {defaultCopy}.
          <br />
          <Link to="https://kb.kentik.com/v0/Bd08.htm#Bd08-Find_Azure_Subscription_ID" blank>
            Click here to learn more.
          </Link>
        </Text>

        <Flex justifyContent="space-between">
          <Field name="properties.azure_location" large mb={2}>
            <Select showFilter />
          </Field>
        </Flex>

        <Flex flexDirection="column">
          <Field name="properties.azure_subscription_id" width="100%" large>
            <InputGroup />
          </Field>
        </Flex>

        <Flex flexDirection="column">{this.renderValidateServicePrincipalButton()}</Flex>

        <Field name="properties.azure_resource_group" width="100%" large mt={2}>
          <InputGroup />
        </Field>
      </>
    );
  }

  renderStorageAcctField() {
    return (
      <>
        <Field name="properties.azure_storage_account" large flex={1}>
          <InputGroup width="100%" />
        </Field>
      </>
    );
  }

  renderLeftColumn() {
    const { $cloudExportWizard } = this.props;
    const { isFlowLogCollectionEnabled, isFirewallLogCollectionEnabled } = $cloudExportWizard;
    if (isFlowLogCollectionEnabled) {
      $cloudExportWizard.form.setValue('properties.azure_collect_flow_logs', true);
    }
    const showStorageAcctField = isFlowLogCollectionEnabled || isFirewallLogCollectionEnabled;

    return (
      <Flex flexDirection="column" gap="1" flex={1} mb={2}>
        <Flex flexDirection="column">
          {this.renderDefaultFields(showStorageAcctField)}
          {showStorageAcctField && this.renderStorageAcctField()}
          {this.renderAccessCheckButton(showStorageAcctField)}
          {isFlowLogCollectionEnabled && <SamplingPanelWrapper />}
        </Flex>
      </Flex>
    );
  }

  renderRightColumn() {
    return (
      <Flex flex={1} flexDirection="column">
        <Flex height={750} flexWrap="wrap" justifyContent="flex-end" />
      </Flex>
    );
  }

  render() {
    return (
      <Flex gap={1}>
        {this.renderLeftColumn()}
        {this.renderRightColumn()}
      </Flex>
    );
  }
}

export default AzureExportConfigFields;
