let asns = {};
let tmpText = null;

const isCircle = ({ type }) =>
  type === 'ip' || type === 'timeout' || type === 'closed_path' || type === 'site' || type === 'target';

const getTextSize = (text) => tmpText.text(text).node().getBBox();
const getASNColor = ({ info = {} }) => info.asn && (asns[info.asn.id] || {}).color;
const getAgentAlias = ({ info = {} }) => info.agent && info.agent.agent_alias;

const getAgentStyle = (node) => {
  const asnColor = getASNColor(node);
  const text = getAgentAlias(node);
  const textSize = getTextSize(text);

  return {
    color: asnColor || '#fff',
    stroke: '#C4C4C4',
    size: [textSize.width + 32 + (node.timedOut ? 28 : 0), 30],
    text,
    textSize
  };
};

const getIPStyle = (node) => {
  const asnColor = getASNColor(node);

  return {
    color: asnColor || '#F6CEB0',
    size: [25, 25]
  };
};

const getClosedPathStyle = (node) => {
  const { info } = node;
  const { textCount, supText: text } = info;
  const textSize = getTextSize(text);

  return {
    color: '#B3E2CD',
    size: [50, 50],
    textCount,
    textSize,
    textOffset: 1
  };
};

const getTimeoutStyle = (node) => {
  const { hops } = node;
  const multiple = hops.length > 1;

  return {
    color: '#fff',
    stroke: '#C4C4C4',
    size: multiple ? [25, 25] : [10, 10],
    text: multiple ? `${hops.length}` : null
  };
};

const getASNStyle = (node) => {
  const { info } = node;
  const asnColor = getASNColor(node);
  const text = info.asn.name;
  const textSize = getTextSize(text);

  return {
    color: asnColor || '#F6CEB0',
    size: [textSize.width + 50, 50],
    text,
    textSize,
    supText: info.supText
  };
};

const getSiteStyle = (node) => {
  const { info } = node;
  const text = info.name;
  const textSize = getTextSize(text);

  return {
    color: '#DBB3E2',
    size: [50, 50],
    text,
    textSize,
    textOffset: 35
  };
};

const getRegionStyle = (node) => {
  const { id } = node;
  const text = id;
  const textSize = getTextSize(text);

  return {
    color: '#DBB3E2',
    size: [textSize.width + 20, 50],
    text,
    textSize
  };
};

const getTargetStyle = (node) => {
  const { id } = node;
  const asnColor = getASNColor(node);
  const text = getAgentAlias(node) || id;
  const textSize = getTextSize(text);
  const textCount = `${node.hops.filter((n) => !n.loss).length} / ${node.hops.length}`;

  return {
    color: asnColor || '#A1E0ED',
    size: [50, 50],
    text,
    textCount,
    textSize,
    textOffset: 35
  };
};

const getVirtualStyle = () => ({
  size: [15, 5]
});

export const buildNodeStyle = (node) => {
  const { type } = node;
  let style;

  if (type === 'agent') {
    style = getAgentStyle(node);
  }

  if (type === 'ip') {
    style = getIPStyle(node);
  }

  if (type === 'closed_path') {
    style = getClosedPathStyle(node);
  }

  if (type === 'timeout') {
    style = getTimeoutStyle(node);
  }

  if (type === 'asn') {
    style = getASNStyle(node);
  }

  if (type === 'site') {
    style = getSiteStyle(node);
  }

  if (type === 'region') {
    style = getRegionStyle(node);
  }

  if (type === 'target') {
    style = getTargetStyle(node);
  }

  if (type === 'virtual') {
    style = getVirtualStyle(node);
  }

  const { size, text, textSize, textOffset, textCount, ...rest } = style;

  node.size = size;
  node.text = text;
  node.textCount = textCount;
  node.textOffset = textOffset;
  node.style = { ...rest };
  node.width = textSize && textSize.width > size[0] ? textSize.width : size[0];
  node.height = size[1] + (textOffset ? textOffset + textSize.height + 50 : 0);
  node.isCircle = isCircle(node);
};

export const setOptions = (options) => {
  asns = options.asns;
  tmpText = options.tmpText;
};
