import React from 'react';
import { Tag, Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import Box from 'core/components/Box';
import Icon from 'core/components/Icon';
import { FiUsers, FiUser } from 'react-icons/fi';
import KentikLogo from './KentikLogo';

const smallCircleStyle = {
  borderRadius: 12,
  display: 'inline-block',
  height: 10,
  width: 10,
  marginRight: 8
};

const presetKentikMarkStyle = {
  cursor: 'pointer',
  width: 12,
  position: 'relative',
  top: 2,
  marginRight: 3,
  opacity: 0.75,
  transform: 'scale(1.2)'
};

export const typesHash = {
  preset: {
    intent: Intent.WARNING,
    icon: undefined,
    text: 'Preset'
  },

  org: {
    intent: Intent.SUCCESS,
    icon: FiUsers,
    text: 'Shared',
    helpText: 'Visible to other users'
  },

  self: {
    intent: Intent.PRIMARY,
    icon: FiUser,
    text: 'Private',
    helpText: 'Only visible to you'
  }
};

export const getShareLevelTag = (item, type) => {
  if (!item && type) {
    return typesHash[type];
  }

  const { isCompanyLevel, isPreset } = item;

  if (isPreset) {
    return typesHash.preset;
  }

  if (isCompanyLevel) {
    return typesHash.org;
  }

  return typesHash.self;
};

// when rendering icons as in the case of the ShareLevelBadge, the last icon in the tag will get cut off in a button
// this wrapper overrides the blueprint selector that causes it
const IconWrapper = styled(Icon)`
  margin-left: auto !important;
  margin-right: 4px !important;
`;

export const ShareLevelIcon = (props) => {
  const { item, type, minimal } = props;

  const { icon } = getShareLevelTag(item, type);

  if (icon) {
    return <IconWrapper icon={icon} />;
  }
  if ((item && item.isPreset) || type === 'preset') {
    return <KentikLogo onlyMark style={presetKentikMarkStyle} fillColor={minimal ? '#ff850e' : '#ffffff'} />;
  }

  return null;
};

const ShareLevelBadge = (props) => {
  const { item, style, type, size, showHelpText = false, showText = true, showIcon = true } = props;
  const { intent, text, helpText } = getShareLevelTag(item, type);

  if (size === 'small') {
    return <Box style={{ ...smallCircleStyle, ...style }} />;
  }

  return (
    <Tag round minimal intent={intent} style={{ ...style }}>
      {showIcon && <ShareLevelIcon {...props} />}
      {showText && item && item.shareLevel}
      {showText && !item && text}
      {showHelpText && helpText && <span>: {helpText}</span>}
    </Tag>
  );
};

export default observer(ShareLevelBadge);
