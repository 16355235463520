import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Intent } from '@blueprintjs/core';
import { withRouter } from 'react-router-dom';
import { FiClock } from 'react-icons/fi';

import { showErrorToast } from 'core/components/toast';
import { Field, Form, Select, Switch } from 'core/form';
import { fields, options } from 'app/forms/config/rawFlow';
import { itemExplorerFields } from 'app/forms/config/dashboardItemDetails';
import { Box, Card, Flex } from 'core/components';
import DeviceSelector from 'app/components/device/DeviceSelector';
import SidebarModel from 'app/stores/query/SidebarModel';
import FilterOptions from 'app/views/core/explorer/sidebar/FilterOptions';
import TimeOptions from 'app/views/core/explorer/sidebar/TimeOptions';
import ItemExplorerOptionTitle from 'app/views/core/dashboards/dashboardItem/form/ItemExplorerOptionTitle';
import RawFlowOptions from 'app/views/core/analytics/RawFlowOptions';
import { getAllDisabledDimensions } from 'app/util/devices';

@Form({ fields: { ...fields, ...itemExplorerFields }, options })
@inject('$dataviews', '$devices', '$dictionary', '$rawFlow')
@observer
class RawFlowDashboardOptions extends Component {
  sidebar = new SidebarModel({
    name: 'rawFlow',
    sections: ['options', 'time', 'filters', 'devices'],
    directToggleSections: ['filters', 'devices']
  });

  componentDidMount() {
    const { $rawFlow, form } = this.props;
    this.setDashboardOverrides();
    $rawFlow.registerFormState(form);
  }

  componentDidUpdate() {
    const { $rawFlow, form, model, dashboardItem } = this.props;
    if (form !== $rawFlow.formState) {
      $rawFlow.registerFormState(form);
    }

    if (!form.dirty && model.get('time_locked') !== form.getValue('time_locked')) {
      // set from source of truth if model and form are not in sync.
      form.setValues({
        time_locked: dashboardItem.get('time_locked'),
        device_locked: dashboardItem.get('device_locked'),
        filter_source: dashboardItem.get('filter_source')
      });
    }
  }

  setDashboardOverrides = () => {
    const { form } = this.props;
    const time_locked = form.getValue('time_locked');
    const device_locked = form.getValue('device_locked');
    const filter_source = form.getValue('filter_source');
    this.syncDeviceLocked(null, !device_locked);
    this.syncTimeLocked(null, !time_locked);
    this.syncFilterSource(null, filter_source);
  };

  toggleFiltersEditing = () => {
    this.sidebar.toggleEditingSection('filters');
  };

  onRunQueryClick = () => {
    const { form, model, $rawFlow } = this.props;

    form.submit(() => {
      const values = form.getValues();
      const { flow_fields, order_by } = values;
      if (!(flow_fields.includes(order_by) || order_by === 'ctimestamp')) {
        showErrorToast('Order column should be in the selected fields');
        return;
      }
      $rawFlow.setQueryData(values);
      $rawFlow.fetchResults(values);
      model.set(values);
      // onSubmit(values);
      form.setModel(model);
    });
  };

  syncTimeLocked = (field, value) => {
    const { form, dashboard } = this.props;
    if (value) {
      form.setValues({
        lookback_seconds: dashboard.get('query').lookback_seconds
      });
    }
  };

  syncDeviceLocked = (field, value) => {
    const { form, dashboard } = this.props;
    if (value) {
      const query = dashboard.get('query');
      form.setValues({
        device_name: query.device_name || [],
        device_sites: query.device_sites || [],
        device_labels: query.device_labels || [],
        device_types: query.device_types || [],
        all_devices: query.all_devices
      });
    }
  };

  syncFilterSource = (field, value) => {
    const { form, dashboard } = this.props;
    if (value === 'dashboard') {
      const query = dashboard.get('query');
      form.setValues({
        saved_filters: query.saved_filters || []
      });
      if (query.filters) {
        form.setValue('filters.connector', query.filters.connector);
        form.setValue('filters.filterGroups', query.filters.filterGroups);
      }
    }
  };

  componentWillUnmount() {
    const { $rawFlow } = this.props;
    $rawFlow.clearResults();
  }

  render() {
    const { form } = this.props;
    const { sidebar } = this;
    const device_name = form.getValue('device_name');
    const device_labels = form.getValue('device_labels');
    const device_sites = form.getValue('device_sites');
    const device_types = form.getValue('device_types');
    const all_devices = form.getValue('all_devices');
    const flow_fields = form.getValue('flow_fields');
    const filter_groups = form.getValue('filters');

    const disabledDimensions = getAllDisabledDimensions({
      all_devices,
      device_name,
      device_labels,
      device_sites,
      device_types,
      flow_fields,
      filter_groups
    });

    const editingFilters = this.sidebar.editingSections.get('filters');

    const isDeviceLocked = form.getValue('device_locked');
    const isTimeLocked = form.getValue('time_locked');
    const isFilterLocked = form.getValue('filter_source') !== 'dashboard';

    return (
      <Flex flexDirection="column" className="dashboard-item-explorer-options overflow-auto" p="2px" pr="4px">
        <Button
          intent={Intent.PRIMARY}
          text="Preview Query"
          className="pt-medium"
          onClick={this.onRunQueryClick}
          disabled={!form.valid}
        />

        <Card py={2} px={1} my={1}>
          <ItemExplorerOptionTitle title="Data Sources" icon="database" />
          <Field name="device_locked" showLabel={false} onChange={this.syncDeviceLocked}>
            <Switch switchLabel="Controlled by Dashboard" />
          </Field>
          <DeviceSelector className="pt-small" readOnly={!isDeviceLocked} disabled={!isDeviceLocked} />
        </Card>

        <Card py={2} px={1} my={1}>
          <ItemExplorerOptionTitle title="Dimensions" icon="layers" />
          <RawFlowOptions disabledDimensions={disabledDimensions} />
        </Card>

        <Card py={2} px={1} my={1}>
          <ItemExplorerOptionTitle title="Time Range" icon={FiClock} />
          <Field
            name="time_locked"
            showLabel={false}
            style={{ marginBottom: 16 }}
            width={230}
            onChange={this.syncTimeLocked}
          >
            <Switch switchLabel="Controlled by Dashboard" />
          </Field>
          <TimeOptions
            className="no-margin"
            onModeChange={this.handleTimeModeChange}
            disabled={!isTimeLocked}
            showTimeZoneSelector={false}
            showJumpButtons={false}
            showModes={['lookback']}
            showFieldLabels={false}
            disabledDimensions={disabledDimensions}
          />
        </Card>

        <Card py={2} px={1} my={1}>
          <ItemExplorerOptionTitle title="Filtering" icon="filter" />
          <Field name="filter_source" onChange={this.syncFilterSource}>
            <Select />
          </Field>
          <FilterOptions
            sidebar={sidebar}
            isOpen={editingFilters}
            onClose={this.toggleFiltersEditing}
            disabledDimensions={disabledDimensions}
            readOnly={!isFilterLocked}
            allowNestedFilters
          />
          <Box mt={1}>
            <Button
              className="pt-small pt-medium"
              onClick={this.toggleFiltersEditing}
              icon="edit"
              text="Edit Filters"
              disabled={!isFilterLocked}
            />
          </Box>
        </Card>
      </Flex>
    );
  }
}

export default withRouter(RawFlowDashboardOptions);
