import React, { Component } from 'react';
import { inject } from 'mobx-react';
import CloudAzureConfigureExportPowershell from './CloudAzureConfigureExportPowershell';

@inject('$cloudExportWizard', '$auth', '$users')
export default class AzureCliSetup extends Component {
  render() {
    const { $cloudExportWizard } = this.props;
    const { azureSubscriptionId, form } = $cloudExportWizard;
    return (
      <CloudAzureConfigureExportPowershell
        subscriptionID={azureSubscriptionId}
        resourceGroup={form.getValue('properties.azure_resource_group')}
        location={form.getValue('properties.azure_location')}
        storageAccount={form.getValue('properties.azure_storage_account')}
        enrichmentScope={form.getValue('properties.azure_enrichment_scope')}
        showHeading={false}
      />
    );
  }
}
