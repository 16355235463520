import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Responsive } from 'react-grid-layout';
import { omit, cloneDeep } from 'lodash';
import DeckCard from 'app/components/decks/DeckCard';
import dashboardWidthProvider from 'app/views/core/dashboards/DashboardWidthProvider';

const gridLayoutDefaults = {
  breakpoints: { lg: 1024, sm: 0 },
  cols: { lg: 12, sm: 1 },
  containerPadding: [12, 12],
  margin: [12, 12],
  rowHeight: 50
};

@inject('$decks')
@dashboardWidthProvider
@observer
class DeckGridLayout extends Component {
  state = {};

  static getDerivedStateFromProps(props, state) {
    const { deck } = props;

    if (deck && (deck !== state.deck || deck.widgets.length !== state.widgetCount)) {
      const smallIssues = deck.layout.sm.filter((item) => item.minW > item.w || item.minW > item.maxW);
      if (smallIssues.length > 0) {
        console.warn('small layout issues', smallIssues);
      }
      const largeIssues = deck.layout.lg.filter((item) => item.minW > item.w || item.minW > item.maxW);
      if (largeIssues.length > 0) {
        console.warn('large layout issues', largeIssues);
      }

      return {
        deck,
        widgetCount: deck.widgets.length,
        layout: cloneDeep(deck.layout)
      };
    }

    return null;
  }

  onLayoutChange = (currentBreakpointLayout, allLayouts) => {
    const { deck } = this.state;

    // Need to strip out moved & static. Using omit, because static is a reserved word
    const { lg, sm } = allLayouts;
    const layout = { lg: [], sm: [] };
    layout.lg = lg.map((layoutItem) => omit(layoutItem, ['moved', 'static']));
    layout.sm = sm.map((layoutItem) => omit(layoutItem, ['moved', 'static']));

    deck.layout = layout;
    this.setState({ layout });
  };

  renderWidgets() {
    const { $decks, canCustomize, canSaveOnly, onWidgetConfigChange, onRemoveWidget, width, deckOverrides } =
      this.props;
    const { deck, layout } = this.state;

    return deck.widgets
      .map(({ widgetName, id, config }) => {
        const widgetConfig = $decks.widgets[widgetName];

        if (!widgetConfig) {
          console.info(`Widget not found: ${widgetName}`);
          return null;
        }

        const { Component: Widget } = widgetConfig;
        const itemLayout = layout.lg.find((item) => item.i === id);

        // Temporary for debug purposes
        if (!itemLayout) {
          console.info(`Widget not found: ${id}`);
          return null;
        }

        return (
          <DeckCard key={id}>
            <Widget
              id={id}
              title={widgetConfig.title}
              deck={deck}
              config={config}
              onConfigChange={(newConfig) => onWidgetConfigChange(id, newConfig)}
              onRemove={() => onRemoveWidget(id)}
              deckOverrides={deckOverrides}
              canCustomize={canCustomize}
              canSaveOnly={canSaveOnly}
              height={itemLayout.h * gridLayoutDefaults.rowHeight + (itemLayout.h - 1) * gridLayoutDefaults.margin[1]}
              deckWidth={width}
              approxWidth={
                width < gridLayoutDefaults.breakpoints.lg ? width : (width / gridLayoutDefaults.cols.lg) * itemLayout.w
              }
            />
          </DeckCard>
        );
      })
      .filter((widget) => widget !== null);
  }

  render() {
    const { canCustomize, size } = this.props;
    const { layout } = this.state;

    return (
      <Responsive
        className="deck-grid-layout"
        {...gridLayoutDefaults}
        isDraggbale={canCustomize}
        isResizable={canCustomize}
        resizeHandles={['sw', 'se']}
        // the entire Widget title bar
        draggableHandle=".drag-header"
        layouts={layout}
        onLayoutChange={this.onLayoutChange}
        onResizeStop={this.onResizeStop}
        width={size.width}
        style={{ width: '100%' }}
        useCSSTransforms={!/^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)}
      >
        {this.renderWidgets()}
      </Responsive>
    );
  }
}

export default DeckGridLayout;
