import React from 'react';
import { inject, observer } from 'mobx-react';
import { defaultsDeep } from 'lodash';
import { Button, Card, Collapse, Flex, Heading, Tooltip } from 'core/components';
import { FormDialog, Field, RadioGroup, TextArea } from 'core/form';
import { formatDateTime } from 'core/util/dateUtils';

const fields = {
  'metadata.admin.rating': {
    options: [
      { value: 3, label: 'Yes' },
      { value: 1, label: 'No' }
    ],
    label: 'Admin Rating',
    rules: ['required', 'numeric', 'max:3', 'min:0']
  },
  'metadata.admin.comment': {
    label: 'Comment',
    rules: ['min:2', 'max:250']
  }
};

const options = {
  name: 'Manage Journey Result',
  showPristineErrors: false
};

@inject('$auth')
@observer
class ManageResult extends React.Component {
  state = {
    isOpen: false,
    isSourceOpen: false
  };

  handleSave = (form, values) => {
    const { $auth, model } = this.props;
    values.metadata.admin.timestamp = formatDateTime(Date.now());
    values.metadata.admin.user_id = $auth.getUnderlyingUserProperty('id');
    // merge with metadata so we do not overwrite sibling properties
    const metadata = defaultsDeep({}, values.metadata, model.get('metadata'));
    return model.save({ metadata }, { sendProvidedValuesOnly: true, toast: false, optimistic: true });
  };

  handleToggleDialog = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  formatCode(code) {
    return JSON.stringify(code, null, 2);
  }

  render() {
    const { model, $auth } = this.props;
    const { isOpen, isSourceOpen } = this.state;
    const { isSudoer } = $auth;

    if (!isSudoer) {
      return null;
    }

    return (
      <>
        <Tooltip content="Manage" position="bottom">
          <Button icon="settings" small style={{ minWidth: 50 }} onClick={() => this.handleToggleDialog()} />
        </Tooltip>
        {isOpen ? (
          <FormDialog
            entityName=""
            model={model}
            fields={fields}
            options={options}
            onClose={this.handleToggleDialog}
            title={`${options.name}: ${model.get('prompt')}`}
            width={1200}
            height={800}
            isOpen
            formActionsProps={{
              onSubmit: this.handleSave,
              onCancel: this.handleToggleDialog
            }}
          >
            {() => (
              <Flex flexDirection="column" gap={2}>
                <Heading level={5} m={0} fontWeight="normal">
                  Is this correct?
                </Heading>
                <Field name="metadata.admin.rating" large showLabel={false} m={0}>
                  <RadioGroup inline />
                </Field>
                <Heading cursor="pointer" onClick={() => this.setState({ isSourceOpen: !isSourceOpen })}>
                  Show/hide Completion and Query
                </Heading>
                <Collapse isOpen={isSourceOpen}>
                  <Flex justifyContent="space-between" alignItems="top">
                    <Card flex={1}>
                      <pre>
                        <code>{this.formatCode(JSON.parse(model.get('completion')))}</code>
                      </pre>
                    </Card>
                    <Card flex={1}>
                      <pre>
                        <code>{this.formatCode(model.get('query'))}</code>
                      </pre>
                    </Card>
                  </Flex>
                </Collapse>
                <Field name="metadata.admin.comment" large m={0}>
                  <TextArea rows={6} fill />
                </Field>
              </Flex>
            )}
          </FormDialog>
        ) : null}
      </>
    );
  }
}

export default ManageResult;
