import React from 'react';
import { inject } from 'mobx-react';

import Link from 'core/components/Link';

const DictionaryLookupLink = inject(
  '$dictionary',
  '$app'
)(({ $app, $dictionary, baseUrl, value, label, lookup, ...rest }) => {
  if (!value || !/[^-]/.test(value)) {
    return value || null;
  }

  const content = label || $dictionary.get(`${lookup}.${value}`) || value;

  if ($app.isSubtenant) {
    return content;
  }

  return (
    <Link to={`${baseUrl}/${value}`} {...rest}>
      {content}
    </Link>
  );
});

export default DictionaryLookupLink;
