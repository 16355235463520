const VALID_FPA_METRICS = [
  'avg_bits_per_sec',
  'p95th_bits_per_sec',
  'max_bits_per_sec',

  'avg_pkts_per_sec',
  'p95th_pkts_per_sec',
  'max_pkts_per_sec',

  'avg_flows_per_sec',
  'p95th_flows_per_sec',
  'max_flows_per_sec'
];

const FPA_ADVANCED_DEFAULTS = {
  MIN_SUPPORT_PCT: 20,
  VIZ_PERC: 10,
  MAX_ITEMSET_LENGTH: 20,
  WINDOW_SIZE: 3,
  CHG_THR: 0.2,
  TOP_NUM_CPDS: 5,
  PERC_THR_CPDS: 20
};

const FPA_TYPES = {
  fpa: 'Single Window',
  diff_fpa: 'Comparison',
  cpd_fpa: 'Change Point Detection'
};

const FPA_FORM_DEFAULTS = {
  type: 'cpd_fpa',
  dimensions: [],
  overrideDimensions: false,
  min_support_pct: FPA_ADVANCED_DEFAULTS.MIN_SUPPORT_PCT,
  viz_perc: FPA_ADVANCED_DEFAULTS.VIZ_PERC,
  max_itemset_length: FPA_ADVANCED_DEFAULTS.MAX_ITEMSET_LENGTH,
  window_size: FPA_ADVANCED_DEFAULTS.WINDOW_SIZE,
  chg_thr: FPA_ADVANCED_DEFAULTS.CHG_THR,
  top_num_cpds: FPA_ADVANCED_DEFAULTS.TOP_NUM_CPDS,
  perc_thr_cpds: FPA_ADVANCED_DEFAULTS.PERC_THR_CPDS,
  time: {
    starting_time: '',
    ending_time: '',
    lookback_seconds: 0
  },
  compare_time: {
    starting_time: '',
    ending_time: '',
    lookback_seconds: 0
  }
};

module.exports = {
  VALID_FPA_METRICS,
  FPA_ADVANCED_DEFAULTS,
  FPA_FORM_DEFAULTS,
  FPA_TYPES
};
