import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ColorBlock, ColorPicker, Dialog, Box, Flex, Button, Popover } from 'core/components';
import { InputGroup } from 'core/form';

@inject('$labels')
@observer
class AddLabelDialog extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      labelColor: '#63707D',
      labelName: '',
      colorOpen: false
    };
  }

  handleColorBlockClick = () => {
    this.setState({ colorOpen: true });
  };

  handleColorChange = ({ hex: color }) => {
    this.setState({ labelColor: color, colorOpen: false });
  };

  handleLabelChange = (e) => {
    this.setState({ labelName: e.target.value });
  };

  handleAddLabel = () => {
    const { $labels, onClose } = this.props;
    const { labelName, labelColor } = this.state;
    const model = $labels.labels.forge({ name: labelName, color: labelColor }, { select: false });
    model.save().then(() => {
      this.inputRef.current.value = '';
      $labels.labels.clearFilters();
      $labels.labels.fetch();
      onClose();
    });
  };

  render() {
    const { $labels, isOpen, onClose } = this.props;
    const { labelName, labelColor, colorOpen } = this.state;

    return (
      <Dialog isOpen={isOpen} onClose={onClose} title="New Label" canOutsideClickClose top={40}>
        <Dialog.Body>
          <Box>
            <Flex>
              <Popover
                usePortal
                isOpen={colorOpen}
                modifiers={{
                  offset: { enabled: false },
                  flip: { enabled: false },
                  preventOverflow: { enabled: true, boundariesElement: 'window' }
                }}
                position="bottom-left"
                content={
                  <Box>
                    <ColorPicker color={labelColor} onChange={this.handleColorChange} width={437} />
                  </Box>
                }
              >
                <Button mr={1} onClick={this.handleColorBlockClick}>
                  <Flex align="center" justify="center">
                    <ColorBlock color={labelColor} style={{ minHeight: 'auto' }} />
                  </Flex>
                </Button>
              </Popover>
              <InputGroup
                inputRef={this.inputRef}
                fill
                onChange={this.handleLabelChange}
                placeholder="Add a label..."
              />
            </Flex>
          </Box>
        </Dialog.Body>
        <Dialog.Footer>
          <Button text="Cancel" onClick={onClose} mr={1} />
          <Button
            text="Save"
            disabled={$labels.labels.loading || labelName.trim() === ''}
            onClick={this.handleAddLabel}
            intent="primary"
          />
        </Dialog.Footer>
      </Dialog>
    );
  }
}

export default AddLabelDialog;
