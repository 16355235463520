import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';
import OttIcon from 'app/views/serviceProvider/ott/OttIcon';

const OttServiceTypeLink = ({ $app, serviceType, showIcon = true, children, ...rest }) => {
  const content = children || (
    <>
      {showIcon && <OttIcon type="type" value={serviceType} iconSize={16} />} {serviceType}
    </>
  );

  if ($app.isSubtenant) {
    return content;
  }
  return (
    <Link to={`/v4/service-provider/ott/type/${serviceType}`} {...rest}>
      {content}
    </Link>
  );
};

export default inject('$app')(OttServiceTypeLink);
