import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { FiShare2 } from 'react-icons/fi';
import { Button, Icon, Menu, MenuItem, Popover } from 'core/components';
import ShareViewDialog from 'app/views/core/ShareViewDialog';

@inject('$exports')
class ExportMenu extends Component {
  static defaultProps = {
    fileName: 'page',
    passLocation: true,
    path: '/api/ui/export/exportPage',
    menuOptions: null,
    exportButtonProps: {
      small: true,
      text: ''
    },
    shareButtonProps: {}
  };

  state = {
    shareDialogOpen: false
  };

  get fileName() {
    const { fileName } = this.props;
    return `export-${fileName.toLowerCase()}-${Date.now()}`;
  }

  fetchExport = (type) => {
    const { $exports, path, exportOptions, passLocation } = this.props;

    $exports.fetchExport({
      path,
      type,
      passLocation,
      fileName: this.fileName,
      exportOptions
    });
  };

  handlePdfClick = () => {
    this.fetchExport('pdf');
  };

  toggleShareDialog = () => {
    this.setState({ shareDialogOpen: true });
  };

  onShareDialogClose = () => {
    this.setState({ shareDialogOpen: false });
  };

  render() {
    const { path, exportButtonProps, shareButtonProps, passLocation, menuItems, additionalExportMenuItems } =
      this.props;
    const { shareDialogOpen } = this.state;

    return (
      <>
        <Button icon={FiShare2} text="Share" minimal onClick={() => this.toggleShareDialog()} />
        <Popover position="bottom-right">
          <Button
            minimal
            text="Actions"
            rightIcon={<Icon icon="caret-down" iconSize={16} mx="-2px" />}
            {...exportButtonProps}
          />
          <Menu>
            <MenuItem icon="export" text="Export" popoverProps={{ openOnTargetFocus: false }}>
              <MenuItem icon="document" text="Visual Report" label=".pdf" onClick={this.handlePdfClick} />
              {additionalExportMenuItems}
            </MenuItem>
            {menuItems}
          </Menu>
        </Popover>

        <ShareViewDialog
          isOpen={shareDialogOpen}
          path={path}
          fileName={this.fileName}
          passLocation={passLocation}
          onClose={this.onShareDialogClose}
          legacyLinkShare
          disabledSubscription
          {...shareButtonProps}
        />
      </>
    );
  }
}

export default ExportMenu;
