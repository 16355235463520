import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { select } from 'd3-selection';
import styled, { withTheme } from 'styled-components';
import { rgb } from 'd3-color';
import { debounce, find, get } from 'lodash';

import sankey from 'app/components/dataviews/d3/sankey';
import { getShortName } from 'app/util/queryResults';
import { adjustByGreekPrefix } from 'core/util';
import { zeroToText } from 'app/util/utils';

import { healthToIntent, getWorstHealth } from 'app/views/synthetics/utils/syntheticsUtils';

const SankeyContainer = styled.div`
  .node rect {
    cursor: move;
    fill-opacity: 0.9;
  }

  .node text {
    pointer-events: none;
    text-shadow: 0 1px 0 ${({ theme }) => theme.colors.white};
    color: red;
    fill: red;
  }

  .node .deep-label {
    background-color: ${({ theme }) => (theme.name === 'dark' ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.5)')};
  }

  .link {
    fill: none;
    stroke: ${({ theme }) => (theme.name === 'dark' ? theme.colors.white : theme.colors.black)};
    stroke-opacity: 0.1;
    transition: stroke-opacity 0.2s ease;
  }

  .link.fillLink {
    fill-opacity: 0.2;
    transition: fill-opacity 0.2s ease;
  }

  .pt-dark .link {
    stroke: ${({ theme }) => theme.colors.lightGray1};
  }

  .link:hover {
    stroke-opacity: 0.5;
  }

  .cycleLink {
    fill: ${({ theme }) => theme.colors.red1};
    opacity: 0.2;
    stroke: none;
    stroke-linejoin: round;
  }

  .cycleLink:hover {
    opacity: 0.5;
  }
`;

@inject('$app', '$dataviews', '$dictionary')
@observer
class SyntheticsSankey extends Component {
  graph = {
    nodes: [],
    links: []
  };

  componentDidMount() {
    this.renderData();
  }

  componentDidUpdate() {
    this.renderData();
  }

  componentWillUnmount() {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }
    const { $dataviews } = this.props;
    $dataviews.unregister(this);
  }

  drawSankey() {
    if (!this.chart) {
      return;
    }

    const { graph, props } = this;
    const { $dictionary, theme } = props;
    const chartTypesValidations = $dictionary.get('chartTypesValidations');

    const margin = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    };

    this.chart.innerHTML = '';
    const chartContainer = select(this.chart);
    const width = parseInt(chartContainer.style('width')) - margin.left - margin.right || 0;
    const height = chartContainer.node().parentElement.scrollHeight - margin.top - margin.bottom;

    const format = (d) => `${zeroToText(adjustByGreekPrefix(d, 'M'), { fix: 3 })} Mbps`;

    const getTitle = (d) => `${d.source.name.substring(d.source.name.indexOf('__k_') + 4)} → ${d.target.name.substring(
      d.target.name.indexOf('__k_') + 4
    )}
      ${format(d.value)}`;

    const getCssClass = (d) => (d.causesCycle ? 'cycleLink' : 'link');

    // Set the sankey diagram properties
    const sankeyChart = sankey().nodeWidth(36).nodePadding(10).nodeMinHeight(5).size([width, height]);

    const path = sankeyChart.link();

    sankeyChart.nodes(graph.nodes).links(graph.links).layout(32, true);

    // prepare the chart canvas
    const svg = chartContainer
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', Math.ceil(sankeyChart.totalDepth) + margin.top)
      .attr('overflow', 'visible')
      .append('g')
      .attr('transform', `translate(${margin.left},0)`);

    // add in the links
    const link = svg
      .append('g')
      .selectAll('.link')
      .data(graph.links)
      .enter()
      .append('path')
      .attr('class', (d) => getCssClass(d))
      .attr('d', path)
      // .sort((a, b) => b.dy - a.dy) // in pathLinks and mouseover handler we assume graph.links and links appended to svg are in the same order
      .style('stroke', (d) => theme.colors[healthToIntent({ health: d.health })])
      .style('stroke-width', (d) => Math.max(1, d.dy));

    // add the link titles
    link.append('title').text((d) => getTitle(d));

    link.on('mouseover', (d) => {
      for (let x = 0, xlen = d.pathLinks.length; x < xlen; x += 1) {
        select(link._groups[0][d.pathLinks[x]]).style('stroke-opacity', 0.5);
      }
    });

    link.on('mouseout', (d) => {
      for (let x = 0, xlen = d.pathLinks.length; x < xlen; x += 1) {
        select(link._groups[0][d.pathLinks[x]]).style('stroke-opacity', 0.1);
      }
    });

    // add in the nodes
    const node = svg
      .append('g')
      .selectAll('.node')
      .data(graph.nodes)
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', (d) => `translate(${d.x},${d.y})`);

    // add the rectangles for the nodes
    node
      .append('rect')
      .attr('height', (d) => d.dy)
      .attr('width', sankeyChart.nodeWidth())
      .style('fill', (d) => (d.color = theme.colors[healthToIntent({ health: d.health })]))
      .style('stroke', (d) => rgb(d.color).darker(2))
      .attr('pointer-events', 'all')
      .on('mouseover', (d) => {
        let x;
        let xlen;
        let y;
        let ylen;
        const linksToHighlight = [];
        for (x = 0, xlen = d.sourceLinks.length; x < xlen; x += 1) {
          for (y = 0, ylen = d.sourceLinks[x].pathLinks.length; y < ylen; y += 1) {
            if (linksToHighlight.indexOf(d.sourceLinks[x].pathLinks[y]) === -1) {
              linksToHighlight.push(d.sourceLinks[x].pathLinks[y]);
            }
          }
        }
        for (x = 0, xlen = d.targetLinks.length; x < xlen; x += 1) {
          for (y = 0, ylen = d.targetLinks[x].pathLinks.length; y < ylen; y += 1) {
            if (linksToHighlight.indexOf(d.targetLinks[x].pathLinks[y]) === -1) {
              linksToHighlight.push(d.targetLinks[x].pathLinks[y]);
            }
          }
        }
        for (x = 0, xlen = linksToHighlight.length; x < xlen; x += 1) {
          select(link._groups[0][linksToHighlight[x]]).style('stroke-opacity', 0.5);
        }
      })
      .on('mouseout', (d) => {
        let x;
        let xlen;
        let y;
        let ylen;
        const linksToHighlight = [];
        for (x = 0, xlen = d.sourceLinks.length; x < xlen; x += 1) {
          for (y = 0, ylen = d.sourceLinks[x].pathLinks.length; y < ylen; y += 1) {
            if (linksToHighlight.indexOf(d.sourceLinks[x].pathLinks[y]) === -1) {
              linksToHighlight.push(d.sourceLinks[x].pathLinks[y]);
            }
          }
        }
        for (x = 0, xlen = d.targetLinks.length; x < xlen; x += 1) {
          for (y = 0, ylen = d.targetLinks[x].pathLinks.length; y < ylen; y += 1) {
            if (linksToHighlight.indexOf(d.targetLinks[x].pathLinks[y]) === -1) {
              linksToHighlight.push(d.targetLinks[x].pathLinks[y]);
            }
          }
        }
        for (x = 0, xlen = linksToHighlight.length; x < xlen; x += 1) {
          select(link._groups[0][linksToHighlight[x]]).style('stroke-opacity', 0.1);
        }
      })
      .append('title')
      .text((d) => {
        const arr = d.name.split('__k_');
        arr[0] = chartTypesValidations[arr[0]];
        arr.push(format(d.value));
        return arr.join('\n');
      });

    // add clickable label to nodes
    node
      .append('foreignObject')
      .attr('x', (d) => {
        let label_width = d.shortName ? d.shortName.length * 10 : 10;
        if (label_width > 250) {
          label_width = 250;
        }
        const label_padding = 6;
        const label_x = d.x < width / 2 ? sankeyChart.nodeWidth() + label_padding : -1 * (label_width + label_padding);
        return label_x;
      })
      .attr('y', (d) => d.dy / 2 - 10)
      .attr('width', (d) => {
        let label_width = d.shortName ? d.shortName.length * 10 : 10;
        if (label_width > 250) {
          label_width = 250;
        }
        return label_width;
      })
      .attr('height', 20)
      .append('xhtml:div')
      .style('margin', 0)
      .style('padding', 0)
      .style('background-color', 'transparent')
      .style('text-align', (d) => {
        const label_align = d.x < width / 2 ? 'left' : 'right';
        return label_align;
      })
      .append('span')
      .attr('class', (d) => {
        if (
          (d.x < width / 2 && d.sourceLinksUnderHalfwayPoint > 1) ||
          (d.x >= width / 2 && d.targetLinksUnderHalfwayPoint > 1)
        ) {
          return 'deep-label';
        }
        return '';
      })
      .text((d) => d.shortName);
  }

  renderData() {
    this.clear();
    const { results, resultTimeMs } = this.props;
    const { flow, tasks, health_ts } = results;
    if (!this.chart || !flow) {
      return;
    }

    const { nodes, links } = this.graph;
    if (!flow.length) {
      return;
    }
    const keys = Object.keys(flow[0]);
    const direction = keys.includes('i_dst_connect_type_name') ? 'dst' : 'src';
    const inetDirection = keys.includes('inet_dst_addr') ? 'dst' : 'src';

    const siteDimension = 'i_device_site_name';
    const connTypeDimension = `i_${direction}_connect_type_name`;
    const providerDimension = `i_${direction}_provider_classification`;
    const inetDimension = `inet_${inetDirection}_addr`;
    const healthTimeKey = (resultTimeMs / 1000).toString();

    for (let index = 0; index < flow.length; index += 1) {
      const row = flow[index];
      const site = row[siteDimension];
      const connType = row[connTypeDimension];
      const provider = row[providerDimension];
      const target = row[inetDimension].split('/')[0];
      const matchingTask = Object.values(tasks).find(
        (task) =>
          task.task &&
          ((task.task.ping && task.task.ping.target === target) ||
            (task.task.knock && task.task.knock.target === target))
      );

      const { agents = {} } = health_ts?.[healthTimeKey].tasks?.[matchingTask?.task?.id] || {};
      const healthByTime = Object.values(agents).reduce(
        (acc, curr) => getWorstHealth(acc, curr.overall_health.health),
        'failing'
      );
      let siteNode = nodes.findIndex((node) => node.name === `${siteDimension}__k_${site}`);
      if (siteNode === -1) {
        siteNode = nodes.length;
        nodes.push({
          node: siteNode,
          name: `${siteDimension}__k_${site}`,
          shortName: getShortName(siteDimension, site),
          health: 'healthy'
        });
      } else {
        nodes[siteNode].health = 'healthy';
      }

      let connTypeNode = nodes.findIndex((node) => node.name === `${connTypeDimension}__k_${connType}`);
      if (connTypeNode === -1) {
        connTypeNode = nodes.length;
        nodes.push({
          node: connTypeNode,
          name: `${connTypeDimension}__k_${connType}`,
          shortName: getShortName(connTypeDimension, connType),
          health: 'healthy'
        });
      } else {
        nodes[connTypeNode].health = 'healthy';
      }

      let providerNode = nodes.findIndex((node) => node.name === `${providerDimension}__k_${provider}`);
      if (providerNode === -1) {
        providerNode = nodes.length;
        nodes.push({
          node: providerNode,
          name: `${providerDimension}__k_${provider}`,
          shortName: getShortName(providerDimension, provider),
          health: 'healthy'
        });
      } else {
        nodes[providerNode].health = 'healthy';
      }

      let targetNode = nodes.findIndex((node) => node.name === `${inetDimension}__k_${target}`);
      if (targetNode === -1) {
        targetNode = nodes.length;
        nodes.push({
          node: targetNode,
          name: `${inetDimension}__k_${target}`,
          shortName: getShortName(inetDimension, target),
          health: healthByTime
        });
      } else {
        nodes[targetNode].health = getWorstHealth(nodes[targetNode].health, healthByTime);
      }

      const linkedLinks = [3 * index, 3 * index + 1, 3 * index + 2];

      const rawFlow = get(row, 'rawData.both_bits_per_sec.flow');
      let linkValue = 1;
      if (rawFlow) {
        const linkFlow = find(rawFlow, (flowTs) => flowTs[0] === resultTimeMs);
        if (linkFlow) {
          [, linkValue] = linkFlow;
        }
      }

      const isFailing = healthByTime === 'failing';

      links.push(
        {
          source: siteNode,
          target: connTypeNode,
          pathLinks: [].concat(linkedLinks),
          value: linkValue || 1,
          health: isFailing ? healthByTime : 'healthy'
        },
        {
          source: connTypeNode,
          target: providerNode,
          pathLinks: [].concat(linkedLinks),
          value: linkValue || 1,
          health: isFailing ? healthByTime : 'healthy'
        },
        {
          source: providerNode,
          target: targetNode,
          pathLinks: [].concat(linkedLinks),
          value: linkValue || 1,
          health: healthByTime
        }
      );
    }

    this.redraw();
  }

  redraw() {
    const { $app } = this.props;

    // only do drawing if there's something to draw
    if (this.graph.nodes.length && this.graph.links.length) {
      $app.renderSync(() => {
        this.drawSankey(this.graph);
      });
    }
  }

  reflow() {
    this.redraw();
  }

  clear() {
    this.graph.nodes = [];
    this.graph.links = [];
  }

  chartRef = (ref) => {
    if (ref) {
      this.chart = ref;
    }

    const { $dataviews } = this.props;
    $dataviews.register(this);

    this.resizeListener = debounce(() => this.reflow(), 200);
    window.addEventListener('resize', this.resizeListener);
  };

  render() {
    return <SankeyContainer style={{ flex: 1 }} ref={this.chartRef} />;
  }
}

export default withTheme(SyntheticsSankey);
