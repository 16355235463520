export const fetchAlertsParameters = {
  states: ['alarm', 'clear'],
  applications: ['kmetrics'], // only kmetrics (NMS)
  lookback: 604800,
  sorting: {
    fields: [
      {
        name: 'start_time',
        order: 'SORT_ORDER_DESCENDING'
      }
    ]
  }
  // limit: 1000
};
