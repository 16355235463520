import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { Flex, FlexColumn, Card } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import { ViewInExplorerButton } from 'app/components/dataviews/tools';
import ResultsTable from 'app/components/resultsTable/ResultsTable';

@inject('$alerting', '$dataviews')
@observer
export default class DDosFactorsTab extends Component {
  static propTypes = {
    onQueryComplete: PropTypes.func,
    // ID of ddos factors query
    queryId: PropTypes.string.isRequired,
    // the alert model
    model: PropTypes.object.isRequired
  };

  static defaultProps = {
    onQueryComplete: noop
  };

  static getDerivedStateFromProps(props) {
    const { $alerting, model, queryId } = props;
    return {
      query: $alerting.getDdosFactorsQuery(model, queryId)
    };
  }

  state = {
    // eslint-disable-next-line react/destructuring-assignment
    query: this.props.$alerting.getDdosFactorsQuery(this.props.model, this.props.queryId)
  };

  handleQueryComplete = ({ results, dataview, query, queryModel, fullyLoaded }) => {
    const { onQueryComplete } = this.props;

    onQueryComplete({ results, dataview, query, queryModel, fullyLoaded });
  };

  render() {
    const { $dataviews } = this.props;
    const { query } = this.state;

    return (
      <LightweightDataViewWrapper query={query} onQueryComplete={this.handleQueryComplete} allowCache={false}>
        {({ loading, dataview, queryModel, results: queryResultsCollection, bucket }) => {
          const { viewType, lastUpdated } = dataview;
          let chart = null;

          if ($dataviews.hasViewModel(viewType) && query.viz_type !== 'table') {
            const ViewComponent = $dataviews.getComponent(viewType);

            chart = (
              <ViewComponent
                query={query}
                dataview={dataview}
                lastUpdated={lastUpdated}
                isViewCmp
                loading={loading}
                viewProps={{ showNativeLegend: false, showTooltips: true }}
              />
            );
          }

          return (
            <FlexColumn p={3} pl={0}>
              {chart ? (
                <FlexColumn mb={2}>
                  <Flex height={400}>{chart}</Flex>
                  <ViewInExplorerButton ml="auto" query={query} minimal small />
                </FlexColumn>
              ) : null}
              <Card flex={1} display="flex" flexDirection="column">
                <Flex height={420} overflow="auto">
                  <ResultsTable
                    bucket={bucket}
                    dataview={dataview}
                    loading={loading}
                    query={query}
                    queryModel={queryModel}
                    queryResultsCollection={queryResultsCollection}
                    showLastDatapoints
                    showDeviceForInterface={false}
                    showSparklines={false}
                  />
                </Flex>
              </Card>
            </FlexColumn>
          );
        }}
      </LightweightDataViewWrapper>
    );
  }
}
