import React, { Component } from 'react';
import { Checkbox, Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import { Icon } from 'core/components';
import MenuItemLight from 'core/components/lightweight/MenuItemLight';

const menuItemStyles = { margin: '0 8px', padding: '2px 4px', breakInside: 'avoid', overflow: 'hidden' };
const multiselectClassName = classNames(Classes.CONTROL, Classes.CHECKBOX);
const singleSelectStyles = { display: 'flex', alignItems: 'flex-start', width: '100%', overflow: 'hidden' };
const singleSelectIconWrapperStyles = { marginTop: -3, marginRight: 3 };
const itemTextStyles = { whiteSpace: 'normal', flex: 1 };

const MultiSelectItemText = ({ disabled, option, selected, multi }) => {
  const { iconCls, label } = option;

  if (!multi) {
    return (
      <div className={Classes.POPOVER_DISMISS} style={singleSelectStyles}>
        <div style={singleSelectIconWrapperStyles}>
          <Icon icon={selected ? 'tick-circle' : 'dot'} iconSize={10} />
        </div>
        <span className={Classes.TEXT_OVERFLOW_ELLIPSIS} style={itemTextStyles}>
          {label}
        </span>
      </div>
    );
  }

  return (
    <>
      <Checkbox checked={selected} disabled={disabled} className="no-margin" onClick={(e) => e.stopPropagation()} />
      <span className={iconCls} style={{ marginRight: iconCls ? 8 : 0 }} />
      <span className={Classes.TEXT_OVERFLOW_ELLIPSIS} style={itemTextStyles}>
        {label}
      </span>
    </>
  );
};

class ModalOptionItem extends Component {
  static defaultProps = {
    disabled: false,
    selected: false
  };

  handleSelectItem = () => {
    const { field, selectItem, option } = this.props;
    selectItem(field, option.value);
  };

  shouldComponentUpdate(nextProps) {
    const { disabled, selected } = this.props;
    return disabled !== nextProps.disabled || selected !== nextProps.selected;
  }

  render() {
    const { disabled, multi, selected, option, field } = this.props;

    return (
      <MenuItemLight
        disabled={disabled}
        onClick={this.handleSelectItem}
        className={multi ? multiselectClassName : undefined}
        style={menuItemStyles}
      >
        <MultiSelectItemText option={option} disabled={disabled} selected={selected} field={field} multi={multi} />
      </MenuItemLight>
    );
  }
}

export default ModalOptionItem;
