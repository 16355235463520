import { css } from 'styled-components';
import {
  gridGap,
  gridRowGap,
  gridColumnGap,
  gridColumn,
  gridRow,
  gridArea,
  gridAutoFlow,
  gridAutoRows,
  gridAutoColumns,
  gridTemplateRows,
  gridTemplateColumns,
  gridTemplateAreas
} from 'styled-system';

const gridProps = css`
  ${gridGap};
  ${gridRowGap};
  ${gridColumnGap};
  ${gridColumn};
  ${gridRow};
  ${gridArea};
  ${gridAutoFlow};
  ${gridAutoRows};
  ${gridAutoColumns};
  ${gridTemplateRows};
  ${gridTemplateColumns};
  ${gridTemplateAreas};
`;

export default gridProps;
