import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { FiSliders } from 'react-icons/fi';

import { Box, Flex, Highcharts, Text } from 'core/components';

import SidebarCard from 'app/components/landing/SidebarCard';
import ModuleLinkButton from 'app/components/landing/ModuleLinkButton';

function generateConfig(summary) {
  const data = [
    ['Healthy', summary.interfaces - summary.interfacesWarning - summary.interfacesCritical],
    ['Warning', summary.interfacesWarning],
    ['Critical', summary.interfacesCritical]
  ];

  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    plotOptions: {
      series: {
        states: { hover: { enabled: false } }
      },
      pie: {
        allowPointSelect: false,
        size: '100%',
        innerSize: '60%', // make it a donut
        dataLabels: {
          enabled: false
        }
      }
    },
    tooltip: { enabled: false },
    series: [
      {
        name: 'Foo',
        data
      }
    ]
  };
}

@inject('$capacity')
@withTheme
@observer
class CapacityCard extends Component {
  state = {
    capacitySummary: {},
    fetchingCapacitySummary: true
  };

  componentDidMount() {
    const { $capacity } = this.props;

    $capacity.fetchCapacityPlansOverview().then((res) => {
      this.setState({
        capacitySummary: res,
        fetchingCapacitySummary: false
      });
    });
  }

  renderGauge() {
    const { capacitySummary } = this.state;
    const { theme } = this.props;

    if (capacitySummary.interfacesCritical === 0 && capacitySummary.interfacesWarning === 0) {
      return null;
    }

    const [healthyColor, warningColor, , criticalColor] = theme.colors.pie;

    return (
      <Box height="100%" width={150} mt={-2} mx={-2} mb={-3}>
        <Highcharts
          options={generateConfig(capacitySummary)}
          colors={[healthyColor, warningColor, criticalColor]}
          height={120}
        />
      </Box>
    );
  }

  render() {
    const { theme } = this.props;
    const [healthyColor, warningColor, , criticalColor] = theme.colors.pie;
    const { capacitySummary, fetchingCapacitySummary } = this.state;

    return (
      <SidebarCard
        icon={FiSliders}
        title="Capacity Planning"
        loading={fetchingCapacitySummary}
        isConfigured={capacitySummary.plans > 0}
        notConfiguredText="You are not currently monitoring interface utilization."
        to="/v4/core/capacity"
        mb={2}
      >
        <Flex alignItems="center" mb="4px">
          {this.renderGauge()}
          <Box flex={1}>
            {capacitySummary.interfacesCritical > 0 && (
              <Flex alignItems="flex-start" mb={1}>
                <Box bg={criticalColor} borderRadius={4} mt="6px" width={16} height={5} mr={1} />
                <Text as="div">
                  <Text fontWeight="bold">{capacitySummary.interfacesCritical}</Text> critical
                </Text>
              </Flex>
            )}
            {capacitySummary.interfacesWarning > 0 && (
              <Flex alignItems="flex-start" mb={1}>
                <Box bg={warningColor} borderRadius={4} mt="6px" width={16} height={5} mr={1} />
                <Text as="div">
                  <Text fontWeight="bold">{capacitySummary.interfacesWarning}</Text> warning
                </Text>
              </Flex>
            )}

            <Flex alignItems="flex-start">
              <Box bg={healthyColor} borderRadius={4} mt="6px" width={16} height={5} mr={1} />
              <Text as="div">
                <Text fontWeight="bold">
                  {capacitySummary.interfaces - capacitySummary.interfacesCritical - capacitySummary.interfacesWarning}
                </Text>{' '}
                healthy
              </Text>
            </Flex>
          </Box>
        </Flex>
        <ModuleLinkButton pt={1} to="/v4/core/capacity" text="Capacity Planning" />
      </SidebarCard>
    );
  }
}

export default CapacityCard;
