import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Select } from 'core/components';

const valueRenderer = (option) => option?.label || null;

@observer
class GroupByControl extends Component {
  static defaultProps = {
    options: [[{ label: 'None', value: '' }]]
  };

  state = {
    grouping: ''
  };

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState() {
    const { collection } = this.props;
    const grouping = collection.groupBy || '';

    this.handleGroupByChange(grouping);
  }

  handleGroupByChange = (grouping) => {
    this.setState({ grouping });

    setTimeout(() => {
      const { collection } = this.props;
      collection.group(grouping || null);
    }, 0);
  };

  render() {
    const { collection, options, ...selectProps } = this.props;
    const { grouping } = this.state;

    return (
      <Select
        inlineLabel
        label="Group By"
        menuWidth={180}
        onChange={this.handleGroupByChange}
        options={options}
        small
        values={grouping}
        valueRenderer={valueRenderer}
        {...selectProps}
      />
    );
  }
}

export default GroupByControl;
