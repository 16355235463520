const { get: _get } = require('lodash');

// firewall subnet MUST be named as AzureFirewallSubnet according to azure documentation
const AZURE_SUBNET_FIREWALL_NAME = 'AzureFirewallSubnet';

const KENTIK_DEFAULT_GCP_PROJECT = 'kentik-vpc-flow';
const KENTIK_STORAGE_BUCKET = 'kentik_cloud_maps_metadata';

const KENTIK_KB_LINK_AWS = 'https://kb.kentik.com/v0/Bd06.htm';
const KENTIK_KB_LINK_GCP = 'https://kb.kentik.com/v0/Bd07.htm';
const KENTIK_KB_LINK_OCI = 'https://kb.kentik.com/v0/Bd12.htm';
const KENTIK_KB_LINK_AZURE = 'https://kb.kentik.com/v0/Bd08.htm';

// all clouds will enforce same entity types for the kube
const KUBE_ENTITY_TYPES = {
  POD: 'pods',
  NODE: 'nodes',
  REGION: 'regions',
  CLUSTER: 'clusters',
  SERVICE: 'services',
  INGRESS: 'ingresses',
  RESOURCE: 'resources',
  NAMESPACE: 'namespaces',
  DEPLOYMENT: 'deployments',
  REPLICASET: 'replicasets'
};

const AWS_ENTITY_TYPES = {
  VPC: 'Vpcs',
  REGION: 'Regions',
  SUBNET: 'Subnets',
  ACCOUNT: 'Accounts',
  INSTANCE: 'Instances',
  RESERVATION: 'Reservations',
  INSTANCE_SUMMARY: 'InstanceSummary',
  NETWORK_INTERFACE: 'NetworkInterfaces',
  VIRTUAL_INTERFACE: 'VirtualInterfaces',
  VPC_PEERING_CONNECTION: 'VpcPeeringConnections',
  LOAD_BALANCER_DESCRIPTION: 'LoadBalancerDescription',

  CORE_NETWORK: 'CoreNetworks',
  CORE_NETWORK_EDGE: 'CoreNetworkEdges',
  CORE_NETWORK_SEGMENT: 'CoreNetworkSegments',
  CORE_NETWORK_ATTACHMENT: 'CoreNetworkAttachments',

  CUSTOMER_GATEWAY: 'CustomerGateways',
  DIRECT_CONNECTION: 'DirectConnections',
  DIRECT_CONNECTION_GATEWAY: 'DirectConnectGateways',

  NAT_GATEWAY: 'NatGateways',
  INTERNET_GATEWAY: 'InternetGateways',
  INTERNET_GATEWAY_ATTACHMENT: 'InternetGatewayAttachments',

  VPN_GATEWAY: 'VpnGateways',
  VPN_CONNECTION: 'VpnConnections',

  TRANSIT_GATEWAY: 'TransitGateways',
  TRANSIT_GATEWAY_CONNECT: 'TransitGatewayConnects',
  TRANSIT_GATEWAY_ATTACHMENT: 'TransitGatewayAttachments',
  TRANSIT_GATEWAY_ROUTE_TABLE: 'TransitGatewayRouteTables',
  TRANSIT_GATEWAY_CONNECT_PEER: 'TransitGatewayConnectPeers',
  TRANSIT_GATEWAY_VPC_ATTACHMENT: 'TransitGatewayVpcAttachments',

  PREFIX_LIST: 'PrefixLists',
  MANAGED_PREFIX_LIST: 'ManagedPrefixLists',

  LAG: 'Lags',
  ROUTER: 'Routers',
  FLOW_LOG: 'FlowLogs',
  ROUTE_TABLE: 'RouteTables',
  NETWORK_ACL: 'NetworkAcls',
  VPC_ENDPOINT: 'VpcEndpoints',
  LOAD_BALANCER: 'LoadBalancers',
  SECURITY_GROUP: 'SecurityGroups',
  AVAILABILITY_ZONE: 'AvailabilityZones',

  FIREWALL: 'Firewalls',
  FIREWALL_POLICY: 'FirewallPolicies',
  FIREWALL_RULE_GROUP: 'FirewallRuleGroups'
};

// all properties withing all aws entities that has accounts id association
const AWS_ACCOUNT_PROPERTIES = ['OwnerId', 'OwnerAccount', 'ResourceOwnerId', 'TransitGatewayOwnerId'];
const AWS_ACCOUNT_SUMMARY_ENTITIES = [
  AWS_ENTITY_TYPES.VPC,
  AWS_ENTITY_TYPES.SUBNET,
  AWS_ENTITY_TYPES.VPN_GATEWAY,
  AWS_ENTITY_TYPES.CORE_NETWORK,
  AWS_ENTITY_TYPES.TRANSIT_GATEWAY,
  AWS_ENTITY_TYPES.INTERNET_GATEWAY
];

const AZURE_ENTITY_TYPES = {
  // VNET
  VNET: 'vnets',
  VNET_GATEWAY: 'vnetGateways',
  VNET_PEERING: 'vnetPeerings',
  VNET_GATEWAY_CONNECTION: 'vnetGatewayConnections',

  SUBNET: 'subnets',
  ROUTER: 'routers',
  LOCATION: 'locations',
  FIREWALL: 'azureFirewalls',
  SUBSCRIPTION: 'subscriptions',
  LOAD_BALANCER: 'loadBalancers',

  NETWORK_INTERFACE: 'networkInterfaces',
  NETWORK_SECURITY_GROUP: 'networkSecurityGroups',

  NAT_GATEWAY: 'natGateways',
  APPLICATION_GATEWAY: 'applicationGateways',
  LOCAL_NETWORK_GATEWAY: 'localNetworkGateways',

  ROUTE_TABLE: 'routeTables',
  RESOURCE_GROUP: 'resourceGroups',
  PUBLIC_IP_ADDRESS: 'publicIPAddresses',

  // VPN ENTITIES
  VPN_SITE: 'vpnSites',
  VPN_GATEWAY: 'vpnGateways',
  VPN_CONNECTIONS: 'vpnConnections',
  VPN_LINK_CONNECTION: 'vpnLinkConnections',

  P2S_VPN_GATEWAY: 'p2SVpnGateways',
  P2S_CONNECTION_CONFIGURATION: 'p2sConnectionConfigurations',

  // HUB / WAN
  VIRTUAL_WAN: 'virtualWans',
  VIRTUAL_HUB: 'virtualHubs',
  VIRTUAL_MACHINE: 'virtualMachines',
  HUB_VIRTUAL_NETWORK_CONNECTION: 'hubVirtualNetworkConnections',

  // EXPRESS ROUTE
  EXPRESS_ROUTE_CIRCUIT: 'expressRouteCircuits',
  EXPRESS_ROUTE_GATEWAY: 'expressRouteGateways',
  EXPRESS_ROUTE_CONNECTION: 'expressRouteConnections',

  FLOW_LOG: 'flowLogs',
  NETWORK_WATCHER: 'networkWatchers'
};

const GCP_ENTITY_TYPES = {
  BACKEND_SERVICE: 'backendServices',
  CLOUD_ROUTER: 'cloudRouters',
  EXTERNAL_VPN_GATEWAY: 'externalVpnGateways',
  FIREWALL: 'firewalls',
  FIREWALL_POLICY: 'firewallPolicies',
  FIREWALL_RULE: 'firewallRules',
  FORWARDING_RULE: 'forwardingRules',
  GLOBAL_FORWARDING_RULE: 'globalForwardingRules',
  GLOBAL_NETWORK_ENDPOINT_GROUP: 'globalNetworkEndpointGroups',
  HEALTH_CHECK: 'healthChecks',
  INSTANCE: 'instances',
  INSTANCE_GROUP: 'instanceGroups',
  INTERCONNECT: 'interconnects',
  INTERCONNECT_ATTACHMENT: 'interconnectAttachments',
  LOAD_BALANCER: 'loadBalancers',
  NAT_GATEWAY: 'natGateways',
  NETWORK: 'networks',
  NETWORK_ENDPOINT_GROUP: 'networkEndpointGroups',
  NETWORK_FIREWALL_POLICY: 'networkFirewallPolicies',
  PROJECT: 'projects',
  PUBLIC_IP_ADDRESS: 'publicIPAddresses',
  REGION: 'regions',
  REGION_BACKEND_SERVICE: 'regionBackendServices',
  REGION_HEALTH_CHECK: 'regionHealthChecks',
  REGION_HEALTH_CHECK_SERVICE: 'regionHealthCheckServices',
  REGION_INSTANCE_GROUP: 'regionInstanceGroups',
  REGION_NETWORK_ENDPOINT_GROUP: 'regionNetworkEndpointGroups',
  REGION_NETWORK_FIREWALL_POLICY: 'regionNetworkFirewallPolicies',
  REGION_SECURITY_POLICY: 'regionSecurityPolicies',
  REGION_TARGET_HTTP_PROXY: 'regionTargetHttpProxies',
  REGION_TARGET_HTTPS_PROXY: 'regionTargetHttpsProxies',
  REGION_TARGET_TCP_PROXY: 'regionTargetTcpProxies',
  REGION_URL_MAP: 'regionUrlMaps',
  ROUTER: 'routers',
  ROUTE: 'routes',
  ROUTE_TABLE: 'routeTables',
  SUBNET: 'subnets',
  TARGET_INSTANCE: 'targetInstances',
  TARGET_GRPC_PROXY: 'targetGrpcProxies',
  TARGET_HTTP_PROXY: 'targetHttpProxies',
  TARGET_HTTPS_PROXY: 'targetHttpsProxies',
  TARGET_SSL_PROXY: 'targetSslProxies',
  TARGET_TCP_PROXY: 'targetTcpProxies',
  TARGET_POOL: 'targetPools',
  TARGET_VPN_GATEWAY: 'targetVpnGateways',
  URL_MAP: 'urlMaps',
  VPN_TUNNEL: 'vpnTunnels',
  VPN_GATEWAY: 'vpnGateways',
  ZONE: 'zones'
};

const OCI_ENTITY_TYPES = {
  ROUTER: 'routers',
  SUBNET: 'subnets',
  REGION: 'regions',
  VIRTUAL_CLOUD_NETWORK: 'vcn',
  VIRTUAL_CIRCUIT: 'virtualCircuits',

  // logging
  LOGS: 'logs',
  LOG_GROUPS: 'logGroups',
  CAPTURE_FILTERS: 'captureFilters',

  // security
  SECURITY_LIST: 'securityLists',
  NETWORK_SECURITY_GROUP: 'networkSecurityGroups',

  // route tables
  ROUTE_TABLE: 'route_tables',
  DRG_ROUTE_TABLE: 'drgRouteTables',

  // connections
  CROSS_CONNECT: 'crossConnects',
  /**
   * A connection between a DRG and CPE. This connection consists of multiple IPSec tunnels. Creating this connection is one of the steps required when setting up a Site-to-Site VPN.
   */
  IP_SEC_CONNECTION: 'ipSecConnections', // this is Site-to-Site VPN
  IP_SEC_CONNECTION_TUNNEL: 'ipSecConnectionTunnels',

  // https://docs.oracle.com/en-us/iaas/api/#/en/iaas/20160918/Cpe/
  CPE: 'cpe', // virtual representation of your customer-premises equipment
  VNIC: 'vnics',
  INSTANCE: 'instances',
  VNIC_ATTACHMENTS: 'vnicAttachments',

  // Gateways
  /**
   * A NAT (Network Address Translation) gateway, which represents a router that lets instances without public IPs contact the public internet without exposing the instance to inbound internet traffic.
   */
  NAT_GATEWAY: 'natGateways',

  /**
   * Represents a router that connects the edge of a VCN with the Internet.
   */
  INTERNET_GATEWAY: 'internetGateways',

  /**
   * Represents a router that lets your VCN privately access specific Oracle services such as Object Storage without exposing the VCN to the public internet.
   */
  SERVICE_GATEWAY: 'serviceGateways',

  /**
   * A local peering gateway (LPG) is an object on a VCN that lets that VCN peer with another VCN in the same region.
   */
  LOCAL_PEERING_GATEWAY: 'localPeeringGateways',

  /**
   * A dynamic routing gateway (DRG) is a virtual router that provides a path for private network traffic between networks.
   * Used with other Networking Service components to create a connection to your on-premises network using Site-to-Site VPN or a connection that uses FastConnect
   */
  DYNAMIC_ROUTING_GATEWAY: 'dynamicRoutingGateways',
  REMOTE_PEERING_CONNECTION: 'remotePeeringConnections',
  DYNAMIC_ROUTING_GATEWAY_ATTACHMENT: 'dynamicRoutingGatewayAttachments',
  DYNAMIC_ROUTING_GATEWAY_ROUTE_DISTRIBUTION: 'drgRouteDistribution'
};

// assembles entity types by cloud provider and exposes a flexible getter
const ENTITY_TYPES = {
  GCP: GCP_ENTITY_TYPES,
  OCI: OCI_ENTITY_TYPES,

  // aws
  AWS: AWS_ENTITY_TYPES,
  KUBE: KUBE_ENTITY_TYPES,

  AZURE: AZURE_ENTITY_TYPES,

  get(selector) {
    return _get(this, String(selector).toUpperCase(), null);
  }
};

const MAP_TYPES = {
  AWS: {
    CLOUD: ['gateway', 'subnet'],
    ON_PREM: ['Lags', 'DirectConnectGateways', 'DirectConnections', 'VpnConnections'],
    VPC_CONNECTIONS: [
      AWS_ENTITY_TYPES.INTERNET_GATEWAY,
      AWS_ENTITY_TYPES.VPC_PEERING_CONNECTION,
      AWS_ENTITY_TYPES.TRANSIT_GATEWAY_ATTACHMENT,
      AWS_ENTITY_TYPES.VPN_GATEWAY,
      AWS_ENTITY_TYPES.NAT_GATEWAY,
      AWS_ENTITY_TYPES.VPC_ENDPOINT,
      AWS_ENTITY_TYPES.CORE_NETWORK_ATTACHMENT
    ],
    MAP_TOP_LEVEL_CONNECTIONS: [
      AWS_ENTITY_TYPES.ROUTER,
      AWS_ENTITY_TYPES.VPN_CONNECTION,
      AWS_ENTITY_TYPES.CUSTOMER_GATEWAY,
      AWS_ENTITY_TYPES.DIRECT_CONNECTION,
      AWS_ENTITY_TYPES.DIRECT_CONNECTION_GATEWAY
    ],
    SUPPORTS_METRICS: [
      AWS_ENTITY_TYPES.NAT_GATEWAY,
      AWS_ENTITY_TYPES.VPC_ENDPOINT,
      AWS_ENTITY_TYPES.TRANSIT_GATEWAY,
      AWS_ENTITY_TYPES.CORE_NETWORK_EDGE,
      AWS_ENTITY_TYPES.DIRECT_CONNECTION,
      AWS_ENTITY_TYPES.CORE_NETWORK_ATTACHMENT,
      AWS_ENTITY_TYPES.TRANSIT_GATEWAY_ATTACHMENT
    ]
  },
  OCI: {
    ON_PREM: [OCI_ENTITY_TYPES.ROUTER],
    GATEWAY_TYPES: [OCI_ENTITY_TYPES.INTERNET_GATEWAY],
    SUPPORTS_METRICS: [
      OCI_ENTITY_TYPES.NAT_GATEWAY,
      OCI_ENTITY_TYPES.SERVICE_GATEWAY,
      OCI_ENTITY_TYPES.INTERNET_GATEWAY,
      OCI_ENTITY_TYPES.IP_SEC_CONNECTION,
      OCI_ENTITY_TYPES.DYNAMIC_ROUTING_GATEWAY,
      OCI_ENTITY_TYPES.DYNAMIC_ROUTING_GATEWAY_ATTACHMENT
    ]
  },
  AZURE: {
    CLOUD: [
      AZURE_ENTITY_TYPES.NAT_GATEWAY,
      AZURE_ENTITY_TYPES.SUBNET,
      AZURE_ENTITY_TYPES.VNET_GATEWAY,
      AZURE_ENTITY_TYPES.VNET_PEERING,
      AZURE_ENTITY_TYPES.LOAD_BALANCER,
      AZURE_ENTITY_TYPES.APPLICATION_GATEWAY
    ],
    ON_PREM: [AZURE_ENTITY_TYPES.LOCAL_NETWORK_GATEWAY, AZURE_ENTITY_TYPES.ROUTER],
    VNET_CONNECTIONS: [
      AZURE_ENTITY_TYPES.NAT_GATEWAY,
      AZURE_ENTITY_TYPES.VNET_GATEWAY,
      AZURE_ENTITY_TYPES.VNET_PEERING
    ],
    VHUB_CONNECTIONS: [
      AZURE_ENTITY_TYPES.VPN_GATEWAY,
      AZURE_ENTITY_TYPES.FIREWALL,
      AZURE_ENTITY_TYPES.EXPRESS_ROUTE_GATEWAY,
      AZURE_ENTITY_TYPES.P2S_VPN_GATEWAY,
      AZURE_ENTITY_TYPES.HUB_VIRTUAL_NETWORK_CONNECTION
    ],
    // 'gateways' are important here for determining whether an entity can be classified as a 'gateway' when assembling traffic link queries in getNodeLinkQueries
    GATEWAYS: [
      AZURE_ENTITY_TYPES.NAT_GATEWAY,
      AZURE_ENTITY_TYPES.VNET_GATEWAY,
      AZURE_ENTITY_TYPES.VNET_PEERING,
      AZURE_ENTITY_TYPES.VPN_GATEWAY
    ],
    // entities that are selectable via 'handleSelectNode' in the map
    // they will present a popover with options such as viewing details and showing traffic link connections
    SELECTABLE_ENTITIES: [
      'internet',
      AZURE_ENTITY_TYPES.SUBNET,
      AZURE_ENTITY_TYPES.NAT_GATEWAY,
      AZURE_ENTITY_TYPES.VNET_GATEWAY,
      AZURE_ENTITY_TYPES.VNET_PEERING,
      AZURE_ENTITY_TYPES.VPN_GATEWAY,
      AZURE_ENTITY_TYPES.FIREWALL,
      AZURE_ENTITY_TYPES.EXPRESS_ROUTE_GATEWAY,
      AZURE_ENTITY_TYPES.LOAD_BALANCER,
      AZURE_ENTITY_TYPES.P2S_VPN_GATEWAY,
      AZURE_ENTITY_TYPES.APPLICATION_GATEWAY,
      AZURE_ENTITY_TYPES.HUB_VIRTUAL_NETWORK_CONNECTION
    ],
    SUPPORTS_METRICS: [
      AZURE_ENTITY_TYPES.EXPRESS_ROUTE_CIRCUIT,
      AZURE_ENTITY_TYPES.VNET_GATEWAY_CONNECTION,
      AZURE_ENTITY_TYPES.VNET,
      AZURE_ENTITY_TYPES.APPLICATION_GATEWAY,
      AZURE_ENTITY_TYPES.FIREWALL,
      AZURE_ENTITY_TYPES.VNET_GATEWAY,
      AZURE_ENTITY_TYPES.LOAD_BALANCER,
      AZURE_ENTITY_TYPES.NAT_GATEWAY,
      AZURE_ENTITY_TYPES.VPN_GATEWAY,
      AZURE_ENTITY_TYPES.EXPRESS_ROUTE_GATEWAY,
      AZURE_ENTITY_TYPES.P2S_VPN_GATEWAY
    ]
  },
  GCP: {
    CLOUD: [GCP_ENTITY_TYPES.SUBNET],
    SELECTABLE_ENTITIES: ['internet', GCP_ENTITY_TYPES.SUBNET],
    REGIONAL_ENTITIES: [
      GCP_ENTITY_TYPES.CLOUD_ROUTER,
      GCP_ENTITY_TYPES.TARGET_VPN_GATEWAY,
      GCP_ENTITY_TYPES.NAT_GATEWAY
    ],
    SUPPORTS_METRICS: [
      GCP_ENTITY_TYPES.CLOUD_ROUTER,
      GCP_ENTITY_TYPES.INTERCONNECT_ATTACHMENT,
      GCP_ENTITY_TYPES.INTERCONNECT,
      GCP_ENTITY_TYPES.NAT_GATEWAY,
      GCP_ENTITY_TYPES.REGION,
      GCP_ENTITY_TYPES.SUBNET,
      GCP_ENTITY_TYPES.VPN_TUNNEL,
      GCP_ENTITY_TYPES.VPN_GATEWAY
    ]
  },

  get(selector) {
    return _get(this, String(selector).toUpperCase(), {
      CLOUD: [],
      GATEWAYS: [],
      ON_PREM: [],
      SELECTABLE_ENTITIES: [],
      VNET_CONNECTIONS: [],
      VPC_CONNECTIONS: []
    });
  }
};

// Per product...this number is somewhat arbitrary.
// Can be adjusted or made dynamic in future if necessary.
const MAX_SAMPLING_RATE = 2000;
const MIN_SAMPLING_RATE = 2;
const MAX_ALLOWED_SELECTED_SUBSCRIPTIONS = 25;

const CLOUD_KB_LINKS = {
  AWS: {
    MAIN: KENTIK_KB_LINK_AWS
  },
  GCP: {
    MAIN: KENTIK_KB_LINK_GCP
  },
  OCI: {
    MAIN: KENTIK_KB_LINK_OCI
  },
  AZURE: {
    MAIN: KENTIK_KB_LINK_AZURE
  },
  get(selector) {
    return _get(this, String(selector).toUpperCase(), null);
  }
};

module.exports = {
  MAP_TYPES,
  ENTITY_TYPES,
  CLOUD_KB_LINKS,
  GCP_ENTITY_TYPES,
  AWS_ENTITY_TYPES,
  OCI_ENTITY_TYPES,
  MAX_SAMPLING_RATE,
  MIN_SAMPLING_RATE,
  KUBE_ENTITY_TYPES,
  AZURE_ENTITY_TYPES,
  KENTIK_STORAGE_BUCKET,
  AWS_ACCOUNT_PROPERTIES,
  KENTIK_DEFAULT_GCP_PROJECT,
  AZURE_SUBNET_FIREWALL_NAME,
  AWS_ACCOUNT_SUMMARY_ENTITIES,
  MAX_ALLOWED_SELECTED_SUBSCRIPTIONS
};
