import React, { Suspense } from 'react';
import { Flex, Spinner } from 'core/components';
import Navbar from 'app/components/navbar/Navbar';

const defaultOptions = {
  enablePageNav: true
};

const createFallback = ({ enablePageNav = true }) => (
  <>
    {enablePageNav && <Navbar bg="navbarBackground" onMenuToggle={() => {}} />}
    <Flex flex="1 1 100%" alignItems="center" justifyContent="center">
      <Spinner />
    </Flex>
  </>
);

function retryOrReload(fn, options) {
  const { retriesLeft = 1, interval = 1000 } = options || {};
  const name = `chunkRefreshed${window.location.pathname}`;

  return new Promise((resolve, reject) => {
    fn()
      .then((...args) => {
        if (window.localStorage) {
          window.localStorage.removeItem(name);
        }
        return resolve(...args);
      })
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 0) {
            if (window.localStorage && !window.localStorage.getItem(name)) {
              window.localStorage.setItem(name, 'true');
              console.info('Setting', name);
              window.location.reload(true);
            } else {
              reject(error);
            }
          } else {
            console.info('Retrying', name);
            retryOrReload(fn, { retriesLeft: retriesLeft - 1, interval }).then(resolve, reject);
          }
        }, interval);
      });
  });
}

/**
 *
 * @param fn
 * @param options
 * @param [options.enablePageNav] { boolean }
 * @param [options.fallback] { Element }
 * @returns {function(*): *}
 */
export default function lazyLoad(fn, options = defaultOptions) {
  const { fallback } = options;
  const Component = React.lazy(() => retryOrReload(fn));

  return (props) => (
    <Suspense fallback={React.isValidElement(fallback) ? fallback : createFallback(options)}>
      <Component {...props} />
    </Suspense>
  );
}
