import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { space, fontWeight, fontFamily, fontSize, display, themeGet } from 'styled-system';
import { Button as BlueprintButton, Classes } from '@blueprintjs/core';
import { rgba } from 'polished';
import classNames from 'classnames';
import { get } from 'lodash';

import Icon from './Icon';
import position from './utils/position';
import flex from './utils/flex';
import size from './utils/size';

const StyledButton = styled(BlueprintButton)`
  &.${Classes.BUTTON} {
    ${display};
    ${fontFamily};
    ${fontSize};
    ${fontWeight};
    ${space};

    ${position};
    ${flex};
    ${size};

    ${(props) =>
      props.small &&
      css`
        font-size: ${props.theme.fontSizes.small};

        .bp4-icon {
          &:first-child {
            margin-right: 4px;
          }

          &:first-child:last-child {
            margin: 0 -7px;
          }
        }
      `}

    > .${Classes.BUTTON_TEXT} {
      overflow: hidden;
      flex: 1 1 auto;
      text-align: left;
      color: ${(props) => themeGet(`colors.${props.color}`, props.color)};
    }

    &.align-center {
      > .${Classes.BUTTON_TEXT} {
        text-align: center;
      }
    }

    ${(props) =>
      props.ellipsis &&
      css`
        > .${Classes.BUTTON_TEXT} {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      `}

    &.translucent.${Classes.MINIMAL} {
      background: ${({ theme }) => rgba(theme.colors.gray5, 0.3)};

      &:hover {
        background: ${({ theme }) => rgba(theme.colors.gray3, 0.3)};
      }
    }
  }
`;

function Button(props) {
  // destructure minWidth and alignSelf, otherwise we get non-standard prop warnings from React.
  const {
    active,
    activeBg,
    alignSelf,
    border,
    minHeight,
    minWidth,
    maxWidth,
    borderRadius,
    className,
    color,
    icon,
    rightIcon,
    large,
    small,
    justifyContent,
    shouldDismissPopover,
    iconSize,
    width,
    height,
    textAlign,
    textColor,
    title,
    theme,
    staticContext,
    bg,
    flexDirection,
    alignItems,
    flexWrap,
    tracker,
    ...rest
  } = props;

  let finalIconSize = 16;
  if (iconSize) {
    finalIconSize = iconSize;
  } else if (large) {
    finalIconSize = 20;
  } else if (small) {
    finalIconSize = 12;
  }

  let background = bg;
  if (active && activeBg) {
    background = activeBg;
  }

  const buttonClassName = classNames(className, tracker, { 'align-center': textAlign === 'center' });
  return (
    <StyledButton
      {...rest}
      active={active}
      large={large}
      small={small}
      className={buttonClassName}
      style={{
        alignSelf,
        alignItems,
        flexWrap,
        border,
        minHeight,
        minWidth,
        maxWidth,
        borderRadius,
        justifyContent,
        flexDirection,
        width,
        height,
        backgroundColor: get(theme.colors, background),
        color: get(theme.colors, textColor || color),
        ...(rest.style || {})
      }}
      title={rest.ellipsis ? rest.text : title}
      icon={icon ? <Icon icon={icon} color={color} iconSize={finalIconSize} /> : undefined}
      rightIcon={rightIcon ? <Icon icon={rightIcon} color={color} iconSize={finalIconSize} /> : undefined}
    />
  );
}

Button.defaultProps = {
  fontFamily: 'body',
  textAlign: 'center'
};

Button.displayName = 'Button';

export default withTheme(Button);
