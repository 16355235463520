const promiseMap = {};

/**
 * Return promiseCollationDetails object for provided key, creating one if one is not already present.
 * @param key
 * @returns {*} promiseCollationDetails object:
 *   {
 *     queue: [],
 *     masterPromise: <promise>,
 *     resolve: <masterPromise resolve fn>
 *     reject: <masterPromise reject fn>
 *   }
 */
function getPromiseCollationDetails({ key }) {
  if (promiseMap[key]) {
    return promiseMap[key];
  }
  const promiseDetails = { queue: [] };
  promiseDetails.masterPromise = new Promise((resolve, reject) => {
    promiseDetails.resolve = resolve;
    promiseDetails.reject = reject;
  });
  promiseMap[key] = promiseDetails;
  return promiseDetails;
}

/**
 * This will maintain a queue of concurrent promises for a provided key returning a master promise that only
 * resolves/rejects when the final promise in a queue for a given key resolves/rejects.
 * @param key String key to use for identify queue of promises.
 * @param promise Promise to add to queue, will only resolve/reject the masterPromise returned if only/last promise in
 * queue when it resolves/rejects.
 */
export default function collatePromises({ key = 'default', promise }) {
  const { masterPromise, queue, resolve, reject } = getPromiseCollationDetails({ key });
  // add promise to queue and hook up conditional masterPromise resolve/reject to current promise
  queue.push(promise);
  promise.then(
    (results) => {
      // only clear/process if promise last in list
      const qPromiseIdx = queue.indexOf(promise);
      if (qPromiseIdx !== -1 && qPromiseIdx === queue.length - 1) {
        delete promiseMap[key];
        resolve(results);
      }
    },
    (err) => {
      // only clear/throw if last in list.
      const qPromiseIdx = queue.indexOf(promise);
      if (qPromiseIdx !== -1 && qPromiseIdx === queue.length - 1) {
        delete promiseMap[key];
        reject(err);
      }
    }
  );
  return masterPromise;
}
