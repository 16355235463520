import React, { Component } from 'react';
import { sortBy } from 'lodash';
import { inject, observer } from 'mobx-react';

import { Box, Flex, Grid, Spinner } from 'core/components';
import { Field, formConsumer, InputGroup, LookbackDateRange, Select, Switch } from 'core/form';
import { TEST_TYPES, urlDefinedTestTypes } from 'app/util/constants';
import LabelOptionRenderer from 'app/components/labels/LabelOptionRenderer';
import { LabelMultiSelect } from 'app/components/labels/LabelMultiSelect';

const MULTI = 'multi';
const SINGLE = 'single';

@inject('$labels', '$syn')
@formConsumer
@observer
class SynthDashboardItemForm extends Component {
  state = {
    lookback_seconds: null,
    starting_time: null,
    ending_time: null
  };

  constructor(props) {
    super(props);

    this.synthAgentLabels = props.$labels.getLabelOptions(props.$labels.getLabels('synth_agent'));

    this.synthTestOptions = props.$syn.tests
      .get()
      .map((test) => ({
        value: test.id,
        label: test.get('display_name'),
        test_type: test.get('test_type'),
        isBulkIpTest: test.isBulkIpTest,
        isDns: test.isDns,
        isFlowBased: test.isFlowBased,
        isHostname: test.isHostname,
        isMesh: test.isMesh,
        isHighDensityGrid: test.isBulkIpTest || test.isDns // we can visualize any IP or DNS test as grid in dashboards
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    // NOTE: if isMulti, add display type to DatViewHeader !isUrlSynthTest condition
    this.synthTestDisplayOptions = [
      // TODO: bring back 'aggregate-agent' when we have agent selector in form
      // { label: 'Min/Max/Avg - By Agent', value: 'aggregate-agent', isMulti: true, showMetricField: true },
      { label: 'Min/Max/Avg - By Label', value: 'aggregate-label', isMulti: true, showMetricField: true },
      { label: 'Gauge - Min', value: 'gauge-min', isMulti: true, showMetricField: true },
      { label: 'Gauge - Max', value: 'gauge-max', isMulti: true, showMetricField: true },
      { label: 'Gauge - Avg', value: 'gauge-avg', isMulti: true, showMetricField: true },
      { label: 'Table', value: 'grid', isSingle: true }, // "grid" is a legacy value for table here
      { label: 'Density Grid', value: 'network-grid', isSingle: true }, // legacy "network-grid" value represents all grid test types
      { label: 'Density Grid Group', value: 'grid-group', isMulti: true },
      { label: 'Mesh', value: 'mesh', isSingle: true },
      { label: 'Availability', value: 'availability', isMulti: true }
    ];

    this.synthTestTypeOptions = [
      { label: 'Agent-to-Agent', value: 'agent' },
      { label: 'Autonomous Test', value: 'isFlowBased' },
      { label: 'DNS Server Monitor', value: 'isDns' },
      { label: 'Hostname', value: 'isHostname' },
      { label: 'HTTPS/API', value: 'url' },
      { label: 'IP Address', value: 'isBulkIpTest' },
      { label: 'Mesh', value: 'isMesh' },
      { label: 'Page Load', value: 'page-load' },
      { label: 'Transaction', value: 'transaction' }
    ];

    this.synthTestMetricOptions = [
      { label: 'Latency', value: 'avg_latency' },
      { label: 'DNS Resolution Time', value: 'dns_resolution' },
      { label: 'Jitter', value: 'avg_jitter' },
      { label: 'Packet Loss', value: 'packet_loss' }
    ];
  }

  get filteredMetricOptions() {
    const { form } = this.props;
    const { synthTestMetricOptions } = this;
    const synth_test_type = form.getValue('synth_test_type');
    return synthTestMetricOptions.filter((option) => {
      if (synth_test_type === 'isDns') {
        return option.value === 'dns_resolution';
      }
      if (urlDefinedTestTypes.includes(synth_test_type)) {
        return option.value === 'avg_latency';
      }
      return option.value !== 'dns_resolution';
    });
  }

  get filteredDisplayOptions() {
    const { form } = this.props;
    const { synthTestDisplayOptions } = this;
    const synth_panel_type = form.getValue('synth_panel_type');
    return synthTestDisplayOptions.filter((option) => {
      if (synth_panel_type === MULTI) {
        return option.isMulti;
      }
      return option.isSingle;
    });
  }

  get filteredTestTypeOptions() {
    const { form } = this.props;
    const { synthTestTypeOptions } = this;
    const synth_panel_type = form.getValue('synth_panel_type');
    const synth_test_display = form.getValue('synth_test_display');
    return synthTestTypeOptions.filter((option) => {
      // multi test dashboard widgets do not support AS tests
      if (synth_panel_type === 'multi' && option.value === 'isFlowBased') {
        return false;
      }
      if (['network-grid', 'grid-group'].includes(synth_test_display)) {
        return ['isBulkIpTest', 'isDns'].includes(option.value);
      }
      if (synth_test_display === 'mesh') {
        return option.value === 'isMesh';
      }
      return true;
    });
  }

  get testLabelOptions() {
    const { $labels } = this.props;
    return sortBy(
      $labels.getLabels('synth_test').map((label) => label.option),
      ['label', 'value']
    );
  }

  get filteredSynthTestOptions() {
    const { form } = this.props;
    const { synthTestOptions } = this;
    const synth_test_type = form.getValue('synth_test_type');
    return synthTestOptions.filter((option) => option[synth_test_type] || option.test_type === synth_test_type);
  }

  // this is kind of annoying but we need it for backwards compatible widgets before synth_test_type was a thing
  get selectedTestType() {
    const { $syn, model } = this.props;
    const query = model.isNew ? model.get('query') : model.dataview.query;
    if (query) {
      const id = query.synth_test_id;
      const test = Array.isArray(id) ? $syn.tests.get(id[0]) : $syn.tests.get(id);
      if (test) {
        const computedValue = ['isBulkIpTest', 'isDns', 'isHostname', 'isMesh'].reduce((next, curr) => {
          if (test[curr]) {
            next = curr;
          }
          return next;
        }, null);
        if (computedValue) {
          return computedValue;
        }
        const test_type = test.get('test_type');
        if ([TEST_TYPES.AGENT, TEST_TYPES.APPLICATION_MESH, ...urlDefinedTestTypes].includes(test_type)) {
          return test_type;
        }
      }
    }
    return null;
  }

  get showTestLabelsField() {
    const { form } = this.props;
    const synth_test_display = form.getValue('synth_test_display');
    return synth_test_display === 'availability';
  }

  get showMetricTypeField() {
    const { form } = this.props;
    const { synthTestDisplayOptions } = this;
    const synth_test_display = form.getValue('synth_test_display');
    const selected = synthTestDisplayOptions.find((option) => option.value === synth_test_display);
    return selected && selected.showMetricField;
  }

  componentDidMount() {
    const { form, model } = this.props;
    const query = model.isNew ? model.get('query') : model.dataview.query;

    if (query) {
      // this is either a clone or we are editing an existing synth test widget
      const {
        synth_panel_type,
        synth_test_id,
        synth_test_display,
        synth_test_metric,
        synth_test_type,
        synth_test_labels,
        lookback_seconds,
        starting_time,
        ending_time
      } = query;
      const synth_panel_type_legacy = synth_test_display === 'grid-group' ? MULTI : SINGLE;

      form.setValues({
        panel_title: model.get('panel_title'),
        panel_description: model.get('panel_description'),
        synth_test_id,
        synth_test_labels,
        time_locked: model.get('time_locked'),
        synth_test_metric,
        // OR conditions are legacy checks for synth panels before we had a panel or test type
        synth_test_type: synth_test_type || this.selectedTestType,
        synth_test_display,
        synth_panel_type: synth_panel_type || synth_panel_type_legacy
      });

      this.setState({ lookback_seconds, starting_time, ending_time });
    } else {
      const { lookback_seconds, starting_time, ending_time } = this.synthTestLookbackQuery;
      this.setState({ lookback_seconds, starting_time, ending_time });
      // else, brand new dashboard item
      form.setValue('time_locked', true);
    }
  }

  get synthTestLookbackQuery() {
    const { $dashboard, model, editQuery } = this.props;
    return model.isNew ? editQuery || model.get('query') || $dashboard.dashboard.get('query') : model.dataview.query;
  }

  onSynthTimeLockChange = (field, value) => {
    const { onQueryChange, editQuery } = this.props;
    const { lookback_seconds, starting_time, ending_time } = this.synthTestLookbackQuery;
    this.setState({ lookback_seconds, starting_time, ending_time });
    if (!value && editQuery) {
      onQueryChange({ lookback_seconds, starting_time, ending_time });
    }
  };

  onSynthLookbackChange = (data) => {
    const { endDate, lookbackSeconds, startDate } = data;
    const { onQueryChange } = this.props;

    this.setState({ lookback_seconds: lookbackSeconds, starting_time: startDate, ending_time: endDate });

    onQueryChange({
      lookback_seconds: lookbackSeconds,
      starting_time: startDate,
      ending_time: endDate
    });
  };

  onPanelOrDisplayChange = (field) => {
    const { form } = this.props;
    if (field.name === 'synth_panel_type') {
      form.setValue('synth_test_display', null);
    }
    form.setValue('synth_test_type', null);
    form.setValue('synth_test_labels', null);
    form.setValue('synth_test_metric', null);
    form.setValue('synth_test_id', null);
  };

  render() {
    const { model, form } = this.props;
    const { lookback_seconds, starting_time, ending_time } = this.state;
    const {
      filteredDisplayOptions,
      filteredSynthTestOptions,
      filteredTestTypeOptions,
      testLabelOptions,
      filteredMetricOptions,
      showTestLabelsField,
      showMetricTypeField
    } = this;
    const synth_test_display = form.getValue('synth_test_display');
    const synth_panel_type = form.getValue('synth_panel_type');
    const synth_test_type = form.getValue('synth_test_type');

    if (!model.fetchedQuery) {
      return (
        <Flex alignItems="center" justifyContent="center" p={2}>
          <Spinner />
        </Flex>
      );
    }

    const isAggregateLabel = synth_test_display === 'aggregate-label';
    const testFieldProps = {
      name: 'synth_test_id',
      large: true,
      label: 'Select Test(s)',
      placeholder: synth_panel_type === MULTI ? 'Select test(s)' : 'Select a test...',
      disabled: !synth_test_type,
      options: filteredSynthTestOptions
    };
    const testFieldSelectProps = { multi: false, keepOpen: false };
    // NOTE: for 'aggregate-label' display, synth_test_id is actually an array of [label_id]
    if (isAggregateLabel) {
      Object.assign(testFieldProps, {
        options: this.synthAgentLabels,
        label: 'Agent Label(s)',
        placeholder: 'Select label(s)...'
      });
      Object.assign(testFieldSelectProps, { multi: true, keepOpen: true, optionRenderer: LabelOptionRenderer });
    }
    if (['gauge-min', 'gauge-max', 'gauge-avg', 'grid-group'].includes(synth_test_display)) {
      Object.assign(testFieldSelectProps, { multi: true, keepOpen: true });
    }

    return (
      <Flex flexDirection="column" flexAuto p={2}>
        <Field name="panel_title" width={476} large>
          <InputGroup />
        </Field>
        <Box mb={2}>
          <Grid gridTemplateColumns="repeat(3, 1fr)">
            <Field name="synth_panel_type" onChange={this.onPanelOrDisplayChange} large>
              <Select />
            </Field>
            <Field
              name="synth_test_display"
              disabled={!synth_panel_type}
              large
              options={filteredDisplayOptions}
              onChange={this.onPanelOrDisplayChange}
            >
              <Select />
            </Field>
            <Field
              name="synth_test_type"
              disabled={!synth_test_display || showTestLabelsField}
              large
              options={filteredTestTypeOptions}
            >
              <Select />
            </Field>
          </Grid>
          {showMetricTypeField && (
            <Field name="synth_test_metric" disabled={!synth_test_type} large options={filteredMetricOptions}>
              <Select multi={isAggregateLabel} keepOpen={isAggregateLabel} toggle fill />
            </Field>
          )}
          <Field {...testFieldProps}>
            <Select {...testFieldSelectProps} fill ellipsisOptions={false} />
          </Field>
          {showTestLabelsField && (
            <Field name="synth_test_labels" large options={testLabelOptions}>
              <LabelMultiSelect fill />
            </Field>
          )}
        </Box>
        <Field name="time_locked" label="Time Configuration" large onChange={this.onSynthTimeLockChange}>
          <Switch switchLabel="Controlled by Dashboard" style={{ marginTop: '4px' }} />
        </Field>
        <LookbackDateRange
          disabled={form.getValue('time_locked')}
          startDate={starting_time}
          endDate={ending_time}
          lookbackSeconds={lookback_seconds}
          onChange={this.onSynthLookbackChange}
        />
      </Flex>
    );
  }
}

export default SynthDashboardItemForm;
