import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Flex, Icon, Popover } from 'core/components';
import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';
import InviteUsers from './InviteUsers';
import EnvironmentCard from './EnvironmentCard';

function ListItemButton({ text, onClick }) {
  const textColor = 'white.8';

  return (
    <Flex alignItems="center">
      <Icon icon="circle" iconSize={6} />
      <Button minimal small color={textColor} fontWeight="heavy" minHeight={20} onClick={onClick}>
        {text}
      </Button>
    </Flex>
  );
}

const DemoModeIndicator = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  color: #ffffff;
  padding: 0 12px;
  background: ${(props) => props.bg};
  cursor: pointer;

  &:hover {
    background: ${(props) => lighten(0.1, props.bg)};
  }

  ${(props) =>
    props.active &&
    css`
      background: ${darken(0.25, props.bg)};

      &:hover {
        background: ${props.bg};
      }
    `}
`;

@inject('$auth', '$sudo')
@withRouter
@observer
class EnvironmentSelector extends Component {
  state = {
    demoMenuOpen: false,
    inviteDialogOpen: false
  };

  onDemoMenuInteraction = (nextState) => this.setState({ demoMenuOpen: nextState });

  handleProdClick = () => {
    const { $sudo } = this.props;
    $sudo.clearDemoSession({ reload: true });
  };

  handleInviteDialogOpen = () => {
    this.onDemoMenuInteraction(false);
    this.setState({ inviteDialogOpen: true });
  };

  handleInviteDialogClose = () => {
    this.setState({ inviteDialogOpen: false });
  };

  renderTarget = (isOpen) => {
    const { $auth } = this.props;
    const { envName, envColor } = $auth.getEnvironmentSettings();

    return (
      <DemoModeIndicator bg={envColor} active={isOpen}>
        <Icon icon="cube" iconSize={18} color="white.6" mr={1} />
        {envName}
      </DemoModeIndicator>
    );
  };

  renderContent = () => {
    const { $auth } = this.props;
    const demoEnv = $auth.getEnvironmentSettings('demo');

    return (
      <EnvironmentCard>
        {$auth.isAbleToInviteFromDemo && <ListItemButton text="Invite Users" onClick={this.handleInviteDialogOpen} />}
        <ListItemButton text={`Exit the ${demoEnv.envName} Environment`} onClick={this.handleProdClick} />
      </EnvironmentCard>
    );
  };

  render() {
    const { $auth } = this.props;
    const { demoMenuOpen, inviteDialogOpen } = this.state;

    if (!$auth.isDemoUser) {
      return null;
    }

    return (
      <>
        <Popover
          position="bottom-right"
          isOpen={demoMenuOpen}
          onInteraction={this.onDemoMenuInteraction}
          boundary="viewport"
          minimal
          modifiers={{
            offset: {
              enabled: true,
              offset: '0,0'
            }
          }}
        >
          {this.renderTarget(demoMenuOpen)}
          {this.renderContent()}
        </Popover>

        <InviteUsers isOpen={inviteDialogOpen} onDialogClose={this.handleInviteDialogClose} />
      </>
    );
  }
}

export default EnvironmentSelector;
