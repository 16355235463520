import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Card } from 'core/components';
import VerticalDetailTabs from 'app/components/detailTabs/VerticalDetailTabs';
import InsightsMenu from 'app/components/navbar/InsightsMenu';
import Page from 'app/components/page/Page';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';

import AggregateViewHeader from '../aggregate/AggregateViewHeader';
import parentLinks from '../NetworkExplorerParentLink';

@inject('$lookups', '$tabs')
@observer
class CountryDetails extends Component {
  constructor(props) {
    super(props);

    const { $tabs } = props;
    const tabs = $tabs.getTabs();

    this.state.tabBlacklist = [tabs.COUNTRIES.id];
  }

  state = {
    countryLabel: undefined,
    filters: [],
    lookback_seconds: 86400,
    aggregateType: 'p95th_bits_per_sec'
  };

  componentDidMount() {
    this.lookupCountryLabel();
  }

  componentDidUpdate(prevProps) {
    if (this.country !== prevProps.match.params.country) {
      this.lookupCountryLabel();
    }
  }

  lookupCountryLabel = () => {
    const { $lookups } = this.props;
    if (this.country) {
      $lookups.countries(this.country).then(([country]) => this.setState(() => ({ countryLabel: country.label })));
    }
  };

  get country() {
    const { match } = this.props;
    return match.params.country;
  }

  get filters() {
    const { filters } = this.state;
    const detailFilter = {
      filterField: 'country',
      filterValue: this.country
    };

    return filters.concat(detailFilter);
  }

  handleFiltersApply = (filters) => {
    this.setState({ filters });
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds });
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType });
  };

  render() {
    const { countryLabel, aggregateType, filters, lookback_seconds, tabBlacklist } = this.state;

    return (
      <Page
        title={countryLabel || this.country}
        parentLinks={[...parentLinks, { link: '/v4/core/quick-views/country', label: 'Countries' }]}
        insightsMenu={<InsightsMenu query="fetchCountryInsights" params={this.country} />}
        scrolls
        showExport
      >
        <AggregateViewHeader
          title={countryLabel || this.country}
          aggregateType={aggregateType}
          filters={filters}
          lookbackSeconds={lookback_seconds}
          onFiltersChange={this.handleFiltersApply}
          onAggregateChange={this.handleAggregationChange}
          onMetricsChange={this.handleAggregationChange}
          onTimeRangeChange={this.handleLookbackChange}
        />

        <Card className="break-after" mb={2}>
          <TabbedChart overrides={{ lookback_seconds, aggregateTypes: [aggregateType] }} simpleFilters={this.filters} />
        </Card>

        <VerticalDetailTabs
          blacklist={tabBlacklist}
          simpleFilters={this.filters}
          queryOverrides={{ lookback_seconds, aggregateTypes: [aggregateType] }}
        />
      </Page>
    );
  }
}

export default CountryDetails;
