import AnnounceKit from 'announcekit-react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import styled from 'styled-components';

import { Box, Button, Icon } from 'core/components';
import { ReactComponent as AnnounceIcon } from 'app/assets/icons/announcement.svg';

const UnreadIndicator = styled.div`
  position: absolute;
  top: -4px;
  right: -8px;
  border-radius: 50%;
  padding: 0 3px;
  min-width: 14px;
  height: 14px;
  background-color: ${(props) => props.theme.colors.kentik};
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
`;

@inject('$app', '$auth')
@observer
export default class ProductUpdatesButton extends Component {
  ref = React.createRef();

  componentWillUnmount() {
    this.ref.current.instance().then((widget) => widget.destroy());
  }

  @action
  updateUnread(unreadCount) {
    const { $app } = this.props;

    $app.unreadProductUpdates = unreadCount;

    // override AnnounceKit's shouldComponentUpdate by forcing a render
    if (this.ref.current) {
      this.ref.current.forceUpdate();
    }
  }

  handleOpen = () => {
    this.ref.current.open();
    this.updateUnread(0);
  };

  handleUnread = (count) => {
    this.updateUnread(count);
  };

  renderIcon = ({ iconSize }) => {
    const { $app } = this.props;
    const unreadCount = $app.unreadProductUpdates;
    return (
      <Box position="relative">
        <Icon icon={AnnounceIcon} color="white.10" iconSize={iconSize} />
        {unreadCount > 0 && <UnreadIndicator>{unreadCount}</UnreadIndicator>}
      </Box>
    );
  };

  render() {
    const { $app, $auth, ...props } = this.props;
    const unreadCount = $app.unreadProductUpdates;

    return (
      <AnnounceKit
        ref={this.ref}
        widget="https://announcekit.co/widgets/v2/2mzlf2"
        onWidgetUnread={this.handleUnread}
        user={{
          id: $auth.getActiveUserProperty('id'),
          email: $auth.getActiveUserProperty('user_email'),
          name: $auth.getActiveUserProperty('user_full_name')
        }}
        data={{
          company_id: $auth.getActiveUserProperty('company_id'),
          company_name: $auth.getActiveUserProperty('company.company_name'),
          company_type: $auth.getActiveUserProperty('company.company_plan_id')
        }}
        {...props}
      >
        <Button
          onClick={this.handleOpen}
          icon={this.renderIcon}
          aria-label={`Product Updates: ${unreadCount} unread`}
          minWidth={36}
          minimal
        />
      </AnnounceKit>
    );
  }
}
