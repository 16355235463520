import React from 'react';
import { inject, observer } from 'mobx-react';
import styled, { withTheme } from 'styled-components';
import { FiActivity, FiCopy } from 'react-icons/fi';
import {
  Box,
  Button,
  CopyText,
  Flex,
  Icon,
  InfoBox,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Tag,
  Text,
  Sparkline,
  Spinner,
  Suspense,
  FlexColumn
} from 'core/components';
import { Table } from 'core/components/table';
import Collection from 'core/model/Collection';
import { PopoverInteractionKind } from '@blueprintjs/core';
import { getAggregateUnitLabel } from 'app/components/metric/metricRenderers';
import { zeroToText, adjustByGreekPrefix, getToFixed } from 'app/util/utils';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';
import { addFilters } from 'app/stores/query/FilterUtils';

export const EntityRow = styled(Flex)`
  align-items: center;
  cursor: pointer;
  background: ${({ theme, selected }) => (selected ? theme.colors.subnavBackground : '')};
  border-bottom: ${({ selected }) => (selected ? '' : '1px solid rgba(16,22,26,0.15)')};
  padding: 8px 16px;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryBackground};
  }
`;

// Sparkline Traffic Data renderer
export const SparklineResult = ({ value, row }) => {
  const name = row.get('key');
  return (
    <Flex alignItems="center" justifyContent="space-between" mb="4px">
      <Text>{name.charAt(0).toUpperCase() + name.slice(1)}</Text>
      <Text textAlign="right">{value}</Text>
    </Flex>
  );
};

function getValue(seriesOrValue, results, queryModel) {
  const [aggregateType] = queryModel.get('aggregateTypes');
  return zeroToText(
    adjustByGreekPrefix(
      seriesOrValue.get ? seriesOrValue.get(aggregateType) : seriesOrValue,
      results.prefix[queryModel.outsortUnit]
    ),
    {
      fix: getToFixed(queryModel.outsortUnit)
    }
  );
}

// Sparkline rendering and Popover
function renderSparklineData(results, queryModel) {
  if (results.loading) {
    return null;
  }
  const { prefix } = results;
  const [aggregateType] = queryModel.get('aggregateTypes');
  const [aggregate] = queryModel.aggregates;
  const [totalOverlay] = results.overlayRows;
  const total = (totalOverlay && totalOverlay.get(aggregateType)) || 0;

  const [, ...aggTypeParts] = aggregateType.split('_');
  const rawDataAttr = `rawData.both_${aggTypeParts.join('_')}.flow`;

  const sparklineData =
    totalOverlay && totalOverlay.get(rawDataAttr) ? totalOverlay.get(rawDataAttr).map((col) => col[1]) : [];

  return (
    <>
      <Popover
        usePortal
        interactionKind={PopoverInteractionKind.HOVER}
        disabled={results.models.length === 0 && !totalOverlay}
        position="right"
        minimal={false}
        modifiers={{
          offset: { enabled: false },
          flip: { enabled: false },
          preventOverflow: { enabled: true, boundariesElement: 'window' }
        }}
        content={
          <Box p={1} width={180}>
            <Flex alignItems="center" justifyContent="space-between" color="muted" fontSize="small" mb="4px">
              <Text>Past Day Traffic</Text>
              {getAggregateUnitLabel({ aggregate, prefix })}
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" mb="4px">
              <Text fontWeight="bold">Total</Text>
              <Text fontWeight="bold" textAlign="right">
                {getValue(total, results, queryModel)}
              </Text>
            </Flex>

            {results.nonOverlayRows.map((row) => {
              const value = getValue(row, results, queryModel);
              return <SparklineResult key={row.id} row={row} value={value} />;
            })}
          </Box>
        }
        target={
          <Flex
            position="absolute"
            left={0}
            bottom={0}
            flexDirection="column"
            justifyContent="flex-end"
            width="100%"
            overflow="hidden"
          >
            <Sparkline data={sparklineData} height={20} margin={1.5} />
          </Flex>
        }
      />

      {results.models.length === 0 && !totalOverlay && (
        <Flex position="absolute" bottom={0} width="100%" alignItems="center" justifyContent="center" p={2}>
          <Text muted>No Traffic Data</Text>
        </Flex>
      )}
    </>
  );
}

// Renders Sparkline at the bottom of parent with Popover for data
export const EntitySparkline = inject('$query')(
  observer(({ id, filter, $query }) => {
    const queryOverrides = Object.assign(
      {},
      {
        lookback_seconds: 86400,
        aggregateType: 'max_bits_per_sec',
        filterDimensionsEnabled: false,
        metric: ['simple_trf_prof'],
        all_devices: false,
        device_types: ['aws_subnet']
      }
    );

    const query = $query.get('inboundOutboundInternalQuery', queryOverrides);
    addFilters(query, [
      {
        filterField: filter,
        operator: '=',
        filterValue: id
      }
    ]);

    return (
      <LightweightDataViewWrapper query={query}>
        {({ results, queryModel }) => (
          <Suspense loading={results.loading} fallback={<Spinner size={24} />}>
            {renderSparklineData(results, queryModel)}
          </Suspense>
        )}
      </LightweightDataViewWrapper>
    );
  })
);

// Tag for boolean or 'boolean adjacent' values w/ popover for actual value
export const TagPopover = observer(({ text, value, successState = true, ...rest }) => (
  <Box {...rest}>
    <Popover
      interactionKind={PopoverInteractionKind.HOVER}
      position="right"
      minimal={false}
      usePortal
      modifiers={{
        offset: { enabled: false },
        flip: { enabled: false },
        preventOverflow: { enabled: true, boundariesElement: 'window' }
      }}
      content={
        <Text as="div" fontWight="bold" p={1}>
          {value.toString()}
        </Text>
      }
      target={
        <Tag
          fontSize="small"
          fontWeight="bold"
          icon={value === successState ? 'small-tick' : 'small-cross'}
          intent={value === successState ? 'success' : 'warning'}
          justifyContent="flex-start"
          minimal
        >
          {text}
        </Tag>
      }
    />
  </Box>
));

// Transforms input to uniform array of {key: *, value: *}
function transformKeyValuePair(data) {
  const finalData = [];

  if (!data || data.length === 0) {
    return undefined;
  }

  if (!Array.isArray(data)) {
    data = Object.entries(data);
  }

  data.forEach((each) => {
    if (!Array.isArray(each)) {
      each = Object.values(each);
    }
    finalData.push({ key: each[0], value: each[1] });
  });
  return finalData;
}

// Table Popover (for Tags/Options)
export const TablePopover = observer(
  ({ data, title, icon, iconSize, buttonText, hideHeader = false, labels = ['key', 'value'], copyKey, ...rest }) => {
    data = transformKeyValuePair(data);
    if (!data || data.length === 0) {
      return (
        <Box {...rest}>
          <Button
            icon={icon || 'tag'}
            iconSize={iconSize}
            text={buttonText || `Show ${title}`}
            small
            outlined
            disabled
          />
        </Box>
      );
    }

    return (
      <Box {...rest}>
        <Popover
          usePortal
          position="right-top"
          minimal={false}
          modifiers={{
            flip: { enabled: false },
            preventOverflow: { enabled: true, boundariesElement: 'window' }
          }}
          content={
            <FlexColumn overflow="hidden" p="1px">
              {title && (
                <Box borderBottom="thin">
                  <Text as="div" fontWeight="bold" p={1}>
                    {title}
                  </Text>
                </Box>
              )}
              <Table
                collection={new Collection(data)}
                columns={[
                  {
                    name: 'key',
                    label: labels[0],
                    width: 200,
                    renderer: copyKey ? ({ value }) => <CopyText text={value} /> : undefined
                  },
                  {
                    name: 'value',
                    label: labels[1],
                    ellipsis: false,
                    renderer: ({ value }) => <CopyText text={value} />
                  }
                ]}
                rowHeight={40}
                selectOnRowClick={false}
                hideHeader={hideHeader}
              />
            </FlexColumn>
          }
          target={
            <Button icon={icon || 'tag'} iconSize={iconSize} text={buttonText || `Show ${title}`} width="100%" small />
          }
        />
      </Box>
    );
  }
);

// Render Entity titles with an ID and optional Name or State
// Includes Popover for State displaying the actual value
export const NameIdState = observer(({ name, id, state, azure }) => {
  const success = azure ? 'Succeeded' : 'available';
  return (
    <FlexColumn>
      <Flex alignItems="center" maxHeight="15px">
        <Text as="div" fontWeight="bold" ellipsis>
          {name || id}
        </Text>
        {state && (
          <Popover
            interactionKind={PopoverInteractionKind.HOVER}
            position="right"
            minimal={false}
            usePortal
            content={
              <Text as="div" fontWeight="bold" p={1} textTransform="capitalize">
                {state}
              </Text>
            }
            target={<Icon icon="dot" color={state === success ? 'success' : 'warning'} iconSize={26} />}
          />
        )}
      </Flex>
      {name && (
        <Text muted ellipsis>
          {id}
        </Text>
      )}
    </FlexColumn>
  );
});

// Id Menu Popover for any type of Entity id
export const EntityIdPopover = withTheme(
  observer(
    ({ title, id, entityType, detailType, mapType, jumpToFn, jumpToId, theme, azure, sidebarProperties = {} }) => {
      if (!id) {
        return null;
      }

      const cloudType = azure ? 'azure' : 'aws';

      return (
        <Flex>
          <Popover
            interactionKind={PopoverInteractionKind.HOVER}
            position="right-top"
            minimal={false}
            usePortal
            modifiers={{
              offset: { enabled: false },
              flip: { enabled: false },
              preventOverflow: { enabled: true, boundariesElement: 'window' }
            }}
            content={
              <Menu>
                {entityType && (
                  <MenuItem
                    text={`Jump to ${entityType}`}
                    icon="arrows-vertical"
                    onClick={() => jumpToFn({ sectionType: entityType.toLowerCase(), id: jumpToId || id })}
                  />
                )}
                {detailType && (
                  <MenuItem
                    text="Open Quick View"
                    icon={FiActivity}
                    onClick={() =>
                      window.open(
                        `${window.location.origin}${`/v4/core/quick-views/cloud/${cloudType}/${detailType}/${id}`}`,
                        '_blank'
                      )
                    }
                  />
                )}
                {mapType && (
                  <MenuItem
                    text="Open in Map"
                    icon="layout-auto"
                    onClick={() =>
                      window.open(
                        `/v4/kentik-map/cloud/${cloudType}?sidebar=${encodeURIComponent(
                          JSON.stringify({ type: mapType, value: id, ...sidebarProperties })
                        )}`,
                        '_blank'
                      )
                    }
                  />
                )}
                <MenuDivider />
                <CopyToClipboardButton text={id} intent="">
                  <MenuItem text="Copy to Clipboard" icon={FiCopy} shouldDismissPopover={false} />
                </CopyToClipboardButton>
              </Menu>
            }
            target={
              <Box p="4px">
                {title && (
                  <InfoBox
                    heading={title}
                    value={id}
                    fontWeight="medium"
                    borderRadius={5}
                    bg={theme.backgrounds.app}
                    p="4px"
                    mb={1}
                  />
                )}
                {!title && (
                  <Box borderRadius={5} bg={theme.backgrounds.app} p="4px">
                    <Text as="div" fontWeight="medium">
                      {id}
                    </Text>
                  </Box>
                )}
              </Box>
            }
          />
        </Flex>
      );
    }
  )
);
