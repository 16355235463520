import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Box, ButtonLink, Flex, Icon, Text } from 'core/components';
import { formatNumber } from 'app/util/utils';

@inject('$app', '$auth', '$syn')
@observer
class CreditsWarningMessage extends Component {
  onClickSubmitRequest = () => {
    const { $app, $auth, $syn } = this.props;
    const { maxCredits, monthlyCreditsAtCurrentBurn } = $syn.plan;
    const needed = monthlyCreditsAtCurrentBurn - maxCredits;
    const formattedNeeded = formatNumber(needed, { fix: needed > 1000 ? 1 : 0, useAbsVal: false, scaleMax: 0.999 });
    $app.setReportIssuePopoverPropsAndOpen({
      type: 'credits',
      summary: `${$auth.companyFullName} requesting more Synthetics test credits`,
      description: `Please provide a quote to increase our Synthetics test credit limit by ${formattedNeeded.displayFull} credits.`
    });
  };

  render() {
    const { $auth, $syn, extraMessaging, showIcon, small } = this.props;
    const { loadingUsage } = $syn.plan;
    const remainingCredits = $syn.plan.calculateRemainingCredits();
    if (loadingUsage || remainingCredits > 0 || $auth.getPermission('synthetics.no_credit_limits')) {
      return null;
    }
    const overage = remainingCredits * -1;
    const formattedOverage = formatNumber(overage, { fix: overage > 1000 ? 1 : 0, useAbsVal: false, scaleMax: 0.999 });
    let warningLarge = true;
    let subWarningSmall = false;
    let warningMessage =
      remainingCredits === 0
        ? 'You have no credits left'
        : `You have exceeded your allocated credits by ${formattedOverage.displayFull}`;
    if (small) {
      warningMessage = remainingCredits === 0 ? 'You have no credits left' : 'Credit allocation exceeded';
      warningLarge = false;
      subWarningSmall = true;
    }
    return (
      <Flex alignItems="center">
        {showIcon && <Icon color="danger" icon="issue" iconSize={24} mr={1} />}
        <Box>
          <Text as="div" color="danger" large={warningLarge} fontWeight="bold">
            {warningMessage}
          </Text>
          <Text as="div" small={subWarningSmall} muted>
            Please{' '}
            <ButtonLink key="charges" onClick={this.onClickSubmitRequest}>
              submit a request
            </ButtonLink>{' '}
            to add credits.
            {extraMessaging
              ? ' You can also pause tests or reduce number of agents used to reduce credit consumption.'
              : ''}
          </Text>
        </Box>
      </Flex>
    );
  }
}

export default CreditsWarningMessage;
