import React from 'react';

import { Box, Text, LinkButton } from 'core/components';
import $devices from 'app/stores/device/$devices';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: [],
  filterFields: []
};

class OCIDependency extends AbstractDependency {
  get key() {
    return 'oci';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get preventQuery() {
    return true;
  }

  get meets() {
    return $devices.hasOCIDevice;
  }

  get message() {
    return (
      <>
        <Box mb="4px">
          <Text small>
            <strong>OCI</strong> devices are required by dimensions you&apos;re using, but have not been detected for
            any devices in your organization. Results shown herein may be highly inaccurate.
          </Text>
        </Box>
        <LinkButton text="Configure" icon="cog" small to="/v4/settings/clouds" />
      </>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || $devices.hasOCIDevice;
  }
}

export default OCIDependency;
