import React from 'react';
import styled from 'styled-components';
import { MultiSlider as BlueprintMultiSlider } from '@blueprintjs/core';
import size from './utils/size';

const StyledMultiSlider = styled(BlueprintMultiSlider)`
  ${size};
`;

const MultiSlider = (props) => <StyledMultiSlider {...props} />;

export const { Handle } = BlueprintMultiSlider;

export default MultiSlider;
