/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { observable, computed } from 'mobx';
import { withTheme } from 'styled-components';
import { drag } from 'd3-drag';
import { ContextMenuTarget } from '@blueprintjs/core';
import { select, event } from 'd3-selection';
import { ThemeProvider, Menu, MenuItem } from 'core/components';

@inject('$topo', '$devices', '$sites')
@ContextMenuTarget
@observer
class Node extends Component {
  static propTypes = {
    backgroundFilter: PropTypes.string,
    color: PropTypes.string,
    highlight: PropTypes.bool,
    maybeMute: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    node: PropTypes.object.isRequired,
    onDrag: PropTypes.func,
    onShowDetails: PropTypes.func,
    onHighlight: PropTypes.func
  };

  static defaultProps = {
    backgroundFilter: undefined,
    color: undefined,
    highlight: false,
    maybeMute: false,
    onDrag: () => {},
    onShowDetails: () => {},
    onHighlight: () => {}
  };

  @observable
  fixed = false;

  @observable
  dragging = false;

  @computed
  get type() {
    const { node } = this.props;
    return node.type && node.type.toLowerCase();
  }

  @computed
  get hasFlow() {
    const { $topo, node } = this.props;
    return $topo.hasFlow(node);
  }

  nodeEl;

  getNodeRef = (nodeEl) => {
    this.nodeEl = nodeEl;

    select(nodeEl).call(
      drag().on('start', this.handleDragStarted).on('drag', this.handleDragged).on('end', this.handleDragEnded)
    );
  };

  showDetails = (e) => {
    const { node, color, onShowDetails } = this.props;
    onShowDetails('node', node, this.nodeEl, { color }, e);
  };

  handleClick = (e) => {
    const { node, highlight, onHighlight } = this.props;
    e.stopPropagation();
    this.showDetails(e);
    onHighlight(node, !highlight);
  };

  handleDragStarted = (d) => {
    const { node, onDrag } = this.props;

    event.sourceEvent.stopPropagation();
    this.dragging = true;
    d.fx = event.x;
    d.fy = event.y;

    onDrag('start', [event.x, event.y], this.nodeEl, d, node);
  };

  handleDragged = (d) => {
    const { node, onDrag } = this.props;

    d.fx = event.x;
    d.fy = event.y;

    onDrag('drag', [event.x, event.y], this.nodeEl, d, node);
  };

  handleDragEnded = (d) => {
    const { node, onDrag, $topo } = this.props;
    const { setNodeInfo } = $topo;

    this.dragging = false;
    this.fixed = true;
    d.fx = event.x;
    d.fy = event.y;

    setNodeInfo({ id: node.id, fx: d.fx, fy: d.fy });
    onDrag('end', [event.x, event.y], this.nodeEl, d, node);
  };

  renderNode = () => {
    const { theme, node, color, highlight } = this.props;
    const nodeProps = {
      stroke: `${theme.name === 'dark' ? theme.colors.lightGray5 : theme.colors.darkGray5}`,
      strokeWidth: this.type === 'provider' ? 2 : 0,
      fill: this.type === 'provider' ? theme.colors.transparent : color || theme.colors.gray2
    };

    if (highlight || this.dragging) {
      nodeProps.strokeWidth = theme.name === 'dark' ? 4 : 2;
    }

    if (node.alarms) {
      return (
        <path
          d="M8 16c1.1 0 2-.9 2-2H6c0 1.1.9 2 2 2zm6-5c-.55 0-1-.45-1-1V6c0-2.43-1.73-4.45-4.02-4.9
             0-.04.02-.06.02-.1 0-.55-.45-1-1-1S7 .45 7 1c0 .04.02.06.02.1A4.992 4.992 0 0 0 3 6v4c0
             .55-.45 1-1 1s-1 .45-1 1 .45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"
          {...nodeProps}
          fill={theme.colors.red4}
          transform="translate(-20 -20) scale(2.6)"
          strokeWidth={nodeProps.strokeWidth / 2}
        >
          <title>{node.alarms.join('\n')}</title>
        </path>
      );
    }

    if (this.type === 'device') {
      return <circle r="26" {...nodeProps} />;
    }

    if (this.type === 'provider' || this.type === 'backbone') {
      return <circle r="20" {...nodeProps} stroke={theme.colors.warning} strokeDasharray={1} />;
    }

    return <rect width="40" height="40" rx="2" ry="2" transform="translate(-20, -20)" {...nodeProps} />;
  };

  renderText = () => {
    const { node, backgroundFilter, theme } = this.props;
    const { colors, fontSizes } = theme;
    const { normal, black } = theme.fontWeights;

    const isProvider = this.type === 'provider';

    const fontWeight = isProvider ? normal : black;
    const fill = theme.name === 'dark' ? colors.lightGray5 : colors.darkGray5;

    return (
      <text
        dx="0"
        dy="5"
        textAnchor="middle"
        fill={fill}
        filter={backgroundFilter ? `url(#${backgroundFilter})` : ''}
        style={{ fontSize: fontSizes.small, fontWeight }}
      >
        {node.name}
      </text>
    );
  };

  renderContextMenu() {
    const { node, theme, $devices, $topo, $sites } = this.props;
    const { name, id, isCtProvider } = node;

    const isProvider = this.type === 'provider' && !isCtProvider;
    const isDevice = this.type === 'device';
    const isSite = this.type === 'site';

    return (
      <ThemeProvider theme={theme}>
        <Menu>
          {isCtProvider && (
            <>
              <MenuItem
                text="View Connectivity Type Details"
                icon="share"
                onClick={() => $topo.navigateToConnectivityType(name)}
              />
            </>
          )}
          {isProvider && (
            <>
              <MenuItem text="View Provider Details" icon="share" onClick={() => $topo.navigateToProvider(name)} />
            </>
          )}
          {isDevice && (
            <>
              <MenuItem text="View Device Details" icon="share" onClick={() => $devices.navigateToDevice(name)} />
            </>
          )}
          {isSite && (
            <>
              <MenuItem text="View Site Details" icon="share" onClick={() => $sites.navigateToSite(id)} />
            </>
          )}
        </Menu>
      </ThemeProvider>
    );
  }

  render() {
    const { maybeMute, highlight } = this.props;

    return (
      <g
        ref={this.getNodeRef}
        className="topoNode"
        onClick={this.handleClick}
        style={{
          cursor: 'move',
          opacity: `${!highlight && (maybeMute === true || (maybeMute === '?' && !this.hasFlow)) ? 0.3 : 1}`
        }}
      >
        {this.renderNode()}
        {this.renderText()}
      </g>
    );
  }
}

export default withTheme(Node);
