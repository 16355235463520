import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withSize } from 'react-sizeme';
import { withRouter } from 'react-router-dom';
import { Box, Text } from 'core/components';
import { Table } from 'core/components/table';
import Collection from 'core/model/Collection';
import healthSerializer from 'app/stores/synthetics/healthSerializer';
import AgentRenderer from 'app/views/synthetics/components/AgentRenderer';
import DensityGrid from 'app/views/synthetics/components/DensityGrid';

function getTest($syn, id) {
  return $syn.tests.get(id) || $syn.communityPerformanceTests.get(id);
}

function getColumns({ $syn, headerLabelFormatter, history, validTestIds, lookbackSeconds, startDate, endDate, size }) {
  const AGENT_WIDTH = 300;
  const PADDING = 12;
  const columns = [
    {
      label: 'Agent',
      name: 'agent_id',
      ellipsis: false,
      width: AGENT_WIDTH,
      renderer: ({ value }) => {
        const agent = $syn.agents.get(value);
        if (agent) {
          return <AgentRenderer agent={agent} showLocationInfo />;
        }
        return (
          <Text fontStyle="italic" muted>
            Unknown or removed agent
          </Text>
        );
      }
    }
  ];
  const testColSize = (size.width - AGENT_WIDTH) / (validTestIds.length > 0 ? validTestIds.length : 1);
  validTestIds.forEach((testId) => {
    const test = getTest($syn, testId);
    columns.push({
      label: headerLabelFormatter ? headerLabelFormatter(test) : test.get('display_name'),
      name: testId,
      ellipsis: false,
      width: testColSize,
      onHeaderClick: () => history.push(`/v4/synthetics/tests/${testId}/results`),
      sortable: false,
      renderer: ({ model, value }) => {
        if (value) {
          const keyId = `${model.get('agent_id')}-${testId}`;
          // this is an array of PingResultModel or DnsResultModel from healthSerializer --- they have their own 'isAggregated' property
          const isAggregated = (value || []).every((m) => m.get('isAggregated'));
          return (
            <Box position="relative">
              <DensityGrid
                keyId={keyId}
                test={test}
                lookbackSeconds={lookbackSeconds}
                startDate={startDate}
                endDate={endDate}
                gridWidth={testColSize - PADDING * 2}
                models={value}
                isAggregated={isAggregated}
              />
            </Box>
          );
        }
        return (
          <Text fontStyle="italic" muted>
            No results available for this test.
          </Text>
        );
      }
    });
  });
  return columns;
}

@withSize()
@inject('$syn')
@withRouter
@observer
class SynthDashboardGridGroup extends Component {
  state = {
    columns: [],
    collection: new Collection([])
  };

  static getDerivedStateFromProps(props) {
    const {
      $syn,
      headerLabelFormatter,
      history,
      testResults,
      test_ids,
      resultTimeMs,
      size,
      lookbackSeconds,
      startDate,
      endDate
    } = props;
    const gridGroupData = {};
    const validTestIds = [];

    // get valid IDs that have not been deleted; this includes paused tests since health resp does not
    test_ids.forEach((test_id) => {
      if (getTest($syn, test_id)) {
        validTestIds.push(`${test_id}`);
      }
    });
    // create group data structure
    const { health } = testResults;
    health.forEach((h) => {
      const test = getTest($syn, h.test_id);
      if (test) {
        // get serialized resultsCollection from testResults as that gets models density grid expects
        const { resultsCollection } = healthSerializer({
          test,
          results: h,
          resultTimeMs,
          $syn,
          agentTaskConfig: h.agentTaskConfig
        });
        // now loop through resultsCollection models and add to gridGroupData agent objects
        resultsCollection.models.forEach((model) => {
          const agent_id = model.get('agent_id');
          if (!gridGroupData[agent_id]) {
            // if agent does not exist
            gridGroupData[agent_id] = { [h.test_id]: [model] };
          } else if (!gridGroupData[agent_id][h.test_id]) {
            // if agent exists, but the test for that agent does not
            gridGroupData[agent_id][h.test_id] = [model];
          } else {
            // else both the agent and test exist
            gridGroupData[agent_id][h.test_id].push(model);
          }
        });
      }
    });

    // now create a flat collection from gridGroupData
    const tableData = Object.keys(gridGroupData).map((agent_id) => {
      const tests = {};
      validTestIds.forEach((test_id) => {
        tests[test_id] = gridGroupData[agent_id][test_id] || null;
      });
      return Object.assign({ agent_id }, tests);
    });

    return {
      columns: getColumns({
        $syn,
        headerLabelFormatter,
        history,
        validTestIds,
        lookbackSeconds,
        startDate,
        endDate,
        size
      }),
      collection: new Collection(tableData, {
        sortState: { field: 'agent_id', direction: 'asc' },
        getSortValue: (field, model) => {
          const value = model.get(field);
          if (field === 'agent_id') {
            const agent = $syn.agents.get(value);
            return agent ? agent.get('agent_alias') : value;
          }
          return value;
        }
      })
    };
  }

  render() {
    const { columns, collection } = this.state;
    return (
      <Box mb={2} position="relative">
        <Table columns={columns} collection={collection} />
      </Box>
    );
  }
}

export default SynthDashboardGridGroup;
