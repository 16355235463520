import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Flex, Box, Heading, Text } from 'core/components';

@observer
class ViewHeader extends Component {
  static defaultProps = {
    flexHeading: true
  };

  render() {
    const { title, subtitle, flexHeading, children } = this.props;

    return (
      <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between">
        {(title || subtitle) && (
          <Box flex={flexHeading ? '1 1 auto' : undefined} mr={2} mb={1}>
            {title && (
              <Heading level={title.length < 30 ? 1 : 2} mb={0}>
                {title}
              </Heading>
            )}
            {subtitle && <Text as="div">{subtitle}</Text>}
          </Box>
        )}
        <Flex mb={1} flex={!flexHeading ? '1 1 auto' : undefined} justifyContent="flex-end">
          {children}
        </Flex>
      </Flex>
    );
  }
}

export default ViewHeader;
