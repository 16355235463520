import { httpStatusCodes, dnsStatusCodes } from 'app/forms/config/syntheticTests/constants';

import { alertFields } from './alertFields';
import { labelFields } from './labelFields';
import { notesFields } from './notesFields';

const httpStatusCodeOptions = Object.keys(httpStatusCodes).map((code) => ({
  value: parseInt(code),
  label: `${code} ${httpStatusCodes[code]}`
}));

const dnsStatusCodeOptions = Object.keys(dnsStatusCodes).map((code) => ({
  value: parseInt(code),
  label: `${code} ${dnsStatusCodes[code].long}`
}));

const ONE_THOUSAND = 1000;
// We are doing the float check here because the transform is applied before it gets to the validator.
const msIn = (value) => {
  const val = parseFloat(value, 10);
  const result = typeof val === 'number' && Number.isInteger(val) ? Math.floor(value / ONE_THOUSAND) : value;
  return result;
};

const msOut = (value) => {
  const val = parseFloat(value, 10);
  const result = typeof val === 'number' && Number.isInteger(val) ? Math.floor(value * ONE_THOUSAND) : value;
  return result;
};

const baseFields = {
  ...alertFields,
  ...labelFields,
  ...notesFields,

  'config.name': {
    label: 'Name',
    rules: 'required',
    messages: {
      required: 'A name is required for this test.'
    }
  },

  'config.target': {},

  'config.agents': {
    defaultValue: [],
    rules: 'required',
    messages: {
      required: 'One or more agent selections is required.'
    }
  },

  'config.rollup_level': {
    defaultValue: 1,
    label: 'Subtest Threshold for Status',
    helpText: 'The minimum number of unhealthy subtests required to report overall test status as unhealthy.',
    rules: ['required', 'numeric', 'min:1']
  },

  'config.healthSettings': {},

  'config.healthSettings.disableAlerts': {
    defaultValue: false,
    label: 'Disable Alerts',
    rules: ['boolean']
  },

  'config.healthSettings.disabledMetrics': {
    defaultValue: {}
  },

  'config.healthSettings.latencyCritical': {
    label: 'Latency Threshold',
    defaultValue: 0,
    rules: ['numeric', 'min:0', 'integer'],
    transform: {
      in: msIn,
      out: msOut
    }
  },
  'config.healthSettings.latencyWarning': {
    label: 'Latency Threshold',
    defaultValue: 0,
    rules: ['numeric', 'min:0', 'integer'],
    transform: {
      in: msIn,
      out: msOut
    }
  },
  'config.healthSettings.latencyCriticalStddev': {
    label: 'Latency Threshold',
    defaultValue: 3.0,
    rules: ['numeric', 'min:0', 'max:100'],
    transform: {
      out: (value) => parseFloat(value)
    }
  },
  'config.healthSettings.latencyWarningStddev': {
    label: 'Latency Threshold',
    defaultValue: 1.5,
    rules: ['numeric', 'min:0', 'max:100'],
    transform: {
      out: (value) => parseFloat(value)
    }
  },
  'config.healthSettings.dnsLatencyCritical': {
    label: 'DNS Latency Threshold',
    defaultValue: 0,
    rules: ['numeric', 'min:0', 'integer'],
    transform: {
      in: msIn,
      out: msOut
    }
  },
  'config.healthSettings.dnsLatencyWarning': {
    label: 'DNS Latency Threshold',
    defaultValue: 0,
    rules: ['numeric', 'min:0', 'integer'],
    transform: {
      in: msIn,
      out: msOut
    }
  },
  'config.healthSettings.dnsLatencyCriticalStddev': {
    label: 'DNS Latency Threshold',
    defaultValue: 3.0,
    rules: ['numeric', 'min:0', 'max:100'],
    transform: {
      out: (value) => parseFloat(value)
    }
  },
  'config.healthSettings.dnsLatencyWarningStddev': {
    label: 'DNS Latency Threshold',
    defaultValue: 1.5,
    rules: ['numeric', 'min:0', 'max:100'],
    transform: {
      out: (value) => parseFloat(value)
    }
  },

  'config.healthSettings.packetLossCritical': {
    label: 'Packet Loss Threshold',
    defaultValue: 50,
    rules: ['numeric', 'min:0', 'max:100'],
    transform: {
      out: (value) => parseInt(value)
    }
  },

  'config.healthSettings.packetLossWarning': {
    label: 'Packet Loss Threshold',
    defaultValue: 0,
    rules: ['numeric', 'min:0', 'max:100'],
    transform: {
      out: (value) => parseInt(value)
    }
  },

  'config.healthSettings.jitterCritical': {
    label: 'Jitter Threshold',
    defaultValue: 0,
    rules: ['numeric', 'min:0', 'integer'],
    transform: {
      in: msIn,
      out: msOut
    }
  },
  'config.healthSettings.jitterWarning': {
    label: 'Jitter Threshold',
    defaultValue: 0,
    rules: ['numeric', 'min:0', 'integer'],
    transform: {
      in: msIn,
      out: msOut
    }
  },
  'config.healthSettings.jitterCriticalStddev': {
    label: 'Jitter Threshold',
    defaultValue: 3.0,
    rules: ['numeric', 'min:0', 'max:100'],
    transform: {
      out: (value) => parseFloat(value)
    }
  },
  'config.healthSettings.jitterWarningStddev': {
    label: 'Jitter Threshold',
    defaultValue: 1.5,
    rules: ['numeric', 'min:0', 'max:100'],
    transform: {
      out: (value) => parseFloat(value)
    }
  },

  'config.healthSettings.httpLatencyCritical': {
    label: 'HTTP Latency Threshold',
    defaultValue: 0,
    rules: ['numeric', 'min:0', 'integer'],
    transform: {
      in: msIn,
      out: msOut
    }
  },
  'config.healthSettings.httpLatencyWarning': {
    label: 'HTTP Latency Threshold',
    defaultValue: 0,
    rules: ['numeric', 'min:0', 'integer'],
    transform: {
      in: msIn,
      out: msOut
    }
  },
  'config.healthSettings.httpLatencyCriticalStddev': {
    label: 'HTTP Latency Threshold',
    defaultValue: 3.0,
    rules: ['numeric', 'min:0', 'max:100'],
    transform: {
      out: (value) => parseFloat(value)
    }
  },
  'config.healthSettings.httpLatencyWarningStddev': {
    label: 'HTTP Latency Threshold',
    defaultValue: 1.5,
    rules: ['numeric', 'min:0', 'max:100'],
    transform: {
      out: (value) => parseFloat(value)
    }
  },
  'config.healthSettings.httpValidCodes': {
    label: 'Valid HTTP Codes',
    defaultValue: [],
    helpText: 'Specify an explicit list of response codes to be considered "healthy"',
    options: httpStatusCodeOptions
  },

  'config.healthSettings.dnsValidCodes': {
    label: 'Valid DNS Codes',
    defaultValue: [],
    helpText: 'Specify an explicit list of response codes to be considered "healthy"',
    options: dnsStatusCodeOptions
  },

  'config.alerting.trigger_per_agent_dst': {
    defaultValue: false
  }
};

export { baseFields };
