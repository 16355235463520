import { inject, observer } from 'mobx-react';
import { getSharedTooltip } from 'app/components/dataviews/views/tooltipRenderers';
import { uninjectedComponent as StackedArea } from './StackedArea';

@inject('$app', '$dictionary')
@observer
export default class StackedAreaHundredPercentView extends StackedArea {
  totalData = {};

  renderOverlay(bucket, name, data) {
    if (name === 'Total' && data) {
      const bucketName = bucket.get('name');
      this.totalData[bucketName] = data;
      const yAxisIndex = this.getYAxis(bucket);
      const yAxis = this.chart.yAxis[yAxisIndex];
      const otherYAxis = this.chart.yAxis[yAxisIndex ? 0 : 1];
      const totalSeries = this.chart.series.find((series) => series.name === 'Other' && series.yAxis === yAxis);
      const otherTotalSeries = this.chart.series.find(
        (series) => series.name === 'Other' && series.yAxis === otherYAxis
      );
      if (!totalSeries) {
        const seriesConfig = { type: 'area', name: 'Other', data: this.totalData, yAxis: yAxisIndex };
        if (otherTotalSeries) {
          seriesConfig.color = otherTotalSeries.color;
        }
        this.chart.addSeries(seriesConfig);
      }
    }
  }

  updateRenderedOverlay(bucket, name, data) {
    if (name === 'Total' && data && data.flow) {
      this.totalData[bucket.get('name')] = data.flow;
    }
  }

  renderTotalSeries() {
    const totalKeys = Object.keys(this.totalData);
    if (this.chart && totalKeys.length) {
      totalKeys.forEach((bucket) => {
        const totalData = this.totalData[bucket];
        const yAxisIndex = this.getYAxis(bucket);
        const yAxis = this.chart.yAxis[yAxisIndex];
        const dataSeries = this.chart.series.filter((series) => series.name !== 'Other' && series.yAxis === yAxis);
        if (dataSeries.length) {
          totalData.forEach((row, index) => {
            dataSeries.forEach((series) => {
              if (yAxisIndex) {
                row[1] += series.data[index].y;
              } else {
                row[1] -= series.data[index].y;
              }
            });
            if (yAxisIndex) {
              row[1] = Math.min(row[1], 0);
            } else {
              row[1] = Math.max(row[1], 0);
            }
          });
          const totalSeries = this.chart.series.find((series) => series.name === 'Other' && series.yAxis === yAxis);
          if (totalSeries) {
            totalSeries.setData(totalData);
          }
        }
      });
    }
  }

  redraw(opts) {
    const { $app } = this.props;

    if (this.chart && this.chart.yAxis) {
      const query = this.props.dataview.queryBuckets.activeBuckets[0].firstQuery;
      let yAxisSuffix = '';
      if (this.chartOptions.plotOptions.area.stacking === 'percent') {
        yAxisSuffix = ' % of ';
        if (query.get('show_total_overlay')) {
          yAxisSuffix += 'total';
        } else {
          yAxisSuffix += `top ${query.get('topx')}`;
        }
      }
      this.chart.yAxis.forEach((yAxis) => {
        if (yAxis.options.title.text && !yAxis.options.title.text.includes(yAxisSuffix)) {
          const arrowSplit = yAxis.options.title.text.split(' \u2192');
          yAxis.update({
            title: {
              text: `${arrowSplit[0].split(' % of ')[0]}${yAxisSuffix}${arrowSplit[1] !== undefined ? ' \u2192' : ''}`
            }
          });
        }
      });
    }

    super.redraw(opts);

    $app.renderSync(() => {
      this.renderTotalSeries();
    });
  }

  getComponent() {
    const { chartOptions, getColorFromIndex } = this;
    const { dataview } = this.props;
    const { activeBucketCount, activeBuckets } = dataview.queryBuckets;

    if (!activeBucketCount) {
      return null;
    }

    const query = activeBuckets[0].firstQuery;

    chartOptions.plotOptions.area.stacking = 'percent';

    chartOptions.tooltip.formatter = function formatter2() {
      return getSharedTooltip({
        points: this.points.filter((point) => point.series.name === 'Other'),
        seriesType: 'area',
        getColorFromIndex,
        tooltip: getSharedTooltip({
          points: this.points.filter((point) => point.series.name !== 'Other'),
          seriesType: 'area',
          getColorFromIndex,
          showSeconds: query.get('reAggInterval') ? query.get('reAggInterval') < 60 : false
        })
      });
    };

    return super.getComponent();
  }
}

const config = {
  showTotalTrafficOverlay: true,
  showLegend: true,
  timeBased: true,
  isSVG: true,
  enableToggle: true,
  mirrorable: true,
  allowsSecondaryOverlay: true,
  allowsSyncAxes: true,
  buckets: [
    {
      name: 'Left +Y Axis',
      mirrorBucket: 1,
      secondaryOverlayBucket: 1
    },
    {
      name: 'Right -Y Axis',
      sampleRateFactor: -1
    }
  ]
};

export { config };
