import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { safelyParseJSON } from 'core/util';

@inject('$dictionary', '$setup')
@observer
class Dismissible extends Component {
  state = {
    isOpen: true
  };

  get sessionStorageKey() {
    const { name, $dictionary } = this.props;
    return `kentik_${$dictionary.dictionary.envName}_${name}_hidden`;
  }

  get preferenceKey() {
    const { name } = this.props;
    return `dismissible_${name}_hidden`;
  }

  constructor(props) {
    super(props);

    const { $setup } = props;

    // Originally this was in sessionStorage, this will preserve that selection
    let isHidden = $setup.getSettings(this.preferenceKey);
    if (isHidden === undefined) {
      isHidden = safelyParseJSON(window.sessionStorage[this.sessionStorageKey]);
    }

    this.state.isOpen = !isHidden;
  }

  handleDismiss = () => {
    const { $setup, noPersist } = this.props;
    this.setState({ isOpen: false });
    if (!noPersist) {
      $setup.updateSettings({ [this.preferenceKey]: true });
    }
  };

  render() {
    const { children } = this.props;
    const { isOpen } = this.state;

    if (!isOpen) {
      return false;
    }

    return children({ onDismiss: this.handleDismiss });
  }
}

export default Dismissible;
