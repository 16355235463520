import { nStdDeviations } from './utils';

export const SUFFIXES = [
  { value: 'M', magnitude: 10 ** 6 },
  { value: 'k', magnitude: 10 ** 3 }
];

export function numberSuffix(data, scaleMax = 5) {
  const twoStdDeviations = nStdDeviations(data, 2);
  const suffix = SUFFIXES.find((pf) => twoStdDeviations / pf.magnitude > scaleMax);
  return suffix ? suffix.value : '';
}

export function adjustByNumberSuffix(value, suffix) {
  const suffixFound = SUFFIXES.find((pf) => pf.value === suffix);
  return suffixFound ? value / suffixFound.magnitude : value;
}
