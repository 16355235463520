import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import AddressInput from 'app/components/AddressInput';
import storeLoader from 'app/stores/storeLoader';
import { Box, Button, Spinner, Suspense } from 'core/components';
import { Field, formConsumer, InputGroup, Select } from 'core/form';

@formConsumer
@storeLoader('$sites')
@inject('$auth')
@observer
class SiteSelect extends Component {
  static defaultProps = {
    siteFieldName: 'site.id',
    isRequired: false,
    showAddSiteFirst: false,
    allowAddSite: true,
    containerProps: {},
    showLabel: false
  };

  constructor(props) {
    super(props);

    this.state = {
      showingAddSite: props.showAddSiteFirst
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { onAddToggle } = this.props;
    const { showingAddSite } = this.state;

    if (showingAddSite !== prevState.showingAddSite) {
      this.updateFormFields(showingAddSite);

      if (onAddToggle) {
        onAddToggle(showingAddSite);
      }
    }
  }

  updateFormFields = (showingAddSite) => {
    const { form, siteFieldName, isRequired } = this.props;
    const siteModelValue = form.model.get(siteFieldName);

    const siteField = form.getField(siteFieldName);
    const titleField = form.getField('site.title');
    const addressField = form.getField('site.address');

    siteField.setRules(showingAddSite || !isRequired ? '' : 'required');
    titleField.setRules(showingAddSite ? 'required' : '');

    if (showingAddSite) {
      siteField.setValue(undefined);
      titleField.setValue('');
      addressField.setValue(undefined);
    } else {
      siteField.setValue(siteModelValue);

      // force model update
      if (siteField.value) {
        siteField.onChange(siteField.value);
      }
    }
  };

  handleCreateNewSite = () => {
    this.setState({ showingAddSite: true });
  };

  handleUseExistingSite = () => {
    this.setState({ showingAddSite: false });
  };

  handleGeocodeComplete = (selectedAddress) => {
    const { form } = this.props;

    form.setValuesDeep({ site: { ...selectedAddress } });

    if (form.onChange) {
      form.onChange({
        form,
        formValues: form.getValues(),
        field: form.getField('site.address'),
        fieldValue: form.getValue('site.address')
      });
    }
  };

  render() {
    const { $auth, $sites, siteFieldName, allowAddSite, disabled, containerProps, onChange, showLabel } = this.props;
    const { showingAddSite } = this.state;
    const { siteOptions } = $sites;
    const hasSites = siteOptions.length > 0;

    return (
      <Suspense
        loading={$sites.collection.loading}
        fallback={
          <Box pt={2}>
            <Spinner size={24} />
          </Box>
        }
      >
        <Box mb={2} {...containerProps}>
          {!showingAddSite && (
            <>
              <Field
                name={siteFieldName}
                options={siteOptions}
                inputStyle={{ width: 300 }}
                large
                disabled={disabled}
                onChange={onChange}
                showLabel={showLabel}
                m={0}
              >
                <Select menuWidth={300} showFilter={siteOptions.length > 10} />
              </Field>

              {allowAddSite && $auth.isAdministrator && (
                <Button icon="add" disabled={disabled} onClick={this.handleCreateNewSite} mt={1}>
                  {`Create ${hasSites ? 'a new' : 'your first'} Site`}
                </Button>
              )}
            </>
          )}

          {allowAddSite && showingAddSite && (
            <>
              <Field name="site.title" label="Site Name" rules="required" large autoFocus>
                <InputGroup width={400} />
              </Field>

              <Field name="site.address" large>
                <AddressInput
                  inputProps={{ width: 400 }}
                  showMap={false}
                  onGeocodeComplete={this.handleGeocodeComplete}
                />
              </Field>

              {hasSites && (
                <Button icon="arrow-left" onClick={this.handleUseExistingSite}>
                  Use an existing Site
                </Button>
              )}
            </>
          )}
        </Box>
      </Suspense>
    );
  }
}

export default SiteSelect;
