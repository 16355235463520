import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import classNames from 'classnames';

import { Box, Button, Flex, Text } from 'core/components';
import { fromNow } from 'core/util/dateUtils';
import ComparisonInsightTable from './ComparisonInsightTable';
import InsightChart from './InsightChart';
import InsightVote from './InsightVote';

const InsightBox = styled(Flex)`
  flex-direction: column;
  width: 100%;
  border-bottom: ${(props) => props.theme.borders.thin};
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;

  &.expanded {
    border: ${(props) => props.theme.borders.thin};
    border-top: none;
    background: ${(props) => props.theme.colors.subnavBackground};
  }

  &:first-child {
    &.expanded {
      border-top: ${(props) => props.theme.borders.thin};
    }
  }

  &:last-child {
    border-bottom: 0;

    &.expanded {
      border-bottom: ${(props) => props.theme.borders.thin};
    }
  }
`;

@inject('$explorer', '$insights', '$app')
@observer
class CompactInsight extends Component {
  static defaultProps = {
    icon: 'help',
    onDataViewCreate: () => {}
  };

  state = {
    query: null
    // isMousedOver: false
  };

  componentDidMount() {
    const { $app, insight, onDataViewCreate } = this.props;

    // If export & comparison insight, send data to show comparison tables have loaded
    if (insight.isComparisonInsight && $app.isExport) {
      onDataViewCreate({ fullyLoaded: true });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { $insights, expanded, insight } = props;
    const { query } = state;

    if (expanded && !query) {
      return {
        query: $insights.getInsightQuery(insight)
      };
    }

    return null;
  }

  handleExpand = () => {
    const { insight, onExpand } = this.props;
    if (onExpand) {
      onExpand(insight);
    }
  };

  handleOpenInExplorer = () => {
    const { $explorer } = this.props;
    const { query } = this.state;

    $explorer.navigateToExplorer(query);
  };

  render() {
    const { $app, icon, description, expanded, creationTime, insight, detailsLink, spoofedUrl, onDataViewCreate } =
      this.props;
    // const { query, isMousedOver } = this.state;
    const { query } = this.state;
    const { comparison } = insight.get ? insight.get() : insight;
    const showChart = !comparison && !insight.isCapacityErrorInsight;
    const hasQuery = !insight.isCapacityErrorInsight;

    return (
      <InsightBox className={classNames({ expanded }, 'avoid-break')} {...insight.tags}>
        <Box
          p={1}
          textAlign="left"
          overflow="hidden"
          style={expanded ? undefined : { cursor: 'pointer' }}
          onClick={this.handleExpand}
          onMouseOver={this.handleButtonMouseOver}
          onMouseOut={this.handleButtonMouseOut}
          onFocus={this.handleButtonMouseOver}
          onBlur={this.handleButtonMouseOut}
        >
          <Flex overflow="hidden">
            <Box mx="7px">{icon && React.cloneElement(icon, { iconSize: 20 })}</Box>
            <Box flex={1} overflow="hidden" px={1}>
              <Box mb="4px" fontSize="small" lineHeight="17px">
                {description}
              </Box>
              <Flex justifyContent="space-between" fontSize="small" color="muted">
                <Text as="span">{fromNow(creationTime)}</Text>
              </Flex>
            </Box>
          </Flex>
        </Box>

        {expanded && (
          <Flex flexDirection="column">
            {showChart && (
              <InsightChart
                query={query}
                insight={insight}
                loadingText="Querying..."
                showExplorerButton={false}
                onDataViewCreate={onDataViewCreate}
                viewProps={{
                  height: 200,
                  showAxisLabels: false,
                  showTooltips: false
                }}
              />
            )}
            {comparison && (
              <ComparisonInsightTable insight={insight.get ? insight.get() : insight} limitRows={5} compact />
            )}

            <Flex p={1} pt={0}>
              {!$app.isExport &&
                detailsLink &&
                React.cloneElement(detailsLink, {
                  as: 'Button',
                  intent: 'primary',
                  fontWeight: 'bold',
                  minimal: true,
                  small: true,
                  fill: true
                })}

              {!$app.isExport && hasQuery && (
                <Button
                  text="View in Data Explorer"
                  onClick={this.handleOpenInExplorer}
                  intent="primary"
                  fontWeight="bold"
                  fill
                  minimal
                  small
                />
              )}
            </Flex>

            {!$app.isExport && <InsightVote insight={insight.get ? insight.get() : insight} />}
          </Flex>
        )}

        {$app.isExport && spoofedUrl && (
          <a style={{ fontWeight: 'bold', padding: '8px' }} href={spoofedUrl} rel="noreferrer" target="_blank">
            View Insight Details
          </a>
        )}
      </InsightBox>
    );
  }
}

export default CompactInsight;
