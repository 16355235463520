import React from 'react';
import styled, { css } from 'styled-components';
import { FiInfo } from 'react-icons/fi';
import { Box, Flex, Heading, Icon, Tooltip } from 'core/components';
import getErrorBoundary from 'core/util/getErrorBoundary';
import WidgetMenu from './WidgetMenu';

const ErrorBoundaryCmp = getErrorBoundary('WidgetFrame');

const StyledBox = styled(Box)`
  ${(props) =>
    props.hideScrollbar &&
    css`
      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    `}
`;

const WidgetFrame = React.forwardRef((props, ref) => {
  const { canCustomize, children, configAction, menuOptions, onRemove, title, titleOverride, tooltip, ...rest } = props;
  return (
    <ErrorBoundaryCmp>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        pl="12px"
        pr={0}
        minHeight={38}
        width="100%"
        className="drag-header"
      >
        <Flex alignItems="center" width="100%">
          {titleOverride && title}
          {!titleOverride && (
            <Heading level={5} mb={0} width="100%" fontSize={14}>
              {title}
            </Heading>
          )}
          {tooltip && (
            <Tooltip content={<div style={{ maxWidth: 200 }}>{tooltip}</div>} className="widget-tooltip">
              <Icon icon={FiInfo} color="primary" iconSize={18} pl="4px" />
            </Tooltip>
          )}
        </Flex>
        {(!!menuOptions || canCustomize) && (
          <WidgetMenu
            onRemove={onRemove}
            menuOptions={menuOptions}
            configAction={configAction}
            canCustomize={canCustomize}
          />
        )}
      </Flex>
      <StyledBox overflow="auto" borderTop="thinLight" flex={1} ref={ref} {...rest}>
        {children}
      </StyledBox>
    </ErrorBoundaryCmp>
  );
});

export default WidgetFrame;
