import deepClone from '../deepClone';
import buildFilterGroup from './buildFilterGroup';
import hasFilters from './hasFilters';

/**
 * Combines filters objects, nesting them as needed
 *
 * @param filters - an array of filtersObj
 * @returns a combined filtersObj
 */
export default function mergeFilterGroups(...filters) {
  const validFilterGroups = filters.filter((f) => f && hasFilters(f));

  if (validFilterGroups.length === 0) {
    return { connector: 'All', filterGroups: [] };
  }

  if (validFilterGroups.length === 1) {
    return validFilterGroups[0];
  }

  return {
    connector: 'All',
    filterGroups: validFilterGroups.map((filter) =>
      buildFilterGroup({
        ...filter,
        filterGroups: filter.filterGroups.map((group) => deepClone(group))
      })
    )
  };
}
