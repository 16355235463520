const V1_BGP_INVALID_ORIGIN = 'bgp-monitor-invalid-origin';
const V1_BGP_REACHABILITY = 'bgp-monitor-visibility';
const BGP_INVALID_ORIGIN = 'bgp-invalid-origin';
const BGP_REACHABILITY = 'bgp-reachability';

const rpkiConversionMap = {
  0: 'Unknown',
  1: 'Not Found',
  2: 'Invalid (explicit)',
  3: 'Invalid (prefix)',
  4: 'Invalid',
  5: 'Valid',
  8: 'Validation Error'
};

const TFZ_FILTERS = [174, 701, 1239, 1299, 2914, 3257, 3320, 3356, 3491, 5511, 6453, 6461, 6762, 7018, 7922, 12956];

const invalidConversionMap = {
  rpki: {
    type: 'invalidRpki',
    type_display: ({ rpki_status }) => `RPKI ${rpkiConversionMap[rpki_status] || 'Invalid'}`
  },
  origin: {
    type: 'unexpectedOrigin',
    type_display: () => 'Unexpected Origin'
  },
  upstream: {
    type: 'unexpectedUpstream',
    type_display: () => 'Unexpected Upstream'
  }
};

const validateOrigin = (config, row) => {
  const { origin = [], check_rpki = true, routeviewer = false } = config;
  const { origin_asn, rpki_status } = row;
  const origin_asn_string = origin_asn?.toString() || '';

  if (!!routeviewer || (!origin.length && !check_rpki)) {
    return {
      invalid: false,
      type: 'escape'
    };
  }

  // logic mirrors filters defined for invalidOrigin policy: src/node/services/synthetics/bgpPolicy.js
  // note: there exists rpki status that are both not invalid and not valid
  const checkBothOriginAndRPKI = !!origin.length && check_rpki;
  const checkOnlyRPKI = !origin.length && check_rpki;
  const checkOnlyOrigin = !!origin.length && !check_rpki;

  // invalid if rpki_status is not valid and origin is not defined in config
  if (checkBothOriginAndRPKI) {
    return {
      invalid: rpki_status !== 5 && !origin.includes(origin_asn_string),
      type: 'rpki' // invalid rpki supersedes invalid origin
    };
  }

  // invalid only if rpki_status is invalid
  if (checkOnlyRPKI) {
    return {
      invalid: rpki_status === 2 || rpki_status === 3 || rpki_status === 4,
      type: 'rpki'
    };
  }

  // invalid only if origin is not defined in config, and not checking rpki
  if (checkOnlyOrigin) {
    return {
      invalid: !origin.includes(origin_asn_string),
      type: 'origin'
    };
  }

  return {
    invalid: false,
    type: 'escape'
  };
};

const validateUpstream = (config, row) => {
  const { upstream = [], routeviewer = false } = config;
  const { upstream_asn } = row;
  const upstream_asn_string = upstream_asn?.toString() || '';

  if (!!routeviewer || !upstream.length) {
    return {
      invalid: false,
      type: 'escape'
    };
  }

  return {
    invalid: ![].concat(TFZ_FILTERS, upstream).includes(upstream_asn_string),
    type: 'upstream'
  };
};

const getPolicyType = (name) => {
  if (name.endsWith(BGP_INVALID_ORIGIN) || name.endsWith(V1_BGP_INVALID_ORIGIN)) {
    return 'origin';
  }

  if (name.endsWith(BGP_REACHABILITY) || name.endsWith(V1_BGP_REACHABILITY)) {
    return 'reachability';
  }

  return 'unknown';
};

const CONDITION_TYPE_LABELS = {
  CONDITION_TYPE_UNSPECIFIED: 'Unspecified',
  CONDITION_TYPE_UNREACHABLE: 'Unreachable',
  CONDITION_TYPE_ORIGIN: 'Origin',
  CONDITION_TYPE_LEAKING: 'Leaking'
};

module.exports = {
  validateOrigin,
  validateUpstream,
  getPolicyType,
  rpkiConversionMap,
  invalidConversionMap,
  V1_BGP_INVALID_ORIGIN,
  V1_BGP_REACHABILITY,
  BGP_INVALID_ORIGIN,
  BGP_REACHABILITY,
  TFZ_FILTERS,
  CONDITION_TYPE_LABELS
};
