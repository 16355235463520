import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { Position } from '@blueprintjs/core';
import { select, mouse, event } from 'd3-selection';
import { zoom } from 'd3-zoom';
import { debounce } from 'lodash';

import { Flex } from 'core/components';
import { makeText } from 'core/util/svgUtils';
import { pathgraph } from './utils';
import {
  makeNodeRect,
  makePrefixNodeText,
  makeASNNodeIDText,
  makeASNNodeNameText,
  makeVPASNNodeIcon,
  makeLinkPaths,
  makeLinkHoverPaths,
  makeLinkArrows,
  linkHover
} from './utils/helpers';
import BgpPathsGraphPopover from './popover';

@inject('$app', '$dataviews')
@withTheme
@observer
class BgpPathsGraph extends Component {
  state = {
    popoverOpen: false,
    popoverData: null,
    popoverPosition: { left: 0, top: 0, width: 0, height: 0, anchor: Position.BOTTOM },
    xScroll: false
  };

  wrapperRef = React.createRef();

  componentDidMount() {
    this.renderData();
    this.renderDataDebounced = debounce(() => this.renderData(), 150);
    window.addEventListener('resize', this.renderDataDebounced);
  }

  componentDidUpdate(prevProps) {
    const { data, collapsePath, prefix, specificPrefix, asn } = this.props;

    if (
      data !== prevProps.data ||
      collapsePath !== prevProps.collapsePath ||
      prefix !== prevProps.prefix ||
      specificPrefix !== prevProps.specificPrefix ||
      asn !== prevProps.asn
    ) {
      this.renderData();
      this.closePopover();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.renderDataDebounced);

    const { $dataviews } = this.props;
    $dataviews.unregister(this);
  }

  chartRef = (ref) => {
    const { $dataviews } = this.props;

    if (ref) {
      this.chart = ref;
    }

    $dataviews.register(this);
  };

  isHopSelected = (hop, group) => !!group && !!group[hop.prefix] && !!group[hop.prefix][hop.routeIndex];

  isPathSelected = ({ toHops }, group) => toHops && toHops.find((hop) => this.isHopSelected(hop, group));

  openLinkPopover = (data, i, paths) => {
    clearTimeout(this.popoverTimer);

    const [mouseX, mouseY] = mouse(paths[i]);

    this.popoverTimer = setTimeout(() => {
      const popoverPosition = {
        left: mouseX,
        top: mouseY,
        width: 0,
        height: 0,
        anchor: Position.TOP
      };

      this.setState({
        popoverOpen: true,
        popoverPosition,
        popoverData: data
      });
    }, 100);
  };

  openNodePopover = (data, i, nodes) => {
    clearTimeout(this.popoverTimer);

    this.popoverTimer = setTimeout(() => {
      const wrapperRect = this.wrapperRef.current.getBoundingClientRect();
      const nodeRect = nodes[i].firstChild.getBoundingClientRect();
      const popoverPosition = {
        left: nodeRect.x - wrapperRect.x,
        top: nodeRect.y - wrapperRect.y,
        width: nodeRect.width,
        height: nodeRect.height,
        anchor: Position.TOP
      };

      this.setState({
        popoverOpen: true,
        popoverPosition,
        popoverData: data
      });
    }, 100);
  };

  closePopover = () => {
    const { popoverOpen } = this.state;

    if (popoverOpen) {
      clearTimeout(this.popoverTimer);

      this.popoverTimer = setTimeout(() => {
        this.setState({ popoverOpen: false, popoverData: null });
      }, 100);
    }
  };

  onPopoverHover = () => {
    clearTimeout(this.popoverTimer);
  };

  renderData() {
    const { $app, data, lookups, theme, collapsePath, setASN, setPrefix, asn, specificPrefix: prefix } = this.props;
    const { xScroll } = this.state;
    const { colors } = theme;

    if (!this.chart) {
      return;
    }

    this.chart.innerHTML = '';
    const chartContainer = select(this.chart);
    const width = parseInt(chartContainer.style('width')) - 8;
    const tmpSvg = select('body').append('svg');
    const tmpText = makeText(tmpSvg);
    const options = {
      width,
      lookups,
      tmpText,
      collapsePath
    };

    const layout = pathgraph(data, options);
    const vb = layout.bounds.expanded(4);
    const xOverflow = vb.width > width + 8;

    const svg = chartContainer
      .append('svg')
      .attr('viewBox', `${vb.x} ${vb.y} ${width + 8}, ${vb.height}`)
      .attr('height', $app.isExport ? '7in' : vb.height)
      .attr('cursor', xOverflow ? 'grab' : 'default');

    if ($app.isExport) {
      svg.attr('width', '100%');
    }

    const g = svg.append('g').attr('cursor', 'default');

    if (xOverflow) {
      const zoomBehavior = zoom()
        .extent([
          [vb.x, vb.y],
          [vb.x + width, vb.y + vb.height]
        ])
        .scaleExtent([1, 1])
        .translateExtent([
          [vb.x, vb.y],
          [vb.x + vb.width, vb.y + vb.height]
        ])
        .on('start', () => svg.attr('cursor', 'grabbing'))
        .on('end', () => svg.attr('cursor', 'grab'))
        .on('zoom', () => g.attr('transform', event.transform));

      svg.call(zoomBehavior);
    }

    const nodeGroup = g.selectAll('.node').data(layout.nodes).enter().append('g').attr('class', 'node');

    const textColor = theme.name === 'light' ? '#252525' : '#fff';
    const lineColor = colors.gray4;
    const lineHoverColor = colors.gray2;

    const nodeRects = makeNodeRect(nodeGroup);
    nodeRects.attr('stroke-width', (d) => (prefix === d.id || asn === d.id ? 4 : 2));
    const prefixNodes = nodeGroup.filter((d) => d.type === 'prefix');
    const asnNodes = nodeGroup.filter((d) => d.type === 'asn');

    makePrefixNodeText(prefixNodes, textColor);
    makeASNNodeIDText(asnNodes, textColor, collapsePath);
    makeVPASNNodeIcon(
      asnNodes.filter((d) => d.isVp || d.pruned),
      textColor,
      collapsePath
    );

    if (!collapsePath) {
      makeASNNodeNameText(asnNodes, textColor);
    }

    const linkGroup = g
      .selectAll('.link')
      .data(layout.links)
      .enter()
      .append('g')
      .attr('class', 'link')
      .attr('fill', 'none');

    const linkPaths = makeLinkPaths(linkGroup, lineColor);
    const linkPathHovers = makeLinkHoverPaths(linkGroup);
    const linkArrows = makeLinkArrows(linkGroup, lineColor);

    const handleHoverForLinks = linkHover.bind(null, {
      paths: linkPaths,
      arrows: linkArrows,
      isSelected: this.isPathSelected,
      hoverColor: lineHoverColor,
      color: lineColor
    });

    linkPathHovers
      .on('mouseenter', (d, i, thesePaths) => {
        handleHoverForLinks(d.info.group);
        this.openLinkPopover(d, i, thesePaths);
      })
      .on('mouseleave', () => {
        handleHoverForLinks();
        this.closePopover();
      });

    nodeGroup
      .on('mouseenter', (d, i, nodes) => {
        nodeRects.attr('stroke-width', (d2) => (d.id === d2.id || prefix === d2.id || asn === d2.id ? 4 : 2));
        handleHoverForLinks(d.group);
        this.openNodePopover(d, i, nodes);
      })
      .on('mouseleave', () => {
        nodeRects.attr('stroke-width', (d) => (prefix === d.id || asn === d.id ? 4 : 2));
        handleHoverForLinks();
        this.closePopover();
      });

    asnNodes.on('click', (d) => setASN(d.id));

    prefixNodes.on('click', (d) => setPrefix(d.id));

    if (xOverflow !== xScroll) {
      this.setState({ xScroll: xOverflow });
    }
  }

  render() {
    const { $app, lookups } = this.props;
    const { popoverOpen, popoverData, popoverPosition } = this.state;

    return (
      <Flex
        flexDirection="column"
        display={$app.isExport ? 'block' : 'flex'}
        flex={1}
        position="relative"
        zIndex={1}
        ref={this.wrapperRef}
      >
        <div ref={this.chartRef} />
        <BgpPathsGraphPopover
          isOpen={popoverOpen}
          data={popoverData}
          position={popoverPosition}
          lookups={lookups}
          onMouseOver={this.onPopoverHover}
          onMouseOut={this.closePopover}
        />
      </Flex>
    );
  }
}

export default BgpPathsGraph;
