import * as React from 'react';
import { observer } from 'mobx-react';
import { Box, Flex, Heading, Text, Link } from 'core/components';
import { formConsumer } from 'core/form';
import EnrichmentScopeEditor from './scopeEditor/EnrichmentScopeEditor';

@formConsumer
@observer
export default class AzureEnrichmentScope extends React.Component {
  render() {
    return (
      <Flex flexDirection="column" gap="1" flex={1} mb={2}>
        <Flex flexDirection="column">
          <Box>
            <Heading level={4}>Define Network Scope for Enrichment (optional)</Heading>
            <Text as="p" pb={2} muted>
              Specify any additional subscriptions you would like included for metadata enrichment. As we parse the
              subscriptions we will check to verify they&apos;re valid, and fetch all resource groups for each
              subscription. Any invalid subscriptions will be ignored from the metadata enrichment scope.
              <br />
              By default, all resource groups will be included in the scope of the subscription&apos;s metadata
              enrichment, or you can select specific resource groups to use via the drop-down.
              <br />
              If you don&apos;t care to add any more subscriptions to your export at this time, continue to the next
              step.
              <br />
              <Link to="https://kb.kentik.com/v0/Bd08.htm#Bd08-Define_Enrichment_Scope" blank>
                Click here to learn more.
              </Link>
            </Text>
          </Box>
          <Flex flexDirection="column" width="60%">
            <EnrichmentScopeEditor />
          </Flex>
        </Flex>
      </Flex>
    );
  }
}
