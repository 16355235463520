// routes for login paths
const LOGIN = {
  ENTRY: '/login',
  TWO_FACTOR: '/login/verify-two-factor',
  // note that this one cannot be changed trivially because it contains a variable.
  // fortunately it's still pointing at the same location as it previously did!
  SSO_COMPANY: '/login/sso/:companyIdentifier',
  SSO_LOOKUP: '/login/ssolookup',
  PASSWORD_RECOVERY: '/login/password',
  ACCOUNT_ACTIVATION: '/login/account-activation'
};

module.exports = { LOGIN };
