import { Rect } from 'app/views/synthetics/components/traceroutes/rect';

export default function (structure, options) {
  const { nodes, numberOfColumns } = structure;
  const { width: minWidth } = options;

  // 1. add bounds to nodes in columns
  // note: prefix nodes are not in columns
  structure.columnKeys.reduce((acc, curr) => {
    const column = structure.columns.get(curr);
    column.left = acc;
    column.relativeTop = 0;

    column.nodes.forEach((nodeId) => {
      const node = nodes[nodeId];
      const left = column.left + (column.width - node.size[0]) / 2;
      const top = (structure.height - column.height) / 2 + column.relativeTop;

      node.bounds = new Rect(left, top, left + node.size[0], top + node.size[1]);
      column.relativeTop += node.height + structure.gutterHeight;
    });

    return (acc += structure.gutterWidth + column.width);
  }, 0);

  // 2. add bounds to prefix nodes
  structure.prefixes.forEach((prefix, i) => {
    const ySpace = structure.height - structure.prefixColumnHeight;
    const yOffset = ySpace ? ySpace / 2 : 0;
    const top = yOffset + (prefix.height + structure.gutterHeight) * i;
    const xSpace = structure.prefixColumnWidth - prefix.size[0];
    const xOffset = xSpace ? xSpace / 2 : 0;
    const xWidth = structure.width || minWidth;
    const left = xWidth - structure.prefixColumnWidth + xOffset;

    nodes[prefix.id].bounds = new Rect(left, top, left + prefix.size[0], top + prefix.size[1]);
  });

  // 3. add points and direction to links
  // note: there is some additional information being added that could potentially be moved into structure.js
  const links = Object.keys(structure.links).reduce((acc, curr) => {
    const node = nodes[curr];

    Object.keys(structure.links[curr]).forEach((key) => {
      const toNode = nodes[key];
      const points = [];
      let direction = 'forward';

      if (node.column < toNode.column || toNode.type === 'prefix') {
        const { right: x0, cy: y0 } = node.bounds;
        const { left: x1, cy: y1 } = toNode.bounds;
        points.push([x0, y0]);

        const toColumn = toNode.type === 'prefix' ? numberOfColumns : toNode.column;
        const columnSpan = toColumn - node.column;
        const virtualId = `${node.id}_${toNode.id}`;

        if (columnSpan > 1) {
          for (let i = node.column + 1; i < toColumn; i += 1) {
            const { cy } = nodes[`${virtualId}_${i}`].bounds;
            const { left, width } = structure.columns.get(i);

            points.push([left, cy]);
            points.push([left + width, cy]);
          }
        }

        points.push([x1, y1]);
      } else if (node.column > toNode.column) {
        const { left: x0, cy: y0 } = node.bounds;
        const { right: x1, cy: y1 } = toNode.bounds;
        direction = 'backward';

        points.push([x0, y0]);
        points.push([x1, y1]);
      } else {
        const { cy: y0 } = node.bounds;
        const { cy: y1 } = toNode.bounds;
        direction = 'same';

        const x0 = y0 > y1 ? node.bounds.right : node.bounds.left;
        const x1 = y0 > y1 ? toNode.bounds.right : toNode.bounds.left;

        points.push([x0, y0]);
        points.push([x1, y1]);
      }

      acc.push({
        ...structure.links[curr][key],
        from: curr,
        to: key,
        points,
        type: toNode.type,
        direction
      });
    });

    return acc;
  }, []);

  // 4. add structure bounds and remove virtual nodes
  return {
    bounds: new Rect(0, 0, structure.width, structure.height),
    nodes: Object.values(nodes).filter((node) => node.type !== 'virtual'),
    links: Object.values(links)
  };
}
