/**
 * @param {[number, number]} a
 * @param {[number, number]} b
 * @returns {boolean} true if the time interval a intersects the time interval b
 */
export function timeIntervalsIntersect(a, b) {
  return (a[0] >= b[0] && a[0] <= b[1]) || (b[0] >= a[0] && b[0] <= a[1]);
}

export function calculateStdDeviation(data) {
  const filteredData = data.filter((datum) => datum !== undefined);
  const avg = filteredData.reduce((sum, row) => sum + row, 0) / filteredData.length;
  const diffs = filteredData.map((row) => (row - avg) ** 2);
  const stdDev = Math.sqrt(diffs.reduce((sum, val) => sum + val, 0) / diffs.length);
  return { avg, stdDev };
}

export function stdDeviation(data) {
  const { stdDev } = calculateStdDeviation(data);
  return stdDev;
}

export function nStdDeviations(data, n = 2) {
  const { avg, stdDev } = calculateStdDeviation(data);
  return avg + stdDev * n;
}
