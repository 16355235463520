import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { formConsumer } from 'core/form';
import StaticRangesDisplay from './StaticRangesDisplay';
import PercentageRangesDisplay from './PercentageRangesDisplay';

@inject('$dictionary')
@formConsumer
@observer
class BracketOptionsDisplay extends Component {
  render() {
    const { form } = this.props;
    const fieldValue = form.getValue('bracketOptions');
    const bracketType = fieldValue && fieldValue.type;

    return (
      <div>
        {bracketType === 'staticRanges' && <StaticRangesDisplay />}
        {(bracketType === 'percentages' || bracketType === 'percentiles') && <PercentageRangesDisplay />}
      </div>
    );
  }
}

export default BracketOptionsDisplay;
