import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

import { Box } from 'core/components';
import { ValidationErrorsOrHelpText } from 'core/form';

import ModalOptionGroups from './ModalOptionGroups';

@observer
class ModalOptions extends Component {
  static defaultProps = {
    padding: 2, // coefficient for internal padding
    padTop: false,
    collapsedState: {},
    collapsible: true
  };

  @observable
  headingWidth;

  componentDidUpdate(prevProps) {
    const { collapsedState } = this.props;
    const { collapsedState: prevCollapsedState } = prevProps;

    if (
      (this.headingCmp && Object.keys(prevCollapsedState).length !== Object.keys(collapsedState).length) ||
      Object.keys(collapsedState).some((group) => prevCollapsedState[group] !== collapsedState[group])
    ) {
      setTimeout(
        action(() => {
          if (this.headingCmp) {
            this.headingWidth = this.headingCmp.offsetWidth;
          }
        }),
        250
      );
    }
  }

  handleSelectGroup = (options) => {
    const { field, selectState, disabledValues = [] } = this.props;
    const { values, selectItems, unselectItems } = selectState;

    let groupOptions;
    if (Array.isArray(options)) {
      groupOptions = options;
    } else {
      groupOptions = Object.keys(options).reduce((result, group) => result.concat(options[group]), []);
    }

    groupOptions = groupOptions.filter((option) => !disabledValues.includes(option.value));

    const groupValues = groupOptions.map((option) => option.value);

    const allSelect = groupValues.every((value) => values.includes(value));

    if (allSelect) {
      unselectItems(field, groupValues);
    } else {
      selectItems(field, groupValues);
    }
  };

  handleOptionsContainerRef = (box) => {
    if (box) {
      this.headingCmp = box;
      setTimeout(
        action(() => {
          if (box) {
            this.headingWidth = box.offsetWidth;
          }
        }),
        100
      );
    }
  };

  render() {
    const { selectState, field, disabled, headings, padding, padTop } = this.props;
    const showError = field.hasError && (field.form.options.showPristineErrors || !field.pristine);

    return (
      <div className="flex-column" style={{ flex: 1, overflow: 'hidden' }}>
        {showError && (
          <Box m={2}>
            <ValidationErrorsOrHelpText calloutStyle field={field} />
          </Box>
        )}
        <div style={{ flex: 1, overflow: 'auto' }}>
          <div>
            {headings && (
              <Box pt={padTop && !showError ? padding : 0} width={this.headingWidth} px={`${padding * 8}px`}>
                {headings}
              </Box>
            )}
            <div
              className="modal-options-container"
              ref={this.handleOptionsContainerRef}
              style={{
                padding: padding * 8,
                paddingTop: headings || (padTop && !showError) ? padding * 8 : 0
              }}
            >
              <ModalOptionGroups
                {...this.props}
                options={selectState.filteredOptions}
                values={selectState.values}
                disabled={disabled}
                handleSelectGroup={this.handleSelectGroup}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalOptions;
