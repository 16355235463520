import React from 'react';
import { withTheme } from 'styled-components';
import capitalize from 'lodash/capitalize';

import {
  MdArchive,
  MdBugReport,
  MdBuild,
  MdCameraAlt,
  MdChat,
  MdClose,
  MdCloudDownload,
  MdFilterDrama,
  MdDescription,
  MdWatch,
  MdGetApp,
  MdLanguage,
  MdLocalLibrary,
  MdMailOutline,
  MdMap,
  MdMovie,
  MdPeopleOutline,
  MdSave,
  MdSettingsInputHdmi,
  MdShoppingCart,
  MdDns,
  MdStore,
  MdVideogameAsset,
  MdVoiceChat,
  MdVolumeUp,
  MdVpnLock,
  MdFavoriteBorder
} from 'react-icons/md';

import Icon from 'core/components/Icon';
import * as Logos from 'app/assets/logos';
import * as IntegrationLogos from 'app/assets/integration-logos';

// these Services will have their Logos "inverted", essentially changing black to white so they look good in dark mode.
const INVERTED_DARK_MODE_SERVICES = ['Amazon', 'Disney', 'Plex', 'Sony', 'TikTok', 'Valve Software', 'Verizon'];

const getLogoName = (name) => capitalize(name.replace(/\+/g, 'plus').replace(/[\W]/g, ''));

const getCategoryAndServiceTypeIcon = (value) => {
  switch (value) {
    case 'Ads':
      return MdStore;
    case 'Adult':
      return MdClose;
    case 'Antivirus':
      return MdBugReport;
    case 'Audio':
      return MdVolumeUp;
    case 'Cloud':
      return MdFilterDrama;
    case 'Conferencing':
      return MdVoiceChat;
    case 'Dating':
      return MdFavoriteBorder;
    case 'Developer Tools':
      return MdBuild;
    case 'Documents':
      return MdDescription;
    case 'Ecommerce':
      return MdShoppingCart;
    case 'File Sharing':
      return MdArchive;
    case 'Gaming':
      return MdVideogameAsset;
    case 'IoT':
      return MdWatch;
    case 'Mail':
      return MdMailOutline;
    case 'Maps':
      return MdMap;
    case 'Media':
      return MdLocalLibrary;
    case 'Messaging':
      return MdChat;
    case 'Network':
      return MdSettingsInputHdmi;
    case 'Newsgroups':
      return MdSave;
    case 'Photo Sharing':
      return MdCameraAlt;
    case 'Social':
      return MdPeopleOutline;
    case 'Software Download':
      return MdGetApp;
    case 'Software Updates':
      return MdCloudDownload;
    case 'Storage':
      return MdDns;
    case 'Video':
      return MdMovie;
    case 'VPN':
      return MdVpnLock;
    case 'Web':
      return MdLanguage;
    default:
      return 'help';
  }
};

const getProviderLogo = ({ value, theme, showValue, ...rest }) => {
  const baseStyle = { width: 'auto' };
  // const flipBlackToWhite = theme.name === 'dark' ?  : baseStyle;
  const logoProps = {
    width: 'auto',
    height: 24,
    ...rest
  };

  const style =
    theme.name === 'dark' && INVERTED_DARK_MODE_SERVICES.includes(value)
      ? { ...baseStyle, filter: 'brightness(0) invert(1)' }
      : baseStyle;

  const LogoComponent = Logos[`${getLogoName(value)}Logo`];
  if (!LogoComponent) {
    return showValue ? value : null;
  }

  return <LogoComponent {...logoProps} style={style} />;
};

const getIntegrationLogo = ({ value, theme, useAlternateDarkLogo, showValue, ...rest }) => {
  const baseStyle = { width: 'auto' };
  const logoProps = {
    width: 'auto',
    height: 40,
    ...rest
  };

  let LogoComponent = IntegrationLogos[`${getLogoName(value)}Logo`];

  if (useAlternateDarkLogo && theme.name === 'dark') {
    LogoComponent = IntegrationLogos[`${getLogoName(value)}DarkLogo`];
  }

  if (!LogoComponent) {
    return showValue ? value : null;
  }

  return <LogoComponent {...logoProps} style={baseStyle} />;
};

const getServiceLogo = ({ value, provider, theme, showValue, ...rest }) => {
  const baseStyle = { width: 'auto', marginRight: 8 };
  const logoProps = {
    width: 'auto',
    height: 30,
    style: baseStyle,
    ...rest
  };

  const LogoComponent = Logos[`${getLogoName(value)}Logo`];

  if (!LogoComponent) {
    return showValue ? value : null;
  }

  return <LogoComponent {...logoProps} />;
};

const OttIcon = ({ type = 'provider', value, provider, showValue, children, ...rest }) => {
  let icon = null;

  if (type === 'type' || type === 'category') {
    icon = <Icon icon={getCategoryAndServiceTypeIcon(value)} iconSize={24} {...rest} />;
  } else if (type === 'provider') {
    icon = getProviderLogo({ value, showValue, ...rest });
  } else if (type === 'service') {
    icon = getServiceLogo({ value, provider, showValue, ...rest });
  } else if (type === 'integration') {
    icon = getIntegrationLogo({ value, showValue, ...rest });
  }

  if (typeof children === 'function') {
    return children({ icon });
  }

  return icon;
};

export default withTheme(OttIcon);
