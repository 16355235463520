import React, { Component } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Position } from '@blueprintjs/core';
import { Box, Flex, Text } from 'core/components';
import { formConsumer, Field, DateTime, Switch, SingleLineErrors } from 'core/form';
import LookbackDateRange from 'core/form/components/LookbackDateRange';

@formConsumer
@observer
export default class TimeWindow extends Component {
  static defaultProps = {
    neverExpiresTitle: 'Start Time',
    expiresTitle: 'Silence Window',
    neverExpireFieldName: 'neverExpire',
    startTimeFieldName: 'startTime',
    endTimeFieldName: 'expirationTime'
  };

  state = {
    isEditingDateRange: false
  };

  componentDidMount() {
    const { form, startTimeFieldName, endTimeFieldName } = this.props;

    if (!this.startTime) {
      form.setValue(startTimeFieldName, moment().add(16, 'minutes'));
    }

    if (!this.endTime) {
      form.setValue(endTimeFieldName, moment().add(7, 'days'));
    }
  }

  get neverExpire() {
    const { form, neverExpireFieldName } = this.props;
    return form.getValue(neverExpireFieldName);
  }

  get startTime() {
    const { form, startTimeFieldName } = this.props;
    return form.getValue(startTimeFieldName);
  }

  get endTime() {
    const { form, endTimeFieldName } = this.props;
    return form.getValue(endTimeFieldName);
  }

  get title() {
    const { neverExpiresTitle, expiresTitle } = this.props;
    return this.neverExpire ? neverExpiresTitle : expiresTitle;
  }

  handleDateRangeChange = ({ startDate, endDate }) => {
    const { form, startTimeFieldName, endTimeFieldName } = this.props;

    form.setValues({
      [startTimeFieldName]: moment.unix(startDate),
      [endTimeFieldName]: moment.unix(endDate)
    });
  };

  handleDateRangeEditModeChange = (isEditingDateRange) => this.setState({ isEditingDateRange });

  handleNeverExpireChange = (field, neverExpires) => {
    const { form, endTimeFieldName } = this.props;

    if (neverExpires) {
      // blank out the expiration time
      form.setValue(endTimeFieldName, undefined);
    } else {
      // reset the expiration time to the default value
      form.getField(endTimeFieldName).reset();
    }
  };

  render() {
    const { form, neverExpireFieldName, startTimeFieldName } = this.props;
    const { isEditingDateRange } = this.state;

    return (
      <Box>
        <Flex alignItems="center" justifyContent="space-between">
          <Text small as="div" fontWeight="bold" mb="4px" lineHeight={1.2}>
            {this.title}
          </Text>
        </Flex>
        <Flex alignItems="center">
          {this.neverExpire ? (
            <Field
              name={startTimeFieldName}
              large
              mb={0}
              labelProps={{ fontSize: 12, mb: '3px' }}
              mr="18px"
              showError={false}
            >
              <DateTime
                fill
                timePrecision={null}
                maxDate={moment().add(10, 'year').toDate()}
                popoverProps={{
                  minimal: true,
                  modifiers: {
                    flip: { enabled: true, boundariesElement: 'viewport' },
                    preventOverflow: { enabled: true, boundariesElement: 'window' },
                    offset: { enabled: true, offset: '0, 8' }
                  },
                  position: Position.BOTTOM_RIGHT
                }}
                style={{ width: 230 }}
              />
            </Field>
          ) : (
            <LookbackDateRange
              stacked
              disabled={this.neverExpire}
              showLabel={false}
              showShortcuts={false}
              minDate={moment().add(16, 'minutes').toDate()}
              maxDate={moment().add(1, 'year').toDate()}
              startDate={moment(this.startTime).unix()}
              endDate={moment(this.endTime).unix()}
              onChange={this.handleDateRangeChange}
              onEditModeChange={this.handleDateRangeEditModeChange}
            />
          )}

          {!isEditingDateRange && (
            <Field
              name={neverExpireFieldName}
              onChange={this.handleNeverExpireChange}
              ml={2}
              mb={0}
              minWidth="fit-content"
            >
              <Switch switchLabel="Never Expire" />
            </Field>
          )}
        </Flex>
        <SingleLineErrors fields={[form.getField(startTimeFieldName)]} />
      </Box>
    );
  }
}
