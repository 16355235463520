import React from 'react';
import { observer } from 'mobx-react';

import { Flex } from 'core/components';

/*
 * TODO: this file has to die. Please don't use it.
 */

const ToolsWrapper = observer(({ children, ...rest }) => (
  <Flex justifyContent="flex-end" {...rest} onMouseDown={(e) => e.stopPropagation()}>
    <Flex alignItems="center">{children}</Flex>
  </Flex>
));

const DataViewTools = observer(({ children, dataview, shouldCollapseButtonText, showButtonText, dataViewSize }) => {
  if (!dataview) {
    return null;
  }

  const { loading } = dataview.loading;

  const toolProps = {
    mt: '4px',
    mr: '4px',
    small: true,
    // minimal: true,
    dataview,
    dataViewSize,
    disabled: loading,
    loading,
    shouldCollapseButtonText,
    showButtonText,
    Wrapper: ToolsWrapper
  };

  return children({
    ...toolProps
  });
});

export default DataViewTools;
