import { bracketingColors } from 'core/components/theme';
import { transformUnitValueIn } from 'app/forms/config/bracketOptions';
import $dictionary from 'app/stores/$dictionary';

export const colorOptions = bracketingColors;
export const overColorValue = colorOptions[colorOptions.length - 1];

const rangeMap = {
  percentages: [
    {
      to: 20,
      data: { value: colorOptions[0] }
    },
    {
      to: 40,
      data: { value: colorOptions.length > 1 ? colorOptions[1] : colorOptions[0] }
    },
    {
      to: 60,
      data: { value: colorOptions.length > 2 ? colorOptions[2] : colorOptions[0] }
    },
    {
      to: 80,
      data: { value: colorOptions.length > 3 ? colorOptions[3] : colorOptions[0] }
    }
  ],
  staticRanges: [
    {
      to: 10000000,
      data: { value: colorOptions[0] }
    }
  ],
  percentiles: [
    {
      to: 25,
      data: { value: colorOptions[0] }
    },
    {
      to: 50,
      data: { value: colorOptions.length > 1 ? colorOptions[1] : colorOptions[0] }
    },
    {
      to: 75,
      data: { value: colorOptions.length > 2 ? colorOptions[2] : colorOptions[0] }
    }
  ]
};

export function getDefaultRanges({ type }) {
  return rangeMap[type] || [];
}

export function getBracketingDataClasses({ metadata, bracketOptions }) {
  const { rangeMetadata, over, rangeRestriction } = bracketOptions;
  const dataClasses = [];
  metadata.rangeValues.forEach((rv, index, array) => {
    dataClasses.push({
      color: rv.data.value,
      from: index === 0 ? -Infinity : array[index - 1].to,
      to: rv.to,
      name: rangeMetadata.metaRanges[index].bracketLabel
    });
  });
  dataClasses.push({
    color: over,
    from: metadata.rangeValues[metadata.rangeValues.length - 1].to,
    name: rangeMetadata.metaRanges[metadata.rangeValues.length].bracketLabel
  });

  // range restriction dataclasses must be AFTER range classes, apparently w/highcharts, last dataclass wins.
  if (rangeRestriction.enabled) {
    const minTo = Math.max(...metadata.values.filter((value) => value < metadata.min));
    const maxFrom = Math.min(...metadata.values.filter((value) => value > metadata.max));
    if (minTo !== -Infinity) {
      dataClasses.push({
        color: '#8FA3B3',
        from: -Infinity,
        to: minTo,
        name: 'Under bracketing min'
      });
      // modify the "from" value of first range to be the min
      if (dataClasses.length > 1) {
        dataClasses[0].from = metadata.min;
      }
    }
    if (maxFrom !== Infinity) {
      // add "to" ceiling to last range, (must skip back two places if min exclusion was added)
      const lastRangeOffset = minTo !== -Infinity && dataClasses.length > 1 ? 2 : 1;
      dataClasses[dataClasses.length - lastRangeOffset].to = maxFrom;
      dataClasses.push({
        color: '#5c7080',
        from: maxFrom,
        name: 'Over bracketing max'
      });
    }
  }
  return dataClasses;
}

/**
 * This is the units label used for BracketOptionsForm input and sidebar bracket display. Based on outsort units
 * but NOT queryResult prefix dependent, it's just fixed so if units are bytes or packets, it's always Mbits/s or
 * KPackets/s so user doesn't have to type a bunch of zeros.
 * @param bracketAggregationUnit
 * @returns {string}
 */
function getBracketAggregationLabel({ bracketAggregationUnit }) {
  const queryUnits = $dictionary.get(`units.${bracketAggregationUnit}`);
  if (
    bracketAggregationUnit === 'bytes' ||
    bracketAggregationUnit === 'in_bytes' ||
    bracketAggregationUnit === 'out_bytes'
  ) {
    return `M${queryUnits.toLowerCase()}`;
  }
  if (
    bracketAggregationUnit === 'packets' ||
    bracketAggregationUnit === 'in_packets' ||
    bracketAggregationUnit === 'out_packets'
  ) {
    return `K${queryUnits.toLowerCase()}`;
  }
  return queryUnits;
}

function getBracketUnitDisplayLabel({ bracketAggregationUnit, type }) {
  if (type === 'percentages') {
    return '%';
  }
  if (type === 'percentiles') {
    return 'th percentile';
  }
  return getBracketAggregationLabel({ bracketAggregationUnit });
}

export function getRangeMetadata({ bracketOptions }) {
  const { bracketAggregationUnit, type, ranges } = bracketOptions;
  const bracketUnitDisplay = getBracketUnitDisplayLabel({ bracketAggregationUnit, type });
  const isPercentageBased = type === 'percentages' || type === 'percentiles';
  const doTransform = !isPercentageBased;

  const metaRanges = ranges.map((range, index, array) => {
    const rangeValue = doTransform ? transformUnitValueIn(bracketAggregationUnit, range.to) : range.to;
    let previousRangeValue = index === 0 ? '' : array[index - 1].to;
    previousRangeValue =
      previousRangeValue && doTransform
        ? transformUnitValueIn(bracketAggregationUnit, previousRangeValue)
        : previousRangeValue;
    return {
      bracketLabel: `${previousRangeValue ? ` \u003E ${previousRangeValue} to ` : '\u2264'}${rangeValue}${
        isPercentageBased ? bracketUnitDisplay : ` ${bracketUnitDisplay}`
      }`
    };
  });
  metaRanges.push({
    bracketLabel: `Over ${
      doTransform
        ? transformUnitValueIn(bracketAggregationUnit, ranges[ranges.length - 1].to)
        : ranges[ranges.length - 1].to
    }${isPercentageBased ? bracketUnitDisplay : ` ${bracketUnitDisplay}`}`
  });
  return { metaRanges };
}

function getPercentileValueOfSpan({ percentile, values }) {
  // standard percentile rank calculation adjusted for 0 based array index.
  const idx = (+percentile / 100) * (values.length + 1) - 1;

  // Handle 0th and 100th, percentile weirdness, lots of different definitions here.
  // For now 100th percentile will be the max value (even though technically it should be value outside results).
  // and 0th will be min(correct). This will work for range limiting (exclusive values).
  if (idx < 0) {
    return values[0];
  }
  if (idx > values.length - 1) {
    return values[values.length - 1];
  }

  if (idx % 1) {
    // this is how it's being done in timeSeriesResultsProcessor on backend so using it here.
    return (values[Math.floor(idx)] + values[Math.ceil(idx)]) / 2;
  }
  return values[idx];
}

function getPercentageValueOfSpan({ percentage, min, max }) {
  return (percentage / 100) * (max - min) + min;
}

export function isMatrix({ queryResults }) {
  return !!(queryResults.size === 1 && queryResults.at(0).get('matrix'));
}

/**
 * Get value metadata for bracketAggregation field of nonOverlay rows from query results (e.g. min, max, values array)
 * @param queryResults
 * @param bracketAggregation bracketOptions bracketAggregation.
 * @returns {{min: number, max: number, values: []}}
 */
function getQueryResultMetadata({ queryResults, bracketAggregation }) {
  const metadata = { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER, values: [] };

  if (isMatrix({ queryResults })) {
    const matrix = queryResults.at(0).get('matrix');
    matrix.forEach((matrixArray) => {
      const value = +matrixArray[2];
      metadata.min = value < metadata.min ? value : metadata.min;
      metadata.max = value > metadata.max ? value : metadata.max;
      metadata.values.push(value);
    });
  } else {
    queryResults.nonOverlayRows.forEach((row) => {
      const value = +row.get(bracketAggregation);
      metadata.min = value < metadata.min ? value : metadata.min;
      metadata.max = value > metadata.max ? value : metadata.max;
      metadata.values.push(value);
    });
  }
  metadata.values.sort((a, b) => a - b);
  metadata.sorted = true;
  return metadata;
}

/**
 * Returns adjusted min/max for metadata values based on bracketOptions rangeRestrictions.
 * Note: only uses restricted values if they ARE restrictive (subset of actual result values), If they ARE
 * restrictive, don't just use those values, collapse down non-excluded min/max result values so percentages
 * only take into account the range of result values, not the entire "restricted" range, which could be much larger.
 * @param metadata - The metadata object with min/max/values fields populated.
 * @param rangeRestriction - The bracketOptions rangeRestriction object.
 * @returns {*}
 */
function getRangeAdjustedMinMax({ metadata, rangeRestriction }) {
  const rangeRestricted = rangeRestriction && rangeRestriction.enabled;
  const { min, max, values } = metadata;

  if (rangeRestricted && rangeRestriction.units === 'raw') {
    const rmin =
      (rangeRestriction.min || rangeRestriction.min === 0) && rangeRestriction.min > metadata.min
        ? Math.min(...values.filter((value) => value >= rangeRestriction.min))
        : min;
    const rmax =
      (rangeRestriction.max || rangeRestriction.max === 0) && rangeRestriction.max < metadata.max
        ? Math.max(...values.filter((value) => value <= rangeRestriction.max))
        : max;
    return { min: rmin, max: rmax };
  }

  if (rangeRestricted && rangeRestriction.units === 'percentile') {
    const pvalMin =
      rangeRestriction.min || rangeRestriction.min === 0
        ? getPercentileValueOfSpan({ percentile: rangeRestriction.min, values })
        : min;
    const pvalMax =
      rangeRestriction.max || rangeRestriction.max === 0
        ? getPercentileValueOfSpan({ percentile: rangeRestriction.max, values })
        : max;

    return {
      min: pvalMin > min ? Math.min(...values.filter((value) => value >= pvalMin)) : min,
      max: pvalMax < max ? Math.max(...values.filter((value) => value <= pvalMax)) : max
    };
  }

  if (rangeRestricted && rangeRestriction.units === 'percent') {
    const pvalMin =
      rangeRestriction.min || rangeRestriction.min === 0
        ? getPercentageValueOfSpan({ percentage: rangeRestriction.min, min, max })
        : min;
    const pvalMax =
      rangeRestriction.max || rangeRestriction.max === 0
        ? getPercentageValueOfSpan({ percentage: rangeRestriction.max, min, max })
        : max;

    return {
      min: pvalMin > min ? Math.min(...values.filter((value) => value >= pvalMin)) : min,
      max: pvalMax < max ? Math.max(...values.filter((value) => value <= pvalMax)) : max
    };
  }

  // no calculations needed, just return min/max
  return { min, max };
}

/**
 * BracketOption ranges are specified in percentages/percentiles, this function will return array of rangeObjects
 * with 'to' field populated (same as bracketOption range obj) with the actual value the percentage/percentile equates
 * to based on the metadata fields
 * @param metadata Object with queryResult min/max/values
 * @param ranges bracketOption ranges
 * @param type bracketOption type
 * @returns {{rangeValues: Array}}
 */
function getPercentageValueBasedRanges({ metadata, ranges, type }) {
  const { min, max, values } = metadata;
  const rangeValues = [];
  let value;

  ranges.forEach((range, idx) => {
    value =
      type === 'percentiles'
        ? getPercentileValueOfSpan({ percentile: +range.to, values: values.filter((val) => !(val < min || val > max)) })
        : getPercentageValueOfSpan({ percentage: +range.to, min, max });

    rangeValues[idx] = {
      to: value,
      origTo: +range.to,
      data: range.data
    };
  });
  return { rangeValues };
}

export function getMetadata({ queryResults, bracketOptions }) {
  const { ranges, rangeRestriction, bracketAggregation, type } = bracketOptions;
  // get raw metadata from queryResult for bracket agg value (value array, min/max)
  const metadata = getQueryResultMetadata({ queryResults, bracketAggregation });

  // update min/max values based on bracketOptions.rangeRestrictions
  Object.assign(metadata, getRangeAdjustedMinMax({ metadata, rangeRestriction }));

  if (type === 'percentages' || type === 'percentiles') {
    Object.assign(metadata, getPercentageValueBasedRanges({ metadata, ranges, type }));
  } else {
    Object.assign(metadata, { rangeValues: ranges });
  }

  return metadata;
}
