import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Heading, Box, Button, Flex, FlexColumn, Grid, Text, Icon } from 'core/components';
import { Field, formConsumer, Checkbox } from 'core/form';

import { CLOUD_PROVIDERS } from 'app/util/constants';

import { isGoogleCloud } from '@kentik/ui-shared/util/map';
import { isAzure, isAws } from 'shared/customDimensions/constants';

import { IoIosRadioButtonOff, IoIosCheckmarkCircle } from 'react-icons/io';
import { awsTile, gcpTile, azureTile, ociTile, PROD_COMPANIES_ALLOW_NO_METADATA } from '../constants';

@formConsumer
@inject('$cloudExportWizard', '$clouds', '$auth')
@observer
class ProvidersAndFeatures extends Component {
  state = {
    allowNoMetadata: false
  };

  componentDidMount() {
    const { $auth } = this.props;
    const { company_id } = $auth.activeUser;
    this.setState({ allowNoMetadata: PROD_COMPANIES_ALLOW_NO_METADATA.includes(company_id) });
  }

  renderMetadataCheckbox() {
    const { $cloudExportWizard } = this.props;
    const { isSelectedCloudProviderAzure } = $cloudExportWizard;
    const { allowNoMetadata } = this.state;
    // backend work only supports metadata-less exports for Azure at present
    const canBeEnabled = isSelectedCloudProviderAzure && allowNoMetadata;

    return (
      <Field name="metadata_collection" showLabel={false} disabled={!canBeEnabled} large>
        <Checkbox />
      </Field>
    );
  }

  renderCloudSpecificOptions() {
    const { $cloudExportWizard } = this.props;
    const { selectedCloudProvider, isFlowLogCollectionEnabled } = $cloudExportWizard;

    if (isAws(selectedCloudProvider) && isFlowLogCollectionEnabled) {
      return (
        <Box ml={1}>
          <Field
            name="properties.aws_delete_after_read"
            label="Allow Kentik to delete logs from your AWS bucket once they are exported"
            showLabel={false} // hides the label above the checkbox, still shows label value beside the checkbox
          >
            <Checkbox />
          </Field>
          <Field name="useTerraform" showLabel={false}>
            <Checkbox />
          </Field>
        </Box>
      );
    }
    if (isGoogleCloud(selectedCloudProvider)) {
      return (
        <Field name="properties.gcp_cloud_run" showLabel={false} large>
          <Checkbox />
        </Field>
      );
    }
    if (isAzure(selectedCloudProvider)) {
      return (
        <>
          {isFlowLogCollectionEnabled && (
            <>
              {/*
              // leave this commented out until we have terraform support for Azure
              <Field name="useTerraform" showLabel={false}>
                <Checkbox />
              </Field> */}
              <Field name="usePowershell" showLabel={false} label="Help me configure my provider via Powershell">
                <Checkbox />
              </Field>
            </>
          )}
          <Field name="properties.enable_firewall_log_collection" showLabel={false} large>
            <Checkbox />
          </Field>
        </>
      );
    }

    return null;
  }

  renderObservabilityFeatureOptions() {
    const { $cloudExportWizard } = this.props;
    const { selectedCloudProvider, isSelectedCloudProviderOci } = $cloudExportWizard;

    if (!selectedCloudProvider || selectedCloudProvider === '') {
      return null;
    }

    const csp = `${CLOUD_PROVIDERS[selectedCloudProvider.toUpperCase()].code} Cloud Export`;
    const additionalFeatureCopy = `Please select additional observability features you want to enable for your ${csp}:`;

    return (
      <>
        <Text textAlign="left" fontSize={12} mb={2} muted>
          {additionalFeatureCopy}
        </Text>
        <Box>
          {this.renderMetadataCheckbox()}

          <Field name="properties.enable_flow_log_collection" showLabel={false} large>
            <Checkbox />
          </Field>

          {this.renderCloudSpecificOptions()}

          {!isSelectedCloudProviderOci && (
            <Field name="properties.enable_metrics_collection" showLabel={false} large>
              <Checkbox />
            </Field>
          )}
        </Box>
      </>
    );
  }

  renderObservabilityFeatures = () => {
    const { allowNoMetadata } = this.state;
    const metadataCopy = 'Metadata collection (required).';
    return (
      <>
        <Heading level={5} py={1} mt={4}>
          Observability Features
        </Heading>
        <Text textAlign="left" fontSize={12} mb={2} muted>
          {allowNoMetadata ? '' : metadataCopy}
        </Text>
        <FlexColumn alignItems="flex-start">{this.renderObservabilityFeatureOptions()}</FlexColumn>
      </>
    );
  };

  handleTileSelection = (tile) => {
    const { $cloudExportWizard } = this.props;
    $cloudExportWizard.resetStore();
    $cloudExportWizard.form.setValue('cloud_provider', tile.provider);
  };

  renderProviderTiles = () => {
    const tiles = [awsTile, gcpTile, azureTile, ociTile];
    const { form } = this.props;
    const selectedCloudProvider = form.getValue('cloud_provider');

    return (
      <Grid gridTemplateColumns="repeat(auto-fit, minmax(110px, 1fr))" gridGap="32px" alignItems="center">
        {tiles.map((tile) => {
          const selectedIcon = tile.provider === selectedCloudProvider ? IoIosCheckmarkCircle : IoIosRadioButtonOff;
          const iconFill = tile.provider === selectedCloudProvider ? '#157ff3' : '';
          return (
            <Flex flexDirection="column" alignItems="center" key={`${tile.provider}-tile`}>
              <Button mr={1} onClick={() => this.handleTileSelection(tile)} borderRadius={15} width="100%">
                <Flex alignItems="center" gap={1} justifyContent="space-between">
                  <Icon icon={tile.logo} iconSize={30} />
                  <Text
                    as="div"
                    flex="1"
                    textAlign="left"
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize={15}
                    lineHeight="30px"
                  >
                    {tile.title}
                  </Text>
                  <Icon icon={selectedIcon} iconSize={20} fill={iconFill} />
                </Flex>

                <Text as="div" textAlign="left" fontWeight="300" fontSize={12} mt={2} mb={2} muted>
                  {tile.description}
                </Text>
                <Text as="div" textAlign="left" fontWeight="100" fontSize={12} mt={2} mb={2} muted>
                  {tile.recommendationText}
                </Text>
              </Button>
            </Flex>
          );
        })}
      </Grid>
    );
  };

  render() {
    return (
      <>
        {this.renderProviderTiles()}
        {this.renderObservabilityFeatures()}
      </>
    );
  }
}

export default ProvidersAndFeatures;
