const DEFAULT_SNMP_FAST_INTERVAL = '1m';
const DEFAULT_SNMP_SLOW_INTERVAL = '5m';
const DEFAULT_GNMI_INTERVAL = '30s';

const MINIMUM_SNMP_INTERVAL = '30s';
const MINIMUM_GNMI_INTERVAL = '1s';
const MINIMUM_METADATA_INTERVAL = '1m';

const DefaultMonitoringTemplateIds = Object.freeze({
  Everything: 'default-monitoring-template-everything',
  Minimal: 'default-monitoring-template-minimal'
});

const DefaultMonitoringTemplatePresets = Object.freeze({
  Everything: 2,
  Minimal: 1
});

const DefaultMonitoringTemplateMinimal = Object.freeze({
  id: DefaultMonitoringTemplateIds.Minimal,
  preset: DefaultMonitoringTemplatePresets.Minimal,
  name: 'Minimal',
  description: 'Only interfaces that are operationally up',
  settings: {
    intervals: {
      fastMetrics: '1m',
      slowMetrics: '5m',
      metadata: '4h'
    }
  },
  interfaceGroup: {
    filters: [{ admin_status: ['up'], interface_type: 'all' }]
  }
});
const DefaultMonitoringTemplateEverything = Object.freeze({
  id: DefaultMonitoringTemplateIds.Everything,
  preset: DefaultMonitoringTemplatePresets.Everything,
  name: 'Everything',
  description: 'Everything on this device',
  settings: {
    intervals: {
      fastMetrics: '1m',
      slowMetrics: '5m',
      metadata: '4h'
    }
  }
});

const DefaultMonitoringTemplates = Object.freeze({
  [DefaultMonitoringTemplateIds.Everything]: DefaultMonitoringTemplateEverything,
  [DefaultMonitoringTemplateIds.Minimal]: DefaultMonitoringTemplateMinimal
});

/** Measurements that use the fast interval setting */
const fastMeasurements = ['/interfaces/counters', '/system', '/system/cpus', '/system/memory'];

const durationUnits = {
  s: 1,
  m: 60,
  h: 3600
};

function parseDuration(duration) {
  const { number, unit } = duration.match(/(?<number>\d*(\.\d+)?)(?<unit>[smh])/)?.groups || {};

  if (number && unit in durationUnits) {
    return Number.parseFloat(number) * durationUnits[unit];
  }

  return Number.NaN;
}

module.exports = {
  DEFAULT_MONITORING_TEMPLATE_PRESET: DefaultMonitoringTemplatePresets.Everything,
  DEFAULT_SNMP_FAST_INTERVAL,
  DEFAULT_SNMP_SLOW_INTERVAL,
  DEFAULT_GNMI_INTERVAL,
  MINIMUM_SNMP_INTERVAL,
  MINIMUM_GNMI_INTERVAL,
  MINIMUM_METADATA_INTERVAL,
  DefaultMonitoringTemplates,
  DefaultMonitoringTemplateIds,
  fastMeasurements,
  parseDuration
};
