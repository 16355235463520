import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FiCopy } from 'react-icons/fi';
import { Box, Button, ConfigSnippet, CalloutOutline, Flex, FlexColumn, Tag, Text, Link } from 'core/components';
import { FormComponent, formConsumer, Field, RadioGroup, Switch, Select, InputGroup } from 'core/form';
import { ReactComponent as TerraformLogo } from 'app/assets/onboarding/terraform-logo.svg';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';
import { trimTransform } from 'app/stores/clouds/cloudExport/steps/utils';
import { FormGrid, FormStep, planRenderer } from '../FormTools';

// commented out fields are not in origianl AWS Terraform
// but should they be?
const fields = {
  name: {
    transform: {
      out: trimTransform
    }
  },
  'properties.description': {
    defaultValue: ''
  },
  plan_id: {
    label: 'Billing Plan',
    placeholder: 'Select a Plan',
    rules: 'required',
    messages: {
      required: 'The "Plan" field is required'
    }
  },
  enabled: {
    defaultValue: true,
    label: 'Enabled'
  },
  cloud_provider: {
    label: 'Cloud Provider',
    rules: 'required',
    defaultValue: 'aws'
  },
  profile: {
    defaultValue: 'default'
  },
  region: {
    label: 'S3 Bucket Region',
    defaultValue: undefined,
    rules: 'required'
  },
  // 'properties.secondary_aws_suffix': {
  //   defaultValue: '',
  //   label: 'Role Suffix'
  // },
  // 'properties.secondary_aws_accounts': {
  //   defaultValue: [],
  //   label: 'Secondary AWS Accounts',
  //   placeholder: 'Enter comma separated list of aws accounts.'
  // },
  // 'properties.secondary_aws_regions': {
  //   defaultValue: [],
  //   label: 'Topology Regions',
  //   placeholder: 'Select regions.',
  //   options: getFilteredOptionsByCloudProvider('aws')
  // },
  // 'properties.aws_transfluo_region': {
  //   defaultValue: null
  // },
  'properties.aws_bucket': {
    label: 'S3 Bucket Name',
    messages: {
      regex: 'AWS bucket names cannot contain uppercase letters, underscores, nor whitespaces.'
    },
    transform: {
      out: trimTransform
    }
  },
  // 'properties.aws_bucket_prefix': {
  //   label: 'S3 Bucket Prefix',
  //   placeholder: 'FlowLogs',
  //   transform: {
  //     out: trimTransform
  //   }
  // },
  'properties.aws_iam_role_arn': {
    label: 'AWS Role',
    transform: {
      out: trimTransform
    }
  },
  'properties.aws_iam_role_arn_is_org': {
    defaultValue: false
  },
  'properties.aws_delete_after_read': {
    defaultValue: false,
    label: 'Delete after read'
  },
  // 'properties.aws_metadata_only': {
  //   defaultValue: false
  // },
  // 'properties.kentik_managed_s3_region': {},
  'properties.api_root': {},
  'properties.flow_dest': {},
  'properties.task_status': {
    defaultValue: 'PENDING'
  },
  'properties.error_message': {},

  'properties.apply_bgp': {
    defaultValue: false
  },
  'properties.device_bgp_type': {
    defaultValue: 'other_device'
  },

  'properties.use_bgp_device_id': {
    label: 'Master BGP device',
    placeholder: 'Select a Peered Device...',
    defaultValue: null,
    rules: [{ required_if: ['properties.apply_bgp', true] }]
  },
  'properties.aws_deployment_methodology': {
    defaultValue: 'noAgent',
    options: [
      {
        value: 'infrastructureAgents',
        label: 'Run agents in my infrastructure',
        recommended: true,
        description:
          'The Kentik agent provides the best experience at the lowest cost. Our lightweight agents produce rich telemetry data, monitor health and system metrics, discover paths, and check the availability and network performance of your environment'
      },

      {
        value: 'collectionAgent',
        label: 'Run a collection agent in my environments',
        description:
          'The Kentik collection agent runs inside of your environment to easily integrate with cloud monitoring tools to export and ingest cloud flow logs and metrics. This option is best for organizations that cannot create delegated roles in their public cloud environments.'
      },

      {
        value: 'noAgent',
        label: 'No agent for me, thanks',
        description:
          'No agents, no problem. Kentik can easily integrate with your favorite cloud monitoring tools in AWS, Azure, and GCP Cloud to export and ingest Cloud Flow Logs and metrics.'
      }
    ]
  },
  enable_flow_logs: {
    label: 'Enable flow logs',
    defaultValue: 'all',
    options: [
      { value: 'all', label: 'For all VPCs in the selected region(s)' },
      { value: 'some', label: 'For selected VPCs in the selected region(s)' }
    ]
  },
  write_logs: {
    label: 'Write logs to bucket',
    defaultValue: false,
    options: [
      { value: true, label: 'Every minute (recommended)' },
      { value: false, label: 'Every 10 minutes (AWS default)' }
    ]
  },
  create_role: {
    label: 'Automatically create necessary role in AWS account',
    defaultValue: true
  },
  s3_bucket_prefix: {
    label: 'S3 Bucket Prefix'
  },
  iam_role_prefix: {
    label: 'IAM Role Prefix'
  },
  use_external_id: {
    label: 'Use External ID',
    defaultValue: true
  }
};

@withRouter
@formConsumer
@inject('$cloudExportWizard', '$auth', '$users')
export default class AwsAutomatedSetup extends Component {
  componentDidMount() {
    const { $users } = this.props;
    $users.fetchUserProfile();
  }

  get defaultPlan() {
    const { $cloudExportWizard } = this.props;
    const planOptions = $cloudExportWizard.getCloudPlanOptions();
    const planAutoSelection = planOptions.find((option) => option.device_count < option.max_devices);

    return planAutoSelection && planAutoSelection.value;
  }

  terraformConfigSource({ create_role, use_external_id }) {
    const { $auth, form: propForm } = this.props;
    const delete_after_read = propForm.getValue('properties.aws_delete_after_read');

    return `terraform {
  required_version = ">= 1.2.0, <= 1.5.7"
  required_providers {
    aws = {
      version = "~> 5.16.2"
    }
    kentik-cloudexport = {
      version = ">= 0.4.0"
      source = "kentik/kentik-cloudexport"
    }
  }
}

provider "aws" {
  region  = "{{region}}"
  profile = "{{profile}}"
}
provider "kentik-cloudexport" {
  apiurl = "{{apiurl}}"
  email = "{{email}}"
  token = "{{token}}"
}
{{vpcs}}

module "kentik_aws_integration" {
  source = "github.com/kentik/config-snippets-cloud/cloud_AWS/terraform/module"
  region  = "{{region}}"
  delete_after_read = ${delete_after_read}
  rw_s3_access = true
  vpc_id_list  = {{vpc_id_list}}
  store_logs_more_frequently = {{write_logs}}
  create_role = {{create_role}}
  ${create_role && use_external_id ? `external_id = ${$auth.activeUser.company_id}` : ''}
  s3_bucket_prefix = "{{s3_bucket_prefix}}"
  iam_role_prefix = "{{iam_role_prefix}}"
  plan_id = {{plan_id}}
  name = "{{name}}"
}`;
  }

  renderScript(source, data) {
    return (
      <ConfigSnippet source={source} data={data} themeName="steel" p={2}>
        {({ snippet, rawContent }) => (
          <Box position="relative" width={754}>
            <CopyToClipboardButton text={rawContent} intent="none">
              <Button icon={FiCopy} title="Copy to Clipboard" position="absolute" top="10px" right="16px" />
            </CopyToClipboardButton>
            {snippet}
          </Box>
        )}
      </ConfigSnippet>
    );
  }

  renderCallout = () => (
    <>
      <CalloutOutline maxWidth={850} p={2} mb={2} border="none">
        <TerraformLogo style={{ width: 120, height: 30, marginBottom: 4 }} />
        <Text as="div">
          We can automatically generate Terraform configurations for your AWS account. Our example configuration can get
          you up and running in minutes. The generated config:
        </Text>
        <ul>
          <li>
            Creates a new cross-account role (&quot;<em>TerraformingIngestRole</em>&quot;, optionally with a prefix you
            define below) within your account, configured with a trust relationship to Kentik&apos;s AWS account
            (834693425129)
          </li>
          <li>
            Assigns{' '}
            <Link blank to="https://kb.kentik.com/v0/Bd06.htm#Bd06-Configure_the_AWS_Role">
              custom permissions
            </Link>{' '}
            to the new role which enables our platform to read data from your account
          </li>
          <li>
            Configures flow log exports for every VPC in the selected region (this can be configured as a list of VPCs-
            see our{' '}
            <Link blank to="https://github.com/kentik/config-snippets-cloud">
              cloud-snippets repository
            </Link>{' '}
            for more information.)
          </li>
          <li>Automatically creates a Kentik Cloud Export, optionally with a prefix you define below.</li>
        </ul>
      </CalloutOutline>
    </>
  );

  renderDefaultFields = (terraformForm) => {
    const { $cloudExportWizard } = this.props;
    const planOptions = $cloudExportWizard.getCloudPlanOptions();
    const regionOptions = $cloudExportWizard.getFilteredOptionsByCloudProvider();

    if (planOptions && !terraformForm.getValue('plan_id')) {
      terraformForm.setValue('plan_id', this.defaultPlan);
    }

    return (
      <>
        <FormStep stepNumber={1} title="AWS Provider Profile Name">
          <Field large name="profile" rules="required" placeholder="default" maxWidth={360}>
            <InputGroup />
          </Field>
        </FormStep>
        <FormStep stepNumber={2} title="Select your region(s)">
          <Field name="region" rules="required" showLabel={false} options={regionOptions} mb={0} large>
            <Select width={400} menuWidth={400} />
          </Field>
        </FormStep>
        <FormStep stepNumber={3} title="Select options">
          <Flex mb={2}>
            <Field name="enable_flow_logs" mb={0} mr={4} large>
              <RadioGroup />
            </Field>
            <Field name="write_logs" m={0} large>
              <RadioGroup />
            </Field>
          </Flex>
          <Field name="create_role" large showLabel={false}>
            <Switch showFieldLabel />
          </Field>
          <Field name="use_external_id" large showLabel={false} disabled={!terraformForm.getValue('create_role')}>
            <Switch showFieldLabel />
          </Field>
          <Flex flexDirection="column">
            <Box mt={1}>
              <Field
                large
                name="name"
                label="Cloud Export Name Prefix"
                helpText="This prefix will be prepended to the Kentik Cloud Export created by terraform"
                placeholder="my-cloud-export"
              >
                <InputGroup />
              </Field>
              <Field
                large
                name="s3_bucket_prefix"
                rules="required"
                helpText="This prefix will be prepended to the Kentik-created S3 bucket created by terraform"
                placeholder="my-kentik-bucket"
              >
                <InputGroup />
              </Field>
              <Field
                large
                name="iam_role_prefix"
                helpText="This prefix will be prepended to the Kentik-created role created by terraform. E.g. [prefix]-TerraformingIngestRole"
                placeholder="my-kentik-prefix"
              >
                <InputGroup />
              </Field>
              {planOptions && planOptions.length !== 1 && (
                <Field
                  name="plan_id"
                  disabled={!planOptions.length}
                  helpText={
                    planOptions.length
                      ? undefined
                      : 'There are no available device slots in your plans. Please contact sales@kentik.com to add more devices.'
                  }
                  options={planOptions}
                  large
                >
                  <Select width={400} menuWidth={400} optionFormatter={planRenderer} valueRenderer={planRenderer} />
                </Field>
              )}
            </Box>
          </Flex>
        </FormStep>
      </>
    );
  };

  renderTerraformConfig = (terraformForm) => {
    const { $auth, $users } = this.props;
    const formValues = terraformForm.getValues();
    const configSrc = this.terraformConfigSource(formValues);

    // this is the aws cli profile specified
    const profile = terraformForm.getValue('profile') ? terraformForm.getValue('profile') : '';
    const region = terraformForm.getValue('region') ?? '';
    const vpcs = terraformForm.getValue('enable_flow_logs') === 'all' ? 'data "aws_vpcs" "all-vpc" {}' : '';
    const vpc_id_list =
      terraformForm.getValue('enable_flow_logs') === 'all' ? 'data.aws_vpcs.all-vpc.ids' : '[Your VPC IDs Here]';
    const email = $auth.getActiveUserProperty('user_email');
    const token = $users?.fullUserProfile?.user_api_key;

    const { baseUrl } = $auth.openConfig;
    const apiurl = baseUrl.replace('portal', 'grpc.api');

    const scriptData = {
      ...formValues,
      profile,
      region,
      vpcs,
      vpc_id_list,
      email,
      token,
      apiurl
    };

    return (
      <>
        <FormStep stepNumber={3} title="Generate Terraform Configuration">
          <Text as="p" muted>
            Copy the generated Terraform configuration and save to a file named{' '}
            <Tag minimal fontWeight="medium">
              main.tf
            </Tag>{' '}
            in an empty directory
          </Text>
          {this.renderScript(configSrc, scriptData)}
        </FormStep>
      </>
    );
  };

  renderReviewStep = () => (
    <>
      <FormStep stepNumber={4} title="Review and Apply Terraform Configuration">
        <Text as="p" muted>
          After you&apos;ve reviewed the configuration in the main.tf file, apply it in the environment where you run
          Terraform.
        </Text>
        <Box mb={2}>{this.renderScript('terraform init')}</Box>
        <Box mb={2}>{this.renderScript('terraform validate')}</Box>
        <Box mb={2}>{this.renderScript('terraform plan')}</Box>
        <Box mb={2}>{this.renderScript('terraform apply')}</Box>
      </FormStep>
    </>
  );

  handleFinishAutomatedSetup = () => {
    const { history } = this.props;

    history.push('/v4/settings/clouds/');
  };

  renderFinishButton = () => (
    <Flex justifyContent="flex-start" pt={2}>
      <Button intent="primary" onClick={this.handleFinishAutomatedSetup} minWidth={110} large>
        Finish
      </Button>
    </Flex>
  );

  renderTerraformFields = (terraformForm) => (
    <Box>
      {this.renderCallout()}
      <FlexColumn pl={1} mt={2} mb={2} />

      <FormGrid>
        {this.renderDefaultFields(terraformForm)}
        {this.renderTerraformConfig(terraformForm)}
        {this.renderReviewStep()}
      </FormGrid>
      {this.renderFinishButton()}
    </Box>
  );

  renderLeftColumn() {
    return (
      <Flex flexDirection="column" gap="1" flex={1} mb={2}>
        <Flex flexDirection="column">
          <FormComponent fields={fields} options={{ name: 'Automated AWS Setup' }}>
            {({ form: terraformForm }) => this.renderTerraformFields(terraformForm)}
          </FormComponent>
        </Flex>
      </Flex>
    );
  }

  renderRightColumn() {
    return (
      <Flex flex={1} flexDirection="column">
        <Flex height={750} flexWrap="wrap" justifyContent="flex-end" />
      </Flex>
    );
  }

  render() {
    return (
      <Flex gap={1}>
        {this.renderLeftColumn()}
        {this.renderRightColumn()}
      </Flex>
    );
  }
}
