import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Text } from 'core/components';
import { Field, FormDialog, InputGroup } from 'core/form';
import alarmSuppressionFields from 'app/forms/config/synthetics/fields/alarmSuppressionFields';
import AgentConfigForm from './AgentConfigForm';

const { startTime, expirationTime, neverExpire } = alarmSuppressionFields;

const fields = {
  agent_alias: {
    label: 'Name',
    rules: 'required',
    placeholder: 'Agent Alias',
    defaultValue: 'name'
  },
  agent_status: {
    label: 'Status',
    defaultValue: 'WAIT',
    options: [
      { value: 'WAIT', label: 'WAIT' },
      { value: 'OK', label: 'OK' }
    ]
  },
  site_id: {
    label: 'Site',
    rules: 'required',
    defaultValue: 0
  },

  'site.title': {
    label: 'Name'
  },
  'site.address': {
    label: 'Street Address',
    helpText: 'We will geocode this address and provide additional Geographic dimensions that involve this'
  },
  'site.country': {
    label: 'Country',
    defaultValue: ''
  },
  'site.city': {
    label: 'City'
  },
  'site.region': {},
  'site.postal': {},
  'site.lat': {},
  'site.lon': {},
  'site.metadata': {
    defaultValue: {}
  },
  'metadata.broadband': {
    label: 'Broadband Agent',
    defaultValue: false
  },
  'metadata.cloud_provider': {
    label: 'Cloud Provider'
  },
  'metadata.cloud_region': {
    label: 'Cloud Region'
  },
  'metadata.cloud_vpc': {
    label: 'Cloud Virtual Network'
  },
  'metadata.private_ipv4_addresses': {
    isComplexArray: true
  },
  'metadata.private_ipv4_addresses[].value': {
    label: 'Private IPv4',
    rules: 'ipv4|ipNoCidr'
  },
  'metadata.private_ipv6_addresses': {
    isComplexArray: true
  },
  'metadata.private_ipv6_addresses[].value': {
    label: 'Private IPv6',
    rules: 'ipv6|ipNoCidr'
  },
  'metadata.public_ipv4_addresses': {
    isComplexArray: true
  },
  'metadata.public_ipv4_addresses[].value': {
    label: 'Public IPv4',
    rules: 'ipv4|ipNoCidr'
  },
  'metadata.public_ipv6_addresses': {
    isComplexArray: true
  },
  'metadata.public_ipv6_addresses[].value': {
    label: 'Public IPv6',
    rules: 'ipv6|ipNoCidr'
  },
  os: {
    label: 'Operating System',
    placeholder: 'Agent OS'
  },
  lat: {
    label: 'Latitude',
    rules: 'required|numeric|between:-90,90',
    placeholder: 'Agent Latitude',
    defaultValue: 0
  },
  long: {
    label: 'Longitude',
    rules: 'required|numeric|between:-180,180',
    placeholder: 'Agent Longitude',
    defaultValue: 0
  },
  challenge: {
    rules: 'size:64'
  },
  country: {
    label: 'Country',
    defaultValue: ''
  },
  region: {},
  city: {
    label: 'City'
  },
  agent_labels: {
    label: 'Labels',
    defaultValue: [],
    placeholder: 'Apply labels to this agent...'
  },
  agent_family: {
    defaultValue: 'DUAL',
    label: 'IP Versions Supported',
    options: [
      {
        value: 'DUAL',
        label: 'v4 + v6'
      },
      {
        value: 'v4',
        label: 'v4 only'
      },
      {
        value: 'v6',
        label: 'v6 only'
      }
    ]
  },
  agent_alert_rule_status: {
    label: 'Status Alerting and Notifications',
    default: true,
    helpText: 'Get alerted when this agent experiences issues. Kentik checks agent status every 30 seconds.'
  },
  'agent_alert_rule_config.alertingRuleId': {
    default: ''
  },
  'agent_alert_rule_config.thresholds[0].thresholdSeconds': {
    default: 5,
    rules: 'AreAgentAlertThresholdsLongEnough|AreAgentAlertThresholdsIntegers'
  },
  'agent_alert_rule_config.thresholds[0].thresholdType': {
    default: 'seconds',
    options: [
      {
        value: 'minutes',
        label: 'minutes'
      },
      {
        value: 'seconds',
        label: 'seconds'
      }
    ]
  },
  notificationChannels: {
    defaultValue: []
  },
  'agent_alert_rule_config.suppression.enabled': {
    label: 'Maintenance Mode',
    defaultValue: false,
    helpText:
      'Pause data collections and alerts for tests using this agent. Maintenance mode will pause agent status alerts.'
  },
  'agent_alert_rule_config.suppression.suppressionId': {
    defaultValue: ''
  },
  'agent_alert_rule_config.suppression.startTime': startTime,
  'agent_alert_rule_config.suppression.endTime': expirationTime,
  'agent_alert_rule_config.suppression.neverExpire': neverExpire,
  'agent_alert_rule_config.suppression.legacySuppressionIds': {
    defaultValue: []
  }
};
const options = {
  name: 'Synthetic Agent Config'
};

@inject('$labels', '$syn', '$sites', '$notifications')
@observer
export default class AgentConfigFormDialog extends Component {
  state = { challengeModel: null };

  constructor(props) {
    super(props);

    const { model } = props;

    if (model) {
      model.setNotificationChannels();
    }
  }

  /*
    To handle the case where someone has enabled a maintenance window and entered a valid start time
    but then leaves the dialog open to the point where the start time 'expires' and becomes invalid,
    we run the form validation resulting in a simple resolve/reject action to start the save chain.
    If the validation promise is rejected, the dialog will remain open and render the appropriate error messaging
  */
  handleSave = (form, values) => {
    const validationPromiseAction = form.validate() ? 'resolve' : 'reject';

    return Promise[validationPromiseAction]().then(() => {
      const { $labels, $sites, $notifications, onSave } = this.props;
      const { model } = form;

      // Anything saved will be "activated" in the process
      values.agent_status = 'OK';

      return model.save(values, { sudoMode: !!form.sudoMode }).then(() => {
        $labels.labels.fetch({ force: true });
        $sites.collection.fetch({ force: true });
        $notifications.collection.fetch({ force: true });
        if (onSave) {
          onSave();
        }
      });
    });
  };

  handleChallenge = (form, { challenge }) => {
    const { $syn, agentMetadata, onChallenge } = this.props;
    return $syn.agents.findAgentByChallenge(challenge, agentMetadata).then((challengeModel) => {
      this.setState({ challengeModel });

      if (onChallenge) {
        onChallenge(challengeModel);
      }

      return false;
    });
  };

  render() {
    const { onClose, challenge, model, onSave, ...rest } = this.props;
    const { challengeModel } = this.state;

    const formModel = challengeModel || model;
    const challengeMode = challenge && !formModel;

    let title = 'Register Agent';
    let submitButtonText = 'Register';
    let closeAfterSave = false;

    if (formModel) {
      closeAfterSave = true;
      title = 'Configure Agent';
      submitButtonText = 'Save';

      if (formModel.get('agent_status') === 'WAIT') {
        title = 'Activate Agent';
        submitButtonText = 'Activate';
      }
    }

    return (
      <FormDialog
        {...rest}
        model={challengeModel || model}
        fields={fields}
        options={options}
        title={title}
        entityName="Agent"
        isOpen
        onClose={onClose}
        formActionsProps={{
          onSubmit: challengeMode ? this.handleChallenge : this.handleSave,
          onCancel: onClose,
          submitButtonText,
          closeAfterSave
        }}
        width={500}
      >
        {challengeMode && (
          <>
            <Text small muted as="div" mb={1}>
              Enter the challenge code shown by your agent:
            </Text>
            <Field name="challenge" fill large autoFocus>
              <InputGroup />
            </Field>
          </>
        )}
        {!challengeMode && <AgentConfigForm />}
      </FormDialog>
    );
  }
}
