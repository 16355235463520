import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Card } from 'core/components';
import VerticalDetailTabs from 'app/components/detailTabs/VerticalDetailTabs';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import Page from 'app/components/page/Page';
import InsightsMenu from 'app/components/navbar/InsightsMenu';
import AggregateViewHeader from '../aggregate/AggregateViewHeader';
import parentLinks from '../NetworkExplorerParentLink';

@inject('$tabs')
@observer
class CityDetails extends Component {
  constructor(props) {
    super(props);

    const { $tabs } = props;
    const tabs = $tabs.getTabs();

    this.state.tabBlacklist = [tabs.CITIES.id];
  }

  state = {
    filters: [],
    lookback_seconds: 86400,
    aggregateType: 'p95th_bits_per_sec'
  };

  get city() {
    const { match } = this.props;
    return decodeURIComponent(match.params.city);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  get country() {
    const { match } = this.props;
    return match.params.country;
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  get region() {
    const { match } = this.props;
    return decodeURIComponent(match.params.region);
  }

  get filters() {
    const { filters } = this.state;
    const detailFilter = {
      filterField: 'city',
      filterValue: this.city
    };

    return filters.concat(detailFilter);
  }

  handleFiltersApply = (filters) => {
    this.setState({ filters });
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds });
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType });
  };

  render() {
    const { aggregateType, filters, lookback_seconds, tabBlacklist } = this.state;

    return (
      <Page
        title={this.city}
        parentLinks={[...parentLinks, { link: '/v4/core/quick-views/city', label: 'Cities' }]}
        insightsMenu={<InsightsMenu query="fetchCityInsights" params={this.city} />}
        scrolls
        showExport
      >
        <AggregateViewHeader
          title={this.city}
          aggregateType={aggregateType}
          filters={filters}
          lookbackSeconds={lookback_seconds}
          onFiltersChange={this.handleFiltersApply}
          onAggregateChange={this.handleAggregationChange}
          onMetricsChange={this.handleAggregationChange}
          onTimeRangeChange={this.handleLookbackChange}
        />

        <Card className="break-after" mb={2}>
          <TabbedChart overrides={{ lookback_seconds, aggregateTypes: [aggregateType] }} simpleFilters={this.filters} />
        </Card>

        <VerticalDetailTabs
          blacklist={tabBlacklist}
          simpleFilters={this.filters}
          queryOverrides={{ lookback_seconds, aggregateTypes: [aggregateType] }}
        />
      </Page>
    );
  }
}

export default CityDetails;
