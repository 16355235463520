import React, { Component } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { PopoverInteractionKind, Position } from '@blueprintjs/core';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import AlertingSiteHealthWrapper from 'app/views/alerting/AlertingSiteHealthWrapper';
import AlertStatsCollection from 'app/stores/alerting/AlertStatsCollection';
import { Text, Spinner, Heading, Box, Flex, Button, Link, Popover, Icon, Tag } from 'core/components';
import { Field, FormComponent, Select, InputGroup } from 'core/form';
import withConfigOptions from '../withConfigOptions';

const defaultWidgetTitle = 'Health Map by Sites';

const INTERVAL_OPTIONS = [
  { label: '1 minute', value: 60000 },
  { label: '3 minutes', value: 60000 * 3 },
  { label: '5 minutes', value: 60000 * 5 },
  { label: '10 minutes', value: 60000 * 10 },
  { label: '15 minutes', value: 60000 * 15 }
];

const LOOKBACK_OPTIONS = [
  { value: 0, label: 'Active Now' },
  { value: 3600, label: 'Last hour' },
  { value: 28800, label: 'Last 8 hours' },
  { value: 86400, label: 'Last 24 hours' },
  { value: 604800, label: 'Last 7 days' },
  { value: 1209600, label: 'Last 14 days' },
  { value: 2592000, label: 'Last 30 Days' }
];

const GROUP_OPTIONS = [
  { label: 'Severity', value: 'severity' },
  { label: 'None', value: 'none' }
];

const SORT_OPTIONS = [
  { label: 'Alert Count', value: 'totalCount' },
  { label: 'Site', value: 'title' },
  { label: 'None', value: 'none' }
];

@withConfigOptions
@observer
class AlertingSiteHealthWidget extends Component {
  state = { collection: new AlertStatsCollection() };

  componentDidMount() {
    const { collection } = this.state;
    const { config } = this.props;

    if (config) {
      this.fetchStats(true);
      this.fetchInterval = setInterval(this.fetchStats, config.interval || 60000);
      collection.sort(this.config.sortBy, this.config.sortBy === 'totalCount' ? 'desc' : 'asc');
    }
  }

  componentWillUnmount() {
    clearInterval(this.fetchInterval);
  }

  get config() {
    const { config } = this.props;
    const { lookback = 0, interval = 60000, groupBy = 'severity', sortBy = 'totalCount' } = config;
    return { lookback, interval, groupBy, sortBy };
  }

  fetchStats = (ignoreState, passedLookback) => {
    const { isConfigurePanelOpen } = this.props;
    const { collection } = this.state;
    const data = {};

    const lookback = Number.isFinite(passedLookback) ? passedLookback : this.config?.lookback;

    if (!ignoreState && (document.hidden || isConfigurePanelOpen)) {
      return;
    }

    const endTime = moment.utc().toISOString();
    const startTime = moment.utc(endTime).subtract(lookback, 'seconds').toISOString();

    // For now we support ranges where endTime is now; no historical end date
    if (lookback) {
      data.startTime = startTime;
      data.endTime = endTime;
    }

    collection.fetch({ data, force: true });
  };

  handleSave(form) {
    const { collection } = this.state;
    const { handleSaveConfiguration } = this.props;
    const interval = form.getValue('interval');
    const lookback = form.getValue('lookback');
    const sortBy = form.getValue('sortBy');
    clearInterval(this.fetchInterval);
    this.fetchInterval = setInterval(this.fetchStats, interval);
    this.fetchStats(true, lookback);
    handleSaveConfiguration(form);
    collection.sort(sortBy, sortBy === 'totalCount' ? 'desc' : 'asc');
  }

  renderConfigurePanel() {
    const { handleCancelConfiguration, model } = this.props;
    const { lookback, interval, groupBy, sortBy } = this.config;

    const fields = {
      title: {
        label: 'Title',
        rules: 'required',
        defaultValue: defaultWidgetTitle
      },
      interval: {
        label: 'Update Every',
        rules: 'required',
        defaultValue: interval,
        options: INTERVAL_OPTIONS
      },
      lookback: {
        label: 'Time Range',
        rules: 'required',
        defaultValue: lookback,
        options: LOOKBACK_OPTIONS
      },
      groupBy: {
        label: 'Group by',
        defaultValue: groupBy,
        options: GROUP_OPTIONS
      },
      sortBy: {
        label: 'Sort by',
        defaultValue: sortBy,
        options: SORT_OPTIONS
      }
    };

    return (
      <FormComponent fields={fields} model={model} options={{ name: 'Configure Health Site Map', large: true }}>
        {({ form }) => (
          <Flex flexDirection="column" justifyContent="space-between" p={2} height="100%">
            <Box>
              <Field name="title" large>
                <InputGroup />
              </Field>
              <Flex gridColumnGap={2} flexWrap="wrap">
                <Field name="lookback" large>
                  <Select />
                </Field>
                <Field name="interval" large>
                  <Select />
                </Field>
                <Field name="groupBy" large>
                  <Select />
                </Field>
                <Field name="sortBy" large>
                  <Select />
                </Field>
              </Flex>
            </Box>
            <Box mt={2}>
              <Flex justifyContent="flex-end" mb={1}>
                <Popover
                  position={Position.TOP}
                  interactionKind={PopoverInteractionKind.HOVER}
                  minimal={false}
                  content={
                    <Box p={2}>
                      <Text as="div">
                        <b>Requirements</b>
                      </Text>
                      <Text runningText>
                        <ul>
                          <li>
                            <Link to="/v4/settings/devices" blank>
                              Devices
                            </Link>{' '}
                            must be assigned to a{' '}
                            <Link to="/v4/settings/sites" blank>
                              Site
                            </Link>{' '}
                            with a valid address.
                          </li>
                          <li>Add a Site or Device dimension to your alerting policy.</li>
                        </ul>
                      </Text>
                    </Box>
                  }
                >
                  <Box cursor="pointer">
                    <Text small minimal>
                      Why aren&rsquo;t my results displaying?
                    </Text>{' '}
                    <Icon icon="help" color="muted" />
                  </Box>
                </Popover>
              </Flex>
              <Flex gap={1}>
                <Button text="Cancel" onClick={() => handleCancelConfiguration(form)} fill large />
                <Button
                  text="Save"
                  intent="primary"
                  fontWeight="medium"
                  disabled={!form.valid}
                  onClick={() => this.handleSave(form)}
                  fill
                  large
                />
              </Flex>
            </Box>
          </Flex>
        )}
      </FormComponent>
    );
  }

  render() {
    const { onRemove, model, handleShowConfigurePanel, isConfigurePanelOpen } = this.props;
    const { collection } = this.state;
    const { lookback, interval, groupBy } = this.config;

    return (
      <WidgetFrame
        canCustomize
        onRemove={onRemove}
        title={
          <Flex alignItems="center" fontWeight="normal" gap={1}>
            <Heading level={5} mb={0} fontSize={14}>
              {isConfigurePanelOpen ? 'Configure' : model.get('title', defaultWidgetTitle)}
            </Heading>
            <Tag minimal round small fontWeight="normal">
              {LOOKBACK_OPTIONS.find((option) => option.value === lookback)?.label}
            </Tag>
            {collection.loading ? (
              <>
                <Text fontSize={12} muted>
                  Updating&hellip;
                </Text>
                <Spinner size={12} ml={1} />
              </>
            ) : (
              <Tag minimal round small fontWeight="normal">
                Updates every {INTERVAL_OPTIONS.find((option) => option.value === interval)?.label}
              </Tag>
            )}
          </Flex>
        }
        configAction={handleShowConfigurePanel}
      >
        {isConfigurePanelOpen && this.renderConfigurePanel()}
        {!isConfigurePanelOpen && (
          <AlertingSiteHealthWrapper collection={collection} lookback={lookback} groupBy={groupBy} />
        )}
      </WidgetFrame>
    );
  }
}

export default AlertingSiteHealthWidget;
