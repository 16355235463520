import React, { Component } from 'react';
import { observer } from 'mobx-react';
import DimensionSelector from 'app/components/dimensions/DimensionSelector';
import { FormWrapper } from 'core/form/components/FormWrapper';
import { arrayNotEmpty } from 'core/form/validators/arrayNotEmpty';

@observer
class InternalStandaloneDimensionSelector extends Component {
  static defaultProps = {
    isOpen: false,
    multi: false
  };

  render() {
    const { isOpen, onClose, mustHaveDimensions } = this.props;

    return (
      isOpen && (
        <DimensionSelector
          {...this.props}
          fieldName="dimension"
          dialogOnly
          isEditing={isOpen}
          onEditComplete={onClose}
          mustHaveDimensions={mustHaveDimensions}
        />
      )
    );
  }
}

const StandaloneDimensionSelector = ({ values, mustHaveDimensions, ...props }) => {
  const fields = { dimension: { defaultValue: [] } };
  if (mustHaveDimensions) {
    // not strictly required as we block users from disabling mustHaveDimensions however it's a nice backup
    fields.dimension.rules = [arrayNotEmpty()];
  }
  return (
    <FormWrapper fields={fields} values={values}>
      <InternalStandaloneDimensionSelector mustHaveDimensions={mustHaveDimensions} {...props} />
    </FormWrapper>
  );
};

export default StandaloneDimensionSelector;
