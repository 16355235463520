import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import { Box, Button, Collapse, Flex, Text, Spinner, Suspense } from 'core/components';
import { VirtualizedTable } from 'core/components/table';
import Collection from 'core/model/Collection';
import { InputGroup } from 'core/form';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import withHybridTopoSettings from 'app/views/hybrid/maps/components/settingsToolbar/withHybridTopoSettings';
import { generateEntityMap } from './OciEntityExplorerMap';
import { EntityRow } from './EntityExplorerRenderers';

const {
  REGION,
  SUBNET,
  NAT_GATEWAY,
  SERVICE_GATEWAY,
  INTERNET_GATEWAY,
  VIRTUAL_CLOUD_NETWORK,
  LOCAL_PEERING_GATEWAY,
  DYNAMIC_ROUTING_GATEWAY
} = ENTITY_TYPES.get('oci');

@withHybridTopoSettings
@inject('$hybridMap')
@withRouter
@observer
class OciEntityExplorerWidget extends Component {
  static defaultProps = {
    entityRowHeight: 37,
    contentRef: React.createRef()
  };

  ENTITY_MAP = {};

  state = {
    loading: true,
    topology: null,
    selected: null,
    selectedId: null,
    searchValue: '',
    contentOverflow: 'auto'
  };

  async fetchTopology() {
    this.setState({ loading: true });

    const { $hybridMap } = this.props;

    $hybridMap.ociCloudMapCollection.fetch().then(() => {
      const topology = this.parseTopology($hybridMap.ociCloudMapCollection.topology);

      this.ENTITY_MAP = generateEntityMap({
        jumpToFn: this.jumpTo,
        entities: $hybridMap.ociCloudMapCollection.Entities
      });
      this.setState({ loading: false, topology });
    });
  }

  componentDidMount() {
    this.fetchTopology();
  }

  componentDidUpdate(prevProps, prevState) {
    const { topology, selected, selectedId, searchValue } = this.state;

    if (prevState.selected !== selected) {
      if (selected !== null) {
        // Wait for Collapse animation
        setTimeout(() => {
          prevProps.contentRef.current.scrollTo({
            top: selected * prevProps.entityRowHeight,
            left: 0,
            behavior: 'smooth'
          });
          if (selectedId) {
            topology[selected].list.get(selectedId).select();
            this.setState({ selectedId: null });
          }

          if (selected === 0) {
            this.hideScrollbars();
          }

          // hide the scrollbars when scrolling has ended
          prevProps.contentRef.current.addEventListener('scrollend', () => this.hideScrollbars(), { once: true });
        }, 200);
      } else {
        // Wait for Collapse animation, otherwise you get can scrollbars popping in/out
        setTimeout(() => {
          this.setState({ contentOverflow: 'auto' });
        }, 200);
      }
      if (prevState.selected !== null) {
        topology[prevState.selected].list.clearSelection();
        topology[prevState.selected].list.clearSort();
      }
    }

    if (prevState.searchValue !== searchValue) {
      topology.forEach((entity) => (entity.name !== 'instance' ? entity.list.filter(searchValue) : null));
    }
  }

  hideScrollbars = () => {
    this.setState({ contentOverflow: 'hidden' });
  };

  parseTopology(data) {
    const topology = [
      REGION,
      VIRTUAL_CLOUD_NETWORK,
      SUBNET,
      NAT_GATEWAY,
      SERVICE_GATEWAY,
      INTERNET_GATEWAY,
      LOCAL_PEERING_GATEWAY,
      DYNAMIC_ROUTING_GATEWAY
    ].flatMap((entityType) => {
      if (entityType === REGION) {
        return {
          name: REGION,
          list: new Collection(data.Hierarchy.Regions.map((reg) => ({ key: reg.Name, ...reg })))
        };
      }
      return {
        name: entityType,
        list: this.objToCollection(data.Entities[entityType] ?? {})
      };
    });

    return topology.filter((entity) => entity.list.size !== 0);
  }

  objToCollection(obj) {
    return new Collection(Object.keys(obj).map((key) => ({ key, ...obj[key] })));
  }

  // Toggles row selection to expand/collapse entity table
  toggleRow({ index }) {
    const { selected } = this.state;
    if (index === selected) {
      this.setState({ selected: null, selectedId: null });
    } else {
      this.setState({ selected: index, selectedId: null });
    }
  }

  // Jumps to the Entity id in the given section
  jumpTo = ({ sectionType, id }) => {
    const { topology } = this.state;
    const sectionIdx = topology.findIndex((entity) => entity.name === sectionType);
    this.setState({ selected: sectionIdx, selectedId: id });
  };

  renderData() {
    const { topology } = this.state;
    if (!topology) {
      return null;
    }

    return (
      <Flex flexDirection="column" position="relative" top="38px" zIndex={1}>
        {topology.map((entity, index) => this.invItemRenderer({ entity, index }))}
      </Flex>
    );
  }

  invItemRenderer({ entity, index }) {
    const { height, entityRowHeight } = this.props;
    const { selected, selectedId } = this.state;
    const { label, icon, renderer, tableProps, rowHeight = 48, expandHeight = 0 } = this.ENTITY_MAP[entity.name];

    return (
      <Box key={`${entity.name}-${entity.list.size}`}>
        <EntityRow onClick={() => this.toggleRow({ index })} height={entityRowHeight} selected={index === selected}>
          {icon}
          <Text px={1}>{label}</Text>
          <Box flex={1} />
          {renderer && renderer(entity)}
          {!renderer && (
            <Flex justifyContent="flex-end">
              <Text as="div" fontWeight="bold">
                {entity.list.size}
              </Text>
              {entity.list.hasFilter && entity.list.unfilteredSize !== entity.list.size && (
                <Text as="div" muted>
                  &nbsp;{`of ${entity.list.unfilteredSize}`}
                </Text>
              )}
            </Flex>
          )}
        </EntityRow>
        <Collapse isOpen={index === selected}>
          <Flex flexDirection="column" height={height - 115} borderBottom="thin">
            <VirtualizedTable
              collection={entity.list}
              rowHeight={({ model }) => (model && model.isSelected ? rowHeight + expandHeight : rowHeight)}
              scrollToIndex={selectedId ? entity.list.get(selectedId)?.index : undefined}
              scrollToAlignment="start"
              flexed
              {...tableProps}
            />
          </Flex>
        </Collapse>
      </Box>
    );
  }

  handleSearch = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  clearSearch() {
    this.setState({ searchValue: '' });
  }

  renderWidgetTitle() {
    const { title } = this.props;

    return (
      <Flex justifyContent="space-between" width="100%" alignItems="center">
        <div>{title}</div>
      </Flex>
    );
  }

  render() {
    const { canCustomize, onRemove, contentRef } = this.props;
    const { loading, selected, contentOverflow, searchValue } = this.state;

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        onRemove={onRemove}
        title={this.renderWidgetTitle()}
        ref={contentRef}
        overflow={contentOverflow}
      >
        <Suspense
          loading={loading}
          fallback={
            <Box pt={2}>
              <Spinner size={24} />
            </Box>
          }
        >
          <Flex width="100%" position="absolute" zIndex={5} bg="appBackground" maxHeight={39} borderBottom="thin">
            <Box width={selected === null ? 0 : 100} overflow="hidden" style={{ transition: 'width 0.2s ease-out' }}>
              <Button
                m="4px 0 4px 4px"
                icon="chevron-left"
                text="Go Back"
                onClick={() => this.toggleRow({ index: selected })}
                width={96}
                outlined
              />
            </Box>
            <Box flex={1} p="4px">
              <InputGroup
                leftIcon="search"
                value={searchValue}
                onChange={this.handleSearch}
                placeholder="Search by name, id, etc..."
                fill
                rightElement={
                  searchValue !== '' ? (
                    <Button small minimal icon="cross" iconSize={16} onClick={() => this.clearSearch()} />
                  ) : null
                }
              />
            </Box>
          </Flex>
          {this.renderData()}
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default OciEntityExplorerWidget;
