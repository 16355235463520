import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { deepClone } from 'core/util';
import CopyToClipboardDialog from 'app/components/CopyToClipboardDialog';

@inject('$dictionary')
@observer
export default class ShowApiCallDialog extends Component {
  convertQueriesForAPI(queries, type) {
    return queries.map((query) => {
      // hacky deep clone here
      const res = deepClone(query);
      const q = res.query;
      q.dimension = q.metric;
      q.metric = q.units;
      delete q.units;
      q.update_frequency = 0;
      delete q.host_selected;
      delete q.filter_string;
      delete q.isEditing;
      delete q.bucket;
      delete q.bucketIndex;
      delete q.time_format;
      if (q.filters) {
        delete q.filters.custom;
        delete q.filters.filterString;
        if (q.filters.filterGroups && q.filters.filterGroups.length) {
          q.filters.filterGroups.forEach((filterGroup) => {
            delete filterGroup.filterString;
            delete filterGroup.id;
            delete filterGroup.metric;
            if (filterGroup.filters && filterGroup.filters.length) {
              filterGroup.filters.forEach((filter) => {
                delete filter.id;
              });
            }
          });
        }
      }
      if (q.aggregates) {
        q.aggregates.forEach((agg) => {
          delete agg.properName;
          delete agg.sortable;
        });
      }
      if (type === 'topxdata') {
        delete res.isOverlay;
        if (q.viz_type !== 'table') {
          delete q.viz_type;
        }
        delete q.show_overlay;
        delete q.overlay_day;
        delete q.sync_axes;
      }
      return res;
    });
  }

  getCurlCommand(queries, type) {
    const { $dictionary } = this.props;
    const data = { version: 4, queries: this.convertQueriesForAPI(queries, type) };
    if (type === 'topxchart') {
      data.imageType = '<CHOOSE ONE OF:pdf|png|svg>';
    }

    const apiUrl = $dictionary.get('baseAPIUrl').replace('/v5', '/next/v5');

    return `curl -v -H 'X-CH-Auth-Email:<YOUR_EMAIL_HERE>' -H 'X-CH-Auth-API-Token:<YOUR_API_TOKEN_HERE>' -H 'Content-Type: application/json' -d '${JSON.stringify(
      data
    )}' ${apiUrl}/query/${type}`;
  }

  preparedQueries() {
    const { dataview } = this.props;
    const { queryBuckets } = dataview;

    const hasFilterDimensions = queryBuckets.some((q) => q.firstQuery?.get('filterDimensionsEnabled'));
    if (hasFilterDimensions) {
      const serializedQueries = [];
      queryBuckets.models.forEach((queryModel) => {
        const serializedQuery = queryModel.queries.models.map((query) => query.serialize());
        const overlays = queryModel.getOverlays();
        serializedQueries.push(
          ...queryModel.breakdownFilterDimensionsQuery(serializedQuery, overlays).map((query) => ({
            bucket: query.bucket,
            isOverlay: query.isOverlay,
            query
          }))
        );
      });
      return serializedQueries;
    }
    return queryBuckets.serialize();
  }

  render() {
    const { showChartApiCurlDialogOpen, showDataApiCurlDialogOpen, showApiJsonDialogOpen, onClose } = this.props;
    const serializedQueries = this.preparedQueries();

    if (showChartApiCurlDialogOpen) {
      return (
        <CopyToClipboardDialog
          title="Chart API Call via cURL"
          isOpen={showChartApiCurlDialogOpen}
          onClose={onClose}
          value={this.getCurlCommand(serializedQueries, 'topxchart')}
          dialogHeight={500}
        />
      );
    }

    if (showDataApiCurlDialogOpen) {
      return (
        <CopyToClipboardDialog
          title="Data API Call via cURL"
          isOpen={showDataApiCurlDialogOpen}
          onClose={onClose}
          value={this.getCurlCommand(serializedQueries, 'topxdata')}
          dialogHeight={500}
        />
      );
    }

    if (showApiJsonDialogOpen) {
      return (
        <CopyToClipboardDialog
          title="Data API JSON Input"
          isOpen={showApiJsonDialogOpen}
          onClose={onClose}
          value={JSON.stringify({ version: 4, queries: this.convertQueriesForAPI(serializedQueries) }, null, 2)}
          dialogHeight={600}
        />
      );
    }

    return null;
  }
}
