import React from 'react';
import { greekIt } from 'app/util/utils';
import { Flex, Box, Text } from 'core/components';
import styled from 'styled-components';

const CapacityMeter = ({
  useCap = 0,
  usePercent = 0,
  useTraffic,
  small = true,
  showText = true,
  warningThreshold = 60,
  dangerThreshold = 90,
  forcePrefix,
  showUnits = true
}) => {
  const dotSize = small ? '5px' : '8px';

  const Dot = styled(Box)`
    width: ${dotSize};
    height: ${dotSize};
    border-radius: 50%;
    margin-right: 2px;
    box-shadow: inset 0px 0px 0px 1px currentColor;
    background-color: ${(props) => (props.filled ? 'currentColor' : 'transparent')};
  `;
  const inWarningZone = usePercent > warningThreshold && usePercent < dangerThreshold;
  const inDangerZone = usePercent > dangerThreshold;
  const { display, displayFull } = greekIt(useCap, {
    forcePrefix,
    suffix: 'bps',
    fix: 0,
    scaleMax: 0.999
  });

  const getColor = (pct) => (pct >= dangerThreshold && 'danger') || (pct >= warningThreshold && 'warning') || 'success';

  return (
    <Box>
      {showText && (
        <Text
          as="div"
          fontWeight={inWarningZone || inDangerZone ? 'bold' : 'normal'}
          color={(inWarningZone && 'warning') || (inDangerZone && 'danger') || 'muted'}
          mb="4px"
        >
          {useTraffic ? `${parseInt(usePercent)}% (${useTraffic})` : `${parseInt(usePercent)}%`} of{' '}
          {showUnits ? displayFull : display}
        </Text>
      )}

      <Flex>
        <Dot color={getColor(0)} filled={usePercent > 0} />
        <Dot color={getColor(10)} filled={usePercent >= 10} />
        <Dot color={getColor(20)} filled={usePercent >= 20} />
        <Dot color={getColor(30)} filled={usePercent >= 30} />
        <Dot color={getColor(40)} filled={usePercent >= 40} />
        <Dot color={getColor(50)} filled={usePercent >= 50} />
        <Dot color={getColor(60)} filled={usePercent >= 60} />
        <Dot color={getColor(70)} filled={usePercent >= 70} />
        <Dot color={getColor(80)} filled={usePercent >= 80} />
        <Dot color={getColor(90)} filled={usePercent >= 90} />
      </Flex>
    </Box>
  );
};

export default CapacityMeter;
