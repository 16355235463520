import React from 'react';
import { Text } from 'core/components';

export default function Dimension({ label, group }) {
  return (
    <>
      {group && (
        <Text muted as="div" fontSize="small" fontWeight="normal">
          {group}
        </Text>
      )}
      {label}
    </>
  );
}
