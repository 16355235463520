import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Card, EmptyState, Flex, Text } from 'core/components';
import VerticalDetailTabs from 'app/components/detailTabs/VerticalDetailTabs';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import Page from 'app/components/page/Page';
import * as cloudDetailMetrics from 'app/views/core/cloud/cloudDetailMetrics';

import { cloudProviderData } from 'app/util/utils';
import { getDefaultTabWhitelistCloudProviderAdjusted } from 'app/components/detailTabs/tabLists';
import { isGoogleCloud } from '@kentik/ui-shared/util/map';
import AggregateViewHeader from '../aggregate/AggregateViewHeader';
import parentLinks from '../NetworkExplorerParentLink';

@inject('$tabs')
@observer
class CloudDetail extends Component {
  state = {
    filters: [],
    lookback_seconds: 86400,
    aggregateType: 'p95th_bits_per_sec'
  };

  static getDerivedStateFromProps(props, state) {
    const { location, $tabs, match } = props;
    let { cloudProvider } = match.params;
    const { detail, value } = match.params;

    if (cloudProvider !== state.cloudProvider || detail !== state.detail || value !== state.value) {
      cloudProvider = isGoogleCloud(cloudProvider) ? 'gcp' : cloudProvider;
      const tabs = $tabs.getTabs(cloudProvider);

      // verify cloud provider/detail combo is valid.
      const detailMetrics = cloudDetailMetrics[detail] || [];
      const isValidDetailForProvider = detailMetrics.some((name) => {
        if (cloudProvider === 'gcp') {
          return name.includes('_gcp_') || name.includes('_gce_');
        }
        if (cloudProvider === 'azure') {
          return name.includes('_az_') || name.includes('_azure_');
        }
        if (cloudProvider === 'aws') {
          return name.includes('_aws_');
        }
        return false;
      });

      let defaultTab;
      switch (cloudProvider) {
        case 'azure':
          defaultTab = tabs.AZURE_INSTANCE.id;
          break;
        case 'aws':
          defaultTab = tabs.AWS_INSTANCE.id;
          break;
        case 'gcp':
          defaultTab = tabs.GCP_VM_NAME.id;
          break;
        default:
      }

      return {
        cloudProvider,
        detail,
        value,
        defaultTab,
        isValidDetailForProvider,
        ...location.state
      };
    }
    return null;
  }

  get filters() {
    const { filters, cloudProvider, detail, value } = this.state;
    const filterField = `${cloudProvider}_${detail}`;
    const detailFilter = {
      filterField,
      operator: '=',
      filterValue: value
    };

    return filters.concat(detailFilter);
  }

  handleFiltersApply = (filters) => {
    this.setState({ filters }, this.updateRouteState);
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds }, this.updateRouteState);
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType }, this.updateRouteState);
  };

  updateRouteState = () => {
    const { history, location } = this.props;
    const { aggregateType, filters, lookback_seconds } = this.state;
    history.replace(location.pathname, {
      aggregateType,
      filters,
      lookback_seconds
    });
  };

  render() {
    const {
      aggregateType,
      filters,
      lookback_seconds,
      cloudProvider,
      detail,
      value,
      isValidDetailForProvider,
      defaultTab
    } = this.state;
    const cloudProviderDisplay = (cloudProviderData(cloudProvider, 'name') || '').split(' (')[0];
    return (
      <Page
        title={value}
        parentLinks={[
          ...parentLinks,
          { link: `/v4/core/quick-views/cloud/${cloudProvider}`, label: `${cloudProviderDisplay}` }
        ]}
        scrolls
        showExport
      >
        {!isValidDetailForProvider && (
          <EmptyState icon="disable" title="Not a valid cloud detail configuration" maxWidth={650} m="0px auto" />
        )}
        {isValidDetailForProvider && (
          <>
            <AggregateViewHeader
              title={
                <Flex alignItems="baseline">
                  <Box mr={1}>{value}</Box>
                  <Text small muted>
                    {detail.replace('_', ' ')}
                  </Text>
                </Flex>
              }
              aggregateType={aggregateType}
              filters={filters}
              lookbackSeconds={lookback_seconds}
              onFiltersChange={this.handleFiltersApply}
              onAggregateChange={this.handleAggregationChange}
              onMetricsChange={this.handleAggregationChange}
              onTimeRangeChange={this.handleLookbackChange}
            />

            <Card mb={2}>
              <TabbedChart
                queryName="cloudInboundOutboundQuery"
                overrides={{
                  lookback_seconds,
                  device_types: [`${cloudProvider}_subnet`],
                  aggregateTypes: [aggregateType]
                }}
                simpleFilters={this.filters}
              />
            </Card>

            <VerticalDetailTabs
              key={cloudProvider}
              defaultTab={defaultTab}
              simpleFilters={this.filters}
              queryOverrides={{ lookback_seconds, aggregateTypes: [aggregateType] }}
              cloud={cloudProvider}
              whitelist={getDefaultTabWhitelistCloudProviderAdjusted(cloudProvider)}
            />
          </>
        )}
      </Page>
    );
  }
}

export default CloudDetail;
