import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { SizeMe } from 'react-sizeme';

import getErrorBoundary from 'core/util/getErrorBoundary';
import { Box, Flex, FlexColumn, Heading, LinkButton } from 'core/components';
import Page from 'app/components/page/Page';

import TrafficMap from './trafficMap/TrafficMap';
import Info from './Info';
import Legend from './Legend';

const ErrorBoundaryCmp = getErrorBoundary('Topology');

@inject('$topo', '$sites')
@observer
class Topology extends Component {
  static defaultProps = {
    embedded: false,
    enableMouseWheelZoom: true,
    showLegend: true
  };

  state = {
    highlightKey: undefined,
    details: undefined,
    isInfoPinned: false,
    allGroupsCollapsed: false
  };

  componentDidMount() {
    const { $sites } = this.props;
    const { collection } = $sites;
    collection.fetch();
  }

  handleShowDetails = (type, data, el, options, e) => {
    const { embedded } = this.props;
    const { isInfoPinned } = this.state;
    const clicked = e.type === 'click';

    if (!isInfoPinned || clicked) {
      this.setState((prevState) => ({
        isInfoPinned:
          clicked && prevState.isInfoPinned && prevState.details && prevState.details.data.id === data.id
            ? false
            : clicked,
        details: {
          type,
          data,
          el,
          options: { embedded, ...options }
        }
      }));
    }
  };

  handleLegendClick = (key) => {
    this.setState({
      highlightKey: key
    });
  };

  handleFilterChange = () => {
    this.setState({
      details: undefined
    });
  };

  renderTrafficMap = (width = 0, height = 0) => {
    const { $topo, enableMouseWheelZoom } = this.props;
    const { highlightKey, allGroupsCollapsed } = this.state;
    const { nodes } = $topo;

    if (width === 0 || height === 0 || !nodes) {
      return null;
    }

    return (
      <ErrorBoundaryCmp>
        <TrafficMap
          height={height}
          width={width}
          highlightKey={highlightKey}
          onShowDetails={this.handleShowDetails}
          allGroupsCollapsed={allGroupsCollapsed}
          enableMouseWheelZoom={enableMouseWheelZoom}
        />
      </ErrorBoundaryCmp>
    );
  };

  render() {
    const { $topo, embedded, siteId, deviceId, title, showLegend } = this.props;
    const { details } = this.state;

    const networkMapTitle = 'Logical Map';
    const parentLinks =
      title && title !== networkMapTitle ? [{ link: '/v4/kentik-map/logical', label: networkMapTitle }] : undefined;

    let hybridMapLink = '/v4/kentik-map';
    if (siteId) {
      hybridMapLink = `${hybridMapLink}/site/${siteId}`;
    } else if (deviceId) {
      hybridMapLink = `${hybridMapLink}/device/${deviceId}`;
    }

    const contents = (
      <>
        <FlexColumn flex="1" overflow="hidden">
          {!embedded && (
            <Flex pb={2} alignItems="flex-start">
              <Box mr={2} flex={1}>
                <Heading level={1} mb={0}>
                  {title || networkMapTitle}
                </Heading>
              </Box>
            </Flex>
          )}

          <SizeMe monitorHeight monitorWidth refreshRate={100} noPlaceholder>
            {({ size }) => (
              <Box flex={1} overflow="hidden">
                {this.renderTrafficMap(size.width, size.height)}
              </Box>
            )}
          </SizeMe>
        </FlexColumn>

        {showLegend && (
          <Flex flexDirection="column" width={275} overflow="hidden" borderRadius={embedded ? '0 3px 3px 0' : 0}>
            {!$topo.loading && (
              <>
                {!embedded && (
                  <Legend
                    hideLegendOptions
                    siteId={siteId}
                    onLegendClick={this.handleLegendClick}
                    onFilterChange={this.handleFilterChange}
                  />
                )}
                <Info details={details} />
              </>
            )}
          </Flex>
        )}
      </>
    );

    if (embedded) {
      return (
        <Flex height="100%" overflow="hidden" p={0} position="relative">
          {contents}
        </Flex>
      );
    }

    return (
      <Page
        title={title || networkMapTitle}
        parentLinks={parentLinks}
        subnavTools={<LinkButton text="View Kentik Map" ml="2px" to={hybridMapLink} minimal />}
        position="relative"
        flexDirection="row"
        canFullScreen
      >
        {contents}
      </Page>
    );
  }
}

export default Topology;
