const fields = {
  view_name: {
    label: 'Name',
    rules: 'required'
  },

  view_description: {
    label: 'Description',
    placeholder: 'Brief description of the query...',
    rules: 'max:300'
  },

  category_id: {
    label: 'Category',
    rules: 'newViewCategory'
  },

  share_level: {
    label: 'Visible To',
    rules: 'required'
  },

  labelIds: {
    label: 'Labels',
    defaultValue: [],
    placeholder: 'Apply labels to this view...'
  }
};

const options = {
  name: 'Saved View'
};

export { fields, options };
