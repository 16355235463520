import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PopoverInteractionKind } from '@blueprintjs/core';
import { withTheme } from 'styled-components';
import { Box, Divider, LinkButton, Popover, Tag, Text } from 'core/components';
import { Grid } from 'app/components/svg';
import MeshMetrics from 'app/views/synthetics/components/mesh/MeshMetrics';
import { getSubtestUrl } from 'app/views/synthetics/utils/syntheticsUtils';
import AggregationTag from 'app/views/synthetics/components/AggregationTag';

@withTheme
@inject('$app', '$sharedLinks')
@observer
class DensityGrid extends Component {
  state = {
    popover: { dimensions: {}, element: null, model: null }
  };

  handlePopoverClose = () => {
    const { popover } = this.state;
    popover.element.classList.remove('hover');
  };

  handleCellMouseOver = (model, dimensions, element) => {
    if (model) {
      const { popover } = this.state;
      if (popover.element) {
        popover.element.classList.remove('hover');
      }
      element.classList.add('hover');
      const { onCellMouseOver } = this.props;
      if (onCellMouseOver) {
        onCellMouseOver(model);
      }
      this.cellTimeout = setTimeout(() => this.setState({ popover: { model, dimensions, element } }), 20);
    } else {
      clearTimeout(this.cellTimeout);
    }
  };

  getPopoverContent = () => {
    const { $app, isAggregated } = this.props;
    const {
      popover: { model }
    } = this.state;
    if (!model) {
      return null;
    }
    const { lookbackSeconds, startDate, endDate, test } = this.props;
    const overall_health = model.get('overall_health');
    const intent = isAggregated ? {} : { critical: 'danger', healthy: 'success', warning: 'warning' };
    const metrics = { latency: { health: model.get('latency_health'), value: model.get('avg_latency') } };
    let target;
    const metricLabels = {};
    if (test.isDns) {
      target = model.get('server');
      // overwrite the latency metric health/value to be dns-based
      metrics.latency = { health: model.get('dns_latency_health'), value: model.get('dns_avg_latency') };
      Object.assign(metricLabels, { latency: 'Resolution Time' });
    } else {
      target = model.get('pingTarget');
      Object.assign(metrics, {
        jitter: { health: model.get('jitter_health'), value: model.get('avg_jitter') },
        loss: { health: model.get('packet_loss_health'), value: model.get('packet_loss') }
      });
    }

    // note that "View Details" below is disabled for preview tests because that route would be impossible to render
    // the preview test is not in the tests collection and cannot be retrieved to load the subtest results
    return (
      <Box p="12px">
        <Box>
          <Tag fontWeight="bold" intent={intent[overall_health] || 'none'} minimal mr={1} textTransform="capitalize">
            {overall_health}
          </Tag>
          <Text>{target}</Text>
          <AggregationTag ml={2} isAggregated={isAggregated} />
        </Box>
        <Divider mt={1} mb={1} />
        <MeshMetrics metrics={metrics} metricLabels={metricLabels} isAggregated={isAggregated} />
        {!$app.isSharedLink && !$app.isSubtenant && (
          <LinkButton
            mt="12px"
            width="100%"
            disabled={test.isPreview || !model.get('agentModel') || model.get('noQueryData')}
            small
            text="View Details"
            key="details"
            to={getSubtestUrl({ agent_id: model.get('agent_id'), target, test_id: test.id })}
            state={{ lookbackSeconds, startDate, endDate }}
          />
        )}
      </Box>
    );
  };

  render() {
    const {
      gridWidth,
      models,
      keyId,
      theme: { colors }
    } = this.props;
    const { popover } = this.state;
    return (
      <>
        <Grid
          width={gridWidth}
          itemWidth={16}
          itemHeight={16}
          getItemFill={(model) => {
            const { danger, success, warning } = colors;
            // even in aggregated mode, we want to show the individual health status
            // the popover will display the aggregation state and disable health colors instead
            // we will also have an aggregation tag on the test results view or in the header of the dashboard widget
            const fill = { critical: danger, healthy: success, warning };
            return fill[model.get('overall_health')] || colors.lightGray1;
          }}
          items={models}
          paddingX={0}
          paddingY={0}
          onItemHover={this.handleCellMouseOver}
        />
        <Popover
          content={this.getPopoverContent()}
          interactionKind={PopoverInteractionKind.HOVER}
          key={`${keyId}-${popover.model ? popover.model.id : 0}`}
          minimal={false}
          modifiers={{
            offset: { enabled: true, offset: '0, -2px' },
            flip: { boundariesElement: 'viewport' },
            preventOverflow: { boundariesElement: 'viewport' }
          }}
          onClose={this.handlePopoverClose}
          popoverClassName="bp4-dark"
          position="right-top"
          targetProps={{
            style: {
              cursor: 'pointer',
              position: 'absolute',
              ...popover.dimensions
            }
          }}
          usePortal
        >
          <div />
        </Popover>
      </>
    );
  }
}

export default DensityGrid;
