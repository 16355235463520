import Collection from 'core/model/Collection';
import PromptGroupModel from './PromptGroupModel';

class PromptGroupCollection extends Collection {
  get model() {
    return PromptGroupModel;
  }
}

export default PromptGroupCollection;
