import { observer } from 'mobx-react';
import { transparentize } from 'polished';
import { formatDateTime } from 'core/util/dateUtils';
import BaseHighchartsNonStyledDataview from 'app/components/dataviews/views/BaseHighchartsNonStyledDataview';

@observer
class BollingerBandChart extends BaseHighchartsNonStyledDataview {
  static defaultProps = {
    isAggregated: false // we do not show health colors in aggregated results
  };

  state = {};

  constructor(props) {
    super(props);

    const {
      theme: { colors, fonts },
      onHover
    } = this.props;

    this.state = {
      stickyPointer: true,
      highlightPointsByEvent: true,
      chartOptions: {
        chart: {
          style: { fontFamily: fonts.body },
          height: 200,
          spacingLeft: 0,
          spacingRight: 0,
          backgroundColor: colors.chart.tooltipBackground
        },
        title: { text: '' },
        credits: { enabled: false },
        legend: { enabled: false },
        xAxis: {
          type: 'datetime',
          crosshair: true,
          lineColor: colors.chart.axisLine,
          gridLineColor: colors.chart.gridLine,
          tickColor: colors.chart.axisTick,
          labels: {
            style: {
              color: colors.muted
            },
            formatter() {
              if (this.dateTimeLabelFormat === '%b %e') {
                return `<strong>${formatDateTime(this.value, 'MMM Do')}</strong>`;
              }

              return this.axis.defaultLabelFormatter.call(this);
            }
          },
          dateTimeLabelFormats: {
            day: '%b %e',
            week: '%b %e'
          }
        },
        yAxis: {
          title: { text: '' },
          gridLineColor: colors.chart.gridLine,
          labels: {
            align: 'left',
            x: 0,
            y: -2,
            style: {
              color: colors.muted
            }
          }
        },
        tooltip: {
          outside: true,
          useHTML: true,
          backgroundColor: colors.chart.tooltipBackground,
          valueSuffix: this.valueSuffix,
          shared: true,
          style: {
            color: colors.body,
            fontFamily: fonts.body
          },
          headerFormat: ''
        },
        series: [
          {
            name: 'Latency',
            data: [],
            zIndex: 1,
            zoneAxis: 'x',
            zones: [],
            marker: {
              enabled: false,
              states: {
                select: {
                  enabled: true,
                  lineWidth: 0
                },
                hover: {
                  enabled: false
                }
              }
            },
            states: {
              hover: {
                enabled: false,
                lineWidthPlus: 0
              }
            },
            point: {
              events: {
                mouseOver: (e) => {
                  if (onHover) {
                    onHover(e.target.options.x);
                  }
                }
              }
            }
          },
          {
            name: 'Rolling Standard Deviation',
            data: [],
            type: 'arearange',
            lineWidth: 0,
            linkedTo: ':previous',
            fillOpacity: 0.3,
            zIndex: 0,
            zoneAxis: 'x',
            zones: [],
            marker: {
              enabled: false,
              states: {
                select: {
                  enabled: true,
                  lineColor: 'rgba(255, 255, 255, 0.5)',
                  lineWidth: 5,
                  radius: 8
                },
                hover: {
                  enabled: false
                }
              }
            },
            states: {
              hover: {
                enabled: false,
                lineWidthPlus: 0
              }
            },
            point: {
              events: {
                mouseOver: (e) => {
                  if (onHover) {
                    onHover(e.target.options.x);
                  }
                }
              }
            }
          }
        ]
      }
    };
  }

  get valueSuffix() {
    return 'ms';
  }

  get type() {
    return 'timeline';
  }

  redraw() {
    if (this.chart) {
      const {
        theme: { colors }
      } = this.props;

      this.setState({
        chartOptions: {
          chart: {
            backgroundColor: colors.chart.tooltipBackground
          },
          tooltip: {
            backgroundColor: colors.chart.tooltipBackground,
            style: {
              color: colors.body
            }
          },
          xAxis: {
            lineColor: colors.chart.axisLine,
            gridLineColor: colors.chart.gridLine,
            tickColor: colors.chart.axisTick,
            labels: {
              style: {
                color: colors.muted
              }
            }
          },
          yAxis: {
            gridLineColor: colors.chart.gridLine,
            labels: {
              style: {
                color: colors.muted
              }
            }
          }
        }
      });
      this.renderData();
    }
  }

  getColor(health) {
    const {
      theme: { colors },
      isAggregated
    } = this.props;

    if (!isAggregated) {
      if (health === 'warning') {
        return colors.warning;
      }

      if (health === 'critical') {
        return colors.danger;
      }
    }

    return colors.muted;
  }

  getFillColor(health) {
    const { theme, isAggregated } = this.props;
    const { colors, name } = theme;

    if (!isAggregated) {
      if (health === 'warning') {
        return transparentize(0.7, colors.warning);
      }

      if (health === 'critical') {
        return transparentize(0.7, colors.danger);
      }
    }

    // TODO: quick hack for dark mode, should fix in theme.
    if (name === 'dark') {
      return transparentize(0.4, colors.muted);
    }
    return transparentize(0.85, colors.muted);
  }
}

export default BollingerBandChart;
