import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import { FormDialog, Form } from 'core/form';
import { fields, options } from 'app/forms/config/dashboardItemDetails';
import QueryModel from 'app/stores/query/QueryModel';
import { getHashForQueries } from 'app/stores/query/urlHash';

import DashboardItemForm from './DashboardItemForm';
import SynthDashboardItemForm from './SynthDashboardItemForm';

@Form({ fields, options })
@inject('$explorer', '$dashboard', '$dashboards', '$rawFlow')
@observer
class DashboardItemDialog extends Component {
  static defaultProps = {
    showDashboardSelect: false
  };

  // need this to force render from handleQueryChange for synth_test panels
  state = {
    synthEditQuery: null
  };

  panel_subtitle = {
    explorer_query: 'Data Explorer',
    alert_scoreboard: 'Scoreboard',
    raw_flow: 'Raw Flow',
    synth_test: 'Synthetic Test',
    metrics: 'Metrics Explorer'
  };

  @observable.ref
  _innerFormState;

  get innerFormState() {
    const { model, $explorer, $rawFlow } = this.props;
    const panelType = model?.get('panel_type');

    if (panelType === 'explorer_query') {
      return $explorer.formState;
    }

    if (panelType === 'raw_flow') {
      return $rawFlow.formState;
    }

    return this._innerFormState ?? null;
  }

  setInnerFormState = (form) => {
    this._innerFormState = form;
  };

  componentDidMount() {
    const { model } = this.props;

    if (model.dataview.formConfig) {
      this.initializeDataViewFields(model);
    }

    if (model && (!model.fetchedQuery || !model.editQuery)) {
      this.loadQueries();
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen: wasOpen } = prevProps;
    const { isOpen: isCurrentlyOpen, model } = this.props;

    if (model && !wasOpen && isCurrentlyOpen) {
      /**
       * A DashboardItemModel's DataView informs us about which Component and form fields we need to render.
       * They live on model.formState
       */
      if (model.dataview.formConfig) {
        this.initializeDataViewFields(model);
      }
      this.loadQueries();
    }
  }

  componentWillUnmount() {
    const { $explorer, model } = this.props;
    model.editQuery = undefined;
    $explorer.destroy();
  }

  loadQueries = () => {
    const { model } = this.props;
    model.fetchSavedQuery().then(() => {
      const [query] = model.get('queries');
      model.editQuery = query;
    });
  };

  /**
   * Adds the required form fields required by this DashboardItemModel's dataview for configuration,
   * and initializes their values.
   */
  initializeDataViewFields = (dashboardItem) => {
    const { form } = this.props;

    const dataviewFields = dashboardItem.dataview.formConfig;
    const dashboardItemValues = dashboardItem.get();
    const dataviewValues = dashboardItem.dataview.viewModel.get();

    const formValues = {
      ...dashboardItemValues,
      ...dataviewValues
    };

    // add the dataviews fields to the form
    form.addFields(dataviewFields);

    // add them to the `dataviews` field group so it's easier to pull them out later.
    form.addFieldsToGroup(dataviewFields, 'dataview');
    form.init(formValues);
  };

  handleSave = async (form) => {
    const { model, $dashboard, $dashboards, $explorer, $rawFlow } = this.props;
    const { synthEditQuery } = this.state;
    const { isNew, dataview } = model;
    const { formState, queryModel } = $explorer;

    $dashboard.needsScrollToBottom = isNew;

    const dashboardItemValues = form.getFieldGroupValues('item');

    const isRawFlow = dataview.viewType === 'rawFlow';
    const isMetrics = dataview.viewType === 'metrics';

    let dataviewValues;
    if (model.get('panel_type') === 'synth_test') {
      dataviewValues = {
        synth_test_type: form.getValue('synth_test_type'),
        synth_panel_type: form.getValue('synth_panel_type'),
        synth_test_id: form.getValue('synth_test_id'),
        synth_test_metric: form.getValue('synth_test_metric'),
        synth_test_display: form.getValue('synth_test_display'),
        synth_test_labels: form.getValue('synth_test_labels')
      };
      let lookback;
      if (!dashboardItemValues.time_locked && synthEditQuery) {
        lookback = synthEditQuery;
      } else {
        const { lookback_seconds, starting_time, ending_time } = $dashboard.dashboard.get('query');
        lookback = { lookback_seconds, starting_time, ending_time };
      }
      Object.assign(dataviewValues, lookback);
    } else if (dataview && isRawFlow) {
      dataviewValues = { ...$rawFlow.formState.getValues(), query_title: dashboardItemValues.panel_title };
    } else if (dataview && isMetrics) {
      dataviewValues = { ...model.editQuery.serialize(), query_title: dashboardItemValues.panel_title };
    } else {
      dataviewValues = { ...form.getFieldGroupValues('dataview'), query_title: dashboardItemValues.panel_title };
    }

    let saved_query_id;
    model.setRequestStatus('updating');

    if (!dataview.isFlowVisualization || (model.isNew && dataview.isFlowVisualization)) {
      saved_query_id = await getHashForQueries(dataviewValues, { persist: true });
    } else {
      saved_query_id = model.get('saved_query_id');
    }

    const attributes = { ...dashboardItemValues, saved_query_id };
    let explorerQuerySwitches = {};

    if (formState) {
      formState.submit(() => {
        queryModel.set(model.getEditQuery().get());
        // Immediately apply the item query changes to the QueryBuckets so they can be saved below. Do not wait. Do not pass Go.
        $explorer.apply(QueryModel.create(model.getEditQuery().serialize()), true);
        formState.setModel(queryModel);
      });
      explorerQuerySwitches = {
        time_locked: formState.getValue('time_locked'),
        device_locked: formState.getValue('device_locked'),
        filter_source: formState.getValue('filter_source')
      };
    }

    if (dataview.isFlowVisualization) {
      $explorer.dataview.queryBuckets.save({ persist: true }).then((hash) =>
        model
          .save(
            Object.assign(form.getValues(), { saved_query_id: hash, ...explorerQuerySwitches }),
            { toast: false },
            attributes
          )
          .then(() => {
            if (model.get('dashboard')) {
              if (!$dashboard.dashboard.isIncompleteParametric) {
                model.updateQuery(QueryModel.create(model.get('dashboard').get('query')).serialize());
              }
            } else {
              const dashToNavigateTo = $dashboards.collection.get(model.get('dashboard_id'));
              $dashboards.navigateToDashboard(dashToNavigateTo);
            }
          })
      );
    } else {
      const saveAttrs = Object.assign({}, attributes, isRawFlow && form.getFieldGroupValues('parametric'));

      model.save(saveAttrs).then(() => {
        model.updateQuery();
        if (isRawFlow) {
          const rawFlowQuery = QueryModel.create(dataviewValues);
          model.set({ rawFlowQuery });
        }
      });
    }
  };

  handleQueryChange = (query) => {
    const { form, model } = this.props;
    model.editQuery = query;
    form.validate();
    if (model.get('panel_type') === 'synth_test') {
      this.setState({ synthEditQuery: query });
    }
  };

  render() {
    const { onClose, showDashboardSelect, model } = this.props;
    const { synthEditQuery } = this.state;

    const { isNew } = model || {};
    const panel_type = model && model.get('panel_type');
    const title = `${isNew ? 'Add' : 'Edit'} ${this.panel_subtitle[panel_type]} Panel`;
    const dialogWidth = panel_type === 'synth_test' ? '800px' : 'calc(100vw - 200px)';

    return (
      <FormDialog
        {...this.props}
        style={{
          width: dialogWidth,
          maxHeight: 'calc(100vh - 100px)',
          maxWidth: 1600,
          top: 40,
          margin: 0,
          position: 'absolute'
        }}
        entityName="Panel"
        title={title}
        formActionsProps={{
          onSubmit: this.handleSave,
          onCancel: onClose,
          width: 110,
          submitButtonProps: { disabled: this.innerFormState?.valid === false }
        }}
      >
        {panel_type === 'synth_test' && (
          <SynthDashboardItemForm
            {...this.props}
            editQuery={synthEditQuery}
            handleSave={this.handleSave}
            onQueryChange={this.handleQueryChange}
          />
        )}
        {panel_type !== 'synth_test' && (
          <DashboardItemForm
            {...this.props}
            handleSave={this.handleSave}
            onQueryChange={this.handleQueryChange}
            showDashboardSelect={showDashboardSelect}
            innerFormSetter={this.setInnerFormState}
          />
        )}
      </FormDialog>
    );
  }
}

export default DashboardItemDialog;
