import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Button, Flex, Popover, Menu, MenuItem, ButtonGroup, Box } from 'core/components';
import { FormComponent, Field, InputGroup, TextArea, FormDialog, SubmitButton } from 'core/form';
import LabelSelector from 'app/components/labels/LabelSelector';
import ShareLevelSelector from 'app/views/core/dashboards/ShareLevelSelector';
import { fields, options } from 'app/forms/config/savedView';

const saveAsDialogFields = { ...fields, is_new: { defaultValue: true } };

@withRouter
@observer
export default class SavedViewOptions extends Component {
  state = {
    isSaveAsDialogOpen: false
  };

  handleSave = (form) => {
    const { onSave } = this.props;
    onSave(form.getValues());
  };

  handleRevert = (form) => {
    const { onRevert } = this.props;
    form.reset();
    onRevert();
  };

  renderOptionsMenu() {
    return (
      <Menu minWidth={139}>
        <MenuItem text="Save as..." onClick={this.onSaveAsClick} />
      </Menu>
    );
  }

  onSaveAsClick = () => {
    this.setState({ isSaveAsDialogOpen: true });
  };

  handleSaveAsDialogClose = () => {
    this.setState({ isSaveAsDialogOpen: false });
  };

  render() {
    const { savedView, match } = this.props;
    const { isSaveAsDialogOpen } = this.state;
    return (
      <>
        <FormComponent fields={fields} options={options} model={savedView}>
          {({ form }) => (
            <>
              <Field name="view_name">
                <InputGroup />
              </Field>
              <Field name="view_description">
                <TextArea fill />
              </Field>
              <Box my={2}>
                <LabelSelector fieldName="labelIds" useDialog />
              </Box>

              <Field name="share_level" label="Visibility">
                <ShareLevelSelector showLabel={false} field={form.getField('share_level')} />
              </Field>
              <Flex mt={2} justifyContent="space-between">
                <ButtonGroup>
                  <SubmitButton intent="primary" width={110} onSubmit={this.handleSave} fontWeight="bold" />
                  <Popover content={this.renderOptionsMenu()} position="bottom-right">
                    <Button intent="primary" width={30} icon="caret-down" />
                  </Popover>
                </ButtonGroup>

                <Button
                  disabled={!form.isDirty && !(match.params.viewId && match.params.hash)}
                  width={110}
                  onClick={() => this.handleRevert(form)}
                >
                  Revert
                </Button>
              </Flex>
            </>
          )}
        </FormComponent>
        {isSaveAsDialogOpen && (
          <FormDialog
            title="Create Saved View"
            isOpen={isSaveAsDialogOpen}
            onClose={this.handleSaveAsDialogClose}
            fields={saveAsDialogFields}
            options={{ name: 'New Saved View' }}
            formActionsProps={{
              onSubmit: this.handleSave,
              onCancel: this.handleSaveAsDialogClose
            }}
            style={{ width: 600 }}
          >
            {({ form }) => (
              <Box>
                <Field name="view_name">
                  <InputGroup />
                </Field>
                <Field name="view_description">
                  <TextArea fill />
                </Field>
                <Box my={2}>
                  <LabelSelector fieldName="labelIds" useDialog />
                </Box>

                <Field name="share_level" label="Visibility">
                  <ShareLevelSelector showLabel={false} field={form.getField('share_level')} />
                </Field>
              </Box>
            )}
          </FormDialog>
        )}
      </>
    );
  }
}
