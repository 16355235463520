import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import { Box, Card, Flex, Heading, Icon, Text } from 'core/components';
import DashLink from './DashLink';

@inject('$dashboards')
@observer
export default class DashboardNavigationDisplay extends Component {
  componentDidMount = () => {
    const { model } = this.props;
    model.fetchDependentsAndDependencies();
  };

  render() {
    const { model, $dashboards } = this.props;
    const dependents = model.get('dependents');
    const dependencies = model.get('dependencies');

    return (
      <Box>
        <Heading level={5}>Dashboard Dependencies</Heading>
        <Flex flexDirection="column" justifyContent="center" mt={2}>
          {dependents && (
            <Box mb={1}>
              <Heading level={6} color="muted" mb="2px">
                Navigate From
              </Heading>
              <Text as="p" muted small>
                Dashboards in which this dashboard is nested
              </Text>
              <Card mt={1} p="12px">
                {!dependents.length && (
                  <Text muted small>
                    None
                  </Text>
                )}
                {dependents.map((dependent, index) => {
                  const dashboard = $dashboards.collection.get(dependent.id);
                  const marginBottom = dependents.length && index !== dependents.length - 1 ? 0.5 : undefined;
                  if (dashboard) {
                    return (
                      <DashLink key={`dashlink_dependent_${dependent.id}`} dashboard={dashboard} mb={marginBottom} />
                    );
                  }
                  return (
                    <Box
                      key={`dashlink_dependent_${dependent.id}`}
                      mb={marginBottom}
                      className={Classes.TEXT_OVERFLOW_ELLIPSIS}
                      title={`${dependent.dash_title} ${dependent.company_name}(${dependent.company_id})`}
                    >
                      <Text mr="4px">{dependent.dash_title}</Text>
                      <Text muted>({dependent.company_name})</Text>
                    </Box>
                  );
                })}
              </Card>
            </Box>
          )}
          <Flex flexDirection="column" alignItems="center">
            <Icon icon="caret-down" mb={1} color="gray5" iconSize={20} />
            <Heading level={4} color="gray5">
              This Dashboard
            </Heading>
            <Icon icon="caret-down" color="gray5" iconSize={20} />
          </Flex>
          {dependencies && (
            <Box mt={1}>
              <Heading level={6} color="muted" mb="2px">
                Navigate To
              </Heading>
              <Text as="p" muted small>
                Dashboards nested in any panel of this dashboard
              </Text>
              <Card mt={1} p="12px">
                {!dependencies.length && (
                  <Text muted small>
                    None
                  </Text>
                )}
                {dependencies.map((dependency, index) => {
                  const dashboard = $dashboards.collection.get(dependency.id);
                  const marginBottom = dependencies.length && index !== dependencies.length - 1 ? 0.5 : undefined;

                  if (dashboard) {
                    return (
                      <DashLink
                        key={`dashlink_dependency_${dependency.id}`}
                        dashboard={dashboard}
                        mb={dependencies.length && index !== dependencies.length - 1 ? 0.5 : undefined}
                      />
                    );
                  }
                  return (
                    <Box
                      key={`dashlink_dependency_${dependency.id}`}
                      mb={marginBottom}
                      className={Classes.TEXT_OVERFLOW_ELLIPSIS}
                      title={`${dependency.dash_title})`}
                    >
                      <Text mr="4px">{dependency.dash_title}</Text>
                    </Box>
                  );
                })}
              </Card>
            </Box>
          )}
        </Flex>
      </Box>
    );
  }
}
