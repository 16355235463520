import { action, observable } from 'mobx';
import $explorer from 'app/stores/explorer/$explorer';

// essentially this is just a React context but hey, why not do it with MobX
class MetricsDataExplorerQueryStore {
  @observable
  query = {};

  @observable
  queryPath = null;

  @action
  setQuery(query) {
    this.query = query;
    if (query) {
      $explorer.getQueryPath(query).then((path) => {
        this.queryPath = path;
      });
    }
  }
}

export const DataExplorerQuery = new MetricsDataExplorerQueryStore();
