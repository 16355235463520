import styled from 'styled-components';
import Box from 'core/components/Box';

const PageContentContainer = styled(Box)`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;

  @media print {
    display: block;
  }
`;

export default PageContentContainer;
