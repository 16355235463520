import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Flex, Spinner } from 'core/components';

import DataViewWrapper from './DataViewWrapper';
import Legend from './views/legend/Legend';

@inject('$dataviews')
@observer
export default class DataViewExportWrapper extends Component {
  static defaultProps = {
    showLegend: true
  };

  dataViewHeight = (type, isGenerator) => {
    if (isGenerator || type === 'table') {
      return 'auto';
    }

    if (type === 'sankey') {
      return '95vh';
    }

    return '900px';
  };

  dismissSpinner = (timeout = 200, setFullyLoaded = true) => {
    const { dataview } = this.props;
    const emptyBuckets = dataview.queryBuckets?.activeBuckets?.length === 0;

    setTimeout(() => {
      if (setFullyLoaded && !emptyBuckets) {
        dataview.setFullyLoaded();
      }
    }, timeout);
  };

  render() {
    const { $dataviews, dataview, customView } = this.props;

    if (!$dataviews.hasViewModel(dataview.viewType) && !customView) {
      this.dismissSpinner();
      return (
        <Flex className="dataview-wrapper" alignItems="center" justifyContent="center" p={3}>
          <p className="pt-text-muted">
            Visualization type <strong>{dataview.viewType}</strong> is not available at this time.
          </p>
        </Flex>
      );
    }

    if (dataview.isFlowVisualization && (!dataview.queryBuckets || !dataview.queryBuckets.selectedQuery)) {
      return null;
    }

    const { viewType } = dataview;
    const dvConfig = $dataviews.getConfig(viewType);
    const shouldShowLegend = window.location.search.includes('format=pdf') && dvConfig.showLegend;
    const isGenerator = dataview.isFlowVisualization && dataview.queryBuckets.selectedQuery.get('generatorMode');

    const dataviewWrapperStyles = {
      width: window.location.search.includes('format=pdf') ? '1320px' : 'auto',
      height: this.dataViewHeight(viewType, isGenerator)
    };

    if (isGenerator) {
      dataviewWrapperStyles.borderRadius = 4;
      dataviewWrapperStyles.border = 'thin';
      dataviewWrapperStyles.bg = 'subnavBackground';
    }

    return (
      <Box px={3}>
        <DataViewWrapper
          {...this.props}
          isExport
          fitToHeight
          padHeight={window.location.search.includes('format=svg') ? 20 : 0}
          allowCache={false}
          viewProps={{
            showNativeLegend: !shouldShowLegend,
            styledMode: !window.location.search.includes('format=svg'),
            height: '100%'
          }}
          headerProps={{
            showTitleLink: false,
            showViewTypeTag: false,
            shouldArrangeVertically: true,
            showLastUpdated: false,
            showDateRange: dataview.isFlowVisualization,
            showAppliedFilters: true,
            showDataSources: dataview.isFlowVisualization
          }}
        >
          {({ component, header }) => (
            <>
              <Box pb={2} overflow="hidden">
                {header}
              </Box>
              <Flex flex={1} className="dataview-wrapper" overflow="auto" {...dataviewWrapperStyles}>
                {component}
              </Flex>
            </>
          )}
        </DataViewWrapper>

        {shouldShowLegend && <Legend hideLegendOptions dataview={dataview} />}

        {/* Show spinner to prevent PNG exports from rasterizing too quickly */}
        {!shouldShowLegend && dataview.loading && <Spinner size={18} />}
      </Box>
    );
  }
}
