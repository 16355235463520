import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PopoverPosition } from '@blueprintjs/core';

import { Flex, Icon, Button, Box, Text, Popover } from 'core/components';
import { Form } from 'core/form';

import SimpleFilterItem from './SimpleFilterItem';
import { FILTER_OPTIONS } from './simpleFilterUtils';

const fields = {
  filters: {
    isComplexArray: true
  },
  'filters[].filterField': {
    rules: 'required'
  },
  'filters[].filterValue': {
    placeholder: 'Enter value...'
  }
};
@Form({ fields, options: { name: 'SimpleFilters' } })
@inject('$app', '$exports')
@observer
class SimpleFilters extends Component {
  static defaultProps = {
    fieldName: 'filters',
    fieldWhitelist: null,
    fieldBlacklist: null
  };

  state = {
    isOpen: false
  };

  static getDerivedStateFromProps(props) {
    const { fieldBlacklist, fieldWhitelist } = props;

    let filterOptions = FILTER_OPTIONS;
    if (fieldBlacklist) {
      filterOptions = filterOptions.filter((option) => !fieldBlacklist.includes(option.value));
    }
    if (fieldWhitelist) {
      filterOptions = filterOptions.filter((option) => fieldBlacklist.includes(option.value));
    }

    return { filterOptions };
  }

  handleAddFilter = () => {
    const { form } = this.props;
    const { filterOptions } = this.state;
    const filters = form.getValue('filters');
    const filterFields = filters.reduce((filterFieldSet, filter) => filterFieldSet.add(filter.filterField), new Set());
    const filterField = filterOptions.find((option) => !filterFields.has(option.value)) || filterOptions[0];

    form.getField('filters').add({ filterField: filterField.value });
  };

  handleRemoveFilter = (filterIndex) => {
    const { form } = this.props;
    form.getField('filters').remove(filterIndex);
    this.handleChange();
  };

  handleInteraction = (nextOpenState) => {
    this.setState({ isOpen: nextOpenState });
  };

  handleChange = () => {
    const { $exports, form, onFiltersChange, hashEnabled } = this.props;

    const filters = form
      .getValue('filters')
      .filter((filter) => filter.filterValue)
      .map((filter) => ({ ...filter, operator: '=' }));

    // Get this in another thread so it doesn't feel like things are stuck!
    setTimeout(() => {
      if (hashEnabled) {
        $exports.setHash({
          hashedFilters: filters
        });
      }
      return onFiltersChange(filters);
    }, 50);
  };

  render() {
    const { $app, fieldName, form, onFiltersChange, values, fieldWhitelist, fieldBlacklist, ...boxProps } = this.props;
    const { isOpen, filterOptions } = this.state;

    const filters = form.getField('filters');
    const hasFilters = filters.size() > 0;
    const width = 155;

    if ($app.isExport) {
      return (
        <Flex px="12px" alignItems="center">
          <Icon icon="filters" iconSize={12} color="muted" mr="4px" />
          <Text small muted>
            {hasFilters ? `${filters.size()} filters` : 'No filters applied'}
          </Text>
        </Flex>
      );
    }

    return (
      <Box {...boxProps}>
        <Popover
          isOpen={isOpen}
          onInteraction={this.handleInteraction}
          position={PopoverPosition.BOTTOM_RIGHT}
          content={
            <Box p={1} minWidth={width}>
              {filters.map((filter, idx) => (
                <Box key={filter.filterField._id} mb={1}>
                  <SimpleFilterItem
                    filter={filter}
                    numFilters={filters.size()}
                    options={filterOptions}
                    index={idx}
                    onChange={this.handleChange}
                    onRemove={this.handleRemoveFilter}
                  />
                </Box>
              ))}

              <Button
                fill
                fontWeight="bold"
                icon="plus"
                intent="primary"
                minimal
                onClick={this.handleAddFilter}
                small
                text="Add a Filter"
              />
            </Box>
          }
        >
          <Button textAlign="left" rightIcon="caret-down" active={isOpen} width={width}>
            <Text as="div" fontSize="small" color="muted" pb="1px">
              Filters
            </Text>
            <Text as="div" fontWeight="bold" fontSize="small">
              {hasFilters ? `${filters.size()} filters applied` : 'No filters applied'}
            </Text>
          </Button>
        </Popover>
      </Box>
    );
  }
}

export default SimpleFilters;
