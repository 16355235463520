const safelyParseJSON = (json) => {
  let parsed;
  // value is already JSON
  if (typeof json === 'object') {
    return json;
  }
  try {
    parsed = JSON.parse(json);
  } catch (e) {
    parsed = undefined;
  }

  return parsed;
};

module.exports = safelyParseJSON;
