import React from 'react';
import { HEALTH } from 'shared/synthetics/constants';
import { Icon } from 'core/components';
import { ReactComponent as WarningIcon } from 'app/assets/icons/synthetics/severity-warning.svg';
import { ReactComponent as CriticalIcon } from 'app/assets/icons/synthetics/severity-critical.svg';

const ICONS = {
  [HEALTH.WARNING]: WarningIcon,
  [HEALTH.CRITICAL]: CriticalIcon
};

export default function SeverityIcon({ severity, ...iconProps }) {
  const icon = ICONS[severity] || null;

  if (icon) {
    return <Icon icon={icon} {...iconProps} />;
  }

  return null;
}
