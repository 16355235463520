const PREFIX = {
  avg: 'avg_ktappprotocol__',
  p95: 'p95th_ktappprotocol__',
  max: 'max_ktappprotocol__'
};

const METRICS = ['05', '06'];

const SNMP_KEYS = {
  outsort: `snmp__INT${METRICS[0]}`,
  secondaryOutsort: `snmp__INT${METRICS[1]}`
};

export const CAPACITY_SNMP_RESPONSE_KEYS = {
  avgIn: `${PREFIX.avg}${SNMP_KEYS.outsort}`,
  avgOut: `${PREFIX.avg}${SNMP_KEYS.secondaryOutsort}`,
  maxIn: `${PREFIX.max}${SNMP_KEYS.outsort}`,
  maxOut: `${PREFIX.max}${SNMP_KEYS.secondaryOutsort}`,
  lastIn: `f_avg_int${METRICS[0]}__k_last`,
  lastOut: `f_avg_int${METRICS[1]}__k_last`
};

export const CAPACITY_SNMP_QUERY = {
  sync_axes: false,
  outsort: `${PREFIX.avg}${SNMP_KEYS.outsort}`,
  secondaryOutsort: `${PREFIX.avg}${SNMP_KEYS.secondaryOutsort}`,

  aggregateTypes: [
    `${PREFIX.avg}${SNMP_KEYS.outsort}`,
    `${PREFIX.p95}${SNMP_KEYS.outsort}`,
    `${PREFIX.max}${SNMP_KEYS.outsort}`,

    `${PREFIX.avg}${SNMP_KEYS.secondaryOutsort}`,
    `${PREFIX.p95}${SNMP_KEYS.secondaryOutsort}`,
    `${PREFIX.max}${SNMP_KEYS.secondaryOutsort}`
  ],

  aggregateThresholds: {
    // SNMP outsort
    avg_ktappprotocol__snmp__INT05: 0,
    p95th_ktappprotocol__snmp__INT05: 0,
    max_ktappprotocol__snmp__INT05: 0,

    // SNMP secondaryOutsort
    avg_ktappprotocol__snmp__INT06: 0,
    p95th_ktappprotocol__snmp__INT06: 0,
    max_ktappprotocol__snmp__INT06: 0
  }
};

// NOTE FOR PEOPLE OF THE FUTURE (namely Art when he needs to look at this again)
// Streaming Telemetries for Interfaces are not yet supported, so here's the plan:
// Use the EXACT query for BITS_ST_QUERY (via LightweightDataViewWrapper),
// then mutate the results (dividing the BITS by the total possible capacity) and hand to DataViewWrapper
