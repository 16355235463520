import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { adjustByGreekPrefix } from 'core/util';
import {
  InternalFilters,
  InternalFilterGroups,
  InboundFilters,
  InboundFilterGroups,
  OutboundFilters,
  OutboundFilterGroups,
  ThroughFilters,
  ThroughFilterGroups
} from 'app/stores/query/baseQueries/inboundOutboundInternalQuery';
import {
  InboundFilters as MkpInboundFilters,
  InboundFilterGroups as MkpInboundFilterGroups,
  OutboundFilters as MkpOutboundFilters,
  OutboundFilterGroups as MkpOutboundFilterGroups
} from 'app/stores/query/baseQueries/mkp/inboundOutboundQuery';
import { getAggregateUnitLabel } from 'app/util/aggregateLabels';
import { zeroToText } from 'app/util/utils';

import Tab from './Tab';

@inject('$app')
@observer
class Tabs extends Component {
  state = {
    tabName: null
  };

  constructor(props) {
    super(props);

    if (props.$app.isSubtenant) {
      this.tabFilters = {
        Inbound: { ...MkpInboundFilters, ...MkpInboundFilterGroups },
        Outbound: { ...MkpOutboundFilters, ...MkpOutboundFilterGroups }
      };
    } else {
      this.tabFilters = {
        Internal: { ...InternalFilters, ...InternalFilterGroups },
        Inbound: { ...InboundFilters, ...InboundFilterGroups },
        Outbound: { ...OutboundFilters, ...OutboundFilterGroups },
        Through: { ...ThroughFilters, ...ThroughFilterGroups }
      };
    }
  }

  handleTabChange = (tabName) => {
    const { onTabChange } = this.props;
    this.setState({ tabName }, () => onTabChange(tabName));
  };

  render() {
    const { data, lookback_seconds, queryModel, queryResults, aggregatePrefix, viewProps, noTrend } = this.props;
    const { tabName } = this.state;

    if (!queryResults) {
      return null;
    }

    const { prefix } = queryResults;
    const [aggregate] = queryModel.aggregates;

    const overlays = data.filter((series) => series.isOverlay);
    const filterDimensions = queryModel.get('filterDimensions.filterGroups');
    const filterDimensionSeries = filterDimensions.map((dimension) => {
      const row = data.find((series) => series.key.toLowerCase() === dimension.name.toLowerCase());

      if (!row) {
        return {
          key: dimension.name,
          name: dimension.name,
          title: dimension.name
        };
      }
      // mimic FBD rows
      row.key = dimension.name;
      row.name = dimension.name;
      row.title = dimension.name;

      return row;
    });

    return overlays.concat(filterDimensionSeries).map((series, index) => {
      let value = series[queryModel.get('outsort')] || null;
      if (value) {
        value = zeroToText(adjustByGreekPrefix(value, aggregatePrefix[queryModel.outsortUnit]), {
          fix: 0
        });
      }

      return (
        <Tab
          index={index}
          key={series.key}
          name={series.name}
          title={series.name}
          filters={this.tabFilters[series.name]}
          lookback_seconds={lookback_seconds}
          series={series}
          value={value}
          unit={getAggregateUnitLabel({ aggregate, prefix })}
          viewProps={viewProps}
          onTabChange={this.handleTabChange}
          active={tabName === series.name || (!tabName && index === 0)}
          noTrend={noTrend}
        />
      );
    });
  }
}

export default Tabs;
