import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('ProtocolAggregate'))
class ProtocolAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="Protocols"
        resultsKey="Proto"
        insightsQuery="fetchProtocolInsights"
        tableQueryProps={{
          metric: ['Proto']
        }}
        chartProps={{
          metrics: {
            Internal: 'Proto',
            Inbound: 'Proto',
            Outbound: 'Proto'
          }
        }}
      />
    );
  }
}

export default ProtocolAggregate;
