import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';

import { Callout, Card, Flex, Icon, Spinner, Tab, Tabs, Text } from 'core/components';
import { Field, formConsumer, InputGroup, Switch, TextArea } from 'core/form';

import MetricsItemExplorer from 'app/views/core/dashboards/dashboardItem/form/MetricsItemExplorer';
import PanelTitleHelpText from './PanelTitleHelpText';
import GuidedModeBehavior from './form/GuidedModeBehavior';
import NavigateDashboard from './form/NavigateDashboard';
import ItemExplorer from './form/ItemExplorer';
import DashboardSelector from '../DashboardSelector';

@inject('$dictionary', '$dashboards', '$dashboard', '$explorer')
@formConsumer
@observer
class DashboardItemForm extends Component {
  static defaultProps = {
    showDashboardSelect: true,
    showItemProperties: true,
    showDataviewConfiguration: true,
    p: 2
  };

  componentDidMount() {
    const { $dashboards, $explorer, form, model } = this.props;
    const dashboard = $dashboards.collection.get(form.getValue('dashboard_id'));
    const panel_type = model.get('panel_type');

    if (
      dashboard &&
      dashboard.get('parametric') &&
      panel_type === 'explorer_query' &&
      form.getValue('parametric_mode') !== 'ignore'
    ) {
      form.getField('parametric_overrides.filterField').setRules('required');
      form.getField('parametric_overrides.operator').setRules('required');
    }

    if (panel_type === 'explorer_query') {
      const overrides = {};

      if (dashboard) {
        const query = dashboard.get('query');

        if (!model.get('time_locked')) {
          overrides.lookback_seconds = query.lookback_seconds;
          overrides.starting_time = query.starting_time;
          overrides.ending_time = query.ending_time;
          overrides.time_format = query.time_format;
        }

        if (!model.get('device_locked')) {
          overrides.device_name = query.device_name || [];
          overrides.device_sites = query.device_sites || [];
          overrides.device_labels = query.device_labels || [];
          overrides.device_types = query.device_types || [];
          overrides.all_devices = query.all_devices;
        }

        if (model.get('filter_source') === 'dashboard') {
          overrides.filters = query.filters;
        }
      }

      $explorer.loadView(model, false, overrides);
    }
  }

  componentDidUpdate() {
    const { model } = this.props;

    if (model) {
      const queries = model.get('queries');
      if (queries && queries.length) {
        const timeLockedDisabled = queries[0].attributes.lookback_seconds === 0;
        this.handleTimeLockedField(timeLockedDisabled);
      }
    }
  }

  @action
  handleTimeLockedField = (timeLockedDisabled) => {
    const { form } = this.props;
    const field = form.getField('time_locked');

    field.options[1].disabled = !!timeLockedDisabled;

    field.options[1].helpText = timeLockedDisabled
      ? 'This option is only enabled when the panel is set to a Lookback Time Range'
      : "The time range represented in this panel's graph will be controlled by the panel's lookback timeframe.";

    if (timeLockedDisabled) {
      field.setValue(false);
    }
  };

  /**
   * Only gets used when DashboardItemModel.isNew, because otherwise this Form only appears
   * in the DashboardItemDetail dialog, which never changes Dashboards.
   */
  handleDashboardChange = (field, dashboardId) => {
    const { form, $dashboards, $dictionary, model } = this.props;
    const { queryFilters } = $dictionary.dictionary;

    const selectedDashboard = $dashboards.collection.get(dashboardId);
    const parametric = selectedDashboard.get('parametric') && form.getValue('parametric_mode') !== 'ignore';
    const parametricType = selectedDashboard.get('parametric_fields') && selectedDashboard.get('parametric_fields')[0];
    const { availableFilters } = this.getAvailableFiltersConfig(parametricType);
    const isExplorerQuery = model.get('panel_type') === 'explorer_query';

    form.getField('parametric_overrides.filterField').setRules(parametric && isExplorerQuery ? 'required' : '');
    form.getField('parametric_overrides.operator').setRules(parametric && isExplorerQuery ? 'required' : '');
    if (parametric) {
      form.setValue(
        'parametric_overrides.filterField',
        availableFilters.length ? availableFilters[0].value : undefined
      );
      form.setValue('parametric_overrides.operator', queryFilters.parametricOperators[parametricType.type]);
    }
  };

  getAvailableFiltersConfig = (parametricType) => {
    const { $dictionary, $dashboards, form, model: item } = this.props;

    const selectedDashboard = $dashboards.collection.get(form.getValue('dashboard_id'));
    const { activeFilterFields } = item.get('queries')[0];

    const { parametric } = selectedDashboard.get();
    const { parametric_mode } = form.getValues();

    // we disable the `override_specific` option if there are no filters in this Items existing filters
    // that can be overridden by the selected parametric type
    const availableFilters = $dictionary.getAvailableFiltersForParametricType(parametricType);
    const canOverrideSpecific = availableFilters.some((filter) => activeFilterFields.includes(filter.value));

    if (canOverrideSpecific) {
      if (parametric && parametric_mode === 'override_specific') {
        const filters = availableFilters.filter((filter) => activeFilterFields.includes(filter.value));
        return { availableFilters: filters, canOverrideSpecific };
      }
    }

    return { availableFilters, canOverrideSpecific };
  };

  render() {
    const {
      model,
      form,
      $dashboards,
      $dictionary,
      onQueryChange,
      showDashboardSelect,
      showItemProperties,
      showDataviewConfiguration,
      innerFormSetter,
      p
    } = this.props;

    const {
      filterOperatorOptions,
      standardFilterOperators,
      dictionary: { queryFilters }
    } = $dictionary;

    const parametric_mode = form.getValue('parametric_mode');
    const panel_type = model.get('panel_type');

    if (!model.fetchedQuery) {
      return (
        <Flex alignItems="center" justifyContent="center" p={p}>
          <Spinner />
        </Flex>
      );
    }

    let availableFiltersConfig;
    let parametric;
    let selectedDashboard;

    const formDashboard = form.getValue('dashboard_id');

    if (formDashboard) {
      selectedDashboard = $dashboards.collection.get(formDashboard);
      parametric = selectedDashboard && selectedDashboard.get('parametric');

      if (parametric) {
        const parametricFields = selectedDashboard.get('parametric_fields');
        const parametricType = parametricFields && parametricFields[0];
        availableFiltersConfig = this.getAvailableFiltersConfig(parametricType);
      }
    }

    const isExplorerPanel = panel_type === 'explorer_query';
    const isRawFlowPanel = panel_type === 'raw_flow';
    const isMetricsPanel = panel_type === 'metrics';

    const DataViewConfigurationForm = model.formState.component;

    const tabs = [
      isExplorerPanel && {
        id: 'query',
        group: 'dataview',
        title: 'Query',
        panel: <ItemExplorer dashboard={selectedDashboard} onQueryChange={onQueryChange} />,
        p: 0
      },
      isRawFlowPanel && {
        id: 'rawFlow',
        group: 'dataview',
        title: 'Query',
        panel: (
          <Card p={2} mt={2}>
            <DataViewConfigurationForm form={form} dashboardItem={model} />
          </Card>
        ),
        p: 0
      },
      parametric && {
        id: 'guided',
        group: 'parametric',
        title: 'Guided Mode',
        icon: 'chat',
        panel: (
          <GuidedModeBehavior
            availableFiltersConfig={availableFiltersConfig}
            parametric_mode={parametric_mode}
            filterOperatorOptions={filterOperatorOptions}
            standardFilterOperators={standardFilterOperators}
            queryFilters={queryFilters}
            form={form}
            boxProps={{}}
          />
        )
      },
      isExplorerPanel && {
        id: 'navigate',
        group: 'nestedDashboard',
        title: 'Navigate To',
        icon: 'arrow-right',
        panel: <NavigateDashboard />
      }
    ].filter((tab) => Boolean(tab));

    return (
      <Flex flexDirection="column" flexAuto p={p}>
        <Field
          name="panel_title"
          helpText={model.dataview.isFlowVisualization && <PanelTitleHelpText parametric={parametric} />}
          width={400}
          large
        >
          <InputGroup />
        </Field>

        <Field name="panel_description" mb={0} width={600} large>
          <TextArea rows={2} fill />
        </Field>

        {isExplorerPanel && (
          <Callout maxWidth={600} mt={2}>
            <Field name="panel_filtering" mb={0} large>
              <Switch showOptionLabel />
            </Field>
          </Callout>
        )}

        {showDashboardSelect && (
          <Card maxWidth={600} p={2} mt={2}>
            <Text fontWeight="bold" small>
              Dashboard this new panel will be added to
            </Text>
            <DashboardSelector
              name="dashboard_id"
              onChange={this.handleDashboardChange}
              onlyEditable
              showPresets={false}
              showLabel={false}
              mb={0}
              fill
              large
            />
          </Card>
        )}

        {model.formState.component && showDataviewConfiguration && !parametric && (
          <Card p={2} mt={2}>
            <DataViewConfigurationForm form={form} dashboardItem={model} />
          </Card>
        )}

        {isMetricsPanel && (
          <Card mt={2} overflow="hidden">
            <MetricsItemExplorer
              form={form}
              dashboardItem={model}
              dashboard={selectedDashboard}
              onQueryChange={onQueryChange}
              innerFormSetter={innerFormSetter}
            />
          </Card>
        )}

        {showItemProperties && (isExplorerPanel || (isRawFlowPanel && parametric)) && (
          <Tabs mt={3}>
            {tabs.map((tab) => (
              <Tab
                key={tab.id}
                id={tab.id}
                title={
                  <Flex alignItems="center" justifyContent="center" px={1}>
                    <Icon icon={tab.icon} mr={1} />
                    {tab.title}
                  </Flex>
                }
                panel={
                  <Card flex={1} p={tab.p !== undefined ? tab.p : 2}>
                    {tab.panel}
                  </Card>
                }
              />
            ))}
          </Tabs>
        )}
      </Flex>
    );
  }
}

export default DashboardItemForm;
