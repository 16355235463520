import React from 'react';
import styled from 'styled-components';
import { borderColor, borders, display, space } from 'styled-system';
import { Classes } from '@blueprintjs/core';

import position from './utils/position';
import size from './utils/size';
import flex from './utils/flex';

const DialogFooterStyled = styled.div`
  ${space};
  ${display};
  ${borders};
  ${borderColor};

  ${flex};
  ${position};
  ${size};
`;

const DialogFooter = ({ children, ...rest }) => (
  <DialogFooterStyled className={Classes.DIALOG_FOOTER} {...rest}>
    {children}
  </DialogFooterStyled>
);

DialogFooter.defaultProps = {
  // default blueprint styling is margin: 12px, we override it to 16px to match our design systems
  m: 2,
  display: 'flex',
  justifyContent: 'flex-end'
};

export default DialogFooter;
