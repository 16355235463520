import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { ButtonLink, Text } from 'core/components';
import { formConsumer, Field, InputGroup, Select } from 'core/form';

@formConsumer
@observer
class InterfaceField extends Component {
  handleReset = () => {
    const { form, fieldName, model } = this.props;
    form.setValue(fieldName, model.get(`initial_${fieldName}`));
  };

  render() {
    const { model, form, fieldName, options, childProps, ...rest } = this.props;
    const field = form.getField(fieldName);
    const overridden = !model.isManuallyAdded && model.overriddenFields.includes(fieldName);

    return (
      <>
        <Field
          name={fieldName}
          labelInfo={overridden ? ' (overridden)' : ''}
          options={options}
          mb={overridden ? 0 : 1}
          large
          {...rest}
        >
          {options || field.options ? <Select fill {...childProps} /> : <InputGroup />}
        </Field>
        {overridden && (
          <Text small muted as="div" mb={1} ml="2px">
            Original value: {model.get(`initial_${fieldName}`) || 'None'}
            <ButtonLink
              ml="4px"
              disabled={field.getValue() === model.get(`initial_${fieldName}`)}
              onClick={this.handleReset}
            >
              restore
            </ButtonLink>
          </Text>
        )}
      </>
    );
  }
}

export default InterfaceField;
