import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Box, Text } from 'core/components';
import FilterGroup from './FilterGroup';

@observer
class FiltersDisplay extends Component {
  render() {
    const { filters, useNmsDimensions } = this.props;
    const hasFilters = filters && filters.filterGroups && filters.filterGroups.length > 0;

    if (!hasFilters) {
      return (
        <Box {...this.props}>
          <Text muted>No filtering criteria has been applied</Text>
        </Box>
      );
    }

    /* eslint-disable react/no-array-index-key */
    return (
      <Box {...this.props}>
        {filters &&
          filters.filterGroups.map((group, index) => (
            <FilterGroup
              key={index}
              index={index}
              group={group}
              connector={filters.connector}
              isLastGroup={index === filters.filterGroups.length - 1}
              useNmsDimensions={useNmsDimensions}
            />
          ))}
      </Box>
    );
  }
}

export default FiltersDisplay;
