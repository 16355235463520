import React from 'react';
import { Tooltip as BlueprintTooltip } from '@blueprintjs/core';

const Tooltip = (props) => <BlueprintTooltip {...props} />;

Tooltip.displayName = 'Tooltip';

Tooltip.defaultProps = {
  modifiers: {
    offset: {
      enabled: true,
      offset: '0, 2px'
    }
  }
};

export default Tooltip;
