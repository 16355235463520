import React from 'react';
import { inject, observer } from 'mobx-react';
import sizeMe from 'react-sizeme';
import { debounce, omit } from 'lodash';
import { greekPrefix } from 'core/util';
import { hexToRgbA } from 'core/util/colors';
import { Box, Flex, FlexColumn, Heading, Text } from 'core/components';
import { addCommas } from 'app/util/utils';
import { getAggregateTypeLabel, getAggregateUnitLabel } from 'app/components/metric/metricRenderers';
import BaseDataview from './BaseDataview';
import { getAggregateRenderer } from './legend/legendRenderers';

const sizeMeHOC = sizeMe({
  monitorHeight: true,
  monitorWidth: true
});

const getLookbackText = (query, $dictionary) => {
  const lookback = query.get('lookback_seconds');
  if (!lookback || +lookback === 0) {
    const start = query.get('starting_time');
    const end = query.get('ending_time');
    return start && end ? `${start} to ${end}` : '';
  }
  return $dictionary.get('showLast')[lookback];
};

const getLastDataPointDurationLabel = (rawData) => {
  let label;
  const flowContainer = rawData && rawData[Object.keys(rawData)[0]];
  const flowArray = (flowContainer && flowContainer.flow) || [];
  if (flowArray.length) {
    const lastPointArray = flowArray[flowArray.length - 1];
    const duration = lastPointArray && lastPointArray.length && lastPointArray.length === 3 && lastPointArray[2];
    if (duration) {
      const mins = duration / 60;
      // if we didn't divide out evenly, it's an unexpected duration, just bail.
      if (mins % 1 === 0) {
        label = `Last ${mins > 1 ? `${mins} Minutes` : 'Minute'}`;
      }
    }
  }

  return label || 'Last Data Point';
};

const getSecondaryOutsortRowData = (query, row, lastDatapoint, unitsToPrefix) => {
  const outsortKey = query.get('secondaryOutsort');
  // short circuit right here if no secondaryOutsort.
  if (!outsortKey) {
    return undefined;
  }

  const isAggTotalOutsort = outsortKey.includes('agg_total');
  // filter out any aggregates that don't have label (they're just used for calculations, we don't need to care about them here.
  const aggregates = query.get('aggregates').filter((agg) => Object.prototype.hasOwnProperty.call(agg, 'label'));
  const aggregate = aggregates.find((agg) => agg.value === outsortKey);
  const outsortUnit = aggregate.unit;
  const value = row.get(lastDatapoint ? `${aggregate.column}__k_last` : outsortKey);
  const rawData = row.get('rawData');
  const prefix = {
    [outsortUnit]: unitsToPrefix.includes(outsortUnit) ? greekPrefix([value], 1) : ''
  };
  const displayUnits = getAggregateUnitLabel({ aggregate, prefix, useLineBreaks: false });
  let valueLabel;

  // if outsort is Total, use totalUnits dictionary to get display units (modified with prefix as appropriate)
  if (isAggTotalOutsort) {
    valueLabel = 'Total';
  } else {
    valueLabel = lastDatapoint
      ? getLastDataPointDurationLabel(rawData)
      : getAggregateTypeLabel({ aggregate, useLineBreaks: false });
  }

  return { aggregate, outsortUnit, value, prefix, displayUnits, valueLabel };
};

const getRowForMaxAggregationValue = ({ rows, aggregationName, tagKey, logsum }) => {
  const maxData = {};
  // reduce down to max info (for both excluded and non-excluded rows)
  rows.reduce((acc, row) => {
    const tagData = row.get(tagKey);
    const rawData = row.get('rawData');
    let rowKey = 'row';
    let sortKey = 'sort';
    if (rawData) {
      acc.rawData = rawData;
    }
    if (!tagData) {
      return acc;
    }

    if (tagData.isExcluded) {
      rowKey = `ex${rowKey}`;
      sortKey = `ex${sortKey}`;
    }
    const maxByValue =
      aggregationName.includes('__k_last') && logsum ? row.get('kt_intell_order__k_last') : row.get(aggregationName);
    if (maxByValue > (acc[sortKey] || Number.MIN_SAFE_INTEGER)) {
      acc[rowKey] = row;
      acc[sortKey] = maxByValue;
    }
    return acc;
  }, maxData);

  // mash in "rawData" field to this row if not present. It's only used in last data point duration calculation
  // so we don't really care what row it came from, just that _this_ row has it.
  const result = maxData.row ? maxData.row : maxData.exrow;
  if (!result.get('rawData')) {
    result.set('rawData', maxData.rawData);
  }

  return result;
};

const getGaugeRow = (tagKey, queryResults, lastDatapoint, aggregationName, logsum) => {
  const nonOverlays = queryResults.outsortNonOverlays;
  if (nonOverlays.length === 0) {
    // no overlays, look for total
    return queryResults.find({ key: 'Total' });
  }

  if (lastDatapoint) {
    return getRowForMaxAggregationValue({ rows: nonOverlays, aggregationName, tagKey, logsum });
  }

  // get first non-excluded overlay, should be max value for outsort
  const result = nonOverlays.find((row) => {
    const tk = row.get(tagKey);
    return tk && !tk.isExcluded;
  });

  // if all excluded, just use first excluded (max)
  return result || nonOverlays[0];
};

/**
 * Since Gauge is more restrictive with fix, we just run the renderedValue through this to chop it down more if needed.
 * @param rendererValue
 * @returns {*}
 */
const adjustFix = (rendererValue) => {
  const num = +rendererValue.toString().replace(',', '');
  if (Number.isNaN(num)) {
    return rendererValue;
  }
  let fix = 0;
  if (num < 1) {
    fix = 3;
  }
  if (num < 10) {
    fix = 2;
  } else if (num < 100) {
    fix = 1;
  }
  return addCommas(num.toFixed(fix));
};

@inject('$app', '$dictionary')
@observer
class Gauge extends BaseDataview {
  sync = false;

  gaugeRow = undefined;

  constructor(props) {
    super(props);
    window.addEventListener(
      'resize',
      debounce(() => this.reflow(), 100)
    );
  }

  redraw() {
    const { $app } = this.props;

    $app.renderSync(() => {
      this.dismissSpinner();
    });
  }

  reflow() {
    this.redraw();
  }

  renderSeries() {}

  renderOverlay() {}

  clear() {}

  onGaugeTitleClick = (e) => {
    const {
      onModelSelect,
      sourceLink: { model }
    } = this.props;

    if (onModelSelect && this.gaugeRow) {
      onModelSelect(this.gaugeRow, model, e.metaKey);
    }
  };

  getComponent() {
    const { $dictionary, dataview, onModelSelect, sourceLink, hasFooter, viewProps } = this.props;
    const { valueFontSize, metricFontSize } = viewProps;
    const unitsToPrefix = $dictionary.get('unitsToPrefix');
    const queryBucket = dataview.queryBuckets.activeBuckets[0];
    const { firstQuery, queryResults } = queryBucket;

    const outsort = firstQuery.outsortDataKey;
    const isAggTotalOutsort = outsort.includes('agg_total');

    const bracketOptions = firstQuery.get('bracketOptions') || {};
    const tagKey = bracketOptions.tagKey || '';
    const lastDatapoint = !!bracketOptions.lastDatapoint && !isAggTotalOutsort;
    // Outsort overrides lastDatapoint if outsort doesn't return a lastDatapoint value (agg_total)
    const aggregationName = bracketOptions.bracketAggregation || outsort;

    // filter out any aggregates that don't have label (they're just used for calculations, we don't need to care about them here.
    const aggregateTypes = firstQuery.get('aggregateTypes');
    const aggregates = firstQuery.aggregates.filter((agg) => aggregateTypes.includes(agg.value));
    let secondaryAggregates = [];
    const aggregate = firstQuery.outsortAggregate;

    let adjustedAggregationName = aggregationName;
    let logsum = false;
    if (lastDatapoint) {
      // Don't use outsortDataKey as that will mask sum_logsum
      logsum = firstQuery.get('outsort').startsWith('sum_logsum');
      adjustedAggregationName = `${aggregate.column}__k_last`;
    }

    const gaugeRow = getGaugeRow(tagKey, queryResults, lastDatapoint, adjustedAggregationName, logsum);
    if (!gaugeRow) {
      this.gaugeRow = undefined;
      return null;
    }

    const secondaryData = getSecondaryOutsortRowData(firstQuery, gaugeRow, lastDatapoint, unitsToPrefix);
    const tagData = gaugeRow.get(tagKey);
    const isBracketed = tagData && tagData.value;
    const gaugeValue = gaugeRow.get(adjustedAggregationName);
    const gaugeRawData = gaugeRow.get('rawData');
    const rowKey = gaugeRow.get('key').split('----').join('\u2192');

    this.gaugeRow = gaugeRow;

    // Don't use queryResult prefix object, use this one to reduce char count in gauge
    const prefix = { [aggregate.unit]: unitsToPrefix.includes(aggregate.unit) ? greekPrefix([gaugeValue], 1) : '' };
    if (aggregate.parentUnit && unitsToPrefix.includes(aggregate.parentUnit)) {
      prefix[aggregate.parentUnit] = greekPrefix([gaugeValue], 1);
    }

    if (secondaryData) {
      const { secondaryOutsortUnit } = firstQuery;
      secondaryAggregates = aggregates.filter((agg) => agg.unit === secondaryOutsortUnit);
      prefix[secondaryOutsortUnit] = unitsToPrefix.includes(secondaryOutsortUnit)
        ? greekPrefix(
            secondaryAggregates.map((agg) => gaugeRow.get(agg.value)),
            1
          )
        : '';
    }

    const displayUnits = getAggregateUnitLabel({ aggregate, prefix, useLineBreaks: false });
    let valueLabel;

    const additionalPrimaryAggregates = aggregates.filter(
      (agg) => agg.column === aggregate.column && (agg.value !== outsort || lastDatapoint)
    );

    const additionalSecondaryAggregates = secondaryAggregates.filter(
      (agg) => agg.value !== firstQuery.get('secondaryOutsort') || lastDatapoint
    );

    // if outsort is Total, use totalUnits dictionary to get display units (modified with prefix as appropriate)
    if (isAggTotalOutsort) {
      valueLabel = 'Total';
    } else {
      valueLabel = lastDatapoint
        ? getLastDataPointDurationLabel(gaugeRawData)
        : getAggregateTypeLabel({ aggregate, useLineBreaks: false });
    }

    const heightAdjustment = 73 + (secondaryData ? 95 : 0);
    const scale = Math.max(
      0.36,
      Math.min(1, this.props.size.width / 1000, (this.props.size.height - heightAdjustment) / 305)
    );

    const aggLabelStyle = {
      fontSize: 16,
      fontWeight: 400
    };

    const gaugeTitle = (
      <Text
        title={rowKey}
        color={isBracketed ? '#fff' : this.chartLabelColor}
        fontWeight="medium"
        fontSize={20}
        pt={2}
        px={2}
      >
        {onModelSelect && sourceLink.model.get('panel_filtering') && (
          // eslint-disable-next-line
          <a onClick={this.onGaugeTitleClick} color="white">
            {rowKey}
          </a>
        )}
        {(!onModelSelect || !sourceLink.model.get('panel_filtering')) && <span>{rowKey}</span>}
      </Text>
    );
    const marginBottom = hasFooter ? 16 : 0;
    const height = hasFooter ? 'calc(100% - 16px)' : '100%';

    return (
      <Flex
        bg={isBracketed ? tagData.value : undefined}
        height={height}
        width="100%"
        mb={marginBottom}
        borderRadius="0px 0px 2px 2px"
      >
        <Box>
          {gaugeTitle}
          <Box p={2} pt={0}>
            <Box
              color={isBracketed ? '#fff' : this.chartLabelColor}
              fontSize={(valueFontSize || 200) * scale}
              fontWeight="normal"
              mb="-0.08em"
            >
              {adjustFix(
                getAggregateRenderer({ aggregate, prefix, bucket: queryBucket })({ value: gaugeValue }),
                prefix
              )}
            </Box>
            <Box
              color={isBracketed ? 'rgba(255, 255, 255, 0.65)' : hexToRgbA(this.chartLabelColor, 0.65)}
              fontSize={(metricFontSize || 50) * scale}
            >
              {valueLabel} {displayUnits}
            </Box>
          </Box>

          {secondaryData && (
            <Box p={2} pt={0}>
              <Box fontSize={50} color={isBracketed ? '#fff' : this.chartLabelColor} mb={-8}>
                {adjustFix(
                  getAggregateRenderer({
                    aggregate: secondaryData.aggregate,
                    prefix: secondaryData.prefix,
                    bucket: queryBucket
                  })({ value: secondaryData.value }),
                  prefix
                )}
              </Box>
              <Box
                fontSize={18}
                color={isBracketed ? 'rgba(255, 255, 255, 0.65)' : hexToRgbA(this.chartLabelColor, 0.65)}
              >
                {secondaryData.valueLabel} {secondaryData.displayUnits}
              </Box>
            </Box>
          )}
        </Box>

        {!isAggTotalOutsort &&
          (additionalPrimaryAggregates.length !== 0 || additionalSecondaryAggregates.length !== 0) && (
            <FlexColumn p={2} minWidth={120} maxWidth={250} background="rgba(0, 0, 0, .15)">
              {additionalPrimaryAggregates.length !== 0 && (
                <Box mb={2}>
                  <Box mb={1}>
                    <Heading level={6} color={isBracketed ? '#fff' : this.chartLabelColor}>
                      <span>
                        {getLookbackText(firstQuery, $dictionary)}
                        <br />
                        {displayUnits}
                      </span>
                    </Heading>
                  </Box>

                  <Box>
                    {additionalPrimaryAggregates.map((agg, index) => {
                      const aggValueRenderer = getAggregateRenderer({ aggregate: agg, prefix, bucket: queryBucket });
                      return (
                        <Flex
                          mb={index !== aggregates.length - 1 ? 1 : 0}
                          justifyContent="space-between"
                          key={agg.value}
                          style={{ ...aggLabelStyle }}
                        >
                          <Box
                            mr={2}
                            color={isBracketed ? 'rgba(255, 255, 255, 0.65)' : hexToRgbA(this.chartLabelColor, 0.8)}
                          >
                            {getAggregateTypeLabel({ aggregate: agg, useLineBreaks: false })
                              .replace(' Percentile', '')
                              .replace('Average', 'Avg')}
                          </Box>
                          <Text fontWeight="bold" color={isBracketed ? '#fff' : this.chartLabelColor}>
                            {adjustFix(aggValueRenderer({ value: gaugeRow.get(agg.value) }), prefix)}
                          </Text>
                        </Flex>
                      );
                    })}
                  </Box>
                </Box>
              )}
              {additionalSecondaryAggregates.length !== 0 && (
                <Box>
                  <Box mb={1}>
                    <Heading level={6} color={isBracketed ? '#fff' : this.chartLabelColor}>
                      <span>
                        {getLookbackText(firstQuery, $dictionary)}
                        <br />
                        {secondaryData.displayUnits}
                      </span>
                    </Heading>
                  </Box>

                  <Box>
                    {additionalSecondaryAggregates.map((agg, index) => {
                      const aggValueRenderer = getAggregateRenderer({ aggregate: agg, prefix, bucket: queryBucket });

                      return (
                        <Flex
                          mb={index !== aggregates.length - 1 ? 1 : 0}
                          justifyContent="space-between"
                          key={agg.value}
                          style={{ ...aggLabelStyle }}
                        >
                          <Box
                            mr={2}
                            color={isBracketed ? 'rgba(255, 255, 255, 0.65)' : hexToRgbA(this.chartLabelColor, 0.8)}
                          >
                            {getAggregateTypeLabel({ aggregate: agg, useLineBreaks: false })
                              .replace(' Percentile', '')
                              .replace('Average', 'Avg')}
                          </Box>
                          <Text fontWeight="bold" color={isBracketed ? '#fff' : this.chartLabelColor}>
                            {adjustFix(aggValueRenderer({ value: gaugeRow.get(agg.value) }), prefix)}
                          </Text>
                        </Flex>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </FlexColumn>
          )}
      </Flex>
    );
  }
}

const SizeMe = sizeMeHOC(Gauge);
export default function SizedGauge(props) {
  return <SizeMe {...omit(props, 'size')} />;
}

const config = {
  showTotalTrafficOverlay: false,
  showHistoricalOverlay: false,
  showLegend: true,
  singleDataseries: true,
  timeBased: false,
  isSVG: false,
  enableToggle: false,
  allowsSecondaryOverlay: true,
  suppressSecondaryTopxSeparate: true,
  buckets: [
    {
      name: 'Gauge',
      secondaryOverlayBucket: 0
    }
  ]
};

export { config };
