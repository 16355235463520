import React, { Component } from 'react';
import { inject } from 'mobx-react';
import moment from 'moment';
import { debounce } from 'lodash';

import { Card, Heading, Highcharts, Suspense, Select, Flex, Box, Text, LinkButton, Icon, Tag } from 'core/components';
import { formatBytesGreek } from 'core/util';

const metricLabelMap = {
  min: 'Minimum',
  max: 'Maximum',
  p98: 'P98th',
  p95: 'P95th',
  p75: 'P75th',
  p50: 'P50th',
  p25: 'P25th',
  p05: 'P5th'
};

function capacityPlanValueRenderer(option) {
  return (
    <Box py="4px">
      <Text as="div" fontSize="small" color="muted" pb="1px">
        Capacity Plan
      </Text>
      <Text as="div" fontWeight="bold" fontSize="small">
        {option && option.label}
      </Text>
    </Box>
  );
}

@inject('$capacity', '$colors')
export default class InterfaceCapacityBitrate extends Component {
  state = {
    activePlan: undefined,
    loading: true,
    boxWhiskerIn: {},
    boxWhiskerOut: {},
    boxWhiskerMetrics: {}
  };

  chartColors = [];

  constructor(props) {
    const { $colors, plans } = props;
    const [plan] = plans;
    super(props);

    this.chartColors = [$colors.getTabColor('Inbound'), $colors.getTabColor('Outbound')];

    this.state.activePlan = plan.id;
  }

  componentDidMount() {
    this.fetchChartData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading, activePlan } = this.state;

    if (!loading && prevState.activePlan !== activePlan) {
      this.fetchChartData().then(() => this.updateChartData());
    }
  }

  componentWillUnmount() {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }
  }

  fetchChartData = () => {
    const { $capacity, selectedInterface } = this.props;
    const { activePlan } = this.state;
    return $capacity
      .fetchBoxAndWhisker({
        deviceId: selectedInterface.get('device_id'),
        snmpId: selectedInterface.get('snmp_id'),
        planId: activePlan
      })
      .then((boxWhiskerData) => {
        const { boxAndWhiskersIn, boxAndWhiskersOut, ...boxWhiskerMetrics } = boxWhiskerData;
        this.setState({
          boxWhiskerIn: boxAndWhiskersIn.filter((data) => Object.keys(data).length > 1),
          boxWhiskerOut: boxAndWhiskersOut.filter((data) => Object.keys(data).length > 1),
          boxWhiskerMetrics,
          loading: false
        });
      });
  };

  updateChartData = () => {
    this.chart.update({ series: this.getSeries() });
  };

  chartCallback = (chart) => {
    this.chart = chart.container ? chart : null;
    this.resizeListener = debounce(() => chart.reflow(), 200);
    window.addEventListener('resize', this.resizeListener);
  };

  getPlanOptions = () => {
    const { plans } = this.props;
    return plans.map((plan) => ({
      label: (
        <Flex flex={1} alignItems="center" justifyContent="space-between">
          <Text as="div" ellipsis width={190}>
            {plan.get('name')}
          </Text>

          <Box width={100}>
            <Tag small minimal mr={1}>
              {plan.get('thresholds').runout.strategy === 'monthOverMonth' ? 'MoM' : 'WoW'}
            </Tag>
            <Tag small minimal>
              {plan.get('thresholds').utilization.aggregate}
            </Tag>
          </Box>
        </Flex>
      ),
      value: plan.id
    }));
  };

  onPlanChange = (plan) => {
    this.setState({ activePlan: plan });
  };

  getSeries = () => {
    const { boxWhiskerIn, boxWhiskerOut, boxWhiskerMetrics } = this.state;
    const { chartColors } = this;
    return [
      {
        name: 'Inbound',
        data: boxWhiskerIn.map((data) => {
          const time = moment.utc(data.time).valueOf();
          return [
            time,
            data.bottomWhisker || 0,
            data.bottomBox || 0,
            data.middle || 0,
            data.topBox || 0,
            data.topWhisker || 0
          ];
        }),
        tooltip: {
          pointFormatter() {
            return (
              `<span style="color:${chartColors[0]}">\u25CF</span> <b> ${this.series.name}</b><br/>` +
              `${metricLabelMap[boxWhiskerMetrics.topWhiskerDesc]}: ${formatBytesGreek(this.high, 'bits/s')}<br/>` +
              `${metricLabelMap[boxWhiskerMetrics.topBoxDesc]}: ${formatBytesGreek(this.q3, 'bits/s')}<br/>` +
              `${metricLabelMap[boxWhiskerMetrics.middleDesc]}: ${formatBytesGreek(this.median, 'bits/s')}<br/>` +
              `${metricLabelMap[boxWhiskerMetrics.bottomBoxDesc]}: ${formatBytesGreek(this.q1, 'bits/s')}<br/>` +
              `${metricLabelMap[boxWhiskerMetrics.bottomWhiskerDesc]}: ${formatBytesGreek(this.low, 'bits/s')}<br/>`
            );
          }
        }
      },
      {
        name: 'Outbound',
        data: boxWhiskerOut.map((data) => {
          const time = moment.utc(data.time).valueOf();
          return [
            time,
            data.bottomWhisker || 0,
            data.bottomBox || 0,
            data.middle || 0,
            data.topBox || 0,
            data.topWhisker || 0
          ];
        }),
        tooltip: {
          pointFormatter() {
            return (
              `<span style="color:${chartColors[1]}">\u25CF</span> <b> ${this.series.name}</b><br/>` +
              `${metricLabelMap[boxWhiskerMetrics.topWhiskerDesc]}: ${formatBytesGreek(this.high, 'bits/s')}<br/>` +
              `${metricLabelMap[boxWhiskerMetrics.topBoxDesc]}: ${formatBytesGreek(this.q3, 'bits/s')}<br/>` +
              `${metricLabelMap[boxWhiskerMetrics.middleDesc]}: ${formatBytesGreek(this.median, 'bits/s')}<br/>` +
              `${metricLabelMap[boxWhiskerMetrics.bottomBoxDesc]}: ${formatBytesGreek(this.q1, 'bits/s')}<br/>` +
              `${metricLabelMap[boxWhiskerMetrics.bottomWhiskerDesc]}: ${formatBytesGreek(this.low, 'bits/s')}<br/>`
            );
          }
        }
      }
    ];
  };

  getBitrateChart = () => {
    const { activePlan } = this.state;
    const { selectedInterface } = this.props;
    const chartOptions = {
      chart: {
        type: 'boxplot'
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%m/%e',
          minute: '%H:%M'
        }
      },
      yAxis: [
        {
          type: 'logarithmic',
          custom: { allowNegativeLog: true },
          unit: 'G',
          title: {
            text: 'Bitrate'
          },
          floor: 0,
          plotLines: [
            {
              id: 'capacity-line',
              className: 'capacity-level',
              label: {
                text: `Capacity (${selectedInterface.capacityLabel})`,
                align: 'left',
                x: -1,
                y: 15,
                className: 'capacity-level'
              },
              value: selectedInterface.get('snmp_speed') * 1e6,
              width: 2
            }
          ]
        }
      ],
      plotOptions: {
        series: {
          marker: {
            enabled: false
          }
        }
      },
      legend: { enabled: false },
      tooltip: {
        xDateFormat: '%Y-%m-%d',
        shared: true,
        crosshairs: true
      },

      series: this.getSeries()
    };

    return (
      <>
        <Flex justifyContent="space-between" pt={1} px={1}>
          <Heading level={6} mb={0}>
            <Icon icon="full-circle" color={this.chartColors[0]} iconSize={16} mr="4px" />
            <Text>Inbound and </Text>
            <Icon icon="full-circle" color={this.chartColors[1]} iconSize={16} mr="4px" />
            Outbound Bitrate
          </Heading>

          <Box width={300}>
            <Select
              fill
              small
              options={this.getPlanOptions()}
              values={[activePlan]}
              onChange={this.onPlanChange}
              valueRenderer={capacityPlanValueRenderer}
            />
          </Box>
        </Flex>
        <Highcharts
          callback={this.chartCallback}
          options={chartOptions}
          height={300}
          width="100%"
          colors={this.chartColors}
        />
        <Flex justifyContent="flex-end">
          <LinkButton small minimal to={`/v4/core/capacity/${activePlan}`} text="View Capacity Plan" />
        </Flex>
      </>
    );
  };

  render() {
    const { loading } = this.state;
    return <Suspense loading={loading}>{!loading && <Card p={1}>{this.getBitrateChart()}</Card>}</Suspense>;
  }
}
