import { forceSimulation, forceLink, forceManyBody, forceCollide, forceX, forceY } from 'd3-force';
import { interpolate } from 'd3-interpolate';
import { polygonCentroid } from 'd3-polygon';
import { line } from 'd3-shape';

export const valueLine = line()
  .x((d) => d[0])
  .y((d) => d[1]);

// scale a node/element to fit a give width and height
export const rescaleNode = (node, width, height, extent = [0.01, 8]) => {
  const bounds = node.getBBox();

  return Math.max(extent[0], Math.min(extent[1], 0.9 / Math.max(bounds.width / width, bounds.height / height)));
};

export const centerElement = (element, elementScale, containerWidth, containerHeight) => {
  const elementBbox = element.getBBox();

  // OMG the math here took me forever!
  const centerX = (containerWidth - elementBbox.width * elementScale) / 2 - elementBbox.x * elementScale;
  const centerY = (containerHeight - elementBbox.height * elementScale) / 2 - elementBbox.y * elementScale;

  return [centerX, centerY];
};

export const tweenXY =
  (xY, endValue = undefined, includeFixed = true) =>
  (node) => {
    const tween = interpolate(node[xY], endValue || node.original[xY]);

    return (step) => {
      node[xY] = tween(step);
      if (includeFixed) {
        node[`f${xY}`] = tween(step);
      }
    };
  };

// add links in between each node in a group in order to keep them closer together
export const addInterGroupLinks = (groups, nodes) => {
  const newLinks = [];

  const buildNodeLinks = (groupNodes) =>
    groupNodes.forEach((node, idx) => {
      if (idx > 0) {
        newLinks.push({ source: groupNodes[0].id, target: node.id, hidden: true });
      }
    });

  Object.keys(groups).forEach((key) => {
    const group = groups[key];
    buildNodeLinks(nodes.filter((node) => node.siteId === group.id));
  });

  return newLinks;
};

// draw a rectangular path around a group of nodes
export const polygonGenerator = (nodes, collapsed = false) => {
  let minX = Infinity;
  let maxX = -Infinity;
  let minY = Infinity;
  let maxY = -Infinity;

  let padding = { t: 10, r: 0, b: 0, l: 0 };

  if (collapsed) {
    padding = { t: -5, r: -10, b: -5, l: -10 };
  }

  nodes.each((node, idx, svgNodes) => {
    const bounds = svgNodes[idx].getBBox();
    const height = Math.max(bounds.height / 2, 15);
    const width = Math.max(bounds.width / 2, 27);

    minX = Math.min(node.x - width - padding.r, minX);
    minY = Math.min(node.y - height - padding.t, minY);
    maxX = Math.max(node.x + width + padding.l, maxX);
    maxY = Math.max(node.y + height + padding.b, maxY);
  });

  return [
    [maxX, maxY],
    [maxX, minY],
    [minX, minY],
    [minX, maxY],
    [maxX, maxY]
  ];
};

export const getPolygonInfo = (polygon, collapsed) => {
  const centroid = polygon ? polygonCentroid(polygon) : [0, 0];
  const scaleFactor = collapsed ? 1 : 1.1;

  return {
    pathD: polygon ? valueLine(polygon.map((point) => [point[0] - centroid[0], point[1] - centroid[1]])) : '',
    height: polygon ? polygon[0][1] - polygon[1][1] : 0,
    centroid,
    scaleFactor
  };
};

export const getCenterOfNodes = (nodes) => {
  let minX = Infinity;
  let maxX = -Infinity;
  let minY = Infinity;
  let maxY = -Infinity;
  let center;

  if (Object.values(nodes).some((node) => node.fx !== undefined)) {
    nodes.forEach((node) => {
      if (node.fx) {
        minX = Math.min(node.fx, minX);
        maxX = Math.max(node.fx, maxX);
      }
      if (node.fy) {
        minY = Math.min(node.fy, minY);
        maxY = Math.max(node.fy, maxY);
      }
    });

    center = [maxX - (maxX - minX) / 2, maxY - (maxY - minY) / 2];
  }

  return center;
};

export const clampNumber = (nbr, low, high, adjust = 0) => {
  let clampedNbr;

  if (low > high) {
    [high, low] = [low, high];
  }

  clampedNbr = Math.min(Math.max(nbr, low), high);

  if (adjust !== 0) {
    clampedNbr += adjust;
  }

  return clampedNbr;
};

// https://github.com/d3/d3-force
export const createForceSimulation = ({
  width,
  height,
  center = [],
  distanceInGroup = 50, // distance between nodes in the same group
  distanceOutOfGroup = 400, // distance between nodes in different groups
  linkStrengthInGroup = 1, // strength applied to links in the same group
  linkStrengthOutOfGroup = 0.5 // strength applied to links between different groups
}) => {
  const collideRadius = 80; // keep nodes to a minimum of X units apart from each other
  const centerPoint = [center[0] || width / 2, center[1] || height / 2];

  const getLinkDistance = (link) =>
    link.type === 'provider' || (link.source.group && link.source.group === link.target.group)
      ? distanceInGroup
      : distanceOutOfGroup;

  const getLinkStrength = (link) =>
    link.source.group && link.source.group === link.target.group ? linkStrengthInGroup : linkStrengthOutOfGroup;

  const simulation = forceSimulation()
    .force(
      'link',
      forceLink()
        .id((d) => d.id)
        .distance(getLinkDistance)
        .strength(getLinkStrength)
    )
    .force('collide', forceCollide().radius(collideRadius))
    .force('charge', forceManyBody())
    .force('x', forceX(centerPoint[0]))
    .force('y', forceY(centerPoint[1]));

  return simulation;
};
