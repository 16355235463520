import React from 'react';
import { observer } from 'mobx-react';

import { Button, Dialog } from 'core/components';

const ModalSelectFooter = (props) => {
  const { field, multi, closeOnSelect, saveButtonText = 'Save', selectState } = props;
  if (field.closeOnSelect === true || closeOnSelect === true || multi === false) {
    return null;
  }

  return (
    <Dialog.Footer>
      <Button text="Cancel" onClick={selectState.resetToLastOpened} mr={1} width={110} />
      <Button
        intent="primary"
        onClick={selectState.saveOptions}
        disabled={field.hasError}
        text={saveButtonText}
        minWidth={110}
      />
    </Dialog.Footer>
  );
};

export default observer(ModalSelectFooter);
