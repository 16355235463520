import Promise from 'bluebird';
import {
  AggregationTemporality,
  MetricReader,
  InstrumentType,
  Aggregation,
  ExplicitBucketHistogramAggregation
} from '@opentelemetry/sdk-metrics';

import api from 'core/util/api';

const METRIC_EXPORT_INTERVAL = 10000; // 10 seconds
// time buckets in seconds
const histogramAggregation = new ExplicitBucketHistogramAggregation([1, 5, 10], true);

const aggregationTemporalitySelector = () => AggregationTemporality.DELTA;

const aggregationSelector = (instrumentType) => {
  switch (instrumentType) {
    case InstrumentType.HISTOGRAM: {
      return histogramAggregation;
    }
    default: {
      return Aggregation.Default();
    }
  }
};

export class JsonMetricExporter extends MetricReader {
  _url = '/api/ui/metrics/browser';

  constructor(options) {
    super({
      aggregationTemporalitySelector,
      aggregationSelector,
      ...options
    });
  }

  onInitialized() {
    this._interval = setInterval(() => {
      this._sendMetrics();
    }, METRIC_EXPORT_INTERVAL);
  }

  _checkDelta(data) {
    let hasData = false;
    data.forEach((scope) => {
      scope.metrics.forEach((metric) => {
        if (metric.dataPoints.length > 0) {
          hasData = true;
        }
      });
    });
    return hasData;
  }

  _sendMetrics() {
    return Promise.resolve(this.collect()).then((result) => {
      const { resourceMetrics, errors } = result;

      if (errors.length) {
        console.error('uiMetricsExporter: metrics collection errors', ...errors);
      }
      if (this._checkDelta(resourceMetrics.scopeMetrics)) {
        return api
          .post(this._url, { data: resourceMetrics.scopeMetrics, noRedirect: true, showErrorToast: false })
          .catch((err) => {
            console.error('uiMetricsExporter: cannot send', err.message);
          });
      }
      return Promise.resolve();
    });
  }

  onShutdown() {
    if (this._interval) {
      clearInterval(this._interval);
    }
    return Promise.resolve();
  }

  onForceFlush() {
    return Promise.resolve();
  }
}
