import React from 'react';
import { inject } from 'mobx-react';
import { Flex, Text } from 'core/components';
import DeviceSubtypeIcon from './DeviceSubtypeIcon';

const DeviceSubtypeLabel = ({ type, showIcon = true, iconProps, labelProps, boxProps, $dictionary }) => {
  const subtype = $dictionary.get('device_subtypes')[type];
  return (
    <Flex alignItems="center" overflow="hidden" {...boxProps}>
      {showIcon && <DeviceSubtypeIcon type={type} style={{ width: 16, marginRight: 4 }} {...iconProps} />}
      <Text {...labelProps} ellipsis>
        {subtype ? subtype.display_name : type}
      </Text>
    </Flex>
  );
};

export default inject('$dictionary')(DeviceSubtypeLabel);
