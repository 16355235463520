import styled from 'styled-components';
import { space, width, color, display } from 'styled-system';
import { ButtonGroup as BlueprintButtonGroup } from '@blueprintjs/core';

const ButtonGroup = styled(BlueprintButtonGroup)`
  ${space};
  ${width};
  ${color};
  ${display};
  flex-wrap: ${(props) => props.wrap};
`;

export default ButtonGroup;
