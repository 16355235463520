import React from 'react';
import styled, { withTheme } from 'styled-components';
import { space, opacity } from 'styled-system';
import { Classes, Icon as BlueprintIcon } from '@blueprintjs/core';
import { BsExclamationOctagonFill, BsExclamationTriangleFill } from 'react-icons/bs';
import { FaCheckCircle } from 'react-icons/fa';
import { FiInfo, FiShare2 } from 'react-icons/fi';

import get from 'lodash/get';
import classNames from 'classnames';
import position from './utils/position';

// because core components don't like referencing things in app
const OpenApplication = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0H15C15.55 0 16 0.45 16 1V11C16 11.55 15.55 12 15 12H8V10H14V3H6V8H4V1C4 0.45 4.45 0 5 0ZM7.5 5H12.5C12.78 5 13 4.78 13 4.5C13 4.22 12.78 4 12.5 4H7.5C7.22 4 7 4.22 7 4.5C7 4.78 7.22 5 7.5 5ZM7.5 7H9.5C9.78 7 10 6.78 10 6.5C10 6.22 9.78 6 9.5 6H7.5C7.22 6 7 6.22 7 6.5C7 6.78 7.22 7 7.5 7ZM11 8.5C11 8.78 10.835 9 10.625 9H8.375C8.165 9 8 8.78 8 8.5C8 8.22 8.165 8 8.375 8H10.625C10.835 8 11 8.22 11 8.5ZM5 14C5 14.55 5.45 15 6 15C6.55 15 7 14.55 7 14V10C7 9.45 6.55 9 6 9H2C1.45 9 1 9.45 1 10C1 10.55 1.45 11 2 11H3.59L0.3 14.29C0.11 14.47 0 14.72 0 15C0 15.55 0.45 16 1 16C1.28 16 1.53 15.89 1.71 15.71L5 12.41V14Z"
      fill="currentColor"
    />
  </svg>
);

// this is me changing the name of the icon so it makes more sense
// this icon is known as "share" in blueprint, we could potentially import the string for "d", but not the full icon
const NewTab = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.99 13.99h-9v-9h4.76l2-2H.99c-.55 0-1 .45-1 1v11c0 .55.45 1 1 1h11c.55 0 1-.45 1-1V7.24l-2 2v4.75zm4-14h-5c-.55 0-1 .45-1 1s.45 1 1 1h2.59L7.29 7.28a1 1 0 00-.3.71 1.003 1.003 0 001.71.71l5.29-5.29V6c0 .55.45 1 1 1s1-.45 1-1V1c0-.56-.45-1.01-1-1.01z"
      fill="currentColor"
    />
  </svg>
);

/**
 * We support the class `icon="BLUEPRINT_ICON_NAME" as well as using `icon={<MdAssessment />}`. All icons
 * can be imported from the react-icons package. See examples here:
 * https://react-icons.netlify.com/#/icons/md
 */

/*
 * Overriding some of the BP icons to use material icons for consistency
 */
const OVERRIDES = {
  error: BsExclamationOctagonFill,
  'warning-sign': BsExclamationTriangleFill,
  'info-sign': FiInfo,
  share: FiShare2,
  success: FaCheckCircle,
  'open-application': OpenApplication,
  'new-tab': NewTab
};

const StyledIcon = styled(BlueprintIcon)`
  ${space};
  ${opacity};
`;

// TODO: need to remove space and position here because it causes inconsistencies since BP doesn't support any of this
const Wrap = styled.div`
  display: inline-block;
  ${space};
  ${position};

  &.${Classes.ICON} {
    color: ${(props) => props.color} !important;
    fill: ${(props) => props.fill} !important;
    stroke: ${(props) => props.stroke} !important;
  }

  svg {
    fill: ${(props) => props.fill} !important;
  }
`;

function Icon(props) {
  const { cursor, icon, iconSize, color, stroke, fill, className, theme, ...rest } = props;
  const finalIcon = OVERRIDES[icon] || icon;
  const cssColor = color ? get(theme.colors, color) || color : undefined;

  if (icon === undefined) {
    return null;
  }

  if (React.isValidElement(finalIcon)) {
    return React.cloneElement(finalIcon, {
      size: iconSize,
      cursor,
      color: color || undefined,
      className,
      iconSize,
      width: iconSize,
      height: iconSize,
      stroke,
      fill
    });
  }

  if (typeof finalIcon === 'string') {
    return <StyledIcon {...rest} icon={icon} iconSize={iconSize} color={cssColor} className={className} />;
  }

  if (typeof finalIcon === 'function') {
    const FinalIcon = finalIcon;
    return (
      <Wrap {...props} className={classNames(Classes.ICON, className)} color={cssColor}>
        <FinalIcon size={iconSize} width={iconSize} height={iconSize} />
      </Wrap>
    );
  }

  console.warn('Unable to render icon', finalIcon);
  return null;
}

Icon.defaultProps = {
  iconSize: 16
};

export default withTheme(Icon);
