/**
 * Note: Do not use this non-form component in your form. Use the core/form/components/Checkbox instead.
 */
import React from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { space, width } from 'styled-system';
import { Classes, Checkbox as BlueprintCheckbox } from '@blueprintjs/core';

const StyledCheckbox = styled(BlueprintCheckbox)`
  &.${Classes.CONTROL} {
    ${space};
    ${width};
  }

  // the indeterminate state is currently very hard to distinguish from the checked state
  // in large lists, so we're graying it out to make it more obvious
  &.bp4-control.bp4-checkbox input:indeterminate ~ .bp4-control-indicator {
    background-image: none;
    filter: grayscale(1);
  }

  /* remove 6px right margin when there's no label */
  ${(props) =>
    !props.labelElement &&
    !props.label &&
    css`
      padding-left: 16px;

      .bp4-control-indicator {
        margin-left: -16px;
        margin-right: 0;
      }
    `}
`;

const Checkbox = observer((props) => {
  const { onChange, className, small, readOnly, ...rest } = props;

  const handleChange = (e) => {
    if (!readOnly) {
      onChange(e.target.checked);
    }
  };

  return (
    <StyledCheckbox {...rest} className={classNames(className, { [Classes.SMALL]: small })} onChange={handleChange} />
  );
});

Checkbox.displayName = 'Checkbox';

Checkbox.defaultProps = {
  m: 0,
  mb: '2px',
  onChange: () => {}
};

export default Checkbox;
