import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, Icon, Select, Text } from 'core/components';

@inject('$capacity')
@observer
export default class SeverityFilters extends Component {
  valueRenderer = (props) => {
    const { className, color, icon, key, label, style, value } = props;
    return (
      <Flex key={key || value} className={className} style={style}>
        <Icon pr={1} icon={icon} color={color} />
        <Text>{label}</Text>
      </Flex>
    );
  };

  optionRenderer = (props) => {
    const { className, color, disabled, icon, key, label, selectItem, selected, style, value } = props;
    const onClick = !selected && !disabled ? () => selectItem(value) : undefined;

    return (
      <Flex key={key || value} className={className} onClick={onClick} style={style}>
        <Icon pr={1} icon={icon} color={color} />
        <Text>{label}</Text>
      </Flex>
    );
  };

  render() {
    const { $capacity, onChange, selectedFilter } = this.props;
    return (
      <Select
        valueRenderer={this.valueRenderer}
        optionRenderer={this.optionRenderer}
        width="310px"
        multi
        placeholder="Select severities"
        values={selectedFilter}
        options={Object.values($capacity.severityTypes)}
        onChange={onChange}
      />
    );
  }
}
