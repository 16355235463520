import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'core/components';
import { formConsumer } from 'core/form';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import Legend from 'app/components/dataviews/views/legend/Legend';
import ExplorerQueryModel from 'app/stores/query/ExplorerQueryModel';
import ItemExplorerOptions from './ItemExplorerOptions';

@inject('$dataviews', '$explorer', '$setup')
@formConsumer
@observer
export default class ItemExplorer extends Component {
  constructor(props) {
    super(props);
    const { model } = props;
    // eslint-disable-next-line react/state-in-constructor
    this.state = { sourceLink: { type: 'dashboard', model } };
  }

  render() {
    const { $dataviews, $explorer, $setup, dashboard, onQueryChange, model } = this.props;
    const { dataview } = $explorer;
    const { selectedQuery } = dataview.queryBuckets;
    if (!selectedQuery) {
      return null;
    }
    const { sourceLink } = this.state;
    const modelOverrides = {};
    if ($explorer.queryModel) {
      const isFirstRender =
        $explorer.queryModel.get('time_locked') === undefined ||
        $explorer.queryModel.get('device_locked') === undefined;
      const currentModel = isFirstRender ? model : $explorer.queryModel;
      Object.assign(modelOverrides, {
        time_locked: currentModel.get('time_locked'),
        device_locked: currentModel.get('device_locked'),
        filter_source: currentModel.get('filter_source')
      });
    }
    $explorer.queryModel = ExplorerQueryModel.createFromQueryModel(selectedQuery);
    $explorer.queryModel.set(modelOverrides);

    const viewCfg = $dataviews.getConfig(dataview.viewType);
    const isLeftSidebar = $setup.getSettings('layout.drawerLocation') === 'left';
    const flexDirection = isLeftSidebar ? 'row-reverse' : 'row';
    const sidebarBorders = { [isLeftSidebar ? 'borderRight' : 'borderLeft']: 'thin' };

    return (
      <Flex flexDirection={flexDirection} flex={1} overflow="hidden">
        <Flex flexDirection="column" flex={1} overflow="hidden">
          <Box height={480}>
            <DataViewWrapper
              dataview={dataview}
              sourceLink={sourceLink}
              headerProps={{
                shouldArrangeVertically: true
              }}
              fitToHeight
              viewProps={{
                height: 400,
                showNativeLegend: false,
                forceLoad: !$explorer.dataview.loading
              }}
            >
              {({ component, header, footer }) => (
                <>
                  <Box m={2}>{header}</Box>
                  <Flex flex={1} overflow="auto">
                    {component}
                  </Flex>
                  <Box m={2} mt={0}>
                    {footer}
                  </Box>
                </>
              )}
            </DataViewWrapper>
          </Box>

          {viewCfg.showLegend && (
            <Flex flex={1} minHeight={280}>
              <Legend dataview={dataview} />
            </Flex>
          )}
        </Flex>

        <Box {...sidebarBorders} mb="-1px">
          <ItemExplorerOptions model={$explorer.queryModel} dashboard={dashboard} onQueryChange={onQueryChange} />
        </Box>
      </Flex>
    );
  }
}
