/** NOTE: This component is shared with MKP!
 * Prevent regressions by spoofing as a tenant when testing. */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Flex, Heading, Link, Button, Icon, Text, Callout, Box } from 'core/components';
import CopyToClipboardButton from 'app/components/CopyToClipboardButton';
import LabelValue from 'app/views/metrics/components/LabelValue';
import { fromNow } from 'core/util/dateUtils';
import CommentList from 'app/components/comments/CommentList';
import { FiCopy, FiUsers } from 'react-icons/fi';
import PolicyLink from 'app/components/links/PolicyLink';
import TenantLink from 'app/components/links/TenantLink';
import { AlertAckChip } from '../AlertAckChip';
import AlertSeverityHistory from '../AlertSeverityHistory';

import AlertingSidebarItem from './AlertingSidebarItem';
import MitigationDetails from '../MitigationDetails';
import AlertOverview from './AlertOverview';
import AlertActions from './AlertActions';

@inject('$app', '$mkp')
@withRouter
@observer
class AlertingSidebar extends Component {
  get tenantModelForSubpolicy() {
    const { $mkp, policy } = this.props;

    return policy?.id && $mkp.tenants.subpolicyIdToTenant[policy.id];
  }

  handleViewPolicyAlerts = () => {
    const { policy, history } = this.props;

    if (policy.ruleId) {
      const filters = {
        ruleIds: [policy.ruleId],
        lookback: 3600 * 24 * 7, // 7 day lookback
        states: ['clear', 'alarm']
      };

      history.push('/v4/alerting/', { filters });
    }
  };

  render() {
    const { $app, model, device, policy, policyAlarmCount, severityHistory } = this.props;

    const policyId = !Number.isNaN(policy.id) && policy.id;

    return (
      <Flex flexDirection="column" width={300} m={0} p={3} pr={0} borderLeft="thin">
        {model.isAcked && (
          <Box mb={2}>
            <AlertAckChip model={model} />
          </Box>
        )}

        {!$app.isSubtenant && this.tenantModelForSubpolicy && (
          <AlertingSidebarItem mb={2} icon={FiUsers} iconProps={{ ml: '2px', iconSize: 16 }} label="Tenant">
            <TenantLink
              id={this.tenantModelForSubpolicy.id}
              alignText="left"
              label={this.tenantModelForSubpolicy.get('name')}
            />
          </AlertingSidebarItem>
        )}

        <Flex flexDirection="column" gap={3} flex={1}>
          <Flex flexDirection="column" gap={1}>
            <Flex alignItems="center" gap={2}>
              <Heading level={5} m={0}>
                Alert Overview
              </Heading>
              <CopyToClipboardButton text={model.get('id')}>
                <Button text="Copy Alert ID" icon={FiCopy} iconSize={12} intent="primary" small minimal />
              </CopyToClipboardButton>
            </Flex>
            <AlertOverview alertModel={model} linkToDetail={false} labelWidth={125} />
            {model.mitigationID && (
              <AlertingSidebarItem
                mt={2}
                label="Mitigation Details"
                value={<MitigationDetails model={model} showHeading={false} />}
              />
            )}
          </Flex>
          {device ? (
            <Flex flexDirection="column" gap={1}>
              <Flex alignItems="flex-end" gap={2}>
                <Heading level={5} m={0}>
                  Device
                </Heading>
                <Link small to={`/v4/nms/devices/${device.id}`}>
                  View Details
                </Link>
              </Flex>
              <LabelValue label="Name" value={device.get('name')} labelWidth={125} />
              {device.get('site.title') && (
                <LabelValue label="Site" value={device.get('site.title')} labelWidth={125} />
              )}
              <LabelValue label="IP Address" value={device.get('ip_address')} labelWidth={125} />
              <LabelValue label="Model" value={device.get('model')} labelWidth={125} />
              <LabelValue label="Manufacturer" value={device.get('vendor')} labelWidth={125} />
              {device.get('serial_number') && (
                <LabelValue label="Serial Number" value={device.get('serial_number')} labelWidth={125} />
              )}
            </Flex>
          ) : null}
          {policyId ? (
            <Flex flexDirection="column" gap={1}>
              <Flex alignItems="flex-end" gap={2}>
                <Heading level={5} m={0}>
                  Policy
                </Heading>
                {!$app.isSubtenant && (
                  <PolicyLink small id={policy.id}>
                    Edit Policy
                  </PolicyLink>
                )}
              </Flex>
              {model.policyChanged && (
                <Callout intent="warning" p="12px" my={1}>
                  <Flex gap={1}>
                    <Icon icon="warning-sign" color="warning" />
                    <Text as="div" fontSize={13} lineHeight="auto">
                      This policy has been changed. The threshold may have been modified since this alert started.
                    </Text>
                  </Flex>
                </Callout>
              )}
              <LabelValue label="Name" value={policy.get('name')} labelWidth={125} />
              <LabelValue
                label="Last Edited"
                value={fromNow(model.isNmsPolicy ? policy.versionCreatedAt : policy.get('lastEditTime'))}
                labelWidth={125}
              />
              <LabelValue
                label="Alerts (7 days)"
                value={
                  <Text
                    as="a"
                    color="darkGray5"
                    fontWeight="bold"
                    onClick={this.handleViewPolicyAlerts}
                    rightIcon="arrow-right"
                  >
                    {policyAlarmCount.toLocaleString()}
                    <Icon icon="arrow-right" iconSize={12} color="muted" ml="5px" mb="2px" />
                  </Text>
                }
                labelWidth={125}
              />
            </Flex>
          ) : (
            <Flex flexDirection="column" gap={1}>
              <Heading level={5} m={0} color="muted">
                Policy Not Found
              </Heading>
              <Text muted>The policy for this alert was deleted.</Text>
            </Flex>
          )}

          {severityHistory && severityHistory.length > 1 && (
            <Box>
              <Heading level={5} mb={1}>
                Severity History
              </Heading>
              <AlertSeverityHistory severityHistory={severityHistory} />
            </Box>
          )}

          {!$app.isSubtenant && (
            <Box>
              <CommentList entityId={model.id} entityType="alert" model={model} addReply />
            </Box>
          )}

          <Flex flexDirection="column" gap={1} mb={0}>
            <Heading level={5} m={0}>
              Take Action
            </Heading>

            <AlertActions
              alertModel={model}
              isSubtenant={$app.isSubtenant}
              tenant={this.tenantModelForSubpolicy}
              showDetailsLink={false}
              showPolicyLink={false}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

export default AlertingSidebar;
