import { uriToObject, getCustomProperties } from 'shared/util/map';

export function getInterconnectAttachmentMetadata(data) {
  const customProperties = getCustomProperties(data);
  const { network } = uriToObject(data.network);
  const { router: cloudRouterName } = uriToObject(data.router);
  const { customerRouter } = customProperties;

  const {
    cloudRouterIpAddress,
    customerRouterIpAddress,
    bandwidth,
    partnerMetadata,
    edgeAvailabilityDomain,
    encryption,
    mtu,
    pairingKey,
    stackType,
    state,
    type: icType,
    vlanTag8021q // this is an odd key name, probably need to test with differnt IC tags to be sure it doesn't change
  } = data;

  return {
    node: {
      'Customer Router ID': customerRouter,
      'Customer Router Ip Address': customerRouterIpAddress,
      'Cloud Router Name': cloudRouterName,
      'Cloud Router Ip Address': cloudRouterIpAddress,

      Bandwidth: bandwidth,
      'Edge Availability Domain': edgeAvailabilityDomain,
      Encryption: encryption,
      Mtu: mtu,
      'Pairing Key': pairingKey,
      'Stack Type': stackType,
      State: state,
      'IC type': icType,
      'Vlan ID': vlanTag8021q ?? '',
      'Interconnect Name': partnerMetadata?.interconnectName ?? '',
      'Partner Name': partnerMetadata?.partnerName ?? '',
      'Portal Url': partnerMetadata?.portalUrl ?? '',
      ...data,
      ...customProperties,
      Network: network
    },
    keys: [
      'selfLink',
      'project',
      'Network',
      'region',
      'id',
      'name',
      'Customer Router ID',
      'Customer Router Ip Address',
      'Cloud Router Name',
      'Cloud Router Ip Address',
      'Bandwidth',
      'Edge Availability Domain',
      'Encryption',
      'Mtu',
      'Pairing Key',
      'Stack Type',
      'State',
      'IC type',
      'Vlan ID',
      'Interconnect Name',
      'Partner Name',
      'Portal Url',
      'description'
    ]
  };
}

export function getVpnGatewayMetadata(data) {
  const customProperties = getCustomProperties(data);
  const { network } = uriToObject(data.network);
  const { router: cloudRouterName } = uriToObject(data.router);
  const returnObj = {
    node: {
      'Cloud Router Name': cloudRouterName,
      ...data,
      ...customProperties,
      Network: network
    },
    keys: ['selfLink', 'Cloud Router Name', 'project', 'Network', 'region', 'id', 'name', 'description']
  };

  return returnObj;
}

export function getVpnTunnelMetadata(data) {
  const customProperties = getCustomProperties(data);
  const { network } = uriToObject(data.network);
  const { router: cloudRouterName } = uriToObject(data.router);
  return {
    node: {
      'Cloud Router Name': cloudRouterName,
      ...data,
      ...customProperties,
      Network: network,
      'Local Traffic Selector': data.localTrafficSelector,
      'Remote Traffic Selector': data.remoteTrafficSelector,
      'Peer IP': data.peerIp,
      Status: data.status
    },
    keys: [
      'selfLink',
      'Cloud Router Name',
      'project',
      'Network',
      'region',
      'id',
      'name',
      'description',
      'Local Traffic Selector',
      'Remote Traffic Selector',
      'Peer IP',
      'Status'
    ]
  };
}

export function getGcpNatGatewayMetadata(data) {
  const { network } = uriToObject(data.network);
  const {
    logConfig: { enable: logsEnabled, filter: logFilter },
    status,
    endpointTypes,
    natIpAllocateOption
  } = data;
  const { numVmEndpointsWithNatMappings, minExtraNatIpsNeeded } = status;

  const returnObj = {
    node: {
      Type: data.natGatewayType,
      'Cloud Router': data.router,
      Network: network,
      Region: data.regionName,
      'Endpoint Types': endpointTypes?.toString(),
      'Nat Ip Allocation Option': natIpAllocateOption,
      'Logs Enabled': `${logsEnabled}`,
      'Log Filter': logFilter,
      'Dynamic Port Allocation:': `${data.enableDynamicPortAllocation}`,
      'Endpoint Independent Mapping': `${data.enableEndpointIndependentMapping}`,
      'Minimum Ports Per VM': data.minPortsPerVm,
      'VM Endpoints With NAT Mappings': numVmEndpointsWithNatMappings,
      'Minimum Extra NAT IPs Needed': minExtraNatIpsNeeded,
      ...data
    },
    keys: [
      'Type',
      'Cloud Router',
      'project',
      'Network',
      'Region',
      'Endpoint Types',
      'Logs Enabled',
      'Log Filter',
      'name',
      'Dynamic Port Allocation:',
      'Endpoint Independent Mapping',
      'Minimum Ports Per VM',
      'peeringId',
      'numVmEndpointsWithNatMappings',
      'Minimum Extra NAT IPs Needed'
    ]
  };

  return returnObj;
}
