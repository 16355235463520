import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import storeLoader from 'app/stores/storeLoader';

import RecentlyAddedTests from 'app/views/synthetics/components/dashboard/RecentlyAddedTests';
import WidgetFrame from '../WidgetFrame';

@storeLoader('$syn.tests', '$syn.agents')
@inject('$syn')
@observer
class RecentlyAddedTestsWidget extends Component {
  constructor(props) {
    super(props);

    const { $syn } = props;

    $syn.tests.loadTestHealth();
  }

  render() {
    const { $syn, canCustomize, onRemove } = this.props;

    return (
      <WidgetFrame canCustomize={canCustomize} onRemove={onRemove} title="Recently Added Tests">
        <RecentlyAddedTests loading={$syn.tests.loading} isWidgetView />
      </WidgetFrame>
    );
  }
}

export default RecentlyAddedTestsWidget;
