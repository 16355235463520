import React from 'react';
import { observer } from 'mobx-react';
import { Text } from 'core/components';
import { formatDateTime } from 'core/util/dateUtils';

const LastUpdatedTag = observer(({ loading, dataview, ...rest }) => {
  if (loading) {
    return null;
  }

  return (
    <Text small muted {...rest}>
      Last Updated: {formatDateTime(dataview.lastUpdated)}
    </Text>
  );
});

export default LastUpdatedTag;
