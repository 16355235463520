import { getOption } from '../../form/components/modalSelect/selectHelpers';

const SelectValueRenderer = (props) => {
  const { valueRenderer, options, values, placeholder, showCount } = props;
  const value = values && values.join ? values.join(',') : values;

  if (value && !options) {
    return null;
  }

  const selectedOption = getOption(options, value);
  return valueRenderer(selectedOption, placeholder, values, options, showCount);
};

export default SelectValueRenderer;
