import { makeText } from 'core/util/svgUtils';

export const makeNodeIcon = (nodeGroup) =>
  nodeGroup
    .append('rect')
    .attr('rx', (d) => (d.isCircle ? d.bounds.width / 2 : 2))
    .attr('ry', (d) => (d.isCircle ? d.bounds.width / 2 : 2))
    .attr('x', (d) => d.bounds.x)
    .attr('y', (d) => d.bounds.y)
    .attr('width', (d) => d.bounds.width)
    .attr('height', (d) => d.bounds.height)
    .style('cursor', 'pointer');

export const makeNodeAgentIcon = (nodeGroup) =>
  nodeGroup
    .append('image')
    .attr('xlink:href', (d) => d.info.agent.agent_icon)
    .attr('width', 16)
    .attr('height', 16)
    .attr('x', (d) => d.bounds.x + 4)
    .attr('y', (d) => d.bounds.y + (d.bounds.height - 16) / 2)
    .style('pointer-events', 'none');

const makeBlueprintIcon = (nodeGroup, { size, fill, x, y }) =>
  nodeGroup
    .append('svg')
    .attr('width', size)
    .attr('height', size)
    .attr('viewBox', `0 0 ${size} ${size}`)
    .attr('x', x)
    .attr('y', y)
    .append('g')
    .attr('stroke', 'none')
    .attr('stroke-width', '1')
    .attr('fill', 'none')
    .attr('fill-rule', 'evenodd')
    .style('pointer-events', 'none')
    .append('g')
    .attr('fill', fill);

export const makeNodeAgentWarningIcon = (nodeGroup, fill) =>
  makeBlueprintIcon(nodeGroup, {
    size: 16,
    x: (d) => d.bounds.right - 24,
    y: (d) => d.bounds.y + (d.bounds.height - 16) / 2,
    fill
  })
    .append('path')
    .attr(
      'd',
      // eslint-disable-next-line max-len
      'M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z M8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 Z M9,12 L7,12 L7,10 L9,10 L9,12 Z M9,9 L7,9 L7,4 L9,4 L9,9 Z'
    );

export const makeNodeText = (nodeGroup, fill) =>
  makeText(nodeGroup)
    .attr('x', (d) => (d.type === 'agent' ? d.bounds.x + 24 : d.bounds.cx))
    .attr('y', (d) => (d.info && d.info.title ? d.bounds.bottom + d.textOffset : d.bounds.cy))
    .attr('dx', 0)
    .attr('stroke', 'none')
    .attr('fill', fill)
    .attr('fill-opacity', 0.8)
    .attr('alignment-baseline', 'central')
    .attr('dominant-baseline', 'central')
    .attr('text-anchor', (d) => (d.type === 'agent' ? 'start' : 'middle'))
    .text((d) => d.text);

export const makeNodeTextCount = (nodeGroup, fill) =>
  makeText(nodeGroup)
    .attr('x', (d) => d.bounds.cx)
    .attr('y', (d) => d.bounds.cy)
    .attr('dx', 0)
    .attr('stroke', 'none')
    .attr('fill', fill)
    .attr('fill-opacity', 0.8)
    .attr('alignment-baseline', 'central')
    .attr('dominant-baseline', 'central')
    .attr('text-anchor', 'middle')
    .text((d) => (d.textCount ? d.textCount : d.hops.length));

export const makeNodeTitle = (nodeGroup, fill) =>
  makeText(nodeGroup)
    .attr('x', (d) => d.bounds.cx)
    .attr('y', (d) => d.bounds.bottom + 15)
    .attr('dx', 0)
    .attr('stroke', 'none')
    .attr('fill', fill)
    .attr('fill-opacity', 0.8)
    .attr('alignment-baseline', 'central')
    .attr('dominant-baseline', 'central')
    .attr('text-anchor', 'middle')
    .attr('font-weight', (d) => (d.text ? 700 : 400))
    .text((d) => d.info.title);

export const makeRectNodeSupText = (nodeGroup, fill) =>
  makeText(nodeGroup)
    .attr('x', (d) => d.bounds.cx)
    .attr('y', (d) => d.bounds.cy - 10)
    .attr('dx', 0)
    .attr('stroke', 'none')
    .attr('fill', fill)
    .attr('fill-opacity', 0.8)
    .attr('alignment-baseline', 'central')
    .attr('dominant-baseline', 'central')
    .attr('text-anchor', 'middle')
    .text((d) => d.info.supText);

export const makeRectNodeSubText = (nodeGroup, fill) =>
  makeText(nodeGroup)
    .attr('x', (d) => d.bounds.cx)
    .attr('y', (d) => d.bounds.cy + 10)
    .attr('dx', 0)
    .attr('stroke', 'none')
    .attr('fill', fill)
    .attr('fill-opacity', 0.8)
    .attr('alignment-baseline', 'central')
    .attr('dominant-baseline', 'central')
    .attr('text-anchor', 'middle')
    .text((d) => d.text);
