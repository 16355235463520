import React from 'react';
import { withRouter } from 'react-router-dom';

import { Breadcrumb as BlueprintBreadcrumb } from '@blueprintjs/core';

function Breadcrumb(props) {
  return <BlueprintBreadcrumb {...props} />;
}

export default withRouter(Breadcrumb);
