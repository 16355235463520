import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';

function applyMaxDateRangeToQuery(query, maxDateRange) {
  if (maxDateRange) {
    const { lookback_seconds, starting_time, ending_time } = query;

    if (lookback_seconds && lookback_seconds > maxDateRange) {
      query.lookback_seconds = maxDateRange;
    } else {
      const momentStart = moment(starting_time);
      const momentEnd = moment(ending_time);
      if (Math.abs(momentStart.diff(momentEnd, 'seconds')) > maxDateRange) {
        query.starting_time = momentEnd.subtract(maxDateRange, 'seconds').format(DEFAULT_DATETIME_FORMAT);
      }
    }
  }

  return query;
}

export default applyMaxDateRangeToQuery;
