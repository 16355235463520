import React from 'react';
import moment from 'moment';
import { Grid, Text } from 'core/components';
import { DEFAULT_DATETIME_FORMAT, getQueryTimeInterval, secondsIntervalToText, timezone } from 'core/util/dateUtils';

const TimeDisplay = (props) => {
  const { model } = props;
  const { lookback_seconds, starting_time, ending_time, time_format } = model ? model.get() : props;
  const { momentFn: tzMomentFn } = timezone;

  let start;
  let end;

  let momentFn = tzMomentFn;
  let utcOffset = 0;
  if (time_format) {
    momentFn = time_format === 'Local' ? moment : moment.utc;
    utcOffset = momentFn().utcOffset(); // in minutes
  }
  if (lookback_seconds) {
    start = momentFn().subtract(lookback_seconds, 'second');
    end = momentFn();
  } else {
    start = momentFn(starting_time).add(utcOffset, 'minute');
    end = momentFn(ending_time).add(utcOffset, 'minute');
  }

  const interval = getQueryTimeInterval({ lookback_seconds, starting_time, ending_time });
  const textInterval = secondsIntervalToText(interval);

  return (
    <Grid gridTemplateColumns="75px 1fr">
      <Text muted>From:</Text>
      <strong>{start.format(DEFAULT_DATETIME_FORMAT)}</strong>
      <Text muted>To:</Text>
      <strong>{end.format(DEFAULT_DATETIME_FORMAT)}</strong>
      <Text muted>Window: </Text> <strong>{textInterval}</strong>
      <Text muted>Time Zone: </Text> <strong>{time_format || timezone.value}</strong>
    </Grid>
  );
};

export default TimeDisplay;
