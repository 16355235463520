export const showToast = (message, options) => import('./Toast').then(({ _showToast }) => _showToast(message, options));

export const showInfoToast = (message, options) =>
  import('./Toast').then(({ _showInfoToast }) => _showInfoToast(message, options));

export const showProgressToast = (message, amount, toastOptions = {}) =>
  import('./Toast').then(({ _showProgressToast }) => _showProgressToast(message, amount, toastOptions));

export const finishProgressToast = (toast, options) =>
  import('./Toast').then(({ _finishProgressToast }) => _finishProgressToast(toast, options));

export const showSuccessToast = (message, options) =>
  import('./Toast').then(({ _showSuccessToast }) => _showSuccessToast(message, options));

export const showErrorToast = (message, options) =>
  import('./Toast').then(({ _showErrorToast }) => _showErrorToast(message, options));

export const showWarningToast = (message, options) =>
  import('./Toast').then(({ _showWarningToast }) => _showWarningToast(message, options));

export const showClientErrorToast = (response) =>
  import('./Toast').then(({ _showClientErrorToast }) => _showClientErrorToast(response));

export const showNetworkErrorToast = (response, rawError) =>
  import('./Toast').then(({ _showNetworkErrorToast }) => _showNetworkErrorToast(response, rawError));
