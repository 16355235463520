import { Select } from 'core/form';
import LabelOptionRenderer from 'app/components/labels/LabelOptionRenderer';
import LabelValueTagRenderer from 'app/components/labels/LabelValueTagRenderer';
import React from 'react';

export const LabelMultiSelect = ({ fill, menuWidth, hideFilter = false, ...props }) => (
  <Select
    optionRenderer={LabelOptionRenderer}
    valueTagRenderer={LabelValueTagRenderer}
    fill={!menuWidth || fill} // defaults to true but disabled if you provide menuWidth
    multi
    keepOpen
    toggle
    showFilter={!hideFilter} // defaults to true
    menuWidth={menuWidth}
    {...props}
  />
);
