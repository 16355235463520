import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Flex from 'core/components/Flex';

import RemoveButton from './RemoveButton';
import SubmitButton from './SubmitButton';
import CancelButton from './CancelButton';

@observer
class FormActions extends Component {
  static defaultProps = {
    permissions: { create: true, delete: true, edit: true },
    showCancel: true,
    showRemove: false,
    showSave: true,
    closeAfterSave: true,
    preserveSelection: false
  };

  state = {
    loading: false
  };

  handleRemove = () => {
    const { form, onRemove } = this.props;
    const handleRemove = onRemove || form.handleRemove;

    if (handleRemove) {
      handleRemove();
    }
    this.handleCancel();
  };

  handleCancel = () => {
    const { form, onCancel, preserveSelection } = this.props;
    const handleCancel = onCancel || form.handleCancel;

    if (!preserveSelection && form.model) {
      const { collection } = form.model;
      if (collection) {
        collection.clearSelection();
      }
    }

    if (handleCancel) {
      handleCancel();
    }
  };

  handleSubmit = (form, values) => {
    const { onCancel, onSubmit: onSubmitProp, closeAfterSave = true } = this.props;
    const onSubmit = onSubmitProp || form.onSubmit;

    const submit = onSubmit(form, values);

    if (submit && submit.then) {
      this.setState({ loading: true });

      return submit
        .then(() => {
          if (closeAfterSave) {
            // Don't setState here because we're closing so it'll warn about memory leaks
            onCancel();
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((e) => {
          console.warn('Error saving (FormActions)', e);
          this.setState({ loading: false });
        });
    }

    return onCancel();
  };

  render() {
    const {
      entityName,
      form,
      handleCancel,
      handleRemove,
      handleSave,
      large,
      model,
      otherActions,
      removeButton,
      showCancel,
      showRemove,
      showSave,
      confirmBodyContent,
      confirmSubmit,
      closeAfterSave,
      submitButtonText,
      preserveSelection,
      submitButtonProps,
      forceLoading,
      ...rest
    } = this.props;

    const { loading } = this.state;
    const { permissions } = form;
    const isLoading = loading || forceLoading;

    const isNew = model && model.isNew;

    const showRemoveButton = showRemove && !isNew && permissions.delete;
    const showSaveButton = showSave && ((isNew && permissions.create) || (!isNew && permissions.edit));

    const onRemove = this.handleRemove;
    const onCancel = this.handleCancel;
    const onSubmit = this.handleSubmit;

    const cancelText = permissions.edit ? 'Cancel' : 'Close';
    const submitText = submitButtonText || (isNew ? `Add ${entityName}` : 'Save');

    const removeButtonCmp = removeButton || <RemoveButton {...this.props} onRemove={onRemove} large={large} />;

    return (
      <Flex flex={1} justifyContent="space-between">
        {showRemoveButton && removeButtonCmp}
        {otherActions}

        <Flex justifyContent="flex-end" flex={1}>
          {showCancel && <CancelButton {...rest} onCancel={onCancel} text={cancelText} large={large} />}
          {showSaveButton && (
            <SubmitButton
              {...rest}
              {...submitButtonProps}
              confirmBodyContent={confirmBodyContent}
              confirmSubmit={confirmSubmit}
              onSubmit={onSubmit}
              text={submitText}
              loading={isLoading}
              large={large}
            />
          )}
        </Flex>
      </Flex>
    );
  }
}

export default FormActions;
