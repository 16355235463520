import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Responsive } from 'react-grid-layout';
import styled from 'styled-components';
import { themeGet } from 'styled-system';

import { Card } from 'core/components';
import DashboardModel from 'app/stores/dashboard/DashboardModel';
import DashboardItem from './dashboardItem/DashboardItem';
import dashboardWidthProvider from './DashboardWidthProvider';

const ResponsiveReactGridLayout = dashboardWidthProvider(Responsive);

const DashboardCard = styled(Card)`
  .editing & {
    &.react-draggable {
      cursor: move;
    }

    &.react-resizable {
      .react-resizable-handle {
        position: absolute;
        border: solid ${themeGet('colors.darkGray5')};
        padding: 3px;
        margin: 4px;

        &-se {
          cursor: se-resize;
          bottom: 0;
          right: 0;
          border-width: 0 2px 2px 0;
        }
      }
    }

    &.react-draggable-dragging {
      .dashboard-item {
        pointer-events: none;
      }
    }
  }
`;

@inject('$dashboard')
@observer
class DashboardItemGrid extends Component {
  componentDidUpdate() {
    const { checkScroll } = this.props;
    if (checkScroll) {
      checkScroll();
    }
  }

  onLayoutChange = (currentBreakpointLayout, layout) => {
    const { $dashboard } = this.props;
    const currLayout = $dashboard.dashboard.get('layout');

    $dashboard.dashboard.set('layout', layout);

    if (!currLayout || currLayout.lg.length !== layout.lg.length || currLayout.sm.length !== layout.sm.length) {
      $dashboard.dashboard.updateLayout();
    }
  };

  onResizeStop = (layout, item) => {
    const { $dashboard } = this.props;
    const dashboardItem = $dashboard.dashboard.get('items').get(item.i);

    if (dashboardItem) {
      setTimeout(() => dashboardItem.reflow(), 10);
    }
  };

  renderDashboardItem = (item, idx) => {
    const { $dashboard, onEditItem, navigationHistory } = this.props;
    const { isEditing } = $dashboard;

    return (
      <DashboardCard key={item.id} elevation={1}>
        <DashboardItem
          item={item}
          isLast={idx === $dashboard.dashboard.get('items').size - 1}
          onEdit={isEditing ? onEditItem : null}
          navigationHistory={navigationHistory}
          isEditing={isEditing}
        />
      </DashboardCard>
    );
  };

  render() {
    const { $dashboard } = this.props;
    const { dashboard, isEditing } = $dashboard;
    const { breakpoints, cols, margin, containerPadding, rowHeight } = DashboardModel.layoutDefaults;

    const layout = dashboard.generateItemLayout();

    const itemGrid = (
      <ResponsiveReactGridLayout
        breakpoints={breakpoints}
        cols={cols}
        containerPadding={containerPadding}
        draggableHandle=".bp4-card.react-grid-item"
        layouts={layout}
        margin={margin}
        onLayoutChange={this.onLayoutChange}
        onResizeStop={this.onResizeStop}
        rowHeight={rowHeight}
        className={isEditing ? 'editing' : undefined}
        style={{ width: '100%' }}
        isDraggable={isEditing}
        isResizable={isEditing}
        useCSSTransforms={!/^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)}
      >
        {dashboard.get('items').models.map(this.renderDashboardItem)}
      </ResponsiveReactGridLayout>
    );

    return itemGrid;
  }
}

export default DashboardItemGrid;
