import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Route, Switch, withRouter } from 'react-router-dom';

import NotFound from 'app/views/NotFound';
import CapacityPlanDetail from './CapacityPlanDetail';
import EditCapacityPlan from './EditCapacityPlan';
import Capacity from './Capacity';

@inject('$auth', '$hybridMap')
@withRouter
class CapacityRoutes extends Component {
  render() {
    const { $auth, match } = this.props;

    return (
      <Switch>
        <Route path={match.url} exact component={Capacity} />
        {$auth.isAdministrator && <Route path={`${match.url}/new`} exact component={EditCapacityPlan} />}
        {$auth.isAdministrator && <Route path={`${match.url}/:id/edit`} exact component={EditCapacityPlan} />}
        <Route path={`${match.url}/:id`} exact component={CapacityPlanDetail} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default CapacityRoutes;
