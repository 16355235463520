import moment from 'moment';
import Validator from 'validatorjs';

const timeInTransform = (value) => (!value ? value : moment.utc(value).toDate());
const timeOutTransform = (value) =>
  value instanceof Date ? moment.utc(value).format('YYYY-MM-DDTHH:mm:ss[Z]') : value;

// we just keep it simple, make sure there's something that resembles a certificate in there and that's pretty much it
Validator.register('x509CertNotify', (value) => value.includes('-----BEGIN'));

const fields = {
  name: {
    label: 'Name',
    rules: 'required',
    placeholder: 'Notification Channel Name'
  },

  channelType: {
    label: 'Type',
    rules: 'required'
  },

  enabled: {
    label: 'Status',
    rules: 'required',
    defaultValue: true
  },

  'config.renderingConfig.insightsDigest': {
    label: 'Send Daily',
    rules: 'required',
    defaultValue: false
  },

  'config.batchingConfig.dailyTimesToSend[0]': {
    label: 'Daily Time',
    defaultValue: moment([2000, 0, 1, 12]).utc().format(),
    transform: {
      in: timeInTransform,
      out: timeOutTransform
    }
  },

  // optional fields
  'config.renderingConfig.customTemplate': {
    label: 'Custom Template',
    defaultValue: '{\n  {{- /* Please use Go-template syntax for the Custom Template */ -}}\n}\n'
  },

  'config.renderingConfig.uglifyJSON': {
    label: 'Uglify JSON',
    defaultValue: false
  },

  insightNames: {
    label: 'Insight Names'
  },

  insightFamilies: {
    label: 'Insight Families'
  },

  'config.sendingConfig.identifier': {
    label: 'Instance'
  },

  'config.sendingConfig.token': {
    label: 'Token'
  },

  'config.sendingConfig.username': {
    label: 'Username'
  },

  'config.sendingConfig.url': {
    label: 'URL',
    rules: 'url'
  },

  'config.sendingConfig.customHeaders[]': {
    label: 'Custom Headers',
    defaultValue: [''],
    validateDebounceDurationMs: 500,
    children: {
      rules: 'httpHeader'
    }
  },

  'config.sendingConfig.usernames[]': {
    label: 'Email Addresses',
    defaultValue: [''],
    validateDebounceDurationMs: 500,
    messages: {
      required: ' '
    },
    children: {
      rules: 'email',
      messages: {
        required: ' ',
        email: 'Must be a valid Email address'
      }
    },
    transform: {
      out: (value) => (value && value.trim ? value.trim() : value)
    }
  },

  'config.syslogConfig.host': {
    label: 'Host'
  },

  'config.syslogConfig.network': {
    label: 'Network Protocol',
    defaultValue: 'tcp',
    options: [
      {
        value: 'tcp',
        label: 'TCP'
      },
      {
        value: 'udp',
        label: 'UDP'
      }
    ]
  },

  'config.syslogConfig.port': {
    label: 'Port',
    type: 'number',
    rules: 'port|numeric|min:0'
  },

  'config.syslogConfig.severity': {
    label: 'Severity',
    defaultValue: 6,
    helpText: 'Severity (syslog severity code, 4=Warning)',
    // list of options for syslog severities
    options: [
      {
        value: 0,
        label: 'Emergency (0)'
      },
      {
        value: 1,
        label: 'Alert (1)'
      },
      {
        value: 2,
        label: 'Critical (2)'
      },
      {
        value: 3,
        label: 'Error (3)'
      },
      {
        value: 4,
        label: 'Warning (4)'
      },
      {
        value: 5,
        label: 'Notice (5)'
      },
      {
        value: 6,
        label: 'Informational (6)'
      },
      {
        value: 7,
        label: 'Debug (7)'
      }
    ]
  },

  'config.syslogConfig.facility': {
    label: 'Facility',
    defaultValue: 16,
    helpText: 'Facility (syslog facility code, 16=Local0)',
    // list of options for syslog facilities
    options: [
      {
        value: 0,
        label: 'Kernel (0)'
      },
      {
        value: 1,
        label: 'User Level (1)'
      },
      {
        value: 2,
        label: 'Mail (2)'
      },
      {
        value: 3,
        label: 'System Daemons (3)'
      },
      {
        value: 4,
        label: 'Security/Authorization (4)'
      },
      {
        value: 5,
        label: 'Syslogd (5)'
      },
      {
        value: 6,
        label: 'Line Printer (6)'
      },
      {
        value: 7,
        label: 'Network News (7)'
      },
      {
        value: 8,
        label: 'UUCP (8)'
      },
      {
        value: 9,
        label: 'Clock Daemon (9)'
      },
      {
        value: 10,
        label: 'Security/Authorization (10)'
      },
      {
        value: 11,
        label: 'FTP Daemon (11)'
      },
      {
        value: 12,
        label: 'NTP Daemon (12)'
      },
      {
        value: 13,
        label: 'Log Audit (13)'
      },
      {
        value: 14,
        label: 'Log Alert (14)'
      },
      {
        value: 15,
        label: 'Clock Daemon (15)'
      },
      {
        value: 16,
        label: 'Local0 (16)'
      },
      {
        value: 17,
        label: 'Local1 (17)'
      },
      {
        value: 18,
        label: 'Local2 (18)'
      },
      {
        value: 19,
        label: 'Local3 (19)'
      },
      {
        value: 20,
        label: 'Local4 (20)'
      },
      {
        value: 21,
        label: 'Local5 (21)'
      },
      {
        value: 22,
        label: 'Local6 (22)'
      },
      {
        value: 23,
        label: 'Local7 (23)'
      }
    ]
  },

  'config.syslogConfig.syslogHostname': {
    label: 'Syslog Hostname'
  },

  'config.sendingConfig.oauth2AccessTokenURL': {
    label: 'Access Token URL',
    placeholder: 'Enter URL...',
    rules: [
      'url',
      'required_with:config.sendingConfig.oauth2ClientId',
      'required_with:config.sendingConfig.oauth2ClientSecret'
    ],
    messages: {
      required_with: 'OAuth fields are required to be provided together.'
    }
  },

  'config.sendingConfig.requestTlsVerify': {
    label: 'Verify TLS certificates',
    defaultValue: true
  },

  'config.sendingConfig.requestTlsPem': {
    label: 'TLS PEM',
    rules: 'x509CertNotify',
    defaultValue: '',
    subLabel:
      '(Use https://developers.onelogin.com/saml/online-tools/x509-certs/format-x509-certificate to format certificate)',
    placeholder: '-----BEGIN CERTIFICATE-----',
    messages: {
      x509CertNotify: 'Invalid X.509 Certificate format.'
    }
  },

  'config.sendingConfig.oauth2TlsVerify': {
    label: 'Verify TLS Certificates for OAuth2 Request',
    defaultValue: true
  },

  'config.sendingConfig.oauth2TlsPem': {
    label: 'TLS PEM for OAuth2 Request',
    rules: 'x509CertNotify',
    defaultValue: '',
    subLabel:
      '(Use https://developers.onelogin.com/saml/online-tools/x509-certs/format-x509-certificate to format certificate)',
    placeholder: '-----BEGIN CERTIFICATE-----',
    messages: {
      x509CertNotify: 'Invalid X.509 Certificate format.'
    }
  },

  'config.sendingConfig.oauth2ClientId': {
    label: 'Client ID',
    placeholder: 'Enter Client ID...',
    rules: [
      'required_with:config.sendingConfig.oauth2AccessTokenURL',
      'required_with:config.sendingConfig.oauth2ClientSecret'
    ],
    messages: {
      required_with: 'OAuth fields are required to be provided together.'
    }
  },

  'config.sendingConfig.oauth2ClientSecret': {
    label: 'Client Secret',
    placeholder: 'Enter Secret...',
    rules: [
      'required_with:config.sendingConfig.oauth2AccessTokenURL',
      'required_with:config.sendingConfig.oauth2ClientId'
    ],
    messages: {
      required_with: 'OAuth fields are required to be provided together.'
    }
  },

  'config.sendingConfig.oauth2Scopes': {
    label: 'Scope',
    placeholder: 'Optional'
  },

  // API driven, not to be edited on the UI
  'config.sendingConfig.credentialsVaultHandle': {}

  // this is currently hidden - need to figure out what the exact approach should be - v4 needs parity work
  // reminder_interval: {
  //   label: 'Status Digest Interval',
  //   defaultValue: 0,
  //   options: [
  //     {
  //       value: 0,
  //       label: "Don't Send"
  //     },
  //     {
  //       value: 1,
  //       label: '1 Hour'
  //     },
  //     {
  //       value: 2,
  //       label: '2 Hours'
  //     },
  //     {
  //       value: 4,
  //       label: '4 Hours'
  //     },
  //     {
  //       value: 8,
  //       label: '8 Hours'
  //     },
  //     {
  //       value: 12,
  //       label: '12 Hours'
  //     },
  //     {
  //       value: 24,
  //       label: '24 Hours'
  //     }
  //   ]
  // }
};

const options = {
  name: 'Notification'
};

export { fields, options };
