import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Flex, Button, Dialog, Link, Text, Tag } from 'core/components';
import { AGENT_IMPLEMENTATIONS } from 'shared/synthetics/constants';
import AgentInstallInstructions from 'app/components/agent/NewAgentInstallInstructions';
import AgentInstallChooseAgentType from 'app/components/agent/AgentInstallChooseAgentType';
import AgentInstallChooseDeploymentOption from 'app/components/agent/AgentInstallChooseDeploymentOption';
import InlineCode from 'app/components/agent/InlineCode';
import { ReactComponent as DockerLogo } from 'app/assets/logos/docker.svg';
import { ReactComponent as DebianLogo } from 'app/assets/logos/debian.svg';
import { ReactComponent as RPMLogo } from 'app/assets/logos/rpm.svg';

const InlineCodeTag = ({ children, small }) => (
  <Tag small={small} minimal>
    <InlineCode>{children}</InlineCode>
  </Tag>
);

const getDockerInstructions = (region, agent = 'ksynth-agent', isThroughputEnabled = false) => {
  const commands = [];
  const instructions = [];
  let dockerRunSuffix = <>.</>;
  if (agent === 'ksynth-agent') {
    instructions.push({
      key: 'docker-create',
      text: 'Create a local directory to mount into the docker container. This is needed so agent identity can persist between docker runs. Note: the Docker agent can run web tests by default.'
    });
    commands.push({
      key: 'docker-node-run',
      command: `docker run -d --name ${agent} -v {/path/you/created}:/var/lib/${agent} kentik/${agent}:latest ${agent}
        ${region === 'eu' ? '--region eu' : ''} --company-id {kentik_company_id} --name {name_string}`
    });
  } else {
    commands.push(
      {
        key: 'docker-rust-run-1',
        command: `docker run --name ${agent} -d -v /opt/kentik/${agent}:/var/lib/${agent} kentik/${agent}:latest --company={kentik_company_id} --region=${region} --name {name_string} --update`
      },
      {
        key: 'docker-rust-run-2',
        command: `docker run -d --name ${agent} -v ${agent}:/var/lib/${agent} kentik/${agent}:latest --company={kentik_company_id} --region=${region} --name {name_string} --update`
      }
    );
    dockerRunSuffix = (
      <>
        {' '}
        and the <InlineCode>update</InlineCode> flag sets it to auto-update when new versions are available.
      </>
    );
  }
  instructions.push(
    {
      key: 'docker-pull',
      text: 'Pull and run the docker image',
      instructions: [
        {
          key: 'docker-pull-copy',
          text: (
            <Text style={{ marginTop: '4px', marginBottom: '4px' }}>
              Copy your Kentik company ID from <Link to="/v4/licenses">Licenses</Link> (the number next to
              &quot;Account#&quot;) and use it in place of <InlineCode>kentik_company_id</InlineCode> below. Note that{' '}
              <InlineCode>name_string</InlineCode> is the name for the agent in the portal{dockerRunSuffix}
            </Text>
          ),
          commands
        }
      ]
    },
    {
      key: 'docker-retrieve',
      text: '(Optional) Retrieve your challenge code and enter it into the Kentik portal',
      command: `docker logs ${agent}`
    }
  );

  if (isThroughputEnabled) {
    instructions.push({
      key: 'docker-iperf',
      text: '(Optional) To run throughput tests, the agent needs to be configured with iperf3. There are both Client and Server modes available.',
      instructions: [
        {
          key: 'iperf-client-docker',
          text: (
            <>
              <Text style={{ fontWeight: '700', marginRight: '5px' }}>Client Mode:</Text>
              <Text style={{ marginTop: '4px', marginBottom: '4px' }}>
                Add <code>--enable-iperf</code> to the agent parameters in the docker run command.
              </Text>
            </>
          ),
          command: `docker run -d --name ${agent} -v {/path/you/created}:/var/lib/${agent} kentik/${agent}:latest ${agent}
        ${region === 'eu' ? '--region eu' : ''} --company-id {kentik_company_id} --name {name_string} --enable-iperf`
        },
        {
          key: 'iperf-server-docker',
          text: (
            <>
              <Text style={{ fontWeight: '700', marginRight: '5px' }}>Server Mode:</Text>
              <Text style={{ marginTop: '4px', marginBottom: '4px' }}>
                Add <code>--iperf-server-port</code> to the agent parameters in the docker run command to enable iperf3
                server mode. You will also need to use the <code>-p</code> flag to publish the containers port.
              </Text>
              <br />
              <br />
              <Text style={{ marginTop: '4px', marginBottom: '4px', fontStyle: 'italic' }}>
                Note: To open multiple iperf ports, list them in this argument separated by commas or spaces.
              </Text>
            </>
          ),
          command: `docker run -d --name ${agent} -p {iperf_server_port}:{iperf_server_port} -v {/path/you/created}:/var/lib/${agent} kentik/${agent}:latest ${agent}
        ${
          region === 'eu' ? '--region eu' : ''
        } --company-id {kentik_company_id} --name {name_string} --iperf-server-port {iperf_server_port}`
        }
      ]
    });
  }
  return {
    title: 'Docker',
    icon: DockerLogo,
    instructions
  };
};

const getPackageInstallInstruction = (agent = 'ksynth-agent', ext, isThroughputEnabled) => {
  const repoInstallStr = agent === 'ksynth-agent' ? 'App Agent' : 'ksynth agent';
  const manager = ext === 'deb' ? 'apt-get' : 'yum';

  // start with download and install commands
  const instructions = [
    {
      key: 'package-repo',
      text: `Install the Kentik ${repoInstallStr} repository`,
      command: `curl -s https://packagecloud.io/install/repositories/kentik/${agent}/script.${ext}.sh | sudo bash`
    },
    {
      key: 'package-install',
      text: `Install the ${agent} service using the package manager`,
      command: `${manager} install ${agent}`
    }
  ];

  // show optional browser test commands
  if (agent === 'ksynth-agent') {
    instructions.push({
      key: 'chromium',
      text: '(Optional) The ksynth-agent is now configured to run in network mode. Complete the following step to enable browser testing, or skip this step if you do not need browser testing',
      instructions: [
        {
          key: 'chromium-1',
          text: 'Open the service configuration file in editor of choice (nano used here)',
          command: 'nano /etc/default/ksynth-agent'
        },
        {
          key: 'chromium-2',
          text: 'Remove (or comment out) the following line and save the file',
          command: 'K_AGENT_DISABLE=browser'
        },
        {
          key: 'chromium-3',
          text: 'Navigate to ksynth-agent working directory scripts folder',
          command: 'cd /var/lib/ksynth-agent/scripts'
        },
        {
          key: 'chromium-4',
          text: 'Run the appropriate script (Debian or RPM) and ensure successful completion',
          commands: [
            { key: 'chromium-4-deb', command: './install-browser-deps_debian' },
            { key: 'chromium-4-rpm', command: './install-browser-deps_centos' }
          ]
        }
      ]
    });
  }

  // finish with commands to run agent
  instructions.push(
    {
      key: 'agent-run',
      text: 'Run the agent (if not already running)',
      command: `systemctl start ${agent}`
    },
    {
      key: 'agent-register',
      text: 'Register the agent with the Kentik portal',
      instructions: [
        {
          key: 'agent-register-1',
          text: (
            <Text>
              <Text fontWeight="700">Option 1: (Manual):</Text> Retrieve/ copy the &quot;challenge code&quot; from the
              agent by running one of these commands on the CLI and paste it into the portal
            </Text>
          ),
          commands: [
            { key: 'systemctl', command: `systemctl status ${agent}` },
            { key: 'journalctl', command: `journalctl -u ${agent}` }
          ]
        },
        {
          key: 'agent-register-2',
          text: (
            <Text>
              <Text fontWeight="700">Option 2: (Automatic):</Text> Specify your Kentik customer ID in the agent
              configuration file
              <ul style={{ marginTop: '4px', marginBottom: '4px', paddingLeft: '20px' }}>
                <li>
                  Copy your Kentik company ID from <Link to="/v4/licenses">Licenses</Link> (the number next t
                  &quot;Account #&quot;)
                </li>
                <li>
                  Paste it after <InlineCode>KENTIK_COMPANY=</InlineCode> in the file{' '}
                  <InlineCode>/etc/default/{agent}</InlineCode>
                </li>
                <li>You may need to restart agent after setting variables</li>
              </ul>
            </Text>
          ),
          command: `systemctl stop ${agent} && systemctl start ${agent}`
        }
      ]
    }
  );

  if (isThroughputEnabled) {
    instructions.push({
      key: 'iperf',
      text: '(Optional) To run throughput tests, the agent needs to be configured with iperf3. There are both Client and Server modes available.',
      instructions: [
        {
          key: 'iperf-client-linux',
          text: <Text style={{ fontWeight: '700', marginRight: '5px' }}>Client Mode:</Text>
        },
        {
          key: 'iperf-client-1',
          text: 'Open the service configuration file in editor of choice (nano used here)',
          command: 'nano /etc/default/ksynth-agent'
        },
        {
          key: 'iperf-client-2',
          text: (
            <Text style={{ marginTop: '4px', marginBottom: '4px' }}>
              Add <code>--enable-iperf</code> to the K_AGENT_ARGS line in the file and save
            </Text>
          ),
          command: 'K_AGENT_ARGS="--enable-iperf"'
        },
        {
          key: 'iperf-server-linux',
          text: <Text style={{ fontWeight: '700', marginRight: '5px' }}>Server Mode:</Text>
        },
        {
          key: 'iperf-server-1',
          text: 'Open the service configuration file in editor of choice (nano used here)',
          command: 'nano /etc/default/ksynth-agent'
        },
        {
          key: 'iperf-server-2',
          text: (
            <>
              <Text style={{ marginTop: '4px', marginBottom: '4px' }}>
                Add <code>--iperf-server-port</code> to the K_AGENT_ARGS line in the file and save
              </Text>
              <br />
              <br />
              <Text style={{ marginTop: '4px', marginBottom: '4px', fontStyle: 'italic' }}>
                Note: To open multiple iperf ports, list them in this argument separated by commas or spaces.
              </Text>
            </>
          ),
          command: 'K_AGENT_ARGS="--iperf-server-port 5201 5202"'
        }
      ]
    });
  }

  return instructions;
};

const deploymentOptions = (envName, agent, isThroughputEnabled) => {
  const isEu = ['EU-SaaS', 'EMEA-SaaS'].includes(envName);
  const region = isEu ? 'eu' : 'us';
  const arr = [
    {
      title: 'Debian/Ubuntu Package',
      icon: DebianLogo,
      instructions: getPackageInstallInstruction(agent, 'deb', isThroughputEnabled)
    },
    {
      title: 'RPM Package',
      icon: RPMLogo,
      instructions: getPackageInstallInstruction(agent, 'rpm', isThroughputEnabled)
    },
    getDockerInstructions(region, agent, isThroughputEnabled)
  ];
  if (isEu) {
    // 0 === .deb, 1 === .rpm
    [0, 1].forEach((i) =>
      arr[i].instructions.splice(2, 0, {
        key: 'region-var',
        text: (
          <Text>
            Set the Kentik SaaS region in the file <InlineCodeTag>/etc/default/{agent}</InlineCodeTag>
          </Text>
        ),
        command: 'KENTIK_REGION=EU'
      })
    );
  }
  return arr;
};

const agentTypeInstructions = (envName, isThroughputEnabled = false) => {
  const [debianRust, rpmRust, dockerRust] = deploymentOptions(envName, 'ksynth', isThroughputEnabled);
  const [debianNode, rpmNode, dockerNode] = deploymentOptions(envName, 'ksynth-agent', isThroughputEnabled);
  return {
    'network-test-agent': {
      name: 'Network Test Agent',
      options: {
        'debian-ubuntu': {
          name: 'Debian/Ubuntu Package',
          instructions: debianRust.instructions
        },
        rpm: {
          name: 'RPM Package',
          instructions: rpmRust.instructions
        },
        docker: {
          name: 'Docker',
          instructions: dockerRust.instructions
        }
      }
    },
    'app-agent': {
      name: 'App Agent',
      options: {
        'debian-ubuntu': {
          name: 'Debian/Ubuntu Package',
          instructions: debianNode.instructions
        },
        rpm: {
          name: 'RPM Package',
          instructions: rpmNode.instructions
        },
        docker: {
          name: 'Docker',
          instructions: dockerNode.instructions
        }
      }
    }
  };
};

const collectionHasRustAgents = (c) => c.some((m) => m.get('agent_impl') === AGENT_IMPLEMENTATIONS.RUST);

const DEFAULT_STATE = {
  step: 1,
  baseStep: 1,
  agentType: '',
  deploymentOption: ''
};

@inject('$dictionary', '$syn', '$auth')
class AgentInstallModal extends Component {
  state = { ...DEFAULT_STATE };

  static getDerivedStateFromProps(props, state) {
    const { isOpen, $syn, $auth } = props;
    const { pendingAgents, privateAgents } = $syn.agents;
    let { baseStep, step, agentType } = state;

    const isThroughputEnabled = $auth.getPermission('synthetics.throughputTesting.enabled');

    // TODO: remove this once rust agents are fully deprecated
    const companyHasRustAgents = collectionHasRustAgents(pendingAgents) || collectionHasRustAgents(privateAgents);
    if (!companyHasRustAgents) {
      baseStep = 2;
      agentType = 'app-agent';
      step = step === 1 ? 2 : step;
    }

    if (!isOpen && step !== 1) {
      return { ...DEFAULT_STATE, isThroughputEnabled };
    }

    return { ...state, agentType, step, baseStep, isThroughputEnabled };
  }

  get nextStep() {
    const { step } = this.state;

    return step + 1;
  }

  handleAgentTypeChange = (agentType) => {
    const { agentType: prevAgentType, deploymentOption: prevDeploymentOption } = this.state;
    const deploymentOption = agentType === prevAgentType ? prevDeploymentOption : '';

    this.setState({ agentType, deploymentOption, step: this.nextStep });
  };

  handleDeploymentOptionChange = (deploymentOption) => {
    this.setState({ deploymentOption, step: this.nextStep });
  };

  handleBack = () => {
    const { step } = this.state;

    this.setState({ step: step - 1 });
  };

  get installInstructions() {
    const { $dictionary } = this.props;
    const { agentType, deploymentOption, isThroughputEnabled } = this.state;

    if (agentType && deploymentOption) {
      const { name, options } = agentTypeInstructions($dictionary.get('envName'), isThroughputEnabled)[agentType];

      return { agentName: name, deploymentOption: options[deploymentOption] };
    }

    return null;
  }

  get agentName() {
    const { agentType, isThroughputEnabled } = this.state;

    if (agentType) {
      const { name } = agentTypeInstructions('', isThroughputEnabled)[agentType];

      return name;
    }

    return null;
  }

  render() {
    const { onClose, isOpen, children, ...dialogOverrides } = this.props;
    const { step, agentType, baseStep, deploymentOption } = this.state;

    return (
      <Dialog
        isOpen={isOpen}
        title="Private Agent Setup"
        onClose={onClose}
        width={758}
        height={550}
        maxHeight="calc(100vh - 150px)"
        canEscapeKeyClose
        {...dialogOverrides}
      >
        <Dialog.Body pt={3}>
          {step === baseStep && children}
          {step === 1 && <AgentInstallChooseAgentType onChange={this.handleAgentTypeChange} agentType={agentType} />}
          {step === 2 && (
            <AgentInstallChooseDeploymentOption
              onChange={this.handleDeploymentOptionChange}
              agentName={this.agentName}
              deploymentOption={deploymentOption}
            />
          )}
          {step === 3 && <AgentInstallInstructions {...this.installInstructions} />}
        </Dialog.Body>
        <Dialog.Footer>
          <Flex flex="1 0 100%">
            {step > baseStep && <Button onClick={this.handleBack} icon="arrow-left" text="Back" iconSize={16} large />}
          </Flex>
        </Dialog.Footer>
      </Dialog>
    );
  }
}

export default AgentInstallModal;
