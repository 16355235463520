const OPEN_AI_STRING = 'openai';
const ANTHROPIC_VERTEX_STRING = 'anthropic_vertex';
const MISTRAL_VERTEX_STRING = 'mistralai';
const GOOGLE_VERTEX_STRING = 'google';

module.exports = {
  DEFAULT_FPA_COLUMNS: [
    'inet_dst_addr',
    'inet_src_addr',

    'i_input_interface_description',
    'i_input_snmp_alias',

    'i_output_interface_description',
    'i_output_snmp_alias',

    'i_device_name',
    'i_device_site_name',

    // Traffic profiles
    'simple_trf_prof',
    'i_trf_origination',
    'i_trf_termination',

    'i_protocol_name',
    'application',

    'src_geo_city',
    'dst_geo_city',

    'src_geo',
    'dst_geo',

    'i_src_as_name',
    'i_dst_as_name',

    // Prefix together with the length
    'i_src_bgp_prefix',
    'i_dst_bgp_prefix',

    'src_flow_tags',
    'dst_flow_tags',

    'src_cloud',
    'src_cloud_service',

    'dst_cloud',
    'dst_cloud_service',

    'i_src_network_bndry_name',
    'i_src_connect_type_name',
    'i_src_provider_classification',

    'i_dst_connect_type_name',
    'i_dst_network_bndry_name',
    'i_dst_provider_classification',

    'i_inet_ult_exit',
    'i_ult_provider_classification'
  ],
  PROMPT_LENGTH_LIMIT: 2000,
  MAX_TOKENS: 2000,
  DEFAULT_MODEL: 'gpt-4o-2024-08-06',
  OPEN_AI_STRING,
  ANTHROPIC_VERTEX_STRING,
  MISTRAL_VERTEX_STRING,
  SUPPORTED_MODELS: {
    'gpt-4o': { api: OPEN_AI_STRING },
    'gpt-4o-2024-11-20': { api: OPEN_AI_STRING },
    'gpt-4o-2024-08-06': { api: OPEN_AI_STRING },
    'gpt-4o-2024-05-13': { api: OPEN_AI_STRING },
    'gpt-4o-mini': { api: OPEN_AI_STRING },
    'gpt-4o-mini-2024-07-18': { api: OPEN_AI_STRING },
    'claude-3-5-sonnet@20240620': { api: ANTHROPIC_VERTEX_STRING },
    'claude-3-5-sonnet-v2@20241022': { api: ANTHROPIC_VERTEX_STRING },
    'claude-3-haiku@20240307': { api: ANTHROPIC_VERTEX_STRING },
    'mistral-nemo': { api: MISTRAL_VERTEX_STRING, version: '@2407' },
    'mistral-large-2411': { api: MISTRAL_VERTEX_STRING },
    'codestral-2501': { api: MISTRAL_VERTEX_STRING },
    'meta/llama3-405b-instruct-maas': {},
    'meta/llama-3.2-90b-vision-instruct-maas': {},
    'gemini-2.0-flash-exp': { api: GOOGLE_VERTEX_STRING, method: 'generateContent' },
    'gemini-1.5-pro-002': { api: GOOGLE_VERTEX_STRING, method: 'generateContent' },
    'gemini-1.5-pro-001': { api: GOOGLE_VERTEX_STRING, method: 'generateContent' }
  }
};
