import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Box, Flex } from 'core/components';
import KentikLogo from 'app/components/KentikLogo';

@inject('$auth', '$app')
@observer
export default class ExportCoverPage extends Component {
  render() {
    const { title, description = '', $app, $auth, slim } = this.props;

    if (slim) {
      return (
        <Flex align="center" justifyContent="center" textAlign="center" p={1}>
          <Flex mr={2}>
            <strong>{title}</strong>
          </Flex>
          <Flex mr={2}>{description}</Flex>
          <Flex>Created for {$auth.companyFullName}</Flex>
        </Flex>
      );
    }

    return (
      <Flex className="break-after" align="center" justifyContent="center" fontSize="large" textAlign="center">
        <div>
          <h1>{title}</h1>

          {description && (
            <Flex
              className="pt-callout"
              justify="center"
              style={{ maxWidth: 96 * 5.5, margin: 'auto', textAlign: 'left' }}
            >
              <span>{description}</span>
            </Flex>
          )}

          <Box className="pt-text-muted" mt={6}>
            This report was generated for <strong>{$auth.companyFullName}</strong>
          </Box>
          {!$app.isSubtenant && <KentikLogo blackText style={{ width: 200, margin: '15px 0px' }} />}
        </div>
      </Flex>
    );
  }
}
