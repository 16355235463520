import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';

const CountryLink = ({ $app, country, children, ...rest }) => {
  if ($app.isSubtenant) {
    return children;
  }

  return (
    <Link to={`/v4/core/quick-views/country/${country}`} {...rest}>
      {children}
    </Link>
  );
};

export default inject('$app')(CountryLink);
