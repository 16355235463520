import { DateRangeInput } from '@blueprintjs/datetime';
import styled from 'styled-components';

class DateRangeInputShortcuts extends DateRangeInput {
  static getDerivedStateFromProps(props, state) {
    const { selectedShortcutIndex } = props;
    if (typeof selectedShortcutIndex === 'number') {
      return { ...state, selectedShortcutIndex };
    }

    return state;
  }

  handleShortcutChange = (shortcut, selectedShortcutIndex) => {
    const { onShortcutChange } = this.props;
    if (onShortcutChange) {
      onShortcutChange(shortcut, selectedShortcutIndex);
    }
    this.setState({ selectedShortcutIndex });
  };
}

const StyledDateRangeInputShortcuts = styled(DateRangeInputShortcuts)`
  .bp4-input-group {
    flex: ${(props) => (props.stacked ? '0 0 100%' : 1)};
    border-radius: ${(props) => (props.stacked ? '4px' : 1)};
  }

  .bp4-control-group {
    flex-wrap: ${(props) => (props.stacked ? 'wrap' : 'nowrap')};
    gap: ${(props) => (props.stacked ? '4px' : 0)};
  }
`;

export default StyledDateRangeInputShortcuts;
