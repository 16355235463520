import React from 'react';
import { observer } from 'mobx-react';
import { DateInput, TimePrecision } from '@blueprintjs/datetime';

import { whitelistFieldProps } from 'core/form/components/util';
import { timezone } from 'core/util/dateUtils';

const DateTime = (props) => {
  const { field, format, style, small } = props;

  const { value, ...fieldProps } = field.getProps();

  return (
    <div style={style}>
      <DateInput
        {...fieldProps}
        {...whitelistFieldProps(props)}
        closeOnSelection={false}
        formatDate={(date) => timezone.momentFn(date).format(format)}
        parseDate={(str) => new Date(str)}
        value={value ? timezone.momentFn(value, format).toDate() : null}
        inputProps={{ small }}
      />
    </div>
  );
};

DateTime.defaultProps = {
  format: 'YYYY-MM-DD HH:mm',
  timePrecision: TimePrecision.MINUTE
};

export default observer(DateTime);
