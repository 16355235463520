import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { inject } from 'mobx-react';
import lazyLoad from 'core/util/lazyLoad';
import NoFlowOverlay from 'app/components/NoFlowOverlay';
import networkExplorerScreenshot from 'app/assets/screenshots/network-explorer-screenshot.png';
import networkExplorerScreenshotDark from 'app/assets/screenshots/network-explorer-screenshot-dark.png';
import hybridMapScreenshotDark from 'app/assets/screenshots/hybrid-screenshot-dark.png';
import hybridMapScreenshot from 'app/assets/screenshots/hybrid-screenshot.png';
import dataExplorerScreenshot from 'app/assets/screenshots/explorer-screenshot.png';
import dataExplorerScreenshotDark from 'app/assets/screenshots/explorer-screenshot-dark.png';
import capacityScreenshot from 'app/assets/screenshots/capacity-screenshot.png';
import capacityScreenshotDark from 'app/assets/screenshots/capacity-screenshot-dark.png';
import libraryScreenshot from 'app/assets/screenshots/library-screenshot.png';
import libraryScreenshotDark from 'app/assets/screenshots/library-screenshot-dark.png';

import journeysScreenshot from 'app/assets/screenshots/journeys-screenshot.png';
import journeysScreenshotDark from 'app/assets/screenshots/journeys-screenshot-dark.png';

const ApiTester = lazyLoad(() => import('../apiTester'));
const QueryEditor = lazyLoad(() => import('./queryEditor/QueryEditor'));

@inject('$auth')
class CoreNoFlowRoutes extends Component {
  render() {
    const { $auth } = this.props;
    return (
      <Switch>
        <Route path="/v4/core/api-tester" component={ApiTester} />
        {$auth.isSudoer && <Route path="/v4/core/query-editor" component={QueryEditor} />}

        <Route
          exact
          path="/v4/core"
          render={() => (
            <NoFlowOverlay imageSrc={networkExplorerScreenshot} darkImageSrc={networkExplorerScreenshotDark} />
          )}
        />
        <Route
          path="/v4/core/journeys"
          render={() => <NoFlowOverlay imageSrc={journeysScreenshot} darkImageSrc={journeysScreenshotDark} />}
        />
        <Route
          path="/v4/kentik-map/logical"
          render={() => (
            <NoFlowOverlay title="Network Map" imageSrc={hybridMapScreenshot} darkImageSrc={hybridMapScreenshotDark} />
          )}
        />
        <Route
          path="/v4/kentik-map"
          render={() => (
            <NoFlowOverlay title="Kentik Map" imageSrc={hybridMapScreenshot} darkImageSrc={hybridMapScreenshotDark} />
          )}
        />
        <Route
          path="/v4/core/explorer"
          render={() => (
            <NoFlowOverlay
              title="Data Explorer"
              imageSrc={dataExplorerScreenshot}
              darkImageSrc={dataExplorerScreenshotDark}
            />
          )}
        />
        <Route
          path="/v4/core/capacity"
          render={() => (
            <NoFlowOverlay
              title="Capacity Planning"
              imageSrc={capacityScreenshot}
              darkImageSrc={capacityScreenshotDark}
            />
          )}
        />
        <Route
          path="/v4/library"
          render={() => (
            <NoFlowOverlay title="Library" imageSrc={libraryScreenshot} darkImageSrc={libraryScreenshotDark} />
          )}
        />
      </Switch>
    );
  }
}

export default CoreNoFlowRoutes;
