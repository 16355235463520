import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Callout, Flex, Icon, LinkButton, Text } from 'core/components';
import { FaCalculator } from 'react-icons/fa';
import { FiDollarSign } from 'react-icons/fi';

@inject('$auth')
@observer
class TrafficCostsBox extends Component {
  render() {
    const { $auth, entityLabel, state, ...props } = this.props;
    return $auth.hasPermission('trafficCosts.enabled', { overrideForSudo: false }) ? (
      <Callout p={3} {...props}>
        <Flex gap={2}>
          <Icon color="muted" icon={FiDollarSign} iconSize={46} />
          <Flex flexDirection="column" gap={1}>
            <Text fontSize={18} fontWeight="normal" color="body" as="div">
              View Traffic Costs for <Text fontWeight="heavy">{entityLabel}</Text>
            </Text>
            <Flex>
              <LinkButton
                fontWeight="heavy"
                icon={FaCalculator}
                intent="primary"
                to="/v4/edge/traffic-costs"
                state={state}
              >
                Calculate
              </LinkButton>
            </Flex>
          </Flex>
        </Flex>
      </Callout>
    ) : null;
  }
}

export default TrafficCostsBox;
