import React from 'react';
import { unescape } from 'lodash';
import { Flex, Icon, Text, Tooltip } from 'core/components';

const TestNotesPopover = ({ children, test, ...props }) => {
  const notes = test?.get('config.notes');

  if (notes) {
    return (
      <Tooltip
        content={
          <Text as="div" maxWidth={200}>
            {unescape(notes)}
          </Text>
        }
        placement="right-end"
      >
        <Flex alignItems="center" gap={1}>
          {children}
          <Icon icon="info-sign" color="primary" {...props} />
        </Flex>
      </Tooltip>
    );
  }

  return null;
};

export default TestNotesPopover;
