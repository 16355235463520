import React from 'react';

import { Box, LinkButton, Text } from 'core/components';
import $devices from 'app/stores/device/$devices';

import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: [
    'ktsubtype__gcp_subnet__STR00',
    'ktsubtype__gcp_subnet__STR01',
    'ktsubtype__gcp_subnet__STR02',
    'ktsubtype__gcp_subnet__STR03',
    'ktsubtype__gcp_subnet__STR04',
    'ktsubtype__gcp_subnet__STR05',
    'ktsubtype__gcp_subnet__STR06',
    'ktsubtype__gcp_subnet__STR07',
    'ktsubtype__gcp_subnet__STR08',
    'ktsubtype__gcp_subnet__STR09',
    'ktsubtype__gcp_subnet__STR10',
    'ktsubtype__gcp_subnet__STR11'
  ],
  filterFields: [
    'ktsubtype__gcp_subnet__STR00',
    'ktsubtype__gcp_subnet__STR01',
    'ktsubtype__gcp_subnet__STR02',
    'ktsubtype__gcp_subnet__STR03',
    'ktsubtype__gcp_subnet__STR04',
    'ktsubtype__gcp_subnet__STR05',
    'ktsubtype__gcp_subnet__STR06',
    'ktsubtype__gcp_subnet__STR07',
    'ktsubtype__gcp_subnet__STR08',
    'ktsubtype__gcp_subnet__STR09',
    'ktsubtype__gcp_subnet__STR10',
    'ktsubtype__gcp_subnet__STR11'
  ]
};

class GCEDependency extends AbstractDependency {
  get key() {
    return 'gce';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return $devices.hasGCEDevice;
  }

  get preventQuery() {
    return true;
  }

  get message() {
    return (
      <>
        <Box mb="4px">
          <Text small>
            <strong>GCP</strong> devices are required by dimensions you&apos;re using, but have not been detected for
            any devices in your organization. Results shown herein may be highly inaccurate.
          </Text>
        </Box>
        <LinkButton text="Configure" icon="cog" small to="/v4/settings/clouds" />
      </>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || $devices.hasGCEDevice;
  }
}

export default GCEDependency;
