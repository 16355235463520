import styled from 'styled-components';
import { lighten } from 'polished';
import { Card } from 'core/components';

const ROW_EXPANSION_HEIGHT = 305;

// Note: we don't support variable height row expanders - leads to bad overflow problems

const ExpandableRow = styled(Card)`
  ${({ rowExpansionHeight = ROW_EXPANSION_HEIGHT }) => `
      flex: 1 0 ${rowExpansionHeight}px;
      border-radius: 0;
      background-color: ${({ theme }) => lighten(0.03, theme.colors.appBackground)} !important;
      display: flex;
      flex-direction: column;
      overflow: auto;
      max-height: ${rowExpansionHeight}px;
      margin: 0 0 1px 0;
    `}
`;

export default ExpandableRow;
