import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ButtonGroupTabs, ButtonGroupTab } from 'core/components';
import { isAws } from 'shared/customDimensions/constants';
import SamplingRatePanel from './SamplingRatePanel';
import { defaultSamplingRate } from './constants';

// This should only display for AWS
const LegacyPanel = () => (
  <div style={{ width: '50%', padding: '5px 0' }}>
    <p className="">
      On a per-file detected from S3, a maximum of 10k flows are processed. These are randomly sampled from the file and
      applied with a sampling rate to match the number of overall flows found in the file. Since the number of flows in
      a file can vary, this is considered &quot;adaptive sampling.&quot; This is on by default if none the above
      overrides are provided.
    </p>
  </div>
);

const UnsampledPanel = () => (
  <div style={{ width: '50%', padding: '5px 0' }}>
    <p className="">All flow logs will be processed. Your license limits are still enforced. </p>
  </div>
);

@inject('$cloudExportWizard')
@observer
class SamplingPanelWrapper extends Component {
  state = {
    activeTab: null
  };

  componentDidMount() {
    let activeTab;
    const { $cloudExportWizard } = this.props;
    const { form, selectedCloudProvider } = $cloudExportWizard;
    const selectedSamplingType = form.getValue('properties.sampling_type');
    const sampling = form.getValue('properties.sampling');
    const sampling_rate = form.getValue('properties.sampling_rate');

    if (selectedSamplingType) {
      activeTab = selectedSamplingType;
    } else if (sampling_rate) {
      activeTab = 'sampling_rate';
    } else if (sampling === false) {
      activeTab = 'unsampled';
    } else {
      activeTab = isAws(selectedCloudProvider) ? 'legacy' : 'unsampled';
    }
    this.setState({ activeTab });
  }

  handleTabChange = (activeTab) => {
    const { $cloudExportWizard } = this.props;
    const { form, onSampleChange } = $cloudExportWizard;
    let sampling = form.getValue('properties.sampling');

    if (activeTab === 'unsampled') {
      sampling = false;
      form.setValue('properties.sampling', false);
      form.setValue('properties.sampling_type', 'unsampled');
      form.setValue('properties.sampling_rate', 0);
    } else {
      sampling = true;
      form.setValue('properties.sampling', sampling);
    }

    if (activeTab === 'legacy') {
      form.setValue('properties.sampling_type', 'legacy');
      form.setValue('properties.sampling_rate', 0);
    }

    if (activeTab === 'sampling_rate') {
      form.setValue('properties.sampling_type', 'sampling_rate');
    }

    this.setState({ activeTab });
    onSampleChange({ sampling });
  };

  render() {
    const { $cloudExportWizard } = this.props;
    const { activeTab } = this.state;
    const { selectedCloudProvider, isFlowLogCollectionEnabled, form } = $cloudExportWizard;
    const hasSamplingByDefault = isAws(selectedCloudProvider);

    if (form.getValue('properties.sampling') === null) {
      form.setValue('properties.sampling', isFlowLogCollectionEnabled ? hasSamplingByDefault : null);
      form.setValue('properties.sampling_rate', isFlowLogCollectionEnabled ? defaultSamplingRate : null);
    }

    if (!activeTab) {
      return null;
    }

    return (
      <div style={{ marginTop: '-10px' }}>
        <h3>Sampling</h3>
        {isAws(selectedCloudProvider) ? (
          <ButtonGroupTabs omitTabsWrapper onChange={(val) => this.handleTabChange(val)} selectedTabId={activeTab}>
            <ButtonGroupTab id="legacy" title="Legacy" panel={<LegacyPanel />} />
            <ButtonGroupTab id="sampling_rate" text="Sampling Rate" panel={<SamplingRatePanel form={form} />} />
            <ButtonGroupTab id="unsampled" text="Unsampled" panel={<UnsampledPanel />} />
          </ButtonGroupTabs>
        ) : (
          <ButtonGroupTabs omitTabsWrapper onChange={(val) => this.handleTabChange(val)} selectedTabId={activeTab}>
            <ButtonGroupTab id="unsampled" text="Unsampled" panel={<UnsampledPanel />} />
            <ButtonGroupTab id="sampling_rate" text="Sampling Rate" panel={<SamplingRatePanel form={form} />} />
          </ButtonGroupTabs>
        )}
      </div>
    );
  }
}

export default SamplingPanelWrapper;
