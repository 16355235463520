/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { ENTITY_TYPES } from 'shared/hybrid/constants';
import ExpandableRow from 'app/components/admin/ExpandableRow';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';
import { OCI } from 'app/views/hybrid/maps/cloudDimensionConstants';

import { FiActivity } from 'react-icons/fi';
import { Flex, InfoBox, LinkButton, Text, FlexColumn } from 'core/components';
import { NameIdState } from './EntityExplorerRenderers';

const {
  VNIC,
  REGION,
  SUBNET,
  NAT_GATEWAY,
  SERVICE_GATEWAY,
  VNIC_ATTACHMENTS,
  INTERNET_GATEWAY,
  LOCAL_PEERING_GATEWAY,
  VIRTUAL_CLOUD_NETWORK,
  DYNAMIC_ROUTING_GATEWAY
} = ENTITY_TYPES.get('oci');

export const generateEntityMap = ({ entities }) => {
  const ENTITY_MAP = {
    [REGION]: {
      label: 'Regions',
      icon: <CloudIcon cloudProvider="oci" entity={REGION} iconSize={20} />,
      rowHeight: 28,
      expandHeight: 170,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const {
            id,
            [NAT_GATEWAY]: natGateways,
            [VIRTUAL_CLOUD_NETWORK]: vncs,
            [SERVICE_GATEWAY]: serviceGateways,
            [INTERNET_GATEWAY]: internetGateways,
            [DYNAMIC_ROUTING_GATEWAY]: drgGateways,
            [LOCAL_PEERING_GATEWAY]: localPeeringGateways
          } = model.get();
          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[REGION].expandHeight} p={1}>
              <Flex>
                <FlexColumn alignItems="flex-start" flex={3} p={1}>
                  <InfoBox heading="Region" fontWeight="medium">
                    {id}
                  </InfoBox>
                  <InfoBox heading="VNCs" fontWeight="medium">
                    {vncs.length}
                  </InfoBox>
                  <InfoBox heading="NAT Gateways" fontWeight="medium">
                    {natGateways.length}
                  </InfoBox>
                </FlexColumn>

                <FlexColumn alignItems="flex-start" flex={3} p={1}>
                  <InfoBox heading="Service Gateways" fontWeight="medium">
                    {serviceGateways.length}
                  </InfoBox>
                  <InfoBox heading="Internet Gateways" fontWeight="medium">
                    {internetGateways.length}
                  </InfoBox>
                  <InfoBox heading="Local Peering Gateways" fontWeight="medium">
                    {localPeeringGateways.length}
                  </InfoBox>
                </FlexColumn>

                <FlexColumn alignItems="flex-start" flex={1} p={1}>
                  <LinkButton
                    icon={FiActivity}
                    iconSize={16}
                    to={`/v4/core/quick-views/cloud/oci/region/${id}`}
                    blank
                    small
                    mb={2}
                  >
                    Open Quick-View
                  </LinkButton>
                  <InfoBox heading="DRG Gateways" fontWeight="medium">
                    {drgGateways.length}
                  </InfoBox>
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'id',
            label: 'Region',
            renderer: ({ value }) => <Text fontWeight="bold">{value}</Text>
          },
          {
            name: VIRTUAL_CLOUD_NETWORK,
            label: 'VNCs',
            ellipsis: false,
            renderer: ({ value }) => <Text>{value.length}</Text>
          },
          {
            name: NAT_GATEWAY,
            label: 'NAT Gateways',
            ellipsis: false,
            renderer: ({ value }) => <Text>{value.length}</Text>
          },
          {
            name: SERVICE_GATEWAY,
            label: 'Service Gateways',
            ellipsis: false,
            renderer: ({ value }) => <Text>{value.length}</Text>
          },
          {
            name: INTERNET_GATEWAY,
            label: 'Internet Gateways',
            ellipsis: false,
            renderer: ({ value }) => <Text>{value.length}</Text>
          },
          {
            name: LOCAL_PEERING_GATEWAY,
            label: 'Local Peering Gateways',
            ellipsis: false,
            renderer: ({ value }) => <Text>{value.length}</Text>
          },
          {
            name: DYNAMIC_ROUTING_GATEWAY,
            label: 'DRG Gateways',
            ellipsis: false,
            renderer: ({ value }) => <Text>{value.length}</Text>
          }
        ]
      }
    },
    [VIRTUAL_CLOUD_NETWORK]: {
      label: 'VCN',
      icon: <CloudIcon cloudProvider="oci" entity={VIRTUAL_CLOUD_NETWORK} iconSize={20} />,
      dimension: OCI.SRC.VCN,
      expandHeight: 210,
      searchable: true,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { id, region, displayName, compartmentId, tenancyId, cidrBlock } = model.get();

          const subnets = Object.values(entities[SUBNET] ?? {}).filter((subnet) => subnet.vcnId === id);
          const natGateways = Object.values(entities[NAT_GATEWAY] ?? {}).filter((nat) => nat.vcnId === id);

          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[VIRTUAL_CLOUD_NETWORK].expandHeight} p={1}>
              <Flex>
                <Flex flex={2}>
                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="Name" value={displayName} fontWeight="medium" copyable />
                    <InfoBox heading="Compartment" value={compartmentId} fontWeight="medium" copyable />
                    <InfoBox heading="Tenancy" value={tenancyId} fontWeight="medium" copyable />
                  </FlexColumn>
                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="Region" value={region} fontWeight="medium" mb={0} />
                    <InfoBox heading="CIDR" value={cidrBlock} fontWeight="medium" copyable />
                    {subnets.length > 0 && (
                      <InfoBox heading="Subnets Count" value={subnets.length} fontWeight="medium" />
                    )}
                    {natGateways.length > 0 && (
                      <InfoBox heading="NAT Gateways Count" value={natGateways.length} fontWeight="medium" />
                    )}
                  </FlexColumn>
                </Flex>

                <FlexColumn alignItems="flex-start" minWidth={145} flex={1} p={1}>
                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/oci?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: VIRTUAL_CLOUD_NETWORK, value: id })
                    )}`}
                    blank
                    small
                    mb={1}
                  >
                    Open in Map
                  </LinkButton>
                  <LinkButton
                    icon={FiActivity}
                    iconSize={16}
                    to={`/v4/core/quick-views/cloud/oci/vcn/${id}`}
                    blank
                    small
                    my={1}
                  >
                    Open Quick-View
                  </LinkButton>
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'id',
            label: 'Name / Id',
            renderer: ({ value, model }) => {
              const { displayName, lifecycleState } = model.get();
              return <NameIdState name={displayName} id={value} state={lifecycleState?.toLowerCase()} />;
            }
          },
          {
            name: 'cidrBlock',
            label: 'CidrBlock'
          }
        ]
      }
    },
    [SUBNET]: {
      label: 'Subnet',
      icon: <CloudIcon cloudProvider="oci" entity={SUBNET} iconSize={20} />,
      dimension: OCI.SRC.SUBNET_NAME,
      expandHeight: 210,
      searchable: true,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { id, region, tenancyId, cidrBlock, displayName, compartmentId, virtualRouterIp, subnetDomainName } =
            model.get();

          const vnics = Object.values(entities[VNIC] ?? {}).filter((vnic) => vnic.subnetId === id);
          const vnicAttachments = Object.values(entities[VNIC_ATTACHMENTS] ?? {}).filter(
            (vnic) => vnic.subnetId === id
          );

          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[VIRTUAL_CLOUD_NETWORK].expandHeight} p={1}>
              <Flex>
                <Flex flex={2}>
                  <FlexColumn alignItems="flex-start" flex={1} p={1}>
                    <InfoBox heading="Name" value={displayName} fontWeight="medium" copyable />
                    <InfoBox heading="Compartment" value={compartmentId} fontWeight="medium" copyable />
                    <InfoBox heading="Tenancy" value={tenancyId} fontWeight="medium" copyable />
                  </FlexColumn>
                  <FlexColumn alignItems="flex-start" flex={1} p={1}>
                    <InfoBox heading="Region" value={region} fontWeight="medium" copyable />
                    <InfoBox heading="CIDR" value={cidrBlock} fontWeight="medium" copyable />
                    <InfoBox heading="Virtual Router Ip" value={virtualRouterIp} fontWeight="medium" copyable />
                  </FlexColumn>
                  <FlexColumn alignItems="flex-start" flex={1} p={1}>
                    <InfoBox heading="Domain Name" value={subnetDomainName} fontWeight="medium" copyable />
                    {vnics.length > 0 && (
                      <InfoBox heading="VNICs Count" value={vnics?.length || 0} fontWeight="medium" />
                    )}
                    {vnicAttachments.length > 0 && (
                      <InfoBox heading="VNICs Attachments Count" value={vnicAttachments.length} fontWeight="medium" />
                    )}
                  </FlexColumn>
                </Flex>

                <FlexColumn alignItems="flex-start" minWidth={145} flex={1} p={1}>
                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/oci?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: SUBNET, value: id })
                    )}`}
                    blank
                    small
                    mb={1}
                  >
                    Open in Map
                  </LinkButton>
                  <LinkButton
                    icon={FiActivity}
                    iconSize={16}
                    to={`/v4/core/quick-views/cloud/oci/subnet/${id}`}
                    blank
                    small
                    my={1}
                  >
                    Open Quick-View
                  </LinkButton>
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'id',
            label: 'Name / Id',
            renderer: ({ value, model }) => {
              const { displayName, lifecycleState } = model.get();
              return <NameIdState name={displayName} id={value} state={lifecycleState?.toLowerCase()} />;
            }
          },
          {
            name: 'cidrBlock',
            label: 'CidrBlock'
          },
          {
            name: 'subnetDomainName',
            label: 'Subnet Domain Name'
          },
          {
            name: 'virtualRouterIp',
            label: 'Virtual Router IP'
          }
        ]
      }
    },
    [NAT_GATEWAY]: {
      label: 'NAT Gateway',
      icon: <CloudIcon cloudProvider="oci" entity={NAT_GATEWAY} iconSize={20} />,
      expandHeight: 210,
      searchable: true,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { id, region, displayName, compartmentId, tenancyId, natIp, routeTableId } = model.get();

          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[NAT_GATEWAY].expandHeight} p={1}>
              <Flex>
                <Flex flex={2}>
                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="Name" value={displayName} fontWeight="medium" copyable />
                    <InfoBox heading="Compartment" value={compartmentId} fontWeight="medium" copyable />
                    <InfoBox heading="Tenancy" value={tenancyId} fontWeight="medium" copyable />
                  </FlexColumn>
                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="Region" value={region} fontWeight="medium" />
                    <InfoBox heading="NAT IP" value={natIp} fontWeight="medium" copyable />
                    <InfoBox heading="Has Route Table" value={routeTableId ? 'Yes' : 'No'} fontWeight="medium" />
                  </FlexColumn>
                </Flex>

                <FlexColumn alignItems="flex-start" minWidth={145} flex={1} p={1}>
                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/oci?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: NAT_GATEWAY, value: id })
                    )}`}
                    blank
                    small
                    mb={1}
                  >
                    Open in Map
                  </LinkButton>
                  <LinkButton
                    icon={FiActivity}
                    iconSize={16}
                    to={`/v4/core/quick-views/cloud/oci/nat_gateway/${id}`}
                    blank
                    small
                    my={1}
                  >
                    Open Quick-View
                  </LinkButton>
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'id',
            label: 'Name / Id',
            renderer: ({ value, model }) => {
              const { displayName, lifecycleState } = model.get();
              return <NameIdState name={displayName} id={value} state={lifecycleState?.toLowerCase()} />;
            }
          },
          {
            name: 'natIp',
            label: 'NAT IP'
          }
        ]
      }
    },
    [SERVICE_GATEWAY]: {
      label: 'Service Gateway',
      icon: <CloudIcon cloudProvider="oci" entity={SERVICE_GATEWAY} iconSize={20} />,
      expandHeight: 210,
      searchable: true,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { id, region, displayName, compartmentId, tenancyId, vcnId } = model.get();

          const vcn = Object.values(entities[VIRTUAL_CLOUD_NETWORK] ?? {}).find((entityVcn) => entityVcn.id === vcnId);

          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[SERVICE_GATEWAY].expandHeight} p={1}>
              <Flex>
                <Flex flex={2}>
                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="Name" value={displayName} fontWeight="medium" copyable />
                    <InfoBox heading="Compartment" value={compartmentId} fontWeight="medium" copyable />
                    <InfoBox heading="Tenancy" value={tenancyId} fontWeight="medium" copyable />
                  </FlexColumn>
                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="Region" value={region} fontWeight="medium" copyable />
                    <InfoBox heading="VCN" value={vcn.displayName} fontWeight="medium" copyable />
                  </FlexColumn>
                </Flex>

                <FlexColumn alignItems="flex-start" minWidth={145} flex={1} p={1}>
                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/oci?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: SERVICE_GATEWAY, value: id })
                    )}`}
                    blank
                    small
                    mb={1}
                  >
                    Open in Map
                  </LinkButton>
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'id',
            label: 'Name / Id',
            renderer: ({ value, model }) => {
              const { displayName, lifecycleState } = model.get();
              return <NameIdState name={displayName} id={value} state={lifecycleState?.toLowerCase()} />;
            }
          },
          {
            name: 'lifecycleState',
            label: 'Status'
          }
        ]
      }
    },
    [INTERNET_GATEWAY]: {
      label: 'Internet Gateway',
      icon: <CloudIcon cloudProvider="oci" entity={INTERNET_GATEWAY} iconSize={20} />,
      expandHeight: 210,
      searchable: true,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { id, region, displayName, compartmentId, tenancyId, vcnId } = model.get();

          const vcn = Object.values(entities[VIRTUAL_CLOUD_NETWORK] ?? {}).find((entityVcn) => entityVcn.id === vcnId);

          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[INTERNET_GATEWAY].expandHeight} p={1}>
              <Flex>
                <Flex flex={2}>
                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="Name" value={displayName} fontWeight="medium" copyable />
                    <InfoBox heading="Compartment" value={compartmentId} fontWeight="medium" copyable />
                    <InfoBox heading="Tenancy" value={tenancyId} fontWeight="medium" copyable />
                  </FlexColumn>
                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="Region" value={region} fontWeight="medium" copyable />
                    <InfoBox heading="VCN" value={vcn.displayName} fontWeight="medium" copyable />
                  </FlexColumn>
                </Flex>

                <FlexColumn alignItems="flex-start" minWidth={145} flex={1} p={1}>
                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/oci?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: INTERNET_GATEWAY, value: id })
                    )}`}
                    blank
                    small
                    mb={1}
                  >
                    Open in Map
                  </LinkButton>
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'id',
            label: 'Name / Id',
            renderer: ({ value, model }) => {
              const { displayName, lifecycleState } = model.get();
              return <NameIdState name={displayName} id={value} state={lifecycleState?.toLowerCase()} />;
            }
          },
          {
            name: 'lifecycleState',
            label: 'Status'
          }
        ]
      }
    },
    [LOCAL_PEERING_GATEWAY]: {
      label: 'Local Peering Gateway',
      icon: <CloudIcon cloudProvider="oci" entity={LOCAL_PEERING_GATEWAY} iconSize={20} />,
      dimension: OCI.SRC[LOCAL_PEERING_GATEWAY],
      expandHeight: 210,
      searchable: true,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const {
            id,
            vcnId,
            region,
            peerId,
            tenancyId,
            displayName,
            compartmentId,
            peerAdvertisedCidr,
            peeringStatusDetails,
            isCrossTenancyPeering
          } = model.get();

          const vcn = Object.values(entities[VIRTUAL_CLOUD_NETWORK] ?? {}).find((entityVcn) => entityVcn.id === vcnId);
          const peer = Object.values(entities[LOCAL_PEERING_GATEWAY] ?? {}).find(
            (peerGateway) => peerGateway.id === peerId
          );

          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[LOCAL_PEERING_GATEWAY].expandHeight} p={1}>
              <Flex>
                <Flex flex={2}>
                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="Name" value={displayName} fontWeight="medium" copyable />
                    <InfoBox heading="Compartment" value={compartmentId} fontWeight="medium" copyable />
                    <InfoBox heading="Tenancy" value={tenancyId} fontWeight="medium" copyable />
                  </FlexColumn>
                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="Region" value={region} fontWeight="medium" copyable />
                    <InfoBox heading="CIDR" value={peerAdvertisedCidr} fontWeight="medium" copyable />
                    <InfoBox heading="VCN" value={vcn.displayName} fontWeight="medium" copyable />
                  </FlexColumn>

                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="Region" value={region} fontWeight="medium" copyable />
                    <InfoBox heading="CIDR" value={peerAdvertisedCidr} fontWeight="medium" copyable />
                    <InfoBox heading="VCN" value={vcn.displayName} fontWeight="medium" copyable />
                  </FlexColumn>

                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="Status" value={peeringStatusDetails} fontWeight="medium" />
                    <InfoBox
                      heading="isCrossTenancyPeering"
                      value={isCrossTenancyPeering ? 'Yes' : 'No'}
                      fontWeight="medium"
                      copyable
                    />
                    <InfoBox heading="Peer" value={peer.displayName} fontWeight="medium" copyable />
                  </FlexColumn>
                </Flex>

                <FlexColumn alignItems="flex-start" minWidth={145} flex={1} p={1}>
                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/oci?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: LOCAL_PEERING_GATEWAY, value: id })
                    )}`}
                    blank
                    small
                    mb={1}
                  >
                    Open in Map
                  </LinkButton>
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'id',
            label: 'Name / Id',
            renderer: ({ value, model }) => {
              const { displayName, lifecycleState } = model.get();
              return <NameIdState name={displayName} id={value} state={lifecycleState?.toLowerCase()} />;
            }
          },
          {
            name: 'peerAdvertisedCidr',
            label: 'CidrBlock'
          }
        ]
      }
    },
    [DYNAMIC_ROUTING_GATEWAY]: {
      label: 'DRG Gateway',
      icon: <CloudIcon cloudProvider="oci" entity={DYNAMIC_ROUTING_GATEWAY} iconSize={20} />,
      dimension: OCI.SRC[DYNAMIC_ROUTING_GATEWAY],
      expandHeight: 210,
      searchable: true,
      tableProps: {
        multiSelect: true,
        expandedRowRenderer: (model) => {
          const { id, region, displayName, compartmentId, tenancyId } = model.get();

          return (
            <ExpandableRow rowExpansionHeight={ENTITY_MAP[DYNAMIC_ROUTING_GATEWAY].expandHeight} p={1}>
              <Flex>
                <Flex flex={2}>
                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="Name" value={displayName} fontWeight="medium" copyable />
                    <InfoBox heading="Compartment" value={compartmentId} fontWeight="medium" copyable />
                    <InfoBox heading="Tenancy" value={tenancyId} fontWeight="medium" copyable />
                  </FlexColumn>
                  <FlexColumn alignItems="flex-start" flex={2} p={1}>
                    <InfoBox heading="Region" value={region} fontWeight="medium" copyable />
                  </FlexColumn>
                </Flex>

                <FlexColumn alignItems="flex-start" minWidth={145} flex={1} p={1}>
                  <LinkButton
                    icon="layout-auto"
                    iconSize={16}
                    to={`/v4/kentik-map/cloud/oci?sidebar=${encodeURIComponent(
                      JSON.stringify({ type: DYNAMIC_ROUTING_GATEWAY, value: id })
                    )}`}
                    blank
                    small
                    mb={1}
                  >
                    Open in Map
                  </LinkButton>
                </FlexColumn>
              </Flex>
            </ExpandableRow>
          );
        },
        columns: [
          {
            name: 'id',
            label: 'Name / Id',
            renderer: ({ value, model }) => {
              const { displayName, lifecycleState } = model.get();
              return <NameIdState name={displayName} id={value} state={lifecycleState?.toLowerCase()} />;
            }
          },
          {
            name: 'lifecycleState',
            label: 'Status'
          }
        ]
      }
    }
  };
  return ENTITY_MAP;
};
