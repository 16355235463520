import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import InterfaceLink from 'app/components/links/InterfaceLink';

@inject('$devices', '$dictionary')
@observer
class InterfaceDisplayValue extends Component {
  render = () => {
    const { $dictionary, $devices, model, property } = this.props;
    const { snmpMetadata } = $dictionary.dictionary;
    const mapping = snmpMetadata[property];
    const { interfaceKvs } = model.attributes;
    const value = interfaceKvs[property];

    if (mapping) {
      return `${mapping[value]} (${value})`;
    }

    if (property === 'isAbove' || property === 'isBelow') {
      const device = $devices.deviceSummariesById[model.get('device_id')];

      if (!device) {
        return value.split(',').join(', ');
      }

      return (
        <>
          {value.split(',').map((snmpId) => (
            <InterfaceLink key={snmpId} snmp_id={snmpId} device_name={device.device_name} mr="4px">
              {snmpId}
            </InterfaceLink>
          ))}
        </>
      );
    }

    return value;
  };
}

export default InterfaceDisplayValue;
