import React from 'react';
import { Text } from 'core/components';

const AlarmValueLabel = ({ large, small, className, children, ...props }) => {
  let fontSize = 14;

  if (large) {
    fontSize = 24;
  }
  if (small) {
    fontSize = 14;
  }

  return (
    <Text as="div" fontWeight="700" fontSize={fontSize} whiteSpace="nowrap" className={className} {...props}>
      {children}
    </Text>
  );
};

AlarmValueLabel.defaultProps = {
  large: false,
  small: false
};

export default AlarmValueLabel;
