import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('ProviderAggregate'))
class ProviderAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="Providers"
        resultsKey="Provider"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'Provider',
          aggregateFilters: [
            { metric: ['i_src_provider_classification', 'i_dst_provider_classification'] },
            { metric: ['i_src_provider_classification'] },
            { metric: ['i_dst_provider_classification'] },
            { metric: ['i_src_provider_classification', 'i_dst_provider_classification'] },
            { metric: ['i_src_provider_classification', 'i_dst_provider_classification'] }
          ]
        }}
        chartProps={{
          metrics: {
            Internal: ['i_dst_provider_classification', 'i_src_provider_classification'],
            Inbound: 'i_src_provider_classification',
            Outbound: 'i_dst_provider_classification'
          },
          topxMetricField: 'i_src|dst_provider_classification'
        }}
      />
    );
  }
}

export default ProviderAggregate;
