import Validator from 'validatorjs';
import { action, computed, observable, set, toJS } from 'mobx';
import { isEmpty, isFunction } from 'lodash';

import guid from 'core/util/guid';
import validOptionsValidation from './util/validOptionsValidation';
import registerValidation from './util/registerValidation';

registerValidation(validOptionsValidation);

function getDefaultValue(defaultValue) {
  return isFunction(defaultValue) ? defaultValue() : defaultValue;
}

export function getInitialValue({ value, defaultValue }) {
  return value !== undefined && value !== null ? value : getDefaultValue(defaultValue);
}

const falseyEquivalents = [null, undefined, ''];
/**
 * Non-strict-ish, deep comparison to replace strict _.isEqual() behavior.
 *
 * This has one intentional limitation specific to FieldState usage:
 *   null, undefined and empty string values are treated as equal.
 */
function isEqual(value1, value2) {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return value1.length === value2.length && value1.every((val1, idx) => isEqual(val1, value2[idx]));
  }
  if (typeof value1 === 'object' && typeof value2 === 'object') {
    const val1Keys = Object.keys(value1 || {});
    const val2Keys = Object.keys(value2 || {});
    const keys = Array.from(new Set(val1Keys.concat(val2Keys)));

    return keys.every((key) => isEqual(value1[key], value2[key]));
  }

  return value1 === value2 || (falseyEquivalents.includes(value1) && falseyEquivalents.includes(value2));
}

class FieldState {
  @observable
  _id = guid();

  @observable
  defaultValue = '';

  @observable
  disabled = false;

  @observable.ref
  errors = [];

  @observable
  label;

  @observable
  name;

  @observable
  placeholder;

  @observable
  pristine = true;

  @observable
  readOnly = false;

  @observable
  initialValue = undefined;

  @observable
  value;

  @observable
  validateDebounceDurationMs = 250;

  @observable
  forceUpdate = 0;

  @observable
  showPristineErrors;

  @observable
  helpText;

  form = undefined;

  initialConfig = undefined;

  parent = undefined;

  // If it's part of an array field
  rules = [];

  transform = undefined;

  debouncedFormValidate = undefined;

  validateOptions = true;

  constructor(form, config) {
    this.form = form;
    this.initialConfig = config;
    // we do this so that observable doesn't make copies. we want the actual reference to stick.
    this.adjacentFields = this.initialConfig.adjacentFields;
    delete this.initialConfig.adjacentFields;

    // this extends this with initialConfig, where all keys become observables #FYI
    set(this, this.initialConfig);

    this.init(config.value);
  }

  validateWithDebounce(options = {}) {
    if (this.debouncedFormValidate) {
      clearTimeout(this.debouncedFormValidate);
    }

    this.debouncedFormValidate = setTimeout(
      action(() => {
        if (options.removePristineState === true) {
          this.pristine = false;
        }
        this.form.validate();
        if (options.callback) {
          options.callback();
        }
      }),
      this.validateDebounceDurationMs
    );
  }

  @action
  init(value) {
    set(this, this.initialConfig);

    const initialValue = getInitialValue({ value, defaultValue: this.defaultValue });

    this.setValue(initialValue, { validate: false });
    this.initialValue = initialValue;
    this.pristine = true;
    this.showPristineErrors =
      this.initialConfig.showPristineErrors !== undefined
        ? this.initialConfig.showPristineErrors
        : this.form.options.showPristineErrors || this.dirty;
  }

  @action
  reset(hardResetValue = false) {
    if (!hardResetValue) {
      const initialValue = toJS(this.initialValue);
      // Note: this extra param only applies to ArrayFieldState (subclass situations)
      this.init(initialValue, { force: true });
    } else {
      this.init(hardResetValue, { force: true });
    }
  }

  @action
  setToDefault(options) {
    const defaultValue = toJS(getDefaultValue(this.defaultValue));
    this.setValue(defaultValue, options);
  }

  @action
  enable = () => {
    this.disabled = false;
  };

  @action
  disable = () => {
    this.disabled = true;
  };

  @action
  setPristine = (value) => {
    this.pristine = value;
  };

  @action
  setLabel = (label) => {
    this.label = label;
  };

  @action
  setDisabled = (disabled) => {
    this.disabled = disabled;
  };

  @action
  setOptions = (options) => {
    this.options = options;
  };

  @action
  setReadOnly = () => {
    this.readOnly = true;
  };

  @action
  setRules = (rules, options = {}) => {
    const { validate = true } = options;

    if (rules === this.rules) {
      return;
    }

    this.rules = rules;

    if (validate) {
      this.form.validateWithDefer();
    }
  };

  @action
  setFieldStates = (fieldStates) => {
    this.fieldStates = fieldStates;
    this.form.validate();
  };

  @action
  setValue(value, options = {}) {
    const { validate = true, pristine = true } = options;

    this.value = this._transformInValue(toJS(value));

    if (validate) {
      this.validateWithDebounce();
    }

    if (!pristine) {
      this.setPristine(false);
    }
  }

  /**
   * Force-push a render update. For use in combination with `<Field unsafe>`
   *
   * @deprecated
   * @private
   */
  @action
  _pushUpdate() {
    // only used for `unsafe` fields, manually triggering redraw after validation completes
    this.forceUpdate += 1;
  }

  @action
  onChange(e) {
    let value;
    const previousFieldValue = this.value;

    if (e && e.target) {
      /* eslint-disable prefer-destructuring */
      value = e.target.value;
    } else {
      value = e;
    }

    this.value = value;

    if (this.form.onChange) {
      this.form.onChange({
        form: this.form,
        formValues: this.form.getValues(),
        field: this,
        fieldValue: value,
        previousFieldValue
      });
    }

    this.validateWithDebounce({
      removePristineState: true,
      callback: () => this._pushUpdate()
    });
  }

  @computed
  get dirty() {
    const { model } = this.form;
    const value = this.getValue();
    const initialValue = this.getInitialValue();
    const defaultValue = toJS(getDefaultValue(this.defaultValue));

    return (
      !isEqual(value, initialValue) || (model?.isNew && !isEmpty(defaultValue) && !isEqual(initialValue, defaultValue))
    );
  }

  @computed
  get hasError() {
    return this.errors && this.errors.length > 0;
  }

  @computed
  get valid() {
    return !this.hasError;
  }

  @computed
  get errorString() {
    return this.errors.join('');
  }

  getOptionValues(obj) {
    if (Array.isArray(obj)) {
      const values = [];
      obj.forEach((opt) => {
        if (typeof opt === 'object' && opt.value === undefined) {
          values.push(...this.getOptionValues(opt));
        } else {
          values.push(opt.value === undefined ? opt : opt.value);
        }
      });
      return values;
    }
    return Object.keys(obj).reduce((acc, key) => [...acc, ...this.getOptionValues(obj[key])], []);
  }

  @action
  validate(values) {
    if (!this.rules && !this.options) {
      this.errors = [];
      return;
    }

    let rulesArray = toJS(this.rules || []);
    if (typeof rulesArray === 'string') {
      rulesArray = rulesArray.split('|');
    }

    const options = toJS(this.options);
    const messages = this.messages || {};
    const ruleIdx = rulesArray.findIndex((rule) => !!rule.validOptions);
    if (options && this.validateOptions) {
      const validOptions = { validOptions: this.getOptionValues(options) };
      if (ruleIdx === -1) {
        rulesArray.push(validOptions);
      } else {
        rulesArray.splice(ruleIdx, 1, validOptions);
      }
    } else if (ruleIdx !== -1) {
      rulesArray.splice(ruleIdx, 1);
    }

    rulesArray = toJS(rulesArray);
    const ruleParams = toJS(this.ruleParams || {});
    Object.keys(ruleParams).forEach((k) => {
      const v = ruleParams[k]();
      rulesArray = rulesArray.map((r) => r.replace(`$${k}`, v));
    });

    const rules = {
      [this.name]: rulesArray
    };

    const attributeNames = {
      [this.name]: this.label || this.name
    };

    const { model } = this.form;

    const validator = new Validator(
      {
        model,
        fieldName: this.name,
        originalFieldName: this.originalName,
        initialValue: this.initialValue,
        isEditAction: this.form.options.isEditAction,
        ...values
      },
      rules,
      messages
    );
    validator.setAttributeNames(attributeNames);

    if (validator.hasAsync) {
      validator.checkAsync(
        () => {
          this.errors = validator.errors.get(this.name);

          this.form.checkFieldErrors();
        },
        () => {
          this.errors = validator.errors.get(this.name);

          this.form.checkFieldErrors();
        }
      );
    } else {
      validator.check();
      this.errors = validator.errors.get(this.name);
    }
  }

  getProps() {
    return {
      name: this.name,
      value: this.value,
      placeholder: this.placeholder,
      disabled: this.disabled
    };
  }

  getInitialValue() {
    return toJS(this.initialValue);
  }

  getValue() {
    return this._transformOutValue(toJS(this.value));
  }

  _transformInValue(value) {
    return this.transform && this.transform.in ? this.transform.in(value, this.form, this) : value;
  }

  _transformOutValue(value) {
    return this.transform && this.transform.out ? this.transform.out(value, this.form, this) : value;
  }

  @action
  remove() {
    if (!this.parent) {
      console.warn('Calling remove() on a field with no parent', this.name);
      return;
    }

    this.parent.remove(this);
  }
}

export default FieldState;
