import { omit } from 'lodash';

const whitelistFields = [
  'autoFocus',
  'field',
  'inputRef',
  'rules',
  'options',
  'placeholder',
  'onEditComplete',
  'onEnterKey',
  'isEditing',
  'noEditLink',
  'hasEditLink',
  'switchLabel',
  'showOptionLabel',
  'showFieldLabel',
  'disabled'
];

export default function whitelistFieldProps(props) {
  return omit(props, whitelistFields);
}
