import { deepClone } from 'core/util';
import { overwriteFilter, getFilterFields } from '@kentik/ui-shared/filters';

export function getCustomDimensionsFromOverrides(overrides) {
  return getFilterFields(overrides.filters.filterGroups, true).map((filterField) => ({
    dimension: filterField.filterField,
    populator: filterField.filterValue
  }));
}

export function applyTenantCustomDimensionFilters(tenantCustomDimensions, filters) {
  const filtersObj = deepClone(filters);

  const filterMapping = tenantCustomDimensions.reduce((acc, customDimension) => {
    const mappingEntry = acc[customDimension.dimension];
    if (mappingEntry) {
      acc[customDimension.dimension] = {
        filterValue: `${mappingEntry.filterValue},${customDimension.populator}`
      };
    } else {
      acc[customDimension.dimension] = { filterValue: customDimension.populator };
    }
    return acc;
  }, {});

  overwriteFilter(filtersObj, filterMapping);
  return filtersObj;
}

export function augmentQueryForCustomDimensions(item, queryOverrides, custom_dimensions) {
  if (custom_dimensions && custom_dimensions.length) {
    return { ...queryOverrides, tenantPreviewFilters: custom_dimensions };
  }

  return { ...queryOverrides };
}
