import moment from 'moment';
import { ALERT_BASELINE_REASONS } from 'shared/alerting/constants';

// Used to determine data groupings for alert chart plot values.
export function parseBaselineUsed(key) {
  const reason = ALERT_BASELINE_REASONS[key];
  const { isMatch, isBaselineFallback } = reason || {};
  return reason ? [isMatch, isBaselineFallback] : [false, false];
}

export function getTimeRange(timeRange, startTime, endTime) {
  const now = moment().utc();

  const startRange = moment(startTime).utc().subtract(timeRange, 'seconds');

  const endRange = moment(endTime || startTime)
    .utc()
    .add(timeRange, 'seconds');

  const startLookback = moment(startTime).utc().subtract(timeRange, 'seconds');

  // If endrange is in the future, do a lookback instead
  if (endRange.isAfter(now)) {
    return {
      start: startLookback.toISOString(),
      end: now.toISOString()
    };
  }

  return {
    start: startRange.toISOString(),
    end: endRange.toISOString()
  };
}
