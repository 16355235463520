import React, { Component } from 'react';
import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { BiFolder } from 'react-icons/bi';
import { FiShare2 } from 'react-icons/fi';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { withRouter } from 'react-router-dom';
import SplitPane from 'react-split-pane';

import { OptionsMenu, RefreshButton } from 'app/components/dataviews/tools';
import DataViewWrapper from 'app/components/dataviews/DataViewWrapper';
import Legend from 'app/components/dataviews/views/legend/Legend';
import Page from 'app/components/page/Page';
import { fields, options } from 'app/forms/config/explorerQuery';
import { persistHash } from 'app/stores/query/urlHash';
import ExplorerQueryModel from 'app/stores/query/ExplorerQueryModel';
import ShareViewDialog from 'app/views/core/ShareViewDialog';
import storeLoader from 'app/stores/storeLoader';
import ViewSidebar from 'app/views/core/ViewSidebar';
import { Box, Button, Callout, Card, Flex, FlexColumn } from 'core/components';
import { FormComponent } from 'core/form';
import Model from 'core/model/Model';
import ExplorerSidebar from './ExplorerSidebar';

const splitPaneProps = {
  split: 'horizontal',
  pane2Style: { display: 'flex', overflow: 'auto' },
  defaultSize: 'calc(50vh - 75px)'
};

@storeLoader('$subscriptions')
@inject('$dataviews', '$explorer', '$setup', '$savedViews', '$sharedLinks')
@withRouter
@HotkeysTarget
@observer
class Explorer extends Component {
  debouncedResize;

  lastWindowHeight;

  splitPane;

  state = {
    sourceLink: null,
    sidebarOpen: 'query',
    publicShareUrlDialogOpen: false
  };

  constructor(props) {
    const { $explorer } = props;

    super(props);

    $explorer.clear();
    this.load('load', props);
  }

  componentDidMount() {
    this.lastWindowHeight = window.innerHeight;
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      this.load('reload', this.props);
    }
  }

  componentWillUnmount() {
    const { $explorer } = this.props;
    $explorer.destroy(true);
    window.removeEventListener('resize', this.handleWindowResize);

    if (this.unlistenQueryChange) {
      this.unlistenQueryChange();
    }
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
          global
          group="Data Explorer"
          combo="shift+q"
          label="Toggle Query Drawer"
          onKeyDown={() => this.handleSidebarToggle('query')}
        />
        <Hotkey
          global
          group="Data Explorer"
          combo="shift+v"
          label="Toggle Saved Views Drawer"
          onKeyDown={() => this.handleSidebarToggle('savedViews')}
          preventDefault
        />
        <Hotkey
          global
          group="Data Explorer"
          combo="shift+r"
          label="Refresh Data"
          onKeyDown={this.handleRefreshDataview}
        />
      </Hotkeys>
    );
  }

  toggleSharePublicUrlDialog = (e) => {
    const { $explorer } = this.props;
    const { dataview } = $explorer;
    const { publicShareUrlDialogOpen } = this.state;

    if (!publicShareUrlDialogOpen) {
      persistHash(dataview.hash);
    }
    this.toggleDialog(e, 'publicShareUrlDialogOpen');
  };

  toggleDialog(e, name) {
    e.preventDefault();
    e.stopPropagation();
    this.setState((state) => ({
      [name]: !state[name]
    }));
  }

  onClose = () => {
    this.setState({
      publicShareUrlDialogOpen: false
    });
  };

  handleRefreshDataview = () => {
    const { $explorer } = this.props;
    const { dataview } = $explorer;
    dataview.refresh();
    dataview.toggleComparisonWorkflow();
  };

  handlePaneResize = (size) => {
    const { $explorer } = this.props;
    const { dataview } = $explorer;
    dataview.reflow();
    localStorage.setItem('kentik.explorerPaneRatio', window.innerHeight - size);
  };

  handleWindowResize = () => {
    const { $explorer } = this.props;

    if (this.debouncedResize) {
      clearTimeout(this.debouncedResize);
    }

    this.debouncedResize = setTimeout(() => {
      if (!this.splitPane) {
        $explorer.dataview.reflow();
        return;
      }

      const dvHeight = this.splitPane.pane1.state ? this.splitPane.pane1.state.size : '';
      const newWindowHeight = window.innerHeight;
      if (parseInt(dvHeight, 10)) {
        this.splitPane.setSize(
          { size: Math.max(this.splitPane.props.minSize, dvHeight - (this.lastWindowHeight - newWindowHeight)) },
          this.splitPane.state
        );
      }
      $explorer.dataview.reflow();
      this.lastWindowHeight = newWindowHeight;
    }, 500);
  };

  handleSidebarToggle = (type) => {
    const { sidebarOpen } = this.state;
    this.setState({ sidebarOpen: sidebarOpen === type ? false : type });
  };

  handleSidebarClose = () => {
    this.setState({ sidebarOpen: false });
  };

  load(fn, props) {
    const { match, $explorer } = props;
    const { urlHash, params } = match.params;

    if (props.location.pathname.includes('/preset')) {
      $explorer.loadPreset();
    } else if (params && !urlHash) {
      $explorer.loadUrlParams(params);
    } else if (!params && !urlHash) {
      $explorer.loadDefaults();
    } else {
      this.updateRecentQueries(urlHash);
      $explorer[`${fn}Hash`](urlHash, undefined, params ? JSON.parse(decodeURIComponent(params)) : params);
    }
  }

  updateRecentQueries(hash) {
    const { $setup } = this.props;
    const recentQueries = $setup.getSettings('recentQueries', []);
    const idx = recentQueries.findIndex((q) => q === hash);
    if (idx >= 0) {
      recentQueries.splice(idx, 1);
    }
    recentQueries.unshift(hash);
    if (recentQueries.length > 10) {
      recentQueries.splice(9);
    }
    $setup.updateSettings({ recentQueries }, { showErrorToast: false });
  }

  splitPaneRef = (ref) => {
    this.splitPane = ref;
  };

  render() {
    const { $explorer, $dataviews, $savedViews } = this.props;
    const { publicShareUrlDialogOpen, sidebarOpen, sourceLink } = this.state;
    const { dataview } = $explorer;
    const { selectedQuery } = dataview.queryBuckets;

    if (!selectedQuery) {
      return <Page title="Data Explorer" pt={0} pb={0} canFullScreen canDockDrawer />;
    }

    const sidebarQuery = ExplorerQueryModel.createFromQueryModel(selectedQuery);

    let explorerChartHeight = 'calc(50vh - 25px)';
    const previousExplorerPaneRatio = localStorage.getItem('kentik.explorerPaneRatio');
    if (previousExplorerPaneRatio) {
      explorerChartHeight = Math.max(window.innerHeight - previousExplorerPaneRatio, 56);
    }

    // const hasUnsavedChanges = sourceLink && dataview.hash !== sourceLink.model.get('saved_query_id');
    // const canEdit =
    //   sourceLink &&
    //   ($auth.isAdministrator ||
    //     $auth.getActiveUserProperty('id') === sourceLink.model.get('user_id') ||
    //     sourceLink.model.get('share_level') === 'user');

    const viewCfg = $dataviews.getConfig(dataview.viewType);

    const buttonProps = {
      minimal: true,
      mx: '2px'
    };

    const subnavTools = (
      <>
        <RefreshButton buttonProps={buttonProps} model={dataview} allowLiveUpdate />
        <Button minimal icon={FiShare2} text="Share" onClick={this.toggleSharePublicUrlDialog} />
        <OptionsMenu buttonProps={buttonProps} dataview={dataview} showText />
        <Button
          text="Saved Views"
          icon={BiFolder}
          ml="2px"
          active={sidebarOpen === 'savedViews'}
          onClick={() => this.handleSidebarToggle('savedViews')}
          minimal
        />
        <Button
          text="Query"
          icon={AiOutlineMenuFold}
          ml="2px"
          active={sidebarOpen === 'query'}
          onClick={() => this.handleSidebarToggle('query')}
          minimal
        />
      </>
    );

    const dataviewWrapperStyles = {};
    if (dataview.queryBuckets.selectedQuery?.get('generatorMode')) {
      dataviewWrapperStyles.borderRadius = 4;
      dataviewWrapperStyles.border = 'thin';
      dataviewWrapperStyles.bg = 'subnavBackground';
    }

    if (dataview.viewType === 'table') {
      dataviewWrapperStyles.borderRadius = 4;
      dataviewWrapperStyles.border = 'thin';
    }

    const dataViewWrapper = (
      <DataViewWrapper
        dataview={dataview}
        sourceLink={sourceLink}
        fitToHeight
        viewProps={{
          showNativeLegend: false,
          hasFooter: true,
          height: '100%'
        }}
        headerProps={{
          showTitleLink: false,
          showViewTypeTag: true,
          shouldArrangeVertically: true,
          showLastUpdated: false,
          showDateRange: true,
          showAppliedFilters: true,
          showDataSources: true,
          showRunCpdFpaButton: true
        }}
        ref={this.wrapperRef}
        loadingText="Your query is running; if you have additional adjustments to make, please feel free to do so!"
      >
        {({ component, header, footer }) => (
          <>
            {/* {hasUnsavedChanges && canEdit && (
              <Box m={2}>
                <UnsavedChangesCallout
                  sourceLink={sourceLink}
                  onSaveChanges={this.handleUpdateSavedView}
                  onCancel={this.handleCancel}
                  onDismiss={this.handleDismiss}
                />
              </Box>
            )} */}
            {dataview.isLegacyMDS && (
              <Callout intent="warning" icon={BsExclamationCircleFill} m={2}>
                This chart is no longer fully editable using Data Explorer. Please use Bi-Directional charting, multiple
                metrics, and/or filter-based dimensions if you want to make changes.
              </Callout>
            )}
            <Box pb={2} overflow="hidden">
              {header}
            </Box>
            <Flex flex={1} overflow="auto" {...dataviewWrapperStyles}>
              {component}
            </Flex>
            <Box pt={1}>{footer}</Box>
          </>
        )}
      </DataViewWrapper>
    );

    let drawerContents = null;
    if (sidebarOpen === 'query' && sidebarQuery) {
      drawerContents = <ExplorerSidebar model={sidebarQuery} sourceLink={sourceLink} width={400} />;
    } else if (sidebarOpen === 'savedViews') {
      drawerContents = (
        <ViewSidebar
          collection={$savedViews.collection}
          fieldMap={{
            title: 'view_name',
            description: 'view_description',
            created_at: 'cdate',
            updated_at: 'edate'
          }}
          labelOptions={$savedViews.labelOptions}
          ownerOptions={$savedViews.ownerOptions}
          privacyOptions={$savedViews.privacyOptions}
          name="Saved View"
          onClose={this.handleSidebarClose}
          toLink={(id) => `/v4/library/saved-views/${id}`}
        />
      );
    }

    return (
      <FormComponent fields={fields} options={options} model={sidebarQuery}>
        <Page
          title="Data Explorer"
          pt={0}
          pb={0}
          subnavTools={subnavTools}
          drawerContents={drawerContents}
          drawerIsOpen={sidebarOpen}
          drawerOnClose={this.handleSidebarClose}
          drawerProps={{ showDockedInLargeFullScreen: sidebarOpen === 'query' }}
          canFullScreen
          canDockDrawer
        >
          <Flex width="100%" height="100%" flex={1} pt={2} pb={3} overflow="hidden" flexDirection="column">
            <FlexColumn flex={1} position="relative" overflow="hidden">
              <Flex flexDirection="column" flex={1} position="relative" overflow="hidden">
                {!viewCfg.showLegend && (
                  <Flex flex="1 1 auto" overflow="hidden">
                    {dataViewWrapper}
                  </Flex>
                )}
                {viewCfg.showLegend && (
                  <SplitPane
                    onDragFinished={this.handlePaneResize}
                    maxSize={-120}
                    minSize={56}
                    {...splitPaneProps}
                    defaultSize={explorerChartHeight}
                    ref={this.splitPaneRef}
                  >
                    {dataViewWrapper}
                    <Card display="flex" flex={1} m="1px" style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                      <Legend dataview={dataview} onChange={$explorer.onFormChange} />
                    </Card>
                  </SplitPane>
                )}
              </Flex>
            </FlexColumn>
          </Flex>

          <ShareViewDialog
            isOpen={publicShareUrlDialogOpen}
            path={`/api/ui/export/explorer/${dataview.hash}/email`}
            model={new Model({ content_type: 'dataview' })}
            dataview={dataview}
            onClose={this.onClose}
            disabledSubscription
          />
        </Page>
      </FormComponent>
    );
  }
}

export default Explorer;
