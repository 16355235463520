import React, { Component } from 'react';

import { Tooltip, Button } from 'core/components';
import { inject, observer } from 'mobx-react';

@inject('$dashboards', '$setup')
@observer
export default class FavoriteButton extends Component {
  onFavorite = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { $setup, onFavorite: onFavoriteProp, type, id, model, name, metadata } = this.props;
    if (model) {
      if (model.get('type') === 'saved-view') {
        $setup.toggleFavorite(`${model.get('type')}$${model.id}`, model.get('view_name'));
      } else {
        $setup.toggleFavorite(`${model.get('type')}$${model.id}`, model.get('dash_title'));
      }
    } else {
      $setup.toggleFavorite(`${type}$${id}`, name, metadata);
    }
    if (onFavoriteProp) {
      onFavoriteProp(model);
    }
  };

  isFavorite() {
    const { $setup, type, id, model } = this.props;
    if (model) {
      return $setup.isFavorite(`${model.get('type')}$${model.id}`);
    }
    return $setup.isFavorite(`${type}$${id}`);
  }

  render() {
    const { onFavorite: onFavoriteProp, model, ...props } = this.props;
    return (
      <Tooltip content={`${this.isFavorite() ? 'Remove from' : 'Add to'} Favorites`}>
        <Button
          icon={this.isFavorite() ? 'star' : 'star-empty'}
          color={this.isFavorite() ? 'yellow' : 'muted'}
          minimal
          onClick={this.onFavorite}
          {...props}
        />
      </Tooltip>
    );
  }
}
