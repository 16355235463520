export function applyWhitelist(options, whitelist) {
  return options.filter((option) => !whitelist || whitelist.has(option.value) || whitelist.has(option.category));
}

export function applyBlacklist(options, blacklist) {
  return options.filter((option) => !blacklist || (!blacklist.has(option.value) && !blacklist.has(option.category)));
}

export function applyRestrictions(options, field) {
  const { optionsBlacklist, optionsWhitelist } = field.initialConfig;
  return applyWhitelist(applyBlacklist(options, optionsBlacklist), optionsWhitelist);
}
