import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Intent } from '@blueprintjs/core';

import Button from 'core/components/Button';
import Box from 'core/components/Box';
import Flex from 'core/components/Flex';
import formConsumer from 'core/form/formConsumer';

import InputGroup from './InputGroup';
import Field from './Field';

@formConsumer
@observer
export default class InputMultiple extends Component {
  static defaultProps = {
    showAddButton: true,
    showRemoveButtons: true
  };

  render() {
    const {
      addButtonText = 'Add Value',
      children,
      field,
      form,
      inputStyle,
      disabled,
      name,
      showAddButton,
      showLabel = false,
      showRemoveButtons,
      inputProps,
      containerProps,
      onEnterKey,
      large
    } = this.props;

    const fieldArrayField = field || form.getField(name);

    const addButton = (
      <Button
        icon="plus"
        m={0}
        onClick={() => fieldArrayField.add('')}
        text={addButtonText}
        small={fieldArrayField.small}
      />
    );

    if (typeof children === 'function') {
      return children({
        field: fieldArrayField,
        value: fieldArrayField.getValue(),
        addButton,
        ...this.props
      });
    }

    return (
      <Flex>
        <Box {...containerProps}>
          {fieldArrayField.map((innerField, index, fieldsArr) => (
            <Field
              key={innerField._id}
              field={innerField}
              showLabel={showLabel}
              inputStyle={inputStyle}
              onEnterKey={onEnterKey}
              disabled={disabled}
              mb={index < fieldArrayField.size() - 1 ? '4px' : 0}
              large={large}
            >
              <InputGroup
                {...inputProps}
                readOnly
                autoComplete="off"
                rightElement={
                  showRemoveButtons && fieldsArr.length > 1 ? (
                    <Button
                      icon="cross"
                      onClick={() => innerField.remove()}
                      intent={Intent.DANGER}
                      minimal
                      small
                      m={0}
                    />
                  ) : null
                }
              />
            </Field>
          ))}
        </Box>
        {showAddButton && !disabled && <Box pl={1}>{addButton}</Box>}
      </Flex>
    );
  }
}
