import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Button from 'core/components/Button';

@inject('$syn', '$auth')
@observer
class TestPauseResumeButton extends Component {
  componentDidMount() {
    const { $syn } = this.props;

    $syn.plan.load();
  }

  render() {
    const { $auth, $syn, model, hideText, hideIcon, hideTextSuffix, ...rest } = this.props;

    let icon = 'pause';
    let text = 'Pause';
    let disabled = true;

    const status = model.get('test_status');
    if (!(status === 'A' || status === 'P')) {
      return null;
    }

    if (status === 'P') {
      icon = 'play';
      text = 'Resume';
      const loadingAgents = $syn.agents.loading;
      const pendingTestUsage = loadingAgents ? 0 : model.calculateTestCredits();
      const currentRemainingCredits = $syn.plan.calculateRemainingCredits({ originalTestUsage: 0, pendingTestUsage });
      const currentIsOverLimit = currentRemainingCredits <= 0;
      const no_credit_limits = $auth.getPermission('synthetics.no_credit_limits');
      disabled = $syn.plan.loadingUsage || loadingAgents || (currentIsOverLimit && !no_credit_limits);
    } else {
      disabled = false;
    }

    return (
      <Button
        disabled={disabled}
        icon={!hideIcon && icon}
        loading={model.togglingStatus}
        {...rest}
        onClick={() => model.togglePlayPause()}
      >
        {!hideText && (
          <>
            {text}
            {!hideTextSuffix && ' Test'}
          </>
        )}
      </Button>
    );
  }
}

export default TestPauseResumeButton;
