import React from 'react';
import { observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core';
import moment from 'moment';
import classNames from 'classnames';

import { Button, Box } from 'core/components';
import { getQueryTimeInterval, secondsIntervalToText, DEFAULT_DATETIME_FORMAT } from 'core/util/dateUtils';

const MOMENT_FN = moment.utc;
let debouncedSubmit = null;

const JumpButtons = ({ disabled, form, submitChanges, children, large }) => {
  const lookback_seconds = form.getValue('lookback_seconds');
  const starting_time = form.getValue('starting_time');
  const ending_time = form.getValue('ending_time');
  const interval = getQueryTimeInterval({ lookback_seconds, starting_time, ending_time });
  let textInterval = secondsIntervalToText(interval);
  const millisecondInterval = interval * 1000;

  if (lookback_seconds === 1 && interval >= 86400) {
    // 'this month'
    // if the calculated interval will have whole days, just display the days and leave out hours and minutes from the display
    const days = Math.floor(interval / 86400);
    textInterval = `${days} day${days > 1 ? 's' : ''}`;
  } else if (lookback_seconds === 2) {
    // 'last month'
    textInterval = '1 month';
  } else if (lookback_seconds === 2592000) {
    // 'last 30 days'
    textInterval = '30 days';
  }

  let forwardDisabled = true;
  if (ending_time) {
    const endTime = MOMENT_FN(ending_time).valueOf();
    forwardDisabled = endTime + millisecondInterval > MOMENT_FN().valueOf();
  } else if (starting_time) {
    const startTime = MOMENT_FN(starting_time).valueOf();
    forwardDisabled = startTime + millisecondInterval * 2 > MOMENT_FN().valueOf();
  }

  const applyChanges = () => {
    if (debouncedSubmit) {
      clearTimeout(debouncedSubmit);
    }
    debouncedSubmit = setTimeout(submitChanges, 1000);
  };

  const backOnClick = () => {
    let startTime;
    let endTime;

    if (starting_time) {
      startTime = MOMENT_FN(starting_time).valueOf() - millisecondInterval;
      endTime = MOMENT_FN(starting_time).valueOf();
    } else if (ending_time) {
      startTime = MOMENT_FN(ending_time).valueOf() - millisecondInterval * 2;
      endTime = MOMENT_FN(ending_time).valueOf() - millisecondInterval;
    } else {
      startTime = MOMENT_FN().valueOf() - millisecondInterval * 2;
      endTime = MOMENT_FN().valueOf() - millisecondInterval;
    }

    if (startTime && endTime) {
      form.setValue('lookback_seconds', 0);
      form.setValue('starting_time', MOMENT_FN(startTime).format(DEFAULT_DATETIME_FORMAT));
      form.setValue('ending_time', MOMENT_FN(endTime).format(DEFAULT_DATETIME_FORMAT));
    }

    applyChanges();
  };

  const forwardOnClick = () => {
    let startTime;
    let endTime;

    if (ending_time) {
      startTime = MOMENT_FN(ending_time).valueOf();
      endTime = MOMENT_FN(ending_time).valueOf() + millisecondInterval;
    } else if (starting_time) {
      startTime = MOMENT_FN(starting_time).valueOf() + millisecondInterval;
      endTime = MOMENT_FN(starting_time).valueOf() + millisecondInterval * 2;
    }

    if (startTime && endTime) {
      form.setValue('lookback_seconds', 0);
      form.setValue('starting_time', MOMENT_FN(startTime).format(DEFAULT_DATETIME_FORMAT));
      form.setValue('ending_time', MOMENT_FN(endTime).format(DEFAULT_DATETIME_FORMAT));
    }

    applyChanges();
  };

  return (
    <Box
      className={classNames({ [Classes.DISABLED]: disabled, [Classes.SMALL]: !large }, Classes.FORM_GROUP)}
      mb={0}
      flex={1}
    >
      <Box className={Classes.BUTTON_GROUP} width="100%">
        <Button icon="caret-left" title={`Back ${textInterval}`} onClick={backOnClick} disabled={disabled} />
        {children}
        <Button
          icon="caret-right"
          disabled={disabled || forwardDisabled}
          title={forwardDisabled ? undefined : `Forward ${textInterval}`}
          onClick={forwardOnClick}
        />
      </Box>
    </Box>
  );
};

export default observer(JumpButtons);
