import Validator from 'validatorjs';
import { get } from 'lodash';

import hasHtml from 'shared/validators/hasHtml';
import {
  AGENT_TYPES,
  getDSCPOptions,
  SYNTH_AGENT_CREDENTIAL_VERSION,
  VAULT_MATCH_REGEX,
  AGENT_CAPABILITIES,
  TASK_TYPES
} from 'shared/synthetics/constants';
import { MAX_WINDOW_LENGTH_MIN } from 'shared/alerting/constants';

import { TEST_INTERVAL_SECS, SYNTH_TRACE_PERIOD_DEFAULT } from 'app/util/constants';
import { trimmedArrFromStr } from 'core/util';
import $syn from 'app/stores/synthetics/$syn';
import $auth from 'app/stores/$auth';
import { isIpV6Valid } from 'core/util/ip';

const ipV6ErrorMessage = 'A selected agent does not support IPv6 addresses. ';
const tcpErrorMessage = 'Global agents do not have TCP ports open. ';

function trimmedIps(field) {
  const servers = get(field, 'validator.input.config.servers');
  return trimmedArrFromStr(servers || `${get(field, 'validator.input.config.target.value', '')}`);
}

Validator.register(
  'agentsSupportIPv6',
  function agentsSupportIPv6() {
    const agents = get(this, 'validator.input.config.agents', []);
    const family = get(this, 'validator.input.config.family', '');
    if (agents.length !== 0 && (trimmedIps(this).some((ip) => isIpV6Valid(ip)) || family === 'v6')) {
      return !agents.some((id) => $syn.agents.get(id).get('agent_family') === 'v4');
    }
    return true;
  },
  ipV6ErrorMessage
);

Validator.register(
  'credentialsAgentVersion',
  function credentialsAgentVersion() {
    const script = get(this, 'validator.input.config.http.trx_script', '');
    const body = get(this, 'validator.input.config.http.body', '');
    const formValues = get(this, 'validator.input.config.http.formValues', '');
    const headers = get(this, 'validator.input.config.http.headers', '');
    if (
      new RegExp(VAULT_MATCH_REGEX).test(script.toString()) ||
      new RegExp(VAULT_MATCH_REGEX).test(body.toString()) ||
      new RegExp(VAULT_MATCH_REGEX).test(JSON.stringify(headers)) ||
      new RegExp(VAULT_MATCH_REGEX).test(JSON.stringify(formValues))
    ) {
      const agentIds = get(this, 'validator.input.config.agents', []);
      const agents = $syn.agents.getAgentsByID(agentIds);
      return (
        agents.length > 0 &&
        agents.every((agent) => agent.get('metadata')?.capabilities?.includes(AGENT_CAPABILITIES.CREDENTIAL_DECRYPTION))
      );
    }
    return true;
  },
  `Credentials are only supported for agents running version >=${SYNTH_AGENT_CREDENTIAL_VERSION}`
);

Validator.register(
  'agentSupportsThroughput',
  function agentSupportsThroughput(value) {
    const tasks = get(this, 'validator.input.config.tasks', []);
    if (tasks.includes(TASK_TYPES.THROUGHPUT)) {
      const agent = $syn.agents.getAgentsByID([value]);
      if (agent.length > 0) {
        return agent[0]?.get('metadata')?.capabilities?.includes(AGENT_CAPABILITIES.THROUGHPUT);
      }
    }
    return true;
  },
  'Throughput tests are not supported on this agent'
);

Validator.register(
  'agentsSupportUdpEcho',
  function agentsSupportUdpEcho() {
    // For DFW1, we have no restrictions on agents and protocols
    if ($auth.currentRegion?.toLowerCase() === 'dfw1') {
      return true;
    }

    const protocol = get(this, 'validator.input.config.protocol', '');
    const agents = get(this, 'validator.input.config.agents', []);
    const reciprocal = get(this, 'validator.input.config.reciprocal', false);

    const target = get(this, 'validator.input.config.target.value', '');
    if (protocol === 'udp-echo' && target && !reciprocal) {
      const agent = $syn.agents.get(target);
      return !(agent?.isGlobal || agent?.isBrowser);
    }
    if (protocol === 'udp-echo' && target) {
      const targetAgent = $syn.agents.get(target);
      return (
        !(targetAgent?.isGlobal || targetAgent?.isBrowser) &&
        agents.every((id) => {
          const agent = $syn.agents.get(id);
          return !(agent?.isGlobal || agent?.isBrowser);
        })
      );
    }

    if (agents.length !== 0 && protocol === 'udp-echo') {
      return agents.every((id) => {
        const agent = $syn.agents.get(id);
        return !(agent?.isGlobal || agent?.isBrowser);
      });
    }

    return true;
  },
  'Global Agents do not support UDP-ECHO.'
);

Validator.register('no_html', (value) => !hasHtml(value), 'No html allowed.');

function hasGlobalAgents(agents = []) {
  return (
    agents.length !== 0 &&
    agents.some((id) => $syn.agents.get(id) && $syn.agents.get(id).get('agent_type') === AGENT_TYPES.GLOBAL)
  );
}

Validator.register(
  'agentsSupportTcp',
  function agentsSupportTcp() {
    // For DFW1, we have no restrictions on agents and protocols
    if ($auth.currentRegion?.toLowerCase() === 'dfw1') {
      return true;
    }

    const { attribute } = this;
    // for agent-to-agent, we will validate config.agents if reciprocal is true
    const reciprocalValue = get(this, 'validator.input.config.reciprocal');
    if (attribute === 'config.agents' && typeof reciprocalValue !== 'undefined' && !reciprocalValue) {
      return true;
    }
    const targetValue = get(this, 'validator.input.config.target.value');
    const pingProtocol = get(this, 'validator.input.config.protocol');
    const traceProtocol = get(this, 'validator.input.config.trace.protocol');
    const agentsValue = get(this, 'validator.input.config.agents', []);
    if (
      ['config.agents', 'config.target.value'].includes(attribute) &&
      hasGlobalAgents(attribute === 'config.target.value' ? [targetValue] : agentsValue)
    ) {
      return !(pingProtocol === 'tcp' || traceProtocol === 'tcp');
    }
    if (['config.protocol', 'config.trace.protocol'].includes(attribute)) {
      let agentsToValidate = agentsValue;
      if (typeof reciprocalValue !== 'undefined') {
        agentsToValidate = reciprocalValue ? agentsValue.concat([targetValue]) : [targetValue];
      }
      if (attribute === 'config.protocol' && hasGlobalAgents(agentsToValidate)) {
        return pingProtocol !== 'tcp';
      }
      if (attribute === 'config.trace.protocol' && hasGlobalAgents(agentsToValidate)) {
        return traceProtocol !== 'tcp';
      }
    }
    return true;
  },
  tcpErrorMessage
);

Validator.register(
  'pingDurationLessThanPeriod',
  function pingDurationLessThanPeriod() {
    const pingPeriod = parseInt(get(this, 'validator.input.config.ping.period'), 10);
    const pingCount = parseInt(get(this, 'validator.input.config.ping.count'), 10);
    const pingDelay = parseInt(get(this, 'validator.input.config.ping.delay'), 10);
    const periodInMs = pingPeriod * 1000; // convert period from seconds to milliseconds
    const pingDuration = pingDelay * pingCount;

    return pingDuration < periodInMs;
  },
  'Inter-probe delay multiplied by probes per ping can not exceed test frequency.'
);

function checkTimeoutAgainstPeriod(timeoutMs, periodSecs) {
  return !Number.isNaN(timeoutMs) && !Number.isNaN(periodSecs) && timeoutMs < periodSecs * 1000;
}

Validator.register(
  'timeoutLessThanPeriod',
  function timeoutLessThanPeriod() {
    return checkTimeoutAgainstPeriod(
      parseInt(get(this, 'validator.input.config.ping.expiry'), 10),
      parseInt(get(this, 'validator.input.config.ping.period'), 10)
    );
  },
  'Timeout value must be less than test frequency'
);

Validator.register(
  'traceTimeoutLessThanPeriod',
  function traceTimeoutLessThanPeriod() {
    const pingPeriod = parseInt(get(this, 'validator.input.config.ping.period'), 10) || 0;
    const periodSecs = pingPeriod < SYNTH_TRACE_PERIOD_DEFAULT ? SYNTH_TRACE_PERIOD_DEFAULT : pingPeriod;
    return checkTimeoutAgainstPeriod(parseInt(get(this, 'validator.input.config.trace.expiry'), 10), periodSecs);
  },
  'Trace timeout value must not exceed test frequency'
);

Validator.register(
  'isValidTestFrequency',
  function isValidTestFrequency() {
    const config = get(this, 'validator.input.config');
    const periodSecs = parseInt(config.period || config.ping?.period, 10) || 60;
    return TEST_INTERVAL_SECS.includes(periodSecs);
  },
  'Invalid test frequency'
);

Validator.register(
  'isValidPeriodForThroughput',
  function isValidPeriodForThroughput() {
    const config = get(this, 'validator.input.config');
    const periodSecs = parseInt(config.period || config.ping?.period, 10) || 60;

    const tasks = get(this, 'validator.input.config.tasks');
    if (tasks.includes('throughput') && periodSecs < 60) {
      return false;
    }
    return TEST_INTERVAL_SECS.includes(periodSecs);
  },
  'Throughput tests require a test frequency of 60 seconds or more'
);

Validator.register(
  'alertsDurationGreaterThanPeriod',
  function alertsDurationGreaterThanPeriod() {
    // Ensure alerts duration is at least or greater than the (period * alertsCount + period)
    const config = get(this, 'validator.input.config');
    const period = parseInt(config.period || config.ping?.period, 10) || 60;
    const testFreq = period * 1000; // coverted to milliseconds
    const alertsCount = parseInt(get(this, 'validator.input.config.activate.times'), 10) || 0;
    const alertsTimeWindowUnit = get(this, 'validator.input.config.activate.timeUnit');
    const alertsTimeWindow = parseInt(get(this, 'validator.input.config.activate.timeWindow'), 10) || 0;
    const alertsDuration =
      alertsTimeWindowUnit === 'm' ? alertsTimeWindow * 60 * 1000 : alertsTimeWindow * 60 * 60 * 1000; // convert to milliseconds
    return alertsDuration >= testFreq * (alertsCount + 1);
  },
  'Increase the alert time range or the overall test frequency to continue.'
);

Validator.register(
  'maxAlertExecutionTime',
  function maxAlertExecutionTime() {
    const alertsTimeWindowUnit = get(this, 'validator.input.config.activate.timeUnit');
    const alertsTimeWindow = parseInt(get(this, 'validator.input.config.activate.timeWindow'), 10) || 0;
    const inputValue = alertsTimeWindowUnit === 'm' ? alertsTimeWindow : alertsTimeWindow * 60;
    return inputValue <= MAX_WINDOW_LENGTH_MIN;
  },
  `Overall test execution time cannot exceed ${MAX_WINDOW_LENGTH_MIN} minutes.`
);

Validator.register(
  'bgpPrefixMaxValidator',
  function bgpPrefixMaxValidator() {
    const prefixes = get(this, 'validator.input.config.bgp.prefix')
      .split(',')
      .map((p) => p.trim())
      .filter((p) => !!p);
    const include_covered = get(this, 'validator.input.config.bgp.include_covered');

    return !include_covered || prefixes.length <= 10;
  },
  'A test can have no more than 10 prefixes. If you have more, create multiple tests.'
);

Validator.register(
  'bgpPrefixMaxAddressSpaceValidator',
  function bgpPrefixMaxAddressSpaceValidator() {
    const prefixes = get(this, 'validator.input.config.bgp.prefix')
      .split(',')
      .map((p) => p.trim())
      .filter((p) => !!p);
    const include_covered = get(this, 'validator.input.config.bgp.include_covered');

    return !(
      include_covered &&
      !!prefixes.filter((p) => {
        const addressSpace = p.split('/')[1];

        return addressSpace ? parseInt(addressSpace.trim(), 10) < 15 : false;
      }).length
    );
  },
  'Minimum prefix length can be /15. If you have less specific prefixes, turn off "Include more specific prefixes"'
);

Validator.registerImplicit(
  'basicBGPRequirementValidator',
  function basicBGPRequirementValidator(value) {
    const tasks = get(this, 'validator.input.config.tasks');
    return tasks.includes('bgp-monitor') ? !!value : true;
  },
  'Field required when BGP monitoring is enabled'
);

Validator.registerImplicit(
  'BGPOriginRequirementValidator',
  function BGPOriginRequirementValidator(value) {
    const tasks = get(this, 'validator.input.config.tasks');
    const check_rpki = get(this, 'validator.input.config.bgp.check_rpki');
    const upstream = get(this, 'validator.input.config.bgp.upstream');

    return tasks.includes('bgp-monitor') ? check_rpki || upstream.length > 0 || value.length > 0 : true;
  },
  'Field required when BGP monitoring is enabled, unless Allowed Upstream ASN(s) is configured'
);

Validator.registerImplicit(
  'BGPUpstreamRequirementValidator',
  function BGPUpstreamRequirementValidator(value) {
    const tasks = get(this, 'validator.input.config.tasks');
    const check_rpki = get(this, 'validator.input.config.bgp.check_rpki');
    const origin = get(this, 'validator.input.config.bgp.origin');

    return tasks.includes('bgp-monitor') ? check_rpki || origin.length > 0 || value.length > 0 : true;
  },
  'Field required when BGP monitoring is enabled, unless Allowed Origin ASN(s) or Check RPKI is configured'
);

Validator.register(
  'DSCPValidator',
  (value) => getDSCPOptions().some((option) => option.value === value),
  'Invalid DSCP Option'
);

Validator.register(
  'MinPingPortValidator',
  function MinPingPortValidator(value) {
    const protocol = get(this, 'validator.input.config.protocol');
    const minValue = protocol === 'icmp' ? 0 : 1;

    return parseInt(value, 10) >= minValue;
  },
  'The Target Port must be at least 1.'
);

Validator.register(
  'MinTraceroutePortValidator',
  function MinTraceroutePortValidator(value) {
    const protocol = get(this, 'validator.input.config.trace.protocol');
    const minValue = protocol === 'icmp' ? 0 : 1;

    return parseInt(value, 10) >= minValue;
  },
  'The Target Port must be at least 1.'
);

Validator.register(
  'AreAgentAlertThresholdsLongEnough',
  function AreAgentAlertThresholdsLongEnough() {
    const threshold = +get(this, 'validator.input.agent_alert_rule_config.thresholds[0].thresholdSeconds');
    const type = get(this, 'validator.input.agent_alert_rule_config.thresholds[0].thresholdType');

    if (type === 'seconds') {
      return threshold >= 300;
    }

    if (type === 'minutes') {
      return threshold >= 5;
    }
  },
  'Threshold must be a minimum of 5 minutes'
);

Validator.register(
  'AreAgentAlertThresholdsIntegers',
  function AreAgentAlertThresholdsIntegers() {
    const threshold = +get(this, 'validator.input.agent_alert_rule_config.thresholds[0].thresholdSeconds');
    const type = get(this, 'validator.input.agent_alert_rule_config.thresholds[0].thresholdType');
    const seconds = type === 'minutes' ? threshold * 60 : threshold;

    return Number.isInteger(seconds);
  },
  'Threshold must be integers when reduced to seconds'
);

/*
  Meant to be applied to warning threshold fields in config.healthSettings.
  Compares the warning threshold against an adjacent field (critical threshold),
  passing validation if the warning does not overlap the critical threshold.

  Example rules:

  { healthThreshold: { lessThan: 'latencyCritical' } }

  { healthThreshold: { greaterThan: 'certExpiryCritical' } }
*/
Validator.register(
  'healthThreshold',
  function healthThreshold(value, params) {
    const { input } = this.validator;
    const { lessThan, greaterThan } = params;
    const adjacentFieldName = lessThan || greaterThan;

    if (adjacentFieldName) {
      // the value of the adjacent threshold --- we know it's under config.healthSettings
      // we pre-configure the string to shorten the rule implementation in the form config, allowing it to read a bit more naturally
      const adjacentValue = get(input, `config.healthSettings.${adjacentFieldName}`);

      if (Number.isFinite(value) && Number.isFinite(adjacentValue)) {
        if (lessThan) {
          return value < adjacentValue;
        }

        return value > adjacentValue;
      }
    }

    return false;
  },
  'This threshold cannot overlap the adjacent threshold'
);

// Passes validation when the alerting grace period is at least 3 times the test frequency
Validator.register(
  'gracePeriodAtLeastTripleTheTestFrequency',
  function gracePeriodAtLeastTripleTheTestFrequency(gracePeriod) {
    const { config } = this.validator.input;

    if (config.healthSettings.disableAlerts === true) {
      // bypass validation when alerting is disabled
      return true;
    }

    const gracePeriodInMinutes = parseInt(gracePeriod);
    const testFrequencyInSeconds = parseInt(config.period || config.ping?.period);
    const gracePeriodInSeconds = gracePeriodInMinutes * 60;

    return gracePeriodInSeconds >= testFrequencyInSeconds * 3;
  },
  'Alerting grace period must be at least 3 times the test frequency'
);

Validator.register(
  'throughputBandwidthValidator',
  function throughputBandwidthValidator(bandwidth) {
    const { config } = this.validator.input;
    if (config?.throughput?.protocol?.toLowerCase() === 'tcp' && bandwidth === 0) {
      return true;
    }
    return Number.isInteger(bandwidth) && bandwidth >= 1 && bandwidth <= 1000;
  },
  `Invalid bandwidth value for throughput test. TCP can either be set to 0, for maximum bandwidth, 
  or an integer between 1 and 1000 for a specific bandwidth limit. UDP must be an integer between 1 and 1000.`
);

export { ipV6ErrorMessage, tcpErrorMessage };
