import React, { Component } from 'react';
import { Flex, Heading, Icon, Text } from 'core/components';
import Collapse from 'core/components/Collapse';
import PropTypes from 'prop-types';

class DrawerSection extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    defaultOpen: PropTypes.bool,
    onChange: PropTypes.func
  };

  static defaultProps = {
    defaultOpen: false,
    onChange: () => {}
  };

  state = {
    open: false
  };

  constructor(props) {
    super(props);

    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      open: props.defaultOpen
    };
  }

  componentDidUpdate(prevProps) {
    const { defaultOpen } = this.props;
    if (defaultOpen !== prevProps.defaultOpen) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ open: defaultOpen });
    }
  }

  toggleOpen = () => {
    const { onChange } = this.props;
    this.setState(
      ({ open }) => ({
        open: !open
      }),
      () => {
        const { open } = this.state;
        onChange(open);
      }
    );
  };

  render() {
    const { children, label, headerProps, ...props } = this.props;
    const { open } = this.state;

    return (
      <>
        <Heading
          level={6}
          mb={1}
          fontWeight="heavy"
          borderBottom="thin"
          onClick={this.toggleOpen}
          style={{ cursor: 'pointer' }}
          {...headerProps}
        >
          <Flex gap={1}>
            <Icon color="muted" icon={open ? 'caret-down' : 'caret-right'} />
            <Text width="100%">{label}</Text>
          </Flex>
        </Heading>
        <Collapse isOpen={open} {...props}>
          {children}
        </Collapse>
      </>
    );
  }
}

export default DrawerSection;
