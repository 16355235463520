import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Card } from 'core/components';
import VerticalDetailTabs from 'app/components/detailTabs/VerticalDetailTabs';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import Page from 'app/components/page/Page';
import InsightsMenu from 'app/components/navbar/InsightsMenu';

import AggregateViewHeader from '../aggregate/AggregateViewHeader';
import parentLinks from '../NetworkExplorerParentLink';

@inject('$lookups', '$tabs')
@observer
class ProtocolDetails extends Component {
  constructor(props) {
    super(props);

    const { $tabs } = props;
    const tabs = $tabs.getTabs();

    this.state.tabBlacklist = [tabs.PROTO.id];
  }

  state = {
    protocolLookup: undefined,
    filters: [],
    lookback_seconds: 86400,
    aggregateType: 'p95th_bits_per_sec'
  };

  componentDidMount() {
    this.lookupProtocol();
  }

  componentDidUpdate(prevProps) {
    if (this.protocol !== prevProps.match.params.protocol) {
      this.lookupProtocol();
    }
  }

  lookupProtocol = () => {
    const { $lookups } = this.props;
    if (this.protocol) {
      $lookups.protocols(this.protocol).then(([protocol]) => {
        this.setState(() => ({ protocolLookup: protocol }));
      });
    }
  };

  get protocol() {
    const { match } = this.props;
    return match.params.protocol;
  }

  get filters() {
    const { filters } = this.state;
    const detailFilter = {
      filterField: 'protocol',
      filterValue: this.protocol
    };

    return filters.concat(detailFilter);
  }

  handleFiltersApply = (filters) => {
    this.setState({ filters });
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds });
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType });
  };

  render() {
    const { protocolLookup, aggregateType, filters, lookback_seconds, tabBlacklist } = this.state;
    const title = protocolLookup ? `${protocolLookup.matcher} (${this.protocol})` : 'Protocol Details';

    return (
      <Page
        title={title}
        parentLinks={[...parentLinks, { link: '/v4/core/quick-views/protocol', label: 'Protocols' }]}
        insightsMenu={<InsightsMenu query="fetchProtocolInsights" params={this.protocol} />}
        scrolls
        showExport
      >
        <AggregateViewHeader
          title={protocolLookup ? protocolLookup.label : this.protocol}
          aggregateType={aggregateType}
          filters={filters}
          lookbackSeconds={lookback_seconds}
          onFiltersChange={this.handleFiltersApply}
          onAggregateChange={this.handleAggregationChange}
          onMetricsChange={this.handleAggregationChange}
          onTimeRangeChange={this.handleLookbackChange}
        />

        <Card mb={2}>
          <TabbedChart overrides={{ lookback_seconds, aggregateTypes: [aggregateType] }} simpleFilters={this.filters} />
        </Card>

        <VerticalDetailTabs
          blacklist={tabBlacklist}
          simpleFilters={this.filters}
          queryOverrides={{ lookback_seconds, aggregateTypes: [aggregateType] }}
        />
      </Page>
    );
  }
}

export default ProtocolDetails;
