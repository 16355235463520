import React from 'react';

import { withRouter } from 'react-router-dom';
import AnchorButton from './AnchorButton';

function LinkButton({ to, history, blank, state, match, location, keepSearch, ...rest }) {
  const onClick = (e) => {
    if (history?.location?.search && keepSearch) {
      to += history.location.search;
    }

    if (e.button === 0 && !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) && !rest.target) {
      e.preventDefault();

      if (blank) {
        const url = (to || '').startsWith('http') ? to : `${window.location.origin}${to}`;
        window.open(url, '_blank');
      } else {
        history.push(to, Object.assign(state ?? {}, { from: location?.pathname ?? '' }));
      }
    }
  };

  return <AnchorButton href={to} onClick={onClick} {...rest} />;
}

export default withRouter(LinkButton);
