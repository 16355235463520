import { Flex, Icon } from 'core/components';
import React from 'react';

export default function PolicyType({ value }) {
  const textMap = { No: 'None' };
  const iconMap = {
    Selective: <Icon color="warning" icon="warning-sign" iconSize={12} />,
    Restrictive: <Icon color="danger" icon="error" iconSize={12} />,
    Open: <Icon color="success" icon="success" iconSize={12} />
  };

  return (
    <Flex gap="6px">
      {iconMap[value] || null}
      <div>{textMap[value] ?? value}</div>
    </Flex>
  );
}
