import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';
import BaseMultiSeriesLineOrAreaGraph from '../../BaseMultiSeriesLineOrAreaGraph';

const chartOptions = {
  yAxis: {
    min: 0,
    type: 'logarithmic',
    custom: {
      allowNegativeLog: true
    }
  },
  tooltip: {
    pointFormatter() {
      return `<div>
        <span style="color:${this.color}">●</span> ${this.series.name}: <b>${this.y.toFixed(0)}</b><br/>
      </div>`;
    },
    excludeZeros: true
  }
};

@inject('$app', '$auth', '$dataviews')
@observer
class BgpPathChangesTimeline extends BaseMultiSeriesLineOrAreaGraph {
  constructor(props) {
    super(props, { chartOptions });
  }
}

export default withTheme(BgpPathChangesTimeline);
