import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, Text, Box, Button, Icon, Popover, Suspense, Spinner, Collapse } from 'core/components';
import { InputGroup } from 'core/form';
import classNames from 'classnames';
import omitFieldProps from 'core/form/components/util/omitFieldProps';

@inject('$marketIntel')
@observer
export default class MarketFilter extends Component {
  state = {
    searchTerm: '',
    marketList: {},
    loading: true,
    navStack: [],
    isOpen: false
  };

  static getDerivedStateFromProps(props, state) {
    const { $marketIntel, fieldValue } = props;
    const { id: store_id } = $marketIntel.filterObj;
    if (!state.geoId) {
      if (fieldValue) {
        return { geoId: fieldValue };
      }
      if (store_id) {
        return { geoId: store_id };
      }
      return { geoId: 'planet$earth' };
    }
    return null;
  }

  componentDidMount() {
    const { $marketIntel } = this.props;
    const { geoId } = this.state;
    $marketIntel.getMarketsHierarchy().then((marketList) => {
      const navStack = this.buildNavStack(geoId, marketList);
      this.setState({ marketList, loading: false, navStack });
    });
  }

  buildNavStack(id, marketList) {
    const { parent_list = [] } = marketList[id];
    const stack = parent_list.length === 0 ? [] : this.buildNavStack(parent_list[0], marketList);
    stack.push(id);

    return stack;
  }

  handleSearch = (e) => {
    const searchTerm = e.target.value;
    this.setState({ searchTerm });
  };

  resetSearch = () => {
    this.setState({ searchTerm: '' });
  };

  handleInteraction = (nextOpenState) => {
    const { disabled } = this.props;
    this.setState({ isOpen: !disabled && nextOpenState });
  };

  handleClick = (id, options) => {
    const { close = false } = options || {};
    const { onChange } = this.props;
    const { navStack, marketList } = this.state;
    let stack = navStack;

    if (navStack.at(-1) === id) {
      stack.pop();
    } else {
      stack = this.buildNavStack(id, marketList);
    }

    if (stack.length === 0) {
      stack.push('planet$earth');
    }

    const { id: newId, geo_scope, geo_name, name: marketName } = marketList[stack.at(-1)];
    onChange({ id: newId, geo_scope, geo_name, marketName });
    this.setState({ navStack: stack, isOpen: !close });
  };

  renderSearchOptions() {
    const { marketList, searchTerm } = this.state;

    const filteredMarkets = Object.values(marketList).filter(({ name = '' }) =>
      name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return filteredMarkets.map((market) => {
      const { id, name } = market;

      return (
        <Box key={id}>
          <Flex
            alignItems="center"
            className="bp4-menu-item"
            onClick={() => this.handleClick(id, { close: true })}
            overflow="hidden"
          >
            <Text flex={1} ellipsis>
              {name}
            </Text>
          </Flex>
        </Box>
      );
    });
  }

  renderOptions(optionsObj) {
    const { navStack, marketList } = this.state;
    if (!optionsObj) {
      return null;
    }
    const { id, name, child_list = [] } = optionsObj;
    const selected = navStack.includes(id);

    const className = classNames('bp4-menu-item', {
      'bp4-intent-primary': selected
    });

    return (
      <Box key={id} overflow="hidden">
        <Flex
          alignItems="center"
          className={className}
          onClick={() => this.handleClick(id, { close: child_list.length === 0 })}
          overflow="hidden"
        >
          {selected && child_list.length > 0 && <Icon icon="caret-down" mr="4px" ml="-4px" />}
          <Text fontWeight={selected && child_list.length > 0 ? 'bold' : 'normal'} flex={1} ellipsis>
            {name}
          </Text>
        </Flex>
        {child_list.length > 0 && (
          <Collapse isOpen={selected}>
            <Box ml={2} overflow="hidden">
              {child_list.map((child) => this.renderOptions(marketList[child]))}
            </Box>
          </Collapse>
        )}
      </Box>
    );
  }

  render() {
    const { disabled } = this.props;
    const { searchTerm, marketList, loading, navStack, isOpen } = this.state;

    return (
      <Box>
        <Popover
          position="bottom-left"
          isOpen={isOpen}
          onInteraction={this.handleInteraction}
          modifiers={{
            keepTogether: { enabled: true },
            flip: { enabled: true, boundariesElement: 'viewport' },
            offset: { enabled: true, offset: '0,2px' }
          }}
          content={
            <Box maxHeight={400} width={300} overflow="hidden">
              <InputGroup
                onChange={this.handleSearch}
                placeholder="Search by Region, Country"
                value={searchTerm}
                rightElement={
                  searchTerm !== '' ? (
                    <Button
                      icon="cross"
                      position="relative"
                      top={4}
                      right={4}
                      onClick={this.resetSearch}
                      minimal
                      small
                    />
                  ) : undefined
                }
                p="4px"
                pb={0}
              />
              <Suspense loading={loading} fallback={<Spinner size={20} intent="primary" />}>
                <Box maxHeight={340} p="4px" className="bp4-menu" style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                  {searchTerm === '' && marketList && this.renderOptions(marketList.planet$earth)}
                  {searchTerm !== '' && this.renderSearchOptions()}
                </Box>
              </Suspense>
            </Box>
          }
          target={
            <Box overflow="hidden">
              <Button
                text={
                  <Box textAlign="left" py="4px">
                    <Text as="div" fontSize="small" color="muted" pb="1px">
                      Geo Market
                    </Text>
                    <Flex overflowX="hidden">
                      {navStack.map((geo, index) => (
                        <Flex key={geo} alignItems="center" pr="4px">
                          {index !== 0 && <Icon icon="arrow-right" iconSize={12} pr="4px" />}
                          <Text as="div" fontWeight="bold" fontSize="small" ellipsis>
                            {marketList[geo]?.name}
                          </Text>
                        </Flex>
                      )) || '---'}
                    </Flex>
                  </Box>
                }
                minWidth={150}
                rightIcon="caret-down"
                disabled={disabled}
                px={1}
                py={0}
              />
            </Box>
          }
        />
      </Box>
    );
  }
}

export const MarketFilterForm = observer((props) => {
  const { field } = props;
  return <MarketFilter {...field.getProps()} {...omitFieldProps(props)} fieldValue={field.value} />;
});
