import React from 'react';
import { Classes, Drawer as BlueprintDrawer } from '@blueprintjs/core';
import styled from 'styled-components';

const StyledDrawer = styled(BlueprintDrawer)`
  .${Classes.DRAWER_FOOTER} {
    padding: 8px;
  }

  .${Classes.DRAWER_BODY} {
    background: ${({ theme }) => theme.colors.appBackground};
  }

  .${Classes.DIALOG_BODY} {
    margin: 0;

    /* for some reason, Blueprint decided that line-heights in Dialogs should be smaller. wrong */
    line-height: 1.28581;
  }
`;

export default ({ children, footer, ...rest }) => (
  <StyledDrawer {...rest}>
    <div className={Classes.DRAWER_BODY}>
      <div className={Classes.DIALOG_BODY}>{children}</div>
    </div>
    {footer && <div className={Classes.DRAWER_FOOTER}>{footer}</div>}
  </StyledDrawer>
);
