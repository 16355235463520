import React, { Component } from 'react';

export default class TextMeasure extends Component {
  static defaultProps = {
    defaultWidth: 100
  };

  text = React.createRef();

  constructor(props) {
    super(props);
    this.state = { width: props.defaultWidth };
  }

  componentDidMount() {
    this.measureText();
  }

  componentDidUpdate(prevProps) {
    const { text } = this.props;

    if (prevProps.text !== text) {
      this.measureText();
    }
  }

  measureText() {
    this.setState({ width: this.text.current.getComputedTextLength() });
  }

  render() {
    const { children, text, style } = this.props;
    const { width } = this.state;

    return (
      <>
        <text ref={this.text} style={{ ...style, pointerEvents: 'none', opacity: 0 }}>
          {text}
        </text>
        {children({ width, text })}
      </>
    );
  }
}
