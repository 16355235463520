import React, { Component } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { InputGroup, Switch, Select } from 'core/form';
import { Tag } from 'core/components';

import {
  ALERT_SEVERITIES,
  ALERT_SEVERITY_COLORS,
  ALERT_SEVERITY_LABELS,
  ALERT_STATE_LABELS,
  ALERT_ACKSTATE_LABELS,
  ALERT_ID_SEARCH_REGEX
} from 'shared/alerting/constants';

import { parseQueryString } from 'app/util/utils';
import { timezone } from 'core/util/dateUtils';
import AdminFilterSidebar from 'app/components/admin/AdminFilterSidebar';

@inject('$alerting', '$app', '$mkp', '$metrics', '$sites')
@withRouter
@observer
class AlertingFilterSidebar extends Component {
  static defaultProps = {
    lookbackOnly: false,
    readOnly: false,
    filterValues: undefined,
    updateHashOnFilterChange: true
  };

  state = { policyOptions: [], showTenantAlerts: false };

  componentDidMount() {
    const { $app, $mkp, $alerting } = this.props;

    if (!$app.isSubtenant) {
      $mkp.tenants.fetch();
    }

    $alerting.policyCollection.fetch().then(() => {
      this.setState({ policyOptions: $alerting.policyOptionsWithRules });
    });
  }

  get routingFilters() {
    const { location } = this.props;
    const queryStringFilters = location?.search ? parseQueryString(location.search) : {};
    const locationStateFilters = location?.state?.filters || {};

    return Object.assign({}, queryStringFilters, locationStateFilters);
  }

  get queryAlertId() {
    const { match } = this.props;

    return match?.params.alertId;
  }

  get resetValues() {
    return {
      keyPartsSearch: '',
      sites: [],
      alarmIds: '',
      applications: [],
      includeSubpolicies: false,
      lookback: 24 * 3600,
      ruleIds: [],
      states: ['alarm']
    };
  }

  get filterFields() {
    const { $alerting, $app, $mkp, $sites, filterValues, lookbackOnly } = this.props;

    const { policyOptions, showTenantAlerts } = this.state;
    const filterOverrides = {};
    const locationFilters = this.routingFilters;
    const passedFilters = Object.assign({}, filterValues, locationFilters, filterOverrides);

    const filterFields = {
      daterange: {
        name: 'daterange',
        isDateRange: true,
        label: `Time Range (${timezone.value})`,
        hidden: lookbackOnly,
        closeDateRangeOnSelection: true,
        startField: 'startDate',
        endField: 'endDate',
        asStrings: true,
        startDefaultValue: moment().subtract(1, 'd'),
        endDefaultValue: moment(),
        adjustEndTime: true,
        showShortcutLabelAsInterval: true,
        overrideShortcuts: [
          { label: 'Last hour', lookbackSeconds: 3600, includeTime: true },
          { label: 'Last 8 hours', lookbackSeconds: 28800, includeTime: true },
          { label: 'Last 24 hours', lookbackSeconds: 86400, includeTime: true },
          { label: 'Last 7 days', lookbackSeconds: 604800, includeTime: true },
          { label: 'Last 14 days', lookbackSeconds: 1209600, includeTime: true },
          { label: 'Last 30 days', lookbackSeconds: 2592000, includeTime: true },
          { label: 'Last 90 days', lookbackSeconds: 7776000, includeTime: true }
        ]
      },

      lookback: {
        name: 'lookback',
        label: 'Time Range',
        defaultValue: this.resetValues.lookback,
        hidden: !lookbackOnly,
        customField: <Select fill />,
        options: [
          { label: 'Last 24 hours', value: 24 * 3600 },
          { label: 'Last 7 days', value: 24 * 7 * 3600 },
          { label: 'Last 14 days', value: 24 * 14 * 3600 },
          { label: 'Last 30 days', value: 24 * 30 * 3600 },
          { label: 'Last 90 days', value: 24 * 90 * 3600 }
        ]
      },

      applications: {
        name: 'applications',
        label: 'Type',
        defaultValue: this.resetValues.applications,
        allowMultiSelect: true,
        showCheckboxes: true,
        options: $alerting.policyTypeOptions
      },

      states: {
        name: 'states',
        label: 'Alert State',
        allowMultiSelect: true,
        showCheckboxes: true,
        defaultValue: this.resetValues.states,
        options: ['alarm', 'clear'].map((value) => ({
          value,
          label: ALERT_STATE_LABELS[value],
          displayLabel: (
            <Tag
              as="div"
              round
              minimal
              intent={value === 'alarm' ? 'danger' : 'success'}
              fontWeight="500"
              width="80px"
              textAlign="center"
              fontSize={12}
              px={1}
              py="2px"
            >
              {ALERT_STATE_LABELS[value]}
            </Tag>
          )
        }))
      },

      ackStates: {
        name: 'ackStates',
        label: 'Ack State',
        allowMultiSelect: true,
        showCheckboxes: true,
        defaultValue: this.resetValues.ackStates,
        options: [
          'ALARM_ACKNOWLEDGEMENT_REQUIRED',
          'ALARM_ACKNOWLEDGEMENT_DONE',
          'ALARM_ACKNOWLEDGEMENT_NOT_ACKED',
          'ACKED_BY_ME'
        ].map((value) => ({
          value,
          label: ALERT_ACKSTATE_LABELS[value]
        }))
      },

      severities: {
        name: 'severities',
        label: 'Severity',
        defaultValue: [],
        allowMultiSelect: true,
        showCheckboxes: true,
        options: ALERT_SEVERITIES.map((value) => ({
          value,
          label: ALERT_SEVERITY_LABELS[value],
          color: ALERT_SEVERITY_COLORS[value],
          dot: true
        }))
      },

      alarmIds: {
        name: 'alarmIds',
        label: 'Alert ID',
        defaultValue: this.resetValues.alarmIds,
        customField: <InputGroup />,
        beforeOnChange: (field, fieldValue, previousValue, form, newValue) =>
          !(newValue || '').match(ALERT_ID_SEARCH_REGEX)
      },

      sites: {
        label: 'Sites',
        name: 'sites',
        options: $sites.siteOptions,
        hidden: $app.isSubtenant,
        allowMultiSelect: true,
        placeholder: 'No sites selected',
        defaultValue: this.resetValues.site
      },

      ruleIds: {
        name: 'ruleIds',
        options: policyOptions,
        defaultValue: [],
        label: 'Policies',
        allowMultiSelect: true,
        placeholder: 'No policies selected',
        valueRenderer: (option) => option.matcher
      },

      includeSubpolicies: {
        name: 'includeSubpolicies',
        defaultValue: this.resetValues.includeSubpolicies,
        customField: <Switch switchLabel="Show Tenant Alerts" />,
        hidden: $app.isSubtenant || $mkp.tenants.tenantAlertingOptions.length === 0
      },

      tenants: {
        label: 'Tenants',
        name: 'tenants',
        options: $mkp.tenants.tenantAlertingOptions,
        hidden: !showTenantAlerts,
        allowMultiSelect: true,
        placeholder: 'No tenants selected'
      },

      keyPartsSearch: {
        name: 'keyPartsSearch',
        customField: <InputGroup />,
        label: 'Dimension Value',
        defaultValue: this.resetValues.keyPartsSearch
      }
    };

    if (passedFilters) {
      Object.keys(passedFilters).forEach((key) => {
        let defaultValue = passedFilters[key];
        const filterField = filterFields[key];

        // set the default form values for all of the incoming query values and prop values
        if (filterField) {
          if (filterField.allowMultiSelect && !Array.isArray(defaultValue)) {
            defaultValue = [defaultValue];
          }

          if (key === 'lookback') {
            defaultValue = parseInt(defaultValue) || 0;
            // if we're only showing lookback (and not start/end dates) make sure we have a valid value for the lookback (at least 24hrs)
            if (lookbackOnly && defaultValue < 86400) {
              defaultValue = 86400;
            }
          }

          filterField.defaultValue = defaultValue;
        } else {
          // could not find a matching filter form field for the incoming value - check start and end dates
          if (key === 'startDate' && !lookbackOnly) {
            filterFields.daterange.startDefaultValue = moment(defaultValue);
            filterFields.lookback.defaultValue = 0;
          }
          if (key === 'endDate' && !lookbackOnly) {
            filterFields.daterange.endDefaultValue = moment(defaultValue);
            filterFields.lookback.defaultValue = 0;
          }
        }
      });
    }

    if (this.queryAlertId) {
      filterFields.alarmIds.defaultValue = this.queryAlertId;
      filterFields.lookback.defaultValue = 7776000;
      filterFields.states.defaultValue = [];
      filterFields.applications.defaultValue = [];
    }

    return Object.values(filterFields);
  }

  get adjustedFilterValues() {
    const { filterValues, lookbackOnly } = this.props;

    // Prefer lookback for subscriptions and make sure its at least 24hrs
    if (lookbackOnly && filterValues?.lookback < 86400) {
      filterValues.lookback = 86400;
    }

    return filterValues;
  }

  handleFilterChange = (filters) => {
    const { collection, onChange } = this.props;

    this.setState({ showTenantAlerts: !!filters.includeSubpolicies });

    if (!filters.includeSubpolicies) {
      filters.tenants = [];
    }

    if (onChange) {
      onChange(filters);
    }

    if (collection) {
      collection.setServerFilter(filters);
    }
  };

  render() {
    const { filterValues, lookbackOnly, readOnly, ...otherProps } = this.props;

    return (
      <AdminFilterSidebar
        {...otherProps}
        readOnly={readOnly}
        filterValues={this.adjustedFilterValues}
        filterFields={this.filterFields}
        resetValues={this.resetValues}
        onFilterChange={this.handleFilterChange}
      />
    );
  }
}

export default AlertingFilterSidebar;
