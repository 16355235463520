import React, { Component } from 'react';
import { interpolateSpectral } from 'd3-scale-chromatic';
import GraphContainer from '../../GraphContainer';
import Timeline from './timeline';

const colorSeries = (allSeries) => {
  const series = [];

  for (let i = 0; i < allSeries.length; i++) {
    const pilot = allSeries[i];
    pilot.color = interpolateSpectral(pilot.colorIndex * 0.25 + 0.75);
    series.push(pilot);
  }

  return series;
};

const parseData = ({ data }) => {
  if (data) {
    const { allSeries = [] } = data;

    return {
      data: colorSeries(allSeries),
      graphProps: {
        type: 'area',
        stacking: 'normal',
        nullFormat: 'No Changes'
      }
    };
  }

  return { data: [] };
};

const checkForResults = (data) => !!data.length;

class BgpPathChangeTimelineWrapper extends Component {
  render() {
    return (
      <GraphContainer
        {...this.props}
        key="bgp-path-change-timeline"
        type="pathChangesTS"
        timelineComponent={Timeline}
        heading="AS Path Changes"
        text={() => "Number of AS path changes observed over time from Kentik's BGP vantage points"}
        noResultsDescription="No AS path changes were observed during this time range"
        parseData={parseData}
        checkForResults={checkForResults}
      />
    );
  }
}

export default BgpPathChangeTimelineWrapper;
