import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { select, event } from 'd3-selection';
import { zoom as d3Zoom } from 'd3-zoom';

@inject('$topo')
@observer
class Chart extends Component {
  static propTypes = {
    backgroundFilterId: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    markerId: PropTypes.string.isRequired,
    onChartDidMount: PropTypes.func,
    onClick: PropTypes.func,
    enableMouseWheelZoom: PropTypes.bool.isRequired
  };

  static defaultProps = {
    hidden: false,
    onChartDidMount: () => {},
    onClick: () => {}
  };

  scaleExtent = [0.3, 2];

  container;

  svg;

  zoom;

  componentDidMount() {
    const { onChartDidMount, enableMouseWheelZoom } = this.props;

    this.zoom = d3Zoom().scaleExtent(this.scaleExtent).on('zoom', this.handleZoomed);

    if (enableMouseWheelZoom) {
      select(this.svg).call(this.zoom);
    } else {
      select(this.svg).call(this.zoom).on('wheel.zoom', null);
    }

    onChartDidMount(this.svg, this.container, this.zoom);
  }

  getSvgRef = (domNode) => {
    this.svg = domNode;
  };

  getContainerRef = (domNode) => {
    this.container = domNode;
  };

  handleZoomed = () => {
    const { transform } = event;
    select(this.container).attr('transform', transform);
  };

  renderMarker = (markerId, color, opacity = 0.6) => (
    <marker id={markerId} markerWidth="3" markerHeight="3" refX="0" refY="1.5" orient="auto" markerUnits="strokeWidth">
      <path
        d="M0,0 L0,3 L1.5,1.5 Z"
        fill={color}
        fillOpacity={opacity}
        stroke={color}
        strokeWidth="0.1"
        strokeOpacity={opacity}
      />
    </marker>
  );

  render() {
    const { $topo, theme, width, height, hidden, children, backgroundFilterId, markerId, onClick } = this.props;
    const { highlightColor } = $topo;

    return (
      <svg
        width={width}
        height={height}
        ref={this.getSvgRef}
        onClick={onClick}
        style={hidden ? { visibility: 'hidden' } : {}}
      >
        <defs>
          <filter x="0" y="0" width="1" height="1" id={backgroundFilterId}>
            <feFlood
              floodColor={theme.name === 'dark' ? theme.colors.darkGray5 : theme.colors.lightGray5}
              floodOpacity="0.4"
            />
            <feComposite in="SourceGraphic" operator="over" />
          </filter>
          <style type="text/css">
            {`
              @keyframes topologyAnimateLine {
                to { stroke-dashoffset: 1000; }
              }
            `}
          </style>

          {this.renderMarker(`${markerId}-dark`, theme.colors.lightGray5, 0.4)}
          {this.renderMarker(`${markerId}-light`, theme.colors.darkGray5, 0.4)}
          {this.renderMarker(`${markerId}-dark-selected`, theme.colors.lightGray5, 1)}
          {this.renderMarker(`${markerId}-light-selected`, theme.colors.darkGray5, 1)}
          {this.renderMarker(`${markerId}-highlightflow`, highlightColor)}
        </defs>

        <g className="container" ref={this.getContainerRef}>
          {children}
        </g>
      </svg>
    );
  }
}

export default withTheme(Chart);
