import React from 'react';
import styled from 'styled-components';
import { space, width, height, display } from 'styled-system';
import { Classes, Tabs as BPTabs } from '@blueprintjs/core';
import classNames from 'classnames';

import flexHelper from './utils/flex';

const StyledTabs = styled(BPTabs)`
  ${space};
  ${width};
  ${height};
  ${display};
  ${flexHelper};

  /* some reasonable resets */
  .${Classes.TABS} {
    min-width: 0;
    background: transparent;
  }

  .${Classes.TAB_LIST} {
    margin-top: ${({ theme, listProps = {} }) => `${theme.space[listProps?.mt]}px`}};
  }

  .${Classes.TAB_PANEL}.legend-tab:not([aria-hidden='true']) {
    display: flex;
    overflow: auto;
    flex: 1;
  }

  .bp4-tab:not([aria-selected='true']):hover {
    background-color: ${({ theme }) => theme.backgrounds.tableHeader} !important;
    color: ${({ theme }) => theme.colors.text} !important;
  }

  &.flexed {
    flex: 1;
    display: flex;
    flex-direction: ${({ vertical }) => (vertical ? 'row' : 'column')};

    > .${Classes.TAB_PANEL} {
      flex: 1;
    }
  }

  &.overflowed {
    overflow: hidden;

    > .${Classes.TAB_PANEL} {
      overflow: auto;
    }
  }

  &.bordered > .${Classes.TAB_LIST} {
    border-bottom: ${({ theme }) => theme.borders.thin};
  }
`;

const Tabs = (props) => {
  const { minWidth, borderRadius, flexed, overflowed, bordered, className, ...rest } = props;
  return (
    <StyledTabs
      {...rest}
      style={{ minWidth, borderRadius }}
      className={classNames(className, { flexed, overflowed, bordered })}
    />
  );
};

export default Tabs;
