import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Field, FormComponent, InputGroup, Switch } from 'core/form';
import { Box, Button, Flex } from 'core/components';
import MetricsExplorerResult from 'app/views/metrics/MetricsExplorerResult';
import MetricsExplorerMenuItem from 'app/views/metrics/MetricsExplorerMenuItem';
import getErrorBoundary from 'core/util/getErrorBoundary';
import withConfigOptions from '../withConfigOptions';
import WidgetFrame from '../WidgetFrame';

const ErrorBoundaryCmp = getErrorBoundary('ReconQueryWidget');

@withConfigOptions
@inject('$metrics')
@observer
class ReconQueryWidget extends Component {
  static defaultProps = {
    isWidget: true,
    showSharedTooltip: false,
    showSparklines: true,
    showTotalRow: false,
    showLegend: true,
    defaultWidgetTitle: 'Metrics',
    showDefaultOptions: true,
    showVisualizationSelector: false,
    useSimplifiedRollupDisplay: false,
    useHorizontalLayout: false
  };

  handleSave(form) {
    const { handleSaveConfiguration } = this.props;
    handleSaveConfiguration(form);
  }

  handleSplitPaneResize = (size) => {
    const { deck, id, config } = this.props;
    deck.updateWidget(id, { ...config, splitPaneProps: { defaultSize: size } });
  };

  renderConfigurePanel = () => {
    const {
      handleCancelConfiguration,
      defaultWidgetTitle,
      showDefaultOptions,
      showLegend,
      showSharedTooltip,
      showSparklines,
      showVisualizationSelector,
      useSimplifiedRollupDisplay,
      useHorizontalLayout
    } = this.props;
    const { model } = this.props;

    const fields = {
      title: {
        label: 'Title',
        rules: 'required',
        defaultValue: defaultWidgetTitle
      },
      showLegend: {
        defaultValue: showLegend
      },
      showSharedTooltip: {
        defaultValue: showSharedTooltip
      },
      showSparklines: {
        defaultValue: showSparklines
      },
      showVisualizationSelector: {
        defaultValue: showVisualizationSelector
      },
      useSimplifiedRollupDisplay: {
        defaultValue: useSimplifiedRollupDisplay
      },
      useHorizontalLayout: {
        defaultValue: useHorizontalLayout
      }
    };

    return (
      <FormComponent fields={fields} model={model} options={{ name: 'Configure Metrics Explorer Query' }}>
        {({ form }) => (
          <Flex flexDirection="column" height="100%" p={2}>
            <Field name="title" large>
              <InputGroup />
            </Field>

            {showDefaultOptions && (
              <Field name="showLegend" showLabel={false} large>
                <Switch switchLabel="Show a legend below the chart" />
              </Field>
            )}

            {showDefaultOptions && (
              <Field name="showSharedTooltip" showLabel={false} large>
                <Switch switchLabel="Show a shared tooltip for all series" />
              </Field>
            )}

            {showDefaultOptions && (
              <Field name="showSparklines" showLabel={false} large>
                <Switch switchLabel="Show sparklines" />
              </Field>
            )}

            {showDefaultOptions && (
              <Field name="showVisualizationSelector" showLabel={false} large>
                <Switch switchLabel="Show metric selector" />
              </Field>
            )}

            {showDefaultOptions && (
              <Field name="useHorizontalLayout" showLabel={false} large>
                <Switch switchLabel="Use horizontal layout" />
              </Field>
            )}

            {showDefaultOptions && (
              <Field name="useSimplifiedRollupDisplay" showLabel={false} large>
                <Switch switchLabel="Use compact rollup display" />
              </Field>
            )}

            <Box flex={1} />

            <Flex gap="8px">
              <Button text="Cancel" onClick={() => handleCancelConfiguration(form)} fill large />
              <Button
                text="Save"
                intent="primary"
                fontWeight="medium"
                disabled={!form.valid}
                onClick={() => this.handleSave(form)}
                fill
                large
              />
            </Flex>
          </Flex>
        )}
      </FormComponent>
    );
  };

  render() {
    const {
      onRemove,
      isConfigurePanelOpen,
      model,
      handleShowConfigurePanel,
      config,
      defaultWidgetTitle,

      // `height` gets set here by DeckGridLayout, but we don't need to pass it through to MetricsExplorerResult
      // because the component manages its own sizing
      height,
      ...rest
    } = this.props;
    const { query } = config;

    return (
      <WidgetFrame
        canCustomize
        configAction={handleShowConfigurePanel}
        menuOptions={
          <>
            <MetricsExplorerMenuItem query={query} />
          </>
        }
        title={isConfigurePanelOpen ? 'Configure' : model.get('title', defaultWidgetTitle)}
        onRemove={onRemove}
        display="flex"
        flexDirection="column"
      >
        <ErrorBoundaryCmp>
          {isConfigurePanelOpen && this.renderConfigurePanel()}
          {!isConfigurePanelOpen && (
            <MetricsExplorerResult
              enableSplitPane
              onSplitPaneResize={this.handleSplitPaneResize}
              {...rest}
              {...config}
            />
          )}
        </ErrorBoundaryCmp>
      </WidgetFrame>
    );
  }
}

export default ReconQueryWidget;
