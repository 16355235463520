import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Table } from 'core/components/table';
import Collection from 'core/model/Collection';
import { Tag, Text } from 'core/components';
import { getPacketLossValue, getMsValue } from 'app/views/synthetics/utils/syntheticsUtils';
import AgentRenderer from 'app/views/synthetics/components/AgentRenderer';

function getColumns({ $labels, $syn, group_by, synth_test_metric }) {
  const columns = {};

  columns.group = {
    label: 'Group',
    name: 'group_id',
    computed: false,
    ellipsis: false,
    renderer: ({ value }) => {
      const model = group_by === 'label' ? $labels.labels.get(value) : $syn.agents.get(value);
      if (group_by === 'agent' && model) {
        return <AgentRenderer agent={model} showLocationInfo />;
      }
      if (group_by === 'label' && model) {
        return (
          <Tag bg={model.get('color')} round>
            {model.get('name')}
          </Tag>
        );
      }
      return (
        <Text fontStyle="italic" muted>
          Unknown or removed {group_by}
        </Text>
      );
    }
  };

  const metric_map = {
    avg_latency_min: { label: 'Latency (Min)', renderValueFn: getMsValue },
    avg_latency_max: { label: 'Latency (Max)', renderValueFn: getMsValue },
    avg_latency_avg: { label: 'Latency (Avg)', renderValueFn: getMsValue },
    avg_jitter_min: { label: 'Jitter (Min)', renderValueFn: getMsValue },
    avg_jitter_max: { label: 'Jitter (Max)', renderValueFn: getMsValue },
    avg_jitter_avg: { label: 'Jitter (Avg)', renderValueFn: getMsValue },
    packet_loss_min: { label: 'Packet Loss (Min)', renderValueFn: getPacketLossValue },
    packet_loss_max: { label: 'Packet Loss (Max)', renderValueFn: getPacketLossValue },
    packet_loss_avg: { label: 'Packet Loss (Avg)', renderValueFn: getPacketLossValue }
  };

  const metric_map_keys = Object.keys(metric_map);

  metric_map_keys.forEach((key) => {
    const metric = metric_map[key];
    columns[key] = {
      label: metric.label,
      name: key,
      computed: false,
      ellipsis: false,
      renderer: ({ value }) => (
        <Tag fontSize="small" fontWeight="bold" minimal>
          {metric.renderValueFn(value)}
        </Tag>
      )
    };
  });

  const filteredColumns = [columns.group];
  if (!synth_test_metric) {
    synth_test_metric = ['avg_latency'];
  }
  synth_test_metric.forEach((test_metric) => {
    if (test_metric === 'avg_latency' || test_metric === 'dns-resolution') {
      filteredColumns.push(columns.avg_latency_min, columns.avg_latency_max, columns.avg_latency_avg);
    }
    if (test_metric === 'avg_jitter') {
      filteredColumns.push(columns.avg_jitter_min, columns.avg_jitter_max, columns.avg_jitter_avg);
    }
    if (test_metric === 'packet_loss') {
      filteredColumns.push(columns.packet_loss_min, columns.packet_loss_max, columns.packet_loss_avg);
    }
  });
  return filteredColumns;
}

@inject('$labels', '$syn')
@observer
class SynthDashboardAggregateTests extends Component {
  state = {
    collection: null,
    columns: null
  };

  static getDerivedStateFromProps(props) {
    const { $labels, $syn, group_ids, synth_test_metric, testResults } = props;
    const { group_by, health } = testResults;
    const [agg] = health;
    // only display groups that match the group_ids being used in the widget config
    const rows = agg.groups?.reduce((groups, group) => {
      const { group_id } = group;
      if (group_ids.includes(parseInt(group_id))) {
        const obj = { group_id };
        ['avg_latency', 'avg_jitter', 'packet_loss'].forEach((type) => {
          ['min', 'max', 'avg'].forEach((metric) => {
            const prop = `${type}_${metric}`;
            Object.assign(obj, { [prop]: group[type][metric] });
          });
        });
        groups.push(obj);
      }
      return groups;
    }, []);
    const collection = new Collection(rows);
    const columns = getColumns({ $labels, $syn, group_by, synth_test_metric });
    return { collection, columns };
  }

  render() {
    const { collection, columns } = this.state;
    return <Table columns={columns} collection={collection} staticColumns />;
  }
}

export default SynthDashboardAggregateTests;
