import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Flex, Icon, Text } from 'core/components';
import { Field, Radio, RadioGroup } from 'core/form';
import KentikLogo from 'app/components/KentikLogo';

@inject('$auth')
@observer
class ShareLevelSelector extends Component {
  static defaultProps = {
    showLabel: true,
    privateValue: 'user',
    sharedValue: 'company',
    globalValue: 'company',
    showPrivateShareLevel: true,
    showGlobalShareLevel: true
  };

  render() {
    const {
      field,
      label,
      showLabel,
      $auth,
      privateValue,
      sharedValue,
      globalValue,
      showGlobalShareLevel,
      showPrivateShareLevel
    } = this.props;

    return (
      <Field field={field}>
        {(fieldProps) => (
          <>
            {showLabel && (
              <Text fontSize={14} fontWeight="bold">
                {label || field.label || 'Share Level'}
              </Text>
            )}
            <RadioGroup {...fieldProps}>
              {$auth.isPresetCompany && showGlobalShareLevel && (
                <Radio
                  style={{ paddingTop: 4 }}
                  value={globalValue}
                  label={
                    <Flex position="relative" style={{ top: -4 }}>
                      <KentikLogo
                        onlyMark
                        alt="Kentik"
                        style={{ width: 20, height: 20, marginRight: 8 }}
                        fillColor="#ff850e"
                      />

                      <div>
                        <strong>Global / Preset</strong> <br />
                        <Text muted small>
                          Visible to all Kentik customers as a Preset.
                        </Text>
                      </div>
                    </Flex>
                  }
                />
              )}

              {showPrivateShareLevel && (
                <Radio
                  style={{ paddingTop: 4 }}
                  value={privateValue}
                  label={
                    <Flex position="relative" style={{ top: -4 }}>
                      <Icon icon="eye-open" iconSize={20} mt="3px" mr={1} />
                      <div>
                        <strong>Private</strong> <br />
                        <Text muted small>
                          Visible only to me
                        </Text>
                      </div>
                    </Flex>
                  }
                />
              )}

              {!$auth.isPresetCompany && (
                <Radio
                  style={{ paddingTop: 4 }}
                  value={sharedValue}
                  label={
                    <Flex position="relative" style={{ top: -4 }}>
                      <Icon icon="people" iconSize={20} mt="3px" mr={1} />
                      <div>
                        <strong>Shared</strong> <br />
                        <Text muted small>
                          Visible to others in my company
                        </Text>
                      </div>
                    </Flex>
                  }
                />
              )}
            </RadioGroup>
          </>
        )}
      </Field>
    );
  }
}

export default ShareLevelSelector;
