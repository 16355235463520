import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('NetworkBoundaryAggregate'))
class NetworkBoundaryAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="Network Boundaries"
        resultsKey="Network Boundary"
        insightsQuery="fetchNetworkBoundaryInsights"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'Network Boundary',
          aggregateFilters: [
            { metric: ['i_src_network_bndry_name', 'i_dst_network_bndry_name'] },
            { metric: ['i_src_network_bndry_name'] },
            { metric: ['i_dst_network_bndry_name'] },
            { metric: ['i_src_network_bndry_name', 'i_dst_network_bndry_name'] },
            { metric: ['i_src_network_bndry_name', 'i_dst_network_bndry_name'] }
          ]
        }}
        chartProps={{
          metrics: {
            Internal: ['i_dst_network_bndry_name', 'i_src_network_bndry_name'],
            Inbound: 'i_src_network_bndry_name',
            Outbound: 'i_dst_network_bndry_name'
          },
          topxMetricField: 'i_src|dst_network_bndry_name'
        }}
      />
    );
  }
}

export default NetworkBoundaryAggregate;
