import React from 'react';
import { get } from 'lodash';

import $insights from 'app/stores/insight/$insights';
import $devices from 'app/stores/device/$devices';

import ApplicationLink from './ApplicationLink';
import AsnLink from './AsnLink';
import CapacityPlanLink from './CapacityPlanLink';
import CdnLink from './CdnLink';
import CityLink from './CityLink';
import CountryLink from './CountryLink';
import DeviceLink from './DeviceLink';
import NmsDeviceLink from './NmsDeviceLink';
import InterfaceLink from './InterfaceLink';
import IpLink from './IpLink';
import ProtocolLink from './ProtocolLink';
import RegionLink from './RegionLink';
import SiteLink from './SiteLink';
import KentikAgentLink from './KentikAgentLink';

const dimensionLinkComponents = {
  application: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'application'),
    component: ({ value, ...props }) => <ApplicationLink application={value} {...props} />
  },
  i_device_id: {
    getValue: ({ dimensionToKeyDetail }) => get(dimensionToKeyDetail, 'i_device_id.device.name'),
    component: ({ value, ...props }) => <DeviceLink name={value} {...props} />
  },
  device_id: {
    getValue: ({ dimensionToKeyPart }) => get($insights.lookupDevice(get(dimensionToKeyPart, 'device_id')), 'name'),
    component: ({ value, ...props }) => <DeviceLink name={value} {...props} />
  },
  // NMS device:
  device_name: {
    getValue: ({ dimensionToKeyPart }) => $devices.deviceSummariesByName[get(dimensionToKeyPart, 'device_name')]?.id,
    component: ({ value, ...props }) => <NmsDeviceLink id={value} {...props} />
  },
  i_device_site_name: {
    getValue: ({ dimensionToKeyDetail }) => get(dimensionToKeyDetail, 'i_device_site_name.site.id'),
    component: ({ value, ...props }) => <SiteLink siteId={value} {...props} />
  },
  Geography_src: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'Geography_src'),
    component: ({ value, ...props }) => <CountryLink country={value} {...props} />
  },
  Geography_dst: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'Geography_dst'),
    component: ({ value, ...props }) => <CountryLink country={value} {...props} />
  },
  src_geo: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'src_geo'),
    component: ({ value, ...props }) => <CountryLink country={value} {...props} />
  },
  dst_geo: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'dst_geo'),
    component: ({ value, ...props }) => <CountryLink country={value} {...props} />
  },
  src_geo_city: {
    getValue: ({ dimensionToKeyPart }) => ({
      city: get(dimensionToKeyPart, 'src_geo_city'),
      region: get(dimensionToKeyPart, 'src_geo_region'),
      country: get(dimensionToKeyPart, 'src_geo')
    }),
    component: ({ value, ...props }) => <CityLink {...value} {...props} />
  },
  dst_geo_city: {
    getValue: ({ dimensionToKeyPart }) => ({
      city: get(dimensionToKeyPart, 'dst_geo_city'),
      region: get(dimensionToKeyPart, 'dst_geo_region'),
      country: get(dimensionToKeyPart, 'dst_geo')
    }),
    component: ({ value, ...props }) => <CityLink {...value} {...props} />
  },
  src_geo_region: {
    getValue: ({ dimensionToKeyPart }) => ({
      region: get(dimensionToKeyPart, 'src_geo_region'),
      country: get(dimensionToKeyPart, 'src_geo')
    }),
    component: ({ value, ...props }) => <RegionLink {...value} {...props} />
  },
  dst_geo_region: {
    getValue: ({ dimensionToKeyPart }) => ({
      region: get(dimensionToKeyPart, 'dst_geo_region'),
      country: get(dimensionToKeyPart, 'dst_geo')
    }),
    component: ({ value, ...props }) => <RegionLink {...value} {...props} />
  },
  IP_src: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'IP_src'),
    component: ({ value, ...props }) => <IpLink ip_address={value} {...props} />
  },
  IP_dst: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'IP_dst'),
    component: ({ value, ...props }) => <IpLink ip_address={value} {...props} />
  },
  Proto: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'Proto'),
    component: ({ value, ...props }) => <ProtocolLink protocol={value} {...props} />
  },
  protocol: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'protocol'),
    component: ({ value, ...props }) => <ProtocolLink protocol={value} {...props} />
  },
  InterfaceID_src: {
    getValue: ({ dimensionToKeyDetail }) => {
      const snmp_id = get(dimensionToKeyDetail, 'InterfaceID_src.interface.snmpID');
      const device_name = get(dimensionToKeyDetail, 'i_device_id.device.name');
      return snmp_id && device_name ? { snmp_id, device_name } : undefined;
    },
    component: ({ value, ...props }) => <InterfaceLink {...value} {...props} />
  },
  InterfaceID_dst: {
    getValue: ({ dimensionToKeyDetail }) => {
      const snmp_id = get(dimensionToKeyDetail, 'InterfaceID_dst.interface.snmpID');
      const device_name = get(dimensionToKeyDetail, 'i_device_id.device.name');
      return snmp_id && device_name ? { snmp_id, device_name } : undefined;
    },
    component: ({ value, ...props }) => <InterfaceLink {...value} {...props} />
  },
  snmp_id: {
    getValue: ({ dimensionToKeyPart }) => ({
      snmp_id: get(dimensionToKeyPart, 'snmp_id'),
      device_name: get($insights.lookupDevice(get(dimensionToKeyPart, 'device_id')), 'name')
    }),
    component: ({ value, ...props }) => <InterfaceLink {...value} {...props} />
  },
  src_nexthop_asn: {
    getValue: ({ dimensionToKeyDetail }) => get(dimensionToKeyDetail, 'src_nexthop_asn.asn.asn'),
    component: ({ value, ...props }) => <AsnLink asn={value} nextHop {...props} />
  },
  dst_nexthop_asn: {
    getValue: ({ dimensionToKeyDetail }) => get(dimensionToKeyDetail, 'dst_nexthop_asn.asn.asn'),
    component: ({ value, ...props }) => <AsnLink asn={value} nextHop {...props} />
  },
  src_as: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'src_as'),
    component: ({ value, ...props }) => <AsnLink asn={value} {...props} />
  },
  dst_as: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'dst_as'),
    component: ({ value, ...props }) => <AsnLink asn={value} {...props} />
  },
  AS_src: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'AS_src'),
    component: ({ value, ...props }) => <AsnLink asn={value} {...props} />
  },
  AS_dst: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'AS_dst'),
    component: ({ value, ...props }) => <AsnLink asn={value} {...props} />
  },
  cdn: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'cdn'),
    component: ({ value, ...props }) => <CdnLink cdn={value} {...props} />
  },
  src_cdn: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'src_cdn'),
    component: ({ value, ...props }) => <CdnLink cdn={value} {...props} />
  },
  dst_cdn: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'dst_cdn'),
    component: ({ value, ...props }) => <CdnLink cdn={value} {...props} />
  },
  ktappprotocol__snmp__i_device_name: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'ktappprotocol__snmp__i_device_name'),
    component: ({ value, ...props }) => <DeviceLink name={value} {...props} />
  },
  ktappprotocol__snmp__output_port: {
    getValue: ({ dimensionToKeyDetail }) => ({
      snmp_id: get(dimensionToKeyDetail, 'ktappprotocol__snmp__output_port.interface.snmpID'),
      device_name: get(dimensionToKeyDetail, 'ktappprotocol__snmp__i_device_name.device.name')
    }),
    component: ({ value, ...props }) => <InterfaceLink {...value} {...props} />
  },
  ktappprotocol__snmp__i_device_site_name: {
    getValue: ({ dimensionToKeyDetail }) =>
      get(dimensionToKeyDetail, 'ktappprotocol__snmp__i_device_site_name.site.id'),
    component: ({ value, ...props }) => <SiteLink siteId={value} {...props} />
  },
  capacity_plan_id: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'capacity'),
    component: ({ value, ...props }) => <CapacityPlanLink id={value} {...props} />
  },
  agent_id: {
    getValue: ({ dimensionToKeyPart }) => get(dimensionToKeyPart, 'agent_id'),
    component: ({ value, ...props }) => <KentikAgentLink id={value} {...props} />
  }
};

export default function DimensionToLink({
  dimension,
  value,
  dimensionToValue,
  dimensionToKeyPart = dimensionToValue,
  dimensionToKeyDetail,
  ...props
}) {
  if (dimension in dimensionLinkComponents) {
    const { getValue, component: Component } = dimensionLinkComponents[dimension];

    if (value === undefined) {
      value = getValue({ dimensionToKeyPart, dimensionToKeyDetail });
    }

    if (value !== undefined) {
      return <Component value={value} {...props} />;
    }
  }

  return props.children || null;
}
