import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import storeLoader from 'app/stores/storeLoader';
import AgentsOverview from 'app/views/synthetics/components/dashboard/AgentsOverview';
import WidgetFrame from '../WidgetFrame';

@storeLoader('$syn.tests', '$syn.agents')
@inject('$syn')
@observer
class AgentsOverviewWidget extends Component {
  render() {
    const { canCustomize, onRemove } = this.props;

    return (
      <WidgetFrame canCustomize={canCustomize} onRemove={onRemove} title="Agents by Status">
        <AgentsOverview isWidgetView />
      </WidgetFrame>
    );
  }
}

export default AgentsOverviewWidget;
