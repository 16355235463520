import React, { Component } from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { withTheme } from 'styled-components';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { Button, Spinner, Suspense, Flex, Box, Heading, Text, SmallCaps, EmptyState } from 'core/components';
import {
  getStartAndEnd,
  getStartAndEndFromLookback,
  dateRangeDisplay,
  DEFAULT_DATETIME_FORMAT
} from 'core/util/dateUtils';
import { makeAlertManagerAlarm } from 'shared/synthetics/utils';
import { parseQueryString } from 'app/util/utils';
import {
  updateTimelineChartSelection,
  hideTooltips,
  aggregateAlertManagerAlarmsIntoTimeslots
} from 'app/views/synthetics/utils/syntheticsUtils';
import Page from 'app/components/page/Page';
import TestPauseResumeButton from 'app/views/synthetics/tests/TestPauseResumeButton';
import AlarmsTimeSeries from 'app/views/synthetics/components/AlarmsTimeSeries';
import TestNotesPopover from 'app/views/synthetics/components/TestNotesPopover';
import GraphController from 'app/views/synthetics/components/bgp/TestResults/GraphController';
import DatePicker from 'app/views/synthetics/components/DatePicker';
import TestResultsGrid from 'app/views/synthetics/components/testResults/TestResultsGrid';
import { LoadingPanel, IncidentLogPanel, SummaryPanel } from 'app/views/synthetics/components/testResults/panels';

const ONE_MINUTE = 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;
const DEFAULT_LOOKBACK = ONE_DAY;
const MAX_DATE_RANGE = ONE_DAY;

@withTheme
@inject('$auth', '$exports', '$dataviews', '$syn', '$app', '$bgp')
@observer
class BgpTestResults extends Component {
  static defaultProps = {
    embedded: false
  };

  state = {
    lookbackSeconds: DEFAULT_LOOKBACK,
    startDate: null,
    endDate: null,
    alarmsLoading: true,
    bgpRequestsLoading: true,
    alarms: null,
    init: false,
    normalizedStartDate: null,
    normalizedEndDate: null,
    subsetStartDate: null,
    subsetEndDate: null,
    resultTimeMs: null,
    selectedPrefix: null
  };

  static getDerivedStateFromProps(props, state) {
    let updateState = false;
    const newProps = {};

    if (
      (props.startDate && props.startDate !== state.startDate) ||
      (props.endDate && props.endDate !== state.endDate) ||
      (props.lookbackSeconds && props.lookbackSeconds !== state.lookbackSeconds)
    ) {
      Object.assign(newProps, {
        startDate: props.startDate,
        endDate: props.endDate,
        lookbackSeconds: props.lookbackSeconds
      });
      updateState = true;
    }

    if (props.embedded && !state.init) {
      Object.assign(newProps, { init: true });
      updateState = true;
    }

    return updateState ? newProps : null;
  }

  componentDidMount() {
    // duplicatated from ../TestResults
    const { $exports, history, embedded, $bgp } = this.props;
    let initPromise = Promise.resolve();

    this.bgpLoadingDisposer = reaction(
      () => $bgp.isLoading,
      (bgpRequestsLoading) => this.setState({ bgpRequestsLoading })
    );

    if (!embedded) {
      const { search, state } = history.location;
      let urlStartDate;
      let urlEndDate;

      if (state && (state.lookbackSeconds || state.startDate || state.endDate)) {
        const { startDate, endDate, lookbackSeconds } = state;

        initPromise = initPromise
          .then(() =>
            $exports.setHash({
              hashedLookbackSeconds: lookbackSeconds,
              hashedStartDate: startDate,
              hashedEndDate: endDate
            })
          )
          .then(() => {
            history.replace({ ...history.location, state: {} });
          });
      }

      if (search) {
        const { start, end } = parseQueryString(search);
        if (start) {
          urlStartDate = Math.floor(parseInt(start) / 60) * 60;
          urlEndDate = Math.floor((parseInt(end) || Date.now() / 1000) / 60) * 60;
          if (urlEndDate > urlStartDate + 179 * 60) {
            urlEndDate = urlStartDate + 179 * 60;
          } else {
            urlEndDate += 900;
          }
          urlStartDate -= 900;
          initPromise = initPromise.then(() =>
            $exports.setHash(
              {
                hashedStartDate: urlStartDate,
                hashedEndDate: urlEndDate,
                hashedLookbackSeconds: null
              },
              true,
              true
            )
          );
        }
      }

      initPromise
        .then(() => $exports.getSettings())
        .then(({ hashedLookbackSeconds, hashedStartDate, hashedEndDate }) => {
          this.setState((prevState) => {
            const newSettings = {
              lookbackSeconds: hashedLookbackSeconds !== undefined ? hashedLookbackSeconds : prevState.lookbackSeconds,
              startDate: hashedStartDate !== undefined ? hashedStartDate : prevState.startDate,
              endDate: hashedEndDate !== undefined ? hashedEndDate : prevState.endDate,
              init: true
            };

            return newSettings;
          });
        });
    } else {
      this.fetch();
    }
  }

  componentWillUnmount() {
    this.bgpLoadingDisposer();
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, lookbackSeconds, init, resultTimeMs } = this.state;

    if (
      prevState.startDate !== startDate ||
      prevState.endDate !== endDate ||
      prevState.lookbackSeconds !== lookbackSeconds ||
      prevState.init !== init
    ) {
      this.fetch();
    }

    if (resultTimeMs !== prevState.resultTimeMs) {
      this.handleTimelineHoverDebounce();
    }
  }

  handleDateRangeChange = (datesOrLookback) => {
    const { $exports } = this.props;
    const { lookbackSeconds, startDate, endDate } = datesOrLookback;

    if ((startDate && endDate) || lookbackSeconds) {
      this.setState({ lookbackSeconds, startDate, endDate, subsetStartDate: null, subsetEndDate: null });

      $exports.setHash({
        hashedLookbackSeconds: lookbackSeconds,
        hashedStartDate: startDate,
        hashedEndDate: endDate
      });
    }
  };

  fetch() {
    const { $syn, model } = this.props;
    const [startDate, endDate] = getStartAndEnd(this.state);
    const start = moment.utc(startDate * 1000).toISOString();
    const end = moment.utc(endDate * 1000).toISOString();
    const invalidDateRange = endDate - startDate > MAX_DATE_RANGE;

    this.setState({
      alarmsLoading: true,
      xAxisMin: startDate * 1000,
      xAxisMax: endDate * 1000,
      normalizedStartDate: startDate,
      normalizedEndDate: endDate,
      invalidDateRange
    });

    if (!invalidDateRange) {
      $syn.requests
        .getTestAlarms({ start, end, ids: [model.id] })
        .then((alarms) =>
          aggregateAlertManagerAlarmsIntoTimeslots({
            alarms: alarms.alarms.map((alarm) => makeAlertManagerAlarm(alarm)),
            startDate,
            endDate,
            creationDate: model.get('cdate'),
            ignoreAdjustment: true
          })
        )
        .then((alarms) => {
          this.setState({
            alarms,
            alarmsLoading: false
          });
        });
    }
  }

  setSubsetTimeRange = (start, end) => {
    const { subsetStartDate, subsetEndDate, xAxisMax } = this.state;
    const newSubsetStart = start / 1000;
    const newSubsetEnd = end > xAxisMax ? xAxisMax / 1000 : end / 1000;
    const newSubsetStartDate = subsetStartDate === newSubsetStart ? null : newSubsetStart;
    const newSubsetEndDate = subsetEndDate === newSubsetEnd ? null : newSubsetEnd;

    this.setState({
      subsetStartDate: newSubsetStartDate,
      subsetEndDate: newSubsetEndDate
    });
  };

  handleTimelineOnHover = (resultTimeMs) => {
    this.setState({ resultTimeMs });
  };

  updateTimelineChartSelection = () => {
    const { $dataviews } = this.props;
    const { resultTimeMs } = this.state;

    updateTimelineChartSelection($dataviews, resultTimeMs);
  };

  handleTimelineHoverDebounce = this.updateTimelineChartSelection;

  handleOnScroll = debounce(() => {
    const { $dataviews } = this.props;

    hideTooltips($dataviews);
  }, 50);

  handleSetPrefix = (nextPrefix) => {
    this.setState({ selectedPrefix: nextPrefix });
  };

  get datePickerRange() {
    const { lookbackSeconds, startDate, endDate } = this.state;

    // if the start and end dates are the same or undetermined, recalculate them based on lookback
    if ((startDate === null && endDate === null) || startDate === endDate) {
      const { start, end } = getStartAndEndFromLookback(lookbackSeconds);

      return { lookbackSeconds, startDate: start.unix(), endDate: end.unix() };
    }

    return { lookbackSeconds, startDate, endDate };
  }

  get incidentLogAlarms() {
    const { alarms, subsetStartDate } = this.state;

    if (subsetStartDate) {
      return alarms?.alarms.filter((alarm) => alarms?.timeslots?.[subsetStartDate]?.alarms?.includes(alarm.id));
    }

    return alarms?.alarms;
  }

  get dateRangeText() {
    const { normalizedStartDate, normalizedEndDate, subsetStartDate, subsetEndDate } = this.state;
    const start = subsetStartDate || normalizedStartDate;
    const end = subsetEndDate || normalizedEndDate;

    return dateRangeDisplay({
      starting_time: start * 1000,
      ending_time: end - start > 60 ? end * 1000 : null,
      format: DEFAULT_DATETIME_FORMAT
    });
  }

  render() {
    const { history, model, $auth, embedded, $app } = this.props;
    const {
      init,
      normalizedStartDate,
      normalizedEndDate,
      lookbackSeconds,
      xAxisMin,
      xAxisMax,
      alarms,
      alarmsLoading,
      bgpRequestsLoading,
      subsetStartDate,
      subsetEndDate,
      selectedPrefix,
      invalidDateRange
    } = this.state;
    const { minLookbackSeconds } = model.results;
    const canEditTests = $auth.hasRbacPermissions(['synthetics.test::update']);

    const pageContent = (
      <TestResultsGrid gridRowGap={3} embedded={embedded}>
        <Suspense
          key="bgp-route-view-graph"
          loading={!init}
          fallback={
            <Flex alignItems="center" flexDirection="column" mt={2}>
              <Spinner intent="primary" mb={2} />
              <Text muted>Initializing...</Text>
            </Flex>
          }
        >
          {normalizedStartDate && normalizedEndDate && !invalidDateRange && (
            <>
              {/* embedded mode will hide the summary component, alarm timeline and incident log since they will already be shown, in a page load with bgp test for example */}
              {!embedded && (
                <>
                  <SummaryPanel
                    test={model}
                    alarms={alarms}
                    startDate={normalizedStartDate}
                    endDate={normalizedEndDate}
                    lookbackSeconds={lookbackSeconds}
                  />

                  <Heading level={6}>Incident Timeline</Heading>
                  <Box>
                    <Suspense
                      key="bgp-alarms"
                      loading={alarmsLoading}
                      fallback={
                        <Flex alignItems="center" flexDirection="column" mt={2}>
                          <Spinner intent="primary" mb={2} />
                        </Flex>
                      }
                    >
                      <AlarmsTimeSeries
                        alarms={alarms}
                        xAxisMin={xAxisMin}
                        xAxisMax={xAxisMax}
                        onTimelineSelect={this.handleTimelineOnHover}
                        onSelect={this.setSubsetTimeRange}
                      />
                    </Suspense>
                  </Box>

                  <IncidentLogPanel
                    mb={2}
                    testResults={model.results}
                    alarms={this.incidentLogAlarms}
                    setPrefixes={this.handleSetPrefix}
                  >
                    {({ label, content }) => (
                      <>
                        {label}
                        <LoadingPanel loading={alarmsLoading}>
                          <Flex flexDirection="column" gap={1}>
                            {content}
                            <Text textAlign="center" muted small>
                              {this.dateRangeText}
                            </Text>
                          </Flex>
                        </LoadingPanel>
                      </>
                    )}
                  </IncidentLogPanel>

                  <Heading level={6}>BGP</Heading>
                </>
              )}
              <GraphController
                alarms={alarms}
                startDate={normalizedStartDate}
                endDate={normalizedEndDate}
                model={model}
                xAxisMin={xAxisMin}
                xAxisMax={xAxisMax}
                loading={alarmsLoading}
                onTimelineHover={this.handleTimelineOnHover}
                setSubsetTimeRange={this.setSubsetTimeRange}
                subsetStartDate={subsetStartDate}
                subsetEndDate={subsetEndDate}
                maxDateRange={MAX_DATE_RANGE}
                selectedPrefix={selectedPrefix}
              />
            </>
          )}
          {invalidDateRange && (
            <Box gridColumn="1/3" my={6}>
              <EmptyState
                icon="calendar"
                title="BGP Date Range Exceeded"
                description="Please select a date range of one day or less"
              />
            </Box>
          )}
        </Suspense>
      </TestResultsGrid>
    );

    if (embedded) {
      return pageContent;
    }

    const testName = model.get('display_name');

    return (
      <Page
        title={testName}
        parentLinks={[{ link: '/v4/synthetics/tests', label: 'Test Control Center' }]}
        showQuickViewSelector
        canFullScreen
        isExportReady={$app.isExport && !bgpRequestsLoading} // applies the .isExportReady css class to the page container when truthy
        scrolls={!$app.isExport}
        showExport={{ selector: '.isExportReady', selectorOptions: { hidden: false } }} // let the runner know where to look to determine report completion
        forceShowExport
        exportButtonProps={{ text: 'Export' }}
        pageProps={{ flex: '1 0 calc(100% - 82px)', overflow: $app.isExport ? 'visible' : 'hidden' }} //
        onScroll={this.handleOnScroll} //
        subnavTools={
          <>
            {canEditTests ? (
              <>
                <Button
                  minimal
                  icon={FiEdit}
                  text="Edit Test"
                  onClick={() => history.push(`/v4/synthetics/tests/${model.id}`)}
                />
                <TestPauseResumeButton model={model} minimal />
              </>
            ) : null}
          </>
        }
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Box whiteSpace="nowrap" overflow="hidden">
            <SmallCaps muted fontSize={12}>
              BGP Monitor
            </SmallCaps>
            <Flex alignItems="center" gap="4px">
              <Heading level={1} mb={0} overflow="hidden" style={{ textOverflow: 'ellipsis' }} title={testName}>
                {model.get('display_name')}
              </Heading>
              <TestNotesPopover test={model} />
            </Flex>
          </Box>
          <Flex flexDirection="column" alignItems="flex-end">
            <DatePicker
              ml={2}
              whiteSpace="nowrap"
              {...this.datePickerRange}
              test={model}
              minLookbackSeconds={minLookbackSeconds}
              maxLookbackSeconds={MAX_DATE_RANGE}
              maxRawLookback={Number.MAX_SAFE_INTEGER}
              onChange={this.handleDateRangeChange}
            />
          </Flex>
        </Flex>
        {pageContent}
      </Page>
    );
  }
}

export default withRouter(BgpTestResults);
