import React, { Component } from 'react';
import { isEqual, isObject } from 'lodash';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { formConsumer, Field, Select } from 'core/form';
import MetricSelector from 'app/components/metric/MetricSelector';
import { getAggregateTypeLabel, getAggregateUnitLabel } from 'app/components/metric/metricRenderers';
import { getAllUnitsLegacyNames, getBaseSorts, getUnitsLegacy } from 'app/stores/query/ExplorerQueryModel';

function getDisabledUnits(disabledGroups, aggregates) {
  const units = new Set();

  const traverseGroup = (name, group, parentDisabled = false) => {
    const disabled = parentDisabled || disabledGroups.has(name);

    if (Array.isArray(group) && disabled) {
      group.forEach((agg) => {
        units.add(agg.unit);
      });
    } else if (isObject(group)) {
      Object.entries(group).forEach(([key, value]) => traverseGroup(key, value, disabled));
    }
  };

  Object.entries(aggregates).forEach(([key, value]) => traverseGroup(key, value));

  return units;
}

@inject('$dictionary', '$auth')
@formConsumer
@observer
class MetricOptions extends Component {
  state = {
    disabledDimensions: [],
    disabledGroups: [],
    disabledUnits: new Set(),
    isEditingMetrics: false
  };

  componentDidUpdate() {
    this.updateMetricField();
  }

  static getDerivedStateFromProps(props, state) {
    const { disabledDimensions, $dictionary } = props;

    if (!isEqual(disabledDimensions, state.disabledDimensions)) {
      const disabledGroups = (disabledDimensions || []).filter((dimension) => !dimension.includes('_'));
      const aggregates = $dictionary.get('aggregates', {});
      return {
        disabledDimensions,
        disabledGroups,
        disabledUnits: getDisabledUnits(new Set(disabledGroups), aggregates)
      };
    }

    return {
      isEditingMetrics: props.isEditingMetrics
    };
  }

  getSortOptions() {
    const { form } = this.props;
    const aggregateTypes = form.getValue('aggregateTypes');
    const viz_type = form.getValue('viz_type');

    if (!aggregateTypes) {
      return [];
    }

    const baseSorts = getBaseSorts(aggregateTypes, viz_type);

    return baseSorts.map((option) => ({
      ...option,
      value: option.value === 'sum_logsum' ? `${option.value}_${option.unit}` : option.value,
      label: (
        <span>
          {getAggregateTypeLabel({ aggregate: option, useLineBreaks: false })}{' '}
          {option.value && getAggregateUnitLabel({ aggregate: option, useLineBreaks: false })}
        </span>
      )
    }));
  }

  handleUnitsLegacyChange = (field, value) => {
    const { form, $dictionary, onEditComplete } = this.props;
    const { unitsLegacy, logsumUnits } = $dictionary.dictionary;

    const aggTypesField = form.getField('aggregateTypes');
    const aggTypes = unitsLegacy[value] || [];

    aggTypesField.setValue(aggTypes);
    aggTypesField.onChange(aggTypes);
    form.getField('outsort').setValue('');

    this.handleMetricSelection(aggTypesField, aggTypes, logsumUnits.includes(value));

    // If 'Custom' was selected, pop open the dialog
    if (aggTypes.length === 0) {
      this.setState({ isEditingMetrics: true });
    }

    onEditComplete(false, undefined, 'unitChange');
  };

  handleMetricSelectorToggle = (forceValue, redrawOnly = false, editAction) => {
    const { form, onEditComplete } = this.props;
    form.getField('aggregateTypes').setPristine(false);
    onEditComplete(forceValue, redrawOnly && !form.getField('outsort').dirty, editAction);
  };

  handleEditBtnClick = (e) => {
    const { onEditComplete } = this.props;

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    onEditComplete(undefined, undefined, 'open');
  };

  @action
  handleMetricSelection = (aggTypesField, aggTypesValues, isLogsum = false) => {
    const { form } = this.props;
    const { hasKProbeSelected } = this.props;
    const outsortOptions = this.getSortOptions();

    // With outsort, we want to automatically select the first metric for convenience
    // ** However, if there as a kt intelligent ordering option and we're in logsum mode, give it precedence
    const outsortField = form.getField('outsort');
    let outsortValue = outsortField.getValue();
    if (!outsortOptions.find((option) => option.value === outsortValue)) {
      let intellOrderOption;
      if (!outsortValue || isLogsum === true) {
        intellOrderOption = outsortOptions.find((option) => option.column === 'kt_intell_order');
      }
      if (intellOrderOption) {
        outsortValue = intellOrderOption.value;
      } else if (outsortOptions.length) {
        outsortValue = outsortOptions[0].value;
      } else {
        outsortValue = '';
      }
      outsortField.options = outsortOptions;
      outsortField.setValue(outsortValue);
      outsortField.setPristine(false);
    }

    // With secondaryOutsort, we just want to clear it out if its value is no longer available
    const secondaryOutsortField = form.getField('secondaryOutsort');
    const secondaryOutsortValue = secondaryOutsortField.getValue();
    if (
      outsortValue === secondaryOutsortValue ||
      !outsortOptions.find((option) => option.value === secondaryOutsortValue)
    ) {
      secondaryOutsortField.setValue('');
      secondaryOutsortField.setPristine(false);
      form.getField('secondaryTopxMirrored').setValue(false);
      form.getField('secondaryTopxSeparate').setValue(false);
    }

    form.setValue('unitsLegacy', getUnitsLegacy(aggTypesValues, hasKProbeSelected));
  };

  updateMetricField() {
    const { form, hasKProbeSelected, $dictionary } = this.props;

    if (!form.getValue('all_devices') && (!form.getValue('device_name') || form.getValue('device_name').length === 0)) {
      return;
    }

    // If there is no kprobe/dns device selected, we need to remove host metrics.
    // field.setValue() does trigger onChange handlers, so we need to do this too.
    if (!hasKProbeSelected) {
      const tcpMetrics = $dictionary.dictionary.aggregates.TCP;
      const aggregateTypesField = form.getField('aggregateTypes');
      const aggregateTypes = aggregateTypesField.getValue();
      const newAggregateTypes = aggregateTypes.filter(
        (aggregateType) =>
          !Object.keys(tcpMetrics).some((group) =>
            tcpMetrics[group].some((aggregate) => aggregate.value === aggregateType)
          )
      );

      if (newAggregateTypes.length < aggregateTypes.length) {
        aggregateTypesField.setValue(newAggregateTypes);
        aggregateTypesField.setPristine(false);
        this.handleMetricSelection(aggregateTypesField, aggregateTypes);
      }
    }
  }

  getUnitsLegacyOptions() {
    const { hasKProbeSelected, $dictionary, $auth } = this.props;
    const { disabledUnits } = this.state;
    const { units } = $dictionary.dictionary;

    return getAllUnitsLegacyNames(hasKProbeSelected)
      .filter(
        (unit) =>
          // hide bgp for now
          $auth.hasSudo || $auth.isSpoofed || !unit.includes('ktappprotocol__ktrac_bgp')
      )
      .map((unit) => ({
        value: unit,
        label: units[unit] || unit,
        disabled: disabledUnits.has(unit)
      }))
      .concat({
        value: '',
        label: 'Custom'
      });
  }

  render() {
    const { disabledMetrics } = this.props;
    const { disabledGroups, isEditingMetrics } = this.state;

    const collapsedGroups = Object.fromEntries(disabledGroups.map((group) => [group, true]));

    return (
      <>
        <Field
          name="unitsLegacy"
          showLabel={false}
          options={this.getUnitsLegacyOptions()}
          onChange={this.handleUnitsLegacyChange}
          small
          mb={0}
        >
          <Select fill showFilter exactMatch />
        </Field>
        <MetricSelector
          isEditing={isEditingMetrics}
          onChange={this.handleMetricSelection}
          onUnitsLegacyChange={this.handleUnitsLegacyChange}
          onEditComplete={this.handleMetricSelectorToggle}
          onEditBtnClick={this.handleEditBtnClick}
          disabledValues={disabledMetrics.concat(disabledGroups)}
          collapsedState={collapsedGroups}
          collapsible={false}
          small
        />
      </>
    );
  }
}

export default MetricOptions;
