import React from 'react';

import { ReactComponent as NetflixLogo } from 'app/assets/logos/netflix.svg';
import { ReactComponent as FacebookLogo } from 'app/assets/logos/facebook.svg';
import { ReactComponent as GoogleLogo } from 'app/assets/logos/google.svg';
import { ReactComponent as OfficeLogo } from 'app/assets/logos/office.svg';
import { ReactComponent as TwitchLogo } from 'app/assets/logos/twitch.svg';
import Box from './Box';

const logos = {
  netflix: NetflixLogo,
  google: GoogleLogo,
  facebook: FacebookLogo,
  office: OfficeLogo,
  twitch: TwitchLogo
};

function Logo({ name, ...rest }) {
  if (!logos[name]) {
    throw new Error(`"${name}" is not a valid logo that we support`);
  }

  return (
    <Box {...rest} as="span">
      {React.createElement(logos[name], { ...rest })}
    </Box>
  );
}

Logo.defaultProps = {
  width: 16,
  height: 16
};

export default Logo;
