import React, { Component } from 'react';
import { Button, Flex, Icon, MenuItem, Text, Tooltip } from 'core/components';
import styled from 'styled-components';
import { InputGroup } from 'core/form';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Position } from '@blueprintjs/core';
import { fromNow } from 'core/util/dateUtils';
import KentikAiBadge from 'core/components/KentikAiBadge';

const JourneyMenuItem = styled(MenuItem)`
  &.bp4-menu-item {
    padding: 8px 16px;
    align-items: center;
    position: relative;
    min-height: 58px;

    &::before,
    & > * {
      margin: 0;
    }
  }

  .actions {
    background-color: ${(props) => (props.theme.name === 'light' ? '#eff0f2' : '#3e4042')};
    align-items: center;
    border-radius: 6px;
    bottom: 0;
    display: flex;
    gap: 2px;
    height: 58px;
    opacity: 0;
    padding-left: 8px;
    padding-right: 8px;
    position: absolute;
    right: -4px;
    top: 0;
    transition:
      opacity 0.2s ease,
      right 0.2s ease;

    &.editing {
      opacity: 1;
      right: 0;
    }
  }

  &.selected {
    .actions {
      background-color: ${(props) => (props.theme.name === 'light' ? '#dfe1e5' : '#4f5256')};
    }
  }

  &:hover:not(.editing) {
    .actions {
      opacity: 1;
      right: 0px;
    }
  }
`;

@inject('$auth')
@withRouter
@observer
class JourneyListItem extends Component {
  state = {
    journeyTitle: '',
    isEditing: false
  };

  constructor(props) {
    super(props);
    this.state.journeyTitle = props.journey?.get('title');
  }

  handleSaveJourneyTitle() {
    const { journeyTitle } = this.state;
    const { journey } = this.props;
    if (journeyTitle !== '') {
      journey.save({ title: journeyTitle }, { clearSelection: false, toast: false, patch: true });
      this.setState({ isEditing: false });
    }
  }

  handleKeyPress(e) {
    const { journey } = this.props;
    if (e.key === 'Enter') {
      this.handleSaveJourneyTitle();
    }
    if (e.key === 'Escape') {
      this.setState({ isEditing: false, journeyTitle: journey?.get('title') });
    }
  }

  handleJourneyTitleChange(e) {
    this.setState({ journeyTitle: e.target.value });
  }

  handleMakeSudo = () => {
    const { journey } = this.props;
    journey.makeSudo();
  };

  handleToggleShareLevel = () => {
    const { journey } = this.props;
    const updatedShareLevel = journey.get('share_level') === 'self' ? 'org' : 'self';
    const toastMessage = `Journey share level changed to ${updatedShareLevel === 'self' ? 'Private' : 'Company'}.`;

    journey.save({ share_level: updatedShareLevel }, { clearSelection: false, toast: true, toastMessage, patch: true });
  };

  render() {
    const { $auth, journey, hideUserName, hideEditedDate, hideShareLevel, onSelectJourney, history } = this.props;
    const { isEditing, journeyTitle } = this.state;
    const { isSudoer } = $auth;
    const { canEdit, canDelete } = journey;

    const shareLevel = journey.get('share_level');

    if (journey.hidden) {
      return null;
    }

    const isInvestigation = journey.get('metadata.type') === 'investigation';

    const label = (
      <div className={classNames('actions', { editing: isEditing })}>
        {isEditing && (
          <Button
            icon="tick"
            small
            minimal
            onClick={(e) => {
              e.stopPropagation();
              this.handleSaveJourneyTitle();
            }}
          />
        )}
        {!isEditing && isSudoer && !journey?.get('metadata.compare') && (
          <Button
            icon="new-object"
            small
            minimal
            title="Make Sudo"
            onClick={(e) => {
              e.stopPropagation();
              this.handleMakeSudo();
            }}
          />
        )}
        {!isEditing && canEdit && (
          <Tooltip
            position={Position.BOTTOM_LEFT}
            content={shareLevel === 'self' ? 'Share with Company' : 'Make private'}
          >
            <Button
              icon={shareLevel === 'self' ? 'eye-open' : 'eye-off'}
              small
              minimal
              onClick={(e) => {
                e.stopPropagation();
                this.handleToggleShareLevel();
              }}
            />
          </Tooltip>
        )}
        {!isEditing && canEdit && (
          <Button
            icon="edit"
            small
            minimal
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ isEditing: true });
            }}
          />
        )}
        {!isEditing && canDelete && (
          <Button
            disabled={isEditing}
            onClick={(e) => {
              e.stopPropagation();
              journey.destroy({ toast: false }).then(() => {
                history.push('/v4/core/journeys');
              });
            }}
            icon="trash"
            small
            minimal
          />
        )}
      </div>
    );

    return (
      <JourneyMenuItem
        className={classNames({ editing: isEditing, selected: journey.isSelected })}
        text={
          <div className="journey-title">
            {isEditing && (
              <InputGroup
                maxWidth={215}
                autoFocus
                placeholder="Enter title..."
                value={journeyTitle}
                onChange={(e) => this.handleJourneyTitleChange(e)}
                onKeyUp={(e) => this.handleKeyPress(e)}
              />
            )}
            {!isEditing && (
              <Flex flexDirection="column" gap="2px">
                <Text fontWeight={journey.isSelected ? 'bold' : 'normal'} className="journey-title">
                  <Flex alignItems="center" gap="5px" style={{ alignSelf: 'flex-start' }}>
                    {isInvestigation && <KentikAiBadge small />}
                    {journey?.get('title')}
                  </Flex>
                </Text>
                {!hideEditedDate && (
                  <Text muted small>
                    Edited {journey?.edateDuration} ago
                  </Text>
                )}
                {isInvestigation && (
                  <Text small muted>
                    <Flex alignItems="center" gap="3px" style={{ alignSelf: 'flex-start' }}>
                      Investigation started {fromNow(journey?.edate)}
                    </Flex>
                  </Text>
                )}
                {!hideUserName && !isInvestigation && (
                  <Text muted small>
                    {journey?.get('user.user_full_name') || journey?.get('user.user_email')}
                  </Text>
                )}
                {!hideShareLevel && (
                  <Flex gap="4px" alignItems="center">
                    <Icon color="muted" icon={journey.get('share_level') === 'self' ? 'eye-off' : 'eye-open'} />
                    <Text small muted>
                      {journey.get('share_level') === 'self' ? 'Private' : 'Shared'}
                    </Text>
                  </Flex>
                )}
              </Flex>
            )}
          </div>
        }
        active={journey.isSelected}
        onClick={() => {
          onSelectJourney(journey);
        }}
        label={label}
      />
    );
  }
}

export default JourneyListItem;
