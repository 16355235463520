import React from 'react';
import { Text } from 'core/components';

const KeyValueRow = ({ title, value, asText = true }) => (
  <tr>
    <Text
      as="th"
      color="muted"
      pr={2}
      pb={2}
      textAlign="left"
      style={{
        verticalAlign: 'top',
        whiteSpace: 'nowrap',
        fontWeight: 'normal'
      }}
    >
      {title}
    </Text>

    <td valign="top">
      {asText && <Text as="div">{value}</Text>}
      {!asText && <>{value}</>}
    </td>
  </tr>
);

const KeyValueTable = ({ keyValuePairs }) => {
  const elements = [];

  for (let i = 0; i < keyValuePairs.length; i++) {
    const pair = keyValuePairs[i];
    elements.push(<KeyValueRow key={i} title={pair.key} value={pair.value} asText={pair.asText} />);
  }

  return (
    <table>
      <tbody>{elements}</tbody>
    </table>
  );
};

export default KeyValueTable;
