function calculateScrollbarWidth() {
  const scrollDiv = document.createElement('div');
  scrollDiv.className = 'scrollbar-measure';
  document.body.appendChild(scrollDiv);

  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

  document.body.removeChild(scrollDiv);

  return scrollbarWidth;
}

let scrollbarWidth;

export default function getScrollbarWidth() {
  if (scrollbarWidth === undefined) {
    scrollbarWidth = calculateScrollbarWidth();
  }

  return scrollbarWidth;
}
