import { ALARM_STATE_KEY_TO_RAW, STANDARD_ALERT_SEVERITY_TO_ALERT_MANAGER_SEVERITY } from 'shared/alerting/constants';

const incidentLogFields = {
  selected_time: {},
  severity: {
    label: 'Status',
    options: [
      { value: 'all', label: 'All' },
      { value: STANDARD_ALERT_SEVERITY_TO_ALERT_MANAGER_SEVERITY.major, label: 'Warning' },
      { value: STANDARD_ALERT_SEVERITY_TO_ALERT_MANAGER_SEVERITY.critical, label: 'Critical' }
    ],
    defaultValue: 'all'
  },
  state: {
    label: 'State',
    options: [
      { value: 'all', label: 'All' },
      { value: ALARM_STATE_KEY_TO_RAW.alarm, label: 'Active' },
      { value: ALARM_STATE_KEY_TO_RAW.clear, label: 'Cleared' }
    ],
    defaultValue: 'all'
  },
  test_ids: {
    label: 'Test Name',
    defaultValue: []
  },
  test_types: {
    label: 'Test Types',
    defaultValue: []
  },
  labels: {
    label: 'Labels',
    defaultValue: []
  },
  private_agents: {
    label: 'Private Agents',
    defaultValue: []
  },
  global_agents: {
    label: 'Global Agents',
    defaultValue: []
  }
};

export default incidentLogFields;
