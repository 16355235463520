import React, { useEffect, useState } from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';

import { getHashForObject } from 'app/stores/query/urlHash';

const hashCache = new Map();

async function getHashForAgent(agentName) {
  if (hashCache.has(agentName)) {
    return hashCache.get(agentName);
  }

  const hash = await getHashForObject({ searchTerm: agentName });
  hashCache.set(agentName, hash);
  return hash;
}

const KentikAgentLink = ({ $app, $kentikAgents, id, name, ...rest }) => {
  const [hash, setHash] = useState(null);
  const [agentName, setAgentName] = useState(null);

  useEffect(() => {
    if (name) {
      setAgentName(name);
    } else if (id) {
      $kentikAgents.lookupAgents([id]).then((agents) => {
        if (agents?.length) {
          setAgentName(agents[0].name);
        }
      });
    }
  });

  useEffect(() => {
    if (agentName) {
      getHashForAgent(agentName).then((_hash) => setHash(_hash));
    }
  }, [!$app.isSubtenant && id && agentName]);

  if ($app.isSubtenant) {
    return agentName || id;
  }
  return (
    <Link to={`/v4/settings/universal-agents${hash ? `/?q=${hash}` : ''}`} {...rest}>
      {agentName || id}
    </Link>
  );
};
export default inject('$app', '$kentikAgents')(KentikAgentLink);
