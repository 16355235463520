import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('RoutePrefixAggregate'))
class RoutePrefixAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="Route Prefixes/LEN"
        resultsKey="Route Prefix/LEN"
        insightsQuery="fetchRoutePrefixInsights"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'Route Prefix/LEN',
          aggregateFilters: [
            { metric: ['src_route_prefix_len', 'dst_route_prefix_len'] },
            { metric: ['src_route_prefix_len'] },
            { metric: ['dst_route_prefix_len'] },
            { metric: ['src_route_prefix_len', 'dst_route_prefix_len'] },
            { metric: ['src_route_prefix_len', 'dst_route_prefix_len'] }
          ]
        }}
        chartProps={{
          metrics: {
            Internal: ['dst_route_prefix_len', 'src_route_prefix_len'],
            Inbound: 'src_route_prefix_len',
            Outbound: 'dst_route_prefix_len'
          }
        }}
      />
    );
  }
}

export default RoutePrefixAggregate;
