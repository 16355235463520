import React from 'react';
import styled from 'styled-components';
import { Flex, Text } from 'core/components';
import AnimatedArrow, { animatedArrowStyles } from 'app/components/AnimatedArrow';

const StyledHealthStatusButton = styled(Flex)`
  min-width: fit-content;
  height: 36px;
  transition: all 150ms ease-in-out;
  padding: 0 10px;
  gap: 10px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors?.healthStatusButton?.activeBackground : theme.colors?.healthStatusButton?.background};
  border: ${({ theme }) => theme.borders.thinLight}};

  ${({ isClickable }) =>
    isClickable &&
    `
      ${animatedArrowStyles};
      cursor: pointer;
  `}
`;

const StatusDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 6px;
  background: ${({ theme, health }) => theme.colors[health] ?? theme.colors.muted};
`;

function HealthStatusButton({ to, label, value, intent, isActive, onClick, ...rest }) {
  return (
    <StyledHealthStatusButton
      borderRadius={4}
      alignItems="center"
      justifyContent="space-between"
      isClickable={onClick}
      isActive={isActive}
      {...rest}
    >
      <Flex alignItems="center">
        <StatusDot health={intent} />
        <Text fontWeight="bold" color={isActive ? 'primary' : ''}>
          {label}
        </Text>
      </Flex>
      <Flex height={36} alignItems="center" onClick={onClick}>
        <Text fontWeight="bold" fontSize={18} color={isActive ? 'primary' : ''}>
          {value}
        </Text>
        {onClick && <AnimatedArrow />}
      </Flex>
    </StyledHealthStatusButton>
  );
}

export default HealthStatusButton;
