import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Flex, Spinner, Suspense, Text, Box, Sparkline, Button, MenuItem } from 'core/components';
import { Field, FormComponent, Select } from 'core/form';
import Model from 'core/model/Model';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import { getAggregateUnitLabel } from 'app/components/metric/metricRenderers';
import { CLOUD_PROVIDERS } from 'app/util/constants';
import { zeroToText, adjustByGreekPrefix, getToFixed } from 'app/util/utils';
import { withRouter } from 'react-router-dom';
import { addFilters } from 'app/stores/query/FilterUtils';
import WidgetFrame from '../WidgetFrame';

const FORM_OPTIONS = {
  name: 'Cloud InboundOutbound Widget Config'
};

const ALL_CLOUD_FORM_OPTIONS = [
  {
    value: 'gcp',
    label: 'GCP',
    regionFilter: 'ktsubtype__gcp_subnet__STR04src|dstktsubtype__gcp_subnet__STR05'
  },
  { value: 'azure', label: 'Azure', regionFilter: 'kt_az_src|dst_region' },
  { value: 'aws', label: 'AWS', regionFilter: 'kt_aws_src|dst_region' }
];

function getValue(seriesOrValue, results, queryModel) {
  const [aggregateType] = queryModel.get('aggregateTypes');
  return zeroToText(
    adjustByGreekPrefix(
      seriesOrValue.get ? seriesOrValue.get(aggregateType) : seriesOrValue,
      results.prefix[queryModel.outsortUnit]
    ),
    {
      fix: getToFixed(queryModel.outsortUnit)
    }
  );
}

function DetailMenuItem({ type, region, history }) {
  return (
    <MenuItem
      icon="series-search"
      text="View Cloud Details"
      onClick={() =>
        history.push(`/v4/core/quick-views/cloud/${type}${region !== 'All Regions' ? `/region/${region}` : ''}`)
      }
    />
  );
}

function Result({ value, row }) {
  const name = row.get('key');
  return (
    <Flex alignItems="center" justifyContent="space-between" color="muted" mb="4px">
      <Text>{name.charAt(0).toUpperCase() + name.slice(1)}</Text>
      <Text alignItems="center" textAlign="right">
        <Text>{value}</Text>
      </Text>
    </Flex>
  );
}

@inject('$query', '$devices', '$clouds')
@withRouter
@observer
class CloudTrafficProfileWidget extends Component {
  state = {
    loading: true,
    queryOverrides: {
      lookback_seconds: 86400,
      aggregateType: 'max_bits_per_sec'
    }
  };

  static getDerivedStateFromProps(props, state) {
    const { config } = props;
    // If there's no config in state, let's get setup
    if (!state.config) {
      // If there's no config, we show the config panel
      if (!config) {
        return { config: {}, isConfigurePanelOpen: true, model: new Model() };
      }
      return { config, model: new Model(config) };
    }

    return null;
  }

  componentDidMount() {
    const { $devices, $clouds } = this.props;

    const deviceTypes = $devices.activeDeviceSummaries.map((d) => d.device_subtype);
    const cloudOptions = ALL_CLOUD_FORM_OPTIONS.filter((cloud) => deviceTypes.includes(`${cloud.value}_subnet`));
    const cloudRegions = $clouds.getCloudRegions(cloudOptions.map((cloud) => cloud.value));
    const regionOptions = Object.entries(cloudRegions).reduce((acc, item) => {
      const [cloud, regions] = item;

      acc[cloud] = [
        { value: 'All Regions', label: 'All Regions' },
        ...regions.map((region) => ({ value: region, label: region }))
      ];

      return acc;
    }, {});

    this.setState({ loading: false, regionOptions, cloudOptions });
  }

  handleSave = (form) => {
    const { onConfigChange } = this.props;
    const { model } = this.state;

    const config = form.getValues();
    model.set(config);

    this.setState({ isConfigurePanelOpen: false, config });

    if (onConfigChange) {
      onConfigChange(config);
    }
  };

  handleCancel = (form) => {
    form.reset();
    this.setState({ isConfigurePanelOpen: false });
  };

  handleShowConfigurePanel = () => {
    this.setState({ isConfigurePanelOpen: true });
  };

  handleCloudChange = (field, value, prev, form) => {
    form.setValues({ cloud_region: 'All Regions' });
  };

  renderConfigurePanel() {
    const { cloudOptions, regionOptions, model, config } = this.state;

    const fields = {
      cloud_type: {
        label: 'Cloud',
        rules: 'required'
      },
      cloud_region: {
        label: 'Region'
      }
    };

    return (
      <FormComponent fields={fields} options={FORM_OPTIONS} model={model}>
        {({ form }) => (
          <Flex flexDirection="column" flex={1}>
            <Box flex={1}>
              <Field name="cloud_type" large options={cloudOptions} onChange={this.handleCloudChange}>
                <Select />
              </Field>
              <Field
                name="cloud_region"
                large
                placeholder="All Regions"
                options={regionOptions[form.getValue('cloud_type')]}
              >
                <Select />
              </Field>
            </Box>
            <Flex pb={2}>
              {config.cloud_type && (
                <Button text="Cancel" onClick={() => this.handleCancel(form)} mr={1} minWidth={100} />
              )}
              <Button
                text="Save"
                intent="primary"
                disabled={!form.valid}
                onClick={() => this.handleSave(form)}
                minWidth={100}
              />
            </Flex>
          </Flex>
        )}
      </FormComponent>
    );
  }

  renderSparkline(results, queryModel) {
    const { config } = this.state;
    const { prefix } = results;
    const [aggregateType] = queryModel.get('aggregateTypes');
    const [aggregate] = queryModel.aggregates;
    const [totalOverlay] = results.overlayRows;
    const total = (totalOverlay && totalOverlay.get(aggregateType)) || 0;

    const [, ...aggTypeParts] = aggregateType.split('_');
    const rawDataAttr = `rawData.both_${aggTypeParts.join('_')}.flow`;

    const sparklineData =
      totalOverlay && totalOverlay.get(rawDataAttr) ? totalOverlay.get(rawDataAttr).map((col) => col[1]) : [];

    const cloudProvider = CLOUD_PROVIDERS.byId(config.cloud_type);

    return (
      <>
        <cloudProvider.logoCmp
          style={{
            width: 120,
            height: 120,
            position: 'absolute',
            left: 'calc(50% - 60px)',
            top: 'calc(50% - 50px)',
            opacity: 0.1
          }}
        />

        <Flex
          position="absolute"
          left={0}
          bottom={0}
          flexDirection="column"
          justifyContent="flex-end"
          width="100%"
          overflow="hidden"
        >
          <Sparkline
            data={sparklineData}
            strokeWidth={1.5}
            height={25}
            margin={4}
            color={cloudProvider.color || 'primary'}
          />
        </Flex>

        <Flex justifyContent="flex-end" color="muted" fontSize="small" mb="4px">
          {getAggregateUnitLabel({ aggregate, prefix })}
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mb="4px">
          <Text fontWeight="bold">Total</Text>
          <Text fontWeight="bold" alignItems="center" textAlign="right">
            <Text>{getValue(total, results, queryModel)}</Text>
          </Text>
        </Flex>

        {results.nonOverlayRows.map((row) => {
          const value = getValue(row, results, queryModel);
          return <Result key={row.id} row={row} value={value} />;
        })}
      </>
    );
  }

  renderData() {
    const { $query } = this.props;
    const { queryOverrides, isConfigurePanelOpen, config } = this.state;
    if (!config.cloud_type || isConfigurePanelOpen) {
      return null;
    }

    const updatedQueryOverrides = Object.assign({}, queryOverrides, {
      filterDimensionsEnabled: false,
      metric: ['simple_trf_prof'],
      all_devices: false,
      device_types: [`${config.cloud_type}_subnet`]
    });

    const query = $query.get('inboundOutboundInternalQuery', updatedQueryOverrides);
    if (config.cloud_region !== 'All Regions') {
      addFilters(query, [
        {
          filterField: ALL_CLOUD_FORM_OPTIONS.find((cloud) => cloud.value === config.cloud_type)?.regionFilter,
          operator: '=',
          filterValue: `${config.cloud_region}`
        }
      ]);
    }

    return (
      <LightweightDataViewWrapper query={query}>
        {({ loading, results, queryModel }) => (
          <Suspense loading={loading} fallback={<Spinner size={24} />}>
            {this.renderSparkline(results, queryModel)}
          </Suspense>
        )}
      </LightweightDataViewWrapper>
    );
  }

  render() {
    const { canCustomize, onRemove, history } = this.props;
    const { isConfigurePanelOpen, loading, config } = this.state;
    const title = config.cloud_type
      ? `${CLOUD_PROVIDERS.byId(config.cloud_type).code}
        ${config.cloud_region ? ` - ${config.cloud_region}` : ''}`
      : 'Select Cloud Source';
    const tooltip =
      'Provides an at-a-glance view of the total traffic of your [AWS, Azure, GCP, etc] environment, broken out by various traffic profiles.';

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        menuOptions={<DetailMenuItem type={config.cloud_type} region={config.cloud_region} history={history} />}
        configAction={this.handleShowConfigurePanel}
        onRemove={onRemove}
        title={title}
        tooltip={tooltip}
        display="flex"
        flexDirection="column"
        position="relative"
        pt={1}
        px={2}
        pb={0}
      >
        <Suspense
          loading={loading}
          fallback={
            <Box pt={2}>
              <Spinner size={24} />
            </Box>
          }
        >
          {(isConfigurePanelOpen || !config.cloud_type) && this.renderConfigurePanel()}
          {!isConfigurePanelOpen && this.renderData()}
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default CloudTrafficProfileWidget;
