import React from 'react';
import { Box, Flex, Text } from 'core/components';
import { numberWithCommas, intlCurrency } from 'app/util/utils';

function LargeLabeledValue(props) {
  const {
    label,
    value,
    valueElement,
    unit,
    currency,
    precision,
    valueSize,
    valueWeight = 'black',
    labelColor,
    labelSize = 12,
    labelTextProps = {},
    valueColor,
    valueBoxProps,
    valueTextProps,
    shouldFormatValue = true,
    ...rest
  } = props;

  const fixedValue = precision === undefined ? value : value?.toFixed(precision) || 0;
  // eslint-disable-next-line no-nested-ternary
  const formattedValue = currency
    ? intlCurrency(value, currency)
    : shouldFormatValue
      ? numberWithCommas(fixedValue)
      : fixedValue;

  return (
    <Box {...rest}>
      <Text as="div" fontSize={labelSize} muted color={labelColor} {...labelTextProps}>
        {label}
      </Text>

      <Flex {...valueBoxProps}>
        {unit && unit.includes('$') && (
          <Text
            muted
            as="span"
            fontWeight="normal"
            fontSize={valueSize * 0.75}
            lineHeight={`${valueSize * 1.25}px`}
            mr="3px"
          >
            {unit}
          </Text>
        )}

        <Text fontSize={valueSize} fontWeight={valueWeight} color={valueColor} {...valueTextProps}>
          {formattedValue}
        </Text>

        {unit && !unit.includes('$') && (
          <Text muted as="span" fontWeight="normal" fontSize={12} lineHeight={`${valueSize}px`} ml="3px">
            {unit}
          </Text>
        )}

        {valueElement}
      </Flex>
    </Box>
  );
}

LargeLabeledValue.defaultProps = {
  valueSize: 20,
  labelSize: 12,
  valueBoxProps: { alignItems: 'flex-end' }
};

export default LargeLabeledValue;
