import React from 'react';
import { Box, LinkButton, Text } from 'core/components';
import $auth from 'app/stores/$auth';

import $rbac from 'app/stores/rbac/$rbac';
import AbstractDependency, { queryContains } from './AbstractDependency';

const CONSTRAINTS = {
  metrics: [
    'i_src_connect_type_name',
    'i_dst_connect_type_name',
    'i_src_network_bndry_name',
    'i_dst_network_bndry_name'
  ],
  filterFields: [
    'i_src_connect_type_name',
    'i_dst_connect_type_name',
    'i_src_network_bndry_name',
    'i_dst_network_bndry_name'
  ]
};

class InterfaceClassDependency extends AbstractDependency {
  get key() {
    return 'ic';
  }

  get constraints() {
    return CONSTRAINTS;
  }

  get meets() {
    return !$auth.interfaceClassBelowThreshold;
  }

  get message() {
    return (
      <>
        <Box mb="4px">
          <Text small>
            <strong>Interface Classification</strong> is required by dimensions you&apos;re using, but your organization
            currently has only {$auth.interfacesClassifiedDisplay}% of interfaces classified. Results shown herein may
            be highly inaccurate.
          </Text>
        </Box>
        {$rbac.hasUnrestrictedPermissions(['device::read']) && (
          <LinkButton icon="cog" small text="Configure" to="/v4/settings/interface-classification" />
        )}
      </>
    );
  }

  static queryMeets(query) {
    return !queryContains(query, CONSTRAINTS) || !$auth.interfaceClassBelowThreshold;
  }
}

export default InterfaceClassDependency;
