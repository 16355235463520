import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Box, Button, Card, Flex, Heading, Text } from 'core/components';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import VerticalDetailTabs from 'app/components/detailTabs/VerticalDetailTabs';
import Page from 'app/components/page/Page';
import InsightsMenu from 'app/components/navbar/InsightsMenu';
import SiteModel from 'app/stores/site/SiteModel';
import NetworkExplorerTabs from 'app/views/core/NetworkExplorerTabs';
import SiteFormDialog from 'app/views/settings/sites/SiteFormDialog';
import parentLinks from 'app/views/core/NetworkExplorerParentLink';
import SiteDetail from 'app/views/core/details/sites/SiteDetail';
import AggregateViewHeader from 'app/views/core/aggregate/AggregateViewHeader';
import FavoriteButton from 'app/views/core/FavoriteButton';

const simpleFiltersBlacklist = [
  'i_device_name',
  'i_device_site_name',
  'i_device_site_country',
  'interface_description',
  'snmp_alias'
];

@withRouter
@inject('$exports', '$sites', '$lookups', '$tabs', '$auth')
@observer
class SiteDetails extends Component {
  constructor(props) {
    super(props);

    const { $tabs } = props;
    const tabs = $tabs.getTabs();

    this.state.tabBlacklist = [tabs.SITES.id];
  }

  state = {
    site: new SiteModel(),
    lookback_seconds: 86400,
    aggregateType: 'p95th_bits_per_sec'
  };

  componentDidMount() {
    const { $exports } = this.props;
    const { aggregateType, filters, lookback_seconds } = this.state;

    $exports.getSettings().then(({ hashedAggregateType, hashedFilters, hashedLookbackSeconds }) => {
      this.setState({
        aggregateType: hashedAggregateType || aggregateType,
        filters: hashedFilters || filters,
        lookback_seconds: hashedLookbackSeconds || lookback_seconds
      });
    });

    this.fetchSite();
  }

  static getDerivedStateFromProps(props, state) {
    const { match } = props;
    const { siteId } = match.params;

    if (siteId !== state.siteId) {
      return {
        siteId,
        site: null
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { siteId } = this.state;
    if (siteId !== prevState.siteId) {
      this.fetchSite();
    }
  }

  fetchSite() {
    const { $sites, $lookups, history, location } = this.props;
    const { siteId } = this.state;

    if (Number.isNaN(parseInt(siteId))) {
      return $lookups
        .sites(siteId, undefined, 'id')
        .then((results) => results.length && parseInt(results[0].value))
        .then((newSiteId) => {
          history.replace({
            ...location,
            pathname: `/v4/core/quick-views/sites/${newSiteId}`
          });
        });
    }

    return $sites.fetchSite(siteId).then((site) => {
      if (site) {
        this.setState({ site });
      }
    });
  }

  get filters() {
    const { site, filters } = this.state;
    return (filters || []).concat({
      filterField: 'i_device_site_name',
      operator: '=',
      filterValue: site.get('title')
    });
  }

  handleFiltersApply = (filters) => {
    this.setState({ filters });
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds });
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType });
  };

  onConfigureClick = () => {
    this.setState({ isSiteDialogOpen: true });
  };

  onSiteDialogClose = () => {
    this.setState({ isSiteDialogOpen: false });
  };

  render() {
    const { $auth, match } = this.props;
    const { site, aggregateType, filters, lookback_seconds, tabBlacklist, isSiteDialogOpen } = this.state;

    return (
      <Page
        title={site ? site.get('title') : undefined}
        parentLinks={[...parentLinks, { link: '/v4/core/quick-views/sites', label: 'Sites' }]}
        insightsMenu={site ? <InsightsMenu query="fetchSiteInsights" params={site.id} /> : undefined}
        scrolls
        showExport
      >
        {site && (
          <>
            <Flex alignItems="flex-start" flexWrap="wrap" pb={1}>
              <Box flex="1 1 auto" overflow="hidden">
                <Flex alignItems="center" gap="4px" flexWrap="wrap">
                  <FavoriteButton
                    type="page"
                    id={match.url}
                    name={site.get('title')}
                    metadata={{ resultType: 'site' }}
                  />
                  <Heading level={1} mb={0}>
                    {site.get('title')}
                  </Heading>
                </Flex>
                <Text as="div">{site.siteLocation}</Text>
              </Box>
              <Box>
                {$auth.isAdministrator && (
                  <Button intent="primary" icon="cog" text="Configure" onClick={this.onConfigureClick} />
                )}
              </Box>
            </Flex>

            <NetworkExplorerTabs
              trafficTabContent={
                <>
                  <AggregateViewHeader
                    aggregateType={aggregateType}
                    filters={filters}
                    lookbackSeconds={lookback_seconds}
                    onFiltersChange={this.handleFiltersApply}
                    onAggregateChange={this.handleAggregationChange}
                    onMetricsChange={this.handleAggregationChange}
                    onTimeRangeChange={this.handleLookbackChange}
                    fieldBlacklist={simpleFiltersBlacklist}
                  />

                  <Card className="break-after" mb={3}>
                    <TabbedChart
                      overrides={{ lookback_seconds, aggregateTypes: [aggregateType] }}
                      simpleFilters={this.filters}
                    />
                  </Card>

                  <VerticalDetailTabs
                    blacklist={tabBlacklist}
                    queryOverrides={{ lookback_seconds, aggregateTypes: [aggregateType] }}
                    simpleFilters={this.filters}
                  />
                </>
              }
              moreInfoTabContent={<SiteDetail site={site} />}
            />
            {isSiteDialogOpen && <SiteFormDialog model={site} onClose={this.onSiteDialogClose} />}
          </>
        )}
      </Page>
    );
  }
}

export default SiteDetails;
