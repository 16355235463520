import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Classes } from '@blueprintjs/core';

import storeLoader from 'app/stores/storeLoader';
import { formConsumer, Field, Select } from 'core/form';
import { Box, showErrorToast, showSuccessToast, Spinner, Suspense } from 'core/components';
import Button from 'core/components/Button';

import NotificationFormDialog from 'app/views/settings/notifications/NotificationsFormDialog';
import NotificationOption from './NotificationOption';

const Container = styled.div`
  .${Classes.INPUT} > .${Classes.TAG} {
    padding-left: 0px;
  }
`;

@formConsumer
@storeLoader('$notifications')
@inject('$auth')
@observer
class NotificationsSelect extends Component {
  state = {
    loading: false,
    isDialogOpen: false,
    selectedModel: undefined
  };

  static defaultProps = {
    fieldName: 'notifications',
    showTestButton: true,
    isRequired: false,
    fieldProps: {},
    containerProps: {},
    hideTenantChannels: true
  };

  get canAddNotificationChannel() {
    const { $auth } = this.props;
    return $auth.hasPermission('alerts.notifications.create');
  }

  get options() {
    const { $notifications, hideTenantChannels } = this.props;
    const options = $notifications.collection.notificationOptions;

    if (hideTenantChannels) {
      return options.filter((option) => !parseInt(option.userGroupId, 10) > 0);
    }

    return options;
  }

  get field() {
    const { form, fieldName, fieldProps } = this.props;
    return fieldProps.field || form.getField(fieldName);
  }

  optionFormatter = (item) =>
    item && (
      <Box>
        <NotificationOption option={item} />
      </Box>
    );

  handleAddClick = () => {
    const { $notifications } = this.props;
    this.setState(() => ({
      isDialogOpen: true,
      selectedModel: $notifications.collection.forge(undefined, { select: false })
    }));
  };

  handleDialogClose = () => {
    const { selectedModel } = this.state;

    if (selectedModel && !selectedModel.isNew) {
      const { $notifications } = this.props;

      $notifications.collection.fetch().then(() => {
        const currentSelections = this.field.value;
        this.field.setValue(currentSelections.concat(selectedModel.id));
      });
    }
    this.setState(() => ({
      isDialogOpen: false,
      selectedModel: null
    }));
  };

  handleTestClick = () => {
    const { $notifications } = this.props;

    this.setState({ loading: true });

    Promise.all(this.field.value.map((chId) => $notifications.testChannel(chId)))
      .then(
        () => {
          showSuccessToast('Test Notifications have been sent.');
        },
        () => {
          showErrorToast('Test Notifications could not be sent.');
        }
      )
      .then(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { isDialogOpen, selectedModel, loading } = this.state;
    const {
      $auth,
      form,
      $notifications,
      fieldName,
      disabled,
      showAddButton,
      showTestButton,
      hideTenantChannels,
      containerProps,
      fieldProps,
      onChange,
      ...selectProps
    } = this.props;

    return (
      <Suspense
        loading={!$notifications.collection.hasFetched && $notifications.collection.loading}
        fallback={
          <Box pt={2}>
            <Spinner size={14} />
          </Box>
        }
      >
        <Container {...containerProps}>
          <Field
            name={fieldName}
            options={this.options}
            width="auto"
            mb="4px"
            large
            disabled={disabled}
            onChange={onChange}
            {...fieldProps}
          >
            <Select
              fill
              multi
              showFilter
              optionFormatter={this.optionFormatter}
              valueRenderer={this.optionFormatter}
              {...selectProps}
            />
          </Field>

          {this.canAddNotificationChannel && showAddButton && (
            <Button minimal text="Add New Channel" onClick={this.handleAddClick} intent="primary" />
          )}

          {this.canAddNotificationChannel && showTestButton && this.field.value.length > 0 && (
            <Button
              minimal
              intent="primary"
              text="Test Notification Channels"
              loading={loading}
              onClick={this.handleTestClick}
            />
          )}
          {isDialogOpen && <NotificationFormDialog model={selectedModel} onClose={this.handleDialogClose} />}
        </Container>
      </Suspense>
    );
  }
}

export default NotificationsSelect;
