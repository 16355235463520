/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { withTheme } from 'styled-components';
import { getPolygonInfo } from 'app/util/graphing';

@inject('$topo')
@observer
class GroupText extends Component {
  static propTypes = {
    groupId: PropTypes.string.isRequired,
    color: PropTypes.string,
    backgroundFilter: PropTypes.string,
    onShowDetails: PropTypes.func
  };

  static defaultProps = {
    color: undefined,
    backgroundFilter: undefined,
    onShowDetails: () => {}
  };

  @computed
  get group() {
    const { $topo, groupId } = this.props;
    const { getGroup } = $topo;
    return getGroup(groupId);
  }

  @computed
  get polygonInfo() {
    return getPolygonInfo(this.group.polygon, this.group.collapsed);
  }

  handleShowDetails = (e) => {
    const { color, onShowDetails } = this.props;
    onShowDetails('group', this.group, this.groupEl, { color }, e);
  };

  render() {
    const { theme, color, backgroundFilter } = this.props;
    const groupColor = color || theme.colors.gray4;

    if (this.group.id === 'unlinked') {
      return null;
    }

    return (
      <g
        transform={`translate(${this.polygonInfo.centroid[0]},${this.polygonInfo.centroid[1]})
          scale(${this.polygonInfo.scaleFactor})`}
        className="grouptext"
        style={{ cursor: 'pointer' }}
        onClick={this.handleShowDetails}
      >
        <text
          dx="0"
          dy={-this.polygonInfo.height / 2 - 3}
          textAnchor="middle"
          fill={groupColor}
          style={{ fontWeight: theme.fontWeights.heavy }}
          filter={backgroundFilter ? `url(#${backgroundFilter})` : ''}
        >
          {this.group.name || this.group.id}
        </text>
      </g>
    );
  }
}

export default withTheme(GroupText);
