import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('AsPathAggregate'))
class AsPathAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="AS Paths"
        resultsKey="Country"
        insightsQuery="fetchAsPathInsights"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'AS Path',
          aggregateFilters: [
            { metric: ['src_bgp_aspath', 'dst_bgp_aspath'] },
            { metric: ['src_bgp_aspath'] },
            { metric: ['dst_bgp_aspath'] },
            { metric: ['src_bgp_aspath', 'dst_bgp_aspath'] },
            { metric: ['src_bgp_aspath', 'dst_bgp_aspath'] }
          ]
        }}
        chartProps={{
          metrics: {
            Internal: ['dst_bgp_aspath', 'src_bgp_aspath'],
            Inbound: 'src_bgp_aspath',
            Outbound: 'dst_bgp_aspath'
          },
          topxMetricField: 'src|dst_bgp_aspath'
        }}
      />
    );
  }
}

export default AsPathAggregate;
