import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FiSliders } from 'react-icons/fi';

import { Box, Button, Callout, Card, Flex, Heading, Link, LinkButton, Tag, Text } from 'core/components';
import TabbedChart from 'app/components/tabbedChart/TabbedChart';
import VerticalDetailTabs from 'app/components/detailTabs/VerticalDetailTabs';
import Page from 'app/components/page/Page';
import InsightsMenu from 'app/components/navbar/InsightsMenu';
import InterfaceFormDialog from 'app/views/settings/interfaces/InterfaceFormDialog';
import AggregateViewHeader from 'app/views/core/aggregate/AggregateViewHeader';
import parentLinks from 'app/views/core/NetworkExplorerParentLink';
import { Collection } from 'core/model';
import FavoriteButton from 'app/views/core/FavoriteButton';
import NetworkExplorerTabs from '../../NetworkExplorerTabs';
import InterfaceCapacityTab from './tabs/InterfaceCapacityTab';
import InterfaceMetricsTab from './tabs/InterfaceMetricsTab';
import InterfaceInfoTab from './tabs/InterfaceInfoTab';

const simpleFiltersBlacklist = [
  'i_device_name',
  'i_device_site_name',
  'i_device_site_country',
  'interface_description',
  'snmp_alias'
];

@inject('$auth', '$exports', '$interfaces', '$capacity', '$tabs')
@observer
class InterfaceDetails extends Component {
  state = {
    interfaceNotFound: false,
    lookback_seconds: 86400,
    aggregateType: 'p95th_bits_per_sec',
    isEditDialogOpen: false,
    capacityPlans: []
  };

  componentDidMount() {
    this.fetchInterface();
    const { $tabs, $exports } = this.props;
    const { aggregateType, filters, lookback_seconds } = this.state;

    this.setState({
      tabs: $tabs.getTabs()
    });

    $exports.getSettings().then(({ hashedAggregateType, hashedFilters, hashedLookbackSeconds }) => {
      this.setState({
        aggregateType: hashedAggregateType || aggregateType,
        filters: hashedFilters || filters,
        lookback_seconds: hashedLookbackSeconds || lookback_seconds
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.deviceInterfaceHash !== prevProps.match.params.deviceInterfaceHash) {
      this.fetchInterface();
    }
  }

  fetchInterface() {
    const { $interfaces, $capacity } = this.props;

    const device_name = this.deviceName;
    const snmp_id = this.snmpId;
    const interface_name = this.interfaceName;

    $interfaces
      .fetchInterface({ device_name, snmp_id, interface_name })
      .then((model) =>
        $capacity
          .fetchPlansForInterface({ deviceId: model.get('device_id'), snmpId: model.get('snmp_id') })
          .then((capacityPlans) => {
            this.setState({ selectedInterface: model, capacityPlans: new Collection(capacityPlans).models });
          })
      )
      .catch(() => {
        this.setState({ interfaceNotFound: true });
      });
  }

  handleEditDialogOpen = () => {
    this.setState({ isEditDialogOpen: true });
  };

  handleEditDialogClose = () => {
    this.setState({ isEditDialogOpen: false });
  };

  handleLookbackChange = (lookback_seconds) => {
    this.setState({ lookback_seconds });
  };

  handleAggregationChange = (aggregateType) => {
    this.setState({ aggregateType });
  };

  handleFiltersApply = (filters) => {
    this.setState({ filters });
  };

  get deviceInterfaceHash() {
    const { match } = this.props;
    return match.params.deviceInterfaceHash;
  }

  get deviceName() {
    const interfaceToken = this.deviceInterfaceHash.lastIndexOf('@');
    if (interfaceToken >= 0) {
      return this.deviceInterfaceHash.substring(0, interfaceToken);
    }

    const splitToken = this.deviceInterfaceHash.lastIndexOf('-');
    return this.deviceInterfaceHash.substring(0, splitToken);
  }

  get snmpId() {
    const interfaceToken = this.deviceInterfaceHash.lastIndexOf('@');
    if (interfaceToken >= 0) {
      return undefined;
    }

    const splitToken = this.deviceInterfaceHash.lastIndexOf('-');
    return this.deviceInterfaceHash.substring(splitToken + 1);
  }

  get interfaceName() {
    const splitToken = this.deviceInterfaceHash.lastIndexOf('@');

    if (splitToken === -1) {
      return undefined;
    }

    return decodeURIComponent(this.deviceInterfaceHash.substring(splitToken + 1));
  }

  get filters() {
    const { filters, selectedInterface } = this.state;

    return (filters || []).concat({
      filterField: 'interface',
      operator: '=',
      filterValue: selectedInterface.get('snmp_id')
    });
  }

  get queryOverrides() {
    const { aggregateType, lookback_seconds } = this.state;

    return {
      all_devices: false,
      lookback_seconds,
      aggregateTypes: [aggregateType],
      device_name: [this.deviceName]
    };
  }

  renderTrafficTabContent = () => {
    const { $auth } = this.props;
    const { aggregateType, filters, lookback_seconds, tabs } = this.state;

    return (
      <>
        <Box ml={2}>
          <Flex mb={2} justifyContent="flex-end">
            <LinkButton
              icon={FiSliders}
              text="Engineer Traffic"
              to={`/v4/edge/traffic/eng/${this.deviceInterfaceHash}`}
              intent="primary"
            />
            {$auth.isAdministrator && (
              <Button icon="cog" text="Configure" onClick={this.handleEditDialogOpen} intent="primary" ml={1} />
            )}
          </Flex>
          <AggregateViewHeader
            aggregateType={aggregateType}
            filters={filters}
            lookbackSeconds={lookback_seconds}
            onFiltersChange={this.handleFiltersApply}
            onAggregateChange={this.handleAggregationChange}
            onMetricsChange={this.handleAggregationChange}
            onTimeRangeChange={this.handleLookbackChange}
            fieldBlacklist={simpleFiltersBlacklist}
          />

          <Card flex="0 0 auto" overflow="hidden" className="break-after">
            <Box>
              <TabbedChart
                height={424}
                queryName="interfaceIngressEgressQuery"
                overrides={this.queryOverrides}
                simpleFilters={this.filters}
                viewProps={{ useQualitativeColors: true }}
              />
            </Box>
          </Card>

          <Box mt={2}>
            <VerticalDetailTabs
              tabBlacklist={[tabs.INTERFACES.id]}
              queryOverrides={this.queryOverrides}
              simpleFilters={this.filters}
            />
          </Box>
        </Box>
      </>
    );
  };

  renderMoreInfoTabContent = () => {
    const { capacityPlans, selectedInterface } = this.state;
    return (
      <>
        <InterfaceInfoTab capacityPlans={capacityPlans} selectedInterface={selectedInterface} />
      </>
    );
  };

  renderMetricsTabContent = () => {
    const { capacityPlans, selectedInterface } = this.state;
    return (
      <>
        <InterfaceMetricsTab capacityPlans={capacityPlans} selectedInterface={selectedInterface} />
      </>
    );
  };

  renderCapacityTabContent = () => {
    const { loading } = this.props;
    const { capacityPlans, selectedInterface } = this.state;

    return (
      <>
        <InterfaceCapacityTab loading={loading} capacityPlans={capacityPlans} selectedInterface={selectedInterface} />
      </>
    );
  };

  render() {
    const { $auth, match } = this.props;
    const { selectedInterface, isEditDialogOpen, interfaceNotFound } = this.state;

    const snmpId = selectedInterface?.get('snmp_id');
    const description = selectedInterface?.get('interface_description');

    const deviceName = selectedInterface?.get('device').get('device_name');
    const snmpAlias = selectedInterface?.get('snmp_alias');

    return (
      <Page
        title={selectedInterface ? description || snmpId : undefined}
        parentLinks={[...parentLinks, { link: '/v4/core/quick-views/interfaces', label: 'Interfaces' }]}
        insightsMenu={<InsightsMenu query="fetchInterfaceInsights" paramsArray={[this.deviceName, snmpId]} />}
        scrolls
        showExport
      >
        {interfaceNotFound && (
          <Callout mt={4} mx="auto" maxWidth={500} intent="danger">
            The specified interface was not found. You may try:
            <Box as="ul">
              <Box as="li">
                <Link to="/v4/core/quick-views/interfaces">View all interfaces</Link>
              </Box>
            </Box>
          </Callout>
        )}
        {selectedInterface && (
          <Flex alignItems="flex-start" flexWrap="wrap" pb={1}>
            <Box flex={1} pb={1}>
              <Flex alignItems="center" gap="4px" mb="4px">
                <FavoriteButton
                  type="page"
                  id={match.url}
                  name={description || 'interface'}
                  metadata={{ resultType: 'interface' }}
                />
                <Heading level={1} mb={0}>
                  {description || 'No description'}
                </Heading>
                <Tag intent={selectedInterface.isUnclassified ? 'danger' : 'success'} ml={1}>
                  {selectedInterface.isUnclassified ? 'Unclassified' : 'Classified'}
                </Tag>
              </Flex>
              <Text color="muted" as="div">
                {deviceName} {deviceName && snmpAlias && ' / '} {snmpAlias}
              </Text>
            </Box>

            <NetworkExplorerTabs
              moreInfoTabContent={this.renderMoreInfoTabContent()}
              trafficTabContent={this.renderTrafficTabContent()}
              capacityTabContent={this.renderCapacityTabContent()}
              metricsTabContent={this.renderMetricsTabContent()}
            />

            {$auth.isAdministrator && isEditDialogOpen && (
              <InterfaceFormDialog model={selectedInterface} onClose={this.handleEditDialogClose} />
            )}
          </Flex>
        )}
      </Page>
    );
  }
}

export default InterfaceDetails;
