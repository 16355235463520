import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';
import moment from 'moment';
import { BiBarChartSquare } from 'react-icons/bi';
import { Box, Icon, Flex, Text, Select, Spinner, Suspense, Tag } from 'core/components';
import Model from 'core/model/Model';
import { adjustByGreekPrefix } from 'core/util';
import { zeroToText } from 'app/util/utils';
import ViewInExplorerMenuItem from 'app/components/dataviews/tools/ViewInExplorerMenuItem';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import ResultsTable from 'app/components/resultsTable/ResultsTable';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import { AWS, AZURE } from 'app/views/hybrid/maps/cloudDimensionConstants';

const COMPARE_OPTIONS = [
  {
    value: 3600,
    label: 'Last Hour',
    compareRange: [moment().subtract(2, 'hours').toDate(), moment().subtract(1, 'hours').toDate()]
  },
  {
    value: 86400,
    label: 'Last Day',
    compareRange: [moment().subtract(1, 'day').subtract(1, 'hours').toDate(), moment().subtract(1, 'day').toDate()]
  },
  {
    value: 604800,
    label: 'Last Week',
    compareRange: [moment().subtract(1, 'weeks').subtract(1, 'hours').toDate(), moment().subtract(1, 'weeks').toDate()]
  },
  {
    value: 2592000,
    label: 'Last 30 Days',
    compareRange: [moment().subtract(30, 'day').subtract(1, 'hours').toDate(), moment().subtract(30, 'day').toDate()]
  }
];

const DEFAULT_CONFIG = {
  compare: 604800
};

function getTrafficQuery(cloudType, region) {
  const CLOUD_CONSTANTS = { AWS, AZURE };
  const CLOUD = CLOUD_CONSTANTS[cloudType.toUpperCase()];
  return {
    all_devices: false,
    device_types: [CLOUD.DATASET],
    metric: [CLOUD.DST.GATEWAY_TYPE],
    aggregateFiltersEnabled: false,
    bracketOptions: '',
    aggregateTypes: ['avg_bits_per_sec'],
    aggregates: [],
    aggregateFilters: [],
    show_total_overlay: false,
    show_overlay: false,
    from_to_lookback: 3600,
    lookback_seconds: 3600,
    outsort: 'avg_bits_per_sec',
    depth: 100,
    filters:
      region === 'all'
        ? undefined
        : {
            connector: 'All',
            filterGroups: [
              {
                name: '',
                named: false,
                connector: 'All',
                not: false,
                autoAdded: '',
                filters: [
                  {
                    filterField: CLOUD.SRC.REGION,
                    operator: '=',
                    filterValue: region
                  }
                ],
                saved_filters: [],
                filterGroups: []
              }
            ]
          }
  };
}

function getComparisonQuery(cloudType, region, compare) {
  const dateRange = COMPARE_OPTIONS.find((option) => option.value === compare).compareRange;

  return {
    ...getTrafficQuery(cloudType, region),
    lookback_seconds: 0,
    starting_time: dateRange[0],
    ending_time: dateRange[1]
  };
}

function regionRenderer(option) {
  return (
    <Flex justifyContent="flex-start" alignItems="center">
      {option && (
        <>
          <Icon icon="globe-network" pr="4px" />
          <Text as="div" fontSize="small" color="muted" pr="4px">
            Region:
          </Text>
          <Text as="div" fontWeight="bold" fontSize="small">
            {option.label}
          </Text>
        </>
      )}
      {!option && <Text>Select a Region...</Text>}
    </Flex>
  );
}

function compareRenderer(option) {
  return (
    <Flex justifyContent="flex-start" alignItems="center">
      <Icon icon={BiBarChartSquare} pr="4px" />
      <Text as="div" fontSize="small" color="muted" pr="4px">
        Compare:
      </Text>
      <Text as="div" fontWeight="bold" fontSize="small">
        {option.label}
      </Text>
    </Flex>
  );
}

function percentageChange(oldNum, newNum) {
  if (!oldNum || !newNum) {
    return 0;
  }
  const actual = ((oldNum - newNum) / oldNum) * 100;
  return actual > -1 && actual < 1 ? +actual.toFixed(1) : actual.toFixed();
}

@withTheme
@inject('$clouds')
@observer
class GatewayTrafficWidget extends Component {
  state = {
    config: null,
    regions: []
  };

  static defaultProps = {
    config: { cloudType: 'aws' }
  };

  static getDerivedStateFromProps(props, state) {
    const { config } = props;

    // If there's no config in state, let's get setup
    if (!state.config) {
      const combinedConfig = { ...DEFAULT_CONFIG, ...config };
      return { config: combinedConfig, model: new Model(combinedConfig) };
    }

    return null;
  }

  componentDidMount() {
    const { $clouds, config } = this.props;
    const cloudRegions = $clouds.getCloudRegions([config.cloudType]);
    const regions = [
      { value: 'all', label: 'All Regions' },
      ...cloudRegions[config.cloudType].map((region) => ({ value: region, label: region }))
    ];

    this.setState((state) => {
      const nextState = { regions };

      if (regions.length > 0) {
        nextState.config = { region: regions[0].value, ...state.config };
      }

      return nextState;
    });
  }

  handleOptionChange = (changed) => {
    const { onConfigChange } = this.props;
    const { config, model } = this.state;

    model.set(changed);
    const newConfig = { ...config, ...changed };
    this.setState({ config: newConfig });

    if (onConfigChange) {
      onConfigChange(newConfig);
    }
  };

  getIntent(value) {
    let intent;

    if (value > 0) {
      intent = 'success';
    } else if (value < 0) {
      intent = 'danger';
    } else {
      intent = 'none';
    }
    return intent;
  }

  getValueOverrides({ curr, compare }) {
    const compareData = curr.models.map((modelCurrent) => {
      const modelCompare = compare.models.find((match) => match.get('key') === modelCurrent.get('key'));
      const inChange = percentageChange(modelCurrent.get('avg_bits_per_sec'), modelCompare?.get('avg_bits_per_sec'));
      return {
        key: modelCurrent.get('key'),
        in: inChange,
        in_intent: this.getIntent(inChange)
      };
    });

    return [
      {
        key: 'avg_bits_per_sec',
        width: 120,
        headerJustify: 'center',
        renderer: ({ value, model }) => {
          const change = compareData.find((row) => row.key === model.get('key'));
          return (
            <Flex alignItems="center" justifyContent="flex-start" overflow="hidden">
              <Box flex={1} textAlign="right" pr={1}>
                {zeroToText(adjustByGreekPrefix(value, curr.prefix.bytes) || 0)}
              </Box>
              <Flex flex={1} alignItems="center" justifyContent="flex-start" overflow="hidden">
                <Tag as="div" textAlign="center" intent={change.in_intent} minimal ellipsis={false}>
                  {change.in === 0 ? '---' : `${change.in}%`}
                </Tag>
              </Flex>
            </Flex>
          );
        }
      }
    ];
  }

  renderData() {
    const { config, regions } = this.state;

    const queryCurrent = getTrafficQuery(config.cloudType, config.region);
    const queryCompare = getComparisonQuery(config.cloudType, config.region, config.compare);

    return (
      <Flex flexDirection="column" px={1}>
        <Flex flexWrap="wrap">
          <Box pt={1} pr={1}>
            <Select
              options={regions}
              values={config.region}
              valueRenderer={regionRenderer}
              onChange={(option) => this.handleOptionChange({ region: option })}
              menuWidth={200}
            />
          </Box>

          <Box pt={1}>
            <Select
              options={COMPARE_OPTIONS}
              values={config.compare}
              valueRenderer={compareRenderer}
              onChange={(option) => this.handleOptionChange({ compare: option })}
              menuWidth={200}
            />
          </Box>
        </Flex>
        {regions && (
          <LightweightDataViewWrapper query={queryCurrent}>
            {({ loading: loadingCurrent, dataview, queryModel, results: resultsCurrent, bucket }) => (
              <LightweightDataViewWrapper query={queryCompare}>
                {({ loading: loadingCompare, results: resultsCompare }) => (
                  <Suspense loading={loadingCurrent} fallback={<Spinner pt={1} size={24} />}>
                    <Flex overflow="auto">
                      <ResultsTable
                        query={queryCurrent}
                        loading={loadingCurrent || loadingCompare}
                        dataview={dataview}
                        queryModel={queryModel}
                        queryResultsCollection={resultsCurrent}
                        bucket={bucket}
                        valueOverrides={this.getValueOverrides({ curr: resultsCurrent, compare: resultsCompare })}
                      />
                    </Flex>
                  </Suspense>
                )}
              </LightweightDataViewWrapper>
            )}
          </LightweightDataViewWrapper>
        )}
      </Flex>
    );
  }

  render() {
    const { canCustomize, onRemove, title } = this.props;
    const { config } = this.state;
    const tooltip =
      // eslint-disable-next-line max-len
      'This widget is useful for seeing increases and decreases in the various types of gateways present in your infrastructure. Unexpected increases of inbound traffic can lead to higher data transfer costs.';

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        menuOptions={<ViewInExplorerMenuItem query={getTrafficQuery(config.cloudType, config.region)} />}
        onRemove={onRemove}
        title={config.title || title}
        tooltip={tooltip}
        display="flex"
        flexDirection="column"
      >
        {this.renderData()}
      </WidgetFrame>
    );
  }
}

export default GatewayTrafficWidget;
