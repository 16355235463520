import { MAX_WINDOW_LENGTH_MIN } from 'shared/alerting/constants';

const alertFields = {
  'config.notifications.channels': {
    defaultValue: []
  },
  'config.activate': {},
  'config.activate.times': {
    label: 'Number of alerts',
    defaultValue: '3',
    rules: ['numeric', 'min:1']
  },
  'config.activate.timeWindow': {
    label: 'Alerts time window',
    defaultValue: '5',
    rules: ['numeric', 'alertsDurationGreaterThanPeriod', 'maxAlertExecutionTime']
  },
  'config.activate.timeUnit': {
    defaultValue: 'm',
    options: [
      {
        value: 'h',
        label: 'Hours'
      },
      {
        value: 'm',
        label: 'Minutes'
      }
    ],
    rules: ['alertsDurationGreaterThanPeriod']
  },
  'config.activate.gracePeriod': {
    label: 'Alerts grace period',
    defaultValue: '3',
    rules: ['numeric', 'min:1', `max:${MAX_WINDOW_LENGTH_MIN}`, 'gracePeriodAtLeastTripleTheTestFrequency']
  }
};

export { alertFields };
