import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import CdnLink from 'app/components/links/CdnLink';
import { Heading, Spinner, Text } from 'core/components';

@inject('$cdns')
@observer
class TopFiveTraffic extends Component {
  static defaultProps = {
    total: 0
  };

  getTrafficPct = (topFiveTraffic, totalTraffic) =>
    totalTraffic > 0 && topFiveTraffic > 0 ? Math.round((topFiveTraffic / totalTraffic) * 100) : 0;

  renderAltHeading = ({ topFiveCDNs, topFiveTraffic, totalTraffic }) => (
    <Text fontSize={14}>
      Top <Text fontWeight="bold">{topFiveCDNs.length}</Text> CDNs are{' '}
      <Text fontWeight="bold">{this.getTrafficPct(topFiveTraffic, totalTraffic)}%</Text> of all subscriber traffic:
    </Text>
  );

  renderHeading = ({ topFiveCDNs, topFiveTraffic, totalTraffic }) => (
    <>
      <Heading level={4} mb={1}>
        Top {topFiveCDNs.length} CDNs
      </Heading>

      <Heading level={1} fontSize={36} mb={0}>
        {this.getTrafficPct(topFiveTraffic, totalTraffic)}%
      </Heading>
      <Text fontSize={14} fontWeight="normal" whiteSpace="nowrap">
        of all subscriber traffic
      </Text>
    </>
  );

  render() {
    const { $cdns, lookback_seconds, aggregateType, filters, total, nonCdnTraffic, altLayout = false } = this.props;
    const totalTraffic = total + nonCdnTraffic;

    return (
      <LightweightDataViewWrapper query={$cdns.queries.getCdnQuery({ lookback_seconds, aggregateType, filters })}>
        {({ loading, results }) => {
          if (loading) {
            return <Spinner size={20} mt={3} />;
          }

          let topFiveTraffic = 0;
          const topFiveCDNs = [];

          results.nonOverlayRows.some((row, index) => {
            topFiveTraffic += row.get(aggregateType);
            topFiveCDNs.push(row.get('src_cdn'));
            return index === 4; // limit to 5 entries
          });

          return (
            <>
              {altLayout
                ? this.renderAltHeading({ topFiveCDNs, topFiveTraffic, totalTraffic })
                : this.renderHeading({ topFiveCDNs, topFiveTraffic, totalTraffic })}

              <Text as={altLayout ? 'span' : 'div'} fontSize={12} mt={altLayout ? 0 : 2} pl={altLayout ? 1 : 0}>
                {topFiveCDNs.map((cdn, index, items) => (
                  <span key={`cdn-${cdn}`}>
                    <CdnLink cdn={cdn} />
                    {index !== items.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </Text>
            </>
          );
        }}
      </LightweightDataViewWrapper>
    );
  }
}

export default TopFiveTraffic;
