/* eslint-disable no-useless-escape */
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const IPV6_REGEX = /^([0-9A-Fa-f]{0,4}:){2,7}([0-9A-Fa-f]{1,4}$|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4})$/;
const IPV4_REGEX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const URL_REGEX = /^(https?|ftp):\/\/([^\s/$.?#]+\.)+[^\s]+$/;
// Valid as per RFC 1123. Originally, RFC 952 specified that hostname segments could not start with a digit.
const HOSTNAME_REGEX =
  /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])(\.?)$/;
const HEX_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
const HTTP_HEADER_REGEX = /^(?<key>(authorization|x-[\w-]+)): ?(?<value>.*)$/i;
const REGEX_HTTP_PROTOCOL = /^https?:\/{2}[^\/]/i;

module.exports = {
  EMAIL_REGEX,
  IPV6_REGEX,
  IPV4_REGEX,
  URL_REGEX,
  HOSTNAME_REGEX,
  HEX_REGEX,
  HTTP_HEADER_REGEX,
  REGEX_HTTP_PROTOCOL
};
