import React, { Component } from 'react';
import { inject } from 'mobx-react';
import moment from 'moment';
import { pick } from 'lodash';

import { Box, Callout, Dialog, Icon, Text } from 'core/components';
import { Field, InputGroup, Select, TextArea } from 'core/form';
import FormComponent from 'core/form/components/FormComponent';
import FormActions from 'core/form/components/FormActions';
import ApplyParametricOptions from 'app/views/core/dashboards/ApplyParametricOptions';

import { exportContentMap, exportContentTypes, getExportOption, exportTypeSelectRenderer } from 'app/util/export';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$dashboards', '$devices.nonCloudCollection', '$sites', '$users')
@inject('$exports')
export default class EmailReportFormDialog extends Component {
  static defaultProps = {
    onClose: () => {}
  };

  state = {
    isParametric: false,
    selectedContent: undefined,
    showContentOptions: false
  };

  componentDidMount() {
    const { $dashboards, model } = this.props;
    const contentType = model?.get('share');
    const contentId = model?.get('content');

    const showContentOptions = !!exportContentMap[contentType]?.contentOptions;

    if (contentType === 'dashboard' && contentId) {
      const selectedContent = $dashboards.collection.get(contentId);
      if (selectedContent) {
        return this.setState({
          isParametric: selectedContent.get('parametric'),
          selectedContent,
          showContentOptions
        });
      }
    }

    if (contentType) {
      this.setState({
        showContentOptions
      });
    }
    return null;
  }

  handleContentSelect = (field, selectedContentId) => {
    const { $dashboards } = this.props;
    const { form } = field;
    const selectedContent = $dashboards.collection.get(selectedContentId);

    const isParametric = selectedContent && selectedContent.get('parametric');

    if (isParametric) {
      const parametric_fields = selectedContent.get('parametric_fields');
      form.setValue('parametric_fields', parametric_fields);
    } else {
      form.setValue('parametric_fields', []);
    }

    this.setState({ isParametric, selectedContent });
  };

  onEmailSubmit = (formValues) => {
    const {
      $devices,
      $exports,
      $sites,
      $users,
      dataview = {},
      path,
      fileName,
      passLocation,
      onClose,
      fetchExportProps = {},
      date,
      model
    } = this.props;
    const { isParametric, selectedContent } = this.state;
    const { hash, queryBuckets = {} } = dataview;
    const { selectedQuery } = queryBuckets;
    const time_format = selectedQuery ? selectedQuery.get('time_format') : '';
    let urlParams = time_format === 'Local' ? { time_format: moment().utcOffset() } : null;
    const contentType = model?.get('share');
    if (contentType === 'dashboard') {
      urlParams = {
        ...urlParams,
        ...pick(selectedContent.get('query'), [
          'all_devices',
          'device_labels',
          'device_name',
          'device_sites',
          'device_types',
          'filters',
          'from_to_lookback',
          'lookback_seconds',
          'starting_time',
          'ending_time',
          'time_format'
        ])
      };
      const { parametric_fields } = formValues;
      if (isParametric && parametric_fields && parametric_fields.length) {
        urlParams.parametric_fields = parametric_fields;
      }
      fetchExportProps.path = `/api/ui/export/dashboards/${model.get('content')}/email`;
    } else if (contentType === 'users') {
      urlParams = {
        query: window.location.search,
        data: { data: $users.collection.toJS() }
      };
    } else if (contentType === 'sites') {
      urlParams = {
        query: window.location.search,
        data: { data: $sites.collection.toJS() }
      };
    } else if (contentType === 'devices') {
      urlParams = {
        query: window.location.search,
        data: { data: $devices.nonCloudCollection.toJS() }
      };
    }

    if (fetchExportProps?.urlParams) {
      fetchExportProps.urlParams = {
        ...urlParams,
        ...fetchExportProps.urlParams
      };
    }
    $exports.fetchExport({
      path: path || '/api/ui/export/email',
      fileName: fileName || `export-${hash}`,
      urlParams,
      date,
      emailParams: formValues,
      passLocation,
      type: formValues?.type || 'pdf',
      viewType: 'explorer',
      viewId: hash,
      ...fetchExportProps
    });
    onClose();
  };

  handleContentTypeSelect = (field, content_type) => {
    const { model } = this.props;
    const { form } = field;
    form.setValue('content', null);
    form.setValue('parametric_fields', []);
    model.set({ share: content_type, content: null });
    this.setState({
      showContentOptions: !!exportContentMap[content_type]?.contentOptions,
      isParametric: false,
      selectedContent: undefined
    });
  };

  options = {
    name: 'EmailReport',
    showPristineErrors: false
  };

  fields = {
    recipients: {
      label: 'Recipients',
      placeholder: 'Enter a comma separated list of internal or external email addresses to send a report to',
      rules: 'required|commaSeparatedEmails'
    },
    share: {
      label: 'Share',
      placeholder: 'Select Content Type'
    },
    content: {
      label: 'Content',
      placeholder: 'Select Content',
      rules: [
        'required_if:share,dashboard',
        'required_if:share,savedview',
        'required_if:share,capacityplans',
        'required_if:share,costproviders',
        'required_if:share,costconnectivitytypes',
        'required_if:share,costsites',
        'required_if:share,costsitemarkets'
      ]
    },
    type: {
      label: 'Report File Type',
      placeholder: 'Select Report File Type',
      rules: 'required',
      defaultValue: 'pdf'
    },
    title: {
      label: 'Subject',
      placeholder: 'Capacity Planning Summary Report',
      rules: 'required|htmlSanitary'
    },
    message: {
      label: 'Message',
      placeholder: 'Enter a message that will be emailed to your recipients'
    },

    parametric_fields: {
      isComplexArray: true
    },
    'parametric_fields[].type': {},
    'parametric_fields[].label': {},
    'parametric_fields[].question': {},
    'parametric_fields[].value': {
      label: 'Filter Value'
    }
  };

  defaultExportOptions = [getExportOption('pdf', 'Visual Report', '.pdf', 'document')];

  render() {
    const { model, onClose } = this.props;
    const { isParametric, selectedContent, showContentOptions } = this.state;
    const contentType = model && model.get('share');
    const shareOptions = exportContentTypes.filter(({ value }) =>
      exportContentMap[contentType]?.associatedContentTypes.includes(value)
    );

    return (
      <FormComponent
        fields={this.fields}
        options={this.options}
        model={model}
        onSubmit={(form, values) => this.onEmailSubmit(values)}
        large
      >
        {(formProps) => (
          <>
            <Dialog.Body>
              <Text as="div" mb={1}>
                Share this report as an email attachment.
              </Text>
              <Field name="title">
                <InputGroup />
              </Field>
              {(!!shareOptions?.length || showContentOptions) && (
                <Box mt={showContentOptions ? 3 : 0} mb={showContentOptions ? 3 : 0}>
                  {!!shareOptions?.length && (
                    <Field name="share" options={shareOptions} onChange={this.handleContentTypeSelect}>
                      <Select fill />
                    </Field>
                  )}
                  {showContentOptions && (
                    <Field
                      label={`Selected ${exportContentMap[contentType]?.selectLabel || 'Content'}`}
                      key="content"
                      name="content"
                      options={exportContentMap[contentType]?.contentOptions?.()}
                      onChange={this.handleContentSelect}
                    >
                      <Select fill showFilter multi={exportContentMap[contentType]?.isMultiSelectable} />
                    </Field>
                  )}
                </Box>
              )}
              {isParametric && selectedContent && (
                <ApplyParametricOptions dashboard={selectedContent}>
                  {({ inputField, question }) => (
                    <Callout icon={null} intent="primary" mb={2}>
                      <Text as="h6" fontSize={12} mt={0} mb="12px" textTransform="uppercase" muted>
                        <Box>
                          <Icon icon="chat" color="primary" mr={1} iconSize={16} />
                          <Text color="primary">{question}</Text>
                        </Box>
                      </Text>

                      {React.cloneElement(inputField, { rules: ['required'] })}
                    </Callout>
                  )}
                </ApplyParametricOptions>
              )}
              <Field name="type" options={exportContentMap[contentType]?.exportOptions || this.defaultExportOptions}>
                <Select style={{ width: 290 }} valueRenderer={exportTypeSelectRenderer} width={290} fill />
              </Field>
              <Field name="recipients">
                <TextArea fill />
              </Field>
              <Field name="message">
                <TextArea width="100%" rows={3} />
              </Field>
            </Dialog.Body>
            <Dialog.Footer>
              <FormActions
                entityName={this.options.name}
                submitButtonText="Share"
                submitButtonProps={{
                  tracker: `share-email-${formProps.form.getValue('share')}-${formProps.form.getValue('type')}`
                }}
                onCancel={onClose}
                {...formProps}
                large={false}
              />
            </Dialog.Footer>
          </>
        )}
      </FormComponent>
    );
  }
}
