import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { cloneDeep } from 'lodash';

import { Box, Button, Card } from 'core/components';

import ResultsTableDataView from '../resultsTable/ResultsTableDataView';
import { applyFiltersToAggFilterQuery, applyFilterGroupsToAggFilterQuery } from '../filters/simple/simpleFilterUtils';

@inject('$app')
class TabResultsTable extends Component {
  static defaultProps = {
    useCard: true
  };

  state = {
    limit: 15,
    loading: true,
    query: null
  };

  static getDerivedStateFromProps(props, state) {
    const { query, queryOverrides, simpleFilters, simpleFilterGroups, $app } = props;
    const overriddenQuery = cloneDeep(query);
    Object.assign(overriddenQuery, queryOverrides);

    if (simpleFilters) {
      applyFiltersToAggFilterQuery(overriddenQuery, simpleFilters);
    }

    if (simpleFilterGroups) {
      applyFilterGroupsToAggFilterQuery(overriddenQuery, simpleFilterGroups);
    }

    return {
      query: overriddenQuery,
      depth: query.depth || 99,
      limit: $app.isExport ? undefined : state.limit
    };
  }

  handleShowMore = () => {
    this.setState({ limit: undefined });
  };

  handleQueryComplete = ({ loading, results }) => {
    this.setState({ loading, results });
  };

  get footerActions() {
    const { depth, limit, loading, results } = this.state;

    if (!limit || loading || results.size <= limit) {
      return null;
    }

    return (
      <Box mr={2}>
        <Button onClick={this.handleShowMore} small minimal intent="primary">
          Show Top {depth + 1} Results
        </Button>
      </Box>
    );
  }

  render() {
    const { $app, title, id, useCard, ...tableProps } = this.props;
    const { limit, query } = this.state;

    const table = (
      <ResultsTableDataView
        showSparklines={false}
        showCombinedTotalColumn
        showActionsMenu={!$app.isSubtenant}
        {...tableProps}
        limit={limit}
        query={query}
        footerActions={this.footerActions}
        onQueryComplete={this.handleQueryComplete}
        hashEnabled
      />
    );

    if (useCard) {
      return <Card>{table}</Card>;
    }

    return table;
  }
}

export default TabResultsTable;
