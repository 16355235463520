import React from 'react';
import { observer } from 'mobx-react';
import MetricsView from 'app/views/synthetics/components/agentResults/MetricsView';
import LoadingPanel from './LoadingPanel';

@observer
export default class MetricsPanel extends React.Component {
  render() {
    const { test, agentResults, targetResults, trendTargetResults, agent_id, target, loading, onChangeResultTimeMs } =
      this.props;
    const { timelineBounds, resultTimeMs, loadingTrendDetailResults } = test.agentResults;
    const { allowFlowQuery } = agentResults;
    const {
      displayFlowQuery,
      pingHealthTs,
      dnsHealthTs,
      dnsHealthTsResult,
      httpHealthTs,
      dnsHealthAggregates,
      httpHealthAggregates,
      pingHealthAggregates,
      trafficFlowQuery,
      throughputHealthTs,
      throughputHealthAggregates
    } = targetResults;
    // we classify this panel has having results by loading state because as trend results are loaded we don't want to flash a blanket "No Results" empty state before showing actual results
    // target results are hydrated by results from AgentResultsState so check both
    const hasResults = loading || (test.agentResults.hasResults && targetResults.hasResults);

    return (
      <LoadingPanel
        loading={!loadingTrendDetailResults && loading}
        test={test}
        testResults={test.results}
        empty={!hasResults}
      >
        <MetricsView
          trendResults={trendTargetResults}
          test={test}
          agent_id={agent_id}
          target={target}
          allowFlowQuery={allowFlowQuery}
          displayFlowQuery={displayFlowQuery}
          pingHealthTs={pingHealthTs}
          dnsHealthTs={dnsHealthTs}
          dnsHealthTsResult={dnsHealthTsResult}
          httpHealthTs={httpHealthTs}
          dnsHealthAggregates={dnsHealthAggregates}
          httpHealthAggregates={httpHealthAggregates}
          pingHealthAggregates={pingHealthAggregates}
          throughputHealthTs={throughputHealthTs}
          throughputHealthAggregates={throughputHealthAggregates}
          trafficFlowQuery={trafficFlowQuery}
          resultTimeMs={resultTimeMs}
          timelineBounds={timelineBounds}
          onHover={onChangeResultTimeMs}
        />
      </LoadingPanel>
    );
  }
}
