import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Card, Flex, FlexColumn, Heading, Spinner, Suspense } from 'core/components';
import HealthStatusButton from './HealthStatusButton';

@inject('$syn')
@observer
class TestStatusSummary extends Component {
  renderContent() {
    const { $syn, loading, isWidgetView } = this.props;
    const hasTests = $syn.tests.size > 0;

    return (
      <Flex alignItems="center" gap="12px">
        {!isWidgetView && (
          <Heading level="4" mb={0} flex="20%">
            Test Status Summary
          </Heading>
        )}
        <Suspense
          loading={loading}
          fallback={<Spinner intent="primary" mr={isWidgetView ? '0' : '26%'} my={1} size={36} />}
        >
          {hasTests && (
            <Flex flexWrap="wrap" alignItems="center" justifyContent="space-between" flex={1} mb={0} gap="12px">
              <HealthStatusButton
                value={$syn.tests.testHealthBreakdown.critical ?? 0}
                label="Critical"
                intent="danger"
                flex={1}
              />
              <HealthStatusButton
                value={$syn.tests.testHealthBreakdown.warning ?? 0}
                label="Warning"
                intent="warning"
                flex={1}
              />
              <HealthStatusButton
                value={$syn.tests.testHealthBreakdown.healthy ?? 0}
                label="Healthy"
                intent="success"
                flex={1}
              />
              <HealthStatusButton
                value={$syn.tests.testHealthBreakdown.failing ?? 0}
                label="Failing"
                intent="muted"
                flex={1}
              />
              <HealthStatusButton
                value={$syn.tests.testHealthBreakdown.paused ?? 0}
                label="Paused"
                intent="muted"
                flex={1}
              />
              <HealthStatusButton
                value={$syn.tests.testHealthBreakdown.pending ?? 0}
                label="Pending"
                intent="muted"
                flex={1}
              />
            </Flex>
          )}
        </Suspense>
      </Flex>
    );
  }

  render() {
    const { $syn, loading, isWidgetView, ...rest } = this.props;

    return isWidgetView ? (
      <FlexColumn p={2} height="100%" justifyContent="center">
        {this.renderContent()}
      </FlexColumn>
    ) : (
      <Box {...rest} className="avoid-break">
        <Card p={2} pb="12px">
          {this.renderContent()}
        </Card>
      </Box>
    );
  }
}

export default TestStatusSummary;
