import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ReconQueryWidget from './ReconQueryWidget';

const defaultWidgetTitle = 'Devices with Highest CPU';

const QUERY = {
  use_kmetrics: true,
  show_overlay: false,
  show_total_overlay: false,
  kmetrics: {
    measurement: '/system/cpus',
    dimensions: ['device_name'],
    metrics: [
      {
        name: 'total/instant',
        type: 'gauge'
      }
    ],
    range: {
      lookback: 'PT3600S'
    },
    merge: {
      dimensions: ['cpu_index'],
      aggregate: 'avg'
    },
    window: {
      size: 0,
      fn: {
        'total/instant': 'avg'
      }
    },
    rollups: {
      'last_total/instant': {
        metric: 'total/instant',
        aggregate: 'last'
      }
    },
    sort: [
      {
        name: 'last_total/instant',
        direction: 'desc'
      }
    ],
    limit: 10,
    includeTimeseries: 10,
    viz: {
      type: 'table',
      rollup: 'last_total/instant',
      metric: 'total/instant'
    }
  }
};

@observer
class ReconCPUWidget extends Component {
  static defaultProps = {
    config: {
      query: QUERY
    }
  };

  render() {
    return (
      <ReconQueryWidget
        {...this.props}
        defaultWidgetTitle={defaultWidgetTitle}
        showDefaultOptions={false}
        hideTotalRow
        showSparklines
      />
    );
  }
}

export default ReconCPUWidget;
