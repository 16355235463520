import { Position } from '@blueprintjs/core';
import React from 'react';
import { Box, Button, Menu, MenuItem, Popover } from 'core/components';
import Validator from 'validatorjs';
import { parseDuration } from '@kentik/ui-shared/nms/deviceMonitoringTemplates';
import InputGroup from './InputGroup';

const unitLabels = { s: 'seconds', m: 'minutes', h: 'hours' };

/**
 * takes a string field with a duration like `5m`, `10s`, `1h`, etc. and turns it into a number input and unit select
 */
export default function Duration({ field, disabled, small, large, onChange, units = ['s', 'm', 'h'] }) {
  const value = field.value || '';
  const { number = '', unit = units[0] } = value.match(/(?<number>\d*(\.\d*)?)(?<unit>[a-z])/)?.groups || {};

  const handleNumberChange = (event) => {
    if (/^\d*(\.\d*)?$/.test(event.target.value)) {
      onChange(`${event.target.value}${unit}`);
    }
  };

  return (
    <Box width={135}>
      <InputGroup
        onChange={handleNumberChange}
        value={number}
        rightElement={
          <Popover
            modifiers={{ offset: { enabled: true, offset: '0,2px' } }}
            content={
              <Menu>
                {units.map((u) => (
                  <MenuItem key={u} onClick={() => onChange(`${number}${u}`)} text={unitLabels[u] || u} />
                ))}
              </Menu>
            }
            disabled={disabled}
            placement={Position.BOTTOM}
          >
            <Button disabled={disabled} minimal rightIcon="caret-down">
              {unitLabels[unit] || unit}
            </Button>
          </Popover>
        }
        small={small}
        large={large}
      />
    </Box>
  );
}

Validator.register('validDuration', (val) => !Number.isNaN(parseDuration(val)), "Interval isn't valid");

Validator.register(
  'minDuration',
  (val, req) => {
    const duration = parseDuration(val);

    // ignore invalid
    if (Number.isNaN(duration)) {
      return true;
    }

    return duration >= parseDuration(req);
  },
  'Interval must be at least :minDuration'
);
