import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';

const OttProviderLink = ({ $app, provider, children, ...rest }) => {
  if ($app.isSubtenant) {
    return children || provider;
  }

  return (
    <Link to={`/v4/service-provider/ott/provider/${encodeURIComponent(provider)}`} {...rest}>
      {children || provider}
    </Link>
  );
};

export default inject('$app')(OttProviderLink);
