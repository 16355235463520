import React from 'react';

import { Box, Flex, Icon } from 'core/components';
import AlarmValue from './AlarmValue';
import AlarmValueLabel from './AlarmValueLabel';

const AlertingSidebarItem = ({ icon, label, value, className, children, iconProps, valueProps, ...props }) => (
  <Flex className={className} {...props}>
    {icon ? (
      <Box width={26}>
        {typeof icon === 'string' || typeof icon === 'function' ? (
          <Icon icon={icon} iconSize={18} color="muted" {...iconProps} />
        ) : (
          icon
        )}
      </Box>
    ) : null}
    <Box flex={1}>
      <AlarmValueLabel mb="4px" small>
        {label}
      </AlarmValueLabel>
      {value ? <AlarmValue small value={value} {...valueProps} /> : children}
    </Box>
  </Flex>
);

export default AlertingSidebarItem;
