import React from 'react';
import styled from 'styled-components';
import { space, width, borderRadius } from 'styled-system';
import { ProgressBar as BlueprintProgressBar, Classes } from '@blueprintjs/core';

const ProgressBarWrap = styled(BlueprintProgressBar)`
  ${space};
  ${width};
  ${borderRadius};

  &.${Classes.PROGRESS_BAR} {
    height: ${(props) => `${props.height || '8px'}`};
  }

  &.${Classes.INTENT_PRIMARY} {
    .${Classes.PROGRESS_METER} {
      background-color: ${(props) => props.theme.colors.primary};
      border-radius: ${(props) => props.borderRadius};
    }
  }
`;

function ProgressBar(props) {
  return <ProgressBarWrap {...props} />;
}

export default ProgressBar;
