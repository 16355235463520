import React, { Component } from 'react';
import { ThemedImage } from 'core/components';

import aiBadgeSvg from 'app/assets/kentik-ai-badge.svg';
import aiBadgeSvgDark from 'app/assets/kentik-ai-badge-dark.svg';
import aiBadgeSmallSvg from 'app/assets/kentik-ai-badge-small.svg';
import { observer } from 'mobx-react';

@observer
class KentikAiBadge extends Component {
  static defaultProps = {
    small: false
  };

  render() {
    const { small } = this.props;

    if (small) {
      return <ThemedImage src={aiBadgeSmallSvg} darkSrc={aiBadgeSmallSvg} width={16} height={16} />;
    }

    return <ThemedImage src={aiBadgeSvg} darkSrc={aiBadgeSvgDark} width={87} height={29} />;
  }
}

export default KentikAiBadge;
