import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Box, Link, Text } from 'core/components';
import { Field, InputGroup } from 'core/form';

@inject('$cloudExportWizard')
@observer
class GcpExportConfigFields extends Component {
  renderFlowLogsCopy = () => (
    <>
      <Text as="p" muted>
        Enable VPC flow logs in your account, and set the VPC to export the log to a single “Cloud Pub/Sub topic.” Then
        set the appropriate roles with necessary permissions to permit Kentik to access metadata from the Google Cloud
        account. Finally, enter the project ID and subscription here.
        <br />
        <Link to="https://kb.kentik.com/v0/Bd07.htm#Bd07-Enable_VPC_Flow_Logs" blank>
          Learn more about enabling VPC flow logs in GCP.
        </Link>
        <br />
        <Link to="https://kb.kentik.com/v0/Bd07.htm#Bd07-Set_Permissions" blank>
          Learn more about setting permissions in GCP.
        </Link>
      </Text>
    </>
  );

  // TODO: When KB article about cloud run logs exists, update link here
  renderCloudRunCopy = () => (
    <>
      <Text as="p" muted>
        Create a new log router sink to export the log to a single &quot;Cloud Pub/Sub topic.&quot;
        <br />
        <Link to="https://kb.kentik.com/v0/Bd07.htm" blank>
          Learn more about enabling Cloud Run logging in GCP.
        </Link>
      </Text>
    </>
  );

  renderMetadataCopy = () => (
    <>
      <Text as="p" muted>
        Set the appropriate roles with necessary permissions to permit Kentik to access metadata from the Google Cloud
        account. Then enter the project name here.
        <br />
        <Link to="https://kb.kentik.com/v0/Bd07.htm#Bd07-Set_Permissions" blank>
          Learn more about setting permissions in GCP.
        </Link>
      </Text>
    </>
  );

  render() {
    const { $cloudExportWizard } = this.props;
    const { isMetadataOnlyEnabled, isFlowLogCollectionEnabled, isGcpCloudRunLogEnabled, isMetricsCollectionEnabled } =
      $cloudExportWizard;
    const showSubscriptionField = isFlowLogCollectionEnabled || isGcpCloudRunLogEnabled;

    const showMetadataOnlyCopy =
      isMetadataOnlyEnabled || (isMetricsCollectionEnabled && !isFlowLogCollectionEnabled && !isGcpCloudRunLogEnabled);

    return (
      <>
        {showMetadataOnlyCopy && this.renderMetadataCopy()}
        {isFlowLogCollectionEnabled && this.renderFlowLogsCopy()}
        {isGcpCloudRunLogEnabled && this.renderCloudRunCopy()}
        {/* test id for CloudExportConfigWizard jest tests */}
        <Box mt={1} mb={2} data-testid="GcpExportConfigFields">
          <Field large name="properties.gce_project" placeholder="my-gcp-project" maxWidth={360}>
            <InputGroup />
          </Field>
        </Box>
        <Box>
          {showSubscriptionField && (
            <Field large name="properties.gce_subscription" placeholder="my-gcp-subscription-id" maxWidth={360}>
              <InputGroup />
            </Field>
          )}
        </Box>
      </>
    );
  }
}

export default GcpExportConfigFields;
