import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { Box, Highcharts, Text, Spinner, Suspense } from 'core/components';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';

function generateConfig(cloudData) {
  return {
    chart: {
      animation: true,
      type: 'bar'
    },
    title: {
      text: ''
    },

    xAxis: {
      type: 'category'
    },
    yAxis: {
      title: {
        text: 'Flows per second'
      },
      gridLineColor: '#DEDEDE',
      gridLineWidth: 1,
      labels: {
        padding: 12
      }
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        animatiom: true
      }
    },
    legend: { enabled: false },
    series: cloudData
  };
}

@withTheme
@inject('$colors', '$clouds')
@observer
class CloudFlowWidget extends Component {
  chart;

  componentDidMount() {
    const { $clouds } = this.props;
    $clouds.loadClouds();
  }

  componentDidUpdate(prevProps) {
    const { height, deckWidth } = this.props;
    if (prevProps.height !== height || prevProps.width !== deckWidth) {
      if (this.debouncedResize) {
        clearTimeout(this.debouncedResize);
      }

      this.debouncedResize = setTimeout(() => this.reflow(), 250);
    }
  }

  chartCallback = (chart) => {
    this.chart = chart;
    this.el = chart.container;
  };

  reflow = () => {
    if (this.chart && this.chart.hasRendered && this.el) {
      this.chart.setSize(this.el.offsetWidth, this.el.offsetHeight);
      this.chart.reflow();
    }
  };

  filterClouds = (cloudData) => {
    const { config } = this.props;
    if (!config?.cloudFilter) {
      return cloudData;
    }
    return cloudData.filter((cloud) => cloud?.data?.[0]?.name === config.cloudFilter);
  };

  render() {
    const { canCustomize, onRemove, $clouds, $colors, height } = this.props;
    const title = 'Public Cloud Flow';
    const tooltip =
      'Understand how your flow logs translate to flows/second and view your total flow consumption in one glance.';

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        onRemove={onRemove}
        title={title}
        tooltip={tooltip}
        display="flex"
        flexDirection="column"
        p={1}
      >
        <Suspense
          loading={!$clouds.statsFetched}
          fallback={
            <Box pt={2}>
              <Spinner size={24} />
            </Box>
          }
        >
          {!$clouds.hasFlow && (
            <Text color="muted" as="div" flex={1}>
              You do not currently have any cloud flow.
            </Text>
          )}
          {$clouds.hasFlow && (
            <Box flex={1}>
              <Highcharts
                height={height - 57}
                width="100%"
                colors={$colors.chartColors}
                callback={this.chartCallback}
                options={generateConfig(this.filterClouds($clouds.cloudProviderChartData))}
              />
            </Box>
          )}
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default CloudFlowWidget;
