import React from 'react';
import { inject } from 'mobx-react';
import Link from 'core/components/Link';

const CloudDetailLink = ({ $app, cloud, detail, value, children, text, ...rest }) => {
  if ($app.isSubtenant) {
    return value;
  }

  return (
    <Link to={`/v4/core/quick-views/cloud/${cloud}/${detail}/${value}`} {...rest}>
      {children || text || value}
    </Link>
  );
};

export default inject('$app')(CloudDetailLink);
