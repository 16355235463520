import { observer, inject } from 'mobx-react';
import { withTheme } from 'styled-components';

import { formatDateTime } from 'core/util/dateUtils';
import { getStatusMessage, checkSeriesDataForFailure } from 'app/views/synthetics/utils/syntheticsUtils';
import BollingerBandChart from 'app/views/synthetics/components/BollingerBandChart';

import withTrendChart from './withTrendChart';

@withTrendChart
@withTheme
@inject('$app', '$dataviews')
@observer
class TransactionCompletionSeries extends BollingerBandChart {
  getColor(status) {
    const {
      theme: { colors }
    } = this.props;
    return status !== 0 ? colors.danger : colors.success;
  }

  renderData() {
    if (this.chart) {
      const { data, seriesName, xAxisMin, xAxisMax } = this.props;

      const averages = [];
      const zones = [];
      let currHealth;

      const offset = data.length > 1 ? (data[1].time - data[0].time) / 2 : 0;

      data.forEach((point) => {
        const { time, status, data: d } = point;
        let decodedStatusMessage = '';
        if (d && d.length > 0) {
          decodedStatusMessage = getStatusMessage(d[0].statusMessage, d[0].statusEncoding);
        }

        if (checkSeriesDataForFailure(point)) {
          averages.push({ time, x: time, y: null });
        } else {
          averages.push({
            time,
            x: time,
            y: status,
            custom: {
              status,
              statusMessage: decodedStatusMessage
            },
            marker: {
              enabled: true,
              states: {
                select: {
                  fillColor: this.getColor(status),
                  lineColor: 'rgba(255, 255, 255, 0.5)',
                  lineWidth: 5,
                  radius: 8
                }
              }
            }
          });
        }

        zones.push({
          value: time - offset * (currHealth === 0 ? 1.1 : 0.9), // offset to center the values, but give more room to non-healthy values
          color: this.getColor(currHealth)
        });
        currHealth = status;
      });

      zones.push({ color: this.getColor(currHealth), fillColor: this.getFillColor(currHealth) });

      const yAxisMax = averages.map((item) => item.y).reduce((a, b) => Math.max(a, b));

      this.setState({
        chartOptions: {
          tooltip: {
            pointFormatter() {
              const isFailing = this.custom.status !== 0;
              return `<div>
                <p style="margin: 0 0 5px 0;"><strong>${formatDateTime(this.x, 'MMM D, HH:mm')}</strong></p>
                <span style="color:${this.color}">●</span> <strong>${isFailing ? 'FAIL: ' : 'PASS'}</strong>${
                  isFailing ? `<span>${this.custom.statusMessage}</span>` : ''
                }
              </div>`;
            }
          },
          series: [{ step: 'center', name: seriesName, data: averages, zones }],
          yAxis: {
            min: 0,
            max: yAxisMax,
            offset: 20,
            reversed: true,
            allowDecimals: false,
            endOnTick: true,
            showLastLabel: true,
            labels: {
              formatter() {
                const label = this.axis.defaultLabelFormatter.call(this);
                if (this.isFirst || this.isLast) {
                  return `${label === '0' ? 'PASS' : 'FAIL'}`;
                }
                return null;
              }
            }
          },
          xAxis: {
            min: xAxisMin,
            max: xAxisMax
          }
        }
      });
    }
  }
}

export default TransactionCompletionSeries;
