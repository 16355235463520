import React, { Component } from 'react';
import { Text, Heading, Flex, Grid, Card } from 'core/components';
import { inject } from 'mobx-react';

import styled from 'styled-components';
import journeyIcon from './journeyIcon.svg';

const ExampleQueryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 8px;
  border: 1px solid transparent;
  transition: all 0.25s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.purpleBackground};
    border: ${({ theme }) => `1px solid ${theme.colors.purple}`};
  }
`;

@inject('$metrics')
export default class JourneyEmptyState extends Component {
  getExamplePrompts = () => {
    const { $metrics } = this.props;
    const NMS = 'nms';
    const FLOW = 'flow';
    const hasNms = $metrics.hasNmsDevices;
    const examplePrompts = [
      {
        prompt: 'Sites with the most packets per second yesterday afternoon',
        source: FLOW,
        pretty: (
          <Text key={0} muted>
            <Text fontWeight="bold" color="body">
              Sites with the most packets per second{' '}
            </Text>{' '}
            yesterday afternoon
          </Text>
        )
      },
      {
        prompt: 'Simple traffic profile for the last 14 days',
        source: FLOW,
        pretty: (
          <Text key={0} muted>
            <Text fontWeight="bold" color="body">
              Simple traffic profile
              <br />
            </Text>{' '}
            for the last 14 days
          </Text>
        )
      },
      {
        prompt: 'Traffic by device and destination port for the last 15 mins',
        source: FLOW,
        pretty: (
          <Text key={0} muted>
            <Text fontWeight="bold" color="body">
              Traffic by device and destination port{' '}
            </Text>{' '}
            for the last 15 mins
          </Text>
        )
      },
      {
        prompt: 'Show me top CPU usage by device for the last hour',
        source: NMS,
        pretty: (
          <Text key={1} muted>
            <Text fontWeight="bold" color="body">
              Show me top CPU usage by device
            </Text>{' '}
            for the last hour
          </Text>
        )
      },
      {
        prompt: 'Show interface utilization by device for the last 15 minutes',
        source: NMS,
        pretty: (
          <Text key={2} muted>
            <Text fontWeight="bold" color="body">
              Show interface utilization{' '}
            </Text>{' '}
            by device for last 15 minutes
          </Text>
        )
      },
      {
        prompt: 'Device uptime and available status with OS name, version and IP',
        source: NMS,
        pretty: (
          <Text key={2} muted>
            <Text fontWeight="bold" color="body">
              Device uptime and available status with OS name, version and IP
            </Text>
          </Text>
        )
      },
      {
        prompt: 'Source and Destination ASN as a Sankey diagram for the last Week',
        source: FLOW,
        pretty: (
          <Text key={3} muted>
            <Text fontWeight="bold" color="body">
              Source and Destination ASN as a Sankey diagram
            </Text>{' '}
            for the last week
          </Text>
        )
      },
      {
        prompt: 'Source city as a map for UDP over the last three hours',
        source: FLOW,
        pretty: (
          <Text key={5} muted>
            <Text fontWeight="bold" color="body">
              Source city as a map for UDP
            </Text>{' '}
            over the last 3 hours
          </Text>
        )
      },
      {
        prompt: 'Show me the top source CDNs last week',
        source: FLOW,
        pretty: (
          <Text key={5} muted>
            <Text fontWeight="bold" color="body">
              Show me the top source CDNs
            </Text>{' '}
            last week
          </Text>
        )
      }
    ];

    return examplePrompts.filter((prompt) => {
      const { source } = prompt;
      if (source === NMS && hasNms) {
        return true;
      }
      if (source === FLOW) {
        return true;
      }
      return false;
    });
  };

  render() {
    const { onSelectSuggestion } = this.props;

    return (
      <Flex
        flexDirection="column"
        flex={1}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        m="0px auto"
        maxWidth={560}
        gap={2}
      >
        <img src={journeyIcon} width={80} alt="journey-icon" />
        <Heading level={3}>Welcome to Journeys</Heading>
        <Text large muted>
          Elevate your network observability, health, performance, and troubleshooting workflows with a seamless
          chat-like conversational interface that understands your network.
        </Text>

        <Grid gridTemplateColumns="1fr 1fr 1fr" gridGap={2} gridRowGap={2} mt={40} width={750}>
          {this.getExamplePrompts().map((suggestion) => (
            <Card
              as={ExampleQueryWrapper}
              interactive
              key={suggestion.prompt}
              onClick={() => onSelectSuggestion(suggestion.prompt)}
            >
              {suggestion.pretty}
            </Card>
          ))}
        </Grid>
      </Flex>
    );
  }
}
