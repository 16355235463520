import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Spinner, Suspense, Text } from 'core/components';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import storeLoader from 'app/stores/storeLoader';
import ModuleLinkButton from '../../../landing/ModuleLinkButton';

@storeLoader('$alerting.policyCollection')
@inject('$mitigations')
@observer
class DdosWidget extends Component {
  state = {
    loadingMitigations: true,
    loadingAttacks: true
  };

  componentDidMount() {
    const { $mitigations, $alerting } = this.props;

    $alerting.collection
      .setServerFilter({
        lookback: 86400,
        applications: ['ddos'],
        states: ['ALARM_STATE_ACTIVE', 'ALARM_STATE_CLEAR'],
        limit: 100
      })
      .then(() => {
        this.setState({ loadingAttacks: false });
      });

    $mitigations.loadActiveMitigations().then(() => {
      this.setState({ loadingMitigations: false });
    });
  }

  renderData() {
    const { $alerting, $mitigations } = this.props;
    const title = 'DDoS Defense';
    const route = '/v4/protect/ddos';
    const configured = $alerting.isDdosConfigured;

    if (!configured) {
      return (
        <>
          <Text as="div" muted mb={2} flex={1}>
            You are not currently protecting your network.
          </Text>
          <ModuleLinkButton text={`Setup ${title}`} to={route} />
        </>
      );
    }

    return (
      <>
        <Box flex={1} mb={2}>
          <Box mb="4px">
            <Text fontWeight="bold">{$alerting.collection.activeAttacks}</Text> active attacks
          </Box>
          <Box mb="4px">
            <Text fontWeight="bold">{$mitigations.activeMitigationCollection.activeCount}</Text> active mitigations
          </Box>
          <Box mb="4px">
            <Text fontWeight="bold">
              {$alerting.collection.attacksWithinLast24Hours}
              {$alerting.collection.attacksWithinLast24Hours < $alerting.collection.totalCount ? '+' : ''}
            </Text>{' '}
            attacks within last 24 hours
          </Box>
        </Box>
        <ModuleLinkButton mt={2} text={title} to={route} />
      </>
    );
  }

  render() {
    const { canCustomize, onRemove, loading } = this.props;
    const { loadingMitigations, loadingAttacks } = this.state;

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        onRemove={onRemove}
        title="DDoS Defense"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Suspense
          loading={loading || loadingMitigations || loadingAttacks}
          fallback={
            <Box mt={2}>
              <Spinner size={24} />
            </Box>
          }
        >
          {this.renderData()}
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default DdosWidget;
