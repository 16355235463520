import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Breadcrumbs as BlueprintBreadcrumbs } from '@blueprintjs/core';

import queryString from 'query-string';

import Flex from './Flex';
import Text from './Text';
import Breadcrumb from './Breadcrumb';

function getHrefForBreadcrumb(route, params) {
  return route
    .split('/')
    .map((routePart) => {
      if (routePart.startsWith(':')) {
        return params[routePart.substring(1)];
      }
      return routePart;
    })
    .join('/');
}

function getItemsFromRoutes({ match, location, routes }) {
  return Object.keys(routes).reduce((acc, route) => {
    if (match.path.startsWith(route)) {
      let routeName = routes[route];
      if (typeof routeName === 'function') {
        routeName = routeName({ ...match.params, search: queryString.parse(location.search) });
      }
      acc.push({ href: getHrefForBreadcrumb(route, match.params), text: routeName });
    }

    return acc;
  }, []);
}

const renderBreadcrumb = (href, text, history) => {
  const onClick = (e) => {
    e.preventDefault();
    history.push(href);
  };

  return <Breadcrumb onClick={onClick}>{text}</Breadcrumb>;
};

const renderCurrentBreadcrumb = ({ text }) => (
  <Text fontWeight="bold" fontSize={16} as="span">
    {text}
  </Text>
);

function Breadcrumbs({ history, match, location, routes, items, model, height = 50 }) {
  return (
    <Flex height={height} alignItems="center">
      <BlueprintBreadcrumbs
        breadcrumbRenderer={({ href, text }) => renderBreadcrumb(href, text, history)}
        currentBreadcrumbRenderer={renderCurrentBreadcrumb}
        items={items || getItemsFromRoutes({ match, location, routes, model })}
      />
    </Flex>
  );
}

export default withRouter(observer(Breadcrumbs));
