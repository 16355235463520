import React from 'react';

import Flex from 'core/components/Flex';
import Text from 'core/components/Text';
import Icon from 'core/components/Icon';
import Swatch from './Swatch';

const AdminFilterItemRenderer = ({ color, iconColor, iconSize, icon, label, wrapLabel, round, dot, displayLabel }) => (
  <Flex alignItems={wrapLabel ? 'flex-start' : 'center'} overflow="hidden" gap="4px">
    {color && <Swatch mr={round || dot ? '2px' : 0} round={round} dot={dot} bg={color} />}
    {icon && <Icon mr="2px" icon={icon} color={iconColor} iconSize={iconSize} />}
    <Text as="div" ellipsis={!wrapLabel} title={label}>
      {displayLabel || label}
    </Text>
  </Flex>
);
export default AdminFilterItemRenderer;
