import React from 'react';
import { action } from 'mobx';
import $auth from 'app/stores/$auth';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { Icon } from 'core/components';

export function filterGroupContains(groups, filterFields) {
  return (
    Array.isArray(groups) &&
    groups.some((group) => {
      if (Array.isArray(group.filters) && group.filters.some((filter) => filterFields.includes(filter.filterField))) {
        return true;
      }

      return group.filterGroups ? filterGroupContains(group.filterGroups, filterFields) : false;
    })
  );
}

function getOffendingFilterFields(groups, filterFields) {
  if (Array.isArray(groups)) {
    return groups.reduce((acc, group) => {
      if (Array.isArray(group.filters)) {
        return acc.concat(
          group.filters
            .filter((filter) => filterFields.includes(filter.filterField))
            .map((filter) => filter.filterField)
        );
      }

      return acc.concat(group.filterGroups ? getOffendingFilterFields(group.filterGroups, filterFields) : []);
    }, []);
  }

  return [];
}
function filterExceptions(offendingFields, exceptions, constraintKey) {
  if (!exceptions || !exceptions.meets) {
    return offendingFields;
  }

  return offendingFields.filter((field) => !exceptions.constraints[constraintKey].includes(field));
}

function filterAllExceptions(offendingFields, exceptions, constraintKey) {
  if (Array.isArray(exceptions)) {
    let filteredFields = [];

    exceptions.forEach((exception) => {
      filteredFields = filterExceptions(filteredFields, exception, constraintKey);
    });

    return filteredFields;
  }

  return filterExceptions(offendingFields, exceptions, constraintKey);
}

export function queryContains(query, constraints, exceptions) {
  const {
    metric: queryMetrics,
    filters: queryFilters,
    aggregateTypes: queryAggregateTypes,
    viz_type: queryVizType
  } = query;
  const { metrics, filterFields, aggregateTypes, vizTypes } = constraints;

  if (vizTypes && vizTypes.includes(queryVizType)) {
    return true;
  }

  if (queryMetrics && metrics) {
    const offendingMetrics = filterAllExceptions(
      queryMetrics.filter((m) => metrics.includes(m)),
      exceptions,
      'metrics'
    );

    if (offendingMetrics.length > 0) {
      // console.info('failed dependency check due to metrics', offendingMetrics);
      return true;
    }
  }

  if (queryAggregateTypes && aggregateTypes) {
    const offendingAggTypes = filterAllExceptions(
      queryAggregateTypes.filter((aggType) => aggregateTypes.includes(aggType)),
      exceptions,
      'aggregateTypes'
    );

    if (offendingAggTypes.length > 0) {
      // console.info('failed dependency check due to aggregateTypes', offendingAggTypes);
      return true;
    }
  }

  if (queryFilters && filterFields) {
    const offendingFilters = filterAllExceptions(
      getOffendingFilterFields(queryFilters.filterGroups, filterFields),
      exceptions,
      'filterFields'
    );

    if (offendingFilters.length > 0) {
      // console.info('failed dependency check due to filters', offendingFilters);
      return true;
    }
  }

  return false;
}

export function dataviewContains(dataviews, constraints, exceptions) {
  return dataviews.some(
    (dataview) =>
      dataview.queryBuckets &&
      dataview.queryBuckets.some((bucket) =>
        bucket.queries.some((queryModel) => {
          const query = queryModel.get(['metric', 'filters', 'aggregateTypes', 'viz_type']);
          return queryContains(query, constraints, exceptions);
        })
      )
  );
}

class AbstractDependency {
  dataviews;

  formState;

  showConfigureButton = true;

  constructor(dataviews, formState, showConfigureButton) {
    this.dataviews = dataviews;
    this.formState = formState;
    this.showConfigureButton = showConfigureButton;
  }

  get constraints() {
    return { metrics: [], filterFields: [], aggregateTypes: [] };
  }

  get exceptions() {
    return { constraints: { metrics: [], filterFields: [], aggregateTypes: [] }, meets: true };
  }

  get formRequires() {
    return queryContains(this.formState.getValues(), this.constraints, this.exceptions);
  }

  get queryRequires() {
    return dataviewContains(this.dataviews, this.constraints, this.exceptions);
  }

  get requires() {
    return this.formState && this.formState.dirty ? this.formRequires : this.queryRequires;
  }

  get meets() {
    console.error('Dependency setup w/o a meets impl');
    return true;
  }

  get title() {
    return 'You have dependency check failures.';
  }

  get icon() {
    return <Icon icon={BsFillExclamationTriangleFill} />;
  }

  get intent() {
    return 'warning';
  }

  get message() {
    console.error('Dependency setup w/o a message impl');
    return <span />;
  }

  get showMessage() {
    return true;
  }

  get preventQuery() {
    return false;
  }

  @action
  validate() {
    const { meets, preventQuery } = this;

    if (preventQuery && this.dataviews.length && !$auth.isPresetCompany) {
      this.dataviews.forEach((dataview) => {
        // Check if it's actually changing because otherwise you can get render loops
        // TODO: fix how dependencies are fired
        if (dataview.preventQuery !== !meets) {
          dataview.preventQuery = !meets;
        }
      });
    }

    return meets;
  }
}

export default AbstractDependency;
