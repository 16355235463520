let lookups = {};
let tmpText = null;
let collapsePath = false;

const getTextSize = (text) => tmpText.text(text).node().getBBox();
const getASNColor = ({ info = {} }) => info.asn && (lookups.asns[info.asn.id] || {}).color;

const getASNStyle = (node) => {
  const { info } = node;
  const color = getASNColor(node) || '#F6CEB0';
  const textSize = getTextSize(collapsePath ? info.name : info.title);
  const adjustment = node.isVp || node.pruned ? 40 : 20;

  return {
    color,
    size: collapsePath ? [textSize.width + adjustment, 26] : [190, 48],
    textSize
  };
};

const getPrefixStyle = (node) => {
  const textSize = getTextSize(node.id);

  return {
    color: getASNColor(node) || '#A1E0ED',
    size: [textSize.width + 32, 30],
    textSize
  };
};

export const buildNodeStyle = (node) => {
  const { type, id: text } = node;
  let style;

  if (type === 'asn') {
    style = getASNStyle(node);
  }

  if (type === 'virtual') {
    style = {
      size: [15, 5]
    };
  }

  if (type === 'prefix') {
    style = getPrefixStyle(node);
  }

  const { size, textSize, ...rest } = style;
  const [width, height] = size;

  node.size = size;
  node.text = text;
  node.style = { ...rest };
  node.width = textSize && textSize.width > width ? textSize.width : width;
  node.height = height;
};

export const setOptions = (options) => {
  lookups = options.lookups;
  tmpText = options.tmpText;
  collapsePath = options.collapsePath;
};
