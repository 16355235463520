import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PopoverInteractionKind } from '@blueprintjs/core';
import { Box, Flex, Icon, Text, Popover } from 'core/components';

@inject('$syn')
@observer
export default class HeaderCellPopover extends Component {
  get agentModel() {
    const { $syn, agentDetails } = this.props;
    const agentId = agentDetails?.agent_id;

    if (agentId) {
      return $syn.agents.get(agentId);
    }

    return null;
  }

  get agentContent() {
    const { type, agentDetails } = this.props;
    const { icon, locationInfo, asFormatted, status } = this.agentModel;
    let label = 'row';
    let subLabel = 'source';

    if (type === 'col') {
      label = 'column';
      subLabel = 'target';
    }

    return (
      <Box p={2}>
        <Text as="div" mb={1} textTransform="uppercase" fontWeight="bold" small muted>{`${label} ${
          agentDetails.index + 1
        } (${subLabel})`}</Text>
        <Flex gap={1}>
          <Icon icon={icon} />
          <Box>
            <Flex gap={1}>
              <Text>{locationInfo}</Text>
              {status?.icon && <Icon icon={status.icon} />}
            </Flex>
            <Text>{asFormatted}</Text>
          </Box>
        </Flex>
      </Box>
    );
  }

  render() {
    if (this.agentModel) {
      const { type, boxProperties, agentDetails, ...popoverProps } = this.props;

      return (
        <Popover
          minimal={false}
          interactionKind={PopoverInteractionKind.HOVER}
          position="right-top"
          hoverOpenDelay={100}
          targetProps={{
            style: {
              cursor: 'pointer',
              position: 'absolute',
              ...boxProperties
            }
          }}
          content={this.agentContent}
          {...popoverProps}
        >
          <div />
        </Popover>
      );
    }

    return null;
  }
}
