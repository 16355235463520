import { css } from 'styled-components';
import { style } from 'styled-system';

export const textTransform = style({
  prop: 'textTransform',
  cssProperty: 'textTransform'
});

export const textDecoration = style({
  prop: 'textDecoration',
  cssProperty: 'textDecoration'
});

export const whiteSpace = style({
  prop: 'whiteSpace',
  cssProperty: 'whiteSpace'
});

export const textOverflow = style({
  prop: 'textOverflow',
  cssProperty: 'textOverflow'
});

const typography = css`
  ${textTransform};
  ${textDecoration};
  ${whiteSpace};
  ${textOverflow};
`;

export default typography;
