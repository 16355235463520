import { escape, unescape } from 'lodash';

const notesFields = {
  'config.notes': {
    label: 'Notes',
    defaultValue: '',
    rules: ['string', 'no_html', 'max:300'],
    messages: {
      no_html: 'Notes cannot contain HTML.'
    },
    transform: {
      in: (value) => unescape(value),
      out: (value) => escape(value)
    }
  }
};

export { notesFields };
