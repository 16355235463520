import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';

import Flex from 'core/components/Flex';
import Icon from 'core/components/Icon';
import Tag from 'core/components/Tag';
import Search from 'core/components/table/Search';
import { Text } from 'core/components';

@withTheme
@observer
class AdminTableSearch extends Component {
  getFilterTag = (filter, filterValue) => {
    const label = filter.label || filter.type || '';
    let value = filterValue;

    if (!Array.isArray(value)) {
      value = [value];
    }

    if (filter.options) {
      value = value.map((fv) => {
        const option = filter.options.find((opt) => opt.value === fv);
        if (option?.matcher && typeof option.matcher === 'string') {
          return option.matcher;
        }
        if (option?.label) {
          return option.label;
        }
        return fv;
      });
    }

    if (label && value) {
      return (
        <>
          {label}
          <Text>: </Text>
          {value.map((fv, idx) => (
            <Fragment key={fv}>
              <Text mr="2px">{fv}</Text>
              {idx < value.length - 1 && <Text>, </Text>}
            </Fragment>
          ))}
        </>
      );
    }

    return null;
  };

  getFilterTags = () => {
    const { filterFields, filterValues, excludeFilters, onRemoveFilter, theme } = this.props;

    const filterFieldsHash = filterFields
      .filter((field) => !excludeFilters.includes(field.name))
      .reduce((acc, field) => {
        acc[field.name] = field;
        return acc;
      }, {});

    if (!filterFields || !filterValues) {
      return null;
    }

    return Object.keys(filterValues)
      .filter((key) => {
        const hasLabel = !!filterFieldsHash[key]?.label;
        const value = filterValues[key];

        if (Array.isArray(value)) {
          return hasLabel && value.length > 0;
        }
        return hasLabel && (Number.isFinite(value) || !!value);
      })
      .map((key) => (
        <Tag key={key} onRemove={() => onRemoveFilter(key)} round minimal={theme.name === 'light'}>
          {this.getFilterTag(filterFieldsHash[key], filterValues[key])}
        </Tag>
      ));
  };

  render() {
    const { collection, onSearch, searchTerm } = this.props;

    return (
      <Search
        collection={collection}
        searchTerm={searchTerm}
        onChange={onSearch}
        placeholder="Search..."
        inputProps={{
          className: 'round chips',
          leftElement: (
            <Flex alignItems="center" maxWidth="1180px">
              <Icon icon="search" mr="4px" />
              {this.getFilterTags()}
            </Flex>
          )
        }}
        flex={1}
        mx="3px" // so that the focus css doesn't overflow
      />
    );
  }
}

export default AdminTableSearch;
