import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FiShield } from 'react-icons/fi';

import Box from 'core/components/Box';
import Text from 'core/components/Text';
import ModuleLinkButton from 'app/components/landing/ModuleLinkButton';
import SidebarCard from 'app/components/landing/SidebarCard';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$alerting.policyCollection')
@inject('$mitigations')
@observer
class DdosCard extends Component {
  state = {
    loadingMitigations: true,
    loadingAttacks: true
  };

  componentDidMount() {
    const { $mitigations, $alerting } = this.props;

    $alerting.collection
      .setServerFilter({
        lookback: 86400,
        applications: ['ddos'],
        states: ['ALARM_STATE_ACTIVE', 'ALARM_STATE_CLEAR'],
        limit: 100
      })
      .then(() => {
        this.setState({ loadingAttacks: false });
      });

    $mitigations.loadActiveMitigations().then(() => {
      this.setState({ loadingMitigations: false });
    });
  }

  render() {
    const { $alerting, $mitigations, loading } = this.props;
    const { loadingMitigations, loadingAttacks } = this.state;

    return (
      <SidebarCard
        loading={loading || loadingMitigations || loadingAttacks}
        icon={FiShield}
        title="DDoS Defense"
        to="/v4/protect/ddos"
        isConfigured={$alerting.isDdosConfigured}
        notConfiguredText="You are not currently protecting your network"
        mb={2}
      >
        <Box mb="4px">
          <Text fontWeight="bold">{$alerting.collection.activeAttacks}</Text> active attacks
        </Box>
        <Box mb="4px">
          <Text fontWeight="bold">{$mitigations.activeMitigationCollection.activeCount}</Text> active mitigations
        </Box>
        <Box mb="4px">
          <Text fontWeight="bold">
            {$alerting.collection.attacksWithinLast24Hours}
            {$alerting.collection.attacksWithinLast24Hours < $alerting.collection.totalCount ? '+' : ''}
          </Text>{' '}
          attacks within last 24 hours
        </Box>
        <ModuleLinkButton mt={1} text="DDoS Defense" to="/v4/protect/ddos" />
      </SidebarCard>
    );
  }
}

export default DdosCard;
