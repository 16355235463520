import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

@Form(aggregateConfig('IPAggregate'))
class IPAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="IP Addresses"
        resultsKey="IP Address"
        insightsQuery="fetchIpInsights"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'IP Address',
          aggregateFilters: [
            { metric: ['IP_src', 'IP_dst'] },
            { metric: ['IP_src'] },
            { metric: ['IP_dst'] },
            { metric: ['IP_src', 'IP_dst'] },
            { metric: ['IP_src', 'IP_dst'] }
          ]
        }}
        chartProps={{
          metrics: {
            Internal: ['IP_src', 'IP_dst'],
            Inbound: 'IP_src',
            Outbound: 'IP_dst'
          },
          topxMetricField: 'inet_src|dst_addr'
        }}
      />
    );
  }
}

export default IPAggregate;
