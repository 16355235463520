/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { inject, observer } from 'mobx-react';
import { AutoSizer } from 'react-virtualized';
import { withRouter } from 'react-router-dom';
import { Box, Card, Tag, Text } from 'core/components';
import Map from 'app/components/map';
import {
  getSubtestUrl,
  getWorstHealth,
  healthToIntent,
  healthToMarkerColor
} from 'app/views/synthetics/utils/syntheticsUtils';

@inject('$syn')
@observer
class TestResultsMap extends Component {
  getMarkerContentHTML = (el, props, popup) => {
    const { $syn, test, history } = this.props;
    const { lookbackSeconds, startDate, endDate, displayOptions } = test.results;
    const marker = this.markersObj[props.modelId];
    const { targets, agents } = marker;
    // we need dangerouslySetInnerHTML to be able to persist the onclick attr in createLink (see below)
    const createLink = (target, click) => {
      // unless we're on a public "shared link". Then don't implement the link functionality
      const onClick = displayOptions.shared ? '' : `onclick="${click}"`;

      return {
        __html: `<span style="padding: 2px 6px" ${onClick}>${test.isTransaction ? 'transaction' : target}</span>`
      };
    };
    // we need this to be able to redirect with lookback, we also can't call window.history directly
    // so we need to create this pointer reference
    window.synthMapMarkerHistoryPush = (to) => history.push(to, { lookbackSeconds, startDate, endDate });
    // create an html string to inject into the ppopup that shows all agents and target subtest options for this popup
    const html = (
      <>
        <Text heavy>{props.title}</Text>
        {agents.map(({ id: agentId }) => {
          const agent = $syn.agents.get(agentId);

          if (!agent) {
            return null;
          }

          return (
            <Box key={agentId} mt="4px">
              <Text>
                {agent.asFormatted}:{' '}
                {targets.map(({ target, health, target_agent_id }) => {
                  let to;
                  if (test.isAgentTest) {
                    to = getSubtestUrl({ agent_id: agentId, target: target_agent_id || target, test_id: test.id });
                  } else if (test.isHostname || test.isTransaction) {
                    to = getSubtestUrl({ agent_id: agentId, test_id: test.id });
                  } else {
                    to = getSubtestUrl({ agent_id: agentId, target, test_id: test.id });
                  }

                  const click = `window.synthMapMarkerHistoryPush('${to}'); return false;`;
                  // we need dangerouslySetInnerHTML to be able to persist the onclick attr in createLink
                  return (
                    <Tag
                      p={0}
                      mt="4px"
                      mr="4px"
                      minimal
                      intent={healthToIntent({ health, isAggregated: test.results.isAggregated })}
                      interactive
                      key={target}
                    >
                      <span dangerouslySetInnerHTML={createLink(target, click)} />
                    </Tag>
                  );
                })}
              </Text>
            </Box>
          );
        })}
      </>
    );
    popup.setHTML(renderToStaticMarkup(html));
  };

  getMarkers() {
    const { $syn, test } = this.props;
    const { resultTimeMs, agentTaskConfig, results, resultsKeys } = test.results;
    const { agentKeys } = resultsKeys;
    const selectedTime = resultTimeMs / 1000;
    const agents = {};

    for (let i = 0; i < agentKeys.length; i += 1) {
      const id = agentKeys[i];
      const health = test.results.agentHealthTs[id] || {};
      const { lat, long, agent_alias } = results.agents[id];
      const { geoCoords } = $syn.agents.get(id) || { geoCoords: { lat, lon: long } };
      const testTarget = test.get('config.target.value');

      agents[id] = {
        id,
        lat: geoCoords.lat,
        lon: geoCoords.lon,
        title: agent_alias,
        targets: agentTaskConfig?.[id]?.targets.reduce((acc, target) => {
          if (test.isHostname) {
            if (health.targets[testTarget]?.ping[selectedTime]?.dst_ip === target) {
              acc.push({
                target,
                health: health.targets[testTarget].overall[selectedTime].health
              });
            }
          } else if (test.isAgentTest) {
            const target_agents = $syn.agents.getTargetAgentsByIps([target]) || [];
            const target_agent_id = target_agents[0]?.id || target;
            acc.push({
              target: target_agents[0]?.label || target,
              target_agent_id,
              health: health?.targets?.[target_agent_id]?.overall?.[selectedTime]?.health
            });
          } else {
            acc.push({
              target,
              health: health?.targets?.[target]?.overall?.[selectedTime]?.health
            });
          }

          return acc;
        }, []),
        health
      };
    }

    // loop through all agents and create a map marker for each unique latLng coords
    // if there are more agents at those coords, create an array of agent ids and health to display in popup
    // also create a hash of additional marker properties that can be used to reset color and display data in popup
    const markers = [];
    this.markersObj = {};
    for (let i = 0; i < agentKeys.length; i += 1) {
      const id = agentKeys[i];
      const agent = agents[id];
      const { lat, lon, title, targets, health } = agent;
      const activeHealth = health?.overall?.[selectedTime] || {};

      const key = `${lat}|${lon}`;
      let marker = this.markersObj[key];
      if (marker) {
        const worstHealth = getWorstHealth(activeHealth.health, marker.health);
        marker.health = worstHealth;
        marker.agents.push({ id, health: activeHealth.health });
        markers[marker.idx].color = healthToMarkerColor({
          health: worstHealth,
          isAggregated: test.results.isAggregated
        });
      } else {
        marker = {
          idx: markers.length,
          agents: [{ id, health: activeHealth.health }],
          health: activeHealth.health,
          targets
        };
        this.markersObj[key] = marker;
        markers.push({
          id: `${lat}|${lon}`,
          lat,
          lon,
          title,
          modelId: `${lat}|${lon}`,
          color: healthToMarkerColor({ health: activeHealth.health, isAggregated: test.results.isAggregated }),
          element: 'dot'
        });
      }
    }

    return markers;
  }

  render() {
    const { onLoad } = this.props;
    const markers = this.getMarkers();
    return (
      <Card mb={2} overflow="hidden" minHeight={265}>
        <AutoSizer disableHeight>
          {({ width }) => {
            if (width === 0) {
              return null;
            }
            return (
              <Map
                containerWrapperProps={{ width, height: 265, borderRadius: 4 }}
                popupCloseButton
                getMarkerContentHTML={this.getMarkerContentHTML}
                markerPopupOnHover
                markerSelectOnClick
                markers={markers}
                submarkers={[]}
                autoBounds
                maxZoom={16}
                onLoad={onLoad}
                hideNonSelectedMarkers={false}
              />
            );
          }}
        </AutoSizer>
      </Card>
    );
  }
}

export default withRouter(TestResultsMap);
