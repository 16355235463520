import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Link, Box, Flex, InfoBox } from 'core/components';
import storeLoader from 'app/stores/storeLoader';

@storeLoader('$kproxyAgents')
@observer
class SiteDetail extends Component {
  // need to fetch plans, looks like directly going to /settings/devices link doesn't load plans

  render() {
    const { $kproxyAgents, site } = this.props;
    const kproxyAgents = $kproxyAgents.collection.models.filter((model) => model.get('site_id') === site.id);

    return (
      <>
        <Flex>
          <Box flex={1} maxWidth={300} ml={3}>
            <InfoBox heading="kproxy Agents">
              {kproxyAgents.length
                ? kproxyAgents.map((agent) => (
                    <Box key={agent.id}>
                      <Link to="/v4/settings/kproxy-agents">{agent.get('title')}</Link>
                    </Box>
                  ))
                : 'None'}
            </InfoBox>
          </Box>
        </Flex>
      </>
    );
  }
}

export default SiteDetail;
