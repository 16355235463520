/*
  Displays a table of alarms demonstrating status, state, id, test, and start/end times
*/
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { isEqual } from 'lodash';
import { Callout, Card, EmptyState, Flex } from 'core/components';
import { VirtualizedTable, Table } from 'core/components/table';
import IncidentLogCollection from 'app/stores/synthetics/IncidentLogCollection';
import { timezone, dateTimeFormatter } from 'core/util/dateUtils';
import { getCollectionConfig } from 'app/views/synthetics/utils/alarmsTableRenderers';
import IncidentLogFilters from './IncidentLogFilters';
import IncidentLogTableFooter from './IncidentLogTableFooter';

@inject('$syn')
@observer
export default class IncidentLogTable extends Component {
  static defaultProps = {
    activeTimeslot: null,
    resultTimeMs: null,
    minimal: false, // when true, loads columns for situations where there is minimal space such as in the agent details sidebar
    bgp: false, // when true, loads columns and groups for bgp test results
    testResults: false, // when true, loads columns and groups for parent/subtest results alarms
    virtualized: true, // favor virtualization whenever possible but allow override to a plain table
    filterable: false, // controls rendering of grouping and search controls in the table header
    setPrefixes: null // callback used in bgp test results
  };

  constructor(props) {
    super(props);

    const { $syn, minimal, bgp, testResults, setPrefixes } = this.props;

    this.collection = new IncidentLogCollection(this.alarms, { store: $syn.store });

    // load the config that drives columns and group rendering
    // there is currently no need to support loading configs as part of the component lifecycle
    this.config = getCollectionConfig({ $syn, minimal, bgp, testResults, setPrefixes });
  }

  componentDidUpdate(prevProps) {
    const { activeTimeslot } = this.props;

    if (!isEqual(prevProps.activeTimeslot, activeTimeslot)) {
      this.collection.processData(this.alarms);
    }
  }

  /*
    Returns the alarms from the active timeslot
    When there is no active timeslot, an empty array is returned
    and the table's empty state is used
  */
  get alarms() {
    const { activeTimeslot } = this.props;
    return activeTimeslot?.alarms || [];
  }

  get emptyState() {
    const { resultTimeMs } = this.props;

    return (
      <Flex justifyContent="center">
        <Callout width={400} intent="success">
          <EmptyState
            icon="thumbs-up"
            title={`No Incidents for ${dateTimeFormatter({ value: resultTimeMs })} ${timezone.value}`}
            description="There were no issues during this time segment."
          />
        </Callout>
      </Flex>
    );
  }

  render() {
    const { $syn, resultTimeMs, activeTimeslot, minimal, columns, virtualized, testResults, filterable, ...boxProps } =
      this.props;
    let TableComponent = minimal ? Table : VirtualizedTable;

    if (!virtualized) {
      // override to use the plain table
      TableComponent = Table;
    }

    if (this.alarms.length === 0) {
      return this.emptyState;
    }

    return (
      <Flex flex={1} flexDirection="column" gap={2} minHeight={0}>
        {filterable && (
          <Card position="sticky" top="-16px" zIndex={2} p={1}>
            <IncidentLogFilters collection={this.collection} groupByOptions={this.config.utils.groupByOptions} />
          </Card>
        )}
        <Card display="flex" flexDirection="column" m="2px" minHeight={0} {...boxProps}>
          <TableComponent
            collection={this.collection}
            columns={columns || this.config.columns}
            rowHeight={36}
            selectOnRowClick={false}
            groupSummaryLookup={this.config.utils.groupSummaryLookup}
            style={{ overflow: 'auto' }}
            isCollapsed
            flexed
            stickyHeader
          />
          <IncidentLogTableFooter collection={this.collection} />
        </Card>
      </Flex>
    );
  }
}
