import React, { Component } from 'react';
import { Classes, Position } from '@blueprintjs/core';
import classNames from 'classnames';

import Box from 'core/components/Box';
import Icon from 'core/components/Icon';
import Spinner from 'core/components/Spinner';
import Flex from 'core/components/Flex';
import Popover from 'core/components/Popover';
import Button from 'core/components/Button';
import Tooltip from 'core/components/Tooltip';
import MenuDivider from 'core/components/MenuDivider';
import { UP, DOWN, ENTER, ESC, TAB } from 'core/util/keyCodes';

import SelectPopoverInput from './SelectPopoverInput';
import { getOptionsWithNone } from '../../form/components/modalSelect/selectHelpers';

export const defaultOptionRenderer = (props) => {
  const {
    className,
    disabled,
    disabledTooltip,
    iconCls,
    iconName,
    icon,
    key,
    label,
    selectItem,
    selected,
    style,
    separator = false,
    value,
    optionFormatter,
    groupHeaderProps,
    multi,
    toggle
  } = props;

  if (separator) {
    return <MenuDivider key="separator" />;
  }

  if (groupHeaderProps) {
    return (
      <Box
        key={key || value}
        className={classNames(className, Classes.MENU_HEADER)}
        bg="selectGroupHeader.grayBackground"
        border="none"
        m="0 -4px"
        position="sticky"
        cursor="auto"
        {...groupHeaderProps}
      >
        {label}
      </Box>
    );
  }

  const onClick = (!selected || (multi && toggle)) && !disabled ? () => selectItem(value) : undefined;
  const iconType = iconCls || iconName || icon;

  const content = (
    <div key={key || value} className={className} onClick={onClick} style={style}>
      {iconType && (
        <Box>
          <Icon icon={iconType} mr={1} />
        </Box>
      )}
      <Box>{optionFormatter ? optionFormatter(props) : label}</Box>
    </div>
  );

  if (disabledTooltip && disabled) {
    return (
      <Tooltip key={key || value} content={disabledTooltip}>
        {content}
      </Tooltip>
    );
  }

  return content;
};

class SelectPopover extends Component {
  static defaultProps = {
    disabled: false,
    disabledValues: [],
    loading: false,
    menuHeight: 300,
    menuWidth: 230,
    multi: false,
    optionLimit: -1,
    ellipsisOptions: true,
    optionRenderer: defaultOptionRenderer,
    popoverPosition: Position.BOTTOM_LEFT,
    modifiers: {
      keepTogether: { enabled: true },
      flip: { enabled: true, boundariesElement: 'viewport' },
      preventOverflow: { enabled: true, boundariesElement: 'viewport' },
      offset: { enabled: true, offset: '0,2px' }
    },
    rowHeight: 28,
    showFilter: false,
    style: {}
  };

  state = {
    optionsFilter: ''
  };

  componentDidUpdate(prevProps) {
    const { isOpen, showFilter } = this.props;

    if (showFilter && !prevProps.isOpen && isOpen) {
      this.filterOptions('');
      this.focusInput();
    }
  }

  handleFilterOptions = (e) => {
    this.filterOptions(e.target.value);
  };

  handleKeyboardEvents = (e) => {
    const { onClose, onSelectItem, values, preventEnterSelection } = this.props;
    const { selectedIndex } = this.state;
    const selectOptions = this.getFilteredOptions();

    const key = e.keyCode ? e.keyCode : e.which;
    const keyCodes = [UP, DOWN, ENTER, ESC, TAB];

    if (keyCodes.includes(key)) {
      e.stopPropagation();

      if (key === DOWN) {
        this.setState(
          {
            selectedIndex: selectedIndex < selectOptions.length - 1 ? selectedIndex + 1 : 0
          },
          this.updateScrollPosition
        );
      }
      if (key === UP) {
        this.setState(
          {
            selectedIndex: selectedIndex > 0 ? selectedIndex - 1 : selectOptions.length - 1
          },
          this.updateScrollPosition
        );
      }

      if (key === ENTER && !preventEnterSelection) {
        const option = selectOptions[selectedIndex === -1 ? 0 : selectedIndex];
        if (option && values !== option.value) {
          onSelectItem(option.value, option);
        } else {
          onClose();
        }

        this.setState({ optionsFilter: '', selectedIndex: -1, options: [] });
      }

      if (key === ESC || key === TAB) {
        onClose();
        this.setState({ optionsFilter: '', selectedIndex: -1, options: [] });
      }
    }
  };

  handleSelectItem = (value) => {
    const { onSelectItem, keepOpen } = this.props;
    const { options } = this.state;

    if (!keepOpen) {
      this.setState({ optionsFilter: '', selectedIndex: -1, options: [] });
    }

    let option;
    if (options) {
      option = options.find((opt) => opt.value === value);
    }

    onSelectItem(value, option);
  };

  updateScrollPosition = () => {
    const { selectedIndex } = this.state;
    const itemHeight = this.scrollEl.firstChild.offsetHeight;

    if (this.scrollEl) {
      const selectedIndexY = (selectedIndex + 1) * itemHeight;

      if (selectedIndexY > this.scrollEl.scrollTop + this.scrollEl.offsetHeight) {
        this.scrollEl.scrollTop = selectedIndexY - this.scrollEl.offsetHeight + 8;
      } else if (selectedIndexY - itemHeight < this.scrollEl.scrollTop) {
        this.scrollEl.scrollTop = selectedIndexY - itemHeight;
      }
    }
  };

  handleScrollRef = (refs) => {
    const { scrollRef } = this.props;

    if (scrollRef) {
      scrollRef(refs);
    } else {
      this.scrollEl = refs;
    }
  };

  focusInput = () => {
    if (this.inputEl) {
      this.inputEl.focus();
    }
  };

  filterOptions(optionsFilter) {
    const { clearable, onQuery, values } = this.props;

    if (onQuery) {
      this.setState({ optionsFilter });

      const { selectedIndex, options } = this.state;

      const value =
        selectedIndex >= 0 && options && options.length > selectedIndex ? options[selectedIndex].value : values;

      onQuery(optionsFilter).then((newOptions) => {
        let newSelectedIndex = newOptions.findIndex((option) => option.value === value);
        if (clearable) {
          newSelectedIndex += 1;
        }
        this.setState({ options: newOptions, selectedIndex: newSelectedIndex });
        this.focusInput();
      });
    } else {
      this.setState({ optionsFilter, selectedIndex: -1 });
    }
  }

  getFilteredOptions() {
    const { clearable, clearableLabel, minFilterChars, onQuery, options, optionLimit, showFilter } = this.props;
    const { optionsFilter } = showFilter ? this.state : this.props;

    let filteredOptions = options;

    if (onQuery) {
      const { options: stateOptions } = this.state;
      filteredOptions = stateOptions || [];
    } else if (optionsFilter) {
      const filter = optionsFilter.toLowerCase();
      filteredOptions = options.filter(
        ({ label, value, filterLabel }) =>
          (filterLabel && filterLabel.toLowerCase().includes(filter)) ||
          (typeof label === 'string' && label.toLowerCase().includes(filter)) ||
          (typeof label === 'number' && label.toString().startsWith(filter)) ||
          (typeof value === 'string' && value.toLowerCase().includes(filter)) ||
          (typeof value === 'number' && value.toString().startsWith(filter))
      );
    }

    if (minFilterChars > 0 && optionsFilter.length < minFilterChars) {
      return [];
    }

    const allFilteredOptions = clearable ? getOptionsWithNone(filteredOptions, clearableLabel) : filteredOptions;

    if (optionLimit > 0 && showFilter) {
      return allFilteredOptions.slice(0, optionLimit);
    }

    return allFilteredOptions;
  }

  renderFilterInput(filteredOptions) {
    const {
      showFilter,
      filterPlaceholder = 'Filter options...',
      small,
      multi,
      fetchingOptions,
      onSelectAll,
      showSelectAll
    } = this.props;
    const { optionsFilter } = this.state;

    if (!showFilter) {
      return null;
    }

    let rightElement;
    if (
      multi &&
      (optionsFilter || showSelectAll) &&
      !fetchingOptions &&
      filteredOptions &&
      filteredOptions.length > 0
    ) {
      rightElement = (
        <Button
          minimal
          intent="primary"
          small={small}
          minHeight={small ? 20 : 24}
          onClick={() =>
            onSelectAll(
              filteredOptions.map((o) => o.value).filter((value) => value !== ''),
              filteredOptions
            )
          }
        >
          Select All
        </Button>
      );
    }

    return (
      <SelectPopoverInput
        placeholder={filterPlaceholder}
        ref={(inputEl) => {
          this.inputEl = inputEl;
        }}
        onKeyDownCapture={this.handleKeyboardEvents}
        onChange={this.handleFilterOptions}
        value={optionsFilter}
        small={small}
        rightElement={rightElement}
      />
    );
  }

  renderOption(option, index) {
    const {
      optionRenderer,
      clearable,
      multi,
      showFilter,
      values,
      disabledValues,
      optionFormatter,
      ellipsisOptions,
      small,
      toggle
    } = this.props;
    const { selectedIndex } = showFilter ? this.state : this.props;

    // This is purely done to make sure it's a string compare (coerces) in multi select scenarios
    // It's not perfect because it'll match across delimiters
    const value = values && values.join ? values.join(',') : values;
    const disabled = option.disabled || disabledValues.includes(option.value);

    /* eslint eqeqeq: 0 */
    const selected =
      option.selected ||
      (multi ? values.indexOf(option.value) >= 0 : value == option.value) ||
      (multi && clearable && option.value === '' && values.length === 0);
    const menuItemClassName = classNames(Classes.MENU_ITEM, option.className, {
      [Classes.TEXT_OVERFLOW_ELLIPSIS]: ellipsisOptions,
      [Classes.ACTIVE]: selected || (!multi && selectedIndex === index),
      [Classes.DISABLED]: disabled,
      [Classes.SMALL]: small
    });

    return optionRenderer({
      ...option,
      className: menuItemClassName,
      selectItem: (!selected || (multi && toggle)) && !disabled ? this.handleSelectItem : () => {},
      focused: selectedIndex === index,
      selected,
      disabled,
      optionFormatter,
      multi,
      toggle
    });
  }

  renderOptions() {
    const { menuHeight, menuWidth, popoverRef, showFilter, fetchingOptions, small } = this.props;

    const padding = 4;
    const filteredOptions = this.getFilteredOptions();
    const optionsHeight = menuHeight - (showFilter ? 38 : 0);
    return (
      <div
        ref={popoverRef}
        style={{ maxHeight: menuHeight, width: menuWidth || 180, overflow: 'hidden', borderRadius: 3 }}
      >
        {this.renderFilterInput(filteredOptions)}
        <div ref={this.handleScrollRef} style={{ maxHeight: optionsHeight, overflow: 'auto', padding }}>
          {fetchingOptions && (
            <Flex alignItems="flex-start" p={1}>
              <Spinner size={18} />
            </Flex>
          )}
          {!fetchingOptions && filteredOptions.length === 0 && (
            <div
              className={classNames(Classes.MENU_ITEM, Classes.DISABLED, {
                [Classes.SMALL]: small
              })}
            >
              No options found
            </div>
          )}
          {!fetchingOptions && filteredOptions.map((option, index) => this.renderOption(option, index))}
        </div>
      </div>
    );
  }

  render() {
    const { boundary, children, isOpen, multi, popoverPosition, showFilter, modifiers } = this.props;

    return (
      <div>
        <Popover
          autoFocus={showFilter}
          canEscapeKeyClose
          content={this.renderOptions()}
          enforceFocus={showFilter}
          isModal={multi}
          isOpen={isOpen}
          position={popoverPosition || Position.BOTTOM_LEFT}
          modifiers={modifiers}
          popoverDidOpen={this.focusInput}
          boundary={boundary}
          minimal
          data-testid="popover"
        >
          {children}
        </Popover>
      </div>
    );
  }
}

export default SelectPopover;
