import React from 'react';
import { observer } from 'mobx-react';
import lazyLoad from 'core/util/lazyLoad';
import LoadingPanel from './LoadingPanel';

const TestResultsWaterfall = lazyLoad(() => import('app/views/synthetics/components/TestResultsWaterfall'));

@observer
export default class MetricsPanel extends React.Component {
  render() {
    const { test, targetResults, loading } = this.props;
    const { resultTimeMs } = test.agentResults;
    const { harTsResult } = targetResults;
    // target results are hydrated by results from AgentResultsState so check both
    const hasResults = test.agentResults.hasResults && targetResults.hasResults;

    return (
      <LoadingPanel loading={loading} test={test} testResults={test.results} empty={!hasResults}>
        <TestResultsWaterfall har={harTsResult} resultTimeMs={resultTimeMs} />
      </LoadingPanel>
    );
  }
}
