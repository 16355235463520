import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Field, FormComponent, Select } from 'core/form';

const fields = {
  savedFilter: {
    label: 'Saved Filter'
  }
};

const options = {
  name: 'Saved Filter Selector'
};

@inject('$savedFilters')
@observer
class SavedFilterSelector extends Component {
  onChange = (field) => {
    const { $savedFilters, onAdd } = this.props;

    onAdd($savedFilters.selectOptions.find((option) => option.value === field.value));

    field.reset();
  };

  render() {
    const { $savedFilters, large, minimal, width } = this.props;

    return (
      <FormComponent fields={fields} options={options}>
        {() => (
          <Field
            name="savedFilter"
            options={$savedFilters.selectOptions}
            showLabel={false}
            onChange={this.onChange}
            large={large}
            small={!large}
            mb={0}
          >
            <Select
              showFilter
              width={width}
              menuWidth={300}
              buttonStyle={{ icon: 'add-to-artifact', rightIcon: null }}
              valueRenderer={() => 'Add Saved Filter'}
              minimal={minimal}
            />
          </Field>
        )}
      </FormComponent>
    );
  }
}

export default SavedFilterSelector;
