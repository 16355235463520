import React, { Component } from 'react';
import { FlexColumn } from 'core/components/Flex';
import Card from 'core/components/Card';
import Icon from 'core/components/Icon';
import { inject, observer } from 'mobx-react';
import { FiEdit } from 'react-icons/fi';
import { Box, FromNowDate, Text, Flex, Button } from 'core/components';
import { RemoveButton } from 'core/form';
import AddEditComment from './AddEditComment';

@inject('$auth')
@observer
export default class Comment extends Component {
  state = {
    editMode: false
  };

  get modelValues() {
    const { model } = this.props;

    return model.get();
  }

  get canEdit() {
    const { model, $auth } = this.props;
    const modelUserId = model.get('userId');

    return $auth.activeUser.id === modelUserId;
  }

  handleUpdate = () => {
    this.setState({
      editMode: false
    });
  };

  handleEditClick = () => {
    this.setState({
      editMode: true
    });
  };

  handleCancelClick = () => {
    this.setState({
      editMode: false
    });
  };

  renderControls = () => {
    const { model, inline } = this.props;
    return this.canEdit ? (
      <Flex alignItems="center" gap="4px">
        {this.canEdit && (
          <Button
            key="edit"
            icon={inline ? null : FiEdit}
            text={inline ? 'Edit' : null}
            title="Edit"
            minimal
            small
            onClick={this.handleEditClick}
          />
        )}
        {this.canEdit && (
          <RemoveButton
            key="remove"
            model={model}
            text="Remove"
            entityName="comment"
            showIcon={!inline}
            hideText={!inline}
            small
          />
        )}
      </Flex>
    ) : null;
  };

  render() {
    const { editMode } = this.state;
    const { $auth, model, entityType, inline = false, large = true } = this.props;
    const { comment, user, cdate } = model.get();

    return (
      <FlexColumn gap={1} width="100%">
        {!editMode && !inline && (
          <Card elevation={1} pl={1}>
            <Flex justifyContent="space-between" gap={1} pr="2px" py="2px" borderBottom="thin">
              <Flex alignItems="center" lineHeight="20px" gap="4px">
                {entityType === 'alertAck' && (
                  <>
                    <Icon icon="endorsed" color="textColor" iconSize="16px" />
                    <Text fontWeight="500" small>
                      Acked
                    </Text>
                    <Text muted small>
                      by
                    </Text>
                  </>
                )}
                {entityType === 'alertAutoAck' && (
                  <>
                    <Icon icon="endorsed" color="textColor" iconSize="16px" />
                    <Text fontWeight="500" small>
                      Auto-acked
                    </Text>
                    <Text muted small>
                      by
                    </Text>
                  </>
                )}
                <Text small fontWeight="500" whiteSpace="nowrap">
                  {user.user_full_name}
                </Text>
                <FromNowDate
                  timeStamp={cdate}
                  userTimeZone={$auth.userTimezone}
                  tooltipPosition="top"
                  textOptions={{ whiteSpace: 'nowrap' }}
                />
              </Flex>
              {this.renderControls()}
            </Flex>
            <Flex p={1} pl={0}>
              <Text>{comment}</Text>
            </Flex>
          </Card>
        )}
        {!editMode && inline && (
          <Box>
            <Text as="div" mb="4px">
              {comment}
            </Text>
            <Flex borderTop="thin" mt={1} pt="4px" justifyContent="flex-end">
              {this.renderControls()}
            </Flex>
          </Box>
        )}
        {editMode && (
          <AddEditComment
            entityType={entityType}
            commentModel={model}
            initialValue={this.modelValues.comment}
            onCancel={this.handleCancelClick}
            onComment={this.handleUpdate}
            editMode
            large={large}
          />
        )}
      </FlexColumn>
    );
  }
}
