import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { BsArrowRightShort } from 'react-icons/bs';
import { ReactComponent as VpcIcon } from 'app/assets/icons/vpc.svg';
import { ReactComponent as TransitGatewayIcon } from 'app/assets/icons/transit-gateway.svg';
import { Box, Flex, Icon, Text, Spinner, Suspense, Select } from 'core/components';
import Model from 'core/model/Model';
import { ViewInExplorerButton } from 'app/components/dataviews/tools';
import ViewInExplorerMenuItem from 'app/components/dataviews/tools/ViewInExplorerMenuItem';
import CloudDetailLink from 'app/components/links/CloudDetailLink';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import ResultsTable from 'app/components/resultsTable/ResultsTable';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';

const AGGREGATE_TYPE_OPTIONS = [
  { value: 'avg_bits_per_sec', label: 'Avg' },
  { value: 'p95th_bits_per_sec', label: 'P95' },
  { value: 'max_bits_per_sec', label: 'Max' }
];

function getVPCsForGatewayQuery(gatewayId, aggregateType) {
  return {
    all_devices: false,
    device_types: ['aws_subnet'],
    aggregateTypes: aggregateType,
    show_total_overlay: false,
    show_overlay: false,
    lookback_seconds: 604800,
    metric: ['kt_aws_src_vpc_name', 'kt_aws_dst_vpc_name'],
    filters: {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          filters: [
            {
              filterField: 'ktsubtype__aws_subnet__STR17',
              operator: '=',
              filterValue: gatewayId
            }
          ],
          saved_filters: [],
          filterGroups: []
        }
      ]
    }
  };
}

function getVPCToVPCQuery(src, dst, gatewayId) {
  return {
    all_devices: false,
    device_types: ['aws_subnet'],
    aggregateTypes: ['avg_bits_per_sec', 'p95th_bits_per_sec', 'max_bits_per_sec'],
    show_total_overlay: false,
    show_overlay: false,
    lookback_seconds: 604800,
    filters: {
      connector: 'All',
      filterGroups: [
        {
          name: '',
          named: false,
          connector: 'All',
          not: false,
          autoAdded: '',
          filters: [
            {
              filterField: 'kt_aws_src_vpc_id',
              operator: '=',
              filterValue: src
            },
            {
              filterField: 'kt_aws_dst_vpc_id',
              operator: '=',
              filterValue: dst
            },
            {
              filterField: 'ktsubtype__aws_subnet__STR17',
              operator: '=',
              filterValue: gatewayId
            }
          ],
          saved_filters: [],
          filterGroups: []
        }
      ]
    }
  };
}

function tgwRenderer(option) {
  return (
    <Flex justifyContent="flex-start" alignItems="center">
      {option && (
        <>
          <Icon icon={TransitGatewayIcon} pr="4px" />
          <Text as="div" fontSize="small" color="muted" pr="4px">
            Transit Gateway:
          </Text>
          <Text as="div" fontWeight="bold" fontSize="small">
            {option.label}
          </Text>
        </>
      )}
      {!option && <Text>Select a Gateway...</Text>}
    </Flex>
  );
}

function metricRenderer(option) {
  return (
    <Flex justifyContent="flex-start" alignItems="center">
      <Text as="div" fontSize="small" color="muted" pr="4px">
        Metric:
      </Text>
      <Text as="div" fontWeight="bold" fontSize="small">
        {option.label}
      </Text>
    </Flex>
  );
}

function getDimensionOverrides() {
  return [
    {
      key: 'kt_aws_src_vpc_name',
      hidden: true
    },
    {
      key: 'kt_aws_dst_vpc_name',
      hidden: true
    },
    {
      key: 'vpc_combined',
      label: (
        <Text fontWeight="bold" pl={1}>
          VPC Conversation
        </Text>
      ),
      renderer: ({ model }) => (
        <Flex alignItems="center">
          <Box overflow="hidden" flex="1" style={{ textOverflow: 'ellipsis' }}>
            <Icon icon={VpcIcon} pr={1} />
            <CloudDetailLink
              cloud="aws"
              detail="vpc_id"
              value={model.get('kt_aws_src_vpc_id')}
              text={model.get('kt_aws_src_vpc_name')}
            />
          </Box>
          <Icon icon={BsArrowRightShort} iconSize={16} px={1} />
          <Box overflow="hidden" flex="1" style={{ textOverflow: 'ellipsis' }}>
            <Icon icon={VpcIcon} pr={1} />
            <CloudDetailLink
              cloud="aws"
              detail="vpc_id"
              value={model.get('kt_aws_dst_vpc_id')}
              text={model.get('kt_aws_dst_vpc_name')}
            />
          </Box>
        </Flex>
      )
    }
  ];
}

@inject('$hybridMap')
@withTheme
@observer
class AWSTransitGatewaysWidget extends Component {
  state = {
    config: null,
    gateways: [],
    hasFetchedGateways: null
  };

  static getDerivedStateFromProps(props, state) {
    const { config } = props;

    // If there's no config in state, let's get setup
    if (!state.config) {
      // If there's no config, initialize one
      if (!config) {
        return { config: { aggregateType: 'avg_bits_per_sec' }, model: new Model() };
      }
      return { config, model: new Model(config) };
    }

    return null;
  }

  componentDidMount() {
    const { $hybridMap } = this.props;

    $hybridMap.awsCloudMapCollection.fetch().then(() => {
      const { topology } = $hybridMap.awsCloudMapCollection;

      const gateways = Object.values(topology.Entities.TransitGateways).map((tgw) => ({
        value: tgw.TransitGatewayId,
        label: tgw.Name
      }));

      this.setState((state) => ({
        gateways,
        hasFetchedGateways: true,
        config: { gatewayId: gateways[0]?.value, ...state.config }
      }));
    });
  }

  handleOptionChange = (changed) => {
    const { onConfigChange } = this.props;
    const { config, model } = this.state;

    model.set(changed);
    const newConfig = { ...config, ...changed };
    this.setState({ config: newConfig });

    if (onConfigChange) {
      onConfigChange(newConfig);
    }
  };

  rowAction = (model) => {
    const { config } = this.state;
    return (
      <ViewInExplorerButton
        small
        text=""
        query={getVPCToVPCQuery(model.get('kt_aws_src_vpc_id'), model.get('kt_aws_dst_vpc_id'), config.gatewayId)}
      />
    );
  };

  renderData() {
    const { config, gateways, hasFetchedGateways } = this.state;
    const query = getVPCsForGatewayQuery(config.gatewayId, [config.aggregateType]);

    if (!gateways.length && !hasFetchedGateways) {
      return <Spinner pt={1} size={24} />;
    }

    return (
      <Flex flexDirection="column">
        <Flex flexWrap="wrap" px={1}>
          <Box pt={1} pr={1}>
            <Select
              options={gateways}
              values={config.gatewayId}
              valueRenderer={tgwRenderer}
              menuWidth={320}
              onChange={(option) => this.handleOptionChange({ gatewayId: option })}
            />
          </Box>
          <Box pt={1}>
            <Select
              options={AGGREGATE_TYPE_OPTIONS}
              values={config.aggregateType}
              valueRenderer={metricRenderer}
              menuWidth={110}
              onChange={(option) => this.handleOptionChange({ aggregateType: option })}
            />
          </Box>
        </Flex>

        {config.gatewayId && (
          <LightweightDataViewWrapper query={query}>
            {({ loading, results, dataview, queryModel, bucket }) => (
              <Suspense loading={loading} fallback={<Spinner pt={1} size={24} />}>
                {!loading && results.size === 0 && (
                  <Text p={1} color="warning">
                    VPCs connected to this Transit Gateway are not monitored by Kentik.
                  </Text>
                )}
                {results.size !== 0 && (
                  <ResultsTable
                    query={query}
                    loading={loading}
                    dataview={dataview}
                    queryModel={queryModel}
                    queryResultsCollection={results}
                    bucket={bucket}
                    dimensionOverrides={getDimensionOverrides()}
                    actions={this.rowAction}
                    showSparklines={false}
                    striped={false}
                    tableClass="row-bordered"
                  />
                )}
              </Suspense>
            )}
          </LightweightDataViewWrapper>
        )}
      </Flex>
    );
  }

  render() {
    const { canCustomize, onRemove } = this.props;
    const { config } = this.state;
    const title = 'AWS InterVPC Traffic by Transit Gateway';
    const tooltip =
      'Use this widget to see how traffic is being routed through your Transit Gateways and quickly explore flows across all or individual TGWs.';
    return (
      <WidgetFrame
        canCustomize={canCustomize}
        menuOptions={
          <ViewInExplorerMenuItem
            query={getVPCsForGatewayQuery(config?.gatewayId, [
              'avg_bits_per_sec',
              'p95th_bits_per_sec',
              'max_bits_per_sec'
            ])}
          />
        }
        onRemove={onRemove}
        title={title}
        tooltip={tooltip}
        display="flex"
        flexDirection="column"
      >
        {this.renderData()}
      </WidgetFrame>
    );
  }
}

export default AWSTransitGatewaysWidget;
