import React from 'react';
import { Flex, Text } from 'core/components';
import LargeLabeledValue from 'app/components/LargeLabeledValue';

const rollupValueProps = {
  labelSize: 12,
  shouldFormatValue: false,
  valueSize: 12,
  valueWeight: 'medium',
  labelTextProps: {
    textAlign: 'right'
  },
  valueBoxProps: {
    flexDirection: 'column'
  }
};

const ValueElement = ({ value }) => (
  <Text fontWeight={rollupValueProps.valueWeight} fontSize={rollupValueProps.valueSize}>
    {value}
  </Text>
);

export const MetricRollupValues = ({
  avgValue,
  maxValue,
  lastValue,
  avgSecondaryValue,
  maxSecondaryValue,
  lastSecondaryValue
}) => (
  <Flex style={{ gap: 16, width: '100%', flexFlow: 'row-reverse' }}>
    <LargeLabeledValue
      label="Last"
      value={lastValue}
      valueElement={<ValueElement value={lastSecondaryValue} />}
      {...rollupValueProps}
    />
    <LargeLabeledValue
      label="Max"
      value={maxValue}
      valueElement={<ValueElement value={maxSecondaryValue} />}
      {...rollupValueProps}
    />
    <LargeLabeledValue
      label="Avg"
      value={avgValue}
      valueElement={<ValueElement value={avgSecondaryValue} />}
      {...rollupValueProps}
    />
    <LargeLabeledValue label="&nbsp;" value="In" valueElement={<ValueElement value="Out" />} {...rollupValueProps} />
  </Flex>
);
