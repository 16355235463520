import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Box, Spinner, Suspense, Text } from 'core/components';
import { greekIt, intlCurrency } from 'app/util/utils';
import WidgetFrame from 'app/components/decks/widgets/WidgetFrame';
import ModuleLinkButton from '../../../landing/ModuleLinkButton';

@inject('$cost')
@observer
class CostsWidget extends Component {
  state = {
    isFetching: true
  };

  componentDidMount() {
    const { $cost } = this.props;

    if (!$cost.canViewCosts) {
      this.setState({ isFetching: false });
    } else {
      $cost.fetchHistory({ summaryOnly: true }).then(() => {
        this.setState({ isFetching: false });
      });
    }
  }

  renderData() {
    const { $cost } = this.props;
    const { adjusted: ingressAdjusted, unitsLabel: ingressUnitsLabel } = greekIt($cost.currentMonthSummary.ingress, {
      suffix: 'bits/s'
    });
    const { adjusted: egressAdjusted, unitsLabel: egressUnitsLabel } = greekIt($cost.currentMonthSummary.egress, {
      suffix: 'bits/s'
    });

    const title = 'Connectivity Costs';
    const route = '/v4/edge/costs';

    return (
      <>
        <Box flex={1} mb={2}>
          <Box mb="4px">
            <Text fontWeight="bold">
              {intlCurrency($cost.currentMonthSummary.cost, $cost.currentMonthSummary.currency)}
            </Text>{' '}
            estimated cost
          </Box>
          <Box mb="4px">
            <Text fontWeight="bold">{ingressAdjusted.toFixed(0)}</Text> {ingressUnitsLabel} ingress
          </Box>
          <Box mb="4px">
            <Text fontWeight="bold">{egressAdjusted.toFixed(0)}</Text> {egressUnitsLabel} egress
          </Box>
        </Box>
        <ModuleLinkButton text={title} to={route} />
      </>
    );
  }

  render() {
    const { $cost, canCustomize, onRemove } = this.props;
    const { isFetching } = this.state;
    const title = 'Connectivity Costs';

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        onRemove={onRemove}
        title={title}
        display="flex"
        flexDirection="column"
        p={2}
      >
        {$cost.canViewCosts && (
          <Suspense
            loading={isFetching}
            fallback={
              <Box mt={2}>
                <Spinner size={24} />
              </Box>
            }
          >
            {this.renderData()}
          </Suspense>
        )}
        {!$cost.canViewCosts && 'Contact your administrator for access to Connectivity Costs'}
      </WidgetFrame>
    );
  }
}

export default CostsWidget;
