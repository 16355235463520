import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { MenuDivider, MenuItem } from 'core/components';
import { getTotalTrafficOverlay, getHistoricalOverlay } from 'app/stores/query/QueryModel';

@withRouter
@inject('$dictionary', '$queryEditor')
@observer
class ViewSqlMenuItem extends Component {
  showQuerySql(query, type) {
    const { history, $queryEditor } = this.props;
    history.push('/v4/core/query-editor');
    const req = query.serialize();
    if (type) {
      req.type = type;
    }
    $queryEditor.loadQuery(req);
  }

  render() {
    const {
      $dictionary: {
        dictionary: {
          appProtocolQuery: { rawLegendUnits = [] }
        }
      },
      dataview
    } = this.props;

    return (
      <MenuItem icon="database" text="View SQL" popoverProps={{ openOnTargetFocus: false }}>
        {dataview.queryBuckets.activeBuckets.reduce((elems, bucket) => {
          elems.push(<MenuDivider key={`bucket-${bucket.id}`} title={bucket.get('name')} />);
          bucket.queries.each((query) => {
            const { descriptor, query_title, show_overlay, show_total_overlay } = query.get();

            elems.push(
              <MenuItem
                key={`query-${query.id}`}
                text={descriptor || query_title || 'Default'}
                tagName="button"
                onClick={() => this.showQuerySql(query)}
              />
            );
            if (rawLegendUnits.includes(query.outsortUnit)) {
              elems.push(
                <MenuItem
                  key={`query-${query.id}-legend`}
                  text="Legend"
                  tagName="button"
                  onClick={() => this.showQuerySql(query, 'rawRows')}
                />
              );
            }
            if (!bucket.overlayQueries.size) {
              if (show_total_overlay) {
                elems.push(
                  <MenuItem
                    key={`query-${query.id}-total`}
                    text="Total"
                    tagName="button"
                    onClick={() => this.showQuerySql(getTotalTrafficOverlay(query))}
                    label="Overlay"
                  />
                );
              }
              if (show_overlay) {
                elems.push(
                  <MenuItem
                    key={`query-${query.id}-historical`}
                    text="Historical"
                    tagName="button"
                    onClick={() => this.showQuerySql(getHistoricalOverlay(query))}
                    label="Overlay"
                  />
                );
              }
            }
          });
          bucket.overlayQueries.each((query) => {
            const { descriptor, query_title } = query.get();
            elems.push(
              <MenuItem
                key={`query-${query.id}`}
                text={descriptor || query_title}
                tagName="button"
                onClick={() => this.showQuerySql(query)}
                label="Overlay"
              />
            );
          });
          return elems;
        }, [])}
      </MenuItem>
    );
  }
}

export default ViewSqlMenuItem;
