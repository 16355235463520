import { greekIt } from 'app/util/utils';
import { Link } from 'core/components';
import React from 'react';
import DeviceLink from 'app/components/links/DeviceLink';

const getGenericDimensionColumn = (dimension, label) => ({
  name: dimension,
  label: label || dimension,
  minWidth: 60,
  flexBasis: 250,
  renderer: ({ value }) => value
});

const getInterfaceCounterDimensionColumn = ({ index }) => ({
  name: 'name',
  label: 'Interface Name',
  minWidth: 60,
  flexBasis: index === 0 ? 250 : 150,
  renderer: ({ model, value }) => {
    const ifIndex = model.get('ifindex');
    const deviceName = model.get('device_name');
    const target = deviceName && ifIndex && `/v4/infrastructure/devices/${deviceName}/interfaces/${ifIndex || ''}`;

    return target ? (
      <Link to={target} fontWeight="medium">
        {value}
      </Link>
    ) : (
      value
    );
  }
});

const getDeviceNameDimensionColumn = ({ index }) => ({
  name: 'device_name',
  label: 'Device Name',
  minWidth: 60,
  flexBasis: index === 0 ? 250 : 150,
  renderer: ({ model }) => <DeviceLink name={model.get('device_name')} fontWeight="medium" />
});

const getSpeedDimensionColumn = () => ({
  label: 'Capacity',
  name: 'speed',
  id: 'speed',
  value: 'speed',
  renderer: ({ value }) => {
    const { displayFull } = greekIt(value * 1e6, { fix: 0, suffix: '' });
    return displayFull;
  }
});

export const getMetricsResultDimensionColumns = ({ decoratedQuery, fullSelectedMeasurement }) =>
  decoratedQuery.dimensions.map((dimension, index) => {
    const label = fullSelectedMeasurement?.get(`storage.Dimensions.${dimension}`)?.Label;
    const columnProps = { dimension, label, decoratedQuery, index };
    switch (dimension) {
      case 'device_name':
        return getDeviceNameDimensionColumn(columnProps);
      case 'mac-address':
      case 'speed':
        return getSpeedDimensionColumn();
      case 'name':
        if (fullSelectedMeasurement?.get('measurement') === '/interfaces/counters') {
          return getInterfaceCounterDimensionColumn(columnProps);
        }
      // intentional fall through to default if above conditional fails
      default:
        return getGenericDimensionColumn(dimension, label);
    }
  });
