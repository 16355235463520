import { Collection } from 'core/model';
import { computed } from 'mobx';
import $auth from 'app/stores/$auth';
import JourneyModel from './JourneyModel';

class JourneyCollection extends Collection {
  get model() {
    return JourneyModel;
  }

  get url() {
    return '/api/ui/journeys/';
  }

  @computed
  get userJourneys() {
    const userId = $auth.getActiveUserProperty('id');
    return this.models.filter((journey) => journey.get('user_id') === userId).sort((a, b) => b.edate - a.edate);
  }

  @computed
  get companyJourneys() {
    const userId = $auth.getActiveUserProperty('id');
    return this.models
      .filter((journey) => journey.get('user_id') !== userId && journey.get('share_level') === 'org')
      .sort((a, b) => a.get('title').localeCompare(b.get('title')));
  }

  @computed
  get lastEditedUserJourney() {
    const userId = $auth.getActiveUserProperty('id');
    let mostRecentJourney = null;
    let mostRecentEdate = null;

    this.models.forEach((journey) => {
      if (journey.get('user_id') === userId && (!mostRecentEdate || journey.get('edate') > mostRecentEdate)) {
        mostRecentJourney = journey;
        mostRecentEdate = journey.get('edate');
      }
    });

    return mostRecentJourney;
  }
}

export default JourneyCollection;
