import React from 'react';
import styled from 'styled-components';
import { Flex, Icon, Link, Tag } from 'core/components';

const MenuHeading = styled.span`
  color: ${({ theme, color }) => color || theme.colors.body};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  display: inline-block;
  margin: 0;

  &:hover {
    text-decoration: ${({ link }) => (link ? 'underline' : 'none')};
  }
`;

function MenuHeaderWithIcon({ title, icon, to, blank, color, onClick, showNewTag, ...rest }) {
  return (
    <Flex alignItems="center" {...rest}>
      <Icon icon={icon} color={color || 'warning'} mr="8px" iconSize={16} />
      {to && (
        <Link to={to} blank={blank} onClick={onClick}>
          <MenuHeading color="primary" link>
            {title}
          </MenuHeading>
        </Link>
      )}
      {!to && <MenuHeading>{title}</MenuHeading>}
      {showNewTag && (
        <Tag className="new-tag" intent="warning" ml={1}>
          New!
        </Tag>
      )}
    </Flex>
  );
}

export default MenuHeaderWithIcon;
