/**
 * @typedef {{
 *   [measurement: string]: {
 *     [metric: string]: string|number;
 *   };
 * }} MetricsKV
 *
 * @typedef {{
 *   triggeredLevel: import('./policies.js').ApiNmsPolicyLevel;
 *   application: string;
 *   previousSeverity: number;
 *   currentSeverity: number;
 *   matchAggregateThresholdType: string;
 *   alarmTargets?: {
 *     [measurement: string]: {
 *       Fields: {
 *         [metric: string]: string | number;
 *       };
 *       Tags: {
 *         [dimension: string]: string | number;
 *       };
 *     };
 *   };
 *   policy: {
 *     id: string;
 *     name: string;
 *     description?: string;
 *     createdAt: string;
 *     version: number|string;
 *     versionCreatedAt: string;
 *     target: {
 *       entity_type: string;
 *       custom_type: boolean;
 *       dimensions: string[];
 *       measurements: string[];
 *     };
 *   };
 *   groupKey: {
 *     [dimension: string]: string|number;
 *   };
 *   metrics?: MetricsKV;
 *   stateChangePreviousStatus?: MetricsKV;
 * }} NmsAlarmContext
 */

/**
 * @param {object} eventViewModel
 * @returns NmsAlarmContext
 */
const getNmsAlarmContext = (eventViewModel) => {
  /** @type {NmsAlarmContext} */
  const alarmContext = {
    policy: {}
  };
  const evmDetails = eventViewModel?.details || [];
  evmDetails.forEach(({ name, value }) => {
    switch (name) {
      case 'AlarmGroupKey':
        alarmContext.groupKey = value;
        break;
      case 'AlarmPreviousSeverity':
        alarmContext.previousSeverity = value;
        break;
      case 'AlarmCurrentSeverity':
        alarmContext.currentSeverity = value;
        break;
      case 'AlarmMatchAggregateThresholdType':
        alarmContext.matchAggregateThresholdType = value;
        break;
      case 'AlarmPolicyApplication':
        alarmContext.application = value;
        break;
      case 'AlarmPolicyThreshold':
        alarmContext.triggeredLevel = value;
        break;
      case 'AlarmTargets':
        // Only present for match single type policies
        alarmContext.alarmTargets = value;
        break;
      case 'AlarmMetrics':
        // Only present for match single type policies
        alarmContext.metrics = value;
        break;
      case 'AlarmPreviousStatus':
        // Only present for alarms that triggered for a level that contains a state change condition
        alarmContext.stateChangePreviousStatus = value;
        break;
      case 'AlarmPolicyID':
        alarmContext.policy.id = value;
        break;
      case 'AlarmPolicyCreatedAt':
        alarmContext.policy.createdAt = value;
        break;
      case 'AlarmPolicyVersion':
        alarmContext.policy.version = value;
        break;
      case 'AlarmPolicyName':
        alarmContext.policy.name = value;
        break;
      case 'AlarmPolicyVersionCreatedAt':
        alarmContext.policy.versionCreatedAt = value;
        break;
      case 'AlarmPolicyDescription':
        alarmContext.policy.description = value;
        break;
      case 'AlarmPolicyTarget':
        alarmContext.policy.target = value;
        break;
      default:
        break;
    }
  });
  return alarmContext;
};

module.exports = {
  getNmsAlarmContext
};
