import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ToggleCollapse from 'app/components/ToggleCollapse';
import { Field, InputGroup, Select, TagInput, formConsumer } from 'core/form';
import { Box, Flex, Text, Button, FlexColumn, CalloutOutline } from 'core/components';
import AdditionalAccountsSelectorPanel from './AdditionalAccountsSelectorPanel';

@inject('$clouds', '$cloudExportWizard')
@formConsumer
@observer
class AdditionalMetadataRoles extends Component {
  state = {
    isLoading: false,
    secondaryAccessMsg: null,
    secondaryAccessValid: undefined
  };

  // in legacy cloud export form comes from form consumer
  get form() {
    const { form, $cloudExportWizard } = this.props;

    return form ?? $cloudExportWizard.form;
  }

  handleCheckSecondaryRoles = () => {
    const { $clouds } = this.props;
    this.setState({ secondaryAccessValid: null, secondaryAccessMsg: null, isLoading: true }, () =>
      $clouds
        .validateAwsSecondaryRoles(this.form.getValues())
        .then((res) => {
          if (Array.isArray(res)) {
            if (res.every((entry) => entry.success)) {
              this.setState({ secondaryAccessValid: true });
            } else {
              this.setState({
                secondaryAccessValid: false,
                secondaryAccessMsg: res
                  .filter((entry) => !entry.success)
                  .map((entry) => <div key={entry.fn}>{entry.message}</div>)
              });
            }
          } else {
            this.setState({
              isLoading: false,
              secondaryAccessValid: !!res.success,
              secondaryAccessMsg: res.message
            });
          }
        })
        .catch((error) => {
          this.setState({
            secondaryAccessValid: false,
            secondaryAccessMsg: error.message
          });
        })
        .then(() => {
          this.setState({
            isLoading: false
          });
        })
    );
  };

  render() {
    const { secondaryAccessMsg, secondaryAccessValid, isLoading } = this.state;

    const isOrgLevelRoleSelected = this.form.getValue('properties.aws_iam_role_arn_is_org');

    return (
      <FlexColumn>
        <ToggleCollapse
          defaultOpen={
            this.form.getField('properties.secondary_aws_accounts')?.getValue()?.length > 0 || isOrgLevelRoleSelected
          }
          label="Optional: Additional Metadata Roles."
          buttonProps={{
            mt: 0,
            mb: 0,
            textAlign: 'left',
            intent: secondaryAccessValid === true ? 'success' : ''
          }}
        >
          <CalloutOutline border="none" intent={secondaryAccessValid === true ? 'success' : ''}>
            <FlexColumn gap={2}>
              <Text>You can specify additional roles/accounts/regions where Kentik will retrieve metadata.</Text>

              <Text muted>
                We will loop through every account and assume primary role
                <em>{` ${this.form.getField('properties.aws_iam_role_arn').getValue()} `}</em> first, then we will
                assume secondary role generated based on template:
                <em>{' arn:aws:iam::<<aws_account_id>>:role/<<role_suffix>> '}</em>
                (or <em>{' arn:aws-cn:iam::<<aws_account_id>>:role/<<role_suffix>> '}</em> for China regions ) and use
                it to fetch topology from AWS.
              </Text>

              {isOrgLevelRoleSelected && <AdditionalAccountsSelectorPanel form={this.form} />}

              {/* Secondary Aws Accounts Field */}
              {!isOrgLevelRoleSelected && (
                <Flex gap={1} alignItems="center">
                  <Field name="properties.secondary_aws_accounts" mb={0} large flex={1}>
                    <TagInput fill dataTestId="properties.secondary_aws_accounts" />
                  </Field>
                </Flex>
              )}

              {/* Regions Dropdown */}
              <Field
                large
                mb={0}
                name="properties.secondary_aws_regions"
                label="Regions Used Across Secondary Accounts."
              >
                <Select multi keepOpen showFilter fill dataTestId="properties.secondary_aws_regions" />
              </Field>

              <FlexColumn gap={1}>
                <Flex alignItems="flex-end">
                  <Field name="properties.secondary_aws_suffix" mb={0} flex={1} large>
                    <InputGroup width="100%" />
                  </Field>
                  <Button
                    ml={1}
                    disabled={
                      secondaryAccessValid ||
                      this.form.getField('properties.secondary_aws_regions').getValue().length === 0 ||
                      (this.form.getField('properties.secondary_aws_accounts').getValue().length === 0 &&
                        !isOrgLevelRoleSelected)
                    }
                    onClick={this.handleCheckSecondaryRoles}
                    text={secondaryAccessValid ? 'Valid' : 'Verify'}
                    intent={secondaryAccessValid === true ? 'success' : 'primary'}
                    loading={isLoading}
                  />
                </Flex>
              </FlexColumn>
              {secondaryAccessValid === false && (
                <Box>
                  <Text mt="4px" color="danger">
                    {secondaryAccessMsg}
                  </Text>
                </Box>
              )}
            </FlexColumn>
          </CalloutOutline>
        </ToggleCollapse>
      </FlexColumn>
    );
  }
}

export default AdditionalMetadataRoles;
