import React, { Component } from 'react';
import { Button, ConfirmPopover, MenuItem } from 'core/components';
import { inject, observer } from 'mobx-react';

@inject('$alerting')
@observer
export default class ClearAlarmButton extends Component {
  static defaultProps = {
    fill: true
  };

  render() {
    const { $alerting, models, asMenuItem, fill } = this.props;
    const ButtonComponent = asMenuItem ? MenuItem : Button;
    const alertModels = Array.isArray(models) ? models : [models];
    return (
      <ConfirmPopover
        onConfirm={() => $alerting.clearAlarms(alertModels)}
        confirmBodyContent={`Are you sure you want to clear ${
          alertModels.length === 1 ? 'this alert' : `${alertModels.length} alerts`
        }?`}
        confirmIntent="primary"
        confirmText="Confirm"
        fill={fill}
      >
        <ButtonComponent
          icon="tick"
          disabled={alertModels.length === 0}
          text={`Clear ${alertModels.length > 1 ? `${alertModels.length} Alerts` : 'Alert'}`}
          fill={fill}
          textAlign="left"
        />
      </ConfirmPopover>
    );
  }
}
