import { sortedIndexBy } from 'lodash';

export const aggFuncsOptions = [
  { value: 'avg', label: 'Average' },
  { value: 'min', label: 'Min' },
  { value: 'max', label: 'Max' },
  { value: 'rate', label: 'Rate' },
  { value: 'p95', label: 'P95' },
  { value: 'sum', label: 'Sum' },
  { value: 'last', label: 'Last' },
  { value: 'delta', label: 'Δ Delta' },
  { value: 'none', label: 'None' }
];

export const rollupAggFuncsOptions = [
  { value: 'avg', label: 'Average' },
  { value: 'min', label: 'Min' },
  { value: 'max', label: 'Max' },
  { value: 'p95', label: 'P95' },
  { value: 'last', label: 'Last' }
];

export const metricOperatorOptions = [
  { value: '=', label: 'equals' },
  { value: '<>', label: 'does not equal' },
  { value: '>', label: 'is greater than' },
  { value: '<', label: 'is less Than' }
];

export const mergeAggFuncsOptions = [
  { value: 'sum', label: 'Sum' },
  { value: 'min', label: 'Min' },
  { value: 'max', label: 'Max' },
  { value: 'avg', label: 'Average' }
];

export const transformationOptions = [
  { value: 'none', label: 'None' },
  { value: 'counter', label: 'Counter' }
];

export const limitCountOptions = [
  { value: 0, label: '0' },
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500' },
  { value: 1000, label: '1000' },
  { value: 5000, label: '5000' },
  { value: 10000, label: '10000' }
];

export const windowSizeOptions = [
  { value: 0, label: 'Auto' },
  { value: 60, label: '1m' },
  { value: 60 * 5, label: '5m' },
  { value: 60 * 10, label: '10m' },
  { value: 60 * 30, label: '30m' },
  { value: 60 * 60, label: '60m' }
];

const closestOption = (options, value) => {
  const insertAt = sortedIndexBy(options, { value }, (option) => option.value);
  const index = insertAt >= options.length ? options.length - 1 : insertAt;
  return options[index];
};

export const closestWindowSizeOptionValue = (value) => closestOption(windowSizeOptions, value).value;

export const closestLimitCountOptionValue = (value) => closestOption(limitCountOptions, value).value;
