import React from 'react';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import { Box, Checkbox, Flex, Icon, Tag, Text } from 'core/components';
import { getOption } from 'core/form/components/modalSelect/selectHelpers';
import { ReactComponent as LowDensity } from 'app/assets/icons/synthetics/density-low.svg';
import { ReactComponent as HighDensity } from 'app/assets/icons/synthetics/density-high.svg';
import { ReactComponent as MaxDensity } from 'app/assets/icons/synthetics/density-max.svg';
import { DENSITY } from 'app/views/synthetics/components/mesh/util/constants';

const ICONS = {
  [DENSITY.LOW.id]: LowDensity,
  [DENSITY.HIGH.id]: HighDensity,
  [DENSITY.MAX.id]: MaxDensity
};

/*
  Renders the selected value AND selectable density options for the grid
  Displays icons and labels with support for theming

  Since there's no difference in what's rendered for value vs option we use this for both dropdown renderers
  This is accomplished by using the optional chain check on selectItem
*/
export function DensityOptionRenderer(option) {
  const { label, value, selectItem, className, theme } = option;

  return (
    <Flex key={value} justifyContent="space-between" p={1} onClick={() => selectItem?.(value)} className={className}>
      <Text>{label}</Text>
      <Icon icon={ICONS[value]} fill={theme.colors.body} />
    </Flex>
  );
}

/*
  Displays the selected value of metrics to filter the grid with
  Displays 'All Metrics' when every metric is selected, otherwise displays a distinct list of selections
*/
export function MetricValueRenderer(selectedOption, placeholder, values, options) {
  let labels = ['All Metrics'];

  if (values.length < 3) {
    labels = values.map((value) => getOption(options, value)?.label || value);
  }

  return <Box p={1}>{labels.join(', ')}</Box>;
}

/*
  Displays the selectable options for metrics in the dropdown
  Uses a checkbox to help demonstrate selected state
*/
export function MetricOptionRenderer(option) {
  const { label, value, selectItem, className, selected, isSelectable } = option;

  return (
    <Flex key={value} p={1} onClick={() => isSelectable && selectItem?.(value)} className={className}>
      <Checkbox mr={1} checked={selected} />
      <Text>{label}</Text>
    </Flex>
  );
}

/*
  Displays the selected value of an agent in the source/target search dropdowns
  Shows the agent alias (label) composed string as well as the index position of the agent in the grid
*/
export function AgentValueRenderer(option, placeholder) {
  let content = <span className={Classes.TEXT_MUTED}>{placeholder || placeholder}</span>;

  if (option) {
    const { meshItem, label } = option;

    content = (
      <Flex alignItems="center" overflow="hidden">
        <Text as="div" ellipsis>
          {label}
        </Text>
        <Tag ml={1} minimal small>
          {meshItem.index}
        </Tag>
      </Flex>
    );
  }

  return <Flex p={1}>{content}</Flex>;
}

/*
  Displays the selectable options for agents in the source/target searchable dropdowns
  Shows:
    - type as icon
    - agent alias (label)
    - status as icon
    - the siteDisplayName of the agent
    - index position in the grid
*/
export function AgentOptionRenderer(option) {
  const { selectItem, selected, value, meshItem, agentModel, label, siteDisplayName } = option;
  let content = <Text>All Agents</Text>;

  if (agentModel) {
    const { icon, status } = agentModel;
    content = (
      <>
        <Icon icon={icon} />
        <Box flex={1} overflow="hidden">
          <Flex alignItems="center" gap={1}>
            <Text as="div" ellipsis>
              {label}
            </Text>
            {status?.icon && <Icon icon={status.icon} />}
          </Flex>
          <Text as="div" ellipsis>
            {siteDisplayName}
          </Text>
        </Box>
        <Tag minimal>{meshItem.index}</Tag>
      </>
    );
  }

  return (
    <Flex
      key={value}
      alignItems="flex-start"
      p={1}
      justifyContent="space-between"
      onClick={() => selectItem?.(value)}
      className={classNames(Classes.MENU_ITEM, Classes.SMALL, {
        [Classes.INTENT_PRIMARY]: selected,
        [Classes.ACTIVE]: selected
      })}
    >
      {content}
    </Flex>
  );
}
