import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Spinner, Suspense, Text, Box } from 'core/components';
import LightweightDataViewWrapper from 'app/components/dataviews/LightweightDataViewWrapper';
import OttServiceLink from 'app/components/links/OttServiceLink';
import formatMetricValue from 'app/util/formatMetricValue';
import { getPeakValues } from 'app/util/queryResults';
import WidgetFrame from '../WidgetFrame';
import ModuleLinkButton from '../../../landing/ModuleLinkButton';

function LoadingSpinner() {
  return (
    <Box mt={2}>
      <Spinner size={24} />
    </Box>
  );
}

@inject('$ott')
@observer
class OttWidget extends Component {
  state = {
    loadingModuleConfig: true,
    totalClassified: 0
  };

  aggregateType = 'max_bits_per_sec';

  componentDidMount() {
    const { $ott } = this.props;

    $ott.getModuleConfig().then(() => {
      this.setState({ loadingModuleConfig: false });
    });
  }

  getTrafficPct = (topTraffic, totalTraffic) => (totalTraffic > 0 ? Math.round((topTraffic / totalTraffic) * 100) : 0);

  handleClassificationQueryComplete = ({ results }) => {
    const peakValues = getPeakValues(results.models);
    const total = peakValues.modelValues
      .filter((item) => item.key === 'Fully Classified' || item.key === 'Provider-Only')
      .reduce((acc, model) => acc + model.value, 0);

    this.setState({ totalClassified: total });
  };

  renderServiceLink = (model, idx, models) => (
    <span key={model.key}>
      <OttServiceLink service={model.key} />
      {idx !== models.length - 1 ? ', ' : ''}
    </span>
  );

  render() {
    const { $ott, canCustomize, onRemove } = this.props;
    const { loadingModuleConfig, totalClassified } = this.state;
    const title = 'OTT Service Tracking';
    const route = '/v4/service-provider/ott';

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        onRemove={onRemove}
        title={title}
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Suspense loading={loadingModuleConfig} fallback={<LoadingSpinner />}>
          <LightweightDataViewWrapper
            query={$ott.queries.getClassificationQuery({})}
            onQueryComplete={this.handleClassificationQueryComplete}
          />
          <LightweightDataViewWrapper
            query={$ott.queries.getTopServicesQuery({
              topx: 5,
              depth: 5,
              filters: [
                {
                  filterField: 'service_name',
                  operator: '<>',
                  filterValue: 'unknown'
                }
              ]
            })}
          >
            {({ loading, results }) => {
              if (loading) {
                return <LoadingSpinner />;
              }

              const peakValues = getPeakValues(results.models);
              const { value: formattedValue, metric } = formatMetricValue(totalClassified, 'bits');
              const topTraffic = peakValues.modelValues.reduce((accum, item) => accum + item.value, 0);
              const configured = results.size > 0;

              return (
                <>
                  {!configured && (
                    <Text as="div" muted flex={1}>
                      You do not have any OTT traffic.
                    </Text>
                  )}
                  {configured && (
                    <Box flex={1}>
                      <Text as="div" fontSize={14}>
                        Total traffic detected is <Text fontWeight="heavy">{formattedValue} </Text>
                        <Text color="muted">{metric}</Text>
                      </Text>

                      <Text as="div" mt={1} fontSize={14}>
                        Top <Text fontWeight="bold">{peakValues.modelValues.length}</Text> services are{' '}
                        <Text fontWeight="bold">{this.getTrafficPct(topTraffic, totalClassified)}%</Text> of total
                        traffic:
                      </Text>

                      <Text fontSize={12}>{peakValues.modelValues.map(this.renderServiceLink)}</Text>
                    </Box>
                  )}
                  <ModuleLinkButton mt={2} text={title} to={route} />
                </>
              );
            }}
          </LightweightDataViewWrapper>
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default OttWidget;
