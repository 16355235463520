import React from 'react';

import { Flex, Text } from 'core/components';
import CloudDetailLink from 'app/components/links/CloudDetailLink';
import CloudIcon from 'app/views/hybrid/maps/components/CloudIcon';

export default function CloudLinkWithIcon({ type, detail, value, ipAddress = '', iconSize = 16, cloud = 'aws' }) {
  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex alignItems="center" overflow="hidden" gap={1}>
        <CloudIcon cloudProvider={cloud} entity={type} width={iconSize} height={iconSize} />
        {detail !== 'eni' ? (
          <CloudDetailLink blank cloud={cloud} detail={detail} value={value} />
        ) : (
          <Text>{value}</Text>
        )}
      </Flex>
      {ipAddress && detail === 'eni' && (
        <Text muted small>
          {ipAddress}
        </Text>
      )}
    </Flex>
  );
}
