import React, { Component } from 'react';
import { Classes, CollapsibleList, MenuItem } from '@blueprintjs/core';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FiGrid } from 'react-icons/fi';

import { AnchorButton, Icon } from 'core/components';

const Breadcrumb = ({ onClick, href, text }) => {
  if (href) {
    return (
      <AnchorButton className={Classes.BREADCRUMB} onClick={onClick} minimal>
        {text}
      </AnchorButton>
    );
  }
  return (
    <span className={Classes.BREADCRUMB_CURRENT}>
      <Icon icon={FiGrid} mr="4px" /> {text}{' '}
    </span>
  );
};

@inject('$dashboards')
@withRouter
class DashboardNavigationBar extends Component {
  render() {
    const { navigationHistory, $dashboards, history } = this.props;

    return (
      <CollapsibleList
        className={Classes.BREADCRUMBS}
        dropdownTarget={<span className={Classes.BREADCRUMBS_COLLAPSED} />}
        visibleItemRenderer={Breadcrumb}
      >
        {navigationHistory.map((item, idx) => {
          const isCurrent = idx === navigationHistory.length - 1;
          const back = -1 * (navigationHistory.length - (idx + 1));
          const onClick = () => history.go(back);
          return (
            <MenuItem
              key={item.id}
              text={$dashboards.collection.get(item.id).get('dash_title')}
              href={!isCurrent && '#'}
              onClick={onClick}
            />
          );
        })}
      </CollapsibleList>
    );
  }
}

export default DashboardNavigationBar;
