import { omit } from 'lodash';

const fieldProps = [
  'autoFocus',
  'field',
  'hasEditLink',
  'isEditing',
  'noEditLink',
  'onEditComplete',
  'options',
  'rules',
  'showFieldLabel',
  'showOptionLabel',
  'showBoxLabel',
  'switchLabel',
  'onQuery',
  'form',
  'loading',
  'isOpen',
  'autoGrow'
];

// just remove all of the things that get passed down to components
// through `Field`, so we can safely use `defaultProps` and spread

export default function omitFieldProps(props) {
  return omit(props, fieldProps);
}
