import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { get, sortBy } from 'lodash';

import { Box, Flex, HTMLTable, Lookup, Text, Icon } from 'core/components';
import DimensionToLink from 'app/components/links/DimensionToLink';
import InsightLink from 'app/components/links/InsightLink';
import KeyTrafficIndicator from './KeyTrafficIndicator';

function KeyRankIndicator({ data }) {
  const rankOld = Number(data.rankOld);
  const rankNew = Number(data.rankNew);
  const rankDiff = rankOld - rankNew;
  const iconProps = { mr: '4px', iconSize: 12, style: { position: 'relative', top: -1 } };

  if (Number.isNaN(rankOld) || rankOld > 10) {
    return (
      <Text color="success" whiteSpace="nowrap" title="New top key">
        <Icon {...iconProps} icon="double-chevron-up" />
        New
      </Text>
    );
  }

  if (Number.isNaN(rankNew) || rankNew > 10) {
    return <Icon {...iconProps} icon="double-chevron-down" color="danger" title="Removed top key" />;
  }

  if (rankNew === rankOld) {
    return null;
  }

  if (rankNew < rankOld) {
    return (
      <Text color="success" whiteSpace="nowrap" title={`Up from #${rankOld}`}>
        <Icon {...iconProps} icon="chevron-up" />+{rankDiff}
      </Text>
    );
  }

  return (
    <Text color="danger" whiteSpace="nowrap" title={`Down from #${rankOld}`}>
      <Icon {...iconProps} icon="chevron-down" />
      {rankDiff}
    </Text>
  );
}

function ComparisonRow({ compact, row }) {
  const color = row.percentage < 0 ? 'danger' : 'success';

  return (
    <Box as="tr" bg={row.isInteresting ? `${color}Background` : 'transparent'}>
      <td>
        <Flex>
          <Box width="2ch" mr="4px">
            {row.rankNew && row.rankNew <= 10 ? row.rankNew : ''}
          </Box>
          <KeyRankIndicator data={row} />
        </Flex>
      </td>
      {sortBy(row.dimensions, ['label']).map(({ key: dimension, value }) => (
        <td key={dimension}>
          <Box>
            <DimensionToLink dimension={dimension} dimensionToKeyPart={row.dimensionToValue}>
              <Lookup lookup={dimension} value={value} />
            </DimensionToLink>
          </Box>
        </td>
      ))}
      <td>
        <Box whiteSpace="nowrap">
          <KeyTrafficIndicator percentage={row.percentage} factor={row.valueChangeFactor} />
          {Number.isFinite(row.percentage) && (
            <Text color={row.hasValueChange ? color : 'body'} fontWeight={row.hasValueChange ? 'bold' : 'normal'}>
              {(row.percentage * 100).toFixed(1)}%
            </Text>
          )}
        </Box>
      </td>
      {!compact && (
        <td>
          <Text color="muted" whiteSpace="nowrap">
            {row.formattedOldBps}
          </Text>
        </td>
      )}
      <td>
        <Text whiteSpace="nowrap">{row.formattedNewBps}</Text>
      </td>
    </Box>
  );
}

@inject('$insights', '$app')
class ComparisonInsightTable extends Component {
  render() {
    const { compact, insight, limitRows, $insights, $app } = this.props;
    const removedKeys = get(insight, 'comparison.oldTopKeys', []).filter(({ rankNew }) => !rankNew || rankNew > 10);
    const comparisons = $insights.getComparisons(insight).slice(0, limitRows);
    const removedComparisons = !limitRows ? $insights.getComparisons(insight, removedKeys) : [];

    return (
      <HTMLTable>
        <thead>
          <tr>
            <th>Rank</th>
            {sortBy(get(comparisons, '0.dimensions'), ['label']).map((dimension) => (
              <th key={dimension.label}>{get(dimension, 'label')}</th>
            ))}
            <th />
            {!compact && <th>Last week</th>}
            <th>This week</th>
          </tr>
        </thead>
        <tbody>
          {comparisons.map((key) => (
            <ComparisonRow row={key} key={key.rankNew} compact={compact} />
          ))}
        </tbody>
        <tbody>
          {removedComparisons.map((key) => (
            <ComparisonRow row={key} key={key.rankOld} compact={compact} />
          ))}
        </tbody>
        {!$app.isExport && limitRows && (
          <tbody>
            <tr>
              <td colSpan={compact ? 4 : 5}>
                <InsightLink id={insight.insightID}>See more</InsightLink>
              </td>
            </tr>
          </tbody>
        )}
      </HTMLTable>
    );
  }
}

export default ComparisonInsightTable;
