import styled, { css, keyframes } from 'styled-components';

const skeletonKeyframes = keyframes`
  0% {
    background-position: -300px 0;
  }
  100% {
    background-position: calc(100% + 300px) 0;
  }
`;

export const CardSkeleton = styled.div.attrs({ className: 'skeleton' })`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 12px;
`;

export const ProductSkeleton = styled.div.attrs({ className: 'skeleton' })`
  display: inline-block;
  height: ${(props) => props.height || '14px'};
  width: ${(props) => props.width || '80%'};
  animation: ${skeletonKeyframes} 1600ms linear infinite;
  background-color: rgba(0, 0, 0, 0.04);
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.04) 0%,
    rgba(245, 245, 245, 0.4) 50%,
    rgba(0, 0, 0, 0.04) 100%
  );
  background-size: 340px 100%;
  background-repeat: no-repeat;
  border-radius: ${(props) => props.borderRadius || '8px'};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  ${(props) =>
    (props.theme.name === 'dark' || props.forceDark) &&
    css`
      background-color: rgba(0, 0, 0, 0.2);
      background-image: linear-gradient(
        90deg,
        rgba(47, 47, 47, 0%) 0%,
        rgb(51, 51, 51) 20%,
        rgb(55, 55, 55) 40%,
        rgb(60, 60, 60) 50%,
        rgb(55, 55, 55) 60%,
        rgba(51, 51, 51, 91%) 80%,
        rgba(47, 47, 47, 0%) 100%
      );
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    `}
`;
