import React, { Component } from 'react';
import { inject } from 'mobx-react';

import { Box, Button, Callout, Dialog, Flex, Heading, Icon, Link, Spinner, Suspense, Text } from 'core/components';
import Card from 'core/components/Card';
import { Field, InputGroup, RadioGroup, Select, TextArea } from 'core/form';
import FormComponent from 'core/form/components/FormComponent';
import FormActions from 'core/form/components/FormActions';
import makeCancelable, { CanceledError } from 'core/util/cancelablePromise';
import ApplyParametricOptions from 'app/views/core/dashboards/ApplyParametricOptions';

import { BsCaretRightFill } from 'react-icons/bs';

import { contentMap, contentTypes, subscriptionSelectOptionFormatter } from 'app/util/subscription';

import { exportTypeSelectRenderer } from 'app/util/export';

import { fields, options } from 'app/forms/config/subscription';
import AlertingOverviewFilters from 'app/views/alerting/components/AlertingOverviewFilters';
import AlertingFilterSidebar from 'app/views/alerting/components/AlertingFilterSidebar';
import MitigationFilterSidebar from 'app/views/protect/mitigations/MitigationFilterSidebar';
import moment from 'moment';
import $subscriptions from 'app/stores/subscriptions/$subscriptions';

@inject('$auth', '$dashboards', '$exports')
export default class SubscriptionDetailsFormDialog extends Component {
  static defaultProps = {
    summary: false,
    showContentType: true,
    subscriptionSelectOptionRenderer: subscriptionSelectOptionFormatter,
    showManageSubscriptions: true,
    onClose: () => {}
  };

  state = {
    loading: true,
    isParametric: false,
    selectedContent: undefined,
    selectedContentType: undefined,
    initialContentMetaData: undefined,
    showNewTitleField: false
  };

  constructor(props) {
    super(props);

    const { model } = props;
    // default filename to title if not set
    if (model.get('title') && !model.get('content_metadata.filename')) {
      const content_metadata = model.get('content_metadata') || {};
      const sanitizedTitle = model
        .get('title')
        .replace(/[^\w\s./\\-]|[.](?![^.]*$)|[/\\]/g, '')
        .replace(/\s/g, '_')
        .trim()
        .substring(0, 200);
      content_metadata.filename = `YYYYMMDD-${sanitizedTitle}`;
      model.set('content_metadata', content_metadata);
    }
  }

  componentDidMount() {
    const { $auth, $dashboards, $exports, model, setModel, subscriptionDialogType } = this.props;
    const {
      activeUser: { user_email }
    } = $auth;

    const contentType = model.get('content_type');
    const contentId = model.get('content_id') || (model.get('content_metadata.values') || []).at(0);

    this.fetchContent(contentType);

    if (setModel) {
      this.subscriptionsOptions = [
        {
          value: 'CREATE_NEW',
          label: 'Create New',
          iconCls: 'plus'
        }
      ];

      const existingOptions = $subscriptions.getSubscriptionsOptions(
        (subscription) =>
          subscription.get('content_type') === contentType &&
          (subscription.isSummary ||
            subscription.get('content_id') === contentId ||
            (subscription.get('content_metadata.values') || []).at(0) === contentId) &&
          // either user wants to unsubscribe to something that he's subscribed to
          // or wants to subscribe to something that he's not subscribed to
          ((subscriptionDialogType !== 'unsubscribe' && !subscription.get('recipients').includes(user_email)) ||
            (subscriptionDialogType === 'unsubscribe' && subscription.get('recipients').includes(user_email)))
      );

      if (existingOptions.length) {
        if (subscriptionDialogType !== 'unsubscribe') {
          this.subscriptionsOptions.push({
            value: 'EDIT_EXISTING',
            disabled: true
          });
        }

        this.subscriptionsOptions.push(...existingOptions);
      }

      if (subscriptionDialogType === 'unsubscribe') {
        this.subscriptionsOptions = this.subscriptionsOptions.filter(({ value }) => value !== 'CREATE_NEW');
      }
    }

    if (contentType === 'dashboard' && contentId) {
      const selectedContent = $dashboards.collection.get(contentId);
      if (selectedContent) {
        return this.setState({
          isParametric: selectedContent.get('parametric'),
          selectedContent,
          selectedContentType: contentType
        });
      }
    }

    // if we load in an alerting model, the filter sidebar will have the right values
    // but the model itself will be completely unaware or set filters, so we populate them here
    if (model.get('content_type') === 'alerts' && !model.get('content_metadata.values')) {
      $exports.getSettings().then(({ hashedFilters }) => {
        const { startDate, endDate, ruleIds, ...restHashedFilters } = hashedFilters || {};

        if (hashedFilters) {
          // Make sure we convert hashed dates into moment objects
          if (typeof startDate === 'string' || typeof startDate === 'number') {
            restHashedFilters.startDate = moment(startDate);
          }
          if (typeof endDate === 'string' || typeof endDate === 'number') {
            restHashedFilters.endDate = moment(endDate);
          }

          // Prefer lookback if we have it
          if (restHashedFilters.lookback) {
            restHashedFilters.startDate = moment().subtract(restHashedFilters.lookback, 'seconds');
            restHashedFilters.endDate = moment();
          }

          model.set('content_metadata', { values: [{ ...restHashedFilters }] });

          this.setState({
            initialContentMetaData: model.get('content_metadata.values'),
            selectedContentType: contentType
          });
        } else {
          this.setState({
            selectedContentType: contentType
          });
        }
      });
    } else if (model.get('content_type') === 'alertOverview' && !model.get('content_metadata.values')) {
      $exports.getSettings().then(({ hashedFilters }) => {
        if (hashedFilters) {
          model.set('content_metadata', { values: [{ ...hashedFilters }] });

          this.setState({
            initialContentMetaData: model.get('content_metadata.values'),
            selectedContentType: contentType
          });
        } else {
          this.setState({
            selectedContentType: contentType
          });
        }
      });
    } else if (contentType) {
      this.setState({
        initialContentMetaData: model.get('content_metadata.values'),
        selectedContentType: contentType
      });
    }

    return null;
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.cancel();
    }
  }

  fetchContent = (contentType) => {
    if (!contentType) {
      this.request = makeCancelable(
        Promise.all([
          contentMap.dashboard.fetch(),
          contentMap.savedview.fetch(),
          contentMap.capacitysummary.fetch(),
          contentMap.costsummary.fetch()
        ])
      );
      this.request.promise
        .then(() => {
          this.setState({ loading: false });
        })
        .catch((err) => {
          if (!(err instanceof CanceledError)) {
            this.setState({ loading: false });
          }
        });
    } else {
      this.request = makeCancelable(contentMap[contentType].fetch?.() || Promise.resolve());
      this.request.promise
        .then(() => this.setState({ loading: false }))
        .catch((err) => {
          if (!(err instanceof CanceledError)) {
            this.setState({ loading: false });
          }
        });
    }
  };

  resetRecurrenceOrdinal = (field, deliveryPeriod) => {
    const recurrenceOrdinal = deliveryPeriod === 'day' || deliveryPeriod === 'month' ? 1 : 0;
    field.form.setValue('recurrence_ordinal', recurrenceOrdinal);
  };

  handleContentSelect = (field, selectedContentId) => {
    const { $dashboards } = this.props;
    const { form } = field;
    const selectedContent = $dashboards.collection.get(selectedContentId);

    const isParametric = selectedContent && selectedContent.get('parametric');

    if (isParametric) {
      const parametric_fields = selectedContent.get('parametric_fields');
      form.setValue('parametric_fields', parametric_fields);
    } else {
      form.setValue('parametric_fields', []);
    }

    this.setState({ isParametric, selectedContent });
  };

  handleContentTypeSelect = (field, content_type) => {
    const { form } = field;
    form.setValue('content_id', null);
    form.setValue('content_metadata.values', null);
    form.setValue('parametric_fields', []);
    form.setValue('content_type', content_type);
    this.setState({
      selectedContentType: content_type,
      isParametric: false,
      selectedContent: undefined
    });
  };

  handleAlertingFilterChange = (form) => (filters) => {
    form.setValue('content_metadata.values', [{ ...filters }]);
  };

  handleSubscriptionSelection = (field, id) => {
    const { form } = field;
    const { $auth, subscriptionDialogType, setModel } = this.props;
    const { initialContentMetaData } = this.state;

    const {
      activeUser: { user_email }
    } = $auth;

    if (id === 'CREATE_NEW') {
      if (initialContentMetaData) {
        form.setValue('content_metadata.values', initialContentMetaData || []);
      }

      setModel(
        $subscriptions.collection.forge({
          content_type: form.getValue('content_type'),
          content_id: form.getValue('content_id'),
          content_metadata: { values: form.getValue('content_metadata.values') },
          recipients: user_email
        })
      );

      return this.setState({ showNewTitleField: true });
    }

    if (id === 'EDIT_EXISTING') {
      return null;
    }

    const model = $subscriptions.collection.get(id);

    if ($auth.isAdministrator && model) {
      form.setModel(model);

      const recipients = form
        .getField('recipients')
        .getValue()
        .split(',')
        .map((recipient) => recipient.trim());

      if (!recipients.includes(user_email) && subscriptionDialogType !== 'unsubscribe') {
        recipients.push(user_email);
        form.getField('recipients').setValue(recipients.join(','));
      }
    }

    return setModel(model);
  };

  handleSubscribe = ({ id, formData }) => {
    const { subscriptionDialogType } = this.props;

    if (subscriptionDialogType === 'subscribe') {
      if (id) {
        return $subscriptions.subscribeToExisting(id);
      }

      return $subscriptions.subscribeToNew(formData);
    }

    if (subscriptionDialogType === 'unsubscribe') {
      return $subscriptions.unsubscribe(id);
    }

    return Promise.resolve();
  };

  handleCancelAdd = (form) => {
    const { $auth } = this.props;
    const {
      activeUser: { user_email }
    } = $auth;
    form.reset();
    form.getField('recipients').setValue(user_email);
    this.setState({ showNewTitleField: false });
  };

  render() {
    const {
      $auth,
      model,
      setModel,
      subscriptionDialogType,
      showManageSubscriptions,
      showContentType,
      subscriptionSelectOptionRenderer,
      onClose
    } = this.props;
    const { isParametric, selectedContent, selectedContentType, showNewTitleField, loading } = this.state;
    const showContentOptions = !!contentMap[selectedContentType]?.contentOptions;
    const contentTypeOptions = setModel
      ? contentTypes.filter(({ value }) => contentMap[selectedContentType]?.associatedContentTypes.includes(value))
      : contentTypes;
    const showRecipients =
      subscriptionDialogType === 'share' ||
      (subscriptionDialogType === 'subscribe' && $auth.isAdministrator) ||
      (subscriptionDialogType === 'unsubscribe' && $auth.isAdministrator);
    const disabled = subscriptionDialogType === 'unsubscribe';

    return (
      <FormComponent
        fields={fields}
        options={options}
        model={model}
        onSubmit={
          (!$auth.isAdministrator && subscriptionDialogType === 'subscribe') || subscriptionDialogType === 'unsubscribe'
            ? (form, values) =>
                this.handleSubscribe({
                  id: model.get('id'),
                  formData: values
                }).then(() => onClose())
            : undefined
        }
        large
      >
        {(formProps) => (
          <Dialog.Body minHeight={390}>
            <Suspense loading={loading} fallback={<Spinner />}>
              {showManageSubscriptions && (
                <Flex justifyContent="flex-end">
                  <Link small to="/v4/settings/subscriptions">
                    Manage Subscriptions
                    <Icon icon={BsCaretRightFill} iconSize={12} color="primary" />
                  </Link>
                </Flex>
              )}

              {selectedContentType === 'peering' && (
                <>
                  <Callout mb={2}>
                    <Heading level={6}>Peering Subscription Details</Heading>
                    <Text as="div" fontWeight="bold" fontSize="small">
                      <Text muted>Title</Text> {model.get('title')}
                    </Text>
                    <Text as="div" fontWeight="bold" fontSize="small">
                      <Text muted>Frequency</Text> {model.deliveryFrequency}
                    </Text>
                    <Text as="div" fontWeight="bold" fontSize="small">
                      <Text muted>Lookback</Text> {model.lookbackDays}
                    </Text>
                  </Callout>
                  <Field name="recipients">
                    <TextArea fill />
                  </Field>
                </>
              )}

              {selectedContentType !== 'peering' && (
                <>
                  {setModel && !showNewTitleField ? (
                    <Field
                      name="id"
                      options={this.subscriptionsOptions}
                      onChange={this.handleSubscriptionSelection}
                      placeholder={disabled ? 'Select' : undefined}
                    >
                      <Select fill optionFormatter={subscriptionSelectOptionRenderer} />
                    </Field>
                  ) : (
                    <Flex width="100%" justifyContent="space-between">
                      <Field flex={1} name="title" disabled={disabled}>
                        <InputGroup />
                      </Field>
                      {showNewTitleField && (
                        <Button
                          mt="19px"
                          minHeight="28px"
                          height="28px"
                          onClick={() => this.handleCancelAdd(formProps.form)}
                        >
                          Cancel
                        </Button>
                      )}
                    </Flex>
                  )}
                  <Box mt={showContentOptions ? 2 : 0} mb={showContentOptions ? 3 : 0}>
                    {showContentType && (
                      <Field
                        name="content_type"
                        options={contentTypeOptions}
                        onChange={this.handleContentTypeSelect}
                        disabled={disabled}
                      >
                        <Select fill />
                      </Field>
                    )}
                    {showContentOptions && (
                      <Field
                        label={`Selected ${contentMap[selectedContentType]?.selectLabel || 'Content'}`}
                        key={
                          contentMap[selectedContentType]?.isMultiSelectable ? 'content_metadata.values' : 'content_id'
                        }
                        name={
                          contentMap[selectedContentType]?.isMultiSelectable ? 'content_metadata.values' : 'content_id'
                        }
                        options={contentMap[selectedContentType]?.contentOptions()}
                        onChange={this.handleContentSelect}
                        disabled={disabled}
                      >
                        <Select fill showFilter multi={contentMap[selectedContentType]?.isMultiSelectable} />
                      </Field>
                    )}
                  </Box>
                  {isParametric && selectedContent && (
                    <ApplyParametricOptions dashboard={selectedContent}>
                      {({ inputField, question }) => (
                        <Callout icon={null} intent="primary" mb={2}>
                          <Text as="h6" fontSize={12} mt={0} mb="12px" textTransform="uppercase" muted>
                            <Box>
                              <Icon icon="chat" color="primary" mr={1} iconSize={16} />
                              <Text color="primary">{question}</Text>
                            </Box>
                          </Text>

                          {React.cloneElement(inputField, { rules: ['required'] })}
                        </Callout>
                      )}
                    </ApplyParametricOptions>
                  )}
                  <Flex>
                    <Field name="content_metadata.filename" disabled={disabled} width={500} mr={2} defaultValue="TEST">
                      <InputGroup />
                    </Field>
                    <Field
                      name="export_type"
                      options={contentMap[selectedContentType]?.exportOptions}
                      disabled={disabled}
                    >
                      <Select style={{ width: 210 }} valueRenderer={exportTypeSelectRenderer} width={210} fill />
                    </Field>
                  </Flex>
                  {showRecipients && (
                    <>
                      <Field name="recipients" disabled={disabled}>
                        <TextArea fill />
                      </Field>
                      <Field name="content_metadata.cc" disabled={disabled}>
                        <TextArea fill />
                      </Field>
                      <Field name="content_metadata.bcc" disabled={disabled}>
                        <TextArea fill />
                      </Field>
                    </>
                  )}
                  <Flex>
                    <Field name="delivery_period" onChange={this.resetRecurrenceOrdinal} mr={2} disabled={disabled}>
                      <Select menuWidth={200} width={200} />
                    </Field>
                    {(formProps.form.getValue('delivery_period') === 'week' ||
                      formProps.form.getValue('delivery_period') === 'month') && (
                      <Field
                        name="recurrence_ordinal"
                        mr={2}
                        options={model.recurrenceOrdinalOptions(formProps.form.getValue('delivery_period'))}
                        disabled={disabled}
                      >
                        <Select menuWidth={100} width={100} />
                      </Field>
                    )}
                    <Field name="recurrence_time" mr={2} disabled={disabled}>
                      <Select menuWidth={200} width={200} />
                    </Field>
                  </Flex>
                  {contentMap[selectedContentType]?.lookbackDays && (
                    <>
                      <Field name="lookback_period" diabled={disabled} mb={0}>
                        <RadioGroup inline />
                      </Field>
                      {formProps.form.getValue('lookback_period') === 'days' && (
                        <Field name="lookback_days" mr={2} disabled={disabled} showLabel={false} ml={2}>
                          <InputGroup type="number" leftIcon="time" min={1} max={90} width={65} />
                        </Field>
                      )}
                    </>
                  )}
                  {(model.get('content_type') === 'alerts' || formProps.form.getValue('content_type') === 'alerts') && (
                    <Card overflow="hidden" mt={1}>
                      <AlertingFilterSidebar
                        title="Alerting Filters"
                        readOnly={subscriptionDialogType === 'unsubscribe'}
                        lookbackOnly
                        resetFiltersOnClear
                        filterValues={(formProps.form.getValue('content_metadata.values') || [])[0]}
                        updateHashOnFilterChange={false}
                        onChange={this.handleAlertingFilterChange(formProps.form)}
                      />
                    </Card>
                  )}
                  {(model.get('content_type') === 'alertOverview' ||
                    formProps.form.getValue('content_type') === 'alertOverview') && (
                    <AlertingOverviewFilters
                      resetFiltersOnClear
                      readOnly={subscriptionDialogType === 'unsubscribe'}
                      filterValues={(formProps.form.getValue('content_metadata.values') || [])[0]}
                      updateHashOnFilterChange={false}
                      onChange={this.handleAlertingFilterChange(formProps.form)}
                    />
                  )}
                  {(model.get('content_type') === 'mitigations' ||
                    formProps.form.getValue('content_type') === 'mitigations') && (
                    <MitigationFilterSidebar
                      lookbackOnly
                      resetFiltersOnClear
                      readOnly={subscriptionDialogType === 'unsubscribe'}
                      filterValues={(formProps.form.getValue('content_metadata.values') || [])[0]}
                      updateHashOnFilterChange={false}
                      onChange={this.handleAlertingFilterChange(formProps.form)}
                    />
                  )}
                </>
              )}
              <Dialog.Footer>
                <FormActions
                  entityName={options.name}
                  submitButtonText={
                    { subscribe: 'Subscribe', unsubscribe: 'Unsubscribe' }[subscriptionDialogType] || 'Share'
                  }
                  submitButtonProps={{
                    tracker: `share-subscription-${formProps.form.getValue('content_type')}-${formProps.form.getValue(
                      'export_type'
                    )}`
                  }}
                  onCancel={onClose}
                  {...formProps}
                  large={false}
                />
              </Dialog.Footer>
            </Suspense>
          </Dialog.Body>
        )}
      </FormComponent>
    );
  }
}
