import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { isEqual } from 'lodash';
import Model from 'core/model/Model';
import { Flex } from 'core/components';
import AlarmsTimeSeries from 'app/views/synthetics/components/AlarmsTimeSeries';
import { aggregateAlertManagerAlarmsIntoTimeslots } from 'app/views/synthetics/utils/syntheticsUtils';
import { DEFAULT_WIDGET_CONFIG } from 'app/views/synthetics/components/incidentLogs/constants';
import IncidentLogForm from './IncidentLogForm';
import IncidentLogSummary from './IncidentLogSummary';
import IncidentLogTable from './IncidentLogTable';

const filterAlarms = ({ $syn, alarms = [], filters = {} }) => {
  const {
    test_types = [],
    private_agents = [],
    global_agents = [],
    labels = [],
    test_ids = [],
    severity,
    state
  } = filters;
  const tests = $syn.tests.get().map((test) => ({
    ...test.get(),
    labels: test.labels
  }));
  const agentIds = [...private_agents, ...global_agents];

  // loop over tests
  // check test type
  // check agents
  // check labels
  // check ids
  const filterIds = tests.reduce(
    (acc, test) => {
      let remove = false;

      if (!!test_types.length && !remove) {
        remove = !test_types.includes(test.test_type);
      }

      if (!!agentIds.length && !remove) {
        remove = !test.config.agents.some((id) => agentIds.includes(id));
      }

      if (!!labels.length && !remove) {
        remove = !test.labels?.some((label) => labels.includes(label?.get('id')));
      }

      if (!!test_ids.length && !remove) {
        remove = !test_ids.includes(test.id);
      }

      test.config.policies.forEach(({ id }) => {
        acc.policyIds[id] = remove;
      });

      acc.testIds[test.id] = remove;

      return acc;
    },
    { policyIds: {}, testIds: {} }
  );

  // loop over alarms
  // check severity
  // check state
  // check policy id
  return alarms.filter((alarm) => {
    let remove = false;

    if (severity !== 'all' && !remove) {
      remove = alarm.severity !== severity;
    }

    if (state !== 'all' && !remove) {
      remove = alarm.state !== state;
    }

    if (!remove) {
      // also check by test id to accommodate v2 alarms
      remove = filterIds.policyIds[alarm.policyID] || filterIds.testIds[alarm?.key?.value?.testId];
    }

    return !remove;
  });
};

@inject('$syn')
@observer
export default class IncidentLog extends Component {
  static defaultProps = {
    alarms: null,
    width: 0,
    model: new Model(DEFAULT_WIDGET_CONFIG),
    minimal: false,
    minimalTable: true,
    onCancel: () => null,
    onSave: () => null
  };

  state = {
    resultTimeMs: undefined,
    activeTimeslot: undefined
  };

  static getDerivedStateFromProps(props, state) {
    const { $syn, alarms = [], model } = props;
    const modelChanged = !isEqual(model.toJS(), state.model?.toJS());

    if (modelChanged) {
      const { lookbackSeconds, startDate, endDate } = model.get('selected_time', {});

      return {
        model,
        alarms: aggregateAlertManagerAlarmsIntoTimeslots({
          alarms: filterAlarms({ $syn, alarms, filters: model.toJS() }),
          lookbackSeconds,
          startDate,
          endDate
        })
      };
    }

    return null;
  }

  handleTimelineSelect = (resultTimeMs) => {
    const { alarms } = this.state;
    const activeTimeslot = Object.values(alarms.timeslots).find((test) => test.time === resultTimeMs / 1000);
    const activeAlarms = alarms.alarms.filter((alarm) => activeTimeslot.alarms.includes(alarm.id));

    this.setState({
      resultTimeMs,
      activeTimeslot: activeAlarms.length > 0 ? { ...activeTimeslot, alarms: activeAlarms } : null
    });
  };

  get boxProps() {
    const { $syn, alarms, model, width, minimal, minimalTable, onCancel, onSave, ...boxProps } = this.props;
    return boxProps;
  }

  render() {
    const { width, minimal, minimalTable, onCancel, onSave } = this.props;
    const { model, alarms, activeTimeslot, resultTimeMs } = this.state;
    const selectedTime = model.get('selected_time', {});

    return (
      <Flex flexDirection="column" {...this.boxProps}>
        {minimal && <IncidentLogForm model={model} minimal={minimal} onCancel={onCancel} onSave={onSave} />}
        <Flex px={2} pt={2}>
          <AlarmsTimeSeries
            alarms={alarms}
            onTimelineSelect={this.handleTimelineSelect}
            lookbackSeconds={selectedTime.lookbackSeconds}
            approxWidth={width}
            startDate={selectedTime.startDate}
            endDate={selectedTime.endDate}
            stackedSeries
          />
        </Flex>
        <IncidentLogSummary activeTimeslot={activeTimeslot} resultTimeMs={resultTimeMs} width={width} />
        <IncidentLogTable
          flex={1}
          activeTimeslot={activeTimeslot}
          resultTimeMs={resultTimeMs}
          minimal={minimal && minimalTable}
        />
      </Flex>
    );
  }
}
