import { action, computed, observable } from 'mobx';
import { flatMap } from 'lodash';

import { getFieldSelectOptions, getFieldValues, getFilteredOptions, getFilteredOptionsLegacy } from './selectHelpers';

export default class SelectState {
  @observable
  filterValue;

  @observable.ref
  filteredOptions = [];

  @observable.ref
  flatFilteredOptions = [];

  // Things that are passed in that we need to reference
  field;

  multi;

  onChange;

  onEditComplete;

  onSave;

  options;

  // Internal State non-observed
  lastOpenedValue;

  useLegacyOptionsFormat;

  constructor({ field, multi, onChange, onEditComplete, onSave, options, useLegacyOptionsFormat }) {
    this.field = field;
    this.multi = multi;
    this.onChange = onChange;
    this.onEditComplete = onEditComplete;
    this.onSave = onSave;
    this.options = options;
    this.useLegacyOptionsFormat = useLegacyOptionsFormat || false;

    this.setFilterValue('');
  }

  @computed
  get values() {
    return getFieldValues({ field: this.field });
  }

  getFilteredOptions(value, options) {
    return (this.useLegacyOptionsFormat ? getFilteredOptionsLegacy : getFilteredOptions)(value, options);
  }

  @action
  setFilterValue(value) {
    this.filterValue = value;

    const options = getFieldSelectOptions({ field: this.field, options: this.options });
    this.filteredOptions = this.getFilteredOptions(value, options);

    this.flatFilteredOptions = flatMap(this.filteredOptions);
  }

  initLastOpenedValue() {
    this.lastOpenedValue = this.field.getValue();
  }

  resetFieldValue() {
    this.field.setValue(this.lastOpenedValue);
  }

  openOptions = () => {
    this.onEditComplete();
    this.field.setPristine(false);
    this.initLastOpenedValue();
  };

  saveOptions = () => {
    if (this.onSave) {
      this.onSave(this.field.getValue());
    }

    this.closeOptions();
  };

  closeOptions = () => {
    this.onEditComplete();
  };

  resetToLastOpened = () => {
    this.resetFieldValue();
    this.closeOptions();
  };

  selectItem = (field, value) => {
    if (this.multi) {
      let nextValues = this.values;
      const idx = nextValues.indexOf(value);

      if (idx === -1) {
        nextValues = nextValues.concat(value);
      } else {
        nextValues = nextValues.slice(0, idx).concat(nextValues.slice(idx + 1));
      }

      this.onChange(nextValues);
    } else {
      this.onChange(value);
      this.closeOptions();
    }
  };

  selectItems = (field, values) => {
    const nextValues = this.values;

    values.forEach((value) => {
      if (!nextValues.includes(value)) {
        nextValues.push(value);
      }
    });

    this.onChange(nextValues);
  };

  unselectItems = (field, values) => {
    const nextValues = this.values;

    values.forEach((value) => {
      const idx = nextValues.indexOf(value);
      if (idx >= 0) {
        nextValues.splice(idx, 1);
      }
    });

    this.onChange(nextValues);
  };
}
