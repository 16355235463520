import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Box } from 'core/components';

import AlertingSiteHealthTable from './components/AlertingSiteHealthTable';
import AlertingSiteHealthMap from './components/healthMap';

@inject('$metrics')
@withRouter
@observer
class AlertingSiteHealthWrapper extends Component {
  handleNavigateToSiteFilterView = (siteId) => {
    const { history, lookback } = this.props;
    const LOOKBACK_90_DAYS = 3600 * 24 * 90;
    const filters = {
      lookback: lookback > LOOKBACK_90_DAYS ? LOOKBACK_90_DAYS : lookback || 3600, // default to last hour
      // Active Now = anything currently in alarm
      // X Lookback = anything active within last window
      states: lookback > 0 ? ['clear', 'alarm'] : ['alarm'],
      groupBy: 'severity',
      sites: [siteId]
    };

    history.push('/v4/alerting/', { filters });
  };

  render() {
    const { maxHeight, minHeight, collection, groupBy, loading } = this.props;

    return (
      <Box display="flex" height="100%" overflow="hidden" maxHeight={maxHeight} minHeight={minHeight}>
        <Box flex={2} overflow="hidden" borderRight="thin">
          <AlertingSiteHealthMap
            collection={collection}
            fetchStatus={`${collection.lastFetched}-${collection.requestStatus}`}
            navigateToSiteFilterView={this.handleNavigateToSiteFilterView}
          />
        </Box>
        <Box minWidth="min-content" flex={1} style={{ overflowX: 'auto', overflowY: 'scroll' }} p={1}>
          <AlertingSiteHealthTable
            loading={loading}
            collection={collection}
            groupBy={groupBy}
            navigateToSiteFilterView={this.handleNavigateToSiteFilterView}
          />
        </Box>
      </Box>
    );
  }
}

export default AlertingSiteHealthWrapper;
