import React, { Component } from 'react';
import { SizeMe } from 'react-sizeme';
import { inject, observer } from 'mobx-react';
import { isEmpty, isEqual } from 'lodash';

import storeLoader from 'app/stores/storeLoader';
import Model from 'core/model/Model';
import {
  Box,
  Button,
  Checkbox,
  EmptyState,
  Flex,
  Heading,
  Link,
  MenuItem,
  Spinner,
  Suspense,
  Text
} from 'core/components';
import { FormComponent } from 'core/form';
import SelectedValuesPanel from 'core/form/components/modalSelect/SelectedValuesPanel';
import { MAX_DETAIL_TABS } from 'app/util/constants';
import AgentMesh from 'app/views/synthetics/components/mesh/AgentMesh';
import WidgetFrame from '../WidgetFrame';

const FORM_OPTIONS = {
  name: 'Synthetic Meshes Widget Config'
};

const valueRenderer = ({ option, onVisibleChange, selectedTabs, maxSelected }) => (
  <Checkbox
    label={option.selectorLabel || option.label}
    checked={option.visible}
    onChange={(checked) => onVisibleChange(option.value, checked)}
    disabled={!option.visible && selectedTabs?.length >= maxSelected}
  />
);

const getSortedMeshTestOptions = (tests, config) => {
  const testOptions = tests.map((model) => ({
    id: model.id,
    value: model.id,
    label: model.get('display_name'),
    visible: config.selected_tests_ids?.includes(model.id)
  }));
  // If it has saved sort order, rehydrated the order
  if (!isEmpty(config.tests_order)) {
    // First remove any stale tests from config
    const testOptionsId = testOptions.map((option) => option.id);
    const order = config.tests_order.filter((id) => testOptionsId.includes(id));
    // Sort Test Options by saved order
    testOptions.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
  }
  return testOptions;
};

@storeLoader('$syn.tests', '$syn.agents')
@inject('$syn')
@observer
class MeshesOverviewWidget extends Component {
  state = {
    isConfigurePanelOpen: false,
    config: null,
    loading: true,
    testsOrder: [],
    testOptions: [],
    selectedTestsIds: []
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  dragging = false;

  get hasSelectedTests() {
    const { config } = this.state;
    return !isEmpty(config.selected_tests_ids);
  }

  static getDerivedStateFromProps(props, state) {
    const { $syn, config, canCustomize } = props;
    // If there's no config in state, let's get setup
    if (!state.config) {
      // If there's no config, we show the config panel
      if (!config) {
        return { config: {}, isConfigurePanelOpen: canCustomize, formModel: new Model() };
      }
      return { config, formModel: new Model(config) };
    }

    if (!props.loading && state.loading) {
      return {
        testOptions: getSortedMeshTestOptions($syn.tests.meshTests, state.config),
        selectedTestsIds: state.config?.selected_tests_ids ?? [],
        loading: false
      };
    }
    return null;
  }

  componentDidMount() {
    const { $syn, canCustomize } = this.props;
    const { config } = this.state;

    if (this.hasSelectedTests) {
      $syn.tests.loadLatestTestResults(config.selected_tests_ids);
    }

    if (!this.hasSelectedTests && !canCustomize) {
      this.updateTestSelection(config);
    }
  }

  componentDidUpdate(prevProp, prevState) {
    const { $syn } = this.props;
    const { config, loading } = this.state;
    if ((!loading && prevState.loading) || !isEqual(prevState.config.selected_tests_ids, config.selected_tests_ids)) {
      $syn.tests.loadLatestTestResults(config.selected_tests_ids);
    }
  }

  updateTestSelection = (config) => {
    const { $syn } = this.props;
    let selectedIds;
    if (isEmpty(config?.selected_tests_ids)) {
      const meshTestOptions = getSortedMeshTestOptions($syn.tests.meshTests, config);
      const defaultSelectedOptions = meshTestOptions.filter((option, index) => [0, 1].includes(index));
      const defaultSelectedOptionIds = defaultSelectedOptions.map((option) => option.id);
      selectedIds = defaultSelectedOptionIds;
    } else {
      selectedIds = config.selected_tests_ids;
    }
    this.setState({
      config: { ...config, selected_tests_ids: selectedIds },
      selectedTestsIds: selectedIds,
      testOptions: getSortedMeshTestOptions($syn.tests.meshTests, { ...config, selected_tests_ids: selectedIds })
    });
  };

  handleShowConfigurePanel = () => {
    this.setState({ isConfigurePanelOpen: true });
  };

  handleSortDragStart = () => {
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.dragging = true;
  };

  handleSortChange = (values) => {
    const { testOptions, selectedTestsIds } = this.state;
    const sortedTestOptions = testOptions.sort((a, b) => values.indexOf(a.id) - values.indexOf(b.id));
    const sortedSelectedTestsIds = selectedTestsIds.sort((a, b) => values.indexOf(a) - values.indexOf(b));
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.dragging = false;
    this.setState({ testsOrder: values, testOptions: sortedTestOptions, selectedTestsIds: sortedSelectedTestsIds });
  };

  handleToggleTestSelection = (testId, checked) => {
    const { testOptions, selectedTestsIds } = this.state;
    const optionsVisibleUpdates = testOptions.map((option) => ({
      ...option,
      visible: testId === option.id ? checked : option.visible
    }));
    const _selectedTestsIds = checked ? [...selectedTestsIds, testId] : selectedTestsIds.filter((id) => id !== testId);
    this.setState({
      testOptions: optionsVisibleUpdates,
      selectedTestsIds: _selectedTestsIds
    });
  };

  handleCancel = (form) => {
    form.reset();
    const { $syn } = this.props;
    const { config } = this.state;
    const { tests_order, selected_tests_ids } = config;
    this.setState({
      isConfigurePanelOpen: false,
      testsOrder: tests_order,
      testOptions: getSortedMeshTestOptions($syn.tests.meshTests, config),
      selectedTestsIds: selected_tests_ids
    });
  };

  handleSave = () => {
    const { onConfigChange } = this.props;
    const { testsOrder, selectedTestsIds } = this.state;
    const config = { tests_order: testsOrder, selected_tests_ids: selectedTestsIds };
    this.setState({ isConfigurePanelOpen: false, config });
    if (onConfigChange) {
      onConfigChange(config);
    }
  };

  renderMenuItems() {
    return <MenuItem icon="cog" text="Configure" onClick={this.handleShowConfigurePanel} />;
  }

  renderConfigurePanel() {
    const { testOptions, formModel } = this.state;
    const columnCount = testOptions && Math.ceil(testOptions.length / 30);

    const fields = {
      tests_order: [],
      selected_tests_ids: []
    };

    const title = (
      <Box mb={2}>
        <Text as="div" fontWeight="heavy" textTransform="capitalize" mb={1}>
          Customize Meshes
        </Text>
        <Text as="div" fontWeight="normal" small muted mr={2}>
          Choose Meshes you want visible on this widget. A maximum {MAX_DETAIL_TABS} Meshes may be visible at once.
        </Text>
      </Box>
    );

    return (
      <FormComponent fields={fields} options={FORM_OPTIONS} model={formModel}>
        {({ form }) => (
          <Flex flexDirection="column" flex={1} p={2}>
            <Box flex={1} mb={2}>
              <SelectedValuesPanel
                selectedValuesTitle={title}
                values={testOptions.map((tab) => tab.value)}
                options={testOptions}
                valueRenderer={valueRenderer}
                onSortStart={this.handleSortDragStart}
                onChange={this.handleSortChange}
                onVisibleChange={this.handleToggleTestSelection}
                valuesItemStyle={{ columnCount }}
                maxSelected={MAX_DETAIL_TABS}
                reorderable
              />
            </Box>
            <Flex justifyContent="flex-end">
              {this.hasSelectedTests && (
                <Button text="Cancel" onClick={() => this.handleCancel(form)} mr={1} minWidth={100} />
              )}
              <Button
                text="Save"
                intent="primary"
                disabled={!form.valid}
                onClick={() => this.handleSave(form)}
                minWidth={100}
              />
            </Flex>
          </Flex>
        )}
      </FormComponent>
    );
  }

  renderContent() {
    const { $syn, widgetRef } = this.props;
    const { selectedTestsIds, loading } = this.state;
    const selectedMeshTests = selectedTestsIds?.map((id) => $syn.tests.get(id)).filter((model) => model);

    if (loading) {
      return null;
    }

    if (isEmpty(selectedMeshTests)) {
      return (
        <Flex justifyContent="space-between" pr={1} py={1}>
          <Box p={3}>No selected tests found</Box>
        </Flex>
      );
    }

    return (
      <SizeMe monitorWidth monitorHeight noPlaceholder>
        {({ size }) =>
          selectedMeshTests.length > 0 && (
            <Flex alignItems="flex-start" size={size} flexWrap="wrap" p={2} ref={widgetRef}>
              {selectedMeshTests.map((test) => {
                const hasMeshResults = test?.mesh?.length > 0;

                const heading = (
                  <Heading level={5} mt={1} mb={0}>
                    <Link to={`/v4/synthetics/tests/${test.id}/results`} fontWeight="bold">
                      {test.get('display_name')}
                    </Link>
                  </Heading>
                );

                if (!hasMeshResults) {
                  return (
                    <Box key={test.id} m="0 auto">
                      {heading}
                      <EmptyState
                        icon="disable"
                        title="No results"
                        description="Results are not currently available for this time range or this test no longer exists."
                      />
                    </Box>
                  );
                }

                return (
                  <AgentMesh
                    heading={heading}
                    resultTimeMs={test.latestResultTimeMs}
                    data={test.mesh}
                    test={test}
                    test_id={test.get('id')}
                    key={test.id}
                    mr="50px"
                    mt={1}
                    mb={4}
                  />
                );
              })}
            </Flex>
          )
        }
      </SizeMe>
    );
  }

  render() {
    const { $syn, canCustomize, onRemove } = this.props;
    const { isConfigurePanelOpen, loading } = this.state;

    return (
      <WidgetFrame
        canCustomize={canCustomize}
        menuOptions={!canCustomize && this.renderMenuItems()}
        display="flex"
        justifyContent="center"
        configAction={this.handleShowConfigurePanel}
        onRemove={onRemove}
        overflow="hidden"
        title="Meshes"
      >
        <Suspense
          loading={loading || $syn.tests.latestResultsLoading}
          fallback={
            <Box pt={2}>
              <Spinner size={24} />
            </Box>
          }
        >
          <Box width="100%" overflow="auto">
            {isConfigurePanelOpen && this.renderConfigurePanel()}
            {!isConfigurePanelOpen && this.renderContent()}
          </Box>
        </Suspense>
      </WidgetFrame>
    );
  }
}

export default MeshesOverviewWidget;
