import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FiDollarSign } from 'react-icons/fi';

import { Box, Text } from 'core/components';
import SidebarCard from 'app/components/landing/SidebarCard';
import ModuleLinkButton from 'app/components/landing/ModuleLinkButton';
import { greekIt, intlCurrency } from 'app/util/utils';

const costBaseRoute = '/v4/edge/costs';

@inject('$cost')
@withRouter
class CostsCard extends Component {
  state = {
    isFetching: true
  };

  componentDidMount() {
    const { $cost } = this.props;

    if (!$cost.canViewCosts) {
      this.setState({ isFetching: false });
    } else {
      $cost.fetchHistory({ summaryOnly: true }).then(() => {
        this.setState({ isFetching: false });
      });
    }
  }

  render() {
    const { $cost } = this.props;
    const { isFetching } = this.state;
    const { adjusted: ingressAdjusted, unitsLabel: ingressUnitsLabel } = greekIt($cost.currentMonthSummary.ingress, {
      suffix: 'bits/s'
    });
    const { adjusted: egressAdjusted, unitsLabel: egressUnitsLabel } = greekIt($cost.currentMonthSummary.egress, {
      suffix: 'bits/s'
    });

    if (!$cost.canViewCosts) {
      return null;
    }

    return (
      <SidebarCard
        icon={FiDollarSign}
        title="Connectivity Costs"
        isConfigured={$cost.providers.size > 0}
        loading={isFetching}
        to={costBaseRoute}
        notConfiguredText="You have not setup cost providers"
        mb={2}
      >
        <Box mb="4px">
          <Text fontWeight="bold">
            {intlCurrency($cost.currentMonthSummary.cost, $cost.currentMonthSummary.currency)}
          </Text>{' '}
          estimated cost
        </Box>
        <Box mb="4px">
          <Text fontWeight="bold">{ingressAdjusted.toFixed(0)}</Text> {ingressUnitsLabel} ingress
        </Box>
        <Box mb="4px">
          <Text fontWeight="bold">{egressAdjusted.toFixed(0)}</Text> {egressUnitsLabel} egress
        </Box>
        <ModuleLinkButton mt={1} text="Connectivity Costs" to={costBaseRoute} />
      </SidebarCard>
    );
  }
}

export default CostsCard;
