import React from 'react';
import { inject } from 'mobx-react';
import { LinkButton, Link } from 'core/components';

const CapacityPlanLink = ({ $app, as, children = 'View Details', id, ...rest }) => {
  const isButton = as === 'Button';
  const to = `/v4/core/capacity/${id}`;

  if ($app.isSubtenant) {
    return children;
  }

  if (isButton) {
    return (
      <LinkButton to={to} {...rest}>
        {children}
      </LinkButton>
    );
  }

  return (
    <Link to={to} {...rest}>
      {children}
    </Link>
  );
};

export default inject('$app')(CapacityPlanLink);
