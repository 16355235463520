const moment = require('moment');
const {
  ALARM_NULL_END_DATE,
  ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY,
  ALARM_STATE_RAW_TO_KEY,
  ALARM_STATE_KEY_TO_RAW,
  SEVERITY_MAP: SEVERITY_MAP_CONST
} = require('@kentik/ui-shared/alerting/constants');
const { HEALTH, HEALTH_SORT, DEFAULT_LOOKBACK_OPTIONS } = require('@kentik/ui-shared/synthetics/constants');

// this bandage will go when the 'makeAlertManagerAlarm' bandage goes
const SEVERITY_MAP = {
  [HEALTH.CRITICAL]: SEVERITY_MAP_CONST.CRITICAL.ALERT_MANAGER,
  [HEALTH.WARNING]: SEVERITY_MAP_CONST.MAJOR.ALERT_MANAGER, // v2 alarms at warning level come back as major
  major: SEVERITY_MAP_CONST.MAJOR.ALERT_MANAGER
};

/*
  Given a 'legacy' alarm, converts into an alert manager alarm shape
*/
function makeAlertManagerAlarm(legacyAlarm) {
  // first check if it's a v2 alarm as everyone is swimming the same bucket right now
  if (legacyAlarm?.key?.value) {
    return legacyAlarm;
  }

  return {
    ...legacyAlarm,
    start_time: legacyAlarm.startTime,
    end_time: legacyAlarm.endTime,
    severity: SEVERITY_MAP[legacyAlarm.severity] || HEALTH.HEALTHY,
    state: legacyAlarm.state === 'alarm' ? 'ALARM_STATE_ACTIVE' : 'ALARM_STATE_CLEAR',
    key: {
      value: {
        ...(legacyAlarm?.dimensionToKeyPart || {}),
        // fall back and check for a bgp test id if necessary
        testId: legacyAlarm?.dimensionToKeyPart?.test_id || legacyAlarm?.dimensionToKeyPart?.synthetic,
        // agent and target agent id are camelcased in the v2 system
        agentId: legacyAlarm?.dimensionToKeyPart?.agent_id,
        targetAgentId: legacyAlarm?.dimensionToKeyPart?.target_agent_id
      }
    }
  };
}

function getWorstHealthInArray(array, metricName) {
  const filtered = array
    .map((item) => HEALTH_SORT.indexOf(item[metricName] === 'major' ? 'warning' : item[metricName]))
    .filter((value) => value >= 0);
  return HEALTH_SORT[Math.min(...filtered)];
}

/*
  accepts an 'event_start_time' value from an alert manager alarm

  legacy v1 alarms and v2 alarms occurring before the 12/24 release of first triggers
  will not have a value

  v1 alarms will be undefined and v2 alarms will have the ALARM_NULL_END_DATE value
*/
function getFirstTriggerTime(value) {
  if (value && value !== ALARM_NULL_END_DATE) {
    return value;
  }

  return null;
}

/*
  Given a list of complete alarms, returns a shorted list of alarms suitable for comparing against timestamps and organizing on a timeline
  Example result with a single active alarm:

  [
    {
      id: '018f7269-3ba4-7c5b-8fbf-37d8e935a73a',
      severity: 'SEVERITY_CRITICAL',
      state: 'ALARM_STATE_ACTIVE',
      start: 1715611427,
      end: null
    }
  ]
*/
function getTimelineAlarms({ alarms = [] } = {}) {
  return alarms.map((alarm) => {
    // default to v1 alarm datetime, allow fallback to v2, beginning painting at the first trigger timestamp if available
    const startTime = alarm.startTime || getFirstTriggerTime(alarm.event_start_time) || alarm.start_time;
    const endTime = alarm.endTime || alarm.end_time;
    const start = moment(startTime).unix();
    const end = endTime === null || endTime === ALARM_NULL_END_DATE ? null : moment(endTime).unix();

    return {
      id: alarm.id,
      severity: ALERT_MANAGER_SEVERITY_TO_STANDARD_ALERT_SEVERITY[alarm.severity] || alarm.severity,
      state: ALARM_STATE_KEY_TO_RAW[alarm.state] || alarm.state,
      metric: alarm.key?.value?.metric,
      start,
      end,
      alarm: makeAlertManagerAlarm(alarm)
    };
  });
}

/*
  Given a timeline alarm and start and end timestamps, returns an object:

  {
    didAlarm // boolean indicating the alarm started inside the timestamp range
    isWithinRange // boolean indicating the alarm was present inside the timestamp range
  }
*/
function isTimelineAlarmWithinTimestampRange({ timelineAlarm, tsStart, tsEnd } = {}) {
  const result = { didAlarm: false, isWithinRange: false };

  if (timelineAlarm?.start && tsStart) {
    const alarmStartedInsideRange =
      timelineAlarm.start >= +tsStart && ((!!tsEnd && timelineAlarm.start < tsEnd) || !tsEnd);

    const alarmPresentInsideRange =
      timelineAlarm.start <= +tsStart && (timelineAlarm.end > (tsEnd || +tsStart) || timelineAlarm.end === null);

    result.didAlarm = alarmStartedInsideRange;
    result.isWithinRange = alarmStartedInsideRange || alarmPresentInsideRange;
  }

  return result;
}

/*
  provides total counts by severity and metric
*/
function getAlarmSummary(alarms) {
  return alarms.reduce(
    (summary, alarm) => {
      const { severity, metric } = alarm;
      const severityKey = severity === 'major' ? 'warning' : severity;
      const metricName = metric || 'other';

      // update severity and metric counts
      summary.severities[severityKey] = (summary.severities[severityKey] || 0) + 1;
      summary.metrics[metricName] = (summary.metrics[metricName] || 0) + 1;

      return summary;
    },
    { severities: {}, metrics: {} }
  );
}

/*
  Given a list of alarms and a list of timestamps, assembles a list representing a health timeline

  ex:

  [
    {
      alarm: true, // boolean indicating whether it fired in the stamp
      alarms: [{
        id: '018fc570-f21d-7a0e-b2a7-3a80073ba042',
        severity: 'major',
        state: 'ALARM_STATE_ACTIVE',
        start: 1717004450,
        end: null
      }], // list of alarms present in the stamp
      health: 'warning', // aggregated worst health of the bunch
      time: 1717004450000 // timestamp
    },
    ...
  ]
*/
function getHealthTimeline({ alarms, timestamps, convertToMs = true }) {
  if (timestamps.length) {
    const timelineAlarms = getTimelineAlarms({ alarms });

    return timestamps.map((ts, index) => {
      let resultAlarmed = false;
      const resultAlarms = [];

      for (let i = 0; i < timelineAlarms.length; i += 1) {
        const alarm = timelineAlarms[i];
        const { didAlarm, isWithinRange } = isTimelineAlarmWithinTimestampRange({
          timelineAlarm: alarm,
          tsStart: ts,
          tsEnd: +timestamps[index + 1]
        });

        if (didAlarm) {
          resultAlarmed = didAlarm;
        }

        if (isWithinRange) {
          resultAlarms.push(alarm);
        }
      }

      return {
        time: convertToMs ? +ts * 1000 : +ts,
        alarm: resultAlarmed,
        alarms: resultAlarms,
        alarmSummary: getAlarmSummary(resultAlarms),
        health: resultAlarms.length ? getWorstHealthInArray(resultAlarms, 'severity') : 'healthy'
      };
    });
  }

  return [];
}

/*
  keys healthtimeline alarms by result time in ms
*/
function getTimelineAlarmsByResultTimeMs(healthTimeline) {
  return (healthTimeline || []).reduce(
    (acc, { time, alarms }) => ({
      ...acc,
      [time]: alarms.map((alarm) => alarm.alarm) // the complete alarm is in the 'alarm' property
    }),
    {}
  );
}

/*
  Checks alarm state in both v1 and v2 styles to determine if it's active
*/
function isAlarmActive(alarmState) {
  return alarmState === ALARM_STATE_KEY_TO_RAW.alarm || alarmState === ALARM_STATE_RAW_TO_KEY.ALARM_STATE_ACTIVE;
}

function getWorstHealth(health1, health2) {
  if (HEALTH_SORT.indexOf(health1) <= HEALTH_SORT.indexOf(health2)) {
    return health1;
  }
  return health2;
}

function getMaxRawLookbackByTestFreq(test) {
  // attempt to get the test frequency, first checking the configured period, falling back to the ping period
  const config = test.get('config');
  const testFrequency = config?.period || config?.ping?.period;

  // Based off current syngest aggregation algorithm (https://github.com/kentik/syngest/blob/a4b343a9e833314765bb99b88141ddcf3dbb2063/pkg/ds/health_ds.go#L582)
  // conversion: if testFreq < aggregation value, then return queryDuration conditional value
  if (testFrequency) {
    if (testFrequency < 60 /* 1m */) {
      return 900; // 15m
    }
    if (testFrequency < 300 /* 5m */) {
      return 3600; // 1h
    }
    if (testFrequency < 600 /* 10m */) {
      return 10800; // 3h
    }
    if (testFrequency < 1800 /* 30m */) {
      return 21600; // 6h
    }
    if (testFrequency < 10800 /* 3h */) {
      return 86400; // 24h
    }
    if (testFrequency < 21600 /* 6h */) {
      return 604800; // 7d
    }
    // max out at 7 days just to not beat on our system by default?
    return 604800; // 7d
  }
  return null;
}

function getLookbackOptions({ test, lookbackOptions = DEFAULT_LOOKBACK_OPTIONS, maxRawLookback }) {
  const max = maxRawLookback || getMaxRawLookbackByTestFreq(test);

  return lookbackOptions.reduce(
    (acc, option) => {
      if (option.value <= max) {
        acc.raw.push(option);
      } else {
        acc.aggregate.push(option);
      }

      return acc;
    },
    { raw: [], aggregate: [] }
  );
}

module.exports = {
  getHealthTimeline,
  getTimelineAlarmsByResultTimeMs,
  getTimelineAlarms,
  getWorstHealthInArray,
  isAlarmActive,
  isTimelineAlarmWithinTimestampRange,
  makeAlertManagerAlarm,
  getWorstHealth,
  getMaxRawLookbackByTestFreq,
  getLookbackOptions,
  getFirstTriggerTime
};
