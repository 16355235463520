import { css } from 'styled-components';
import { Classes } from '@blueprintjs/core';

/**
 *  `divs` (rendered by Popover) that appear inside of a control group, do not inherit `border-radius` properly.
 * */
const controlGroupPopovers = css`
  .${Classes.CONTROL_GROUP} .${Classes.POPOVER_WRAPPER} div {
    border-radius: inherit;
  }
  .${Classes.CONTROL_GROUP} .${Classes.FORM_CONTENT} {
    border-radius: inherit;
  }
  .${Classes.CONTROL_GROUP} .${Classes.FORM_CONTENT} {
    border-radius: inherit;
  }
`;

/**
 * Reset input line-height to look better in Safari
 */
const inputLineHeight = css`
  .${Classes.INPUT} {
    line-height: 1.15;
  }
  .${Classes.INPUT_GROUP}.${Classes.SMALL} .${Classes.INPUT} {
    line-height: 1.15;
  }
  .${Classes.INPUT_GROUP}.${Classes.LARGE} .${Classes.INPUT} {
    line-height: 1.15;
  }
`;

/**
 * The RangeSlider doesn't properly use the intent backgrounds from the Dark theme
 */
const rangeSliderBackgroundIntentsDarkMode = css`
  .${Classes.SLIDER_PROGRESS} {
    &.${Classes.INTENT_PRIMARY} {
      background-color: ${({ theme }) => theme.colors.primary} !important;
    }
  }
`;

/**
 * There's a dark mode style that gets applied to icons inside of InputGroups that seems
 * very broad, since it actually targets things inside of Tags also, like the "remove" button.
 * Unset this color
 * .bp4-dark .bp4-input-group .bp4-icon {
      color: #bfccd6;
    }
 */

// const incorrectDarkIconInsideFormGroupColor = css`
//   .${Classes.DARK} {
//     .${Classes.INPUT_GROUP} .${Classes.ICON} {
//       color: unset !important;
//     }
//   }
// `;

/**
 * A few of the styles in DateRange need to be adjusted to match the colors in our theme,
 * because the variables don't trickle through when we compile the sass.
 */

const dateRangePickerStyles = css`
  .bp4-datepicker .DayPicker-Day.DayPicker-Day--selected {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  .bp4-dark .bp4-datepicker {
    background: #373c3f;
    .DayPicker-Day.DayPicker-Day--selected {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

/**
 * Since we use "large", sometimes pt-large gets put on things so we need to reset them
 */
const resetPtLargeFormControls = css`
  .bp4-input-group.bp4-large .bp4-input:not(:first-child) {
    padding-left: 30px;
  }
`;

/**
 * Easy custom matched scrollbars for Webkit
 */
const styledScrollbars = css`
  *::-webkit-scrollbar {
    height: 14px;
    width: 14px;
  }
  *::-webkit-scrollbar-track {
    background-color: #f0f1f4; */
  }
  *::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: rgba(0, 0, 0, 0.5);
    border: 3px solid transparent;
    border-radius: 8px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.65);
    }
  }
  *::-webkit-scrollbar-corner {
    background-color: #f0f1f4;
  }
  .bp4-dark & {
    *::-webkit-scrollbar-track {
      background-color: #323232;
    }
    *::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      &:hover {
        background-color: #6a6a6a;
      }
      &:active {
        background-color: #4a4a4a;
      }
    }
    *::-webkit-scrollbar-corner {
      background-color: #323232;
    }
  }
`;

/**
 * Icons that appear as the last child in a Button have incorrect margins.
 */
// const adjustIconButtonMargin = css`
//   .${Classes.BUTTON} > :last-child {
//     margin-right: ${({ theme }) => `${theme.gridSize}px`} !important;
//   }
// `;

// Sidebar items that have a submenu are rendered in a portal and have their own classname
// This is used for tweaking positioning, borders, and box shadows a bit to give the look of rendering directly next to its target
const sidebarSubmenuPopoverPortal = css`
  .sidebar-submenu-portal {
    .${Classes.TRANSITION_CONTAINER} {
      margin-top: 5px;
    }
    .${Classes.POPOVER} {
      border-left: ${({ theme }) => theme.borders.thin};
      border-right: ${({ theme }) => theme.borders.thin};
      &,
      &.${Classes.DARK}, .${Classes.POPOVER_CONTENT} {
        box-shadow: none;
        border-radius: 0;
      }
    }
  }
`;

/*
  Styles for dashboards in edit mode:
  - checkerboard background
  - placeholders for when resizing and dragging
*/
const dashboardEditStyles = css`
  .react-grid-layout {
    overflow: auto;
    position: relative;
    transition: unset;
    &.editing {
      ${({ theme }) => {
        const transparent = theme.name === 'light' ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.5)';
        const grey = theme.name === 'light' ? theme.colors.lightGray4 : theme.colors.black[4];

        return css`
          background: linear-gradient(
              45deg,
              ${grey} 25%,
              ${transparent} 25%,
              ${transparent} 75%,
              ${grey} 75%,
              ${grey} 0
            ),
            linear-gradient(45deg, ${grey} 25%, ${transparent} 25%, ${transparent} 75%, ${grey} 75%, ${grey} 0),
            ${theme.colors.lightGray2};
          background-position:
            0 0,
            10px 10px;
          background-repeat: repeat, repeat;
          background-size:
            20px 20px,
            20px 20px;
          border-top: 1px solid ${theme.colors.navbarMenuBackground};
          min-height: 100%;
        `;
      }}
    }
  }
  .react-draggable-dragging {
    z-index: 2;
  }
  .react-grid-placeholder {
    background: ${({ theme }) => theme.colors.blue1};
    opacity: 0.15;
    z-index: 1;
  }
`;

const inputPlaceholderColor = css`
  input::placeholder {
    color: ${({ theme }) => theme.colors.gray1};
  }
`;

// When applied to components such as Popovers, it allows us to disable transforms without losing the arrow style.
// While Dialogs give us a prop to use, Popovers do not.
// This becomes important when you want to display a chart in a Popover. If the chart is loaded from cache,
// there's a race condition with the default scale transform and the loading of the tooltips.
// With the scale transform in place, the tooltips will not work properly and highlight incorrect values.
const noTransform = css`
  .no-transform {
    transform: none !important;
  }
`;

export default css`
  ${controlGroupPopovers};
  ${inputLineHeight};
  ${rangeSliderBackgroundIntentsDarkMode};
  ${styledScrollbars};
  ${sidebarSubmenuPopoverPortal};
  ${dashboardEditStyles};
  ${resetPtLargeFormControls};
  ${inputPlaceholderColor};
  ${noTransform};
  ${dateRangePickerStyles};
`;
