export function getMaxFps(plan) {
  if (!plan) {
    return null;
  }

  const { plan_type, cloud_provider, pak_fps, max_fps } = plan;
  const isUniversalCloudPlan = plan_type === 'cloud' && cloud_provider === 'universal';
  const isCloudPlan = plan_type === 'cloud' && cloud_provider !== 'universal';
  const isFlowPak = plan_type === 'flowpak';
  const isUniversalPak = plan_type === 'universalpak';

  return isUniversalPak || isFlowPak || isCloudPlan || isUniversalCloudPlan ? pak_fps : max_fps;
}

export function getMaxMps(plan) {
  if (!plan) {
    return null;
  }

  const { pak_fps, max_fps } = plan;

  return pak_fps || max_fps;
}
