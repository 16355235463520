import React, { Component } from 'react';

import { Form } from 'core/form';
import aggregateConfig from 'app/forms/config/aggregate';
import QuickViewAggregate from './QuickViewAggregate';

const nextHopAsnTopxMatchFn = (result) => {
  const [, match] = (result['Next Hop AS Number'] || '').match(/\(([0-9]+)\)/) || [];
  return match;
};

@Form(aggregateConfig('NextHopAsnAggregate'))
class NextHopAsnAggregate extends Component {
  render() {
    return (
      <QuickViewAggregate
        title="Next-Hop ASNs"
        resultsKey="'Next Hop AS Number"
        insightsQuery="fetchAsnInsights"
        tableQueryProps={{
          aggregateFiltersDimensionLabel: 'Next Hop AS Number',
          aggregateFilters: [
            { metric: ['src_nexthop_asn', 'dst_nexthop_asn'] },
            { metric: ['src_nexthop_asn'] },
            { metric: ['dst_nexthop_asn'] },
            { metric: ['src_nexthop_asn', 'dst_nexthop_asn'] },
            { metric: ['src_nexthop_asn', 'dst_nexthop_asn'] }
          ]
        }}
        getTopxProps={{ mapFn: nextHopAsnTopxMatchFn }}
        chartProps={{
          metrics: {
            Internal: ['dst_nexthop_asn', 'src_nexthop_asn'],
            Inbound: 'src_nexthop_asn',
            Outbound: 'dst_nexthop_asn'
          }
        }}
      />
    );
  }
}

export default NextHopAsnAggregate;
