import React, { Component } from 'react';
import Button from 'core/components/Button';
import AnchorButton from 'core/components/AnchorButton';
import Tooltip from 'core/components/Tooltip';

class ButtonGroupTab extends Component {
  render() {
    const { disabledTooltip, selectedTabId, onClick, id, title, ...rest } = this.props;
    const active = selectedTabId === id;

    const ButtonComponent = disabledTooltip ? AnchorButton : Button;

    const content = (
      <ButtonComponent p="6px 10px" active={active} onClick={() => onClick(id)} {...rest}>
        {title}
      </ButtonComponent>
    );

    if (disabledTooltip && rest.disabled) {
      return (
        <Tooltip key={title} content={disabledTooltip}>
          {content}
        </Tooltip>
      );
    }

    return content;
  }
}

export default ButtonGroupTab;
