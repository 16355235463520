import React from 'react';
import { observer } from 'mobx-react';
import { Flex, Text } from 'core/components';
import { Search } from 'core/components/table';
import GroupByControl from 'app/components/admin/GroupByControl';

@observer
export default class IncidentLogFilters extends React.Component {
  static defaultProps = {
    groupByOptions: [],
    onGroupByChange: null
  };

  get groupBySelector() {
    const { collection, groupByOptions } = this.props;

    if (groupByOptions.length > 0) {
      return (
        <>
          <Text small>Group By:</Text>
          <GroupByControl collection={collection} options={groupByOptions} inlineLabel={false} small />
        </>
      );
    }

    return null;
  }

  render() {
    const { collection } = this.props;

    return (
      <Flex display="flex" alignItems="center" justifyContent="space-between">
        <Text fontWeight="bold">Filters</Text>
        <Flex alignItems="center" gap="4px">
          {this.groupBySelector}
          <Search
            placeholder="Search Incident Log"
            collection={collection}
            inputProps={{
              width: 500,
              small: true,
              clearable: true
            }}
            onChange={this.handleSearchChange}
          />
        </Flex>
      </Flex>
    );
  }
}
